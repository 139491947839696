import { CloseOutlined } from "@ant-design/icons";
import { Button, Input, type InputRef, Typography } from "antd";
import React, { useRef } from "react";
import "./MultiValueInput.scss";

export interface IMultiValueInputProps {
  value?: string[];
  onChange?: (e: string[]) => void;
  placeholder: string;
}

export function MultiValueInput(props: IMultiValueInputProps) {
  const { onChange, value, placeholder } = props;
  const inputRef = useRef<InputRef>(null);
  const [val, setVal] = React.useState<string>("");

  return (
    <div className="multivalueinput">
      <div>
        {value?.map((v) => {
          return (
            <div className="multivalueinput-value" key={v}>
              <div className="multivalueinput-value-value">
                <Typography.Text ellipsis>{v}</Typography.Text>
              </div>
              <div className="multivalueinput-value-action">
                <Button
                  type="text"
                  shape="circle"
                  size="small"
                  onClick={() => {
                    if (onChange) {
                      onChange(value.filter((va) => va !== v));
                    }
                  }}
                  icon={<CloseOutlined />}
                />
              </div>
            </div>
          );
        })}
      </div>
      <div>
        <Input
          ref={inputRef}
          placeholder={placeholder}
          value={val}
          onChange={(e) => setVal(e.target.value)}
          onPressEnter={(e) => {
            e.preventDefault();
            e.stopPropagation();
            if (onChange) {
              onChange([...(value ? value : []), val]);
              setVal("");
              setTimeout(() => {
                inputRef.current?.focus();
              }, 200);
            }
          }}
          suffix={
            <Typography.Text strong type="secondary" keyboard>
              ↵
            </Typography.Text>
          }
        />
      </div>
    </div>
  );
}
