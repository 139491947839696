import Icon from "@ant-design/icons";
import type { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";

export const WlyFolderIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon
    component={() => (
      <svg
        width="1em"
        height="1em"
        viewBox="0 0 12 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1.5 1H5.6495C5.85099 0.999997 6.04778 1.06086 6.21409 1.17462C6.3804 1.28837 6.50846 1.44971 6.5815 1.6375L7.235 3.319C7.26442 3.39476 7.27493 3.47656 7.26562 3.5573C7.2563 3.63804 7.22745 3.71529 7.18155 3.78236C7.13565 3.84944 7.07409 3.90431 7.00221 3.94224C6.93032 3.98016 6.85028 3.99999 6.769 4H0.5V2C0.5 1.86868 0.525866 1.73864 0.57612 1.61732C0.626375 1.49599 0.700035 1.38575 0.792893 1.29289C0.885752 1.20003 0.995991 1.12638 1.11732 1.07612C1.23864 1.02587 1.36868 1 1.5 1Z"
          fill="#2599D4"
          opacity="0.6"
        />
        <path
          d="M0.5 3H10.5C10.7652 3 11.0196 3.10536 11.2071 3.29289C11.3946 3.48043 11.5 3.73478 11.5 4V10C11.5 10.1313 11.4741 10.2614 11.4239 10.3827C11.3736 10.504 11.3 10.6142 11.2071 10.7071C11.1142 10.8 11.004 10.8736 10.8827 10.9239C10.7614 10.9741 10.6313 11 10.5 11H1.5C1.36868 11 1.23864 10.9741 1.11732 10.9239C0.995991 10.8736 0.885752 10.8 0.792893 10.7071C0.700035 10.6142 0.626375 10.504 0.57612 10.3827C0.525866 10.2614 0.5 10.1313 0.5 10V3Z"
          fill="#2599D4"
        />
        <path d="M8.5 11L11.5 8V4L4.5 11H8.5Z" fill="currentColor" />
      </svg>
    )}
    {...props}
  />
);
