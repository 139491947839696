import { gutter, GutterMarker } from "@codemirror/view";
import type { AsyncData } from "../../../../../../../helpers/typescriptHelpers";
import "./sqlErrorGutter.scss";

export function sqlErrorGutter(data: AsyncData<{}>) {
  const errorMarker = new (class extends GutterMarker {
    toDOM() {
      const node = document.createElement("div");
      node.className = "pulsing-dot";
      return node;
    }
  })();

  const lineInError = () => {
    if (data.status !== "error") return null;
    const regex = /\[([0-9]+):([0-9]+)\]/i;
    const errorLocation = data.error.message?.match(regex);
    if (!errorLocation) return null;
    return +errorLocation[1];
  };

  return gutter({
    class: "cm-errorGutter",
    lineMarker(view, line) {
      if (view.state.doc.lineAt(line.from).number !== lineInError()) {
        return null;
      } else {
        return errorMarker;
      }
    },
    initialSpacer: () => errorMarker,
  });
}
