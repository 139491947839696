import { useForm } from "antd/es/form/Form";
import { SimpleFormModal } from "../../../../../../components/form/modal/SimpleFormModal";
import type { IObject, IObjectView } from "../../../../../../interfaces/object";
import type { PromoteObjectViewFormData } from "../forms/PromoteObjectViewForm";
import { PromoteObjectViewForm } from "../forms/PromoteObjectViewForm";

type Props = {
  object: IObject;
  open: boolean;
  initialValues?: Partial<PromoteObjectViewFormData>;
  isSaving: boolean;
  views: IObjectView[];
  onCancel: () => void;
  onSave: (values: PromoteObjectViewFormData) => any;
};

export default function PromoteObjectViewModal({
  object,
  open,
  initialValues,
  isSaving,
  views,
  onCancel,
  onSave,
}: Props) {
  const [form] = useForm<PromoteObjectViewFormData>();

  return (
    <SimpleFormModal<PromoteObjectViewFormData>
      form={form}
      open={open}
      onCancel={onCancel}
      isSaving={isSaving}
      modalProps={{ title: "Default view", okText: "Save", width: 420 }}
    >
      <PromoteObjectViewForm
        form={form}
        initialValues={initialValues}
        object={object}
        views={views}
        onSave={onSave}
      />
    </SimpleFormModal>
  );
}
