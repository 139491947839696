import { useEffect, useRef } from "react";

export function usePollingEffect(
  asyncCallback: () => Promise<any>,
  dependencies: any[] = [],
  {
    interval = 2_000, // 2 seconds,
    skipWhenTabHidden = true,
    onCleanUp = () => {},
  } = {}
) {
  const timeoutIdRef = useRef(null);
  useEffect(() => {
    let _stopped = false;
    // Side note: preceding semicolon needed for IIFEs.
    (async function pollingCallback() {
      try {
        const shouldRun =
          !skipWhenTabHidden || (skipWhenTabHidden && !document.hidden);
        if (shouldRun) {
          await asyncCallback();
        }
      } finally {
        // Set timeout after it finished, unless stopped
        timeoutIdRef.current =
          !_stopped && setTimeout(pollingCallback, interval);
      }
    })();
    // Clean up if dependencies change
    return () => {
      _stopped = true; // prevent racing conditions
      clearTimeout(timeoutIdRef.current);
      onCleanUp();
    };
  }, [...dependencies, interval]);
}
