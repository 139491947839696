import * as React from "react";
import { compose } from "../../../../components/compose/WlyCompose";
import Error from "../../../../components/layout/feedback/error";
import Loading from "../../../../components/layout/feedback/loading";
import type { IDestination } from "../../../../interfaces/destinations";
import GraphQl from "../../../graphql/graphql";
import type { InjectedOrgProps } from "../../../orgs/WithOrg";
import WithOrg from "../../../orgs/WithOrg";
import SettingsWrapper from "../../SettingsWrapper";
import WarehouseList from "./WarehouseList";

// import "./Warehouse.scss";
// import WarehouseDetail from "./WarehouseDetail

interface WarehousePageProps {}

const gql = `
query getConnectedWarehouse(
    $orgId: ID!
  ) {
    allDestinations(where: { org: { id: $orgId }, isDeleted_not: true }) {
      id
      name
      isBiEnabled
      destinationMeta {
        id
        slug
        warehouseType
        isWhalyManaged
        publicInfo {
          name
          description
          logo
          website
        }
        name
        options(where: { hidden: false }) {
          id
          slug
          key
          label
          editable
          type
          options
          encrypt
          hidden
        }
      }
      values(where: { option: { hidden: false } }) {
        id
        value
        option {
          id
          slug
          key
          label
          editable
          type
          options
          encrypt
          description
          hidden
        }
      }
      slug
      org {
        id
      }
    }
  }
`;

interface IReturnValue {
  allDestinations: IDestination[];
}

type Props = WarehousePageProps & InjectedOrgProps;

class WarehousePage extends React.Component<Props> {
  public render() {
    const { org } = this.props;
    return (
      <SettingsWrapper>
        <GraphQl<IReturnValue> query={gql} variables={{ orgId: org.id }}>
          {(gql) => {
            if (gql.status === "loading" || gql.status === "initial") {
              return <Loading />;
            }
            if (gql.status === "error") {
              return <Error />;
            }

            return <WarehouseList destinations={gql.data.allDestinations} />;
          }}
        </GraphQl>
      </SettingsWrapper>
    );
  }
}

export default compose<Props, WarehousePageProps>(WithOrg)(WarehousePage);
