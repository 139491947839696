export const textFormatting = {
  render: "TextFormatting",
  children: [
    "paragraph",
    "tag",
    "list",
    "strong",
    "italic",
    "link",
    "em",
    "image",
    "heading",
  ],
  attributes: {
    type: {
      type: String,
      matches: ["success", "warning", "danger", "secondary"],
    },
    size: {
      type: String,
      default: "medium",
      matches: ["large", "medium", "small"],
    },
    lineClamp: {
      type: Number,
    },
  },
};
