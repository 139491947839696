import React from "react";
import Feednack from "../../../../components/layout/feedback/feedback";
import Loading from "../../../../components/layout/feedback/loading";
import WlyModal from "../../../../components/modal/WlyModal";
import type { AsyncData } from "../../../../helpers/typescriptHelpers";
import type { IDataset } from "../../../../interfaces/sources";
import GraphQLService from "../../../../services/graphql/GraphQLService";

import type { IDestination } from "../../../../interfaces/destinations";
import "./MigrationModal.scss";
import { MigrationModalStep1 } from "./step1/MigrationModalStep1";
import MigrationModalStep2 from "./step2/MigrationModalStep2";

interface IModelMigrationModalProps {
  onClose: () => void;
  visible: boolean;
  datasets: IDataset[];
  dataset: IDataset;
  onMigrationDone: (
    toDatasetId: string,
    datasetIds: string[],
    explorationIds: string[]
  ) => Promise<void>;
  currentWarehouse: IDestination;
}

type Step = "STEP_1" | "STEP_2" | "STEP_3";

export function ModelMigrationModal(props: IModelMigrationModalProps) {
  const {
    onClose,
    visible,
    datasets,
    dataset,
    onMigrationDone,
    currentWarehouse,
  } = props;

  const [step, setStep] = React.useState<Step>("STEP_1");

  const [destinationDataset, setDestinationDataset] = React.useState<
    IDataset | undefined
  >();

  const [isSchemaValid, setIsSchemaValid] = React.useState<AsyncData<boolean>>({
    status: "initial",
  });

  const [migration, setMigration] = React.useState<
    AsyncData<{
      updatedDatasets: string[];
      updatedExplorations: string[];
      errors?: string[];
    }>
  >({
    status: "initial",
  });

  React.useEffect(() => {
    if (visible) {
      setStep("STEP_1");
      setDestinationDataset(undefined);
      setIsSchemaValid({ status: "initial" });
      setMigration({ status: "initial" });
    }
  }, [visible]);

  const migrate = async () => {
    try {
      setMigration({ status: "loading" });
      const data = await GraphQLService<{
        runDatasetMigration: {
          updatedDatasets: string[];
          updatedExplorations: string[];
          errors?: string[];
        };
      }>(
        `mutation runMigration($currentViewId: ID!, $destinationViewId: ID!) {
          runDatasetMigration(currentViewId: $currentViewId, destinationViewId: $destinationViewId) {
            updatedDatasets
            updatedExplorations
            errors
          }
      }`,
        {
          currentViewId: dataset.views[0]?.id,
          destinationViewId: destinationDataset?.views[0].id,
        }
      );
      await onMigrationDone(
        destinationDataset.id,
        data.runDatasetMigration.updatedDatasets,
        data.runDatasetMigration.updatedExplorations
      );
      setMigration({ status: "success", data: data.runDatasetMigration });
    } catch (err) {
      console.error(err);
      setMigration({ status: "error", error: err });
    }
  };

  React.useEffect(() => {
    if (step === "STEP_3") {
      migrate();
    }
  }, [step]);

  const renderStep2ButtonTitle = () => {
    if (
      isSchemaValid.status === "initial" ||
      isSchemaValid.status === "loading"
    ) {
      return { title: "Checking...", danger: false, loading: true };
    }
    if (isSchemaValid.status === "success") {
      return { title: "Start migration", danger: false, loading: false };
    }
    return { title: "Migrate anyway", danger: true, loading: false };
  };

  const renderStep3 = () => {
    if (migration.status === "initial" || migration.status === "loading") {
      return (
        <Feednack>
          <Loading />
        </Feednack>
      );
    } else if (migration.status === "error" || migration.data?.errors?.length) {
      return (
        <Feednack>
          <div>There was an error running your migration...</div>
        </Feednack>
      );
    }

    return <div>Your migration was a success...</div>;
  };

  const renderTitle = (): {
    title: string;
    onOk: () => void;
    okDisabled: boolean;
    okText: string;
    body: React.ReactNode;
    confirmLoading?: boolean;
    okDanger?: boolean;
  } => {
    switch (step) {
      case "STEP_1":
        return {
          title: "Configure migration",
          onOk: () => setStep("STEP_2"),
          okDisabled: !destinationDataset,
          okText: "Continue",
          body: (
            <MigrationModalStep1
              dataset={dataset}
              datasets={datasets}
              destinationDataset={destinationDataset}
              setDestinationDataset={setDestinationDataset}
            />
          ),
        };
      case "STEP_2":
        const button = renderStep2ButtonTitle();
        return {
          title: "Checking migration...",
          onOk: () => setStep("STEP_3"),
          okDisabled: button.loading,
          okText: button.title,
          confirmLoading: button.loading,
          okDanger: button.danger,
          body: (
            <MigrationModalStep2
              dataset={dataset}
              destinationDataset={destinationDataset}
              setIsSchemaValid={setIsSchemaValid}
              currentWarehouse={currentWarehouse}
            />
          ),
        };
      case "STEP_3":
        return {
          title: "Migrating",
          onOk: () => onClose(),
          okText: "Done",
          okDisabled: false,
          body: renderStep3(),
        };
    }
  };

  const content = renderTitle();

  return (
    <WlyModal
      okButtonDisabled={content.okDisabled}
      confirmLoading={content.confirmLoading}
      onClose={onClose}
      open={visible}
      onOk={content.onOk}
      okText={content.okText}
      title={content.title}
      className="migration-modal"
      okButtonDanger={content.okDanger}
      maskClosable={false}
    >
      {content.body}
    </WlyModal>
  );
}
