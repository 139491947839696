import { action, makeObservable, observable } from "mobx";
import type { CreateObjectViewFormData } from "../containers/v2-demo/container/object/viewer/forms/CreateObjectViewForm";
import type { IObjectView, IObjectViewType } from "../interfaces/object";

type CreateObjectViewParams =
  | { title: string; values: CreateObjectViewFormData; type?: IObjectViewType }
  | undefined;
type UpdateObjectViewParams =
  | { id: string; title?: string; content?: string; query: string }
  | undefined;
type DeleteObjectViewParams = Pick<IObjectView, "id"> | undefined;
type RenameObjectViewParams = Pick<IObjectView, "id" | "name"> | undefined;
type ShareObjectViewParams = Pick<IObjectView, "id" | "isPersonal"> | undefined;
type PromoteObjectViewParams = Pick<IObjectView, "id"> | undefined;
type AddRecordObjectViewParams =
  | { records: string[]; views: string[] }
  | undefined;

class ObjectUIStore {
  selectViewOpen: boolean = false;
  createViewOpen: CreateObjectViewParams = undefined;
  updateViewOpen: UpdateObjectViewParams = undefined;
  deleteViewOpen: DeleteObjectViewParams = undefined;
  renameViewOpen: RenameObjectViewParams = undefined;
  shareViewOpen: ShareObjectViewParams = undefined;
  promoteViewOpen: PromoteObjectViewParams = undefined;
  addRecordsToView: AddRecordObjectViewParams = undefined;

  constructor() {
    makeObservable(this, {
      selectViewOpen: observable,
      createViewOpen: observable,
      updateViewOpen: observable,
      deleteViewOpen: observable,
      renameViewOpen: observable,
      shareViewOpen: observable,
      promoteViewOpen: observable,
      addRecordsToView: observable,
      setSelectViewOpen: action,
      setCreateViewOpen: action,
      setDeleteViewOpen: action,
      setRenameViewOpen: action,
      setShareViewOpen: action,
      setPromoteViewOpen: action,
      setUpdateViewOpen: action,

      reset: action,
    });
  }

  reset = () => {
    this.selectViewOpen = false;
    this.createViewOpen = undefined;
    this.updateViewOpen = undefined;
    this.deleteViewOpen = undefined;
    this.renameViewOpen = undefined;
    this.shareViewOpen = undefined;
    this.promoteViewOpen = undefined;
    this.addRecordsToView = undefined;
  };

  setAddRecordsToView = (open: AddRecordObjectViewParams) => {
    this.addRecordsToView = open;
  };

  setSelectViewOpen = (open: boolean) => {
    this.selectViewOpen = open;
  };

  setCreateViewOpen = (open: CreateObjectViewParams) => {
    this.createViewOpen = open;
  };

  setUpdateViewOpen = (open: UpdateObjectViewParams) => {
    this.updateViewOpen = open;
  };

  setDeleteViewOpen = (params: DeleteObjectViewParams) => {
    this.deleteViewOpen = params;
  };

  setRenameViewOpen = (params: RenameObjectViewParams) => {
    this.renameViewOpen = params;
  };

  setShareViewOpen = (params: ShareObjectViewParams) => {
    this.shareViewOpen = params;
  };

  setPromoteViewOpen = (params: PromoteObjectViewParams) => {
    this.promoteViewOpen = params;
  };
}

export interface ObjectUIStoreProps {
  objectUIStore: ObjectUIStore;
}

export default new ObjectUIStore();
