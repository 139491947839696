import { Card } from "antd";
import type { CardProps } from "antd/lib/card";

export interface IWlyCardProps extends CardProps {
  forwardRef?: React.MutableRefObject<HTMLDivElement>;
  children?: React.ReactNode;
}

export function WlyCard(props: IWlyCardProps) {
  const { forwardRef } = props;
  const classNames = [props.className, "wly-card"].join(" ");
  return (
    <Card {...props} ref={forwardRef} className={classNames}>
      {props.children}
    </Card>
  );
}
