import { Space } from "antd";
import type { Remote } from "comlink";
import _ from "lodash";
import { observer } from "mobx-react";
import type { Moment } from "moment";
import * as React from "react";
import type { RouteComponentProps } from "react-router";
import { withRouter } from "react-router";
import { compose } from "../../../../../components/compose/WlyCompose";
import Feednack from "../../../../../components/layout/feedback/feedback";
import Loading from "../../../../../components/layout/feedback/loading";
import type {
  AvailableDimension,
  AvailableMetric,
} from "../../../../../components/measures/filter-item/FilterItem";
import type { IExploration } from "../../../../../interfaces/explorations";
import type {
  IFilter,
  IFilterControlledBy,
  IReport,
  ITile,
  ITileToUpdate,
} from "../../../../../interfaces/reports";
import type { IStandardDimension } from "../../../../../interfaces/table";
import { routeDescriptor } from "../../../../../routes/routes";
import GraphQLService from "../../../../../services/graphql/GraphQLService";
import type { CubeJSPivotWorker } from "../../../../../worker/main.worker";
import type { ChartOption } from "../../../../chart-options/ChartOptions";
import type { ILagoonQuery } from "../../../../exploration/single/domain";
import { getTileDimension } from "../../../../exploration/single/visualization/actions/AddTileToReport";
import type { InjectedOrgProps } from "../../../../orgs/WithOrg";
import WithOrg from "../../../../orgs/WithOrg";
import { FILTER_ARRAY_SEPARATOR, getFilterDefaultValue } from "../../../domain";
import type { Store } from "../../chart/card/ChartCard";
import type { ChartEditionInitialData } from "../../chart/edition/ChartEditionDrawer";
import { ChartEditionDrawer } from "../../chart/edition/ChartEditionDrawer";
import { ChartOptionPanel } from "../../chart/options/ChartOptionPanel";
import type { ITileOptions } from "../../chart/panel/EditTilePanel";
import type {
  ActionType,
  DataMapStore,
  FilterMapStore,
  IFilterStore,
} from "../../domain";
import {
  CREATE_TILE,
  CREATE_TILE_FULL,
  RELOAD_EXPLORATION,
  UPDATE_FILTER,
  UPDATE_MULTIPLE_TILES,
  UPDATE_QUERY,
  UPDATE_TILE_FULL,
} from "../../domain";
import { convertWlyDatePickerValueToString } from "../../filters/date-filter/WlyDatePicker";
import FilterEdition from "../../filters/edition/FilterEdition";
import FilterOptionsEdit from "../../filters/options/FilterOptionsEdit";
import NavigationEdition from "../../navigation/edition/NavigationEdition";
import { NavigationOptionEdit } from "../../navigation/options/NavigationOptions";
import TextEdition from "../../text/edition/TextEdition";
import { TextOptionEdit } from "../../text/options/TextOptionEdit";
import { DashboardContentHeader } from "./DashboardContentHeader";
import { DashboarEditAddChartDrawer } from "./DashboardEditAddChartDrawer";
import { DashboardEditLeftActionBar } from "./DashboardEditLeftActionBar";
import { DashboardEditRightActionBar } from "./DashboardEditRightActionBar";
import DashboardEditTopActionBar from "./DashboardEditTopActionBar";
import DashboardFilters from "./DashboardFilters";
import DashboardGrid from "./DashboardGrid";
import { MIN_HEIGHT, MIN_WIDTH, generateTileTitle } from "./domain";
import type { IReportOptionPayload } from "./options/DashboardOptionsEdit";
import { DashboardOptionEdit } from "./options/DashboardOptionsEdit";

interface IDashboardContentProps {
  onChartAddition?: (explorationSlug: string) => void;
  disableNavigationItems?: boolean;
  fetched?: Moment;
  isEmbedded?: boolean;
  isSharingLink?: boolean;
  editing: boolean;
  hideFilters?: boolean;
  actionType?: ActionType;
  hideLayout?: boolean;
  report: IReport;
  reloadReport: (reportSlug: string, orgId) => Promise<IReport>;
  saving: (saving: boolean) => void;
  showTitle?: boolean;
  explorations: Array<IExploration>;
  shouldUpdateQueries: boolean;
  setExplorations: (explorations: IExploration[]) => void;
  setReport: (report: IReport) => void;
  filterStore: FilterMapStore;
  onEditClick: () => void;
  onCancelClick: () => void;
  setFilterStoreValue: (id: string, data: IFilterStore) => void;
  dataStore: DataMapStore;
  setDataStoreValue: (id: string, data: Partial<Store>) => void;
  selected?: { id: string; type: "TILE" | "FILTER" };
  setSelected: (
    selected: { type: "TILE" | "FILTER"; id: string } | undefined
  ) => void;
  embededWorkbench?: boolean;
  setShouldUpdateQuery: (v: boolean) => void;
  refreshReport: () => Promise<any>;
  addingNewText: boolean | ITile;
  setAddingNewText: (c: boolean | ITile) => void;
  addingNewNavigation: boolean | ITile;
  setAddingNewNavigation: (c: boolean | ITile) => void;
  addingNewFilter: boolean | IFilter;
  setAddingNewFilter: (c: boolean) => void;
  onOpenConsole?: (c?: string) => void;
  disableDrills?: boolean;
  forceCacheRefresh: (force: boolean) => any;
  isDisplayedInWorkspace: boolean;
  isBeingPushed?: boolean;
  externalWorker: Remote<CubeJSPivotWorker>;
  onRefresh?: (tileId: string, overrideQuery?: ILagoonQuery) => void;
}

type Props = IDashboardContentProps &
  RouteComponentProps<
    { tileSlug?: string; explorationSlug?: string; filterSlug?: string },
    {},
    { scrollToBottom?: boolean }
  > &
  InjectedOrgProps;

type IState = {
  editAddChartDrawerOpen: boolean;
  displayHeaderShadow: boolean;
  blinkingTileId?: string;
};

class DashboardContent extends React.Component<Props, IState> {
  reportContent: React.MutableRefObject<HTMLDivElement>;

  constructor(props: Props) {
    super(props);
    this.state = {
      editAddChartDrawerOpen: false,
      displayHeaderShadow: false,
    };
    this.reportContent = React.createRef();
  }

  componentDidMount() {
    const {
      match: { params },
    } = this.props;

    if (params.tileSlug) {
      this.setSelected("TILE", params.tileSlug);
    } else if (params.filterSlug) {
      this.setSelected("FILTER", params.filterSlug);
    } else {
      this.setSelected("TILE");
    }

    if (this.props.location.state && this.props.location.state.scrollToBottom) {
      this.scrollToBottom();
    }
    if (this.reportContent.current) {
      this.reportContent.current.addEventListener(
        "scroll",
        this.debouncedheaderShadowOnScroll
      );
      this.debouncedheaderShadowOnScroll();
    }
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.debouncedheaderShadowOnScroll);
    const style = (document.querySelector(".report-view") as HTMLElement)
      ?.style;
    style?.removeProperty?.("--report-header-background-color");
    style?.removeProperty?.("--report-header-border-color");
    style?.removeProperty?.("--report-subsider-header-background-color");
    style?.removeProperty?.("--report-subsider-body-background-color");
  }

  headerShadowOnScroll = () => {
    if (!this.reportContent.current) return;
    if (
      this.reportContent.current.scrollTop > 0 &&
      !this.state.displayHeaderShadow
    ) {
      this.setState({ displayHeaderShadow: true });
    } else if (
      this.reportContent.current.scrollTop === 0 &&
      this.state.displayHeaderShadow
    ) {
      this.setState({ displayHeaderShadow: false });
    }
  };

  debouncedheaderShadowOnScroll = _.debounce(this.headerShadowOnScroll, 500);

  componentDidUpdate(prevProps: Props) {
    const {
      match: {
        params: { tileSlug, filterSlug },
      },
    } = this.props;
    const {
      match: {
        params: { tileSlug: prevTileSlug, filterSlug: prevFilterSlug },
      },
    } = prevProps;

    if (tileSlug !== prevTileSlug || filterSlug !== prevFilterSlug) {
      if (tileSlug) {
        this.setSelected("TILE", tileSlug);
      } else if (filterSlug) {
        this.setSelected("FILTER", filterSlug);
      } else {
        this.setSelected("TILE");
      }
    }

    // hide edit left panel on not editiong
    if (prevProps.editing && !this.props.editing) {
      this.setState({ editAddChartDrawerOpen: false });
    }
  }

  setSelected = (type: "FILTER" | "TILE", slug?: string) => {
    const { report, setSelected, selected } = this.props;

    if (slug) {
      if (type === "TILE") {
        const selectedTile = report.tiles.find((t) => t.slug === slug);
        if (selectedTile) {
          setSelected({ type: "TILE", id: selectedTile.id });
        }
      } else {
        const selectedFilter = report.filters.find((t) => t.slug === slug);
        if (selectedFilter) {
          setSelected({ type: "FILTER", id: selectedFilter.id });
        }
      }
    } else if (selected) {
      setSelected(undefined);
    }
  };

  scrollToBottom = () => {
    const reportContent = this.reportContent.current;
    reportContent?.scrollTo?.({
      top: reportContent.scrollHeight,
      behavior: "smooth",
    });
  };

  getFilterStoreValues = (filterId: string) => {
    const { filterStore } = this.props;
    return filterStore[filterId] ? filterStore[filterId]?.value : [];
  };

  scrollToId = (id: string) => {
    const el = document.getElementById(`tile-${id}`);
    if (el) {
      el.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "center",
      });
      this.setState({ blinkingTileId: id });
      window.setTimeout(() => {
        this.setState({ blinkingTileId: null });
      }, 3000);
    }
  };

  onGridUpdate = async (tiles: ITile[]) => {
    const { report, setReport } = this.props;
    if (_.isEqual(tiles, report.tiles)) return;
    this.props.saving(true);
    try {
      const response = await GraphQLService(UPDATE_MULTIPLE_TILES, {
        data: tiles.map((t) => ({
          id: t.id,
          data: {
            height: t.height,
            width: t.width,
            top: t.top,
            left: t.left,
          },
        })),
        reportId: report.id,
      });

      const updatedTiles = report.tiles.map((tile) => {
        const matchingTile = response.updateTiles.find(
          (updatedTile) => updatedTile.id === tile.id
        );
        if (!matchingTile) return tile;
        return {
          ...tile,
          top: matchingTile.top,
          left: matchingTile.left,
          width: matchingTile.width,
          height: matchingTile.height,
        };
      });

      setReport({
        ...report,
        tiles: updatedTiles,
      });

      this.props.saving(false);
    } catch (error) {
      console.error(error);
      this.props.saving(false);
    }
  };

  onTileUpdate = (id: string, data: any) => {
    const { report, setReport, setAddingNewText } = this.props;

    this.props.saving(true);
    return GraphQLService(UPDATE_TILE_FULL, {
      id: id,
      data: data,
      reportId: report.id,
    })
      .then((r) => {
        setReport({
          ...report,
          tiles: report.tiles.map((t) => {
            if (t.id === id) {
              return r.updateTile;
            }
            return t;
          }),
          updatedAt: r.updateReport.updatedAt,
          updatedBy: r.updateReport.updatedBy,
        });
        setAddingNewText(false);
        this.props.saving(false);
      })
      .catch(() => {
        this.props.saving(false);
      });
  };

  updateChartOptions = (selectedTile: ITile, v: ChartOption) => {
    const { setDataStoreValue, dataStore } = this.props;

    if (selectedTile.id && dataStore[selectedTile.id]) {
      if (
        !_.isEqual(
          _(dataStore[selectedTile.id].query.chartOptions)
            .omitBy(_.isUndefined)
            .value(),
          _(v).omitBy(_.isUndefined).value()
        )
      ) {
        setDataStoreValue(selectedTile.id, {
          ...dataStore[selectedTile.id],
          query: {
            ...dataStore[selectedTile.id].query,
            chartOptions: v,
          },
        });
        if (selectedTile.id && dataStore[selectedTile.id].query) {
          this.debouncedTileUpdate(selectedTile.id, {
            content: JSON.stringify({
              ...dataStore[selectedTile.id].query,
              chartOptions: v,
            }),
          });
        }
      }
    }
  };

  updateTileInfo = (selectedTile: ITile, v: ITileOptions) => {
    const { setDataStoreValue, dataStore, setReport, report } = this.props;
    if (selectedTile.id && dataStore[selectedTile.id]) {
      setReport({
        ...report,
        tiles: report.tiles.map((t) =>
          t.id === selectedTile.id ? { ...t, ...v } : t
        ),
      });
      setDataStoreValue(selectedTile.id, {
        ...dataStore[selectedTile.id],
        raw: {
          ...dataStore[selectedTile.id].raw,
          ...v,
        },
      });
      if (selectedTile.id && dataStore[selectedTile.id].query) {
        this.debouncedTileUpdate(selectedTile.id, {
          ...v,
        });
      }
    }
  };

  onReportUpdate = (report: IReportOptionPayload) => {
    return GraphQLService(
      `
    mutation updateReport($reportId: ID!, $data: ReportUpdateInput!) {
      updateReport(id: $reportId, data: $data) {
        id
      } 
    }
    `,
      {
        reportId: this.props.report.id,
        data: {
          name: report.name,
          description: report.description,
          reportOptions: JSON.stringify(report.reportOptions),
        },
      }
    );
  };

  onFilterUpdate = async (filter: IFilter, shouldReloadQuery?: boolean) => {
    const {
      report,
      org,
      setReport,
      setFilterStoreValue,
      userAttributes,
      setShouldUpdateQuery,
      filterStore,
    } = this.props;

    const getDefaultValue = () => {
      if (!filter.defaultValue) {
        return null;
      } else if (Array.isArray(filter.defaultValue)) {
        return filter.defaultValue.join(FILTER_ARRAY_SEPARATOR);
      } else {
        return filter.defaultValue;
      }
    };

    const getFilterExploration = (): IExploration => {
      const explorationsFlatMap = this.props.explorations.flatMap((ex) =>
        ex.tables.flatMap((t) =>
          t.dimensions.flatMap((d) => ({
            value: `${t.cubeName}.${d.cubeName}`,
            domain: d.type === "standard" ? d.columnDomain : undefined,
            explorationId: ex.id,
          }))
        )
      );
      const explorationId = explorationsFlatMap.find(
        (efm) => efm.value === filter.valueDimension
      )?.explorationId;
      if (!explorationId) return null;
      return this.props.explorations.find((e) => e.id === explorationId);
    };

    setReport({
      ...report,
      filters: report.filters.map((f) => {
        if (f.id === filter.id) {
          return {
            ...filter,
            name: filter.name,
            labelDimension: filter.labelDimension
              ? filter.labelDimension
              : null,
            filterLimit: filter.filterLimit,
            exploration: getFilterExploration(),
            valueDimension: filter.valueDimension,
            apiName: filter.apiName,
            defaultTo: filter.defaultTo,
            defaultValue: filter.defaultValue ? filter.defaultValue : null,
            rawDefaultValue: getDefaultValue(),
            useDefaultTimeField: filter.valueDimension === "DEFAULT_TIME",
            type: filter.type,
            hidden: filter.hidden,
            requireValue: filter.requireValue,
            componentType: filter.componentType,
            tilesToUpdate: (filter.tilesToUpdate || [])
              .map<ITileToUpdate>((tu) => {
                return {
                  id: "",
                  dimensionToUpdate: tu.dimensionToUpdate,
                  tile: { id: tu.tile.id },
                } as ITileToUpdate;
              })
              .filter((tu) => tu.dimensionToUpdate !== "" && !!tu?.tile?.id),
            controlledBy: (filter.controlledBy || [])
              .map<IFilterControlledBy>((cb) => {
                return {
                  id: cb.id,
                  parent: { id: (cb as any).parent },
                  child: { id: (cb as any).child },
                  dimensionToUpdate: cb.dimensionToUpdate,
                } as IFilterControlledBy;
              })
              .filter((cb) => cb.dimensionToUpdate !== "" && !!cb?.parent?.id),
          };
        }
        return f;
      }),
    });

    const defaultValue = getFilterDefaultValue(
      { ...filter, defaultValue: getDefaultValue() as any },
      userAttributes
    );

    if (!_.isEqual(defaultValue, filterStore[filter.id]?.value)) {
      setFilterStoreValue(filter.id, {
        value: defaultValue,
        dimension: filter.valueDimension,
        type: filter.type,
        hidden: !!filter.hidden,
      });
      setShouldUpdateQuery(true);
    }

    return GraphQLService(UPDATE_FILTER, {
      id: filter.id,
      reportId: report.id,
      data: {
        name: filter.name,
        filterLimit: filter.filterLimit,
        labelDimension: filter.labelDimension ? filter.labelDimension : null,
        exploration: getFilterExploration()?.id
          ? {
              disconnectAll: true,
              connect: {
                id: getFilterExploration()?.id,
              },
            }
          : {
              disconnectAll: true,
            },
        valueDimension: filter.valueDimension,
        apiName: filter.apiName,
        defaultTo: filter.defaultTo,
        defaultValue: getDefaultValue(),
        useDefaultTimeField: filter.valueDimension === "DEFAULT_TIME",
        type: filter.type,
        hidden: filter.hidden,
        requireValue: filter.requireValue,
        componentType: filter.componentType,
        tilesToUpdate: filter.tilesToUpdate
          ? {
              disconnectAll: true,
              create: (filter.tilesToUpdate || [])
                .map((tu) => {
                  return {
                    dimensionToUpdate: tu.dimensionToUpdate,
                    tile: { connect: { id: tu.tile.id } },
                    org: { connect: { id: org.id } },
                  };
                })
                .filter(
                  (tu) => tu.dimensionToUpdate !== "" && tu.tile?.connect?.id
                ),
            }
          : null,
        controlledBy: filter.controlledBy
          ? {
              disconnectAll: true,
              create: (filter.controlledBy || [])
                .map((cb) => {
                  return {
                    dimensionToUpdate: cb.dimensionToUpdate,
                    parent: { connect: { id: cb.parent } },
                    child: { connect: { id: filter.id } },
                    org: { connect: { id: org.id } },
                  };
                })
                .filter(
                  (cb) =>
                    cb.dimensionToUpdate &&
                    cb.dimensionToUpdate !== "" &&
                    cb.parent?.connect?.id &&
                    cb.child?.connect?.id
                ),
            }
          : null,
      },
    });
  };

  onFiltersUpdate = (filters: IFilter[]) => {
    return GraphQLService(
      `mutation updateFilters($data: [FiltersUpdateInput]!) {
        updateFilters(data: $data) {
          id
        }  
      }`,
      {
        data: filters.map((f) => {
          return {
            id: f.id,
            data: {
              order: f.order,
            },
          };
        }),
      }
    );
  };

  debouncedTileOptions = _.debounce(this.updateTileInfo, 500);
  debouncedChartOptions = _.debounce(this.updateChartOptions, 500);
  debouncedTileUpdate = _.debounce(this.onTileUpdate, 500);
  debouncedReportUpdate = _.debounce(this.onReportUpdate, 500);
  debouncedFilterUpdate = _.debounce(this.onFilterUpdate, 500);
  debouncedFiltersUpdate = _.debounce(this.onFiltersUpdate, 500);

  renderActionType = (): ChartEditionInitialData | undefined => {
    const {
      actionType,
      match: { params },
      org,
      report,
      explorations,
      reloadReport,
      setExplorations,
      setDataStoreValue,
      dataStore,
    } = this.props;

    if (!actionType) {
      return undefined;
    }
    switch (actionType) {
      case "CREATE_TILE":
        if (!params.explorationSlug) {
          throw new Error("Exploration slug must be defined...");
        }
        return {
          actionType: "CREATE_TILE",
          explorationSlug: this.props.match.params.explorationSlug,
          onSave: async (q, e) => {
            await GraphQLService(CREATE_TILE, {
              data: {
                slug: "tile",
                name: generateTileTitle(q, e),
                content: JSON.stringify({
                  ...q,
                  dateRange: convertWlyDatePickerValueToString(q.dateRange),
                }),
                width: getTileDimension(q.chartType, q).width,
                height: getTileDimension(q.chartType, q).height,
                left: 0,
                top: 1000000,
                type: "chart",
                analysisType: q.analysisType,
                chartType: q.chartType,
                org: {
                  connect: {
                    id: org.id,
                  },
                },
                report: {
                  connect: {
                    id: report.id,
                  },
                },
                exploration: {
                  connect: {
                    id: e.id,
                  },
                },
              },
              reportId: report.id,
            })
              .then(() => {
                return GraphQLService(RELOAD_EXPLORATION, {
                  explorationId: e.id,
                }).then((ex) => ex.Exploration as IExploration);
              })
              .then((explo) => {
                const isInReferencial = explorations.find(
                  (e) => e.id === explo.id
                );
                if (isInReferencial) {
                  const newExplorations = explorations.map((ex) => {
                    if (ex.id === explo.id) {
                      return explo;
                    }
                    return ex;
                  });
                  setExplorations(newExplorations);
                } else {
                  setExplorations([...explorations, explo]);
                }

                return reloadReport(report.slug, org.id);
              })
              .then(() => {
                this.scrollToBottom();
                this.props.saving(false);
              })
              .catch(() => {
                this.props.saving(false);
              });
          },
          onCancel: async (e: IExploration) => {
            if (!e) return;
            const refreshData = async () => {
              const data = await GraphQLService<{ Exploration: IExploration }>(
                RELOAD_EXPLORATION,
                {
                  explorationId: e.id,
                }
              );

              const newExploration = data.Exploration;

              const isInReferencial = explorations.find(
                (e) => e.id === newExploration.id
              );
              if (isInReferencial) {
                const newExplorations = explorations.map((ex) => {
                  if (ex.id === newExploration.id) {
                    return newExploration;
                  }
                  return ex;
                });
                setExplorations(newExplorations);
              } else {
                setExplorations([...explorations, newExploration]);
              }

              await reloadReport(report.slug, org.id);
            };
            refreshData();
          },
        };
      case "EDIT_TILE":
        const editingTile = report.tiles.find(
          (t) => t.slug === this.props.match.params.tileSlug
        );
        if (!editingTile) {
          throw new Error("Unknown tile");
        }
        return {
          actionType: "EDIT_TILE",
          tile: editingTile,
          onSave: async (t, q, e) => {
            this.props.saving(true);
            await this.onTileUpdate(t.id, {
              name: t.name,
              chartType: q.chartType,
              analysisType: q.analysisType,
              content: JSON.stringify({
                ...q,
                dateRange: convertWlyDatePickerValueToString(q.dateRange),
              }),
            })
              .then(() => {
                return GraphQLService(RELOAD_EXPLORATION, {
                  explorationId: e.id,
                }).then((ex) => ex.Exploration as IExploration);
              })
              .then((exp) => {
                const isInReferencial = explorations.find(
                  (e) => e.id === exp.id
                );
                if (isInReferencial) {
                  const newExplorations = explorations.map((ex) => {
                    if (ex.id === exp.id) {
                      return exp;
                    }
                    return ex;
                  });
                  setExplorations(newExplorations);
                } else {
                  setExplorations([...explorations, exp]);
                }

                setDataStoreValue(t.id!, {
                  ...dataStore[t.id!],
                  query: q,
                  explorationId: t.exploration.id,
                  data: {
                    status: "initial",
                  },
                });

                return reloadReport(report.slug, org.id);
              })
              .then(() => {
                this.props.saving(false);
              })
              .catch(() => {
                this.props.saving(false);
              });
          },
          onCancel: async (e) => {
            if (!e) return;
            const refreshData = async () => {
              const data = await GraphQLService<{ Exploration: IExploration }>(
                RELOAD_EXPLORATION,
                {
                  explorationId: e.id,
                }
              );

              const newExploration = data.Exploration;

              const isInReferencial = explorations.find(
                (e) => e.id === newExploration.id
              );
              if (isInReferencial) {
                const newExplorations = explorations.map((ex) => {
                  if (ex.id === newExploration.id) {
                    return ex;
                  }
                  return ex;
                });
                setExplorations(newExplorations);
              } else {
                setExplorations([...explorations, newExploration]);
              }

              await reloadReport(report.slug, org.id);
            };
            refreshData();
          },
        };
      case "EXPLORE":
        const exploringTile = report.tiles.find(
          (t) => t.slug === this.props.match.params.tileSlug
        );
        if (!exploringTile) {
          throw new Error("Unknown tile");
        }

        return {
          actionType: "EXPLORE",
          tile: exploringTile,
          filters: this.props.filterStore,
          onAddToReport: async () => {
            this.props.saving(true);
            await reloadReport(report.slug, org.id);
            this.props.saving(false);
          },
          onCancel: async (e) => {
            if (!e) return;

            const refreshData = async () => {
              const data = await GraphQLService<{ Exploration: IExploration }>(
                RELOAD_EXPLORATION,
                {
                  explorationId: e.id,
                }
              );

              const newExploration = data.Exploration;

              const isInReferencial = explorations.find(
                (e) => e.id === newExploration.id
              );
              if (isInReferencial) {
                const newExplorations = explorations.map((ex) => {
                  if (ex.id === newExploration.id) {
                    return ex;
                  }
                  return ex;
                });
                setExplorations(newExplorations);
              } else {
                setExplorations([...explorations, newExploration]);
              }

              await reloadReport(report.slug, org.id);
            };
            refreshData();
          },
        };
    }
  };

  public renderChartPanel = () => {
    const { dataStore, setReport, report, selected, explorations } = this.props;

    const renderChartOption = (selectedTile: ITile) => {
      if (
        dataStore[selectedTile.id] &&
        (dataStore[selectedTile.id].data.status === "initial" ||
          dataStore[selectedTile.id].data.status === "loading")
      ) {
        return (
          <Feednack>
            <Loading />
          </Feednack>
        );
      } else if (
        dataStore[selectedTile.id] &&
        dataStore[selectedTile.id].data.status === "error"
      ) {
        return (
          <Feednack>
            Please check your tile, there seems to be an error somewhere...
          </Feednack>
        );
      } else {
        const currentExplorationId = dataStore[selectedTile.id].explorationId;
        const foundCurrentExploration = explorations.find(
          (e) => e.id === currentExplorationId
        );
        let availableDimensions: AvailableDimension[] = [];
        let availableMetrics: AvailableMetric[] = [];
        if (foundCurrentExploration) {
          foundCurrentExploration.tables.forEach((t) => {
            t.dimensions.forEach((d) => {
              availableDimensions.push({
                description: d.description,
                domain: (d as IStandardDimension).columnDomain
                  ? (d as IStandardDimension).columnDomain
                  : "STRING",
                key: `${t.cubeName}.${d.cubeName}`,
                label: d.name,
                type: d.type,
              });
            });
            t.metrics.forEach((m) => {
              availableMetrics.push({
                description: m.description,
                formatter: {
                  prefix: m.prefix,
                  suffix: m.suffix,
                  format: m.format,
                  customFormatting: m.overrideFormatting,
                },
                key: `${t.cubeName}.${m.cubeName}`,
                label: m.name,
              });
            });
          });
        }
        return (
          <ChartOptionPanel
            tile={dataStore[selectedTile.id].raw}
            meta={dataStore[selectedTile.id].meta}
            currentChartType={dataStore[selectedTile.id].chartType}
            lagoonQuery={dataStore[selectedTile.id].query}
            onTileInfoChange={(v) => this.debouncedTileOptions(selectedTile, v)}
            onOptionsChange={(v) => this.debouncedChartOptions(selectedTile, v)}
            availableDimensions={availableDimensions}
            availableMetrics={availableMetrics}
          />
        );
      }
    };

    if (!selected) {
      // we are in a situation where we can update the dashboard
      return (
        <DashboardOptionEdit
          report={report}
          onReportUpdate={(r) => {
            this.debouncedReportUpdate(r);
            setReport({
              ...report,
              name: r.name,
              description: r.description,
              reportOptions: JSON.stringify(r.reportOptions),
            });
          }}
        />
      );
    }

    if (selected.type === "FILTER") {
      const currentFilter = report.filters.find((d) => d.id === selected.id);
      const assignedReport = Object.assign(report);
      if (!currentFilter) {
        return <div>Filter not found</div>;
      }
      return (
        <FilterOptionsEdit
          filter={currentFilter}
          explorations={explorations}
          onFilterUpdate={this.debouncedFilterUpdate}
          report={assignedReport}
          scrollToId={this.scrollToId}
        />
      );
    }

    const selectedTile =
      selected.type === "TILE"
        ? report.tiles.find((t) => t.id === selected.id)
        : undefined;
    if (!selectedTile) {
      return <Feednack>Unknown selected tile</Feednack>;
    }
    switch (selectedTile.type) {
      case "text":
        return (
          <TextOptionEdit
            tile={selectedTile}
            onTileUpdate={(v) => {
              if (selectedTile.id) {
                setReport({
                  ...report,
                  tiles: report.tiles.map((t) =>
                    t.id === selectedTile.id ? { ...t, ...v } : t
                  ),
                });
                if (selectedTile.id) {
                  this.debouncedTileUpdate(selectedTile.id, {
                    ...v,
                  });
                }
              }
            }}
          />
        );
      case "chart":
        return renderChartOption(selectedTile);
      case "navigation":
        return (
          <NavigationOptionEdit
            tile={selectedTile}
            onTileUpdate={(v) => {
              if (selectedTile.id) {
                setReport({
                  ...report,
                  tiles: report.tiles.map((t) =>
                    t.id === selectedTile.id ? { ...t, ...v } : t
                  ),
                });
                if (selectedTile.id) {
                  this.debouncedTileUpdate(selectedTile.id, {
                    ...v,
                  });
                }
              }
            }}
          />
        );
      default:
        return <Feednack>Unknown tile type</Feednack>;
    }
  };

  public render() {
    const {
      onChartAddition,
      disableNavigationItems,
      fetched,
      editing,
      history,
      match: { params },
      hideFilters,
      hideLayout,
      report,
      shouldUpdateQueries,
      reloadReport,
      saving,
      org,
      explorations,
      filterStore,
      setReport,
      dataStore,
      setDataStoreValue,
      selected,
      setShouldUpdateQuery,
      setFilterStoreValue,
      setAddingNewText,
      setAddingNewFilter,
      addingNewText,
      addingNewFilter,
      refreshReport,
      setAddingNewNavigation,
      addingNewNavigation,
      onOpenConsole,
      onEditClick,
      onCancelClick,
      isDisplayedInWorkspace,
      isEmbedded,
      isSharingLink,
      forceCacheRefresh,
      setExplorations,
      onRefresh,
    } = this.props;

    const { blinkingTileId } = this.state;

    return (
      <>
        {editing && (
          <DashboardEditTopActionBar
            hideLayout={hideLayout}
            report={report}
            onCancelClick={onCancelClick}
            onOpenConsole={onOpenConsole}
            setReport={setReport}
          />
        )}
        <div className="report-content-wrapper">
          {editing && (
            <DashboarEditAddChartDrawer
              open={this.state.editAddChartDrawerOpen}
              onSelectExploration={(e) => {
                onChartAddition(e.slug);
                this.setState({ editAddChartDrawerOpen: false });
              }}
              setOpen={(open: boolean) => {
                this.setState({ editAddChartDrawerOpen: open });
              }}
            />
          )}
          {editing && (
            <DashboardEditLeftActionBar
              isAddChartOpen={this.state.editAddChartDrawerOpen}
              onAddChartClick={() => {
                this.setState((state) => {
                  return {
                    editAddChartDrawerOpen: !state.editAddChartDrawerOpen,
                  };
                });
              }}
              onAddFilterClick={() => setAddingNewFilter(true)}
              onAddTextClick={() => setAddingNewText(true)}
              onAddNavigationClick={() => setAddingNewNavigation(true)}
            />
          )}
          <div
            onClick={() => {
              if (editing) {
                history.push(
                  routeDescriptor["reportEdit"].renderRoute({
                    ...params,
                    tileSlug: undefined,
                  })
                );
              }
            }}
            className={`report-content-container report-content-container-dashboard ${
              hideLayout ? "full-height" : ""
            } ${editing ? "report-content-container-editing" : null}`}
          >
            {!hideLayout && (
              <div
                style={{
                  margin: editing || isSharingLink ? `0 -12px` : "0 -24px",
                }}
              >
                <DashboardContentHeader
                  report={report}
                  editing={editing}
                  onEditClick={onEditClick}
                  isDisplayedInWorkspace={isDisplayedInWorkspace}
                />
              </div>
            )}
            <div className="report-content-body" ref={this.reportContent}>
              <Space
                direction="vertical"
                style={{ width: "100%", paddingTop: 12 }}
                size={12}
                className="screenshot-container"
              >
                <DashboardFilters
                  editing={editing}
                  hideFilters={hideFilters}
                  explorations={explorations}
                  filterStore={filterStore}
                  report={report}
                  fetched={fetched}
                  forceCacheRefresh={forceCacheRefresh}
                  shouldUpdateQueries={shouldUpdateQueries}
                  refreshReport={refreshReport}
                  reloadReport={reloadReport}
                  saving={saving}
                  onOpenConsole={onOpenConsole}
                  setSelected={(id: string) => {
                    this.props.setSelected({ type: "FILTER", id: id });
                  }}
                  setReport={(r) => {
                    setReport(r);
                    this.debouncedFiltersUpdate(r.filters);
                  }}
                  setFilterStoreValue={setFilterStoreValue}
                  setShouldUpdateQuery={setShouldUpdateQuery}
                  isEmbedded={isEmbedded}
                />

                {this.props.showTitle && (
                  <DashboardContentHeader
                    isDisplayedInWorkspace={isDisplayedInWorkspace}
                    report={report}
                    small
                  />
                )}
                <DashboardGrid
                  disableNavigationItems={disableNavigationItems}
                  editing={editing}
                  isEmbedded={this.props.isEmbedded}
                  report={report}
                  reloadReport={reloadReport}
                  saving={saving}
                  setReport={setReport}
                  dataStore={dataStore}
                  setDataStoreValue={setDataStoreValue}
                  selected={
                    selected && selected.type === "TILE"
                      ? selected.id
                      : undefined
                  }
                  filterStore={filterStore}
                  setSelected={this.setSelected}
                  setShouldUpdateQuery={setShouldUpdateQuery}
                  addingNewText={addingNewText}
                  setAddingNewText={setAddingNewText}
                  scrollToBottom={this.scrollToBottom}
                  onGridUpdate={this.onGridUpdate}
                  onOpenConsole={onOpenConsole}
                  disableDrills={this.props.disableDrills}
                  blinkingTileId={blinkingTileId}
                  getFilterStoreValues={this.getFilterStoreValues}
                  renderOutsideViewport={this.props.isBeingPushed}
                  externalWorker={this.props.externalWorker}
                  onRefresh={onRefresh}
                />
              </Space>
            </div>
          </div>
          {editing && (
            <DashboardEditRightActionBar>
              {this.renderChartPanel()}
            </DashboardEditRightActionBar>
          )}
        </div>
        <ChartEditionDrawer
          visible={!!this.props.actionType}
          report={this.props.report}
          setVisible={(b) => {
            // we should find a better way to avoid reloading the whole dashboard and just reload the tiles that have change but I have no idea on how to do it now ...
            refreshReport();
            if (editing && selected) {
              return history.push(
                routeDescriptor["reportTileEdit"].renderRoute({
                  ...this.props.match.params,
                })
              );
            } else if (editing) {
              return history.push(
                routeDescriptor["reportEdit"].renderRoute({
                  ...this.props.match.params,
                })
              );
            }
            return history.push(
              routeDescriptor["report"].renderRoute({
                ...this.props.match.params,
              })
            );
          }}
          embededWorkbench={this.props.embededWorkbench}
          initialData={this.renderActionType()}
        />
        {addingNewFilter && (
          <FilterEdition
            onSave={(v, e) => {
              this.props.saving(true);
              const defaultValue = v.defaultValue;
              if (!v.id) {
                if (e && !explorations.find((a) => a.id === e.id)) {
                  setExplorations([...explorations, e]);
                }
                return GraphQLService(UPDATE_QUERY, {
                  id: report.id,
                  reportId: report.id,
                  data: {
                    filters: {
                      create: {
                        name: v.filterName,
                        labelDimension: v.labelDimension,
                        valueDimension: v.valueDimension,
                        apiName: v.apiName,
                        defaultValue: defaultValue
                          ? defaultValue.join(FILTER_ARRAY_SEPARATOR)
                          : null,
                        useDefaultTimeField: v.useDefaultTimeField,
                        type: v.filterType,
                        hidden: v.hidden,
                        requireValue: v.requireValue,
                        componentType: v.componentType,
                        order: report.filters.length,
                        exploration: e
                          ? {
                              connect: {
                                id: e.id,
                              },
                            }
                          : undefined,
                        tilesToUpdate: {
                          create: v.tilesToUpdate
                            .map((tu) => {
                              return {
                                dimensionToUpdate: tu.dimensionToUpdate,
                                tile: { connect: { id: tu.tileId } },
                                org: { connect: { id: org.id } },
                              };
                            })
                            .filter((tu) => tu.dimensionToUpdate !== ""),
                        },
                        org: { connect: { id: org.id } },
                      },
                    },
                  },
                })
                  .then((r) => {
                    return reloadReport(report.slug, org.id);
                  })
                  .then(() => {
                    this.props.saving(false);
                    setAddingNewFilter(false);
                  })
                  .catch((err) => {
                    this.props.saving(false);
                  });
              } else {
                throw new Error("not implemented");
              }
            }}
            onCancel={() => setAddingNewFilter(false)}
            tiles={report.tiles}
          />
        )}
        {addingNewText && (
          <TextEdition
            onSave={async (data) => {
              this.props.saving(true);
              if (data.id) {
                // edition
                await this.onTileUpdate(data.id, {
                  name: data.name,
                  content: data.content,
                });
              } else {
                await GraphQLService(CREATE_TILE_FULL, {
                  data: {
                    name: data.name,
                    content: data.content,
                    width: 12,
                    height: 8,
                    left: 0,
                    top: 1000000,
                    type: "text",
                    analysisType: "METRIC",
                    chartType: "kpi",
                    org: {
                      connect: {
                        id: org.id,
                      },
                    },
                    report: {
                      connect: {
                        id: report.id,
                      },
                    },
                  },
                  reportId: report.id,
                })
                  .then((r) => {
                    setReport({
                      ...report,
                      tiles: [...report.tiles, r.createTile],
                      updatedAt: r.updateReport.updatedAt,
                      updatedBy: r.updateReport.updatedBy,
                    });
                    setAddingNewText(false);
                    this.props.saving(false);
                    this.scrollToBottom();
                  })
                  .catch(() => {
                    this.props.saving(false);
                  });
              }
            }}
            onCancel={() => setAddingNewText(false)}
            initialData={
              typeof addingNewText === "boolean"
                ? undefined
                : {
                    id: addingNewText.id,
                    name: addingNewText.name,
                    content: addingNewText.content,
                  }
            }
          />
        )}
        {addingNewNavigation && (
          <NavigationEdition
            onSave={async (data) => {
              this.props.saving(true);
              if (data.id) {
                // edition
                await this.onTileUpdate(data.id, {
                  name: data.name,
                });
              } else {
                await GraphQLService(CREATE_TILE_FULL, {
                  data: {
                    name: data.name,
                    width: MIN_WIDTH,
                    height: MIN_HEIGHT,
                    content: "",
                    left: 0,
                    top: 1000000,
                    type: "navigation",
                    analysisType: "METRIC",
                    chartType: "kpi",
                    org: {
                      connect: {
                        id: org.id,
                      },
                    },
                    report: {
                      connect: {
                        id: report.id,
                      },
                    },
                  },
                  reportId: report.id,
                })
                  .then((r) => {
                    setReport({
                      ...report,
                      tiles: [...report.tiles, r.createTile],
                      updatedAt: r.updateReport.updatedAt,
                      updatedBy: r.updateReport.updatedBy,
                    });
                    setAddingNewNavigation(false);
                    this.props.saving(false);
                    this.scrollToBottom();
                  })
                  .catch(() => {
                    this.props.saving(false);
                  });
              }
            }}
            onCancel={() => setAddingNewNavigation(false)}
            initialData={
              typeof addingNewText === "boolean"
                ? undefined
                : {
                    id: addingNewText.id,
                    name: addingNewText.name,
                  }
            }
          />
        )}
      </>
    );
  }
}

export default compose<Props, IDashboardContentProps>(
  withRouter,
  WithOrg
)(observer(DashboardContent));
