import Icon from "@ant-design/icons";
import type { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";

export const WlySpacerIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon
    component={() => (
      <svg
        width="1em"
        height="1em"
        viewBox="0 0 213 213"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M175 152.37C175 147.695 173.143 143.211 169.836 139.904C166.53 136.598 162.046 134.741 157.37 134.741H56.6296C51.954 134.741 47.4698 136.598 44.1636 139.904C40.8574 143.211 39 147.695 39 152.37V180.074H49.1111V146.37C49.1111 145.702 49.3765 145.062 49.8488 144.589C50.3211 144.117 50.9617 143.852 51.6296 143.852H162.37C163.038 143.852 163.679 144.117 164.151 144.589C164.624 145.062 164.889 145.702 164.889 146.37V180.074H175V152.37Z"
          fill="#5D6671"
        />
        <path
          d="M175 34H164.889V67.7037C164.889 68.3717 164.624 69.0123 164.151 69.4846C163.679 69.9569 163.038 70.2222 162.37 70.2222H52.6296C51.9617 70.2222 51.3211 69.9569 50.8488 69.4846C50.3765 69.0123 50.1111 68.3717 50.1111 67.7037V34H39V61.7037C39 66.3794 40.8574 70.8635 44.1636 74.1697C47.4698 77.4759 51.954 79.3333 56.6296 79.3333H157.37C162.046 79.3333 166.53 77.4759 169.836 74.1697C173.143 70.8635 175 66.3794 175 61.7037V34Z"
          fill="#5D6671"
        />
        <path
          d="M152.852 99.4814H141.741C140.636 99.4814 139.741 100.377 139.741 101.481V112.593C139.741 113.697 140.636 114.593 141.741 114.593H152.852C153.956 114.593 154.852 113.697 154.852 112.593V101.481C154.852 100.377 153.956 99.4814 152.852 99.4814Z"
          fill="#5D6671"
        />
        <path
          d="M112.555 99.4814H101.444C100.34 99.4814 99.4443 100.377 99.4443 101.481V112.593C99.4443 113.697 100.34 114.593 101.444 114.593H112.555C113.66 114.593 114.555 113.697 114.555 112.593V101.481C114.555 100.377 113.66 99.4814 112.555 99.4814Z"
          fill="#5D6671"
        />
        <path
          d="M72.2593 99.4814H61.1482C60.0436 99.4814 59.1482 100.377 59.1482 101.481V112.593C59.1482 113.697 60.0436 114.593 61.1482 114.593H72.2593C73.3639 114.593 74.2593 113.697 74.2593 112.593V101.481C74.2593 100.377 73.3639 99.4814 72.2593 99.4814Z"
          fill="#5D6671"
        />
      </svg>
    )}
    {...props}
  />
);
