import { NotificationOutlined } from "@ant-design/icons";
import type { TreeDataNode } from "antd";
import { Space, Tag, Tree, Typography } from "antd";
import _ from "lodash";
import type { InjectedOrgProps } from "../../containers/orgs/WithOrg";
import WithOrg from "../../containers/orgs/WithOrg";
import type { INotificationWorkflow } from "../../interfaces/notification";
import type { ITopic } from "../../interfaces/topic";
import { arrayToTree } from "../../utils/arrayToTree";
import type { InjectedAntUtilsProps } from "../ant-utils/withAntUtils";
import { withAntUtils } from "../ant-utils/withAntUtils";
import { compose } from "../compose/WlyCompose";
import "./NotificationSubscriptionPicker.scss";

interface INotificationSubscriptionPickerProps {
  topics: ITopic[];
  workflows: INotificationWorkflow[];
  values: { topicId: string; workflowId: string }[];
  onChange: (
    selectedRowKeys: { topicId: string; workflowId: string }[]
  ) => void;
}

interface DataType {
  topicId: string;
  topicName: string;
  topicGroupId: string | undefined;
  topicGroupName: string | undefined;
  workflowId: string;
  workflowName: string;
  allowedWorkflows?: string[];
}

type Props = INotificationSubscriptionPickerProps &
  InjectedAntUtilsProps &
  InjectedOrgProps;

function NotificationSubscriptionPicker(props: Props) {
  const { topics, workflows, values, onChange } = props;

  const availableTopics = topics.map((topic) => ({
    topicId: topic.id,
    topicName: topic.name,
    topicGroupId: topic.topicGroup?.id,
    topicGroupName: topic.topicGroup?.name,
    allowedWorkflows: (topic.topicGroup.workflowSelections ?? []).map(
      (ws) => ws.workflow.id
    ),
  }));

  const uniqGroups = _.uniqBy(
    availableTopics.map((at) => ({
      id: at.topicGroupId,
      name: at.topicGroupName,
    })),
    (e) => e.id
  );

  const availableTopicGroups: { id: string; name: string }[] =
    uniqGroups.filter(
      (item): item is { id: string; name: string } => !!item.id && !!item.name
    );

  const availableWorkflows = workflows.map((workflow) => ({
    workflowId: workflow.id,
    workflowName: workflow.name,
  }));

  const getAvailableSubscriptions = (
    availableTopics: Pick<
      DataType,
      | "topicId"
      | "topicName"
      | "topicGroupId"
      | "topicGroupName"
      | "allowedWorkflows"
    >[],
    availableWorkflows: Pick<DataType, "workflowId" | "workflowName">[]
  ): DataType[] => {
    const result: DataType[] = [];
    availableTopics.forEach((topic) => {
      availableWorkflows.forEach((workflow) => {
        const mergedObj: DataType = {
          ...topic,
          ...workflow,
        };
        if ((topic.allowedWorkflows ?? []).includes(workflow.workflowId)) {
          result.push(mergedObj);
        }
      });
    });
    return result;
  };

  const availableSubscriptions: DataType[] = getAvailableSubscriptions(
    availableTopics,
    availableWorkflows
  );

  const flatTreeData: TreeDataNode[] = [
    ...availableTopicGroups.map((atg) => ({
      key: `group_${atg.id}`,
      title: <Typography.Text strong>{atg.name ?? atg.id}</Typography.Text>,
      className: "wly-tree-level-1",
      parent: undefined,
      checkable: false,
    })),
    ...availableTopics.map((at) => ({
      key: `topic_${at.topicId}`,
      title: (
        <Space>
          <Typography.Text strong>{at.topicName}</Typography.Text>
          {values.map((v) => v.topicId).includes(at.topicId) && (
            <Tag color="blue">
              <Space size={"small"}>
                <NotificationOutlined />
                Subscribed
              </Space>
            </Tag>
          )}
        </Space>
      ),
      className: "wly-tree-level-2",
      parent: `group_${at.topicGroupId}`,
      checkable: false,
    })),
    ...availableSubscriptions.map((as) => ({
      key: `${as.topicId}-${as.workflowId}`,
      title: as.workflowName,
      parent: `topic_${as.topicId}`,
      checkable: true,
    })),
  ];

  const treeData = arrayToTree(flatTreeData, {
    childrenField: "children",
    dataField: null,
    id: "key",
    parentId: "parent",
  });

  return (
    <Tree
      className="notification-subscription-picker-tree"
      treeData={treeData}
      blockNode
      checkable
      selectable={false}
      defaultExpandedKeys={[
        ...availableTopicGroups.map((atg) => `group_${atg.id}`),
        ...values.map((v) => `${v.topicId}-${v.workflowId}`),
      ]}
      checkedKeys={values.map((v) => `${v.topicId}-${v.workflowId}`)}
      onCheck={(v) =>
        v instanceof Array
          ? onChange(
              v.map((i) => {
                const [topicId, workflowId] = i.toString().split("-");
                return { topicId, workflowId };
              })
            )
          : undefined
      }
      defaultExpandParent
    />
  );
}

export default compose<Props, INotificationSubscriptionPickerProps>(
  withAntUtils,
  WithOrg
)(NotificationSubscriptionPicker);
