import Highcharts from "highcharts";
import { HighchartsReact } from "highcharts-react-official";
import _ from "lodash";
import React from "react";

interface SparkLineProps {
  data: number[];
  color: string;
  width: number;
  height: number;
}

class SparkLine extends React.Component<SparkLineProps> {
  ref: React.RefObject<{
    chart: Highcharts.Chart;
    container: React.RefObject<HTMLDivElement>;
  }>;

  constructor(props) {
    super(props);
    this.ref = React.createRef();
  }

  componentDidUpdate(prevProps: SparkLineProps) {
    if (
      this.props.width !== prevProps.width ||
      this.props.height !== prevProps.height
    ) {
      setTimeout(() => {
        this.ref.current.chart.reflow();
      }, 200);
    }
  }

  shouldComponentUpdate(
    nextProps: Readonly<SparkLineProps>,
    nextState: Readonly<{}>,
    nextContext: any
  ): boolean {
    if (!_.isEqual(this.props.color, nextProps.color)) return true;
    if (!_.isEqual(this.props.width, nextProps.width)) return true;
    if (!_.isEqual(this.props.height, nextProps.height)) return true;
    if (!_.isEqual(this.props.data, nextProps.data)) return true;
    return false;
  }

  render() {
    const { data, color, height } = this.props;

    const defaultOptions: Highcharts.Options = {
      chart: {
        backgroundColor: null,
        height: height,
        borderWidth: 0,
        margin: 0,
        marginBottom: 1,
        type: "area",
        style: {
          overflow: "visible",
        },
      },
      title: {
        text: "",
      },
      credits: {
        enabled: false,
      },
      xAxis: {
        visible: false,
        labels: {
          enabled: false,
        },
        title: {
          text: null,
        },
        startOnTick: false,
        endOnTick: false,
        tickPositions: [],
      },
      yAxis: {
        endOnTick: false,
        startOnTick: false,
        labels: {
          enabled: false,
        },
        title: {
          text: null,
        },
        tickPositions: [0],
        visible: false,
      },
      legend: {
        enabled: false,
      },
      tooltip: {
        enabled: false,
      },
      accessibility: {
        enabled: false,
      },
      plotOptions: {
        series: {
          animation: false,
          borderWidth: 0,
          lineWidth: 1,
          shadow: false,
          clip: false,
          states: {
            hover: {
              lineWidth: 1,
            },
          },
          marker: {
            enabled: false,
            states: {
              select: {
                enabled: false,
              },
              hover: {
                enabled: false,
                radius: 0,
                width: 0,
              },
            },
          },
        },
      },
      series: [
        {
          data: data.map((_, i, s) => {
            if (i === s.length - 1) {
              return [
                _,
                { enabled: true, radius: 4, width: 0, color: "black" },
              ];
            }
            return [_, { enabled: false }];
          }),
          keys: ["y", "marker"],
          state: {
            normal: {
              animation: false,
            },
          },
          ...({
            fillColor: {
              linearGradient: {
                x1: 0,
                y1: 0,
                x2: 0,
                y2: 1,
              },
              stops: [
                [0, Highcharts.color(color!).setOpacity(0.5).get("rgba")],
                [1, Highcharts.color(color!).setOpacity(0).get("rgba")],
              ],
            },
          } as any),
          color: Highcharts.color(color!).setOpacity(0.5).get("rgba"),
        } as any,
      ],
    };

    return (
      <div style={{ width: "100%", height: height }}>
        <HighchartsReact
          ref={this.ref}
          highcharts={Highcharts}
          options={defaultOptions}
        />
      </div>
    );
  }
}

export default SparkLine;
