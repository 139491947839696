import type { Query } from "@cubejs-client/core";
import { ResponsiveFunnel } from "@nivo/funnel";
import React, { useEffect, useState } from "react";
import type { ChartOption } from "../../../containers/chart-options/ChartOptions";
import type { UserLocale } from "../../../interfaces/user";
import type { Formatter } from "../domain";
import { customFormatter } from "../utils/optionsHelper";
import "./Funnel.scss";

const FUNNEL_MARGIN_TOP = 10;
const FUNNEL_MARGIN_BOTTOM = 20;
const VERTICAL_FUNNEL_LABEL_WIDTH = 200;

export type FunnelChartDirection = "vertical" | "horizontal";
export type FunnelCompareWith = "first-value" | "previous-value";

interface IFunnelChartProps {
  height: number;
  data: Array<{ [key: string]: string | number | boolean }>;
  config: FunnelChartConfig;
  tinyLegend?: boolean;
  scale?: "time" | "ordinal";
  width?: number;
  onDrill?: (q: Query) => void;
  chartOptions?: ChartOption;
  locale: UserLocale;
}

interface FunnelChartConfig {
  x: {
    key: string;
    label?: string;
  };
  y: Array<{
    key: string;
    label?: string;
    color?: string;
    canDrill: (xValue: string, yValue?: string) => Query | null;
    dashed?: boolean;
    formatter: Formatter;
  }>;
  showLabels?: boolean;
}

const formatData = (data, config) => {
  return config.y.map((line) => {
    return {
      id: line.key,
      label: line.label,
      value: data[0][line.key],
    };
  });
};

const generateGridAreas = (formattedData, direction: FunnelChartDirection) => {
  let gridArea = `"${formattedData
    .map((d, i) => {
      return `label${i}`;
    })
    .join(" ")}"`;

  if (direction === "vertical") {
    gridArea = `${formattedData
      .map((d, i) => {
        return `"label${i}"`;
      })
      .join("\n")}`;
  }

  return gridArea;
};

const generateLabels = (formattedData, compareWith: FunnelCompareWith) => {
  return formattedData.map((d, i) => {
    let isFirst = false;
    if (!formattedData[i - 1]) isFirst = true;
    let percent = 100;
    if (!isFirst) {
      const comparedWith =
        compareWith === "first-value"
          ? formattedData[0].value
          : formattedData[i - 1].value;
      percent = (d.value / comparedWith) * 100;
    }
    return {
      key: d.id,
      title: d.label,
      value: d.value,
      percentage: isNaN(percent) ? "" : `${percent.toFixed(1)}%`,
    };
  });
};

const getBeforeSeparatorLength = (
  showLabels: boolean,
  direction: FunnelChartDirection
) => {
  if (!showLabels) {
    return 10;
  } else if (direction === "vertical") {
    return VERTICAL_FUNNEL_LABEL_WIDTH;
  } else {
    return 90;
  }
};

export default function FunnelChart(props: IFunnelChartProps) {
  const { data, config, height, chartOptions, onDrill, locale } = props;
  const [formattedData, setFormattedData] = useState(formatData(data, config));
  const [colors, setColors] = useState([]);
  const [showLabels, setShowLabels] = useState(config.showLabels);
  const [labels, setLabels] = useState([]);

  const direction = chartOptions?.["funnel-direction"] ?? "horizontal";
  const compareWith = chartOptions?.["funnel-compare-with"] ?? "first-value";

  useEffect(() => {
    setFormattedData(formatData(data, config));
    setColors(config.y.map((line) => line.color));
    setShowLabels(chartOptions?.label);
  }, [data, config, chartOptions]);

  useEffect(() => {
    setLabels(generateLabels(formattedData, compareWith));
  }, [formattedData, compareWith]);

  const tooltip = ({ part }) => {
    return (
      <div
        style={{
          padding: 10,
          fontSize: 13,
          color: "#fff",
          background: "#2E363B",
          borderRadius: 5,
        }}
      >
        {part.data.label}
        <br />
        <strong>
          {customFormatter(
            part.formattedValue,
            locale,
            config.y.find((item) => item.key === part.data.id).formatter
          )}
        </strong>
      </div>
    );
  };

  const onMouseEnter = (data, event: React.MouseEvent<HTMLCanvasElement>) => {
    event.currentTarget.style.cursor = "pointer";
  };

  const onMouseLeave = (data, event: React.MouseEvent<HTMLCanvasElement>) => {
    event.currentTarget.style.cursor = "auto";
  };

  const onClick = (part) => {
    const xValue = part.data.id.toString();
    const q = config.y
      .find((item) => item.key === part.data.id)
      .canDrill(
        xValue.toString(),
        part.data.value ? part.data.value.toString() : undefined
      );
    if (q) {
      return onDrill(q);
    }
  };

  return (
    <div style={{ height, width: "100%" }}>
      <div
        className={`funnel-grid ${direction}`}
        style={{
          gridTemplateColumns:
            direction === "horizontal"
              ? `repeat(${formattedData.length}, 1fr)`
              : "none",
          gridTemplateAreas: generateGridAreas(formattedData, direction),
          opacity: showLabels ? 1 : 0,
          height: height - FUNNEL_MARGIN_BOTTOM,
          width:
            direction === "horizontal" ? "100%" : VERTICAL_FUNNEL_LABEL_WIDTH,
        }}
      >
        {labels.map((label, i) => {
          return (
            <div
              key={i}
              className="funnel-label"
              style={{ gridArea: `label${i}` }}
            >
              <div className="funnel-metric">
                {customFormatter(
                  label.value,
                  locale,
                  config.y.find((item) => item.key === label.key).formatter
                )}
              </div>
              <div className="funnel-title">{label.title}</div>
              <div
                className="funnel-percentage"
                style={{ opacity: i === 0 ? 0 : 1 }}
              >
                {label.percentage}
              </div>
            </div>
          );
        })}
      </div>
      <div style={{ height: height }}>
        <ResponsiveFunnel
          data={formattedData}
          animate={false}
          direction={direction}
          margin={{ top: FUNNEL_MARGIN_TOP, bottom: FUNNEL_MARGIN_BOTTOM }}
          colors={colors}
          enableLabel={false}
          borderOpacity={0}
          borderWidth={0}
          beforeSeparatorLength={getBeforeSeparatorLength(
            showLabels,
            direction
          )}
          beforeSeparatorOffset={0}
          afterSeparatorLength={10}
          afterSeparatorOffset={0}
          currentPartSizeExtension={10}
          currentBorderWidth={10}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          onClick={onDrill ? onClick : undefined}
          motionConfig="stiff"
          tooltip={tooltip}
          layers={["separators", "parts", "labels", "annotations"]}
        />
      </div>
    </div>
  );
}
