import type { RouteComponentProps } from "react-router";
import { withRouter } from "react-router";
import { compose } from "../../components/compose/WlyCompose";
import {
  parseSearch,
  PORTAL_JWT_TOKEN_SEARCH_SETTINGS,
  STATE_SEARCH_SETTINGS,
} from "../../helpers/queryStringHelpers";
import { routeDescriptor } from "../../routes/routes";

interface ICallbackProps {}

const SEARCH_SETTINGS = [
  ...PORTAL_JWT_TOKEN_SEARCH_SETTINGS,
  ...STATE_SEARCH_SETTINGS,
];

export interface PortalLoginSearchSettings {
  token: string;
  expire_in: string;
  state?: string;
}

type Props = ICallbackProps & RouteComponentProps<{}>;

function PortalCallback(props: Props) {
  const {
    location: { search },
    history,
  } = props;

  const values = parseSearch<PortalLoginSearchSettings>(
    search,
    SEARCH_SETTINGS
  );

  history.replace(routeDescriptor.portalLogin.renderRoute({}), values);
  return <div />;
}

export default compose<Props, ICallbackProps>(withRouter)(PortalCallback);
