import moment from "moment";
import type { MeasureType } from "../../../components/measures/measure-table/MeasureTable";
import type {
  AsyncCachedData,
  AsyncData,
} from "../../../helpers/typescriptHelpers";
import type { IDestination } from "../../../interfaces/destinations";
import type { IExploration } from "../../../interfaces/explorations";
import type {
  IJobExecution,
  IRunResult,
} from "../../../interfaces/jobExecutions";
import type { IModelFolder } from "../../../interfaces/modelFolder";
import type {
  IObject,
  IObjectLayout,
  IObjectLayoutType,
} from "../../../interfaces/object";
import type {
  IDataset,
  IDatasetRelationship,
  IDatasetType,
  IsPersistedAsType,
} from "../../../interfaces/sources";
import type {
  SchemaResult,
  Transformation,
} from "../../../interfaces/transformations";
import type { IUser } from "../../../interfaces/user";
import type { IView } from "../../../interfaces/view";
import type { IWorksheet } from "../../../interfaces/worksheet";

export interface IActiveObjectDatasetUrlState {
  tab?: string;
  node_flow_selection?: string;
  view?: string;
}

interface IActiveObjectSettingsUrlState {}

export interface IActiveObjectObjectUrlState {
  tab?: string;
}

export interface IActiveObjectSourceUrlState {
  tab?: string;
  activeSource?: string;
}

interface ExplorationData {
  id: string;
  name: string;
  exploration: AsyncCachedData<IExploration>;
  usage: AsyncData<IExplorationMeasureUsage>;
  sectionEmoji?: string;
}

export interface IExplorationMeasureUsage {
  dimensions: ExplorationMeasureItemUsage[];
  metrics: ExplorationMeasureItemUsage[];
}

export interface ExplorationMeasureItemUsage {
  resourceId: string;
  foreignResourceId: string;
  reportSlug: string;
  reportName: string;
  reportType: string;
  usageType: "filter" | "query";
}

export interface ExplorationStore {
  [explorationId: string]: ExplorationData;
}

export interface ObjectData {
  id: string;
  name: string;
  object: AsyncCachedData<IObject>;
  layouts: {
    [id: string]: ObjectLayoutData;
  };
}

export interface ObjectLayoutData {
  id: string;
  name: string;
  type: IObjectLayoutType;
  data: AsyncCachedData<IObjectLayout>;
}

export interface ObjectStore {
  [objectId: string]: ObjectData;
}

export interface WorksheetStore {
  [worksheetId: string]: {
    id: string;
    worksheet: IWorksheet;
  };
}

export interface IActiveObjectExplorationUrlState {
  tab?: string;
  items?: Array<{ type: MeasureType; id: string }>;
}

export interface IActiveObjectLayoutUrlState {
  recordId?: string;
}

type IActiveObjectUrlState =
  | IActiveObjectDatasetUrlState
  | IActiveObjectObjectUrlState
  | IActiveObjectSettingsUrlState
  | IActiveObjectExplorationUrlState
  | IActiveObjectLayoutUrlState;

export type IActiveObjectSQLConfigUIState = {
  configDataExplorerIsOpen?: boolean;
  configEditorInitialState?: any;
  configEditorScrollLeft?: number;
  configEditorScrollTop?: number;
  configEditorHeight?: number;
  configEditorMaxHeight?: number;
  configEditorLastSavedQuery?: string;
  configEditorLastSuccessfulQuery?: string;
  configResultsScrollLeft?: number;
  configResultsScrollTop?: number;
  configResultsData?: AsyncData<{
    records: any;
    count: number;
    schema: SchemaResult;
  }>;
};

export type IActiveObjectFlowDatasetUIState = {
  configCanvasZoom?: number;
  configCanvasOffsetX?: number;
  configCanvasOffsetY?: number;
  configCanvasNodeSelection?: string;
  configOperation?: any;
};

export type IActiveObjectUIState =
  | IActiveObjectSQLConfigUIState
  | IActiveObjectFlowDatasetUIState;

export interface IActiveObject {
  type: IActiveObjectType;
  value: string;
  focused?: boolean;
  stale?: boolean;
  staleState?: object;
  urlState?: IActiveObjectUrlState;
  uiState?: IActiveObjectUIState;
}

export type IActiveObjectType =
  | "dataset"
  | "job"
  | "settings"
  | "exploration"
  | "worksheet"
  | "connector"
  | "object"
  | "object-layout"
  | "radar";

export interface TableData {
  list?: Array<{ [key: string]: any }>;
  schema?: SchemaResult;
  rowCount?: number;
}

export interface Store {
  raw: AsyncData<TableData>;
  cache?: TableData;
}

export interface TabData {
  id: string;
  slug: string;
  name: string;
  dataset: IDataset;
  views: IView[];
  primaryKey: string[];
  dependencyGraph: IDatasetLineageGraph;
  hasUpstreamErrors: boolean;
  runResults: Array<IRunResult>;
  hideFromInterface: boolean;
  foreignKeys: string[];
  userDefinedColumns: string[];
  warehouseTableId: string;
  warehouseSchemaId: string;
  type: IDatasetType;
  sql: string;
  head?: string;
  currentStack: Transformation[];
  deleted: boolean;
  currentDatasetResolverCursor: string;
  datasetQueryId: string;
  relationships: IDatasetRelationship[];
  folder: IModelFolder;
  isModel?: boolean;
  isDependency?: boolean;
  data: {
    [viewId: string]: IViewData;
  };
}

export interface IViewData {
  viewQueryId: string;
  currentViewResolverCursor: AsyncData<string>;
  currentStack: AsyncData<Transformation[]>;
  store: Store;
  schema: AsyncData<SchemaResult>;
  name: string;
  editable: boolean;
  cubeName: string;
  usedInExplorations: { name: string; slug: string }[];
  drills: string[];
  updatedBy: IUser;
  updatedAt: string;
}

export interface IDatasetDragItem {
  id: string;
}

export type IDatasetUpdate = (a: {
  name?: string;
  description?: string;
  sql?: string;
  transformations?: string;
  head?: string;
  primaryKey?: string;
  warehouseViewDatabaseId?: string;
  warehouseViewSchemaId?: string;
  warehouseViewTableId?: string;
  isPersistedAs?: IsPersistedAsType;
  cacheStrategy?: string;
  columnTests?: string;
}) => Promise<void>;

export interface CacheStrategy {
  type: "every" | "column" | "sql" | "whaly_synced";
  value: string;
}

export type ModifyQuery = (
  prevTransformation: Transformation[],
  nextTransformation: Transformation[],
  reloadView?: boolean,
  scrollTo?: string,
  isFlow?: boolean
) => Promise<any>;

export const generateForeignKeys = (d: IDataset): string[] => {
  return [
    ...d.incomingRelationships.map((ir) => ir.to),
    ...d.outgoingRelationships.map((or) => or.from),
  ];
};

export const generateRelationships = (
  d: IDataset,
  datasets: IDataset[]
): IDatasetRelationship[] => {
  return [
    ...d.incomingRelationships.map((ir) => {
      const newIr = {
        ...ir,
        from: ir.to,
        to: ir.from,
        type: ir.type === "1-1" ? ir.type : ir.type === "1-N" ? "N-1" : "1-N",
        left: d,
        right: datasets.find((da) => da.id === ir.left.id)!,
      } as IDatasetRelationship;
      return newIr;
    }),
    ...d.outgoingRelationships.map((or) => {
      const newOr = {
        ...or,
        left: d,
        right: datasets.find((da) => da.id === or.right.id)!,
      };
      return newOr;
    }),
  ];
};

export const generatePrimaryKey = (d: IDataset) =>
  typeof d.primaryKey === "string" && d.primaryKey.length
    ? d.primaryKey.split(",")
    : [];

export const generateJobExecutionName = (j: IJobExecution) =>
  moment(j.createdAt).format("MMMM Do [at] HH:mm");

export type FetchedDestination = Pick<
  IDestination,
  | "id"
  | "isPersistenceEngineEnabled"
  | "persistenceEngineSyncStatus"
  | "persistenceEngineNextSyncDate"
  | "persistenceEngineSyncPeriod"
  | "persistenceEngineDefaultTargetDatabase"
  | "persistenceEngineDefaultTargetSchema"
  | "isDbtCloudSyncEnabled"
  | "dbtApiKey"
  | "dbtAccountId"
  | "dbtProjectId"
  | "dbtCloudSyncStatus"
  | "dbtCloudSyncNextSyncDate"
  | "dbtCloudSyncPeriod"
  | "dbtCloudSyncLastJobExecution"
  | "persistenceEngineLastJobExecution"
  | "isSignalEngineEnabled"
  | "signalEngineDefaultTargetDatabase"
  | "signalEngineDefaultTargetSchema"
>;

export type UpdateDestination = (id: string, data: any) => Promise<void>;

export interface IDatasetLineageGraph {
  [datasetId: string]: IDatasetLineageGraph;
}

export const computeRunResultStepName = (runResult: IRunResult) => {
  if (runResult.type === "freshness") {
    return "Data freshness";
  }
  if (runResult.type === "materialization") {
    return `Materialize as ${runResult.operationName}`;
  }
  if (runResult.type === "test") {
    return `Run ${runResult.operationName} test on column ${runResult.columnName}`;
  }
};

export const DEFAULT_WORKBENCH_RECORD_NUMBER = 3000;
