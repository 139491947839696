import type { SelectProps } from "antd";
import { Button, Col, Row } from "antd";
import type { FormItemProps } from "antd/lib/form";
import type { InputProps } from "antd/lib/input";
import type { FormikProps } from "formik";
import { Field, Form, Formik } from "formik";
import * as React from "react";
import type { RouteComponentProps } from "react-router";
import { withRouter } from "react-router";
import Card from "../../../../../components/cards/Card";
import { compose } from "../../../../../components/compose/WlyCompose";
import Input from "../../../../../components/form/elements/input/Input";
import Select from "../../../../../components/form/elements/select/Select";

import { isRequired } from "../../../../../components/form/validators/validators";
import { handleGQLErrors } from "../../../../../helpers/gqlHelpers";
import type { InjectedOrgProps } from "../../../../orgs/WithOrg";
import WithOrg from "../../../../orgs/WithOrg";
import { generateItemValueFromInput } from "../../../domain";

import Logo from "../../../../../assets/icon.svg";
import { GoogleButton } from "../../../../../components/button/GoogleButton";
import Aligner from "../../../../../components/layout/aligner/Aligner";
import Loading from "../../../../../components/layout/feedback/loading";
import type { ICatalogItemMeta } from "../../../../../interfaces/catalog";
import { track } from "../../../../../services/AnalyticsService";

const F = Form as any;

interface IItemConnectionStep1Props {
  initialValues: InitialValues;
  itemMeta: ICatalogItemMeta;
  step1CreationPromise: Step1CreationPromise;
  overrideGenerateItemValueFromInput?: ItemValueFromInputGenerator;
  additionalFormFieldsGenerator?: AdditionalFieldsGenerator;
  redirectUri: string;
}

type Props = IItemConnectionStep1Props &
  InjectedOrgProps &
  RouteComponentProps<{ organizationSlug: string; itemSlug: string }>;

interface State {
  isLoading: boolean;
  additionalFieldsDef?: AdditionalFieldsDef[];
}

interface InitialValues {
  name: string;
  [key: string]: string | number | Date;
}

export interface RawInput {
  [key: string]: string | number | Date;
}

interface OptionConnectInput {
  connect: {
    id: string;
  };
}
export interface ItemValueInput {
  value: string;
  option: OptionConnectInput | String;
  org: {
    connect: {
      id: string;
    };
  };
}

interface AdditionalFieldsDef {
  name: string;
  label: string;
  defaultValue: string;
  type: "input" | "select";
  validate?: (v: any) => void;
  options?: string[];
}

export type Step1CreationPromise = (
  itemName: string,
  orgId: string,
  itemMeta: ICatalogItemMeta,
  itemValues: ItemValueInput[],
  rawInputs: RawInput
) => Promise<void>;

export type ItemValueFromInputGenerator = (
  itemMeta: ICatalogItemMeta,
  v: { [key: string]: any },
  orgId: string
) => ItemValueInput[];
export type AdditionalFieldsGenerator = (
  orgId: string,
  itemMeta: ICatalogItemMeta
) => Promise<AdditionalFieldsDef[]>;

class ItemConnectionStep1 extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isLoading: true,
    };
  }

  async componentDidMount() {
    const { additionalFormFieldsGenerator, itemMeta, org } = this.props;
    let additionalFieldsDef: AdditionalFieldsDef[] = [];
    if (additionalFormFieldsGenerator) {
      additionalFieldsDef = await additionalFormFieldsGenerator(
        org.id,
        itemMeta
      );
      this.setState({
        isLoading: false,
        additionalFieldsDef,
      });
    } else {
      this.setState({
        isLoading: false,
      });
    }
  }

  public onSubmit = (v: InitialValues) => {
    const {
      org,
      itemMeta,
      step1CreationPromise,
      warehouses,
      overrideGenerateItemValueFromInput,
    } = this.props;

    let itemValues: ItemValueInput[] = [];
    if (overrideGenerateItemValueFromInput) {
      itemValues = overrideGenerateItemValueFromInput(itemMeta, v, org.id);
    } else {
      itemValues = generateItemValueFromInput(itemMeta, v, org.id);
    }

    const rawInputs: RawInput = Object.keys(v).reduce<RawInput>((acc, key) => {
      acc[key] = v[key];
      return acc;
    }, {});
    // TODO: Differentiate Source from Action and Warehouse
    track("Catalog Item Created", { name: v.name });
    return step1CreationPromise(
      v.name,
      org.id,
      itemMeta,
      itemValues,
      rawInputs
    ).catch(handleGQLErrors());
  };

  public onCancel = () => {
    const { history, redirectUri } = this.props;

    history.push(redirectUri);
  };

  public render() {
    const { isLoading, additionalFieldsDef } = this.state;
    const { initialValues, itemMeta } = this.props;

    if (isLoading === true) {
      return <Loading />;
    } else {
      if (additionalFieldsDef && additionalFieldsDef.length > 0) {
        additionalFieldsDef.forEach((fieldDef) => {
          initialValues[fieldDef.name] = fieldDef.defaultValue;
        });
      }

      const genAdditionalFieldComponents = (
        p: FormikProps<InitialValues>,
        additionalFieldsDef: AdditionalFieldsDef[]
      ) => {
        return additionalFieldsDef.map((fieldDef, i) => {
          return (
            <Field
              key={i}
              type={fieldDef.name}
              name={fieldDef.name}
              component={fieldDef.type === "input" ? Input : Select}
              validate={
                fieldDef.validate ? isRequired && fieldDef.validate : isRequired
              }
              disabled={p.isSubmitting}
              inputProps={
                fieldDef.type === "input"
                  ? ({
                      placeholder: "",
                    } as InputProps)
                  : ({
                      options: fieldDef.options.map((opt) => {
                        return { label: opt, value: opt };
                      }),
                    } as SelectProps)
              }
              formItemProps={
                {
                  label: fieldDef.label,
                  hasFeedback: true,
                  colon: false,
                  required: true,
                } as FormItemProps
              }
            />
          );
        });
      };
      return (
        <Aligner className="whly-content item-connection">
          <Row justify="center">
            <Col xs={24} sm={20} md={18} lg={14} xl={12}>
              <Formik initialValues={initialValues} onSubmit={this.onSubmit}>
                {(p: FormikProps<InitialValues>) => {
                  return (
                    <F className="form-container">
                      <Card className="card">
                        <div className="image-wrapper">
                          <div className="from">
                            <img
                              src={itemMeta.publicInfo.logo}
                              alt={itemMeta.publicInfo.name}
                            />
                          </div>
                          <div className="dots">
                            <div className="dot" />
                            <div className="dot" />
                            <div className="dot" />
                          </div>
                          <div className="to">
                            <img src={Logo} alt="whaly logo" />
                          </div>
                        </div>
                        <Field
                          type="name"
                          name="name"
                          component={Input}
                          validate={isRequired}
                          disabled={p.isSubmitting}
                          inputProps={
                            {
                              placeholder:
                                "Proper names are poetry in the raw. Like all poetry they are untranslatable. — W.H. Auden",
                            } as InputProps
                          }
                          formItemProps={
                            {
                              label: "Name",
                              hasFeedback: true,
                              colon: false,
                              required: true,
                            } as FormItemProps
                          }
                        />
                        {additionalFieldsDef && additionalFieldsDef.length > 0
                          ? genAdditionalFieldComponents(p, additionalFieldsDef)
                          : undefined}
                        <Row>
                          <Col span={12}>
                            <Button type="link" onClick={this.onCancel}>
                              Cancel
                            </Button>
                          </Col>
                          <Col span={12} style={{ textAlign: "right" }}>
                            {(itemMeta as any).buttonType === "google" ? (
                              <GoogleButton htmlType="submit" />
                            ) : (
                              <Button type="primary" htmlType="submit">
                                Continue
                              </Button>
                            )}
                          </Col>
                        </Row>
                      </Card>
                    </F>
                  );
                }}
              </Formik>
            </Col>
          </Row>
        </Aligner>
      );
    }
  }
}

export default compose<Props, IItemConnectionStep1Props>(
  WithOrg,
  withRouter
)(ItemConnectionStep1);
