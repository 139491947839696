import { Checkbox } from "antd";
import * as React from "react";
import { OrderedList } from "../../../../../../components/form/orderered-list/OrderedList";

interface ICustomOrderedListProps {
  value?: string[];
  defaultValue?: string[];
  onChange?: (v?: string[]) => void;
  fallbackValues: string[];
}

export function CustomOrderedList(props: ICustomOrderedListProps) {
  return (
    <div>
      <Checkbox
        checked={!!(props.value && props.value.length)}
        onChange={(e) => {
          if (e.target.checked && props.onChange) {
            props.onChange(props.fallbackValues);
          } else if (props.onChange) {
            props.onChange(undefined);
          }
        }}
      >
        Enable custom order
      </Checkbox>
      {props.value && props.value.length && (
        <OrderedList onChange={props.onChange} values={props.value} />
      )}
    </div>
  );
}
