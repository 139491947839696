import {
  CloseOutlined,
  CodeOutlined,
  CompassOutlined,
  DeleteOutlined,
  DoubleRightOutlined,
  EyeInvisibleOutlined,
  FilterOutlined,
  ForkOutlined,
  FormOutlined,
  FunctionOutlined,
  HistoryOutlined,
  MoreOutlined,
  PlusCircleOutlined,
  ReloadOutlined,
} from "@ant-design/icons";
import type { MenuProps } from "antd";
import { Alert, Button, Dropdown, Menu, Popover, Space } from "antd";
import * as React from "react";
import type {
  BooleanSchemaItem,
  Filter,
  FormulaSchemaItem,
  SchemaResult,
  TableAddColumnOperation,
  TableFromDatasetTableOperation,
  TableRemoveColumnOperation,
  TableResult,
  TableSelectRowsOperation,
  Transformation,
  WhalyExtTableLookupOperation,
  WhalyExtTableRollupOperation,
} from "../../interfaces/transformations";
import { generateUniqueId } from "../../utils/uniqueId";
import * as Toolbar from "./toolbar/Toolbar";

import cuid from "cuid";
import moment from "moment";
import { Link } from "react-router-dom";
import type { InjectedAntUtilsProps } from "../../components/ant-utils/withAntUtils";
import { withAntUtils } from "../../components/ant-utils/withAntUtils";
import { compose } from "../../components/compose/WlyCompose";
import UserAvatar from "../../components/user/avatar/UserAvatar";
import { handleGQLErrors } from "../../helpers/gqlHelpers";
import type { AsyncData } from "../../helpers/typescriptHelpers";
import type { IDestination } from "../../interfaces/destinations";
import { IOrgFeatureType } from "../../interfaces/org";
import type { IDataset } from "../../interfaces/sources";
import { routeDescriptor } from "../../routes/routes";
import type { InjectedOrgProps } from "../orgs/WithOrg";
import WithOrg from "../orgs/WithOrg";
import type { CreateEditTransformationComponent } from "../transformations";
import AddColumnCreateEdit from "../transformations/AddColumn/CreateEdit";
import AddColumnMeta from "../transformations/AddColumnMeta/AddColumnMeta";
import HideColumnCreateEdit from "../transformations/HideColumns/CreateEdit";
import LookupCreateEdit from "../transformations/Lookup/CreateEdit";
import RollupCreateEdit from "../transformations/Rollup/CreateEdit";
import SelectRowsCreateEdit from "../transformations/SelectRows/CreateEdit";
import FlowViewer from "../workbench/workbench/dataset/tabs/flow/FlowViewer";
import type { IDatasetUpdate } from "../workbench/workbench/domain";
import { ModelMigrationModal } from "../workbench/workbench/migration/MigrationModal";
import type { DatasetSavedData } from "../workbench/workbench/selector/tables/models/AddModelModal";
import AddModelModal from "../workbench/workbench/selector/tables/models/AddModelModal";
import type {
  ColumnSelectedItem,
  ISpreadsheetLoading,
  RowSelectedItem,
  SelectedItem,
  ToolbarMenuElement,
} from "./SpreadsheetCore";
import SpreadsheetCore from "./SpreadsheetCore";
import type { ColumnMenuElement } from "./columnMenu/ColumnMenu";
import DbtQueryModal from "./dbtQueryModal/dbtQueryModal";
import type { ColumnInformations, TableTabItem } from "./domain";
import { checkIfColumnIsUsed, hideColumn, removeColumn } from "./domain";
import Drills from "./drills/DrillsModal";
import FormDropdown from "./formmodal/FormDropdown";
import { renderContent } from "./helpers";
import type { IRelatedObject } from "./history/HistoryDrawer";
import HistoryDrawer from "./history/HistoryDrawer";
import ColumnModalContent from "./modalcontent/ColumnModalContent";

interface ISpreadsheetProps {
  records?: TableResult;
  count?: number;
  schema?: SchemaResult;
  columnsInfos?: ColumnInformations;
  style?: React.CSSProperties;
  tabStyle?: React.CSSProperties;
  tables: Array<TableTabItem>;
  activeTableKey: string;
  activeViewKey: string;
  datasetQueryCursor: string;
  viewQueryCursor: AsyncData<string>;
  transformations: AsyncData<Transformation[]>;
  onExploreClick?: (activeKey: string, activeView: string) => void;
  onDrillUpdate?: (viewId: string, drills: string[]) => Promise<any>;
  onModifyTransformation?: (
    prevTransformation: Transformation[],
    nextTransformation: Transformation[],
    reloadView?: boolean,
    scrollTo?: string
  ) => Promise<any>;
  onShowFlowMigration?: () => void;
  onRefresh?: (activeKey: string, activeView: string) => void;
  error?: string;
  loading: ISpreadsheetLoading;
  scrollToColumnIndex?: number;
  rounded?: boolean;
  operationState?: ISpreadsheetOperationState;
  datasets: IDataset[];
  onCreateModel: (dataset: DatasetSavedData) => Promise<any>;
  viewNameRenderer?: React.ReactNode;
  onUpdateDataset?: IDatasetUpdate;
  onMigrationDone: (
    toDatasetId: string,
    datasetIds: string[],
    explorationIds: string[]
  ) => Promise<void>;
  currentWarehouse: IDestination;
}

export interface ISpreadsheetOperationState {
  refresh?: OperationState;
  hide?: OperationState;
  filter?: OperationState;
  add_column?: OperationState;
}

type OperationState = "normal" | "disabled" | "hidden";

interface IState {
  selectedItem?: SelectedItem;
  addingANewTransformation: boolean;
  editingTransformation?: {
    transformation: Transformation;
    component: CreateEditTransformationComponent<Transformation>;
  };
  submittingTransformation: boolean;
  height: number;
  showHistory?: boolean;
  showFlow?: boolean;
  isMigratingFlow: boolean;
  migrationModalOpen: boolean;
  datasetEditionOpen: boolean;
  flowToSQLOpen: boolean;
  isDbtQueryModalOpen: boolean;
}

type Props = InjectedOrgProps & ISpreadsheetProps & InjectedAntUtilsProps;

class Spreadsheet extends React.Component<Props, IState> {
  id = cuid();

  constructor(props: Props) {
    super(props);
    this.state = {
      addingANewTransformation: false,
      submittingTransformation: false,
      height: 0,
      showHistory: false,
      isMigratingFlow: false,
      datasetEditionOpen: false,
      migrationModalOpen: false,
      flowToSQLOpen: false,
      isDbtQueryModalOpen: false,
    };
  }

  componentDidUpdate(prevProps: ISpreadsheetProps) {
    const { activeTableKey, activeViewKey } = this.props;
    const {
      activeTableKey: prevActiveTableKey,
      activeViewKey: prevActiveViewKey,
    } = prevProps;
    if (
      activeTableKey !== prevActiveTableKey ||
      activeViewKey !== prevActiveViewKey
    ) {
      this.setState({ selectedItem: undefined });
    }
  }

  public onRowClick = (e: RowSelectedItem) => {
    this.setState({ selectedItem: e });
  };

  public onHeaderClick = (e: ColumnSelectedItem) => {
    this.setState({ selectedItem: e });
  };

  public countNumberOfFilter = (condition: Filter) => {
    if ((condition[0] as any).and) {
      return (condition[0] as any).and.length;
    }
    if ((condition[0] as any).or) {
      return (condition[0] as any).or.length;
    }
    return condition.length;
  };

  toolbarMenuElements = (): ToolbarMenuElement[] => {
    const {
      transformations,
      onModifyTransformation,
      tables,
      activeTableKey,
      activeViewKey,
      onRefresh,
      operationState,
      loading,
      error,
      viewQueryCursor,
      datasetQueryCursor,
      schema,
      datasets,
      onExploreClick,
      currentWarehouse,
    } = this.props;

    const { submittingTransformation } = this.state;

    const elements: ToolbarMenuElement[] = [];

    const activeTable = tables.find((t) => t.key === activeTableKey);

    const displayMigrateToFlow =
      this.props.orgFeatures.includes(IOrgFeatureType.OLD_WORKBENCH) &&
      !activeTable.isModel;

    const displayDbtQueryModal: boolean =
      activeTable.dataset?.isModel &&
      activeTable.dataset?.managedBy === "WHALY";

    const moreMenuItems: MenuProps["items"] = [];

    if (activeTable.isModel) {
      moreMenuItems.push({
        key: 0,
        icon: <HistoryOutlined />,
        label: "Show history",
        onClick: () =>
          this.setState({
            showHistory: true,
          }),
      });
    }

    if (displayMigrateToFlow) {
      moreMenuItems.push({
        key: 1,
        icon: <ForkOutlined />,
        label: "Migrate to flow",
        onClick: () =>
          this.setState({
            showFlow: true,
            isMigratingFlow: true,
          }),
      });
    }

    if (displayDbtQueryModal) {
      moreMenuItems.push({
        key: 2,
        icon: <CodeOutlined />,
        label: "Show dbt query",
        onClick: () =>
          this.setState({
            isDbtQueryModalOpen: true,
          }),
      });
    }

    let schemaDef = schema ? schema : {};
    if (loading.schema) {
      schemaDef = Array.from({ length: 10 }, (_, a) => ({
        key: generateUniqueId(),
      }))
        .map((_) => {
          return {
            [_.key]: {
              domain: "BOOLEAN",
              type: "BOOLEAN",
              distinct: 0,
              null: 0,
              count: 0,
            } as BooleanSchemaItem,
          };
        })
        .reduce((p, v, i) => {
          return {
            ...p,
            ...v,
          };
        }, {} as SchemaResult);
    }

    if (
      transformations.status === "success" &&
      transformations.data.length !== 0 &&
      onModifyTransformation &&
      activeTable &&
      activeTable.views.find((v) => v.key === activeViewKey)
    ) {
      const renderViewName = () => {
        if (this.props.viewNameRenderer) {
          return this.props.viewNameRenderer;
        }
        return activeTable.label.length > 55
          ? `${activeTable.label.substring(55)}...`
          : activeTable.label;
      };

      elements.push({
        key: "view-name",
        renderer: () => {
          return <Toolbar.ViewName>{renderViewName()}</Toolbar.ViewName>;
        },
      });

      elements.push({
        key: "separator",
        renderer: () => <Toolbar.Separator />,
      });
    }

    if (
      !this.props.orgFeatures.includes(IOrgFeatureType.OLD_WORKBENCH) &&
      !activeTable.isModel
    ) {
      elements.push({
        key: "migrate-to-model",
        renderer: () => {
          return (
            <Toolbar.Item>
              <ModelMigrationModal
                currentWarehouse={currentWarehouse}
                visible={this.state.migrationModalOpen}
                onClose={() => {
                  this.setState({
                    migrationModalOpen: false,
                  });
                }}
                datasets={datasets}
                dataset={activeTable.dataset}
                onMigrationDone={this.props.onMigrationDone}
              />

              <span
                onClick={() =>
                  this.setState({
                    migrationModalOpen: true,
                  })
                }
              >
                <DoubleRightOutlined /> Migrate
              </span>
            </Toolbar.Item>
          );
        },
      });
      elements.push({
        key: "new-flow",
        renderer: () => {
          return (
            <Toolbar.Item>
              <AddModelModal
                key={datasetQueryCursor}
                visible={this.state.datasetEditionOpen}
                currentWarehouse={currentWarehouse}
                initialData={{
                  datasetId: datasetQueryCursor,
                }}
                onCancel={() =>
                  this.setState({
                    datasetEditionOpen: false,
                  })
                }
                onSave={(data) => {
                  return this.props
                    .onCreateModel({ ...data, isModel: true } as any)
                    .then(() => {
                      this.setState({
                        datasetEditionOpen: false,
                      });
                    })
                    .catch(handleGQLErrors());
                }}
              />

              <span
                onClick={() =>
                  this.setState({
                    datasetEditionOpen: true,
                  })
                }
              >
                <PlusCircleOutlined /> Use in Flow
              </span>
            </Toolbar.Item>
          );
        },
      });
    }

    if (onExploreClick) {
      elements.push({
        key: "explore",
        renderer: () => (
          <Toolbar.Item disabled={loading.records}>
            <span onClick={() => onExploreClick(activeTableKey, activeViewKey)}>
              <CompassOutlined /> Explore
            </span>
          </Toolbar.Item>
        ),
      });
    }

    if (onRefresh && (!operationState || operationState.refresh !== "hidden")) {
      elements.push({
        key: "refresh",
        renderer: () => {
          return (
            <Toolbar.Item disabled={loading.records}>
              <span onClick={() => onRefresh(activeTableKey, activeViewKey)}>
                <ReloadOutlined /> Refresh
              </span>
            </Toolbar.Item>
          );
        },
      });
    }

    if (
      transformations.status === "success" &&
      viewQueryCursor.status === "success" &&
      !error &&
      this.props.orgFeatures.includes(IOrgFeatureType.OLD_WORKBENCH) &&
      onModifyTransformation &&
      (!operationState || operationState.hide !== "hidden")
    ) {
      elements.push({
        key: "hide-column",
        renderer: () => {
          return (
            <FormDropdown
              renderDropdownElement={(isStale, onClose) => {
                return (
                  <HideColumnCreateEdit
                    currentTransformation={{
                      operation: {
                        type: "Table.RemoveColumns",
                        args: {
                          columns: [],
                          table: viewQueryCursor.data,
                        },
                      },
                      var: transformations.data.find(
                        (t) => t.operation.type === "Table.RemoveColumns"
                      )
                        ? transformations.data.find(
                            (t) => t.operation.type === "Table.RemoveColumns"
                          )!.var
                        : generateUniqueId(),
                      domain: "viewResolver",
                    }}
                    isStale={isStale}
                    currentSchema={schemaDef}
                    onSave={this.onAddModifyTransformation}
                    submitting={this.state.submittingTransformation}
                    transformations={transformations.data}
                    columns={schema ? Object.keys(schema) : []}
                    primaryKeys={activeTable ? activeTable.primaryKey : []}
                    relationships={activeTable ? activeTable.foreignKeys : []}
                    drills={
                      activeTable
                        ? activeTable.views.find(
                            (v) => v.key === activeViewKey
                          )!.drills
                        : []
                    }
                    reports={tables}
                    viewId={activeViewKey}
                    onCancel={onClose}
                    currentWarehouse={currentWarehouse}
                  />
                );
              }}
            >
              {transformations.data.find(
                (t) => t.operation.type === "Table.RemoveColumns"
              ) &&
              (
                transformations.data.find(
                  (t) => t.operation.type === "Table.RemoveColumns"
                )!.operation as TableRemoveColumnOperation
              ).args.columns.length ? (
                <Toolbar.Item active={true} color={"violet"}>
                  <EyeInvisibleOutlined />{" "}
                  {
                    (
                      transformations.data.find(
                        (t) => t.operation.type === "Table.RemoveColumns"
                      )!.operation as TableRemoveColumnOperation
                    ).args.columns.length
                  }{" "}
                  hidden columns
                </Toolbar.Item>
              ) : (
                <Toolbar.Item active={false} color={"violet"}>
                  <EyeInvisibleOutlined /> Hide columns
                </Toolbar.Item>
              )}
            </FormDropdown>
          );
        },
      });
    }

    if (
      !error &&
      transformations.status === "success" &&
      viewQueryCursor.status === "success" &&
      this.props.orgFeatures.includes(IOrgFeatureType.OLD_WORKBENCH) &&
      onModifyTransformation &&
      activeTable &&
      activeTable.views.find((v) => v.key === activeViewKey) &&
      (!operationState || operationState.filter !== "hidden")
    ) {
      elements.push({
        key: "filter-rows",
        renderer: () => {
          return (
            <FormDropdown
              renderDropdownElement={(isStale, onClose) => (
                <SelectRowsCreateEdit
                  currentTransformation={
                    transformations.data.find(
                      (t) => t.operation.type === "Table.SelectRows"
                    )
                      ? (transformations.data.find(
                          (t) => t.operation.type === "Table.SelectRows"
                        )! as any)
                      : {
                          operation: {
                            type: "Table.SelectRows",
                            args: {
                              condition: [{ or: [] }],
                              table: viewQueryCursor.data,
                            },
                          },
                          var: generateUniqueId(),
                          domain: "viewResolver",
                        }
                  }
                  isStale={isStale}
                  viewCubeName={
                    activeTable.views.find((v) => v.key === activeViewKey)!
                      .viewCubeName
                  }
                  viewId={activeViewKey}
                  currentSchema={schemaDef}
                  onSave={this.onAddModifyTransformation}
                  submitting={submittingTransformation}
                  transformations={transformations.data}
                  columns={schema ? Object.keys(schema) : []}
                  reports={tables}
                  onCancel={onClose}
                  currentWarehouse={currentWarehouse}
                />
              )}
            >
              {transformations.data.find(
                (t) => t.operation.type === "Table.SelectRows"
              ) &&
              this.countNumberOfFilter(
                (
                  transformations.data.find(
                    (t) => t.operation.type === "Table.SelectRows"
                  )!.operation as TableSelectRowsOperation
                ).args.condition
              ) !== 0 ? (
                <Toolbar.Item active={true} color="green">
                  <EyeInvisibleOutlined />{" "}
                  {this.countNumberOfFilter(
                    (
                      transformations.data.find(
                        (t) => t.operation.type === "Table.SelectRows"
                      )!.operation as TableSelectRowsOperation
                    ).args.condition
                  )}{" "}
                  filters
                </Toolbar.Item>
              ) : (
                <Toolbar.Item color="green">
                  <span>
                    <FilterOutlined /> Filter
                  </span>
                </Toolbar.Item>
              )}
            </FormDropdown>
          );
        },
      });
    }

    if (
      !error &&
      transformations.status === "success" &&
      this.props.orgFeatures.includes(IOrgFeatureType.OLD_WORKBENCH) &&
      onModifyTransformation &&
      activeTable &&
      activeTable.baseDatasetId &&
      (!operationState || operationState.add_column !== "hidden")
    ) {
      elements.push({
        key: "add-column",
        renderer: () => {
          return (
            <FormDropdown
              renderDropdownElement={(isStale, onClose) => (
                <AddColumnMeta isStale={isStale} onCancel={onClose}>
                  {(type) => {
                    if (type === "FORMULA") {
                      return (
                        <AddColumnCreateEdit
                          currentTransformation={{
                            operation: {
                              type: "Table.AddColumn",
                              args: {
                                columnGenerator: "",
                                newColumnName: "",
                                table: datasetQueryCursor,
                              },
                            },
                            var: generateUniqueId(),
                            domain: "datasetResolver",
                          }}
                          isStale={isStale}
                          onSave={this.onAddModifyTransformation}
                          currentSchema={schemaDef}
                          submitting={submittingTransformation}
                          transformations={transformations.data}
                          columns={schema ? Object.keys(schema) : []}
                          reports={tables}
                          onCancel={onClose}
                          currentWarehouse={currentWarehouse}
                        />
                      );
                    } else if (type === "LOOKUP") {
                      return (
                        <LookupCreateEdit
                          currentTransformation={{
                            operation: {
                              type: "WhalyExt.Table.AddLookupColumn",
                              args: {
                                table1: datasetQueryCursor,
                                table2: "",
                                key1: "",
                                key2: "",
                                aggregationType: "FIRST_VALUE",
                                newColumnName: "",
                              },
                            },
                            var: generateUniqueId(),
                            domain: "datasetResolver",
                          }}
                          currentDatasetId={activeTable.baseDatasetId!}
                          isStale={isStale}
                          currentSchema={schemaDef}
                          onSave={this.onAddModifyTransformation}
                          submitting={submittingTransformation}
                          transformations={transformations.data}
                          columns={schema ? Object.keys(schema) : []}
                          reports={tables}
                          onCancel={onClose}
                          currentWarehouse={currentWarehouse}
                        />
                      );
                    } else if (type === "ROLLUP") {
                      return (
                        <RollupCreateEdit
                          currentTransformation={{
                            operation: {
                              type: "WhalyExt.Table.AddRollupColumn",
                              args: {
                                table1: datasetQueryCursor,
                                table2: "",
                                key1: "",
                                key2: "",
                                aggregationType: "COUNT",
                                newColumnName: "",
                              },
                            },
                            var: generateUniqueId(),
                            domain: "datasetResolver",
                          }}
                          currentDatasetId={activeTable.baseDatasetId!}
                          isStale={isStale}
                          currentSchema={schemaDef}
                          onSave={this.onAddModifyTransformation}
                          submitting={submittingTransformation}
                          transformations={transformations.data}
                          columns={schema ? Object.keys(schema) : []}
                          reports={tables}
                          onCancel={onClose}
                          currentWarehouse={currentWarehouse}
                        />
                      );
                    } else {
                      return <div />;
                    }
                  }}
                </AddColumnMeta>
              )}
            >
              <Toolbar.Item color="yellow">
                <span>
                  <PlusCircleOutlined /> Add Column
                </span>
              </Toolbar.Item>
            </FormDropdown>
          );
        },
      });
    }

    elements.push({
      key: "usage",
      renderer: () => {
        const { org } = this.props;
        const relatedExplorations = activeTable.views.find(
          (v) => v.key === activeViewKey
        ).usedInExplorations;
        const type = activeTable.isModel ? "Model" : "View";
        if (relatedExplorations.length === 0) return null;
        return (
          <Popover
            title={
              <b>
                {type} used in {relatedExplorations.length} exploration
                {relatedExplorations.length > 1 ? "s" : ""}
              </b>
            }
            placement="bottom"
            content={
              <div
                style={{
                  width: "300px",
                  maxHeight: "350px",
                  overflowY: "auto",
                }}
              >
                <Space direction="vertical">
                  <Alert
                    type="info"
                    message={`Be careful when filtering columns, hiding columns or modifying calculated columns as it will impact your exploration${
                      relatedExplorations.length > 1 ? "s" : ""
                    }`}
                  />
                  <p>
                    Used in the following exploration
                    {relatedExplorations.length > 1 ? "s" : ""}:
                    <ul>
                      {relatedExplorations.map((e, i) => {
                        return (
                          <li key={i}>
                            <Link
                              key={i}
                              target={"_blank"}
                              to={routeDescriptor["explore"].renderRoute({
                                organizationSlug: org.slug,
                                exploreSlug: e.slug,
                              })}
                            >
                              {e.name} <FormOutlined />
                            </Link>
                          </li>
                        );
                      })}
                    </ul>
                  </p>
                </Space>
              </div>
            }
          >
            <Toolbar.Item type="success">
              <div>
                🎉 {type} used in {relatedExplorations.length} exploration
                {relatedExplorations.length > 1 ? "s" : ""}
              </div>
            </Toolbar.Item>
          </Popover>
        );
      },
    });

    elements.push({
      key: "creator",
      renderer: () => {
        return (
          activeTable.views.find((v) => v.key === activeViewKey) && (
            <div style={{ marginLeft: "auto", paddingRight: 12 }}>
              <span>
                {moment(
                  activeTable.views.find((v) => v.key === activeViewKey)
                    .updatedAt
                ).fromNow()}{" "}
                <UserAvatar
                  size={"small"}
                  user={
                    activeTable.views.find((v) => v.key === activeViewKey)
                      .updatedBy
                  }
                />
              </span>
              {moreMenuItems.length > 0 && (
                <Toolbar.Item>
                  <Dropdown menu={{ items: moreMenuItems }} trigger={["click"]}>
                    <MoreOutlined />
                  </Dropdown>
                </Toolbar.Item>
              )}
            </div>
          )
        );
      },
    });

    return elements;
  };

  public renderModalContent = (selectedItem: SelectedItem) => {
    const { schema, count, activeViewKey, columnsInfos } = this.props;
    if (selectedItem.type === "row") {
      return (
        <div>
          {Object.keys(selectedItem.data).map((k) => {
            return (
              <div key={k} className="item">
                <div className="title">{k}</div>
                <div>
                  {renderContent(
                    selectedItem.data[k],
                    schema && schema[k] ? schema[k].domain : undefined
                  )}
                </div>
              </div>
            );
          })}
        </div>
      );
    } else if (
      selectedItem.type === "column" &&
      schema &&
      schema[selectedItem.index]
    ) {
      return (
        <ColumnModalContent
          columnName={selectedItem.index as string}
          columnType={schema[selectedItem.index].type}
          description={schema[selectedItem.index].description}
          recordCount={count}
          tests={columnsInfos[selectedItem.index]?.tests}
          viewId={activeViewKey}
          columnDomain={schema[selectedItem.index].domain}
        />
      );
    }
  };

  renderHeaderMenuElements = (dataKey: string, schema: SchemaResult) => {
    const {
      operationState,
      transformations,
      tables,
      activeTableKey,
      viewQueryCursor,
      activeViewKey,
      onModifyTransformation,
      org,
      antUtils,
      currentWarehouse,
    } = this.props;
    const { submittingTransformation } = this.state;
    const menuElements: ColumnMenuElement[] = [];

    if (
      transformations.status === "success" &&
      operationState?.add_column !== "hidden" &&
      operationState?.add_column !== "disabled" &&
      schema &&
      schema[dataKey] &&
      schema[dataKey].operation === "Table.AddColumn"
    ) {
      menuElements.push({
        key: "Table.AddColumn",
        renderListItem: (handleClose, setComponent, setStale) => {
          return (
            <div
              key={dataKey}
              onClick={() => {
                setComponent(
                  <Menu className="form-dropdown-menu">
                    <AddColumnCreateEdit
                      currentTransformation={
                        transformations.data.find(
                          (t) =>
                            t.var === (schema[dataKey] as FormulaSchemaItem).var
                        )! as {
                          var: string;
                          operation: TableAddColumnOperation;
                          domain: "datasetResolver";
                        }
                      }
                      onSave={this.onAddModifyTransformation}
                      submitting={submittingTransformation}
                      transformations={transformations.data}
                      columns={schema ? Object.keys(schema) : []}
                      reports={tables}
                      isStale={setStale}
                      onCancel={handleClose}
                      currentSchema={schema}
                      currentWarehouse={currentWarehouse}
                    />
                  </Menu>
                );
              }}
            >
              <FunctionOutlined /> Edit Formula
            </div>
          );
        },
      });
    }

    if (
      transformations.status === "success" &&
      operationState?.add_column !== "hidden" &&
      operationState?.add_column !== "disabled" &&
      schema &&
      schema[dataKey] &&
      schema[dataKey].operation === "WhalyExt.Table.AddRollupColumn"
    ) {
      menuElements.push({
        key: "WhalyExt.Table.AddRollupColumn",
        renderListItem: (handleClose, setComponent, setStale) => {
          return (
            <div
              onClick={() => {
                const editingTransformation = transformations.data.find(
                  (t) => t.var === (schema[dataKey] as FormulaSchemaItem).var
                )! as {
                  var: string;
                  operation: WhalyExtTableLookupOperation;
                  domain: "datasetResolver";
                };
                if (!editingTransformation) {
                  return setComponent(
                    <div>Current transformation not found</div>
                  );
                }
                const activeTable = tables.find(
                  (t) => t.key === activeTableKey
                );
                if (!activeTable || !activeTable.baseDatasetId) {
                  return setComponent(
                    <div>
                      An unexpected error happened, please contact your support
                    </div>
                  );
                }
                const currentTransformation = transformations.data.find(
                  (t) => t.var === (schema[dataKey] as FormulaSchemaItem).var
                ) as
                  | {
                      var: string;
                      operation: WhalyExtTableRollupOperation;
                      domain: "datasetResolver";
                      viewId?: string;
                    }
                  | undefined;
                if (currentTransformation) {
                  const relatedTransformation = transformations.data.find(
                    (t) =>
                      t.var ===
                      (
                        currentTransformation.operation as WhalyExtTableRollupOperation
                      ).args.table2
                  );
                  if (relatedTransformation) {
                    currentTransformation.viewId = (
                      relatedTransformation.operation as TableFromDatasetTableOperation
                    ).args.viewId;
                  }
                }
                setComponent(
                  <Menu className="form-dropdown-menu">
                    <RollupCreateEdit
                      currentTransformation={{ ...currentTransformation! }}
                      onSave={this.onAddModifyTransformation}
                      currentDatasetId={activeTable.baseDatasetId}
                      submitting={submittingTransformation}
                      transformations={transformations.data}
                      columns={schema ? Object.keys(schema) : []}
                      reports={tables}
                      isStale={setStale}
                      onCancel={handleClose}
                      currentSchema={schema}
                      currentWarehouse={currentWarehouse}
                    />
                  </Menu>
                );
              }}
            >
              <FunctionOutlined /> Edit Rollup
            </div>
          );
        },
      });
    }

    if (
      transformations.status === "success" &&
      operationState?.add_column !== "hidden" &&
      operationState?.add_column !== "disabled" &&
      schema &&
      schema[dataKey] &&
      schema[dataKey].operation === "WhalyExt.Table.AddLookupColumn"
    ) {
      menuElements.push({
        key: "WhalyExt.Table.AddLookupColumn",
        renderListItem: (handleClose, setComponent, setStale) => {
          return (
            <div
              onClick={() => {
                const editingTransformation = transformations.data.find(
                  (t) => t.var === (schema[dataKey] as FormulaSchemaItem).var
                )! as {
                  var: string;
                  operation: WhalyExtTableLookupOperation;
                  domain: "datasetResolver";
                };
                if (!editingTransformation) {
                  return setComponent(
                    <div>Current transformation not found</div>
                  );
                }
                const activeTable = tables.find(
                  (t) => t.key === activeTableKey
                );
                if (!activeTable || !activeTable.baseDatasetId) {
                  return setComponent(
                    <div>
                      An unexpected error happened, please contact your support
                    </div>
                  );
                }

                return setComponent(
                  <Menu className="form-dropdown-menu">
                    <LookupCreateEdit
                      currentTransformation={editingTransformation}
                      onSave={this.onAddModifyTransformation}
                      currentDatasetId={activeTable.baseDatasetId}
                      submitting={submittingTransformation}
                      transformations={transformations.data}
                      columns={schema ? Object.keys(schema) : []}
                      reports={tables}
                      onCancel={handleClose}
                      isStale={setStale}
                      currentSchema={schema}
                      currentWarehouse={currentWarehouse}
                    />
                  </Menu>
                );
              }}
            >
              <FunctionOutlined /> Edit Lookup
            </div>
          );
        },
      });
    }

    if (
      transformations.status === "success" &&
      viewQueryCursor.status === "success" &&
      operationState?.hide !== "hidden" &&
      operationState?.hide !== "disabled" &&
      this.props.orgFeatures.includes(IOrgFeatureType.OLD_WORKBENCH)
    ) {
      menuElements.push({
        key: "Hide.Column",
        renderListItem: (handleClose, setComponent) => {
          return (
            <div
              onClick={async () => {
                const nextTransformation = hideColumn(
                  transformations.data,
                  dataKey,
                  viewQueryCursor.data
                );
                const activeTable = tables.find(
                  (t) => t.key === activeTableKey
                );
                if (!activeTable) {
                  return setComponent(
                    <div>
                      An unexpected error happened, please contact your support
                    </div>
                  );
                }
                const activeView = activeTable.views.find(
                  (v) => v.key === activeViewKey
                );
                if (!activeView) {
                  return setComponent(
                    <div>
                      An unexpected error happened, please contact your support
                    </div>
                  );
                }
                const hide = antUtils.message.loading("Hiding...", 0);
                try {
                  const isInUseInDrills = activeView.drills.find(
                    (drill) => drill === dataKey
                  );
                  const isInUse = await checkIfColumnIsUsed(
                    org.id,
                    dataKey,
                    activeTable.baseDatasetId
                  );
                  if (isInUseInDrills) {
                    const keepGoing = await new Promise((resolve, reject) => {
                      hide();
                      antUtils.modal.confirm({
                        title: "This column is in use",
                        content: `this column is used in this view drills. Please remove those drills before deleting the column.`,
                        onOk: () => {
                          resolve(false);
                        },
                        cancelButtonProps: { style: { display: "none" } },
                      });
                    });
                  } else if (
                    isInUse.allDimensions.length ||
                    isInUse.allMetrics.length
                  ) {
                    const keepGoing = await new Promise((resolve, reject) => {
                      hide();
                      antUtils.modal.confirm({
                        title: "This column is in use",
                        content: `this column is used in ${isInUse.allDimensions.length} dimensions and ${isInUse.allMetrics.length} metrics. Please delete those before deleting the column.`,
                        onOk: () => {
                          resolve(false);
                        },
                        cancelButtonProps: { style: { display: "none" } },
                      });
                    });
                  } else {
                    onModifyTransformation &&
                      onModifyTransformation(
                        transformations.data,
                        nextTransformation
                      );
                    handleClose();
                    hide();
                  }
                } catch (err) {
                  hide();
                  antUtils.message.error(
                    "Can't delete this column... Is this column in use?"
                  );
                  setComponent(
                    <div>
                      An unexpected error happened, please contact your support
                    </div>
                  );
                }
              }}
            >
              <EyeInvisibleOutlined /> Hide Column
            </div>
          );
        },
      });
    }

    if (
      transformations.status === "success" &&
      operationState?.hide !== "hidden" &&
      operationState?.hide !== "disabled" &&
      schema &&
      schema[dataKey] &&
      schema[dataKey].operation &&
      schema[dataKey].var
    ) {
      menuElements.push({
        key: "Remove.Column",
        renderListItem: (handleClose, setComponent) => {
          return (
            <div
              onClick={async () => {
                const activeTable = tables.find(
                  (t) => t.key === activeTableKey
                );
                if (!activeTable || !activeTable.baseDatasetId) {
                  return setComponent(
                    <div>
                      An unexpected error happened, please contact your support
                    </div>
                  );
                }
                const activeView = activeTable.views.find(
                  (v) => v.key === activeViewKey
                );
                if (!activeView) {
                  return setComponent(
                    <div>
                      An unexpected error happened, please contact your support
                    </div>
                  );
                }
                const hide = antUtils.message.loading("Deleting...", 0);
                handleClose();
                try {
                  const nextTransformation = removeColumn(
                    transformations.data,
                    schema[dataKey].var!
                  );
                  const isInUse = await checkIfColumnIsUsed(
                    org.id,
                    dataKey,
                    activeTable.baseDatasetId
                  );
                  const isInUseInDrills = activeTable.views
                    .reduce(
                      (acc, cur) => {
                        acc.drills.push(...cur.drills);
                        return acc;
                      },
                      { drills: [] as string[] }
                    )
                    .drills.find((drill) => drill === dataKey);
                  if (isInUseInDrills) {
                    const keepGoing = await new Promise((resolve, reject) => {
                      hide();
                      antUtils.modal.confirm({
                        title: "This column is in use",
                        content: `this column is used in a view drills. Please remove those drills before deleting the column.`,
                        onOk: () => {
                          resolve(false);
                        },
                        cancelButtonProps: { style: { display: "none" } },
                      });
                    });
                  } else if (
                    isInUse.allDimensions.length ||
                    isInUse.allMetrics.length
                  ) {
                    const keepGoing = await new Promise((resolve, reject) => {
                      hide();
                      antUtils.modal.confirm({
                        title: "This column is in use",
                        content: `this column is used in ${isInUse.allDimensions.length} dimensions and ${isInUse.allMetrics.length} metrics. Please delete those before deleting the column.`,
                        onOk: () => {
                          resolve(false);
                        },
                        cancelButtonProps: { style: { display: "none" } },
                      });
                    });
                  } else if (onModifyTransformation) {
                    await onModifyTransformation(
                      transformations.data,
                      nextTransformation
                    );
                    hide();
                  }
                } catch (err) {
                  hide();
                  antUtils.message.error(
                    "Can't delete this column... Is this column in use?"
                  );
                  setComponent(
                    <div>
                      An unexpected error happened, please contact your support
                    </div>
                  );
                }
              }}
            >
              <DeleteOutlined /> Delete Column
            </div>
          );
        },
      });
    }

    return menuElements;
  };

  public onAddModifyTransformation = (
    t: Transformation[],
    reloadView?: boolean,
    scrollTo?: string
  ): Promise<void> => {
    const { onModifyTransformation, transformations } = this.props;
    if (onModifyTransformation && transformations.status === "success") {
      return onModifyTransformation(
        transformations.data,
        t,
        reloadView,
        scrollTo
      );
    }
    return Promise.reject("no modifier");
  };

  public footerMenuElements = () => {
    const { onDrillUpdate, tables, activeTableKey, activeViewKey, schema } =
      this.props;

    const elements: ToolbarMenuElement[] = [];

    const activeTable = tables.find((t) => t.key === activeTableKey);

    if (
      onDrillUpdate &&
      activeTable &&
      activeTable.views.find((v) => v.key === activeViewKey)
    ) {
      elements.push({
        key: "drills",
        renderer: () => {
          return (
            <Drills
              disabled={this.props.loading.schema}
              drills={
                activeTable.views.find((v) => v.key === activeViewKey)!.drills
              }
              schema={schema ? schema : {}}
              primaryKeys={
                tables.find((t) => t.key === activeTableKey)
                  ? tables.find((t) => t.key === activeTableKey).primaryKey
                  : []
              }
              onUpdate={(drills: string[]) =>
                onDrillUpdate(activeViewKey, drills)
              }
            />
          );
        },
      });
    }

    return elements;
  };

  public render() {
    const {
      schema,
      count,
      records,
      loading,
      style,
      activeTableKey,
      tables,
      transformations,
      error,
      scrollToColumnIndex,
      rounded,
      tabStyle,
      activeViewKey,
      columnsInfos,
      currentWarehouse,
    } = this.props;

    const activeTable = tables.find((t) => t.key === activeTableKey);

    const relatedObjects: IRelatedObject[] = [
      {
        recordId: activeTableKey,
        tableName: "Dataset",
      },
    ];

    if (activeTable && !activeTable.isModel) {
      relatedObjects.push({
        recordId: activeViewKey,
        tableName: "View",
      });
    }

    const displayDbtQueryModal =
      activeTable.dataset?.id &&
      activeTable.dataset?.isModel &&
      activeTable.dataset?.managedBy === "WHALY";

    return (
      <>
        <SpreadsheetCore
          records={records}
          count={count}
          schema={schema}
          transformations={transformations}
          style={style}
          tabStyle={tabStyle}
          primaryKeys={activeTable.primaryKey}
          foreignKeys={activeTable.foreignKeys}
          userDefinedColumns={activeTable.userDefinedColumns}
          outgoingRelationships={activeTable.relationships}
          headerMenuElements={this.renderHeaderMenuElements}
          toolbarMenuElements={this.toolbarMenuElements()}
          onRowClick={this.onRowClick}
          onHeaderClick={this.onHeaderClick}
          selectedItem={this.state.selectedItem}
          error={error}
          loading={loading}
          scrollToColumnIndex={scrollToColumnIndex}
          rounded={rounded}
          columnsInfos={columnsInfos}
          footerMenuElements={this.footerMenuElements()}
          renderModalContent={(height) => {
            if (this.state.selectedItem) {
              return (
                <>
                  <div
                    className="close-button"
                    onClick={() => this.setState({ selectedItem: undefined })}
                  >
                    <CloseOutlined />
                  </div>
                  <div
                    className={`content ${this.state.selectedItem.type}`}
                    style={{ height: height }}
                  >
                    {this.renderModalContent(this.state.selectedItem)}
                  </div>
                </>
              );
            }
            return;
          }}
        />
        {displayDbtQueryModal && (
          <DbtQueryModal
            datasetId={activeTable.dataset?.id}
            isOpen={this.state.isDbtQueryModalOpen}
            setIsOpen={(open) => {
              this.setState({
                isDbtQueryModalOpen: open,
              });
            }}
          />
        )}
        <HistoryDrawer
          onClose={() => this.setState({ showHistory: false })}
          visible={this.state.showHistory}
          relatedObjects={relatedObjects}
        />

        <FlowViewer
          onClose={() => this.setState({ showFlow: false })}
          visible={this.state.showFlow}
          datasets={this.props.datasets}
          title={this.state.isMigratingFlow ? "Migrate Flow" : "Create Flow"}
          query={this.props.transformations}
          currentWarehouse={currentWarehouse}
          dataset={this.props.datasets.find(
            (d) => d.id === this.props.activeTableKey
          )}
          extra={
            <Button
              type={"primary"}
              onClick={() => {
                this.setState({
                  showFlow: false,
                  isMigratingFlow: false,
                });
                this.props.onShowFlowMigration();
              }}
            >
              Migrate
            </Button>
          }
        />
      </>
    );
  }
}

export default compose<Props, ISpreadsheetProps>(
  WithOrg,
  withAntUtils
)(Spreadsheet);
