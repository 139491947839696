import Icon from "@ant-design/icons";
import type { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";

export const WlyWaterfallChartIcon = (
  props: Partial<CustomIconComponentProps>
) => (
  <Icon
    component={() => (
      <svg
        width="1em"
        height="1em"
        viewBox="0 0 213 213"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_1008_1420)">
          <rect y="164.966" width="27.3186" height="48.0342" fill="#41D58A" />
          <rect
            x="37.1362"
            y="136.34"
            width="27.3186"
            height="28.6264"
            fill="#41D58A"
          />
          <rect
            x="74.2725"
            y="68.8975"
            width="27.3186"
            height="67.4419"
            fill="#41D58A"
          />
          <rect x="111.409" width="27.3186" height="68.8975" fill="#41D58A" />
          <rect x="148.545" width="27.3186" height="102.861" fill="#EE5858" />
          <rect
            x="185.681"
            y="102.861"
            width="27.3186"
            height="110.139"
            fill="#3A5C83"
          />
        </g>
        <defs>
          <clipPath id="clip0_1008_1420">
            <rect width="213" height="213" fill="white" />
          </clipPath>
        </defs>
      </svg>
    )}
    {...props}
  />
);
