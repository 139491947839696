import Mustache from "mustache";
import { customFormatter } from "../../../../../../../components/chart/utils/optionsHelper";
import type { IRecord } from "../../../domain";

export const formatRecordForMustache = (rec, cols, user) => {
  return Object.keys(rec).reduce((acc, v) => {
    const currentColumn = cols.find((a) => a.data.key === v);
    const formatedValue =
      typeof currentColumn?.data?.formatter === "object"
        ? customFormatter(
            rec[v] ? rec[v] : "",
            user.locale,
            currentColumn.data.formatter
          )
        : rec[v];

    return { ...acc, [v.replace(".", "-")]: formatedValue };
  }, {});
};

export const renderMustache = (template: string, record: IRecord) => {
  try {
    return Mustache.render(template, record);
  } catch (err) {
    return "";
  }
};
