import type { Query } from "@cubejs-client/core";
import type { MeasureItemSortValue } from "../../../../../../../components/measures/measure-item/MeasureItem";
import type { IObject } from "../../../../../../../interfaces/object";
import {
  convertPropertyToAvailableProperties,
  getObjectColumns,
  isAvailableMetric,
} from "../../../../object/domain";
import type { IRecord } from "../../../domain";
import { type BaseConfig } from "../domain";
import type { IFilterEditorValue } from "../related-lists/domain";
import { buildQueryFromDimensionAndMetrics } from "../related-lists/domain";

export interface IWidgetKPIConfig extends BaseConfig {
  columns: Array<string>;
  label?: string;
  secondary?: string;
  theme?: string;
  type?: "table" | "bar" | "progress" | "none";
  barConfig?: {
    foreignObjectPropertyId: string;
    metric: string[];
    groupBy: string[];
    filters?: IFilterEditorValue;
    highlightLabel?: string;
  };
  tableConfig?: {
    foreignObjectPropertyId: string;
    metric: string[];
    groupBy: string[];
    filters?: IFilterEditorValue;
    highlightLabel?: string;
  };
}
type theme = "gray" | "blue" | "orange" | "yellow" | "green";

export type kpiTheme = {
  background: string;
  primary: string;
  secondary: string;
};

export const kpiThemes: {
  [key in theme]: kpiTheme;
} = {
  gray: {
    background: "#F7F7F8",
    primary: "#4C4C4C",
    secondary: "#AAAAAA",
  },
  blue: {
    background: "#E7F0FF",
    primary: "#184D80",
    secondary: "#85A1C1",
  },
  orange: {
    background: "#FFEFE6",
    primary: "#8D3300",
    secondary: "#BA988E",
  },
  yellow: {
    background: "#FFF5DA",
    primary: "#785800",
    secondary: "#B6A685",
  },
  green: {
    background: "#E6FAE8",
    primary: "#00640A",
    secondary: "#79ad75",
  },
};

const buildSparklineQuery = (options: {
  metrics: string[];
  dimensions: string[];
  recordFilter?: {
    recordForeignKey: string;
    recordId: string;
  };
  additionalFilters?: IFilterEditorValue;
  sortBy?: Array<[string, MeasureItemSortValue]>;
  limit?: number;
}): Query => {
  return buildQueryFromDimensionAndMetrics(
    options.dimensions,
    options.metrics,
    options.recordFilter,
    options.additionalFilters,
    options.sortBy,
    options.limit
  );
};

export const getForeignObject = (
  object: IObject,
  foreignObjectPropertyId: string
) => {
  const foreignProperty = object.foreignKeys.find(
    (p) => p.id === foreignObjectPropertyId
  );
  if (!foreignProperty) return undefined;
  return foreignProperty.object;
};

export const getSparklineQuery = (options: {
  object: IObject;
  record: IRecord;
  conf: IWidgetKPIConfig;
}) => {
  const { object, record, conf } = options;

  const sparklineConfig =
    conf.type === "bar"
      ? conf.barConfig
      : conf.type === "table"
      ? conf.tableConfig
      : undefined;

  if (!sparklineConfig) {
    return undefined;
  }

  const foreignObject = getForeignObject(
    object,
    sparklineConfig.foreignObjectPropertyId
  );

  if (!foreignObject) {
    return undefined;
  }

  const property = foreignObject.properties.find(
    (p) => p.id === sparklineConfig?.foreignObjectPropertyId
  );

  if (!property) {
    return undefined;
  }

  const foreignAvailable = convertPropertyToAvailableProperties(
    foreignObject.table.cubeName,
    foreignObject,
    property
  );
  const metrics = sparklineConfig.metric;
  const dimensions = sparklineConfig?.groupBy;
  const recordFilter = {
    recordForeignKey: `${foreignAvailable.key}_raw`,
    recordId: record[`${object.table.cubeName}.id`] as string,
  };

  if (!metrics || !dimensions) {
    return;
  }

  const metric = getObjectColumns(foreignObject)
    .filter(isAvailableMetric)
    .find((m) => m.data.key === metrics[0]);

  if (!metric) {
    return undefined;
  }

  return buildSparklineQuery({
    metrics: metrics,
    dimensions: dimensions,
    additionalFilters: sparklineConfig.filters,
    recordFilter: recordFilter,
    sortBy: metrics.map((m) => [m, "desc"]),
    limit: conf.type === "bar" ? 4 : 10,
  });
};
