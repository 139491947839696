import { computeSimplifiedTrace } from "../../../../exploration/single/performance/domain";
import type { ISimplifiedTraceChartStep } from "../../../../performance-console/trace-charts/SimplifiedTraceChart";
import SimplifiedTraceChart from "../../../../performance-console/trace-charts/SimplifiedTraceChart";
import type { DataMapStore } from "../../domain";

interface IOverallPerformanceProps {
  data: DataMapStore;
}

export function OverallPerformance(props: IOverallPerformanceProps) {
  const { data } = props;

  const formattedData = Object.keys(data).map<ISimplifiedTraceChartStep>(
    (k) => {
      const trace = data[k].trace;
      return {
        name: `${data[k].raw.name} - (${data[k].raw.slug})`,
        simplifiedTrace:
          trace && trace?.status === "success"
            ? computeSimplifiedTrace(trace.data.events)
            : [],
      };
    }
  );

  return (
    <div className="report-console-content-wrapper">
      <SimplifiedTraceChart data={formattedData} />
    </div>
  );
}
