import { createContext } from "react";

export type IHomePageContext =
  | { status: "loading" }
  | { status: "success"; data: { object: string; record: string } };

export const HomePageContext = createContext<{
  value: IHomePageContext;
  setValue: ((v: IHomePageContext) => void | undefined) | undefined;
}>({ value: { status: "loading" }, setValue: undefined });
