import * as React from "react";
import { compose } from "../../../../components/compose/WlyCompose";
import Feednack from "../../../../components/layout/feedback/feedback";
import type { AsyncData } from "../../../../helpers/typescriptHelpers";
import type { IObject } from "../../../../interfaces/object";
import type { InjectedOrgProps } from "../../../orgs/WithOrg";
import WithOrg from "../../../orgs/WithOrg";
import type { IRecordWidgetData } from "./component/domain";
import { fetchRecord, parseObjectLayout } from "./component/domain";
import RecordEmailLayout from "./component/layout/RecordEmailLayout";
import RecordLayout from "./component/layout/RecordLayout";
import type { EmbedType, IRecord } from "./domain";
interface IRecordPageProps {
  recordId: string;
  object: IObject;
  hideHeader?: boolean;
  embedType?: EmbedType;
  isSubLayout?: boolean;
}

type Props = IRecordPageProps & InjectedOrgProps;

function RecordPage(props: Props) {
  const { recordId, object, org, hideHeader, isSubLayout, embedType } = props;

  const [record, setRecord] = React.useState<AsyncData<IRecord | null>>({
    status: "initial",
  });

  React.useEffect(() => {
    fetchRecord(org.id, recordId, object, setRecord);
  }, [recordId, object?.id, org.id]);

  if (record.status === "error") {
    return (
      <Feednack>
        <div>There was an error fetching your record...</div>
      </Feednack>
    );
  }

  if (record.status === "success" && !record.data) {
    return (
      <Feednack>
        <div>This record does not seem to exists...</div>
      </Feednack>
    );
  }

  const data: IRecordWidgetData =
    record.status === "success"
      ? {
          status: "success",
          data: record.data!,
        }
      : {
          status: "loading",
        };

  if (data.status === "success" && !data.data) {
    return <Feednack>Not found</Feednack>;
  }

  const renderLayout = () => {
    const layout = parseObjectLayout(object.defaultLayout);

    if (layout.type === "EMAIL") {
      return (
        <RecordEmailLayout
          preview={false}
          object={object}
          layout={layout}
          data={data}
          edit={false}
        />
      );
    }

    return (
      <RecordLayout
        preview={false}
        object={object}
        layout={layout}
        data={data}
        edit={false}
        hideHeader={hideHeader}
        isSubLayout={isSubLayout}
        embedType={embedType}
      />
    );
  };

  return renderLayout();
}

export default compose<Props, IRecordPageProps>(WithOrg)(RecordPage);
