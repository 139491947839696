import { Divider, Form, Input, Select, Space, Typography } from "antd";
import { useForm } from "antd/es/form/Form";
import React from "react";
import type { RouteComponentProps } from "react-router";
import { withRouter } from "react-router";
import type { InjectedAntUtilsProps } from "../../../../../../components/ant-utils/withAntUtils";
import { withAntUtils } from "../../../../../../components/ant-utils/withAntUtils";
import { compose } from "../../../../../../components/compose/WlyCompose";
import { SimpleFormModal } from "../../../../../../components/form/modal/SimpleFormModal";
import { SourceImageRenderer } from "../../../../../../components/sources/SourceImageRenderer";
import type { IObject } from "../../../../../../interfaces/object";
import type { IDataset } from "../../../../../../interfaces/sources";
import type { InjectedOrgProps } from "../../../../../orgs/WithOrg";
import WithOrg, { getCurrentWarehouse } from "../../../../../orgs/WithOrg";
import type { CreateRadarFunction } from "../../../selector/radar/domain";
import { RecordKeySelector } from "./RecordKeySelector";

interface ICreateRadarModalProps {
  datasets: IDataset[];
  objects: IObject[];
  open: boolean;
  onClose: () => void;
  onCreate: CreateRadarFunction;
}

interface CreateObjectFormValue {
  name: string;
  recordIdKey: string;
  model: {
    id: string;
  };
  object: {
    id: string;
  };
}

type Props = ICreateRadarModalProps &
  InjectedAntUtilsProps &
  InjectedOrgProps &
  RouteComponentProps<{ warehouseSlug: string }>;

function CreateRadarModal(props: Props) {
  const {
    datasets,
    objects,
    open,
    onClose,
    onCreate,
    org,
    match: {
      params: { warehouseSlug },
    },
  } = props;
  const [form] = useForm<CreateObjectFormValue>();

  const [loading, setLoading] = React.useState<boolean>(false);
  const currentWarehouse = getCurrentWarehouse(org, warehouseSlug);

  return (
    <SimpleFormModal
      isSaving={loading}
      form={form}
      open={open}
      onCancel={onClose}
      modalProps={{ title: "Create a radar" }}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={async (values) => {
          try {
            setLoading(true);
            await onCreate(values);
          } catch (error) {
            console.error(error);
          } finally {
            setLoading(false);
            onClose();
          }
        }}
      >
        <div style={{ paddingBottom: 12 }}>
          <Typography.Text type="secondary">
            A radar periodically extracts signals from a model to create
            notifications on an object
          </Typography.Text>
        </div>
        <Form.Item label={"Name"} name={["name"]} rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item
          label={"Model"}
          name={["model", "id"]}
          rules={[{ required: true }]}
          help={
            "The model that will be executed every period to extract the signals from."
          }
        >
          <Select style={{ width: "100%" }} showSearch optionFilterProp="label">
            {datasets
              .slice()
              .filter((d) => d.views?.length && d.isModel)
              .sort(function (a, b) {
                if (a.name < b.name) {
                  return -1;
                }
                if (a.name > b.name) {
                  return 1;
                }
                return 0;
              })
              .map((v) => {
                return (
                  <Select.Option key={v.id} value={v.id} label={v.name}>
                    <Space>
                      <SourceImageRenderer
                        alt={"sourceName"}
                        className="source-table-selection-logo"
                        img={
                          !v.isModel
                            ? v.source.sourceMeta.publicInfo.logo
                            : undefined
                        }
                        size={16}
                        isModel={v.isModel}
                      />
                      {v.name}
                      <Typography.Text type="secondary">
                        {v.isModel ? "Model" : v.source?.name}
                      </Typography.Text>
                    </Space>
                  </Select.Option>
                );
              })}
          </Select>
        </Form.Item>
        <Form.Item shouldUpdate={true}>
          {() => {
            const modelId = form.getFieldValue(["model", "id"]);
            if (!modelId) return null;
            const model = datasets.find((d) => d.id === modelId);
            if (!model) return null;
            return (
              <>
                <Divider style={{ marginTop: 48 }} />
                <Form.Item
                  label={"Object"}
                  name={["object", "id"]}
                  rules={[{ required: true }]}
                  help={
                    "The object on which you want to attach your notifications to"
                  }
                >
                  <Select
                    style={{ width: "100%" }}
                    showSearch
                    optionFilterProp="label"
                  >
                    {objects
                      .slice()
                      .sort(function (a, b) {
                        if (a.name < b.name) {
                          return -1;
                        }
                        if (a.name > b.name) {
                          return 1;
                        }
                        return 0;
                      })
                      .map((v) => {
                        return (
                          <Select.Option key={v.id} value={v.id} label={v.name}>
                            <Space>{v.name}</Space>
                          </Select.Option>
                        );
                      })}
                  </Select>
                </Form.Item>
                <Form.Item
                  label={"Record key"}
                  name={["recordIdKey"]}
                  rules={[{ required: true }]}
                  help={
                    "The key in your model that represents the record of your object"
                  }
                >
                  <RecordKeySelector
                    model={model}
                    currentWarehouse={currentWarehouse}
                  />
                </Form.Item>
              </>
            );
          }}
        </Form.Item>
      </Form>
    </SimpleFormModal>
  );
}

export default compose<Props, ICreateRadarModalProps>(
  WithOrg,
  withRouter,
  withAntUtils
)(CreateRadarModal);
