import { CloseOutlined } from "@ant-design/icons";
import type { DrawerProps } from "antd";
import { Drawer } from "antd";
import * as React from "react";

import "./Drawer.scss";

interface IResizableDrawerProps extends Omit<DrawerProps, "children"> {
  visible: boolean;
  defaultHeight?: number;
  setVisible: (b: boolean) => void;
  children: (
    height: number,
    setTitle: (title: string) => void,
    setExtra: (extra?: React.ReactNode) => void
  ) => React.ReactNode;
}

const drawerHeight = 320;

export const ResizableDrawer: React.FunctionComponent<IResizableDrawerProps> = (
  props
) => {
  const { visible, setVisible, defaultHeight, children, ...rest } = props;
  const [title, setTitle] = React.useState<string | undefined>();
  const [extra, setExtra] = React.useState<React.ReactNode | undefined>();

  React.useEffect(() => {
    if (visible === false) {
      setTitle(undefined);
      setExtra(undefined);
    }
  }, [visible]);

  const setMainTitle = (t: string) => {
    if (title !== t) {
      setTitle(t);
    }
  };

  const setMainExtra = (e: React.ReactNode) => {
    if ((extra && !e) || (e && !extra)) {
      setExtra(e);
    }
  };

  return (
    <Drawer
      placement={"bottom"}
      closable={true}
      onClose={(e) => {
        e.stopPropagation();
        setVisible(false);
      }}
      open={visible}
      key={"bottom"}
      height={"calc(100% - 46px)"}
      styles={{
        body: { padding: 0, flexDirection: "column", display: "flex" },
        header: { display: "none" },
      }}
      push={false}
      {...rest}
    >
      {children(drawerHeight, setMainTitle, setMainExtra)}
    </Drawer>
  );
};

interface IResizableDrawerHeaderProps {
  onClose: () => void;
  title: string;
  extra?: React.ReactNode;
}

export const ResizableDrawerHeader: React.FunctionComponent<
  IResizableDrawerHeaderProps
> = (props) => {
  return (
    <div
      className="ant-drawer-header"
      style={{ paddingLeft: 12, paddingRight: 12, maxHeight: 56 }}
    >
      <div className="ant-drawer-header-title">
        <button
          type="button"
          onClick={props.onClose}
          aria-label="Close"
          className={`ant-close`}
        >
          <CloseOutlined />
        </button>
        <div className="ant-drawer-title">{props.title}</div>
      </div>
      <div className={`ant-extra`}>{props.extra}</div>
    </div>
  );
};

export const ResizableDrawerBody: React.FunctionComponent<{
  children?: React.ReactNode;
}> = (props) => {
  return (
    <div
      className="ant-drawer-body"
      style={{ padding: 0, display: "flex", flexDirection: "column" }}
    >
      {props.children}
    </div>
  );
};
