import { HighlightStyle } from "@codemirror/language";
import { tags } from "@lezer/highlight";

export const formulaHighlightStyle = HighlightStyle.define([
  { tag: tags.number, color: "#1a8f15" },
  { tag: tags.string, color: "#e40" },
  { tag: tags.atom, color: "#337AFF" },
  // we define a custom class to be able to parse the dom
  // and extract columns names to send to apis
  { tag: tags.keyword, class: "wly-column" },
  { tag: tags.variableName, color: "#DD33FF" },
]);
