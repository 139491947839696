import { Col, Row, Space, Typography } from "antd";
import _ from "lodash";
import * as React from "react";
import { compose } from "../../../../../../components/compose/WlyCompose";
import type { IObject } from "../../../../../../interfaces/object";
import type { InjectedOrgProps } from "../../../../../orgs/WithOrg";
import WithOrg from "../../../../../orgs/WithOrg";
import type {
  IColumn,
  ILayout,
  IRecordAction,
  IRecordWidgetData,
  IRow,
  IWidget,
} from "../domain";
import { layoutObjectData, widgetObjectData } from "../domain";
import RecordHeader from "../header/RecordHeader";

import {
  ArrowDownOutlined,
  ArrowUpOutlined,
  CopyFilled,
  DeleteFilled,
} from "@ant-design/icons";
import type { RouteComponentProps } from "react-router";
import { withRouter } from "react-router";
import { IObjectLayoutItemType } from "../../../../../custom-layout-editor/main/sider/add/domain";
import { generateFakeId } from "../../../../../workbench/workbench/exploration/domain";
import { EmbedType } from "../../domain";
import { shouldDisplayWidget } from "../common/visibility-filters/domain";
import { RecordHomePageHeader } from "../header/RecordHomePageHeader";
import RecordDocumentRenderer from "../panels/RecordDocumentRenderer";
import RecordDocuments from "../panels/RecordDocuments";
import { WidgetRenderer } from "../widgets/WidgetRenderer";
import { EmptyColumnDropZone } from "./EmptyColumnDropZone";
import { HoverWrapper } from "./HoverWrapper";
import "./RecordLayout.scss";
import { WidgetDrag } from "./WidgetDrag";

type IRecordLayoutProps = IRecordLayoutEditionProps | IRecordLayoutDisplayProps;

interface IRecordLayoutEditionProps extends IRecordLayoutDisplayProps {
  edit: true;
  hovered?: string;
  selected?: string;
  setAction: (action: "hover" | "click", key: string) => void;
  onLayoutChange: (actions: IRecordAction[]) => void;
}

interface IRecordLayoutDisplayProps {
  layout: ILayout;
  object: IObject;
  data: IRecordWidgetData;
  edit: boolean;
  preview: boolean;
  boxed?: boolean;
  hideHeader?: boolean;
  embedType?: EmbedType;
  isSubLayout?: boolean;
}

interface IRecordLayoutState {
  panel: "FOLDER" | null;
}

type Props = IRecordLayoutProps &
  InjectedOrgProps &
  RouteComponentProps<{ documentId?: string }>;

interface IInner<T, S extends string> {
  id: string;
  position: number;
  type: S;
  data: T;
}

type IInnerColumn =
  | IInner<IRow, "row">
  | IInner<IWidget, "widget">
  | IInner<{}, "space">;

class RecordLayout extends React.Component<Props, IRecordLayoutState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      panel: null,
    };
  }

  buildWidget = (widget: IWidget, colId: string, p: number) => {
    const { data, object, org, layout } = this.props;
    const { edit, hovered, selected, setAction, onLayoutChange } = this
      .props as IRecordLayoutEditionProps;

    const renderInner = () => {
      return (
        <WidgetRenderer
          object={object}
          widget={widget}
          record={data}
          edit={edit}
        />
      );
    };

    return (
      <HoverWrapper
        componentKey={`widget::${widget.id}`}
        componentLabel={"Widget"}
        edit={edit}
        hovered={hovered}
        selected={selected}
        setAction={setAction}
        drop={{
          accept: IObjectLayoutItemType.WIDGET,
          onDrop: (i, position) => {
            if (i.operation === "add") {
              const widgetItem = widgetObjectData[i.identifier];
              if (!widgetItem) {
                return alert("item does not exists");
              }

              const gen = widgetItem.generate(
                widget.position,
                widget.parentColumn?.id
              );
              onLayoutChange([
                { type: "widget::add", data: gen },
                {
                  type: "widget::move",
                  data: gen,
                  position: position === "top" ? "top" : "bottom",
                },
              ]);
            } else {
              const parentId = colId;

              const prevParentId = i.data.parentColumn?.id;

              const fromPositionFromLayout = layout.widgets.find(
                (w) => w.id === i.identifier
              );
              const toPositionFromLayout = layout.widgets.find(
                (w) => w.id === widget.id
              );

              const data = {
                ...i.data,
                position: p,
                parentColumn: {
                  id: parentId,
                },
              };

              // alert(
              //   `moved from position ${
              //     i.data.position
              //   } layout.widget.position ${
              //     fromPositionFromLayout?.position
              //   } to widget.position: ${
              //     widget.position
              //   } and layout.widget.position ${
              //     toPositionFromLayout?.position
              //   } on ${position}. We have ${
              //     parentId !== prevParentId ? "" : "not "
              //   } changed columns`
              // );
              onLayoutChange([
                {
                  type: "widget::move",
                  data: Object.assign({}, data),
                  colIdToReorder:
                    parentId !== prevParentId ? prevParentId : undefined,
                  position: position === "top" ? "top" : "bottom",
                },
              ]);
            }
          },
        }}
        toolbox={[
          // {
          //   key: "position",
          //   name: "Position",
          //   icon: widget.position,
          //   onClick: () => console.log("ok"),
          // },
          {
            key: "duplicate",
            name: "Duplicate",
            icon: <CopyFilled />,
            onClick: () => {
              const id = generateFakeId();
              const newWidget = {
                ...widget,
                id,
              };
              onLayoutChange([
                {
                  type: "widget::add",
                  data: newWidget,
                },
                {
                  type: "widget::move",
                  position: "bottom",
                  data: newWidget,
                },
              ]);
              setAction("click", `widget::${id}`);
            },
          },
          {
            key: "delete",
            name: "Delete",
            icon: <DeleteFilled />,
            onClick: () => {
              setAction("click", "body");
              onLayoutChange([{ type: "widget::remove", data: widget }]);
            },
          },
        ]}
      >
        <WidgetDrag edit={edit} widget={widget}>
          {renderInner()}
        </WidgetDrag>
      </HoverWrapper>
    );
  };

  buildColumns = (cols: IColumn[]) => {
    const { data, object, edit, hovered, selected, setAction, onLayoutChange } =
      this.props as IRecordLayoutEditionProps;

    return _.sortBy(cols, ["position"]).map((c) => {
      const { layout } = this.props;
      const innerRows: Array<IInner<IRow, "row">> = layout.rows
        .filter((f) => f.parentColumn?.id === c.id)
        .map((w) => ({ data: w, type: "row", position: w.position, id: w.id }));
      const innerWidgets: Array<IInner<IWidget, "widget">> = layout.widgets
        .filter((f) => f.parentColumn?.id === c.id)
        .map((w) => ({
          data: w,
          type: "widget",
          position: w.position,
          id: w.id,
        }));

      const inner: IInnerColumn[] = _.sortBy(
        [...innerRows, ...innerWidgets],
        ["position"]
      );

      const renderInnerColumn = (i: IInnerColumn) => {
        if (i.type === "row") {
          return this.buildRows([i.data]);
        } else if (i.type === "widget") {
          return this.buildWidget(i.data, c.id, i.data.position);
        } else if (i.type === "space") {
          return (
            <EmptyColumnDropZone
              colId={c.id}
              drop={{
                accept: IObjectLayoutItemType.WIDGET,
                onDrop: (d) => {
                  if (d.operation === "add") {
                    const widgetItem = widgetObjectData[d.identifier];
                    if (!widgetItem) {
                      return alert("item does not exists");
                    }
                    const gen = widgetItem.generate(0, c.id);
                    onLayoutChange([{ type: "widget::add", data: gen }]);
                  } else if (d.operation === "move") {
                    const parentId = c.id;
                    const prevParentId = d.data.parentColumn?.id;

                    const ndata = {
                      ...d.data,
                      position: 0,
                      parentColumn: {
                        id: parentId,
                      },
                    };

                    // alert(
                    //   `moved from position ${
                    //     d.data.position
                    //   } to position 0. We have ${
                    //     parentId !== prevParentId ? "" : "not "
                    //   } changed columns`
                    // );
                    onLayoutChange([
                      {
                        type: "widget::move",
                        data: ndata,
                        position: "top",
                        colIdToReorder:
                          parentId !== prevParentId ? prevParentId : undefined,
                      },
                    ]);
                  }
                },
              }}
            >
              Drop something here
            </EmptyColumnDropZone>
          );
        }
      };

      if (edit && inner.length === 0) {
        inner.unshift({
          id: generateFakeId(),
          position: 0,
          type: "space",
          data: {},
        });
      }

      return (
        <Col key={c.id} xs={24} md={c.size}>
          <HoverWrapper
            componentKey={`col::${c.id}`}
            componentLabel={`Col`}
            edit={edit}
            hovered={hovered}
            selected={selected}
            setAction={setAction}
          >
            <Space
              style={{ width: "100%", display: "flex" }}
              size={"middle"}
              direction="vertical"
            >
              {inner
                .filter((i) => {
                  if (edit) {
                    return true;
                  }
                  if (i.type === "widget") {
                    if (i.data.displayFilters) {
                      return shouldDisplayWidget(
                        i.data.displayFilters,
                        data.status === "success" ? data.data : {},
                        object
                      );
                    }
                  }
                  return true;
                })
                .map((i) => {
                  return <div key={i.id}>{renderInnerColumn(i)}</div>;
                })}
            </Space>
          </HoverWrapper>
        </Col>
      );
    });
  };

  buildRows = (rows: IRow[], attachTo?: { type: "widget"; id: string }) => {
    const { data, object, edit, hovered, selected, setAction, onLayoutChange } =
      this.props as IRecordLayoutEditionProps;

    const getSortedRows = () => _.sortBy(rows, ["position"]);

    const sortedRows = getSortedRows();

    if (sortedRows.length === 0 && edit) {
      return (
        <EmptyColumnDropZone
          colId={"initial"}
          drop={{
            accept: IObjectLayoutItemType.LAYOUT,
            onDrop: (i) => {
              const layoutItem = layoutObjectData[i.identifier];
              if (!layoutItem) {
                return alert("item does not exists");
              }

              const newItemPosition = 0;

              const gen = layoutItem.generate(newItemPosition);
              onLayoutChange([{ type: "layout::add", data: gen }]);
            },
          }}
        >
          Drop something here
        </EmptyColumnDropZone>
      );
    }

    return sortedRows
      .filter((r) => {
        if (edit) {
          return true;
        }
        if (r.displayFilters) {
          const shouldDisplay = shouldDisplayWidget(
            r.displayFilters,
            data.status === "success" ? data.data : {},
            object
          );
          return shouldDisplay;
        }
        return true;
      })
      .map((r, i, s) => {
        return (
          <HoverWrapper
            componentKey={`row::${r.id}`}
            componentLabel={"Row"}
            edit={edit}
            hovered={hovered}
            selected={selected}
            setAction={setAction}
            key={r.id}
            toolbox={[
              // {
              //   key: "position",
              //   name: "Position",
              //   icon: r.position,
              //   onClick: () => console.log("ok"),
              // },
              ...(i !== 0
                ? [
                    {
                      key: "move-up",
                      name: "Move up",
                      icon: <ArrowUpOutlined />,
                      onClick: () => {
                        onLayoutChange([
                          {
                            type: "layout::move",
                            data: { ...r, position: r.position - 1 },
                            position: "top",
                          },
                        ]);
                      },
                    },
                  ]
                : []),
              ...(i !== s.length - 1
                ? [
                    {
                      key: "move-down",
                      name: "Move down",
                      icon: <ArrowDownOutlined />,
                      onClick: () => {
                        onLayoutChange([
                          {
                            type: "layout::move",
                            data: { ...r, position: r.position + 1 },
                            position: "top",
                          },
                        ]);
                      },
                    },
                  ]
                : []),
              {
                key: "delete",
                name: "Delete",
                icon: <DeleteFilled />,
                onClick: () => {
                  setAction("click", "body");
                  onLayoutChange([{ type: "layout::remove", data: r }]);
                },
              },
            ]}
            drop={
              edit
                ? {
                    accept: IObjectLayoutItemType.LAYOUT,
                    onDrop: (i, position) => {
                      const layoutItem = layoutObjectData[i.identifier];
                      if (!layoutItem) {
                        return alert("item does not exists");
                      }

                      const newPosition =
                        position === "top" ? r.position : r.position + 1;
                      const gen = layoutItem.generate(newPosition);

                      onLayoutChange([
                        {
                          type: "layout::add",
                          data: gen,
                        },
                        {
                          type: "layout::move",
                          data: gen,
                          position: "top",
                        },
                      ]);
                    },
                  }
                : undefined
            }
          >
            <Row style={{ marginTop: 8, padding: `8px 0` }} gutter={[16, 16]}>
              {(r.name || r.description) && (
                <Col xs={24}>
                  <Space direction="vertical" style={{ width: "100%" }}>
                    {r.name && (
                      <Typography.Title level={4} style={{ marginBottom: 0 }}>
                        {r.name}
                      </Typography.Title>
                    )}
                    {r.description && (
                      <Typography.Text type="secondary">
                        {r.description}
                      </Typography.Text>
                    )}
                  </Space>
                </Col>
              )}
              {this.buildColumns(r.columns)}
            </Row>
          </HoverWrapper>
        );
      });
  };

  render() {
    const {
      layout,
      data,
      object,
      preview,
      boxed,
      hideHeader,
      isSubLayout,
      embedType,
      match: {
        params,
        params: { documentId },
      },
    } = this.props;
    const { edit, hovered, selected, setAction } = this
      .props as IRecordLayoutEditionProps;

    return (
      <div
        className={`record-view record-layout ${edit ? "edition" : ""} ${
          boxed ? "boxed" : ""
        }`}
      >
        {!hideHeader && (
          <HoverWrapper
            componentKey="header"
            componentLabel={"Header"}
            edit={edit}
            hovered={hovered}
            selected={selected}
            setAction={setAction}
          >
            <RecordHeader
              object={object}
              edit={edit}
              config={layout.header}
              data={data}
              preview={preview}
              embedType={embedType}
              disableEdit={hideHeader}
              action={() =>
                this.state.panel === "FOLDER"
                  ? this.setState({ panel: null })
                  : this.setState({ panel: "FOLDER" })
              }
            />
          </HoverWrapper>
        )}
        {!isSubLayout && embedType === EmbedType.home && (
          <RecordHomePageHeader data={data} object={object} />
        )}

        <div className="record-view-body">
          <div
            className={
              isSubLayout
                ? "record-view-body-content is-sub-layout"
                : "record-view-body-content"
            }
          >
            {documentId &&
            embedType !== EmbedType.home &&
            data.status === "success" ? (
              <RecordDocumentRenderer
                documentId={documentId}
                object={object}
                data={data.data}
                embedType={embedType}
              />
            ) : (
              this.buildRows(layout.rows)
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default compose<Props, IRecordLayoutProps>(
  WithOrg,
  withRouter
)(RecordLayout);
