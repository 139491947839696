import Icon from "@ant-design/icons";
import type { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";

export const WlyHearbeatIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon
    component={() => (
      <svg
        width="1em"
        height="1em"
        viewBox="0 0 92 64"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M40.7188 0.995442C40.0846 1.05363 39.4853 1.31206 39.0078 1.73342C38.5303 2.15477 38.1992 2.71719 38.0625 3.33919L30.5938 36.4641L26.5 30.3079C26.2266 29.895 25.8552 29.5562 25.419 29.3217C24.9827 29.0873 24.4952 28.9644 24 28.9641H4C3.60247 28.9585 3.20778 29.032 2.83887 29.1802C2.46997 29.3284 2.13421 29.5485 1.8511 29.8277C1.56799 30.1068 1.34318 30.4394 1.18974 30.8062C1.03629 31.1729 0.957275 31.5666 0.957275 31.9641C0.957275 32.3617 1.03629 32.7553 1.18974 33.1221C1.34318 33.4889 1.56799 33.8215 1.8511 34.1006C2.13421 34.3797 2.46997 34.5998 2.83887 34.7481C3.20778 34.8963 3.60247 34.9698 4 34.9641H22.375L29.5 45.6516C29.8239 46.1448 30.2861 46.5315 30.8287 46.7634C31.3712 46.9953 31.9702 47.0621 32.5505 46.9554C33.1308 46.8487 33.6668 46.5732 34.0914 46.1634C34.5159 45.7536 34.8102 45.2278 34.9375 44.6516L40.5 19.8704L47.0312 60.4641C47.136 61.1611 47.4828 61.799 48.0108 62.2659C48.5388 62.7328 49.2143 62.999 49.9189 63.0176C50.6235 63.0362 51.3121 62.8063 51.8641 62.3679C52.416 61.9296 52.796 61.3109 52.9375 60.6204L60.3125 26.2766L64.4063 33.4641C64.6652 33.9254 65.0415 34.31 65.497 34.5789C65.9525 34.8478 66.4711 34.9915 67 34.9954L88 34.9644C88.3975 34.9701 88.7922 34.8966 89.1611 34.7484C89.53 34.6001 89.8658 34.38 90.1489 34.1009C90.432 33.8218 90.6568 33.4892 90.8103 33.1224C90.9637 32.7556 91.0427 32.362 91.0427 31.9644C91.0427 31.5669 90.9637 31.1732 90.8103 30.8065C90.6568 30.4397 90.432 30.1071 90.1489 29.828C89.8658 29.5488 89.53 29.3287 89.1611 29.1805C88.7922 29.0323 88.3975 28.9588 88 28.9644L68.75 28.9954L61.5938 16.4954C61.2925 15.9706 60.8396 15.5491 60.2944 15.2863C59.7493 15.0235 59.1374 14.9317 58.5392 15.023C57.9409 15.1142 57.3842 15.3843 56.9422 15.7976C56.5002 16.211 56.1936 16.7484 56.0625 17.3392L50.4375 43.6516L43.9688 3.49544C43.8453 2.74414 43.4409 2.06781 42.8374 1.6036C42.234 1.13938 41.4766 0.922009 40.7188 0.995442Z"
          fill="currentColor"
        />
      </svg>
    )}
    {...props}
  />
);
