import { AutoComplete } from "antd";
import * as React from "react";
import { compose } from "../../../../../../components/compose/WlyCompose";
import Loading from "../../../../../../components/layout/feedback/loading";
import type { AsyncData } from "../../../../../../helpers/typescriptHelpers";
import type { IReport } from "../../../../../../interfaces/reports";
import { routeDescriptor } from "../../../../../../routes/routes";
import GraphQLService from "../../../../../../services/graphql/GraphQLService";
import type { InjectedOrgProps } from "../../../../../orgs/WithOrg";
import WithOrg from "../../../../../orgs/WithOrg";

interface ILinkPickerProps {
  value?: string;
  onChange?: (e: string) => void;
}

interface IState {
  data: AsyncData<IReport[]>;
}

type Props = ILinkPickerProps & InjectedOrgProps;

class LinkPicker extends React.Component<Props, IState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      data: {
        status: "initial",
      },
    };
  }

  componentDidMount() {
    this.fetchData(this.props.org.id);
  }

  fetchData = (orgId: string) => {
    this.setState({ data: { status: "loading" } });
    return GraphQLService(
      `
    query getAllReports($orgId: ID!) {
      allReports(where: { org: { id: $orgId }, deleted_not: true }) {
        id
        slug
        name
        org {
          slug
        }
      }
    }
    `,
      {
        orgId: orgId,
      }
    )
      .then((r) => {
        this.setState({
          data: {
            status: "success",
            data: r.allReports as IReport[],
          },
        });
      })
      .catch((err) => {
        this.setState({
          data: {
            status: "error",
            error: err,
          },
        });
      });
  };

  public render() {
    const { data } = this.state;

    if (data.status === "initial" || data.status === "loading") {
      return <Loading />;
    }

    if (data.status === "error") {
      return <div>{data.error.message}</div>;
    }

    return (
      <AutoComplete
        placeholder="Add an url to an external link or select a report"
        {...this.props}
        style={{ width: "100%" }}
      >
        {data.data.map((d) => {
          return (
            <AutoComplete.Option
              key={d.id}
              value={routeDescriptor.report.renderRoute({
                organizationSlug: d.org.slug,
                reportSlug: d.slug,
              })}
            >
              {d.name}
            </AutoComplete.Option>
          );
        })}
      </AutoComplete>
    );
  }
}

export default compose<Props, ILinkPickerProps>(WithOrg)(LinkPicker);
