import { MinusCircleOutlined } from "@ant-design/icons";
import type {
  BinaryFilter,
  BinaryOperator,
  UnaryFilter,
} from "@cubejs-client/core";
import { Button, Space } from "antd";
import { DroppableMeasureZone } from "../measure-picker/DroppableMeasureZone";
import { AddFilterButton } from "./AddFilterButton";
import type {
  AvailableDimension,
  IAvailableDimensionGroup,
} from "./FilterItem";
import FilterItem from "./FilterItem";
import "./FilterItem.scss";

interface IFilterGroupItemProps {
  filterOperator: "and" | "or";
  filters: (UnaryFilter | BinaryFilter)[];
  availableDimensions: Array<AvailableDimension | IAvailableDimensionGroup>;
  onFilterOperatorChange: (FilterOperator: "or" | "and") => void;
  autocomplete?: (
    dimensionName: string,
    operator?: BinaryOperator,
    value?: string
  ) => Promise<string[]>;
  onGroupDelete: () => void;
  onDelete: (index: number) => void;
  onFiltersChange: (filters: (BinaryFilter | UnaryFilter)[]) => void;
}

export function FilterGroupItem(props: IFilterGroupItemProps) {
  const {
    filterOperator,
    onFilterOperatorChange,
    filters,
    availableDimensions,
    onDelete,
    onFiltersChange,
    autocomplete,
    onGroupDelete,
  } = props;

  const hasGroup =
    filters.length && filters.every((f) => !!(f as any).and || !!(f as any).or);

  const ads = availableDimensions.flatMap((ad) =>
    ad.type === "group" ? ad.availableDimensions : [ad]
  );

  const wrapInDroppable = (r: React.ReactElement): React.ReactElement => {
    if (hasGroup) {
      return r;
    }
    return (
      <DroppableMeasureZone
        zone="dimensions_filters"
        accept={["dimension"]}
        availableMeasures={availableDimensions}
        onAddMeasure={(i) =>
          onFiltersChange([
            ...filters,
            {
              member: i,
              operator: "set",
            },
          ])
        }
      >
        {r}
      </DroppableMeasureZone>
    );
  };

  return wrapInDroppable(
    <Space direction="vertical" className="filter-group-item">
      <div className="filter-group-item-header">
        <div className="filter-group-item-header-title">
          <Button
            size="small"
            onClick={() =>
              onFilterOperatorChange(filterOperator === "or" ? "and" : "or")
            }
          >
            {filterOperator === "or" ? "any" : "all"}
          </Button>{" "}
          of the following:
        </div>
        <div onClick={onGroupDelete} className="filter-group-item-remove">
          <MinusCircleOutlined />
        </div>
      </div>
      {filters.flatMap((f, i, s) => {
        if ((f as any).and || (f as any).or) {
          const operator = Object.keys(f)[0] as "and" | "or";
          const subFilters = (f as any)[operator];
          return [
            <FilterGroupItem
              key={i}
              filterOperator={operator}
              filters={subFilters}
              availableDimensions={availableDimensions}
              onFilterOperatorChange={() => {
                onFiltersChange(
                  filters.map((a, index) => {
                    const nextKey = operator === "and" ? "or" : "and";
                    if (index === i) {
                      return {
                        [nextKey]: subFilters,
                      } as any;
                    }
                    return a;
                  })
                );
              }}
              autocomplete={autocomplete}
              onDelete={(index) =>
                onFiltersChange(
                  filters.map((a, n) => {
                    if (n === i) {
                      return {
                        [operator]: subFilters.filter((s, j) => j !== index),
                      } as any;
                    }
                    return a;
                  })
                )
              }
              onGroupDelete={() => {
                const newFilters = filters.filter((a, index) => i !== index);
                if (newFilters.length === 1) {
                  if ((newFilters[0] as any).or) {
                    onFiltersChange((newFilters[0] as any).or);
                  } else if ((newFilters[0] as any).and) {
                    onFiltersChange((newFilters[0] as any).and);
                  }
                } else {
                  onFiltersChange(newFilters);
                }
              }}
              onFiltersChange={(filts) =>
                onFiltersChange(
                  filters.map((a, j) => {
                    if (j === i) {
                      return {
                        [operator]: filts,
                      } as any;
                    }
                    return a;
                  })
                )
              }
            />,
            i !== s.length - 1 ? (
              <div style={{ textAlign: "center" }}>
                <Button
                  onClick={() =>
                    onFilterOperatorChange(
                      filterOperator === "and" ? "or" : "and"
                    )
                  }
                  size="small"
                >
                  {filterOperator === "and" ? "And" : "Or"}
                </Button>
              </div>
            ) : null,
          ];
        }
        return [
          <FilterItem
            key={i}
            filter={f}
            availableDimensions={availableDimensions}
            onDelete={() => onDelete(i)}
            onChange={(filter) =>
              onFiltersChange(
                filters.map((a, j) => {
                  if (j === i) {
                    return filter;
                  }
                  return a;
                })
              )
            }
            autocomplete={autocomplete}
          />,
        ];
      })}
      <AddFilterButton
        availableDimensions={ads}
        filters={filters}
        setFilters={onFiltersChange}
      />
    </Space>
  );
}
