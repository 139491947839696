import { MinusCircleOutlined } from "@ant-design/icons";
import {
  Button,
  Checkbox,
  Form,
  Input,
  InputNumber,
  Select,
  Switch,
} from "antd";
import { useForm } from "antd/lib/form/Form";
import _ from "lodash";
import React from "react";
import type { InjectedAntUtilsProps } from "../../../components/ant-utils/withAntUtils";
import { withAntUtils } from "../../../components/ant-utils/withAntUtils";
import { compose } from "../../../components/compose/WlyCompose";
import FormActions from "../../../components/form/actions/FormActions";
import type { AsyncData } from "../../../helpers/typescriptHelpers";
import type {
  IUserAttributeMeta,
  IUserAttributeMetaType,
} from "../../../interfaces/org";
import GraphQLService from "../../../services/graphql/GraphQLService";
import type { InjectedOrgProps } from "../../orgs/WithOrg";
import WithOrg from "../../orgs/WithOrg";
import { WlyDatePicker } from "../../reports/view/filters/date-filter/WlyDatePicker";
import { parseUserAttributeMeta } from "./domain";
import { UserAttributeRenderer } from "./UserAttributeRenderer";

interface IUserAttributesFormProps {
  onClose: () => void;
  userAttributeMeta?: IUserAttributeMeta;
  onSubmit: () => Promise<void>;
  visible: boolean;
}

type Props = InjectedOrgProps &
  IUserAttributesFormProps &
  InjectedAntUtilsProps;

interface FormValues
  extends Pick<
    IUserAttributeMeta,
    "label" | "technicalName" | "type" | "allowMultipleValues"
  > {
  options?: Array<{
    value: string;
    label: string;
  }>;
  hasDefaultValue: boolean;
  restrictSelection?: boolean;
  defaultValue: string[];
}

function UserAttributesForm(props: Props) {
  const { onClose, userAttributeMeta, org, onSubmit, antUtils, visible } =
    props;
  const [form] = useForm<FormValues>();
  const [state, setState] = React.useState<AsyncData<undefined>>({
    status: "initial",
  });

  const [validate, setValidate] = React.useState<AsyncData<undefined>>({
    status: "initial",
  });

  React.useEffect(() => {
    if (visible) {
      form.resetFields();
    }
  }, [visible]);

  const renderValidateStatus = ():
    | ""
    | "error"
    | "success"
    | "warning"
    | "validating" => {
    switch (validate.status) {
      case "error":
        return "error";
      case "initial":
        return "";
      case "loading":
        return "validating";
      case "success":
        return "success";
    }
  };

  const validateTechnicalName = async (technicalName: string) => {
    setValidate({ status: "loading" });

    if (!technicalName) {
      setValidate({
        status: "error",
        error: new Error("Please set up a value..."),
      });
      return false;
    }
    const re = new RegExp(/^\S[a-z0-9_]+/gm);
    const match = re.exec(technicalName);
    if (!match || (match && match.length && match[0] !== technicalName)) {
      setValidate({
        status: "error",
        error: new Error(
          "Should only contain alphanumeric characters and underscores and no whitespaces."
        ),
      });
      return false;
    }
    try {
      const data = await GraphQLService<{
        allUserAttributeMetas: Array<{ id: string }>;
      }>(
        `
      query validateUserAttributeMetaTechnicalName($technicalName: String!, $orgId: ID!, $id: ID) {
        allUserAttributeMetas(where: {
          id_not: $id,
          technicalName: $technicalName,
          deleted_not: true,
          org: {
            id: $orgId
          }
        }) {
          id
        }
      } 
      `,
        {
          orgId: org.id,
          technicalName,
          id: userAttributeMeta?.id,
        }
      );
      if (data.allUserAttributeMetas.length === 0) {
        setValidate({ status: "success", data: undefined });
        return true;
      } else {
        setValidate({
          status: "error",
          error: new Error(
            "There is already a user attribute sharing this technical name"
          ),
        });
        return false;
      }
    } catch (err) {
      setValidate({
        status: "error",
        error: new Error("There was an error fetching data"),
      });
      return false;
    }
  };

  const debouncedValidate = _.debounce(validateTechnicalName, 500);

  const parsedUserAttributeMeta = userAttributeMeta
    ? parseUserAttributeMeta(userAttributeMeta)
    : null;

  const initialValues: FormValues = userAttributeMeta
    ? {
        type: parsedUserAttributeMeta.type,
        label: parsedUserAttributeMeta.label,
        technicalName: parsedUserAttributeMeta.technicalName,
        options: parsedUserAttributeMeta.options,
        restrictSelection: !!parsedUserAttributeMeta.options,
        allowMultipleValues: parsedUserAttributeMeta.allowMultipleValues,
        hasDefaultValue: parsedUserAttributeMeta.defaultValue?.length > 0,
        defaultValue: parsedUserAttributeMeta.defaultValue
          ? parsedUserAttributeMeta.defaultValue
          : [],
      }
    : {
        type: "STRING",
        label: "",
        technicalName: "",
        restrictSelection: false,
        allowMultipleValues: false,
        hasDefaultValue: false,
        defaultValue: [],
      };

  const convertFormValueToPayload = (v: FormValues) => {
    const {
      restrictSelection,
      options,
      hasDefaultValue,
      defaultValue,
      ...rest
    } = v;
    return {
      ...rest,
      options: options ? JSON.stringify(options) : null,
      defaultValue:
        hasDefaultValue && defaultValue ? JSON.stringify(defaultValue) : null,
    };
  };

  const create = (v: FormValues) => {
    return GraphQLService(
      `mutation createUserAttributeMeta($data: UserAttributeMetaCreateInput!) {
        createUserAttributeMeta(data: $data) {
          id
        }
      }`,
      {
        data: {
          ...convertFormValueToPayload(v),
          org: {
            connect: {
              id: org.id,
            },
          },
        },
      }
    );
  };

  const update = (id: string, v: FormValues) => {
    return GraphQLService(
      `mutation updateUserAttributeMeta($id: ID!, $data: UserAttributeMetaUpdateInput!) {
        updateUserAttributeMeta(id: $id, data: $data) {
          id
        }
      }`,
      {
        data: {
          ...convertFormValueToPayload(v),
        },
        id: id,
      }
    );
  };

  const renderInput = (type: IUserAttributeMetaType) => {
    switch (type) {
      case "BOOLEAN":
        return <Switch />;
      case "TIME":
        return <WlyDatePicker />;
      case "NUMERIC":
        return <InputNumber />;
      case "STRING":
        return <Input />;
    }
  };

  return (
    <>
      <Form
        initialValues={initialValues}
        style={{ padding: 24, paddingBottom: 48 }}
        layout="vertical"
        form={form}
        onValuesChange={(e) => {
          if (e.restrictSelection === true) {
            form.setFieldValue("options", []);
          } else if (e.restrictSelection === false) {
            form.setFieldValue("options", null);
          }

          if (e.type) {
            form.setFieldValue("restrictSelection", false);
            form.setFieldValue("options", null);
            form.setFieldValue("defaultValue", null);
            form.setFieldValue("hasDefaultValue", false);
            form.setFieldValue("allowMultipleValues", false);
          }
          if (e.hasDefaultValue === true) {
            form.setFieldValue("defaultValue", []);
          }
          if (e.hasDefaultValue === false) {
            form.setFieldValue("defaultValue", null);
          }
        }}
        onFinish={async (v) => {
          try {
            setState({ status: "loading" });
            const valid = await validateTechnicalName(v.technicalName);
            if (!valid) {
              throw new Error("error");
            }
            const values = await form.validateFields();
            if (userAttributeMeta) {
              await update(userAttributeMeta.id, values);
            } else {
              await create(values);
            }
            await onSubmit();
            setState({ status: "success", data: undefined });
            onClose();
          } catch (err) {
            console.error(err);
            setState({ status: "error", error: err });
            antUtils.message.error(
              "An unexpected error happened, please retry"
            );
          }
        }}
      >
        <Form.Item
          rules={[{ required: true }]}
          name={["label"]}
          required
          label="Label"
          extra={
            "This is how your users will be able to use this attribute in Whaly."
          }
        >
          <Input />
        </Form.Item>
        <Form.Item
          hasFeedback
          rules={[
            {
              required: true,
            },
          ]}
          validateStatus={renderValidateStatus()}
          name={["technicalName"]}
          required
          label="Technical name"
          help={
            validate.status === "error" ? validate.error.message : undefined
          }
          extra={
            "This is how you will reference your user attributes using our API."
          }
        >
          <Input
            disabled={!!userAttributeMeta?.id}
            onChange={(e) => debouncedValidate(e.target.value)}
          />
        </Form.Item>
        <Form.Item
          rules={[{ required: true }]}
          name={["type"]}
          required
          label="Type"
        >
          <Select disabled={!!userAttributeMeta?.id}>
            <Select.Option value="STRING">String</Select.Option>
            <Select.Option value="BOOLEAN">Boolean</Select.Option>
            <Select.Option value="NUMERIC">Number</Select.Option>
            <Select.Option value="TIME">Date</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item noStyle shouldUpdate>
          {() => {
            if (
              form.getFieldValue("type") === "BOOLEAN" ||
              form.getFieldValue("type") === "TIME"
            ) {
              return;
            }
            return (
              <Form.Item name={["restrictSelection"]} valuePropName="checked">
                <Checkbox>Allow only specific values</Checkbox>
              </Form.Item>
            );
          }}
        </Form.Item>
        <Form.Item noStyle shouldUpdate>
          {() => {
            if (form.getFieldValue("restrictSelection") === true) {
              return (
                <Form.List name={["options"]}>
                  {(fields, { add, remove }, { errors }) => {
                    return (
                      <div>
                        {fields.map((f, i) => {
                          return (
                            <div
                              key={i}
                              style={{
                                display: "flex",
                                alignItems: "end",
                                marginBottom: 24,
                              }}
                            >
                              <div style={{ flex: 1 }}>
                                <Form.Item
                                  label="Value"
                                  style={{ marginBottom: 0 }}
                                  required
                                  rules={[{ required: true }]}
                                  name={[f.name, "value"]}
                                >
                                  {renderInput(form.getFieldValue("type"))}
                                </Form.Item>
                              </div>
                              <div style={{ flex: 1, marginLeft: 12 }}>
                                <Form.Item
                                  label={"Label"}
                                  style={{ marginBottom: 0 }}
                                  required
                                  rules={[{ required: true }]}
                                  name={[f.name, "label"]}
                                >
                                  <Input />
                                </Form.Item>
                              </div>
                              <div
                                style={{ flex: 0, width: 40, marginLeft: 12 }}
                              >
                                <Button
                                  type="text"
                                  icon={<MinusCircleOutlined />}
                                  onClick={() => remove(f.name)}
                                />
                              </div>
                            </div>
                          );
                        })}
                        <Button
                          onClick={() => add({ value: "", label: "" })}
                          block
                          style={{ marginBottom: 24 }}
                          type="dashed"
                        >
                          Add
                        </Button>
                      </div>
                    );
                  }}
                </Form.List>
              );
            }
          }}
        </Form.Item>
        <Form.Item noStyle shouldUpdate>
          {() => {
            if (
              form.getFieldValue("type") === "BOOLEAN" ||
              form.getFieldValue("type") === "TIME"
            ) {
              return;
            }
            return (
              <Form.Item name={["allowMultipleValues"]} valuePropName="checked">
                <Checkbox>Allow multiple value</Checkbox>
              </Form.Item>
            );
          }}
        </Form.Item>
        <Form.Item name={["hasDefaultValue"]} valuePropName="checked">
          <Checkbox>Add a default value</Checkbox>
        </Form.Item>
        <Form.Item noStyle shouldUpdate>
          {() => {
            if (form.getFieldValue("hasDefaultValue") === false) {
              return;
            }
            return (
              <Form.Item name={["defaultValue"]} label="Default value">
                <UserAttributeRenderer
                  type={form.getFieldValue("type")}
                  multi={form.getFieldValue("allowMultipleValues")}
                  options={form.getFieldValue("options")}
                />
              </Form.Item>
            );
          }}
        </Form.Item>
      </Form>
      <FormActions
        onSubmit={() => form.submit()}
        isSubmitting={state.status === "loading"}
        onCancel={onClose}
      />
    </>
  );
}

export default compose<Props, IUserAttributesFormProps>(
  WithOrg,
  withAntUtils
)(UserAttributesForm);
