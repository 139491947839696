import { gql } from "@apollo/client";
import {
  useGQLQuery,
  type QueryOptions,
} from "../../../../../../components/hooks/query/useGQLQuery";
import type { IRadar } from "../../../../../../interfaces/radar";
import { RADAR_COMPLETE_FRAGMENT } from "../../../../single/domain";

type QueryData = {
  Radar: IRadar;
};

type QueryVariables = {
  id: string;
};

const RadarDetailsQuery = gql`
  ${RADAR_COMPLETE_FRAGMENT}

  query GetRadarDetails($id: ID!) {
    Radar(where: { id: $id }) {
      ...RadarCompleteFragment
    }
  }
`;

export const useGetRadarDetails = (
  options: QueryOptions<QueryData, QueryVariables>
) => {
  return useGQLQuery<QueryData, QueryVariables>(RadarDetailsQuery, options);
};
