import type React from "react";
import type { IReportFolder } from "../../interfaces/folder";

type DataNode = {
  value: any;
  title: any;
  children?: DataNode[];
  isLeaf?: boolean;
  disabled?: boolean;
  disableCheckbox?: boolean;
  selectable?: boolean;
  checkable?: boolean;
};

export const buildFolderTree = (
  folders: IReportFolder[],
  renderTitle: (f: IReportFolder) => React.ReactNode,
  personalFolder?: IReportFolder
): DataNode[] => {
  const buildTree = (parentId?: string): DataNode[] => {
    const buildTreeNode = (f: IReportFolder): DataNode => {
      const id = `${f.id}`;
      const children = folders.filter(
        (fol) => fol.parent && fol.parent.id === f.id
      );
      const title = renderTitle(f);
      if (children && children.length) {
        return {
          title: title,
          value: id,
          selectable: true,
          isLeaf: false,
          children: buildTree(f.id),
        };
      } else {
        return {
          title: title,
          value: id,
          selectable: true,
          isLeaf: false,
          // children: [
          //   {
          //     title: "No folders inside",
          //     value: id + "-none",
          //     selectable: false,
          //     isLeaf: true,
          //     disabled: true,
          //   },
          // ],
        };
      }
    };

    if (parentId) {
      const childrenFolders = folders.filter(
        (f) => f.parent && f.parent.id === parentId
      );
      const treeNodes = childrenFolders
        .sort((a, b) => a.order - b.order)
        .map(buildTreeNode);
      return treeNodes;
    }
    const initialFolders = folders.filter((f) => !f.parent);
    const treeNodes = initialFolders
      .sort((a, b) => a.order - b.order)
      .map(buildTreeNode);
    return treeNodes;
  };

  let pF: DataNode[] = personalFolder
    ? [
        {
          title: renderTitle({
            ...personalFolder,
            name: "Personal Folder",
            image: ":closed_lock_with_key:",
          }),
          value: personalFolder.id,
          selectable: true,
          isLeaf: true,
        },
      ]
    : [];

  const treeData: DataNode[] = buildTree();

  return [...treeData, ...pF];
};
