import { Tag } from "antd";
import type { TooltipPlacement } from "antd/lib/tooltip";
import dbt from "../../../../assets/dbt.webp";
import whaly from "../../../../assets/icon.svg";
import { WorkbenchPopover } from "../../../../components/workbench/popover/WorkbenchPopover";
import type { TableTabItem } from "../../../spreadsheet/domain";
import type { FetchedDestination } from "../domain";

export const toDisplayErrors = (tabData: TableTabItem): string[] => {
  let errors: string[] = [];
  const runResultErrors = tabData.runResults.filter(
    (r) => r.status !== "success"
  );
  if (
    runResultErrors.find(
      (err) =>
        err.type === "configuration" && err.operationName === "primary_key"
    )
  ) {
    errors.push("Missing primary key");
  }

  if (tabData.hasUpstreamError) {
    errors.push("Upstream error");
  }

  const numberOfFailedTest = runResultErrors.filter(
    (err) => err.type === "test"
  );
  if (numberOfFailedTest.length > 0) {
    errors.push(`${numberOfFailedTest.length} failed tests`);
  }

  const freshnessError = runResultErrors.find(
    (err) => err.type === "freshness"
  );
  if (freshnessError) {
    errors.push("Freshness error");
  }

  const materializationError = runResultErrors.find(
    (err) => err.type === "materialization"
  );
  if (materializationError) {
    errors.push("Materialization error");
  }
  return errors;
};

export const wrapInTooltip =
  (
    tabData: TableTabItem,
    destination: FetchedDestination,
    placement: TooltipPlacement
  ) =>
  (child: React.ReactNode, isDragging?: boolean) => {
    const renderManagedBy = () => {
      switch (tabData.dataset.managedBy) {
        case "WHALY":
          return (
            <div
              style={{
                height: 22,
                display: "flex",
                alignItems: "inherit",
                paddingRight: 6,
              }}
            >
              <img src={whaly} alt="whaly" style={{ width: 15, height: 15 }} />
            </div>
          );
        case "DBT_CLOUD":
          return (
            <div
              style={{
                height: 22,
                display: "flex",
                alignItems: "inherit",
                paddingRight: 6,
              }}
            >
              <img src={dbt} alt="dbt" style={{ width: 15, height: 15 }} />
            </div>
          );
      }
    };
    if (isDragging) {
      return child;
    }
    if (tabData?.dataset) {
      return (
        <WorkbenchPopover
          description={tabData.dataset.description}
          placement={placement}
          tags={toDisplayErrors(tabData).map((err, i) => {
            return (
              <Tag color="error" key={i}>
                {err}
              </Tag>
            );
          })}
          title={tabData.dataset.name}
          logo={destination.isDbtCloudSyncEnabled ? renderManagedBy() : null}
        >
          {child}
        </WorkbenchPopover>
      );
    }
    return child;
  };
