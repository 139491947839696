import { indentService } from "@codemirror/language";

export const sqlIndentService = indentService.of((context, pos) => {
  if (pos - 1 <= 0) return 0;
  const previousLine = context.lineAt(pos - 1, -1);
  const previousLineIndendation = previousLine.text.match(/^(\s)*/)[0].length;
  const trimmedText = previousLine.text.trim().toLowerCase();
  let action: "keep" | "indent" | "deindent" = "keep";

  if (
    trimmedText.endsWith("(") ||
    trimmedText === "with" ||
    trimmedText === "select" ||
    trimmedText === "case" ||
    trimmedText === "from" ||
    trimmedText === "where" ||
    trimmedText === "group by" ||
    trimmedText === "limit"
  ) {
    action = "indent";
  } else if (trimmedText === "*") {
    action = "deindent";
  } else {
    action = "keep";
  }

  switch (action) {
    case "keep":
      return previousLineIndendation;
    case "indent":
      return previousLineIndendation + 2;
    case "deindent":
      return Math.max(previousLineIndendation - 2, 0);
  }
});
