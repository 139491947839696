import { ConfigProvider, Form, Input, Space, theme } from "antd";
import * as React from "react";
import usePrevious from "../../../../../../components/hooks/usePrevious";
import type { IReport } from "../../../../../../interfaces/reports";
import { ChartOptionCollapse } from "../../../../../chart-options/components/ChartOptionCollapse";
import ChartOptionLine from "../../../../../chart-options/components/ChartOptionLine";
import { parseOptions } from "../../../domain";

interface IDashboardOptionEditProps {
  report: IReport;
  onReportUpdate: (v: IReportOptionPayload) => void;
}

export interface IReportOptionPayload {
  name: string;
  description?: string;
  reportOptions?: IReportOption;
}

export interface IReportOption {
  body?: {
    backgroundColor?: string;
  };
  header?: {
    type: "IMAGE" | "NAVBAR";
    color?: "light" | "dark";
    backgroundColor?: string;
  };
}

export function DashboardOptionEdit(props: IDashboardOptionEditProps) {
  const { report, onReportUpdate } = props;
  const [form] = Form.useForm();
  const prevReport = usePrevious(report);

  React.useEffect(() => {
    if (prevReport?.id && report.id !== prevReport?.id) {
      form.resetFields();
    }
  }, [report.id, prevReport?.id]);

  let content = parseOptions(report.reportOptions);

  const initialValues: IReportOptionPayload = {
    name: report.name,
    description: report.description,
    reportOptions: content,
  };

  const namePopover = {
    title: "Name",
    content: (
      <Space direction="vertical" style={{ width: "100%", marginTop: 12 }}>
        <Form.Item label={"Name"} name={["name"]}>
          <Input />
        </Form.Item>
        <Form.Item label={"Description"} name={["description"]}>
          <Input />
        </Form.Item>
      </Space>
    ),
  };

  return (
    <ConfigProvider
      theme={{
        algorithm: theme.compactAlgorithm,
      }}
    >
      <Form
        layout="vertical"
        form={form}
        initialValues={initialValues}
        onFieldsChange={(e) => {
          if (onReportUpdate) {
            const v = form.getFieldsValue();
            onReportUpdate(v);
          }
        }}
      >
        <ChartOptionCollapse title={"Global Options"}>
          <Space direction="vertical" style={{ width: "100% " }}>
            <ChartOptionLine
              items={[
                {
                  flex: 1,
                  content: "Name & description",
                  popover: namePopover,
                },
                {
                  flex: 0,
                  type: "edit",
                  popover: namePopover,
                },
              ]}
            />
          </Space>
        </ChartOptionCollapse>
      </Form>
    </ConfigProvider>
  );
}
