import { Form, InputNumber, Space } from "antd";
import { ChartOptionCollapse } from "../../../../../../../chart-options/components/ChartOptionCollapse";
import ChartOptionLine from "../../../../../../../chart-options/components/ChartOptionLine";
import type { IWidgetEmailEditorProps } from "../../domain";
import type { IEmailWidgetSpacerConfig } from "../domain";

interface IEmailWidgetButtonEditorProps
  extends IWidgetEmailEditorProps<IEmailWidgetSpacerConfig> {}

export function EmailWidgetSpacerEditor(props: IEmailWidgetButtonEditorProps) {
  const { object } = props;

  return (
    <>
      <ChartOptionCollapse dark title="Spacer Configuration">
        <Space size="middle" style={{ width: "100%" }} direction="vertical">
          <ChartOptionLine
            items={[
              {
                content: <div>Height</div>,
                flex: `1`,
              },
              {
                content: (
                  <Form.Item name={["config", "height"]} noStyle>
                    <InputNumber suffix={"px"} style={{ width: "100%" }} />
                  </Form.Item>
                ),
                flex: `1`,
              },
            ]}
          />
        </Space>
      </ChartOptionCollapse>
    </>
  );
}
