import {
  AlignCenterOutlined,
  AlignLeftOutlined,
  AlignRightOutlined,
} from "@ant-design/icons";
import { Form, InputNumber, Radio, Select, Space } from "antd";
import { ChartOptionCollapse } from "../../../../../../../chart-options/components/ChartOptionCollapse";
import ChartOptionLine from "../../../../../../../chart-options/components/ChartOptionLine";
import { getObjectColumns } from "../../../../../object/domain";
import type { IWidgetEmailEditorProps } from "../../domain";
import type { IEmailWidgetImageConfig } from "../domain";

interface IEmailWidgetImageEditorProps
  extends IWidgetEmailEditorProps<IEmailWidgetImageConfig> {}

export function EmailWidgetImageEditor(props: IEmailWidgetImageEditorProps) {
  const { object } = props;

  const cols = getObjectColumns(object);

  return (
    <>
      <ChartOptionCollapse dark title="Image Configuration">
        <Space size="middle" style={{ width: "100%" }} direction="vertical">
          <ChartOptionLine
            items={[
              {
                content: <div>Align</div>,
                flex: `1`,
              },
              {
                content: (
                  <Form.Item name={["config", "align"]} noStyle>
                    <Radio.Group
                      options={[
                        {
                          value: "left",
                          label: <AlignLeftOutlined />,
                        },
                        {
                          value: "center",
                          label: <AlignCenterOutlined />,
                        },
                        {
                          value: "right",
                          label: <AlignRightOutlined />,
                        },
                      ]}
                      optionType="button"
                      buttonStyle="solid"
                    />
                  </Form.Item>
                ),
                flex: `0 auto`,
              },
            ]}
          />
          <ChartOptionLine
            items={[
              {
                content: <div>Spacing</div>,
                flex: `1`,
              },
              {
                content: (
                  <Form.Item
                    initialValue={"normal"}
                    name={["config", "spacing"]}
                    noStyle
                  >
                    <Select style={{ width: "100%" }}>
                      <Select.Option value="normal">Normal</Select.Option>
                      <Select.Option value="compact">Compact</Select.Option>
                    </Select>
                  </Form.Item>
                ),
                flex: `1`,
              },
            ]}
          />
          <ChartOptionLine
            items={[
              {
                content: <div>Image</div>,
                flex: 1,
              },
              {
                content: (
                  <Form.Item noStyle name={["config", "src"]}>
                    <Select style={{ width: "100%" }}>
                      {cols
                        .filter((c) => c.type === "property")
                        .map((c) => {
                          return (
                            <Select.Option key={c.data.key} value={c.data.key}>
                              {c.data.label}
                            </Select.Option>
                          );
                        })}
                    </Select>
                  </Form.Item>
                ),
                flex: 1,
              },
            ]}
          />
          <ChartOptionLine
            items={[
              {
                content: <div>Width</div>,
                flex: 1,
              },
              {
                content: (
                  <Form.Item noStyle name={["config", "width"]}>
                    <InputNumber style={{ width: "100%" }} suffix={"px"} />
                  </Form.Item>
                ),
                flex: 1,
              },
            ]}
          />
          <ChartOptionLine
            items={[
              {
                content: <div>Border Radius</div>,
                flex: 1,
              },
              {
                content: (
                  <Form.Item noStyle name={["config", "borderRadius"]}>
                    <InputNumber style={{ width: "100%" }} suffix={"px"} />
                  </Form.Item>
                ),
                flex: 1,
              },
            ]}
          />
        </Space>
      </ChartOptionCollapse>
    </>
  );
}
