import type { DiagramEngine } from "@projectstorm/react-diagrams";
import {
  DefaultLabelFactory,
  PortModelAlignment,
} from "@projectstorm/react-diagrams";
import { EditableLabelFactory } from "../flow/nodes/label/EditableLabelFactory";
import { FlowLinkFactory } from "../flow/nodes/link/FlowLinkFactory";
import { SimplePortFactory } from "../flow/nodes/port/SimplePortFactory";
import { SimplePortModel } from "../flow/nodes/port/SimplePortModel";
import { DatasetNodeFactory } from "./dataset-node/DatasetNodeFactory";
import { ExplorationNodeFactory } from "./exploration-node/ExplorationNodeFactory";

export const GRID_SIZE = 68;

export const generateEngine = (engine: DiagramEngine): DiagramEngine => {
  engine
    .getPortFactories()
    .registerFactory(
      new SimplePortFactory(
        "diamond",
        (config) => new SimplePortModel(PortModelAlignment.LEFT)
      )
    );
  engine
    .getPortFactories()
    .registerFactory(
      new SimplePortFactory(
        "diamond",
        (config) => new SimplePortModel(PortModelAlignment.RIGHT)
      )
    );

  engine.getLabelFactories().registerFactory(new DefaultLabelFactory());

  engine.getLabelFactories().registerFactory(new EditableLabelFactory());

  engine.getLinkFactories().registerFactory(new FlowLinkFactory());

  engine.getNodeFactories().registerFactory(new DatasetNodeFactory());

  engine.getNodeFactories().registerFactory(new ExplorationNodeFactory());

  engine.maxNumberPointsPerLink = 0;

  return engine;
};
