import type {
  AbstractDisplacementStateEvent,
  State,
} from "@projectstorm/react-canvas-core";
import { AbstractDisplacementState } from "@projectstorm/react-canvas-core";

interface DragCanvasStateOptions {
  /**
   * If enabled, the canvas is available to drag
   */
  allowDrag?: boolean;
}

export class WlyDragCanvasState extends AbstractDisplacementState {
  // store this as we drag the canvas
  initialCanvasX: number;
  initialCanvasY: number;
  config: DragCanvasStateOptions;

  constructor(options: DragCanvasStateOptions = {}) {
    super({
      name: "drag-canvas",
    });
    this.config = {
      allowDrag: true,
      ...options,
    };
  }

  async activated(prev) {
    super.activated(prev);
    // here we disable not deselection when dragging
    // this.engine.getModel().clearSelection();
    await this.engine.repaintCanvas(true);

    // we can block layer rendering because we are only targeting the transforms
    for (let layer of this.engine.getModel().getLayers()) {
      layer.allowRepaint(false);
    }

    this.initialCanvasX = this.engine.getModel().getOffsetX();
    this.initialCanvasY = this.engine.getModel().getOffsetY();
  }

  deactivated(next: State) {
    super.deactivated(next);
    for (let layer of this.engine.getModel().getLayers()) {
      layer.allowRepaint(true);
    }
  }

  fireMouseMoved(event: AbstractDisplacementStateEvent) {
    if (this.config.allowDrag) {
      this.engine
        .getModel()
        .setOffset(
          this.initialCanvasX + event.displacementX,
          this.initialCanvasY + event.displacementY
        );
      this.engine.repaintCanvas();
    }
  }
}
