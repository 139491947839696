import Icon from "@ant-design/icons";
import type { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";

export const WlyButtonIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon
    component={() => (
      <svg
        width="1em"
        height="1em"
        viewBox="0 0 213 213"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M188.329 55H26.5552C20.1999 55 15 60.1999 15 66.5552V112.776C15 119.132 20.1999 124.331 26.5552 124.331H143.933L142.554 118.554H26.5552C23.3689 118.554 20.7776 115.963 20.7776 112.776V66.5552C20.7776 63.3689 23.3689 60.7776 26.5552 60.7776H188.329C191.515 60.7776 194.106 63.3689 194.106 66.5552V112.776C194.106 115.531 192.166 117.832 189.582 118.407L190.099 118.564C192.146 119.186 193.81 120.534 194.895 122.262C197.904 120.17 199.884 116.696 199.884 112.776V66.5552C199.884 60.1999 194.684 55 188.329 55Z"
          fill="#5D6671"
        />
        <path
          d="M179.914 132.628L188.541 129.58C189.718 129.164 190.495 128.041 190.466 126.791C190.439 125.545 189.613 124.456 188.42 124.093L151.616 112.902C150.625 112.598 149.549 112.853 148.797 113.56C148.043 114.27 147.726 115.33 147.966 116.337L156.305 151.268C156.582 152.431 157.547 153.302 158.733 153.461C159.917 153.614 161.077 153.032 161.651 151.983L165.997 144.026L179.802 158.132C180.367 158.707 181.115 159 181.869 159C182.522 159 183.179 158.779 183.718 158.33L193.072 150.53C193.692 150.014 194.066 149.262 194.106 148.456C194.147 147.651 193.849 146.866 193.287 146.289L179.914 132.628ZM182.063 152.181L167.393 137.192C166.846 136.633 166.099 136.324 165.328 136.324C165.197 136.324 165.064 136.333 164.932 136.352C164.025 136.477 163.232 137.026 162.794 137.828L160.179 142.616L154.753 119.893L178.326 127.061L173.72 128.689C172.788 129.018 172.091 129.798 171.868 130.76C171.646 131.721 171.927 132.729 172.618 133.435L186.967 148.093L182.063 152.181Z"
          fill="#5D6671"
        />
      </svg>
    )}
    {...props}
  />
);
