import { Button } from "antd";
import * as React from "react";
import type { RouteComponentProps } from "react-router";
import { withRouter } from "react-router";
import type { ICatalogItemMeta } from "../../../interfaces/catalog";
import type { ViewName } from "../../../routes/domain";
import { routeDescriptor } from "../../../routes/routes";
import { track } from "../../../services/AnalyticsService";
import { compose } from "../../compose/WlyCompose";
import "./CatalogItemDetail.scss";
import CatalogItemDetailHeader from "./header/CatalogItemDetailHeader";

type ICatalogItemDetailProps =
  | ICatalogItemDetailRedirectProps
  | ICatalogItemDetailClickProps;

interface ICatalogItemDetailRedirectProps extends ICatalogItemDetailBaseProps {
  selected?: ICatalogItemMeta;
  disabled?: boolean;
  destinationViewName: ViewName;
}

interface ICatalogItemDetailClickProps extends ICatalogItemDetailBaseProps {
  selected?: ICatalogItemMeta;
  disabled?: boolean;
  onClick: (s: ICatalogItemMeta) => Promise<any>;
}

interface ICatalogItemDetailBaseProps {
  selected?: ICatalogItemMeta;
  disabled?: boolean;
  destinationViewName?: ViewName;
  overrideButtonName?: string;
  currentWarehouseSlug?: string;
  onClick?: (s: ICatalogItemMeta) => Promise<any>;
  children?: React.ReactNode;
}

interface IState {
  open: boolean;
}

type Props = ICatalogItemDetailProps &
  RouteComponentProps<{ organizationSlug: string; itemSlug?: string }>;

class CatalogItemDetail extends React.Component<Props, IState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      open: false,
    };
  }

  public onConnectClick = () => {
    const {
      history,
      match: {
        params: { organizationSlug },
      },
      selected,
      destinationViewName,
      currentWarehouseSlug,
    } = this.props;
    // this.setState({ open: true })
    if (destinationViewName && selected) {
      history.push(
        routeDescriptor[destinationViewName].renderRoute({
          organizationSlug,
          warehouseSlug: currentWarehouseSlug,
          itemSlug: selected.slug,
        })
      );
    }
  };

  public onClose = () => {
    this.setState({ open: false });
  };

  public render() {
    const { selected, disabled, onClick, overrideButtonName } = this.props;
    if (!selected) {
      return <div>no source found</div>;
    }

    return (
      <div className="source-catalog-detail">
        <CatalogItemDetailHeader
          logo={selected.publicInfo.logo}
          name={selected.publicInfo.name}
          tagline={selected.publicInfo.tagline}
        >
          <div className="content">
            <div className="sidebar">
              <Button
                disabled={disabled}
                onClick={() => {
                  track("Source Connect Button Clicked", {
                    id: selected.id,
                    name: selected.publicInfo.name,
                  });
                  if (onClick) {
                    onClick(selected);
                  } else {
                    this.onConnectClick();
                  }
                }}
                type="primary"
              >
                {overrideButtonName ? overrideButtonName : "Connect"}
              </Button>
              <div className="meta">
                <div className="title">Website:</div>
                <div>
                  <a
                    rel="noopener noreferrer"
                    href={selected.publicInfo.website}
                    target="_blank"
                  >
                    {selected.publicInfo.name}
                  </a>
                </div>
              </div>
            </div>
            <div className="main">
              <div className="inner">
                {/* {disabled && <div style={{ paddingTop: 16 }}>
                  <Alert message="Please reach out to your support contact in your slack group to connect this source." type="warning" />
                </div>} */}
                <div style={{ paddingTop: 10 }}>
                  {selected.publicInfo.description}
                </div>
              </div>
            </div>
          </div>
          <div className="models">{this.props.children}</div>
        </CatalogItemDetailHeader>
      </div>
    );
  }
}

export default compose<Props, ICatalogItemDetailProps>(withRouter)(
  CatalogItemDetail
);
