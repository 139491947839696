import type { IEmailRendererConfig } from "../domain";
import type { IEmailWidgetSpacerConfig } from "./domain";
import { EmailWidgetSpacerEditor } from "./editor/EmailWidgetSpacerEditor";
import { EmailWidgetSpacerMjml } from "./mjml/EmailWidgetButtonMjml";
import { EmailWidgetSpacerRenderer } from "./web-widget/EmailWidgetSpacerRenderer";

export const emailWidgetSpacerDefinition: IEmailRendererConfig<IEmailWidgetSpacerConfig> =
  {
    parseConfig: (widget) => {
      const defaultValue: IEmailWidgetSpacerConfig = {
        height: 50,
      };

      if (!widget.config) {
        return defaultValue;
      }

      try {
        const config: IEmailWidgetSpacerConfig = JSON.parse(widget.config);
        return config;
      } catch (err) {
        console.error(err);
        return defaultValue;
      }
    },
    renderEditor: (widget, object, record, conf, org, user, form, datasets) => {
      return (
        <EmailWidgetSpacerEditor
          widget={widget}
          object={object}
          record={record}
          conf={conf}
          org={org}
          user={user}
          form={form}
          datasets={datasets}
        />
      );
    },
    renderComponent: (widget, object, record, conf, org, user, edit) => {
      return (
        <EmailWidgetSpacerRenderer
          widget={widget}
          object={object}
          record={record}
          conf={conf}
          org={org}
          user={user}
          edit={edit}
        />
      );
    },
    renderMjml: EmailWidgetSpacerMjml,
  };
