import type { FormInstance } from "antd";
import React from "react";
import type { VisualizationSpec } from "react-vega";
import { WlyBarChartIcon } from "../../../../../../../../../components/icons/custom-icons/WlyBarChartIcon";
import { WlyPieChartIcon } from "../../../../../../../../../components/icons/custom-icons/WlyPieChartIcon";
import { WlyScatterChartIcon } from "../../../../../../../../../components/icons/custom-icons/WlyScatterChartIcon";
import type {
  SchemaResult,
  TableResult,
} from "../../../../../../../../../interfaces/transformations";
import type { EmbeddedCatalogItem } from "../Selector/EmbeddedChartSelector";

export type EmbeddedChartType = "histogram" | "scatter" | "pie";

export const chartCatalog: Array<EmbeddedCatalogItem<EmbeddedChartType>> = [
  {
    key: "other",
    label: "Other",
    charts: [
      {
        key: "histogram",
        label: "Histogram",
        icon: <WlyBarChartIcon />,
      },
      {
        key: "scatter",
        label: "Scatter",
        icon: <WlyScatterChartIcon />,
      },
      {
        key: "pie",
        label: "Pie",
        icon: <WlyPieChartIcon />,
      },
    ],
  },
];

export type AggrerateValue =
  | "count"
  | "sum"
  | "mean"
  | "median"
  | "distinct"
  | "min"
  | "max"
  | "missing"
  | "valid";

export const aggregates: Array<{
  value: AggrerateValue | null;
  label: string;
  description: string;
}> = [
  {
    value: null,
    label: "None",
    description: "No aggregation",
  },
  {
    value: "count",
    label: "Count",
    description: "The total count of rows",
  },
  {
    value: "sum",
    label: "Sum",
    description: "The sum of all values",
  },
  {
    value: "mean",
    label: "Average",
    description: "The mean of all values",
  },
  {
    value: "median",
    label: "Median",
    description: "The median value",
  },
  {
    value: "distinct",
    label: "Distinct",
    description: "The count of distinct values",
  },
  {
    value: "min",
    label: "Min",
    description: "The minimun value",
  },
  {
    value: "max",
    label: "Max",
    description: "The maximun value",
  },
  {
    value: "missing",
    label: "Missing",
    description: "The count of `null` or `undefined` values",
  },
  {
    value: "valid",
    label: "Valid",
    description: "The count of non `null` or non `undefined` values",
  },
];

export interface IDiscoveryChartDefinition<T> {
  renderForm: (
    schema: SchemaResult,
    form: FormInstance,
    onChange: (values: T) => void
  ) => React.ReactNode;
  renderSpec: (
    data: TableResult,
    schema: SchemaResult,
    definition?: T
  ) => VisualizationSpec;
}
