import { Form, Select, Typography } from "antd";
import cuid from "cuid";
import * as React from "react";
import { compose } from "../../../../../../../../components/compose/WlyCompose";
import { SourceImageRenderer } from "../../../../../../../../components/sources/SourceImageRenderer";
import type {
  TableFromDatasetTableOperation,
  TableFromWarehouseTableOperation,
} from "../../../../../../../../interfaces/transformations";
import type { InjectedOrgProps } from "../../../../../../../orgs/WithOrg";
import WithOrg from "../../../../../../../orgs/WithOrg";
import { catchErrors } from "../../../../../../../transformations/domain";
import type { FlowOperationFormProps } from "../domain";

type LookupColumnFormProps = FlowOperationFormProps<{
  var: string;
  operation: TableFromWarehouseTableOperation | TableFromDatasetTableOperation;
  domain: "datasetResolver";
}>;

type Props = LookupColumnFormProps & InjectedOrgProps;

const id = cuid();

const LookupColumnForm: React.FunctionComponent<Props> = (props) => {
  const {
    onSave,
    currentTransformation,
    transformations,
    onCancel,
    isStale,
    setFormInstance,
    canvasNodeGenerator,
    datasets,
    setSubmitting,
    currentWarehouse,
  } = props;

  const [form] = Form.useForm();
  const isFormDisabled = !onSave;

  React.useEffect(() => {
    if (setFormInstance) {
      setFormInstance(form);
    }
  }, [setFormInstance, form]);

  React.useEffect(() => {
    form?.resetFields();
  }, [currentTransformation.var, form]);

  const getValue = (
    operation: TableFromWarehouseTableOperation | TableFromDatasetTableOperation
  ) => {
    // used for datasets
    if (operation.type === "Table.FromWarehouseTable") {
      return datasets.find(
        (d) =>
          d.warehouseTableId === operation.args.tableName &&
          d.warehouseSchemaId === operation.args.schemaName &&
          d.warehouseDatabaseId === operation.args.databaseName
      )?.id;
    }
    // used for models
    else if (operation.type === "Table.FromWhalyDataset") {
      return operation.args.datasetId;
    }
  };

  const setValue = (
    datasetId: string
  ): TableFromWarehouseTableOperation | TableFromDatasetTableOperation => {
    const dataset = datasets.find((d) => d.id === datasetId);
    // used for datasets
    if (!dataset.isModel) {
      return {
        type: "Table.FromWarehouseTable",
        args: {
          databaseName: dataset.warehouseDatabaseId,
          schemaName: dataset.warehouseSchemaId,
          tableName: dataset.warehouseTableId,
        },
      };
    }
    // used for models
    else {
      return {
        type: "Table.FromWhalyDataset",
        args: {
          datasetId: dataset.id,
        },
      };
    }
  };

  const initialValues = {
    datasetId: getValue(currentTransformation.operation),
  };

  return (
    <Form
      initialValues={initialValues}
      onFieldsChange={(f) => {
        isStale && isStale(true);
      }}
      className="form-dropdown-form"
      form={form}
      onFinish={async (values) => {
        try {
          setSubmitting(true);
          const value = setValue(values.datasetId);
          await onSave([
            {
              type: "UPDATE",
              transformation: {
                ...currentTransformation,
                operation: value,
              },
            },
          ]);
          if (onCancel) {
            onCancel();
          }
          setSubmitting(false);
        } catch (err) {
          console.error(err);
          catchErrors(err, id);
          setSubmitting(false);
        }
      }}
      layout="vertical"
    >
      <div className="form-dropdown-form-content">
        <Form.Item
          name={"datasetId"}
          label={`Select the dataset to reference`}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select
            disabled={isFormDisabled}
            showSearch={true}
            optionFilterProp="label"
            popupMatchSelectWidth={false}
          >
            {[...datasets]
              .sort((a, b) => {
                const aSource = a.source;
                const bSource = b.source;
                if (a.isModel !== b.isModel) {
                  return a.isModel ? 1 : -1;
                } else if (aSource?.name !== bSource?.name) {
                  return aSource?.name > bSource?.name ? 1 : -1;
                } else {
                  return a.name > b.name ? 1 : -1;
                }
              })
              .map((d) => (
                <Select.Option key={d.id} value={d.id} label={d.name}>
                  <span role="img" style={{ marginRight: 5 }}>
                    <SourceImageRenderer
                      alt={"sourceName"}
                      className="source-table-selection-logo"
                      isModel={d.isModel}
                      img={
                        d.isModel
                          ? undefined
                          : d.source?.sourceMeta.publicInfo.logo
                      }
                      size={16}
                    />
                  </span>
                  <Typography.Text>
                    {d.name}
                    <Typography.Text type="secondary" style={{ marginLeft: 6 }}>
                      {d.isModel ? <>Model</> : <>{d.source?.name}</>}
                    </Typography.Text>
                  </Typography.Text>
                </Select.Option>
              ))}
          </Select>
        </Form.Item>
      </div>
    </Form>
  );
};

export default compose<Props, LookupColumnFormProps>(WithOrg)(LookupColumnForm);
