import moment from "moment";
import type { ISource } from "../../interfaces/sources";

type sourceStatus =
  | "misconfiguration"
  | "unknown"
  | "success"
  | "error"
  | "syncing"
  | "late";

export const getSourceStatus = (source: ISource): sourceStatus => {
  if (source.status === "discovery") return "unknown";
  if (source.lastJobExecution) {
    if (source.lastJobExecution.status === "RUNNING") return "syncing";
    if (source.lastJobExecution.status === "SUCCESSED") return "success";
    if (source.lastJobExecution.status === "ERROR") return "error";
    if (source.lastJobExecution.status === "FAILED") {
      if (!source.lastSyncDate) return "unknown";
      let syncPeriodInMinute: number | null = null;
      switch (source.syncPeriod) {
        case "five_minutes":
          syncPeriodInMinute = 5;
          break;
        case "fifteen_minutes":
          syncPeriodInMinute = 15;
          break;
        case "thirty_minutes":
          syncPeriodInMinute = 30;
          break;
        case "one_hour":
          syncPeriodInMinute = 1 * 60;
          break;
        case "two_hours":
          syncPeriodInMinute = 2 * 60;
          break;
        case "three_hours":
          syncPeriodInMinute = 3 * 60;
          break;
        case "six_hours":
          syncPeriodInMinute = 6 * 60;
          break;
        case "eight_hours":
          syncPeriodInMinute = 8 * 60;
          break;
        case "twelve_hours":
          syncPeriodInMinute = 12 * 60;
          break;
        case "twenty_four_hours":
          syncPeriodInMinute = 24 * 60;
          break;
      }
      if (
        moment(source.lastSyncDate)
          .add(2 * syncPeriodInMinute, "minutes")
          .isBefore(moment())
      ) {
        return "late";
      } else {
        return "error";
      }
    }
  } else {
    if (source._datasetsMeta?.count) {
      return "unknown";
    } else {
      return "misconfiguration";
    }
  }
  return "unknown";
};
