import React from "react";
import { Wly121ColumnIcon } from "../../../../../components/icons/custom-icons/Wly121ColumnIcon";
import { Wly51ColumnIcon } from "../../../../../components/icons/custom-icons/Wly15ColumnIcon";
import { Wly1ColumnIcon } from "../../../../../components/icons/custom-icons/Wly1ColumnIcon";
import { Wly2ColumnIcon } from "../../../../../components/icons/custom-icons/Wly2ColumnIcon";
import { Wly3ColumnIcon } from "../../../../../components/icons/custom-icons/Wly3ColumnIcon";
import { Wly4ColumnIcon } from "../../../../../components/icons/custom-icons/Wly4ColumnIcon";
import { WlyAlertIcon } from "../../../../../components/icons/custom-icons/WlyAlertIcon";
import { WlyButtonIcon } from "../../../../../components/icons/custom-icons/WlyButtonIcon";
import { WlyHashtagIcon } from "../../../../../components/icons/custom-icons/WlyHashtagIcon";
import { WlyImageIcon } from "../../../../../components/icons/custom-icons/WlyImageIcon";
import { WlyMapIcon } from "../../../../../components/icons/custom-icons/WlyMapIcon";
import { WlyMarkdownIcon } from "../../../../../components/icons/custom-icons/WlyMarkdownIcon";
import { WlyPropertiesIcon } from "../../../../../components/icons/custom-icons/WlyPropertiesIcon";
import { WlyRelatedListIcon } from "../../../../../components/icons/custom-icons/WlyRelatedListIcon";
import { WlyRepeatableIcon } from "../../../../../components/icons/custom-icons/WlyRepeatableIcon";
import { WlySpacerIcon } from "../../../../../components/icons/custom-icons/WlySpacerIcon";
import type { AsyncData } from "../../../../../helpers/typescriptHelpers";
import type {
  IObject,
  IObjectLayout,
  IObjectLayoutType,
} from "../../../../../interfaces/object";
import {
  LagoonCallOrigin,
  lagoonServiceLoad,
} from "../../../../../services/LagoonService";
import { generateFakeId } from "../../../../workbench/workbench/exploration/domain";
import { getObjectColumns } from "../../object/domain";
import type { IRecord } from "../domain";
import type { IWidgetVisibilityFiltersValue } from "./common/visibility-filters/WidgetVisibilityFilters";
import { IEmailWidgetType } from "./email-widgets/domain";

export const parseObjectLayout = (layout?: IObjectLayout): ILayout => {
  if (!layout) {
    return {
      header: {
        visible: true,
      },
      type: "RECORD",
      name: "Untitled",
      id: generateFakeId(),
      rows: [] as any,
      widgets: [] as any,
    };
  }

  const parseConfig = <T extends unknown>(conf: string, defaultValue: T): T => {
    try {
      const c = JSON.parse(conf) as T;
      return c;
    } catch (err) {
      console.log(err);
      return defaultValue;
    }
  };

  const parseDisplayFilters = (
    displayFilters?: string
  ): IWidgetVisibilityFiltersValue | undefined => {
    if (!displayFilters) {
      return undefined;
    }
    try {
      const filters = JSON.parse(
        displayFilters
      ) as IWidgetVisibilityFiltersValue;
      if (
        !filters ||
        !filters?.action ||
        !filters?.operator ||
        !filters.filters
      ) {
        return undefined;
      }
      return filters;
    } catch (err) {
      console.error(err);
      return undefined;
    }
  };

  return {
    id: layout.id,
    name: layout.name ? layout.name : "Untitled",
    type: layout.type ? layout.type : "RECORD",
    header: parseConfig<{ visible: boolean }>(layout.header, { visible: true }),
    rows: layout.rows.map((r) => {
      const { displayFilters, ...row } = r;
      return {
        ...row,
        displayFilters: parseDisplayFilters(displayFilters),
        columns: layout.cols
          .filter((f) => f.row.id === r.id)
          .map((c) => {
            const conf = parseConfig<{ size: number }>(c.config, { size: 24 });
            const { config, ...rest } = c;
            return {
              ...rest,
              ...conf,
            };
          }),
      };
    }),
    widgets: layout.widgets
      .filter((w) => w.parentColumn && w.parentColumn.id)
      .map((w) => {
        const { displayFilters, ...widget } = w;
        return {
          ...widget,
          displayFilters: parseDisplayFilters(displayFilters),
          type: w.type as IWidgetType,
        };
      }),
  };
};

interface IRecordWidgetLoadingData {
  status: "loading";
}

export interface IRecordWidgetSuccessData {
  status: "success";
  data: IRecord;
}

export type IRecordWidgetData =
  | IRecordWidgetLoadingData
  | IRecordWidgetSuccessData;

export interface IRow {
  id: string;
  position: number;
  name?: string;
  description?: string;
  displayFilters?: IWidgetVisibilityFiltersValue;
  parentColumn?: {
    id: string;
  };
  parentWidget?: {
    id: string;
  };
  columns: Array<IColumn>;
}

export interface IColumn {
  id: string;
  position: number;
  size: number;
}

export interface IWidget {
  id: string;
  name?: string;
  displayFilters?: IWidgetVisibilityFiltersValue;
  type: IWidgetType | IEmailWidgetType;
  config?: string;
  position: number;
  parentColumn?: {
    id: string;
  };
}

export enum IWidgetType {
  "chart" = "chart",
  "properties" = "properties",
  "actions" = "actions",
  "map" = "map",
  "relatedList" = "relatedList",
  "markdown" = "markdown",
  "repeatable" = "repeatable",
  "alert" = "alert",
  "separator" = "separator",
  "kpi" = "kpi",
  "suggestedProducts" = "suggestedProducts",
  "buttons" = "buttons",
  "title" = "title",
  "generated-text" = "generated-text",
}

export interface ILayout {
  id: string;
  type: IObjectLayoutType;
  name?: string;
  header: IHeaderProperties;
  widgets: Array<IWidget>;
  rows: Array<IRow>;
}

export interface IHeaderProperties {
  visible: boolean;
  featuredActions?: string[];
  additionalActions?: string[];
}

export type IRecordAction =
  | ILayoutAction
  | IWidgetAction
  | IHeaderAction
  | IWidgetMove
  | ILayoutMove;

export interface ILayoutAction {
  type: "layout::add" | "layout::remove" | "layout::modify";
  data: IRow;
}

interface ILayoutMove {
  type: "layout::move";
  data: IRow;
  position: "top" | "bottom";
}

interface IWidgetAction {
  type: "widget::add" | "widget::remove" | "widget::modify";
  data: IWidget;
}

interface IWidgetMove {
  type: "widget::move";
  data: IWidget;
  position: "top" | "bottom";
  colIdToReorder?: string;
}

interface IHeaderAction {
  type: "header::modify";
  data: IHeaderProperties;
}

export const layoutObjectData: {
  [key: string]: {
    icon: React.ReactNode;
    title: string;
    generate: (position: number, parentColumnId?: string) => IRow;
  };
} = {
  "1-column": {
    icon: <Wly1ColumnIcon />,
    title: "1 Column",
    generate: (position, parentColumnId) => ({
      id: generateFakeId(),
      position: position,
      parentColumn: parentColumnId ? { id: parentColumnId } : undefined,
      columns: [
        {
          id: generateFakeId(),
          position: 0,
          size: 24,
        },
      ],
    }),
  },
  "2-column": {
    icon: <Wly2ColumnIcon />,
    title: "2 Columns",
    generate: (position, parentColumnId) => ({
      id: generateFakeId(),
      position: position,
      parentColumn: parentColumnId ? { id: parentColumnId } : undefined,
      columns: [
        {
          id: generateFakeId(),
          position: 0,
          size: 12,
        },
        {
          id: generateFakeId(),
          position: 1,
          size: 12,
        },
      ],
    }),
  },
  "3-column": {
    icon: <Wly3ColumnIcon />,
    title: "3 Columns",
    generate: (position, parentColumnId) => ({
      id: generateFakeId(),
      position: position,
      parentColumn: parentColumnId ? { id: parentColumnId } : undefined,
      columns: [
        {
          id: generateFakeId(),
          position: 0,
          size: 8,
        },
        {
          id: generateFakeId(),
          position: 1,
          size: 8,
        },
        {
          id: generateFakeId(),
          position: 2,
          size: 8,
        },
      ],
    }),
  },
  "4-column": {
    icon: <Wly4ColumnIcon />,
    title: "4 Columns",
    generate: (position, parentColumnId) => ({
      id: generateFakeId(),
      position: position,
      parentColumn: parentColumnId ? { id: parentColumnId } : undefined,
      columns: [
        {
          id: generateFakeId(),
          position: 0,
          size: 6,
        },
        {
          id: generateFakeId(),
          position: 1,
          size: 6,
        },
        {
          id: generateFakeId(),
          position: 2,
          size: 6,
        },
        {
          id: generateFakeId(),
          position: 2,
          size: 6,
        },
      ],
    }),
  },
  "1-2-1-column": {
    icon: <Wly121ColumnIcon />,
    title: "1-2-1 Columns",
    generate: (position, parentColumnId) => ({
      id: generateFakeId(),
      position: position,
      parentColumn: parentColumnId ? { id: parentColumnId } : undefined,
      columns: [
        {
          id: generateFakeId(),
          position: 0,
          size: 6,
        },
        {
          id: generateFakeId(),
          position: 1,
          size: 12,
        },
        {
          id: generateFakeId(),
          position: 2,
          size: 6,
        },
      ],
    }),
  },
  "1-5-column": {
    icon: <Wly51ColumnIcon />,
    title: "1-5 Columns",
    generate: (position, parentColumnId) => ({
      id: generateFakeId(),
      position: position,
      parentColumn: parentColumnId ? { id: parentColumnId } : undefined,
      columns: [
        {
          id: generateFakeId(),
          position: 0,
          size: 4,
        },
        {
          id: generateFakeId(),
          position: 1,
          size: 20,
        },
      ],
    }),
  },
};

export const emailWidgetObjectData: {
  [key: string]: {
    icon: React.ReactNode;
    title: string;
    generate: (position: number, parentColumnId?: string) => IWidget;
  };
} = {
  image: {
    icon: <WlyImageIcon />,
    title: "Image",
    generate: (position, parentColumnId) => ({
      id: generateFakeId(),
      position: position,
      parentColumn: parentColumnId ? { id: parentColumnId } : undefined,
      type: IEmailWidgetType.image,
      config: "",
    }),
  },
  text: {
    icon: <WlyMarkdownIcon />,
    title: "Text",
    generate: (position, parentColumnId) => ({
      id: generateFakeId(),
      position: position,
      parentColumn: parentColumnId ? { id: parentColumnId } : undefined,
      type: IEmailWidgetType.text,
      config: "",
    }),
  },
  button: {
    icon: <WlyButtonIcon />,
    title: "Button",
    generate: (position, parentColumnId) => ({
      id: generateFakeId(),
      position: position,
      parentColumn: parentColumnId ? { id: parentColumnId } : undefined,
      type: IEmailWidgetType.button,
      config: "",
    }),
  },
  spacer: {
    icon: <WlySpacerIcon />,
    title: "Spacer",
    generate: (position, parentColumnId) => ({
      id: generateFakeId(),
      position: position,
      parentColumn: parentColumnId ? { id: parentColumnId } : undefined,
      type: IEmailWidgetType.spacer,
      config: "",
    }),
  },
  "property-list": {
    icon: <WlyPropertiesIcon />,
    title: "Properties",
    generate: (position, parentColumnId) => ({
      id: generateFakeId(),
      position: position,
      parentColumn: parentColumnId ? { id: parentColumnId } : undefined,
      type: IEmailWidgetType["property-list"],
      config: "",
    }),
  },
  "related-list": {
    icon: <WlyRelatedListIcon />,
    title: "Related List",
    generate: (position, parentColumnId) => ({
      id: generateFakeId(),
      position: position,
      parentColumn: parentColumnId ? { id: parentColumnId } : undefined,
      type: IEmailWidgetType["related-list"],
      config: "",
    }),
  },
  divider: {
    icon: <WlySpacerIcon />,
    title: "Divider",
    generate: (position, parentColumnId) => ({
      id: generateFakeId(),
      position: position,
      parentColumn: parentColumnId ? { id: parentColumnId } : undefined,
      type: IEmailWidgetType.divider,
      config: "",
    }),
  },
  repeatable: {
    icon: <WlyRepeatableIcon />,
    title: "Repeatable",
    generate: (position, parentColumnId) => ({
      id: generateFakeId(),
      position: position,
      parentColumn: parentColumnId ? { id: parentColumnId } : undefined,
      type: IEmailWidgetType.repeatable,
      config: "",
    }),
  },
};

export const widgetObjectData: {
  [key: string]: {
    icon: React.ReactNode;
    title: string;
    generate: (position: number, parentColumnId?: string) => IWidget;
  };
} = {
  properties: {
    icon: <WlyPropertiesIcon />,
    title: "Properties",
    generate: (position, parentColumnId) => ({
      id: generateFakeId(),
      position: position,
      parentColumn: parentColumnId ? { id: parentColumnId } : undefined,
      type: IWidgetType.properties,
      config: "",
    }),
  },
  map: {
    icon: <WlyMapIcon />,
    title: "Map",
    generate: (position, parentColumnId) => ({
      id: generateFakeId(),
      position: position,
      parentColumn: parentColumnId ? { id: parentColumnId } : undefined,
      type: IWidgetType.map,
      config: "",
    }),
  },
  relatedlist: {
    icon: <WlyRelatedListIcon />,
    title: "Related List",
    generate: (position, parentColumnId) => ({
      id: generateFakeId(),
      position: position,
      parentColumn: parentColumnId ? { id: parentColumnId } : undefined,
      type: IWidgetType.relatedList,
      config: "",
    }),
  },
  productRecommendation: {
    icon: <WlyRelatedListIcon />,
    title: "Recommender",
    generate: (position, parentColumnId) => ({
      id: generateFakeId(),
      position: position,
      parentColumn: parentColumnId ? { id: parentColumnId } : undefined,
      type: IWidgetType.suggestedProducts,
      config: "",
    }),
  },
  repeatable: {
    icon: <WlyRepeatableIcon />,
    title: "Repeatable",
    generate: (position, parentColumnId) => ({
      id: generateFakeId(),
      position: position,
      parentColumn: parentColumnId ? { id: parentColumnId } : undefined,
      type: IWidgetType.repeatable,
      config: "",
    }),
  },
  alert: {
    icon: <WlyAlertIcon />,
    title: "Alert",
    generate: (position, parentColumnId) => ({
      id: generateFakeId(),
      position: position,
      parentColumn: parentColumnId ? { id: parentColumnId } : undefined,
      type: IWidgetType.alert,
      config: "",
    }),
  },
  markdown: {
    icon: <WlyMarkdownIcon />,
    title: "Markdown",
    generate: (position, parentColumnId) => ({
      id: generateFakeId(),
      position: position,
      parentColumn: parentColumnId ? { id: parentColumnId } : undefined,
      type: IWidgetType.markdown,
      config: "",
    }),
  },
  separator: {
    icon: <WlySpacerIcon />,
    title: "Separator",
    generate: (position, parentColumnId) => ({
      id: generateFakeId(),
      position: position,
      parentColumn: parentColumnId ? { id: parentColumnId } : undefined,
      type: IWidgetType.separator,
      config: "",
    }),
  },
  kpi: {
    icon: <WlyHashtagIcon />,
    title: "KPI",
    generate: (position, parentColumnId) => ({
      id: generateFakeId(),
      position: position,
      parentColumn: parentColumnId ? { id: parentColumnId } : undefined,
      type: IWidgetType.kpi,
      config: "",
    }),
  },
  buttons: {
    icon: <WlyButtonIcon />,
    title: "Buttons",
    generate: (position, parentColumnId) => ({
      id: generateFakeId(),
      position: position,
      parentColumn: parentColumnId ? { id: parentColumnId } : undefined,
      type: IWidgetType.buttons,
      config: "",
    }),
  },
  title: {
    icon: <WlyButtonIcon />,
    title: "Title",
    generate: (position, parentColumnId) => ({
      id: generateFakeId(),
      position: position,
      parentColumn: parentColumnId ? { id: parentColumnId } : undefined,
      type: IWidgetType.title,
      config: "",
    }),
  },
  "generated-text": {
    icon: <WlyRepeatableIcon />,
    title: "Generated Text",
    generate: (position, parentColumnId) => ({
      id: generateFakeId(),
      position: position,
      parentColumn: parentColumnId ? { id: parentColumnId } : undefined,
      type: IWidgetType["generated-text"],
      config: "",
    }),
  },
};

export const fetchRecord = async (
  orgId: string,
  recordId: string,
  object: IObject,
  setRecord: (d: AsyncData<IRecord | null>) => void
) => {
  try {
    setRecord({ status: "loading" });
    const cubeName = object.table.cubeName;
    const columns = getObjectColumns(object);
    const buildDimensions = object.properties.map(
      (p) => `${cubeName}.${p.cubeName}`
    );
    const data = await lagoonServiceLoad(
      orgId,
      {
        measures: (columns ? columns : [])
          ?.filter?.((c) => c.type === "metric")
          ?.map((m) => m.data.key),
        dimensions: [
          `${cubeName}.id`,
          `${cubeName}.label`,
          `${cubeName}.name`,
          ...buildDimensions,
        ],
        limit: 1,
        filters: [
          {
            and: [
              {
                operator: "equals",
                member: `${cubeName}.id`,
                values: [recordId],
              },
            ],
          },
        ],
      },
      "OBJECT",
      object.id,
      undefined,
      LagoonCallOrigin.WHALY_APP
    );
    const d = data.tablePivot()[0];
    const formatData = () => {
      if (!d) {
        return null;
      }
      return d;
    };
    setRecord({ status: "success", data: formatData() });
  } catch (err) {
    console.error(err);
    setRecord({ status: "error", error: err });
  }
};
