// @ts-strict-ignore
import { Button, Form, Input, Modal } from "antd";
import { useForm } from "antd/es/form/Form";
import React, { useEffect } from "react";
import type { RouteComponentProps } from "react-router";
import { withRouter } from "react-router";
import type { InjectedAntUtilsProps } from "../../../../../../components/ant-utils/withAntUtils";
import { withAntUtils } from "../../../../../../components/ant-utils/withAntUtils";
import { compose } from "../../../../../../components/compose/WlyCompose";
import type { InjectedOrgProps } from "../../../../../orgs/WithOrg";
import WithOrg from "../../../../../orgs/WithOrg";
import "./CreateObjectLayoutModal.scss";

interface ICreateObjectLayoutModalProps {
  open: boolean;
  onClose: () => void;
  onCreate: (values: CreateObjectFormValue) => Promise<void>;
}

interface CreateObjectFormValue {
  name: string;
}

type Props = ICreateObjectLayoutModalProps &
  InjectedAntUtilsProps &
  RouteComponentProps<{ warehouseSlug?: string }> &
  InjectedOrgProps;

function CreateObjectLayoutModal(props: Props) {
  const { org, open, onClose, onCreate, antUtils, match } = props;

  const [form] = useForm<CreateObjectFormValue>();

  const [loading, setLoading] = React.useState<boolean>(false);

  useEffect(() => {
    if (open) {
      form.setFieldValue("name", "");
    }
  }, [open]);

  const renderInner = (): JSX.Element => {
    return (
      <>
        <div className="content">
          <Form
            form={form}
            layout="vertical"
            onFinish={async (v) => {
              try {
                setLoading(true);
                await form.validateFields();
                await onCreate(v);
              } catch (err) {
                console.error(err);
                antUtils.message.error(
                  "An unexpected error happened, please retry"
                );
              } finally {
                setLoading(false);
              }
            }}
          >
            <Form.Item
              required
              rules={[{ required: true }]}
              label={"Name"}
              name="name"
            >
              <Input />
            </Form.Item>
          </Form>
        </div>
        <div className="footer">
          <div className="left">
            <Button onClick={onClose} type="link">
              Cancel
            </Button>
          </div>
          <div className="right">
            <Button
              onClick={() => {
                form.submit();
              }}
              type="primary"
            >
              Continue
            </Button>
          </div>
        </div>
      </>
    );
  };

  return (
    <Modal
      footer={null}
      closable={false}
      maskClosable={true}
      width={"40%"}
      open={open}
      title={null}
      onCancel={onClose}
      confirmLoading={loading}
      className="object-layout-creation-modal"
    >
      <div className="header">Create an object layout</div>
      {renderInner()}
    </Modal>
  );
}

export default compose<Props, ICreateObjectLayoutModalProps>(
  WithOrg,
  withRouter,
  withAntUtils
)(CreateObjectLayoutModal);
