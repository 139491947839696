import * as React from "react";
import type { IDataset } from "../../../../../../interfaces/sources";
import type { Transformation } from "../../../../../../interfaces/transformations";

import { Drawer } from "antd";
import { compose } from "../../../../../../components/compose/WlyCompose";
import Error from "../../../../../../components/error/Error";
import Feednack from "../../../../../../components/layout/feedback/feedback";
import Loading from "../../../../../../components/layout/feedback/loading";
import type { AsyncData } from "../../../../../../helpers/typescriptHelpers";
import type { IDestination } from "../../../../../../interfaces/destinations";
import { computeTransformations } from "../../../../../../services/BrizoService";
import type { InjectedOrgProps } from "../../../../../orgs/WithOrg";
import WithOrg from "../../../../../orgs/WithOrg";
import FlowConfiguration from "./FlowConfiguration";
import "./FlowConfiguration.scss";

interface IFlowViewerProps {
  datasets: IDataset[];
  query: AsyncData<Transformation[]>;
  title: string;
  head?: string;
  visible: boolean;
  onClose: () => void;
  extra?: React.ReactNode;
  dataset: IDataset;
  currentWarehouse: IDestination;
}

interface IState {
  data: AsyncData<Transformation[]>;
}

type Props = IFlowViewerProps & InjectedOrgProps;
class FlowViewer extends React.Component<Props, IState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      data: {
        status: "initial",
      },
    };
  }

  componentDidMount() {
    const { query, visible, currentWarehouse } = this.props;
    if (query.status === "success" && visible) {
      this.fetchData(currentWarehouse.id, query.data);
    }
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<IState>) {
    const { query, visible, currentWarehouse } = this.props;
    const {
      query: prevQuery,
      visible: prevVisible,
      currentWarehouse: prevWarehouse,
    } = prevProps;

    if (
      visible &&
      query.status === "success" &&
      (visible !== prevVisible ||
        currentWarehouse?.id !== prevWarehouse?.id ||
        prevQuery.status !== "success" ||
        JSON.stringify(prevQuery) !== JSON.stringify(query))
    ) {
      this.fetchData(currentWarehouse.id, query.data);
    }
  }

  fetchData = async (warehouseId: string, query: Transformation[]) => {
    this.setState({
      data: {
        status: "loading",
      },
    });
    try {
      const data = await computeTransformations(
        warehouseId,
        { res: query },
        true
      );
      this.setState({
        data: {
          status: "success",
          data: data.data.res as any as Transformation[],
        },
      });
    } catch (err) {
      console.error(err);
      this.setState({
        data: {
          status: "error",
          error: err,
        },
      });
    }
  };

  public renderInner = () => {
    const { data } = this.state;
    const { datasets, head, dataset, query, currentWarehouse } = this.props;
    if (
      query.status === "initial" ||
      query.status === "loading" ||
      data.status === "initial" ||
      data.status === "loading"
    ) {
      return (
        <Feednack>
          <Loading />
        </Feednack>
      );
    }

    if (data.status === "error") {
      return (
        <Feednack>
          <Error />
        </Feednack>
      );
    }

    const rewrittenQuery = data.data.map((q) => {
      return {
        ...q,
        domain: "datasetResolver",
      } as Transformation;
    });
    // we rewrite the query since we are keeping only dataset resolver below to avoid the refs

    return (
      <div style={{ height: "100%" }}>
        <FlowConfiguration
          datasets={datasets}
          dataset={dataset}
          query={rewrittenQuery}
          currentWarehouse={currentWarehouse}
          staleHead={
            head ? head : rewrittenQuery[rewrittenQuery.length - 1].var
          }
          isStale={false}
          staleQuery={JSON.stringify(rewrittenQuery)}
        />
      </div>
    );
  };

  public render() {
    const { visible, onClose, extra, title } = this.props;
    return (
      <Drawer
        open={visible}
        height={"calc(100% - 48px)"}
        closable={true}
        destroyOnClose={true}
        onClose={onClose}
        placement="bottom"
        footer={null}
        title={title}
        styles={{
          body: { padding: 0 },
          header: { padding: "16px 8px" },
        }}
        extra={extra}
      >
        {this.renderInner()}
      </Drawer>
    );
  }
}

export default compose<Props, IFlowViewerProps>(WithOrg)(FlowViewer);
