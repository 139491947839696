import { Select } from "antd";
import { type AvailableProperty } from "../../containers/v2-demo/container/object/viewer/domain";
import { type AvailableMetric } from "../measures/filter-item/FilterItem";

export interface ISingleColumnAdditionProps {
  value?: string[];
  onChange?: (v: string[]) => void;
  availableColumns: Array<
    | { type: "metric"; data: AvailableMetric }
    | { type: "property"; data: AvailableProperty }
  >;
}

export function SingleColumnAddition(props: ISingleColumnAdditionProps) {
  const { value, onChange, availableColumns } = props;
  return (
    <Select allowClear value={value} onChange={onChange}>
      {availableColumns.map((ac) => {
        return <Select.Option key={ac.data.key}>{ac.data.label}</Select.Option>;
      })}
    </Select>
  );
}
