import { Form, Input } from "antd";
import type { FormInstance } from "antd/es/form/Form";

interface IRenameObjectViewFormProps {
  form: FormInstance<RenameObjectViewFormData>;
  initialValues?: Partial<RenameObjectViewFormData>;
  onSave: (values: RenameObjectViewFormData) => any;
}

export type RenameObjectViewFormData = {
  id: string;
  name: string;
};

export function RenameObjectViewForm(props: IRenameObjectViewFormProps) {
  const { form, initialValues, onSave } = props;
  return (
    <Form<RenameObjectViewFormData>
      form={form}
      preserve={false}
      layout="vertical"
      initialValues={initialValues}
      onFinish={(values) => {
        onSave(values);
      }}
    >
      <Form.Item
        label="Name"
        name={["name"]}
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input placeholder="View name" />
      </Form.Item>
      <Form.Item
        noStyle
        hidden
        name={["id"]}
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input />
      </Form.Item>
    </Form>
  );
}
