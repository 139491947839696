import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import { Button, ConfigProvider, Layout, Space, Typography } from "antd";
import Sider from "antd/es/layout/Sider";
import { Content, Header } from "antd/es/layout/layout";
import type { Location } from "history";
import _ from "lodash";
import { inject, observer } from "mobx-react";
import React from "react";
import type { RouteComponentProps } from "react-router";
import { Route, Switch, matchPath, withRouter } from "react-router";
import { compose } from "../../../../components/compose/WlyCompose";
import Error from "../../../../components/error/Error";
import { WlyCaretDownIcon } from "../../../../components/icons/custom-icons/WlyCaretDownIcon";
import Feednack from "../../../../components/layout/feedback/feedback";
import { LoadingLogo } from "../../../../components/layout/feedback/loadinglogo";
import UserAvatar from "../../../../components/user/avatar/UserAvatar";
import { IOrgFeatureType } from "../../../../interfaces/org";
import { IUserRoleType } from "../../../../interfaces/user";
import routes, { routeDescriptor } from "../../../../routes/routes";
import { routesComponents } from "../../../../routes/routesComponents";
import { track } from "../../../../services/AnalyticsService";
import type { WorkspaceDatatoreProps } from "../../../../store/dataStores/workspace/workspaceDatastore";
import type { WorkspaceUIStoreProps } from "../../../../store/workspaceUIStore";
import type { InjectedOrgProps } from "../../../orgs/WithOrg";
import WithOrg from "../../../orgs/WithOrg";
import AppSwitcherDropdown from "../../../orgs/picker/AppSwitcherDropdown";
import UserMenuDropdown from "../../../orgs/picker/UserMenuDropdown";
import { currentTool } from "../../../orgs/picker/domain";
import HasRoleAccess from "../../../user-settings/HasRoleAccess";
import ObjectShareForm from "../object/viewer/modals/ObjectShareForm";
import WorkspaceSearchModal from "../workspace/WorkspaceSearchModal";
import { LayoutDNDWrapper } from "./LayoutDNDWrapper";
import { OrgLogoV2 } from "./OrgLogoV2";
import "./V2Layout.scss";
import type { IV2Context } from "./domain";
import { BORDER_COLOR } from "./domain";
import CreateButton from "./menus/CreateButton";
import SettingsMenu from "./menus/SettingsMenu";
import WorkspaceMenu from "./menus/WorkspaceMenu";

interface IV2LayoutProps {
  menu?: "DEFAULT" | "SETTINGS";
}

type Props = IV2LayoutProps &
  InjectedOrgProps &
  RouteComponentProps &
  WorkspaceDatatoreProps &
  WorkspaceUIStoreProps;

class V2Layout extends React.Component<
  Props,
  {
    loading: boolean;
    explorationModalOpen: boolean;
    mobileSiderOpen: boolean;
  }
> {
  private location: Location;

  constructor(props: Props) {
    super(props);
    this.state = {
      loading: false,
      explorationModalOpen: false,
      mobileSiderOpen: false,
    };

    // if the initial route is not a modal we store the current route
    // as a fallback so when we open the modal we can close & return to the same route
    // I am pretty sure there is a better way to do it but I don't know how at 10pm
    if (!this.getCurrentModalRoute()?.embedModal) {
      this.overrideLocation();
    }
  }

  overrideLocation() {
    let location = Object.assign({}, this.props.location);
    this.location = location;
  }

  async componentDidMount() {
    const { workspaceDatastore, org, user } = this.props;
    this.setState({ loading: true });

    const includeObjects = !!org.featureGrants.find(
      (fg) => fg.feature.apiName === IOrgFeatureType.OBJECTS
    );

    const notInLabels: string[] = [];
    if (!user.isAdmin) {
      notInLabels.push("technical");
    }
    track("Workspace Viewed", {});
    await workspaceDatastore.init(
      {
        orgId: org.id,
        userId: user.id,
        notInLabels: notInLabels,
        includeObjects: includeObjects,
      },
      2500
    );
    this.setState({ loading: false });

    const toolname = this.getToolName();
    if (toolname === "workbench") {
      workspaceDatastore.sleep();
    } else {
      workspaceDatastore.wakeUp();
    }
  }

  getToolName = () => {
    const { location } = this.props;
    const route = this.getCurrentModalRoute();
    const toolName = currentTool(
      route?.embedModal && this.location
        ? this.location.pathname
        : location.pathname
    );
    return toolName;
  };

  componentDidUpdate(prevProps: Props) {
    const { org, user, workspaceDatastore, location } = this.props;
    if (prevProps.org.id !== org.id || prevProps.user.id !== user.id) {
      const notInLabels: string[] = [];
      if (!user.isAdmin) {
        notInLabels.push("technical");
      }
      workspaceDatastore.reset();
      const includeObjects = !!org.featureGrants.find(
        (fg) => fg.feature.apiName === IOrgFeatureType.OBJECTS
      );
      workspaceDatastore.init(
        {
          orgId: org.id,
          userId: user.id,
          notInLabels: notInLabels,
          includeObjects,
        },
        2500
      );
    }
    // if the route is not a modal we store the previous route
    if (
      !_.isEqual(location, prevProps.location) &&
      !this.getCurrentModalRoute()?.embedModal
    ) {
      this.overrideLocation();
    }

    const toolname = this.getToolName();
    if (toolname === "workbench") {
      workspaceDatastore.sleep();
    } else {
      workspaceDatastore.wakeUp();
    }
  }

  componentWillUnmount() {
    const { workspaceDatastore } = this.props;
    workspaceDatastore.reset();
  }

  getCurrentModalRoute = () => {
    const {
      location: { pathname },
    } = this.props;
    const modalRoutes = routes.filter((k) => k.embedModal);
    return modalRoutes.find((mr) => {
      return !!matchPath(pathname, mr.path);
    });
  };

  render() {
    const {
      user,
      org,
      workspaceDatastore: { data },
      workspaceUIStore,
      workspaceUIStore: { siderOpen, siderSize },
      menu,
      history,
      match: { params },
      location,
    } = this.props;

    const { loading } = this.state;

    const route = this.getCurrentModalRoute();
    const toolName = currentTool(
      route?.embedModal && this.location
        ? this.location.pathname
        : location.pathname
    );

    if (loading || data.status === "initial" || data.status === "loading") {
      return (
        <Feednack>
          <LoadingLogo />
        </Feednack>
      );
    }

    if (data.status === "error") {
      return (
        <Feednack>
          <Error />
        </Feednack>
      );
    }

    const context: IV2Context = {
      folders: data.data.allReportFolders,
      personalFolder: data.data.myFolder?.[0],
      allObjects: data.data.allObjects ? data.data.allObjects : [],
    };

    const renderModal = () => {
      if (route && route.embedModal) {
        const Comp = routesComponents[route.embedModal];
        return (
          <Comp
            open={!!route}
            additionalProps={route.props}
            onClose={() =>
              history.push(
                this.location
                  ? this.location
                  : routeDescriptor.home.renderRoute({
                      ...params,
                    })
              )
            }
          />
        );
      } else {
        return null;
      }
    };

    const tool = currentTool(location.pathname);

    return (
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: "#3a5c83",
            colorBorderSecondary: BORDER_COLOR,
          },
          components: {
            Dropdown: {
              controlItemBgActive: "#E7ECEF",
              controlItemBgActiveHover: "rgba(0, 0, 0, 0.04)",
            },
          },
        }}
      >
        {workspaceUIStore.shareObjectModalOpen && (
          <ObjectShareForm
            visible={!!workspaceUIStore.shareObjectModalOpen}
            canBeManagedByCurrentUser={
              workspaceUIStore.shareObjectModalOpen.canBeManagedByCurrentUser ||
              false
            }
            onClose={() => workspaceUIStore.setShareObjectModalOpen(undefined)}
            object={workspaceUIStore.shareObjectModalOpen}
          />
        )}
        <LayoutDNDWrapper>
          {(drop, isDraggingReport) => {
            const renderMenu = () => {
              if (!menu || menu === "DEFAULT") {
                return (
                  <WorkspaceMenu
                    allReportFolders={data.data.allReportFolders}
                    personalFolder={data.data.myFolder?.[0]}
                    allObjects={data.data.allObjects}
                    isDraggingReport={isDraggingReport}
                  />
                );
              } else if (menu === "SETTINGS") {
                return <SettingsMenu />;
              }
            };

            return (
              <Layout className="v2-layout" ref={drop}>
                {toolName !== "workbench" &&
                !workspaceUIStore.hideRightHeader ? (
                  <Header className="v2-layout-header">
                    <WorkspaceSearchModal
                      allExplorationSections={data.data.allExplorationSections}
                      allExplorations={data.data.allExplorations}
                      folders={data.data.allReportFolders}
                      personalFolder={data.data.myFolder}
                      objects={data.data.allObjects ?? []}
                    />
                    <div style={{ flex: 1, height: "100%" }}>
                      <Space style={{ height: "100%", display: "flex" }}>
                        <div>
                          <Button
                            className="display-inline display-none-lg"
                            shape="circle"
                            onClick={() => {
                              workspaceUIStore.setSiderOpen();
                            }}
                            icon={
                              this.state.mobileSiderOpen ? (
                                <MenuFoldOutlined />
                              ) : (
                                <MenuUnfoldOutlined />
                              )
                            }
                          />
                          <OrgLogoV2
                            className="display-none display-block-lg"
                            logo={user.portal?.logo ?? org.logo}
                            open={workspaceUIStore.siderOpen}
                            onClick={() => workspaceUIStore.setSiderOpen()}
                          />
                        </div>
                        {user.type === "PORTAL" && (
                          <Typography.Text type="secondary" strong>
                            {_.capitalize(user.portal?.name ?? org.name)}
                          </Typography.Text>
                        )}
                        {user.type === "STANDARD" && (
                          <>
                            <Button
                              type="text"
                              key="org"
                              size="small"
                              onClick={() => {
                                history.push(
                                  routeDescriptor["org-picker"].renderRoute({
                                    ...params,
                                  })
                                );
                              }}
                            >
                              <Typography.Text type="secondary" strong>
                                {_.capitalize(org.name)}
                              </Typography.Text>
                              <span
                                style={{
                                  // transform: "scale(0.8)",
                                  marginLeft: 4,
                                }}
                              >
                                <WlyCaretDownIcon
                                  style={{ color: "#939393" }}
                                />
                              </span>
                            </Button>
                            <Typography.Text
                              type="secondary"
                              className="display-none display-inline-lg"
                            >
                              /
                            </Typography.Text>
                            <AppSwitcherDropdown>
                              <Button
                                type="text"
                                key="app"
                                size="small"
                                className="display-none display-inline-lg"
                              >
                                <Typography.Text strong>
                                  {_.capitalize(currentTool(location.pathname))}
                                </Typography.Text>
                                <span
                                  style={{
                                    /*transform: "scale(0.8)", */ marginLeft: 4,
                                  }}
                                >
                                  <WlyCaretDownIcon />
                                </span>
                              </Button>
                            </AppSwitcherDropdown>
                          </>
                        )}
                      </Space>
                    </div>
                    <div style={{ flex: 0, height: "100%" }}>
                      <Space style={{ height: "100%", display: "flex" }}>
                        {tool === "workspace" && (
                          <HasRoleAccess accessLevel={IUserRoleType.EDITOR}>
                            <div className="display-none display-inline-lg">
                              <CreateButton />
                            </div>
                          </HasRoleAccess>
                        )}
                        <UserMenuDropdown>
                          <div
                            style={{
                              height: "100%",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              border: `1px solid ${BORDER_COLOR}`,
                              borderRadius: "50%",
                            }}
                          >
                            <UserAvatar
                              user={user}
                              size={32}
                              style={{ cursor: "pointer" }}
                            />
                          </div>
                        </UserMenuDropdown>
                      </Space>
                    </div>
                  </Header>
                ) : null}
                <Layout style={{ position: "relative" }} hasSider>
                  {toolName !== "workbench" && (
                    <Sider
                      width={siderOpen ? siderSize : 0}
                      style={{
                        backgroundColor: "#F9F8F8",
                        borderRight: "1px solid #D8D8D8",
                        overflowX: "hidden",
                      }}
                      className="whly-sub-menu display-none display-block-lg"
                    >
                      {renderMenu()}
                    </Sider>
                  )}
                  <div
                    style={{
                      backgroundColor: "#F9F8F8",
                      borderRight: "1px solid #D8D8D8",
                      overflowX: "hidden",
                      overflowY: "auto",
                      position: "absolute",
                      top: 0,
                      bottom: 0,
                      left: 0,
                      width: siderOpen ? siderSize : 0,
                      transition: "width 0.2s",
                      zIndex: 2,
                    }}
                    className="whly-sub-menu display-block display-none-lg"
                  >
                    {renderMenu()}
                  </div>
                  <Content
                    style={{
                      overflowY: "auto",
                      maxHeight: "100%",
                      background: "white",
                      zIndex: 1,
                    }}
                  >
                    <Switch
                      location={route?.embedModal ? this.location : undefined}
                    >
                      {routes
                        .filter((r) => !r.public && (r as any).subComponent)
                        .map((r) => {
                          const Component =
                            routesComponents[(r as any).subComponent];
                          const subProps = (r as any).subProps;
                          const props = (r as any).props;
                          return (
                            <Route
                              key={0} // shared key to reuse layout and avoid remounting components on route change
                              exact={true}
                              path={r.path}
                            >
                              <Component
                                {...(r.removeInheritance ? {} : this.props)}
                                {...(subProps ? subProps : {})}
                                {...(props ? props : {})}
                                {...{ context: context }}
                              />
                            </Route>
                          );
                        })}
                    </Switch>
                    {renderModal()}
                  </Content>
                </Layout>
              </Layout>
            );
          }}
        </LayoutDNDWrapper>
      </ConfigProvider>
    );
  }
}

export default compose<Props, IV2LayoutProps>(
  WithOrg,
  withRouter,
  inject("workspaceDatastore", "workspaceUIStore"),
  observer
)(V2Layout);
