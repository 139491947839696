import { CheckCircleTwoTone } from "@ant-design/icons";
import { Button, Modal } from "antd";
import { inject, observer } from "mobx-react";
import * as React from "react";
import type { RouteComponentProps } from "react-router";
import { withRouter } from "react-router";
import { compose } from "../../../../../../components/compose/WlyCompose";
import Loading from "../../../../../../components/layout/feedback/loading";
import type { IMigrationStep } from "../../../../../../components/migration/MigrationHelper";
import { MigrationHelper } from "../../../../../../components/migration/MigrationHelper";
import type { AsyncData } from "../../../../../../helpers/typescriptHelpers";
import type { IDestination } from "../../../../../../interfaces/destinations";
import type { IDatasetRelationship } from "../../../../../../interfaces/sources";
import type { Transformation } from "../../../../../../interfaces/transformations";
import type { IView } from "../../../../../../interfaces/view";
import { computeTransformations } from "../../../../../../services/BrizoService";
import GraphQLService from "../../../../../../services/graphql/GraphQLService";
import type { WorkbenchUIStoreProps } from "../../../../../../store/workbenchUIStore";
import { encodeUrlParam } from "../../../../../../store/workbenchUIStore";
import type { InjectedOrgProps } from "../../../../../orgs/WithOrg";
import WithOrg from "../../../../../orgs/WithOrg";
import type { IActiveObject } from "../../../domain";

interface IFlowMigrationModalProps {
  fromViewId: string;
  visible: boolean;
  onClose: () => void;
  onUpdateDataset?: (
    datasetId: string,
    a: {
      name?: string;
      description?: string;
      sql?: string;
      primaryKey?: string;
    }
  ) => Promise<any>;
  routeRender: (params: object, query?: object) => string;
  currentWarehouse: IDestination;
}

interface IState {
  initialData: AsyncData<{
    name: string;
    table_ids: string[];
    transformed_query: Transformation[];
    incomingRelationships: IDatasetRelationship[];
    outgoingRelationships: IDatasetRelationship[];
    primaryKeys: string;
  }>;
  step: "STEP_1" | "STEP_2";
  migrateModel: AsyncData<{
    datasetId: string;
    datasetSlug: string;
    viewId: string;
  }>;
  migrateRelationships: AsyncData<{}>;
  migrateExploration: AsyncData<{}>;
  overallMigration: AsyncData<{
    url: string;
  }>;
}

type Props = IFlowMigrationModalProps &
  InjectedOrgProps &
  RouteComponentProps<{ organizationSlug: string }> &
  WorkbenchUIStoreProps;

class FlowMigrationModal extends React.Component<Props, IState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      initialData: {
        status: "initial",
      },
      step: "STEP_1",
      migrateModel: {
        status: "initial",
      },
      migrateExploration: {
        status: "initial",
      },
      migrateRelationships: {
        status: "initial",
      },
      overallMigration: {
        status: "initial",
      },
    };
  }

  componentDidMount() {
    this.fetchQuery(this.props.currentWarehouse?.id, this.props.fromViewId);
  }

  componentDidUpdate(prevProps: Props) {
    if (
      this.props.currentWarehouse?.id !== prevProps.currentWarehouse?.id ||
      this.props.fromViewId !== prevProps.fromViewId ||
      (this.props.visible !== prevProps.visible && this.props.visible === true)
    ) {
      this.fetchQuery(this.props.currentWarehouse?.id, this.props.fromViewId);
    }

    if (
      this.props.visible !== prevProps.visible &&
      this.props.visible === false
    ) {
      this.setState({
        step: "STEP_1",
        initialData: { status: "initial" },
        overallMigration: { status: "initial" },
        migrateExploration: { status: "initial" },
        migrateModel: { status: "initial" },
        migrateRelationships: { status: "initial" },
      });
    }
  }

  fetchQuery = async (warehouseId: string, viewId: string) => {
    this.setState({
      initialData: {
        status: "loading",
      },
    });
    try {
      const data = await GraphQLService<{ View: IView }>(
        `
      query getDatasetConfigFromViewId($viewId: ID!) {
        View(where: {id: $viewId}) {
          id
          name
          rawQuery
          dataset {
            primaryKey
            incomingRelationships(where: { deleted_not: true }) {
              type
              from
              to
              left {
                id
              }
            }
            outgoingRelationships(where: { deleted_not: true }) {
              type
              from
              to
              right {
                id
              }
            }
          }
          table {
            id
          }
        }
      }
    `,
        {
          viewId: viewId,
        }
      );
      const currentQuery = JSON.parse(data.View.rawQuery);
      const query = await computeTransformations(
        warehouseId,
        { res: currentQuery },
        true
      );
      this.setState({
        initialData: {
          status: "success",
          data: {
            name: data.View.name,
            table_ids: data.View.table.map((t) => t.id),
            transformed_query: query.data.res as any as Transformation[],
            incomingRelationships: data.View.dataset.incomingRelationships,
            outgoingRelationships: data.View.dataset.outgoingRelationships,
            primaryKeys: data.View.dataset.primaryKey,
          },
        },
      });
    } catch (err) {
      console.error(err);
      this.setState({
        initialData: {
          status: "error",
          error: err,
        },
      });
    }
  };

  renderInner = () => {
    const { initialData, step } = this.state;
    const { org, workbenchUIStore } = this.props;

    if (initialData.status === "initial" || initialData.status === "loading") {
      return <Loading />;
    }
    if (initialData.status === "error") {
      return (
        <div>
          There was an error transforming your query... please reach out to
          support
        </div>
      );
    }

    if (step === "STEP_1") {
      return (
        <div>
          <div>
            Flow modeling is a new and more robust way to create tables fitting
            your business needs. With Flow you can:
          </div>
          <div style={{ paddingTop: 12 }}>
            <CheckCircleTwoTone twoToneColor={"#52c41a"} /> Easily combine data
            from different sources
          </div>
          <div style={{ paddingTop: 12 }}>
            <CheckCircleTwoTone twoToneColor={"#52c41a"} /> Audit which
            collaborator has done what
          </div>
          <div style={{ paddingTop: 12 }}>
            <CheckCircleTwoTone twoToneColor={"#52c41a"} /> Sandbox your changes
            so one change does not impact your entire organisation
          </div>
          <div style={{ paddingTop: 12 }}>
            During the migration we will create a new model, transfer all
            relationships to the newly created model and replace tables used in
            explorations with the new model.
          </div>
        </div>
      );
    }

    if (step === "STEP_2") {
      const steps: IMigrationStep[] = [
        {
          name: <span>Creating Model...</span>,
          onStart: async () => {
            this.setState({
              migrateModel: { status: "loading" },
              overallMigration: { status: "loading" },
            });
            try {
              const dataset = await GraphQLService<{
                createDataset: {
                  id: string;
                  slug: string;
                  views: { id: string }[];
                };
              }>(
                `
              mutation createDataset($datasetQuery: DatasetCreateInput) {
                createDataset(data: $datasetQuery) {
                  id
                  slug,
                  views {
                    id
                  }
                }
              }
              `,
                {
                  datasetQuery: {
                    name: initialData.data.name,
                    primaryKey: initialData.data.primaryKeys,
                    head: initialData.data.transformed_query[
                      initialData.data.transformed_query.length - 1
                    ].var,
                    type: "FLOW",
                    isModel: true,
                    org: {
                      connect: {
                        id: org.id,
                      },
                    },
                    warehouse: {
                      connect: {
                        id: this.props.currentWarehouse?.id,
                      },
                    },
                    query: {
                      create: {
                        type: "MASHUP",
                        queryText: JSON.stringify(
                          initialData.data.transformed_query
                        ),
                      },
                    },
                  },
                }
              );
              this.setState({
                migrateModel: {
                  status: "success",
                  data: {
                    datasetId: dataset.createDataset.id,
                    viewId: dataset.createDataset.views[0].id,
                    datasetSlug: dataset.createDataset.slug,
                  },
                },
              });
              return Promise.resolve();
            } catch (err) {
              console.error(err);
              this.setState({
                migrateModel: { status: "error", error: err },
                overallMigration: {
                  status: "error",
                  error: err,
                },
              });
              return Promise.resolve();
            }
          },
          store: this.state.migrateModel,
        },
        {
          name: <span>Migrating relationships...</span>,
          onStart: async () => {
            const { migrateModel } = this.state;
            this.setState({ migrateRelationships: { status: "loading" } });
            if (migrateModel.status !== "success") {
              const error = new Error(
                "Couldn't migrate relationships as model hasn't been created"
              );
              this.setState({
                migrateRelationships: {
                  status: "error",
                  error: error,
                },
                overallMigration: {
                  status: "error",
                  error: error,
                },
              });
              return Promise.resolve();
            }
            try {
              const data = await GraphQLService<{
                updateDataset: { id: string };
              }>(
                `
              mutation updateDataset($id: ID!, $data: DatasetUpdateInput) {
                updateDataset(id: $id, data: $data) {
                  id
                }
              }
              `,
                {
                  id: migrateModel.data.datasetId,
                  data: {
                    incomingRelationships: {
                      create: initialData.data.incomingRelationships.map(
                        (ir) => {
                          return {
                            from: ir.from,
                            to: ir.to,
                            type: ir.type,
                            editable: true,
                            deleted: false,
                            left: {
                              connect: {
                                id: ir.left.id,
                              },
                            },
                            right: {
                              connect: {
                                id: migrateModel.data.datasetId,
                              },
                            },
                            org: {
                              connect: {
                                id: org.id,
                              },
                            },
                          };
                        }
                      ),
                    },
                    outgoingRelationships: {
                      create: initialData.data.outgoingRelationships.map(
                        (ir) => {
                          return {
                            from: ir.from,
                            to: ir.to,
                            type: ir.type,
                            editable: true,
                            deleted: false,
                            left: {
                              connect: {
                                id: migrateModel.data.datasetId,
                              },
                            },
                            right: {
                              connect: {
                                id: ir.right.id,
                              },
                            },
                            org: {
                              connect: {
                                id: org.id,
                              },
                            },
                          };
                        }
                      ),
                    },
                  },
                }
              );
              this.setState({
                migrateRelationships: {
                  status: "success",
                  data: {},
                },
              });
            } catch (err) {
              console.error(err);
              this.setState({
                migrateRelationships: {
                  status: "error",
                  error: err,
                },
                overallMigration: {
                  status: "error",
                  error: err,
                },
              });
            }
            return Promise.resolve();
          },
          store: this.state.migrateRelationships,
        },
        {
          name: <span>Migrating explorations...</span>,
          onStart: async () => {
            const { migrateModel } = this.state;
            this.setState({ migrateExploration: { status: "loading" } });
            if (migrateModel.status !== "success") {
              const error = new Error(
                "Couldn't migrate explorations as model hasn't been created"
              );
              this.setState({
                migrateExploration: {
                  status: "error",
                  error: error,
                },
                overallMigration: {
                  status: "error",
                  error: error,
                },
              });
              return Promise.resolve();
            }
            try {
              const data = await GraphQLService(
                `
                mutation updateTablesAfterMigration($data: [TablesUpdateInput]!) {
                  updateTables(data: $data) {
                    id
                  }
                }
              `,
                {
                  data: initialData.data.table_ids.map((t) => {
                    return {
                      id: t,
                      data: {
                        primaryKey: initialData.data.primaryKeys,
                        view: {
                          connect: {
                            id: migrateModel.data.viewId,
                          },
                        },
                      },
                    };
                  }),
                }
              );
              await this.props.onUpdateDataset(migrateModel.data.datasetId, {});
              this.setState({
                migrateExploration: {
                  status: "success",
                  data: {},
                },
              });
            } catch (err) {
              console.error(err);
              this.setState({
                migrateExploration: {
                  status: "error",
                  error: err,
                },
                overallMigration: {
                  status: "error",
                  error: err,
                },
              });
            }

            return Promise.resolve();
          },
          store: this.state.migrateExploration,
        },
      ];
      return (
        <MigrationHelper
          steps={steps}
          onDone={async () => {
            if (this.state.migrateModel.status === "success") {
              const datasetId = this.state.migrateModel.data.datasetId;
              const url = this.props.routeRender({
                ...this.props.match.params,
                tableSlug: encodeUrlParam({
                  type: "dataset",
                  value: datasetId,
                }),
              });
              this.setState(
                {
                  overallMigration: {
                    status: "success",
                    data: {
                      url: url,
                    },
                  },
                },
                () => {
                  setTimeout(() => {
                    this.props.onClose();
                    const newActiveObject: IActiveObject = {
                      type: "dataset",
                      value: datasetId,
                    };
                    workbenchUIStore.pushActiveObject(newActiveObject);
                    workbenchUIStore.setActiveObjectFocused(newActiveObject);
                  }, 2000);
                }
              );
            }

            return Promise.resolve();
          }}
        />
      );
    }
  };

  renderFooter = () => {
    const renderRightButton = () => {
      if (this.state.step === "STEP_1") {
        return (
          <Button
            disabled={this.state.initialData.status !== "success"}
            loading={
              this.state.initialData.status === "initial" ||
              this.state.initialData.status === "loading"
            }
            type="primary"
            onClick={() => this.setState({ step: "STEP_2" })}
          >
            Migrate
          </Button>
        );
      }
      return (
        <Button
          disabled={this.state.overallMigration.status !== "success"}
          loading={
            this.state.overallMigration.status === "initial" ||
            this.state.overallMigration.status === "loading"
          }
          type="primary"
          onClick={() => {
            this.props.onClose();
            if (this.state.overallMigration.status === "success") {
              this.props.history.push(this.state.overallMigration.data.url);
            }
          }}
        >
          Take me to my Flow
        </Button>
      );
    };

    return (
      <div style={{ display: "flex", width: "100%" }}>
        <div style={{ flex: 1, textAlign: "left" }}>
          <Button onClick={this.props.onClose}>Cancel</Button>
        </div>
        <div style={{ flex: 1, textAlign: "right" }}>{renderRightButton()}</div>
      </div>
    );
  };

  public render() {
    const { visible, onClose } = this.props;
    return (
      <Modal
        open={visible}
        onCancel={onClose}
        footer={this.renderFooter()}
        title={
          this.state.step === "STEP_1"
            ? "Welcome to the Flow migration assistant"
            : "Migrating..."
        }
        destroyOnClose={true}
      >
        {this.renderInner()}
      </Modal>
    );
  }
}

export default compose<Props, IFlowMigrationModalProps>(
  WithOrg,
  withRouter
)(inject("workbenchUIStore")(observer(FlowMigrationModal)));
