import type { Query } from "@cubejs-client/core";
import type { SeriesTreemapOptions } from "highcharts";
import Highcharts from "highcharts";
import { HighchartsReact } from "highcharts-react-official";
import HighchartsTreemap from "highcharts/modules/treemap";
import _ from "lodash";
import * as React from "react";
import type { ChartOption } from "../../../containers/chart-options/ChartOptions";
import type { Meta } from "../../../containers/exploration/single/visualization/chart/Chart";
import type { UserLocale } from "../../../interfaces/user";
import type PaletteGenerator from "../../palette/utils/PaletteGenerator";
import type { Formatter } from "../domain";
import { localizeHighchart } from "../domain";
import { optionsHelper, renderSerieTooltip } from "../utils/optionsHelper";

HighchartsTreemap(Highcharts);

interface ITreemapChartProps {
  height: number;
  width?: number;
  data: Meta["tableRows"];
  config: TreemapChartConfig;
  onDrill?: (q: Query) => void;
  chartOptions?: ChartOption;
  palette: PaletteGenerator;
  locale: UserLocale;
}

interface TreemapChartConfig {
  metricKey: string;
  label?: string;
  canDrill: (xValue: string, yValue?: string) => Query | null;
  formatter: Formatter;
  dimensionKeys: string[];
}

type IState = Highcharts.Options;

const formatData = (
  data: Meta["tableRows"],
  config: TreemapChartConfig,
  chartOptions: ChartOption,
  palette: PaletteGenerator
): SeriesTreemapOptions["data"] => {
  if (data?.length === 0) {
    return [];
  }

  const metricKey = config.metricKey;
  const dimensionKeys = config.dimensionKeys;

  if (dimensionKeys.length === 1) {
    const formattedData: SeriesTreemapOptions["data"] = data.map((d, i) => {
      const value = d[metricKey] ? +d[metricKey] : null;
      const dimValue = d[dimensionKeys[0]] as string;
      const options = chartOptions?.series?.[dimValue];
      const name =
        options?.label && options?.label.length
          ? options?.label
          : d[dimensionKeys[0]];
      const color =
        chartOptions?.series &&
        chartOptions?.series[dimValue] &&
        chartOptions?.series[dimValue].color
          ? chartOptions?.series[dimValue].color
          : palette.getColorAtIndex(i);

      return {
        value: value,
        name: name as string,
        color: color,
      };
    });
    return formattedData;
  } else if (dimensionKeys.length === 2) {
    // parent dimension values are used for creating the groups
    const parentDimension = dimensionKeys[0];
    const childDimension = dimensionKeys[1];
    const parentValues = data
      .map((item) => item[parentDimension])
      .filter((value, index, self) => self.indexOf(value) === index);

    const formattedParents: SeriesTreemapOptions["data"] = parentValues.map(
      (pv: string, i) => {
        const options = chartOptions?.series?.[pv];
        const name =
          options?.label && options?.label.length ? options?.label : pv;
        const color =
          chartOptions?.series &&
          chartOptions?.series[pv] &&
          chartOptions?.series[pv].color
            ? chartOptions?.series[pv].color
            : palette.getColorAtIndex(i);
        return {
          id: pv,
          name: name,
          color: color,
        };
      }
    );

    const formattedChildren: SeriesTreemapOptions["data"] = data.map((d) => {
      return {
        value: d[metricKey] as number,
        name: d[childDimension] as string,
        parent: d[parentDimension] as string,
      };
    });
    return [...formattedParents, ...formattedChildren];
  } else {
    return [];
  }
};

const generateOptions = (
  data: Meta["tableRows"],
  config: TreemapChartConfig,
  onDrill: (q: Query) => void | undefined,
  width: number,
  height: number,
  chartOptions: ChartOption,
  optionHelpers,
  palette: PaletteGenerator,
  locale: UserLocale
) => {
  const formattedData = formatData(data, config, chartOptions, palette);
  const options: Highcharts.Options = {
    ...optionHelpers,
    chart: {
      ...optionHelpers.chart,
      type: "treemap",
      height: height,
      width: width,
      backgroundColor: "transparent",
      spacing: [
        optionHelpers.chart.spacing[0],
        -2,
        optionHelpers.chart.spacing[2],
        -2,
      ],
    },
    plotOptions: {
      ...optionHelpers.plotOptions,
      treemap: {
        cursor: typeof onDrill === "function" ? "pointer" : "auto",
        borderRadius: 5,
      },
    },
    series: [
      {
        type: "treemap",
        layoutAlgorithm: "sliceAndDice",
        alternateStartingDirection: true,
        levels: [
          {
            level: 1,
            borderColor: "white",
            borderWidth: 3,
            dataLabels: {
              enabled: chartOptions?.label ?? true,
              align: "left",
              verticalAlign: "top",
              style: {
                textOutline: 0,
                fontSize: "15px",
                fontWeight: "bold",
                fontFamily: "Lato",
                cursor: "pointer",
              },
            },
          },
          {
            level: 2,
            borderColor: "white",
            borderWidth: 2,
            dataLabels: {
              enabled: chartOptions?.label ?? true,
              align: "center",
              verticalAlign: "middle",
              style: {
                textOutline: 0,
                fontFamily: "Lato",
                cursor: "pointer",
              },
            },
          },
        ],
        data: formattedData,
        tooltip: renderSerieTooltip({
          data: data,
          locale: locale,
          seriesLength: 1,
          formatter: config.formatter,
          alternateSerieDisplayLabel: config.label,
          hideSerieColor: true,
          valueGetter: "this.value",
        }),
        events: {
          click: onDrill
            ? (e) => {
                const xValue = e?.point?.name?.toString();
                const parentValue = (e as any).point.parent?.toString();
                const concat = [parentValue, xValue].filter((v) => v).join(",");
                const q = config.canDrill(concat);
                // we prevent drilling on top level dimension when we have two dimensions
                // as this use case is not supported in the drills as of today
                // we should find a less hacky way to do it
                if (
                  config?.dimensionKeys?.length === 2 &&
                  (e as any)?.point?.node?.level === 1
                ) {
                  return;
                } else if (q) {
                  return onDrill(q);
                }
              }
            : undefined,
        },
      },
    ],
  };
  return options;
};

export default class TreemapChart extends React.Component<
  ITreemapChartProps,
  IState
> {
  ref?: Highcharts.Chart;

  shouldComponentUpdate(nextProps: Readonly<ITreemapChartProps>): boolean {
    if (!_.isEqual(this.props.width, nextProps.width)) return true;
    if (!_.isEqual(this.props.height, nextProps.height)) return true;
    if (!_.isEqual(this.props.data, nextProps.data)) return true;
    if (!_.isEqual(this.props.chartOptions, nextProps.chartOptions))
      return true;
    if (
      !_.isEqualWith(this.props.palette, nextProps.palette, (a, b) =>
        typeof a === "function" || typeof b === "function" ? true : undefined
      )
    ) {
      return true;
    }
    return false;
  }

  public render() {
    const {
      data,
      palette,
      height,
      width,
      config,
      chartOptions,
      onDrill,
      locale,
    } = this.props;

    localizeHighchart(Highcharts, locale);

    const optionHelpers = optionsHelper(
      height,
      width,
      locale,
      "ordinal",
      config.formatter,
      undefined,
      undefined,
      chartOptions
    );

    const options = generateOptions(
      data,
      config,
      onDrill,
      width,
      height,
      chartOptions,
      optionHelpers,
      palette,
      locale
    );

    return (
      <div style={{ height, width: "100%" }}>
        <HighchartsReact
          ref={(r) => (this.ref = r?.chart)}
          highcharts={Highcharts}
          options={options}
        />
      </div>
    );
  }
}
