import * as React from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { MoreOutlined } from "@ant-design/icons";

interface IOrderedListProps {
  fallbackValues?: string[];
  onChange?: (val: string[]) => void;
  values?: string[];
}

const reorder = (list: string[], startIndex: number, endIndex: number) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const grid = 8;

const getItemStyle = (
  isDragging: boolean,
  draggableStyle?: React.CSSProperties
): React.CSSProperties => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  padding: `${grid / 2}px 0`,
  margin: `0 0 ${grid / 2}px 0`,

  // change background colour if dragging
  background: isDragging ? "#E7ECEF" : "transparent",

  // styles we need to apply on draggables
  ...draggableStyle,
});

const getListStyle = (isDraggingOver: boolean) => ({
  padding: `${grid}px 0`,
  width: "100%",
});

export function OrderedList(props: IOrderedListProps) {
  const [values, setValues] = React.useState<string[]>(
    props.values
      ? props.values
      : props.fallbackValues
      ? props.fallbackValues
      : []
  );

  return (
    <DragDropContext
      onDragEnd={(r) => {
        if (!r.destination) {
          return;
        }
        const items = reorder(values, r.source.index, r.destination.index);
        setValues(items);
        if (props.onChange) {
          props.onChange(items);
        }
      }}
    >
      <Droppable droppableId="droppable">
        {(provided, snapshot) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            style={getListStyle(snapshot.isDraggingOver)}
          >
            {values.map((v, i) => {
              return (
                <Draggable key={v} draggableId={v} index={i}>
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={getItemStyle(
                        snapshot.isDragging,
                        provided.draggableProps.style
                      )}
                    >
                      <span>
                        <MoreOutlined /> {v}
                      </span>
                    </div>
                  )}
                </Draggable>
              );
            })}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
}
