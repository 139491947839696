import { Typography } from "antd";

export interface IWarningCardProps {}

export function WarningCard(props: IWarningCardProps) {
  return (
    <div
      style={{
        backgroundColor: `rgba(241,189,108,0.3)`,
        borderRadius: 4,
        padding: 8,
      }}
    >
      <div style={{ paddingBottom: 12 }}>
        <Typography.Text strong style={{ color: `#8E3C23` }}>
          3 Warnings
        </Typography.Text>
      </div>
      <div style={{ display: "flex", flexDirection: "column", gap: 8 }}>
        <div>Pas de rencontres lors des trois dernières visites</div>
        <div>Magasin en sous-visite</div>
        <div>
          Les ventes déclarées sur la visite précédente ne se sont pas
          concrétisées
        </div>
      </div>
    </div>
  );
}
