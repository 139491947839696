import { ExclamationCircleOutlined, MoreOutlined } from "@ant-design/icons";
import type { MenuProps } from "antd";
import { Button, Col, Drawer, Dropdown, Row } from "antd";
import type { ColumnProps } from "antd/lib/table";
import { inject, observer } from "mobx-react";
import * as React from "react";
import type { RouteComponentProps } from "react-router";
import { withRouter } from "react-router";
import type { InjectedAntUtilsProps } from "../../../components/ant-utils/withAntUtils";
import { withAntUtils } from "../../../components/ant-utils/withAntUtils";
import { compose } from "../../../components/compose/WlyCompose";
import Aligner from "../../../components/layout/aligner/Aligner";
import CardTable from "../../../components/table/CardTable";
import type {
  IServiceAccount,
  IServiceAccountKey,
} from "../../../interfaces/serviceAccount";
import { IUserRoleType } from "../../../interfaces/user";
import GraphQLService from "../../../services/graphql/GraphQLService";
import type { UserStoreProps } from "../../../store/userStore";
import type { InjectedOrgProps } from "../../orgs/WithOrg";
import WithOrg from "../../orgs/WithOrg";
import HasRoleAccess, {
  hasRoleAccessBoolean,
} from "../../user-settings/HasRoleAccess";
import "./ServiceAccountManagement.scss";
import ServiceAccountManagementForm from "./ServiceAccountManagementForm";

interface IUserGroupManagementTableProps {
  serviceAccounts: IServiceAccount[];
  reRender: () => void;
  createServiceAccount: (
    orgId: string,
    name: string,
    role: IUserRoleType
  ) => Promise<string>;
  createKey: (serviceAccountId: string) => Promise<string>;
  removeKey: (key: IServiceAccountKey) => Promise<void>;
}

interface IState {
  isEditFormVisible: boolean;
  selectedServiceAccount?: IServiceAccount;
}

type Props = IUserGroupManagementTableProps &
  RouteComponentProps<{ organizationSlug: string }> &
  UserStoreProps &
  InjectedOrgProps &
  InjectedAntUtilsProps;

class ServiceAccountManagementTable extends React.Component<Props, IState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isEditFormVisible: false,
    };
  }

  public showDrawer = () => {
    this.setState({ isEditFormVisible: true });
  };

  public hideDrawer = () => {
    this.setState({
      isEditFormVisible: false,
      selectedServiceAccount: undefined,
    });
  };

  public onSubmit = async () => {
    this.props.reRender();
    this.props.userStore.getUser();
  };

  public disableServiceAccount = (userRoleId: string) => {
    const { antUtils } = this.props;
    return antUtils.modal.confirm({
      title: `Are your sure you want to delete this Service Account?`,
      icon: <ExclamationCircleOutlined />,
      content: `By deleting this Service Account, any integration relying on any of its keys will stop working.`,
      okText: "Delete anyway",
      okButtonProps: {
        danger: true,
      },
      cancelText: "Cancel",
      onOk: async () => {
        try {
          await GraphQLService(
            `
          mutation DeleteUserRole(
            $userRoleId: ID!,
          ) {
            updateUserRole(
              id: $userRoleId,
              data: { isDeleted: true }
            ) {
              id
            }
          }`,
            { userRoleId }
          );
          await this.onSubmit();
          antUtils.message.success("Service Account successfully deleted");
        } catch (err) {
          antUtils.message.error("An unexpected error happened, please retry");
        }
      },
      onCancel() {
        return null;
      },
    });
  };

  public renderServiceAccountName = (sa: IServiceAccount) => {
    return sa.name;
  };

  public generateColumns = (
    connectedUserRoleType: IUserRoleType
  ): ColumnProps<IServiceAccount>[] => [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (v, s) => {
        return (
          <div style={{ display: "flex" }}>
            <div>{this.renderServiceAccountName(s)}</div>
          </div>
        );
      },
    },
    {
      title: "Keys",
      dataIndex: "keys",
      key: "keys",
      render: (v, s) => {
        return (
          <div>
            <div>{s.keys.length} keys</div>
          </div>
        );
      },
    },
    ...(connectedUserRoleType === IUserRoleType.ADMIN_BUILDER
      ? [
          {
            title: "Actions",
            key: "Actions",
            align: "right" as const,
            render: (v, sa: IServiceAccount) => {
              const menu: MenuProps = {
                items: [
                  {
                    key: `manage-keys-sa-${sa.id}`,
                    onClick: () =>
                      this.setState({
                        isEditFormVisible: true,
                        selectedServiceAccount: sa,
                      }),
                    label: "Manage keys",
                  },
                  // TODO: let's see how we will handle that when the Service Account will have multiple UserRoles
                  sa.roles.length === 1 && {
                    key: `disable-sa-${sa.id}`,
                    onClick: () => this.disableServiceAccount(sa.roles[0].id),
                    label: "Disable",
                    danger: true,
                  },
                ],
              };

              return (
                <HasRoleAccess accessLevel={IUserRoleType.ADMIN_BUILDER}>
                  <Dropdown
                    trigger={["click"]}
                    placement="bottomRight"
                    menu={menu}
                  >
                    <Button
                      type="text"
                      shape="circle"
                      icon={<MoreOutlined />}
                    />
                  </Dropdown>
                </HasRoleAccess>
              );
            },
          },
        ]
      : []),
  ];

  public render() {
    const {
      serviceAccounts,
      user: connectedUser,
      org,
      role: connectedUserRole,
    } = this.props;
    return (
      <Aligner>
        <Row justify="center" className="service-account-management">
          <Col xs={24} sm={24} md={20} lg={20} xl={20}>
            <CardTable<IServiceAccount>
              cardTitle={<div className="title">Service Accounts</div>}
              actionButtons={
                hasRoleAccessBoolean(
                  IUserRoleType.ADMIN_BUILDER,
                  connectedUser,
                  org.id
                ) ? (
                  <Button onClick={this.showDrawer} type="primary">
                    Create new Service Account
                  </Button>
                ) : undefined
              }
              rowKey="id"
              dataSource={serviceAccounts}
              columns={this.generateColumns(connectedUserRole.role)}
              pagination={{
                size: "small",
                style: { display: "none" },
                defaultPageSize: 1000,
              }}
            />
          </Col>
          <Drawer
            width={"35%"}
            open={this.state.isEditFormVisible}
            closable={false}
            styles={{ body: { padding: "0 0 80px 0" } }}
            keyboard={false}
            maskClosable={false}
            destroyOnClose={true}
          >
            <ServiceAccountManagementForm
              orgId={org.id}
              isEditMode={this.state.selectedServiceAccount !== undefined}
              selectedServiceAccount={this.state.selectedServiceAccount}
              onSubmit={this.onSubmit}
              onDrawerClose={this.hideDrawer}
              createServiceAccount={this.props.createServiceAccount}
              createKey={this.props.createKey}
              removeKey={this.props.removeKey}
            />
          </Drawer>
        </Row>
      </Aligner>
    );
  }
}

export default compose<Props, IUserGroupManagementTableProps>(
  inject("userStore"),
  observer,
  withRouter,
  WithOrg,
  withAntUtils
)(ServiceAccountManagementTable);
