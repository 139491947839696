import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useHorizontalScroll } from "../../../../components/hooks/useHorizontalScroll";
import type { IActiveObject } from "../domain";
import "./TabBar.scss";
import { TabBarItem } from "./TabBarItem";

interface ITabBarProps {
  activeObjects: IActiveObject[];
  onReorder: (activeObjects: IActiveObject[]) => void;
  onRemoveObject: (activeObject: IActiveObject) => void;
  onObjectSelect: (activeObject: IActiveObject) => void;
  nameRenderer: (activeObject: IActiveObject) => string;
  iconRenderer?: (activeObject: IActiveObject) => React.ReactNode;
}

function reorder<T>(list: T[], startIndex: number, endIndex: number) {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
}

export function TabBar(props: ITabBarProps) {
  const scrollRef = useHorizontalScroll();

  return (
    <DragDropContext
      onDragEnd={(result) => {
        if (!result.destination) {
          return;
        }

        const items = reorder(
          [...props.activeObjects],
          result.source.index,
          result.destination.index
        );
        props.onReorder(items);
      }}
    >
      <Droppable droppableId={"WB_TABS"} type="WB_TAB" direction="horizontal">
        {(provided, snapshot) => (
          <div ref={provided.innerRef} {...provided.droppableProps}>
            <div className="content-tab-bar" ref={scrollRef}>
              {props.activeObjects.map((ao, index) => {
                return (
                  <Draggable
                    draggableId={ao.value}
                    key={ao.value}
                    index={index}
                  >
                    {(provided) => (
                      <span
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                      >
                        <TabBarItem
                          activeObject={ao}
                          key={ao.value}
                          onRemoveObject={props.onRemoveObject}
                          onObjectSelect={props.onObjectSelect}
                          nameRenderer={props.nameRenderer}
                          iconRenderer={props.iconRenderer}
                          dragHandleProps={provided.dragHandleProps}
                        />
                      </span>
                    )}
                  </Draggable>
                );
              })}
              {provided.placeholder}
            </div>
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
}
