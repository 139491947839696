import { MenuOutlined } from "@ant-design/icons";
import { Transfer } from "antd";
import type { TransferDirection } from "antd/es/transfer";
import type { TransferItem } from "antd/lib/transfer";
import _ from "lodash";
import React from "react";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import MeasureItem from "../../../../../../../components/measures/measure-item/MeasureItem";
import type { IMeasureTable } from "../../../domain";
import { isFakeId } from "../../../domain";
import MeasureEditorTree from "../../../measure-editor-tree/MeasureEditorTree";

import "./TreeTransfer.scss";

interface TreeTransferProps {
  tables: IMeasureTable[];
  targetKeys?: string[];
  onChange?: (
    targetKeys: string[],
    direction: TransferDirection,
    moveKeys: string[]
  ) => void;
}

const DraggableItem = ({
  index,
  type,
  name,
  moveRow,
}: {
  index: number;
  type: any;
  name: string;
  moveRow: (dragIndex: number, hoverIndex: number) => void;
}) => {
  const ref = React.useRef(null);
  const [{ isOver, dropClassName }, drop] = useDrop({
    accept: type,
    collect: (monitor: any) => {
      const { index: dragIndex } = monitor.getItem() || {};
      if (dragIndex === index) {
        return {};
      }
      return {
        isOver: monitor.isOver(),
        dropClassName:
          dragIndex < index ? ` drop-over-downward` : ` drop-over-upward`,
      };
    },
    drop: (item: { index: number }) => {
      moveRow(item.index, index);
    },
  });

  const [, drag, preview] = useDrag({
    type: type,
    item: { index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  preview(drop(ref));

  return (
    <div
      key={index}
      ref={ref}
      className={`tree-item-wrapper ${isOver ? dropClassName : ""}`}
    >
      <div className="label">
        <MeasureItem
          hidePopover={true}
          compact={true}
          type={type}
          name={name}
        />
      </div>
      {index !== -1 && (
        <div className="drag-icon" ref={drag}>
          <MenuOutlined />
        </div>
      )}
    </div>
  );
};

export const TreeTransfer = ({
  tables,
  targetKeys,
  onChange,
  ...restProps
}: TreeTransferProps) => {
  const transferItems: TransferItem[] = [];
  tables.forEach((t) => {
    t.dimensions.forEach((d) => {
      transferItems.push({
        key: isFakeId(d.id) ? d.id : `dim${d.id}`,
        title: d.name,
        type: "dimension",
        externalId: `dimension-${d.id}`,
      });
    });
    t.metrics.forEach((d) => {
      transferItems.push({
        key: isFakeId(d.id) ? d.id : `met${d.id}`,
        title: d.name,
        type: "metric",
        externalId: `metric-${d.id}`,
      });
    });
  });

  // there seems to be a bug where keys can get duplicated in the api
  const sanitizedTargetKeys = _.uniq(
    (targetKeys ?? []).filter((tk) =>
      transferItems.map((ti) => ti.key).includes(tk)
    )
  );

  const moveRow = async (dragIndex, hoverIndex) => {
    const clonedList = [...(sanitizedTargetKeys ?? [])];
    const el = clonedList.splice(dragIndex, 1)[0];
    clonedList.splice(hoverIndex, 0, el);
    if (onChange) {
      onChange(clonedList, "right", []);
    }
  };

  const onChangeWrapper: (
    targetKeys: string[],
    direction: TransferDirection,
    moveKeys: string[]
  ) => void = (tk, direction, moveKeys) => {
    if (direction === "right") {
      // we need to put dimension at the of the list and
      const allAddedDimensions = moveKeys.filter((mk) => mk.startsWith("dim"));
      const allAddedMetrics = moveKeys.filter((mk) => mk.startsWith("met"));
      const newTargetKeys = [
        ...allAddedDimensions,
        ...(targetKeys || []),
        ...allAddedMetrics,
      ];
      return onChange?.(newTargetKeys, direction, moveKeys);
    }
    return onChange?.(tk, direction, moveKeys);
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <Transfer
        {...restProps}
        onChange={onChangeWrapper}
        targetKeys={sanitizedTargetKeys || []}
        className="tree-transfer"
        render={(item) => {
          return (
            <DraggableItem
              type={item.type}
              name={item.title || ""}
              index={(sanitizedTargetKeys || []).findIndex(
                (key) => key === item.key
              )}
              moveRow={moveRow}
            />
          );
        }}
        titles={["Available measures", "Selected measures"]}
        dataSource={transferItems}
        showSelectAll={false}
      >
        {({ direction, onItemSelect, selectedKeys }) => {
          if (direction === "left") {
            const checkedKeys = [
              ...selectedKeys
                .map((sk) => {
                  const found = transferItems.find((t) => t.key === sk);
                  if (found) {
                    return found.externalId;
                  }
                  return null;
                })
                .filter((t) => !!t),
              ...(sanitizedTargetKeys || []),
            ];
            return (
              <MeasureEditorTree
                tables={tables}
                disabledCapabilities={[]}
                checkable={{
                  checkedKeys: checkedKeys,
                  onCheck: (key, check) => {
                    const found = transferItems.find(
                      (t) => t.externalId === key
                    );
                    if (found && found.key) {
                      onItemSelect(found.key, check);
                    }
                  },
                  isChecked: (dependency) => {
                    if (dependency.type === "semanticGroup") {
                      return false;
                    }
                    return checkedKeys.includes(dependency.item.cubeName);
                  },
                  targetKeys: sanitizedTargetKeys || [],
                }}
              />
            );
          }
        }}
      </Transfer>
    </DndProvider>
  );
};
