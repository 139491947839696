import { customFormatter } from "../../../../../../../components/chart/utils/optionsHelper";
import type { IObjectPropertyPillsFormatterConfig } from "../../../../../../../interfaces/object";
import type { UserLocale } from "../../../../../../../interfaces/user";
import type { AvailableColumn } from "../../../../object/domain";
import { parseObjectPropertyFormatter } from "../../../../object/viewer/domain";
import type { IRecord } from "../../../domain";
import { parseLabel } from "../../../domain";
import type { IWidgetVisibilityFiltersValue } from "../../common/visibility-filters/WidgetVisibilityFilters";

export interface IEmailWidgetPropertyListConfig {
  align?: "left" | "right" | "center";
  spacing?: "normal" | "compact";
  columns?: Array<string>;
  options?: {
    [keys: string]: {
      label?: string;
      color?: "normal" | "success" | "warning" | "danger";
      displayFilter?: IWidgetVisibilityFiltersValue;
    };
  };
}

export const getDefaultConfig = (
  conf: IEmailWidgetPropertyListConfig,
  record: IRecord
) => {
  const columns = conf.columns ? conf.columns : [];
  const align = conf.align ? conf.align : [];
  const spacing = conf.spacing ? conf.spacing : "normal";

  return {
    columns,
    align,
    spacing,
  };
};

export const getPropertyColor = (
  a: AvailableColumn,
  v: number | string | boolean
): string | undefined => {
  if (a.type === "property" && a.data.formatter === "pills") {
    const config = parseObjectPropertyFormatter(
      "pills",
      a.data.formatterConfig || ""
    ) as IObjectPropertyPillsFormatterConfig;
    const valueColor = config.config.values.find(
      (a) => a.value.toString() === v.toString()
    )?.color;
    return valueColor ?? config.config.default;
  }
  return;
};

export const renderColors = (
  color: "danger" | "success" | "warning" | "normal"
): { bgColor: string; fontColor: string } => {
  switch (color) {
    case "danger":
      return {
        bgColor: "#E5CED6",
        fontColor: "#E5014B",
      };
    case "success":
      return {
        bgColor: "#E8FDF9",
        fontColor: "#00806a",
      };
    case "warning":
      return {
        bgColor: "#FEEAB6",
        fontColor: "#7E5C04",
      };
    default:
      return {
        bgColor: "#F5F5F5",
        fontColor: "#1E1E1E",
      };
  }
};

const formatProperty = (p: string) => p.replaceAll(".", "_");

const renderFormattedPropertyValue = (
  availableColumn: AvailableColumn,
  rawValue: any,
  userLocale: UserLocale
): string => {
  if (!rawValue) {
    return rawValue;
  }
  if (availableColumn.type === "property") {
    if (availableColumn.data.type !== "standard") {
      const parsed = parseLabel(rawValue);
      return parsed.name ? parsed.name : parsed.id;
    }
  } else if (availableColumn.type === "metric") {
    const m = availableColumn.data.formatter;
    return customFormatter(rawValue, userLocale, m);
  }
  return rawValue;
};

export const renderValue = (
  availableColumn: AvailableColumn,
  conf: IEmailWidgetPropertyListConfig,
  record: IRecord,
  userLocale: UserLocale
) => {
  const formattedP = formatProperty(availableColumn.data.key);
  const value = renderFormattedPropertyValue(
    availableColumn,
    record[availableColumn.data.key],
    userLocale
  );
  return `${
    conf.options?.[formattedP]?.label
      ? `${conf.options?.[formattedP]?.label} `
      : ""
  }${
    typeof value === "number" || typeof value === "string" ? value : "No value"
  }`;
};
