import Icon from "@ant-design/icons";
import type { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";

export const WlyActivityChartIcon = (
  props: Partial<CustomIconComponentProps>
) => (
  <Icon
    component={() => (
      <svg
        width="1em"
        height="1em"
        viewBox="0 0 213 213"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="34.5" cy="40.5" r="28.5" fill="#FBCE19" />
        <circle cx="107" cy="41" r="18" fill="#FBCE19" />
        <circle cx="177.5" cy="42.5" r="26.5" fill="#FBCE19" />
        <circle cx="34.5" cy="172.5" r="28.5" fill="#41D58A" />
        <circle cx="107" cy="173" r="18" fill="#41D58A" />
        <circle cx="177.5" cy="174.5" r="26.5" fill="#41D58A" />
        <ellipse cx="34.5" cy="107" rx="19.5" ry="20" fill="#3A5C83" />
        <ellipse cx="177.5" cy="107" rx="19.5" ry="20" fill="#3A5C83" />
        <circle cx="106.5" cy="106.5" r="26.5" fill="#3A5C83" />
      </svg>
    )}
    {...props}
  />
);
