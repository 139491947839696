import { Button } from "antd";
import * as React from "react";
import type { InjectedColorProps } from "react-color";
import { CustomPicker } from "react-color";
import {
  Alpha,
  EditableInput,
  Hue,
  Saturation,
} from "react-color/lib/components/common";

import "./SimpleColorEditor.scss";

interface ISimpleColorEditorProps {
  onAddition: () => void;
  onDeletion: () => void;
  disableAddition: boolean;
  disableDeletion: boolean;
}

type Props = InjectedColorProps & ISimpleColorEditorProps;

class SimpleColorEditor extends React.Component<Props> {
  public render() {
    const { onAddition, onDeletion, disableDeletion, disableAddition } =
      this.props;
    return (
      <div className="simple-color-editor">
        <div className="simple-color-editor-header">
          <div className="simple-color-editor-header-left">
            <Button
              disabled={disableDeletion}
              onClick={onDeletion}
              size="small"
            >
              -
            </Button>
            <Button
              disabled={disableAddition}
              onClick={onAddition}
              size="small"
            >
              +
            </Button>
          </div>
          <div className="simple-color-editor-header-right">
            <EditableInput
              {...this.props}
              style={{
                input: {
                  width: "100%",
                  fontSize: 12,
                },
              }}
              value={this.props.hex}
            />
          </div>
        </div>
        <div className="simple-color-editor-saturation">
          <Saturation {...this.props} onChange={this.props.onChange as any} />
        </div>
        <div className="simple-color-editor-checkboard">
          <Hue
            {...this.props}
            direction={"horizontal"}
            onChange={this.props.onChange as any}
          />
        </div>
        <div className="simple-color-editor-checkboard">
          <Alpha {...this.props} onChange={this.props.onChange as any} />
        </div>
      </div>
    );
  }
}

export default CustomPicker(SimpleColorEditor);
