import { Modal, Select, Space, Typography } from "antd";
import { useState } from "react";
import {
  withAntUtils,
  type InjectedAntUtilsProps,
} from "../../../../../../../../components/ant-utils/withAntUtils";
import { compose } from "../../../../../../../../components/compose/WlyCompose";
import { SourceImageRenderer } from "../../../../../../../../components/sources/SourceImageRenderer";
import type { IRadar } from "../../../../../../../../interfaces/radar";
import type { IDataset } from "../../../../../../../../interfaces/sources";
import type { UpdateRadarFunction } from "../../../../../selector/radar/domain";

type IRadarModelModalProps = {
  datasets: IDataset[];
  radar: IRadar;
  open: boolean;
  onClose: () => void;
  onUpdateRadar: UpdateRadarFunction;
};

type Props = IRadarModelModalProps & InjectedAntUtilsProps;

function RadarModelModal({
  antUtils,
  datasets,
  radar,
  open,
  onClose,
  onUpdateRadar,
}: Props) {
  const [newDatasetId, setNewDatasetId] = useState<string | undefined>(
    radar?.model?.id
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  return (
    <Modal
      title={"Update radar"}
      open={open}
      onOk={async () => {
        try {
          setIsLoading(true);
          await onUpdateRadar(radar.id, {
            model: {
              id: newDatasetId,
            },
          });
          antUtils.message.success("Radar successfully updated");
        } catch (error) {
          console.error(error);
          antUtils.message.error("Error while updating radar");
          setNewDatasetId(radar?.model?.id);
        } finally {
          setIsLoading(false);
          onClose();
        }
      }}
      okButtonProps={{
        loading: isLoading,
        disabled: isLoading || newDatasetId === radar?.model?.id,
      }}
      onCancel={() => {
        setNewDatasetId(radar?.model?.id);
        onClose();
      }}
    >
      <Typography.Text type="secondary">Based on</Typography.Text>
      <Select
        style={{ width: "100%" }}
        showSearch
        optionFilterProp="label"
        value={newDatasetId}
        onChange={(v) => {
          setNewDatasetId(v);
        }}
      >
        {datasets
          .filter((d) => d.views?.length && d.isModel)
          .sort(function (a, b) {
            if (a.name < b.name) {
              return -1;
            }
            if (a.name > b.name) {
              return 1;
            }
            return 0;
          })
          .map((v) => {
            return (
              <Select.Option key={v.id} value={v.id} label={v.name}>
                <Space>
                  <SourceImageRenderer
                    alt={"sourceName"}
                    className="source-table-selection-logo"
                    img={
                      !v.isModel
                        ? v.source.sourceMeta.publicInfo.logo
                        : undefined
                    }
                    size={16}
                    isModel={v.isModel}
                  />
                  {v.name}
                  <Typography.Text type="secondary">
                    {v.isModel ? "Model" : v.source?.name}
                  </Typography.Text>
                </Space>
              </Select.Option>
            );
          })}
      </Select>
    </Modal>
  );
}

export default compose<Props, IRadarModelModalProps>(withAntUtils)(
  RadarModelModal
);
