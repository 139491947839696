import { Button, Col, Row, Space, Tooltip } from "antd";
import type { ColumnProps } from "antd/lib/table";
import moment from "moment";
import * as React from "react";
import type { RouteComponentProps } from "react-router";
import { withRouter } from "react-router";
import type { InjectedAntUtilsProps } from "../../../../components/ant-utils/withAntUtils";
import { withAntUtils } from "../../../../components/ant-utils/withAntUtils";
import { compose } from "../../../../components/compose/WlyCompose";
import Aligner from "../../../../components/layout/aligner/Aligner";
import { FromNow } from "../../../../components/moment/FromNow";
import CardTable from "../../../../components/table/CardTable";
import UserAvatar from "../../../../components/user/avatar/UserAvatar";
import { handleGQLErrors } from "../../../../helpers/gqlHelpers";
import type { IAction, IActionMeta } from "../../../../interfaces/actions";
import { IUserRoleType } from "../../../../interfaces/user";
import { routeDescriptor } from "../../../../routes/routes";
import GraphQLService from "../../../../services/graphql/GraphQLService";
import { CatalogModal } from "../../../catalog/CatalogModal";
import type { InjectedOrgProps } from "../../../orgs/WithOrg";
import WithOrg from "../../../orgs/WithOrg";
import { hasRoleAccessBoolean } from "../../../user-settings/HasRoleAccess";

interface IActionManagementTableProps {
  actions: IAction[];
  actionMetas: IActionMeta[];
}

type Props = IActionManagementTableProps &
  InjectedOrgProps &
  InjectedAntUtilsProps &
  RouteComponentProps<{}>;

function ActionManagementTable(props: Props) {
  const {
    antUtils,
    actions,
    user,
    org,
    history,
    match: { params },
    location,
    actionMetas,
  } = props;

  const [keys, setKeys] = React.useState<string[]>([]);
  const [showCatalog, setShowCatalog] = React.useState<boolean>(false);

  const trash = () => {
    antUtils.modal.confirm({
      title: "This operation cannot be undone",
      content: `You are about to delete ${keys.length} actions. This operation cannot be undone. Do you want to proceed?`,
      okButtonProps: {
        danger: true,
      },
      okText: "Delete",
      onOk: () => {
        return GraphQLService(
          `
          mutation DeleteAction($data: [ActionsUpdateInput]!) {
            updateActions(data: $data) {
              id
            }
          }
          `,
          {
            data: keys.map((i) => ({
              id: i,
              data: {
                isDeleted: true,
              },
            })),
          }
        )
          .then(() => {
            history.push(routeDescriptor["redirect"].renderRoute(params), {
              to: location.pathname,
            });
          })
          .catch(handleGQLErrors);
      },
      onCancel: () => {
        return Promise.resolve();
      },
    });
  };

  const generateColumns = (): ColumnProps<IAction>[] => [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (v, s) => {
        const meta = actionMetas.find((am) => am.id === s.actionMeta);
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            {meta && <img alt={v} style={{ width: 24, height: 24 }} />}
            {v}
          </div>
        );
      },
    },
    {
      title: "Created by",
      dataIndex: "createdBy",
      key: "createdBy",
      render: (v, s) => {
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <Tooltip
              title={`${s.createdBy?.firstName} ${s.createdBy?.lastName}`}
              mouseEnterDelay={0.5}
              placement="top"
            >
              <UserAvatar user={v} size={24} />
            </Tooltip>
            <div style={{ paddingLeft: 6 }}>
              <FromNow date={moment(s.createdAt)} />
            </div>
          </div>
        );
      },
    },
  ];

  return (
    <Aligner>
      <Row justify="center" className="access-management">
        <Col xs={24} sm={24} md={20} lg={20} xl={20}>
          <CardTable<IAction>
            rowSelection={{
              type: "checkbox",
              selectedRowKeys: keys,
              onChange: (s) => {
                setKeys(s as string[]);
              },
            }}
            cardTitle={<div className="title">Installed Actions</div>}
            actionButtons={
              <Space size={12}>
                {hasRoleAccessBoolean(
                  IUserRoleType.ADMIN_BUILDER,
                  user,
                  org.id
                ) ? (
                  <Button
                    onClick={trash}
                    disabled={keys.length === 0}
                    color={"danger"}
                    danger={true}
                    type="primary"
                  >
                    Trash
                  </Button>
                ) : undefined}
                {hasRoleAccessBoolean(
                  IUserRoleType.ADMIN_BUILDER,
                  user,
                  org.id
                ) ? (
                  <Button onClick={() => setShowCatalog(true)} type="primary">
                    Install new action
                  </Button>
                ) : undefined}
              </Space>
            }
            rowKey="id"
            dataSource={actions}
            columns={generateColumns()}
            pagination={{
              size: "small",
              style: { display: "none" },
              defaultPageSize: 1000,
            }}
          />
        </Col>
      </Row>
      <CatalogModal
        type="action"
        open={showCatalog}
        onClose={() => setShowCatalog(false)}
      />
    </Aligner>
  );
}

export default compose<Props, IActionManagementTableProps>(
  WithOrg,
  withRouter,
  withAntUtils
)(ActionManagementTable);
