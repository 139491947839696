import { BulbOutlined } from "@ant-design/icons";
import type * as duckdb from "@duckdb/duckdb-wasm";
import { Card, Space, Typography } from "antd";
import numeral from "numeral";
import * as React from "react";
import Feednack from "../../../../../components/layout/feedback/feedback";
import Loading from "../../../../../components/layout/feedback/loading";
import type { AsyncData } from "../../../../../helpers/typescriptHelpers";
import type { IAdditionalFilters } from "./RootCauseAnalysisTable";
import type { RootCauseAnalysis } from "./interface";

interface IRootCauseAnalysisFactProps {
  rca: RootCauseAnalysis;
  db: duckdb.AsyncDuckDB;
  table: string;
  selectedProportionDimension: string;
  additionalFilters: IAdditionalFilters;
}

interface IRecoData {
  dimKey: string;
  dimValue: string;
  impact: number;
  population: number;
  rootPopulation: number;
}

export function RootCauseAnalysisFact(props: IRootCauseAnalysisFactProps) {
  const { rca, db, table, selectedProportionDimension } = props;

  const [columnData, setColumnData] = React.useState<
    AsyncData<{
      high: Array<IRecoData>;
      low: Array<IRecoData>;
      mainMetricVariation: number;
    }>
  >({ status: "initial" });

  const fetchInitialData = async () => {
    setColumnData({ status: "loading" });
    try {
      const c = await db.connect();

      const getTotalPopulation = async () => {
        // find the metric value data
        const allDimensionReduced = rca.dimensions
          .map((v) => {
            return `${v.key} is null`;
          })
          .join(" and ");
        const metricQuery = `select * from ${table} where ${allDimensionReduced}`;
        const metricData = await c.query(metricQuery);
        const col1Results = metricData.toArray().map((row) => row.toJSON());

        const populationImpacted = col1Results[0][selectedProportionDimension];
        const metricVariation = col1Results[0][rca.primaryMetric.key];
        return { population: populationImpacted, metricVariation };
      };

      const { population, metricVariation } = await getTotalPopulation();

      const findMostImpactedDimensions = async (
        find: "low" | "high",
        limit: number
      ) => {
        // find the most impacted dimension in low or high
        const whereClause = rca.dimensions
          .map((du) => {
            return `(${rca.dimensions
              .map((dd) => {
                if (du.key === dd.key) {
                  return `${dd.key} is not null`;
                }
                return `${dd.key} is null`;
              })
              .join(` and `)})`;
          })
          .join(` or `);

        const firstDimensionQuery = `select *, "${
          rca.primaryMetric.key
        }" * "${selectedProportionDimension}" / ${parseInt(
          population
        )} as impact from ${table} where ${whereClause} order by impact ${
          find === "low" ? "asc" : "desc"
        } limit ${limit}`;

        const firstDimensionsData = await c.query(firstDimensionQuery);
        const col2Dim = firstDimensionsData
          .toArray()
          .map((row) => row.toJSON())
          .map<IRecoData>((r) => {
            const nonNullDimKey = Object.keys(r).filter(
              (k) => rca.dimensions.map((d) => d.key).includes(k) && r[k]
            )[0];
            return {
              dimKey: nonNullDimKey,
              dimValue: r[nonNullDimKey],
              impact: parseFloat(r[rca.primaryMetric.key]),
              metric: r[rca.primaryMetric.key],
              population: parseInt(r[selectedProportionDimension]),
              rootPopulation: parseInt(population),
            };
          });

        return col2Dim;
      };

      const growth = await findMostImpactedDimensions("high", 3);
      const shrink = await findMostImpactedDimensions("low", 3);

      setColumnData({
        status: "success",
        data: {
          high: growth,
          low: shrink,
          mainMetricVariation: metricVariation,
        },
      });

      await c.close();
    } catch (err) {
      setColumnData({ status: "error", error: err });
    }
  };

  React.useEffect(() => {
    fetchInitialData();
  }, []);

  const renderInner = () => {
    if (columnData.status === "initial" || columnData.status === "loading") {
      return (
        <Feednack>
          <Loading />
        </Feednack>
      );
    }
    if (columnData.status === "error") {
      return <Feednack>{columnData.error?.message}</Feednack>;
    }

    const displayPercent = (n: number) => {
      return Math.round(n * 10000) / 100;
    };

    const renderCard = (h: IRecoData, f: "low" | "high") => {
      const dim = rca.dimensions.find((d) => d.key === h.dimKey);
      return (
        <Card key={`${h.dimKey}${h.dimValue}`} size="small">
          <div style={{ display: "flex" }}>
            <div style={{ flex: 1 }}>
              <Typography.Text ellipsis type="secondary">
                {dim?.label}
              </Typography.Text>
            </div>
            {/* <div style={{ flex: `0 auto` }}>
              <Button
                shape="circle"
                type="text"
                size="small"
                icon={
                  <Typography.Text type="secondary">
                    <RightOutlined />
                  </Typography.Text>
                }
              />
            </div> */}
          </div>
          <div>
            Stores with {dim?.label} as{" "}
            <Typography.Text strong>{h.dimValue}</Typography.Text> have{" "}
            {f === "high" ? (
              <Typography.Text type="success" strong>
                increased
              </Typography.Text>
            ) : (
              <Typography.Text type="danger" strong>
                decreased
              </Typography.Text>
            )}{" "}
            their {rca.primaryMetric.label} by{" "}
            <Typography.Text strong>
              {numeral(h.impact).format("0.[00]")}pt
            </Typography.Text>
            . This accounts for{" "}
            <Typography.Text strong>
              {displayPercent(h.population / h.rootPopulation)}%
            </Typography.Text>{" "}
            (<Typography.Text>{h.population}</Typography.Text> ) of your stores.
          </div>
        </Card>
      );
    };

    return (
      <div
        style={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          gap: 12,
        }}
      >
        <div style={{ flex: 0 }}>
          <Typography.Title level={5}>
            <BulbOutlined /> AI Insights on{" "}
            {numeral(columnData.data.mainMetricVariation).format("0.[00]")}pt{" "}
            {columnData.data.mainMetricVariation > 0 ? "increase" : "decrease"}
          </Typography.Title>
        </div>
        <div style={{ flex: 0 }}>
          <Space direction="vertical" style={{ width: "100%" }}>
            {columnData.data.high.map((h) => {
              return renderCard(h, "high");
            })}
          </Space>
        </div>
        <div style={{ flex: 0 }}>
          <Space direction="vertical" style={{ width: "100%" }}>
            {columnData.data.low.map((h) => {
              return renderCard(h, "low");
            })}
          </Space>
        </div>
      </div>
    );
  };

  return <div>{renderInner()}</div>;
}
