import type { IAction } from "../../../../interfaces/actions";
import type { ReportType } from "../../../../interfaces/reports";
import type { WorkflowContentFormat } from "../../../../interfaces/schedule";
export type ScheduleOuterType = "email" | "third_party";

interface FormatOption {
  value: string;
  label: string;
}

// Based on the report used in the Push, we have a different set of available options
export const getAvailableFormatOptionsForReportType = (
  reportType: ReportType
): FormatOption[] => {
  const format = [
    { value: "pdf", label: "PDF" },
    { value: "png", label: "PNG" },
  ];
  if (reportType === "QUESTION") {
    format.push(
      { value: "csv", label: "CSV" },
      { value: "json", label: "JSON" }
    );
  }
  return format;
};

export const ACTION_FORM_FIELD_VALUE_PREFIX = "actionId:";

export const generateDestinationId = (actionId: string) => {
  if (actionId === "email") {
    return "email";
  } else {
    return `${ACTION_FORM_FIELD_VALUE_PREFIX}${actionId}`;
  }
};

export const getFormatsSupportedByDestination = (
  destinationId: string,
  allActions: IAction[]
): WorkflowContentFormat[] => {
  if (destinationId === "email") {
    return ["png", "csv", "pdf"];
  } else if (destinationId.startsWith(ACTION_FORM_FIELD_VALUE_PREFIX)) {
    const actionId = destinationId.split(ACTION_FORM_FIELD_VALUE_PREFIX)[1];
    const action = allActions.find((actionRes) => {
      return actionRes.id === actionId;
    });

    const actionMetaSuppportedFormats =
      action?.actionMetaVirtual?.supportedFormats || [];
    const supportedFormats: WorkflowContentFormat[] = [];
    if (actionMetaSuppportedFormats.includes("csv")) {
      supportedFormats.push("csv");
    }
    if (actionMetaSuppportedFormats.includes("json_detail")) {
      supportedFormats.push("json");
    }
    if (actionMetaSuppportedFormats.includes("pdf")) {
      supportedFormats.push("pdf");
    }
    if (actionMetaSuppportedFormats.includes("png")) {
      supportedFormats.push("png");
    }
    return supportedFormats;
  } else {
    return [];
  }
};

export const getFormatOptionsAvailableForDestination = (
  destinationId: string,
  allActions: IAction[],
  reportType: ReportType
): FormatOption[] => {
  const supportedFormats = getFormatsSupportedByDestination(
    destinationId,
    allActions
  );
  // Take the intersection between what the current report type provides and what the destination support
  const formatOptions = getAvailableFormatOptionsForReportType(
    reportType
  ).filter((option) => {
    if (supportedFormats.includes(option.value as WorkflowContentFormat)) {
      return true;
    }
    return false;
  });
  return formatOptions;
};

export const getAvailableActions = (
  allActions: IAction[],
  reportType: ReportType
): IAction[] => {
  const actionWithAtLeastOneAvailableFormat = allActions.filter((action) => {
    const destinationId = generateDestinationId(action.id);
    const availableFormatOptions = getFormatOptionsAvailableForDestination(
      destinationId,
      allActions,
      reportType
    );
    if (availableFormatOptions.length > 0) {
      return true;
    } else {
      return false;
    }
  });
  return actionWithAtLeastOneAvailableFormat;
};
