import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Button, Modal, Space, Typography } from "antd";
import type { ColumnProps } from "antd/lib/table";
import * as React from "react";
import {
  type InjectedAntUtilsProps,
  withAntUtils,
} from "../../../../../../../components/ant-utils/withAntUtils";
import { compose } from "../../../../../../../components/compose/WlyCompose";
import CardTable from "../../../../../../../components/table/CardTable";
import type { AsyncData } from "../../../../../../../helpers/typescriptHelpers";
import type { IParsedColumnTest } from "../../../../../../../interfaces/sources";
import type { SchemaResult } from "../../../../../../../interfaces/transformations";
import type { IDatasetUpdate, TabData } from "../../../../domain";
import { ColumnTestForm } from "./ColumnTestForm";

interface ColumnTestsCardProps {
  onUpdateDataset?: IDatasetUpdate;
  currentTab: TabData;
  schema: AsyncData<SchemaResult>;
}

type Props = ColumnTestsCardProps & InjectedAntUtilsProps;

function ColumnTestsCard(props: Props) {
  const { onUpdateDataset, currentTab, schema, antUtils } = props;
  const [isEditingTest, setEditingTest] = React.useState<
    boolean | IParsedColumnTest
  >(false);

  const getData = (): Array<IParsedColumnTest> => {
    const dataset = currentTab.dataset;
    try {
      if (!dataset.columnTests && typeof dataset.columnTests !== "string") {
        return [];
      }
      const parsed = JSON.parse(dataset.columnTests);
      return parsed;
    } catch (err) {
      console.error(err);
      return [];
    }
  };

  const data = getData();

  const columns: ColumnProps<IParsedColumnTest>[] = [
    {
      title: "Column",
      dataIndex: "columns",
      key: "columns",
      width: "40%",
      ellipsis: true,
      render: (v, s) => {
        return <Typography.Text ellipsis>{s.column}</Typography.Text>;
      },
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      width: "40%",
      ellipsis: true,
      render: (v, s) => {
        return <Typography.Text ellipsis>{s.type}</Typography.Text>;
      },
    },
    {
      title: "Actions",
      dataIndex: "Actions",
      key: "Actions",
      width: "10%",
      render: (v, s) => {
        return (
          <Space>
            <Button
              shape="circle"
              disabled={!onUpdateDataset}
              icon={<EditOutlined />}
              onClick={() => {
                setEditingTest(s);
              }}
            />
            <Button
              shape="circle"
              disabled={!onUpdateDataset}
              icon={<DeleteOutlined />}
              danger
              onClick={() => {
                return new Promise<void>((resolve, reject) => {
                  antUtils.modal.confirm({
                    onCancel: () => {
                      return resolve();
                    },
                    onOk: () => {
                      return onUpdateDataset
                        ? onUpdateDataset({
                            columnTests: JSON.stringify(
                              data.filter((d) => d.id !== s.id)
                            ),
                          }).then(resolve)
                        : resolve();
                    },
                    okButtonProps: {
                      danger: true,
                    },
                    okText: "Delete",
                    title: "Are you sure you want to proceed ?",
                    content:
                      "You are about to delete a column, this operation cannot be undone. Are you sure you want to proceed ?",
                  });
                });
              }}
            />
          </Space>
        );
      },
    },
  ];

  return (
    <CardTable<IParsedColumnTest>
      cardTitle="Column Tests"
      actionButtons={
        onUpdateDataset && (
          <>
            <Button
              type="primary"
              size="small"
              onClick={() => setEditingTest(true)}
            >
              New
            </Button>
            <Modal
              open={!!isEditingTest}
              footer={null}
              title={
                typeof isEditingTest === "boolean"
                  ? "Create a column test"
                  : "Edit a column test"
              }
              onCancel={() => setEditingTest(false)}
            >
              <ColumnTestForm
                initialValues={
                  typeof isEditingTest === "boolean" ? undefined : isEditingTest
                }
                onUpdateDataset={onUpdateDataset}
                onDone={() => setEditingTest(false)}
                schema={schema}
                parsedColumnTests={data}
              />
            </Modal>
          </>
        )
      }
      rowKey="id"
      dataSource={data}
      columns={columns}
      pagination={{
        style: { display: "none" },
        defaultPageSize: 10000,
      }}
    />
  );
}

export default compose<Props, ColumnTestsCardProps>(withAntUtils)(
  ColumnTestsCard
);
