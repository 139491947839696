import styled from "@emotion/styled";
import type { DiagramEngine } from "@projectstorm/react-diagrams";
import * as React from "react";
import type { EditableLabelModel } from "./EditableLabelModel";

interface FlowAliasLabelWidgetProps {
  model: EditableLabelModel;
  engine: DiagramEngine;
}

namespace S {
  // NOTE: this CSS rules allows to interact with elements in label
  export const Label = styled.div<{ parentSelected: boolean }>`
    display: ${(p) => (p.parentSelected ? "block" : "none")};
    user-select: none;
    pointer-events: auto;
  `;
}

// now we can render all what we want in the label
export const EditableLabelWidget: React.FunctionComponent<
  FlowAliasLabelWidgetProps
> = (props) => {
  return (
    <S.Label parentSelected={false}>
      <button
        onClick={() => {
          props.model.remove();
        }}
      >
        +
      </button>
      <button
        onClick={() => {
          props.model.getParent().remove();
        }}
      >
        X
      </button>
    </S.Label>
  );
};
