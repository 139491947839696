import { Modal, Select, Space, Typography } from "antd";
import { useState } from "react";
import {
  withAntUtils,
  type InjectedAntUtilsProps,
} from "../../../../../../../../components/ant-utils/withAntUtils";
import { compose } from "../../../../../../../../components/compose/WlyCompose";
import type { IRadar } from "../../../../../../../../interfaces/radar";
import type { ObjectStore } from "../../../../../domain";
import type { UpdateRadarFunction } from "../../../../../selector/radar/domain";

type IRadarObjectModalProps = {
  objectStore: ObjectStore;
  radar: IRadar;
  open: boolean;
  onClose: () => void;
  onUpdateRadar: UpdateRadarFunction;
};

type Props = IRadarObjectModalProps & InjectedAntUtilsProps;

function RadarObjectModal({
  antUtils,
  objectStore,
  radar,
  open,
  onClose,
  onUpdateRadar,
}: Props) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [newObjectId, setNewObjectId] = useState<string | undefined>(
    radar?.object?.id
  );
  const allObjects = Object.keys(objectStore)
    .map((key) => objectStore[key])
    .map((object) => ({
      ...object,
      emailLayouts: Object.keys(object.layouts)
        .map((key) => object.layouts[key])
        .filter((layout) => layout.type === "EMAIL")
        .sort(function (a, b) {
          if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }
          return 0;
        }),
    }))
    .sort((a, b) => {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    });
  return (
    <Modal
      title={"Update radar"}
      open={open}
      onOk={async () => {
        try {
          setIsLoading(true);
          await onUpdateRadar(radar.id, {
            object: {
              id: newObjectId,
            },
          });
          antUtils.message.success("Object successfully updated");
        } catch (error) {
          console.error(error);
          antUtils.message.error("Error while updating object");
        } finally {
          setIsLoading(false);
          onClose();
        }
      }}
      okButtonProps={{
        loading: isLoading,
        disabled:
          isLoading || !newObjectId || newObjectId === radar?.object?.id,
      }}
      onCancel={() => {
        setNewObjectId(radar?.object?.id);
        onClose();
      }}
    >
      <Typography.Text type="secondary">Based on</Typography.Text>
      <Select
        style={{ width: "100%" }}
        showSearch
        optionFilterProp="label"
        value={newObjectId}
        onChange={(v) => {
          setNewObjectId(v);
        }}
      >
        {allObjects.map((v) => {
          return (
            <Select.Option key={v.id} value={v.id} label={v.name}>
              <Space>{v.name}</Space>
            </Select.Option>
          );
        })}
      </Select>
    </Modal>
  );
}

export default compose<Props, IRadarObjectModalProps>(withAntUtils)(
  RadarObjectModal
);
