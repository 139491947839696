import _ from "lodash";
import type { TableQuery } from "../../../../../../../../components/ag-grid/object-table/domain";
import type { IObject } from "../../../../../../../../interfaces/object";
import { getFilterLength } from "../../../toolbar/ObjectToobarFilter";

export const getTotalNumberOfFilters = (
  tableQuery: TableQuery,
  object: IObject
) => {
  const allValues = Object.keys(tableQuery.queryBuilderItems || {});
  const allItemIds = object.queryBuilderSections.flatMap((qbs) =>
    qbs.items.map((o) => o.id)
  );
  const intersection = _.intersection(allItemIds, allValues).length;
  const filterLenght = getFilterLength(
    tableQuery.filters,
    tableQuery.presetFilters ?? []
  );
  const numberOfAppliedFilters = intersection + filterLenght;
  return numberOfAppliedFilters;
};
