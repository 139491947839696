import { Progress, Tag } from "antd";
import type { InjectedOrgProps } from "../../../../containers/orgs/WithOrg";
import WithOrg from "../../../../containers/orgs/WithOrg";
import { customFormatter } from "../../../chart/utils/optionsHelper";
import { compose } from "../../../compose/WlyCompose";

interface ITagCellRendererProps {
  formatterConfig: "VARIANCE" | "PROGRESS" | "INVERTED_VARIANCE";
  value: string;
  className?: string;
  forceMinWidth?: number;
}

type Props = ITagCellRendererProps & InjectedOrgProps;

const ProgressCellRenderer = (props: Props) => {
  const { formatterConfig, value, user, className, forceMinWidth } = props;

  const wrap = (r: React.ReactElement<any, any>) => {
    return forceMinWidth ? (
      <div style={{ width: 250, maxWidth: "30vw" }}>{r}</div>
    ) : (
      r
    );
  };

  try {
    const num = parseFloat(value);
    if (formatterConfig === "PROGRESS") {
      return wrap(
        <div
          style={{
            display: "flex",
            width: "100%",
            height: "100%",
            alignItems: "center",
          }}
          className={className}
        >
          <Progress
            style={{ margin: 0 }}
            size={"small"}
            format={() => `${Math.round(num * 100)}%`}
            percent={Math.round(num * 100)}
          />
        </div>
      );
    } else if (
      formatterConfig === "VARIANCE" ||
      formatterConfig === "INVERTED_VARIANCE"
    ) {
      const renderTag = () => {
        const val = customFormatter(value, user.locale, {
          format: "PERCENT",
        });
        if (num > 0) {
          const color = formatterConfig === "VARIANCE" ? "green" : "red";
          return (
            <Tag color={color} bordered={false}>
              <b>+{val}</b>
            </Tag>
          );
        } else if (num < 0) {
          const color = formatterConfig === "VARIANCE" ? "red" : "green";
          return (
            <Tag color={color} bordered={false}>
              <b>{val}</b>
            </Tag>
          );
        } else {
          return (
            <Tag color="orange" bordered={false}>
              <b>{val}</b>
            </Tag>
          );
        }
      };

      return (
        <div
          style={{
            display: "flex",
            width: "100%",
            height: "100%",
            alignItems: "center",
          }}
          className={className}
        >
          {renderTag()}
        </div>
      );
    } else {
      throw new Error("Unsupported");
    }
  } catch (err) {
    console.error(err);
    return (
      <div
        style={{
          display: "flex",
          width: "100%",
          height: "100%",
          alignItems: "center",
        }}
        className={className}
      >
        #ERR
      </div>
    );
  }
};

export default compose<Props, ITagCellRendererProps>(WithOrg)(
  ProgressCellRenderer
);
