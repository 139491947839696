import type { chartOptionRenderer } from "../../../containers/chart-options/ChartOptions";
import type { IAnalysisType } from "../../../containers/exploration/single/domain";
import { WlyMetricChartIcon } from "../../icons/custom-icons/WlyMetricChartIcon";
import type { SingleChartDefinition } from "../domain";

export const metricChartDefinition: SingleChartDefinition = {
  label: "Metric chart",
  icon: <WlyMetricChartIcon />,
  allowTimeComparison: true,
  pivot: (analysisType: IAnalysisType) => false,
  canDisplay: (rs) => undefined,
  canPredict: () => false,
  config: ["font-size", "inverted-arrow", "show-sparkline", "palette"],
  declarativeChartOptionRenderer: (options) => {
    const template: chartOptionRenderer = [
      {
        label: "Chart options",
        lines: [
          {
            type: "standard",
            component: "font-size",
          },
          !!options.query?.comparison && {
            type: "standard",
            component: "inverted-arrow",
          },
          !!options.query?.selectedTime && {
            type: "standard",
            component: "show-sparkline",
          },
          {
            type: "standard",
            component: "palette",
          },
        ],
      },
    ];
    return template;
  },
  minMetrics: 1,
  maxMetrics: 4,
  minDimensions: 0,
  maxDimensions: 0,
  axis: {
    bottom: {
      editable: false,
      isTimeFormattable: (a) => false,
    },
  },
  extractCustomizationDimensions: (d, q) => {
    return {
      dimensions: [],
    };
  },
  extractCustomizationSeries: (s, d) => {
    return {
      series: s.filter((a) => !a.previous),
      customColor: true,
      customLabel: true,
      customType: [],
      conditionalFormatting: {
        fontColor: true,
        overrideValue: true,
      },
    };
  },
};
