import { inject, observer } from "mobx-react";
import * as React from "react";
import type { RouteComponentProps } from "react-router";
import { withRouter } from "react-router";
import type { InjectedAntUtilsProps } from "../../../components/ant-utils/withAntUtils";
import { withAntUtils } from "../../../components/ant-utils/withAntUtils";
import { compose } from "../../../components/compose/WlyCompose";
import type { ICatalogValue } from "../../../interfaces/catalog";
import type { IDestinationMeta } from "../../../interfaces/destinations";
import { routeDescriptor } from "../../../routes/routes";
import {
  WarehouseUserType,
  checkWarehouseCredentials,
} from "../../../services/BrizoService";
import GraphQLService from "../../../services/graphql/GraphQLService";
import type { UserStoreProps } from "../../../store/userStore";
import type { InjectedOrgProps } from "../../orgs/WithOrg";
import WithOrg from "../../orgs/WithOrg";
import ItemConnectionPage from "../connection/ItemConnectionPage";
import type { ItemValueInput } from "../connection/steps/step1/ItemConnectionStep1";
import type { ItemValueUpdateInput } from "../connection/steps/step2/ItemConnectionStep2";
import { createDestinationAndRedirect, destinationStep2 } from "../domain";

interface IWarehouseConnectionPageProps {}

type Props = IWarehouseConnectionPageProps &
  RouteComponentProps<{ organizationSlug: string; connectionType?: string }> &
  UserStoreProps &
  InjectedOrgProps &
  InjectedAntUtilsProps;

class WarehouseConnectionPage extends React.Component<Props> {
  public render() {
    const {
      userStore,
      match: { params },
    } = this.props;
    return (
      <ItemConnectionPage
        submitButtonLabel="Test & Save"
        redirectUri={routeDescriptor.settingsWarehouse.renderRoute({
          ...params,
        })}
        allItemsMetaQuery={`
        query getDestinationMeta(
          $slug: String!
        ) {
          allItemMetas: allDestinationMetas(where: {deleted: false, slug: $slug}) {
            id
            slug
            name
            auth
            warehouseType
            defaultTargetDatabase
            publicInfo {
              id
              name
              slug
              label
              tagline
              website
              logo
              description
              documentationPagePath
              secondaryDocumentationPagePath
            }
            options {
              id
              slug
              key
              type
              options
              label
              editable
              defaultValue
              encrypt
              hidden
            }
          }
        }
        `}
        remainingItemValuesQuery={`
        query getRemainingWarehouseValues(
          $itemId: ID!
        ) {
            allItemValue: allDestinationValues(where:{destination: {id: $itemId}, option: { hidden_not: true}}) {
              id
              value
              option {
                id
                slug
                key
                type
                options
                label
                editable
                description
                defaultValue
                order
                credentialType
                encrypt
                hidden
              }
            }
        }
        `}
        getDocumentPagePath={(i) => {
          const { connectionType } = params;
          if (
            connectionType &&
            connectionType === WarehouseUserType.DATA_LOADING
          ) {
            return i.publicInfo.secondaryDocumentationPagePath;
          }
          return i.publicInfo.documentationPagePath;
        }}
        catalogValueTransformer={(catalog) => {
          const { connectionType } = params;
          if (!connectionType || connectionType === WarehouseUserType.BI) {
            return catalog.filter(
              (c) =>
                c.option.credentialType.toLowerCase() === WarehouseUserType.BI
            );
          }
          return catalog.map((c) => {
            if (
              !c.option.credentialType ||
              c.option.credentialType.toLowerCase() === WarehouseUserType.BI
            ) {
              return {
                ...c,
                option: {
                  ...c.option,
                  editable: false,
                },
              } as ICatalogValue;
            }
            return c;
          });
        }}
        step1CreationPromise={(
          name: string,
          orgId: string,
          itemMeta: IDestinationMeta,
          inputs: ItemValueInput[]
        ) =>
          createDestinationAndRedirect(name, orgId, itemMeta, inputs, (s) => {
            const {
              match: { params },
              history,
            } = this.props;
            history.push(
              routeDescriptor.warehouseConnectionStep2.renderRoute({
                ...params,
                itemId: s.id,
              })
            );
            return userStore.getUser();
          })
        }
        inputValidationPromise={async (itemId, v) => {
          const warehouseTypeRes = await GraphQLService<{
            Destination: {
              destinationMeta: {
                warehouseType: string;
              };
            };
          }>(
            `query GetWarehouseDbType($destinationId: ID!) {
              Destination(where: {id: $destinationId}) {
                destinationMeta {
                  warehouseType
                }
              }
            }`,
            {
              destinationId: itemId,
            }
          );

          const warehouseType =
            warehouseTypeRes?.Destination?.destinationMeta?.warehouseType;

          const checkResult = await checkWarehouseCredentials(
            this.props.org.id,
            {
              warehouse_type: warehouseType,
              ...(v as any),
            }
          );
          if (checkResult.status === "error") {
            throw new Error(checkResult.error);
          }
        }}
        step2CreationPromise={(
          itemId: string,
          itemValueUpdateInput: ItemValueUpdateInput[]
        ) =>
          destinationStep2(
            itemId,
            itemValueUpdateInput,
            params.connectionType === WarehouseUserType.DATA_LOADING
          ).then((slug) => {
            const {
              history,
              match: { params },
            } = this.props;
            return userStore.getUser().then(() => {
              history.push(
                routeDescriptor.home.renderRoute({
                  organizationSlug: params.organizationSlug,
                }),
                {
                  showNewWarehouseModal: true,
                }
              );
            });
          })
        }
      />
    );
  }
}

export default compose<Props, IWarehouseConnectionPageProps>(
  withRouter,
  WithOrg,
  withAntUtils
)(inject("userStore")(observer(WarehouseConnectionPage)));
