import cuid from "cuid";
import { GoogleCharts } from "google-charts";
import _ from "lodash";
import * as React from "react";
import type { ProcessingStep } from "../../exploration/single/performance/domain";

interface ISimplifiedTraceChartProps {
  data: Array<ISimplifiedTraceChartStep>;
  width?: number;
}

export interface ISimplifiedTraceChartStep {
  name: string;
  simplifiedTrace: ProcessingStep[];
}

export default class SimplifiedTraceChart extends React.Component<ISimplifiedTraceChartProps> {
  ref?: Highcharts.Chart;
  id = cuid();

  componentDidUpdate(prevProps: ISimplifiedTraceChartProps) {
    if (prevProps.width !== this.props.width && this.ref && this.ref.reflow) {
      this.ref.reflow();
    }
  }

  shouldComponentUpdate(
    nextProps: Readonly<ISimplifiedTraceChartProps>,
    nextState: Readonly<{}>,
    nextContext: any
  ): boolean {
    if (!_.isEqual(this.props.width, nextProps.width)) return true;
    if (!_.isEqual(this.props.data, nextProps.data)) return true;
    return false;
  }

  public render() {
    const { data, width } = this.props;

    const drawChart = () => {
      const dataTable = new GoogleCharts.api.visualization.DataTable();
      dataTable.addColumn({ type: "string", id: "Chart" });
      dataTable.addColumn({ type: "string", id: "Step" });
      dataTable.addColumn({ type: "date", id: "Start" });
      dataTable.addColumn({ type: "date", id: "End" });

      const timeline = new GoogleCharts.api.visualization.Timeline(
        document.getElementById(this.id)
      );

      const rows = [];

      data.forEach((d) => {
        d.simplifiedTrace.forEach((a) => {
          rows.push([
            d.name,
            a.name,
            new Date(a.start_date),
            new Date(a.end_date),
          ]);
        });
      });

      dataTable.addRows(rows);

      const options = {
        timeline: { groupByRowLabel: true },
      };

      timeline.draw(dataTable, options);
    };

    GoogleCharts.load(drawChart, { packages: ["timeline"] });

    return (
      <div
        id={this.id}
        style={{ height: 500, width: width ? width : "100%" }}
      />
    );
  }
}
