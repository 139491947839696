import { inject, observer } from "mobx-react";
import * as React from "react";
import type { RouteComponentProps } from "react-router";
import { withRouter } from "react-router";
import { compose } from "../../../components/compose/WlyCompose";
import Aligner from "../../../components/layout/aligner/Aligner";
import Loading from "../../../components/layout/feedback/loading";
import type { AsyncData } from "../../../helpers/typescriptHelpers";
import type { AuthStoreProps } from "../../../store/authStore";
import type { UserStoreProps } from "../../../store/userStore";

import {
  parseSearch,
  TOKEN_SEARCH_SETTINGS,
} from "../../../helpers/queryStringHelpers";
import GraphQLService from "../../../services/graphql/GraphQLService";
import ReportPublicView from "../public/ReportPublicView";
import { GET_EMBEDDED_CONFIG } from "../view/domain";

interface IReportEmbedPageProps {}

interface IState {
  filters: AsyncData<{ [key: string]: boolean | string | number }>;
  disableDrills: boolean;
}

type Props = IReportEmbedPageProps &
  RouteComponentProps<{ organizationSlug: string; embedToken: string }> &
  UserStoreProps &
  AuthStoreProps;

class ReportEmbedPage extends React.Component<Props, IState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      filters: {
        status: "initial",
      },
      disableDrills: false,
    };
  }

  componentDidMount() {
    const {
      location: { search },
    } = this.props;
    const s = parseSearch<{ token: string }>(search, TOKEN_SEARCH_SETTINGS);
    this.fetchPageStatus(s.token);
  }

  componentDidUpdate(prevProps: Props) {
    const {
      location: { search },
    } = this.props;
    const {
      location: { search: prevSearch },
    } = prevProps;
    const s = parseSearch<{ token: string }>(search, TOKEN_SEARCH_SETTINGS);
    const ps = parseSearch<{ token: string }>(
      prevSearch,
      TOKEN_SEARCH_SETTINGS
    );
    if (ps.token !== s.token) {
      this.fetchPageStatus(s.token);
    }
  }

  fetchPageStatus = (embedToken: string) => {
    this.setState({ filters: { status: "initial" } });

    // read if the password is stored in the local storage
    return Promise.resolve(
      this.props.authStore.saveToken({
        access_token: `embed:${this.props.match.params.organizationSlug}:${embedToken}`,
        expires_at: Date.now() + 10000 * 365 * 24 * 3600 * 1000,
        expires_in: 10000 * 365 * 24 * 3600 * 1000,
        refresh_token: "",
        scope: "",
        token_type: "bearer",
      })
    )
      .then(() => this.props.userStore.getUser())
      .then(() =>
        GraphQLService(GET_EMBEDDED_CONFIG, {
          embedToken: embedToken,
        })
      )
      .then((r) => {
        this.setState({
          filters: {
            status: "success",
            data: r.getEmbedConfig ? r.getEmbedConfig.filters : null,
          },
          disableDrills:
            r.getEmbedConfig &&
            (r.getEmbedConfig.disableDrills || r.getEmbedConfig.disable_drills),
        });
      })
      .catch((err) => {
        console.error(err);
        this.setState({
          filters: {
            status: "error",
            error: err,
          },
        });
      });
  };

  public renderContent = () => {
    const { filters } = this.state;

    switch (filters.status) {
      case "initial":
        return (
          <Aligner className="standard-bg">
            <Loading />
          </Aligner>
        );
      case "error":
        return (
          <Aligner className="standard-bg">
            This report either doesn't exists or is not public anymore...
          </Aligner>
        );
      case "loading":
      case "success":
        return (
          <ReportPublicView
            isEmbedded={true}
            isBeingPushed={false}
            hideLayout={true}
            showTitle={false}
            hideFilters={false}
            fetchOnlyPublicReport={false}
            defaultFilters={filters.status === "success" ? filters.data : {}}
            disableDrills={this.state.disableDrills}
          />
        );
    }
  };

  public render() {
    return this.renderContent();
  }
}

export default compose<Props, IReportEmbedPageProps>(withRouter)(
  inject("userStore", "authStore")(observer(ReportEmbedPage))
);
