import { ArrowDownOutlined } from "@ant-design/icons";
import type { InputRef } from "antd";
import { Button, Input, Popover, Space, Typography } from "antd";
import * as React from "react";
import { SourceImageRenderer } from "../../../../../components/sources/SourceImageRenderer";
import type { IDataset } from "../../../../../interfaces/sources";

interface IMigrationModalStep1Props {
  datasets: IDataset[];
  dataset: IDataset;
  destinationDataset?: IDataset;
  setDestinationDataset: (d: IDataset) => void;
}

export function MigrationModalStep1(props: IMigrationModalStep1Props) {
  const { datasets, dataset, destinationDataset, setDestinationDataset } =
    props;

  const [search, setSearch] = React.useState<string>("");
  const [popoverVisible, setPopOverVisible] = React.useState<boolean>(false);
  const inputRef = React.useRef<InputRef>();

  React.useEffect(() => {
    if (popoverVisible) {
      setSearch("");
      inputRef?.current?.focus();
    }
  }, [popoverVisible]);

  const renderDataset = (d: IDataset, showAction?: boolean) => {
    return (
      <div className="migration-modal-dataset">
        <div className="migration-modal-dataset-image">
          {d.isModel ? (
            <SourceImageRenderer alt={d.name} isModel size={36} />
          ) : (
            <SourceImageRenderer
              alt={d.name}
              size={36}
              img={d.source.sourceMeta.publicInfo.logo}
            />
          )}
        </div>
        <div className="migration-modal-dataset-content">
          <div>
            <Typography.Text strong>{d.name}</Typography.Text>
          </div>
          <div>
            <Typography.Text type="secondary">
              {d.isModel ? "Model" : `Source - ${d.source.name}`}
            </Typography.Text>
          </div>
        </div>
        {showAction && (
          <div className="migration-modal-dataset-actions">
            <Button
              danger
              type="text"
              onClick={() => setDestinationDataset(undefined)}
            >
              Remove
            </Button>
          </div>
        )}
      </div>
    );
  };

  const renderPopover = () => {
    return (
      <div>
        <div>
          <Input
            ref={inputRef}
            value={search}
            placeholder={"Search..."}
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
        <div
          className="migration-modal-popover"
          style={{
            width: 200,
            height: 250,
            overflowY: "scroll",
            overflowX: "hidden",
          }}
        >
          <div className="migration-modal-popover-inner">
            {datasets
              .filter((d) => d.id !== dataset.id)
              .filter((d) =>
                search ? d.name.includes(search.toLowerCase()) : true
              )
              .map((d, i) => {
                return (
                  <div
                    key={i}
                    onClick={() => {
                      setDestinationDataset(d);
                      setPopOverVisible(false);
                    }}
                    className="migration-modal-popover-item"
                  >
                    {d.isModel ? (
                      <SourceImageRenderer alt={d.name} size={16} isModel />
                    ) : (
                      <SourceImageRenderer
                        size={16}
                        alt={d.name}
                        img={d.source.sourceMeta.publicInfo.logo}
                      />
                    )}
                    <span style={{ paddingLeft: 4 }}>{d.name}</span>
                    {!d.isModel ? (
                      <Typography.Text type="secondary">
                        {" "}
                        - {d.source.name}
                      </Typography.Text>
                    ) : null}
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    );
  };

  const renderSelectDestination = () => {
    if (!destinationDataset) {
      return (
        <div style={{ textAlign: "center" }}>
          <Popover
            destroyTooltipOnHide={true}
            open={popoverVisible}
            onOpenChange={setPopOverVisible}
            trigger={["click"]}
            content={renderPopover()}
          >
            <Button onClick={() => setPopOverVisible(true)}>
              Select a dataset
            </Button>
          </Popover>
        </div>
      );
    }
    return renderDataset(destinationDataset, true);
  };

  return (
    <Space direction="vertical" style={{ width: "100%" }}>
      <div className="migration-modal-dataset-intro">
        A migration will copy all relationships and drills associated to the new
        dataset. This will also migrate all explorations and models using the
        previous dataset into using the new one. Finally it will delete the
        previous model.
      </div>
      {renderDataset(dataset)}
      <div className="migration-modal-arrow">
        <ArrowDownOutlined />
      </div>
      {renderSelectDestination()}
    </Space>
  );
}
