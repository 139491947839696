import { LockFilled, LockOutlined } from "@ant-design/icons";
import {
  Alert,
  Button,
  Col,
  Form,
  Row,
  Select,
  Slider,
  Space,
  Switch,
  Typography,
} from "antd";
import { useForm } from "antd/lib/form/Form";
import * as React from "react";
import type { InjectedAntUtilsProps } from "../../../../../../components/ant-utils/withAntUtils";
import { withAntUtils } from "../../../../../../components/ant-utils/withAntUtils";
import { WlyCard } from "../../../../../../components/cards/WlyCard";
import { compose } from "../../../../../../components/compose/WlyCompose";
import Aligner from "../../../../../../components/layout/aligner/Aligner";
import Feednack from "../../../../../../components/layout/feedback/feedback";
import type { AsyncData } from "../../../../../../helpers/typescriptHelpers";
import type { IDestination } from "../../../../../../interfaces/destinations";
import { IOrgFeatureType } from "../../../../../../interfaces/org";
import type {
  DatabaseInfo,
  SchemaInfo,
} from "../../../../../../services/BrizoService";
import {
  WarehouseUserType,
  getDatabases,
  getSchemas,
} from "../../../../../../services/BrizoService";
import type { InjectedOrgProps } from "../../../../../orgs/WithOrg";
import WithOrg from "../../../../../orgs/WithOrg";
import type { FetchedDestination } from "../../../domain";
import type { UpdateDestination } from "./domain";

const { Title } = Typography;

interface ISignalEngineFormPageProps {
  destination?: FetchedDestination;
  onDestinationUpdate?: UpdateDestination;
  currentWarehouse: IDestination;
}

type Props = ISignalEngineFormPageProps &
  InjectedOrgProps &
  InjectedAntUtilsProps;

interface FormValues {
  destination: {
    activate: boolean;
    signalEngineDefaultTargetDatabase?: string;
    signalEngineDefaultTargetSchema?: string;
  };
}

function SignalEngineFormPage(props: Props) {
  const {
    antUtils,
    currentWarehouse,
    destination,
    onDestinationUpdate,
    orgFeatures,
    org,
  } = props;

  const orgHasAccess =
    orgFeatures.includes(IOrgFeatureType.PERSISTENCE_ENGINE_FEATURE_API_NAME) &&
    currentWarehouse;

  const hasPersistCapability =
    !currentWarehouse?.destinationMeta?.isWhalyManaged &&
    !!currentWarehouse.destinationMeta.targetName;
  const isPersistencePossible = currentWarehouse.isDataLoadingEnabled;

  const [submitting, setSubmitting] = React.useState<boolean>(false);
  const [availableDatabases, setAvailableDatabases] = React.useState<
    AsyncData<Array<DatabaseInfo>>
  >({ status: "initial" });
  const [availableSchemas, setAvailableSchemas] = React.useState<
    AsyncData<Array<SchemaInfo>>
  >({ status: "initial" });
  const [selectedDatabaseName, setSelectedDatabaseName] =
    React.useState<string>();

  React.useEffect(() => {
    setAvailableDatabases({ status: "loading" });
    getDatabases(currentWarehouse.id, WarehouseUserType.DATA_LOADING)
      .then((r) => {
        setAvailableDatabases({ status: "success", data: r.data });
      })
      .catch((err) => {
        setAvailableDatabases({ status: "error", error: err });
      });
  }, [currentWarehouse?.id]);

  React.useEffect(() => {
    if (
      selectedDatabaseName ||
      destination?.signalEngineDefaultTargetDatabase
    ) {
      setAvailableSchemas({ status: "loading" });
      getSchemas(
        currentWarehouse.id,
        selectedDatabaseName || destination?.signalEngineDefaultTargetDatabase,
        WarehouseUserType.DATA_LOADING
      )
        .then((r) => {
          setAvailableSchemas({ status: "success", data: r.data });
        })
        .catch((err) => {
          setAvailableSchemas({ status: "error", error: err });
        });
    }
  }, [currentWarehouse?.id, selectedDatabaseName]);

  const [form] = useForm<FormValues>();

  const renderInner = () => {
    if (!currentWarehouse) {
      return <Feednack>Please connect a warehouse</Feednack>;
    }

    if (!destination) {
      return <Feednack>You should have a warehouse connected...</Feednack>;
    }

    const initialData: FormValues = {
      destination: {
        activate: destination?.isSignalEngineEnabled,
        signalEngineDefaultTargetDatabase:
          destination?.signalEngineDefaultTargetDatabase,
        signalEngineDefaultTargetSchema:
          destination?.signalEngineDefaultTargetSchema,
      },
    };

    const onSave = async (values: FormValues) => {
      setSubmitting(true);
      try {
        if (!onDestinationUpdate) {
          throw new Error("Can't perform this operation");
        }
        await form.validateFields();
        await onDestinationUpdate(currentWarehouse.id, {
          isSignalEngineEnabled: values.destination.activate,
          signalEngineDefaultTargetDatabase:
            values.destination.signalEngineDefaultTargetDatabase,
          signalEngineDefaultTargetSchema:
            values.destination.signalEngineDefaultTargetSchema,
        });
        antUtils.message.success("Successfully updated modeling settings", 2);
      } catch (err) {
        console.error(err);
        antUtils.message.error("there was an error saving settings...", 2);
      } finally {
        setSubmitting(false);
      }
    };

    return (
      <Form
        layout="vertical"
        form={form}
        disabled={!orgHasAccess}
        initialValues={initialData}
        onFinish={onSave}
        onValuesChange={(changedValues) => {
       
          if (
            changedValues["destination"]?.[
              "signalEngineDefaultTargetDatabase"
            ]
          ) {
            const newDatabase = form.getFieldValue([
              "destination",
              "signalEngineDefaultTargetDatabase",
            ]);
            setSelectedDatabaseName(newDatabase);
            form.setFieldValue(
              ["destination", "signalEngineDefaultTargetSchema"],
              null
            );
          }
        }}
      >
        <Space direction="vertical" size={32} style={{ width: "100%" }}>
          <div style={{ paddingTop: 16, display: "flex" }}>
            <div style={{ flex: 1 }}>
              <Title style={{ marginBottom: 0 }} level={3}>
                {orgHasAccess ? (
                  "Signal Engine"
                ) : (
                  <>
                    <LockOutlined /> Signal Engine
                  </>
                )}
              </Title>
            </div>
            <div>
              <Button
                type="primary"
                htmlType="submit"
                disabled={
                  submitting ||
                  !onDestinationUpdate ||
                  !orgHasAccess ||
                  !isPersistencePossible ||
                  !hasPersistCapability
                }
                loading={submitting}
              >
                Save
              </Button>
            </div>
          </div>
          <div>
            <Typography.Text>
              Signal engine allow you to collect & historize data from models
              periodically or from user actions. This is usefull to get feedback
              on your data or trigger notification from anomalies, in order to
              use it you need to activate data loading capabilities to your
              warehouse.
            </Typography.Text>
          </div>
          {!orgHasAccess ? (
            <Alert
              message={
                <div style={{ display: "flex" }}>
                  <div>Your plan doesn't have access to this feature yet.</div>
                </div>
              }
              type="warning"
            />
          ) : undefined}
          {orgHasAccess && !hasPersistCapability ? (
            <Alert
              message={
                <div style={{ display: "flex" }}>
                  <div>
                    Data loading capability is not available for your warehouse.
                  </div>
                </div>
              }
              type="warning"
            />
          ) : undefined}
          {orgHasAccess && hasPersistCapability && !isPersistencePossible ? (
            <Alert
              message={
                <div style={{ display: "flex" }}>
                  <div>
                    Please add the data loader credentials in your warehouse to
                    activate persistence
                  </div>
                </div>
              }
              type="warning"
            />
          ) : undefined}
          <WlyCard title="Configuration">
            <Form.Item
              name={["destination", "activate"]}
              label={"Activate Signal Engine"}
              valuePropName={"checked"}
            >
              <Switch
                disabled={
                  !orgHasAccess ||
                  !isPersistencePossible ||
                  !hasPersistCapability
                }
                checkedChildren={
                  !orgHasAccess ||
                  !isPersistencePossible ||
                  !hasPersistCapability ? (
                    <LockFilled />
                  ) : undefined
                }
                unCheckedChildren={
                  !orgHasAccess ||
                  !isPersistencePossible ||
                  !hasPersistCapability ? (
                    <LockFilled />
                  ) : undefined
                }
              />
            </Form.Item>
            <Form.Item noStyle shouldUpdate={true}>
              {() => {
                if (form.getFieldValue(["destination", "activate"])) {
                  return (
                    <>
                      <Form.Item
                        name={[
                          "destination",
                          "signalEngineDefaultTargetDatabase",
                        ]}
                        label={"Default database to persist to"}
                        help={
                          "This database will be the one used by default to persist the Signals."
                        }
                        style={{
                          paddingBottom: 24,
                        }}
                      >
                        <Select
                          disabled={
                            availableDatabases.status !== "success" ||
                            !orgHasAccess ||
                            !isPersistencePossible ||
                            !hasPersistCapability
                          }
                          loading={availableDatabases.status === "loading"}
                        >
                          {availableDatabases.status === "success" &&
                            availableDatabases.data.map((db, i) => {
                              return (
                                <Select.Option value={db.databaseName} key={i}>
                                  {db.databaseName}
                                </Select.Option>
                              );
                            })}
                        </Select>
                      </Form.Item>
                      <Form.Item
                        name={[
                          "destination",
                          "signalEngineDefaultTargetSchema",
                        ]}
                        label={"Default schema to persist to"}
                        help={
                          "This schema will be the one used by default to persist the Signals."
                        }
                        style={{
                          paddingBottom: 24,
                        }}
                      >
                        <Select
                          disabled={
                            !form.getFieldValue([
                              "destination",
                              "signalEngineDefaultTargetDatabase",
                            ]) ||
                            availableSchemas.status !== "success" ||
                            !orgHasAccess ||
                            !isPersistencePossible ||
                            !hasPersistCapability
                          }
                          loading={availableSchemas.status === "loading"}
                        >
                          {availableSchemas.status === "success" &&
                            availableSchemas.data.map((db, i) => {
                              return (
                                <Select.Option value={db.schemaName} key={i}>
                                  {db.schemaName}
                                </Select.Option>
                              );
                            })}
                        </Select>
                      </Form.Item>
                    </>
                  );
                }
                return;
              }}
            </Form.Item>
          </WlyCard>
        </Space>
      </Form>
    );
  };

  return (
    <Aligner>
      <Row justify="center" className="access-management">
        <Col xs={24} sm={24} md={20} lg={20} xl={20}>
          {renderInner()}
        </Col>
      </Row>
    </Aligner>
  );
}

export default compose<Props, ISignalEngineFormPageProps>(
  WithOrg,
  withAntUtils
)(SignalEngineFormPage);
