import * as React from "react";
import { DragDropContext, Droppable } from "react-beautiful-dnd";

export interface IDNDWrapperProps<T> {
  droppableId: string;
  droppableType: string;
  onReorder: (items: T[], from: number, to: number) => void;
  items: T[];
}

export function reorder<T>(list: T[], startIndex: number, endIndex: number) {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
}

export function DNDWrapper<T>(
  props: React.PropsWithChildren<IDNDWrapperProps<T>>
) {
  const { children, droppableId, droppableType, items, onReorder } = props;
  return (
    <DragDropContext
      onDragEnd={(result) => {
        if (!result.destination) {
          return;
        }

        const newItems = reorder(
          [...items],
          result.source.index,
          result.destination.index
        );

        onReorder(newItems, result.source.index, result.destination.index);
      }}
    >
      <Droppable droppableId={droppableId} type={droppableType}>
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            {...provided.droppableProps}
            style={
              snapshot.isDraggingOver
                ? {
                    background: "#F2F4F5",
                    outline: "6px solid #F2F4F5",
                    borderRadius: 6,
                  }
                : {}
            }
          >
            {children}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
}
