import { Form, Input, Modal } from "antd";
import * as React from "react";
import "react-quill/dist/quill.snow.css";
import { compose } from "../../../../../components/compose/WlyCompose";
import type { InjectedOrgProps } from "../../../../orgs/WithOrg";
import WithOrg from "../../../../orgs/WithOrg";

import "react-quill/dist/quill.snow.css";

interface INavigationEditionProps {
  initialData?: INavigationInitialData;
  onSave: (data: INavigationInitialData) => Promise<any>;
  onCancel: () => void;
}

interface INavigationInitialData {
  id?: string;
  name: string;
}

type Props = INavigationEditionProps & InjectedOrgProps;

const NavigationEdition: React.FunctionComponent<Props> = (props) => {
  const { onSave, org, onCancel, initialData } = props;

  const [confirmLoading, setConfirmLoading] = React.useState(false);
  const [form] = Form.useForm();

  const renderInnerModal = () => {
    return (
      <Form
        initialValues={{
          name: initialData ? initialData.name : "",
        }}
        layout="vertical"
        form={form}
      >
        <Form.Item name={["name"]} label="Name">
          <Input />
        </Form.Item>
      </Form>
    );
  };

  return (
    <Modal
      open={true}
      title={initialData ? "Update navigation" : "Create navigation"}
      okText={initialData ? "Update" : "Create"}
      destroyOnClose={true}
      cancelText="Cancel"
      onCancel={onCancel}
      confirmLoading={confirmLoading}
      onOk={() => {
        setConfirmLoading(true);
        return form
          .validateFields()
          .then((values) => {
            const v = values as INavigationInitialData;
            return onSave({ ...initialData, ...v }).then(() =>
              setConfirmLoading(false)
            );
          })
          .catch((info) => {
            setConfirmLoading(false);
            console.error("Validate Failed:", info);
          });
      }}
    >
      {renderInnerModal()}
    </Modal>
  );
};

export default compose<Props, INavigationEditionProps>(WithOrg)(
  NavigationEdition
);
