import { Button, Divider, Space } from "antd";
import type { RouteComponentProps } from "react-router";
import { withRouter } from "react-router";
import { compose } from "../../../../../components/compose/WlyCompose";
import { routeDescriptor } from "../../../../../routes/routes";
import GraphQl from "../../../../graphql/graphql";
import type { InjectedOrgProps } from "../../../../orgs/WithOrg";
import WithOrg from "../../../../orgs/WithOrg";
import { BORDER_COLOR, COLOR_DOWN, COLOR_UP } from "../../layout/domain";
import { ERROR_GQL } from "../../modals/status/domain";

interface IHomeHeaderProps {}

type Props = IHomeHeaderProps & RouteComponentProps & InjectedOrgProps;

function HomeHeader(props: Props) {
  const {
    org,
    history,
    match: { params },
  } = props;

  return (
    <div
      style={{
        backgroundColor: "#F9F8F8",
        border: `1px solid ${BORDER_COLOR}`,
        borderRadius: 24,
        height: 36,
        display: "flex",
        alignItems: "center",
        padding: "0 24px",
      }}
    >
      <GraphQl<{
        allSources: Array<{ id: string }>;
        allDestinations: Array<{ id: string }>;
        allExplorations: Array<{ id: string }>;
      }>
        query={ERROR_GQL}
        variables={{ orgId: org.id }}
      >
        {(data) => {
          const hasError =
            data.status === "success" &&
            (data.data.allDestinations.length > 0 ||
              data.data.allSources.length > 0 ||
              data.data.allExplorations.length > 0);

          return (
            <>
              <Space
                split={
                  <Divider
                    style={{ marginLeft: 0, marginRight: 0 }}
                    type="vertical"
                  />
                }
              >
                <Button
                  icon={
                    <div
                      style={{
                        color: hasError ? COLOR_DOWN : COLOR_UP,
                        display: data.status !== "success" ? "none" : undefined,
                      }}
                    >
                      &#8226;
                    </div>
                  }
                  onClick={() =>
                    history.push(
                      routeDescriptor["org-status"].renderRoute({
                        ...params,
                      })
                    )
                  }
                  size="small"
                  type="text"
                >
                  Status page
                </Button>
              </Space>
            </>
          );
        }}
      </GraphQl>
    </div>
  );
}

export default compose<Props, IHomeHeaderProps>(
  WithOrg,
  withRouter
)(HomeHeader);
