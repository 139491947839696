import { FileMarkdownOutlined } from "@ant-design/icons";
import { Button, Form, Input, Modal, Select, Typography } from "antd";
import { useForm } from "antd/es/form/Form";
import cuid from "cuid";
import humanizeString from "humanize-string";
import { mode } from "mathjs";
import React, { useEffect } from "react";
import type { RouteComponentProps } from "react-router";
import { withRouter } from "react-router";
import type { InjectedAntUtilsProps } from "../../../../../../components/ant-utils/withAntUtils";
import { withAntUtils } from "../../../../../../components/ant-utils/withAntUtils";
import { compose } from "../../../../../../components/compose/WlyCompose";
import ColorIconPicker from "../../../../../../components/icons/color-icon-picker/ColorIconPicker";
import { SourceImageRenderer } from "../../../../../../components/sources/SourceImageRenderer";
import type { IDataset } from "../../../../../../interfaces/sources";
import type { SchemaResult } from "../../../../../../interfaces/transformations";
import { computeTransformations } from "../../../../../../services/BrizoService";
import GraphQLService from "../../../../../../services/graphql/GraphQLService";
import type { InjectedOrgProps } from "../../../../../orgs/WithOrg";
import WithOrg, { getCurrentWarehouse } from "../../../../../orgs/WithOrg";
import SchemaSelectionStep from "../../../../../spreadsheet/steps/RelatedData/SchemaSelectionStep";
import type {
  IDimensionFormInput,
  IObjectPropertyFormInput,
} from "../../../exploration/domain";
import type { CreateObjectFunction } from "../../../selector/object/domain";
import "./CreateObjectModal.scss";

interface ICreateObjectModalProps {
  open: boolean;
  datasets: IDataset[];
  onClose: () => void;
  onCreate: CreateObjectFunction;
}

interface CreateObjectFormValue {
  name: string;
  modelId: string;
  colorIcon: {
    icon: string;
    color: string;
  };
}

type Props = ICreateObjectModalProps &
  InjectedAntUtilsProps &
  RouteComponentProps<{ warehouseSlug?: string }> &
  InjectedOrgProps;

type Step = IModelSelection | ITableGenerationModeSelect;

interface IModelSelection {
  type: "MODEL_SELECTION";
}

interface ITableGenerationModeSelect {
  type: "TABLE_GENERATION";
  data: {
    name: string;
    icon: string;
    color: string;
    modelId: string;
    viewId: string;
  };
}

const { OptGroup, Option } = Select;

function CreateObjectModal(props: Props) {
  const { org, open, onClose, onCreate, antUtils, match, datasets } = props;

  const [form] = useForm<CreateObjectFormValue>();

  const [loading, setLoading] = React.useState<boolean>(false);
  const [step, setStep] = React.useState<Step>({ type: "MODEL_SELECTION" });

  useEffect(() => {
    if (open) {
      form.setFieldValue("name", "");
      form.setFieldValue("modelId", "");
      form.setFieldValue("colorIcon", {
        icon: "LayoutOutlined",
        color: "#F1BD6C",
      });
    }
  }, [open]);

  const fetchSchemaForWarehouse =
    (warehouseId: string) => (datasetId: string, viewId: string) => {
      const id = cuid();
      return computeTransformations(warehouseId, {
        tableSchema: [
          {
            var: id,
            domain: "viewResolver",
            operation: {
              type: "Table.FromWhalyDataset",
              args: {
                datasetId: datasetId,
                viewId,
              },
            },
          },
          {
            var: cuid(),
            domain: "dataset",
            operation: {
              type: "Table.Schema",
              args: {
                table: id,
              },
            },
          },
        ],
      }).then((r) => {
        return [r.data.tableSchema] as SchemaResult[];
      });
    };

  const getViewIdFromModel = async (modelId: string): Promise<string> => {
    const GQL = `
      query GetModelViews($modelId:ID!) {
        Dataset(where: {id: $modelId}) {
          views {
            id
          }
        }
      }
      `;
    interface ModelWithViewsResult {
      Dataset?: {
        views?: {
          id: string;
        }[];
      };
    }
    const result = await GraphQLService<ModelWithViewsResult>(GQL, {
      modelId,
    });

    if (!result.Dataset?.views || result.Dataset?.views?.length === 0) {
      throw new Error(`no view found attached to modelId: ${mode}`);
    }

    return result.Dataset?.views?.[0].id;
  };

  const renderInner = (): React.ReactNode => {
    if (step.type === "MODEL_SELECTION") {
      return (
        <>
          <div className="content">
            <Form
              form={form}
              layout="vertical"
              onFinish={async (v) => {
                try {
                  setLoading(true);
                  await form.validateFields();
                  const viewId = await getViewIdFromModel(v.modelId);
                  setStep({
                    type: "TABLE_GENERATION",
                    data: {
                      name: v.name,
                      icon: v.colorIcon.icon,
                      color: v.colorIcon.color,
                      modelId: v.modelId,
                      viewId: viewId,
                    },
                  });
                } catch (err) {
                  console.error(err);
                  antUtils.message.error(
                    "An unexpected error happened, please retry"
                  );
                } finally {
                  setLoading(false);
                }
              }}
            >
              <Typography.Text>
                <Typography.Text type="danger">*</Typography.Text> Name
              </Typography.Text>
              <div style={{ display: "flex", paddingTop: 12 }}>
                <div style={{ width: 40 }}>
                  <Form.Item
                    required
                    rules={[{ required: true }]}
                    label={null}
                    name="colorIcon"
                  >
                    <ColorIconPicker />
                  </Form.Item>
                </div>
                <div style={{ flex: 1 }}>
                  <Form.Item
                    required
                    rules={[{ required: true }]}
                    label={null}
                    name="name"
                  >
                    <Input />
                  </Form.Item>
                </div>
              </div>
              <Form.Item
                required
                rules={[{ required: true }]}
                label={"Model"}
                name="modelId"
              >
                <Select
                  style={{ width: "100%" }}
                  placeholder={"Select an option in the list"}
                  showSearch
                  optionFilterProp="label"
                >
                  <OptGroup label="Start from model">
                    {[...datasets]
                      .filter((d) => !!d.isModel)
                      .sort((a, b) => {
                        if (a.folder?.name !== b.folder?.name) {
                          return a.folder?.name > b.folder?.name ? 1 : -1;
                        } else {
                          return a.name > b.name ? 1 : -1;
                        }
                      })
                      .map((dataset) => (
                        <Option
                          value={dataset.id}
                          label={dataset.name}
                          key={dataset.id}
                        >
                          <div>
                            <span
                              role="img"
                              style={{
                                marginRight: 5,
                              }}
                            >
                              <FileMarkdownOutlined />
                            </span>
                            <Typography.Text>
                              {dataset.name}
                              <Typography.Text type="secondary">
                                {" "}
                                {dataset.folder?.name}
                              </Typography.Text>
                            </Typography.Text>
                          </div>
                        </Option>
                      ))}
                  </OptGroup>
                  <OptGroup label="Start from source">
                    {[...datasets]
                      .filter((d) => !d.isModel)
                      .sort((a, b) => {
                        if (a.source?.name !== b.source?.name) {
                          return a.source?.name > b.source?.name ? 1 : -1;
                        } else {
                          return a.name > b.name ? 1 : -1;
                        }
                      })
                      .map((dataset) => (
                        <Option
                          value={dataset.id}
                          label={dataset.name}
                          key={dataset.id}
                        >
                          <div>
                            <span
                              role="img"
                              style={{
                                marginRight: 5,
                              }}
                            >
                              <SourceImageRenderer
                                alt={"sourceName"}
                                className="source-table-selection-logo"
                                img={
                                  !dataset.source
                                    ? undefined
                                    : dataset.source.sourceMeta.publicInfo.logo
                                }
                                size={16}
                              />
                            </span>
                            <Typography.Text>
                              {dataset.name}
                              <Typography.Text type="secondary">
                                {" "}
                                {dataset.source.name}
                              </Typography.Text>
                            </Typography.Text>
                          </div>
                        </Option>
                      ))}
                  </OptGroup>
                </Select>
              </Form.Item>
            </Form>
          </div>
          <div className="footer">
            <div className="left">
              <Button onClick={onClose} type="link">
                Cancel
              </Button>
            </div>
            <div className="right">
              <Button
                onClick={() => {
                  form.submit();
                }}
                type="primary"
              >
                Continue
              </Button>
            </div>
          </div>
        </>
      );
    }

    const currentWarehouse = getCurrentWarehouse(
      org,
      match.params.warehouseSlug
    );
    const fetchSchema = fetchSchemaForWarehouse(currentWarehouse?.id);

    if (step.type === "TABLE_GENERATION") {
      const convertDimensionToProperties = (
        d: IDimensionFormInput
      ): IObjectPropertyFormInput => {
        const {
          type,
          semanticGroupIds,
          hidden,
          overrideName,
          latitude,
          longitude,
          ...rest
        } = d;
        return {
          ...rest,
          label: humanizeString(rest.columnName || ""),
        } as IObjectPropertyFormInput;
      };

      return (
        <div className="content">
          <SchemaSelectionStep
            fetchSchema={() => fetchSchema(step.data.modelId, step.data.viewId)}
            onSelect={async (metrics, dimensions) => {
              const d = datasets.find((d) => d.id === step.data.modelId);
              if (!d) {
                throw new Error("can't find selected dataset");
              } else {
                await onCreate({
                  name: step.data.name,
                  color: step.data.color,
                  icon: step.data.icon,
                  modelId: step.data.modelId,
                  viewId: step.data.viewId,
                  primaryKey: d.primaryKey,
                  properties: dimensions
                    ? dimensions.map((d) => {
                        return convertDimensionToProperties(d);
                      })
                    : [],
                  metrics: metrics ? metrics : [],
                });
                await onClose();
              }
            }}
          />
        </div>
      );
    }
  };

  return (
    <Modal
      footer={null}
      closable={false}
      maskClosable={true}
      width={"40%"}
      open={open}
      title={null}
      onCancel={onClose}
      confirmLoading={loading}
      className={`object-creation-modal whaly-modal-v2`}
    >
      <div className="header">Create an Object</div>
      {renderInner()}
    </Modal>
  );
}

export default compose<Props, ICreateObjectModalProps>(
  WithOrg,
  withRouter,
  withAntUtils
)(CreateObjectModal);
