import * as React from "react";
import "./FormHeader.scss";
import { CloseOutlined } from "@ant-design/icons";

interface IFormHeaderProps {
  title: string;
  onClose?: () => void;
}

export default class FormHeader extends React.Component<IFormHeaderProps> {
  public render() {
    const { onClose, title } = this.props;
    return (
      <div className="form-header">
        <span className="title">{title}</span>
        {onClose ? (
          <span onClick={onClose} className="close">
            <CloseOutlined />
          </span>
        ) : undefined}
      </div>
    );
  }
}
