import type { IObject } from "../../../../../../interfaces/object";
import { parseLabel } from "../../domain";
import type { IRecordWidgetSuccessData } from "../domain";

export const extractRecordLabel = (
  data: IRecordWidgetSuccessData,
  object: IObject
) => {
  const cubeName = object.table.cubeName;
  const label = data.data[`${cubeName}.label`] as string;
  const d = parseLabel(label ? label : "");
  return d;
};
