import type { TableProps } from "antd";
import { Col, Row, Select, Space, Table, Typography } from "antd";
import moment from "moment";
import { useEffect, useReducer } from "react";
import { compose } from "../../../../components/compose/WlyCompose";
import DatePicker from "../../../../components/datepicker/DatePicker";
import Feednack from "../../../../components/layout/feedback/feedback";
import type { INotificationJob } from "../../../../interfaces/notification";
import type { InjectedOrgProps } from "../../../orgs/WithOrg";
import WithOrg from "../../../orgs/WithOrg";
import SettingsWrapper from "../../SettingsWrapper";
import { useGetNotificationJobs } from "./api/useGetNotificationJobs";
import { useGetOrgUsers } from "./api/useGetOrgUsers";
import { JobMessage } from "./cells/JobMessage";
import { JobStatus } from "./cells/JobStatus";

interface INotificationJobsPageProps {}

type Props = INotificationJobsPageProps & InjectedOrgProps;

type SetTableState =
  | {
      action: "setDate";
      date: string | undefined;
    }
  | { action: "setUser"; user: string | undefined }
  | { action: "setStatus"; status: INotificationJob["status"][] }
  | { action: "setPage"; page: number };

type TableState = {
  filters: {
    orgId: string;
    status: INotificationJob["status"][];
    date?: string;
    userId?: string;
  };
  pagination: {
    page: number;
  };
};

const PAGE_SIZE = 10;

const tableStateToQueryFilters = (state: TableState) => {
  return {
    filters: {
      org: {
        id: state.filters.orgId,
      },
      user: {
        id: state.filters.userId,
      },
      isDeleted: false,
      status_not_in: ["merged"],
      executeAt_gt: state.filters.date
        ? moment(state.filters.date).startOf("day").toISOString()
        : undefined,
      executeAt_lt: state.filters.date
        ? moment(state.filters.date).endOf("day").toISOString()
        : undefined,
    },
    pageSize: PAGE_SIZE,
    offset: PAGE_SIZE * (state.pagination.page - 1),
  };
};

function NotificationJobsPage(props: Props) {
  const { org } = props;

  const [tableState, setTableState] = useReducer(
    (state: TableState, payload: SetTableState) => {
      let updatedState = state;
      if (payload.action === "setDate") {
        updatedState = {
          ...state,
          filters: {
            ...state.filters,
            date: payload.date,
          },
          pagination: {
            ...state.pagination,
            page: 1,
          },
        };
      }
      if (payload.action === "setUser") {
        updatedState = {
          ...state,
          filters: {
            ...state.filters,
            userId: payload.user,
          },
          pagination: {
            ...state.pagination,
            page: 1,
          },
        };
      }
      if (payload.action === "setStatus") {
        updatedState = {
          ...state,
          filters: {
            ...state.filters,
            status: payload.status,
          },
          pagination: {
            ...state.pagination,
            page: 1,
          },
        };
      }
      if (payload.action === "setPage") {
        updatedState = {
          ...state,
          pagination: {
            ...state.pagination,
            page: payload.page,
          },
        };
      }
      return updatedState;
    },
    {
      filters: {
        orgId: org.id,
        status: [
          "canceled",
          "completed",
          "delayed",
          "failed",
          "pending",
          "running",
        ],
      },
      pagination: {
        page: 1,
      },
    }
  );

  const { data, loading, error, fetchMore } = useGetNotificationJobs({
    variables: tableStateToQueryFilters(tableState),
  });

  const {
    data: userData,
    loading: userLoading,
    error: userError,
  } = useGetOrgUsers({
    variables: {
      filters: {
        org: { id: org.id },
        isDeleted: false,
        user: { isDeleted: false, isPublicGuestUser: false, type: "STANDARD" },
      },
    },
  });

  useEffect(() => {
    fetchMore(tableStateToQueryFilters(tableState));
  }, [tableState]);

  if (error) return <Feednack>{error.message}</Feednack>;

  const jobs = data?.allNotificationJobs ?? [];

  const columns: TableProps<INotificationJob>["columns"] = [
    {
      title: "Status",
      key: "status",
      width: 300,
      render: (_, record) => <JobStatus job={record} />,
    },
    {
      title: "Message",
      key: "message",
      render: (_, record) => <JobMessage job={record} />,
    },
    {
      title: "Action",
      key: "action",
      width: 120,
      render: (_, record) =>
        record.token ? (
          <a
            href={`https://api.whaly.io/v1/notifications/email/web-view/${record.token}`}
            target="_wly_email_preview"
          >
            Preview
          </a>
        ) : undefined,
    },
  ];

  return (
    <SettingsWrapper>
      <div style={{ padding: 32 }}>
        <Space style={{ display: "flex" }} direction="vertical">
          <Row gutter={[16, 16]}>
            <Col xs={24}>
              <Typography.Title level={3}>
                Notification history
              </Typography.Title>
            </Col>
          </Row>
          <Space>
            <Select<string>
              placeholder="Select a user"
              style={{ minWidth: 200 }}
              popupMatchSelectWidth={false}
              options={(userData?.allUserRoles ?? []).map((role) => ({
                value: role.user.id,
                label: (
                  <Space>
                    {(role.user.firstName || role.user.lastName) && (
                      <Typography.Text>
                        {role.user.firstName} {role.user.lastName}
                      </Typography.Text>
                    )}
                    <Typography.Text type="secondary">
                      {role.user.email}
                    </Typography.Text>
                  </Space>
                ),
              }))}
              allowClear
              loading={userLoading}
              disabled={!!userError}
              value={tableState.filters.userId}
              onChange={(v) => setTableState({ action: "setUser", user: v })}
            />
            <DatePicker
              placeholder="Select a date"
              allowClear
              value={
                tableState.filters.date
                  ? moment(tableState.filters.date)
                  : undefined
              }
              onChange={(v) =>
                setTableState({ action: "setDate", date: v?.toString() })
              }
            />
          </Space>
          <Table
            columns={columns}
            dataSource={jobs}
            style={{ border: "1px solid #D8D8D8", borderRadius: 6 }}
            loading={loading}
            pagination={{
              pageSize: PAGE_SIZE,
              current: tableState.pagination.page,
              total: data?._allNotificationJobsMeta.count || 0,
              style: { marginRight: 16 },
              position: ["bottomRight"],
              showSizeChanger: false,
              onChange: (page) =>
                setTableState({
                  action: "setPage",
                  page,
                }),
            }}
          />
        </Space>
      </div>
    </SettingsWrapper>
  );
}

export default compose<Props, INotificationJobsPageProps>(WithOrg)(
  NotificationJobsPage
);
