import { makeBaseAuth } from "../helpers/authHelpers";
import type { RefreshTokenResource } from "../interfaces/auth";
import ApiService from "../services/ApiService";
import { encodePageLocation } from "../services/authService";

const baseAppUrl = window.WHALY_CONSTANTS.APP_URL;
const baseAuthUrl = window.WHALY_CONSTANTS.AUTH_SERVER_URL;
const clientId = window.WHALY_CONSTANTS.APP_CLIENT_ID;
const clientSecret = window.WHALY_CONSTANTS.APP_CLIENT_SECRET;

type RefreshToken = AuthorizationCodeGrant | RefreshTokenGrant;

interface AuthorizationCodeGrant extends BaseGrant {
  code: string;
  grant_type: "authorization_code";
}

interface RefreshTokenGrant extends BaseGrant {
  refresh_token: string;
  grant_type: "refresh_token";
}

interface BaseGrant {
  grant_type: "authorization_code" | "refresh_token";
}

// Create a new request
const request = (page: string) => ({
  client_id: clientId,
  metadata: {},
  redirect_uri: `${baseAppUrl}/callback`,
  response_type: "code",
  scope: "offline",
  state: encodePageLocation(page),
});

export const login = (page: string) => {
  const req = request(page);
  return `${baseAuthUrl}/oauth2/auth?client_id=${encodeURI(
    req.client_id
  )}&response_type=${encodeURI(req.response_type)}&redirect_uri=${encodeURI(
    req.redirect_uri
  )}&scope=${encodeURI(req.scope)}&state=${encodeURI(req.state)}`;
};

export const portalLogin = (page: string) => {
  const state = encodePageLocation(page);
  return `${
    window.WHALY_CONSTANTS.OVERRIDE_PORTAL_URL
      ? window.WHALY_CONSTANTS.OVERRIDE_PORTAL_URL
      : ""
  }/auth/public/signin?state=${state}${
    window.WHALY_CONSTANTS.OVERRIDE_PORTAL_URL ? "&localDomain=true" : ""
  }`;
};

export const refreshToken = (
  payload: RefreshToken
): Promise<RefreshTokenResource> => {
  const formData = new FormData();
  Object.keys(payload).forEach((k) => {
    formData.append(k, (payload as any)[k]);
  });
  formData.append("redirect_uri", `${baseAppUrl}/callback`);

  const data = new URLSearchParams();
  for (const pair of formData as any) {
    data.append(pair[0], pair[1]);
  }

  return ApiService.postRequest(
    `/oauth2/token`,
    data,
    {},
    {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: makeBaseAuth(clientId, clientSecret),
    },
    {
      authUrl: true,
      authenticated: false,
    }
  );
};

export const refreshPortalJwt = (
  currentToken: string
): Promise<{ token: string; expire_in: string }> => {
  return ApiService.postRequest(
    `/auth/api/refresh-session`,
    {
      token: currentToken,
    },
    {},
    {},
    {
      customDomain: window.WHALY_CONSTANTS.OVERRIDE_PORTAL_URL,
      authenticated: false,
    }
  );
};
