import type { TableType } from "../containers/workbench/workbench/exploration/domain";
import GraphQLService from "./graphql/GraphQLService";

export interface IExplorationUpdatePayload {
  id?: string;
  name?: string;
  description?: string;
  table: ITablePayload;
}

export interface IObjectUpdatePayload {
  id?: string;
  icon?: string;
  color?: string;
  name?: string;
  description?: string;
  table: ITablePayload;
  conf: IObjectPayload;
}

export type ITablePayload = ITableUpdatePayload | ITableCreatePayload;

type IObjectPayload = IObjectBasePayload;

type IObjectPropertyPayload =
  | _IObjectPropertyUpdatePayload
  | _IObjectPropertyCreatePayload;

export interface _IObjectPropertyUpdatePayload
  extends IUpdateBaseObject,
    IObjectPropertyBasePayload {}

export interface _IObjectPropertyCreatePayload
  extends ICreateBaseObject,
    IObjectPropertyBasePayload {}

interface ITableUpdatePayload extends IUpdateBaseObject, ITableBasePayload {}
interface ITableCreatePayload extends ICreateBaseObject, ITableBasePayload {}

interface ITableBasePayload {
  name: string;
  primaryKey: string;
  view: string;
  tableType: TableType;
  blendedDatasetIds?: string[];
  dimensions: IDimensionPayload[];
  metrics: IMetricPayload[];
  relationships?: IRelationshipPayload[];
  drills: string;
  semanticGroups: ISemanticGroupPayload[];
}

interface IObjectBasePayload {
  name: string;
  icon?: string;
  color?: string;
  description?: string;
  primaryLabel?: string;
  primaryImage?: string;
  canBeListed: boolean;
  properties: IObjectPropertyPayload[];
}

interface IObjectPropertyBasePayload {
  columnName: string;
  columnDomain: string;
  label: string;
  description?: string;
  formatter?: string;
  formatterConfig?: string;
  foreignKey?: string;
}

export type ISemanticGroupPayload =
  | ISemanticGroupCreatePayload
  | ISemanticGroupUpdatePayload;

interface ISemanticGroupUpdatePayload
  extends EnhancedSemanticGroupTemplate,
    IUpdateBaseObject {}
interface ISemanticGroupCreatePayload
  extends EnhancedSemanticGroupTemplate,
    ICreateBaseObject {}

interface EnhancedSemanticGroupTemplate {
  name: string;
}

export type IDimensionPayload =
  | IDimensionCreatePayload
  | IDimensionUpdatePayload;

interface IDimensionUpdatePayload
  extends EnhancedDimensionTemplate,
    IUpdateBaseObject {}
interface IDimensionCreatePayload
  extends EnhancedDimensionTemplate,
    ICreateBaseObject {}

interface EnhancedDimensionTemplate {
  type: "geo" | "standard";
  columnName?: string;
  description?: string;
  overrideName?: string;
  columnDomain?: string;
  latitude?: string;
  longitude?: string;
  hidden?: boolean;
  semanticGroupIds?: string[];
}

export type IMetricPayload = IMetricCreatePayload | IMetricUpdatePayload;

interface IMetricUpdatePayload
  extends EnhancedMetricTemplate,
    IUpdateBaseObject {}
interface IMetricCreatePayload
  extends EnhancedMetricTemplate,
    ICreateBaseObject {}

interface EnhancedMetricTemplate {
  columnName?: string;
  overrideName?: string;
  description?: string;
  prefix?: string;
  suffix?: string;
  format?: string;
  expression: string;
  filters?: string;
  drills?: string;
  semanticGroupIds?: string[];
}

export type IRelationshipPayload =
  | IRelationshipUpdatePayload
  | IRelationshipCreatePayload;

interface IRelationshipUpdatePayload
  extends EnhancedRelationshipTemplate,
    IUpdateBaseObject {}
interface IRelationshipCreatePayload
  extends EnhancedRelationshipTemplate,
    ICreateBaseObject {}

interface EnhancedRelationshipTemplate {
  from: string;
  to: string;
  right: ITablePayload;
  type: string;
}

interface IUpdateBaseObject {
  id: string;
}

interface ICreateBaseObject {
  tempId: string;
}

interface RedirectMutation {
  resourceType:
    | "table"
    | "metric"
    | "dimension"
    | "semanticGroup"
    | "property"
    | "object";
  resourceId: string;
}

interface RedirectExplorationMutationOutput extends RedirectMutation {
  explorationId: string;
}

interface RedirectObjectMutationOutput extends RedirectMutation {
  objectId: string;
}

export const mutateExplorationFromCode = (
  orgId: string,
  warehouseId: string,
  payload: IExplorationUpdatePayload,
  resourceRedirect?: RedirectMutation
): Promise<RedirectExplorationMutationOutput | null> => {
  return GraphQLService<{
    mutateExplorationFromDeclaration: RedirectExplorationMutationOutput | null;
  }>(
    `
  mutation mutateExplorationFromDeclaration(
    $orgId: ID!,
    $warehouseId: ID!,
    $payload: JSON,
    $resourceRedirect: JSON
    ) {
    mutateExplorationFromDeclaration(
      orgId: $orgId,
      warehouseId: $warehouseId,
      payload: $payload,
      resourceRedirect: $resourceRedirect
      ) {
      resourceType
      resourceId
      explorationId
    }
  }`,
    {
      orgId: orgId,
      warehouseId: warehouseId,
      payload: payload,
      resourceRedirect: resourceRedirect,
    }
  ).then((r) => {
    return r.mutateExplorationFromDeclaration;
  });
};

export const mutateObjectFromCode = (
  orgId: string,
  modelId: string,
  payload: IObjectUpdatePayload,
  resourceRedirect?: RedirectMutation
): Promise<RedirectObjectMutationOutput | null> => {
  const newPaylod = payload;
  if (newPaylod.conf.properties) {
    newPaylod.conf.properties = newPaylod.conf.properties.map((property) => {
      if (property.formatterConfig) {
        let newFormatterConfig = property.formatterConfig;
        try {
          newFormatterConfig = JSON.stringify(property.formatterConfig);
        } catch (error) {}
        return {
          ...property,
          formatterConfig: newFormatterConfig,
        };
      } else {
        return property;
      }
    });
  }
  return GraphQLService<{
    mutateObjectFromDeclaration: RedirectObjectMutationOutput | null;
  }>(
    `
  mutation mutateObjectFromDeclaration(
    $orgId: ID!,
    $modelId: ID!,
    $payload: JSON,
    $resourceRedirect: JSON
    ) {
    mutateObjectFromDeclaration(
      orgId: $orgId,
      modelId: $modelId,
      payload: $payload,
      resourceRedirect: $resourceRedirect
      ) {
      resourceType
      resourceId
      objectId
    }
  }`,
    {
      orgId: orgId,
      modelId: modelId,
      payload: newPaylod,
      resourceRedirect: resourceRedirect,
    }
  ).then((r) => {
    return r.mutateObjectFromDeclaration;
  });
};
