import Icon from "@ant-design/icons";
import type { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";

export const WlyPieChartIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon
    component={() => (
      <svg
        width="1em"
        height="1em"
        viewBox="0 0 339 339"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M339 169.5C339 195.499 333.019 221.15 321.52 244.468C310.021 267.786 293.312 288.146 272.685 303.973C252.059 319.801 228.067 330.671 202.568 335.743C177.068 340.815 150.743 339.954 125.63 333.224C100.517 326.495 77.2881 314.079 57.7409 296.937C38.1936 279.794 22.8519 258.385 12.9024 234.365C2.95295 210.345 -1.33752 184.358 0.362912 158.414C2.06334 132.471 9.70909 107.266 22.7087 84.75L169.5 169.5H339Z"
          fill="#41D58A"
        />
        <path
          d="M232.185 12.0171C256.738 21.7902 278.665 37.1688 296.217 56.925C313.768 76.6811 326.457 100.267 333.27 125.8C340.083 151.333 340.832 178.106 335.456 203.98C330.08 229.854 318.729 254.112 302.309 274.818L169.5 169.5L232.185 12.0171Z"
          fill="#FBCE19"
        />
        <path
          d="M21.1779 87.4586C31.9517 67.9806 46.4565 50.8155 63.8641 36.9432C81.2718 23.0709 101.241 12.7631 122.633 6.60835C144.024 0.453623 166.418 -1.42752 188.536 1.07233C210.654 3.57219 232.064 10.4041 251.542 21.1779L169.5 169.5L21.1779 87.4586Z"
          fill="#3A5C83"
        />
      </svg>
    )}
    {...props}
  />
);
