import * as React from "react";
import type { RouteComponentProps } from "react-router";
import { withRouter } from "react-router";
import GenericCatalog from "../../../components/catalog/list/GenericCatalog";
import { GenericCatalogLoader } from "../../../components/catalog/loader/GenericCatalogLoader";
import { compose } from "../../../components/compose/WlyCompose";
import Error from "../../../components/layout/feedback/error";
import Feednack from "../../../components/layout/feedback/feedback";
import type { IOrgWithSources } from "../../../interfaces/org";
import type {
  ISourceMeta,
  ISourceMetaDataPublicInfoLabel,
} from "../../../interfaces/sources";
import GraphQl from "../../graphql/graphql";
import type { InjectedOrgProps } from "../../orgs/WithOrg";
import withOrg, { getCurrentWarehouse } from "../../orgs/WithOrg";
import sources from "./catalog.json";

interface ISourcesProps {
  onClose?: () => void;
  selectedWarehouseSlug: string;
}

const GQL = `
query getSourceMetas(
  $id: ID!,
  $labels: [SourceMetaDataPublicInfoLabelType]!
) {
  allSourceMetas(sortBy: name_ASC, where: {deleted: false, executor_not: WAREHOUSE, publicInfo: {label_not_in: $labels}}) {
    id
    slug
    name
    auth
    publicInfo {
      id
      name
      slug
      label
      tagline
      website
      logo
      description
    }
  }
  Org(where:{id: $id}) {
    sources {
      id
      name
      sourceMeta{
        publicInfo {
          logo
        }
      }
    }
  }
}
`;

interface GQLQueryResult {
  allSourceMetas: ISourceMeta[];
  Org: IOrgWithSources;
}

type Props = ISourcesProps &
  InjectedOrgProps &
  RouteComponentProps<{ warehouseSlug?: string }>;

class Sources extends React.Component<Props> {
  public render() {
    const { org, user, onClose, selectedWarehouseSlug } = this.props;

    const labelsToHide: ISourceMetaDataPublicInfoLabel[] = [
      "deprecated",
      "thirdparty",
    ];

    const title = "Source Catalog";

    if (!user.isAdmin) {
      labelsToHide.push("technical");
    }
    return (
      <GraphQl<GQLQueryResult>
        query={GQL}
        variables={{ id: org.id, labels: labelsToHide }}
      >
        {(gql) => {
          if (gql.status === "initial" || gql.status === "loading") {
            return <GenericCatalogLoader onClose={onClose} title={title} />;
          }
          if (gql.status === "error") {
            return (
              <Feednack>
                <Error />
              </Feednack>
            );
          }

          const connectorCatalogData = sources;
          const connectorCatalogItems: ISourceMeta[] =
            connectorCatalogData.sources.map((connectorCatalogSource) => {
              return {
                publicInfo: {
                  label: "thirdparty",
                  name: connectorCatalogSource.name,
                  slug: connectorCatalogSource.slug,
                  logo: connectorCatalogSource.logoUrl
                    ? `/connector-catalog/images/${connectorCatalogSource.logoUrl}`
                    : undefined,
                },
                overrideUrl: `https://connectorcatalog.com/?connector=${connectorCatalogSource.slug}`,
              } as any;
            });
          return (
            <>
              <GenericCatalog
                onClose={onClose}
                items={gql.data.allSourceMetas.concat(connectorCatalogItems)}
                alreadyPurchasedItems={[]}
                destinationViewName={"sourceConnection"}
                title={title}
                disableSelection={(s) => {
                  // disable segment for non managed warehouse
                  return (
                    getCurrentWarehouse(
                      this.props.org,
                      this.props.match.params.warehouseSlug
                    )?.destinationMeta.isWhalyManaged === false &&
                    s.slug === "segment"
                  );
                }}
                currentWarehouseSlug={selectedWarehouseSlug}
              />
            </>
          );
        }}
      </GraphQl>
    );
  }
}

export default compose<Props, ISourcesProps>(withOrg, withRouter)(Sources);
