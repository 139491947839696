import type { RouteComponentProps } from "react-router";
import { withRouter } from "react-router";
import { compose } from "../../components/compose/WlyCompose";
import {
  AUTHORIZATION_CODE_SEARCH_SETTINGS,
  AUTHORIZATION_ERROR_SEARCH_SETTINGS,
  parseSearch,
  STATE_SEARCH_SETTINGS,
} from "../../helpers/queryStringHelpers";
import { routeDescriptor } from "../../routes/routes";

interface ICallbackProps {}

const SEARCH_SETTINGS = [
  ...AUTHORIZATION_CODE_SEARCH_SETTINGS,
  ...AUTHORIZATION_ERROR_SEARCH_SETTINGS,
  ...STATE_SEARCH_SETTINGS,
];

export interface LoginSearchSettings {
  code: string;
  error: string;
  error_description: string;
  error_hint: string;
  state: string;
}

type Props = ICallbackProps & RouteComponentProps<{}>;

function Callback(props: Props) {
  const {
    location: { search },
    history,
  } = props;

  const values = parseSearch<LoginSearchSettings>(search, SEARCH_SETTINGS);

  history.replace(routeDescriptor.login.renderRoute({}), values);
  return <div />;
}

export default compose<Props, ICallbackProps>(withRouter)(Callback);
