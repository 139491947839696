import type { IObject } from "../../../../../../../interfaces/object";
import type { IOrg } from "../../../../../../../interfaces/org";
import { routeDescriptor } from "../../../../../../../routes/routes";
import { getObjectColumns } from "../../../../object/domain";
import type { IRecord } from "../../../domain";
import { parseLabel } from "../../../domain";

export interface IEmailWidgetButtonConfig {
  align?: "left" | "right" | "center";
  spacing?: "normal" | "compact";
  text?: string;
  href?: string;
}

export const getDefaultConfig = (
  conf: IEmailWidgetButtonConfig,
  record: IRecord
) => {
  const align = conf.align ? conf.align : "left";
  const text = conf.text ? conf.text : "Click here";
  const href = conf.href ? conf.href : "https://whaly.io";
  const spacing = conf.spacing ? conf.spacing : "normal";

  return {
    align,
    text,
    href,
    spacing,
  };
};

export const getHref = (
  conf: IEmailWidgetButtonConfig,
  object: IObject,
  record: IRecord,
  org: IOrg
) => {
  const cols = getObjectColumns(object);
  const currentColumn = cols.find((c) => c.data.key === conf.href);
  let href = conf.href;
  if (
    currentColumn &&
    conf.href &&
    !conf.href.startsWith("http") &&
    currentColumn.type === "property" &&
    (currentColumn.data.type === "foreignKey" ||
      currentColumn.data.key.endsWith(".label"))
  ) {
    const l = parseLabel(record[conf.href] as string);
    href = `${window.WHALY_CONSTANTS.APP_URL}${routeDescriptor[
      "object-record"
    ].renderRoute({
      organizationSlug: org.slug,
      objectSlug: currentColumn.data.object.slug,
      recordId: l.id,
    })}`;
  }

  return href;
};
