import { Button, Modal, Space, Typography } from "antd";
import { useEffect, useState } from "react";
import type { INotificationWorkflow } from "../../interfaces/notification";
import type { ITopic } from "../../interfaces/topic";
import "./NotificationSubscriptionModal.scss";
import NotificationSubscriptionPicker from "./NotificationSubscriptionPicker";

interface INotificationSubscriptionModalProps {
  topics: ITopic[];
  workflows: INotificationWorkflow[];
  initialValues: { topicId: string; workflowId: string }[];
  open: boolean;
  onOk: (values: { topicId: string; workflowId: string }[]) => void;
  onCancel: () => void;
}

export function NotificationSubscriptionModal(
  props: INotificationSubscriptionModalProps
) {
  const { topics, workflows, initialValues, open, onCancel, onOk } = props;
  const [values, setValues] =
    useState<{ topicId: string; workflowId: string }[]>(initialValues);

  useEffect(() => {
    if (open) {
      setValues(initialValues);
    }
  }, [open]);

  return (
    <Modal
      open={open}
      className="notification-subscription-modal"
      onCancel={onCancel}
      title={"Manage subscriptions"}
      okText={"Ok"}
      footer={null}
      maskClosable={false}
      width={"80%"}
      centered={true}
      destroyOnClose={true}
    >
      <div className="notification-subscription-modal-body">
        <Space direction="vertical" style={{ display: "flex" }}>
          <Typography.Text>
            Choose what topics matter and how to get notified about them.
          </Typography.Text>
          <NotificationSubscriptionPicker
            topics={topics}
            workflows={workflows}
            values={values}
            onChange={(srk) => setValues(srk)}
          />
        </Space>
      </div>
      <div className="notification-subscription-modal-footer">
        <Space>
          <Button onClick={onCancel}>Cancel</Button>
          <Button onClick={() => onOk(values)} type="primary">
            Ok
          </Button>
        </Space>
      </div>
    </Modal>
  );
}
