import type { SelectProps } from "antd";
import { Select } from "antd";
import type {
  SchemaItem,
  SchemaResult,
} from "../../../../../../../../../../interfaces/transformations";
import TypeRenderer from "../../../../../../../../../spreadsheet/renderer/TypeRenderer";

interface SchemaFieldProps extends SelectProps {
  schema: SchemaResult;
  filter?: (schemaItem: SchemaItem) => boolean;
}

export function SchemaField(props: SchemaFieldProps) {
  const { schema, filter, ...rest } = props;
  return (
    <Select {...rest} style={{ width: "100%" }}>
      {Object.keys(schema)
        .filter((k) => (filter ? filter(schema[k]) : true))
        .map((k) => {
          return (
            <Select.Option key={k} value={k}>
              <div style={{ display: "flex" }}>
                <div style={{ flex: 1 }}>{k}</div>
                <TypeRenderer {...schema[k]} />
              </div>
            </Select.Option>
          );
        })}
    </Select>
  );
}
