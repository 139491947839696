import Markdoc from "@markdoc/markdoc";
import { Typography } from "antd/lib";
import React from "react";
import { compose } from "../../../../../../../../components/compose/WlyCompose";
import type { IObject } from "../../../../../../../../interfaces/object";
import type { InjectedOrgProps } from "../../../../../../../orgs/WithOrg";
import WithOrg from "../../../../../../../orgs/WithOrg";
import { getObjectColumns } from "../../../../../object/domain";
import type { IRecord } from "../../../../domain";
import type { IWidget } from "../../../domain";
import { getParsedDoc } from "../../../email-widgets/text/domain";
import type { IWidgetMarkdownConfig } from "../domain";
import { convertKeyToMarkdocVariable } from "../domain";
import "./MarkdownWidget.scss";
import {
  additionalComponents,
  nodesConfig,
} from "./markdoc-react-component/config";

interface IMarkdownWidgetProps {
  widget: IWidget;
  object: IObject;
  record: IRecord;
  conf: IWidgetMarkdownConfig;
  edit?: boolean;
}

type Props = IMarkdownWidgetProps & InjectedOrgProps;

function MarkdownWidget(props: Props) {
  const { conf, record, user, object } = props;
  const source = conf?.text ? conf.text : "";

  if (!source) {
    return (
      <Typography.Text type="secondary">
        Please configure this widget
      </Typography.Text>
    );
  }

  const formattedRecord = Object.keys(record).reduce((acc, v) => {
    return {
      ...acc,
      [convertKeyToMarkdocVariable(v)]: record[v],
    };
  }, {});

  const columns = getObjectColumns(object);
  const content = getParsedDoc(
    source,
    formattedRecord,
    user,
    columns,
    nodesConfig
  );

  const html = Markdoc.renderers.react(content, React, {
    ...additionalComponents,
  });

  // max-width: 612px;
  // margin-left: auto;

  return (
    <div
      className="markdown-widget"
      style={
        conf.boxed
          ? { maxWidth: 612, marginLeft: "auto", marginRight: "auto" }
          : undefined
      }
    >
      {html}
    </div>
  );
}

export default compose<Props, IMarkdownWidgetProps>(WithOrg)(MarkdownWidget);
