import * as React from "react";
import "./FormCard.scss";

interface IFormCardProps {
  left: React.ReactNode;
  right: React.ReactNode;
  children?: React.ReactNode;
}

export default class FormCard extends React.Component<IFormCardProps> {
  public render() {
    const { left, right } = this.props;
    return (
      <div className="form-card">
        <div className="top-info">
          <div className="left">{left}</div>
          <div className="right">{right}</div>
        </div>
        <div className="content">{this.props.children}</div>
      </div>
    );
  }
}
