import { CloseOutlined, SwapOutlined } from "@ant-design/icons";
import { Button, Space } from "antd";
import Dropdown from "antd/es/dropdown/dropdown";
import useToken from "antd/es/theme/useToken";
import type { MeasureItemSortValue } from "../../../../../../components/measures/measure-item/MeasureItem";
import type { AvailableColumn } from "../../domain";
import PropertySort from "./PropertiesSelector/PropertySortSelector";

interface IObjectToolbarSortProps {
  columns: Array<AvailableColumn>;
  value: Array<[string, MeasureItemSortValue]>;
  onChange: (value: Array<[string, MeasureItemSortValue]>) => any;
}

export function ObjectToolbarSort(props: IObjectToolbarSortProps) {
  const { columns, value, onChange } = props;
  const [, token] = useToken();

  const contentStyle = {
    backgroundColor: token.colorBgElevated,
    borderRadius: token.borderRadiusLG,
    boxShadow: token.boxShadowSecondary,
  };

  return (
    <Dropdown
      trigger={["click"]}
      dropdownRender={() => {
        return (
          <div
            className="wlydatepicker-custom-menu"
            style={{
              ...contentStyle,
            }}
          >
            <div
              style={{
                width: 320,
                margin: "4px 12px",
                paddingTop: 12,
                paddingBottom: 12,
              }}
            >
              <PropertySort
                columns={columns}
                value={value}
                onChange={onChange}
              />
            </div>
          </div>
        );
      }}
    >
      <div>
        <Button
          size="small"
          type="text"
          icon={<SwapOutlined rotate={90} />}
          style={
            value?.length
              ? {
                  background: "#DBE0FD",
                  color: "#3f6ac4",
                }
              : undefined
          }
        >
          <Space>
            {value?.length ? `Sort: ${value.length}` : "Sort"}
            {value?.length > 0 && (
              <CloseOutlined
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  onChange([]);
                }}
              />
            )}
          </Space>
        </Button>
      </div>
    </Dropdown>
  );
}
