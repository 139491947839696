import { Typography } from "antd";
import { getObjectColumns } from "../../../../../object/domain";
import type { AvailableProperty } from "../../../../../object/viewer/domain";
import { parseLabel } from "../../../../domain";
import type { IWidgetEmailRendererProps } from "../../domain";
import type { IEmailWidgetImageConfig } from "../domain";
import { getDefaultConfig, isConfInvalid } from "../domain";

interface IEmailWidgetImageRendererProps
  extends IWidgetEmailRendererProps<IEmailWidgetImageConfig> {}

export function EmailWidgetImageRenderer(
  props: IEmailWidgetImageRendererProps
) {
  const { conf, record, object } = props;

  if (isConfInvalid(conf, record)) {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: 40,
        }}
      >
        <Typography.Text type="secondary">
          Please configure this widget
        </Typography.Text>
      </div>
    );
  }

  const defaults = getDefaultConfig(conf, record);
  const columns = getObjectColumns(object);
  const selectedColumn = columns
    .map((c) => c.data)
    .find((c) => c.key === conf.src) as AvailableProperty | undefined;
  if (!selectedColumn) {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: 40,
        }}
      >
        <Typography.Text type="secondary">
          The property you have selected does not exists
        </Typography.Text>
      </div>
    );
  }

  const propertyType = selectedColumn.type;

  let imageSrc = defaults.imageSrc;

  if (propertyType !== "standard") {
    const t = parseLabel(record[selectedColumn.key] as string);
    if (t && t.image) {
      imageSrc = t.image;
    }
  }

  const renderAlign = () => {
    if (defaults.align === "center") {
      return defaults.align;
    }
    if (defaults.align === "right") {
      return "flex-end";
    }
    return "flex-start";
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: renderAlign(),
      }}
    >
      <img
        src={imageSrc}
        style={{
          width: defaults.width,
          borderRadius: defaults.borderRadius,
        }}
      />
    </div>
  );
}
