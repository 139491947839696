import { Typography } from "antd";
import * as React from "react";
import { useDrag } from "react-dnd";
import type { IObjectLayoutItemType } from "./domain";

interface IObjectLayoutEditorAddItemProps {
  title: string;
  icon: React.ReactNode;
  type: IObjectLayoutItemType;
  identifier: string;
}

export function ObjectLayoutEditorAddItem(
  props: IObjectLayoutEditorAddItemProps
) {
  const { title, icon, type, identifier } = props;

  const [{ opacity }, dragRef] = useDrag(
    () => ({
      type: type,
      item: { operation: "add", identifier },
      collect: (monitor) => ({
        opacity: monitor.isDragging() ? 0.5 : 1,
      }),
    }),
    []
  );

  return (
    <div ref={dragRef} style={{ opacity }} className="layout-editor-add-item">
      <div className="layout-editor-add-item-icon">{icon}</div>
      <div className="layout-editor-add-item-text">
        <Typography.Text type="secondary" ellipsis>
          {title}
        </Typography.Text>
      </div>
    </div>
  );
}
