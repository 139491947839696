import { EditorView } from "@codemirror/view";

export const CalculatedMetricEditorTheme = EditorView.theme({
  "&.cm-editor": {
    borderRadius: "6px",
  },
  ".cm-scroller": {
    fontFamily: 'Menlo, Monaco, "Courier New", monospace',
  },
  "&.cm-editor.cm-focused": {
    outline: "none",
  },
  ".cm-tooltip-autocomplete": {
    background: "white",
    color: "#303030",
    border: "none",
    boxShadow:
      "0 6px 16px 0 rgb(0 0 0 / 8%), 0 3px 6px -4px rgb(0 0 0 / 12%), 0 9px 28px 8px rgb(0 0 0 / 5%)",
    "& > ul > li": {
      fontFamily: 'Menlo, Monaco, "Courier New", monospace',
      backgroundColor: "transparent",
    },
    "& > ul > li[aria-selected]": {
      backgroundColor: "#E6F4FF",
      color: "#1677FF",
    },
  },
});
