import { Form, Select } from "antd";
import { useForm } from "antd/es/form/Form";
import { useEffect, useState } from "react";
import {
  withAntUtils,
  type InjectedAntUtilsProps,
} from "../../../../../../../../components/ant-utils/withAntUtils";
import { compose } from "../../../../../../../../components/compose/WlyCompose";
import { SimpleFormModal } from "../../../../../../../../components/form/modal/SimpleFormModal";
import type { IDestination } from "../../../../../../../../interfaces/destinations";
import type { IRadar } from "../../../../../../../../interfaces/radar";
import type { IDataset } from "../../../../../../../../interfaces/sources";
import type { Transformation } from "../../../../../../../../interfaces/transformations";
import { computeTransformations } from "../../../../../../../../services/BrizoService";
import { generateUniqueId } from "../../../../../../../../utils/uniqueId";
import type { UpdateRadarFunction } from "../../../../../selector/radar/domain";

type IRadarRecordKeyModalProps = {
  currentWarehouse: IDestination;
  radar: IRadar;
  open: boolean;
  onClose: () => void;
  onUpdateRadar: UpdateRadarFunction;
};

type Props = IRadarRecordKeyModalProps & InjectedAntUtilsProps;

const getSchemaForDataset = async (
  dataset: IDataset,
  currentWarehouseId: string,
  getRaw: boolean = false
) => {
  const prevId = generateUniqueId();
  const operation: Transformation = getRaw
    ? {
        var: prevId,
        domain: "dataset",
        operation: {
          type: "Table.FromWarehouseTable",
          args: {
            databaseName: dataset.warehouseDatabaseId,
            schemaName: dataset.warehouseSchemaId,
            tableName: dataset.warehouseTableId,
          },
        },
      }
    : {
        var: prevId,
        domain: "dataset",
        operation: {
          type: "Table.FromWhalyDataset",
          args: {
            datasetId: dataset.id,
          },
        },
      };
  return computeTransformations(currentWarehouseId, {
    schema: [
      operation,
      {
        var: generateUniqueId(),
        operation: {
          type: "Table.Schema",
          args: {
            table: prevId,
          },
        },
        domain: "viewResolver",
      },
    ],
  });
};

function RadarRecordKeyModal({
  antUtils,
  currentWarehouse,
  radar,
  open,
  onClose,
  onUpdateRadar,
}: Props) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [autocompleteValues, setAutocompleteValues] = useState<string[]>([]);
  const [form] = useForm();

  useEffect(() => {
    const fetchAutocompleteResults = async () => {
      try {
        if (!radar.model) {
          return [];
        }
        const res = await getSchemaForDataset(
          radar.model,
          currentWarehouse.id,
          false
        );
        if (res.data.schema) {
          setAutocompleteValues(Object.keys(res.data.schema).sort());
        } else {
          setAutocompleteValues([]);
        }
      } catch (error) {
        console.error("Error while fetching results");
        setAutocompleteValues([]);
      }
    };
    if (open) {
      fetchAutocompleteResults();
    }
    if (!open) {
      setAutocompleteValues([]);
    }
  }, [open]);

  return (
    <SimpleFormModal
      open={open}
      isSaving={isLoading}
      form={form}
      modalProps={{ title: "Update radar" }}
      onCancel={() => {
        onClose();
      }}
    >
      <Form
        layout="vertical"
        form={form}
        initialValues={{
          recordIdKey: radar.recordIdKey,
        }}
        onFinish={async (values) => {
          try {
            setIsLoading(true);
            await onUpdateRadar(radar.id, {
              recordIdKey: values.recordIdKey,
            });
            antUtils.message.success("Radar successfully updated");
          } catch (error) {
            console.error(error);
            antUtils.message.error("Error while updating radar");
          } finally {
            setIsLoading(false);
            onClose();
          }
        }}
      >
        <Form.Item label="Record ID key" name={"recordIdKey"}>
          <Select
            style={{ width: "100%" }}
            placeholder="Record ID key"
            allowClear
            options={autocompleteValues.map((v) => ({ label: v, value: v }))}
          />
        </Form.Item>
      </Form>
    </SimpleFormModal>
  );
}

export default compose<Props, IRadarRecordKeyModalProps>(withAntUtils)(
  RadarRecordKeyModal
);
