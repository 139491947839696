import { Form, Input, InputNumber, Select, Typography } from "antd";
import cuid from "cuid";
import * as React from "react";
import FormActions from "../../../../../../../components/form/actions/FormActions";
import Loading from "../../../../../../../components/layout/feedback/loading";
import type { AsyncData } from "../../../../../../../helpers/typescriptHelpers";
import type { SchemaResult } from "../../../../../../../interfaces/transformations";
import { catchErrors } from "../../../../../../transformations/domain";
import type { CacheStrategy, IDatasetUpdate } from "../../../../domain";

const { Text } = Typography;

interface ICacheStrategyFormProps {
  initialValues: CacheStrategy;
  onDone: () => void;
  onUpdateDataset: IDatasetUpdate;
  schema: AsyncData<SchemaResult>;
}

interface CacheStrategyFormValues {
  type: "every" | "column" | "sql" | "whaly_synced";
  columnValue: string;
  periodicValue: number;
  periodicPeriodValue: "minute" | "second" | "hour" | "day";
  sqlValue: string;
}

export function CacheStrategyForm(props: ICacheStrategyFormProps) {
  const { initialValues, onDone, onUpdateDataset, schema } = props;

  const [form] = Form.useForm<CacheStrategyFormValues>();
  const [id] = React.useState<string>(cuid());

  const [submitting, setSubmitting] = React.useState<boolean>(false);

  const getInitialValues = (): CacheStrategyFormValues => {
    const defaultColumn =
      schema.status === "success" ? Object.keys(schema.data)[0] : "loading...";
    const defaultValues: CacheStrategyFormValues = {
      type: "every",
      columnValue: defaultColumn,
      periodicValue: 45,
      periodicPeriodValue: "minute",
      sqlValue: "SELECT 42",
    };

    switch (initialValues.type) {
      case "column":
        defaultValues.type = "column";
        defaultValues.columnValue = initialValues.value;
        break;
      case "every":
        defaultValues.type = "every";
        const splitted = initialValues.value.split(" ");
        const parsedPeriod = parseInt(splitted?.[0]);
        if (Number.isSafeInteger(parsedPeriod)) {
          defaultValues.periodicValue = parsedPeriod;
        }
        if (["minute", "second", "hour", "day"].includes(splitted?.[1])) {
          defaultValues.periodicPeriodValue = splitted[1] as any;
        }
        break;
      case "sql":
        defaultValues.type = "sql";
        defaultValues.sqlValue = initialValues.value;
        break;
    }

    return defaultValues;
  };

  const formattedInitialValues: CacheStrategyFormValues = getInitialValues();

  const onFinish = () => {
    form.resetFields();
    onDone();
  };

  return (
    <Form
      form={form}
      initialValues={formattedInitialValues}
      layout="vertical"
      className="form-dropdown-form"
      onFinish={async (values) => {
        try {
          setSubmitting(true);
          const getValue = (): string | undefined => {
            switch (values.type) {
              case "column":
                return values.columnValue;
              case "every":
                return `${values.periodicValue} ${values.periodicPeriodValue}`;
              case "sql":
                return values.sqlValue;
              default:
                return "";
            }
          };

          const newCacheStrategy: CacheStrategy = {
            type: values.type,
            value: getValue(),
          };
          await onUpdateDataset({
            cacheStrategy: JSON.stringify(newCacheStrategy),
          });
          setSubmitting(false);
          onFinish();
        } catch (err) {
          console.error(err);
          catchErrors(err, id);
          setSubmitting(false);
        }
      }}
    >
      <div className="form-dropdown-form-content" id={id}>
        <Form.Item name={["type"]} label={"Select your cache strategy"}>
          <Select>
            <Select.Option value="every">Periodic refresh</Select.Option>
            <Select.Option value="column">On column value change</Select.Option>
            <Select.Option value="sql">
              On SQL query result change
            </Select.Option>
          </Select>
        </Form.Item>
        <Form.Item shouldUpdate={true}>
          {() => {
            const type = form.getFieldValue(["type"]);
            if (type === "every") {
              return (
                <Form.Item label="Choose your refresh period">
                  <Input.Group compact>
                    <Form.Item
                      name={["periodicValue"]}
                      rules={[{ required: true }]}
                      noStyle
                    >
                      <InputNumber style={{ width: "70%" }} min={1} />
                    </Form.Item>
                    <Form.Item
                      name={["periodicPeriodValue"]}
                      rules={[{ required: true }]}
                      noStyle
                    >
                      <Select style={{ width: "30%" }}>
                        <Select.Option value="minute">Minutes</Select.Option>
                        <Select.Option value="hour">Hours</Select.Option>
                        <Select.Option value="day">Days</Select.Option>
                      </Select>
                    </Form.Item>
                  </Input.Group>
                </Form.Item>
              );
            } else if (type === "column") {
              if (schema.status === "success") {
                const options: JSX.Element[] = Object.keys(schema.data).map(
                  (field, i) => {
                    return (
                      <Select.Option value={field} key={i}>
                        {field}
                      </Select.Option>
                    );
                  }
                );
                return (
                  <Form.Item label="Choose the column your cache will be based on">
                    <Input.Group>
                      <Text code>MAX(</Text>
                      <Form.Item name={["columnValue"]} noStyle>
                        <Select style={{ width: "80%" }}>{options}</Select>
                      </Form.Item>
                      <Text code>)</Text>
                    </Input.Group>
                  </Form.Item>
                );
              } else if (
                schema.status === "initial" ||
                schema.status === "loading"
              ) {
                return <Loading />;
              } else if (schema.status === "error") {
                return (
                  <div>
                    There was an error while fetching columns:{" "}
                    {schema.error?.message}
                  </div>
                );
              }
            } else if (type === "sql") {
              return (
                <Form.Item name={["sqlValue"]} label="Choose your sql query">
                  <Input.TextArea />
                </Form.Item>
              );
            }
          }}
        </Form.Item>
      </div>
      <div className="form-dropdown-form-buttons">
        <FormActions
          flex={true}
          size="small"
          onCancel={onFinish}
          isSubmitting={submitting}
        />
      </div>
    </Form>
  );
}
