import { Dropdown, theme } from "antd";
import { type MenuProps } from "antd/lib";
import * as React from "react";

export interface IChartPickerDropdownProps {
  menu: MenuProps;
  disabled: boolean;
}

const { useToken } = theme;

export function ChartPickerDropdown(
  props: React.PropsWithChildren<IChartPickerDropdownProps>
) {
  const { menu, children, disabled } = props;

  const { token } = useToken();

  const contentStyle: React.CSSProperties = {
    backgroundColor: token.colorBgElevated,
    borderRadius: token.borderRadiusLG,
    boxShadow: token.boxShadowSecondary,
    maxHeight: 300,
    overflowY: "auto",
  };

  const menuStyle: React.CSSProperties = {
    boxShadow: "none",
  };

  return (
    <Dropdown
      disabled={disabled}
      trigger={["click"]}
      menu={menu}
      dropdownRender={(m) => {
        return (
          <div style={contentStyle}>
            {React.cloneElement(m as React.ReactElement, {
              style: menuStyle,
            })}
          </div>
        );
      }}
    >
      {children}
    </Dropdown>
  );
}
