import { Button, Modal } from "antd";
import * as React from "react";
import { WlyCardDangerZone } from "../../../../../components/cards/WlyCardDangerZone";

interface IWarehouseDangerZoneProps {
  onDelete: () => Promise<any>;
}

interface IState {
  visible: boolean;
  confirmLoading: boolean;
}

export default class WarehouseDangerZone extends React.Component<
  IWarehouseDangerZoneProps,
  IState
> {
  constructor(props: IWarehouseDangerZoneProps) {
    super(props);
    this.state = {
      visible: false,
      confirmLoading: false,
    };
  }

  handleOk = () => {
    this.setState({ confirmLoading: true });
    this.props
      .onDelete()
      .then(() => {
        this.setState({ visible: false });
      })
      .finally(() => {
        this.setState({ confirmLoading: false });
      });
  };

  public render() {
    const { visible, confirmLoading } = this.state;
    return (
      <>
        <WlyCardDangerZone
          title="Danger Zone"
          button={
            <Button
              onClick={() => this.setState({ visible: true })}
              danger={true}
              type="primary"
            >
              Delete
            </Button>
          }
        >
          Click here to permanently delete this destination. This cannot be
          undone.
        </WlyCardDangerZone>
        <Modal
          title="Delete source"
          open={visible}
          onOk={this.handleOk}
          confirmLoading={confirmLoading}
          onCancel={() => this.setState({ visible: false })}
          okButtonProps={{ danger: true }}
          okText={"Delete"}
        >
          <p>
            By clicking on delete you will remove the warehouse connection and
            delete everything on your organization. Are you sure you want to
            continue ?
          </p>
        </Modal>
      </>
    );
  }
}
