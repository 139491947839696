import type { BinaryOperator } from "@cubejs-client/core";
import { Form, Input } from "antd";
import type { FormInstance } from "antd/es/form/Form";
import type { AvailableDimension } from "../../../../../../../../components/measures/filter-item/FilterItem";
import { FilterEditor } from "../../../../../../../v2-demo/container/record/component/widgets/related-lists/editor/FilterEditor";
import type { CreateEditPresetFilterFormData } from "../domain";

interface IEditPresetFilterFormProps {
  form: FormInstance<CreateEditPresetFilterFormData>;
  initialValues?: Partial<CreateEditPresetFilterFormData>;
  onSave: (values: CreateEditPresetFilterFormData) => any;
  availableDimensions: AvailableDimension[];
  autocomplete: (
    dimensionName: string,
    operator: BinaryOperator,
    value?: string
  ) => Promise<string[]>;
}

export function CreateEditPresetFilterForm(props: IEditPresetFilterFormProps) {
  const { form, initialValues, onSave, availableDimensions, autocomplete } =
    props;
  return (
    <Form<CreateEditPresetFilterFormData>
      form={form}
      preserve={false}
      layout="vertical"
      initialValues={initialValues}
      onFinish={(values) => {
        onSave(values);
      }}
    >
      <Form.Item
        label="Name"
        name={["name"]}
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input placeholder="Filter name" />
      </Form.Item>
      <Form.Item
        label="Filter"
        name={["value"]}
        rules={[
          {
            required: true,
          },
        ]}
      >
        <FilterEditor
          autocomplete={autocomplete}
          availableDimensions={availableDimensions}
        />
      </Form.Item>
      <Form.Item
        noStyle
        hidden
        name={["id"]}
        rules={[
          {
            required: !!initialValues?.id,
          },
        ]}
      >
        <Input />
      </Form.Item>
    </Form>
  );
}
