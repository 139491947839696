import type { Query } from "@cubejs-client/core";
import _ from "lodash";
import { useEffect, useState } from "react";
import type { IObject } from "../../../../../../../../../../interfaces/object";
import {
  getObjectColumns,
  isAvailableMetric,
  isAvailableProperty,
} from "../../../../../../../object/domain";
import type { IWidgetKPIConfig } from "../../../domain";
import { getForeignObject } from "../../../domain";
import type { ITableSparklineRendererProps } from "./TableSparklineRenderer";

type IUseTableSparklineConfigOptions = {
  conf: IWidgetKPIConfig;
  query: Query | undefined;
  object: IObject;
};

export function useTableSparklineConfig(
  options: IUseTableSparklineConfigOptions
) {
  const { conf, query, object } = options;
  const [config, setConfig] = useState<
    ITableSparklineRendererProps["config"] | undefined
  >();

  useEffect(() => {
    try {
      if (!conf.tableConfig?.foreignObjectPropertyId) {
        throw new Error("foreignObjectPropertyId must be configured");
      }

      const foreignObject = getForeignObject(
        object,
        conf.tableConfig.foreignObjectPropertyId
      );

      const columns = foreignObject ? getObjectColumns(foreignObject) : [];
      const metric = columns
        .filter(isAvailableMetric)
        .find((ap) => ap.data.key === conf.tableConfig?.metric[0]);
      const dimension = columns
        .filter(isAvailableProperty)
        .find((ap) => ap.data.key === conf.tableConfig?.groupBy[0]);

      if (!metric || !dimension) {
        throw new Error("Metric and dimension must be set");
      }

      const newConfig = {
        x: {
          key: dimension.data.key,
        },
        y: {
          key: metric.data.key,
          formatter: metric.data.formatter,
        },
        options: {
          highlightLabel: conf.tableConfig.highlightLabel,
        },
      };
      if (!_.isEqual(newConfig, config)) {
        setConfig(newConfig);
      }
    } catch (error) {
      console.warn(error);
      if (config) {
        setConfig(undefined);
      }
    }
  }, [config, object, query, conf.tableConfig]);

  return config;
}
