import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Avatar, Button, Card, Flex, Modal, Typography } from "antd";
import { useForm } from "antd/es/form/Form";
import { useState } from "react";
import type { RouteComponentProps } from "react-router";
import { withRouter } from "react-router";
import type { InjectedAntUtilsProps } from "../../../../components/ant-utils/withAntUtils";
import { withAntUtils } from "../../../../components/ant-utils/withAntUtils";
import { compose } from "../../../../components/compose/WlyCompose";
import { SimpleFormModal } from "../../../../components/form/modal/SimpleFormModal";
import { useHover } from "../../../../components/hooks/useHover";
import UserAvatar from "../../../../components/user/avatar/UserAvatar";
import type { ITopic } from "../../../../interfaces/topic";
import { routeDescriptor } from "../../../../routes/routes";
import NotificationTopicUserList from "./NotificationTopicUserList";
import { useUpdateTopic } from "./api/useUpdateTopic";
import { CreateEditTopicForm } from "./forms/CreateEditTopicForm";

interface INotificationTopicCardProps {
  topic: ITopic & { _subscribersMeta?: { count?: number } };
  onSave?: () => void;
}

type Props = INotificationTopicCardProps &
  RouteComponentProps &
  InjectedAntUtilsProps;

function NotificationTopicCard(props: Props) {
  const {
    topic,
    antUtils,
    history,
    match: { params },
    onSave,
  } = props;

  const [subscribersOpen, setSubscribersOpen] = useState<boolean>(false);
  const [editOpen, setEditOpen] = useState<boolean>(false);

  const [hover, isHover] = useHover<HTMLDivElement>();
  const [editTopicForm] = useForm();

  const { mutate, mutateAsync } = useUpdateTopic({
    onSuccess: () => {
      antUtils.message.success("Updated!");
      setEditOpen(false);
      onSave?.();
    },
    onError: () => {
      antUtils.message.error("Error while updating");
    },
  });

  const subscribers = (topic.subscribers ?? []).flatMap((subscriber) =>
    subscriber.user ? [subscriber.user] : []
  );

  return (
    <>
      <Card ref={hover} size="small">
        <Flex justify="space-between" style={{ maxWidth: "100%", height: 22 }}>
          <Flex style={{ minWidth: 0 }}>
            <Typography.Text ellipsis strong style={{ height: 22 }}>
              {topic.name}
            </Typography.Text>
            {(isHover || editOpen || subscribersOpen) && (
              <>
                <Button
                  shape="circle"
                  type="text"
                  size="small"
                  style={{ marginTop: -2, marginLeft: 6 }}
                  icon={<EditOutlined />}
                  onClick={() => setEditOpen(true)}
                />
                <Button
                  shape="circle"
                  type="text"
                  size="small"
                  danger
                  style={{ marginTop: -2, marginLeft: 6 }}
                  icon={<DeleteOutlined />}
                  onClick={() =>
                    antUtils.modal.confirm({
                      title: "Are you sure?",
                      content: `You are about to delete this topic, this can't be undone. Continue?`,
                      okButtonProps: {
                        danger: true,
                      },
                      okText: "Delete",
                      onOk: () =>
                        mutateAsync({
                          variables: {
                            id: topic.id,
                            data: { isDeleted: true },
                          },
                        }).catch(),
                      onCancel: () => {},
                    })
                  }
                />
              </>
            )}
          </Flex>
          <div style={{ maxWidth: 68 }}>
            {topic._subscribersMeta?.count ? (
              <div onClick={() => setSubscribersOpen(true)}>
                <Avatar.Group
                  size={"small"}
                  style={{ cursor: "pointer", paddingLeft: 12 }}
                >
                  {subscribers.slice(0, 2).map((_, index) => (
                    <UserAvatar
                      key={index}
                      size={"small"}
                      user={topic.subscribers?.[index]?.user}
                    />
                  ))}
                  {subscribers.length < topic._subscribersMeta?.count ? (
                    <Avatar
                      size={"small"}
                      style={{
                        color: "#f56a00",
                        backgroundColor: "#fde3cf",
                      }}
                    >
                      +{topic._subscribersMeta.count - 2}
                    </Avatar>
                  ) : undefined}
                </Avatar.Group>
              </div>
            ) : undefined}
          </div>
        </Flex>
      </Card>
      <SimpleFormModal
        open={editOpen}
        onCancel={() => setEditOpen(false)}
        form={editTopicForm}
        isSaving={false}
        modalProps={{
          title: "Update topic group",
        }}
      >
        <CreateEditTopicForm
          form={editTopicForm}
          initialValues={{
            topicId: topic.id,
            topicGroupId: topic.topicGroup?.id,
            name: topic.name,
            apiName: topic.apiName,
          }}
          onSave={(v) =>
            mutate({
              variables: {
                id: topic.id,
                data: { name: v.name, apiName: v.apiName },
              },
            })
          }
        />
      </SimpleFormModal>
      <Modal
        open={subscribersOpen}
        destroyOnClose
        onOk={() => setSubscribersOpen(false)}
        onCancel={() => setSubscribersOpen(false)}
        cancelButtonProps={{
          hidden: true,
        }}
        title={`${topic.name} subscribers`}
        footer={
          <Flex justify="space-between">
            <Button
              onClick={() => {
                history.push(
                  routeDescriptor["settingsAccessManagement"].renderRoute({
                    ...params,
                  })
                );
              }}
            >
              Subscribe other people
            </Button>
            <Button type="primary" onClick={() => setSubscribersOpen(false)}>
              Ok
            </Button>
          </Flex>
        }
      >
        <NotificationTopicUserList topic={topic} open={subscribersOpen} />
      </Modal>
    </>
  );
}

export default compose<Props, INotificationTopicCardProps>(
  withAntUtils,
  withRouter
)(NotificationTopicCard);
