import { CloseOutlined } from "@ant-design/icons";
import { sql as sqlLang } from "@codemirror/lang-sql";
import CodeMirror from "@uiw/react-codemirror";
import { Button, Typography } from "antd";
import React from "react";
import type { RouteComponentProps } from "react-router";
import { withRouter } from "react-router";
import type { InjectedAntUtilsProps } from "../../../components/ant-utils/withAntUtils";
import { withAntUtils } from "../../../components/ant-utils/withAntUtils";
import { compose } from "../../../components/compose/WlyCompose";
import { CatalogGenericModal } from "../../../components/modal/CatalogModal/CatalogGenericModal";
import type { AsyncData } from "../../../helpers/typescriptHelpers";
import type { Transformation } from "../../../interfaces/transformations";
import { getSqlFromTransformations } from "../../../services/BrizoService";
import type { InjectedOrgProps } from "../../orgs/WithOrg";
import WithOrg, { getCurrentWarehouse } from "../../orgs/WithOrg";

interface IFlowToSQLProps {
  onCancel: () => void;
  transformations: Transformation[];
  visible: boolean;
}

type Props = IFlowToSQLProps &
  InjectedAntUtilsProps &
  InjectedOrgProps &
  RouteComponentProps<{ warehouseSlug?: string }>;

function FlowToSQL(props: Props) {
  const {
    onCancel,
    visible,
    antUtils: { message },
    transformations,
    org,
    match,
  } = props;

  const currentWarehouse = getCurrentWarehouse(org, match.params.warehouseSlug);
  const [sql, setSql] = React.useState<AsyncData<string>>({
    status: "initial",
  });

  const convertDataset = async () => {
    try {
      setSql({ status: "loading" });
      const s = await getSqlFromTransformations(
        currentWarehouse?.id,
        transformations
      );
      setSql({ status: "success", data: s.data });
    } catch (err) {
      setSql({ status: "error", error: err });
      message.error("There was an error trying to convert your dataset.");
    }
  };

  React.useEffect(() => {
    if (visible) {
      convertDataset();
    }
  }, [visible]);

  const height = document.body.getBoundingClientRect().height * 0.9 - 64;

  return (
    <CatalogGenericModal onClose={onCancel} open={visible}>
      <div className="catalog-modal-header">
        <div className="catalog-modal-header-title">
          <Typography.Text strong>SQL Transformations</Typography.Text>
        </div>
        <div className="catalog-modal-header-button">
          <Button
            shape="circle"
            type="text"
            onClick={onCancel}
            icon={<CloseOutlined />}
          />
        </div>
      </div>
      {sql.status === "success" ? (
        <CodeMirror
          basicSetup={{
            foldGutter: false,
          }}
          extensions={[sqlLang()]}
          readOnly={true}
          height={`${height}px`}
          value={sql.data}
          autoFocus={true}
        />
      ) : undefined}
    </CatalogGenericModal>
  );
}

export default compose<Props, IFlowToSQLProps>(
  withAntUtils,
  WithOrg,
  withRouter
)(FlowToSQL);
