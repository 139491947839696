import { Form, Input } from "antd";
import * as React from "react";
import EmojiPicker from "../../../../../components/form/emoji-picker/EmojiPicker";
import WlyModal from "../../../../../components/modal/WlyModal";

interface IModelFolderEditFormProps {
  initialData?: ModelFolderEditInitialData;
  onEdit: (v: ModelFolderEditInitialData) => Promise<any>;
  onCreate: (v: ModelFolderEditInitialData) => Promise<any>;
  visible: boolean;
  onCancel: () => any;
}

export interface ModelFolderEditInitialData {
  id?: string;
  name?: string;
  image?: string;
  parentId?: string;
}

export function ModelFolderEditForm(props: IModelFolderEditFormProps) {
  const { onCreate, visible, onCancel, initialData, onEdit } = props;

  const [confirmLoading, setConfirmLoading] = React.useState(false);

  const [form] = Form.useForm();

  React.useEffect(() => {
    if (visible === false) {
      form.resetFields();
      form.setFieldsValue({ name: "", image: "random" });
    }
  }, [visible, form]);

  React.useEffect(() => {
    form.setFieldsValue(initialData);
  }, [initialData]);

  const renderInnerModal = () => {
    return (
      <Form
        initialValues={{
          name: initialData?.name ?? "",
          image: initialData?.image ?? "random",
          parentId: initialData?.parentId ?? null,
        }}
        layout="vertical"
        form={form}
      >
        <Form.Item label="Folder Name">
          <Input.Group compact>
            <Form.Item
              name={["image"]}
              noStyle
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <EmojiPicker />
            </Form.Item>
            <Form.Item
              name={["name"]}
              noStyle
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input
                style={{ width: "Calc(100% - 38px)", marginLeft: 6 }}
                placeholder="Your folder name"
              />
            </Form.Item>
            <Form.Item name={["parentId"]} noStyle>
              <Input hidden />
            </Form.Item>
          </Input.Group>
        </Form.Item>
      </Form>
    );
  };

  return (
    <WlyModal
      open={!!visible}
      title={initialData?.id ? "Update a folder" : "Create a folder"}
      okText={initialData?.id ? "Update" : "Create"}
      cancelText="Cancel"
      onClose={() => {
        form.resetFields();
        onCancel();
      }}
      confirmLoading={confirmLoading}
      onOk={() => {
        setConfirmLoading(true);
        return form
          .validateFields()
          .then((values) => {
            const v = values as ModelFolderEditInitialData;
            if (initialData?.id) {
              return onEdit({
                name: v.name,
                image: v.image,
                id: initialData.id,
              }).then(() => setConfirmLoading(false));
            } else {
              return onCreate({ ...v }).then(() => setConfirmLoading(false));
            }
          })
          .then(() => {
            onCancel();
            return form.resetFields();
          })
          .catch((info) => {
            setConfirmLoading(false);
            console.error("Validate Failed:", info);
          });
      }}
    >
      {renderInnerModal()}
    </WlyModal>
  );
}
