import { Button, Col, Row, Space, Typography } from "antd";
import React from "react";
import type { RouteComponentProps } from "react-router";
import { withRouter } from "react-router";
import { compose } from "../../../../components/compose/WlyCompose";
import Aligner from "../../../../components/layout/aligner/Aligner";
import CardTable from "../../../../components/table/CardTable";
import type { IDestination } from "../../../../interfaces/destinations";
import { IUserRoleType } from "../../../../interfaces/user";
import { routeDescriptor } from "../../../../routes/routes";
import { CatalogModal } from "../../../catalog/CatalogModal";
import type { InjectedOrgProps } from "../../../orgs/WithOrg";
import WithOrg from "../../../orgs/WithOrg";
import { hasRoleAccessBoolean } from "../../../user-settings/HasRoleAccess";

import { ExclamationCircleTwoTone } from "@ant-design/icons";
import "./WarehouseList.scss";

interface IWarehouseListProps {
  destinations: IDestination[];
}

type Props = IWarehouseListProps & InjectedOrgProps & RouteComponentProps<{}>;

function WarehouseList(props: Props) {
  const {
    user,
    role,
    org,
    destinations,
    history,
    match: { params },
  } = props;

  const [catalogOpen, setCatalogOpen] = React.useState<boolean>(false);

  const generateColumns = () => {
    return [
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
        render: (v, s: IDestination) => {
          return (
            <div className="warehouse-list-item">
              <div className="warehouse-list-item-image">
                <img
                  src={s.destinationMeta.publicInfo.logo}
                  alt="warehouse logo"
                />
              </div>
              <div className="warehouse-list-item-description">
                <div className="warehouse-list-item-description-name">
                  <Typography.Text ellipsis strong>
                    {s.name}
                  </Typography.Text>
                </div>
                <div className="warehouse-list-item-description-type">
                  <Typography.Text ellipsis>
                    {s.destinationMeta.publicInfo.name}
                  </Typography.Text>
                </div>
              </div>
              {!s.isBiEnabled ? (
                <div style={{ marginLeft: 4 }}>
                  <ExclamationCircleTwoTone twoToneColor={"red"} />
                </div>
              ) : null}
            </div>
          );
        },
      },
      ...(role.role === IUserRoleType.ADMIN_BUILDER
        ? [
            {
              title: "Actions",
              key: "Actions",
              align: "right" as const,
              render: (v, record) => {
                return (
                  <Space>
                    <Button
                      size="small"
                      disabled={
                        record?.destinationMeta.isWhalyManaged === true &&
                        !user.isAdmin
                      }
                      onClick={() =>
                        history.push(
                          routeDescriptor[
                            "settingsWarehouseDetail"
                          ].renderRoute({
                            ...params,
                            warehouseSlug: record.slug,
                          })
                        )
                      }
                    >
                      Edit
                    </Button>
                  </Space>
                );
              },
            },
          ]
        : []),
    ];
  };

  return (
    <Aligner>
      <Row justify="center" className="warehouse-list">
        <Col xs={24} sm={24} md={20} lg={20} xl={20}>
          <CardTable<IDestination>
            cardTitle={<div className="title">Warehouses</div>}
            actionButtons={
              hasRoleAccessBoolean(
                IUserRoleType.ADMIN_BUILDER,
                user,
                org.id
              ) ? (
                <Space>
                  <Button onClick={() => setCatalogOpen(true)} type="primary">
                    Add a warehouse
                  </Button>
                </Space>
              ) : undefined
            }
            rowKey="id"
            dataSource={destinations}
            columns={generateColumns()}
            pagination={{
              size: "small",
              style: { display: "none" },
              defaultPageSize: 1000,
            }}
          />
        </Col>
      </Row>
      <CatalogModal
        open={catalogOpen}
        onClose={() => setCatalogOpen(false)}
        type="warehouse"
      />
    </Aligner>
  );
}
export default compose<Props, IWarehouseListProps>(
  WithOrg,
  withRouter
)(WarehouseList);
