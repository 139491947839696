import * as React from "react";
import { compose } from "../../../../components/compose/WlyCompose";
import Error from "../../../../components/layout/feedback/error";
import Loading from "../../../../components/layout/feedback/loading";
import type { IAction, IActionMeta } from "../../../../interfaces/actions";
import GraphQl from "../../../graphql/graphql";
import type { InjectedOrgProps } from "../../../orgs/WithOrg";
import WithOrg from "../../../orgs/WithOrg";
import SettingsWrapper from "../../SettingsWrapper";
import ActionManagementTable from "./ActionManagementTable";

interface IActionManagementPageProps {}

const gql = `
query getInstalledAction(
  $orgId: ID!
) {
  allActions(where: {org: {id: $orgId}, isDeleted_not: true}) {
    id
    slug
    name
    actionMeta
    createdBy {
      firstName
      lastName
      gravatar
      avatarColor
    }
    createdAt
  }
  allActionMetas {
    slug
    publicInfo {
      logo
    }
  }
}
`;

interface IReturnValue {
  allActions: IAction[];
  allActionMetas: IActionMeta[];
}

type Props = IActionManagementPageProps & InjectedOrgProps;

class ActionManagementPage extends React.Component<Props> {
  public render() {
    const { org } = this.props;

    return (
      <SettingsWrapper>
        <GraphQl<IReturnValue> query={gql} variables={{ orgId: org.id }}>
          {(gql) => {
            if (gql.status === "loading" || gql.status === "initial") {
              return <Loading />;
            }
            if (gql.status === "error") {
              return <Error />;
            }
            return (
              <ActionManagementTable
                actions={gql.data.allActions}
                actionMetas={gql.data.allActionMetas}
              />
            );
          }}
        </GraphQl>
      </SettingsWrapper>
    );
  }
}

export default compose<Props, IActionManagementPageProps>(WithOrg)(
  ActionManagementPage
);
