import type { NodeModelGenerics } from "@projectstorm/react-diagrams";
import { NodeModel, PortModelAlignment } from "@projectstorm/react-diagrams";
import { SimplePortModel } from "../port/SimplePortModel";
import { SIZE } from "./OutputNodeWidget";

interface OutputNodeModelGenerics {
  PORT: SimplePortModel;
}

export class OutputNodeModel extends NodeModel<
  NodeModelGenerics & OutputNodeModelGenerics
> {
  constructor() {
    super({
      type: "output",
    });
    const port = new SimplePortModel(PortModelAlignment.LEFT);
    port.setDirection("in");
    port.setMaximumLinks(1);
    this.addPort(port);

    this.width = SIZE;
    this.height = SIZE;
  }

  getPort = (name: string): SimplePortModel => {
    return super.getPort(name) as SimplePortModel;
  };

  getPrimaryInPort = () => {
    return this.getPort(PortModelAlignment.LEFT);
  };

  getSecondaryInPort = () => {
    return undefined;
  };

  getOutPort = () => {
    return undefined;
  };
}
