import type { Identifier } from "dnd-core";
import React, { useRef } from "react";
import { useDrag, useDrop } from "react-dnd";
import type { IFilter } from "../../../../../interfaces/reports";
import type { IFieldFilterProps } from "./FieldFilter";
import FieldFilter from "./FieldFilter";

interface IDraggableFielFilterProps extends IFieldFilterProps {
  filterId: string;
  filters: IFilter[];
  onDrop: (filters: IFilter[]) => void;
  onDrag: (id: string, dragging: boolean) => void;
}

export function DraggableFielFilter(props: IDraggableFielFilterProps) {
  const { filterId, onDrop, filters, onDrag, ...rest } = props;
  const ref = useRef<HTMLDivElement>(null);
  const [dropDirection, setDropDirection] = React.useState<"LEFT" | "RIGHT">(
    "LEFT"
  );
  const currentIndex = filters.findIndex((d) => d.id === filterId);

  const [{ isDragging, draggedItem }, drag] = useDrag({
    type: "REPORT_FILTER",
    item: () => {
      return {
        filterId: filterId,
      };
    },
    collect: (monitor) => {
      onDrag(filterId, monitor.isDragging());

      return {
        isDragging: monitor.isDragging(),
        draggedItem: monitor.getItem(),
      };
    },
  });

  const [{ isOver }, drop] = useDrop<
    { filterId: string },
    void,
    { handlerId: Identifier | null; isOver: boolean }
  >({
    accept: "REPORT_FILTER",
    collect: (monitor) => {
      return {
        handlerId: monitor.getHandlerId(),
        isOver: monitor.isOver(),
      };
    },
    hover: (item) => {
      const draggedIndex = filters.findIndex((f) => f.id === item.filterId);
      setDropDirection(draggedIndex - currentIndex <= 0 ? "RIGHT" : "LEFT");
    },
    drop: (item, monitor) => {
      const droppingFilter = filters.find((f) => f.id === item.filterId);
      if (droppingFilter && droppingFilter.id !== filterId) {
        const reOrderedFilters = filters
          .flatMap((f) => {
            if (f.id === filterId) {
              if (dropDirection === "LEFT") {
                return [droppingFilter, f];
              } else {
                return [f, droppingFilter];
              }
            } else if (f.id === droppingFilter.id) {
              return [];
            } else {
              return [f];
            }
          })
          .map((f, i) => {
            return {
              ...f,
              order: i,
            };
          });
        onDrop(reOrderedFilters);
      }
    },
  });

  drag(drop(ref));

  return (
    <div
      style={{ opacity: isDragging ? 0.5 : 1, position: "relative" }}
      ref={ref}
    >
      {dropDirection === "LEFT" ? (
        <div
          style={{
            transition: "opacity 0.2s",
            position: "absolute",
            top: 0,
            bottom: 0,
            left: -6,
            width: 1,
            backgroundColor: "black",
            opacity:
              isOver && draggedItem && draggedItem.filterId !== filterId
                ? 100
                : 0,
          }}
        />
      ) : null}
      <FieldFilter {...rest} />
      {dropDirection === "RIGHT" ? (
        <div
          style={{
            transition: "opacity 0.2s",
            position: "absolute",
            top: 0,
            bottom: 0,
            right: -6,
            width: 1,
            backgroundColor: "black",
            opacity:
              isOver && draggedItem && draggedItem.filterId !== filterId
                ? 100
                : 0,
          }}
        />
      ) : null}
    </div>
  );
}
