import type {
  BinaryFilter,
  ResultSet,
  SerializedResult,
  UnaryFilter,
} from "@cubejs-client/core";
import moment from "moment";
import type { ISerieData } from "../../../../../components/chart/domain";
import { getSelectedPalette } from "../../../../../components/palette/utils/paletteData";
import type { IOrg } from "../../../../../interfaces/org";
import type { ChartOption } from "../../../../chart-options/ChartOptions";
import type { IAnalysisType, ILagoonQuery } from "../../domain";

// we clean the resultSet to pretend we never modified the query for sorting
export const cleanSerializedResultSetFromCustomSorting = (
  resultSet: SerializedResult
) => {
  const tmp = resultSet;

  const cleanKeys = (obj: { [key: string]: any }) => {
    const keys = Object.keys(obj).filter((k) => !k.endsWith("__sort"));
    const acc = {};
    keys.forEach((k) => {
      acc[k] = obj[k];
    });
    return acc;
  };

  tmp.loadResponse.pivotQuery.dimensions = (
    tmp.loadResponse.pivotQuery.dimensions ?? []
  ).filter((dim) => !dim.endsWith("__sort"));

  if (tmp.loadResponse.pivotQuery.order instanceof Array) {
    tmp.loadResponse.pivotQuery.order = (
      (tmp.loadResponse.pivotQuery.order ?? []) as unknown as {
        id: string;
        desc: boolean;
      }[]
    )?.map((o) => ({ ...o, id: o.id.split("__sort")[0] })) as any;
  }

  tmp.loadResponse.results = tmp.loadResponse.results.map((result) => {
    return {
      ...result,
      annotation: {
        ...result.annotation,
        dimensions: cleanKeys(result.annotation.dimensions),
      },
      data: result.data.map((row) => {
        const obj: { [key: string]: string | number | boolean } = {};
        Object.keys(row).forEach((key) => {
          if (!key.endsWith("__sort")) {
            obj[key] = row[key];
          }
        });
        return obj;
      }),
      query: {
        ...result.query,
        order:
          result.query.order instanceof Array
            ? ((
                (result.query.order ?? []) as unknown as {
                  id: string;
                  desc: boolean;
                }[]
              )?.map((o) => ({ ...o, id: o.id.split("__sort")[0] })) as any)
            : undefined,
        dimensions: (result.query.dimensions ?? []).filter(
          (dim) => !dim.endsWith("__sort")
        ),
      },
    };
  });
  return tmp;
};

export const canDrill = (
  resultSet: ResultSet,
  serie: ISerieData,
  xKey: string,
  analysisType: IAnalysisType,
  lagoonQuery: ILagoonQuery
) => {
  const getPivot = (r: ResultSet) => {
    const pivot = r.normalizePivotConfig();
    const { x, y } = pivot;
    const formattedPivot = {
      x: x.filter((v) => v !== "compareDateRange" && v !== "measures"),
      y: y.filter((v) => v !== "compareDateRange"),
    };

    // const pivotingValue = serie.dimensions.filter(a => (pivotConfig || []).includes(a.key));

    let xValues: string[] = [];
    let yValues: string[] = [];

    (serie.dimensions || []).map((dim) => {
      if (x.includes(dim.key)) {
        xValues.push(dim.value);
      }
      if (y.includes(dim.key)) {
        yValues.push(dim.value);
      }
    });

    let formattedXKey = xKey
      .split(",")
      .filter((r) => r !== "previous" && r !== "current");

    if (
      analysisType === "TIME" &&
      serie.previous &&
      lagoonQuery.selectedGranularity
    ) {
      // in case of time series and comparison we need to subtract the comparison granularity to get the proper date
      formattedPivot.x.forEach((fp, i) => {
        const [cubeName, dimension, granularity] = fp.split(".");
        if (granularity) {
          // we found the index for the time we can down tune it
          formattedXKey = formattedXKey.map((_, index) => {
            if (index === i) {
              return moment(formattedXKey[i])
                .subtract(1, lagoonQuery.selectedGranularity)
                .format("YYYY-MM-DDTHH:mm:ss.SSS");
            }
            return _;
          });
        }
      });
    }

    const removeWierdValues = (v: string) => {
      if (v === "∅") {
        return null;
      } else if (v === "[Empty string]") {
        return "";
      }
      return v;
    };

    const p = {
      xValues: [...xValues, ...formattedXKey].map(removeWierdValues),
      yValues: [...yValues, serie.metric.key].map(removeWierdValues),
    };

    return {
      drills: p,
      pivot: formattedPivot,
    };
  };

  // we need to decompose queries for date comparison as drill is not supported
  if ((resultSet as any).queryType === "compareDateRangeQuery") {
    const queries = (resultSet as any).decompose();

    if (serie.previous) {
      const { drills, pivot } = getPivot(queries[1]);
      return queries[1].drillDown(drills, pivot);
    } else {
      const { drills, pivot } = getPivot(queries[0]);
      // doing so because cube adds a compareDateRange filter to compare query
      const q = queries[0].drillDown(drills, pivot);
      return {
        ...q,
        filters: (q.filters || []).filter((a) => {
          return (
            (a as BinaryFilter | UnaryFilter).member !== "compareDateRange"
          );
        }),
      };
    }
  }

  const { drills, pivot } = getPivot(resultSet);
  return resultSet.drillDown(drills, pivot);
};

export const formatChartOptions = (org: IOrg, chartOptions?: ChartOption) => {
  if (!chartOptions) {
    return undefined;
  }
  const copiedChartOptions = { ...chartOptions };
  if (copiedChartOptions.palette) {
    copiedChartOptions.palette = getSelectedPalette(org, chartOptions.palette);
  }
  if (copiedChartOptions.series) {
    copiedChartOptions.series = Object.keys(copiedChartOptions.series)
      .filter((f) => !!f && !!copiedChartOptions.series[f])
      .reduce((acc, v) => {
        return {
          ...acc,
          [v]: {
            ...copiedChartOptions.series[v],
            palette: copiedChartOptions.series[v].palette
              ? getSelectedPalette(org, copiedChartOptions.series[v].palette)
              : undefined,
          },
        };
      }, {});
  }
  return copiedChartOptions;
};
