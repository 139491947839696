import data from "@emoji-mart/data";
import { init } from "emoji-mart";

init({ data });

interface IEmojiProps {
  emoji: string;
  size: string | number;
}

export function Emoji(props: IEmojiProps) {
  return (
    <span
      style={{
        height: "100%",
        display: "flex",
        alignItems: "center",
      }}
    >
      <em-emoji shortcodes={props.emoji} size={props.size} />
    </span>
  );
}
