import { Col, Row } from "antd";
import * as React from "react";
import { compose } from "../../../../components/compose/WlyCompose";
import Aligner from "../../../../components/layout/aligner/Aligner";
import Feednack from "../../../../components/layout/feedback/feedback";
import type { InjectedOrgProps } from "../../../orgs/WithOrg";
import WithOrg from "../../../orgs/WithOrg";

import { inject, observer } from "mobx-react";
import SourceTableSelectionCard from "../../../../components/explorations/SourceTableSelectionCard";
import type {
  IDataset,
  IDatasetRelationship,
} from "../../../../interfaces/sources";
import type { SchemaResult } from "../../../../interfaces/transformations";
import type { IView } from "../../../../interfaces/view";
import type { WorkbenchUIStoreProps } from "../../../../store/workbenchUIStore";
import type {
  IDimensionFormInput,
  IMeasureTable,
  IMetricFormInput,
} from "../../../workbench/workbench/exploration/domain";
import ViewSelector from "../../views/ViewSelector";
import "./RelatedDatasetSelection.scss";
import SchemaSelectionStep from "./SchemaSelectionStep";

interface IRelatedDataSelectionProps {
  selectedTable: IMeasureTable;
  allDatasetRelationships: IDatasetRelationship[];
  allDatasets: IDataset[];
  onSelect: (
    fromTableId: string,
    relationship: IDatasetRelationship,
    toDataset: IDataset,
    toView: IView,
    metrics?: IMetricFormInput[],
    dimensions?: IDimensionFormInput[]
  ) => void;
  fetchSchema: (
    tablesSchemas: Array<{ datasetId: string; viewId: string }>
  ) => Promise<SchemaResult[]>;
}

type Props = InjectedOrgProps &
  IRelatedDataSelectionProps &
  WorkbenchUIStoreProps;

interface IState {
  step: IDatasetSelection | IViewSelection | ISchemaSelection;
  submitting: boolean;
}

interface IDatasetSelection {
  step: "DATASET_SELECTION";
}

interface IViewSelection {
  step: "VIEW_SELECTION";
  data: {
    fromTableId: string;
    relationship: IDatasetRelationship;
    toDataset: IDataset;
  };
}

interface ISchemaSelection {
  step: "SCHEMA_SELECTION";
  data: {
    viewId?: string;
    fromTableId: string;
    relationship: IDatasetRelationship;
    toDataset: IDataset;
  };
}

class RelatedDataSelection extends React.Component<Props, IState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      step: {
        step: "DATASET_SELECTION",
      },
      submitting: false,
    };
  }

  public render() {
    const {
      org,
      selectedTable,
      onSelect,
      allDatasetRelationships,
      allDatasets,
      workbenchUIStore: { pushActiveObject },
      fetchSchema,
    } = this.props;

    const { step } = this.state;

    const renderInner = () => {
      const addedDatasetIds = {
        fromTableId: selectedTable.id,
        fromTabelLabel: selectedTable.name,
        fromDataset: selectedTable.datasetId,
        relationships: allDatasetRelationships
          .filter((dr) => {
            return (
              dr.left.id === selectedTable.datasetId ||
              dr.right.id === selectedTable.datasetId
            );
          })
          .map((r) => {
            if (r.left.id === selectedTable.datasetId) {
              return r;
            } else {
              return {
                id: r.id,
                left: {
                  id: selectedTable.datasetId,
                  name: selectedTable.name,
                  primaryKey: r.right.primaryKey,
                },
                right: {
                  id: r.left.id,
                  name: r.left.name,
                  primaryKey: r.left.primaryKey,
                },
                from: r.to,
                to: r.from,
                type:
                  r.type === "1-1" ? "1-1" : r.type === "1-N" ? "N-1" : "1-N",
              } as IDatasetRelationship;
            }
          }),
      };

      if (step.step === "DATASET_SELECTION") {
        if (allDatasetRelationships.length === 0 || allDatasets.length === 0) {
          return (
            <Aligner>
              <Feednack>
                <div>
                  <div>
                    This table doesn't seem to have any relationships, please
                    configure them{" "}
                    <a
                      onClick={() =>
                        pushActiveObject({
                          type: "dataset",
                          value: selectedTable.datasetId,
                        })
                      }
                    >
                      on the dataset
                    </a>
                    .
                  </div>
                </div>
              </Feednack>
            </Aligner>
          );
        }

        return (
          <>
            <div className="related-dataset-selection-explaination">
              By adding data related to {selectedTable.name} you'll be able to
              create query that involves any records linked to a single{" "}
              {selectedTable.name}. If you don't see the data you want to add,
              you might want to consider creating a relationship in the
              workbench.
            </div>
            <div className="content">
              <Row gutter={[16, 16]}>
                {addedDatasetIds.relationships.map((r, i) => {
                  const currentDataset = allDatasets.find(
                    (d) => d.id === r.right.id
                  );
                  if (!currentDataset) {
                    return (
                      <Col span={12} key={i}>
                        Missing dataset...
                      </Col>
                    );
                  }
                  const hasSeveralRelationshipsToSameDataset =
                    addedDatasetIds.relationships.filter(
                      (dr) => dr.right.id === r.right.id
                    ).length > 1;
                  return (
                    <Col key={`${r.left.id}-${r.right.id}`} span={8}>
                      <SourceTableSelectionCard
                        onClick={() =>
                          currentDataset.views.length === 1
                            ? this.setState({
                                step: {
                                  step: "SCHEMA_SELECTION",
                                  data: {
                                    viewId: currentDataset.views[0].id,
                                    fromTableId: addedDatasetIds.fromTableId,
                                    relationship: r,
                                    toDataset: currentDataset,
                                  },
                                },
                              })
                            : this.setState({
                                step: {
                                  step: "VIEW_SELECTION",
                                  data: {
                                    fromTableId: addedDatasetIds.fromTableId,
                                    relationship: r,
                                    toDataset: currentDataset,
                                  },
                                },
                              })
                        }
                        isModel={currentDataset.isModel}
                        name={currentDataset.name}
                        primaryKey={currentDataset.primaryKey}
                        sourceName={currentDataset.source?.name}
                        logo={
                          currentDataset.source?.sourceMeta?.publicInfo?.logo
                        }
                      >
                        <div style={{ opacity: 0.7 }}>
                          A {r.left.name}{" "}
                          {r.type === "1-1"
                            ? "has one"
                            : r.type === "1-N"
                            ? "has many"
                            : "belongs to"}{" "}
                          {r.right.name}{" "}
                          {hasSeveralRelationshipsToSameDataset ? (
                            <span>
                              (using {r.from} &rarr; {r.to})
                            </span>
                          ) : null}
                        </div>
                      </SourceTableSelectionCard>
                    </Col>
                  );
                })}
              </Row>
            </div>
          </>
        );
      }

      if (step.step === "VIEW_SELECTION") {
        const form = step.data;
        return (
          <div style={{ width: "100%" }} className="view-selector">
            <ViewSelector
              views={form.toDataset.views.map((v) => {
                return {
                  key: v.id,
                  label: v.name,
                  editable: false,
                  viewQueryCursor: "",
                  query: [],
                  viewCubeName: v.cubeName,
                  usedInExplorations: [],
                  drills: [],
                };
              })}
              selectedViewId={
                form.toDataset.views.find((v) => v.default === true)
                  ? form.toDataset.views.find((v) => v.default === true)!.id
                  : undefined
              }
              onSelectView={(viewId) =>
                form.toDataset.views.find((vi) => vi.id === viewId) &&
                this.setState({
                  step: {
                    step: "SCHEMA_SELECTION",
                    data: {
                      viewId: viewId,
                      fromTableId: form.fromTableId,
                      relationship: form.relationship,
                      toDataset: form.toDataset,
                    },
                  },
                })
              }
            >
              <div style={{ paddingBottom: 12 }}>
                Which view do you to use for {form.toDataset.name} ?
              </div>
            </ViewSelector>
          </div>
        );
      }

      return (
        <>
          <div className="related-dataset-selection-explaination">
            Choose how to import your dataset:
          </div>
          <div className="content">
            <SchemaSelectionStep
              fetchSchema={() =>
                fetchSchema([
                  {
                    datasetId: step.data.toDataset.id,
                    viewId: step.data.viewId,
                  },
                ])
              }
              onSelect={async (m, d) => {
                return onSelect(
                  step.data.fromTableId,
                  step.data.relationship,
                  step.data.toDataset,
                  step.data.toDataset.views.find(
                    (v) => v.id === step.data.viewId
                  )!,
                  m,
                  d
                );
              }}
            />
          </div>
        </>
      );
    };

    return <div className="related-dataset-selection">{renderInner()}</div>;
  }
}

export default compose<Props, IRelatedDataSelectionProps>(
  WithOrg,
  inject("workbenchUIStore"),
  observer
)(RelatedDataSelection);
