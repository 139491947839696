import { Tag } from "@lezer/highlight";
import type { DelimiterType, MarkdownConfig } from "@lezer/markdown";

export const markdocTags = {
  markdocTag: Tag.define(),
  markdocString: Tag.define(),
};

const MarkdocDelimiter: DelimiterType = {
  resolve: "MarkdocTag",
  mark: "MarkdocTag",
};

const MarkdocStringDelimiter: DelimiterType = {
  resolve: "MarkdocString",
  mark: "MarkdocString",
};

const MarkdocExtention: MarkdownConfig = {
  defineNodes: [
    { name: "MarkdocTag", block: true, style: markdocTags.markdocTag },
    { name: "MarkdocString", block: true, style: markdocTags.markdocString },
  ],
  parseInline: [
    {
      name: "MarkdocTag",
      parse(cx, next, pos) {
        /* {% some markdoc input %} */
        /* 123 == unicode { */
        /* 125 == unicode } */
        /*  37 == unicode % */
        /*  34 == unicode " */
        if (next == 123 && cx.char(pos + 1) == 37) {
          return cx.addDelimiter(MarkdocDelimiter, pos, pos + 2, true, false);
        } else if (next == 37 && cx.char(pos + 1) == 125) {
          return cx.addDelimiter(MarkdocDelimiter, pos, pos + 2, false, true);
        }
        return -1;
      },
    },
    {
      name: "MarkdocString",
      parse(cx, next, pos) {
        if (
          next == 34 &&
          typeof cx.findOpeningDelimiter(MarkdocDelimiter) === "number"
        ) {
          return cx.addDelimiter(
            MarkdocStringDelimiter,
            pos,
            pos + 1,
            true,
            true
          );
        }
        return -1;
      },
      after: "Emphasis",
    },
  ],
};

export default MarkdocExtention;
