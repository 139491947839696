import * as React from "react";
import "./feedback.scss";
import { LoadingOutlined } from "@ant-design/icons";

interface ILoadingProps {}

export default class Loading extends React.Component<ILoadingProps> {
  public render() {
    return (
      <div className="feedback">
        <LoadingOutlined style={{ fontSize: "32px" }} />
      </div>
    );
  }
}
