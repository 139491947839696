import { Select, Space, Typography } from "antd";
import type { IDataset } from "../../interfaces/sources";
import { SourceImageRenderer } from "../sources/SourceImageRenderer";

export interface IDatasetSelectorProps {
  datasets: IDataset[];
  value?: string;
  onChange?: (v: string) => void;
}

export function DatasetSelector(props: IDatasetSelectorProps) {
  const { value, onChange, datasets } = props;
  return (
    <Select
      style={{ width: "100%" }}
      showSearch
      optionFilterProp="label"
      value={value}
      onChange={onChange}
    >
      {datasets
        .filter((d) => d.views?.length)
        .map((v) => {
          return (
            <Select.Option key={v.id} value={v.id} label={v.name}>
              <Space>
                <SourceImageRenderer
                  alt={"sourceName"}
                  className="source-table-selection-logo"
                  img={
                    !v.isModel ? v.source.sourceMeta.publicInfo.logo : undefined
                  }
                  size={16}
                  isModel={v.isModel}
                />
                {v.name}
                <Typography.Text type="secondary">
                  {v.isModel ? "Model" : v.source?.name}
                </Typography.Text>
              </Space>
            </Select.Option>
          );
        })}
    </Select>
  );
}
