import type { Query } from "@cubejs-client/core";
import * as turf from "@turf/turf";
import _, { uniqueId } from "lodash";
import mapboxgl from "mapbox-gl";
import MapboxGLWorker from "mapbox-gl/dist/mapbox-gl-csp-worker?worker";
import "mapbox-gl/dist/mapbox-gl.css";
import React, { useEffect, useMemo } from "react";
import type { ChartOption } from "../../../containers/chart-options/ChartOptions";
import type { IPaletteCollection } from "../../../interfaces/org";
import type { IDimensionType } from "../../../interfaces/table";
import type { UserLocale } from "../../../interfaces/user";
import { getFontColorFromBackground } from "../../../utils/colorUtils";
import usePrevious from "../../hooks/usePrevious";
import type { Formatter } from "../domain";
import "./Map.scss";

(mapboxgl as any).workerClass = MapboxGLWorker;
(mapboxgl as any).accessToken =
  "pk.eyJ1IjoiZXNhbmNoZXoxNDMiLCJhIjoiY2w0cXp0cHM3MGJ3bzNlazJhdm5lNGxjayJ9.FZi7Vst4XWV2HS18PPUFrQ";

interface IMapboxMarkerChartProps {
  height: number;
  data: Array<{ [key: string]: string | number | boolean }>;
  config: MapboxMarkerChartConfig;
  width?: number;
  onDrill?: (q: Query) => void;
  chartOptions?: ChartOption;
  locale: UserLocale;
  defaultCollection: IPaletteCollection;
  removeMargin?: boolean;
}

interface MapboxMarkerChartConfig {
  x: {
    key: string;
    type: IDimensionType;
    label?: string;
    color?: string;
  };
  y: {
    key: string;
    label?: string;
    canDrill: (xValue: string, yValue?: string) => Query | null;
    formatter: Formatter;
  };
}

const navigation = new mapboxgl.NavigationControl({
  showCompass: false,
  visualizePitch: false,
});

interface IFormatedData {
  lat: number;
  long: number;
  value: number;
  name?: string | number | boolean;
  color?: string;
}

const convertToLatLong = (s: string) => {
  if (typeof s === "string" && s.includes(",")) {
    const [lat, long] = s.split(",");
    if (lat && long) {
      try {
        return {
          lat: parseFloat(lat),
          long: parseFloat(long),
        };
      } catch (err) {
        console.error(err);
        return;
      }
    }
  }
  return;
};

const MapboxMarkerChart: React.FunctionComponent<IMapboxMarkerChartProps> = (
  props
) => {
  const {
    width,
    height,
    data,
    config,
    chartOptions,
    defaultCollection,
    removeMargin,
  } = props;

  const mapContainer = React.useRef(null);
  const map = React.useRef<mapboxgl.Map>(null);
  const prevChartOptions = usePrevious(chartOptions);
  const prevData = usePrevious(data);

  const defaultColor =
    chartOptions?.series?.[config.y.key]?.color ??
    defaultCollection.categorical.find((p) => p.type === "discrete")
      ?.colors?.[0];

  const getGeoJSON = (): mapboxgl.GeoJSONSourceRaw => {
    const series = Object.keys(
      chartOptions?.series ? chartOptions?.series : {}
    );
    const formattedData: IFormatedData[] = data
      .map((d) => {
        const coordinates = convertToLatLong(d[config.x.key] as string);
        let color = defaultColor;
        if (config.x.color) {
          let dimValue = (
            d[config.x.color] ? d[config.x.color] : "∅"
          ).toString();
          if (chartOptions?.series?.[dimValue]?.color) {
            color = chartOptions?.series?.[dimValue]?.color;
          } else if (series.findIndex((v) => v === d[config.x.color]) > -1) {
            color = defaultCollection.categorical.find(
              (p) => p.type === "discrete"
            )?.colors?.[series.findIndex((v) => v === d[config.x.color])];
          }
        }
        if (coordinates) {
          return {
            lat: coordinates.lat,
            long: coordinates.long,
            value: !d[config.y.key] ? 0 : parseFloat(d[config.y.key] as string),
            name: d[config.x.label] ? d[config.x.label] : null,
            color: color,
          };
        } else {
          return null;
        }
      })
      .filter((d) => d);

    return {
      type: "geojson",
      data: {
        type: "FeatureCollection",
        features: formattedData.flatMap((d, i) => {
          const id = uniqueId();
          return [
            {
              type: "Feature",
              geometry: {
                type: "Point",
                coordinates: [d.long, d.lat],
              },
              id: `point-${id}`,
              properties: {
                value: d.value,
                lat: d.lat,
                long: d.long,
                color: d.color,
                description: d.name,
              },
            },
          ];
        }),
      },
      cluster:
        chartOptions?.["interractive-map-pin-cluster-enabled"] === true
          ? true
          : false,
      clusterMaxZoom: 14,
      clusterRadius: 50,
    };
  };

  const getMapPosition = (geojsonData: mapboxgl.GeoJSONSourceRaw) => {
    let mapInitialPosition: Partial<
      Pick<
        mapboxgl.MapboxOptions,
        "bounds" | "fitBoundsOptions" | "center" | "zoom"
      >
    >;
    if (chartOptions?.["interractive-map-position"]?.type === "custom") {
      const lat = chartOptions?.["interractive-map-position"]?.position?.lat
        ? parseFloat(chartOptions?.["interractive-map-position"]?.position?.lat)
        : 0;
      const lng = chartOptions?.["interractive-map-position"]?.position?.lng
        ? parseFloat(chartOptions?.["interractive-map-position"]?.position?.lng)
        : 0;
      mapInitialPosition = {
        center: [lng, lat],
        zoom: chartOptions?.["interractive-map-position"]?.position?.zoomLevel
          ? parseFloat(
              chartOptions?.["interractive-map-position"]?.position?.zoomLevel
            )
          : 1,
      };
    } else if (!(geojsonData.data as any)?.features.length) {
      mapInitialPosition = {
        zoom: 1,
      };
    } else {
      const bounds = new mapboxgl.LngLatBounds();
      (
        geojsonData.data as GeoJSON.FeatureCollection<GeoJSON.Geometry>
      ).features.forEach((d) => {
        const bbox = turf.bbox(d.geometry);
        bounds.extend(bbox as [number, number, number, number]);
      });

      mapInitialPosition = {
        bounds: bounds,
        fitBoundsOptions: {
          padding: 25,
        },
      };
    }
    return mapInitialPosition;
  };

  useEffect(() => {
    if (map.current) return;
    const geojsonData = getGeoJSON();
    const mapInitialPosition = getMapPosition(geojsonData);

    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: "mapbox://styles/esanchez143/cl4r4nbwu000c14l8xj43b1b7",
      attributionControl: false,
      interactive: true,
      ...mapInitialPosition,
    });

    if (
      !chartOptions?.["interractive-map-disable-zoom"] &&
      !map.current.hasControl(navigation)
    ) {
      map.current.addControl(navigation, "top-left");
      map.current.scrollZoom.enable();
    }

    map.current.on("load", () => {
      map.current.addSource("data", geojsonData);

      map.current.addLayer({
        id: "points-clusters-effect",
        type: "circle",
        source: "data",
        filter: ["all", ["==", "$type", "Point"], ["has", "point_count"]],
        paint: {
          "circle-color": defaultColor,
          "circle-opacity": 0.3,
          "circle-radius": [
            "step",
            ["get", "point_count"],
            25,
            100,
            35,
            750,
            45,
          ],
        },
      });

      map.current.addLayer({
        id: "points-clusters",
        type: "circle",
        source: "data",
        filter: ["all", ["==", "$type", "Point"], ["has", "point_count"]],
        paint: {
          "circle-color": defaultColor,
          "circle-opacity": 0.8,
          "circle-radius": [
            "step",
            ["get", "point_count"],
            20,
            100,
            30,
            750,
            40,
          ],
        },
      });

      map.current.addLayer({
        id: "points-cluster-count",
        type: "symbol",
        source: "data",
        filter: ["all", ["==", "$type", "Point"], ["has", "point_count"]],
        layout: {
          "text-field": ["get", "point_count_abbreviated"],
          "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
          "text-size": 12,
        },
        paint: {
          "text-color": getFontColorFromBackground(defaultColor),
        },
      });

      map.current.addLayer({
        id: "points",
        type: "circle",
        source: "data",
        filter: ["!", ["has", "point_count"]],
        paint: {
          "circle-radius": {
            base: 5,
            stops: [
              [12, 5],
              [22, 180],
            ],
          },
          "circle-color": ["get", "color"],
          "circle-stroke-width": 1,
          "circle-stroke-color": "#fff",
        },
      });

      if (props.onDrill) {
        map.current.on("click", "points", (e) => {
          const latlong = [
            e.features[0].properties.lat.toString(),
            e.features[0].properties.long.toString(),
          ].join(",");
          let q = props.config?.y?.canDrill?.(latlong);
          if (config.x.label) {
            // if we have a label or color dimension we have to make sure to exclude it from the drills filters
            q = {
              ...q,
              filters: q.filters.filter((f: any) => {
                if (f.member) {
                  if (f.member === config.x.label) {
                    return false;
                  }
                  if (f.member === config.x.color) {
                    return false;
                  }
                  return true;
                } else {
                  return true;
                }
              }),
            };
          }
          if (typeof q !== "object") return;
          const newQ = {
            ...q,
            filters: q.filters?.map((f: any) => {
              if (f.member === props.config?.x?.key) {
                return {
                  ...f,
                  values: [latlong],
                };
              } else {
                return f;
              }
            }),
          };
          if (newQ) {
            props.onDrill?.(newQ);
          }
        });
      }

      const popup = new mapboxgl.Popup({
        closeButton: false,
        closeOnClick: false,
      });

      map.current.on("mouseenter", "points", (e) => {
        map.current.getCanvas().style.cursor = "pointer";
        if (config.x.label) {
          // Copy coordinates array.
          const coordinates = (
            e.features[0].geometry as any
          ).coordinates.slice();
          const description = e.features[0].properties.description;

          // Ensure that if the map is zoomed out such that multiple
          // copies of the feature are visible, the popup appears
          // over the copy being pointed to.
          while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
            coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
          }

          popup
            .setLngLat(coordinates)
            .setHTML(`${description?.split("\\n").join("<br />")}`)
            .addTo(map.current);
        }
      });

      let hoverID = null;

      map.current.on("mouseleave", "points", (e) => {
        map.current.getCanvas().style.cursor = "";
        if (config.x.label) {
          popup.remove();
        }
        if (hoverID) {
          map.current.setFeatureState(
            {
              source: "data",
              id: hoverID,
            },
            {
              hover: false,
            }
          );
        }

        hoverID = null;
      });

      map.current.on("mousemove", "points", (e) => {
        const features = map.current.queryRenderedFeatures(e.point, {
          layers: ["points-clusters"],
        });
        if (!features?.[0]?.id) return;
        if (hoverID) {
          map.current.removeFeatureState({
            source: "data",
            id: hoverID,
          });
        }
        hoverID = e.features[0].id;
        map.current.setFeatureState(
          { source: "data", id: hoverID },
          { hover: true }
        );
      });

      map.current.on("mouseenter", "points-clusters", () => {
        map.current.getCanvas().style.cursor = "pointer";
      });
      map.current.on("mouseleave", "points-clusters", () => {
        map.current.getCanvas().style.cursor = "";
      });

      map.current.on("click", "points-clusters", (e) => {
        const features = map.current.queryRenderedFeatures(e.point, {
          layers: ["points-clusters"],
        });
        const clusterId = features[0].properties.cluster_id;
        const source = map.current.getSource("data");
        if (source.type === "geojson") {
          source.getClusterExpansionZoom(clusterId, (err, zoom) => {
            if (err) return;

            map.current.easeTo({
              center: (features[0].geometry as any).coordinates,
              zoom: zoom,
            });
          });
        }
      });

      map.current.triggerRepaint();
    });
  });

  const debouncedResizeMap = useMemo(
    () =>
      _.debounce(() => {
        map.current?.resize?.();
      }, 200),
    []
  );

  useEffect(() => {
    debouncedResizeMap();
  }, [width, height]);

  useEffect(() => {
    let shouldUpdateData = false;

    if (!_.isEqual(chartOptions?.series, prevChartOptions?.series)) {
      shouldUpdateData = true;
    }

    if (!_.isEqual(data, prevData)) {
      shouldUpdateData = true;
    }

    if (shouldUpdateData) {
      const source = map.current?.getSource("data");
      if (source && source.type === "geojson") {
        source.setData(getGeoJSON().data as any);
      }
    }

    if (
      !_.isEqual(
        chartOptions?.["interractive-map-pin-cluster-enabled"],
        prevChartOptions?.["interractive-map-pin-cluster-enabled"]
      )
    ) {
      if (map.current?.getSource("data")) {
        const style = map.current?.getStyle();
        try {
          (style.sources.data as any).cluster =
            chartOptions?.["interractive-map-pin-cluster-enabled"] === true
              ? true
              : false;
          map.current?.setStyle(style);
        } catch (error) {
          console.warn(error);
        }
      }
    }

    if (
      _.isEqual(
        chartOptions?.["interractive-map-disable-zoom"],
        prevChartOptions?.["interractive-map-disable-zoom"]
      )
    ) {
      if (
        map.current?.hasControl?.(navigation) &&
        chartOptions?.["interractive-map-disable-zoom"]
      ) {
        map.current?.removeControl?.(navigation);
        map.current?.scrollZoom.disable();
      } else if (
        !map.current?.hasControl(navigation) &&
        !chartOptions?.["interractive-map-disable-zoom"]
      ) {
        map.current?.addControl?.(navigation, "top-left");
        map.current?.scrollZoom.enable();
      }
    }

    if (
      !_.isEqual(
        chartOptions?.["interractive-map-position"],
        prevChartOptions?.["interractive-map-position"]
      )
    ) {
      const mapPosition = getMapPosition(getGeoJSON());
      if (typeof mapPosition.zoom === "number") {
        map.current?.setZoom(mapPosition.zoom);
      }
      if (mapPosition.center) {
        map.current?.setCenter(mapPosition.center);
      }
      if (mapPosition.bounds) {
        map.current?.fitBounds(mapPosition.bounds, { padding: 25 });
      }
    }
  }, [chartOptions]);

  return (
    <div
      ref={mapContainer}
      className="map-container"
      style={{
        height: removeMargin ? height : height - 6,
        width: width ? width : "100%",
        marginTop: removeMargin ? 0 : 6,
      }}
    />
  );
};

export default MapboxMarkerChart;
