import type { IFilter } from "../../interfaces/reports";
import type { ICompiledUserAttributes } from "../orgs/WithOrg";

export const FILTER_ARRAY_SEPARATOR = "||||";

export const parseEmbedValue = (filter: IFilter, p: any): string[] => {
  return p;
};

export const getFilterDefaultValue = (
  filter: IFilter,
  userAttributes: ICompiledUserAttributes
): string[] => {
  if (filter.defaultTo === "VALUE" && filter.defaultValue) {
    return typeof filter.defaultValue === "string"
      ? (filter.defaultValue as string)
          .split(FILTER_ARRAY_SEPARATOR)
          .filter((v) => !!v)
      : filter.defaultValue;
  } else if (
    filter.defaultTo === "ATTRIBUTE" &&
    filter.defaultValue &&
    typeof filter.defaultValue === "string" &&
    userAttributes[filter.defaultValue as string]
  ) {
    return userAttributes[filter.defaultValue as string].value;
  }
  return [];
};
