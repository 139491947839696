import Icon from "@ant-design/icons";
import type { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";

export const WlyMarkdownIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon
    component={() => (
      <svg
        width="1em"
        height="1em"
        viewBox="0 0 213 213"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M148.067 18C150.463 18 152.541 19.6582 153.072 21.9951L155.913 34.4855C157.226 40.2628 161.737 44.7739 167.515 46.0875L180.005 48.9278C182.342 49.4591 184 51.5368 184 53.9333C184 56.3298 182.342 58.4075 180.005 58.9388L167.515 61.7791C161.737 63.0927 157.226 67.6039 155.913 73.3812L153.072 85.8717C152.541 88.2083 150.463 89.8667 148.067 89.8667C145.67 89.8667 143.593 88.2083 143.061 85.8717L140.221 73.3812C138.907 67.6039 134.396 63.0927 128.619 61.7791L116.129 58.9388C113.792 58.4075 112.134 56.3298 112.134 53.9333C112.134 51.5368 113.792 49.4591 116.129 48.9278L128.619 46.0875C134.396 44.7739 138.907 40.2628 140.221 34.4855L143.061 21.9951C143.593 19.6582 145.67 18 148.067 18Z"
          fill="#5D6671"
        />
        <path
          d="M30 74.4666C30 71.6314 32.2983 69.3333 35.1333 69.3333H91.6C94.4351 69.3333 96.7333 71.6314 96.7333 74.4666C96.7333 77.3017 94.4351 79.5999 91.6 79.5999H35.1333C32.2983 79.5999 30 77.3017 30 74.4666Z"
          fill="#5D6671"
        />
        <path
          d="M35.1333 100.134C32.2983 100.134 30 102.432 30 105.267C30 108.102 32.2983 110.4 35.1333 110.4H178.867C181.702 110.4 184 108.102 184 105.267C184 102.432 181.702 100.134 178.867 100.134H35.1333Z"
          fill="#5D6671"
        />
        <path
          d="M30 136.067C30 133.232 32.2983 130.933 35.1333 130.933H178.867C181.702 130.933 184 133.232 184 136.067C184 138.902 181.702 141.2 178.867 141.2H35.1333C32.2983 141.2 30 138.902 30 136.067Z"
          fill="#5D6671"
        />
        <path
          d="M35.1333 161.733C32.2983 161.733 30 164.032 30 166.867C30 169.702 32.2983 172 35.1333 172H178.867C181.702 172 184 169.702 184 166.867C184 164.032 181.702 161.733 178.867 161.733H35.1333Z"
          fill="#5D6671"
        />
      </svg>
    )}
    {...props}
  />
);
