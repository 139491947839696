import { LockFilled } from "@ant-design/icons";
import { Alert, Button, Input } from "antd";
import * as React from "react";
import Feednack from "../../../components/layout/feedback/feedback";

import Logo from "../../../assets/logo/logo.svg";

import "./ReportSharingLinkPasswordPage.scss";

interface IReportSharingLinkPasswordPageProps {
  loading: boolean;
  login: (password: string) => any;
  error: boolean;
}

interface IState {
  password: string;
}

export default class ReportSharingLinkPasswordPage extends React.Component<
  IReportSharingLinkPasswordPageProps,
  IState
> {
  constructor(props: IReportSharingLinkPasswordPageProps) {
    super(props);
    this.state = {
      password: "",
    };
  }

  public render() {
    return (
      <div className="studio-layout">
        <div className={`main-content`}>
          <Feednack>
            <div className="report-sharing-link-password-page">
              <p className="report-sharing-link-password-page-text">
                This is a password protected dashboard
              </p>
              {this.props.error ? (
                <Alert
                  style={{ marginBottom: 24 }}
                  message="Wrong Password"
                  type="error"
                />
              ) : undefined}
              <Input
                value={this.state.password}
                onChange={(e) => this.setState({ password: e.target.value })}
                className="report-sharing-link-password-page-input"
                placeholder="Password"
                type={"password"}
                prefix={<LockFilled />}
              />
              <Button
                loading={this.props.loading}
                onClick={() => this.props.login(this.state.password)}
                style={{ width: "100%" }}
              >
                OK
              </Button>
              <div className="report-sharing-link-password-page-footer">
                Powered by{" "}
                <a rel="noreferrer" target="_blank" href="https://whaly.io">
                  <img src={Logo} style={{ height: 30 }} alt="" />
                </a>
              </div>
            </div>
          </Feednack>
        </div>
      </div>
    );
  }
}
