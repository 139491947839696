import { ArrowLeftOutlined } from "@ant-design/icons";
import Markdoc from "@markdoc/markdoc";
import { Button } from "antd";
import * as React from "react";
import type { RouteComponentProps } from "react-router";
import { withRouter } from "react-router";
import { compose } from "../../../../../../components/compose/WlyCompose";
import Feednack from "../../../../../../components/layout/feedback/feedback";
import Loading from "../../../../../../components/layout/feedback/loading";
import { type AsyncData } from "../../../../../../helpers/typescriptHelpers";
import { type IObject } from "../../../../../../interfaces/object";
import { routeDescriptor } from "../../../../../../routes/routes";
import WithOrg, { type InjectedOrgProps } from "../../../../../orgs/WithOrg";
import { EmbedType, type IRecord } from "../../domain";
import { getParsedDoc } from "../email-widgets/text/domain";
import {
  additionalComponents,
  nodesConfig,
} from "../widgets/markdown/widget/markdoc-react-component/config";
import { findDocument, type IRecordDocument } from "./domain";
import { getObjectColumns } from "../../../object/domain";

export interface IRecordDocumentRendererProps {
  object: IObject;
  data: IRecord;
  documentId: string;
  embedType?: EmbedType;
}

type Props = IRecordDocumentRendererProps &
  InjectedOrgProps &
  RouteComponentProps;

function RecordDocumentRenderer(props: Props) {
  const {
    object,
    data,
    documentId,
    user,
    embedType,
    match: { params },
    history,
  } = props;

  const [doc, setDoc] = React.useState<AsyncData<IRecordDocument | undefined>>({
    status: "initial",
  });

  React.useEffect(() => {
    try {
      setDoc({ status: "loading" });
      const source = findDocument(
        object.id,
        data[`${object.table.cubeName}.id`] as string,
        documentId
      );
      setDoc({ status: "success", data: source });
    } catch (err) {
      setDoc({ status: "error", error: err });
    }
  }, [object.id, data[`${object.table.cubeName}.id`]]);

  if (doc.status === "initial" || doc.status === "loading") {
    return (
      <Feednack>
        <Loading />
      </Feednack>
    );
  }

  if (doc.status === "error") {
    return (
      <Feednack>
        <div>There was an error fetching your document</div>
      </Feednack>
    );
  }

  if (!doc.data) {
    return (
      <Feednack>
        <div>Document not found</div>
      </Feednack>
    );
  }

  const columns = getObjectColumns(object);

  const content = getParsedDoc(
    doc.data.content ? doc.data.content : "",
    {},
    user,
    columns,
    nodesConfig
  );

  const html = Markdoc.renderers.react(content, React, additionalComponents);

  return (
    <div
      className="markdown-widget"
      style={{
        maxWidth: 612,
        marginLeft: "auto",
        marginRight: "auto",
        paddingTop: 50,
      }}
    >
      <div style={{ paddingBottom: "1.2em", textAlign: "left" }}>
        <Button
          shape="round"
          onClick={() => {
            if (embedType === EmbedType.recordModal) {
              return history.push(
                routeDescriptor["object-record-centered"].renderRoute(params)
              );
            }
            return history.push(
              routeDescriptor["object-record"].renderRoute(params)
            );
          }}
          icon={<ArrowLeftOutlined />}
        >
          Back
        </Button>
      </div>
      <div>{html}</div>
    </div>
  );
}

export default compose<Props, IRecordDocumentRendererProps>(
  WithOrg,
  withRouter
)(RecordDocumentRenderer);
