import * as React from "react";
import { useDrop } from "react-dnd";
import type { DraggableMeasure } from "../measure-item/DraggableMeasureItem";
import type { MeasureType } from "../measure-table/MeasureTable";
import "./DroppableMeasureZone.scss";

type DragType = MeasureType | "property";

export type MeasureDropZoneAccept = Array<DragType>;

export type MeasureDropZone =
  | "measure_store"
  | "dimensions"
  | "metrics"
  | "time_dimension"
  | "dimensions_filters"
  | "pivot"
  | "sort"
  | "name_dimension";

interface IDroppableMeasureZone {
  accept: MeasureDropZoneAccept;
  availableMeasures: Array<{ key: string; label: string }>;
  zone: MeasureDropZone;
  children?: any;
  small?: boolean;
  onAddMeasure: (measure: string) => void;
}

const getClassName = (
  isDragging: boolean = false,
  canDrop: boolean = false
) => {
  const lessened = "droppable-measure-zone-lessened";
  const highlighted = "droppable-measure-zone-highlighted";
  if (!isDragging) return undefined;
  if (canDrop) {
    return highlighted;
  } else {
    return lessened;
  }
};

const isAllowedToDrop = (availableMeasures, item) => {
  if (item?.zone !== "measure_store") return false;
  return availableMeasures.map((m) => m.key).includes(item?.id) ? true : false;
};

export function DroppableMeasureZone(props: IDroppableMeasureZone) {
  const { accept, children } = props;
  const [canDrop, setCanDrop] = React.useState<boolean>(false);
  React.useEffect(() => {}, [props]);

  const ref = React.useRef<HTMLDivElement>();

  const [{ isOver, isDragging, isDroppingInSameZone, what }, drop] = useDrop<
    DraggableMeasure,
    void,
    {
      isOver: boolean;
      isDragging: boolean;
      isDroppingInSameZone: boolean;
      what: DraggableMeasure;
    }
  >(
    () => ({
      accept: accept,
      collect: (monitor) => ({
        isOver: !!monitor.isOver(),
        isDragging: !!monitor.getItem(),
        isDroppingInSameZone:
          !!monitor.getItem() && monitor.getItem().zone === props.zone,
        what: monitor.getItem(),
      }),
      canDrop: (item) => {
        if (item.zone !== "measure_store") {
          setCanDrop(false);
          return false;
        }
        const allowed = isAllowedToDrop(props.availableMeasures, item);
        setCanDrop(allowed);
        return allowed;
      },
      drop: (item: DraggableMeasure, monitor) => {
        const didDrop = monitor.didDrop();
        // didDrop becomes true when a nested zone catched the drop
        if (didDrop) return;
        // only delete item from measure it's original zone
        // when an item is dropped into another zone
        if (
          item.onRemove &&
          item.zone !== props.zone &&
          item.zone !== "measure_store"
        ) {
          item.onRemove();
        }
        props.onAddMeasure(item.id);
      },
    }),
    [props.availableMeasures]
  );

  drop(ref);

  const classNames = ["droppable-measure-zone"];
  if (!props.small) {
    classNames.push("droppable-measure-zone-full-with");
  }
  if (isOver && canDrop) {
    classNames.push("droppable-measure-zone-hover");
  }
  const additionalClass = getClassName(
    isDragging,
    isAllowedToDrop(props.availableMeasures, what) || isDroppingInSameZone
  );
  if (additionalClass) {
    classNames.push(additionalClass);
  }

  return (
    <div className={classNames.join(" ")} ref={ref}>
      {children}
    </div>
  );
}
