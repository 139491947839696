import { Button, Form, Space } from "antd";
import cuid from "cuid";
import * as React from "react";
import { compose } from "../../../../../../../../components/compose/WlyCompose";
import FilterItem from "../../../../../../../../components/measures/filter-item/FilterItem";
import type { IDestination } from "../../../../../../../../interfaces/destinations";
import type {
  AndFilterItem,
  BinaryFilterItem,
  Filter,
  GroupFilterItem,
  OrFilterItem,
  TableSelectRowsOperation,
  Transformation,
  UnaryFilterItem,
} from "../../../../../../../../interfaces/transformations";
import { computeTransformations } from "../../../../../../../../services/BrizoService";
import { generateUniqueId } from "../../../../../../../../utils/uniqueId";
import type { InjectedOrgProps } from "../../../../../../../orgs/WithOrg";
import WithOrg from "../../../../../../../orgs/WithOrg";
import { catchErrors } from "../../../../../../../transformations/domain";
import type { FlowOperationFormProps } from "../domain";

type FilterRowFormProps = FlowOperationFormProps<{
  var: string;
  operation: TableSelectRowsOperation;
  domain: "viewResolver";
}> & {
  currentWarehouse: IDestination;
};

interface FormValue {
  condition: Array<BinaryFilterItem | UnaryFilterItem>;
  operator: "and" | "or";
}

type Props = FilterRowFormProps & InjectedOrgProps;

const id = cuid();

const FilterRowForm: React.FunctionComponent<Props> = (props) => {
  const {
    onSave,
    transformations,
    onCancel,
    isStale,
    currentTransformation,
    setFormInstance,
    prevStepSchema,
    setSubmitting,
    currentWarehouse,
  } = props;

  const [form] = Form.useForm();

  React.useEffect(() => {
    if (setFormInstance) {
      setFormInstance(form);
    }
  }, []);

  const isFormDisabled = !onSave;

  const autocomplete = async (dimension: string): Promise<string[]> => {
    const groupId = generateUniqueId();
    const sortId = generateUniqueId();
    const limitId = generateUniqueId();

    const newTransformations: Transformation[] = [
      ...transformations,
      {
        var: groupId,
        operation: {
          type: "Table.Group",
          args: {
            // we take the transformation before the select rows otherwise we don't see all the values
            table: currentTransformation.operation.args.table,
            keys: [dimension],
            aggregatedColumns: [
              {
                aggregatedColumn: dimension,
                aggregationType: "COUNT",
                destinationColumnName: "counter",
              },
            ],
          },
        },
        domain: "datasetResolver",
      },
      {
        var: sortId,
        operation: {
          type: "Table.Sort",
          args: {
            table: groupId,
            condition: [
              {
                column: "counter",
                sort: "DESC",
              },
            ],
          },
        },
        domain: "datasetResolver",
      },
      {
        var: limitId,
        operation: {
          type: "Table.FirstN",
          args: {
            table: sortId,
            countOrCondition: 200,
          },
        },
        domain: "datasetResolver",
      },
    ];
    return computeTransformations(currentWarehouse.id, {
      recommendation: newTransformations,
    }).then((r) => {
      return (r.data.recommendation as Array<any>).map((r) => {
        return r[dimension] as string;
      });
    });
  };

  if (currentTransformation.operation.args.condition.length > 1) {
    return (
      <div>You must have only on condition at the root of your filter.</div>
    );
  }

  if (
    (currentTransformation.operation.args.condition[0] as AndFilterItem).and ===
      undefined &&
    (currentTransformation.operation.args.condition[0] as OrFilterItem).or ===
      undefined
  ) {
    return (
      <div>The root condition must be a AND condition or OR condition</div>
    );
  }

  const extractFilters = (cond: Filter): FormValue => {
    const extractOperator = (c: GroupFilterItem): FormValue => {
      if ((c as any).and) {
        return {
          operator: "and",
          condition: (c as any).and,
        };
      } else {
        return {
          operator: "or",
          condition: (c as any).or,
        };
      }
    };
    return extractOperator(cond[0] as GroupFilterItem);
  };

  const initialValues: FormValue = extractFilters(
    currentTransformation.operation.args.condition
  );
  if (
    initialValues.condition.filter((c) => (c as any).or || (c as any).and)
      .length > 0
  ) {
    return <div>Level 2 conditions cannot be a group condition yet</div>;
  }

  return (
    <Form
      form={form}
      initialValues={initialValues}
      onFieldsChange={() => {
        isStale && isStale(true);
      }}
      className="form-dropdown-form"
      onFinish={async (v) => {
        try {
          setSubmitting(true);

          const newTransformation: Transformation = {
            ...currentTransformation,
            operation: {
              ...currentTransformation.operation,
              args: {
                ...currentTransformation.operation.args,
                condition: [
                  {
                    [v.operator]: v.condition,
                  },
                ] as any,
              },
            },
          };

          await onSave([
            {
              type: "UPDATE",
              transformation: newTransformation,
            },
          ]);
          setSubmitting(false);

          if (onCancel) {
            onCancel();
          }
        } catch (err) {
          setSubmitting(false);
          catchErrors(err, id);
          console.error(err);
        }
      }}
      layout="vertical"
    >
      <div className="form-dropdown-form-content" id={id}>
        <Form.Item style={{ marginBottom: 0 }} shouldUpdate={true}>
          {() => {
            const currentValue = form.getFieldValue(["operator"]);
            const condition = form.getFieldValue(["condition"]);
            return (
              <Form.Item style={{ marginBottom: 0 }} name="operator">
                <div>
                  ... filter rows matching{" "}
                  <Button
                    onClick={() => {
                      form.setFieldsValue({
                        operator: currentValue === "or" ? "and" : "or",
                        condition,
                      });
                    }}
                    size="small"
                    disabled={isFormDisabled}
                  >
                    {currentValue === "or" ? "any" : "all"}
                  </Button>{" "}
                  the following:
                </div>
              </Form.Item>
            );
          }}
        </Form.Item>
        <Form.Item shouldUpdate={true}>
          {() => {
            return (
              <Form.List name="condition">
                {(fields, { add, remove }) => (
                  <Space direction="vertical" style={{ width: "100%" }}>
                    {fields.map((field, i) => {
                      const value = form.getFieldValue([
                        "condition",
                        field.name,
                      ]);
                      return (
                        <FilterItem
                          availableDimensions={Object.keys(prevStepSchema).map(
                            (k) => {
                              return {
                                key: k,
                                label: k,
                                type: "standard",
                                domain: prevStepSchema[k].domain,
                              };
                            }
                          )}
                          disabled={isFormDisabled}
                          autocomplete={autocomplete}
                          keyName={"column"}
                          filter={value}
                          onDelete={() => remove(i)}
                          onChange={(f) => {
                            const condition = form.getFieldValue(["condition"]);
                            form.setFieldsValue({
                              condition: (condition as FilterItem[]).map(
                                (c, ci) => {
                                  if (ci === i) {
                                    return f;
                                  }
                                  return c;
                                }
                              ),
                            });
                          }}
                          key={i}
                        />
                      );
                    })}
                    <Form.Item>
                      <Button
                        size="small"
                        type="dashed"
                        disabled={isFormDisabled}
                        onClick={() => {
                          const filterItem: UnaryFilterItem = {
                            column: Object.keys(prevStepSchema)[0],
                            operator: "set",
                          };
                          return add(filterItem);
                        }}
                        block={true}
                      >
                        Add Filter
                      </Button>
                    </Form.Item>
                  </Space>
                )}
              </Form.List>
            );
          }}
        </Form.Item>
      </div>
    </Form>
  );
};

export default compose<Props, FilterRowFormProps>(WithOrg)(FilterRowForm);
