import type { IQueryTraceData } from "../../../../services/LagoonService";

export interface ProcessingStep {
  name:
    | "INCOMING_PROCESSING"
    | "QUEUED"
    | "OUTGOING_PROCESS"
    | "WAREHOUSE_QUERY"
    | "FETCHING_FROM_CACHE";
  end_date: number;
  start_date: number;
}

interface PerformedQueryTime {
  processingId: string;
  queueSize: number;
  duration: number;
  timeInQueue: number;
  addedToQueueTime: number;
}

export const computeSimplifiedTrace = (trace: IQueryTraceData[]) => {
  const reversedTrace = [...trace].reverse();

  const findFirstEntry = () => {
    return trace[0];
  };

  const findLastEntry = () => {
    return reversedTrace[0];
  };

  const foundFirstQueryStarted = () => {
    return trace.find((q) => q.evtName === "Query started");
  };

  const foundLastQueryCompleted = () => {
    return reversedTrace.find((q) => q.evtName === "Query completed");
  };

  const findAllSqlQueryProcessingEntry = (): Array<
    IQueryTraceData<PerformedQueryTime>
  > => {
    return reversedTrace.filter(
      (q) => q.evtName === "Performing query completed"
    );
  };

  const firstEntry = findFirstEntry();
  const lastEntry = findLastEntry();
  const queryStarted = foundFirstQueryStarted();
  const queryCompleted = foundLastQueryCompleted();

  const reworkedTrace: ProcessingStep[] = [];

  reworkedTrace.push({
    name: "INCOMING_PROCESSING",
    end_date: queryStarted?.ts,
    start_date: firstEntry?.ts,
  });

  reworkedTrace.push({
    name: "OUTGOING_PROCESS",
    end_date: lastEntry?.ts,
    start_date: queryCompleted?.ts,
  });

  const allSqlQuery = findAllSqlQueryProcessingEntry();

  if (allSqlQuery.length) {
    findAllSqlQueryProcessingEntry().forEach((ls) => {
      reworkedTrace.push({
        name: "QUEUED",
        start_date: ls.data.addedToQueueTime,
        end_date: ls.data.addedToQueueTime + ls.data.timeInQueue,
      });
      reworkedTrace.push({
        name: "WAREHOUSE_QUERY",
        start_date: ls.data.addedToQueueTime + ls.data.timeInQueue,
        end_date:
          ls.data.addedToQueueTime + ls.data.timeInQueue + ls.data.duration,
      });
    });
  } else {
    reworkedTrace.push({
      name: "FETCHING_FROM_CACHE",
      start_date: queryStarted?.ts,
      end_date: queryCompleted?.ts,
    });
  }

  return reworkedTrace;
};

export const computeLoadTime = (simplifiedTrace: ProcessingStep[]) => {
  const min = Math.min(...simplifiedTrace.map((st) => st.start_date));
  const max = Math.max(...simplifiedTrace.map((st) => st.end_date));
  return max - min;
};
