import { Form, Modal } from "antd";
import * as React from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { compose } from "../../../../../components/compose/WlyCompose";
import type { InjectedOrgProps } from "../../../../orgs/WithOrg";
import WithOrg from "../../../../orgs/WithOrg";

import "react-quill/dist/quill.snow.css";
import Delayed from "../../../../../components/delayed/delayed";

interface ITextEditionProps {
  initialData?: ITextInitialData;
  onSave: (data: ITextInitialData) => Promise<any>;
  onCancel: () => void;
}

interface ITextInitialData {
  id?: string;
  name: string;
  content: string;
}

type Props = ITextEditionProps & InjectedOrgProps;

const TextEdition: React.FunctionComponent<Props> = (props) => {
  const { onSave, org, onCancel, initialData } = props;

  const [confirmLoading, setConfirmLoading] = React.useState(false);
  const [form] = Form.useForm();

  const renderInnerModal = () => {
    return (
      <Delayed delay={100}>
        <Form
          initialValues={{
            name: initialData ? initialData.name : "",
            content: initialData ? initialData.content : "",
          }}
          layout="vertical"
          form={form}
        >
          <Form.Item name={["content"]} label="Description">
            <ReactQuill
              formats={[
                "header",
                "bold",
                "italic",
                "underline",
                "strike",
                "blockquote",
                "list",
                "bullet",
                "indent",
                "link",
                "image",
                "align",
              ]}
              theme="snow"
              modules={{
                toolbar: [
                  [{ header: [1, 2, 3, false] }],
                  ["bold", "italic", "underline"],
                  [
                    { align: "" },
                    { align: "center" },
                    { align: "right" },
                    { align: "justify" },
                  ],
                  [
                    { list: "ordered" },
                    { list: "bullet" },
                    { indent: "-1" },
                    { indent: "+1" },
                  ],
                  ["link"],
                ],
              }}
            />
          </Form.Item>
        </Form>
      </Delayed>
    );
  };

  return (
    <Modal
      open={true}
      title={initialData ? "Update text" : "Create text"}
      okText={initialData ? "Update" : "Create"}
      destroyOnClose={true}
      cancelText="Cancel"
      onCancel={onCancel}
      confirmLoading={confirmLoading}
      onOk={() => {
        setConfirmLoading(true);
        return form
          .validateFields()
          .then((values) => {
            const v = values as ITextInitialData;
            return onSave({ ...initialData, ...v, name: "tile" }).then(() =>
              setConfirmLoading(false)
            );
          })
          .catch((info) => {
            setConfirmLoading(false);
            console.error("Validate Failed:", info);
          });
      }}
    >
      {renderInnerModal()}
    </Modal>
  );
};

export default compose<Props, ITextEditionProps>(WithOrg)(TextEdition);
