import * as React from "react";
import { useDrag } from "react-dnd";

interface ITableItemProps {
  nameComponent: React.ReactNode;
  menuComponent?: React.ReactNode;
  id: string;
  generateDragItem: () => object;
  draggable?: boolean;
  wrap: (c: React.ReactNode, isDragging?: boolean) => React.ReactNode;
}

export function TableItem(props: ITableItemProps) {
  const { nameComponent, menuComponent, id, wrap } = props;

  const ref = React.useRef<HTMLDivElement>();

  const [{ isDragging }, drag] = useDrag({
    type: "DATASET",
    item: () => {
      return { ...props.generateDragItem() };
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  if (props.draggable) {
    drag(ref);
  }

  return (
    <div ref={ref} style={{ opacity: 1 }} className="workbench-item">
      {wrap(
        <div className="workbench-item-name">{nameComponent}</div>,
        isDragging
      )}
      {menuComponent && (
        <div className="workbench-item-actions" id={id}>
          {menuComponent}
        </div>
      )}
    </div>
  );
}
