import { Form, Input, InputNumber, Modal, Select } from "antd";
import { useForm } from "antd/es/form/Form";
import type { Moment } from "moment";
import moment from "moment";
import { useState } from "react";
import type { IOrgAccountType, IOrgStatus } from "../../../interfaces/org";
import GraphQLService from "../../../services/graphql/GraphQLService";
import DatePicker from "../../datepicker/DatePicker";

interface ICreateOrgModalProps {
  isOpen: boolean;
  setOpen: (open: boolean) => void;
}

type ICreateOrgFormValues = {
  name: string;
  status: IOrgStatus;
  accountType: IOrgAccountType;
  freeTrialEndDate: Moment;
  realm: Number | null;
};

const CreateRealmQuery = `
  mutation createRealm($data: RealmCreateInput!) {
    createRealm(data: $data) {
        id
    }
  }
`;

const CreateOrgQuery = `
  mutation createOrg($data: OrgCreateInput!) {
    createOrg(data: $data) {
        id
        slug
    }
  }
`;

export function CreateOrgModal(props: ICreateOrgModalProps) {
  const { isOpen, setOpen } = props;

  const [form] = useForm<ICreateOrgFormValues>();

  const [loading, setLoading] = useState<boolean>(false);

  return (
    <Modal
      title="Add an org"
      onCancel={() => setOpen(false)}
      open={isOpen}
      okButtonProps={{
        loading: loading,
        disabled: loading,
      }}
      onOk={async () => {
        try {
          setLoading(true);
          const values: ICreateOrgFormValues = await form.validateFields();

          let realmId = values.realm?.toString();
          if (!realmId) {
            realmId = (
              await GraphQLService<{ createRealm: { id: string } }>(
                CreateRealmQuery,
                {
                  data: {
                    identityProviderType: "whaly",
                  },
                }
              )
            ).createRealm.id;
          }

          const data = {
            ...values,
            freeTrialEndDate: moment(values.freeTrialEndDate)
              .format("YYYY-MM-DD")
              .toString(),
            realm: {
              connect: {
                id: realmId,
              },
            },
          };

          const orgSlug = (
            await GraphQLService<{ createOrg: { slug: string } }>(
              CreateOrgQuery,
              { data: data }
            )
          ).createOrg.slug;

          setOpen(false);

          window.location.assign("/" + orgSlug);
        } catch (error) {
          console.error(error);
        } finally {
          setLoading(false);
        }
      }}
    >
      <Form form={form} layout="vertical">
        <Form.Item
          name={"name"}
          label="Name"
          rules={[{ required: true, type: "string" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item name={"status"} label="Status" rules={[{ required: true }]}>
          <Select<IOrgStatus>
            options={[
              {
                value: "onboarding",
                label: "Onboarding",
              },
              {
                value: "freetrial",
                label: "Freetrial",
              },
              {
                value: "poc",
                label: "POC",
              },
              {
                value: "full",
                label: "Full",
              },
            ]}
          />
        </Form.Item>
        <Form.Item
          name={"accountType"}
          label="Account type"
          rules={[{ required: true }]}
        >
          <Select<IOrgAccountType>
            options={[
              {
                value: "partner",
                label: "Partner",
              },
              {
                value: "customer",
                label: "Customer",
              },
              {
                value: "internal",
                label: "Internal",
              },
            ]}
          />
        </Form.Item>
        <Form.Item
          name={"freeTrialEndDate"}
          label="Free trial end date"
          rules={[{ required: true }]}
        >
          <DatePicker />
        </Form.Item>
        <Form.Item
          name={"realm"}
          label="Realm ID"
          help="leave empty to create a new realm"
        >
          <InputNumber />
        </Form.Item>
      </Form>
    </Modal>
  );
}
