import { CloseOutlined, LogoutOutlined } from "@ant-design/icons";
import type { DiagramEngine } from "@projectstorm/react-diagrams";
import { PortModelAlignment, PortWidget } from "@projectstorm/react-diagrams";
import * as React from "react";
import type { OutputNodeModel } from "./OutputNodeModel";

import type { RouteComponentProps } from "react-router";
import { withRouter } from "react-router";
import { compose } from "../../../../../../../../components/compose/WlyCompose";
import "./OutputNodeWidget.scss";

interface OutputNodeWidgetProps {
  node: OutputNodeModel;
  engine: DiagramEngine;
  size?: number;
}

export const SIZE = 52;
const PORT_SIZE = 16;

type Props = OutputNodeWidgetProps & RouteComponentProps<{}>;

class OutputNodeWidget extends React.Component<Props> {
  render() {
    const { node } = this.props;
    return (
      <div
        className={`output-node-widget-wrapper ${
          node.isSelected() ? "selected" : ""
        }`}
        style={{ height: SIZE + PORT_SIZE, width: SIZE + PORT_SIZE }}
      >
        <PortWidget
          style={{
            top: SIZE / 2,
            left: 0,
            position: "absolute",
          }}
          port={this.props.node.getPort(PortModelAlignment.LEFT)}
          engine={this.props.engine}
        >
          <div className="output-port-widget" />
        </PortWidget>
        {node.isSelected() && (
          <div
            onClick={() => {
              node.remove();
            }}
            className="output-node-widget-remove"
          >
            <CloseOutlined />
          </div>
        )}
        <div
          className="output-node-widget-core"
          style={{ height: SIZE, width: SIZE }}
        >
          <div>
            <LogoutOutlined style={{ fontSize: 24 }} />
          </div>
        </div>
      </div>
    );
  }
}

export default compose<Props, OutputNodeWidgetProps>(withRouter)(
  OutputNodeWidget
);
