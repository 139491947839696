import { Form, InputNumber, Select, Space } from "antd";
import type { VisualizationSpec } from "react-vega";
import { ChartOptionCollapse } from "../../../../../../../../../chart-options/components/ChartOptionCollapse";
import ChartOptionLine from "../../../../../../../../../chart-options/components/ChartOptionLine";
import { AggregateField } from "../common/AggregateField";
import { SchemaField } from "../common/SchemaField";
import type { AggrerateValue, IDiscoveryChartDefinition } from "../domain";

const histogram: IDiscoveryChartDefinition<{
  column: string;
  aggregate: AggrerateValue;
  bin: "maxBin" | "columnName";
  maxBin?: number;
}> = {
  renderForm: (schema, form, onChange) => {
    return (
      <Form
        form={form}
        initialValues={{
          aggregate: "count",
          bin: "maxBin",
          maxBin: 10,
        }}
        onValuesChange={(changed) => {
          if (changed.bin && changed.bin === "columnValue") {
            form.setFieldValue("maxBin", null);
          } else if (changed.bin === "maxBin") {
            form.setFieldValue("maxBin", 10);
          }
          onChange(form.getFieldsValue());
        }}
      >
        <ChartOptionCollapse title="Column">
          <Space style={{ width: "100%" }} direction="vertical">
            <ChartOptionLine
              items={[
                {
                  flex: 1,
                  content: (
                    <Form.Item noStyle name="column">
                      <SchemaField
                        schema={schema}
                        filter={(s) => s.domain === "NUMERIC"}
                      />
                    </Form.Item>
                  ),
                },
              ]}
            />
            <ChartOptionLine
              items={[
                {
                  flex: 1,
                  content: <div>Aggregate</div>,
                },
                {
                  flex: 0,
                  content: (
                    <Form.Item noStyle name="aggregate">
                      <AggregateField
                        availableAggregates={[
                          "count",
                          "sum",
                          "mean",
                          "min",
                          "max",
                        ]}
                      />
                    </Form.Item>
                  ),
                },
              ]}
            />
            <ChartOptionLine
              items={[
                {
                  flex: 1,
                  content: <div>Bin by</div>,
                },
                {
                  flex: 0,
                  content: (
                    <Form.Item noStyle name="bin">
                      <Select
                        popupMatchSelectWidth={false}
                        bordered={false}
                        style={{ width: "100%" }}
                      >
                        <Select.Option key="maxBin" value="maxBin">
                          Max bin
                        </Select.Option>
                        <Select.Option key="columnValue" value="columnValue">
                          Column value
                        </Select.Option>
                      </Select>
                    </Form.Item>
                  ),
                },
              ]}
            />
            <Form.Item noStyle shouldUpdate>
              {() => {
                if (form.getFieldValue("bin") === "columnValue") {
                  return;
                }
                return (
                  <ChartOptionLine
                    items={[
                      {
                        flex: 1,
                        content: <div>Max bins</div>,
                      },
                      {
                        flex: 0,
                        content: (
                          <Form.Item noStyle name="maxBin">
                            <InputNumber min={1} max={100} />
                          </Form.Item>
                        ),
                      },
                    ]}
                  />
                );
              }}
            </Form.Item>
          </Space>
        </ChartOptionCollapse>
      </Form>
    );
  },
  renderSpec: (data, schema, def) => {
    if (!def || !def.column) {
      return {};
    }

    const spec: VisualizationSpec = {
      $schema: "https://vega.github.io/schema/vega-lite/v5.json",
      width: "container",
      height: "container",
      data: { values: data },
      mark: "bar",
      background: "transparent",
      padding: {
        left: 12,
        right: 12,
      },
      config: {
        axis: {
          domainColor: "#ddd",
          tickColor: "#ddd",
        },
      },
      encoding: {
        x:
          def.bin === "maxBin"
            ? {
                bin: {
                  maxbins: def.maxBin,
                },
                field: def.column,
              }
            : {
                field: def.column,
                type: "quantitative",
              },
        y: {
          aggregate: def.aggregate ? def.aggregate : "count",
          field: def.aggregate ? def.column : undefined,
        },
      },
    };

    return spec;
  },
};

export default histogram;
