import { CloseOutlined } from "@ant-design/icons";
import { Button, Col, Divider, Row, Skeleton, Typography } from "antd";
import Card from "../../cards/Card";

interface IGenericCatalogLoaderProps {
  title?: string;
  onClose: () => void;
}

export function GenericCatalogLoader(props: IGenericCatalogLoaderProps) {
  const { title, onClose } = props;

  const items = [1, 2, 3, 4, 5, 6, 7, 8];

  return (
    <>
      <div className={`catalog-modal-header`}>
        <div className={`catalog-modal-header-title`}>
          {title && (
            <Typography.Title style={{ marginBottom: 0 }} level={5}>
              {title}
            </Typography.Title>
          )}
        </div>
        <div className={`catalog-modal-header-button`}>
          <Button
            onClick={onClose}
            type={"default"}
            shape="circle"
            icon={<CloseOutlined />}
          />
        </div>
      </div>
      <Divider style={{ margin: 0 }} />
      <div className={`catalog-modal-content`}>
        <Row gutter={[24, 24]} className="source-catalog">
          {items.map((s) => {
            return (
              <Col key={s} xs={24} sm={12} md={8} lg={6}>
                <Card height={300}>
                  <div className={"source"}>
                    <div className="image">
                      <Skeleton.Avatar
                        style={{ width: 140, height: 140 }}
                        shape="circle"
                        size={"large"}
                        active
                      />
                    </div>
                    <div className="text">
                      <Skeleton.Button
                        block
                        shape="round"
                        size={"small"}
                        active
                        style={{ marginTop: 16 }}
                      />
                    </div>
                  </div>
                </Card>
              </Col>
            );
          })}
          <div style={{ width: "100%" }} key="padding" />
        </Row>
      </div>
    </>
  );
}
