import { syntaxTree } from "@codemirror/language";
import type { DecorationSet, ViewUpdate } from "@codemirror/view";
import {
  Decoration,
  EditorView,
  ViewPlugin,
  WidgetType,
} from "@codemirror/view";

import type { SQLAutocompleteItem } from "./sqlAutocomplete";
import { datasetIconToHTMLElement } from "./sqlAutocomplete";
import "./sqlVariablesHighlight.scss";

const SQL_LEGACY_MATCH_ONCE = /\$\{TABLES\["(.+?)"]\["(.+?)"]\}/i;
const SQL_SOURCE_MATCH_ONCE = /\$\{DATASETS\["(.+?)"]\}/i;
const SQL_RAW_MATCH_ONCE = /\$\{RAW\["(.+?)"]\}/i;

export function sqlVariablesHighlight(
  completionItems: SQLAutocompleteItem[],
  onDatasetClick?: (datasetId: string) => void
) {
  class VariablesWidget extends WidgetType {
    constructor(readonly label: string) {
      super();
    }
    eq(other: VariablesWidget) {
      return other.label === this.label;
    }
    toDOM() {
      const wrap = document.createElement("span");
      const completionItem = completionItems.find(
        (i) => i.label === this.label
      );
      const clickable = !!(
        typeof onDatasetClick === "function" &&
        completionItem?.datasetId &&
        (completionItem?.isModel || completionItem?.whalyType === "raw")
      );
      let img = "https://app.whaly.io/logo1x.png";
      let text = `${this.label} (unknown table)`;
      let className = "cm-variable cm-variable-missing";
      if (completionItem) {
        img = completionItem.icon;
        text = `${this.label}`;
        className = "cm-variable";
        if (clickable) {
          className += " cm-variable-clickable";
        }
      }
      const textNode = document.createElement("span");
      textNode.className = "cm-variable-text";
      textNode.innerText = text;

      const node = document.createElement("span");
      node.className = className;

      let imgElement = datasetIconToHTMLElement(
        img,
        completionItem?.isModel,
        14
      );
      if (imgElement) {
        imgElement.classList.add("cm-variable-img");
        node.appendChild(imgElement);
      }
      node.appendChild(textNode);
      wrap.appendChild(node);
      wrap.onclick = () => {
        if (clickable) {
          onDatasetClick(completionItem.apply);
        }
      };
      return wrap;
    }
    ignoreEvent() {
      return false;
    }
  }

  function variables(view: EditorView) {
    let widgets = [];
    for (let { from, to } of view.visibleRanges) {
      syntaxTree(view.state).iterate({
        from,
        to,
        enter: ({ type, from, to }) => {
          if (type.name === "Braces") {
            const newFrom = from - 1;
            let text = view.state.doc.sliceString(newFrom, to);
            const legacyMatch = text.match(SQL_LEGACY_MATCH_ONCE);
            const match = text.match(SQL_SOURCE_MATCH_ONCE);
            const rawMatch = text.match(SQL_RAW_MATCH_ONCE);
            if (!!(match || legacyMatch || rawMatch)) {
              if (!!legacyMatch) {
                text = `${legacyMatch[1]}.${legacyMatch[2]}`;
                let deco = Decoration.replace({
                  widget: new VariablesWidget(text),
                });
                widgets.push(deco.range(newFrom, to));
              } else if (!!match) {
                text = `${
                  completionItems.find(
                    (c) => c.whalyType === "source" && c.datasetId === match[1]
                  )?.label
                }`;
                let deco = Decoration.replace({
                  widget: new VariablesWidget(text),
                });
                widgets.push(deco.range(newFrom, to));
              } else if (!!rawMatch) {
                text = `${
                  completionItems.find(
                    (c) => c.whalyType === "raw" && c.datasetId === rawMatch[1]
                  )?.label
                }`;
                let deco = Decoration.replace({
                  widget: new VariablesWidget(text),
                });
                widgets.push(deco.range(newFrom, to));
              }
            } else {
              return;
            }
          }
        },
      });
    }
    return Decoration.set(widgets);
  }

  const variablesPlugin = ViewPlugin.fromClass(
    class {
      decorations: DecorationSet;

      constructor(view: EditorView) {
        this.decorations = variables(view);
      }

      update(update: ViewUpdate) {
        if (update.docChanged || update.viewportChanged)
          this.decorations = variables(update.view);
      }
    },
    {
      decorations: (v) => v.decorations,
      provide: (plugin) =>
        EditorView.atomicRanges.of((view) => {
          let value = view.plugin(plugin);
          return value ? value.decorations : Decoration.none;
        }),
    }
  );
  return variablesPlugin;
}
