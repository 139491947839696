import type { FormInstance } from "antd";
import { Button, Form, Input, Space, Switch, Typography } from "antd";
import cuid from "cuid";
import _ from "lodash";
import React from "react";
import { WlyCard } from "../../../../../../components/cards/WlyCard";
import { WlyCardDangerZone } from "../../../../../../components/cards/WlyCardDangerZone";
import { compose } from "../../../../../../components/compose/WlyCompose";
import { CalculatedMetricFormulaEditor } from "../../../../../../components/formula/calculated-metric/CalculatedMetricFormulaEditor";
import type { SchemaResult } from "../../../../../../interfaces/transformations";
import { checkFunction } from "../../../../../../services/ParseService";
import type { InjectedOrgProps } from "../../../../../orgs/WithOrg";
import WithOrg from "../../../../../orgs/WithOrg";
import type { ExplorationMeasureItemUsage, IViewData } from "../../../domain";
import type {
  IMeasureTable,
  IMetricFormInput,
  IObjectConfigurationInfos,
  MeasureValidationFunction,
} from "../../domain";
import { ErrorRenderer } from "../common/ErrorRenderer";
import UsageAlert from "../common/UsageAlert";
import { DrillsEditor } from "./common/DrillsEditor";
import MetricFormatFormItem from "./common/MetricFormatFormItem";

interface ICalculatedMetricEditorProps {
  currentId: string;
  initialData?: IMetricFormInput;
  form: FormInstance<IMetricFormInput>;
  usage: ExplorationMeasureItemUsage[];
  error: string[];
  onSave: (data: IMetricFormInput) => Promise<void>;
  onDelete: () => Promise<void>;
  tables: IMeasureTable[];
  selectedView: IViewData;
  object?: IObjectConfigurationInfos;
}

const buildSchema = (tables: IMeasureTable[], currentId?: string) => {
  return tables.reduce<SchemaResult>((tableAcc, table) => {
    return {
      ...tableAcc,
      ...table.metrics
        .filter((m) => m.id !== currentId)
        .reduce<SchemaResult>((metricAcc, metric) => {
          return {
            ...metricAcc,
            [`${table.cubeName}.${metric.cubeName}`]: {
              type: "NUMERIC",
              domain: "NUMERIC",
              label: metric.name,
            },
          };
        }, {}),
    };
  }, {});
};

export const validateCalculatedMetricFormInput: MeasureValidationFunction<
  IMetricFormInput
> = (data, schema, allDatasets, allDatasetRelationships, allTables) => {
  const errors: string[] = [];
  if (schema.status === "initial" || schema.status === "loading") {
    return errors;
  }
  if (schema.status === "error") {
    return errors;
  }

  if (!data.columnName) {
    errors.push("Formula can't be empty");
    return errors;
  }

  try {
    const schema = buildSchema(allTables);
    const error = checkFunction(data.columnName, schema, () => ({}));
    if (error) {
      errors.push("Error in formula");
    }
    return errors;
  } catch (err) {
    errors.push("Error in formula");
    return errors;
  }
};

export const generateComputedMetricName = (overrideName?: string) => {
  if (overrideName) {
    return overrideName;
  } else {
    return "Untitled";
  }
};

type Props = ICalculatedMetricEditorProps & InjectedOrgProps;

function CalculatedMetricEditor(props: Props) {
  const {
    initialData,
    form,
    onSave,
    onDelete,
    tables,
    currentId,
    usage,
    error,
    selectedView,
    user,
    object,
  } = props;

  const debouncedOnSave = _.debounce(onSave, 200);

  const attachementId = React.useRef(cuid());

  const currentSchema: SchemaResult = buildSchema(tables, currentId);

  const getColumnReplacement = (name) =>
    currentSchema[name] && currentSchema[name].label
      ? currentSchema[name].label!
      : name;

  const columnsSuggestions = Object.keys(currentSchema).map((ac) => {
    return {
      key: ac,
      label: getColumnReplacement(ac),
    };
  });

  return (
    <Form
      initialValues={initialData}
      layout="vertical"
      form={form}
      onFieldsChange={(changedFields) => {
        if (
          changedFields.find(
            (f) => JSON.stringify(f.name) === JSON.stringify(["format"])
          )
        ) {
          form.setFieldsValue({
            prefix: "",
            suffix: "",
            overrideFormatting: "",
          });
        }
        debouncedOnSave({
          ...initialData,
          ...form.getFieldsValue(),
          expression: "COMPUTED",
        });
      }}
    >
      <Space style={{ width: "100%" }} direction="vertical" size={24}>
        <div style={{ marginTop: 12 }} />
        {usage.length > 0 ? <UsageAlert type="metric" usage={usage} /> : null}
        {error && error.length > 0 ? <ErrorRenderer error={error} /> : null}
        <WlyCard
          title={
            <Typography.Title level={5}>General Information</Typography.Title>
          }
        >
          <Form.Item
            name={["overrideName"]}
            label="Give your metric a name"
            rules={[
              {
                required: true,
                message: "Metric name can not be empty",
              },
            ]}
          >
            <Input placeholder="Your custom metric name" />
          </Form.Item>
          <Form.Item
            name={["hidden"]}
            label="Hide your metric from the interface"
            valuePropName="checked"
          >
            <Switch />
          </Form.Item>
          <Form.Item
            name={["description"]}
            label="... add a description to your metric"
          >
            <Input.TextArea placeholder="Your description" />
          </Form.Item>
          {object ? (
            <Form.Item name={["hierarchyPath"]} label="Hierarch path">
              <Input />
            </Form.Item>
          ) : undefined}
        </WlyCard>
        <WlyCard title={<Typography.Title level={5}>Formula</Typography.Title>}>
          <Form.Item
            name={["columnName"]}
            label="Formula"
            help={`Input metric names, numbers and operators. Example: "Number of orders / 2"`}
            rules={[
              {
                validator: (_, v) =>
                  !!checkFunction(v, currentSchema, (explainations) => null)
                    ? Promise.reject(`Error`)
                    : Promise.resolve(),
              },
              {
                required: true,
                message: "Formula is required",
              },
            ]}
          >
            <CalculatedMetricFormulaEditor
              columnsSuggestions={columnsSuggestions}
            />
          </Form.Item>
        </WlyCard>
        <WlyCard title={<Typography.Title level={5}>Format</Typography.Title>}>
          <MetricFormatFormItem
            object={object}
            form={form}
            locale={user.locale}
          />
        </WlyCard>
        <WlyCard
          id={attachementId.current}
          title={<Typography.Title level={5}>Drill downs</Typography.Title>}
        >
          <DrillsEditor
            id={attachementId.current}
            tables={tables}
            form={form}
            view={selectedView}
            parentTable={tables.find((t) => t.id === currentId)}
          />
        </WlyCard>
        {onDelete && (
          <WlyCardDangerZone
            title="Danger Zone"
            button={
              <Button
                type="primary"
                danger={true}
                onClick={onDelete ? onDelete : undefined}
              >
                Delete
              </Button>
            }
          >
            Click here to permanently delete this metric. This cannot be undone.
          </WlyCardDangerZone>
        )}
        <div style={{ marginTop: 12 }} />
      </Space>
    </Form>
  );
}

export default compose<Props, ICalculatedMetricEditorProps>(WithOrg)(
  CalculatedMetricEditor
);
