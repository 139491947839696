import type { SchemaResult } from "../../../../interfaces/transformations";
import type {
  IDimensionFormInput,
  IMetricFormInput,
} from "../../../workbench/workbench/exploration/domain";

export const generateMeasuresFromSchema = (
  schema: SchemaResult
): {
  metrics: IMetricFormInput[];
  dimensions: IDimensionFormInput[];
} => {
  const metrics: IMetricFormInput[] = [];
  const dimensions: IDimensionFormInput[] = [];

  metrics.push({
    expression: "COUNT",
    semanticGroupIds: [],
  });

  Object.keys(schema).forEach((k) => {
    if (schema[k].domain === "NUMERIC" && (k !== "id" || !k.endsWith("_id"))) {
      metrics.push({
        columnName: k,
        expression: "AVG",
        semanticGroupIds: [],
      });
      metrics.push({
        columnName: k,
        expression: "SUM",
        semanticGroupIds: [],
      });
    } else {
      dimensions.push({
        type: "standard",
        columnName: k,
        columnDomain: schema[k].domain,
        description: schema[k].description,
        semanticGroupIds: [],
      });
    }
  });

  return {
    metrics: metrics,
    dimensions: dimensions,
  };
};
