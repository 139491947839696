import * as React from "react";
import { CloseCircleFilled, CheckCircleFilled } from "@ant-design/icons";
import type { ColumnInfoTest } from "../domain";

interface ITestRendererProps {
  test: ColumnInfoTest;
}

export function TestRenderer(props: ITestRendererProps) {
  const { test } = props;
  return (
    <div>
      {test.status !== "success" ? (
        <CloseCircleFilled style={{ color: "#ff4d4f" }} />
      ) : (
        <CheckCircleFilled style={{ color: "#52c41a" }} />
      )}{" "}
      {test.name}
    </div>
  );
}
