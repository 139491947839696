import { Button, Col, Empty, Flex, Space, Typography } from "antd";
import { useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import type { InjectedAntUtilsProps } from "../../../../../../../components/ant-utils/withAntUtils";
import { withAntUtils } from "../../../../../../../components/ant-utils/withAntUtils";
import { compose } from "../../../../../../../components/compose/WlyCompose";
import Feednack from "../../../../../../../components/layout/feedback/feedback";
import Loading from "../../../../../../../components/layout/feedback/loading";
import type {
  IObject,
  IObjectQueryBuilderSection,
} from "../../../../../../../interfaces/object";
import type { InjectedOrgProps } from "../../../../../../orgs/WithOrg";
import WithOrg from "../../../../../../orgs/WithOrg";
import {
  useCreateObjectQueryBuilderSection,
  useGetObjectQueryBuilderSections,
  useUpdateObjectQueryBuilderSections,
} from "./api";
import { ObjectQueryBuilderSectionCard } from "./cards/ObjectQueryBuilderSectionCard";
import { reorder } from "./dnd/DNDWrapper";
import { type CreateEditQueryBuilderSectionFormData } from "./modal/domain";
import { ObjectQueryBuilderCreation } from "./modal/ObjectQueryBuilderCreation";
import ObjectQueryBuilderUpdateDrawer from "./modal/ObjectQueryBuilderUpdateDrawer";

type IObjectQueryBuilderProps = {
  object: IObject;
};

type Props = IObjectQueryBuilderProps &
  InjectedOrgProps &
  InjectedAntUtilsProps;

const { Title } = Typography;

function ObjectQueryBuilder({ antUtils, object, org }: Props) {
  const { data, pending, loading, error, refetch } =
    useGetObjectQueryBuilderSections({
      variables: { orgId: org.id, objectId: object.id },
    });
  const { mutateAsync: updateSections } = useUpdateObjectQueryBuilderSections();
  const { mutateAsync: createSection, loading: isCreating } =
    useCreateObjectQueryBuilderSection();

  const [queryBuilderSections, setQueryBuilderSections] = useState<
    IObjectQueryBuilderSection[]
  >([]);
  const [isEditing, setIsEditing] = useState<
    CreateEditQueryBuilderSectionFormData | undefined
  >(undefined);

  useEffect(() => {
    if (data?.allObjectQueryBuilderSections) {
      setQueryBuilderSections(data?.allObjectQueryBuilderSections ?? []);
    }
  }, [data]);

  if (pending || loading) {
    return (
      <Feednack>
        <Loading />
      </Feednack>
    );
  }

  if (error) {
    return (
      <Feednack>
        <div>{error.message}</div>
      </Feednack>
    );
  }

  const onDelete = async (id: string) => {
    try {
      await updateSections({
        variables: { data: [{ id: id, data: { isDeleted: true } }] },
      });
      setQueryBuilderSections((prev: IObjectQueryBuilderSection[]) =>
        prev.filter((p) => p.id !== id)
      );
      antUtils.message.success("Section deleted");
    } catch (error) {
      console.error(error);
      antUtils.message.error(
        "Error while deleting your section, please try again"
      );
    }
  };

  const onCreate = async (val: Partial<IObjectQueryBuilderSection>) => {
    try {
      const d = await createSection({
        variables: {
          data: {
            ...val,
            order:
              queryBuilderSections.length === 0
                ? 0
                : Math.max(...queryBuilderSections.map((av) => av.order)) + 1,
            object: { connect: { id: object.id } },
            org: { connect: { id: org.id } },
          },
        },
      });
      refetch();
      setIsEditing(d.createObjectQueryBuilderSection);
      antUtils.message.success("Section created");
    } catch (error) {
      console.error(error);
      antUtils.message.error("Error while creating section. Try again");
    }
  };

  const onReorder = async (sections: IObjectQueryBuilderSection[]) => {
    try {
      setQueryBuilderSections(sections);
      await updateSections({
        variables: {
          data: sections.map((s, index) => ({
            id: s.id,
            data: { order: index },
          })),
        },
      });
      antUtils.message.success("Presets updated!");
    } catch (error) {
      console.error(error);
      antUtils.message.error("Error while ordering presets. Try again");
    }
  };

  const onUpdate = async (
    id: string,
    udpated: Partial<CreateEditQueryBuilderSectionFormData>
  ) => {
    try {
      const d = await updateSections({
        variables: {
          data: [
            {
              id: id,
              data: {
                name: udpated.name,
              },
            },
          ],
        },
      });
      setQueryBuilderSections((prev: IObjectQueryBuilderSection[]) =>
        prev.map((p) => {
          const foundIndex = d.updateObjectQueryBuilderSections.findIndex(
            (up) => up.id === p.id
          );
          if (foundIndex > -1) {
            return {
              ...p,
              ...d.updateObjectQueryBuilderSections[foundIndex],
            };
          } else {
            return p;
          }
        })
      );
      antUtils.message.success("Section updated");
    } catch (error) {
      console.error(error);
      antUtils.message.error("Error while updating section. Try again");
    }
  };

  return (
    <Col span={16} offset={4}>
      <Space direction="vertical" size={32} style={{ width: "100%" }}>
        <div>
          <Flex align="center" style={{ paddingTop: 16 }}>
            <div style={{ flex: 1 }}>
              <Title level={3} style={{ marginBottom: 0 }}>
                Query Builder
              </Title>
            </div>
            <Button
              onClick={() =>
                setIsEditing({
                  name: "",
                  type: "OWN_PROPERTIES",
                  order: queryBuilderSections.length,
                })
              }
            >
              Add Section
            </Button>
          </Flex>
        </div>
        <DragDropContext
          onDragEnd={(result) => {
            if (!result.destination) {
              return;
            }

            const items = reorder(
              [...queryBuilderSections],
              result.source.index,
              result.destination.index
            );

            onReorder(items);
          }}
        >
          <Droppable
            droppableId={"OBJECT_QUERY_BUILDER_SECTION"}
            type="OBJECT_QUERY_BUILDER_SECTION"
          >
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                {...provided.droppableProps}
                style={
                  snapshot.isDraggingOver
                    ? {
                        background: "#F2F4F5",
                        outline: "6px solid #F2F4F5",
                        borderRadius: 6,
                      }
                    : {}
                }
              >
                {queryBuilderSections.length ? (
                  queryBuilderSections.map((apf, index) => (
                    <Draggable draggableId={apf.id} key={apf.id} index={index}>
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                        >
                          <div style={{ paddingTop: 3, paddingBottom: 3 }}>
                            <ObjectQueryBuilderSectionCard
                              section={apf}
                              dragHandleProps={provided.dragHandleProps}
                              onDelete={onDelete}
                              onEdit={() => setIsEditing(apf)}
                            />
                          </div>
                        </div>
                      )}
                    </Draggable>
                  ))
                ) : (
                  <div>
                    <Empty description="No sections" />
                  </div>
                )}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        <ObjectQueryBuilderCreation
          open={!!isEditing && !isEditing.id}
          initialValues={isEditing}
          onCancel={() => setIsEditing(undefined)}
          object={object}
          onSave={(v) => {
            try {
              onCreate(v);
              setIsEditing(undefined);
            } catch (error) {
              console.error(error);
            }
          }}
        />
        <ObjectQueryBuilderUpdateDrawer
          open={!!isEditing && !!isEditing.id}
          initialValues={isEditing}
          onCancel={() => setIsEditing(undefined)}
          object={object}
          onSave={(id, v) => {
            try {
              onUpdate(id, { ...isEditing, name: v });
              setIsEditing(undefined);
            } catch (error) {
              console.error(error);
            }
          }}
        />
        <div style={{ height: 32 }}></div>
      </Space>
    </Col>
  );
}

export default compose<Props, IObjectQueryBuilderProps>(
  WithOrg,
  withAntUtils
)(ObjectQueryBuilder);
