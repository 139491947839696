import { inject } from "mobx-react";
import React from "react";
import type { RouteComponentProps } from "react-router";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import type { InjectedAntUtilsProps } from "../../components/ant-utils/withAntUtils";
import { withAntUtils } from "../../components/ant-utils/withAntUtils";
import { compose } from "../../components/compose/WlyCompose";
import Loading from "../../components/layout/feedback/loading";
import MainHeader from "../../components/layout/header/MainHeader";
import UserAvatar from "../../components/user/avatar/UserAvatar";
import GraphQLService from "../../services/graphql/GraphQLService";
import type { UserStoreProps } from "../../store/userStore";
import ProfileDropDown from "../menu/ProfileDropDown";

interface IFeatureCrossSellPageProps {}

type Props = IFeatureCrossSellPageProps &
  RouteComponentProps<{ featureSlug: string }> &
  UserStoreProps &
  InjectedAntUtilsProps;

// This is a component that is loading a page explaining why to cross sell
function FeatureCrossSellPage(props: Props) {
  const { location, match, userStore } = props;

  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [descriptionUrl, setDescriptionUrl] = React.useState<string>(undefined);

  const featureSlug = match.params.featureSlug;

  React.useEffect(() => {
    setIsLoading(true);
    fetchFeatureInfo();
  }, [featureSlug]);

  const fetchFeatureInfo = async (): Promise<void> => {
    const queryText = `
    query GetFeatureInfo($featureSlug: String!) {
      allOrgFeatureMetas(where: {
        slug: $featureSlug
      }) {
        descriptionUrl
      }
    }
    `;

    interface GetFeatureInfoResult {
      allOrgFeatureMetas: {
        descriptionUrl: string;
      }[];
    }

    const result: GetFeatureInfoResult =
      await GraphQLService<GetFeatureInfoResult>(queryText, {
        featureSlug,
      });

    if (result.allOrgFeatureMetas?.[0]?.descriptionUrl) {
      setDescriptionUrl(result.allOrgFeatureMetas?.[0]?.descriptionUrl);
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return <Loading />;
  }

  const searchQs = location.search;
  const parsedQS = new URLSearchParams(searchQs);
  const backPath = parsedQS.get(`backPath`);

  return (
    <>
      <MainHeader
        userInfo={
          <ProfileDropDown placement="bottomLeft">
            <UserAvatar user={userStore.user} />
          </ProfileDropDown>
        }
        breadcrumb={<Link to={backPath}>← Go back</Link>}
        showLogo={false}
      />
      <iframe
        style={{ border: 0, width: "100%", height: "100%" }}
        src={descriptionUrl}
      />
    </>
  );
}

export default compose<Props, IFeatureCrossSellPageProps>(
  withRouter,
  withAntUtils,
  inject("userStore")
)(FeatureCrossSellPage);
