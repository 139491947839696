import type { IWlyCardProps } from "./WlyCard";
import { WlyCard } from "./WlyCard";
import "./WlyCardDangerZone.scss";

interface IWlyCardDangerZoneProps extends IWlyCardProps {
  button: React.ReactElement;
  children?: React.ReactNode;
}

export function WlyCardDangerZone(props: IWlyCardDangerZoneProps) {
  const classNames = [props.className, "wly-danger-zone"].join(" ");
  const { button, ...rest } = props;
  return (
    <WlyCard {...rest} className={classNames}>
      {props.children}
      <div className="wly-danger-zone-button">{button}</div>
    </WlyCard>
  );
}
