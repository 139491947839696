import moment from "moment";
import * as React from "react";
import type { DataType } from "../../../interfaces/transformations";

import { Typography } from "antd";
import "./FieldRenderer.scss";

interface IFieldRendererProps {
  content: any;
  style?: React.CSSProperties;
  type?: DataType;
  momentFormat?:
    | "hour"
    | "day"
    | "week"
    | "month"
    | "quarter"
    | "year"
    | string;
}

const isMarkdownLink = (content: any) => {
  const regex =
    /^\[([\w\s\d]+)\]\((https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_+.~#?&/=]*))\)$/m;
  const found = (content as string).match(regex);
  if (found) {
    const title = found[1];
    const link = found[2];
    return {
      title,
      link,
    };
  }
  return null;
};

const isUrl = (content: any) => {
  try {
    const url = new URL(content);
    return url;
  } catch (error) {
    return null;
  }
};

export default class FieldRenderer extends React.Component<IFieldRendererProps> {
  public render() {
    const { content, type, style, momentFormat } = this.props;
    if (content === null || content === undefined) {
      return (
        <div className="field-renderer null" style={style}>
          null
        </div>
      );
    }

    if (type === "TIME") {
      if (momentFormat === "hour") {
        return moment.utc(content as any).format("MMMM DD YYYY, HH:mm");
      } else if (momentFormat === "day" || momentFormat === "week") {
        return moment.utc(content as any).format("MMMM DD, YYYY");
      } else if (momentFormat === "month") {
        return moment.utc(content as any).format("MMMM YYYY");
      } else if (momentFormat === "quarter") {
        return moment.utc(content as any).format("\\QQ YYYY");
      } else if (momentFormat === "year") {
        return moment.utc(content as any).format("YYYY");
      } else if (typeof momentFormat === "string") {
        return moment.utc(content as any).format(momentFormat);
      } else {
        return moment.utc(content as any).format("MMMM DD YYYY, HH:mm:ss");
      }
    }

    if (type === "STRING" && typeof content === "string") {
      const markdownLinkData = isMarkdownLink(content);
      if (markdownLinkData) {
        return (
          <a
            href={markdownLinkData.link}
            target="_blank"
            rel="noreferrer"
            style={style}
          >
            {markdownLinkData.title}
          </a>
        );
      }

      const hrefLinkData = isUrl(content);
      if (hrefLinkData) {
        if (hrefLinkData.href.toLowerCase().endsWith(".mp3")) {
          return (
            <audio controls style={{ height: 26, width: "100%" }}>
              <source src={hrefLinkData.href} type="audio/mpeg"></source>
            </audio>
          );
        }

        return (
          <Typography.Link
            href={hrefLinkData.href}
            target="_blank"
            rel="noreferrer"
            style={style}
          >
            {hrefLinkData.href}
          </Typography.Link>
        );
      }

      if (content.length === 0) {
        return <i style={style}>Empty String</i>;
      }
    }

    switch (typeof content) {
      case "undefined":
        return (
          <div className="field-renderer null" style={style}>
            null
          </div>
        );
      case null:
        return (
          <div className="field-renderer null" style={style}>
            null
          </div>
        );
      case "boolean":
        return JSON.stringify(content);
      case "number":
        return content;
      case "string":
        return content;
      default:
        return JSON.stringify(content);
    }
  }
}
