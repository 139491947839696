import type { SqlQuery } from "@cubejs-client/core";
import { Space, Tabs, Timeline, Typography } from "antd";
import _ from "lodash";
import { compose } from "../../../components/compose/WlyCompose";
import Error from "../../../components/error/Error";
import Feednack from "../../../components/layout/feedback/feedback";
import Loading from "../../../components/layout/feedback/loading";
import type {
  AsyncCachedData,
  AsyncData,
} from "../../../helpers/typescriptHelpers";
import type {
  IQueryTraceResult,
  WlyResultSet,
} from "../../../services/LagoonService";
import {
  computeLoadTime,
  computeSimplifiedTrace,
} from "../../exploration/single/performance/domain";
import type { InjectedOrgProps } from "../../orgs/WithOrg";
import WithOrg from "../../orgs/WithOrg";
import SimplifiedTraceChart from "../trace-charts/SimplifiedTraceChart";
import TraceChart from "../trace-charts/TraceChart";

import "./ItemPerformance.scss";

interface IItemPerformanceProps {
  traceData?: AsyncData<IQueryTraceResult>;
  name: string;
  data: AsyncCachedData<WlyResultSet<any>>;
  sql?: SqlQuery | string;
}

type Props = IItemPerformanceProps & InjectedOrgProps;

function ItemPerformance(props: Props) {
  const { traceData, sql, name, data, user } = props;

  const trace =
    traceData?.status === "success" ? traceData.data : { events: [] };
  const simplifiedTrace = computeSimplifiedTrace(trace.events);
  const hashitCache = simplifiedTrace.find(
    (t) => t.name === "FETCHING_FROM_CACHE"
  );

  if (
    !traceData ||
    traceData.status === "initial" ||
    traceData.status === "loading"
  ) {
    return (
      <Feednack>
        <Loading />
      </Feednack>
    );
  }

  if (traceData.status === "error") {
    return (
      <Feednack>
        <Error />
      </Feednack>
    );
  }

  return (
    <div className={"item-performance"}>
      <div className={"item-performance-tile-name"}>
        <Typography.Text strong>{name}</Typography.Text>
      </div>
      <Space style={{ width: "100%" }} direction="vertical" size={"small"}>
        <div className={"item-performance-performance-item"}>
          <Typography.Text
            className={"item-performance-performance-item-title"}
            strong
          >
            Loaded in:
          </Typography.Text>
          <Typography.Text
            className={"item-performance-performance-item-figure"}
            style={{ marginLeft: 6 }}
            type="secondary"
          >
            {computeLoadTime(simplifiedTrace)} ms
          </Typography.Text>
        </div>
        <div className={"item-performance-performance-item"}>
          <Typography.Text
            className={"item-performance-performance-item-title"}
            strong
          >
            From cache:
          </Typography.Text>
          <Typography.Text
            className={"item-performance-performance-item-figure"}
            style={{ marginLeft: 6 }}
            type={hashitCache ? "success" : "danger"}
          >
            {hashitCache ? "Yes" : "No"}
          </Typography.Text>
        </div>
        <Tabs>
          <Tabs.TabPane tab="Performance" key="performance">
            <SimplifiedTraceChart
              data={[
                {
                  name: name,
                  simplifiedTrace: simplifiedTrace,
                },
              ]}
            />
          </Tabs.TabPane>
          <Tabs.TabPane tab="SQL" key="sql">
            {sql && data.status === "success" && (
              <div>
                <Typography.Text
                  className={"item-performance-performance-item-figure"}
                  type="secondary"
                  copyable
                >
                  {(data.data as any).queryType === "compareDateRangeQuery"
                    ? "SQL decomposition is not supported when comparing date range."
                    : typeof sql === "string"
                    ? sql
                    : sql.sql()}
                </Typography.Text>
              </div>
            )}
          </Tabs.TabPane>
          {user.isAdmin ? (
            <Tabs.TabPane tab="Technical trace" key="trace">
              <TraceChart data={trace.events} />
            </Tabs.TabPane>
          ) : undefined}
          {user.isAdmin ? (
            <Tabs.TabPane tab="Technical trace (raw)" key="trace-raw">
              <Timeline mode={"left"}>
                {_.uniq(trace.events).map((t, i) => {
                  return (
                    <Timeline.Item key={i} label={`${t.offset / 1000}s`}>
                      <div>
                        <div>{t.evtName}</div>
                        <div>
                          <pre>{JSON.stringify(t.data, undefined, 4)}</pre>
                        </div>
                      </div>
                    </Timeline.Item>
                  );
                })}
              </Timeline>
            </Tabs.TabPane>
          ) : undefined}
        </Tabs>
      </Space>
    </div>
  );
}

export default compose<Props, IItemPerformanceProps>(WithOrg)(ItemPerformance);
