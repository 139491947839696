import {
  BarChartOutlined,
  FilterOutlined,
  FontSizeOutlined,
  LinkOutlined,
} from "@ant-design/icons";
import { Button, Space, Tooltip } from "antd";

interface IDashboardEditLeftActionBarProps {
  isAddChartOpen: boolean;
  onAddChartClick: () => void;
  onAddFilterClick: () => void;
  onAddTextClick: () => void;
  onAddNavigationClick: () => void;
}

type Props = IDashboardEditLeftActionBarProps;

export function DashboardEditLeftActionBar(props: Props) {
  const {
    isAddChartOpen,
    onAddChartClick,
    onAddFilterClick,
    onAddTextClick,
    onAddNavigationClick,
  } = props;

  return (
    <div className="report-edit-left-action-bar">
      <Space direction="vertical" size={"large"}>
        <Tooltip title="Add a chart" placement="right">
          <Button
            type={isAddChartOpen ? "link" : "text"}
            onClick={() => onAddChartClick()}
            icon={
              <BarChartOutlined
                style={{
                  fontSize: 16,
                }}
              />
            }
          />
        </Tooltip>
        <Tooltip title="Add a filter" placement="right">
          <Button
            type="text"
            onClick={() => onAddFilterClick()}
            icon={
              <FilterOutlined
                style={{
                  fontSize: 16,
                }}
              />
            }
          />
        </Tooltip>
        <Tooltip title="Add text" placement="right">
          <Button
            type="text"
            onClick={() => onAddTextClick()}
            icon={
              <FontSizeOutlined
                style={{
                  fontSize: 16,
                }}
              />
            }
          />
        </Tooltip>
        <Tooltip title="Add navigation" placement="right">
          <Button
            type="text"
            onClick={() => onAddNavigationClick()}
            icon={
              <LinkOutlined
                style={{
                  fontSize: 16,
                }}
              />
            }
          />
        </Tooltip>
      </Space>
    </div>
  );
}
