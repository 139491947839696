import * as React from "react";
import type { RouteComponentProps } from "react-router";
import { withRouter } from "react-router";
import { compose } from "../../../../components/compose/WlyCompose";
import type { AsyncData } from "../../../../helpers/typescriptHelpers";
import type { InjectedOrgProps } from "../../../orgs/WithOrg";
import WithOrg from "../../../orgs/WithOrg";
import { getRelatedMjml } from "./utils";

interface IRecordMjmlProps {}

type Props = IRecordMjmlProps &
  InjectedOrgProps &
  RouteComponentProps<{ recordId: string; layoutId: string; objectId: string }>;

function RecordMjml(props: Props) {
  const {
    org,
    user,
    match: {
      params: { recordId, layoutId, objectId },
    },
  } = props;

  const [data, setData] = React.useState<AsyncData<string[]>>({
    status: "initial",
  });

  React.useEffect(() => {
    const fetchData = async () => {
      setData({ status: "loading" });
      try {
        // need object with layout and record
        const data = await getRelatedMjml(
          org,
          user,
          layoutId,
          objectId,
          recordId
        );
        setData({ status: "success", data: data });
      } catch (err) {
        console.error(err);
        setData({ status: "error", error: err });
      }
    };
    fetchData();
  }, [recordId, layoutId, objectId]);

  (window as any).objectData = data;

  return <div>{JSON.stringify(data)}</div>;
}

export default compose<Props, IRecordMjmlProps>(
  WithOrg,
  withRouter
)(RecordMjml);
