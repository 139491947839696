import type {
  BinaryFilter,
  Filter,
  LogicalAndFilter,
  LogicalOrFilter,
  UnaryFilter,
} from "@cubejs-client/core";
import moment from "moment";

export const hasFilterGroup = (filters: Filter[]) => {
  if (
    filters &&
    filters.length &&
    ((filters[0] as LogicalOrFilter).or || (filters[0] as LogicalAndFilter).and)
  ) {
    return true;
  }
  return false;
};

const liftGroupWithSameConditions = (filters: Filter[]) => {
  // const allOrs =
  //   filters.length > 0 && filters.every((f) => !!(f as LogicalOrFilter).or);
  // const allAnds =
  //   filters.length > 0 && filters.every((f) => !!(f as LogicalAndFilter).and);

  const someOrs =
    filters.length > 0 && filters.some((f) => !!(f as LogicalOrFilter).or);
  const someAnds =
    filters.length > 0 && filters.some((f) => !!(f as LogicalAndFilter).and);

  // we lift when we share
  // the same operator
  // only one filter --- TODO
  // filters cannot be without a group in case of a group
  if (someOrs && !someAnds) {
    return liftGroupWithSameConditions(
      filters.flatMap((f) =>
        (f as LogicalOrFilter).or ? (f as LogicalOrFilter).or : [f]
      )
    );
  }
  if (!someOrs && someAnds) {
    return liftGroupWithSameConditions(
      filters.flatMap((f) =>
        (f as LogicalAndFilter).and ? (f as LogicalAndFilter).and : [f]
      )
    );
  }
  return filters;
};

export const rationalizeFilters = (filters: Filter[]) => {
  let depth: number = 1;
  const getDepth = (f: Filter[], n: number) => {
    f.forEach((a) => {
      if ((a as LogicalOrFilter).or) {
        return getDepth((a as LogicalOrFilter).or, n + 1);
      } else if ((a as LogicalAndFilter).and) {
        return getDepth((a as LogicalAndFilter).and, n + 1);
      }
      if (depth < n) {
        depth = n;
      }
    });
  };
  getDepth(filters, depth);

  let newFilters: Filter[] = filters;
  for (let i = 0; i <= depth; i++) {
    newFilters = liftGroupWithSameConditions(newFilters);
  }

  // avoid non OK config

  // return liftGroupWithSameConditions(filters);
  return newFilters;
};

export const cleanIncompleteFilters = (filters: Filter[]): Filter[] => {
  const hasError = (fil: UnaryFilter | BinaryFilter) =>
    !["notSet", "set"].includes(fil.operator) &&
    (!fil.values || fil.values.length < 1);

  const getNodes = (result: Filter[], filter: Filter) => {
    if ((filter as LogicalAndFilter).and) {
      const nodes = (filter as LogicalAndFilter).and.reduce(getNodes, []);
      if (nodes.length) result.push({ and: nodes });
    } else if ((filter as LogicalOrFilter).or) {
      const nodes = (filter as LogicalOrFilter).or.reduce(getNodes, []);
      if (nodes.length) result.push({ or: nodes });
    } else if (!hasError(filter as UnaryFilter | BinaryFilter)) {
      result.push(filter);
      return result;
    }

    return result;
  };
  return filters.reduce(getNodes, []);
};

export const validateFilters = (filters: Filter[]): boolean => {
  return filters && filters.length > 0
    ? filters.reduce((acc, f) => {
        if (!acc) {
          return false;
        }
        if ((f as LogicalAndFilter).and) {
          const r = validateFilters((f as LogicalAndFilter).and);
          return r;
        } else if ((f as LogicalOrFilter).or) {
          const r = validateFilters((f as LogicalOrFilter).or);
          return r;
        }
        const fil = f as UnaryFilter | BinaryFilter;
        const hasError =
          !["notSet", "set"].includes(fil.operator) &&
          (!fil.values || fil.values.length < 1);
        return !hasError;
      }, true)
    : true;
};

export const validateUsedDimensioFilters = (filters: Filter[]) => {
  return filters && filters.length > 0
    ? filters.reduce((acc, f) => {
        if (!acc) {
          return false;
        }
        if (!!(f as LogicalAndFilter).and) {
          const r = validateUsedDimensioFilters((f as LogicalAndFilter).and);
          return r;
        } else if (!!(f as LogicalOrFilter).or) {
          const r = validateUsedDimensioFilters((f as LogicalOrFilter).or);
          return r;
        }
        const fil = f as UnaryFilter | BinaryFilter;
        const isValid = !!fil.member;
        return isValid;
      }, true)
    : true;
};

export const agGridToCubeFilter = (filter: any, dimension: string): Filter => {
  if (filter.filterType === "set") {
    const values = filter.values as string[];
    let cubeFilter: Filter = { member: dimension, operator: "notSet" };
    if (values.length) {
      cubeFilter = {
        member: dimension,
        values: values,
        operator: "equals",
      };
    }
    return cubeFilter;
  } else if (filter.filterType === "number") {
    if (filter.type === "equals") {
      return {
        member: dimension,
        operator: "equals",
        values: [filter.filter],
      } as Filter;
    } else if (filter.type === "notEqual") {
      return {
        member: dimension,
        operator: "notEquals",
        values: [filter.filter],
      } as Filter;
    } else if (filter.type === "greaterThan") {
      return {
        member: dimension,
        operator: "gt",
        values: [filter.filter],
      } as Filter;
    } else if (filter.type === "greaterThanOrEqual") {
      return {
        member: dimension,
        operator: "gte",
        values: [filter.filter],
      } as Filter;
    } else if (filter.type === "lessThan") {
      return {
        member: dimension,
        operator: "lt",
        values: [filter.filter],
      } as Filter;
    } else if (filter.type === "lessThanOrEqual") {
      return {
        member: dimension,
        operator: "lte",
        values: [filter.filter],
      } as Filter;
    } else if (filter.type === "inRange") {
      return {
        and: [
          {
            member: dimension,
            operator: "gte",
            values: [filter.filter],
          },
          {
            member: dimension,
            operator: "lte",
            values: [filter.filterTo],
          },
        ],
      } as Filter;
    } else if (filter.type === "blank") {
      return {
        member: dimension,
        operator: "notSet",
      } as Filter;
    } else if (filter.type === "notBlank") {
      return {
        member: dimension,
        operator: "set",
      } as Filter;
    }
  } else if (filter.filterType === "date") {
    const format = "YYYY-MM-DD HH:mm:ss";
    if (filter.type === "equals") {
      return {
        member: dimension,
        operator: "inDateRange",
        values: [
          moment(filter.dateFrom).startOf("day").format(format),
          moment(filter.dateFrom).endOf("day").format(format),
        ],
      } as Filter;
    } else if (filter.type === "notEqual") {
      return {
        member: dimension,
        operator: "notInDateRange",
        values: [
          moment(filter.dateFrom).startOf("day").format(format),
          moment(filter.dateFrom).endOf("day").format(format),
        ],
      } as Filter;
    } else if (filter.type === "lessThan") {
      return {
        member: dimension,
        operator: "beforeDate",
        values: [moment(filter.dateFrom).startOf("day").format(format)],
      } as Filter;
    } else if (filter.type === "greaterThan") {
      return {
        member: dimension,
        operator: "afterDate",
        values: [moment(filter.dateFrom).endOf("day").format(format)],
      } as Filter;
    } else if (filter.type === "inRange") {
      return {
        member: dimension,
        operator: "inDateRange",
        values: [
          moment(filter.dateFrom).startOf("day").format(format),
          moment(filter.dateTo).endOf("day").format(format),
        ],
      } as Filter;
    } else if (filter.type === "blank") {
      return {
        member: dimension,
        operator: "notSet",
      } as Filter;
    } else if (filter.type === "notBlank") {
      return {
        member: dimension,
        operator: "set",
      } as Filter;
    }
  }
  return undefined;
};
