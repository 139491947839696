import { Skeleton } from "antd";

import "./ReportCard.scss";

interface IReportCardSkeletonProps {}

export function ReportCardSkeleton(props: IReportCardSkeletonProps) {
  return (
    <div className="report-card-wrapper">
      <div className="report-card">
        <div className="logo">
          <Skeleton.Button size="small" shape="circle" active />
        </div>
        <div className="title">
          <Skeleton.Button size="small" shape="round" block active />
        </div>
        <div style={{ marginLeft: 12 }} className="sources">
          <Skeleton.Button size="small" shape="round" block active />
        </div>
        <div style={{ marginLeft: 12 }} className="sources">
          <Skeleton.Button size="small" shape="circle" active />
        </div>
        <div style={{ marginLeft: 12 }} className="actions">
          <Skeleton.Button size="small" shape="circle" active />
        </div>
      </div>
    </div>
  );
}
