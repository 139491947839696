import type { DiagramEngine } from "@projectstorm/react-diagrams";
import { PortModelAlignment, PortWidget } from "@projectstorm/react-diagrams";
import * as React from "react";
import type { RouteComponentProps } from "react-router";
import { withRouter } from "react-router";
import { compose } from "../../../../../../../components/compose/WlyCompose";
import { SourceImageRenderer } from "../../../../../../../components/sources/SourceImageRenderer";
import type { ExplorationNodeModel } from "./ExplorationNodeModel";
import "./ExplorationNodeWidget.scss";

interface ExplorationNodeWidgetProps {
  node: ExplorationNodeModel;
  engine: DiagramEngine;
  size?: number;
}

export const HEIGHT = 52;
export const WIDTH = 188; // GRID IS 68 px so this should be a multiple of 68 - PORT SIZE
const PORT_SIZE = 16;

type Props = ExplorationNodeWidgetProps & RouteComponentProps<{}>;

class ExplorationNodeWidget extends React.Component<Props> {
  render() {
    const { node } = this.props;

    const inLinks = Object.keys(node.getInPort().getLinks());
    const outLinks = Object.keys(node.getOutPort().getLinks());

    return (
      <div
        className={`exploration-node-widget-wrapper`}
        style={{ height: HEIGHT + PORT_SIZE, width: WIDTH + PORT_SIZE }}
      >
        {inLinks.length > 0 ? (
          <PortWidget
            style={{
              top: HEIGHT / 2,
              left: 0,
              position: "absolute",
            }}
            port={this.props.node.getPort(PortModelAlignment.LEFT)}
            engine={this.props.engine}
          >
            <div className="exploration-port-widget" />
          </PortWidget>
        ) : null}
        <div
          className="exploration-node-widget-core"
          style={{ height: HEIGHT, width: WIDTH }}
        >
          {node.hasUpstreamErrors && (
            <div className="data-node-widget-warning" />
          )}
          <div className="exploration-node-widget-core-inner">
            <div className="exploration-node-widget-core-logo">
              <SourceImageRenderer
                size={24}
                alt={node.exploration?.name}
                img={":compass:"}
              />
            </div>
            <div className="exploration-node-widget-core-content">
              <div className="exploration-node-widget-core-content-title">
                {node.exploration?.name}
              </div>
            </div>
          </div>
        </div>
        {outLinks.length ? (
          <PortWidget
            style={{
              top: HEIGHT / 2,
              right: 0,
              position: "absolute",
            }}
            port={this.props.node.getPort(PortModelAlignment.RIGHT)}
            engine={this.props.engine}
          >
            <div className="exploration-port-widget" />
          </PortWidget>
        ) : null}
      </div>
    );
  }
}

export default compose<Props, ExplorationNodeWidgetProps>(withRouter)(
  ExplorationNodeWidget
);
