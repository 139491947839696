export const toggle = {
  render: "Toggle",
  children: ["paragraph", "tag", "list"],
  attributes: {
    title: {
      type: String,
      default: "Details",
    },
  },
};
