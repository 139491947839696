import type { IReportFolder } from "../../../../interfaces/folder";
import type { IObject } from "../../../../interfaces/object";

export const BORDER_COLOR = "#D8D8D8";

export const COLOR_UP = "#52c41a";
export const COLOR_DOWN = "#cf1322";
export const COLOR_NEUTRAL = "#a157d3";
export const COLOR_GOLD = "#d48806";

export interface IV2Context {
  folders: IReportFolder[];
  personalFolder: IReportFolder;
  allObjects: IObject[];
}
