import { gql } from "@apollo/client";
import type { MutateOptions } from "../../../../../../components/hooks/query/useQGLMutate";
import { useGQLMutate } from "../../../../../../components/hooks/query/useQGLMutate";
import type { ISchedule } from "../../../../../../interfaces/schedule";

type MutationData = { updateTopic: { id: string } };

type MutationVariables = {
  id: string;
  data: Partial<ISchedule>;
};

const UpdateScheduleMutation = gql`
  mutation UpdateSchedule($id: ID!, $data: ScheduleUpdateInput!) {
    updateSchedule(id: $id, data: $data) {
      id
    }
  }
`;

export const useUpdateSchedule = (options?: MutateOptions<MutationData>) => {
  return useGQLMutate<MutationData, MutationVariables>(
    UpdateScheduleMutation,
    options
  );
};
