import type {
  OperationType,
  Transformation,
} from "../../../../../../../interfaces/transformations";
import AddColumnForm from "./AddColumn/AddColumnForm";
import type { FlowOperationFormProps } from "./domain";
import FilterRowForm from "./FilterRow/FilterRowForm";
import FromDatasetOrModelForm from "./FromDatasetOrModel/FromDatasetOrModelForm";
import GroupByForm from "./GroupBy/groupByForm";
import HideColumnFom from "./HideColumn/HideColumnFom";
import LookupColumnForm from "./LookupRollupColumn/LookupRollupColumnForm";
import UnionForm from "./Union/UnionForm";

export const HAS_FORM: OperationType[] = [
  "Table.AddColumn",
  "WhalyExt.Table.AddLookupColumn",
  "WhalyExt.Table.AddRollupColumn",
  "Table.SelectRows",
  "Table.RemoveColumns",
  "Table.Group",
  "Table.Combine",
  "Table.FromWarehouseTable",
  "Table.FromWhalyDataset",
];

export const modifyChildKey = (
  parentTransformation: Transformation,
  oldChildKey: string,
  newChildKey: string
) => {
  let modifiedParentTransformation = parentTransformation;
  if (modifiedParentTransformation) {
    if (modifiedParentTransformation.operation.type === "Table.Combine") {
      modifiedParentTransformation = {
        ...modifiedParentTransformation,
        operation: {
          ...modifiedParentTransformation.operation,
          args: {
            ...modifiedParentTransformation.operation.args,
            table1:
              modifiedParentTransformation.operation.args.table1 === oldChildKey
                ? newChildKey
                : modifiedParentTransformation.operation.args.table1,
            table2:
              modifiedParentTransformation.operation.args.table2 === oldChildKey
                ? newChildKey
                : modifiedParentTransformation.operation.args.table2,
          },
        },
      };
    } else if (modifiedParentTransformation.operation.type === "Table.Join") {
      modifiedParentTransformation = {
        ...modifiedParentTransformation,
        operation: {
          ...modifiedParentTransformation.operation,
          args: {
            ...modifiedParentTransformation.operation.args,
            table1:
              modifiedParentTransformation.operation.args.table1 === oldChildKey
                ? newChildKey
                : modifiedParentTransformation.operation.args.table1,
            table2:
              modifiedParentTransformation.operation.args.table2 === oldChildKey
                ? newChildKey
                : modifiedParentTransformation.operation.args.table2,
          },
        },
      };
    } else if (
      modifiedParentTransformation.operation.type ===
      "WhalyExt.Table.AddLookupColumn"
    ) {
      modifiedParentTransformation = {
        ...modifiedParentTransformation,
        operation: {
          ...modifiedParentTransformation.operation,
          args: {
            ...modifiedParentTransformation.operation.args,
            table1:
              modifiedParentTransformation.operation.args.table1 === oldChildKey
                ? newChildKey
                : modifiedParentTransformation.operation.args.table1,
            table2:
              modifiedParentTransformation.operation.args.table2 === oldChildKey
                ? newChildKey
                : modifiedParentTransformation.operation.args.table2,
          },
        },
      };
    } else if (
      modifiedParentTransformation.operation.type ===
      "WhalyExt.Table.AddRollupColumn"
    ) {
      modifiedParentTransformation = {
        ...modifiedParentTransformation,
        operation: {
          ...modifiedParentTransformation.operation,
          args: {
            ...modifiedParentTransformation.operation.args,
            table1:
              modifiedParentTransformation.operation.args.table1 === oldChildKey
                ? newChildKey
                : modifiedParentTransformation.operation.args.table1,
            table2:
              modifiedParentTransformation.operation.args.table2 === oldChildKey
                ? newChildKey
                : modifiedParentTransformation.operation.args.table2,
          },
        },
      };
    } else {
      modifiedParentTransformation = {
        ...modifiedParentTransformation,
        operation: {
          ...(modifiedParentTransformation.operation as any),
          args: {
            ...modifiedParentTransformation.operation.args,
            table:
              (modifiedParentTransformation.operation.args as any).table ===
              oldChildKey
                ? newChildKey
                : (modifiedParentTransformation.operation.args as any).table,
          },
        },
      };
    }
  }
  return modifiedParentTransformation;
};

export class OperationHandler {
  private operation: Transformation;

  constructor(operation: Transformation) {
    this.operation = operation;
  }

  public getForm = (props: FlowOperationFormProps<any>) => {
    switch (this.operation.operation.type) {
      case "Table.AddColumn":
        return <AddColumnForm {...props} />;
      case "WhalyExt.Table.AddLookupColumn":
        return <LookupColumnForm {...props} />;
      case "WhalyExt.Table.AddRollupColumn":
        return <LookupColumnForm {...props} isRollup={true} />;
      case "Table.Ref":
        return <div>Not implemented</div>;
      case "Table.SelectRows":
        return <FilterRowForm {...props} />;
      case "Table.RemoveColumns":
        return <HideColumnFom {...props} />;
      case "Table.Group":
        return <GroupByForm {...props} />;
      case "Table.Combine":
        return <UnionForm {...props} />;
      case "Table.FromWarehouseTable":
      case "Table.FromWhalyDataset":
        return <FromDatasetOrModelForm {...props} />;
      default:
        return "Not implemented";
    }
  };
}
