import * as React from "react";
import ReactDOM from "react-dom";

interface IExplorationModalActionButtonProps {
  children?: React.ReactNode;
}

export default class ExplorationModalActionButton extends React.Component<IExplorationModalActionButtonProps> {
  getElement = () => {
    const els = document.getElementsByClassName(
      "exploration-title-action-portal"
    );
    if (els && els.length) {
      return els[0];
    }
  };

  public render() {
    const el = this.getElement();
    if (el) {
      return ReactDOM.createPortal(this.props.children, el);
    }
    return null;
  }
}
