import { Modal } from "antd";
import Org from "../../../../orgs/Org";
import "../settingsModal.scss";

interface IOrgPickerModalProps {
  open: boolean;
  onClose: () => void;
}

export function OrgPickerModal(props: IOrgPickerModalProps) {
  const { open, onClose } = props;
  return (
    <Modal
      maskClosable={false}
      closable={true}
      width={"50%"}
      centered
      className="settings-modal"
      destroyOnClose={true}
      styles={{
        body: {
          height: "100%",
          maxHeight: "100%",
          overflowX: "hidden",
          overflowY: "auto",
        },
      }}
      title={"Organizations"}
      onCancel={onClose}
      footer={null}
      open={open}
    >
      <Org />
    </Modal>
  );
}
