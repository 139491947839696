import * as React from "react";

import { Emoji } from "./Emoji";
import "./EmojiPicker.scss";

interface IEmojiRendererProps {
  style?: React.CSSProperties;
  onClick?: () => void;
  hasMargin?: boolean;
  selected?: boolean;
  emoji: string;
  size?: number;
  children?: React.ReactNode;
}

export default class EmojiRenderer extends React.PureComponent<IEmojiRendererProps> {
  public render() {
    const { style, onClick, hasMargin, emoji, selected, size } = this.props;
    const classes: string[] = ["emoji-picker-viewer"];
    if (hasMargin) {
      classes.push("margin");
    }
    if (selected) {
      classes.push("selected");
    }
    return (
      <div
        className={classes.join(" ")}
        onClick={onClick ? onClick : undefined}
        style={{
          width: size ? size : 32,
          height: size ? size : 32,
          ...style,
        }}
      >
        <Emoji emoji={emoji} size={size ? size : 32} />
        {this.props.children}
      </div>
    );
  }
}
