import Icon from "@ant-design/icons";
import type { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";

export const WlyFunnelChartIcon = (
  props: Partial<CustomIconComponentProps>
) => (
  <Icon
    component={() => (
      <svg
        width="1em"
        height="1em"
        viewBox="0 0 213 213"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_1008_1309)">
          <path d="M0 0.5L71.1172 57.1383V157.426L0 213V0.5Z" fill="#3A5C83" />
          <path
            d="M71.1172 57.1382C71.1172 57.1382 91.322 68.3542 105.398 72.4688C119.203 76.5042 141.809 80.9859 141.809 80.9859V132.088C141.809 132.088 119.195 137.681 105.398 141.883C91.3364 146.165 71.1172 157.426 71.1172 157.426V57.1382Z"
            fill="#669DF3"
          />
          <path
            d="M141.809 80.9861L212.926 92.9099V119.526L141.809 131.875V80.9861Z"
            fill="#69A2B0"
          />
        </g>
        <defs>
          <clipPath id="clip0_1008_1309">
            <rect width="213" height="213" fill="white" />
          </clipPath>
        </defs>
      </svg>
    )}
    {...props}
  />
);
