import { QuestionCircleOutlined } from "@ant-design/icons";
import { Checkbox, Form, Input } from "antd";
import { useForm } from "antd/es/form/Form";
import React from "react";
import type { InjectedAntUtilsProps } from "../../../../../../components/ant-utils/withAntUtils";
import { withAntUtils } from "../../../../../../components/ant-utils/withAntUtils";
import { compose } from "../../../../../../components/compose/WlyCompose";
import Loading from "../../../../../../components/layout/feedback/loading";
import WlyModal from "../../../../../../components/modal/WlyModal";
import type { SchemaResult } from "../../../../../../interfaces/transformations";
import {
  PKValidationRule,
  PrimaryKeyField,
} from "../../../dataset/tabs/common/PrimaryKeyField";

interface ICreateModelModalProps {
  visible: boolean;
  getSchema?: () => Promise<SchemaResult>;
  onClose: () => void;
  onCreate: (
    name: string,
    primaryKeys: string[],
    removeWorksheet?: boolean
  ) => Promise<any>;
}

type Props = ICreateModelModalProps & InjectedAntUtilsProps;

function CreateModelModal(props: Props) {
  const { visible, onClose, getSchema, antUtils, onCreate } = props;
  const [form] = useForm<{
    name: string;
    description?: string;
    keys: string[];
    removeWorksheet?: boolean;
  }>();
  const [loading, setLoading] = React.useState(true);
  const [schema, setSchema] = React.useState<SchemaResult | undefined>(
    undefined
  );

  React.useEffect(() => {
    if (!visible) {
      return setSchema(undefined);
    }
    const fetchSchema = async () => {
      try {
        setLoading(true);
        if (getSchema) {
          const schema = await getSchema();
          setSchema(schema);
        }
      } catch (error) {
        console.warn(error);
        antUtils.message.error(
          "Error while loading the schema, please try again"
        );
      } finally {
        setLoading(false);
        form.resetFields();
      }
    };
    fetchSchema();
  }, [visible]);

  const renderInner = () => {
    if (!visible) return <></>;
    if (loading) return <Loading />;
    if (!schema) return <>No schema associated with the table...</>;

    return (
      <Form
        layout="vertical"
        form={form}
        initialValues={{
          removeWorksheet: true,
        }}
        onFinish={async (v) => {
          setLoading(true);
          try {
            await form.validateFields();
            await onCreate(v.name, v.keys, v.removeWorksheet);
          } catch (err) {
            console.error(err);
            antUtils.message.error(
              "There was an error saving your model, try again..."
            );
          } finally {
            setLoading(false);
          }
        }}
      >
        <Form.Item
          label="Model name"
          name={"name"}
          required
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Primary key(s)"
          name={"keys"}
          required
          rules={PKValidationRule}
          extra={
            <>
              <QuestionCircleOutlined /> Select a key/combination of keys that
              returns a unique value for each row
            </>
          }
        >
          <PrimaryKeyField schema={schema} outdatedKeys={[]} />
        </Form.Item>
        <Form.Item name="removeWorksheet" valuePropName="checked">
          <Checkbox>Delete worksheet after model creation</Checkbox>
        </Form.Item>
      </Form>
    );
  };

  return (
    <WlyModal
      title="Save as model"
      open={visible}
      onClose={onClose}
      onOk={async () => {
        await form.submit();
      }}
      confirmLoading={loading}
    >
      {renderInner()}
    </WlyModal>
  );
}

export default compose<Props, ICreateModelModalProps>(withAntUtils)(
  CreateModelModal
);
