import { Tag, Typography } from "antd";
import React from "react";
import { compose } from "../../../../../components/compose/WlyCompose";
import type { AsyncData } from "../../../../../helpers/typescriptHelpers";
import type { IDestination } from "../../../../../interfaces/destinations";
import type { IDataset } from "../../../../../interfaces/sources";
import type { SchemaResult } from "../../../../../interfaces/transformations";
import { computeTransformations } from "../../../../../services/BrizoService";
import GraphQLService from "../../../../../services/graphql/GraphQLService";
import { generateUniqueId } from "../../../../../utils/uniqueId";
import type { InjectedOrgProps } from "../../../../orgs/WithOrg";
import WithOrg from "../../../../orgs/WithOrg";
import { RELATED_MODELS_QUERY } from "../../dataset/tabs/common/domain";
import type {
  AsyncStepData,
  IPreSaveModelStep,
} from "../../dataset/tabs/common/PreSaveModelSteps";
import { PreSaveModelSteps } from "../../dataset/tabs/common/PreSaveModelSteps";

interface IMigrationModalStep2Props {
  dataset: IDataset;
  destinationDataset: IDataset;
  setIsSchemaValid: (d: AsyncData<boolean>) => void;
  currentWarehouse: IDestination;
}

type Props = IMigrationModalStep2Props & InjectedOrgProps;

interface IState {
  schema: AsyncStepData<{}>;
  explorations: AsyncStepData<{}>;
  models: AsyncStepData<{}>;
}

class MigrationModalStep2 extends React.Component<Props, IState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      explorations: {
        status: "initial",
      },
      models: {
        status: "initial",
      },
      schema: {
        status: "initial",
      },
    };
  }

  render() {
    const {
      setIsSchemaValid,
      org,
      dataset,
      destinationDataset,
      currentWarehouse,
    } = this.props;

    const getSchema = async () => {
      const id = generateUniqueId();
      return computeTransformations(currentWarehouse.id, {
        base: [
          {
            var: id,
            domain: "dataset",
            operation: {
              type: "Table.FromWhalyDataset",
              args: {
                datasetId: dataset.id,
              },
            },
          },
          {
            var: generateUniqueId(),
            operation: {
              type: "Table.Schema",
              args: {
                table: id,
              },
            },
            domain: "viewResolver",
          },
        ],
        destination: [
          {
            var: id,
            domain: "dataset",
            operation: {
              type: "Table.FromWhalyDataset",
              args: {
                datasetId: destinationDataset.id,
              },
            },
          },
          {
            var: generateUniqueId(),
            operation: {
              type: "Table.Schema",
              args: {
                table: id,
              },
            },
            domain: "viewResolver",
          },
        ],
      });
    };

    const compareSchemas = (base: SchemaResult, destination: SchemaResult) => {
      const missingColumns: string[] = [];
      const wrongColumnType: string[] = [];

      Object.keys(base).forEach((k) => {
        if (!destination[k]) {
          missingColumns.push(k);
        } else if (destination[k].type !== base[k].type) {
          wrongColumnType.push(k);
        }
      });

      return {
        missingColumns: missingColumns,
        wrongColumnType: wrongColumnType,
      };
    };

    const steps: IPreSaveModelStep[] = [
      {
        name: <Typography.Text strong>Validating schema</Typography.Text>,
        onStart: async () => {
          this.setState((s) => ({
            ...s,
            schema: {
              status: "loading",
            },
          }));

          try {
            const schemas = (await getSchema()).data as {
              base: SchemaResult;
              destination: SchemaResult;
            };

            const isValidMigration = compareSchemas(
              schemas.base,
              schemas.destination
            );

            if (
              isValidMigration.missingColumns.length > 0 ||
              isValidMigration.wrongColumnType.length > 0
            ) {
              const missingText = isValidMigration.missingColumns.length ? (
                <span>
                  The following columns are missing from{" "}
                  {destinationDataset.name}:{" "}
                  {isValidMigration.missingColumns.map((k) => (
                    <Tag key={k}>{k}</Tag>
                  ))}
                  .
                </span>
              ) : (
                ""
              );

              const typeText = isValidMigration.wrongColumnType.length ? (
                <span>
                  The following columns from {destinationDataset.name} don't
                  share the same type:{" "}
                  {isValidMigration.wrongColumnType.map((k) => (
                    <Tag key={k}>{k}</Tag>
                  ))}
                  .
                </span>
              ) : (
                ""
              );

              this.setState((s) => ({
                ...s,
                schema: {
                  status: "warning",
                  data: {},
                  component: (
                    <>
                      <span>
                        Your schemas don't match, this might impact your drills
                        or relationships configuration. {missingText} {typeText}
                      </span>
                    </>
                  ),
                },
              }));
            } else {
              this.setState((s) => ({
                ...s,
                schema: {
                  status: "success",
                  data: {},
                  component: (
                    <div>Your migration will not impact your schema</div>
                  ),
                },
              }));
            }
          } catch (err) {
            this.setState((s) => ({
              ...s,
              schema: {
                status: "error",
                error: err,
                component: <>There was an error fetching your schema</>,
              },
            }));
          }
        },
        store: this.state.schema,
      },
      {
        name: (
          <Typography.Text strong>Checking exploration usage</Typography.Text>
        ),
        onStart: async () => {
          this.setState((s) => ({
            ...s,
            explorations: {
              status: "loading",
            },
          }));
          try {
            const explorations = await GraphQLService<{
              allExplorations: Array<{ id: string; name: string }>;
            }>(
              `
              query allExplorations($orgId: ID!, $datasetId: ID!) {
                allExplorations(where: { 
                  org: { id: $orgId }, 
                  deleted_not: true, 
                  tables_some: {view: { dataset: { id: $datasetId } } }
                }) {
                  id
                  name
                }               
              }
            `,
              {
                orgId: org.id,
                datasetId: dataset.id,
              }
            );

            if (
              this.state.schema.status === "warning" &&
              explorations.allExplorations.length > 0
            ) {
              this.setState((s) => ({
                ...s,
                explorations: {
                  status: "warning",
                  data: {},
                  component: (
                    <>
                      Make sure that your schema changes don't break the
                      following explorations{" "}
                      {explorations.allExplorations.map((d) => (
                        <Tag key={d.id}>{d.name}</Tag>
                      ))}
                    </>
                  ),
                },
              }));
            } else {
              this.setState((s) => ({
                ...s,
                explorations: {
                  status: "success",
                  data: {},
                  component: (
                    <>
                      {explorations.allExplorations.length} explorations
                      currently referencing <b>{dataset.name}</b> will be
                      migrated
                    </>
                  ),
                },
              }));
            }
          } catch (err) {
            this.setState((s) => ({
              ...s,
              explorations: {
                status: "error",
                error: err,
                component: (
                  <>There was an error fetching your explorations...</>
                ),
              },
            }));
          }
        },
        store: this.state.explorations,
      },
      {
        name: <Typography.Text strong>Checking models usage</Typography.Text>,
        onStart: async () => {
          this.setState((s) => ({
            ...s,
            models: {
              status: "loading",
            },
          }));
          try {
            const relatedModels = await GraphQLService<{
              allModels: Array<{ id: string; name: string }>;
            }>(RELATED_MODELS_QUERY, {
              orgId: org.id,
              datasetId: dataset.id,
            });

            if (
              this.state.schema.status === "warning" &&
              relatedModels.allModels.length > 0
            ) {
              this.setState((s) => ({
                ...s,
                models: {
                  status: "warning",
                  data: {},
                  component: (
                    <>
                      Make sure that your schema changes don't break the
                      following models{" "}
                      {relatedModels.allModels.map((d) => (
                        <Tag key={d.id}>{d.name}</Tag>
                      ))}
                    </>
                  ),
                },
              }));
            } else {
              this.setState((s) => ({
                ...s,
                models: {
                  status: "success",
                  data: {},
                  component: (
                    <>
                      {relatedModels.allModels.length} models currently
                      referencing <b>{dataset.name}</b> will be migrated.
                    </>
                  ),
                },
              }));
            }
          } catch (err) {
            this.setState((s) => ({
              ...s,
              models: {
                status: "error",
                error: err,
                component: (
                  <>There was an error fetching your explorations...</>
                ),
              },
            }));
          }
        },
        store: this.state.models,
      },
    ];

    return (
      <div>
        <PreSaveModelSteps
          steps={steps}
          onDone={async () => {
            if (
              this.state.explorations.status === "warning" ||
              this.state.explorations.status === "error" ||
              this.state.models.status === "warning" ||
              this.state.models.status === "error" ||
              this.state.schema.status === "warning" ||
              this.state.schema.status === "error"
            ) {
              setIsSchemaValid({ status: "error", error: new Error("error") });
            } else {
              setIsSchemaValid({ status: "success", data: true });
            }
          }}
        />
      </div>
    );
  }
}

export default compose<Props, IMigrationModalStep2Props>(WithOrg)(
  MigrationModalStep2
);
