import { gql } from "@apollo/client";
import type { QueryOptions } from "../../../../../components/hooks/query/useGQLQuery";
import { useGQLQuery } from "../../../../../components/hooks/query/useGQLQuery";
import type { INotificationJob } from "../../../../../interfaces/notification";

type QueryData = {
  _allNotificationJobsMeta: { count: number };
  allNotificationJobs: INotificationJob[];
};

type QueryVariables = {
  filters: Record<string, any>;
  pageSize: number;
  offset: number;
};

const NotificationJobsQuery = gql`
  query paginatedNotificationJobs(
    $filters: NotificationJobWhereInput!
    $pageSize: Int!
    $offset: Int!
  ) {
    _allNotificationJobsMeta(where: $filters) {
      count
    }
    allNotificationJobs(
      where: $filters
      sortBy: id_DESC
      first: $pageSize
      skip: $offset
    ) {
      id
      createdAt
      jobType
      executeAt
      status
      token
      notificationWorkflow {
        id
        channel
      }
      user {
        id
        firstName
        lastName
        email
        gravatar
        avatarColor
      }
      emailSubject
    }
  }
`;

export const useGetNotificationJobs = (
  options: QueryOptions<QueryData, QueryVariables>
) => {
  return useGQLQuery<QueryData, QueryVariables>(NotificationJobsQuery, options);
};
