import {
  AlignCenterOutlined,
  AlignLeftOutlined,
  AlignRightOutlined,
} from "@ant-design/icons";
import type { FormInstance } from "antd";
import { Form, Input, Radio, Space } from "antd";
import type { IObject } from "../../../../../../../../interfaces/object";
import { ChartOptionCollapse } from "../../../../../../../chart-options/components/ChartOptionCollapse";
import ChartOptionLine from "../../../../../../../chart-options/components/ChartOptionLine";
import { getObjectColumns } from "../../../../../object/domain";
import type { IRecord } from "../../../../domain";
import type { IWidget } from "../../../domain";
import type { IWidgetSeparatorConfig } from "../domain";

interface ButtonWidgetEditorProps {
  widget: IWidget;
  object: IObject;
  record: IRecord;
  conf: IWidgetSeparatorConfig;
  form: FormInstance;
}

export function ButtonWidgetEditor(props: ButtonWidgetEditorProps) {
  const { widget, object, record, conf, form } = props;

  const objectColumns = getObjectColumns(object).filter(
    (o) => !o.data.key.endsWith(".label")
  );

  return (
    <>
      <ChartOptionCollapse dark title="Alert Configuration">
        <Space size={"middle"} direction="vertical" style={{ width: "100%" }}>
          <ChartOptionLine
            items={[
              {
                content: <div>Align</div>,
                flex: `1`,
              },
              {
                content: (
                  <Form.Item name={["config", "align"]} noStyle>
                    <Radio.Group
                      options={[
                        {
                          value: "left",
                          label: <AlignLeftOutlined />,
                        },
                        {
                          value: "center",
                          label: <AlignCenterOutlined />,
                        },
                        {
                          value: "right",
                          label: <AlignRightOutlined />,
                        },
                      ]}
                      optionType="button"
                      buttonStyle="solid"
                    />
                  </Form.Item>
                ),
                flex: `0 auto`,
              },
            ]}
          />
          <ChartOptionLine
            items={[
              {
                content: <div>Title</div>,
                flex: `1`,
              },
              {
                content: (
                  <Form.Item name={["config", "text"]} noStyle>
                    <Input />
                  </Form.Item>
                ),
                flex: `0 auto`,
              },
            ]}
          />
        </Space>
      </ChartOptionCollapse>
    </>
  );
}
