import ApiService from "./ApiService";

export const githubEndpoint =
  "https://raw.githubusercontent.com/whalyapp/whaly-docs/master/";

export const getReadme = async (path: string): Promise<string> => {
  return ApiService.getRequest(
    path,
    {},
    {},
    {
      authenticated: false,
      customDomain: githubEndpoint,
    }
  );
};
