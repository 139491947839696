import { SearchOutlined } from "@ant-design/icons";
import { Button, Checkbox, Col, Form, Input, Row } from "antd";
import * as React from "react";
import { compose } from "../../../components/compose/WlyCompose";
import FormActions from "../../../components/form/actions/FormActions";
import Feednack from "../../../components/layout/feedback/feedback";
import Loading from "../../../components/layout/feedback/loading";
import type { AsyncData } from "../../../helpers/typescriptHelpers";
import type { IDestination } from "../../../interfaces/destinations";
import type { IDimension, IMetric } from "../../../interfaces/table";
import type {
  SchemaResult,
  TableRemoveColumnOperation,
} from "../../../interfaces/transformations";
import { computeTransformations } from "../../../services/BrizoService";
import GraphQLService from "../../../services/graphql/GraphQLService";
import { optimizeStack } from "../../../utils/optimizeTransformation";
import { generateUniqueId } from "../../../utils/uniqueId";
import type { InjectedOrgProps } from "../../orgs/WithOrg";
import WithOrg from "../../orgs/WithOrg";
import TypeRenderer from "../../spreadsheet/renderer/TypeRenderer";
import type { ICreateComponentProps } from "../domain";
import "./HideColumn.scss";

type CreateEditProps = ICreateComponentProps<{
  var: string;
  operation: TableRemoveColumnOperation;
  domain: "viewResolver";
}> & {
  viewId?: string;
  relationships: string[];
  drills: string[];
  primaryKeys: string[];
  currentWarehouse: IDestination;
};

const GRAPHQL_QUERY = `
query getUsedColumns($orgId: ID!, $columns: [String], $viewId: ID!) {
  allDimensions(where: { org: {id: $orgId }, columnName_in: $columns, table: { view: { id: $viewId } } }) {
    columnName
  }
  allMetrics(where: { org: {id: $orgId }, columnName_in: $columns, expression_not: COMPUTED, table: { view: { id: $viewId } } }) {
    columnName
  }
}
`;

type Props = CreateEditProps & InjectedOrgProps;

const CreateEdit: React.FunctionComponent<Props> = (props) => {
  const {
    onSave,
    transformations,
    submitting,
    onCancel,
    org,
    isStale,
    currentTransformation,
    viewId,
    relationships,
    primaryKeys,
    drills,
    setFormInstance,
    currentWarehouse,
  } = props;

  const [fullSchemas, setFullSchemas] = React.useState<
    AsyncData<{ [key: string]: SchemaResult }>
  >({ status: "initial" });
  const [selectedSchema, setSelectedSchema] = React.useState<string[]>([]);
  const [usedColumnsInView, setUsedColumnsInView] = React.useState<string[]>(
    []
  );
  const [search, setSearch] = React.useState<string>();
  const [form] = Form.useForm();

  if (setFormInstance) {
    setFormInstance(form);
  }

  const varReplacementRaw: Array<{ from: string; to: string }> = [];
  const filteredTransformations = transformations.filter((t) => {
    if (
      t.operation.type === "Table.RemoveColumns" ||
      t.operation.type === "Table.SelectColumns"
    ) {
      varReplacementRaw.push({
        from: t.var,
        to: t.operation.args.table,
      });
      return false;
    }
    return true;
  });

  const refinedTransformations = optimizeStack(
    filteredTransformations,
    varReplacementRaw
  );

  React.useEffect(() => {
    async function fetchSchemas() {
      setFullSchemas({ status: "loading" });
      await computeTransformations(currentWarehouse?.id, {
        full: [
          ...refinedTransformations,
          {
            var: generateUniqueId(),
            operation: {
              type: "Table.Schema",
              args: {
                table:
                  refinedTransformations[refinedTransformations.length - 1].var,
              },
            },
            domain: "viewResolver",
          },
        ],
        selected: [
          ...transformations,
          {
            var: generateUniqueId(),
            operation: {
              type: "Table.Schema",
              args: {
                table: transformations[transformations.length - 1].var,
              },
            },
            domain: "viewResolver",
          },
        ],
      })
        .then((r) => {
          const promise = Promise.resolve();
          if (viewId) {
            promise.then(() => {
              return GraphQLService(GRAPHQL_QUERY, {
                orgId: org.id,
                columns: Object.keys(r.data.selected),
                viewId: viewId,
              }).then((t) => {
                const dim = t.allDimensions as IDimension[];
                const met = t.allMetrics as IMetric[];
                setUsedColumnsInView([
                  ...dim.flatMap((d) =>
                    d.type === "standard"
                      ? d.columnName
                      : [d.latitude, d.longitude]
                  ),
                  ...met.map((m) => m.columnName),
                ]);
              });
            });
          }

          promise.finally(() => {
            setSelectedSchema(Object.keys(r.data.selected));
            setFullSchemas({ status: "success", data: r.data as any });
          });
        })
        .catch((r) => {
          setFullSchemas({ status: "error", error: r });
        });
    }
    fetchSchemas();
  }, [currentWarehouse?.id]);

  if (fullSchemas.status === "loading" || fullSchemas.status === "initial") {
    return <Loading />;
  }

  if (fullSchemas.status === "error") {
    return <Feednack>{fullSchemas.error.message}</Feednack>;
  }

  // const hideColumns = transformations.filter(t => t.operation.type === "Table.RemoveColumns");
  // if (hideColumns.length > 1) {
  //   return <div>Error: you must have only one Remove Column</div>
  // }

  const disabledColumns = Object.keys(fullSchemas.data.full)
    .map((k) => ({
      key: k,
      domain: fullSchemas.data.full[k].domain,
      formula: fullSchemas.data.full[k].operation === "Table.AddColumn",
    }))
    .filter((ck, i) => {
      const reasons = [];
      if (usedColumnsInView.includes(ck.key)) {
        reasons.push("exploration");
      }
      if (relationships.includes(ck.key)) {
        reasons.push("relationship");
      }
      if (primaryKeys.includes(ck.key)) {
        reasons.push("primary keys");
      }
      if (drills.includes(ck.key)) {
        reasons.push("drill");
      }
      return reasons.length > 0;
    });

  return (
    <Form
      form={form}
      initialValues={{ columns: selectedSchema }}
      onFieldsChange={() => {
        isStale && isStale(true);
      }}
      onFinish={async (v) => {
        const currentlySelected = v.columns as string[];
        const toRemoveColumn = Object.keys(fullSchemas.data.full).filter(
          (k) => currentlySelected.indexOf(k) < 0
        );

        // const toAdd = currentlySelected.filter(c => !selectedSchema.find(s => s === c));
        // const toDelete = selectedSchema.filter(s => !(v.columns as string[]).find(c => c === s));

        const isEditing = !!transformations.find(
          (t) => t.var === currentTransformation.var
        );
        if (isEditing) {
          return Promise.resolve(
            onSave(
              transformations.map((t) => {
                if (t.var === currentTransformation.var) {
                  return {
                    ...t,
                    operation: {
                      ...t.operation,
                      args: {
                        ...t.operation.args,
                        columns: toRemoveColumn,
                      },
                    } as TableRemoveColumnOperation,
                  };
                }
                return t;
              })
            )
          ).then(() => onCancel && onCancel());
        } else {
          return Promise.resolve(
            onSave([
              ...transformations,
              {
                ...currentTransformation,
                operation: {
                  ...currentTransformation.operation,
                  args: {
                    ...currentTransformation.operation.args,
                    columns: toRemoveColumn,
                  },
                },
              },
            ])
          ).then(() => onCancel && onCancel());
        }
      }}
      layout="vertical"
      className="form-dropdown-form"
    >
      <div className="form-dropdown-form-header hide-column-header">
        <div>
          <Button
            className="left"
            onClick={() => {
              form.setFieldsValue({
                columns: Object.keys(fullSchemas.data.full),
              });
            }}
            size="small"
          >
            Select All
          </Button>
          <Button
            className="right"
            onClick={() => {
              form.setFieldsValue({
                columns: disabledColumns.map((o) => o.key),
              });
            }}
            size="small"
          >
            Hide All
          </Button>
        </div>
        <div style={{ padding: "6px 0" }}>
          <Input
            value={search}
            onChange={(v) => setSearch(v.target.value)}
            addonAfter={<SearchOutlined />}
          />
        </div>
      </div>
      <div className="form-dropdown-form-content">
        <Form.Item style={{ marginBottom: 0 }} shouldUpdate={true}>
          {() => {
            return (
              <Form.Item style={{ marginBottom: 0 }} name={["columns"]}>
                <Checkbox.Group style={{ width: "100%" }}>
                  <Row>
                    {Object.keys(fullSchemas.data.full)
                      .map((k) => ({
                        key: k,
                        domain: fullSchemas.data.full[k].domain,
                        formula:
                          fullSchemas.data.full[k].operation ===
                          "Table.AddColumn",
                      }))
                      .map((ck, i) => {
                        const reasons = [];
                        if (usedColumnsInView.includes(ck.key)) {
                          reasons.push("exploration");
                        }
                        if (relationships.includes(ck.key)) {
                          reasons.push("relationship");
                        }
                        if (primaryKeys.includes(ck.key)) {
                          reasons.push("primary keys");
                        }
                        if (drills.includes(ck.key)) {
                          reasons.push("drill");
                        }
                        const disabled = disabledColumns
                          .map((c) => c.key)
                          .includes(ck.key);
                        if (search) {
                          if (
                            !ck.key.toLowerCase().includes(search.toLowerCase())
                          ) {
                            return (
                              <Col
                                style={{ display: "none" }}
                                key={ck.key}
                                span={24}
                              >
                                <Checkbox disabled={disabled} value={ck.key}>
                                  <TypeRenderer
                                    domain={ck.domain}
                                    formula={ck.formula}
                                  />{" "}
                                  {ck.key}
                                </Checkbox>
                              </Col>
                            );
                          }
                        }
                        return (
                          <Col key={ck.key} span={24}>
                            <Checkbox disabled={disabled} value={ck.key}>
                              <TypeRenderer
                                domain={ck.domain}
                                formula={ck.formula}
                              />{" "}
                              {ck.key}{" "}
                              {reasons.length
                                ? `(used in ${reasons.join(" and ")})`
                                : undefined}
                            </Checkbox>
                          </Col>
                        );
                      })}
                  </Row>
                </Checkbox.Group>
              </Form.Item>
            );
          }}
        </Form.Item>
      </div>
      {onCancel && (
        <div className="form-dropdown-form-buttons">
          <FormActions
            size="small"
            flex={true}
            isSubmitting={submitting}
            onCancel={onCancel}
          />
        </div>
      )}
    </Form>
  );
};

export default compose<Props, CreateEditProps>(WithOrg)(CreateEdit);
