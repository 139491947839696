import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import {
  Button,
  ColorPicker,
  Flex,
  Form,
  Input,
  InputNumber,
  Select,
  Typography,
} from "antd";
import type { FormInstance } from "antd/lib";
import type { IObject } from "../../../../../../../interfaces/object";
import type { SchemaResult } from "../../../../../../../interfaces/transformations";
import { SchemaSelect } from "../../common/SchemaSelect";
import {
  colorPresets,
  getColorAtIndex,
  normalizeColorPickerValue,
  renderColorPanel,
} from "./domain";

interface IActivitiesFormatterProps {
  form: FormInstance;
  schema: SchemaResult;
  allObjects: IObject[];
}

export function ActivitiesFormatter(props: IActivitiesFormatterProps) {
  const { form, schema, allObjects } = props;

  return (
    <>
      <Form.Item name={["sortingAndFilteringColumn"]} label="Sorting property">
        <SchemaSelect schema={schema} alreadyUsed={[]} />
      </Form.Item>
      <Typography.Text style={{ marginBottom: 8 }}>
        Window configuration
      </Typography.Text>
      <div style={{ height: 8 }} />
      <Flex
        gap={8}
        style={{
          height: 45,
        }}
      >
        <Form.Item name={["formatterConfig", "dateRange", "window"]}>
          <Select style={{ width: 125 }}>
            <Select.Option value="lookback">Previous</Select.Option>
            <Select.Option value="lookforward">Next</Select.Option>
          </Select>
        </Form.Item>
        <Flex flex={1}>
          <Form.Item
            style={{ width: "100%" }}
            name={["formatterConfig", "dateRange", "number"]}
          >
            <InputNumber style={{ width: "100%" }} />
          </Form.Item>
        </Flex>
        <Form.Item name={["formatterConfig", "dateRange", "unit"]}>
          <Select style={{ width: 125 }}>
            <Select.Option value="day">Days</Select.Option>
            <Select.Option value="week">Weeks</Select.Option>
            <Select.Option value="month">Months</Select.Option>
            <Select.Option value="year">Years</Select.Option>
          </Select>
        </Form.Item>
      </Flex>
      <Typography.Text style={{ marginBottom: 8 }}>Series</Typography.Text>
      <div style={{ height: 8 }} />
      <Form.List name={["formatterConfig", "series"]}>
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, ...restField }) => (
              <Flex
                key={key}
                gap={8}
                style={{
                  height: 45,
                }}
              >
                <Form.Item
                  {...restField}
                  normalize={normalizeColorPickerValue}
                  name={[name, "color"]}
                >
                  <ColorPicker
                    format="hex"
                    disabledAlpha
                    presets={colorPresets}
                    panelRender={renderColorPanel}
                  />
                </Form.Item>
                <Form.Item {...restField} name={[name, "key"]}>
                  <Input
                    placeholder="your serie property key"
                    addonBefore={"key"}
                  />
                </Form.Item>
                <Flex flex={1}>
                  <Form.Item
                    style={{ width: "100%" }}
                    {...restField}
                    name={[name, "label"]}
                  >
                    <Input
                      placeholder="Your Serie Label"
                      addonBefore={"label"}
                    />
                  </Form.Item>
                </Flex>
                <Form.Item {...restField} name={[name, "type"]}>
                  <Select style={{ width: 125 }}>
                    <Select.Option value="bubble">Bubble</Select.Option>
                    <Select.Option value="band">Band</Select.Option>
                  </Select>
                </Form.Item>

                <Form.Item {...restField} name={[name, "drillObjectId"]}>
                  <Select
                    style={{ width: 125 }}
                    placeholder="Drill on"
                    allowClear
                    options={(allObjects ?? []).map((obj) => ({
                      label: obj.name,
                      value: obj.id,
                    }))}
                  />
                </Form.Item>

                <Form.Item>
                  <Button
                    icon={<DeleteOutlined />}
                    onClick={() => remove(name)}
                  />
                </Form.Item>
              </Flex>
            ))}
            <Form.Item>
              <Button
                type="dashed"
                onClick={() =>
                  add({
                    color: getColorAtIndex(
                      form.getFieldValue("formatterConfig")?.values?.length ?? 0
                    ),
                    key: "Change me",
                    type: "bubble",
                  })
                }
                block
                icon={<PlusOutlined />}
              >
                Add field
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>
    </>
  );
}
