import { foldGutter } from "@codemirror/language";

export const sqlFoldGutter = foldGutter({
  markerDOM(open: boolean): HTMLElement {
    const node = document.createElement("div");
    node.style.width = "12px";
    node.style.height = "18px";
    node.style.fontSize = "10px";
    node.style.display = "flex";
    node.style.alignItems = "center";
    if (!open) {
      node.className = "foldIcon foldIcon-closed";
      node.style.color = "#707070";
    } else {
      node.className = "foldIcon foldIcon-opened";
      node.style.color = "#B1B6C4";
    }

    const iconSvg = document.createElementNS(
      "http://www.w3.org/2000/svg",
      "svg"
    );
    iconSvg.setAttribute("viewBox", "64 64 896 896");
    iconSvg.setAttribute("width", "1em");
    iconSvg.setAttribute("height", "1em");
    iconSvg.setAttribute("fill", "currentColor");

    if (open) {
      const iconPath = document.createElementNS(
        "http://www.w3.org/2000/svg",
        "path"
      );
      iconPath.setAttribute(
        "d",
        "M884 256h-75c-5.1 0-9.9 2.5-12.9 6.6L512 654.2 227.9 262.6c-3-4.1-7.8-6.6-12.9-6.6h-75c-6.5 0-10.3 7.4-6.5 12.7l352.6 486.1c12.8 17.6 39 17.6 51.7 0l352.6-486.1c3.9-5.3.1-12.7-6.4-12.7z"
      );
      iconSvg.appendChild(iconPath);
    } else {
      const iconPath = document.createElementNS(
        "http://www.w3.org/2000/svg",
        "path"
      );
      iconPath.setAttribute(
        "d",
        "M765.7 486.8L314.9 134.7A7.97 7.97 0 00302 141v77.3c0 4.9 2.3 9.6 6.1 12.6l360 281.1-360 281.1c-3.9 3-6.1 7.7-6.1 12.6V883c0 6.7 7.7 10.4 12.9 6.3l450.8-352.1a31.96 31.96 0 000-50.4z"
      );
      iconSvg.appendChild(iconPath);
    }
    node.appendChild(iconSvg);
    return node;
  },
});
