import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Dropdown } from "antd";
import cuid from "cuid";
import * as React from "react";
import type { InjectedAntUtilsProps } from "../../../components/ant-utils/withAntUtils";
import { withAntUtils } from "../../../components/ant-utils/withAntUtils";
import { compose } from "../../../components/compose/WlyCompose";

import "./FormDropdown.scss";

interface IFormDropdownProps {
  renderDropdownElement: (
    isStale: (isStale: boolean) => void,
    onClose: () => void
  ) => React.ReactNode;
  position?:
    | "topLeft"
    | "topRight"
    | "bottomLeft"
    | "bottomRight"
    | "topCenter"
    | "bottomCenter";
  attachToId?: string;
  children?: React.ReactNode;
}

interface IState {
  dropDownvisible: boolean;
  contentVisible: boolean;
  confirmOpen: boolean;
  isFormStale: boolean;
}

type Props = IFormDropdownProps & InjectedAntUtilsProps;

class FormDropdown extends React.Component<Props, IState> {
  id = cuid();
  node: any;

  constructor(props: Props) {
    super(props);
    this.state = {
      dropDownvisible: false,
      contentVisible: false,
      isFormStale: false,
      confirmOpen: false,
    };
  }

  public componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside, false);
  }

  public componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside, false);
  }

  handleClickOutside = (e: any) => {
    const { antUtils } = this.props;
    if (this.node.contains(e.target) || this.state.confirmOpen) {
      return;
    }
    const isStale = this.state.isFormStale;
    if (isStale) {
      this.setState({ confirmOpen: true }, () => {
        antUtils.modal.confirm({
          title: "Discard your changes?",
          icon: <ExclamationCircleOutlined />,
          onOk: () => {
            this.closeDropdown();
          },
          onCancel: () => {
            this.openDropdown();
          },
        });
      });
    } else {
      this.closeDropdown();
      return;
    }
  };

  public closeDropdown = () => {
    this.setState(
      {
        dropDownvisible: false,
        confirmOpen: false,
        isFormStale: false,
      },
      () => {
        setTimeout(() => {
          this.setState({
            contentVisible: false,
          });
        }, 90);
      }
    );
  };

  public openDropdown = () => {
    this.setState({
      dropDownvisible: true,
      contentVisible: true,
      confirmOpen: false,
      isFormStale: false,
    });
  };

  public getPopupContainer = () => {
    const el = document.getElementById(
      this.props.attachToId ? this.props.attachToId : this.id
    );
    if (el) {
      return el;
    }
    return document.body;
  };

  public isStale = (stale: boolean) => {
    if (this.state.isFormStale !== stale) {
      this.setState({
        isFormStale: stale,
      });
    }
  };

  public render() {
    const { renderDropdownElement, position } = this.props;
    const { contentVisible } = this.state;
    return (
      <span ref={(node) => (this.node = node)} className="form-dropdown">
        <Dropdown
          dropdownRender={() =>
            contentVisible ? (
              <div
                className="form-dropdown-menu ant-dropdown-menu"
                style={{ background: "#fff", padding: 0 }}
              >
                {renderDropdownElement(this.isStale, this.closeDropdown)}
              </div>
            ) : (
              <div />
            )
          }
          open={this.state.dropDownvisible}
          getPopupContainer={this.getPopupContainer}
          placement={position ? position : "bottomLeft"}
          overlayClassName="form-dropdown-overlay"
          overlayStyle={{ zIndex: 1000 }}
        >
          <span
            id={this.id}
            onClick={
              this.state.dropDownvisible
                ? this.closeDropdown
                : this.openDropdown
            }
          >
            {this.props.children}
          </span>
        </Dropdown>
      </span>
    );
  }
}

export default compose<Props, IFormDropdownProps>(withAntUtils)(FormDropdown);
