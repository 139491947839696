import { Form, Input, Popover, Select, Typography } from "antd";
import * as React from "react";
import { compose } from "../../../../../../../../components/compose/WlyCompose";
import Feednack from "../../../../../../../../components/layout/feedback/feedback";
import Loading from "../../../../../../../../components/layout/feedback/loading";
import { SourceImageRenderer } from "../../../../../../../../components/sources/SourceImageRenderer";
import type { AsyncData } from "../../../../../../../../helpers/typescriptHelpers";
import type {
  IDataset,
  IDatasetRelationship,
} from "../../../../../../../../interfaces/sources";
import type {
  SchemaResult,
  Transformation,
  WhalyExtTableLookupOperation,
} from "../../../../../../../../interfaces/transformations";
import { computeTransformations } from "../../../../../../../../services/BrizoService";
import GraphQLService from "../../../../../../../../services/graphql/GraphQLService";
import { generateUniqueId } from "../../../../../../../../utils/uniqueId";
import type { InjectedOrgProps } from "../../../../../../../orgs/WithOrg";
import WithOrg from "../../../../../../../orgs/WithOrg";
import TypeRenderer from "../../../../../../../spreadsheet/renderer/TypeRenderer";

import type { IDestination } from "../../../../../../../../interfaces/destinations";
import {
  catchErrors,
  validateColumnName,
} from "../../../../../../../transformations/domain";
import { getDisplayName, type FlowOperationFormProps } from "../domain";

const { Text } = Typography;
const { Option, OptGroup } = Select;

type LookupColumnFormProps = FlowOperationFormProps<{
  var: string;
  operation: WhalyExtTableLookupOperation;
  domain: "viewResolver";
}> & {
  isRollup?: boolean;
  currentWarehouse: IDestination;
};

type Props = LookupColumnFormProps & InjectedOrgProps;

const id = "test";

interface AvailableNode {
  nodeId: string;
  relationshipId?: string;
}

type SelectedRelationship = NodeRelationshipSelection | RelationshipSelection;

interface NodeRelationshipSelection {
  type: "NODE";
  id: string;
  relationshipId?: string;
}
interface RelationshipSelection {
  type: "RELATIONSHIP" | "NODE";
  id: string;
}

const LookupColumnForm: React.FunctionComponent<Props> = (props) => {
  const {
    onSave,
    currentTransformation,
    transformations,
    onCancel,
    org,
    isStale,
    setFormInstance,
    prevStepSchema,
    canvasNodeGenerator,
    datasets,
    isRollup,
    setSubmitting,
    onNodeHighlight,
    currentWarehouse,
  } = props;

  const isEditing =
    transformations.find((t) => t.var === currentTransformation.var) &&
    currentTransformation.operation.args.table2 &&
    currentTransformation.operation.args.key2;

  const [form] = Form.useForm();
  const [schemas, setSchemas] = React.useState<
    AsyncData<{
      schema: { [key: string]: SchemaResult };
      relationships: IDatasetRelationship[];
      availableNodes: Array<AvailableNode>;
    }>
  >({ status: "initial" });
  const [selectedRelationship, setSelectedRelationship] = React.useState<
    SelectedRelationship | undefined
  >(
    isEditing
      ? {
          type: "NODE",
          id: currentTransformation.operation.args.table2,
          relationshipId: null,
        }
      : undefined
  );

  const isFormDisabled = !onSave;

  const [selectedAggregation, setSelectedAggregation] = React.useState<
    string | undefined
  >(
    currentTransformation.operation.args.aggregationType
      ? currentTransformation.operation.args.aggregationType
      : undefined
  );

  React.useEffect(() => {
    if (setFormInstance) {
      setFormInstance(form);
    }
  }, [setFormInstance, form]);

  // step 1 - list related dataset
  // step 2 - fetch dataset schema
  // step 3 - print form
  // step 4 - submit form

  React.useEffect(() => {
    async function fetchSchemas() {
      setSchemas({ status: "loading" });

      const tabItem = canvasNodeGenerator.getCurrentTabItem(
        currentTransformation.var
      );

      const computeParent = (key: string) => {
        const parent = canvasNodeGenerator.getParentKey(key);
        if (parent) {
          return computeParent(parent);
        }
        return key;
      };

      let currentParent = computeParent(currentTransformation.var);

      const solitaryNodes = canvasNodeGenerator.parentKeys
        .filter((p) => {
          return p !== currentParent;
        })
        .map((p) => {
          return canvasNodeGenerator.getCurrentTabItem(p);
        });

      const currentSelectedKey = currentTransformation.operation.args.table2;

      if (
        currentSelectedKey &&
        canvasNodeGenerator.getNodeIndex(currentSelectedKey)
      ) {
        const tabItem =
          canvasNodeGenerator.getCurrentTabItem(currentSelectedKey);
        solitaryNodes.push(tabItem);
      }

      await GraphQLService(
        `
      query getDatasetRelationship($datasetId: ID!, $outgoingRelationshipType: [String]!, $incomingRelationshipType: [String]!) {
        Dataset(where:{id: $datasetId}) {
          id
          rawQuery
          outgoingRelationships(where: { type_in: $outgoingRelationshipType, deleted_not: true, right: { deleted_not: true } }) {
            id
            from
            to
            left {
              id
              name
              source {
                sourceMeta {
                  publicInfo {
                    logo
                  }
                }
              }
            }
            right {
              id
              name
              rawQuery
              source {
                sourceMeta {
                  publicInfo {
                    logo
                  }
                }
              }
            }
          }
          incomingRelationships(where: { type_in: $incomingRelationshipType, deleted_not: true, left: { deleted_not: true } }) {
            id
            from
            to
            left {
              id
              name
              rawQuery
              source {
                sourceMeta {
                  publicInfo {
                    logo
                  }
                }
              }
            }
            right {
              id
              name
              source {
                sourceMeta {
                  publicInfo {
                    logo
                  }
                }
              }
            }
          }
        }
      }
      `,
        {
          datasetId: tabItem.datasetId,
          outgoingRelationshipType: isRollup ? ["1-N"] : ["N-1", "1-1"],
          incomingRelationshipType: isRollup ? ["N-1"] : ["1-N", "1-1"],
        }
      )
        .then((d) => {
          return d.Dataset;
        })
        .then((d: IDataset) => {
          const relationships = [
            ...d.outgoingRelationships,
            ...d.incomingRelationships.map((ir) => {
              return {
                id: ir.id,
                from: ir.to,
                to: ir.from,
                left: ir.right,
                right: ir.left,
              } as IDatasetRelationship;
            }),
          ];
          return relationships;
        })
        .then((r) => {
          const solitaryNodesQueries = solitaryNodes.reduce((acc, v, i) => {
            return {
              ...acc,
              [v.key]: [
                ...v.query,
                {
                  var: generateUniqueId(),
                  operation: {
                    type: "Table.Schema",
                    args: {
                      table: v.key,
                    },
                  },
                },
              ],
            };
          }, {} as {});

          const queries = r.reduce(
            (acc, v, i) => {
              const query = JSON.parse(v.right.rawQuery);
              return {
                ...acc,
                [v.id]: [
                  ...query,
                  {
                    var: generateUniqueId(),
                    operation: {
                      type: "Table.Schema",
                      args: {
                        table: query[query.length - 1].var,
                      },
                    },
                  },
                ],
              };
            },
            {
              ...solitaryNodesQueries,
            } as {}
          );
          return computeTransformations(
            currentWarehouse.id,
            queries,
            false,
            false
          ).then((resp) => ({
            relationships: r,
            schema: resp.data as any,
          }));
        })
        .then((r) => {
          const availableNodeRelationship: Array<AvailableNode> =
            solitaryNodes.map((sn) => {
              return {
                nodeId: sn.key,
              };
            });

          setSchemas({
            status: "success",
            data: {
              ...r,
              availableNodes: availableNodeRelationship,
            },
          });
        })
        .catch((err) => {
          setSchemas({ status: "error", error: err });
        });
    }
    if (schemas.status !== "loading") {
      fetchSchemas();
    }
  }, [currentTransformation.var, canvasNodeGenerator.parentKeys]);

  if (schemas.status === "loading" || schemas.status === "initial") {
    return <Loading />;
  }

  if (schemas.status === "error") {
    return <Feednack>{JSON.stringify(schemas.error)}</Feednack>;
  }

  const initialValues = {
    ...currentTransformation.operation.args,
    table2: currentTransformation.operation.args.table2
      ? `RELATIONSHIP--NODE-${currentTransformation.operation.args.table2}`
      : null,
  };

  const renderRelationshipOption = (
    r: IDatasetRelationship,
    nodeId?: string,
    operationLabel?: string
  ) => {
    const hasSeveralRelationshipsToSameDataset =
      schemas.data.relationships.filter((sr) => r.right.id === sr.right.id)
        .length > 1;
    let label = <Text>{r.right.name}</Text>;
    if (hasSeveralRelationshipsToSameDataset) {
      label = (
        <Text>
          {r.right.name}
          <Text type="secondary">
            {" "}
            (where {r.to} = {r.from})
          </Text>
        </Text>
      );
    }

    const isInError = schemas.data.schema[r.id] === undefined ? true : false;

    const renderValue = nodeId
      ? `RELATIONSHIP-${r.id}-NODE-${nodeId}`
      : `RELATIONSHIP-${r.id}`;

    return (
      <Option
        key={renderValue}
        value={renderValue}
        disabled={isInError}
        label={r.right.name}
      >
        <div className="demo-option-label-item">
          <span role="img" style={{ marginRight: 5 }}>
            <SourceImageRenderer
              alt={"sourceName"}
              className="source-table-selection-logo"
              img={
                !r.right.source
                  ? undefined
                  : r.right.source.sourceMeta.publicInfo.logo
              }
              size={16}
              isModel={!r.right.source}
            />
          </span>
          {label}
          {operationLabel ? (
            <Text type="secondary"> ({operationLabel})</Text>
          ) : (
            ""
          )}
          <div
            style={{
              display: isInError ? "inline-block" : "none",
              marginLeft: 6,
            }}
          >
            <Popover
              title={<b>Dataset in error</b>}
              content={
                <div>
                  This dataset in currently in error and must be fixed before
                  you can use it in relationships.
                </div>
              }
            >
              <span>
                <SourceImageRenderer
                  alt={"sourceName"}
                  className="source-table-selection-logo"
                  img={":warning:"}
                  size={16}
                />
              </span>
            </Popover>
          </div>
        </div>
      </Option>
    );
  };

  const schemaFromRelationshipSelection = selectedRelationship
    ? schemas.data.schema[selectedRelationship.id]
    : undefined;

  return (
    <Form
      initialValues={initialValues}
      onFieldsChange={(f) => {
        isStale && isStale(true);
      }}
      onValuesChange={(c) => {
        if (c.table2 || (c.aggregationType && c.aggregationType === "COUNT")) {
          // when changing table2 we should reset the key2 column
          // when changing aggregation type to count we remove the column
          form.setFieldsValue({
            aggregatedColumn: "",
          });
        }
      }}
      className="form-dropdown-form"
      form={form}
      onFinish={async (v) => {
        try {
          setSubmitting(true);

          if (selectedRelationship) {
            if (selectedRelationship.type === "RELATIONSHIP") {
              // we are in quick insert mode so we need to add a node
              const relationship = schemas.data.relationships.find(
                (r) => r.id === selectedRelationship.id
              );

              if (relationship) {
                const datasetToInsert = datasets.find(
                  (d) => d.id === relationship.right.id
                );
                if (datasetToInsert) {
                  const id = generateUniqueId();
                  const newTransformation: Transformation = {
                    ...currentTransformation,
                    operation: {
                      ...currentTransformation.operation,
                      args: {
                        ...currentTransformation.operation.args,
                        ...v,
                        aggregationType: isRollup
                          ? v.aggregationType
                          : "FIRST_VALUE",
                        key1: relationship.from,
                        key2: relationship.to,
                        table2: id,
                      },
                    },
                  };

                  const prevStep: Transformation = datasetToInsert.isModel
                    ? {
                        var: id,
                        domain: "datasetResolver",
                        operation: {
                          type: "Table.FromWhalyDataset",
                          args: {
                            datasetId: datasetToInsert.id,
                          },
                        },
                      }
                    : {
                        var: id,
                        domain: "datasetResolver",
                        operation: {
                          type: "Table.FromWarehouseTable",
                          args: {
                            databaseName: datasetToInsert.warehouseDatabaseId,
                            schemaName: datasetToInsert.warehouseSchemaId,
                            tableName: datasetToInsert.warehouseTableId,
                          },
                        },
                      };

                  await onSave?.([
                    {
                      type: "CREATE",
                      parentKey: currentTransformation.var,
                      transformation: prevStep,
                    },
                    {
                      type: "UPDATE",
                      transformation: newTransformation,
                    },
                  ]);
                }
              }
            } else if (selectedRelationship.type === "NODE") {
              // we are in linking mode so no need to add a new node
              const relationship = schemas.data.relationships.find(
                (r) =>
                  r.id ===
                  (selectedRelationship as NodeRelationshipSelection)
                    .relationshipId
              );

              const newTransformation: Transformation = {
                ...currentTransformation,
                operation: {
                  ...currentTransformation.operation,
                  args: {
                    ...currentTransformation.operation.args,
                    ...v,
                    aggregationType: isRollup
                      ? v.aggregationType
                      : "FIRST_VALUE",
                    key1: relationship ? relationship.from : v.key1,
                    key2: relationship ? relationship.to : v.key2,
                    table2: selectedRelationship.id,
                  },
                },
              };

              await onSave?.([
                {
                  type: "UPDATE",
                  transformation: newTransformation,
                },
              ]);
            }
          }

          if (onCancel) {
            onCancel();
          }
          setSubmitting(false);
        } catch (err) {
          console.error(err);
          catchErrors(err, id);
          setSubmitting(false);
        }
      }}
      layout="vertical"
    >
      <div className="form-dropdown-form-header">
        <Form.Item
          name={["newColumnName"]}
          label="Column Name"
          rules={[
            {
              required: true,
            },
            {
              validator: validateColumnName(
                Object.keys(prevStepSchema),
                currentTransformation.operation.args.newColumnName
              ),
            },
          ]}
        >
          <Input disabled={isFormDisabled} />
        </Form.Item>
      </div>
      <div className="form-dropdown-form-content" id={id}>
        <Form.Item
          name={["table2"]}
          label={`Select the dataset your want to ${
            isRollup ? "rollup" : "lookup"
          }`}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select
            disabled={isFormDisabled}
            notFoundContent="Drag and drop a dataset or a model in the canvas"
            onChange={(v) => {
              if (v) {
                const [rel, relId, node, ...rest] = v.toString().split("-");
                if (node) {
                  setSelectedRelationship({
                    type: "NODE",
                    id: rest.join("-"),
                    relationshipId: relId.length ? relId : null,
                  });
                } else {
                  setSelectedRelationship({
                    type: "RELATIONSHIP",
                    id: relId,
                  });
                }
                const foundRelationship = schemas.data.relationships.find(
                  (r) => r.id === relId
                );
                if (foundRelationship) {
                  form.setFieldsValue({
                    key1: foundRelationship.from,
                    key2: foundRelationship.to,
                  });
                } else {
                  form.setFieldsValue({
                    key1: null,
                    key2: null,
                  });
                }
              }
            }}
            showSearch={true}
            optionFilterProp="label"
            popupMatchSelectWidth={false}
          >
            {(schemas.data.availableNodes.length ||
              (isEditing &&
                selectedRelationship &&
                selectedRelationship.type === "NODE")) && (
              <OptGroup label="From canvas">
                {schemas.data.availableNodes
                  .filter((an) => {
                    if (
                      isEditing &&
                      selectedRelationship &&
                      selectedRelationship.type === "NODE"
                    ) {
                      if (
                        !(selectedRelationship as NodeRelationshipSelection)
                          .relationshipId &&
                        schemas.data.availableNodes.find(
                          (an) => an.relationshipId === selectedRelationship.id
                        )
                      ) {
                        return false;
                      }
                    }
                    return true;
                  })
                  .map((can, i) => {
                    const headNode = canvasNodeGenerator.getNodeIndex(
                      can.nodeId
                    );
                    const relationship = schemas.data.relationships.find(
                      (r) => r.id === can.relationshipId
                    );

                    if (!relationship) {
                      return (
                        <Option
                          key={i}
                          value={`RELATIONSHIP--NODE-${headNode.step.var}`}
                        >
                          <div
                            onMouseEnter={() =>
                              onNodeHighlight?.(headNode.step.var, true)
                            }
                            onMouseLeave={() =>
                              onNodeHighlight?.(headNode.step.var, false)
                            }
                            className="demo-option-label-item"
                          >
                            {getDisplayName(headNode.step.operation.type)}
                          </div>
                        </Option>
                      );
                    }

                    return renderRelationshipOption(
                      relationship,
                      can.nodeId,
                      getDisplayName(headNode.step.operation.type)
                    );
                  })}
              </OptGroup>
            )}
            {schemas.data.relationships.length && (
              <OptGroup label="Quick add to canvas using existing relationship">
                {schemas.data.relationships.map((r) => {
                  return renderRelationshipOption(r);
                })}
              </OptGroup>
            )}
          </Select>
        </Form.Item>

        <Form.Item
          label={`Select the column you want to ${
            isRollup ? "aggregate" : "view"
          }`}
        >
          <Input.Group compact>
            {isRollup ? (
              <Form.Item
                name={["aggregationType"]}
                noStyle
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select
                  onChange={(v) => v && setSelectedAggregation(v.toString())}
                  style={{ width: "30%" }}
                  disabled={isFormDisabled}
                >
                  <Select.Option value={"COUNT"}>Count</Select.Option>
                  <Select.Option value={"SUM"}>Sum</Select.Option>
                  <Select.Option value={"MAX"}>Max</Select.Option>
                  <Select.Option value={"MIN"}>Min</Select.Option>
                  <Select.Option value={"AVG"}>Average</Select.Option>
                  <Select.Option value={"FIRST_VALUE"}>
                    First value
                  </Select.Option>
                  <Select.Option value={"FIRST_VALUE_IGNORE_NULLS"}>
                    First non null value
                  </Select.Option>
                </Select>
              </Form.Item>
            ) : null}
            <Form.Item noStyle name={["aggregatedColumn"]}>
              <Select
                disabled={
                  isFormDisabled ||
                  !selectedRelationship ||
                  !selectedAggregation ||
                  selectedAggregation === "COUNT"
                }
                showSearch={true}
                optionFilterProp="label"
                popupMatchSelectWidth={false}
                style={
                  isRollup
                    ? {
                        width: "70%",
                      }
                    : {
                        width: "100%",
                      }
                }
              >
                {selectedRelationship &&
                  schemaFromRelationshipSelection &&
                  Object.keys(schemaFromRelationshipSelection).map((r) => {
                    return (
                      <Option
                        key={r}
                        value={r}
                        label={schemaFromRelationshipSelection[r].label ?? r}
                      >
                        <div className="demo-option-label-item">
                          <span role="img" style={{ marginRight: 5 }}>
                            <TypeRenderer
                              domain={schemaFromRelationshipSelection[r].domain}
                              formula={
                                schemaFromRelationshipSelection[r].operation ===
                                "Table.AddColumn"
                              }
                            />
                          </span>
                          {schemaFromRelationshipSelection[r].label ?? r}
                        </div>
                      </Option>
                    );
                  })}
              </Select>
            </Form.Item>
          </Input.Group>
        </Form.Item>
        <Form.Item noStyle>
          <Input.Group>
            <Form.Item name={["key1"]} label={"Between key..."}>
              <Select
                disabled={
                  isFormDisabled ||
                  !!(
                    selectedRelationship &&
                    selectedRelationship.type === "RELATIONSHIP"
                  ) ||
                  !selectedAggregation
                }
                showSearch={true}
                optionFilterProp="label"
                popupMatchSelectWidth={false}
              >
                {Object.keys(prevStepSchema).map((k) => {
                  return (
                    <Select.Option
                      key={k}
                      value={k}
                      label={prevStepSchema[k].label ?? k}
                    >
                      <div className="demo-option-label-item">
                        <span role="img" style={{ marginRight: 5 }}>
                          <TypeRenderer
                            domain={prevStepSchema[k].domain}
                            formula={
                              prevStepSchema[k].operation === "Table.AddColumn"
                            }
                          />
                        </span>
                        {prevStepSchema[k].label ?? k}
                      </div>
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
            <Form.Item name={["key2"]} label={"And key..."}>
              <Select
                disabled={
                  isFormDisabled ||
                  !!(
                    selectedRelationship &&
                    selectedRelationship.type === "RELATIONSHIP"
                  ) ||
                  !selectedAggregation
                }
                showSearch={true}
                optionFilterProp="label"
                popupMatchSelectWidth={false}
              >
                {selectedRelationship &&
                  schemaFromRelationshipSelection &&
                  Object.keys(schemaFromRelationshipSelection).map((k) => {
                    return (
                      <Select.Option
                        key={k}
                        value={k}
                        label={schemaFromRelationshipSelection[k].label ?? k}
                      >
                        <div className="demo-option-label-item">
                          <span role="img" style={{ marginRight: 5 }}>
                            <TypeRenderer
                              domain={schemaFromRelationshipSelection[k].domain}
                              formula={
                                schemaFromRelationshipSelection[k].operation ===
                                "Table.AddColumn"
                              }
                            />
                          </span>
                          {schemaFromRelationshipSelection[k].label ?? k}
                        </div>
                      </Select.Option>
                    );
                  })}
              </Select>
            </Form.Item>
          </Input.Group>
        </Form.Item>
        {isRollup ? (
          <Form.Item label={`Order by`}>
            <Input.Group compact>
              <Form.Item name={["orderedBy"]} noStyle>
                <Select
                  showSearch={true}
                  optionFilterProp="label"
                  disabled={isFormDisabled}
                  popupMatchSelectWidth={false}
                  allowClear
                  style={{
                    width: "65%",
                  }}
                >
                  {selectedRelationship &&
                    schemaFromRelationshipSelection &&
                    Object.keys(schemaFromRelationshipSelection).map((r) => {
                      return (
                        <Option
                          key={r}
                          value={r}
                          label={schemaFromRelationshipSelection[r].label ?? r}
                        >
                          <div className="demo-option-label-item">
                            <span role="img" style={{ marginRight: 5 }}>
                              <TypeRenderer
                                domain={
                                  schemaFromRelationshipSelection[r].domain
                                }
                                formula={
                                  schemaFromRelationshipSelection[r]
                                    .operation === "Table.AddColumn"
                                }
                              />
                            </span>
                            {schemaFromRelationshipSelection[r].label ?? r}
                          </div>
                        </Option>
                      );
                    })}
                </Select>
              </Form.Item>
              <Form.Item name={["order"]} noStyle>
                <Select
                  showSearch={true}
                  optionFilterProp="children"
                  disabled={isFormDisabled}
                  popupMatchSelectWidth={false}
                  allowClear
                  style={{ width: "35%" }}
                >
                  <Select.Option value={"ASC"}>Ascendant</Select.Option>
                  <Select.Option value={"DESC"}>Descendant</Select.Option>
                </Select>
              </Form.Item>
            </Input.Group>
          </Form.Item>
        ) : null}
      </div>
    </Form>
  );
};

export default compose<Props, LookupColumnFormProps>(WithOrg)(LookupColumnForm);
