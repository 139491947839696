export const defaultColor = "#E5E9F0";

export const normalizeColorPickerValue = (value: any) => {
  if (typeof value?.toHexString === "function") {
    return value.toHexString();
  }
  return value;
};

export const colorPresets = [
  {
    label: "wlyColors",
    colors: [
      "#D1E2FF",
      "#C4ECFF",
      "#C2F4F0",
      "#CEF5D1",
      "#FEEAB6",
      "#FFE0CD",
      "#FFD4E0",
      "#FBD1FC",
      "#E0DAFD",
      "#E5E9F0",
    ],
  },
];

export const getColorAtIndex = (i: number) => {
  const wlyColors = colorPresets.find((e) => e.label === "wlyColors")!.colors;
  return wlyColors[i % wlyColors.length];
};

export const renderColorPanel = (_, { components: { Picker, Presets } }) => {
  return (
    <div
      className="wly-pills-colorpicker-popover"
      style={{
        display: "flex",
        width: 164,
      }}
    >
      <div style={{ flex: 1 }}>
        <Presets />
      </div>
    </div>
  );
};
