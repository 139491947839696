import ApiService from "./ApiService";

export const getImageUrl = (fileUri: string): string => {
  if (fileUri.startsWith("http")) {
    return fileUri;
  }
  const assetServiceUrl = window.WHALY_CONSTANTS.ASSETS_URL;
  if (fileUri.startsWith("/")) {
    return `${assetServiceUrl}${fileUri}`;
  }
  return `${assetServiceUrl}/${fileUri}`;
};

export const createUserAsset = (
  userId: string,
  fileName: string,
  body: FormData
): Promise<{
  status: "ok";
  data: {
    filePath: string;
  };
}> => {
  return ApiService.postRequest(
    `/user/${userId}/file/${fileName}`,
    body,
    {},
    {},
    { authenticated: true, assetsUrl: true }
  );
};

export const createOrgAsset = (
  orgId: string,
  fileName: string,
  body: FormData
): Promise<{
  status: "ok";
  data: {
    filePath: string;
  };
}> => {
  return ApiService.postRequest(
    `/org/${orgId}/file/${fileName}`,
    body,
    {},
    {},
    { authenticated: true, assetsUrl: true }
  );
};
