import { CaretRightOutlined } from "@ant-design/icons";
import * as React from "react";
import type { RouteComponentProps } from "react-router";
import { withRouter } from "react-router";
import type { ICatalogItem } from "../../../interfaces/catalog";
import type {
  IDestination,
  IDestinationOption,
  IDestinationValue,
} from "../../../interfaces/destinations";
import type {
  ISource,
  ISourceOption,
  ISourceValue,
} from "../../../interfaces/sources";
import { compose } from "../../compose/WlyCompose";

import { Empty } from "antd";
import "./CatalogItemInfo.scss";

interface ICatalogItemInfoProps {
  item: ICatalogItem;
  onValueSelection: (value: string) => void;
}

interface IEnhancedSourceOption extends ISourceOption {
  value: string;
  value_id?: string;
}

interface IEnhancedDestinationOption extends IDestinationOption {
  value: string;
  value_id?: string;
}

type EnhancedOption = IEnhancedSourceOption | IEnhancedDestinationOption;

interface IState {
  deleting: boolean;
  deleteModalOpen: boolean;
}

type Props = ICatalogItemInfoProps &
  RouteComponentProps<{ organizationSlug: string }>;

class CatalogItemInfo extends React.Component<Props, IState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      deleting: false,
      deleteModalOpen: false,
    };
  }

  public showDeleteModal = () => {
    this.setState({ deleteModalOpen: true });
  };

  public hideDeleteModal = () => {
    this.setState({ deleteModalOpen: false });
  };

  public renderValue = (opt: IEnhancedSourceOption) => {
    switch (opt.type) {
      case "boolean":
        return (
          <div>
            <div className={opt.value === "true" ? "ON" : "OFF"} />{" "}
            {opt.value === "true" ? "ON" : "OFF"}
          </div>
        );
      case "select":
      case "string":
        const slicedStringValue = opt.value?.slice(0, 27);
        return (
          <span>
            {slicedStringValue === opt.value
              ? slicedStringValue
              : `${slicedStringValue}...`}
          </span>
        );
      case "password":
        const slicedPasswordValue = opt.value.slice(0, 27);
        const length = slicedPasswordValue.length;
        let passworValue = "";
        for (let i = 0; i < length; i++) {
          passworValue = passworValue + "•";
        }
        return (
          <span>
            {slicedPasswordValue === opt.value
              ? passworValue
              : `${passworValue}...`}
          </span>
        );
      case "date":
      case "int":
        return <span>{opt.value}</span>;
    }
  };

  public render() {
    const { item, onValueSelection } = this.props;
    const values = item.values;
    const enhancedSourceOption: EnhancedOption[] = (item as ISource).sourceMeta
      ? (item as ISource).sourceMeta.options.map((so) => {
          const foundValue = (values as ISourceValue[]).find(
            (v) => v.option.id === so.id
          );
          return {
            ...so,
            value: foundValue ? foundValue.value : "",
            value_id: foundValue ? foundValue.id : undefined,
          };
        })
      : (item as IDestination).destinationMeta.options.map((so) => {
          const foundValue = (values as IDestinationValue[]).find(
            (v) => v.option.id === so.id
          );
          return {
            ...so,
            value: foundValue ? foundValue.value : "",
            value_id: foundValue ? foundValue.id : undefined,
          };
        });
    return (
      <div className="calatlog-item-info">
        <div className="calatlog-item-info-values">
          <div className="calatlog-item-info-title">Connection Settings</div>
          <div className="calatlog-item-info-option-list">
            {!enhancedSourceOption.length ? (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={<span>This source has no settings</span>}
              />
            ) : undefined}
            {enhancedSourceOption.map((opt) => {
              return (
                <div
                  key={opt.key}
                  className="calatlog-item-info-option"
                  onClick={() => onValueSelection(opt.id)}
                >
                  <div className="calatlog-item-info-title">{opt.label}</div>
                  <div className="calatlog-item-info-value">
                    {this.renderValue(opt as any)}
                  </div>
                  <div className="calatlog-item-info-caret">
                    <CaretRightOutlined />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
}

export default compose<Props, ICatalogItemInfoProps>(withRouter)(
  CatalogItemInfo
);
