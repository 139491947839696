import { Promise as BPromise } from "bluebird";
import { compose } from "../../../../../../components/compose/WlyCompose";
import ShareForm from "../../../../../../components/object-sharing/ShareForm";
import type {
  ISearchableItem,
  ISharing,
  ISharingInitialData,
} from "../../../../../../components/object-sharing/domain";
import type { IGroup } from "../../../../../../interfaces/group";
import type {
  IObject,
  IObjectSharing,
} from "../../../../../../interfaces/object";
import { IOrgFeatureType } from "../../../../../../interfaces/org";
import type { IAccessType } from "../../../../../../interfaces/reportSharing";
import type { IUserGravatarInfo } from "../../../../../../interfaces/user";
import { IUserRoleType } from "../../../../../../interfaces/user";
import GraphQLService from "../../../../../../services/graphql/GraphQLService";
import { assignUserRole } from "../../../../../../services/userService";
import type { InjectedOrgProps } from "../../../../../orgs/WithOrg";
import WithOrg from "../../../../../orgs/WithOrg";

interface IObjectShareProps {
  visible: boolean;
  canBeManagedByCurrentUser: boolean;
  onClose: () => any;
  object: IObject;
}

type Props = IObjectShareProps & InjectedOrgProps;

const GROUP_INFO_FRAGMENT = `
fragment GroupInfo on Group {
  id
  name
  isAdminGroup
  isBuilderGroup
  isSystemGroup
  members: _usersMeta(where: { isDeleted: false, user: { isDeleted: false, isPublicGuestUser: false, isAdmin: false }}) {
    count
  }
}
`;

const USER_INFO_FRAGMENT = `
fragment UserInfo on User {
  id
  firstName
  lastName
  email
  gravatar
  avatarColor
}
`;

const OBJECT_SHARING_FRAGMENT = `
fragment ObjectSharingInfo on ObjectSharing {
  id
  user {
    ...UserInfo
    isPublicGuestUser
  }
  group {
    ...GroupInfo
  }
  accessType
}
`;

const GET_SHARING_FORM_DATA = `

${USER_INFO_FRAGMENT}
${GROUP_INFO_FRAGMENT}
${OBJECT_SHARING_FRAGMENT}

query GetSharingFromData($orgId: ID!, $realmId: ID!, $objectId: ID!) {
  orgUsers: allUsers(
    where: {
      isPublicGuestUser: false
      isDeleted: false
      isAdmin: false
      roles_some: { isDeleted: false, org: { id: $orgId } }
    }
  ) {
    ...UserInfo
  }
  realmUsers: allUsers(
    where: {
      isPublicGuestUser: false
      isDeleted: false
      isAdmin: false
      realm: { realm: { id: $realmId }}
    }
  ) {
    ...UserInfo
  }
  groups: allGroups(where: { isDeleted: false, org: { id: $orgId } }) {
    ...GroupInfo
  }
  sharings: allObjectSharings(
    where: { isDeleted: false, object: { id: $objectId } }
  ) {
    ...ObjectSharingInfo
  }
}
`;

interface ShareFormDataResult {
  orgUsers: IUserGravatarInfo[];
  realmUsers: IUserGravatarInfo[];
  groups: IGroup[];
  sharings: IObjectSharing[];
}

function ObjectShareForm(props: Props) {
  const {
    visible,
    canBeManagedByCurrentUser,
    onClose,
    orgFeatures,
    org,
    object,
  } = props;

  const hasShareFeatures = !!orgFeatures.find(
    (o) => IOrgFeatureType.GRANULAR_REPORT_FOLDER_SHARING === o
  );

  const fetchInitialData = async (): Promise<ISharingInitialData> => {
    const sharingFormData = await GraphQLService<ShareFormDataResult>(
      GET_SHARING_FORM_DATA,
      {
        orgId: org.id,
        realmId: org.realm?.id,
        objectId: object.id,
      }
    );

    // Compute the existings Sharings
    const directSharings: ISharing[] = sharingFormData.sharings.filter(
      (sharing) => {
        if (sharing.user?.isPublicGuestUser === true) {
          return false;
        }
        return true;
      }
    );

    // Compute the users / groups that could be still be shared with
    const allSharings: ISharing[] = directSharings;

    const allUsersIdsWithSharings = allSharings
      .filter((sharing) => sharing.user)
      .map((sharing) => sharing.user!.id);

    const allGroupsIdsWithSharings = allSharings
      .filter((sharing) => sharing.group)
      .map((sharing) => sharing.group!.id);

    const orgUsers = sharingFormData.orgUsers.filter(
      (user) => !allUsersIdsWithSharings.includes(user.id)
    );

    const realmUsers = sharingFormData.realmUsers
      .filter((userRes) => {
        const orgUserIds = orgUsers.map((userRes) => userRes.id);
        return !orgUserIds.includes(userRes.id);
      })
      .filter((user) => !allUsersIdsWithSharings.includes(user.id));

    const groups = sharingFormData.groups.filter(
      (group) => !allGroupsIdsWithSharings.includes(group.id)
    );

    return {
      availableUsersAndGroup: {
        orgUsers,
        realmUsers,
        groups,
      },
      directSharings,
    };
  };

  const onAccessTypeChange = async (
    sharingId: string,
    newType: IAccessType
  ) => {
    const queryText = `
    mutation UpdateObjectSharing($sharingId:ID!, $newType: ReportFolderSharingAccessTypeType!) {
      updateObjectSharing(id: $sharingId, data: {
        accessType: $newType
      }) {
        id
      }
    }`;
    await GraphQLService(queryText, { sharingId, newType });
  };

  const onRemoveAccess = async (sharingId: string) => {
    const queryText = `
    mutation DeleteObjectSharing($sharingId:ID!) {
      updateObjectSharing(id: $sharingId, data: {
        isDeleted: true
      }) {
        id
      }
    }`;
    await GraphQLService(queryText, { sharingId });
  };

  const createNewSharings = async (
    selectedItems: ISearchableItem[],
    accessType: IAccessType
  ) => {
    const queryText = `
    mutation CreateObjectSharings($data: [ObjectSharingsCreateInput]) {
      createObjectSharingsWithACL(data: $data)
    }`;

    interface ObjectSharingsCreateInput {
      data: ObjectSharingCreateInput;
    }
    interface ObjectSharingCreateInput {
      object: {
        connect: {
          id: string;
        };
      };
      accessType: IAccessType;
      user?: { connect: { id: string } };
      group?: { connect: { id: string } };
      isDeleted: boolean;
      org: { connect: { id: string } };
    }

    const selectedRealmUserIds = selectedItems
      .filter((item) => {
        return item.type === "user-realm";
      })
      .map((item) => item.id);

    const selectedOrgUserIds = selectedItems
      .filter((item) => {
        return item.type === "user-org";
      })
      .map((item) => item.id);

    // Create UserRole for those users before creating a sharing
    if (selectedRealmUserIds.length > 0) {
      await BPromise.map(
        selectedRealmUserIds,
        async (userId) => {
          await assignUserRole(userId, org.id, IUserRoleType.VIEWER);
        },
        { concurrency: 3 }
      );
    }

    const userSharingData: ObjectSharingsCreateInput[] = selectedOrgUserIds
      .concat(selectedRealmUserIds)
      .map((userId) => {
        return {
          data: {
            object: {
              connect: {
                id: object.id,
              },
            },
            org: {
              connect: { id: org.id },
            },
            isDeleted: false,
            accessType,
            user: {
              connect: {
                id: userId,
              },
            },
          },
        };
      });

    const selectedGroupIds = selectedItems
      .filter((item) => {
        return item.type === "group";
      })
      .map((item) => item.id);

    const groupSharingData: ObjectSharingsCreateInput[] = selectedGroupIds.map(
      (groupId) => {
        return {
          data: {
            object: {
              connect: {
                id: object.id,
              },
            },
            org: {
              connect: { id: org.id },
            },
            isDeleted: false,
            accessType,
            group: {
              connect: {
                id: groupId,
              },
            },
          },
        };
      }
    );

    const data = userSharingData.concat(groupSharingData);
    if (data.length > 0) {
      await GraphQLService(queryText, { data });
    }
  };

  const disableEdit = (group?: IGroup) => {
    if (!canBeManagedByCurrentUser) {
      return true;
    }
    if (!hasShareFeatures) {
      return true;
    }
    if (group?.isAdminGroup) {
      return true;
    }
    if (group?.isBuilderGroup) {
      return true;
    }
    return false;
  };

  return (
    <ShareForm
      visible={visible}
      canBeManagedByCurrentUser={canBeManagedByCurrentUser}
      orgHasFeature={hasShareFeatures}
      onClose={onClose}
      modalTitle={`Share '${object?.name}'`}
      fetchSharings={fetchInitialData}
      updateSharing={onAccessTypeChange}
      removeSharing={onRemoveAccess}
      createSharings={createNewSharings}
      availableAccessTypes={["view"]}
      disableEdit={disableEdit}
    />
  );
}

export default compose<Props, IObjectShareProps>(WithOrg)(ObjectShareForm);
