import styled from "@emotion/styled";
import type { DiagramEngine, LabelModel } from "@projectstorm/react-diagrams";
import * as React from "react";
import type { FlowLinkModel } from "./FlowLinkModel";

namespace S {
  export const Path = styled.path<{ selected: boolean }>`
    fill: none;
    pointer-events: auto;
  `;
}

interface IFlowLinkWidgetProps {
  model: FlowLinkModel;
  path: string;
  engine: DiagramEngine;
}

interface IState {
  selected: boolean;
}

export class FlowLinkWidget extends React.Component<
  IFlowLinkWidgetProps,
  IState
> {
  label?: LabelModel;
  refPaths: React.RefObject<SVGPathElement>[];

  constructor(props) {
    super(props);
    this.state = {
      selected: false,
    };
  }

  componentDidUpdate(): void {
    this.props.model.setRenderedPaths(
      this.refPaths.map((ref) => {
        return ref.current;
      })
    );
  }

  componentWillUnmount(): void {
    this.props.model.setRenderedPaths([]);
  }

  getRenderedPath = () => {
    return this.refPaths.map((ref) => {
      return ref.current;
    });
  };

  render() {
    this.refPaths = [];
    const ref = React.createRef<SVGPathElement>();
    this.refPaths.push(ref);

    const selected = false; // this.props.model.selected();

    const bottom = React.cloneElement(
      <S.Path
        selected={selected}
        stroke={
          selected
            ? this.props.model.getOptions().selectedColor
            : this.props.model.getOptions().color
        }
        strokeWidth={this.props.model.getOptions().width}
        d={this.props.path}
        ref={ref}
      />
    );

    const top = React.cloneElement(bottom, {
      strokeLinecap: "round",
      onMouseLeave: () => {
        this.props.model.hover(false);
      },
      onMouseEnter: () => {
        this.props.model.hover(true);
      },
      ref: null,
      "data-linkid": this.props.model.getID(),
      strokeOpacity: selected ? 0.1 : 0,
      strokeWidth: 20,
      fill: "none",
    });

    return (
      <g data-linkid={this.props.model.getID()}>
        {bottom}
        {top}
      </g>
    );
  }
}
