import { gql } from "@apollo/client";
import { OBJECT_FRAGMENT } from "../../../../../fragments/object";
import { USER_FRAGMENT } from "../../../../../fragments/user";
import { EXTRACT_SCHEDULE_FRAGMENT } from "../../../../../store/dataStores/workspace/WorkspaceDatastoreDomain";
import { TABLE_FRAGMENT } from "../../../../exploration/single/domain";
import { OBJECT_REGISTERED_ACTION_FRAGMENT } from "./tabs/actions/domain";

export const OBJECT_COMPLETE_FRAGMENT = gql`
  ${USER_FRAGMENT}

  ${EXTRACT_SCHEDULE_FRAGMENT}

  ${TABLE_FRAGMENT}

  ${OBJECT_FRAGMENT}

  ${OBJECT_REGISTERED_ACTION_FRAGMENT}

  fragment ObjectQuery on Object {
    id
    name
    slug
    primaryLabel
    primaryImage
    rowLevelFilters
    foreignKeys(
      where: { isDeleted_not: true, object: { isDeleted_not: true } }
    ) {
      id
      label
      object {
        ...ObjectFragment
        layouts(where: { isDeleted_not: true }) {
          id
          name
          type
        }
      }
    }
    properties(where: { isDeleted_not: true }) {
      id
      label
      cubeName
      description
      columnName
      columnDomain
      formatter
      formatterConfig
      userPropertyMapping
      sortingAndFilteringColumn
      hierarchyPath
      foreignKey {
        id
        cubeName
        table {
          cubeName
          object {
            id
            slug
          }
        }
      }
    }
    isExtractEnabled
    extractSchedule {
      ...ExtractSchedule
    }
    model {
      id
    }
    canBeListed
    table {
      ...TableQuery
    }
    registeredActions(where: { isDeleted_not: true }) {
      ...ObjectRegisteredActionFragment
    }
    isDeleted
    createdAt
    createdBy {
      ...UpdatedBy
    }
    org {
      emailTemplateMjml
    }
  }
`;
