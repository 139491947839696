import { EditFilled, MoreOutlined } from "@ant-design/icons";
import type { MenuProps } from "antd";
import { Button, Dropdown, Space, Typography } from "antd";
import * as React from "react";
import type { InjectedAntUtilsProps } from "../../../../../components/ant-utils/withAntUtils";
import { withAntUtils } from "../../../../../components/ant-utils/withAntUtils";
import { compose } from "../../../../../components/compose/WlyCompose";
import type { IReport } from "../../../../../interfaces/reports";
import { IUserRoleType } from "../../../../../interfaces/user";
import HasRoleAccess from "../../../../user-settings/HasRoleAccess";
import { BORDER_COLOR } from "../../../../v2-demo/container/layout/domain";
import ScheduleListDrawer from "../../schedule/ScheduleListDrawer";
import type { ScheduleOuterType } from "../../schedule/domain";
import ShareDrawer from "../../share/ShareDrawer";
import { renderEmbedModal } from "../dashboard/domain";

interface IQuestionHeaderProps {
  hideLayout?: boolean;
  editing?: boolean;
  report: IReport;
  onEditClick: (explorationId?: string) => void;
  forceCacheRefresh: (force: boolean) => Promise<any>;
  onOpenConsole?: (c?: string) => void;
  isDisplayedInWorkspace?: boolean;
}

type Props = IQuestionHeaderProps & InjectedAntUtilsProps;

function QuestionHeader(props: Props) {
  const {
    hideLayout,
    editing,
    report,
    onEditClick,
    forceCacheRefresh,
    onOpenConsole,
    isDisplayedInWorkspace,
    antUtils,
  } = props;

  const [scheduleListDrawerOpened, setScheduleListDrawerOpened] =
    React.useState<{ opened: boolean; type: ScheduleOuterType }>({
      opened: false,
      type: "email",
    });
  const [shareDrawerOpened, setShareDrawerOpened] = React.useState(false);

  const menu: MenuProps = {
    style: { maxHeight: 300, overflow: "auto" },
    items: [
      {
        type: "group",
        label: "Explorations",
        children: [].map((e, i) => {
          return {
            key: i,
            onClick: () => onEditClick(e.id),
            label: e.name,
          };
        }),
      },
    ],
  };

  if (hideLayout) {
    return (
      <div style={{ flex: 0 }}>
        <div id="help-button" />
      </div>
    );
  }

  if (!isDisplayedInWorkspace) {
    return null;
  }

  const showEmbedModal = () => antUtils.modal.info(renderEmbedModal(report));

  return (
    <div
      style={{
        flex: 0,
        borderBottom: `1px solid ${BORDER_COLOR}`,
        padding: 24,
        display: "flex",
      }}
    >
      <div style={{ flex: 1 }}>
        <Typography.Title style={{ marginBottom: 0 }} level={4}>
          {report.name}
        </Typography.Title>
      </div>
      <div style={{ flex: 0 }}>
        <Space>
          <HasRoleAccess accessLevel={IUserRoleType.EDITOR}>
            {report.tiles.length ? (
              <Button
                className="display-none display-inline-lg"
                onClick={() => onEditClick()}
              >
                <EditFilled /> Edit
              </Button>
            ) : (
              <Dropdown
                className="display-none display-inline-lg"
                trigger={["click"]}
                menu={menu}
              >
                <Button type="primary" size={"small"}>
                  <EditFilled /> Ask your question
                </Button>
              </Dropdown>
            )}
          </HasRoleAccess>
          {onOpenConsole ? (
            <HasRoleAccess accessLevel={IUserRoleType.EDITOR}>
              <Dropdown
                className="display-none display-inline-lg"
                trigger={["click"]}
                arrow
                menu={{
                  items: [
                    {
                      key: "1",
                      type: "group",
                      label: "Push",
                      children: [
                        {
                          key: 1,
                          onClick: () =>
                            setScheduleListDrawerOpened({
                              opened: true,
                              type: "email",
                            }),
                          label: "Schedule delivery",
                        },
                      ],
                    },
                    {
                      key: "2",
                      type: "group",
                      label: "Sharing",
                      children: [
                        {
                          key: "sharing",
                          onClick: () => setShareDrawerOpened(true),
                          label: "Sharing Links",
                        },
                        {
                          key: "embeds",
                          onClick: () => showEmbedModal(),
                          label: "Embed code",
                        },
                      ],
                    },
                    {
                      type: "divider",
                    },
                    {
                      key: 3,
                      onClick: () => onOpenConsole(),
                      label: "Open console",
                    },
                  ],
                }}
              >
                <Button shape="circle" icon={<MoreOutlined />} />
              </Dropdown>
            </HasRoleAccess>
          ) : undefined}
        </Space>
      </div>
      <ShareDrawer
        reportId={props.report.id}
        visible={shareDrawerOpened}
        setVisible={setShareDrawerOpened}
      />
      <ScheduleListDrawer
        report={props.report}
        type={scheduleListDrawerOpened.type}
        visible={scheduleListDrawerOpened.opened}
        onClose={() =>
          setScheduleListDrawerOpened({
            opened: false,
            type: scheduleListDrawerOpened.type,
          })
        }
      />
    </div>
  );
}

export default compose<Props, IQuestionHeaderProps>(withAntUtils)(
  QuestionHeader
);
