import { Select, Typography } from "antd";
import type { SelectProps } from "antd/lib/select";

interface ITimeAggregateProps extends SelectProps {}

export function TimeAggregate(props: ITimeAggregateProps) {
  return (
    <Select
      {...props}
      popupMatchSelectWidth={false}
      bordered={false}
      style={{ width: "100%" }}
      optionLabelProp="label"
    >
      <Select.Option value={null} label={"None"}>
        <div style={{ display: "flex" }}>
          <div style={{ flex: 1, marginRight: 4 }}>None</div>
          <div style={{ flex: 0 }}></div>
        </div>
      </Select.Option>
      <Select.Option value={"year"} label={"Year"}>
        <div style={{ display: "flex" }}>
          <div style={{ flex: 1, marginRight: 4 }}>Year</div>
          <div style={{ flex: 0 }}>
            <Typography.Text type="secondary">2023</Typography.Text>
          </div>
        </div>
      </Select.Option>
      <Select.Option value={"quarteryear"} label={"Quarter"}>
        <div style={{ display: "flex" }}>
          <div style={{ flex: 1, marginRight: 4 }}>Quarter</div>
          <div style={{ flex: 0 }}>
            <Typography.Text type="secondary">2023 Q1</Typography.Text>
          </div>
        </div>
      </Select.Option>
      <Select.Option value={"monthyear"} label={"Month"}>
        <div style={{ display: "flex" }}>
          <div style={{ flex: 1, marginRight: 4 }}>Month</div>
          <div style={{ flex: 0 }}>
            <Typography.Text type="secondary">01 2023</Typography.Text>
          </div>
        </div>
      </Select.Option>
      <Select.Option value={"weekyear"} label={"Week"}>
        <div style={{ display: "flex" }}>
          <div style={{ flex: 1, marginRight: 4 }}>Week</div>
          <div style={{ flex: 0 }}>
            <Typography.Text type="secondary">2023 W01</Typography.Text>
          </div>
        </div>
      </Select.Option>
      <Select.Option value={"dayweekyear"} label={"Day"}>
        <div style={{ display: "flex" }}>
          <div style={{ flex: 1, marginRight: 4 }}>Day</div>
          <div style={{ flex: 0 }}>
            <Typography.Text type="secondary">2023 W01, Mon</Typography.Text>
          </div>
        </div>
      </Select.Option>
      <Select.Option value={"utcyearmonthdate"} label={"Date"}>
        <div style={{ display: "flex" }}>
          <div style={{ flex: 1, marginRight: 4 }}>Date</div>
          <div style={{ flex: 0 }}>
            <Typography.Text type="secondary">Jan 01, 2023</Typography.Text>
          </div>
        </div>
      </Select.Option>
      <Select.Option value={"utcyearmonthdatehours"} label={"Hours"}>
        <div style={{ display: "flex" }}>
          <div style={{ flex: 1, marginRight: 4 }}>Hours</div>
          <div style={{ flex: 0 }}>
            <Typography.Text type="secondary">
              Jan 01, 2023 12:00
            </Typography.Text>
          </div>
        </div>
      </Select.Option>
      <Select.Option value={"utcyearmonthdatehoursminutes"} label={"Minutes"}>
        <div style={{ display: "flex" }}>
          <div style={{ flex: 1, marginRight: 4 }}>Minutes</div>
          <div style={{ flex: 0 }}>
            <Typography.Text type="secondary">
              Jan 01, 2023 12:43
            </Typography.Text>
          </div>
        </div>
      </Select.Option>
      <Select.Option
        value={"utcyearmonthdatehoursminutesseconds"}
        label={"Seconds"}
      >
        <div style={{ display: "flex" }}>
          <div style={{ flex: 1, marginRight: 4 }}>Seconds</div>
          <div style={{ flex: 0 }}>
            <Typography.Text type="secondary">
              Jan 01, 2023 12:43:32
            </Typography.Text>
          </div>
        </div>
      </Select.Option>
    </Select>
  );
}
