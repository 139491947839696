import { Form } from "antd";
import _ from "lodash";
import React, { useRef } from "react";
import usePrevious from "../../../../../components/hooks/usePrevious";
import Feednack from "../../../../../components/layout/feedback/feedback";
import type {
  ExtendedMeasureType,
  MeasureType,
} from "../../../../../components/measures/measure-table/MeasureTable";
import type { IDestination } from "../../../../../interfaces/destinations";
import type { IObject } from "../../../../../interfaces/object";
import type {
  IDataset,
  IDatasetRelationship,
} from "../../../../../interfaces/sources";
import type { SchemaResult } from "../../../../../interfaces/transformations";
import type { TabData } from "../../domain";
import type {
  DisabledTableConfigurationCapabilities,
  ExplorationOperation,
  IMeasureTable,
  IObjectConfigurationInfos,
  SelectedItems,
} from "../domain";
import { generateFakeId } from "../domain";
import { MeasureEditorFormWrapper } from "./MeasureEditorFormWrapper";
import DimensionEditor from "./dimension-editor/DimensionEditor";
import CalculatedMetricEditor from "./metric-editor/CalculatedMetricEditor";
import MetricEditor from "./metric-editor/MetricEditor";
import { ObjectEditor } from "./object-editor/ObjectEditor";
import PropertiesEditor from "./properties-editor/PropertiesEditor";
import RelatedData from "./related-data/RelatedData";
import SemanticGroupEditor from "./semantic-group-editor/SemanticGroupEditor";
import TableEditor from "./table-editor/TableEditor";

interface IMeasureEditorProps {
  tempTables: IMeasureTable[];
  selectedItems: SelectedItems;
  dataStore: {
    [tableKey: string]: TabData;
  };
  onRemoveTable: (tableId: string) => void;
  onRemoveGroup: (semanticGroupId: string) => void;
  onRemoveMeasure: (
    type: MeasureType,
    tableId: string,
    measureId: string
  ) => void;
  pushStaleElement: (e: ExplorationOperation | ExplorationOperation[]) => void;
  allIncomingDatasetRelationships: IDatasetRelationship[];
  allDatasets: IDataset[];
  onClick: (selectedItems: SelectedItems) => void;
  fetchSchema: (
    tablesSchemas: Array<{ datasetId: string; viewId: string }>
  ) => Promise<SchemaResult[]>;
  updateStaleElement: (
    type: ExtendedMeasureType,
    objectId: string,
    data: any
  ) => void;
  disabledCapabilities: Array<DisabledTableConfigurationCapabilities>;
  objectInfos?: IObjectConfigurationInfos;
  allObjects?: IObject[];
  currentWarehouse: IDestination;
}

export function MeasureEditor(props: IMeasureEditorProps) {
  const {
    selectedItems,
    dataStore,
    tempTables,
    allIncomingDatasetRelationships,
    allDatasets,
    pushStaleElement,
    onClick,
    updateStaleElement,
    fetchSchema,
    onRemoveTable,
    onRemoveGroup,
    onRemoveMeasure,
    disabledCapabilities,
    objectInfos,
    allObjects,
    currentWarehouse,
  } = props;

  const [form] = Form.useForm();
  const viewRef = useRef<HTMLDivElement>(null);
  const previousSelectedItems = usePrevious(selectedItems);

  React.useEffect(() => {
    if (!_.isEqual(previousSelectedItems, selectedItems)) {
      form.resetFields();
      if (viewRef.current) {
        viewRef.current.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }
    }
  }, [selectedItems]);

  const renderInner = () => {
    const allMetrics = tempTables.flatMap((t) => [
      ...t.metrics.map((m) => ({ ...m, table: t })),
    ]);
    const allDimensions = tempTables.flatMap((t) =>
      [...t.dimensions].map((d) => ({ ...d, table: t }))
    );
    const allGroups = tempTables.flatMap((t) =>
      [...t.semanticGroups].map((g) => ({ ...g, table: t }))
    );
    const allTables = tempTables;

    if (Array.isArray(selectedItems)) {
      // I haven't implemented the array select type
      if (selectedItems.length === 0) {
        return <Feednack>No measure selected</Feednack>;
      }
      if (selectedItems.length > 1) {
        return (
          <MeasureEditorFormWrapper ref={viewRef} name="Not implemented">
            <Feednack>
              Please implement the multiple deletion capability
            </Feednack>
          </MeasureEditorFormWrapper>
        );
      }
      const [selectedItem] = selectedItems;

      if (selectedItem.type === "dimension") {
        const selectedDimension = allDimensions.find(
          (d) => d.id === selectedItem.id
        );

        if (!selectedDimension) {
          return (
            <MeasureEditorFormWrapper ref={viewRef} name="Unknown dimension">
              <Feednack>Couldn't find dimension {selectedItem.id}</Feednack>
            </MeasureEditorFormWrapper>
          );
        }

        const selectedParentTable = tempTables.find(
          (t) => t.id === selectedDimension.table.id
        );
        if (!selectedParentTable) {
          return (
            <MeasureEditorFormWrapper ref={viewRef} name="Unknown dimension">
              <Feednack>
                Couldn't find parent table for dimension {selectedItem.id}
              </Feednack>
            </MeasureEditorFormWrapper>
          );
        }

        return (
          <MeasureEditorFormWrapper ref={viewRef} name={`Updating dimension`}>
            <DimensionEditor
              initialData={selectedDimension.rawData}
              form={form}
              usage={selectedDimension.usage}
              error={
                selectedDimension.hasError ? selectedDimension.hasError : []
              }
              schemaResults={
                dataStore[selectedParentTable.datasetId].data[
                  selectedParentTable.viewId
                ].schema
              }
              currentWarehouse={currentWarehouse}
              datasetId={selectedParentTable.datasetId}
              alreadyUsedDimensions={[]}
              onSave={(d) => {
                let data = d;
                if (Array.isArray(d.customOrderingConfArray)) {
                  data = {
                    ...data,
                    customOrderingConfArray: JSON.stringify(
                      data.customOrderingConfArray
                    ) as any,
                  };
                }
                updateStaleElement("dimension", selectedDimension.id, data);
                return Promise.resolve();
              }}
              onDelete={() => {
                onRemoveMeasure(
                  "dimension",
                  selectedDimension.table.id,
                  selectedDimension.id
                );
                return Promise.resolve();
              }}
            />
          </MeasureEditorFormWrapper>
        );
      }
      if (selectedItem.type === "metric") {
        const selectedMetric = allMetrics.find((d) => d.id === selectedItem.id);
        if (!selectedMetric) {
          return <Feednack>Couldn't find metric {selectedItem.id}</Feednack>;
        }
        const selectedParentTable = tempTables.find(
          (t) => t.id === selectedMetric.table.id
        );
        if (!selectedParentTable) {
          return (
            <MeasureEditorFormWrapper ref={viewRef} name="Unknown parent table">
              <Feednack>
                Couldn't find parent table for metric {selectedItem.id}
              </Feednack>
            </MeasureEditorFormWrapper>
          );
        }

        if (selectedMetric.subType === "COMPUTED") {
          return (
            <MeasureEditorFormWrapper
              ref={viewRef}
              name={`Updating calculated metric`}
            >
              <CalculatedMetricEditor
                currentId={selectedMetric.id}
                initialData={selectedMetric.rawData}
                error={selectedMetric.hasError ? selectedMetric.hasError : []}
                form={form}
                usage={selectedMetric.usage}
                tables={tempTables}
                object={objectInfos}
                onSave={(d) => {
                  updateStaleElement("metric", selectedMetric.id, d);
                  return Promise.resolve();
                }}
                selectedView={
                  dataStore[selectedParentTable.datasetId].data[
                    selectedParentTable.viewId
                  ]
                }
                onDelete={() => {
                  onRemoveMeasure(
                    "metric",
                    selectedMetric.table.id,
                    selectedMetric.id
                  );
                  return Promise.resolve();
                }}
              />
            </MeasureEditorFormWrapper>
          );
        }
        return (
          <MeasureEditorFormWrapper ref={viewRef} name={`Updating metric`}>
            <MetricEditor
              initialData={selectedMetric.rawData}
              error={selectedMetric.hasError ? selectedMetric.hasError : []}
              form={form}
              usage={selectedMetric.usage}
              table={selectedMetric.table}
              object={objectInfos}
              tables={tempTables}
              schemaResults={
                dataStore[selectedParentTable.datasetId].data[
                  selectedParentTable.viewId
                ].schema
              }
              selectedView={
                dataStore[selectedParentTable.datasetId].data[
                  selectedParentTable.viewId
                ]
              }
              onSave={(d) => {
                updateStaleElement("metric", selectedMetric.id, d);
                return Promise.resolve();
              }}
              onDelete={() => {
                onRemoveMeasure(
                  "metric",
                  selectedMetric.table.id,
                  selectedMetric.id
                );
                return Promise.resolve();
              }}
            />
          </MeasureEditorFormWrapper>
        );
      }

      if (selectedItem.type === "table") {
        const selectedTable = allTables.find((t) => t.id === selectedItem.id);
        if (!selectedTable) {
          return (
            <MeasureEditorFormWrapper
              ref={viewRef}
              name={`Unknown table`}
              fullSize={true}
            >
              <Feednack>Couldn't find table {selectedItem.id}</Feednack>
            </MeasureEditorFormWrapper>
          );
        }
        // // we filter on available dimensions
        // if (
        //   selectedTable.drills?.type === "CUSTOM" &&
        //   typeof selectedTable.drills?.values === "object"
        // ) {
        //   selectedTable.drills.values = selectedTable.drills?.values.filter(
        //     (v) => {
        //       if (
        //         selectedTable.dimensions
        //           ?.map((d) => `dim${d.id}`)
        //           .includes(v) ||
        //         selectedTable.metrics?.map((d) => `met${d.id}`).includes(v)
        //       ) {
        //         return true;
        //       } else {
        //         return false;
        //       }
        //     }
        //   );
        // }
        return (
          <MeasureEditorFormWrapper ref={viewRef} name={`Table edition`}>
            <TableEditor
              initialData={selectedTable}
              onSave={(d) => {
                updateStaleElement("table", selectedTable.id, d);
                return Promise.resolve();
              }}
              form={form}
              onDelete={() => {
                onRemoveTable(selectedTable.id);
                return Promise.resolve();
              }}
              dataStore={dataStore}
              tables={tempTables}
              allIncomingDatasetRelationships={allIncomingDatasetRelationships}
              allDatasets={allDatasets}
              disabledCapabilities={disabledCapabilities}
            />
          </MeasureEditorFormWrapper>
        );
      }

      if (selectedItem.type === "semanticGroup") {
        const selectedGroup = allGroups.find(
          (group) => group.id === selectedItem.id
        );
        if (!selectedGroup) {
          return (
            <MeasureEditorFormWrapper
              ref={viewRef}
              name={`Unknown group`}
              fullSize={true}
            >
              <Feednack>Couldn't find group {selectedItem.id}</Feednack>
            </MeasureEditorFormWrapper>
          );
        }
        return (
          <MeasureEditorFormWrapper ref={viewRef} name={`Group edition`}>
            <SemanticGroupEditor
              initialData={selectedGroup}
              onSave={(semanticGroup) => {
                updateStaleElement(
                  "semanticGroup",
                  selectedGroup.id,
                  semanticGroup
                );
                return Promise.resolve();
              }}
              form={form}
              onDelete={() => {
                onRemoveGroup(selectedGroup.id);
                return Promise.resolve();
              }}
              tables={tempTables}
            />
          </MeasureEditorFormWrapper>
        );
      }

      if (selectedItem.type === "object") {
        return (
          <MeasureEditorFormWrapper ref={viewRef} name={`Object edition`}>
            <ObjectEditor
              initialData={objectInfos}
              error={objectInfos.hasError ? objectInfos.hasError : []}
              onSave={(objectData) => {
                updateStaleElement("object", objectInfos.id, objectData);
                return Promise.resolve();
              }}
              form={form}
              schemaResults={
                dataStore[tempTables[0].datasetId].data[tempTables[0].viewId]
                  .schema
              }
            />
          </MeasureEditorFormWrapper>
        );
      }

      if (selectedItem.type === "property") {
        const selectedProperty = objectInfos?.properties?.find(
          (p) => p.id === selectedItem.id
        );
        if (!selectedProperty) {
          return (
            <MeasureEditorFormWrapper
              ref={viewRef}
              name={`Unknown property`}
              fullSize={true}
            >
              <Feednack>Couldn't find property {selectedItem.id}</Feednack>
            </MeasureEditorFormWrapper>
          );
        }
        return (
          <MeasureEditorFormWrapper ref={viewRef} name={`Property edition`}>
            <PropertiesEditor
              initialData={selectedProperty}
              allObjects={allObjects ? allObjects : []}
              onSave={(property) => {
                updateStaleElement("property", selectedProperty.id, property);
                return Promise.resolve();
              }}
              error={selectedProperty.hasError ? selectedProperty.hasError : []}
              form={form}
              datasetId={tempTables[0].datasetId}
              currentWarehouse={currentWarehouse}
              schemaResults={
                dataStore[tempTables[0].datasetId].data[tempTables[0].viewId]
                  .schema
              }
            />
          </MeasureEditorFormWrapper>
        );
      }

      return (
        <MeasureEditorFormWrapper
          ref={viewRef}
          name={`Unknown type`}
          fullSize={true}
        >
          <Feednack>Unknown type {selectedItem.type}</Feednack>
        </MeasureEditorFormWrapper>
      );
    }

    const selectedParentTable = allTables.find(
      (t) => t.id === (selectedItems as any).table_id
    );
    if (!selectedParentTable) {
      return (
        <MeasureEditorFormWrapper ref={viewRef} name={`Not found`}>
          <Feednack>
            Couldn't find parent table {selectedParentTable.id}
          </Feednack>
        </MeasureEditorFormWrapper>
      );
    }

    if (selectedItems.object_type === "table") {
      return (
        <MeasureEditorFormWrapper
          ref={viewRef}
          name={`Add related data`}
          fullSize={true}
        >
          <RelatedData
            table={selectedParentTable}
            allIncomingDatasetRelationships={allIncomingDatasetRelationships}
            allDatasets={allDatasets}
            onAddRelatedData={(data, metrics, dimensions) => {
              const newId = generateFakeId();
              const elementToPush = [];
              (metrics || []).forEach((m) => {
                elementToPush.push({
                  type: "create",
                  objectType: "metric",
                  operationId: generateFakeId(),
                  parentTableId: newId,
                  data: m,
                });
              });
              (dimensions || []).forEach((m) => {
                elementToPush.push({
                  type: "create",
                  objectType: "dimension",
                  operationId: generateFakeId(),
                  parentTableId: newId,
                  data: m,
                });
              });
              elementToPush.push({
                type: "create",
                objectType: "table",
                parentTableId: selectedParentTable.id,
                operationId: newId,
                data: data,
              });
              pushStaleElement(elementToPush);
              onClick([
                {
                  type: "table",
                  id: newId,
                },
              ]);
            }}
            fetchSchema={fetchSchema}
          />
        </MeasureEditorFormWrapper>
      );
    }

    return <Feednack>Adding {selectedItems.object_type}</Feednack>;
  };

  return renderInner();
}
