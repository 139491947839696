import {
  EyeInvisibleOutlined,
  EyeOutlined,
  HomeOutlined,
} from "@ant-design/icons";
import { Breadcrumb, Button, Flex, Typography } from "antd";
import _ from "lodash";
import React from "react";
import { SwitchIcons } from "../../../../../../../../components/form/elements/switch-icons/SwitchIcons";
import { WlyFolderIcon } from "../../../../../../../../components/icons/custom-icons/WlyFolderIcon";
import MeasureItem from "../../../../../../../../components/measures/measure-item/MeasureItem";
import { type AvailableColumn } from "../../../../domain";
import "./AvailablePropertySelector.scss";
import { type PropertySelectorValue } from "./domain";

export interface IAvailablePropertySelectorProps {
  availableColumns?: Array<AvailableColumn>;
  value?: Array<PropertySelectorValue>;
  onChange?: (value: Array<PropertySelectorValue>) => any;
}

interface Hierarchy {
  [key: string]: Array<string> | Hierarchy;
}

export function AvailablePropertySelector(
  props: IAvailablePropertySelectorProps
) {
  const { availableColumns: availableColumnsProps, value, onChange } = props;

  const [selectedPath, setSelectedPath] = React.useState<string>("");

  const formatHierarchyPath = (p: string) => {
    return p
      .split("/")
      .map((r) => _.trim(r))
      .filter((s) => s !== "")
      .join(".");
  };

  const availableColumns = (
    availableColumnsProps ? availableColumnsProps : []
  ).map((ac) => {
    if (!ac.data.hierarchyPath) {
      ac.data.hierarchyPath = "";
    } else {
      ac.data.hierarchyPath = formatHierarchyPath(ac.data.hierarchyPath);
    }
    return ac;
  });

  let hierarchy: Hierarchy = {};

  availableColumns
    .map((ac) => ({
      path: formatHierarchyPath(ac.data.hierarchyPath ?? ""),
      value: ac.data.key,
    }))
    .map((h) => {
      const prev = _.get(hierarchy, h.path);
      if (prev && Array.isArray(prev)) {
        _.set(hierarchy, h.path, [...prev, h.value]);
      } else {
        _.set(hierarchy, h.path, [h.value]);
      }
    });

  const currentPath = formatHierarchyPath(selectedPath);
  const currentHierarchy = currentPath
    ? _.get(hierarchy, currentPath)
    : hierarchy;

  const rootColumns = availableColumns.filter(
    (s) => s.data.hierarchyPath === selectedPath
  );

  const currentSplittedPath = currentPath.split(".").filter((s) => s !== "");

  return (
    <>
      <div className="available-property-selector">
        <Breadcrumb
          items={[
            {
              onClick: () => setSelectedPath(""),
              title: (
                <Button size="small" type="text">
                  <HomeOutlined />
                </Button>
              ),
            },
            ...currentSplittedPath.map((pathItem, i, s) => {
              const prev = s.slice(0, i + 1);
              return {
                onClick: () => setSelectedPath(prev.join(".")),
                title: (
                  <Button size="small" type="text">
                    {pathItem}
                  </Button>
                ),
              };
            }),
          ]}
        />
      </div>
      <div className="columns-folder-items">
        {rootColumns.map((rc) => {
          const val = value ? value : [];
          const isVisible = !![...val].find(
            (v) => v.key === rc.data.key && v.visible
          );

          return (
            <Flex
              gap={8}
              key={rc.data.key}
              align="center"
              style={!isVisible ? { opacity: 0.5 } : {}}
            >
              <SwitchIcons
                icons={{
                  true: <EyeOutlined />,
                  false: <EyeInvisibleOutlined />,
                }}
                shape="default"
                size="small"
                value={isVisible}
                onChange={(checked) => {
                  if (checked) {
                    onChange?.(
                      [...val].map((v) => {
                        if (v.key === rc?.data?.key) {
                          return {
                            key: v.key,
                            visible: true,
                          };
                        }
                        return v;
                      })
                    );
                  } else {
                    onChange?.(
                      [...val].map((v) => {
                        if (v.key === rc?.data?.key) {
                          return {
                            key: v.key,
                            visible: false,
                          };
                        }
                        return v;
                      })
                    );
                  }
                }}
              />
              <div style={{ flex: 1, marginRight: 6 }}>
                <MeasureItem
                  key={rc.data.key}
                  name={rc.data.label}
                  type={rc.type}
                />
              </div>
            </Flex>
          );
        })}
        {Array.isArray(currentHierarchy)
          ? undefined
          : Object.keys(currentHierarchy ? currentHierarchy : {})
              .filter((s) => !!s)
              .map((k) => {
                return (
                  <Flex
                    style={{ marginTop: 2, marginBottom: 2 }}
                    gap={8}
                    key={k}
                    align="center"
                    className="columns-folder-item-hoverable"
                    onClick={() =>
                      setSelectedPath(selectedPath ? `${selectedPath}.${k}` : k)
                    }
                  >
                    <div
                      style={{
                        padding: 4,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <WlyFolderIcon
                        style={{
                          fontSize: 20,
                          color: "#2599D4",
                        }}
                      />
                    </div>
                    <div
                      style={{
                        flex: 1,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "start",
                        padding: 4,
                        paddingLeft: 6,
                      }}
                    >
                      <Typography.Text ellipsis>{k}</Typography.Text>
                    </div>
                  </Flex>
                );
              })}
      </div>
    </>
  );
}
