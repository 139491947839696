import { Button, Col, Empty, Flex, List, Space } from "antd";
import Title from "antd/es/typography/Title";
import * as React from "react";
import {
  withAntUtils,
  type InjectedAntUtilsProps,
} from "../../../../../../../components/ant-utils/withAntUtils";
import { compose } from "../../../../../../../components/compose/WlyCompose";
import { useGQLQuery } from "../../../../../../../components/hooks/query/useGQLQuery";
import Loading from "../../../../../../../components/layout/feedback/loading";
import {
  type IObject,
  type IObjectRegisteredAction,
} from "../../../../../../../interfaces/object";
import GraphQLService from "../../../../../../../services/graphql/GraphQLService";
import WithOrg, { type InjectedOrgProps } from "../../../../../../orgs/WithOrg";
import { ObjectRegisteredActionCard } from "./ObjectRegisteredActionCard";
import {
  CREATE_OBJECT_REGISTERED_ACTION,
  GET_OBJECT_REGISTERED_ACTIONS,
  UPDATE_OBJECT_REGISTERED_ACTION,
} from "./domain";
import {
  CreateRegisteredAction,
  type CreateEditRegisteredActionFormData,
} from "./modal/CreateRegisteredAction";

export interface IObjectRegisteredActionProps {
  object: IObject;
}

type Props = IObjectRegisteredActionProps &
  InjectedOrgProps &
  InjectedAntUtilsProps;

function ObjectRegisteredAction(props: Props) {
  const { org, object, antUtils } = props;

  const [isEditing, setIsEditing] = React.useState<
    CreateEditRegisteredActionFormData | undefined
  >(undefined);

  const [saving, setSaving] = React.useState<boolean>(false);

  const { data, error, pending, loading, refetch } = useGQLQuery<{
    allObjectRegisteredActions: IObjectRegisteredAction[];
  }>(GET_OBJECT_REGISTERED_ACTIONS, {
    variables: {
      orgId: org.id,
      objectId: object.id,
    },
  });

  if (pending || loading) {
    return <Loading />;
  }

  if (error) {
    return <>{error.message}</>;
  }

  const onSave = async (d: CreateEditRegisteredActionFormData) => {
    setSaving(true);
    try {
      if (d.id) {
        await GraphQLService(UPDATE_OBJECT_REGISTERED_ACTION, {
          id: d.id,
          data: {
            name: d.name,
            description: d.description,
            displayName: d.displayName,
            icon: d.icon,
            type: d.type,
            config: d.config,
          },
        });
      } else {
        await GraphQLService(CREATE_OBJECT_REGISTERED_ACTION, {
          data: {
            name: d.name,
            description: d.description,
            type: d.type,
            config: d.config,
            displayName: d.displayName,
            icon: d.icon,
            object: {
              connect: {
                id: object.id,
              },
            },
            org: {
              connect: {
                id: org.id,
              },
            },
          },
        });
      }
      await refetch();
      await setIsEditing(undefined);
      antUtils.message.success("Successfully saved your action");
    } catch (err) {
      antUtils.message.error(
        "There was an error saving your action, please retry"
      );
    } finally {
      setSaving(false);
    }
  };

  const onDelete = async (d: { id: string }) => {
    const l = antUtils.message.loading(null, 0);
    try {
      await GraphQLService(UPDATE_OBJECT_REGISTERED_ACTION, {
        id: d.id,
        data: {
          isDeleted: true,
        },
      });
      await refetch();
      antUtils.message.success("Your action was successfully deleted");
    } catch (err) {
      antUtils.message.error("There was an error deleting your action");
    } finally {
      l();
    }
  };

  return (
    <Col span={16} offset={4}>
      <Space direction="vertical" size={32} style={{ width: "100%" }}>
        <div>
          <Flex align="center" style={{ paddingTop: 16 }}>
            <div style={{ flex: 1 }}>
              <Title level={3} style={{ marginBottom: 0 }}>
                Registered actions
              </Title>
            </div>
            <Button
              onClick={() =>
                setIsEditing({
                  name: "",
                  displayName: "",
                  icon: "PhoneOutlined",
                  type: "FEEDBACK",
                  config: "{}",
                })
              }
            >
              Register an action
            </Button>
          </Flex>
        </div>
        {data?.allObjectRegisteredActions &&
        data?.allObjectRegisteredActions.length ? (
          <List
            grid={{
              gutter: 16,
              column: 1,
            }}
            dataSource={data?.allObjectRegisteredActions}
            renderItem={(_) => (
              <List.Item>
                <ObjectRegisteredActionCard
                  registeredAction={_}
                  onEdit={() => setIsEditing(_)}
                  onDelete={() => onDelete(_)}
                />
              </List.Item>
            )}
          />
        ) : (
          <div>
            <Empty description="No actions registered" />
          </div>
        )}
      </Space>
      <CreateRegisteredAction
        open={!!isEditing}
        isSaving={false}
        onSave={onSave}
        initialValues={isEditing}
        onCancel={() => setIsEditing(undefined)}
        object={object}
      />
    </Col>
  );
}

export default compose<Props, IObjectRegisteredActionProps>(
  WithOrg,
  withAntUtils
)(ObjectRegisteredAction);
