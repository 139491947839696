import Icon from "@ant-design/icons";
import type { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";

export const WlyAlertIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon
    component={() => (
      <svg
        width="1em"
        height="1em"
        viewBox="0 0 213 213"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M84.4516 57.8848C88.9824 49.9152 97.3933 45 106.5 45C115.607 45 124.018 49.9152 128.548 57.8848L172.052 134.407C173.984 137.804 175 141.652 175 145.568C175 157.956 165.045 168 152.765 168H60.2357C47.9552 168 38 157.956 38 145.568C38 141.652 39.0162 137.804 40.9473 134.407L84.4516 57.8848ZM106.5 56.5178C101.484 56.5178 96.8506 59.2253 94.355 63.6152L50.8507 140.137C49.9111 141.79 49.4167 143.662 49.4167 145.568C49.4167 151.596 54.2605 156.482 60.2357 156.482H152.765C158.739 156.482 163.583 151.596 163.583 145.568C163.583 143.662 163.089 141.79 162.149 140.137L118.645 63.6152C116.149 59.2253 111.516 56.5178 106.5 56.5178Z"
          fill="#5D6671"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M106.5 80C109.538 80 112 82.5667 112 85.7329V115.792C112 118.958 109.538 121.525 106.5 121.525C103.462 121.525 101 118.958 101 115.792V85.7329C101 82.5667 103.462 80 106.5 80ZM106.5 132.81C109.538 132.81 112 135.377 112 138.543V141.267C112 144.433 109.538 147 106.5 147C103.462 147 101 144.433 101 141.267V138.543C101 135.377 103.462 132.81 106.5 132.81Z"
          fill="#5D6671"
        />
      </svg>
    )}
    {...props}
  />
);
