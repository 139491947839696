import Icon from "@ant-design/icons";
import type { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";

export const WlyColorDropIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon
    component={() => (
      <svg
        width="1em"
        height="1em"
        viewBox="0 0 501 501"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M439.991 311.403C439.991 361.686 420.024 409.909 384.489 445.466C348.951 481.023 300.748 501 250.496 501C200.243 501 152.036 481.023 116.502 445.466C80.9668 409.909 61 361.685 61 311.403C61 194.443 205.923 40.347 242.299 3.44056C244.414 1.24222 247.336 0 250.383 0C253.434 0 256.352 1.24222 258.471 3.44056C294.405 41.8628 440 203.151 440 311.403H439.991Z"
          fill="black"
        />
      </svg>
    )}
    {...props}
  />
);
