import type { Filter } from "@cubejs-client/core";
import { compose } from "../../../../../../../../components/compose/WlyCompose";
import Feednack from "../../../../../../../../components/layout/feedback/feedback";
import type { IObject } from "../../../../../../../../interfaces/object";
import type { InjectedOrgProps } from "../../../../../../../orgs/WithOrg";
import WithOrg from "../../../../../../../orgs/WithOrg";
import {
  convertPropertyToAvailableProperties,
  getObjectColumns,
} from "../../../../../object/domain";
import type { IRecord } from "../../../../domain";
import type { IWidget } from "../../../domain";
import type { IRelatedListConfig } from "../domain";
import RelatedListQuery from "./RelatedListQuery";

interface IRecordRelatedListProps {
  widget: IWidget;
  object: IObject;
  record: IRecord;
  conf: IRelatedListConfig;
  edit?: boolean;
}

type Props = InjectedOrgProps & IRecordRelatedListProps;

function RecordRelatedList(props: Props) {
  const { conf, record, widget, object, edit } = props;

  if (!conf.foreignObjectPropertyId) {
    return (
      <div style={{ height: 250 }}>
        <Feednack>Missing configuration</Feednack>
      </div>
    );
  }

  const foreignProperty = object.foreignKeys.find(
    (p) => p.id === conf.foreignObjectPropertyId
  );

  if (!foreignProperty) {
    return (
      <div style={{ height: 250 }}>
        <Feednack>Can't find foreign property</Feednack>
      </div>
    );
  }

  if (!conf.options?.columns?.length) {
    return (
      <div style={{ height: 250 }}>
        <Feednack>Please add at least one column to display</Feednack>
      </div>
    );
  }

  const foreignObject = foreignProperty.object;

  const property = foreignObject.properties.find(
    (p) => p.id === conf.foreignObjectPropertyId
  );

  if (!property) {
    return (
      <div style={{ height: 250 }}>
        <Feednack>Property must be defined</Feednack>
      </div>
    );
  }

  const foreignAvailable = convertPropertyToAvailableProperties(
    foreignObject.table.cubeName,
    foreignObject,
    property
  );
  const cubeName = object.table.cubeName;
  const recordFilters = [
    {
      member: `${foreignAvailable.key}_raw`,
      operator: "equals",
      values: [record[`${cubeName}.id`] as string],
    },
  ];
  const availableColumns = getObjectColumns(foreignObject);
  const columns = conf.options.columns;
  const additionalFilters = conf.options.filters;
  const sortBy = conf.options?.sortBy ?? [];
  const groupBy = conf.options?.groupBy ?? [];
  const hidePagination = conf.options?.hidePagination;
  const showRowNumber = conf.options?.showRowNumber;
  const columnsSettings = conf.options?.columnsSettings ?? {};

  const filters = [
    {
      and: [
        ...recordFilters,
        additionalFilters
          ? { [additionalFilters.operator]: additionalFilters.filters }
          : { and: [] },
      ],
    },
  ] as Filter[];

  return (
    <RelatedListQuery
      object={foreignObject}
      availableColumns={availableColumns}
      pageSize={conf.options.pageSize || 10}
      filters={filters}
      sortBy={sortBy}
      groupBy={groupBy}
      columns={columns}
      columnsSettings={columnsSettings}
      hidePagination={hidePagination}
      showRowNumber={showRowNumber}
    />
  );
}

export default compose<Props, IRecordRelatedListProps>(WithOrg)(
  RecordRelatedList
);
