import { gql } from "@apollo/client";
import type { MutateOptions } from "../../../../../../components/hooks/query/useQGLMutate";
import { useGQLMutate } from "../../../../../../components/hooks/query/useQGLMutate";
import { OBJECT_FRAGMENT } from "../../../../../../fragments/object";

type MutationData = { updateObject: { id: string } };

type MutationVariables = {
  id: string;
  data: Record<string, any>;
};

const UpdateObjectQuery = gql`
  ${OBJECT_FRAGMENT}
  mutation UpdateObject($id: ID!, $data: ObjectUpdateInput!) {
    updateObject(id: $id, data: $data) {
      ...ObjectFragment
    }
  }
`;

export const useUpdateObject = (options?: MutateOptions<MutationData>) => {
  return useGQLMutate<MutationData, MutationVariables>(
    UpdateObjectQuery,
    options
  );
};
