import type {
  DiagramEngine,
  NodeModel,
  NodeModelGenerics,
} from "@projectstorm/react-diagrams";
import {
  DefaultLabelFactory,
  PortModelAlignment,
} from "@projectstorm/react-diagrams";
import type { Transformation } from "../../../../../../interfaces/transformations";
import type { CanvasNodeGenerator } from "./helpers/CanvasNodeGenerator";
import { DataNodeFactory } from "./nodes/data/DataNodeFactory";
import { EditableLabelFactory } from "./nodes/label/EditableLabelFactory";
import { FlowLinkFactory } from "./nodes/link/FlowLinkFactory";
import { OperationNodeFactory } from "./nodes/operation/OperationNodeFactory";
import { OutputNodeFactory } from "./nodes/output/OutputNodeFactory";
import { SimplePortFactory } from "./nodes/port/SimplePortFactory";
import { SimplePortModel } from "./nodes/port/SimplePortModel";
import { TeamMetricFactory } from "./nodes/team-metric/TeamMetricFactory";

export const GRID_SIZE = 68;

export interface WhalyNodeModel extends NodeModel<NodeModelGenerics> {
  bindCanvasNodeGenerator: (c: CanvasNodeGenerator) => void;
  bindTransformation: (d: Transformation) => void;
  getPrimaryInPort: () => SimplePortModel | undefined;
  getSecondaryInPort: () => SimplePortModel | undefined;
  getOutPort: () => SimplePortModel | undefined;
  hasWarning: () => boolean;
  isOutput: () => boolean;
  canEdit: () => boolean;
}

export const generateEngine = (engine: DiagramEngine): DiagramEngine => {
  engine
    .getPortFactories()
    .registerFactory(
      new SimplePortFactory(
        "diamond",
        (config) => new SimplePortModel(PortModelAlignment.LEFT)
      )
    );
  engine
    .getPortFactories()
    .registerFactory(
      new SimplePortFactory(
        "diamond",
        (config) => new SimplePortModel(PortModelAlignment.RIGHT)
      )
    );

  engine.getLabelFactories().registerFactory(new DefaultLabelFactory());

  engine.getLabelFactories().registerFactory(new EditableLabelFactory());

  engine.getLinkFactories().registerFactory(new FlowLinkFactory());

  engine.getNodeFactories().registerFactory(new OutputNodeFactory());

  engine.getNodeFactories().registerFactory(new OperationNodeFactory());

  engine.getNodeFactories().registerFactory(new DataNodeFactory());

  engine.getNodeFactories().registerFactory(new TeamMetricFactory());

  engine.maxNumberPointsPerLink = 0;

  return engine;
};
