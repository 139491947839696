import * as React from "react";
import { Link } from "react-router-dom";

import "./SubMenu.scss";

type ISubMenuItemProps = ISubMenuItemClickProps | ISubMenuItemLinkProps;

interface ISubMenuItemLinkProps extends ISubMenuItemBaseProps {
  to: string;
}

interface ISubMenuItemClickProps extends ISubMenuItemBaseProps {
  onClick: () => void;
}

interface ISubMenuItemBaseProps {
  selected: boolean;
  displayName: React.ReactNode;
  ref?: any;
  style?: React.CSSProperties;
}

export default class SubMenuItem extends React.Component<ISubMenuItemProps> {
  public render() {
    const { selected, displayName, ref, style } = this.props;

    if ((this.props as ISubMenuItemClickProps).onClick) {
      return (
        <div
          onClick={(this.props as ISubMenuItemClickProps).onClick}
          ref={ref}
          className={`whly-sub-menu-item ${selected ? "selected" : ""}`}
          style={style}
        >
          <div className="background">{displayName}</div>
        </div>
      );
    }

    return (
      <div
        ref={ref}
        className={`whly-sub-menu-item ${selected ? "selected" : ""}`}
      >
        <Link to={(this.props as ISubMenuItemLinkProps).to}>
          <div className="background">{displayName}</div>
        </Link>
      </div>
    );
  }
}
