import { Form, Input, Modal } from "antd";
import * as React from "react";
import "react-quill/dist/quill.snow.css";
import { compose } from "../../../../../components/compose/WlyCompose";
import Loading from "../../../../../components/layout/feedback/loading";
import type { AsyncData } from "../../../../../helpers/typescriptHelpers";
import type { IExploration } from "../../../../../interfaces/explorations";
import type { ComponentType, ITile } from "../../../../../interfaces/reports";
import GraphQLService from "../../../../../services/graphql/GraphQLService";
import type { InjectedOrgProps } from "../../../../orgs/WithOrg";
import WithOrg from "../../../../orgs/WithOrg";
import FilterDimensionSelector from "./FilterDimensionSelector";

interface IFilterEditionProps {
  onSave: (data: IFilterData, exploration?: IExploration) => Promise<any>;
  onCancel: () => void;
  tiles: ITile[];
}

interface IFilterData {
  id?: string;
  filterName: string;
  filterType: string;
  apiName?: string;
  defaultValue: string[];
  hidden?: boolean;
  labelDimension?: string;
  valueDimension: string;
  useDefaultTimeField?: boolean;
  requireValue?: boolean;
  componentType?: ComponentType;
  tilesToUpdate: Array<{
    tileId: string;
    tileName: string;
    dimensionToUpdate: string;
  }>;
}

const GRAPHQL = `
query fetchExplorations(
  $orgId: ID!,
) {
  allExplorations(where: {org: {id: $orgId} deleted_not: true}) {
    id
    name
    tables(where: { deleted_not: true }) {
      id
      name
      cubeName
      dimensions(where: { deleted_not: true }) {
        id
        type
        name
        cubeName
        columnName
        columnDomain
      }
    }
  }
}
`;

type Props = IFilterEditionProps & InjectedOrgProps;

const FilterEdition: React.FunctionComponent<Props> = (props) => {
  const { onSave, org, onCancel, tiles } = props;

  const [confirmLoading, setConfirmLoading] = React.useState(false);
  const [data, setData] = React.useState<AsyncData<Array<IExploration>>>({
    status: "initial",
  });
  const [form] = Form.useForm();

  React.useEffect(() => {
    setData({ status: "loading" });
    GraphQLService(GRAPHQL, {
      orgId: org.id,
    })
      .then((r) => {
        setData({ status: "success", data: r.allExplorations });
      })
      .catch((err) => {
        setData({ status: "error", error: err });
      });
  }, [org.id]);

  const explorationsFlatMap =
    data.status === "success"
      ? data.data.flatMap((ex) =>
          ex.tables.flatMap((t) =>
            t.dimensions.flatMap((d) => ({
              value: `${t.cubeName}.${d.cubeName}`,
              domain: d.type === "standard" ? d.columnDomain : undefined,
              explorationId: ex.id,
            }))
          )
        )
      : [];

  const renderInnerModal = () => {
    if (data.status === "initial" || data.status === "loading") {
      return <Loading />;
    }
    if (data.status === "error") {
      return <div>{JSON.stringify(data.error)}</div>;
    }

    const findCurrentDomain = (dimension: string) => {
      return explorationsFlatMap.find((v) => v.value === dimension) &&
        explorationsFlatMap.find((v) => v.value === dimension)!.domain
        ? explorationsFlatMap.find((v) => v.value === dimension)!.domain
        : "STRING";
    };

    const formattedTiles = tiles.filter(
      (t) => t.type === "chart" && t.exploration
    );
    return (
      <Form
        initialValues={{
          tilesToUpdate: (formattedTiles || []).map((t) => {
            return {
              tileId: t.id,
              tileName: t.name,
              dimensionToUpdate: "",
            };
          }),
        }}
        layout="vertical"
        form={form}
        onValuesChange={(change, val) => {
          // we need to update the labelDimension on clear
          // as the component makes it undefined and thus
          // update does not happed in graphql
          if (
            change &&
            "labelDimension" in change &&
            change.labelDimension === undefined
          ) {
            form.setFieldsValue({ labelDimension: null });
          }
          if (change && change.valueDimension) {
            form.setFieldsValue({ labelDimension: null });
          }
          if (change && change.requireValue === false) {
            form.setFieldsValue({ defaultValue: null });
          }
          if (change && !!change.valueDimension) {
            form.setFieldsValue({ defaultValue: undefined });
            if (change.valueDimension === "DEFAULT_TIME") {
              form.setFieldsValue({
                tilesToUpdate: [],
                filterType: "TIME",
                defaultValue: "LAST_7_DAYS",
                useDefaultTimeField: true,
                requireValue: true,
                componentType: "TIME_PICKER",
              });
            } else {
              const newDomain = findCurrentDomain(change.valueDimension);
              const newComponentType = (): ComponentType => {
                if (
                  newDomain === "TIME" &&
                  form.getFieldValue(["componentType"]) !== "TIME_PICKER"
                ) {
                  return "TIME_PICKER";
                } else if (
                  newDomain !== "TIME" &&
                  form.getFieldValue(["componentType"]) === "TIME_PICKER"
                ) {
                  return "SELECT";
                } else {
                  return form.getFieldValue(["componentType"]);
                }
              };

              form.setFieldsValue({
                tilesToUpdate: tiles
                  .filter((t) => t.type === "chart" && t.exploration)
                  .map((t, i) => {
                    const prevValue = form.getFieldValue([
                      "tilesToUpdate",
                      i,
                      "dimensionToUpdate",
                    ]);
                    return {
                      tileId: t.id,
                      tileName: t.name,
                      dimensionToUpdate: explorationsFlatMap.find(
                        (v) =>
                          v.value === change.valueDimension &&
                          v.explorationId === t.exploration.id
                      )
                        ? change.valueDimension
                        : prevValue,
                    };
                  }),
                useDefaultTimeField: false,
                requireValue:
                  newDomain === "TIME"
                    ? true
                    : !!form.getFieldValue(["requireValue"]),
                componentType: newComponentType(),
                defaultValue: newDomain === "TIME" ? "LAST_7_DAYS" : undefined,
                filterType: newDomain,
              });
            }
          }
        }}
      >
        <Form.Item
          name={["filterName"]}
          label="Filter name"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name={["valueDimension"]}
          label="Dimension to filter on"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <FilterDimensionSelector
            showDoNotFilter={false}
            showDefaultTime={true}
            explorations={data.data}
            groupUsedExplorations={data.data
              .map((exploration) => exploration.id)
              .filter((explorationId) =>
                tiles
                  .filter((tile) => !tile.deleted && tile.type === "chart")
                  .map((tile) => tile.exploration.id)
                  .includes(explorationId)
              )}
          />
        </Form.Item>
      </Form>
    );
  };

  return (
    <Modal
      open={true}
      title={"Create filter"}
      okText={"Create"}
      destroyOnClose={true}
      cancelText="Cancel"
      onCancel={onCancel}
      confirmLoading={confirmLoading}
      okButtonProps={{
        disabled: false,
      }}
      width={"50%"}
      onOk={() => {
        setConfirmLoading(true);
        return form
          .validateFields()
          .then((values) => {
            const v = values as IFilterData;
            const filterType = form.getFieldValue("filterType");
            const useDefaultTimeField = form.getFieldValue(
              "useDefaultTimeField"
            );

            const getComponentType = () => {
              if (v.filterType === "TIME") {
                return "TIME_PICKER";
              }
              return "SELECT";
            };

            const componentType = v.componentType
              ? v.componentType
              : getComponentType();

            const defaultValue = Array.isArray(v.defaultValue)
              ? v.defaultValue
              : [v.defaultValue].filter((v) => v);

            const tilesToUpdate = tiles
              .filter((t) => t.type === "chart" && t.exploration)
              .map((t, i) => {
                let dimensionToUpdate: string = "";
                if (
                  explorationsFlatMap.find(
                    (v) =>
                      v.value === values.valueDimension &&
                      v.explorationId === t.exploration.id
                  )
                ) {
                  dimensionToUpdate = values.valueDimension;
                }
                if (useDefaultTimeField) {
                  dimensionToUpdate = "DEFAULT_TIME";
                }

                return {
                  tileId: t.id,
                  tileName: t.name,
                  dimensionToUpdate: dimensionToUpdate,
                };
              });

            const explorationId = explorationsFlatMap.find(
              (explo) => explo.value === v.valueDimension
            )?.explorationId;
            const explorations = data.status === "success" ? data.data : [];
            const foundExploration = explorations.find(
              (a) => a.id === explorationId
            );

            return onSave(
              {
                ...v,
                componentType,
                defaultValue,
                filterType,
                useDefaultTimeField,
                tilesToUpdate,
              },
              foundExploration
            ).then(() => setConfirmLoading(false));
          })
          .catch((info) => {
            setConfirmLoading(false);
            console.error("Validate Failed:", info);
          });
      }}
    >
      {renderInnerModal()}
    </Modal>
  );
};

export default compose<Props, IFilterEditionProps>(WithOrg)(FilterEdition);
