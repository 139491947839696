import { Space, Typography } from "antd";
import * as React from "react";
import { parseObjectPropertyFormatter } from "../../../../../containers/v2-demo/container/object/viewer/domain";
import type { IObjectPropertyActivitiesFormatterConfig } from "../../../../../interfaces/object";

export interface IObjectTableHeaderActivitiesPopoverProps {
  conf: string;
  description?: string;
}

export function ObjectTableHeaderActivitiesPopover(
  props: IObjectTableHeaderActivitiesPopoverProps
) {
  const { conf, description } = props;

  const config = React.useMemo(
    () =>
      parseObjectPropertyFormatter(
        "activities",
        conf || ""
      ) as IObjectPropertyActivitiesFormatterConfig,
    [conf]
  );

  return (
    <div>
      <div style={{ marginBottom: 12, whiteSpace: "pre-line" }}>
        {description}
      </div>
      <Space direction="vertical" size={"small"}>
        {config.config.series.map((s) => {
          return (
            <div style={{ display: "flex", gap: 8, height: 24 }} key={s.key}>
              <div
                style={{
                  flex: `0 24px`,
                  backgroundColor: s.color,
                  height: 24,
                  width: 24,
                  borderRadius: 6,
                }}
              />
              <div style={{ flex: 1 }}>
                <Typography.Text strong ellipsis>
                  {s.label}
                </Typography.Text>
              </div>
            </div>
          );
        })}
      </Space>
    </div>
  );
}
