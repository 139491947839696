import * as React from "react";
import { compose } from "../../../components/compose/WlyCompose";
import Error from "../../../components/layout/feedback/error";
import Loading from "../../../components/layout/feedback/loading";
import type { IOrg } from "../../../interfaces/org";
import GraphQl from "../../graphql/graphql";
import type { InjectedOrgProps } from "../../orgs/WithOrg";
import WithOrg from "../../orgs/WithOrg";
import SettingsWrapper from "../SettingsWrapper";
import BasicSettingForm from "./BaseSettingForm";

interface IBasicSettingPageProps {}

const gql = `
  query getCurrentOrg(
    $orgId: ID!
  ) {
    Org(where: { id: $orgId }) {
      id
      slug
      name
      client_secret
      createdAt
      createdBy {
        id
      }
    }
  }
`;

type Props = IBasicSettingPageProps & InjectedOrgProps;

class BasicSettingPage extends React.Component<Props> {
  public render() {
    const { org } = this.props;

    return (
      <SettingsWrapper>
        <GraphQl<IOrg> query={gql} variables={{ orgId: org.id }} extract="Org">
          {(gql) => {
            if (gql.status === "loading" || gql.status === "initial") {
              return <Loading />;
            }
            if (gql.status === "error") {
              return <Error />;
            }
            return <BasicSettingForm orgData={gql.data} />;
          }}
        </GraphQl>
      </SettingsWrapper>
    );
  }
}

export default compose<Props, IBasicSettingPageProps>(WithOrg)(
  BasicSettingPage
);
