import { CaretDownOutlined, LayoutOutlined } from "@ant-design/icons";
import { Typography } from "antd";
import { compose } from "../../../../../../components/compose/WlyCompose";
import { WlyDynamicIcon } from "../../../../../../components/icons/dynamic-renderer/WlyDynamicIconRenderer";
import { IconHolder } from "../../../../../../components/icons/holder/IconHolder";
import type { IObject, IObjectView } from "../../../../../../interfaces/object";
import { BORDER_COLOR } from "../../../layout/domain";
import ObjectViews from "./ObjectViews";

type IObjectHeaderProps = {
  object: IObject;
  views: IObjectView[];
  activeView?: IObjectView;
  setActiveView: (slug: string | undefined) => void;
};

type Props = IObjectHeaderProps;

const ObjectHeader = ({ activeView, object, views, setActiveView }: Props) => {
  return (
    <div
      style={{
        borderBottom: `1px solid ${BORDER_COLOR}`,
        padding: `12px 8px`,
        display: "flex",
        gap: 16,
      }}
    >
      <div
        style={{
          width: 48,
        }}
      >
        <IconHolder
          size={48}
          color="#F9F8F8"
          background={object.color || "#F1BD6C"}
          icon={
            object.icon ? (
              <WlyDynamicIcon name={object.icon} />
            ) : (
              <LayoutOutlined color="#F9F8F8" />
            )
          }
        />
      </div>
      <div
        style={{
          display: "flex",
          flex: 1,
          justifyContent: "center",
          overflowX: "hidden",
          flexDirection: "column",
          overflow: "hidden",
        }}
      >
        <div>
          <Typography.Text style={{ marginBottom: 0 }} strong type="secondary">
            {object.name}
          </Typography.Text>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "end",
          }}
        >
          <ObjectViews
            object={object}
            views={views}
            activeView={activeView}
            setActiveView={setActiveView}
          >
            <span style={{ cursor: "pointer" }}>
              <Typography.Title style={{ marginBottom: 0 }} level={5}>
                {activeView ? activeView.name : `All ${object.name}`}
                <span style={{ fontSize: "0.7em", paddingLeft: 4 }}>
                  <CaretDownOutlined />
                </span>
              </Typography.Title>
            </span>
          </ObjectViews>
        </div>
      </div>
    </div>
  );
};
export default compose<Props, IObjectHeaderProps>()(ObjectHeader);
