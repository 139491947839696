import {
  authenticatedRequest,
  getAccessToken,
  getAuthorizationType,
} from "../authService";

import type { DocumentNode } from "@apollo/client";
import {
  ApolloClient,
  createHttpLink,
  gql,
  InMemoryCache,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import StorageService from "../StorageService";

const coreUri = StorageService.getItem("_wly_override_core_uri")
  ? StorageService.getItem("_wly_override_core_uri")
  : window.WHALY_CONSTANTS.API_URL;

const httpLink = createHttpLink({
  uri: `${coreUri}/v1/graphql`,
});

const authLink = setContext((_, { headers, body }) => {
  // get the authentication token from local storage if it exists
  const token = getAccessToken();
  const type = getAuthorizationType();
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `${type} ${token}` : "",
    },
    body: {
      ...body,
      operationName: _.operationName,
    },
  };
});

export const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
  connectToDevTools: true,
});

export default <T = any>(
  query: string | DocumentNode,
  variables: any,
  queryType?: string
): Promise<T> => {
  const gqlQuery = typeof query === "string" ? gql(query) : query;
  if (
    gqlQuery.definitions.filter((d) => d.kind === "OperationDefinition")
      .length > 1
  ) {
    throw new Error("Please pass only one OperationDefinition");
  }
  const op = (
    gqlQuery.definitions.filter(
      (d) => d.kind === "OperationDefinition"
    )[0] as any
  ).operation;

  return authenticatedRequest()
    .then((): Promise<any> => {
      if (op === "query") {
        return client.query({
          query: typeof query === "string" ? gql(query) : query,
          variables,
          fetchPolicy: "network-only",
        });
      }
      return client.mutate({
        mutation: typeof query === "string" ? gql(query) : query,
        variables,
        fetchPolicy: "network-only",
      });
    })
    .then((r: any) => {
      if (r.errors) {
        throw new Error(JSON.stringify(r.errors));
      }
      if (!r.data) {
        throw new Error("There was an error with your query");
      }
      if (queryType) {
        return r.data[queryType] as T;
      }
      return r.data as T;
    })
    .catch((err) => {
      console.error(err);
      throw new Error(err);
    });
};
