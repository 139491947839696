import { DownloadOutlined } from "@ant-design/icons";
import * as React from "react";
import { useDrop } from "react-dnd";
import type { DraggedItemProps } from "../../../../../custom-layout-editor/main/sider/add/domain";
import { IObjectLayoutItemType } from "../../../../../custom-layout-editor/main/sider/add/domain";
import { COLOR_GOLD, COLOR_NEUTRAL, COLOR_UP } from "../../../layout/domain";

interface IEmptyColumnDropZoneProps {
  style?: React.CSSProperties;
  colId: string;
  drop: {
    accept: IObjectLayoutItemType;
    onDrop: (item: DraggedItemProps) => void;
  };
  children?: React.ReactNode;
}

export function EmptyColumnDropZone(props: IEmptyColumnDropZoneProps) {
  const { drop, style, colId, children } = props;

  const [{ isOver }, dropRef] = useDrop(
    () => ({
      accept: drop?.accept ? drop.accept : "none",
      drop: drop?.onDrop,
      collect: (monitor) => {
        const canDrop = true;

        return {
          isOver: canDrop ? !!monitor.isOver() : false,
        };
      },
    }),
    [colId]
  );

  const getBarColor = () => {
    if (drop?.accept === IObjectLayoutItemType.LAYOUT) {
      return COLOR_UP;
    }
    if (drop?.accept === IObjectLayoutItemType.WIDGET) {
      return COLOR_GOLD;
    }
    return COLOR_NEUTRAL;
  };

  const layoutAddBar = (
    <div style={{ outline: `2px solid ${getBarColor()}` }} />
  );
  return (
    <div ref={dropRef} style={style}>
      <div
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "#ECF3FC",
          borderRadius: 6,
          border: `1px dashed #8AB7EC`,
          color: "#8AB7EC",
        }}
      >
        <div
          style={{
            padding: `12px 0`,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div>
            <DownloadOutlined />
          </div>
          <div>Drop content here</div>
        </div>
      </div>
      {isOver ? layoutAddBar : null}
    </div>
  );
}
