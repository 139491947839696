import { Checkbox, Form, type FormInstance, InputNumber, Select } from "antd";
import type { IObject } from "../../../../../../../../../interfaces/object";
import { getObjectColumns } from "../../../../../../../../v2-demo/container/object/domain";

export interface IOwnPropertySectionItemFormProps {
  object: IObject;
  form: FormInstance;
}

export function OwnPropertySectionItemForm(
  props: IOwnPropertySectionItemFormProps
) {
  const { object, form } = props;

  const columns = getObjectColumns(object);
  const properties = columns.filter((c) => c.type === "property");

  return (
    <>
      <Form.Item
        rules={[{ required: true }]}
        label="Property"
        name={["property", 0, "key"]}
      >
        <Select>
          {properties.map((p) => {
            return (
              <Select.Option value={p.data.key} key={p.data.key}>
                {p.data.label}
              </Select.Option>
            );
          })}
        </Select>
      </Form.Item>
      <Form.Item noStyle shouldUpdate>
        {() => {
          const foundProperty = properties.find(
            (p) => p.data.key === form.getFieldValue(["property", 0, "key"])
          );
          if (
            foundProperty &&
            foundProperty.type === "property" &&
            foundProperty.data.domain === "TIME"
          ) {
            return null;
          }
          return (
            <>
              <Form.Item
                name={["property", 0, "config", "singleValueOnly"]}
                valuePropName="checked"
              >
                <Checkbox>Allow only single value</Checkbox>
              </Form.Item>
              <Form.Item
                name={["property", 0, "config", "limit"]}
                label={"Autocomplete limit"}
              >
                <InputNumber placeholder="100" style={{ width: "100%" }} />
              </Form.Item>
            </>
          );
        }}
      </Form.Item>
    </>
  );
}
