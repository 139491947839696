import { CloseOutlined, FullscreenOutlined } from "@ant-design/icons";
import {
  Avatar,
  Button,
  Divider,
  Modal,
  Skeleton,
  Space,
  Typography,
} from "antd";
import _ from "lodash";
import { useState } from "react";
import type { RouteComponentProps } from "react-router";
import { withRouter } from "react-router";
import { compose } from "../../../../../components/compose/WlyCompose";
import { routeDescriptor } from "../../../../../routes/routes";
import type { InjectedOrgProps } from "../../../../orgs/WithOrg";
import WithOrg from "../../../../orgs/WithOrg";
import RecordView from "../../record/RecordView";
import { EmbedType } from "../../record/domain";
import "./RecordModal.scss";
import type { IRecordModalContext } from "./RecordModalContext";
import { RecordModalContext } from "./RecordModalContext";

interface IRecordModalProps {
  open: boolean;
  onClose: () => void;
}

type Props = IRecordModalProps &
  InjectedOrgProps &
  RouteComponentProps<{ layoutId?: string }>;

function RecordModal(props: Props) {
  const {
    history,
    match: { params },
    open,
    org,
    onClose,
  } = props;

  // const [opacity, setOpacity] = React.useState<number>(0);
  const [modalContext, setModalContext] = useState<
    IRecordModalContext | undefined
  >(undefined);

  const context = {
    value: modalContext,
    setValue: (v: IRecordModalContext) =>
      !_.isEqual(v, modalContext) ? setModalContext(v) : undefined,
  };

  // React.useEffect(() => {
  //   const getElement = () => {
  //     const el = document.querySelectorAll(".record-modal .record-body");
  //     if (el && el.length) {
  //       return el[0];
  //     }
  //   };
  //   const el = getElement() || document;
  //   const onSroll = () => {
  //     const el = document.querySelectorAll(
  //       ".record-modal .record-body .record-view-header "
  //     );
  //     if (el && el.length) {
  //       const l = el[0];
  //       const dr = l.getBoundingClientRect();

  //       if (dr.top > 44) {
  //         return setOpacity(0);
  //       }
  //       const percentage = Math.abs((dr.top - 44) / dr.height);
  //       if (percentage > 1) {
  //         return setOpacity(1);
  //       }

  //       return setOpacity(percentage);
  //     }
  //   };
  //   el.addEventListener("scroll", onSroll);
  //   return () => el.removeEventListener("scroll", onSroll);
  // }, []);

  const onFullScreen = () => {
    history.replace(
      routeDescriptor["object-record"].renderRoute({
        organizationSlug: org.slug,
        ...params,
      })
    );
  };

  const renderModalHeader = () => {
    if (
      !modalContext ||
      modalContext?.status === "initial" ||
      modalContext.status === "loading"
    ) {
      return (
        <div className="record-title">
          <div className="record-title-content">
            <div
              style={{ display: "flex", gap: 8, alignItems: "center" }}
              key="1"
            >
              <div style={{ flex: `0 24px` }}>
                <Skeleton.Button
                  shape="circle"
                  size="small"
                  active
                  style={{ width: 22 }}
                />
              </div>
              <div style={{ flex: `1` }}>
                <Skeleton.Button
                  shape="round"
                  size="small"
                  active
                  style={{ width: 75 }}
                />
              </div>
            </div>
          </div>
          <div className="record-title-action">
            <Space>
              {/* <Button
                  onClick={onFullScreen}
                  icon={<FolderOutlined style={{ color: "gray" }} />}
                  type="text"
                  shape="circle"
                  size="small"
                />
                <Divider type="vertical" /> */}
              <Button
                onClick={onFullScreen}
                icon={<FullscreenOutlined style={{ color: "gray" }} />}
                type="text"
                shape="circle"
                size="small"
              />
              <Button
                onClick={onClose}
                icon={<CloseOutlined style={{ color: "gray" }} />}
                type="text"
                shape="circle"
                size="small"
              />
            </Space>
          </div>
        </div>
      );
    }

    if (modalContext.status === "error") {
      return (
        <div className="record-title">
          <div className="record-title-content">
            <div
              style={{ display: "flex", gap: 8, alignItems: "center" }}
              key="1"
            >
              <div style={{ flex: `1` }}>
                <Typography.Text type="danger">
                  Error while fetching your record
                </Typography.Text>
              </div>
            </div>
          </div>
          <div className="record-title-action">
            <Space>
              {/* <Button
                  onClick={onFullScreen}
                  icon={<FolderOutlined style={{ color: "gray" }} />}
                  type="text"
                  shape="circle"
                  size="small"
                />
                <Divider type="vertical" /> */}
              <Button
                onClick={onFullScreen}
                icon={<FullscreenOutlined style={{ color: "gray" }} />}
                type="text"
                shape="circle"
                size="small"
              />
              <Button
                onClick={onClose}
                icon={<CloseOutlined style={{ color: "gray" }} />}
                type="text"
                shape="circle"
                size="small"
              />
            </Space>
          </div>
        </div>
      );
    }

    const data = modalContext.data;

    return (
      <div className="record-title">
        <div className="record-title-content">
          {data && (
            <div
              style={{ display: "flex", gap: 8, alignItems: "center" }}
              key="1"
            >
              {data?.image ? (
                <div style={{ flex: `0 24px` }}>
                  <Avatar size={24} src={data?.image} />
                </div>
              ) : null}
              <div style={{ flex: `1` }}>
                <Typography.Text strong>
                  {data?.name ? data?.name : data?.id}
                </Typography.Text>
              </div>
            </div>
          )}
        </div>
        <div className="record-title-action">
          <Space>
            {data.additionalButtons && data.additionalButtons?.length
              ? [
                  ...data.additionalButtons,
                  <Divider key="div" type="vertical" />,
                ]
              : null}
            <Button
              onClick={onFullScreen}
              icon={<FullscreenOutlined style={{ color: "gray" }} />}
              type="text"
              shape="circle"
              size="small"
            />
            <Button
              onClick={onClose}
              icon={<CloseOutlined style={{ color: "gray" }} />}
              type="text"
              shape="circle"
              size="small"
            />
          </Space>
        </div>
      </div>
    );
  };

  const layoutId = params.layoutId;

  return (
    <RecordModalContext.Provider value={context}>
      <Modal
        maskClosable={false}
        closable={false}
        width={"90%"}
        centered
        className="record-modal"
        destroyOnClose={true}
        styles={{
          body: {
            height: "100%",
            maxHeight: "100%",
            overflowX: "hidden",
            overflowY: "auto",
          },
        }}
        onCancel={onClose}
        footer={null}
        open={open}
      >
        {renderModalHeader()}
        <div
          style={{
            display: "flex",
            overflow: "hidden",
            maxHeight: "calc(100% - 44px)",
            height: "100%",
          }}
        >
          <div style={{ flex: 1, minWidth: 0 }} className="record-body">
            <RecordView
              homePageConfig={layoutId ? { layoutId: layoutId } : undefined}
              embedType={EmbedType.recordModal}
            />
          </div>
          {modalContext &&
          modalContext.status === "success" &&
          modalContext.data.recommendations &&
          modalContext.data.recommendations.length ? (
            <div className="record-panel" id="record-panel-modal-context">
              {modalContext.data.recommendations}
            </div>
          ) : null}
        </div>
      </Modal>
    </RecordModalContext.Provider>
  );
}

export default compose<Props, IRecordModalProps>(
  withRouter,
  WithOrg
)(RecordModal);
