import { Space, Typography } from "antd";
import { BORDER_COLOR } from "../../layout/domain";
import CustomizeHomePageSelector from "./CustomizeHomePageSelector";
import type { ContentSelectorType, CustomizeHomeHideConfig } from "./domain";
import { isContentSelectorValid } from "./domain";

interface ICustomizeHomePageProps {
  hide?: CustomizeHomeHideConfig;
  onChange: (color: string, value: ContentSelectorType) => void;
  color: string;
  layout: ContentSelectorType;
}

export function CustomizeHomePage(props: ICustomizeHomePageProps) {
  const { hide, color, layout, onChange } = props;

  const colorPresets = [
    {
      label: "wlyColors",
      colors: [
        "transparent",
        "#D1E2FF",
        "#C4ECFF",
        "#C2F4F0",
        "#CEF5D1",
        "#FEEAB6",
        "#FFE0CD",
        "#FFD4E0",
        "#FBD1FC",
        "#E0DAFD",
        "#E5E9F0",
      ],
    },
  ];

  return (
    <Space direction="vertical" size={"large"} style={{ display: "flex" }}>
      {!hide?.includes("color") && (
        <Space direction="vertical" style={{ display: "flex" }}>
          <Typography.Text strong>Background Color</Typography.Text>
          <Space wrap>
            {colorPresets
              .flatMap((c) => {
                return c.colors;
              })
              .map((c, index) => {
                return (
                  <div
                    key={index}
                    onClick={() => onChange(c, layout)}
                    style={{
                      width: 32,
                      height: 32,
                      backgroundColor: c,
                      borderRadius: 32,
                      outline: color === c ? "2px solid black" : undefined,
                      outlineOffset: 2,
                      border: `1px solid ${BORDER_COLOR}`,
                      cursor: "pointer",
                    }}
                  />
                );
              })}
          </Space>
        </Space>
      )}
      {!hide?.includes("layout") && (
        <CustomizeHomePageSelector
          value={layout}
          onChange={(v) => {
            if (isContentSelectorValid(v)) {
              onChange(color, v);
            }
          }}
        />
      )}
    </Space>
  );
}
