import { Button } from "antd";
import type { RouteComponentProps } from "react-router";
import { withRouter } from "react-router";
import Logo from "../../../assets/icon.svg";
import { compose } from "../../../components/compose/WlyCompose";
import Feednack from "../../../components/layout/feedback/feedback";
import { routeDescriptor } from "../../../routes/routes";

interface INoUserProps {}

type Props = INoUserProps & RouteComponentProps;

function NoUser(props: Props) {
  return (
    <Feednack>
      <div>
        <div style={{ paddingBottom: 12 }}>
          <img src={Logo} style={{ width: 120, height: 120 }} alt={"logo"} />
        </div>
        <div>
          {" "}
          You don't seem to have the proper right to view this page. Are you
          sure you typed the right address?
        </div>
        <div style={{ paddingTop: 12 }}>
          <Button
            onClick={() =>
              props.history.push(
                routeDescriptor["home"].renderRoute({
                  ...props.match.params,
                })
              )
            }
          >
            Go to homepage
          </Button>
        </div>
      </div>
    </Feednack>
  );
}

export default compose<Props, INoUserProps>(withRouter)(NoUser);
