import type { NodeModelGenerics } from "@projectstorm/react-diagrams";
import { NodeModel, PortModelAlignment } from "@projectstorm/react-diagrams";
import type { TableTabItem } from "../../../../../../spreadsheet/domain";
import type { FetchedDestination } from "../../../../domain";
import { SimplePortModel } from "../../flow/nodes/port/SimplePortModel";
import { HEIGHT, WIDTH } from "./DatasetNodeWidget";

export class DatasetNodeModel extends NodeModel<NodeModelGenerics> {
  tableTabItem: TableTabItem;
  isFeatured: boolean;
  destination: FetchedDestination;

  constructor(isFeatured = false) {
    super({
      type: "data",
    });

    this.isFeatured = isFeatured;

    const outPort = new SimplePortModel(PortModelAlignment.RIGHT);
    outPort.setDirection("out");
    this.addPort(outPort);

    const inPort = new SimplePortModel(PortModelAlignment.LEFT);
    inPort.setDirection("in");
    this.addPort(inPort);

    this.width = WIDTH;
    this.height = HEIGHT;
  }

  bindDataset = (d: TableTabItem) => {
    this.tableTabItem = d;
  };

  bindDestination = (d: FetchedDestination) => {
    this.destination = d;
  };

  getPort = (name: string): SimplePortModel => {
    return super.getPort(name) as SimplePortModel;
  };

  getInPort = (): SimplePortModel => {
    return super.getPort(PortModelAlignment.LEFT) as SimplePortModel;
  };

  getOutPort = (): SimplePortModel => {
    return super.getPort(PortModelAlignment.RIGHT) as SimplePortModel;
  };
}
