import { action, makeObservable, observable } from "mobx";
import { refreshPortalJwt, refreshToken } from "../auth/auth";
import type {
  JwtTokenResource,
  RefreshTokenResource,
} from "../interfaces/auth";
import StorageService from "../services/StorageService";

const safetyNet = 10;

class AuthStore {
  authentication: RefreshTokenResource | JwtTokenResource | undefined;
  loading: boolean;
  refreshing: boolean;

  constructor() {
    makeObservable(this, {
      authentication: observable,
      loading: observable,
      refreshing: observable,
      login: action,
      saveToken: action,
      refreshSession: action,
      logout: action,
    });

    this.loading = false;
    this.refreshing = false;
  }

  generateTimeout = (delay: number) => {
    if (!(window as any).timeout) {
      this.refreshing = true;
      setTimeout(() => {
        this.refreshSession();
        (window as any).timeout = false;
      }, (delay - safetyNet) * 1000);
    }
  };

  saveToken = (r: RefreshTokenResource | JwtTokenResource) => {
    const ra = {
      ...r,
      expires_at: Date.now() + r.expires_in * 1000 - safetyNet * 1000,
    };
    window.authentication = ra;
    this.authentication = ra;
    StorageService.setItem(ra as any);
    this.generateTimeout(r.expires_in);
  };

  login(code: string) {
    this.loading = true;
    return refreshToken({ code: code, grant_type: "authorization_code" }).then(
      action((r) => {
        const ra = {
          ...r,
          expires_at: Date.now() + r.expires_in * 1000 - safetyNet * 1000,
        };
        this.saveToken(r);
        this.loading = false;
        return r;
      })
    );
  }

  refreshSession() {
    this.refreshing = true;
    if (this.authentication) {
      if (this.authentication.token_type === "bearer") {
        // refreshing the access token with the refresh token;
        return refreshToken({
          refresh_token: this.authentication!.refresh_token,
          grant_type: "refresh_token",
        })
          .then(
            action((r) => {
              const ra = {
                ...r,
                expires_at: Date.now() + r.expires_in * 1000 - safetyNet * 1000,
              };
              window.authentication = ra;
              this.authentication = ra;
              this.refreshing = false;
              this.saveToken(r);
              return r;
            })
          )
          .catch((err) => console.error(err));
      } else if (this.authentication.token_type === "portal-jwt") {
        // refresh the jwt session here

        return refreshPortalJwt(this.authentication.access_token)
          .then(
            action((r) => {
              const expireIn = r.expire_in ? parseFloat(r.expire_in) : 2500;
              const ra: JwtTokenResource = {
                token_type: "portal-jwt",
                expires_in: expireIn,
                expires_at: Date.now() + expireIn * 1000 - 10 * 1000,
                access_token: r.token,
              };
              window.authentication = ra;
              this.authentication = ra;
              this.refreshing = false;

              this.saveToken(ra);
              return r;
            })
          )
          .catch((err) => console.error(err));
      }
    }
  }

  logout() {
    this.authentication = undefined;
  }
}

export interface AuthStoreProps {
  authStore: AuthStore;
}

export default new AuthStore();
