import { gql } from "@apollo/client";

export const OBJECT_REGISTERED_ACTION_FRAGMENT = gql`
  fragment ObjectRegisteredActionFragment on ObjectRegisteredAction {
    id
    name
    displayName
    icon
    description
    type
    config
  }
`;

export const GET_OBJECT_REGISTERED_ACTIONS = gql`
  ${OBJECT_REGISTERED_ACTION_FRAGMENT}

  query allObjectRegisteredActions($orgId: ID!, $objectId: ID!) {
    allObjectRegisteredActions(
      where: {
        isDeleted: false
        org: { id: $orgId }
        object: { id: $objectId }
      }
    ) {
      ...ObjectRegisteredActionFragment
    }
  }
`;

export const CREATE_OBJECT_REGISTERED_ACTION = gql`
  ${OBJECT_REGISTERED_ACTION_FRAGMENT}

  mutation CreateObjectRegisteredAction(
    $data: ObjectRegisteredActionCreateInput!
  ) {
    createObjectRegisteredAction(data: $data) {
      ...ObjectRegisteredActionFragment
    }
  }
`;

export const UPDATE_OBJECT_REGISTERED_ACTION = gql`
  ${OBJECT_REGISTERED_ACTION_FRAGMENT}

  mutation UpdateObjectRegisteredAction(
    $id: ID!,
    $data: ObjectRegisteredActionUpdateInput!
  ) {
    updateObjectRegisteredAction(id: $id, data: $data) {
      ...ObjectRegisteredActionFragment
    }
  }
`;
