import type { DecorationSet, ViewUpdate } from "@codemirror/view";
import {
  Decoration,
  EditorView,
  MatchDecorator,
  ViewPlugin,
  WidgetType,
} from "@codemirror/view";
import type { FormulaEditorColumnAutocompleteValue } from "./CalculatedMetricAutocomplete";

export function CalculatedMetricFormulaEditorColumnsHighlight(
  completionItems?: FormulaEditorColumnAutocompleteValue[]
) {
  const buildRegex = (
    completionItems?: FormulaEditorColumnAutocompleteValue[]
  ): RegExp => {
    if (!completionItems || completionItems.length === 0) {
      // we create a regex that never matches anything
      return new RegExp("a^", "g");
    }
    const map = completionItems
      .map((ci) => ci.key.replaceAll("$", "\\$"))
      .join("|");
    const reg = new RegExp(`(?<!")(${map})`, "g");
    return reg;
  };
  const placeholderMatcher = new MatchDecorator({
    regexp: buildRegex(completionItems),
    decoration: (match) =>
      Decoration.replace({
        widget: new PlaceholderWidget(match[0]),
      }),
  });

  class PlaceholderWidget extends WidgetType {
    constructor(readonly name: string) {
      super();
    }
    eq(other: PlaceholderWidget) {
      return this.name === other.name;
    }
    toDOM() {
      let elt = document.createElement("span");
      let label = "Unknown column";
      const match = (completionItems || []).find((ci) => ci.key === this.name);
      if (match) {
        label = match.label;
      }

      if (match?.highlightColor === "lightblue") {
        elt.style.cssText = `
        border-radius: 4px;
        padding: 1px 6px;
        height: 18px;
        font-family: Lato;
        font-weight: 700;
        border: 1px solid lightblue;
        background: #0ec3f929;`;
      } else if (match?.highlightColor === "green") {
        elt.style.cssText = `
        border-radius: 4px;
        padding: 1px 6px;
        height: 18px;
        font-weight: 700;
        border: 1px solid #61f90e73;
        background: #15c649a1;`;
      } else if (match?.highlightColor === "purple") {
        elt.style.cssText = `
        border-radius: 4px;
        padding: 1px 6px;
        height: 18px;
        border: 1px solid 800ef973;
        font-family: Lato;
        font-weight: 700;
        color: white;
        background: #800ef98f;`;
      } else {
        elt.style.cssText = `
        border-radius: 3px;
        padding: 3px;
        font-size: 0.9em;
        font-family: Lato;
        font-weight: 700;
        color: white;
        background: #5E8CC5;`;
      }

      elt.textContent = label;

      return elt;
    }
    ignoreEvent() {
      return false;
    }
  }

  const placeholders = ViewPlugin.fromClass(
    class {
      placeholders: DecorationSet;

      constructor(view: EditorView) {
        this.placeholders = placeholderMatcher.createDeco(view);
      }
      update(update: ViewUpdate) {
        this.placeholders = placeholderMatcher.updateDeco(
          update,
          this.placeholders
        );
      }
    },
    {
      decorations: (instance) => instance.placeholders,
      provide: (plugin) =>
        EditorView.atomicRanges.of((view) => {
          return view.plugin(plugin)?.placeholders || Decoration.none;
        }),
    }
  );

  return placeholders;
}
