import { gql } from "@apollo/client";
import type { QueryOptions } from "../../../../../../components/hooks/query/useGQLQuery";
import { useGQLQuery } from "../../../../../../components/hooks/query/useGQLQuery";
import {
  OBJECT_FRAGMENT,
  OBJECT_PRESET_FILTER_FRAGMENT,
  OBJECT_QUERY_BUILDER_SECTION_FRAGMENT,
  OBJECT_VIEW_FRAGMENT,
} from "../../../../../../fragments/object";
import type { IObject, IObjectView } from "../../../../../../interfaces/object";

export type GetObjectWithViewsQueryData = {
  allObjects: IObject[];
  allObjectViews: IObjectView[];
};

type QueryVariables = {
  objectSlug: string;
  userId: string;
  orgId: string;
};

export const ObjectWithViewsQuery = gql`
  ${OBJECT_FRAGMENT}
  ${OBJECT_VIEW_FRAGMENT}
  ${OBJECT_PRESET_FILTER_FRAGMENT}
  ${OBJECT_QUERY_BUILDER_SECTION_FRAGMENT}

  query getObjectWithViews($objectSlug: String!, $userId: ID!, $orgId: ID!) {
    allObjects(where: { slug: $objectSlug, org: { id: $orgId } }, first: 1) {
      ...ObjectFragment
      presetFilters(
        where: { isDeleted: false }
        sortBy: [order_ASC, name_ASC]
      ) {
        ...ObjectPresetFilterFragment
      }
      queryBuilderSections(where: { isDeleted: false }, sortBy: [order_ASC]) {
        ...ObjectQueryBuilderSectionFragment
      }
    }
    allObjectViews(
      where: {
        AND: [
          {
            isDeleted: false
            object: { slug: $objectSlug }
            org: { id: $orgId }
          }
          {
            OR: [
              { AND: [{ isPersonal: true, createdBy: { id: $userId } }] }
              { AND: [{ isPersonal: false }] }
            ]
          }
        ]
      }
      sortBy: name_ASC
    ) {
      ...ObjectViewFragment
      createdBy {
        id
      }
    }
  }
`;

export type QueryBuilderRemotePropertyQueryType = {
  allReferencedObjects: IObject[];
};

export const QueryBuilderRemotePropertyQuery = gql`
  ${OBJECT_FRAGMENT}

  query getRemoteObjects($tableIds: [ID!]!) {
    allReferencedObjects: allObjects(where: { table: { id_in: $tableIds } }) {
      ...ObjectFragment
    }
  }
`;

export const useGetObjectWithViews = (
  options: QueryOptions<GetObjectWithViewsQueryData, QueryVariables>
) => {
  return useGQLQuery<GetObjectWithViewsQueryData, QueryVariables>(
    ObjectWithViewsQuery,
    options
  );
};
