import type {
  BinaryFilter,
  Filter,
  Query,
  UnaryFilter,
} from "@cubejs-client/core";
import type { SelectProps } from "antd";
import { Empty, Select, Spin } from "antd";
import _ from "lodash";
import { useEffect, useMemo, useRef } from "react";
import { compose } from "../../../../../../components/compose/WlyCompose";
import { useLagoonQuery } from "../../../../../../components/hooks/query/useLagoonQuery";
import type { IFilterType } from "../../../../../../interfaces/reports";
import type { LagoonObjectType } from "../../../../../../services/LagoonService";
import type { InjectedOrgProps } from "../../../../../orgs/WithOrg";
import WithOrg from "../../../../../orgs/WithOrg";
import {
  convertFilterTypeToString,
  convertStringToFilterType,
} from "../../../domain";

interface IFieldAutocompleteProps {
  defaultValue?: string[];
  value?: string[];
  onChange?: (s: string[] | undefined) => void;
  labelDimension?: string;
  valueDimension: string;
  style?: React.CSSProperties;
  filterType: IFilterType;
  requireValue: boolean;
  multi: boolean;
  objectType: LagoonObjectType;
  objectId: string;
  allowClear?: boolean;
  additionalQueryFilters?: Array<Filter>;
  limit?: number;
  renderDropdownInline?: boolean;
  injectedMetrics?: string[];
}

type Props = InjectedOrgProps & IFieldAutocompleteProps;

const FieldAutocomplete = (props: Props) => {
  const {
    additionalQueryFilters,
    allowClear,
    defaultValue,
    filterType,
    objectId,
    renderDropdownInline,
    onChange,
    limit,
    multi,
    style,
    value,
    org,
    labelDimension,
    valueDimension,
    requireValue,
    objectType,
    injectedMetrics,
  } = props;
  const ref = useRef<HTMLDivElement>(null);
  const useAutocomplete = useRef<boolean | undefined>(undefined);
  const getAutocompleteQuery = (value?: string) => {
    const dimensions = [valueDimension];
    let orderBy = valueDimension;
    if (labelDimension) {
      orderBy = labelDimension;
      dimensions.push(labelDimension);
    }

    const valueFilter: Filter = value
      ? {
          member: valueDimension,
          operator: filterType === "NUMERIC" ? "equals" : "contains",
          values: [value],
        }
      : {
          member: valueDimension,
          operator: "set",
        };

    let queryFilters: Array<Filter> = [
      valueFilter,
      ...(additionalQueryFilters ?? []),
    ];

    return {
      measures: injectedMetrics ? _.uniq(injectedMetrics) : undefined,
      dimensions: _.uniq(dimensions),
      filters: [
        {
          and: queryFilters,
        },
      ],
      order: [[orderBy, "asc"]],
      limit: typeof limit === "number" ? limit : 5_000,
      total: true,
    } as Query;
  };

  const { data, resultSet, loading, refetch } = useLagoonQuery(
    getAutocompleteQuery(),
    {
      objectType: objectType,
      objectId: objectId,
      org: org,
      cache: true,
    }
  );

  useEffect(() => {
    useAutocomplete.current = undefined;
    refetch(getAutocompleteQuery());
  }, [valueDimension, labelDimension, limit, additionalQueryFilters]);

  useEffect(() => {
    if (resultSet && useAutocomplete.current === undefined) {
      useAutocomplete.current = (resultSet.totalRows() || 0) > (limit || 5000);
    }
  }, [resultSet]);

  const dropdownStyle: React.CSSProperties = renderDropdownInline
    ? {
        position: "initial",
        borderRadius: 6,
        border: "1px solid #ececec",
        boxShadow: "none",
      }
    : {};

  const dropdownContainer: React.ReactElement = renderDropdownInline ? (
    <div ref={ref} style={{ padding: 2, marginTop: 4 }} />
  ) : (
    <></>
  );

  const getPopupContainer = () => {
    if (renderDropdownInline && ref?.current) {
      return ref.current;
    } else {
      return document.body;
    }
  };

  const buildOptions = (args?: { excludeExtra: boolean }) => {
    if (loading) return [];
    const suggestions = (data ?? [])
      .map((r) => ({
        label: labelDimension ? r[labelDimension] : undefined,
        value: r[valueDimension],
      }))
      .map((d, i) => {
        let label: string | undefined = undefined;
        if (d.label) {
          label = d.label as string;
        } else if (d.value === true || d.value === false) {
          label = JSON.stringify(d.value);
        } else {
          label = d.value as string;
        }
        return {
          value: convertFilterTypeToString(d.value, filterType),
          label: label,
        };
      });

    const extra = requireValue
      ? []
      : [{ value: "WHALY_NO_FILTER", label: "No Filter" }];

    if (args?.excludeExtra) {
      return suggestions;
    }

    return [...extra, ...suggestions];
  };

  const onSearch = (value: string) => {
    if (useAutocomplete.current) {
      refetch(getAutocompleteQuery(value));
    }
  };

  const debouncedOnSearch = useMemo(() => _.debounce(onSearch, 300), []);

  let selectProps: SelectProps = {
    // common props
    showSearch: true,
    style: style ?? { width: 200 },
    notFoundContent: loading ? (
      <Spin size="small" />
    ) : (
      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
    ),
    getPopupContainer: getPopupContainer,
    dropdownStyle: dropdownStyle,
    filterOption: !useAutocomplete.current,
    onSearch: debouncedOnSearch,
    loading: loading,
    placeholder: loading ? "Loading..." : "No filter",
    allowClear: allowClear,
    // specific props
    options: buildOptions({ excludeExtra: multi }),
    optionFilterProp: "label",
    mode: multi ? "multiple" : undefined,
    maxTagCount: multi ? 5 : undefined,
  };

  if (multi) {
    const convertedValue = Array.isArray(value)
      ? value.map((v) => convertStringToFilterType(v, filterType))
      : [];

    const convertedDefaultValue = defaultValue
      ? [convertStringToFilterType(defaultValue, filterType)]
      : [];

    return (
      <>
        <Select
          {...selectProps}
          value={convertedValue}
          onChange={(k) => {
            if (onChange) {
              onChange(
                (k as string[]).map((c) =>
                  convertFilterTypeToString(c, filterType)
                )
              );
            }
          }}
          defaultValue={convertedDefaultValue}
        />
        {dropdownContainer}
      </>
    );
  }

  const convertedValue =
    Array.isArray(value) && value.length > 0
      ? convertStringToFilterType(value[0], filterType)
      : undefined;

  return (
    <>
      <Select
        {...selectProps}
        value={convertedValue}
        onChange={(k) => {
          if (!onChange) return;
          if (!k) return onChange(undefined);
          onChange([convertFilterTypeToString(k, filterType)]);
        }}
        defaultValue={convertStringToFilterType(defaultValue, filterType)}
      />
      {dropdownContainer}
    </>
  );
};

export default compose<Props, IFieldAutocompleteProps>(WithOrg)(
  FieldAutocomplete
);
