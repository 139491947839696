import type { SelectProps } from "antd";
import { Select } from "antd";
import type { SchemaResult } from "../../../../../../interfaces/transformations";
import TypeRenderer from "../../../../../spreadsheet/renderer/TypeRenderer";

interface ISchemaSelectProps extends SelectProps {
  schema: SchemaResult;
  alreadyUsed: string[];
}

export function SchemaSelect(props: ISchemaSelectProps) {
  const { schema, alreadyUsed, ...rest } = props;

  return (
    <Select
      showSearch={true}
      optionFilterProp="children"
      popupMatchSelectWidth={false}
      {...rest}
    >
      {Object.keys(schema).map((k) => {
        const data = schema[k];
        return (
          <Select.Option disabled={alreadyUsed.includes(k)} key={k} value={k}>
            <TypeRenderer
              domain={data.domain}
              formula={data.operation === "Table.AddColumn"}
            />{" "}
            {k}
          </Select.Option>
        );
      })}
    </Select>
  );
}
