import React, { useState } from "react";
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import "./WorkbenchCollapse.scss";

type WorkbenchCollapseItem = {
  key: number;
  hidden?: boolean;
  label: React.ReactNode;
  children: React.ReactNode;
  extra?: React.ReactNode;
};

interface IWorkbenchCollapseProps {
  items: WorkbenchCollapseItem[];
}

const MIN_SIZE = 40;

export function WorkbenchCollapse(props: IWorkbenchCollapseProps) {
  const { items } = props;
  const [isDragging, setIsDragging] = useState<number>(null);
  return (
    <PanelGroup direction="vertical" className="workbench-collapse">
      {items
        .filter((item) => !item.hidden)
        .map((item, i) => {
          const showResizable = i !== 0;
          const panelClassName = ["workbench-collapse-panel"];

          if (showResizable) {
            panelClassName.push("resizable");
          }

          return (
            <Panel
              key={item.key}
              className={panelClassName.join(" ")}
              minSizePixels={MIN_SIZE}
              id={item.key.toString()}
              order={i}
            >
              <div
                className="workbench-collapse-panel-header"
                style={{
                  height: MIN_SIZE,
                  minHeight: MIN_SIZE,
                }}
              >
                {showResizable && (
                  <div style={{ height: 3 }}>
                    <PanelResizeHandle
                      className={`workbench-collapse-separator ${
                        isDragging === item.key ? "dragging" : ""
                      }`}
                      onDragging={(isDragging) =>
                        setIsDragging(isDragging ? item.key : null)
                      }
                    />
                  </div>
                )}
                <div
                  style={{
                    flex: 1,
                  }}
                >
                  {item.label}
                </div>
                {item.extra && <div style={{ flex: 0 }}>{item.extra}</div>}
              </div>
              <div
                className="workbench-collapse-panel-body"
                style={{
                  height: `calc(100% - ${MIN_SIZE}px)`,
                  overflowY: "auto",
                  overflowX: "hidden",
                }}
              >
                {item.children}
              </div>
            </Panel>
          );
        })}
    </PanelGroup>
  );
}
