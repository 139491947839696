import type { PopoverProps } from "antd";
import {
  Button,
  Form,
  Input,
  InputNumber,
  Segmented,
  Select,
  Space,
} from "antd";
import { useEffect } from "react";
import ChartOptionLine from "../../containers/chart-options/components/ChartOptionLine";
import type { InjectedOrgProps } from "../../containers/orgs/WithOrg";
import WithOrg from "../../containers/orgs/WithOrg";
import { compose } from "../compose/WlyCompose";
import ColorRenderer from "../form/color-picker/ColorRenderer";
import SketchPickerFormItem from "../form/sketch-picker-form-item/SketchPickerFormItem";
import usePrevious from "../hooks/usePrevious";
import PaletteRenderer from "../palette/PaletteRenderer";
import PaletteSelector from "../palette/selector/PaletteSelector";
import { getSelectedPalette } from "../palette/utils/paletteData";
import type {
  IConditionalFormatterNumericOperators,
  IConditionalFormatterRule,
} from "./domain";
import { ConditionalFormatterNumericOperators } from "./domain";
import type { IWlyConditionalFormatterFormOptions } from "./WlyConditionalFormatterForm";

interface IWlyConditionalFormatterRuleFormProps {
  options: IWlyConditionalFormatterFormOptions;
  initialValues: IConditionalFormatterRule;
  open: boolean;
  onCancel: () => void;
}

type Props = IWlyConditionalFormatterRuleFormProps & InjectedOrgProps;

const WlyConditionalFormatterRuleForm: React.FC<Props> = (props: Props) => {
  const { options, open, initialValues, org, onCancel } = props;
  const prevOpen = usePrevious(open);
  const [form] = Form.useForm<IConditionalFormatterRule>();

  useEffect(() => {
    if (open && !prevOpen) {
      form.resetFields();
      form.setFieldsValue(initialValues);
    }
  }, [form, open, prevOpen, initialValues]);

  const onOk = () => {
    form.submit();
  };

  return (
    <Form
      layout="vertical"
      form={form}
      initialValues={initialValues}
      name="ruleForm"
      onValuesChange={(changedValues) => {
        if (
          ["isNull", "isNotNull"].includes(changedValues?.condition?.operator)
        ) {
          form.setFieldValue(["condition", "value"], undefined);
        }
        // in scale we set a default palette and operator to is in range
        if (changedValues?.type === "scale") {
          if (!form.getFieldValue(["format", "palette"])) {
            form.setFieldValue(["format", "palette"], {
              type: "PALETTE_SELECTION",
              collection_name: "default",
              palette_type: "continue",
              palette_subtype: "diverging",
              index: 0,
            });
            form.setFieldValue(["condition", "operator"], "isInRange");
          }
        }
      }}
    >
      <Form.Item name="id" hidden>
        <Input disabled />
      </Form.Item>
      <Form.Item name="ruleType" hidden>
        <Input disabled />
      </Form.Item>
      <Space direction="vertical" style={{ width: "100%" }}>
        <Form.Item name="type">
          <Segmented
            block
            options={[
              {
                value: "single",
                label: "Single color",
              },
              {
                value: "scale",
                label: "Color scale",
              },
            ]}
          />
        </Form.Item>
        <Form.Item shouldUpdate noStyle>
          {() => {
            const type = form.getFieldValue("type");
            const fontColorPopover: PopoverProps = {
              overlayInnerStyle: { padding: 0 },
              content: (
                <Form.Item
                  name={["format", "fontColor"]}
                  noStyle={true}
                  valuePropName="color"
                >
                  <SketchPickerFormItem
                    allowClear
                    styles={{
                      default: {
                        picker: {
                          width: "initial",
                        },
                      },
                    }}
                  />
                </Form.Item>
              ),
            };
            const backgroundColorPopover: PopoverProps = {
              overlayInnerStyle: { padding: 0 },
              content: (
                <Form.Item
                  name={["format", "backgroundColor"]}
                  noStyle={true}
                  valuePropName="color"
                >
                  <SketchPickerFormItem
                    allowClear
                    styles={{
                      default: {
                        picker: {
                          width: "initial",
                        },
                      },
                    }}
                  />
                </Form.Item>
              ),
            };
            const palettePopover: PopoverProps = {
              overlayInnerStyle: {
                padding: 0,
              },
              overlayStyle: {
                width: 220,
              },
              content: (
                <Form.Item
                  name={["format", "palette"]}
                  noStyle={true}
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <PaletteSelector restrict="continue" />
                </Form.Item>
              ),
            };
            const palette = form.getFieldValue(["format", "palette"]) ?? {
              type: "PALETTE_SELECTION",
              collection_name: "default",
              palette_type: "continue",
              palette_subtype: "diverging",
              index: 0,
            };
            const currentPalette = getSelectedPalette(org, palette);
            if (type === "single") {
              return (
                <Space direction="vertical" style={{ width: "100%" }}>
                  <b>Format rules</b>
                  <Form.Item>
                    <Form.Item
                      name={["condition", "operator"]}
                      label={"Format if"}
                    >
                      <Select<IConditionalFormatterNumericOperators>
                        style={{ width: "100%" }}
                        options={ConditionalFormatterNumericOperators.map(
                          (operator) => {
                            return {
                              label: operator.label,
                              value: operator.type,
                            };
                          }
                        )}
                      />
                    </Form.Item>
                    <Form.Item shouldUpdate noStyle>
                      {() => {
                        const isValueInputRange = [
                          "isInRange",
                          "isNotInRange",
                        ].includes(
                          form.getFieldValue(["condition", "operator"])
                        );
                        const isValueInputDisabled = [
                          "isNull",
                          "isNotNull",
                        ].includes(
                          form.getFieldValue(["condition", "operator"])
                        );
                        if (!isValueInputRange) {
                          return (
                            <Form.Item
                              name={["condition", "value"]}
                              noStyle
                              rules={[
                                {
                                  required: !isValueInputDisabled,
                                  message: "Value is required",
                                },
                              ]}
                            >
                              <InputNumber
                                style={{ width: "100%" }}
                                placeholder="value"
                                disabled={isValueInputDisabled}
                              />
                            </Form.Item>
                          );
                        } else {
                          return (
                            <>
                              <Form.Item
                                name={["condition", "value", "min"]}
                                rules={[
                                  {
                                    required: true,
                                    message: "Min value is required",
                                  },
                                ]}
                              >
                                <InputNumber
                                  addonBefore={"Min"}
                                  style={{ width: "100%" }}
                                  placeholder="Min value"
                                />
                              </Form.Item>
                              <Form.Item
                                name={["condition", "value", "max"]}
                                rules={[
                                  {
                                    required: true,
                                    message: "Max value is required",
                                  },
                                ]}
                              >
                                <InputNumber
                                  addonBefore={"Max"}
                                  style={{ width: "100%" }}
                                  placeholder="Max value"
                                />
                              </Form.Item>
                            </>
                          );
                        }
                      }}
                    </Form.Item>
                  </Form.Item>
                  <b>Format</b>
                  {options.fontColor && (
                    <ChartOptionLine
                      items={[
                        {
                          flex: 1,
                          content: "Font color",
                          popover: fontColorPopover,
                        },
                        {
                          flex: 0,
                          content: (
                            <ColorRenderer
                              color={form.getFieldValue([
                                "format",
                                "fontColor",
                              ])}
                            />
                          ),
                          popover: fontColorPopover,
                        },
                      ]}
                    />
                  )}
                  {options.backgroundColor && (
                    <ChartOptionLine
                      items={[
                        {
                          flex: 1,
                          content: "Background color",
                          popover: backgroundColorPopover,
                        },
                        {
                          flex: 0,
                          content: (
                            <ColorRenderer
                              color={form.getFieldValue([
                                "format",
                                "backgroundColor",
                              ])}
                            />
                          ),
                          popover: backgroundColorPopover,
                        },
                      ]}
                    />
                  )}
                  {options.overrideValue && (
                    <ChartOptionLine
                      items={[
                        {
                          flex: 1,
                          content: "Display value",
                        },
                        {
                          flex: 1,
                          content: (
                            <>
                              <Form.Item
                                name={["format", "overrideValue", "type"]}
                                noStyle
                                hidden
                              >
                                <Input />
                              </Form.Item>
                              <Form.Item
                                name={["format", "overrideValue", "value"]}
                                noStyle
                                getValueFromEvent={(args) => {
                                  if (
                                    args.target.value &&
                                    typeof args.target.value === "string" &&
                                    args.target.value.length > 0
                                  ) {
                                    return args.target.value;
                                  } else {
                                    return null;
                                  }
                                }}
                              >
                                <Input allowClear />
                              </Form.Item>
                            </>
                          ),
                        },
                      ]}
                    />
                  )}
                </Space>
              );
            } else if (type === "scale") {
              return (
                <Space direction="vertical" style={{ width: "100%" }}>
                  <b>Format rules</b>
                  <Form.Item name={["condition", "operator"]} noStyle>
                    <Input hidden />
                  </Form.Item>
                  <Form.Item
                    name={["condition", "value", "min"]}
                    rules={[
                      { required: true, message: "Range min is required" },
                      {
                        validator: (_, v) => {
                          if (
                            v >=
                            form.getFieldValue(["condition", "value", "max"])
                          ) {
                            return Promise.reject(
                              `Range min must be < range max `
                            );
                          }
                          return Promise.resolve();
                        },
                      },
                    ]}
                  >
                    <InputNumber
                      addonBefore="Min"
                      placeholder="min value"
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                  <Form.Item
                    name={["condition", "value", "max"]}
                    rules={[
                      { required: true, message: "Range max is required" },
                      {
                        validator: (_, v) => {
                          if (
                            v <=
                            form.getFieldValue(["condition", "value", "min"])
                          ) {
                            return Promise.reject(
                              `Range max must be > range min `
                            );
                          }
                          return Promise.resolve();
                        },
                      },
                    ]}
                  >
                    <InputNumber
                      addonBefore="Max"
                      placeholder="max value"
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                  <b>Format</b>
                  <ChartOptionLine
                    items={[
                      {
                        flex: 1,
                        content: "Palette",
                        popover: palettePopover,
                      },
                      {
                        flex: 0,
                        content: <PaletteRenderer {...currentPalette} />,
                        popover: palettePopover,
                      },
                    ]}
                  />
                </Space>
              );
            }
          }}
        </Form.Item>
      </Space>
      <div style={{ paddingTop: 24, textAlign: "right" }}>
        <Space align="end">
          <Button onClick={() => onCancel()}>Cancel</Button>
          <Button type="primary" onClick={() => onOk()}>
            Done
          </Button>
        </Space>
      </div>
    </Form>
  );
};

export default compose<Props, IWlyConditionalFormatterRuleFormProps>(WithOrg)(
  WlyConditionalFormatterRuleForm
);
