import { Button, Space, Typography } from "antd";
import { inject, observer } from "mobx-react";
import moment from "moment";
import * as React from "react";
import type { InjectedAntUtilsProps } from "../../../../../components/ant-utils/withAntUtils";
import { withAntUtils } from "../../../../../components/ant-utils/withAntUtils";
import { compose } from "../../../../../components/compose/WlyCompose";
import { SplitView } from "../../../../../components/resizable/SplitView";
import type { IJobExecution } from "../../../../../interfaces/jobExecutions";
import { getBlobFile } from "../../../../../services/FileService";
import type { WorkbenchUIStoreProps } from "../../../../../store/workbenchUIStore";
import ExecutionLogsRenderer from "../../../../job-execution/ExecutionLogsRenderer";
import { ExecutionStatusTag } from "../../../../job-execution/status/ExecutionStatusTag";
import * as Toolbar from "../../../../spreadsheet/toolbar/Toolbar";
import type { IActiveObject } from "../../domain";
import NoAccess from "../empty/NoAccess";

import "./JobViewer.scss";

const { Text } = Typography;
interface IJobViewerProps {
  executionStore: {
    [key: string]: IJobExecution;
  };
  activeObject: IActiveObject;
}

type Props = IJobViewerProps & WorkbenchUIStoreProps & InjectedAntUtilsProps;

interface IState {
  downloading: boolean;
}

class JobViewer extends React.Component<Props, IState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      downloading: false,
    };
  }

  public render() {
    const { executionStore, activeObject, antUtils } = this.props;

    const currentExecution = executionStore[activeObject.value];

    if (!activeObject || !currentExecution) {
      return <NoAccess />;
    }

    const logs = (
      <>
        <Toolbar.Toolbar style={{ borderTop: "none" }}>
          <Toolbar.ViewName>Logs</Toolbar.ViewName>
        </Toolbar.Toolbar>
        <div className="job-viewer-logs-panel">
          <ExecutionLogsRenderer
            viewType="terminal"
            selectedJobExecution={currentExecution}
          />
        </div>
      </>
    );

    const meta = (
      <>
        <Toolbar.Toolbar style={{ borderTop: "none" }}>
          <Toolbar.ViewName>Metas</Toolbar.ViewName>
        </Toolbar.Toolbar>
        <Space direction="vertical" size={24} className="job-viewer-inner">
          <div>
            <Text strong>Status</Text>
            <div>
              <ExecutionStatusTag
                execution={currentExecution}
                hideError={true}
              />
            </div>
          </div>
          {currentExecution.status === "ERROR" ? (
            <div>
              <Text strong>Error</Text>
              <div>{currentExecution.errorText}</div>
            </div>
          ) : null}
          <div>
            <Text strong>Started</Text>
            <div>
              {moment(currentExecution.createdAt).format(
                "[on] YYYY/MM/HH [at] HH:mm:ss"
              )}
            </div>
          </div>
          {currentExecution.status !== "RUNNING" ? (
            <div>
              <Text strong>Duration of</Text>
              <div>
                {moment
                  .duration(
                    moment(currentExecution.updatedAt).diff(
                      currentExecution.createdAt
                    )
                  )
                  .humanize()}
              </div>
            </div>
          ) : null}
          {currentExecution.artifactsUri ? (
            <div>
              <Text strong>Download project</Text>
              <div>
                <Button
                  size="small"
                  loading={this.state.downloading}
                  onClick={async () => {
                    this.setState({
                      downloading: true,
                    });
                    try {
                      const a = document.createElement("a");
                      document.body.appendChild(a);
                      a.style.display = "none";
                      const blob = await getBlobFile(
                        currentExecution.artifactsUri
                      );
                      const url = window.URL.createObjectURL(blob);
                      a.href = url;
                      a.download = "dbt_project.zip";
                      a.click();
                      window.URL.revokeObjectURL(url);
                    } catch (err) {
                      console.error(err);
                      this.setState({ downloading: false });
                      antUtils.message.error(
                        "There was an error downloading the project"
                      );
                    } finally {
                      this.setState({ downloading: false });
                    }
                  }}
                >
                  Download
                </Button>
              </div>
            </div>
          ) : null}
        </Space>
      </>
    );

    return (
      <div className="workbench-content">
        <div className="workbench-spreadsheet">
          <div className="workbench-content-inner">
            <SplitView
              left={meta}
              right={logs}
              leftClassName="job-viewer"
              minWidth={250}
              startWidth={300}
              maxWidth={"50%"}
              mainClassName={"workbench-content"}
              rightClassName="job-viewer"
              alignement="LTR"
              compact={true}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default compose<Props, IJobViewerProps>(
  inject("workbenchUIStore"),
  observer,
  withAntUtils
)(JobViewer);
