import {
  DeleteOutlined,
  EditOutlined,
  HolderOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import { Button, Card, Flex, Popconfirm } from "antd";
import { useState } from "react";
import type { DraggableProvidedDragHandleProps } from "react-beautiful-dnd";
import { useHover } from "../../../../../../../components/hooks/useHover";
import type { DeleteObjectPresetFiltersParams } from "./domain";

type Props = {
  presetFilter: any;
  dragHandleProps: DraggableProvidedDragHandleProps | undefined;
  onDelete: (params: DeleteObjectPresetFiltersParams) => void;
  onEdit: () => void;
};

function PresetFilterCard({
  dragHandleProps,
  presetFilter,
  onDelete,
  onEdit,
}: Props) {
  const [hoverRef, isHover] = useHover<HTMLDivElement>();
  const [confirmOpen, setConfirmOpen] = useState<boolean>(false);

  return (
    <div ref={hoverRef}>
      <Card key={presetFilter.id} size="small">
        <Flex align="center" gap={8}>
          <div {...dragHandleProps}>
            <HolderOutlined style={{ cursor: "move" }} />
          </div>
          <div style={{ flex: 1, fontWeight: 600 }}>{presetFilter.name}</div>
          <div style={{ height: 32 }}>
            <div hidden={!isHover && !confirmOpen}>
              <Button
                shape="circle"
                type="text"
                icon={<EditOutlined />}
                onClick={() => onEdit()}
              />
              <Popconfirm
                title="Delete the preset filter"
                description="Are you sure to delete this preset filter?"
                icon={<QuestionCircleOutlined style={{ color: "red" }} />}
                open={confirmOpen}
                onOpenChange={(open) => setConfirmOpen(open)}
                okText="Delete"
                okButtonProps={{ danger: true }}
                onConfirm={() => onDelete({ id: presetFilter.id })}
              >
                <Button
                  shape="circle"
                  type="text"
                  icon={<DeleteOutlined />}
                  onClick={() => setConfirmOpen(true)}
                  danger
                />
              </Popconfirm>
            </div>
          </div>
        </Flex>
      </Card>
    </div>
  );
}

export default PresetFilterCard;
