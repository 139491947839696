import {
  DoubleLeftOutlined,
  DoubleRightOutlined,
  MoreOutlined,
  PlayCircleOutlined,
  PlusCircleOutlined,
  SaveOutlined,
} from "@ant-design/icons";
import { Badge, Button, Dropdown, Tooltip, Typography } from "antd";
import React from "react";
import "../../../../../../../node_modules/react-resizable/css/styles.css";
import type { InjectedAntUtilsProps } from "../../../../../../components/ant-utils/withAntUtils";
import { withAntUtils } from "../../../../../../components/ant-utils/withAntUtils";
import { compose } from "../../../../../../components/compose/WlyCompose";
import type { IDestination } from "../../../../../../interfaces/destinations";
import type { IDataset } from "../../../../../../interfaces/sources";
import type { SchemaResult } from "../../../../../../interfaces/transformations";
import { isMacintosh } from "../../../../../../utils/isMacinthosh";
import * as Toolbar from "../../../../../spreadsheet/toolbar/Toolbar";
import { ModelMigrationModal } from "../../../migration/MigrationModal";
import CreateModelModal from "../../../viewer/worksheet/Modals/CreateModelModal";
import PreSaveModelModal from "../common/PreSaveModelModal";
import PrimaryKeysModal from "../common/PrimaryKeysModal";
import "./SQLConfiguration.scss";

interface ISQLToolbarProps {
  datasets: IDataset[];
  currentDataset?: IDataset;
  highlightRunQuery: boolean;
  isMigrationModalOpen: boolean;
  onMigrationDone: (
    toDatasetId: string,
    datasetIds: string[],
    explorationIds: string[]
  ) => Promise<void>;
  setMigrationModalOpen: (open?: boolean) => void;
  onFormatQuery: () => void;
  onRunQuery: () => void;
  schema?: SchemaResult;
  updateModel?: (primaryKeys: string[]) => Promise<void>;
  onCreateDataset?: (
    name: string,
    primaryKeys: string[],
    shouldDelete?: boolean
  ) => Promise<void>;
  canUpdateModel?: boolean;
  onDelete?: () => Promise<any>;
  onToggleDataExplorer: () => void;
  isDataExplorerOpen: boolean;
  currentWarehouse: IDestination;
}

type Props = ISQLToolbarProps & InjectedAntUtilsProps;

function SQLToolbar(props: Props) {
  const {
    datasets,
    currentDataset,
    isMigrationModalOpen,
    highlightRunQuery,
    setMigrationModalOpen,
    onMigrationDone,
    onFormatQuery,
    onRunQuery,
    updateModel,
    canUpdateModel,
    schema,
    antUtils,
    onCreateDataset,
    onDelete,
    onToggleDataExplorer,
    isDataExplorerOpen,
    currentWarehouse,
  } = props;

  const schemaDef = schema ? schema : {};

  const [preSaveModalVisible, setPreSaveModalVisible] =
    React.useState<boolean>(false);
  const [primaryKeysModalVisible, setPrimaryKeysModalVisible] =
    React.useState<boolean>(false);
  const [primaryKeysModalSaving, setPrimaryKeysModalSaving] =
    React.useState<boolean>(false);
  const [createModelModalVisible, setCreateModelModalVisible] =
    React.useState<boolean>(false);

  return (
    <div style={{ height: 40 }}>
      <Toolbar.Toolbar align="right" style={{ borderTop: "none" }}>
        <Toolbar.ViewName>
          <Button
            shape="circle"
            size="small"
            icon={
              isDataExplorerOpen ? (
                <DoubleLeftOutlined />
              ) : (
                <DoubleRightOutlined />
              )
            }
            onClick={onToggleDataExplorer}
          />
        </Toolbar.ViewName>
        <Toolbar.Separator />
        <Toolbar.ViewName>SQL Query</Toolbar.ViewName>
        <Toolbar.Separator />
        <div style={{ width: 12, height: "100%" }} />
        {currentDataset && (
          <ModelMigrationModal
            visible={isMigrationModalOpen}
            onClose={() => setMigrationModalOpen(false)}
            datasets={datasets}
            dataset={currentDataset}
            onMigrationDone={onMigrationDone}
            currentWarehouse={currentWarehouse}
          />
        )}
        <div style={{ flex: 1 }} />
        {currentDataset?.managedBy !== "DBT_CLOUD" ? (
          <div>
            <Tooltip
              title={`Run query ${
                isMacintosh() ? "(⌘ + enter)" : "(Ctrl + enter)"
              }`}
            >
              <div style={{ display: "inline-block" }}>
                <Toolbar.Item onClick={onRunQuery} color="green">
                  <Badge offset={[5, -5]} dot={highlightRunQuery}>
                    <PlayCircleOutlined /> Run query
                  </Badge>
                </Toolbar.Item>
              </div>
            </Tooltip>
            {updateModel && currentDataset && (
              <Toolbar.Item
                onClick={() => setPreSaveModalVisible(true)}
                disabled={!canUpdateModel}
                color="violet"
              >
                <SaveOutlined /> Save
              </Toolbar.Item>
            )}
            {onCreateDataset && (
              <Toolbar.Item
                onClick={() => setCreateModelModalVisible(true)}
                disabled={!canUpdateModel}
                color="violet"
              >
                <PlusCircleOutlined /> Save as model
              </Toolbar.Item>
            )}
            {onCreateDataset && (
              <CreateModelModal
                visible={createModelModalVisible}
                onClose={() => setCreateModelModalVisible(false)}
                getSchema={async () => schemaDef}
                onCreate={onCreateDataset}
              />
            )}
            {currentDataset && (
              <PreSaveModelModal
                datasetId={currentDataset.id}
                currentWarehouse={currentWarehouse}
                visible={preSaveModalVisible}
                getNewSchema={async () => {
                  return schemaDef;
                }}
                onCancel={() => setPreSaveModalVisible(false)}
                onContinue={() => {
                  setPreSaveModalVisible(false);
                  setPrimaryKeysModalVisible(true);
                }}
              />
            )}
            {currentDataset && (
              <PrimaryKeysModal
                getInitialKeys={() => currentDataset.primaryKey.split(",")}
                getSchema={async () => {
                  return schemaDef;
                }}
                visible={primaryKeysModalVisible}
                saving={primaryKeysModalSaving}
                onCancel={() => setPrimaryKeysModalVisible(false)}
                onSave={async (values) => {
                  setPrimaryKeysModalSaving(true);
                  try {
                    await updateModel?.(values.keys);
                  } catch (error) {
                    antUtils.message.error("Error saving your query");
                  } finally {
                    setPrimaryKeysModalSaving(false);
                    setPrimaryKeysModalVisible(false);
                  }
                }}
              />
            )}
            <Dropdown
              trigger={["click"]}
              menu={{
                items: [
                  {
                    key: "format",
                    label: (
                      <Tooltip
                        title={
                          isMacintosh()
                            ? "(⌘ + shift + F)"
                            : "(Ctrl + shift + F)"
                        }
                        placement="left"
                      >
                        <Typography.Text>Format query</Typography.Text>{" "}
                      </Tooltip>
                    ),
                    onClick: onFormatQuery,
                  },
                  ...(currentDataset
                    ? [
                        {
                          key: "migrate",
                          label: "Migrate",
                          onClick: () => setMigrationModalOpen(true),
                        },
                      ]
                    : []),
                  ...(onDelete
                    ? [
                        {
                          key: "delete",
                          label: "Delete",
                          danger: true,
                          onClick: () =>
                            antUtils.modal.confirm({
                              title: "Do you want to proceed?",
                              content:
                                "This action cannot be undone, are you sure you want to proceed ?",
                              onOk: onDelete,
                              onCancel: () => undefined,
                            }),
                        },
                      ]
                    : []),
                ],
              }}
            >
              <Toolbar.Item color="pink">
                <MoreOutlined />
              </Toolbar.Item>
            </Dropdown>
          </div>
        ) : null}
      </Toolbar.Toolbar>
    </div>
  );
}

export default compose<Props, ISQLToolbarProps>(withAntUtils)(SQLToolbar);
