import Markdoc from "@markdoc/markdoc";
import { getObjectColumns } from "../../../../../object/domain";
import type { IWidgetEmailMjmlRenderer } from "../../domain";
import type { IEmailWidgetTextConfig } from "../domain";
import { getDefaultConfig, getFormattedRecord, getParsedDoc } from "../domain";

export const EmailWidgetTextMjml: IWidgetEmailMjmlRenderer<
  IEmailWidgetTextConfig
> = async (widget, object, record, conf, org, user) => {
  const defaults = getDefaultConfig(conf, record);
  const source = defaults.content as string;

  const padding =
    defaults.spacing === "normal"
      ? `padding-top="10" padding-bottom="10"`
      : `padding-top="0" padding-bottom="0"`;

  if (!source) {
    return `<mj-text ${padding} align="center"><i> Please configure this widget</i></mj-text>`;
  }

  const columns = getObjectColumns(object);
  const formattedRecord = getFormattedRecord(columns, record);

  const content = getParsedDoc(source, formattedRecord, user, columns);
  const html = Markdoc.renderers.html(content);
  return `<mj-text ${padding} align="${
    defaults.align ? defaults.align : "left"
  }">${html
    .replaceAll("<article>", "")
    .replaceAll("</article>", "")}</mj-text>`;
};
