import type { TreeSelectProps } from "antd";
import { Space, TreeSelect, Typography } from "antd";
import * as React from "react";
import { compose } from "../../../../../components/compose/WlyCompose";
import type { IExploration } from "../../../../../interfaces/explorations";
import type { IDimension } from "../../../../../interfaces/table";
import type { DataType } from "../../../../../interfaces/transformations";
import type { InjectedOrgProps } from "../../../../orgs/WithOrg";
import WithOrg from "../../../../orgs/WithOrg";
import TypeRenderer from "../../../../spreadsheet/renderer/TypeRenderer";

const { TreeNode } = TreeSelect;

interface IFilterDimensionSelectorProps extends TreeSelectProps<any> {
  showDoNotFilter: boolean;
  showDefaultTime: boolean;
  explorations: IExploration[];
  groupUsedExplorations?: string[];
  restrict?: DataType[];
}

type Props = IFilterDimensionSelectorProps & InjectedOrgProps;

class FilterDimensionSelector extends React.Component<Props> {
  public render() {
    const {
      org,
      explorations,
      showDoNotFilter,
      showDefaultTime,
      groupUsedExplorations,
      restrict,
      ...rest
    } = this.props;

    const getDimensionTypeRenderer = (dim: IDimension) => {
      if (dim.type === "geo") {
        return <TypeRenderer domain="STRING" geo />;
      }
      if (dim.type === "standard") {
        return <TypeRenderer domain={dim.columnDomain} />;
      }
    };

    const renderExplorationTreeNode = (d: IExploration) => (
      <TreeNode
        key={`exp-${d.id}`}
        selectable={false}
        value={`exp-${d.id}`}
        title={d.name}
        isLeaf={false}
      >
        {d.tables.map((t) => {
          return (
            <TreeNode
              key={`exp-${d.id}-table-${t.id}`}
              selectable={false}
              value={`table-${t.id}`}
              title={t.name}
              isLeaf={false}
            >
              {t.dimensions.flatMap((dim) => {
                const allowedDomain = restrict ?? [];

                if (
                  !allowedDomain.length ||
                  (dim.type === "standard" &&
                    allowedDomain.includes(dim.columnDomain))
                ) {
                  return [
                    <TreeNode
                      key={`${t.cubeName}.${dim.cubeName}`}
                      isLeaf={true}
                      selectable={true}
                      value={`${t.cubeName}.${dim.cubeName}`}
                      title={
                        <>
                          {getDimensionTypeRenderer(dim)} {dim.name}
                        </>
                      }
                    />,
                  ];
                } else {
                  return [];
                }
              })}
            </TreeNode>
          );
        })}
      </TreeNode>
    );

    const primaryExplorations = [...explorations]
      .sort((a, b) => a.name.localeCompare(b.name))
      .filter((exploration) => {
        if (groupUsedExplorations) {
          return groupUsedExplorations.includes(exploration.id);
        } else {
          return true;
        }
      });

    const otherExplorations =
      (groupUsedExplorations ?? []).length > 0
        ? [...explorations]
            .sort((a, b) => a.name.localeCompare(b.name))
            .filter((exploration) => {
              if (groupUsedExplorations) {
                return !groupUsedExplorations.includes(exploration.id);
              } else {
                return false;
              }
            })
        : [];

    return (
      <TreeSelect
        dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
        allowClear={this.props.allowClear}
        {...rest}
      >
        {showDefaultTime && (
          <TreeNode
            value={"DEFAULT_TIME"}
            isLeaf={true}
            selectable={true}
            title={
              <Space>
                <TypeRenderer domain="TIME" /> Default time field
              </Space>
            }
          />
        )}
        {showDoNotFilter && (
          <TreeNode
            value={""}
            isLeaf={true}
            selectable={true}
            title={"Do not filter"}
          />
        )}
        {primaryExplorations.map((d) => {
          return renderExplorationTreeNode(d);
        })}
        {otherExplorations?.length > 0 && (
          <TreeNode
            key={`unused-group`}
            selectable={false}
            value={`unused-group`}
            title={
              <Typography.Text type="secondary">
                Other explorations
              </Typography.Text>
            }
            isLeaf={false}
          >
            {otherExplorations.map((d) => {
              return renderExplorationTreeNode(d);
            })}
          </TreeNode>
        )}
      </TreeSelect>
    );
  }
}

export default compose<Props, IFilterDimensionSelectorProps>(WithOrg)(
  FilterDimensionSelector
);
