import { Form, Input } from "antd";
import type { FormInstance } from "antd/es/form/Form";

interface ICreateEditTopicFormProps {
  form: FormInstance<CreateEditTopicFormData>;
  initialValues?: Partial<CreateEditTopicFormData>;
  onSave: (values: CreateEditTopicFormData) => any;
}

export type CreateEditTopicFormData = {
  topicId?: string;
  topicGroupId?: string;
  name: string;
  apiName: string;
};

export function CreateEditTopicForm(props: ICreateEditTopicFormProps) {
  const { form, initialValues, onSave } = props;
  const isEditing = !!initialValues?.topicId;
  return (
    <Form<CreateEditTopicFormData>
      form={form}
      preserve={false}
      layout="vertical"
      initialValues={initialValues}
      onFinish={(values) => {
        onSave(values);
      }}
    >
      <Form.Item
        label="Topic name"
        name={["name"]}
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="api name"
        help="Must match your radar configuration. Should be unique across your org"
        name={["apiName"]}
        style={{
          paddingBottom: 20,
        }}
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name={["topicGroupId"]}
        hidden
        rules={[
          {
            required: !isEditing,
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name={["topicId"]}
        hidden
        rules={[
          {
            required: isEditing,
          },
        ]}
      >
        <Input />
      </Form.Item>
    </Form>
  );
}
