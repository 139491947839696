import { LoadingOutlined } from "@ant-design/icons";
import type { PivotConfig, ResultSet, TableColumn } from "@cubejs-client/core";
import { Button, Empty, Spin } from "antd";
import type { Remote } from "comlink";
import { proxy, wrap } from "comlink";
import _ from "lodash";
import * as React from "react";
import type { ChartType } from "../../../../../components/chart/domain";
import { ChartDefinition } from "../../../../../components/chart/domain";
import TableChart from "../../../../../components/chart/table/TableChart";
import { compose } from "../../../../../components/compose/WlyCompose";
import { withHeightSizer } from "../../../../../components/height-sizer/withHeightSizer";
import type { AvailableMetric } from "../../../../../components/measures/filter-item/FilterItem";
import { getDefaultCollection } from "../../../../../components/palette/utils/paletteData";
import type { AsyncData } from "../../../../../helpers/typescriptHelpers";
import type { WlyResultSet } from "../../../../../services/LagoonService";
import { downloadCsv } from "../../../../../utils/csvDownload";
import type { CubeJSPivotWorker } from "../../../../../worker/main.worker";
import type { InjectedOrgProps } from "../../../../orgs/WithOrg";
import WithOrg from "../../../../orgs/WithOrg";
import type { IAnalysisType, ILagoonQuery } from "../../domain";
import "./Breakdown.scss";

interface IBreakdownProps {
  data: AsyncData<ResultSet<any>>;
  additionalResults: AsyncData<WlyResultSet<any>>;
  chartType: ChartType;
  query: ILagoonQuery;
  pivotConfig: string[];
  analysisType: IAnalysisType;
  availableMetrics: Array<AvailableMetric>;
}

interface IState {
  hasAlreadyFetch: boolean;
  meta: AsyncData<{
    pivotConfig: PivotConfig;
    columns: TableColumn[];
    tableRows: Array<{
      [key: string]: string | number | boolean;
    }>;
  }>;
}

const loadingIcon = <LoadingOutlined style={{ fontSize: 24 }} spin={true} />;

type Props = IBreakdownProps & InjectedOrgProps;

const BreakdownTable = withHeightSizer(TableChart);

class Breakdown extends React.Component<Props, IState> {
  originalWorker: Worker;
  worker: Remote<CubeJSPivotWorker>;

  constructor(props: Props) {
    super(props);
    this.originalWorker = new Worker(
      new URL("../../../../../worker/main.worker", import.meta.url),
      {
        name: "breakdown-worker",
        type: "module",
      }
    );
    this.worker = wrap<CubeJSPivotWorker>(this.originalWorker);
    this.state = {
      hasAlreadyFetch: false,
      meta: { status: "initial" },
    };
  }

  componentDidUpdate(prevProps: Props) {
    const { data, query } = this.props;
    const { data: prevData, query: prevQuery } = prevProps;

    const { chartOptions, ...currentQuery } = query;
    const { chartOptions: prevChartOptions, ...previousQuery } = query;

    if (
      (data.status === "success" && prevData.status === "loading") ||
      (data.status === "success" && !_.isEqual(currentQuery, previousQuery))
    ) {
      this.renderMeta(data.data);
    }
    if (prevData.status === "success" && data.status === "loading") {
      this.setState({ meta: { status: "initial" } });
    }
    if (
      (data.status === "success" || data.status === "error") &&
      !this.state.hasAlreadyFetch
    ) {
      this.setState({ hasAlreadyFetch: true });
    }
  }

  componentWillUnmount(): void {
    this.originalWorker.terminate();
  }

  renderMeta = async (resultSet: ResultSet) => {
    try {
      const { analysisType } = this.props;

      this.setState({ meta: { status: "loading" } });
      const serializedResultset = resultSet.serialize();
      const pivotC = await this.worker.getPivotConfig(
        proxy(resultSet),
        this.props.analysisType,
        "table",
        this.props.pivotConfig
      );
      const columns = await this.worker.getTableColumns(
        serializedResultset,
        pivotC
      );
      let tableRows = await this.worker.getTableRows(
        serializedResultset,
        pivotC,
        analysisType
      );

      if (
        this.props.query.forecast &&
        this.props.query.forecast.enabled &&
        this.props.query.selectedTime
      ) {
        tableRows = await this.worker.predictTableRows(
          tableRows,
          columns.find((c) => c.key.startsWith(this.props.query.selectedTime))
            ?.key,
          columns
            .filter((c) => !c.key.startsWith(this.props.query.selectedTime))
            .map((c) => c.key)
        );
      }

      this.setState({
        meta: {
          status: "success",
          data: {
            pivotConfig: pivotC,
            columns: columns,
            tableRows: tableRows,
          },
        },
      });
    } catch (err) {
      this.setState({ meta: { status: "error", error: err } });
    }
  };

  public renderContent = () => {
    const {
      data,
      analysisType,
      org,
      user: { locale },
    } = this.props;
    const { meta } = this.state;

    if (
      data.status === "initial" ||
      data.status === "loading" ||
      meta.status === "initial" ||
      meta.status === "loading"
    ) {
      return (
        <div style={{ height: "100%" }} className="empty-state">
          {data.status !== "loading"
            ? "Please run a query to view the breakdown"
            : ""}
        </div>
      );
    }

    if (data.status === "error") {
      return (
        <div style={{ height: "100%" }} className="empty-state">
          {data.error.message}
        </div>
      );
    }

    if (meta.status === "error") {
      return (
        <div style={{ height: "100%" }} className="empty-state">
          {meta.error.message}
        </div>
      );
    }

    if (data.data.tablePivot().length === 0) {
      return (
        <div
          className="empty-state"
          style={{ height: "100%", position: "relative" }}
        >
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translateX(-50%) translateY(-50%) ",
            }}
          >
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          </div>
        </div>
      );
    }

    return (
      <BreakdownTable
        height={200}
        data={data.data}
        pivotConfig={meta.data.pivotConfig}
        columns={meta.data.columns}
        tableRows={meta.data.tableRows}
        analysisType={analysisType}
        availableMetrics={this.props.availableMetrics}
        defaultCollection={getDefaultCollection(org)}
        locale={locale}
      />
    );
  };

  public render() {
    const { data, org } = this.props;

    return (
      <div className="breakdown-wrapper">
        <div className="breakdown-inner">
          <div className="breakdown-header">
            <div className="breakdown-header-title">Breakdown</div>
            <div className="breakdown-header-actions">
              <Button
                onClick={() => {
                  if (
                    typeof ChartDefinition[this.props.chartType].downloadCSV ===
                    "function"
                  ) {
                    downloadCsv(org.id, undefined, {
                      type: "ChartData",
                      chartType: this.props.chartType,
                      options: {
                        query: this.props.query,
                        resultSet: data,
                        additionalResultSet: this.props.additionalResults,
                      },
                    })();
                  } else {
                    downloadCsv(org.id, undefined, {
                      type: "ResultSet",
                      data: data,
                    })();
                  }
                }}
                disabled={data.status !== "success"}
                size="small"
              >
                Export CSV
              </Button>
            </div>
          </div>
          <div className="breakdown-content">
            <Spin
              spinning={data.status === "loading"}
              indicator={loadingIcon}
              style={{ height: "100%", maxHeight: "100%" }}
            >
              {this.renderContent()}
            </Spin>
          </div>
        </div>
      </div>
    );
  }
}

export default compose<Props, IBreakdownProps>(WithOrg)(Breakdown);
