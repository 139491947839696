/* eslint-disable no-useless-escape */
import { Modal, Space, Table } from "antd";
import type { ColumnProps } from "antd/lib/table";
import _ from "lodash";
import React from "react";
import { compose } from "../../../../../../components/compose/WlyCompose";
import Loading from "../../../../../../components/layout/feedback/loading";
import MeasureItem from "../../../../../../components/measures/measure-item/MeasureItem";
import { SourceImageRenderer } from "../../../../../../components/sources/SourceImageRenderer";
import type { AsyncData } from "../../../../../../helpers/typescriptHelpers";
import type { IDestination } from "../../../../../../interfaces/destinations";
import type {
  IDataset,
  IDatasetRelationship,
} from "../../../../../../interfaces/sources";
import type { SchemaResult } from "../../../../../../interfaces/transformations";
import { computeTransformations } from "../../../../../../services/BrizoService";
import GraphQLService from "../../../../../../services/graphql/GraphQLService";
import { generateUniqueId } from "../../../../../../utils/uniqueId";
import type { InjectedOrgProps } from "../../../../../orgs/WithOrg";
import WithOrg from "../../../../../orgs/WithOrg";
import { RelationshipSideRenderer } from "../general/relationship/RelationshipSideRenderer";
import { RelationshipTypeRenderer } from "../general/relationship/RelationshipTypeRenderer";
import { RELATED_MODELS_QUERY } from "./domain";
import type { AsyncStepData, IPreSaveModelStep } from "./PreSaveModelSteps";
import { PreSaveModelSteps } from "./PreSaveModelSteps";

interface IPreSaveModelModalProps {
  datasetId: string;
  visible: boolean;
  getNewSchema: () => Promise<SchemaResult>;
  onCancel: () => void;
  onContinue: () => void;
  currentWarehouse: IDestination;
}

interface IState {
  currentStep: number;
  isLoading: boolean;
  hasWarning: boolean;
  dataset: AsyncData<{
    dataset: IDataset;
    schema?: SchemaResult;
  }>;
  drills: AsyncStepData<string[]>;
  relationships: AsyncStepData<IDatasetRelationship[]>;
  explorations: AsyncStepData<string[]>;
  models: AsyncStepData<IDataset[]>;
}

type Props = IPreSaveModelModalProps & InjectedOrgProps;

class PreSaveModelModal extends React.Component<Props, IState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      currentStep: 0,
      isLoading: true,
      hasWarning: false,
      dataset: {
        status: "initial",
      },
      drills: {
        status: "initial",
      },
      relationships: {
        status: "initial",
      },
      explorations: {
        status: "initial",
      },
      models: {
        status: "initial",
      },
    };
  }

  getDataset = async () => {
    const { datasetId, org } = this.props;
    const getDatasetQuery = `
    query getSyncedTables($id: ID!, $orgId: ID!) {
      allDatasets: allDatasets(
        where: {
          id: $id,
          org: { id: $orgId },
          deleted_not: true
        }
      ) {
        id
        primaryKey
        head
        type
        views(where: { deleted_not: true }) {
          drills
        }
        incomingRelationships(where: { deleted_not: true }) {
          type
          from
          to
          left {
            id
            name
            isModel
            source {
              sourceMeta {
                publicInfo {
                  logo
                }
              }
            }
          }
          right {
            id
            name
            isModel
            source {
              sourceMeta {
                publicInfo {
                  logo
                }
              }
            }
          }
        }
        outgoingRelationships(where: { deleted_not: true }) {
          type
          from
          to
          left {
            id
            name
            isModel
            source {
              sourceMeta {
                publicInfo {
                  logo
                }
              }
            }
          }
          right {
            id
            name
            isModel
            source {
              sourceMeta {
                publicInfo {
                  logo
                }
              }
            }
          }
        }
      }
    }
  `;
    return GraphQLService<{ allDatasets: IDataset[] }>(getDatasetQuery, {
      id: datasetId,
      orgId: org.id,
    });
  };

  getSchema = async () => {
    const { datasetId } = this.props;
    const id = generateUniqueId();
    return computeTransformations(this.props.currentWarehouse.id, {
      schema: [
        {
          var: id,
          domain: "dataset",
          operation: {
            type: "Table.FromWhalyDataset",
            args: {
              datasetId: datasetId,
            },
          },
        },
        {
          var: generateUniqueId(),
          operation: {
            type: "Table.Schema",
            args: {
              table: id,
            },
          },
          domain: "viewResolver",
        },
      ],
    });
  };

  async componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<IState>
  ) {
    if (
      this.props.visible === false &&
      prevProps.visible === true &&
      this.state.currentStep !== 0
    ) {
      this.setState({
        currentStep: 0,
        isLoading: true,
        hasWarning: false,
        dataset: {
          status: "initial",
        },
        drills: {
          status: "initial",
        },
        relationships: {
          status: "initial",
        },
        explorations: {
          status: "initial",
        },
        models: {
          status: "initial",
        },
      });
    }
    if (
      this.props.visible === true &&
      prevProps.visible === false &&
      this.state.currentStep === 0
    ) {
      this.setState({
        dataset: {
          status: "loading",
        },
      });
      try {
        const datasetData = await this.getDataset();
        const dataset = datasetData.allDatasets[0];
        let schemaData = {};
        if (dataset.type === "FLOW" && dataset.head === null) {
          schemaData = {};
        } else {
          schemaData = (await this.getSchema()).data.schema;
        }
        this.setState({
          currentStep: 1,
          dataset: {
            status: "success",
            data: {
              dataset: dataset,
              schema: schemaData as SchemaResult,
            },
          },
        });
      } catch (err) {
        console.error(err);
        this.setState({
          currentStep: 1,
          dataset: {
            status: "error",
            error: err,
          },
        });
      }
    }
  }

  getDeletedColumns = async () => {
    if (this.state.dataset.status === "success") {
      const currentSchema = this.state.dataset.data.schema;
      const newSchema = await this.props.getNewSchema();

      return Object.keys(currentSchema)
        .map((c) => {
          if (!newSchema[c]) return c;
          return null;
        })
        .filter((c) => c);
    }
  };

  getAddedColumns = async () => {
    if (this.state.dataset.status === "success") {
      const currentSchema = this.state.dataset.data.schema;
      const newSchema = await this.props.getNewSchema();

      return Object.keys(newSchema)
        .map((c) => {
          if (!currentSchema[c]) return c;
          return null;
        })
        .filter((c) => c);
    }
  };

  getModifiedColumns = async () => {
    if (this.state.dataset.status === "success") {
      const currentSchema = this.state.dataset.data.schema;
      const newSchema = await this.props.getNewSchema();

      return Object.keys(currentSchema)
        .map((c) => {
          if (!newSchema[c]) return null;
          if (currentSchema[c].type !== newSchema[c].type) return c;
          return null;
        })
        .filter((c) => c);
    }
  };

  getExplorationUsage = async (
    columnNames: string[]
  ): Promise<{
    allDimensions: Array<{
      name: string;
      table: {
        name: string;
      };
    }>;
    allMetrics: Array<{
      name: string;
      table: {
        name: string;
      };
    }>;
  }> => {
    // prettier-ignore
    const query = `
      query getUsedDimension(
        $orgId: ID!
        $datasetId: ID!
        $columnNames: [String!]!
      ) {
        allDimensions(
          where: {
            columnName_in: $columnNames
            table: {
              deleted_not: true
              exploration: { deleted_not: true }
              view: {
                deleted_not: true
                dataset: { deleted_not: true, id: $datasetId }
              }
            }
            deleted_not: true
            org: { id: $orgId }
          }
        ) {
          name
          table {
            exploration {
            name
          }
          }
        }
        allMetrics(
          where: {
            AND: [
              {
                deleted_not: true
                table: {
                  deleted_not: true
                  exploration: { deleted_not: true }
                  view: {
                    deleted_not: true
                    dataset: { deleted_not: true, id: $datasetId }
                  }
                }
                org: { id: $orgId }
              }
              {
                OR: [
                  { columnName_in: $columnNames },
                  ${columnNames
                    .map((c) => {
                      const name = '\\"column\\":\\"' + c + '\\"';
                      return '{ filters_contains_i: \"' + name + '\"}';
                    })
                    .join(", ")}
                ]
              }
            ]
          }
        ) {
          name
          table {
            exploration {
            name
          }
          }
        }
      }
    `;
    return GraphQLService(query, {
      orgId: this.props.org.id,
      datasetId: this.props.datasetId,
      columnNames,
    });
  };

  getRelatedModels = async (): Promise<{
    allModels: Array<IDataset>;
  }> => {
    // we need a way to identify flow models that directly use this dataset
    // prettier-ignore
    const query = RELATED_MODELS_QUERY;
    return GraphQLService(query, {
      orgId: this.props.org.id,
      datasetId: this.props.datasetId,
    });
  };

  renderInnerModal = () => {
    if (this.state.currentStep === 0) {
      return (
        <div style={{ width: "100%", textAlign: "center" }}>
          <Loading />
          <br />
          Fetching dataset information...
        </div>
      );
    }
    if (this.state.currentStep === 1) {
      const steps: IPreSaveModelStep[] = [
        {
          name: <span>Checking relationships</span>,
          onStart: async () => {
            this.setState({
              relationships: {
                status: "loading",
              },
            });
            try {
              if (this.state.dataset.status === "success") {
                const deletedColumns = await this.getDeletedColumns();
                const modifiedColumns = await this.getModifiedColumns();
                const impactedColumns = _.uniq([
                  ...deletedColumns,
                  ...modifiedColumns,
                ]);

                const reverseRelationship = (
                  r: IDatasetRelationship
                ): Partial<IDatasetRelationship> => {
                  // eslint-disable-next-line no-nested-ternary
                  const type = r.type === "1-N" ? "N-1" : "N-1" ? "1-N" : "1-1";
                  return {
                    left: r.right,
                    right: r.left,
                    from: r.to,
                    to: r.from,
                    type: type,
                  };
                };
                const impactedRelationships = {
                  incoming: [
                    ...this.state.dataset.data.dataset.incomingRelationships
                      .filter((r) => impactedColumns.includes(r.to))
                      .map((r) => reverseRelationship(r)),
                  ],
                  outgoing: [
                    ...this.state.dataset.data.dataset.outgoingRelationships.filter(
                      (r) => impactedColumns.includes(r.from)
                    ),
                  ],
                };
                if (
                  impactedRelationships.incoming.length === 0 &&
                  impactedRelationships.outgoing.length === 0
                ) {
                  this.setState({
                    relationships: {
                      status: "success",
                      data: [],
                      component: (
                        <>Relationships won't be affected by your changes</>
                      ),
                    },
                  });
                } else {
                  const data = [
                    ...impactedRelationships.outgoing,
                    ...impactedRelationships.incoming,
                  ];
                  const columns: ColumnProps<IDatasetRelationship>[] = [
                    {
                      title: "From",
                      dataIndex: "from",
                      key: "from",
                      width: "40%",
                      ellipsis: true,
                      render: (v, s) => {
                        return (
                          <RelationshipSideRenderer
                            columnName={s.from}
                            datasetName={s.left.name}
                            datasetImg={
                              s.left.source?.sourceMeta?.publicInfo?.logo
                            }
                            isModel={s.left.isModel}
                          />
                        );
                      },
                    },
                    {
                      title: "Type",
                      dataIndex: "Type",
                      key: "Type",
                      width: "20%",
                      render: (v, s) => {
                        return <RelationshipTypeRenderer type={s.type} />;
                      },
                    },
                    {
                      title: "To",
                      dataIndex: "To",
                      key: "To",
                      width: "40%",
                      ellipsis: true,
                      render: (v, s) => {
                        return (
                          <RelationshipSideRenderer
                            columnName={s.to}
                            datasetName={s.right.name}
                            datasetImg={
                              s.right.source?.sourceMeta?.publicInfo?.logo
                            }
                            isModel={s.right.isModel}
                          />
                        );
                      },
                    },
                  ];

                  this.setState({
                    relationships: {
                      status: "error",
                      component: (
                        <>
                          <p>
                            The following relationships will break with your
                            changes:
                          </p>
                          <Table
                            columns={columns}
                            dataSource={data}
                            size={"small"}
                            pagination={{
                              style: { display: "none" },
                              defaultPageSize: 10000,
                            }}
                          />
                        </>
                      ),
                    },
                  });
                }

                return Promise.resolve();
              } else {
                throw Error("Can't check relationships");
              }
            } catch (err) {
              console.error(err);
              this.setState({
                relationships: {
                  status: "error",
                  component: <>Error while checking relationships</>,
                  error: err,
                },
              });
              return Promise.resolve();
            }
          },
          store: this.state.relationships,
        },
        {
          name: <span>Checking drills</span>,
          onStart: async () => {
            this.setState({
              drills: {
                status: "loading",
              },
            });
            try {
              if (this.state.dataset.status === "success") {
                const deletedColumns = await this.getDeletedColumns();
                const drills = this.state.dataset.data.dataset.views
                  .flatMap((v) => (v.drills ? v.drills.split(",") : null))
                  .filter((v) => v);
                const deletedDrills = drills.filter((c) =>
                  deletedColumns.includes(c)
                );
                if (deletedDrills.length === 0) {
                  this.setState({
                    drills: {
                      status: "success",
                      data: [],
                      component: <>Drills won't be impacted by your changes</>,
                    },
                  });
                } else {
                  this.setState({
                    drills: {
                      status: "error",
                      component: (
                        <>
                          <p>
                            This model drills will break with your changes, you
                            will need to remove the following columns:
                          </p>
                          <ul>
                            {deletedColumns.map((c, i) => (
                              <li key={i}>{c}</li>
                            ))}
                          </ul>
                        </>
                      ),
                    },
                  });
                }

                return Promise.resolve();
              } else {
                throw Error("Can't check drills");
              }
            } catch (err) {
              console.error(err);
              this.setState({
                drills: {
                  status: "error",
                  error: err,
                  component: <>Error while checking drills</>,
                },
              });
              return Promise.resolve();
            }
          },
          store: this.state.drills,
        },
        {
          name: <span>Checking explorations</span>,
          onStart: async () => {
            this.setState({
              explorations: {
                status: "loading",
              },
            });
            try {
              if (this.state.dataset.status === "success") {
                const deletedColumns = await this.getDeletedColumns();
                const modifiedColumns = await this.getModifiedColumns();
                const columns = _.uniq([...deletedColumns, ...modifiedColumns]);
                const measures = await this.getExplorationUsage(columns);
                if (
                  measures.allDimensions.length === 0 &&
                  measures.allMetrics.length === 0
                ) {
                  this.setState({
                    explorations: {
                      status: "success",
                      data: [],
                      component: (
                        <>Explorations won't be impacted by your changes</>
                      ),
                    },
                  });
                } else {
                  const distinctExplorations = _.uniq(
                    [...measures.allDimensions, ...measures.allMetrics].flatMap(
                      (m) => (m.table as any).exploration.name
                    )
                  );
                  this.setState({
                    explorations: {
                      status: "error",
                      component: (
                        <>
                          <p>
                            The following explorations metrics and dimensions
                            will break with your changes:
                          </p>
                          <Space
                            direction="vertical"
                            style={{ width: "300px" }}
                          >
                            {distinctExplorations.map((e, i) => {
                              return (
                                <div key={i}>
                                  {e}
                                  {measures.allDimensions
                                    .filter(
                                      (m) =>
                                        (m.table as any).exploration.name === e
                                    )
                                    .map((m, j) => (
                                      <MeasureItem
                                        type="dimension"
                                        name={m.name}
                                        key={j}
                                      />
                                    ))}
                                  {measures.allMetrics
                                    .filter(
                                      (m) =>
                                        (m.table as any).exploration.name === e
                                    )
                                    .map((m, j) => (
                                      <MeasureItem
                                        type="metric"
                                        name={m.name}
                                        key={j}
                                      />
                                    ))}
                                </div>
                              );
                            })}
                          </Space>
                        </>
                      ),
                    },
                  });
                }
                return Promise.resolve();
              } else {
                throw Error("Can't check explorations");
              }
            } catch (err) {
              console.error(err);
              this.setState({
                explorations: {
                  status: "error",
                  error: err,
                  component: <>Error while checking explorations</>,
                },
              });
              return Promise.resolve();
            }
          },
          store: this.state.explorations,
        },
        {
          name: <span>Checking models</span>,
          onStart: async () => {
            this.setState({
              models: {
                status: "loading",
              },
            });
            try {
              if (this.state.dataset.status === "success") {
                const relatedModels = await this.getRelatedModels();
                const addedColumns = await this.getAddedColumns();
                const deletedColumns = await this.getDeletedColumns();
                const modifiedColumns = await this.getModifiedColumns();

                if (
                  relatedModels.allModels.length === 0 ||
                  (addedColumns.length === 0 &&
                    deletedColumns.length === 0 &&
                    modifiedColumns.length === 0)
                ) {
                  this.setState({
                    models: {
                      status: "success",
                      data: [],
                      component: <>Models won't be impacted by your changes</>,
                    },
                  });
                } else {
                  this.setState({
                    models: {
                      status: "warning",
                      component: (
                        <>
                          <p>
                            Make sure that your changes won't break the
                            following models that directly reference the current
                            model:
                          </p>
                          <Space
                            direction="vertical"
                            style={{ width: "300px" }}
                          >
                            {relatedModels.allModels.map((m, i) => (
                              <div key={i}>
                                <SourceImageRenderer
                                  alt={m.name}
                                  size={16}
                                  isModel
                                />{" "}
                                {m.name}
                              </div>
                            ))}
                          </Space>
                        </>
                      ),
                    },
                  });
                }
                return Promise.resolve();
              } else {
                throw Error("Can't check models");
              }
            } catch (err) {
              console.error(err);
              this.setState({
                models: {
                  status: "error",
                  error: err,
                  component: <>Error while checking models</>,
                },
              });
              return Promise.resolve();
            }
          },
          store: this.state.models,
        },
      ];
      return (
        <div style={{ marginTop: 24 }}>
          <PreSaveModelSteps
            steps={steps}
            onDone={async () => {
              this.setState({
                isLoading: false,
                hasWarning: [
                  this.state.drills.status,
                  this.state.explorations.status,
                  this.state.relationships.status,
                  this.state.models.status,
                ].some((s) => ["warning", "error"].includes(s)),
              });
              return Promise.resolve();
            }}
          />
        </div>
      );
    }
  };

  public render() {
    const { visible, onCancel, onContinue } = this.props;
    return (
      <Modal
        title={"Running pre-save checks"}
        open={visible}
        onCancel={onCancel}
        onOk={onContinue}
        okButtonProps={{
          loading: this.state.isLoading,
          danger: this.state.hasWarning,
        }}
        okText={this.state.hasWarning ? "Continue anyway" : "Continue"}
        width={740}
      >
        {this.renderInnerModal()}
      </Modal>
    );
  }
}

export default compose<Props, IPreSaveModelModalProps>(WithOrg)(
  PreSaveModelModal
);
