import * as React from "react";
import type { CellData, DataType } from "../../interfaces/transformations";
import FieldRenderer from "./renderer/FieldRenderer";

const wrapContent = (n: React.ReactNode, isForeign = false, isUsed = false) => {
  return isForeign ? (
    <span className={`foreign ${isUsed ? " used" : ""}`}>{n}</span>
  ) : (
    <span>{n}</span>
  );
};

export const renderContent = (
  cellData: CellData,
  type?: DataType,
  isForeign?: boolean,
  isUsed?: boolean
) => {
  let content = <FieldRenderer content={cellData} type={type} />;
  return cellData ? wrapContent(content, isForeign, isUsed) : content;
};
