import { CloseOutlined } from "@ant-design/icons";
import { Button } from "antd";
import * as React from "react";
import type { DraggableProvidedDragHandleProps } from "react-beautiful-dnd";
import type { IActiveObject } from "../domain";

interface ITabBarItemProps {
  activeObject: IActiveObject;
  dragHandleProps: DraggableProvidedDragHandleProps | undefined;
  onRemoveObject: (activeObject: IActiveObject) => void;
  onObjectSelect: (activeObject: IActiveObject) => void;
  nameRenderer: (activeObject: IActiveObject) => string;
  iconRenderer?: (activeObject: IActiveObject) => React.ReactNode;
}

export function TabBarItem(props: ITabBarItemProps) {
  const {
    activeObject,
    dragHandleProps,
    onRemoveObject,
    onObjectSelect,
    nameRenderer,
    iconRenderer,
  } = props;

  const TabRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    if (!activeObject.focused) return;
    TabRef?.current?.scrollIntoView({
      block: "center",
      inline: "center",
    });
  }, [activeObject.focused]);

  const icon = iconRenderer ? iconRenderer(activeObject) : null;
  return (
    <div
      className="content-tab-bar-tab"
      onClick={() => onObjectSelect(activeObject)}
      ref={TabRef}
    >
      <div
        {...dragHandleProps}
        className={`content-tab-bar-tab-inner ${
          activeObject.focused ? "active" : ""
        }`}
      >
        {icon && <span className="content-tab-bar-tab-inner-icon">{icon}</span>}
        <span className="content-tab-bar-tab-inner-text">
          {nameRenderer(activeObject)}
        </span>
        <span className="content-tab-bar-tab-inner-actions">
          {activeObject.stale ? <span className="stale">&#9679;</span> : null}
          <Button
            shape="circle"
            size="small"
            className="close"
            icon={<CloseOutlined />}
            type="text"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              onRemoveObject(activeObject);
            }}
          />
        </span>
      </div>
    </div>
  );
}
