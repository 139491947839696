import { List, Skeleton, Space, Typography } from "antd";
import * as React from "react";
import { compose } from "../../../../../components/compose/WlyCompose";
import {
  computeLoadTime,
  computeSimplifiedTrace,
} from "../../../../exploration/single/performance/domain";
import type { InjectedOrgProps } from "../../../../orgs/WithOrg";
import WithOrg from "../../../../orgs/WithOrg";
import ItemPerformance from "../../../../performance-console/item-performance/ItemPerformance";
import type { DataMapStore } from "../../domain";

interface IChartPerformanceProps {
  data: DataMapStore;
  initialSelectedItem?: string;
}

type Props = IChartPerformanceProps & InjectedOrgProps;

function ChartPerformance(props: Props) {
  const { data, initialSelectedItem, user } = props;
  const [selectedItem, setSelectedItem] = React.useState<string>(
    initialSelectedItem ? initialSelectedItem : Object.keys(data)[0]
  );

  React.useEffect(() => {
    setSelectedItem(
      initialSelectedItem ? initialSelectedItem : Object.keys(data)[0]
    );
  }, [initialSelectedItem]);

  const renderRight = () => {
    if (!selectedItem || !data[selectedItem]) {
      return "No item selected";
    }
    const record = data[selectedItem];

    return (
      <ItemPerformance
        data={record.data}
        traceData={record.trace}
        sql={record.sql}
        name={record.raw.name}
      />
    );
  };

  return (
    <div className="report-console-content-wrapper">
      <div className="report-console-content-left">
        <List
          dataSource={Object.keys(data).map((k, i) => {
            const record = data[k];
            return record;
          })}
          className="report-console-content-left-wrapper"
          renderItem={(item) => {
            const trace = computeSimplifiedTrace(
              item.trace && item.trace.status === "success"
                ? item.trace.data.events
                : []
            );
            return (
              <List.Item
                onClick={() => setSelectedItem(item.raw.id)}
                className={`report-console-content-left-item ${
                  selectedItem === item.raw.id ? "active" : ""
                }`}
              >
                <List.Item.Meta
                  title={
                    <Typography.Text ellipsis>{item.raw.name}</Typography.Text>
                  }
                  description={
                    <Skeleton
                      paragraph={false}
                      title={true}
                      avatar={false}
                      loading={item.trace?.status !== "success"}
                    >
                      <Typography.Text ellipsis type="secondary">
                        {computeLoadTime(trace)} ms
                      </Typography.Text>
                    </Skeleton>
                  }
                />
              </List.Item>
            );
          }}
        />
      </div>
      <div className="report-console-content-right">
        <Space style={{ width: "100%" }} direction="vertical" size={"large"}>
          {renderRight()}
        </Space>
      </div>
    </div>
  );
}

export default compose<Props, IChartPerformanceProps>(WithOrg)(
  ChartPerformance
);
