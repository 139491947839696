import { Button, Select } from "antd";
import type { BaseSelectRef } from "rc-select";
import * as React from "react";
import "./MeasurePicker.scss";

interface IMeasurePickerProps {
  availableMeasures: Array<{ key: string; label: string }>;
  selectedMeasureKeys: Array<string>;
  onMeasureChange: (measure: string) => void;
  block?: boolean;
  max?: number;
  disabled?: boolean;
  children?: React.ReactNode;
}

const MeasurePicker: React.FunctionComponent<IMeasurePickerProps> = (props) => {
  const {
    availableMeasures,
    selectedMeasureKeys,
    onMeasureChange,
    children,
    max,
  } = props;

  let disabled = props.disabled || availableMeasures.length === 0;
  if (typeof max === "number" && selectedMeasureKeys.length > 0) {
    disabled =
      props.disabled ||
      !availableMeasures ||
      availableMeasures.length === 0 ||
      selectedMeasureKeys.length >= max;
  }
  const [open, setOpen] = React.useState<boolean>(null);

  const selectRef = React.useRef<BaseSelectRef>(null);

  return (
    <div style={{ position: "relative" }}>
      <Button
        block
        size={"small"}
        type={"dashed"}
        disabled={disabled}
        style={{
          pointerEvents: "none",
          position: "absolute",
          inset: 0,
          zIndex: 2,
          display: open ? "none" : "initial",
        }}
      >
        {children}
      </Button>
      <Select
        ref={selectRef}
        className="wly-dropdown-select"
        style={{ width: "100%", outline: "1px solid transparent" }}
        onDropdownVisibleChange={setOpen}
        size={"small"}
        showSearch
        disabled={disabled}
        optionFilterProp={"label"}
        options={availableMeasures.map((am) => {
          return {
            label: am.label,
            value: am.key,
          };
        })}
        onChange={(measure) => {
          onMeasureChange(measure);
          selectRef.current?.blur?.();
        }}
      />
    </div>
  );
};

export default MeasurePicker;
