import type { BinaryOperator, Query } from "@cubejs-client/core";
import { Button, Col, Empty, Flex, Space, Typography } from "antd";
import { useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import type { InjectedAntUtilsProps } from "../../../../../../../components/ant-utils/withAntUtils";
import { withAntUtils } from "../../../../../../../components/ant-utils/withAntUtils";
import { compose } from "../../../../../../../components/compose/WlyCompose";
import Loading from "../../../../../../../components/layout/feedback/loading";
import type {
  IObject,
  IObjectPresetFilter,
} from "../../../../../../../interfaces/object";
import {
  LagoonCallOrigin,
  lagoonServiceLoad,
} from "../../../../../../../services/LagoonService";
import type { InjectedOrgProps } from "../../../../../../orgs/WithOrg";
import WithOrg from "../../../../../../orgs/WithOrg";
import {
  getObjectColumns,
  isAvailableProperty,
} from "../../../../../../v2-demo/container/object/domain";
import { getAvailableDimensions } from "../../../../../../v2-demo/container/object/viewer/domain";
import PresetFilterCard from "./PresetFilterCard";
import { useCreateObjectPresetFilter } from "./api/useCreateObjectPresetFilter";
import { useGetObjectPresetFilters } from "./api/useGetObjectPresetFilters";
import { useUpdateObjectPresetFilters } from "./api/useUpdateObjectPresetFilters";
import type {
  CreateEditPresetFilterFormData,
  DeleteObjectPresetFiltersParams,
} from "./domain";
import CreateEditPresetFilterModal from "./modal/CreateEditPresetFilterModal";

type IObjectPresetFiltersProps = {
  object: IObject;
};

type Props = IObjectPresetFiltersProps &
  InjectedOrgProps &
  InjectedAntUtilsProps;

const { Title } = Typography;

function reorder<T>(list: T[], startIndex: number, endIndex: number) {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
}

function ObjectPresetFilters({ antUtils, object, org }: Props) {
  const { data, pending, loading, error, refetch } = useGetObjectPresetFilters({
    variables: { orgId: org.id, objectId: object.id },
  });
  const { mutateAsync: updatePresets } = useUpdateObjectPresetFilters();
  const { mutateAsync: createPreset, loading: isCreating } =
    useCreateObjectPresetFilter();

  const [presetFilters, setPresetFilters] = useState<IObjectPresetFilter[]>([]);
  const [isEditing, setIsEditing] = useState<
    CreateEditPresetFilterFormData | undefined
  >(undefined);

  useEffect(() => {
    if (data?.allObjectPresetFilters) {
      setPresetFilters(
        data?.allObjectPresetFilters.map((f) => ({
          ...f,
          value: JSON.parse(f.value as unknown as string),
        })) ?? []
      );
    }
  }, [data]);

  if (pending || loading) {
    return <Loading />;
  }

  if (error) {
    return <>{error.message}</>;
  }

  const onDelete = async (params: DeleteObjectPresetFiltersParams) => {
    try {
      await updatePresets({
        variables: { data: [{ id: params.id, data: { isDeleted: true } }] },
      });
      setPresetFilters((prev: IObjectPresetFilter[]) =>
        prev.filter((p) => p.id !== params.id)
      );
      antUtils.message.success("Preset deleted");
    } catch (error) {
      console.error(error);
      antUtils.message.error("Error while deleting preset. Try again");
    }
  };

  const onCreate = async (filter: Partial<IObjectPresetFilter>) => {
    try {
      await createPreset({
        variables: {
          data: {
            ...filter,
            value: JSON.stringify(filter.value),
            order:
              presetFilters.length === 0
                ? 0
                : Math.max(...presetFilters.map((av) => av.order)) + 1,
            object: { connect: { id: object.id } },
            org: { connect: { id: org.id } },
          },
        },
      });
      refetch();
      antUtils.message.success("Preset created");
    } catch (error) {
      console.error(error);
      antUtils.message.error("Error while creating preset. Try again");
    }
  };

  const onReorder = async (filters: IObjectPresetFilter[]) => {
    try {
      setPresetFilters(filters);
      await updatePresets({
        variables: {
          data: filters.map((filter, index) => ({
            id: filter.id,
            data: { order: index },
          })),
        },
      });
      antUtils.message.success("Presets updated!");
    } catch (error) {
      console.error(error);
      antUtils.message.error("Error while ordering presets. Try again");
    }
  };

  const onUpdate = async (
    id: string,
    udpated: CreateEditPresetFilterFormData
  ) => {
    try {
      await updatePresets({
        variables: {
          data: [
            {
              id: id,
              data: {
                name: udpated.name,
                value: JSON.stringify(udpated.value),
              },
            },
          ],
        },
      });
      setPresetFilters((prev: IObjectPresetFilter[]) =>
        prev.map((p) => {
          if (p.id === id) {
            return {
              ...p,
              ...udpated,
            };
          } else {
            return p;
          }
        })
      );
      antUtils.message.success("Preset updated");
    } catch (error) {
      console.error(error);
      antUtils.message.error("Error while updating preset. Try again");
    }
  };

  const availableColumns = getObjectColumns(object);
  const availableProperties = availableColumns
    .filter(isAvailableProperty)
    .map((d) => d.data);
  const availableDimensions = getAvailableDimensions(availableProperties, {
    type: "sortAndFilter",
  });

  const autocomplete = async (
    dimension: string,
    operator?: BinaryOperator,
    value?: string
  ): Promise<string[]> => {
    let query: Query = {
      dimensions: [dimension],
      limit: 50,
      filters: [
        {
          member: dimension,
          operator: "set",
        },
      ],
    };
    if (typeof value === "string" && value !== "" && operator) {
      query = {
        dimensions: [dimension],
        limit: 50,
        filters: [
          {
            member: dimension,
            operator: operator,
            values: [value],
          },
        ],
      };
    }
    return lagoonServiceLoad(
      org.id,
      query,
      "OBJECT",
      object.id,
      object.id,
      LagoonCallOrigin.WHALY_APP,
      undefined,
      undefined,
      false
    )
      .then((r) => {
        return r.tablePivot();
      })
      .then((r) => {
        return (r || []).map((d) => d[dimension] as string);
      });
  };

  return (
    <Col span={16} offset={4}>
      <Space direction="vertical" size={32} style={{ width: "100%" }}>
        <div>
          <Flex align="center" style={{ paddingTop: 16 }}>
            <div style={{ flex: 1 }}>
              <Title level={3} style={{ marginBottom: 0 }}>
                Preset filters
              </Title>
            </div>
            <Button
              onClick={() =>
                setIsEditing({
                  name: "",
                  value: { operator: "and", filters: [] },
                })
              }
            >
              Add filter
            </Button>
          </Flex>
        </div>
        <DragDropContext
          onDragEnd={(result) => {
            if (!result.destination) {
              return;
            }

            const items = reorder(
              [...presetFilters],
              result.source.index,
              result.destination.index
            );

            onReorder(items);
          }}
        >
          <Droppable
            droppableId={"OBJECT_PRESET_FILTERS"}
            type="OBJECT_PRESET_FILTER"
          >
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                {...provided.droppableProps}
                style={
                  snapshot.isDraggingOver
                    ? {
                        background: "#F2F4F5",
                        outline: "6px solid #F2F4F5",
                        borderRadius: 6,
                      }
                    : {}
                }
              >
                {presetFilters.length ? (
                  presetFilters.map((apf, index) => (
                    <Draggable draggableId={apf.id} key={apf.id} index={index}>
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                        >
                          <div style={{ paddingTop: 3, paddingBottom: 3 }}>
                            <PresetFilterCard
                              key={apf.id}
                              presetFilter={apf}
                              onDelete={onDelete}
                              dragHandleProps={provided.dragHandleProps}
                              onEdit={() =>
                                setIsEditing({
                                  id: apf.id,
                                  name: apf.name,
                                  value: apf.value,
                                })
                              }
                            />
                          </div>
                        </div>
                      )}
                    </Draggable>
                  ))
                ) : (
                  <div>
                    <Empty description="No filters" />
                  </div>
                )}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        <CreateEditPresetFilterModal
          autocomplete={autocomplete}
          availableDimensions={availableDimensions}
          open={!!isEditing}
          isSaving={isCreating}
          onCancel={() => setIsEditing(undefined)}
          initialValues={isEditing}
          onSave={async (values) => {
            try {
              if (values.id) {
                onUpdate(values.id, values);
              } else {
                onCreate({
                  name: values.name,
                  value: values.value,
                });
              }
              setIsEditing(undefined);
            } catch (error) {
              console.error(error);
            }
          }}
        />
        <div style={{ height: 32 }}></div>
      </Space>
    </Col>
  );
}

export default compose<Props, IObjectPresetFiltersProps>(
  WithOrg,
  withAntUtils
)(ObjectPresetFilters);
