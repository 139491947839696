import type { IUser } from "./user";

export enum AuditLogAction {
  PERFORM = "PERFORM",
  CREATE = "CREATE",
  UPDATE = "UPDATE",
  DELETE = "DELETE",
}

export enum AuditLogCategory {
  SECURITY_ACTIVITY = "SECURITY_ACTIVITY",
  FIELD = "FIELD",
  OBJECT = "OBJECT",
  LOGIN = "LOGIN",
}

export enum AuditLogSubCategory {
  DATA_EXPORT = "DATA_EXPORT",
  ADD_USER = "ADD_USER",
  REMOVE_USER = "REMOVE_USER",
  CHANGE_LICENSE = "CHANGE_LICENSE",
  CHANGE_PASSWORD = "CHANGE_PASSWORD",
  SERVICE_ACCOUNT_KEY_CREATE = "SERVICE_ACCOUNT_KEY_CREATE",
  SERVICE_ACCOUNT_KEY_DELETE = "SERVICE_ACCOUNT_KEY_DELETE",
  IMPERSONATE_USER = "IMPERSONATE_USER",
  DATASET = "DATASET",
}

export interface IAuditLog {
  id: string;
  action: AuditLogAction;
  recordId: string;
  at: string;
  category: AuditLogCategory;
  subCategory: AuditLogSubCategory;
  user: IUser;
  tableName?: string;
  fieldName?: string;
  previousValue?: any;
  newValue?: any;
}
