import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  InfoCircleTwoTone,
  QuestionCircleOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import { Tag, Tooltip } from "antd";
import type { IJobExecution } from "../../../interfaces/jobExecutions";

interface IExecutionStatusTagProps {
  execution?: IJobExecution;
  hideError?: boolean;
}

export function ExecutionStatusTag(props: IExecutionStatusTagProps) {
  const { execution, hideError } = props;
  if (!execution || !execution.status)
    return (
      <Tag>
        <QuestionCircleOutlined /> Unknown
      </Tag>
    );
  switch (props.execution.status) {
    case "SUCCESSED":
      return (
        <Tag color="success">
          <CheckCircleOutlined /> Success
        </Tag>
      );
    case "FAILED":
      return (
        <Tag color="error">
          <CloseCircleOutlined /> Failed
        </Tag>
      );
    case "RUNNING":
      return (
        <Tag color="processing">
          <SyncOutlined spin /> Running
        </Tag>
      );
    case "ERROR":
      return (
        <span>
          <Tag color="error">
            <CloseCircleOutlined /> Error
          </Tag>{" "}
          {execution.errorText && !hideError && (
            <Tooltip title={execution.errorText}>
              <InfoCircleTwoTone />
            </Tooltip>
          )}
        </span>
      );
  }
}
