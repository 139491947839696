import Icon from "@ant-design/icons";
import type { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";

export const WlyCalendarChartIcon = (
  props: Partial<CustomIconComponentProps>
) => (
  <Icon
    component={() => (
      <svg
        width="1em"
        height="1em"
        viewBox="0 0 197 201"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="2"
          y="160"
          width="39"
          height="39"
          fill="#B8C4D2"
          stroke="#3A5C83"
          strokeWidth="4"
        />
        <rect
          x="41"
          y="160"
          width="39"
          height="39"
          fill="#B8C4D2"
          stroke="#3A5C83"
          strokeWidth="4"
        />
        <rect
          x="80"
          y="160"
          width="38"
          height="39"
          fill="#B8C4D2"
          stroke="#3A5C83"
          strokeWidth="4"
        />
        <rect
          x="118"
          y="160"
          width="39"
          height="39"
          fill="#B8C4D2"
          stroke="#3A5C83"
          strokeWidth="4"
        />
        <rect
          x="2"
          y="120"
          width="39"
          height="40"
          fill="#B8C4D2"
          stroke="#3A5C83"
          strokeWidth="4"
        />
        <rect
          x="41"
          y="120"
          width="39"
          height="40"
          fill="#B8C4D2"
          stroke="#3A5C83"
          strokeWidth="4"
        />
        <rect
          x="80"
          y="120"
          width="38"
          height="40"
          fill="#B8C4D2"
          stroke="#3A5C83"
          strokeWidth="4"
        />
        <rect
          x="118"
          y="120"
          width="39"
          height="40"
          fill="#B8C4D2"
          stroke="#3A5C83"
          strokeWidth="4"
        />
        <rect
          x="2"
          y="81"
          width="39"
          height="39"
          fill="#B8C4D2"
          stroke="#3A5C83"
          strokeWidth="4"
        />
        <rect
          x="41"
          y="81"
          width="39"
          height="39"
          fill="#B8C4D2"
          stroke="#3A5C83"
          strokeWidth="4"
        />
        <rect
          x="80"
          y="81"
          width="38"
          height="39"
          fill="#B8C4D2"
          stroke="#3A5C83"
          strokeWidth="4"
        />
        <rect
          x="118"
          y="81"
          width="39"
          height="39"
          fill="#B8C4D2"
          stroke="#3A5C83"
          strokeWidth="4"
        />
        <rect
          x="41"
          y="41"
          width="38"
          height="40"
          fill="#B8C4D2"
          stroke="#3A5C83"
          strokeWidth="4"
        />
        <rect
          x="79"
          y="41"
          width="39"
          height="40"
          fill="#B8C4D2"
          stroke="#3A5C83"
          strokeWidth="4"
        />
        <rect
          x="118"
          y="41"
          width="39"
          height="40"
          fill="#B8C4D2"
          stroke="#3A5C83"
          strokeWidth="4"
        />
        <rect
          x="157"
          y="41"
          width="38"
          height="40"
          fill="#B8C4D2"
          stroke="#3A5C83"
          strokeWidth="4"
        />
        <rect
          x="41"
          y="2"
          width="38"
          height="39"
          fill="#B8C4D2"
          stroke="#3A5C83"
          strokeWidth="4"
        />
        <rect
          x="79"
          y="2"
          width="39"
          height="39"
          fill="#B8C4D2"
          stroke="#3A5C83"
          strokeWidth="4"
        />
        <rect
          x="118"
          y="2"
          width="39"
          height="39"
          fill="#B8C4D2"
          stroke="#3A5C83"
          strokeWidth="4"
        />
        <rect
          x="157"
          y="2"
          width="38"
          height="39"
          fill="#B8C4D2"
          stroke="#3A5C83"
          strokeWidth="4"
        />
      </svg>
    )}
    {...props}
  />
);
