import { QuestionCircleOutlined } from "@ant-design/icons";
import type { SelectProps } from "antd";
import { Select, Tag, Tooltip } from "antd";
import type { SchemaResult } from "../../../../../../interfaces/transformations";
import TypeRenderer from "../../../../../spreadsheet/renderer/TypeRenderer";

interface IPrimaryKeyFieldProps extends SelectProps {
  schema: SchemaResult;
  outdatedKeys: string[];
}

export const PKValidationRule = [
  {
    validator: async (_, keys) => {
      if (!keys || keys.length < 1) {
        return Promise.reject(new Error("At least 1 key required"));
      }
    },
  },
];

export function PrimaryKeyField(props: IPrimaryKeyFieldProps) {
  const { schema, outdatedKeys, ...rest } = props;

  const tagRender = (rp: any) => {
    const { label, closable, onClose } = rp;
    const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
      event.preventDefault();
      event.stopPropagation();
    };

    const isOutdated = outdatedKeys.includes(label);

    return (
      <Tag
        color={isOutdated ? "gold" : "default"}
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        style={{ marginRight: 3 }}
      >
        <div style={{ display: "inline-block" }}>
          {isOutdated && "⚠️"} {label}
          {isOutdated ? (
            <span style={{ color: "gray", paddingLeft: 5 }}>
              <Tooltip title="This column does not exist anymore. You should probably remove it from the drill.">
                <QuestionCircleOutlined />
              </Tooltip>
            </span>
          ) : undefined}
        </div>
      </Tag>
    );
  };

  return (
    <Select
      {...rest}
      mode="multiple"
      style={{ width: "100%" }}
      placeholder="Select your primary keys"
      tagRender={tagRender}
    >
      {Object.keys(schema).map((k, i) => {
        return (
          <Select.Option key={i} value={k}>
            <TypeRenderer domain={schema[k].domain} formula={!!schema[k].var} />{" "}
            {k}
          </Select.Option>
        );
      })}
    </Select>
  );
}
