import { DefaultLinkFactory } from "@projectstorm/react-diagrams";
import { FlowLinkModel } from "./FlowLinkModel";
import { FlowLinkWidget } from "./FlowLinkWidget";

export class FlowLinkFactory extends DefaultLinkFactory {
  constructor() {
    super("advanced");
  }

  generateModel(e): FlowLinkModel {
    return new FlowLinkModel();
  }

  generateLinkSegment(model: FlowLinkModel, selected: boolean, path: string) {
    return <FlowLinkWidget model={model} path={path} engine={this.engine} />;
  }
}
