import { Input, Space } from "antd";
import type { IExploration } from "../../../../../interfaces/explorations";
import type { IReport } from "../../../../../interfaces/reports";
import type { ILagoonQuery } from "../../../../exploration/single/domain";

export const MIN_HEIGHT = 4;
export const MIN_WIDTH = 3;

export const renderEmbedModal = (report: IReport) => ({
  title: `Embed`,
  content: (
    <Space
      direction="vertical"
      size="small"
      style={{
        width: "100%",
      }}
    >
      <div>
        Embed token :
        <Input disabled value={report.publicToken} />
      </div>
      <div>
        Head over to our{" "}
        <a href="https://docs.whaly.io/embedding/embedding-api">
          embed documentation
        </a>{" "}
        to learn more about dashboard embedding
      </div>
    </Space>
  ),
});

export const generateTileTitle = (
  query: ILagoonQuery,
  exploration: IExploration
) => {
  const availableMetrics = exploration.tables.flatMap((t) =>
    t.metrics.map((m) => ({
      key: `${t.cubeName}.${m.cubeName}`,
      label: m.name,
    }))
  );
  const availableDimensions = exploration.tables.flatMap((t) =>
    t.dimensions.map((d) => ({
      key: `${t.cubeName}.${d.cubeName}`,
      label: d.name,
    }))
  );

  const m = availableMetrics
    .filter((a) => query.selectedMeasures.includes(a.key))
    .map((a) => a.label)
    .join(", ");

  const d = availableDimensions
    .filter((a) => query.selectedDimensions.includes(a.key))
    .map((a) => a.label)
    .join(", ");

  return `${m}${d ? ` by ${d}` : ``}`;
};
