import type { ISource } from "../../../../../interfaces/sources";
import { getSourceStatus } from "../../../../workspace/domain";
import "./ConnectorStatusRenderer.scss";

interface IConnectorExecutionStatusRendererProps {
  connector: ISource;
}

export function ConnectorExecutionStatusRenderer(
  props: IConnectorExecutionStatusRendererProps
) {
  const { connector } = props;
  return (
    <div
      className={`connector-status-renderer-dot connector-status-renderer-dot-${getSourceStatus(
        connector
      )}`}
    >
      &#9679;
    </div>
  );
}
