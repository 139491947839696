import { Card, Typography } from "antd";
import { compose } from "../../../../../../../../components/compose/WlyCompose";
import Feednack from "../../../../../../../../components/layout/feedback/feedback";
import type { IObject } from "../../../../../../../../interfaces/object";
import type { InjectedOrgProps } from "../../../../../../../orgs/WithOrg";
import WithOrg from "../../../../../../../orgs/WithOrg";
import { type IRecord } from "../../../../domain";
import type { IWidget } from "../../../domain";
import { type IWidgetSuggestedProductsConfig } from "../domain";
import { SuggestedProductsLoader } from "./SuggestedProductsLoader";

interface IRecordSuggestedProductsProps {
  widget: IWidget;
  object: IObject;
  record: IRecord;
  conf: IWidgetSuggestedProductsConfig;
  edit?: boolean;
}

type Props = InjectedOrgProps & IRecordSuggestedProductsProps;

function RecordSuggestedProducts(props: Props) {
  const { conf, record, widget, object, edit, org, user } = props;

  const opt = conf.options;

  if (!conf.foreignObjectPropertyId || !opt?.productKey) {
    return <Feednack>Please configure the widget</Feednack>;
  }

  const foreignProperty = object.foreignKeys.find(
    (p) => p.id === conf.foreignObjectPropertyId
  );

  if (!foreignProperty) {
    return (
      <Feednack>
        <>Missing foreign property</>
      </Feednack>
    );
  }

  const foreignObject = foreignProperty.object;

  if (!foreignProperty) {
    return (
      <Feednack>
        <>Missing foreign object</>
      </Feednack>
    );
  }

  if (edit) {
    return (
      <>
        {conf.options?.categoryKey ? (
          <Typography.Title level={5}>Category name (1)</Typography.Title>
        ) : undefined}
        <Card style={{ width: 360 }}>Product name</Card>
      </>
    );
  }

  return (
    <SuggestedProductsLoader
      widget={widget}
      object={object}
      foreignObject={foreignObject}
      record={record}
      conf={conf}
      parsedConf={{
        labelKey: opt.productKey,
        categoryKey: conf.options?.categoryKey,
        badgeKey: conf.options?.badgeLabel?.[0],
        primaryText: conf.options?.primaryText,
        secondaryText: conf.options?.secondaryText,
        fallbackImageSrc: conf.options?.fallbackImageSrc,
        displayedAction: conf.options?.displayedAction,
      }}
      org={org}
      user={user}
    />
  );
}

export default compose<Props, IRecordSuggestedProductsProps>(WithOrg)(
  RecordSuggestedProducts
);
