import Icon from "@ant-design/icons";
import type { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";

export const WlyLineChartIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon
    component={() => (
      <svg
        width="1em"
        height="1em"
        viewBox="0 0 213 213"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_1008_1274)">
          <path
            d="M-12 147.5L46.3974 68.6031L78.8256 112.267L105.766 78.0305L134.702 93.9084L160.146 53.2214L192.574 58.6794L217.5 -13.5"
            stroke="#3A5C83"
            strokeWidth="14"
          />
          <path
            d="M0.5 -9L43 188.5L83.5 165.5L104.5 188.5L152 165.5L193 205L224.5 165.5"
            stroke="#FD6348"
            strokeWidth="14"
          />
        </g>
        <defs>
          <clipPath id="clip0_1008_1274">
            <rect width="213" height="213" fill="white" />
          </clipPath>
        </defs>
      </svg>
    )}
    {...props}
  />
);
