import { Col, Row } from "antd";
import Aligner from "../../../components/layout/aligner/Aligner";
import SettingsWrapper from "../SettingsWrapper";
import ServiceAccountManagementTableWrapper from "./ServiceAccountManagementTableWrapper";

interface IServiceAccountManagementPageProps {}

export function ServiceAccountManagementPage(
  props: IServiceAccountManagementPageProps
) {
  return (
    <SettingsWrapper>
      <Aligner>
        <Row justify="center" className="access-management">
          <Col xs={24} sm={24} md={20} lg={20} xl={20}>
            <ServiceAccountManagementTableWrapper />
          </Col>
        </Row>
      </Aligner>
    </SettingsWrapper>
  );
}
