import { Space, Typography } from "antd";
import UserAvatar from "../../../../../components/user/avatar/UserAvatar";
import type { INotificationJob } from "../../../../../interfaces/notification";
import { getJobMessageSubtitle, getJobMessageTitle } from "./domain";

interface IJobMessageProps {
  job: INotificationJob;
}

export function JobMessage(props: IJobMessageProps) {
  const { job } = props;
  return (
    <Space direction="vertical" size={"small"} style={{ display: "flex" }}>
      <Space>
        <UserAvatar size={"small"} user={job.user} tooltip />
        <Typography.Text strong>{getJobMessageTitle(job)}</Typography.Text>
      </Space>
      <Typography.Text type="secondary">
        {getJobMessageSubtitle(job)}
      </Typography.Text>
    </Space>
  );
}
