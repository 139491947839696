import * as React from "react";

import "./CatalogItemDetailHeader.scss";

interface ICatalogItemDetailHeaderProps {
  name: string;
  logo: string;
  tagline?: string;
  children?: React.ReactNode;
}

export default class CatalogItemDetailHeader extends React.Component<ICatalogItemDetailHeaderProps> {
  public render() {
    const { logo, name, tagline } = this.props;
    return (
      <div className="catalog-item-detail-header">
        <div className="header">
          <div className="wrapper">
            <div className="logo">
              <img alt={name} src={logo} />
            </div>
            <div className="title">
              <div className="main">{name}</div>
              <div className="sub">{tagline}</div>
            </div>
          </div>
        </div>
        {this.props.children}
      </div>
    );
  }
}
