import Icon from "@ant-design/icons";
import type { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";

export const WlyTableChartIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon
    component={() => (
      <svg
        width="1em"
        height="1em"
        viewBox="0 0 213 213"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="213" height="39" fill="#D9D9D9" />
        <rect x="8" y="53" width="68" height="25" rx="12.5" fill="#D9D9D9" />
        <rect x="8" y="96" width="68" height="25" rx="12.5" fill="#D9D9D9" />
        <rect x="8" y="138" width="68" height="25" rx="12.5" fill="#D9D9D9" />
        <rect x="8" y="181" width="68" height="25" rx="12.5" fill="#D9D9D9" />
        <rect x="82" y="53" width="48" height="25" rx="12.5" fill="#D9D9D9" />
        <rect x="157" y="53" width="48" height="25" rx="12.5" fill="#D9D9D9" />
        <rect x="8" y="8" width="68" height="23" rx="11.5" fill="#5D6671" />
        <rect x="82" y="8" width="48" height="23" rx="11.5" fill="#5D6671" />
        <rect x="144" y="8" width="61" height="23" rx="11.5" fill="#5D6671" />
        <rect x="157" y="96" width="48" height="25" rx="12.5" fill="#D9D9D9" />
        <rect x="157" y="138" width="48" height="25" rx="12.5" fill="#D9D9D9" />
        <rect x="157" y="181" width="48" height="25" rx="12.5" fill="#D9D9D9" />
        <rect x="82" y="96" width="56" height="25" rx="12.5" fill="#D9D9D9" />
        <rect x="82" y="138" width="56" height="25" rx="12.5" fill="#D9D9D9" />
        <rect x="82" y="181" width="38" height="25" rx="12.5" fill="#D9D9D9" />
      </svg>
    )}
    {...props}
  />
);
