import { Button, Card, Drawer, Empty, Form, List, Switch } from "antd";
import * as React from "react";
import type { RouteComponentProps } from "react-router";
import { withRouter } from "react-router";
import type { InjectedAntUtilsProps } from "../../../../components/ant-utils/withAntUtils";
import { withAntUtils } from "../../../../components/ant-utils/withAntUtils";
import { compose } from "../../../../components/compose/WlyCompose";
import FormActions from "../../../../components/form/actions/FormActions";
import Feednack from "../../../../components/layout/feedback/feedback";
import Loading from "../../../../components/layout/feedback/loading";
import type { AsyncData } from "../../../../helpers/typescriptHelpers";
import type { IExploration } from "../../../../interfaces/explorations";
import type { IReport } from "../../../../interfaces/reports";
import { IUserRoleType } from "../../../../interfaces/user";
import { routeDescriptor } from "../../../../routes/routes";
import GraphQLService from "../../../../services/graphql/GraphQLService";
import { TABLE_WITHOUT_BRIZO_FRAGMENT } from "../../../exploration/single/domain";
import type { InjectedOrgProps } from "../../../orgs/WithOrg";
import WithOrg from "../../../orgs/WithOrg";
import HasRoleAccess from "../../../user-settings/HasRoleAccess";
import { FILTER_ARRAY_SEPARATOR, getFilterDefaultValue } from "../../domain";

import "./Share.scss";
import type { ISharingLinkInitalData } from "./ShareModal";
import ShareModal from "./ShareModal";

interface IShareModalProps {
  reportId: string;
  visible: boolean;
  setVisible: (v: boolean) => void;
}

type Props = IShareModalProps &
  InjectedAntUtilsProps &
  RouteComponentProps<{ organizationSlug: string }> &
  InjectedOrgProps;

const ShareDrawer: React.FunctionComponent<Props> = (props) => {
  const { antUtils } = props;
  const [form] = Form.useForm();
  const [opened, setOpened] = React.useState<boolean | ISharingLinkInitalData>(
    false
  );
  const [saving, setSaving] = React.useState<boolean>(false);
  const [data, setData] = React.useState<
    AsyncData<{ report: IReport; explorations: IExploration[] }>
  >({ status: "initial" });

  React.useEffect(() => {
    if (opened === false && props.visible === true) {
      setData({ status: "loading" });
      GraphQLService<{ Report: IReport; allExplorations: IExploration[] }>(
        `
    ${TABLE_WITHOUT_BRIZO_FRAGMENT}

    query getReportSharedStatus($reportId: ID!, $orgId: ID!) {
      Report(where: {id: $reportId}) {
        id
        isPublicShared
        publicToken
        filters(where: { deleted_not: true }) {
            id
            name
            defaultValue
            labelDimension
            valueDimension
            useDefaultTimeField
            componentType
            requireValue
            type
            filterLimit
        }
        sharingLinks(where: { deleted_not: true }) {
            id
            name
            publicToken
            isPasswordProtected
            disableDrills
            password
            controlledValues(where: { deleted_not: true }) {
                id
                filter {
                    id
                }
                value
            }
        }
      }
      allExplorations(where:{AND: [{org: {id: $orgId}}, {deleted_not: true}]}) {
        id
        slug
        name
        description
        tables(where: {deleted_not: true}) {
          ...TableCoreQuery
        }
      }
    }
    `,
        {
          reportId: props.reportId,
          orgId: props.org.id,
        }
      )
        .then((r) => {
          setData({
            status: "success",
            data: {
              report: {
                ...r.Report,
                filters: r.Report.filters.map((f) => {
                  return {
                    ...f,
                    defaultValue: getFilterDefaultValue(
                      f,
                      props.userAttributes
                    ),
                    rawDefaultValue: f.defaultValue as any,
                  };
                }),
                sharingLinks: r.Report.sharingLinks.map((s) => {
                  return {
                    ...s,
                    controlledValues: s.controlledValues.map((c) => {
                      return {
                        ...c,
                        value: c.value
                          ? (c.value as any)
                              .split(FILTER_ARRAY_SEPARATOR)
                              .filter((v) => v)
                          : [],
                      };
                    }),
                  };
                }),
              },
              explorations: r.allExplorations,
            },
          });
        })
        .catch((err) => {
          setData({ status: "error", error: err });
        });
    }
  }, [props.visible, props.reportId, opened]);

  const onSave = (values: IReport) => {
    setSaving(true);
    if (data.status === "success") {
      return GraphQLService(
        `
      mutation updateReportPublicSettings($reportId: ID!, $isPublicShared: Boolean!) {
        updateReport(id: $reportId, data: {  isPublicShared: $isPublicShared }) {
          id
        }
      }
    `,
        {
          reportId: data.data.report.id,
          isPublicShared: values.isPublicShared,
        }
      ).then(() => {
        setSaving(false);
      });
    } else {
      return Promise.resolve();
    }
  };

  const renderEmbedTokenCard = () => {
    if (data.status === "initial" || data.status === "loading") {
      return <Loading />;
    } else if (data.status === "error") {
      return <Feednack>{data.error.message}</Feednack>;
    }

    if (!data.data.report.isPublicShared) {
      return null;
    }

    return (
      <HasRoleAccess accessLevel={IUserRoleType.ADMIN_BUILDER}>
        <Card style={{ width: "100%" }}>
          <Form
            form={form}
            layout="vertical"
            initialValues={{
              isPublicShared: data.data.report.isPublicShared,
              embedToken: data.data.report.publicToken,
            }}
            onFieldsChange={async (c) => {
              if (
                JSON.stringify(c[0].name) === JSON.stringify(["isPublicShared"])
              ) {
                try {
                  await onSave({ isPublicShared: c[0].value } as IReport);
                } catch {
                  form.setFieldsValue({
                    isPublicShared: !c[0].value,
                  });
                }
              }
            }}
          >
            <Form.Item noStyle={true} shouldUpdate={true}>
              {() => {
                if (saving === true) {
                  return <Loading />;
                }
                if (form.getFieldValue("isPublicShared")) {
                  return (
                    <>
                      <Form.Item
                        name="isPublicShared"
                        label="Public Link"
                        valuePropName="checked"
                      >
                        <Switch />
                      </Form.Item>
                      <Button
                        onClick={() => {
                          navigator.clipboard.writeText(
                            `${
                              window.WHALY_CONSTANTS.APP_URL
                            }${routeDescriptor.publicLinkReport.renderRoute({
                              organizationSlug:
                                props.match.params.organizationSlug,
                              publicToken: data.data.report.publicToken,
                            })}`
                          );
                          antUtils.message.info(
                            "Public Link copied to your clipboard"
                          );
                        }}
                      >
                        Get Link
                      </Button>
                    </>
                  );
                }
              }}
            </Form.Item>
          </Form>
        </Card>
      </HasRoleAccess>
    );
  };

  const renderInner = () => {
    if (data.status === "initial" || data.status === "loading") {
      return <Loading />;
    }
    if (data.status === "error") {
      return <Feednack>{JSON.stringify(data.error)}</Feednack>;
    }

    return data.data.report.sharingLinks.length ? (
      <List
        style={{ marginTop: 24 }}
        dataSource={data.data.report.sharingLinks}
        bordered={true}
        renderItem={(i, index) => {
          return (
            <List.Item
              actions={[
                <span
                  key={"get"}
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    navigator.clipboard.writeText(
                      `${
                        window.WHALY_CONSTANTS.APP_URL
                      }${routeDescriptor.sharingLinkReport.renderRoute({
                        organizationSlug: props.match.params.organizationSlug,
                        publicToken: data.data.report.publicToken,
                        sharingToken: i.publicToken,
                      })}`
                    );
                    antUtils.message.info(
                      "Sharing Link copied to your clipboard"
                    );
                  }}
                >
                  get link
                </span>,
                <span
                  key={"edit"}
                  style={{ cursor: "pointer" }}
                  onClick={() => setOpened({ ...i } as ISharingLinkInitalData)}
                >
                  edit
                </span>,
              ]}
            >
              {i.name}
            </List.Item>
          );
        }}
      />
    ) : (
      <div>
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description={
            <div>
              <div>You haven't set up a sharing link yet</div>
              <div>
                <Button type="link" onClick={() => setOpened(true)}>
                  New
                </Button>
              </div>
            </div>
          }
        />
      </div>
    );
  };

  return (
    <Drawer
      title="Share your report"
      open={props.visible}
      footer={null}
      maskClosable={true}
      onClose={() => props.setVisible(false)}
      destroyOnClose={true}
      width={500}
    >
      {renderEmbedTokenCard()}
      {renderInner()}
      <ShareModal
        filters={data.status === "success" ? data.data.report.filters : []}
        reportId={props.reportId}
        visible={opened}
        setVisible={setOpened}
        explorations={data.status === "success" ? data.data.explorations : []}
      />
      <FormActions
        submitText={"New sharing link"}
        isSubmitting={false}
        onSubmit={() => setOpened(true)}
      />
    </Drawer>
  );
};

export default compose<Props, IShareModalProps>(
  withRouter,
  WithOrg,
  withAntUtils
)(ShareDrawer);
