import * as React from "react";
import "./Typography.scss";

interface ISubtitleProps {
  children?: React.ReactNode;
}

export default class Subtitle extends React.Component<ISubtitleProps> {
  public render() {
    return <span className="typography subtitle">{this.props.children}</span>;
  }
}
