import { Decoration, MatchDecorator, ViewPlugin } from "@codemirror/view";

const matchDecorator = new MatchDecorator({
  regexp: /^ +/gm,
  decoration: (match) =>
    Decoration.mark({
      attributes: {
        class: "cm-highlightedSpaces",
        "data-display": "·".repeat(match[0].length),
      },
    }),
  boundary: /\S/,
});

export const showLeadingWhitespaces = ViewPlugin.define(
  (view) => ({
    decorations: matchDecorator.createDeco(view),
    update(viewUpdate) {
      this.decorations = matchDecorator.updateDeco(
        viewUpdate,
        this.decorations
      );
    },
  }),
  {
    decorations: (view) => view.decorations,
  }
);
