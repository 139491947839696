import type { RouteComponentProps } from "react-router";
import { withRouter } from "react-router";
import { compose } from "../../../../components/compose/WlyCompose";
import Aligner from "../../../../components/layout/aligner/Aligner";
import Feednack from "../../../../components/layout/feedback/feedback";
import type { InjectedOrgProps } from "../../../orgs/WithOrg";
import WithOrg from "../../../orgs/WithOrg";
import type { IV2Context } from "../layout/domain";
import ObjectPageWrapper from "./viewer/ObjectPageWrapper";

interface IObjectPageContainerProps {
  edit?: boolean;
  context: IV2Context;
}

type Props = RouteComponentProps<{
  organizationSlug: string;
  objectSlug: string;
}> &
  IObjectPageContainerProps &
  InjectedOrgProps;

function ObjectPageContainer(props: Props) {
  const {
    match: {
      params: { objectSlug },
    },
    context: { allObjects },
    location: { search },
  } = props;

  const object = allObjects.find((o) => o.slug === objectSlug);

  if (!object) {
    return (
      <Aligner>
        <Feednack>
          <div>You don't seem to have access to this object.</div>
          <div> If you think this is a mistake, please contact your admin.</div>
        </Feednack>
      </Aligner>
    );
  }

  return <ObjectPageWrapper key={objectSlug} objectSlug={objectSlug} />;
}

export default compose<Props, IObjectPageContainerProps>(
  withRouter,
  WithOrg
)(ObjectPageContainer);
