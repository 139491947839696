import { Modal, Table } from "antd";
import type { InjectedAntUtilsProps } from "../../../../components/ant-utils/withAntUtils";
import { withAntUtils } from "../../../../components/ant-utils/withAntUtils";
import { compose } from "../../../../components/compose/WlyCompose";
import type { ISchedule } from "../../../../interfaces/schedule";
import type { ITable } from "../../../../interfaces/table";
import { LocaleService } from "../../../../services/localeService";
import type { InjectedOrgProps } from "../../../orgs/WithOrg";
import WithOrg from "../../../orgs/WithOrg";

interface IQueryModeStorageCardProps {
  tables: ITable[];
  extractSchedule: ISchedule;
  visible: boolean;
  onClose: () => void;
}

type Props = IQueryModeStorageCardProps &
  InjectedOrgProps &
  InjectedAntUtilsProps;

function QueryModeStorageCard(props: Props) {
  const {
    tables,
    extractSchedule,
    user: { locale },
    visible,
    onClose,
  } = props;

  const localeService = new LocaleService(locale);
  const numeral = localeService.getNumberDefaultFormatting();

  const renderInner = (): JSX.Element => {
    if (!extractSchedule) {
      return (
        <>
          No extracts are scheduled, this is not expected, please reach out to
          your support contact to fix this issue.
        </>
      );
    }
    const renderCurrentExtractInfo = (): JSX.Element => {
      const tablesWithActiveExtract = tables.filter(
        (tbl) => !!tbl.activeExtract
      );
      if (tablesWithActiveExtract.length === 0) {
        return <p>No active extracts in Serving Layer</p>;
      }
      const columns = [
        {
          title: "Table",
          dataIndex: "table_name",
          key: "table_name",
        },
        {
          title: "Number of rows",
          dataIndex: "row_count",
          key: "row_count",
        },
        {
          title: "Storage",
          dataIndex: "storage",
          key: "storage",
        },
      ];
      const dataSource = tablesWithActiveExtract.map((tbl) => {
        return {
          key: tbl.id,
          table_name: tbl.name,
          row_count: numeral(tbl.activeExtract.rowCount).format("0,0"),
          storage: numeral(tbl.activeExtract.sizeBytes).format("0.00b"),
        };
      });
      return (
        <Table pagination={false} dataSource={dataSource} columns={columns} />
      );
    };
    return <>{renderCurrentExtractInfo()}</>;
  };

  return (
    <Modal
      open={visible}
      title={"Serving Layer Storage details"}
      maskClosable={true}
      footer={null}
      okText={"Close"}
      onCancel={onClose}
      width={"50%"}
      closable={true}
    >
      {renderInner()}
    </Modal>
  );
}

export default compose<Props, IQueryModeStorageCardProps>(
  WithOrg,
  withAntUtils
)(QueryModeStorageCard);
