import { ExclamationCircleOutlined, MoreOutlined } from "@ant-design/icons";
import type { MenuProps } from "antd";
import { Alert, Button, Col, Drawer, Dropdown, Row } from "antd";
import type { ColumnProps } from "antd/lib/table";
import { inject, observer } from "mobx-react";
import * as React from "react";
import type { RouteComponentProps } from "react-router";
import { withRouter } from "react-router";
import type { InjectedAntUtilsProps } from "../../../../components/ant-utils/withAntUtils";
import { withAntUtils } from "../../../../components/ant-utils/withAntUtils";
import { compose } from "../../../../components/compose/WlyCompose";
import Aligner from "../../../../components/layout/aligner/Aligner";
import CardTable from "../../../../components/table/CardTable";
import type { IPartnerPortal } from "../../../../interfaces/org";
import { IOrgFeatureType } from "../../../../interfaces/org";
import { IUserRoleType } from "../../../../interfaces/user";
import { routeDescriptor } from "../../../../routes/routes";
import GraphQLService from "../../../../services/graphql/GraphQLService";
import type { UserStoreProps } from "../../../../store/userStore";
import type { InjectedOrgProps } from "../../../orgs/WithOrg";
import WithOrg from "../../../orgs/WithOrg";
import HasRoleAccess, {
  hasRoleAccessBoolean,
} from "../../../user-settings/HasRoleAccess";
import PartnerPortalForm from "./PartnerPortalForm";

interface IPartnerPortalTableProps {
  portals: IPartnerPortal[];
  rerender: () => void;
}

interface IState {
  visible: boolean;
  selectedPortal?: IPartnerPortal;
}

type Props = IPartnerPortalTableProps &
  RouteComponentProps<{ organizationSlug: string }> &
  UserStoreProps &
  InjectedOrgProps &
  InjectedAntUtilsProps;

class PartnerPortalTable extends React.Component<Props, IState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      visible: false,
    };
  }

  public showDrawer = () => {
    this.setState({ visible: true });
  };

  public hideDrawer = () => {
    this.setState({ visible: false, selectedPortal: undefined });
  };

  public onSubmit = () => {
    return this.props.rerender();
  };

  public deletePortal = (portal: IPartnerPortal) => {
    const {
      history,
      match: {
        params: { organizationSlug },
      },
      antUtils,
    } = this.props;
    return antUtils.modal.confirm({
      title: `Are your sure you want to delete this portal?`,
      icon: <ExclamationCircleOutlined />,
      content: `By deleting this portal, partners will be automatically disconnected from it and won't be able to log in again.`,
      okText: "Delete",
      okButtonProps: {
        danger: true,
      },
      cancelText: "Cancel",
      onOk: async () => {
        try {
          await GraphQLService(
            `
          mutation deletePortal(
            $portalId: ID!,
          ) {
            updatePartnerPortal(id: $portalId, data: {isDeleted: true}) {
              id
            }
          }`,
            { portalId: portal.id }
          );
          await this.onSubmit();
          antUtils.message.success("Portal successfully deleted");
        } catch (err) {
          antUtils.message.error("An unexpected error happened, please retry");
        }
      },
      onCancel() {
        return null;
      },
    });
  };

  public generateColumns = (
    connectedUserRoleType: IUserRoleType
  ): ColumnProps<IPartnerPortal>[] => [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (v, s) => {
        const {
          match: { params },
          history,
        } = this.props;
        return (
          <a
            onClick={() =>
              history.push(
                routeDescriptor["settingsPartnerPortalDetails"].renderRoute({
                  ...params,
                  partnerPortalSlug: s.slug,
                })
              )
            }
            style={{ display: "flex" }}
          >
            <div>{s.name}</div>
          </a>
        );
      },
    },
    ...(connectedUserRoleType === IUserRoleType.ADMIN_BUILDER
      ? [
          {
            title: "Actions",
            key: "Actions",
            align: "right" as const,
            render: (v, s: IPartnerPortal) => {
              const portal = s;

              const menu: MenuProps = {
                items: [
                  {
                    key: `edit-portal-${portal.id}`,
                    onClick: () =>
                      this.setState({
                        visible: true,
                        selectedPortal: portal,
                      }),
                    label: "Edit",
                  },
                  {
                    key: `delete-portal-${portal.id}`,
                    onClick: () => this.deletePortal(portal),
                    label: "Delete",
                    danger: true,
                  },
                ],
              };

              return (
                <HasRoleAccess accessLevel={IUserRoleType.ADMIN_BUILDER}>
                  <Dropdown
                    trigger={["click"]}
                    placement="bottomRight"
                    menu={menu}
                  >
                    <Button
                      type="text"
                      shape="circle"
                      disabled={
                        !this.props.orgFeatures.includes(
                          IOrgFeatureType.PARTNER_PORTAL_SYNC_FEATURE_API_NAME
                        )
                      }
                      icon={<MoreOutlined />}
                    />
                  </Dropdown>
                </HasRoleAccess>
              );
            },
          },
        ]
      : []),
  ];

  public render() {
    const {
      portals,
      user: connectedUser,
      org,
      role: connectedUserRole,
    } = this.props;
    return (
      <Aligner>
        <Row justify="center" className="group-management">
          {!this.props.orgFeatures.includes(
            IOrgFeatureType.PARTNER_PORTAL_SYNC_FEATURE_API_NAME
          ) ? (
            <Col xs={24} sm={24} md={20} lg={20} xl={20}>
              <Alert
                style={{ width: "100%", marginBottom: 24 }}
                message={
                  <div style={{ display: "flex" }}>
                    <div>
                      Your plan doesn't have access to this feature yet.
                    </div>
                  </div>
                }
                type="warning"
              />
            </Col>
          ) : undefined}
          <Col xs={24} sm={24} md={20} lg={20} xl={20}>
            <CardTable<IPartnerPortal>
              cardTitle={<div className="title">Partner Portals</div>}
              actionButtons={
                hasRoleAccessBoolean(
                  IUserRoleType.ADMIN_BUILDER,
                  connectedUser,
                  org.id
                ) ? (
                  <Button
                    disabled={
                      !this.props.orgFeatures.includes(
                        IOrgFeatureType.PARTNER_PORTAL_SYNC_FEATURE_API_NAME
                      )
                    }
                    onClick={this.showDrawer}
                    type="primary"
                  >
                    Create new Portal
                  </Button>
                ) : undefined
              }
              rowKey="id"
              dataSource={portals}
              columns={this.generateColumns(connectedUserRole.role)}
              pagination={{
                size: "small",
                style: { display: "none" },
                defaultPageSize: 1000,
              }}
            />
          </Col>
          <Drawer
            width={"35%"}
            open={this.state.visible}
            closable={true}
            styles={{ body: { padding: "24px 24px 80px 24px" } }}
            keyboard={false}
            maskClosable={false}
            destroyOnClose={true}
            title={this.state.selectedPortal ? "Edit Portal" : "Create Portal"}
            onClose={this.hideDrawer}
          >
            <PartnerPortalForm
              org={org}
              selectedPortal={this.state.selectedPortal}
              onSubmit={this.onSubmit}
              onDrawerClose={this.hideDrawer}
            />
          </Drawer>
        </Row>
      </Aligner>
    );
  }
}

export default compose<Props, IPartnerPortalTableProps>(
  inject("userStore"),
  observer,
  withRouter,
  WithOrg,
  withAntUtils
)(PartnerPortalTable);
