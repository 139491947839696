import numeral from "numeral";
import { UserLocale } from "../interfaces/user";

type DAY =
  | "MONDAY"
  | "TUESDAY"
  | "WEDNESDAY"
  | "THURSDAY"
  | "FRIDAY"
  | "SATURDAY"
  | "SUNDAY";
type MONTH =
  | "JANUARY"
  | "FEBRUARY"
  | "MARCH"
  | "APRIL"
  | "MAY"
  | "JUNE"
  | "JULY"
  | "AUGUST"
  | "SEPTEMBER"
  | "OCTOBER"
  | "NOVEMBER"
  | "DECEMBER";

const englishWeekdays: { [key in DAY]: string } = {
  MONDAY: "Monday",
  TUESDAY: "Tuesday",
  WEDNESDAY: "Wednesday",
  THURSDAY: "Thursday",
  FRIDAY: "Friday",
  SATURDAY: "Saturday",
  SUNDAY: "Sunday",
};

const frenchWeekdays: { [key in DAY]: string } = {
  MONDAY: "Lundi",
  TUESDAY: "Mardi",
  WEDNESDAY: "Mercredi",
  THURSDAY: "Jeudi",
  FRIDAY: "Vendredi",
  SATURDAY: "Samedi",
  SUNDAY: "Dimanche",
};

const englishMonths: { [str in MONTH]: string } = {
  JANUARY: "January",
  FEBRUARY: "February",
  MARCH: "March",
  APRIL: "April",
  MAY: "May",
  JUNE: "June",
  JULY: "July",
  AUGUST: "August",
  SEPTEMBER: "September",
  OCTOBER: "October",
  NOVEMBER: "November",
  DECEMBER: "December",
};

const frenchMonths: { [str in MONTH]: string } = {
  JANUARY: "Janvier",
  FEBRUARY: "Fevrier",
  MARCH: "Mars",
  APRIL: "Avril",
  MAY: "Mai",
  JUNE: "Juin",
  JULY: "Juillet",
  AUGUST: "Août",
  SEPTEMBER: "Septembre",
  OCTOBER: "Octobre",
  NOVEMBER: "Novembre",
  DECEMBER: "Decembre",
};

const englishShortMonths: { [str in MONTH]: string } = {
  JANUARY: "Jan",
  FEBRUARY: "Feb",
  MARCH: "Mar",
  APRIL: "Apr",
  MAY: "May",
  JUNE: "June",
  JULY: "July",
  AUGUST: "Aug",
  SEPTEMBER: "Sept",
  OCTOBER: "Oct",
  NOVEMBER: "Nov",
  DECEMBER: "Déc",
};

const frenchShortMonths: { [str in MONTH]: string } = {
  JANUARY: "Jan",
  FEBRUARY: "Fev",
  MARCH: "Mars",
  APRIL: "Avr",
  MAY: "Mai",
  JUNE: "Juin",
  JULY: "Juil",
  AUGUST: "Août",
  SEPTEMBER: "Sept",
  OCTOBER: "Oct",
  NOVEMBER: "Nov",
  DECEMBER: "Déc",
};

numeral.register("locale", "fr", {
  delimiters: {
    thousands: " ",
    decimal: ",",
  },
  abbreviations: {
    thousand: "k",
    million: "m",
    billion: "b",
    trillion: "t",
  },
  ordinal: function (number) {
    return number === 1 ? "er" : "ème";
  },
  currency: {
    symbol: "€",
  },
});

export class LocaleService {
  locale: UserLocale;
  constructor(locale: UserLocale) {
    this.locale = locale;
  }

  // date capabilities
  getWeekday = (day: DAY) => {
    if (this.locale === UserLocale.fr_FR) {
      return frenchWeekdays[day];
    }
    return englishWeekdays[day];
  };

  getMonth = (month: MONTH) => {
    if (this.locale === UserLocale.fr_FR) {
      return frenchMonths[month];
    }
    return englishMonths[month];
  };

  getShortMonth = (month: MONTH) => {
    if (this.locale === UserLocale.fr_FR) {
      return frenchShortMonths[month];
    }
    return englishShortMonths[month];
  };

  // number capabilities
  getNumberDefaultFormatting = () => {
    if (this.locale === UserLocale.fr_FR) {
      numeral.locale("fr");
      return numeral;
    }
    numeral.locale("en");
    return numeral;
  };
}
