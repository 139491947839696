import { CopyOutlined } from "@ant-design/icons";
import { Input, message, Modal } from "antd";
import { useEffect, useState } from "react";
import type { InjectedAntUtilsProps } from "../../../components/ant-utils/withAntUtils";
import { withAntUtils } from "../../../components/ant-utils/withAntUtils";
import { compose } from "../../../components/compose/WlyCompose";
import Loading from "../../../components/layout/feedback/loading";
import type { AsyncData } from "../../../helpers/typescriptHelpers";
import type { IDataset } from "../../../interfaces/sources";
import GraphQLService from "../../../services/graphql/GraphQLService";

interface IDbtQueryModalProps {
  datasetId: string;
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
}
type Props = IDbtQueryModalProps & InjectedAntUtilsProps;

const gqlDbtQuery = `
  query getCurrentDatasetDbtQuery($datasetId: ID!) {
    Dataset(where: { id: $datasetId }) {
      dbtRenderedSql
    }
  }
`;

function DbtQueryModal(props: Props) {
  const { datasetId, isOpen, setIsOpen, antUtils } = props;
  const [dbtQuery, setDbtQuery] = useState<
    AsyncData<Pick<IDataset, "dbtRenderedSql">>
  >({ status: "initial" });

  useEffect(() => {
    const getDbtQuery = async () => {
      setDbtQuery({
        status: "loading",
      });
      try {
        const datasetQuery = await GraphQLService<{
          Dataset: Pick<IDataset, "dbtRenderedSql">;
        }>(gqlDbtQuery, {
          datasetId: datasetId,
        });
        setDbtQuery({
          status: "success",
          data: datasetQuery.Dataset,
        });
      } catch (error) {
        console.error(error);
        message.error("Error loading dbt query");
        setDbtQuery({ status: "error", error: error });
      }
    };

    if (isOpen) {
      getDbtQuery();
    }
  }, [datasetId, isOpen]);

  return (
    <Modal
      open={isOpen}
      onCancel={() => setIsOpen(false)}
      title="dbt query"
      width={500}
      okText="Copy to clipboard"
      okButtonProps={{
        icon: <CopyOutlined />,
      }}
      onOk={async () => {
        if (dbtQuery.status === "success") {
          await navigator.clipboard.writeText(dbtQuery.data.dbtRenderedSql);
          antUtils.message.success("Copied!");
        }
        setIsOpen(false);
      }}
    >
      {dbtQuery.status === "initial" && <Loading />}
      {dbtQuery.status === "loading" && <Loading />}
      {dbtQuery.status === "success" && (
        <Input.TextArea
          value={dbtQuery.data.dbtRenderedSql}
          style={{ height: 100, resize: "none" }}
          disabled
        />
      )}
      {dbtQuery.status === "error" &&
        "There was an error retrieving your query"}
    </Modal>
  );
}

export default compose<Props, IDbtQueryModalProps>(withAntUtils)(DbtQueryModal);
