import { useState } from "react";

type Props = {
  children?: React.ReactNode;
};

export default function Crosshair({ children }: Props) {
  const [position, setPosition] = useState<
    | {
        left: number;
        height: number;
      }
    | undefined
  >(undefined);

  const onEvent = (
    event: React.WheelEvent<HTMLDivElement> | React.MouseEvent<HTMLDivElement>
  ) => {
    const elements = document.elementsFromPoint(event.clientX, event.clientY);
    const tableElement = elements.find((el) =>
      el.classList.contains("ag-body")
    );
    const tableIndex = elements.findIndex((el) =>
      el.classList.contains("ag-body")
    );
    const chartElement = elements.find(
      (el, i) =>
        el.classList.contains("object-table-show-crosshair") && i < tableIndex
    );
    const listElement = elements.find(
      (el, i) =>
        el.classList.contains("ag-center-cols-container") && i < tableIndex
    );
    const viewportElement = elements.find(
      (el, i) => el.classList.contains("ag-body-viewport") && i < tableIndex
    );
    if (tableElement && chartElement && listElement && viewportElement) {
      setPosition({
        left: event.clientX - tableElement.getBoundingClientRect().x,
        height: Math.min(
          listElement.getBoundingClientRect().height,
          viewportElement.getBoundingClientRect().height
        ),
      });
    } else {
      setPosition(undefined);
    }
  };

  return (
    <div
      style={{ height: "100%", position: "relative" }}
      onWheel={() => setPosition(undefined)}
      onMouseMove={onEvent}
    >
      {children}
      {typeof position === "object" && (
        <div
          style={{
            position: "absolute",
            left: position.left - 2,
            pointerEvents: "none",
            top: 62 + 1, // header height
            height: position.height,
            background: "#CD2020",
            opacity: 0.4,
            width: 2,
          }}
        />
      )}
    </div>
  );
}
