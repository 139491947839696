import { CloseOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  Divider,
  Layout,
  Row,
  Skeleton,
  Space,
  Typography,
} from "antd";
import Card from "../../../components/cards/Card";
import Aligner from "../../../components/layout/aligner/Aligner";

const { Sider } = Layout;

interface ITemplateListLoaderProps {
  onClose: () => void;
}

export function TemplateListLoader(props: ITemplateListLoaderProps) {
  const { onClose } = props;

  const items = [1, 2, 3, 4, 5, 6, 7, 8, 9];

  return (
    <>
      <div className={`catalog-modal-header`}>
        <div className={`catalog-modal-header-title`}>
          <Typography.Title style={{ marginBottom: 0 }} level={5}>
            Template Catalog
          </Typography.Title>
        </div>
        <div className={`catalog-modal-header-button`}>
          <Button
            onClick={onClose}
            type={"text"}
            shape="circle"
            icon={<CloseOutlined />}
          />
        </div>
      </div>
      <Divider style={{ margin: 0 }} />
      <Layout className="templates-page">
        <Sider width={255} className="menu">
          <div>
            <Skeleton.Button block size="small" active shape="round" />
          </div>
          <hr />
          <Space direction="vertical" size={16} style={{ width: "100%" }}>
            <Skeleton.Button block size="small" active shape="round" />
            <Skeleton.Button block size="small" active shape="round" />
            <Skeleton.Button block size="small" active shape="round" />
            <Skeleton.Button block size="small" active shape="round" />
          </Space>
        </Sider>
        <Layout id="content" className="content catalog-modal-content selected">
          <Aligner>
            <Row gutter={[16, 16]}>
              {items.map((template) => {
                return (
                  <Col xs={24} sm={12} md={12} lg={8} key={template} span={8}>
                    <Card className="template">
                      <div className="title">
                        <Skeleton.Button
                          shape="round"
                          style={{ width: "70%" }}
                          block
                          size="small"
                          active
                        />
                      </div>
                      <div className="description">
                        <Skeleton
                          paragraph={{
                            rows: 2,
                          }}
                          active
                          round
                        />
                      </div>
                      <div className="sources-meta">
                        <Skeleton.Avatar size="small" active />
                      </div>
                    </Card>
                  </Col>
                );
              })}
              <div style={{ width: "100%", marginTop: 24 }} />
            </Row>
          </Aligner>
        </Layout>
      </Layout>
    </>
  );
}
