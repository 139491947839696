import { Skeleton, Typography } from "antd";
import React from "react";
import { withRouter, type RouteComponentProps } from "react-router";
import { compose } from "../../../../../../../components/compose/WlyCompose";
import { type AsyncData } from "../../../../../../../helpers/typescriptHelpers";
import { type IObject } from "../../../../../../../interfaces/object";
import { EmbedType } from "../../../domain";
import type { IRecordWidgetSuccessData } from "../../domain";
import { type IRecordWidgetData } from "../../domain";
import { findAllDocuments, type IRecordDocument } from "../../panels/domain";

import { Link } from "react-router-dom";
import { routeDescriptor } from "../../../../../../../routes/routes";
import "./RecommendationCard.scss";

export interface IAnalysisCardProps {
  object: IObject;
  data: IRecordWidgetData;
  embedType?: EmbedType;
}

type Props = IAnalysisCardProps & RouteComponentProps<{ documentId?: string }>;

function AnalysisCard(props: Props) {
  const {
    object,
    data,
    match: { params },
    history,
    embedType,
  } = props;

  const [docs, setDocs] = React.useState<AsyncData<IRecordDocument[]>>({
    status: "initial",
  });

  React.useEffect(() => {
    if (data.status === "success") {
      try {
        setDocs({ status: "loading" });
        const d = findAllDocuments(
          object.id,
          data.data[`${object.table.cubeName}.id`] as string
        );
        setDocs({ status: "success", data: d });
      } catch (err) {
        setDocs({ status: "error", error: err });
      }
    }
  }, [
    object.id,
    data.status,
    (data as IRecordWidgetSuccessData)?.data?.[`${object.table.cubeName}.id`],
  ]);

  const renderInner = () => {
    if (docs.status === "loading" || docs.status === "initial") {
      return Array(3)
        .fill("")
        .map((_, i) => {
          return (
            <div key={i} className={`recommendation-card-items-item `}>
              <div className="recommendation-card-items-item-content">
                <div className="recommendation-card-items-item-content-title">
                  <Skeleton.Button size="small" active block shape="round" />
                </div>
                <div className="recommendation-card-items-item-content-subtitle">
                  <Skeleton.Button
                    size="small"
                    active
                    style={{ width: 32 }}
                    shape="round"
                  />
                </div>
              </div>
            </div>
          );
        });
    } else if (docs.status === "error") {
      return (
        <div>
          <Typography.Text type="danger">
            There was an error fetching analyses
          </Typography.Text>
        </div>
      );
    } else {
      if (docs.data.length === 0) {
        return (
          <div>
            <Typography.Text type="secondary">
              No analyses available
            </Typography.Text>
          </div>
        );
      }

      return docs.data.map((d) => {
        const renderRoute = () => {
          if (embedType === EmbedType.recordModal) {
            return routeDescriptor[
              "object-record-document-centered"
            ].renderRoute({
              ...params,
              documentId: d.documentId,
            });
          }
          return routeDescriptor["object-record-document"].renderRoute({
            ...params,
            documentId: d.documentId,
          });
        };

        return (
          <Link key={d.documentId} to={renderRoute()}>
            <div className={`recommendation-card-items-item clickable`}>
              <div className="recommendation-card-items-item-content">
                <div className="recommendation-card-items-item-content-title">
                  <Typography.Text>{d.name}</Typography.Text>
                </div>
                <div className="recommendation-card-items-item-content-subtitle">
                  <Typography.Text type="secondary">{d.name}</Typography.Text>
                </div>
              </div>
            </div>
          </Link>
        );
      });
    }
  };

  return (
    <div className="recommendation-card">
      <div className="recommendation-card-title">Analyses</div>
      <div className="recommendation-card-items">{renderInner()}</div>
    </div>
  );
}

export default compose<Props, IAnalysisCardProps>(withRouter)(AnalysisCard);
