import type { BinaryFilter, UnaryFilter } from "@cubejs-client/core";
import cuid from "cuid";
import type { ExtendedMeasureType } from "../../../../components/measures/measure-table/MeasureTable";
import type { AsyncData } from "../../../../helpers/typescriptHelpers";
import type { IObject, IObjectProperty } from "../../../../interfaces/object";
import type {
  IDataset,
  IDatasetRelationship,
} from "../../../../interfaces/sources";
import type {
  IDimension,
  IDimensionType,
  IMetric,
  IMetricExpression,
  IMetricType,
  IRelationshipType,
  ISemanticGroup,
  ITable,
  ITableRelationship,
} from "../../../../interfaces/table";
import type {
  DataType,
  SchemaResult,
} from "../../../../interfaces/transformations";
import type { ExplorationMeasureItemUsage } from "../domain";

const FAKE_PREFIX = "fake_";

export const generateFakeId = () => {
  return `${FAKE_PREFIX}${cuid()}`;
};

export const isFakeId = (id: string) => {
  return id.startsWith(FAKE_PREFIX);
};

export type SelectedItems =
  | Array<{ type: ExtendedMeasureType; id: string }>
  | {
      type: "add";
      object_type: "table" | "semanticGroup";
      table_id: string;
    }
  | {
      type: "add";
      object_type: "property";
      object_id: string;
    };

export type StaleElements = Array<ExplorationOperation>;

export type ExplorationOperation =
  | ExplorationTableCreateOperation
  | ExplorationMetricCreateOperation
  | ExplorationDimensionCreateOperation
  | ExplorationSemanticGroupCreateOperation
  | ExplorationDeleteOperation
  | ExplorationUpdateOperation
  | ObjectPropertyCreateOperation;

export type ExplorationTableCreateOperation = ExplorationGenericCreateOperation<
  "table",
  ITableFormInput
>;

export enum TableType {
  REGULAR = "REGULAR",
  BLENDED_WITH_DATASET = "BLENDED_WITH_DATASET",
}

export interface ITableFormInput {
  name: string;
  primaryKey: string;
  viewCubeName: string;
  viewId: string;
  datasetId: string;
  blendedDatasetIds?: string[];
  incomingRelationship: {
    id: string;
    from: string;
    to: string;
    type: IRelationshipType;
    parentId: string;
  };
  drills?: IDrillType;
}

export interface IMetricFormInput {
  description?: string;
  expression: IMetricExpression;
  columnName?: string;
  operator?: "or" | "and";
  condition?: (BinaryFilter | UnaryFilter)[];
  overrideFormatting?: string;
  overrideName?: string;
  prefix?: string;
  suffix?: string;
  format?: IMetricType;
  hidden?: boolean;
  drills?: IDrillType;
  semanticGroupIds?: string[];
  hierarchyPath?: string;
}

export type IDrillType =
  | {
      type: "CUSTOM";
      values: string[];
    }
  | {
      type: "DISABLED" | "INHERIT";
    };

export interface IDimensionFormInput {
  type: IDimensionType;
  description?: string;
  overrideName?: string;
  columnDomain?: DataType;
  columnName?: string;
  latitude?: string;
  longitude?: string;
  hidden?: boolean;
  semanticGroupIds?: string[];
  customOrderingConfArray?: string[];
}

export interface ISemanticGroupFormInput {
  name: string;
}

export type ExplorationMetricCreateOperation =
  ExplorationGenericCreateOperation<"metric", IMetricFormInput>;

export type ExplorationDimensionCreateOperation =
  ExplorationGenericCreateOperation<"dimension", IDimensionFormInput>;

export type ExplorationSemanticGroupCreateOperation =
  ExplorationGenericCreateOperation<"semanticGroup", ISemanticGroupFormInput>;

export type ObjectPropertyCreateOperation = ExplorationGenericCreateOperation<
  "property",
  IObjectPropertyFormInput
>;

interface ExplorationGenericCreateOperation<ObjectType, T> {
  operationId: string;
  type: ExplorationCreateType;
  objectType: ObjectType;
  parentTableId: string;
  data: T;
}

export interface ExplorationUpdateOperation {
  operationId: string;
  type: ExplorationUpdateType;
  objectType:
    | "table"
    | "dimension"
    | "metric"
    | "semanticGroup"
    | "object"
    | "property";
  objectId: string;
  data: any;
}

interface ExplorationDeleteOperation {
  operationId: string;
  type: ExplorationDeleteType;
  objectType:
    | "table"
    | "dimension"
    | "metric"
    | "semanticGroup"
    | "property"
    | "object";
  objectId: string;
}

type ExplorationDeleteType = "delete";
type ExplorationCreateType = "create";
type ExplorationUpdateType = "update";

export type IMeasureSemanticGroup = Pick<ISemanticGroup, "id" | "name"> & {} & {
  rawData: ISemanticGroupFormInput;
} & ISemanticGroupState;

export type IMeasureTable = Pick<ITable, "id" | "name" | "cubeName"> & {
  metrics: IMeasureMetric[];
  dimensions: IMeasureDimension[];
  incomingRelationship?: IMeasureIncomingRelationship;
  schema: AsyncData<SchemaResult>;
  drills: IDrillType;
  semanticGroups: IMeasureSemanticGroup[];
} & ITableFormInput &
  IMeasureState;

export type IMeasureIncomingRelationship = Pick<
  ITableRelationship,
  "id" | "from" | "to" | "type"
> & {
  parentId: string;
} & IMeasureState;

export type IMeasureDimension = Pick<
  IDimension,
  "id" | "name" | "cubeName" | "description" | "hidden"
> & {
  rawData: IDimensionFormInput;
} & IMeasureState &
  IMeasureUsage;

export type IMeasureMetric = Pick<
  IMetric,
  "id" | "name" | "cubeName" | "description" | "hidden" | "hierarchyPath"
> & {
  rawData: IMetricFormInput;
} & {
  subType?: "COMPUTED";
} & IMeasureState &
  IMeasureUsage;

interface ISemanticGroupState {
  status?: ExplorationMeasureStatus;
}

interface IMeasureState {
  status?: ExplorationMeasureStatus;
  hasError?: string[];
}

interface IMeasureUsage {
  usage: ExplorationMeasureItemUsage[];
  rowLevelAccess: boolean;
}

export type ExplorationMeasureStatus =
  | ExplorationUpdateType
  | ExplorationCreateType
  | ExplorationDeleteType;

export type MeasureValidationFunction<T> = (
  data: T,
  schema: AsyncData<SchemaResult>,
  allDatasets: IDataset[],
  allDatasetRelationships: IDatasetRelationship[],
  allTables: IMeasureTable[]
) => string[];

export type DisabledTableConfigurationCapabilities =
  | "table::name"
  | "table::blend"
  | "table::model"
  | "table::add_related_data"
  | "table::add_semantic_group"
  | "table::add_dimension";

export interface IObjectConfigurationInfos
  extends IObjectFormInput,
    IMeasureState {
  id: string;
  properties: Array<IObjectPropertyConfiguration>;
  schema: AsyncData<SchemaResult>;
}

export interface IObjectFormInput
  extends Pick<IObject, "name" | "primaryImage" | "primaryLabel"> {
  primaryKey: string[];
  datasetId: string;
  viewId: string;
}

export interface IObjectPropertyConfiguration
  extends IObjectPropertyFormInput,
    IMeasureState {
  id: string;
}

export interface IObjectPropertyFormInput
  extends Pick<
    IObjectProperty,
    | "label"
    | "description"
    | "columnName"
    | "columnDomain"
    | "formatter"
    | "formatterConfig"
    | "userPropertyMapping"
    | "sortingAndFilteringColumn"
    | "hierarchyPath"
  > {
  type: "standard" | "foreignKey" | "userProperty";
  foreignKey?: string;
}
