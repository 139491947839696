import { Breadcrumb } from "antd";
import { inject, observer } from "mobx-react";
import * as React from "react";
import { Link } from "react-router-dom";
import { compose } from "../../components/compose/WlyCompose";
import type { BreadcrumbStoreProps } from "../../store/breadCrumbStore";
import type { UserStoreProps } from "../../store/userStore";

interface IWhlyBreadcrumbProps {}

type Props = IWhlyBreadcrumbProps & BreadcrumbStoreProps & UserStoreProps;

class WhlyBreadcrumb extends React.Component<Props> {
  public render() {
    const {
      breadcrumbStore: { breadcrumb },
    } = this.props;
    return breadcrumb.length ? (
      <Breadcrumb>
        {breadcrumb.map((b) => {
          let item = b.to ? (
            <Link to={b.to}>{b.label}</Link>
          ) : (
            <span>{b.label}</span>
          );
          return <Breadcrumb.Item key={b.label}>{item}</Breadcrumb.Item>;
        })}
      </Breadcrumb>
    ) : (
      <div />
    );
  }
}

export default compose<Props, IWhlyBreadcrumbProps>()(
  inject("breadcrumbStore", "userStore")(observer(WhlyBreadcrumb))
);
