import { Modal, Space } from "antd";

type Props = {
  open: boolean;
  title?: string;
  content?: string;
  onCancel: () => void;
  onOk: () => void;
};

export default function UpdateObjectViewModal({
  open,
  onCancel,
  onOk,
  title,
  content,
}: Props) {
  return (
    <Modal
      title={title ? title : <Space>Update view</Space>}
      open={open}
      onCancel={onCancel}
      onOk={onOk}
      width={420}
      okText={"Update"}
    >
      {content
        ? content
        : "You are about to modify the current view, this can't be undone. Do you want to proceed?"}
    </Modal>
  );
}
