import { Button, Modal, Select, Space, Typography } from "antd";
import { useState } from "react";
import type { InjectedAntUtilsProps } from "../../../../../../../components/ant-utils/withAntUtils";
import { withAntUtils } from "../../../../../../../components/ant-utils/withAntUtils";
import { WlyCard } from "../../../../../../../components/cards/WlyCard";
import { compose } from "../../../../../../../components/compose/WlyCompose";
import { Emoji } from "../../../../../../../components/form/emoji-picker/Emoji";
import { SourceImageRenderer } from "../../../../../../../components/sources/SourceImageRenderer";
import { DatasetSelector } from "../../../../../../../components/workbench/DatasetSelector";
import type { IObject } from "../../../../../../../interfaces/object";
import type { IDataset } from "../../../../../../../interfaces/sources";
import type { UpdateObjectFunction } from "../../../../selector/object/domain";

interface IObjectModelCardProps {
  object: IObject;
  allDatasets: IDataset[];
  onUpdateObject: UpdateObjectFunction;
}

type Props = IObjectModelCardProps & InjectedAntUtilsProps;

function ObjectModelCard(props: Props) {
  const { antUtils, object, onUpdateObject, allDatasets } = props;
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [modelEditionOpen, setModelEditionOpen] = useState<boolean>(false);
  const [newDatasetId, setNewDatasetId] = useState<string>(object?.model?.id);
  const [newViewId, setNewViewId] = useState<string | undefined>(
    object?.table?.view?.id
  );

  const currentDataset = allDatasets.find((m) => m.id === object.model?.id);

  return (
    <>
      <WlyCard
        title="Model"
        extra={
          <Button
            type="primary"
            size="small"
            onClick={() => setModelEditionOpen(true)}
          >
            Edit
          </Button>
        }
      >
        {currentDataset ? (
          <Space>
            <SourceImageRenderer
              isModel={currentDataset.isModel}
              alt="SQL Model"
              size={16}
              img={
                !currentDataset.isModel
                  ? currentDataset.source.sourceMeta.publicInfo.logo
                  : undefined
              }
            />
            {currentDataset.name}
          </Space>
        ) : (
          <Space>
            <Emoji emoji={":warning:"} size={16} />
            <Typography.Text type="secondary">
              Model not found. Please replace the model if deleted
            </Typography.Text>
          </Space>
        )}
      </WlyCard>
      <Modal
        title={"Update model"}
        open={modelEditionOpen}
        onOk={async () => {
          try {
            setIsLoading(true);
            await onUpdateObject(object.id, {
              model: {
                id: newDatasetId,
              },
              table: {
                id: object.table?.id,
                view: {
                  id: allDatasets.find((m) => m.id === newDatasetId)?.views?.[0]
                    ?.id,
                },
              },
            });
            antUtils.message.success("Object successfully updated");
          } catch (error) {
            console.error(error);
            antUtils.message.error("Error while updating object");
          } finally {
            setIsLoading(false);
            setModelEditionOpen(false);
          }
        }}
        okButtonProps={{
          loading: isLoading,
          disabled: isLoading || newDatasetId === object?.model?.id,
        }}
        onCancel={() => {
          setNewDatasetId(object?.model?.id);
          setNewViewId(object?.table?.view?.id);
          setModelEditionOpen(false);
        }}
      >
        <Typography.Text type="secondary">Based on</Typography.Text>
        <DatasetSelector
          value={newDatasetId}
          onChange={(v) => {
            setNewDatasetId(v);
            setNewViewId(allDatasets.find((d) => d.id === v)?.views?.[0]?.id);
          }}
          datasets={allDatasets}
        />
        {allDatasets.find((d) => d.id === newDatasetId)?.views && (
          <div style={{ marginTop: 12 }}>
            <Typography.Text type="secondary">Using view</Typography.Text>
            <Select
              style={{ width: "100%" }}
              showSearch
              optionFilterProp="label"
              value={newViewId}
              onChange={(v) => setNewViewId(v)}
              options={allDatasets
                .find((d) => d.id === newDatasetId)
                ?.views?.map?.((v) => ({ label: v.name, value: v.id }))}
            />
          </div>
        )}
      </Modal>
    </>
  );
}

export default compose<Props, IObjectModelCardProps>(withAntUtils)(
  ObjectModelCard
);
