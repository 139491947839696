import { Modal } from "antd";

import "./CatalogGenericModal.scss";

type ICatalogGenericModalProps = IBaseCatalogGenericModalProps;

interface IBaseCatalogGenericModalProps {
  open: boolean;
  onClose: () => void;
  children?: React.ReactNode;
}

export function CatalogGenericModal(props: ICatalogGenericModalProps) {
  const { open, onClose, children } = props;

  return (
    <Modal
      footer={null}
      closable={false}
      title={null}
      open={open}
      width={"70%"}
      className="catalog-modal"
      style={{
        top: "5vh",
        bottom: "5vh",
      }}
      destroyOnClose={true}
      onCancel={onClose}
      maskClosable={true}
    >
      {children}
    </Modal>
  );
}
