import type {
  IParsedUserAttributeMeta,
  IUserAttributeMeta,
} from "../../../interfaces/org";

const parseOptions = (op?: string) => {
  if (op) {
    try {
      return JSON.parse(op);
    } catch (err) {
      return null;
    }
  }
  return null;
};

export const parseAttributeValue = (value?: string): string[] => {
  if (!value) {
    return [];
  }
  try {
    return JSON.parse(value);
  } catch (err) {
    return [];
  }
};

export const parseUserAttributeMeta = (
  u: IUserAttributeMeta
): IParsedUserAttributeMeta => {
  const { options, defaultValue, ...rest } = u;
  return {
    options: parseOptions(options),
    defaultValue: parseAttributeValue(defaultValue),
    ...rest,
  };
};

export const isAttributeValid = (
  value: string[],
  meta: IParsedUserAttributeMeta
) => {
  if (!value || value.length === 0) return true;
  return value.reduce<boolean>((acc, v) => {
    if (!acc) {
      return acc;
    }
    if (meta.options && !meta.options.map((o) => o.value).includes(v)) {
      return false;
    } else {
      return true;
    }
  }, true);
};
