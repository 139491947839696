import type { FormInstance } from "antd";
import { Form, Select, Tag } from "antd";
import MeasureItem from "../../../../../../../components/measures/measure-item/MeasureItem";
import type { MeasureType } from "../../../../../../../components/measures/measure-table/MeasureTable";
import type { IViewData } from "../../../../domain";
import type {
  IDrillType,
  IMeasureTable,
  ITableFormInput,
} from "../../../domain";
import { TreeTransfer } from "./TreeTransfer";

interface IDrillsEditorProps {
  form: FormInstance<any>;
  view: IViewData;
  tables: IMeasureTable[];
  id: string;
  parentTable?: ITableFormInput & {
    id: string;
  };
}

export function DrillsEditor(props: IDrillsEditorProps) {
  const { form, view, tables, id, parentTable } = props;

  // useEffect(() => {
  //   const mez = [];
  //   tables.forEach((t) => {
  //     t.dimensions.forEach((d) => {
  //       mez.push(`dim${d.id}`);
  //     });
  //     t.metrics.forEach((d) => {
  //       mez.push(`met${d.id}`);
  //     });
  //   });
  //   form.getFieldValue(["drills", "values"]).forEach((v) => {
  //     if (!mez.includes(v)) console.warn("unknow drill member", v);
  //   });
  // }, []);

  return (
    <>
      <Form.Item name={["drills", "type"]} label="Select a drill type">
        <Select
          getPopupContainer={() => {
            const el = document.getElementById(id);
            if (el) {
              return el;
            }
          }}
        >
          <Select.Option value="INHERIT">
            Inherit from {!!parentTable ? "table" : "dataset"}
          </Select.Option>
          <Select.Option value="CUSTOM">
            Select from existing measures
          </Select.Option>
          <Select.Option value="DISABLED">Disable drills</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item noStyle shouldUpdate={true}>
        {() => {
          const drillType = form.getFieldValue(["drills", "type"]);
          if (drillType === "CUSTOM") {
            return (
              <Form.Item
                name={["drills", "values"]}
                label={"Configure your drills"}
                valuePropName="targetKeys"
              >
                <TreeTransfer tables={tables ? tables : []} />
              </Form.Item>
            );
          }
          if (drillType === "DISABLED") {
            return <span />;
          }

          // we inherit
          // if no parent table then we inherit from the dataset
          if (!parentTable) {
            return (
              <div>
                <div>Your drill will output the following columns:</div>
                <div>
                  {(view.drills || []).map((d) => {
                    return <Tag key={d}>{d}</Tag>;
                  })}
                </div>
              </div>
            );
          }

          const drills: IDrillType = parentTable.drills
            ? parentTable.drills
            : { type: "INHERIT" };

          const renderInheritedDrills = () => {
            const elements = tables.reduce<{
              [key: string]: { name: string; type: MeasureType };
            }>((acc, v) => {
              return {
                ...acc,
                ...v.dimensions.reduce((accd, d) => {
                  return {
                    ...accd,
                    [d.cubeName]: { name: d.name, type: "dimension" },
                  };
                }, {}),
                ...v.metrics.reduce((accd, d) => {
                  return {
                    ...accd,
                    [d.cubeName]: { name: d.name, type: "metric" },
                  };
                }, {}),
              };
            }, {});
            switch (drills.type) {
              case "INHERIT":
                return (
                  <div>
                    <div>Your drill will output the following columns:</div>
                    <div>
                      {(view.drills || []).map((d) => {
                        return <Tag key={d}>{d}</Tag>;
                      })}
                    </div>
                  </div>
                );
              case "DISABLED":
                return (
                  <div>Drills are disabled according to the table config</div>
                );
              case "CUSTOM":
                return (
                  <div>
                    <div>Your drill will output the following query:</div>
                    <div>
                      {(drills.values || []).map((d) => {
                        const found = elements[d];
                        return (
                          <MeasureItem
                            key={d}
                            type={found ? elements[d].type : "dimension"}
                            name={found ? elements[d].name : "Deleted"}
                            small={true}
                            hidePopover={true}
                          />
                        );
                      })}
                    </div>
                  </div>
                );
            }
          };

          return renderInheritedDrills();
        }}
      </Form.Item>
    </>
  );
}
