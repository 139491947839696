import { type Filter } from "@cubejs-client/core";
import "ag-grid-enterprise";
import type { RouteComponentProps } from "react-router";
import { withRouter } from "react-router";
import { compose } from "../../../../../components/compose/WlyCompose";
import {
  FILTERS_SEARCH_SETTINGS,
  OBJECT_VIEW_SLUG,
  parseSearch,
} from "../../../../../helpers/queryStringHelpers";
import type { IObject, IObjectView } from "../../../../../interfaces/object";
import ObjectPageContent from "./content/ObjectPageContent";

interface IObjectPageProps {
  object: IObject;
  referencedObjects: IObject[];
  views: IObjectView[];
  setActiveView: (slug: string | undefined) => void;
}

type Props = IObjectPageProps & RouteComponentProps;

function ObjectPage(props: Props) {
  const {
    object,
    views,
    location: { search },
    referencedObjects,
    setActiveView,
  } = props;

  const { view: activeViewSlug, filters } = parseSearch<{
    view?: string;
    filters?: Filter[];
  }>(search, [...OBJECT_VIEW_SLUG, ...FILTERS_SEARCH_SETTINGS]);

  const injectedFilters = filters || [];

  const getActiveView = () => {
    if (!activeViewSlug) return undefined;
    if (activeViewSlug) {
      const view = views.find((view) => view.slug === activeViewSlug);
      return view;
    }
  };

  const activeView = getActiveView();

  return (
    <ObjectPageContent
      key={`${object.id}-${activeView?.slug ?? "default"}`}
      object={object}
      views={views}
      injectedFilters={injectedFilters}
      activeView={activeView}
      setActiveView={setActiveView}
      referencedObjects={referencedObjects}
    />
  );
}

export default compose<Props, IObjectPageProps>(withRouter)(ObjectPage);
