import { Select, Tabs } from "antd";
import _ from "lodash";
import * as React from "react";
import type { InjectedOrgProps } from "../../../containers/orgs/WithOrg";
import WithOrg from "../../../containers/orgs/WithOrg";
import { compose } from "../../compose/WlyCompose";
import PaletteEditor from "../editor/PaletteEditor";
import PaletteRenderer from "../PaletteRenderer";
import type {
  IPalette,
  IPaletteDiscreteSubtype,
  IPaletteSelection,
  IPaletteType,
} from "../utils/paletteData";
import {
  getAvailableCollections,
  getDefaultCollection,
  getSelectedPalette,
} from "../utils/paletteData";

import "./PaletteSelector.scss";

interface IPaletteSelectorProps {
  onChange?: (v: IPalette | IPaletteSelection) => void;
  value?: IPalette | IPaletteSelection;
  restrict?: "discrete" | "continue";
  editModeOnly?: boolean;
}

type Props = IPaletteSelectorProps & InjectedOrgProps;

interface IState {
  selectedCollectionName: string;
}

interface IPaletteWrapper {
  collection_name: string;
  index: number;
  type: IPaletteType;
  subtype?: IPaletteDiscreteSubtype;
  palette: IPalette;
}

class PaletteSelector extends React.Component<Props, IState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      selectedCollectionName: this.getSelectedCollectionName(),
    };
  }

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<IState>,
    snapshot?: any
  ): void {
    if (!_.isEqual(prevProps.value, this.props.value)) {
      this.setState({
        selectedCollectionName: this.getSelectedCollectionName(),
      });
    }
  }

  getValue = (): IPalette | IPaletteSelection => {
    if (this.props.value) {
      return this.props.value;
    }
    return {
      type: "PALETTE_SELECTION",
      palette_type: this.props.restrict ? this.props.restrict : "discrete",
      collection_name: "default",
      index: 0,
    };
  };

  getSelectedCollectionName = () => {
    const { org } = this.props;
    const value = this.getValue();

    const collections = getAvailableCollections(org);
    const selectedCollection =
      (value as IPaletteSelection).collection_name &&
      collections.find(
        (c) => (value as IPaletteSelection).collection_name === c.slug
      )
        ? (value as IPaletteSelection).collection_name
        : "default";
    return selectedCollection;
  };

  public render() {
    const { onChange, restrict, editModeOnly, org } = this.props;
    const { selectedCollectionName } = this.state;
    const value = this.getValue();

    const collections = getAvailableCollections(org);
    const defaultCollection = getDefaultCollection(org);
    const selectedCollection =
      selectedCollectionName === "default"
        ? defaultCollection
        : collections.find((c) => c.slug === selectedCollectionName);

    const palettes = [
      ...selectedCollection.categorical.map<IPaletteWrapper>((c, i) => ({
        index: i,
        type: c.type,
        collection_name: this.state.selectedCollectionName,
        palette: c,
      })),
      ...selectedCollection.diverging.map<IPaletteWrapper>((c, i) => ({
        index: i,
        type: c.type,
        collection_name: this.state.selectedCollectionName,
        subtype: "diverging",
        palette: c,
      })),
      ...selectedCollection.sequential.map<IPaletteWrapper>((c, i) => ({
        index: i,
        type: c.type,
        collection_name: this.state.selectedCollectionName,
        subtype: "sequential",
        palette: c,
      })),
    ];

    const defaultActiveKey = (value as IPaletteSelection).collection_name
      ? "1"
      : "2"; // if value is IPalette selection we render the palette picker otherwise it is a custom palette

    const defaultPalette = getSelectedPalette(org, value);

    if (editModeOnly) {
      return (
        <div className="palette-selector" style={{ padding: "12px 0" }}>
          <PaletteEditor
            value={defaultPalette}
            onPaletteChange={(v) => {
              if (onChange) {
                return onChange(v);
              }
            }}
          />
        </div>
      );
    }

    return (
      <Tabs
        defaultActiveKey={defaultActiveKey}
        className="palette-selector"
        size="small"
        centered={true}
        items={[
          {
            key: "1",
            label: "Palettes",
            children: (
              <div
                className="palette-selector-preset"
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div style={{ flex: 1, paddingBottom: 12, width: 176 }}>
                  <Select
                    defaultValue={this.state.selectedCollectionName}
                    onChange={(v) =>
                      this.setState({ selectedCollectionName: v })
                    }
                    style={{ width: "100%" }}
                  >
                    <Select.Option key={"default"}>
                      Default Collection
                    </Select.Option>
                    {collections
                      .filter((c) => c.id !== defaultCollection.id)
                      .map((c) => {
                        return (
                          <Select.Option key={c.slug}>{c.name}</Select.Option>
                        );
                      })}
                  </Select>
                </div>
                <div
                  style={{ flex: 1 }}
                  className="palette-selector-preset-palette-list"
                >
                  {palettes
                    .filter((p) => {
                      const pal = p.palette;
                      if (restrict) {
                        return pal.type === restrict;
                      }
                      return true;
                    })
                    .map((p) => {
                      const val: IPaletteSelection = {
                        type: "PALETTE_SELECTION",
                        collection_name: p.collection_name,
                        palette_type: p.type,
                        palette_subtype: p.subtype,
                        index: p.index,
                      };
                      return (
                        <div
                          key={`${p.type}${p.subtype ? p.subtype : ""}${
                            p.index
                          }`}
                          className="palette-selector-preset-palette-list-item"
                          onClick={() => {
                            if (onChange) {
                              onChange(val);
                            }
                          }}
                        >
                          <PaletteRenderer
                            selected={
                              JSON.stringify(value) === JSON.stringify(val)
                            }
                            {...p.palette}
                          />
                        </div>
                      );
                    })}
                </div>
              </div>
            ),
          },
          {
            key: "2",
            label: "Custom",
            children: (
              <PaletteEditor
                value={defaultPalette}
                onPaletteChange={(v) => {
                  if (onChange) {
                    return onChange(v);
                  }
                }}
              />
            ),
          },
        ]}
      />
    );
  }
}

export default compose<Props, IPaletteSelectorProps>(WithOrg)(PaletteSelector);
