import { CloseCircleOutlined, InfoCircleOutlined } from "@ant-design/icons";
import type { FormInstance } from "antd";
import {
  Button,
  Form,
  Input,
  Radio,
  Space,
  Switch,
  Tooltip,
  Typography,
} from "antd";
import humanizeString from "humanize-string";
import _ from "lodash";
import { useState } from "react";
import { WlyCard } from "../../../../../../components/cards/WlyCard";
import { WlyCardDangerZone } from "../../../../../../components/cards/WlyCardDangerZone";
import { compose } from "../../../../../../components/compose/WlyCompose";
import Feednack from "../../../../../../components/layout/feedback/feedback";
import Loading from "../../../../../../components/layout/feedback/loading";
import type { AsyncData } from "../../../../../../helpers/typescriptHelpers";
import type { IDestination } from "../../../../../../interfaces/destinations";
import type {
  IDimension,
  IDimensionType,
} from "../../../../../../interfaces/table";
import type {
  SchemaResult,
  TableResult,
} from "../../../../../../interfaces/transformations";
import { computeTransformations } from "../../../../../../services/BrizoService";
import { generateUniqueId } from "../../../../../../utils/uniqueId";
import type { InjectedOrgProps } from "../../../../../orgs/WithOrg";
import WithOrg from "../../../../../orgs/WithOrg";
import type { ExplorationMeasureItemUsage } from "../../../domain";
import type {
  IDimensionFormInput,
  MeasureValidationFunction,
} from "../../domain";
import { ErrorRenderer } from "../common/ErrorRenderer";
import { SchemaSelect } from "../common/SchemaSelect";
import UsageAlert from "../common/UsageAlert";
import DimensionOrdering from "./DimensionOrdering";

const { Text } = Typography;

export const generateDimensionName = (
  type?: IDimensionType,
  columnName?: string,
  overrideName?: string
) => {
  if (overrideName) {
    return overrideName;
  }
  if (type === "geo") {
    return "Location";
  } else if (type === "standard" && columnName) {
    return humanizeString(columnName);
  } else {
    return "Untitled";
  }
};

export const validateDimensionFormInput: MeasureValidationFunction<
  IDimensionFormInput
> = (data, schema, allDatasets, allDatasetRelationships) => {
  const errors: string[] = [];
  if (schema.status === "initial" || schema.status === "loading") {
    return errors;
  }
  if (schema.status === "error") {
    return errors;
  }

  if (data.type === "standard" && !schema.data[data.columnName]) {
    errors.push("Can't find column in schema");
    return errors;
  }

  if (
    data.type === "geo" &&
    (!schema.data[data.latitude] || !schema.data[data.longitude])
  ) {
    errors.push("Can't find latitude or longitude in schema");
    return errors;
  }

  return errors;
};

interface IDimensionEditorProps {
  initialData?: IDimensionFormInput;
  form: FormInstance<IDimensionFormInput>;
  usage: ExplorationMeasureItemUsage[];
  schemaResults: AsyncData<SchemaResult>;
  alreadyUsedDimensions: IDimension[];
  error: string[];
  currentWarehouse: IDestination;
  datasetId: string;
  onSave: (data: IDimensionFormInput) => Promise<void>;
  onDelete?: () => Promise<void>;
}

type Props = IDimensionEditorProps & InjectedOrgProps;

function DimensionEditor(props: Props) {
  const {
    initialData,
    datasetId,
    currentWarehouse,
    form,
    schemaResults,
    alreadyUsedDimensions,
    onSave,
    onDelete,
    usage,
    error,
  } = props;

  const [loadingValues, setLoadingValues] = useState<boolean>(false);

  const getDistinctValues = async () => {
    const tableId = generateUniqueId();
    const groupId = generateUniqueId();
    const sortId = generateUniqueId();
    const limitId = generateUniqueId();
    const columnName = form.getFieldValue("columnName");

    setLoadingValues(true);

    try {
      const results = await computeTransformations(currentWarehouse.id, {
        base: [
          {
            operation: {
              type: "Table.FromWhalyDataset",
              args: { datasetId: datasetId },
            },
            var: tableId,
            domain: "datasetResolver",
          },
          {
            var: groupId,
            domain: "datasetResolver",
            operation: {
              type: "Table.Group",
              args: {
                keys: [columnName],
                table: tableId,
                aggregatedColumns: [
                  {
                    aggregatedColumn: columnName,
                    aggregationType: "COUNT",
                    destinationColumnName: "_wly_counter",
                  },
                ],
              },
            },
          },
          {
            var: sortId,
            operation: {
              type: "Table.Sort",
              args: {
                table: groupId,
                condition: [
                  {
                    column: columnName,

                    sort: "ASC",
                  },
                ],
              },
            },
            domain: "datasetResolver",
          },
          {
            var: limitId,
            operation: {
              type: "Table.FirstN",
              args: {
                table: sortId,
                countOrCondition: 20,
              },
            },
            domain: "datasetResolver",
          },
        ],
      });
      const result = (results.data.base as TableResult).map((r, i) => {
        return String(r[columnName]);
      });
      form.setFieldValue("customOrderingConfArray", result);
      debouncedOnSave(form.getFieldsValue());
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingValues(false);
    }
  };

  const debouncedOnSave = _.debounce(onSave, 200);

  const { __typename, ...formatedInitialData } = initialData
    ? initialData
    : ({} as any);

  if (
    schemaResults.status === "initial" ||
    schemaResults.status === "loading"
  ) {
    return (
      <Feednack>
        <div>
          <Loading />
          <div>Loading Schema...</div>
        </div>
      </Feednack>
    );
  }

  if (schemaResults.status === "error") {
    return <Feednack>An unexpected error happened</Feednack>;
  }

  return (
    <Form
      initialValues={
        !formatedInitialData
          ? { type: "standard" }
          : {
              ...formatedInitialData,
              customOrderingConfArray: JSON.parse(
                formatedInitialData.customOrderingConfArray ?? "null"
              ),
            }
      }
      layout="vertical"
      form={form}
      style={formatedInitialData ? { padding: "24px 0" } : {}}
      className="dimension-creation-modal"
      onFinish={async (v) => {
        try {
          await form.validateFields();
          const values = form.getFieldsValue();
          if (values.overrideName)
            values.overrideName = values.overrideName.trim();
          if (values.columnName)
            values.columnDomain = schemaResults.data[values.columnName]?.domain;
          await onSave({
            ...formatedInitialData,
            ...values,
          });
        } catch (error) {
          console.error("Validate Failed:", error);
        }
      }}
      onValuesChange={(e) => {
        if (e.type) {
          form.setFieldsValue({
            columnName: null,
            columnDomain: null,
            longitude: null,
            latitude: null,
            customOrderingConfArray: null,
          });
        }
        if (e.columnName) {
          form.setFieldsValue({
            columnDomain: schemaResults.data[e.columnName]?.domain,
            customOrderingConfArray: null,
          });
        }
        debouncedOnSave(form.getFieldsValue());
      }}
    >
      <Form.Item hidden name={["columnDomain"]}>
        <Input />
      </Form.Item>
      <Space direction="vertical" style={{ width: "100%" }} size={24}>
        {usage.length > 0 ? (
          <UsageAlert type="dimension" usage={usage} />
        ) : null}
        {error && error.length > 0 ? <ErrorRenderer error={error} /> : null}
        <WlyCard
          title={
            <Typography.Title style={{ marginBottom: 0 }} level={5}>
              Dimension type
            </Typography.Title>
          }
        >
          <div>
            <Form.Item
              name={["type"]}
              label="Type"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Radio.Group>
                <Space direction="vertical">
                  <Radio value={"standard"}>
                    <div>
                      <div>
                        <Text strong={true}>Standard</Text>
                      </div>
                      <div>
                        <Text type="secondary" italic={true}>
                          Create a dimension from a single column (boolean,
                          string, number, ...)
                        </Text>
                      </div>
                    </div>
                  </Radio>
                  <Radio value={"geo"}>
                    <div>
                      <div>
                        <Text strong={true}>Geolocation</Text>
                      </div>
                      <div>
                        <Text type="secondary" italic={true}>
                          Create a dimension from a latitude & longitude column
                        </Text>
                      </div>
                    </div>
                  </Radio>
                </Space>
              </Radio.Group>
            </Form.Item>
          </div>
        </WlyCard>

        <WlyCard
          title={
            <Typography.Title style={{ marginBottom: 0 }} level={5}>
              Column selection
            </Typography.Title>
          }
        >
          <div>
            <Form.Item noStyle={true} shouldUpdate={true}>
              {() => {
                if (form.getFieldValue("type") === "geo") {
                  return (
                    <>
                      <Form.Item
                        name={["latitude"]}
                        label="Latitude"
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <SchemaSelect
                          schema={schemaResults.data}
                          alreadyUsed={alreadyUsedDimensions.map(
                            (aud) => (aud as any).longitude
                          )}
                        />
                      </Form.Item>
                      <Form.Item
                        name={["longitude"]}
                        label="Longitude"
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <SchemaSelect
                          schema={schemaResults.data}
                          alreadyUsed={alreadyUsedDimensions.map(
                            (aud) => (aud as any).latitude
                          )}
                        />
                      </Form.Item>
                    </>
                  );
                }
                return (
                  <Form.Item
                    name={["columnName"]}
                    label="Column"
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <SchemaSelect
                      schema={schemaResults.data}
                      alreadyUsed={alreadyUsedDimensions.map(
                        (aud) => (aud as any).columnName
                      )}
                    />
                  </Form.Item>
                );
              }}
            </Form.Item>
          </div>
        </WlyCard>
        <WlyCard
          title={
            <Typography.Title style={{ marginBottom: 0 }} level={5}>
              General Information
            </Typography.Title>
          }
        >
          <div>
            <Form.Item noStyle={true} shouldUpdate={true}>
              {() => {
                const generatedExpression = generateDimensionName(
                  form.getFieldValue("type"),
                  form.getFieldValue("columnName"),
                  form.getFieldValue("overrideName")
                );
                if (
                  form.getFieldValue("overrideName") === undefined ||
                  form.getFieldValue("overrideName") === null
                ) {
                  return (
                    <Form.Item>
                      <div>
                        <div>Your dimension name</div>
                        <div>
                          <span>
                            <b>{generatedExpression}</b>
                          </span>
                          <span style={{ marginLeft: 12 }}>
                            <Button
                              type="text"
                              onClick={() =>
                                form.setFieldsValue({
                                  overrideName: generatedExpression,
                                })
                              }
                            >
                              edit
                            </Button>
                          </span>
                        </div>
                      </div>
                    </Form.Item>
                  );
                }
                return (
                  <Form.Item
                    name={["overrideName"]}
                    label="Give your dimension a name"
                    rules={[
                      {
                        required: true,
                        message: "dimension name is required",
                      },
                    ]}
                  >
                    <Input
                      placeholder="Your dimension name"
                      suffix={
                        <CloseCircleOutlined
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            form.setFieldsValue({ overrideName: null });
                            onSave(form.getFieldsValue());
                          }}
                        />
                      }
                    />
                  </Form.Item>
                );
              }}
            </Form.Item>
            <Form.Item
              name={["hidden"]}
              label={
                <Typography.Text>
                  Hide your dimension from the interface{" "}
                  <Tooltip title="Useful when you want to hide dimensions that are supposed to be used only in drills.">
                    <InfoCircleOutlined />
                  </Tooltip>
                </Typography.Text>
              }
              valuePropName="checked"
            >
              <Switch />
            </Form.Item>
            <Form.Item
              name={["description"]}
              label="... add a description to your dimension"
            >
              <Input.TextArea placeholder="Your description" />
            </Form.Item>
          </div>
        </WlyCard>
        <DimensionOrdering
          form={form}
          debouncedOnSave={debouncedOnSave}
          getDistinctValues={getDistinctValues}
          loadingValues={loadingValues}
        />
        {onDelete && (
          <WlyCardDangerZone
            title="Danger Zone"
            button={
              <Button
                type="primary"
                danger={true}
                onClick={onDelete ? onDelete : undefined}
              >
                Delete
              </Button>
            }
          >
            Click here to permanently delete this dimension. This cannot be
            undone.
          </WlyCardDangerZone>
        )}
      </Space>
    </Form>
  );
}

export default compose<Props, IDimensionEditorProps>(WithOrg)(DimensionEditor);
