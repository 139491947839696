import { Typography } from "antd";
import type { IWidgetEmailRendererProps } from "../../domain";
import type { IEmailWidgetButtonConfig } from "../domain";
import { getDefaultConfig, getHref } from "../domain";

interface IEmailWidgetButtonRendererProps
  extends IWidgetEmailRendererProps<IEmailWidgetButtonConfig> {}

export function EmailWidgetButtonRenderer(
  props: IEmailWidgetButtonRendererProps
) {
  const { conf, record, object, org } = props;

  if (!conf.text) {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: 40,
        }}
      >
        <Typography.Text type="secondary">
          Please configure this widget
        </Typography.Text>
      </div>
    );
  }

  const defaults = getDefaultConfig(conf, record);

  const renderAlign = () => {
    if (defaults.align === "center") {
      return defaults.align;
    }
    if (defaults.align === "right") {
      return "flex-end";
    }
    return "flex-start";
  };

  const href = getHref(defaults, object, record, org);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: renderAlign(),
      }}
    >
      <a
        style={{
          backgroundColor: "blue",
          padding: `12px 24px`,
          color: "white",
          borderRadius: 6,
        }}
        href={href}
        target="_blank"
        rel={"noreferrer"}
      >
        {defaults.text}
      </a>
    </div>
  );
}
