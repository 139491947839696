import { ArrowLeftOutlined } from "@ant-design/icons";
import { Button } from "antd";
import * as React from "react";

import "./MenuTitle.scss";

interface IMenuTitleProps {
  onBackClick?: () => void;
  button?: React.ReactNode;
  title: string;
}

export function MenuTitle(props: IMenuTitleProps) {
  const { onBackClick, button, title } = props;

  return (
    <div
      className={onBackClick ? "sider-menu-title button" : "sider-menu-title"}
    >
      {onBackClick && (
        <div className="sider-menu-title-left">
          <Button
            type="text"
            shape="circle"
            onClick={onBackClick}
            icon={<ArrowLeftOutlined />}
            size="small"
          />
        </div>
      )}
      <div className="sider-menu-title-mid">{title}</div>
      {button && <div className="sider-menu-title-right">{button}</div>}
    </div>
  );
}
