import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

import * as React from "react";
import { parseObjectPropertyFormatter } from "../../../../containers/v2-demo/container/object/viewer/domain";
import { type IObjectPropertyActivitiesFormatterConfig } from "../../../../interfaces/object";
import { useHover } from "../../../hooks/useHover";
import { computeMax, computeMin } from "./ActivityCellUtils";

interface IActivityChartSummaryCellRendererProps {
  height: number;
  conf: string;
  reversed?: boolean;
}

export function ActivityChartSummaryCellRenderer(
  props: IActivityChartSummaryCellRendererProps
) {
  const { height, conf } = props;
  const [hoverRef, isHover] = useHover<HTMLDivElement>();
  const ref = React.useRef<HighchartsReact.RefObject>(null);

  const config = React.useMemo(
    () =>
      parseObjectPropertyFormatter(
        "activities",
        conf || ""
      ) as IObjectPropertyActivitiesFormatterConfig,
    [conf]
  );

  // the plotband array is an array that works as follow:
  // 000011110000111
  // each 1 highlights a plot band value, if they are adjascent they must be merged

  React.useEffect(() => {
    if (!isHover) {
      ref.current?.chart.tooltip.destroy();
      ref.current?.chart.xAxis.forEach((xa) => xa.hideCrosshair());
    }
  }, [isHover]);

  const chartOptions: Highcharts.Options = {
    chart: {
      reflow: true,
      plotBorderWidth: 0,
      marginTop: 0,
      marginRight: 0,
      marginLeft: 0,
      marginBottom: 0,
      borderWidth: 0,
      height: height,
      backgroundColor: "transparent",
      type: "column",
    },
    tooltip: {
      enabled: false,
    },
    legend: {
      enabled: false,
    },
    title: {
      text: undefined,
    },
    credits: {
      enabled: false,
    },
    xAxis: {
      type: "datetime",
      offset: -(height + 1),
      tickLength: 7,
      lineColor: "#D8D8D8",
      tickColor: "#D8D8D8",
      startOnTick: false,
      endOnTick: false,
      opposite: props.reversed,
      min: computeMin(config),
      max: computeMax(config),
      labels: {
        y: props.reversed ? 0 : 17,
        style: {
          color: "rgb(51, 51, 51)",
          fontSize: "10px",
        },
      },
    },
    series: [
      {
        type: "column",
        color: "transparent",
        data: [0, 0],
      },
    ],
  };

  return (
    <div style={{ height, width: "100%" }} ref={hoverRef}>
      <HighchartsReact
        ref={ref}
        highcharts={Highcharts}
        options={chartOptions}
      />
    </div>
  );
}
