export type ContentSelectorType =
  | { type: "default" }
  | { type: "report"; reportId: string }
  | { type: "record"; recordId: string; objectId: string };

export type CustomizeHomeHideConfig = Array<"color" | "layout">;

export type HomeConfigType = {
  color: string;
  layout: ContentSelectorType;
};

export const isContentSelectorValid = (value: ContentSelectorType) => {
  if (value?.type === "default") {
    return true;
  } else if (value?.type === "record" && value?.objectId && value?.recordId) {
    return true;
  } else if (value?.type === "report" && value?.reportId) {
    return true;
  }
  return false;
};

export const parseV2HomeConfig = (config?: string): HomeConfigType => {
  const defaultConfig: HomeConfigType = {
    color: "transparent",
    layout: { type: "default" },
  };
  if (!config) {
    return defaultConfig;
  }
  try {
    const parsed = JSON.parse(config);
    const isValid = isContentSelectorValid(parsed?.layout);

    if (!isValid) {
      throw new Error("Home config is not valid");
    }
    return {
      color: parsed?.color ? parsed.color : "transparent",
      layout: parsed.layout,
    };
  } catch (err) {
    console.error(err);
    return defaultConfig;
  }
};
