import {
  DeleteOutlined,
  DownOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import type { InputRef } from "antd";
import { Button, Divider, Dropdown, Input } from "antd";
import { inject, observer } from "mobx-react";
import * as React from "react";
import type { RouteComponentProps } from "react-router";
import { withRouter } from "react-router";
import type { InjectedAntUtilsProps } from "../../../../../components/ant-utils/withAntUtils";
import { withAntUtils } from "../../../../../components/ant-utils/withAntUtils";
import { compose } from "../../../../../components/compose/WlyCompose";
import { handleGQLErrors } from "../../../../../helpers/gqlHelpers";
import type { IDataset } from "../../../../../interfaces/sources";
import type { WorkbenchUIStoreProps } from "../../../../../store/workbenchUIStore";
import type { InjectedOrgProps } from "../../../../orgs/WithOrg";
import WithOrg from "../../../../orgs/WithOrg";
import type { ViewInitialData } from "../../../../spreadsheet/views/ViewEdition";
import ViewEdition from "../../../../spreadsheet/views/ViewEdition";

import "./ViewSelector.scss";

interface IViewExplorerProps {
  dataset: IDataset;
  selectedViewId?: string;
  routeRender: (params: object, query?: object) => string;
  onRenameView?: (viewId: string, name: string) => Promise<void>;
  onDeleteView?: (viewId: string) => Promise<any>;
  onCreateView?: (name: string) => Promise<void>;
}

interface IState {
  viewEditionOpen: boolean;
  viewInitialData?: ViewInitialData;
  dropdownOpen: boolean;
  search?: string;
}

type Props = RouteComponentProps<{}> &
  InjectedOrgProps &
  InjectedAntUtilsProps &
  IViewExplorerProps &
  WorkbenchUIStoreProps;

class ViewSelector extends React.Component<Props, IState> {
  inputSearch: React.RefObject<InputRef>;

  constructor(props: Props) {
    super(props);
    this.state = {
      viewEditionOpen: false,
      dropdownOpen: false,
    };

    this.inputSearch = React.createRef();
  }

  public render() {
    const {
      dataset,
      selectedViewId,
      antUtils,
      onCreateView,
      onRenameView,
      onDeleteView,
    } = this.props;
    return (
      <>
        {onCreateView && onRenameView && this.state.viewEditionOpen && (
          <ViewEdition
            visible={this.state.viewEditionOpen}
            initialData={this.state.viewInitialData}
            onCancel={() =>
              this.setState({
                viewEditionOpen: false,
                viewInitialData: undefined,
              })
            }
            onSave={(data) => {
              const promise = data.id
                ? onRenameView(data.id, data.name)
                : onCreateView(data.name);
              return promise
                .then(() => {
                  this.setState({
                    viewEditionOpen: false,
                    viewInitialData: undefined,
                  });
                })
                .catch(handleGQLErrors());
            }}
          />
        )}
        <Dropdown
          trigger={["click"]}
          arrow={true}
          open={this.state.dropdownOpen}
          onOpenChange={(v) => {
            this.setState({ dropdownOpen: v });
            if (v && this.inputSearch && this.inputSearch.current) {
              this.inputSearch.current.focus();
            }
          }}
          dropdownRender={(menu) => (
            <div
              className="view-selector ant-dropdown-menu"
              style={{ padding: 0 }}
            >
              <div className="view-selector-search" key="Search">
                <Input.Search
                  onChange={(e) => this.setState({ search: e.target.value })}
                  ref={this.inputSearch}
                  placeholder={"Search views"}
                />
              </div>
              <Divider style={{ margin: "3px 0" }} />
              <div key="content" className="view-selector-content">
                {menu}
              </div>
              <Divider style={{ margin: "3px 0" }} />
              <div
                onClick={() =>
                  this.setState({
                    viewEditionOpen: true,
                    dropdownOpen: false,
                  })
                }
                key={"add"}
                className="view-selector-footer"
              >
                <Button type="text" size="small">
                  <PlusOutlined /> add a view
                </Button>
              </div>
            </div>
          )}
          menu={{
            items: dataset.views
              .filter((v) =>
                this.state.search
                  ? v.name
                      .toLocaleLowerCase()
                      .includes(this.state.search.toLocaleLowerCase())
                  : true
              )
              .map((v, i) => {
                return {
                  key: i,
                  label: (
                    <div className="view-selector-content-item">
                      <div
                        onClick={() => {
                          const activeObject =
                            this.props.workbenchUIStore.getActiveObject();
                          this.props.workbenchUIStore.setActiveObjectUrlParams(
                            activeObject,
                            {
                              ...activeObject.urlState,
                              view: v.id,
                            }
                          );
                        }}
                        className="view-selector-content-item-name"
                      >
                        {v.name}
                      </div>
                      <Button
                        type="text"
                        shape="circle"
                        size="small"
                        className="view-selector-content-item-action"
                        icon={<EditOutlined />}
                        onClick={(e) => {
                          this.setState({
                            viewEditionOpen: true,
                            viewInitialData: {
                              id: v.id,
                              name: v.name,
                            },
                          });
                        }}
                      />
                      {!v.default && (
                        <Button
                          type="text"
                          shape="circle"
                          size="small"
                          icon={<DeleteOutlined />}
                          danger={true}
                          className="view-selector-content-item-action"
                          onClick={
                            onDeleteView && !v.default
                              ? () =>
                                  antUtils.modal.confirm({
                                    title: "Do you want to delete this view?",
                                    icon: <ExclamationCircleOutlined />,
                                    content:
                                      "This cannot be undone, do you want to proceed ?",
                                    okText: "Delete",
                                    okButtonProps: {
                                      danger: true,
                                    },
                                    onOk() {
                                      return onDeleteView(v.id);
                                    },
                                  })
                              : undefined
                          }
                        />
                      )}
                    </div>
                  ),
                };
              }),
          }}
        >
          <div
            className="ant-dropdown-link"
            onClick={(e) => e.preventDefault()}
            style={{ cursor: "pointer" }}
          >
            {dataset.views.find((v) => v.id === selectedViewId)!.name.length >
            55
              ? `${dataset.views
                  .find((v) => v.id === selectedViewId)!
                  .name.substr(55)}...`
              : dataset.views.find((v) => v.id === selectedViewId)!.name}{" "}
            <DownOutlined />
          </div>
        </Dropdown>
      </>
    );
  }
}

export default compose<Props, IViewExplorerProps>(
  withRouter,
  WithOrg,
  withAntUtils
)(inject("workbenchUIStore")(observer(ViewSelector)));
