import { Col, Row, Space } from "antd";
import cuid from "cuid";
import React from "react";
import type { RouteComponentProps } from "react-router";
import { withRouter } from "react-router";
import { compose } from "../../../../components/compose/WlyCompose";
import Error from "../../../../components/error/Error";
import Aligner from "../../../../components/layout/aligner/Aligner";
import Feednack from "../../../../components/layout/feedback/feedback";
import Loading from "../../../../components/layout/feedback/loading";
import type { IPartnerPortal } from "../../../../interfaces/org";
import GraphQl from "../../../graphql/graphql";
import type { InjectedOrgProps } from "../../../orgs/WithOrg";
import WithOrg from "../../../orgs/WithOrg";
import SettingsWrapper from "../../SettingsWrapper";
import { PartnerPortalDetailAccessManagement } from "./items/PartnerPortalDetailAccessManagement";
import { PartnerPortalDetailGroup } from "./items/PartnerPortalDetailGroup";
import PartnerPortalDetailHeader from "./items/PartnerPortalDetailHeader";

interface IPartnerPortalDetailPageProps {}

type Props = IPartnerPortalDetailPageProps &
  InjectedOrgProps &
  RouteComponentProps<{ partnerPortalSlug: string }>;

function PartnerPortalDetailPage(props: Props) {
  const {
    match: {
      params: { partnerPortalSlug },
    },
    org,
  } = props;
  const [render, setRender] = React.useState<string>(cuid());
  return (
    <SettingsWrapper>
      <GraphQl<{ allPartnerPortals: Array<IPartnerPortal> }>
        query={`query getPartnerPortalDetails($orgId: ID!, $partnerPortalSlug: String!) {
          allPartnerPortals(where: { org: { id: $orgId }, slug: $partnerPortalSlug }) {
            id
            name
            primaryColor
            logo
            slug
            logoutUrl
            defaultHomeCover
          }
        }`}
        variables={{
          render,
          partnerPortalSlug: partnerPortalSlug,
          orgId: org.id,
        }}
      >
        {(gql) => {
          if (gql.status === "initial" || gql.status === "loading") {
            return <Loading />;
          }
          if (gql.status === "error") {
            return <Error />;
          }

          if (gql.data.allPartnerPortals.length === 0) {
            return <Feednack>Not found</Feednack>;
          }
          return (
            <Aligner>
              <Row justify="center">
                <Col xs={24} sm={24} md={20} lg={20} xl={20}>
                  <Space style={{ width: "100%" }} direction="vertical">
                    <PartnerPortalDetailHeader
                      portal={gql.data.allPartnerPortals[0]}
                      onSubmit={() => setRender(cuid())}
                    />
                    <PartnerPortalDetailGroup
                      portal={gql.data.allPartnerPortals[0]}
                    />
                    <PartnerPortalDetailAccessManagement
                      portal={gql.data.allPartnerPortals[0]}
                    />
                  </Space>
                </Col>
              </Row>
            </Aligner>
          );
        }}
      </GraphQl>
    </SettingsWrapper>
  );
}

export default compose<Props, IPartnerPortalDetailPageProps>(
  withRouter,
  WithOrg
)(PartnerPortalDetailPage);
