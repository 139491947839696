import { Button, Form, Space } from "antd";
import cuid from "cuid";
import * as React from "react";
import { compose } from "../../../components/compose/WlyCompose";
import FormActions from "../../../components/form/actions/FormActions";
import Feednack from "../../../components/layout/feedback/feedback";
import Loading from "../../../components/layout/feedback/loading";
import FilterItem from "../../../components/measures/filter-item/FilterItem";
import type { AsyncData } from "../../../helpers/typescriptHelpers";
import type { IDestination } from "../../../interfaces/destinations";
import type {
  AndFilterItem,
  BinaryFilterItem,
  Filter,
  GroupFilterItem,
  OrFilterItem,
  SchemaResult,
  TableSelectRowsOperation,
  Transformation,
  UnaryFilterItem,
} from "../../../interfaces/transformations";
import { computeTransformations } from "../../../services/BrizoService";
import {
  LagoonCallOrigin,
  lagoonServiceLoad,
} from "../../../services/LagoonService";
import { optimizeStack } from "../../../utils/optimizeTransformation";
import { generateUniqueId } from "../../../utils/uniqueId";
import type { InjectedOrgProps } from "../../orgs/WithOrg";
import WithOrg from "../../orgs/WithOrg";
import type { ICreateComponentProps } from "../domain";
import { catchErrors } from "../domain";

type CreateEditProps = ICreateComponentProps<{
  var: string;
  operation: TableSelectRowsOperation;
  domain: "viewResolver";
}> & {
  viewCubeName: string;
  viewId: string;
  currentWarehouse: IDestination;
};

interface FormValue {
  condition: Array<BinaryFilterItem | UnaryFilterItem>;
  operator: "and" | "or";
}

type Props = CreateEditProps & InjectedOrgProps;

const id = cuid();

const CreateEdit: React.FunctionComponent<Props> = (props) => {
  const {
    onSave,
    transformations,
    onCancel,
    org,
    isStale,
    currentTransformation,
    viewCubeName,
    viewId,
    setFormInstance,
    currentWarehouse,
  } = props;

  const [submitting, setSubmitting] = React.useState<boolean>(false);
  const [fullSchemas, setFullSchemas] = React.useState<
    AsyncData<{ [key: string]: SchemaResult }>
  >({ status: "initial" });

  const [form] = Form.useForm();

  if (setFormInstance) {
    setFormInstance(form);
  }

  const varReplacementRaw: Array<{ from: string; to: string }> = [];
  const filteredTransformations = transformations.filter((t) => {
    if (
      t.operation.type === "Table.RemoveColumns" ||
      t.operation.type === "Table.SelectColumns"
    ) {
      varReplacementRaw.push({
        from: t.var,
        to: t.operation.args.table,
      });
      return false;
    }
    return true;
  });

  const autocomplete = async (dimension: string): Promise<string[]> => {
    const formattedDimension = `${viewCubeName}.${dimension}`;
    return lagoonServiceLoad(
      org.id,
      {
        dimensions: [formattedDimension],
        limit: 5_000,
      },
      "VIEW",
      viewId,
      undefined,
      LagoonCallOrigin.WHALY_APP
    )
      .then((r) => {
        return r.tablePivot();
      })
      .then((r) => {
        return r.map((d) => d[formattedDimension] as string);
      });
  };

  const refinedTransformations = optimizeStack(
    filteredTransformations,
    varReplacementRaw
  );

  React.useEffect(() => {
    async function fetchSchemas() {
      setFullSchemas({ status: "loading" });
      await computeTransformations(currentWarehouse?.id, {
        full: [
          ...refinedTransformations,
          {
            var: generateUniqueId(),
            operation: {
              type: "Table.Schema",
              args: {
                table:
                  refinedTransformations[refinedTransformations.length - 1].var,
              },
            },
            domain: "viewResolver",
          },
        ],
        selected: [
          ...transformations,
          {
            var: generateUniqueId(),
            operation: {
              type: "Table.Schema",
              args: {
                table: transformations[transformations.length - 1].var,
              },
            },
            domain: "viewResolver",
          },
        ],
      })
        .then((r) => {
          setFullSchemas({ status: "success", data: r.data as any });
        })
        .catch((r) => {
          setFullSchemas({ status: "error", error: r });
        });
    }
    fetchSchemas();
  }, []);

  if (fullSchemas.status === "loading" || fullSchemas.status === "initial") {
    return <Loading />;
  }

  if (fullSchemas.status === "error") {
    return <Feednack>{fullSchemas.error.message}</Feednack>;
  }

  if (
    transformations.filter((t) => t.operation.type === "Table.SelectRows")
      .length > 1 ||
    (transformations.filter((t) => t.operation.type === "Table.SelectRows")
      .length === 1 &&
      transformations.find((t) => t.operation.type === "Table.SelectRows")!
        .var !== currentTransformation.var)
  ) {
    return <div>You can only have one select row transformation</div>;
  }

  if (currentTransformation.operation.args.condition.length > 1) {
    return (
      <div>You must have only on condition at the root of your filter.</div>
    );
  }

  if (
    (currentTransformation.operation.args.condition[0] as AndFilterItem).and ===
      undefined &&
    (currentTransformation.operation.args.condition[0] as OrFilterItem).or ===
      undefined
  ) {
    return (
      <div>The root condition must be a AND condition or OR condition</div>
    );
  }

  const extractFilters = (cond: Filter): FormValue => {
    const extractOperator = (c: GroupFilterItem): FormValue => {
      if ((c as any).and) {
        return {
          operator: "and",
          condition: (c as any).and,
        };
      } else {
        return {
          operator: "or",
          condition: (c as any).or,
        };
      }
    };
    return extractOperator(cond[0] as GroupFilterItem);
  };

  const initialValues: FormValue = extractFilters(
    currentTransformation.operation.args.condition
  );
  if (
    initialValues.condition.filter((c) => (c as any).or || (c as any).and)
      .length > 0
  ) {
    return <div>Level 2 conditions cannot be a group condition yet</div>;
  }

  const isEditing = transformations.find(
    (t) => t.var === currentTransformation.var
  );

  return (
    <Form
      form={form}
      initialValues={initialValues}
      onFieldsChange={() => {
        isStale && isStale(true);
      }}
      className="form-dropdown-form"
      onFinish={async (v) => {
        try {
          setSubmitting(true);
          let newTransformations = transformations;
          if (isEditing) {
            newTransformations = newTransformations.map((t) => {
              if (t.var === currentTransformation.var) {
                return {
                  ...currentTransformation,
                  operation: {
                    ...currentTransformation.operation,
                    args: {
                      ...currentTransformation.operation.args,
                      condition: [
                        {
                          [v.operator]: v.condition,
                        },
                      ] as any,
                    },
                  },
                } as Transformation;
              }
              return t;
            });
          } else {
            newTransformations = [
              ...newTransformations,
              {
                ...currentTransformation,
                operation: {
                  ...currentTransformation.operation,
                  args: {
                    ...currentTransformation.operation.args,
                    condition: [
                      {
                        [v.operator]: v.condition,
                      },
                    ] as any,
                  },
                },
              } as Transformation,
            ];
          }
          await onSave(newTransformations, true);
          setSubmitting(false);
          if (onCancel) {
            onCancel();
          }
        } catch (err) {
          setSubmitting(false);
          catchErrors(err, id);
          console.error(err);
        }
      }}
      layout="vertical"
    >
      <div className="form-dropdown-form-content" id={id}>
        <Form.Item style={{ marginBottom: 0 }} shouldUpdate={true}>
          {() => {
            const currentValue = form.getFieldValue(["operator"]);
            const condition = form.getFieldValue(["condition"]);
            return (
              <Form.Item style={{ marginBottom: 0 }} name="operator">
                <div>
                  ... filter rows matching{" "}
                  <Button
                    onClick={() => {
                      form.setFieldsValue({
                        operator: currentValue === "or" ? "and" : "or",
                        condition,
                      });
                    }}
                    size="small"
                  >
                    {currentValue === "or" ? "any" : "all"}
                  </Button>{" "}
                  the following:
                </div>
              </Form.Item>
            );
          }}
        </Form.Item>
        <Form.Item shouldUpdate={true}>
          {() => {
            return (
              <Form.List name="condition">
                {(fields, { add, remove }) => (
                  <Space direction="vertical" style={{ width: "100%" }}>
                    {fields.map((field, i) => {
                      const value = form.getFieldValue([
                        "condition",
                        field.name,
                      ]);
                      return (
                        <FilterItem
                          availableDimensions={Object.keys(
                            fullSchemas.data.selected
                          ).map((k) => {
                            return {
                              key: k,
                              label: k,
                              type: "standard",
                              domain: fullSchemas.data.selected[k].domain,
                            };
                          })}
                          autocomplete={autocomplete}
                          keyName={"column"}
                          filter={value}
                          onDelete={() => remove(i)}
                          onChange={(f) => {
                            const condition = form.getFieldValue(["condition"]);
                            form.setFieldsValue({
                              condition: (condition as FilterItem[]).map(
                                (c, ci) => {
                                  if (ci === i) {
                                    return f;
                                  }
                                  return c;
                                }
                              ),
                            });
                          }}
                          key={i}
                        />
                      );
                    })}
                    <Form.Item>
                      <Button
                        size="small"
                        type="dashed"
                        onClick={() => {
                          const filterItem: UnaryFilterItem = {
                            column: Object.keys(fullSchemas.data.selected)[0],
                            operator: "set",
                          };
                          return add(filterItem);
                        }}
                        block={true}
                      >
                        Add Filter
                      </Button>
                    </Form.Item>
                  </Space>
                )}
              </Form.List>
            );
          }}
        </Form.Item>
      </div>
      {onCancel && (
        <div className="form-dropdown-form-buttons">
          <FormActions
            size="small"
            onCancel={onCancel}
            flex={true}
            isSubmitting={submitting}
          />
        </div>
      )}
    </Form>
  );
};

export default compose<Props, CreateEditProps>(WithOrg)(CreateEdit);
