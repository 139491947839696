import * as React from "react";
import { compose } from "../../../../components/compose/WlyCompose";
import Error from "../../../../components/layout/feedback/error";
import Feednack from "../../../../components/layout/feedback/feedback";
import Loading from "../../../../components/layout/feedback/loading";
import type { IDestination } from "../../../../interfaces/destinations";
import GraphQl from "../../../graphql/graphql";
import SettingsWrapper from "../../SettingsWrapper";

import type { RouteComponentProps } from "react-router";
import { withRouter } from "react-router";
import type { InjectedOrgProps } from "../../../orgs/WithOrg";
import WithOrg from "../../../orgs/WithOrg";
import "./Warehouse.scss";
import WarehouseDetail from "./WarehouseDetail";

interface WarehousePageProps {}

const gql = `
query getConnectedWarehouse(
  $destinationSlug: String!,
  $orgId: ID!,
) {
  _allDestinationsMeta(where: { org: { id: $orgId }, isDeleted_not: true }) {
    count
  }
  allDestinations(where: { slug: $destinationSlug, isDeleted_not: true }) {
    id
    name
    isBiEnabled
    isDataLoadingEnabled
    destinationMeta {
      id
      slug
      defaultTargetDatabase
      warehouseType
      targetName
      publicInfo {
        name
        description
        logo
        website
      }
      name
      options(where: { hidden: false }) {
        id
        slug
        key
        label
        editable
        type
        options
        encrypt
        hidden
      }
    }
    values(where: { option: { hidden: false } }) {
      id
      value
      option {
        id
        slug
        key
        label
        editable
        type
        options
        encrypt
        description
        hidden
      }
    }
    slug
    org {
      id
    }
  }
}
`;

interface IReturnValue {
  allDestinations: IDestination[];
  _allDestinationsMeta: {
    count: number;
  };
}

type Props = WarehousePageProps &
  RouteComponentProps<{ warehouseSlug: string }> &
  InjectedOrgProps;

class WarehousePage extends React.Component<Props> {
  public render() {
    const {
      match: {
        params: { warehouseSlug },
      },
      org,
    } = this.props;
    return (
      <SettingsWrapper>
        <GraphQl<IReturnValue>
          query={gql}
          variables={{ destinationSlug: warehouseSlug, orgId: org.id }}
        >
          {(gql) => {
            if (gql.status === "loading" || gql.status === "initial") {
              return <Loading />;
            }
            if (gql.status === "error") {
              return <Error />;
            }
            if (gql.data.allDestinations.length === 0) {
              return <Feednack>Please add a warehouse to your org</Feednack>;
            }
            if (gql.data.allDestinations.length > 1) {
              return (
                <Feednack>
                  You have too many warehouse connected to your org... please
                  contact the support.
                </Feednack>
              );
            }
            return (
              <WarehouseDetail
                warehouse={gql.data.allDestinations[0]}
                numberOfWarehouses={gql.data._allDestinationsMeta.count}
              />
            );
          }}
        </GraphQl>
      </SettingsWrapper>
    );
  }
}

export default compose<Props, WarehousePageProps>(
  withRouter,
  WithOrg
)(WarehousePage);
