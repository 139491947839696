import type { DataType } from "../interfaces/transformations";

interface FunctionDefinitionContent {
  description: string;
  args?: Array<{
    name: string;
    type: DataType | "ANY";
    description: string;
    unlimited?: boolean;
    isSameTypeAs?: number;
  }>;
}
export interface FunctionDefinition {
  [key: string]: FunctionDefinitionContent;
}

export const functionDefinition: FunctionDefinition = {
  IF: {
    description:
      "Returns the true statement if the condition matches, otherwise returns the false statement",
    args: [
      {
        name: "Condition",
        type: "BOOLEAN",
        description: "Your starting condition",
      },
      {
        name: "True Statement",
        type: "ANY",
        description: "Your true statement, returned when condition is true",
      },
      {
        name: "False Statement",
        type: "ANY",
        description: "Your false statement, returned when condition is false",
        isSameTypeAs: 1,
      },
    ],
  },
  CONCAT: {
    description: "Concatenate several columns value into one",
    args: [
      {
        name: "Value",
        type: "ANY",
        description: "The column referencing your value",
        unlimited: true,
      },
    ],
  },
  LEFT: {
    description: "Extract howMany characters from the beginning of the string.",
    args: [
      {
        name: "String",
        type: "STRING",
        description: "The column referencing your value",
      },
      {
        name: "howMany",
        type: "NUMERIC",
        description: "The number of characters to keep.",
      },
    ],
  },
  RIGHT: {
    description: "Extract howMany characters from the end of the string.",
    args: [
      {
        name: "String",
        type: "STRING",
        description: "The column referencing your value",
      },
      {
        name: "howMany",
        type: "NUMERIC",
        description: "The number of characters to keep.",
      },
    ],
  },
  MID: {
    description:
      "Extract a substring of count characters starting at whereToStart.",
    args: [
      {
        name: "String",
        type: "STRING",
        description: "The column referencing your value",
      },
      {
        name: "whereToStart",
        type: "NUMERIC",
        description: "The position where we should start.",
      },
      {
        name: "count",
        type: "NUMERIC",
        description: "The number of characters to keep.",
      },
    ],
  },
  LENGTH: {
    description: "Returns the number of characters.",
    args: [
      {
        name: "String",
        type: "STRING",
        description: "The column containing the input String",
      },
    ],
  },
  DATEVALUE: {
    description: "Returns a date when inputed a number",
    args: [
      {
        name: "Number",
        type: "NUMERIC",
        description: "The column referencing your value",
      },
    ],
  },
  DATEDIF: {
    description: "Returns a date when inputed a number",
    args: [
      {
        name: "Date",
        type: "TIME",
        description: "The column referencing your date",
      },
      {
        name: "Date",
        type: "TIME",
        description: "The column referencing your date",
      },
      {
        name: "Type",
        type: "STRING",
        description:
          "Could be either DAY, WEEK, MONTH or YEAR, depending on which cohort you want to create",
      },
    ],
  },
  NOW: {
    description: "Returns the current time",
  },
  IS_NOT_NULL: {
    description: "Returns false if the value passed is null or true otherwise",
    args: [
      {
        name: "URL",
        type: "ANY",
        description: "The column referencing your value",
      },
    ],
  },
  IS_NULL: {
    description: "Returns true if the value passed is null or false otherwise",
    args: [
      {
        name: "URL",
        type: "ANY",
        description: "The column referencing your value",
      },
    ],
  },
  UTM_CONTENT: {
    description: "Allow you to extract utm_content tag from any url",
    args: [
      {
        name: "URL",
        type: "STRING",
        description: "The column or text referencing your URL",
      },
    ],
  },
  UTM_SOURCE: {
    description: "Allow you to extract utm_source tag from any url",
    args: [
      {
        name: "URL",
        type: "STRING",
        description: "The column or text referencing your URL",
      },
    ],
  },
  UTM_TERM: {
    description: "Allow you to extract utm_term tag from any url",
    args: [
      {
        name: "URL",
        type: "STRING",
        description: "The column or text referencing your URL",
      },
    ],
  },
  DOMAIN: {
    description: "Allow you to extract the domain name from any url",
    args: [
      {
        name: "URL",
        type: "STRING",
        description: "The column or text referencing your URL",
      },
    ],
  },
  UTM_CAMPAIGN: {
    description: "Allow you to extract utm_campaign tag from any url",
    args: [
      {
        name: "URL",
        type: "STRING",
        description: "The column or text referencing your URL",
      },
    ],
  },
  UTM_MEDIUM: {
    description: "Allow you to extract utm_medium tag from any url",
    args: [
      {
        name: "URL",
        type: "STRING",
        description: "The column or text referencing your URL",
      },
    ],
  },
  COHORT: {
    description: "Returns a cohort when inputed a date",
    args: [
      {
        name: "Date",
        type: "TIME",
        description: "The column referencing your timestamp",
      },
      {
        name: "Part",
        type: "STRING",
        description:
          "Could be either DAY, WEEK, MONTH or YEAR depending on which cohort you want to create",
      },
    ],
  },
  COHORT_WITH_TZ: {
    description:
      "Returns a cohort when inputed a date, rounded using a timezone.",
    args: [
      {
        name: "Date",
        type: "TIME",
        description: "The column referencing your timestamp",
      },
      {
        name: "Part",
        type: "STRING",
        description:
          "Could be either DAY, WEEK, MONTH or YEAR depending on which cohort you want to create",
      },
      {
        name: "Timezone",
        type: "STRING",
        description:
          "Timezone on which we should round up the timestamp to the Part. Ex. 'Europe/Paris'. UTC by default if not set.",
      },
    ],
  },
  AND: {
    description: "Return a boolean",
    args: [
      {
        name: "Expression",
        type: "BOOLEAN",
        description: "The boolean expression you want to match",
        unlimited: true,
      },
    ],
  },
  OR: {
    description: "Return a boolean",
    args: [
      {
        name: "Expression",
        type: "BOOLEAN",
        description: "The boolean expression you want to match",
        unlimited: true,
      },
    ],
  },
  PARSE_GOOGLESHEET_TIMESTAMP: {
    description: "Parse a timestamp from Google Sheet into a unix timestamp.",
    args: [
      {
        name: "Expression",
        type: "NUMERIC",
        description: "The numeric expression you want to match",
      },
    ],
  },
  DATETIME_PARSE: {
    description:
      "Returns a date when inputed a text representing a date and its format.",
    args: [
      {
        name: "Text",
        type: "STRING",
        description: "The column referencing the date to be parsed",
      },
      {
        name: "Format",
        type: "STRING",
        description:
          "The format of the date that is being parsed. Ex: %Y-%m-%d",
      },
    ],
  },
  SPLIT: {
    description:
      "Split a string on a given substring and returns the Nth element of the resulting array",
    args: [
      {
        name: "Base String",
        type: "STRING",
        description: "The main expression you want to split",
      },
      {
        name: "Split String",
        type: "STRING",
        description: "The string you want to split the main expression with",
      },
      {
        name: "Index",
        type: "NUMERIC",
        description: "The index of the substring you want to return",
      },
    ],
  },
  ROUND: {
    description: "Round a number",
    args: [
      {
        name: "Number to round",
        type: "NUMERIC",
        description: "The number you want to round",
      },
      {
        name: "Precision",
        type: "NUMERIC",
        description: "The precision you want to round on",
      },
    ],
  },
  DATEADD: {
    description: "Add period to a date",
    args: [
      {
        name: "Date",
        type: "TIME",
        description: "The number you want to round",
      },
      {
        name: "Interval",
        type: "NUMERIC",
        description: "The interval",
      },
      {
        name: "Part",
        type: "STRING",
        description: "The part",
      },
    ],
  },
  DATETIME_FORMAT: {
    description: "Format a date into string",
    args: [
      {
        name: "Date",
        type: "TIME",
        description: "The date you want to format",
      },
      {
        name: "Format",
        type: "STRING",
        description: "The format",
      },
    ],
  },
  VALUE: {
    description: "Convert a text into an integer number.",
    args: [
      {
        name: "expression",
        type: "STRING",
        description: "The string you want to convert",
      },
    ],
  },
  FLOOR: {
    description: "Floor a number",
    args: [
      {
        name: "Number",
        type: "NUMERIC",
        description: "The number you want to floor",
      },
    ],
  },
  URL: {
    description: "Render a URL",
    args: [
      {
        name: "Url",
        type: "STRING",
        description: "The link your want to redirect your user to",
      },
      {
        name: "Title",
        type: "STRING",
        description: "The title of the link",
      },
    ],
  },
};
