import type { ColumnProps } from "antd/lib/table";
import { inject, observer } from "mobx-react";
import * as React from "react";
import { compose } from "../../components/compose/WlyCompose";
import OrgAvatar from "../../components/orgs/Avatar/OrgAvatar";
import CardTable from "../../components/table/CardTable";
import type { IRealm } from "../../interfaces/realm";
import { authenticatedRequest } from "../../services/authService";
import GraphQLService from "../../services/graphql/GraphQLService";
import type { UserStoreProps } from "../../store/userStore";

interface IUserRealmTableProps {
  isSSOEnabled: boolean;
  realm: IRealm;
}

interface OrgInfo {
  id: string;
  name: string;
  isSsoUserProvisioningEnabled: boolean;
  roleCount: {
    count: number;
  };
}

type Props = IUserRealmTableProps & UserStoreProps;

interface State {
  isLoading: boolean;
  orgsOfRealm: OrgInfo[];
}

class OrgRealmTable extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isLoading: true,
      orgsOfRealm: [],
    };

    this.fetchOrgsOfRealms();
  }

  public generateColumns = (): ColumnProps<OrgInfo>[] => {
    const { isSSOEnabled } = this.props;

    const cols = [
      {
        title: "Organization Name",
        key: "name",
        render: (value, record) => {
          return (
            <div>
              <div style={{ float: "left", display: "inline-clock" }}>
                <OrgAvatar name={record.name} style={{ marginRight: 14 }} />
                <div style={{ display: "inline-block" }}>{record.name}</div>
              </div>
            </div>
          );
        },
      },
      {
        title: "Number of users",
        key: "user_count",
        render: (value, record) => {
          return (
            <div>
              <div style={{ float: "left", display: "inline-clock" }}>
                {record.roleCount.count}
              </div>
            </div>
          );
        },
      },
    ];

    if (isSSOEnabled) {
      cols.push({
        title: "Users should be provisioned at first login",
        key: "user_provisioning",
        render: (value, record) => {
          return (
            <div>
              <div style={{ float: "left", display: "inline-clock" }}>
                {record.isSsoUserProvisioningEnabled ? "✅" : "❌"}
              </div>
            </div>
          );
        },
      });
    }
    return cols;
  };

  fetchOrgsOfRealms = async (): Promise<void> => {
    const { realm } = this.props;

    await authenticatedRequest();
    const GQL = `
    query GetOrgsOfRealm($realmId: ID!) {
      allOrgs(where: { isDeleted_not: true, realm: { id: $realmId } }) {
        id
        name
        isSsoUserProvisioningEnabled
        roleCount: _rolesMeta(where: { isDeleted_not: true }) {
          count
        }
      }
    }
    `;
    const orgsRes = await GraphQLService(GQL, { realmId: realm.id });

    this.setState({
      isLoading: false,
      orgsOfRealm: orgsRes.allOrgs,
    });
  };

  public render() {
    const { isLoading, orgsOfRealm } = this.state;

    return (
      <CardTable<OrgInfo>
        loading={isLoading}
        cardTitle={<div>Team Organizations ({orgsOfRealm.length})</div>}
        actionButtons={<div />}
        rowKey={(record) => record.id}
        dataSource={orgsOfRealm}
        columns={this.generateColumns()}
        pagination={{
          size: "small",
          style: { display: "none" },
          defaultPageSize: 1000,
        }}
      />
    );
  }
}

export default compose<Props, IUserRealmTableProps>()(
  inject("userStore")(observer(OrgRealmTable))
);
