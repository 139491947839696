import { ArrowLeftOutlined } from "@ant-design/icons";
import * as React from "react";
import { compose } from "../../components/compose/WlyCompose";
import Loading from "../../components/layout/feedback/loading";
import WlyModal from "../../components/modal/WlyModal";
import type { InjectedOrgProps } from "../../containers/orgs/WithOrg";
import WithOrg from "../../containers/orgs/WithOrg";
import type { AsyncData } from "../../helpers/typescriptHelpers";
import type { IGroup } from "../../interfaces/group";
import type { IAccessType } from "../../interfaces/reportSharing";
import type { InjectedAntUtilsProps } from "../ant-utils/withAntUtils";
import { withAntUtils } from "../ant-utils/withAntUtils";
import type { ISearchableItem, ISharingInitialData } from "./domain";
import EditSharingForm from "./forms/EdittSharingForm";
import NewSharingForm from "./forms/NewSharingForm";

interface IShareProps {
  visible: boolean;
  canBeManagedByCurrentUser: boolean;
  orgHasFeature: boolean;
  onClose: () => any;
  modalTitle: React.ReactNode;
  fetchSharings: () => Promise<ISharingInitialData>;
  updateSharing: (sharingId: string, newType: IAccessType) => Promise<void>;
  removeSharing: (sharingId: string) => Promise<void>;
  createSharings: (
    s: ISearchableItem[],
    accessType: IAccessType
  ) => Promise<void>;
  availableAccessTypes: Array<IAccessType>;
  disableEdit: (group?: IGroup) => boolean;
}

type Props = IShareProps & InjectedOrgProps & InjectedAntUtilsProps;

// This is a wrapper of 2 components:
// - One to let people give NEW access
// - One to EDIT existing access
// This wrapper is handling the state / API calls
function ShareForm(props: Props) {
  const {
    visible,
    canBeManagedByCurrentUser,
    orgHasFeature,
    onClose,
    modalTitle,
    fetchSharings,
    antUtils: { message },
    updateSharing,
    removeSharing,
    createSharings,
    availableAccessTypes,
    disableEdit,
  } = props;

  // Toggle between Edit and New mode
  // We display different forms based on the mode
  const [editMode, setEditMode] = React.useState<boolean>(true);

  // State management of "new sharings form"
  // We manage the state here as we have the "submit logic" in this top level component
  const [selectedItems, setSelectedItems] = React.useState<ISearchableItem[]>(
    []
  );

  const renderBiggerRole = (): IAccessType => {
    if (availableAccessTypes.includes("manage")) {
      return "manage";
    } else if (availableAccessTypes.includes("edit")) {
      return "edit";
    } else if (availableAccessTypes.includes("view")) {
      return "view";
    }
    return "view";
  };

  const [accessType, setAccessType] = React.useState<IAccessType>(
    renderBiggerRole()
  );

  // Infos of who could get the access
  const [data, setData] = React.useState<AsyncData<ISharingInitialData>>({
    status: "initial",
  });

  const fetchFormData = async () => {
    try {
      setData({ status: "initial" });
      const data = await fetchSharings();
      setData({
        status: "success",
        data: data,
      });
    } catch (err) {
      console.error(err);
      setData({ status: "error", error: err });
    }
  };

  const onAccessTypeChange = async (
    sharingId: string,
    newType: IAccessType
  ) => {
    try {
      await updateSharing(sharingId, newType);
      setData((v) => {
        if (v.status !== "success") {
          return v;
        }
        return {
          ...v,
          data: {
            ...v.data,
            directSharings: v.data.directSharings.map((a) => {
              if (a.id === sharingId) {
                return {
                  ...a,
                  accessType: newType,
                };
              }
              return a;
            }),
          },
        };
      });
    } catch (err) {
      console.error(err);
      message.error("Error while saving, please retry...");
    }
  };

  const onRemoveAccess = async (sharingId: string) => {
    try {
      await removeSharing(sharingId);
      setData((v) => {
        if (v.status !== "success") {
          return v;
        }
        return {
          ...v,
          data: {
            ...v.data,
            directSharings: v.data.directSharings.filter((a) => {
              if (a.id === sharingId) {
                return false;
              }
              return true;
            }),
          },
        };
      });
    } catch (err) {
      console.error(err);
      message.error("Error while saving, please retry...");
    }
  };

  const createNewSharings = async () => {
    try {
      await createSharings(selectedItems, accessType);
      setSelectedItems([]);
      setEditMode(true);
      await fetchFormData();
    } catch (err) {
      console.error(err);
      message.error("Error while saving, please retry...");
    }
  };

  React.useEffect(() => {
    if (visible === true) {
      fetchFormData();
    }
  }, [visible]);

  const renderInnerModal = () => {
    if (data.status === "initial" || data.status === "loading") {
      return <Loading />;
    }

    if (data.status === "error") {
      return <div>Error while fetching: {data.error?.message}</div>;
    }

    if (!editMode) {
      return (
        <NewSharingForm
          availableUsersAndGroups={data.data.availableUsersAndGroup}
          selectedItems={selectedItems}
          onSelectedItemsChange={(selectedItems: ISearchableItem[]) => {
            setSelectedItems(selectedItems);
          }}
          accessType={accessType}
          onAccessTypeChange={(accessType: IAccessType) => {
            setAccessType(accessType);
          }}
          availableAccessTypes={availableAccessTypes}
        />
      );
    } else {
      return (
        <EditSharingForm
          orgHasFeature={orgHasFeature}
          canBeManagedByCurrentUser={canBeManagedByCurrentUser}
          directSharings={data.data.directSharings}
          indirectSharings={data.data.inheritedSharings}
          onEditModeSwitch={() => {
            setEditMode(false);
            setSelectedItems([]);
          }}
          onAccessTypeChange={onAccessTypeChange}
          onRemoveAccess={onRemoveAccess}
          onClose={() => onClose()}
          availableAccessTypes={availableAccessTypes}
          disableEdit={disableEdit}
        />
      );
    }
  };

  return (
    <WlyModal
      open={!!visible}
      title={modalTitle}
      okText={!editMode ? "Invite" : "Done"}
      cancelText={
        !editMode ? (
          <>
            <ArrowLeftOutlined /> Back
          </>
        ) : (
          "Cancel"
        )
      }
      onClose={() => {
        if (editMode === false) {
          setEditMode(true);
        } else {
          onClose();
        }
      }}
      confirmLoading={false}
      onOk={() => {
        if (editMode === true) {
          onClose();
        } else {
          createNewSharings();
          setSelectedItems([]);
        }
      }}
    >
      {renderInnerModal()}
    </WlyModal>
  );
}

export default compose<Props, IShareProps>(WithOrg, withAntUtils)(ShareForm);
