import { Drawer, Dropdown, Typography } from "antd";
import React from "react";
import type { RouteComponentProps } from "react-router";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import { compose } from "../../../../../components/compose/WlyCompose";
import type { IPartnerPortal } from "../../../../../interfaces/org";
import { routeDescriptor } from "../../../../../routes/routes";
import type { InjectedOrgProps } from "../../../../orgs/WithOrg";
import WithOrg from "../../../../orgs/WithOrg";
import PartnerPortalForm from "../../list/PartnerPortalForm";

interface IPartnerPortalDetailHeaderProps {
  portal: IPartnerPortal;
  onSubmit: () => void;
}

type Props = IPartnerPortalDetailHeaderProps &
  RouteComponentProps<{}> &
  InjectedOrgProps;

function PartnerPortalDetailHeader(props: Props) {
  const {
    portal,
    match: { params },
    onSubmit,
    org,
  } = props;

  const [visible, setVisible] = React.useState<boolean>(false);

  return (
    <div style={{ display: "flex", alignItems: "end" }}>
      <div style={{ flex: 1 }}>
        <div>
          <Link
            to={routeDescriptor["settingsPartnerPortals"].renderRoute({
              ...params,
            })}
          >
            All portals {">"}
          </Link>
        </div>
        <div>
          <Typography.Title style={{ marginBottom: 0 }} level={3}>
            {portal.name}
          </Typography.Title>
        </div>
      </div>
      <div style={{ flex: 0 }}>
        <Dropdown.Button
          trigger={["click"]}
          menu={{
            items: [
              {
                key: "edit",
                label: "Edit",
                onClick: () => setVisible(true),
              },
            ],
          }}
          onClick={() =>
            window
              .open(
                `${window.WHALY_CONSTANTS.PARTNER_PORTAL_TEMPLATE_URL.replace(
                  "{PORTAL_SLUG}",
                  portal.slug
                )}/auth/public/signin`,
                "_blank"
              )
              .focus()
          }
        >
          Open portal
        </Dropdown.Button>
        <Drawer
          width={"35%"}
          open={visible}
          closable={true}
          styles={{ body: { padding: "24px 24px 80px 24px" } }}
          keyboard={false}
          maskClosable={false}
          destroyOnClose={true}
          title={"Edit Portal"}
          onClose={() => setVisible(false)}
        >
          <PartnerPortalForm
            selectedPortal={portal}
            onSubmit={onSubmit}
            onDrawerClose={() => setVisible(false)}
            org={org}
          />
        </Drawer>
      </div>
    </div>
  );
}

export default compose<Props, IPartnerPortalDetailHeaderProps>(
  WithOrg,
  withRouter
)(PartnerPortalDetailHeader);
