import type { IEmailRendererConfig } from "../domain";
import type { IEmailWidgetRepeatableConfig } from "./domain";
import { EmailWidgetRepeatableEditor } from "./editor/EmailWidgetRepeatableEditor";
import { EmailWidgetRepeatableMjml } from "./mjml/EmailWidgetRepeatableMjml";
import { EmailWidgetRepeatableRenderer } from "./web-widget/EmailWidgetRepeatableRenderer";

export const emailWidgetRepeatableDefinition: IEmailRendererConfig<IEmailWidgetRepeatableConfig> =
  {
    parseConfig: (widget) => {
      const defaultValue: IEmailWidgetRepeatableConfig = {
        type: "LIST",
        fallback: {
          content: "No records to show",
        },
        options: {
          limit: 10,
        },
      };

      if (!widget.config) {
        return defaultValue;
      }

      try {
        const config: IEmailWidgetRepeatableConfig = JSON.parse(widget.config);
        return config;
      } catch (err) {
        console.error(err);
        return defaultValue;
      }
    },
    renderEditor: (widget, object, record, conf, org, user, form, datasets) => {
      return (
        <EmailWidgetRepeatableEditor
          widget={widget}
          object={object}
          record={record}
          conf={conf}
          form={form}
          org={org}
          user={user}
          datasets={datasets}
        />
      );
    },
    renderComponent: (widget, object, record, conf, org, user, edit) => {
      return (
        <EmailWidgetRepeatableRenderer
          widget={widget}
          object={object}
          record={record}
          conf={conf}
          org={org}
          user={user}
          edit={edit}
        />
      );
    },
    renderMjml: EmailWidgetRepeatableMjml,
  };
