import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Dropdown } from "antd";
import * as React from "react";
import type { InjectedAntUtilsProps } from "../../../components/ant-utils/withAntUtils";
import { withAntUtils } from "../../../components/ant-utils/withAntUtils";
import { compose } from "../../../components/compose/WlyCompose";

interface IColumnDropdownProps {
  overlay: (
    handleClose: () => void,
    handleClickOutside: (stale: boolean) => void,
    catchClicks: boolean
  ) => React.ReactElement<any>;
  position?:
    | "topLeft"
    | "topRight"
    | "bottomLeft"
    | "bottomRight"
    | "topCenter"
    | "bottomCenter";
  id?: string;
  children?: React.ReactNode;
}

interface IState {
  dropDownvisible: boolean;
  contentVisible: boolean;
  confirmOpen: boolean;
}

type Props = IColumnDropdownProps & InjectedAntUtilsProps;

class ColumnDropdown extends React.Component<Props, IState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      dropDownvisible: false,
      contentVisible: false,
      confirmOpen: false,
    };
  }

  handleClickOutside = (isStale: boolean) => {
    const { antUtils } = this.props;
    if (isStale && !this.state.confirmOpen) {
      this.setState({ confirmOpen: true }, () => {
        antUtils.modal.confirm({
          title: "Discard your changes?",
          icon: <ExclamationCircleOutlined />,
          onOk: () => {
            this.closeDropdown();
          },
          onCancel: () => {
            this.openDropdown();
          },
        });
      });
    } else {
      this.closeDropdown();
      return;
    }
  };

  public closeDropdown = () => {
    this.setState(
      {
        dropDownvisible: false,
        confirmOpen: false,
      },
      () => {
        setTimeout(() => {
          this.setState({
            contentVisible: false,
          });
        }, 90);
      }
    );
  };

  public openDropdown = () => {
    this.setState({
      dropDownvisible: true,
      contentVisible: true,
      confirmOpen: false,
    });
  };

  public render() {
    const { overlay, children, position, id } = this.props;
    return (
      <>
        <Dropdown
          dropdownRender={() =>
            this.state.contentVisible ? (
              overlay(
                this.closeDropdown,
                this.handleClickOutside,
                this.state.confirmOpen
              )
            ) : (
              <div />
            )
          }
          overlayStyle={{ zIndex: 1000 }}
          open={this.state.dropDownvisible}
          placement={position ? position : "bottomLeft"}
          overlayClassName={"form-dropdown-overlay"}
          getPopupContainer={(n) =>
            id && document.getElementById(id)
              ? document.getElementById(id)!
              : document.body
          }
        >
          <span
            onClick={
              this.state.dropDownvisible
                ? this.closeDropdown
                : this.openDropdown
            }
          >
            {children}
          </span>
        </Dropdown>
      </>
    );
  }
}

export default compose<Props, IColumnDropdownProps>(withAntUtils)(
  ColumnDropdown
);
