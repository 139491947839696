import { Dropdown } from "antd";
import { inject, observer } from "mobx-react";
import React from "react";
import type { RouteComponentProps } from "react-router";
import { withRouter } from "react-router";
import { compose } from "../../../components/compose/WlyCompose";
import { IUserRoleType } from "../../../interfaces/user";
import { routeDescriptor } from "../../../routes/routes";
import type { UserStoreProps } from "../../../store/userStore";
import { hasRoleAccessBoolean } from "../../user-settings/HasRoleAccess";
import type { InjectedOrgProps } from "../WithOrg";
import WithOrg from "../WithOrg";

interface IAppSwitcherDropdownProps {
  children?: JSX.Element;
}

type Props = IAppSwitcherDropdownProps &
  RouteComponentProps<{}> &
  InjectedOrgProps &
  UserStoreProps;

function AppSwitcherDropdown(props: Props) {
  const { history, org, user, children } = props;

  const [visible, setVisible] = React.useState<boolean>(false);

  return (
    <Dropdown
      open={visible}
      onOpenChange={setVisible}
      menu={{
        items: [
          {
            key: "workspace",
            onClick: () => {
              setVisible(false);
              history.push(
                routeDescriptor["home"].renderRoute({
                  ...props.match.params,
                })
              );
            },
            label: "Workspace",
          },
          {
            key: "workbench",
            disabled: !hasRoleAccessBoolean(
              IUserRoleType.BUILDER,
              user,
              org.id
            ),
            onClick: () => {
              setVisible(false);
              history.push(
                routeDescriptor["workbench"].renderRoute({
                  ...props.match.params,
                })
              );
            },
            label: "Workbench",
          },
          {
            key: "settings",
            onClick: () => {
              setVisible(false);
              history.push(
                routeDescriptor["settingsBasic"].renderRoute({
                  ...props.match.params,
                })
              );
            },
            disabled: !hasRoleAccessBoolean(
              IUserRoleType.ADMIN_BUILDER,
              user,
              org.id
            ),
            label: "Settings",
          },
        ],
      }}
      trigger={["click"]}
      placement="bottomLeft"
      arrow
    >
      {children}
    </Dropdown>
  );
}

export default compose<Props, IAppSwitcherDropdownProps>(
  withRouter,
  WithOrg,
  inject("userStore"),
  observer
)(AppSwitcherDropdown);
