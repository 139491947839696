import { QuestionCircleOutlined } from "@ant-design/icons";
import type { FormInstance } from "antd";
import { Col, Form, Input, Radio, Row, Select, Tooltip } from "antd";
import ProgressCellRenderer from "../../../../../../../components/ag-grid/object-table/cells/ProgressCellRenderer";
import { customFormatter } from "../../../../../../../components/chart/utils/optionsHelper";
import type { IMetricType } from "../../../../../../../interfaces/table";
import type { UserLocale } from "../../../../../../../interfaces/user";
import type {
  IMetricFormInput,
  IObjectConfigurationInfos,
} from "../../../domain";

type IMetricFormatFormItemProps = {
  form: FormInstance<IMetricFormInput>;
  locale: UserLocale;
  object?: IObjectConfigurationInfos;
};

const NUMBER_VALUE = 1832837;
const PERCENT_VALUE = 0.1934;
const DURATION_SECONDS_VALUE = 3953729;
const DURATION_MILLISECONDS_VALUE = 3953729340;

const MetricFormatFormItem: React.FunctionComponent<
  IMetricFormatFormItemProps
> = (props: IMetricFormatFormItemProps) => {
  const { form, locale, object } = props;

  const renderSubForm = (format?: IMetricType) => {
    let value = NUMBER_VALUE;
    if (format === "PERCENT") {
      value = PERCENT_VALUE;
    } else if (format === "DURATION_SECONDS") {
      value = DURATION_SECONDS_VALUE;
    } else if (format === "DURATION_MILLISECONDS") {
      value = DURATION_MILLISECONDS_VALUE;
    }

    if (!format) {
      return (
        <>
          <Form.Item name={["prefix"]} noStyle hidden>
            <Input placeholder="Prefix" />
          </Form.Item>
          <Form.Item name={["overrideFormatting"]} noStyle hidden>
            <Input placeholder="Suffix" />
          </Form.Item>
          <Form.Item name={["suffix"]} noStyle hidden>
            <Input placeholder="Prefix" />
          </Form.Item>
        </>
      );
    } else if (format === "NUMBER") {
      return (
        <>
          <Row gutter={10}>
            <Col span={8}>
              <Form.Item name={["prefix"]} label="Prefix">
                <Input placeholder="Prefix" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name={["overrideFormatting"]}
                label={
                  <span>
                    Format{" "}
                    <Tooltip title="Click to open the documentation">
                      <a
                        style={{ color: "gray" }}
                        href="https://docs.whaly.io/data-management/explorations/add-metrics/using-custom-format"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <QuestionCircleOutlined size={20} />
                      </a>{" "}
                    </Tooltip>
                  </span>
                }
              >
                <Input placeholder="Custom format" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name={["suffix"]} label="Suffix">
                <Input placeholder="Suffix" />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              This will display the following raw value <b>{value}</b> as{" "}
              <b>
                {customFormatter(value, locale, {
                  customFormatting: form.getFieldValue("overrideFormatting"),
                  format: form.getFieldValue("format"),
                  prefix: form.getFieldValue("prefix"),
                  suffix: form.getFieldValue("suffix"),
                })}
              </b>
            </Col>
          </Row>
        </>
      );
    } else if (format === "PERCENT") {
      const renderCustomValue = () => {
        if (form.getFieldValue("overrideFormatting")) {
          return (
            <span>
              <ProgressCellRenderer
                value={value as any}
                formatterConfig={
                  form.getFieldValue("overrideFormatting") as any
                }
              />
            </span>
          );
        }
        return (
          <b>
            {customFormatter(value, locale, {
              customFormatting: form.getFieldValue("overrideFormatting"),
              format: form.getFieldValue("format"),
              prefix: form.getFieldValue("prefix"),
              suffix: form.getFieldValue("suffix"),
            })}
          </b>
        );
      };

      return (
        <>
          <Row>
            <Col span={24}>
              <Form.Item noStyle hidden name="prefix">
                <Input />
              </Form.Item>
              <Form.Item noStyle hidden name="suffix">
                <Input />
              </Form.Item>
              <Form.Item
                noStyle={!object}
                hidden={!object}
                label="Display as"
                name="overrideFormatting"
              >
                <Select placeholder="Number" allowClear>
                  <Select key="0" value={""}>
                    No formatting
                  </Select>
                  <Select.Option key="1" value="PROGRESS">
                    Progress
                  </Select.Option>
                  <Select.Option key="2" value="VARIANCE">
                    Variance
                  </Select.Option>
                  <Select.Option key="3" value="INVERTED_VARIANCE">
                    Variance (inverted color)
                  </Select.Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              This will display the following raw value <b>{value}</b> as:{" "}
              {renderCustomValue()}
            </Col>
          </Row>
        </>
      );
    } else if (
      format === "DURATION_MILLISECONDS" ||
      format === "DURATION_SECONDS"
    ) {
      return (
        <>
          <Row>
            <Col span={24}>
              <Form.Item name="prefix" noStyle hidden>
                <Input />
              </Form.Item>
              <Form.Item name="suffix" noStyle hidden>
                <Input />
              </Form.Item>
              <Form.Item
                name={["overrideFormatting"]}
                label={
                  <span>
                    Format{" "}
                    <Tooltip title="Click to open the documentation">
                      <a
                        style={{ color: "gray" }}
                        href="https://docs.whaly.io/data-management/explorations/add-metrics/using-custom-format"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <QuestionCircleOutlined size={20} />
                      </a>{" "}
                    </Tooltip>
                  </span>
                }
              >
                <Input placeholder="Custom format" />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              This will display the following raw value <b>{value}</b> as{" "}
              <b>
                {customFormatter(value, locale, {
                  customFormatting: form.getFieldValue("overrideFormatting"),
                  format: form.getFieldValue("format"),
                  prefix: form.getFieldValue("prefix"),
                  suffix: form.getFieldValue("suffix"),
                })}
              </b>
            </Col>
          </Row>
        </>
      );
    }
  };

  return (
    <Form.Item noStyle shouldUpdate={true}>
      {() => {
        const format = form.getFieldValue("format");
        const hide = [
          "PERCENT",
          "DURATION_SECONDS",
          "DURATION_MILLISECONDS",
        ].includes(format);

        return (
          <>
            <Form.Item name={["format"]} label="Select a metric format">
              <Radio.Group
                options={[
                  {
                    label: "Number",
                    value: "NUMBER",
                  },
                  {
                    label: "Percentage",
                    value: "PERCENT",
                  },
                  {
                    label: "Duration (in seconds)",
                    value: "DURATION_SECONDS",
                  },
                  {
                    label: "Duration (in milliseconds)",
                    value: "DURATION_MILLISECONDS",
                  },
                ]}
                onChange={(e) => {
                  if (hide) {
                    form.setFieldsValue({
                      prefix: null,
                      suffix: null,
                    });
                  }

                  return e;
                }}
                optionType="button"
                buttonStyle="solid"
              />
            </Form.Item>
            {renderSubForm(format)}
          </>
        );
      }}
    </Form.Item>
  );
};

export default MetricFormatFormItem;
