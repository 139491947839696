import { Form, Input, Space } from "antd";
import type { FormInstance } from "antd/lib";
import _ from "lodash";
import { WlyCard } from "../../../../../../components/cards/WlyCard";
import Feednack from "../../../../../../components/layout/feedback/feedback";
import Loading from "../../../../../../components/layout/feedback/loading";
import type { AsyncData } from "../../../../../../helpers/typescriptHelpers";
import type { SchemaResult } from "../../../../../../interfaces/transformations";
import type { IObjectFormInput, MeasureValidationFunction } from "../../domain";
import { ErrorRenderer } from "../common/ErrorRenderer";
import { SchemaSelect } from "../common/SchemaSelect";

interface IObjectEditorProps {
  initialData: IObjectFormInput;
  schemaResults: AsyncData<SchemaResult>;
  form: FormInstance;
  onSave: (data: IObjectFormInput) => Promise<void>;
  error: string[];
}

export const validateObjectFormInput: MeasureValidationFunction<
  IObjectFormInput
> = (data, schema, allDatasets, allDatasetRelationships, allTables) => {
  const errors: string[] = [];
  const currentDataset = allDatasets.find((d) => d.id === data.datasetId);
  if (!currentDataset) {
    errors.push(`Object based on missing dataset`);
    return errors;
  }

  if (schema.status === "initial" || schema.status === "loading") {
    return errors;
  }
  if (schema.status === "error") {
    return errors;
  }

  if (
    !currentDataset.isModel &&
    !currentDataset.views.find((v) => v.id === data.viewId)
  ) {
    errors.push(`Object based on missing view`);
    return errors;
  }

  if (data.primaryKey.length === 0) {
    errors.push(`Object dataset has no primary key`);
  }

  const schemaKeys = Object.keys(schema.data);
  data.primaryKey.forEach((pk) => {
    if (!schemaKeys.includes(pk)) {
      errors.push(`Primary key ${pk} does not exists in the schema`);
    }
  });

  if (data.primaryImage && !schemaKeys.includes(data.primaryImage)) {
    errors.push(
      `Primary image (${data.primaryImage}) can't be found in schema`
    );
  }

  if (data.primaryLabel && !schemaKeys.includes(data.primaryLabel)) {
    errors.push(
      `Primary label (${data.primaryLabel}) can't be found in schema`
    );
  }

  return errors;
};

export function ObjectEditor(props: IObjectEditorProps) {
  const { initialData, schemaResults, form, onSave, error } = props;

  const debouncedOnSave = _.debounce(onSave, 200);

  if (
    schemaResults.status === "initial" ||
    schemaResults.status === "loading"
  ) {
    return (
      <Feednack>
        <div>
          <Loading />
          <div>Loading Schema...</div>
        </div>
      </Feednack>
    );
  }

  if (schemaResults.status === "error") {
    return <Feednack>An unexpected error happened</Feednack>;
  }

  return (
    <Form
      initialValues={initialData}
      layout="vertical"
      form={form}
      style={initialData ? { padding: "24px 0" } : {}}
      onFinish={async (v) => {
        try {
          await form.validateFields();
          const values = form.getFieldsValue();
          await onSave({
            ...initialData,
            ...values,
          });
        } catch (error) {
          console.error("Validate Failed:", error);
        }
      }}
      onValuesChange={(e) => {
        debouncedOnSave(form.getFieldsValue());
      }}
    >
      <Space direction="vertical" style={{ width: "100%" }} size={24}>
        {error && error.length > 0 ? <ErrorRenderer error={error} /> : null}
        <WlyCard title="Object Information">
          <Form.Item name="name" label="Name">
            <Input />
          </Form.Item>
        </WlyCard>
        <WlyCard title={"Record Information"}>
          <Form.Item name="primaryKey" label="ID">
            <SchemaSelect
              schema={schemaResults.data}
              alreadyUsed={[]}
              mode={"multiple"}
              disabled
            />
          </Form.Item>
          <Form.Item name="primaryLabel" label="Label">
            <SchemaSelect schema={schemaResults.data} alreadyUsed={[]} />
          </Form.Item>
          <Form.Item name="primaryImage" label="Image">
            <SchemaSelect schema={schemaResults.data} alreadyUsed={[]} />
          </Form.Item>
        </WlyCard>
      </Space>
    </Form>
  );
}
