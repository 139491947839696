import React, { useEffect, useRef, useState } from "react";

type IWindowProps = {
  url: string;
  title: string;
  width: number;
  height: number;
};

type IPopupProps = IWindowProps & {
  onClose?: () => void;
  children: React.ReactNode;
};

const createPopup = ({ url, title, height, width }: IWindowProps) => {
  const left = window.screenX + (window.outerWidth - width) / 2;
  const top = window.screenY + (window.outerHeight - height) / 2.5;
  const externalPopup = window.open(
    url,
    title,
    `width=${width},height=${height},left=${left},top=${top}`
  );
  return externalPopup;
};

const Popup: React.FC<IPopupProps> = ({
  title = "",
  width = 500,
  height = 500,
  url,
  children,
  onClose,
}: IPopupProps) => {
  const [externalWindow, setExternalWindow] = useState<Window | null>();
  const intervalRef = useRef<number>();

  const clearTimer = () => {
    window.clearInterval(intervalRef.current);
  };

  const onContainerClick = () => {
    setExternalWindow(
      createPopup({
        url,
        title,
        width,
        height,
      })
    );
  };

  useEffect(() => {
    if (externalWindow) {
      intervalRef.current = window.setInterval(() => {
        try {
          if (externalWindow && externalWindow.closed) {
            if (onClose) onClose();
            clearTimer();
          }
        } catch (error) {}
      }, 100);
    }
  }, [externalWindow]);

  return (
    <div
      onClick={() => {
        onContainerClick();
      }}
    >
      {children}
    </div>
  );
};

export default Popup;
