import _ from "lodash";
import type { IOrg, IPaletteCollection } from "../../../interfaces/org";

export type IPalette = IPaletteContinue | IPaletteDiscrete;

export interface IBasePalette {
  type: IPaletteType;
  colors: string[];
}

export interface IPaletteSelection {
  type: "PALETTE_SELECTION";
  collection_name: string; // default for default collection or the slug of the collection if the user has overriden the default settings
  palette_type: IPaletteType;
  palette_subtype?: IPaletteDiscreteSubtype;
  index: number;
}

interface IPaletteDiscrete extends IBasePalette {
  type: "discrete";
}

export interface IPaletteContinue extends IBasePalette {
  type: "continue";
  subType: IPaletteDiscreteSubtype;
}

export type IPaletteType = "continue" | "discrete";
export type IPaletteDiscreteSubtype = "sequential" | "diverging";

enum PaletteIds {
  "whaly-categorical" = "whaly-categorical",
  "whaly-divergent-green" = "whaly-divergent-green",
  "whaly-divergent-blue" = "whaly-divergent-blue",
}

export const palettes: { [key in PaletteIds]: IPalette } = {
  "whaly-categorical": {
    type: "discrete",
    colors: [
      "#69a2b0",
      "#fbce19",
      "#41d58a",
      "#e49993",
      "#a157d3",
      "#2e2484",
      "#719494",
      "#ee83e3",
      "#b2ed7e",
      "#5e8cc5",
      "#af2239",
      "#e2d07f",
      "#219734",
    ],
  },
  "whaly-divergent-green": {
    type: "continue",
    subType: "diverging",
    colors: ["#219734", "#af2239"],
  },
  "whaly-divergent-blue": {
    type: "continue",
    subType: "sequential",
    colors: ["#69a2b0", "#e2d07f"],
  },
};

const systemPaletteLabel = "whaly-system";

const wlyDefaultCollection: IPaletteCollection = {
  id: "0",
  slug: systemPaletteLabel,
  name: "Whaly",
  system: true,
  categorical: [palettes["whaly-categorical"]],
  diverging: [palettes["whaly-divergent-green"]],
  sequential: [palettes["whaly-divergent-blue"]],
};

export const getSelectedPalette = (
  org: IOrg,
  palettePayload: IPaletteSelection | IPalette
): IPalette => {
  if ((palettePayload as IPalette).colors) {
    // when running on custom palette we pass the palette directly
    return palettePayload as IPalette;
  }

  const paletteSelection = palettePayload as IPaletteSelection;

  const getCollection = () => {
    const defaultCollection = getDefaultCollection(org);
    if (paletteSelection.collection_name === "default") {
      return _.cloneDeep(defaultCollection);
    } else {
      const foundCollection = getAvailableCollections(org).find(
        (c) => c.slug === paletteSelection.collection_name
      );
      if (foundCollection) {
        return _.cloneDeep(foundCollection);
      }
      return _.cloneDeep(defaultCollection);
    }
  };

  const currentCollection = getCollection();

  if (paletteSelection.palette_type === "discrete") {
    const palettes = currentCollection.categorical;
    return _.cloneDeep(palettes[paletteSelection.index % palettes.length]);
  } else {
    if (paletteSelection.palette_subtype) {
      if (paletteSelection.palette_subtype === "sequential") {
        const palettes = currentCollection.sequential;
        return _.cloneDeep(palettes[paletteSelection.index % palettes.length]);
      }
    }
    const palettes = currentCollection.diverging;
    return _.cloneDeep(palettes[paletteSelection.index % palettes.length]);
  }
};

export const getDefaultCollection = (org: IOrg) => {
  if (!org.defaultPaletteCollection) {
    return _.cloneDeep(wlyDefaultCollection);
  }
  const foundPalette = org.paletteCollections.find(
    (pc) => pc.slug === org.defaultPaletteCollection
  );
  if (foundPalette) {
    return _.cloneDeep(foundPalette);
  }
  return _.cloneDeep(wlyDefaultCollection);
};

export const getAvailableCollections = (org: IOrg) => {
  return [
    _.cloneDeep(wlyDefaultCollection),
    ..._.cloneDeep(org.paletteCollections),
  ];
};
