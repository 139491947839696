import { action, makeObservable, observable } from "mobx";
import type { IOrgStatus } from "../interfaces/org";
import GraphQLService from "../services/graphql/GraphQLService";

class OnboardingStore {
  onboardingStep:
    | "QUESTIONNAIRE"
    | "FREE_TRIAL"
    | "RUNNING"
    | "WAREHOUSE_CONNECTION";
  loading: boolean;

  constructor() {
    makeObservable(this, {
      onboardingStep: observable,
      loading: observable,
      getOnboardingStatus: action,
    });

    this.loading = false;
    this.onboardingStep = "RUNNING";
  }

  getOnboardingStatus(orgSlug: string, orgStatus: IOrgStatus) {
    this.loading = true;
    const that = this;
    return GraphQLService(
      `
        query getOnboardingStatus($orgSlug: String!) {
            _allDestinationsMeta(where:{org:{slug: $orgSlug}, isDeleted_not: true}) {
              count
            }
          }
    `,
      {
        orgSlug,
      },
      "_allDestinationsMeta"
    )
      .then(
        action((r: { count: number }) => {
          if (orgStatus === "onboarding") {
            this.onboardingStep = "QUESTIONNAIRE";
          } else if (r.count < 1) {
            this.onboardingStep = "WAREHOUSE_CONNECTION";
          } else if (orgStatus === "freetrial") {
            this.onboardingStep = "FREE_TRIAL";
          } else if (orgStatus === "full") {
            this.onboardingStep = "RUNNING";
          }
        })
      )
      .then(
        action(() => {
          that.loading = false;
        })
      )
      .catch((err) => console.error(err));
  }
}

export interface OnboardingStoreProps {
  onboardingStore: OnboardingStore;
}

export default new OnboardingStore();
