import { MoreOutlined } from "@ant-design/icons";
import { Button, Dropdown, List, Typography } from "antd";
import { inject, observer } from "mobx-react";
import moment from "moment";
import * as React from "react";
import type { InjectedAntUtilsProps } from "../../../../../components/ant-utils/withAntUtils";
import { withAntUtils } from "../../../../../components/ant-utils/withAntUtils";
import { compose } from "../../../../../components/compose/WlyCompose";
import UserAvatar from "../../../../../components/user/avatar/UserAvatar";
import type { IDestination } from "../../../../../interfaces/destinations";
import type { IWorksheet } from "../../../../../interfaces/worksheet";
import type { WorkbenchUIStoreProps } from "../../../../../store/workbenchUIStore";
import workbenchUIStore from "../../../../../store/workbenchUIStore";
import type { IActiveObject } from "../../domain";
import RenameWorksheetModal from "../../viewer/worksheet/Modals/RenameWorksheetModal";
import "./WorksheetTable.scss";

interface IWorksheetTableProps {
  worksheets: IWorksheet[];
  activeObject?: IActiveObject;
  onActiveObjectChange: (activeObject: IActiveObject) => void;
  onUpdateWorksheet: (id: string, data: Partial<IWorksheet>) => Promise<any>;
  currentWarehouse: IDestination;
}

type Props = IWorksheetTableProps &
  WorkbenchUIStoreProps &
  InjectedAntUtilsProps;

interface IState {
  renameOpen?: IWorksheet;
}

export const generateWorksheetName = (w: IWorksheet) => {
  if (w?.name) return w.name;
  return moment(w?.createdAt).format("YYYY-MM-DD HH:mm");
};

class WorksheetTable extends React.Component<Props, IState> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  public render() {
    const {
      activeObject,
      onActiveObjectChange,
      worksheets,
      antUtils,
      onUpdateWorksheet,
    } = this.props;

    return (
      <>
        <List<IWorksheet>
          itemLayout="horizontal"
          dataSource={worksheets}
          className="worksheet-content-list"
          renderItem={(i) => {
            return (
              <List.Item
                className={`worksheet-content-list-item ${
                  activeObject &&
                  activeObject.type === "worksheet" &&
                  activeObject.value === i.id
                    ? "active"
                    : ""
                }`}
                actions={[
                  <Dropdown
                    key={"1"}
                    trigger={["click"]}
                    menu={{
                      items: [
                        {
                          label: "Rename",
                          key: "rename",
                          onClick: (e) => {
                            e.domEvent.stopPropagation();
                            this.setState({
                              renameOpen: i,
                            });
                          },
                        },
                        {
                          label: "Delete",
                          key: "delete",
                          onClick: (e) => {
                            e.domEvent.stopPropagation();
                            return antUtils.modal.confirm({
                              title: "Do you want to proceed?",
                              content:
                                "You are about to delete this worksheet, this cannot be undone. Do you want to proceed ?",
                              onCancel: () => undefined,
                              okButtonProps: {
                                danger: true,
                              },
                              onOk: () => {
                                workbenchUIStore.removeActiveObject({
                                  type: "worksheet",
                                  value: i.id,
                                });
                                return onUpdateWorksheet(i.id, {
                                  deleted: true,
                                });
                              },
                            });
                          },
                          danger: true,
                        },
                      ],
                    }}
                    placement={"bottomRight"}
                  >
                    <Button
                      size="small"
                      type="text"
                      shape="circle"
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    >
                      <MoreOutlined />
                    </Button>
                  </Dropdown>,
                ]}
                onClick={() =>
                  onActiveObjectChange({
                    type: "worksheet",
                    value: i.id,
                  })
                }
              >
                <List.Item.Meta
                  title={
                    <div className="worksheet-content-list-item-title">
                      {generateWorksheetName(i)}
                    </div>
                  }
                  description={
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <UserAvatar
                        style={{ marginRight: 4 }}
                        user={i.createdBy}
                        size={"small"}
                      />
                      <Typography.Text type="secondary" ellipsis>
                        {i.createdBy?.firstName} {i.createdBy?.lastName}
                      </Typography.Text>
                    </div>
                  }
                />
              </List.Item>
            );
          }}
        />
        <RenameWorksheetModal
          open={!!this.state.renameOpen}
          onClose={() => this.setState({ renameOpen: null })}
          onRename={(v) =>
            this.state.renameOpen
              ? onUpdateWorksheet(this.state.renameOpen.id, {
                  name: v.name,
                })
              : Promise.resolve()
          }
          initialValue={
            this.state.renameOpen
              ? { name: generateWorksheetName(this.state.renameOpen) }
              : undefined
          }
        />
      </>
    );
  }
}

export default compose<Props, IWorksheetTableProps>(
  inject("workbenchUIStore"),
  observer,
  withAntUtils
)(WorksheetTable);
