import type { Query } from "@cubejs-client/core";
import Highcharts from "highcharts";
import { HighchartsReact } from "highcharts-react-official";
import _ from "lodash";
import * as React from "react";
import type { ChartOption } from "../../../containers/chart-options/ChartOptions";
import type { UserLocale } from "../../../interfaces/user";
import type { Formatter } from "../domain";
import { localizeHighchart } from "../domain";
import {
  customFormatter,
  hasPredominentFormatter,
  optionsHelper,
  renderSerieTooltip,
} from "../utils/optionsHelper";

interface IScatterChartProps {
  height: number;
  data: Array<{ [key: string]: string | number | boolean }>;
  config: ScatterChartConfig;
  tinyLegend?: boolean;
  unit?: "second" | "percent";
  width?: number;
  onDrill?: (q: Query) => void;
  chartOptions?: ChartOption;
  locale: UserLocale;
}

interface ScatterChartConfig {
  x: {
    key: string;
    label?: string;
    formatter: Formatter;
  };
  nameKey: string;
  y: Array<{
    key: string;
    label?: string;
    serieLabel?: string;
    color?: string;
    canDrill: (xValue: string, yValue?: string) => Query | null;
    dashed?: boolean;
    formatter: Formatter;
    showLabels?: boolean;
  }>;
  stacking?: boolean;
  showLabels?: boolean;
}

export default class ScatterChart extends React.Component<IScatterChartProps> {
  ref?: Highcharts.Chart;

  componentDidUpdate(prevProps: IScatterChartProps) {
    if (
      (prevProps.width !== this.props.width ||
        prevProps.height !== this.props.height ||
        !_.isEqual(this.props.config, prevProps.config)) &&
      this.ref &&
      this.ref.reflow
    ) {
      this.ref.reflow();
    }
  }

  shouldComponentUpdate(
    nextProps: Readonly<IScatterChartProps>,
    nextState: Readonly<{}>,
    nextContext: any
  ): boolean {
    if (!_.isEqual(this.props.width, nextProps.width)) return true;
    if (!_.isEqual(this.props.height, nextProps.height)) return true;
    if (!_.isEqual(this.props.data, nextProps.data)) return true;
    if (!_.isEqual(this.props.tinyLegend, nextProps.tinyLegend)) return true;
    if (!_.isEqual(this.props.chartOptions, nextProps.chartOptions))
      return true;
    return false;
  }

  public render() {
    const { height, config, data, width, chartOptions, locale } = this.props;
    const predominentFormatter = hasPredominentFormatter(
      config.y.map((f) => f.formatter)
    );

    localizeHighchart(Highcharts, locale);

    const xFormatter = config.x.formatter;

    const defaultOptions = optionsHelper(
      height,
      width,
      locale,
      "numeric",
      predominentFormatter,
      xFormatter,
      undefined,
      chartOptions
    );

    const options: Highcharts.Options = {
      ...defaultOptions,
      series: config.y.map((y) => {
        return {
          type: "scatter",
          turboThreshold: 9e9,
          color: y.color,
          name: y.serieLabel,
          data: data.map((d) => {
            return {
              x: d[config.x.key] ? +(d[config.x.key] as any) : 0,
              y: (d[y.key] as any) ? +(d[y.key] as any) : 0,
              name: (d[config.nameKey] as string)
                ? (d[config.nameKey] as string)
                : "null",
            };
          }),
          dataLabels: [
            {
              style: {
                fontSize: "0.8em",
              },
              enabled: config.showLabels,
              formatter: function () {
                return customFormatter((this as any).y, locale, y.formatter);
              },
            },
          ],
          cursor: "pointer",
          tooltip: renderSerieTooltip({
            data: data,
            locale: locale,
            seriesLength: 1,
            valueGetter: "this.x",
            hideSerieColor: true,
            alternateSerieDisplayLabel: config.x.label,
            formatter: config.x.formatter,
            additionalLine: {
              alternateSerieDisplayLabel: y.label,
              hideSerieColor: true,
              formatter: y.formatter,
            },
          }),
        };
      }),
      legend: {
        enabled: false,
      },
      plotOptions: {
        ...defaultOptions.plotOptions,
        scatter: {
          dataLabels: {
            enabled: config.showLabels,
            style: {
              fontSize: "0.8em",
            },
          },
        },
      },
    };
    if (config.stacking) {
      options.plotOptions = {
        ...(options.plotOptions ? options.plotOptions : {}),
        column: {
          ...(options.plotOptions && options.plotOptions.column
            ? options.plotOptions.column
            : {}),
          stacking: "normal",
        },
      };
    }

    return (
      <div style={{ height, width: "100%" }}>
        <HighchartsReact highcharts={Highcharts} options={options} />
      </div>
    );
  }
}
