import { Popover, Space, Typography } from "antd";
import { useState } from "react";
import { WlyFolderIcon } from "../custom-icons/WlyFolderIcon";
import { IconHolder } from "../holder/IconHolder";

const colorPresets = [
  {
    label: "wlyColors",
    colors: [
      "#2599D4",
      "#F06A6A",
      "#EC8D71",
      "#F1BD6C",
      "#F8DF72",
      "#B3DF97",
      "#83C9A9",
      "#4ECBC4",
      "#9EE7E3",
      "#4473D2",
      "#A69FF3",
      "#CD95EA",
      "#F9AAEF",
      "#F26FB2",
      "#6D6E6F",
    ],
  },
];

type FolderIconPickerValue = string;

interface IFolderIconPickerProps {
  value?: FolderIconPickerValue;
  onChange?: (v: FolderIconPickerValue) => void;
}

const FolderIconPicker = (props: IFolderIconPickerProps) => {
  const { onChange } = props;
  const [popoverVisible, setPopoverVisible] = useState<boolean>(false);
  const value = props.value ?? "#2599D4";

  const size = 20;
  return (
    <div>
      <Popover
        trigger={["click"]}
        overlayStyle={{ width: 300, maxHeight: 400 }}
        open={popoverVisible}
        onOpenChange={setPopoverVisible}
        destroyTooltipOnHide
        content={
          <Space
            direction="vertical"
            style={{ display: "flex", width: "100%" }}
            size={20}
          >
            <Typography.Text strong>Edit icon appearance</Typography.Text>
            <Space wrap>
              {colorPresets
                .flatMap((c) => {
                  return c.colors;
                })
                .map((c, index) => {
                  return (
                    <div
                      key={index}
                      onClick={() => {
                        onChange?.(c);
                        setPopoverVisible(false);
                      }}
                      style={{
                        width: size,
                        height: size,
                        backgroundColor: c,
                        borderRadius: 4,
                        outline: value === c ? "1px solid black" : undefined,
                        outlineOffset: 1,
                        border: `1px solid rgba(0, 0, 0, 0.06)`,
                        cursor: "pointer",
                      }}
                    />
                  );
                })}
            </Space>
          </Space>
        }
      >
        <div
          style={{
            width: 32,
            height: 32,
            border: "1px solid #D9D9D9",
            borderRadius: 6,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            cursor: "pointer",
          }}
        >
          <IconHolder
            color={value}
            background={"transparent"}
            icon={<WlyFolderIcon style={{ color: value }} />}
            size={28}
          />
        </div>
      </Popover>
    </div>
  );
};

export default FolderIconPicker;
