import {
  CheckCircleTwoTone,
  EditOutlined,
  LoadingOutlined,
  PlusOutlined,
  WarningTwoTone,
} from "@ant-design/icons";
import { Button, Dropdown, Space, Tooltip, Tree } from "antd";
import type { DataNode } from "antd/lib/tree";
import * as React from "react";
import type { InjectedAntUtilsProps } from "../../../../../components/ant-utils/withAntUtils";
import { withAntUtils } from "../../../../../components/ant-utils/withAntUtils";
import MeasureItem from "../../../../../components/measures/measure-item/MeasureItem";
import type {
  ExtendedMeasureType,
  MeasureType,
} from "../../../../../components/measures/measure-table/MeasureTable";
import type { InjectedOrgProps } from "../../../../../containers/orgs/WithOrg";
import WithOrg from "../../../../../containers/orgs/WithOrg";
import HasRoleAccess, {
  hasRoleAccessBoolean,
} from "../../../../../containers/user-settings/HasRoleAccess";
import type { AsyncData } from "../../../../../helpers/typescriptHelpers";
import type { SchemaResult } from "../../../../../interfaces/transformations";
import { IUserRoleType } from "../../../../../interfaces/user";

import type {
  DisabledTableConfigurationCapabilities,
  ExplorationMeasureStatus,
  IMeasureDimension,
  IMeasureMetric,
  IMeasureSemanticGroup,
  IMeasureTable,
  IObjectConfigurationInfos,
} from "../domain";

import { compose } from "../../../../../components/compose/WlyCompose";
import { arrayToTree } from "../../../../../utils/arrayToTree";
import "./MeasureEditorTree.scss";

interface IMeasureTreeProps {
  tables: IMeasureTable[];
  onDuplicate?: (type: MeasureType, tableId: string, measureId: string) => void;
  onModify?: (type: MeasureType, tableId: string, measureId: string) => void;
  onDelete?: (
    type: MeasureType,
    tableId: string,
    measureId: string
  ) => Promise<any>;
  onAddMeasure?: (
    type: MeasureType,
    tableId: string,
    subType?: "COMPUTED"
  ) => void;
  onAddProperty?: () => void;
  onDeleteProperty?: (propertyId: string) => void;
  onAddTable?: (tableId: string) => void;
  onDeleteTable?: (tableId: string) => void;
  onAddGroup?: (tableId: string) => void;
  onMoveMeasureToGroup?: (
    type: MeasureType,
    tableId: string,
    measureId: string,
    groupId: string,
    fromGroupId?: string
  ) => void;
  onEditTable?: (tableId: string) => void;
  onClick?: (type: ExtendedMeasureType, id: string) => void;
  selectedItems?: Array<{ type: ExtendedMeasureType; id: string }>;
  raw?: boolean;
  checkable?: {
    checkedKeys: string[];
    onCheck: (key: string, check: boolean) => void;
    targetKeys: string[];
    isChecked: (dep: NodeWithType) => boolean;
  };
  treeStyle?: React.CSSProperties;
  disabledCapabilities: Array<DisabledTableConfigurationCapabilities>;
  header?: React.ReactNode;
  objectInfos?: IObjectConfigurationInfos;
}

type MeasureNodeWithType =
  | {
      type: "dimension";
      item: IMeasureDimension;
    }
  | {
      type: "metric";
      item: IMeasureMetric;
    };

type SemanticGroupNodeWithType = {
  type: "semanticGroup";
  item: IMeasureSemanticGroup;
};

type NodeWithType = MeasureNodeWithType | SemanticGroupNodeWithType;

const isChecked = (
  selectedKeys: (string | number)[],
  eventKey: string | number
) => selectedKeys.includes(eventKey);

const alphabeticalOrder = (a, b) => {
  const aName = `${a.hierarchyPath}${a.name?.toLocaleLowerCase()}`;
  const bName = `${b.hierarchyPath}${b.name?.toLocaleLowerCase()}`;
  if (aName < bName) {
    return -1;
  }
  if (aName > bName) {
    return 1;
  }
  return 0;
};

type Props = IMeasureTreeProps & InjectedOrgProps & InjectedAntUtilsProps;

interface MeasureDataNode extends DataNode {
  allowedTable?: string;
  allowedGroups?: Array<string>;
  allowedMetrics?: Array<string>;
  allowedDimensions?: Array<string>;
  parentKey?: string;
}

function MeasureEditorTree(props: Props) {
  const {
    tables,
    onDelete,
    onDuplicate,
    onModify,
    onMoveMeasureToGroup,
    org,
    user,
    onClick,
    selectedItems,
    checkable,
    treeStyle,
    antUtils,
    disabledCapabilities,
    header,
    onAddProperty,
    onDeleteProperty,
    objectInfos,
  } = props;

  const renderMeasureHeader = (
    node: MeasureNodeWithType,
    table: IMeasureTable
  ) => {
    let style: React.CSSProperties = {};
    if (node.item.hidden) {
      style.opacity = 0.7;
    }
    const update =
      node.item.status === "update" || node.item.status === "create";
    const isUsedInChart = (node.item.usage || []).length > 0;
    const wrappedOnDelete = (
      type: MeasureType,
      tableId: string,
      measureId: string
    ) => {
      if (isUsedInChart) {
        antUtils.modal.confirm({
          title: "This measure is in use",
          content: `This measure is used in dashbaords or questions. Are you sure you want to delete it?`,
          okButtonProps: {
            danger: true,
          },
          okText: "Delete",
          onOk: () => {
            onDelete(type, tableId, measureId);
          },
          onCancel: () => false,
        });
      } else if (node.item.rowLevelAccess) {
        antUtils.modal.confirm({
          title: "This measure is in use",
          content: `This measure is used in a row level access condition. Are you sure you want to delete it?`,
          okButtonProps: {
            danger: true,
          },
          okText: "Delete",
          onOk: () => {
            onDelete(type, tableId, measureId);
          },
          onCancel: () => false,
        });
      } else {
        onDelete(type, tableId, measureId);
      }
    };

    const leafSelected =
      selectedItems &&
      selectedItems.find(
        (si) => si.type === node.type && si.id === node.item.id
      )
        ? true
        : false;

    const renderName = (): string => {
      if (node.type === "metric") {
        if (node.item.hierarchyPath) {
          return `${node.item.hierarchyPath} / ${node.item.name}`;
        }
      }

      return node.item.name;
    };

    return (
      <div className={`measures-table-content`} style={style}>
        <MeasureItem
          isDragging={false}
          type={node.type}
          hidePopover={!!checkable}
          error={node.item.hasError ? node.item.hasError : []}
          name={`${update ? "• " : ""}${renderName()}`}
          description={node.item.description}
          selected={leafSelected}
          isUsedInChart={isUsedInChart}
          onDelete={
            hasRoleAccessBoolean(IUserRoleType.BUILDER, user, org.id) &&
            onDelete
              ? () =>
                  wrappedOnDelete(
                    node.type.toLowerCase() as any,
                    table.id,
                    node.item.id
                  )
              : undefined
          }
          onDuplicate={
            hasRoleAccessBoolean(IUserRoleType.BUILDER, user, org.id) &&
            onDuplicate
              ? () =>
                  onDuplicate(
                    node.type.toLowerCase() as any,
                    table.id,
                    node.item.id
                  )
              : undefined
          }
          onModify={
            hasRoleAccessBoolean(IUserRoleType.BUILDER, user, org.id) &&
            onModify
              ? () =>
                  onModify(
                    node.type.toLowerCase() as any,
                    table.id,
                    node.item.id
                  )
              : undefined
          }
          onClick={onClick ? () => onClick(node.type, node.item.id) : undefined}
        />
      </div>
    );
  };

  const renderCheckIndicator = (
    schema: AsyncData<SchemaResult>,
    hasError?: string[]
  ) => {
    if (hasError && hasError.length) {
      return (
        <Tooltip title={hasError.join(". ")}>
          <WarningTwoTone twoToneColor="#FA7375" />
        </Tooltip>
      );
    }
    if (schema.status === "initial" || schema.status === "loading") {
      return <LoadingOutlined />;
    }
    if (schema.status === "error") {
      return (
        <Tooltip title="Couldn't load schema.">
          <WarningTwoTone twoToneColor="#FA7375" />
        </Tooltip>
      );
    }
    return (
      <Tooltip title="No errors found">
        <CheckCircleTwoTone twoToneColor="#52c41a" />
      </Tooltip>
    );
  };

  const renderTableHeader = (
    tableId: string,
    tableName: string,
    schema: AsyncData<SchemaResult>,
    status?: ExplorationMeasureStatus,
    hasError?: string[]
  ) => {
    const { onAddMeasure, onAddTable, onAddGroup } = props;

    const style = hasError && hasError.length > 0 ? { color: "#ff4d4f" } : {};

    return (
      <div
        className="measures-table-header"
        onClick={() => onClick("table", tableId)}
      >
        <div className="measures-table-header-name" style={style}>
          {status === "create" || status === "update" ? "• " : ""}
          {tableName}
        </div>
        <HasRoleAccess accessLevel={IUserRoleType.BUILDER}>
          <div
            className="measures-table-header-actions"
            onClick={(e) => e.stopPropagation()}
            id={tableId}
          >
            <Space size={2}>
              <Button shape="circle" type="text" size="small">
                {renderCheckIndicator(schema, hasError)}
              </Button>
              <Button shape="circle" type="text" size="small">
                <EditOutlined onClick={() => onClick("table", tableId)} />
              </Button>
              {(onAddMeasure || onAddTable) && (
                <span>
                  <Dropdown
                    trigger={["click"]}
                    placement="bottomRight"
                    {...{ arrow: true }}
                    menu={{
                      items: [
                        onAddMeasure &&
                          !disabledCapabilities.includes(
                            "table::add_dimension"
                          ) && {
                            key: 0,
                            onClick: () => onAddMeasure("dimension", tableId),
                            label: "Add a dimension",
                          },
                        onAddMeasure && {
                          key: 1,
                          onClick: () => onAddMeasure("metric", tableId),
                          label: "Add a metric",
                        },
                        onAddMeasure && {
                          key: 2,
                          onClick: () =>
                            onAddMeasure("metric", tableId, "COMPUTED"),
                          label: "Add a calculated metric",
                        },
                        onAddTable &&
                          !disabledCapabilities.includes(
                            "table::add_related_data"
                          ) && {
                            key: 3,
                            onClick: () => onAddTable(tableId),
                            label: "Add related data",
                          },
                        onAddGroup &&
                          !disabledCapabilities.includes(
                            "table::add_semantic_group"
                          ) && {
                            key: 4,
                            onClick: () => {
                              onAddGroup(tableId);
                            },
                            label: "Add a group",
                          },
                      ],
                    }}
                  >
                    <Button shape="circle" type="text" size="small">
                      <PlusOutlined />
                    </Button>
                  </Dropdown>
                </span>
              )}
            </Space>
          </div>
        </HasRoleAccess>
      </div>
    );
  };

  const renderObjectHeader = (
    objectId: string,
    objectName: string,
    schema: AsyncData<SchemaResult>,
    status?: ExplorationMeasureStatus,
    hasError?: string[]
  ) => {
    const { onAddMeasure, onAddTable, onAddGroup } = props;

    const style = hasError && hasError.length > 0 ? { color: "#ff4d4f" } : {};

    return (
      <div
        className="measures-table-header"
        onClick={() => onClick("object", objectId)}
      >
        <div className="measures-table-header-name" style={style}>
          {status === "create" || status === "update" ? "• " : ""}
          {objectName}
        </div>
        <HasRoleAccess accessLevel={IUserRoleType.BUILDER}>
          <div
            className="measures-table-header-actions"
            onClick={(e) => e.stopPropagation()}
            id={objectId}
          >
            <Space size={2}>
              <Button shape="circle" type="text" size="small">
                {renderCheckIndicator(schema, hasError)}
              </Button>
              <Button shape="circle" type="text" size="small">
                <EditOutlined onClick={() => onClick("object", objectId)} />
              </Button>
              {onAddProperty && (
                <span>
                  <Dropdown
                    trigger={["click"]}
                    placement="bottomRight"
                    {...{ arrow: true }}
                    menu={{
                      items: [
                        onAddProperty && {
                          key: 0,
                          onClick: () => onAddProperty(),
                          label: "Add a property",
                        },
                      ],
                    }}
                  >
                    <Button shape="circle" type="text" size="small">
                      <PlusOutlined />
                    </Button>
                  </Dropdown>
                </span>
              )}
            </Space>
          </div>
        </HasRoleAccess>
      </div>
    );
  };

  const renderGroupHeader = (
    groupId: string,
    groupName: string,
    status?: ExplorationMeasureStatus
  ) => {
    return (
      <div
        className="measures-table-header"
        onClick={() => onClick("semanticGroup", groupId)}
      >
        <div className="measures-table-header-name">
          {status === "create" || status === "update" ? "• " : ""}
          {groupName}
        </div>
        <Button shape="circle" type="text" size="small">
          <EditOutlined onClick={() => onClick("semanticGroup", groupId)} />
        </Button>
      </div>
    );
  };

  const buildExplorationTree = (): MeasureDataNode[] => {
    const tablePrefix = "table-";
    const groupPrefix = "semanticGroup-";
    const metricPrefix = "metric-";
    const dimensionPrefix = "dimension-";
    const tableData: MeasureDataNode[] = tables.map((table) => ({
      key: tablePrefix + table.id,
      title: renderTableHeader(
        table.id,
        objectInfos ? "Measures" : table.name,
        table.schema,
        table.status,
        table.hasError
      ),
      parentKey: table.incomingRelationship?.parentId
        ? tablePrefix + table.incomingRelationship?.parentId
        : null,
      isLeaf: false,
      selectable: true,
      checkable: false,
      draggable: false,
    }));

    const groupData: MeasureDataNode[] = tables
      .filter((t) => t.semanticGroups?.length >= 0)
      .flatMap((table) =>
        table.semanticGroups.sort(alphabeticalOrder).map((group) => ({
          key: groupPrefix + group.id,
          title: renderGroupHeader(group.id, group.name, group.status),
          parentKey: tablePrefix + table.id,
          isLeaf: false,
          selectable: true,
          checkable: false,
          draggable: false,
        }))
      );

    const dimensionData: MeasureDataNode[] = tables
      .filter((t) => t.dimensions?.length >= 0)
      .flatMap((table) =>
        table.dimensions.sort(alphabeticalOrder).map((dimension) => {
          const firstMatchingGroupId = (
            dimension.rawData.semanticGroupIds || []
          ).find((groupId) =>
            (table.semanticGroups || []).map((sm) => sm.id).includes(groupId)
          );
          return {
            key: dimensionPrefix + dimension.id,
            title: renderMeasureHeader(
              { item: dimension, type: "dimension" },
              table
            ),
            parentKey: firstMatchingGroupId
              ? groupPrefix + firstMatchingGroupId
              : tablePrefix + table.id,
            isLeaf: true,
            selectable: true,
            checkable: true,
            allowedGroups: !!firstMatchingGroupId
              ? table.semanticGroups
                  .map((sm) => sm.id)
                  .filter((groupId) => groupId !== firstMatchingGroupId)
              : (table.semanticGroups || []).map((sm) => sm.id),
            allowedTable: !!firstMatchingGroupId ? table.id : undefined,
            allowedMetrics: table.metrics
              .filter((m) => {
                const dimensionFirstMatchingGroupId = (
                  m.rawData.semanticGroupIds || []
                ).find((groupId) =>
                  (table.semanticGroups || [])
                    .map((sm) => sm.id)
                    .includes(groupId)
                );
                return dimensionFirstMatchingGroupId !== firstMatchingGroupId;
              })
              .map((m) => m.id),
            allowedDimensions: table.dimensions
              .filter((d) => d.id !== dimension.id)
              .filter((d) => {
                const dimensionFirstMatchingGroupId = (
                  d.rawData.semanticGroupIds || []
                ).find((groupId) =>
                  (table.semanticGroups || [])
                    .map((sm) => sm.id)
                    .includes(groupId)
                );
                return dimensionFirstMatchingGroupId !== firstMatchingGroupId;
              })
              .map((d) => d.id),
          };
        })
      );

    const metricData: MeasureDataNode[] = tables
      .filter((t) => t.metrics?.length >= 0)
      .flatMap((table) =>
        table.metrics.sort(alphabeticalOrder).map((metric) => {
          const firstMatchingGroupId = (
            metric.rawData.semanticGroupIds || []
          ).find((groupId) =>
            (table.semanticGroups || []).map((sm) => sm.id).includes(groupId)
          );
          return {
            key: metricPrefix + metric.id,
            title: renderMeasureHeader({ item: metric, type: "metric" }, table),
            parentKey: firstMatchingGroupId
              ? groupPrefix + firstMatchingGroupId
              : tablePrefix + table.id,
            isLeaf: true,
            selectable: true,
            checkable: true,
            allowedGroups: !!firstMatchingGroupId
              ? table.semanticGroups
                  .map((sm) => sm.id)
                  .filter((groupId) => groupId !== firstMatchingGroupId)
              : (table.semanticGroups || []).map((sm) => sm.id),
            allowedTable: !!firstMatchingGroupId ? table.id : undefined,
            allowedMetrics: table.metrics
              .filter((m) => m.id !== metric.id)
              .filter((m) => {
                const dimensionFirstMatchingGroupId = (
                  m.rawData.semanticGroupIds || []
                ).find((groupId) =>
                  (table.semanticGroups || [])
                    .map((sm) => sm.id)
                    .includes(groupId)
                );
                return dimensionFirstMatchingGroupId !== firstMatchingGroupId;
              })
              .map((m) => m.id),
            allowedDimensions: table.dimensions
              .filter((d) => {
                const dimensionFirstMatchingGroupId = (
                  d.rawData.semanticGroupIds || []
                ).find((groupId) =>
                  (table.semanticGroups || [])
                    .map((sm) => sm.id)
                    .includes(groupId)
                );
                return dimensionFirstMatchingGroupId !== firstMatchingGroupId;
              })
              .map((d) => d.id),
          };
        })
      );

    const treeData: MeasureDataNode[] = [
      ...dimensionData,
      ...metricData,
      ...groupData,
      ...tableData,
    ];

    return treeData;
  };

  const buildPropertiesTree = (): MeasureDataNode[] => {
    if (!objectInfos) {
      return [];
    }
    return [
      {
        key: `object-${objectInfos.id}`,
        title: renderObjectHeader(
          objectInfos.id,
          "Object",
          tables[0].schema,
          objectInfos.status,
          objectInfos.hasError
        ),
        children: objectInfos.properties.length
          ? objectInfos.properties.map((p) => {
              const update = p.status === "update" || p.status === "create";
              return {
                key: `property-${p.id}`,
                title: (
                  <div className={`measures-table-content`}>
                    <MeasureItem
                      isDragging={false}
                      type={"property"}
                      hidePopover={!!checkable}
                      error={p.hasError}
                      name={`${update ? "• " : ""}${
                        p.hierarchyPath ? `${p.hierarchyPath} / ` : ""
                      }${p.label}`}
                      description={p.description}
                      onClick={
                        onClick ? () => onClick("property", p.id) : undefined
                      }
                      onDelete={
                        onDeleteProperty
                          ? () => onDeleteProperty(p.id)
                          : undefined
                      }
                    />
                  </div>
                ),
                isLeaf: true,
              };
            })
          : [
              {
                key: `unknown-${objectInfos.id}`,
                selectable: false,
                disabled: true,
                title: <i>No properties...</i>,
              },
            ],
      },
    ];
  };

  const flatExplorationTreeData = buildExplorationTree();

  const propertiesTreeData = buildPropertiesTree();

  return (
    <>
      {header}
      <div className="measures-tree editor">
        <div className="measures-content">
          {objectInfos && (
            <Tree
              className="measure-table"
              draggable={!checkable}
              defaultExpandAll={true}
              selectedKeys={(selectedItems || []).map(
                (si) => si.type + "-" + si.id
              )}
              rootStyle={{ paddingBottom: 0 }}
              blockNode={true}
              selectable={true}
              showIcon={true}
              checkable={!!checkable}
              treeData={propertiesTreeData}
            />
          )}
          <Tree<MeasureDataNode>
            treeData={
              arrayToTree(flatExplorationTreeData, {
                dataField: null,
                id: "key",
                parentId: "parentKey",
              }) as DataNode[]
            }
            className="measure-table"
            draggable={!checkable}
            defaultExpandAll={true}
            selectedKeys={(selectedItems || []).map(
              (si) => si.type + "-" + si.id
            )}
            blockNode={true}
            selectable={true}
            showIcon={true}
            checkable={!!checkable}
            onDragStart={({ event, node }) => {
              if (
                !["metric", "dimension"].includes(
                  node.key.toString().split("-").shift()
                )
              ) {
                event.preventDefault();
                event.stopPropagation();
              }
            }}
            allowDrop={({ dropNode, dragNode, dropPosition }) => {
              const dropNodeKey = dropNode.key;
              const dropNodeType = (dropNodeKey as string).split(`-`)[0];
              const dropNodeId = dropNodeKey.toString().split("-").pop();
              if (dropPosition === -1) {
                // can't drop before tree top level
                return false;
              } else if (
                dropNodeType === "semanticGroup" &&
                dragNode.allowedGroups?.includes(dropNodeId)
              ) {
                // allow drop into a semantic group
                return true;
              } else if (
                dropNodeType === "table" &&
                dragNode.allowedTable?.includes(dropNodeId)
              ) {
                return true;
              } else if (
                dropNodeType === "metric" &&
                dropPosition === 1 &&
                dragNode.allowedMetrics?.includes(dropNodeId)
              ) {
                return true;
              } else if (
                dropNodeType === "dimension" &&
                dropPosition === 1 &&
                dragNode.allowedDimensions?.includes(dropNodeId)
              ) {
                return true;
              } else {
                // can't drop anywhere else
                return false;
              }
            }}
            onDrop={({ dragNode, node: dropNode }) => {
              const dragNodeKey = dragNode.key;
              const dragNodeType = (dragNodeKey as string).split(`-`).shift();
              const dragNodeId = dragNodeKey.toString().split("-").pop();

              let dropNodeKey = dropNode.key;
              if (
                dropNodeKey.toString().startsWith("metric-") ||
                dropNodeKey.toString().startsWith("dimension-")
              ) {
                const measure = flatExplorationTreeData.find(
                  (td) => td.key === dropNodeKey
                );
                dropNodeKey = measure.parentKey;
              }
              const dropNodeType = (dropNodeKey as string).split(`-`).shift();
              const dropNodeId = dropNodeKey.toString().split("-").pop();

              const groupId =
                dropNodeType === "semanticGroup" ? dropNodeId : undefined;

              const fromGroupId = flatExplorationTreeData
                .find((td) => td.key === dragNodeKey)
                .parentKey?.startsWith("semanticGroup-")
                ? flatExplorationTreeData
                    .find((td) => td.key === dragNodeKey)
                    .parentKey.split("-")
                    .pop()
                : undefined;

              const table = tables.find((t) => {
                if (dragNodeType === "metric") {
                  return !!t.metrics.find((m) => m.id === dragNodeId);
                } else if (dragNodeType === "dimension") {
                  return !!t.dimensions.find((d) => d.id === dragNodeId);
                } else {
                  return false;
                }
              });

              if (dropNodeType === "semanticGroup") {
                onMoveMeasureToGroup(
                  dragNodeType as MeasureType,
                  table.id,
                  dragNodeId,
                  groupId,
                  fromGroupId
                );
              } else if (dropNodeType === "table") {
                onMoveMeasureToGroup(
                  dragNodeType as MeasureType,
                  table.id,
                  dragNodeId,
                  undefined,
                  fromGroupId
                );
              }
            }}
            onCheck={
              checkable
                ? (_, { node: { key } }) => {
                    checkable.onCheck(
                      key as string,
                      !isChecked(checkable.checkedKeys, key)
                    );
                  }
                : undefined
            }
            checkStrictly={!!checkable}
            checkedKeys={checkable ? checkable.checkedKeys : undefined}
            style={treeStyle}
          ></Tree>
        </div>
      </div>
    </>
  );
}

export default compose<Props, IMeasureTreeProps>(
  WithOrg,
  withAntUtils
)(MeasureEditorTree);
