import { Button, Flex, Form, Select, Space, Tag, Typography } from "antd";
import { useForm } from "antd/es/form/Form";
import { useEffect, useState } from "react";
import type { InjectedAntUtilsProps } from "../../../../../../../components/ant-utils/withAntUtils";
import { withAntUtils } from "../../../../../../../components/ant-utils/withAntUtils";
import { WlyCard } from "../../../../../../../components/cards/WlyCard";
import { compose } from "../../../../../../../components/compose/WlyCompose";
import { SimpleFormModal } from "../../../../../../../components/form/modal/SimpleFormModal";
import type { IDestination } from "../../../../../../../interfaces/destinations";
import type { IRadar } from "../../../../../../../interfaces/radar";
import type { IDataset } from "../../../../../../../interfaces/sources";
import type { Transformation } from "../../../../../../../interfaces/transformations";
import { computeTransformations } from "../../../../../../../services/BrizoService";
import { generateUniqueId } from "../../../../../../../utils/uniqueId";
import type { UpdateRadarFunction } from "../../../../selector/radar/domain";

interface IRadarSignalDefinitionCardProps {
  currentWarehouse: IDestination;
  radar: IRadar;
  onUpdateRadar: UpdateRadarFunction;
}

type Props = IRadarSignalDefinitionCardProps & InjectedAntUtilsProps;

const { Text } = Typography;

const getSchemaForDataset = async (
  dataset: IDataset,
  currentWarehouseId: string,
  getRaw: boolean = false
) => {
  const prevId = generateUniqueId();
  const operation: Transformation = getRaw
    ? {
        var: prevId,
        domain: "dataset",
        operation: {
          type: "Table.FromWarehouseTable",
          args: {
            databaseName: dataset.warehouseDatabaseId,
            schemaName: dataset.warehouseSchemaId,
            tableName: dataset.warehouseTableId,
          },
        },
      }
    : {
        var: prevId,
        domain: "dataset",
        operation: {
          type: "Table.FromWhalyDataset",
          args: {
            datasetId: dataset.id,
          },
        },
      };
  return computeTransformations(currentWarehouseId, {
    schema: [
      operation,
      {
        var: generateUniqueId(),
        operation: {
          type: "Table.Schema",
          args: {
            table: prevId,
          },
        },
        domain: "viewResolver",
      },
    ],
  });
};

function RadarSignalDefinitionCard(props: Props) {
  const { antUtils, currentWarehouse, radar, onUpdateRadar } = props;
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [editionOpen, setEditionOpen] = useState<boolean>(false);
  const [autocompleteValues, setAutocompleteValues] = useState<string[]>([]);
  const [form] = useForm();

  useEffect(() => {
    const fetchAutocompleteResults = async () => {
      try {
        if (!radar.model) {
          return [];
        }
        const res = await getSchemaForDataset(
          radar.model,
          currentWarehouse.id,
          false
        );
        if (res.data.schema) {
          setAutocompleteValues(Object.keys(res.data.schema).sort());
        } else {
          setAutocompleteValues([]);
        }
      } catch (error) {
        console.error("Error while fetching results");
        setAutocompleteValues([]);
      }
    };
    if (editionOpen) {
      fetchAutocompleteResults();
    }
    if (!editionOpen) {
      setAutocompleteValues([]);
    }
  }, [editionOpen]);

  return (
    <>
      <WlyCard
        title="Signal definition"
        extra={
          <Button
            type="primary"
            size="small"
            onClick={() => setEditionOpen(true)}
          >
            Edit
          </Button>
        }
      >
        <Space direction="vertical" style={{ display: "flex" }} size={20}>
          <Flex>
            <div style={{ flex: 1 }}>
              <Text strong>Unique identifier</Text>
              <br />
              <Text type="secondary">
                Unique key that will make sure signals are unique
              </Text>
            </div>
            <div>
              <Space>
                {radar.uniqueKeys ? (
                  radar.uniqueKeys
                    .split(",")
                    .map((key, index) => <Tag key={index}>{key}</Tag>)
                ) : (
                  <Text type="secondary">Not set</Text>
                )}
              </Space>
            </div>
          </Flex>
          <Flex>
            <div style={{ flex: 1 }}>
              <Text strong>Metadata keys</Text>
              <br />
              <Text type="secondary">Keys used in the rendering context</Text>
            </div>
            <div>
              <Space>
                {radar.eventKeys ? (
                  radar.eventKeys
                    .split(",")
                    .map((key, index) => <Tag key={index}>{key}</Tag>)
                ) : (
                  <Text type="secondary">Not set</Text>
                )}
              </Space>
            </div>
          </Flex>
        </Space>
      </WlyCard>
      <SimpleFormModal
        open={editionOpen}
        isSaving={isLoading}
        form={form}
        modalProps={{ title: "Update radar" }}
        onCancel={() => {
          setEditionOpen(false);
        }}
      >
        <Form
          layout="vertical"
          form={form}
          initialValues={{
            uniqueKeys: radar.uniqueKeys ? radar.uniqueKeys.split(",") : [],
            eventKeys: radar.eventKeys ? radar.eventKeys.split(",") : [],
          }}
          onFinish={async (values) => {
            try {
              setIsLoading(true);
              await onUpdateRadar(radar.id, {
                uniqueKeys: (values.uniqueKeys || "").join(","),
                eventKeys: (values.eventKeys || "").join(","),
              });
              antUtils.message.success("Radar successfully updated");
            } catch (error) {
              console.error(error);
              antUtils.message.error("Error while updating radar");
            } finally {
              setIsLoading(false);
              setEditionOpen(false);
            }
          }}
        >
          <Form.Item label="Unique keys" name={"uniqueKeys"}>
            <Select
              mode="tags"
              style={{ width: "100%" }}
              placeholder="Unique keys"
              allowClear
              options={autocompleteValues.map((v) => ({ label: v, value: v }))}
            />
          </Form.Item>
          <Form.Item label="Metadata keys" name={"eventKeys"}>
            <Select
              mode="tags"
              style={{ width: "100%" }}
              placeholder="Metadata keys"
              allowClear
              options={autocompleteValues.map((v) => ({ label: v, value: v }))}
            />
          </Form.Item>
        </Form>
      </SimpleFormModal>
    </>
  );
}

export default compose<Props, IRadarSignalDefinitionCardProps>(withAntUtils)(
  RadarSignalDefinitionCard
);
