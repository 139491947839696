import type { chartOptionRenderer } from "../../../containers/chart-options/ChartOptions";
import type { IAnalysisType } from "../../../containers/exploration/single/domain";
import { WlyPieChartIcon } from "../../icons/custom-icons/WlyPieChartIcon";
import type { SingleChartDefinition } from "../domain";

export const pieChartDefinition: SingleChartDefinition = {
  label: "Pie chart",
  icon: <WlyPieChartIcon />,
  allowTimeComparison: false,
  canPredict: () => false,
  minDimensions: 1,
  minMetrics: 1,
  maxMetrics: 1,
  pivot: (analysisType: IAnalysisType) => false,
  canDisplay: (rs) => {
    if ((rs as any).queryType === "compareDateRangeQuery") {
      return "Cannot use a date comparison query on a pie chart";
    }
    return;
  },
  config: ["labels", "pie-labels-in-percent", "palette"],
  declarativeChartOptionRenderer: (options) => {
    const template: chartOptionRenderer = [
      {
        label: "Chart options",
        lines: [
          {
            type: "composed",
            items: [
              {
                flex: 1,
                type: "label",
                title: "Label",
              },
              { flex: 1, type: "option", option: "pie-labels-in-percent" },
              { flex: 0, type: "option", option: "labels" },
            ],
          },
          {
            type: "standard",
            component: "palette",
          },
        ],
      },
    ];
    return template;
  },
  axis: {
    bottom: {
      editable: false,
      isTimeFormattable: (a) => false,
    },
    left: {
      editable: false,
    },
    right: {
      editable: false,
    },
  },
  extractCustomizationDimensions: (d, q) => {
    return {
      dimensions: [],
    };
  },
  extractCustomizationSeries: (s, data, q) => {
    const series = data.map((d) => {
      const dimensionValues = [];
      const dimensionsKeys = Object.keys(d).filter(
        (k) => !q.selectedMeasures.includes(k)
      );

      dimensionsKeys.forEach((dk) => dimensionValues.push(d[dk]));

      const sanitizedDimensionValues = dimensionValues.map((v) => v ?? "∅");

      return {
        key: sanitizedDimensionValues.join(","),
        label: sanitizedDimensionValues.join(", "),
      };
    });

    return {
      series: series,
      customColor: true,
      customLabel: true,
      customType: [],
    };
  },
};
