import type { InjectedAntUtilsProps } from "../../../../../components/ant-utils/withAntUtils";
import { withAntUtils } from "../../../../../components/ant-utils/withAntUtils";
import { compose } from "../../../../../components/compose/WlyCompose";
import type { IExploration } from "../../../../../interfaces/explorations";
import type { InjectedOrgProps } from "../../../../orgs/WithOrg";
import WithOrg from "../../../../orgs/WithOrg";
import QueryModeCard from "../../servingLayer/queryModeCard";

interface IExplorationQueryModeCardProps {
  exploration: IExploration;
  onExplorationExtractDataNow: (explorationId: string) => Promise<void>;
  onExplorationUpdate: (id: string, payload: any) => Promise<void>;
}

type Props = IExplorationQueryModeCardProps &
  InjectedOrgProps &
  InjectedAntUtilsProps;

function ExplorationQueryModeCard(props: Props) {
  const { exploration, onExplorationUpdate, onExplorationExtractDataNow, org } =
    props;

  return (
    <QueryModeCard
      isExtractEnabled={exploration.isExtractEnabled}
      extractSchedule={exploration.extractSchedule}
      objectType={"Exploration"}
      objectId={exploration.id}
      tables={exploration.tables}
      onExtractDataNow={onExplorationExtractDataNow}
      onSave={async (queryMode, dataExtractConfig) => {
        if (onExplorationUpdate) {
          const isExtractEnabled = queryMode === "DIRECT_QUERY" ? false : true;

          const data = {
            isExtractEnabled,
          };

          if (queryMode === "SERVING_LAYER") {
            data["extractSchedule"] = {
              create: {
                name: `${exploration.name} - Serving Layer extract`,
                period: dataExtractConfig.period,
                atHour: dataExtractConfig.atHour,
                dailySendEvery: dataExtractConfig.dailySendEvery,
                timezone: dataExtractConfig.timezone,
                type: "extract",
                org: {
                  connect: {
                    id: org.id,
                  },
                },
              },
            };
          } else {
            data["extractSchedule"] = {
              disconnectAll: true,
            };
          }
          await onExplorationUpdate(exploration.id, data);
        }
      }}
    />
  );
}

export default compose<Props, IExplorationQueryModeCardProps>(
  WithOrg,
  withAntUtils
)(ExplorationQueryModeCard);
