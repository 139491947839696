import type { IAnalysisType } from "../../../containers/exploration/single/domain";
import { WlyHeatmapChartIcon } from "../../icons/custom-icons/WlyHeatmapChartIcon";
import type { SingleChartDefinition } from "../domain";

export const heatmapChartDefinition: SingleChartDefinition = {
  label: "Heatmap",
  allowTimeComparison: false,
  icon: <WlyHeatmapChartIcon />,
  canPredict: () => false,
  pivot: (analysisType: IAnalysisType) => false,
  minDimensions: 2,
  maxDimensions: 2,
  minMetrics: 1,
  maxMetrics: 1,
  canDisplay: (rs) => {
    if ((rs as any).queryType === "compareDateRangeQuery") {
      return "Cannot use a date comparison query on a heatmap";
    }
    return;
  },
  config: ["labels", "hide-legend", "palette"],
  axis: {
    bottom: {
      editable: true,
      isTimeFormattable: (a) => false,
      reorderable: (s, t, q) => {
        const xDimension = q.selectedDimensions[0];
        if (t.length && xDimension) {
          const map = new Set<string>();
          t.forEach((d) => map.add(d[xDimension]));
          return Array.from(map);
        }
      },
    },
    left: {
      editable: true,
      reorderable: (s, t, q) => {
        const leftDimension = q.selectedDimensions[1];
        if (t.length && leftDimension) {
          const map = new Set<string>();
          t.forEach((d) => map.add(d[leftDimension]));
          return Array.from(map);
        }
      },
    },
    right: {
      editable: false,
    },
  },
  extractCustomizationDimensions: (d, q) => {
    return {
      dimensions: [],
    };
  },
  extractCustomizationSeries: (s, d) => {
    return {
      series: s,
      customColor: false,
      customLabel: true,
      customType: [],
    };
  },
};
