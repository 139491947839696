import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Form, Input, Modal, Select, Space, Switch } from "antd";
import * as React from "react";
import type { RouteComponentProps } from "react-router";
import { withRouter } from "react-router";
import type { InjectedAntUtilsProps } from "../../../../components/ant-utils/withAntUtils";
import { withAntUtils } from "../../../../components/ant-utils/withAntUtils";
import { compose } from "../../../../components/compose/WlyCompose";
import type { IExploration } from "../../../../interfaces/explorations";
import type { IFilter, ISharingLink } from "../../../../interfaces/reports";
import GraphQLService from "../../../../services/graphql/GraphQLService";
import type { InjectedOrgProps } from "../../../orgs/WithOrg";
import WithOrg from "../../../orgs/WithOrg";
import { FILTER_ARRAY_SEPARATOR } from "../../domain";
import FieldFilter from "../filters/field-filter/FieldFilter";
import "./Share.scss";

interface IShareModalProps {
  reportId: string;
  visible: boolean | ISharingLinkInitalData;
  filters: IFilter[];
  setVisible: (v: boolean) => void;
  explorations: IExploration[];
}

export interface ISharingLinkInitalData
  extends Omit<ISharingLink, "id" | "org" | "publicToken" | "deleted"> {
  id?: string;
}

type Props = IShareModalProps &
  InjectedAntUtilsProps &
  RouteComponentProps<{ organizationSlug: string }> &
  InjectedOrgProps;

const ShareModal: React.FunctionComponent<Props> = (props) => {
  const { antUtils } = props;
  const [form] = Form.useForm();
  const [saving, setSaving] = React.useState<boolean>(false);

  let initialValues: ISharingLinkInitalData =
    typeof props.visible === "boolean"
      ? {
          name: "",
          isPasswordProtected: false,
          password: "",
          controlledValues: [],
          disableDrills: false,
        }
      : {
          ...props.visible,
          controlledValues: props.visible.controlledValues.map((c) => {
            const values = c.value;
            return {
              ...c,
              value: Array.isArray(values) ? values : ([values] as any),
            };
          }),
        };

  React.useEffect(() => {
    form.resetFields();
    if (props.visible) {
      form.setFieldsValue(initialValues);
    }
  }, [props.visible]);

  const onDelete = () => {
    if (initialValues && initialValues.id) {
      return antUtils.modal.confirm({
        title: "Do you confirm?",
        content:
          "You are about to remove a sharing link, this cannot be undone. Are you sure you want to proceed?",
        okButtonProps: {
          danger: true,
        },
        okText: "Delete",
        onOk: () => {
          return GraphQLService(
            `
            mutation removeSharingLink($sharingLinkId: ID!) {
              updateReportSharingLink(id: $sharingLinkId, data: { deleted: true }) {id}
            }
            `,
            {
              sharingLinkId: initialValues?.id,
            }
          ).then((r) => {
            return props.setVisible(false);
          });
        },
      });
    }
  };

  const onSave = (a: ISharingLinkInitalData) => {
    const { controlledValues, ...values } = a;
    const controlledValuesData = (controlledValues || []).map((f) => {
      return {
        value: f.value ? f.value.join(FILTER_ARRAY_SEPARATOR) : null,
        filter: {
          connect: {
            id: f.filter.id,
          },
        },
        org: {
          connect: {
            id: props.org.id,
          },
        },
      };
    });
    setSaving(true);
    if (values.id) {
      const { id, ...rest } = values;
      return GraphQLService(
        `
        mutation updateReportSharingLink($sharingLinkId: ID!, $data: ReportSharingLinkUpdateInput, $controlledValues: [FilterControledValuesUpdateInput]) {
          updateReportSharingLink(id: $sharingLinkId, data: $data) {
            id
          }
          updateFilterControledValues(data: $controlledValues) {
            id
          }
        }
      `,
        {
          sharingLinkId: id,
          controlledValues: initialValues.controlledValues.map((v) => {
            return {
              id: v.id,
              data: {
                deleted: true,
              },
            };
          }),
          data: {
            ...rest,
            controlledValues: {
              create: controlledValuesData,
            },
          },
        }
      ).then(() => {
        setSaving(false);
      });
    } else {
      return GraphQLService(
        `
        mutation createReportSharingLink($data: ReportSharingLinkCreateInput) {
          createReportSharingLink(data: $data) {
            id
          }
        }
      `,
        {
          data: {
            ...values,
            controlledValues: {
              create: controlledValuesData,
            },
          },
        }
      ).then(() => {
        setSaving(false);
      });
    }
  };

  const renderForm = () => {
    if (initialValues && (initialValues as any).__typename) {
      delete (initialValues as any).__typename;
    }

    return (
      <Form
        form={form}
        layout="vertical"
        initialValues={initialValues}
        onFieldsChange={(change, val) => {
          if (
            change &&
            change[0] &&
            Array.isArray(change[0].name) &&
            change[0].name[change[0].name.length - 1] === "filter_id"
          ) {
            const values = form.getFieldsValue();
            const index = change[0].name[1];
            values.controlledValues[index].value = undefined;
            form.setFieldsValue(values);
          }
        }}
      >
        <Form.Item name="name" label="Name">
          <Input />
        </Form.Item>
        <Form.Item
          name="isPasswordProtected"
          label="Protect with a password"
          valuePropName="checked"
        >
          <Switch />
        </Form.Item>
        <Form.Item shouldUpdate={true} noStyle={true}>
          {() => {
            if (form.getFieldValue("isPasswordProtected")) {
              return (
                <Form.Item name="password" label="Password">
                  <Input type={"password"} />
                </Form.Item>
              );
            }
          }}
        </Form.Item>
        <Form.Item
          name="disableDrills"
          label="Disable drills"
          valuePropName="checked"
        >
          <Switch />
        </Form.Item>
        {props.filters.length ? (
          <Form.List name="controlledValues">
            {(fields, { add, remove }) => (
              <>
                {fields.map((field) => (
                  <Space
                    key={field.key}
                    align="center"
                    style={{ width: "100%" }}
                  >
                    <Form.Item
                      {...field}
                      label="Filter"
                      name={[field.name, "filter", "id"]}
                      rules={[{ required: true, message: "Missing filter" }]}
                      style={{ width: 212 }}
                    >
                      <Select>
                        {props.filters.map((f, i) => {
                          return (
                            <Select.Option key={i} value={f.id}>
                              {f.name}
                            </Select.Option>
                          );
                        })}
                      </Select>
                    </Form.Item>

                    <Form.Item noStyle={true} shouldUpdate={true}>
                      {() => {
                        const f = form.getFieldValue([
                          "controlledValues",
                          field.name,
                          "filter",
                          "id",
                        ]);
                        const filter = props.filters.find((fi) => fi.id === f);
                        const flatExplorations = props.explorations.flatMap(
                          (ex) =>
                            ex.tables.flatMap((t) =>
                              t.dimensions.map((d) => ({
                                ...d,
                                explorationId: ex.id,
                                generatedId: `${t.cubeName}.${d.cubeName}`,
                              }))
                            )
                        );
                        return (
                          filter && (
                            <Form.Item
                              {...field}
                              label="Value"
                              name={[field.name, "value"]}
                              initialValue={
                                filter.type === "TIME" ? "LAST_7_DAYS" : null
                              }
                              rules={[
                                {
                                  required: true,
                                  message: "Missing filter value",
                                },
                              ]}
                              style={{ width: 212 }}
                            >
                              <FieldFilter
                                editing={false}
                                labelDimension={filter.labelDimension}
                                valueDimension={filter.valueDimension}
                                controlledBy={[]}
                                tilesToUpdate={[]}
                                requireValue={filter.requireValue}
                                componentType={filter.componentType}
                                filterType={filter.type}
                                objectId={
                                  flatExplorations.find(
                                    (e) =>
                                      e.generatedId === filter.valueDimension
                                  )?.explorationId || ""
                                }
                                objectType={"EXPLORATION"}
                                autocompleteLimit={filter.filterLimit}
                              />
                            </Form.Item>
                          )
                        );
                      }}
                    </Form.Item>
                    <MinusCircleOutlined onClick={() => remove(field.name)} />
                  </Space>
                ))}

                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    block
                    icon={<PlusOutlined />}
                  >
                    Set a filter value
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
        ) : null}
      </Form>
    );
  };

  const renderFooter = () => {
    return (
      <div style={{ display: "flex" }}>
        <div style={{ flex: "1 1 auto", textAlign: "left" }}>
          {initialValues && initialValues.id && (
            <Button onClick={onDelete} disabled={saving} ghost danger={true}>
              Delete
            </Button>
          )}
        </div>
        <div>
          <Button onClick={() => props.setVisible(false)}>Cancel</Button>
          <Button
            type="primary"
            loading={saving}
            onClick={() => {
              setSaving(true);
              return form
                .validateFields()
                .then((values) => {
                  const v = values as FormData;
                  const newValues: any = {
                    ...initialValues,
                    ...v,
                    report: {
                      connect: {
                        id: props.reportId,
                      },
                    },
                    org: {
                      connect: {
                        id: props.org.id,
                      },
                    },
                  };
                  return onSave(newValues)
                    .then(() => setSaving(false))
                    .then(() => props.setVisible(false));
                })
                .catch((info) => {
                  console.error(info);
                  antUtils.message.error(info.error);
                  setSaving(false);
                });
            }}
          >
            Save
          </Button>
        </div>
      </div>
    );
  };

  return (
    <Modal
      title="Public Access"
      open={!!props.visible}
      maskClosable={true}
      footer={renderFooter()}
      onCancel={() => props.setVisible(false)}
    >
      {renderForm()}
    </Modal>
  );
};

export default compose<Props, IShareModalProps>(
  withRouter,
  WithOrg,
  withAntUtils
)(ShareModal);
