import { message } from "antd";
import type { GqlError } from "../interfaces/gql";

export const handleGQLErrors =
  (callback?: () => void) => (err: { errors: GqlError[] } | string) => {
    console.error(err);
    if (typeof err === "string") {
      message.error(err, 4);
    } else {
      err.errors.forEach((error) => {
        message.error(error.message, 4);
      });
    }
    callback && callback();
  };
