import type { DurationInputArg2 } from "moment";
import moment from "moment";

export const format = "YYYY-MM-DD HH:mm:ss";

export function getDatesFromObservationPeriod(period: string) {
  if (period === "now") {
    return moment().format(format);
  }
  if (period.startsWith("now")) {
    const p = getPeriodValue("now", period);
    const m = moment();
    if (p.comparison === "+") {
      m.add(p.value, p.unit);
    } else {
      m.subtract(p.value, p.unit);
    }
    return m.format(format);
  }
  return moment(period).format(format);
}

function getComparator(s: string) {
  if (s.startsWith("+")) {
    return "+";
  }
  // default to previous
  return "-";
}

function getUnit(s: string): DurationInputArg2 {
  if (
    observationUnit.reduce((acc, val) => {
      return !acc ? s.endsWith(val) : acc;
    }, false)
  ) {
    return s.substring(s.length - 1) as DurationInputArg2;
  }
  // default to days
  return "d";
}

function getPeriodValue(baseline: string, period: string) {
  const trimmedPeriod = period.substring(baseline.length);
  const comparison = getComparator(trimmedPeriod);
  const unit = getUnit(trimmedPeriod);
  const value = trimmedPeriod.match(/\d+/);
  if (value) {
    // return the first match
    return {
      comparison,
      unit,
      value: parseInt(value[0]),
    };
  }
  // default previous to 30 days
  return {
    comparison,
    unit,
    value: 30,
  };
}

const observationUnit: DurationInputArg2[] = [
  "M",
  "y",
  "w",
  "d",
  "h",
  "m",
  "s",
];
