import { emailWidgetButtonDefinition } from "./button/EmailWidgetButton.definition";
import { emailWidgetDividerDefinition } from "./divider/EmailWidgetDivider.definition";
import type { IEmailRendererConfig, IEmailWidgetType } from "./domain";
import { notImplemented } from "./domain";
import { emailWidgetImageDefinition } from "./image/EmailWidgetImage.definition";
import { emailWidgetPropertyListDefinition } from "./property-list/EmailWidgetPropertyList.definition";
import { emailWidgetRelatedListDefinition } from "./related-list/EmailRelatedListWidget.definition";
import { emailWidgetRepeatableDefinition } from "./repeatable/EmailWidgetRepeatable.definition";
import { emailWidgetSpacerDefinition } from "./spacer/EmailWidgetSpacer.definition";
import { emailWidgetTextDefinition } from "./text/EmailWidgetText.definition";

export const rendererConfig: {
  [key in IEmailWidgetType]: IEmailRendererConfig<any>;
} = {
  properties: notImplemented,
  image: emailWidgetImageDefinition,
  text: emailWidgetTextDefinition,
  button: emailWidgetButtonDefinition,
  spacer: emailWidgetSpacerDefinition,
  "property-list": emailWidgetPropertyListDefinition,
  "related-list": emailWidgetRelatedListDefinition,
  divider: emailWidgetDividerDefinition,
  repeatable: emailWidgetRepeatableDefinition,
};
