export function getInnerHeight(elm: HTMLElement): number {
  const computed = getComputedStyle(elm);
  const paddingTop = computed.paddingTop;
  const paddingBottom = computed.paddingBottom;
  const marginTop = computed.marginTop;
  const marginBottom = computed.marginBottom;
  const padding =
    parseInt(paddingTop ? paddingTop : "0") +
    parseInt(paddingBottom ? paddingBottom : "0");
  const margin =
    parseInt(marginTop ? marginTop : "0") +
    parseInt(marginBottom ? marginBottom : "0");
  return elm.clientHeight - padding - margin;
}

export function getInnerWidth(elm: HTMLElement): number {
  const computed = getComputedStyle(elm);
  const paddingLeft = computed.paddingLeft;
  const paddingRight = computed.paddingRight;
  const padding =
    parseInt(paddingLeft ? paddingLeft : "0") +
    parseInt(paddingRight ? paddingRight : "0");
  return elm.clientWidth - padding;
}

export function getOuterHeight(elm: HTMLElement): number {
  return elm.clientHeight;
}
