import { gql } from "@apollo/client";
import type { MutateOptions } from "../../../../../../../../components/hooks/query/useQGLMutate";
import { useGQLMutate } from "../../../../../../../../components/hooks/query/useQGLMutate";
import { OBJECT_PRESET_FILTER_FRAGMENT } from "../../../../../../../../fragments/object";
import type { IObjectPresetFilter } from "../../../../../../../../interfaces/object";

type MutationData = { createObjectPresetFilter: IObjectPresetFilter };

type MutationVariables = {
  data: Record<string, any>;
};

const CreateObjectPresetFilterMutation = gql`
  ${OBJECT_PRESET_FILTER_FRAGMENT}

  mutation CreateObjectPresetFilter($data: ObjectPresetFilterCreateInput!) {
    createObjectPresetFilter(data: $data) {
      ...ObjectPresetFilterFragment
    }
  }
`;

export const useCreateObjectPresetFilter = (
  options?: MutateOptions<MutationData>
) => {
  return useGQLMutate<MutationData, MutationVariables>(
    CreateObjectPresetFilterMutation,
    options
  );
};
