import type { ReactNode } from "react";
import type { IGroup } from "../../interfaces/group";
import GroupAvatar from "./avatar/GroupAvatar";
import "./GroupRenderer.scss";

interface IGroupRendererProps {
  group: IGroup;
  description?: ReactNode;
}

export function GroupRenderer(props: IGroupRendererProps) {
  const { group, description } = props;
  return (
    <div className="group-renderer-info">
      <div className="group-renderer-image">
        <GroupAvatar group={group} />
      </div>
      <div className="group-renderer-meta">
        <div className="group-renderer-name">{group.name}</div>
        <div className="group-renderer-member-count">
          {group.members.count} people
        </div>
        {description ? (
          <div className="group-renderer-description">{description}</div>
        ) : undefined}
      </div>
    </div>
  );
}
