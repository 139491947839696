import { Select } from "antd";
import type { IAccessType } from "../../../interfaces/reportSharing";
import "./AccessTypePickerRenderer.scss";
const { Option } = Select;

interface AccessTypeRendererProps {
  className?: string;
  disabled?: boolean;
  currentAccessType: IAccessType;
  showRemove?: boolean;
  onAccessTypeChange: (newType: IAccessType) => void;
  onRemoveAccess?: () => void;
  availableAccessTypes: Array<IAccessType>;
}

export function AccessTypePickerRenderer(props: AccessTypeRendererProps) {
  const {
    currentAccessType,
    showRemove,
    onAccessTypeChange,
    onRemoveAccess,
    className,
    disabled,
    availableAccessTypes,
  } = props;

  return (
    <div className={className}>
      <Select
        disabled={disabled}
        defaultValue={currentAccessType}
        bordered={false}
        className="access-type-renderer"
        popupMatchSelectWidth={false}
        placement="bottomRight"
        optionLabelProp={"label"}
        onChange={(value: IAccessType | "remove") => {
          if (value === "remove") {
            onRemoveAccess?.();
          } else {
            onAccessTypeChange(value);
          }
        }}
      >
        <Option
          style={{
            display: availableAccessTypes.includes("manage")
              ? undefined
              : "none",
          }}
          value="manage"
          label="Full access"
        >
          <div>Full access</div>
          <div className="access-type-renderer-description">
            Can edit and share with others.
          </div>
        </Option>

        <Option
          style={{
            display: availableAccessTypes.includes("edit") ? undefined : "none",
          }}
          value="edit"
          label="Can edit"
        >
          Can edit
          <br />
          <div className="access-type-renderer-description">
            Can edit, but not share with others.
          </div>
        </Option>

        <Option
          style={{
            display: availableAccessTypes.includes("view") ? undefined : "none",
          }}
          value="view"
          label="Can view"
        >
          Can view
          <br />
          <div className="access-type-renderer-description">
            Cannot edit or share with others.
          </div>
        </Option>

        {showRemove ? (
          <Option value="remove" label="Remove">
            <span style={{ color: "red" }}>Remove</span>
          </Option>
        ) : undefined}
      </Select>
    </div>
  );
}
