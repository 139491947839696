import { Flex, Space, Typography } from "antd";
import moment from "moment";
import type { INotificationJob } from "../../../../../interfaces/notification";
import { getJobColor, getJobSimplifiedStatus } from "./domain";

interface IJobStatusProps {
  job: INotificationJob;
}

export function JobStatus(props: IJobStatusProps) {
  const { job } = props;
  return (
    <Flex>
      <div style={{ marginRight: 16, paddingTop: 4 }}>
        <div
          style={{
            height: 16,
            width: 16,
            background: getJobColor(job),
            borderRadius: 8,
          }}
        />
      </div>
      <Space direction="vertical" size={"small"} style={{ display: "flex" }}>
        <Typography.Text strong>{getJobSimplifiedStatus(job)}</Typography.Text>
        <Typography.Text type="secondary">
          {moment(job.executeAt).format("YYYY-MM-DD HH:mm:SS")}
        </Typography.Text>
      </Space>
    </Flex>
  );
}
