import data from "@emoji-mart/data";
import Picker from "@emoji-mart/react";
import React, { Suspense } from "react";
import "./EmojiPicker.scss";
import EmojiRenderer from "./EmojiRenderer";

const emojis = Object.keys((data as any).emojis);

interface IColorPickerProps {
  value?: string;
  onChange?: (v: string) => void;
  size?: number;
}

interface IState {
  visible: boolean;
  randomEmoji: string;
}

export default class EmojiPicker extends React.Component<
  IColorPickerProps,
  IState
> {
  constructor(props: IColorPickerProps) {
    super(props);
    this.state = {
      visible: false,
      randomEmoji: "",
    };
  }

  computeRandomEmoji = () => {
    const numberOfEmojis = emojis.length;
    const randomEmoji =
      emojis[Math.round(Math.random() * (numberOfEmojis - 1))];
    return `:${randomEmoji}:`;
  };

  componentDidMount() {
    const { onChange, value } = this.props;
    if (onChange && value === "random") {
      const randomEmoji = this.computeRandomEmoji();
      this.setState({ randomEmoji: randomEmoji }, () => {
        onChange(randomEmoji);
      });
    }
  }

  public render() {
    const { value, onChange, size } = this.props;

    const val = value;
    const handleClose = () => this.setState({ visible: !this.state.visible });
    const handleOpen = () => this.setState({ visible: true });
    return (
      <div className="emoji-picker">
        {this.state.visible && (
          <div className="emoji-picker-popover">
            <div className="emoji-picker-cover" onClick={handleClose} />
          </div>
        )}
        {val && val !== "random" && (
          <EmojiRenderer size={size} emoji={val} onClick={handleOpen} />
        )}
        {this.state.visible && (
          <div className="emoji-picker-popover picker">
            <Suspense fallback={<></>}>
              <Picker
                theme={"auto"}
                data={data}
                defaultSkin={1}
                onEmojiSelect={(e) => {
                  if (onChange) {
                    onChange((e as any).shortcodes);
                    handleClose();
                  }
                }}
              />
            </Suspense>
          </div>
        )}
      </div>
    );
  }
}
