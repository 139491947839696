import { Form, Input, Radio } from "antd";

export interface IMapMarkerRadiusPickerValue {
  type?: "circle";
  radius?: IProportionalRadius | IEqualRadius | IFixedRadius;
}

interface IProportionalRadius {
  type?: "proportional";
  minRadius?: string;
  maxRadius?: string;
}

interface IEqualRadius {
  type?: "equal";
}

interface IFixedRadius {
  type?: "fixed";
  radius?: string;
}

interface IMapMarkerRadiusPickerProps {
  value?: IMapMarkerRadiusPickerValue;
  onChange?: (v: IMapMarkerRadiusPickerValue) => void;
  initialValue?: IMapMarkerRadiusPickerValue;
}

export function MapMarkerRadiusPicker(props: IMapMarkerRadiusPickerProps) {
  const { value, onChange } = props;

  const renderSpecificForm = (t: IMapMarkerRadiusPickerValue) => {
    switch (t?.radius?.type) {
      case "fixed":
        return (
          <>
            <Form.Item label="Radius">
              <Input
                value={(t?.radius as IFixedRadius)?.radius}
                type="number"
                onChange={(e) => {
                  onChange({
                    ...value,
                    radius: {
                      ...value.radius,
                      radius: e.target.value,
                    } as IFixedRadius,
                  });
                }}
                addonAfter={"km"}
              />
            </Form.Item>
          </>
        );
      case "equal":
        return null;
      case "proportional":
        return (
          <>
            <Form.Item label={"Min radius"}>
              <Input
                value={t?.radius?.minRadius}
                type="number"
                onChange={(e) => {
                  onChange({
                    ...value,
                    radius: {
                      ...value.radius,
                      minRadius: e.target.value,
                    } as IProportionalRadius,
                  });
                }}
                addonAfter={"km"}
              />
            </Form.Item>
            <Form.Item label={"Max radius"}>
              <Input
                value={t?.radius?.maxRadius}
                type="number"
                onChange={(e) => {
                  onChange({
                    ...value,
                    radius: {
                      ...value.radius,
                      maxRadius: e.target.value,
                    } as IProportionalRadius,
                  });
                }}
                addonAfter={"km"}
              />
            </Form.Item>
          </>
        );
    }
  };

  return (
    <div>
      <Form.Item>
        <Radio.Group
          options={[
            {
              label: "Proportional",
              value: "proportional",
            },
            {
              label: "Equal",
              value: "equal",
            },
            {
              label: "Fixed",
              value: "fixed",
            },
          ]}
          onChange={(v) => {
            const newValue: IMapMarkerRadiusPickerValue = {
              type: "circle",
              radius: {
                type: v.target.value as any,
              },
            };
            onChange(newValue);
          }}
          value={value?.radius?.type}
        />
      </Form.Item>
      {renderSpecificForm(value)}
    </div>
  );
}
