import { MenuOutlined } from "@ant-design/icons";
import { Avatar } from "antd";
import { useState } from "react";
import defaultLogo from "../../../../assets/logo/logo_square_dark.svg";
import { useHover } from "../../../../components/hooks/useHover";

interface IOrgLogoV2Props {
  className?: string;
  logo?: string;
  open: boolean;
  onClick?: () => void;
}

export function OrgLogoV2(props: IOrgLogoV2Props) {
  const { className, logo, open, onClick } = props;
  const [error, setError] = useState<boolean>(false);
  const [ref, isHover] = useHover<HTMLDivElement>();
  const isSwitcherDisplayed = isHover || !open || false;
  return (
    <div
      ref={ref}
      onClick={() => {
        onClick?.();
      }}
      className={className}
      style={{ width: 32, height: 32, cursor: "pointer", position: "relative" }}
    >
      <Avatar
        icon={<MenuOutlined style={{ color: "#5D6671" }} />}
        shape="square"
        style={{
          opacity: isSwitcherDisplayed ? 1 : 0,
          position: "absolute",
          background: "#F9F8F8",
          borderColor: "#F9F8F8",
        }}
      />
      <Avatar
        src={error ? defaultLogo : logo || defaultLogo}
        shape="square"
        onError={() => {
          setError(true);
          return false;
        }}
        style={{
          opacity: isSwitcherDisplayed ? 0 : 1,
          transition: "opacity 0.25s",
          position: "absolute",
          borderColor: "rgb(216, 216, 216)",
          background: "white",
        }}
      />
    </div>
  );
}
