import { Popover, Skeleton, Space, Tag, Typography } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { FromNow } from "../../../components/moment/FromNow";
import UserAvatar from "../../../components/user/avatar/UserAvatar";
import type { IExploration } from "../../../interfaces/explorations";
import { useGetExplorationUpdatedBy } from "../api/useGetExplorationUpdatedBy";

const { Text } = Typography;

interface IExplorationMenuItemPopoverProps {
  exploration: IExploration;
  showAccelerationStatus: boolean;
  children: React.ReactNode;
}

function ExplorationMenuItemPopover(props: IExplorationMenuItemPopoverProps) {
  const { children, exploration, showAccelerationStatus } = props;

  const [open, setOpen] = useState<boolean>(false);
  const [hasBeenOpenedOnce, setHasBeenOpenedOnce] = useState<boolean>(false);

  const { data, loading, error, pending } = useGetExplorationUpdatedBy({
    variables: { explorationId: exploration.id },
    enabled: hasBeenOpenedOnce,
  });

  useEffect(() => {
    if (open && !hasBeenOpenedOnce) {
      setHasBeenOpenedOnce(true);
    }
  }, [open]);

  // strip html tags from the description
  const textDescription = exploration.description ? (
    <Text>{exploration.description.replace(/<(.|\n)*?>/g, "")}</Text>
  ) : (
    <Text italic>Empty description</Text>
  );

  const hasServingLayerIssues = () => {
    return (
      exploration.extractSchedule?.lastJobExecution?.status === "ERROR" ||
      exploration.extractSchedule?.lastJobExecution?.status === "FAILED"
    );
  };

  const getUpdatedByLabel = (): JSX.Element => {
    if (pending || loading) {
      return <Skeleton.Button size="small" active style={{ width: 140 }} />;
    }
    if (error || !data?.Exploration) {
      return <></>;
    }
    return (
      <>
        <UserAvatar tooltip user={data.Exploration.updatedBy} size={16} />{" "}
        updated <FromNow tooltip date={moment(data.Exploration.updatedAt)} />
      </>
    );
  };

  return (
    <>
      <Popover
        arrow
        onOpenChange={(open) => setOpen(open)}
        mouseEnterDelay={0.3}
        placement="right"
        title={
          <div>
            <Text strong ellipsis>
              {exploration.name}
            </Text>
          </div>
        }
        content={
          <Space direction="vertical">
            <div
              style={{
                width: "300px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                display: "-webkit-box",
                WebkitBoxOrient: "vertical",
                WebkitLineClamp: 2,
              }}
            >
              {textDescription}
            </div>
            {((showAccelerationStatus && exploration.extractSchedule) ||
              exploration.hasUpstreamErrors) && (
              <Space>
                {showAccelerationStatus && exploration.isExtractEnabled ? (
                  hasServingLayerIssues() ? (
                    <Tag color="error">Serving layer replication failed</Tag>
                  ) : (
                    <Tag color="success">Serving layer replication success</Tag>
                  )
                ) : null}
                {exploration.hasUpstreamErrors ? (
                  <div style={{ paddingTop: 6 }}>
                    <Tag color="error">Upstream models Errors</Tag>
                  </div>
                ) : null}
              </Space>
            )}
            <div style={{ paddingTop: 6 }}>{getUpdatedByLabel()}</div>
          </Space>
        }
      >
        {children}
      </Popover>
    </>
  );
}

export default ExplorationMenuItemPopover;
