import { FileMarkdownOutlined } from "@ant-design/icons";
import { Emoji } from "../form/emoji-picker/Emoji";

interface ISourceImageRendererProps {
  alt: string;
  size: number;
  className?: string;
  img?: string;
  isModel?: boolean;
  style?: React.CSSProperties;
}

export function SourceImageRenderer(props: ISourceImageRendererProps) {
  const size = props.size ? props.size : 16;
  const style = props.style ?? {};
  if (props.isModel) {
    return (
      <FileMarkdownOutlined style={{ fontSize: size, color: "#7a7d7f" }} />
    );
  } else if (props.img && props.img.startsWith("http")) {
    return (
      <img
        className={props.className}
        src={props.img}
        alt={props.alt}
        style={{ width: size, ...style }}
      />
    );
  } else if (props.img) {
    return (
      <div
        style={{
          display: "inline-block",
          width: props.size,
          height: props.size,
          ...style,
        }}
      >
        <Emoji emoji={props.img} size={size} />
      </div>
    );
  } else {
    return <span />;
  }
}
