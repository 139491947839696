import _ from "lodash";
import { USER_FRAGMENT } from "../../../fragments/user";
import type { IExploration } from "../../../interfaces/explorations";
import type { IFilterType, IReport } from "../../../interfaces/reports";
import GraphQLService from "../../../services/graphql/GraphQLService";
import type { Store } from "./chart/card/ChartCard";
import type { IReportOption } from "./content/dashboard/options/DashboardOptionsEdit";
import {
  convertStringToWlyDatePickerValue,
  convertWlyDatePickerValueToString,
} from "./filters/date-filter/WlyDatePicker";
export type ActionType = "EXPLORE" | "CREATE_TILE" | "EDIT_TILE";

export interface FilterMapStore {
  [key: string]: IFilterStore;
}

export interface IFilterStore {
  type: IFilterType;
  value: string[];
  dimension: string;
  hidden: boolean;
}

export interface DataMapStore {
  [key: string]: Store;
}

export const fetchUsedExplorationInReport = (
  report: IReport,
  orgId: string
): Promise<IExploration[]> => {
  const allExplorationIds: string[] = [];

  report.filters.forEach((e) => {
    if (e.exploration) {
      allExplorationIds.push(e.exploration.id);
    }
  });

  report.tiles.forEach((t) => {
    if (t.exploration) {
      allExplorationIds.push(t.exploration.id);
    }
  });

  return GraphQLService<{
    allExplorations: IExploration[];
  }>(USED_EXPLORATIONS, {
    orgId: orgId,
    allExplorationIds: _.uniq(allExplorationIds),
  }).then((r) => {
    return r.allExplorations;
  });
};

const REPORT_FRAGMENT = `
${USER_FRAGMENT}

fragment ReportQuery on Report {
  id
  slug
  name
  type
  description
  reportOptions
  createdAt
  createdBy {
    id
    firstName
    lastName
    gravatar
    avatarColor
  }
  updatedAt
  updatedBy {
    ...UpdatedBy
  }
  canBeEditedByCurrentUser
  filters(where: { deleted_not: true }) {
    id
    slug
    name
    apiName
    hidden
    order
    defaultValue
    labelDimension
    valueDimension
    useDefaultTimeField
    defaultTo
    exploration {
      id
      version {id}
    }
    type
    componentType
    requireValue
    tilesToUpdate(where: { deleted_not: true }) {
      id
      dimensionToUpdate
      tile {id}
    }
    controlledBy(where: { deleted_not: true, parent: {deleted_not: true} }) {
      id
      dimensionToUpdate
      parent {id}
    }
    filterLimit
  }
  folder {
    id
  }
  tiles(where: { deleted_not: true }) {
    id
    name
    slug
    description
    type
    content
    analysisType
    chartType
    hide_name
    hide_card
    vertical_align
    horizontal_align
    width
    height
    top
    left
    updatedBy {
      ...UpdatedBy
    }
    updatedAt
    exploration {
      id
      slug
      version {id}
    }
  }
  org {
    id
  }
}
`;

const TABLE_REPORT = `
  fragment TableReport on Table {
    id
    name
    apiName
    slug
    cubeName
    dimensions(where: { deleted_not: true }, sortBy: [columnName_ASC]) {
      id
      name
      type
      apiName
      description
      cubeName
      columnDomain
      overrideName
    }
    primaryKey
    metrics(where: { deleted_not: true }, sortBy: [overrideName_ASC]) {
      id
      name
      apiName
      overrideName
      description
      cubeName
      filters
      format
      prefix
      suffix
      overrideFormatting
    }
  }
`;

const INITIAL_EXPLORATION_FRAGMENT = `
${TABLE_REPORT}

fragment InitialExploration on Exploration {
  id
  slug
  name
  description
  hasUpstreamErrors
  tables(where: {deleted_not: true}) {
    ...TableReport
  }
}
`;

export const RELOAD_EXPLORATION = `
${INITIAL_EXPLORATION_FRAGMENT}

query reloadExploration($explorationId: ID!) {
  Exploration(where: { id: $explorationId }) {
    ...InitialExploration
  }
}
`;

export const INITIAL_GQL_QUERY = `
${REPORT_FRAGMENT}

query getReport($slug: String!, $orgId: ID!) {
  allReports(where: {slug: $slug, org: { id: $orgId }, deleted_not: true}) {
    ...ReportQuery
    isPublicShared
    publicToken
  }
}
`;

export const INITIAL_GQL_QUERY_ID = `
${REPORT_FRAGMENT}

query getReport($id: ID!, $orgId: ID!) {
  allReports(where: {id: $id, org: { id: $orgId }, deleted_not: true}) {
    ...ReportQuery
    isPublicShared
    publicToken
  }
}
`;

const USED_EXPLORATIONS = `
${INITIAL_EXPLORATION_FRAGMENT}

query getUsedExploration($orgId: ID!, $allExplorationIds: [ID!]) {
  allExplorations(sortBy: name_ASC, where: { org: { id: $orgId }, deleted_not: true, id_in: $allExplorationIds }) {
    ...InitialExploration
   }
}
`;

export const INITIAL_PUBLIC_LINK_GQL_QUERY = (
  fetchOnlyPublicReport: boolean
) => `
${REPORT_FRAGMENT}

query getReport($publicToken: String!) {
  allReports(where: {
    publicToken: $publicToken, 
    deleted_not: true, 
    ${fetchOnlyPublicReport ? ", isPublicShared: true" : ""}
  }) {
    ...ReportQuery
  }
}
`;

export const INITIAL_PRIVATE_LINK_GQL_QUERY = () => `
${REPORT_FRAGMENT}

query getReport($privateToken: String!, $enableWorkflow: Boolean!, $workflowId: ID!) {
  allReports(where: {
    privateToken: $privateToken,
    deleted_not: true
  }) {
    ...ReportQuery
  }
  Workflow(where:{id: $workflowId}) @include(if: $enableWorkflow) {
    controlledValues(where: { deleted_not: true }) {
      filter {
        id
        apiName
      }
      value
    }
  }
}
`;

export const IS_PASSWORD_PROTECTED = `
query reportSharingLinkPasswordAccess($sharingLinkToken: String!) {
  reportSharingLinkPasswordAccess(sharingLinkToken: $sharingLinkToken)
}
`;

export const GET_EMBEDDED_CONFIG = `
query getEmbedConfig($embedToken: String!) {
  getEmbedConfig(token: $embedToken)
}
`;

export const INITIAL_SHARED_GQL_QUERY = () => `
  ${REPORT_FRAGMENT}

  query getReport($publicToken: String!, $sharingToken: String!) {
    allReports(
      where: {
        publicToken: $publicToken
        sharingLinks_some: { publicToken: $sharingToken, deleted_not: true }
        deleted_not: true
      }
    ) {
      ...ReportQuery
      controledValues: filters(where: { deleted_not: true }) {
        filter_id: id
        controledValues(
          where: {
            deleted_not: true
            sharingLink: { publicToken: $sharingToken }
          }
        ) {
          value
        }
      }
      sharingLinks(where: { publicToken: $sharingToken, deleted_not: true} ) {
        disableDrills
      }
    }
  }
`;

export const UPDATE_QUERY = `
${USER_FRAGMENT}

mutation updateReport($id: ID!, $data: ReportUpdateInput!) {
  updateReport(id: $id, data: $data) {
    id
    slug
    name
    type
    description
    updatedAt
    updatedBy {
      ...UpdatedBy
    }
    org {
      id
    }
    filters(where: { deleted_not: true }) {
      id
      name
      apiName
      defaultValue
      labelDimension
      valueDimension
      defaultTo
      useDefaultTimeField
      componentType
      hidden
      requireValue
      type
      tilesToUpdate(where: { deleted_not: true }) {
        id
        dimensionToUpdate
        tile {id}
      }
    }
    tiles(where: { deleted_not: true }) {
      id
      name
      slug
      description
      type
      content
      analysisType
      hide_name
      hide_card
      vertical_align
      horizontal_align
      chartType
      width
      height
      top
      left
      exploration {
        id
        
      }
    }
  }
}
`;

const GET_RELATED_EXPLORATIONS = `
${USER_FRAGMENT}

query getRelatedExploration($idIn: [ID]!, $orgId: ID!) {
  allExplorations(where: {id_in: $idIn, org: { id: $orgId }, deleted_not: true}) {
    id
    slug
    name
    description
    updatedAt
    updatedBy {
      ...UpdatedBy
    }
    tiles(where: { deleted_not: true }) {
      id
      name
      slug
      description
      type
      content
      chartType
      hide_name
      hide_card
      vertical_align
      horizontal_align
      analysisType
      width
      height
      top
      left
    }
  }
}
`;

export const UPDATE_TILE = `
${USER_FRAGMENT}

mutation updateTile($id: ID!, $data: TileUpdateInput!, $reportId: ID!){
  updateTile(id: $id, data: $data) {
    id
  }
  updateReport(id: $reportId, data: {}) {
    updatedAt
    updatedBy {
      ...UpdatedBy
    }
  }
}
`;

export const UPDATE_FILTER = `
${USER_FRAGMENT}

mutation updateFilter($id: ID!, $data: FilterUpdateInput!, $reportId: ID!){
  updateFilter(id: $id, data: $data) {
    id
  }
  updateReport(id: $reportId, data: {}) {
    updatedAt
    updatedBy {
      ...UpdatedBy
    }
  }
}
`;

export const UPDATE_MULTIPLE_TILES = `
${USER_FRAGMENT}

mutation updateTile($data: [TilesUpdateInput]!, $reportId: ID!){
  updateTiles(data: $data) {
    id
    top
    left
    width
    height
  }
  updateReport(id: $reportId, data: {}) {
    updatedAt
    updatedBy {
      ...UpdatedBy
    }
  }
}
`;

export const CREATE_TILE = `
${USER_FRAGMENT}

mutation updateTile($data: TileCreateInput!, $reportId: ID!){
  createTile(data: $data) {
    id
  }
  updateReport(id: $reportId, data: {}) {
    updatedAt
    updatedBy {
      ...UpdatedBy
    }
  }
}
`;

export const CREATE_TILE_FULL = `
${TABLE_REPORT}
${USER_FRAGMENT}

mutation updateTile($data: TileCreateInput!, $reportId: ID!){
  createTile(data: $data) {
    id
      name
      slug
      description
      type
      content
      chartType
      analysisType
      hide_name
      hide_card
      vertical_align
      horizontal_align
      width
      height
      top
      left
      exploration {
        id
        name
        slug
        description
        tables(where: {deleted_not: true}) {
          ...TableReport
        }
      }
  }
  updateReport(id: $reportId, data: {}) {
    updatedAt
    updatedBy {
      ...UpdatedBy
    }
  }
}
`;

export const UPDATE_TILE_FULL = `
${TABLE_REPORT}
${USER_FRAGMENT}

mutation updateTile($id: ID!, $data: TileUpdateInput!, $reportId: ID!){
  updateTile(id: $id, data: $data) {
    id
      name
      slug
      description
      type
      content
      chartType
      analysisType
      hide_name
      hide_card
      vertical_align
      horizontal_align
      width
      height
      top
      left
      updatedBy {
        ...UpdatedBy
      }
      updatedAt
      exploration {
        id
        name
        slug
        description
        tables(where: {deleted_not: true}) {
          ...TableReport
        }
      }
  }
  updateReport(id: $reportId, data: {}) {
    updatedAt
    updatedBy {
      ...UpdatedBy
    }
  }
}
`;

export const convertFilterTypeToString = (v: any, type: IFilterType) => {
  if (v === undefined) {
    return v;
  }
  if (type === "STRING") {
    return v;
  } else if (type === "NUMERIC") {
    return v.toString();
  } else if (type === "BOOLEAN") {
    if (typeof v === "boolean") {
      return JSON.stringify(v);
    } else {
      return v;
    }
  } else if (type === "TIME") {
    return convertWlyDatePickerValueToString(v);
  }
};

export const convertStringToFilterType = (v: any, type: IFilterType) => {
  if (v === undefined) {
    return v;
  }
  if (type === "STRING") {
    return v;
  } else if (type === "NUMERIC") {
    return v.toString();
  } else if (type === "BOOLEAN") {
    return v;
  } else if (type === "TIME") {
    return convertStringToWlyDatePickerValue(v);
  }
};

export const parseOptions = (reportOptions?: string): IReportOption => {
  try {
    const options = reportOptions ? JSON.parse(reportOptions) : {};
    return options as IReportOption;
  } catch (err) {
    return {} as IReportOption;
  }
};

export const updateCSS = (reportOptions?: string) => {
  const options = reportOptions
    ? parseOptions(reportOptions)
    : ({} as IReportOption);
  const doc = document.documentElement;
  if (options?.body?.backgroundColor) {
    doc.style.setProperty(
      "--dashboard-body-background-color",
      options?.body?.backgroundColor
    );
  } else {
    doc.style.removeProperty("--dashboard-body-background-color");
  }

  if (options?.header?.backgroundColor) {
    doc.style.setProperty(
      "--dashboard-header-background-color",
      options?.header?.backgroundColor
    );
  } else {
    doc.style.removeProperty("--dashboard-header-background-color");
  }

  if (options?.header?.color) {
    doc.style.setProperty("--dashboard-header-color", options?.header?.color);
  } else {
    doc.style.removeProperty("--dashboard-header-color");
  }
};
