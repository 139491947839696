import {
  CloseOutlined,
  EyeInvisibleOutlined,
  FilterOutlined,
  FunctionOutlined,
  GroupOutlined,
  MergeCellsOutlined,
  PlusCircleOutlined,
  ReloadOutlined,
  SearchOutlined,
  UndoOutlined,
} from "@ant-design/icons";
import * as React from "react";
import type {
  BooleanSchemaItem,
  Filter,
  SchemaResult,
  TableResult,
  Transformation,
} from "../../interfaces/transformations";
import { generateUniqueId } from "../../utils/uniqueId";
import * as Toolbar from "./toolbar/Toolbar";

import { Dropdown } from "antd";
import cuid from "cuid";
import { compose } from "../../components/compose/WlyCompose";
import type { InjectedOrgProps } from "../orgs/WithOrg";
import WithOrg from "../orgs/WithOrg";
import type { CreateEditTransformationComponent } from "../transformations";
import type { BasicTableTabItem } from "./domain";
import { renderContent } from "./helpers";
import type {
  ISpreadsheetLoading,
  RowSelectedItem,
  SelectedItem,
  ToolbarMenuElement,
} from "./SpreadsheetCore";
import SpreadsheetCore from "./SpreadsheetCore";

interface ISpreadsheetProps {
  records?: TableResult;
  count?: number;
  schema?: SchemaResult;
  transformations: Transformation[];
  style?: React.CSSProperties;
  tabStyle?: React.CSSProperties;
  currentTabItem: BasicTableTabItem;
  onAddTransformation?: (transformation: Transformation) => Promise<any>;
  onRefresh?: () => void;
  error?: string;
  loading: ISpreadsheetLoading;
  scrollToColumnIndex?: number;
  rounded?: boolean;
  operationState?: ISpreadsheetOperationState;
  head: string;
  output: string;
  setOutput?: (head: string) => void;
}

interface ISpreadsheetOperationState {
  refresh?: OperationState;
  hide?: OperationState;
  filter?: OperationState;
  add_column?: OperationState;
}

type OperationState = "normal" | "disabled" | "hidden";

interface IState {
  selectedItem?: SelectedItem;
  addingANewTransformation: boolean;
  editingTransformation?: {
    transformation: Transformation;
    component: CreateEditTransformationComponent<Transformation>;
  };
  submittingTransformation: boolean;
  viewingSQL: boolean;
  height: number;
}

type Props = InjectedOrgProps & ISpreadsheetProps;

class SpreadsheetFlow extends React.Component<Props, IState> {
  id = cuid();

  constructor(props: Props) {
    super(props);
    this.state = {
      addingANewTransformation: false,
      submittingTransformation: false,
      viewingSQL: false,
      height: 0,
    };
  }

  componentDidUpdate(prevProps: ISpreadsheetProps) {
    const { currentTabItem } = this.props;
    const { currentTabItem: prevCurrentTabItem } = prevProps;
    if (currentTabItem.key !== prevCurrentTabItem.key) {
      this.setState({ selectedItem: undefined });
    }
  }

  public onRowClick = (e: RowSelectedItem) => {
    this.setState({ selectedItem: e });
  };

  public countNumberOfFilter = (condition: Filter) => {
    if ((condition[0] as any).and) {
      return (condition[0] as any).and.length;
    }
    if ((condition[0] as any).or) {
      return (condition[0] as any).or.length;
    }
    return condition.length;
  };

  toolbarMenuElements = (): ToolbarMenuElement[] => {
    const {
      head,
      onAddTransformation,
      currentTabItem,
      onRefresh,
      operationState,
      loading,
      error,
      schema,
    } = this.props;

    const viewName: ToolbarMenuElement = {
      key: "title",
      renderer: () => {
        return <Toolbar.ViewName>Step output</Toolbar.ViewName>;
      },
    };

    const elements: ToolbarMenuElement[] = [];

    let schemaDef = schema ? schema : {};
    if (loading.schema) {
      schemaDef = Array.from({ length: 10 }, (_, a) => ({
        key: generateUniqueId(),
      }))
        .map((_) => {
          return {
            [_.key]: {
              domain: "BOOLEAN",
              type: "BOOLEAN",
              distinct: 0,
              null: 0,
              count: 0,
            } as BooleanSchemaItem,
          };
        })
        .reduce((p, v, i) => {
          return {
            ...p,
            ...v,
          };
        }, {} as SchemaResult);
    }

    if (onRefresh && (!operationState || operationState.refresh !== "hidden")) {
      elements.push({
        key: "refresh",
        renderer: () => {
          return (
            <Toolbar.Item disabled={loading.records}>
              <span onClick={() => onRefresh()}>
                <ReloadOutlined /> Refresh
              </span>
            </Toolbar.Item>
          );
        },
      });
    }

    if (
      !error &&
      onAddTransformation &&
      (!operationState || operationState.hide !== "hidden")
    ) {
      elements.push({
        key: "hide-column",
        renderer: () => {
          return (
            <Toolbar.Item
              onClick={() => {
                onAddTransformation({
                  var: generateUniqueId(),
                  domain: "viewResolver",
                  operation: {
                    type: "Table.RemoveColumns",
                    args: {
                      columns: [],
                      table: head,
                    },
                  },
                });
              }}
              active={false}
              color={"violet"}
            >
              <EyeInvisibleOutlined /> Hide columns
            </Toolbar.Item>
          );
        },
      });
    }

    if (
      !error &&
      onAddTransformation &&
      currentTabItem &&
      (!operationState || operationState.filter !== "hidden")
    ) {
      elements.push({
        key: "filter-rows",
        renderer: () => {
          return (
            <Toolbar.Item
              onClick={() => {
                onAddTransformation({
                  var: generateUniqueId(),
                  domain: "viewResolver",
                  operation: {
                    type: "Table.SelectRows",
                    args: {
                      condition: [
                        {
                          and: [],
                        },
                      ],
                      table: head,
                    },
                  },
                });
              }}
              color="green"
            >
              <span>
                <FilterOutlined /> Filter
              </span>
            </Toolbar.Item>
          );
        },
      });
    }

    if (
      !error &&
      onAddTransformation &&
      currentTabItem &&
      (!operationState || operationState.filter !== "hidden")
    ) {
      elements.push({
        key: "group-rows",
        renderer: () => {
          return (
            <Toolbar.Item
              onClick={() => {
                onAddTransformation({
                  var: generateUniqueId(),
                  domain: "viewResolver",
                  operation: {
                    type: "Table.Group",
                    args: {
                      keys: [],
                      table: head,
                      aggregatedColumns: [],
                    },
                  },
                });
              }}
              color="green"
            >
              <span>
                <GroupOutlined /> Group
              </span>
            </Toolbar.Item>
          );
        },
      });
    }

    if (
      !error &&
      onAddTransformation &&
      currentTabItem &&
      (!operationState || operationState.add_column !== "hidden")
    ) {
      elements.push({
        key: "add-column",
        renderer: () => {
          return (
            <Toolbar.Item color="yellow">
              <Dropdown
                menu={{
                  items: [
                    {
                      key: "addcolumn",
                      onClick: () => {
                        onAddTransformation({
                          var: generateUniqueId(),
                          domain: "datasetResolver",
                          operation: {
                            type: "Table.AddColumn",
                            args: {
                              newColumnName: "",
                              columnGenerator: "",
                              table: head,
                            },
                          },
                        });
                      },
                      label: "Formula",
                      icon: <FunctionOutlined />,
                    },
                    {
                      key: "lookup",
                      onClick: () => {
                        onAddTransformation({
                          var: generateUniqueId(),
                          domain: "datasetResolver",
                          operation: {
                            type: "WhalyExt.Table.AddLookupColumn",
                            args: {
                              table1: head,
                              table2: null,
                              key1: null,
                              key2: null,
                              aggregationType: "FIRST_VALUE_IGNORE_NULLS",
                              aggregatedColumn: null,
                              newColumnName: "",
                            },
                          },
                        });
                      },
                      label: "Lookup",
                      icon: <SearchOutlined />,
                    },
                    {
                      key: "rollup",
                      onClick: () => {
                        onAddTransformation({
                          var: generateUniqueId(),
                          domain: "datasetResolver",
                          operation: {
                            type: "WhalyExt.Table.AddRollupColumn",
                            args: {
                              table1: head,
                              table2: null,
                              key1: null,
                              key2: null,
                              order: null,
                              orderedBy: null,
                              aggregationType: "COUNT",
                              aggregatedColumn: null,
                              newColumnName: "",
                            },
                          },
                        });
                      },
                      label: "Rollup",
                      icon: <UndoOutlined />,
                    },
                  ],
                }}
                trigger={["click"]}
              >
                <span>
                  <PlusCircleOutlined /> Add Column
                </span>
              </Dropdown>
            </Toolbar.Item>
          );
        },
      });
    }

    if (
      !error &&
      onAddTransformation &&
      currentTabItem &&
      (!operationState || operationState.add_column !== "hidden")
    ) {
      elements.push({
        key: "combine-tables",
        renderer: () => {
          return (
            <Toolbar.Item color="yellow">
              <Dropdown
                menu={{
                  items: [
                    {
                      key: "union",
                      onClick: () => {
                        onAddTransformation({
                          var: generateUniqueId(),
                          domain: "datasetResolver",
                          operation: {
                            type: "Table.Combine",
                            args: {
                              table1: head,
                              table2: null,
                              columns: [],
                            },
                          },
                        });
                      },
                      label: "Union",
                    },
                  ],
                }}
                trigger={["click"]}
              >
                <span>
                  <MergeCellsOutlined /> Combine
                </span>
              </Dropdown>
            </Toolbar.Item>
          );
        },
      });
    }

    const finalElements: ToolbarMenuElement[] = [
      viewName,
      ...(elements.length
        ? [
            {
              key: "separator",
              renderer: () => <Toolbar.Separator />,
            },
          ]
        : []),
      ...elements,
    ];

    return finalElements;
  };

  public renderModalContent = (selectedItem: SelectedItem) => {
    const { schema } = this.props;
    if (selectedItem.type === "row") {
      return (
        <div>
          {Object.keys(selectedItem.data).map((k) => {
            return (
              <div key={k} className="item">
                <div className="title">{k}</div>
                <div>
                  {renderContent(
                    selectedItem.data[k],
                    schema && schema[k] ? schema[k].domain : undefined
                  )}
                </div>
              </div>
            );
          })}
        </div>
      );
    }

    return undefined;
  };

  public render() {
    const {
      schema,
      count,
      records,
      loading,
      style,
      currentTabItem,
      transformations,
      error,
      scrollToColumnIndex,
      rounded,
      tabStyle,
      head,
      output,
      setOutput,
    } = this.props;

    return (
      <SpreadsheetCore
        records={records}
        count={count}
        schema={schema}
        transformations={{ status: "success", data: transformations }}
        style={style}
        tabStyle={tabStyle}
        foreignKeys={currentTabItem.foreignKeys}
        userDefinedColumns={currentTabItem.userDefinedColumns}
        outgoingRelationships={currentTabItem.relationships}
        toolbarMenuElements={this.toolbarMenuElements()}
        onRowClick={this.onRowClick}
        selectedItem={this.state.selectedItem}
        error={error}
        loading={loading}
        scrollToColumnIndex={scrollToColumnIndex}
        rounded={rounded}
        // footerMenuElements={
        //   setOutput
        //     ? [
        //         {
        //           key: "output",
        //           renderer: () => {
        //             return (
        //               <Toolbar.Item
        //                 color="green"
        //                 active={head === output}
        //                 onClick={() => {
        //                   setOutput(head);
        //                 }}
        //               >
        //                 <ArrowRightOutlined /> Set as output
        //               </Toolbar.Item>
        //             );
        //           },
        //         },
        //         {
        //           key: "view-sql",
        //           renderer: () => {
        //             return (
        //               <>
        //                 <Toolbar.Item
        //                   color="pink"
        //                   onClick={() => {
        //                     this.setState({ viewingSQL: true });
        //                   }}
        //                 >
        //                   <CodeOutlined /> View step SQL
        //                 </Toolbar.Item>
        //                 <FlowToSQL
        //                   onCancel={() => this.setState({ viewingSQL: false })}
        //                   visible={this.state.viewingSQL}
        //                   transformations={transformations}
        //                 />
        //               </>
        //             );
        //           },
        //         },
        //       ]
        //     : []
        // }
        renderModalContent={(height) => {
          if (
            this.state.selectedItem &&
            this.state.selectedItem.type === "row"
          ) {
            return (
              <>
                <div
                  className="close-button"
                  onClick={() => this.setState({ selectedItem: undefined })}
                >
                  <CloseOutlined />
                </div>
                <div
                  className={`content ${this.state.selectedItem.type}`}
                  style={{ height: height }}
                >
                  {this.renderModalContent(this.state.selectedItem)}
                </div>
              </>
            );
          }
          return;
        }}
      />
    );
  }
}

export default compose<Props, ISpreadsheetProps>(WithOrg)(SpreadsheetFlow);
