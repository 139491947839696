import type { IEmailRendererConfig } from "../domain";
import type { IEmailWidgetButtonConfig } from "./domain";
import { EmailWidgetButtonEditor } from "./editor/EmailWidgetButtonEditor";
import { EmailWidgetButtonMjml } from "./mjml/EmailWidgetButtonMjml";
import { EmailWidgetButtonRenderer } from "./web-widget/EmailWidgetButtonRenderer";

export const emailWidgetButtonDefinition: IEmailRendererConfig<IEmailWidgetButtonConfig> =
  {
    parseConfig: (widget) => {
      const defaultValue: IEmailWidgetButtonConfig = {
        align: "center",
        text: "Click here",
        href: "",
      };

      if (!widget.config) {
        return defaultValue;
      }

      try {
        const config: IEmailWidgetButtonConfig = JSON.parse(widget.config);
        return config;
      } catch (err) {
        console.error(err);
        return defaultValue;
      }
    },
    renderEditor: (widget, object, record, conf, org, user, form, datasets) => {
      return (
        <EmailWidgetButtonEditor
          widget={widget}
          object={object}
          record={record}
          conf={conf}
          org={org}
          user={user}
          form={form}
          datasets={datasets}
        />
      );
    },
    renderComponent: (widget, object, record, conf, org, user, edit) => {
      return (
        <EmailWidgetButtonRenderer
          widget={widget}
          object={object}
          record={record}
          conf={conf}
          org={org}
          user={user}
          edit={edit}
        />
      );
    },
    renderMjml: EmailWidgetButtonMjml,
  };
