import {
  EyeInvisibleOutlined,
  EyeOutlined,
  VerticalAlignBottomOutlined,
  VerticalAlignMiddleOutlined,
  VerticalAlignTopOutlined,
} from "@ant-design/icons";
import type { PopoverProps } from "antd";
import { ConfigProvider, Form, Segmented, Space, theme } from "antd";
import * as React from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Delayed from "../../../../../components/delayed/delayed";
import { SwitchIcons } from "../../../../../components/form/elements/switch-icons/SwitchIcons";
import type { ITile } from "../../../../../interfaces/reports";
import { ChartOptionCollapse } from "../../../../chart-options/components/ChartOptionCollapse";
import ChartOptionLine from "../../../../chart-options/components/ChartOptionLine";

interface ITextOptionEditProps {
  tile: ITile;
  onTileUpdate: (v: ITextOption) => void;
}

interface ITextOption {
  hide_card?: boolean;
  content: string;
  vertical_align?: "top" | "center" | "bottom";
}

export function TextOptionEdit(props: ITextOptionEditProps) {
  const { tile, onTileUpdate } = props;
  const [form] = Form.useForm();

  React.useEffect(() => {
    form.resetFields();
  }, [tile.id]);

  const popover: PopoverProps = {
    title: "Edit content",
    overlayStyle: {
      width: 600,
    },
    fresh: true,
    destroyTooltipOnHide: true,
    content: (
      <Delayed delay={100}>
        <Form.Item name={["content"]}>
          <ReactQuill
            formats={[
              "header",
              "bold",
              "italic",
              "underline",
              "strike",
              "blockquote",
              "list",
              "bullet",
              "indent",
              "link",
              "image",
              "align",
            ]}
            preserveWhitespace={true}
            theme="snow"
            modules={{
              toolbar: [
                [{ header: [1, 2, 3, false] }],
                ["bold", "italic", "underline"],
                [
                  { align: "" },
                  { align: "center" },
                  { align: "right" },
                  { align: "justify" },
                ],
                [
                  { list: "ordered" },
                  { list: "bullet" },
                  { indent: "-1" },
                  { indent: "+1" },
                ],
                ["link"],
              ],
            }}
          />
        </Form.Item>
      </Delayed>
    ),
  };

  return (
    <ConfigProvider
      theme={{
        algorithm: theme.compactAlgorithm,
      }}
    >
      <ChartOptionCollapse title={"Text options"}>
        <Form
          layout="vertical"
          form={form}
          initialValues={tile}
          onFieldsChange={(e) => {
            if (onTileUpdate) {
              onTileUpdate(form.getFieldsValue());
            }
          }}
        >
          <Space direction="vertical" style={{ width: "100% " }}>
            <ChartOptionLine
              items={[
                {
                  flex: 1,
                  content: "Content",
                  popover: popover,
                },
                {
                  flex: 0,
                  type: "edit",
                  popover: popover,
                },
              ]}
            />
            <ChartOptionLine
              items={[
                {
                  flex: 1,
                  content: "Background",
                },
                {
                  flex: 0,
                  content: (
                    <Form.Item name={["hide_card"]} noStyle>
                      <SwitchIcons
                        icons={{
                          true: <EyeInvisibleOutlined />,
                          false: <EyeOutlined />,
                        }}
                      />
                    </Form.Item>
                  ),
                },
              ]}
            />
            <ChartOptionLine
              items={[
                {
                  flex: 1,
                  content: "Vertical align",
                },
                {
                  flex: 0,
                  content: (
                    <Form.Item noStyle name={["vertical_align"]}>
                      <Segmented
                        options={[
                          {
                            icon: <VerticalAlignTopOutlined />,
                            value: "top",
                          },
                          {
                            icon: <VerticalAlignMiddleOutlined />,
                            value: "center",
                          },
                          {
                            icon: <VerticalAlignBottomOutlined />,
                            value: "bottom",
                          },
                        ]}
                      />
                    </Form.Item>
                  ),
                },
              ]}
            />
          </Space>
        </Form>
      </ChartOptionCollapse>
    </ConfigProvider>
  );
}
