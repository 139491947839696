import type { IUser } from "./user";

export type IRealmIdentityProviderType = "whaly" | "work_os";

export interface IRealm {
  id: string;
  identityProviderType: IRealmIdentityProviderType;
  emailDomain?: string;
}

export enum IUserRealmType {
  ADMIN = "admin",
  CITIZEN = "citizen",
}

export interface IUserRealm {
  id: string;
  type: IUserRealmType;
  isDeleted: boolean;
  user: IUser;
  realm: IRealm;
}
