import { inject, observer } from "mobx-react";
import React from "react";
import { compose } from "../../../../../components/compose/WlyCompose";
import Feednack from "../../../../../components/layout/feedback/feedback";
import Loading from "../../../../../components/layout/feedback/loading";
import type { ExtendedMeasureType } from "../../../../../components/measures/measure-table/MeasureTable";
import type { DeepPartial } from "../../../../../helpers/typescriptHelpers";
import type { IDestination } from "../../../../../interfaces/destinations";
import type { IExploration } from "../../../../../interfaces/explorations";
import type {
  IDataset,
  IDatasetRelationship,
} from "../../../../../interfaces/sources";
import type { SchemaResult } from "../../../../../interfaces/transformations";
import type { WorkbenchUIStoreProps } from "../../../../../store/workbenchUIStore";
import * as Toolbar from "../../../../spreadsheet/toolbar/Toolbar";
import type {
  ExplorationStore,
  IActiveObject,
  IActiveObjectExplorationUrlState,
  IExplorationMeasureUsage,
  TabData,
} from "../../domain";
import ExplorationConfigurationLayout from "../../exploration/ExplorationConfigurationLayout";
import ExplorationGeneralInformation from "../../exploration/ExplorationGeneralInformation";
import type { SelectedItems, StaleElements } from "../../exploration/domain";
import "./ExplorationViewer.scss";

interface IExplorationViewerProps {
  activeObject: IActiveObject;
  explorationStore: ExplorationStore;
  fetchExploration: (explorationId: string) => Promise<void>;
  updateExploration: (
    explorationId: string,
    payload: DeepPartial<IExploration>
  ) => Promise<void>;
  runDataExtract: (explorationId: string) => Promise<void>;
  fetchTableSchema: (
    tablesSchemas: Array<{ datasetId: string; viewId: string }>
  ) => Promise<SchemaResult[]>;
  dataStore: {
    [tableKey: string]: TabData;
  };
  allIncomingDatasetRelationships: IDatasetRelationship[];
  allDatasets: IDataset[];
  currentWarehouse: IDestination;
}

type Props = IExplorationViewerProps & WorkbenchUIStoreProps;

function ExplorationViewer(props: Props) {
  const {
    activeObject,
    workbenchUIStore: {
      setActiveObjectUrlParams,
      getActiveObjectUrlParams,
      getActiveObjectStaleState,
      setActiveObjectStale,
    },
    explorationStore,
    fetchExploration,
    dataStore,
    fetchTableSchema,
    allIncomingDatasetRelationships,
    allDatasets,
    updateExploration,
    runDataExtract,
    currentWarehouse,
  } = props;

  const activeExploration = explorationStore[activeObject.value];
  React.useEffect(() => {
    if (
      activeExploration &&
      activeExploration.exploration.status === "initial"
    ) {
      fetchExploration(activeExploration.id);
    }
  }, [activeExploration?.exploration.status]);

  const getSelectedTab = () => {
    switch (
      (
        getActiveObjectUrlParams(
          activeObject
        ) as IActiveObjectExplorationUrlState
      )?.tab
    ) {
      case "information":
        return "information";
      case "config":
        return "config";
      default:
        return "config";
    }
  };

  const renderInner = () => {
    if (!activeExploration) {
      return (
        <div className="workbench-content">
          <div className="exploration-viewer">
            <Feednack>Exploration not found</Feednack>
          </div>
        </div>
      );
    }

    if (
      activeExploration.exploration.status === "initial" ||
      (activeExploration.exploration.status === "loading" &&
        !activeExploration.exploration.cache)
    ) {
      return (
        <div className="workbench-content">
          <div className="exploration-viewer">
            <Loading />
          </div>
        </div>
      );
    }

    if (activeExploration.exploration.status === "error") {
      return (
        <div className="workbench-content">
          <div className="exploration-viewer">
            <Feednack>Error fetching the exploration content</Feednack>
          </div>
        </div>
      );
    }

    const state = getActiveObjectUrlParams(
      activeObject
    ) as IActiveObjectExplorationUrlState;

    const onSelectItems = (selectedItems: SelectedItems) => {
      setActiveObjectUrlParams(activeObject, {
        ...state,
        items: selectedItems,
      });
    };

    const staleState: StaleElements = getActiveObjectStaleState(activeObject)
      ? (getActiveObjectStaleState(activeObject) as StaleElements)
      : [];

    const exploration =
      activeExploration.exploration.status === "success"
        ? activeExploration.exploration.data
        : activeExploration.exploration.cache;

    const usage: IExplorationMeasureUsage =
      activeExploration.usage.status === "success"
        ? activeExploration.usage.data
        : {
            dimensions: [],
            metrics: [],
          };

    switch (getSelectedTab()) {
      case "information":
        return (
          <ExplorationGeneralInformation
            exploration={exploration}
            fetchExploration={fetchExploration}
            onExplorationUpdate={updateExploration}
            onExplorationExtractDataNow={runDataExtract}
          />
        );
      case "config":
        return (
          <ExplorationConfigurationLayout
            exploration={exploration}
            usage={usage}
            currentWarehouse={currentWarehouse}
            selectedItems={state?.items ? state.items : []}
            onClick={(selectedItems) => onSelectItems(selectedItems)}
            fetchExploration={fetchExploration}
            staleElements={staleState}
            pushStaleElement={(element) => {
              if (Array.isArray(element)) {
                setActiveObjectStale(activeObject, [...element, ...staleState]);
              } else {
                setActiveObjectStale(activeObject, [element, ...staleState]);
              }
            }}
            removeStaleElement={(operationId) => {
              setActiveObjectStale(
                activeObject,
                staleState.filter((dd) => dd.operationId !== operationId)
              );
            }}
            removeAllStaleElements={() => {
              setActiveObjectStale(activeObject, undefined, false);
            }}
            updateStaleElement={(
              type: ExtendedMeasureType,
              objectId: string,
              data: any
            ) => {
              if (
                staleState.find(
                  (ss) =>
                    ss.objectType === type &&
                    ss.type === "update" &&
                    ss.objectId === objectId
                )
              ) {
                setActiveObjectStale(
                  activeObject,
                  staleState.filter((dd) => {
                    if (
                      dd.objectType === type &&
                      dd.type === "update" &&
                      dd.objectId === objectId
                    ) {
                      dd.data = data;
                    }
                    return dd;
                  })
                );
              } else if (
                staleState.find(
                  (ss) =>
                    ss.objectType === type &&
                    ss.type === "create" &&
                    ss.operationId === objectId
                )
              ) {
                setActiveObjectStale(
                  activeObject,
                  staleState.filter((dd) => {
                    if (
                      dd.objectType === type &&
                      dd.type === "create" &&
                      dd.operationId === objectId
                    ) {
                      dd.data = data;
                      return dd;
                    }
                    return dd;
                  })
                );
              } else if (
                staleState.find(
                  (ss) =>
                    ss.objectType === type &&
                    ss.type === "delete" &&
                    ss.objectId === objectId
                )
              ) {
                setActiveObjectStale(activeObject, staleState);
              } else {
                setActiveObjectStale(activeObject, [
                  ...staleState,
                  {
                    type: "update",
                    objectId: objectId,
                    objectType: type,
                    data,
                  },
                ] as StaleElements);
              }
            }}
            dataStore={dataStore}
            fetchTableSchema={fetchTableSchema}
            allIncomingDatasetRelationships={allIncomingDatasetRelationships}
            allDatasets={allDatasets}
          />
        );
      default:
        return <div>Unknown</div>;
    }
  };

  return (
    <div className="workbench-content">
      <div className="workbench-spreadsheet">
        <div className="workbench-content-inner">
          <Toolbar.Toolbar style={{ borderTop: "none" }}>
            <Toolbar.Item
              color="yellow"
              active={getSelectedTab() === "config"}
              onClick={() => {
                setActiveObjectUrlParams(activeObject, {
                  ...activeObject.urlState,
                  tab: "config",
                });
              }}
            >
              Configuration
            </Toolbar.Item>
            <Toolbar.Item
              color="green"
              active={getSelectedTab() === "information"}
              onClick={() => {
                setActiveObjectUrlParams(activeObject, {
                  ...activeObject.urlState,
                  tab: "information",
                });
              }}
            >
              Information
            </Toolbar.Item>
          </Toolbar.Toolbar>
          {renderInner()}
        </div>
      </div>
    </div>
  );
}

export default compose<Props, IExplorationViewerProps>(
  inject("workbenchUIStore"),
  observer
)(ExplorationViewer);
