import type { ButtonProps } from "antd";
import { Button } from "antd";
import * as React from "react";
import GoogleLogo from "../../assets/g-logo.png";

interface IGoogleButtonProps extends ButtonProps {}

export function GoogleButton(props: IGoogleButtonProps) {
  return (
    <Button {...props} type="default">
      <img
        src={GoogleLogo}
        style={{ width: 18, height: 18, marginRight: 15 }}
      />{" "}
      Sign in with Google
    </Button>
  );
}
