import _ from "lodash";
import * as React from "react";
import useResizeObserver from "use-resize-observer";
import Feednack from "../../../../../components/layout/feedback/feedback";
import type { IChartProps } from "./Chart";
import Chart from "./Chart";

interface IChartSizerProps extends IChartProps {
  children?: React.ReactNode;
}

type size = {
  width: number;
  height: number;
};

function ChartSizer(props: IChartSizerProps) {
  const [size, setSize] = React.useState<size>({
    width: 0,
    height: 0,
  });
  const onResize = React.useMemo(() => _.debounce(setSize, 100), []);
  const { ref } = useResizeObserver({ onResize });

  const renderInner = () => {
    if (!props.exploration) {
      return (
        <Feednack>
          The exploration this chart is based on has either been deleted or you
          don't seem to have access to it. Please contact your admin.
        </Feednack>
      );
    }
    return (
      <Chart height={size.height} width={size.width} {...props}>
        {props.children}
      </Chart>
    );
  };

  return (
    <div ref={ref} style={{ width: "100%", height: "100%" }}>
      {renderInner()}
    </div>
  );
}

export default ChartSizer;
