import { DefaultLinkModel } from "@projectstorm/react-diagrams";

export class FlowLinkModel extends DefaultLinkModel {
  isOver: boolean = false;

  constructor() {
    super({
      type: "advanced",
      width: 3,
    });
    super.setLocked(true);
  }

  hover = (over: boolean) => {
    this.isOver = over;
  };

  getRenderedPath = () => {
    const p = this.renderedPaths.flatMap((d: any) => {
      return d.getRenderedPath();
    });
    return p;
  };
}
