import { EditOutlined } from "@ant-design/icons";
import type { FormInstance } from "antd";
import { Button, Form, Modal, Space, Switch } from "antd";
import React from "react";
import type { IObject } from "../../../../../../../../interfaces/object";
import { ChartOptionCollapse } from "../../../../../../../chart-options/components/ChartOptionCollapse";
import ChartOptionLine from "../../../../../../../chart-options/components/ChartOptionLine";
import { getObjectColumns } from "../../../../../object/domain";
import type { IRecord } from "../../../../domain";
import type { IWidget } from "../../../domain";
import type { IWidgetMarkdownConfig } from "../domain";
import { convertKeyToMarkdocVariable } from "../domain";
import { MarkdocEditor } from "./MarkdocEditor";
import "./MarkdownModal.scss";

interface IMarkdownWidgetEditorProps {
  widget: IWidget;
  object: IObject;
  record: IRecord;
  conf: IWidgetMarkdownConfig;
  edit?: boolean;
  form: FormInstance;
}

export function MarkdownWidgetEditor(props: IMarkdownWidgetEditorProps) {
  const { object, form } = props;
  const [open, setOpen] = React.useState<boolean>(false);
  const columns = getObjectColumns(object);
  return (
    <>
      <ChartOptionCollapse dark title="Markdown Configuration">
        <Space size={"middle"} direction="vertical" style={{ width: "100%" }}>
          {/* <ChartOptionLine
            items={[
              {
                content: <div>Boxed</div>,
                flex: `1`,
              },
              {
                content: (
                  <Form.Item
                    valuePropName="checked"
                    noStyle
                    name={["config", "boxed"]}
                  >
                    <Switch size="small" />
                  </Form.Item>
                ),
                flex: "0 auto",
              },
            ]}
          /> */}
          <ChartOptionLine
            items={[
              {
                content: (
                  <div>
                    Content
                    <Form.Item hidden noStyle name={["config", "text"]} />
                  </div>
                ),
                flex: `1`,
              },
              {
                content: (
                  <>
                    <Button
                      onClick={() => setOpen(true)}
                      size="small"
                      icon={<EditOutlined />}
                    >
                      Edit
                    </Button>
                    <Modal
                      open={open}
                      onCancel={() => setOpen(false)}
                      destroyOnClose
                      maskClosable={false}
                      className="markdown-modal"
                      title={"Edit markdown"}
                      width={"50%"}
                      okText={"Save"}
                      onOk={() => {
                        setOpen(false);
                      }}
                    >
                      <Form.Item noStyle name={["config", "text"]}>
                        <MarkdocEditor
                          columnsSuggestions={columns.map((c) => ({
                            key: `$${convertKeyToMarkdocVariable(c.data.key)}`,
                            label: c.data.label,
                          }))}
                        />
                      </Form.Item>
                    </Modal>
                  </>
                ),
                flex: "0 auto",
              },
            ]}
          />
        </Space>
      </ChartOptionCollapse>
    </>
  );
}
