import type { NodeModelGenerics } from "@projectstorm/react-diagrams";
import { NodeModel, PortModelAlignment } from "@projectstorm/react-diagrams";
import type { IExploration } from "../../../../../../../interfaces/explorations";
import { SimplePortModel } from "../../flow/nodes/port/SimplePortModel";
import { HEIGHT, WIDTH } from "./ExplorationNodeWidget";

export class ExplorationNodeModel extends NodeModel<NodeModelGenerics> {
  exploration: IExploration;
  hasUpstreamErrors: boolean;

  constructor(hasUpstreamErrors = false) {
    super({
      type: "exploration",
    });

    this.hasUpstreamErrors = hasUpstreamErrors;

    const outPort = new SimplePortModel(PortModelAlignment.RIGHT);
    outPort.setDirection("out");
    this.addPort(outPort);

    const inPort = new SimplePortModel(PortModelAlignment.LEFT);
    inPort.setDirection("in");
    this.addPort(inPort);

    this.width = WIDTH;
    this.height = HEIGHT;
  }

  bindExploration = (d: IExploration) => {
    this.exploration = d;
  };

  getPort = (name: string): SimplePortModel => {
    return super.getPort(name) as SimplePortModel;
  };

  getInPort = (): SimplePortModel => {
    return super.getPort(PortModelAlignment.LEFT) as SimplePortModel;
  };

  getOutPort = (): SimplePortModel => {
    return super.getPort(PortModelAlignment.RIGHT) as SimplePortModel;
  };
}
