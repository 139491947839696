import type { ModalProps } from "antd";
import { Modal } from "antd";
import type { FormInstance } from "antd/lib";
import { useEffect } from "react";
import useKeyPress from "../../hooks/useKeypress";

interface ISimpleFormModalProps<TFormData> {
  form: FormInstance<TFormData>;
  open: boolean;
  isSaving: boolean;
  onCancel: () => void;
  children: React.ReactElement;
  modalProps: Omit<ModalProps, "open" | "setOpen" | "children">;
  disableEnterKey?: boolean;
}

export function SimpleFormModal<TFormData>(
  props: ISimpleFormModalProps<TFormData>
) {
  const {
    children,
    form,
    open,
    isSaving,
    modalProps,
    onCancel,
    disableEnterKey,
  } = props;
  const rest = modalProps ? modalProps : {};

  const enter = useKeyPress("Enter");

  useEffect(() => {
    if (open && enter && !disableEnterKey) {
      form.submit();
    }
  }, [enter, open, disableEnterKey]);

  // https://segmentfault.com/a/1190000041172195/en
  useEffect(() => {
    setTimeout(() => {
      form.resetFields();
    });
  }, [open]);

  return (
    <Modal
      {...rest}
      open={open}
      onOk={form.submit}
      okButtonProps={{
        loading: isSaving || form.isFieldsValidating(),
      }}
      cancelButtonProps={{
        disabled: isSaving,
      }}
      onCancel={() => onCancel()}
    >
      {children}
    </Modal>
  );
}
