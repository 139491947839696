import { Tag, type RenderableTreeNodes } from "@markdoc/markdoc";

export default function renderTreeNodeAsText(
  node: RenderableTreeNodes
): string {
  if (typeof node === "string" || typeof node === "number") return String(node);

  if (Array.isArray(node)) return node.map(renderTreeNodeAsText).join("");

  if (node === null || typeof node !== "object" || !Tag.isTag(node)) return "";

  const { name, children = [] } = node;

  if (children.length === 0) return "";

  if (name === "Paragraph") {
    return renderTreeNodeAsText([...children, "\n"]);
  } else if (name === "List") {
    return renderTreeNodeAsText(children.flatMap((c) => ["- ", c, "\n"]));
  }

  return renderTreeNodeAsText(children);
}
