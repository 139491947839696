import type { Query } from "@cubejs-client/core";
import type { PointOptionsObject } from "highcharts";
import Highcharts from "highcharts";
import { HighchartsReact } from "highcharts-react-official";
import HighchartsHeatmap from "highcharts/modules/heatmap";
import _ from "lodash";
import * as React from "react";
import type { ChartOption } from "../../../containers/chart-options/ChartOptions";
import type { UserLocale } from "../../../interfaces/user";
import type { Formatter } from "../domain";
import { localizeHighchart } from "../domain";
import {
  customFormatter,
  optionsHelper,
  renderSerieTooltip,
} from "../utils/optionsHelper";
import "./Heatmap.scss";

HighchartsHeatmap(Highcharts);

interface IHeatmapChartProps {
  height: number;
  data: Array<{ [key: string]: string | number | boolean }>;
  config: HeatChartConfig;
  // contextualMenu?: IContextualMenu;
  tinyLegend?: boolean;
  scale?: "time" | "ordinal";
  width?: number;
  onDrill?: (q: Query) => void;
  chartOptions?: ChartOption;
  locale: UserLocale;
}

interface HeatChartConfig {
  x1: {
    key: string;
    label?: string;
  };
  x2: {
    key: string;
    label?: string;
  };
  y: {
    key: string;
    label?: string;
    color?: string;
    canDrill: (xValue: string, yValue?: string) => Query | null;
    dashed?: boolean;
    formatter: Formatter;
  };
  showLabels?: boolean;
}

type IState = Highcharts.Options;

const generateOptions = (
  data,
  config,
  onDrill,
  width,
  height,
  chartOptions,
  optionHelpers,
  locale
) => {
  const y = config.y;

  const xCategorieSet = new Set();
  const yCategorieSet = new Set();

  data.forEach((d) => {
    xCategorieSet.add(d[config.x1.key]);
    yCategorieSet.add(d[config.x2.key]);
  });

  const xCategories =
    chartOptions?.axis?.bottom?.customOrder &&
    chartOptions?.axis?.bottom?.customOrder?.length
      ? chartOptions?.axis.bottom.customOrder
      : Array.from(xCategorieSet);
  const yCategories =
    chartOptions?.axis?.left?.customOrder &&
    chartOptions?.axis?.left?.customOrder?.length
      ? chartOptions?.axis?.left?.customOrder
      : Array.from(yCategorieSet);
  const values = data.map((d) => d[config.y.key]) as Array<number>;
  const nullFormattedData: Array<PointOptionsObject> = [];
  const formattedData: Array<PointOptionsObject> = data.map((d) => ({
    x: xCategories.indexOf(d[config.x1.key]),
    y: yCategories.indexOf(d[config.x2.key]),
    value: d[config.y.key],
    className: typeof onDrill === "function" ? "pointer" : "auto",
    events: {
      click: () => {
        if (typeof onDrill !== "function") return;
        const q = y.canDrill(
          [
            Array.from(xCategorieSet)[
              xCategories.indexOf(d[config.x1.key])
            ].toString(),
            Array.from(yCategorieSet)[
              yCategories.indexOf(d[config.x2.key])
            ].toString(),
          ].join(",")
        );
        if (q) {
          return onDrill(q);
        }
      },
    },
  }));

  const minX = Math.min(...formattedData.map((v) => v.x));
  const maxX = Math.max(...formattedData.map((v) => v.x));
  const minY = Math.min(...formattedData.map((v) => v.y));
  const maxY = Math.max(...formattedData.map((v) => v.y));

  let i = minX;
  while (i <= maxX) {
    let j = minY;
    while (j <= maxY) {
      if (!formattedData.find((v) => v.x === i && v.y === j)) {
        nullFormattedData.push({
          x: i,
          y: j,
          value: null,
          color: "#F2F2F2",
          events: {
            click: () => false,
          },
          marker: {
            states: {
              hover: {
                enabled: false,
              },
            },
          },
        });
      }
      j++;
    }
    i++;
  }

  const colors = chartOptions?.palette?.colors;

  const options: Highcharts.Options = {
    ...optionHelpers,
    chart: {
      ...optionHelpers.chart,
      type: "heatmap",
      height: height,
      width: width,
    },
    colorAxis: {
      min: Math.min.apply(Math, values),
      max: Math.max.apply(Math, values),
      minColor: colors && colors[1] ? colors[1] : "#cde4ff",
      maxColor: colors && colors[0] ? colors[0] : "#3a5c83",
      gridLineColor: "transparent",
      tickAmount: 2,
      x: 10,
      y: 10,
      labels: {
        formatter: (ctx) => {
          return customFormatter(ctx.value, locale, config.y.formatter);
        },
      },
    },
    xAxis: {
      ...optionHelpers.xAxis,
      categories: xCategories as any,
      title: { text: config.x1.label },
      labels: {
        ...optionHelpers.xAxis.labels,
        formatter: null,
      },
    },
    yAxis: {
      ...optionHelpers.yAxis,
      gridLineColor: "transparent",
      categories: yCategories as any,
      title: { text: config.x2.label },
      labels: {
        ...optionHelpers.yAxis.labels,
        formatter: null,
      },
    },
    series: [
      {
        type: "heatmap",
        data: formattedData,
        stickyTracking: false,
        turboThreshold: 9e9,
        name: y.label,
        color: y.color,
        borderWidth: 0,
        dataLabels: [
          {
            enabled: config.showLabels,
            style: {
              fontSize: "0.8em",
            },
            formatter: function () {
              return customFormatter(
                (this as any).point.value,
                locale,
                y.formatter
              );
            },
          },
        ],
        tooltip: renderSerieTooltip({
          data: data,
          locale: locale,
          seriesLength: 1,
          formatter: y.formatter,
          hideHeader: true,
          hideSerieColor: true,
          valueGetter: "this.options.value",
        }),
      },
      {
        type: "heatmap",
        data: nullFormattedData,
        enableMouseTracking: false,
        states: {
          inactive: {
            enabled: false,
          },
        },
      },
    ],
  };
  return options;
};

export default class HeatmapChart extends React.Component<
  IHeatmapChartProps,
  IState
> {
  ref?: Highcharts.Chart;

  shouldComponentUpdate(
    nextProps: Readonly<IHeatmapChartProps>,
    nextState: Readonly<Highcharts.Options>,
    nextContext: any
  ): boolean {
    if (
      !_.isEqual(this.props.width, nextProps.width) ||
      !_.isEqual(this.props.height, nextProps.height) ||
      !_.isEqual(this.props.data, nextProps.data) ||
      !_.isEqual(
        _.omit(this.props.config, "y.canDrill"),
        _.omit(nextProps.config, "y.canDrill")
      ) ||
      !_.isEqual(this.props.scale, nextProps.scale) ||
      !_.isEqual(this.props.tinyLegend, nextProps.tinyLegend) ||
      !_.isEqual(this.props.chartOptions, nextProps.chartOptions)
    ) {
      return true;
    }
    return false;
  }

  public render() {
    const { height, locale } = this.props;

    const optionHelpers = optionsHelper(
      this.props.height,
      this.props.width,
      locale,
      "ordinal",
      this.props.config.y.formatter,
      undefined,
      undefined,
      this.props.chartOptions
    );

    const options = generateOptions(
      this.props.data,
      this.props.config,
      this.props.onDrill,
      this.props.width,
      this.props.height,
      this.props.chartOptions,
      optionHelpers,
      locale
    );

    localizeHighchart(Highcharts, locale);

    return (
      <div style={{ height, width: "100%" }} className="heatmap-chart">
        <HighchartsReact
          ref={(r) => (this.ref = r?.chart)}
          highcharts={Highcharts}
          options={options}
        />
      </div>
    );
  }
}
