import "ag-grid-enterprise";
import _ from "lodash";
import { inject, observer } from "mobx-react";
import React, { useEffect, useReducer } from "react";
import type { RouteComponentProps } from "react-router";
import { withRouter } from "react-router";
import { POLICIES } from "../../../../../auth/authorization";
import type { TableQuery } from "../../../../../components/ag-grid/object-table/domain";
import type { InjectedAntUtilsProps } from "../../../../../components/ant-utils/withAntUtils";
import { withAntUtils } from "../../../../../components/ant-utils/withAntUtils";
import { compose } from "../../../../../components/compose/WlyCompose";
import Loading from "../../../../../components/layout/feedback/loading";
import type { AsyncData } from "../../../../../helpers/typescriptHelpers";
import {
  type IObject,
  type IObjectView,
} from "../../../../../interfaces/object";
import { IUserFeatureType } from "../../../../../interfaces/user";
import { routeDescriptor } from "../../../../../routes/routes";
import GraphQLService from "../../../../../services/graphql/GraphQLService";
import type { ObjectUIStoreProps } from "../../../../../store/objectUIStore";
import type { InjectedOrgProps } from "../../../../orgs/WithOrg";
import WithOrg from "../../../../orgs/WithOrg";
import ObjectPage from "./ObjectPage";
import { useCreateObjectView } from "./api/useCreateObjectView";
import type { QueryBuilderRemotePropertyQueryType } from "./api/useGetObjectWithViews";
import {
  type GetObjectWithViewsQueryData,
  ObjectWithViewsQuery,
  QueryBuilderRemotePropertyQuery,
} from "./api/useGetObjectWithViews";
import { useUpdateObject } from "./api/useUpdateObject";
import { useUpdateObjectView } from "./api/useUpdateView";
import {
  DUMMY_TOKEN,
  getReferencedTableFromObject,
  MANUAL_LIST_LIMIT,
  tableQueryManualFilterKey,
} from "./domain";
import AddRecordsObjectViewModal from "./modals/AddRecordsObjectViewModal";
import CreateObjectViewModal from "./modals/CreateObjectViewModal";
import DeleteObjectViewModal from "./modals/DeleteObjectViewModal";
import PromoteObjectViewModal from "./modals/PromoteObjectViewModal";
import RenameObjectViewModal from "./modals/RenameObjectViewModal";
import ShareObjectViewModal from "./modals/ShareObjectViewModal";
import UpdateObjectViewModal from "./modals/UpdateObjectViewModal";

interface IObjectPageWrapperProps {
  objectSlug: string;
}

type Props = IObjectPageWrapperProps &
  InjectedOrgProps &
  InjectedAntUtilsProps &
  RouteComponentProps &
  ObjectUIStoreProps;

type DataStore = { object: IObject | undefined; views: IObjectView[] };

type UpdateDataStore =
  | {
      action: "setData";
      params: {
        object: IObject | undefined;
        views: IObjectView[];
      };
    }
  | {
      action: "updateObjectDefaultView";
      params: {
        view?: IObjectView;
      };
    }
  | {
      action: "updateView";
      params: { id: string; data: Partial<IObjectView> };
    }
  | {
      action: "createView";
      params: {
        data: IObjectView;
      };
    }
  | {
      action: "deleteView";
      params: {
        id: string;
      };
    };

function ObjectPageWrapper(props: Props) {
  const {
    antUtils,
    objectSlug,
    user,
    objectUIStore,
    org,
    history,
    match: { params },
    userFeatures,
  } = props;

  const [dataStore, setDataStore] = useReducer(
    (state: DataStore, payload: UpdateDataStore) => {
      switch (payload.action) {
        case "setData":
          return {
            ...state,
            object: payload.params.object,
            views: payload.params.views,
          };
        case "updateObjectDefaultView":
          return {
            ...state,
            object: {
              ...state.object,
              defaultView: payload.params.view,
            } as IObject,
          };
        case "updateView":
          return {
            ...state,
            views: state.views.map((view) => {
              if (view.id === payload.params.id) {
                return {
                  ...view,
                  ...payload.params.data,
                } as IObjectView;
              }
              return view;
            }),
          };
        case "createView":
          return {
            ...state,
            views: [...state.views, payload.params.data],
          };
        case "deleteView":
          return {
            ...state,
            views: state.views.flatMap((view) => {
              if (view.id === payload.params.id) {
                return [];
              }
              return [view];
            }),
          };
        default:
          return state;
      }
    },
    { object: undefined, views: [] }
  );

  const [data, setData] = React.useState<
    AsyncData<{
      allObjects: IObject[];
      allObjectViews: IObjectView[];
      allReferencedObjects: IObject[];
    }>
  >({
    status: "initial",
  });

  React.useEffect(() => {
    const fetch = async () => {
      try {
        setData({ status: "loading" });
        const data = await GraphQLService<GetObjectWithViewsQueryData>(
          ObjectWithViewsQuery,
          {
            objectSlug: objectSlug,
            userId: user.id,
            orgId: org.id,
          }
        );
        const foundObject = data.allObjects?.[0];
        let allReferencedObjects: IObject[] = [];
        let returnData = data;
        if (foundObject) {
          if (
            !userFeatures.includes(IUserFeatureType.TMP_OBJECT_QUERY_BUILDER) &&
            data.allObjects?.[0]
          ) {
            returnData = {
              ...returnData,
              allObjects: returnData.allObjects.map((r) => {
                return {
                  ...r,
                  queryBuilderSections: [],
                };
              }),
            };
          }
          const referencedTables = getReferencedTableFromObject(foundObject);
          const referencedObjects =
            await GraphQLService<QueryBuilderRemotePropertyQueryType>(
              QueryBuilderRemotePropertyQuery,
              { tableIds: referencedTables }
            );
          allReferencedObjects = referencedObjects.allReferencedObjects;
        }

        setData({
          status: "success",
          data: { ...returnData, allReferencedObjects },
        });
      } catch (err) {
        console.error(err);
        setData({ status: "error", error: err });
      }
    };
    fetch();
  }, [objectSlug, user.id, org.id]);

  const { mutateAsync: updateView, loading: isUpdatingView } =
    useUpdateObjectView();
  const { mutateAsync: updateObject } = useUpdateObject();
  const { mutateAsync: createView, loading: isCreatingView } =
    useCreateObjectView();

  const setActiveView = (slug: string | undefined) => {
    history.replace(
      routeDescriptor["object"].renderRoute({ ...params }, { view: slug })
    );
  };

  useEffect(() => {
    if (data.status === "success") {
      const object = data.data?.allObjects?.[0];
      const views = data.data?.allObjectViews ?? [];
      setDataStore({ action: "setData", params: { object, views } });
    }
  }, [data]);

  useEffect(() => {
    return () => {
      objectUIStore.reset();
    };
  }, []);

  if (data.status === "initial" || data.status === "loading") {
    return <Loading />;
  }

  if (data.status === "error") {
    return <>{data.error.message}</>;
  }

  const object = dataStore.object;
  const views = dataStore.views;

  if (!data.data.allObjects.length || !object) {
    return <>Object not found.</>;
  }

  return (
    <>
      <ObjectPage
        object={object}
        views={views}
        referencedObjects={data.data.allReferencedObjects}
        setActiveView={setActiveView}
      />
      {/* Delete object view */}
      <DeleteObjectViewModal
        open={!!objectUIStore.deleteViewOpen}
        onCancel={() => objectUIStore.setDeleteViewOpen(undefined)}
        onOk={async () => {
          try {
            const id = objectUIStore.deleteViewOpen?.id;
            if (!id) throw new Error("View id required");
            await updateView({
              variables: {
                id: id,
                data: {
                  isDeleted: true,
                },
              },
            });
            antUtils.message.success("View deleted");
            setDataStore({ action: "deleteView", params: { id: id } });
            objectUIStore.setDeleteViewOpen(undefined);
          } catch (error) {
            antUtils.message.error(
              "Error while deleting view. Please try again"
            );
          }
        }}
      />
      {/* Update object view */}
      <UpdateObjectViewModal
        open={!!objectUIStore.updateViewOpen}
        onCancel={() => objectUIStore.setUpdateViewOpen(undefined)}
        onOk={async () => {
          try {
            const id = objectUIStore.updateViewOpen?.id;
            const query = objectUIStore.updateViewOpen?.query;
            if (!id || !query) {
              throw Error("id and query must be defined");
            }
            await updateView({
              variables: {
                id: id,
                data: {
                  config: query,
                },
              },
            });
            antUtils.message.success("View updated");
            setDataStore({
              action: "updateView",
              params: { id: id, data: { config: query } },
            });
            objectUIStore.setUpdateViewOpen(undefined);
          } catch (error) {
            antUtils.message.error(
              "Error while updating view. Please try again"
            );
          }
        }}
      />
      {/* Rename object view */}
      <RenameObjectViewModal
        initialValues={objectUIStore.renameViewOpen}
        isSaving={false}
        open={!!objectUIStore.renameViewOpen}
        onCancel={() => objectUIStore.setRenameViewOpen(undefined)}
        onSave={async (item) => {
          try {
            await updateView({
              variables: {
                id: item.id,
                data: {
                  name: item.name,
                },
              },
            });
            antUtils.message.success("View renamed");
            setDataStore({
              action: "updateView",
              params: { id: item.id, data: { name: item.name } },
            });
            objectUIStore.setRenameViewOpen(undefined);
          } catch (error) {
            antUtils.message.error(
              "Error while renaming view. Please try again"
            );
          }
        }}
      />
      {/* Create object view */}
      <CreateObjectViewModal
        initialValues={objectUIStore.createViewOpen?.values}
        title={objectUIStore.createViewOpen?.title}
        isSaving={isCreatingView}
        open={!!objectUIStore.createViewOpen}
        onCancel={() => objectUIStore.setCreateViewOpen(undefined)}
        onSave={async (values) => {
          try {
            const view = await createView({
              variables: {
                data: {
                  name: values.name,
                  config: values.query,
                  viewType: objectUIStore.createViewOpen?.type,
                  object: {
                    connect: {
                      id: object.id,
                    },
                  },
                  org: {
                    connect: {
                      id: org.id,
                    },
                  },
                },
              },
            });
            setTimeout(() => {
              setActiveView(view.createObjectView.slug);
            }, 200);
            objectUIStore.setCreateViewOpen(undefined);
            setDataStore({
              action: "createView",
              params: {
                data: view.createObjectView,
              },
            });
            antUtils.message.success("View successfully created");
          } catch (error) {
            console.error(error);
            antUtils.message.error("Error while saving view");
          }
        }}
      />
      {/* Share object view */}
      <ShareObjectViewModal
        initialValues={objectUIStore.shareViewOpen}
        isSaving={false}
        open={!!objectUIStore.shareViewOpen}
        onCancel={() => objectUIStore.setShareViewOpen(undefined)}
        onSave={async (item) => {
          try {
            await updateView({
              variables: {
                id: item.id,
                data: {
                  isPersonal: item.isPersonal,
                },
              },
            });
            antUtils.message.success("View updated");
            setDataStore({
              action: "updateView",
              params: { id: item.id, data: { isPersonal: item.isPersonal } },
            });
            objectUIStore.setShareViewOpen(undefined);
          } catch (error) {
            antUtils.message.error(
              "Error while updating view. Please try again"
            );
          }
        }}
      />
      {/* Promote object view */}
      <PromoteObjectViewModal
        initialValues={objectUIStore.promoteViewOpen}
        isSaving={false}
        object={object}
        views={dataStore.views.filter((view) =>
          POLICIES["objectview:promote"]({ user, orgId: org.id, view: view })
        )}
        open={!!objectUIStore.promoteViewOpen}
        onCancel={() => objectUIStore.setPromoteViewOpen(undefined)}
        onSave={async (item) => {
          try {
            await updateObject({
              variables: {
                id: object.id,
                data:
                  item.id === "system"
                    ? { defaultView: { disconnectAll: true } }
                    : { defaultView: { connect: { id: item.id } } },
              },
            });
            antUtils.message.success("Default view updated!");
            setDataStore({
              action: "updateObjectDefaultView",
              params: { view: views.find((view) => view.id === item.id) },
            });
            objectUIStore.setPromoteViewOpen(undefined);
          } catch (error) {
            antUtils.message.error(
              "Error while updating default view. Please try again"
            );
          }
        }}
      />
      {/* Add Records to a manual view */}
      <AddRecordsObjectViewModal
        initialValues={{ recordIds: objectUIStore.addRecordsToView?.records }}
        isSaving={isUpdatingView}
        open={!!objectUIStore.addRecordsToView}
        onCancel={() => objectUIStore.setAddRecordsToView(undefined)}
        views={views.filter((v) =>
          objectUIStore.addRecordsToView?.views.includes(v.id)
        )}
        onSave={async (val) => {
          try {
            const recordIds = val.recordIds;
            const viewId = val.viewId;
            if (!recordIds || !viewId) {
              throw Error("recordIds and viewId must be defined");
            }

            const selectedView = views.find((v) => v.id === viewId);
            if (!selectedView) {
              throw Error(`Can't find view with id ${viewId}`);
            }

            const currentQuery: TableQuery = selectedView.config
              ? JSON.parse(selectedView.config)
              : ({
                  filters: [
                    {
                      and: [
                        {
                          member: `${object.table.cubeName}.id`,
                          operator: "equals",
                          values: [DUMMY_TOKEN],
                        },
                      ],
                    },
                  ],
                } as TableQuery);

            const newTableQuery = { ...currentQuery };
            const values = _.get(newTableQuery, tableQueryManualFilterKey);
            const newValues = _.uniq([...values, DUMMY_TOKEN, ...recordIds]);
            if (newValues.length >= MANUAL_LIST_LIMIT) {
              antUtils.message.error(
                `Can't have more than ${
                  MANUAL_LIST_LIMIT - 1
                } records to a view`
              );
              return;
            }
            _.set(newTableQuery, tableQueryManualFilterKey, newValues);

            const modifiedQuery = JSON.stringify(newTableQuery);
            await updateView({
              variables: {
                id: viewId,
                data: {
                  config: modifiedQuery,
                },
              },
            });
            antUtils.message.success("Successfully added records to view");
            setDataStore({
              action: "updateView",
              params: { id: viewId, data: { config: modifiedQuery } },
            });

            setTimeout(() => {
              setActiveView(selectedView.slug);
            }, 200);
            objectUIStore.setAddRecordsToView(undefined);
          } catch (error) {
            console.error(error);
            antUtils.message.error(
              "Error while updating view. Please try again"
            );
          }
        }}
      />
    </>
  );
}

export default compose<Props, IObjectPageWrapperProps>(
  WithOrg,
  withAntUtils,
  withRouter,
  inject("objectUIStore"),
  observer
)(ObjectPageWrapper);
