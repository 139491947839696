import _ from "lodash";
import React from "react";
import type { RouteComponentProps } from "react-router";
import { withRouter } from "react-router";
import type { InjectedAntUtilsProps } from "../../../../../components/ant-utils/withAntUtils";
import { withAntUtils } from "../../../../../components/ant-utils/withAntUtils";
import { compose } from "../../../../../components/compose/WlyCompose";
import usePrevious from "../../../../../components/hooks/usePrevious";
import type { IReportFolder } from "../../../../../interfaces/folder";
import FolderMenuInner from "./FolderMenuInner";

interface IFolderMenuProps {
  personalFolder?: IReportFolder;
  folders: IReportFolder[];
  isDragging: boolean;
}

type Props = IFolderMenuProps & RouteComponentProps<{}> & InjectedAntUtilsProps;

function FolderMenu(props: Props) {
  const {
    history,
    match: { params },
    folders,
    antUtils,
    isDragging,
    personalFolder,
  } = props;

  const previousFolder = usePrevious(folders);

  const [staleFolders, setStaleFolders] = React.useState<IReportFolder[]>([]);

  const getFolders = () => {
    const createdFolders = staleFolders.filter(
      (f) => !folders.find((a) => a.id === f.id)
    );
    return [
      ...createdFolders,
      ...folders.map((f) => {
        const found = staleFolders.find((sf) => sf.id === f.id);
        if (found) {
          return found;
        }
        return f;
      }),
    ];
  };

  const unregisterStaleFolder = (remoteFolders: IReportFolder[]) => {
    const newStaleFolders = staleFolders.filter((sf) => {
      const remote = remoteFolders.find((rf) => rf.id === sf.id);
      if (remote && new Date(remote.updatedAt) > new Date(sf.updatedAt)) {
        return false;
      }
      return true;
    });
    return newStaleFolders;
  };

  React.useEffect(() => {
    if (!_.isEqual(folders, previousFolder)) {
      setStaleFolders(unregisterStaleFolder(folders));
    }
  }, [folders]);

  return (
    <FolderMenuInner
      personalFolder={personalFolder}
      folders={getFolders()}
      shouldRerender={() => {
        const e = new Event("hasDroppedFolder");
        window.dispatchEvent(e);
      }}
      isDragging={isDragging}
      updateFolders={(f) => {
        // find which folders have been updated
        // put them as stale
        const staleFolders = f
          .filter((staleFolder) => {
            const previousFolder = folders.find((a) => a.id === staleFolder.id);
            if (previousFolder && _.isEqual(staleFolder, previousFolder)) {
              return false;
            }
            return true;
          })
          .map((d) => ({
            ...d,
            updatedAt: new Date().toISOString(),
          }));
        setStaleFolders(staleFolders);
      }}
    />
  );
}

export default compose<Props, IFolderMenuProps>(
  withRouter,
  withAntUtils
)(FolderMenu);
