import { MoreOutlined } from "@ant-design/icons";
import type { MenuProps } from "antd";
import {
  Button,
  Col,
  Drawer,
  Dropdown,
  Empty,
  List,
  Modal,
  Row,
  Tabs,
  Tooltip,
  Typography,
} from "antd";
import cuid from "cuid";
import _ from "lodash";
import * as React from "react";
import type { RouteComponentProps } from "react-router";
import { withRouter } from "react-router";
import Email from "../../../../assets/email.png";
import type { InjectedAntUtilsProps } from "../../../../components/ant-utils/withAntUtils";
import { withAntUtils } from "../../../../components/ant-utils/withAntUtils";
import Card from "../../../../components/cards/Card";
import { compose } from "../../../../components/compose/WlyCompose";
import Feednack from "../../../../components/layout/feedback/feedback";
import Loading from "../../../../components/layout/feedback/loading";
import UserAvatar from "../../../../components/user/avatar/UserAvatar";
import type { AsyncData } from "../../../../helpers/typescriptHelpers";
import type { IAction, IActionMeta } from "../../../../interfaces/actions";
import type { IExploration } from "../../../../interfaces/explorations";
import type { IReport } from "../../../../interfaces/reports";
import type { ISchedule } from "../../../../interfaces/schedule";
import { routeDescriptor } from "../../../../routes/routes";
import GraphQLService from "../../../../services/graphql/GraphQLService";
import { CatalogModal } from "../../../catalog/CatalogModal";
import { TABLE_WITHOUT_BRIZO_FRAGMENT } from "../../../exploration/single/domain";
import type { InjectedOrgProps } from "../../../orgs/WithOrg";
import WithOrg from "../../../orgs/WithOrg";
import {
  getActionImg,
  renderSchedule,
  shouldDisplayDestinationFormat,
} from "../../../settings/actions/push-history/helper";
import ScheduleEditDrawer from "./ScheduleEditDrawer";
import type { ScheduleOuterType } from "./domain";
import { generateDestinationId, getAvailableActions } from "./domain";

interface IScheduleDrawerProps {
  // Use to show/hide this drawer
  type?: ScheduleOuterType;
  visible: boolean;
  onClose: () => void;
  report: IReport;
}

interface AllSchedulesResult {
  allActionMetas: IActionMeta[];
  allActions: IAction[];
  allExplorations: IExploration[];
  allSchedules: ISchedule[];
}

type Props = IScheduleDrawerProps &
  InjectedOrgProps &
  InjectedAntUtilsProps &
  RouteComponentProps<{ organizationSlug: string }>;

const LAST_JOB_EXEC_QUERY = `
query GetCurrentJobExecutionID($scheduleId: ID!) {
  Schedule(where: { id: $scheduleId }) {
    lastJobExecution {
      id
      status
    }
  }
}
`;

const CANCEL_RUN = `
mutation StopCurrentSync($scheduleId: ID!) {
  updateSchedule(id: $scheduleId, data: {
    status: idle
  }) {
    id
  }
}
`;

const CANCEL_RUN_WITH_JOB_EXECUTION = `
mutation StopCurrentSync($scheduleId: ID!, $jobExecutionId: ID!) {
  updateSchedule(id: $scheduleId, data: {
    status: idle
  }) {
    id
  }

  updateJobExecution(id: $jobExecutionId, data: {
    status: FAILED
  }) {
    id
  }
}
`;

function ScheduleListDrawer(props: Props) {
  const { antUtils, type, org, report, user } = props;
  const [editDrawerIsOpened, setEditDrawerIsOpened] = React.useState<{
    opened: boolean;
    initialDestinationId?: string;
    schedule?: ISchedule;
  }>({ opened: false });
  const [deleteModalIsOpened, setDeleteModalIsOpened] = React.useState<{
    opened: boolean;
    schedule?: ISchedule;
  }>({ opened: false });

  const [data, setData] = React.useState<AsyncData<AllSchedulesResult>>({
    status: "initial",
  });

  const [catalogModalOpen, setCatalogModalOpen] =
    React.useState<boolean>(false);

  const getSchedulesListData = () => {
    GraphQLService<AllSchedulesResult>(
      `
    ${TABLE_WITHOUT_BRIZO_FRAGMENT}

    query getSchedulesForReport($orgId: ID!, $reportId: ID!) {
      allActionMetas {
        slug
        publicInfo {
            logo
            label
            name
        }
      }
      allActions(where: { org: {id: $orgId}, isDeleted_not: true }) {
        id
        name
        actionMeta
        actionMetaVirtual {
          supportedFormats
        }
      }
      allSchedules(
        sortBy: id_ASC
        where: {
          deleted_not: true,
          org: {
            id: $orgId
          },
          workflow: { 
            report: { id: $reportId }
           }
        }
      ) {
        id
        name
        status
        period
        atHour
        dailySendEvery
        weeklyOnDay
        monthlyDayOfMonthPreset
        monthlyDayOfMonthNumber
        timezone
        workflow {
          id
          type
          emails
          contentFormat
          action {
            id
            name
            actionMeta
          }
          owner {
            id
            firstName
            lastName
            logo
            gravatar
            avatarColor
          }
          controlledValues(where: { deleted_not: true }) {
            id
            filter {
              id
            }
            value
          }
          actionFormValues
        }
      }
      allExplorations(where:{AND: [{org: {id: $orgId}}, {deleted_not: true}]}) {
        id
        slug
        name
        description
        tables(where: {deleted_not: true}) {
          ...TableCoreQuery
        }
      }
    }
    `,
      {
        reportId: props.report.id,
        orgId: props.org.id,
      }
    )
      .then((r) => {
        setData((prevState) => {
          if (prevState.status !== "success") {
            return { status: "success", data: r };
          } else if (!_.isEqual(r, prevState.data)) {
            return { status: "success", data: r };
          }
          return prevState;
        });
      })
      .catch((err) => {
        setData({ status: "error", error: err });
      });
  };

  const deleteSchedule = (schedule?: ISchedule) => async () => {
    await GraphQLService(
      `
    mutation DeleteSchedule($scheduleId: ID!, $workflowId: ID!) {
      updateSchedule(id: $scheduleId, data: { deleted: true }) {
        id
      }
      updateWorkflow(id: $workflowId, data: { deleted: true }) {
        id
      }
    }
`,
      {
        scheduleId: schedule?.id,
        workflowId: schedule?.workflow?.id,
      }
    );
    setDeleteModalIsOpened({ opened: false });
  };

  const executeSchedule = async (scheduleId: string) => {
    await GraphQLService(
      `
    mutation ExecuteScheduleNow($scheduleId: ID!) {
      executeScheduleNow(scheduleId: $scheduleId)
    }
    `,
      {
        scheduleId: scheduleId,
      }
    );
    await getSchedulesListData();
  };

  const cancelScheduleExecution = async (schedule: ISchedule) => {
    const scheduleId = schedule.id;

    const currentJobExecutionIdRes = await GraphQLService<{
      Schedule: {
        lastJobExecution: {
          id: string;
          status: string;
        };
      };
    }>(LAST_JOB_EXEC_QUERY, {
      scheduleId,
    });

    const jobExecutionStatus =
      currentJobExecutionIdRes.Schedule.lastJobExecution.status;
    const jobExecutionId =
      currentJobExecutionIdRes.Schedule.lastJobExecution.id;

    if (jobExecutionStatus === "RUNNING") {
      await GraphQLService<{
        updateSchedule: {
          id: string;
        };
        updateJobExecution: {
          id: string;
        };
      }>(CANCEL_RUN_WITH_JOB_EXECUTION, {
        scheduleId,
        jobExecutionId,
      });
    } else {
      await GraphQLService<{
        updateSchedule: {
          id: string;
        };
      }>(CANCEL_RUN, {
        scheduleId,
      });
    }
    await getSchedulesListData();
  };

  React.useEffect(() => {
    if (editDrawerIsOpened.opened === false && props.visible === true) {
      setData({ status: "loading" });
      getSchedulesListData();
      const refreshInterval = window.setInterval(
        () => getSchedulesListData(),
        1000
      );

      return () => {
        clearInterval(refreshInterval);
      };
    }
  }, [props.visible, props.report.id, editDrawerIsOpened, deleteModalIsOpened]);

  const menu = (schedule: ISchedule): MenuProps => {
    return {
      items: [
        {
          key: cuid(),
          onClick: () => setEditDrawerIsOpened({ opened: true, schedule }),
          label: "Edit",
        },
        {
          key: cuid(),
          onClick: () =>
            props.history.push(
              routeDescriptor.settingsActionHistory.renderRoute({
                organizationSlug: props.org.slug,
              })
            ),
          label: "See history",
        },
        {
          key: cuid(),
          onClick: () => setDeleteModalIsOpened({ opened: true, schedule }),
          label: "Delete",
          danger: true,
        },
      ],
    };
  };

  const renderInner = () => {
    if (data.status === "initial" || data.status === "loading") {
      return <Loading />;
    }
    if (data.status === "error") {
      return <Feednack>{JSON.stringify(data.error)}</Feednack>;
    }

    const renderHeader = () => {
      const availableActions = getAvailableActions(
        data.data.allActions,
        props.report.type
      );

      return (
        <div>
          <div className="push-header">
            <div className="push-header-title">
              <Typography.Title level={5}>Available actions</Typography.Title>
            </div>
            <div className="push-header-description">
              <a onClick={() => setCatalogModalOpen(true)}>View all</a>
            </div>
          </div>
          <Row gutter={[16, 16]} className="actions-list">
            <Col
              key={"email"}
              onClick={() =>
                setEditDrawerIsOpened({
                  opened: true,
                  initialDestinationId: "email",
                })
              }
              span={12}
            >
              <Card className={`actions-list-item`}>
                <div className="actions-list-item-image">
                  <img src={Email} alt={"logo"} />
                </div>
                <div className="actions-list-item-title">
                  <Typography.Text strong>Push to email</Typography.Text>
                </div>
              </Card>
            </Col>
            {data.data.allActions.map((action) => {
              const foundMeta = data.data.allActionMetas.find(
                (actionMeta) => action.actionMeta === actionMeta.slug
              );
              const foundAvailableAction = availableActions.find((a) => {
                return action.id === a.id;
              });
              if (!foundMeta) {
                return null;
              }

              const wrapInTooltip = (r: React.ReactNode) => {
                if (foundAvailableAction) {
                  return r;
                }
                return (
                  <Tooltip title={`Only available for question.`}>{r}</Tooltip>
                );
              };

              return wrapInTooltip(
                <Col
                  key={action.id}
                  onClick={
                    foundAvailableAction
                      ? () =>
                          setEditDrawerIsOpened({
                            opened: true,
                            initialDestinationId: generateDestinationId(
                              action.id
                            ),
                          })
                      : undefined
                  }
                  span={12}
                >
                  <Card
                    className={`actions-list-item ${
                      foundAvailableAction ? "" : "disabled"
                    }`}
                  >
                    <div className="actions-list-item-image">
                      <img src={foundMeta.publicInfo.logo} alt={"logo"} />
                    </div>
                    <div className="actions-list-item-title">
                      <Typography.Text strong>
                        Push to {action.name}
                      </Typography.Text>
                    </div>
                  </Card>
                </Col>
              );
            })}
          </Row>
        </div>
      );
    };

    const mySchedules = data.data.allSchedules.filter(
      (s) =>
        s.workflow?.owner?.id === user.id &&
        !(s.period === "never" && s.status !== "running")
    );

    const renderSchedlue = (i: ISchedule) => {
      return (
        <List.Item
          className="schedule-item"
          actions={[
            i.status === "idle" ? (
              <Button
                onClick={() => {
                  antUtils.message.info(
                    "It may take up to a few minutes for the push to be process. In the meantime, you can continue using Whaly."
                  );
                  executeSchedule(i.id);
                }}
              >
                Send now
              </Button>
            ) : (
              <>
                <Button loading={true}>Running</Button>
                <Button
                  style={{ marginLeft: 8 }}
                  danger
                  onClick={() => cancelScheduleExecution(i)}
                >
                  Cancel
                </Button>
              </>
            ),
            <Dropdown trigger={["click"]} menu={menu(i)} key={i.id}>
              <MoreOutlined />
            </Dropdown>,
          ]}
        >
          <List.Item.Meta
            avatar={
              <img
                src={getActionImg(i, data.data.allActionMetas)}
                alt={i.name}
                style={{ width: 40 }}
              />
            }
            title={i.name}
            description={
              <>
                {renderSchedule(i, true, () => (
                  <span>-</span>
                ))}
                {shouldDisplayDestinationFormat(i) && (
                  <>
                    {" • "} {i.workflow.contentFormat}
                  </>
                )}
                {
                  <>
                    {" • "}
                    <UserAvatar size={18} user={i.workflow?.owner} />
                  </>
                }
              </>
            }
          />
        </List.Item>
      );
    };

    return (
      <>
        {renderHeader()}
        <div>
          <div className={`push-header margin-top`}>
            <Tabs
              style={{ width: "100%" }}
              items={[
                {
                  label: <Typography.Text strong>My Schedules</Typography.Text>,
                  key: "my",
                  destroyInactiveTabPane: true,
                  children: mySchedules.length ? (
                    <List
                      className="schedule-list"
                      dataSource={mySchedules}
                      bordered={true}
                      renderItem={(schedule) => {
                        return renderSchedlue(schedule);
                      }}
                    />
                  ) : (
                    <div>
                      <Empty
                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                        style={{ margin: "38px 0px" }}
                        description={
                          <div>
                            <div>You haven't set up a push yet</div>
                            {type === "email" ? (
                              <div>
                                <Button
                                  type="link"
                                  onClick={() =>
                                    setEditDrawerIsOpened({
                                      opened: true,
                                      initialDestinationId: "email",
                                    })
                                  }
                                >
                                  New
                                </Button>
                              </div>
                            ) : null}
                          </div>
                        }
                      />
                    </div>
                  ),
                },
                {
                  label: (
                    <Typography.Text strong>All Schedules</Typography.Text>
                  ),
                  key: "all",
                  destroyInactiveTabPane: true,
                  children: data.data.allSchedules.length ? (
                    <List
                      className="schedule-list"
                      dataSource={data.data.allSchedules}
                      bordered={true}
                      renderItem={(schedule) => {
                        return renderSchedlue(schedule);
                      }}
                    />
                  ) : (
                    <div>
                      <Empty
                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                        style={{ margin: "38px 0px" }}
                        description={
                          <div>
                            <div>You haven't set up a push yet</div>
                            {type === "email" ? (
                              <div>
                                <Button
                                  type="link"
                                  onClick={() =>
                                    setEditDrawerIsOpened({
                                      opened: true,
                                      initialDestinationId: "email",
                                    })
                                  }
                                >
                                  New
                                </Button>
                              </div>
                            ) : null}
                          </div>
                        }
                      />
                    </div>
                  ),
                },
              ]}
            />
          </div>
          <div>
            <div></div>
          </div>
        </div>
      </>
    );
  };

  const drawerTitle = "Schedule delivery";

  return (
    <Drawer
      title={drawerTitle}
      placement={"right"}
      width={560}
      onClose={props.onClose}
      open={props.visible}
    >
      {renderInner()}
      <ScheduleEditDrawer
        report={props.report}
        filters={props.report.filters}
        explorations={
          data.status === "success" ? data.data.allExplorations : []
        }
        executeSchedule={executeSchedule}
        visible={editDrawerIsOpened.opened}
        schedule={editDrawerIsOpened.schedule}
        initialDestinationId={editDrawerIsOpened.initialDestinationId}
        type={type}
        onClose={() => setEditDrawerIsOpened({ opened: false })}
      />
      <Modal
        title={`Danger zone`}
        open={deleteModalIsOpened.opened}
        onOk={deleteSchedule(deleteModalIsOpened?.schedule)}
        onCancel={() => {
          setDeleteModalIsOpened({ opened: false });
        }}
      >
        {(() => {
          const scheduleBeingDeleted = deleteModalIsOpened.schedule;
          return (
            <>
              <p>
                Are you sure to delete Push <b>{scheduleBeingDeleted?.name}</b>?
                This action can't be undone
              </p>
            </>
          );
        })()}
      </Modal>
      <CatalogModal
        open={catalogModalOpen}
        onClose={() => setCatalogModalOpen(false)}
        type={"action"}
      />
    </Drawer>
  );
}

export default compose<Props, IScheduleDrawerProps>(
  WithOrg,
  withRouter,
  withAntUtils
)(ScheduleListDrawer);
