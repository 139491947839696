import Icon from "@ant-design/icons";
import type { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";

export const WlyColorDropCrossedIcon = (
  props: Partial<CustomIconComponentProps>
) => (
  <Icon
    component={() => (
      <svg
        width="1em"
        height="1em"
        viewBox="0 0 500 500"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M434.396 311.071C434.396 203.046 288.713 41.9842 252.756 4.03377V4.02986C250.635 1.84002 247.716 0.600403 244.663 0.600403C241.614 0.600403 238.69 1.84002 236.574 4.02986C225.339 15.2408 204.324 37.2723 180.391 65.6976L434.277 319.088C434.277 316.339 434.39 313.762 434.39 311.071L434.396 311.071Z"
          fill="black"
        />
        <path
          d="M452.997 427.455L95.4543 70.6948C93.3458 68.5871 90.485 67.4061 87.5026 67.4061C84.5202 67.4061 81.6633 68.5871 79.5549 70.6948L47.8107 102.37C45.7022 104.47 44.5148 107.324 44.5148 110.3C44.5148 113.276 45.7022 116.13 47.8107 118.234L102.591 172.894C75.3974 218.748 55.1706 267.241 55.1706 311.082C55.1941 360.944 74.9419 408.784 110.126 444.204C145.311 479.616 193.08 499.736 243.058 500.193C293.03 500.647 341.162 481.4 376.994 446.636L405.085 474.666C407.19 476.77 410.05 477.955 413.033 477.955C416.015 477.955 418.876 476.77 420.985 474.666L452.729 442.991C456.981 438.729 457.102 431.877 453.007 427.463L452.997 427.455Z"
          fill="black"
        />
      </svg>
    )}
    {...props}
  />
);
