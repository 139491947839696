import { Form, Input } from "antd";
import * as React from "react";
import { UPDATE_QUERY } from "../../containers/reports/view/domain";
import GraphQLService from "../../services/graphql/GraphQLService";
import type { InjectedAntUtilsProps } from "../ant-utils/withAntUtils";
import { withAntUtils } from "../ant-utils/withAntUtils";
import { compose } from "../compose/WlyCompose";
import WlyModal from "../modal/WlyModal";

interface IReportRenameProps {
  visible: boolean;
  initialData?: ReportInitialData;
  onSave: (v: ReportInitialData) => any;
  onCancel: () => any;
}

type Props = IReportRenameProps & InjectedAntUtilsProps;

interface ReportInitialData {
  id: string;
  name: string;
}

function ReportRename(props: Props) {
  const { visible, initialData, antUtils, onCancel, onSave } = props;
  const [confirmLoading, setConfirmLoading] = React.useState(false);
  const inputRef = React.useRef<any>(null);
  const [form] = Form.useForm();

  React.useEffect(() => {
    if (visible === false) {
      form.resetFields();
      form.setFieldsValue({ name: "" });
    }
  }, [visible]);

  React.useEffect(() => {
    form.setFieldsValue(initialData);
  }, [JSON.stringify(initialData)]);

  const renderInnerModal = () => {
    return (
      <Form
        initialValues={
          initialData
            ? {
                name: initialData.name,
              }
            : {
                name: "",
              }
        }
        layout="vertical"
        form={form}
      >
        <Form.Item label="Report name">
          <Input.Group compact>
            <Form.Item
              name={["name"]}
              noStyle
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input
                ref={inputRef}
                style={{ width: "100%" }}
                placeholder="Report name"
              />
            </Form.Item>
          </Input.Group>
        </Form.Item>
      </Form>
    );
  };

  return (
    <WlyModal
      open={!!visible}
      title={"Rename Report"}
      okText={"Rename"}
      cancelText="Cancel"
      onClose={() => {
        form.resetFields();
        onCancel();
      }}
      confirmLoading={confirmLoading}
      onOk={async () => {
        setConfirmLoading(true);
        try {
          const values: ReportInitialData = await form.validateFields();
          if (initialData && initialData.id) {
            const resp = await GraphQLService(UPDATE_QUERY, {
              id: initialData.id,
              data: { name: values.name },
            });
            setConfirmLoading(false);
            antUtils.message.success("Report renamed");
            return onSave(resp.updateReport);
          }
          onCancel();
          return form.resetFields();
        } catch (error) {
          setConfirmLoading(false);
          antUtils.message.error("Error while renaming Report");
          console.warn("Validate Failed:", error);
        }
      }}
    >
      {renderInnerModal()}
    </WlyModal>
  );
}

export default compose<Props, IReportRenameProps>(withAntUtils)(ReportRename);
