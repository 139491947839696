import type { EditorState } from "@codemirror/state";
import type { EditorView } from "@codemirror/view";
import { ViewPlugin } from "@codemirror/view";

export const onScrollPlugin = (
  onChange: (scrollTop: number, scrollLeft: number) => void
) => {
  return ViewPlugin.fromClass(
    class ViewScroll {
      public state: EditorState;
      public view: EditorView;
      public scrollEndTimer: any;
      constructor(view: EditorView) {
        this.view = view;
        this.scrollEndTimer = null;
        view.scrollDOM.addEventListener("scroll", this.onScroll.bind(this));
      }

      onScroll(e: Event) {
        clearTimeout(this.scrollEndTimer);
        this.scrollEndTimer = setTimeout(() => {
          const target = e.target as HTMLDivElement;
          if (target) {
            onChange(target.scrollTop, target.scrollLeft);
          }
        }, 200);
      }

      destroy() {
        this.view.scrollDOM.removeEventListener(
          "scroll",
          this.onScroll.bind(this)
        );
      }
    }
  );
};
