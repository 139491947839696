import { Promise as BPromise } from "bluebird";
import { compose } from "../../../components/compose/WlyCompose";
import ShareForm from "../../../components/object-sharing/ShareForm";
import type {
  ISearchableItem,
  ISharing,
  ISharingInitialData,
  InheritedSharing,
} from "../../../components/object-sharing/domain";
import { GROUP_INFO_FRAGMENT } from "../../../fragments/group";
import type {
  IReportFolder,
  IReportFolderSharing,
} from "../../../interfaces/folder";
import type { IGroup } from "../../../interfaces/group";
import type { IAccessType } from "../../../interfaces/reportSharing";
import type { IUserGravatarInfo } from "../../../interfaces/user";
import { IUserRoleType } from "../../../interfaces/user";
import { routeDescriptor } from "../../../routes/routes";
import GraphQLService from "../../../services/graphql/GraphQLService";
import { assignUserRole } from "../../../services/userService";
import type { InjectedOrgProps } from "../../orgs/WithOrg";
import WithOrg from "../../orgs/WithOrg";

interface IFolderShareProps {
  visible: boolean;
  canBeManagedByCurrentUser: boolean;
  orgHasFeature: boolean;
  onClose: () => any;
  reportFolder: IReportFolder;
}

type Props = IFolderShareProps & InjectedOrgProps;

const USER_INFO_FRAGMENT = `
fragment UserInfo on User {
  id
  firstName
  lastName
  email
  gravatar
  avatarColor
}
`;

const REPORT_FOLDER_SHARING_FRAGMENT = `
fragment ReportFolderSharingInfo on ReportFolderSharing {
  id
  user {
    ...UserInfo
    isPublicGuestUser
  }
  group {
    ...GroupInfo
  }
  inheritParentFolderSharing
  accessType
}
`;

const GET_SHARING_FORM_DATA = `

${USER_INFO_FRAGMENT}
${GROUP_INFO_FRAGMENT}
${REPORT_FOLDER_SHARING_FRAGMENT}

query GetSharingFromData($orgId: ID!, $realmId: ID!, $reportFolderId: ID!) {
  orgUsers: allUsers(
    where: {
      isPublicGuestUser: false
      isDeleted: false
      isAdmin: false
      roles_some: { isDeleted: false, org: { id: $orgId } }
    }
  ) {
    ...UserInfo
  }
  realmUsers: allUsers(
    where: {
      isPublicGuestUser: false
      isDeleted: false
      isAdmin: false
      realm: { realm: { id: $realmId }}
    }
  ) {
    ...UserInfo
  }
  groups: allGroups(where: { isDeleted: false, org: { id: $orgId } }) {
    ...GroupInfo
  }
  sharings: allReportFolderSharings(
    where: { isDeleted: false, reportFolder: { id: $reportFolderId } }
  ) {
    ...ReportFolderSharingInfo
  }
  folder: ReportFolder(where: { id: $reportFolderId }) {
    parent {
      id
    }
  }
}
`;

interface ShareFormDataResult {
  orgUsers: IUserGravatarInfo[];
  realmUsers: IUserGravatarInfo[];
  groups: IGroup[];
  sharings: IReportFolderSharing[];
  folder: {
    parent?: {
      id: string;
    };
  };
}

function FolderShareForm(props: Props) {
  const {
    visible,
    canBeManagedByCurrentUser,
    orgHasFeature,
    onClose,
    org,
    reportFolder,
  } = props;

  const fetchInitialData = async (): Promise<ISharingInitialData> => {
    const sharingFormData = await GraphQLService<ShareFormDataResult>(
      GET_SHARING_FORM_DATA,
      {
        orgId: org.id,
        realmId: org.realm?.id,
        reportFolderId: reportFolder.id,
      }
    );

    // Compute the existings Sharings
    const directSharings: ISharing[] = sharingFormData.sharings
      .filter((sharing) => {
        if (sharing.user?.isPublicGuestUser === true) {
          return false;
        }
        return sharing.inheritParentFolderSharing === false;
      })
      .map((rs) => {
        return {
          ...rs,
          inheritParentSharing: rs.inheritParentFolderSharing,
        };
      });

    const getInheritedSharings = async (): Promise<InheritedSharing[]> => {
      const recursiveFindIneritedSharings = async (
        parentId: string,
        sharingsAcc: InheritedSharing[]
      ): Promise<InheritedSharing[]> => {
        const queryText = `
          fragment UserInfo on User {
            id
            firstName
            lastName
            email
            gravatar
            avatarColor
          }
          
          ${GROUP_INFO_FRAGMENT}
          ${REPORT_FOLDER_SHARING_FRAGMENT}
    
          query GetParentReportFolderSharings($parentId: ID!) {
            allReportFolders(where: { id: $parentId }) {
              id
              slug
              name
              description
              order
              image
              deleted
              parent {
                id
              }
              reportSharings(where: { isDeleted: false }) {
                ...ReportFolderSharingInfo
              }
            }
          }
          `;

        interface GetParentSharingResult {
          allReportFolders: {
            id: string;
            slug: string;
            name: string;
            description: string;
            image: string;
            deleted: boolean;
            parent?: {
              id: string;
            };
            reportSharings: IReportFolderSharing[];
          }[];
        }

        const result = await GraphQLService<GetParentSharingResult>(queryText, {
          parentId,
        });
        const parent = result.allReportFolders[0];
        const nextParentId = parent.parent?.id;

        const isInheritingParentSharing = parent.reportSharings.find(
          (sharing) => {
            return sharing.inheritParentFolderSharing === true;
          }
        );

        const directParentSharings = parent.reportSharings.filter((sharing) => {
          if (sharing.user?.isPublicGuestUser === true) {
            return false;
          }
          return sharing.inheritParentFolderSharing === false;
        });

        if (directParentSharings.length > 0) {
          sharingsAcc.push({
            inheritingFrom: {
              name: parent.name,
              linkTo: routeDescriptor["folders"].renderRoute({
                organizationSlug: org.slug,
                folderSlug: parent.slug,
              }),
            },
            sharings: directParentSharings,
          });
        }

        if (isInheritingParentSharing && nextParentId) {
          return recursiveFindIneritedSharings(nextParentId, sharingsAcc);
        } else {
          return sharingsAcc;
        }
      };

      // If we're inheriting, we'll check in the hierarchy who is the parent
      if (
        sharingFormData.sharings.find(
          (sharing) => sharing.inheritParentFolderSharing === true
        ) &&
        sharingFormData.folder.parent?.id
      ) {
        const inheritedSharings = await recursiveFindIneritedSharings(
          sharingFormData.folder.parent.id,
          []
        );
        return inheritedSharings;
      } else {
        return [];
      }
    };

    const inheritedSharings = await getInheritedSharings();

    // Compute the users / groups that could be still be shared with
    const allSharings: ISharing[] = directSharings.concat(
      inheritedSharings.flatMap((inheritedSharing) => inheritedSharing.sharings)
    );

    const allUsersIdsWithSharings = allSharings
      .filter((sharing) => sharing.user)
      .map((sharing) => sharing.user!.id);

    const allGroupsIdsWithSharings = allSharings
      .filter((sharing) => sharing.group)
      .map((sharing) => sharing.group!.id);

    const orgUsers = sharingFormData.orgUsers.filter(
      (user) => !allUsersIdsWithSharings.includes(user.id)
    );

    const realmUsers = sharingFormData.realmUsers
      .filter((userRes) => {
        const orgUserIds = orgUsers.map((userRes) => userRes.id);
        return !orgUserIds.includes(userRes.id);
      })
      .filter((user) => !allUsersIdsWithSharings.includes(user.id));

    const groups = sharingFormData.groups.filter(
      (group) => !allGroupsIdsWithSharings.includes(group.id)
    );

    return {
      availableUsersAndGroup: {
        orgUsers,
        realmUsers,
        groups,
      },
      inheritedSharings,
      directSharings,
    };
  };

  const onAccessTypeChange = async (
    sharingId: string,
    newType: IAccessType
  ) => {
    const queryText = `
    mutation UpdateReportSharing($sharingId:ID!, $newType: ReportFolderSharingAccessTypeType!) {
      updateReportFolderSharing(id: $sharingId, data: {
        accessType: $newType
      }) {
        id
      }
    }`;
    await GraphQLService(queryText, { sharingId, newType });
  };

  const onRemoveAccess = async (sharingId: string) => {
    const queryText = `
    mutation DeleteReportSharing($sharingId:ID!) {
      updateReportFolderSharing(id: $sharingId, data: {
        isDeleted: true
      }) {
        id
      }
    }`;
    await GraphQLService(queryText, { sharingId });
  };

  const createNewSharings = async (
    selectedItems: ISearchableItem[],
    accessType: IAccessType
  ) => {
    const queryText = `
    mutation CreateReportFolderSharings($data: [ReportFolderSharingsCreateInput]) {
      createReportFolderSharingsWithACL(data: $data)
    }`;

    interface ReportFolderSharingsCreateInput {
      data: ReportFolderSharingCreateInput;
    }
    interface ReportFolderSharingCreateInput {
      reportFolder: {
        connect: {
          id: string;
        };
      };
      inheritParentFolderSharing: boolean;
      accessType: IAccessType;
      user?: { connect: { id: string } };
      group?: { connect: { id: string } };
      isDeleted?: boolean;
      org: { connect: { id: string } };
    }

    const selectedRealmUserIds = selectedItems
      .filter((item) => {
        return item.type === "user-realm";
      })
      .map((item) => item.id);

    const selectedOrgUserIds = selectedItems
      .filter((item) => {
        return item.type === "user-org";
      })
      .map((item) => item.id);

    // Create UserRole for those users before creating a sharing
    if (selectedRealmUserIds.length > 0) {
      await BPromise.map(
        selectedRealmUserIds,
        async (userId) => {
          await assignUserRole(userId, org.id, IUserRoleType.VIEWER);
        },
        { concurrency: 3 }
      );
    }

    const userSharingData: ReportFolderSharingsCreateInput[] =
      selectedOrgUserIds.concat(selectedRealmUserIds).map((userId) => {
        return {
          data: {
            reportFolder: {
              connect: {
                id: reportFolder.id,
              },
            },
            org: {
              connect: { id: org.id },
            },
            isDeleted: false,
            inheritParentFolderSharing: false,
            accessType,
            user: {
              connect: {
                id: userId,
              },
            },
          },
        };
      });

    const selectedGroupIds = selectedItems
      .filter((item) => {
        return item.type === "group";
      })
      .map((item) => item.id);

    const groupSharingData: ReportFolderSharingsCreateInput[] =
      selectedGroupIds.map((groupId) => {
        return {
          data: {
            reportFolder: {
              connect: {
                id: reportFolder.id,
              },
            },
            org: {
              connect: { id: org.id },
            },
            isDeleted: false,
            inheritParentFolderSharing: false,
            accessType,
            group: {
              connect: {
                id: groupId,
              },
            },
          },
        };
      });

    const data = userSharingData.concat(groupSharingData);
    if (data.length > 0) {
      await GraphQLService(queryText, { data });
    }
  };

  const disableEdit = (group?: IGroup) => {
    if (!canBeManagedByCurrentUser) {
      return true;
    }
    if (!orgHasFeature) {
      return true;
    }
    if (group?.isAdminGroup) {
      return true;
    }
    return false;
  };

  return (
    <ShareForm
      visible={visible}
      canBeManagedByCurrentUser={canBeManagedByCurrentUser}
      orgHasFeature={orgHasFeature}
      onClose={onClose}
      modalTitle={`Share '${reportFolder?.name}'`}
      fetchSharings={fetchInitialData}
      updateSharing={onAccessTypeChange}
      removeSharing={onRemoveAccess}
      createSharings={createNewSharings}
      availableAccessTypes={["edit", "view", "manage"]}
      disableEdit={disableEdit}
    />
  );
}

export default compose<Props, IFolderShareProps>(WithOrg)(FolderShareForm);
