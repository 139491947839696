import {
  DeleteOutlined,
  EyeInvisibleOutlined,
  EyeOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import type { MenuProps } from "antd";
import {
  Button,
  ConfigProvider,
  Dropdown,
  Form,
  Input,
  InputNumber,
  Popconfirm,
  Select,
  Space,
  Switch,
  Typography,
  theme,
} from "antd";
import { useForm } from "antd/lib/form/Form";
import _ from "lodash";
import React from "react";
import { compose } from "../../../../../components/compose/WlyCompose";
import { SwitchIcons } from "../../../../../components/form/elements/switch-icons/SwitchIcons";
import usePrevious from "../../../../../components/hooks/usePrevious";
import type { IExploration } from "../../../../../interfaces/explorations";
import type { IFilter, IReport } from "../../../../../interfaces/reports";
import type { DataType } from "../../../../../interfaces/transformations";
import { ChartOptionCollapse } from "../../../../chart-options/components/ChartOptionCollapse";
import ChartOptionLine from "../../../../chart-options/components/ChartOptionLine";
import type { InjectedOrgProps } from "../../../../orgs/WithOrg";
import WithOrg from "../../../../orgs/WithOrg";
import FilterDimensionSelector from "../edition/FilterDimensionSelector";
import FieldFilter from "../field-filter/FieldFilter";
import FieldAutocomplete from "../field-filter/fields/FieldAutocomplete";

interface IFilterOptionsEditProps {
  filter: IFilter;
  report: IReport;
  explorations: IExploration[];
  onFilterUpdate: (
    filter: IFilter,
    shouldReloadQuery?: boolean
  ) => Promise<void>;
  scrollToId: (id: string) => void;
}

type Props = IFilterOptionsEditProps & InjectedOrgProps;

function FilterOptionsEdit(props: Props) {
  const { filter, explorations, onFilterUpdate, report, org, scrollToId } =
    props;
  const [form] = useForm();
  const prevFilterId = usePrevious(filter?.id);

  const explorationsFlatMap = explorations.flatMap((ex) =>
    ex.tables.flatMap((t) =>
      t.dimensions.flatMap((d) => ({
        value: `${t.cubeName}.${d.cubeName}`,
        domain: d.type === "standard" ? d.columnDomain : undefined,
        explorationId: ex.id,
      }))
    )
  );

  const getDomain = (f: IFilter): DataType => {
    if (f?.useDefaultTimeField) {
      return "TIME";
    }

    const selectedDimension = explorationsFlatMap.find(
      (d) => d.value === f?.valueDimension
    );

    if (selectedDimension?.domain) {
      return selectedDimension.domain;
    }

    return "TIME";
  };

  const [domain, setDomain] = React.useState<DataType>(getDomain(filter));

  React.useEffect(() => {
    if (filter?.id !== prevFilterId) {
      form.resetFields();
      setDomain(getDomain(filter));
    }
  }, [filter]);

  if (!filter) {
    return null;
  }

  const filteredTiles = report.tiles.filter(
    (t) => t.type === "chart" && t.exploration
  );

  return (
    <ConfigProvider
      theme={{
        algorithm: theme.compactAlgorithm,
      }}
    >
      <Form
        initialValues={{
          ...filter,
          defaultValue:
            filter.defaultTo === "ATTRIBUTE"
              ? filter.rawDefaultValue
              : filter.defaultValue,
          controlledBy:
            filter.controlledBy.map((cb) => ({
              id: cb.id,
              parent: cb.parent.id,
              child: filter.id,
              dimensionToUpdate: cb.dimensionToUpdate,
            })) ?? [],
          tilesToUpdate: filteredTiles.reduce<{ [key: string]: string }>(
            (acc, t) => {
              const ttu = filter.tilesToUpdate.find((a) => a.tile.id === t.id);
              return {
                ...acc,
                [t.id]: ttu?.dimensionToUpdate ? ttu?.dimensionToUpdate : "",
              };
            },
            {}
          ),
        }}
        form={form}
        onValuesChange={(change) => {
          const formValues = form.getFieldsValue();
          const currentDomain = getDomain(formValues);
          setDomain(currentDomain);
          if ("filterLimit" in change) {
            if (typeof formValues.filterLimit !== "number") {
              form.setFieldValue("filterLimit", 5000);
            }
          }
          if (change.valueDimension) {
            if (currentDomain === "TIME") {
              // in time we require a value and cannot use labelDimension
              // required and componentType
              form.setFieldValue("labelDimension", null);
              form.setFieldValue("componentType", "TIME_PICKER");
              form.setFieldValue("defaultValue", "LAST_7_DAYS");
              form.setFieldValue("defaultTo", "VALUE");
              form.setFieldValue("controlledBy", []);
            } else {
              form.setFieldValue("defaultValue", null);
              form.setFieldValue("defaultTo", "VALUE");
              form.setFieldValue("componentType", "SELECT");
            }
          }

          if (change.defaultTo) {
            form.setFieldValue("defaultValue", null);
          }

          const resettedFormValues = form.getFieldsValue();

          if (onFilterUpdate) {
            onFilterUpdate(
              {
                ...filter,
                type: currentDomain,
                labelDimension: formValues.labelDimension
                  ? formValues.labelDimension
                  : null,
                ...resettedFormValues,
                controlledBy: (resettedFormValues.controlledBy ?? []).filter(
                  (cb) => cb.dimensionToUpdate && cb.dimensionToUpdate !== ""
                ),
                tilesToUpdate: Object.keys(
                  resettedFormValues.tilesToUpdate
                    ? resettedFormValues.tilesToUpdate
                    : {}
                )
                  .filter(
                    (t) =>
                      !!resettedFormValues.tilesToUpdate[t] &&
                      resettedFormValues.tilesToUpdate[t] !== ""
                  )
                  .map((t) => ({
                    tile: { id: t },
                    dimensionToUpdate: resettedFormValues.tilesToUpdate[t],
                  })),
              },
              !_.isEqual(filter.defaultValue, formValues.defaultValue)
            );
          }
        }}
      >
        <Form.Item shouldUpdate noStyle>
          {() => {
            const defaultTo = form.getFieldValue("defaultTo");
            const componentType = form.getFieldValue("componentType");
            const dimension = form.getFieldValue("valueDimension");

            const objectId = explorationsFlatMap.find(
              (v) => v.value === dimension
            )?.explorationId;

            const renderDefaultValueComponent = () => {
              if (defaultTo === "ATTRIBUTE") {
                return (
                  <Select style={{ width: "100%" }}>
                    {org.userAttributeMetas
                      .filter((am) => am.type === domain)
                      .map((am) => {
                        return (
                          <Select.Option
                            key={am.technicalName}
                            value={am.technicalName}
                          >
                            {am.label}
                          </Select.Option>
                        );
                      })}
                  </Select>
                );
              } else if (defaultTo === "VALUE") {
                if (domain === "TIME") {
                  return (
                    <FieldFilter
                      editing={false}
                      valueDimension={form.getFieldValue("valueDimension")}
                      tilesToUpdate={[]}
                      controlledBy={[]}
                      requireValue={true}
                      componentType={"TIME_PICKER"}
                      filterType={"TIME"}
                      objectId={""}
                      objectType={"EXPLORATION"}
                      block
                    />
                  );
                }
                if (objectId) {
                  return (
                    <FieldAutocomplete
                      multi={componentType === "MULTI_SELECT"}
                      requireValue={form.getFieldValue("requireValue")}
                      filterType={domain}
                      style={{ width: "100%" }}
                      valueDimension={form.getFieldValue("valueDimension")}
                      labelDimension={form.getFieldValue("labelDimension")}
                      objectId={objectId}
                      objectType={"EXPLORATION"}
                      limit={filter.filterLimit}
                    />
                  );
                }
              }
              return null;
            };

            return (
              <>
                <ChartOptionCollapse title={"Filter settings"}>
                  <Space style={{ width: "100%" }} direction="vertical">
                    <ChartOptionLine
                      items={[
                        {
                          flex: 1,
                          content: "Name",
                        },
                        {
                          flex: 2,
                          content: (
                            <Form.Item name={["name"]} noStyle>
                              <Input />
                            </Form.Item>
                          ),
                        },
                      ]}
                    />
                    {filter.type !== "TIME" && (
                      <ChartOptionLine
                        items={[
                          {
                            flex: 1,
                            content: "Dimension",
                          },
                          {
                            flex: 2,
                            content: (
                              <Form.Item name={["valueDimension"]} noStyle>
                                <FilterDimensionSelector
                                  showDoNotFilter={false}
                                  showDefaultTime={true}
                                  explorations={explorations}
                                  style={{ width: "100%" }}
                                  popupMatchSelectWidth={false}
                                  placement="bottomRight"
                                />
                              </Form.Item>
                            ),
                          },
                        ]}
                      />
                    )}
                    <ChartOptionLine
                      items={[
                        {
                          flex: 1,
                          content: "Display filter",
                        },
                        {
                          flex: 0,
                          content: (
                            <Form.Item name={["hidden"]} noStyle>
                              <SwitchIcons
                                icons={{
                                  true: <EyeInvisibleOutlined />,
                                  false: <EyeOutlined />,
                                }}
                              />
                            </Form.Item>
                          ),
                        },
                      ]}
                    />
                  </Space>
                  {/* Hidden for hidden to make sure we do not lose the value */}
                  {filter.type === "TIME" && (
                    <Form.Item
                      name={["valueDimension"]}
                      noStyle
                      hidden={true}
                    />
                  )}
                </ChartOptionCollapse>
                <ChartOptionCollapse title={"Render options"}>
                  <Space style={{ width: "100%" }} direction="vertical">
                    {domain !== "TIME" && (
                      <ChartOptionLine
                        items={[
                          {
                            flex: 1,
                            content: "Filter value",
                          },
                          {
                            flex: 2,
                            content: (
                              <Form.Item name={["labelDimension"]} noStyle>
                                <FilterDimensionSelector
                                  showDoNotFilter={false}
                                  showDefaultTime={false}
                                  explorations={explorations}
                                  popupMatchSelectWidth={false}
                                  style={{ width: "100%" }}
                                  placement="bottomRight"
                                  allowClear
                                />
                              </Form.Item>
                            ),
                          },
                        ]}
                      />
                    )}
                    {domain !== "TIME" && (
                      <ChartOptionLine
                        items={[
                          {
                            flex: 1,
                            content: "Filter type",
                          },
                          {
                            flex: 2,
                            content: (
                              <Form.Item name={["componentType"]} noStyle>
                                <Select style={{ width: "100%" }}>
                                  <Select.Option value={"SELECT"}>
                                    Single value
                                  </Select.Option>
                                  <Select.Option value={"MULTI_SELECT"}>
                                    Multiple values
                                  </Select.Option>
                                </Select>
                              </Form.Item>
                            ),
                          },
                        ]}
                      />
                    )}
                    {domain !== "TIME" &&
                    form.getFieldValue("componentType") === "SELECT" ? (
                      <ChartOptionLine
                        items={[
                          {
                            flex: 1,
                            content: "Requires a value",
                          },
                          {
                            flex: 0,
                            content: (
                              <Form.Item
                                valuePropName="checked"
                                name={["requireValue"]}
                                noStyle
                              >
                                <Switch />
                              </Form.Item>
                            ),
                          },
                        ]}
                      />
                    ) : undefined}
                    <ChartOptionLine
                      items={[
                        {
                          flex: 1,
                          content: "Default to",
                        },
                        {
                          flex: 2,
                          content: (
                            <Form.Item name={["defaultTo"]} noStyle>
                              <Select
                                style={{ width: "100%" }}
                                popupMatchSelectWidth={false}
                              >
                                <Select.Option value={"VALUE"}>
                                  Predefined value
                                </Select.Option>
                                <Select.Option value={"ATTRIBUTE"}>
                                  User attributes
                                </Select.Option>
                              </Select>
                            </Form.Item>
                          ),
                        },
                      ]}
                    />
                    <ChartOptionLine
                      items={[
                        {
                          flex: 1,
                          content: "Default value",
                        },
                        {
                          flex: 2,
                          content: (
                            <Form.Item name={["defaultValue"]} noStyle>
                              {renderDefaultValueComponent()}
                            </Form.Item>
                          ),
                        },
                      ]}
                    />
                    {domain !== "TIME" && (
                      <ChartOptionLine
                        items={[
                          {
                            flex: 2,
                            content: "Autocomplete limit",
                          },
                          {
                            flex: 1,
                            content: (
                              <Form.Item name={["filterLimit"]} noStyle>
                                <InputNumber
                                  precision={0}
                                  min={0}
                                  max={50000}
                                  style={{ width: "100%" }}
                                />
                              </Form.Item>
                            ),
                          },
                        ]}
                      />
                    )}
                  </Space>
                  {/* we duplicate this field in order to still get data
                  when they are hidden in the user visible form */}
                  <div hidden>
                    <ChartOptionLine
                      items={[
                        {
                          flex: 1,
                          content: "Filter type",
                        },
                        {
                          flex: 2,
                          content: (
                            <Form.Item name={["componentType"]} noStyle>
                              <Select style={{ width: "100%" }}>
                                <Select.Option value={"SELECT"}>
                                  Single value
                                </Select.Option>
                                <Select.Option value={"MULTI_SELECT"}>
                                  Multiple values
                                </Select.Option>
                              </Select>
                            </Form.Item>
                          ),
                        },
                      ]}
                    />
                  </div>
                </ChartOptionCollapse>
                <ChartOptionCollapse title={"Tiles to update"}>
                  <Space style={{ width: "100%" }} direction="vertical">
                    {filteredTiles.map((t, i) => {
                      return (
                        <ChartOptionLine
                          key={i}
                          items={[
                            {
                              flex: 1,
                              additionalStyle: {
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                maxWidth: "100%",
                              },
                              content: (
                                <span
                                  style={{ cursor: "pointer" }}
                                  onClick={() => scrollToId(t.id)}
                                  title={t.name}
                                  data-tile-id={t.id}
                                >
                                  {t.name}
                                </span>
                              ),
                            },
                            {
                              flex: 1,
                              content: (
                                <Form.Item
                                  name={["tilesToUpdate", t.id]}
                                  noStyle
                                >
                                  <FilterDimensionSelector
                                    placeholder={"Do not filter"}
                                    explorations={explorations.filter((d) =>
                                      t && t.exploration && t.exploration.id
                                        ? d.id === t.exploration.id
                                        : false
                                    )}
                                    showDoNotFilter={true}
                                    showDefaultTime={
                                      getDomain(filter) === "TIME"
                                    }
                                    restrict={
                                      getDomain(filter) === "TIME"
                                        ? ["TIME"]
                                        : ["BOOLEAN", "NUMERIC", "STRING"]
                                    }
                                    popupMatchSelectWidth={false}
                                    placement="bottomRight"
                                    style={{ width: "100%" }}
                                  />
                                </Form.Item>
                              ),
                            },
                          ]}
                        />
                      );
                    })}
                  </Space>
                </ChartOptionCollapse>
                <div hidden={filter.type === "TIME"}>
                  <ChartOptionCollapse title={"Controlled by filters"}>
                    <Space style={{ width: "100%" }} direction="vertical">
                      <ChartOptionLine
                        items={[
                          {
                            flex: 1,
                            content: (
                              <Typography.Text type="secondary">
                                Manage filters that should restrict the
                                autocomplete values of this filter
                              </Typography.Text>
                            ),
                          },
                        ]}
                      />
                      <Form.List name="controlledBy">
                        {(fields, { add, remove }) => {
                          const explorationId = filter.exploration?.id;
                          const isBasedOnTimeDimension = filter.type === "TIME";

                          const moreMenuItems: MenuProps["items"] = (
                            report.filters || []
                          )
                            // we allow to have controls by any filter that is not
                            // - the current filter
                            .filter((f) => f.id !== filter.id)
                            // - already added as a control
                            .filter(
                              (f) =>
                                !filter.controlledBy
                                  .map((cb) => cb.parent.id)
                                  .includes(f.id)
                            )
                            .map((f, i) => ({
                              key: i,
                              label: f.name,
                              onClick: () => {
                                let dimensionToUpdate: string | null = null;
                                if (
                                  filter.exploration?.id === f.exploration?.id
                                ) {
                                  dimensionToUpdate = f.valueDimension;
                                }
                                return add({
                                  id: `new-${i}`,
                                  parent: f.id,
                                  child: filter.id,
                                  dimensionToUpdate: dimensionToUpdate,
                                });
                              },
                            }));

                          const isAddMoreDisabled =
                            isBasedOnTimeDimension ||
                            !explorationId ||
                            !moreMenuItems ||
                            moreMenuItems?.length === 0;

                          return (
                            <Space
                              direction="vertical"
                              style={{ width: "100%" }}
                            >
                              {fields.map((field, index) => {
                                const cb = form.getFieldValue([
                                  "controlledBy",
                                  index,
                                ]);
                                const parentFilter = report.filters?.find(
                                  (f) => f.id === cb.parent
                                );
                                const exploration = explorations.find(
                                  (e) => e.id === filter?.exploration?.id
                                );
                                return (
                                  <ChartOptionLine
                                    key={index}
                                    items={[
                                      {
                                        flex: 1,
                                        content: parentFilter?.name,
                                      },
                                      {
                                        flex: 2,
                                        content: (
                                          <Form.Item
                                            noStyle
                                            name={[index, "dimensionToUpdate"]}
                                          >
                                            <FilterDimensionSelector
                                              showDoNotFilter={true}
                                              showDefaultTime={false}
                                              explorations={
                                                exploration ? [exploration] : []
                                              }
                                              style={{ width: "100%" }}
                                              popupMatchSelectWidth={false}
                                              placement="bottomRight"
                                              treeDefaultExpandAll
                                            />
                                          </Form.Item>
                                        ),
                                      },
                                      {
                                        flex: 0,
                                        content: (
                                          <Popconfirm
                                            title="Delete this control"
                                            description="Are you sure you want to delete this control?"
                                            okText="Yes"
                                            onConfirm={() => remove(index)}
                                            okButtonProps={{
                                              danger: true,
                                            }}
                                            cancelText="No"
                                            placement="topRight"
                                          >
                                            <Button
                                              type="text"
                                              icon={<DeleteOutlined />}
                                            />
                                          </Popconfirm>
                                        ),
                                      },
                                    ]}
                                  />
                                );
                              })}
                              <ChartOptionLine
                                items={[
                                  {
                                    flex: 1,
                                    content: (
                                      <Dropdown
                                        trigger={["click"]}
                                        menu={{ items: moreMenuItems }}
                                        disabled={isAddMoreDisabled}
                                      >
                                        <Button
                                          type="dashed"
                                          icon={<PlusOutlined />}
                                          disabled={isAddMoreDisabled}
                                          block
                                        >
                                          Add a control
                                        </Button>
                                      </Dropdown>
                                    ),
                                  },
                                ]}
                              />
                            </Space>
                          );
                        }}
                      </Form.List>
                    </Space>
                  </ChartOptionCollapse>
                </div>
                <ChartOptionCollapse
                  defaultExpanded={false}
                  title={"Developer options"}
                >
                  <ChartOptionLine
                    items={[
                      {
                        flex: 1,
                        content: "Api name",
                      },
                      {
                        flex: 2,
                        content: (
                          <Form.Item name={["apiName"]} noStyle>
                            <Input />
                          </Form.Item>
                        ),
                      },
                    ]}
                  />
                </ChartOptionCollapse>
              </>
            );
          }}
        </Form.Item>
      </Form>
    </ConfigProvider>
  );
}

export default compose<Props, IFilterOptionsEditProps>(WithOrg)(
  FilterOptionsEdit
);
