import * as React from "react";
import type { RouteComponentProps } from "react-router";
import { Redirect, withRouter } from "react-router";
import { compose } from "../../components/compose/WlyCompose";

interface IRedirectPageProps {}

type Props = IRedirectPageProps & RouteComponentProps<{ to: string }>;

class RedirectPage extends React.Component<Props> {
  public render() {
    const {
      location: { state },
    } = this.props;
    const s: { to: string } = state as any;
    return <Redirect to={s.to} />;
  }
}

export default compose<Props, IRedirectPageProps>(withRouter)(RedirectPage);
