import type * as React from "react";
import type { ConnectDropTarget } from "react-dnd";
import { useDrop } from "react-dnd";

interface ILayoutDNDWrapperProps {
  children: (
    drop: ConnectDropTarget,
    isDraggingReport: boolean
  ) => React.ReactChild | React.ReactChildren | React.ReactNode | any;
}

export function LayoutDNDWrapper(props: ILayoutDNDWrapperProps) {
  const [{ canDrop, isOver }, drop] = useDrop(() => ({
    // The type (or types) to accept - strings or symbols
    accept: "REPORT",
    // Props to collect
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  }));
  return props.children(drop, isOver);
}
