import { CaretRightOutlined } from "@ant-design/icons";
import { Collapse } from "antd";
import "./ChartOptionCollapse.scss";

interface IChartOptionCollapseProps {
  title?: React.ReactNode;
  children?: React.ReactNode;
  defaultExpanded?: boolean;
  dark?: boolean;
}

export function ChartOptionCollapse(props: IChartOptionCollapseProps) {
  const { dark } = props;

  return (
    <Collapse
      bordered={false}
      defaultActiveKey={props.defaultExpanded === false ? [] : ["panel"]}
      className={`chart-options-collapse ${dark ? "dark" : ""}`}
      expandIcon={({ isActive }) => (
        <CaretRightOutlined rotate={isActive ? 90 : 0} />
      )}
      expandIconPosition="end"
      items={[{ key: "panel", label: props.title, children: props.children }]}
    />
  );
}
