import { SplitView } from "../../../../../../../components/resizable/SplitView";
import type {
  SchemaResult,
  TableResult,
} from "../../../../../../../interfaces/transformations";

import { useForm } from "antd/es/form/Form";
import _ from "lodash";
import React from "react";
import type { WidthProviderProps } from "react-grid-layout";
import { WidthProvider } from "react-grid-layout";
import { Vega } from "react-vega";
import { WlyCard } from "../../../../../../../components/cards/WlyCard";
import { compose } from "../../../../../../../components/compose/WlyCompose";
import Feednack from "../../../../../../../components/layout/feedback/feedback";
import Loading from "../../../../../../../components/layout/feedback/loading";
import "./EmbeddedChart.scss";
import type { EmbeddedChartType } from "./builder/Charts/domain";
import { chartCatalog } from "./builder/Charts/domain";
import { embeddedChartDefinition } from "./builder/Charts/embeddedChartDefinition";
import { EmbeddedChartSelector } from "./builder/Selector/EmbeddedChartSelector";

interface IEmbeddedChartsProps {
  data: TableResult;
  schema: SchemaResult;
  status: "initial" | "error" | "loading" | "success";
}

// STRING | DATE | BOOLEAN  => count| distinct | valid
// NUMBER => sum | count | disctinct

type Props = WidthProviderProps & IEmbeddedChartsProps;

function EmbeddedCharts(props: Props) {
  const { data, schema, status } = props;

  const [query, setQuery] = React.useState<{
    chartType: EmbeddedChartType;
    configuration?: any;
  }>({
    chartType: "histogram",
  });

  const [form] = useForm();

  React.useEffect(() => {
    form.resetFields();
  }, [query.chartType]);

  React.useEffect(() => {
    form.resetFields();
    setQuery((q) => ({ ...q, configuration: null }));
  }, [status]);

  const definition = embeddedChartDefinition[query.chartType];
  const spec = definition.renderSpec(data, schema, query.configuration);

  const onChange = (v: any) =>
    setQuery((q) => ({ chartType: q.chartType, configuration: v }));

  if (status === "initial") {
    return <div />;
  } else if (status === "loading") {
    return (
      <Feednack>
        <Loading />
      </Feednack>
    );
  } else if (status === "error") {
    return (
      <Feednack>There was an error with your query, please check</Feednack>
    );
  } else if (Object.keys(schema).length === 0 || data.length === 0) {
    return (
      <Feednack>
        You query does not output data, please run another one.
      </Feednack>
    );
  }

  return (
    <SplitView
      compact={true}
      left={
        <div className="worksheet-tree-inner">
          <div
            style={{
              padding: 8,
              borderBottom: "1px solid #ececec",
            }}
          >
            <EmbeddedChartSelector<EmbeddedChartType>
              catalog={chartCatalog}
              value={query.chartType}
              onChange={(v) => setQuery({ chartType: v, configuration: null })}
            />
          </div>
          {definition.renderForm(schema, form, onChange)}
        </div>
      }
      right={
        <div className="worksheet-chart-inner">
          <WlyCard style={{ height: "100%", width: "100%" }}>
            {!_.isEqual(spec, {}) ? (
              <Vega spec={spec} actions={false} />
            ) : (
              <Feednack>Input a configuration to plot a chart</Feednack>
            )}
          </WlyCard>
        </div>
      }
      leftClassName={"worksheet-tree"}
      rightClassName={"worksheet-chart"}
      mainClassName="worksheet-content"
      minWidth={200}
      maxWidth={"50%"}
      startWidth={250}
      alignement={"LTR"}
    />
  );
}

export default compose<Props, IEmbeddedChartsProps>(WidthProvider)(
  EmbeddedCharts
);
