import { Typography } from "antd";
import React from "react";
import { useDrop } from "react-dnd";
import { WlyCard } from "../../../../../components/cards/WlyCard";
import type { IReportFolder } from "../../../../../interfaces/folder";
import type { IReport } from "../../../../../interfaces/reports";

import { WlyFolderIcon } from "../../../../../components/icons/custom-icons/WlyFolderIcon";
import { BORDER_COLOR } from "../../../../v2-demo/container/layout/domain";
import "./HomeCard.scss";

interface IFolderCardProps {
  onDropReport?: (r: IReport, f: IReportFolder) => Promise<any>;
  folder: IReportFolder;
  onClick: () => void;
}

export function FolderCard(props: IFolderCardProps) {
  const { folder, onClick, onDropReport } = props;
  const ref = React.useRef<HTMLDivElement>(null);
  const [{ isOver }, drop] = useDrop<IReport, void, { isOver: boolean }>({
    accept: "REPORT",
    collect(monitor) {
      return {
        isOver: monitor.isOver(),
      };
    },
    drop(item) {
      if (onDropReport) {
        onDropReport(item, folder);
      }
    },
  });

  drop(ref);

  const className = `folder-card ${isOver ? "hovering" : ""}`;

  return (
    <WlyCard
      style={{ borderColor: BORDER_COLOR }}
      onClick={onClick}
      className={className}
    >
      <div ref={ref} className="folder-card-inner">
        <div className="folder-card-inner-image">
          <WlyFolderIcon
            style={{
              fontSize: 20,
              color: folder.color ?? "#2599D4",
            }}
          />
        </div>
        <div className="folder-card-inner-text">
          <Typography.Text strong>{folder.name}</Typography.Text>
        </div>
      </div>
    </WlyCard>
  );
}
