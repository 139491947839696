import type { RootCauseAnalysis } from "./interface";

export const RCA: RootCauseAnalysis = {
  name: "Analyse de la PDL",
  object: {
    name: "Magasins",
    slug: "magasins-4jz00xv",
  },
  analyses: [
    {
      start: "01 fevrier",
      end: "01 mars",
    },
    {
      start: "01 janvier",
      end: "01 février",
    },
  ],
  dimensions: [
    { key: "sector_id", label: "Secteur", cubeName: "Tab_8220.name" },
    { key: "sub_retailer", label: "Enseigne", cubeName: "Tab_8225.prop195" },
    { key: "surface_strategy", label: "Surface", cubeName: "Tab_8225.prop224" },
    { key: "store_segment", label: "Segment", cubeName: "Tab_8225.prop192" },
  ],
  primaryMetric: {
    key: "last_visit_frais_pdl",
    label: "PDL",
    formatter: "POINT",
  },
  proportionMetrics: [
    {
      key: "number_of_magasin",
      label: "Nombre de magasins",
    },
    {
      key: "revenue",
      label: "CA",
    },
  ],
  // secondaryMetrics: [
  //   {
  //     key: "number_of_magasin",
  //     label: "PDL 2",
  //     formatter: "POINT",
  //   },
  // ],
};
