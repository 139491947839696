import Icon from "@ant-design/icons";
import type { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";

export const WlyTreemapChartIcon = (
  props: Partial<CustomIconComponentProps>
) => (
  <Icon
    component={() => (
      <svg
        width="1em"
        height="1em"
        viewBox="0 0 213 213"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_1054_1258)">
          <rect width="129" height="131" fill="#FBCE19" />
          <rect x="129" width="84" height="99" fill="#3A5C83" />
          <rect x="84" y="131" width="45" height="82" fill="#EE5858" />
          <rect y="131" width="84" height="82" fill="#41D58A" />
          <rect x="129" y="99" width="84" height="114" fill="#B0BECD" />
        </g>
        <defs>
          <clipPath id="clip0_1054_1258">
            <rect width="213" height="213" fill="white" />
          </clipPath>
        </defs>
      </svg>
    )}
    {...props}
  />
);
