import * as React from "react";
import "./Card.scss";

interface ICardProps {
  height?: number | string;
  className?: string;
  ref?: React.LegacyRef<HTMLDivElement>;
  noBackground?: boolean;
  cardTitle?: React.ReactNode;
  actionButtons?: React.ReactNode;
  children?: React.ReactNode;
}

export const CardContext = React.createContext({ color: null });

export default function Card(props: React.PropsWithChildren<ICardProps>) {
  const {
    height,
    children,
    className,
    noBackground,
    ref,
    cardTitle,
    actionButtons,
  } = props;

  const context = React.useContext(CardContext);

  return (
    <div
      ref={ref}
      className={
        className
          ? `whaly-card ${noBackground ? "no-bg" : ""} ${className}`
          : `${noBackground ? "no-bg" : ""} whaly-card`
      }
      style={{
        height,
        borderColor:
          context?.color && !noBackground ? context.color : undefined,
      }}
    >
      {cardTitle || actionButtons ? (
        <div className="whaly-card-top">
          {cardTitle ? <div className="title">{cardTitle}</div> : null}
          {actionButtons ? (
            <div className="buttons">{actionButtons}</div>
          ) : null}
        </div>
      ) : null}
      {children}
    </div>
  );
}
