import Mustache from "mustache";
import type { IDataset } from "../../../interfaces/sources";
import type {
  IMetricExpression,
  IMetricType,
  IRelationshipType,
  ITable,
} from "../../../interfaces/table";
import GraphQLService from "../../../services/graphql/GraphQLService";

export interface BasicExplorationTemplate {
  name: string;
  apiName?: string;
  description: string;
  table: BasicTableTemplate;
}

interface BasicDimensionsTemplate {
  apiName?: string;
  columnName: string;
  description: string;
  overrideName?: string;
  columnDomain?: string;
}

interface BasicMetricTemplate {
  apiName?: string;
  columnName: string | null;
  overrideName?: string;
  description?: string;
  prefix?: string;
  suffix?: string;
  format?: IMetricType;
  expression: IMetricExpression;
  filters: string;
}

interface BasicRelationshipTemplate {
  apiName?: string;
  from: string;
  to: string;
  type: IRelationshipType;
  table: BasicTableTemplate;
}

interface EnhancedRelationshipTemplate {
  apiName?: string;
  from: string;
  to: string;
  left: string;
  right: string;
  type: string;
}

export interface BasicTableTemplate {
  apiName?: string;
  table_name: string;
  dimensions?: BasicDimensionsTemplate[];
  metrics?: BasicMetricTemplate[];
  relationships?: BasicRelationshipTemplate[];
}

interface TableNameViewIdResolver {
  [tableName: string]: {
    viewId: string;
    primaryKey: string;
    datasetName: string;
  };
}

interface TableNameTableIdResolver {
  [tableName: string]: {
    tableId: string;
    metrics: {
      [metricKey: string]: string;
    };
  };
}

const generateTemplateBasedOnExecutor = (
  executor: string
): BasicExplorationTemplate | BasicExplorationTemplate[] => {
  try {
    const ex = JSON.parse(executor);
    return ex;
  } catch (err) {
    console.error(err);
  }

  throw new Error("This executor doesn't exist");
};

interface EnhancedTemplateMetrics extends BasicMetricTemplate {
  references: string[];
  isReferencedBy: string[];
  tableId: string;
}

const generateComputedValueDependencyMap = (
  tables: Omit<BasicTableTemplate, "relationships">[]
): Array<EnhancedTemplateMetrics> => {
  const allComputedMetrics = tables.flatMap((t) =>
    (t.metrics || [])
      .filter((m) => m.expression === "COMPUTED")
      .map((m) => ({
        ...m,
        tableId: t.table_name,
      }))
  );
  // extract for each metric the reference
  const allComputedMetricsWithReference = allComputedMetrics.map((v) => {
    const match = v.columnName.match(/{{([\:\/\-\\\,\.a-zA-Z0-9\s]*)}}/gm);
    return {
      ...v,
      references: (match || [])
        .map((m) => {
          const localMatch = /{{([\:\/\-\\\,\.a-zA-Z0-9\s]*)}}/gm.exec(m);
          if (localMatch) return localMatch[1].trim();
          return null;
        })
        .filter((m) => !!m),
    };
  });
  const allComputedMetricsWithTwoWayReference =
    allComputedMetricsWithReference.map((v, i, s) => {
      return {
        ...v,
        isReferencedBy: s
          .filter((m) => m.references.includes(v.overrideName))
          .map((m) => m.overrideName)
          .filter((m) => !!m),
      };
    });

  return allComputedMetricsWithTwoWayReference;
};

const generateExplorationQueryBasedOnTemplate = (
  orgId: string,
  warehouseId: string,
  template: BasicExplorationTemplate,
  tables: Array<Omit<BasicTableTemplate, "relationships">>,
  resolver: TableNameViewIdResolver
) => {
  return {
    name: template.name,
    description: template.description,
    tables: {
      create: tables
        .map((t) => {
          if (!resolver[t.apiName ? t.apiName : t.table_name]) {
            console.error(
              "could not find",
              t.apiName ? t.apiName : t.table_name,
              "in",
              resolver
            );
            return null;
          }
          return {
            name: resolver[t.apiName ? t.apiName : t.table_name].datasetName,
            view: {
              connect: {
                id: resolver[t.apiName ? t.apiName : t.table_name].viewId,
              },
            },
            primaryKey:
              resolver[t.apiName ? t.apiName : t.table_name].primaryKey,
            metrics: {
              create: (t.metrics || [])
                .filter((m) => m.expression !== "COMPUTED")
                .map((m) => {
                  return {
                    expression: m.expression,
                    columnName: m.columnName,
                    filters: m.filters,
                    overrideName: m.overrideName,
                    format: m.format,
                    prefix: m.prefix,
                    suffix: m.suffix,
                    description: m.description,
                    org: {
                      connect: {
                        id: orgId,
                      },
                    },
                  };
                }),
            },
            dimensions: {
              create: (t.dimensions || []).map((d) => {
                return {
                  overrideName: d.overrideName,
                  columnName: d.columnName,
                  columnDomain: d.columnDomain,
                  description: d.description,
                  org: {
                    connect: {
                      id: orgId,
                    },
                  },
                };
              }),
            },
            org: {
              connect: {
                id: orgId,
              },
            },
          };
        })
        .filter((m) => !!m),
    },
    org: {
      connect: {
        id: orgId,
      },
    },
    warehouse: {
      connect: {
        id: warehouseId,
      },
    },
  };
};

const generateRelationshipQueryBasedOnTemplate = (
  orgId: string,
  relationships: Array<EnhancedRelationshipTemplate>,
  resolver: TableNameTableIdResolver
) => {
  return relationships
    .map((r) => {
      if (!resolver[r.right]) {
        console.error(
          "Could not resolve right table ",
          r.right,
          "in",
          resolver
        );
        return null;
      }
      if (!resolver[r.left]) {
        console.error("Could not resolve left table ", r.left, "in", resolver);
        return null;
      }
      return {
        data: {
          type: r.type,
          left: {
            connect: {
              id: resolver[r.left].tableId,
            },
          },
          right: {
            connect: {
              id: resolver[r.right].tableId,
            },
          },
          from: r.from,
          to: r.to,
          org: {
            connect: {
              id: orgId,
            },
          },
        },
      };
    })
    .filter((r) => !!r);
};

const extractTables = (
  table: BasicTableTemplate
): Array<Omit<BasicTableTemplate, "relationships">> => {
  const tables: Array<Omit<BasicTableTemplate, "relationships">> = [];
  const extractor = (table: BasicTableTemplate) => {
    tables.push(table);
    if (table.relationships) {
      table.relationships.forEach((r) => {
        extractor(r.table);
      });
    }
  };
  extractor(table);
  return tables;
};

const extractRelationships = (
  table: BasicTableTemplate
): Array<EnhancedRelationshipTemplate> => {
  const relationships: Array<EnhancedRelationshipTemplate> = [];
  const extractor = (
    parentTableName: string,
    rel: BasicRelationshipTemplate
  ) => {
    relationships.push({
      apiName: rel.apiName,
      from: rel.from,
      to: rel.to,
      right: rel.table.table_name,
      left: parentTableName,
      type: rel.type,
    });
    if (rel.table.relationships) {
      rel.table.relationships.forEach((r) => {
        extractor(rel.table.table_name, r);
      });
    }
  };
  if (table.relationships) {
    table.relationships.forEach((r) => {
      extractor(table.table_name, r);
    });
  }

  return relationships;
};

export const getNumberOfExplorations = (executor: string): string[] => {
  const templates = generateTemplateBasedOnExecutor(executor);
  if (Array.isArray(templates)) {
    return templates.map((t) => t.name);
  } else {
    return [templates.name];
  }
};

export const createExploration = async (
  orgId: string,
  warehouseId: string,
  sourceIds: string[],
  executor: string
): Promise<Array<{ id: string; slug: string }>> => {
  const templates = generateTemplateBasedOnExecutor(executor);

  const create = async (template: BasicExplorationTemplate) => {
    const tables = extractTables(template.table);

    const datasetsWithView = await GraphQLService(
      `
  query getCurrentDatasetWithDefaultView($warehouseTablesIds: [String]!, $orgId: ID!, $sourceIds: [ID]!) {
    allDatasets(where: { org: { id: $orgId }, warehouseTableId_in: $warehouseTablesIds, source: {id_in: $sourceIds } }) {
      name
      primaryKey
      warehouseTableId
      views(where: {default: true}) {
          id
        }
    }
  }
    `,
      {
        warehouseTablesIds: tables.map((t) => t.table_name),
        orgId: orgId,
        sourceIds: sourceIds,
      }
    );

    const tableNameToViewId: TableNameViewIdResolver = (
      datasetsWithView.allDatasets as IDataset[]
    ).reduce<TableNameViewIdResolver>((acc, v) => {
      return {
        ...acc,
        [v.warehouseTableId]: {
          viewId: v.views[0].id,
          datasetName: v.name,
          primaryKey: v.primaryKey,
        },
      };
    }, {});

    const query = generateExplorationQueryBasedOnTemplate(
      orgId,
      warehouseId,
      template,
      tables,
      tableNameToViewId
    );

    const createExploration = await GraphQLService(
      `
  mutation createExploration($data: ExplorationCreateInput!) {
    createExploration(data: $data) {
      id
      slug
      tables {
        id
        cubeName
        view {
          dataset {
            warehouseTableId
          }
        }
        metrics {
          id
          name
          cubeName
        }
      }
    }
  }
  `,
      {
        data: query,
      }
    );

    const enhancedResolver = (
      createExploration.createExploration.tables as ITable[]
    ).reduce<TableNameTableIdResolver>((acc, v) => {
      return {
        ...acc,
        [v.view.dataset.warehouseTableId]: {
          tableId: v.id,
          metrics: v.metrics.reduce((a, m) => {
            return {
              ...a,
              [m.name]: `${v.cubeName}.${m.cubeName}`,
            };
          }, {}),
        },
      };
    }, {});

    // Currently we don't support if two metrics share the same name

    const reversedEnhancedResolver: {
      [key: string]: { metricCubeName: string; tableId: string };
    } = Object.keys(enhancedResolver)
      .flatMap((k) =>
        Object.keys(enhancedResolver[k].metrics).map((m) => ({
          metricName: m,
          metricCubeName: enhancedResolver[k].metrics[m],
          tableId: enhancedResolver[k].tableId,
        }))
      )
      .reduce<{ [key: string]: { metricCubeName: string; tableId: string } }>(
        (acc, v) => {
          return {
            ...acc,
            [v.metricName]: {
              metricCubeName: v.metricCubeName,
              tableId: v.tableId,
            },
          };
        },
        {}
      );

    // we generate a list of all computed metrics with their
    const computedValuesDependencyList =
      generateComputedValueDependencyMap(tables);
    // we replace the warehouse table name by the table ID
    const computedValuesDependencyMap = computedValuesDependencyList.map(
      (m) => ({
        ...m,
        tableId: enhancedResolver[m.tableId].tableId,
      })
    );

    const saveComputedMetrics = async (
      metricsToSave: EnhancedTemplateMetrics[]
    ) => {
      const createComputedMetricColumn = await GraphQLService<{
        createMetrics: {
          overrideName: string;
          cubeName: string;
          table: { id: string };
        }[];
      }>(
        `
      mutation createComputedMetric($data: [MetricsCreateInput]!) {
        createMetrics(data: $data) {
          name,
          cubeName,
          table {
            id
          }
        }
      }
      `,
        {
          data: metricsToSave.map((m) => {
            return {
              data: {
                overrideName: m.overrideName,
                columnName: Mustache.render(
                  m.columnName!,
                  Object.keys(reversedEnhancedResolver).reduce<{
                    [key: string]: string;
                  }>((acc, v) => {
                    return {
                      ...acc,
                      [v]: reversedEnhancedResolver[v].metricCubeName,
                    };
                  }, {})
                ),
                expression: m.expression,
                format: m.format,
                filters: m.filters,
                prefix: m.prefix,
                suffix: m.suffix,
                description: m.description,
                table: {
                  connect: {
                    id: m.tableId,
                  },
                },
                org: {
                  connect: {
                    id: orgId,
                  },
                },
              },
            };
          }),
        }
      );
      return createComputedMetricColumn;
    };

    const loopAndSaveComputedMetrics = async (
      newComputedValues?: EnhancedTemplateMetrics[],
      initial?: boolean
    ) => {
      const unsavedComputedMetrics = !newComputedValues
        ? computedValuesDependencyMap
        : newComputedValues;
      if (!unsavedComputedMetrics.length) {
        return;
      }
      if (initial) {
        // we are running the function for the first time we will save computed metrics that are not referenced by another one
        const canSave = unsavedComputedMetrics.filter((us) => {
          // we keep only the computed metrics for which all de
          return us.isReferencedBy.length === 0;
        });
        const saved = await saveComputedMetrics(canSave);
        // we update the resolver
        saved.createMetrics.forEach((s) => {
          reversedEnhancedResolver[s.overrideName] = {
            metricCubeName: s.cubeName,
            tableId: s.table.id,
          };
        });
        // we go for another round if needed
        // return loopAndSaveComputedMetrics()
      } else {
        // we are dealing with computed metrics that reference each other
        throw new Error("not implemented");
      }
    };

    await loopAndSaveComputedMetrics(undefined, true);

    const relationships = extractRelationships(template.table);
    if (relationships.length) {
      const relationshipQuery = generateRelationshipQueryBasedOnTemplate(
        orgId,
        relationships,
        enhancedResolver
      );
      const createdRelationships = await GraphQLService(
        `
      mutation createRelationships($data: [TableRelationshipsCreateInput]!) {
        createTableRelationships(data: $data) {
          id
        }
      }
    `,
        {
          data: relationshipQuery,
        }
      );
    }

    return createExploration.createExploration;
  };

  if (Array.isArray(templates)) {
    return Promise.all(
      templates.map((t) => {
        return create(t);
      })
    );
  } else {
    return create(templates).then((t) => [t]);
  }
};
