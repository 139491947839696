import { ExpandOutlined } from "@ant-design/icons";
import * as React from "react";
import type { ISourceMetaDataPublicInfoLabel } from "../../../interfaces/sources";
import { LabelRenderer } from "../label/LabelRenderer";
import "./CatalogItem.scss";

interface ICatalogItemProps {
  label: ISourceMetaDataPublicInfoLabel;
  logo?: string;
  name: string;
  connected: boolean;
}

export default class CatalogItem extends React.Component<ICatalogItemProps> {
  public render() {
    const { label, logo, name } = this.props;
    return (
      <div className={"source"}>
        <div className="image">
          <LabelRenderer label={label} />
          {logo ? <img src={logo} alt={name} /> : <ExpandOutlined />}
        </div>
        <div className="text">{name}</div>
      </div>
    );
  }
}
