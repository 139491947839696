import _ from "lodash";
import * as React from "react";
import Feednack from "../../../../../components/layout/feedback/feedback";
import Loading from "../../../../../components/layout/feedback/loading";
import CustomLayoutEditorInner from "../../../../custom-layout-editor/main/CustomLayoutEditorInner";
import type { ILayout } from "../../../../v2-demo/container/record/component/domain";
import { parseObjectLayout } from "../../../../v2-demo/container/record/component/domain";
import type {
  IActiveObject,
  IActiveObjectLayoutUrlState,
  ObjectData,
  ObjectLayoutData,
} from "../../domain";
import { isFakeId } from "../../exploration/domain";
import type { UpdateObjectLayoutFunction } from "../../selector/object-layouts/domain";
import RenameObjectLayoutModal from "./modals/RenameObjectLayoutModal";
import { type IDataset } from "../../../../../interfaces/sources";

interface IObjectLayoutViewerInnerProps {
  activeObject: IActiveObject;
  objectData: ObjectData;
  layoutData: ObjectLayoutData;
  fetchObject: (objectId: string) => Promise<void>;
  fetchObjectLayout: (objectId: string, layoutId: string) => Promise<void>;
  updateActiveObject: (newActiveObject: IActiveObject) => void;
  onUpdateObjectLayout: UpdateObjectLayoutFunction;
  allDatasets: IDataset[];
}

export function ObjectLayoutViewerInner(props: IObjectLayoutViewerInnerProps) {
  const {
    objectData,
    layoutData,
    fetchObject,
    fetchObjectLayout,
    activeObject,
    updateActiveObject,
    onUpdateObjectLayout,
    allDatasets,
  } = props;

  const localOnly = isFakeId(layoutData.id);

  const [visible, setVisible] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (
      objectData.object.status === "initial" ||
      objectData.object.status === "error"
    ) {
      fetchObject(objectData.id);
    }
  }, [objectData.id]);

  React.useEffect(() => {
    if (
      !localOnly &&
      (layoutData.data.status === "initial" ||
        layoutData.data.status === "error")
    ) {
      fetchObjectLayout(objectData.id, layoutData.id);
    }
  }, [layoutData.id]);

  if (
    (!localOnly &&
      (layoutData.data.status === "initial" ||
        layoutData.data.status === "loading")) ||
    objectData.object.status === "initial" ||
    objectData.object.status === "loading"
  ) {
    return (
      <Feednack>
        <Loading />
      </Feednack>
    );
  }

  if (layoutData.data.status === "error") {
    return (
      <Feednack>
        <div>
          There was an error fetching your layout:{" "}
          {layoutData.data.error?.message}
        </div>
      </Feednack>
    );
  }

  if (objectData.object.status === "error") {
    return (
      <Feednack>
        <div>
          There was an error fetching your object:{" "}
          {objectData.object.error?.message}
        </div>
      </Feednack>
    );
  }

  const getLayoutData = (): ILayout => {
    if (activeObject.stale) {
      if (activeObject.staleState) {
        return activeObject.staleState as ILayout;
      }
    } else {
      if (localOnly) {
        return parseObjectLayout();
      }
      if (layoutData.data.status === "success") {
        return parseObjectLayout(layoutData.data.data);
      }
    }
    return parseObjectLayout();
  };

  const layout = getLayoutData();

  return (
    <>
      <CustomLayoutEditorInner
        layout={layout}
        object={objectData.object.data}
        onSave={(orgId, objectId, layout) => {
          updateActiveObject({
            ...activeObject,
            stale: false,
          });
          return fetchObjectLayout(objectData.id, layoutData.id);
        }}
        onChange={(changedLayout, recordId) => {
          if (!_.isEqual(changedLayout, layout)) {
            updateActiveObject({
              ...activeObject,
              stale: true,
              staleState: changedLayout,
              urlState: { recordId: recordId },
            });
          }
        }}
        boxed={false}
        recordId={
          (activeObject.urlState as IActiveObjectLayoutUrlState)?.recordId
        }
        onRename={() => setVisible(true)}
        allDatasets={allDatasets}
      />
      <RenameObjectLayoutModal
        onClose={() => setVisible(false)}
        open={visible}
        onRename={async (v) => {
          await onUpdateObjectLayout(layoutData.id, v);
          await fetchObjectLayout(objectData.id, layoutData.id);
        }}
        initialValue={{
          name: layout.name ? layout.name : "",
        }}
      />
    </>
  );
}
