import { Form, Input } from "antd";
import * as React from "react";
import GraphQLService from "../../services/graphql/GraphQLService";
import type { InjectedAntUtilsProps } from "../ant-utils/withAntUtils";
import { withAntUtils } from "../ant-utils/withAntUtils";
import { compose } from "../compose/WlyCompose";
import WlyModal from "../modal/WlyModal";

interface IReportDuplicateProps {
  visible: boolean;
  initialData?: ReportInitialData;
  onSave: (slug: string) => any;
  onCancel: () => any;
}

interface ReportInitialData {
  id: string;
  name: string;
}

type Props = IReportDuplicateProps & InjectedAntUtilsProps;

function ReportDuplicate(props: Props) {
  const { visible, onCancel, initialData, onSave, antUtils } = props;
  const [confirmLoading, setConfirmLoading] = React.useState(false);
  const [form] = Form.useForm();

  React.useEffect(() => {
    if (visible === false) {
      form.resetFields();
      form.setFieldsValue({ name: "" });
    }
  }, [visible, form]);

  React.useEffect(() => {
    form.setFieldsValue(initialData);
  }, [form, initialData?.id]);

  const renderInnerModal = () => {
    return (
      <Form
        initialValues={
          initialData
            ? {
                name: initialData.name,
              }
            : {
                name: "",
              }
        }
        layout="vertical"
        form={form}
      >
        <Form.Item label="Report name">
          <Input.Group compact>
            <Form.Item
              name={["name"]}
              noStyle
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input style={{ width: "100%" }} placeholder="Report name" />
            </Form.Item>
          </Input.Group>
        </Form.Item>
      </Form>
    );
  };

  return (
    <WlyModal
      open={!!visible}
      title={"Duplicate Report"}
      okText={"Duplicate"}
      cancelText="Cancel"
      onClose={() => {
        form.resetFields();
        onCancel();
      }}
      confirmLoading={confirmLoading}
      onOk={async () => {
        setConfirmLoading(true);
        try {
          const values: ReportInitialData = await form.validateFields();
          if (initialData && initialData.id) {
            const resp = await GraphQLService(
              `mutation duplicateReport($reportId: ID!, $newReportName: String) {
              duplicateReport(reportId: $reportId, newReportName: $newReportName)
            }`,
              {
                reportId: initialData.id,
                newReportName: values.name,
              }
            );
            setConfirmLoading(false);
            antUtils.message.success("Report duplicated");
            return onSave(resp.duplicateReport);
          }
          onCancel();
          return form.resetFields();
        } catch (error) {
          setConfirmLoading(false);
          antUtils.message.error("Error while duplicating Report");
          console.warn("Validate Failed:", error);
        }
      }}
    >
      {renderInnerModal()}
    </WlyModal>
  );
}

export default compose<Props, IReportDuplicateProps>(withAntUtils)(
  ReportDuplicate
);
