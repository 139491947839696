import Icon from "@ant-design/icons";
import type { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";

export const WlyPropertiesIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon
    component={() => (
      <svg
        width="1em"
        height="1em"
        viewBox="0 0 213 213"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="8"
          y="57"
          width="121"
          height="23"
          rx="4"
          stroke="#5D6671"
          strokeWidth="8"
          strokeLinejoin="round"
        />
        <rect
          x="8"
          y="104"
          width="198"
          height="33"
          rx="4"
          stroke="#5D6671"
          strokeWidth="8"
          strokeLinejoin="round"
        />
      </svg>
    )}
    {...props}
  />
);
