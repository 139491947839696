import { TeamOutlined } from "@ant-design/icons";
import { Select, Tag, Tooltip, Typography } from "antd";
import * as React from "react";
import type { InjectedOrgProps } from "../../../containers/orgs/WithOrg";
import WithOrg from "../../../containers/orgs/WithOrg";
import type { IAccessType } from "../../../interfaces/reportSharing";
import type { IUserGravatarInfo } from "../../../interfaces/user";
import { compose } from "../../compose/WlyCompose";
import { GroupRenderer } from "../../group/GroupRenderer";
import { UserRenderer } from "../../user/UserRenderer";
import UserAvatar from "../../user/avatar/UserAvatar";
import type {
  AvailableUsersAndGroups,
  ISearchableItem,
  ISearchableItemType,
} from "../domain";
import { AccessTypePickerRenderer } from "../renderer/AccessTypePickerRenderer";
import "./NewSharingForm.scss";

const { Option, OptGroup } = Select;

interface INewSharingFormModalProps {
  availableUsersAndGroups: AvailableUsersAndGroups;
  selectedItems: ISearchableItem[];
  onSelectedItemsChange: (selectedItems: ISearchableItem[]) => void;
  accessType: IAccessType;
  onAccessTypeChange: (accessType: IAccessType) => void;
  availableAccessTypes: Array<IAccessType>;
}

type Props = InjectedOrgProps & INewSharingFormModalProps;

interface UserInfoWithOrigin extends IUserGravatarInfo {
  type: "org" | "realm";
}

function NewSharingForm(props: Props) {
  const {
    availableUsersAndGroups,
    selectedItems,
    onSelectedItemsChange,
    accessType,
    onAccessTypeChange,
    availableAccessTypes,
  } = props;

  const [searchableItems, setSearchableItems] = React.useState<
    ISearchableItem[]
  >([]);
  const [allUsers, setAllUsers] = React.useState<UserInfoWithOrigin[]>([]);

  React.useEffect(() => {
    const orgUsers: UserInfoWithOrigin[] = availableUsersAndGroups.orgUsers.map(
      (userInfo) => {
        return {
          type: "org",
          ...userInfo,
        };
      }
    );

    const realmUsers: UserInfoWithOrigin[] =
      availableUsersAndGroups.realmUsers.map((userInfo) => {
        return {
          type: "realm",
          ...userInfo,
        };
      });

    const allUsers = orgUsers.concat(realmUsers);
    setAllUsers(allUsers);

    const searchableOrgUsers: ISearchableItem[] = orgUsers.map((userInfo) => {
      return {
        type: "user-org",
        id: userInfo.id,
        name: `${userInfo.firstName} ${userInfo.lastName}`,
      };
    });
    const searchableRealmUsers: ISearchableItem[] = realmUsers.map(
      (userInfo) => {
        return {
          type: "user-realm",
          id: userInfo.id,
          name: `${userInfo.firstName} ${userInfo.lastName}`,
        };
      }
    );
    const searchableGroups: ISearchableItem[] =
      availableUsersAndGroups.groups.map((groupInfo) => {
        return {
          type: "group",
          id: groupInfo.id,
          name: groupInfo.name,
        };
      });

    const searchableItems = searchableOrgUsers
      .concat(searchableRealmUsers)
      .concat(searchableGroups);
    setSearchableItems(searchableItems);
  }, [
    availableUsersAndGroups.orgUsers,
    availableUsersAndGroups.realmUsers,
    availableUsersAndGroups.groups,
  ]);

  const renderOptions = () => {
    const remainingItems = searchableItems.filter((item) => {
      const selectedItemIds = selectedItems.map(
        (item) => `${item.type}:${item.id}`
      );

      return !selectedItemIds.includes(`${item.type}:${item.id}`);
    });
    const matchedUsers = remainingItems.filter((item) =>
      item.type.startsWith("user")
    );
    const matchedGroups = remainingItems.filter(
      (item) => item.type === "group"
    );

    return (
      <>
        {matchedGroups.length > 0 ? (
          <>
            <OptGroup label="Select a group"> </OptGroup>
            {matchedGroups.map((d) => {
              const key = `${d.type}:${d.id}`;
              const groupInfo = availableUsersAndGroups.groups.find(
                (group) => group.id === d.id
              );
              return (
                <Option value={key} key={key}>
                  <div
                    key={key}
                    className="share-new-picker-item"
                    onClick={() => {
                      onSelectedItemsChange(selectedItems.concat([d]));
                    }}
                  >
                    <div className="share-new-picker-item-content">
                      {groupInfo ? (
                        <GroupRenderer group={groupInfo} />
                      ) : (
                        <Typography.Text type="danger">
                          No group found
                        </Typography.Text>
                      )}
                    </div>
                  </div>
                </Option>
              );
            })}
          </>
        ) : undefined}
        {matchedUsers.length > 0 ? (
          <>
            <OptGroup label="Select a person"> </OptGroup>

            {matchedUsers.map((d) => {
              const key = `${d.type}:${d.id}`;
              const userInfo = allUsers.find((user) => user.id === d.id);
              return (
                <Option value={key} key={key}>
                  <div
                    key={key}
                    className="share-new-picker-item"
                    onClick={() => {
                      onSelectedItemsChange(selectedItems.concat([d]));
                    }}
                  >
                    <div className="share-new-picker-item-content">
                      {userInfo ? (
                        <UserRenderer user={userInfo} />
                      ) : (
                        <Typography.Text type="danger">
                          No user found
                        </Typography.Text>
                      )}
                    </div>
                  </div>
                </Option>
              );
            })}
          </>
        ) : undefined}
      </>
    );
  };

  const tagRender = (props: any) => {
    const { value, closable, onClose } = props;
    const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
      event.preventDefault();
      event.stopPropagation();
    };
    const [type, id]: [ISearchableItemType, string] = value.split(":");
    const item = searchableItems.find(
      (item) => item.type === type && item.id === id
    );

    if (type.startsWith("user")) {
      const user = allUsers.find((user) => user.id === id);
      const isUserRealm = type === "user-realm";
      return (
        <Tooltip
          title={
            isUserRealm
              ? `${item?.name} will be invited to the organization as a Viewer`
              : item?.name
          }
        >
          <Tag
            onMouseDown={onPreventMouseDown}
            closable={closable}
            color={isUserRealm ? "orange" : "default"}
            onClose={() => {
              onSelectedItemsChange(
                selectedItems.filter((selItem) => {
                  if (
                    item &&
                    selItem.id === item.id &&
                    selItem.type === item.type
                  ) {
                    return false;
                  } else {
                    return true;
                  }
                })
              );
              onClose();
            }}
            className="share-new-picker-search-select-tag"
            icon={
              <UserAvatar
                className="share-new-picker-search-select-tag-icon"
                user={user}
              />
            }
          >
            {item && item.name.length > 25
              ? `${item?.name.slice(0, 25)}...`
              : item?.name}
          </Tag>
        </Tooltip>
      );
    } else {
      return (
        <Tag
          onMouseDown={onPreventMouseDown}
          closable={closable}
          onClose={() => {
            onSelectedItemsChange(
              selectedItems.filter((selItem) => {
                if (
                  item &&
                  selItem.id === item.id &&
                  selItem.type === item.type
                ) {
                  return false;
                } else {
                  return true;
                }
              })
            );
            onClose();
          }}
          className="share-new-picker-search-select-tag"
          icon={
            <TeamOutlined className="share-new-picker-search-select-tag-icon" />
          }
        >
          {item && item.name.length > 25
            ? `${item.name.slice(0, 25)}...`
            : item?.name}
        </Tag>
      );
    }
  };

  return (
    <div className="share-new-picker">
      <div className="share-new-picker-search-bar">
        <Select
          mode="multiple"
          className="share-new-picker-search-select"
          bordered={false}
          filterOption={(inputValue, optionOrGroup) => {
            const isOptionGroup =
              optionOrGroup && Array.isArray(optionOrGroup.options);
            if (isOptionGroup) {
              return true;
            }

            const optionValue = optionOrGroup!.value;

            const [type, id] = optionValue!.toString().split(":");
            const item = searchableItems.find(
              (item) => item.id === id && item.type === type
            );

            if (
              item &&
              item.name.toLowerCase().includes(inputValue.toLowerCase())
            ) {
              return true;
            } else {
              return false;
            }
          }}
          tagRender={tagRender}
          style={{ width: "100%" }}
          defaultOpen={true}
          autoFocus={true}
          placeholder="Add people, groups or emails..."
        >
          {renderOptions()}
        </Select>
        <AccessTypePickerRenderer
          className="share-new-picker-search-access-type-picker"
          currentAccessType={accessType}
          availableAccessTypes={availableAccessTypes}
          onAccessTypeChange={(newAccessType: IAccessType) => {
            onAccessTypeChange(newAccessType);
          }}
        />
      </div>
    </div>
  );
}
export default compose<Props, INewSharingFormModalProps>(WithOrg)(
  NewSharingForm
);
