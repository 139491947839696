import { Form } from "antd";
import type { PickerProps } from "antd/lib/date-picker/generatePicker";
import type { FormItemProps } from "antd/lib/form/FormItem";
import type { FieldProps } from "formik";
import type { Moment } from "moment";
import moment from "moment";
import * as React from "react";
import DatePicker from "../../../datepicker/DatePicker";

interface IDatePickerProps extends FieldProps {
  inputProps: PickerProps<Moment>;
  formItemProps: FormItemProps;
}

export default class Input extends React.Component<IDatePickerProps> {
  public render() {
    const { inputProps, formItemProps, field, form } = this.props;

    const isSubmitting = form.isSubmitting;
    const touched = form.touched[field.name];
    const submitted = form.submitCount > 0;
    const hasError = form.errors[field.name];
    const submittedError = hasError && submitted;
    const touchedError = hasError && touched;
    const onInputChange = (date: Moment | null) =>
      date && form.setFieldValue(field.name, date.format("YYYY/MM/DD"));
    const value = field.value ? moment(field.value) : undefined;
    return (
      <Form.Item
        label={formItemProps.label}
        hasFeedback={
          (formItemProps.hasFeedback && submitted) ||
          (formItemProps.hasFeedback && touched)
            ? true
            : undefined
        }
        help={submittedError || touchedError ? <>{hasError}</> : undefined}
        validateStatus={
          submittedError || touchedError
            ? "error"
            : isSubmitting
            ? "validating"
            : "success"
        }
        {...formItemProps}
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
      >
        <DatePicker
          {...field}
          {...inputProps}
          value={value}
          disabled={isSubmitting || inputProps.disabled}
          onChange={onInputChange}
        />
      </Form.Item>
    );
  }
}
