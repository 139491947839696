import _ from "lodash";
import { inject, observer } from "mobx-react";
import React, { useMemo } from "react";
import { compose } from "../../../../../components/compose/WlyCompose";
import Loading from "../../../../../components/layout/feedback/loading";
import type { IDestination } from "../../../../../interfaces/destinations";
import type { IDataset } from "../../../../../interfaces/sources";
import type { IWorksheet } from "../../../../../interfaces/worksheet";
import GraphQLService from "../../../../../services/graphql/GraphQLService";
import type { WorkbenchUIStoreProps } from "../../../../../store/workbenchUIStore";
import SQLConfiguration from "../../dataset/tabs/sql/SQLConfiguration";
import type {
  IActiveObject,
  IActiveObjectSQLConfigUIState,
  WorksheetStore,
} from "../../domain";
import { isFakeId } from "../../exploration/domain";
import type { DatasetSavedData } from "../../selector/tables/models/AddModelModal";
import NoAccess from "../empty/NoAccess";
import "./WorksheetViewer.scss";

interface IWorksheetViewerProps {
  datasets: IDataset[];
  activeObject: IActiveObject;
  worksheetStore: WorksheetStore;
  updateWorksheetData: UpdateWorksheetData;
  onCreateDataset?: (datasetData: DatasetSavedData) => Promise<any>;
  currentWarehouse: IDestination;
}

export type UpdateWorksheetData = (
  id: string,
  data: Partial<IWorksheet>
) => void;

const GQQUERY = `
mutation updateWorksheet($id: ID!, $data: WorksheetUpdateInput!) {
  updateWorksheet(id: $id, data: $data) {
    id
  }
}
`;

type Props = IWorksheetViewerProps & WorkbenchUIStoreProps;

function WorksheetViewer(props: Props) {
  const {
    datasets,
    activeObject,
    worksheetStore,
    workbenchUIStore,
    updateWorksheetData,
    onCreateDataset,
    currentWarehouse,
  } = props;

  const debouncedSaveQuery = useMemo(
    () =>
      _.debounce((id: string, query: string) => {
        return GraphQLService(GQQUERY, {
          id: id,
          data: {
            query: query,
          },
        });
      }, 500),
    []
  );

  const findWorksheet = () => {
    const ao = workbenchUIStore.getActiveObject();
    return worksheetStore[ao.value]?.worksheet;
  };

  const getInitialStaleQuery = () => {
    const w = findWorksheet();
    return w?.query;
  };

  const [staleQuery, setStaleQuery] = React.useState<string>(
    getInitialStaleQuery()
  );

  React.useEffect(() => {
    setStaleQuery(getInitialStaleQuery());
  }, [activeObject.value]);

  const currentWorksheet = findWorksheet();

  const renderInner = () => {
    if (!activeObject || !currentWorksheet) {
      return <NoAccess />;
    }

    if (isFakeId(activeObject.value)) {
      return <Loading />;
    }

    const key = `worksheet-${activeObject.value.toString()}`;

    return (
      <SQLConfiguration
        key={key} // this makes sure that the component unmounts when switching between two open worksheets
        uniqueId={key}
        currentWarehouse={currentWarehouse}
        query={currentWorksheet?.query}
        datasets={datasets}
        staleQuery={staleQuery}
        initialState={activeObject.uiState as IActiveObjectSQLConfigUIState}
        onCreateDataset={onCreateDataset}
        onDelete={async () => {
          if (currentWorksheet) {
            await GraphQLService(GQQUERY, {
              id: currentWorksheet.id,
              data: {
                deleted: true,
              },
            });
            updateWorksheetData(currentWorksheet.id, {
              deleted: true,
            });
            workbenchUIStore.removeActiveObject({
              type: "worksheet",
              value: currentWorksheet.id,
            });
            await new Promise((resolve, reject) =>
              setTimeout(() => {
                return resolve({});
              }, 1000)
            );
          }
        }}
        saveUIState={(uiState) => {
          const currentActiveObject = workbenchUIStore.getActiveObject();
          if (!currentActiveObject) return;
          const fullstate: IActiveObjectSQLConfigUIState =
            currentActiveObject.uiState
              ? {
                  ...currentActiveObject.uiState,
                  ...uiState,
                }
              : {
                  ...uiState,
                };
          workbenchUIStore.setActiveObjectUIState(
            currentActiveObject,
            fullstate
          );
        }}
        onUpdateDataset={() => Promise.resolve()}
        setStaleQuery={(query) => {
          setStaleQuery(query);
          const currentActiveObject = workbenchUIStore.getActiveObject();
          updateWorksheetData(currentActiveObject.value, {
            query: query,
          });
          debouncedSaveQuery(currentActiveObject.value, query);
        }}
      />
    );
  };

  return (
    <div className="workbench-content">
      <div className="workbench-spreadsheet">
        <div className="workbench-content-inner">
          <div className="workbench-content">
            <div className="worksheet-viewer">{renderInner()}</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default compose<Props, IWorksheetViewerProps>(
  inject("workbenchUIStore"),
  observer
)(WorksheetViewer);
