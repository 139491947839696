import { CloseOutlined, EyeInvisibleOutlined } from "@ant-design/icons";
import { Button, Space } from "antd";
import Dropdown from "antd/es/dropdown/dropdown";
import useToken from "antd/es/theme/useToken";
import type { AvailableColumn } from "../../domain";
import PropertyVisibilitySelector from "./PropertiesSelector/visibility/PropertyVisibilitySelector";

interface IObjectToolbarFieldsProps {
  availableColumns: Array<AvailableColumn>;
  value: Array<{ key: string; visible: boolean }>;
  onChange: (value: Array<{ key: string; visible: boolean }>) => any;
}

export function ObjectToolbarFields(props: IObjectToolbarFieldsProps) {
  const { availableColumns, value, onChange } = props;
  const [, token] = useToken();

  const contentStyle = {
    backgroundColor: token.colorBgElevated,
    borderRadius: token.borderRadiusLG,
    boxShadow: token.boxShadowSecondary,
  };

  return (
    <Dropdown
      trigger={["click"]}
      destroyPopupOnHide
      dropdownRender={() => {
        return (
          <div
            className="wlydatepicker-custom-menu"
            style={{
              ...contentStyle,
            }}
          >
            <div
              style={{
                width: 320,
                margin: "4px 12px",
                paddingTop: 12,
                paddingBottom: 12,
              }}
            >
              <PropertyVisibilitySelector
                availableColumns={availableColumns}
                value={value}
                onChange={onChange}
              />
            </div>
          </div>
        );
      }}
    >
      <div>
        <Button
          size="small"
          type="text"
          icon={<EyeInvisibleOutlined />}
          style={
            typeof value?.length === "number" &&
            value?.filter?.((v) => v.visible)?.length !==
              availableColumns?.length
              ? { background: "#DBE0FD", color: "#3f6ac4" }
              : undefined
          }
        >
          <Space>
            <span>
              Hide
              {value?.filter?.((v) => !v.visible).length > 0 && (
                <>: {value?.filter?.((v) => !v.visible).length}</>
              )}
            </span>
            {value?.filter?.((v) => !v.visible).length > 0 && (
              <CloseOutlined
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  onChange(value.map((v) => ({ key: v.key, visible: true })));
                }}
              />
            )}
          </Space>
        </Button>
      </div>
    </Dropdown>
  );
}
