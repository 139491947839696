import { inject, observer } from "mobx-react";
import * as React from "react";
import type { InjectedAntUtilsProps } from "../../../../../components/ant-utils/withAntUtils";
import { withAntUtils } from "../../../../../components/ant-utils/withAntUtils";
import { compose } from "../../../../../components/compose/WlyCompose";
import type { WorkbenchUIStoreProps } from "../../../../../store/workbenchUIStore";
import type {
  FetchedDestination,
  IActiveObject,
  UpdateDestination,
} from "../../domain";
import NoAccess from "../empty/NoAccess";

import { Tree } from "antd";
import type { DataNode } from "antd/es/tree";
import { SplitView } from "../../../../../components/resizable/SplitView";
import type { IDestination } from "../../../../../interfaces/destinations";
import * as Toolbar from "../../../../spreadsheet/toolbar/Toolbar";
import "./SettingsViewer.scss";
import ModelsSyncForm from "./models-sync/ModelsSyncForm";
import PersistenceEngineForm from "./persistence-engine/PersistenceEngineForm";
import SignalEngineForm from "./signals/SignalEngineForm";

interface ISettingsViewerProps {
  activeObject: IActiveObject;
  destination: FetchedDestination;
  onDestinationUpdate?: UpdateDestination;
  currentWarehouse: IDestination;
}

type Props = ISettingsViewerProps &
  WorkbenchUIStoreProps &
  InjectedAntUtilsProps;

interface IState {
  selectedMenu: string;
}

class SettingsViewer extends React.Component<Props, IState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      selectedMenu: "persistence-engine",
    };
  }

  public render() {
    const { activeObject, destination, onDestinationUpdate, currentWarehouse } =
      this.props;

    if (!activeObject) {
      return <NoAccess />;
    }

    const menu = [
      {
        key: "persistence-engine",
        label: "Persistence Engine",
        component: (
          <PersistenceEngineForm
            destination={destination}
            onDestinationUpdate={onDestinationUpdate}
            currentWarehouse={currentWarehouse}
          />
        ),
      },
      {
        key: "models-sync",
        label: "Models Sync",
        component: (
          <ModelsSyncForm
            destination={destination}
            onDestinationUpdate={onDestinationUpdate}
          />
        ),
      },
      {
        key: "signals-engine",
        label: "Signal Engine",
        component: (
          <SignalEngineForm
            destination={destination}
            onDestinationUpdate={onDestinationUpdate}
            currentWarehouse={currentWarehouse}
          />
        ),
      },
    ];

    const left = (
      <div>
        <Toolbar.Toolbar style={{ borderTop: "none" }}>
          <Toolbar.ViewName>Settings</Toolbar.ViewName>
        </Toolbar.Toolbar>
        <div className="workbench-content-inner-content">
          <Tree
            blockNode={true}
            selectable={true}
            draggable={false}
            selectedKeys={[this.state.selectedMenu]}
            className="workbench-item"
            autoExpandParent={true}
            treeData={menu.map<DataNode>((m) => {
              return {
                key: m.key,
                title: (
                  <div
                    className="workbench-item"
                    onClick={() => this.setState({ selectedMenu: m.key })}
                  >
                    <div className="workbench-item-name">{m.label}</div>
                  </div>
                ),
                isLeaf: true,
                selectable: false,
              };
            })}
          />
        </div>
      </div>
    );
    const right = (
      <div className="settings-viewer">
        <Toolbar.Toolbar style={{ borderTop: "none" }}>
          <div />
        </Toolbar.Toolbar>
        <div className="settings-viewer-panel">
          {menu.find((m) => m.key === this.state.selectedMenu)?.component}
        </div>
      </div>
    );

    return (
      <div className="workbench-content">
        <div className="workbench-spreadsheet">
          <div className="workbench-content-inner">
            <div className="workbench-content">
              <SplitView
                left={left}
                right={right}
                leftClassName="settings-viewer"
                minWidth={250}
                startWidth={300}
                maxWidth={"50%"}
                mainClassName={"workbench-content"}
                rightClassName="settings-viewer"
                alignement="LTR"
                compact={true}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default compose<Props, ISettingsViewerProps>(
  inject("workbenchUIStore"),
  observer,
  withAntUtils
)(SettingsViewer);
