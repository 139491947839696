import { gql } from "@apollo/client";
import type { MutateOptions } from "../../../../../components/hooks/query/useQGLMutate";
import { useGQLMutate } from "../../../../../components/hooks/query/useQGLMutate";

type MutationData = { updateTopic: { id: string } };

type MutationVariables = {
  id: string;
  data: Record<string, any>;
};

const UpdateTopicMutation = gql`
  mutation UpdateTopic($id: ID!, $data: TopicUpdateInput!) {
    updateTopic(id: $id, data: $data) {
      id
    }
  }
`;

export const useUpdateTopic = (options?: MutateOptions<MutationData>) => {
  return useGQLMutate<MutationData, MutationVariables>(
    UpdateTopicMutation,
    options
  );
};
