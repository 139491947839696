import { Form, Input, Modal } from "antd";
import * as React from "react";

export interface ViewInitialData {
  id?: string;
  name: string;
}

interface IViewEditionProps {
  initialData?: ViewInitialData;
  onSave: (data: ViewInitialData) => Promise<void>;
  visible: boolean;
  onCancel: () => void;
}

type Props = IViewEditionProps;

const ViewEdition: React.FunctionComponent<Props> = (props) => {
  const { onSave, visible, onCancel, initialData } = props;

  const [confirmLoading, setConfirmLoading] = React.useState(false);
  const [form] = Form.useForm();

  React.useEffect(() => {
    if (visible === false) {
      form.resetFields();
    }
  }, [visible]);

  const renderInnerModal = () => {
    return (
      <Form
        initialValues={
          !initialData
            ? {
                name: "",
              }
            : initialData
        }
        layout="vertical"
        form={form}
      >
        <Form.Item
          name={["name"]}
          label="View Name"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input placeholder="Your view name" />
        </Form.Item>
      </Form>
    );
  };

  return (
    <Modal
      open={visible}
      title={initialData ? "Update your view" : "Create a view"}
      okText={initialData ? "Update" : "Create"}
      destroyOnClose={true}
      cancelText="Cancel"
      onCancel={() => {
        form.resetFields();
        onCancel();
      }}
      confirmLoading={confirmLoading}
      onOk={() => {
        setConfirmLoading(true);
        return form
          .validateFields()
          .then((values) => {
            const v = values as ViewInitialData;
            return onSave({ ...initialData, ...v }).then(() =>
              setConfirmLoading(false)
            );
          })
          .then(() => {
            return form.resetFields();
          })
          .catch((info) => {
            setConfirmLoading(false);
            console.error("Validate Failed:", info);
          });
      }}
    >
      {renderInnerModal()}
    </Modal>
  );
};

export default ViewEdition;
