import type { IAnalysisType } from "../../../containers/exploration/single/domain";
import { WlyTreemapChartIcon } from "../../icons/custom-icons/WlyTreemapChartIcon";
import type { SingleChartDefinition } from "../domain";

export const treemapChartDefinition: SingleChartDefinition = {
  label: "Treemap",
  allowTimeComparison: false,
  icon: <WlyTreemapChartIcon />,
  canPredict: () => false,
  pivot: (analysisType: IAnalysisType) => false,
  minDimensions: 1,
  maxDimensions: 2,
  minMetrics: 1,
  maxMetrics: 1,
  canDisplay: (rs) => {
    if ((rs as any).queryType === "compareDateRangeQuery") {
      return "Cannot use a date comparison query on a heatmap";
    }
    return;
  },
  config: ["labels", "palette"],
  axis: {
    bottom: {
      editable: false,
      isTimeFormattable: (a) => false,
    },
    left: {
      editable: false,
    },
    right: {
      editable: false,
    },
  },
  extractCustomizationDimensions: (d, q) => {
    return {
      dimensions: [],
    };
  },
  extractCustomizationSeries: (s, d, q) => {
    return {
      series: d
        .map((item) => item[q.selectedDimensions[0]])
        .filter((value, index, self) => self.indexOf(value) === index)
        .map((a) => ({
          key: a,
          label: a,
        })),
      customColor: true,
      customLabel: true,
      customType: [],
    };
  },
};
