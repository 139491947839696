import { FileMarkdownOutlined, FileOutlined } from "@ant-design/icons";
import { Form, Input, Select, Space, TreeSelect, Typography } from "antd";
import * as React from "react";
import { useCallback, useEffect } from "react";
import Flow from "../../../../../../assets/datasets/flow.svg";
import SQL from "../../../../../../assets/datasets/sql.svg";
import type { InjectedAntUtilsProps } from "../../../../../../components/ant-utils/withAntUtils";
import { withAntUtils } from "../../../../../../components/ant-utils/withAntUtils";
import { compose } from "../../../../../../components/compose/WlyCompose";
import EmojiRenderer from "../../../../../../components/form/emoji-picker/EmojiRenderer";
import Aligner from "../../../../../../components/layout/aligner/Aligner";
import Loading from "../../../../../../components/layout/feedback/loading";
import WlyModal from "../../../../../../components/modal/WlyModal";
import { WlySelector } from "../../../../../../components/selector/WlySelector";
import { SourceImageRenderer } from "../../../../../../components/sources/SourceImageRenderer";
import type { AsyncData } from "../../../../../../helpers/typescriptHelpers";
import type { IDestination } from "../../../../../../interfaces/destinations";
import type { IModelFolder } from "../../../../../../interfaces/modelFolder";
import type { IDataset } from "../../../../../../interfaces/sources";
import { track } from "../../../../../../services/AnalyticsService";
import GraphQLService from "../../../../../../services/graphql/GraphQLService";
import type { InjectedOrgProps } from "../../../../../orgs/WithOrg";
import WithOrg from "../../../../../orgs/WithOrg";
import { MODEL_FOLDER_FRAGMENT } from "../../../../single/domain";

const { Option, OptGroup } = Select;
const { Text } = Typography;

export interface DatasetInitialData {
  id?: string;
  sourceId?: string;
  name?: string;
  datasetId?: string;
  folderId?: string;
}

export interface DatasetSavedData extends DatasetInitialData {
  type: "SQL" | "WAREHOUSE" | "TABLE" | "FLOW";
  primaryKeys: string[];
  sql?: string;
  database?: string;
  databaseQuoting?: boolean;
  schema?: string;
  schemaQuoting?: boolean;
  table?: string;
  tableQuoting?: boolean;
  isModel?: boolean;
  startFromDatasetId?: string;
}

interface IDatasetEditionProps {
  initialData?: DatasetInitialData;
  visible: boolean;
  onCancel: () => void;
  onSave: (data: DatasetSavedData) => Promise<void>;
  currentWarehouse: IDestination;
}

type GQLQueryResult = {
  allDatasets: IDataset[];
  allModelFolders: IModelFolder[];
};

type Props = IDatasetEditionProps & InjectedOrgProps & InjectedAntUtilsProps;

const GQL = `
  ${MODEL_FOLDER_FRAGMENT}
  query getSyncedTables($id: ID!, $destinationId: ID!) {
    allModelFolders: allModelFolders(
      sortBy: name_ASC
      where: { org: { id: $id }, warehouse: { id: $destinationId }, isDeleted_not: true }
    ) {
      ...ModelFolderQuery
    }
    allDatasets: allDatasets(
      where: {
        org: { id: $id }
        deleted_not: true
        warehouse: { id: $destinationId }
        OR: [
          { source: { isDeleted_not: true, hideFromInterface: false } },
          { isModel: true }
        ]
        hideFromInterface: false
      }
    ) {
      id
      name
      primaryKey
      isModel
      warehouseDatabaseId
      warehouseSchemaId
      warehouseTableId
      source {
        id
        name
        sourceMeta {
          publicInfo {
            logo
          }
        }
      }
      folder {
        name
      }
    }
  }
`;

const AddModelModal: React.FunctionComponent<Props> = (props) => {
  const {
    antUtils,
    onSave,
    visible,
    onCancel,
    initialData,
    org,
    currentWarehouse,
  } = props;
  const isEditing = !!initialData?.name;
  const isCreatingFromSource = !!initialData?.datasetId;

  const [confirmLoading, setConfirmLoading] = React.useState(false);
  const [step, setStep] = React.useState<"STEP_1" | "STEP_2">(
    isEditing || isCreatingFromSource ? "STEP_2" : "STEP_1"
  );
  const [selected, setSelected] = React.useState<"SQL" | "FLOW">("FLOW");
  const [data, setData] = React.useState<
    AsyncData<{ datasets: IDataset[]; modelFolders: IModelFolder[] }>
  >({
    status: "initial",
  });
  const [form] = Form.useForm();

  const getDatasetsAndFolders = useCallback(async () => {
    try {
      const result = await GraphQLService<GQLQueryResult>(GQL, {
        id: org.id,
        destinationId: currentWarehouse.id,
      });
      setData({
        data: {
          datasets: result.allDatasets,
          modelFolders: result.allModelFolders,
        },
        status: "success",
      });
    } catch (error) {
      console.warn(error);
      setData({
        error: undefined,
        status: "error",
      });
      antUtils.message.error("Error fetching datasets");
    }
  }, [org]);

  useEffect(() => {
    if (props.visible) {
      getDatasetsAndFolders();
    }
  }, [getDatasetsAndFolders, props.visible]);

  const renderInnerModal = () => {
    if (step === "STEP_1") {
      return (
        <WlySelector
          left={{
            id: "FLOW",
            title: "Flow Model",
            description:
              "Use our editor to edit and create your model without SQL.",
            image: Flow,
          }}
          right={{
            id: "SQL",
            title: "SQL Model",
            description:
              "Create a model using SQL to combine existing datasets together or to create a new model from scratch",
            image: SQL,
          }}
          selected={selected}
          onSelect={(s) => setSelected(s as any)}
        />
      );
    }

    if (step === "STEP_2") {
      if (data.status === "initial" || data.status === "loading") {
        return (
          <Aligner>
            <Loading />
          </Aligner>
        );
      }
      if (data.status === "error") {
        return <Aligner>{data.error?.message}</Aligner>;
      }
      return (
        <Form initialValues={initialData} layout="vertical" form={form}>
          <Form.Item
            name={["name"]}
            label="Model Name"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input placeholder="Your model name" />
          </Form.Item>

          {!isEditing && selected === "FLOW" && (
            <Form.Item
              name={["datasetId"]}
              label="Select a starting point"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select
                style={{ width: "100%" }}
                placeholder={"Select an option in the list"}
                showSearch
                optionFilterProp="label"
              >
                <Option value={"blank"} label={"blank"}>
                  <div>
                    <span
                      role="img"
                      style={{
                        marginRight: 5,
                      }}
                    >
                      <FileOutlined />
                    </span>
                    <Text>Start from a blank canvas</Text>
                  </div>
                </Option>
                <OptGroup label="Start from model">
                  {[...data.data.datasets]
                    .filter((d) => !!d.isModel)
                    .sort((a, b) => {
                      if (a.folder?.name !== b.folder?.name) {
                        return a.folder?.name > b.folder?.name ? 1 : -1;
                      } else {
                        return a.name > b.name ? 1 : -1;
                      }
                    })
                    .map((dataset) => (
                      <Option
                        value={dataset.id}
                        label={dataset.name}
                        key={dataset.id}
                      >
                        <div>
                          <span
                            role="img"
                            style={{
                              marginRight: 5,
                            }}
                          >
                            <FileMarkdownOutlined />
                          </span>
                          <Text>
                            {dataset.name}
                            <Text type="secondary">
                              {" "}
                              {dataset.folder?.name}
                            </Text>
                          </Text>
                        </div>
                      </Option>
                    ))}
                </OptGroup>
                <OptGroup label="Start from source">
                  {[...data.data.datasets]
                    .filter((d) => !d.isModel)
                    .sort((a, b) => {
                      if (a.source?.name !== b.source?.name) {
                        return a.source?.name > b.source?.name ? 1 : -1;
                      } else {
                        return a.name > b.name ? 1 : -1;
                      }
                    })
                    .map((dataset) => (
                      <Option
                        value={dataset.id}
                        label={dataset.name}
                        key={dataset.id}
                      >
                        <div>
                          <span
                            role="img"
                            style={{
                              marginRight: 5,
                            }}
                          >
                            <SourceImageRenderer
                              alt={"sourceName"}
                              className="source-table-selection-logo"
                              img={
                                !dataset.source
                                  ? undefined
                                  : dataset.source.sourceMeta.publicInfo.logo
                              }
                              size={16}
                            />
                          </span>
                          <Text>
                            {dataset.name}
                            <Text type="secondary"> {dataset.source.name}</Text>
                          </Text>
                        </div>
                      </Option>
                    ))}
                </OptGroup>
              </Select>
            </Form.Item>
          )}

          {!isEditing && (
            <Form.Item
              name={["folderId"]}
              label="Select a folder"
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <TreeSelect
                style={{ width: "100%" }}
                dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
                treeData={data.data.modelFolders.map((mf) => ({
                  title: (
                    <Space size={4} style={{ width: "100%" }}>
                      <EmojiRenderer emoji={mf.image} size={20} /> {mf.name}
                    </Space>
                  ),
                  value: mf.id,
                  id: mf.id,
                  pId: mf.parent ? mf.parent.id : null,
                }))}
                treeDataSimpleMode={true}
                placeholder="No folder selected"
                treeDefaultExpandAll
                allowClear
              />
            </Form.Item>
          )}
        </Form>
      );
    }
  };

  return (
    <WlyModal
      open={visible}
      title={isEditing ? "Update a model" : "Create a model"}
      okText={step === "STEP_1" ? "Next" : isEditing ? "Update" : "Create"}
      cancelText="Cancel"
      onClose={() => {
        form.resetFields();
        onCancel();
      }}
      confirmLoading={confirmLoading}
      onOk={async () => {
        if (step === "STEP_1") {
          setStep("STEP_2");
        } else {
          try {
            setConfirmLoading(true);
            const values: DatasetSavedData = await form.validateFields();
            let startFromDatasetId = undefined;
            if (data.status !== "success") {
              throw new Error("data not loaded");
            }
            if (
              !isEditing &&
              selected === "FLOW" &&
              values.datasetId !== "blank"
            ) {
              startFromDatasetId = data.data.datasets.find(
                (d) => d.id === values.datasetId
              ).id;
            }
            await onSave({
              ...initialData,
              ...values,
              type: selected,
              startFromDatasetId,
            });
            track("Model Created", {
              type: selected,
            });
            form.resetFields();
          } catch (error) {
            console.warn(error);
            setConfirmLoading(false);
          }
        }
      }}
    >
      {renderInnerModal()}
    </WlyModal>
  );
};

export default compose<Props, IDatasetEditionProps>(
  WithOrg,
  withAntUtils
)(AddModelModal);
