import { Dropdown, Progress, Typography } from "antd";
import numeral from "numeral";
import React from "react";
import { BORDER_COLOR } from "../../layout/domain";
import { type RCAMetricFormatter } from "./interface";

interface IRCAElementProps {
  selected?: boolean;
  onSelect?: () => void;
  name: string;
  value: number;
  proportion: number;
  proportionFormatter: "PERCENT_FROM_ROOT" | "PERCENT_FROM_PARENT";
  parentProportion: number;
  rootProportion: number;
  redirectToRecords?: (value: string) => void;
  formatter: RCAMetricFormatter;
}

export function RCAElement(props: IRCAElementProps) {
  const {
    name,
    value,
    proportion,
    proportionFormatter,
    parentProportion,
    rootProportion,
    selected,
    onSelect,
    redirectToRecords,
    formatter,
  } = props;

  const id = React.useId();

  const computeProportion = () => {
    if (proportionFormatter === "PERCENT_FROM_ROOT") {
      return parseFloat(proportion as any) / parseFloat(rootProportion as any);
    }
    if (proportionFormatter === "PERCENT_FROM_PARENT") {
      return (
        parseFloat(proportion as any) / parseFloat(parentProportion as any)
      );
    }
    return proportion;
  };

  const formatProportion = (p: number) => numeral(p).format("0.[00]%");

  const computeMetric = () => {
    if (formatter === "POINT") {
      return `${numeral(value * 100).format("0.[00]")}pt`;
    }
    if (formatter === "PERCENT") {
      return `${numeral(value).format("0.[00]%")}`;
    }
  };

  return (
    <div id={id} onClick={onSelect}>
      <Dropdown
        menu={{
          items: redirectToRecords
            ? [
                {
                  key: "view-underlying-records",
                  label: "View underlying records",
                  onClick: (e) => {
                    e.domEvent.preventDefault();
                    e.domEvent.stopPropagation();
                    redirectToRecords(name);
                  },
                },
              ]
            : [],
        }}
        trigger={["contextMenu"]}
      >
        <div
          style={{
            outline: `${selected ? "2" : "1"}px solid ${
              selected ? "#69A3B0" : BORDER_COLOR
            }`,
            outlineOffset: selected ? -2 : -1,
            cursor: onSelect ? "pointer" : undefined,
            padding: "12px",
            borderRadius: 12,
          }}
        >
          <div style={{ display: "flex", gap: 8 }}>
            <div style={{ flex: 0 }}>
              <Progress
                size={44}
                type="circle"
                showInfo={false}
                strokeWidth={12}
                percent={computeProportion() * 100}
              />
            </div>
            <div
              style={{
                flex: 1,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div style={{ flex: 1, display: "flex" }}>
                <div style={{ flex: 1 }}>
                  <Typography.Text strong>{name}</Typography.Text>
                </div>
                <div style={{ flex: `0 auto` }}>
                  <Typography.Text strong>{computeMetric()}</Typography.Text>
                </div>
              </div>
              <div style={{ flex: 1, display: "flex" }}>
                <div style={{ flex: 1 }}>
                  <Typography.Text type="secondary">Proportion</Typography.Text>
                </div>
                <div style={{ flex: `0 auto` }}>
                  <Typography.Text type="secondary">
                    {formatProportion(computeProportion())}
                  </Typography.Text>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Dropdown>
    </div>
  );
}
