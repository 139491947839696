import { isEmpty } from "lodash";
import "whatwg-fetch";
import { getAccessToken, getAuthorizationType } from "./authService";

export interface ApiOptions {
  authUrl?: boolean;
  localUrl?: boolean;
  loginUrl?: boolean;
  adminApi?: object;
  customDomain?: string;
  lagoonUrl?: boolean;
  lagoonNextUrl?: boolean;
  overrideUrl?: string;
  fileServiceUrl?: boolean;
  assetsUrl?: boolean;
  withCredentials?: boolean;
  authenticated?: boolean;
  asBlob?: boolean;
}

const WHALY_CONSTANTS: any = window.WHALY_CONSTANTS || {};
const AUTH_URL = `${WHALY_CONSTANTS.AUTH_SERVER_URL}`;
const LOCAL_URL = `${WHALY_CONSTANTS.APP_URL}`;
const API_URL = `${WHALY_CONSTANTS.API_URL}/v1/`;
const LAGOON_URL = `${WHALY_CONSTANTS.LAGOON_URL}`;
const LAGOON_NEXT_URL = `${WHALY_CONSTANTS.LAGOON_NEXT_URL}`;
const LOGIN_URL = `${WHALY_CONSTANTS.LOGIN_APP_URL}`;
const FILESERVICE_URL = `${WHALY_CONSTANTS.FILESERVICE_URL}`;
const ASSETS_URL = `${WHALY_CONSTANTS.ASSETS_URL}`;

type RequestMethod = "get" | "post" | "put" | "delete";

function paramsToQueryString(paramsArg: { [key: string]: any } = {}) {
  if (!paramsArg) return "";
  const paramsToArray: string[] = Object.keys(paramsArg);
  const str: string = paramsToArray
    .filter((key) => paramsArg[key] !== undefined)
    .map(
      (key) =>
        `${encodeURIComponent(key)}=${encodeURIComponent(paramsArg[key])}`
    )
    .join("&");
  return str.length ? `?${str}` : "";
}

function request(
  method: RequestMethod,
  endpoint: string,
  options: {
    params?: { [key: string]: any };
    body?: any;
    headers?: { [key: string]: any };
    customDomain?: string;
    localUrl?: boolean;
    loginUrl?: boolean;
    authUrl?: boolean;
    lagoonUrl?: boolean;
    lagoonNextUrl?: boolean;
    overrideUrl?: string;
    fileServiceUrl?: boolean;
    adminApi?: object;
    assetsUrl?: boolean;
    withCredentials?: boolean;
    authenticated?: boolean;
    asBlob?: boolean;
  } = {}
) {
  const generateBaseUrl = () => {
    if (options.customDomain) {
      return options.customDomain;
    }
    if (options.authUrl) {
      return AUTH_URL;
    }
    if (options.localUrl) {
      return LOCAL_URL;
    }
    if (options.loginUrl) {
      return LOGIN_URL;
    }
    if (options.lagoonUrl) {
      return LAGOON_URL;
    }
    if (options.lagoonNextUrl) {
      return LAGOON_NEXT_URL;
    }
    if (options.overrideUrl) {
      return options.overrideUrl;
    }
    if (options.fileServiceUrl) {
      return FILESERVICE_URL;
    }
    if (options.assetsUrl) {
      return ASSETS_URL;
    }
    return API_URL;
  };

  const baseUrl = generateBaseUrl();
  const url = `${baseUrl}${endpoint}${paramsToQueryString(options.params)}`;

  const requestHeaders = new Headers(options.headers || {});

  if (options.authenticated !== false) {
    const token = getAccessToken();
    const type = getAuthorizationType();
    if (token) {
      requestHeaders.append("Authorization", `${type} ${token}`);
    } else {
      throw new Error(
        `Error. Authenticated without token, endpoint:${endpoint}`
      );
    }
  }

  const config: RequestInit = {
    method,
    headers: requestHeaders,
  };

  if (options.body instanceof FormData || options.body instanceof Blob) {
    config.body = options.body;
  } else if (options.body) {
    if (isEmpty(options.headers)) {
      // default headers: application/json
      requestHeaders.append("Accept", "application/json");
      requestHeaders.append("Content-Type", "application/json");
      config.body = JSON.stringify(options.body);
    } else {
      config.body = options.body;
    }
  }

  if (options.withCredentials) {
    config.credentials = "include";
  }

  const checkAndParse = (response: Response) => {
    const contentType = response.headers.get("Content-Type");

    if (response.status === 401) {
      const event = new Event("unauthorizedEvent");
      document.dispatchEvent(event);
    }

    if (options.asBlob) {
      return response.blob().then((blob) => {
        if (!response.ok) {
          Promise.reject(blob);
        }
        return blob;
      });
    }

    if (
      contentType &&
      (contentType.indexOf("image/png") !== -1 ||
        contentType.indexOf("application/octet-stream") !== -1)
    ) {
      return response.blob().then((blob) => {
        if (!response.ok) {
          Promise.reject(blob);
        }
        return blob;
      });
    } else if (contentType && contentType.indexOf("text/html") !== -1) {
      return response.status < 400
        ? Promise.resolve()
        : Promise.reject(response);
    } else if (contentType && contentType.indexOf("text/plain") !== -1) {
      return response.status < 400 ? response.text() : Promise.reject(response);
    }

    // Considered as a json response by default
    return response.json().then((json) => {
      if (!response.ok) {
        return Promise.reject(json);
      }

      return json;
    });
  };

  return fetch(url, config).then(checkAndParse);
}

function getRequest<T>(
  endpoint: string,
  params: { [key: string]: any } = {},
  headers: { [key: string]: any } = {},
  options: ApiOptions = {}
): Promise<T> {
  return request("get", endpoint, {
    params,
    headers,
    ...options,
    authenticated:
      options.authenticated === undefined ? true : options.authenticated,
  }) as Promise<T>;
}

function postRequest<T>(
  endpoint: string,
  body: any,
  params: { [key: string]: any } = {},
  headers: { [key: string]: any } = {},
  options: ApiOptions = {}
): Promise<T> {
  return request("post", endpoint, {
    params,
    headers,
    body,
    ...options,
    authenticated:
      options.authenticated === undefined ? true : options.authenticated,
  }) as Promise<T>;
}

function putRequest<T>(
  endpoint: string,
  body: any,
  params: { [key: string]: any } = {},
  headers: { [key: string]: any } = {},
  options: ApiOptions = {}
): Promise<T> {
  return request("put", endpoint, {
    params,
    headers,
    body,
    ...options,
    authenticated:
      options.authenticated === undefined ? true : options.authenticated,
  }) as Promise<T>;
}

function deleteRequest<T>(
  endpoint: string,
  params: { [key: string]: any } = {},
  headers: { [key: string]: any } = {},
  options: ApiOptions = {}
): Promise<T> {
  return request("delete", endpoint, {
    params,
    headers,
    ...options,
    authenticated:
      options.authenticated === undefined ? true : options.authenticated,
  }) as Promise<T>;
}

export default {
  request,
  getRequest,
  postRequest,
  putRequest,
  deleteRequest,
};
