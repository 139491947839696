import { Form, Select, Space, Switch } from "antd";
import Delayed from "../../components/delayed/delayed";
import type {
  AvailableDimension,
  AvailableMetric,
} from "../../components/measures/filter-item/FilterItem";
import type { ILagoonQuery } from "../exploration/single/domain";
import { timeGrains } from "../reports/view/chart/panel/summary.domain";
import { ChartOptionCollapse } from "./components/ChartOptionCollapse";
import ChartOptionLine from "./components/ChartOptionLine";

interface IChartOptionSummaryProps {
  availableDimensions: AvailableDimension[];
  availableMetrics: AvailableMetric[];
  query: ILagoonQuery;
}

export const canDisplaySummary = (query: ILagoonQuery) => {
  if (query.analysisType === "METRIC") {
    return false;
  }
  if (
    query.selectedDimensions.length > 1 ||
    query.selectedMeasures.length > 1
  ) {
    return false;
  }

  return true;
};

export function ChartOptionSummary(props: IChartOptionSummaryProps) {
  const { availableDimensions, availableMetrics, query } = props;

  const metricPopover = {
    title: "Metric summary definition",
    overlayStyle: {
      width: 400,
    },
    content: (
      <Delayed delay={100}>
        <Space direction="vertical" style={{ width: "100%" }}>
          Allow metric changes:
          <Form.Item
            valuePropName="checked"
            name={["summary", "metrics", "allow"]}
            noStyle
          >
            <Switch />
          </Form.Item>
          What metrics do you want to showcase:
          <Form.Item name={["summary", "metrics", "whiteList"]} noStyle>
            <Select mode="multiple" style={{ width: "100%" }}>
              {availableMetrics.map((am) => {
                return (
                  <Select.Option key={am.key} value={am.key}>
                    {am.label}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
        </Space>
      </Delayed>
    ),
  };

  const dimensionPopover = {
    title: "Dimension summary definition",
    overlayStyle: {
      width: 400,
    },
    content: (
      <Delayed delay={100}>
        <Space direction="vertical" style={{ width: "100%" }}>
          Allow dimension changes:
          <Form.Item
            valuePropName="checked"
            name={["summary", "dimensions", "allow"]}
            noStyle
          >
            <Switch />
          </Form.Item>
          What dimension do you want to showcase:
          <Form.Item name={["summary", "dimensions", "whiteList"]} noStyle>
            <Select mode="multiple" style={{ width: "100%" }}>
              {availableDimensions.map((am) => {
                return (
                  <Select.Option key={am.key} value={am.key}>
                    {am.label}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
        </Space>
      </Delayed>
    ),
  };

  const timePopover = {
    title: "Time grain summary definition",
    overlayStyle: {
      width: 400,
    },
    content: (
      <Delayed delay={100}>
        <Space direction="vertical" style={{ width: "100%" }}>
          Allow time grain changes:
          <Form.Item
            valuePropName="checked"
            name={["summary", "time", "allow"]}
            noStyle
          >
            <Switch />
          </Form.Item>
          What time grain do you want to showcase:
          <Form.Item name={["summary", "time", "whiteList"]} noStyle>
            <Select mode={"multiple"} style={{ width: "100%" }}>
              {timeGrains.map((tg) => {
                return (
                  <Select.Option key={tg} value={tg}>
                    {tg}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
        </Space>
      </Delayed>
    ),
  };

  return (
    <ChartOptionCollapse title="Summary query options">
      <Space direction="vertical" style={{ width: "100% " }}>
        {query.selectedMeasures.length === 1 && (
          <ChartOptionLine
            items={[
              {
                flex: 1,
                content: "Metrics",
                popover: metricPopover,
              },
              {
                flex: 0,
                type: "edit",
                popover: metricPopover,
              },
            ]}
          />
        )}
        {query.selectedDimensions.length <= 1 && (
          <ChartOptionLine
            items={[
              {
                flex: 1,
                content: "Dimensions",
                popover: dimensionPopover,
              },
              {
                flex: 0,
                type: "edit",
                popover: dimensionPopover,
              },
            ]}
          />
        )}
        {query.selectedTime && (
          <ChartOptionLine
            items={[
              {
                flex: 1,
                content: "Time grain",
                popover: timePopover,
              },
              {
                flex: 0,
                type: "edit",
                popover: timePopover,
              },
            ]}
          />
        )}
      </Space>
    </ChartOptionCollapse>
  );
}
