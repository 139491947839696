import type { Remote } from "comlink";
import type { CSSProperties, HTMLAttributes } from "react";
import { useMemo } from "react";
import type { RouteComponentProps } from "react-router";
import { withRouter } from "react-router";
import Card from "../../../../../components/cards/Card";
import { compose } from "../../../../../components/compose/WlyCompose";
import type { IReport, ITile } from "../../../../../interfaces/reports";
import { routeDescriptor } from "../../../../../routes/routes";
import GraphQLService from "../../../../../services/graphql/GraphQLService";
import type { CubeJSPivotWorker } from "../../../../../worker/main.worker";
import type { ILagoonQuery } from "../../../../exploration/single/domain";
import type { InjectedOrgProps } from "../../../../orgs/WithOrg";
import WithOrg from "../../../../orgs/WithOrg";
import type { Store } from "../../chart/card/ChartCard";
import ChartCard from "../../chart/card/ChartCard";
import type { FilterMapStore } from "../../domain";
import { CREATE_TILE, UPDATE_TILE } from "../../domain";
import NavigationComponent from "../../navigation/component/NavigationComponent";
import TextCard from "../../text/card/TextCard";

interface IDashboardGridItemProps extends HTMLAttributes<HTMLDivElement> {
  disableNavigationItems?: boolean;
  tile: ITile;
  editing: boolean;
  store: Store;
  report: IReport;
  selected?: string;
  reloadReport: (reportSlug: string, orgId) => Promise<IReport>;
  saving: (saving: boolean) => void;
  scrollToBottom: () => void;
  setAddingNewText: (c: boolean | ITile) => void;
  setDataStoreValue: (id: string, data: Partial<Store>) => void;
  getFilterStoreValues: (id: string) => string[];
  setReport: (report: IReport) => void;
  isEmbedded: boolean;
  onOpenConsole?: (c?: string) => void;
  disableDrills?: boolean;
  setStatic: (id: string, s: boolean) => void;
  renderOutsideViewport?: boolean;
  externalWorker: Remote<CubeJSPivotWorker>;
  filterStore: FilterMapStore;
  onRefresh?: (tileId: string, overrideQuery?: ILagoonQuery) => void;
}

type Props = IDashboardGridItemProps &
  RouteComponentProps<
    { tileSlug?: string; explorationSlug?: string },
    {},
    { scrollToBottom?: boolean }
  > &
  InjectedOrgProps;

function DashboardGridItem(props: Props) {
  const {
    store,
    disableNavigationItems,
    editing,
    history,
    match: { params },
    org,
    report,
    selected,
    tile,
    reloadReport,
    saving,
    scrollToBottom,
    setAddingNewText,
    setDataStoreValue,
    getFilterStoreValues,
    setReport,
    isEmbedded,
    onOpenConsole,
    setStatic,
    renderOutsideViewport,
    externalWorker,
    filterStore,
    onRefresh,
  } = props;

  const storeMemo = useMemo(() => store, [store?.updatedAt]);

  const onChartEdit = editing
    ? () => {
        return history.push(
          routeDescriptor["reportTileExplorationEdit"].renderRoute({
            ...params,
            explorationSlug: tile.exploration.slug,
          })
        );
      }
    : undefined;

  const onTextEdit = editing ? () => setAddingNewText(tile) : undefined;

  const onDelete = editing
    ? (id: string) => {
        saving(true);
        return GraphQLService(UPDATE_TILE, {
          id,
          data: {
            deleted: true,
          },
          reportId: report.id,
        })
          .then(() => {
            return reloadReport(report.slug, org.id);
          })
          .then(() => {
            saving(false);
          })
          .catch(() => {
            saving(false);
          });
      }
    : undefined;

  const onRename = editing
    ? (id: string, name: string) => {
        saving(true);
        const prevTile = report.tiles.find((t) => t.id === id)!.name;
        setReport({
          ...report,
          tiles: report.tiles.map((t) => {
            if (t.id === id) {
              return {
                ...t,
                name,
              };
            }
            return t;
          }),
        });
        return GraphQLService(UPDATE_TILE, {
          id,
          data: {
            name: name,
          },
          reportId: report.id,
        })
          .then(() => {
            return reloadReport(report.slug, org.id);
          })
          .then(() => {
            saving(false);
          })
          .catch(() => {
            setReport({
              ...report,
              tiles: report.tiles.map((t) => {
                if (t.id === id) {
                  return {
                    ...t,
                    name: prevTile,
                  };
                }
                return t;
              }),
            });

            saving(false);
          });
      }
    : undefined;

  const onDuplicate = editing
    ? (tile: ITile) => {
        saving(true);
        return GraphQLService(CREATE_TILE, {
          data: {
            name: tile.name,
            content: tile.content,
            width: tile.width,
            height: tile.height,
            left: tile.left,
            top: 1000000,
            type: tile.type,
            analysisType: tile.analysisType,
            chartType: tile.chartType,
            hide_name: !!tile.hide_name,
            hide_card: !!tile.hide_card,
            vertical_align: tile.vertical_align,
            horizontal_align: tile.horizontal_align,
            org: {
              connect: {
                id: org.id,
              },
            },
            report: {
              connect: {
                id: report.id,
              },
            },
            ...(tile.exploration
              ? {
                  exploration: {
                    connect: {
                      id: tile.exploration.id,
                    },
                  },
                }
              : {}),
          },
          reportId: report.id,
        })
          .then(() => {
            return reloadReport(report.slug, org.id);
          })
          .then(() => {
            scrollToBottom();
            saving(false);
          });
      }
    : undefined;

  const setRender = useMemo(
    () => (meta) => {
      setDataStoreValue(tile.id, {
        meta: meta,
      });
    },
    []
  );

  const wrapInCard = (r: React.ReactNode) => {
    return (
      <Card height="100%" noBackground={tile.hide_card}>
        {r}
      </Card>
    );
  };

  const selectedStyle: CSSProperties = {
    borderRadius: 6,
    boxShadow: selected === tile.id ? "0 0 0 1px #3A5C83" : "none",
  };

  const renderTile = () => {
    switch (tile.type) {
      case "text":
        return (
          <div
            onClick={(e) => {
              if (editing) {
                history.push(
                  routeDescriptor["reportTileEdit"].renderRoute({
                    ...params,
                    tileSlug: tile.slug,
                  })
                );
              }
              e.stopPropagation();
            }}
            key={tile.id}
            style={{
              height: "100%",
              width: "100%",
              ...selectedStyle,
            }}
          >
            {wrapInCard(
              <TextCard
                onDuplicate={onDuplicate}
                tile={tile}
                onDelete={onDelete}
                getFilterStoreValues={getFilterStoreValues}
                onEdit={onTextEdit}
                editing={editing}
                selected={selected === tile.id}
                position={`${tile.width}${tile.height}${tile.top}${tile.left}`}
                report={report}
                setStatic={(s) => setStatic(tile.id, s)}
              />
            )}
          </div>
        );
      case "navigation":
        const renderAlign = () => {
          if (tile.vertical_align === "top") {
            return "start";
          } else if (tile.vertical_align === "bottom") {
            return "end";
          }
          return tile.vertical_align;
        };
        const style: React.CSSProperties = {
          display: tile.vertical_align ? "flex" : undefined,
          alignItems: renderAlign(),
          justifyContent: tile.horizontal_align,
          position: "relative",
          height: "100%",
          width: "100%",
          ...selectedStyle,
        };
        return (
          <div
            onClick={(e) => {
              if (editing) {
                history.push(
                  routeDescriptor["reportTileEdit"].renderRoute({
                    ...params,
                    tileSlug: tile.slug,
                  })
                );
              }
              e.stopPropagation();
            }}
            key={tile.id}
            style={style}
          >
            <NavigationComponent
              editing={editing}
              disabled={!!disableNavigationItems}
              selected={selected === tile.id}
              tile={tile}
              onDelete={onDelete}
            />
          </div>
        );
      case "chart":
        // const filters = getFiltersUsedInTile(tile.id);
        return (
          <div
            onClick={(e) => {
              if (editing) {
                history.push(
                  routeDescriptor["reportTileEdit"].renderRoute({
                    ...params,
                    tileSlug: tile.slug,
                  })
                );
              }
              e.stopPropagation();
            }}
            key={tile.id}
            style={{
              height: "100%",
              width: "100%",
              ...selectedStyle,
            }}
          >
            {wrapInCard(
              <ChartCard
                onDuplicate={onDuplicate}
                tile={tile}
                editing={editing}
                selected={selected === tile.id}
                isEmbedded={isEmbedded}
                data={storeMemo}
                onEdit={onChartEdit}
                onDelete={onDelete}
                onRename={onRename}
                onOpenConsole={onOpenConsole}
                onRefresh={onRefresh}
                reportId={report.id}
                setRender={setRender}
                disableDrills={props.disableDrills}
                renderOutsideViewport={renderOutsideViewport}
                externalWorker={externalWorker}
                filterStore={filterStore}
                reportFilters={report.filters}
              />
            )}
          </div>
        );
    }
  };

  return renderTile();
}

export default compose<Props, IDashboardGridItemProps>(
  withRouter,
  WithOrg
)(DashboardGridItem);
