import { gql } from "@apollo/client";
import type { QueryOptions } from "../../../../../components/hooks/query/useGQLQuery";
import { useGQLQuery } from "../../../../../components/hooks/query/useGQLQuery";
import type { INotificationWorkflow } from "../../../../../interfaces/notification";
import type { ITopicGroup } from "../../../../../interfaces/topic";

type QueryData = {
  allTopicGroups: ITopicGroup[];
  allNotificationWorkflows: INotificationWorkflow[];
};

type QueryVariables = {
  orgId: string;
};

const NotificationTopicsPageQuery = gql`
  query allTopicGroups($orgId: ID!) {
    allNotificationWorkflows(
      where: { isDeleted: false, org: { id: $orgId } }
      sortBy: name_ASC
    ) {
      id
      name
    }
    allTopicGroups(
      where: { isDeleted: false, org: { id: $orgId } }
      sortBy: name_ASC
    ) {
      id
      name
      workflowSelections(where: { isDeleted: false, org: { id: $orgId } }) {
        id
        workflow {
          id
          name
        }
      }
      topics(
        where: { isDeleted: false, org: { id: $orgId } }
        sortBy: name_ASC
      ) {
        id
        name
        apiName
        subscribers(
          where: {
            isDeleted: false
            org: { id: $orgId }
            user: { isDeleted: false }
          }
          first: 2
        ) {
          id
          user {
            id
            firstName
            lastName
            email
            gravatar
            avatarColor
          }
        }
        _subscribersMeta(
          where: {
            isDeleted: false
            org: { id: $orgId }
            user: { isDeleted: false }
          }
        ) {
          count
        }
      }
    }
  }
`;

export const useGetTopicGroups = (
  options: QueryOptions<QueryData, QueryVariables>
) => {
  return useGQLQuery<QueryData, QueryVariables>(
    NotificationTopicsPageQuery,
    options
  );
};
