import type { Query, TimeDimensionGranularity } from "@cubejs-client/core";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import * as React from "react";
import type { UserLocale } from "../../../interfaces/user";
import type { Formatter } from "../domain";
import {
  customFormatter,
  optionsHelper,
  renderSerieTooltip,
} from "../utils/optionsHelper";
import "./ActivityChart.scss";

interface IActivityChartProps {
  width: number;
  height: number;
  locale: UserLocale;
  data: Array<{ [key: string]: string | number }>;
  config: ActivityChartConfig;
  onDrill?: (q: Query) => void;
}

interface ActivityChartConfig {
  x: {
    key: string;
    timeGranularity: TimeDimensionGranularity;
    label?: string;
    formatter?: Formatter;
  };
  y: {
    series: Array<{
      key: string;
      label: string;
      color: string;
      canDrill: (xValue: string, yValue?: string) => Query | null;
    }>;
    label?: string;
  };
  z: {
    label?: string;
    formatter: Formatter;
  };
  showLabels?: boolean;
}

export function ActivityChart(props: IActivityChartProps) {
  const { width, height, locale, config, data, onDrill } = props;
  const ref = React.useRef<HighchartsReact.RefObject>(null);
  const [rerender, setRerender] = React.useState<number | undefined>(undefined);

  const optionHelpers = optionsHelper(
    height,
    width,
    locale,
    "ordinal",
    undefined,
    undefined,
    undefined,
    undefined
  );

  const xCategories = data.map((d) => d.x as string);
  const yCategories = config.y.series.map((y) => y.label);

  const computeDataForSerie = (
    yKey: string,
    yIndex: number,
    color: string,
    canDrill: (xValue: string, yValue?: string) => Query | null
  ) => {
    const formattedData: Highcharts.PointOptionsObject[] = data.map(
      (d, xIndex) => ({
        x: xIndex,
        y: yIndex,
        z: (d[yKey] || 0) as number,
        color: !d[yKey] ? "#EEEDF2" : color,
        className: typeof onDrill === "function" ? "pointer" : "auto",
        events: {
          click: () => {
            if (typeof onDrill !== "function") return;
            const q = canDrill(
              xCategories[xIndex].toString(),
              xCategories[xIndex].toString()
            );
            if (q) {
              return onDrill(q);
            }
          },
        },
      })
    );
    return formattedData;
  };

  const series: Highcharts.SeriesOptionsType[] = [
    {
      type: "bubble",
      name: config.z.label,
      data: config.y.series.flatMap((y, yIndex) =>
        computeDataForSerie(y.key, yIndex, y.color, y.canDrill)
      ),
      marker: {
        lineWidth: 0,
        lineColor: undefined,
        fillOpacity: 1,
      },
      tooltip: renderSerieTooltip({
        data: data,
        locale: locale,
        seriesLength: 1,
        valueGetter: "this.z",
        hideSerieColor: true,
        hideHeader: true,
        formatter: config.z.formatter,
      }),
      dataLabels: {
        enabled: config.showLabels,
        formatter: function () {
          if (config.z.formatter) {
            return customFormatter(
              (this as any).point.z,
              locale,
              config.z.formatter
            );
          }
          return this.point.value;
        },
      },
    },
  ];

  const getBubbleMaxSize = (): number => {
    const xAxisLegendHeight = (
      ref?.current?.chart.axes?.find((axe) => axe.coll === "xAxis") as any
    )?.labelGroup?.element?.getBoundingClientRect?.()?.height;

    const yAxisLegendWidth = Math.round(
      (
        ref?.current?.chart.axes?.find((axe) => axe.coll === "yAxis") as any
      )?.labelGroup?.element?.getBoundingClientRect?.()?.width
    );

    const axisPadding = 35;

    const maxHeight =
      (height -
        xAxisLegendHeight -
        axisPadding -
        yCategories.length * 14 -
        (config.x.label ? 20 : 0)) /
      yCategories.length;

    const maxWidth =
      (width -
        axisPadding -
        yAxisLegendWidth -
        xCategories.length * 14 -
        -(config.y.label ? 20 : 0)) /
      xCategories.length;

    return Math.min(maxHeight, maxWidth);
  };

  const options: Highcharts.Options = {
    ...optionHelpers,
    chart: {
      ...optionHelpers.chart,
      reflow: false,
      height: height,
      spacing: [20, 0, 10, 0],
      borderWidth: 0,
      backgroundColor: "transparent",
      type: "bubble",
    },
    legend: {
      enabled: false,
    },
    plotOptions: {
      ...optionHelpers.plotOptions,
      bubble: {
        maxSize: getBubbleMaxSize(),
      },
    },
    xAxis: {
      ...optionHelpers.xAxis,
      categories: xCategories,
      labels: {
        formatter: function () {
          if (config.x.formatter) {
            return customFormatter(
              new Date(this.value).getTime(),
              locale,
              config.x.formatter
            ) as string;
          }
          return this.value as string;
        },
      },
      min: 0,
      max: xCategories.length - 1 > 0 ? xCategories.length - 1 : 0,
      title: {
        text: config.x.label,
      },
    },
    yAxis: {
      ...optionHelpers.yAxis,
      categories: yCategories,
      min: 0,
      max: yCategories.length - 1 > 0 ? yCategories.length - 1 : 0,
      title: {
        text: config.y.label,
      },
    },
    series: series,
  };

  return (
    <div style={{ height, width: "100%" }} className="activity-chart">
      <HighchartsReact
        ref={ref}
        highcharts={Highcharts}
        options={options}
        callback={() => {
          setTimeout(() => {
            // to recalculate bubbleSize after initial rerender
            setRerender(1);
          }, 200);
        }}
      />
    </div>
  );
}
