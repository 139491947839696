import { TeamOutlined } from "@ant-design/icons";
import { Avatar, Tooltip } from "antd";
import type { AvatarProps } from "antd/lib/avatar";
import * as React from "react";
import type { IGroup } from "../../../interfaces/group";

interface IGroupAvatarProps extends AvatarProps {
  group: IGroup;
  /**
   * Displays a tooltip containing the user name on hover
   */
  tooltip?: boolean;
  tooltipSuffixMessage?: string;
}

export default class GroupAvatar extends React.Component<IGroupAvatarProps> {
  public render() {
    const { group, tooltip, tooltipSuffixMessage, ...rest } = this.props;

    const name = group.name;

    return (
      <Tooltip
        title={[name, tooltipSuffixMessage].join(" ")}
        overlayStyle={{
          visibility: tooltip ? "initial" : "hidden",
        }}
      >
        <Avatar
          shape="circle"
          size="large"
          icon={<TeamOutlined />}
          {...rest}
          alt={name}
        />
      </Tooltip>
    );
  }
}
