import { LoadingOutlined } from "@ant-design/icons";
import type {
  BinaryFilter,
  PivotConfig,
  Query,
  ResultSet,
  TableColumn,
  UnaryFilter,
} from "@cubejs-client/core";
import { Empty, Spin } from "antd";
import type { Remote } from "comlink";
import { proxy, releaseProxy, wrap } from "comlink";
import cuid from "cuid";
import _ from "lodash";
import moment from "moment";
import * as React from "react";
import { InView } from "react-intersection-observer";
import "../../../../../components/ag-grid/theme/ag-theme-whaly.scss";
import { ActivityChart } from "../../../../../components/chart/activity/ActivityChart";
import BarChart from "../../../../../components/chart/barchart/BarChart";
import CalendarChart from "../../../../../components/chart/calendar/CalendarChart";
import type {
  ChartType,
  ISerieData,
} from "../../../../../components/chart/domain";
import { ChartDefinition } from "../../../../../components/chart/domain";
import FunnelChart from "../../../../../components/chart/funnel/Funnel";
import GaugeChart from "../../../../../components/chart/gauge/GaugeChart";
import MapChart from "../../../../../components/chart/geo-map/Map";
import HeatmapChart from "../../../../../components/chart/heatmap/Heatmap";
import BubbleMap from "../../../../../components/chart/interractive-map/BubbleMap";
import PinMap from "../../../../../components/chart/interractive-map/PinMap";
import LineChart from "../../../../../components/chart/linechart/LineChart";
import Metric from "../../../../../components/chart/metric/Metric";
import PieChart from "../../../../../components/chart/pie/Pie";
import { RetentionChart } from "../../../../../components/chart/retention/RetentionChart";
import ScatterChart from "../../../../../components/chart/scatter/Scatter";
import StackedAreaChart from "../../../../../components/chart/stacked-area/StackedAreaChart";
import StackedBarChartTimeserie from "../../../../../components/chart/stacked-barchart-timeserie/StackedBarChartTimeserie";
import SunburstChart from "../../../../../components/chart/sunburst/Sunburst";
import TableChart from "../../../../../components/chart/table/TableChart";
import TreemapChart from "../../../../../components/chart/treemap/Treemap";
import { customFormatter } from "../../../../../components/chart/utils/optionsHelper";
import { getColorFromPallette } from "../../../../../components/chart/utils/palletteUtils";
import { WaterfallChartTimeSerie } from "../../../../../components/chart/waterfall-timeserie/WaterfallTimeserie";
import WaterfallChart from "../../../../../components/chart/waterfall/Waterfall";
import { compose } from "../../../../../components/compose/WlyCompose";
import type {
  AvailableDimension,
  AvailableMetric,
} from "../../../../../components/measures/filter-item/FilterItem";
import PaletteGenerator from "../../../../../components/palette/utils/PaletteGenerator";
import {
  getDefaultCollection,
  getSelectedPalette,
} from "../../../../../components/palette/utils/paletteData";
import type { AsyncData } from "../../../../../helpers/typescriptHelpers";
import type { IExploration } from "../../../../../interfaces/explorations";
import type {
  IQueryTraceResult,
  WlyResultSet,
} from "../../../../../services/LagoonService";
import type { CubeJSPivotWorker } from "../../../../../worker/main.worker";
import type { ChartOption } from "../../../../chart-options/ChartOptions";
import type { InjectedOrgProps } from "../../../../orgs/WithOrg";
import WithOrg from "../../../../orgs/WithOrg";
import type { IWlyDatePickerValue } from "../../../../reports/view/filters/date-filter/WlyDatePicker";
import { convertWlyDatePickerInputValueToValue } from "../../../../reports/view/filters/date-filter/WlyDatePicker";
import type {
  IAnalysisType,
  IForecastConfig,
  ILagoonQuery,
} from "../../domain";
import { TraceDrawer } from "../../performance/TraceDrawer";
import { DEFAULT_ROW_LIMIT } from "../query-builder/domain";
import "./Chart.scss";
import { canDrill, formatChartOptions } from "./domain";
import DrillDown from "./drill-down/DrillDown";

export const STALE_DATA_MESSAGE =
  "This chart is based on stale data, please contact the owner for a resolution time.";

export interface IChartProps {
  currentChartType: ChartType;
  data: AsyncData<WlyResultSet<any>>;
  additionalResults: AsyncData<WlyResultSet<any>>;
  analysisType: IAnalysisType;
  availableMetrics: Array<AvailableMetric>;
  availableDimensions: Array<AvailableDimension>;
  lagoonQuery: ILagoonQuery;
  exploration: IExploration;
  scale?: Scale;
  height?: number;
  width?: number;
  sql?: AsyncData<string>;
  trace?: AsyncData<IQueryTraceResult>;
  disableDrills?: boolean;
  reportId?: string;
  renderOutsideViewport?: boolean;
  externalWorker?: Remote<CubeJSPivotWorker>;
  setRender?: (meta: IAsyncMeta) => void;
  onChartOptionSave?: (chartOptions: ChartOption) => void;
  onForecastChange?: (forecast: IForecastConfig) => void;
  onWarningsChange?: (warnings: string[]) => void;
  children?: React.ReactNode;
}

type Scale = "time" | "ordinal";

enum RenderMetaMode {
  mainData,
  additionalData,
}

// Used to hold together many SerieData
interface DatasetItem {
  // Value on the X axis
  x: string;
  // One entry per SerieData
  // The key is following the format `xxxx.current` or `xxxx.previous`
  // This is to help reconcilating series that are linked together through a time comparaison
  [key: string]: string | number;
}

interface IState {
  // hasAlreadyFetch: boolean;
  meta: IAsyncMeta;
  additionalResultsMeta: AsyncData<Meta | undefined>;
  drillQuery?: Query;
  sqlQueryVisible?: boolean;
  traceQueryVisible?: boolean;
  warnings: string[];
}

export type IAsyncMeta = AsyncData<Meta>;

export interface Meta {
  series: ISerieData[];
  pivotConfig: PivotConfig;
  columns: TableColumn[];
  tableRows: Array<{
    [key: string]: string | number | boolean;
  }>;
  rowCount: number;
}

const HEIGHT = 300;

const loadingIcon = <LoadingOutlined style={{ fontSize: 24 }} spin={true} />;

type Props = IChartProps & InjectedOrgProps;

class Chart extends React.Component<Props, IState> {
  originalWorker: Worker;
  worker: Remote<CubeJSPivotWorker>;
  id: string = cuid();
  headerId: string = cuid();

  constructor(props: Props) {
    super(props);
    if (!this.props.externalWorker) {
      this.originalWorker = new Worker(
        new URL("../../../../../worker/main.worker", import.meta.url),
        {
          name: "chart-worker",
          type: "module",
        }
      );
      this.worker = wrap<CubeJSPivotWorker>(this.originalWorker);
    }
    this.state = {
      meta: { status: "initial" },
      additionalResultsMeta: { status: "initial" },
      sqlQueryVisible: false,
      traceQueryVisible: false,
      warnings: [],
    };
  }

  componentDidMount(): void {
    if (
      this.props.data.status === "success" &&
      this.state.meta.status === "initial"
    ) {
      this.renderMeta(
        this.props.data.data,
        this.props.analysisType,
        RenderMetaMode.mainData
      );
    }
    if (
      this.props.additionalResults.status === "success" &&
      this.state.additionalResultsMeta.status === "initial"
    ) {
      this.renderMeta(
        this.props.additionalResults.data,
        this.props.analysisType,
        RenderMetaMode.additionalData
      );
    }
  }

  componentWillUnmount(): void {
    if (this.worker) {
      this.worker[releaseProxy]();
    }
    if (this.originalWorker) {
      this.originalWorker.terminate();
    }
  }

  setWarnings(warnings: string[]) {
    this.setState({ warnings: warnings });
    if (this.props.onWarningsChange) this.props.onWarningsChange(warnings);
  }

  componentDidUpdate(prevProps: Props, prevState: IState) {
    const { data, currentChartType, lagoonQuery, additionalResults } =
      this.props;
    const {
      data: prevData,
      currentChartType: prevCurrentChartType,
      lagoonQuery: prevLagoonQuery,
      additionalResults: prevAdditionalResults,
    } = prevProps;

    const { chartOptions, ...currentQuery } = lagoonQuery;
    const { chartOptions: prevChartOptions, ...prevQuery } = prevLagoonQuery;

    if (!_.isEqual(prevState.meta, this.state.meta)) {
      this.props.setRender?.(this.state.meta);
    }

    if (data.status === "loading" && this.state.warnings.length > 0) {
      this.setWarnings([]);
    }

    if (data.status === "error" && prevData.status !== "error") {
      this.setState({ meta: { status: "error", error: data.error } });
    }

    if (
      additionalResults.status === "error" &&
      prevAdditionalResults.status !== "error"
    ) {
      this.setState({
        additionalResultsMeta: {
          status: "error",
          error: additionalResults.error,
        },
      });
    }

    if (data.status === "loading" && prevData.status !== "loading") {
      this.setState({ meta: { status: "loading" } });
    }

    if (
      additionalResults.status === "loading" &&
      prevAdditionalResults.status !== "loading"
    ) {
      this.setState({ additionalResultsMeta: { status: "loading" } });
    }

    if (data.status === "success" && prevData.status === "loading") {
      this.renderMeta(
        data.data,
        this.props.analysisType,
        RenderMetaMode.mainData
      );
    }
    if (
      data.status === "success" &&
      (!_.isEqual(currentQuery, prevQuery) ||
        currentChartType !== prevCurrentChartType)
    ) {
      this.renderMeta(
        data.data,
        this.props.analysisType,
        RenderMetaMode.mainData
      );
    }

    if (
      additionalResults.status === "success" &&
      prevAdditionalResults.status !== "success" &&
      additionalResults.data
    ) {
      this.renderMeta(
        additionalResults.data,
        this.props.analysisType,
        RenderMetaMode.additionalData
      );
    }

    if (
      additionalResults.status === "success" &&
      !additionalResults.data &&
      this.state.additionalResultsMeta.status !== "success"
    ) {
      this.setState({
        additionalResultsMeta: { status: "success", data: undefined },
      });
    }

    if (
      additionalResults.status === "success" &&
      (!_.isEqual(currentQuery, prevQuery) ||
        currentChartType !== prevCurrentChartType) &&
      additionalResults.data
    ) {
      this.renderMeta(
        additionalResults.data,
        this.props.analysisType,
        RenderMetaMode.additionalData
      );
    }
  }

  buildDataset = (data: ISerieData[]): Array<DatasetItem> => {
    const keys = new Set<string>();
    const map = data.reduce<{
      [x: string]: { [newSerieKey: string]: string | number };
    }>((acc, val) => {
      val.data.forEach((v) => {
        // In order to reconcile easily the "current" and the "previous" series when doing time comparaison
        // we create a new key that is sharing the same prefix for series that are comparing to each other
        let newKey = ``;
        if (val.previous) {
          const metricKey = val.metric.key;
          const currentSerieData = data.find(
            (serieData) =>
              serieData.metric.key === metricKey && serieData.previous === false
          );
          newKey = `${currentSerieData?.key}.previous`;
        } else {
          newKey = `${val.key}.current`;
        }

        acc[v.x] = {
          ...acc[v.x],
          [newKey]: v.value === null ? 0 : v.value,
        };
        keys.add(v.x);
        return acc;
      });

      if (val.prediction) {
        val.prediction.forEach((v) => {
          keys.add(v.x);
          acc[v.x] = {
            ...acc[v.x],
            [`${val.key}.current`]: v.value,
            [`${val.key}.current_high`]: v.high,
            [`${val.key}.current_low`]: v.low,
          };
        });
      }

      return {
        ...acc,
      };
    }, {});
    return Array.from(keys).map((k) => ({
      ...map[k],
      x: k,
      x2: data?.find((d) => d.previous)?.data?.find((d) => d.x === k)?.x2,
    }));
  };

  renderMeta = async (
    resultSet: ResultSet,
    analysisType: IAnalysisType,
    mode: RenderMetaMode
  ) => {
    try {
      if (mode === RenderMetaMode.additionalData) {
        this.setState({ additionalResultsMeta: { status: "loading" } });
      } else {
        this.setState({ meta: { status: "loading" } });
      }

      const serailizedResultSet = resultSet.serialize();

      let dateRange: IWlyDatePickerValue =
        convertWlyDatePickerInputValueToValue(this.props.lagoonQuery.dateRange);

      if (dateRange.type === "between") {
        dateRange = {
          ...dateRange,
          between: dateRange.between.map((d) => moment(d).toISOString()) as any,
        };
      }
      if (dateRange.type === "on") {
        dateRange = {
          ...dateRange,
          on: moment(dateRange.on).toISOString() as any,
        };
      }

      if (dateRange.type === "advanced") {
        dateRange = {
          ...dateRange,
          startPeriod: {
            ...dateRange.startPeriod,
            fixedDate: moment(
              dateRange.startPeriod.fixedDate
            ).toISOString() as any,
          },
          endPeriod: {
            ...dateRange.endPeriod,
            fixedDate: moment(
              dateRange.endPeriod.fixedDate
            ).toISOString() as any,
          },
        };
      }

      const worker = this.props.externalWorker
        ? this.props.externalWorker
        : this.worker;

      const formattedLagoonQuery: ILagoonQuery = {
        ...this.props.lagoonQuery,
        dateRange: dateRange,
        chartType: this.props.currentChartType,
      };

      let allSerieData = await worker.generateAllSerieData(
        serailizedResultSet,
        formattedLagoonQuery,
        analysisType,
        this.props.currentChartType,
        this.props.availableMetrics,
        this.props.availableDimensions,
        formattedLagoonQuery.pivotDimensions
      );

      const pivotC = await worker.getPivotConfig(
        proxy(resultSet),
        this.props.analysisType,
        this.props.currentChartType,
        formattedLagoonQuery.pivotDimensions
      );
      const columns = await worker.getTableColumns(serailizedResultSet, pivotC);

      let tableRows = await worker.getTableRows(
        serailizedResultSet,
        pivotC,
        analysisType
      );

      let rowCount = (
        await worker.getTableRows(serailizedResultSet, {}, analysisType)
      ).length;

      if (
        formattedLagoonQuery.forecast &&
        formattedLagoonQuery.forecast.enabled &&
        formattedLagoonQuery.selectedTime
      ) {
        allSerieData = await worker.predictSeries(allSerieData);
        tableRows = await worker.predictTableRows(
          tableRows,
          columns.find((c) =>
            c.key.startsWith(formattedLagoonQuery.selectedTime)
          )?.key,
          columns
            .filter((c) => !c.key.startsWith(formattedLagoonQuery.selectedTime))
            .map((c) => c.key)
        );
      }

      if (mode === RenderMetaMode.additionalData) {
        this.setState({
          additionalResultsMeta: {
            status: "success",
            data: {
              series: allSerieData,
              pivotConfig: pivotC,
              columns: columns,
              tableRows: tableRows,
              rowCount: rowCount,
            },
          },
        });
      } else {
        this.setState({
          meta: {
            status: "success",
            data: {
              series: allSerieData,
              pivotConfig: pivotC,
              columns: columns,
              tableRows: tableRows,
              rowCount: rowCount,
            },
          },
        });
      }
    } catch (err) {
      if (mode === RenderMetaMode.additionalData) {
        this.setState({
          additionalResultsMeta: {
            status: "error",
            error: err as any,
          },
        });
      } else {
        this.setState({
          meta: {
            status: "error",
            error: err as any,
          },
        });
      }
    }
  };

  renderChart = (
    chartType: ChartType,
    data: AsyncData<ResultSet<any>>,
    analysisType: IAnalysisType,
    scale?: Scale,
    pivotDimensions?: string[]
  ) => {
    const { meta, additionalResultsMeta } = this.state;
    const {
      lagoonQuery,
      additionalResults,
      width,
      height,
      disableDrills,
      org,
      user: { locale },
    } = this.props;

    const chartHeight = height ? height : HEIGHT;

    if (data.status === "error") {
      return (
        <div style={{ height: chartHeight }} className="empty-state">
          {data.error.message}
        </div>
      );
    }

    if (additionalResults.status === "error") {
      return (
        <div style={{ height: chartHeight }} className="empty-state">
          {additionalResults.error.message}
        </div>
      );
    }

    if (
      data.status === "initial" ||
      data.status === "loading" ||
      meta.status === "initial" ||
      meta.status === "loading" ||
      additionalResultsMeta.status === "initial" ||
      additionalResultsMeta.status === "loading"
    ) {
      return (
        <div style={{ height: chartHeight }} className="empty-state">
          {data.status === "initial"
            ? "Please run a query to view a chart"
            : ""}
        </div>
      );
    }

    if (meta.status === "error") {
      return (
        <div style={{ height: chartHeight }} className="empty-state">
          {meta.error.message}
        </div>
      );
    }

    if (additionalResultsMeta.status === "error") {
      return (
        <div style={{ height: chartHeight }} className="empty-state">
          {additionalResultsMeta.error.message}
        </div>
      );
    }

    if (
      data.status === "success" &&
      meta.status === "success" &&
      (meta.data.tableRows.length === 0 || meta.data.series.length === 0)
    ) {
      return (
        <div
          className="empty-state"
          style={{ height: chartHeight, position: "relative" }}
        >
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translateX(-50%) translateY(-50%) ",
            }}
          >
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          </div>
        </div>
      );
    }

    const currentRowLimit = lagoonQuery.limit || DEFAULT_ROW_LIMIT;
    const currentRowNumber = meta.data.rowCount;

    if (currentRowNumber >= currentRowLimit && !lagoonQuery.limit) {
      const warning =
        "Row limit reached : only a subset of data is currently displayed. Update the query row limit of change your query.";
      const warnings = this.state.warnings;
      if (warnings.indexOf(warning) === -1) {
        warnings.push(warning);
        this.setWarnings(warnings);
      }
    }

    const isDisabled = () => {
      const chartItemDef = ChartDefinition[chartType];
      const query = this.props.lagoonQuery;
      if (
        typeof chartItemDef.minDimensions !== "undefined" &&
        chartItemDef.minDimensions > query.selectedDimensions.length
      ) {
        return true;
      }
      if (
        typeof chartItemDef.maxDimensions !== "undefined" &&
        chartItemDef.maxDimensions < query.selectedDimensions.length
      ) {
        return true;
      }
      if (
        typeof chartItemDef.minMetrics !== "undefined" &&
        chartItemDef.minMetrics > query.selectedMeasures.length
      ) {
        return true;
      }
      if (
        typeof chartItemDef.maxMetrics !== "undefined" &&
        chartItemDef.maxMetrics < query.selectedMeasures.length
      ) {
        return true;
      }
      return false;
    };

    const chartItemDef = ChartDefinition[chartType];

    if (isDisabled()) {
      const minMetrics =
        typeof chartItemDef.minMetrics !== "undefined"
          ? `at least ${chartItemDef.minMetrics} metrics`
          : "";
      const maxmetrics =
        typeof chartItemDef.maxMetrics !== "undefined"
          ? `at most ${chartItemDef.maxMetrics} metrics`
          : "";
      const minDimensions =
        typeof chartItemDef.minDimensions !== "undefined"
          ? `at least ${chartItemDef.minDimensions} dimensions`
          : "";
      const maxDimensions =
        typeof chartItemDef.maxDimensions !== "undefined"
          ? `at most ${chartItemDef.maxDimensions} dimensions`
          : "";
      const sentence = [minMetrics, maxmetrics, minDimensions, maxDimensions]
        .filter((d) => d.length > 0)
        .reduce((acc, v, i, s) => {
          return `${acc}${
            acc.length !== 0 ? (i === s.length - 1 ? " and " : ", ") : ""
          }${v}`;
        }, "");
      return (
        <div className="empty-state" style={{ height: chartHeight }}>
          In order to use this viz, you need to have {sentence}
        </div>
      );
    }
    const reason = chartItemDef.canDisplay(data.data, lagoonQuery);
    if (!!reason) {
      return (
        <div className="empty-state" style={{ height: chartHeight }}>
          {reason}
        </div>
      );
    }

    const dataset = this.buildDataset(meta.data.series);
    const additonalResultsDataset = additionalResultsMeta.data
      ? this.buildDataset(additionalResultsMeta.data.series)
      : undefined;

    const getNewKey = (serieData: ISerieData): string => {
      // When working with date comparaison, we have to reconcile 2 series: "current" and "previous" serie,
      // to do so, we rewrite the key of the "previous" serie to start with the same key of the "current" serie
      let newKey = ``;
      if (serieData.previous) {
        const metricKey = serieData.metric.key;
        const currentSerieData = meta.data.series.find((serieData) => {
          return (
            serieData.metric.key === metricKey && serieData.previous === false
          );
        });
        newKey = `${currentSerieData?.key}.previous`;
      } else {
        newKey = `${serieData.key}.current`;
      }
      return newKey;
    };

    const defaultPalette = getSelectedPalette(org, {
      type: "PALETTE_SELECTION",
      collection_name: "default",
      palette_type: "discrete",
      index: 0,
    });

    const formattedChartOptions = formatChartOptions(
      org,
      this.props.lagoonQuery.chartOptions
    );

    if (chartType === "line") {
      const options = formattedChartOptions;
      const selectedPalette =
        options && options.palette
          ? getSelectedPalette(org, options.palette)
          : defaultPalette;
      const palette = new PaletteGenerator({
        ...selectedPalette,
        numberOfColors: meta.data.series.length,
      });
      const xAxisLabel = options?.axis?.bottom?.label;
      return (
        <LineChart
          width={width}
          height={chartHeight}
          data={dataset}
          locale={locale}
          onDrill={
            disableDrills ? undefined : (q) => this.setState({ drillQuery: q })
          }
          config={{
            x: { key: "x", label: xAxisLabel },
            y: meta.data.series.flatMap((d, i) => {
              const newKey = getNewKey(d);
              const chartOptions = formattedChartOptions;
              const label =
                options &&
                options.series &&
                options.series[d.key] &&
                options.series[d.key].label
                  ? options.series[d.key].label
                  : d.label;
              const axis =
                chartOptions &&
                chartOptions.series &&
                chartOptions.series[d.key] &&
                chartOptions.series[d.key].axis
                  ? chartOptions.series[d.key].axis
                  : "left";
              const color =
                chartOptions &&
                chartOptions.series &&
                chartOptions.series[d.key] &&
                chartOptions.series[d.key].color
                  ? chartOptions.series[d.key].color
                  : palette.getColorAtIndex(i);
              const type =
                chartOptions &&
                chartOptions.series &&
                chartOptions.series[d.key] &&
                chartOptions.series[d.key].type
                  ? chartOptions.series[d.key].type
                  : undefined;
              return {
                key: newKey,
                label: label,
                color: color,
                axis: axis,
                type: type,
                dashed: d.previous,
                prediction: d.prediction
                  ? {
                      previousStartValue:
                        dataset[dataset.length - 1 - d.prediction.length].x,
                      startValue:
                        dataset[dataset.length - d.prediction.length].x,
                      getHighKey: () => `${newKey}_high`,
                      getLowKey: () => `${newKey}_low`,
                    }
                  : undefined,
                canDrill: (xKey: string): Query | null => {
                  return canDrill(
                    data.data,
                    d,
                    xKey,
                    analysisType,
                    lagoonQuery
                  );
                },
                formatter: {
                  prefix: d.metric ? d.metric.formatter.prefix : "",
                  suffix: d.metric ? d.metric.formatter.suffix : "",
                  format: d.metric ? d.metric.formatter.format : "NUMBER",
                  customFormatting: d.metric
                    ? d.metric.formatter.customFormatting
                    : undefined,
                },
              };
            }),
            timeGranularity: lagoonQuery.selectedGranularity,
            stacking: !!pivotDimensions,
            showLabels:
              formattedChartOptions && formattedChartOptions.label
                ? formattedChartOptions.label
                : false,
          }}
          tinyLegend={meta.data.series.length > 12}
          scale={scale}
          chartOptions={formattedChartOptions}
        />
      );
    } else if (chartType === "stacked-area") {
      const options = formattedChartOptions;
      const selectedPalette =
        options && options.palette
          ? getSelectedPalette(org, options.palette)
          : defaultPalette;
      const palette = new PaletteGenerator({
        ...selectedPalette,
        numberOfColors: meta.data.series.length,
      });
      const xAxisLabel = options?.axis?.bottom?.label;
      return (
        <StackedAreaChart
          width={width}
          locale={locale}
          height={chartHeight}
          data={dataset.map((d) => {
            // we modify the key to keep only the dimension value
            // we replace null with ∅
            const keys = Object.keys(d);
            const line = {};
            keys.forEach((k) => {
              let newKey = k;
              if (k !== "x" && k !== "x2") {
                newKey = k.indexOf(",") > -1 ? k.split(",")?.[0] : "∅";
              }
              line[newKey] = d[k];
            });
            return line;
          })}
          onDrill={
            disableDrills ? undefined : (q) => this.setState({ drillQuery: q })
          }
          config={{
            x: { key: "x", label: xAxisLabel },
            y: meta.data.series.flatMap((d, i) => {
              // we modify the key to keep only the dimension value
              // we replace null with ∅
              const key = d.key.indexOf(",") > -1 ? d.key.split(",")?.[0] : "∅";
              const chartOptions = formattedChartOptions;
              const label = options?.series?.[key]?.label ?? key;
              const color =
                chartOptions?.series?.[key]?.color ??
                palette.getColorAtIndex(i);
              return {
                key: key,
                label: label,
                color: color,
                canDrill: (xKey: string): Query | null => {
                  return canDrill(
                    data.data,
                    d,
                    xKey,
                    analysisType,
                    lagoonQuery
                  );
                },
                formatter: {
                  prefix: d.metric ? d.metric.formatter.prefix : "",
                  suffix: d.metric ? d.metric.formatter.suffix : "",
                  format: d.metric ? d.metric.formatter.format : "NUMBER",
                  customFormatting: d.metric
                    ? d.metric.formatter.customFormatting
                    : undefined,
                },
              };
            }),
          }}
          chartOptions={formattedChartOptions}
        />
      );
    } else if (chartType === "calendar") {
      return (
        <CalendarChart
          width={width}
          locale={locale}
          height={chartHeight}
          data={dataset}
          onDrill={
            disableDrills ? undefined : (q) => this.setState({ drillQuery: q })
          }
          config={
            meta.data.series.flatMap((d, i) => {
              const newKey = getNewKey(d);
              const label =
                formattedChartOptions &&
                formattedChartOptions.series &&
                formattedChartOptions.series[d.key] &&
                formattedChartOptions.series[d.key].label
                  ? formattedChartOptions.series[d.key].label
                  : d.label;
              return {
                key: newKey,
                label: label,
                canDrill: (xKey: string): Query | null => {
                  return canDrill(
                    data.data,
                    d,
                    xKey,
                    analysisType,
                    lagoonQuery
                  );
                },
                formatter: {
                  prefix: d.metric ? d.metric.formatter.prefix : "",
                  suffix: d.metric ? d.metric.formatter.suffix : "",
                  format: d.metric ? d.metric.formatter.format : "NUMBER",
                  customFormatting: d.metric
                    ? d.metric.formatter.customFormatting
                    : undefined,
                },
              };
            })[0]
          }
          chartOptions={formattedChartOptions}
          defaultCollection={getDefaultCollection(this.props.org)}
        />
      );
    } else if (chartType === "bar" || chartType === "bar-horizontal") {
      const options = formattedChartOptions;
      const selectedPalette =
        options && options.palette
          ? getSelectedPalette(org, options.palette)
          : defaultPalette;
      const palette = new PaletteGenerator({
        ...selectedPalette,
        numberOfColors: meta.data.series.length,
      });
      const xAxisLabel = options?.axis?.bottom?.label;
      return (
        <BarChart
          width={width}
          locale={locale}
          height={chartHeight}
          data={dataset}
          config={{
            x: { key: "x", label: xAxisLabel },
            horizontal: chartType === "bar-horizontal",
            y: meta.data.series.map((d, i) => {
              const newKey = getNewKey(d);
              const label =
                options &&
                options.series &&
                options.series[d.key] &&
                options.series[d.key].label
                  ? options.series[d.key].label
                  : d.label;
              const chartOptions = formattedChartOptions;
              const color =
                chartOptions &&
                chartOptions.series &&
                chartOptions.series[d.key] &&
                chartOptions.series[d.key].color
                  ? chartOptions.series[d.key].color
                  : palette.getColorAtIndex(i);
              const axis =
                chartOptions &&
                chartOptions.series &&
                chartOptions.series[d.key] &&
                chartOptions.series[d.key].axis
                  ? chartOptions.series[d.key].axis
                  : "left";
              const type =
                chartOptions &&
                chartOptions.series &&
                chartOptions.series[d.key] &&
                chartOptions.series[d.key].type
                  ? chartOptions.series[d.key].type
                  : undefined;
              return {
                key: newKey,
                label: label,
                color: color,
                dashed: false,
                axis: axis,
                type: type,
                prediction: d.prediction
                  ? {
                      previousStartValue:
                        dataset[dataset.length - 1 - d.prediction.length].x,
                      startValue:
                        dataset[dataset.length - d.prediction.length].x,
                      getHighKey: () => `${newKey}_high`,
                      getLowKey: () => `${newKey}_low`,
                    }
                  : undefined,
                canDrill: (xKey: string, i): Query | null => {
                  return canDrill(
                    data.data,
                    d,
                    xKey,
                    analysisType,
                    lagoonQuery
                  );
                },
                formatter: {
                  prefix: d.metric ? d.metric.formatter.prefix : "",
                  suffix: d.metric ? d.metric.formatter.suffix : "",
                  format: d.metric ? d.metric.formatter.format : "NUMBER",
                  customFormatting: d.metric
                    ? d.metric.formatter.customFormatting
                    : undefined,
                },
              };
            }),
            timeGranularity: lagoonQuery.selectedGranularity,
            comparisonPeriod: lagoonQuery.comparison,
            stacking:
              formattedChartOptions && formattedChartOptions.unstack === true
                ? !formattedChartOptions.unstack
                : !!pivotDimensions,
            percent: !!(formattedChartOptions && formattedChartOptions.percent),
            showLabels:
              formattedChartOptions && formattedChartOptions.label === true
                ? formattedChartOptions.label
                : false,
          }}
          tinyLegend={meta.data.series.length > 12}
          scale={scale}
          onDrill={
            disableDrills
              ? undefined
              : (query) => {
                  this.setState({ drillQuery: query });
                }
          }
          chartOptions={formattedChartOptions}
        />
      );
    } else if (chartType === "stacked-barchart-timeserie") {
      const options = formattedChartOptions;
      const selectedPalette =
        options && options.palette
          ? getSelectedPalette(org, options.palette)
          : defaultPalette;
      const palette = new PaletteGenerator({
        ...selectedPalette,
        numberOfColors: meta.data.series.length,
      });
      const xAxisLabel = options?.axis?.bottom?.label;

      return (
        <StackedBarChartTimeserie
          width={width}
          locale={locale}
          height={chartHeight}
          data={dataset}
          config={{
            x: { key: "x", label: xAxisLabel },
            y: meta.data.series.map((serie, i) => {
              // we modify the key to keep only the dimension value
              // we replace null with ∅
              const key = getNewKey(serie);
              const chartOptions = formattedChartOptions;
              const label =
                options?.series?.[serie.key]?.label ??
                serie.dimensions.map((d) => d.value ?? "∅").join(", ");
              const color =
                chartOptions?.series?.[serie.key]?.color ??
                palette.getColorAtIndex(i);
              return {
                key: key,
                label: label,
                color: color,
                canDrill: (xKey: string, i): Query | null => {
                  return canDrill(
                    data.data,
                    serie,
                    xKey,
                    analysisType,
                    lagoonQuery
                  );
                },
                formatter: {
                  prefix: serie.metric ? serie.metric.formatter.prefix : "",
                  suffix: serie.metric ? serie.metric.formatter.suffix : "",
                  format: serie.metric
                    ? serie.metric.formatter.format
                    : "NUMBER",
                  customFormatting: serie.metric
                    ? serie.metric.formatter.customFormatting
                    : undefined,
                },
              };
            }),
            percent: !!formattedChartOptions?.percent,
            showLabels: !!formattedChartOptions?.label,
          }}
          tinyLegend={meta.data.series.length > 12}
          scale={"time"}
          onDrill={
            disableDrills
              ? undefined
              : (query) => {
                  this.setState({ drillQuery: query });
                }
          }
          chartOptions={formattedChartOptions}
        />
      );
    } else if (chartType === "activity") {
      const options = formattedChartOptions;
      const selectedPalette =
        options && options.palette
          ? getSelectedPalette(org, options.palette)
          : defaultPalette;
      const palette = new PaletteGenerator({
        ...selectedPalette,
        numberOfColors: meta.data.series.length,
      });

      const xAxisLabel = options?.axis?.bottom?.label;
      const yAxisLabel = options?.axis?.left?.label;

      return (
        <ActivityChart
          width={width}
          locale={locale}
          height={chartHeight}
          data={dataset}
          config={{
            x: {
              key: "x",
              label: xAxisLabel,
              timeGranularity: lagoonQuery.selectedGranularity,
              formatter: {
                format: {
                  type: "moment",
                  value: formattedChartOptions?.axis?.bottom?.timeFormat
                    ? formattedChartOptions?.axis?.bottom?.timeFormat
                    : lagoonQuery.selectedGranularity,
                },
              },
            },
            y: {
              series: meta.data.series.map((serie, i) => {
                // we modify the key to keep only the dimension value
                // we replace null with ∅
                const key = getNewKey(serie);
                const chartOptions = formattedChartOptions;
                const label =
                  options?.series?.[serie.key]?.label ??
                  serie.dimensions.map((d) => d.value ?? "∅").join(", ");
                const color =
                  chartOptions?.series?.[serie.key]?.color ??
                  palette.getColorAtIndex(i);
                return {
                  key: key,
                  label: label,
                  color: color,
                  canDrill: (xKey: string, i): Query | null => {
                    return canDrill(
                      data.data,
                      serie,
                      xKey,
                      analysisType,
                      lagoonQuery
                    );
                  },
                };
              }),
              label: yAxisLabel,
            },
            z: {
              label: meta.data.series[0]?.metric.label,
              formatter: meta.data.series[0]?.metric.formatter,
            },
            showLabels: !!formattedChartOptions?.label,
          }}
          onDrill={
            disableDrills
              ? undefined
              : (query) => {
                  this.setState({ drillQuery: query });
                }
          }
        />
      );
    } else if (chartType === "retention") {
      const cohortSize = this.props.availableMetrics.find(
        (am) => am.key === lagoonQuery.selectedMeasures[1]
      );

      const cohortValue = this.props.availableMetrics.find(
        (am) => am.key === lagoonQuery.selectedMeasures[0]
      );

      const cohortLabel = this.props.availableDimensions.find(
        (am) => am.key === lagoonQuery.selectedDimensions[0]
      );

      const periodValues: string[] = [];
      const periodKey = lagoonQuery.selectedDimensions[1];
      const labelKey = lagoonQuery.selectedDimensions[0];
      const valueKey = lagoonQuery.selectedMeasures[0];
      const sizeKey = lagoonQuery.selectedMeasures[1];

      const retentionData = dataset.map((d) => {
        const periodArray = (additonalResultsDataset ?? [])
          .filter((ad) => ad.x.startsWith(`${d.x},`))
          .map((ad) => {
            const periodValue = ad.x.split(",")[1];
            if (!periodValues.includes(periodValue)) {
              periodValues.push(periodValue);
            }
            return [`${periodValue},${valueKey}`, ad[`${valueKey}.current`]];
          });
        const periods = Object.fromEntries(periodArray);
        return {
          [labelKey]: d.x,
          [sizeKey]: d[`${sizeKey}.current`],
          ...periods,
        };
      });

      const defaultPaletteCollection = getDefaultCollection(org);
      const defaultPalette =
        defaultPaletteCollection.diverging[0] ??
        defaultPaletteCollection.sequential[0];

      let palette: PaletteGenerator | undefined = undefined;

      if (formattedChartOptions?.["palette-continue"]) {
        palette = new PaletteGenerator({
          ...getSelectedPalette(org, formattedChartOptions["palette-continue"]),
          numberOfColors: 101,
        });
      } else if (defaultPalette) {
        palette = new PaletteGenerator({
          ...defaultPalette,
          numberOfColors: 101,
        });
      }

      const displayPercent =
        typeof formattedChartOptions?.["retention-percent"] === "boolean"
          ? formattedChartOptions?.["retention-percent"]
          : true;
      const showColors =
        typeof formattedChartOptions?.["retention-colors"] === "boolean"
          ? formattedChartOptions?.["retention-colors"]
          : true;
      const showTotal =
        typeof formattedChartOptions?.["retention-total"] === "boolean"
          ? formattedChartOptions?.["retention-total"]
          : true;

      return (
        <RetentionChart
          data={retentionData}
          config={{
            cohortLabel: {
              key: labelKey,
              label: cohortLabel?.label ?? labelKey,
            },
            cohortSize: {
              key: sizeKey,
              label: cohortSize?.label ?? sizeKey,
              formatter: cohortSize?.formatter,
            },
            cohortValue: {
              key: valueKey,
              periods: periodValues,
              formatter: cohortValue?.formatter,
            },
            palette: palette,
            displayPercent: displayPercent,
            showColors: showColors,
            showTotal: showTotal,
          }}
          locale={locale}
          onDrill={(options) => {
            const { cohortLabel, periodValue } = options;

            if (disableDrills) return;

            const q = data.data.drillDown({
              xValues: [cohortLabel],
              yValues: [],
            });

            if (q?.filters instanceof Array) {
              q.filters.push({
                member: periodKey,
                operator: "equals",
                values: [periodValue],
              });
            }

            if (q) {
              this.setState({ drillQuery: q });
            }
          }}
        />
      );
    } else if (chartType === "scatter") {
      const query = this.props.lagoonQuery;
      const options = formattedChartOptions;
      const selectedPalette =
        options && options.palette
          ? getSelectedPalette(org, options.palette)
          : defaultPalette;
      const palette = new PaletteGenerator({
        ...selectedPalette,
        numberOfColors: 1,
      });
      const met0 = this.props.availableMetrics.find(
        (am) => am.key === query.selectedMeasures[0]
      );
      const met1 = this.props.availableMetrics.find(
        (am) => am.key === query.selectedMeasures[1]
      );
      const dim1 = this.props.availableDimensions.find(
        (am) => am.key === query.selectedDimensions[0]
      );
      const xAxisLabel = options?.axis?.bottom?.label;
      const yAxisLabel = options?.axis?.left?.label;
      return (
        <ScatterChart
          width={width}
          height={chartHeight}
          locale={locale}
          data={meta.data.tableRows}
          config={{
            x: {
              key: query.selectedMeasures[0],
              label: xAxisLabel
                ? xAxisLabel
                : met0 && met0.label
                ? met0.label
                : query.selectedMeasures[0],
              formatter: {
                prefix:
                  met0 && met0.formatter.prefix ? met0.formatter.prefix : "",
                suffix:
                  met0 && met0.formatter.suffix ? met0.formatter.suffix : "",
                format:
                  met0 && met0.formatter.format
                    ? met0.formatter.format
                    : "NUMBER",
                customFormatting:
                  met0 && met1.formatter.customFormatting
                    ? met0.formatter.customFormatting
                    : undefined,
              },
            },
            nameKey: query.selectedDimensions[0],
            y: [
              {
                key: query.selectedMeasures[1],
                label: yAxisLabel
                  ? yAxisLabel
                  : met1 && met1.label
                  ? met1.label
                  : undefined,
                serieLabel:
                  dim1 && dim1.label ? dim1.label : query.selectedDimensions[1],
                color:
                  options &&
                  options?.series &&
                  options?.series[query.selectedMeasures[1]] &&
                  options?.series[query.selectedMeasures[1]].color
                    ? options?.series[query.selectedMeasures[1]].color
                    : palette.getColorAtIndex(0),
                dashed: false,
                formatter: {
                  prefix:
                    met1 && met1.formatter.prefix ? met1.formatter.prefix : "",
                  suffix:
                    met1 && met1.formatter.suffix ? met1.formatter.suffix : "",
                  format:
                    met1 && met1.formatter.format
                      ? met1.formatter.format
                      : "NUMBER",
                  customFormatting:
                    met1 && met1.formatter.customFormatting
                      ? met1.formatter.customFormatting
                      : undefined,
                },
                canDrill: () => null,
              },
            ],
            showLabels: formattedChartOptions
              ? formattedChartOptions.label
              : false,
          }}
          tinyLegend={meta.data.series.length > 12}
          onDrill={
            disableDrills
              ? undefined
              : (query) => {
                  this.setState({ drillQuery: query });
                }
          }
          chartOptions={formattedChartOptions}
        />
      );
    } else if (chartType === "pie") {
      const query = this.props.lagoonQuery;
      const met0 = this.props.availableMetrics.find(
        (am) => am.key === query.selectedMeasures[0]
      );
      const serie = meta.data.series[0];
      const selectedPalette =
        formattedChartOptions && formattedChartOptions.palette
          ? getSelectedPalette(org, formattedChartOptions.palette)
          : defaultPalette;
      const palette = new PaletteGenerator({
        ...selectedPalette,
        numberOfColors: meta.data.tableRows.length,
      });
      return (
        <PieChart
          width={width}
          locale={locale}
          height={chartHeight}
          data={meta.data.tableRows}
          config={{
            y: {
              key: query.selectedMeasures[0],
              label:
                met0 && met0.label ? met0.label : query.selectedMeasures[0],
              formatter: {
                prefix:
                  met0 && met0.formatter.prefix ? met0.formatter.prefix : "",
                suffix:
                  met0 && met0.formatter.suffix ? met0.formatter.suffix : "",
                format:
                  met0 && met0.formatter.format
                    ? met0.formatter.format
                    : "NUMBER",
                customFormatting:
                  met0 && met0.formatter.customFormatting
                    ? met0.formatter.customFormatting
                    : undefined,
              },
              canDrill: (xKey: string) => {
                if (this.props.data.status === "success") {
                  return this.props.data.data.drillDown(
                    {
                      xValues:
                        this.props.lagoonQuery.selectedDimensions?.length > 1
                          ? xKey.split(",").map((v) => (v === "∅" ? null : v))
                          : [xKey === "∅" ? null : xKey],
                      yValues: serie.metric ? [serie.metric.key] : [],
                    },
                    meta.data.pivotConfig
                  );
                }
                return null;
              },
            },
            showLabels: formattedChartOptions
              ? formattedChartOptions.label
              : false,
            nameKey: query.selectedDimensions[0],
          }}
          tinyLegend={meta.data.series.length > 12}
          onDrill={
            disableDrills
              ? undefined
              : (query) => {
                  this.setState({ drillQuery: query });
                }
          }
          palette={palette}
          chartOptions={formattedChartOptions}
        />
      );
    } else if (chartType === "kpi") {
      // We filter out the "previous" serieData as they are already included into the dataset
      // And this Chart is using the dataset keys to display its metrics
      const selectedPalette =
        formattedChartOptions && formattedChartOptions.palette
          ? getSelectedPalette(org, formattedChartOptions.palette)
          : defaultPalette;
      const palette = new PaletteGenerator({
        ...selectedPalette,
        numberOfColors: meta.data.series.length,
      });
      const config = meta.data.series
        .filter((serieData) => {
          return serieData.previous !== true;
        })
        .map((d, i) => {
          return {
            name:
              formattedChartOptions &&
              formattedChartOptions?.series &&
              formattedChartOptions?.series[d.key] &&
              formattedChartOptions?.series[d.key].label
                ? formattedChartOptions?.series[d.key].label
                : d.label,
            conditionalFormatting:
              formattedChartOptions?.series?.[d.key]?.conditionalFormatting,
            data: {
              current: dataset[0][`${d.key}.current`] as string,
              previous: dataset[0][`${d.key}.previous`] as string,
            },
            sparkline: additonalResultsDataset
              ? additonalResultsDataset.map(
                  (data) => data[`${d.key}.current`] as number
                )
              : [],
            invertedComparison: formattedChartOptions
              ? !!formattedChartOptions["inverted-arrow"]
              : false,
            color:
              formattedChartOptions &&
              formattedChartOptions?.series &&
              formattedChartOptions?.series[d.key] &&
              formattedChartOptions?.series[d.key].color
                ? formattedChartOptions?.series[d.key].color
                : palette.getColorAtIndex(i),
            canDrill: (key: "current" | "previous") => {
              if (
                data.status === "success" &&
                data.data &&
                meta.status === "success" &&
                meta.data
              ) {
                if ((data.data as any).queryType === "compareDateRangeQuery") {
                  const queries = data.data.decompose();
                  if (key === "current") {
                    const q = queries[0].drillDown(
                      { xValues: [], yValues: d.metric ? [d.metric.key] : [] },
                      meta.data.pivotConfig
                    );
                    if (q) {
                      return {
                        ...q,
                        filters: (q.filters || []).filter((a) => {
                          return (
                            (a as BinaryFilter | UnaryFilter).member !==
                            "compareDateRange"
                          );
                        }),
                      };
                    }
                  } else {
                    return queries[1].drillDown(
                      { xValues: [], yValues: d.metric ? [d.metric.key] : [] },
                      meta.data.pivotConfig
                    );
                  }
                } else if (key === "current") {
                  return data.data.drillDown(
                    { xValues: [], yValues: d.metric ? [d.metric.key] : [] },
                    meta.data.pivotConfig
                  );
                }
              } else {
                if (this.props.data.status === "success") {
                  if (
                    (this.props.data.data as any).queryType ===
                    "compareDateRangeQuery"
                  ) {
                    const queries = this.props.data.data.decompose();
                    if (key === "current") {
                      const q = queries[0].drillDown(
                        {
                          xValues: [],
                          yValues: d.metric ? [d.metric.key] : [],
                        },
                        meta.data.pivotConfig
                      );
                      if (q) {
                        return {
                          ...q,
                          filters: (q.filters || []).filter((a) => {
                            return (
                              (a as BinaryFilter | UnaryFilter).member !==
                              "compareDateRange"
                            );
                          }),
                        };
                      }
                    } else {
                      return queries[1].drillDown(
                        {
                          xValues: [],
                          yValues: d.metric ? [d.metric.key] : [],
                        },
                        meta.data.pivotConfig
                      );
                    }
                  } else if (key === "current") {
                    return this.props.data.data.drillDown(
                      { xValues: [], yValues: d.metric ? [d.metric.key] : [] },
                      meta.data.pivotConfig
                    );
                  }
                }
              }
              return null;
            },
            comparisonPeriod: this.props.lagoonQuery.comparison,
            formatter: {
              prefix: d.metric ? d.metric.formatter.prefix : "",
              suffix: d.metric ? d.metric.formatter.suffix : "",
              format: d.metric ? d.metric.formatter.format : "NUMBER",
              customFormatting: d.metric
                ? d.metric.formatter.customFormatting
                : undefined,
            },
          };
        });
      return (
        <div style={{ height: chartHeight, width: "100%" }}>
          <Metric
            config={config}
            locale={locale}
            onDrill={
              disableDrills
                ? undefined
                : (query) => {
                    this.setState({ drillQuery: query });
                  }
            }
            width={width}
            height={height}
            chartOptions={formattedChartOptions}
          />
        </div>
      );
    } else if (chartType === "table") {
      return (
        <TableChart
          data={data.data}
          locale={locale}
          columns={meta.data.columns}
          tableRows={meta.data.tableRows}
          grandTotal={
            additionalResultsMeta.data &&
            additionalResultsMeta.data.tableRows.length
              ? additionalResultsMeta.data.tableRows[0]
              : undefined
          }
          analysisType={analysisType}
          onDrill={
            disableDrills
              ? undefined
              : (query) => {
                  this.setState({ drillQuery: query });
                }
          }
          height={chartHeight}
          isPivoting={pivotDimensions ? pivotDimensions.length > 0 : false}
          pivotConfig={meta.data.pivotConfig}
          metrics={lagoonQuery.selectedMeasures}
          availableMetrics={this.props.availableMetrics}
          chartOptions={formattedChartOptions}
          defaultCollection={getDefaultCollection(this.props.org)}
        />
      );
    } else if (chartType === "heatmap") {
      const d = meta.data.series[0];
      const query = this.props.lagoonQuery;
      const options = formattedChartOptions;
      const xAxisLabel = options?.axis?.bottom?.label;
      const yAxisLabel = options?.axis?.left?.label;
      const label =
        options &&
        options.series &&
        options.series[d.key] &&
        options.series[d.key].label
          ? options.series[d.key].label
          : d.label;
      return (
        <HeatmapChart
          width={width}
          locale={locale}
          height={chartHeight}
          data={meta.data.tableRows}
          scale={scale}
          config={{
            x1: { key: query.selectedDimensions[0], label: xAxisLabel },
            x2: { key: query.selectedDimensions[1], label: yAxisLabel },
            y: {
              key: query.selectedMeasures[0],
              label: label,
              color: getColorFromPallette(0),
              dashed: false,
              canDrill: (xKey: string, i): Query | null => {
                return canDrill(data.data, d, xKey, analysisType, lagoonQuery);
              },
              formatter: {
                prefix: d.metric ? d.metric.formatter.prefix : "",
                suffix: d.metric ? d.metric.formatter.suffix : "",
                format: d.metric ? d.metric.formatter.format : "NUMBER",
                customFormatting: d.metric
                  ? d.metric.formatter.customFormatting
                  : undefined,
              },
            },
            showLabels:
              formattedChartOptions && formattedChartOptions.label === true
                ? formattedChartOptions.label
                : false,
          }}
          tinyLegend={meta.data.series.length > 12}
          onDrill={
            disableDrills
              ? undefined
              : (query) => {
                  this.setState({ drillQuery: query });
                }
          }
          chartOptions={formattedChartOptions}
        />
      );
    } else if (chartType === "sunburst") {
      const query = this.props.lagoonQuery;
      const met0 = this.props.availableMetrics.find(
        (am) => am.key === query.selectedMeasures[0]
      );
      const selectedPalette =
        formattedChartOptions && formattedChartOptions.palette
          ? getSelectedPalette(org, formattedChartOptions.palette)
          : defaultPalette;

      let numberOfColors = 0;
      if (!isNaN(query.selectedDimensions?.length)) {
        const firstDimensionsValues = meta.data.tableRows
          .map((item) => item[query.selectedDimensions[0]])
          .filter((value, index, self) => self.indexOf(value) === index);
        numberOfColors = firstDimensionsValues.length;
      }
      const palette = new PaletteGenerator({
        ...selectedPalette,
        numberOfColors: numberOfColors,
      });
      return (
        <SunburstChart
          width={width}
          height={chartHeight}
          locale={locale}
          data={meta.data.tableRows}
          config={{
            metricKey: query.selectedMeasures[0],
            label: met0 && met0.label ? met0.label : query.selectedMeasures[0],
            formatter: {
              prefix:
                met0 && met0.formatter.prefix ? met0.formatter.prefix : "",
              suffix:
                met0 && met0.formatter.suffix ? met0.formatter.suffix : "",
              format:
                met0 && met0.formatter.format
                  ? met0.formatter.format
                  : "NUMBER",
              customFormatting:
                met0 && met0.formatter.customFormatting
                  ? met0.formatter.customFormatting
                  : undefined,
            },
            canDrill: (xKey: string[]): Query | null => {
              if (this.props.data.status === "success") {
                return this.props.data.data.drillDown(
                  {
                    xValues: xKey,
                    yValues: this.props.lagoonQuery.selectedMeasures,
                  },
                  meta.data.pivotConfig
                );
              }
              return null;
            },
            dimensionKeys: query.selectedDimensions,
          }}
          onDrill={
            disableDrills
              ? undefined
              : (query) => {
                  this.setState({ drillQuery: query });
                }
          }
          palette={palette}
          chartOptions={formattedChartOptions}
        />
      );
    } else if (chartType === "treemap") {
      const query = this.props.lagoonQuery;
      const met0 = this.props.availableMetrics.find(
        (am) => am.key === query.selectedMeasures[0]
      );
      const selectedPalette =
        formattedChartOptions && formattedChartOptions.palette
          ? getSelectedPalette(org, formattedChartOptions.palette)
          : defaultPalette;
      const palette = new PaletteGenerator({
        ...selectedPalette,
        numberOfColors: meta.data.tableRows.length,
      });
      return (
        <TreemapChart
          width={width}
          locale={locale}
          height={chartHeight}
          data={meta.data.tableRows}
          config={{
            metricKey: query.selectedMeasures[0],
            label: met0 && met0.label ? met0.label : query.selectedMeasures[0],
            formatter: {
              prefix:
                met0 && met0.formatter.prefix ? met0.formatter.prefix : "",
              suffix:
                met0 && met0.formatter.suffix ? met0.formatter.suffix : "",
              format:
                met0 && met0.formatter.format
                  ? met0.formatter.format
                  : "NUMBER",
              customFormatting:
                met0 && met0.formatter.customFormatting
                  ? met0.formatter.customFormatting
                  : undefined,
            },
            canDrill: (xKey: string, i): Query | null => {
              return canDrill(
                data.data,
                meta.data.series[0],
                xKey,
                analysisType,
                lagoonQuery
              );
            },
            dimensionKeys: query.selectedDimensions,
          }}
          onDrill={
            disableDrills
              ? undefined
              : (query) => {
                  this.setState({ drillQuery: query });
                }
          }
          palette={palette}
          chartOptions={formattedChartOptions}
        />
      );
    } else if (chartType === "waterfall") {
      const d = meta.data.series[0];
      const query = this.props.lagoonQuery;
      const options = formattedChartOptions;
      const xAxisLabel = options?.axis?.bottom?.label;
      const yAxisLabel = options?.axis?.left?.label;
      const label =
        options &&
        options.series &&
        options.series[d.key] &&
        options.series[d.key].label
          ? options.series[d.key].label
          : d.label;
      return (
        <WaterfallChart
          width={width}
          locale={locale}
          height={chartHeight}
          data={meta.data.tableRows}
          scale={scale}
          config={{
            x1: { key: query.selectedDimensions[0], label: xAxisLabel },
            x2: { key: query.selectedDimensions[1], label: yAxisLabel },
            y: {
              key: query.selectedMeasures[0],
              label: label,
              color: getColorFromPallette(0),
              dashed: false,
              canDrill: (xKey: string, i): Query | null => {
                return canDrill(data.data, d, xKey, analysisType, lagoonQuery);
              },
              formatter: {
                prefix: d.metric ? d.metric.formatter.prefix : "",
                suffix: d.metric ? d.metric.formatter.suffix : "",
                format: d.metric ? d.metric.formatter.format : "NUMBER",
                customFormatting: d.metric
                  ? d.metric.formatter.customFormatting
                  : undefined,
              },
            },
            showLabels:
              formattedChartOptions && formattedChartOptions.label === true
                ? formattedChartOptions.label
                : false,
          }}
          tinyLegend={meta.data.series.length > 12}
          onDrill={
            disableDrills
              ? undefined
              : (query) => {
                  this.setState({ drillQuery: query });
                }
          }
          chartOptions={formattedChartOptions}
        />
      );
    } else if (chartType === "waterfall-timeserie") {
      // dataset contains series broken down by dim
      // additional contains series not broken down

      if (!additonalResultsDataset) {
        throw new Error("Additional dataset required");
      }
      const valueKey = lagoonQuery.selectedMeasures[0];

      const valueStart = dataset?.[0]?.[`${valueKey}.current`];
      const valueEnd = dataset?.[dataset.length - 1]?.[`${valueKey}.current`];

      const labelStart = customFormatter(new Date(dataset[0].x), locale, {
        format: {
          type: "moment",
          value:
            formattedChartOptions?.axis?.bottom?.timeFormat ||
            lagoonQuery.selectedGranularity!,
        },
      });
      const labelEnd = customFormatter(
        new Date(dataset[dataset.length - 1].x),
        locale,
        {
          format: {
            type: "moment",
            value:
              formattedChartOptions?.axis?.bottom?.timeFormat ||
              lagoonQuery.selectedGranularity!,
          },
        }
      );

      const diffsKeys = Object.keys(additonalResultsDataset[0])
        .filter((k) => k !== "x" && k !== "x2")
        .map((k) => (k.indexOf(",") > -1 ? k.split(",")?.[0] : "∅"));

      const computeDiffs = (
        arr: {
          [key: string]: number;
        }[]
      ) => {
        const result: {
          [key: string]: number;
        } = {};

        const first = arr[0];
        const last = arr[arr.length - 1];

        Object.keys(last).forEach((key) => {
          result[key] = (+last[key] || 0) - (+first[key] || 0);
        });

        return result;
      };

      const colorBounds =
        formattedChartOptions?.["waterfall-timeserie-color-bounds"] ??
        "#A5A5A5";
      const colorDown =
        formattedChartOptions?.["waterfall-timeserie-color-down"] ?? "#EB6F6C";
      const colorUp =
        formattedChartOptions?.["waterfall-timeserie-color-up"] ?? "#96B657";

      const extractColor = (value: number) => {
        if (value === 0) return "#9B9B9B";
        if (value > 0) return colorUp;
        return colorDown;
      };

      const formattedData = additonalResultsDataset.map((d) => {
        // we modify the key to keep only the dimension value
        // we replace null with ∅
        const keys = Object.keys(d);
        const line = {};
        keys.forEach((k) => {
          let newKey = k;
          if (k !== "x" && k !== "x2") {
            newKey = k.indexOf(",") > -1 ? k.split(",")?.[0] : "∅";
          }
          line[newKey] = d[k] ? +d[k] : 0;
        });
        return line;
      });

      const diffs = computeDiffs(formattedData);

      const chartData = [
        {
          name: labelStart,
          color: colorBounds,
          y: valueStart ? +valueStart : 0,
          onClick: disableDrills
            ? undefined
            : () => {
                const q = data.data.drillDown({
                  xValues: [dataset[0].x],
                  yValues: [],
                });
                if (!q) return;
                this.setState({ drillQuery: q });
              },
        },
        ...diffsKeys
          .map((k) => ({
            name: formattedChartOptions?.series?.[k]?.label ?? k,
            y: diffs[k],
            color: extractColor(diffs[k]),
            onClick: disableDrills
              ? undefined
              : () => {
                  if (
                    additionalResults.status !== "success" ||
                    !additonalResultsDataset
                  ) {
                    return;
                  }
                  const q = additionalResults.data.drillDown({
                    xValues: [],
                    yValues: k === "∅" ? [] : [k],
                  });
                  if (!q) return;
                  if (q.timeDimensions?.[0]) {
                    q.timeDimensions = [
                      {
                        ...q.timeDimensions[0],
                        dateRange:
                          additionalResults.data.query().timeDimensions?.[0]
                            .dateRange,
                        granularity: undefined,
                      },
                    ];
                  }
                  this.setState({ drillQuery: q });
                },
          }))
          .sort((a, b) => b.y - a.y),
        {
          name: labelEnd,
          isSum: true,
          color: colorBounds,
          y: valueEnd ? +valueEnd : 0,
          onClick: disableDrills
            ? undefined
            : () => {
                const q = data.data.drillDown({
                  xValues: [dataset[dataset.length - 1].x],
                  yValues: [],
                });
                if (!q) return;
                this.setState({ drillQuery: q });
              },
        },
      ];

      const warning =
        "It seems your query is not compatible with this type of chart. Displayed results may contain some errors.";

      // we use approx equality to avoid rounding errors
      const approxeq = (v1, v2, epsilon = 0.01) => Math.abs(v1 - v2) <= epsilon;
      if (
        !approxeq(
          diffsKeys.reduce(
            (acc, k) => diffs[k] + acc,
            valueStart ? +valueStart : 0
          ),
          valueEnd ? +valueEnd : 0
        )
      ) {
        if (this.state.warnings.indexOf(warning) === -1) {
          this.setWarnings([...this.state.warnings, warning]);
        }
      } else if (this.state.warnings.indexOf(warning) > -1) {
        this.setWarnings([...this.state.warnings].filter((w) => w !== warning));
      }

      return (
        <WaterfallChartTimeSerie
          width={width}
          locale={locale}
          height={chartHeight}
          data={chartData}
          config={{
            x: {
              label: formattedChartOptions?.axis?.bottom?.label,
            },
            y: {
              label: formattedChartOptions?.axis?.left?.label,
              formatter: meta.data.series[0]?.metric.formatter,
            },
            serieLabel:
              formattedChartOptions?.series?.[lagoonQuery.selectedMeasures?.[0]]
                ?.label ?? meta.data.series?.[0].metric?.label,
            chartOptions: formattedChartOptions,
            showLabels:
              typeof formattedChartOptions?.label === "boolean"
                ? formattedChartOptions?.label
                : true,
          }}
        />
      );
    } else if (chartType === "map") {
      const d = meta.data.series[0];
      const query = this.props.lagoonQuery;
      const options = formattedChartOptions;
      const xAxisLabel = options?.axis?.bottom?.label;
      const yAxisLabel = options?.axis?.left?.label;
      const label =
        options &&
        options.series &&
        options.series[d.key] &&
        options.series[d.key].label
          ? options.series[d.key].label
          : d.label;
      return (
        <MapChart
          width={width}
          locale={locale}
          height={chartHeight}
          data={meta.data.tableRows}
          scale={scale}
          config={{
            x1: { key: query.selectedDimensions[0], label: xAxisLabel },
            x2: { key: query.selectedDimensions[1], label: yAxisLabel },
            y: {
              key: query.selectedMeasures[0],
              label: label,
              color: getColorFromPallette(0),
              dashed: false,
              canDrill: (xKey: string): Query | null => {
                return canDrill(data.data, d, xKey, analysisType, lagoonQuery);
              },
              formatter: {
                prefix: d.metric ? d.metric.formatter.prefix : "",
                suffix: d.metric ? d.metric.formatter.suffix : "",
                format: d.metric ? d.metric.formatter.format : "NUMBER",
                customFormatting: d.metric
                  ? d.metric.formatter.customFormatting
                  : undefined,
              },
            },
            showLabels:
              formattedChartOptions && formattedChartOptions.label === true
                ? formattedChartOptions.label
                : false,
          }}
          tinyLegend={meta.data.series.length > 12}
          onDrill={
            disableDrills
              ? undefined
              : (query) => {
                  this.setState({ drillQuery: query });
                }
          }
          chartOptions={formattedChartOptions}
        />
      );
    } else if (chartType === "funnel") {
      const options = formattedChartOptions;
      const xAxisLabel = options?.axis?.bottom?.label;
      const selectedPalette =
        options && options.palette
          ? getSelectedPalette(org, options.palette)
          : defaultPalette;
      const palette = new PaletteGenerator({
        ...selectedPalette,
        numberOfColors: meta.data.series.length,
      });
      return (
        <FunnelChart
          width={width}
          locale={locale}
          height={chartHeight}
          data={dataset}
          onDrill={
            disableDrills ? undefined : (q) => this.setState({ drillQuery: q })
          }
          config={{
            x: { key: "x", label: xAxisLabel },
            y: meta.data.series.map((d, i) => {
              const newKey = getNewKey(d);
              const chartOptions = formattedChartOptions;
              const label =
                options &&
                options.series &&
                options.series[d.key] &&
                options.series[d.key].label
                  ? options.series[d.key].label
                  : d.label;
              const axis =
                chartOptions &&
                chartOptions.series &&
                chartOptions.series[d.key] &&
                chartOptions.series[d.key].axis
                  ? chartOptions.series[d.key].axis
                  : "left";
              const color =
                chartOptions &&
                chartOptions.series &&
                chartOptions.series[d.key] &&
                chartOptions.series[d.key].color
                  ? chartOptions.series[d.key].color
                  : palette.getColorAtIndex(i);
              const type =
                chartOptions &&
                chartOptions.series &&
                chartOptions.series[d.key] &&
                chartOptions.series[d.key].type
                  ? chartOptions.series[d.key].type
                  : undefined;
              return {
                key: newKey,
                label: label,
                color: color,
                axis: axis,
                type: type,
                dashed: d.previous,
                canDrill: (xKey: string) => {
                  return canDrill(
                    data.data,
                    d,
                    xKey,
                    analysisType,
                    lagoonQuery
                  );
                },
                formatter: {
                  prefix: d.metric ? d.metric.formatter.prefix : "",
                  suffix: d.metric ? d.metric.formatter.suffix : "",
                  format: d.metric ? d.metric.formatter.format : "NUMBER",
                  customFormatting: d.metric
                    ? d.metric.formatter.customFormatting
                    : undefined,
                },
              };
            }),
            showLabels:
              formattedChartOptions && formattedChartOptions.label
                ? formattedChartOptions.label
                : false,
          }}
          tinyLegend={meta.data.series.length > 12}
          scale={scale}
          chartOptions={formattedChartOptions}
        />
      );
    } else if (chartType === "gauge") {
      const chartOptions = formattedChartOptions;
      const selectedPalette =
        chartOptions && chartOptions.palette
          ? getSelectedPalette(org, chartOptions.palette)
          : defaultPalette;
      const palette = new PaletteGenerator({
        ...selectedPalette,
        numberOfColors: meta.data.series.length,
      });

      return (
        <GaugeChart
          width={width}
          locale={locale}
          height={chartHeight}
          config={meta.data.series
            .filter((serieData) => {
              return serieData.previous !== true;
            })
            .map((d, i) => {
              const color =
                chartOptions &&
                chartOptions.series &&
                chartOptions.series[d.key] &&
                chartOptions.series[d.key].color
                  ? chartOptions.series[d.key].color
                  : palette.getColorAtIndex(i);
              return {
                name: d.label,
                color: color,
                data: {
                  current: dataset[0][`${d.key}.current`] as string,
                  previous: dataset[0][`${d.key}.previous`] as string,
                },
                canDrill: (key: "current" | "previous") => {
                  if (
                    data.status === "success" &&
                    data.data &&
                    meta.status === "success" &&
                    meta.data
                  ) {
                    if (
                      (data.data as any).queryType === "compareDateRangeQuery"
                    ) {
                      const queries = data.data.decompose();
                      if (key === "current") {
                        const q = queries[0].drillDown(
                          {
                            xValues: [],
                            yValues: d.metric ? [d.metric.key] : [],
                          },
                          meta.data.pivotConfig
                        );
                        if (q) {
                          return {
                            ...q,
                            filters: (q.filters || []).filter((a) => {
                              return (
                                (a as BinaryFilter | UnaryFilter).member !==
                                "compareDateRange"
                              );
                            }),
                          };
                        }
                      } else {
                        return queries[1].drillDown(
                          {
                            xValues: [],
                            yValues: d.metric ? [d.metric.key] : [],
                          },
                          meta.data.pivotConfig
                        );
                      }
                    } else if (key === "current") {
                      return data.data.drillDown(
                        {
                          xValues: [],
                          yValues: d.metric ? [d.metric.key] : [],
                        },
                        meta.data.pivotConfig
                      );
                    }
                  } else {
                    if (this.props.data.status === "success") {
                      if (
                        (this.props.data.data as any).queryType ===
                        "compareDateRangeQuery"
                      ) {
                        const queries = this.props.data.data.decompose();
                        if (key === "current") {
                          const q = queries[0].drillDown(
                            {
                              xValues: [],
                              yValues: d.metric ? [d.metric.key] : [],
                            },
                            meta.data.pivotConfig
                          );
                          if (q) {
                            return {
                              ...q,
                              filters: (q.filters || []).filter((a) => {
                                return (
                                  (a as BinaryFilter | UnaryFilter).member !==
                                  "compareDateRange"
                                );
                              }),
                            };
                          }
                        } else {
                          return queries[1].drillDown(
                            {
                              xValues: [],
                              yValues: d.metric ? [d.metric.key] : [],
                            },
                            meta.data.pivotConfig
                          );
                        }
                      } else if (key === "current") {
                        return this.props.data.data.drillDown(
                          {
                            xValues: [],
                            yValues: d.metric ? [d.metric.key] : [],
                          },
                          meta.data.pivotConfig
                        );
                      }
                    }
                  }
                  return null;
                },
                formatter: {
                  prefix: d.metric ? d.metric.formatter.prefix : "",
                  suffix: d.metric ? d.metric.formatter.suffix : "",
                  format: d.metric ? d.metric.formatter.format : "NUMBER",
                  customFormatting: d.metric
                    ? d.metric.formatter.customFormatting
                    : undefined,
                },
              };
            })}
          onDrill={
            disableDrills
              ? undefined
              : (query) => {
                  this.setState({ drillQuery: query });
                }
          }
          chartOptions={formattedChartOptions}
        />
      );
    } else if (chartType === "interractive-map") {
      const query = lagoonQuery;
      const d = meta.data.series[0];
      const dimType = this.props.availableDimensions.find(
        (a) => a.key === query.selectedDimensions[0]
      );
      if (!d) {
        return (
          <div style={{ height, width }}>You must pass at least one metric</div>
        );
      }
      if (!dimType || (dimType && dimType.type !== "geo")) {
        return (
          <div style={{ height, width }}>
            You must use a geo dimension in order to use this map
          </div>
        );
      }
      const selectedPalette =
        formattedChartOptions && formattedChartOptions.palette
          ? getSelectedPalette(org, formattedChartOptions.palette)
          : defaultPalette;
      const palette = new PaletteGenerator({
        ...selectedPalette,
        numberOfColors: meta.data.tableRows.length,
      });
      return (
        <BubbleMap
          locale={locale}
          height={height}
          width={width}
          data={meta.data.tableRows}
          config={{
            x: {
              key: query.selectedDimensions[0],
              type: "geo",
              label: query.selectedDimensions[1],
            },
            y: {
              key: query.selectedMeasures[0],
              canDrill: (xKey: string): Query | null => {
                return canDrill(data.data, d, xKey, analysisType, lagoonQuery);
              },
              formatter: {
                prefix: d.metric ? d.metric.formatter.prefix : "",
                suffix: d.metric ? d.metric.formatter.suffix : "",
                format: d.metric ? d.metric.formatter.format : "NUMBER",
                customFormatting: d.metric
                  ? d.metric.formatter.customFormatting
                  : undefined,
              },
            },
          }}
          onDrill={
            disableDrills
              ? undefined
              : (query) => {
                  this.setState({ drillQuery: query });
                }
          }
          chartOptions={formattedChartOptions}
          palette={palette}
        />
      );
    } else if (chartType === "interractive-pin-map") {
      const query = lagoonQuery;
      const d = meta.data.series[0];
      const dimType = this.props.availableDimensions.find(
        (a) => a.key === query.selectedDimensions[0]
      );
      if (!d) {
        return (
          <div style={{ height, width }}>You must pass at least one metric</div>
        );
      }
      if (!dimType || (dimType && dimType.type !== "geo")) {
        return (
          <div style={{ height, width }}>
            You must use a geo dimension in order to use this map
          </div>
        );
      }
      return (
        <PinMap
          locale={locale}
          height={height}
          width={width}
          data={meta.data.tableRows}
          config={{
            x: {
              key: query.selectedDimensions[0],
              type: "geo",
              label: query.extra?.["pin-map-chart-label"]?.dimensions?.length
                ? query.extra?.["pin-map-chart-label"]?.dimensions[0]
                : null,
              color: query.extra?.["pin-map-color"]?.dimensions?.length
                ? query.extra?.["pin-map-color"]?.dimensions[0]
                : null,
            },
            y: {
              key: query.selectedMeasures[0],
              canDrill: (xKey: string): Query | null => {
                return canDrill(data.data, d, xKey, analysisType, lagoonQuery);
              },
              formatter: {
                prefix: d.metric ? d.metric.formatter.prefix : "",
                suffix: d.metric ? d.metric.formatter.suffix : "",
                format: d.metric ? d.metric.formatter.format : "NUMBER",
                customFormatting: d.metric
                  ? d.metric.formatter.customFormatting
                  : undefined,
              },
            },
          }}
          onDrill={
            disableDrills
              ? undefined
              : (query) => {
                  this.setState({ drillQuery: query });
                }
          }
          chartOptions={formattedChartOptions}
          defaultCollection={getDefaultCollection(this.props.org)}
        />
      );
    }
  };

  public render() {
    const {
      sql,
      trace,
      data,
      currentChartType,
      scale,
      height,
      width,
      analysisType,
      lagoonQuery,
      additionalResults: grandTotal,
    } = this.props;

    const { meta, additionalResultsMeta: grandTotalMeta } = this.state;
    const pivotDimensions = lagoonQuery.pivotDimensions;

    return (
      <div
        className="wly-chart"
        style={{ height: height, width: width ? width : "100%" }}
      >
        <div
          className="inner"
          id={this.id}
          style={{
            height: height,
            width: width ? width : "100%",
          }}
        >
          <div
            className="chart-drawing"
            style={{ height: "100%", width: "100%" }}
          >
            <InView
              initialInView={
                this.props.renderOutsideViewport ? true : undefined
              }
              skip={this.props.renderOutsideViewport ? true : undefined}
              triggerOnce={true}
              style={{ height: "100%", maxHeight: "100%", width: "100%" }}
            >
              {({ inView, ref, entry }) => (
                <div
                  ref={ref}
                  style={{ height: "100%", maxHeight: "100%", width: "100%" }}
                >
                  <Spin
                    style={{ height: "100%", maxHeight: "100%", width: "100%" }}
                    spinning={
                      !inView ||
                      data.status === "loading" ||
                      meta.status === "loading" ||
                      grandTotal.status === "loading" ||
                      grandTotalMeta.status === "loading"
                    }
                    indicator={loadingIcon}
                  >
                    {inView &&
                      this.renderChart(
                        currentChartType,
                        data,
                        analysisType,
                        scale,
                        pivotDimensions
                      )}
                  </Spin>
                </div>
              )}
            </InView>
          </div>
        </div>
        {this.state.drillQuery && (
          <DrillDown
            onClose={() => this.setState({ drillQuery: undefined })}
            query={this.state.drillQuery}
            availableMetrics={this.props.availableMetrics}
            explorationId={this.props.exploration?.id}
            reportId={this.props.reportId}
          />
        )}
        {(trace || sql) && (
          <TraceDrawer
            visible={this.state.traceQueryVisible}
            onClose={() => this.setState({ traceQueryVisible: false })}
            trace={this.props.trace}
            sql={sql?.status === "success" ? sql.data : undefined}
            data={this.props.data}
          />
        )}
      </div>
    );
  }
}

export default compose<Props, IChartProps>(React.memo, WithOrg)(Chart);
