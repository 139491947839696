import * as peggy from "peggy";
import type { DataType, SchemaResult } from "../interfaces/transformations";
import { functionDefinition } from "../parser/functions";

import grammar from "../parser/function.pegjs?raw";
const functions = peggy.generate(grammar);

interface FormulaLocation {
  start: {
    offset: number;
    line: number;
    column: number;
  };
  end: {
    offset: number;
    line: number;
    column: number;
  };
}

export interface FunctionExplaination {
  type: "FunctionExplaination";
  location: FormulaLocation;
  explaination: {
    name: string;
    description: string;
    args: Array<{
      name: string;
      type: DataType | "ANY";
      description: string;
      isSameTypeAs: number;
      unlimited?: boolean;
    }>;
  };
  current: {
    args: Array<{ type: DataType; valid: boolean }>;
  };
}

export const checkFunction = (
  t: string,
  schema: SchemaResult,
  explainationCallback: (exp: FunctionExplaination[]) => void
) => {
  try {
    const explainations: FunctionExplaination[] = [];
    functions.parse(t, {
      schema,
      explainations,
      definitions: functionDefinition,
    });
    explainationCallback(explainations);
    return undefined;
  } catch (err) {
    return {
      found: err.found,
      location: err.location,
      expected: err.expected[0].description,
    };
  }
};
