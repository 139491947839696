import { Button, Col, Row } from "antd";
import type { FormItemProps } from "antd/lib/form";
import type { InputProps } from "antd/lib/input";
import type { FormikProps } from "formik";
import { Field, Form, Formik } from "formik";
import { inject, observer } from "mobx-react";
import * as React from "react";
import type { RouteComponentProps } from "react-router";
import { withRouter } from "react-router";
import type { InjectedAntUtilsProps } from "../../../components/ant-utils/withAntUtils";
import { withAntUtils } from "../../../components/ant-utils/withAntUtils";
import { WlyCard } from "../../../components/cards/WlyCard";
import { compose } from "../../../components/compose/WlyCompose";
import Input from "../../../components/form/elements/input/Input";
import { isRequired } from "../../../components/form/validators/validators";
import Aligner from "../../../components/layout/aligner/Aligner";
import OrgAvatar from "../../../components/orgs/Avatar/OrgAvatar";
import { handleGQLErrors } from "../../../helpers/gqlHelpers";
import type { IOrg } from "../../../interfaces/org";
import { IUserRoleType } from "../../../interfaces/user";
import { createOrgAsset } from "../../../services/AssetService";
import GraphQLService from "../../../services/graphql/GraphQLService";
import type { UserStoreProps } from "../../../store/userStore";
import type { InjectedOrgProps } from "../../orgs/WithOrg";
import WithOrg from "../../orgs/WithOrg";
import LogoUploader from "../../uploader/modal/LogoUploader";
import { hasRoleAccessBoolean } from "../../user-settings/HasRoleAccess";

interface IBaseSettingFormProps {
  orgData: IOrg;
}

const F = Form as any;

interface InitialValues {
  name: string;
  slug: string;
}

type Props = IBaseSettingFormProps &
  InjectedOrgProps &
  RouteComponentProps<{}> &
  UserStoreProps &
  InjectedAntUtilsProps;

const GQL = `
mutation updateOrg(
  $id: ID!,
  $name: String!,
  $slug: String!
) {
  updateOrg(id: $id, data: {
    name: $name,
    slug: $slug
  }) {
    id
    name
    slug
    createdBy {
      id
    }
  }
}
`;
class BaseSettingForm extends React.Component<Props> {
  public onSubmit = async (v: InitialValues) => {
    const { antUtils, orgData, history } = this.props;

    try {
      const org = await GraphQLService(
        GQL,
        { id: orgData.id, ...v },
        "updateOrg"
      );
      antUtils.message.success("Changes successfully saved !");
      setTimeout(() => {
        const location = history.location.pathname.replace(
          `/${orgData.slug}/`,
          `/${org.slug}/`
        );
        window.location.replace(location);
      }, 300);
    } catch (error) {
      antUtils.message.error("Error saving your changes");
      handleGQLErrors();
    }
  };

  public render() {
    const {
      orgData,
      org,
      user,
      userStore: { getUser },
    } = this.props;

    return (
      <Aligner>
        <Row justify="center">
          <Col xs={24} sm={24} md={20} lg={20} xl={20}>
            <Formik
              initialValues={{
                name: orgData.name,
                slug: orgData.slug,
                client_secret: orgData.client_secret,
              }}
              onSubmit={this.onSubmit}
            >
              {(p: FormikProps<InitialValues>) => {
                return (
                  <F className="form-container">
                    <WlyCard
                      title={<div className="title">General Settings</div>}
                      extra={
                        <Button
                          type="primary"
                          disabled={
                            !hasRoleAccessBoolean(
                              IUserRoleType.ADMIN_BUILDER,
                              user,
                              org.id
                            )
                          }
                          htmlType="submit"
                        >
                          Save
                        </Button>
                      }
                    >
                      <Row>
                        <Col style={{ paddingTop: 12 }} flex="100px">
                          <LogoUploader
                            onUpload={(fileName, formData) => {
                              return createOrgAsset(
                                org.id,
                                fileName,
                                formData
                              ).then((r) => {
                                return r.data.filePath;
                              });
                            }}
                            modalTitle="Change Org Logo"
                            minWidth={256}
                            display={"round"}
                            onSave={(path) => {
                              return GraphQLService(
                                `
                          mutation updateLogo($orgId: ID!, $logo: String!) {
                            updateOrg(id: $orgId, data: { logo: $logo }) {
                              id
                            }
                          }
                        `,
                                {
                                  orgId: org.id,
                                  logo: path,
                                }
                              ).then((r) => {
                                return getUser();
                              });
                            }}
                            size={88}
                            disabled={
                              !hasRoleAccessBoolean(
                                IUserRoleType.ADMIN_BUILDER,
                                user,
                                org.id
                              )
                            }
                          >
                            <OrgAvatar
                              name={org.name}
                              overrideUrl={org.logo ? org.logo : undefined}
                              size={88}
                            />
                          </LogoUploader>
                          {/* <OrgAvatarEdit
                            onSave={}
                          /> */}
                        </Col>
                        <Col flex="auto">
                          <Field
                            type="name"
                            name="name"
                            component={Input}
                            validate={isRequired}
                            inputProps={
                              {
                                placeholder: "",
                                disabled:
                                  p.isSubmitting ||
                                  !hasRoleAccessBoolean(
                                    IUserRoleType.ADMIN_BUILDER,
                                    user,
                                    org.id
                                  ),
                              } as InputProps
                            }
                            formItemProps={
                              {
                                label: "Org Name",
                                hasFeedback: true,
                                colon: false,
                                required: true,
                              } as FormItemProps
                            }
                          />
                          <Field
                            type="slug"
                            name="slug"
                            component={Input}
                            validate={isRequired}
                            inputProps={
                              {
                                placeholder: "",
                                disabled:
                                  p.isSubmitting ||
                                  !hasRoleAccessBoolean(
                                    IUserRoleType.ADMIN_BUILDER,
                                    user,
                                    org.id
                                  ),
                              } as InputProps
                            }
                            formItemProps={
                              {
                                label: "Slug",
                                hasFeedback: true,
                                colon: false,
                                required: true,
                              } as FormItemProps
                            }
                          />
                          <Field
                            type="client_secret"
                            name="client_secret"
                            component={Input}
                            validate={isRequired}
                            inputProps={
                              {
                                placeholder: "",
                                disabled: true,
                              } as InputProps
                            }
                            formItemProps={
                              {
                                label: "Client Secret",
                                hasFeedback: true,
                                colon: false,
                                required: true,
                              } as FormItemProps
                            }
                          />
                        </Col>
                      </Row>
                    </WlyCard>
                  </F>
                );
              }}
            </Formik>
          </Col>
        </Row>
      </Aligner>
    );
  }
}

export default compose<Props, IBaseSettingFormProps>(
  withRouter,
  WithOrg,
  withAntUtils
)(inject("userStore")(observer(BaseSettingForm)));
