type scrollbarSize = {
  width: number;
  height: number;
};

export const getScrollbarSize = (): scrollbarSize => {
  // Creating invisible container
  const outer = document.createElement("div");
  outer.style.visibility = "hidden";
  outer.style.overflow = "scroll";
  document.body.appendChild(outer);

  // Creating inner element and placing it in the container
  const inner = document.createElement("div");
  outer.appendChild(inner);

  // Calculating difference between container's full width and the child width
  const scrollbarWidth = outer.offsetWidth - inner.offsetWidth;
  const scrollbarHeight = outer.offsetHeight - inner.offsetHeight;

  // Removing temporary elements from the DOM
  outer.parentNode.removeChild(outer);

  return { width: scrollbarWidth, height: scrollbarHeight };
};
