import { Col, Space, Typography } from "antd";
import type { InjectedAntUtilsProps } from "../../../../../../../components/ant-utils/withAntUtils";
import { withAntUtils } from "../../../../../../../components/ant-utils/withAntUtils";
import { compose } from "../../../../../../../components/compose/WlyCompose";
import type { IDestination } from "../../../../../../../interfaces/destinations";
import type { IRadar } from "../../../../../../../interfaces/radar";
import type { IDataset } from "../../../../../../../interfaces/sources";
import type { ObjectStore } from "../../../../domain";
import type { UpdateRadarFunction } from "../../../../selector/radar/domain";
import RadarDefinitionCard from "./RadarDefinitionCard";
import RadarPersistenceCard from "./RadarPersistenceCard";
import RadarSignalDefinitionCard from "./RadarSignalDefinitionCard";
import RadarSignalPublishCard from "./RadarSignalPublishCard";

const { Title, Text } = Typography;

interface IRadarSettingsProps {
  currentWarehouse: IDestination;
  radar: IRadar;
  datasets: IDataset[];
  objectStore: ObjectStore;
  onUpdateRadar: UpdateRadarFunction;
  afterSave: () => Promise<void>;
}

type Props = IRadarSettingsProps & InjectedAntUtilsProps;

function RadarSettings(props: Props) {
  const {
    currentWarehouse,
    radar,
    objectStore,
    datasets,
    onUpdateRadar,
    afterSave,
  } = props;

  return (
    <Col span={16} offset={4}>
      <Space direction="vertical" size={32} style={{ width: "100%" }}>
        <div>
          <Title style={{ paddingTop: 16 }} level={3}>
            {radar.name}
          </Title>
          <Text type="secondary">
            A radar periodically extracts signals from a model to create
            notifications on an object
          </Text>
        </div>
        <RadarDefinitionCard
          currentWarehouse={currentWarehouse}
          radar={radar}
          datasets={datasets}
          objectStore={objectStore}
          afterScheduleSave={afterSave}
          onUpdateRadar={onUpdateRadar}
        />
        <RadarSignalDefinitionCard
          currentWarehouse={currentWarehouse}
          radar={radar}
          onUpdateRadar={onUpdateRadar}
        />
        <RadarSignalPublishCard
          currentWarehouse={currentWarehouse}
          radar={radar}
          objectStore={objectStore}
          onUpdateRadar={onUpdateRadar}
        />
        <RadarPersistenceCard radar={radar} onUpdateRadar={onUpdateRadar} />
        <div style={{ height: 32 }}></div>
      </Space>
    </Col>
  );
}

export default compose<Props, IRadarSettingsProps>(withAntUtils)(RadarSettings);
