import { Divider as AntDivider } from "antd";
import * as React from "react";

export interface IDividerProps {
  id: string;
}

export function Divider(props: React.PropsWithChildren<IDividerProps>) {
  const { children } = props;

  return <AntDivider />;
}
