import { CheckCircleTwoTone } from "@ant-design/icons";
import { Space } from "antd";
import * as React from "react";
import type { InjectedAntUtilsProps } from "../../../../../components/ant-utils/withAntUtils";
import { withAntUtils } from "../../../../../components/ant-utils/withAntUtils";
import { compose } from "../../../../../components/compose/WlyCompose";
import WlyModal from "../../../../../components/modal/WlyModal";
import type { IDestination } from "../../../../../interfaces/destinations";
import { JobType } from "../../../../../interfaces/jobExecutions";
import GraphQLService from "../../../../../services/graphql/GraphQLService";
import type { InjectedOrgProps } from "../../../../orgs/WithOrg";
import WithOrg from "../../../../orgs/WithOrg";

interface ITriggerJobModalProps {
  visible: boolean;
  onCancel: () => void;
  onDone: () => Promise<void>;
  jobType: JobType;
  currentWarehouse: IDestination;
}

type Props = ITriggerJobModalProps & InjectedOrgProps & InjectedAntUtilsProps;

function TriggerJobModal(props: Props) {
  const { antUtils, visible, onCancel, currentWarehouse, onDone, jobType } =
    props;
  const [submitting, setSubmitting] = React.useState<boolean>(false);

  const renderInner = () => {
    switch (jobType) {
      case JobType.DBT_CLOUD_SYNC:
        return (
          <Space direction="vertical">
            <div>
              You are about to sync the artifacts from the last dbt Cloud job
              execution, this will:
            </div>
            <div>
              <CheckCircleTwoTone twoToneColor="#52c41a" /> Import all models
              and sources definition from your dbt project
            </div>
            <div>
              <CheckCircleTwoTone twoToneColor="#52c41a" /> Read the models
              tests results and import them
            </div>
            <div>
              <CheckCircleTwoTone twoToneColor="#52c41a" /> Check your Source
              Freshness status and import them
            </div>
          </Space>
        );
      case JobType.PERSISTENCE_ENGINE_RUN:
        return (
          <Space direction="vertical">
            <div>
              You are about to trigger the persistence engine, this will:
            </div>
            <div>
              <CheckCircleTwoTone twoToneColor="#52c41a" /> Materialize selected
              models as view or as tables in your Warehouse
            </div>
          </Space>
        );
      default:
        return <div>Not implemented</div>;
    }
  };

  return (
    <WlyModal
      open={visible}
      title={"Trigger an execution"}
      okText={"Run now"}
      cancelText="Cancel"
      confirmLoading={submitting}
      onClose={() => {
        onCancel();
      }}
      onOk={
        currentWarehouse
          ? async () => {
              setSubmitting(true);
              try {
                const triggerJob = async (): Promise<boolean> => {
                  if (jobType === JobType.DBT_CLOUD_SYNC) {
                    const resp = await GraphQLService(
                      `
              mutation runDbtCloudSync($destinationId: ID!){
                executeDbtCloudSyncNow(destinationId: $destinationId)
              }
            `,
                      {
                        destinationId: currentWarehouse.id,
                      }
                    );
                    return resp.executeDbtCloudSyncNow;
                  } else if (jobType === JobType.PERSISTENCE_ENGINE_RUN) {
                    const resp = await GraphQLService(
                      `
                mutation runPersistenceEngineNow($destinationId: ID!){
                  executePersistenceEngineNow(destinationId: $destinationId)
                }
              `,
                      {
                        destinationId: currentWarehouse.id,
                      }
                    );
                    return resp.executePersistenceEngineNow;
                  }
                };

                const resp = await triggerJob();
                if (!resp) {
                  antUtils.message.error(
                    "There was an error triggering the execution"
                  );
                } else {
                  antUtils.message.success("Job successfully triggered");
                }
                await onDone();
              } catch (err) {
                console.error(err);
                antUtils.message.error("Cannot trigger job...");
              } finally {
                setSubmitting(false);
              }
            }
          : undefined
      }
    >
      <div>{renderInner()}</div>
    </WlyModal>
  );
}

export default compose<Props, ITriggerJobModalProps>(
  WithOrg,
  withAntUtils
)(TriggerJobModal);
