import StorageService from "./StorageService";

export const identify =
  window.analytics && window.WHALY_CONSTANTS.WHALY_ENVIRONMENT === "PROD"
    ? window.analytics.identify
    : (...r: any[]) => console.log("analytics: identify", ...r);
export const track: (event: string, properties?: unknown) => void =
  window.analytics && window.WHALY_CONSTANTS.WHALY_ENVIRONMENT === "PROD"
    ? window.analytics.track
    : (...r: any[]) => console.log("analytics: track", ...r);
export const group =
  window.analytics && window.WHALY_CONSTANTS.WHALY_ENVIRONMENT === "PROD"
    ? window.analytics.group
    : (...r: any[]) => console.log("analytics: group", ...r);
export const alias =
  window.analytics && window.WHALY_CONSTANTS.WHALY_ENVIRONMENT === "PROD"
    ? window.analytics.alias
    : (...r: any[]) => console.log("analytics: alias", ...r);
export const activeTracking =
  window.WHALY_CONSTANTS.WHALY_ENVIRONMENT === "PROD" &&
  !StorageService.getItem("_wly_disable_tracking");
