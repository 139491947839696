import { Form, Input, Select, Typography } from "antd";
import type { FormInstance } from "antd/es/form/Form";

interface ShareObjectViewFormProps {
  form: FormInstance<ShareObjectViewFormData>;
  initialValues?: Partial<ShareObjectViewFormData>;
  onSave: (values: ShareObjectViewFormData) => any;
}

export type ShareObjectViewFormData = {
  id: string;
  isPersonal: boolean;
};

export function ShareObjectViewForm(props: ShareObjectViewFormProps) {
  const { form, initialValues, onSave } = props;
  return (
    <Form<ShareObjectViewFormData>
      form={form}
      preserve={false}
      layout="vertical"
      initialValues={initialValues}
      onFinish={(values) => {
        onSave(values);
      }}
    >
      <Form.Item
        name={["isPersonal"]}
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Select
          options={[
            {
              label: (
                <Typography.Text>
                  Private
                  <Typography.Text type="secondary" style={{ marginLeft: 8 }}>
                    can be viewed only by its owner
                  </Typography.Text>
                </Typography.Text>
              ),
              value: true,
            },
            {
              label: (
                <Typography.Text>
                  Public
                  <Typography.Text type="secondary" style={{ marginLeft: 8 }}>
                    can be viewed by everyone
                  </Typography.Text>
                </Typography.Text>
              ),
              value: false,
            },
          ]}
          style={{ width: "100%" }}
        />
      </Form.Item>
      <Form.Item
        noStyle
        hidden
        name={["id"]}
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input />
      </Form.Item>
    </Form>
  );
}
