import type { MenuProps } from "antd";
import { Menu, Popover } from "antd";
import type { TooltipPlacement } from "antd/lib/tooltip";
import { inject, observer } from "mobx-react";
import * as React from "react";
import type { RouteComponentProps } from "react-router-dom";
import { Link, withRouter } from "react-router-dom";
import { compose } from "../../components/compose/WlyCompose";
import type { IOrg } from "../../interfaces/org";
import { routeDescriptor } from "../../routes/routes";
import type { UserStoreProps } from "../../store/userStore";
import "./ProfileDropdown.scss";

interface IProfileDropDownProps {
  org?: IOrg;
  placement?: TooltipPlacement;
  children?: React.ReactNode;
}

type Props = IProfileDropDownProps & UserStoreProps & RouteComponentProps;

class ProfileDropDown extends React.Component<Props> {
  public render() {
    const { placement } = this.props;
    const {
      userStore,
      org,
      location: { pathname, search },
    } = this.props;

    const items: MenuProps["items"] = [
      ...(org
        ? [
            {
              key: "1",
              label: (
                <Link
                  to={{
                    pathname: routeDescriptor.settingsUser.renderRoute({
                      organizationSlug: org.slug,
                    }),
                    state: { from: `${pathname}?${search}` },
                  }}
                >
                  User Settings
                </Link>
              ),
            },
          ]
        : []),
      {
        key: "1-1",
        type: "divider",
      },
      org
        ? {
            key: "2",
            label: <Link to="/">Change Organization</Link>,
          }
        : null,
      org
        ? {
            key: "2-1",
            type: "divider",
          }
        : null,
      {
        key: "3",
        label: (
          <a
            href={`${window.WHALY_CONSTANTS.AUTH_SERVER_URL}/oauth2/sessions/logout`}
          >
            Logout
          </a>
        ),
      },
    ];

    return (
      <Popover
        overlayClassName="profile-dropdown"
        content={
          <Menu
            style={{
              borderRadius: "0 0 6px 6px",
            }}
            items={items}
          />
        }
        trigger={["click"]}
        placement={placement ? placement : "rightBottom"}
        overlayInnerStyle={{
          padding: 0,
        }}
        title={
          <div className="info-wrapper">
            {org ? <div className="company">{org.name}</div> : undefined}
            <div>{userStore.user!.email}</div>
          </div>
        }
      >
        <div style={{ cursor: "pointer" }}>{this.props.children}</div>
      </Popover>
    );
  }
}

export default compose<Props, IProfileDropDownProps>(withRouter)(
  inject("userStore")(observer(ProfileDropDown))
);
