import { Typography } from "antd";

export interface IParagraphProps {
  lineClamp?: number;
}

export function Paragraph(props: React.PropsWithChildren<IParagraphProps>) {
  const { children, lineClamp } = props;
  return (
    <Typography.Paragraph
      style={{ color: "inherit", fontSize: "inherit", lineClamp: "inherit" }}
      ellipsis={
        lineClamp
          ? {
              rows: (props as any).lineClamp,
              expandable: false,
              symbol: "...",
            }
          : false
      }
    >
      {children}
    </Typography.Paragraph>
  );
}
