import { InfoCircleOutlined } from "@ant-design/icons";
import { Popover } from "antd";
import * as React from "react";

export interface IObjectTableHeaderStandardPopoverProps {
  description?: string;
  label?: string
}

export function ObjectTableHeaderStandardPopover(
  props: IObjectTableHeaderStandardPopoverProps
) {
  const { description, label } = props;
  if (!description) {
    return null;
  }

  return (
    <Popover
      title={label}
      overlayStyle={{ width: 300, maxWidth: 300 }}
      trigger={["hover"]}
      placement="bottom"
      content={
        <div>
          <div style={{ marginBottom: 12, whiteSpace: "pre-line" }}>
            {description}
          </div>
        </div>
      }
    >
      <InfoCircleOutlined />
    </Popover>
  );
}
