import Icon from "@ant-design/icons";
import type { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";

export const WlyStackedBarChartIcon = (
  props: Partial<CustomIconComponentProps>
) => (
  <Icon
    component={() => (
      <svg
        width="1em"
        height="1em"
        viewBox="0 0 213 213"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_1105_1258)">
          <rect y="131" width="30" height="82" fill="#3A5C83" />
          <rect y="25" width="30" height="106" fill="#41D58A" />
          <rect x="46" y="84" width="29" height="129" fill="#3A5C83" />
          <rect x="46" y="13" width="29" height="71" fill="#41D58A" />
          <rect x="92" y="143" width="29" height="70" fill="#3A5C83" />
          <rect x="92" y="51" width="29" height="92" fill="#41D58A" />
          <rect x="138" y="106" width="29" height="107" fill="#3A5C83" />
          <rect x="138" width="29" height="106" fill="#41D58A" />
          <rect
            x="183.489"
            y="130.734"
            width="29.5113"
            height="81.9152"
            fill="#3A5C83"
          />
          <rect
            x="184"
            y="56"
            width="29.5113"
            height="81.9152"
            fill="#41D58A"
          />
        </g>
        <defs>
          <clipPath id="clip0_1105_1258">
            <rect width="213" height="213" fill="white" />
          </clipPath>
        </defs>
      </svg>
    )}
    {...props}
  />
);
