import {
  VerticalAlignBottomOutlined,
  VerticalAlignMiddleOutlined,
  VerticalAlignTopOutlined,
} from "@ant-design/icons";
import {
  ConfigProvider,
  Form,
  Input,
  Segmented,
  Select,
  Space,
  Switch,
  theme,
} from "antd";
import * as React from "react";
import type { ITile } from "../../../../../interfaces/reports";
import { ChartOptionCollapse } from "../../../../chart-options/components/ChartOptionCollapse";
import ChartOptionLine from "../../../../chart-options/components/ChartOptionLine";
import LinkPicker from "./LinkPicker/LinkPicker";

interface INavigationOptionEditProps {
  tile: ITile;
  onTileUpdate: (v: ITextOption) => void;
}

interface ITextOption {
  name: string;
  hide_card?: boolean;
  content: string;
  vertical_align?: "top" | "center" | "bottom";
}

export function NavigationOptionEdit(props: INavigationOptionEditProps) {
  const { tile, onTileUpdate } = props;
  const [form] = Form.useForm();

  React.useEffect(() => {
    form.resetFields();
  }, [tile.id]);

  let content = {};
  try {
    content = JSON.parse(tile.content);
  } catch (err) {
    content = {};
  }

  const initialValues = {
    ...tile,
    content: content,
  };

  const linkPopover = {
    title: "Name",
    content: (
      <Space direction="vertical" style={{ width: "100%" }}>
        <Form.Item
          style={{ marginBottom: 0 }}
          label="Link"
          name={["content", "href"]}
        >
          <LinkPicker />
        </Form.Item>
        <ChartOptionLine
          items={[
            {
              flex: 1,
              content: "Open in new tab",
            },
            {
              flex: 0,
              content: (
                <Form.Item
                  name={["content", "target"]}
                  valuePropName={"checked"}
                  noStyle
                >
                  <Switch />
                </Form.Item>
              ),
            },
          ]}
        />
      </Space>
    ),
  };

  const namePopover = {
    title: "Link settings",
    content: (
      <Space direction="vertical" style={{ width: "100%" }}>
        <Form.Item label={"Name"} name={["name"]}>
          <Input />
        </Form.Item>
      </Space>
    ),
  };

  return (
    <ConfigProvider
      theme={{
        algorithm: theme.compactAlgorithm,
      }}
    >
      <ChartOptionCollapse title={"Navigation options"}>
        <Form
          layout="vertical"
          form={form}
          initialValues={initialValues}
          onFieldsChange={(e) => {
            if (onTileUpdate) {
              const v = form.getFieldsValue();
              const values = {
                ...v,
                content: JSON.stringify(v.content ? v.content : {}),
              };
              onTileUpdate(values);
            }
          }}
        >
          <Space direction="vertical" style={{ width: "100% " }}>
            <ChartOptionLine
              items={[
                {
                  flex: 0,
                  content: "Label",
                },
                {
                  flex: 1,
                  content: (
                    <Form.Item noStyle name={["name"]}>
                      <Input />
                    </Form.Item>
                  ),
                },
              ]}
            />
            <ChartOptionLine
              items={[
                {
                  flex: 1,
                  content: "Link",
                  popover: linkPopover,
                },
                {
                  flex: 0,
                  type: "settings",
                  popover: linkPopover,
                },
              ]}
            />
            <ChartOptionLine
              items={[
                {
                  flex: 1,
                  content: "Full width",
                },
                {
                  flex: 0,
                  content: (
                    <Form.Item
                      noStyle
                      name={["content", "block"]}
                      valuePropName={"checked"}
                    >
                      <Switch />
                    </Form.Item>
                  ),
                },
              ]}
            />
            <ChartOptionLine
              items={[
                {
                  flex: 1,
                  content: "Horizontal align",
                },
                {
                  flex: 1,
                  content: (
                    <Form.Item noStyle name={["horizontal_align"]}>
                      <Segmented
                        block
                        options={[
                          {
                            icon: <VerticalAlignTopOutlined rotate={-90} />,
                            value: "left",
                          },
                          {
                            icon: <VerticalAlignMiddleOutlined rotate={90} />,
                            value: "center",
                          },
                          {
                            icon: <VerticalAlignBottomOutlined rotate={-90} />,
                            value: "right",
                          },
                        ]}
                      />
                    </Form.Item>
                  ),
                },
              ]}
            />
            <ChartOptionLine
              items={[
                {
                  flex: 1,
                  content: "Vertical align",
                },
                {
                  flex: 1,
                  content: (
                    <Form.Item noStyle name={["vertical_align"]}>
                      <Segmented
                        block
                        options={[
                          {
                            icon: <VerticalAlignTopOutlined />,
                            value: "top",
                          },
                          {
                            icon: <VerticalAlignMiddleOutlined />,
                            value: "center",
                          },
                          {
                            icon: <VerticalAlignBottomOutlined />,
                            value: "bottom",
                          },
                        ]}
                      />
                    </Form.Item>
                  ),
                },
              ]}
            />
            <ChartOptionLine
              items={[
                {
                  flex: 1,
                  content: "Button type",
                },
                {
                  flex: 1,
                  content: (
                    <Form.Item noStyle name={["content", "type"]}>
                      <Select style={{ width: "100%" }}>
                        <Select.Option value="primary">Primary</Select.Option>
                        <Select.Option value="default">Default</Select.Option>
                        <Select.Option value="dashed">Dashed</Select.Option>
                      </Select>
                    </Form.Item>
                  ),
                },
              ]}
            />
          </Space>
        </Form>
      </ChartOptionCollapse>
    </ConfigProvider>
  );
}
