import type { InputRef } from "antd";
import { Button, Input } from "antd";
import Fuse from "fuse.js";
import * as React from "react";
import type { InjectedOrgProps } from "../../../containers/orgs/WithOrg";
import WithOrg from "../../../containers/orgs/WithOrg";
import type { IUserGravatarInfo } from "../../../interfaces/user";
import { compose } from "../../compose/WlyCompose";
import { UserRenderer } from "../UserRenderer";
import "./UserPicker.scss";

interface IUserPickerModalProps {
  onClose: () => void;
  ctaText: string;
  selectedUserId?: string;
  selectUserComponent?: JSX.Element;
  availableUsers: IUserGravatarInfo[];
  onUserSelection: (userId: string) => Promise<void>;
}

type Props = InjectedOrgProps & IUserPickerModalProps;

function UserPicker(props: Props) {
  const {
    onClose,
    ctaText,
    selectedUserId,
    onUserSelection,
    selectUserComponent,
    availableUsers,
  } = props;

  const [search, setSearch] = React.useState<string>("");
  const ref = React.useRef<InputRef>();

  const isSearching = search && search.length > 2;
  const options = {
    // isCaseSensitive: false,
    includeScore: true,
    shouldSort: true,
    includeMatches: true,
    findAllMatches: true,
    minMatchCharLength: 3,
    // location: 0,
    threshold: 0.1,
    distance: 5,
    // useExtendedSearch: false,
    ignoreLocation: true,
    // ignoreFieldNorm: false,
    keys: ["firstName", "lastName", "email"],
  };

  React.useEffect(() => {
    if (search.length) {
      setSearch("");
    }

    if (ref.current) {
      ref.current.focus({
        cursor: "start",
      });
    }
  }, [availableUsers]);

  const save = (userId: string) => async () => {
    try {
      await onUserSelection(userId);
      onClose();
    } catch (err) {
      console.error(err);
    }
  };

  const renderInner = () => {
    const fuse = new Fuse(availableUsers, options);
    const searchedResults = search ? fuse.search(search) : [];
    const tableFinal =
      isSearching && search
        ? searchedResults.map((f) => f.item)
        : availableUsers;

    return tableFinal.map((d) => {
      return (
        <div key={d.id} className="user-picker-item">
          <div className="user-picker-item-content">
            <UserRenderer user={d} />
          </div>
          <div className="user-picker-item-actions">
            {d.id === selectedUserId && selectUserComponent ? (
              selectUserComponent
            ) : (
              <Button
                onClick={save(d.id)}
                type="link"
                shape="round"
                size="small"
              >
                {ctaText}
              </Button>
            )}
          </div>
        </div>
      );
    });
  };

  return (
    <div className="user-picker">
      <Input
        ref={ref}
        onChange={(v) => setSearch(v.target.value)}
        value={search}
        placeholder={"Search for a user"}
        className="user-picker-search"
      />
      {renderInner()}
    </div>
  );
}
export default compose<Props, IUserPickerModalProps>(WithOrg)(UserPicker);
