import { gql } from "@apollo/client";
import type { MutateOptions } from "../../../../../components/hooks/query/useQGLMutate";
import { useGQLMutate } from "../../../../../components/hooks/query/useQGLMutate";

type MutationData = { updateTopicGroup: { id: string; isDeleted: boolean } };

type MutationVariables = {
  id: string;
  data: Record<string, any>;
};

const UpdateTopicGroupMutation = gql`
  mutation UpdateTopicGroup($id: ID!, $data: TopicGroupUpdateInput!) {
    updateTopicGroup(id: $id, data: $data) {
      id
      isDeleted
    }
  }
`;

export const useUpdateTopicGroup = (options?: MutateOptions<MutationData>) => {
  return useGQLMutate<MutationData, MutationVariables>(
    UpdateTopicGroupMutation,
    options
  );
};
