import Icon from "@ant-design/icons";
import type { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";

export const WlyBarChartIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon
    component={() => (
      <svg
        width="1em"
        height="1em"
        viewBox="0 0 213 213"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_1008_1279)">
          <rect y="89.3623" width="29.5113" height="123.287" fill="#3A5C83" />
          <rect
            x="45.8722"
            y="13.2393"
            width="29.5113"
            height="199.41"
            fill="#3A5C83"
          />
          <rect
            x="91.7444"
            y="66.1941"
            width="29.5113"
            height="146.455"
            fill="#3A5C83"
          />
          <rect x="137.617" width="29.5113" height="212.649" fill="#3A5C83" />
          <rect
            x="183.489"
            y="130.734"
            width="29.5113"
            height="81.9152"
            fill="#3A5C83"
          />
        </g>
        <defs>
          <clipPath id="clip0_1008_1279">
            <rect width="213" height="213" fill="white" />
          </clipPath>
        </defs>
      </svg>
    )}
    {...props}
  />
);
