import * as React from "react";
import ReactMarkdown from "react-markdown";
import emoji from "remark-emoji";
import remarkFlexibleContainers from "remark-flexible-containers";

import { sql } from "@codemirror/lang-sql";
import ReactCodeMirror from "@uiw/react-codemirror";
import "./gitbook.scss";

interface IGitbookMarkdownRendererProps {
  baseUrl: string;
  children: string;
}

export default class GitbookMarkdownRenderer extends React.Component<IGitbookMarkdownRendererProps> {
  public render() {
    const { baseUrl } = this.props;
    return (
      <ReactMarkdown
        linkTarget={"_blank"}
        skipHtml={true}
        remarkPlugins={[
          emoji,
          [remarkFlexibleContainers, { containerClassName: "hint-container" }],
        ]}
        className="gitbook-markdown"
        components={{
          code: ({ node, inline, className, children, ...props }) => {
            if (!inline && className === "language-sql") {
              const codeValue = children[0].valueOf();
              return (
                <ReactCodeMirror
                  basicSetup={{
                    foldGutter: false,
                    lineNumbers: true,
                  }}
                  theme={"light"}
                  extensions={[sql()]}
                  readOnly={true}
                  autoFocus={false}
                  value={codeValue as string}
                ></ReactCodeMirror>
              );
            } else {
              return (
                <code {...props} className={className}>
                  {children}
                </code>
              );
            }
          },
          img: (v) => {
            return (
              <div
                style={{
                  width: "100%",
                  maxHeight: 400,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  paddingTop: 24,
                  paddingBottom: 24,
                }}
              >
                <img {...v} style={{ maxWidth: "100%", maxHeight: 400 }} />
              </div>
            );
          },
        }}
        transformLinkUri={(uri) => {
          if (uri.startsWith("http")) {
            return uri;
          }
          const newUri = `https://docs.whaly.io/${uri
            .replaceAll("../", "")
            .replaceAll("./", "")
            .replaceAll(".md", "")}`;
          return newUri;
        }}
        transformImageUri={(uri) => {
          if (uri.startsWith("http")) {
            return uri;
          }
          // we have relatives URI from gitbook such as ../../.gitbook/assets/image...
          // we need to rewrite them
          const newUri = `${baseUrl}${uri
            .replaceAll("../", "")
            .replaceAll("./", "")}`;
          return newUri;
        }}
      >
        {this.props.children as string}
      </ReactMarkdown>
    );
  }
}
