import type { Query } from "@cubejs-client/core";
import { useEffect, useMemo, useState } from "react";
import type { IOrg } from "../../../interfaces/org";
import type {
  LagoonObjectType,
  WlyResultSet,
} from "../../../services/LagoonService";
import {
  LagoonCallOrigin,
  lagoonServiceLoad,
} from "../../../services/LagoonService";
import { useCache } from "./useCache";

type CubeAsyncData<T> =
  | { status: "initial" }
  | { status: "loading" }
  | { status: "success"; data: T; resultSet: WlyResultSet<T> }
  | { status: "error"; error: Error };

type BaseQueryOptions = {
  org: Pick<IOrg, "id">;
  enabled?: boolean;
  cache?: boolean;
  objectType: LagoonObjectType;
  objectId: string;
};

type QueryOptions = BaseQueryOptions;

export function useLagoonQuery(
  query: Query | undefined,
  options: QueryOptions
) {
  const [enabled, setEnabled] = useState<boolean>(
    typeof options.enabled === "boolean" ? options.enabled : true
  );
  const cache = useCache<{
    data: { [key: string]: string | number | boolean }[];
    resultSet: WlyResultSet<any>;
  }>();
  const [data, setData] = useState<
    CubeAsyncData<{ [key: string]: string | number | boolean }[]>
  >({
    status: "initial",
  });

  const fetch = useMemo(
    () => async (query: Query | undefined, options: QueryOptions) => {
      if (!query) return;

      const cacheKey = JSON.stringify(query);
      const cacheData = cache.get(cacheKey);
      try {
        if (options.cache && !!cacheData) {
          setData({
            status: "success",
            data: cacheData.value.data,
            resultSet: cacheData.value.resultSet,
          });
        } else {
          setData({ status: "loading" });
        }
        const data = await lagoonServiceLoad(
          options.org.id,
          query,
          options.objectType,
          options.objectId,
          undefined,
          LagoonCallOrigin.WHALY_APP,
          undefined
        );
        const records = data.tablePivot();
        setData({ status: "success", data: records, resultSet: data });
        cache.set(cacheKey, { data: records, resultSet: data });
      } catch (err) {
        console.error(err);
        setData({
          status: "error",
          error: err instanceof Error ? err : new Error(err),
        });
      }
    },
    []
  );

  useEffect(() => {
    if (enabled) {
      fetch(query, options);
    }
  }, [enabled]);

  useEffect(() => {
    if (!enabled && options.enabled) {
      setEnabled(true);
    }
  }, [options.enabled, enabled]);

  const refetch = useMemo(
    () => async (query: Query) => {
      fetch(query, options);
    },
    []
  );

  return {
    /** An object containing the result of your GraphQL query after it completes.
     * This value might be undefined if a query results in one or more errors */
    data: data.status === "success" ? data.data : undefined,
    resultSet: data.status === "success" ? data.resultSet : undefined,
    /** If true, the query is still in flight and results have not yet been returned. */
    loading: data.status === "loading",
    /** If the query produces an error, this object contains the associated error. Otherwise, this value is undefined. */
    error: data.status === "error" ? data.error : undefined,
    /** A function that helps you fetch the same object with different variables */
    refetch,
  };
}
