import type { IEmailTemplateSettings } from "./EmailTemplateSettings";

interface IEmailTemplateRendererProps {
  logo: string;
  variables: IEmailTemplateSettings;
}

export function EmailTemplateRenderer(props: IEmailTemplateRendererProps) {
  const { logo, variables } = props;
  return (
    <div style={{ fontFamily: "Helvetica", minWidth: 800 }}>
      <div
        style={{
          width: "100%",
          border: "1px solid #cecece",
          background: variables.backgroundColor,
          borderRadius: 6,
        }}
      >
        <div
          style={{
            width: 600,
            margin: "auto",
            textAlign: "center",
          }}
        >
          <div style={{ padding: "20px 0px" }}>
            <img src={logo} alt={"Logo"} style={{ height: 40 }} />
          </div>
        </div>
        <div
          style={{
            width: 600,
            margin: "auto",
            padding: "20px 15px",
            background: variables.bodyBackgroundColor,
          }}
        >
          <p
            style={{
              fontSize: 20,
              color: variables.textColor,
            }}
          >
            Hi Jane,
          </p>
          <p
            style={{
              fontSize: 14,
              color: variables.textColor,
            }}
          >
            Lorem ipsum, dolor sit amet consectetur adipisicing elit. Quia a
            assumenda nulla in quisquam optio quibusdam fugiat perspiciatis
            nobis, ad tempora culpa porro labore. Repudiandae accusamus
            obcaecati voluptatibus accusantium perspiciatis.
          </p>
          <p>
            <div
              style={{
                width: 300,
                color: variables.buttonColor,
                background: variables.buttonBackgroundColor,
                margin: "auto",
                padding: "12px 0px",
                fontSize: 14,
                fontWeight: "bold",
                borderRadius: 3,
                cursor: "pointer",
                textAlign: "center",
              }}
            >
              Click on a button
            </div>
          </p>
          <p
            style={{
              fontSize: 14,
              color: variables.textColor,
            }}
          >
            Tempora culpa porro labore. Repudiandae accusamus obcaecati
            voluptatibus accusantium perspiciatis:
          </p>
          <p
            style={{
              color: variables.linkColor,
              cursor: "pointer",
            }}
          >
            Click me
          </p>
        </div>
        <div
          style={{
            width: 600,
            margin: "auto",
            textAlign: "center",
            color: variables.textColor,
            fontSize: 10,
            padding: "20px 0px",
          }}
        >
          This email was sent by Whaly
        </div>
      </div>
    </div>
  );
}
