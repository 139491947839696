import {
  CopyOutlined,
  EllipsisOutlined,
  SaveOutlined,
} from "@ant-design/icons";
import { type AgGridReact } from "ag-grid-react";
import { Button, Dropdown, Space } from "antd";
import { inject } from "mobx-react";
import { POLICIES } from "../../../../../../auth/authorization";
import { compose } from "../../../../../../components/compose/WlyCompose";
import type { IObject, IObjectView } from "../../../../../../interfaces/object";
import { IUserRoleType } from "../../../../../../interfaces/user";
import type { ObjectUIStoreProps } from "../../../../../../store/objectUIStore";
import type { InjectedOrgProps } from "../../../../../orgs/WithOrg";
import WithOrg from "../../../../../orgs/WithOrg";
import { hasRoleAccessBoolean } from "../../../../../user-settings/HasRoleAccess";

type ISaveButtonProps = {
  object: IObject;
  activeView?: IObjectView;
  query: string;
  isStale: boolean;
  onReset: () => void;
  gridRef: React.RefObject<AgGridReact>;
};

type Props = ISaveButtonProps & ObjectUIStoreProps & InjectedOrgProps;

function SaveButton({
  activeView,
  object,
  query,
  objectUIStore,
  isStale,
  user,
  org,
  onReset,
  gridRef,
}: Props) {
  const isBuilder = hasRoleAccessBoolean(IUserRoleType.BUILDER, user, org.id);
  const canPromote = POLICIES["objectview:promote"]({
    user: user,
    orgId: org.id,
    view: activeView ?? "system",
  });
  const downloadExcel = () => {
    gridRef?.current?.api.exportDataAsExcel();
  };

  const enableExcel = () => {
    return !!gridRef?.current?.api.exportDataAsExcel;
  };

  if (activeView) {
    const canSave = POLICIES["objectview:updateconfig"]({
      user: user,
      orgId: org.id,
      view: activeView,
    });
    const canShare = POLICIES["objectview:share"]({
      user: user,
      orgId: org.id,
      view: activeView,
      object: object,
    });
    const canRename = POLICIES["objectview:rename"]({
      user: user,
      orgId: org.id,
      view: activeView,
    });
    const canDelete = POLICIES["objectview:delete"]({
      user: user,
      orgId: org.id,
      view: activeView,
    });

    return (
      <Button.Group size="small">
        <Button
          onClick={() => {
            isStale && canSave
              ? objectUIStore.setUpdateViewOpen({
                  id: activeView.id,
                  query: query,
                })
              : objectUIStore.setCreateViewOpen({
                  title: isStale ? "Save as" : "Make a copy",
                  type: activeView.viewType,
                  values: {
                    name: `${activeView.name} (copy)`,
                    query: query,
                  },
                });
          }}
        >
          {isStale && canSave ? (
            <Space>
              <SaveOutlined /> Save
            </Space>
          ) : (
            <Space>
              {isStale ? (
                <>
                  <SaveOutlined /> Save as
                </>
              ) : (
                <>
                  <CopyOutlined /> Make a copy
                </>
              )}
            </Space>
          )}
        </Button>
        <Dropdown
          trigger={["click"]}
          menu={{
            items: [
              {
                key: "save",
                label: "Save",
                style: canSave ? undefined : { display: "none" },
                disabled: !isStale,
                onClick: () => {
                  objectUIStore.setUpdateViewOpen({
                    id: activeView.id,
                    query: query,
                  });
                },
              },
              {
                key: "saveas",
                label: isStale ? "Save as..." : "Make a copy",
                onClick: () => {
                  objectUIStore.setCreateViewOpen({
                    title: isStale ? "Save as" : "Make a copy",
                    type: activeView.viewType,
                    values: {
                      name: `${activeView.name} (copy)`,
                      query: query,
                    },
                  });
                },
              },
              {
                key: "revert",
                label: "Revert to last save",
                disabled: !isStale,
                onClick: () => {
                  onReset();
                },
              },
              {
                type: "divider",
                style: canShare || canPromote ? undefined : { display: "none" },
              },
              {
                key: "share",
                label: "Set visibility",
                style: canShare ? undefined : { display: "none" },
                onClick: () => {
                  objectUIStore.setShareViewOpen({
                    id: activeView.id,
                    isPersonal: activeView.isPersonal,
                  });
                },
              },
              {
                key: "promote",
                label: "Set as default",
                style: canPromote ? undefined : { display: "none" },
                disabled: activeView.id === object.defaultView?.id,
                onClick: () => {
                  objectUIStore.setPromoteViewOpen({
                    id: activeView.id,
                  });
                },
              },
              {
                key: "export",
                label: "Export as excel",
                disabled: !enableExcel(),
                onClick: downloadExcel,
              },
              {
                type: "divider",
                style: canRename || canDelete ? undefined : { display: "none" },
              },
              {
                key: "rename",
                label: "Rename",
                style: canRename ? undefined : { display: "none" },
                onClick: () => {
                  objectUIStore.setRenameViewOpen({
                    id: activeView.id,
                    name: activeView.name,
                  });
                },
              },

              {
                key: "delete",
                label: "Delete",
                style: canDelete ? undefined : { display: "none" },
                danger: true,
                onClick: () => {
                  objectUIStore.setDeleteViewOpen({ id: activeView.id });
                },
              },
            ],
          }}
        >
          <Button icon={<EllipsisOutlined rotate={90} />} />
        </Dropdown>
      </Button.Group>
    );
  }

  return (
    <Button.Group size="small">
      <Button
        onClick={() =>
          objectUIStore.setCreateViewOpen({
            title: isStale ? "Save as" : "Make a copy",
            values: {
              name: isStale ? "" : `All ${object.name} (copy)`,
              query: query,
            },
          })
        }
      >
        {isStale ? (
          <Space>
            <SaveOutlined />
            Save as
          </Space>
        ) : (
          <Space>
            <CopyOutlined /> Make a copy
          </Space>
        )}
      </Button>

      <Dropdown
        trigger={["click"]}
        menu={{
          items: [
            ...(isBuilder
              ? [
                  {
                    key: "promote",
                    label: "Set as default",
                    disabled: !canPromote || !object.defaultView,
                    onClick: () => {
                      objectUIStore.setPromoteViewOpen({
                        id: "system",
                      });
                    },
                  },
                ]
              : []
            ).concat({
              key: "export",
              label: "Export as excel",
              disabled: !enableExcel(),
              onClick: downloadExcel,
            }),
          ],
        }}
      >
        <Button icon={<EllipsisOutlined rotate={90} />} />
      </Dropdown>
    </Button.Group>
  );
}

export default compose<Props, ISaveButtonProps>(
  inject("objectUIStore"),
  WithOrg
)(SaveButton);
