import type { Query } from "@cubejs-client/core";
import type { SeriesSunburstOptions } from "highcharts";
import Highcharts from "highcharts";
import { HighchartsReact } from "highcharts-react-official";
import HighchartsSunburst from "highcharts/modules/sunburst";
import _ from "lodash";
import * as React from "react";
import type { ChartOption } from "../../../containers/chart-options/ChartOptions";
import type { Meta } from "../../../containers/exploration/single/visualization/chart/Chart";
import type { UserLocale } from "../../../interfaces/user";
import type PaletteGenerator from "../../palette/utils/PaletteGenerator";
import type { Formatter } from "../domain";
import { optionsHelper, renderSerieTooltip } from "../utils/optionsHelper";

HighchartsSunburst(Highcharts);

interface ISunburstChartProps {
  locale: UserLocale;
  height: number;
  width?: number;
  data: Meta["tableRows"];
  config: SunburstChartConfig;
  onDrill?: (q: Query) => void;
  chartOptions?: ChartOption;
  palette: PaletteGenerator;
}

interface SunburstChartConfig {
  metricKey: string;
  label?: string;
  canDrill: (xValue: string[]) => Query | null;
  formatter: Formatter;
  dimensionKeys: string[];
}

type IState = Highcharts.Options;

const ID_SEPARATOR = "|::|";

const formatData = (
  data: Meta["tableRows"],
  config: SunburstChartConfig,
  chartOptions: ChartOption,
  palette: PaletteGenerator
): SeriesSunburstOptions["data"] => {
  if (data?.length === 0) {
    return [];
  }

  const dataPoints: SeriesSunburstOptions["data"] = [];

  const metricKey = config.metricKey;
  const dimensionKeys = config.dimensionKeys;

  const parentValues = data
    .map((item) => item[dimensionKeys[0]] ?? "∅")
    .filter((value, index, self) => self.indexOf(value) === index);

  // we iterate over each datapoint in order to create
  // the children parent data we need
  // we deduplicate the datapoints at the end
  data.forEach((d) => {
    // we need to generate the values for each couples of dimensions
    // we replace null values in dimensions with ∅
    const dimValues = dimensionKeys.map((dk) => d[dk] ?? "∅");
    const metricValue = d[metricKey];

    while (dimValues.length >= 1) {
      let dataPoint = {
        id: dimValues.join(ID_SEPARATOR),
        parent: dimValues.slice(0, -1).join(ID_SEPARATOR),
        name: dimValues[dimValues.length - 1] as string,
        label: dimValues[dimValues.length - 1] as string,
        value: null,
        color: null,
      };

      if (dimValues.length === dimensionKeys.length) {
        // we are at the topmost level of the data tree
        // we include the value here and only here
        // we convert the value to a Number
        dataPoint.value = +metricValue;
      } else if (dimValues.length === 1) {
        // we are at the bottommost level of the datatree
        // we apply color and serie customization here
        const parentValue = dimValues[0] as string;
        const options = chartOptions?.series?.[parentValue];
        const label = options?.label?.length ? options?.label : parentValue;
        const color =
          chartOptions?.series?.[parentValue]?.color ??
          palette.getColorAtIndex(parentValues.indexOf(parentValue));

        dataPoint.label = label;
        dataPoint.color = color;
        dataPoint.parent = null;
      }

      dataPoints.push(dataPoint);
      dimValues.pop();
    }
  });
  return _.uniqBy(dataPoints, "id");
};

const generateOptions = (
  data: Meta["tableRows"],
  config: SunburstChartConfig,
  onDrill: (q: Query) => void | undefined,
  width: number,
  height: number,
  chartOptions: ChartOption,
  optionHelpers,
  palette: PaletteGenerator,
  locale: UserLocale
) => {
  const formattedData = formatData(data, config, chartOptions, palette);
  const options: Highcharts.Options = {
    ...optionHelpers,
    chart: {
      ...optionHelpers.chart,
      type: "sunburst",
      height: height,
      width: width,
      backgroundColor: "transparent",
      shadow: false,
    },
    plotOptions: {
      ...optionHelpers.plotOptions,
      sunburst: {
        cursor: typeof onDrill === "function" ? "pointer" : "auto",
        // we create a hole at the center of the chart
        innerSize: width
          ? Math.round((height ? Math.min(width, height) : width) * 0.3)
          : 80,
      },
    },
    series: [
      {
        type: "sunburst",
        borderRadius: 3,
        borderColor: "white",
        states: {
          hover: {
            borderColor: "white",
          },
        },
        dataLabels: {
          enabled: chartOptions?.label ?? true,
          style: {
            textOutline: 0,
          },
        },
        name: config?.label,
        data: formattedData,
        tooltip: renderSerieTooltip({
          data: data,
          locale: locale,
          seriesLength: 1,
          valueGetter: "this.value",
          hideSerieColor: true,
          formatter: config.formatter,
        }),
        // we enable drilltonode as whaly drill does not currently
        // work well on dimensions that are not at the topmost of the chart
        allowDrillToNode: true,
        events: {
          click: onDrill
            ? (e) => {
                // we prevent whaly drill if we have a chart drill
                if (typeof (e as any).point.drillId === "string") {
                  return;
                } else {
                  const value = (e as any).point.id
                    ?.split(ID_SEPARATOR)
                    .map((v) => (v === "∅" ? null : v));
                  const q = config.canDrill(value);
                  if (q) {
                    return onDrill(q);
                  }
                }
              }
            : undefined,
        },
      },
    ],
  };
  return options;
};

export default class SunburstChart extends React.Component<
  ISunburstChartProps,
  IState
> {
  ref?: Highcharts.Chart;

  shouldComponentUpdate(nextProps: Readonly<ISunburstChartProps>): boolean {
    if (!_.isEqual(this.props.width, nextProps.width)) return true;
    if (!_.isEqual(this.props.height, nextProps.height)) return true;
    if (!_.isEqual(this.props.data, nextProps.data)) return true;
    if (!_.isEqual(this.props.chartOptions, nextProps.chartOptions))
      return true;
    if (
      !_.isEqualWith(this.props.palette, nextProps.palette, (a, b) =>
        typeof a === "function" || typeof b === "function" ? true : undefined
      )
    ) {
      return true;
    }
    return false;
  }

  public render() {
    const {
      data,
      palette,
      height,
      width,
      config,
      chartOptions,
      locale,
      onDrill,
    } = this.props;

    const optionHelpers = optionsHelper(
      height,
      width,
      locale,
      "ordinal",
      config.formatter,
      undefined,
      undefined,
      chartOptions
    );

    const options = generateOptions(
      data,
      config,
      onDrill,
      width,
      height,
      chartOptions,
      optionHelpers,
      palette,
      locale
    );

    return (
      <div style={{ height, width: "100%" }}>
        <HighchartsReact
          ref={(r) => (this.ref = r?.chart)}
          highcharts={Highcharts}
          options={options}
        />
      </div>
    );
  }
}
