import { Button, Col, Space, Typography } from "antd";
import React, { useEffect } from "react";
import type { InjectedAntUtilsProps } from "../../../../components/ant-utils/withAntUtils";
import { withAntUtils } from "../../../../components/ant-utils/withAntUtils";
import { WlyCard } from "../../../../components/cards/WlyCard";
import { compose } from "../../../../components/compose/WlyCompose";
import DescriptionEditor from "../../../../components/studio/description/DescriptionEditor";
import { UserRenderer } from "../../../../components/user/UserRenderer";
import type { IExploration } from "../../../../interfaces/explorations";
import { IOrgFeatureType } from "../../../../interfaces/org";
import type { InjectedOrgProps } from "../../../orgs/WithOrg";
import WithOrg from "../../../orgs/WithOrg";
import OwnerSelectionModal from "../../../users/OwnerSelectionModal";
import "./ExplorationGeneralInformation.scss";
import ExplorationQueryModeCard from "./query-mode/explorationQueryModeCard";
import RowLevelFilterDisplay from "./row-level-filters/RowLevelFilterDisplay";
import RowLevelFilters from "./row-level-filters/RowLevelFilters";
import { buildAvailableDimension } from "./row-level-filters/domain";

const { Title } = Typography;
interface IExplorationGeneralInformationProps {
  exploration: IExploration;
  onExplorationExtractDataNow: (explorationId: string) => Promise<void>;
  onExplorationUpdate: (id: string, payload: any) => Promise<void>;
  fetchExploration: (explorationId: string) => Promise<void>;
}

type Props = IExplorationGeneralInformationProps &
  InjectedOrgProps &
  InjectedAntUtilsProps;

function ExplorationGeneralInformation(props: Props) {
  const {
    exploration,
    onExplorationUpdate,
    onExplorationExtractDataNow,
    fetchExploration,
    orgFeatures,
  } = props;
  const [descriptionModalOpen, setDescriptionModalOpened] =
    React.useState<boolean>(false);
  const [rowLevelAccess, setRowLevelAccess] = React.useState<boolean>(false);
  const [ownerModalOpen, setOwnerModalOpened] = React.useState<boolean>();

  // Used to refresh the exploration periodically when it has an active extract going on
  useEffect(() => {
    const interval = setInterval(() => {
      if (
        exploration.extractSchedule?.status === "running" ||
        exploration.extractSchedule?.status === "scheduled"
      ) {
        fetchExploration(exploration.id);
      }
    }, 1_000);
    return () => {
      clearInterval(interval);
    };
  }, [exploration.extractSchedule?.status]);

  const allDimensions = buildAvailableDimension(exploration);

  return (
    <div className="exploration-general-information">
      <Col span={16} offset={4}>
        <Space direction="vertical" size={32} style={{ width: "100%" }}>
          <div>
            <Title style={{ paddingTop: 16 }} level={3}>
              {exploration.name}
            </Title>
          </div>
          <WlyCard
            title="Description"
            extra={
              <Button
                type="primary"
                size="small"
                onClick={() => setDescriptionModalOpened(true)}
              >
                Edit
              </Button>
            }
          >
            <div
              dangerouslySetInnerHTML={{
                __html: exploration.description
                  ? exploration.description
                  : "No description",
              }}
            />
          </WlyCard>
          {orgFeatures.includes(IOrgFeatureType.SERVING_LAYER_API_NAME) ? (
            <ExplorationQueryModeCard
              exploration={exploration}
              onExplorationExtractDataNow={onExplorationExtractDataNow}
              onExplorationUpdate={onExplorationUpdate}
            />
          ) : undefined}
          <WlyCard
            title="Row level security"
            extra={
              <Button
                type="primary"
                size="small"
                onClick={() => setRowLevelAccess(true)}
              >
                Edit
              </Button>
            }
          >
            <RowLevelFilterDisplay
              rowLevelFilters={exploration.rowLevelFilters}
              columns={allDimensions}
            />
          </WlyCard>
          <WlyCard
            title="Owner"
            extra={
              <Button
                type="primary"
                size="small"
                onClick={() => setOwnerModalOpened(true)}
              >
                Edit
              </Button>
            }
          >
            {exploration.owner ? (
              <UserRenderer user={exploration.owner} />
            ) : (
              "No owner defined"
            )}
          </WlyCard>
          <DescriptionEditor
            initialData={
              exploration.description
                ? { description: exploration.description }
                : undefined
            }
            visible={descriptionModalOpen}
            onSave={(data) =>
              onExplorationUpdate
                ? onExplorationUpdate(exploration.id, {
                    description: data.description,
                  }).then(() => setDescriptionModalOpened(false))
                : Promise.resolve().then(() => setDescriptionModalOpened(false))
            }
            onCancel={() => setDescriptionModalOpened(false)}
          />
          <OwnerSelectionModal
            visible={!!ownerModalOpen}
            onClose={() => setOwnerModalOpened(false)}
            currentOwnerId={exploration?.owner?.id}
            onOwnerSelection={(id) =>
              onExplorationUpdate(exploration?.id, {
                owner: { connect: { id: id } },
              })
            }
          />
          <RowLevelFilters
            visible={rowLevelAccess}
            columns={allDimensions}
            rowLevelFilters={exploration.rowLevelFilters}
            onClose={() => setRowLevelAccess(false)}
            onSave={async (v) =>
              onExplorationUpdate(exploration.id, {
                rowLevelFilters: v,
              }).then(() => setRowLevelAccess(false))
            }
          />
          <div />
        </Space>
      </Col>
    </div>
  );
}

export default compose<Props, IExplorationGeneralInformationProps>(
  WithOrg,
  withAntUtils
)(ExplorationGeneralInformation);
