import type {
  ScheduleDailySendEvery,
  SchedulePeriod,
} from "../../../../interfaces/schedule";
import type { ITable } from "../../../../interfaces/table";

export type QueryMode = "DIRECT_QUERY" | "SERVING_LAYER";

export interface DataExtractConfig {
  period: SchedulePeriod;
  atHour?: number;
  dailySendEvery?: ScheduleDailySendEvery;
  timezone?: string;
}

export const getTotalServingLayerSpaceUsedBytes = (
  tables: ITable[]
): number => {
  return tables.reduce<number>((acc, curr) => {
    let parsedBytes = parseInt(curr?.activeExtract?.sizeBytes || "0");
    if (Number.isNaN(parsedBytes)) {
      parsedBytes = 0;
    }
    acc += parsedBytes;
    return acc;
  }, 0);
};
