import type { chartOptionRenderer } from "../../../containers/chart-options/ChartOptions";
import type { IAnalysisType } from "../../../containers/exploration/single/domain";
import { WlyFunnelChartIcon } from "../../icons/custom-icons/WlyFunnelChartIcon";
import type { SingleChartDefinition } from "../domain";

export const funnelChartDefinition: SingleChartDefinition = {
  label: "Funnel",
  allowTimeComparison: false,
  icon: <WlyFunnelChartIcon />,
  canPredict: () => false,
  pivot: (analysisType: IAnalysisType) => false,
  minDimensions: 0,
  maxDimensions: 0,
  minMetrics: 2,
  canDisplay: (rs) => {
    if ((rs as any).queryType === "compareDateRangeQuery") {
      return "Cannot use a date comparison query on a funnel";
    }
    return;
  },
  config: ["labels", "funnel-direction", "funnel-compare-with", "palette"],
  declarativeChartOptionRenderer: (options) => {
    const template: chartOptionRenderer = [
      {
        label: "Chart options",
        lines: [
          {
            type: "composed",
            items: [
              {
                flex: 1,
                type: "label",
                title: "Label",
              },
              { flex: 1, type: "option", option: "funnel-compare-with" },
              { flex: 0, type: "option", option: "labels" },
            ],
          },
          {
            type: "standard",
            component: "funnel-direction",
          },
          {
            type: "standard",
            component: "palette",
          },
        ],
      },
    ];
    return template;
  },
  axis: {
    bottom: {
      editable: false,
      isTimeFormattable: (a) => false,
    },
    left: {
      editable: false,
    },
    right: {
      editable: false,
    },
  },
  extractCustomizationDimensions: (d, q) => {
    return {
      dimensions: [],
    };
  },
  extractCustomizationSeries: (s, d) => {
    return {
      series: s,
      customColor: true,
      customLabel: true,
      customType: [],
    };
  },
};
