import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import type { Formatter } from "../../../../../../../../../../components/chart/domain";
import { customFormatter } from "../../../../../../../../../../components/chart/utils/optionsHelper";
import type { UserLocale } from "../../../../../../../../../../interfaces/user";
import type { kpiTheme } from "../../../domain";

export interface IBarSparklineRendererProps {
  config: {
    x: {
      key: string;
    };
    y: { key: string; formatter: Formatter };
    options?: {
      highlightLabel?: string;
    };
  };
  data: Array<{
    [key: string]: string | number | boolean;
  }>;
  locale: UserLocale;
  theme: kpiTheme;
}

export function BarSparklineRenderer(props: IBarSparklineRendererProps) {
  const { config, data, theme, locale } = props;

  const formattedData: Array<{ x: string; y: number }> = data.map(
    (datapoint) => {
      const x = datapoint[config.x.key]?.toString?.();
      const y = datapoint[config.y.key] as number;
      return { x, y };
    }
  );

  const barchartOptions: Highcharts.Options = {
    chart: {
      type: "column",
      height: 110,
      spacing: [0, 0, 0, 0],
      backgroundColor: "transparent",
      animation: false,
    },
    title: {
      text: undefined,
    },
    credits: {
      enabled: false,
    },
    xAxis: {
      visible: true,
      categories: formattedData.map((datapoint) => datapoint.x),
      labels: {
        enabled: true,
        rotation: 0,
        useHTML: true,
        formatter: function () {
          const label = this.axis.defaultLabelFormatter.call(this);
          const labelToHighlight = config.options?.highlightLabel;
          if (labelToHighlight && label === labelToHighlight) {
            return `<div style="background: ${theme.primary};color: ${theme.background};padding: 0px 3px; border-radius:6px; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;">${label}</div>`;
          }
          return label;
        },
        style: {
          color: theme.secondary,
          fontFamily: "Lato",
          fontSize: "11px",
        },
      },
      lineWidth: 0,
      title: {
        text: undefined,
      },
    },
    legend: {
      enabled: false,
    },
    yAxis: {
      visible: false,
      labels: {
        enabled: false,
      },
      title: {
        text: undefined,
      },
    },
    tooltip: {
      shared: true,
      useHTML: true,
      outside: false,
      shadow: false,
      backgroundColor: "#2E363B",
      borderRadius: 5,
      pointFormatter: function () {
        return `<b>${customFormatter(this.y, locale, config.y.formatter)}</b>`;
      },
      style: {
        color: "white",
        fontSize: "0.85em",
      },
    },
    plotOptions: {
      column: {
        groupPadding: 0.1,
        borderWidth: 0,
      },
    },
    series: [
      {
        type: "column",
        animation: false,
        color: theme.secondary,
        states: {
          hover: {
            enabled: false,
          },
        },
        data: formattedData.map((datapoint) => {
          let color = theme.secondary;
          if (config.options?.highlightLabel === datapoint.x) {
            color = theme.primary;
          }
          return {
            color: color,
            y: datapoint.y,
          };
        }),
      },
    ],
  };
  return <HighchartsReact highcharts={Highcharts} options={barchartOptions} />;
}
