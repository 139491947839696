import { EXPLORATION_ITEM_FRAGMENT } from "../../../containers/exploration/single/domain";
import type {
  IExploration,
  IExplorationSection,
} from "../../../interfaces/explorations";
import type { IReportFolder } from "../../../interfaces/folder";
import type { IObject } from "../../../interfaces/object";
import type { ISource } from "../../../interfaces/sources";

export interface WorkspaceDatastoreData {
  allReportFolders: IReportFolder[];
  myFolder: IReportFolder;
  allExplorations: IExploration[];
  allExplorationSections: IExplorationSection[];
  allSources: ISource[];
  allObjects?: IObject[];
}

export const ReportFolderFragment = `
fragment Folder on ReportFolder {
    id
    cover
    description
    name
    slug
    parent {
      id
    }
    user {
      id
    }
    description
    order
    image
    color
    canBeEditedByCurrentUser
    canBeManagedByCurrentUser
    updatedAt
    reports(where: {deleted_not: true}) {
      id
      name
      type
      slug
    }
}
`;

export const DESTINATION_FRAGMENT = `
fragment Destination on Destination {
  id
  isPersistenceEngineEnabled
  persistenceEngineSyncStatus
  persistenceEngineNextSyncDate

  persistenceEngineSyncPeriod
  persistenceEngineDefaultTargetDatabase
  persistenceEngineDefaultTargetSchema
  persistenceEngineLastJobExecution {
    status
  }

  isSignalEngineEnabled
  signalEngineDefaultTargetDatabase
  signalEngineDefaultTargetSchema

  isDbtCloudSyncEnabled
  dbtCloudSyncStatus
  dbtCloudSyncNextSyncDate
  dbtAccountId
  dbtApiKey
  dbtProjectId
  dbtCloudSyncPeriod

  dbtCloudSyncLastJobExecution {
    status
  }
}
`;

export const JOB_EXECUTION_FRAGMENT = `
fragment JobExecution on JobExecution {
  id
  identifier
  jobType
  objectType
  objectId
  status
  errorText
  errorDebugText
  errorType
  artifactsUri
  runResults
  createdAt
  updatedAt
}
`;

export const EXTRACT_SCHEDULE_FRAGMENT = `
fragment ExtractSchedule on Schedule {
    status
    period
    atHour
    dailySendEvery
    timezone
    nextScheduledAt
    lastJobExecution {
      status
      createdAt
    }
}
`;

export const EXPLORATION_EXTRACT_INFO_FRAGMENT = `
${EXTRACT_SCHEDULE_FRAGMENT}
fragment ExplorationExtractInfo on Exploration {
  isExtractEnabled
  extractSchedule {
    ...ExtractSchedule
  }
  tables(sortBy: name_ASC, where: {deleted_not: true}) {
    activeExtract {
      status
      sizeBytes
      rowCount
    }
  }
}
`;

export const CONNECTOR_FRAGMENT = `
  fragment ConnectorFragment on Source {
    id
    name
    status
    syncPeriod
    isEnabled
    lastSyncDate
    lastJobExecution {
      status
    }
    _datasetsMeta(where: { deleted_not: true }) {
      count
    }
    sourceMeta {
      executor
      publicInfo {
        logo
      }
    }
  }
`;

export const EXPLORATION_SECTION_FRAGMENT = `
fragment Section on ExplorationSection {
  id
  name
  image
  order
  description
}`;

const SOURCES_FRAGMENT = `
fragment Source on Source {
  id
  name
  status
  slug
  error
  isEnabled
  lastSyncDate
  syncPeriod
  lastJobExecution {
    status
  }
  sourceMeta {
    id
    publicInfo {
      logo
    }
  }
}
`;

export const REPORT_FOLDER = `
${ReportFolderFragment}

query getReportFolder($orgId: ID!, $userId: ID! ) {
  allReportFolders(sortBy: name_ASC, where: { org: { id: $orgId }, deleted_not: true, user_is_null: true }) {
    ...Folder
  }
  myFolder: allReportFolders(where: { org: { id: $orgId }, deleted_not: true, user: { id: $userId } }, first: 1, sortBy: [createdAt_ASC]) {
    ...Folder
  }
}
`;

const OBJECT_FRAGMENT = `
fragment Object on Object {
  id
  slug
  color
  icon
  canBeListed
  position
  name
  defaultView {
    id
    slug
    isDeleted
  }
}
`;

export const WORKSPACE_DETAILS = `
${ReportFolderFragment}

${EXPLORATION_SECTION_FRAGMENT}

${EXPLORATION_ITEM_FRAGMENT}

${SOURCES_FRAGMENT}

${OBJECT_FRAGMENT}

query getWorkspaceInfos($orgId: ID!, $userId: ID!, $notInlabels: [SourceMetaDataPublicInfoLabelType], $includeObjects: Boolean! ) {
  allReportFolders(sortBy: name_ASC, where: { org: { id: $orgId }, deleted_not: true, user_is_null: true }) {
    ...Folder
  }
  myFolder: allReportFolders(where: { org: { id: $orgId }, deleted_not: true, user: { id: $userId } }, first: 1, sortBy: [createdAt_ASC]) {
    ...Folder
  }
  allExplorations(sortBy: name_ASC, where: { org: { id: $orgId }, deleted_not: true, warehouse: { isDeleted_not: true } }) {
    ...Exploration
  }
  allExplorationSections(sortBy: name_ASC, where: { org: { id: $orgId }, deleted_not: true }) {
    ...Section
  }
  allSources(sortBy: name_ASC, where:{org: { id: $orgId }, isDeleted_not: true, warehouse: {isDeleted_not: true}, sourceMeta: { executor_not: WAREHOUSE, publicInfo: {label_not_in: $notInlabels}}}) {
    ...Source
  }
  allObjects(where:{org: { id: $orgId }, isDeleted_not: true, model: { deleted_not: true, warehouse: {isDeleted_not: true} }}) @include(if: $includeObjects) {
    ...Object
  }
}
`;
