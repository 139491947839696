import type { IRelationshipType } from "../../../../../../../interfaces/table";
import "./RelationshipTypeRenderer.scss";
export interface IRelationshipTypeRendererProps {
  type: IRelationshipType;
}

export function RelationshipTypeRenderer(
  props: IRelationshipTypeRendererProps
) {
  let wording: string = undefined;
  if (props.type === "1-1") {
    wording = "has one";
  } else if (props.type === "1-N") {
    wording = "has many";
  } else if (props.type === "N-1") {
    wording = "belongs to";
  }
  return (
    <div className="relationship-arrow">
      <div className="relationship-arrow-type">{wording}</div>
      <div className="relationship-arrow-icon">&rarr;</div>
    </div>
  );
}
