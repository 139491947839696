import type {
  BinaryFilter,
  DateRange,
  Query,
  SqlQuery,
  TimeDimension,
  TimeDimensionGranularity,
  UnaryFilter,
} from "@cubejs-client/core";
import { ResultSet } from "@cubejs-client/core";
import _ from "lodash";
import moment from "moment";
import type { ChartType } from "../../../../components/chart/domain";
import type { IComparisonPeriod } from "../../../../components/measures/comparison-selector/ComparisonSelector";
import type { MeasureItemSortValue } from "../../../../components/measures/measure-item/MeasureItem";
import type {
  IQueryTraceResult,
  LagoonCallOrigin,
  WlyResultSet,
} from "../../../../services/LagoonService";
import {
  lagoonServiceLoad,
  lagoonServiceSQL,
  traceQuery,
} from "../../../../services/LagoonService";
import { getComparisonDate } from "../../../../utils/cubejsUtils";
import { format } from "../../../../utils/periodUtils";
import type { IWlyDatePickerInputValue } from "../../../reports/view/filters/date-filter/WlyDatePicker";
import { convertWlyDatePickerValueToMoment } from "../../../reports/view/filters/date-filter/WlyDatePicker";
import type { SimpleDateRange } from "../../../reports/view/filters/domain";
import type {
  FilterOperator,
  IAnalysisType,
  ILagoonQueryExtra,
} from "../domain";
import { cleanSerializedResultSetFromCustomSorting } from "./chart/domain";

export const queryData = async (
  orgId: string,
  objectId: string,
  objectType: "VIEW" | "EXPLORATION",
  analysisType: IAnalysisType,
  chartType: ChartType,
  measures: string[],
  dimensions: string[],
  filters: (UnaryFilter | BinaryFilter)[],
  filterOperator: FilterOperator,
  dateRange: IWlyDatePickerInputValue,
  orderBy: Array<[string, MeasureItemSortValue]>,
  limit: number,
  origin: LagoonCallOrigin,
  comparisonPeriod?: IComparisonPeriod,
  timeDimension?: string,
  selectedGranularity?: TimeDimensionGranularity,
  pivotConfig?: string[],
  extra?: ILagoonQueryExtra,
  reportId?: string,
  useLagoonNext?: boolean
): Promise<{
  resultSet: WlyResultSet<any>;
  additionalQueryResultSet?: WlyResultSet<any>;
  getSQL: () => Promise<SqlQuery>;
  getTrace: () => Promise<IQueryTraceResult>;
}> => {
  // when sorting we sort on a custom dimension (dimname + __sort)
  const sortMapping: { [key: string]: string } = {};
  orderBy.forEach((o) => (sortMapping[o[0]] = o[0] + "__sort"));

  const formattedOrderedBy: Array<[string, MeasureItemSortValue]> = [];
  measures.forEach((m) => {
    const foundOrder = orderBy.find((ob) => ob[0] === m);
    if (foundOrder) {
      formattedOrderedBy.push(foundOrder);
    }
  });
  dimensions.forEach((d) => {
    const foundOrder = orderBy.find((ob) => ob[0] === d);
    if (foundOrder) {
      const hijackedOrder = sortMapping[foundOrder[0]];
      if (foundOrder && hijackedOrder) {
        formattedOrderedBy.push([hijackedOrder, foundOrder[1]]);
      } else if (foundOrder) {
        formattedOrderedBy.push(foundOrder);
      }
    }
  });

  enum AdditionalQueryType {
    sparkLine,
    tableGrandTotal,
    pivotedTableGrandTotal,
    retention,
    waterfallTimeserie,
    // No operation, do nothing
    noop,
  }

  const getSecondaryQueryType = (): AdditionalQueryType => {
    if (analysisType === "METRIC" && timeDimension) {
      return AdditionalQueryType.sparkLine;
    } else if (chartType === "waterfall-timeserie") {
      return AdditionalQueryType.waterfallTimeserie;
    } else if (chartType === "retention") {
      return AdditionalQueryType.retention;
    } else if (chartType === "table" && !pivotConfig?.length) {
      return AdditionalQueryType.tableGrandTotal;
    } else if (chartType === "table" && pivotConfig?.length) {
      return AdditionalQueryType.pivotedTableGrandTotal;
    } else {
      return AdditionalQueryType.noop;
    }
  };

  const computeSparklineGranularity = () => {
    if (!dates) {
      return "month";
    }

    const startDate = (dates as unknown as SimpleDateRange)[0];
    const endDate = (dates as unknown as SimpleDateRange)[1];

    const duration = moment.duration(endDate.diff(startDate));

    if (duration.asDays() <= 30) {
      return "day";
    } else if (duration.asWeeks() <= 52) {
      return "week";
    } else if (duration.asMonths() <= 36) {
      return "month";
    }
    return "year";
  };

  const dates = dateRange
    ? convertWlyDatePickerValueToMoment(dateRange)
    : dateRange;

  const getTimeDimensions = (
    granularity: TimeDimensionGranularity | undefined
  ): TimeDimension[] | undefined => {
    if (!timeDimension) {
      return undefined;
    } else {
      if (comparisonPeriod) {
        return [
          {
            dimension: timeDimension,
            compareDateRange: getCompareDateRange(),
            granularity,
          },
        ];
      } else {
        return [
          {
            dimension: timeDimension,
            dateRange: getDateRange(),
            granularity,
          },
        ];
      }
    }
  };

  const getDateRange = (): DateRange | undefined => {
    if (!dates) {
      return undefined;
    } else {
      return [
        (dates as any)[0].format(format),
        (dates as any)[1].format(format),
      ];
    }
  };

  const getCompareDateRange = (): DateRange[] | undefined => {
    if (!dates) {
      return undefined;
    } else {
      const currentDateRange: DateRange = [
        (dates as SimpleDateRange)[0].format(format),
        (dates as SimpleDateRange)[1].format(format),
      ];
      const previousDateRange: DateRange = [
        getComparisonDate(dates as SimpleDateRange, comparisonPeriod)[0].format(
          format
        ),
        getComparisonDate(dates as SimpleDateRange, comparisonPeriod)[1].format(
          format
        ),
      ];

      const result = [currentDateRange, previousDateRange];
      return result;
    }
  };

  /**
   * This query is used to calculate an additional aggregation
   * For table viz, it will calculate a grouped aggregate to get a total
   * For metrics viz with timeseries, it will calculate a result breakdown by time to get a sparkline
   * @returns
   */
  const generateAdditionalResultPromise = ():
    | Promise<WlyResultSet<any>>
    | Promise<undefined> => {
    const additionalQueryType = getSecondaryQueryType();

    switch (additionalQueryType) {
      case AdditionalQueryType.waterfallTimeserie:
        return lagoonServiceLoad(
          orgId,
          {
            measures: measures,
            dimensions: dimensions,
            timeDimensions: selectedGranularity
              ? getTimeDimensions(selectedGranularity)
              : [],
            limit: limit,
            filters: [
              {
                [filterOperator]: filters,
              } as any,
            ],
            order: formattedOrderedBy.length ? formattedOrderedBy : undefined,
          },
          objectType,
          objectId,
          objectType === "EXPLORATION" ? objectId : undefined,
          origin,
          reportId,
          undefined,
          useLagoonNext
        );
      case AdditionalQueryType.retention:
        return lagoonServiceLoad(
          orgId,
          {
            measures: [measures[0]],
            dimensions: dimensions,
            limit: limit,
            filters: [
              {
                [filterOperator]: filters,
              } as any,
            ],
            timeDimensions: getTimeDimensions(undefined),
            order: formattedOrderedBy.length ? formattedOrderedBy : undefined,
          },
          objectType,
          objectId,
          objectType === "EXPLORATION" ? objectId : undefined,
          origin,
          reportId,
          undefined,
          useLagoonNext
        );
      case AdditionalQueryType.sparkLine:
        return lagoonServiceLoad(
          orgId,
          {
            measures,
            // We remove all dimensions, except the time dimension
            dimensions: [],
            timeDimensions: getTimeDimensions(computeSparklineGranularity()),
            limit: limit,
            filters: [
              {
                [filterOperator]: filters,
              } as any,
            ],
            order: formattedOrderedBy.length ? formattedOrderedBy : undefined,
          },
          objectType,
          objectId,
          objectType === "EXPLORATION" ? objectId : undefined,
          origin,
          reportId,
          undefined,
          useLagoonNext
        );
      case AdditionalQueryType.tableGrandTotal:
        return lagoonServiceLoad(
          orgId,
          {
            measures,
            // To get a grand total, we remove all dimensions
            dimensions: [],
            timeDimensions: getTimeDimensions(undefined),
            limit: limit,
            filters: [
              {
                [filterOperator]: filters,
              } as any,
            ],
            order: formattedOrderedBy.length ? formattedOrderedBy : undefined,
          },
          objectType,
          objectId,
          objectType === "EXPLORATION" ? objectId : undefined,
          origin,
          reportId,
          undefined,
          useLagoonNext
        );
      case AdditionalQueryType.pivotedTableGrandTotal:
        return lagoonServiceLoad(
          orgId,
          {
            measures,
            // To get a grand total, we remove all dimensions
            dimensions: pivotConfig ?? [],
            timeDimensions: getTimeDimensions(undefined),
            limit: limit,
            filters: [
              {
                [filterOperator]: filters,
              } as any,
            ],
            order: formattedOrderedBy.length ? formattedOrderedBy : undefined,
          },
          objectType,
          objectId,
          objectType === "EXPLORATION" ? objectId : undefined,
          origin,
          reportId,
          undefined,
          useLagoonNext
        );
      default:
        return Promise.resolve(undefined);
    }
  };

  const additionalSortDimensions = dimensions.flatMap((d) => {
    const sortDim = sortMapping[d];
    if (sortDim) return [sortDim];
    return [];
  });

  let dimensionsToQuery = _.uniq([...dimensions, ...additionalSortDimensions]);

  let measuresToQuery = measures;

  if (chartType === "waterfall-timeserie") {
    dimensionsToQuery = [];
    measuresToQuery = measures;
  }

  if (chartType === "retention") {
    dimensionsToQuery = [dimensions[0]];
    measuresToQuery = [measures[1]];
  }

  if (extra) {
    const dimensionsToAdd = Object.keys(extra).reduce(
      (acc, currentValue) => [
        ...acc,
        ...(extra[currentValue].dimensions?.length > 0
          ? extra[currentValue].dimensions
          : []),
      ],
      []
    );
    dimensionsToQuery = _.uniq([...dimensions, ...dimensionsToAdd]);
    const measuresToAdd = Object.keys(extra).reduce(
      (acc, currentValue) => [
        ...acc,
        ...(extra[currentValue].metrics?.length > 0
          ? extra[currentValue].metrics
          : []),
      ],
      []
    );
    measuresToQuery = _.uniq([...measures, ...measuresToAdd]);
  }

  const mainQuery: Query = {
    measures: measuresToQuery,
    dimensions: dimensionsToQuery,
    timeDimensions: getTimeDimensions(selectedGranularity),
    limit: limit,
    filters: [
      {
        [filterOperator]: filters,
      } as any,
    ],
    order: formattedOrderedBy.length ? formattedOrderedBy : undefined,
  };

  const generateResultPromise = (): Promise<WlyResultSet<any>> => {
    return lagoonServiceLoad(
      orgId,
      mainQuery,
      objectType,
      objectId,
      objectType === "EXPLORATION" ? objectId : undefined,
      origin,
      reportId,
      undefined,
      useLagoonNext
    );
  };

  const generateGetSQLPromise = (): Promise<SqlQuery> => {
    return lagoonServiceSQL(
      orgId,
      mainQuery,
      objectType,
      objectId,
      objectType === "EXPLORATION" ? objectId : undefined,
      origin,
      reportId,
      undefined,
      useLagoonNext
    );
  };

  const [resultSetResponse, additionalQueryResponse] = await Promise.allSettled(
    [generateResultPromise(), generateAdditionalResultPromise()]
  );

  // TODO: Return the values of SQL even when the resultSet is in error
  // This need to change the return type of this function
  if (resultSetResponse.status === "rejected") {
    throw new Error(resultSetResponse.reason);
  }

  if (additionalQueryResponse.status === "rejected") {
    throw new Error(additionalQueryResponse.reason);
  }

  const clean = (q: WlyResultSet<any>): WlyResultSet<any> => {
    const r = ResultSet.deserialize(
      cleanSerializedResultSetFromCustomSorting(q.serialize())
    );
    (r as any).id = q.id;
    (r as any).duration = q.duration;
    return r as WlyResultSet<any>;
  };

  return {
    resultSet: clean(resultSetResponse.value),
    additionalQueryResultSet: additionalQueryResponse.value
      ? clean(additionalQueryResponse.value)
      : undefined,
    getSQL: generateGetSQLPromise,
    getTrace: async () =>
      await traceQuery(resultSetResponse.value.id, useLagoonNext),
  };
};
