import { Typography } from "antd";
import type { FunctionComponent } from "react";
import type { CacheStrategy } from "../../../containers/workbench/workbench/domain";

const { Text } = Typography;

interface Props {
  cacheStrategy: CacheStrategy;
}

const CacheStrageyRenderer: FunctionComponent<Props> = (props) => {
  const { cacheStrategy } = props;
  if (!cacheStrategy) {
    return <div>No cache strategy</div>;
  }
  if (cacheStrategy.type === "every") {
    return (
      <>
        This dataset cache will be renewed <b>every {cacheStrategy.value}s</b>
      </>
    );
  }
  if (cacheStrategy.type === "column") {
    return (
      <>
        This dataset cache will be renewed when the value of{" "}
        <Text code>MAX({cacheStrategy.value})</Text> has changed
      </>
    );
  }
  if (cacheStrategy.type === "sql") {
    return (
      <>
        <div>
          This dataset cache will be renewed when the value ouputed by the
          following SQL query will change
        </div>
        <div>
          <Text code>{cacheStrategy.value}</Text>
        </div>
      </>
    );
  }
  if (cacheStrategy.type === "whaly_synced") {
    return (
      <>
        <div>
          This dataset cache will be renewed{" "}
          <b>when the associated Whaly connector will have loaded new data</b>{" "}
          into it.
        </div>
      </>
    );
  }
  return <>Unknown cache strategy</>;
};

export default CacheStrageyRenderer;
