import type { BinaryFilter } from "@cubejs-client/core";
import { Button, Form, Modal, Space } from "antd";
import { useForm } from "antd/es/form/Form";
import * as React from "react";
import { compose } from "../../../../../components/compose/WlyCompose";
import type { AvailableDimension } from "../../../../../components/measures/filter-item/FilterItem";
import type { InjectedOrgProps } from "../../../../orgs/WithOrg";
import WithOrg from "../../../../orgs/WithOrg";
import type { AvailableProperty } from "../../../../v2-demo/container/object/viewer/domain";
import RowLevelFilterInput from "./RowLevelFilterInput";
import type { RowLevelFilter } from "./domain";
import { parseRowLevelFilterValue } from "./domain";

interface IRowLevelFiltersProps {
  visible: boolean;
  columns: Array<AvailableDimension | AvailableProperty>;
  rowLevelFilters: string;
  onClose: () => void;
  onSave: (rowLevelFilters: string) => Promise<any>;
}

type Props = InjectedOrgProps & IRowLevelFiltersProps;

function RowLevelFilters(props: Props) {
  const { visible, onClose, org, user, onSave, columns, rowLevelFilters } =
    props;

  const [form] = useForm<RowLevelFilter>();

  const [saving, setSaving] = React.useState<boolean>(false);

  const allDimensions = columns;

  const onFinish = async (values: any) => {
    setSaving(true);
    try {
      await form.validateFields();
      await onSave(JSON.stringify(values));
      onClose();
      setSaving(false);
    } catch (err) {
      console.error(err);
      setSaving(false);
    }
  };

  const generateMatchingDefault = (): BinaryFilter | undefined => {
    const matchingDimensionsWithUserAttributes = allDimensions.find((ad) => {
      if (org.userAttributeMetas.find((am) => am.type === ad.domain)) {
        return true;
      }
      return false;
    });
    if (matchingDimensionsWithUserAttributes) {
      const userAttribute = org.userAttributeMetas.find(
        (am) => am.type === matchingDimensionsWithUserAttributes.domain
      );
      if (userAttribute) {
        return {
          member: matchingDimensionsWithUserAttributes.key,
          operator: "equals",
          values: [userAttribute.id],
        };
      }
    }
    return undefined;
  };

  const renderInner = () => {
    return (
      <Form
        form={form}
        initialValues={parseRowLevelFilterValue(rowLevelFilters)}
        onFinish={onFinish}
      >
        <Form.Item style={{ marginBottom: 0 }} shouldUpdate={true}>
          {() => {
            const currentValue = form.getFieldValue(["operator"]);
            console.log(currentValue);
            return (
              <Form.Item style={{ marginBottom: 0 }} name="operator">
                <div>
                  Filter rows matching{" "}
                  <Button
                    onClick={() => {
                      form.setFieldsValue({
                        operator: currentValue === "or" ? "and" : "or",
                      });
                    }}
                    size="small"
                  >
                    {currentValue === "or" ? "any" : "all"}
                  </Button>{" "}
                  of the following attributes:
                </div>
              </Form.Item>
            );
          }}
        </Form.Item>
        <Form.Item style={{ marginBottom: 0 }} shouldUpdate={true}>
          {() => {
            return (
              <Form.List name="condition">
                {(fields, { add, remove }) => (
                  <Space style={{ width: "100%" }} direction="vertical">
                    {fields.map((field, i) => {
                      return (
                        <RowLevelFilterInput
                          key={field.key}
                          filter={form.getFieldValue(["condition", field.name])}
                          columns={columns}
                          onDelete={() => remove(i)}
                          attributeMetas={org.userAttributeMetas}
                          onChange={(v) => {
                            form.setFieldValue(["condition", field.name], v);
                          }}
                        />
                      );
                    })}
                    <Form.Item>
                      <Button
                        size="small"
                        type="dashed"
                        disabled={!generateMatchingDefault()}
                        onClick={() => {
                          const filter = generateMatchingDefault();
                          if (filter) {
                            return add(filter);
                          }
                        }}
                        block={true}
                      >
                        Add Row level restriction
                      </Button>
                    </Form.Item>
                  </Space>
                )}
              </Form.List>
            );
          }}
        </Form.Item>
      </Form>
    );
  };

  return (
    <Modal
      open={visible}
      title={"Row level access"}
      onCancel={onClose}
      maskClosable={false}
      destroyOnClose={true}
      okText={"Save"}
      cancelText={"Cancel"}
      confirmLoading={saving}
      onOk={() => form.submit()}
      width={"50%"}
    >
      {renderInner()}
    </Modal>
  );
}

export default compose<Props, IRowLevelFiltersProps>(WithOrg)(RowLevelFilters);
