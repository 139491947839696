export const ERROR_GQL = `
query getOrgStatus($orgId: ID!) {
  allSources(where: { 
    isDeleted_not: true, 
    org: { id: $orgId },
    hideFromInterface: false
    sourceMeta: {
      executor_not: WAREHOUSE
    }
    lastJobExecution: {
    	status_in: [FAILED, ERROR]
  	} 
  }) {
    id
    lastJobExecution {
      status
    }
  }
  allDestinations(where: { 
    isDeleted_not: true, 
    org: { id: $orgId }, 
    lastJobExecution: {
      status_in: [FAILED, ERROR]
    } 
  }) {
    id
    lastJobExecution {
      status
    }
  }
  allExplorations(where: {
    deleted_not: true,
    org: { id: $orgId },
    extractSchedule: {
      deleted_not: true,
      lastJobExecution: {
        status_in: [FAILED, ERROR]
      }
    }
  }) {
    id
  }
}
`;
