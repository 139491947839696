import type { Filter } from "@cubejs-client/core";
import type { ILagoonQuery } from "../containers/exploration/single/domain";
import type { FilterMapStore } from "../containers/reports/view/domain";
import { convertStringToFilterType } from "../containers/reports/view/domain";
import type { IWlyDatePickerValue } from "../containers/reports/view/filters/date-filter/WlyDatePicker";
import {
  convertStringToWlyDatePickerValue,
  convertWlyDatePickerValueToMoment,
} from "../containers/reports/view/filters/date-filter/WlyDatePicker";
import type { IFilter } from "../interfaces/reports";
import { format } from "../utils/periodUtils";

export const parseTileContent = (value: any): ILagoonQuery => {
  const parsed = JSON.parse(value);

  // We renamed the pivotConfig to pivotDimensions at some point
  // As we still have JSON stored in the backend that is using the pivotConfig name, we copy it into the new field when parsing
  if (parsed.pivotConfig && !parsed.pivotDimensions) {
    parsed.pivotDimensions = parsed.pivotConfig;
  }

  return parsed as ILagoonQuery;
};

export const applyFiltersToLagoonQuery = (
  query: ILagoonQuery,
  reportFilters: IFilter[],
  filterMap: FilterMapStore,
  currentTileId: string
): ILagoonQuery => {
  const newQuery = Object.assign({}, query);
  // Not sure why we have to do this but sometimes the dateRange value is a string here
  // When it should be a JSON object
  newQuery.dateRange = convertStringToWlyDatePickerValue(
    query.dateRange as unknown as string
  );

  let overwriteDefaultTime: undefined | IWlyDatePickerValue = undefined;

  // if a filter applies to the default time on this tile we will apply it
  const defaultTimeFilter = reportFilters.find(
    (f) =>
      f.type === "TIME" &&
      f.tilesToUpdate.find(
        (ttu) =>
          ttu.tile.id === currentTileId &&
          ttu.dimensionToUpdate === "DEFAULT_TIME"
      )
  );

  if (defaultTimeFilter) {
    overwriteDefaultTime = convertStringToWlyDatePickerValue(
      filterMap[defaultTimeFilter.id].value[0]
    );
  }

  // if a time filter applies to the current tile default time field we will apply it
  const otherTimeFilter = reportFilters.find(
    (f) =>
      query.selectedTime &&
      f.type === "TIME" &&
      f.tilesToUpdate.find(
        (ttu) =>
          ttu.tile.id === currentTileId &&
          ttu.dimensionToUpdate === query["selectedTime"]
      )
  );

  if (otherTimeFilter && !defaultTimeFilter) {
    overwriteDefaultTime = convertStringToWlyDatePickerValue(
      filterMap[otherTimeFilter.id].value[0]
    );
  }

  let filters = [
    {
      [newQuery.filterOperator]: newQuery.filters,
    } as any,
  ] as Filter[];

  // we reset the filters if needed
  if (
    filters.length === 1 &&
    (((filters as any)[0].or && (filters as any)[0].or.length === 0) ||
      ((filters as any)[0].and && (filters as any)[0].and.length === 0))
  ) {
    filters = [];
  }

  const reportFiltersToEvaluate = reportFilters
    .flatMap((f) => {
      return f.tilesToUpdate.map((t) => {
        return { filterId: f.id, ...t, filter: f };
      });
    })
    .filter((f) => {
      return f.tile.id === currentTileId;
    });

  const reportFiltersWithAValue = reportFiltersToEvaluate.filter((fta) => {
    if (fta.filter.type === "TIME") {
      // For time dimensions we exclude the following time to updates
      // -> tta that point on the default time
      // -> tta that point on the same dimension as the one used in the chart default time
      // as these are already taken care of in the query default time filter
      if (fta.dimensionToUpdate === "DEFAULT_TIME") {
        return false;
      }
      if (query.selectedTime && fta.dimensionToUpdate === query.selectedTime) {
        return false;
      }
      return true;
    } else {
      return (
        filterMap[fta.filterId].value.length &&
        filterMap[fta.filterId].value[0] !== "WHALY_NO_FILTER" &&
        !!filterMap[fta.filterId].value[0]
      );
    }
  });

  if (reportFiltersWithAValue.length > 0) {
    filters = [
      ...filters,
      {
        and: [
          ...reportFiltersWithAValue
            .map((fta) => {
              if (filterMap[fta.filterId].type === "BOOLEAN") {
                const value = convertStringToFilterType(
                  filterMap[fta.filterId].value,
                  filterMap[fta.filterId].type
                );
                return {
                  member: fta.dimensionToUpdate,
                  operator: "equals",
                  values: value,
                };
              } else if (filterMap[fta.filterId].type === "TIME") {
                const value = convertWlyDatePickerValueToMoment(
                  convertStringToFilterType(
                    filterMap[fta.filterId].value[0],
                    filterMap[fta.filterId].type
                  )
                );
                if (!value) {
                  return null;
                }
                return {
                  member: fta.dimensionToUpdate,
                  operator: "inDateRange",
                  values: [value[0].format(format), value[1].format(format)],
                };
              } else {
                return {
                  member: fta.dimensionToUpdate,
                  operator: "equals",
                  values: filterMap[fta.filterId].value.map((v) =>
                    typeof v === "number" ? (v as number).toString() : v
                  ),
                };
              }
            })
            .filter((v) => !!v), // we keep only values that are non nulls,
        ],
      } as any,
    ];
  }

  if (overwriteDefaultTime) {
    newQuery.dateRange = overwriteDefaultTime;
  }
  newQuery.filters = filters as any;
  return newQuery;
};
