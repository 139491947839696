import { Button } from "antd";
import * as React from "react";

import { PlusOutlined } from "@ant-design/icons";
import type { IReportFolder } from "../../../interfaces/folder";
import "./ReportCreation.scss";
import ReportCreationModal from "./ReportCreationModal";

interface IReportCreationProps {
  folder: IReportFolder;
}

type Props = IReportCreationProps;

class ReportCreation extends React.Component<Props> {
  public render() {
    const { folder } = this.props;

    return (
      <ReportCreationModal folder={folder}>
        {(onClick) => {
          return (
            <Button onClick={onClick} icon={<PlusOutlined />}>
              Report
            </Button>
          );
        }}
      </ReportCreationModal>
    );
  }
}

export default ReportCreation;
