import { Button, Col, Row } from "antd";
import { Typography } from "antd";
import * as React from "react";
import Error from "../../../components/error/Error";
import Feednack from "../../../components/layout/feedback/feedback";
import { Emoji } from "../../../components/form/emoji-picker/Emoji";

const { Title } = Typography;

interface IDefaultPaymentProps {}

export function DefaultPayment(props: IDefaultPaymentProps) {
  return (
    <Feednack>
      <Error>
        <Row>
          <Col span={12} offset={6}>
            <Title>
              Oh oh <Emoji size={32} emoji={":whale:"} />
              ...
            </Title>
            <div>
              Our records indicates that you haven't setup your payment means{" "}
              <Emoji size={14} emoji={":sweat_smile:"} />. In order to continue
              using our service, please setup a mean of payment.
            </div>
            <br />
            <div>
              Don't worry, we haven't deleted any of your data, so as soon as we
              receive your mean of payment you will get access to your Whaly
              environment as you left it <Emoji size={14} emoji={":fire:"} />{" "}
              with up to date data.
            </div>
            <br />
            <div></div>
            <br />
            <Button
              type="primary"
              href="https://pay.gocardless.com/AL0004CV8KAMVY"
            >
              Setup my billing information 
              <Emoji size={14} emoji={":rocket:"} />
            </Button>
          </Col>
        </Row>
      </Error>
    </Feednack>
  );
}
