import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { useRef } from "react";
import type { ChartOption } from "../../../containers/chart-options/ChartOptions";
import type { UserLocale } from "../../../interfaces/user";
import "../../ag-grid/theme/ag-theme-whaly.scss";
import type PaletteGenerator from "../../palette/utils/PaletteGenerator";
import type { Formatter } from "../domain";
import {
  customFormatter,
  optionsHelper,
  renderSerieTooltip,
} from "../utils/optionsHelper";
import "./WaterfallTimeserie.scss";

interface IWaterfallChartTimeSerieProps {
  height: number;
  width?: number;
  locale: UserLocale;
  data: Array<{
    name: string;
    y: number;
    isSum?: boolean;
    color?: string;
    onClick?: () => void;
  }>;
  config: WaterfallChartTimeSerieConfig;
}

interface WaterfallChartTimeSerieConfig {
  x: {
    label?: string;
  };
  y: {
    label?: string;
    formatter?: Formatter;
  };
  serieLabel?: string;
  showLabels?: boolean;
  palette?: PaletteGenerator;
  chartOptions?: ChartOption;
}

export function WaterfallChartTimeSerie(props: IWaterfallChartTimeSerieProps) {
  const { width, height, locale, config, data } = props;
  const ref = useRef<HighchartsReact.RefObject>(null);

  const optionHelpers = optionsHelper(
    height,
    width,
    locale,
    "ordinal",
    config.y.formatter,
    undefined,
    undefined,
    config.chartOptions
  );

  const options: Highcharts.Options = {
    ...optionHelpers,
    chart: {
      ...optionHelpers.chart,
      reflow: false,
      height: height,
      spacing: [20, 0, 10, 0],
      borderWidth: 0,
      backgroundColor: "transparent",
    },
    legend: {
      enabled: false,
    },
    plotOptions: {
      ...optionHelpers.plotOptions,
    },
    xAxis: {
      ...optionHelpers.xAxis,
      type: "category",
      title: {
        text: config.x.label,
      },
    },
    yAxis: {
      ...optionHelpers.yAxis,
      title: {
        text: config.y.label,
      },
    },
    series: [
      {
        type: "waterfall",
        name: config.serieLabel,
        data: data.map((d) => ({
          ...d,
          className: typeof d.onClick === "function" ? "pointer" : "auto",
          events: {
            click: function () {
              d.onClick?.();
            },
          },
        })),
        borderColor: "transparent",
        tooltip: renderSerieTooltip({
          data: [],
          locale: locale,
          seriesLength: 1,
          formatter: config.y.formatter,
          hideSerieColor: true,
        }),
        minPointLength: 1,
        dataLabels: [
          {
            enabled: config.showLabels,
            formatter: function () {
              return customFormatter(this.y, locale, config.y.formatter);
            },
            crop: false,
            inside: false,
            style: {
              fontSize: "0.8em",
            },
          },
        ],
      },
    ],
  };

  return (
    <div
      style={{ height, width: "100%" }}
      className="waterfall-timeserie-chart"
    >
      <HighchartsReact ref={ref} highcharts={Highcharts} options={options} />
    </div>
  );
}
