import { ConfigProvider, Form, theme } from "antd";
import * as React from "react";
import type { ChartType } from "../../../../../components/chart/domain";
import type {
  AvailableDimension,
  AvailableMetric,
} from "../../../../../components/measures/filter-item/FilterItem";
import type { ITile } from "../../../../../interfaces/reports";
import type {
  ChartOption,
  ISummaryQueryOptions,
} from "../../../../chart-options/ChartOptions";
import { ChartOptionsWrapper } from "../../../../chart-options/ChartOptionsWrapper";
import { ChartOptionCollapse } from "../../../../chart-options/components/ChartOptionCollapse";
import type { ILagoonQuery } from "../../../../exploration/single/domain";
import type { IAsyncMeta } from "../../../../exploration/single/visualization/chart/Chart";
import type { ITileOptions } from "../panel/EditTilePanel";
import { EditTilePanel } from "../panel/EditTilePanel";
import "./ChartOptionPanel.scss";

interface IChartOptionPanelProps {
  currentChartType: ChartType;
  lagoonQuery: ILagoonQuery;
  meta?: IAsyncMeta;
  onOptionsChange: (v: ChartOption) => void;
  onTileInfoChange: (v: ITileOptions) => void;
  tile: ITile;
  availableDimensions: AvailableDimension[];
  availableMetrics: AvailableMetric[];
}

export function ChartOptionPanel(props: IChartOptionPanelProps) {
  const {
    meta,
    lagoonQuery,
    currentChartType,
    onOptionsChange: onChange,
    tile,
    onTileInfoChange,
    availableDimensions,
    availableMetrics,
  } = props;
  const [form] = Form.useForm();

  React.useEffect(() => {
    form.resetFields();
  }, [tile.id]);

  const onFormChange = (summary?: ISummaryQueryOptions) => {
    const values = form.getFieldsValue();
    const combinedValues = {
      ...values,
      summary: summary ? summary : values.summary,
    };
    onChange(combinedValues);
  };

  return (
    <div className="chart-option-panel">
      <ConfigProvider
        theme={{
          algorithm: theme.compactAlgorithm,
        }}
      >
        <ChartOptionCollapse title="Card options">
          <EditTilePanel
            key={tile.id}
            tileOptions={{
              name: tile.name,
              description: tile.description,
              hide_card: tile.hide_card,
              hide_name: tile.hide_name,
            }}
            onChange={onTileInfoChange}
            tile={tile}
          />
        </ChartOptionCollapse>
        <ChartOptionsWrapper
          key={tile.id}
          currentChartType={currentChartType}
          lagoonQuery={lagoonQuery}
          availableDimensions={availableDimensions}
          availableMetrics={availableMetrics}
          meta={meta ? meta : ({ status: "initial" } as IAsyncMeta)}
          form={form}
          onChange={onFormChange}
          showSummary={true}
        />
      </ConfigProvider>
    </div>
  );
}
