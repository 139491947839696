import * as colorutil from "color-util";
const convertAnyColorToRGB = (color: string) => {
  const rgba = colorutil.color(color).rgb;
  return rgba;
};

// http://www.w3.org/TR/AERT#color-contrast
export const getFontColorFromBackground = (color: string) => {
  const rgb = convertAnyColorToRGB(color);
  const brightness = Math.round(
    (parseInt(rgb.r) * 299 + parseInt(rgb.g) * 587 + parseInt(rgb.b) * 114) /
      1000
  );
  const textColor = brightness > 125 ? "#000000" : "#FFFFFF";
  return textColor;
};
