import { Table } from "antd";
import type { TableProps } from "antd/lib/table";
import * as React from "react";
import { WlyCard } from "../cards/WlyCard";
import "./CardTable.scss";

interface ICardTableProps<T> extends TableProps<T> {
  className?: string;
  cardTitle: React.ReactNode;
  actionButtons: React.ReactNode;
}

export default class CardTable<T> extends React.Component<ICardTableProps<T>> {
  public render() {
    const { className, cardTitle, actionButtons, ...rest } = this.props;
    return (
      <WlyCard
        title={cardTitle}
        extra={actionButtons}
        bodyStyle={{
          padding: 0,
        }}
        headStyle={{
          borderBottom: "1px solid transparent",
        }}
        style={{
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
          borderBottom: "none",
        }}
        size={this.props.size === "small" ? "small" : "default"}
      >
        <Table className="card-table" {...(rest as any)} />
      </WlyCard>
    );
  }
}
