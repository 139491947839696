import { Alert, Col, Row } from "antd";
import type { Moment } from "moment";
import moment from "moment";
import { useEffect, useState } from "react";
import "./AdvancedDateRangePicker.scss";
import type { AdvancedDatePickerPeriod } from "./AdvancedDateRangePickerPanel";
import AdvancedDatePickerPanel from "./AdvancedDateRangePickerPanel";

interface IAdvancedDatePickerProps {
  value?: AdvancedDatePickerPeriods;
  onChange?: (periods: AdvancedDatePickerPeriods) => void;
}

type AdvancedDatePickerPeriods = {
  startPeriod: AdvancedDatePickerPeriod;
  endPeriod: AdvancedDatePickerPeriod;
};

export const ComputeAdvancedDatePickerPeriod = (
  period: AdvancedDatePickerPeriod
): Moment => {
  const getInitialDate = () => {
    switch (period.startOn) {
      case "fixed":
        return moment(period.fixedDate);
      case "today":
        return moment().startOf("minute");
      case "start_day":
        return moment().startOf("day");
      case "start_week":
        return moment().startOf("isoWeek");
      case "start_month":
        return moment().startOf("month");
      case "start_quarter":
        return moment().startOf("quarter");
      case "start_year":
        return moment().startOf("year");
      case "end_day":
        return moment().endOf("day");
      case "end_week":
        return moment().endOf("isoWeek");
      case "end_month":
        return moment().endOf("month");
      case "end_quarter":
        return moment().endOf("quarter");
      case "end_year":
        return moment().endOf("year");
    }
  };
  const addDiff = (startDate: Moment): Moment => {
    if (period.startOn === "fixed") {
      return startDate;
    } else {
      if (period.operator === "plus") {
        return startDate.add(period.difference, period.period);
      } else {
        return startDate.subtract(period.difference, period.period);
      }
    }
  };
  const round = (startDate: Moment): Moment => {
    if (period.startOn === "fixed") return startDate;
    switch (period.roundTo) {
      case "nothing":
        return startDate;
      case "start_hour":
        return startDate.startOf("hour");
      case "end_hour":
        return startDate.endOf("hour");
      case "start_day":
        return startDate.startOf("day");
      case "end_day":
        return startDate.endOf("day");
      case "start_week":
        return startDate.startOf("isoWeek");
      case "start_month":
        return startDate.startOf("month");
      case "start_quarter":
        return startDate.startOf("quarter");
      case "start_year":
        return startDate.startOf("year");
      case "end_week":
        return startDate.endOf("isoWeek");
      case "end_month":
        return startDate.endOf("month");
      case "end_quarter":
        return startDate.endOf("quarter");
      case "end_year":
        return startDate.endOf("year");
    }
  };
  const result = round(addDiff(getInitialDate()));
  return result;
};

export const isPeriodRangeValid = (
  startPeriod: AdvancedDatePickerPeriod,
  endPeriod: AdvancedDatePickerPeriod
): boolean => {
  if (!startPeriod || !endPeriod) return false;
  if (
    moment(ComputeAdvancedDatePickerPeriod(startPeriod)).diff(
      ComputeAdvancedDatePickerPeriod(endPeriod),
      "millisecond"
    ) < 0
  )
    return true;
  return false;
};

const renderPeriods = (
  startPeriod: AdvancedDatePickerPeriod,
  endPeriod: AdvancedDatePickerPeriod
): JSX.Element => {
  if (!startPeriod || !endPeriod) return undefined;
  if (!isPeriodRangeValid(startPeriod, endPeriod))
    return (
      <Alert
        showIcon
        type="warning"
        message="Start date should be after end date"
      />
    );
  const startDate = ComputeAdvancedDatePickerPeriod(startPeriod);
  const endDate = ComputeAdvancedDatePickerPeriod(endPeriod);

  return (
    <div className="content">
      <p>📆 Selected dates are:</p>
      <div style={{ display: "flex" }}>
        <div style={{ width: 50, fontWeight: "bold" }}>From:</div>
        <div>{startDate && startDate.format(advancedFormat)}</div>
        <br />
      </div>
      <div style={{ display: "flex" }}>
        <div style={{ width: 50, fontWeight: "bold" }}>To:</div>
        <div>{endDate && endDate.format(advancedFormat)}</div>
      </div>
    </div>
  );
};

const advancedFormat = "dddd, MMMM Do YYYY HH:mm:ss";

export function AdvancedDatePicker(props: IAdvancedDatePickerProps) {
  const { value, onChange } = props;

  const [startPeriod, setStartPeriod] = useState<AdvancedDatePickerPeriod>(
    value ? value.startPeriod : null
  );
  const [endPeriod, setEndPeriod] = useState<AdvancedDatePickerPeriod>(
    value ? value.endPeriod : null
  );

  useEffect(() => {
    if (onChange) {
      onChange({ startPeriod: startPeriod, endPeriod: endPeriod });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startPeriod, endPeriod]);

  useEffect(() => {
    if (value?.startPeriod) {
      setStartPeriod(value.startPeriod);
    }
    if (value.endPeriod) {
      setEndPeriod(value.endPeriod);
    }
  }, [value]);

  return (
    <>
      <Row gutter={30}>
        <Col span={12}>
          <AdvancedDatePickerPanel
            title="Start"
            value={startPeriod}
            onChange={(value) => setStartPeriod(value)}
          />
        </Col>
        <Col span={12}>
          <AdvancedDatePickerPanel
            title="End"
            value={endPeriod}
            onChange={(value) => setEndPeriod(value)}
          />
        </Col>
      </Row>
      <br />
      {renderPeriods(startPeriod, endPeriod)}
    </>
  );
}
