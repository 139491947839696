import type { IWidgetEmailRendererProps } from "../../domain";
import type { IEmailWidgetSpacerConfig } from "../domain";
import { getDefaultConfig } from "../domain";

interface IEmailWidgetSpacerRendererProps
  extends IWidgetEmailRendererProps<IEmailWidgetSpacerConfig> {}

export function EmailWidgetSpacerRenderer(
  props: IEmailWidgetSpacerRendererProps
) {
  const { conf, record } = props;

  const defaults = getDefaultConfig(conf, record);

  return (
    <div
      style={{
        height: defaults.height,
      }}
    />
  );
}
