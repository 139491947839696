import * as React from "react";
import { compose } from "../../../components/compose/WlyCompose";
import FormActions from "../../../components/form/actions/FormActions";
import type { InjectedOrgProps } from "../../orgs/WithOrg";
import WithOrg from "../../orgs/WithOrg";

import "./AddColumnMeta.scss";

type fieldType = "FORMULA" | "ROLLUP" | "LOOKUP";

type CreateEditProps = {
  children: (type: fieldType) => React.ReactElement;
  onCancel: () => void;
  isStale: (v: boolean) => void;
};

type Props = CreateEditProps & InjectedOrgProps;

const CreateEdit: React.FunctionComponent<Props> = (props) => {
  const { onCancel, children } = props;

  const [type, setType] = React.useState<fieldType>();

  return !type ? (
    <div className="form-dropdown-form add-column-meta">
      <div className="form-dropdown-form-header add-column-meta-title">
        Select a column type
      </div>

      <div className="form-dropdown-form-content">
        <div
          className="add-column-meta-item"
          onClick={() => setType("FORMULA")}
        >
          Formula
        </div>
        <div className="add-column-meta-item" onClick={() => setType("LOOKUP")}>
          Lookup
        </div>
        <div className="add-column-meta-item" onClick={() => setType("ROLLUP")}>
          Rollup
        </div>
      </div>
      <div className="form-dropdown-form-buttons">
        <FormActions
          hideSubmit={true}
          size="small"
          flex={true}
          onCancel={onCancel}
          isSubmitting={false}
        />
      </div>
    </div>
  ) : (
    children(type)
  );
};

export default compose<Props, CreateEditProps>(WithOrg)(CreateEdit);
