import { MoreOutlined } from "@ant-design/icons";
import type { MenuProps } from "antd";
import { Button, Col, Dropdown, Row, Space, Tag, Typography } from "antd";
import { inject, observer } from "mobx-react";
import React from "react";
import type { InjectedAntUtilsProps } from "../../../components/ant-utils/withAntUtils";
import { withAntUtils } from "../../../components/ant-utils/withAntUtils";
import { compose } from "../../../components/compose/WlyCompose";
import Error from "../../../components/error/Error";
import Aligner from "../../../components/layout/aligner/Aligner";
import Loading from "../../../components/layout/feedback/loading";
import PaletteRenderer from "../../../components/palette/PaletteRenderer";
import type { IPalette } from "../../../components/palette/utils/paletteData";
import {
  getAvailableCollections,
  getDefaultCollection,
} from "../../../components/palette/utils/paletteData";
import CardTable from "../../../components/table/CardTable";
import type { AsyncData } from "../../../helpers/typescriptHelpers";
import type { IPaletteCollection } from "../../../interfaces/org";
import { IUserRoleType } from "../../../interfaces/user";
import GraphQLService from "../../../services/graphql/GraphQLService";
import type { UserStoreProps } from "../../../store/userStore";
import type { InjectedOrgProps } from "../../orgs/WithOrg";
import WithOrg from "../../orgs/WithOrg";
import { hasRoleAccessBoolean } from "../../user-settings/HasRoleAccess";
import SettingsWrapper from "../SettingsWrapper";
import type { ICollectionInitialData } from "./PaletteCollectionEdit";
import PaletteCollectionEdit from "./PaletteCollectionEdit";

interface IThemeSettingListProps {}

type Props = IThemeSettingListProps &
  InjectedOrgProps &
  InjectedAntUtilsProps &
  UserStoreProps;

function ThemeSettingPage(props: Props) {
  const { user, org, antUtils, userStore } = props;

  const [visible, setVisible] = React.useState<
    boolean | ICollectionInitialData
  >(false);
  const [data, setData] = React.useState<AsyncData<null>>({
    status: "initial",
  });

  const loadData = async (initial?: boolean) => {
    try {
      setData({ status: "loading" });
      if (!initial) {
        await userStore.getUser();
      }
      setData({ status: "success", data: null });
    } catch (err) {
      console.error(err);
      setData({ status: "error", error: err });
    }
  };

  React.useEffect(() => {
    loadData(true);
  }, []);

  const renderInner = () => {
    if (data.status === "loading" || data.status === "initial") {
      return <Loading />;
    }
    if (data.status === "error") {
      return <Error />;
    }

    const wlyPalettes: IPaletteCollection[] = getAvailableCollections(org);

    const defaultCollection = getDefaultCollection(org);

    return (
      <Space size={16} direction="vertical" style={{ width: "100%" }}>
        {wlyPalettes.map((p) => {
          const categorical: IPalette[] = p.categorical;
          const diverging: IPalette[] = p.diverging;
          const sequential: IPalette[] = p.sequential;
          const collection = {
            id: p.id,
            name: p.name,
            categorical: categorical,
            diverging: diverging,
            sequential: sequential,
          };

          const items: MenuProps["items"] = [
            {
              key: "0",
              label: "Make default",
              disabled: defaultCollection.slug === p.slug,
              onClick: () => {
                antUtils.modal.confirm({
                  title: "Make default palette",
                  content:
                    "You are about to make this palette collection default. This will change all charts using the default collection use this one now. Do you want to continue ?",
                  onCancel: () => false,
                  onOk: async () => {
                    try {
                      await GraphQLService(
                        `
                        mutation updateOrg($id: ID!, $collectionSlug: String!) {
                          updateOrg(id: $id, data: { defaultPaletteCollection: $collectionSlug }) {
                            id
                          }
                        }
                      `,
                        {
                          id: org.id,
                          collectionSlug: p.slug,
                        }
                      );
                      loadData();
                    } catch (err) {}
                  },
                });
              },
            },
            ...(!p.system
              ? [
                  {
                    key: "1",
                    label: "Edit",
                    disabled: p.system,
                    onClick: () => setVisible(collection),
                  },
                ]
              : []),
            ...(!p.system
              ? [
                  {
                    key: "2",
                    label: "Delete",
                    danger: true,
                    onClick: () =>
                      antUtils.modal.confirm({
                        title: "Warning",
                        content:
                          "You are about to delete this collection, this can't be undone. Do you want to proceed?",
                        onCancel: () => false,
                        onOk: async () => {
                          await GraphQLService(
                            `
                            mutation deletePaletteCollection($id: ID!) {
                              updatePaletteCollection(id: $id, data: { deleted: true }) {
                                id
                              }
                            }
                            `,
                            {
                              id: p.id,
                            }
                          );
                          loadData();
                        },
                      }),
                  },
                ]
              : []),
          ];

          return (
            <CardTable<ICollectionInitialData>
              key={p.id}
              cardTitle={<span>{p.name}</span>}
              actionButtons={
                <Space size={12}>
                  {p.slug === defaultCollection.slug ? (
                    <Tag color="success">Default collection</Tag>
                  ) : null}
                  {hasRoleAccessBoolean(
                    IUserRoleType.ADMIN_BUILDER,
                    user,
                    org.id
                  ) ? (
                    <Dropdown
                      menu={{ items }}
                      placement="bottomRight"
                      trigger={["click"]}
                    >
                      <Button
                        type="text"
                        shape="circle"
                        icon={<MoreOutlined />}
                      />
                    </Dropdown>
                  ) : undefined}
                </Space>
              }
              rowKey="id"
              dataSource={[collection]}
              columns={[
                {
                  title: "Categorical",
                  dataIndex: "categorical",
                  key: "categorical",
                  width: "33%",
                  render: (v, r) => {
                    return (
                      <Space>
                        {(r.categorical || []).map((c, i) => {
                          return (
                            <PaletteRenderer
                              type={c.type}
                              colors={c.colors}
                              key={i}
                            />
                          );
                        })}
                      </Space>
                    );
                  },
                },
                {
                  title: "Sequential",
                  dataIndex: "sequential",
                  key: "sequential",
                  width: "33%",
                  render: (v, r) => {
                    return (
                      <Space>
                        {(r.sequential || []).map((c, i) => {
                          return (
                            <PaletteRenderer
                              type={c.type}
                              colors={c.colors}
                              key={i}
                            />
                          );
                        })}
                      </Space>
                    );
                  },
                },
                {
                  title: "Diverging",
                  dataIndex: "diverging",
                  key: "diverging",
                  width: "33%",
                  render: (v, r) => {
                    return (
                      <Space>
                        {(r.diverging || []).map((c, i) => {
                          return (
                            <PaletteRenderer
                              type={c.type}
                              colors={c.colors}
                              key={i}
                            />
                          );
                        })}
                      </Space>
                    );
                  },
                },
              ]}
              pagination={{
                size: "small",
                style: { display: "none" },
                defaultPageSize: 1000,
              }}
            />
          );
        })}
      </Space>
    );
  };

  return (
    <SettingsWrapper>
      <Aligner>
        <Row justify="center" gutter={[24, 24]}>
          <Col xs={24} sm={24} md={20} lg={20} xl={20}>
            <div style={{ display: "flex", paddingBottom: 24 }}>
              <div style={{ flex: 1 }}>
                <Typography.Title level={4}>Theme</Typography.Title>
              </div>
              <Button type="primary" onClick={() => setVisible(true)}>
                New
              </Button>
            </div>
          </Col>
        </Row>
        <Row justify="center">
          <Col xs={24} sm={24} md={20} lg={20} xl={20}>
            {renderInner()}
          </Col>
        </Row>
        <PaletteCollectionEdit
          visible={!!visible}
          onClose={(reload) => {
            setVisible(false);
            if (reload) {
              loadData();
            }
          }}
          initialData={typeof visible === "boolean" ? undefined : visible}
        />
      </Aligner>
    </SettingsWrapper>
  );
}

export default compose<Props, IThemeSettingListProps>(
  WithOrg,
  withAntUtils,
  inject("userStore"),
  observer
)(ThemeSettingPage);
