import { MoreOutlined } from "@ant-design/icons";
import { Button, Dropdown, Modal, Space } from "antd";
import React, { useState } from "react";
import type { RouteComponentProps } from "react-router";
import { withRouter } from "react-router";
import type { ChartType } from "../../../components/chart/domain";
import { compose } from "../../../components/compose/WlyCompose";
import type { IExploration } from "../../../interfaces/explorations";
import { IUserRoleType } from "../../../interfaces/user";
import { routeDescriptor } from "../../../routes/routes";
import { encodeUrlParam } from "../../../store/workbenchUIStore";
import type { InjectedOrgProps } from "../../orgs/WithOrg";
import WithOrg from "../../orgs/WithOrg";
import HasRoleAccess, {
  hasRoleAccessBoolean,
} from "../../user-settings/HasRoleAccess";
import ExplorationModalActionButton from "../../v2-demo/container/eploration/ExplorationModalActionButton";
import type { IAnalysisType, ILagoonQuery } from "./domain";
import ChartLib from "./exploration-usage/ExplorationUsage";
import type { ITraceModalProps } from "./performance/TraceDrawer";
import { TraceDrawer } from "./performance/TraceDrawer";
import AddTileToQuestion from "./visualization/actions/AddTileToQuestion";
import AddTileToReport from "./visualization/actions/AddTileToReport";

interface IExplorationHeaderProps {
  canSaveChart: boolean;
  chartType: ChartType;
  exploration: IExploration;
  selectedAnalysis: IAnalysisType;
  getQuery: () => ILagoonQuery;
  traceModal: Omit<ITraceModalProps, "visible" | "onClose">;
}

type Props = IExplorationHeaderProps &
  InjectedOrgProps &
  RouteComponentProps<{}>;

function ExplorationHeader(props: Props) {
  const {
    canSaveChart,
    chartType,
    exploration,
    selectedAnalysis,
    traceModal,
    getQuery,
    match: { params },
    org,
    user,
  } = props;
  const [addingToReportVisible, setAddingToReportVisible] =
    useState<boolean>(false);
  const [addingToQuestionVisible, setAddingToQuestionVisible] =
    useState<boolean>(false);
  const [explorationUsageVisible, setExplorationUsageVisible] =
    useState<boolean>(false);
  const [consoleVisible, setConsoleVisible] = useState<boolean>(false);

  const wrap = (node: React.ReactNode) => {
    return <ExplorationModalActionButton>{node}</ExplorationModalActionButton>;
  };

  return (
    <>
      <AddTileToReport
        chartType={chartType}
        analysisType={selectedAnalysis}
        explorationId={exploration.id}
        visible={addingToReportVisible}
        query={getQuery()}
        onCancel={() => setAddingToReportVisible(false)}
      />
      <AddTileToQuestion
        chartType={chartType}
        analysisType={selectedAnalysis}
        explorationId={exploration.id}
        visible={addingToQuestionVisible}
        query={getQuery()}
        onCancel={() => setAddingToQuestionVisible(false)}
      />
      {wrap(
        <Space size={12}>
          <HasRoleAccess accessLevel={IUserRoleType.EDITOR}>
            <Dropdown
              trigger={["click"]}
              disabled={!canSaveChart}
              placement="bottomRight"
              menu={{
                items: [
                  {
                    key: "report",
                    label: "Add to report",
                    onClick: () => setAddingToReportVisible(true),
                  },
                  {
                    key: "question",
                    label: "Save as question",
                    onClick: () => setAddingToQuestionVisible(true),
                  },
                ],
              }}
            >
              <Button type="primary">Save as</Button>
            </Dropdown>
          </HasRoleAccess>
          <Dropdown
            trigger={["click"]}
            placement="bottomRight"
            menu={{
              items: [
                {
                  key: "usage",
                  label: "Usage",
                  onClick: () => setExplorationUsageVisible(true),
                },
                {
                  key: "edit",
                  onClick: () => {
                    const computedUrlState = encodeUrlParam({
                      type: "exploration",
                      value: exploration.id,
                    });
                    window
                      .open(
                        routeDescriptor.workbench.renderRoute({
                          ...params,
                          tableSlug: computedUrlState,
                          warehouseSlug: exploration.warehouse?.slug,
                        }),
                        "_blank"
                      )
                      .focus();
                  },
                  disabled: !hasRoleAccessBoolean(
                    IUserRoleType.BUILDER,
                    user,
                    org.id
                  ),
                  label: "Edit exploration",
                },
                {
                  type: "divider",
                },
                {
                  key: "console",
                  label: "Open console",
                  disabled: traceModal.data.status !== "success",
                  onClick: () => setConsoleVisible(true),
                },
              ],
            }}
          >
            <Button shape="circle" icon={<MoreOutlined />} />
          </Dropdown>
        </Space>
      )}

      <Modal
        width={1200}
        open={explorationUsageVisible}
        closable={true}
        onCancel={() => setExplorationUsageVisible(false)}
        title={"Exploration usage"}
      >
        <ChartLib explorationId={exploration.id} />
      </Modal>
      <TraceDrawer
        visible={consoleVisible}
        onClose={() => setConsoleVisible(false)}
        data={traceModal.data}
        trace={traceModal.trace}
        sql={traceModal.sql}
      />
    </>
  );
}

export default compose<Props, IExplorationHeaderProps>(
  WithOrg,
  withRouter
)(ExplorationHeader);
