import {
  ArrowLeftOutlined,
  ExclamationCircleOutlined,
  LockFilled,
} from "@ant-design/icons";
import { Button, Col, Layout, Row, Select, Tooltip } from "antd";
import * as React from "react";
import ReactMarkdown from "react-markdown";
import ReactVisibilitySensor from "react-visibility-sensor";
import type { InjectedAntUtilsProps } from "../../../components/ant-utils/withAntUtils";
import { withAntUtils } from "../../../components/ant-utils/withAntUtils";
import Card from "../../../components/cards/Card";
import { compose } from "../../../components/compose/WlyCompose";
import Feednack from "../../../components/layout/feedback/feedback";
import Loading from "../../../components/layout/feedback/loading";
import type { AsyncData } from "../../../helpers/typescriptHelpers";
import type { IExploration } from "../../../interfaces/explorations";
import type { ISourceMeta } from "../../../interfaces/sources";
import type { ITemplate } from "../../../interfaces/template";
import GraphQl from "../../graphql/graphql";
import type { InjectedOrgProps } from "../../orgs/WithOrg";
import WithOrg, { getCurrentWarehouse } from "../../orgs/WithOrg";
import { createExploration, getNumberOfExplorations } from "../executor/domain";

import type { RouteComponentProps } from "react-router";
import { withRouter } from "react-router";
import "./TemplateView.scss";

interface ITemplateViewProps {
  tslug: string;
  onBack: (category?: string) => void;
  onSuccess: (
    explorations: Array<Pick<IExploration, "id" | "slug">>
  ) => Promise<any>;
}

const GQL = `
query getCurrentTemplate($templateSlug: String!, $orgId: ID!) {
  allTemplates(where: {slug: $templateSlug}) {
    id
    name
    description
    googleDataStudioTemplateId
    featuredImage
    executor
    categories {
      id
      slug
      name
    }
    sourceMeta {
      id
      shoreSourceName
      publicInfo {
        name
        logo
      }
    }
  }
  allConnectedSourceMeta: allSourceMetas(where: { sources_some: {org: {id: $orgId }, isDeleted_not: true}, deleted_not: true }) {
    id
    publicInfo {
      name
    }
    sources(where: {isDeleted_not: true, org: {id: $orgId } }) {
      id
      name
    }
  }
}
`;

type Props = ITemplateViewProps &
  InjectedOrgProps &
  InjectedAntUtilsProps &
  RouteComponentProps<{ warehouseSlug?: string }>;

interface IState {
  buttonVisible: boolean;
  creatingExploration: AsyncData<{}>;
}

class TemplateView extends React.Component<Props, IState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      buttonVisible: true,
      creatingExploration: { status: "initial" },
    };
  }

  public onTemplateCreation = (
    sourceMeta: ISourceMeta[],
    executor: string,
    template: ITemplate
  ) => {
    const { org, onSuccess, antUtils, match } = this.props;
    const currentWarehouse = getCurrentWarehouse(
      org,
      match.params.warehouseSlug
    );
    this.setState({ creatingExploration: { status: "loading" } });
    const sourceMetaIdToSourceId: string[] = [];
    return new Promise((resolve, reject) => {
      const sources = sourceMeta.filter((sm) =>
        template.sourceMeta.map((s) => s.id).includes(sm.id)
      );
      if (sources.filter((sm) => sm.sources.length > 1).length > 0) {
        return antUtils.modal.confirm({
          title: "Select your source",
          icon: <ExclamationCircleOutlined />,
          content: (
            <div>
              {sources.map((sm) => {
                return (
                  <div key={sm.id}>
                    Which {sm.publicInfo.name} do you want to use:
                    <Select
                      onChange={(e) =>
                        e
                          ? sourceMetaIdToSourceId.push(e.toString())
                          : undefined
                      }
                      style={{ width: "100%" }}
                    >
                      {sm.sources.map((s) => {
                        return (
                          <Select.Option key={s.id} value={s.id}>
                            {s.name}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </div>
                );
              })}
            </div>
          ),
          onOk: () => {
            resolve({});
          },
          onCancel: () => {
            reject();
          },
        });
      } else {
        sourceMetaIdToSourceId.push(sources[0].sources[0].id);
        return resolve({});
      }
    }).then(() => {
      const loadingMessage = antUtils.message.loading(
        "Setting up template...",
        0
      );
      return createExploration(
        org.id,
        currentWarehouse.id,
        sourceMetaIdToSourceId,
        executor
      )
        .then((s) => {
          this.setState({
            creatingExploration: { status: "success", data: {} },
          });
          return onSuccess(s).then(() => {
            loadingMessage();
            antUtils.message.success(
              "Your template has successfully been set up.",
              2
            );
          });
        })
        .catch((err) => {
          console.error(err);
          loadingMessage();
          antUtils.message.error(
            "There was an error setting up your template.",
            2
          );
          this.setState({
            creatingExploration: { status: "error", error: err },
          });
        });
    });
  };

  public render() {
    const { tslug, org, onBack } = this.props;
    const { buttonVisible, creatingExploration } = this.state;

    return (
      <GraphQl<{
        allTemplates: ITemplate[];
        allConnectedSourceMeta: ISourceMeta[];
      }>
        query={GQL}
        variables={{ templateSlug: tslug, orgId: org.id }}
      >
        {(r) => {
          if (r.status === "loading" || r.status === "initial") {
            return (
              <Feednack>
                <Loading />
              </Feednack>
            );
          }
          if (r.status === "error") {
            return <Feednack>{JSON.stringify(r.error)}</Feednack>;
          }

          if (r.data.allTemplates.length === 0) {
            return (
              <Feednack>
                The template you are looking for seems to not exists anymore...
              </Feednack>
            );
          }

          const template = r.data.allTemplates[0];
          const sourceMetas = r.data.allConnectedSourceMeta;

          const missingSources = template.sourceMeta.filter((v) => {
            return !sourceMetas.map((sm) => sm.id).includes(v.id);
          });

          const hasSeverralSource = sourceMetas.filter(
            (sm) => sm.sources.length > 1
          );

          const message = missingSources.length ? (
            <span>
              In order to use this template, you need to connect the following
              sources:{" "}
              {missingSources.map((ms) => ms.publicInfo.name).join(", ")}
            </span>
          ) : undefined;

          return (
            <Layout style={{ padding: 16 }} className="template-view">
              {!buttonVisible && (
                <div className="scrolled-header">
                  <div className="header-title">
                    <Button
                      className="button"
                      type="text"
                      shape="circle"
                      icon={<ArrowLeftOutlined />}
                      onClick={() => onBack()}
                    />{" "}
                    {template.name}
                  </div>
                  <div className="header-button">
                    <Tooltip title={message}>
                      <Button
                        loading={creatingExploration.status === "loading"}
                        onClick={
                          missingSources.length
                            ? undefined
                            : () =>
                                this.onTemplateCreation(
                                  sourceMetas,
                                  template.executor,
                                  template
                                )
                        }
                        type="primary"
                        size={"middle"}
                      >
                        {missingSources.length ? (
                          <span>
                            <LockFilled /> Use Template
                          </span>
                        ) : (
                          <span>Use Template</span>
                        )}
                      </Button>
                    </Tooltip>
                  </div>
                </div>
              )}
              <div className="title">
                <Button
                  className="button"
                  type="text"
                  shape="circle"
                  icon={<ArrowLeftOutlined />}
                  onClick={() => onBack()}
                />{" "}
                {template.name}
              </div>
              <div className="categories">
                {template.categories.map((c) => {
                  return (
                    <a
                      key={c.slug}
                      onClick={() => onBack(c.slug)}
                      className="category"
                    >
                      #{c.name}
                    </a>
                  );
                })}
              </div>
              <div className="main" id="main">
                <div className="description">
                  <div>
                    <ReactMarkdown>{template.description}</ReactMarkdown>
                  </div>
                  <div className="sub-title margin-top">What you'll need</div>
                  <div>
                    <div className="margin-bottom">
                      The following sources connected to your org
                    </div>
                    <Row gutter={[16, 16]}>
                      {template.sourceMeta.map((sm) => {
                        return (
                          <Col span={12} key={sm.id}>
                            <Card height={72}>
                              <div className="source-meta-inner">
                                <div className="source-meta-logo">
                                  <img src={sm.publicInfo.logo} />
                                </div>
                                <div className="source-meta-name">
                                  {sm.publicInfo.name}
                                </div>
                              </div>
                            </Card>
                          </Col>
                        );
                      })}
                    </Row>
                  </div>
                  <div className="sub-title margin-top">What you'll get</div>
                  <div>
                    <div className="margin-bottom">
                      This template will create{" "}
                      {getNumberOfExplorations(template.executor).length}{" "}
                      exploration on your behalf
                    </div>
                  </div>
                  <div className="margin-bottom" />
                </div>
                <div className="cta">
                  <ReactVisibilitySensor>
                    {({ isVisible }) => {
                      if (buttonVisible !== isVisible) {
                        this.setState({ buttonVisible: isVisible });
                      }
                      return (
                        <Tooltip title={message}>
                          <Button
                            loading={creatingExploration.status === "loading"}
                            onClick={
                              missingSources.length
                                ? undefined
                                : () =>
                                    this.onTemplateCreation(
                                      sourceMetas,
                                      template.executor,
                                      template
                                    )
                            }
                            id={"cta"}
                            type="primary"
                            block={true}
                            size={"large"}
                          >
                            {missingSources.length ? (
                              <span>
                                <LockFilled /> Use Template
                              </span>
                            ) : (
                              <span>Use Template</span>
                            )}
                          </Button>
                        </Tooltip>
                      );
                    }}
                  </ReactVisibilitySensor>
                </div>
              </div>
            </Layout>
          );
        }}
      </GraphQl>
    );
  }
}

export default compose<Props, ITemplateViewProps>(
  WithOrg,
  withAntUtils,
  withRouter
)(TemplateView);
