import type { DataNode } from "antd/es/tree";
import Tree from "antd/es/tree";
import { useCallback, useEffect, useState } from "react";
import type { RouterProps } from "react-router";
import { withRouter } from "react-router";
import type { InjectedAntUtilsProps } from "../../../../../components/ant-utils/withAntUtils";
import { withAntUtils } from "../../../../../components/ant-utils/withAntUtils";
import { compose } from "../../../../../components/compose/WlyCompose";
import Loading from "../../../../../components/layout/feedback/loading";
import type {
  IExploration,
  IExplorationSection,
} from "../../../../../interfaces/explorations";
import { IUserRoleType } from "../../../../../interfaces/user";
import GraphQLService from "../../../../../services/graphql/GraphQLService";
import ExplorationMenuItem from "../../../../exploration/menu/ExplorationMenuItem";
import { ExplorationSectionMenuItem } from "../../../../exploration/menu/ExplorationSectionMenuItem";
import type { InjectedOrgProps } from "../../../../orgs/WithOrg";
import WithOrg from "../../../../orgs/WithOrg";
import { hasRoleAccessBoolean } from "../../../../user-settings/HasRoleAccess";

interface IDashboardEditAddChartExplorationProps {
  onSelectExploration: (exploration: IExploration) => void;
}

type Props = IDashboardEditAddChartExplorationProps &
  RouterProps &
  InjectedOrgProps &
  InjectedAntUtilsProps;

const generateSectionKey = (id: string) => {
  return `section-${id}`;
};

function DashboardEditAddChartExploration(props: Props) {
  const { antUtils, org, user, onSelectExploration } = props;

  const [explorations, setExplorations] = useState<IExploration[]>([]);
  const [sections, setSections] = useState<IExplorationSection[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [openKeys, setOpenKeys] = useState<string[]>([]);

  const fetchData = useCallback(async () => {
    try {
      setLoading(true);
      const query = `
        query getExplorationsAndSections($orgId: ID!) {
          allExplorations(sortBy: name_ASC, where: { org: { id: $orgId }, deleted_not: true }) {
            id
            slug
            name
            section {
              id
            }
          }
          allExplorationSections(
            sortBy: name_ASC
            where: { org: { id: $orgId }, deleted_not: true }
          ) {
            id
            slug
            name
            image
            order
            explorations {
              id
            }
          }
        }
      `;
      const resp = await GraphQLService<{
        allExplorationSections: IExplorationSection[];
        allExplorations: IExploration[];
      }>(query, {
        orgId: org.id,
      });
      setSections(resp.allExplorationSections);
      setExplorations(resp.allExplorations);
      setOpenKeys([
        ...resp.allExplorationSections.map((s) => generateSectionKey(s.id)),
        "section-wip",
      ]);
    } catch (error) {
      console.warn(error);
      antUtils.message.error("Error, please try again");
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const isBuilder = hasRoleAccessBoolean(IUserRoleType.BUILDER, user, org.id);

  const getSortedSection = (
    s: IExplorationSection[]
  ): IExplorationSection[] => {
    return [...s].sort((a, b) => {
      const f = a.order ? a.order : 0;
      const g = b.order ? b.order : 0;
      if (f === 0 && g === 0) {
        return 1;
      }
      return f - g;
    });
  };

  const buildTree = (): DataNode[] => {
    const renderLine = (ex: IExplorationSection) => {
      return <ExplorationSectionMenuItem explorationSection={ex} />;
    };

    const renderExplorationTitle = (e: IExploration) => {
      return (
        <ExplorationMenuItem
          exploration={e}
          onClick={() => {
            onSelectExploration(e);
          }}
          showAccelerationStatus={false}
        />
      );
    };

    const buildExploration = (
      parentKey: string,
      ex: IExploration[]
    ): DataNode[] => {
      if (ex.length > 0) {
        return ex.map((e) => ({
          key: `exploration-${e.id}`,
          title: renderExplorationTitle(e),
          isLeaf: true,
          selectable: false,
        }));
      }
      return [
        {
          key: `no-data-${parentKey}`,
          title: "No explorations...",
          selectable: false,
          isLeaf: true,
          disabled: true,
        },
      ];
    };

    return [
      ...getSortedSection(sections).map((s) => {
        return {
          key: generateSectionKey(s.id),
          title: renderLine(s),
          selectable: false,
          children: buildExploration(
            s.id,
            explorations.filter((e) => e.section && e.section.id === s.id)
          ),
        };
      }),
      ...(isBuilder
        ? [
            {
              key: "section-wip",
              title: renderLine({
                name: "Work in progress",
                image: ":hammer_and_wrench:",
              } as IExplorationSection),
              selectable: false,
              children: buildExploration(
                "wip",
                explorations.filter((e) => !e.section)
              ),
            },
          ]
        : []),
    ];
  };
  if (loading) {
    return (
      <div style={{ marginTop: "50%" }}>
        <Loading />
      </div>
    );
  } else {
    return (
      <Tree
        onExpand={(expandedKeys) => {
          setOpenKeys(expandedKeys.map((k) => k.toString()));
        }}
        expandedKeys={openKeys}
        defaultExpandAll={true}
        blockNode={true}
        selectable={false}
        draggable={false}
        treeData={buildTree()}
        rootStyle={{
          background: "none",
        }}
      />
    );
  }
}

export default compose<Props, IDashboardEditAddChartExplorationProps>(
  withRouter,
  WithOrg,
  withAntUtils
)(DashboardEditAddChartExploration);
