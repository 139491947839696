import { inject, observer } from "mobx-react";
import moment from "moment";
import * as React from "react";
import type { RouteComponentProps } from "react-router";
import { withRouter } from "react-router";
import type { InjectedAntUtilsProps } from "../../components/ant-utils/withAntUtils";
import { withAntUtils } from "../../components/ant-utils/withAntUtils";
import { compose } from "../../components/compose/WlyCompose";
import {
  ONBOARDING_SEARCH_SETTINGS,
  parseSearch,
} from "../../helpers/queryStringHelpers";
import { IOrgFeatureType } from "../../interfaces/org";
import type { OnboardingStoreProps } from "../../store/onboardingStore";
import type { UserStoreProps } from "../../store/userStore";
import ExternalLayout from "../layout/ExternalLayout";
import type { InjectedOrgProps } from "../orgs/WithOrg";
import WithOrg from "../orgs/WithOrg";
import Questionnaire from "../questionnaire/Questionnaire";
import { DefaultPayment } from "./payment/DefaultPayment";
import WarehouseConnection from "./steps/WarehouseConnection";

interface IOnboardingProps {
  children?: React.ReactNode;
}

type Props = IOnboardingProps &
  UserStoreProps &
  OnboardingStoreProps &
  RouteComponentProps<{ organizationSlug: string }> &
  InjectedOrgProps &
  InjectedAntUtilsProps;

interface IState {
  hasDisplayedNotifications: boolean;
}

class Onboarding extends React.Component<Props, IState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      hasDisplayedNotifications: false,
    };
  }

  generateMessage = () => {
    const {
      org,
      antUtils: { notifications },
    } = this.props;
    const featureFreeTrials = org.featureGrants
      .filter((grant) => {
        return !!grant.freeTrialEndAt;
      })
      .map((grant) => {
        const remainingDays = grant.freeTrialEndAt
          ? moment(grant.freeTrialEndAt).diff(moment(), "day")
          : -1;
        return {
          remainingDays,
          name:
            grant.feature.apiName ===
            IOrgFeatureType.GRANULAR_REPORT_FOLDER_SHARING
              ? `Workspace Access Control`
              : undefined,
        };
      });

    const showOrgLevelAlert = org.status === "freetrial";
    const showFeatureGrantLevelAlert = featureFreeTrials.length > 0;
    if (!this.state.hasDisplayedNotifications) {
      const orgRemainingDays = org.freeTrialEndDate
        ? moment(org.freeTrialEndDate).diff(moment(), "day")
        : -1;

      if (showOrgLevelAlert) {
        if (orgRemainingDays >= 0) {
          notifications.info({
            key: "org_trial",
            description: (
              <span>Your Whaly trial ends in {orgRemainingDays} days. </span>
            ),
            duration: 0,
            message: `${orgRemainingDays} days remaining`,
          });
        } else {
          notifications.info({
            key: "org_trial",
            duration: 0,
            description: (
              <span>
                Your Whaly trial has ended, please contact your representative
                to activate the full version.
              </span>
            ),
            message: "Your trial has ended",
          });
        }
      }

      if (showFeatureGrantLevelAlert) {
        featureFreeTrials.map((freeTrial) => {
          if (freeTrial.remainingDays >= 0) {
            notifications.info({
              key: `${freeTrial.name}_trial`,
              duration: 0,
              description: (
                <span>
                  Your {freeTrial.name} trial ends in {freeTrial.remainingDays}{" "}
                  days.
                </span>
              ),
              message: "Your trial has ended",
            });
          } else {
            notifications.info({
              key: `${freeTrial.name}_trial`,
              duration: 0,
              description: (
                <span>
                  Your {freeTrial.name} trial has ended, please contact your
                  representative to activate the full version.
                </span>
              ),
              message: "Your trial has ended",
            });
          }
        });
      }

      this.setState({ hasDisplayedNotifications: true });
    }
  };

  componentDidMount() {
    const {
      onboardingStore,
      org,
      location: { search },
    } = this.props;
    onboardingStore.getOnboardingStatus(org.slug, org.status);
    const s = parseSearch<{
      onboardingStatus: { sourceId: string; status: "syncing" };
    }>(search, ONBOARDING_SEARCH_SETTINGS);
    this.generateMessage();
  }

  componentDidUpdate(prevProps: Props) {
    const {
      org,
      onboardingStore,
      location: { search },
    } = this.props;
    const {
      org: prevOrg,
      location: { search: prevSearch },
    } = prevProps;
    if (org.status !== prevOrg.status) {
      onboardingStore.getOnboardingStatus(org.slug, org.status);
    }
    if (org.slug !== prevOrg.slug) {
      onboardingStore.getOnboardingStatus(org.slug, org.status);
    }
    this.generateMessage();
  }

  public render() {
    const { children, onboardingStore, org, userFeatures } = this.props;

    if (onboardingStore.onboardingStep === "QUESTIONNAIRE") {
      return (
        <Questionnaire
          onDone={async () => {
            try {
              await this.props.userStore.getUser();
            } catch (error) {
              console.warn(error);
            }
          }}
        />
      );
    }

    if (onboardingStore.onboardingStep === "WAREHOUSE_CONNECTION") {
      const refreshOnboarding = () =>
        onboardingStore.getOnboardingStatus(org.slug, org.status);
      return <ExternalLayout contentComponent={WarehouseConnection as any} />;
    }

    if (org.status === "defaultpayment") {
      return <ExternalLayout contentComponent={DefaultPayment as any} />;
    }

    return children;
  }
}

export default compose<Props, IOnboardingProps>(
  withRouter,
  WithOrg,
  withAntUtils
)(inject("onboardingStore", "userStore")(observer(Onboarding)));
