import type {
  GenerateModelEvent,
  GenerateWidgetEvent,
} from "@projectstorm/react-canvas-core";
import { AbstractReactFactory } from "@projectstorm/react-canvas-core";
import type { DiagramEngine } from "@projectstorm/react-diagrams-core";
import { ExplorationNodeModel } from "./ExplorationNodeModel";
import ExplorationNodeWidget from "./ExplorationNodeWidget";

export class ExplorationNodeFactory extends AbstractReactFactory<
  ExplorationNodeModel,
  DiagramEngine
> {
  constructor() {
    super("exploration");
  }

  generateReactWidget(
    event: GenerateWidgetEvent<ExplorationNodeModel>
  ): JSX.Element {
    return (
      <ExplorationNodeWidget
        engine={this.engine}
        size={50}
        node={event.model}
      />
    );
  }

  generateModel(event: GenerateModelEvent) {
    return new ExplorationNodeModel();
  }
}
