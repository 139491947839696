import { List } from "antd";
import type { Formatter } from "../../../../../../../../../../components/chart/domain";
import { customFormatter } from "../../../../../../../../../../components/chart/utils/optionsHelper";
import type { UserLocale } from "../../../../../../../../../../interfaces/user";
import "./TableSparklineRenderer.scss";

export interface ITableSparklineRendererProps {
  config: {
    x: {
      key: string;
    };
    y: { key: string; formatter: Formatter };
    options: {
      highlightLabel?: string;
    };
  };
  data: Array<{
    [key: string]: string | number | boolean;
  }>;
  locale: UserLocale;
  theme: { background: string; primary: string; secondary: string };
}

export function TableSparklineRenderer(props: ITableSparklineRendererProps) {
  const { config, data, theme, locale } = props;

  if (!data.length) return <></>;

  return (
    <List
      size="small"
      className="sparkline-table"
      style={{ height: "100%" }}
      bordered={false}
      dataSource={data}
      renderItem={(item, index) => {
        return (
          <List.Item style={{ padding: "3px 0px" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                gap: 6,
              }}
            >
              <div
                style={{
                  width: 20,
                  fontSize: 11,
                  color:
                    item[config.x.key] === config?.options?.highlightLabel
                      ? theme.primary
                      : theme.secondary,
                  padding: "1px 4px",
                }}
              >
                {index + 1}.
              </div>
              <div
                style={{
                  color:
                    item[config.x.key] === config?.options?.highlightLabel
                      ? "white"
                      : theme.secondary,
                  fontSize: 11,
                  padding: "1px 7px",
                  background:
                    item[config.x.key] === config?.options?.highlightLabel
                      ? theme.primary
                      : "transparent",
                  borderRadius: 5,
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {item[config.x.key]}
              </div>
              <div style={{ flex: 1 }} />
              <div
                style={{
                  padding: "1px 4px",
                  whiteSpace: "nowrap",
                  color:
                    item[config.x.key] === config?.options?.highlightLabel
                      ? theme.primary
                      : theme.secondary,
                  fontSize: 12,
                }}
              >
                {customFormatter(
                  item[config.y.key],
                  locale,
                  config.y.formatter
                )}
              </div>
            </div>
          </List.Item>
        );
      }}
    />
  );
}
