import {
  ArrowRightOutlined,
  HolderOutlined,
  LayoutOutlined,
  ShareAltOutlined,
} from "@ant-design/icons";
import { Button, Card, Empty, Space, Tooltip, Typography } from "antd";
import _ from "lodash";
import { inject, observer } from "mobx-react";
import React from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { Link } from "react-router-dom";
import type { InjectedAntUtilsProps } from "../../../../../components/ant-utils/withAntUtils";
import { withAntUtils } from "../../../../../components/ant-utils/withAntUtils";
import { compose } from "../../../../../components/compose/WlyCompose";
import { WlyDynamicIcon } from "../../../../../components/icons/dynamic-renderer/WlyDynamicIconRenderer";
import { IconHolder } from "../../../../../components/icons/holder/IconHolder";
import type { IObject } from "../../../../../interfaces/object";
import type { IOrg } from "../../../../../interfaces/org";
import { routeDescriptor } from "../../../../../routes/routes";
import GraphQLService from "../../../../../services/graphql/GraphQLService";
import type { WorkspaceDatatoreProps } from "../../../../../store/dataStores/workspace/workspaceDatastore";
import type { WorkspaceUIStoreProps } from "../../../../../store/workspaceUIStore";

interface IObjectPageModalInnerProps {
  data: IObject[];
  org: IOrg;
  canMoveObject: boolean;
}

const insertIntoArray = (arr: Array<any>, index: number, newItem: any) => [
  ...arr.slice(0, index),

  newItem,

  ...arr.slice(index),
];

type Props = IObjectPageModalInnerProps &
  WorkspaceDatatoreProps &
  InjectedAntUtilsProps &
  WorkspaceUIStoreProps;

function ObjectPageModalInner(props: Props) {
  const {
    data,
    org,
    workspaceUIStore,
    workspaceDatastore,
    antUtils: { message },
    canMoveObject,
  } = props;

  const [tempData, setTempData] = React.useState<IObject[]>(data);

  const onChange = async () => {
    const l = message.loading("Updating...", 0);
    try {
      await GraphQLService(
        `
        mutation updateAllObject($data: [ObjectsUpdateInput]!) {
          updateObjects(data: $data) {
            id
          }
      }
      `,
        {
          data: tempData.map((t) => {
            return {
              id: t.id,
              data: {
                canBeListed: t.canBeListed,
                position: t.position,
              },
            };
          }),
        }
      );
      l();
      workspaceDatastore.updateObjects(tempData);
      message.success("Successfully updated objects");
    } catch (err) {
      l();
      message.error("There was an error updating objects, please retry");
    }
  };

  const debouncedChange = _.debounce(onChange, 500);

  React.useEffect(() => {
    if (!_.isEqual(tempData, data)) {
      debouncedChange();
    }
  }, [tempData]);

  const renderObjectCard = (o: IObject, index: number) => {
    return (
      <Draggable
        isDragDisabled={!canMoveObject}
        draggableId={o.id}
        key={o.id}
        index={index}
      >
        {(provided, snapshot) => (
          <Card
            ref={provided.innerRef}
            {...provided.draggableProps}
            key={o.id}
            size="small"
            bodyStyle={{ padding: `6px 12px` }}
          >
            <Link
              replace
              to={routeDescriptor["object"].renderRoute(
                {
                  organizationSlug: org.slug,
                  objectSlug: o.slug,
                },
                {
                  view:
                    !o.defaultView?.isDeleted && o.defaultView?.slug
                      ? o.defaultView.slug
                      : undefined,
                }
              )}
            >
              <div style={{ display: "flex", alignItems: "center", gap: 6 }}>
                {canMoveObject && (
                  <div
                    {...provided.dragHandleProps}
                    style={{ flex: `0 auto`, cursor: "grab" }}
                  >
                    <HolderOutlined style={{ color: "gray" }} />
                  </div>
                )}
                <IconHolder
                  color="#F9F8F8"
                  background={o?.color || "#F1BD6C"}
                  icon={
                    o.icon ? (
                      <WlyDynamicIcon name={o.icon} />
                    ) : (
                      <LayoutOutlined color="#F9F8F8" />
                    )
                  }
                  size={24}
                />
                <div style={{ flex: 1 }}>
                  <Typography.Text strong>{o.name}</Typography.Text>
                </div>
                <div style={{ flex: `0 auto` }}>
                  <Space>
                    {o.canBeManagedByCurrentUser && (
                      <Tooltip title="Share">
                        <Button
                          type="text"
                          icon={<ShareAltOutlined />}
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            workspaceUIStore.setShareObjectModalOpen(o);
                          }}
                        />
                      </Tooltip>
                    )}
                    <Link
                      replace
                      to={routeDescriptor["object"].renderRoute({
                        organizationSlug: org.slug,
                        objectSlug: o.slug,
                      })}
                    >
                      <Button type="text" icon={<ArrowRightOutlined />} />
                    </Link>
                  </Space>
                </div>
              </div>
            </Link>
          </Card>
        )}
      </Draggable>
    );
  };

  const renderDroppableZone = (
    title: React.ReactNode,
    droppableId: string,
    data: IObject[]
  ) => {
    return (
      <div>
        <div>
          <Typography.Title level={5}>{title}</Typography.Title>
        </div>
        <Droppable
          isDropDisabled={!canMoveObject}
          droppableId={droppableId}
          type="OBJECT"
        >
          {(provided, snapshot) => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              <Space
                style={{ width: "100%" }}
                size={"small"}
                direction={"vertical"}
              >
                {data.length ? (
                  data.map((o, i) => {
                    return (
                      <React.Fragment key={o.id}>
                        {renderObjectCard(o as IObject, i)}
                      </React.Fragment>
                    );
                  })
                ) : (
                  <div>
                    <Empty />
                  </div>
                )}
                {provided.placeholder}
              </Space>
            </div>
          )}
        </Droppable>
      </div>
    );
  };

  return (
    <DragDropContext
      onDragEnd={(e, provided) => {
        const draggedObject = tempData.find((d) => d.id === e.draggableId);
        if (
          draggedObject &&
          e.destination &&
          typeof e.destination?.index === "number"
        ) {
          const allVisibleObjects = _.sortBy(
            tempData.filter((o) => o.canBeListed && o.id !== draggedObject.id),
            "position"
          );

          const allInvisibleObjects = _.sortBy(
            tempData.filter((o) => !o.canBeListed && o.id !== draggedObject.id),
            "position"
          );

          if (e.source?.droppableId !== e.destination?.droppableId) {
            // we changed the visibility

            if (e.destination?.droppableId === "visible") {
              const newAllVisibleObjects = insertIntoArray(
                allVisibleObjects,
                e.destination?.index,
                { ...draggedObject, canBeListed: true }
              ).map((n, i) => ({ ...n, position: i }));
              const newAllInvisibleObjects = allInvisibleObjects.map(
                (n, i) => ({ ...n, position: i })
              );
              setTempData([...newAllVisibleObjects, ...newAllInvisibleObjects]);
            } else {
              const newAllInvisibleObjects = insertIntoArray(
                allInvisibleObjects,
                e.destination?.index,
                { ...draggedObject, canBeListed: false }
              ).map((n, i) => ({ ...n, position: i }));
              const newAllVisibleObjects = allVisibleObjects.map((n, i) => ({
                ...n,
                position: i,
              }));
              setTempData([...newAllVisibleObjects, ...newAllInvisibleObjects]);
            }
          } else {
            // we just repositionned

            if (e.destination?.droppableId === "visible") {
              const newAllVisibleObjects = insertIntoArray(
                allVisibleObjects,
                e.destination?.index,
                draggedObject
              ).map((n, i) => ({ ...n, position: i }));
              setTempData([...newAllVisibleObjects, ...allInvisibleObjects]);
            } else {
              const newAllInvisibleObjects = insertIntoArray(
                allInvisibleObjects,
                e.destination?.index,
                draggedObject
              ).map((n, i) => ({ ...n, position: i }));
              setTempData([...allVisibleObjects, ...newAllInvisibleObjects]);
            }
          }
        }
      }}
    >
      <Space size={"large"} style={{ width: "100%" }} direction={"vertical"}>
        {renderDroppableZone(
          "Pinned",
          "visible",
          _.sortBy(
            tempData.filter((o) => o.canBeListed),
            "position"
          )
        )}
        {renderDroppableZone(
          "Hidden",
          "hidden",
          _.sortBy(
            tempData.filter((o) => !o.canBeListed),
            "position"
          )
        )}
      </Space>
    </DragDropContext>
  );
}

export default compose<Props, IObjectPageModalInnerProps>(
  inject("workspaceDatastore", "workspaceUIStore"),
  observer,
  withAntUtils
)(ObjectPageModalInner);
