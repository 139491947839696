import { gql } from "@apollo/client";
import type { QueryOptions } from "../../../../../components/hooks/query/useGQLQuery";
import { useGQLQuery } from "../../../../../components/hooks/query/useGQLQuery";
import type { IUserRole } from "../../../../../interfaces/user";

type QueryData = {
  allUserRoles: IUserRole[];
};

type QueryVariables = {
  filters: Record<string, any>;
};

const OrgUsersQuery = gql`
  query orgUsersQuery($filters: UserRoleWhereInput!) {
    allUserRoles(where: $filters) {
      id
      user {
        id
        firstName
        lastName
        email
      }
    }
  }
`;

export const useGetOrgUsers = (
  options: QueryOptions<QueryData, QueryVariables>
) => {
  return useGQLQuery<QueryData, QueryVariables>(OrgUsersQuery, options);
};
