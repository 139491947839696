import * as React from "react";
import { compose } from "../../../components/compose/WlyCompose";
import Error from "../../../components/layout/feedback/error";
import Loading from "../../../components/layout/feedback/loading";
import type { IExploration } from "../../../interfaces/explorations";
import GraphQl from "../../graphql/graphql";
import type { InjectedOrgProps } from "../../orgs/WithOrg";
import WithOrg from "../../orgs/WithOrg";
import SettingsWrapper from "../SettingsWrapper";
import UnsedExplorationTable from "./UnusedExplorationTable";

interface IUnusedExplorationPageProps {}

const gql = `
query getUnusedExplorations(
  $orgId: ID!
) {
  allExplorations(where: {
    tiles_none: {
      deleted_not: true,
      report: {
        deleted_not: true,
        folder: {
          deleted_not: true
        }
      }
    },
    deleted_not: true,
    org: {
        id: $orgId
      }
  }) {
    id
    name
    slug
    section {
      name
      image
    }
    updatedAt
    updatedBy {
      id
      firstName
      lastName
      gravatar
      avatarColor
    }
  }
}
`;

interface IReturnValue {
  allExplorations: IExploration[];
}

type Props = IUnusedExplorationPageProps & InjectedOrgProps;

class UnusedExplorationPage extends React.Component<Props> {
  public render() {
    const { org } = this.props;
    return (
      <SettingsWrapper>
        <GraphQl<IReturnValue> query={gql} variables={{ orgId: org.id }}>
          {(gql) => {
            if (gql.status === "loading" || gql.status === "initial") {
              return <Loading />;
            }
            if (gql.status === "error") {
              return <Error />;
            }
            return (
              <UnsedExplorationTable explorations={gql.data.allExplorations} />
            );
          }}
        </GraphQl>
      </SettingsWrapper>
    );
  }
}

export default compose<Props, IUnusedExplorationPageProps>(WithOrg)(
  UnusedExplorationPage
);
