import { DeleteOutlined, DragOutlined } from "@ant-design/icons";
import { Button, Typography } from "antd";
import type { Identifier, XYCoord } from "dnd-core";
import { useRef } from "react";
import { useDrag, useDrop } from "react-dnd";
import { useHover } from "../../components/hooks/useHover";
import type { InjectedOrgProps } from "../../containers/orgs/WithOrg";
import WithOrg from "../../containers/orgs/WithOrg";
import { compose } from "../compose/WlyCompose";
import PaletteRenderer from "../palette/PaletteRenderer";
import type { IPalette, IPaletteSelection } from "../palette/utils/paletteData";
import { getSelectedPalette } from "../palette/utils/paletteData";
import type { IConditionalFormatterRule } from "./domain";
import { getOperatorSentence } from "./domain";

import "./WlyConditionalFormatterRuleCard.scss";

interface IConditionalFormatterRuleCardProps {
  rule: IConditionalFormatterRule;
  index?: number;
  moveCard?: (dragIndex: number, hoverIndex: number) => void;
  onDelete?: (ruleKey: string) => void;
  onEdit?: (ruleKey: string) => void;
}

type Props = IConditionalFormatterRuleCardProps & InjectedOrgProps;

interface DragItem {
  index: number;
  id: string;
  type: string;
}

function WlyConditionalFormatterRuleCard(props: Props) {
  const { rule, index, org, moveCard, onDelete, onEdit } = props;

  const ref = useRef<HTMLDivElement>(null);
  const [hoverRef, isHover] = useHover<HTMLDivElement>();
  const [, drop] = useDrop<DragItem, void, { handlerId: Identifier | null }>({
    accept: "CUSTOM_FORMATTING_RULE_CARD",
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item: DragItem, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      // Time to actually perform the action
      moveCard(dragIndex, hoverIndex);

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: "CUSTOM_FORMATTING_RULE_CARD",
    item: () => {
      return { ...rule, index };
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));

  const renderColor = (rule: IConditionalFormatterRule) => {
    if (rule.type === "scale") {
      const palette: IPalette | IPaletteSelection = rule.format.palette ?? {
        type: "PALETTE_SELECTION",
        collection_name: "default",
        palette_type: "continue",
        palette_subtype: "diverging",
        index: 0,
      };
      const currentPalette: IPalette = getSelectedPalette(org, palette);
      return <PaletteRenderer size="small" {...currentPalette} />;
    } else if (rule.type === "single") {
      return (
        <div
          style={{
            width: 28,
            height: 24,
            borderRadius: 6,
            color: rule.format.fontColor ?? "black",
            backgroundColor: rule.format.backgroundColor ?? "transparent",
            border: "1px solid #cecece",
            fontWeight: 700,
            fontSize: 14,
            display: "flex",
            alignContent: "center",
            justifyContent: "center",
          }}
        >
          1
        </div>
      );
    }
  };

  const renderText = (rule: IConditionalFormatterRule) => {
    if (rule.type === "single") {
      return getOperatorSentence(rule.condition);
    } else if (rule.type === "scale") {
      return `From ${rule.condition.value.min} to ${rule.condition.value.max}`;
    }
  };

  return (
    <div ref={hoverRef}>
      <div
        ref={ref}
        onClick={() => onEdit(rule.id)}
        className={"wly-conditional-formatter-rule-card"}
        style={{
          opacity,
        }}
      >
        <div style={{ flex: "0 0 40px" }}>{renderColor(rule)}</div>
        <div style={{ flex: "1 1 auto", minWidth: 0 }}>
          <Typography.Text ellipsis strong>
            {renderText(rule)}
          </Typography.Text>
        </div>
        <div hidden={!isHover} style={{ flex: "0 0 60px" }}>
          <Button
            icon={<DragOutlined />}
            type="text"
            shape="circle"
            className="drag-button"
          />
          <Button
            icon={<DeleteOutlined />}
            type={"text"}
            shape="circle"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              onDelete(rule.id);
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default compose<Props, IConditionalFormatterRuleCardProps>(WithOrg)(
  WlyConditionalFormatterRuleCard
);
