import "./TabItem.scss";

interface ITabItemProps extends React.HTMLProps<HTMLDivElement> {
  active?: boolean;
  children?: React.ReactNode;
}

export function TabItem(props: ITabItemProps) {
  const { active, ...rest } = props;
  return (
    <div
      className={`workbench-tab-item ${props.active ? "active" : ""}`}
      {...rest}
    >
      {props.children}
    </div>
  );
}
