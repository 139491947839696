import _ from "lodash";
import type { IRunResult } from "../../interfaces/jobExecutions";
import type { IModelFolder } from "../../interfaces/modelFolder";
import type { IDataset, IDatasetRelationship } from "../../interfaces/sources";
import type {
  BundledTransformation,
  TableJoinOperation,
  TableRemoveColumnOperation,
  Transformation,
  WhalyExtTableRollupOperation,
} from "../../interfaces/transformations";
import GraphQLService from "../../services/graphql/GraphQLService";
import { generateUniqueId } from "../../utils/uniqueId";
import type { ViewTabItem } from "./views/ViewSelector";

const bundleTransformation = (
  transformations: Transformation[]
): BundledTransformation[] => {
  const bundledTransformation: BundledTransformation[] = [];
  const keysToSkip: string[] = [];
  transformations.forEach((tr) => {
    if (!keysToSkip.find((k) => k === tr.var)) {
      if (
        tr.operation.type === "Table.Join" ||
        tr.operation.type === "WhalyExt.Table.AddRollupColumn" ||
        tr.operation.type === "WhalyExt.Table.AddLookupColumn"
      ) {
        switch (tr.operation.type) {
          case "Table.Join":
            const joinRefColumns: Transformation[] = [];
            keysToSkip.push(tr.operation.args.table2);
            const jftr = transformations.find(
              (tra) =>
                tra.var === (tr.operation as TableJoinOperation).args.table2
            );
            // we are assuming the the next item in the stack is a dataset reference where it can be anything
            if (jftr && jftr.operation.type === "Table.FromWhalyDataset") {
              joinRefColumns.push(jftr);
            }
            bundledTransformation.push({
              baseTransformation: tr,
              baseVar: tr.operation.args.table1,
              referenceReports: joinRefColumns,
            });
            break;
          case "WhalyExt.Table.AddLookupColumn":
          case "WhalyExt.Table.AddRollupColumn":
            const rollUpRefColumns: Transformation[] = [];
            keysToSkip.push(tr.operation.args.table2);
            const rftr = transformations.find(
              (tra) =>
                tra.var ===
                (tr.operation as WhalyExtTableRollupOperation).args.table2
            );
            if (rftr && rftr.operation.type === "Table.FromWhalyDataset") {
              rollUpRefColumns.push(rftr);
            }
            bundledTransformation.push({
              baseTransformation: tr,
              baseVar: tr.operation.args.table1,
              referenceReports: rollUpRefColumns,
            });
            break;
        }
      } else {
        bundledTransformation.push({
          baseTransformation: tr,
          // we need to better type operation base
          baseVar: (tr.operation.args as any).table,
          referenceReports: [],
        });
      }
    }
  });
  return bundledTransformation;
};

export const hideColumn = (
  prevTransformations: Transformation[],
  columnName: string,
  viewCursor: string
) => {
  let newTransformations = prevTransformations;
  if (
    prevTransformations.find((t) => t.operation.type === "Table.RemoveColumns")
  ) {
    newTransformations = newTransformations.map((t) => {
      if (t.operation.type === "Table.RemoveColumns") {
        return {
          ...t,
          operation: {
            ...t.operation,
            args: {
              ...t.operation.args,
              columns: [...t.operation.args.columns, columnName],
            },
          } as TableRemoveColumnOperation,
        };
      }
      return t;
    });
  } else {
    newTransformations = [
      ...newTransformations,
      {
        var: generateUniqueId(),
        domain: "viewResolver",
        operation: {
          type: "Table.RemoveColumns",
          args: {
            table: viewCursor,
            columns: [columnName],
          },
        } as TableRemoveColumnOperation,
      },
    ];
  }
  return newTransformations;
};

export const removeColumn = (
  transformations: Transformation[],
  toRemoveVar: string
) => {
  let newTransformations = _.cloneDeep(transformations);
  const bundledItems = bundleTransformation(newTransformations);
  const bundleItem = bundledItems.find(
    (t) => t.baseTransformation.var === toRemoveVar
  );
  if (bundleItem) {
    newTransformations = newTransformations
      .filter(
        (t) => bundleItem.referenceReports.map((r) => r.var).indexOf(t.var) < 0
      )
      .map((t) => {
        if (t.var === bundleItem.baseTransformation.var) {
          return {
            var: t.var,
            operation: {
              type: "Table.Ref",
              args: {
                table: bundleItem.baseVar,
              },
            },
            domain: t.domain,
          };
        }
        return t;
      });
  }
  return newTransformations;
};

export const checkIfColumnIsUsed = (
  orgId: string,
  columnName: string,
  datasetId: string
): Promise<{
  allDimensions: Array<{
    name: string;
    table: {
      name: string;
    };
  }>;
  allMetrics: Array<{
    name: string;
    table: {
      name: string;
    };
  }>;
}> => {
  const columnNameInFilter = `"column":"${columnName}"`;
  return GraphQLService(
    `
  query getUsedDimension($orgId: ID!, $columnName: String!,  $columnNameInFilter: String!, $datasetId: ID!) {
    allDimensions(where: {
      columnName: $columnName,
      table: {
        deleted_not: true,
        exploration: {
          deleted_not: true
        },
        view: {
          deleted_not: true,
          dataset: {
            deleted_not: true,
            id:$datasetId
          }
        }
      },
      deleted_not: true,
      org: {
        id: $orgId
      }
    }) {
      name
      table {
        name
      }
    }
    allMetrics(where: {
      AND: [{
        deleted_not: true,
        table: {
          deleted_not: true,
          exploration: {
            deleted_not: true
          },
          view: {
            deleted_not: true,
            dataset: {
              deleted_not: true,
              id:$datasetId
            }
          }
        },
        org: {
          id: $orgId
        }
      }, {
        OR: [
          {columnName: $columnName},
          {filters_contains_i: $columnNameInFilter}
        ]
      }]
    }) {
      name
      table {
        name
      }
    }
  }
  `,
    {
      orgId,
      columnName,
      columnNameInFilter,
      datasetId,
    }
  );
};

export interface TableTabItem extends BasicTableTabItem {
  key: string;
  label: string;
  query: Transformation[];
  isModel: boolean;
  folder: IModelFolder;
  primaryKey: string[];
  foreignKeys: string[];
  userDefinedColumns: string[];
  baseDatasetId: string;
  baseSourceId: string;
  isSQL: boolean;
  managedBy: "WHALY" | "DBT_CLOUD";
  datasetQueryCursor: string;
  relationships: IDatasetRelationship[];
  hasUpstreamError: boolean;
  dataset: IDataset;
  views: ViewTabItem[];
  hideFromInterface: boolean;
}

export interface BasicTableTabItem extends CoreTableTabItem {
  key: string;
  label?: string;
  query: Transformation[];
}

export interface CoreTableTabItem {
  primaryKey: string[];
  foreignKeys: string[];
  userDefinedColumns: string[];
  relationships: IDatasetRelationship[];
  datasetId?: string;
  runResults: Array<
    | {
        status: "error";
        message: string;
        operationName: string;
        type: "configuration";
      }
    | IRunResult
  >; // configuration errors are issued on the frontend
}

export interface ColumnInformations {
  [column_name: string]: {
    label?: string;
    description?: string;
    tests?: Array<ColumnInfoTest>;
  };
}

export interface ColumnInfoTest {
  name: string;
  status: "success" | "error" | "warning";
}
