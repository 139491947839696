import { CloseOutlined } from "@ant-design/icons";
import { Button, Col, Divider, Layout, Row, Typography } from "antd";
import * as React from "react";
import Card from "../../../components/cards/Card";
import Aligner from "../../../components/layout/aligner/Aligner";
import Feednack from "../../../components/layout/feedback/feedback";
import type { IExploration } from "../../../interfaces/explorations";
import type {
  ITemplate,
  ITemplateCategory,
} from "../../../interfaces/template";
import TemplateView from "../single/TemplateView";

import "./TemplatesPage.scss";

interface ITemplatePageProps {
  onClose: () => void;
  allTemplates: ITemplate[];
  allFeaturedTemplates: ITemplate[];
  allTemplateCategories: ITemplateCategory[];
  onSuccess: (
    explorations: Array<Pick<IExploration, "id" | "slug">>
  ) => Promise<any>;
}

const { Paragraph } = Typography;
const { Sider } = Layout;

export function TemplatePage(props: ITemplatePageProps) {
  const {
    onClose,
    allTemplates,
    allFeaturedTemplates,
    allTemplateCategories,
    onSuccess,
  } = props;

  const [category, setCategory] = React.useState<string | undefined>("all");
  const [templateSlug, setTemplateSlug] = React.useState<string | undefined>();

  const renderList = (templates: ITemplate[]) => {
    if (templates.length === 0) {
      return <Feednack>No Matching Template</Feednack>;
    }

    const onTemplateClick = (template: ITemplate) => {
      setTemplateSlug(template.slug);
      setCategory(undefined);
    };

    return (
      <Row gutter={[16, 16]}>
        {templates.map((template) => {
          return (
            <Col
              xs={24}
              sm={12}
              md={12}
              lg={8}
              key={template.id}
              span={8}
              onClick={() => onTemplateClick(template)}
            >
              <Card className="template">
                <Paragraph
                  className="title"
                  ellipsis={{ expandable: false, rows: 2 }}
                >
                  {template.name}
                </Paragraph>
                <Paragraph
                  className="description"
                  ellipsis={{ expandable: false, rows: 3 }}
                >
                  {template.description}
                </Paragraph>
                <div className="sources-meta">
                  {template.sourceMeta.map((s) => {
                    return (
                      <img
                        key={s.publicInfo.name}
                        className="source-logo"
                        alt={s.publicInfo.name}
                        src={s.publicInfo.logo}
                      />
                    );
                  })}
                </div>
              </Card>
            </Col>
          );
        })}
        <div style={{ width: "100%", marginTop: 24 }} />
      </Row>
    );
  };

  const renderTemplateDetail = (tslug: string) => {
    return (
      <TemplateView
        tslug={tslug}
        onSuccess={onSuccess}
        onBack={(c) => {
          setTemplateSlug(undefined);
          setCategory(c ? c : "all");
        }}
      />
    );
  };

  const renderTemplateList = () => {
    let data: ITemplate[] = [];

    if (!category || category === "all") {
      data = allTemplates;
    } else if (category === "FEATURED") {
      data = allFeaturedTemplates;
    } else {
      data = allTemplateCategories.find(
        (cat) => cat.slug === category
      ).templates;
    }

    return <Aligner>{renderList(data)}</Aligner>;
  };

  return (
    <>
      <div className={`catalog-modal-header`}>
        <div className={`catalog-modal-header-title`}>
          <Typography.Title style={{ marginBottom: 0 }} level={5}>
            Template Catalog
          </Typography.Title>
        </div>
        <div className={`catalog-modal-header-button`}>
          <Button
            onClick={onClose}
            type={"text"}
            shape="circle"
            icon={<CloseOutlined />}
          />
        </div>
      </div>
      <Divider style={{ margin: 0 }} />
      <Layout className="templates-page">
        <Sider width={255} className="menu">
          <div
            onClick={() => setCategory(undefined)}
            className={`category-item ${category === "all" ? " selected" : ""}`}
          >
            All
          </div>
          <hr />
          {allTemplateCategories.map((tc) => {
            return (
              <div
                onClick={() => setCategory(tc.slug)}
                key={tc.slug}
                className={`category-item ${
                  category === tc.slug && !templateSlug ? " selected" : ""
                }`}
              >
                {tc.name}
              </div>
            );
          })}
        </Sider>
        <Layout id="content" className="content catalog-modal-content selected">
          {templateSlug
            ? renderTemplateDetail(templateSlug)
            : renderTemplateList()}
        </Layout>
      </Layout>
    </>
  );
}
