import { inject, observer } from "mobx-react";
import * as React from "react";
import type { RouteComponentProps } from "react-router";
import { withRouter } from "react-router";
import type { InjectedAntUtilsProps } from "../../components/ant-utils/withAntUtils";
import { withAntUtils } from "../../components/ant-utils/withAntUtils";
import { compose } from "../../components/compose/WlyCompose";
import type { IUserRoleType } from "../../interfaces/user";
import type { Layout } from "../../routes/domain";
import type { UserStoreProps } from "../../store/userStore";
import OnboardingWrapper from "../onboarding/OnboardingWrapper";
import NoAccess from "../orgs/no-access/NoAccess";
import NoOrgAccess from "../orgs/no-access/NoOrgAccess";
import NoUser from "../orgs/no-access/NoUser";
import { hasRoleAccessBoolean } from "../user-settings/HasRoleAccess";
import ExternalLayout from "./ExternalLayout";
import NoLayout from "./NoLayout";

interface ILayoutManagerProps {
  layout: Layout;
  component: React.ComponentClass;
  additionalProps?: { [key: string]: any };
  hideOnboarding?: boolean;
  restrictAccess?: IUserRoleType;
}

type Props = ILayoutManagerProps &
  UserStoreProps &
  InjectedAntUtilsProps &
  RouteComponentProps<{ organizationSlug?: string }>;

class LayoutManager extends React.Component<Props> {
  public render() {
    const {
      antUtils,
      layout,
      userStore,
      component,
      additionalProps,
      hideOnboarding,
      match: {
        params: { organizationSlug },
      },
      restrictAccess,
    } = this.props;

    if (!userStore.user) {
      return <NoUser />;
    }

    if (
      organizationSlug &&
      !userStore.user.roles.find((r) => r.org.slug === organizationSlug)
    ) {
      return <NoOrgAccess />;
    }

    const role = userStore.user.roles.find(
      (r) => r.org.slug === organizationSlug
    );

    if (
      restrictAccess &&
      role &&
      !hasRoleAccessBoolean(restrictAccess, userStore.user, role.org.id)
    ) {
      return <NoAccess />;
    }
    const wrapOnboarding = (c: React.ReactNode) => {
      if (hideOnboarding) {
        return c;
      }
      return <OnboardingWrapper>{c}</OnboardingWrapper>;
    };

    if (userStore.session.isSudoSession === true) {
      const messageId = `sudoSessionWarn`;
      const logoutUrl =
        userStore.user.type === "PORTAL"
          ? `${
              window.WHALY_CONSTANTS.OVERRIDE_PORTAL_URL
                ? window.WHALY_CONSTANTS.OVERRIDE_PORTAL_URL
                : ""
            }/auth/api/logout`
          : `${window.WHALY_CONSTANTS.AUTH_SERVER_URL}/oauth2/sessions/logout`;

      // Remove previous message if there
      antUtils.message.destroy(messageId);
      antUtils.message.warning({
        key: messageId,
        content: (
          <>
            You are impersonnating{" "}
            <b>
              {userStore.user.firstName
                ? `${userStore.user.firstName} ${userStore.user.lastName}`
                : userStore.user.email}
            </b>
            . <a href={logoutUrl}>Click here to logout.</a>
          </>
        ),
        duration: 0,
      });
    }

    switch (layout) {
      case "external":
        return wrapOnboarding(
          <ExternalLayout
            contentComponent={component}
            additionalProps={additionalProps}
          />
        );
      case "none":
        return wrapOnboarding(
          <NoLayout
            contentComponent={component}
            additionalProps={additionalProps}
          />
        );
    }
  }
}

export default compose<Props, ILayoutManagerProps>(
  inject("userStore"),
  observer,
  withRouter,
  withAntUtils
)(LayoutManager);
