import type { IRecord } from "../../../domain";

export interface IEmailWidgetDividerConfig {
  borderWidth?: number;
  spacing?: "compact" | "normal";
  color?: string;
}

export const getDefaultConfig = (
  conf: IEmailWidgetDividerConfig,
  record: IRecord
) => {
  const borderWidth = conf.borderWidth ? conf.borderWidth : 1;
  const spacing = conf.spacing ? conf.spacing : "compact";
  const color = conf.color ? conf.color : "#F3F3F3";

  return {
    borderWidth,
    spacing,
    color,
  };
};
