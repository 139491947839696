import { EditorView } from "@codemirror/view";

export const SQLEditorTheme = EditorView.theme({
  ".cm-content, .cm-gutter": { minHeight: "70px" },
  ".cm-scroller": {
    fontFamily: 'Menlo, Monaco, "Courier New", monospace',
    fontSize: "13px",
    lineHeight: "18px",
  },
  "&.cm-editor": {
    borderRadius: "6px",
  },
  ".cm-highlightedTab": {
    display: "inline-block",
    position: "relative",
    "&:before": {
      content: '""',
      borderBottom: "1px solid #DADADA",
      position: "absolute",
      left: "5%",
      bottom: "50%",
      width: "90%",
      pointerEvents: "none",
    },
  },
  ".cm-highlightedSpaces:before": {
    color: "#DADADA",
    content: "attr(data-display)",
    position: "absolute",
    pointerEvents: "none",
  },
  ".cm-gutter.cm-foldGutter": {
    "& > .cm-gutterElement > .foldIcon.foldIcon-closed": {
      color: "black",
    },
    "& > .cm-gutterElement > .foldIcon.foldIcon-opened": {
      opacity: 0,
      transition: "opacity 0.3s",
    },
  },
  ".cm-gutter.cm-foldGutter:hover": {
    "& > .cm-gutterElement > .foldIcon.foldIcon-opened": {
      opacity: 1,
      transition: "opacity 0.3s",
    },
  },
  ".cm-gutters": {
    borderRadius: "6px 0px 0px 6px",
    background: "none",
    borderRight: "1px solid transparent",
    paddingLeft: "4px",
    color: "#B1B6C4",
  },
  ".cm-gutters .cm-gutterElement.cm-activeLineGutter": {
    background: "transparent",
    color: "black",
  },
  ".cm-activeLine": {
    background: "none",
  },
  "&.cm-editor.cm-focused": {
    outline: "none",
  },
  ".cm-tooltip": {
    fontFamily: 'Menlo, Monaco, "Courier New", monospace',
    fontSize: "12px",
    background: "white",
    maxWidth: "500px",
    color: "#77849B",
    border: "none",
    borderRadius: "6px",
    padding: "6px",
    boxShadow:
      "0 6px 16px 0 rgb(0 0 0 / 8%), 0 3px 6px -4px rgb(0 0 0 / 12%), 0 9px 28px 8px rgb(0 0 0 / 5%)",
    "& > ul": {
      padding: "0px 0px 0px 0px",
      "&::-webkit-scrollbar": {
        display: "none",
      },
    },
    "& > ul > li": {
      fontFamily: 'Menlo, Monaco, "Courier New", monospace',
      fontSize: "12px",
      backgroundColor: "transparent",
      display: "flex",
      height: "26px",
      borderRadius: "6px",
      "& > .cm-completionIcon": {
        marginLeft: "2px",
        marginTop: "5px",
        height: "15px",
      },
      "& > .cm-completionLabel": {
        flex: 1,
        alignItems: "center",
        display: "flex",
        whiteSpace: "pre",
        "& > .cm-completionMatchedText": {
          textDecoration: "none",
          fontWeight: "600",
        },
      },
      "& > .cm-completionDetail": {
        color: "#77849B",
        fontStyle: "normal",
        alignItems: "center",
        display: "flex",
        paddingRight: "6px",
      },
    },
    "& > ul > li[aria-selected]": {
      backgroundColor: "#E6F4FF",
      color: "#1677FF",
    },
  },
  ".cm-tooltip.cm-completionInfo": {
    background: "white",
    color: "#77849B",
    border: "none",
    fontFamily: 'Menlo, Monaco, "Courier New", monospace',
    fontSize: "12px",
    boxShadow:
      "0 6px 16px 0 rgb(0 0 0 / 8%), 0 3px 6px -4px rgb(0 0 0 / 12%), 0 9px 28px 8px rgb(0 0 0 / 5%)",
    margin: "0px 6px",
    borderRadius: "6px",
  },
});
