import type { IEmailRendererConfig } from "../domain";
import type { IEmailWidgetPropertyListConfig } from "./domain";
import { EmailWidgetPropertyListEditor } from "./editor/EmailWidgetPropertyListEditor";
import { EmailWidgetPropertyListMjml } from "./mjml/EmailWidgetPropertyListMjml";
import { EmailWidgetPropertyListRenderer } from "./web-widget/EmailWidgetPropertyListRenderer";

export const emailWidgetPropertyListDefinition: IEmailRendererConfig<IEmailWidgetPropertyListConfig> =
  {
    parseConfig: (widget) => {
      const defaultValue: IEmailWidgetPropertyListConfig = {
        align: "center",
        columns: [],
      };

      if (!widget.config) {
        return defaultValue;
      }

      try {
        const config: IEmailWidgetPropertyListConfig = JSON.parse(
          widget.config
        );
        return config;
      } catch (err) {
        console.error(err);
        return defaultValue;
      }
    },
    renderEditor: (widget, object, record, conf, org, user, form, datasets) => {
      return (
        <EmailWidgetPropertyListEditor
          widget={widget}
          object={object}
          record={record}
          conf={conf}
          form={form}
          org={org}
          user={user}
          datasets={datasets}
        />
      );
    },
    renderComponent: (widget, object, record, conf, org, user, edit) => {
      return (
        <EmailWidgetPropertyListRenderer
          widget={widget}
          object={object}
          record={record}
          conf={conf}
          org={org}
          user={user}
          edit={edit}
        />
      );
    },
    renderMjml: EmailWidgetPropertyListMjml,
  };
