import type { chartOptionRenderer } from "../../../containers/chart-options/ChartOptions";
import type { IAnalysisType } from "../../../containers/exploration/single/domain";
import { WlyTableChartIcon } from "../../icons/custom-icons/WlyTableChartIcon";
import type { AvailableMetric } from "../../measures/filter-item/FilterItem";
import type { SingleChartDefinition } from "../domain";

export const tableChartDefinition: SingleChartDefinition = {
  label: "Table chart",
  allowTimeComparison: true,
  minMetrics: 1,
  minDimensions: 0,
  icon: <WlyTableChartIcon />,
  canPredict: (analysisType, query) =>
    analysisType === "TIME" &&
    (!query.pivotDimensions || query.pivotDimensions.length === 0) &&
    query.selectedDimensions.length === 0 &&
    !query.comparison,
  pivot: (analysisType: IAnalysisType) => analysisType === "CATEGORIES",
  canDisplay: (rs) => undefined,
  config: (analysisType) => {
    if (analysisType === "CATEGORIES") {
      return ["table-theme", "show-row-number", "show-total"];
    } else {
      return ["table-theme", "show-row-number"];
    }
  },
  declarativeChartOptionRenderer: (options) => {
    const isComparedQuery = !!options.query.comparison;
    const template: chartOptionRenderer = [
      {
        label: "Chart options",
        lines: [
          {
            type: "standard",
            component: "table-theme",
          },
          {
            type: "standard",
            component: "show-row-number",
          },
          {
            type: "standard",
            component: "show-total",
          },
          {
            type: "standard",
            component: "table-comparison-value",
            hidden: !isComparedQuery,
          },
          {
            type: "standard",
            component: "table-comparison-delta-percent",
            hidden: !isComparedQuery,
          },
          {
            type: "standard",
            component: "table-comparison-delta-value",
            hidden: !isComparedQuery,
          },
        ],
      },
    ];
    return template;
  },
  axis: {
    bottom: {
      editable: false,
      isTimeFormattable: (a) => false,
    },
    left: {
      editable: false,
    },
    right: {
      editable: false,
    },
  },
  extractCustomizationDimensions: (data, availableDimensions, query) => {
    return {
      dimensions: availableDimensions
        .filter((ad) => {
          return (
            query.selectedDimensions.find((sd) => sd === ad.key) ||
            (query.analysisType === "TIME" && query.selectedTime === ad.key)
          );
        })
        .map((ad) => {
          return {
            key: ad.key,
            label: ad.label,
            labelEditable: true,
            momentFormatEditable:
              (query.analysisType === "TIME" &&
                query.selectedTime === ad.key) ||
              ad.domain === "TIME",
          };
        }),
    };
  },
  extractCustomizationSeries: (s, d, q) => {
    const availableMet: AvailableMetric[] = s.map((a) => a.metric);
    return {
      series: (q.selectedMeasures || []).map((m) => {
        return {
          key: m,
          label: availableMet.find((am) => am.key === m)?.label,
        };
      }),
      customColor: "palette::continue",
      customLabel: true,
      customType: [],
      showVisualization: true,
      conditionalFormatting: {
        fontColor: true,
        backgroundColor: true,
        overrideValue: true,
      },
    };
  },
};
