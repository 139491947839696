import { CheckCircleFilled } from "@ant-design/icons";
import { Col, Row } from "antd";

import "./WlySelector.scss";

interface IWlySelectorProps {
  left: SelectorElement;
  right: SelectorElement;
  onSelect: (id: string) => void;
  selected: string;
}

interface SelectorElement {
  id: string;
  image?: string;
  title: string;
  description: string | JSX.Element;
  disabled?: boolean;
}

export function WlySelector(props: IWlySelectorProps) {
  const { left, right, onSelect, selected } = props;

  const renderClassName = (selected: boolean, disabled: boolean) => {
    const baseClassName = "choice-selection";
    if (disabled) {
      return `${baseClassName} disabled`;
    }
    if (selected) {
      return `${baseClassName} selected`;
    }
    return baseClassName;
  };

  return (
    <Row className="whaly-selector" gutter={[16, 16]}>
      <Col span={12}>
        <div
          className={renderClassName(selected === left.id, left.disabled)}
          onClick={
            !left.disabled
              ? () => {
                  onSelect(left.id);
                }
              : undefined
          }
        >
          <div className="choice-selection-inner">
            {left.image ? (
              <div className="choice-selection-image">
                <img src={left.image} />
              </div>
            ) : (
              <div className="choice-selection-noimage" />
            )}
            <div className="choice-selection-title">{left.title}</div>
            <div className="choice-selection-description">
              {left.description}
            </div>
            <div className="choice-selection-icon">
              <CheckCircleFilled />
            </div>
          </div>
        </div>
      </Col>
      <Col span={12}>
        <div
          className={renderClassName(selected === right.id, right.disabled)}
          onClick={
            !right.disabled
              ? () => {
                  onSelect(right.id);
                }
              : undefined
          }
        >
          <div className="choice-selection-inner">
            {right.image ? (
              <div className="choice-selection-image">
                <img src={right.image} />
              </div>
            ) : (
              <div className="choice-selection-noimage" />
            )}
            <div className="choice-selection-title">{right.title}</div>
            <div className="choice-selection-description">
              {right.description}
            </div>
            <div className="choice-selection-icon">
              <CheckCircleFilled />
            </div>
          </div>
        </div>
      </Col>
    </Row>
  );
}
