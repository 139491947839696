import { Alert, Modal, Table, Typography } from "antd";
import type { InjectedAntUtilsProps } from "../../../../components/ant-utils/withAntUtils";
import { withAntUtils } from "../../../../components/ant-utils/withAntUtils";
import { compose } from "../../../../components/compose/WlyCompose";
import CacheStrageyRenderer from "../../../../components/workbench/cache/CacheStrategyRenderer";
import type { ITable } from "../../../../interfaces/table";
import type { InjectedOrgProps } from "../../../orgs/WithOrg";
import WithOrg from "../../../orgs/WithOrg";
import type { CacheStrategy } from "../domain";

interface ICacheStrategyModalCardProps {
  tables: ITable[];
  visible: boolean;
  onClose: () => void;
}

type Props = ICacheStrategyModalCardProps &
  InjectedOrgProps &
  InjectedAntUtilsProps;

function CacheStrategyModalCard(props: Props) {
  const { tables, visible, onClose } = props;

  // Cache strategy section - START //
  const isUsingDefaultCacheStrategy = tables.reduce<boolean>((acc, curr) => {
    const cacheStrategy: CacheStrategy = JSON.parse(
      curr.view.dataset.cacheStrategy
    );
    // Default value of the cache strategy at the date of writing
    if (cacheStrategy.type !== "every" || cacheStrategy.value !== "2 minute") {
      return false;
    }
    return acc;
  }, true);

  const renderInner = () => {
    const cacheStrategyTableBreakdownData = tables.map((table) => {
      const rawCacheStrategy = table?.view?.dataset?.cacheStrategy;
      const cacheStrategy = JSON.parse(rawCacheStrategy);
      return {
        key: table.id,
        table_name: table.name,
        cache_strategy: cacheStrategy,
      };
    });

    const cacheStrategyTableBreakdownColumns = [
      {
        title: "Table",
        dataIndex: "table_name",
        key: "table_name",
      },
      {
        title: "Cache strategy",
        dataIndex: "cache_strategy",
        key: "cache_strategy",
        render: (cacheStrategy, _) => (
          <CacheStrageyRenderer cacheStrategy={cacheStrategy} />
        ),
      },
    ];

    return (
      <>
        {isUsingDefaultCacheStrategy ? (
          <Alert
            style={{ marginBottom: 8 }}
            type="info"
            message={
              <>
                <Typography.Text>
                  You are using the default cache strategy for all tables. The
                  default cache strategy is optimized for datasets that are{" "}
                  <b>updated very frequently</b> (every 2 minutes).
                </Typography.Text>
                <br />
                <br />
                <Typography.Text>
                  If your datasets are updated less often (hourly, daily,
                  weekly), configuring the proper cache strategy will optimize
                  the loading speed of your reports and decrease your Warehouse
                  costs. Configure your tables Cache Strategy to match your
                  datasets update frequency for optimum performance.{" "}
                </Typography.Text>
                <br />
                <a
                  rel="noreferrer"
                  target="_blank"
                  href={"https://docs.whaly.io/platform-concepts/caching"}
                >
                  More info
                </a>
              </>
            }
          />
        ) : undefined}
        <Table
          pagination={false}
          size="small"
          dataSource={cacheStrategyTableBreakdownData}
          columns={cacheStrategyTableBreakdownColumns}
        />
      </>
    );
  };

  return (
    <Modal
      open={visible}
      title={"Cache strategy details"}
      maskClosable={true}
      footer={null}
      okText={"Close"}
      onCancel={onClose}
      width={"50%"}
      closable={true}
    >
      {renderInner()}
    </Modal>
  );
}

export default compose<Props, ICacheStrategyModalCardProps>(
  WithOrg,
  withAntUtils
)(CacheStrategyModalCard);
