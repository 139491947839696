import { Alert, Button, Input, Space } from "antd";
import type { RouteComponentProps } from "react-router";
import { withRouter } from "react-router";
import type { InjectedOrgProps } from "../../../containers/orgs/WithOrg";
import WithOrg from "../../../containers/orgs/WithOrg";
import type { IGroup } from "../../../interfaces/group";
import type { IAccessType } from "../../../interfaces/reportSharing";
import { routeDescriptor } from "../../../routes/routes";
import { compose } from "../../compose/WlyCompose";
import type { ISharing, InheritedSharing } from "../domain";
import FolderSharingRenderer from "../renderer/SharingRenderer";
import "./EditSharingForm.scss";

interface IEditSharingFormModalProps {
  // When clicking on a link, we close the sharing form
  onClose: () => void;
  // When clicking on "Cancel", we go back to the edit mode instead of closing the whole sharing form
  onEditModeSwitch: () => void;
  onAccessTypeChange: (sharingId: string, newType: IAccessType) => void;
  onRemoveAccess: (sharingId: string) => void;
  // Sharings that are directly coming from this folder
  directSharings: ISharing[];
  // Sharings that are inherited from parents in the hierarchy
  indirectSharings?: InheritedSharing[];
  // For orgs that doesn't have access to this feature in their plan
  orgHasFeature: boolean;
  // For user that can't manage the current folder but can see the sharing settings
  canBeManagedByCurrentUser: boolean;
  availableAccessTypes: Array<IAccessType>;
  disableEdit: (group?: IGroup) => boolean;
}

type Props = InjectedOrgProps &
  IEditSharingFormModalProps &
  RouteComponentProps<{}>;

function EditSharingForm(props: Props) {
  const {
    directSharings,
    indirectSharings,
    onEditModeSwitch,
    onClose,
    onAccessTypeChange,
    onRemoveAccess,
    location,
    orgHasFeature,
    canBeManagedByCurrentUser,
    availableAccessTypes,
    disableEdit,
  } = props;

  const crossSellUrl = routeDescriptor.featureCrossSellPage.renderRoute(
    {
      featureSlug: "advanced-access-control",
    },
    {
      backPath: location.pathname,
    }
  );

  const renderInner = () => {
    return (
      <>
        {directSharings.map((sharing) => {
          const isDisabled = disableEdit(sharing.group);
          return (
            <FolderSharingRenderer
              key={sharing.id}
              disabled={isDisabled}
              sharing={sharing}
              onClose={() => onClose()}
              onAccessTypeChange={(newType) => {
                onAccessTypeChange(sharing.id, newType);
              }}
              onRemoveAccess={() => {
                onRemoveAccess(sharing.id);
              }}
              availableAccessTypes={availableAccessTypes}
            />
          );
        })}
        {(indirectSharings || []).flatMap((indirectSharing) => {
          return indirectSharing.sharings.map((sharing) => {
            const isDisabled = disableEdit(sharing.group);
            return (
              <FolderSharingRenderer
                key={sharing.id}
                disabled={isDisabled}
                onClose={() => onClose()}
                sharing={sharing}
                inheritingFrom={indirectSharing.inheritingFrom}
                onAccessTypeChange={(newType) => {
                  onAccessTypeChange(sharing.id, newType);
                }}
                onRemoveAccess={() => {
                  onRemoveAccess(sharing.id);
                }}
                availableAccessTypes={availableAccessTypes}
              />
            );
          });
        })}
      </>
    );
  };

  return (
    <Space direction="vertical" size="middle" style={{ width: "100%" }}>
      {!canBeManagedByCurrentUser ? (
        <Alert
          message={
            <div style={{ display: "flex" }}>
              <div>
                You don't have the permission to edit this report's sharings.
              </div>
            </div>
          }
          type="info"
        />
      ) : undefined}
      {!orgHasFeature ? (
        <Alert
          message={
            <div style={{ display: "flex" }}>
              <div>Your plan doesn't have access to this feature yet.</div>
              <a href={crossSellUrl} style={{ marginLeft: "auto" }}>
                Learn more
              </a>
            </div>
          }
          type="warning"
        />
      ) : undefined}

      <div className="share-edit-picker">
        <Input
          size="large"
          disabled={disableEdit()}
          placeholder={"Add emails or people"}
          onClick={() => {
            onEditModeSwitch();
          }}
        ></Input>
        <Button
          disabled={disableEdit()}
          className="share-edit-picker-invite-btn"
          size="large"
          onClick={() => {
            onEditModeSwitch();
          }}
        >
          Invite
        </Button>
      </div>
      {renderInner()}
    </Space>
  );
}

export default compose<Props, IEditSharingFormModalProps>(
  WithOrg,
  withRouter
)(EditSharingForm);
