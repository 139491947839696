import GraphQLService from "./GraphQLService";

const PAGE_SIZE = 50;

export const GQL_PAGINATION_VARIABLES_CLAUSE_PARAMS = `
$offset: Int!,
$pageSize: Int!
`;

export const GQL_PAGINATION_QUERY_CLAUSE_PARAMS = `
orderBy: "id_ASC"
first: $pageSize
skip: $offset
`;

export async function* makeRecordIterator<T>(
  query: string,
  vars: any,
  operationName: string
): AsyncIterable<T> {
  let isFinished = false;
  let offset = 0;
  while (!isFinished) {
    const nextPage = await getRecordPage<T>(query, vars, operationName, offset);
    if (nextPage.length === 0) {
      isFinished = true;
    }

    for (const record of nextPage) {
      yield record;
    }
    offset = offset + PAGE_SIZE;
  }
  return;
}

const getRecordPage = async <T>(
  query: string,
  vars: any,
  operationName: string,
  offset: number
): Promise<T[]> => {
  const variables = {
    ...vars,
    pageSize: PAGE_SIZE,
    offset,
  };

  // console.log(
  //   `Executing %s query - Page: %s`,
  //   operationName,
  //   Math.floor(offset / PAGE_SIZE) + 1
  // );

  const resJson = await GraphQLService<{ [operationName: string]: T[] }>(
    query,
    variables
  );
  // console.log(
  //   `Executing %s query - Page: %s - Received: %j`,
  //   operationName,
  //   Math.floor(offset / PAGE_SIZE) + 1,
  //   resJson
  // );
  const parsed: T[] = resJson[operationName];

  return parsed;
};
