import { Card, Col, Row } from "antd";
import { inject, observer } from "mobx-react";
import * as React from "react";
import type { RouteComponentProps } from "react-router-dom";
import { withRouter } from "react-router-dom";
import { compose } from "../../components/compose/WlyCompose";
import type { BreadcrumbStoreProps } from "../../store/breadCrumbStore";
import UserInfoForm from "./UserInfoForm";
import UserOrgAccessTable from "./UserOrgAccessTable";

interface IUserSettingsPageProps {}

type Props = IUserSettingsPageProps &
  RouteComponentProps<{}> &
  BreadcrumbStoreProps;

class UserSettingsPage extends React.Component<Props> {
  public componentDidMount() {
    this.props.breadcrumbStore.setBreadcrumb([{ label: "User Settings" }]);
  }

  public render() {
    return (
      <Row justify="center" className="access-management" gutter={[24, 24]}>
        <Col xs={24}>
          <Card bodyStyle={{ padding: 0 }}>
            <UserInfoForm />
          </Card>
        </Col>
        <Col xs={24}>
          <UserOrgAccessTable />
        </Col>
        <Col xs={24} />
      </Row>
    );
  }
}

export default compose<Props, IUserSettingsPageProps>(withRouter)(
  inject("breadcrumbStore")(observer(UserSettingsPage))
);
