import { Button, Form, Input, List, Modal, Typography } from "antd";
import * as React from "react";
import { compose } from "../../../components/compose/WlyCompose";
import FormActions from "../../../components/form/actions/FormActions";
import FormHeader from "../../../components/form/header/FormHeader";
import Loading from "../../../components/layout/feedback/loading";
import UserAvatar from "../../../components/user/avatar/UserAvatar";
import UserPicker from "../../../components/user/picker/UserPicker";
import { handleGQLErrors } from "../../../helpers/gqlHelpers";
import type { IGroup } from "../../../interfaces/group";
import type { IUserGravatarInfo } from "../../../interfaces/user";
import { track } from "../../../services/AnalyticsService";
import GraphQLService from "../../../services/graphql/GraphQLService";
import type { InjectedOrgProps } from "../../orgs/WithOrg";
import WithOrg from "../../orgs/WithOrg";
import "./UserGroupUserRenderer.scss";

interface IGroupManagementFormProps {
  onDrawerClose: () => void;
  onSubmit: () => Promise<void>;
  orgId: string;
  isEditMode: boolean;
  selectedGroup?: IGroup;
}

type Props = InjectedOrgProps & IGroupManagementFormProps;

interface GroupMember {
  userGroupId: string;
  userId: string;
}

interface IState {
  submitting: boolean;
  loading: boolean;
  // State from the Backend
  existingGroupMembers: GroupMember[];
  usersNotInTheGroupYet: IUserGravatarInfo[];
  usersInTheGroup: IUserGravatarInfo[];
  // State from the UI
  addMemberModalIsOpened: boolean;
  initialValues: InitialValues;
  usersToAdd: IUserGravatarInfo[];
  usersToRemove: IUserGravatarInfo[];
}

interface InitialValues {
  groupName: string;
}

const CREATE_NEW_GROUP_GQL = `
mutation createGroupWithACL($groupName: String!, $orgId: ID!) {
  createGroupWithACL(groupName: $groupName, orgId: $orgId) {
    id
  }
}
`;

const UPDATE_GROUP_GQL = `
mutation updateGroup($groupId: ID!, $groupName: String!) {
  updateGroup(id: $groupId, data: { name: $groupName }) {
    id
  }
}
`;

interface UserGroupCreateInput {
  data: {
    org: { connect: { id: string } };
    user: { connect: { id: string } };
    group: { connect: { id: string } };
  };
}

const ADD_USER_GROUP_GQL = `
mutation assignUserGroup($userGroupCreateInputs: [UserGroupsCreateInput]!) {
  createUserGroupsWithACL(
    data: $userGroupCreateInputs
  )
}
`;

interface UserGroupDeleteInput {
  id: string;
  data: {
    isDeleted: true;
  };
}

const DELETE_USER_GROUP_GQL = `
mutation deleteUserGroup($userGroupDeleteInputs: [UserGroupsUpdateInput]!) {
  updateUserGroups(
    data: $userGroupDeleteInputs
  ) {
    id
  }
}
`;

// Used both for edit of existing groups and creation of new ones
class GroupManagementForm extends React.Component<Props, IState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      initialValues: {
        groupName: "My Group",
      },
      loading: true,
      submitting: false,
      existingGroupMembers: [],
      usersInTheGroup: [],
      addMemberModalIsOpened: false,
      usersNotInTheGroupYet: [],
      usersToAdd: [],
      usersToRemove: [],
    };
    this.fetchGroupInfo();
  }

  async fetchGroupInfo() {
    const { user, orgId, isEditMode, selectedGroup } = this.props;
    const groupId = selectedGroup?.id;

    if (isEditMode && groupId) {
      const getExistingGroupInfo = `
    query GetGroupInfo($groupId: ID!, $orgId: ID!) {
      Group(where: { id: $groupId }) {
        name
        users(where: {
          isDeleted_not: true,
          user: {
            isDeleted_not: true,
            isPublicGuestUser: false,
            isAdmin_not: true
          }
        }) {
          id
          user {
            id
            firstName
            lastName
            email
            gravatar
            avatarColor
          }
        }
      }
      Org(where: { id: $orgId }) {
        roles(where: {
          isDeleted_not: true,
          user: {
            isDeleted_not: true,
            isPublicGuestUser: false,
            isAdmin_not: true
          }
        }) {
          user {
            id
            firstName
            lastName
            email
            gravatar
            avatarColor
          }
        }
      }
    }
    `;

      const groupInfo = await GraphQLService<{
        Group: {
          name: string;
          users: {
            id: string;
            user: {
              id: string;
              firstName: string;
              lastName: string;
              email: string;
              gravatar?: string;
              avatarColor: string;
            };
          }[];
        };
        Org: {
          roles: {
            user: {
              id: string;
              firstName: string;
              lastName: string;
              email: string;
              gravatar?: string;
              avatarColor: string;
            };
          }[];
        };
      }>(getExistingGroupInfo, { orgId, groupId });

      const usersInTheGroup = groupInfo.Group.users.map(
        (userRole) => userRole.user
      );
      const allUsersOfTheOrg = groupInfo.Org.roles.map(
        (userRole) => userRole.user
      );
      const usersNotInTheGroupYet = allUsersOfTheOrg.filter((user) => {
        const userIdsInTheGroup = usersInTheGroup.map((user) => user.id);
        return !userIdsInTheGroup.includes(user.id);
      });
      const existingGroupMembers = groupInfo.Group.users.map((user) => {
        return {
          userGroupId: user.id,
          userId: user.user.id,
        };
      });

      this.setState({
        loading: false,
        usersNotInTheGroupYet,
        usersInTheGroup,
        existingGroupMembers,
        initialValues: {
          groupName: groupInfo.Group.name,
        },
      });
    } else {
      const getExistingGroupInfo = `
    query GetAllOrgUsers($orgId: ID!) {
      Org(where: { id: $orgId }) {
        roles(where: {
          isDeleted_not: true,
          user: {
            isDeleted_not: true,
            isPublicGuestUser: false,
            isAdmin_not: true
          }
        }) {
          user {
            id
            firstName
            lastName
            email
            gravatar
            avatarColor
          }
        }
      }
    }
    `;

      const usersInfo = await GraphQLService<{
        Org: {
          roles: {
            user: {
              id: string;
              firstName: string;
              lastName: string;
              email: string;
              gravatar?: string;
              avatarColor: string;
            };
          }[];
        };
      }>(getExistingGroupInfo, { orgId, groupId, hideAdmin: !user.isAdmin });

      const allUsersOfTheOrg = usersInfo.Org.roles.map(
        (userRole) => userRole.user
      );

      this.setState({
        loading: false,
        usersNotInTheGroupYet: allUsersOfTheOrg,
        usersInTheGroup: [],
      });
    }
  }

  componentDidMount() {
    track("User Group Edit Opened");
  }

  async updateOrCreateGroup(v: InitialValues): Promise<string> {
    const { orgId, isEditMode, selectedGroup } = this.props;

    if (!isEditMode) {
      const newGroupRes = await GraphQLService<{
        id: string;
      }>(
        CREATE_NEW_GROUP_GQL,
        {
          orgId,
          groupName: v.groupName,
        },
        "createGroupWithACL"
      );
      track("Group Created");
      return newGroupRes.id;
    } else {
      const updatedGroupRes = await GraphQLService<{
        id: string;
      }>(
        UPDATE_GROUP_GQL,
        {
          groupName: v.groupName,
          groupId: selectedGroup.id,
        },
        "updateGroup"
      );
      track("Group Updated");
      return updatedGroupRes.id;
    }
  }

  async addMembersMutation(
    groupId: string,
    usersToAdd: IUserGravatarInfo[]
  ): Promise<void> {
    const { orgId } = this.props;

    const userGroupCreateInputs: UserGroupCreateInput[] = usersToAdd.map(
      (memberToAdd) => {
        return {
          data: {
            org: {
              connect: {
                id: orgId,
              },
            },
            user: {
              connect: {
                id: memberToAdd.id,
              },
            },
            group: {
              connect: {
                id: groupId,
              },
            },
          },
        };
      }
    );
    await GraphQLService(
      ADD_USER_GROUP_GQL,
      {
        userGroupCreateInputs,
      },
      "createUserGroups"
    );
  }

  async removeMembersMutation(
    usersToRemove: IUserGravatarInfo[]
  ): Promise<void> {
    const { existingGroupMembers } = this.state;
    const userGroupDeleteInputs: UserGroupDeleteInput[] = usersToRemove.map(
      (memberToRemove) => {
        const userGroupToRemove = existingGroupMembers.find((member) => {
          return member.userId === memberToRemove.id;
        });

        const userGroupId = userGroupToRemove.userGroupId;
        return {
          id: userGroupId,
          data: {
            isDeleted: true,
          },
        };
      }
    );
    await GraphQLService(
      DELETE_USER_GROUP_GQL,
      {
        userGroupDeleteInputs,
      },
      "updateUserGroups"
    );
  }

  public submitForm = async (v: InitialValues) => {
    const { onSubmit, onDrawerClose } = this.props;
    const { usersToAdd: membersToAdd, usersToRemove: membersToDelete } =
      this.state;

    this.setState({ submitting: true });

    try {
      const groupId = await this.updateOrCreateGroup(v);

      if (membersToAdd.length > 0) {
        await this.addMembersMutation(groupId, membersToAdd);
      }

      if (membersToDelete.length > 0) {
        await this.removeMembersMutation(membersToDelete);
      }
      await onSubmit();
      onDrawerClose();
    } catch (err) {
      handleGQLErrors(() => {
        this.setState({ submitting: false });
      })(err);
    }
  };

  removeMember(user: IUserGravatarInfo) {
    const {
      usersInTheGroup,
      usersToRemove: membersToDelete,
      usersToAdd: membersToAdd,
    } = this.state;

    // If we remove a member that was previously added, we have to remove it
    const newMemberToAdd = membersToAdd.filter((userToAdd) => {
      return user.id !== userToAdd.id;
    });

    // If the user was already in the group before the form edit, we plan to delete it
    const newMemberToDelete = usersInTheGroup
      .map((user) => user.id)
      .includes(user.id)
      ? membersToDelete.concat([user])
      : membersToDelete;

    this.setState({
      usersToRemove: newMemberToDelete,
      usersToAdd: newMemberToAdd,
    });
  }

  addMember(user: IUserGravatarInfo) {
    const { usersToAdd: membersToAdd } = this.state;

    this.setState({
      usersToAdd: membersToAdd.concat([user]),
    });
  }

  public render() {
    const { onDrawerClose, selectedGroup, isEditMode } = this.props;
    const {
      loading,
      usersNotInTheGroupYet,
      usersInTheGroup,
      initialValues,
      addMemberModalIsOpened,
      usersToAdd: membersToAdd,
      usersToRemove: membersToDelete,
    } = this.state;

    if (loading) {
      return <Loading />;
    }

    // For the system managed groups, the form is read only
    const isReadOnly = selectedGroup && selectedGroup.isSystemGroup;

    const usersInTheGroupNotDeleted = usersInTheGroup.filter((user) => {
      return !membersToDelete.map((user) => user.id).includes(user.id);
    });

    const usersToShow = usersInTheGroupNotDeleted.concat(membersToAdd);

    const usersThatCanStillBeAdded = usersNotInTheGroupYet.filter((user) => {
      return !membersToAdd.map((user) => user.id).includes(user.id);
    });

    return (
      <>
        <Form
          initialValues={initialValues}
          onFinish={this.submitForm}
          className="form-container"
          layout="vertical"
        >
          <FormHeader
            title={isEditMode ? "Edit Group" : "Create Group"}
            onClose={onDrawerClose}
          />
          <div style={{ padding: "0 24px", marginTop: "16px" }}>
            <Form.Item name={["groupName"]} required={true} label="Name">
              <Input disabled={isReadOnly} />
            </Form.Item>

            <div className="group-user-renderer-header">
              <h3>Members ({usersToShow.length})</h3>
              {!isReadOnly ? (
                <div className="group-user-renderer-header-add-btn">
                  <Button
                    type="link"
                    onClick={() =>
                      this.setState({ addMemberModalIsOpened: true })
                    }
                  >
                    Add
                  </Button>
                </div>
              ) : undefined}
            </div>

            <List
              itemLayout="horizontal"
              dataSource={usersToShow}
              renderItem={(user) => (
                <List.Item>
                  <div className="group-user-renderer-info">
                    {!user.firstName && !user.lastName ? (
                      <>
                        <Typography.Text type="secondary">
                          {user.email}
                        </Typography.Text>
                      </>
                    ) : (
                      <>
                        <div className="group-user-renderer-image">
                          <UserAvatar user={user} />
                        </div>
                        <div className="group-user-renderer-meta">
                          {user.firstName} {user.lastName}
                        </div>
                      </>
                    )}
                    {!isReadOnly ? (
                      <Button
                        className="group-user-renderer-remove-btn"
                        type="link"
                        onClick={() => this.removeMember(user)}
                      >
                        Remove
                      </Button>
                    ) : undefined}
                  </div>
                </List.Item>
              )}
            />
          </div>

          <FormActions
            hideSubmit={isReadOnly}
            isSubmitting={this.state.submitting}
            onCancel={onDrawerClose}
          />
        </Form>
        <Modal
          open={addMemberModalIsOpened}
          title={"Add a User"}
          onCancel={() => this.setState({ addMemberModalIsOpened: false })}
          maskClosable={false}
          footer={null}
        >
          <UserPicker
            ctaText="Add"
            onClose={() => this.setState({ addMemberModalIsOpened: false })}
            availableUsers={usersThatCanStillBeAdded}
            onUserSelection={(userId) => {
              const { usersToAdd: membersToAdd } = this.state;
              const userToAdd = this.state.usersNotInTheGroupYet.find(
                (user) => user.id === userId
              );
              this.setState({ usersToAdd: membersToAdd.concat([userToAdd]) });
              return Promise.resolve();
            }}
          />
        </Modal>
      </>
    );
  }
}

export default compose<Props, IGroupManagementFormProps>(WithOrg)(
  GroupManagementForm
);
