import { EditorView } from "@codemirror/view";
import ReactCodeMirror from "@uiw/react-codemirror";
import { useMemo } from "react";
import { CalculatedMetricFormulaEditorAutocomplete } from "./CalculatedMetricAutocomplete";
import { CalculatedMetricFormulaEditorColumnsHighlight } from "./CalculatedMetricColumnHighlight";
import { CalculatedMetricEditorTheme } from "./CalculatedMetricTheme";

interface CalculatedMetricFormulaEditorProps {
  columnsSuggestions: {
    key: string;
    label: string;
  }[];
  value?: string;
  onChange?: (value: string) => void;
}

export const CalculatedMetricFormulaEditor = (
  props: CalculatedMetricFormulaEditorProps
) => {
  const { columnsSuggestions, onChange } = props;
  let value = "";
  if (typeof props.value === "string") {
    value = props.value;
  }

  // This is a hack to make sure that the autocomplete does not resets
  // when the component rerenders
  const columnsSuggestionsStringified = JSON.stringify(columnsSuggestions);
  const extensions = useMemo(
    () => [
      CalculatedMetricFormulaEditorAutocomplete(columnsSuggestions),
      CalculatedMetricFormulaEditorColumnsHighlight(columnsSuggestions),
      CalculatedMetricEditorTheme,
      EditorView.lineWrapping,
    ],
    [columnsSuggestionsStringified]
  );

  return (
    <ReactCodeMirror
      basicSetup={{
        foldGutter: false,
        lineNumbers: false,
        highlightActiveLine: false,
      }}
      extensions={extensions}
      height={"100px"}
      style={{
        border: "1px solid #D9D9D9",
        borderRadius: 6,
      }}
      value={value}
      onChange={(v) => {
        if (onChange) {
          onChange(v);
        }
      }}
    />
  );
};
