import { Form } from "antd";
import * as React from "react";
import { compose } from "../../../../../components/compose/WlyCompose";
import WlyModal from "../../../../../components/modal/WlyModal";
import type { IDestination } from "../../../../../interfaces/destinations";
import type { InjectedOrgProps } from "../../../../orgs/WithOrg";
import WithOrg from "../../../../orgs/WithOrg";
import type { TableTabItem } from "../../../../spreadsheet/domain";
import type { DatasetSavedData } from "../../selector/tables/models/AddModelModal";
import "./DatasetEdition.scss";
import type { ImportDatasetFormAction } from "./ImportDatasetForm";
import ImportDatasetForm from "./ImportDatasetForm";

// used for
// 1. source creation + dataset import
// 2. source edition (name + emoji)
// 3. dataset imports in source

export interface DatasetInitialData {
  emoji: string;
  name: string;
  sourceId?: string;
}

export type DatasetEditionSavedData = (data: {
  source: {
    name: string;
    emoji: string;
  };
  importTables: DatasetSavedData[];
}) => Promise<void>;

interface IDatasetEditionProps {
  action: ImportDatasetFormAction;
  disabledFields?: Array<"name">;
  initialData?: DatasetInitialData;
  tables: Array<TableTabItem>;
  visible: boolean;
  onCancel: () => void;
  onSave: DatasetEditionSavedData;
  currentWarehouse: IDestination;
}

type Props = IDatasetEditionProps & InjectedOrgProps;

const DatasetEdition: React.FunctionComponent<Props> = (props) => {
  const {
    action,
    disabledFields,
    initialData,
    tables,
    visible,
    onCancel,
    onSave,
    currentWarehouse,
  } = props;
  const [confirmLoading, setConfirmLoading] = React.useState(false);
  const [step, setStep] = React.useState<"STEP_1" | "STEP_2">("STEP_1");
  const [form] = Form.useForm();
  const importTablesValue = Form.useWatch("importTables", form);
  const sourceNameValue = Form.useWatch("name", form);

  const renderInnerModal = () => {
    return (
      <ImportDatasetForm
        action={action}
        form={form}
        step={step}
        alreadyImportedtables={tables}
        initialData={initialData}
        disabledFields={disabledFields}
        currentWarehouse={currentWarehouse}
      />
    );
  };

  const getOkButtonDisabled = () => {
    if (action === "EDIT_SOURCE") {
      return !sourceNameValue;
    } else if (action === "IMPORT_DATASETS") {
      return !importTablesValue;
    } else if (action === "CREATE_SOURCE_AND_IMPORT_DATASETS") {
      return !sourceNameValue || !importTablesValue;
    }
  };

  const onSubmit = async () => {
    setConfirmLoading(true);
    try {
      const values = await form.validateFields();
      const formattedTableValues: DatasetSavedData[] =
        values.tables?.map?.((value) => {
          return {
            ...initialData,
            ...value,
            name: value.overrideName,
            table: value.name,
            type: "WAREHOUSE",
          };
        }) ?? [];
      await onSave({
        importTables: formattedTableValues,
        source: { emoji: values.emoji, name: values.name },
      });
      form.resetFields();
    } catch (error) {
      setConfirmLoading(false);
      console.warn("Validate Failed:", error);
    }
  };

  const onOk = async () => {
    if (action === "EDIT_SOURCE") {
      onSubmit();
    } else if (
      action === "CREATE_SOURCE_AND_IMPORT_DATASETS" ||
      action === "IMPORT_DATASETS"
    ) {
      if (step === "STEP_1") {
        setStep("STEP_2");
      } else {
        onSubmit();
      }
    }
  };

  const okText = () => {
    if (action === "EDIT_SOURCE") {
      return "Save";
    } else {
      return step === "STEP_1" ? "Next" : "Save";
    }
  };

  const cancelText = () => {
    return step === "STEP_2" ? "Previous" : "Cancel";
  };

  const onClose = () => {
    if (step === "STEP_2") {
      setStep("STEP_1");
    } else {
      form.resetFields();
      onCancel();
    }
  };

  const getTitle = () => {
    switch (action) {
      case "CREATE_SOURCE_AND_IMPORT_DATASETS":
        return "Create a source";
      case "EDIT_SOURCE":
        return "Edit source";
      case "IMPORT_DATASETS":
        return "Import datasets";
    }
  };

  return (
    <WlyModal
      open={visible}
      title={getTitle()}
      okText={okText()}
      cancelText={cancelText()}
      okButtonDisabled={getOkButtonDisabled()}
      maskClosable={false}
      onClose={onClose}
      confirmLoading={confirmLoading}
      onOk={onOk}
    >
      <div className="dataset-edition">{renderInnerModal()}</div>
    </WlyModal>
  );
};

export default compose<Props, IDatasetEditionProps>(WithOrg)(DatasetEdition);
