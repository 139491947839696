import { LoadingOutlined } from "@ant-design/icons";

interface ICountStatusBarComponentProps {
  count: number;
}

const CountStatusBarComponent = (props: ICountStatusBarComponentProps) => {
  const { count } = props;
  return (
    <div className="ag-status-name-value">
      {props.count === undefined ? (
        <span className="component">
          <LoadingOutlined />
        </span>
      ) : (
        <>
          <span
            className="ag-status-name-value-value"
            style={{ fontWeight: 500 }}
          >
            {count}
          </span>
          <span className="component">&nbsp;records</span>
        </>
      )}
    </div>
  );
};

export default CountStatusBarComponent;
