import type { Query, TimeDimensionGranularity } from "@cubejs-client/core";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import _ from "lodash";
import * as React from "react";
import type { ChartOption } from "../../../containers/chart-options/ChartOptions";
import type { UserLocale } from "../../../interfaces/user";
import type { IComparisonPeriod } from "../../measures/comparison-selector/ComparisonSelector";
import type { Formatter } from "../domain";
import { localizeHighchart } from "../domain";
import type { HighchartLegendItem } from "../utils/HighchartsLegend";
import { HighchartsLegend } from "../utils/HighchartsLegend";
import type { IReferenceLine } from "../utils/optionsHelper";
import {
  customFormatter,
  hasPredominentFormatter,
  optionsHelper,
  renderSerieTooltip,
} from "../utils/optionsHelper";

interface IBarChartProps {
  height: number;
  scale?: "time" | "ordinal";
  data: Array<{ [key: string]: string | number }>;
  config: BarChartConfig;
  tinyLegend?: boolean;
  unit?: "second" | "percent";
  width?: number;
  onDrill?: (q: Query) => void;
  chartOptions?: ChartOption;
  locale: UserLocale;
  referenceLine?: IReferenceLine;
}

interface BarChartConfig {
  x: {
    key: string;
    label?: string;
  };
  y: Array<{
    key: string;
    type?: "bar" | "line" | "area" | "scatter";
    label?: string;
    axis?: "left" | "right";
    prediction?: {
      previousStartValue: string;
      startValue: string;
      getHighKey: () => string;
      getLowKey: () => string;
    };
    color?: string;
    canDrill: (xValue: string, yValue?: string) => Query | null;
    dashed?: boolean;
    formatter: Formatter;
  }>;
  stacking?: boolean;
  percent?: boolean;
  showLabels?: boolean;
  horizontal?: boolean;
  comparisonPeriod?: IComparisonPeriod;
  timeGranularity?: TimeDimensionGranularity;
}

interface IState {
  legendItems: HighchartLegendItem[];
}

export default class BarChart extends React.Component<IBarChartProps, IState> {
  chartRef: HighchartsReact.RefObject;

  constructor(props: IBarChartProps) {
    super(props);
    this.state = {
      legendItems: [],
    };
  }

  componentDidUpdate(prevProps: IBarChartProps) {
    if (
      (prevProps.width !== this.props.width ||
        prevProps.height !== this.props.height ||
        !_.isEqual(this.props.config, prevProps.config)) &&
      this.chartRef
    ) {
      this.chartRef.chart?.reflow?.();
    }
  }

  shouldComponentUpdate(
    nextProps: Readonly<IBarChartProps>,
    nextState: Readonly<IState>
  ): boolean {
    if (!_.isEqual(this.state.legendItems, nextState.legendItems)) return true;
    if (!_.isEqual(this.props.width, nextProps.width)) return true;
    if (!_.isEqual(this.props.height, nextProps.height)) return true;
    if (!_.isEqual(this.props.data, nextProps.data)) return true;
    if (!_.isEqual(this.props.scale, nextProps.scale)) return true;
    if (!_.isEqual(this.props.unit, nextProps.unit)) return true;
    if (!_.isEqual(this.props.tinyLegend, nextProps.tinyLegend)) return true;
    if (!_.isEqual(this.props.referenceLine, nextProps.referenceLine))
      return true;
    if (!_.isEqual(this.props.chartOptions, nextProps.chartOptions))
      return true;
    return false;
  }

  public render() {
    const {
      height,
      config,
      data,
      scale,
      width,
      onDrill,
      chartOptions,
      locale,
      referenceLine,
    } = this.props;

    localizeHighchart(Highcharts, locale);
    const xFormatter: Formatter | undefined = chartOptions?.axis?.bottom
      ?.timeFormat
      ? {
          format: {
            type: "moment",
            value: chartOptions?.axis?.bottom?.timeFormat,
          },
        }
      : null;
    const predominentY1Formatter: Formatter = config.percent
      ? { format: "NUMBER", suffix: "%" }
      : hasPredominentFormatter(
          config.y
            .filter((f) => !f.axis || f.axis === "left")
            .map((f) => f.formatter)
        );
    const predominentY2Formatter: Formatter = config.percent
      ? { format: "NUMBER", suffix: "%" }
      : hasPredominentFormatter(
          config.y.filter((f) => f.axis === "right").map((f) => f.formatter)
        );

    const adjustedHeight =
      chartOptions?.["hide-legend"] !== true &&
      this.state.legendItems.length > 1
        ? height - 40
        : height;

    const defaultOptions = optionsHelper(
      adjustedHeight,
      width,
      locale,
      scale,
      predominentY1Formatter,
      predominentY2Formatter,
      xFormatter,
      chartOptions,
      referenceLine
    );

    const options: Highcharts.Options = {
      series: config.y.flatMap((y) => {
        let type: any = config.horizontal ? "bar" : "column";

        if (y.type === "bar" && !config.horizontal) {
          type = "column";
        } else if (y.type === "line") {
          type = "spline";
        } else if (y.type === "area") {
          type = "areaspline";
        } else if (y.type) {
          type = y.type;
        }

        let fillColor: any = {
          fillColor: undefined,
          color: Highcharts.color(y.color!).setOpacity(1).get("rgba"),
        };
        if (type === "areaspline") {
          fillColor.fillColor = {
            linearGradient: {
              x1: 0,
              y1: 0,
              x2: 0,
              y2: 1,
            },
            stops: [
              [0, Highcharts.color(y.color!).setOpacity(0.6).get("rgba")],
              [1, Highcharts.color(y.color!).setOpacity(0).get("rgba")],
            ],
          };
        } else if (type === "column" && y.prediction) {
          fillColor.color = Highcharts.color(y.color!)
            .setOpacity(0.5)
            .get("rgba");
        }

        const startPrediction = () => {
          if (y.prediction) {
            if (type === "column" || type === "scatter") {
              return scale === "time"
                ? new Date(y.prediction.startValue).getTime()
                : y.prediction.startValue;
            }
            return scale === "time"
              ? new Date(y.prediction.previousStartValue).getTime()
              : y.prediction.previousStartValue;
          }
          return undefined;
        };
        const getXValue = (d: { [key: string]: string | number }) =>
          scale === "time"
            ? new Date(d[config.x.key]).getTime()
            : d[config.x.key];

        const getZoneDefinition = () => {
          if (y.prediction) {
            if (type === "column" || type === "scatter") {
              return [
                {
                  value: startPrediction(),
                  color: Highcharts.color(y.color!).setOpacity(1).get("rgba"),
                },
              ];
            }
            return [
              {
                value: startPrediction(),
                dashStyle: "solid",
              },
            ];
          }
          return [];
        };

        const ret: Array<Highcharts.SeriesOptionsType> = [
          {
            type: type,
            dashStyle:
              y.dashed || (y.prediction && type !== "column")
                ? "Dash"
                : "Solid",
            color: y.color,
            name: y.label,
            id: y.key,
            yAxis: y.axis && y.axis === "right" ? 1 : undefined,
            data: data.map((d) => {
              return [getXValue(d), d[y.key]];
            }),
            cursor: "pointer",
            events: {
              click: onDrill
                ? (e) => {
                    const xValue =
                      scale === "time"
                        ? data.find(
                            (q) =>
                              new Date(q[config.x.key]).getTime() === e.point.x
                          )![config.x.key]
                        : e.point.name.toString();
                    const q = y.canDrill(xValue.toString());
                    if (q) {
                      return onDrill(q);
                    }
                  }
                : undefined,
            },
            ...fillColor,
            dataLabels: [
              {
                enabled: config.showLabels,
                formatter: function () {
                  if (config.percent) {
                    return typeof this.percentage === "number"
                      ? Number(this.percentage?.toFixed(1)) + "%"
                      : null;
                  }
                  return customFormatter((this as any).y, locale, y.formatter);
                },
                style: {
                  fontSize: "0.8em",
                },
              },
              {
                enabled: config.showLabels,
                formatter: function () {
                  if (config.percent) {
                    return typeof this.percentage === "number"
                      ? Number(this.percentage?.toFixed(1)) + "%"
                      : null;
                  }
                  return customFormatter((this as any).y, locale, y.formatter);
                },
              },
            ],
            zIndex: 1,
            zoneAxis: "x",
            zones: getZoneDefinition(),
            tooltip: renderSerieTooltip({
              data: data,
              locale: locale,
              seriesLength: config.y.length,
              formatter: y.formatter,
              displayValueInPercent: config.percent,
              comparison: {
                timeGranularity: config.timeGranularity,
                comparisonPeriod: config.comparisonPeriod,
              },
            }),
          },
        ];

        if (y.prediction) {
          const getLowValue = (d: { [key: string]: string | number }) => {
            const lowValueKey = y.prediction.getLowKey();
            if (d[lowValueKey]) {
              return d[lowValueKey];
            }
            if (startPrediction() === getXValue(d)) {
              return d[y.key];
            }
            return undefined;
          };

          const getHighValue = (d: { [key: string]: string | number }) => {
            const highValueKey = y.prediction.getHighKey();
            if (d[highValueKey]) {
              return d[highValueKey];
            }
            if (startPrediction() === getXValue(d)) {
              return d[y.key];
            }
            return undefined;
          };

          if (type === "spline" || type === "areaspline") {
            ret.push({
              type: "areasplinerange",
              lineWidth: 0,
              color: y.color,
              name: y.label,
              linkedTo: ":previous",
              dashStyle: y.dashed ? "Dash" : "Solid",
              yAxis: y.axis && y.axis === "right" ? 1 : 0,
              fillOpacity: 0.3,
              enableMouseTracking: false,
              zIndex: 0,
              tooltip: {
                enabled: false,
              } as any,
              marker: {
                enabled: false,
              },
              data: data
                .map((d) => {
                  return [getXValue(d), getLowValue(d), getHighValue(d)];
                })
                .filter((d) => d[1] && d[2]),
              dataLabels: [
                {
                  enabled: config.showLabels,
                  formatter: function () {
                    return customFormatter(
                      (this as any).y,
                      locale,
                      y.formatter
                    );
                  },
                  filter: {
                    operator: ">",
                    property: "y",
                    value: 0,
                  },
                },
                {
                  enabled: config.showLabels,
                  formatter: function () {
                    return customFormatter(
                      (this as any).y,
                      locale,
                      y.formatter
                    );
                  },
                  filter: {
                    operator: "<",
                    property: "y",
                    value: 0,
                  },
                },
              ],
            });
          } else {
            ret.push({
              type: "errorbar",
              color: y.color,
              name: y.label,
              linkedTo: ":previous",
              zIndex: 0,
              enableMouseTracking: false,
              data: data
                .map((d) => {
                  return [getXValue(d), getLowValue(d), getHighValue(d)];
                })
                .filter((d) => d[1] && d[2]),
            });
          }
        }
        return ret;
      }),
      ...(defaultOptions ? defaultOptions : {}),
      legend: {
        ...defaultOptions.legend,
        enabled: true,
        floating: true,
        x: -10000,
      },
      ...{
        plotOptions: {
          ...(defaultOptions?.plotOptions ? defaultOptions?.plotOptions : {}),
          column: {
            dataLabels: {
              enabled: config.showLabels,
              style: {
                fontSize: "0.8em",
              },
            },
            borderWidth: 0,
          },
        },
      },
    };
    if (config.stacking !== undefined) {
      options.plotOptions = {
        ...(options.plotOptions ? options.plotOptions : {}),
        column: {
          ...(options.plotOptions && options.plotOptions.column
            ? options.plotOptions.column
            : {}),
          stacking: config.stacking
            ? config.percent
              ? "percent"
              : "normal"
            : undefined,
        },
        areaspline: {
          lineWidth: 2,
          marker: {
            enabled: false,
          },
        },
        line: {
          lineWidth: 2,
          marker: {
            enabled: false,
          },
        },
        area: {
          lineWidth: 2,
          marker: {
            enabled: false,
          },
        },
        bar: {
          ...(options.plotOptions && options.plotOptions.bar
            ? options.plotOptions.bar
            : {}),
          stacking: config.stacking
            ? config.percent
              ? "percent"
              : "normal"
            : undefined,
        },
      };
    }

    return (
      <div style={{ height, width: "100%" }}>
        {this.props.chartOptions?.["hide-legend"] !== true &&
          this.state.legendItems.length > 1 && (
            <HighchartsLegend
              legendItems={this.state.legendItems}
              setVisibilityState={(index, s) => {
                this.chartRef?.chart?.series?.[index]?.setState?.(s);
              }}
              toggleVisibility={(index) => {
                this.setState((prevState) => ({
                  legendItems: prevState.legendItems?.map((legendItem, i) => {
                    if (index === i) {
                      return {
                        ...legendItem,
                        visible: !legendItem.visible,
                      };
                    } else {
                      return legendItem;
                    }
                  }),
                }));
                if (this.state.legendItems?.[index]?.visible) {
                  this.chartRef?.chart?.series?.[index]?.hide?.();
                } else {
                  this.chartRef?.chart?.series?.[index]?.show?.();
                }
              }}
            />
          )}
        <HighchartsReact
          ref={(ref) => {
            if (ref) {
              this.chartRef = ref;
              if (Array.isArray(ref?.chart?.legend?.allItems)) {
                const legendItems: HighchartLegendItem[] =
                  ref?.chart?.legend?.allItems.map((legendItem, index) => ({
                    key: index,
                    name: legendItem.name,
                    color: legendItem.color as string,
                    visible: legendItem.visible,
                  }));
                this.setState({ legendItems: legendItems });
              }
            }
          }}
          highcharts={Highcharts}
          options={options}
          callback={(chart) => {
            this.setState({ legendItems: chart.legend.allItems });
          }}
        />
      </div>
    );
  }
}
