import { Button } from "antd";

interface ISwitchIconsProps {
  icons: {
    true: React.ReactNode;
    false: React.ReactNode;
  };
  shape?: "circle" | "default" | "round";
  size?: "small" | "middle" | "large";
  value?: boolean;
  onChange?: (v: boolean) => void;
}

export function SwitchIcons(props: ISwitchIconsProps) {
  const value = props.value;
  return (
    <Button
      type="text"
      icon={value ? props.icons.true : props.icons.false}
      size={props.size ? props.size : "middle"}
      shape={props.shape ? props.shape : "circle"}
      onClick={() => {
        if (!props.onChange) return null;
        return props.onChange(value ? false : true);
      }}
    />
  );
}
