import type { IObjectQueryBuilderSectionItemConfig_Parsed } from "../../../../../../../interfaces/object";

export const parseQueryBuilderSectionItemConfidProperty = (
  s: string
): IObjectQueryBuilderSectionItemConfig_Parsed => {
  if (!s) {
    return [];
  }
  try {
    return JSON.parse(s);
  } catch (err) {
    console.error(err);
    return [];
  }
};
