import { Button, Dropdown, Flex, Space, Tag, Typography } from "antd";
import moment from "moment";
import { useState } from "react";
import type { InjectedAntUtilsProps } from "../../../../../../../components/ant-utils/withAntUtils";
import { withAntUtils } from "../../../../../../../components/ant-utils/withAntUtils";
import { WlyCard } from "../../../../../../../components/cards/WlyCard";
import { compose } from "../../../../../../../components/compose/WlyCompose";
import { Emoji } from "../../../../../../../components/form/emoji-picker/Emoji";
import { SourceImageRenderer } from "../../../../../../../components/sources/SourceImageRenderer";
import type { IDestination } from "../../../../../../../interfaces/destinations";
import type { IRadar } from "../../../../../../../interfaces/radar";
import type { ISchedule } from "../../../../../../../interfaces/schedule";
import type { IDataset } from "../../../../../../../interfaces/sources";
import type { ObjectData, ObjectStore } from "../../../../domain";
import type { UpdateRadarFunction } from "../../../../selector/radar/domain";
import RadarModelModal from "./modals/RadarModelModal";
import RadarObjectModal from "./modals/RadarObjectModal";
import RadarRecordKeyModal from "./modals/RadarRecordKeyModal";
import RadarScheduleModal from "./modals/RadarScheduleModal";

interface IRadarModelCardProps {
  radar: IRadar;
  datasets: IDataset[];
  objectStore: ObjectStore;
  currentWarehouse: IDestination;
  afterScheduleSave: () => Promise<void>;
  onUpdateRadar: UpdateRadarFunction;
}

type Props = IRadarModelCardProps & InjectedAntUtilsProps;

const hourMapping = {
  0: "00:00",
  1: "01:00",
  2: "02:00",
  3: "03:00",
  4: "04:00",
  5: "05:00",
  6: "06:00",
  7: "07:00",
  8: "08:00",
  9: "09:00",
  10: "10:00",
  11: "11:00",
  12: "12:00",
  13: "13:00",
  14: "14:00",
  15: "15:00",
  16: "16:00",
  17: "17:00",
  18: "18:00",
  19: "19:00",
  20: "20:00",
  21: "21:00",
  22: "22:00",
  23: "23:00",
};

const renderScheduleAsText = (schedule: ISchedule): string => {
  if (!schedule) return "Schedule not found";
  if (schedule.period === "never") {
    return "Never";
  }
  if (schedule.period === "hour") {
    return "Hourly";
  }
  if (schedule.period === "day" && schedule.dailySendEvery && schedule.atHour) {
    return `${
      schedule.dailySendEvery === "on_week_day" ? "Every business day" : "Daily"
    } at ${hourMapping[schedule.atHour]}`;
  }
  if (schedule.period === "week" && schedule.weeklyOnDay && schedule.atHour) {
    return `Every ${schedule.weeklyOnDay} at ${hourMapping[schedule.atHour]}`;
  }
  if (
    schedule.period === "month" &&
    schedule.monthlyDayOfMonthPreset === "first_day" &&
    schedule.atHour
  ) {
    return `On the first day of the month at ${hourMapping[schedule.atHour]}`;
  }
  if (
    schedule.period === "month" &&
    schedule.monthlyDayOfMonthPreset === "last_day" &&
    schedule.atHour
  ) {
    return `On the last day of the month at ${hourMapping[schedule.atHour]}`;
  }
  if (
    schedule.period === "month" &&
    schedule.monthlyDayOfMonthPreset === "nth_day" &&
    schedule.monthlyDayOfMonthNumber &&
    schedule.atHour
  ) {
    return `On the ${moment
      .localeData()
      .ordinal(schedule.monthlyDayOfMonthNumber)} day of the month at ${
      hourMapping[schedule.atHour]
    }`;
  }

  return "Invalid schedule config";
};

function RadarModelCard(props: Props) {
  const {
    currentWarehouse,
    radar,
    afterScheduleSave,
    onUpdateRadar,
    datasets,
    objectStore,
  } = props;

  const [modalOpen, setModalOpen] = useState<
    "model" | "object" | "recordKey" | "schedule" | undefined
  >(undefined);

  const currentDataset = datasets.find(
    (m) => m.isModel && m.id === radar.model?.id
  );

  const currentObject: ObjectData | undefined = radar.object?.id
    ? objectStore[radar.object.id]
    : undefined;

  return (
    <>
      <WlyCard
        title="Radar definition"
        extra={
          <Dropdown
            placement="bottomRight"
            trigger={["click"]}
            menu={{
              items: [
                {
                  key: "object",
                  label: "Edit object",
                  onClick: () => setModalOpen("object"),
                },
                {
                  key: "model",
                  label: "Edit model",
                  onClick: () => setModalOpen("model"),
                },
                {
                  key: "record",
                  label: "Edit record key",
                  onClick: () => setModalOpen("recordKey"),
                },
                {
                  key: "schedule",
                  label: "Edit schedule",
                  onClick: () => setModalOpen("schedule"),
                },
              ],
            }}
          >
            <Button type="primary" size="small">
              Edit
            </Button>
          </Dropdown>
        }
      >
        <Space direction="vertical" style={{ display: "flex" }} size={20}>
          <Flex>
            <div style={{ flex: 1 }}>
              <Typography.Text strong>Object</Typography.Text>
            </div>
            {currentObject ? (
              <Space>{currentObject.name}</Space>
            ) : (
              <Space>
                <Emoji emoji={":warning:"} size={16} />
                <Typography.Text type="secondary">
                  Object not found
                </Typography.Text>
              </Space>
            )}
          </Flex>
          <Flex>
            <div style={{ flex: 1 }}>
              <Typography.Text strong>Model</Typography.Text>
            </div>
            {currentDataset ? (
              <Space>
                <SourceImageRenderer
                  isModel={currentDataset.isModel}
                  alt="SQL Model"
                  size={16}
                  img={
                    !currentDataset.isModel
                      ? currentDataset.source.sourceMeta.publicInfo.logo
                      : undefined
                  }
                />
                {currentDataset.name}
              </Space>
            ) : (
              <Space>
                <Emoji emoji={":warning:"} size={16} />
                <Typography.Text type="secondary">
                  Model not found
                </Typography.Text>
              </Space>
            )}
          </Flex>
          <Flex>
            <div style={{ flex: 1 }}>
              <Typography.Text strong>Record key</Typography.Text>
            </div>
            {radar.recordIdKey ? (
              <Tag>{radar.recordIdKey}</Tag>
            ) : (
              <Typography.Text type="secondary">Not set</Typography.Text>
            )}
          </Flex>
          <Flex>
            <div style={{ flex: 1 }}>
              <Typography.Text strong>Schedule</Typography.Text>
            </div>
            {radar.schedule ? (
              renderScheduleAsText(radar.schedule)
            ) : (
              <Space>
                <Emoji emoji={":warning:"} size={16} />
                <Typography.Text type="secondary">
                  Schedule not found
                </Typography.Text>
              </Space>
            )}
          </Flex>
        </Space>
      </WlyCard>
      <RadarModelModal
        datasets={datasets}
        onUpdateRadar={onUpdateRadar}
        open={modalOpen === "model"}
        radar={radar}
        onClose={() => setModalOpen(undefined)}
      />
      <RadarObjectModal
        objectStore={objectStore}
        onUpdateRadar={onUpdateRadar}
        open={modalOpen === "object"}
        radar={radar}
        onClose={() => setModalOpen(undefined)}
      />
      <RadarRecordKeyModal
        currentWarehouse={currentWarehouse}
        onUpdateRadar={onUpdateRadar}
        open={modalOpen === "recordKey"}
        radar={radar}
        onClose={() => setModalOpen(undefined)}
      />
      <RadarScheduleModal
        afterScheduleSave={afterScheduleSave}
        open={modalOpen === "schedule"}
        onClose={() => setModalOpen(undefined)}
        radar={radar}
      />
    </>
  );
}

export default compose<Props, IRadarModelCardProps>(withAntUtils)(
  RadarModelCard
);
