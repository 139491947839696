import {
  FieldBinaryOutlined,
  FieldNumberOutlined,
  FieldStringOutlined,
  FieldTimeOutlined,
  FunctionOutlined,
  GlobalOutlined,
} from "@ant-design/icons";
import * as React from "react";
import type { DataType } from "../../../interfaces/transformations";

interface ITypeRendererProps {
  domain: DataType;
  geo?: boolean;
  formula?: boolean;
}

export default class TypeRenderer extends React.Component<ITypeRendererProps> {
  public render() {
    const { domain, formula, geo } = this.props;
    if (formula) {
      return <FunctionOutlined />;
    }

    if (geo) {
      return <GlobalOutlined />;
    }

    switch (domain) {
      case "BOOLEAN":
        return <FieldBinaryOutlined />;
      case "NUMERIC":
        return <FieldNumberOutlined />;
      case "STRING":
        return <FieldStringOutlined />;
      case "TIME":
        return <FieldTimeOutlined />;
      default:
        return null;
    }
  }
}
