import { Skeleton, Space } from "antd";
import Aligner from "../../../components/layout/aligner/Aligner";
import Feednack from "../../../components/layout/feedback/feedback";
import { LongLoading } from "../../../components/layout/feedback/long-loading";

interface IWorkbenchLoaderProps {}

export function WorkbenchLoader(props: IWorkbenchLoaderProps) {
  return (
    <div className="workbench">
      <div className="workbench-inner">
        <div className="workbench-toolbar">
          <Space style={{ paddingTop: 18 }} direction="vertical" size={18}>
            <Skeleton.Button shape="circle" active />
            <Skeleton.Button shape="circle" active />
            <Skeleton.Button shape="circle" active />
            <Skeleton.Button shape="circle" active />
          </Space>
        </div>
        <Aligner className="standard-bg" style={{ width: "100%" }}>
          <Feednack>
            <LongLoading color="#5C6671" />
          </Feednack>
        </Aligner>
      </div>
    </div>
  );
}
