import type { IRecord } from "../../../domain";

export interface IEmailWidgetSpacerConfig {
  height?: number;
}

export const getDefaultConfig = (
  conf: IEmailWidgetSpacerConfig,
  record: IRecord
) => {
  const height = conf.height ? conf.height : 50;

  return {
    height,
  };
};
