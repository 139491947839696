import type { IWidgetEmailMjmlRenderer } from "../../domain";
import type { IEmailWidgetDividerConfig } from "../domain";
import { getDefaultConfig } from "../domain";

export const EmailWidgetDividerMjml: IWidgetEmailMjmlRenderer<
  IEmailWidgetDividerConfig
> = async (widget, object, record, conf) => {
  const defaults = getDefaultConfig(conf, record);
  const padding =
    defaults.spacing === "normal"
      ? `padding-top="10" padding-bottom="10"`
      : `padding-top="0" padding-bottom="0"`;

  return `<mj-divider padding-left="0" padding-right="0" ${padding} border-width="${defaults.borderWidth}px" border-style="solid" border-color="${defaults.color}" />`;
};
