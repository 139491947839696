import * as React from "react";
import type { RouteComponentProps } from "react-router";
import { withRouter } from "react-router";
import { compose } from "../../../components/compose/WlyCompose";
import Feednack from "../../../components/layout/feedback/feedback";
import type {
  ISource,
  ISourceMeta,
  ISourceMetaExecutor,
} from "../../../interfaces/sources";
import {
  TARGET_DATABASE_FORM_FIELD_NAME,
  TARGET_SCHEMA_FORM_FIELD_NAME,
} from "../../../interfaces/sources";
import { routeDescriptor } from "../../../routes/routes";
import {
  WarehouseUserType,
  getDatabases,
} from "../../../services/BrizoService";
import GraphQLService from "../../../services/graphql/GraphQLService";
import { encodeUrlParam } from "../../../store/workbenchUIStore";
import type { InjectedOrgProps } from "../../orgs/WithOrg";
import WithOrg, { getCurrentWarehouse } from "../../orgs/WithOrg";
import ItemConnectionPage from "../connection/ItemConnectionPage";
import { createSourceAndRedirect, sourceStep2 } from "../domain";

interface ISourceConnectionPageProps {}

type Props = ISourceConnectionPageProps &
  RouteComponentProps<{ organizationSlug: string; warehouseSlug?: string }> &
  InjectedOrgProps;

class SourceConnectionPage extends React.Component<Props> {
  public render() {
    const {
      history,
      match: { params },
      org,
    } = this.props;

    const currentWarehouse = getCurrentWarehouse(org, params.warehouseSlug);

    const cancelRedirectUri = routeDescriptor.workbench.renderRoute({
      ...params,
    });

    if (!currentWarehouse) {
      return <Feednack>No warehouse found</Feednack>;
    }

    const renderRouteCreate = (
      source: ISource,
      sourceType: ISourceMetaExecutor
    ) => {
      if (sourceType === "FIVETRAN") {
        return routeDescriptor.workbench.renderRoute({
          ...params,
          tableSlug: encodeUrlParam({
            type: "connector",
            value: source.id,
          }),
        });
      } else {
        return routeDescriptor.sourceConnectionStep2.renderRoute({
          ...params,
          itemId: source.id,
        });
      }
    };

    return (
      <ItemConnectionPage
        allItemsMetaQuery={`
        query getSourceMeta(
          $slug: String!
        ) {
          allItemMetas: allSourceMetas(where: {deleted: false, slug: $slug}) {
            id
            slug
            name
            auth
            executor
            defaultTargetSchema
            buttonType
            oAuthConfig {
                authorizationUrl
            }
            publicInfo {
              id
              name
              slug
              label
              tagline
              website
              logo
              description
            }
            options {
              id
              slug
              key
              type
              options
              label
              editable
              defaultValue
              encrypt
              hidden
              required
              description
            }
          }
        }
        `}
        remainingItemValuesQuery={`
        query getRemainingSourceValues(
          $itemId: ID!
        ) {
            allItemValue: allSourceValues(where:{source: {id: $itemId}, value: ""}) {
              id
              value
              option {
                id
                slug
                key
                type
                options
                label
                editable
                defaultValue
                encrypt
                hidden
                required
                description
              }
            }
        }
        `}
        step1CreationPromise={(
          itemName,
          orgId,
          itemMeta: ISourceMeta,
          itemValue,
          rawInputs
        ) =>
          createSourceAndRedirect(
            itemName,
            orgId,
            currentWarehouse.id,
            itemMeta,
            itemValue,
            history,
            renderRouteCreate,
            rawInputs
          )
        }
        step2CreationPromise={(itemId, itemValueUpdateInput) =>
          sourceStep2(itemId, itemValueUpdateInput).then((s) => {
            const {
              history,
              match: { params },
            } = this.props;
            history.push(
              routeDescriptor.workbench.renderRoute({
                ...params,
                tableSlug: encodeUrlParam({
                  type: "connector",
                  value: s.updateSource.id,
                }),
              })
            );
          })
        }
        additionalFormFieldsGenerator={async (
          orgId: string,
          itemMeta: ISourceMeta
        ) => {
          const destinationMetaResult = await GraphQLService<{
            allDestinations: [
              {
                destinationMeta: {
                  isWhalyManaged: false;
                };
                targetDatabase: string;
              }
            ];
          }>(
            `
          query getConnectedWarehouse($orgId: ID!) {
            allDestinations(where: { isDeleted: false, org: { id: $orgId } }) {
              destinationMeta {
                isWhalyManaged
              }
              targetDatabase
            }
          }
          `,
            {
              orgId,
            }
          );
          if (
            destinationMetaResult.allDestinations.length > 0 &&
            destinationMetaResult.allDestinations[0].destinationMeta
              .isWhalyManaged === false
          ) {
            const destination = destinationMetaResult.allDestinations[0];

            const availableDatabases = await getDatabases(
              currentWarehouse.id,
              WarehouseUserType.DATA_LOADING
            );
            const options = availableDatabases.data.map(
              (data) => data.databaseName
            );
            return [
              {
                name: TARGET_DATABASE_FORM_FIELD_NAME,
                label: "Name of the output Database in the Warehouse",
                type: "select",
                defaultValue: destination.targetDatabase,
                options,
              },
              {
                name: TARGET_SCHEMA_FORM_FIELD_NAME,
                label: "Name of the output Schema in the Warehouse",
                type: "input",
                defaultValue: itemMeta.defaultTargetSchema,
                validate: (value) => {
                  let errorMessage;
                  if (!/^([A-Za-z0-9\_]+)$/i.test(value)) {
                    errorMessage =
                      "Only letters, numbers, and underscores allowed";
                  }
                  return errorMessage;
                },
              },
            ];
          }
        }}
        redirectUri={cancelRedirectUri}
      />
    );
  }
}

export default compose<Props, ISourceConnectionPageProps>(
  withRouter,
  WithOrg
)(SourceConnectionPage);
