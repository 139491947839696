import { ExpandOutlined, MinusOutlined, PlusOutlined } from "@ant-design/icons";
import { CanvasWidget } from "@projectstorm/react-canvas-core";
import type { DiagramEngine } from "@projectstorm/react-diagrams";
import { Button } from "antd";
import type { Identifier } from "dnd-core";
import { useDrop } from "react-dnd";
import type { IDatasetDragItem } from "../../../../domain";
import { WhalyCanvasWidget } from "./CanvasWidget";

interface ICanvasWrapperProps {
  height?: number;
  engine: DiagramEngine;
  onAddDataset?: (id: string) => void;
  setContainer?: (d: HTMLDivElement) => void;
  onZoomIn?: () => void;
  onZoomOut?: () => void;
  onFit?: () => void;
  additionalButtons?: React.ReactNode;
}

export function CanvasWrapper(props: ICanvasWrapperProps) {
  const { onFit, onZoomIn, onZoomOut, additionalButtons } = props;

  const [, drop] = useDrop<
    IDatasetDragItem,
    void,
    { handlerId: Identifier | null }
  >({
    accept: "DATASET",
    canDrop(item, monitor) {
      return true;
    },
    drop: (i) => {
      if (props.onAddDataset) {
        props.onAddDataset(i.id);
      }
    },
  });

  return (
    <div
      ref={drop}
      onDragOver={(e) => {
        // we allow drop for application/whaly.model
        if (e.dataTransfer.types?.includes("application/whaly.model")) {
          e.stopPropagation();
          e.preventDefault();
        }
      }}
      onDrop={(e) => {
        // we get the ID from the drag event
        const id = e.dataTransfer.getData("application/whaly.model");
        if (id) {
          props.onAddDataset(id);
        }
      }}
      style={{
        height: props.height ? props.height : "100%",
        position: "relative",
      }}
    >
      <WhalyCanvasWidget
        height={props.height}
        setContainer={props.setContainer}
      >
        <CanvasWidget engine={props.engine} />
      </WhalyCanvasWidget>
      {additionalButtons ? (
        <div style={{ position: "absolute", bottom: 12, left: 12 }}>
          {additionalButtons}
        </div>
      ) : null}
      {onZoomIn && onZoomOut && (
        <div style={{ position: "absolute", bottom: 12, right: 12 }}>
          {onZoomIn && (
            <div style={{ paddingBottom: 6 }}>
              <Button
                icon={<PlusOutlined />}
                size="small"
                type="default"
                shape="circle"
                onClick={onZoomIn}
              />
            </div>
          )}
          {onZoomOut && (
            <div>
              <Button
                icon={<MinusOutlined />}
                size="small"
                type="default"
                shape="circle"
                onClick={onZoomOut}
              />
            </div>
          )}
          {onFit && (
            <div style={{ paddingTop: 6 }}>
              <Button
                icon={<ExpandOutlined style={{ transform: "scale(0.9)" }} />}
                size="small"
                type="default"
                shape="circle"
                onClick={onFit}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
}
