import { WarningFilled } from "@ant-design/icons";
import type { DiagramEngine } from "@projectstorm/react-diagrams";
import { PortModelAlignment, PortWidget } from "@projectstorm/react-diagrams";
import { Progress, Statistic, Tag, Typography } from "antd";
import humanizeString from "humanize-string";
import { inject, observer } from "mobx-react";
import numeral from "numeral";
import { compose } from "../../../../../../../../components/compose/WlyCompose";
import type { DemoStoreProps } from "../../../../../../../../store/demoStore";
import { BORDER_COLOR } from "../../../../../../../v2-demo/container/layout/domain";
import type { TeamMetricModel } from "./TeamMetricModel";
import "./TeamMetricWidget.scss";

interface ITeamMetricWidgetProps {
  node: TeamMetricModel;
  engine: DiagramEngine;
  size?: number;
}

export const HEIGHT = 52;
export const WIDTH = 188; // GRID IS 68 px so this should be a multiple of 68 - PORT SIZE
const PORT_SIZE = 16;

export interface MetricData {
  name: string;
  value: number;
  teams: Array<{ name: string; value: number; alerts: number }>;
}

type Props = ITeamMetricWidgetProps & DemoStoreProps;

function TeamMetricWidget(props: Props) {
  const { node, engine, demoStore } = props;

  const data = node.data;
  const hasInPort = node.hasInPort;
  const hasOutPort = node.hasOutPort;

  const copy = [...data.teams];

  const s = copy.sort((a, b) => {
    return b.value - a.value;
  });
  const maxTeams = s[0];
  const minTeams = s[s.length - 1];

  const alerts = copy.reduce((acc, v) => {
    return acc + v.alerts;
  }, 0);

  const onCLick = node.hasInPort
    ? undefined
    : () => {
        if (!demoStore.selectedInput) {
          demoStore.setSelectedInput(data.name);
        } else {
          demoStore.setSelectedInput(undefined);
        }
      };

  return (
    <div
      className={`team-node-widget-wrapper `}
      style={{ height: HEIGHT * 6 + PORT_SIZE, width: WIDTH * 1.5 + PORT_SIZE }}
    >
      {hasOutPort ? (
        <PortWidget
          style={{
            top: (HEIGHT * 6) / 2,
            right: 0,
            position: "absolute",
          }}
          port={node.getPort(PortModelAlignment.RIGHT)}
          engine={engine}
        >
          <div className="data-port-widget" />
        </PortWidget>
      ) : undefined}
      {hasInPort ? (
        <PortWidget
          style={{
            top: (HEIGHT * 6) / 2,
            left: 0,
            position: "absolute",
          }}
          port={node.getPort(PortModelAlignment.LEFT)}
          engine={engine}
        >
          <div className="data-port-widget" />
        </PortWidget>
      ) : undefined}
      <div
        className={`team-node-widget-core ${!hasInPort ? "start-flow" : ""} ${
          demoStore.selectedInput &&
          demoStore.selectedInput !== data.name &&
          !hasInPort
            ? "invisible"
            : ""
        }`}
        style={{ height: HEIGHT * 6, width: WIDTH * 1.5 }}
        onClick={onCLick}
      >
        <div className="team-node-widget-core-inner">
          <div
            style={{ padding: 6, borderBottom: `1px solid ${BORDER_COLOR}` }}
          >
            <div>
              <Typography.Title style={{ marginBottom: 0 }} level={5}>
                {data?.name ? data.name : "Outbound"}
              </Typography.Title>
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <div style={{ flex: 0, marginRight: 8 }}>
                <Statistic value={data?.value ? data.value : 123} />
              </div>
              {alerts > 0 && (
                <div style={{ flex: 1 }}>
                  <Tag icon={<WarningFilled />} color="gold">
                    {alerts}
                  </Tag>
                </div>
              )}
            </div>
          </div>
          {(data?.teams || []).map((t) => {
            const p = (100 * t.value) / data.value;
            const percent = numeral(p).format("0.0");

            const renderStatus = () => {
              if (maxTeams.name === t.name) {
                return "success";
              }
              if (minTeams.name === t.name) {
                return "exception";
              }
            };
            return (
              <div
                key={t.value}
                style={{
                  borderBottom: `1px solid ${BORDER_COLOR}`,
                  padding: 6,
                }}
                className={`${demoStore.hoveredTeam === t.name ? "hover" : ""}`}
                onMouseEnter={() => demoStore.setHoveredTeam(t.name)}
                onMouseLeave={() => demoStore.setHoveredTeam(undefined)}
                onClick={() =>
                  demoStore.setSelectedTeam({ name: t.name, metric: data.name })
                }
              >
                <div style={{ display: "flex" }}>
                  <div style={{ flex: 1 }}>
                    <Typography.Text style={{ marginRight: 6 }} strong>
                      {humanizeString(t.name)}
                    </Typography.Text>
                    {t.alerts > 0 && (
                      <Tag
                        bordered={false}
                        icon={<WarningFilled />}
                        color="gold"
                      >
                        {t.alerts}
                      </Tag>
                    )}
                  </div>
                  <div style={{ flex: 0 }}>{percent}%</div>
                </div>
                <div style={{ display: "flex" }}>
                  <div style={{ flex: 1 }}>{numeral(t.value).format()}</div>
                  <div style={{ flex: 2 }}>
                    <Progress
                      status={renderStatus()}
                      percent={p}
                      showInfo={false}
                      style={{ marginBottom: 0 }}
                    />
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default compose<Props, ITeamMetricWidgetProps>(
  inject("demoStore"),
  observer
)(TeamMetricWidget);
