import { Typography } from "antd";
import * as React from "react";
import { compose } from "../../../../../components/compose/WlyCompose";
import { UserRenderer } from "../../../../../components/user/UserRenderer";
import type { IExploration } from "../../../../../interfaces/explorations";
import type { InjectedOrgProps } from "../../../../orgs/WithOrg";
import WithOrg from "../../../../orgs/WithOrg";

import "./ExplorationDetails.scss";

interface IExplorationDetailProps {
  exploration: IExploration;
}

type Props = InjectedOrgProps & IExplorationDetailProps;

const { Text, Title } = Typography;

class ExplorationDetail extends React.Component<Props> {
  public render() {
    const { exploration } = this.props;
    return (
      <div className="exploration-details">
        <div className="exploration-details-title">
          <div className="exploration-details-title-text">
            <Title level={5} ellipsis>
              Welcome to {exploration.name}
            </Title>
          </div>
        </div>
        {exploration.description ? (
          <div
            className="exploration-details-content"
            dangerouslySetInnerHTML={{ __html: exploration.description }}
          />
        ) : (
          <Text italic>Empty description... </Text>
        )}
        <div className="exploration-details-item">
          <div style={{ display: "flex" }}>
            <div style={{ flex: 1 }}>
              <Text strong>Owner</Text>
            </div>
          </div>
          <div>
            {exploration.owner ? (
              <div className="exploration-detail-owner">
                <div className="exploration-detail-owner-renderer">
                  <UserRenderer user={exploration.owner} />
                </div>
              </div>
            ) : (
              <Text italic>No owner</Text>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default compose<Props, IExplorationDetailProps>(WithOrg)(
  ExplorationDetail
);
