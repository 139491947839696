import { compose } from "../../../../../../components/compose/WlyCompose";
import type {
  IDataset,
  IDatasetRelationship,
} from "../../../../../../interfaces/sources";
import type { SchemaResult } from "../../../../../../interfaces/transformations";
import type { InjectedOrgProps } from "../../../../../orgs/WithOrg";
import WithOrg from "../../../../../orgs/WithOrg";
import RelatedDatasetSelection from "../../../../../spreadsheet/steps/RelatedData/RelatedDatasetSelection";
import type {
  IDimensionFormInput,
  IMeasureTable,
  IMetricFormInput,
  ITableFormInput,
} from "../../domain";
import { generateFakeId } from "../../domain";

interface IRelatedDataProps {
  table: IMeasureTable;
  onAddRelatedData: (
    data: ITableFormInput,
    metrics?: IMetricFormInput[],
    dimensions?: IDimensionFormInput[]
  ) => void;
  allIncomingDatasetRelationships: IDatasetRelationship[];
  allDatasets: IDataset[];
  fetchSchema: (
    tablesSchemas: Array<{ datasetId: string; viewId: string }>
  ) => Promise<SchemaResult[]>;
}

type Props = IRelatedDataProps & InjectedOrgProps;

function RelatedData(props: Props) {
  const {
    table,
    onAddRelatedData,
    allIncomingDatasetRelationships,
    allDatasets,
    fetchSchema,
  } = props;

  return (
    <div>
      <RelatedDatasetSelection
        selectedTable={table}
        allDatasetRelationships={allIncomingDatasetRelationships}
        allDatasets={allDatasets}
        fetchSchema={fetchSchema}
        onSelect={(
          fromTableId,
          relationship,
          toDataset,
          toView,
          measures,
          dimensions
        ) => {
          const name = toDataset.name;
          onAddRelatedData(
            {
              datasetId: toDataset.id,
              name: name,
              drills: { type: "INHERIT" },
              primaryKey: toDataset.primaryKey,
              viewCubeName: toView.cubeName,
              viewId: toView.id,
              incomingRelationship: {
                from: relationship.from,
                id: generateFakeId(),
                parentId: fromTableId,
                to: relationship.to,
                type: relationship.type,
              },
            },
            measures,
            dimensions
          );
        }}
      />
    </div>
  );
}

export default compose<Props, IRelatedDataProps>(WithOrg)(RelatedData);
