export const generateGradient = (colors: string[]) => {
  const linearGradient = `
        linear-gradient(90deg, ${colors
          .map((c, i, s) => `${c} ${Math.round((i / (s.length - 1)) * 100)}%`)
          .join(", ")})
      `;
  return linearGradient;
};

export const toRgba = (c: { r: number; g: number; b: number; a?: number }) =>
  `rgba(${c.r},${c.g},${c.b},${c.a ? c.a : 1})`;
