import { Button, Form, Modal } from "antd";
import * as React from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import type { InjectedOrgProps } from "../../../containers/orgs/WithOrg";
import WithOrg from "../../../containers/orgs/WithOrg";
import { hasRoleAccessBoolean } from "../../../containers/user-settings/HasRoleAccess";
import { IUserRoleType } from "../../../interfaces/user";
import GraphQLService from "../../../services/graphql/GraphQLService";
import type { InjectedAntUtilsProps } from "../../ant-utils/withAntUtils";
import { withAntUtils } from "../../ant-utils/withAntUtils";
import { compose } from "../../compose/WlyCompose";
import Delayed from "../../delayed/delayed";

interface IExplorationEditDescriptionProps {
  initialData?: initialData;
  visible: boolean;
  onSave: (initialData: initialData) => any;
  onCancel: () => void;
}

interface initialData {
  id: string;
  description: string;
}

type Props = IExplorationEditDescriptionProps &
  InjectedOrgProps &
  InjectedAntUtilsProps;

const IExplorationEditDescription: React.FunctionComponent<Props> = (props) => {
  const { visible, initialData, antUtils, onSave, onCancel, user, org } = props;
  const [confirmLoading, setConfirmLoading] = React.useState<boolean>(false);
  const [form] = Form.useForm();

  const renderContent = (initialData: initialData) => {
    return (
      <Delayed delay={100}>
        <Form
          initialValues={initialData}
          layout="vertical"
          form={form}
          preserve={false}
        >
          <Form.Item name={["description"]}>
            <ReactQuill
              formats={[
                "header",
                "bold",
                "italic",
                "underline",
                "strike",
                "blockquote",
                "list",
                "bullet",
                "indent",
                "link",
                "image",
                "align",
              ]}
              theme="snow"
              modules={{
                toolbar: [
                  [{ header: [1, 2, 3, false] }],
                  ["bold", "italic", "underline"],
                  [
                    { align: "" },
                    { align: "center" },
                    { align: "right" },
                    { align: "justify" },
                  ],
                  [
                    { list: "ordered" },
                    { list: "bullet" },
                    { indent: "-1" },
                    { indent: "+1" },
                  ],
                  ["link"],
                ],
              }}
            />
          </Form.Item>
        </Form>
      </Delayed>
    );
  };

  const renderFooter = () => {
    return (
      <div>
        <Button onClick={onCancel}>Cancel</Button>
        <Button
          type="primary"
          disabled={!hasRoleAccessBoolean(IUserRoleType.BUILDER, user, org.id)}
          loading={confirmLoading}
          onClick={async () => {
            setConfirmLoading(true);
            try {
              const values: initialData = await form.validateFields();
              await GraphQLService(
                `
                  mutation updateExploration($id: ID!, $data: ExplorationUpdateInput) {
                      updateExploration(id: $id, data: $data) {
                        id
                      }
                    }
                  `,
                {
                  id: initialData.id,
                  data: {
                    description: values.description,
                  },
                }
              );
              setConfirmLoading(false);
              antUtils.message.success("Description saved");
              onSave({ ...initialData, ...values });

              return true;
            } catch (error) {
              antUtils.message.error("Error while saving description");
              console.warn(error);
            }
          }}
        >
          Save
        </Button>
      </div>
    );
  };

  return (
    <Modal
      title="Edit description"
      centered
      open={visible}
      footer={renderFooter()}
      confirmLoading={confirmLoading}
      destroyOnClose={true}
      onCancel={onCancel}
    >
      {renderContent(initialData)}
    </Modal>
  );
};

export default compose<Props, IExplorationEditDescriptionProps>(
  WithOrg,
  withAntUtils
)(IExplorationEditDescription);
