import { ConfigProvider, Form, Segmented, theme } from "antd";
import * as React from "react";
import type { ChartType } from "../../../../components/chart/domain";
import Feednack from "../../../../components/layout/feedback/feedback";
import type {
  AvailableDimension,
  AvailableMetric,
} from "../../../../components/measures/filter-item/FilterItem";
import type { IExploration } from "../../../../interfaces/explorations";
import type { ChartOption } from "../../../chart-options/ChartOptions";
import { ChartOptionsWrapper } from "../../../chart-options/ChartOptionsWrapper";
import type { ILagoonQuery } from "../domain";
import type { IAsyncMeta } from "../visualization/chart/Chart";

interface IChartOptionsPanelWrapperProps {
  queryBuilder: React.ReactNode;
  meta: IAsyncMeta;
  exploration: IExploration;
  availableMetrics: AvailableMetric[];
  availableDimensions: AvailableDimension[];
  lagoonQuery: ILagoonQuery;
  currentChartType: ChartType;
  onChange: (e: ChartOption) => void;
}

type PanelValue = "OPTION" | "QUERY";

export function ChartOptionsPanelWrapper(
  props: IChartOptionsPanelWrapperProps
) {
  const [selected, setSelected] = React.useState<PanelValue>("QUERY");
  const [form] = Form.useForm();

  const renderInner = () => {
    return (
      <>
        <div className="content options" hidden={selected !== "OPTION"}>
          {props.meta.status === "initial" ? (
            <Feednack>Please run a query to customize the chart</Feednack>
          ) : (
            <ConfigProvider
              theme={{
                algorithm: theme.compactAlgorithm,
              }}
            >
              <ChartOptionsWrapper
                currentChartType={props.currentChartType}
                lagoonQuery={props.lagoonQuery}
                availableDimensions={props.availableDimensions}
                availableMetrics={props.availableMetrics}
                meta={props.meta}
                form={form}
                onChange={() => props.onChange(form.getFieldsValue())}
              />
            </ConfigProvider>
          )}
        </div>
        <div className="content" hidden={selected !== "QUERY"}>
          {props.queryBuilder}
        </div>
      </>
    );
  };

  return (
    <div className="chart-options-panel">
      <div
        style={{
          padding: 12,
        }}
      >
        <Segmented
          block
          value={selected}
          onChange={(v: PanelValue) => setSelected(v)}
          options={[
            {
              label: "Query",
              value: "QUERY",
            },
            {
              label: "Options",
              value: "OPTION",
            },
          ]}
        />
      </div>
      {renderInner()}
    </div>
  );
}
