import type { IWidgetEmailMjmlRenderer } from "../../domain";
import type { IEmailWidgetButtonConfig } from "../domain";
import { getDefaultConfig, getHref } from "../domain";

export const EmailWidgetButtonMjml: IWidgetEmailMjmlRenderer<
  IEmailWidgetButtonConfig
> = async (widget, object, record, conf, org) => {
  const defaults = getDefaultConfig(conf, record);
  const href = getHref(defaults, object, record, org);
  const padding =
    defaults.spacing === "normal"
      ? `padding-top="10" padding-bottom="10"`
      : `padding-top="0" padding-bottom="0"`;
  return `<mj-button ${padding} align="${defaults.align}" href="${href}">${defaults.text}</mj-button>`;
};
