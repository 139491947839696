import { Form, Input, Modal, TreeSelect } from "antd";
import * as React from "react";
import type { RouteComponentProps } from "react-router";
import { withRouter } from "react-router";
import type { ChartType } from "../../../../../components/chart/domain";
import { compose } from "../../../../../components/compose/WlyCompose";
import { buildFolderTree } from "../../../../../components/folders/domain";
import { WlyFolderIcon } from "../../../../../components/icons/custom-icons/WlyFolderIcon";
import Aligner from "../../../../../components/layout/aligner/Aligner";
import Loading from "../../../../../components/layout/feedback/loading";
import type { AsyncData } from "../../../../../helpers/typescriptHelpers";
import type { IReportFolder } from "../../../../../interfaces/folder";
import type { IReport } from "../../../../../interfaces/reports";
import { routeDescriptor } from "../../../../../routes/routes";
import { track } from "../../../../../services/AnalyticsService";
import GraphQLService from "../../../../../services/graphql/GraphQLService";
import { REPORT_FOLDER } from "../../../../../store/dataStores/workspace/WorkspaceDatastoreDomain";
import type { InjectedOrgProps } from "../../../../orgs/WithOrg";
import WithOrg from "../../../../orgs/WithOrg";
import { convertWlyDatePickerValueToString } from "../../../../reports/view/filters/date-filter/WlyDatePicker";
import { ALL_REPORTS_GQL } from "../../../../workspace/reports/domain";
import type { IAnalysisType, ILagoonQuery } from "../../domain";

interface IAddTileToQuestionProps {
  visible: boolean;
  explorationId: string;
  currentReportId?: string;
  query: ILagoonQuery;
  chartType: ChartType;
  analysisType: IAnalysisType;
  onCancel: () => void;
  afterSave?: () => Promise<void>;
}

type Props = IAddTileToQuestionProps &
  InjectedOrgProps &
  RouteComponentProps<{ organizationSlug: string }>;

const AddTileToQuestion: React.FunctionComponent<Props> = (props) => {
  const {
    org,
    visible,
    onCancel,
    explorationId,
    query,
    analysisType,
    chartType,
    history,
    match: { params },
  } = props;

  const [reports, setReports] = React.useState<AsyncData<IReport[]>>({
    status: "initial",
  });
  const [folders, setFolders] = React.useState<
    AsyncData<{ allReportFolders: IReportFolder[]; myFolder?: IReportFolder }>
  >({ status: "initial" });
  const [confirmLoading, setConfirmLoading] = React.useState(false);
  const [form] = Form.useForm();

  React.useEffect(() => {
    async function fetchReports() {
      setReports({ status: "loading" });
      await GraphQLService(ALL_REPORTS_GQL, { orgId: org.id })
        .then((r) => {
          setReports({ status: "success", data: r.allReports });
        })
        .catch((r) => {
          setReports({ status: "error", error: r });
        });
    }
    if (visible == true) {
      fetchReports();
    }
  }, [org.id, visible]);

  React.useEffect(() => {
    async function fetchFolders() {
      setFolders({ status: "loading" });
      await GraphQLService(REPORT_FOLDER, {
        orgId: org.id,
        userId: props.user.id,
      })
        .then((r) => {
          setFolders({
            status: "success",
            data: {
              allReportFolders: r.allReportFolders,
              myFolder: r.myFolder[0],
            },
          });
        })
        .catch((r) => {
          setFolders({ status: "error", error: r });
        });
    }
    if (visible == true) {
      fetchFolders();
    }
  }, [org.id, visible]);

  const renderInnerModal = () => {
    if (
      reports.status === "initial" ||
      reports.status === "loading" ||
      folders.status === "initial" ||
      folders.status === "loading"
    ) {
      return (
        <Aligner>
          <Loading />
        </Aligner>
      );
    }

    if (reports.status === "error" || folders.status === "error") {
      return <Aligner>An unexpected error happened</Aligner>;
    }

    return (
      <Form
        layout="vertical"
        form={form}
        initialValues={{
          name: "",
          reportId: undefined,
          folderId:
            folders.status === "success" &&
            folders.data.allReportFolders &&
            folders.data.allReportFolders[0]
              ? folders.data.allReportFolders[0].id
              : undefined,
        }}
        onValuesChange={(change, val) => {
          if (change.folderId) {
            form.setFieldsValue({ reportId: undefined });
          }
        }}
      >
        <Form.Item
          name={["name"]}
          label="What's your question name?"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item style={{ marginBottom: 0 }} shouldUpdate={true}>
          {() => {
            const style: React.CSSProperties = {
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            };
            const render = (f) => (
              <div style={{ display: "flex", alignItems: "center" }}>
                <div
                  style={{ width: 16, height: 24, marginRight: 6, ...style }}
                >
                  <WlyFolderIcon
                    style={{ fontSize: 20, color: f.color ?? "#2599D4" }}
                  />
                </div>
                <span>{f.name}</span>
              </div>
            );

            return (
              <Form.Item
                name={["folderId"]}
                label="Choose your folder"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <TreeSelect
                  treeData={buildFolderTree(
                    folders.data.allReportFolders,
                    render,
                    folders.data.myFolder
                  )}
                />
              </Form.Item>
            );
          }}
        </Form.Item>
      </Form>
    );
  };

  return (
    <Modal
      open={visible}
      title={"Save your Question"}
      okText={"Save"}
      cancelText="Cancel"
      destroyOnClose={true}
      onCancel={onCancel}
      confirmLoading={confirmLoading}
      onOk={(e) => {
        setConfirmLoading(true);
        const afterSave = props.afterSave;
        return form
          .validateFields()
          .then((values) => {
            return GraphQLService(
              `
            mutation createReport($data: ReportCreateInput) {
              createReport(data: $data) {
                slug
                tiles {
                  slug
                }
              }
            }
            `,
              {
                data: {
                  name: values.name,
                  type: "QUESTION",
                  tiles: {
                    create: {
                      name: (values as any).name,
                      type: "chart",
                      analysisType: analysisType,
                      chartType: chartType,
                      content: JSON.stringify({
                        ...query,
                        dateRange: convertWlyDatePickerValueToString(
                          query.dateRange
                        ),
                      }),
                      width: 0,
                      height: 0,
                      top: 0,
                      left: 0,
                      deleted: false,
                      org: { connect: { id: org.id } },
                      exploration: { connect: { id: explorationId } },
                    },
                  },
                  folder: { connect: { id: values.folderId } },
                  deleted: false,
                  org: { connect: { id: org.id } },
                },
              }
            ).then((r) => {
              track("Report Created", {
                type: "QUESTION",
              });
              const redirect = () => {
                setConfirmLoading(false);
                history.push(
                  routeDescriptor.reportTileEdit.renderRoute({
                    ...params,
                    reportSlug: r.createReport.slug,
                    tileSlug: r.createReport.tiles[0].slug,
                  })
                );
              };
              if (
                afterSave &&
                props.currentReportId === (values as any).reportId
              ) {
                afterSave().then(redirect);
              }
              redirect();
            });
          })
          .catch((info) => {
            setConfirmLoading(false);
            console.error("Validate Failed:", info);
          });
      }}
    >
      {renderInnerModal()}
    </Modal>
  );
};

export default compose<Props, IAddTileToQuestionProps>(
  WithOrg,
  withRouter
)(AddTileToQuestion);
