import { ExclamationCircleFilled } from "@ant-design/icons";
import { Modal, Space } from "antd";

type Props = {
  open: boolean;
  onCancel: () => void;
  onOk: () => void;
};

export default function DeleteObjectViewModal({ open, onCancel, onOk }: Props) {
  return (
    <Modal
      title={
        <Space>
          <ExclamationCircleFilled style={{ color: "#FAAD14" }} /> Delete view
        </Space>
      }
      open={open}
      onOk={onOk}
      onCancel={onCancel}
      okText="Delete"
      cancelText="Cancel"
      okButtonProps={{
        danger: true,
      }}
      width={420}
    >
      <p>
        You are about to delete this view, this cannot be undone. Do you want to
        proceed ?
      </p>
    </Modal>
  );
}
