import { MoreOutlined } from "@ant-design/icons";
import type { MenuProps } from "antd";
import { Button, Dropdown, Grid, Typography } from "antd";
import moment from "moment";
import React, { useState } from "react";
import type { RouteComponentProps } from "react-router";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import type { InjectedOrgProps } from "../../../containers/orgs/WithOrg";
import WithOrg from "../../../containers/orgs/WithOrg";
import { UPDATE_QUERY } from "../../../containers/reports/view/domain";
import { hasRoleAccessBoolean } from "../../../containers/user-settings/HasRoleAccess";
import { canEditFromViewSize } from "../../../containers/user-settings/domain";
import type { IReport } from "../../../interfaces/reports";
import { IUserRoleType } from "../../../interfaces/user";
import { routeDescriptor } from "../../../routes/routes";
import GraphQLService from "../../../services/graphql/GraphQLService";
import type { InjectedAntUtilsProps } from "../../ant-utils/withAntUtils";
import { withAntUtils } from "../../ant-utils/withAntUtils";
import { compose } from "../../compose/WlyCompose";
import UserAvatar from "../../user/avatar/UserAvatar";
import ReportDuplicate from "../ReportDuplicate";
import ReportRename from "../ReportRename";
import { WlyDashboardIcon } from "../icons/DashboardIcon";
import { WlyQuestionIcon } from "../icons/QuestionIcon";

import { BORDER_COLOR } from "../../../containers/v2-demo/container/layout/domain";
import { IconHolder } from "../../icons/holder/IconHolder";
import "./ReportCard.scss";

const { useBreakpoint } = Grid;
interface IReportCardProps {
  report: IReport;
  onUpdate: () => void;
  forwardRef?: any;
  style?: React.CSSProperties;
}

type Props = InjectedOrgProps &
  InjectedAntUtilsProps &
  IReportCardProps &
  RouteComponentProps<{ organizationSlug: string; folderSlug?: string }>;

function ReportCard(props: Props) {
  const { antUtils, report, user, org, onUpdate, forwardRef, style } = props;
  const [isRenameVisible, setIsRenameVisible] = useState(false);
  const [isDuplicateVisible, setIsDuplicateVisible] = useState(false);

  const screens = useBreakpoint();
  const canEdit = canEditFromViewSize(screens);

  const deleteReport = async (id: string, onDelete: () => void) => {
    try {
      return antUtils.modal.confirm({
        title: `Delete this report?`,
        content: `You are about to delete this report. This cannot be undone. Do you wish to proceed?`,
        okText: "Delete",
        okButtonProps: {
          danger: true,
        },
        cancelText: "Cancel",
        onOk: async () => {
          await GraphQLService(UPDATE_QUERY, {
            id: id,
            data: {
              deleted: true,
            },
          });
          antUtils.message.success("Report deleted");
          if (onDelete && typeof onDelete === "function") return onDelete();
          return true;
        },
      });
    } catch (error) {
      antUtils.message.error("Error while deleting report");
      return console.error(error);
    }
  };

  const menuItems: MenuProps["items"] = [
    {
      key: 0,
      disabled: !(
        hasRoleAccessBoolean(IUserRoleType.BUILDER, user, org.id) ||
        (hasRoleAccessBoolean(IUserRoleType.EDITOR, user, org.id) &&
          report.createdBy?.id === user.id)
      ),
      onClick: (e) => {
        e.domEvent.preventDefault();
        setIsRenameVisible(true);
      },
      label: "Rename",
    },
    {
      key: 1,
      disabled: !hasRoleAccessBoolean(IUserRoleType.EDITOR, user, org.id),
      onClick: (e) => {
        e.domEvent.preventDefault();
        setIsDuplicateVisible(true);
      },
      label: "Duplicate",
    },
    {
      key: 2,
      disabled: !(
        hasRoleAccessBoolean(IUserRoleType.BUILDER, user, org.id) ||
        (hasRoleAccessBoolean(IUserRoleType.EDITOR, user, org.id) &&
          report.createdBy?.id === user.id)
      ),
      onClick: (e) => {
        e.domEvent.preventDefault();
        deleteReport(report.id, onUpdate);
      },
      danger: true,
      label: "Delete",
    },
  ];

  const renderLogo = () => {
    switch (report.type) {
      case "DASHBOARD":
        return (
          <IconHolder
            size={20}
            background="#BE98C6"
            color="#F9F8F8"
            icon={<WlyDashboardIcon />}
          />
        );
      case "QUESTION":
        return (
          <IconHolder
            size={20}
            background="#69A2B0"
            color="#F9F8F8"
            icon={<WlyQuestionIcon />}
          />
        );
    }
  };

  return (
    <>
      <Link
        to={routeDescriptor.report.renderRoute({
          organizationSlug: org.slug,
          reportSlug: report.slug,
        })}
        className="report-card-wrapper"
        style={style}
      >
        <div
          ref={forwardRef}
          style={{ borderColor: BORDER_COLOR }}
          className="report-card"
        >
          <div className="logo">{renderLogo()}</div>
          <div className="title">
            <Typography.Text style={{ marginBottom: 0 }}>
              {report.name}
            </Typography.Text>
          </div>
          <div className="sources">
            <Typography.Text
              style={{ marginBottom: 0, fontSize: "0.9em" }}
              type="secondary"
            >
              {moment(
                report.updatedAt ? report.updatedAt : report.createdAt
              ).fromNow()}{" "}
            </Typography.Text>
            <UserAvatar
              user={report.updatedBy ? report.updatedBy : report.createdBy}
              size={"small"}
              tooltip
            />
          </div>
          <div
            className={`actions ${
              hasRoleAccessBoolean(IUserRoleType.EDITOR, user, org.id) &&
              report.canBeEditedByCurrentUser &&
              canEdit
                ? ""
                : "hidden"
            }`}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            <Dropdown
              menu={{ items: menuItems }}
              trigger={["click"]}
              placement="bottomRight"
            >
              <Button
                type="text"
                size="small"
                shape="circle"
                icon={<MoreOutlined />}
              />
            </Dropdown>
          </div>
        </div>
      </Link>
      <ReportRename
        visible={isRenameVisible}
        initialData={{
          id: report.id,
          name: report.name,
        }}
        onSave={(e) => {
          onUpdate();
          setIsRenameVisible(false);
        }}
        onCancel={() => setIsRenameVisible(false)}
      />
      <ReportDuplicate
        visible={isDuplicateVisible}
        initialData={{
          id: report.id,
          name: [report.name, "(copy)"].join(" "),
        }}
        onSave={(e) => {
          onUpdate();
          setIsDuplicateVisible(false);
        }}
        onCancel={() => setIsDuplicateVisible(false)}
      />
    </>
  );
}

export default compose<Props, IReportCardProps>(
  WithOrg,
  withRouter,
  withAntUtils
)(ReportCard);
