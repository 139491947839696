import color from "color";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { inject } from "mobx-react";
import moment from "moment";
import * as React from "react";
import { withRouter, type RouteComponentProps } from "react-router";
import type { InjectedOrgProps } from "../../../../containers/orgs/WithOrg";
import WithOrg from "../../../../containers/orgs/WithOrg";
import type { IObjectPropertyActivitiesFormatterConfig } from "../../../../interfaces/object";
import { routeDescriptor } from "../../../../routes/routes";
import type { WorkspaceDatatoreProps } from "../../../../store/dataStores/workspace/workspaceDatastore";
import { localizeHighchart } from "../../../chart/domain";
import {
  optionsHelper,
  renderSerieTooltip,
} from "../../../chart/utils/optionsHelper";
import { compose } from "../../../compose/WlyCompose";
import { useHover } from "../../../hooks/useHover";
import { computeMax, computeMin } from "./ActivityCellUtils";
import "./ActivityChartCellRenderer.scss";

interface IActivityChartCellRendererProps {
  height: number;
  config: IObjectPropertyActivitiesFormatterConfig;
  values: {
    [key: string]: Array<{ value: number; linkTo?: string | undefined }>;
  };
}

type Props = IActivityChartCellRendererProps &
  InjectedOrgProps &
  RouteComponentProps &
  WorkspaceDatatoreProps;

const getTimestampAtIndex = (index: number, length: number) => {
  return moment()
    .subtract(length - index, "days")
    .valueOf();
};

function ActivityChartCellRenderer(props: Props) {
  const { height, values, config, history, org, workspaceDatastore, user } =
    props;
  const [hoverRef, isHover] = useHover<HTMLDivElement>();
  const ref = React.useRef<HighchartsReact.RefObject>(null);

  // the plotband array is an array that works as follow:
  // 000011110000111
  // each 1 highlights a plot band value, if they are adjascent they must be merged
  React.useEffect(() => {
    if (!isHover) {
      ref.current?.chart?.tooltip?.destroy?.();
    }
  }, [isHover]);

  const defaultOptions = optionsHelper(height, 100, user.locale);

  const getPlotBand = (data: number[], color: string) => {
    const plots: Array<[number, number]> = [];

    const extractPlots = (
      initialPlots: Array<number>,
      startIndex: number = 0
    ) => {
      const p = [...initialPlots].slice(startIndex);
      const start = p.findIndex((a) => a === 1);
      if (start < 0) {
        return null;
      }
      // we have a start let's find an end
      const subP = [...p].slice(start);
      const end = subP.findIndex((a) => a === 0);
      if (end < 0) {
        // there is no 0 left so the end is the end of the array
        plots.push([startIndex + start, startIndex + p.length - 1]);
        return null;
      }
      // we have an end
      plots.push([startIndex + start, startIndex + start + end - 1]);
      return extractPlots(initialPlots, startIndex + start + end);
    };

    extractPlots(data);
    return plots.map((p) => ({
      color: color, // Color value
      from: getTimestampAtIndex(p[0], 181), // Start of the plot band
      to: getTimestampAtIndex(p[1], 181), // End of the plot band
    }));
  };

  const plotbands = config.config.series
    .filter((s) => s.type === "band")
    .flatMap((s) => getPlotBand(values[s.key] as any, s.color));

  const series: Highcharts.Options["series"] = config.config.series
    .filter((serie) => serie.type === "bubble")
    .flatMap((serie) => {
      const isSerieDrillable = !!serie.drillObjectId;
      const serieColor = color(serie.color).darken(0.2).hex();
      const formattedData = (values[serie.key] || [])
        .map((value, index, orginalArray) => {
          const isDrillable = isSerieDrillable && !!value.linkTo;
          return {
            x: getTimestampAtIndex(index, orginalArray.length),
            y: 0,
            color: serieColor,
            className: isDrillable ? "pointer" : undefined,
            events: {
              click: () => {
                const foreignObject =
                  workspaceDatastore.data.status === "success"
                    ? (workspaceDatastore.data.data.allObjects ?? []).find(
                        (obj) => obj.id === serie.drillObjectId
                      )
                    : undefined;
                if (isDrillable && foreignObject?.slug) {
                  history.push(
                    routeDescriptor["object-record-centered"].renderRoute({
                      organizationSlug: org.slug,
                      objectSlug: foreignObject.slug,
                      recordId: value.linkTo,
                    })
                  );
                } else {
                  return undefined;
                }
              },
            },
            value: getTimestampAtIndex(index, orginalArray.length),
            z: value.value,
          };
        })
        .filter((a) => a.z !== 0);

      return {
        type: "bubble",
        maxSize: "80%",
        name: serie.label,
        data: formattedData,
        pointInterval: 3600000,
        color: serieColor,
        tooltip: renderSerieTooltip({
          formatter: { format: "NUMBER", customFormatting: "'0.[0]a'" },
          valueGetter: "this.z",
          hideSerieColor: false,
          hideHeader: false,
          data: formattedData as any,
          locale: user.locale,
          seriesLength: 1,
        }),
        marker: {
          fillColor: serieColor,
          lineWidth: 0,
          lineColor: undefined,
        },
      };
    });

  const options: Highcharts.Options = {
    chart: {
      reflow: false,
      plotBorderWidth: 0,
      height: height,
      marginTop: 0,
      marginRight: 0,
      marginLeft: 0,
      marginBottom: 0,
      borderWidth: 0,
      backgroundColor: "transparent",
      type: "bubble",
    },

    legend: {
      enabled: false,
    },
    title: {
      text: undefined,
    },
    credits: {
      enabled: false,
    },
    xAxis: {
      type: "datetime",
      title: {
        text: null,
      },
      labels: {
        enabled: false,
      },
      startOnTick: false,
      endOnTick: false,
      min: computeMin(config),
      max: computeMax(config),
      gridLineWidth: 0,
      lineWidth: 0,
      minorGridLineWidth: 0,
      tickLength: -height,
      tickColor: "#E6E6E6",
      plotBands: plotbands,
    },
    yAxis: {
      startOnTick: false,
      endOnTick: false,
      title: {
        text: null,
      },
      labels: {
        enabled: false,
      },
      minorTickLength: 0,
      tickLength: 0,
      lineWidth: 0,
    },
    plotOptions: {
      bubble: {
        sizeByAbsoluteValue: true,
      },
      series: {
        animation: false,
        dataLabels: {
          enabled: false,
        },
        enableMouseTracking: true,
        stickyTracking: false,
      },
    },
    tooltip: {
      ...defaultOptions.tooltip,
      animation: false,
      shared: true,
      outside: true,
      hideDelay: 0,
      snap: 0,
    },
    series: series,
  };

  localizeHighchart(Highcharts, user.locale);

  return (
    <div
      style={{ height, width: "100%" }}
      ref={hoverRef}
      className="activity-chart-cell object-table-show-crosshair"
    >
      <HighchartsReact ref={ref} highcharts={Highcharts} options={options} />
    </div>
  );
}

export default compose<Props, IActivityChartCellRendererProps>(
  WithOrg,
  withRouter,
  inject("workspaceDatastore")
)(ActivityChartCellRenderer);
