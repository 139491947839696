import Markdoc from "@markdoc/markdoc";
import { Typography } from "antd";
import React from "react";
import { getObjectColumns } from "../../../../../object/domain";
import type { IWidgetEmailRendererProps } from "../../domain";
import type { IEmailWidgetTextConfig } from "../domain";
import { getDefaultConfig, getFormattedRecord, getParsedDoc } from "../domain";

interface IEmailWidgetTextRendererProps
  extends IWidgetEmailRendererProps<IEmailWidgetTextConfig> {}

export function EmailWidgetTextRenderer(props: IEmailWidgetTextRendererProps) {
  const { conf, record, object, user } = props;

  const defaults = getDefaultConfig(conf, record);
  const align = defaults.align;
  const source = defaults.content as string;
  const columns = getObjectColumns(object);

  if (!source) {
    return (
      <Typography.Text type="secondary">
        Please configure this widget
      </Typography.Text>
    );
  }

  const formattedRecord = getFormattedRecord(columns, record);

  const content = getParsedDoc(source, formattedRecord, user, columns);

  const html = Markdoc.renderers.react(content, React);

  return (
    <div
      style={{
        textAlign: align,
      }}
    >
      {html}
    </div>
  );
}
