import { gql } from "@apollo/client";
import type { MutateOptions } from "../../../../../../components/hooks/query/useQGLMutate";
import { useGQLMutate } from "../../../../../../components/hooks/query/useQGLMutate";
import { OBJECT_VIEW_FRAGMENT } from "../../../../../../fragments/object";

type MutationData = { updateObjectView: { id: string } };

type MutationVariables = {
  id: string;
  data: Record<string, any>;
};

const UpdateObjectViewQuery = gql`
  ${OBJECT_VIEW_FRAGMENT}
  mutation UpdateObjectView($id: ID!, $data: ObjectViewUpdateInput!) {
    updateObjectView(id: $id, data: $data) {
      ...ObjectViewFragment
      createdBy {
        id
      }
    }
  }
`;

export const useUpdateObjectView = (options?: MutateOptions<MutationData>) => {
  return useGQLMutate<MutationData, MutationVariables>(
    UpdateObjectViewQuery,
    options
  );
};
