import { inject, observer } from "mobx-react";
import * as React from "react";
import type { RouteComponentProps } from "react-router";
import { withRouter } from "react-router";
import type { InjectedAntUtilsProps } from "../../../components/ant-utils/withAntUtils";
import { withAntUtils } from "../../../components/ant-utils/withAntUtils";
import { compose } from "../../../components/compose/WlyCompose";
import type { IActionMeta } from "../../../interfaces/actions";
import { routeDescriptor } from "../../../routes/routes";
import GraphQLService from "../../../services/graphql/GraphQLService";
import type { UserStoreProps } from "../../../store/userStore";
import type { InjectedOrgProps } from "../../orgs/WithOrg";
import WithOrg from "../../orgs/WithOrg";
import ItemConnectionPage from "../connection/ItemConnectionPage";
import type { ItemValueInput } from "../connection/steps/step1/ItemConnectionStep1";
import { actionStep2, createActionAndRedirect } from "../domain";

interface IActionConnectionPageProps {}

type Props = IActionConnectionPageProps &
  RouteComponentProps<{ organizationSlug: string; itemSlug: string }> &
  UserStoreProps &
  InjectedOrgProps &
  InjectedAntUtilsProps;

// As we don't have the ActionMeta+ActionOption stored into the database, we don't directly connect the ActionValue to the Actionoption with IDs as we do with other catalogs (Source, Catalog)
// Instead, we store the optionId as a string
const generateActionValueFromInput = (
  itemMeta: IActionMeta,
  v: { [key: string]: any },
  orgId: string
): ItemValueInput[] => {
  const itemInputs: ItemValueInput[] = itemMeta.options.map((k) => {
    const val = v[k.key] ? v[k.key] : k.defaultValue ? k.defaultValue : "";
    return {
      value: val as string,
      option: k.id,
      org: {
        connect: {
          id: orgId,
        },
      },
    };
  });
  return itemInputs;
};

class ActionConnectionPage extends React.Component<Props> {
  public render() {
    const {
      history,
      match: { params },
      antUtils,
    } = this.props;

    const redirectUri = routeDescriptor.settingsActionManagement.renderRoute({
      ...params,
    });

    return (
      <ItemConnectionPage
        allItemsMetaQuery={`
        query getActionMeta($slug: String!) {
          allItemMetas: allActionMetas(where: { slug: $slug }) {
            id
            slug
            publicInfo {
              name
              label
              tagline
              website
              logo
              description
            }
            options {
              id
              slug
              key
              type
              options
              description
              label
              editable
              defaultValue
              encrypt
              hidden
            }
          }
        }
        `}
        remainingItemValuesQuery={`
        query getRemainingActionValues(
          $itemId: ID!
        ) {
            allItemValue: allActionValues(where:{ action: {id: $itemId}}) {
              id
              value
              option: optionVirtual {
                id
                slug
                key
                type
                options
                description
                label
                editable
                defaultValue
                encrypt
                hidden
              }
            }
        }
        `}
        step1CreationPromise={(
          name: string,
          orgId: string,
          itemMeta: IActionMeta,
          inputs: ItemValueInput[]
        ) => {
          return createActionAndRedirect(name, orgId, itemMeta, inputs, (s) => {
            const {
              match: { params },
              history,
            } = this.props;
            history.push(
              routeDescriptor.actionConnectionStep2.renderRoute({
                ...params,
                itemId: s.id,
              })
            );
            return Promise.resolve();
          });
        }}
        inputValidationPromise={(v) => {
          return Promise.resolve();
        }}
        step2CreationPromise={async (
          itemId,
          itemValueUpdateInput,
          rawValues
        ) => {
          try {
            const data = await GraphQLService(
              `
              query validateActionForm($orgId: ID!, $actionId: ID!, $slug: String!, $data: JSON) {
                getActionForm(orgId: $orgId,actionId: $actionId, slug: $slug, data: $data)
              }
              `,
              {
                orgId: this.props.org.id,
                slug: this.props.match.params.itemSlug,
                data: rawValues,
                actionId: itemId,
              }
            );

            if (data.getActionForm.error) {
              throw new Error(data.getActionForm.error);
            }

            await actionStep2(itemId, itemValueUpdateInput);
            antUtils.message.success(
              `Action has been successfully installed!`,
              4
            );
            history.push(redirectUri);
          } catch (err) {
            console.error(err, this.props.match.params);
            antUtils.message.error(err.message);
          }
        }}
        overrideGenerateItemValueFromInput={generateActionValueFromInput}
        redirectUri={redirectUri}
      />
    );
  }
}

export default compose<Props, IActionConnectionPageProps>(
  withRouter,
  WithOrg,
  withAntUtils
)(inject("userStore")(observer(ActionConnectionPage)));
