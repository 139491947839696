import type {
  BinaryFilter,
  BinaryOperator,
  UnaryFilter,
} from "@cubejs-client/core";
import type {
  AvailableDimension,
  IAvailableDimensionGroup,
} from "../../../../../../../../components/measures/filter-item/FilterItem";
import { QueryBuilderFilter } from "../../../../../../../exploration/single/visualization/query-builder/query-builder-items/QueryBuilderFilter";
import type { IFilterEditorValue } from "../domain";
import { type DataType } from "../../../../../../../../interfaces/transformations";

interface IFilterEditorProps {
  onChange?: (value: IFilterEditorValue) => void;
  value?: IFilterEditorValue;
  availableDimensions: Array<AvailableDimension | IAvailableDimensionGroup>;
  autocomplete: (
    dimensionName: string,
    operator: BinaryOperator,
    value?: string
  ) => Promise<string[]>;
  valueSubstitutionColumns?: Array<{
    key: string;
    domain: DataType;
    label: string;
  }>;
}

export function FilterEditor(props: IFilterEditorProps) {
  const {
    availableDimensions,
    autocomplete,
    value,
    onChange,
    valueSubstitutionColumns,
  } = props;
  const operator: "and" | "or" = value?.operator ? value?.operator : "and";
  const filters: (UnaryFilter | BinaryFilter)[] = value?.filters
    ? value?.filters
    : [];

  return (
    <div>
      <QueryBuilderFilter
        availableDimensions={availableDimensions}
        autocomplete={autocomplete}
        filterOperator={operator}
        filters={filters}
        valueSubstitutionColumns={valueSubstitutionColumns}
        setFilters={(filters) =>
          onChange?.({ operator: operator, filters: filters })
        }
        setFilterOperator={(filterOperator) =>
          onChange?.({ operator: filterOperator, filters: filters })
        }
      />
    </div>
  );
}
