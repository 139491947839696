import { SearchOutlined } from "@ant-design/icons";
import { Button, Input, Typography } from "antd";
import * as React from "react";
import type { RouteComponentProps } from "react-router-dom";
import { Link, withRouter } from "react-router-dom";
import { compose } from "../../../components/compose/WlyCompose";
import Aligner from "../../../components/layout/aligner/Aligner";
import Error from "../../../components/layout/feedback/error";
import Feednack from "../../../components/layout/feedback/feedback";
import Loading from "../../../components/layout/feedback/loading";
import SourceTableList from "../../../components/spreadsheet/SourceTableSelectionList";
import type { IDataset, ISource } from "../../../interfaces/sources";
import GraphQl from "../../graphql/graphql";
import type { InjectedOrgProps } from "../../orgs/WithOrg";
import WithOrg, { getCurrentWarehouse } from "../../orgs/WithOrg";

import cuid from "cuid";
import type { SchemaResult } from "../../../interfaces/transformations";
import type { IView } from "../../../interfaces/view";
import { computeTransformations } from "../../../services/BrizoService";
import type {
  IDimensionFormInput,
  IMetricFormInput,
  ISemanticGroupFormInput,
} from "../../workbench/workbench/exploration/domain";
import ViewSelector from "../views/ViewSelector";
import "./InitialDatasetSelection.scss";
import SchemaSelectionStep from "./RelatedData/SchemaSelectionStep";

const { Text } = Typography;

interface ISelectTableStep3BProps {
  onSelect: (payload: {
    explorationName: string;
    dataset: IDataset;
    view?: IView;
    blendedDatasets?: IDataset[];
    metrics: IMetricFormInput[];
    dimensions: IDimensionFormInput[];
    semanticGroups: ISemanticGroupFormInput[];
  }) => void;
}

type IState = {
  step: IDatasetSelection | IViewSelection | ISchemaSelection | INameSelection;
};

interface IDatasetSelection {
  step: "DATASET_SELECTION";
  data: {
    search?: string;
  };
}

interface IViewSelection {
  step: "VIEW_SELECTION";
  data: {
    selectedDataset: IDataset;
  };
}

interface ISchemaSelection {
  step: "SCHEMA_SELECTION";
  data: {
    selectedDataset: IDataset;
    viewId: string;
    name: string;
  };
}

interface INameSelection {
  step: "NAME_SELECTION";
  data: {
    selectedDataset: IDataset;
    viewId: string;
    name: string;
  };
}

const GQL = `
query getSyncedTables(
  $id: ID!,
  $warehouseId: ID!
) {
  allSources(where: {AND: [{org: { id: $id}, isDeleted_not: true, warehouse: { id: $warehouseId }}]}, sortBy: name_ASC) {
    id
    name
    datasets(where: { org: { id: $id}, deleted_not: true, hideFromInterface_not: true }, sortBy: name_ASC) {
      id
      name
      warehouseTableId
      warehouseSchemaId
      primaryKey
      managedBy
      views(where: { org: { id: $id}, deleted_not: true }, sortBy: name_ASC) {
        id
        name
        default
      }
    }
    sourceMeta {
      publicInfo {
        name
        logo
      }
    }
  }
  allModels: allDatasets(where: { org: { id: $id}, warehouse: { id: $warehouseId }, isModel: true, deleted_not: true, hideFromInterface_not: true }) {
    id
    name
    warehouseTableId
    warehouseSchemaId
    primaryKey
    managedBy
    views(where: { org: { id: $id}, deleted_not: true }, sortBy: name_ASC) {
      id
      name
      default
    }
  }
}
`;

type Props = ISelectTableStep3BProps &
  InjectedOrgProps &
  RouteComponentProps<{ warehouseSlug?: string }>;

type GQLQueryResult = { allSources: ISource[]; allModels: IDataset[] };

class SelectTableStep3B extends React.Component<Props, IState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      step: {
        step: "DATASET_SELECTION",
        data: {},
      },
    };
  }

  public fetchSchema =
    (warehouseId: string) => (datasetId: string, viewId: string) => {
      const id = cuid();
      return computeTransformations(warehouseId, {
        tableSchema: [
          {
            var: id,
            domain: "viewResolver",
            operation: {
              type: "Table.FromWhalyDataset",
              args: {
                datasetId: datasetId,
                viewId,
              },
            },
          },
          {
            var: cuid(),
            domain: "dataset",
            operation: {
              type: "Table.Schema",
              args: {
                table: id,
              },
            },
          },
        ],
      }).then((r) => {
        return [r.data.tableSchema] as SchemaResult[];
      });
    };

  public render() {
    const { org, onSelect, match } = this.props;
    const { step } = this.state;

    const currentWarehouse = getCurrentWarehouse(
      org,
      match.params.warehouseSlug
    );

    return (
      <div className="select-table-step-3_B">
        <GraphQl<GQLQueryResult>
          query={GQL}
          variables={{ id: org.id, warehouseId: currentWarehouse.id }}
        >
          {(gql) => {
            if (gql.status === "initial" || gql.status === "loading") {
              return (
                <Aligner>
                  <Loading />
                </Aligner>
              );
            }
            if (gql.status === "error") {
              return (
                <Aligner>
                  <Error />
                </Aligner>
              );
            }
            if (
              gql.data.allSources.length === 0 &&
              gql.data.allModels.length === 0
            ) {
              return (
                <Aligner>
                  <Feednack>
                    <div>
                      <div>
                        You don't have any source connected. Start by connecting
                        one.
                      </div>
                      <div>
                        <Link to={`/${org.slug}/catalog/sources`}>
                          <Button type="primary">Connect a source</Button>
                        </Link>
                      </div>
                    </div>
                  </Feednack>
                </Aligner>
              );
            }

            if (step.step === "DATASET_SELECTION") {
              return (
                <div className="select-table-step-3_B-content">
                  <Input
                    className="search"
                    size="large"
                    placeholder="Search for any dataset name..."
                    suffix={<SearchOutlined />}
                    onChange={(e) => {
                      this.setState({
                        step: {
                          step: "DATASET_SELECTION",
                          data: { search: e.target.value },
                        },
                      });
                    }}
                  />
                  <SourceTableList
                    onSelect={(dataset) =>
                      dataset.views.length > 1
                        ? this.setState({
                            step: {
                              step: "VIEW_SELECTION",
                              data: { selectedDataset: dataset },
                            },
                          })
                        : this.setState({
                            step: {
                              step: "NAME_SELECTION",
                              data: {
                                selectedDataset: dataset,
                                viewId: dataset.views[0].id,
                                name: "",
                              },
                            },
                          })
                    }
                    models={gql.data.allModels}
                    sources={gql.data.allSources}
                    search={step.data.search}
                  />
                </div>
              );
            }

            if (step.step === "VIEW_SELECTION") {
              return (
                <div className="select-table-step-3_B-content">
                  <div className="view-selector">
                    <ViewSelector
                      views={step.data.selectedDataset.views.map((v) => {
                        return {
                          key: v.id,
                          label: v.name,
                          editable: false,
                          viewQueryCursor: "",
                          query: [],
                          usedInExplorations: [],
                          viewCubeName: v.cubeName,
                          drills: [],
                        };
                      })}
                      selectedViewId={
                        step.data.selectedDataset.views.find(
                          (v) => v.default === true
                        )
                          ? step.data.selectedDataset.views.find(
                              (v) => v.default === true
                            )!.id
                          : undefined
                      }
                      onSelectView={(viewId) =>
                        step.data.selectedDataset.views.find(
                          (vi) => vi.id === viewId
                        ) &&
                        this.setState({
                          step: {
                            step: "NAME_SELECTION",
                            data: {
                              selectedDataset: step.data.selectedDataset,
                              viewId: step.data.selectedDataset.views.find(
                                (vi) => vi.id === viewId
                              )?.id,
                              name: "",
                            },
                          },
                        })
                      }
                    >
                      <div style={{ paddingBottom: 12 }}>
                        Which view do you want to base you exploration on?
                      </div>
                    </ViewSelector>
                  </div>
                </div>
              );
            }

            if (step.step === "NAME_SELECTION") {
              return (
                <div className="select-table-step-3_B-content">
                  <div style={{ paddingBottom: 6 }}>
                    <Text strong>Exploration Name</Text>
                  </div>
                  <div>
                    <Input
                      className="search"
                      size="large"
                      placeholder="Give your exploration a name..."
                      onChange={(e) => {
                        this.setState({
                          step: {
                            step: "NAME_SELECTION",
                            data: { ...step.data, name: e.target.value },
                          },
                        });
                      }}
                    />
                  </div>
                  <div style={{ display: "flex" }}>
                    <div style={{ flex: 1 }}>
                      <Button
                        type="text"
                        onClick={() =>
                          this.setState({
                            step: { step: "DATASET_SELECTION", data: {} },
                          })
                        }
                      >
                        Back
                      </Button>
                    </div>
                    <div style={{ flex: 0 }}>
                      <Button
                        onClick={() =>
                          this.setState({
                            step: {
                              step: "SCHEMA_SELECTION",
                              data: { ...step.data },
                            },
                          })
                        }
                        type="primary"
                      >
                        Next
                      </Button>
                    </div>
                  </div>
                </div>
              );
            }

            const fetchSchema = this.fetchSchema(currentWarehouse?.id);

            return (
              <div className="select-table-step-3_B-content">
                <SchemaSelectionStep
                  fetchSchema={() =>
                    fetchSchema(step.data.selectedDataset.id, step.data.viewId)
                  }
                  onSelect={async (m, d) =>
                    await onSelect({
                      explorationName: step.data.name,
                      dataset: step.data.selectedDataset,
                      view: step.data.selectedDataset.views.find(
                        (v) => v.id === step.data.viewId
                      ),
                      metrics: m,
                      dimensions: d,
                      semanticGroups: [],
                    })
                  }
                />
              </div>
            );
          }}
        </GraphQl>
      </div>
    );
  }
}

export default compose<Props, ISelectTableStep3BProps>(
  WithOrg,
  withRouter
)(SelectTableStep3B);
