import Icon from "@ant-design/icons";
import type { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";

export const WlyRadarIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon
    component={() => (
      <svg
        fill="currentColor"
        width="1em"
        height="1em"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          d="M20.9450712,13 L18.9291111,13 C18.4438815,16.3922941 15.5264719,19 12,19 C8.13400675,19 5,15.8659932 5,12 C5,8.13400675 8.13400675,5 12,5 C13.9329966,5 15.6829966,5.78350169 16.9497475,7.05025253 L15.5355339,8.46446609 C14.6307119,7.55964406 13.3807119,7 12,7 C9.23857625,7 7,9.23857625 7,12 C7,14.7614237 9.23857625,17 12,17 C14.4189579,17 16.4367116,15.2822403 16.8999819,13 L14.8292943,13 C14.4174579,14.1651924 13.3062188,15 12,15 C10.3431458,15 9,13.6568542 9,12 C9,10.3431458 10.3431458,9 12,9 C12.8284271,9 13.5784271,9.33578644 14.1213203,9.87867966 L13,11 L23,11 L23,13 L22.955157,13 C22.4499282,18.6065 17.7380426,23 12,23 C5.92486775,23 1,18.0751322 1,12 C1,5.92486775 5.92486775,1 12,1 C15.0375661,1 17.7875661,2.23121694 19.7781746,4.22182541 L18.363961,5.63603897 C16.7352814,4.00735931 14.4852814,3 12,3 C7.02943725,3 3,7.02943725 3,12 C3,16.9705627 7.02943725,21 12,21 C16.6325537,21 20.4476269,17.4999505 20.9450712,13 Z"
          transform="matrix(0 -1 -1 0 24 24)"
        />
      </svg>
    )}
    {...props}
  />
);
