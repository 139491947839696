import type { IObject } from "../../../../../../interfaces/object";
import type { IOrg } from "../../../../../../interfaces/org";
import type { IUser } from "../../../../../../interfaces/user";
import type { IRecordWidgetData, IWidget } from "../domain";
import { rendererConfig } from "./rendererConfig";

interface IEmailWidgetRendererProps {
  object: IObject;
  widget: IWidget;
  record: IRecordWidgetData;
  org: IOrg;
  user: IUser;
  edit?: boolean;
}

export function EmailWidgetRenderer(props: IEmailWidgetRendererProps) {
  const { widget, object, record, edit, org, user } = props;

  const renderer = rendererConfig[widget.type];

  const conf = renderer.parseConfig(widget, object);

  if (record.status === "loading") {
    return "loading...";
  }

  return renderer.renderComponent(
    widget,
    object,
    record.data,
    conf,
    org,
    user,
    edit
  );
}
