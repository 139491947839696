import { CheckOutlined, ExclamationCircleTwoTone } from "@ant-design/icons";
import { Tooltip, Typography } from "antd";
import { WlyCaretDownIcon } from "../../../components/icons/custom-icons/WlyCaretDownIcon";
import type { IDestination } from "../../../interfaces/destinations";

interface IWarehouseRendererProps {
  destination: IDestination;
  showCaret?: boolean;
  selected?: boolean;
  strong?: boolean;
  secondary?: boolean;
}

export function WarehouseRenderer(props: IWarehouseRendererProps) {
  const { showCaret, selected, destination, secondary, strong } = props;
  const wrapInError = (r: any) => {
    if (!destination.isBiEnabled && !showCaret) {
      return (
        <Tooltip
          placement="left"
          title={
            "There is an issue with your warehouse connection. Please reconnect"
          }
        >
          {r}
        </Tooltip>
      );
    }
    return r;
  };

  return wrapInError(
    <div style={{ display: "flex", alignItems: "center" }}>
      <div
        style={{
          flex: "0 18px",
          width: 14,
          marginRight: 4,
          display: "flex",
        }}
      >
        <img
          style={{ maxHeight: "100%", maxWidth: "100%", margin: "auto" }}
          src={destination.destinationMeta.publicInfo.logo}
          alt={destination.name}
        />
      </div>
      <div style={{ flex: 1 }}>
        <Typography.Text
          ellipsis
          strong={strong}
          type={secondary ? "secondary" : undefined}
        >
          {destination.name}
        </Typography.Text>
      </div>
      {!destination.isBiEnabled ? (
        <div style={{ flex: 0, marginLeft: 4 }}>
          <ExclamationCircleTwoTone twoToneColor={"red"} />
        </div>
      ) : null}
      {showCaret ? (
        <div style={{ flex: 0, marginLeft: 4 /*transform: "scale(0.8)"*/ }}>
          <WlyCaretDownIcon style={{ color: "#939393" }} />
        </div>
      ) : null}
      {selected ? (
        <div
          style={{
            flex: "0",
            marginLeft: 6,
            transform: "scale(0.8)",
            color: "green",
          }}
        >
          <CheckOutlined />
        </div>
      ) : null}
    </div>
  );
}
