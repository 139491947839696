import { Form, InputNumber as AntNumberInput } from "antd";
import type { FormItemProps } from "antd/lib/form/FormItem";
import type { InputProps } from "antd/lib/input";
import type { FieldProps } from "formik";
import * as React from "react";

interface IInputNumberProps extends FieldProps {
  inputProps: InputProps;
  formItemProps: FormItemProps;
}

export default class InputNumber extends React.Component<IInputNumberProps> {
  public render() {
    const { inputProps, formItemProps, field, form } = this.props;

    const isSubmitting = form.isSubmitting;
    const touched = form.touched[field.name];
    const submitted = form.submitCount > 0;
    const hasError = form.errors[field.name];
    const submittedError = hasError && submitted;
    const touchedError = hasError && touched;
    const onInputChange = (value: number | string): void =>
      form.setFieldValue(field.name, value);
    const onBlur = () => form.setFieldTouched(field.name, true);

    return (
      <Form.Item
        label={formItemProps.label}
        hasFeedback={
          (formItemProps.hasFeedback && submitted) ||
          (formItemProps.hasFeedback && touched)
            ? true
            : undefined
        }
        help={submittedError || touchedError ? <>{hasError}</> : undefined}
        validateStatus={
          submittedError || touchedError
            ? "error"
            : isSubmitting
            ? "validating"
            : "success"
        }
        {...formItemProps}
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
      >
        <AntNumberInput
          {...field}
          {...inputProps}
          disabled={isSubmitting || inputProps.disabled}
          size="large"
          onBlur={onBlur}
          prefix={undefined}
          onInput={undefined}
          onChange={onInputChange}
        />
      </Form.Item>
    );
  }
}
