import { compose } from "../../../../../../../../../../components/compose/WlyCompose";
import { useLagoonQuery } from "../../../../../../../../../../components/hooks/query/useLagoonQuery";
import Feednack from "../../../../../../../../../../components/layout/feedback/feedback";
import Loading from "../../../../../../../../../../components/layout/feedback/loading";
import type { IObject } from "../../../../../../../../../../interfaces/object";
import type { UserLocale } from "../../../../../../../../../../interfaces/user";
import type { InjectedOrgProps } from "../../../../../../../../../orgs/WithOrg";
import WithOrg from "../../../../../../../../../orgs/WithOrg";
import type { IRecord } from "../../../../../../domain";
import type { IWidgetKPIConfig, kpiTheme } from "../../../domain";
import { getSparklineQuery } from "../../../domain";
import { TableSparklineRenderer } from "./TableSparklineRenderer";
import { useTableSparklineConfig } from "./useTableSparklineConfig";

interface ITableSparklineProps {
  theme: kpiTheme;
  object: IObject;
  record: IRecord;
  locale: UserLocale;
  conf: IWidgetKPIConfig;
}

type Props = ITableSparklineProps & InjectedOrgProps;

function TableSparkline(props: Props) {
  const { theme, object, record, conf, locale, org } = props;

  const query = getSparklineQuery({
    conf: conf,
    object: object,
    record: record,
  });

  const { data, loading, error } = useLagoonQuery(query, {
    objectType: "OBJECT",
    objectId: object.id,
    org: org,
    enabled: !!query,
  });

  const config = useTableSparklineConfig({ conf, object, query });

  if (!query || !config) {
    return <></>;
  }

  if (loading) {
    return (
      <Feednack>
        <Loading />
      </Feednack>
    );
  }

  if (error) {
    return (
      <Feednack>
        <>{error.message}</>
      </Feednack>
    );
  }

  return (
    <TableSparklineRenderer
      config={config}
      theme={theme}
      locale={locale}
      data={data ?? []}
    />
  );
}

export default compose<Props, ITableSparklineProps>(WithOrg)(TableSparkline);
