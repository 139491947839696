import type { IRecord } from "../../../domain";

export interface IEmailWidgetImageConfig {
  align?: "left" | "right" | "center";
  spacing?: "normal" | "compact";
  width?: number;
  src?: string;
  borderRadius?: number;
}

export const isConfInvalid = (
  conf: IEmailWidgetImageConfig,
  record: IRecord
) => {
  return !conf.src || !record[conf.src];
};

export const getDefaultConfig = (
  conf: IEmailWidgetImageConfig,
  record: IRecord
) => {
  const align = conf.align ? conf.align : "left";
  const width = conf.width ? conf.width : 50;
  const borderRadius = conf.borderRadius;
  const imageSrc = conf.src
    ? (record[conf.src] as string)
    : "https://placehold.co/400";
  const spacing = conf.spacing ? conf.spacing : "normal";

  return {
    align,
    width,
    borderRadius,
    imageSrc,
    spacing,
  };
};
