import { Avatar } from "antd";
import React, { useState } from "react";

type Props = {
  src?: string;
  fallback?: string;
};

export default function SuggestedProductAvatar({ src, fallback }: Props) {
  const [img, setImg] = useState<string | undefined>(src);

  React.useEffect(() => {
    setImg(src);
  }, [src]);

  return (
    <Avatar
      src={img}
      shape="square"
      size={160}
      style={{
        marginTop: -1,
        marginLeft: -1,
        position: "relative",
      }}
      onError={() => {
        if (fallback) {
          setImg(fallback);
        }
        return true;
      }}
    />
  );
}
