import { ArrowRightOutlined } from "@ant-design/icons";
import type { InputRef } from "antd";
import { Button, Col, Input, Row, Tag } from "antd";
import { inject, observer } from "mobx-react";
import moment from "moment";
import * as React from "react";
import type { RouteComponentProps } from "react-router-dom";
import { withRouter } from "react-router-dom";
import { compose } from "../../components/compose/WlyCompose";
import OrgAvatar from "../../components/orgs/Avatar/OrgAvatar";
import { CreateOrgModal } from "../../components/orgs/CreateOrg/CreateOrgModal";
import OrgListItem from "../../components/orgs/ListItem/OrgListItem";
import Subtitle from "../../components/typography/Subtitle";
import type { IOrg } from "../../interfaces/org";
import { IUserRealmType } from "../../interfaces/realm";
import { routeDescriptor } from "../../routes/routes";
import { track } from "../../services/AnalyticsService";
import type { BreadcrumbStoreProps } from "../../store/breadCrumbStore";
import type { UserStoreProps } from "../../store/userStore";
import { hasRealmAccessBoolean } from "../realm-settings/HasRealmAccess";
import "./Org.scss";

interface IOrgProps {}

type Props = IOrgProps &
  UserStoreProps &
  BreadcrumbStoreProps &
  RouteComponentProps;

interface IState {
  isCreateOrgModalOpen: boolean;
  search?: string;
}

class Org extends React.Component<Props, IState> {
  searchInput: React.RefObject<InputRef>;

  constructor(props: Props) {
    super(props);
    this.searchInput = React.createRef();
    this.state = {
      isCreateOrgModalOpen: false,
    };
  }

  public componentDidMount() {
    this.props.breadcrumbStore.setBreadcrumb([]);
    setTimeout(() => {
      this.searchInput?.current?.focus?.();
    }, 200);
    track("Org Viewed", {});
  }

  public render() {
    const { userStore } = this.props;
    const renderOrgStatus = (org: IOrg): JSX.Element => {
      switch (org.isDeleted) {
        case true:
          return <Tag color="red">Deleted</Tag>;
        default:
          break;
      }
      switch (org.status) {
        case "full":
          return <Tag color="green">Full access</Tag>;
        case "freetrial":
          let subtitle: string = "unknown end date";
          if (org.freeTrialEndDate) {
            const trialEndDate = moment(org.freeTrialEndDate);
            const today = moment();
            const counter = trialEndDate.diff(today, "days");
            if (counter >= 0) {
              subtitle = `${counter} days left`;
            } else {
              subtitle = `ended ${-counter} days ago`;
            }
          }
          return <Tag color="magenta">Free trial ({subtitle})</Tag>;
        case "onboarding":
          return <Tag color="cyan">Onboarding</Tag>;
        case "defaultpayment":
          return <Tag color="orange">Default of payment</Tag>;
        default:
          return <Tag color="default">{org.status}</Tag>;
      }
    };
    const renderOrgView = (org: IOrg) => {
      return (
        <div style={{ display: "flex", alignItems: "center" }}>
          <div style={{ flex: 0 }}>
            <OrgAvatar
              overrideUrl={org.logo}
              name={org.name}
              style={{ marginRight: 14 }}
            />
          </div>
          <div
            style={{
              flex: 1,
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {org.name}
          </div>
          {userStore.user.isAdmin ? (
            <div style={{ flex: 0 }}>{renderOrgStatus(org)}</div>
          ) : null}
          <div
            style={{
              flex: 0,
              textAlign: "right",
            }}
          >
            <ArrowRightOutlined />
          </div>
        </div>
      );
    };
    if (userStore.user) {
      const roles = userStore.user.roles.filter((o) =>
        this.state.search
          ? o.org.name.toLowerCase().includes(this.state.search.toLowerCase())
          : true
      );

      const teamOrgRoles = roles.filter((role) => {
        return role.org.realm?.id === userStore.user.realm.realm.id;
      });

      const sharedOrgRoles = roles.filter((role) => {
        return role.org.realm?.id !== userStore.user.realm.realm.id;
      });

      const span = { xs: 24 };

      return (
        <Row className={`main-org-picker v2`}>
          <Col {...span}>
            <div className="main-org-picker-description">
              <Subtitle>
                Organizations lets you compartment your work accross your
                organization. You can invite people to collaborate on any
                organizations.
              </Subtitle>
            </div>
            {/**  Search bar **/}
            {userStore.user.roles.length > 10 ? (
              <div style={{ marginTop: 31 }}>
                <Input
                  ref={this.searchInput}
                  placeholder="Search..."
                  size="large"
                  onChange={(s) => this.setState({ search: s.target.value })}
                />
              </div>
            ) : null}
            {/**  Team Organisations **/}
            <div style={{ margin: "31px 0px" }}>
              {teamOrgRoles.length > 0 ? (
                <>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-start",
                      alignItems: "baseline",
                      width: "100%",
                    }}
                  >
                    <h2 style={{ marginBottom: 0 }}>Team Organizations</h2>
                    <div style={{ marginLeft: "auto" }}>
                      {hasRealmAccessBoolean(
                        IUserRealmType.ADMIN,
                        userStore.user
                      ) ? (
                        <Button
                          onClick={() =>
                            this.props.history.push(
                              routeDescriptor["settingsRealm"].renderRoute({
                                ...this.props.match.params,
                              })
                            )
                          }
                          type="primary"
                        >
                          Manage team
                        </Button>
                      ) : undefined}
                      {/* TODO: add an org creation form
                      <Button
                        shape="circle"
                        type="default"
                        size="small"
                        icon={<PlusOutlined />}
                      />
                      */}
                    </div>
                  </div>
                  {teamOrgRoles.map((o) => {
                    return (
                      <OrgListItem
                        key={o.org.id}
                        org={o.org}
                        render={renderOrgView}
                        to={routeDescriptor["home"].renderRoute({
                          organizationSlug: o.org.slug,
                        })}
                      />
                    );
                  })}
                </>
              ) : undefined}
            </div>
            {/**  Shared Organisations **/}
            <div style={{ margin: "31px 0px" }}>
              {sharedOrgRoles.length > 0 ? (
                <>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-start",
                      alignItems: "baseline",
                      width: "100%",
                    }}
                  >
                    <h2>Shared Organizations</h2>
                    <div style={{ marginLeft: "auto" }}>
                      {/**  Create org form **/}
                      {userStore.user.isAdmin ? (
                        <div style={{ marginTop: 31 }}>
                          <Button
                            type="primary"
                            onClick={() =>
                              this.setState({ isCreateOrgModalOpen: true })
                            }
                          >
                            Add org
                          </Button>
                          <CreateOrgModal
                            isOpen={this.state.isCreateOrgModalOpen}
                            setOpen={(open) =>
                              this.setState({ isCreateOrgModalOpen: open })
                            }
                          />
                        </div>
                      ) : null}
                    </div>
                  </div>
                  {sharedOrgRoles.map((o) => {
                    return (
                      <OrgListItem
                        key={o.org.id}
                        org={o.org}
                        render={renderOrgView}
                        to={routeDescriptor["home"].renderRoute({
                          organizationSlug: o.org.slug,
                        })}
                      />
                    );
                  })}
                </>
              ) : undefined}
            </div>
          </Col>
          <Col xs={24} style={{ height: 24 }} />
        </Row>
      );
    }
    return <div>You must be logged to access this page</div>;
  }
}

export default compose<Props, IOrgProps>(withRouter)(
  inject("breadcrumbStore", "userStore")(observer(Org))
);
