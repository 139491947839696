import { useRef } from "react";

export function useCache<T = any>() {
  const cache = useRef(new Map<string, { value: T }>());
  return {
    cache: cache.current,
    set: (key: string, value: T) => cache.current.set(key, { value: value }),
    get: (key: string) => cache.current.get(key),
    delete: (key: string) => cache.current.delete(key),
    clear: () => cache.current.clear(),
  };
}
