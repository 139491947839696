import { Form, Input, Select } from "antd";
import cuid from "cuid";
import * as React from "react";
import { compose } from "../../../../../../../components/compose/WlyCompose";
import FormActions from "../../../../../../../components/form/actions/FormActions";
import type { AsyncData } from "../../../../../../../helpers/typescriptHelpers";
import type { IDestination } from "../../../../../../../interfaces/destinations";
import type { IsPersistedAsType } from "../../../../../../../interfaces/sources";
import type {
  DatabaseInfo,
  SchemaInfo,
} from "../../../../../../../services/BrizoService";
import {
  getDatabases,
  getSchemas,
  WarehouseUserType,
} from "../../../../../../../services/BrizoService";
import type { InjectedOrgProps } from "../../../../../../orgs/WithOrg";
import WithOrg from "../../../../../../orgs/WithOrg";
import { catchErrors } from "../../../../../../transformations/domain";

interface IPersistDatasetModalFormProps {
  onUpdate: (d: IDatasetInitialValue) => Promise<any>;
  onFinish: () => void;
  onCancel: () => void;
  initialValues: IDatasetInitialValue;
  currentWarehouse: IDestination;
}

interface IDatasetInitialValue {
  isPersistedAs: IsPersistedAsType;
  warehouseViewTableId?: string;
  warehouseViewSchemaId?: string;
  warehouseViewDatabaseId?: string;
}

type Props = InjectedOrgProps & IPersistDatasetModalFormProps;

const id = cuid();

const PersistDatasetModalForm: React.FunctionComponent<Props> = (props) => {
  const { onUpdate, onCancel, initialValues, onFinish, org, currentWarehouse } =
    props;

  const [form] = Form.useForm();
  const [submitting, setSubmitting] = React.useState<boolean>(false);
  const [selectedDatabaseName, setSelectedDatabaseName] =
    React.useState<string>();

  const [availableDatabases, setAvailableDatabases] = React.useState<
    AsyncData<Array<DatabaseInfo>>
  >({ status: "initial" });
  const [availableSchemas, setAvailableSchemas] = React.useState<
    AsyncData<Array<SchemaInfo>>
  >({ status: "initial" });

  React.useEffect(() => {
    setAvailableDatabases({ status: "loading" });
    getDatabases(currentWarehouse.id, WarehouseUserType.DATA_LOADING)
      .then((r) => {
        setAvailableDatabases({ status: "success", data: r.data });
      })
      .catch((err) => {
        setAvailableDatabases({ status: "error", error: err });
      });
  }, [currentWarehouse?.id]);

  React.useEffect(() => {
    if (
      selectedDatabaseName ||
      initialValuesWithWarehouseDefaults.warehouseViewDatabaseId
    ) {
      setAvailableSchemas({ status: "loading" });
      getSchemas(
        currentWarehouse?.id,
        selectedDatabaseName ||
          initialValuesWithWarehouseDefaults.warehouseViewDatabaseId,
        WarehouseUserType.DATA_LOADING
      )
        .then((r) => {
          setAvailableSchemas({ status: "success", data: r.data });
        })
        .catch((err) => {
          setAvailableSchemas({ status: "error", error: err });
        });
    }
  }, [currentWarehouse?.id, selectedDatabaseName]);

  const initialValuesWithWarehouseDefaults: IDatasetInitialValue = {
    ...initialValues,
    warehouseViewDatabaseId:
      initialValues.warehouseViewDatabaseId ||
      currentWarehouse?.persistenceEngineDefaultTargetDatabase,
    warehouseViewSchemaId:
      initialValues.warehouseViewSchemaId ||
      currentWarehouse?.persistenceEngineDefaultTargetSchema,
  };
  return (
    <Form
      initialValues={initialValuesWithWarehouseDefaults}
      form={form}
      className="form-dropdown-form"
      onValuesChange={(changedValues) => {
        if (changedValues["warehouseViewDatabaseId"]) {
          const newDatabase = form.getFieldValue("warehouseViewDatabaseId");
          setSelectedDatabaseName(newDatabase);
          form.setFieldValue(["warehouseViewSchemaId"], null);
        }
      }}
      onFinish={async (v: IDatasetInitialValue) => {
        try {
          setSubmitting(true);
          await onUpdate(v);
          setSubmitting(false);
          onFinish();
        } catch (err) {
          console.error(err);
          catchErrors(err, id);
          setSubmitting(false);
        }
      }}
      layout="vertical"
    >
      <div className="form-dropdown-form-content" id={id}>
        <Form.Item
          name={["isPersistedAs"]}
          label="Materialization"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select>
            <Select.Option value="ephemeral">
              Don't materialize this model
            </Select.Option>
            <Select.Option value="view">
              Materialize this model as a view
            </Select.Option>
            <Select.Option value="table">
              Materialize this model as a table
            </Select.Option>
          </Select>
        </Form.Item>
        <Form.Item shouldUpdate={true} noStyle={true}>
          {() => {
            if (form.getFieldValue(["isPersistedAs"]) === "ephemeral") {
              return undefined;
            }
            return (
              <>
                <Form.Item
                  name={["warehouseViewDatabaseId"]}
                  label="Select your database"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Select
                    disabled={availableDatabases.status !== "success"}
                    loading={availableDatabases.status === "loading"}
                  >
                    {availableDatabases.status === "success" &&
                      availableDatabases.data.map((db, i) => {
                        return (
                          <Select.Option value={db.databaseName} key={i}>
                            {db.databaseName}
                          </Select.Option>
                        );
                      })}
                  </Select>
                </Form.Item>
                <>
                  <Form.Item shouldUpdate={true} noStyle>
                    {() => {
                      return (
                        <Form.Item
                          name={["warehouseViewSchemaId"]}
                          label="Select your schema"
                          rules={[
                            {
                              required: true,
                            },
                          ]}
                        >
                          <Select
                            disabled={
                              !form.getFieldValue([
                                "warehouseViewDatabaseId",
                              ]) || availableSchemas.status !== "success"
                            }
                            loading={availableSchemas.status === "loading"}
                          >
                            {availableSchemas.status === "success" &&
                              availableSchemas.data.map((schema, i) => {
                                return (
                                  <Select.Option
                                    value={schema.schemaName}
                                    key={i}
                                  >
                                    {schema.schemaName}
                                  </Select.Option>
                                );
                              })}
                          </Select>
                        </Form.Item>
                      );
                    }}
                  </Form.Item>
                  <Form.Item shouldUpdate={true} noStyle>
                    {() => {
                      return (
                        <Form.Item
                          name={["warehouseViewTableId"]}
                          label={`Name your ${form.getFieldValue(
                            "isPersistedAs"
                          )}`}
                          rules={[
                            {
                              required: true,
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      );
                    }}
                  </Form.Item>
                </>
              </>
            );
          }}
        </Form.Item>
      </div>
      <div className="form-dropdown-form-buttons">
        <FormActions
          flex={true}
          size="small"
          onCancel={onCancel}
          isSubmitting={submitting}
        />
      </div>
    </Form>
  );
};

export default compose<Props, IPersistDatasetModalFormProps>(WithOrg)(
  PersistDatasetModalForm
);
