import {
  CheckCircleTwoTone,
  CloseCircleTwoTone,
  DownloadOutlined,
  InfoCircleOutlined,
  LineChartOutlined,
  LoadingOutlined,
  TableOutlined,
} from "@ant-design/icons";
import { Button, Popconfirm, Skeleton, Space, Typography } from "antd";
import { ExportToCsv } from "export-to-csv";
import * as React from "react";
import type { GridChildComponentProps } from "react-window";
import type { InjectedAntUtilsProps } from "../../../../../../components/ant-utils/withAntUtils";
import { withAntUtils } from "../../../../../../components/ant-utils/withAntUtils";
import { compose } from "../../../../../../components/compose/WlyCompose";
import Feednack from "../../../../../../components/layout/feedback/feedback";
import type {
  SpreadsheetBodyCellRenderer,
  SpreadsheetHeaderCellRenderer,
} from "../../../../../../components/spreadsheet/SpreadsheetScrollSync";
import FuncSpreadsheetScrollSync from "../../../../../../components/spreadsheet/SpreadsheetScrollSync";
import type {
  BooleanSchemaItem,
  CellData,
  DataType,
  SchemaResult,
  TableResult,
} from "../../../../../../interfaces/transformations";
import { generateUniqueId } from "../../../../../../utils/uniqueId";
import { SpreadsheetErrorRenderer } from "../../../../../spreadsheet/error/SpreadsheetErrorRenderer";
import FieldRenderer from "../../../../../spreadsheet/renderer/FieldRenderer";
import TypeRenderer from "../../../../../spreadsheet/renderer/TypeRenderer";
import * as Toolbar from "../../../../../spreadsheet/toolbar/Toolbar";
import { DEFAULT_WORKBENCH_RECORD_NUMBER } from "../../../domain";
import EmbeddedCharts from "./embeded-charts/EmbeddedChart";

interface ISQLResultsProps {
  status: "initial" | "error" | "loading" | "success";
  count?: number;
  records?: TableResult;
  schema?: SchemaResult;
  error?: string;
  loading: {
    schema: boolean;
    count: boolean;
    records: boolean;
  };
  initialScrollLeft?: number;
  initialScrollTop?: number;
  onScroll?: (scrollLeft: number, scrollTop: number) => void;
}

interface IState {
  preSaveModalVisible: boolean;
  primaryKeysModalVisible: boolean;
  primaryKeysModalSaving: boolean;
  tab: "results" | "chart";
}

type Props = ISQLResultsProps & InjectedAntUtilsProps;

class SQLResults extends React.Component<Props, IState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      preSaveModalVisible: false,
      primaryKeysModalVisible: false,
      primaryKeysModalSaving: false,
      tab: "results",
    };
  }

  public spreadsheetBodyCellRenderer =
    (schemaDef: SchemaResult): SpreadsheetBodyCellRenderer =>
    (props: GridChildComponentProps<SchemaResult>) => {
      const { loading, schema, records } = this.props;

      const dataKey = Object.keys(schemaDef)[props.columnIndex];
      const cellData =
        records && records[props.rowIndex]
          ? records[props.rowIndex][dataKey]
          : null;

      const key = props.rowIndex + "-" + props.columnIndex;

      return loading.records || (schema && schema[dataKey].loading) ? (
        <div
          key={`loading-${key}`}
          className="cell-content"
          style={{ ...props.style }}
        >
          <Skeleton.Input
            style={{ width: 174, borderRadius: 12, height: 16, marginTop: 7 }}
            active={true}
            size={"small"}
          />
        </div>
      ) : (
        <div
          key={key}
          style={{ ...props.style }}
          className={`cell-content`}
          onDoubleClick={(e) => {
            try {
              if (records?.[props.rowIndex]?.[dataKey]) {
                e.preventDefault();
                navigator.clipboard.writeText(
                  records[props.rowIndex]?.[dataKey]?.toString?.()
                );
                this.props.antUtils.message.success(
                  "Value copied to clipboard"
                );
              }
            } catch (error) {
              console.warn(error);
            }
          }}
        >
          {this.renderContent(
            cellData,
            schema ? schema[dataKey].domain : undefined
          )}
        </div>
      );
    };

  public renderContent = (cellData: CellData, type?: DataType) => {
    let content = cellData;

    return <FieldRenderer content={content} type={type} />;
  };

  public spreadsheetHeaderCellRenderer =
    (schemaDef: SchemaResult): SpreadsheetHeaderCellRenderer =>
    (listProps) => {
      const { loading, schema } = this.props;
      const dataKey = Object.keys(schemaDef)[listProps.index];

      const renderDataType = () => {
        if (schema) {
          const schemaItem = schema[dataKey];
          return (
            <TypeRenderer
              domain={schemaItem.domain}
              formula={schemaItem.operation === "Table.AddColumn"}
            />
          );
        }
        return <span />;
      };

      return loading.schema ? (
        <div
          className="ReactVirtualized__Table__headerColumn"
          style={listProps.style}
        >
          <Skeleton.Input
            style={{ height: 16, borderRadius: 12, width: 174, marginTop: 7 }}
            active={true}
            size={"small"}
          />
        </div>
      ) : (
        <div
          className={`ReactVirtualized__Table__headerColumn`}
          style={listProps.style}
        >
          {
            <div className="header-inner">
              <div className="header-type">{renderDataType()}</div>
              <div className="header-name">
                {schema && schema[dataKey] && schema[dataKey].label
                  ? schema[dataKey].label
                  : dataKey}
              </div>
            </div>
          }
        </div>
      );
    };

  public render() {
    const {
      initialScrollTop,
      initialScrollLeft,
      loading,
      schema,
      records,
      count,
      error,
      onScroll,
      status,
    } = this.props;

    let schemaDef = schema ? schema : {};
    if (loading.schema) {
      schemaDef = Array.from({ length: 10 }, (_, a) => ({
        key: generateUniqueId(),
      }))
        .map((_) => {
          return {
            [_.key]: {
              domain: "BOOLEAN",
              type: "BOOLEAN",
              distinct: 0,
              null: 0,
              count: 0,
            } as BooleanSchemaItem,
          };
        })
        .reduce((p, v, i) => {
          return {
            ...p,
            ...v,
          };
        }, {} as SchemaResult);
    }

    let recordsDef = records ? records : [];
    if (loading.records) {
      const record = Object.keys(schemaDef).reduce((p, v, i) => {
        return {
          ...p,
          [v]: null,
        };
      }, {});
      recordsDef = Array.from({ length: 100 }, (_, a) => record);
    }

    const renderInner = () => {
      if (status === "initial") {
        return <Feednack>Please run a query to get started</Feednack>;
      }
      if (this.state.tab === "results") {
        return (
          <>
            <div className="spreadsheet-table">
              {error ? (
                <SpreadsheetErrorRenderer>{error}</SpreadsheetErrorRenderer>
              ) : (
                <FuncSpreadsheetScrollSync
                  columnCount={Object.keys(schemaDef).length}
                  rowCount={recordsDef.length}
                  renderHeaderCell={this.spreadsheetHeaderCellRenderer(
                    schemaDef
                  )}
                  renderBodyCell={this.spreadsheetBodyCellRenderer(schemaDef)}
                  isLoading={loading.records || loading.count}
                  initialScrollLeft={initialScrollLeft}
                  initialScrollTop={initialScrollTop}
                  onScroll={onScroll}
                />
              )}
            </div>
            <div className="spreadsheet-footer">
              <div className="spreadsheet-footer-inner">
                <Space>
                  {count !== undefined && count !== null ? (
                    <div>{count} records</div>
                  ) : null}
                  {count !== undefined && count !== null ? (
                    <Popconfirm
                      title="Export as CSV"
                      description={`This will export the ${count} visible results as CSV`}
                      placement="topRight"
                      icon={<InfoCircleOutlined />}
                      okText="Download"
                      cancelText="No"
                      onConfirm={() => {
                        const csvExporter = new ExportToCsv({
                          useKeysAsHeaders: true,
                          fieldSeparator: ",",
                          quoteStrings: '"',
                          decimalSeparator: ".",
                          showLabels: true,
                          showTitle: false,
                          useTextFile: false,
                          useBom: true,
                        });
                        return csvExporter.generateCsv(recordsDef);
                      }}
                    >
                      <Button type="text" ghost icon={<DownloadOutlined />} />
                    </Popconfirm>
                  ) : null}
                </Space>
              </div>
            </div>
          </>
        );
      } else {
        return (
          <div className="spreadsheet-table">
            {" "}
            {error ? (
              <SpreadsheetErrorRenderer>{error}</SpreadsheetErrorRenderer>
            ) : (
              <EmbeddedCharts
                status={status}
                data={loading.records ? [] : recordsDef}
                schema={loading.schema ? {} : schemaDef}
              />
            )}
          </div>
        );
      }
    };

    const renderIcon = () => {
      if (status === "initial") {
        return <div />;
      }
      if (status === "loading") {
        return <LoadingOutlined />;
      }
      if (status === "error") {
        return (
          <div>
            <Typography.Text type="danger">
              <CloseCircleTwoTone twoToneColor={"#ff4d4f"} /> Error
            </Typography.Text>
          </div>
        );
      }
      if (status === "success") {
        if (count >= DEFAULT_WORKBENCH_RECORD_NUMBER) {
          return (
            <div>
              <Typography.Text strong type="warning">
                Displaying only the first {DEFAULT_WORKBENCH_RECORD_NUMBER}{" "}
                results.
              </Typography.Text>
            </div>
          );
        }
        return (
          <div>
            <CheckCircleTwoTone twoToneColor={"#52c41a"} />
          </div>
        );
      }
    };

    return (
      <div className="spreadsheet-inner">
        <div>
          <Toolbar.Toolbar>
            <Toolbar.Item
              onClick={() => this.setState({ tab: "results" })}
              active={this.state.tab === "results"}
            >
              <TableOutlined /> Results
            </Toolbar.Item>
            <Toolbar.Item
              onClick={() => this.setState({ tab: "chart" })}
              active={this.state.tab === "chart"}
            >
              <LineChartOutlined /> Chart
            </Toolbar.Item>
            <div style={{ flex: 1 }} />
            {renderIcon()}
            <div style={{ width: 12 }} />
          </Toolbar.Toolbar>
        </div>
        {renderInner()}
      </div>
    );
  }
}

export default compose<Props, ISQLResultsProps>(withAntUtils)(SQLResults);
