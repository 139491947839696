import { InfoCircleFilled, MoreOutlined } from "@ant-design/icons";
import type { MenuProps } from "antd";
import { Button, Dropdown, Tooltip, Typography } from "antd";
import * as React from "react";
import { Emoji } from "../../../components/form/emoji-picker/Emoji";
import type { IExplorationSection } from "../../../interfaces/explorations";

interface IExplorationSectionMenuItemProps {
  explorationSection: IExplorationSection;
  onEdit?: () => void;
  onDelete?: () => void;
}

export function ExplorationSectionMenuItem(
  props: IExplorationSectionMenuItemProps
) {
  const { explorationSection, onDelete, onEdit } = props;
  const [hovering, setHovering] = React.useState<boolean>(false);
  const dropdownContainerRef = React.useRef(null);

  const menuItems: MenuProps["items"] = [
    onEdit ? {
      key: 1,
      onClick: onEdit,
      label: "Edit",
    } : null,
    onDelete ? {
      key: 2,
      onClick: onDelete,
      danger: true,
      label: "Delete",
    } : null,
  ];

  const size = 20;
  return (
    <div
      onMouseEnter={() => setHovering(true)}
      onMouseLeave={() => setHovering(false)}
      ref={dropdownContainerRef}
      className="exploration-menu-item"
    >
      <div style={{ width: size, height: size, marginRight: 6 }}>
        <Emoji size={size} emoji={explorationSection.image} />
      </div>
      <div className="exploration-menu-item-name">
        <Typography.Text strong ellipsis>
          {explorationSection.name}
        </Typography.Text>
      </div>
      {(onEdit || onDelete) && (
        <div style={{ flex: "0 1 auto" }} hidden={!hovering}>
          <Dropdown
            menu={{
              items: menuItems,
            }}
            trigger={["click"]}
            getPopupContainer={() => dropdownContainerRef?.current!}
          >
            <Button
              id={`section-${explorationSection.id}`}
              onClick={(e) => e.stopPropagation()}
              type="text"
              shape="circle"
              size="small"
              icon={<MoreOutlined />}
            />
          </Dropdown>
          {explorationSection.description ? (
            <span style={{ paddingLeft: 6 }}>
              <Tooltip title={explorationSection.description} placement="right">
                <InfoCircleFilled style={{ opacity: 0.3 }} />
              </Tooltip>
            </span>
          ) : null}
        </div>
      )}
    </div>
  );
}
