export type Index<T> = { [key: string]: T };

export type DeepPartial<T> = T extends Function
  ? T
  : T extends object
  ? { [P in keyof T]?: DeepPartial<T[P]> }
  : T;

export type AsyncData<T> =
  | { status: "initial" }
  | { status: "loading" }
  | { status: "success"; data: T }
  | { status: "error"; error: Error };

export type AsyncCachedData<T> =
  | { status: "initial" }
  | { status: "loading"; cache?: T }
  | { status: "success"; data: T; cache?: T }
  | { status: "error"; error: Error; cache?: T };

type Matcher<T, R> = {
  initial: () => R;
  loading: () => R;
  success: (data: T) => R;
  error: (error: Error) => R;
};

export const match = <T, R>(m: Matcher<T, R>, ad: AsyncData<T>): R => {
  if (ad.status === "initial") {
    return m.initial();
  }
  if (ad.status === "loading") {
    return m.loading();
  }
  if (ad.status === "error") {
    return m.error(ad.error);
  }
  return m.success(ad.data);
};
