import { Promise as BPromise } from "bluebird";
import React from "react";
import { compose } from "../../../components/compose/WlyCompose";
import Error from "../../../components/error/Error";
import type { AsyncData } from "../../../helpers/typescriptHelpers";
import type { IAuditLog } from "../../../interfaces/auditLog";
import {
  AuditLogCategory,
  AuditLogSubCategory,
} from "../../../interfaces/auditLog";
import type { IReport } from "../../../interfaces/reports";
import GraphQLService from "../../../services/graphql/GraphQLService";
import type { InjectedOrgProps } from "../../orgs/WithOrg";
import WithOrg from "../../orgs/WithOrg";
import SettingsWrapper from "../SettingsWrapper";
import AuditLogTable from "./AuditLogTable";

interface IAuditLogPageProps {}

const PAGE_SIZE = 20;

const GET_AUDIT_LOGS = `
query getAuditLogPage(
  $orgId: ID!,
  $offset: Int!
) {
  _allAuditLogsMeta(
    where: { org: { id: $orgId }, category: "SECURITY_ACTIVITY" }
  ) {
    count
  }
  allAuditLogs(
    first: ${PAGE_SIZE}
    skip: $offset
    orderBy: "id_DESC"
    where: { org: { id: $orgId }, category: "SECURITY_ACTIVITY" }
  ) {
    at
    category
    subCategory
    action
    recordId
    user {
      id
      firstName
      lastName
      gravatar
      avatarColor
    }
  }
}
`;

interface IAuditLogsReturnValue {
  _allAuditLogsMeta: {
    count: number;
  };
  allAuditLogs: IAuditLog[];
}

const GET_REPORT_SLUG = `
query getReportSlug(
  $reportId: ID!
) {
  Report(
    where: { id: $reportId }
  ) {
   slug
  }
}
`;

interface IReportSlugReturnValue {
  Report: IReport;
}

type Props = IAuditLogPageProps & InjectedOrgProps;

const AuditLogPage: React.FunctionComponent<Props> = (props) => {
  const { org } = props;

  const [pageData, setPageData] = React.useState<AsyncData<IAuditLog[]>>({
    status: "initial",
  });
  const [pageNumber, setPageNumber] = React.useState<number>(1);
  const [total, setTotal] = React.useState<number>(0);

  const fetchPageData = async () => {
    setPageData({ status: "loading" });

    const pageRes = await GraphQLService<IAuditLogsReturnValue>(
      GET_AUDIT_LOGS,
      {
        orgId: org.id,
        offset: (pageNumber - 1) * PAGE_SIZE,
      }
    );

    setTotal(pageRes._allAuditLogsMeta.count);

    const rawAuditLogs = pageRes.allAuditLogs;
    const auditLogsWithSlugs = await BPromise.map(
      rawAuditLogs,
      async (curr) => {
        if (curr.recordId === "unknown") {
          return curr;
        }
        if (
          curr.category === AuditLogCategory.SECURITY_ACTIVITY &&
          curr.subCategory === AuditLogSubCategory.DATA_EXPORT
        ) {
          const reportSlugRes = await GraphQLService<IReportSlugReturnValue>(
            GET_REPORT_SLUG,
            { reportId: curr.recordId }
          );
          return {
            ...curr,
            recordSlug: reportSlugRes?.Report?.slug,
          };
        }
      },
      { concurrency: 3 }
    );
    setPageData({ status: "success", data: auditLogsWithSlugs });
  };

  React.useEffect(() => {
    fetchPageData();
  }, [org.id, pageNumber]);

  if (pageData.status === "error") {
    return <Error>{pageData.error?.message}</Error>;
  }

  return (
    <SettingsWrapper>
      <AuditLogTable
        auditLogs={pageData}
        total={total}
        current={pageNumber}
        pageSize={PAGE_SIZE}
        setPageNumber={setPageNumber}
      />
    </SettingsWrapper>
  );
};

export default compose<Props, IAuditLogPageProps>(WithOrg)(AuditLogPage);
