import Icon from "@ant-design/icons";
import type { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";

export const WlyQuestionIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon
    component={() => (
      <svg
        width="1em"
        height="1em"
        viewBox="0 0 310 309"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M202.131 0.260489C194.963 -0.790078 187.698 1.36892 182.267 6.16157C176.669 10.9422 173.46 17.9447 173.489 25.3057V110.512C173.525 117.181 176.193 123.565 180.908 128.28C185.623 132.996 192.006 135.664 198.676 135.699H283.883C291.196 135.685 298.145 132.511 302.942 126.996C307.742 121.478 309.925 114.158 308.928 106.914C305.012 79.9942 292.497 55.0622 273.249 35.8403C254.001 16.6191 229.052 4.13705 202.132 0.257651L202.131 0.260489Z" />
        <path d="M107.278 305.828C116.099 307.546 125.063 308.414 134.05 308.418C161.872 308.394 188.974 299.578 211.484 283.231C226.248 272.617 238.689 259.099 248.045 243.507C257.399 227.914 263.474 210.575 265.891 192.553C266.939 185.3 264.775 177.951 259.966 172.421C255.156 166.893 248.176 163.734 240.848 163.766H148.295C146.31 163.766 144.698 162.156 144.698 160.168V68.3371C144.731 61.0094 141.57 54.0291 136.042 49.2195C130.515 44.4099 123.163 42.2459 115.91 43.2943C84.6411 47.3737 55.834 62.4217 34.6209 85.7561C13.4091 109.093 1.16887 139.199 0.0795036 170.716C-1.00986 202.233 9.12507 233.111 28.6763 257.859C48.2275 282.6 75.9287 299.6 106.838 305.829L107.278 305.828Z" />
      </svg>
      // <svg
      //   width="1em"
      //   height="1em"
      //   viewBox="0 0 1024 1024"
      //   fill="currentColor"
      //   xmlns="http://www.w3.org/2000/svg"
      // >
      //   <path d="M323.2 422.4c-16 0-32 16-32 32l0 256c0 16 16 32 32 32 19.2 0 32-16 32-32l0-256C355.2 435.2 342.4 422.4 323.2 422.4zM483.2 294.4c-16 0-32 16-32 32l0 384c0 16 16 32 32 32 19.2 0 32-16 32-32l0-384C515.2 307.2 502.4 294.4 483.2 294.4zM643.2 518.4c-16 0-32 16-32 32l0 160c0 16 16 32 32 32 19.2 0 32-16 32-32l0-160C675.2 531.2 662.4 518.4 643.2 518.4zM803.2 358.4c-16 0-32 16-32 32l0 320c0 16 16 32 32 32 19.2 0 32-16 32-32l0-320C835.2 371.2 822.4 358.4 803.2 358.4zM867.2 838.4l-672 0 0-672c0-19.2-12.8-32-32-32s-32 12.8-32 32l0 672c0 35.2 28.8 64 64 64l672 0c19.2 0 32-12.8 32-32S886.4 838.4 867.2 838.4z"></path>
      // </svg>
    )}
    {...props}
  />
);
