import { Form, Input } from "antd";
import * as React from "react";
import GraphQLService from "../../../services/graphql/GraphQLService";
import type { InjectedAntUtilsProps } from "../../ant-utils/withAntUtils";
import { withAntUtils } from "../../ant-utils/withAntUtils";
import { compose } from "../../compose/WlyCompose";
import WlyModal from "../../modal/WlyModal";

interface IExplorationRenameProps {
  visible: boolean;
  initialData?: ExplorationInitialData;
  onSave: (v: ExplorationInitialData) => any;
  onCancel: () => any;
}

type Props = IExplorationRenameProps & InjectedAntUtilsProps;

export interface ExplorationInitialData {
  id: string;
  name: string;
}

function ExplorationRename(props: Props) {
  const { visible, onCancel, initialData, onSave, antUtils } = props;
  const [confirmLoading, setConfirmLoading] = React.useState(false);
  const inputRef = React.useRef<any>(null);
  const [form] = Form.useForm();

  React.useEffect(() => {
    if (visible === false) {
      form.resetFields();
      form.setFieldsValue({ name: "" });
    }
  }, [visible, form]);

  React.useEffect(() => {
    form.setFieldsValue(initialData);
  }, [form, initialData]);

  const renderInnerModal = () => {
    return (
      <Form
        initialValues={
          initialData
            ? {
                name: initialData.name,
              }
            : {
                name: "",
              }
        }
        layout="vertical"
        form={form}
      >
        <Form.Item label="Exploration name">
          <Input.Group compact>
            <Form.Item
              name={["name"]}
              noStyle
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input
                ref={inputRef}
                style={{ width: "100%" }}
                placeholder="Exploration name"
              />
            </Form.Item>
          </Input.Group>
        </Form.Item>
      </Form>
    );
  };

  return (
    <WlyModal
      open={!!visible}
      title={"Rename exploration"}
      okText={"Rename"}
      cancelText="Cancel"
      onClose={() => {
        form.resetFields();
        onCancel();
      }}
      confirmLoading={confirmLoading}
      onOk={async () => {
        setConfirmLoading(true);
        try {
          const values: ExplorationInitialData = await form.validateFields();
          if (initialData && initialData.id) {
            await GraphQLService(
              `
                mutation updateExploration($id: ID!, $data: ExplorationUpdateInput) {
                    updateExploration(id: $id, data: $data) {
                      id
                    }
                  }
                `,
              {
                id: initialData.id,
                data: {
                  name: values.name,
                },
              }
            );
            setConfirmLoading(false);
            antUtils.message.success("Exploration renamed");
            return onSave({
              id: initialData.id,
              name: values.name,
            });
          }
          onCancel();
          return form.resetFields();
        } catch (error) {
          setConfirmLoading(false);
          antUtils.message.error("Error while renaming exploration");
          console.error("Validate Failed:", error);
        }
      }}
    >
      {renderInnerModal()}
    </WlyModal>
  );
}

export default compose<Props, IExplorationRenameProps>(withAntUtils)(
  ExplorationRename
);
