import type { QueryOrder } from "@cubejs-client/core";
import { useReducer } from "react";
import type { ISetTableQueryAction, TableQuery } from "./domain";

export const useTableQuery = (options: {
  initialQuery: TableQuery;
  defaultQuery: TableQuery;
  getInitialQuery: () => TableQuery;
}): [TableQuery, React.Dispatch<ISetTableQueryAction>] => {
  const [tableQuery, setTableQuery] = useReducer(
    (state: TableQuery, payload: ISetTableQueryAction) => {
      switch (payload.action) {
        case "reset":
          return {
            ...state,
            ...options.initialQuery,
          };
        case "setTableQuery":
          return {
            ...payload.tableQuery,
          };
        case "toggleOrder":
          // we delete the sorted column
          if (!payload.order[1]) {
            return {
              ...state,
              order: state.order.filter((o) => o[0] !== payload.order[0]),
            };
          }
          // we update the sort order
          else if (state.order.find((o) => o[0] === payload.order[0])) {
            return {
              ...state,
              order: state.order.map((o) => {
                if (o[0] === payload.order[0]) {
                  return payload.order as [string, QueryOrder];
                }
                return o;
              }),
            };
          }
          // // we add the new sort
          else if (!state.order.find((o) => o[0] === payload.order[0])) {
            return {
              ...state,
              order: [...state.order, payload.order as [string, QueryOrder]],
            };
          }
          // // we do nothing
          else {
            return { ...state };
          }
        case "setOrder":
          return {
            ...state,
            order: payload.order,
          };
        case "setFilters":
          return {
            ...state,
            filters: payload.filters,
          };
        case "setPresetFilters":
          return {
            ...state,
            presetFilters: payload.presetIds,
          };
        case "addFilters":
          if ("and" in state.filters?.[0]) {
            return {
              ...state,
              filters: [{ and: [...state.filters[0].and, ...payload.filters] }],
            };
          } else {
            return state;
          }
        case "setGroups":
          return {
            ...state,
            groups: payload.groups,
          };
        case "columnSizing":
          return {
            ...state,
            columnSizing: payload.columnSizing,
          };
        case "columnPinning":
          return {
            ...state,
            columnPinning: payload.columnPinning,
          };
        case "columnOrder":
          return {
            ...state,
            columnOrder: payload.columnOrder,
          };
        case "columnVisibility":
          return {
            ...state,
            columnVisibility: payload.columnVisibility,
          };
        case "columnVisibilityAndOrder":
          return {
            ...state,
            columnVisibility: payload.columnVisibility,
            columnOrder: payload.columnOrder,
          };
        case "setQueryBuilderItems":
          return {
            ...state,
            queryBuilderItems: payload.queryBuilderItems,
          };

        default:
          return state;
      }
    },
    options.defaultQuery,
    options.getInitialQuery
  );
  return [tableQuery, setTableQuery];
};
