import { action, observable, makeObservable } from "mobx";
import type { IBreadcrumbItem } from "../interfaces/breadcrumb";

class BreadcrumbStore {
  breadcrumb: IBreadcrumbItem[];

  constructor() {
    makeObservable(this, {
      breadcrumb: observable,
      setBreadcrumb: action,
    });

    this.breadcrumb = [];
  }

  setBreadcrumb(breadcrumb: IBreadcrumbItem[]) {
    this.breadcrumb = breadcrumb;
  }
}

export interface BreadcrumbStoreProps {
  breadcrumbStore: BreadcrumbStore;
}

export default new BreadcrumbStore();
