import type { DrawerProps } from "antd";
import { Button, Drawer } from "antd";
import type { FormInstance } from "antd/lib";
import { useEffect } from "react";
import useKeyPress from "../../hooks/useKeypress";

interface ISimpleFormDrawerProps<TFormData> {
  form: FormInstance<TFormData>;
  open: boolean;
  isSaving: boolean;
  onCancel: () => void;
  children: React.ReactElement;
  drawerProps: Omit<DrawerProps, "open" | "setOpen" | "children">;
  disableEnterKey?: boolean;
  removeExtra?: boolean;
}

export function SimpleFormDrawer<TFormData>(
  props: ISimpleFormDrawerProps<TFormData>
) {
  const {
    children,
    form,
    open,
    isSaving,
    drawerProps,
    onCancel,
    disableEnterKey,
    removeExtra,
  } = props;
  const rest = drawerProps ? drawerProps : {};

  const enter = useKeyPress("Enter");

  useEffect(() => {
    if (open && enter && !disableEnterKey) {
      form.submit();
    }
  }, [enter, open, disableEnterKey]);

  // https://segmentfault.com/a/1190000041172195/en
  useEffect(() => {
    setTimeout(() => {
      form.resetFields();
    });
  }, [open]);

  return (
    <Drawer
      {...rest}
      open={open}
      extra={
        removeExtra ? undefined : (
          <Button
            loading={isSaving || form.isFieldsValidating()}
            disabled={isSaving}
            onClick={form.submit}
            type="primary"
          >
            Save
          </Button>
        )
      }
      onClose={isSaving ? undefined : () => onCancel()}
    >
      {children}
    </Drawer>
  );
}
