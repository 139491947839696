import { CloseCircleOutlined, InfoCircleOutlined } from "@ant-design/icons";
import type { BinaryFilter, UnaryFilter } from "@cubejs-client/core";
import type { FormInstance } from "antd";
import {
  Button,
  Form,
  Input,
  Select,
  Space,
  Switch,
  Tooltip,
  Typography,
} from "antd";
import cuid from "cuid";
import humanizeString from "humanize-string";
import _ from "lodash";
import React from "react";
import { WlyCard } from "../../../../../../components/cards/WlyCard";
import { WlyCardDangerZone } from "../../../../../../components/cards/WlyCardDangerZone";
import { compose } from "../../../../../../components/compose/WlyCompose";
import Feednack from "../../../../../../components/layout/feedback/feedback";
import Loading from "../../../../../../components/layout/feedback/loading";
import FilterItem from "../../../../../../components/measures/filter-item/FilterItem";
import type { AsyncData } from "../../../../../../helpers/typescriptHelpers";
import type { IMetricExpression } from "../../../../../../interfaces/table";
import type {
  SchemaResult,
  UnaryFilterItem,
} from "../../../../../../interfaces/transformations";
import {
  LagoonCallOrigin,
  lagoonServiceLoad,
} from "../../../../../../services/LagoonService";
import type { InjectedOrgProps } from "../../../../../orgs/WithOrg";
import WithOrg from "../../../../../orgs/WithOrg";
import TypeRenderer from "../../../../../spreadsheet/renderer/TypeRenderer";
import type { ExplorationMeasureItemUsage, IViewData } from "../../../domain";
import type {
  IMeasureTable,
  IMetricFormInput,
  IObjectConfigurationInfos,
  ITableFormInput,
  MeasureValidationFunction,
} from "../../domain";
import { ErrorRenderer } from "../common/ErrorRenderer";
import UsageAlert from "../common/UsageAlert";
import { validateCalculatedMetricFormInput } from "./CalculatedMetricEditor";
import { DrillsEditor } from "./common/DrillsEditor";
import MetricFormatFormItem from "./common/MetricFormatFormItem";

type Props = IMetricEditorProps & InjectedOrgProps;

export const validateMetricFormInput: MeasureValidationFunction<
  IMetricFormInput
> = (data, schema, allDatasets, allDatasetRelationships, allTables) => {
  const errors: string[] = [];
  if (schema.status === "initial" || schema.status === "loading") {
    return errors;
  }
  if (schema.status === "error") {
    return errors;
  }

  if (!data.expression) {
    errors.push("No aggregation selected");
    return errors;
  }

  if (data.expression === "COMPUTED") {
    const computedErrors = validateCalculatedMetricFormInput(
      data,
      schema,
      allDatasets,
      allDatasetRelationships,
      allTables
    );
    return computedErrors;
  }

  if (
    data.expression !== "COUNT" &&
    data.expression !== "CUMULATIVE_COUNT" &&
    (!data.columnName || !schema.data[data.columnName])
  ) {
    errors.push("Can't find aggregation column in schema");
    return errors;
  }

  if ((data.condition || []).find((c) => !schema.data[(c as any).column])) {
    errors.push("Can't find filtered column in schema");
    return errors;
  }
  return errors;
};

interface IMetricEditorProps {
  initialData?: IMetricFormInput;
  form: FormInstance<IMetricFormInput>;
  usage: ExplorationMeasureItemUsage[];
  schemaResults: AsyncData<SchemaResult>;
  error: string[];
  onSave: (data: IMetricFormInput) => Promise<void>;
  onDelete: () => Promise<void>;
  table: ITableFormInput & { id: string };
  selectedView: IViewData;
  tables: IMeasureTable[];
  object?: IObjectConfigurationInfos;
}

const generateName = (
  type: IMetricExpression,
  tableName: string,
  columnName?: string
) => {
  const columnLabel = columnName ? humanizeString(columnName) : tableName;
  switch (type) {
    case "AVG":
      return `Average of ${columnLabel}`;
    case "COUNT":
      return `Number of ${columnLabel}`;
    case "COUNT_DISTINCT":
      return `Number of ${columnLabel}`;
    case "MAX":
      return `Maximum of ${columnLabel}`;
    case "MIN":
      return `Minimum of ${columnLabel}`;
    case "SUM":
      return `Sum of ${columnLabel}`;
    case "CUMULATIVE_SUM":
      return `Cumulative Sum of ${columnLabel}`;
    case "CUMULATIVE_COUNT":
      return `Cumulative Count of ${columnLabel}`;
    case "MEDIAN":
      return `Median of ${columnLabel}`;
    default:
      return columnLabel;
  }
};

export const generateMetricName = (
  tableName: string,
  type?: IMetricExpression,
  columnName?: string,
  overrideName?: string
) => {
  if (overrideName) {
    return overrideName;
  }
  if (type) {
    return generateName(type, tableName, columnName);
  } else {
    return "Untitled";
  }
};

function MetricEditor(props: Props) {
  const {
    initialData,
    form,
    schemaResults,
    onSave,
    onDelete,
    table,
    org,
    error,
    usage,
    selectedView,
    tables,
    user,
    object,
  } = props;

  const debouncedOnSave = _.debounce(onSave, 200);

  const initialValues = !initialData
    ? {
        name: "",
        columnName: undefined,
        operator: "or",
        condition: [],
        expression: "COUNT",
        format: "NUMBER",
        drills: {
          type: "INHERIT",
        },
      }
    : initialData;

  const attachementId = React.useRef(cuid());

  const autocomplete = async (dimension: string): Promise<string[]> => {
    const formattedDimension = `${table.viewCubeName}.${dimension}`;
    return lagoonServiceLoad(
      org.id,
      {
        dimensions: [formattedDimension],
        limit: 500,
      },
      "VIEW",
      table.viewId,
      undefined,
      LagoonCallOrigin.WHALY_APP
    )
      .then((r) => {
        return r.tablePivot();
      })
      .then((r) => {
        return r.map((d) => d[formattedDimension] as string);
      });
  };

  if (
    schemaResults.status === "initial" ||
    schemaResults.status === "loading"
  ) {
    return (
      <Feednack>
        <div>
          <Loading />
          <div>Loading Schema...</div>
        </div>
      </Feednack>
    );
  }

  if (schemaResults.status === "error") {
    return <Feednack>An unexpected error happened</Feednack>;
  }

  if (initialData && (initialData as any).__typename) {
    delete (initialData as any).__typename;
  }

  return (
    <Form
      initialValues={initialValues}
      layout="vertical"
      form={form}
      style={initialValues ? { padding: "24px 0" } : {}}
      onValuesChange={(changedValues, values) => {
        if (changedValues.format) {
          form.setFieldsValue({
            prefix: "",
            suffix: "",
            overrideFormatting: "",
          });
        }

        if (
          Object.keys(changedValues).includes("overrideFormatting") &&
          typeof values.overrideFormatting === "undefined"
        ) {
          form.setFieldsValue({ overrideFormatting: "" });
        }
        if (changedValues.drills?.type) {
          form.setFieldsValue({
            drills: {
              type: changedValues.drills?.type,
              values: undefined,
            },
          });
        }
        debouncedOnSave(form.getFieldsValue());
      }}
    >
      <Space direction="vertical" size={24} style={{ width: "100%" }}>
        {usage.length > 0 ? <UsageAlert type="metric" usage={usage} /> : null}
        {error && error.length > 0 ? <ErrorRenderer error={error} /> : null}
        <WlyCard
          title={<Typography.Title level={5}>Aggregation</Typography.Title>}
        >
          <Form.Item shouldUpdate={true}>
            {() => {
              const isColumnNameDisabled = (value) => {
                if (["COUNT", "CUMULATIVE_COUNT"].includes(value)) return true;
                return false;
              };

              const columnNameDisabled = isColumnNameDisabled(
                form.getFieldValue("expression")
              );

              return (
                <>
                  <Form.Item
                    name={["expression"]}
                    label="Select an aggregation type"
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Select>
                      <Select.Option value="COUNT">Count</Select.Option>
                      <Select.Option value="COUNT_DISTINCT">
                        Count Distinct
                      </Select.Option>
                      <Select.Option value="CUMULATIVE_COUNT">
                        Cumulative Count
                      </Select.Option>
                      <Select.Option value="MIN">Min</Select.Option>
                      <Select.Option value="MAX">Max</Select.Option>
                      <Select.Option value="AVG">Average</Select.Option>
                      <Select.Option value="SUM">Sum</Select.Option>
                      <Select.Option value="MEDIAN">Median</Select.Option>
                      <Select.Option value="CUMULATIVE_SUM">
                        Cumulative Sum
                      </Select.Option>
                    </Select>
                  </Form.Item>
                  <Form.Item
                    name={["columnName"]}
                    label="Select the column used for aggregation"
                    rules={[
                      {
                        required: !columnNameDisabled,
                        message: "Column is required",
                      },
                    ]}
                    style={{
                      display: columnNameDisabled ? "none" : "inherit",
                    }}
                  >
                    <Select
                      showSearch={true}
                      optionFilterProp="children"
                      disabled={columnNameDisabled}
                      popupMatchSelectWidth={false}
                    >
                      {Object.keys(schemaResults.data)
                        .filter((k) => {
                          const data = schemaResults.data[k];
                          return form.getFieldValue("expression") ===
                            "COUNT_DISTINCT"
                            ? data.domain
                            : data.domain === "NUMERIC";
                        })
                        .map((k) => {
                          const data = schemaResults.data[k];
                          return (
                            <Select.Option key={k} value={k}>
                              <TypeRenderer
                                domain={data.domain}
                                formula={data.type === "FORMULA"}
                              />{" "}
                              {k}
                            </Select.Option>
                          );
                        })}
                    </Select>
                  </Form.Item>
                  <div style={{ paddingTop: 24 }}></div>
                  <Form.Item style={{ marginBottom: 0 }} shouldUpdate={true}>
                    {() => {
                      const currentValue = form.getFieldValue(["operator"]);
                      const condition = form.getFieldValue(["condition"]);
                      return (
                        <Form.Item style={{ marginBottom: 0 }} name="operator">
                          <div>
                            Optionally, filter rows matching{" "}
                            <Button
                              onClick={() => {
                                form.setFieldsValue({
                                  operator:
                                    currentValue === "or" ? "and" : "or",
                                  condition,
                                });
                                onSave(form.getFieldsValue());
                              }}
                              size="small"
                            >
                              {currentValue === "or" ? "any" : "all"}
                            </Button>{" "}
                            of the following:
                          </div>
                        </Form.Item>
                      );
                    }}
                  </Form.Item>
                  <Form.Item style={{ marginBottom: 0 }} shouldUpdate={true}>
                    {() => {
                      return (
                        <Form.List name="condition">
                          {(fields, { add, remove }) => (
                            <Space
                              style={{ width: "100%" }}
                              direction="vertical"
                            >
                              {fields.map((field, i) => {
                                const value = form.getFieldValue([
                                  "condition",
                                  field.name,
                                ]);
                                return (
                                  <FilterItem
                                    availableDimensions={Object.keys(
                                      schemaResults.data
                                    ).map((k) => {
                                      return {
                                        key: k,
                                        label: k,
                                        type: "standard",
                                        domain: schemaResults.data[k].domain,
                                      };
                                    })}
                                    autocomplete={autocomplete}
                                    keyName={"column"}
                                    filter={value}
                                    onDelete={() => remove(i)}
                                    onChange={(f) => {
                                      const condition = form.getFieldValue([
                                        "condition",
                                      ]);
                                      form.setFieldsValue({
                                        condition: (
                                          condition as (
                                            | BinaryFilter
                                            | UnaryFilter
                                          )[]
                                        ).map((c, ci) => {
                                          if (ci === i) {
                                            return f;
                                          }
                                          return c;
                                        }),
                                      });
                                      onSave(form.getFieldsValue());
                                    }}
                                    key={i}
                                  />
                                );
                              })}
                              <Form.Item>
                                <Button
                                  size="small"
                                  type="dashed"
                                  onClick={() => {
                                    const filterItem: UnaryFilterItem = {
                                      column: Object.keys(
                                        schemaResults.data
                                      )[0],
                                      operator: "set",
                                    };
                                    return add(filterItem);
                                  }}
                                  block={true}
                                >
                                  Add Filter
                                </Button>
                              </Form.Item>
                            </Space>
                          )}
                        </Form.List>
                      );
                    }}
                  </Form.Item>
                </>
              );
            }}
          </Form.Item>
        </WlyCard>
        <WlyCard
          title={<Typography.Title level={5}>Description</Typography.Title>}
        >
          <>
            {" "}
            <Form.Item noStyle={true} shouldUpdate={true}>
              {() => {
                const generatedExpression = generateName(
                  form.getFieldValue("expression"),
                  table.name,
                  form.getFieldValue("columnName")
                );
                if (
                  form.getFieldValue("overrideName") === undefined ||
                  form.getFieldValue("overrideName") === null
                ) {
                  return (
                    <Form.Item>
                      <div>
                        <div>Your metric name</div>
                        <div>
                          <span>
                            <b>{generatedExpression}</b>
                          </span>
                          <span style={{ marginLeft: 12 }}>
                            <Button
                              type="text"
                              onClick={() =>
                                form.setFieldsValue({
                                  overrideName: generatedExpression,
                                })
                              }
                            >
                              edit
                            </Button>
                          </span>
                        </div>
                      </div>
                    </Form.Item>
                  );
                }
                return (
                  <Form.Item
                    name={["overrideName"]}
                    label="Give your metric a name"
                    rules={[
                      {
                        required: true,
                        message: "Metric name is required",
                      },
                    ]}
                  >
                    <Input
                      placeholder="Your metric name"
                      suffix={
                        <CloseCircleOutlined
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            form.setFieldsValue({ overrideName: null });
                            onSave(form.getFieldsValue());
                          }}
                        />
                      }
                    />
                  </Form.Item>
                );
              }}
            </Form.Item>
            <Form.Item
              name={["hidden"]}
              label={
                <Typography.Text>
                  Hide your metric from the interface{" "}
                  <Tooltip title="Useful when you want to hide metrics that are supposed to be used only in drills or other computed metrics.">
                    <InfoCircleOutlined />
                  </Tooltip>
                </Typography.Text>
              }
              valuePropName="checked"
            >
              <Switch />
            </Form.Item>
            <Form.Item
              name={["description"]}
              label="Give a description to your metric"
            >
              <Input.TextArea placeholder="Your description" />
            </Form.Item>
            {object ? (
              <Form.Item name={["hierarchyPath"]} label="Hierarchy path">
                <Input />
              </Form.Item>
            ) : undefined}
          </>
        </WlyCard>
        <WlyCard
          id={attachementId.current}
          title={<Typography.Title level={5}>Drill downs</Typography.Title>}
        >
          <DrillsEditor
            id={attachementId.current}
            tables={tables}
            form={form}
            view={selectedView}
            parentTable={table}
          />
        </WlyCard>
        <WlyCard title={<Typography.Title level={5}>Format</Typography.Title>}>
          <MetricFormatFormItem
            object={object}
            form={form}
            locale={user.locale}
          />
        </WlyCard>
        {onDelete && (
          <WlyCardDangerZone
            title="Danger Zone"
            button={
              <Button
                type="primary"
                danger={true}
                onClick={onDelete ? onDelete : undefined}
              >
                Delete
              </Button>
            }
          >
            Click here to permanently delete this metric. This cannot be undone.
          </WlyCardDangerZone>
        )}
      </Space>
    </Form>
  );
}

export default compose<Props, IMetricEditorProps>(WithOrg)(MetricEditor);
