export interface IRecordDocument {
  documentId: string;
  name: string;
  objectId: string;
  recordId: string;
  content: string;
}

const documents: IRecordDocument[] = [
  {
    documentId: `corse`,
    name: `Catégorie Ambiant - P3 2024`,
    objectId: `30`,
    recordId: `CORSE`,
    content: `
# Catégorie Ambiant - P3 2024

### Executive Summary
La performance de la distribution du produit Tropicana sur la **Corse** affiche une **sous-performance** par rapport à la moyenne nationale. Malgré une croissance du chiffre d'affaires de **+9.2%** sur la catégorie Ambiant, celle-ci reste en deçà de la moyenne nationale de **+33%**. Des détails plus approfondis sur les tendances de distribution sont disponibles dans les sections ultérieures du rapport.

La **Part de Linéaire (PDL)** et la **Distribution Numérique (DN)** sur la Corse révèlent des écarts significatifs par rapport à la moyenne nationale. La PDL Ambiant est inférieure de **-0.73%** à la moyenne nationale, tandis que la DN Ambiant est en moyenne de **-7.81%**. Ces écarts sont particulièrement marqués chez certains détaillants tels que **Carrefour**, **Leclerc**, et **U Enseigne**. Des ajustements stratégiques peuvent être nécessaires pour améliorer la distribution de produits clés tels que **Essentiels Vitalité 1L** et **Bollywood 1L**, tout en tenant compte des produits déjà sur-distribués comme **Orange avec Pulpe 4x25CL**.

----

### Quelle est la situation sur le CA ?

Sur la P3 Nielsen, sur la catégorie Ambiant, la Corse a connu une croissance de CA de *+9.2%*, qui est sous la moyenne nationale qui est à *+33%*, ce qui indique une sous performance du secteur par rapport au reste du pays, malgré une croissance.

### Quelle est la situation actuelle par rapport au reste du Pays ?

#### Point sur la PDL

La PDL ambiant est inférieure de *-0.73%* par rapport à la moyenne nationale, ce qui indique un **retard** par rapport à la moyenne nationale. La PDL Ambiant de la corse est à *10,1%* alors que la moyenne sur la France est à *10.8%*.

Cet écart est surtout visible sur **Carrefour** (*-2.96%*), **Leclerc** (*-0.64%*) et sur **U Enseigne** (*-0.56%*).

{% toggle name="Détails" %}
{% rca file="stores-today.csv" metric="ambiant_pdl_gap_vs_france" dimension="retailer" /%}
{% /toggle %}

#### Point sur la DN

La DN Ambiant est en moyenne de *-7.81%* par rapport à la moyenne nationale, ce qui indique un **certain retard** dans la distribution sur ce secteur. La DN Ambiant Globale de la Corse est à *77,8%* alors que la DN Ambiant Globale est en moyenne de *85,7%* sur la France.

Les disparités les plus fortes sont sur **Leclerc** (-10.09%) et sur **U Enseigne** (-12.62%).

{% toggle name="Détails" %}
{% rca file="stores-today.csv" metric="ambiant_global_dn_gap_vs_france" dimension="retailer" /%}
{% /toggle %}

#### Point sur la distribution des produits

On remarque que certains produits ont des retards de distribution importants par rapport à la moyenne Française :
- **Essentiels Vitalité 1L** (-37%)
- **Bollywood 1L** (-32%)
- **Réveil fruité Ambiant 1L** (-24%)
- **Orange sans Pulpe 2x1L** (-23%)

Dans le même temps, certains produits sont sur-distribué par rapport à la moyenne française :
- **Orange avec Pulpe 4x25CL** (+7.6%)

{% toggle name="Détails" %}
{% rca file="product-today.csv" metric="ambiant_global_dn" dimension="product_name" /%}
{% /toggle %}

### Quels ont été les changements marquants en P3 ?

#### Point sur la PDL

La PDL a baissée de *-0.19%* sur la période, ce qui est une sous performance par rapport au reste de la France dans laquelle on a vu une baisse de *-0.04%*.

D'un point de vue positif, la PDL Ambiant a progressé de *+0.83%* sur **AMC (Casino)**, ce qui est une **sur-performance** par rapport au reste de la France qui a régressé de *-0.41%* sur AMC dans le même temps.

D'un point de vue négatif, la PDL Ambiant sur l'enseigne **U Enseigne** est en récession avec une baisse de *-2.91%* sur la période, alors que la moyenne nationale est en légère progression (*+0.1%*), ce qui indique une **sous-performance** sur cette enseigne.

{% toggle name="Détails" %}
{% rca file="stores-diff.csv" metric="ambiant_pdl_variation_vs_france" dimension="retailer" /%}
{% /toggle %}

#### Point sur la DN

La DN Ambiant a augmenté de *+1.56%* sur la période, notamment dû au travail sur Amc et U Enseigne. Cette croissance est bien supérieure à celle observée dans le reste de la France qui est de *-0.08%*.

D'un point de vue négatif, la DN Ambiant sur la sous enseigne **Centre E. Leclerc** est en récession avec une baisse de *-0.86%* sur la période, ce qui est presque dix fois plus important que que la moyenne nationale qui est en très légère récession (*-0.1%*), ce qui indique une sous-performance sur cette enseigne.

{% toggle name="Détails" %}
{% rca file="stores-diff.csv" metric="ambiant_global_dn_variation_vs_france" dimension="sub_retailer" /%}
{% /toggle %}

#### Point sur la distribution des produits

3 produits ont perdus plus de 2% de distribution durant cette période :
- **Raisin 1L** (*-10%*)
- **Pomme Origine France 1L** (*-4.56%*)
- **Orange avec Pulpe 1.5L** (*-3.68%*)
- **Multifruits Tropical 1.5L** (*-2.75%*)

Dans le même temps, 5 produits ont monté en distribution de plus de 5% :
- **Orange avec Pulpe 4x25CL** (*+9.1%*)
- **Orange avec Pulpe 2x1L** (*+8.68%*)
- **Samba 1L** (*+6.46%*)
- **Bollywood 1L** (*+6.15%*)
- **Multivitaminés 1,5L** (*+5.56%*)

{% toggle name="Détails" %}
{% rca file="product-diff.csv" metric="ambiant_global_dn_variation" dimension="product_name" /%}
{% /toggle %}
    `,
  },
  {
    documentId: `corse`,
    name: `Catégorie Ambiant - P3 2024`,
    objectId: `50`,
    recordId: `CORSE`,
    content: `
# Catégorie Ambiant - P3 2024

### Executive Summary
La performance de la distribution du produit Tropicana sur la **Corse** affiche une **sous-performance** par rapport à la moyenne nationale. Malgré une croissance du chiffre d'affaires de **+9.2%** sur la catégorie Ambiant, celle-ci reste en deçà de la moyenne nationale de **+33%**. Des détails plus approfondis sur les tendances de distribution sont disponibles dans les sections ultérieures du rapport.

La **Part de Linéaire (PDL)** et la **Distribution Numérique (DN)** sur la Corse révèlent des écarts significatifs par rapport à la moyenne nationale. La PDL Ambiant est inférieure de **-0.73%** à la moyenne nationale, tandis que la DN Ambiant est en moyenne de **-7.81%**. Ces écarts sont particulièrement marqués chez certains détaillants tels que **Carrefour**, **Leclerc**, et **U Enseigne**. Des ajustements stratégiques peuvent être nécessaires pour améliorer la distribution de produits clés tels que **Essentiels Vitalité 1L** et **Bollywood 1L**, tout en tenant compte des produits déjà sur-distribués comme **Orange avec Pulpe 4x25CL**.

----

### Quelle est la situation sur le CA ?

Sur la P3 Nielsen, sur la catégorie Ambiant, la Corse a connu une croissance de CA de *+9.2%*, qui est sous la moyenne nationale qui est à *+33%*, ce qui indique une sous performance du secteur par rapport au reste du pays, malgré une croissance.

### Quelle est la situation actuelle par rapport au reste du Pays ?

#### Point sur la PDL

La PDL ambiant est inférieure de *-0.73%* par rapport à la moyenne nationale, ce qui indique un **retard** par rapport à la moyenne nationale. La PDL Ambiant de la corse est à *10,1%* alors que la moyenne sur la France est à *10.8%*.

Cet écart est surtout visible sur **Carrefour** (*-2.96%*), **Leclerc** (*-0.64%*) et sur **U Enseigne** (*-0.56%*).

{% toggle name="Détails" %}
{% rca file="stores-today.csv" metric="ambiant_pdl_gap_vs_france" dimension="retailer" /%}
{% /toggle %}

#### Point sur la DN

La DN Ambiant est en moyenne de *-7.81%* par rapport à la moyenne nationale, ce qui indique un **certain retard** dans la distribution sur ce secteur. La DN Ambiant Globale de la Corse est à *77,8%* alors que la DN Ambiant Globale est en moyenne de *85,7%* sur la France.

Les disparités les plus fortes sont sur **Leclerc** (-10.09%) et sur **U Enseigne** (-12.62%).

{% toggle name="Détails" %}
{% rca file="stores-today.csv" metric="ambiant_global_dn_gap_vs_france" dimension="retailer" /%}
{% /toggle %}

#### Point sur la distribution des produits

On remarque que certains produits ont des retards de distribution importants par rapport à la moyenne Française :
- **Essentiels Vitalité 1L** (-37%)
- **Bollywood 1L** (-32%)
- **Réveil fruité Ambiant 1L** (-24%)
- **Orange sans Pulpe 2x1L** (-23%)

Dans le même temps, certains produits sont sur-distribué par rapport à la moyenne française :
- **Orange avec Pulpe 4x25CL** (+7.6%)

{% toggle name="Détails" %}
{% rca file="product-today.csv" metric="ambiant_global_dn" dimension="product_name" /%}
{% /toggle %}

### Quels ont été les changements marquants en P3 ?

#### Point sur la PDL

La PDL a baissée de *-0.19%* sur la période, ce qui est une sous performance par rapport au reste de la France dans laquelle on a vu une baisse de *-0.04%*.

D'un point de vue positif, la PDL Ambiant a progressé de *+0.83%* sur **AMC (Casino)**, ce qui est une **sur-performance** par rapport au reste de la France qui a régressé de *-0.41%* sur AMC dans le même temps.

D'un point de vue négatif, la PDL Ambiant sur l'enseigne **U Enseigne** est en récession avec une baisse de *-2.91%* sur la période, alors que la moyenne nationale est en légère progression (*+0.1%*), ce qui indique une **sous-performance** sur cette enseigne.

{% toggle name="Détails" %}
{% rca file="stores-diff.csv" metric="ambiant_pdl_variation_vs_france" dimension="retailer" /%}
{% /toggle %}

#### Point sur la DN

La DN Ambiant a augmenté de *+1.56%* sur la période, notamment dû au travail sur Amc et U Enseigne. Cette croissance est bien supérieure à celle observée dans le reste de la France qui est de *-0.08%*.

D'un point de vue négatif, la DN Ambiant sur la sous enseigne **Centre E. Leclerc** est en récession avec une baisse de *-0.86%* sur la période, ce qui est presque dix fois plus important que que la moyenne nationale qui est en très légère récession (*-0.1%*), ce qui indique une sous-performance sur cette enseigne.

{% toggle name="Détails" %}
{% rca file="stores-diff.csv" metric="ambiant_global_dn_variation_vs_france" dimension="sub_retailer" /%}
{% /toggle %}

#### Point sur la distribution des produits

3 produits ont perdus plus de 2% de distribution durant cette période :
- **Raisin 1L** (*-10%*)
- **Pomme Origine France 1L** (*-4.56%*)
- **Orange avec Pulpe 1.5L** (*-3.68%*)
- **Multifruits Tropical 1.5L** (*-2.75%*)

Dans le même temps, 5 produits ont monté en distribution de plus de 5% :
- **Orange avec Pulpe 4x25CL** (*+9.1%*)
- **Orange avec Pulpe 2x1L** (*+8.68%*)
- **Samba 1L** (*+6.46%*)
- **Bollywood 1L** (*+6.15%*)
- **Multivitaminés 1,5L** (*+5.56%*)

{% toggle name="Détails" %}
{% rca file="product-diff.csv" metric="ambiant_global_dn_variation" dimension="product_name" /%}
{% /toggle %}
    `,
  },
];

export const findAllDocuments = (objectId: string, recordId: string) =>
  documents.filter((d) => d.objectId === objectId && d.recordId === recordId);

export const findDocument = (
  objectId: string,
  recordId: string,
  documentId: string
) =>
  documents.find(
    (d) =>
      d.objectId === objectId &&
      d.recordId === recordId &&
      d.documentId === documentId
  );
