import type { Query } from "@cubejs-client/core";
import Highcharts from "highcharts";
import { HighchartsReact } from "highcharts-react-official";
import _ from "lodash";
import * as React from "react";
import type { ChartOption } from "../../../containers/chart-options/ChartOptions";
import { getChartOptionValue } from "../../../containers/chart-options/ChartOptions";
import type { UserLocale } from "../../../interfaces/user";
import type PaletteGenerator from "../../palette/utils/PaletteGenerator";
import type { Formatter } from "../domain";
import { localizeHighchart } from "../domain";
import {
  customFormatter,
  optionsHelper,
  renderSerieTooltip,
} from "../utils/optionsHelper";
interface IPieChartProps {
  height: number;
  width?: number;
  data: Array<{ [key: string]: string | number | boolean }>;
  config: PieChartConfig;
  tinyLegend?: boolean;
  unit?: "second" | "percent";
  onDrill?: (q: Query) => void;
  chartOptions?: ChartOption;
  palette: PaletteGenerator;
  locale: UserLocale;
}

interface PieChartConfig {
  y: {
    key: string;
    label?: string;
    serieLabel?: string;
    color?: string;
    canDrill: (xValue: string, yValue?: string) => Query | null;
    dashed?: boolean;
    formatter: Formatter;
  };
  nameKey: string;
  showLabels?: boolean;
}

export default class PieChart extends React.Component<IPieChartProps> {
  ref?: Highcharts.Chart;

  componentDidUpdate(prevProps: IPieChartProps) {
    if (
      (prevProps.width !== this.props.width ||
        prevProps.height !== this.props.height ||
        !_.isEqual(this.props.config, prevProps.config)) &&
      this.ref &&
      this.ref.reflow
    ) {
      this.ref.reflow();
    }
  }

  shouldComponentUpdate(nextProps: Readonly<IPieChartProps>): boolean {
    if (!_.isEqual(this.props.width, nextProps.width)) return true;
    if (!_.isEqual(this.props.height, nextProps.height)) return true;
    if (!_.isEqual(this.props.data, nextProps.data)) return true;
    if (!_.isEqual(this.props.tinyLegend, nextProps.tinyLegend)) return true;
    if (!_.isEqual(this.props.unit, nextProps.unit)) return true;
    if (!_.isEqual(this.props.chartOptions, nextProps.chartOptions))
      return true;
    if (
      !_.isEqualWith(this.props.palette, nextProps.palette, (a, b) =>
        typeof a === "function" || typeof b === "function" ? true : undefined
      )
    ) {
      return true;
    }
    return false;
  }

  public render() {
    const {
      height,
      config,
      data,
      width,
      onDrill,
      chartOptions,
      palette,
      locale,
    } = this.props;

    const yFormatter = config.y.formatter;

    localizeHighchart(Highcharts, locale);

    const defaultOptions = optionsHelper(
      height,
      width,
      locale,
      "numeric",
      yFormatter,
      undefined,
      undefined,
      chartOptions
    );

    const options: Highcharts.Options = {
      ...defaultOptions,
      series: [
        {
          type: "pie",
          color: config.y.color,
          name: config.y.serieLabel,
          data: data.map((d, i) => {
            const dimKeys = Object.keys(d).filter((k) => k !== config.y.key);
            const dimValue = dimKeys.map((k) => d[k] ?? "∅");

            const serieKey = dimValue.join(",");
            const serieDefaultLabel = dimValue.join(", ");

            const label =
              chartOptions?.series?.[serieKey]?.label ?? serieDefaultLabel;
            return {
              x: (d[config.y.key] as any) ? (d[config.y.key] as any) : 0,
              // todo : move to upper level
              y: (d[config.y.key] as any)
                ? typeof d[config.y.key] === "string"
                  ? parseFloat(d[config.y.key] as string)
                  : (d[config.y.key] as number)
                : 0,
              label: label,
              name: serieKey,
              color:
                chartOptions?.series?.[serieKey]?.color ??
                palette.getColorAtIndex(i),
            };
          }),
          events: {
            click: onDrill
              ? (e) => {
                  const xValue = e.point.name.toString();
                  const q = config.y.canDrill(xValue.toString());
                  if (q) {
                    return onDrill(q);
                  }
                }
              : undefined,
          },
          cursor: "pointer",
          tooltip: renderSerieTooltip({
            data: data,
            locale: locale,
            alternateSerieDisplayLabel: config.y.label,
            seriesLength: 1,
            formatter: config.y.formatter,
          }),
        },
      ],

      plotOptions: {
        ...defaultOptions.plotOptions,
        pie: {
          dataLabels: {
            style: {
              fontSize: "0.8em",
              fontWeight: "normal",
            },
            enabled: config.showLabels,
            formatter: function () {
              const label = (this as any).point?.label ?? this.key;
              let formattedValue = customFormatter(
                (this as any).y,
                locale,
                config.y.formatter
              );
              if (
                getChartOptionValue(
                  chartOptions,
                  "pie-labels-in-percent",
                  "pie"
                )
              ) {
                formattedValue = Number(
                  this.point.percentage / 100
                ).toLocaleString(undefined, {
                  style: "percent",
                  minimumFractionDigits: 1,
                });
              }
              return `${label}: ${formattedValue}`;
            },
          },
        },
      },
    };

    return (
      <div style={{ height, width: "100%" }}>
        <HighchartsReact highcharts={Highcharts} options={options} />
      </div>
    );
  }
}
