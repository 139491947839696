import type { IUserGroup } from "./group";
import type { INotificationWorkflow } from "./notification";
import type { IOrg, IPartnerPortal, IUserAttributeMeta } from "./org";
import type { IRealm, IUserRealmType } from "./realm";
import type { ITopic } from "./topic";

export enum IUserFeatureType {
  TMP_USE_LAGOON_NEXT = "tmp-useLagoonNext",
  TMP_PARAMETRIC_MEASURE = "tmp-parametricMeasures",
  TMP_OBJECT_DOCUMENTS = "tmp-object-documents",
  TMP_OBJECT_QUERY_BUILDER = "tmp-object-query-builder",
}

interface IUserFeature {
  apiName: IUserFeatureType;
}

export interface IUserGravatarInfo {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  signUpToken?: string;
  gravatar?: string;
  avatarColor: string;
}

export interface IUserGravatarInfoWithGuestUser extends IUserGravatarInfo {
  isPublicGuestUser: boolean;
}

export interface IUser {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  gravatar?: string;
  avatarColor: string;
  type: UserTypeType;
  portal?: IPartnerPortal;
  isAdmin: boolean;
  isPublicGuestUser: boolean;
  roles: IUserRole[];
  signUpToken?: string;
  realm: {
    realm: IRealm;
    type: IUserRealmType;
  };
  topicSubscriptions?: IUserTopicSubscription[];
  attributes: Array<IUserAttribute>;
  featureGrants: {
    feature: IUserFeature;
  }[];
  locale: UserLocale;
  lastLoginDate: string;
  createdAt: string;
}

export enum UserLocale {
  fr_FR = "fr_FR",
  en_US = "en_US",
}

export type UserTypeType = "STANDARD" | "PORTAL";

interface IUserAttribute {
  id: string;
  value: string;
  userAttributeMeta: IUserAttributeMeta;
}

interface IUserWithGroupInfo extends IUser {
  groups: IUserGroup[];
  attributes: IUserAttribute[];
}

export interface IUserRole {
  id: string;
  role: IUserRoleType;
  org: IOrg;
  homeCover?: string;
  v2HomeConfig?: string;
  lastVisitedReports: string;
  user: IUser;
}

export interface IUserRoleWithGroups extends IUserRole {
  user: IUserWithGroupInfo;
}

export enum IUserRoleType {
  ADMIN_BUILDER = "owner",
  BUILDER = "member",
  EDITOR = "reportBuilder",
  VIEWER = "viewer",
  REPORT_DOWNLOADER = "reportDownloader",
  REPORT_VIEWER = "reportViewer",
}

type RolesImportance = {
  [key in IUserRoleType]: number;
};

export const rolesImportance: RolesImportance = {
  reportViewer: 0,
  reportDownloader: 1,
  viewer: 2,
  reportBuilder: 3,
  member: 4,
  owner: 5,
};

export interface IUserTopicSubscription {
  id: string;
  user?: IUser;
  topic?: ITopic;
  workflow?: INotificationWorkflow;
  isDeleted?: boolean;
  org: IOrg;
  createdAt: string;
}

export interface IUserTopicSubscriptionWorkflow {
  id: string;
  subscription?: IUserTopicSubscription;
  notificationWorkflow?: INotificationWorkflow;
  isDeleted?: boolean;
  org: IOrg;
  createdAt: string;
}
