import { LinkOutlined } from "@ant-design/icons";
import { Button } from "antd";
import _ from "lodash";
import React from "react";
import type { InjectedAntUtilsProps } from "../../../../components/ant-utils/withAntUtils";
import { withAntUtils } from "../../../../components/ant-utils/withAntUtils";
import { compose } from "../../../../components/compose/WlyCompose";
import usePrevious from "../../../../components/hooks/usePrevious";
import Feednack from "../../../../components/layout/feedback/feedback";
import Loading from "../../../../components/layout/feedback/loading";
import type {
  ExtendedMeasureType,
  MeasureType,
} from "../../../../components/measures/measure-table/MeasureTable";
import { SplitView } from "../../../../components/resizable/SplitView";
import type { AsyncData } from "../../../../helpers/typescriptHelpers";
import type { IDestination } from "../../../../interfaces/destinations";
import type { IObject, IObjectProperty } from "../../../../interfaces/object";
import type {
  IDataset,
  IDatasetRelationship,
} from "../../../../interfaces/sources";
import type {
  IDimension,
  IGeoDimension,
  IMetric,
  ISemanticGroup,
  IStandardDimension,
  ITable,
} from "../../../../interfaces/table";
import type { SchemaResult } from "../../../../interfaces/transformations";
import type {
  IDimensionPayload,
  IExplorationUpdatePayload,
  IMetricPayload,
  IObjectUpdatePayload,
  IRelationshipPayload,
  ISemanticGroupPayload,
  ITablePayload,
  _IObjectPropertyCreatePayload,
  _IObjectPropertyUpdatePayload,
} from "../../../../services/ExplorationMutationService";
import type { InjectedOrgProps } from "../../../orgs/WithOrg";
import WithOrg from "../../../orgs/WithOrg";
import * as Toolbar from "../../../spreadsheet/toolbar/Toolbar";
import type { IExplorationMeasureUsage, TabData } from "../domain";
import type {
  DisabledTableConfigurationCapabilities,
  ExplorationDimensionCreateOperation,
  ExplorationMetricCreateOperation,
  ExplorationOperation,
  ExplorationSemanticGroupCreateOperation,
  ExplorationTableCreateOperation,
  ExplorationUpdateOperation,
  IDimensionFormInput,
  IMeasureDimension,
  IMeasureIncomingRelationship,
  IMeasureMetric,
  IMeasureSemanticGroup,
  IMeasureTable,
  IMetricFormInput,
  IObjectConfigurationInfos,
  IObjectFormInput,
  IObjectPropertyConfiguration,
  IObjectPropertyFormInput,
  ISemanticGroupFormInput,
  ObjectPropertyCreateOperation,
  SelectedItems,
  StaleElements,
} from "./domain";
import { TableType, generateFakeId, isFakeId } from "./domain";
import MeasureEditorTree from "./measure-editor-tree/MeasureEditorTree";
import { MeasureEditor } from "./measure-editor/MeasureEditor";
import {
  generateDimensionName,
  validateDimensionFormInput,
} from "./measure-editor/dimension-editor/DimensionEditor";
import { generateComputedMetricName } from "./measure-editor/metric-editor/CalculatedMetricEditor";
import {
  generateMetricName,
  validateMetricFormInput,
} from "./measure-editor/metric-editor/MetricEditor";
import { validateObjectFormInput } from "./measure-editor/object-editor/ObjectEditor";
import { validateObjectPropertyFormInput } from "./measure-editor/properties-editor/PropertiesEditor";
import { validateTableFormInput } from "./measure-editor/table-editor/TableEditor";
import { parseRowLevelFilterValue } from "./row-level-filters/domain";
interface ITableConfigurationEditProps {
  currentId: string;
  displayName: string;
  rowLevelFilters?: string;
  tables: ITable[];
  usage?: IExplorationMeasureUsage;
  selectedItems: SelectedItems;
  onClick: (selectedItems: SelectedItems) => void;
  fetch: (currentId: string) => Promise<void>;
  removeStaleElement: (operationId: string) => void;
  pushStaleElement: (e: ExplorationOperation | ExplorationOperation[]) => void;
  staleElements: StaleElements;
  dataStore: {
    [tableKey: string]: TabData;
  };
  fetchTableSchema: (
    tablesSchemas: Array<{ datasetId: string; viewId: string }>
  ) => Promise<SchemaResult[]>;
  allIncomingDatasetRelationships: IDatasetRelationship[];
  allDatasets: IDataset[];
  allObjects?: IObject[];
  updateStaleElement: (
    type: ExtendedMeasureType,
    objectId: string,
    data: any
  ) => void;
  removeAllStaleElements: () => void;
  worskspaceRoute?: string;
  displayDescription?: string;
  save: (payload: IExplorationUpdatePayload) => Promise<{
    resourceType: ExtendedMeasureType;
    resourceId: string;
  }>;
  disabledCapabilities?: Array<DisabledTableConfigurationCapabilities>;
  overrideHeaderName?: string;
  objectInfos?: IObject;
  currentWarehouse: IDestination;
}

type Props = ITableConfigurationEditProps &
  InjectedOrgProps &
  InjectedAntUtilsProps;

function TableConfigurationEdit(props: Props) {
  const {
    currentId,
    displayName,
    displayDescription,
    usage,
    onClick,
    selectedItems,
    staleElements,
    pushStaleElement,
    dataStore,
    fetchTableSchema,
    allIncomingDatasetRelationships,
    allDatasets,
    removeStaleElement,
    updateStaleElement,
    removeAllStaleElements,
    fetch,
    antUtils,
    worskspaceRoute,
    tables,
    rowLevelFilters,
    save,
    disabledCapabilities: disabledCapabilitiesProps,
    overrideHeaderName,
    objectInfos,
    allObjects,
    currentWarehouse,
  } = props;

  const [submitting, setSubmitting] = React.useState<boolean>(false);

  const disabledCapabilities = disabledCapabilitiesProps || [];
  const rowLevelAccess = parseRowLevelFilterValue(
    rowLevelFilters ? rowLevelFilters : ""
  );

  const convertStaleMetrics = (
    m: ExplorationMetricCreateOperation,
    tableName: string
  ): IMeasureMetric => {
    const isComputed = m.data && m.data.expression === "COMPUTED";
    return {
      id: m.operationId,
      name: isComputed
        ? generateComputedMetricName(m.data?.overrideName)
        : generateMetricName(
            tableName,
            m.data?.expression,
            m.data?.columnName,
            m.data?.overrideName
          ),
      description: m.data?.description,
      cubeName: m.operationId,
      subType:
        m.data && m.data.expression === "COMPUTED" ? "COMPUTED" : undefined,
      status: "create",
      hidden: m.data?.hidden,
      usage: (usage?.metrics || []).filter(
        (usedMetric) => usedMetric.resourceId === m.operationId
      ),
      rowLevelAccess: false,
      rawData: m.data,
    };
  };

  const convertStaleDimensions = (
    m: ExplorationDimensionCreateOperation
  ): IMeasureDimension => {
    return {
      id: m.operationId,
      name: generateDimensionName(
        m.data?.type,
        m.data?.columnName,
        m.data?.overrideName
      ),
      description: m.data?.description,
      cubeName: m.operationId,
      status: "create",
      usage: (usage?.metrics || []).filter(
        (usedMetric) => usedMetric.resourceId === m.operationId
      ),
      hidden: m.data?.hidden,
      rowLevelAccess: !!rowLevelAccess.condition.find(
        (c) => c.member === m.operationId
      ),
      rawData: m.data,
    };
  };

  const convertStaleProperty = (
    m: ObjectPropertyCreateOperation,
    schema: AsyncData<SchemaResult>,
    allTables: IMeasureTable[]
  ): IObjectPropertyConfiguration => {
    const formData: IObjectPropertyFormInput = {
      label: m.data.label,
      columnName: m.data.columnName,
      formatter: m.data.formatter,
      formatterConfig: m.data.formatterConfig,
      description: m.data?.description,
      columnDomain: m.data?.columnDomain,
      type: m.data.type,
      foreignKey: m.data.foreignKey,
      userPropertyMapping: m.data.userPropertyMapping,
      sortingAndFilteringColumn: m.data.sortingAndFilteringColumn,
      hierarchyPath: m.data.hierarchyPath,
    };
    return {
      ...formData,
      id: m.operationId,
      status: "create",
      hasError: validateObjectPropertyFormInput(
        formData,
        schema,
        allDatasets,
        allIncomingDatasetRelationships,
        allTables
      ),
    };
  };

  const convertStaleSemanticGroups = (
    m: ExplorationSemanticGroupCreateOperation
  ): IMeasureSemanticGroup => {
    return {
      id: m.operationId,
      name: m.data.name,
      status: "create",
      rawData: m.data,
    };
  };

  const generateRawDataFromDimension = (
    dimension: IDimension
  ): IDimensionFormInput => ({
    description: dimension.description,
    overrideName: dimension.overrideName,
    type: dimension.type,
    columnDomain: (dimension as IStandardDimension).columnDomain,
    columnName: (dimension as IStandardDimension).columnName,
    latitude: (dimension as IGeoDimension).latitude,
    longitude: (dimension as IGeoDimension).longitude,
    hidden: dimension.hidden,
    semanticGroupIds: dimension.semanticGroups.map((sg) => sg.id),
    customOrderingConfArray: dimension.customOrderingConfArray,
  });

  const generateRawDataFromProperty = (
    property: IObjectProperty
  ): IObjectPropertyFormInput => {
    const getType = () => {
      if (property.foreignKey) {
        return "foreignKey";
      }
      if (property.userPropertyMapping) {
        return "userProperty";
      }
      return "standard";
    };
    return {
      columnName: property.columnName,
      label: property.label,
      type: getType(),
      foreignKey: property.foreignKey?.id,
      description: property.description,
      formatter: property.formatter,
      formatterConfig: property.formatterConfig
        ? JSON.parse(property.formatterConfig)
        : undefined,
      columnDomain: property.columnDomain,
      userPropertyMapping: property.userPropertyMapping,
      sortingAndFilteringColumn: property.sortingAndFilteringColumn,
      hierarchyPath: property.hierarchyPath,
    } as IObjectPropertyFormInput;
  };

  const parseDrill = (d: string | undefined) => {
    if (!d) {
      return {
        type: "INHERIT",
      };
    }
    try {
      return JSON.parse(d);
    } catch (err) {
      return {
        type: "INHERIT",
      };
    }
  };

  const generateRawDataFromMetric = (metric: IMetric): IMetricFormInput => {
    let operator: "and" | "or" = "or";
    let condition = [];
    if (metric.filters) {
      try {
        const filters = JSON.parse(metric.filters);
        if (filters.length) {
          operator = Object.keys(filters[0])[0] as "and" | "or";
          condition = filters[0][Object.keys(filters[0])[0]];
        }
      } catch (err) {}
    }

    return {
      description: metric.description,
      expression: metric.expression,
      columnName: metric.columnName,
      overrideFormatting: metric.overrideFormatting,
      overrideName: metric.overrideName,
      prefix: metric.prefix,
      suffix: metric.suffix,
      format: metric.format,
      operator: operator,
      condition: condition,
      hidden: metric.hidden,
      drills: parseDrill(metric.drills),
      semanticGroupIds: metric.semanticGroups.map((sg) => sg.id),
      hierarchyPath: metric.hierarchyPath,
    };
  };

  const generateRawDataFromSemanticGroup = (
    semanticGroup: ISemanticGroup
  ): ISemanticGroupFormInput => {
    return {
      name: semanticGroup.name,
    };
  };

  const tempTables = [
    ...tables
      .filter(
        (m) =>
          !staleElements.find(
            (se) =>
              se.objectType === "table" &&
              se.type === "delete" &&
              se.objectId === m.id
          )
      )
      .map<IMeasureTable>((table) => {
        const incomingRelationship = table.incomingRelationships[0];
        const formattedIncomingRelationship:
          | IMeasureIncomingRelationship
          | undefined = incomingRelationship
          ? {
              id: incomingRelationship.id,
              from: incomingRelationship.from,
              to: incomingRelationship.to,
              parentId: incomingRelationship.left.id,
              type: incomingRelationship.type,
            }
          : undefined;

        const staleData = staleElements.find(
          (se) =>
            se.objectType === "table" &&
            se.type === "update" &&
            se.objectId === table.id
        ) as ExplorationTableCreateOperation;

        const baseDatasetId = staleData?.data?.datasetId
          ? staleData?.data?.datasetId
          : table.view.dataset.id;
        const baseViewId = staleData?.data?.viewId
          ? staleData?.data?.viewId
          : table.view.id;
        const blendedDatasetIds =
          staleData?.data && "blendedDatasetIds" in staleData?.data
            ? staleData?.data?.blendedDatasetIds
            : table.blendedDatasets?.map((dat) => dat.id);

        const schema: AsyncData<SchemaResult> = dataStore[baseDatasetId]?.data[
          baseViewId
        ]?.schema
          ? dataStore[baseDatasetId]?.data[baseViewId]?.schema
          : { status: "initial" };

        const getMeasureMetrics = (metrics: IMetric[]): IMeasureMetric[] => {
          return [
            ...metrics
              .filter(
                (m) =>
                  !staleElements.find(
                    (se) =>
                      se.objectType === "metric" &&
                      se.type === "delete" &&
                      se.objectId === m.id
                  )
              )
              .map<IMeasureMetric>((m) => {
                const state = staleElements.find(
                  (se) =>
                    se.objectType === "metric" &&
                    se.type !== "create" &&
                    se.objectId === m.id
                );
                const rawData =
                  state?.type === "create" || state?.type === "update"
                    ? state.data
                    : generateRawDataFromMetric(m);
                return {
                  id: m.id,
                  name: generateMetricName(
                    table.name,
                    rawData.expression,
                    rawData.columnName,
                    rawData.overrideName
                  ),
                  cubeName: m.cubeName,
                  description: m.description,
                  status: state?.type,
                  subType: m.expression === "COMPUTED" ? "COMPUTED" : undefined,
                  rowLevelAccess: false,
                  hidden: rawData && rawData.hidden ? rawData.hidden : m.hidden,
                  usage: (usage?.metrics || []).filter(
                    (usedMetric) => usedMetric.resourceId === m.id
                  ),
                  hierarchyPath: m.hierarchyPath,
                  rawData: rawData,
                };
              }),
            ...staleElements
              .filter(
                (se) =>
                  se.objectType === "metric" &&
                  se.type === "create" &&
                  se.parentTableId === table.id
              )
              .map<IMeasureMetric>((m: ExplorationMetricCreateOperation) =>
                convertStaleMetrics(m, table.name)
              ),
          ];
        };

        const getMeasureDimensions = (
          dimensions: IDimension[]
        ): IMeasureDimension[] => {
          return [
            ...dimensions
              .filter(
                (m) =>
                  !staleElements.find(
                    (se) =>
                      se.objectType === "dimension" &&
                      se.type === "delete" &&
                      se.objectId === m.id
                  )
              )
              .map<IMeasureDimension>((d) => {
                const state = staleElements.find(
                  (se) =>
                    se.objectType === "dimension" &&
                    se.type !== "create" &&
                    se.objectId === d.id
                );
                const rawData =
                  state?.type === "create" || state?.type === "update"
                    ? state.data
                    : generateRawDataFromDimension(d);
                const completeCubeName = `${table.cubeName}.${d.cubeName}`;

                return {
                  id: d.id,
                  name: generateDimensionName(
                    rawData.type,
                    rawData.columnName,
                    rawData.overrideName
                  ),
                  cubeName: d.cubeName,
                  description: d.description,
                  status: state?.type,
                  rowLevelAccess: !!rowLevelAccess.condition.find(
                    (c) => c.member === completeCubeName
                  ),
                  usage: (usage?.dimensions || []).filter(
                    (usedDimension) => usedDimension.resourceId === d.id
                  ),
                  hidden: rawData && rawData.hidden ? rawData.hidden : d.hidden,
                  rawData: rawData,
                };
              }),
            ...staleElements
              .filter(
                (se) =>
                  se.objectType === "dimension" &&
                  se.type === "create" &&
                  se.parentTableId === table.id
              )
              .map<IMeasureDimension>(convertStaleDimensions),
          ];
        };

        const getMeasureSemanticGroups = (
          semanticGroups: ISemanticGroup[]
        ): IMeasureSemanticGroup[] => {
          const result = [
            ...semanticGroups
              .filter(
                (m) =>
                  !staleElements.find(
                    (se) =>
                      se.objectType === "semanticGroup" &&
                      se.type === "delete" &&
                      se.objectId === m.id
                  )
              )
              .map<IMeasureSemanticGroup>((semanticGroup) => {
                const state = staleElements.find(
                  (se) =>
                    se.objectType === "semanticGroup" &&
                    se.type !== "create" &&
                    se.objectId === semanticGroup.id
                );
                const rawData =
                  state?.type === "create" || state?.type === "update"
                    ? state.data
                    : generateRawDataFromSemanticGroup(semanticGroup);

                return {
                  id: semanticGroup.id,
                  name: semanticGroup.name,
                  status: state?.type,
                  rawData: rawData,
                };
              }),
            ...staleElements
              .filter(
                (se) =>
                  se.objectType === "semanticGroup" &&
                  se.type === "create" &&
                  se.parentTableId === table.id
              )
              .map<IMeasureSemanticGroup>(convertStaleSemanticGroups),
          ];
          return result;
        };

        return {
          id: table.id,
          name: staleData ? staleData?.data?.name : table.name,
          cubeName: table.cubeName,
          viewCubeName: table.view.cubeName,
          incomingRelationship:
            staleData && staleData?.data?.incomingRelationship
              ? staleData?.data?.incomingRelationship
              : formattedIncomingRelationship,
          primaryKey: staleData?.data?.primaryKey
            ? staleData?.data?.primaryKey
            : table.primaryKey,
          viewId: baseViewId,
          datasetId: baseDatasetId,
          blendedDatasetIds,
          schema,
          drills:
            staleData && staleData.data && staleData.data.drills
              ? staleData.data.drills
              : parseDrill(table.drills),
          status: staleData ? staleData?.type : undefined,
          metrics: getMeasureMetrics(table.metrics),
          dimensions: getMeasureDimensions(table.dimensions),
          semanticGroups: getMeasureSemanticGroups(table.semanticGroups),
        };
      }),
    ...staleElements
      .filter((v) => v.objectType === "table" && v.type === "create")
      .map<IMeasureTable>((t: ExplorationTableCreateOperation) => {
        const foundMetrics = staleElements.filter(
          (e) =>
            e.objectType === "metric" &&
            e.type === "create" &&
            e.parentTableId === t.operationId
        );
        const foundDimensions = staleElements.filter(
          (e) =>
            e.objectType === "dimension" &&
            e.type === "create" &&
            e.parentTableId === t.operationId
        );
        const foundSemanticGroups = staleElements.filter(
          (e) =>
            e.objectType === "semanticGroup" &&
            e.type === "create" &&
            e.parentTableId === t.operationId
        );
        const schema: AsyncData<SchemaResult> = dataStore[t.data.datasetId]
          ?.data[t.data.viewId]?.schema
          ? dataStore[t.data.datasetId]?.data[t.data.viewId]?.schema
          : { status: "initial" };

        return {
          id: t.operationId,
          name: t.data?.name,
          cubeName: t.operationId,
          incomingRelationship: t.data?.incomingRelationship,
          metrics: foundMetrics.map((m: ExplorationMetricCreateOperation) =>
            convertStaleMetrics(m, t.data?.name)
          ),
          dimensions: foundDimensions.map(convertStaleDimensions),
          semanticGroups: foundSemanticGroups.map(convertStaleSemanticGroups),
          primaryKey: t.data?.primaryKey,
          viewId: t.data?.viewId,
          viewCubeName: t.data?.viewCubeName,
          datasetId: t.data?.datasetId,
          blendedDatasetIds: t.data?.blendedDatasetIds,
          schema: schema,
          drills: t.data.drills,
          status: t.type,
        };
      }),
  ];

  const getTempObjectDefinition = (original: IObject): IObjectFormInput => {
    const foundUpdate = staleElements.find(
      (se) => se.type === "update" && se.objectType === "object"
    );
    const data = foundUpdate
      ? (foundUpdate as ExplorationUpdateOperation).data
      : original;
    return {
      name: data.name,
      primaryKey: dataStore[original.table.view.dataset.id].primaryKey,
      primaryImage: data.primaryImage,
      primaryLabel: data.primaryLabel,
      datasetId: original.table.view.dataset.id,
      viewId: original.table.view.id,
    };
  };

  const tempObject: IObjectConfigurationInfos | undefined = objectInfos
    ? {
        ...getTempObjectDefinition(objectInfos),
        id: objectInfos.id,
        schema:
          dataStore[objectInfos.table.view.dataset.id].data[
            objectInfos.table.view.id
          ].schema,
        status: staleElements.find((se) => se.objectType === "object")?.type,
        hasError: validateObjectFormInput(
          getTempObjectDefinition(objectInfos),
          dataStore[objectInfos.table.view.dataset.id].data[
            objectInfos.table.view.id
          ].schema,
          allDatasets,
          allIncomingDatasetRelationships,
          tempTables
        ), // compute tableFormInput,
        properties: [
          ...(objectInfos?.properties || [])
            .filter((p) => {
              return !staleElements.find(
                (se) =>
                  se.objectType === "property" &&
                  se.type === "delete" &&
                  se.objectId === p.id
              );
            })
            .map<IObjectPropertyConfiguration>((property) => {
              const state = staleElements.find(
                (se) =>
                  se.objectType === "property" &&
                  se.type !== "create" &&
                  se.objectId === property.id
              );
              const rawData =
                state?.type === "create" || state?.type === "update"
                  ? state.data
                  : generateRawDataFromProperty(property);

              return {
                id: property.id,
                columnName: rawData.columnName,
                columnDomain: rawData.columnDomain,
                description: rawData.description,
                label: rawData.label,
                formatter: rawData.formatter,
                formatterConfig: rawData.formatterConfig,
                userPropertyMapping: rawData.userPropertyMapping,
                sortingAndFilteringColumn: rawData.sortingAndFilteringColumn,
                hierarchyPath: rawData.hierarchyPath,
                status: state?.type,
                rawData: rawData,
                type: rawData.type,
                foreignKey: rawData.foreignKey,
                hasError: validateObjectPropertyFormInput(
                  rawData,
                  dataStore[objectInfos.table.view.dataset.id].data[
                    objectInfos.table.view.id
                  ].schema,
                  allDatasets,
                  allIncomingDatasetRelationships,
                  tempTables
                ),
              };
            }),
          ...staleElements
            .filter(
              (se) => se.objectType === "property" && se.type === "create"
            )
            .map<IObjectPropertyConfiguration>((m) =>
              convertStaleProperty(
                m as ObjectPropertyCreateOperation,
                dataStore[objectInfos.table.view.dataset.id].data[
                  objectInfos.table.view.id
                ].schema,
                tempTables
              )
            ),
        ],
      }
    : undefined;

  const previousTempTables = usePrevious(tempTables);
  const previousId = usePrevious(currentId);

  React.useEffect(() => {
    if (
      !_.isEqual(previousId, currentId) ||
      !_.isEqual(tempTables, previousTempTables)
    ) {
      if (
        tempTables.some(
          (tt) =>
            dataStore?.[tt.datasetId]?.data?.[tt.viewId]?.schema?.status !==
            "success"
        ) ||
        !_.isEqual(previousId, currentId)
      ) {
        fetchTableSchema(
          tempTables.map((tt) => ({
            datasetId: tt.datasetId,
            viewId: tt.viewId,
            blendedDatasetId: tt.blendedDatasetIds?.[0],
          }))
        );
      }
    }
  }, [currentId, tempTables]);

  const tempTablesWithError = tempTables.map((tempTable) => {
    return {
      ...tempTable,
      hasError: validateTableFormInput(
        tempTable,
        tempTable.schema,
        allDatasets,
        allIncomingDatasetRelationships,
        tempTables
      ), // compute tableFormInput
      metrics: tempTable.metrics.map((metric) => {
        return {
          ...metric,
          hasError: validateMetricFormInput(
            metric.rawData,
            tempTable.schema,
            allDatasets,
            allIncomingDatasetRelationships,
            tempTables
          ),
        };
      }),
      dimensions: tempTable.dimensions.map((dimension) => {
        return {
          ...dimension,
          hasError: validateDimensionFormInput(
            dimension.rawData,
            tempTable.schema,
            allDatasets,
            allIncomingDatasetRelationships,
            tempTables
          ),
        };
      }),
      semanticGroups: tempTable.semanticGroups,
    } as IMeasureTable;
  });

  const onRemoveMeasure = (
    type: MeasureType,
    tableId: string,
    measureId: string
  ) => {
    const activeTable = tempTables.find((t) => t.id === tableId);
    if (activeTable) {
      const orderedItems: Array<{ type: MeasureType; id: string }> = [
        ...activeTable.dimensions.map((d) => ({
          id: d.id,
          type: "dimension" as MeasureType,
        })),
        ...activeTable.metrics.map((d) => ({
          id: d.id,
          type: "metric" as MeasureType,
        })),
      ];

      // if it is the first metric we check if dimension exists
      // if not we select the next metric
      // if is the only metric with no dimension we select the table
      const toRemoveIndex = orderedItems.findIndex(
        (s) => s.id === measureId && s.type === type
      );

      if (orderedItems.length > 1) {
        if (toRemoveIndex === orderedItems.length - 1) {
          onClick([orderedItems[toRemoveIndex - 1]]);
        } else {
          onClick([orderedItems[toRemoveIndex + 1]]);
        }
      } else {
        onClick([
          {
            type: "table",
            id: tableId,
          },
        ]);
      }
    } else {
      onClick([
        {
          type: "table",
          id: tempTables[0]?.id,
        },
      ]);
    }
    if (isFakeId(measureId)) {
      removeStaleElement(measureId);
    } else {
      pushStaleElement({
        type: "delete",
        operationId: generateFakeId(),
        objectId: measureId,
        objectType: type,
      });
    }
  };

  const onRemoveTable = (tableId: string) => {
    const toRemoveIndex = tempTables.findIndex((tt) => tt.id === tableId);
    if (tempTables.length > 1) {
      if (toRemoveIndex > 0) {
        onClick([
          {
            type: "table",
            id: tempTables[toRemoveIndex - 1]?.id,
          },
        ]);
      }
    }

    if (isFakeId(tableId)) {
      removeStaleElement(tableId);
    } else {
      pushStaleElement({
        type: "delete",
        operationId: generateFakeId(),
        objectId: tableId,
        objectType: "table",
      });
    }
  };

  const onRemoveGroup = (semanticGroupId: string) => {
    const getParentTableId = (): string | undefined => {
      return tempTables.reduce<string | undefined>((acc, table) => {
        if (table.semanticGroups.map((sg) => sg.id).includes(semanticGroupId)) {
          acc = table.id;
        }
        return acc;
      }, undefined);
    };
    onClick([
      {
        type: "table",
        id: getParentTableId(),
      },
    ]);

    if (isFakeId(semanticGroupId)) {
      removeStaleElement(semanticGroupId);
    } else {
      pushStaleElement({
        type: "delete",
        operationId: generateFakeId(),
        objectId: semanticGroupId,
        objectType: "semanticGroup",
      });
    }
  };

  const left = (
    <>
      <MeasureEditorTree
        header={
          <Toolbar.Toolbar style={{ borderTop: "none" }}>
            <Toolbar.ViewName>
              {overrideHeaderName ? overrideHeaderName : "Measures"}
            </Toolbar.ViewName>
          </Toolbar.Toolbar>
        }
        key={currentId}
        disabledCapabilities={disabledCapabilities}
        selectedItems={Array.isArray(selectedItems) ? selectedItems : []}
        tables={tempTablesWithError}
        onClick={(type, id) => {
          onClick([{ type, id }]);
        }}
        objectInfos={tempObject}
        onAddTable={(tableId) =>
          onClick({
            type: "add",
            table_id: tableId,
            object_type: "table",
          })
        }
        onAddProperty={
          objectInfos
            ? () => {
                const id = generateFakeId();
                pushStaleElement({
                  type: "create",
                  objectType: "property",
                  parentTableId: objectInfos.id,
                  operationId: id,
                  data: {
                    label: "Untitled property",
                    columnName: "",
                    columnDomain: "STRING",
                    type: "standard",
                    formatter: null,
                    formatterConfig: null,
                  },
                });
                setTimeout(() => {
                  onClick([{ type: "property", id }]);
                }, 200);
              }
            : undefined
        }
        onDeleteProperty={
          objectInfos
            ? (propertyId) => {
                const orderedItems = tempObject.properties.map((t) => ({
                  id: t.id,
                  type: "property" as ExtendedMeasureType,
                }));
                const toRemoveIndex = tempObject.properties.findIndex(
                  (p) => p.id === propertyId
                );

                if (orderedItems.length > 1) {
                  if (toRemoveIndex === orderedItems.length - 1) {
                    onClick([orderedItems[toRemoveIndex - 1]]);
                  } else {
                    onClick([orderedItems[toRemoveIndex + 1]]);
                  }
                } else {
                  onClick([
                    {
                      type: "object",
                      id: tempObject.id,
                    },
                  ]);
                }

                if (isFakeId(propertyId)) {
                  removeStaleElement(propertyId);
                } else {
                  pushStaleElement({
                    type: "delete",
                    operationId: generateFakeId(),
                    objectId: propertyId,
                    objectType: "property",
                  });
                }
              }
            : undefined
        }
        onAddGroup={(tableId) => {
          const id = generateFakeId();
          pushStaleElement({
            type: "create",
            objectType: "semanticGroup",
            operationId: id,
            parentTableId: tableId,
            data: {
              name: "Untitled group",
            },
          });
          setTimeout(() => {
            onClick([{ type: "semanticGroup", id }]);
          }, 200);
        }}
        onDelete={(type, tableId, measureId) => {
          onRemoveMeasure(type, tableId, measureId);
          return Promise.resolve();
        }}
        onDuplicate={(type, tableId, measureId) => {
          const id = generateFakeId();
          if (type === "metric") {
            const originalMetric = tempTables
              .find((t) => t.id === tableId)
              .metrics.find((m) => m.id === measureId);
            pushStaleElement({
              type: "create",
              objectType: type,
              operationId: id,
              parentTableId: tableId,
              data: originalMetric.rawData,
            });
          }
          if (type === "dimension") {
            const originalDimension = tempTables
              .find((t) => t.id === tableId)
              .dimensions.find((d) => d.id === measureId);
            pushStaleElement({
              type: "create",
              objectType: type,
              operationId: id,
              parentTableId: tableId,
              data: originalDimension.rawData,
            });
          }
        }}
        onAddMeasure={(type, tableId, subType) => {
          const id = generateFakeId();
          if (type === "metric") {
            pushStaleElement({
              type: "create",
              objectType: type,
              operationId: id,
              parentTableId: tableId,
              data: {
                expression: subType ? subType : undefined,
                drills: { type: "INHERIT" },
                format: "NUMBER",
                semanticGroupIds: [],
              },
            });
          } else {
            pushStaleElement({
              type: "create",
              objectType: type,
              operationId: id,
              parentTableId: tableId,
              data: {
                type: "standard",
                semanticGroupIds: [],
              },
            });
          }
          onClick([
            {
              type,
              id,
            },
          ]);
        }}
        onMoveMeasureToGroup={(
          type: MeasureType,
          tableId: string,
          measureId: string,
          toGroupId: string,
          fromGroupId: string
        ) => {
          if (type === "metric") {
            const originalMetric = tempTables
              .find((t) => t.id === tableId)
              .metrics.find((m) => m.id === measureId);

            const updatedData = {
              ...originalMetric.rawData,
              semanticGroupIds: (originalMetric.rawData.semanticGroupIds || [])
                .filter((groupId) => groupId !== fromGroupId)
                .concat(toGroupId || []),
            };

            updateStaleElement(type, measureId, updatedData);
          }
          if (type === "dimension") {
            const originalDimension = tempTables
              .find((t) => t.id === tableId)
              .dimensions.find((d) => d.id === measureId);

            const updatedData = {
              ...originalDimension.rawData,
              semanticGroupIds: (
                originalDimension.rawData.semanticGroupIds || []
              )
                .filter((groupId) => groupId !== fromGroupId)
                .concat(toGroupId || []),
            };

            updateStaleElement(type, measureId, updatedData);
          }
        }}
      />
    </>
  );

  const right = (
    <MeasureEditor
      tempTables={tempTablesWithError}
      selectedItems={selectedItems}
      pushStaleElement={pushStaleElement}
      dataStore={dataStore}
      allIncomingDatasetRelationships={allIncomingDatasetRelationships}
      allDatasets={allDatasets}
      onRemoveMeasure={onRemoveMeasure}
      onClick={onClick}
      updateStaleElement={updateStaleElement}
      fetchSchema={fetchTableSchema}
      onRemoveTable={onRemoveTable}
      onRemoveGroup={onRemoveGroup}
      disabledCapabilities={disabledCapabilities}
      objectInfos={tempObject}
      allObjects={allObjects}
      currentWarehouse={currentWarehouse}
    />
  );

  const onSave = async () => {
    setSubmitting(true);
    const loading = antUtils.message.loading("Saving...", 0);
    try {
      const buildTable = (table: IMeasureTable): ITablePayload => {
        const isNewlyCreatedTable = isFakeId(table.id);

        const id = isNewlyCreatedTable
          ? { tempId: table.id }
          : { id: table.id };

        const semanticGroups: ISemanticGroupPayload[] =
          table.semanticGroups.map<ISemanticGroupPayload>((semanticGroup) => {
            const id = isFakeId(semanticGroup.id)
              ? { tempId: semanticGroup.id }
              : { id: semanticGroup.id };
            return {
              ...id,
              ...semanticGroup.rawData,
            };
          });

        const dimensions: IDimensionPayload[] =
          table.dimensions.map<IDimensionPayload>((dimension) => {
            const id = isFakeId(dimension.id)
              ? { tempId: dimension.id }
              : { id: dimension.id };
            return {
              ...id,
              ...dimension.rawData,
              columnDomain:
                dimension.rawData &&
                dimension.rawData.columnName &&
                table.schema.status === "success" &&
                table.schema.data[dimension.rawData.columnName]
                  ? table.schema.data[dimension.rawData.columnName].domain
                  : undefined,
            };
          });

        const metrics: IMetricPayload[] = table.metrics.map<IMetricPayload>(
          (d) => {
            const id = isFakeId(d.id) ? { tempId: d.id } : { id: d.id };
            const { operator, condition, format, drills, ...rest } = d.rawData;

            return {
              ...id,
              ...rest,
              format:
                typeof format === "string" ? format : JSON.stringify(format),
              drills: JSON.stringify(drills),
              filters:
                condition && condition.length
                  ? JSON.stringify([
                      {
                        [operator ? operator : "or"]: condition,
                      },
                    ])
                  : "",
            };
          }
        );

        return {
          ...id,
          name: table.name,
          primaryKey: table.primaryKey,
          view: table.viewId,
          tableType: table.blendedDatasetIds
            ? TableType.BLENDED_WITH_DATASET
            : TableType.REGULAR,
          blendedDatasetIds: table.blendedDatasetIds,
          dimensions: dimensions,
          metrics: metrics,
          drills: JSON.stringify(table.drills),
          semanticGroups: semanticGroups,
          relationships: tempTablesWithError.flatMap((tt) => {
            if (tt.incomingRelationship?.parentId === table.id) {
              const id = isFakeId(tt.incomingRelationship.id)
                ? { tempId: tt.incomingRelationship.id }
                : { id: tt.incomingRelationship.id };
              const relationship: IRelationshipPayload = {
                ...id,
                from: tt.incomingRelationship.from,
                to: tt.incomingRelationship.to,
                type: tt.incomingRelationship.type,
                right: buildTable(tt),
              };
              return [relationship];
            }
            return [];
          }),
        };
      };

      let data: {
        resourceType: ExtendedMeasureType;
        resourceId: string;
      } = undefined;
      if (objectInfos) {
        const objectUpdatePayload: IObjectUpdatePayload = {
          id: objectInfos.id,
          name: tempObject.name,
          table: buildTable(tempTables.find((tt) => !tt.incomingRelationship)),
          conf: {
            name: tempObject.name,
            canBeListed: objectInfos.canBeListed,
            primaryImage: tempObject.primaryImage,
            primaryLabel: tempObject.primaryLabel,
            properties: tempObject.properties.map((p) => {
              const { id, status, hasError, ...rest } = p;
              if (isFakeId(id)) {
                const t: _IObjectPropertyCreatePayload = {
                  ...rest,
                  tempId: id,
                };
                return t;
              } else {
                const t: _IObjectPropertyUpdatePayload = {
                  ...rest,
                  id: id,
                };
                return t;
              }
            }),
          },
        };
        data = await save(objectUpdatePayload);
      } else {
        const explorationUpdatePayload: IExplorationUpdatePayload = {
          id: currentId,
          name: displayName,
          description: displayDescription,
          table: buildTable(tempTables.find((tt) => !tt.incomingRelationship)),
        };
        data = await save(explorationUpdatePayload);
      }

      removeAllStaleElements();
      await fetch(currentId);
      if (data) {
        onClick([
          {
            type: data.resourceType,
            id: data.resourceId,
          },
        ]);
      }

      // we should remove only stale element that could have been saved
    } catch (err) {
      loading();
      antUtils.message.error("An unexpected error happened... Please retry");
      console.error(err);
    } finally {
      loading();
      antUtils.message.success("Successfully saved");
      setSubmitting(false);
    }
  };

  const renderInner = () => {
    return (
      <>
        <Toolbar.Toolbar style={{ borderTop: "none" }}>
          <Toolbar.ViewName>{displayName}</Toolbar.ViewName>
          <div style={{ flex: 1 }} />

          {worskspaceRoute && (
            <Button
              size="small"
              type="text"
              style={{ marginRight: 12 }}
              href={worskspaceRoute}
              target="_blank"
              rel="noreferrer"
            >
              <LinkOutlined /> View in workspace
            </Button>
          )}
          <Button
            disabled={staleElements.length === 0 || submitting}
            style={{ marginRight: 12 }}
            size="small"
            type="primary"
            onClick={onSave}
          >
            Save
          </Button>
        </Toolbar.Toolbar>
        {submitting ? (
          <div className="exploration-general-information">
            <Feednack>
              <div>
                <div>
                  <Loading />
                </div>
                <div>Saving...</div>
              </div>
            </Feednack>
          </div>
        ) : (
          <SplitView
            left={left}
            right={right}
            leftClassName="exploration-viewer"
            minWidth={250}
            startWidth={350}
            maxWidth={"80%"}
            mainClassName={"workbench-content"}
            rightClassName="exploration-viewer"
            alignement="LTR"
            compact={true}
          />
        )}
      </>
    );
  };

  return renderInner();
}

export default compose<Props, ITableConfigurationEditProps>(
  WithOrg,
  withAntUtils
)(TableConfigurationEdit);
