import type { Query } from "@cubejs-client/core";
import type {
  CalendarDatum,
  CalendarSvgProps,
  TimeRangeSvgProps,
} from "@nivo/calendar";
import { ResponsiveCalendar, ResponsiveTimeRange } from "@nivo/calendar";
import type { ChartOption } from "../../../containers/chart-options/ChartOptions";
import type { Meta } from "../../../containers/exploration/single/visualization/chart/Chart";
import type { IPaletteCollection } from "../../../interfaces/org";
import type { UserLocale } from "../../../interfaces/user";
import PaletteGenerator from "../../palette/utils/PaletteGenerator";
import type { Formatter } from "../domain";
import { customFormatter } from "../utils/optionsHelper";

interface ICalendarChartProps {
  height: number;
  data: Meta["tableRows"];
  config: CalendarChartConfig;
  width?: number;
  onDrill?: (q: Query) => void;
  chartOptions?: ChartOption;
  defaultCollection: IPaletteCollection;
  locale: UserLocale;
}

interface CalendarChartConfig {
  key: string;
  label: string;
  canDrill: (xValue: string, yValue?: string) => Query | null;
  formatter: Formatter;
}

export default function CalendarChart(props: ICalendarChartProps) {
  const {
    data,
    config,
    height,
    chartOptions,
    onDrill,
    width,
    defaultCollection,
    locale,
  } = props;
  const metricKey = config.key;
  const formattedData: CalendarDatum[] = data.map((d) => {
    const datum: CalendarDatum = {
      day: (d.x as string).substring(0, 10),
      value: d[metricKey] as number,
    };
    return datum;
  });

  const dates = formattedData.map((d) => d.day).sort();
  const from = dates?.[0];
  const to = dates?.[dates?.length - 1];

  const defaultPalette =
    chartOptions?.series?.[metricKey?.replace(".current", "")]?.palette ??
    defaultCollection.diverging[0];

  const palette = new PaletteGenerator({
    ...defaultPalette,
    numberOfColors: 10,
  });

  const colors = [...palette.colors].reverse();

  const calendarType = chartOptions?.["calendar-type"] ?? "yearly";
  const monthSpacing = chartOptions?.["calendar-month-spacing"] ?? 0;
  const displayLabel = chartOptions?.label ?? true;
  const displayLegend = chartOptions?.["hide-legend"]
    ? !chartOptions["hide-legend"]
    : true;

  const margin = {
    top: displayLabel ? 40 : 10,
    right: displayLabel ? 40 : 10,
    bottom: displayLegend ? 50 : 10,
    left: displayLabel ? 40 : 10,
  };

  type Common<A, B> = {
    [P in keyof A & keyof B]: A[P] | B[P];
  };

  const commonProps: Partial<
    Common<
      Omit<CalendarSvgProps, "height" | "width">,
      Omit<TimeRangeSvgProps, "height" | "width">
    >
  > = {
    data: formattedData,
    from: from,
    to: to,
    minValue: "auto",
    maxValue: "auto",
    emptyColor: "#eeeeee",
    colors: colors,
    margin: {
      top: margin.top,
      right: margin.right,
      bottom: margin.bottom,
      left: margin.left,
    },
    dayBorderWidth: 0,
    daySpacing: 2,
    monthLegend: (year, month, date) => {
      return displayLabel
        ? date.toLocaleString("en", { month: "short" })
        : null;
    },
    onClick: (day) => {
      const canDrill = config?.canDrill;
      if (onDrill && canDrill && day.value != null) {
        onDrill(canDrill(day.day));
      }
    },
    tooltip: function (part) {
      return (
        <div
          style={{
            padding: 10,
            fontSize: 13,
            color: "#fff",
            background: "#2E363B",
            borderRadius: 5,
          }}
        >
          {config.label}
          <br />

          {part.day}
          {": "}
          <strong>
            {customFormatter(part.value, locale, config.formatter)}
          </strong>
        </div>
      );
    },
    legendFormat: (value) => customFormatter(value, locale, config.formatter),
    legends: displayLegend
      ? [
          {
            anchor: "bottom",
            direction: "row",
            itemCount: 4,
            itemWidth: 60,
            itemHeight: 30,
            itemsSpacing: 20,
            translateX: displayLabel ? -20 : 10,
            translateY: displayLabel ? -40 : -10,
            symbolSize: 20,
          },
        ]
      : [],
  };

  const calendarProps: Omit<CalendarSvgProps, "height" | "width"> = {
    ...(commonProps as Omit<CalendarSvgProps, "height" | "width">),
    yearSpacing: 40,
    monthBorderWidth: 0,
    monthSpacing: monthSpacing,
    yearLegend: (year) => {
      return displayLabel ? year : null;
    },
  };

  const timerangeProps: Omit<TimeRangeSvgProps, "height" | "width"> = {
    ...(commonProps as Omit<TimeRangeSvgProps, "height" | "width">),
    weekdayTicks: [],
    weekdayLegendOffset: 0,
    dayRadius: 5,
  };

  return (
    <div style={{ height, width: width ?? "100%" }}>
      {calendarType === "yearly" ? (
        <ResponsiveCalendar {...calendarProps} />
      ) : (
        <ResponsiveTimeRange {...timerangeProps} />
      )}
    </div>
  );
}
