import * as React from "react";
import { compose } from "../../../components/compose/WlyCompose";
import type { InjectedOrgProps } from "../../orgs/WithOrg";
import WithOrg from "../../orgs/WithOrg";
import SettingsWrapper from "../SettingsWrapper";
import UserGroupManagementTable from "./UserGroupManagementTable";

interface IUserGroupManagementPageProps {}

type Props = IUserGroupManagementPageProps & InjectedOrgProps;

class UserGroupManagementPage extends React.Component<Props> {
  public render() {
    const { org } = this.props;

    return (
      <SettingsWrapper>
        <UserGroupManagementTable groups={org.groups} currentOrg={org} />
      </SettingsWrapper>
    );
  }
}

export default compose<Props, IUserGroupManagementPageProps>(WithOrg)(
  UserGroupManagementPage
);
