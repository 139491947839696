import type { Remote } from "comlink";
import type { Moment } from "moment";
import * as React from "react";
import type { RouteComponentProps } from "react-router";
import { withRouter } from "react-router";
import { compose } from "../../../../../components/compose/WlyCompose";
import type { IExploration } from "../../../../../interfaces/explorations";
import type { IReport, ITile } from "../../../../../interfaces/reports";
import { routeDescriptor } from "../../../../../routes/routes";
import type { CubeJSPivotWorker } from "../../../../../worker/main.worker";
import type { Store } from "../../chart/card/ChartCard";
import type {
  ActionType,
  DataMapStore,
  FilterMapStore,
  IFilterStore,
} from "../../domain";
import DashboardContent from "./DashboardContent";
import type { ILagoonQuery } from "../../../../exploration/single/domain";

interface IDashboardWrapperProps {
  isSharingLink?: boolean;
  isEmbedded?: boolean;
  hideLayout?: boolean;
  disableNavigationItems?: boolean;
  editing?: boolean;
  report: IReport;
  fetched?: Moment;
  isDisplayedInWorkspace: boolean;
  explorations: IExploration[];
  refreshReport?: () => any;
  forceCacheRefresh?: (force: boolean) => any;
  actionType?: ActionType;
  reloadReport: (reportSlug: string, orgId) => Promise<any>;
  saving: (saving: boolean) => void;
  showTitle?: boolean;
  setExplorations: (explorations: IExploration[]) => void;
  setReport: (report: IReport) => void;
  filterStore: FilterMapStore;
  setFilterStoreValue: (id: string, data: IFilterStore) => void;
  dataStore: DataMapStore;
  setDataStoreValue: (id: string, data: Partial<Store>) => void;
  hideFilters?: boolean;
  embededWorkbench?: boolean;
  onOpenConsole?: (c?: string) => void;
  disableDrills?: boolean;
  isBeingPushed?: boolean;
  externalWorker: Remote<CubeJSPivotWorker>;
  onRefresh?: (tileId: string, overrideQuery?: ILagoonQuery) => void;
}

type Props = IDashboardWrapperProps &
  RouteComponentProps<
    { tileSlug?: string; explorationSlug?: string },
    {},
    { scrollToBottom?: boolean }
  >;

interface IState {
  shouldUpdateQueries: boolean;
  selected?: { id: string; type: "TILE" | "FILTER" };
  addingNewText: boolean | ITile;
  addingNewFilter: boolean;
  addingNewNavigation: boolean | ITile;
}

class DashboardWrapper extends React.Component<Props, IState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      shouldUpdateQueries: false,
      addingNewText: false,
      addingNewFilter: false,
      addingNewNavigation: false,
    };
  }

  setSelected = (selected) => {
    this.setState({ selected });
  };

  setShouldUpdateQuery = (s) => this.setState({ shouldUpdateQueries: s });

  setAddingNewText = (c) => {
    this.setState({ addingNewText: c });
  };

  setAddingNewFilter = (c: boolean) => this.setState({ addingNewFilter: c });
  setAddingNewNavigation = (c) => this.setState({ addingNewNavigation: c });

  public render() {
    const {
      editing,
      report,
      forceCacheRefresh,
      explorations,
      refreshReport,
      fetched,
      history,
      match: { params },
      hideLayout,
      saving,
      actionType,
      hideFilters,
      reloadReport,
      showTitle,
      setExplorations,
      setDataStoreValue,
      setReport,
      filterStore,
      dataStore,
      setFilterStoreValue,
      embededWorkbench,
      onOpenConsole,
      isDisplayedInWorkspace,
      disableNavigationItems,
    } = this.props;

    const {
      shouldUpdateQueries,
      addingNewText,
      addingNewFilter,
      selected,
      addingNewNavigation,
    } = this.state;

    const onEditClick = () => {
      // used to resize the grid
      setTimeout(() => {
        window.dispatchEvent(new Event("resize"));
      }, 50);
      return history.push(
        routeDescriptor["reportEdit"].renderRoute({ ...params })
      );
    };

    const onCancelClick = () => {
      this.setState({ selected: undefined });
      // used to resize the grid
      setTimeout(() => {
        window.dispatchEvent(new Event("resize"));
      }, 50);
      return history.push(routeDescriptor.report.renderRoute({ ...params }));
    };

    const onChartAddition = (explorationSlug: string) => {
      history.push(
        routeDescriptor.reportTileExplorationCreate.renderRoute({
          ...this.props.match.params,
          explorationSlug,
        })
      );
    };

    return (
      <DashboardContent
        onChartAddition={onChartAddition}
        fetched={fetched}
        forceCacheRefresh={forceCacheRefresh}
        isEmbedded={this.props.isEmbedded}
        isSharingLink={this.props.isSharingLink}
        editing={editing}
        hideFilters={hideFilters}
        disableNavigationItems={disableNavigationItems}
        actionType={actionType}
        hideLayout={hideLayout}
        report={report}
        reloadReport={reloadReport}
        onEditClick={onEditClick}
        onCancelClick={onCancelClick}
        saving={saving}
        showTitle={showTitle}
        explorations={explorations}
        setExplorations={setExplorations}
        setReport={setReport}
        filterStore={filterStore}
        setFilterStoreValue={setFilterStoreValue}
        onOpenConsole={onOpenConsole}
        dataStore={dataStore}
        shouldUpdateQueries={shouldUpdateQueries}
        setDataStoreValue={setDataStoreValue}
        selected={selected}
        setSelected={this.setSelected}
        embededWorkbench={embededWorkbench}
        setShouldUpdateQuery={this.setShouldUpdateQuery}
        refreshReport={refreshReport}
        addingNewText={addingNewText}
        setAddingNewText={this.setAddingNewText}
        addingNewFilter={addingNewFilter}
        setAddingNewFilter={this.setAddingNewFilter}
        addingNewNavigation={addingNewNavigation}
        setAddingNewNavigation={this.setAddingNewNavigation}
        disableDrills={this.props.disableDrills}
        isDisplayedInWorkspace={isDisplayedInWorkspace}
        isBeingPushed={this.props.isBeingPushed}
        externalWorker={this.props.externalWorker}
        onRefresh={this.props.onRefresh}
      />
    );
  }
}

export default compose<Props, IDashboardWrapperProps>(withRouter)(
  DashboardWrapper
);
