import { CloseOutlined, EyeInvisibleOutlined } from "@ant-design/icons";
import type { BinaryFilter, Filter, UnaryFilter } from "@cubejs-client/core";
import { Button } from "antd";
import * as React from "react";
import type { InjectedAntUtilsProps } from "../../../../../components/ant-utils/withAntUtils";
import { withAntUtils } from "../../../../../components/ant-utils/withAntUtils";
import { compose } from "../../../../../components/compose/WlyCompose";
import type {
  ComponentType,
  IFilterControlledBy,
  IFilterType,
  ITile,
} from "../../../../../interfaces/reports";
import type { LagoonObjectType } from "../../../../../services/LagoonService";
import { BooleanFilter } from "../boolean-filter/BooleanFilter";
import {
  WlyDatePicker,
  convertStringToWlyDatePickerValue,
  convertWlyDatePickerValueToString,
} from "../date-filter/WlyDatePicker";
import { NumberFilter } from "../number-filter/NumberFilter";
import { StringFilter } from "../string-filter/StringFilter";
import "./FieldFilter.scss";

export interface IFieldFilterProps extends Config {
  defaultValue?: string[];
  value?: string[];
  editing: boolean;
  onChange?: (s) => void;
  onEdit?: () => void;
  onRemove?: () => void;
  hidden?: "FULL" | "VISIBLE" | "NONE";
  compact?: boolean;
  objectId: string;
  objectType: LagoonObjectType;
  style?: React.CSSProperties;
  selected?: boolean;
  hideLinkFilterMark?: boolean;
  additionalQueryFilters?: Array<Filter>;
  autocompleteLimit?: number;
  block?: boolean;
  injectedMetrics?: string[];
}

interface Config {
  filterName?: string;
  filterNameDisplayType?: "placeholder" | "inline";
  allowNullValueForDate?: boolean;
  labelDimension?: string;
  valueDimension: string;
  tilesToUpdate: TileToUpdate[];
  controlledBy: IFilterControlledBy[];
  requireValue?: boolean;
  componentType?: ComponentType;
  filterType: IFilterType;
}

interface TileToUpdate {
  dimensionToUpdate: string;
  tile: ITile;
}

type Props = IFieldFilterProps & InjectedAntUtilsProps;

class FieldFilter extends React.Component<Props> {
  public render() {
    const {
      value,
      style,
      filterName,
      filterNameDisplayType,
      editing,
      hidden,
      onChange,
      defaultValue,
      onEdit,
      onRemove,
      labelDimension,
      valueDimension,
      filterType,
      requireValue,
      componentType,
      objectId,
      objectType,
      selected,
      antUtils,
      additionalQueryFilters,
      autocompleteLimit,
      block,
      allowNullValueForDate,
      hideLinkFilterMark,
      injectedMetrics,
    } = this.props;

    const renderFieldType = () => {
      if (filterType === "BOOLEAN") {
        return (
          <BooleanFilter
            name={filterName}
            displayNameAs={filterNameDisplayType}
            onChange={(v) => onChange?.(v)}
            disabled={false}
            value={value}
            multi={componentType === "MULTI_SELECT"}
            requireValue={!!requireValue}
            filterType={filterType}
            defaultValue={defaultValue}
            valueDimension={valueDimension}
            labelDimension={labelDimension}
            objectId={objectId}
            objectType={objectType}
            additionalQueryFilters={additionalQueryFilters}
            autocompleteLimit={autocompleteLimit}
            block={block}
            hideLinkFilterMark={hideLinkFilterMark}
            injectedMetrics={injectedMetrics}
          />
        );
      } else if (filterType === "NUMERIC") {
        return (
          <NumberFilter
            name={filterName}
            displayNameAs={filterNameDisplayType}
            onChange={(v) => onChange?.(v)}
            disabled={false}
            value={value}
            multi={componentType === "MULTI_SELECT"}
            requireValue={!!requireValue}
            filterType={filterType}
            defaultValue={defaultValue}
            valueDimension={valueDimension}
            labelDimension={labelDimension}
            objectId={objectId}
            objectType={objectType}
            additionalQueryFilters={additionalQueryFilters}
            autocompleteLimit={autocompleteLimit}
            block={block}
            hideLinkFilterMark={hideLinkFilterMark}
            injectedMetrics={injectedMetrics}
          />
        );
      } else if (filterType === "STRING") {
        return (
          <StringFilter
            name={filterName}
            displayNameAs={filterNameDisplayType}
            onChange={(v) => onChange?.(v)}
            disabled={false}
            value={value}
            multi={componentType === "MULTI_SELECT"}
            requireValue={!!requireValue}
            filterType={filterType}
            defaultValue={defaultValue}
            valueDimension={valueDimension}
            labelDimension={labelDimension}
            objectId={objectId}
            objectType={objectType}
            additionalQueryFilters={additionalQueryFilters}
            autocompleteLimit={autocompleteLimit}
            block={block}
            hideLinkFilterMark={hideLinkFilterMark}
            injectedMetrics={injectedMetrics}
          />
        );
      } else if (filterType === "TIME") {
        return (
          <WlyDatePicker
            name={filterName}
            size="middle"
            displayNameAs={filterNameDisplayType}
            onChange={(v) => onChange?.([convertWlyDatePickerValueToString(v)])}
            disabled={false}
            value={value ? convertStringToWlyDatePickerValue(value[0]) : null}
            block={block}
            allowNullValue={allowNullValueForDate}
          />
        );
      }
    };

    const selectedCSS: React.CSSProperties = {
      borderRadius: 6,
      padding: 4,
      margin: -4,
      boxShadow: `rgb(58 92 131) 0px 0px 0px 1px`,
    };

    return hidden === "FULL" ? null : (
      <div
        className="field-filter-picker"
        style={{
          ...style,
          width: block ? "100%" : "initial",
          cursor: editing ? "pointer" : "auto",
          ...(selected ? selectedCSS : {}),
        }}
        onClick={
          editing && onEdit
            ? (e) => {
                onEdit();
                e.stopPropagation();
                e.preventDefault();
              }
            : undefined
        }
      >
        <span
          style={{ pointerEvents: editing && !selected ? "none" : undefined }}
          onClick={(e) => e.stopPropagation()}
        >
          {renderFieldType()}
        </span>
        {hidden === "VISIBLE" ? (
          <div
            style={{
              position: "absolute",
              top: -8,
              right: selected ? 12 : -8,
              transform: "scale(0.7)",
              pointerEvents: "none",
            }}
          >
            <Button
              icon={<EyeInvisibleOutlined />}
              shape="circle"
              type="default"
              size="small"
            />
          </div>
        ) : null}
        {selected ? (
          <div
            style={{
              position: "absolute",
              top: -8,
              right: -8,
              transform: "scale(0.7)",
            }}
          >
            <Button
              icon={<CloseOutlined />}
              danger
              shape="circle"
              type="default"
              size="small"
              onClick={() => {
                antUtils.modal.confirm({
                  title: "Do you want to continue?",
                  content:
                    "Removing a filter cannot be undone, are you sure you want to proceed?",
                  onOk: onRemove,
                  okType: "danger",
                  okText: "Proceed",
                  cancelText: "Cancel",
                  onCancel: () => undefined,
                });
              }}
            />
          </div>
        ) : null}
      </div>
    );
  }
}

export default compose<Props, IFieldFilterProps>(withAntUtils)(FieldFilter);
