import { Button } from "antd";
import type { RouteComponentProps } from "react-router";
import { withRouter } from "react-router";
import Logo from "../../../assets/icon.svg";
import { compose } from "../../../components/compose/WlyCompose";
import Feednack from "../../../components/layout/feedback/feedback";

interface INoOrgAccessProps {}

type Props = INoOrgAccessProps & RouteComponentProps;

function NoOrgAccess(props: Props) {
  const { history } = props;
  return (
    <Feednack>
      <div>
        <div style={{ paddingBottom: 12 }}>
          <img src={Logo} style={{ width: 120, height: 120 }} alt={"logo"} />
        </div>
        <div>
          You don't seem to have access to this org. Are you sure you typed the
          right address?
        </div>
        <div style={{ paddingTop: 12 }}>
          <Button onClick={() => history.push("/")}>View my Orgs</Button>
        </div>
      </div>
    </Feednack>
  );
}

export default compose<Props, INoOrgAccessProps>(withRouter)(NoOrgAccess);
