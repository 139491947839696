import { CaretDownOutlined, LinkOutlined } from "@ant-design/icons";
import type { Filter } from "@cubejs-client/core";
import { Button, Divider, Dropdown, Space, Typography } from "antd";
import useToken from "antd/es/theme/useToken";
import cuid from "cuid";
import _ from "lodash";
import React from "react";
import type { IFilterType } from "../../../../../interfaces/reports";
import type { LagoonObjectType } from "../../../../../services/LagoonService";
import FIeldAutocomplete from "../field-filter/fields/FieldAutocomplete";
import "./BooleanFilter.scss";

interface IBooleanFilterProps {
  name?: string;
  block?: boolean;
  displayNameAs?: "inline" | "placeholder";
  disabled?: boolean;
  size?: "small" | "middle" | "large";
  value?: string[];
  onChange?: (value: string[] | undefined) => void;
  additionalQueryFilters?: Array<Filter>;
  defaultValue?: string[];
  labelDimension?: string;
  valueDimension: string;
  filterType: IFilterType;
  requireValue: boolean;
  multi: boolean;
  objectType: LagoonObjectType;
  objectId: string;
  allowClear?: boolean;
  autocompleteLimit?: number;
  hideLinkFilterMark?: boolean;
  injectedMetrics?: string[];
}

export function BooleanFilter(props: IBooleanFilterProps) {
  const {
    name,
    block,
    disabled,
    size,
    value,
    onChange,
    multi,
    requireValue,
    filterType,
    defaultValue,
    valueDimension,
    labelDimension,
    objectId,
    objectType,
    additionalQueryFilters,
    autocompleteLimit,
    displayNameAs,
    hideLinkFilterMark,
    injectedMetrics,
  } = props;

  const [isMenuVisible, setIsMenuVisible] = React.useState<boolean>(false);
  const [state, setState] = React.useState<string[] | undefined>(value);
  const [id] = React.useState(cuid());
  const [, token] = useToken();

  const contentStyle = {
    backgroundColor: token.colorBgElevated,
    borderRadius: token.borderRadiusLG,
    boxShadow: token.boxShadowSecondary,
  };

  React.useEffect(() => {
    if (isMenuVisible) {
      setState(value);
    }
  }, [isMenuVisible]);

  const hasValue = value && value.length && value[0] !== "WHALY_NO_FILTER";

  const renderButtonInner = () => {
    return (
      <>
        {name && (!displayNameAs || displayNameAs === "inline") ? (
          <>
            <Typography.Text strong>
              {(additionalQueryFilters ?? []).length > 0 &&
                !hideLinkFilterMark && (
                  <>
                    <LinkOutlined />{" "}
                  </>
                )}
              {_.capitalize(name)}
            </Typography.Text>
            {hasValue ? ": " : ""}
          </>
        ) : (
          ""
        )}
        {name && displayNameAs === "placeholder" && !hasValue ? (
          <>
            <Typography.Text type="secondary">
              {additionalQueryFilters &&
                !hideLinkFilterMark &&
                additionalQueryFilters?.length > 0 && (
                  <>
                    <LinkOutlined />{" "}
                  </>
                )}
              {_.capitalize(name)}
            </Typography.Text>
          </>
        ) : (
          ""
        )}
        {hasValue ? (
          <Typography.Text ellipsis>{value.join(", ")}</Typography.Text>
        ) : (
          ""
        )}
      </>
    );
  };

  return (
    <Dropdown
      open={isMenuVisible}
      onOpenChange={setIsMenuVisible}
      disabled={disabled}
      trigger={["click"]}
      getPopupContainer={() => {
        const el = document.getElementById(id);
        if (el) {
          return el;
        }
        return document.body;
      }}
      dropdownRender={() => {
        return (
          <div
            className="wlystringpicker-custom-menu"
            style={{ ...contentStyle, padding: "6px 0" }}
          >
            <div style={{ width: 290, padding: 8 }}>
              <Space direction="vertical" style={{ width: "100%" }}>
                <FIeldAutocomplete
                  multi={multi}
                  requireValue={!!requireValue}
                  filterType={filterType}
                  onChange={(v) => setState(v)}
                  value={state}
                  defaultValue={defaultValue}
                  valueDimension={valueDimension}
                  labelDimension={labelDimension}
                  objectId={objectId}
                  objectType={objectType}
                  style={{ width: "100%" }}
                  additionalQueryFilters={additionalQueryFilters}
                  limit={autocompleteLimit}
                  renderDropdownInline
                  injectedMetrics={injectedMetrics}
                />
                {(additionalQueryFilters ?? []).length > 0 &&
                  !hideLinkFilterMark && (
                    <Typography.Text type="secondary" style={{ fontSize: 12 }}>
                      <LinkOutlined /> Autocomplete values are controlled by
                      other filters
                    </Typography.Text>
                  )}
              </Space>
            </div>
            <Divider style={{ margin: 0 }} />
            <div style={{ padding: 8 }}>
              <div
                style={{
                  display: "flex",
                  flexWrap: "nowrap",
                  flexDirection: "row",
                }}
              >
                <div
                  style={{
                    flex: "1 1 auto",
                  }}
                >
                  <Button
                    onClick={() => {
                      setIsMenuVisible(false);
                    }}
                  >
                    Cancel
                  </Button>
                </div>
                <div
                  style={{
                    flex: "0 0 auto",
                  }}
                >
                  <Button
                    type="primary"
                    onClick={() => {
                      setIsMenuVisible(false);
                      if (onChange) {
                        onChange(state);
                      }
                    }}
                  >
                    Apply
                  </Button>
                </div>
              </div>
            </div>
          </div>
        );
      }}
    >
      <div
        id={id}
        style={{
          display: "inline-block",
          width: block ? "100%" : "initial",
        }}
      >
        <Button
          style={{
            width: block ? "100%" : "initial",
            overflow: "hidden",
            opacity: hasValue ? 1 : 0.7,
            textAlign: block ? "left" : undefined,
          }}
          disabled={disabled}
          size={size}
        >
          <div style={{ display: "flex" }}>
            <div style={{ flex: 1 }}>{renderButtonInner()}</div>
            <div style={{ flex: 0 }}>
              <CaretDownOutlined style={{ transform: "scale(0.8)" }} />
            </div>
          </div>
        </Button>
      </div>
    </Dropdown>
  );
}
