import type { IRelationshipRendererProps } from "./RelationshipSideRenderer";
import { RelationshipSideRenderer } from "./RelationshipSideRenderer";
import type { IRelationshipTypeRendererProps } from "./RelationshipTypeRenderer";
import { RelationshipTypeRenderer } from "./RelationshipTypeRenderer";

import type { IRelationshipType } from "../../../../../../../interfaces/table";
import "./RelationshipLineRenderer.scss";

interface IRelationshipLineRendererProps
  extends IRelationshipTypeRendererProps {
  from: IRelationshipRendererProps;
  to: IRelationshipRendererProps;
  reverse?: boolean;
}

export const reverseRelationshipType = (
  type: IRelationshipType
): IRelationshipType => {
  switch (type) {
    case "1-1":
      return "1-1";
    case "1-N":
      return "N-1";
    case "N-1":
      return "1-N";
  }
};

export function RelationshipLineRenderer(
  props: IRelationshipLineRendererProps
) {
  const { reverse } = props;
  let { from, to, type } = props;

  if (reverse) {
    let temp;
    temp = from;
    from = to;
    to = temp;
    type = reverseRelationshipType(type);
  }

  return (
    <div className="relationship-line-container">
      <div className="relationship-line-left">
        <RelationshipSideRenderer
          columnName={from.columnName}
          isModel={from.isModel}
          datasetImg={from.datasetImg}
          datasetName={from.datasetName}
        />
      </div>
      <div className="relationship-line-middle">
        <RelationshipTypeRenderer type={type} />
      </div>
      <div className="relationship-line-right">
        <RelationshipSideRenderer
          columnName={to.columnName}
          isModel={to.isModel}
          datasetImg={to.datasetImg}
          datasetName={to.datasetName}
        />
      </div>
    </div>
  );
}
