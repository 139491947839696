import * as React from "react";
import ReactDOM from "react-dom";

interface IFloatingActionButtonsProps {
  children?: React.ReactNode;
}

export default class FloatingActionButtons extends React.Component<IFloatingActionButtonsProps> {
  getElement = () => {
    const els = document.getElementsByClassName(
      "floating-layout-header-right-right"
    );
    if (els && els.length) {
      return els[0];
    }
  };

  public render() {
    const el = this.getElement();
    if (el) {
      return ReactDOM.createPortal(this.props.children, el);
    }
    return null;
  }
}
