import { Alert, AutoComplete, Checkbox, Form, Select, Typography } from "antd";
import * as React from "react";
import FormActions from "../../../components/form/actions/FormActions";
import FormHeader from "../../../components/form/header/FormHeader";
import Loading from "../../../components/layout/feedback/loading";
import { handleGQLErrors } from "../../../helpers/gqlHelpers";
import type { IOrg, IPartnerPortal } from "../../../interfaces/org";
import type { IRealmIdentityProviderType } from "../../../interfaces/realm";
import { IUserRoleType } from "../../../interfaces/user";
import { track } from "../../../services/AnalyticsService";
import GraphQLService from "../../../services/graphql/GraphQLService";
import { assignUserRole } from "../../../services/userService";
import { UserAttributeRenderer } from "../user-attributes/UserAttributeRenderer";
import {
  isAttributeValid,
  parseUserAttributeMeta,
} from "../user-attributes/domain";

interface INewUserFormProps {
  onDrawerClose: () => void;
  onSubmit: (v: InitialValues) => void;
  org: IOrg;
  portal?: IPartnerPortal;
}

interface InitialValues {
  email: string;
  role: IUserRoleType;
  sendInviteEmail: boolean;
  attributes: {
    [key: string]: string[];
  };
}

const initialValues = (portal?: boolean): InitialValues => ({
  email: "",
  role: portal ? IUserRoleType.REPORT_VIEWER : IUserRoleType.BUILDER,
  attributes: {},
  sendInviteEmail: true,
});

export const CREATE_USER_EMAIL_INVITE_GQL = `
  mutation inviteUsers(
    $orgId: ID!,
    $emails: [String!]!,
    $role: String!,
    $attributes: [UserAttributeValues],
    $sendInviteEmail: Boolean
  ) {
    invite(orgId: $orgId, emails: $emails, role: $role, attributes: $attributes, sendInviteEmail: $sendInviteEmail)
  }
`;

const CREATE_PORTAL_USER_EMAIL_INVITE_GQL = `
mutation invitePortalUsers(
  $orgId: ID!,
  $emails: [String!]!,
  $role: String!,
  $portalId: ID!,
  $attributes: [UserAttributeValues]
) {
  portalInvite(orgId: $orgId, emails: $emails, role: $role, portalId: $portalId, attributes: $attributes)
}
`;

interface IState {
  submitting: boolean;
  loading: boolean;
  isSSO: boolean;
  userEmailsOfTheRealmNotInTheOrg: string[];
}

export default class NewUserForm extends React.Component<
  INewUserFormProps,
  IState
> {
  constructor(props: INewUserFormProps) {
    super(props);
    this.state = {
      loading: true,
      submitting: false,
      isSSO: false,
      userEmailsOfTheRealmNotInTheOrg: [],
    };
    this.fetchRealmType();
  }

  async fetchRealmType() {
    const { org } = this.props;

    const queryText = `
    query GetRealmTypeForOrg($orgId: ID!) {
      Org(where: {id: $orgId}) {
        roles(where: {
          isDeleted: false,
          user: { isDeleted: false, isPublicGuestUser: false }
        }) {
          user {
            email
          }
        }
        realm {
          identityProviderType
          users(where: { 
            isDeleted: false,
            user: { isDeleted: false, isPublicGuestUser: false }
          }) {
            user {
              email
            }
          }
        }
      }
    }
    `;

    const orgInfo = await GraphQLService<{
      roles: {
        user: {
          email: string;
        };
      }[];
      realm: {
        identityProviderType: IRealmIdentityProviderType;
        users: {
          user: {
            email: string;
          };
        }[];
      };
    }>(queryText, { orgId: org.id }, `Org`);

    const userEmailsOfTheOrg = orgInfo.roles.map((user) => user?.user?.email);
    const userEmailsOfTheRealm = orgInfo.realm.users.map(
      (user) => user?.user?.email
    );
    const userEmailsOfTheRealmNotInTheOrg = userEmailsOfTheRealm.filter(
      (userEmail) => {
        return !userEmailsOfTheOrg.includes(userEmail);
      }
    );

    if (orgInfo?.realm?.identityProviderType === "work_os") {
      this.setState({
        loading: false,
        isSSO: true,
        userEmailsOfTheRealmNotInTheOrg,
      });
    } else {
      this.setState({
        loading: false,
        isSSO: false,
        userEmailsOfTheRealmNotInTheOrg,
      });
    }
  }

  componentDidMount() {
    track("User Invite Opened");
  }

  public submitForm = async (v: InitialValues) => {
    const { org, onSubmit, portal } = this.props;
    const { isSSO, userEmailsOfTheRealmNotInTheOrg } = this.state;
    const invitedEmail = v.email;

    const attributes = v.attributes
      ? Object.keys(v.attributes).map((k) => {
          return {
            attributeMetaId: k,
            value: JSON.stringify(v.attributes[k]),
          };
        })
      : null;

    this.setState({ submitting: true });
    if (
      (!!portal || isSSO === false) &&
      !userEmailsOfTheRealmNotInTheOrg.includes(invitedEmail)
    ) {
      const promise = portal
        ? GraphQLService(
            CREATE_PORTAL_USER_EMAIL_INVITE_GQL,
            {
              orgId: org.id,
              emails: [invitedEmail],
              role: v.role,
              portalId: portal.id,
              attributes,
            },
            "invite"
          )
        : GraphQLService(
            CREATE_USER_EMAIL_INVITE_GQL,
            {
              orgId: org.id,
              emails: [invitedEmail],
              role: v.role,
              attributes,
              sendInviteEmail: !!v.sendInviteEmail,
            },
            "invite"
          );
      return promise
        .then(() => {
          track("User Invite Sent");
          onSubmit(v);
          this.setState({ submitting: false });
        })
        .catch(
          handleGQLErrors(() => {
            this.setState({ submitting: false });
          })
        );
    } else {
      const findUserIdQueryText = `
      query GetUserIdByEmail($email: String!) {
        allUsers(where: {email: $email}) {
          id
        }
      }
      `;

      try {
        const userIdRes = await GraphQLService<{ id: string }[]>(
          findUserIdQueryText,
          { email: invitedEmail },
          "allUsers"
        );

        const userId = userIdRes?.[0].id;

        if (!userId) {
          throw new Error(`There is no user for email=${v.email}`);
        }

        await assignUserRole(userId, org.id, v.role);
        track("User Role Assigned");
        onSubmit(v);
        this.setState({ submitting: false });
      } catch (err) {
        handleGQLErrors(() => {
          this.setState({ submitting: false });
        })(err);
      }
    }
  };

  public render() {
    const { onDrawerClose, portal, org } = this.props;
    const { loading, isSSO, userEmailsOfTheRealmNotInTheOrg } = this.state;

    const eligibleTeamUserSelectOptions = userEmailsOfTheRealmNotInTheOrg.map(
      (userEmail) => {
        return (
          <Select.Option value={userEmail} key={userEmail}>
            {userEmail}
          </Select.Option>
        );
      }
    );
    const eligibleTeamUserAutocompleteOptions =
      userEmailsOfTheRealmNotInTheOrg.map((userEmail) => {
        return { value: userEmail };
      });
    if (loading) {
      return <Loading />;
    }
    return (
      <>
        <Form
          initialValues={initialValues(!!portal)}
          onFinish={this.submitForm}
          className="form-container"
          layout="vertical"
        >
          <FormHeader
            title={portal ? "Invite new Partner" : "Invite new Users"}
            onClose={onDrawerClose}
          />
          {isSSO === true && !portal ? (
            <Alert
              message="Your team is using Single Sign On"
              description={
                <div>
                  <p>
                    In order to invite co-workers, please send them the link to
                    the <a href={window.WHALY_CONSTANTS.APP_URL}>login page</a>.
                  </p>

                  <p>
                    They will be added to your team after their first login and
                    you'll be able to give them the proper access rights from
                    here.
                  </p>
                </div>
              }
              type="info"
            />
          ) : undefined}
          {isSSO &&
          !portal &&
          userEmailsOfTheRealmNotInTheOrg.length === 0 ? undefined : (
            <div style={{ padding: "0 24px", marginTop: "16px" }}>
              <Form.Item label="Role" name={["role"]} required={true}>
                <Select>
                  <Select.Option value={IUserRoleType.ADMIN_BUILDER}>
                    Admin builder
                  </Select.Option>
                  <Select.Option value={IUserRoleType.BUILDER}>
                    Builder
                  </Select.Option>
                  <Select.Option value={IUserRoleType.EDITOR}>
                    Editor
                  </Select.Option>
                  <Select.Option value={IUserRoleType.VIEWER}>
                    Viewer
                  </Select.Option>
                  <Select.Option value={IUserRoleType.REPORT_DOWNLOADER}>
                    Report Downloader
                  </Select.Option>
                  <Select.Option value={IUserRoleType.REPORT_VIEWER}>
                    Report Viewer
                  </Select.Option>
                </Select>
              </Form.Item>
              <Form.Item
                label={"Email"}
                required={true}
                name="email"
                rules={[
                  {
                    required: true,
                    pattern: /^\w+([+.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,10})+$/,
                    message: "Invalid email address!",
                  },
                ]}
              >
                {isSSO === true && !portal ? (
                  <Select>{eligibleTeamUserSelectOptions}</Select>
                ) : (
                  <AutoComplete
                    disabled={this.state.submitting}
                    placeholder="eg: steve@apple.com"
                    options={eligibleTeamUserAutocompleteOptions}
                  />
                )}
              </Form.Item>
              {!isSSO && !portal ? (
                <Form.Item valuePropName="checked" name="sendInviteEmail">
                  <Checkbox>Generate invite link & send invite email</Checkbox>
                </Form.Item>
              ) : null}
              <div>
                {org.userAttributeMetas.length > 0 && (
                  <div style={{ marginBottom: 12 }}>
                    <Typography.Title level={5}>Attributes</Typography.Title>
                  </div>
                )}
                {org.userAttributeMetas.map((t, i) => {
                  const parsed = parseUserAttributeMeta(t);

                  return (
                    <Form.Item
                      key={i}
                      rules={[
                        {
                          validator: (_, v) => {
                            const isValid = isAttributeValid(v, parsed);
                            if (!isValid) {
                              return Promise.reject("Value is not valid");
                            }
                            return Promise.resolve();
                          },
                        },
                      ]}
                      name={["attributes", t.id]}
                      label={t.label}
                    >
                      <UserAttributeRenderer
                        type={parsed.type}
                        multi={!!parsed.allowMultipleValues}
                        options={parsed.options}
                      />
                    </Form.Item>
                  );
                })}
              </div>
            </div>
          )}
          <FormActions
            isSubmitting={this.state.submitting}
            onCancel={onDrawerClose}
            hideSubmit={isSSO && userEmailsOfTheRealmNotInTheOrg.length === 0}
          />
        </Form>
      </>
    );
  }
}
