import { InfoCircleOutlined, PlusOutlined } from "@ant-design/icons";
import * as React from "react";

import { Tooltip } from "antd";
import { handleGQLErrors } from "../../../helpers/gqlHelpers";
import type { IUser } from "../../../interfaces/user";
import type { ViewInitialData } from "./ViewEdition";
import ViewEdition from "./ViewEdition";
import "./ViewSelector.scss";
import ViewSelectorItem from "./ViewSelectorItem";

export interface ViewTabItem {
  key: string;
  label: string;
  viewCubeName: string;
  editable: boolean;
  usedInExplorations: { name: string; slug: string }[];
  updatedBy?: IUser;
  updatedAt?: string;
  drills: string[];
}

interface IViewSelectorProps {
  views: ViewTabItem[];
  selectedViewId?: string;
  onSelectView: (viewId: string) => void;
  onCreateView?: (name: string) => Promise<void>;
  onDeleteView?: (viewId: string) => Promise<any>;
  onRenameView?: (viewId: string, name: string) => Promise<void>;
  children?: React.ReactNode;
}

interface IState {
  open: boolean;
  initialData?: ViewInitialData;
}

export default class ViewSelector extends React.Component<
  IViewSelectorProps,
  IState
> {
  constructor(props: IViewSelectorProps) {
    super(props);
    this.state = {
      open: false,
    };
  }

  public render() {
    const {
      views,
      selectedViewId,
      onCreateView,
      onRenameView,
      onDeleteView,
      onSelectView,
      children,
    } = this.props;
    return (
      <div className="view-selector-inner">
        <div className="view-selector-inner-list">
          {children}
          {views.map((v) => {
            return (
              <ViewSelectorItem
                key={v.key}
                isSelected={selectedViewId === v.key}
                name={v.label}
                hoverable={true}
                shouldShowDropdown={true}
                onRenameView={
                  onRenameView && !v.editable
                    ? () =>
                        this.setState({
                          open: true,
                          initialData: { id: v.key, name: v.label },
                        })
                    : undefined
                }
                onDeleteView={
                  onDeleteView && !v.editable
                    ? () => onDeleteView(v.key)
                    : undefined
                }
                onClick={() => onSelectView(v.key)}
                complementaryIcon={
                  <Tooltip
                    placement="topLeft"
                    title={
                      v.usedInExplorations.length === 0 ? (
                        <div>Used in no explorations</div>
                      ) : (
                        <div>
                          <div>Used in explorations:</div>
                          {v.usedInExplorations.map((m) => (
                            <div key={m.slug}>- {m.name}</div>
                          ))}
                        </div>
                      )
                    }
                  >
                    <InfoCircleOutlined />
                  </Tooltip>
                }
              />
            );
          })}
        </div>
        {onCreateView && onRenameView && (
          <div className="view-selector-inner-actions">
            <ViewSelectorItem
              shouldShowDropdown={false}
              onClick={() => this.setState({ open: true })}
              isSelected={false}
              name={"Create a view"}
              complementaryIcon={<PlusOutlined />}
            />
          </div>
        )}
        {onCreateView && onRenameView && (
          <ViewEdition
            visible={this.state.open}
            initialData={this.state.initialData}
            onCancel={() => this.setState({ open: false })}
            onSave={(data) => {
              const promise = data.id
                ? onRenameView(data.id, data.name)
                : onCreateView(data.name);
              return promise
                .then(() => {
                  this.setState({
                    open: false,
                  });
                })
                .catch(handleGQLErrors());
            }}
          />
        )}
      </div>
    );
  }
}
