import { Typography } from "antd";
import { Link } from "react-router-dom";
import type { InjectedOrgProps } from "../../../containers/orgs/WithOrg";
import WithOrg from "../../../containers/orgs/WithOrg";
import type { IAccessType } from "../../../interfaces/reportSharing";
import { compose } from "../../compose/WlyCompose";
import { GroupRenderer } from "../../group/GroupRenderer";
import { UserRenderer } from "../../user/UserRenderer";
import type { IInheritedFromInfos, ISharing } from "../domain";
import { AccessTypePickerRenderer } from "./AccessTypePickerRenderer";
import "./SharingRenderer.scss";

interface ISharingRendererProps {
  sharing: ISharing;
  inheritingFrom?: IInheritedFromInfos;
  disabled: boolean;
  onClose: () => void;
  onAccessTypeChange: (newType: IAccessType) => void;
  onRemoveAccess: () => void;
  availableAccessTypes: Array<IAccessType>;
}

type Props = InjectedOrgProps & ISharingRendererProps;

function SharingRenderer(props: Props) {
  const {
    sharing,
    inheritingFrom,
    onClose,
    onAccessTypeChange,
    onRemoveAccess,
    disabled,
    availableAccessTypes,
  } = props;

  const getSubjectRenderer = (): JSX.Element => {
    const baseOnComponent = inheritingFrom ? (
      <span>
        Based on{" "}
        <Link
          onClick={() => {
            onClose();
          }}
          to={inheritingFrom.linkTo}
        >
          {inheritingFrom.name}
        </Link>
      </span>
    ) : undefined;

    if (sharing.user) {
      return <UserRenderer user={sharing.user} description={baseOnComponent} />;
    } else if (sharing.group) {
      return (
        <GroupRenderer group={sharing.group} description={baseOnComponent} />
      );
    } else {
      return <Typography.Text>Not supported</Typography.Text>;
    }
  };

  return (
    <div className="folder-sharing-container">
      {getSubjectRenderer()}
      <div className="folder-sharing-access-type-picker">
        <AccessTypePickerRenderer
          disabled={disabled || !!inheritingFrom}
          currentAccessType={sharing.accessType}
          showRemove={true}
          onAccessTypeChange={(newType) => {
            onAccessTypeChange(newType);
          }}
          onRemoveAccess={() => {
            onRemoveAccess();
          }}
          availableAccessTypes={availableAccessTypes}
        />
      </div>
    </div>
  );
}
export default compose<Props, ISharingRendererProps>(WithOrg)(SharingRenderer);
