import { Tag } from "antd";
import type { ReactNode } from "react";
import UserAvatar from "../../components/user/avatar/UserAvatar";
import type { IUser, IUserGravatarInfo } from "../../interfaces/user";
import "./UserRenderer.scss";

interface IUserRendererProps {
  user: IUserGravatarInfo;
  description?: ReactNode;
}

export function UserRenderer(props: IUserRendererProps) {
  const { user, description } = props;
  return (
    <div className="user-renderer-info">
      {(user as IUser).firstName && (
        <div className="user-renderer-image">
          <UserAvatar user={user as IUser} />
        </div>
      )}
      <div className="user-renderer-meta">
        {(user as IUser).firstName && (
          <div className="user-renderer-name">
            {(user as IUser).firstName} {(user as IUser).lastName}
          </div>
        )}
        <div className="user-renderer-email">{user.email}</div>
        {user.signUpToken ? (
          <div>
            <Tag color="blue">Invite Pending</Tag>
          </div>
        ) : null}
        {description ? (
          <div className="user-renderer-description">{description}</div>
        ) : undefined}
      </div>
    </div>
  );
}
