import {
  CheckCircleTwoTone,
  ExclamationCircleTwoTone,
} from "@ant-design/icons";
import { Button, Col, Row, Space, Typography } from "antd";
import * as React from "react";
import type { RouteComponentProps } from "react-router";
import { withRouter } from "react-router";
import type { InjectedAntUtilsProps } from "../../../../components/ant-utils/withAntUtils";
import { withAntUtils } from "../../../../components/ant-utils/withAntUtils";
import Card from "../../../../components/cards/Card";
import CatalogItemDetailHeader from "../../../../components/catalog/detail/header/CatalogItemDetailHeader";
import { compose } from "../../../../components/compose/WlyCompose";
import Aligner from "../../../../components/layout/aligner/Aligner";
import type {
  IDestination,
  IDestinationValue,
} from "../../../../interfaces/destinations";
import { routeDescriptor } from "../../../../routes/routes";
import { WarehouseUserType } from "../../../../services/BrizoService";
import GraphQLService from "../../../../services/graphql/GraphQLService";
import type { InjectedOrgProps } from "../../../orgs/WithOrg";
import WithOrg from "../../../orgs/WithOrg";
import WarehouseDangerZone from "./danger/WarehouseDangerZone";

interface IWarehouseDetailProps {
  warehouse: IDestination;
  numberOfWarehouses: number;
}

type Props = IWarehouseDetailProps &
  InjectedAntUtilsProps &
  RouteComponentProps<{ organizationSlug: string }> &
  InjectedOrgProps;

interface IState {
  warehouse: IDestination;
  selectedValue?: IDestinationValue;
}

const GQL = `mutation saveValue(
  $id: ID!
  $value: String!
) {
  updateDestinationValue(
    id: $id
    data: {
      value: $value
    }
  ) {
    id
    value
  }
}`;

class WarehouseDetail extends React.Component<Props, IState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      warehouse: props.warehouse,
    };
  }

  onWarehouseUpdate = (warehouse: IDestination) => {
    this.setState({ warehouse });
  };

  public onWarehouseValueUpdate = (value: IDestinationValue) => {
    const { warehouse } = this.state;
    const newWarehouse: IDestination = {
      ...warehouse,
      values: warehouse.values.map((v) => {
        return v.id === value.id ? { ...v, ...value } : v;
      }),
    };
    this.onWarehouseUpdate(newWarehouse);
  };

  onBackClick = () => this.setState({ selectedValue: undefined });

  onDestinationValueUpdate = (valueId: string, value: string | number | Date) =>
    GraphQLService(GQL, {
      id: valueId,
      value: value,
    }).then((d) => {
      this.onWarehouseValueUpdate(d.updateDestinationValue);
    });

  onValueSelection = (v: string) =>
    this.setState({
      selectedValue: this.state.warehouse.values.find(
        (val) => val.option.id === v
      ),
    });

  public render() {
    const {
      antUtils,
      history,
      match: { params },
      numberOfWarehouses,
      org,
    } = this.props;
    const { warehouse } = this.state;
    return (
      <Aligner>
        <Row justify="center" className="warehouse-detail">
          <Col xs={24} sm={20} md={18} lg={14} xl={12}>
            <div style={{ marginBottom: 12 }}>
              <a onClick={() => history.push("settingsWarehouse")}>
                All warehouses {" > "}
              </a>
            </div>
            <Card className="card">
              <CatalogItemDetailHeader
                logo={warehouse.destinationMeta.publicInfo.logo}
                name={warehouse.destinationMeta.publicInfo.name}
                tagline={warehouse.destinationMeta.publicInfo.tagline}
              >
                <div>
                  <div style={{ padding: "0 24px 12px 24px" }}>
                    <Typography.Text strong>Connections:</Typography.Text>
                  </div>
                  <div
                    style={{
                      padding: "24px 24px 24px 24px",
                      borderTop: "1px solid #E7ECEF",
                      display: "flex",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <div
                      style={{
                        flex: 0,
                        marginRight: 12,
                        transform: "scale(1.3)",
                      }}
                    >
                      {warehouse.isBiEnabled ? (
                        <CheckCircleTwoTone twoToneColor={"#52c41a"} />
                      ) : (
                        <ExclamationCircleTwoTone twoToneColor={"#ff4d4f"} />
                      )}
                    </div>
                    <div style={{ flex: 1 }}>
                      <Typography.Text>
                        Business Intelligence Connection
                      </Typography.Text>
                    </div>
                    <div style={{ flex: 0, marginLeft: 12 }}>
                      <Space>
                        <Button
                          onClick={() =>
                            history.push(
                              routeDescriptor.warehouseConnectionStep2.renderRoute(
                                {
                                  organizationSlug: org.slug,
                                  itemSlug: warehouse.destinationMeta.slug,
                                  itemId: warehouse.id,
                                }
                              )
                            )
                          }
                          type="primary"
                        >
                          {warehouse.isBiEnabled ? "Reconnect" : "Connect"}
                        </Button>
                      </Space>
                    </div>
                  </div>
                  {warehouse.destinationMeta.targetName ? (
                    <div
                      style={{
                        padding: "24px 24px 24px 24px",
                        borderTop: "1px solid #E7ECEF",
                        display: "flex",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      <div
                        style={{
                          flex: 0,
                          marginRight: 12,
                          transform: "scale(1.3)",
                        }}
                      >
                        {warehouse.isDataLoadingEnabled ? (
                          <CheckCircleTwoTone twoToneColor={"#52c41a"} />
                        ) : (
                          <ExclamationCircleTwoTone twoToneColor={"#ff4d4f"} />
                        )}
                      </div>
                      <div style={{ flex: 1 }}>
                        <Typography.Text>
                          Data Loader Connection
                        </Typography.Text>
                      </div>
                      <div style={{ flex: 0, marginLeft: 12 }}>
                        <Space>
                          <Button
                            onClick={() =>
                              history.push(
                                routeDescriptor.warehouseConnectionStep2.renderRoute(
                                  {
                                    organizationSlug: org.slug,
                                    itemSlug: warehouse.destinationMeta.slug,
                                    itemId: warehouse.id,
                                    connectionType:
                                      WarehouseUserType.DATA_LOADING,
                                  }
                                )
                              )
                            }
                            type="primary"
                          >
                            {warehouse.isDataLoadingEnabled
                              ? "Reconnect"
                              : "Connect"}
                          </Button>
                        </Space>
                      </div>
                    </div>
                  ) : undefined}
                </div>
              </CatalogItemDetailHeader>
            </Card>
            <div style={{ marginTop: 24 }} />
            <WarehouseDangerZone
              onDelete={async () => {
                try {
                  await GraphQLService(
                    `
                mutation DeleteDestination(
                  $id: ID!,
                ) {
                  updateDestination(id: $id, data: {isDeleted: true}) {
                    id
                  }
                }`,
                    { id: warehouse.id }
                  );
                  if (numberOfWarehouses === 1) {
                    // we do so, so we can show the user the onboarding flow
                    window.location.reload();
                  } else {
                    history.push(
                      routeDescriptor["settingsWarehouse"].renderRoute({
                        ...params,
                      })
                    );
                  }
                } catch (err) {
                  antUtils.message.error(
                    "An unexpected error happened, plese retry"
                  );
                }
              }}
            />
          </Col>
        </Row>
      </Aligner>
    );
  }
}

export default compose<Props, IWarehouseDetailProps>(
  withRouter,
  WithOrg,
  withAntUtils
)(WarehouseDetail);
