import { useForm } from "antd/es/form/Form";
import { SimpleFormModal } from "../../../../../../components/form/modal/SimpleFormModal";
import type { CreateObjectViewFormData } from "../forms/CreateObjectViewForm";
import { CreateObjectViewForm } from "../forms/CreateObjectViewForm";

type Props = {
  open: boolean;
  title?: string;
  initialValues?: Partial<CreateObjectViewFormData>;
  isSaving: boolean;
  onCancel: () => void;
  onSave: (values: CreateObjectViewFormData) => any;
};

export default function CreateObjectViewModal({
  open,
  title,
  initialValues,
  isSaving,
  onCancel,
  onSave,
}: Props) {
  const [form] = useForm<CreateObjectViewFormData>();

  return (
    <SimpleFormModal<CreateObjectViewFormData>
      form={form}
      open={open}
      onCancel={onCancel}
      isSaving={isSaving}
      modalProps={{
        title: title ?? "Create view",
      }}
    >
      <CreateObjectViewForm
        form={form}
        initialValues={initialValues}
        onSave={onSave}
      />
    </SimpleFormModal>
  );
}
