import { getRelatedMjml } from "../../../../utils";
import type { IWidgetEmailMjmlRenderer } from "../../domain";
import type { IEmailWidgetRelatedListConfig } from "../domain";

interface IData {
  id: string;
  label: string;
  score: number;
  icon: string;
  day: string;
}

// const pillTemaplate = (
//   color: "success" | "warning" | "danger" | "normal",
//   content: string,
//   icon?: boolean
// ) => {
//   const renderIcon = () => {
//     if (icon) {
//       return `
// <td style="padding:4px">
//   <img aria-label="Accepter" height="16" role="img" src="https://ci3.googleusercontent.com/meips/ADKq_NZExJqh2SMMI8eNa8gX07_Ub-2HG1oD9ldtir3xdryeAvA3Gdgn1krt7Z0tEBaXyKHwi1zTjlUtNlEGsOacaeX3g7JHOM9coCXfoQ=s0-d-e1-ft#https://www.doctolib.fr/email_layout/circle-check.png" style="padding:4px;padding-right:0px" width="16" class="CToWUd" data-bit="iit">
// </td>
//       `;
//     }
//     return "";
//   };

//   const colors = renderColors(color);
//   const renderedIcon = renderIcon();

//   return `
//   <table bgcolor="${
//     colors.bgColor
//   }" border="0" cellpadding="0" cellspacing="0" height="32" role="listitem" style="display:inline-block;border-radius:16px;margin-right:8px">
//     <tbody>
//       <tr style="line-height:0px">
//         ${renderedIcon}
//         <td style="color:${
//           colors.fontColor
//         };font-size:14px;line-height:32px;padding-right:8px;${
//     !renderedIcon ? "padding-left:8px;" : ""
//   }" valign="sub">
//           ${content}
//         </td>
//       </tr>
//     </tbody>
//   </table>
//   `;
// };

export const EmailRelatedListMJML: IWidgetEmailMjmlRenderer<
  IEmailWidgetRelatedListConfig
> = async (widget, object, record, conf, org, user) => {
  const layoutId = "16";
  const objectId = "24";

  const data: Array<IData> = [
    {
      id: "15999430",
      label: "Centre E. Leclerc Echirolles",
      score: 0,
      icon: "https://cdn.whaly.io/retail-demo/leclerc.png",
      day: "monday",
    },
    {
      id: "15999681",
      label: "Centre E. Leclerc Drumettaz Clarafond",
      score: 1,
      icon: "https://cdn.whaly.io/retail-demo/leclerc.png",
      day: "monday",
    },
    {
      id: "16001108",
      label: "Super U Bourg Saint Maurice",
      score: 1,
      icon: "https://cdn.whaly.io/retail-demo/systemeu.png",
      day: "monday",
    },
    {
      id: "15999683",
      label: "Centre E. Leclerc Ville La Grand",
      score: 2,
      icon: "https://cdn.whaly.io/retail-demo/leclerc.png",
      day: "tuesday",
    },
    {
      id: "15998237",
      label: "Carrefour L'isle D'abeau",
      score: 2,
      icon: "https://cdn.whaly.io/retail-demo/carrefour.png",
      day: "tuesday",
    },
    {
      id: "15998318",
      label: "Carrefour Sallanches",
      score: 2,
      icon: "https://cdn.whaly.io/retail-demo/carrefour.png",
      day: "wednesday",
    },
    {
      id: "15999429",
      label: "Centre E. Leclerc Chatte",
      score: 2,
      icon: "https://cdn.whaly.io/retail-demo/leclerc.png",
      day: "wednesday",
    },
    {
      id: "15998239",
      label: "Carrefour Saint Egreve",
      score: 3,
      icon: "https://cdn.whaly.io/retail-demo/carrefour.png",
      day: "wednesday",
    },
    {
      id: "15998158",
      label: "Carrefour Ferney Voltaire",
      score: 3,
      icon: "https://cdn.whaly.io/retail-demo/carrefour.png",
      day: "thursday",
    },
    {
      id: "15998317",
      label: "Carrefour Margencel",
      score: 3,
      icon: "https://cdn.whaly.io/retail-demo/carrefour.png",
      day: "thursday",
    },
  ];

  // const renderLine = (d: IData) => {
  //   return `<tr>
  //   <td style="padding: 6px 12px 6px 0; width: 24px">
  //     <img src="${d.icon}" width="24px" />
  //   </td>
  //   <td style="padding: 6px 0px;"><a target="_blank" href="https://app.whaly.io/tropicana/v2/object/magasins/${
  //     d.id
  //   }">${d.label}</a></td>
  //   <td style="padding: 6px 0 6px 12px;text-align:end;">${pillTemaplate(
  //     "danger",
  //     d.score.toString()
  //   )}</td>
  // </tr>`;
  // };

  const filteredData = data.filter((d) => d.day === conf.day);

  const padding =
    !conf.spacing || conf.spacing === "normal"
      ? `padding-top="10" padding-bottom="10"`
      : `padding-top="0" padding-bottom="0"`;

  if (filteredData.length === 0) {
    return `
    <mj-text ${padding}>
      <p>Pas de visites prévue en ce jour...</p>
    </mj-text>
    `;
  }

  const renderedLine = await Promise.all(
    filteredData.flatMap(async (fd) => {
      const data = await getRelatedMjml(org, user, layoutId, objectId, fd.id);
      return data;
    })
  );

  return `
  ${renderedLine.join("\n")}
`;
};
