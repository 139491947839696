import { calculateReverseDistance } from "../../utils/distance";
import type { IPaletteContinue } from "../palette/utils/paletteData";
import { getSelectedPalette } from "../palette/utils/paletteData";
import PaletteGenerator from "../palette/utils/PaletteGenerator";

interface IBaseRule {
  id: string;
  type: IRuleType;
  format: ISingleColorRuleFormat | IColorScaleRuleFormat;
}

interface ISingleColorRule extends IBaseRule {
  type: "single";
  condition: IWlyConditionalFormatterCondition;
  format: ISingleColorRuleFormat;
}

interface IConditionalFormatterOverrideValue {
  type: "text";
  value: string;
}

interface ISingleColorRuleFormat {
  overrideValue?: IConditionalFormatterOverrideValue;
  fontColor?: IColor;
  backgroundColor?: IColor;
}

interface IColorScaleRule extends IBaseRule {
  type: "scale";
  condition: {
    operator: Extract<IConditionalFormatterNumericOperators, "isInRange">;
    value: Extract<IConditionalFormatterNumericValue, IRuleValueRange>;
  };
  format: IColorScaleRuleFormat;
}

interface IColorScaleRuleFormat {
  palette?: IPaletteContinue;
}

export type IConditionalFormatterRule = ISingleColorRule | IColorScaleRule;

type IRuleType = "single" | "scale";

type IColor = string;

export type IConditionalFormatterNumericOperators =
  | "equals"
  | "notEquals"
  | "lessThan"
  | "lessThanOrEqualsTo"
  | "greaterThan"
  | "greatherThanOrEqualsTo"
  | "isNull"
  | "isNotNull"
  | "isInRange"
  | "isNotInRange";

type IRuleValueRange = {
  min: number;
  max: number;
};

type IConditionalFormatterNumericValue =
  | number
  | null
  | undefined
  | IRuleValueRange;

type IWlyConditionalFormatterCondition =
  | {
      operator: Extract<
        IConditionalFormatterNumericOperators,
        | "equals"
        | "notEquals"
        | "lessThan"
        | "lessThanOrEqualsTo"
        | "greaterThan"
        | "greatherThanOrEqualsTo"
      >;
      value: Extract<IConditionalFormatterNumericValue, number>;
    }
  | {
      operator: Extract<
        IConditionalFormatterNumericOperators,
        "isNull" | "isNotNull"
      >;
      value: Extract<IConditionalFormatterNumericValue, null | undefined>;
    }
  | {
      operator: Extract<
        IConditionalFormatterNumericOperators,
        "isInRange" | "isNotInRange"
      >;
      value: Extract<IConditionalFormatterNumericValue, IRuleValueRange>;
    };

export const ConditionalFormatterNumericOperators: {
  label: string;
  type: IConditionalFormatterNumericOperators;
}[] = [
  {
    type: "equals",
    label: "Is equal to",
  },
  {
    type: "notEquals",
    label: "Is not equal to",
  },
  {
    type: "lessThan",
    label: "Is less than",
  },
  {
    type: "lessThanOrEqualsTo",
    label: "Is less than or equals to",
  },
  {
    type: "greaterThan",
    label: "Is greater than",
  },
  {
    type: "greatherThanOrEqualsTo",
    label: "Is greater than or equals to",
  },
  {
    type: "isInRange",
    label: "Is between",
  },
  {
    type: "isNotInRange",
    label: "Is not between",
  },
  {
    type: "isNull",
    label: "Is null",
  },
  {
    type: "isNotNull",
    label: "Is not null",
  },
];

export const getOperatorSentence = (
  condition: IWlyConditionalFormatterCondition
): string => {
  const { operator, value } = condition;
  switch (operator) {
    case "equals":
      return `Is equal to ${value}`;
    case "notEquals":
      return `Is not equal to ${value}`;
    case "lessThan":
      return `Is less than ${value}`;
    case "lessThanOrEqualsTo":
      return `Is less than or equal to ${value}`;
    case "greaterThan":
      return `Is greater than ${value}`;
    case "greatherThanOrEqualsTo":
      return `Is greater than or equal to ${value}`;
    case "isNull":
      return `Is null`;
    case "isNotNull":
      return `Is not null`;
    case "isInRange":
      return `Is between ${value.min} and ${value.max}`;
    case "isNotInRange":
      return `Is not between ${value.min} and ${value.max}`;
  }
};

const valueMatchesRule = (
  rule: IConditionalFormatterRule,
  value: number | null
): boolean => {
  if (rule.type === "scale") {
    if (typeof value !== "number") {
      return false;
    } else {
      if (
        typeof rule.condition.value.min === "number" &&
        typeof rule.condition.value.max === "number"
      ) {
        if (
          value >= rule.condition.value.min &&
          value <= rule.condition.value.max
        ) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    }
  } else if (rule.type === "single") {
    switch (rule.condition.operator) {
      case "equals":
        if (typeof value === "number" && value === rule.condition.value) {
          return true;
        } else {
          return false;
        }
      case "notEquals":
        if (typeof value === "number" && value !== rule.condition.value) {
          return true;
        } else {
          return false;
        }
      case "greaterThan":
        if (typeof value === "number" && value > rule.condition.value) {
          return true;
        } else {
          return false;
        }
      case "greatherThanOrEqualsTo":
        if (typeof value === "number" && value >= rule.condition.value) {
          return true;
        } else {
          return false;
        }
      case "lessThan":
        if (typeof value === "number" && value < rule.condition.value) {
          return true;
        } else {
          return false;
        }
      case "lessThanOrEqualsTo":
        if (typeof value === "number" && value <= rule.condition.value) {
          return true;
        } else {
          return false;
        }
      case "isNull":
        if (value == null) {
          return true;
        } else {
          return false;
        }
      case "isNotNull":
        if (value != null) {
          return true;
        } else {
          return false;
        }
      case "isInRange":
        if (
          typeof value === "number" &&
          value >= rule.condition.value.min &&
          value <= rule.condition.value.max
        ) {
          return true;
        } else {
          return false;
        }
      case "isNotInRange":
        if (
          typeof value === "number" &&
          (value < rule.condition.value.min || value > rule.condition.value.max)
        ) {
          return true;
        } else {
          return false;
        }
    }
  }
  return false;
};

export interface IConditionalFormatterOutputFormat
  extends ISingleColorRuleFormat {
  paletteColor?: IColor;
}

const getFormatFromRule = (
  org,
  rule: IConditionalFormatterRule,
  value?: number
): IConditionalFormatterOutputFormat => {
  if (rule.type === "single") {
    return {
      overrideValue: rule.format.overrideValue,
      fontColor: rule.format.fontColor,
      backgroundColor: rule.format.backgroundColor,
    };
  } else if (rule.type === "scale") {
    if (typeof value !== "number") {
      return {};
    } else {
      const palette = new PaletteGenerator({
        ...getSelectedPalette(org, rule.format.palette),
        numberOfColors: 101,
      });

      const distance = calculateReverseDistance(
        rule.condition.value.min,
        rule.condition.value.max,
        value
      );

      return {
        paletteColor: palette.getColorAtIndex(distance),
      };
    }
  }
};

export const getFormatFromRules = (
  org,
  rules: IConditionalFormatterRule[],
  value: number | string | null
): IConditionalFormatterOutputFormat => {
  for (const rule of rules ?? []) {
    let numberValue = Number(value);
    if (valueMatchesRule(rule, numberValue)) {
      return getFormatFromRule(org, rule, numberValue);
    }
  }
  return {};
};
