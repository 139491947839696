import { Select, Typography } from "antd";
import * as React from "react";
import { type AsyncData } from "../../../../../../helpers/typescriptHelpers";
import { type IDestination } from "../../../../../../interfaces/destinations";
import { type IDataset } from "../../../../../../interfaces/sources";
import {
  type SchemaResult,
  type Transformation,
} from "../../../../../../interfaces/transformations";
import { computeTransformations } from "../../../../../../services/BrizoService";
import { generateUniqueId } from "../../../../../../utils/uniqueId";

export interface IRecordKeySelectorProps {
  model: IDataset;
  currentWarehouse: IDestination;
  value?: string;
  onChange?: (e: any) => void;
}

const getSchemaForDataset = async (
  dataset: IDataset,
  currentWarehouseId: string,
  getRaw: boolean = false
) => {
  const prevId = generateUniqueId();
  const operation: Transformation = getRaw
    ? {
        var: prevId,
        domain: "dataset",
        operation: {
          type: "Table.FromWarehouseTable",
          args: {
            databaseName: dataset.warehouseDatabaseId,
            schemaName: dataset.warehouseSchemaId,
            tableName: dataset.warehouseTableId,
          },
        },
      }
    : {
        var: prevId,
        domain: "dataset",
        operation: {
          type: "Table.FromWhalyDataset",
          args: {
            datasetId: dataset.id,
          },
        },
      };
  return computeTransformations(currentWarehouseId, {
    schema: [
      operation,
      {
        var: generateUniqueId(),
        operation: {
          type: "Table.Schema",
          args: {
            table: prevId,
          },
        },
        domain: "viewResolver",
      },
    ],
  });
};

export function RecordKeySelector(props: IRecordKeySelectorProps) {
  const { model, currentWarehouse, ...rest } = props;
  const [schema, setSchema] = React.useState<AsyncData<SchemaResult>>({
    status: "initial",
  });

  React.useEffect(() => {
    const fetchSchema = async () => {
      try {
        setSchema({ status: "loading" });
        const res = await getSchemaForDataset(
          model,
          currentWarehouse.id,
          false
        );
        setSchema({ status: "success", data: res.data.schema as SchemaResult });
      } catch (err) {
        console.error(err);
        setSchema({ status: "error", error: err });
      }
    };
    fetchSchema();
  }, [model.id]);

  if (schema.status === "initial" || schema.status === "loading") {
    return (
      <div>
        <Typography.Text>Loading schema...</Typography.Text>
      </div>
    );
  }

  if (schema.status === "error") {
    return (
      <div>
        <Typography.Text type="danger">
          Error while fetching your schema...s
        </Typography.Text>
      </div>
    );
  }

  return (
    <Select {...rest}>
      {Object.keys(schema.data).map((k) => {
        return (
          <Select.Option key={k} value={k}>
            {k}
          </Select.Option>
        );
      })}
    </Select>
  );
}
