const REPORT_FRAGMENT = `
fragment ReportFragment on Report {
  id
  canBeEditedByCurrentUser
  name
  description
  type
  slug
  folder {
    id
  }
  createdAt
  createdBy {
    id
    firstName
    lastName
    email
    gravatar
    avatarColor
  }
  updatedAt
  updatedBy {
    firstName
    lastName
    email
    gravatar
    avatarColor
  }
}
`;

export const ALL_REPORTS_GQL = `
${REPORT_FRAGMENT}

query fetchReports($orgId: ID!, $folderSlug: String, $type: [ReportTypeType]) {
  allReports(sortBy: name_ASC, where:{AND: [{org: {id: $orgId}}, {type_in: $type}, {deleted_not: true}, { folder: { id_not: null, slug: $folderSlug } }]}) {
    ...ReportFragment
  }
}
`;

export const PREVIOUS_REPORT_GQL = `
  ${REPORT_FRAGMENT}

  query fetchPreviousReports($orgId: ID!, $userId: ID!) {
    allReportUserLastViews(
      sortBy: lastViewedAt_DESC
      where: {
        AND: [
          { org: { id: $orgId } }
          { user: { id: $userId } }
          { report: { deleted_not: true, folder: { deleted_not: true } } }
        ]
      }
      first: 5
    ) {
      lastViewedAt
      report {
        ...ReportFragment
      }
    }
  }
`;

export const POPULAR_REPORT_GQL = `
  query fetchPopularReports($orgId: ID!, $createdAfter: DateTime) {
    allReportUserViews(
      sortBy: createdAt_DESC
      where: {
        AND: [
          { org: { id: $orgId } }
          { createdAt_gte: $createdAfter }
          { report: { deleted_not: true, folder: { deleted_not: true } } }
        ]
      }
      first: 200
    ) {
      id
      report {
        id
        name
        slug
        type
      }
    }
  }
`;
