import { BulbTwoTone, CaretDownFilled } from "@ant-design/icons";
import { Button, Dropdown, Skeleton, Space, Typography } from "antd";
import { COLOR_GOLD } from "../../layout/domain";
import { RCAElement } from "./RCAELement";
import { type RCAMetricFormatter } from "./interface";

export const autoKey = `_wly_auto`;

interface IRCAColumnProps {
  dimension?: { key: string; label: string };
  parentProportion: number;
  rootProportion: number;
  data: Array<{ key: string; name: string; value: number; proportion: number }>;
  selected?: string;
  onSelect?: (s: string) => void;
  onChangeColumnDefintiion?: (c: string) => void;
  availableColumns?: Array<{ key: string; label: string }>;
  auto?: boolean;
  redirectToRecords?: (dimKey: string, value: string) => void;
  formatter: RCAMetricFormatter;
}

interface IRCAColumnSkeletonProps {
  number?: number;
}

function RCAColumnSkeleton(props: IRCAColumnSkeletonProps) {
  const { number } = props;

  return (
    <div
      style={{
        width: 300,
        minWidth: 300,
        maxHeight: "100%",
        overflowY: "auto",
      }}
    >
      <Space direction="vertical" style={{ width: "100%" }}>
        {Array(number ? number : 3)
          .fill(1)
          .map((d, i) => {
            return <Skeleton.Button active block shape="round" key={i} />;
          })}
      </Space>
    </div>
  );
}

export function RCAColumn(props: IRCAColumnProps) {
  const {
    rootProportion,
    parentProportion,
    data,
    selected,
    onSelect,
    dimension,
    availableColumns,
    onChangeColumnDefintiion,
    auto,
    redirectToRecords,
    formatter,
  } = props;

  return (
    <div
      style={{
        width: 300,
        minWidth: 300,
        maxHeight: "100%",
        overflowY: "auto",
      }}
    >
      <div style={{ marginBottom: 12, height: 24, opacity: dimension ? 1 : 0 }}>
        {availableColumns &&
        availableColumns.length &&
        onChangeColumnDefintiion ? (
          <Dropdown
            arrow
            placement="bottomLeft"
            menu={{
              items: [
                ...availableColumns.map((ac) => ({
                  ...ac,
                  onClick: () => onChangeColumnDefintiion(ac.key),
                })),
                { type: "divider" },
                {
                  key: autoKey,
                  label: (
                    <>
                      Auto <BulbTwoTone twoToneColor={COLOR_GOLD} />{" "}
                    </>
                  ),
                  onClick: () => onChangeColumnDefintiion(autoKey),
                },
              ],
            }}
            trigger={["click"]}
          >
            <Button size="small" type="text">
              <Typography.Text strong>
                {dimension ? dimension.label : ""}
                {auto && (
                  <>
                    {" "}
                    <BulbTwoTone twoToneColor={COLOR_GOLD} />
                  </>
                )}{" "}
                <span style={{ fontSize: "0.9em" }}>
                  <CaretDownFilled />
                </span>
              </Typography.Text>
            </Button>
          </Dropdown>
        ) : (
          <Typography.Text strong>
            {dimension ? dimension.label : "Metric"}
          </Typography.Text>
        )}
      </div>
      <Space direction="vertical" style={{ width: "100%" }}>
        {data.map((d) => {
          return (
            <RCAElement
              key={d.key}
              name={d.name}
              value={d.value}
              proportion={d.proportion}
              proportionFormatter="PERCENT_FROM_PARENT"
              parentProportion={parentProportion}
              rootProportion={rootProportion}
              selected={selected === d.key}
              onSelect={onSelect ? () => onSelect(d.key) : undefined}
              redirectToRecords={
                redirectToRecords && dimension
                  ? (v) => redirectToRecords(dimension.key, v)
                  : undefined
              }
              formatter={formatter}
            />
          );
        })}
      </Space>
    </div>
  );
}
