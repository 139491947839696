import { gql } from "@apollo/client";
import type { MutateOptions } from "../../../../../../components/hooks/query/useQGLMutate";
import { useGQLMutate } from "../../../../../../components/hooks/query/useQGLMutate";
import { OBJECT_VIEW_FRAGMENT } from "../../../../../../fragments/object";
import type { IObjectView } from "../../../../../../interfaces/object";

type MutationData = { createObjectView: IObjectView };

type MutationVariables = Record<string, any>;

const CreateObjectViewQuery = gql`
  ${OBJECT_VIEW_FRAGMENT}
  mutation CreateObjectView($data: ObjectViewCreateInput!) {
    createObjectView(data: $data) {
      ...ObjectViewFragment
      createdBy {
        id
      }
    }
  }
`;

export const useCreateObjectView = (options?: MutateOptions<MutationData>) => {
  return useGQLMutate<MutationData, MutationVariables>(
    CreateObjectViewQuery,
    options
  );
};
