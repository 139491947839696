import { useFormikContext } from "formik";
import React from "react";
import usePrevious from "../../hooks/usePrevious";

interface FormObserverProps<T> {
  onChange: (values: T, previous?: T) => void;
}

export function FormObserver<T>(props: FormObserverProps<T>) {
  const { onChange } = props;
  const { values } = useFormikContext<T>();
  const previous = usePrevious(values);
  React.useEffect(() => {
    onChange(values, previous);
  }, [values]);
  return null;
}
