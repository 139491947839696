import { CameraFilled, LoadingOutlined } from "@ant-design/icons";
import type { UploadProps } from "antd";
import { Button, Modal, Upload } from "antd";
import * as React from "react";
import type { InjectedAntUtilsProps } from "../../../components/ant-utils/withAntUtils";
import { withAntUtils } from "../../../components/ant-utils/withAntUtils";
import { compose } from "../../../components/compose/WlyCompose";
import type { AsyncData } from "../../../helpers/typescriptHelpers";
import { getImageUrl } from "../../../services/AssetService";

import "./LogoUploader.scss";

interface ILogoUploaderProps {
  onSave: (filePath: string) => Promise<any>;
  modalTitle: string;
  minWidth: number;
  onUpload: (fileName: string, formData: FormData) => Promise<string>;
  children: React.ReactNode;
  display: "round" | "circle";
  size: number;
  disabled?: boolean;
}

const { Dragger } = Upload;

type Props = ILogoUploaderProps & InjectedAntUtilsProps;

function LogoUploader(props: Props) {
  const {
    antUtils: { message },
    onSave,
    children,
    modalTitle,
    minWidth,
    onUpload,
    display,
    size,
    disabled,
  } = props;
  const [modalOpen, setModalOpen] = React.useState<boolean>(false);
  const [uploading, setUploading] = React.useState<
    AsyncData<{ filePath: string }>
  >({
    status: "initial",
  });
  const [saving, setSaving] = React.useState<AsyncData<{}>>({
    status: "initial",
  });

  React.useEffect(() => {
    if (!modalOpen) {
      setUploading({ status: "initial" });
      setSaving({ status: "initial" });
    }
  }, [modalOpen]);

  const verifyImageProperties = (file: File): Promise<File> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = function (e) {
        // verify height & size
        const image = new Image();
        image.src = e.target.result as string;
        image.onload = function () {
          var height = image.height;
          var width = image.width;
          if (minWidth && (height < minWidth || width < minWidth)) {
            return reject(
              new Error("Height and Width must be more than 256px.")
            );
          }
          if (height !== width) {
            return reject(new Error("Image must be a square."));
          }
          return resolve(file);
        };
      };
      reader.onerror = function (e) {
        reject(new Error("Couldn't read image"));
      };
      reader.readAsDataURL(file);
    });
  };

  const uploadProps: UploadProps = {
    name: "file",
    disabled: uploading.status === "loading" || disabled,
    showUploadList: false,
    accept: "image/*",
    customRequest: (options) => {
      const formData = new FormData();
      formData.append("file", options.file);
      onUpload((options.file as File).name, formData)
        .then((r) => {
          options.onSuccess(r);
        })
        .catch((err) => {
          options.onError(err);
        });
    },
    onChange(info) {
      const { status } = info.file;
      if (status === "uploading") {
        setUploading({ status: "loading" });
      }
      if (status === "done" && info.file?.response) {
        setUploading({
          status: "success",
          data: { filePath: info.file?.response },
        });
      } else if (status === "error") {
        setUploading({ status: "initial" });
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    beforeUpload: async (file) => {
      setUploading({ status: "loading" });
      try {
        const validatedFile = await verifyImageProperties(file);
        return validatedFile;
      } catch (err) {
        setUploading({ status: "initial" });
        message.error(`Logo upload failed: ${err.message}`);
        throw new Error("`Logo upload failed");
      }
    },
  };

  const renderInnerModal = () => {
    if (uploading.status === "success") {
      return (
        <div
          style={{
            height: 200,
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img
            style={{
              height: "100%",
              borderRadius: display === "circle" ? "50%" : 6,
            }}
            src={getImageUrl(uploading.data.filePath)}
            alt={"Logo"}
          />
        </div>
      );
    }
    if (uploading.status === "loading") {
      return (
        <Dragger {...uploadProps}>
          <p className="ant-upload-drag-icon">
            <LoadingOutlined />
          </p>
          <p className="ant-upload-text">Uploading...</p>
        </Dragger>
      );
    }

    return (
      <Dragger {...uploadProps}>
        <p className="ant-upload-drag-icon">
          <CameraFilled />
        </p>
        <p className="ant-upload-text">Drag photo here</p>
        <p className="ant-upload-hint">
          Your logo should be square with a minimum of {minWidth}x{minWidth}px
          resolution.
        </p>
      </Dragger>
    );
  };

  const renderFooter = () => {
    if (uploading.status === "success") {
      return (
        <div className="logo-edit-modal-footer">
          <div className="logo-edit-modal-footer-left">
            <Button
              disabled={saving.status === "loading"}
              onClick={() => setModalOpen(false)}
            >
              Cancel
            </Button>
          </div>
          <div className="logo-edit-modal-footer-right">
            <Button
              disabled={saving.status === "loading"}
              loading={saving.status === "loading"}
              onClick={async () => {
                try {
                  setSaving({ status: "loading" });
                  await onSave(getImageUrl(uploading.data.filePath));
                  setSaving({ status: "success", data: {} });
                  setModalOpen(false);
                } catch (err) {
                  console.error(err);
                  message.error("Couldn't set new logo... try again.");
                  setSaving({ status: "error", error: err });
                }
              }}
              type="primary"
            >
              Update
            </Button>
          </div>
        </div>
      );
    }
    return null;
  };

  return (
    <>
      <div
        className="logo-avatar-edit"
        style={{ width: size, cursor: disabled ? "default" : "pointer" }}
        onClick={disabled ? undefined : () => setModalOpen(true)}
      >
        {children}
        {!disabled && (
          <div className={`logo-avatar-edit-overlay ${display}`}>
            <CameraFilled />
          </div>
        )}
      </div>
      <Modal
        title={modalTitle}
        open={modalOpen}
        className="logo-edit-modal"
        maskClosable={saving.status === "loading" ? false : true}
        footer={renderFooter()}
        onCancel={() => setModalOpen(false)}
      >
        {renderInnerModal()}
      </Modal>
    </>
  );
}

export default compose<Props, ILogoUploaderProps>(withAntUtils)(LogoUploader);
