import type { Filter } from "@cubejs-client/core";
import type * as duckdb from "@duckdb/duckdb-wasm";
import React from "react";
import { flushSync } from "react-dom";
import { withRouter, type RouteComponentProps } from "react-router";
import { compose } from "../../../../../components/compose/WlyCompose";
import Feednack from "../../../../../components/layout/feedback/feedback";
import Loading from "../../../../../components/layout/feedback/loading";
import { encodeLagoonQueryForURL } from "../../../../../helpers/queryStringHelpers";
import type { AsyncData } from "../../../../../helpers/typescriptHelpers";
import { routeDescriptor } from "../../../../../routes/routes";
import { RCAColumn, autoKey } from "./RCAColumn";
import type { RootCauseAnalysis } from "./interface";

interface IRootCauseAnalysisTableProps {
  rca: RootCauseAnalysis;
  db: duckdb.AsyncDuckDB;
  table: string;
  find: "high" | "low";
  selectedProportionDimension: string;
  additionalFilters: IAdditionalFilters;
  injectedDimensionsColumn?: string;
}

export type IAdditionalFilters = Array<{ [key: string]: string[] }>;

interface IColumnData {
  items: Array<{ [key: string]: number | string }>;
  column: "primary" | string;
  selectedValue?: string;
  auto?: boolean;
}

type Props = IRootCauseAnalysisTableProps & RouteComponentProps<{}>;

const sanitizeValue = (v?: string) => v?.replaceAll("'", "''");

function RootCauseAnalysisTable(props: Props) {
  const {
    rca,
    db,
    table,
    find,
    selectedProportionDimension,
    history,
    match: { params },
    injectedDimensionsColumn,
  } = props;

  const [columnsData, setColumnData] = React.useState<
    AsyncData<Array<IColumnData>>
  >({ status: "initial" });

  const [newColumnStatus, setNewColumnStatus] = React.useState<AsyncData<null>>(
    { status: "initial" }
  );

  const [primaryMetricKey, setPrimaryMetricKey] = React.useState(
    rca.primaryMetric.key
  );
  const allMetrics = [rca.primaryMetric, ...(rca.secondaryMetrics || [])];

  React.useEffect(() => {
    setPrimaryMetricKey(rca.primaryMetric.key);
  }, [rca.primaryMetric.key]);

  const fetchInitialData = async () => {
    setColumnData({ status: "loading" });
    try {
      const c = await db.connect();

      // find the metric value data
      const allDimensionReduced = rca.dimensions
        .map((v) => {
          return `${v.key} is null`;
        })
        .join(" and ");
      const metricQuery = `select * from ${table} where ${allDimensionReduced}`;
      const metricData = await c.query(metricQuery);
      const col1Results = metricData.toArray().map((row) => row.toJSON());

      const populationImpacted = col1Results[0][selectedProportionDimension];

      // find the most impacted dimension in low or high
      const whereClause = rca.dimensions
        .map((du) => {
          return `(${rca.dimensions
            .map((dd) => {
              if (du.key === dd.key) {
                return `${dd.key} is not null`;
              }
              return `${dd.key} is null`;
            })
            .join(` and `)})`;
        })
        .join(` or `);

      const firstDimensionQuery = `select *, "${primaryMetricKey}" * "${selectedProportionDimension}" / ${parseInt(
        populationImpacted
      )} as impact from ${table} where ${whereClause} order by impact ${
        find === "low" ? "asc" : "desc"
      }`;

      const firstDimensionsData = await c.query(firstDimensionQuery);
      const col2Dim = firstDimensionsData
        .toArray()
        .map((row) => row.toJSON())[0];

      const nonNullDimKey = injectedDimensionsColumn
        ? injectedDimensionsColumn
        : Object.keys(col2Dim).filter(
            (k) => rca.dimensions.map((d) => d.key).includes(k) && col2Dim[k]
          )[0];
      if (!nonNullDimKey) {
        throw new Error("no dim found");
      }

      const col2whereClause = rca.dimensions
        .map((d) => {
          if (d.key === nonNullDimKey) {
            return `${d.key} is not null`;
          }
          return `${d.key} is null`;
        })
        .join(` and `);

      const col2DimensionQuery = `select *, ${primaryMetricKey} * ${selectedProportionDimension} / ${parseInt(
        populationImpacted as any
      )} as "impact" from ${table} where ${col2whereClause} order by impact ${
        find === "low" ? "asc" : "desc"
      }`;
      const col2Data = await c.query(col2DimensionQuery);
      const col2Results = col2Data.toArray().map((row) => row.toJSON());
      setColumnData({
        status: "success",
        data: [
          {
            column: "primary",
            items: col1Results,
            selectedValue: "primary",
          },
          {
            column: nonNullDimKey,
            items: col2Results,
            auto: true,
          },
        ],
      });
      await c.close();
    } catch (err) {
      setColumnData({ status: "error", error: err });
    }
  };

  React.useEffect(() => {
    fetchInitialData();
  }, [find, primaryMetricKey]);

  // the first column is always the metric column
  // the second column is always the dim value with the higest / lowest contribution based on what the user determines
  // the third and above columns are determined by the user clicks

  const findMostImpactedDimension = async (
    rest: IColumnData[],
    parentProportion: number,
    c: duckdb.AsyncDuckDBConnection
  ) => {
    const currenScope = rest
      .filter((rc) => rc.selectedValue)
      .map((rc) => `${rc.column} = '${sanitizeValue(rc.selectedValue)}'`)
      .join(` and `);

    const columnsToAnalyze = rca.dimensions.filter(
      (d) => !rest.map((r) => r.column).includes(d.key)
    );

    const where = columnsToAnalyze
      .map((c) => {
        return `(${columnsToAnalyze
          .map((a) => {
            if (a.key === c.key) {
              return `${a.key} is not null`;
            }
            return `${a.key} is null`;
          })
          .join(` and `)})`;
      })
      .join(` or `);

    const query = `
      with scopeddata as (
        select * from ${table} where ${currenScope}
      ),
      ordereddimensions as (
        select *, ${primaryMetricKey} * ${selectedProportionDimension} / ${parseInt(
      parentProportion as any
    )} as "impact" from scopeddata where ${where} order by impact ${
      find === "low" ? "asc" : "desc"
    }
      )
      select * from ordereddimensions limit 1;
      `;

    const properties = await c.query(query);
    const col2Results = properties.toArray().map((row) => row.toJSON())[0];
    const propertyKey = Object.keys(col2Results).find(
      (k) => columnsToAnalyze.map((c) => c.key).includes(k) && col2Results[k]
    );
    return propertyKey;
  };

  // method triggered when the user click on a value in a column
  const onSelectedValueAtIndexChange = async (
    index: number,
    parentProportion: number,
    selectedValue: string
  ) => {
    if (columnsData.status !== "success") {
      return;
    }

    setNewColumnStatus({ status: "loading" });
    // this is triggered by the user selecting a card at any level
    // reset the current columns and display loading indicator;
    const remainingColumns: IColumnData[] = columnsData.data
      .filter((_, i) => i <= index)
      .map((_, i) => ({
        ..._,
        selectedValue: i === index ? selectedValue : _.selectedValue,
      }));

    try {
      const c = await db.connect();

      const [metricCol, ...rest] = remainingColumns;

      const propertyKey = await findMostImpactedDimension(
        rest,
        parentProportion,
        c
      );

      const newWhere = rca.dimensions
        .map((d) => {
          if (d.key === propertyKey) {
            return `${propertyKey} is not null`;
          }
          const foundScopedProperty = rest.find(
            (r) => r.column === d.key && r.selectedValue
          );
          if (foundScopedProperty) {
            return `${foundScopedProperty.column} = '${sanitizeValue(
              foundScopedProperty.selectedValue
            )}'`;
          }
          return `${d.key} is null`;
        })
        .join(` and `);

      const newQuery = `select *, ${primaryMetricKey} * ${selectedProportionDimension} / ${parseInt(
        parentProportion as any
      )} as "impact" from ${table} where ${newWhere} order by impact ${
        find === "low" ? "asc" : "desc"
      }`;

      const newResult = await c.query(newQuery);

      const lastColumnResults = newResult.toArray().map((row) => row.toJSON());

      // save
      setColumnData((v) => {
        if (v.status === "success") {
          return {
            ...v,
            data: [
              ...remainingColumns,
              {
                column: propertyKey,
                items: lastColumnResults,
                auto: true,
              } as IColumnData,
            ],
          };
        }
        return v;
      });
      setNewColumnStatus({ status: "initial" });

      await c.close();
    } catch (err) {
      setNewColumnStatus({ status: "error", error: err });
    }
  };

  // method triggered when the user changes the column definition
  const onSelectedDefinitionAtIndexChange = async (
    index: number,
    parentProportion: number,
    columnDefinition: string
  ) => {
    if (columnsData.status !== "success") {
      return;
    }

    setNewColumnStatus({ status: "loading" });
    // this is triggered by the user selecting a card at any level
    // reset the current columns and display loading indicator;
    const remainingColumns: IColumnData[] = columnsData.data.filter(
      (_, i) => i <= index
    );

    try {
      const c = await db.connect();

      const [metricCol, ...rest] = remainingColumns;

      const propertyKey = await (columnDefinition === autoKey
        ? findMostImpactedDimension(rest, parentProportion, c)
        : Promise.resolve(columnDefinition));

      const newWhere = rca.dimensions
        .map((d) => {
          if (d.key === propertyKey) {
            return `${propertyKey} is not null`;
          }
          const foundScopedProperty = rest.find(
            (r) => r.column === d.key && r.selectedValue
          );
          if (foundScopedProperty) {
            return `${foundScopedProperty.column} = '${foundScopedProperty.selectedValue}'`;
          }
          return `${d.key} is null`;
        })
        .join(` and `);

      const newQuery = `select *, ${primaryMetricKey} * ${selectedProportionDimension} / ${parseInt(
        parentProportion as any
      )} as "impact" from ${table} where ${newWhere} order by impact ${
        find === "low" ? "asc" : "desc"
      }`;

      const newResult = await c.query(newQuery);

      const lastColumnResults = newResult.toArray().map((row) => row.toJSON());

      // save
      setColumnData((v) => {
        if (v.status === "success") {
          return {
            ...v,
            data: [
              ...remainingColumns,
              {
                column: propertyKey,
                items: lastColumnResults,
                auto: columnDefinition === autoKey,
              } as IColumnData,
            ],
          };
        }
        return v;
      });
      setNewColumnStatus({ status: "initial" });

      await c.close();
    } catch (err) {
      setNewColumnStatus({ status: "error", error: err });
    }
  };

  const currentMetric = allMetrics.find((m) => m.key === primaryMetricKey);

  if (!currentMetric) {
    return (
      <Feednack>
        <div>Can't find metric</div>
      </Feednack>
    );
  }

  const renderColumn = () => {
    if (columnsData.status === "initial" || columnsData.status === "loading") {
      return (
        <Feednack>
          <Loading />
        </Feednack>
      );
    }
    if (columnsData.status === "error") {
      return <Feednack>{columnsData.error?.message}</Feednack>;
    }

    const [col1, col2, ...rest] = columnsData.data;
    const rootValue = col1.items[0][primaryMetricKey] as number;
    const rootProportion = col1.items[0][selectedProportionDimension] as number;

    const selectedMetric =
      rca.primaryMetric.key === primaryMetricKey
        ? rca.primaryMetric
        : rca.secondaryMetrics?.find((rca) => rca.key === primaryMetricKey);

    return (
      <>
        <RCAColumn
          parentProportion={rootProportion}
          rootProportion={rootProportion}
          data={[
            {
              key: rca.primaryMetric.key,
              name: rca.primaryMetric.label,
              value: rootValue,
              proportion: rootProportion,
            },
          ]}
          selected={primaryMetricKey}
          onChangeColumnDefintiion={(s) => {
            flushSync(() => {
              setPrimaryMetricKey(s);
            });
          }}
          dimension={rca.secondaryMetrics?.length ? selectedMetric : undefined}
          availableColumns={
            rca.secondaryMetrics?.length
              ? [
                  {
                    key: rca.primaryMetric.key,
                    label: rca.primaryMetric.label,
                  },
                  ...rca.secondaryMetrics.map((sm) => {
                    return {
                      key: sm.key,
                      label: sm.label,
                    };
                  }),
                ]
              : undefined
          }
          formatter={currentMetric.formatter}
        />
        <RCAColumn
          dimension={rca.dimensions.find((d) => d.key === col2.column)}
          parentProportion={rootProportion}
          rootProportion={rootProportion}
          data={col2.items.map((i) => ({
            key: i[col2.column] as string,
            name: i[col2.column] as string,
            value: i[primaryMetricKey] as number,
            proportion: i[selectedProportionDimension] as number,
          }))}
          auto={col2.auto}
          selected={col2.selectedValue}
          availableColumns={rca.dimensions.filter((r) => r.key !== col2.column)}
          redirectToRecords={(dimKey, value) => {
            const q: Filter[] = [
              {
                member: rca.dimensions.find((d) => d.key === dimKey)?.cubeName,
                operator: "equals",
                values: [value],
              },
            ];
            history.push(
              routeDescriptor.object.renderRoute(
                {
                  ...params,
                  objectSlug: rca.object.slug,
                },
                {
                  filters: encodeLagoonQueryForURL(q),
                }
              )
            );
          }}
          onChangeColumnDefintiion={(columnDefinition) => {
            if (columnDefinition === autoKey) {
              // we switch back to auto mode
              fetchInitialData();
            } else {
              // we switch to manual model
              onSelectedDefinitionAtIndexChange(
                0,
                rootProportion,
                columnDefinition
              );
            }
          }}
          onSelect={(s) => {
            setColumnData((c) => {
              if (c.status === "success") {
                return {
                  ...c,
                  data: c.data.map((v, i) => {
                    if (i === 1) {
                      return {
                        ...v,
                        selectedValue: s,
                      };
                    }
                    return v;
                  }),
                };
              }
              return c;
            });
            onSelectedValueAtIndexChange(1, rootProportion, s);
          }}
          formatter={currentMetric.formatter}
        />
        {rest.map((r, i, s) => {
          const isLast = rca.dimensions.length - 1 === i;

          const parentProportion = (i === 0 ? col2 : s[i - 1]).items.find(
            (a) => {
              const left = a[i === 0 ? col2.column : s[i - 1].column];
              const right =
                i === 0 ? col2.selectedValue : s[i - 1].selectedValue;
              return left === right;
            }
          )?.[selectedProportionDimension] as number;

          const usedDimensionsAbove = [
            col2.column,
            ...rest.filter((_, j) => j < i).map((_) => _.column),
          ];
          const remainingDimensions = rca.dimensions
            .filter((_) => !usedDimensionsAbove.includes(_.key))
            .filter((a) => a.key !== r.column);

          return (
            <RCAColumn
              key={r.column}
              dimension={rca.dimensions.find((d) => d.key === r.column)}
              parentProportion={parentProportion}
              rootProportion={rootProportion}
              data={r.items.map((i) => ({
                key: i[r.column] as string,
                name: i[r.column] as string,
                value: i[primaryMetricKey] as number,
                proportion: i[selectedProportionDimension] as number,
              }))}
              auto={r.auto}
              selected={r.selectedValue}
              availableColumns={remainingDimensions}
              onChangeColumnDefintiion={(columnDefinition) => {
                if (isLast) {
                  return;
                }
                onSelectedDefinitionAtIndexChange(
                  i + 1,
                  parentProportion,
                  columnDefinition
                );
              }}
              onSelect={
                !isLast
                  ? (s) => {
                      setColumnData((c) => {
                        if (c.status === "success") {
                          return {
                            ...c,
                            data: c.data.map((v, ind) => {
                              if (ind === i + 2) {
                                return {
                                  ...v,
                                  selectedValue: s,
                                };
                              }
                              return v;
                            }),
                          };
                        }
                        return c;
                      });
                      onSelectedValueAtIndexChange(i + 2, parentProportion, s);
                    }
                  : undefined
              }
              formatter={currentMetric.formatter}
              redirectToRecords={(dimKey, value) => {
                const previousFilters: Filter[] = rest
                  .filter((_, j) => j < i)
                  .map((_) => {
                    return {
                      member: rca.dimensions.find((d) => d.key === _.column)
                        ?.cubeName,
                      operator: "equals",
                      values: [_.selectedValue!],
                    } as Filter;
                  });
                const currentFilter = [
                  {
                    member: rca.dimensions.find((d) => d.key === col2.column)
                      ?.cubeName,
                    operator: "equals",
                    values: [col2.selectedValue!],
                  },
                  ...previousFilters,
                  {
                    member: rca.dimensions.find((d) => d.key === dimKey)
                      ?.cubeName,
                    operator: "equals",
                    values: [value],
                  },
                ];

                history.push(
                  routeDescriptor.object.renderRoute(
                    {
                      ...params,
                      objectSlug: rca.object.slug,
                    },
                    {
                      filters: encodeLagoonQueryForURL(currentFilter),
                    }
                  )
                );
              }}
            />
          );
        })}
      </>
    );
  };

  return (
    <div
      style={{
        display: "flex",
        flex: 1,
        overflowY: "hidden",
        gap: 16,
        overflowX: "auto",
        scrollbarWidth: "none",
        height: "100%",
      }}
    >
      {renderColumn()}
    </div>
  );
}

export default compose<Props, IRootCauseAnalysisTableProps>(withRouter)(
  RootCauseAnalysisTable
);
