import {
  ArrowDownOutlined,
  ArrowUpOutlined,
  CloseCircleFilled,
  CopyOutlined,
  EditFilled,
  HolderOutlined,
  RiseOutlined,
} from "@ant-design/icons";
import { Popover, Select, Tag } from "antd";
import * as React from "react";

import type { MeasureType } from "../measure-table/MeasureTable";
import "./MeasureItem.scss";

type IMeasureItemProps = IBaseMeasureItemProps;

export type MeasureItemSortValue = "asc" | "desc";

export interface IBaseMeasureItemProps {
  type: MeasureType | "property";
  name: string;
  description?: string;
  onDuplicate?: () => void;
  onDelete?: () => void;
  onModify?: () => void;
  small?: boolean;
  onSort?: (s?: MeasureItemSortValue) => void;
  sort?: MeasureItemSortValue;
  isDragging?: boolean;
  onClick?: () => void;
  selected?: boolean;
  error?: string[];
  isUsedInChart?: boolean;
  compact?: boolean;
  hidePopover?: boolean;
  isDeleted?: boolean;
  timeAgg?: string;
  onTimeAgg?: (agg: string) => void;
  draggable?: boolean;
}

type IState = {};

export default class MeasureItem extends React.Component<
  IMeasureItemProps,
  IState
> {
  constructor(props: IMeasureItemProps) {
    super(props);
  }

  public renderSort = (sort?: MeasureItemSortValue) => {
    const icon = !sort ? undefined : sort === "asc" ? (
      <ArrowUpOutlined />
    ) : (
      <ArrowDownOutlined />
    );
    return (
      <span style={{ paddingLeft: 2 }} className="measure-item-icon">
        {icon}
      </span>
    );
  };

  public render() {
    const {
      name,
      onDelete,
      onDuplicate,
      type,
      small,
      onModify,
      description,
      error,
      isUsedInChart,
      compact,
      isDeleted,
      draggable,
      onTimeAgg,
      timeAgg,
      sort,
      onSort,
    } = this.props;

    const onClick = () => {
      if (this.props.onSort) {
        const sort = this.props.sort;
        const nextBestAction: MeasureItemSortValue =
          sort === "asc" ? "desc" : "asc";
        return this.props.onSort(nextBestAction);
      } else if (this.props.onClick) {
        return this.props.onClick();
      }
      return null;
    };

    const conditionalWrap = (el: React.ReactNode) => {
      if (this.props.isDragging) {
        return el;
      }
      if (this.props.hidePopover) {
        return el;
      }
      return (
        <Popover
          mouseEnterDelay={0.4}
          placement="rightTop"
          title={<b>{name}</b>}
          destroyTooltipOnHide
          content={
            <div style={{ width: "300px", whiteSpace: "pre-wrap" }}>
              {description ? description : <i>no description</i>}
              {error && error.length ? (
                <div style={{ width: "300px" }}>
                  <Tag color="error">{error[0]}</Tag>
                </div>
              ) : null}
            </div>
          }
        >
          {el}
        </Popover>
      );
    };

    const classNames = ["measure-item", `${type.toLowerCase()}-item`];
    if (small) {
      classNames.push("small");
    } else {
      classNames.push("flex");
    }
    if (isDeleted) {
      classNames.push("deleted");
    }
    if (error?.length) {
      classNames.push("error");
    }
    if (compact) {
      classNames.push("compact");
    }
    if (draggable) {
      classNames.push("draggable");
    }

    return conditionalWrap(
      <span className={classNames.join(" ")}>
        <span className="measure-item-drag-icon">
          <HolderOutlined />
        </span>
        {onSort ? (
          <span onClick={onClick} className="measure-item-content">
            <span className="measure-item-content measure-item-sortable">
              {isDeleted ? "Deleted" : name}
            </span>
            {this.renderSort(sort)}
          </span>
        ) : (
          <span onClick={onClick} className="measure-item-content">
            {isDeleted ? "Deleted" : name}
          </span>
        )}
        {onTimeAgg && (
          <Select
            className="measure-select"
            defaultValue={""}
            size="small"
            style={{ background: "transparent", maxWidth: 72 }}
            dropdownStyle={{ width: 100 }}
            bordered={false}
            value={timeAgg ?? ""}
            options={[
              { label: "none", value: "" },
              { label: "day", value: "day" },
              { label: "week", value: "week" },
              { label: "month", value: "month" },
              { label: "year", value: "year" },
            ]}
            onChange={(v) => {
              onTimeAgg?.(v);
            }}
          />
        )}
        {isUsedInChart && (
          <span className="measure-item-actions">
            <RiseOutlined />
          </span>
        )}
        {onDuplicate && (
          <span className="measure-item-actions" onClick={onDuplicate}>
            <CopyOutlined />
          </span>
        )}
        {onModify && (
          <span className="measure-item-actions" onClick={onModify}>
            <EditFilled />
          </span>
        )}
        {onDelete && (
          <span className="measure-item-actions" onClick={onDelete}>
            <CloseCircleFilled />
          </span>
        )}
      </span>
    );
  }
}
