import type { ColumnProps } from "antd/lib/table";
import { inject, observer } from "mobx-react";
import * as React from "react";
import { compose } from "../../components/compose/WlyCompose";
import OrgAvatar from "../../components/orgs/Avatar/OrgAvatar";
import CardTable from "../../components/table/CardTable";
import type { IUserRole } from "../../interfaces/user";
import type { UserStoreProps } from "../../store/userStore";

interface IUserOrgAccessTableProps {}

type Props = IUserOrgAccessTableProps & UserStoreProps;

class UserOrgAccessTable extends React.Component<Props> {
  public generateColumns = (): ColumnProps<IUserRole>[] => {
    return [
      {
        title: "Organization Name",
        key: "name",
        render: (v, s) => {
          return (
            <div>
              <div style={{ float: "left", display: "inline-clock" }}>
                <OrgAvatar
                  overrideUrl={s.org.logo}
                  name={s.org.name}
                  style={{ marginRight: 14 }}
                />
                <div style={{ display: "inline-block" }}>{s.org.name}</div>
              </div>
            </div>
          );
        },
      },
    ];
  };

  public render() {
    const { userStore } = this.props;
    const userRoles = userStore.user!.roles;
    return (
      <CardTable<IUserRole>
        cardTitle={<div>Organization Access</div>}
        actionButtons={<div />}
        rowKey={(s) => s.org.id}
        dataSource={userRoles}
        columns={this.generateColumns()}
        pagination={{
          size: "small",
          style: { display: "none" },
          defaultPageSize: 1000,
        }}
      />
    );
  }
}

export default compose<Props, IUserOrgAccessTableProps>()(
  inject("userStore")(observer(UserOrgAccessTable))
);
