import { ExclamationCircleOutlined, MoreOutlined } from "@ant-design/icons";
import type { MenuProps } from "antd";
import { Button, Col, Drawer, Dropdown, Row, Tag } from "antd";
import type { ColumnProps } from "antd/lib/table";
import { inject, observer } from "mobx-react";
import * as React from "react";
import type { RouteComponentProps } from "react-router";
import { withRouter } from "react-router";
import type { InjectedAntUtilsProps } from "../../../components/ant-utils/withAntUtils";
import { withAntUtils } from "../../../components/ant-utils/withAntUtils";
import { compose } from "../../../components/compose/WlyCompose";
import Aligner from "../../../components/layout/aligner/Aligner";
import CardTable from "../../../components/table/CardTable";
import type { IGroup } from "../../../interfaces/group";
import type { IOrg } from "../../../interfaces/org";
import { IUserRoleType } from "../../../interfaces/user";
import GraphQLService from "../../../services/graphql/GraphQLService";
import type { UserStoreProps } from "../../../store/userStore";
import type { InjectedOrgProps } from "../../orgs/WithOrg";
import WithOrg from "../../orgs/WithOrg";
import HasRoleAccess, {
  hasRoleAccessBoolean,
} from "../../user-settings/HasRoleAccess";
import "./UserGroupManagement.scss";
import GroupManagementForm from "./UserGroupManagementForm";

interface IUserGroupManagementTableProps {
  groups: IGroup[];
  currentOrg: IOrg;
}

interface IState {
  isGroupFormVisible: boolean;
  selectedGroup?: IGroup;
}

type Props = IUserGroupManagementTableProps &
  RouteComponentProps<{ organizationSlug: string }> &
  UserStoreProps &
  InjectedOrgProps &
  InjectedAntUtilsProps;

class UserGroupManagementTable extends React.Component<Props, IState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isGroupFormVisible: false,
    };
  }

  public showDrawer = () => {
    this.setState({ isGroupFormVisible: true });
  };

  public hideDrawer = () => {
    this.setState({ isGroupFormVisible: false, selectedGroup: undefined });
  };

  public onSubmit = () => {
    return this.props.userStore.getUser();
  };

  public deleteGroup = (group: IGroup) => {
    const {
      history,
      match: {
        params: { organizationSlug },
      },
      antUtils,
    } = this.props;
    return antUtils.modal.confirm({
      title: `Are your sure you want to delete this group?`,
      icon: <ExclamationCircleOutlined />,
      content: `By deleting this group, the sharing of some of your Reports will be lost for all people that belonged to this group.`,
      okText: "Delete",
      okButtonProps: {
        danger: true,
      },
      cancelText: "Cancel",
      onOk: async () => {
        try {
          await GraphQLService(
            `
          mutation DeleteGroup(
            $groupId: ID!,
          ) {
            updateGroup(id: $groupId, data: {isDeleted: true}) {
              id
            }
          }`,
            { groupId: group.id }
          );
          await this.onSubmit();
          antUtils.message.success("Group successfully deleted");
        } catch (err) {
          antUtils.message.error("An unexpected error happened, please retry");
        }
      },
      onCancel() {
        return null;
      },
    });
  };

  public renderTag = (g: IGroup) => {
    if (g.isSystemGroup) {
      return <Tag style={{ marginLeft: 8 }}>default</Tag>;
    }
  };

  public renderGroupName = (g: IGroup) => {
    if (g.portal && g.isSystemGroup) {
      return `All ${g.portal.name} users`;
    }
    return g.name;
  };

  public generateColumns = (
    connectedUserRoleType: IUserRoleType
  ): ColumnProps<IGroup>[] => [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (v, s) => {
        return (
          <div style={{ display: "flex" }}>
            <div>{this.renderGroupName(s)}</div>
            {this.renderTag(s)}
          </div>
        );
      },
    },
    {
      title: "Members",
      dataIndex: "members",
      key: "members",
      render: (v, s) => {
        return (
          <div>
            <div>{s.members.count} members</div>
          </div>
        );
      },
    },
    ...(connectedUserRoleType === IUserRoleType.ADMIN_BUILDER
      ? [
          {
            title: "Actions",
            key: "Actions",
            align: "right" as const,
            render: (v, s: IGroup) => {
              const group = s;

              const menu: MenuProps = {
                items: [
                  s.isSystemGroup && {
                    key: `view-group-${group.id}`,
                    onClick: () =>
                      this.setState({
                        isGroupFormVisible: true,
                        selectedGroup: group,
                      }),
                    label: "View",
                  },
                  !s.isSystemGroup && {
                    key: `edit-group-${group.id}`,
                    onClick: () =>
                      this.setState({
                        isGroupFormVisible: true,
                        selectedGroup: group,
                      }),
                    label: "Edit",
                  },
                  !s.isSystemGroup && {
                    key: `delete-group-${group.id}`,
                    onClick: () => this.deleteGroup(group),
                    label: "Delete",
                    danger: true,
                  },
                ],
              };

              return (
                <HasRoleAccess accessLevel={IUserRoleType.ADMIN_BUILDER}>
                  <Dropdown
                    trigger={["click"]}
                    placement="bottomRight"
                    menu={menu}
                  >
                    <Button
                      type="text"
                      shape="circle"
                      icon={<MoreOutlined />}
                    />
                  </Dropdown>
                </HasRoleAccess>
              );
            },
          },
        ]
      : []),
  ];

  public render() {
    const {
      groups,
      currentOrg,
      user: connectedUser,
      org,
      role: connectedUserRole,
    } = this.props;
    return (
      <Aligner>
        <Row justify="center" className="group-management">
          <Col xs={24} sm={24} md={20} lg={20} xl={20}>
            <CardTable<IGroup>
              cardTitle={<div className="title">User Groups</div>}
              actionButtons={
                hasRoleAccessBoolean(
                  IUserRoleType.ADMIN_BUILDER,
                  connectedUser,
                  org.id
                ) ? (
                  <Button onClick={this.showDrawer} type="primary">
                    Create new Group
                  </Button>
                ) : undefined
              }
              rowKey="id"
              dataSource={groups}
              columns={this.generateColumns(connectedUserRole.role)}
              pagination={{
                size: "small",
                style: { display: "none" },
                defaultPageSize: 1000,
              }}
            />
          </Col>
          <Drawer
            width={"35%"}
            open={this.state.isGroupFormVisible}
            closable={false}
            styles={{ body: { padding: "0 0 80px 0" } }}
            keyboard={false}
            maskClosable={false}
            destroyOnClose={true}
          >
            <GroupManagementForm
              orgId={currentOrg.id}
              isEditMode={this.state.selectedGroup !== undefined}
              selectedGroup={this.state.selectedGroup}
              onSubmit={this.onSubmit}
              onDrawerClose={this.hideDrawer}
            />
          </Drawer>
        </Row>
      </Aligner>
    );
  }
}

export default compose<Props, IUserGroupManagementTableProps>(
  inject("userStore"),
  observer,
  withRouter,
  WithOrg,
  withAntUtils
)(UserGroupManagementTable);
