import type { Query } from "@cubejs-client/core";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import HighchartsMore from "highcharts/highcharts-more";
import _ from "lodash";
import * as React from "react";
import type { ChartOption } from "../../../containers/chart-options/ChartOptions";
import type { UserLocale } from "../../../interfaces/user";
import type { Formatter } from "../domain";
import { localizeHighchart } from "../domain";
import type { HighchartLegendItem } from "../utils/HighchartsLegend";
import { HighchartsLegend } from "../utils/HighchartsLegend";
import {
  customFormatter,
  optionsHelper,
  renderSerieTooltip,
} from "../utils/optionsHelper";

HighchartsMore(Highcharts);

interface IStackedAreaChartProps {
  config: StackedAreaChartConfig;
  data: Array<{ [key: string]: string | number }>;
  height: number;
  width?: number;
  chartOptions?: ChartOption;
  onDrill?: (q: Query) => void;
  locale: UserLocale;
}

interface StackedAreaChartConfig {
  x: {
    key: string;
    label?: string;
  };
  y: Array<IYConfigStackedAreaChart>;
}

interface IYConfigStackedAreaChart {
  key: string;
  label?: string;
  color?: string;
  formatter: Formatter;
  canDrill: (xValue: string) => Query | null;
}

interface IState {
  legendItems: HighchartLegendItem[];
}

export default class StackedAreaChart extends React.Component<
  IStackedAreaChartProps,
  IState
> {
  chartRef: HighchartsReact.RefObject;

  constructor(props: IStackedAreaChartProps) {
    super(props);
    this.state = {
      legendItems: [],
    };
  }

  componentDidUpdate(prevProps: IStackedAreaChartProps) {
    if (
      (prevProps.width !== this.props.width ||
        prevProps.height !== this.props.height ||
        !_.isEqual(this.props.config, prevProps.config)) &&
      this.chartRef
    ) {
      this.chartRef.chart?.reflow?.();
    }
  }

  shouldComponentUpdate(
    nextProps: Readonly<IStackedAreaChartProps>,
    nextState: Readonly<IState>,
    nextContext: any
  ): boolean {
    if (!_.isEqual(this.state.legendItems, nextState.legendItems)) return true;
    if (!_.isEqual(this.props.width, nextProps.width)) return true;
    if (!_.isEqual(this.props.height, nextProps.height)) return true;
    if (!_.isEqual(this.props.data, nextProps.data)) return true;
    if (!_.isEqual(this.props.chartOptions, nextProps.chartOptions))
      return true;
    return false;
  }

  public render() {
    const { height, config, data, width, onDrill, chartOptions, locale } =
      this.props;

    localizeHighchart(Highcharts, locale);

    const xFormatter: Formatter | undefined = chartOptions?.axis?.bottom
      ?.timeFormat
      ? {
          format: {
            type: "moment",
            value: chartOptions?.axis?.bottom?.timeFormat,
          },
        }
      : undefined;

    const adjustedHeight =
      chartOptions?.["hide-legend"] !== true &&
      this.state.legendItems.length > 1
        ? height - 40
        : height;

    const defaultOptions = optionsHelper(
      adjustedHeight,
      width,
      locale,
      "time",
      undefined,
      undefined,
      xFormatter,
      chartOptions
    );

    const getXValue = (d: { [key: string]: string | number }) =>
      new Date(d[config.x.key]).getTime();

    let stacking: Highcharts.OptionsStackingValue = "normal";

    if (chartOptions?.["stacked-area-stacking"] === "percent") {
      stacking = "percent";
    }

    const options: Highcharts.Options = {
      ...defaultOptions,
      legend: {
        ...defaultOptions.legend,
        enabled: true,
        floating: true,
        x: -10000,
      },
      yAxis: {
        ...defaultOptions.yAxis?.[0],
        title: {
          text: chartOptions?.axis?.left?.label,
        },
        labels: {
          formatter: (ctx: any) => {
            if (chartOptions?.["stacked-area-stacking"] === "percent") {
              return ctx.value + "%";
            } else if (config?.y?.[0]?.formatter) {
              return customFormatter(
                ctx.value,
                locale,
                config?.y?.[0]?.formatter
              );
            } else {
              return ctx.value;
            }
          },
        },
        stackLabels: {
          style: {
            fontSize: "0.8em",
          },
          enabled:
            typeof chartOptions?.["label-stacked"] === "boolean"
              ? chartOptions?.["label-stacked"]
              : false,
          formatter: function () {
            if (chartOptions?.["stacked-area-stacking"] === "percent") {
              return null;
            } else if (config?.y?.[0]?.formatter) {
              return customFormatter(
                this.total,
                locale,
                config?.y?.[0]?.formatter
              );
            } else {
              return this.total?.toString();
            }
          },
        },
      },
      plotOptions: {
        ...defaultOptions.plotOptions,
        area: {
          stacking: stacking,
          lineWidth: 1,
          marker: {
            enabled: false,
          },
          dataLabels: {
            style: {
              fontSize: "0.8em",
            },
            enabled:
              typeof chartOptions?.label === "boolean"
                ? chartOptions?.label
                : false,
          },
        },
      },
      series: config.y.flatMap((y, i) => {
        return {
          type: "area",
          name: y.label,
          cursor: typeof onDrill === "function" ? "pointer" : "auto",
          events: {
            click: onDrill
              ? (e) => {
                  const xValue = data.find(
                    (q) => new Date(q[config.x.key]).getTime() === e.point.x
                  )![config.x.key];
                  const q = y.canDrill(xValue.toString());
                  if (q) {
                    return onDrill(q);
                  }
                }
              : undefined,
          },
          data: data.map((d) => {
            return [getXValue(d), d[y.key]];
          }),
          dataLabels: {
            style: {
              fontSize: "0.8em",
            },
            filter: {
              operator: ">",
              property: "y",
              value: 0,
            },
            formatter: function () {
              let value = customFormatter(this.y, locale, y.formatter);
              if (chartOptions?.["stacked-area-stacking"] === "percent") {
                value = +this.percentage?.toFixed(1) + "%";
              }
              return value;
            },
          },
          color: y.color,
          fillOpacity: 1,
          tooltip: renderSerieTooltip({
            data: data,
            locale: locale,
            seriesLength: config.y.length,
            formatter: y.formatter,
            hideZeros: true,
            showTotal: chartOptions?.["stacked-area-stacking"] !== "percent",
          }),
        };
      }),
    };

    return (
      <div style={{ height, width: width ? width : "100%" }}>
        {this.props.chartOptions?.["hide-legend"] !== true &&
          this.state.legendItems.length > 1 && (
            <HighchartsLegend
              legendItems={this.state.legendItems}
              setVisibilityState={(index, s) => {
                this.chartRef?.chart?.series?.[index]?.setState?.(s);
              }}
              toggleVisibility={(index) => {
                this.setState((prevState) => ({
                  legendItems: prevState.legendItems?.map((legendItem, i) => {
                    if (index === i) {
                      return {
                        ...legendItem,
                        visible: !legendItem.visible,
                      };
                    } else {
                      return legendItem;
                    }
                  }),
                }));
                if (this.state.legendItems?.[index]?.visible) {
                  this.chartRef?.chart?.series?.[index]?.hide?.();
                } else {
                  this.chartRef?.chart?.series?.[index]?.show?.();
                }
              }}
            />
          )}
        <HighchartsReact
          ref={(ref) => {
            if (ref) {
              this.chartRef = ref;
              if (Array.isArray(ref?.chart?.legend?.allItems)) {
                const legendItems: HighchartLegendItem[] =
                  ref?.chart?.legend?.allItems.map((legendItem, index) => ({
                    key: index,
                    name: legendItem.name,
                    color: legendItem.color as string,
                    visible: legendItem.visible,
                  }));
                this.setState({ legendItems: legendItems });
              }
            }
          }}
          highcharts={Highcharts}
          options={options}
          callback={(chart) => {
            this.setState({ legendItems: chart.legend.allItems });
          }}
        />
      </div>
    );
  }
}
