import type {
  GenerateModelEvent,
  GenerateWidgetEvent,
} from "@projectstorm/react-canvas-core";
import { AbstractReactFactory } from "@projectstorm/react-canvas-core";
import type { DiagramEngine } from "@projectstorm/react-diagrams-core";
import { TeamMetricModel } from "./TeamMetricModel";
import TeamMetricWidget from "./TeamMetricWidget";

export class TeamMetricFactory extends AbstractReactFactory<
  TeamMetricModel,
  DiagramEngine
> {
  constructor() {
    super("team-metric");
  }

  generateReactWidget(
    event: GenerateWidgetEvent<TeamMetricModel>
  ): JSX.Element {
    return (
      <TeamMetricWidget engine={this.engine} size={50} node={event.model} />
    );
  }

  generateModel(event: GenerateModelEvent) {
    return new TeamMetricModel();
  }
}
