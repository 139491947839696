import _ from "lodash";
import React, { useMemo, useState } from "react";
import useResizeObserver from "use-resize-observer";

type Props<T> = T & {
  children: React.ReactElement;
};

type size = {
  width: number;
  height: number;
};

export const HeightSizer = <T,>({ children, ...otherProps }: Props<T>) => {
  const [size, setSize] = useState<size>({
    width: 0,
    height: 0,
  });

  const onResize = useMemo(() => _.debounce(setSize, 100), []);
  const { ref } = useResizeObserver({ onResize });

  const newChildren = React.cloneElement(children, {
    ...otherProps,
    height: size.height,
  });

  return (
    <div ref={ref} style={{ width: "100%", height: "100%" }}>
      {newChildren}
    </div>
  );
};
