import { action, makeObservable, observable, runInAction } from "mobx";
import type { IObject } from "../interfaces/object";

type ShareObjectModalOpenParams = IObject | undefined;
type OverrideSiderState = "OPEN" | "CLOSED" | "USER_DEFINED";

class WorkspaceUIStore {
  siderOpen: boolean;
  private overrideSiderOpen: OverrideSiderState = "USER_DEFINED";
  private userSiderOpen: boolean = true;
  shareObjectModalOpen: ShareObjectModalOpenParams;
  siderSize: number = 240;
  siderClosable: boolean = true;
  selectedFolderId: string | undefined;
  hideRightHeader: boolean = false;
  globalSearchOpen: boolean = false;

  constructor() {
    makeObservable(this, {
      shareObjectModalOpen: observable,
      globalSearchOpen: observable,
      siderOpen: observable,
      siderSize: observable,
      siderClosable: observable,
      selectedFolderId: observable,
      hideRightHeader: observable,
      setRightHeaderVisible: action,
      setSiderOpen: action,
      setSiderOverrideState: action,
      setSiderSize: action,
      setSiderClosable: action,
      setSelectedFolder: action,
      setGlobalSearchOpen: action,
      setShareObjectModalOpen: action,
    });

    this.siderOpen = this.getSiderState();

    if (document.body.getBoundingClientRect()?.width <= 992) {
      this.userSiderOpen = false;
    }
  }

  setRightHeaderVisible = (visible: boolean) => {
    this.hideRightHeader = !visible;
    setTimeout(() => {
      window.dispatchEvent(new Event("resize"));
    }, 50);
  };

  getSiderState = () => {
    if (this.overrideSiderOpen === "CLOSED") {
      return false;
    } else if (this.overrideSiderOpen === "OPEN") {
      return true;
    } else {
      return this.userSiderOpen;
    }
  };

  setSiderOpen = () => {
    this.userSiderOpen = !this.userSiderOpen;
    this.siderOpen = this.getSiderState();
  };

  setGlobalSearchOpen = (open: boolean) => {
    this.globalSearchOpen = open;
  };

  setSiderOverrideState = (value: OverrideSiderState) => {
    this.overrideSiderOpen = value;
    this.siderOpen = this.getSiderState();
  };

  setSiderSize = (siderSize: number) => {
    this.siderSize = siderSize;
  };

  setSiderClosable = (closable: boolean) => {
    this.siderClosable = closable;
    if (!closable && !this.siderOpen) {
      this.siderOpen = true;
    }
    this.siderOpen = this.getSiderState();
  };

  setSelectedFolder = (folderId?: string) => {
    this.selectedFolderId = folderId;
  };

  setShareObjectModalOpen = (params: ShareObjectModalOpenParams) => {
    runInAction(() => {
      this.shareObjectModalOpen = params;
    });
  };
}

export interface WorkspaceUIStoreProps {
  workspaceUIStore: WorkspaceUIStore;
}

export default new WorkspaceUIStore();
