import { EyeFilled } from "@ant-design/icons";
import { Button, Dropdown, Row, Space, Typography } from "antd";
import moment from "moment";
import * as React from "react";
import type { RouteComponentProps } from "react-router-dom";
import { Link, withRouter } from "react-router-dom";
import Logo from "../../../../../assets/logo/logo_square_dark.svg";
import type { InjectedAntUtilsProps } from "../../../../../components/ant-utils/withAntUtils";
import { withAntUtils } from "../../../../../components/ant-utils/withAntUtils";
import { compose } from "../../../../../components/compose/WlyCompose";
import { FromNow } from "../../../../../components/moment/FromNow";
import ReportDuplicate from "../../../../../components/report/ReportDuplicate";
import ReportRename from "../../../../../components/report/ReportRename";
import DescriptionEditor from "../../../../../components/studio/description/DescriptionEditor";
import UserAvatar from "../../../../../components/user/avatar/UserAvatar";
import type { IReport } from "../../../../../interfaces/reports";
import { routeDescriptor } from "../../../../../routes/routes";
import GraphQLService from "../../../../../services/graphql/GraphQLService";
import HelpDropdown from "../../../../help/HelpDropdown";
import type { InjectedOrgProps } from "../../../../orgs/WithOrg";
import WithOrg from "../../../../orgs/WithOrg";
import { UPDATE_QUERY } from "../../domain";
import ScheduleListDrawer from "../../schedule/ScheduleListDrawer";
import type { ScheduleOuterType } from "../../schedule/domain";
import ShareDrawer from "../../share/ShareDrawer";
import { renderEmbedModal } from "./domain";

interface IDashboardEditTopActionBarProps {
  hideLayout?: boolean;
  report: IReport;
  onCancelClick: () => void;
  onOpenConsole?: (c?: string) => void;
  setReport: (report: IReport) => void;
}

type Props = IDashboardEditTopActionBarProps &
  InjectedOrgProps &
  RouteComponentProps &
  InjectedAntUtilsProps;

function DashboardEditTopActionBar(props: Props) {
  const {
    antUtils,
    hideLayout,
    history,
    report,
    org,
    onCancelClick,
    onOpenConsole,
    setReport,
    match: { params },
  } = props;

  const [scheduleListDrawerOpened, setScheduleListDrawerOpened] =
    React.useState<{ opened: boolean; type: ScheduleOuterType }>({
      opened: false,
      type: "email",
    });
  const [shareDrawerOpened, setShareDrawerOpened] =
    React.useState<boolean>(false);
  const [renameReportOpened, setRenameReportOpened] =
    React.useState<boolean>(false);
  const [duplicateReportOpened, setDuplicateReportOpened] =
    React.useState<boolean>(false);
  const [editDescriptionOpened, setEditDescriptionOpened] =
    React.useState<boolean>(false);

  const deleteReport = async () => {
    try {
      return antUtils.modal.confirm({
        title: `Delete this report?`,
        content: `You are about to delete this report. This cannot be undone. Do you wish to proceed?`,
        okText: "Delete",
        okButtonProps: {
          danger: true,
        },
        cancelText: "Cancel",
        onOk: async () => {
          await GraphQLService(UPDATE_QUERY, {
            id: report.id,
            data: {
              deleted: true,
            },
          });
          antUtils.message.success("Report deleted");
          history.push(
            routeDescriptor["home"].renderRoute({
              organizationSlug: org.slug,
            })
          );
        },
      });
    } catch (error) {
      antUtils.message.error("Error while deleting report");
      return console.error(error);
    }
  };

  const showEmbedModal = () => antUtils.modal.info(renderEmbedModal(report));

  return (
    <>
      {hideLayout && <div id="help-button" />}
      {!hideLayout && (
        <>
          {/* Edit top bar */}
          <div
            style={{ pointerEvents: "auto" }}
            className={"report-edit-top-action-bar"}
          >
            <div className="left">
              <div className="logo">
                <Link
                  to={routeDescriptor["report"].renderRoute({
                    ...params,
                  })}
                >
                  <img
                    src={org.logo ? org.logo : Logo}
                    alt={"Whaly"}
                    style={{ borderRadius: 6 }}
                  />
                </Link>
              </div>
              <div className="menu">
                <Row>
                  <Typography.Text className="title">
                    Dashboard studio
                  </Typography.Text>
                </Row>
                <Row>
                  <Space size={"small"}>
                    <span>
                      {/* File */}
                      <Dropdown
                        placement="bottomLeft"
                        trigger={["click"]}
                        menu={{
                          items: [
                            {
                              key: "rename",
                              label: "Rename",
                              onClick: () => setRenameReportOpened(true),
                            },
                            {
                              key: "description",
                              label: "Edit description",
                              onClick: () => setEditDescriptionOpened(true),
                            },
                            {
                              type: "divider",
                            },
                            {
                              key: "duplicate",
                              label: "Duplicate",
                              onClick: () => setDuplicateReportOpened(true),
                            },
                            {
                              key: "delete",
                              label: "Delete",
                              danger: true,
                              onClick: () => deleteReport(),
                            },
                            {
                              type: "divider",
                            },
                            {
                              key: "sharinglink",
                              label: "Sharing links",
                              onClick: () => setShareDrawerOpened(true),
                            },
                            {
                              key: "push_email",
                              label: "Push to email",
                              onClick: () =>
                                setScheduleListDrawerOpened({
                                  opened: true,
                                  type: "email",
                                }),
                            },
                            {
                              key: "push_third_party",
                              label: "Push to 3rd party",
                              onClick: () =>
                                setScheduleListDrawerOpened({
                                  opened: true,
                                  type: "third_party",
                                }),
                            },
                            {
                              type: "divider",
                            },
                            {
                              key: "editedby",
                              disabled: true,
                              label: (
                                <>
                                  <UserAvatar
                                    size="small"
                                    user={
                                      report.updatedBy
                                        ? report.updatedBy
                                        : report.createdBy
                                    }
                                  />
                                  <Typography.Text style={{ fontSize: 12 }}>
                                    {" edited "}
                                    <FromNow
                                      date={moment(
                                        report.updatedAt
                                          ? report.updatedAt
                                          : report.createdAt
                                      )}
                                    />
                                  </Typography.Text>
                                </>
                              ),
                            },
                          ],
                        }}
                      >
                        <Button type="text" size="small">
                          File
                        </Button>
                      </Dropdown>
                      <Dropdown
                        placement="bottomLeft"
                        trigger={["click"]}
                        menu={{
                          items: [
                            {
                              key: "embed",
                              label: "Embed",
                              onClick: () => showEmbedModal(),
                            },
                            {
                              type: "divider",
                            },
                            {
                              key: 2,
                              label: "Console",
                              onClick: () => onOpenConsole?.(),
                            },
                          ],
                        }}
                      >
                        <Button type="text" size="small">
                          Developer
                        </Button>
                      </Dropdown>
                      <HelpDropdown trigger={["click"]} placement="bottomLeft">
                        <Button type="text" size="small">
                          Help
                        </Button>
                      </HelpDropdown>
                    </span>
                  </Space>
                </Row>
              </div>
            </div>
            <div className="right">
              <Button type="default" onClick={onCancelClick}>
                View{" "}
                <EyeFilled
                  style={{
                    fontSize: 16,
                  }}
                />
              </Button>
            </div>
          </div>
        </>
      )}

      <ShareDrawer
        reportId={props.report.id}
        visible={shareDrawerOpened}
        setVisible={setShareDrawerOpened}
      />

      <ScheduleListDrawer
        report={props.report}
        type={scheduleListDrawerOpened.type}
        visible={scheduleListDrawerOpened.opened}
        onClose={() =>
          setScheduleListDrawerOpened({
            opened: false,
            type: scheduleListDrawerOpened.type,
          })
        }
      />

      <ReportRename
        visible={renameReportOpened}
        initialData={{
          id: report.id,
          name: report.name,
        }}
        onCancel={() => setRenameReportOpened(false)}
        onSave={(r) => {
          setReport({ ...report, name: r.name });
          setRenameReportOpened(false);
        }}
      />

      <ReportDuplicate
        visible={duplicateReportOpened}
        initialData={{
          id: report.id,
          name: `${report.name} (copy)`,
        }}
        onCancel={() => setDuplicateReportOpened(false)}
        onSave={(slug) => {
          history.push(
            routeDescriptor["report"].renderRoute({
              organizationSlug: org.slug,
              reportSlug: slug,
            })
          );
          setDuplicateReportOpened(false);
        }}
      />

      <DescriptionEditor
        initialData={
          report.description ? { description: report.description } : undefined
        }
        visible={editDescriptionOpened}
        onSave={async (data) => {
          try {
            const resp = await GraphQLService<{ updateReport: IReport }>(
              UPDATE_QUERY,
              {
                id: report.id,
                data,
              }
            );
            setReport({
              ...report,
              description: resp.updateReport.description,
            });
            antUtils.message.success("Description updated");
            setEditDescriptionOpened(false);
          } catch (error) {
            antUtils.message.error("Error updating description");
            setEditDescriptionOpened(false);
          }
        }}
        onCancel={() => setEditDescriptionOpened(false)}
      />
    </>
  );
}

export default compose<Props, IDashboardEditTopActionBarProps>(
  WithOrg,
  withRouter,
  withAntUtils
)(DashboardEditTopActionBar);
