import type {
  Completion,
  CompletionContext,
  CompletionSource,
} from "@codemirror/autocomplete";
import { autocompletion } from "@codemirror/autocomplete";
import type { EditorView } from "@codemirror/view";
import type { FunctionDefinition } from "../../../parser/functions";

const formatColumnsSuggestions = (suggestions: string[]): Completion[] => {
  const options: Completion[] = suggestions.map((v) => ({
    label: v,
    apply: v,
    detail: "column",
    type: "column",
  }));
  return options;
};

const formatFormulasSuggestions = (
  suggestions: FunctionDefinition
): Completion[] => {
  const suggestionsMap = Object.keys(suggestions).length
    ? Object.keys(suggestions)
    : [];

  const options: Completion[] = suggestionsMap.map((key) => {
    const formula = suggestions[key];

    const rest = {
      detail: "formula",
      info: `${formula.description}`,
      type: "formula",
    };

    if (formula.args && formula.args.length > 1) {
      // we only add the opening parenthesis if the formula has some args
      // we also move the cursor between the parenthesis
      const apply = key + "()";
      const completion: Completion = {
        label: key,
        apply: (
          view: EditorView,
          completion: Completion,
          from: number,
          to: number
        ) => {
          view.dispatch({
            changes: {
              from: from,
              to: to,
              insert: apply,
            },
            selection: { anchor: from + apply.length - 1 },
          });
        },
        ...rest,
      };
      return completion;
    } else {
      return {
        label: key,
        apply: key,
        ...rest,
      };
    }
  });
  return options;
};

export function FormulaEditorAutocomplete(
  availableColumns: string[],
  availableFormulas: FunctionDefinition
) {
  const columnSuggestions: CompletionSource = (context: CompletionContext) => {
    let word = context.matchBefore(/\w*/);
    if (word.from === word.to && !context.explicit) return null;

    return {
      from: word.from,
      options: formatColumnsSuggestions(availableColumns),
    };
  };

  const formulaSuggestions: CompletionSource = (context: CompletionContext) => {
    let word = context.matchBefore(/\w*/);
    if (word.from === word.to && !context.explicit) return null;

    return {
      from: word.from,
      options: formatFormulasSuggestions(availableFormulas),
    };
  };

  return autocompletion({
    override: [columnSuggestions, formulaSuggestions],
  });
}
