import { FormOutlined, InfoCircleFilled } from "@ant-design/icons";
import type { FormInstance } from "antd";
import {
  Alert,
  Button,
  Form,
  Input,
  Radio,
  Select,
  Space,
  Tag,
  Tooltip,
  Typography,
} from "antd";
import cuid from "cuid";
import _ from "lodash";
import { inject } from "mobx-react";
import React from "react";
import { WlyCard } from "../../../../../../components/cards/WlyCard";
import { WlyCardDangerZone } from "../../../../../../components/cards/WlyCardDangerZone";
import { compose } from "../../../../../../components/compose/WlyCompose";
import { SourceImageRenderer } from "../../../../../../components/sources/SourceImageRenderer";
import { IOrgFeatureType } from "../../../../../../interfaces/org";
import type {
  IDataset,
  IDatasetRelationship,
} from "../../../../../../interfaces/sources";
import type { WorkbenchUIStoreProps } from "../../../../../../store/workbenchUIStore";
import type { InjectedOrgProps } from "../../../../../orgs/WithOrg";
import WithOrg from "../../../../../orgs/WithOrg";
import {
  RelationshipLineRenderer,
  reverseRelationshipType,
} from "../../../dataset/tabs/general/relationship/RelationshipLineRenderer";
import type { TabData } from "../../../domain";
import type {
  DisabledTableConfigurationCapabilities,
  IMeasureTable,
  ITableFormInput,
  MeasureValidationFunction,
} from "../../domain";
import { DrillsEditor } from "../metric-editor/common/DrillsEditor";

interface ITableEditorProps {
  initialData: ITableFormInput & {
    id: string;
  };
  tables: IMeasureTable[];
  onSave: (data: ITableFormInput) => Promise<void>;
  form: FormInstance<any>;
  onDelete?: () => Promise<void>;
  allDatasets: IDataset[];
  allIncomingDatasetRelationships: IDatasetRelationship[];
  dataStore: {
    [tableKey: string]: TabData;
  };
  disabledCapabilities: Array<DisabledTableConfigurationCapabilities>;
}

const { Text, Title } = Typography;

type Props = ITableEditorProps & InjectedOrgProps & WorkbenchUIStoreProps;

const reverseRelationship = (rel: IDatasetRelationship) => ({
  ...rel,
  left: rel.right,
  right: rel.left,
  from: rel.to,
  to: rel.from,
  type: reverseRelationshipType(rel.type),
});

export const validateTableFormInput: MeasureValidationFunction<
  ITableFormInput
> = (data, schema, allDatasets, allDatasetRelationships, allTables) => {
  const errors: string[] = [];
  const currentDataset = allDatasets.find((d) => d.id === data.datasetId);
  if (!currentDataset) {
    errors.push(`Table based on missing dataset`);
    return errors;
  }

  if (schema.status === "initial" || schema.status === "loading") {
    return errors;
  }
  if (schema.status === "error") {
    return errors;
  }

  if (
    !currentDataset.isModel &&
    !currentDataset.views.find((v) => v.id === data.viewId)
  ) {
    errors.push(`Table based on missing view`);
    return errors;
  }
  const allRelationships = [
    ...allDatasetRelationships,
    ...allDatasetRelationships.map(reverseRelationship),
  ];

  if (data.incomingRelationship) {
    const parentTable = allTables.find(
      (tt) => tt.id === data.incomingRelationship.parentId
    );

    const parentDataset = allDatasets.find(
      (d) => d.id === parentTable?.datasetId
    );

    if (
      parentTable &&
      parentDataset &&
      !allRelationships.find(
        (r) =>
          r.from === data.incomingRelationship.from &&
          r.to === data.incomingRelationship.to &&
          r.left.id === parentDataset.id &&
          r.type === data.incomingRelationship.type &&
          r.right.id === data.datasetId
      )
    ) {
      errors.push(`No relationships found with parent`);
      return errors;
    }
  }
  return errors;
};

function TableEditor(props: Props) {
  const {
    initialData,
    onSave,
    form,
    onDelete,
    tables,
    allIncomingDatasetRelationships,
    allDatasets,
    dataStore,
    disabledCapabilities,
  } = props;

  const debouncedOnSave = _.debounce(onSave, 200);

  const attachementId = React.useRef(cuid());

  const parentDataset = tables.find(
    (t) => t.id === initialData.incomingRelationship?.parentId
  );

  const parentDatasetId = parentDataset?.datasetId;

  const isExplorationHead = !parentDatasetId;

  const currentDataset = allDatasets.find(
    (d) => d.id === initialData.datasetId
  );

  // if (!currentDataset) {
  //   return <div>Error: no dataset found</div>;
  // }
  const currentView =
    currentDataset && currentDataset.views && currentDataset.views.length
      ? currentDataset.views.find((v) => v.id === initialData.viewId)
      : null;

  // if (!currentView) {
  //   return <div>Error: no view found</div>;
  // }

  let initialRelationship: string | null = null;

  // we should duplicate all relationship in all directions to make sure we have the proper coverage
  const allRelationships = [
    ...allIncomingDatasetRelationships,
    ...allIncomingDatasetRelationships.map(reverseRelationship),
  ];

  if (initialData.incomingRelationship) {
    let tableRelationship = initialData.incomingRelationship;
    initialRelationship = allRelationships.find(
      (r) =>
        r.type === tableRelationship.type &&
        r.from === tableRelationship.from &&
        r.to === tableRelationship.to &&
        r.left.id === parentDatasetId
    )?.id;
  }

  const availableDatasets = [...allDatasets]
    .sort((a, b) => {
      if (a.isModel !== b.isModel) {
        return a.isModel ? 1 : -1;
      } else if (a.source?.name !== b.source?.name) {
        return a.source?.name > b.source?.name ? 1 : -1;
      } else {
        return a.name > b.name ? 1 : -1;
      }
    })
    .filter((d) => {
      if (isExplorationHead) {
        // we are showing all datasets for the head of exploration
        return true;
      }
      if (
        d.incomingRelationships?.some((r) => r.left.id === parentDatasetId) ||
        d.outgoingRelationships?.some((r) => r.right.id === parentDatasetId)
      ) {
        // if a dataset has a relationship with parent then we show them
        return true;
      }
      return false;
    });

  const formattedAvailableDatasets = [...availableDatasets];

  if (
    !availableDatasets.map((ad) => ad.id).includes(currentDataset?.id) &&
    currentDataset
  ) {
    formattedAvailableDatasets.push(currentDataset);
  }

  const shouldHideConfigurationCard =
    disabledCapabilities.includes("table::blend") &&
    disabledCapabilities.includes("table::model") &&
    disabledCapabilities.includes("table::name");

  return (
    <Form
      name="tableInfoUpdateForm"
      initialValues={{
        name: initialData.name,
        datasetId: initialData.datasetId,
        primaryKey: initialData.primaryKey,
        viewId: initialData.viewId,
        blendedDatasetIds: initialData.blendedDatasetIds,
        relationship: initialRelationship,
        drills: initialData.drills,
      }}
      layout="vertical"
      form={form}
      onValuesChange={(change) => {
        const dataset = allDatasets.find(
          (d) => d.id === form.getFieldValue("datasetId")
        );

        form.setFieldsValue({ primaryKey: dataset.primaryKey });
        if (change && change.datasetId) {
          const views = dataset.views;
          if (Array.isArray(views) && views.length) {
            form.setFieldsValue({
              viewId: views[0].id,
              name: dataset.name,
            });
          }

          // we changed the datasetId so we are changing the relationship
          const relationship = allIncomingDatasetRelationships.find(
            (relationship) => {
              return (
                (relationship.left.id === parentDatasetId &&
                  relationship.right.id === dataset.id) ||
                (relationship.left.id === dataset.id &&
                  relationship.right.id === parentDatasetId)
              );
            }
          );

          // if we don't find a relationship
          // we are not changing the relationship by default as we want to show the tree as it was

          if (relationship) {
            form.setFieldsValue({ relationship: relationship?.id });
          }
        }

        const foundRelationship = allIncomingDatasetRelationships.find(
          (r) => r.id === form.getFieldValue("relationship")
        );
        // we should reverse the relationship if needed

        const shouldReverse = foundRelationship
          ? foundRelationship.left.id === dataset.id
          : false;

        const values = form.getFieldsValue();
        const datasetViews = dataset?.views || [];

        const toUpdateData = {
          ...values,
          primaryKey: dataset.primaryKey,
          viewCubeName: datasetViews.find((v) => v.id === values.viewId)
            ?.cubeName,
          incomingRelationship: foundRelationship
            ? {
                type: shouldReverse
                  ? reverseRelationshipType(foundRelationship.type)
                  : foundRelationship.type,
                from: shouldReverse
                  ? foundRelationship.to
                  : foundRelationship.from,
                to: shouldReverse
                  ? foundRelationship.from
                  : foundRelationship.to,
                id: initialData.incomingRelationship.id,
                parentId: initialData.incomingRelationship?.parentId,
              }
            : undefined,
        };
        debouncedOnSave(toUpdateData);
      }}
    >
      <Space direction="vertical" size={24} style={{ width: "100%" }}>
        <div style={{ paddingTop: 12 }}></div>
        <WlyCard
          style={{
            display: shouldHideConfigurationCard ? "none" : undefined,
            marginBottom: shouldHideConfigurationCard ? -24 : undefined,
          }}
          title={
            <Title style={{ marginBottom: 0 }} level={5}>
              Configuration
            </Title>
          }
        >
          {/* Name of the exploration table */}
          <Form.Item
            name={["name"]}
            label="Name"
            hidden={disabledCapabilities.includes("table::name")}
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input placeholder={`${currentDataset?.name}`} />
          </Form.Item>

          {/* Dataset for the table, should update view on change */}
          <Form.Item noStyle shouldUpdate={true}>
            {() => (
              <Form.Item
                name={["datasetId"]}
                label="Based on"
                hidden={disabledCapabilities.includes("table::model")}
                help={
                  <div style={{ width: "100%", textAlign: "right" }}>
                    <Button
                      type="link"
                      icon={<FormOutlined />}
                      disabled={!form.getFieldValue("datasetId")}
                      style={{ padding: 0 }}
                      onClick={() => {
                        props.workbenchUIStore.pushActiveObject({
                          type: "dataset",
                          value: form.getFieldValue("datasetId"),
                        });
                      }}
                    >
                      Open
                    </Button>
                  </div>
                }
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select
                  style={{ width: "100%" }}
                  showSearch
                  optionFilterProp="label"
                >
                  {formattedAvailableDatasets.map((v) => {
                    if (
                      !availableDatasets.map((ad) => ad?.id).includes(v?.id)
                    ) {
                      // in this case it means that we don't have the current dataset as direct child of the parent table
                      // therefore we show it with a warning sign, disabled and an explaination

                      return (
                        <Select.Option disabled={true} key={v.id} value={v.id}>
                          <Space>
                            <SourceImageRenderer
                              alt={"sourceName"}
                              className="source-table-selection-logo"
                              img={":warning:"}
                              size={16}
                            />
                            {v.name}
                            <Text type="secondary">
                              No relationship with "{parentDataset?.name}"
                            </Text>
                          </Space>
                        </Select.Option>
                      );
                    }
                    return (
                      <Select.Option key={v.id} value={v.id} label={v.name}>
                        <Space>
                          <SourceImageRenderer
                            alt={"sourceName"}
                            className="source-table-selection-logo"
                            img={
                              !v.isModel
                                ? v.source.sourceMeta.publicInfo.logo
                                : undefined
                            }
                            size={16}
                            isModel={v.isModel}
                          />
                          {v.name}
                          <Text type="secondary">
                            {v.isModel ? "Model" : v.source?.name}
                          </Text>
                        </Space>
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
            )}
          </Form.Item>

          {/* View for the table, should update when dataset change, should be hidden for models and new workbench orgs */}
          <Form.Item
            noStyle
            shouldUpdate={true}
            hidden={
              !props.orgFeatures.includes(IOrgFeatureType.OLD_WORKBENCH) ||
              disabledCapabilities.includes("table::model")
            }
          >
            {() => {
              const dataset = allDatasets.find(
                (d) => d.id === form.getFieldValue("datasetId")
              );
              const views = dataset?.views ? dataset.views : [];
              return (
                <Form.Item
                  name={["viewId"]}
                  label="Using view"
                  hidden={dataset?.isModel}
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Select>
                    {views.map((v) => (
                      <Select.Option value={v.id} key={v.id}>
                        {v.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              );
            }}
          </Form.Item>

          {/* Dataset for the table, should update view on change */}
          <Form.Item noStyle shouldUpdate={true}>
            {() => (
              <Form.Item
                name={["blendedDatasetIds"]}
                hidden={disabledCapabilities.includes("table::blend")}
                label={
                  <>
                    <>{"Blend with"}</>
                    <Tooltip
                      title="When blending with another Dataset, you can reference the columns of the other dataset in Dimensions and Metrics. Columns that share the same name between the two Dataset can be used in common dimensions or metrics. Equivalent with a SQL UNION command."
                      placement="right"
                    >
                      <InfoCircleFilled
                        style={{ color: "#B3B5B6", marginLeft: 8 }}
                      />
                    </Tooltip>
                  </>
                }
                rules={[
                  {
                    required: false,
                  },
                ]}
              >
                <Select
                  mode="multiple"
                  allowClear={true}
                  style={{ width: "100%" }}
                  optionFilterProp="label"
                >
                  {formattedAvailableDatasets.map((v) => {
                    return (
                      <Select.Option key={v.id} value={v.id} label={v.name}>
                        <Space>
                          <SourceImageRenderer
                            alt={"sourceName"}
                            className="source-table-selection-logo"
                            img={
                              !v.isModel
                                ? v.source.sourceMeta.publicInfo.logo
                                : undefined
                            }
                            size={16}
                            isModel={v.isModel}
                          />
                          {v.name}
                          <Text type="secondary">
                            {v.isModel ? "Model" : v.source?.name}
                          </Text>
                        </Space>
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
            )}
          </Form.Item>

          {/* relationship to use */}
          {!isExplorationHead && (
            <Form.Item noStyle shouldUpdate>
              {() => {
                const relationships = allIncomingDatasetRelationships.filter(
                  (relationship) => {
                    return (
                      (relationship.left.id === parentDatasetId &&
                        relationship.right.id ===
                          form.getFieldValue("datasetId")) ||
                      (relationship.left.id ===
                        form.getFieldValue("datasetId") &&
                        relationship.right.id === parentDatasetId)
                    );
                  }
                );

                if (!relationships.length) {
                  return (
                    <Form.Item
                      label="With relationship to parent"
                      style={{
                        width: "100%",
                      }}
                    >
                      <Alert
                        message="No relationships exists betweeen the two datasets. You should probably create one before updating this."
                        type="warning"
                      />
                    </Form.Item>
                  );
                }

                return (
                  <Form.Item
                    name={"relationship"}
                    label="With relationship to parent"
                    style={{
                      width: "100%",
                    }}
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Radio.Group
                      style={{
                        width: "100%",
                      }}
                    >
                      <Space direction="vertical" style={{ width: "100%" }}>
                        {relationships.map((relationship) => {
                          const fromDataset = allDatasets.find(
                            (d) => d.id === relationship.left.id
                          );
                          const toDataset = allDatasets.find(
                            (d) => d.id === relationship.right.id
                          );
                          const from = {
                            columnName: relationship.from,
                            isModel: fromDataset?.isModel,
                            datasetImg:
                              fromDataset?.source?.sourceMeta.publicInfo.logo,

                            datasetName: fromDataset?.name,
                          };

                          const to = {
                            columnName: relationship.to,
                            isModel: toDataset?.isModel,
                            datasetImg:
                              toDataset?.source?.sourceMeta.publicInfo.logo,

                            datasetName: toDataset?.name,
                          };

                          const reverse =
                            relationship.left.id ===
                            form.getFieldValue("datasetId");
                          return (
                            <Radio.Button
                              key={relationship.id}
                              value={relationship.id}
                              style={{
                                width: "100%",
                                height: 62,
                                lineHeight: "initial",
                                padding: 12,
                              }}
                            >
                              <div
                                style={{
                                  width: "100%",
                                  display: "inline-flex",
                                  alignItems: "center",
                                }}
                              >
                                <div style={{ width: "85%" }}>
                                  <RelationshipLineRenderer
                                    from={from}
                                    to={to}
                                    type={relationship.type}
                                    reverse={reverse}
                                  />
                                </div>
                                <div style={{ width: "15%" }}>
                                  {form.getFieldValue("relationship") ===
                                    relationship.id && (
                                    <Tag color="blue">Selected</Tag>
                                  )}
                                </div>
                              </div>
                            </Radio.Button>
                          );
                        })}
                      </Space>
                    </Radio.Group>
                  </Form.Item>
                );
              }}
            </Form.Item>
          )}
        </WlyCard>
        <WlyCard
          id={attachementId.current}
          title={
            <Typography.Title style={{ marginBottom: 0 }} level={5}>
              Drill downs
            </Typography.Title>
          }
        >
          <Form.Item noStyle shouldUpdate={true}>
            {() => {
              const datasetId = form.getFieldValue("datasetId");
              const viewId = form.getFieldValue("viewId");
              if (!datasetId || !viewId) {
                return <div>Please select a view or a dataset</div>;
              }

              if (!dataStore[datasetId]) {
                return <div>the selected dataset seems deleted</div>;
              }

              if (!dataStore[datasetId].data[viewId]) {
                return <div>the selected view seems deleted</div>;
              }

              return (
                <DrillsEditor
                  id={attachementId.current}
                  tables={tables}
                  form={form}
                  view={dataStore[datasetId].data[viewId]}
                />
              );
            }}
          </Form.Item>
        </WlyCard>
        {onDelete && !isExplorationHead && (
          <WlyCardDangerZone
            title="Danger Zone"
            button={
              <Button
                type="primary"
                danger={true}
                onClick={onDelete ? onDelete : undefined}
              >
                Delete
              </Button>
            }
          >
            Click here to permanently delete this table. This cannot be undone.
          </WlyCardDangerZone>
        )}
        <div style={{ paddingTop: 24 }}></div>
      </Space>
    </Form>
  );
}

export default compose<Props, ITableEditorProps>(
  WithOrg,
  inject("workbenchUIStore")
)(TableEditor);
