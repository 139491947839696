import {
  ArrowRightOutlined,
  CloseOutlined,
  WarningFilled,
} from "@ant-design/icons";
import type { DiagramEngine } from "@projectstorm/react-diagrams";
import { PortModelAlignment, PortWidget } from "@projectstorm/react-diagrams";
import * as React from "react";
import type { DataNodeModel } from "./DataNodeModel";

import { inject, observer } from "mobx-react";
import type { RouteComponentProps } from "react-router";
import { withRouter } from "react-router";
import { compose } from "../../../../../../../../components/compose/WlyCompose";
import { SourceImageRenderer } from "../../../../../../../../components/sources/SourceImageRenderer";
import type { WorkbenchUIStoreProps } from "../../../../../../../../store/workbenchUIStore";
import "./DataNodeWidget.scss";

interface DataNodeWidgetProps {
  node: DataNodeModel;
  engine: DiagramEngine;
  size?: number;
}

export const HEIGHT = 52;
export const WIDTH = 188; // GRID IS 68 px so this should be a multiple of 68 - PORT SIZE
const PORT_SIZE = 16;

type Props = DataNodeWidgetProps &
  RouteComponentProps<{}> &
  WorkbenchUIStoreProps;

class DataNodeWidget extends React.Component<Props> {
  render() {
    const { node, workbenchUIStore } = this.props;
    const hovering =
      node.transformation.var === workbenchUIStore.showcasedNodeId;
    return (
      <div
        className={`data-node-widget-wrapper ${
          node.isSelected() ? "selected" : ""
        } ${hovering ? "showcasing" : ""}`}
        style={{ height: HEIGHT + PORT_SIZE, width: WIDTH + PORT_SIZE }}
      >
        <PortWidget
          style={{
            top: HEIGHT / 2,
            right: 0,
            position: "absolute",
          }}
          port={this.props.node.getPort(PortModelAlignment.RIGHT)}
          engine={this.props.engine}
        >
          <div className="data-port-widget" />
        </PortWidget>
        {node.isSelected() && node.canEdit() && (
          <div
            onClick={() => {
              node.remove();
            }}
            className="data-node-widget-remove"
          >
            <CloseOutlined />
          </div>
        )}
        {node.hasWarning() && (
          <div className="data-node-widget-warning">
            <WarningFilled />
          </div>
        )}
        {node.isOutput() && (
          <div className="data-node-widget-output">
            <ArrowRightOutlined />
          </div>
        )}
        <div
          className="data-node-widget-core"
          style={{ height: HEIGHT, width: WIDTH }}
        >
          <div className="data-node-widget-core-inner">
            <div className="data-node-widget-core-logo">
              <SourceImageRenderer
                size={24}
                isModel={node.dataset?.isModel}
                alt={node.dataset?.name}
                img={node.dataset?.source?.sourceMeta?.publicInfo?.logo}
              />
            </div>
            <div className="data-node-widget-core-content">
              <div className="data-node-widget-core-content-title">
                {node.dataset?.name}
              </div>
              <div className="data-node-widget-core-content-subtitle">
                {node.dataset?.source?.name}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default compose<Props, DataNodeWidgetProps>(withRouter)(
  inject("workbenchUIStore")(observer(DataNodeWidget))
);
