import { Layout } from "antd";
import * as React from "react";

interface INoLayoutProps {
  contentComponent: React.ComponentClass;
  additionalProps?: { [key: string]: any };
}

class NoLayout extends React.Component<INoLayoutProps> {
  public render() {
    const { contentComponent, additionalProps } = this.props;
    const ContentComponent = contentComponent;

    return (
      <Layout style={{ minHeight: "100%" }}>
        <Layout className="wly-layout background">
          <ContentComponent {...additionalProps} />
        </Layout>
      </Layout>
    );
  }
}

export default NoLayout;
