import Icon from "@ant-design/icons";
import type { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";

export const WlyCaretDownIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon
    component={() => (
      <svg
        width="1em"
        height="1em"
        viewBox="0 0 213 213"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M76.4706 76.8209L106.491 46.6695L136.511 76.8209C137.73 78.0496 139.18 79.0248 140.777 79.6904C142.375 80.3559 144.088 80.6986 145.819 80.6986C147.549 80.6986 149.263 80.3559 150.86 79.6904C152.458 79.0248 153.908 78.0496 155.126 76.8209C156.355 75.6022 157.33 74.1523 157.996 72.5548C158.661 70.9573 159.004 69.2438 159.004 67.5133C159.004 65.7827 158.661 64.0692 157.996 62.4717C157.33 60.8742 156.355 59.4243 155.126 58.2057L115.799 18.8777C114.58 17.649 113.13 16.6737 111.532 16.0082C109.935 15.3427 108.221 15 106.491 15C104.76 15 103.047 15.3427 101.449 16.0082C99.8519 16.6737 98.402 17.649 97.1833 18.8777L57.8553 58.2057C55.3868 60.6742 54 64.0222 54 67.5133C54 71.0043 55.3868 74.3523 57.8553 76.8209C60.3239 79.2894 63.6719 80.6762 67.163 80.6762C70.654 80.6762 74.002 79.2894 76.4706 76.8209ZM136.511 136.862L106.491 167.013L76.4706 136.862C75.2483 135.639 73.7972 134.67 72.2002 134.008C70.6032 133.347 68.8915 133.006 67.163 133.006C65.4344 133.006 63.7227 133.347 62.1257 134.008C60.5287 134.67 59.0776 135.639 57.8553 136.862C56.633 138.084 55.6635 139.535 55.002 141.132C54.3405 142.729 54 144.441 54 146.169C54 147.898 54.3405 149.609 55.002 151.206C55.6635 152.803 56.633 154.254 57.8553 155.477L97.1833 194.805C98.402 196.033 99.8519 197.009 101.449 197.674C103.047 198.34 104.76 198.682 106.491 198.682C108.221 198.682 109.935 198.34 111.532 197.674C113.13 197.009 114.58 196.033 115.799 194.805L155.126 155.477C157.595 153.008 158.982 149.66 158.982 146.169C158.982 142.678 157.595 139.33 155.126 136.862C152.658 134.393 149.31 133.006 145.819 133.006C142.328 133.006 138.98 134.393 136.511 136.862Z"
          fill="currentColor"
        />
      </svg>
    )}
    {...props}
  />
);
