export type JobSyncStatus = "idle" | "syncing" | "scheduled";
export type JobSyncPeriod =
  | "five_minutes"
  | "fifteen_minutes"
  | "thirty_minutes"
  | "one_hour"
  | "two_hours"
  | "three_hours"
  | "six_hours"
  | "eight_hours"
  | "twelve_hours"
  | "twenty_four_hours";

export enum JobType {
  "DEFAULT" = "DEFAULT",
  "DBT_CLOUD_SYNC" = "DBT_CLOUD_SYNC",
  "PERSISTENCE_ENGINE_RUN" = "PERSISTENCE_ENGINE_RUN",
  "EXPLORATION_DATA_EXTRACT" = "EXPLORATION_DATA_EXTRACT",
}

export type JobExecutionRunStatus =
  | "RUNNING"
  | "SUCCESSED"
  | "FAILED"
  | "ERROR";
export interface IJobExecution {
  id: string;
  identifier: string;
  objectId: Number;
  objectType: "Source" | "Schedule" | "Destination";
  jobType: JobType;
  status: JobExecutionRunStatus;
  artifactsUri?: string;
  createdAt: string;
  updatedAt: string;
  errorText?: string;
  errorType?: string;
  errorDebugText?: string;
}

export interface IRunResult {
  status: "success" | "error" | "warning";
  message?: string; // message helps understanding when there is an error or warning
  operationName: string; // used as the technical name of the operation
  type: "test" | "materialization" | "freshness" | "configuration";
  columnName?: string; // when a results is tied to a column flag it here
  objectId?: string;
  objectType?: "Dataset";
  generatedAt?: string;
}
