import { FileTextOutlined } from "@ant-design/icons";
import { Button, Table, Typography } from "antd";
import type { ColumnProps } from "antd/lib/table";
import moment from "moment";
import * as React from "react";
import Aligner from "../../../../components/layout/aligner/Aligner";
import Loading from "../../../../components/layout/feedback/loading";
import type { IJobExecution } from "../../../../interfaces/jobExecutions";
import GraphQLService from "../../../../services/graphql/GraphQLService";
import ExecutionLogsDrawer from "../../../job-execution/ExecutionLogsDrawer";
import { ExecutionStatusTag } from "../../../job-execution/status/ExecutionStatusTag";

interface IServingLayerQueryExecutionProps {
  objectType: string;
  objectId: string;
}

interface IServingLayerQueryExecutionState {
  loading: boolean;
  executions: IJobExecution[];
  selectedJobExecution?: IJobExecution;
}

const GQL = `
query getServingLayerQueryExecution($objectType: JobExecutionObjectTypeType, $objectId: String) {
  allJobExecutions(where:
    {AND: [
      {objectId: $objectId},
      {objectType: $objectType}
    ]},
    sortBy: [createdAt_DESC],
    first: 24
    ) {
    id
    identifier
    objectType
    objectId
    status
    errorText
    errorDebugText
    errorType
    createdAt
    updatedAt
  }
}
`;

export default class ServingLayerQueryExecution extends React.Component<
  IServingLayerQueryExecutionProps,
  IServingLayerQueryExecutionState
> {
  interval: NodeJS.Timeout;

  constructor(props: IServingLayerQueryExecutionProps) {
    super(props);
    this.state = {
      loading: true,
      executions: [],
    };
  }

  componentDidMount() {
    this.interval = setInterval(this.getJobExecutions, 1000);
  }

  componentWillUnmount() {
    window.clearTimeout(this.interval);
  }

  public generateColumns = (): ColumnProps<IJobExecution>[] => {
    return [
      {
        title: "Status",
        key: "status",
        render: (v, s) => {
          return (
            <div>
              <ExecutionStatusTag execution={s} />
            </div>
          );
        },
      },
      {
        title: "Start Date",
        key: "status",
        render: (v, s) => {
          return <div>{moment(s.createdAt).fromNow()}</div>;
        },
      },
      {
        title: "Duration",
        key: "duration",
        render: (v, s) => {
          return (
            <div>
              {s.status !== "RUNNING"
                ? moment
                    .duration(moment(s.updatedAt).diff(s.createdAt))
                    .humanize()
                : "---"}
            </div>
          );
        },
      },
      {
        title: "Logs",
        key: "logs",
        render: (v, s) => {
          return (
            <div>
              <Button
                shape="circle"
                icon={<FileTextOutlined />}
                onClick={() => {
                  this.setState({
                    ...this.state,
                    selectedJobExecution: s,
                  });
                }}
              />
            </div>
          );
        },
      },
    ];
  };

  getJobExecutions = async () => {
    const { objectType, objectId } = this.props;
    const jobExecutions = await GraphQLService<{
      allJobExecutions: IJobExecution[];
    }>(GQL, {
      objectType,
      objectId,
    });
    this.setState({
      loading: false,
      executions: jobExecutions.allJobExecutions,
    });
  };

  public render() {
    const { loading, executions } = this.state;

    const renderInner = () => {
      if (loading === true) {
        return (
          <Aligner className="whly-content">
            <Loading />
          </Aligner>
        );
      } else {
        return (
          <div>
            <Table<IJobExecution>
              rowKey="id"
              dataSource={executions}
              columns={this.generateColumns()}
              pagination={{
                size: "small",
                style: { display: "none" },
                defaultPageSize: 1000,
              }}
            />
            <ExecutionLogsDrawer
              onClose={() => this.setState({ selectedJobExecution: undefined })}
              selectedJobExecution={this.state.selectedJobExecution}
              visible={!!this.state.selectedJobExecution}
            />
          </div>
        );
      }
    };

    return (
      <div>
        <div style={{}}>
          <Typography.Text strong>Executions</Typography.Text>
        </div>
        <div style={{ marginLeft: -24, marginRight: -24, marginBottom: -24 }}>
          {renderInner()}
        </div>
      </div>
    );
  }
}
