import type { Config } from "@markdoc/markdoc";
import type { IUser } from "../../../../../../../interfaces/user";
import { LocaleService } from "../../../../../../../services/localeService";
import { type AvailableColumn } from "../../../../object/domain";

const lte = {
  transform(parameters) {
    const number1 = parameters[0];
    const number2 = parameters[1];

    if (typeof number1 !== "number" || typeof number2 !== "number") {
      return false;
    }

    return number1 <= number2;
  },
};

const gte = {
  transform(parameters) {
    const number1 = parameters[0];
    const number2 = parameters[1];

    if (typeof number1 !== "number" || typeof number2 !== "number") {
      return false;
    }

    return number1 >= number2;
  },
};

const gt = {
  transform(parameters) {
    const number1 = parameters[0];
    const number2 = parameters[1];

    if (typeof number1 !== "number" || typeof number2 !== "number") {
      return false;
    }

    return number1 > number2;
  },
};

const lt = {
  transform(parameters) {
    const number1 = parameters[0];
    const number2 = parameters[1];

    if (typeof number1 !== "number" || typeof number2 !== "number") {
      return false;
    }

    return number1 < number2;
  },
};

const format = (user: IUser) => ({
  transform(parameters) {
    const value = parameters[0];
    const formatter = parameters[1];
    try {
      const v = parseFloat(value);
      if (isNaN(v)) {
        return value;
      }
      const localeService = new LocaleService(user.locale);
      const numeral = localeService.getNumberDefaultFormatting();
      if (formatter === "variant") {
        return numeral(v).format("+ 0,0[.]0%");
      }
      if (formatter === "percent") {
        return numeral(v).format("0,0[.]0%");
      }
      if (!!formatter) {
        return numeral(v).format(formatter);
      }
      // Default
      return numeral(v).format("0,0[.]0(0)");
    } catch (err) {
      return value;
    }
  },
});

export const generateConfig = (
  user: IUser,
  availableColumns: AvailableColumn[]
): Config => ({
  functions: {
    lte,
    gte,
    lt,
    gt,
    format: format(user),
  },
});
