import { inject, observer } from "mobx-react";
import * as React from "react";
import GenericCatalog from "../../../components/catalog/list/GenericCatalog";
import { GenericCatalogLoader } from "../../../components/catalog/loader/GenericCatalogLoader";
import { compose } from "../../../components/compose/WlyCompose";
import Error from "../../../components/layout/feedback/error";
import type { IDestinationMeta } from "../../../interfaces/destinations";
import type { IOrgWithSources } from "../../../interfaces/org";
import type { BreadcrumbStoreProps } from "../../../store/breadCrumbStore";
import GraphQl from "../../graphql/graphql";
import type { InjectedOrgProps } from "../../orgs/WithOrg";
import withOrg from "../../orgs/WithOrg";

interface ISourcesProps {
  onClose: () => void;
}

const GQL = `
  query getWarehouseMetas(
    $id: ID!
  ) {
    allDestinationMetas(sortBy: name_ASC, where: {deleted: false}) {
      id
      slug
      name
      auth
      publicInfo {
        id
        name
        slug
        label
        tagline
        website
        logo
        description
      }
    }
    Org(where:{id: $id}) {
      destinations {
        id
      }
    }
  }
`;

interface GQLQueryResult {
  allDestinationMetas: IDestinationMeta[];
  Org: IOrgWithSources;
}

type Props = ISourcesProps & InjectedOrgProps & BreadcrumbStoreProps;

class Sources extends React.Component<Props> {
  public componentDidMount() {
    const { breadcrumbStore } = this.props;
    breadcrumbStore.setBreadcrumb([{ label: "Warehouse Catalog" }]);
  }

  public render() {
    const { org, onClose } = this.props;

    const title = "Warehouse Catalog";

    return (
      <GraphQl<GQLQueryResult> query={GQL} variables={{ id: org.id }}>
        {(gql) => {
          if (gql.status === "initial" || gql.status === "loading") {
            return <GenericCatalogLoader title={title} onClose={onClose} />;
          }
          if (gql.status === "error") {
            return <Error />;
          }
          return (
            <GenericCatalog
              title={title}
              items={gql.data.allDestinationMetas}
              alreadyPurchasedItems={[]}
              destinationViewName={"warehouseConnection"}
              onClose={onClose}
            />
          );
        }}
      </GraphQl>
    );
  }
}

export default compose<Props, ISourcesProps>(withOrg)(
  inject("breadcrumbStore")(observer(Sources))
);
