import * as React from "react";
import { compose } from "../../../../components/compose/WlyCompose";
import Error from "../../../../components/error/Error";
import Feednack from "../../../../components/layout/feedback/feedback";
import Loading from "../../../../components/layout/feedback/loading";
import type { ISource } from "../../../../interfaces/sources";
import GraphQl from "../../../graphql/graphql";
import type { InjectedOrgProps } from "../../../orgs/WithOrg";
import WithOrg from "../../../orgs/WithOrg";

interface SourceUsageProps {
  source: ISource;
}

const gql = `
query getJwt($orgId: ID!, $filters: JSON) {
  createEmbedConfig(orgId: $orgId, filters: $filters)
}
`;

type Props = InjectedOrgProps & SourceUsageProps;

class SourceUsage extends React.Component<Props> {
  render() {
    const { org, source } = this.props;

    const orgSlug = "whaly-internal";
    const embedToken = "edbbae3a-3439-4221-a8d9-f13adfb95186";

    return (
      <GraphQl<{ createEmbedConfig: string }>
        query={gql}
        variables={{
          orgId: org.id,
          filters: {
            sourceId: source.id,
          },
        }}
      >
        {(gql) => {
          if (gql.status === "loading" || gql.status === "initial") {
            return (
              <Feednack>
                <Loading />
              </Feednack>
            );
          }
          if (gql.status === "error") {
            return (
              <Feednack>
                <Error />
              </Feednack>
            );
          }
          return (
            <iframe
              title="embedUsage"
              width={"100%"}
              height={"100%"}
              frameBorder={0}
              allowFullScreen
              src={`https://app.whaly.io/${orgSlug}/embed/report/${embedToken}?token=${gql.data.createEmbedConfig}`}
            />
          );
        }}
      </GraphQl>
    );
  }
}

export default compose<Props, SourceUsageProps>(WithOrg)(SourceUsage);
