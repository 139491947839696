import { Form, Input, Radio } from "antd";

export interface IMapPositionPickerValue {
  type?: "fit" | "custom";
  position?: {
    lat?: string;
    lng?: string;
    zoomLevel?: string;
  };
}

interface IMapPositionPickerProps {
  value?: IMapPositionPickerValue;
  onChange?: (v: IMapPositionPickerValue) => void;
  initialValue?: IMapPositionPickerValue;
}

export function MapPositionPicker(props: IMapPositionPickerProps) {
  const { value, onChange } = props;

  const renderSpecificForm = (t: IMapPositionPickerValue) => {
    switch (t?.type) {
      case "custom":
        return (
          <>
            <Form.Item label="Latitude">
              <Input
                value={t?.position?.lat}
                type="number"
                onChange={(e) => {
                  onChange({
                    ...value,
                    position: {
                      ...value.position,
                      lat: e.target.value,
                    },
                  });
                }}
              />
            </Form.Item>
            <Form.Item label="Longitude">
              <Input
                value={t?.position?.lng}
                type="number"
                onChange={(e) => {
                  onChange({
                    ...value,
                    position: {
                      ...value.position,
                      lng: e.target.value,
                    },
                  });
                }}
              />
            </Form.Item>
            <Form.Item label="Zoom level">
              <Input
                value={t?.position?.zoomLevel}
                type="number"
                onChange={(e) => {
                  onChange({
                    ...value,
                    position: {
                      ...value.position,
                      zoomLevel: e.target.value,
                    },
                  });
                }}
              />
            </Form.Item>
          </>
        );
      case "fit":
        return null;
    }
  };

  return (
    <div>
      <Form.Item>
        <Radio.Group
          defaultValue={"fit"}
          options={[
            {
              label: "Fit to data",
              value: "fit",
            },
            {
              label: "Custom",
              value: "custom",
            },
          ]}
          onChange={(v) => {
            const newValue: IMapPositionPickerValue = {
              type: v.target.value as any,
              position: null,
            };
            onChange(newValue);
          }}
          value={value?.type}
        />
      </Form.Item>
      {renderSpecificForm(value)}
    </div>
  );
}
