import type { Diagnostic } from "@codemirror/lint";
import { linter } from "@codemirror/lint";
import type { ValidateError } from "@markdoc/markdoc";

export const markdocLinter = (errors: ValidateError[]) =>
  linter((view) => {
    const diagnostics: Diagnostic[] = [];
    errors.forEach((error) => {
      const from = error.lines[0];
      const to = error.lines[1];
      const message = error.error.message;
      let severity = error.error.level;
      if (severity === "debug") {
        severity = "info";
      }
      if (severity === "critical") {
        severity = "error";
      }
      diagnostics.push({
        from: view.state.doc.line(from + 1).from,
        to: view.state.doc.line(to).to,
        severity: severity,
        message: message,
      });
    });

    return diagnostics;
  });
