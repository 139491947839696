import type { IAnalysisType } from "../../../containers/exploration/single/domain";
import { WlyGaugeChartIcon } from "../../icons/custom-icons/WlyGaugeChartIcon";
import type { SingleChartDefinition } from "../domain";

export const gaugeChartDefinition: SingleChartDefinition = {
  label: "Gauge chart",
  allowTimeComparison: false,
  minMetrics: 2,
  maxMetrics: 2,
  minDimensions: 0,
  maxDimensions: 0,
  icon: <WlyGaugeChartIcon />,
  pivot: (analysisType: IAnalysisType) => false,
  canDisplay: (rs, query) => {
    if (query.analysisType !== "METRIC") {
      return "Can only use a gauge on a metric visualization...";
    }
    if (query.selectedMeasures.length !== 2) {
      return "You need to specify 2 metrics in order to use a gauge...";
    } else if (query.selectedDimensions.length > 0) {
      return "Cannot use a gauge with a dimension...";
    }
  },
  canPredict: () => false,
  config: [
    "font-size",
    "gauge-type",
    "show-percent",
    "hide-confettis",
    "show-total",
    "palette",
  ],
  axis: {
    bottom: {
      editable: false,
      isTimeFormattable: (a) => false,
    },
  },
  extractCustomizationDimensions: (d, q) => {
    return {
      dimensions: [],
    };
  },
  extractCustomizationSeries: (s, d) => {
    return {
      series: s,
      customColor: true,
      customLabel: false,
      customType: [],
    };
  },
};
