import * as React from "react";
import type { IOrg } from "../../../interfaces/org";
import "./OrgListItem.scss";
import { Link } from "react-router-dom";

interface IOrgListItemProps {
  org: IOrg;
  render: (org: IOrg) => React.ReactNode;
  to: string;
}

export default class OrgListItem extends React.Component<IOrgListItemProps> {
  public render() {
    const { org, render, to } = this.props;
    return (
      <Link to={to}>
        <div className="org-list-item">{render(org)}</div>
      </Link>
    );
  }
}
