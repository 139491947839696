import { AppstoreAddOutlined } from "@ant-design/icons";
import { Button, Dropdown, Skeleton, Space, theme, Typography } from "antd";
import _ from "lodash";
import { inject, observer } from "mobx-react";
import moment from "moment";
import React, { useState } from "react";
import type { RouteComponentProps } from "react-router";
import { withRouter } from "react-router";
import type { InjectedAntUtilsProps } from "../../../../components/ant-utils/withAntUtils";
import { withAntUtils } from "../../../../components/ant-utils/withAntUtils";
import { compose } from "../../../../components/compose/WlyCompose";
import { IUserRoleType } from "../../../../interfaces/user";
import type { UserStoreProps } from "../../../../store/userStore";
import type { InjectedOrgProps } from "../../../orgs/WithOrg";
import WithOrg from "../../../orgs/WithOrg";
import { hasRoleAccessBoolean } from "../../../user-settings/HasRoleAccess";
import { EmbedType } from "../record/domain";
import RecordView from "../record/RecordView";
import ReportV2Page from "../report/ReportV2Page";
import { CustomizeHomePage } from "./customize/CustomizeHomePage";
import type { ContentSelectorType, HomeConfigType } from "./customize/domain";
import { parseV2HomeConfig } from "./customize/domain";
import "./HomePage.scss";
import type { IHomePageContext } from "./HomePageContext";
import { HomePageContext } from "./HomePageContext";
import HomeBody from "./main/HomeBody";
import HomeHeader from "./main/HomeHeader";

const { useToken } = theme;

const { Title } = Typography;

interface IHomePageProps {}

type Props = IHomePageProps &
  RouteComponentProps &
  InjectedOrgProps &
  UserStoreProps &
  InjectedAntUtilsProps;

function HomePage(props: Props) {
  const {
    user,
    org,
    role,
    userStore: { saveHomeConfig },
    antUtils,
  } = props;

  const { token } = useToken();

  const initialConfig = parseV2HomeConfig(role.v2HomeConfig);

  const contentStyle: React.CSSProperties = {
    backgroundColor: token.colorBgElevated,
    borderRadius: token.borderRadiusLG,
    boxShadow: token.boxShadowSecondary,
  };

  const [tempConfig, setTempConfig] = useState<HomeConfigType | undefined>();

  const [homeContext, setHomeContext] = useState<IHomePageContext>({
    status: "loading",
  });
  const context = {
    value: homeContext,
    setValue: (v: IHomePageContext) =>
      !_.isEqual(v, homeContext) ? setHomeContext(v) : undefined,
  };

  const currentHomeConfig = tempConfig ? tempConfig : initialConfig;

  const getGreetings = () => {
    const now = new Date().getHours();
    if (now < 12) return "Good morning";
    if (now < 18) return "Good afternoon";
    return "Good evening";
  };

  const renderInner = () => {
    try {
      const definition = currentHomeConfig.layout;
      if (definition.type === "default") {
        return (
          <div style={{ margin: "auto", marginTop: 16, padding: 16 }}>
            <HomeBody />
          </div>
        );
      }
      if (definition.type === "report") {
        return (
          <div style={{ marginRight: -8, marginLeft: -8 }}>
            <ReportV2Page homePageConfig={{ reportId: definition.reportId }} />
          </div>
        );
      } else if (definition.type === "record") {
        return (
          <RecordView
            embedType={EmbedType.home}
            homePageConfig={{
              objectId: definition.objectId,
              recordId: definition.recordId,
              hideHeader: true,
            }}
          />
        );
      }
      return role.v2HomeConfig;
    } catch (err) {
      console.error(err);
      return (
        <div>
          <HomeBody />
        </div>
      );
    }
  };

  const onSave = async (c: string, l: ContentSelectorType) => {
    const loading = antUtils.message.loading({ content: "Saving..." });
    try {
      await saveHomeConfig(role.id, { color: c, layout: l });
      loading();
      antUtils.message.success({
        content: "Successfully updated configuration",
      });
    } catch (err) {
      loading();
      antUtils.message.error({
        content: "There was an error saving your configuration",
      });
    } finally {
      setTempConfig(undefined);
    }
  };

  return (
    <HomePageContext.Provider value={context}>
      <div className="home-page">
        <div className="home-page-top-component">
          <div className="home-page-top-component-title" style={{ flex: 1 }}>
            {currentHomeConfig.layout.type !== "record" && (
              <Typography.Title level={4} style={{ marginBottom: 0 }}>
                Home
              </Typography.Title>
            )}
            {currentHomeConfig.layout.type === "record" &&
              context.value.status === "loading" && (
                <Space>
                  <Skeleton.Button
                    size="small"
                    style={{ width: 60 }}
                    shape="round"
                    active
                  />
                  <Typography.Title
                    level={4}
                    type="secondary"
                    style={{ marginBottom: 0 }}
                  >
                    /
                  </Typography.Title>
                  <Skeleton.Button
                    size="small"
                    shape="round"
                    style={{ width: 60 }}
                    active
                  />
                </Space>
              )}
            {currentHomeConfig.layout.type === "record" &&
              context.value.status === "success" && (
                <Space>
                  <Typography.Title level={4} style={{ marginBottom: 0 }}>
                    {context.value.data.object}
                  </Typography.Title>
                  <Typography.Title
                    level={4}
                    type="secondary"
                    style={{ marginBottom: 0 }}
                  >
                    /
                  </Typography.Title>
                  <Typography.Title level={4} style={{ marginBottom: 0 }}>
                    {context.value.data.record}
                  </Typography.Title>
                </Space>
              )}
          </div>
          <div className="home-page-top-component-buttons" style={{ flex: 0 }}>
            <Dropdown
              trigger={["click"]}
              placement="bottomRight"
              arrow
              destroyPopupOnHide={true}
              onOpenChange={(v) => {
                if (!v && !_.isEqual(tempConfig, initialConfig) && tempConfig) {
                  onSave(tempConfig.color, tempConfig.layout);
                }
              }}
              dropdownRender={() => {
                return (
                  <div style={{ ...contentStyle, maxWidth: 256, padding: 12 }}>
                    <CustomizeHomePage
                      color={currentHomeConfig.color}
                      layout={currentHomeConfig.layout}
                      hide={
                        hasRoleAccessBoolean(
                          IUserRoleType.BUILDER,
                          user,
                          org.id
                        )
                          ? []
                          : ["layout"]
                      }
                      onChange={(c, v) => {
                        setTempConfig({ color: c, layout: v });
                      }}
                    />
                  </div>
                );
              }}
            >
              <Button icon={<AppstoreAddOutlined />}>Customize</Button>
            </Dropdown>
          </div>
        </div>
        <div className="home-page-inner">
          <div
            className="home-page-cover"
            style={{
              backgroundColor: currentHomeConfig.color,
            }}
          />
          <div className="home-page-cover-content">
            <div className="home-page-cover-content-title">
              <Title type="secondary" level={5}>
                {moment().format("dddd, MMMM D")}
              </Title>
              <Title
                level={2}
                style={{ paddingBottom: 16, marginTop: 0, marginBottom: 0 }}
              >
                {getGreetings()}
                {user.firstName && `, ${user.firstName}`}
              </Title>
            </div>

            <div className="home-page-cover-content-buttons" style={{}}>
              <div className="home-page-cover-content-buttons-inner">
                {hasRoleAccessBoolean(IUserRoleType.BUILDER, user, org.id) ? (
                  <HomeHeader />
                ) : undefined}
              </div>
            </div>
          </div>
        </div>
        {renderInner()}
      </div>
    </HomePageContext.Provider>
  );
}

export default compose<Props, IHomePageProps>(
  inject("userStore"),
  observer,
  withAntUtils,
  WithOrg,
  withRouter
)(HomePage);
