import {
  AlignCenterOutlined,
  AlignLeftOutlined,
  AlignRightOutlined,
  EditOutlined,
} from "@ant-design/icons";
import { Button, Form, Input, Radio, Select, Space } from "antd";
import type { AvailableDimension } from "../../../../../../../../components/measures/filter-item/FilterItem";
import { ColumnsAddition } from "../../../../../../../../components/widget-options/ColumnsAddition";
import {
  LagoonCallOrigin,
  lagoonServiceLoad,
} from "../../../../../../../../services/LagoonService";
import { ChartOptionCollapse } from "../../../../../../../chart-options/components/ChartOptionCollapse";
import ChartOptionLine from "../../../../../../../chart-options/components/ChartOptionLine";
import type { AvailableColumn } from "../../../../../object/domain";
import { getObjectColumns } from "../../../../../object/domain";
import type { AvailableProperty } from "../../../../../object/viewer/domain";
import { WidgetVisibilityFilters } from "../../../common/visibility-filters/WidgetVisibilityFilters";
import { buildQueryFromDimensionAndMetrics } from "../../../widgets/related-lists/domain";
import type { IWidgetEmailEditorProps } from "../../domain";
import type { IEmailWidgetPropertyListConfig } from "../domain";

interface IEmailWidgetPropertyListEditorProps
  extends IWidgetEmailEditorProps<IEmailWidgetPropertyListConfig> {}

export function EmailWidgetPropertyListEditor(
  props: IEmailWidgetPropertyListEditorProps
) {
  const { object, form, org } = props;

  const columns = getObjectColumns(object);

  return (
    <>
      <ChartOptionCollapse dark title="Image Configuration">
        <Space size="middle" style={{ width: "100%" }} direction="vertical">
          <ChartOptionLine
            items={[
              {
                content: <div>Align</div>,
                flex: `1`,
              },
              {
                content: (
                  <Form.Item name={["config", "align"]} noStyle>
                    <Radio.Group
                      options={[
                        {
                          value: "left",
                          label: <AlignLeftOutlined />,
                        },
                        {
                          value: "center",
                          label: <AlignCenterOutlined />,
                        },
                        {
                          value: "right",
                          label: <AlignRightOutlined />,
                        },
                      ]}
                      optionType="button"
                      buttonStyle="solid"
                    />
                  </Form.Item>
                ),
                flex: `0 auto`,
              },
            ]}
          />
          <ChartOptionLine
            items={[
              {
                content: <div>Spacing</div>,
                flex: `1`,
              },
              {
                content: (
                  <Form.Item
                    initialValue={"normal"}
                    name={["config", "spacing"]}
                    noStyle
                  >
                    <Select style={{ width: "100%" }}>
                      <Select.Option value="normal">Normal</Select.Option>
                      <Select.Option value="compact">Compact</Select.Option>
                    </Select>
                  </Form.Item>
                ),
                flex: `1`,
              },
            ]}
          />
          <ChartOptionLine
            items={[
              {
                content: (
                  <Form.Item noStyle name={["config", "columns"]}>
                    <ColumnsAddition availableColumns={columns} />
                  </Form.Item>
                ),
                flex: `1`,
              },
            ]}
          />
        </Space>
      </ChartOptionCollapse>
      <Form.Item noStyle shouldUpdate>
        {() => {
          const values: AvailableColumn[] = (
            form?.getFieldValue(["config", "columns"]) || []
          )
            .map((c) => columns.find((a) => a.data.key === c))
            .filter((c) => !!c);
          return values.map((v) => (
            <ChartOptionCollapse key={v.data.key} dark title={v.data.label}>
              <Space
                size="middle"
                style={{ width: "100%" }}
                direction="vertical"
              >
                <ChartOptionLine
                  items={[
                    {
                      flex: 1,
                      content: <div>Name</div>,
                    },
                    {
                      flex: 1,
                      content: (
                        <Form.Item
                          name={[
                            "config",
                            "options",
                            v.data.key.replaceAll(".", "_"),
                            "label",
                          ]}
                          noStyle
                        >
                          <Input />
                        </Form.Item>
                      ),
                    },
                    {
                      flex: `0 auto`,
                      popover: {
                        content: (
                          <Form.Item
                            noStyle
                            name={[
                              "config",
                              "options",
                              v.data.key.replaceAll(".", "_"),
                              "displayFilter",
                            ]}
                          >
                            <WidgetVisibilityFilters
                              autocomplete={async (dimension) => {
                                try {
                                  const dim = columns.find(
                                    (ac) =>
                                      ac.type === "property" &&
                                      dimension === ac.data.key
                                  );

                                  if (!dim) {
                                    return [];
                                  }

                                  const dimKey = (dim.data as AvailableProperty)
                                    .sortAndFilterKey;
                                  if (!dimKey) {
                                    throw new Error("Dim not found");
                                  }

                                  const query =
                                    buildQueryFromDimensionAndMetrics(
                                      [dimKey],
                                      []
                                    );

                                  const data = await lagoonServiceLoad(
                                    org.id,
                                    query,
                                    "OBJECT",
                                    object.id,
                                    undefined,
                                    LagoonCallOrigin.WHALY_APP
                                  );

                                  const results = data.tablePivot();

                                  return results.map((r) =>
                                    r[dimKey].toString()
                                  );
                                } catch (err) {
                                  console.error(err);
                                  throw new Error(
                                    "Can't fetch recommendations"
                                  );
                                }
                              }}
                              availableDimensions={columns.map<AvailableDimension>(
                                (ac) => {
                                  const prop = ac.data;
                                  const domain =
                                    ac.type === "property"
                                      ? ac.data.domain
                                      : "NUMERIC";
                                  return {
                                    key: prop.key,
                                    label: prop.label,
                                    description: prop.description,
                                    type: "standard",
                                    domain: domain,
                                  };
                                }
                              )}
                            />
                          </Form.Item>
                        ),
                      },
                      content: (
                        <Button
                          shape="circle"
                          type="text"
                          icon={<EditOutlined />}
                        />
                      ),
                    },
                  ]}
                />
              </Space>
            </ChartOptionCollapse>
          ));
        }}
      </Form.Item>
    </>
  );
}
