import type { AvailableColumn } from "../../../../../object/domain";
import { getObjectColumns } from "../../../../../object/domain";
import { shouldDisplayWidget } from "../../../common/visibility-filters/domain";
import type { IWidgetEmailMjmlRenderer } from "../../domain";
import type { IEmailWidgetPropertyListConfig } from "../domain";
import {
  getDefaultConfig,
  getPropertyColor,
  renderColors,
  renderValue,
} from "../domain";

const pillTemaplate = (
  color: "success" | "warning" | "danger" | "normal",
  rawValue: string | number | boolean,
  content: string,
  available: AvailableColumn
) => {
  const renderIcon = () => {
    if (false) {
      return `
<td style="padding:4px">
  <img aria-label="Accepter" height="16" role="img" src="https://ci3.googleusercontent.com/meips/ADKq_NZExJqh2SMMI8eNa8gX07_Ub-2HG1oD9ldtir3xdryeAvA3Gdgn1krt7Z0tEBaXyKHwi1zTjlUtNlEGsOacaeX3g7JHOM9coCXfoQ=s0-d-e1-ft#https://www.doctolib.fr/email_layout/circle-check.png" style="padding:4px;padding-right:0px" width="16" class="CToWUd" data-bit="iit">
</td>
      `;
    }
    return "";
  };

  const pillColor = getPropertyColor(available, rawValue);
  const colors = renderColors(color);
  const renderedIcon = renderIcon();

  const appliedColor = pillColor
    ? {
        bgColor: pillColor,
        fontColor: "#333333",
      }
    : colors;

  return `
  <table bgcolor="${
    appliedColor.bgColor
  }" border="0" cellpadding="0" cellspacing="0" height="22" role="listitem" style="display:inline-block;border-radius:4px;margin-right:8px">
    <tbody>
      <tr style="line-height:0px">
        ${renderedIcon}
        <td style="color:${
          appliedColor.fontColor
        };font-size:12px;line-height:22px;padding-right:8px;${
    !renderedIcon ? "padding-left:8px;" : ""
  }" valign="sub">
          ${content}
        </td>
      </tr>
    </tbody>
  </table>
  `;
};

export const EmailWidgetPropertyListMjml: IWidgetEmailMjmlRenderer<
  IEmailWidgetPropertyListConfig
> = async (widget, object, record, conf, org, user) => {
  const defaults = getDefaultConfig(conf, record);
  const columns = getObjectColumns(object);

  const available = (conf.columns || [])
    .map((c) => {
      return columns.find((a) => a.data.key === c);
    })
    .filter((r) => !!r)
    .filter((r) => {
      const currentConf =
        conf.options?.[r!.data.key.replaceAll(".", "_")]?.displayFilter;
      if (!currentConf) {
        return true;
      }
      return shouldDisplayWidget(currentConf, record, object);
    })
    .map((r) => {
      const value = renderValue(r!, conf, record, user.locale);
      return pillTemaplate("normal", record[r!.data.key], value, r!);
    });

  const padding =
    defaults.spacing === "normal"
      ? `padding-top="10" padding-bottom="10"`
      : `padding-top="0" padding-bottom="0"`;

  return `
<mj-text ${padding} align="${defaults.align}">
  ${available.join("\n")}
</mj-text>
`;
};
