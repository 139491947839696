export const GET_SERVICE_ACCOUNTS = `
query getOrgServiceAccounts(
  $orgId: ID!
  ) {
  allServiceAccounts: allUsers(
    where: { 
      type: SERVICE_ACCOUNT, 
      isDeleted: false,
      roles_some: {
        isDeleted: false,
        org: {
          id: $orgId
        }
      }
    },
    sortBy: [createdAt_DESC]
  ) {
  id
  name: lastName
  keys(where: { isDeleted: false }) {
    id
    valueRedacted
    createdAt
  }
  roles(where: { isDeleted: false, org: { id: $orgId }}) {
    id
    role
  }
}
}
`;
