import type { IPartnerPortal } from "../../../../../interfaces/org";
import AccessManagementTableWrapper from "../../../users-management/AccessManagementTableWrapper";

interface IPartnerPortalDetailAccessManagementProps {
  portal: IPartnerPortal;
}

export function PartnerPortalDetailAccessManagement(
  props: IPartnerPortalDetailAccessManagementProps
) {
  const { portal } = props;
  return <AccessManagementTableWrapper type="PORTAL" portal={portal} />;
}
