import Markdoc from "@markdoc/markdoc";
import {
  LagoonCallOrigin,
  lagoonServiceLoad,
} from "../../../../../../../../services/LagoonService";
import { getObjectColumns } from "../../../../../object/domain";
import { getRelatedMjml } from "../../../../utils";
import type { IWidgetEmailMjmlRenderer } from "../../domain";
import { getFormattedRecord, getParsedDoc } from "../../text/domain";
import type { IEmailWidgetRepeatableConfig } from "../domain";
import { getEmailWidetRepeatableQueryConfig } from "../domain";

export const EmailWidgetRepeatableMjml: IWidgetEmailMjmlRenderer<
  IEmailWidgetRepeatableConfig
> = async (widget, object, record, conf, org, user) => {
  const queryConfig = getEmailWidetRepeatableQueryConfig({
    object,
    record,
    conf,
  });

  const layoutId = conf.options?.foreignObjectSelectedlayout;

  if (!queryConfig.isValid || !layoutId) {
    return "Can't render";
  }

  const data = await lagoonServiceLoad(
    org.id,
    queryConfig.query,
    "OBJECT",
    object.id,
    undefined,
    LagoonCallOrigin.WHALY_APP,
    undefined
  );

  const records = data.tablePivot();

  const renderedLine = await Promise.all(
    records.map(async (record) => {
      const data = await getRelatedMjml(
        org,
        user,
        conf.options!.foreignObjectSelectedlayout!,
        queryConfig.foreignObject.id,
        record[queryConfig.dimensionKey].toString()
      );
      return data;
    })
  );

  if (renderedLine.length) {
    return `${renderedLine.join("\n")}`;
  }

  const columns = getObjectColumns(object);
  const formattedRecord = getFormattedRecord(columns, record);

  const content = getParsedDoc(
    conf.fallback?.content ?? "",
    formattedRecord,
    user,
    columns
  );

  const html = Markdoc.renderers.html(content);
  return `<mj-text padding-top="10" padding-bottom="10" align="left">${html
    .replaceAll("<article>", "")
    .replaceAll("</article>", "")}</mj-text>`;
};
