import type { NodeModelGenerics } from "@projectstorm/react-diagrams";
import { NodeModel, PortModelAlignment } from "@projectstorm/react-diagrams";
import type { Transformation } from "../../../../../../../../interfaces/transformations";
import type { WhalyNodeModel } from "../../domain";
import type { CanvasNodeGenerator } from "../../helpers/CanvasNodeGenerator";
import { HEIGHT } from "../data/DataNodeWidget";
import { SimplePortModel } from "../port/SimplePortModel";
import { WIDTH } from "./OperationNodeWidget";

// prettier-ignore
export class OperationNodeModel extends NodeModel<NodeModelGenerics> implements WhalyNodeModel
{
  transformation: Transformation;
  hasSecondaryOutPort: boolean;
  canvaNodeGenerator: CanvasNodeGenerator;

  constructor(hasSecondaryOutPort?: boolean) {
    super({
      type: "operation",
    });

    this.hasSecondaryOutPort = hasSecondaryOutPort;

    const port = new SimplePortModel(PortModelAlignment.RIGHT);
    port.setDirection("out");
    port.setMaximumLinks(1);
    this.addPort(port);

    const por2 = new SimplePortModel(PortModelAlignment.LEFT);
    por2.setDirection("in");
    por2.setMaximumLinks(1);
    this.addPort(por2);

    if (hasSecondaryOutPort) {
      const por3 = new SimplePortModel(PortModelAlignment.TOP);
      por3.setDirection("in");
      por3.setMaximumLinks(1);
      this.addPort(por3);
    }

    this.width = WIDTH;
    this.height = HEIGHT;
  }

  bindTransformation = (d: Transformation) => {
    this.transformation = d;
  };

  getPort = (name: string): SimplePortModel => {
    return super.getPort(name) as SimplePortModel;
  };

  getPrimaryInPort = () => {
    return this.getPort(PortModelAlignment.LEFT);
  };

  getSecondaryInPort = () => {
    if (this.hasSecondaryOutPort) {
      return this.getPort(PortModelAlignment.TOP);
    }
  };

  bindCanvasNodeGenerator = (c: CanvasNodeGenerator) => {
    this.canvaNodeGenerator = c;
  };

  getOutPort = () => {
    return this.getPort(PortModelAlignment.RIGHT);
  };

  hasWarning = () => {
    if (
      this.canvaNodeGenerator &&
      this.transformation &&
      this.canvaNodeGenerator.hasWarnings(this.transformation.var)
    ) {
      return true;
    }
    return false;
  };

  isOutput = () => {
    if (
      this.canvaNodeGenerator &&
      this.transformation &&
      this.canvaNodeGenerator.outputVar === this.transformation.var
    ) {
      return true;
    }
    return false;
  };

  canEdit = () => {
    if (this.canvaNodeGenerator) {
      return this.canvaNodeGenerator.canEdit;
    }
    return false;
  };
}
