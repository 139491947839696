import { Form, Input, Select } from "antd";
import cuid from "cuid";
import * as React from "react";
import { compose } from "../../../../../../../../components/compose/WlyCompose";
import Feednack from "../../../../../../../../components/layout/feedback/feedback";
import Loading from "../../../../../../../../components/layout/feedback/loading";
import type { AsyncData } from "../../../../../../../../helpers/typescriptHelpers";
import type { IDestination } from "../../../../../../../../interfaces/destinations";
import type {
  SchemaResult,
  TableCombineOperation,
  Transformation,
} from "../../../../../../../../interfaces/transformations";
import { computeTransformations } from "../../../../../../../../services/BrizoService";
import { generateUniqueId } from "../../../../../../../../utils/uniqueId";
import type { InjectedOrgProps } from "../../../../../../../orgs/WithOrg";
import WithOrg from "../../../../../../../orgs/WithOrg";
import TypeRenderer from "../../../../../../../spreadsheet/renderer/TypeRenderer";
import { catchErrors } from "../../../../../../../transformations/domain";
import { getDisplayName, type FlowOperationFormProps } from "../domain";

const { Option, OptGroup } = Select;
const id = cuid();

type UnionFormProps = FlowOperationFormProps<{
  var: string;
  operation: TableCombineOperation;
  domain: "viewResolver";
}> & {
  currentWarehouse: IDestination;
};

type Props = UnionFormProps & InjectedOrgProps;

interface AvailableNode {
  nodeId: string;
  schema: SchemaResult;
}

interface FormValue {
  table1: string;
  table2: string;
  columns: string | string[];
}

const UnionForm: React.FunctionComponent<Props> = (props) => {
  const {
    canvasNodeGenerator,
    currentTransformation,
    org,
    prevStepSchema,
    isStale,
    onCancel,
    onNodeHighlight,
    onSave,
    setFormInstance,
    setSubmitting,
    currentWarehouse,
  } = props;

  const [form] = Form.useForm();

  const [schemas, setSchemas] = React.useState<
    AsyncData<{
      availableNodes: Array<AvailableNode>;
    }>
  >({ status: "initial" });

  React.useEffect(() => {
    async function fetchSchemas() {
      try {
        setSchemas({ status: "loading" });

        const computeParent = (key: string) => {
          const parent = canvasNodeGenerator.getParentKey(key);
          if (parent) {
            return computeParent(parent);
          }
          return key;
        };

        let currentParent = computeParent(currentTransformation.var);

        const solitaryNodes = canvasNodeGenerator.parentKeys
          .filter((p) => {
            return p !== currentParent;
          })
          .map((p) => {
            return canvasNodeGenerator.getCurrentTabItem(p);
          });

        const currentSelectedKey = currentTransformation.operation.args.table2;

        if (
          currentSelectedKey &&
          canvasNodeGenerator.getNodeIndex(currentSelectedKey)
        ) {
          const tabItem =
            canvasNodeGenerator.getCurrentTabItem(currentSelectedKey);
          solitaryNodes.push(tabItem);
        }

        const solitaryNodesQueries = solitaryNodes.reduce((acc, v, i) => {
          return {
            ...acc,
            [v.key]: [
              ...v.query,
              {
                var: generateUniqueId(),
                operation: {
                  type: "Table.Schema",
                  args: {
                    table: v.key,
                  },
                },
              },
            ],
          };
        }, {} as {});

        const r = await computeTransformations(
          currentWarehouse.id,
          solitaryNodesQueries,
          false,
          false
        );

        const availableNodesWithSchema: Array<AvailableNode> =
          solitaryNodes.map((sn) => {
            const schema = r.data[sn.key] as SchemaResult;
            const node = {
              nodeId: sn.key,
              schema: schema,
            };
            return node;
          });

        setSchemas({
          status: "success",
          data: {
            availableNodes: availableNodesWithSchema,
          },
        });
      } catch (error) {
        console.warn(error);
        setSchemas({ status: "error", error: error });
      }
    }
    if (schemas.status !== "loading") {
      fetchSchemas();
    }
  }, [currentTransformation.var, canvasNodeGenerator.parentKeys]);

  React.useEffect(() => {
    if (setFormInstance) {
      setFormInstance(form);
    }
  }, [setFormInstance, form]);

  const isFormDisabled = !onSave;

  const initialValues: FormValue = {
    ...currentTransformation.operation.args,
  };

  if (schemas.status === "loading" || schemas.status === "initial") {
    return <Loading />;
  }

  if (schemas.status === "error") {
    return <Feednack>{JSON.stringify(schemas.error)}</Feednack>;
  }

  return (
    <Form
      layout="vertical"
      form={form}
      initialValues={initialValues}
      onFieldsChange={() => {
        isStale && isStale(true);
      }}
      onFinish={async (v: FormValue) => {
        try {
          setSubmitting(true);
          const newTransformation: Transformation = {
            ...currentTransformation,
            operation: {
              ...currentTransformation.operation,
              args: {
                ...currentTransformation.operation.args,
                table1: v.table1,
                table2: v.table2,
                columns: v.columns,
              },
            },
          };

          await onSave?.([
            {
              type: "UPDATE",
              transformation: newTransformation,
            },
          ]);
          setSubmitting(false);

          if (onCancel) {
            onCancel();
          }
        } catch (err) {
          setSubmitting(false);
          catchErrors(err, id);
          console.warn(err);
        }
      }}
    >
      <Form.Item name="table1" required={true} style={{ display: "none" }}>
        <Input disabled />
      </Form.Item>

      <Form.Item
        label="Union"
        name="table2"
        required={true}
        tooltip="Select the step to union with. If you want to union a dataset or a model, drop it in the canvas first."
        rules={[
          {
            required: true,
            message: "This field is required",
          },
        ]}
      >
        <Select
          disabled={isFormDisabled}
          notFoundContent="Drag and drop a dataset or a model in the canvas"
          allowClear
          style={{ width: "100%" }}
          placeholder="Select the table to combine"
          popupMatchSelectWidth={false}
          onChange={(v) => {
            form.setFieldsValue({
              columns: [],
            });
          }}
        >
          {schemas.data.availableNodes.length > 0 ? (
            <OptGroup label="Canvas step">
              {schemas.data.availableNodes.map((can, i) => {
                const headNode = canvasNodeGenerator.getNodeIndex(can.nodeId);
                return (
                  <Option
                    key={i}
                    label={headNode.step}
                    value={headNode.step.var}
                  >
                    <div
                      onMouseEnter={() =>
                        onNodeHighlight?.(headNode.step.var, true)
                      }
                      onMouseLeave={() =>
                        onNodeHighlight?.(headNode.step.var, false)
                      }
                      className="demo-option-label-item"
                    >
                      {getDisplayName(headNode.step.operation.type)}
                    </div>
                  </Option>
                );
              })}
            </OptGroup>
          ) : (
            <></>
          )}
        </Select>
      </Form.Item>
      <Form.Item
        shouldUpdate={(prevValues: FormValue, curValues: FormValue) =>
          prevValues.table2 !== curValues.table2
        }
      >
        {() => {
          return (
            <Form.Item
              label="With the following columns"
              name="columns"
              rules={[
                {
                  required: true,
                  message: "At least one column required",
                },
              ]}
              tooltip="Only the columns having the same name and type in the two tables can be unioned"
            >
              <Select
                mode="multiple"
                disabled={isFormDisabled}
                allowClear
                style={{ width: "100%" }}
                placeholder="Select the columns to group on"
                showSearch={true}
                optionFilterProp="key"
                popupMatchSelectWidth={false}
              >
                {Object.keys(prevStepSchema)
                  .filter((columnName) => {
                    const unionedSchema = schemas.data.availableNodes?.find(
                      (n) => n.nodeId === form.getFieldValue("table2")
                    )?.schema;

                    if (!unionedSchema) return false;

                    const columnDefinitionInPreviousTable =
                      prevStepSchema[columnName];
                    const columnDefinitionInUnionedTable =
                      unionedSchema[columnName];

                    if (!columnDefinitionInUnionedTable) {
                      return false;
                    }
                    if (
                      columnDefinitionInPreviousTable.domain !==
                      columnDefinitionInUnionedTable.domain
                    ) {
                      return false;
                    }

                    return true;
                  })
                  .map((r, i) => {
                    return (
                      <Option key={i} value={r}>
                        <div className="demo-option-label-item">
                          <span role="img" style={{ marginRight: 5 }}>
                            <TypeRenderer
                              domain={prevStepSchema[r].domain}
                              formula={
                                prevStepSchema[r].operation ===
                                "Table.AddColumn"
                              }
                            />
                          </span>
                          {prevStepSchema[r].label
                            ? prevStepSchema[r].label
                            : r}
                        </div>
                      </Option>
                    );
                  })}
              </Select>
            </Form.Item>
          );
        }}
      </Form.Item>
    </Form>
  );
};

export default compose<Props, UnionFormProps>(WithOrg)(UnionForm);
