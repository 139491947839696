import type { Moment } from "moment";
import moment from "moment";

export type SimpleDateRange = [Moment, Moment];

export type DATE_RANGE_PRESET =
  | "TODAY"
  | "YESTERDAY"
  | "LAST_7_DAYS"
  | "LAST_14_DAYS"
  | "LAST_28_DAYS"
  | "LAST_30_DAYS"
  | "LAST_90_DAYS"
  | "LAST_180_DAYS"
  | "LAST_360_DAYS"
  | "YEAR_TO_DATE"
  | "THIS_WEEK"
  | "THIS_MONTH"
  | "THIS_QUARTER"
  | "THIS_YEAR"
  | "PREVIOUS_WEEK"
  | "THIS_QUARTER"
  | "PREVIOUS_MONTH"
  | "PREVIOUS_QUARTER"
  | "PREVIOUS_YEAR"
  | "ALL_TIME";

export const PRESETS: Array<{
  key: DATE_RANGE_PRESET;
  label: string;
  values: [Moment, Moment];
  section: number;
  defaultVisible: boolean;
}> = [
  {
    key: "TODAY",
    label: "Today",
    values: [moment().startOf("day"), moment().endOf("day")],
    section: 0,
    defaultVisible: true,
  },
  {
    key: "YESTERDAY",
    label: "Yesterday",
    values: [
      moment().subtract(1, "day").startOf("day"),
      moment().subtract(1, "day").endOf("day"),
    ],
    section: 0,
    defaultVisible: true,
  },
  {
    key: "LAST_7_DAYS",
    label: "Last 7 Days",
    values: [moment().subtract(7, "day").startOf("day"), moment().endOf("day")],
    section: 1,
    defaultVisible: true,
  },
  {
    key: "LAST_14_DAYS",
    label: "Last 14 Days",
    values: [
      moment().subtract(14, "day").startOf("day"),
      moment().endOf("day"),
    ],
    section: 1,
    defaultVisible: true,
  },
  {
    key: "LAST_28_DAYS",
    label: "Last 28 Days",
    values: [
      moment().subtract(28, "day").startOf("day"),
      moment().endOf("day"),
    ],
    section: 1,
    defaultVisible: false,
  },
  {
    key: "LAST_30_DAYS",
    label: "Last 30 Days",
    values: [
      moment().subtract(30, "day").startOf("day"),
      moment().endOf("day"),
    ],
    section: 2,
    defaultVisible: true,
  },
  {
    key: "LAST_90_DAYS",
    label: "Last 90 Days",
    values: [
      moment().subtract(90, "day").startOf("day"),
      moment().endOf("day"),
    ],
    section: 2,
    defaultVisible: false,
  },
  {
    key: "LAST_180_DAYS",
    label: "Last 180 Days",
    values: [
      moment().subtract(180, "day").startOf("day"),
      moment().endOf("day"),
    ],
    section: 2,
    defaultVisible: false,
  },
  {
    key: "LAST_360_DAYS",
    label: "Last 360 Days",
    values: [
      moment().subtract(360, "day").startOf("day"),
      moment().endOf("day"),
    ],
    section: 2,
    defaultVisible: false,
  },
  {
    key: "YEAR_TO_DATE",
    label: "Year to Date",
    values: [moment().startOf("year"), moment().endOf("day")],
    section: 2,
    defaultVisible: true,
  },
  {
    key: "THIS_WEEK",
    label: "This Week",
    values: [moment().startOf("isoWeek"), moment().endOf("isoWeek")],
    section: 3,
    defaultVisible: false,
  },
  {
    key: "THIS_MONTH",
    label: "This Month",
    values: [moment().startOf("month"), moment().endOf("month")],
    section: 3,
    defaultVisible: false,
  },
  {
    key: "THIS_QUARTER",
    label: "This Quarter",
    values: [moment().startOf("quarter"), moment().endOf("quarter")],
    section: 3,
    defaultVisible: false,
  },
  {
    key: "THIS_YEAR",
    label: "This Year",
    values: [moment().startOf("year"), moment().endOf("year")],
    section: 3,
    defaultVisible: false,
  },
  {
    key: "PREVIOUS_WEEK",
    label: "Previous Week",
    values: [
      moment().subtract(1, "week").startOf("isoWeek"),
      moment().subtract(1, "week").endOf("isoWeek"),
    ],
    section: 4,
    defaultVisible: false,
  },
  {
    key: "PREVIOUS_MONTH",
    label: "Previous Month",
    values: [
      moment().subtract(1, "month").startOf("month"),
      moment().subtract(1, "month").endOf("month"),
    ],
    section: 4,
    defaultVisible: false,
  },
  {
    key: "PREVIOUS_QUARTER",
    label: "Previous Quarter",
    values: [
      moment().subtract(1, "quarter").startOf("quarter"),
      moment().subtract(1, "quarter").endOf("quarter"),
    ],
    section: 4,
    defaultVisible: false,
  },
  {
    key: "PREVIOUS_YEAR",
    label: "Previous Year",
    values: [
      moment().subtract(1, "year").startOf("year"),
      moment().subtract(1, "year").endOf("year"),
    ],
    section: 4,
    defaultVisible: false,
  },
  {
    key: "ALL_TIME",
    label: "All Time",
    values: undefined,
    section: 5,
    defaultVisible: true,
  },
];
