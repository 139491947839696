import * as duckdb from "@duckdb/duckdb-wasm";
import eh_worker from "@duckdb/duckdb-wasm/dist/duckdb-browser-eh.worker.js?url";
import mvp_worker from "@duckdb/duckdb-wasm/dist/duckdb-browser-mvp.worker.js?url";
import duckdb_wasm_eh from "@duckdb/duckdb-wasm/dist/duckdb-eh.wasm?url";
import duckdb_wasm from "@duckdb/duckdb-wasm/dist/duckdb-mvp.wasm?url";
import * as React from "react";
import Feednack from "../../../../../../../../../../components/layout/feedback/feedback";
import Loading from "../../../../../../../../../../components/layout/feedback/loading";
import { type AsyncData } from "../../../../../../../../../../helpers/typescriptHelpers";
import RootCauseAnalysisTable from "../../../../../../../modals/root-causes/RootCauseAnalysisTable";
import { type RCAMetricFormatter } from "../../../../../../../modals/root-causes/interface";
const MANUAL_BUNDLES: duckdb.DuckDBBundles = {
  mvp: {
    mainModule: duckdb_wasm,
    mainWorker: mvp_worker,
  },
  eh: {
    mainModule: duckdb_wasm_eh,
    mainWorker: eh_worker,
  },
};

export interface IRCAProps {
  id: string;
  file?: string;
  dimension?: string;
  metric?: string;
}

export function RCA(props: React.PropsWithChildren<IRCAProps>) {
  const { children, id, file, dimension, metric } = props;

  const [init, setInit] = React.useState<AsyncData<{ db: duckdb.AsyncDuckDB }>>(
    { status: "initial" }
  );

  React.useEffect(() => {
    const init = async () => {
      try {
        setInit({ status: "loading" });
        // Select a bundle based on browser checks
        const bundle = await duckdb.selectBundle(MANUAL_BUNDLES);
        // Instantiate the asynchronus version of DuckDB-wasm
        const worker = new Worker(bundle.mainWorker!);
        const logger = new duckdb.ConsoleLogger();
        const db = new duckdb.AsyncDuckDB(logger, worker);
        await db.instantiate(bundle.mainModule, bundle.pthreadWorker);
        const csvurl = `${window.WHALY_CONSTANTS.APP_URL}/v3-demo-data/${file}`;
        const streamResponse = await fetch(csvurl);
        await db.registerFileBuffer(
          file!,
          new Uint8Array(await streamResponse.arrayBuffer())
        );

        const c = await db.connect();

        await c.insertCSVFromPath(file!, {
          schema: "main",
          name: "rca",
          detect: true,
          header: true,
        });

        await c.close();
        setInit({ status: "success", data: { db } });
      } catch (err: any) {
        console.error(err);
        setInit({ status: "error", error: err });
      }
    };
    init();
  }, []);

  const renderInner = () => {
    if (init.status === "initial" || init.status === "loading") {
      return (
        <Feednack>
          <Loading />
        </Feednack>
      );
    }
    if (init.status === "error") {
      return <Feednack>Error while fetching the db</Feednack>;
    }

    const availableMetrics: Array<{
      key: string;
      label: string;
      formatter: RCAMetricFormatter;
    }> = [
      // today
      {
        key: "ambiant_global_dn",
        label: "DN Corse",
        formatter: "POINT",
      },
      {
        key: "ambiant_pdl",
        label: "PDL Corse",
        formatter: "POINT",
      },
      {
        key: "france_ambiant_global_dn",
        label: "DN France",
        formatter: "POINT",
      },
      {
        key: "france_ambiant_pdl",
        label: "PDL France",
        formatter: "POINT",
      },
      {
        key: "ambiant_global_dn_gap_vs_france",
        label: "DN gap par rapport a la france",
        formatter: "POINT",
      },
      {
        key: "ambiant_pdl_gap_vs_france",
        label: "PDL gap par rapport a la france",
        formatter: "POINT",
      },
      // diff
      {
        key: "ambiant_global_dn_variation",
        label: "Variation de DN Corse",
        formatter: "POINT",
      },
      {
        key: "ambiant_pdl_variation",
        label: "Variation de PDL Corse",
        formatter: "POINT",
      },
      {
        key: "france_ambiant_global_dn_variation",
        label: "Variation de DN France",
        formatter: "POINT",
      },
      {
        key: "france_ambiant_pdl_variation",
        label: "Variation de PDL France",
        formatter: "POINT",
      },
      {
        key: "ambiant_global_dn_variation_vs_france",
        label: "Variation de DN par rapport a la france",
        formatter: "POINT",
      },
      {
        key: "ambiant_pdl_variation_vs_france",
        label: "Variation de PDL par rapport a la france",
        formatter: "POINT",
      },
    ];

    const found = availableMetrics.find((m) => m.key === metric);

    if (!metric) {
      return <Feednack>Please pass a metric</Feednack>;
    }

    if (!found) {
      return <Feednack>Can't find metric {metric}</Feednack>;
    }

    return (
      <RootCauseAnalysisTable
        table={"main.rca"}
        db={init.data.db}
        rca={{
          name: "",
          analyses: [],
          primaryMetric: found,
          proportionMetrics: [
            {
              key: "store_count",
              label: "Nombre de magasins",
            },
            {
              key: "surface_m2",
              label: "Surface",
            },
          ],
          dimensions: [
            {
              key: "retailer",
              label: "Enseigne",
              cubeName: "",
            },
            {
              key: "sub_retailer",
              label: "Sous Enseigne",
              cubeName: "",
            },
            {
              key: "store_segment",
              label: "Cluster",
              cubeName: "",
            },
            {
              key: "surface_strategy",
              label: "Strategie",
              cubeName: "",
            },
            {
              key: "field_sales_assignement_type",
              label: "Attribution",
              cubeName: "",
            },
            ...(dimension === "product_name"
              ? [
                  {
                    key: "product_name",
                    label: "Produit",
                    cubeName: "",
                  },
                ]
              : []),
          ],
          object: {
            slug: "",
            name: "",
          },
        }}
        find={"low"}
        selectedProportionDimension={"store_count"}
        additionalFilters={[]}
        injectedDimensionsColumn={dimension}
      />
    );
  };

  return <div style={{ height: 450 }}>{renderInner()}</div>;
}
