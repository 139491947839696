import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { Alert, Button, Drawer, Space } from "antd";
import cuid from "cuid";
import type { FormikProps } from "formik";
import { Field, FieldArray, Form, Formik } from "formik";
import * as React from "react";
import type { RouteComponentProps } from "react-router";
import { withRouter } from "react-router";
import { CatalogFormElement } from "../../../../components/catalog/form/CatalogFormElement";
import { compose } from "../../../../components/compose/WlyCompose";
import FormActions from "../../../../components/form/actions/FormActions";

// Formik components
import Input from "../../../../components/form/elements/input/Input";
import InputNumber from "../../../../components/form/elements/inputNumber/InputNumber";
import Select from "../../../../components/form/elements/select/Select";
import { FormObserver } from "../../../../components/form/formik-observer/FormikObserver";

import Feednack from "../../../../components/layout/feedback/feedback";
import Loading from "../../../../components/layout/feedback/loading";
import type { AsyncData } from "../../../../helpers/typescriptHelpers";
import type {
  ActionFormFieldSelect,
  IAction,
  IActionOption,
} from "../../../../interfaces/actions";
import type { IExploration } from "../../../../interfaces/explorations";
import type { ISchedule } from "../../../../interfaces/schedule";

import type { IFilter, IReport } from "../../../../interfaces/reports";
import type {
  ScheduleDailySendEvery,
  ScheduleDayOfMonthPreset,
  SchedulePeriod,
  ScheduleWeeklyAtDay,
  WorkflowContentFormat,
} from "../../../../interfaces/schedule";
import GraphQLService from "../../../../services/graphql/GraphQLService";
import GraphQl from "../../../graphql/graphql";
import type { InjectedOrgProps } from "../../../orgs/WithOrg";
import WithOrg from "../../../orgs/WithOrg";
import { FILTER_ARRAY_SEPARATOR } from "../../domain";
import FieldFilter from "../filters/field-filter/FieldFilter";
import type { ScheduleOuterType } from "./domain";
import {
  ACTION_FORM_FIELD_VALUE_PREFIX,
  generateDestinationId,
  getAvailableFormatOptionsForReportType,
  getFormatOptionsAvailableForDestination,
  getFormatsSupportedByDestination,
} from "./domain";

import "./Schedule.scss";

const ACTION_FORM_FIELD_NAME_PREFIX = "actionForm_";

const days = [
  { value: "monday", label: "Monday" },
  { value: "tuesday", label: "Tuesday" },
  { value: "wednesday", label: "Wednesday" },
  { value: "thursday", label: "Thursday" },
  { value: "friday", label: "Friday" },
  { value: "saturday", label: "Saturday" },
  { value: "sunday", label: "Sunday" },
];

interface IScheduleEditDrawerProps {
  report: IReport;
  type: ScheduleOuterType;
  visible: boolean;
  schedule?: ISchedule;
  initialDestinationId?: string;
  onClose: () => void;
  filters: IFilter[];
  explorations: IExploration[];
  executeSchedule: (scheduleId: string) => Promise<void>;
}

interface FormDataModel {
  isEditMode: boolean;
  scheduleId?: string;
  workflowId?: string;

  name: string;

  period: SchedulePeriod;
  atHour: number;
  timezone: string;

  dailySendEvery: ScheduleDailySendEvery;
  weeklyOnDay: ScheduleWeeklyAtDay;
  monthlyDayOfMonthPreset: ScheduleDayOfMonthPreset;
  monthlyDayOfMonthNumber: number;

  destination: string;
  emails?: string[];
  actionFormValues?: {
    [key: string]: string;
  };
  format: WorkflowContentFormat;
  controlledValues: Array<{ filterId: string; value: string[] }>;
}

type Props = IScheduleEditDrawerProps &
  RouteComponentProps<{ organizationSlug: string }> &
  InjectedOrgProps;

interface InstalledActionsResult {
  allActions: IAction[];
  allUserRoles: {
    user: {
      email: string;
    };
  }[];
  Report: {
    type: "DASHBOARD" | "QUESTION";
  };
}

const getPushEditFormInitialDataQuery = `
query GetPushEditFormInitialData($orgId: ID!, $reportId:ID!) {
  allActions(where: { org: { id: $orgId }, isDeleted_not: true }) {
    id
    name
    actionMeta
    actionMetaVirtual {
      supportedFormats
    }
  }
  allUserRoles(
    where: {
      org: { id: $orgId }
      isDeleted_not: true
      user: { isPublicGuestUser_not: true, isAdmin_not: true },
    }
  ) {
    user {
      email
    }
  }
  Report(where: {id: $reportId}) {
    type
  }
}
`;

interface ActionDataResult {
  Action: {
    values: [
      {
        value: string;
        option: {
          key: string;
        };
      }
    ];
  };
}

const getActionDataQglQuery = `
query GetActionData($actionId: ID!) {
  Action(where: { id: $actionId }) {
    values {
      value
      option: optionVirtual {
        key
      }
    }
  }
}
`;

interface ActionFormResult {
  getActionForm: {
    fields: {
      name: string;
      type: "oauth_link_google";
      label: string;
      description: string;
      oauth_url: string;
      required?: boolean;
      default?: string;
    }[];
    state: {};
  };
}

const getActionFormGqlQuery = `
query GetActionForm(
  $orgId: ID!
  $actionId: ID!
  $actionMetaSlug: String!
  $data: JSON!
) {
  Action(where: { id: $actionId }) {
    values {
      value
      option: optionVirtual {
        key
      }
    }
  }
  getActionForm(
    orgId: $orgId
    actionId: $actionId
    slug: $actionMetaSlug
    data: $data
  )
}
`;

const ScheduleEditDrawer: React.FunctionComponent<Props> = (props) => {
  const {
    org,
    user,
    report,
    schedule,
    initialDestinationId,
    filters,
    explorations,
    executeSchedule,
  } = props;
  const [data, setData] = React.useState<AsyncData<InstalledActionsResult>>({
    status: "initial",
  });
  const [reload, setReload] = React.useState<string>(cuid());

  React.useEffect(() => {
    setData({ status: "loading" });
    const fetchData = async () => {
      const result = await GraphQLService<InstalledActionsResult>(
        getPushEditFormInitialDataQuery,
        {
          orgId: org.id,
          reportId: props.report.id,
        }
      );
      setData({ status: "success", data: result });
    };
    fetchData().catch((err) => {
      setData({ status: "error", error: err });
    });
  }, [props.org]);

  const onSave = async (values: FormDataModel): Promise<void> => {
    const getScheduleConfig = (values: FormDataModel) => {
      // Used to "clean" any value that is no longer needed when doing updates
      // hence, we use null as this is passed in the GraphQL query
      const baseScheduleConfig = {
        name: values.name,
        atHour: null,
        dailySendEvery: null,
        weeklyOnDay: null,
        monthlyDayOfMonthPreset: null,
        monthlyDayOfMonthNumber: null,
        timezone: values.timezone,
      };
      switch (values.period) {
        case "never":
          return {
            ...baseScheduleConfig,
            period: "never",
          };
        case "hour":
          return {
            ...baseScheduleConfig,
            period: "hour",
          };
        case "day":
          return {
            ...baseScheduleConfig,
            period: "day",
            atHour: values.atHour,
            dailySendEvery: values.dailySendEvery,
          };
        case "week":
          return {
            ...baseScheduleConfig,
            period: "week",
            atHour: values.atHour,
            weeklyOnDay: values.weeklyOnDay,
          };
        case "month":
          switch (values.monthlyDayOfMonthPreset) {
            case "nth_day":
              return {
                ...baseScheduleConfig,
                period: "month",
                atHour: values.atHour,
                monthlyDayOfMonthPreset: values.monthlyDayOfMonthPreset,
                monthlyDayOfMonthNumber: values.monthlyDayOfMonthNumber,
              };
            default:
              return {
                ...baseScheduleConfig,
                period: "month",
                atHour: values.atHour,
                monthlyDayOfMonthPreset: values.monthlyDayOfMonthPreset,
              };
          }
        default:
          throw new Error(`Period: ${values.period} is not supported`);
      }
    };

    const getWorkflowConfig = (values: FormDataModel) => {
      const baseWorkflowConfig = {
        contentFormat: values.format,
        emails: null,
        action: null,
        actionFormValues: null,
        controlledValues: {
          create: values.controlledValues.map((cv) => ({
            value: cv.value ? cv.value.join(FILTER_ARRAY_SEPARATOR) : null,
            filter: {
              connect: {
                id: cv.filterId,
              },
            },
            org: {
              connect: {
                id: org.id,
              },
            },
          })),
        },
        owner: {
          connect: {
            id: user.id,
          },
        },
      };
      if (values.destination === "email") {
        return {
          ...baseWorkflowConfig,
          type: "email_content_delivery",
          emails: (values.emails || []).join(","),
        };
      } else if (
        values?.destination?.startsWith(ACTION_FORM_FIELD_VALUE_PREFIX)
      ) {
        const actionId = values.destination.split(":")[1];
        return {
          ...baseWorkflowConfig,
          type: "action_content_delivery",
          action: {
            connect: {
              id: actionId,
            },
          },
          actionFormValues: JSON.stringify(
            Object.keys(values)
              .filter((key) => {
                return key.startsWith(ACTION_FORM_FIELD_NAME_PREFIX);
              })
              .reduce((acc, key) => {
                const newKey = key.split(ACTION_FORM_FIELD_NAME_PREFIX)[1];
                acc[newKey] = values[key];
                return acc;
              }, {})
          ),
        };
      } else {
        throw new Error(`Destination: ${values.destination} is not supported!`);
      }
    };

    const isEditMode = schedule ? true : false;
    const scheduleConfig = getScheduleConfig(values);
    const workflowConfig = getWorkflowConfig(values);

    // Creation of records
    if (!isEditMode) {
      const newScheduleData = {
        status: "idle",
        ...scheduleConfig,
        workflow: {
          create: {
            ...workflowConfig,

            org: {
              connect: {
                id: org.id,
              },
            },
            report: {
              connect: {
                id: props.report.id,
              },
            },
          },
        },
        org: {
          connect: {
            id: org.id,
          },
        },
      };

      const data = await GraphQLService(
        `
      mutation CreateScheduleAndWorkflow($scheduleData: ScheduleCreateInput!) {
        createSchedule(data: $scheduleData) {
          id
        }
      }`,
        {
          scheduleData: newScheduleData,
        }
      );

      if (newScheduleData.period === "never") {
        await executeSchedule(data.createSchedule.id);
      }
      // Edition of existing records
    } else {
      const scheduleId = values.scheduleId;
      const workflowId = values.workflowId;

      await GraphQLService(
        `
      mutation updateFilterControledValues($controlledValues: [FilterControledValuesUpdateInput]) {
        updateFilterControledValues(data: $controlledValues) {
          id
        }
      }
      `,
        {
          controlledValues: schedule?.workflow?.controlledValues.map((v) => {
            return {
              id: v.id,
              data: {
                deleted: true,
              },
            };
          }),
        }
      );

      await GraphQLService(
        `
      mutation UpdateScheduleAndWorkflow(
        $scheduleId: ID!
        $scheduleData: ScheduleUpdateInput!
        $workflowId: ID!
        $workflowData: WorkflowUpdateInput!
      ) {
        updateSchedule(id: $scheduleId, data: $scheduleData) {
          id
        }
        updateWorkflow(id: $workflowId, data: $workflowData) {
          id
        }
      }
      `,
        {
          scheduleId,
          scheduleData: scheduleConfig,
          workflowId,
          workflowData: workflowConfig,
        }
      );
    }

    props.onClose();
  };

  const getExistingDestinationId = (): string | undefined => {
    if (initialDestinationId) {
      return initialDestinationId;
    }
    if (schedule?.workflow?.type === "email_content_delivery") {
      return generateDestinationId("email");
    } else if (schedule?.workflow?.type === "action_content_delivery") {
      return generateDestinationId(schedule?.workflow?.action?.id);
    }
  };

  const destination = getExistingDestinationId();
  const reportSupportedFormat = getAvailableFormatOptionsForReportType(
    report.type
  ).map((formatOpt) => formatOpt.value);

  // We keep the intersection of what the current report type support and what the destination support
  const allSupportedFormats = destination
    ? getFormatsSupportedByDestination(
        destination,
        data.status === "success" ? data.data.allActions : []
      ).filter((format) => {
        if (reportSupportedFormat.includes(format)) {
          return true;
        }
        return false;
      })
    : undefined;

  let initialData: FormDataModel = {
    // Existing vs creation mode
    isEditMode: schedule ? true : false,
    scheduleId: schedule?.id,
    workflowId: schedule?.workflow?.id,

    // Meta
    name: schedule?.name || "",

    // Schedule
    period: schedule?.period || "hour",
    atHour: schedule?.atHour || 6,
    timezone:
      schedule?.timezone || Intl.DateTimeFormat().resolvedOptions().timeZone,
    dailySendEvery: schedule?.dailySendEvery || "every_day",
    weeklyOnDay: schedule?.weeklyOnDay || "monday",
    monthlyDayOfMonthPreset: schedule?.monthlyDayOfMonthPreset || "first_day",
    monthlyDayOfMonthNumber: schedule?.monthlyDayOfMonthNumber || 1,

    // Destination / workflow
    destination: destination || "email",
    emails: schedule?.workflow?.emails?.split(",") || [user.email],

    format:
      schedule?.workflow?.contentFormat ||
      (allSupportedFormats !== undefined && allSupportedFormats.length
        ? allSupportedFormats[0]
        : "png"),
    controlledValues: schedule?.workflow?.controlledValues
      ? schedule?.workflow?.controlledValues?.map((cv) => {
          return {
            filterId: cv.filter?.id,
            value: (cv.value as any).split(FILTER_ARRAY_SEPARATOR),
          };
        })
      : [], // todo
  };

  // Action form values
  if (initialData.destination.startsWith(ACTION_FORM_FIELD_VALUE_PREFIX)) {
    const currentActionFormValues = schedule?.workflow?.actionFormValues
      ? JSON.parse(schedule.workflow.actionFormValues)
      : {};
    const actionFormValueWithPrefix = Object.keys(
      currentActionFormValues
    ).reduce((acc, fieldName) => {
      acc[`${ACTION_FORM_FIELD_NAME_PREFIX}${fieldName}`] =
        currentActionFormValues[fieldName];
      return acc;
    }, {});
    initialData = {
      ...initialData,
      ...actionFormValueWithPrefix,
    };
  }

  const renderForm = () => {
    if (data.status === "initial" || data.status === "loading") {
      return <Loading />;
    } else if (data.status === "error") {
      return <Feednack>{data.error.message}</Feednack>;
    }

    function getAtHourFormField(): JSX.Element {
      return (
        <Field
          component={Select}
          name="atHour"
          formItemProps={{
            label: "At",
            style: {
              display: "inline-block",
              width: "calc(50% - 12px)",
            },
          }}
          inputProps={{
            options: [
              { value: 0, label: "00:00" },
              { value: 1, label: "01:00" },
              { value: 2, label: "02:00" },
              { value: 3, label: "03:00" },
              { value: 4, label: "04:00" },
              { value: 5, label: "05:00" },
              { value: 6, label: "06:00" },
              { value: 7, label: "07:00" },
              { value: 8, label: "08:00" },
              { value: 9, label: "09:00" },
              { value: 10, label: "10:00" },
              { value: 11, label: "11:00" },
              { value: 12, label: "12:00" },
              { value: 13, label: "13:00" },
              { value: 14, label: "14:00" },
              { value: 15, label: "15:00" },
              { value: 16, label: "16:00" },
              { value: 17, label: "17:00" },
              { value: 18, label: "18:00" },
              { value: 19, label: "19:00" },
              { value: 20, label: "20:00" },
              { value: 21, label: "21:00" },
              { value: 22, label: "22:00" },
              { value: 23, label: "23:00" },
            ],
          }}
        ></Field>
      );
    }

    return (
      <Formik
        initialValues={initialData}
        onSubmit={async (v) => {
          await onSave(v);
        }}
      >
        {(renderProps: FormikProps<FormDataModel>) => {
          return (
            <Form style={{ marginBottom: 48 }}>
              <FormObserver<FormDataModel>
                onChange={(values, previous) => {
                  values.controlledValues.map((cv, i) => {
                    const foundPrevious = previous?.controlledValues[i];
                    if (
                      (previous && !foundPrevious) ||
                      (foundPrevious && foundPrevious.filterId !== cv.filterId)
                    ) {
                      const foundFilter = filters.find(
                        (f) => f.id === cv.filterId
                      );
                      if (foundFilter) {
                        console.debug("resetting");
                        renderProps.setFieldValue(
                          `controlledValues.${i}.value`,
                          foundFilter.type === "TIME" ? ["LAST_7_DAYS"] : null
                        );
                      }
                    }
                  });
                }}
              />
              {initialData && initialData.isEditMode && (
                <Alert
                  style={{ marginBottom: 24 }}
                  message="You are editting an existing schedule."
                  type="info"
                />
              )}

              <Field
                component={Input}
                name="name"
                formItemProps={{
                  label: "Name",
                  required: true,
                }}
                inputProps={{}}
              ></Field>

              <Field
                component={Select}
                name="period"
                formItemProps={{
                  label: "Recurrence",
                  required: true,
                }}
                inputProps={{
                  options: [
                    { value: "never", label: "Send now" },
                    { value: "hour", label: "Hourly" },
                    { value: "day", label: "Daily" },
                    { value: "week", label: "Weekly" },
                    { value: "month", label: "Monthly" },
                  ],
                }}
              ></Field>

              {/* Daily period details */}
              {(() => {
                return renderProps.values["period"] === "day" ? (
                  <>
                    <Field
                      component={Select}
                      name="dailySendEvery"
                      formItemProps={{
                        label: "Send every",
                        style: {
                          display: "inline-block",
                          width: "calc(50% - 12px)",
                        },
                      }}
                      inputProps={{
                        options: [
                          { value: "every_day", label: "Day" },
                          { value: "on_week_day", label: "Business day" },
                        ],
                      }}
                    ></Field>
                    <span
                      style={{ display: "inline-block", width: "24px" }}
                    ></span>
                    {getAtHourFormField()}
                  </>
                ) : undefined;
              })()}

              {/* Weekly period details */}
              {(() => {
                return renderProps.values["period"] === "week" ? (
                  <>
                    <Field
                      component={Select}
                      name="weeklyOnDay"
                      formItemProps={{
                        label: "Every",
                        style: {
                          display: "inline-block",
                          width: "calc(50% - 12px)",
                        },
                      }}
                      inputProps={{
                        options: days.map((d) => {
                          return {
                            value: d.value,
                            label: d.label,
                          };
                        }),
                      }}
                    ></Field>
                    <span
                      style={{ display: "inline-block", width: "24px" }}
                    ></span>
                    {getAtHourFormField()}
                  </>
                ) : undefined;
              })()}
              {/* Monthly period details */}
              {(() => {
                return renderProps.values["period"] === "month" ? (
                  <>
                    <Field
                      component={Select}
                      name="monthlyDayOfMonthPreset"
                      formItemProps={{
                        label: "Every",
                        style: {
                          display: "inline-block",
                          width: "calc(50% - 12px)",
                        },
                      }}
                      inputProps={{
                        options: [
                          {
                            value: "first_day",
                            label: "First day of the Month",
                          },
                          { value: "last_day", label: "Last day of the Month" },
                          { value: "nth_day", label: "Nth Day of the Month" },
                        ],
                      }}
                    ></Field>
                    <span
                      style={{ display: "inline-block", width: "24px" }}
                    ></span>
                    {getAtHourFormField()}
                    {(() => {
                      return renderProps.values["monthlyDayOfMonthPreset"] ===
                        "nth_day" ? (
                        <>
                          <Field
                            component={InputNumber}
                            name="monthlyDayOfMonthNumber"
                            formItemProps={{
                              label: "On Nth day",
                            }}
                            inputProps={{
                              min: 1,
                              max: 31,
                              defaultValue: 1,
                            }}
                          ></Field>
                        </>
                      ) : undefined;
                    })()}
                  </>
                ) : undefined;
              })()}

              {/* Email Destination details */}
              {(() => {
                const validateEmail = (value: string[]) => {
                  if (
                    !value ||
                    value.length === 0 ||
                    !(value instanceof Array)
                  ) {
                    return `At least one email is required! `;
                  }
                  value.forEach((email) => {
                    if (
                      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)
                    ) {
                      return `Invalid email address: '${email}'`;
                    }
                  });
                  return undefined;
                };
                return renderProps.values["destination"] === "email" ? (
                  <Field
                    component={Select}
                    name="emails"
                    formItemProps={{
                      label: "Email addresses",
                    }}
                    inputProps={{
                      style: { width: "100%" },
                      mode: "tags",
                      tokenSeparators: [","],
                      showArrow: false,
                      filterOption: false,
                      options: [],
                      notFoundContent: null,
                    }}
                    validate={validateEmail}
                  />
                ) : undefined;
              })()}

              {/* Action Destination details */}
              {(() => {
                const destinationValue = renderProps.values["destination"];
                if (
                  typeof destinationValue === "string" &&
                  destinationValue.startsWith(ACTION_FORM_FIELD_VALUE_PREFIX)
                ) {
                  const actionId = destinationValue.split(`:`)[1];
                  return (
                    <GraphQl<ActionDataResult>
                      query={getActionDataQglQuery}
                      variables={{
                        actionId,
                      }}
                    >
                      {(actionDataGql) => {
                        const orgId = org.id;
                        const actionMetaSlug = data.data.allActions.find(
                          (action) => {
                            return action.id === actionId;
                          }
                        )?.actionMeta;
                        switch (actionDataGql.status) {
                          case "initial":
                          case "loading":
                            return (
                              <Feednack>
                                <Loading />
                              </Feednack>
                            );
                          case "error":
                            return (
                              <Feednack>
                                {JSON.stringify(actionDataGql.error)}
                              </Feednack>
                            );
                          case "success":
                            const actionData =
                              actionDataGql?.data.Action.values.reduce(
                                (acc, v) => {
                                  return {
                                    ...acc,
                                    [v.option.key]: v.value,
                                  };
                                },
                                {}
                              );
                            return (
                              <GraphQl<ActionFormResult>
                                query={getActionFormGqlQuery}
                                variables={{
                                  orgId,
                                  actionId,
                                  actionMetaSlug,
                                  data: actionData,
                                  // used to force the form reload after the oauth Popup closes
                                  reload,
                                }}
                              >
                                {(gql) => {
                                  switch (gql.status) {
                                    case "initial":
                                    case "loading":
                                      return (
                                        <Feednack>
                                          <Loading />
                                        </Feednack>
                                      );
                                    case "error":
                                      return (
                                        <Feednack>
                                          {JSON.stringify(gql.error)}
                                        </Feednack>
                                      );
                                    case "success":
                                      const fields =
                                        gql.data.getActionForm.fields;
                                      return (
                                        <>
                                          {fields?.map((field) => {
                                            const prefixedName = `${ACTION_FORM_FIELD_NAME_PREFIX}${field.name}`;

                                            const values = renderProps.values;
                                            if (
                                              !values[prefixedName] &&
                                              !field.type.startsWith("oauth") &&
                                              field.default
                                            ) {
                                              renderProps.setFieldValue(
                                                prefixedName,
                                                field.default
                                              );
                                            }
                                            return (
                                              <div key={prefixedName}>
                                                <CatalogFormElement
                                                  catalogItemOption={
                                                    {
                                                      required: field?.required,
                                                      id: prefixedName,
                                                      key: prefixedName,
                                                      label: field.label
                                                        ? field.label
                                                        : field.name,
                                                      slug: prefixedName,
                                                      type: field.type as any,
                                                      options: (
                                                        (
                                                          field as unknown as ActionFormFieldSelect
                                                        ).options || []
                                                      ).map((o) => ({
                                                        value: o.name,
                                                        label: o.label,
                                                      })),
                                                      editable: true,
                                                      hidden: false,
                                                      encrypt: false,
                                                      description:
                                                        field.description,
                                                      defaultValue:
                                                        field.type.startsWith(
                                                          "oauth"
                                                        )
                                                          ? (field as any)
                                                              .oauth_url
                                                          : field.default,
                                                    } as IActionOption
                                                  }
                                                  submitting={
                                                    renderProps.isSubmitting
                                                  }
                                                  onClose={() =>
                                                    setReload(cuid())
                                                  }
                                                />
                                              </div>
                                            );
                                          })}
                                        </>
                                      );
                                  }
                                }}
                              </GraphQl>
                            );
                        }
                      }}
                    </GraphQl>
                  );
                } else {
                  return undefined;
                }
              })()}

              {/* Format details */}
              {(() => {
                const formatOptions = getFormatOptionsAvailableForDestination(
                  renderProps.values["destination"],
                  data.data.allActions,
                  data.data.Report.type
                );

                if (formatOptions.length === 0) {
                  return undefined;
                }

                return (
                  <Field
                    component={Select}
                    name="format"
                    formItemProps={{
                      label: "Format",
                    }}
                    inputProps={{
                      options: formatOptions,
                    }}
                    validate={(value) => {
                      const supportedFormats = getFormatsSupportedByDestination(
                        renderProps.values["destination"],
                        data.data.allActions
                      );
                      if (supportedFormats.includes(value)) {
                        return;
                      } else {
                        return "This format is not supported by this destination Please select a supported one!";
                      }
                    }}
                  />
                );
              })()}

              {/* Filter controlled values details */}
              {(() => {
                if (props.report.type === "QUESTION") {
                  // disable for question
                  return null;
                }
                return (
                  <FieldArray
                    name="controlledValues"
                    render={(arrayHelpers) => (
                      <div>
                        {renderProps.values.controlledValues.map(
                          (value, index) => (
                            <div key={index} style={{ marginBottom: 24 }}>
                              <div
                                style={{
                                  padding: "0 0 8px",
                                  lineHeight: "32px",
                                }}
                              >
                                <label>Filter</label>
                              </div>
                              <Space
                                align="center"
                                style={{ width: "100%" }}
                                key={index}
                              >
                                <Field
                                  name={`controlledValues.${index}.filterId`}
                                  component={Select}
                                  formItemProps={{
                                    label: "",
                                    style: { width: 212, marginBottom: 0 },
                                  }}
                                  inputProps={{
                                    size: "normal",
                                    options: filters.map((f) => {
                                      return {
                                        value: f.id,
                                        label: f.name,
                                      };
                                    }),
                                  }}
                                />
                                {(() => {
                                  const foundFilter = filters.find(
                                    (f) =>
                                      f.id ===
                                      renderProps.values.controlledValues[index]
                                        .filterId
                                  );
                                  if (!foundFilter) {
                                    return <div style={{ width: 212 }} />;
                                  }

                                  const flatExplorations = explorations.flatMap(
                                    (ex) =>
                                      ex.tables.flatMap((t) =>
                                        t.dimensions.map((d) => ({
                                          ...d,
                                          explorationId: ex.id,
                                          generatedId: `${t.cubeName}.${d.cubeName}`,
                                        }))
                                      )
                                  );
                                  return (
                                    <Field
                                      name={`controlledValues.${index}.value`}
                                    >
                                      {({ field, form, meta }) => {
                                        return (
                                          <div style={{ width: 212 }}>
                                            <FieldFilter
                                              editing={false}
                                              labelDimension={
                                                foundFilter.labelDimension
                                              }
                                              valueDimension={
                                                foundFilter.valueDimension
                                              }
                                              tilesToUpdate={[]}
                                              requireValue={
                                                foundFilter.requireValue
                                              }
                                              componentType={
                                                foundFilter.componentType
                                              }
                                              filterType={foundFilter.type}
                                              objectId={
                                                flatExplorations.find(
                                                  (e) =>
                                                    e.generatedId ===
                                                    foundFilter.valueDimension
                                                )?.explorationId || ""
                                              }
                                              objectType={"EXPLORATION"}
                                              {...field}
                                              onChange={(s) => {
                                                form.setFieldValue(
                                                  field.name,
                                                  s
                                                );
                                              }}
                                              autocompleteLimit={
                                                foundFilter.filterLimit
                                              }
                                            />
                                          </div>
                                        );
                                      }}
                                    </Field>
                                  );
                                })()}
                                <MinusCircleOutlined
                                  onClick={() => arrayHelpers.remove(index)}
                                />
                              </Space>
                            </div>
                          )
                        )}
                        <Button
                          type="dashed"
                          onClick={() =>
                            arrayHelpers.push({ filterId: "", value: "" })
                          }
                          block
                          icon={<PlusOutlined />}
                        >
                          Set a filter value
                        </Button>
                      </div>
                    )}
                  />
                );
              })()}

              <FormActions
                submitText={"Save"}
                isSubmitting={renderProps.isSubmitting}
              />
            </Form>
          );
        }}
      </Formik>
    );
  };

  return (
    <Drawer
      title="New push"
      placement={"right"}
      width={500}
      onClose={props.onClose}
      open={props.visible}
      destroyOnClose={true}
    >
      {renderForm()}
    </Drawer>
  );
};

export default compose<Props, IScheduleEditDrawerProps>(
  withRouter,
  WithOrg
)(ScheduleEditDrawer);
