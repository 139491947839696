import type { IActionMeta } from "../../../../interfaces/actions";
import type { ISchedule } from "../../../../interfaces/schedule";

export const getActionImg = (
  schedule: ISchedule,
  actionMetas: IActionMeta[]
): string => {
  const emailImg = "https://cdn.whaly.io/actions/mail.png";
  const unknownImg = "https://cdn.whaly.io/actions/unkown-action.png";
  if (schedule.workflow.type === "email_content_delivery") return emailImg;

  const meta = actionMetas.find(
    (actionMeta) => actionMeta.slug === schedule.workflow.action.actionMeta
  );

  if (!meta) return unknownImg;
  return meta.publicInfo.logo;
};

export const getActionDestination = (schedule: ISchedule) => {
  switch (schedule.workflow.type) {
    case "email_content_delivery":
      return schedule.workflow.emails;
    case "action_content_delivery":
      switch (schedule.workflow.action.actionMeta) {
        case "whaly_slack":
          return `Slack`;
        case "whaly_google_sheets":
          return JSON.parse(schedule.workflow.actionFormValues).filename;
        case "whaly_airtable":
          return "Airtable";
        case "whaly_webhook":
          return "Webhook";
        default:
          return "";
      }
    default:
      return "";
  }
};

export const shouldDisplayDestinationFormat = (
  schedule: ISchedule
): boolean => {
  switch (schedule.workflow.type) {
    case "email_content_delivery":
      return true;
    case "action_content_delivery":
      switch (schedule.workflow.action.actionMeta) {
        case "whaly_slack":
          return true;
        case "whaly_google_sheets":
          return false;
        case "whaly_airtable":
          return false;
        case "whaly_webhook":
          return false;
        default:
          return true;
      }
    default:
      return true;
  }
};

export const getActionDestinationUrl = (schedule: ISchedule): string => {
  switch (schedule.workflow.type) {
    case "email_content_delivery":
      return null;
    case "action_content_delivery":
      switch (schedule.workflow.action.actionMeta) {
        case "whaly_slack":
          return `https://slack.com/app_redirect?channel=${
            JSON.parse(schedule.workflow.actionFormValues).channel
          }`;
        case "whaly_google_sheets":
          return null;
        case "whaly_airtable":
          return `https://airtable.com/${
            JSON.parse(schedule.workflow.actionFormValues).base
          }/${JSON.parse(schedule.workflow.actionFormValues).table}`;
        case "whaly_webhook":
          return JSON.parse(schedule.workflow.actionFormValues).url;
        default:
          return "";
      }
    default:
      return "";
  }
};

const renderSeparator = () => {
  return <span className="separator">•</span>;
};

export const renderSchedule = (
  schedule: ISchedule,
  displayTZ: boolean = false,
  separator: () => JSX.Element = renderSeparator
) => {
  const renderAtHour = () => {
    return <span>{schedule.atHour}:00</span>;
  };
  const renderTZ = () => {
    if (!displayTZ) return;
    return <span>({schedule.timezone})</span>;
  };

  switch (schedule.period) {
    case "never":
      return <span>Send now</span>;
    case "hour":
      return <span>Hourly</span>;
    case "day":
      return (
        <span>
          Daily {separator()} {renderAtHour()} {renderTZ()}
        </span>
      );
    case "week":
      switch (schedule.weeklyOnDay) {
        case "monday":
          return (
            <span>
              Mondays {separator()} {renderAtHour()} {renderTZ()}
            </span>
          );
        case "tuesday":
          return (
            <span>
              Tuesdays {separator()} {renderAtHour()} {renderTZ()}
            </span>
          );
        case "wednesday":
          return (
            <span>
              Wednesdays {separator()} {renderAtHour()} {renderTZ()}
            </span>
          );
        case "thursday":
          return (
            <span>
              Thursday {separator()} {renderAtHour()} {renderTZ()}
            </span>
          );
        case "friday":
          return (
            <span>
              Fridays {separator()} {renderAtHour()} {renderTZ()}
            </span>
          );
        case "saturday":
          return (
            <span>
              Staurdays {separator()} {renderAtHour()} {renderTZ()}
            </span>
          );
        case "sunday":
          return (
            <span>
              Sundays {separator()} {renderAtHour()} {renderTZ()}
            </span>
          );
        default:
          return (
            <span>
              Weekly {separator()} {renderAtHour()} {renderTZ()}
            </span>
          );
      }
    case "month":
      switch (schedule.monthlyDayOfMonthPreset) {
        case "first_day":
          return (
            <span>
              First day of the Month {separator()} {renderAtHour()} {renderTZ()}
            </span>
          );
        case "last_day":
          return (
            <span>
              Last day of the Month {separator()} {renderAtHour()} {renderTZ()}
            </span>
          );
        case "nth_day":
          return (
            <span>
              {schedule.monthlyDayOfMonthNumber}th day of the Month{" "}
              {separator()} {renderAtHour()} {renderTZ()}
            </span>
          );
        default:
          return <span>Monthly</span>;
      }
    default:
      return "Unsupported";
  }
};
