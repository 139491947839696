import { Button, Col, Space, Typography } from "antd";
import React from "react";
import type { InjectedAntUtilsProps } from "../../../../../../../components/ant-utils/withAntUtils";
import { withAntUtils } from "../../../../../../../components/ant-utils/withAntUtils";
import { WlyCard } from "../../../../../../../components/cards/WlyCard";
import { compose } from "../../../../../../../components/compose/WlyCompose";
import type { IObject } from "../../../../../../../interfaces/object";
import type { IDataset } from "../../../../../../../interfaces/sources";
import { getObjectColumns } from "../../../../../../v2-demo/container/object/domain";
import type { AvailableProperty } from "../../../../../../v2-demo/container/object/viewer/domain";
import RowLevelFilterDisplay from "../../../../exploration/row-level-filters/RowLevelFilterDisplay";
import RowLevelFilters from "../../../../exploration/row-level-filters/RowLevelFilters";
import type { UpdateObjectFunction } from "../../../../selector/object/domain";
import ObjectQueryModeCard from "../../cards/objectQueryModeCard";
import ObjectModelCard from "./ObjectModelCard";

const { Title } = Typography;

interface IObjectSettingsProps {
  object: IObject;
  allDatasets: IDataset[];
  onUpdateObject: UpdateObjectFunction;
}

type Props = IObjectSettingsProps & InjectedAntUtilsProps;

function ObjectSettings(props: Props) {
  const { object, onUpdateObject, allDatasets } = props;

  const [rowLevelAccess, setRowLevelAccess] = React.useState<boolean>(false);

  const availableProperties = getObjectColumns(object, true)
    .filter((o) => o.type === "property")
    .map<AvailableProperty>((p) => p.data as AvailableProperty);

  return (
    <Col span={16} offset={4}>
      <Space direction="vertical" size={32} style={{ width: "100%" }}>
        <div>
          <Title style={{ paddingTop: 16 }} level={3}>
            {object.name}
          </Title>
        </div>
        <ObjectModelCard
          object={object}
          allDatasets={allDatasets}
          onUpdateObject={onUpdateObject}
        />
        <WlyCard
          title="Row level security"
          extra={
            <Button
              type="primary"
              size="small"
              onClick={() => setRowLevelAccess(true)}
            >
              Edit
            </Button>
          }
        >
          <RowLevelFilterDisplay
            rowLevelFilters={object.rowLevelFilters}
            columns={availableProperties}
          />
          <RowLevelFilters
            visible={rowLevelAccess}
            columns={availableProperties}
            rowLevelFilters={object.rowLevelFilters}
            onClose={() => setRowLevelAccess(false)}
            onSave={async (v) =>
              onUpdateObject(object.id, {
                rowLevelFilters: v,
              }).then(() => setRowLevelAccess(false))
            }
          />
        </WlyCard>
        <ObjectQueryModeCard
          object={object}
          onObjectExtractDataNow={function (objectId: string): Promise<void> {
            throw new Error("Function not implemented.");
          }}
          onObjectUpdate={async (id: string, payload: any): Promise<void> => {
            await onUpdateObject(id, payload);
          }}
        />
        <div style={{ height: 32 }}></div>
      </Space>
    </Col>
  );
}

export default compose<Props, IObjectSettingsProps>(withAntUtils)(
  ObjectSettings
);
