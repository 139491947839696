import {
  DeleteOutlined,
  EditOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import { Button, Card, Flex, Popconfirm, Typography } from "antd";
import React from "react";
import { useHover } from "../../../../../../../components/hooks/useHover";
import { type IObjectRegisteredAction } from "../../../../../../../interfaces/object";

export interface IObjectRegisteredActionCardProps {
  registeredAction: IObjectRegisteredAction;
  onDelete: (params: { id: string }) => void;
  onEdit: () => void;
}

export function ObjectRegisteredActionCard(
  props: IObjectRegisteredActionCardProps
) {
  const { registeredAction, onEdit, onDelete } = props;

  const [hoverRef, isHover] = useHover<HTMLDivElement>();
  const [confirmOpen, setConfirmOpen] = React.useState<boolean>(false);

  return (
    <div ref={hoverRef}>
      <Card size="small">
        <Flex align="center" gap={8}>
          <Flex flex={1}>
            <Typography.Text strong>{registeredAction.name}</Typography.Text>
          </Flex>
          <div style={{ height: 32 }}>
            <div hidden={!isHover && !confirmOpen}>
              <Button
                shape="circle"
                type="text"
                icon={<EditOutlined />}
                onClick={() => onEdit()}
              />
              <Popconfirm
                title="Delete the action"
                description="Are you sure to delete this action?"
                icon={<QuestionCircleOutlined style={{ color: "red" }} />}
                open={confirmOpen}
                onOpenChange={(open) => setConfirmOpen(open)}
                okText="Delete"
                okButtonProps={{ danger: true }}
                onConfirm={() => onDelete({ id: registeredAction.id })}
              >
                <Button
                  shape="circle"
                  type="text"
                  icon={<DeleteOutlined />}
                  onClick={() => setConfirmOpen(true)}
                  danger
                />
              </Popconfirm>
            </div>
          </div>
        </Flex>
      </Card>
    </div>
  );
}
