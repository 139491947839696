import type {
  PivotConfig,
  Query,
  ResultSet,
  TableColumn,
} from "@cubejs-client/core";
import { Button, Modal, Space, Typography } from "antd";
import type { Remote } from "comlink";
import { proxy, wrap } from "comlink";
import _ from "lodash";
import * as React from "react";
import TableChart from "../../../../../../components/chart/table/TableChart";
import { compose } from "../../../../../../components/compose/WlyCompose";
import Loading from "../../../../../../components/layout/feedback/loading";
import type { AvailableMetric } from "../../../../../../components/measures/filter-item/FilterItem";
import { getDefaultCollection } from "../../../../../../components/palette/utils/paletteData";
import type { AsyncData } from "../../../../../../helpers/typescriptHelpers";
import { IUserFeatureType } from "../../../../../../interfaces/user";
import {
  LagoonCallOrigin,
  lagoonServiceLoad,
} from "../../../../../../services/LagoonService";
import { downloadCsv } from "../../../../../../utils/csvDownload";
import type { CubeJSPivotWorker } from "../../../../../../worker/main.worker";
import type { InjectedOrgProps } from "../../../../../orgs/WithOrg";
import WithOrg from "../../../../../orgs/WithOrg";
import "./DrillDown.scss";

interface IDrillDownProps {
  onClose: () => void;
  query: Query;
  availableMetrics: Array<AvailableMetric>;
  explorationId: string;
  reportId?: string;
}

interface IState {
  results: AsyncData<{
    resultSet: ResultSet;
    pivotConfig: PivotConfig;
    columns: TableColumn[];
    tableRows: Array<{
      [key: string]: string | number | boolean;
    }>;
  }>;
}

type Props = IDrillDownProps & InjectedOrgProps;

class DrillDown extends React.Component<Props, IState> {
  originalWorker: Worker;
  worker: Remote<CubeJSPivotWorker>;
  constructor(props: Props) {
    super(props);
    this.originalWorker = new Worker(
      new URL("../../../../../../worker/main.worker", import.meta.url),
      {
        name: "drill-worker",
        type: "module",
      }
    );
    this.worker = wrap<CubeJSPivotWorker>(this.originalWorker);
    this.state = {
      results: {
        status: "initial",
      },
    };
  }

  componentDidMount() {
    const { query, org, explorationId, reportId } = this.props;
    this.fetchData(org.id, query, explorationId, reportId);
  }

  componentDidUpdate(prevProps: Props) {
    const { query, explorationId, org, reportId } = this.props;
    const {
      query: prevQuery,
      explorationId: prevExplorationId,
      org: prevOrg,
    } = prevProps;
    if (
      !_.isEqual(query, prevQuery) ||
      prevExplorationId !== explorationId ||
      org.id !== prevOrg.id
    ) {
      this.fetchData(org.id, query, explorationId, reportId);
    }
  }

  componentWillUnmount(): void {
    this.originalWorker.terminate();
  }

  fetchData = async (
    orgId: string,
    query: Query,
    explorationId: string,
    reportId?: string
  ) => {
    try {
      this.setState({ results: { status: "loading" } });
      const { userFeatures } = this.props;
      const shouldUseLagoonNext = userFeatures.includes(
        IUserFeatureType.TMP_USE_LAGOON_NEXT
      );
      const resultSet = await lagoonServiceLoad(
        orgId,
        query,
        "EXPLORATION",
        explorationId,
        explorationId,
        LagoonCallOrigin.WHALY_APP,
        undefined,
        undefined,
        shouldUseLagoonNext
      );
      if (resultSet instanceof Error) {
        throw new Error(resultSet.message);
      }
      const tmp = resultSet.serialize();
      const pivotC = await this.worker.getPivotConfig(
        proxy(resultSet),
        "CATEGORIES",
        "table",
        []
      );
      const columns = resultSet.tableColumns(pivotC);
      const tableRows = await this.worker.getTableRows(
        tmp,
        pivotC,
        "CATEGORIES"
      );
      this.setState({
        results: {
          status: "success",
          data: {
            resultSet,
            pivotConfig: pivotC,
            columns: columns,
            tableRows: tableRows,
          },
        },
      });
    } catch (err) {
      this.setState({ results: { status: "error", error: err } });
    }
  };

  renderInner = () => {
    const { results } = this.state;
    const {
      org,
      user: { locale },
      reportId,
    } = this.props;
    if (results.status === "initial" || results.status === "loading") {
      return <Loading />;
    }
    if (results.status === "error") {
      return <div>{results.error.message}</div>;
    }

    return (
      <div>
        <div className="records-length">
          <Space>
            <Typography.Text type="secondary">
              {results.data.resultSet.tablePivot().length} records
            </Typography.Text>
            <Button
              onClick={downloadCsv(
                org.id,
                reportId,
                {
                  type: "ResultSet",
                  data: {
                    status: "success",
                    data: results.data.resultSet,
                  },
                },
                "drilldown"
              )}
              size="small"
              style={{ backgroundColor: "transparent" }}
            >
              Download
            </Button>
          </Space>
        </div>
        <div style={{ height: "calc(90vh - 78px)" }}>
          <TableChart
            data={results.data.resultSet}
            analysisType={"CATEGORIES"}
            height={"100%"}
            pivotConfig={results.data.pivotConfig}
            columns={results.data.columns}
            tableRows={results.data.tableRows}
            availableMetrics={this.props.availableMetrics}
            defaultCollection={getDefaultCollection(org)}
            locale={locale}
          />
        </div>
      </div>
    );
  };

  public render() {
    const { onClose } = this.props;
    return (
      <Modal
        onCancel={onClose}
        open={true}
        footer={null}
        width={"90%"}
        title={"Drill down"}
        style={{ top: "5vh", bottom: "5vh" }}
        className="drilldown-modal"
      >
        <div>{this.renderInner()}</div>
      </Modal>
    );
  }
}

export default compose<Props, IDrillDownProps>(WithOrg)(DrillDown);
