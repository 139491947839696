import { useContext, useEffect } from "react";
import type { IObject } from "../../../../../../interfaces/object";
import { HomePageContext } from "../../../home/HomePageContext";
import type { IRecordWidgetData } from "../domain";
import { extractRecordLabel } from "./domain";

interface IRecordHomePageHeaderProps {
  object: IObject;
  data: IRecordWidgetData;
}

export function RecordHomePageHeader(props: IRecordHomePageHeaderProps) {
  const { data, object } = props;

  const { setValue } = useContext(HomePageContext);

  useEffect(() => {
    if (data.status === "loading") {
      setValue?.({ status: "loading" });
    }
    if (data.status === "success") {
      const d = extractRecordLabel(data, object);
      setValue?.({
        status: "success",
        data: { object: object.name, record: d.name ? d.name : d.id },
      });
    }
  }, [data, object]);

  return null;
}
