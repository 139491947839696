import Tracker from "@openreplay/tracker";
import trackerAssist from "@openreplay/tracker-assist";
import * as Sentry from "@sentry/react";
import { sort } from "fast-sort";
import { action, makeObservable, observable } from "mobx";
import type { HomeConfigType } from "../containers/v2-demo/container/home/customize/domain";
import type { ConnectedUserResult, SessionInfo } from "../interfaces/auth";
import type { IUser } from "../interfaces/user";
import { rolesImportance } from "../interfaces/user";
import {
  activeTracking,
  alias,
  group,
  identify,
} from "../services/AnalyticsService";
import StorageService from "../services/StorageService";
import GraphQLService from "../services/graphql/GraphQLService";
import {
  getConnectedUser,
  registerReportView,
  registerUserLogin,
} from "../services/userService";

class UserStore {
  user: IUser | undefined;
  session: SessionInfo;
  loading: boolean;
  updating: boolean;
  openreplay: any;

  constructor() {
    makeObservable(this, {
      user: observable,
      session: observable,
      loading: observable,
      updating: observable,
      saveHomeCover: action,
      registerViewedReport: action,
      getUser: action,
      updateUser: action,
      logout: action,
    });

    this.loading = false;
    this.updating = false;

    if (activeTracking) {
      // openreplay
      this.openreplay = new Tracker({
        projectKey: "7292801344558635",
        onStart: ({ sessionToken }) => {
          Sentry.setTag("openReplaySessionToken", sessionToken);
          Sentry.setTag(
            "openReplayUrl",
            `https://app.openreplay.com/3805/session/${this.openreplay.getSessionID()}`
          );
        },
      });
    }
  }

  saveHomeCover = (userRoleId: string, url: string, position?: number) => {
    const homeCover = JSON.stringify({ url: url, position: position });

    this.user = {
      ...this.user,
      roles: this.user.roles.map((r) => {
        if (r.id === userRoleId) {
          return {
            ...r,
            homeCover,
          };
        }
        return r;
      }),
    };

    return GraphQLService(
      `
      mutation updateHomeCover($userRoleId: ID!, $homeCover: String) {
        updateUserRole(id: $userRoleId, data: { homeCover: $homeCover }) {
          id
        }
      }
    `,
      {
        userRoleId: userRoleId,
        homeCover: homeCover,
      }
    );
  };

  saveHomeConfig = (userRoleId: string, config: HomeConfigType) => {
    const homeConfig = JSON.stringify(config);

    this.user = {
      ...this.user,
      roles: this.user.roles.map((r) => {
        if (r.id === userRoleId) {
          return {
            ...r,
            v2HomeConfig: homeConfig,
          };
        }
        return r;
      }),
    };

    return GraphQLService(
      `
      mutation updateHomeConfig($userRoleId: ID!, $home: String) {
        updateUserRole(id: $userRoleId, data: { v2HomeConfig: $home }) {
          id
        }
      }
    `,
      {
        userRoleId: userRoleId,
        home: homeConfig,
      }
    );
  };

  registerViewedReport = (orgId: string, reportId: string) => {
    if (!StorageService.getItem("_wly_disable_tracking")) {
      registerReportView(orgId, reportId);
      this.user.roles = this.user.roles.map((r) => {
        if (r.org.id === orgId) {
          const newReportList = r.lastVisitedReports
            ? r.lastVisitedReports.split(",")
            : [];
          let array: string[] = [];
          if (newReportList.find((r) => r === reportId.toString())) {
            const index = newReportList.findIndex(
              (r) => r === reportId.toString()
            );
            const temp = [...newReportList];
            temp.splice(index, 1);
            array = [reportId, ...temp];
          } else {
            array = [reportId, ...newReportList];
          }

          return {
            ...r,
            lastVisitedReports: array.join(","),
          };
        }
        return r;
      });
    }
  };

  getUser = () => {
    this.loading = true;
    const that = this;
    return getConnectedUser()
      .then(
        action((connectedUserRes: ConnectedUserResult) => {
          const userRes = connectedUserRes.me;
          const user = {
            ...userRes,
            roles: sort(userRes.roles).asc((role) =>
              role.org.name.toLowerCase()
            ),
          };

          this.user = user;
          this.session = connectedUserRes.session;
          if (activeTracking) {
            Sentry.setUser({
              email: userRes.email,
              id: userRes.id,
              first_name: userRes.firstName,
              last_name: userRes.lastName,
              isPublicGuestUser: userRes.isPublicGuestUser,
              isAdmin: userRes.isAdmin,
            });
            if (this.openreplay && !userRes.isPublicGuestUser) {
              if (!this.openreplay.isActive()) {
                this.openreplay.start({
                  userID: userRes.email,
                  metadata: {
                    user_id: userRes.id,
                    user_name: `${userRes.firstName} ${userRes.lastName}`,
                  },
                });
                this.openreplay.use(trackerAssist());
              }
            }
            if (!(this.user.isPublicGuestUser || this.session.isSudoSession)) {
              const userRoles = userRes.roles.map((r) => {
                return r.role;
              });
              const descRoles = sort(userRoles).desc(
                (tag) => rolesImportance[tag] || 0
              );
              const maxRole = descRoles.length > 0 ? descRoles[0] : null;
              identify(userRes.id, {
                // default
                email: userRes.email,
                firstName: userRes.firstName,
                lastName: userRes.lastName,
                // custom
                displayName: `${userRes.firstName} ${userRes.lastName}`,
                last_login_date: new Date(),
                isAdmin: userRes.isAdmin,
                maxRole: maxRole,
              });
              alias(userRes.id, userRes.email);
              userRes.roles.forEach((rle) => {
                return group(rle.org.id, {
                  // default
                  name: rle.org.name,
                  // custom
                  status: rle.org.status,
                });
              });

              // wootric NPS
              if (this.user.type === "STANDARD") {
                let setupScript = document.createElement("script");
                setupScript.type = "text/javascript";
                setupScript.id = "wootric-settings";
                setupScript.async = true;
                setupScript.innerHTML = `
                  window.wootricSettings = {
                    'email': '${this.user.email}',
                    'created_at': ${Math.round(
                      new Date(this.user.createdAt).getTime() / 1000
                    )},
                    'external_id': '${this.user.id}',
                    'account_token': 'NPS-74d3ca10'
                  };
                `;
                if (document.getElementById("wootric-settings") == null) {
                  document.body.appendChild(setupScript);
                }
                let beacon = document.createElement("script");
                beacon.type = "text/javascript";
                beacon.id = "wootric-beacon";
                beacon.async = true;
                beacon.src = "https://cdn.wootric.com/wootric-sdk.js";
                beacon.onload = function () {
                  (window as any).wootric("run");
                };
                if (document.getElementById("wootric-beacon") == null) {
                  document.body.appendChild(beacon);
                }
              }
            }
          }
          registerUserLogin(
            connectedUserRes.me.id,
            connectedUserRes.me.lastLoginDate
          );
          return connectedUserRes;
        })
      )
      .then(
        action(() => {
          that.loading = false;
        })
      );
  };

  updateUser = (newUser: IUser) => {
    this.updating = true;
    return Promise.resolve();
  };

  logout = () => {
    this.user = undefined;
    Sentry.configureScope((scope) => scope.setUser(null));
    if (this.openreplay && this.openreplay.isActive()) {
      this.openreplay.stop();
    }
  };
}

export interface UserStoreProps {
  userStore: UserStore;
}

export default new UserStore();
