import {
  ExclamationCircleOutlined,
  MinusCircleOutlined,
} from "@ant-design/icons";
import type { BinaryFilter, BinaryOperator } from "@cubejs-client/core";
import { Col, Row, Select, Tooltip } from "antd";
import * as React from "react";
import type { AvailableDimension } from "../../../../../components/measures/filter-item/FilterItem";
import type { IUserAttributeMeta } from "../../../../../interfaces/org";
import type { DataType } from "../../../../../interfaces/transformations";
import type { AvailableProperty } from "../../../../v2-demo/container/object/viewer/domain";

interface RowLevelFilterInputProps {
  filter: BinaryFilter;
  columns: Array<AvailableDimension | AvailableProperty>;
  onDelete: () => void;
  onChange: (filter: BinaryFilter) => void;
  attributeMetas: Array<IUserAttributeMeta>;
  className?: string;
  disabled?: boolean;
}

const availableOperators = [
  {
    operator: "equals",
    label: "Equals",
  },
  {
    operator: "notEquals",
    label: "Does not equal",
  },
];

export default class RowLevelFilterInput extends React.Component<RowLevelFilterInputProps> {
  id: string;

  public render() {
    const {
      columns,
      onDelete,
      filter,
      onChange,
      className,
      disabled,
      attributeMetas,
    } = this.props;

    const selectedDimension = columns.find(
      (ad) => ad.key === (filter as any).member
    );

    const selectedUserAttribute = attributeMetas.find(
      (am) => am.id === filter.values[0]
    );

    let content: React.ReactElement | null = null;

    const renderType = (type?: DataType) => {
      if (type === "TIME") {
        return "Time";
      }
      if (type === "NUMERIC") {
        return "Number";
      }
      if (type === "BOOLEAN") {
        return "Boolean";
      }
      return "String";
    };

    if (
      !selectedDimension ||
      !selectedUserAttribute ||
      selectedDimension.domain !== selectedUserAttribute.type
    ) {
      let text = "";
      if (!selectedDimension) {
        text = "This dimension was deleted";
      } else if (!selectedUserAttribute) {
        text = "This user attributes seems deleted";
      } else if (selectedDimension.domain !== selectedUserAttribute.type) {
        text = "Dimension type and attribute type do not match...";
      }
      content = (
        <>
          <div style={{ flex: 1 }}>
            <Select
              size="small"
              disabled
              style={{
                width: "100%",
                border: "1px solid red",
                borderRadius: 6,
              }}
              bordered={false}
              value={text}
            />
          </div>
          <div style={{ flex: "0 0 26px" }}>
            <div className="remove" onClick={disabled ? undefined : onDelete}>
              <MinusCircleOutlined />
            </div>
          </div>
        </>
      );
    } else {
      const operators = availableOperators;
      content = (
        <>
          <div
            style={{
              flex: 1,
            }}
          >
            <Row gutter={6}>
              <Col span={12}>
                <Select
                  size="small"
                  style={{ width: "100%" }}
                  defaultValue={columns[0].key}
                  value={(filter as any).member}
                  showSearch={true}
                  optionFilterProp="children"
                  popupMatchSelectWidth={false as any}
                  disabled={disabled}
                  onChange={(v) => {
                    onChange({
                      ...filter,
                      member: v,
                    });
                  }}
                >
                  {columns.map((ad) => {
                    const hasAttributesOfType = attributeMetas.find((uam) => {
                      if (ad.type === "standard" && ad.domain) {
                        return uam.type === ad.domain;
                      }
                      return uam.type === "STRING";
                    });
                    return (
                      <Select.Option
                        disabled={!hasAttributesOfType}
                        key={ad.key}
                        value={ad.key}
                      >
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                          }}
                        >
                          <div style={{ flex: 1 }}>
                            {ad.label ? ad.label : "Empty String"}{" "}
                          </div>
                          {!hasAttributesOfType && (
                            <div style={{ flex: 0 }}>
                              <Tooltip
                                title={`You have no attributes of type ${renderType(
                                  ad.type === "standard" ? ad.domain : undefined
                                )}`}
                              >
                                <ExclamationCircleOutlined />
                              </Tooltip>
                            </div>
                          )}
                        </div>
                      </Select.Option>
                    );
                  })}
                </Select>
              </Col>
              <Col span={12}>
                <Select
                  size="small"
                  style={{ width: "100%" }}
                  defaultValue={operators[0].operator}
                  value={filter.operator}
                  popupMatchSelectWidth={false as any}
                  disabled={disabled}
                  onChange={(v) => {
                    onChange({
                      ...filter,
                      operator: v as BinaryOperator,
                    } as BinaryFilter);
                  }}
                >
                  {operators.map((o) => {
                    return (
                      <Select.Option key={o.operator} value={o.operator}>
                        {o.label}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Col>
            </Row>
            <Row>
              <Col span={24} style={{ paddingTop: 6 }}>
                <Select
                  size={"small"}
                  popupMatchSelectWidth={false as any}
                  disabled={disabled}
                  style={{ width: "100%" }}
                  value={filter.values[0]}
                  onChange={(v) => {
                    onChange({
                      ...filter,
                      values: [v],
                    } as BinaryFilter);
                  }}
                >
                  {attributeMetas.map((uam) => {
                    const disabled = selectedDimension.domain !== uam.type;
                    return (
                      <Select.Option
                        disabled={disabled}
                        key={uam.id}
                        value={uam.id}
                      >
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                          }}
                        >
                          <div style={{ flex: 1 }}>{uam.label} </div>
                          {disabled && (
                            <div style={{ flex: 0 }}>
                              <Tooltip
                                title={`Selected dimension is not of type ${renderType(
                                  uam.type
                                )}`}
                              >
                                <ExclamationCircleOutlined />
                              </Tooltip>
                            </div>
                          )}
                        </div>
                      </Select.Option>
                    );
                  })}
                </Select>
              </Col>
            </Row>
          </div>
          <div
            style={{
              display: "flex",
              flex: "0 0 26px",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div className="remove" onClick={disabled ? undefined : onDelete}>
              <MinusCircleOutlined />
            </div>
          </div>
        </>
      );
    }

    return (
      <div id={this.id} className={`filter-item ${className}`}>
        {content}
      </div>
    );
  }
}
