import type { ActionEvent } from "@projectstorm/react-canvas-core";
import { Action, InputType, State } from "@projectstorm/react-canvas-core";
import type { DiagramEngine } from "@projectstorm/react-diagrams-core";
import { DragDiagramItemsState } from "@projectstorm/react-diagrams-core";
import type { MouseEvent } from "react";
import { WlyDragCanvasState } from "./WlyDragState";

export class WlyDefaultState extends State<DiagramEngine> {
  dragCanvas: WlyDragCanvasState;
  dragItems: DragDiagramItemsState;

  constructor() {
    super({ name: "starting-state" });
    // we set childStates as empty to prevent multiple selections
    this.childStates = [];
    this.dragCanvas = new WlyDragCanvasState();
    this.dragItems = new DragDiagramItemsState();

    // determine what was clicked on
    this.registerAction(
      new Action({
        type: InputType.MOUSE_DOWN,
        fire: (event: ActionEvent<MouseEvent>) => {
          const element = this.engine
            .getActionEventBus()
            .getModelForEvent(event);
          // the canvas was clicked on, transition to the dragging canvas state
          if (!element) {
            this.transitionWithEvent(this.dragCanvas, event);
          }
          // initiate dragging a new link
          else {
            this.transitionWithEvent(this.dragItems, event);
          }
        },
      })
    );
  }
}
