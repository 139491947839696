import { Button } from "antd";
import type { DatePickerProps } from "antd/lib/date-picker";
import type { FormItemProps } from "antd/lib/form";
import type { InputProps } from "antd/lib/input";
import type { SwitchProps } from "antd/lib/switch";
import { Field } from "formik";
import type { SelectProps } from "rc-select";
import * as React from "react";
import type {
  ICatalogItemOption,
  ICatalogValue,
} from "../../../interfaces/catalog";
import { GoogleButton } from "../../button/GoogleButton";
import DatePicker from "../../form/elements/datePicker/DatePicker";
import Input from "../../form/elements/input/Input";
import Select from "../../form/elements/select/Select";
import Switch from "../../form/elements/switch/Switch";
import { isFloat, isInt, isRequired } from "../../form/validators/validators";
import Popup from "../../popup/Popup";

interface ISourceFormElementProps {
  catalogItemOption: ICatalogItemOption;
  catalogValue?: ICatalogValue;
  submitting?: boolean;
  onClose?: () => void;
}

export class CatalogFormElement extends React.Component<ISourceFormElementProps> {
  render() {
    const props = this.props;
    switch (props.catalogItemOption.type) {
      case "boolean":
        return (
          <Field
            type={props.catalogItemOption.type}
            name={props.catalogItemOption.key}
            component={Switch}
            switchProps={
              {
                disabled:
                  !!props.submitting || !props.catalogItemOption.editable,
              } as SwitchProps
            }
            style={{ paddingBottom: 0 }}
            formItemProps={
              {
                label: props.catalogItemOption.label,
                hasFeedback: true,
                colon: false,
                required: props.catalogItemOption.required,
                extra: (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: props.catalogItemOption.description,
                    }}
                  />
                ),
              } as FormItemProps
            }
          />
        );
      case "date":
        return (
          <Field
            type={props.catalogItemOption.type}
            name={props.catalogItemOption.key}
            component={DatePicker}
            validate={props.catalogItemOption.required ? isRequired : null}
            inputProps={
              {
                disabled:
                  !!props.submitting || !props.catalogItemOption.editable,
              } as DatePickerProps
            }
            formItemProps={
              {
                label: props.catalogItemOption.label,
                hasFeedback: true,
                colon: false,
                required: props.catalogItemOption.required,
                extra: (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: props.catalogItemOption.description,
                    }}
                  />
                ),
              } as FormItemProps
            }
          />
        );
      case "float":
        return (
          <Field
            type={props.catalogItemOption.type}
            name={props.catalogItemOption.key}
            component={Input}
            validate={
              props.catalogItemOption.required ? isRequired && isFloat : isFloat
            }
            inputProps={
              {
                placeholder: "Please input a number",
                disabled:
                  !!props.submitting || !props.catalogItemOption.editable,
              } as InputProps
            }
            formItemProps={
              {
                label: props.catalogItemOption.label,
                hasFeedback: true,
                colon: false,
                required: props.catalogItemOption.required,
                extra: (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: props.catalogItemOption.description,
                    }}
                  />
                ),
              } as FormItemProps
            }
          />
        );
      case "int":
        return (
          <Field
            type={props.catalogItemOption.type}
            name={props.catalogItemOption.key}
            component={Input}
            validate={
              props.catalogItemOption.required ? isRequired && isInt : isInt
            }
            inputProps={
              {
                placeholder: "Please input a number",
                disabled:
                  !!props.submitting || !props.catalogItemOption.editable,
              } as InputProps
            }
            formItemProps={
              {
                label: props.catalogItemOption.label,
                hasFeedback: true,
                colon: false,
                required: props.catalogItemOption.required,
                extra: (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: props.catalogItemOption.description,
                    }}
                  />
                ),
              } as FormItemProps
            }
          />
        );
      case "password":
        return (
          <Field
            type={props.catalogItemOption.type}
            name={props.catalogItemOption.key}
            component={Input}
            validate={props.catalogItemOption.required ? isRequired : null}
            inputProps={
              {
                disabled:
                  !!props.submitting || !props.catalogItemOption.editable,
                type: "password",
              } as InputProps
            }
            formItemProps={
              {
                label: props.catalogItemOption.label,
                hasFeedback: true,
                colon: false,
                required: props.catalogItemOption.required,
                extra: (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: props.catalogItemOption.description,
                    }}
                  />
                ),
              } as FormItemProps
            }
          />
        );
      case "textarea":
      case "string":
        return (
          <Field
            type={props.catalogItemOption.type}
            name={props.catalogItemOption.key}
            component={Input}
            validate={props.catalogItemOption.required ? isRequired : null}
            inputProps={
              {
                disabled:
                  !!props.submitting || !props.catalogItemOption.editable,
              } as InputProps
            }
            formItemProps={
              {
                label: props.catalogItemOption.label,
                hasFeedback: true,
                colon: false,
                required: props.catalogItemOption.required,
                extra: (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: props.catalogItemOption.description,
                    }}
                  />
                ),
              } as FormItemProps
            }
          />
        );
      case "select":
        const generateOptions = () => {
          if (props.catalogItemOption.options) {
            if (typeof props.catalogItemOption.options === "string") {
              return props.catalogItemOption.options
                .split(",")
                .map((v) => ({ label: v, value: v }));
            } else {
              return props.catalogItemOption.options;
            }
          }
        };
        return (
          <Field
            type={props.catalogItemOption.type}
            name={props.catalogItemOption.key}
            component={Select}
            validate={props.catalogItemOption.required ? isRequired : null}
            inputProps={
              {
                disabled:
                  !!props.submitting || !props.catalogItemOption.editable,
                options: generateOptions(),
              } as SelectProps
            }
            formItemProps={
              {
                label: props.catalogItemOption.label,
                hasFeedback: true,
                colon: false,
                required: props.catalogItemOption.required,
                extra: (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: props.catalogItemOption.description,
                    }}
                  />
                ),
              } as FormItemProps
            }
          />
        );
      case "oauth_link_google":
        return (
          <div
            style={{
              marginBottom: "24px",
            }}
          >
            <div>{props.catalogItemOption.description}</div>
            <Popup
              url={props.catalogItemOption.defaultValue}
              title={`Oauth`}
              width={500}
              height={750}
              onClose={this.props.onClose}
            >
              <GoogleButton />
            </Popup>
          </div>
        );
      case "oauth_link":
        return (
          <div
            style={{
              marginBottom: "24px",
            }}
          >
            <div>{props.catalogItemOption.description}</div>
            <Popup
              url={props.catalogItemOption.defaultValue}
              title={`Oauth`}
              width={500}
              height={750}
              onClose={this.props.onClose}
            >
              <Button type="default">{props.catalogItemOption.label}</Button>
            </Popup>
          </div>
        );
      default:
        return <div>not supported</div>;
    }
  }
}
