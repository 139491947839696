import type { NodeModelGenerics } from "@projectstorm/react-diagrams";
import { NodeModel, PortModelAlignment } from "@projectstorm/react-diagrams";
import { SimplePortModel } from "../port/SimplePortModel";
import type { MetricData } from "./TeamMetricWidget";
import { HEIGHT, WIDTH } from "./TeamMetricWidget";

// prettier-ignore
export class TeamMetricModel extends NodeModel<NodeModelGenerics> {

  data: MetricData;

  hasInPort = false;
  hasOutPort = true;

  constructor() {
    super({
      type: "team-metric",
    });
    const port = new SimplePortModel(PortModelAlignment.RIGHT);
    port.setDirection("out");
    port.setMaximumLinks(1);
    this.addPort(port);

    this.width = WIDTH;
    this.height = HEIGHT;
  }

  bindData = (data: MetricData) => {
    this.data = data
  }

  createInPort = () => {
    const port = new SimplePortModel(PortModelAlignment.LEFT);
    port.setDirection("in");
    this.addPort(port);
    this.hasInPort = true;
  }

  removeOutPort = () => {
    this.hasOutPort = false
  }
  
  getPort = (name: string): SimplePortModel => {
    return super.getPort(name) as SimplePortModel;
  };

  getPrimaryInPort = () => {
    return this.getPort(PortModelAlignment.LEFT);;
  };

  getSecondaryInPort = () => {
    return undefined;
  };

  getOutPort = () => {
    return this.getPort(PortModelAlignment.RIGHT);
  };

  hasWarning = () => {
    return false;
  };

}
