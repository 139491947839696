import { Typography } from "antd";

export interface IListProps {}

export function List(props: React.PropsWithChildren<IListProps>) {
  const { children } = props;
  return (
    <Typography.Paragraph>
      {/* <ul>
        {(Array.isArray(children) ? children : []).map((c, i) => {
          return <li key={i}>{c}</li>;
        })}
      </ul> */}
      <ul>{children}</ul>
    </Typography.Paragraph>
  );
}
