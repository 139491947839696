import type { TCubeMemberType } from "@cubejs-client/core";
import type moment from "moment";
import type { IComparisonPeriod } from "../components/measures/comparison-selector/ComparisonSelector";
import type { DataType } from "../interfaces/transformations";

export const convertDatatypes = (type: DataType): TCubeMemberType => {
  switch (type) {
    case "BOOLEAN":
      return "boolean";
    case "NUMERIC":
      return "number";
    case "STRING":
      return "string";
    case "TIME":
      return "time";
  }
};

export const getComparisonDate = (
  dates: [moment.Moment, moment.Moment],
  comparison: IComparisonPeriod
): [moment.Moment, moment.Moment] => {
  if (comparison === "previous") {
    // +1 to include the start period
    const period = dates[1].diff(dates[0], "days") + 1;
    return [
      dates[0].clone().subtract(period, "days"),
      dates[1].clone().subtract(period, "days"),
    ];
  }
  return [
    dates[0].clone().subtract(1, comparison),
    dates[1].clone().subtract(1, comparison),
  ];
};

export const getTimezone = () => {
  return Intl ? Intl.DateTimeFormat().resolvedOptions().timeZone : undefined;
};
