import { Button, Flex, Form, Space } from "antd";
import update from "immutability-helper";
import _ from "lodash";
import { useCallback, useEffect, useState } from "react";
import type { InjectedOrgProps } from "../../containers/orgs/WithOrg";
import WithOrg from "../../containers/orgs/WithOrg";
import { generateUniqueId } from "../../utils/uniqueId";
import type { InjectedAntUtilsProps } from "../ant-utils/withAntUtils";
import { withAntUtils } from "../ant-utils/withAntUtils";
import { compose } from "../compose/WlyCompose";
import type { IConditionalFormatterRule } from "./domain";
import WlyConditionalFormatterRuleCard from "./WlyConditionalFormatterRuleCard";
import WlyConditionalFormatterRuleForm from "./WlyConditionalFormatterRuleForm";

export interface IWlyConditionalFormatterFormOptions {
  fontColor?: boolean;
  backgroundColor?: boolean;
  overrideValue?: boolean;
}

interface IWlyConditionalFormatterProps {
  options: IWlyConditionalFormatterFormOptions;
  value?: IConditionalFormatterRule[];
  onChange?: (value: IConditionalFormatterRule[]) => void;
}

type Props = IWlyConditionalFormatterProps &
  InjectedOrgProps &
  InjectedAntUtilsProps;

const WlyConditionalFormatterForm = (props: Props) => {
  const { antUtils, options, value, onChange } = props;

  // we need to copy rules in a state to access it for moving / editing rules
  const [rules, setRules] = useState<IConditionalFormatterRule[]>(value);
  const [open, setOpen] = useState<{
    open: boolean;
    initialValues: IConditionalFormatterRule;
  }>({
    open: false,
    initialValues: undefined,
  });

  useEffect(() => {
    if (onChange && rules) {
      onChange(rules);
    }
  }, [rules]);

  const moveCard = useCallback((dragIndex: number, hoverIndex: number) => {
    setRules((prevRules: IConditionalFormatterRule[]) =>
      update(prevRules, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, prevRules[dragIndex] as IConditionalFormatterRule],
        ],
      })
    );
  }, []);

  const renderRuleCard = useCallback(
    (
      rule: IConditionalFormatterRule,
      onDelete: (ruleKey: string) => void,
      onEdit: (ruleKey: string) => void,
      index: number
    ) => {
      return (
        <WlyConditionalFormatterRuleCard
          rule={rule}
          key={rule.id}
          index={index}
          moveCard={moveCard}
          onDelete={onDelete}
          onEdit={onEdit}
        />
      );
    },
    [moveCard]
  );

  const showRuleModal = (ruleKey?: string) => {
    let defaultValue: IConditionalFormatterRule = null;
    if (ruleKey) {
      defaultValue = rules.find((r) => r.id === ruleKey);
    } else {
      defaultValue = {
        id: generateUniqueId(),
        type: "single",
        condition: {
          operator: "equals",
          value: null,
        },
        format: {
          fontColor: "#7ED321",
          backgroundColor: null,
          overrideValue: {
            type: "text",
            value: null,
          },
        },
      };
    }
    setOpen({
      open: true,
      initialValues: defaultValue,
    });
  };

  const hideRuleForm = () => {
    setOpen({
      open: false,
      initialValues: undefined,
    });
  };

  return (
    <Form.Provider
      onFormFinish={(name, { values, forms }) => {
        if (name === "ruleForm") {
          if (!rules) {
            setRules([values as any]);
          } else if (rules.find((rule) => rule.id === values.id)) {
            setRules(
              rules.map((rule) => {
                if (rule.id === values.id) {
                  return values as any;
                } else {
                  return rule;
                }
              })
            );
          } else {
            setRules([...rules, values as any]);
          }
          hideRuleForm();
        }
      }}
    >
      <Form
        name="ConditionalFormatterForm"
        layout="vertical"
        hidden={open.open}
      >
        <Space direction="vertical" style={{ width: "100%" }}>
          <Form.Item shouldUpdate={true} noStyle>
            {({ setFieldsValue }) => {
              return rules?.length > 0 ? (
                <>
                  {rules.map((rule, i) => {
                    const onDelete = (ruleKey) => {
                      setFieldsValue({
                        rules: [...rules.filter((r) => r.id !== ruleKey)],
                      });
                      setRules([...rules.filter((r) => r.id !== ruleKey)]);
                    };
                    const onEdit = (ruleKey) => {
                      showRuleModal(ruleKey);
                    };
                    return renderRuleCard(rule, onDelete, onEdit, i);
                  })}
                </>
              ) : (
                <div
                  style={{
                    paddingTop: 12,
                    paddingRight: 12,
                    paddingLeft: 12,
                  }}
                >
                  Add rules to update colors based on your conditions
                </div>
              );
            }}
          </Form.Item>
          <div style={{ padding: 12 }}>
            <Button block onClick={() => showRuleModal()} type="dashed">
              Add {rules?.length > 0 ? "another" : "a"} rule
            </Button>
          </div>
          <div style={{ padding: "0px 12px" }}>
            <Flex gap={12} align="center" justify="space-between">
              <Button
                block
                disabled={!rules || rules?.length === 0}
                onClick={() => {
                  navigator.clipboard.writeText(
                    JSON.stringify({
                      type: "WlyFormatterRules",
                      options: options,
                      value: value,
                    })
                  );
                  antUtils.message.info("Rules copied to clipboard");
                }}
                type="dashed"
              >
                Copy rules
              </Button>
              <Button
                block
                onClick={async () => {
                  try {
                    const text = await navigator.clipboard.readText();
                    const pastedRules: {
                      type?: string;
                      options?: IWlyConditionalFormatterFormOptions;
                      value?: IConditionalFormatterRule[];
                    } = JSON.parse(text);
                    if (
                      !pastedRules ||
                      !pastedRules?.type ||
                      !pastedRules?.options ||
                      !pastedRules?.value ||
                      pastedRules?.type !== "WlyFormatterRules" ||
                      !_.isEqual(options, pastedRules?.options)
                    ) {
                      throw new Error();
                    } else {
                      setRules([
                        ...pastedRules.value.map((r) => ({
                          ...r,
                          id: generateUniqueId(),
                        })),
                        ...(rules ? rules : []),
                      ]);
                      antUtils.message.success("Rules pasted");
                    }
                  } catch (error) {
                    antUtils.message.error("Error while pasting rules");
                  }
                }}
                type="dashed"
              >
                Paste rules
              </Button>
            </Flex>
          </div>
        </Space>
      </Form>
      <div style={{ padding: 12 }} hidden={!open.open}>
        <WlyConditionalFormatterRuleForm
          options={options}
          open={open.open}
          initialValues={open.initialValues}
          onCancel={hideRuleForm}
        />
      </div>
    </Form.Provider>
  );
};

export default compose<Props, IWlyConditionalFormatterProps>(
  WithOrg,
  withAntUtils
)(WlyConditionalFormatterForm);
