import {
  AlignCenterOutlined,
  AlignLeftOutlined,
  AlignRightOutlined,
} from "@ant-design/icons";
import { Form, Input, Radio, Select, Space } from "antd";
import { ChartOptionCollapse } from "../../../../../../../chart-options/components/ChartOptionCollapse";
import ChartOptionLine from "../../../../../../../chart-options/components/ChartOptionLine";
import { getObjectColumns } from "../../../../../object/domain";
import type { IWidgetEmailEditorProps } from "../../domain";
import type { IEmailWidgetButtonConfig } from "../domain";

interface IEmailWidgetButtonEditorProps
  extends IWidgetEmailEditorProps<IEmailWidgetButtonConfig> {}

export function EmailWidgetButtonEditor(props: IEmailWidgetButtonEditorProps) {
  const { object } = props;

  const cols = getObjectColumns(object);
  const keys = cols
    .filter(
      (c) =>
        c.type === "property" &&
        (c.data.type === "foreignKey" || c.data.type === "primaryKey")
    )
    .map((c) => c.data);

  return (
    <>
      <ChartOptionCollapse dark title="Button Configuration">
        <Space size="middle" style={{ width: "100%" }} direction="vertical">
          <ChartOptionLine
            items={[
              {
                content: <div>Align</div>,
                flex: `1`,
              },
              {
                content: (
                  <Form.Item name={["config", "align"]} noStyle>
                    <Radio.Group
                      options={[
                        {
                          value: "left",
                          label: <AlignLeftOutlined />,
                        },
                        {
                          value: "center",
                          label: <AlignCenterOutlined />,
                        },
                        {
                          value: "right",
                          label: <AlignRightOutlined />,
                        },
                      ]}
                      optionType="button"
                      buttonStyle="solid"
                    />
                  </Form.Item>
                ),
                flex: `0 auto`,
              },
            ]}
          />
          <ChartOptionLine
            items={[
              {
                content: <div>Spacing</div>,
                flex: `1`,
              },
              {
                content: (
                  <Form.Item
                    initialValue={"normal"}
                    name={["config", "spacing"]}
                    noStyle
                  >
                    <Select style={{ width: "100%" }}>
                      <Select.Option value="normal">Normal</Select.Option>
                      <Select.Option value="compact">Compact</Select.Option>
                    </Select>
                  </Form.Item>
                ),
                flex: `1`,
              },
            ]}
          />
          <ChartOptionLine
            items={[
              {
                content: <div>Text</div>,
                flex: `0 auto`,
              },
              {
                content: (
                  <Form.Item name={["config", "text"]} noStyle>
                    <Input style={{ width: "100%" }} />
                  </Form.Item>
                ),
                flex: 1,
              },
            ]}
          />
          <ChartOptionLine
            items={[
              {
                content: <div>Link to</div>,
                flex: `0 auto`,
              },
              {
                content: (
                  <Form.Item name={["config", "href"]} noStyle>
                    <Select style={{ width: "100%" }}>
                      {keys.map((k) => {
                        return (
                          <Select.Option key={k.key} value={k.key}>
                            {k.label}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                ),
                flex: 1,
              },
            ]}
          />
        </Space>
      </ChartOptionCollapse>
    </>
  );
}
