import { Badge, Space } from "antd";
import * as React from "react";
import { SourceImageRenderer } from "../sources/SourceImageRenderer";

import "./SourceTableSelectionCard.scss";

const { Ribbon } = Badge;
interface ISourceTableSelectionCardProps {
  logo?: string;
  isModel: boolean;
  primaryKey: string;
  name: string;
  sourceName: string;
  onClick?: () => void;
  isManagedByDbt?: boolean;
  children?: React.ReactNode;
}

export default class SourceTableSelectionCard extends React.Component<ISourceTableSelectionCardProps> {
  public render() {
    const {
      logo,
      sourceName,
      name,
      isModel,
      children,
      primaryKey,
      isManagedByDbt,
      onClick,
    } = this.props;

    const isDisabled = !primaryKey || primaryKey.length === 0;
    const wrapInError = (c: React.ReactNode) => {
      if (isDisabled) {
        return (
          <Ribbon
            color="orange"
            text={isDisabled ? "Missing primary key" : undefined}
          >
            {c}
          </Ribbon>
        );
      }
      return c;
    };
    return wrapInError(
      <div
        className={`source-table-selection-card ${
          isDisabled ? "disabled" : ""
        }`}
        onClick={onClick && !isDisabled ? onClick : undefined}
      >
        <div className="source-table-selection-logo-wrapper">
          <SourceImageRenderer
            alt={sourceName}
            className="source-table-selection-logo"
            img={logo}
            isModel={isModel}
            size={48}
          />
        </div>
        <div
          className="source-table-selection-description-wrapper"
          style={!logo ? { paddingLeft: 24 } : {}}
        >
          <div className="source-table-selection-table-name">
            <Space>
              {isManagedByDbt && (
                <img
                  src="https://cdn.whaly.io/modelling_engines/dbt.png"
                  style={{ height: 16, width: 16, marginBottom: 4 }}
                />
              )}
              {name}
            </Space>
          </div>
          <div className="source-table-selection-source-name">{sourceName}</div>
          {children}
        </div>
      </div>
    );
  }
}
