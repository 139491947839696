import { LoadingOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import { Button, Divider, Drawer, Space, Tag, Tooltip } from "antd";
import type { ColumnsType } from "antd/lib/table";
import Table from "antd/lib/table";
import _ from "lodash";
import React from "react";
import type { InjectedAntUtilsProps } from "../../components/ant-utils/withAntUtils";
import { withAntUtils } from "../../components/ant-utils/withAntUtils";
import { compose } from "../../components/compose/WlyCompose";
import usePrevious from "../../components/hooks/usePrevious";
import Loading from "../../components/layout/feedback/loading";
import type { IJobExecution } from "../../interfaces/jobExecutions";
import type { LogEntry } from "../../services/logService";
import { getLogs } from "../../services/logService";
import { downloadTextFile } from "../../utils/textFileDownload";
import type { InjectedOrgProps } from "../orgs/WithOrg";
import WithOrg from "../orgs/WithOrg";
import "./ExecutionLogsDrawer.scss";

interface IExecutionLogsDrawerProps {
  selectedJobExecution?: IJobExecution;
  onClose: () => void;
  visible: boolean;
}

type Props = IExecutionLogsDrawerProps &
  InjectedAntUtilsProps &
  InjectedOrgProps;

const dedupLogs = (l: LogEntry) => {
  return `${l.timestamp}-${l.level}-${l.message}`;
};

const updateLogs = (
  currentLogs: LogEntry[],
  newLogs: LogEntry[]
): LogEntry[] => {
  const clonedLogs = [...currentLogs, ...newLogs];
  const dedup = _.uniqBy(clonedLogs, dedupLogs);
  const sorted = _.sortBy(dedup, "timestamp");
  return sorted;
};

const columns: ColumnsType<LogEntry> = [
  {
    title: "Date",
    dataIndex: "timestamp",
    key: "timestamp",
    width: 210,
  },
  {
    title: "Level",
    key: "level",
    dataIndex: "level",
    width: 70,
    render: (_, log) => {
      let color = "blue";
      if (log.level === "info") {
        color = "blue";
      } else if (log.level === "warning") {
        color = "orange";
      } else if (log.level === "error") {
        color = "red";
      }
      return <Tag color={color}>{log.level}</Tag>;
    },
    filters: [
      {
        text: "info",
        value: "info",
      },
      {
        text: "warning",
        value: "warning",
      },
      {
        text: "error",
        value: "error",
      },
    ],
    onFilter: (value: string, record) => record.level.indexOf(value) === 0,
  },
  {
    title: "Message",
    dataIndex: "message",
    key: "message",
    ellipsis: true,
  },
];

const convertLogsToText = (logs: LogEntry[]) => {
  return logs
    .map((l) => {
      return `${l.timestamp} - ${l.level} - ${l.message}`;
    })
    .join("\n");
};

const ExecutionLogsDrawer = (props: Props) => {
  const { onClose, selectedJobExecution, visible, antUtils } = props;
  const [isLoading, setIsLoading] = React.useState(true);
  const [isLoadingMoreAfter, setIsLoadingMoreAfter] = React.useState(false);
  const [rawLogs, setRawLogs] = React.useState<LogEntry[]>([]);
  const [logs, setLogs] = React.useState<LogEntry[]>([]);
  const prevLogs = usePrevious(logs);
  const timeout = React.useRef(null);
  const intervalMs = 5000;

  const {
    org: { id: orgId },
  } = props;

  // Fetch data
  React.useEffect(() => {
    async function fetchLogs(isInTimeout?: boolean) {
      if (!isInTimeout) {
        try {
          setIsLoading(true);
          const res = await getLogs(orgId, selectedJobExecution);
          setIsLoading(false);
          setRawLogs(res.data.logs || []);
        } catch (error) {
          setIsLoading(false);
          antUtils.message.error("An error happened while fetching the logs");
          return console.warn(error);
        }
      }

      // only setup a timeout if the execution is running
      if (selectedJobExecution.status !== "RUNNING") return;

      try {
        if (isInTimeout) {
          setIsLoadingMoreAfter(true);
          const res = await getLogs(orgId, selectedJobExecution);
          setRawLogs(res.data.logs || []);
        }
      } catch (error) {
        console.warn(error);
      } finally {
        setIsLoadingMoreAfter(false);
        timeout.current = setTimeout(() => {
          fetchLogs(true);
        }, intervalMs);
      }
    }

    if (visible === true) {
      fetchLogs();
    } else if (visible === false && timeout.current) {
      clearTimeout(timeout.current);
    }

    return () => {
      if (timeout.current) {
        clearTimeout(timeout.current);
      }
    };
  }, [selectedJobExecution, visible]);

  // set logs when raw logs change
  React.useEffect(() => {
    setLogs((currentLogs) => updateLogs(currentLogs, rawLogs));
  }, [rawLogs]);

  // make table scroll at bottom on initial load
  React.useEffect(() => {
    const node = document.querySelector<HTMLElement>(
      ".logs-drawer .ant-drawer-body"
    );
    if (logs.length && prevLogs && prevLogs.length === 0) {
      if (node) {
        node.scrollTop = node.scrollHeight;
      }
    }
  }, [logs, prevLogs]);

  const renderInner = (): JSX.Element => {
    if (isLoading) {
      return <Loading />;
    } else {
      return (
        <Table
          pagination={false}
          columns={columns}
          dataSource={logs.map((log, i) => {
            return {
              key: i,
              level: log.level,
              message: log.message,
              timestamp: log.timestamp,
            };
          })}
          size="small"
          scroll={{
            y: null,
          }}
          sticky={true}
          expandable={{
            expandedRowRender: (log) => (
              <p style={{ margin: 0 }}>{log.message}</p>
            ),
          }}
        />
      );
    }
  };

  const title = (
    <Space>
      Logs
      <Tooltip
        placement="right"
        style={{ marginLeft: 8 }}
        title={
          <div style={{ paddingLeft: 8 }}>
            Only the most recent logs are displayed.
          </div>
        }
      >
        <QuestionCircleOutlined />
      </Tooltip>
    </Space>
  );

  const renderExtra = () => (
    <Space>
      {isLoadingMoreAfter ? (
        <>
          <LoadingOutlined spin={true} style={{ fontSize: 18 }} />
          Loading more logs
          <Divider type="vertical" />
        </>
      ) : undefined}
      {selectedJobExecution?.status === "RUNNING" ? (
        <>
          <div>🔁 Refreshed every {intervalMs / 1000} seconds</div>
          <Divider type="vertical" />
        </>
      ) : undefined}
      <Button
        ghost
        disabled={!logs.length}
        onClick={() => {
          downloadTextFile(
            `logs-exec-${props.selectedJobExecution?.id}-${Date.now()}.txt`,
            convertLogsToText(logs)
          );
        }}
      >
        Download
      </Button>
    </Space>
  );

  return (
    <Drawer
      title={title}
      className="logs-drawer"
      open={visible}
      destroyOnClose={true}
      height={"90%"}
      onClose={() => {
        setLogs([]);
        onClose();
      }}
      placement="bottom"
      extra={renderExtra()}
    >
      {renderInner()}
    </Drawer>
  );
};

export default compose<Props, IExecutionLogsDrawerProps>(
  withAntUtils,
  WithOrg
)(ExecutionLogsDrawer);
