import type {
  IJobExecution,
  JobSyncStatus,
} from "../../../../../interfaces/jobExecutions";
import "./JobStatusRenderer.scss";

interface IJobExecutionStatusRendererProps {
  jobExecution: IJobExecution;
}

type ExecutionStatus =
  | "syncing"
  | "success"
  | "waiting"
  | "error"
  | "late"
  | "unknown";

const getExecutionStatus = (
  execution: IJobExecution | undefined,
  syncStatus?: JobSyncStatus
): ExecutionStatus => {
  if (syncStatus === "scheduled") {
    return "syncing";
  }

  if (!execution && syncStatus === "idle") {
    return "waiting";
  }

  switch (execution.status) {
    case "RUNNING":
      return "syncing";
    case "SUCCESSED":
      return "success";
    case "ERROR":
    case "FAILED":
      return "error";
    default:
      return "unknown";
  }
};

export function JobExecutionStatusRenderer(
  props: IJobExecutionStatusRendererProps
) {
  const { jobExecution } = props;
  return (
    <div
      className={`job-status-renderer-dot job-status-renderer-dot-${getExecutionStatus(
        jobExecution
      )}`}
    >
      &#9679;
    </div>
  );
}
