import type { ColDef } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import type { UserLocale } from "../../../interfaces/user";
import { getFontColorFromBackground } from "../../../utils/colorUtils";
import { calculateReverseDistance } from "../../../utils/distance";
import "../../ag-grid/theme/ag-theme-whaly.scss";
import type PaletteGenerator from "../../palette/utils/PaletteGenerator";
import type { Formatter } from "../domain";
import { customFormatter } from "../utils/optionsHelper";

interface IRetentionChartProps {
  locale: UserLocale;
  data: Array<{ [key: string]: string | number | boolean | undefined }>;
  config: RetentionChartConfig;
  onDrill?: (options: { periodValue: string; cohortLabel: string }) => void;
}

interface RetentionChartConfig {
  cohortLabel: {
    key: string;
    label: string;
  };
  cohortSize: {
    key: string;
    label: string;
    formatter?: Formatter;
  };
  cohortValue: {
    key: string;
    periods: string[];
    formatter?: Formatter;
  };
  palette?: PaletteGenerator;
  displayPercent?: boolean;
  showColors?: boolean;
  showTotal?: boolean;
}

export function RetentionChart(props: IRetentionChartProps) {
  const { locale, config, data, onDrill } = props;

  const firstPeriod = config.cohortValue.periods.sort((a, b) =>
    a.localeCompare(b)
  )[0];

  const defaultColDef: ColDef = {
    flex: 1,
    minWidth: 100,
    sortable: false,
    resizable: false,
    suppressMovable: true,
    menuTabs: [],
  };

  const colDef: ColDef[] = [
    {
      headerName: config.cohortLabel.label,
      field: config.cohortLabel.key,
      sort: "asc",
    },
    {
      headerName: config.cohortSize.label,
      field: config.cohortSize.key,
      hide: !!!config.cohortSize.key,
      valueFormatter: (params) => {
        if (
          params.value === undefined ||
          params.value === null ||
          params.value === ""
        ) {
          return params.value;
        }
        return customFormatter(
          params.value,
          locale,
          config.cohortSize.formatter
        );
      },
    },
    ...(config.cohortValue.periods ?? [])
      .sort((a, b) => a.localeCompare(b))
      .map(
        (pk) =>
          ({
            headerName: pk,
            field: `${pk},${config.cohortValue.key}`,
            onCellClicked: (params) => {
              if (!onDrill || typeof params.value === "undefined") return;
              const cohortLabel = params.data[config.cohortLabel.key];
              onDrill({ cohortLabel, periodValue: pk });
            },
            valueFormatter: (params) => {
              if (
                params.value === undefined ||
                params.value === null ||
                params.value === ""
              ) {
                return params.value;
              }
              if (config.displayPercent) {
                return customFormatter(
                  params.value /
                    params.data[
                      config.cohortSize.key ??
                        `${firstPeriod},${config.cohortValue.key}`
                    ],
                  locale,
                  {
                    format: "NUMBER",
                    customFormatting: "0.[0]%",
                  }
                );
              } else {
                return customFormatter(
                  params.value,
                  locale,
                  config.cohortValue.formatter
                );
              }
            },
            cellStyle: (params) => {
              const defaultStyle = {
                textAlign: "center",
                cursor: "default",
              };
              if (
                typeof onDrill === "function" &&
                typeof params.value !== "undefined"
              ) {
                defaultStyle.cursor = "pointer";
              }
              if (typeof params.value !== "number" || !params.value) {
                return defaultStyle;
              } else if (config.palette && config.showColors) {
                try {
                  const backgroundColor = config.palette.getColorAtIndex(
                    calculateReverseDistance(
                      0,
                      params.data[config.cohortSize.key],
                      params.data[params.colDef.field!]
                    )
                  );
                  const fontColor = getFontColorFromBackground(backgroundColor);
                  return {
                    ...defaultStyle,
                    background: backgroundColor,
                    color: fontColor,
                  };
                } catch (error) {
                  return defaultStyle;
                }
              } else {
                return defaultStyle;
              }
            },
          } as ColDef)
      ),
  ];

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <AgGridReact
        className="ag-theme-retention"
        defaultColDef={defaultColDef}
        columnDefs={colDef}
        rowData={data}
        suppressFieldDotNotation={true}
        suppressContextMenu={true}
        suppressCellFocus={true}
      />
    </div>
  );
}
