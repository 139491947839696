import { ArrowLeftOutlined, CloseOutlined } from "@ant-design/icons";
import { Button, Col, Divider, Row, Typography } from "antd";
import _ from "lodash";
import * as React from "react";
import type { InjectedOrgProps } from "../../../containers/orgs/WithOrg";
import WithOrg from "../../../containers/orgs/WithOrg";
import { hasRoleAccessBoolean } from "../../../containers/user-settings/HasRoleAccess";
import type { ICatalogItemMeta } from "../../../interfaces/catalog";
import { IUserRoleType } from "../../../interfaces/user";
import type { ViewName } from "../../../routes/domain";
import Card from "../../cards/Card";
import { compose } from "../../compose/WlyCompose";
import CatalogItemDetail from "../detail/CatalogItemDetail";
import CatalogItem from "../item/CatalogItem";
import "./GenericCatalog.scss";

interface IGenericCatalogProps {
  title?: string;
  items: ICatalogItemMeta[];
  alreadyPurchasedItems: ICatalogItemMeta[];
  destinationViewName: ViewName;
  renderDetail?: (selected: ICatalogItemMeta) => React.ReactNode;
  disableSelection?: (item: ICatalogItemMeta) => boolean;
  overrideButtonName?: string;
  onClose?: () => void;
  currentWarehouseSlug?: string;
}

type Props = IGenericCatalogProps & InjectedOrgProps;

interface IState {
  selected?: ICatalogItemMeta;
  searchValue?: string;
}

class GenericCatalog extends React.Component<Props, IState> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  public closeDrawer = () => {
    this.setState({ selected: undefined });
  };

  public onItemClick = (s: ICatalogItemMeta) => () => {
    if (s.overrideUrl) {
      window.open(s.overrideUrl, "_blank", "noreferrer");
      return;
    }
    this.setState({ selected: s });
  };

  renderDetail = (selected: ICatalogItemMeta, disabled: boolean) => {
    const { overrideButtonName } = this.props;
    if (this.props.renderDetail) {
      return this.props.renderDetail(selected);
    }
    return (
      <CatalogItemDetail
        overrideButtonName={overrideButtonName}
        selected={selected}
        disabled={disabled}
        destinationViewName={this.props.destinationViewName}
        currentWarehouseSlug={this.props.currentWarehouseSlug}
      />
    );
  };

  renderInner = () => {
    const { alreadyPurchasedItems, items, org, user, disableSelection } =
      this.props;
    const { selected, searchValue } = this.state;

    let disable = false;
    if (disableSelection && selected) {
      disable = disableSelection(selected);
    }

    if (selected) {
      return this.renderDetail(
        selected,
        disable || !hasRoleAccessBoolean(IUserRoleType.BUILDER, user, org.id)
      );
    }

    const itemsToDisplay = items
      .filter((item) => !!item.publicInfo) // one source does not have public info and breaks the catalog
      .filter((item) => {
        if (!searchValue) return true;
        return item.publicInfo.name
          .toLowerCase()
          .includes(searchValue.toLowerCase());
      });

    const itemsPerCategory = _.groupBy(itemsToDisplay, "categoryLabel");

    return Object.keys(itemsPerCategory).map((category) => {
      return (
        <>
          {category !== "undefined" ? (
            <Typography.Title level={5}>{category}</Typography.Title>
          ) : undefined}
          <Row gutter={[24, 24]} className="source-catalog">
            {itemsPerCategory[category].map((s) => {
              return (
                <Col
                  key={s.slug}
                  xs={24}
                  sm={12}
                  md={8}
                  lg={6}
                  onClick={this.onItemClick(s)}
                >
                  <Card height={300}>
                    <CatalogItem
                      label={s.publicInfo.label}
                      logo={s.publicInfo.logo}
                      name={s.publicInfo.name}
                      connected={
                        !!alreadyPurchasedItems.find(
                          (api) => api.slug === s.slug
                        )
                      }
                    />
                  </Card>
                </Col>
              );
            })}
            <div style={{ width: "100%" }} key="padding" />
          </Row>
        </>
      );
    });
  };

  public render() {
    const { title, onClose } = this.props;
    const { selected } = this.state;

    const renderHeader = () => {
      if (!selected) {
        return (
          <>
            <div className={`catalog-modal-header`}>
              <div className={`catalog-modal-header-title`}>
                <Typography.Title style={{ marginBottom: 0 }} level={5}>
                  {title}
                </Typography.Title>
              </div>
              <div className={`catalog-modal-header-button`}>
                <Button
                  onClick={onClose}
                  type={!!selected ? "default" : "text"}
                  shape="circle"
                  icon={<CloseOutlined />}
                  ghost
                />
              </div>
            </div>
            <Divider style={{ margin: 0 }} />
          </>
        );
      }
      return (
        <div className={`catalog-modal-header selected`}>
          <div className={`catalog-modal-header-title`}>
            <Button
              onClick={() => this.setState({ selected: undefined })}
              type={!!selected ? "default" : "text"}
              shape="circle"
              icon={<ArrowLeftOutlined />}
              ghost
            />
            <Typography.Text style={{ paddingLeft: 12, color: "#fff" }} strong>
              Back
            </Typography.Text>
          </div>
          <div className={`catalog-modal-header-button`}>
            <Button
              onClick={onClose}
              type={!!selected ? "default" : "text"}
              shape="circle"
              icon={<CloseOutlined />}
              ghost
            />
          </div>
        </div>
      );
    };

    return (
      <>
        {renderHeader()}
        <div
          className={`catalog-modal-content ${!!selected ? "selected" : ""}`}
        >
          {!selected ? (
            <input
              placeholder="Search"
              style={{
                width: "100%",
                marginTop: 8,
                marginBottom: 24,
                padding: 8,
              }}
              onChange={(event) => {
                this.setState({
                  searchValue: event.target.value,
                });
              }}
            ></input>
          ) : undefined}
          {this.renderInner()}
        </div>
      </>
    );
  }
}

export default compose<Props, IGenericCatalogProps>(WithOrg)(GenericCatalog);
