import { Button, Form } from "antd";
import React from "react";
import type { InjectedAntUtilsProps } from "../../../components/ant-utils/withAntUtils";
import { withAntUtils } from "../../../components/ant-utils/withAntUtils";
import { compose } from "../../../components/compose/WlyCompose";
import { IUserRoleType } from "../../../interfaces/user";
import type { InjectedOrgProps } from "../../orgs/WithOrg";
import WithOrg from "../../orgs/WithOrg";
import type { IUserInviteValues } from "../../users/invite/UserInviteForm";
import { inviteUsers, UserInviteForm } from "../../users/invite/UserInviteForm";

interface IInviteUserProps {
  onDone: () => void;
}

type Props = IInviteUserProps & InjectedAntUtilsProps & InjectedOrgProps;

function InviteUser(props: Props) {
  const { onDone } = props;

  const [form] = Form.useForm();
  const [loading, setLoading] = React.useState<boolean>(false);

  const onFinish = async (values: IUserInviteValues) => {
    const { antUtils, org } = props;

    setLoading(true);
    try {
      await form.validateFields();
      await inviteUsers(values, org.id, IUserRoleType.VIEWER);
      antUtils.message.success("Invites succesfully sent");
      setTimeout(() => {
        onDone();
      }, 300);
    } catch (error) {
      console.error(error);
      antUtils.message.error("Error while inviting users. Please try again");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <UserInviteForm onFinish={onFinish} form={form} />
      <div className="warehouse-connection-content center">
        <Button onClick={onDone}>Skip for now</Button>{" "}
        <Button
          type={"primary"}
          onClick={() => {
            form.submit();
          }}
          loading={loading}
        >
          Send invites
        </Button>
      </div>
    </>
  );
}

export default compose<Props, IInviteUserProps>(
  WithOrg,
  withAntUtils
)(InviteUser);
