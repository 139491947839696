import type { AvailableDimension } from "../../../components/measures/filter-item/FilterItem";
import type { IExploration } from "../../../interfaces/explorations";
import type { GetCurrentQuery, ILagoonQuery } from "./domain";
import Visualization from "./visualization/Visualization";

interface IVisualizationWrapperProps {
  exploration: IExploration;
  getCurrentQuery?: GetCurrentQuery;
  initialQuery?: ILagoonQuery;
  initialQueryLoad?: boolean;
  isEmbeded?: boolean;
  overrideObjectType?: "VIEW" | "EXPLORATION";
}

export function VisualizationWrapper(props: IVisualizationWrapperProps) {
  const {
    exploration,
    getCurrentQuery,
    initialQuery,
    initialQueryLoad,
    isEmbeded,
    overrideObjectType,
  } = props;
  // todo fetch the domain
  const tableEnhancedDimensions: Array<AvailableDimension> =
    exploration.tables.flatMap((t) => {
      return t.dimensions
        .filter((d) => !d.hidden)
        .map((d) => ({
          key: `${t.cubeName}.${d.cubeName}`,
          label: d.name,
          tableName: t.name,
          description: d.description,
          type: d.type,
          domain: d.type === "geo" ? undefined : d.columnDomain,
        }));
    }) as Array<AvailableDimension>;
  // I can't remember why we had this check so I am removing it in hope I don't break anything
  // todo: if we need it we should fetch it from lagoon instead of core
  // .filter(t => !!t.domain)

  return (
    <Visualization
      availableDimensions={tableEnhancedDimensions}
      overrideObjectType={overrideObjectType}
      availableMetrics={exploration.tables.flatMap((t) => {
        return t.metrics
          .filter((d) => !d.hidden)
          .map((d) => {
            return {
              key: `${t.cubeName}.${d.cubeName}`,
              label: d.name,
              description: d.description,
              formatter: {
                prefix: d.prefix,
                suffix: d.suffix,
                format: d.format,
                customFormatting: d.overrideFormatting,
              },
            };
          });
      })}
      availableTime={tableEnhancedDimensions.filter((t) => t.domain === "TIME")}
      exploration={exploration}
      getCurrentQuery={getCurrentQuery}
      initialQuery={initialQuery}
      initialQueryLoad={initialQueryLoad}
      isEmbeded={isEmbeded}
    />
  );
}
