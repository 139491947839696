import { makeObservable, observable } from "mobx";

class DemoStore {
  selectedInput: string | undefined;
  selectedTeam: { name: string; metric: string } | undefined;
  hoveredTeam: string | undefined;

  constructor() {
    makeObservable(this, {
      selectedInput: observable,
      selectedTeam: observable,
      hoveredTeam: observable,
    });
  }

  setSelectedInput = (s: string | undefined) => {
    this.selectedInput = s;
  };

  setSelectedTeam = (s: { name: string; metric: string } | undefined) => {
    this.selectedTeam = s;
  };

  setHoveredTeam = (s: string | undefined) => {
    this.hoveredTeam = s;
  };
}

export interface DemoStoreProps {
  demoStore: DemoStore;
}

export default new DemoStore();
