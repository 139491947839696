export const rca = {
  render: "RCA",
  children: ["paragraph", "tag", "list"],
  attributes: {
    file: {
      type: String,
    },
    metric: {
      type: String,
    },
    dimension: {
      type: String,
    },
  },
};
