export interface IDataItem {
  id: string;
  image: string;
  confidence: number;
  price: number;
  qty: number;
  type?: string;
  name: string;
}

export const data: Array<IDataItem> = [
  {
    id: "1",
    image:
      "https://res.cloudinary.com/hugo-valla/image/fetch/a_hflip/e_improve/e_pixelate_faces:40/e_make_transparent/https://img01.ztat.net/article/spp-media-p1/5b1c5cbace2b3d7e947673f4bf3efb51/d4519c2520b14eb493b135e78356a284.jpg?imwidth=300",
    confidence: 0.99,
    price: 64.99,
    qty: 23,
    type: "HIGH_MARGIN",
    name: "gore tex thermo - gloves - black",
  },
  {
    id: "2",
    image:
      "https://res.cloudinary.com/hugo-valla/image/fetch/a_hflip/e_improve/e_pixelate_faces:40/e_make_transparent/https://img01.ztat.net/article/spp-media-p1/ef835e362d6844939a3793d07ffaa8c5/82c824a3723147fc9a2f08bf85d8e417.jpg?imwidth=300&filter=packshot",
    confidence: 0.83,
    price: 42.95,
    qty: 23,
    type: "NEW",
    name: "gloves - black",
  },
  {
    id: "3",
    image:
      "https://res.cloudinary.com/hugo-valla/image/fetch/a_hflip/e_improve/e_pixelate_faces:40/e_make_transparent/https://img01.ztat.net/article/spp-media-p1/ee7847baf6653103a23fb7fba34f217e/1adcdd4a932342d8ac0d5e5ff89350bf.jpg?imwidth=300&filter=packshot",
    confidence: 0.79,
    price: 25.99,
    qty: 12,
    type: "BEST_SELLER",
    name: "gloves - black",
  },
  {
    id: "4",
    image:
      "https://res.cloudinary.com/hugo-valla/image/fetch/a_hflip/e_improve/e_pixelate_faces:40/e_make_transparent/https://img01.ztat.net/article/spp-media-p1/529d7d3650ff4c9eb6faae4f4e8a0c5e/b3f1b2c4b01545888b1761e4359721a4.jpg?imwidth=300&filter=packshot",
    confidence: 0.76,
    price: 12.99,
    qty: 8,
    type: "BEST_SELLER",
    name: "gloves - black",
  },
  {
    id: "5",
    image:
      "https://res.cloudinary.com/hugo-valla/image/fetch/a_hflip/e_improve/e_pixelate_faces:40/e_make_transparent/https://img01.ztat.net/article/spp-media-p1/d969b40a51494ce9a058eb5d298ff13d/6f762b2dfac54ec3a6f22e1c224c3be9.jpg?imwidth=300&filter=packshot",
    confidence: 0.73,
    price: 25.99,
    qty: 23,
    name: "gloves - black",
  },
  {
    id: "6",
    image:
      "https://res.cloudinary.com/hugo-valla/image/fetch/a_hflip/e_improve/e_pixelate_faces:40/e_make_transparent/https://img01.ztat.net/article/spp-media-p1/5c97e966dffe473a8adea76f8ad76b99/f707f5682fb54f90b9ac22335eb5bfb2.jpg?imwidth=300&filter=packshot",
    confidence: 0.63,
    price: 12.99,
    qty: 23,
    name: "gloves - black",
  },
  {
    id: "7",
    image:
      "https://res.cloudinary.com/hugo-valla/image/fetch/a_hflip/e_improve/e_pixelate_faces:40/e_make_transparent/https://img01.ztat.net/article/spp-media-p1/2988485eba1c36089f9a3462a86a3d79/a21dcc6087e24612a8f25f978bdbcc8c.jpg?imwidth=300&filter=packshot",
    confidence: 0.62,
    price: 11.89,
    qty: 23,
    type: "HIGH_MARGIN",
    name: "gloves - black",
  },
  {
    id: "8",
    image:
      "https://res.cloudinary.com/hugo-valla/image/fetch/a_hflip/e_improve/e_pixelate_faces:40/e_make_transparent/https://img01.ztat.net/article/spp-media-p1/1da7b8e592d04fea935e8223f57c391e/6cffc35beaa54d74a264812dc504d2e4.jpg?imwidth=300&filter=packshot",
    confidence: 0.61,
    price: 12.99,
    qty: 23,
    name: "gloves - black",
  },
  {
    id: "9",
    image:
      "https://res.cloudinary.com/hugo-valla/image/fetch/a_hflip/e_improve/e_pixelate_faces:40/e_make_transparent/https://img01.ztat.net/article/spp-media-p1/0a814b3632fb40d48e071ac63ef404f8/ca43388d1e5a4d1ca31c6a62f8e96d3e.jpg?imwidth=300&filter=packshot",
    confidence: 0.54,
    price: 10.99,
    qty: 10,
    name: "gloves - black",
  },
  {
    id: "10",
    image:
      "https://res.cloudinary.com/hugo-valla/image/fetch/a_hflip/e_improve/e_pixelate_faces:40/e_make_transparent/https://img01.ztat.net/article/spp-media-p1/0d49df8582c73e6ea86ad8f0d60d5958/40c21a1632c44310b86a527ba545cfef.jpg?imwidth=300&filter=packshot",
    confidence: 0.51,
    price: 15.99,
    qty: 12,
    name: "gloves - black",
  },
];

export interface IStoreItem {
  id: string;
  image: string;
  distance: number;
  city: string;
  name: string;
  score: number;
}

export const stores: Array<IStoreItem> = [
  {
    id: "15999681",
    image: "https://cdn.whaly.io/retail-demo/leclerc.png",
    distance: 1500,
    city: "Drumettaz",
    name: "Centre E. Leclerc Drumettaz Clarafond",
    score: 0,
  },
  {
    id: "15999683",
    image: "https://cdn.whaly.io/retail-demo/leclerc.png",
    distance: 3000,
    city: "Ville La Grand",
    name: "Centre E. Leclerc Ville La Grand",
    score: 1,
  },
  {
    id: "15999682",
    image: "https://cdn.whaly.io/retail-demo/leclerc.png",
    distance: 5000,
    city: "Cran Gevrier",
    name: "Centre E. Leclerc Cran Gevrier",
    score: 1,
  },
  {
    id: "15998238",
    image: "https://cdn.whaly.io/retail-demo/carrefour.png",
    distance: 7000,
    city: "Meylan",
    name: "Carrefour Meylan",
    score: 3,
  },
  {
    id: "16001108",
    image: "https://cdn.whaly.io/retail-demo/systemeu.png",
    distance: 8000,
    city: "Bourg Saint Maurice",
    name: "Super U Bourg Saint Maurice",
    score: 4,
  },
];

export const recommendedStores = [
  {
    id: "15998159",
    image: "https://cdn.whaly.io/retail-demo/carrefour.png",
    distance: 14560,
    city: "Segny",
    name: "Carrefour Segny",
    score: 3,
  },
  {
    id: "15998159",
    image: "https://cdn.whaly.io/retail-demo/casino.png",
    distance: 23890,
    city: "Annemasse",
    name: "Geant Casino Annemasse",
    score: 5,
  },
];

export const productToRemove = [
  {
    id: "2621306",
    image: "https://cdn.whaly.io/retail-demo/products/lunar-lemonade.png",
    name: "Flamenco 90CL",
    vmh: 8,
    facing: -2,
  },
  {
    id: "2621294",
    image: "https://cdn.whaly.io/retail-demo/products/seafoam-fizz.png",
    name: "Fresh Fruity Awakening 90CL",
    vmh: 13.3,
    facing: -2,
  },
];
