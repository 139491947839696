import Fuse from "fuse.js";
import { useState } from "react";

type FuseSearchOptions<T = any> = {
  initialSearch?: string;
  items: T[];
  fuseOptions: Fuse.IFuseOptions<T>;
};

export function useFuseSearch<T>(options: FuseSearchOptions<T>) {
  const [search, setSearch] = useState<string | undefined>(
    options.initialSearch
  );

  const fuse = new Fuse<T>(options.items, options.fuseOptions);
  const results = search
    ? fuse.search(search).map((i) => i.item)
    : options.items;
  const details = search ? fuse.search(search) : undefined;

  return {
    search,
    setSearch,
    results,
    details,
  };
}
