import Icon from "@ant-design/icons";
import type { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";

export const WlyScatterChartIcon = (
  props: Partial<CustomIconComponentProps>
) => (
  <Icon
    component={() => (
      <svg
        width="1em"
        height="1em"
        viewBox="0 0 213 213"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="26" cy="181" r="19" fill="#3A5C83" />
        <circle cx="54" cy="99" r="19" fill="#4E6C8F" />
        <circle cx="106" cy="158" r="19" fill="#617D9C" />
        <circle cx="125" cy="25" r="19" fill="#899DB5" />
        <circle cx="186" cy="94" r="19" fill="#B0BECD" />
      </svg>
    )}
    {...props}
  />
);
