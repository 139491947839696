import { InfoCircleOutlined } from "@ant-design/icons";
import type { SelectProps } from "antd";
import { Select, Tooltip } from "antd";
import type { Moment } from "moment";
import moment from "moment";
import * as React from "react";
import { getComparisonDate } from "../../../utils/cubejsUtils";
import "./ComparisonSelector.scss";

interface IState {
  hover: string;
}
interface IComparisonProps extends SelectProps {
  disabled?: boolean;
  datesToCompare?: [Moment, Moment];
  value?: IComparisonPeriod;
  onChange?: (comparison?: IComparisonPeriod) => void;
}

export type IComparisonPeriod =
  | "day"
  | "week"
  | "month"
  | "quarter"
  | "year"
  | "previous";

const getPeriodString = (
  dates: [Moment, Moment],
  period: IComparisonPeriod
) => {
  const format = "YYYY/MM/DD";
  const comparedDates = getComparisonDate(dates, period);
  if (comparedDates[0] === comparedDates[1])
    return `On ${comparedDates[0].format(format)}`;
  return `From ${comparedDates[0].format(format)} to ${comparedDates[1].format(
    format
  )}`;
};

const renderTooltip = (dates: [Moment, Moment], period: IComparisonPeriod) => {
  return (
    <Tooltip title={getPeriodString(dates, period)}>
      <InfoCircleOutlined />
    </Tooltip>
  );
};

export default class ComparisonSelector extends React.Component<
  IComparisonProps,
  IState
> {
  constructor(props: IComparisonProps) {
    super(props);
    this.state = {
      hover: null,
    };
  }

  public render() {
    const { value, datesToCompare, onChange, ...rest } = this.props;
    const shouldDisplayTooltip =
      datesToCompare &&
      datesToCompare[0] &&
      datesToCompare[1] &&
      moment.isMoment(datesToCompare[0]) &&
      moment.isMoment(datesToCompare[1]);

    const onMenuSelect = (value: IComparisonPeriod) => {
      onChange(value);
    };

    const onRemove = () => {
      onChange(undefined);
    };

    const comparisonPeriods: IComparisonPeriod[] = [
      "week",
      "month",
      "quarter",
      "year",
      "previous",
    ];

    return (
      <Select<IComparisonPeriod>
        onChange={onMenuSelect}
        style={{ width: "100%" }}
        dropdownStyle={{
          width: 160,
        }}
        popupMatchSelectWidth={false}
        placeholder="Not comparing"
        disabled={rest.disabled}
        optionLabelProp="label"
        onClear={onRemove}
        allowClear
        value={value}
        {...rest}
      >
        {comparisonPeriods.map((v, i) => {
          return (
            <Select.Option
              label={<>Previous {v === "previous" ? "period" : v}</>}
              key={v}
              value={v}
              onMouseEnter={() => this.setState({ hover: v })}
              onMouseLeave={() => this.setState({ hover: null })}
            >
              <>
                Previous {v === "previous" ? "period" : v}
                {
                  <span
                    style={{
                      float: "right",
                      display: this.state.hover === v ? "initial" : "none",
                    }}
                  >
                    {shouldDisplayTooltip && renderTooltip(datesToCompare, v)}
                  </span>
                }
              </>
            </Select.Option>
          );
        })}
      </Select>
    );
  }
}
