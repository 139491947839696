import type { ComponentClass } from "react";
import ActionConnectionPage from "../containers/catalog/actions/ActionConnectionPage";
import SourceConnectionPage from "../containers/catalog/sources/SourceConnectionPage";
import WarehouseConnectionPage from "../containers/catalog/warehouses/WarehouseConnectionPage";
import FeatureCrossSellPage from "../containers/feature-cross-sell/featureCrossSellPage";
import Callback from "../containers/login/Callback";
import Login from "../containers/login/Login";
import PortalCallback from "../containers/login/PortalCallback";
import PortalLogin from "../containers/login/PortalLogin";
import Org from "../containers/orgs/Org";
import RedirectPage from "../containers/redirect/redirect";
import ReportEmbedPage from "../containers/reports/embed/ReportEmbedPage";
import ReportPublicView from "../containers/reports/public/ReportPublicView";
import ReportSharingLink from "../containers/reports/sharing-link/ReportSharingLinkPage";
import ActionManagementPage from "../containers/settings/actions/action-management/ActionManagementPage";
import ActionHistoryPage from "../containers/settings/actions/push-history/PushHistoryPage";
import AuditLogPage from "../containers/settings/audit-log/AuditLogPage";
import BasicSettingPage from "../containers/settings/basic/BasicSettingPage";
import EmailTemplate from "../containers/settings/email-template/EmailTemplateSettings";
import NotificationJobsPage from "../containers/settings/notification/jobs/NotificationJobsPage";
import NotificationTopicsPage from "../containers/settings/notification/topics/NotificationTopicsPage";
import PartnerPortalDetailPage from "../containers/settings/partner-portals/detail/PartnerPortalDetailPage";
import PartnerPortalPage from "../containers/settings/partner-portals/list/PartnerPortalPage";
import SettingsPlatformUsage from "../containers/settings/platform-usage/SettingsPlatformUsage";
import { ServiceAccountManagementPage } from "../containers/settings/service-accounts/ServiceAccountManagementPage";
import SharedReportsPage from "../containers/settings/shared-reports/SharedReportsPage";
import SettingsSourceUsage from "../containers/settings/source-usage/SettingsSourceUsage";
import ThemeSettingPage from "../containers/settings/theme/ThemeSettingPage";
import UnusedExplorationPage from "../containers/settings/unused-explorations/UnusedExplorationPage";
import UserAttributesPage from "../containers/settings/user-attributes/UserAttributesPage";
import UserGroupManagementPage from "../containers/settings/user-groups/UserGroupManagementPage";
import { AccessManagementPage } from "../containers/settings/users-management/AccessManagementPage";
import WarehouseSettingsPage from "../containers/settings/warehouse/details/WarehousePage";
import WarehouseListPage from "../containers/settings/warehouse/list/WarehouseListPage";
import ExplorationModal from "../containers/v2-demo/container/eploration/ExplorationModal";
import FolderPage from "../containers/v2-demo/container/folders/page/FolderPage";
import HomePage from "../containers/v2-demo/container/home/HomePage";
import V2Layout from "../containers/v2-demo/container/layout/V2Layout";
import ObjectPageModal from "../containers/v2-demo/container/modals/objects/ObjectPageModal";
import { OrgPickerModal } from "../containers/v2-demo/container/modals/orgs/OrgPickerModal";
import { RealmSettingsModal } from "../containers/v2-demo/container/modals/realm/RealmSettingsModal";
import RecordModal from "../containers/v2-demo/container/modals/records/RecordModal";
import { RootCauseAnalysisPage } from "../containers/v2-demo/container/modals/root-causes/RootCauseAnalysisPage";
import StatusPageModal from "../containers/v2-demo/container/modals/status/StatusPageModal";
import { UserSettingsModal } from "../containers/v2-demo/container/modals/user/UserSettingsModal";
import ObjectPageContainer from "../containers/v2-demo/container/object/ObjectPageContainer";
import RecordMjml from "../containers/v2-demo/container/record/RecordMjml";
import RecordView from "../containers/v2-demo/container/record/RecordView";
import RecordViewEmbed from "../containers/v2-demo/container/record/RecordViewEmbed";
import { default as RecordViewPublicEmbed } from "../containers/v2-demo/container/record/RecordViewPublicEmbed";
import ReportV2Page from "../containers/v2-demo/container/report/ReportV2Page";
import WorkbenchPageWrapper from "../containers/workbench/single/WorkbenchPageWrapper";
import type { RouteDescriptorComponent } from "./domain";

export const routesComponents: {
  [key in RouteDescriptorComponent]: ComponentClass<any>;
} = {
  ExplorationModal: ExplorationModal,
  ObjectPageModal: ObjectPageModal,
  OrgPickerModal: OrgPickerModal as unknown as ComponentClass,
  RealmSettingsModal: RealmSettingsModal as unknown as ComponentClass,
  RecordModal: RecordModal,
  RootCauseAnalysisPage: RootCauseAnalysisPage as unknown as ComponentClass,
  StatusPageModal: StatusPageModal,
  UserSettingsModal: UserSettingsModal as unknown as ComponentClass,
  V2Layout: V2Layout,
  ReportV2Page: ReportV2Page,
  ReportPublicView: ReportPublicView,
  ActionConnectionPage: ActionConnectionPage,
  SourceConnectionPage: SourceConnectionPage,
  WarehouseConnectionPage: WarehouseConnectionPage,
  FeatureCrossSellPage: FeatureCrossSellPage,
  Callback: Callback,
  Login: Login,
  PortalCallback: PortalCallback,
  PortalLogin: PortalLogin,
  Org: Org,
  RedirectPage: RedirectPage,
  ReportEmbedPage: ReportEmbedPage,
  ReportSharingLink: ReportSharingLink,
  ActionManagementPage: ActionManagementPage,
  ActionHistoryPage: ActionHistoryPage,
  AuditLogPage: AuditLogPage,
  BasicSettingPage: BasicSettingPage,
  EmailTemplate: EmailTemplate,
  NotificationJobsPage: NotificationJobsPage,
  NotificationTopicsPage: NotificationTopicsPage,
  PartnerPortalDetailPage: PartnerPortalDetailPage,
  PartnerPortalPage: PartnerPortalPage,
  SettingsPlatformUsage: SettingsPlatformUsage,
  ServiceAccountManagementPage:
    ServiceAccountManagementPage as unknown as ComponentClass,
  SharedReportsPage: SharedReportsPage,
  SettingsSourceUsage: SettingsSourceUsage,
  ThemeSettingPage: ThemeSettingPage,
  UnusedExplorationPage: UnusedExplorationPage,
  UserAttributesPage: UserAttributesPage,
  UserGroupManagementPage: UserGroupManagementPage,
  AccessManagementPage: AccessManagementPage as unknown as ComponentClass,
  WarehouseSettingsPage: WarehouseSettingsPage,
  WarehouseListPage: WarehouseListPage,
  ObjectPageContainer: ObjectPageContainer,
  RecordMjml: RecordMjml,
  RecordView: RecordView,
  RecordViewEmbed: RecordViewEmbed as unknown as ComponentClass,
  RecordViewPublicEmbed: RecordViewPublicEmbed,
  WorkbenchPageWrapper: WorkbenchPageWrapper,
  FolderPage: FolderPage,
  HomePage: HomePage,
  ObjectEmbedView: ObjectPageModal,
};
