import {
  CheckCircleTwoTone,
  DeleteOutlined,
  InfoCircleTwoTone,
  WarningTwoTone,
} from "@ant-design/icons";
import {
  Alert,
  Button,
  Col,
  Input,
  Modal,
  Space,
  Tag,
  Tooltip,
  Typography,
} from "antd";
import * as React from "react";
import { compose } from "../../../../../../components/compose/WlyCompose";
import Feednack from "../../../../../../components/layout/feedback/feedback";
import Loading from "../../../../../../components/layout/feedback/loading";
import type { AsyncData } from "../../../../../../helpers/typescriptHelpers";
import type {
  SchemaResult,
  Transformation,
} from "../../../../../../interfaces/transformations";
import { computeTransformations } from "../../../../../../services/BrizoService";
import { generateUniqueId } from "../../../../../../utils/uniqueId";
import type { InjectedOrgProps } from "../../../../../orgs/WithOrg";
import WithOrg from "../../../../../orgs/WithOrg";
import type { TableTabItem } from "../../../../../spreadsheet/domain";
import RelationshipModalForm from "../../../../../spreadsheet/relationships/RelationshipModalForm";
import TypeRenderer from "../../../../../spreadsheet/renderer/TypeRenderer";
import type {
  FetchedDestination,
  IDatasetUpdate,
  TabData,
} from "../../../domain";
import { computeRunResultStepName } from "../../../domain";

import type { ColumnProps } from "antd/lib/table";
import moment from "moment";
import type { InjectedAntUtilsProps } from "../../../../../../components/ant-utils/withAntUtils";
import { withAntUtils } from "../../../../../../components/ant-utils/withAntUtils";
import { BlurredWrapper } from "../../../../../../components/cards/BlurredWrapper";
import { WlyCard } from "../../../../../../components/cards/WlyCard";
import { WlyCardDangerZone } from "../../../../../../components/cards/WlyCardDangerZone";
import CardTable from "../../../../../../components/table/CardTable";
import { userCanDeleteDataset } from "../../../../../../helpers/sourceHelpers";
import type { IDestination } from "../../../../../../interfaces/destinations";
import type { IRunResult } from "../../../../../../interfaces/jobExecutions";
import type {
  IDataset,
  IDatasetRelationship,
  ISource,
} from "../../../../../../interfaces/sources";
import { LocaleService } from "../../../../../../services/localeService";
import PrimaryKeysModal from "../common/PrimaryKeysModal";
import { CacheStrategyCard } from "./CacheStrategy/CacheStrategyCard";
import ColumnTestsCard from "./ColumnTests/ColumnTestsCard";
import "./GeneralInformation.scss";
import PersistDatasetModalForm from "./PersistDataset/PersistDataset";
import { RelationshipSideRenderer } from "./relationship/RelationshipSideRenderer";
import { RelationshipTypeRenderer } from "./relationship/RelationshipTypeRenderer";

const { Text, Title } = Typography;

interface IGeneralInformationProps {
  tabs: TableTabItem[];
  currentTab: TabData;
  sources: Array<ISource>;
  activeViewKey: string;
  datasets: IDataset[];
  onCreateRelationship?: (createPayload: any) => Promise<void>;
  onUpdateRelationship?: (id: string, updatePayload: any) => Promise<void>;
  onDeleteRelationship?: (id: string) => Promise<void>;
  onDeleteDataset?: () => Promise<void>;
  onUpdateDataset?: IDatasetUpdate;
  currentTransformations: AsyncData<Transformation[]>;
  runResults: IRunResult[];
  destination: FetchedDestination;
  currentWarehouse: IDestination;
}

interface IState {
  loadingDescription: boolean;
  newDescription: string;
  descriptionModalOpen: boolean;
  relationshipModalOpen: boolean;
  schemaEditModalOpen: boolean;
  schemaEditModalSaving: boolean;
  schema: AsyncData<SchemaResult>;
  persistViewModalOpen: boolean;
  historyDrawerVisible: boolean;
}

interface SchemaColumn {
  domain: any;
  operation: string;
  name: string;
  description?: string;
  isPrimaryKey: boolean;
  isUserGenerated: boolean;
  isFormula: boolean;
  relationships: IDatasetRelationship[];
}

type Props = IGeneralInformationProps &
  InjectedOrgProps &
  InjectedAntUtilsProps;

class GeneralInformation extends React.Component<Props, IState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      loadingDescription: false,
      newDescription: undefined,
      descriptionModalOpen: false,
      relationshipModalOpen: false,
      schemaEditModalOpen: false,
      schemaEditModalSaving: false,
      persistViewModalOpen: false,
      historyDrawerVisible: false,
      schema: {
        status: "initial",
      },
    };
  }
  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate(prevProps: Props) {
    if (
      prevProps.org.id !== this.props.org.id ||
      prevProps.currentTab.dataset.id !== this.props.currentTab.dataset.id
    ) {
      this.fetchData();
    }
  }

  fetchData = () => {
    const { currentTransformations, currentWarehouse } = this.props;
    if (
      currentTransformations &&
      currentTransformations.status === "success" &&
      currentTransformations.data.length
    ) {
      this.setState({ schema: { status: "loading" } });
      return computeTransformations(currentWarehouse.id, {
        res: [
          ...currentTransformations.data,
          {
            var: generateUniqueId(),
            operation: {
              type: "Table.Schema",
              args: {
                table:
                  currentTransformations.data[
                    currentTransformations.data.length - 1
                  ].var,
              },
            },
            domain: "viewResolver",
          },
        ],
      })
        .then((r) => {
          this.setState({
            schema: { status: "success", data: r.data.res as SchemaResult },
          });
        })
        .catch((err) => {
          this.setState({ schema: { status: "error", error: err } });
        });
    } else {
      this.setState({
        schema: { status: "success", data: {} as SchemaResult },
      });
    }
  };

  renderRelationship = (
    relationship: IDatasetRelationship,
    sources: ISource[],
    side: "left" | "right"
  ) => {
    const dataset = relationship[side];
    const column = side === "left" ? relationship.from : relationship.to;
    const isModel = dataset?.isModel;
    return (
      <RelationshipSideRenderer
        isModel={isModel}
        datasetName={dataset?.name}
        datasetImg={dataset?.source?.sourceMeta?.publicInfo?.logo}
        columnName={column}
      />
    );
  };

  generateRelationshipsColumns = (
    onDelete
  ): ColumnProps<IDatasetRelationship>[] => {
    const { antUtils } = this.props;
    return [
      {
        title: "From",
        dataIndex: "from",
        key: "from",
        width: "40%",
        ellipsis: true,
        render: (v, s) => {
          return <>{this.renderRelationship(s, this.props.sources, "left")}</>;
        },
      },
      {
        title: "Type",
        dataIndex: "Type",
        key: "Type",
        width: "10%",
        render: (v, s) => {
          return <RelationshipTypeRenderer type={s.type} />;
        },
      },
      {
        title: "To",
        dataIndex: "To",
        key: "To",
        width: "40%",
        ellipsis: true,
        render: (v, s) => {
          return <>{this.renderRelationship(s, this.props.sources, "right")}</>;
        },
      },
      {
        title: "Action",
        dataIndex: "Action",
        key: "Action",
        width: "10%",
        render: (v, s) => {
          return (
            <Button
              shape="circle"
              disabled={!s.editable}
              icon={<DeleteOutlined />}
              onClick={() => {
                return new Promise<void>((resolve, reject) => {
                  antUtils.modal.confirm({
                    onCancel: () => {
                      return resolve();
                    },
                    onOk: () => {
                      return onDelete(s.id).then(resolve);
                    },
                    okButtonProps: {
                      danger: true,
                    },
                    okText: "Delete",
                    title: "Are you sure you want to proceed ?",
                    content:
                      "You are about to delete a relationship, this operation cannot be undone. Are you sure you want to proceed ?",
                  });
                });
              }}
            />
          );
        },
      },
    ];
  };

  generateColumns = (): ColumnProps<SchemaColumn>[] => [
    {
      title: "Type",
      dataIndex: "Type",
      key: "Type",
      width: 100,
      render: (v, s) => {
        return (
          <>
            <TypeRenderer domain={s.domain} formula={s.isFormula} />{" "}
            {(s.domain as string).toLocaleLowerCase()}
          </>
        );
      },
    },
    {
      title: "Column",
      dataIndex: "Column",
      key: "Column",
      ellipsis: true,
      render: (v, s) => {
        const classNames = ["schema-item"];
        if (s.isPrimaryKey) {
          classNames.push("primary-key");
        }
        return (
          <>
            <span className={classNames.join(" ")}>{s.name}</span>{" "}
            {s.isUserGenerated ? (
              <Tooltip title="This column has been user generated">
                <span className="schema-item-user-defined" />
              </Tooltip>
            ) : null}
            {s.isPrimaryKey ? <Tag color="gold">Primary Key</Tag> : undefined}
            {s.relationships.length ? (
              <Tag color="blue">Foreign Key</Tag>
            ) : undefined}
          </>
        );
      },
    },
    {
      title: "Description",
      dataIndex: "Description",
      key: "Description",
      render: (v, s) => {
        return <>{s.description}</>;
      },
    },
  ];

  public render() {
    const {
      tabs,
      currentTab,
      onCreateRelationship: onCreate,
      onDeleteRelationship: onDelete,
      onUpdateRelationship: onUpdate,
      onUpdateDataset,
      onDeleteDataset,
      currentWarehouse,
      destination,
      runResults,
      antUtils,
      user: { locale },
    } = this.props;
    const { schema } = this.state;

    if (schema.status === "initial" || schema.status === "loading") {
      return (
        <div className="dataset-general-information">
          <Loading />
        </div>
      );
    } else if (schema.status === "error") {
      return (
        <div className="dataset-general-information">
          <Feednack>{schema.error.message}</Feednack>
        </div>
      );
    }

    const renderMaterializationStatus = () => {
      const renderWarehouseSize = () => {
        const localeService = new LocaleService(locale);
        const numerald = localeService.getNumberDefaultFormatting();
        if (
          !currentTab.dataset?.warehouseSize ||
          (!currentTab.dataset?.warehouseSize?.rowCount &&
            !currentTab.dataset?.warehouseSize?.sizeBytes)
        )
          return undefined;
        return (
          <div style={{ marginTop: 8 }}>
            <Text strong>Storage info</Text>
            <ul>
              {currentTab.dataset?.warehouseSize?.rowCount ? (
                <li>
                  Number of rows:{" "}
                  {numerald(currentTab.dataset?.warehouseSize?.rowCount).format(
                    "0.[0]a"
                  )}
                </li>
              ) : undefined}
              {currentTab.dataset?.warehouseSize?.sizeBytes ? (
                <li>
                  Storage size:{" "}
                  {numerald(
                    currentTab.dataset?.warehouseSize?.sizeBytes
                  ).format("0.00b")}
                </li>
              ) : undefined}
            </ul>
          </div>
        );
      };
      if (
        currentTab.dataset.isModel &&
        currentTab.dataset.isPersistedAs !== "ephemeral"
      ) {
        return (
          <>
            <div>
              Model persisted as{" "}
              <Text code>{currentTab.dataset.isPersistedAs}</Text> in warehouse
              at{" "}
              <Text code>
                {currentTab.dataset.warehouseViewSchemaId} /{" "}
                {currentTab.dataset.warehouseViewTableId}
              </Text>
            </div>
            {renderWarehouseSize()}
          </>
        );
      } else if (
        currentTab.dataset.isModel &&
        currentTab.dataset.isPersistedAs === "ephemeral"
      ) {
        const localeService = new LocaleService(locale);
        const numerald = localeService.getNumberDefaultFormatting();
        return (
          <>
            <div>Model not persisted in warehouse</div>
            <div style={{ marginTop: 8 }}>
              <Text strong>Query info</Text>
              <ul>
                {currentTab.dataset?.warehouseSize?.sizeBytes ? (
                  <li>
                    Processed size:{" "}
                    {numerald(
                      currentTab.dataset?.warehouseSize?.sizeBytes
                    ).format("0.00b")}
                  </li>
                ) : undefined}
              </ul>
            </div>
          </>
        );
      } else if (
        currentTab.dataset.warehouseSchemaId &&
        currentTab.dataset.warehouseTableId &&
        currentTab.type !== "SQL"
      ) {
        return (
          <>
            <div>
              Raw data location in warehouse{" "}
              <Text code>
                {currentTab.dataset.warehouseSchemaId} /{" "}
                {currentTab.dataset.warehouseTableId}
              </Text>
            </div>
            {renderWarehouseSize()}
          </>
        );
      }
      return null;
    };

    return (
      <div className="dataset-general-information">
        <Col span={16} offset={4}>
          <Space direction="vertical" size={32} style={{ width: "100%" }}>
            <div>
              <Title style={{ paddingTop: 16 }} level={3}>
                {currentTab.dataset.name}
              </Title>
              {currentTab.dataset.managedBy === "DBT_CLOUD" ? (
                <Text>
                  Managed by dbt{" "}
                  <Text code>{currentTab.dataset.dbtFileName}</Text>
                </Text>
              ) : null}
            </div>
            {currentTab.runResults
              .filter((t) => t.status !== "success")
              .map((error, i) => {
                return (
                  <Alert
                    showIcon
                    key={i}
                    type="warning"
                    message={error.message}
                  />
                );
              })}
            <WlyCard
              title="Description"
              extra={
                currentTab.dataset.managedBy !== "DBT_CLOUD" ? (
                  <Button
                    type="primary"
                    size="small"
                    onClick={() =>
                      this.setState({ descriptionModalOpen: true })
                    }
                  >
                    Edit
                  </Button>
                ) : undefined
              }
            >
              {currentTab.dataset.description
                ? currentTab.dataset.description
                : "No description"}
            </WlyCard>
            {runResults.length ? (
              <WlyCard title="Run Results">
                <Space direction="vertical" size={32} style={{ width: "100%" }}>
                  {runResults.length
                    ? runResults.map((rr, i) => {
                        const renderStatus = () => {
                          switch (rr.status) {
                            case "success":
                              return (
                                <CheckCircleTwoTone
                                  twoToneColor="#52c41a"
                                  key={i}
                                />
                              );
                            case "error":
                              return (
                                <WarningTwoTone
                                  twoToneColor="#faad14"
                                  key={i}
                                />
                              );
                            default:
                              return <InfoCircleTwoTone key={i} />;
                          }
                        };

                        return (
                          <div key={i}>
                            <div>
                              <Typography.Text strong>
                                {renderStatus()} {computeRunResultStepName(rr)}{" "}
                                {rr.message ? " :" : ""}{" "}
                              </Typography.Text>
                            </div>
                            {rr.message ? <div>{rr.message}</div> : undefined}
                            {rr.generatedAt ? (
                              <div>
                                <Typography.Text type="secondary" italic>
                                  {moment(rr.generatedAt).format(
                                    "YYYY/MM/DD [at] HH:mm:ss"
                                  )}
                                </Typography.Text>
                              </div>
                            ) : null}
                          </div>
                        );
                      })
                    : "No description"}
                </Space>
              </WlyCard>
            ) : null}
            <BlurredWrapper
              message={
                !destination.persistenceEngineNextSyncDate
                  ? "Activate modeling to materialize your datasets in your warehouse and speed up queries"
                  : null
              }
            >
              <WlyCard
                title="Warehouse"
                extra={
                  onUpdateDataset &&
                  currentTab.dataset.managedBy !== "DBT_CLOUD" &&
                  currentTab.dataset.isModel && (
                    <Button
                      type="primary"
                      size="small"
                      disabled={currentWarehouse.destinationMeta.isWhalyManaged}
                      onClick={() =>
                        this.setState({ persistViewModalOpen: true })
                      }
                    >
                      Materialize
                    </Button>
                  )
                }
              >
                {renderMaterializationStatus()}
              </WlyCard>
            </BlurredWrapper>
            <CacheStrategyCard
              onUpdateDataset={onUpdateDataset}
              currentTab={currentTab}
              schema={schema}
            />
            <CardTable<SchemaColumn>
              cardTitle="Schema"
              actionButtons={
                onUpdateDataset && (
                  <Button
                    onClick={() => this.setState({ schemaEditModalOpen: true })}
                    type="primary"
                    size="small"
                  >
                    Edit primary keys
                  </Button>
                )
              }
              rowKey="id"
              dataSource={Object.keys(schema.data).map((k) => {
                return {
                  isPrimaryKey: currentTab.primaryKey.includes(k),
                  isFormula: schema.data[k].operation === "Table.AddColumn",
                  isUserGenerated: currentTab.userDefinedColumns.includes(k),
                  domain: schema.data[k].domain,
                  operation: schema.data[k].operation,
                  name: k,
                  description: schema.data[k].description,
                  relationships: currentTab.relationships.filter(
                    (r) => r.from === k
                  ),
                };
              })}
              columns={this.generateColumns()}
              pagination={{
                style: { display: "none" },
                defaultPageSize: 10000,
              }}
            />
            <ColumnTestsCard
              onUpdateDataset={onUpdateDataset}
              currentTab={currentTab}
              schema={schema}
            />
            <CardTable<IDatasetRelationship>
              size="middle"
              cardTitle="Relationships"
              actionButtons={
                onCreate &&
                onUpdate && (
                  <Button
                    onClick={() =>
                      this.setState({ relationshipModalOpen: true })
                    }
                    type="primary"
                    size="small"
                  >
                    New
                  </Button>
                )
              }
              rowKey="id"
              dataSource={currentTab.relationships.sort((a, b) =>
                a.from.localeCompare(b.from)
              )}
              columns={this.generateRelationshipsColumns(onDelete)}
              pagination={{
                style: { display: "none" },
                defaultPageSize: 10000,
              }}
            />
            {onDeleteDataset && userCanDeleteDataset(currentTab.dataset) && (
              <WlyCardDangerZone
                title="Danger Zone"
                button={
                  <Button
                    type="primary"
                    danger={true}
                    onClick={onDeleteDataset ? onDeleteDataset : undefined}
                  >
                    Delete
                  </Button>
                }
              >
                Click here to permanently delete this dataset. This cannot be
                undone.
              </WlyCardDangerZone>
            )}
            <div style={{ height: 32 }}></div>
          </Space>
        </Col>

        <Modal
          open={this.state.descriptionModalOpen}
          title={"Edit description"}
          onCancel={() => this.setState({ descriptionModalOpen: false })}
          okButtonProps={{ loading: this.state.loadingDescription }}
          onOk={async () => {
            try {
              this.setState({ loadingDescription: true });
              await onUpdateDataset({ description: this.state.newDescription });
            } catch (error) {
              console.warn(error);
              antUtils.message.error(
                "Error while updating the description, please try again"
              );
            } finally {
              this.setState({
                loadingDescription: false,
                newDescription: undefined,
                descriptionModalOpen: false,
              });
            }
          }}
        >
          <Input.TextArea
            rows={3}
            placeholder={"Give a description"}
            defaultValue={currentTab.dataset.description}
            onChange={(e) => this.setState({ newDescription: e.target.value })}
            onPressEnter={(e) => {
              // prevent line break
              e.preventDefault();
            }}
          ></Input.TextArea>
        </Modal>

        <Modal
          open={this.state.relationshipModalOpen}
          footer={null}
          title={"Create a relationship"}
          onCancel={() => this.setState({ relationshipModalOpen: false })}
        >
          {onCreate && onUpdate && (
            <RelationshipModalForm
              onCancel={() => this.setState({ relationshipModalOpen: false })}
              onFinish={() => this.setState({ relationshipModalOpen: false })}
              onUpdate={onUpdate}
              onCreate={onCreate}
              isStale={() => ({})}
              currentTabs={tabs}
              initialValues={{
                fromDatasetId: currentTab.dataset.id,
                fromColumnName: Object.keys(schema.data).length
                  ? Object.keys(schema.data)[0]
                  : "",
                type: "1-1",
              }}
              sources={this.props.sources}
              hideTitle={true}
            />
          )}
        </Modal>

        {onUpdateDataset && (
          <PrimaryKeysModal
            onCancel={() => this.setState({ schemaEditModalOpen: false })}
            onSave={async (values) => {
              try {
                this.setState({ schemaEditModalSaving: true });
                await onUpdateDataset({
                  primaryKey: values.keys.join(","),
                });
                this.setState({
                  schemaEditModalOpen: false,
                  schemaEditModalSaving: false,
                });
              } catch (error) {
                console.warn(error);
                antUtils.message.error("Error saving your primary keys");
                this.setState({
                  schemaEditModalOpen: false,
                  schemaEditModalSaving: false,
                });
              }
            }}
            getSchema={async () => schema.data}
            getInitialKeys={() => currentTab.primaryKey.filter((p) => p.length)}
            visible={this.state.schemaEditModalOpen}
            saving={this.state.schemaEditModalSaving}
            showOutdatedKeys
          />
        )}
        <Modal
          open={this.state.persistViewModalOpen}
          footer={null}
          title={"Materialize"}
          onCancel={() => this.setState({ persistViewModalOpen: false })}
        >
          {onUpdateDataset && (
            <PersistDatasetModalForm
              onCancel={() => this.setState({ persistViewModalOpen: false })}
              onFinish={() => this.setState({ persistViewModalOpen: false })}
              onUpdate={onUpdateDataset}
              initialValues={{
                warehouseViewDatabaseId:
                  currentTab.dataset.warehouseViewDatabaseId,
                warehouseViewSchemaId: currentTab.dataset.warehouseViewSchemaId,
                warehouseViewTableId: currentTab.dataset.warehouseViewTableId,
                isPersistedAs: currentTab.dataset.isPersistedAs,
              }}
              currentWarehouse={currentWarehouse}
            />
          )}
        </Modal>
      </div>
    );
  }
}

export default compose<Props, IGeneralInformationProps>(
  WithOrg,
  withAntUtils
)(GeneralInformation);
