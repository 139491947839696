import { InfoCircleOutlined } from "@ant-design/icons";
import { Form, Select, Typography } from "antd";
import type { FormInstance } from "antd/es/form/Form";
import type { IObject, IObjectView } from "../../../../../../interfaces/object";

interface PromoteObjectViewFormProps {
  form: FormInstance<PromoteObjectViewFormData>;
  initialValues?: Partial<PromoteObjectViewFormData>;
  object: IObject;
  views: IObjectView[];
  onSave: (values: PromoteObjectViewFormData) => any;
}

export type PromoteObjectViewFormData = {
  id?: string;
};

export function PromoteObjectViewForm(props: PromoteObjectViewFormProps) {
  const { form, initialValues, object, views, onSave } = props;
  return (
    <Form<PromoteObjectViewFormData>
      form={form}
      preserve={false}
      layout="vertical"
      initialValues={initialValues}
      onFinish={(values) => {
        onSave(values);
      }}
    >
      <Form.Item name={["id"]} rules={[{ required: true }]} noStyle>
        <Select
          style={{ width: "100%" }}
          options={[
            { label: `All ${object.name}`, value: "system" },
            ...views.map((view) => ({ label: view.name, value: view.id })),
          ]}
        />
      </Form.Item>
      <Form.Item>
        <Typography.Text
          type="secondary"
          style={{ paddingTop: 8, paddingBottom: 8 }}
        >
          <InfoCircleOutlined style={{ color: "gray", paddingRight: 8 }} />
          Everyone who opens this list will see this view first
        </Typography.Text>
      </Form.Item>
    </Form>
  );
}
