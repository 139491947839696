import cuid from "cuid";
import { inject, observer } from "mobx-react";
import * as React from "react";
import type { RouteComponentProps } from "react-router";
import { withRouter } from "react-router";
import type { InjectedAntUtilsProps } from "../../../components/ant-utils/withAntUtils";
import { withAntUtils } from "../../../components/ant-utils/withAntUtils";
import { compose } from "../../../components/compose/WlyCompose";
import Error from "../../../components/layout/feedback/error";
import Loading from "../../../components/layout/feedback/loading";
import type {
  IServiceAccount,
  IServiceAccountKey,
} from "../../../interfaces/serviceAccount";
import type { IUserRoleType } from "../../../interfaces/user";
import GraphQLService from "../../../services/graphql/GraphQLService";
import type { UserStoreProps } from "../../../store/userStore";
import GraphQl from "../../graphql/graphql";
import type { InjectedOrgProps } from "../../orgs/WithOrg";
import WithOrg from "../../orgs/WithOrg";
import ServiceAccountManagementTable from "./ServiceAccountManagementTable";
import { GET_SERVICE_ACCOUNTS } from "./domain";

interface IUserGroupManagementTableProps {}

const gql = GET_SERVICE_ACCOUNTS;

interface IReturnValue {
  allServiceAccounts: IServiceAccount[];
}

interface IState {
  render: string;
}

type Props = IUserGroupManagementTableProps &
  RouteComponentProps<{ organizationSlug: string }> &
  UserStoreProps &
  InjectedOrgProps &
  InjectedAntUtilsProps;

class ServiceAccountManagementTableWrapper extends React.Component<
  Props,
  IState
> {
  constructor(props: Props) {
    super(props);
    this.state = {
      render: cuid(),
    };
  }

  public reRender = () => {
    this.setState({ render: cuid() });
  };

  public removeKey = async (key: IServiceAccountKey) => {
    const gql = `
    mutation DeleteServiceAccountKey($serviceAccountId: ID!) {
      updateServiceAccountKey(
        id: $serviceAccountId,
        data: { isDeleted: true }
      ) {
        id
      }
    }
    `;

    await GraphQLService<{ id: string }>(
      gql,
      {
        serviceAccountId: key.id,
      },
      `updateServiceAccountKey`
    );
  };

  public createKey = async (serviceAccountId: string): Promise<string> => {
    const gql = `
    mutation CreateServiceAccountKey($serviceAccountId: ID!) {
      createServiceAccountKeyWithACL(
        data: { user: { connect: { id: $serviceAccountId } } }
      ) {
        value
      }
    }
    `;

    const resp = await GraphQLService<{ value: string }>(
      gql,
      {
        serviceAccountId,
      },
      `createServiceAccountKeyWithACL`
    );

    return resp.value;
  };

  public createServiceAccount = async (
    orgId: string,
    name: string,
    role: IUserRoleType
  ): Promise<string> => {
    const gql = `
    mutation CreateServiceAccount(
      $role: UserRoleRoleType!
      $orgId: ID!
      $name: String!
    ) {
      createServiceAccountWithACL(data: { role: $role, orgId: $orgId, name: $name }) {
        id
      }
    }
    `;

    const resp = await GraphQLService<{ id: string }>(
      gql,
      {
        name,
        role,
        orgId,
      },
      `createServiceAccountWithACL`
    );

    return resp?.id;
  };

  public render() {
    const { org } = this.props;
    return (
      <GraphQl<IReturnValue>
        query={gql}
        variables={{
          orgId: org.id,
          render: this.state.render,
        }}
      >
        {(gql) => {
          if (gql.status === "loading" || gql.status === "initial") {
            return <Loading />;
          }
          if (gql.status === "error") {
            return <Error />;
          }

          return (
            <ServiceAccountManagementTable
              serviceAccounts={gql.data.allServiceAccounts}
              reRender={this.reRender}
              createServiceAccount={this.createServiceAccount}
              createKey={this.createKey}
              removeKey={this.removeKey}
            />
          );
        }}
      </GraphQl>
    );
  }
}

export default compose<Props, IUserGroupManagementTableProps>(
  inject("userStore"),
  observer,
  withRouter,
  WithOrg,
  withAntUtils
)(ServiceAccountManagementTableWrapper);
