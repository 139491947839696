import * as Sentry from "@sentry/react";
import { Alert, Button } from "antd";
import * as React from "react";
import error500 from "../../../assets/500_text.svg";
import "./feedback.scss";

interface IErrorProps {
  err?: string | object | Error;
}

const displayError = (err: string | object | Error): string => {
  if (!err) return null;
  if (typeof err === "string") return err;
  if (err instanceof Error) {
    if (err.stack) return err.stack;
    return err.message;
  }
  if (typeof err === "object") {
    try {
      const stringified = JSON.stringify(err);
      return stringified;
    } catch (error) {
      return null;
    }
  }
  return null;
};

export default class ErrorFeedback extends React.Component<IErrorProps> {
  public render() {
    const { err } = this.props;
    const message = displayError(err);
    return (
      <div className="feedback">
        <img src={error500} alt="error 500" style={{ height: "60%" }} />
        <Alert
          className="feedback-error-alert"
          message="An unexpected error has happened"
          description={
            message ? message : "This error has no additionnal details"
          }
          type="error"
          showIcon
          action={
            <Button
              size="small"
              danger
              onClick={() => Sentry.showReportDialog()}
            >
              Contact support
            </Button>
          }
        />
      </div>
    );
  }
}
