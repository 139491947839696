import {
  LoadingOutlined,
  MoreOutlined,
  ThunderboltFilled,
} from "@ant-design/icons";
import type { MenuProps } from "antd";
import { Badge, Button, Dropdown, Typography } from "antd";
import * as React from "react";
import type { InjectedAntUtilsProps } from "../../../components/ant-utils/withAntUtils";
import { withAntUtils } from "../../../components/ant-utils/withAntUtils";
import { compose } from "../../../components/compose/WlyCompose";
import ExplorationEditDescription from "../../../components/explorations/modal/ExplorationEditDescription";
import type { ExplorationInitialData } from "../../../components/explorations/modal/ExplorationRename";
import ExplorationRename from "../../../components/explorations/modal/ExplorationRename";
import type { InjectedOrgProps } from "../../../containers/orgs/WithOrg";
import WithOrg from "../../../containers/orgs/WithOrg";
import type { IExploration } from "../../../interfaces/explorations";
import { IUserRoleType } from "../../../interfaces/user";
import GraphQLService from "../../../services/graphql/GraphQLService";
import HasRoleAccess from "../../user-settings/HasRoleAccess";
import { COLOR_DOWN, COLOR_UP } from "../../v2-demo/container/layout/domain";
import { CHECK_TILE_EXPLORATION_USAGE, UPDATE_QUERY } from "../single/domain";
import "./ExplorationMenuItem.scss";
import ExplorationMenuItemPopover from "./ExplorationMenuItemPopover";

const { Text } = Typography;
interface IExplorationMenuItemProps {
  exploration: IExploration;
  onClick: () => void;
  onDelete?: () => any;
  onEditDescription?: (v) => any;
  onRename?: (v: ExplorationInitialData) => any;
  showAccelerationStatus: boolean;
}

type Props = IExplorationMenuItemProps &
  InjectedOrgProps &
  InjectedAntUtilsProps;

function ExplorationMenuItem(props: Props) {
  const {
    antUtils,
    exploration,
    org,
    onClick,
    onDelete,
    onRename,
    onEditDescription,
    showAccelerationStatus,
  } = props;

  const [hover, setHover] = React.useState<boolean>(false);
  const [isRenameOpen, setIsRenameOpen] = React.useState(false);
  const [isEditDescriptionOpen, setIsEditDescriptionOpen] =
    React.useState(false);

  const dropdownContainerRef = React.useRef(null);

  const deleteExploration = async () => {
    try {
      const usage = await GraphQLService(CHECK_TILE_EXPLORATION_USAGE, {
        orgId: org.id,
        explorationId: exploration.id,
      });
      if (usage._allTilesMeta.count > 0) {
        return antUtils.modal.confirm({
          title: `This exploration is already in use in your reports`,
          content: `This exploration is used in ${usage._allTilesMeta.count} tiles. Please delete those tiles before deleting this table.`,
          okText: "Delete anyway",
          okButtonProps: {
            danger: true,
          },
          cancelText: "Cancel",
          onOk: async () => {
            await GraphQLService(UPDATE_QUERY, {
              explorationId: exploration.id,
              explorationUpdateInput: {
                deleted: true,
              },
            });
            antUtils.message.success("Exploration deleted");
            if (onDelete && typeof onDelete === "function") return onDelete();
            return true;
          },
        });
      } else {
        return antUtils.modal.confirm({
          title: `Delete this exploration?`,
          content: `You are about to delete this exploration. This cannot be undone. Do you wish to proceed?`,
          okText: "Ok",
          cancelText: "Cancel",
          onOk: async () => {
            await GraphQLService(UPDATE_QUERY, {
              explorationId: exploration.id,
              explorationUpdateInput: {
                deleted: true,
              },
            });
            antUtils.message.success("Exploration deleted");
            if (onDelete && typeof onDelete === "function") return onDelete();
            return true;
          },
        });
      }
    } catch (error) {
      antUtils.message.error("Error while deleting exploration");
      return console.error(error);
    }
  };

  const editDescription = () => {
    setIsEditDescriptionOpen(true);
  };

  const renameExploration = () => {
    setIsRenameOpen(true);
  };

  const menuItems: MenuProps["items"] = [
    onRename
      ? {
          key: "rename",
          onClick: renameExploration,
          label: "Rename",
        }
      : null,
    onEditDescription
      ? {
          key: "edit_description",
          onClick: editDescription,
          label: "Edit description",
        }
      : null,
    onDelete
      ? {
          key: "delete",
          danger: true,
          onClick: deleteExploration,
          label: "Delete",
        }
      : null,
  ];

  const renderServingLayerIcon = () => {
    if (exploration.extractSchedule?.status !== "idle") {
      return <LoadingOutlined />;
    }
    if (exploration.extractSchedule?.lastJobExecution?.status === "RUNNING") {
      return <LoadingOutlined />;
    }
    if (
      exploration.extractSchedule?.lastJobExecution?.status === "ERROR" ||
      exploration.extractSchedule?.lastJobExecution?.status === "FAILED"
    ) {
      return <ThunderboltFilled style={{ color: COLOR_DOWN }} />;
    }
    return <ThunderboltFilled style={{ color: COLOR_UP }} />;
  };

  return (
    <>
      <ExplorationMenuItemPopover
        exploration={exploration}
        showAccelerationStatus={showAccelerationStatus}
      >
        <div
          className="exploration-menu-item"
          id={`exploration-${exploration.id}`}
          onMouseEnter={() => {
            setHover(true);
          }}
          onMouseLeave={() => {
            setHover(false);
          }}
          ref={dropdownContainerRef}
        >
          {exploration.isExtractEnabled && showAccelerationStatus ? (
            <div className="exploration-menu-item-acceleration">
              {renderServingLayerIcon()}
            </div>
          ) : null}
          <div className="exploration-menu-item-name" onClick={onClick}>
            <span className={`exploration-menu-item-name-inner`}>
              <span className="exploration-menu-item-name-content">
                <Badge
                  dot={exploration.hasUpstreamErrors}
                  status="warning"
                  offset={[4, 4]}
                  style={{ width: 2 }}
                >
                  <Text ellipsis>{exploration.name}</Text>
                </Badge>
              </span>
            </span>
          </div>
          {(onRename || onDelete || onEditDescription) && (
            <HasRoleAccess accessLevel={IUserRoleType.BUILDER}>
              <div className="exploration-menu-item-actions" hidden={!hover}>
                <Dropdown
                  getPopupContainer={() => dropdownContainerRef?.current!}
                  menu={{
                    items: menuItems,
                  }}
                  trigger={["click"]}
                  destroyPopupOnHide={true}
                >
                  <Button
                    id={exploration.id}
                    onClick={(e) => e.stopPropagation()}
                    type="text"
                    shape="circle"
                    size="small"
                    icon={<MoreOutlined />}
                  />
                </Dropdown>
              </div>
            </HasRoleAccess>
          )}
        </div>
      </ExplorationMenuItemPopover>
      {onEditDescription && isEditDescriptionOpen && (
        <ExplorationEditDescription
          initialData={exploration}
          visible={isEditDescriptionOpen}
          onSave={(v) => {
            setIsEditDescriptionOpen(false);
            onEditDescription(v);
          }}
          onCancel={() => {
            setIsEditDescriptionOpen(false);
          }}
        />
      )}
      {onRename && (
        <ExplorationRename
          initialData={exploration}
          visible={isRenameOpen}
          onSave={(v) => {
            setIsRenameOpen(false);
            onRename(v);
          }}
          onCancel={() => {
            setIsRenameOpen(false);
          }}
        />
      )}
    </>
  );
}

export default compose<Props, IExplorationMenuItemProps>(
  WithOrg,
  withAntUtils
)(ExplorationMenuItem);
