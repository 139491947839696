import { getObjectColumns } from "../../../../../object/domain";
import type { AvailableProperty } from "../../../../../object/viewer/domain";
import { parseLabel } from "../../../../domain";
import type { IWidgetEmailMjmlRenderer } from "../../domain";
import type { IEmailWidgetImageConfig } from "../domain";
import { getDefaultConfig } from "../domain";

export const EmailWidgetImageMjml: IWidgetEmailMjmlRenderer<
  IEmailWidgetImageConfig
> = async (widget, object, record, conf) => {
  const defaults = getDefaultConfig(conf, record);
  const columns = getObjectColumns(object);
  const selectedColumn = columns
    .map((c) => c.data)
    .find((c) => c.key === conf.src) as AvailableProperty | undefined;

  let imageSrc = defaults.imageSrc;

  if (selectedColumn) {
    if (selectedColumn.type !== "standard") {
      const t = parseLabel(record[selectedColumn.key] as string);
      if (t && t.image) {
        imageSrc = t.image;
      }
    }
  }

  const padding =
    defaults.spacing === "normal"
      ? `padding-top="10" padding-bottom="10"`
      : `padding-top="0" padding-bottom="0"`;

  return `<mj-image ${padding} align="${defaults.align}" width="${defaults.width}px" border-radius="${defaults.borderRadius}px" src="${imageSrc}" />`;
};
