import * as React from "react";
import type { RouteComponentProps } from "react-router";
import { withRouter } from "react-router";
import { compose } from "../../../components/compose/WlyCompose";
import Measures from "../../../components/measures/Measures";
import { SplitView } from "../../../components/resizable/SplitView";
import type { IExploration } from "../../../interfaces/explorations";
import type { InjectedOrgProps } from "../../orgs/WithOrg";
import WithOrg from "../../orgs/WithOrg";
import { VisualizationWrapper } from "./VisualizationWrapper";
import type { GetCurrentQuery, ILagoonQuery } from "./domain";
import type { IAsyncMeta } from "./visualization/chart/Chart";

interface IExplorationContentProps {
  isEmbeded?: boolean;
  exploration: IExploration;
  getCurrentQuery?: GetCurrentQuery;
  initialQuery?: ILagoonQuery;
  initialQueryLoad?: boolean;
  renderRoute?: (params: object, query?: object | undefined) => string;
  overrideObjectType?: "VIEW" | "EXPLORATION";
}

interface IState {
  meta: IAsyncMeta;
}

type Props = IExplorationContentProps &
  InjectedOrgProps &
  RouteComponentProps<{
    organizationSlug: string;
    explorationSlug: string;
  }>;

class ExplorationContent extends React.Component<Props, IState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      meta: { status: "initial" },
    };
  }

  public render() {
    const {
      getCurrentQuery,
      initialQuery,
      exploration,
      initialQueryLoad,
      isEmbeded,
      overrideObjectType,
    } = this.props;

    return (
      <>
        <SplitView
          mainClassName="exploration-content-wrapper"
          startWidth={300}
          left={
            <Measures
              isEmbeded={isEmbeded}
              exploration={exploration}
              renderRoute={this.props.renderRoute}
            />
          }
          leftClassName="measures-panel"
          right={
            <VisualizationWrapper
              exploration={exploration}
              getCurrentQuery={getCurrentQuery}
              initialQuery={initialQuery}
              initialQueryLoad={initialQueryLoad}
              isEmbeded={isEmbeded}
              overrideObjectType={overrideObjectType}
            />
          }
          rightClassName="visualization-content-wrapper"
          minWidth={210}
          maxWidth={"40%"}
          alignement="LTR"
        />
      </>
    );
  }
}

export default compose<Props, IExplorationContentProps>(
  WithOrg,
  withRouter
)(ExplorationContent);
