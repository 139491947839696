import { WarningOutlined } from "@ant-design/icons";
import { Button, Tooltip, type ButtonProps } from "antd";
import {
  type IObject,
  type IObjectRegisteredAction,
} from "../../../../../../../interfaces/object";
import { type IRecord } from "../../../domain";
import { FeedbackActionButtonRenderer } from "./feedback/FeedbackActionButtonRenderer";
import LinkActionButtonRenderer from "./link/LinkActionButtonRenderer";

export interface IActionButtonRendererProps extends ButtonProps {
  display: "ICON" | "TEXT";
  registeredAction: IObjectRegisteredAction;
  record: IRecord;
  object: IObject;
  edit?: boolean;
  // Allows us to understand in which context the feedback is used and will be removed for instance
  // pass userId.parentObjectId.parentRecordId.currentObjectId.currentRecordId to remove the recommendation for a
  // sub object
  contextKey: string;
}

export function ActionButtonRenderer(props: IActionButtonRendererProps) {
  const {
    display,
    registeredAction,
    record,
    object,
    contextKey,
    edit,
    ...rest
  } = props;

  const parseConfig = () => {
    try {
      return JSON.parse(registeredAction.config);
    } catch (err) {
      console.error(err);
      return {};
    }
  };

  const config = parseConfig();

  if (registeredAction.type === "FEEDBACK") {
    return (
      <FeedbackActionButtonRenderer
        registeredAction={registeredAction}
        contextKey={contextKey}
        conf={config}
        record={record}
        buttonProps={{
          ...rest,
          onClick: (e) => {
            e.stopPropagation();
            if (rest.onClick) {
              rest.onClick(e);
            }
          },
        }}
        display={display}
        object={object}
      />
    );
  } else if (registeredAction.type === "EXTERNAL_LINK") {
    return (
      <LinkActionButtonRenderer
        registeredAction={registeredAction}
        contextKey={contextKey}
        conf={config}
        record={record}
        buttonProps={{
          ...rest,
          disabled: edit,
          onClick: (e) => {
            e.stopPropagation();
            if (rest.onClick) {
              rest.onClick(e);
            }
          },
        }}
        display={display}
        object={object}
      />
    );
  }

  return (
    <Tooltip placement="left">
      <Button
        danger
        icon={display === "ICON" ? <WarningOutlined /> : undefined}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        {display === "TEXT" ? "Not implemented" : undefined}
      </Button>
    </Tooltip>
  );
}
