import { Form, Input, Typography } from "antd";
import _ from "lodash";
import * as React from "react";
import { compose } from "../../../components/compose/WlyCompose";
import FormActions from "../../../components/form/actions/FormActions";
import type { AsyncData } from "../../../helpers/typescriptHelpers";
import type {
  SchemaResult,
  TableAddColumnOperation,
  Transformation,
} from "../../../interfaces/transformations";
import { functionDefinition } from "../../../parser/functions";
import type { FunctionExplaination } from "../../../services/ParseService";
import { checkFunction } from "../../../services/ParseService";
import type { InjectedOrgProps } from "../../orgs/WithOrg";
import WithOrg from "../../orgs/WithOrg";
import type { ICreateComponentProps } from "../domain";
import {
  catchErrors,
  replaceRemoveColumns,
  validateColumnName,
} from "../domain";

import cuid from "cuid";
import FormulaEditor from "../../../components/formula/workbench/FormulaEditor";
import Feednack from "../../../components/layout/feedback/feedback";
import Loading from "../../../components/layout/feedback/loading";
import type { IDestination } from "../../../interfaces/destinations";
import { computeTransformations } from "../../../services/BrizoService";
import { generateUniqueId } from "../../../utils/uniqueId";

type CreateEditProps = ICreateComponentProps<{
  var: string;
  operation: TableAddColumnOperation;
  domain: "datasetResolver";
}> & {
  columns: string[];
  currentWarehouse: IDestination;
};

type Props = CreateEditProps & InjectedOrgProps;

const id = cuid();

const CreateEdit: React.FunctionComponent<Props> = (props) => {
  const {
    onSave,
    currentTransformation,
    transformations,
    onCancel,
    currentSchema,
    isStale,
    org,
    setFormInstance,
    currentWarehouse,
  } = props;

  const [form] = Form.useForm();
  const [submitting, setSubmitting] = React.useState<boolean>(false);
  const [schemas, setSchemas] = React.useState<AsyncData<SchemaResult>>({
    status: "initial",
  });
  const [formula, setFormula] = React.useState<string>(
    currentTransformation.operation.args.columnGenerator
  );
  const [activeColumns, setActiveColumns] = React.useState<
    string[] | undefined
  >(currentTransformation.operation.args.columns);

  const { Text, Link } = Typography;

  if (setFormInstance) {
    setFormInstance(form);
  }

  React.useEffect(() => {
    async function fetchSchemas() {
      setSchemas({ status: "loading" });

      await computeTransformations(currentWarehouse.id, {
        full: replaceRemoveColumns([
          ...transformations,
          {
            var: generateUniqueId(),
            operation: {
              type: "Table.Schema",
              args: {
                table: transformations[transformations.length - 1].var,
              },
            },
            domain: "viewResolver",
          },
        ]),
      })
        .then((r) => {
          setSchemas({ status: "success", data: r.data.full as any });
        })
        .catch((err) => {
          setSchemas({ status: "error", error: err });
        });
    }
    fetchSchemas();
  }, [currentWarehouse?.id]);

  if (schemas.status === "loading" || schemas.status === "initial") {
    return <Loading />;
  }

  if (schemas.status === "error") {
    return <Feednack>{schemas.error?.message}</Feednack>;
  }

  const parser = (
    formula: string,
    callback: (explainations: FunctionExplaination[]) => void
  ) => {
    return checkFunction(formula, currentSchema, callback);
  };

  return (
    <Form
      form={form}
      initialValues={currentTransformation.operation.args}
      onFieldsChange={() => isStale && isStale(true)}
      className="form-dropdown-form"
      onFinish={async (v) => {
        try {
          setSubmitting(true);
          // if (form.getFieldsError(["columnGenerator"]).length || form.getFieldsError(["newColumnName"]).length) {
          //   return;
          // }

          const newTransformation: Transformation = {
            ...currentTransformation,
            operation: {
              ...currentTransformation.operation,
              args: {
                ...currentTransformation.operation.args,
                ...v,
                columnGenerator: formula,
                columns: activeColumns,
              },
            },
          };
          const isEditing = transformations.find(
            (t) => t.var === newTransformation.var
          );
          if (isEditing) {
            await onSave(
              transformations.map((t) => {
                if (t.var === newTransformation.var) {
                  return newTransformation;
                }
                return t;
              }),
              undefined,
              (newTransformation.operation as TableAddColumnOperation).args
                .newColumnName
            );
            if (onCancel) {
              onCancel();
            }

            setSubmitting(false);
          } else {
            await onSave(
              [...transformations, newTransformation],
              undefined,
              (newTransformation.operation as TableAddColumnOperation).args
                .newColumnName
            );
            if (onCancel) {
              onCancel();
            }
            setSubmitting(false);
          }
        } catch (err) {
          console.error("err", err);
          catchErrors(err, id);
          setSubmitting(false);
        }
      }}
      layout="vertical"
    >
      <div className="form-dropdown-form-header" id={id}>
        <Form.Item
          name={["newColumnName"]}
          label="Column Name"
          rules={[
            {
              required: true,
            },
            {
              validator: validateColumnName(
                Object.keys(schemas.data),
                currentTransformation.operation.args.newColumnName
              ),
            },
          ]}
        >
          <Input />
        </Form.Item>
      </div>
      <div className="form-dropdown-form-content">
        <Form.Item
          name={["columnGenerator"]}
          label="Formula"
          rules={[
            {
              validator: (_, v) => {
                const result = parser(v, () => {});
                if (result) {
                  return Promise.reject(`Error in ${result.found}`);
                }
                return Promise.resolve();
              },
            },
          ]}
        >
          <FormulaEditor
            value={formula}
            onChange={setFormula}
            availableFormulas={functionDefinition}
            availableColumns={Object.keys(currentSchema)}
            recordColumns={(c) => {
              if (!_.isEqual(c, activeColumns)) {
                setActiveColumns(c);
              }
            }}
          />
        </Form.Item>
      </div>
      <Text style={{ margin: "8px 0px" }}>
        📚 Read our formulas{" "}
        <Link
          href="https://docs.whaly.io/data-management/workbench/formulas"
          target="whalydoc"
        >
          documention
        </Link>
      </Text>
      {onCancel && onSave && (
        <div className="form-dropdown-form-buttons">
          <FormActions
            flex={true}
            size="small"
            onCancel={onCancel}
            isSubmitting={submitting}
          />
        </div>
      )}
    </Form>
  );
};

export default compose<Props, CreateEditProps>(WithOrg)(CreateEdit);
