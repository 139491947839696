import _ from "lodash";
import type { ComponentType } from "react";
import { useMemo, useState } from "react";
import useResizeObserver from "use-resize-observer";

type size = {
  width: number;
  height: number;
};

export function withHeightSizer<T>(WrappedComponent: ComponentType<T>) {
  return function WithHeightSizer(props: T) {
    const [size, setSize] = useState<size>({
      width: 0,
      height: 0,
    });

    const onResize = useMemo(() => _.debounce(setSize, 100), []);
    const { ref } = useResizeObserver({ onResize });

    return (
      <div ref={ref} style={{ width: "100%", height: "100%" }}>
        <WrappedComponent {...props} height={size.height} />
      </div>
    );
  };
}
