import * as React from "react";

import "./ColorPicker.scss";

interface IColorRendererProps {
  style?: React.CSSProperties;
  onClick?: () => void;
  hasMargin?: boolean;
  selected?: boolean;
  color: string;
  children?: React.ReactNode;
}

export default class ColorRenderer extends React.PureComponent<IColorRendererProps> {
  public render() {
    const { style, onClick, hasMargin, color, selected } = this.props;
    const classes: string[] = ["color-picker-viewer"];
    if (hasMargin) {
      classes.push("margin");
    }
    if (selected) {
      classes.push("selected");
    }
    const styles: React.CSSProperties = {
      position: "absolute",
      inset: 0,
      zIndex: 2,
      borderRadius: 3,
    };
    if (color) {
      styles.background = color;
    }
    return (
      <div
        className={classes.join(" ")}
        style={{
          ...style,
          border: "1px solid #cecece",
          borderRadius: 3,
        }}
        onClick={onClick}
      >
        <div
          style={{
            position: "absolute",
            inset: 0,
            background: `url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAAXNSR0IArs4c6QAAADFJREFUOE9jZGBgEGHAD97gk2YcNYBhmIQBgWSAP52AwoAQwJvQRg1gACckQoC2gQgAIF8IscwEtKYAAAAASUVORK5CYII=")`,
          }}
        >
          <div style={styles}>{this.props.children}</div>
        </div>
      </div>
    );
  }
}
