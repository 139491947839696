import RecordView from "./RecordView";

type RecordViewEmbedProps = {};

function RecordViewEmbed(props: RecordViewEmbedProps) {
  return (
    <div style={{ overflowX: "auto", background: "white", height: "100%" }}>
      <RecordView />
    </div>
  );
}

export default RecordViewEmbed;
