import { Col, Row } from "antd";
import { inject, observer } from "mobx-react";
import * as React from "react";
import type { RouteComponentProps } from "react-router-dom";
import { withRouter } from "react-router-dom";
import { compose } from "../../components/compose/WlyCompose";
import type { BreadcrumbStoreProps } from "../../store/breadCrumbStore";
import type { UserStoreProps } from "../../store/userStore";
import OrgRealmTable from "./OrgRealmTable";
import RealmSSOInfoForm from "./RealmSSOInfoForm";
import UserRealmTable from "./UserRealmTable";

interface IRealmSettingsPageProps {}

type Props = IRealmSettingsPageProps &
  RouteComponentProps<{}> &
  BreadcrumbStoreProps &
  UserStoreProps;

class RealmSettingsPage extends React.Component<Props> {
  public componentDidMount() {
    this.props.breadcrumbStore.setBreadcrumb([{ label: "Team Settings" }]);
  }

  public render() {
    const {
      location: { state },
      userStore,
    } = this.props;

    const realm = userStore.user.realm.realm;

    return (
      <Row justify="center" className="access-management" gutter={[24, 24]}>
        <Col xs={24}>
          <RealmSSOInfoForm realm={realm} />
        </Col>
        <Col xs={24}>
          <OrgRealmTable
            realm={realm}
            isSSOEnabled={realm.identityProviderType === "work_os"}
          />
        </Col>
        <Col xs={24}>
          <UserRealmTable realm={realm} />
        </Col>
        <Col xs={24} />
      </Row>
    );
  }
}

export default compose<Props, IRealmSettingsPageProps>(withRouter)(
  inject("breadcrumbStore")(inject("userStore")(observer(RealmSettingsPage)))
);
