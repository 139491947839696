import { gql } from "@apollo/client";
import type { QueryOptions } from "../../../../../../../components/hooks/query/useGQLQuery";
import { useGQLQuery } from "../../../../../../../components/hooks/query/useGQLQuery";
import type { MutateOptions } from "../../../../../../../components/hooks/query/useQGLMutate";
import { useGQLMutate } from "../../../../../../../components/hooks/query/useQGLMutate";
import { OBJECT_QUERY_BUILDER_SECTION_FRAGMENT } from "../../../../../../../fragments/object";
import type { IObject, IObjectQueryBuilderSection } from "../../../../../../../interfaces/object";
import { OBJECT_COMPLETE_FRAGMENT } from "../../domain";

type QueryData = {
  allObjectQueryBuilderSections: IObjectQueryBuilderSection[];
};

type QueryVariables = {
  objectId: string;
  orgId: string;
};

const ObjectQueryBuilderSectionQuery = gql`
  ${OBJECT_QUERY_BUILDER_SECTION_FRAGMENT}

  query allObjectQueryBuilderSections($orgId: ID!, $objectId: ID!) {
    allObjectQueryBuilderSections(
      where: {
        isDeleted: false
        org: { id: $orgId }
        object: { id: $objectId }
      }
      sortBy: order_ASC
    ) {
      ...ObjectQueryBuilderSectionFragment
    }
  }
`;

export const useGetObjectQueryBuilderSections = (
  options: QueryOptions<QueryData, QueryVariables>
) => {
  return useGQLQuery<QueryData, QueryVariables>(
    ObjectQueryBuilderSectionQuery,
    options
  );
};

type CreateMutationVariables = {
  data: Record<string, any>;
};

type CreateMutationData = {
  createObjectQueryBuilderSection: IObjectQueryBuilderSection;
};

const CreateObjectQueryBuilderSectionsMutation = gql`
  ${OBJECT_QUERY_BUILDER_SECTION_FRAGMENT}

  mutation CreateObjectQueryBuilderSection(
    $data: ObjectQueryBuilderSectionCreateInput!
  ) {
    createObjectQueryBuilderSection(data: $data) {
      ...ObjectQueryBuilderSectionFragment
    }
  }
`;

export const useCreateObjectQueryBuilderSection = (
  options?: MutateOptions<CreateMutationData>
) => {
  return useGQLMutate<CreateMutationData, CreateMutationVariables>(
    CreateObjectQueryBuilderSectionsMutation,
    options
  );
};

type UpdateMutationVariables = {
  data: Array<{ id: string; data: Record<string, any> }>;
};

type UpdateMutationData = {
  updateObjectQueryBuilderSections: IObjectQueryBuilderSection[];
};

const UpdateObjectPresetFilterMutation = gql`
  ${OBJECT_QUERY_BUILDER_SECTION_FRAGMENT}

  mutation UpdateObjectQueryBuilderSection(
    $data: [ObjectQueryBuilderSectionsUpdateInput]
  ) {
    updateObjectQueryBuilderSections(data: $data) {
      ...ObjectQueryBuilderSectionFragment
    }
  }
`;

export const useUpdateObjectQueryBuilderSections = (
  options?: MutateOptions<UpdateMutationData>
) => {
  return useGQLMutate<UpdateMutationData, UpdateMutationVariables>(
    UpdateObjectPresetFilterMutation,
    options
  );
};

const ObjectQuery = gql`
  ${OBJECT_COMPLETE_FRAGMENT}

  query allObjects($orgId: ID!) {
    allObjects(where: { org: { id: $orgId }, isDeleted_not: true }) {
      ...ObjectQuery
    }
  }
`;

type AllObjectsQueryData = {
  allObjects: IObject[];
};

type AllObjectQueryVariables = {
  orgId: string;
};

export const useGetObjects = (
  options: QueryOptions<AllObjectsQueryData, AllObjectQueryVariables>
) => {
  return useGQLQuery<AllObjectsQueryData, AllObjectQueryVariables>(ObjectQuery, options);
};
