import _ from "lodash";
import type { ConfigType } from "../../../containers/chart-options/ChartOptions";
import type { IAnalysisType } from "../../../containers/exploration/single/domain";
import { WlyRetentionChartIcon } from "../../icons/custom-icons/WlyRetentionChartIcon";
import type { SingleChartDefinition } from "../domain";

export const retentionChartDefinition: SingleChartDefinition = {
  label: "Retention chart",
  allowTimeComparison: false,
  minMetrics: 2,
  maxMetrics: 2,
  minDimensions: 2,
  maxDimensions: 2,
  icon: <WlyRetentionChartIcon />,
  axis: {
    bottom: {
      editable: false,
      isTimeFormattable: () => false,
    },
    left: {
      editable: false,
    },
  },
  pivot: (analysisType: IAnalysisType) => false,
  canDisplay: (rs) => undefined,
  downloadCSV: ({ query, resultSet, additionalResultSet }) => {
    if (
      resultSet.status !== "success" ||
      additionalResultSet?.status !== "success"
    ) {
      return undefined;
    }

    const labelKey = query.selectedDimensions[0];
    const periodKey = query.selectedDimensions[1];

    // we recreate the data based on the resultSet and additionalResultSet
    const data = [...resultSet.data.tablePivot()]
      .map((line) => {
        const match =
          additionalResultSet.data
            .tablePivot({
              y: [periodKey],
            })
            .find((l) => l[labelKey] === line[labelKey]) || {};
        const cleanedMatch = _.omit(match, labelKey);
        return {
          ...line,
          ...cleanedMatch,
        };
      })
      .sort((a, b) => {
        if (
          typeof a[labelKey] === "string" &&
          typeof b[labelKey] === "string"
        ) {
          return a[labelKey].localeCompare(b[labelKey]);
        } else {
          return a[labelKey] - b[labelKey];
        }
      });

    // we recreate the headers based on the resultSet and additionalResultSet
    const rawHeaders = [
      ...resultSet.data.tableColumns(),
      ...additionalResultSet.data
        .tableColumns({
          y: [periodKey],
        })
        ?.filter((col) => col.key !== labelKey),
    ];

    // we make sure the the headers follows the columns order
    const headers = Object.keys(data[0] || {}).map(
      (k) => rawHeaders.find((h) => h.key === k.split(",")[0])?.shortTitle ?? ""
    );

    const csvData = {
      headers: headers,
      data: data,
    };
    return csvData;
  },
  config: (a) => {
    const ret: Array<ConfigType> = [
      "retention-percent",
      "retention-colors",
      "palette-continue",
    ];
    return ret;
  },
  extractCustomizationDimensions: (d, q) => {
    return {
      dimensions: [],
    };
  },
  canPredict: (analysisType, query) => false,
  extractCustomizationSeries: (series, d) => {
    return {
      series: [{ key: "retention", label: "Retention" }],
      customColor: false,
      customLabel: false,
      customType: [],
    };
  },
};
