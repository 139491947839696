import Icon from "@ant-design/icons";
import type { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";

export const WlyDashboardIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon
    component={() => (
      <svg
        width="1em"
        height="1em"
        viewBox="0 0 259 308"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="113" height="99" rx="30" fill="currentColor" />
        <rect y="136" width="113" height="172" rx="30" fill="currentColor" />
        <rect x="146" width="113" height="169" rx="30" fill="currentColor" />
        <rect
          x="146"
          y="209"
          width="113"
          height="99"
          rx="30"
          fill="currentColor"
        />
      </svg>
      // <svg
      //   xmlns="http://www.w3.org/2000/svg"
      //   className="icon icon-tabler icon-tabler-layout-dashboard"
      //   width="1em"
      //   height="1em"
      //   viewBox="0 0 1024 1024"
      //   fill="currentColor"
      // >
      //   <path d="M184.888889 554.666667h227.555555c31.288889 0 56.888889-25.6 56.888889-56.888889V184.888889c0-31.288889-25.6-56.888889-56.888889-56.888889H184.888889c-31.288889 0-56.888889 25.6-56.888889 56.888889v312.888889c0 31.288889 25.6 56.888889 56.888889 56.888889z m0-369.777778h227.555555v312.888889H184.888889V184.888889zM184.888889 896h227.555555c31.288889 0 56.888889-25.6 56.888889-56.888889V696.888889c0-31.288889-25.6-56.888889-56.888889-56.888889H184.888889c-31.288889 0-56.888889 25.6-56.888889 56.888889v142.222222c0 31.288889 25.6 56.888889 56.888889 56.888889z m0-199.111111h227.555555v142.222222H184.888889V696.888889zM611.555556 896h227.555555c31.288889 0 56.888889-25.6 56.888889-56.888889V526.222222c0-31.288889-25.6-56.888889-56.888889-56.888889H611.555556c-31.288889 0-56.888889 25.6-56.888889 56.888889v312.888889c0 31.288889 25.6 56.888889 56.888889 56.888889z m0-369.777778h227.555555v312.888889H611.555556V526.222222zM554.666667 184.888889v142.222222c0 31.288889 25.6 56.888889 56.888889 56.888889h227.555555c31.288889 0 56.888889-25.6 56.888889-56.888889V184.888889c0-31.288889-25.6-56.888889-56.888889-56.888889H611.555556c-31.288889 0-56.888889 25.6-56.888889 56.888889z m284.444444 142.222222H611.555556V184.888889h227.555555v142.222222z" />
      // </svg>
    )}
    {...props}
  />
);
