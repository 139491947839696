import { Form, Input, Typography } from "antd";
import * as React from "react";
import { compose } from "../../../../../../../../components/compose/WlyCompose";
import type {
  TableAddColumnOperation,
  Transformation,
} from "../../../../../../../../interfaces/transformations";
import { functionDefinition } from "../../../../../../../../parser/functions";
import type { FunctionExplaination } from "../../../../../../../../services/ParseService";
import { checkFunction } from "../../../../../../../../services/ParseService";
import type { InjectedOrgProps } from "../../../../../../../orgs/WithOrg";
import WithOrg from "../../../../../../../orgs/WithOrg";

import cuid from "cuid";
import _ from "lodash";
import FormulaEditor from "../../../../../../../../components/formula/workbench/FormulaEditor";
import {
  catchErrors,
  validateColumnName,
} from "../../../../../../../transformations/domain";
import type { FlowOperationFormProps } from "../domain";

type AddColumnFormProps = FlowOperationFormProps<{
  var: string;
  operation: TableAddColumnOperation;
  domain: "datasetResolver";
}>;

type Props = AddColumnFormProps & InjectedOrgProps;

const id = cuid();

const AddColumnForm: React.FunctionComponent<Props> = (props) => {
  const {
    onSave,
    currentTransformation,
    onCancel,
    prevStepSchema,
    isStale,
    setSubmitting,
    setFormInstance,
  } = props;

  const [form] = Form.useForm();
  const [formula, setFormula] = React.useState<string>(
    currentTransformation.operation.args.columnGenerator
  );
  const [activeColumns, setActiveColumns] = React.useState<
    string[] | undefined
  >(currentTransformation.operation.args.columns);

  const { Text, Link } = Typography;

  React.useEffect(() => {
    if (setFormInstance) {
      setFormInstance(form);
    }
  }, [setFormInstance, form]);

  const isFormDisabled = !onSave;

  const parser = (
    formula: string,
    callback: (explainations: FunctionExplaination[]) => void
  ) => {
    return checkFunction(formula, prevStepSchema, callback);
  };

  return (
    <Form
      form={form}
      initialValues={currentTransformation.operation.args}
      onFieldsChange={() => isStale && isStale(true)}
      className="form-dropdown-form"
      onFinish={async (v) => {
        try {
          setSubmitting(true);
          const newTransformation: Transformation = {
            ...currentTransformation,
            operation: {
              ...currentTransformation.operation,
              args: {
                ...currentTransformation.operation.args,
                ...v,
                columnGenerator: formula,
                columns: activeColumns,
              },
            },
          };

          await onSave([
            {
              type: "UPDATE",
              transformation: newTransformation,
            },
          ]);

          if (onCancel) {
            onCancel();
          }

          setSubmitting(false);
        } catch (err) {
          console.error("err", err);
          catchErrors(err, id);
          setSubmitting(false);
        }
      }}
      layout="vertical"
    >
      <div className="form-dropdown-form-header" id={id}>
        <Form.Item
          name={["newColumnName"]}
          label="Column Name"
          rules={[
            {
              required: true,
            },
            {
              validator: validateColumnName(
                Object.keys(prevStepSchema),
                currentTransformation.operation.args.newColumnName
              ),
            },
          ]}
        >
          <Input disabled={isFormDisabled} />
        </Form.Item>
      </div>
      <div className="form-dropdown-form-content">
        <Form.Item
          name={["columnGenerator"]}
          label="Formula"
          rules={[
            {
              required: true,
            },
            {
              validator: (_, v) => {
                const result = parser(v, () => {});
                if (result) {
                  return Promise.reject(`Error in ${result.found}`);
                }
                return Promise.resolve();
              },
            },
          ]}
        >
          <FormulaEditor
            availableFormulas={functionDefinition}
            availableColumns={Object.keys(prevStepSchema)}
            onChange={setFormula}
            recordColumns={(columns) => {
              if (!_.isEqual(columns, activeColumns)) {
                setActiveColumns(columns);
              }
            }}
            disabled={isFormDisabled}
          />
        </Form.Item>
      </div>
      <Text style={{ margin: "8px 0px" }}>
        📚 Read our formulas{" "}
        <Link
          href="https://docs.whaly.io/data-management/workbench/formulas"
          target="whalydoc"
        >
          documention
        </Link>
      </Text>
    </Form>
  );
};

export default compose<Props, AddColumnFormProps>(WithOrg)(AddColumnForm);
