import chroma from "chroma-js";

const numberOfColors = 5;

const redPalette = chroma.scale(["#ff4d4f", "#f5a633"]).colors(numberOfColors);
const greenPalette = chroma
  .scale(["#7EA95C", "#3a5c83"])
  .colors(numberOfColors);
const yellowPalette = chroma
  .scale(["#eeb62f", "#73c6da"])
  .colors(numberOfColors);
const bluePalette = chroma.scale(["#6db6ca", "#446a8d"]).colors(numberOfColors);

const palette: string[] = [];

bluePalette.forEach((s, i) => {
  palette.push(s);
  palette.push(yellowPalette[i]);
  palette.push(greenPalette[i]);
  palette.push(redPalette[i]);
});

export const getColorFromPallette = (i: number): string => {
  return palette[i % palette.length];
};
