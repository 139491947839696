import * as React from "react";
import { compose } from "../../../components/compose/WlyCompose";
import Error from "../../../components/layout/feedback/error";
import Loading from "../../../components/layout/feedback/loading";
import type { IReport } from "../../../interfaces/reports";
import GraphQl from "../../graphql/graphql";
import type { InjectedOrgProps } from "../../orgs/WithOrg";
import WithOrg from "../../orgs/WithOrg";
import SettingsWrapper from "../SettingsWrapper";
import SharedReportsTable from "./SharedReportsTable";

interface ISharedReportsPageProps {}

const gql = `
query getSharedReports($orgId: ID!) {
  allReports(
    where: {
      AND: [
        { deleted_not: true, org: { id: $orgId } }
        { OR: [{ isPublicShared: true }, { sharingLinks_some: { deleted_not: true } }] }
      ]
    }
  ) {
    id
    name
    slug
    type
    folder {
      name
      image
      color
    }
    createdAt
    createdBy {
      id
      firstName
      lastName
      gravatar
      avatarColor
    }
    updatedAt
    updatedBy {
      id
      firstName
      lastName
      gravatar
      avatarColor
    }
  }
}
`;

interface IReturnValue {
  allReports: IReport[];
}

type Props = ISharedReportsPageProps & InjectedOrgProps;

class SharedReports extends React.Component<Props> {
  public render() {
    const { org } = this.props;
    return (
      <SettingsWrapper>
        <GraphQl<IReturnValue> query={gql} variables={{ orgId: org.id }}>
          {(gql) => {
            if (gql.status === "loading" || gql.status === "initial") {
              return <Loading />;
            }
            if (gql.status === "error") {
              return <Error />;
            }
            return <SharedReportsTable reports={gql.data.allReports} />;
          }}
        </GraphQl>
      </SettingsWrapper>
    );
  }
}

export default compose<Props, ISharedReportsPageProps>(WithOrg)(SharedReports);
