import { Button, Card, Col, Row, Steps } from "antd";
import { inject, observer } from "mobx-react";
import * as React from "react";
import type { RouteComponentProps } from "react-router";
import { withRouter } from "react-router";
import waving from "../../../assets/emoji-hand-waving.png";
import type { InjectedAntUtilsProps } from "../../../components/ant-utils/withAntUtils";
import { withAntUtils } from "../../../components/ant-utils/withAntUtils";
import { compose } from "../../../components/compose/WlyCompose";

import OnboardingCatalog from "../../../components/catalog/onboarding/OnboardingCatalog";
import Feednack from "../../../components/layout/feedback/feedback";
import Loading from "../../../components/layout/feedback/loading";
import type { AsyncData } from "../../../helpers/typescriptHelpers";
import type { IDestinationMeta } from "../../../interfaces/destinations";
import { routeDescriptor } from "../../../routes/routes";
import { track } from "../../../services/AnalyticsService";
import GraphQLService from "../../../services/graphql/GraphQLService";
import onboardingStore from "../../../store/onboardingStore";
import type { UserStoreProps } from "../../../store/userStore";
import {
  createDestinationAndRedirect,
  generateItemValueFromInput,
} from "../../catalog/domain";
import type { InjectedOrgProps } from "../../orgs/WithOrg";
import WithOrg from "../../orgs/WithOrg";
import InviteUser from "./InviteUser";
import "./WarehouseConnection.scss";

interface IWarehouseConnectionProps {}

interface IState {
  step: IStep;
  userFormLoading: boolean;
  destinationMetas: AsyncData<IDestinationMeta[]>;
}

type IStep = "STEP_1" | "STEP_2";

type Props = IWarehouseConnectionProps &
  InjectedOrgProps &
  UserStoreProps &
  InjectedAntUtilsProps &
  RouteComponentProps<{ organizationSlug: string }>;

class WarehouseConnection extends React.Component<Props, IState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      step: "STEP_1",
      userFormLoading: false,
      destinationMetas: { status: "initial" },
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData = () => {
    this.setState({ destinationMetas: { status: "loading" } });
    return GraphQLService(
      `
    query getDestinationMeta {
      allItemMetas: allDestinationMetas(where: {
          deleted: false, 
          ${!this.props.user.isAdmin ? `isWhalyManaged: false,` : ``}
          publicInfo: { label_in: [beta, live] }
      }) {
        id
        slug
        name
        auth
        isWhalyManaged
        defaultTargetDatabase
        publicInfo {
          id
          name
          slug
          label
          tagline
          website
          logo
          description
        }
        options {
          id
          slug
          key
          type
          label
          editable
          defaultValue
          order
          encrypt
          hidden
        }
      }
    }`,
      {}
    )
      .then((r) => {
        const destinationMetas = r.allItemMetas as IDestinationMeta[];
        this.setState({
          destinationMetas: { status: "success", data: destinationMetas },
        });
      })
      .catch((err) => {
        this.setState({ destinationMetas: { status: "error", error: err } });
      });
  };

  public renderWarehouses = () => {
    const { antUtils, org } = this.props;
    const { destinationMetas } = this.state;

    if (
      destinationMetas.status === "initial" ||
      destinationMetas.status === "loading"
    ) {
      return <Loading />;
    } else if (destinationMetas.status === "error") {
      return <Feednack>{destinationMetas.error.message}</Feednack>;
    } else if (destinationMetas.status === "success") {
      const onDestinationSelect: (s: any) => Promise<any> = async (
        s: IDestinationMeta
      ) => {
        const input = generateItemValueFromInput(s as any, {}, org.id);
        await createDestinationAndRedirect(
          s.name,
          org.id,
          s as any,
          input,
          (d) => {
            return Promise.resolve().then(() => {
              const {
                history,
                match: { params },
                userStore,
              } = this.props;
              return userStore
                .getUser()
                .then(() => {
                  return onboardingStore
                    .getOnboardingStatus(params.organizationSlug, "freetrial")
                    .catch((err) => {
                      antUtils.message.error(err.error);
                    });
                })
                .then(() => {
                  return history.push(
                    routeDescriptor.warehouseConnectionStep2.renderRoute({
                      organizationSlug: params.organizationSlug,
                      itemSlug: s.slug,
                      itemId: d.id,
                    })
                  );
                });
            });
          }
        );
      };
      track("Onboarding Step 2 - Source Values Viewed");
      return (
        <OnboardingCatalog
          items={destinationMetas.data as any}
          onItemClick={onDestinationSelect}
        />
      );
    }
  };

  public renderStep = (step: IStep) => {
    const { org } = this.props;
    switch (step) {
      case "STEP_1":
        track("Onboarding Step 1 Viewed");
        let content = (
          <InviteUser onDone={() => this.setState({ step: "STEP_2" })} />
        );
        if (org.realm?.identityProviderType !== "whaly") {
          content = (
            <>
              {" "}
              <div className="warehouse-connection-content">
                Whaly helps you build and automate your reportings. Companies
                use Whaly to gain access to their data and create metrics
                combining various data sources. Our main concern is your
                experience with our product, if you have any remarks or
                suggestions, please email us at{" "}
                <a href="mailto:hello@whaly.io"></a> as we take them very
                seriously and want to provide the best experience for our
                clients. <br /> We are delighted to have you onboard. <br />
                <br /> Emilien - CEO & Co-Founder
              </div>
              <div className="warehouse-connection-content center">
                <Button
                  type={"primary"}
                  onClick={() => this.setState({ step: "STEP_2" })}
                >
                  Let's connect a data warehouse
                </Button>
              </div>
            </>
          );
        }
        return (
          <div className="warehouse-connection-inner">
            <Card>
              <div className="warehouse-connection-content">
                <div className="warehouse-connection-header">
                  <img
                    className="warehouse-connection-emoji"
                    src={waving}
                    alt="hand waving"
                  />{" "}
                  Welcome to Whaly
                </div>
              </div>
              {content}
            </Card>
          </div>
        );
      case "STEP_2":
        return (
          <div className="warehouse-connection-inner">
            <div className="warehouse-connection-header">
              Connect a warehouse
            </div>
            <div className="warehouse-connection-content">
              Before digging in any data analysis, we need to set up a data
              warehouse.
            </div>
            <div className="warehouse-connection-content">
              {this.renderWarehouses()}
            </div>
          </div>
        );
    }
  };

  public render() {
    const { step } = this.state;

    return (
      <Row className="warehouse-connection">
        <Col offset={6} span={12}>
          <Steps size="small" current={step === "STEP_1" ? 0 : 1}>
            <Steps.Step title="Welcome to Whaly" />
            <Steps.Step title="Warehouse connection" />
          </Steps>
          {this.renderStep(step)}
        </Col>
      </Row>
    );
  }
}

export default compose<Props, IWarehouseConnectionProps>(
  WithOrg,
  withRouter,
  withAntUtils,
  inject("userStore"),
  observer
)(WarehouseConnection);
