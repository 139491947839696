import { CaretRightOutlined } from "@ant-design/icons";
import type { PopoverProps } from "antd";
import { Button, Col, Popover, Row, Space, Tooltip, Typography } from "antd";
import React, { useState } from "react";

import "./EmbeddedChartSelector.scss";

export interface EmbeddedCatalogItem<T> {
  key: string;
  label: string;
  charts: Array<{
    key: T;
    label: string;
    icon: JSX.Element;
  }>;
}

interface IEmbeddedChartSelectorProps<T> {
  catalog: EmbeddedCatalogItem<T>[];
  value?: T;
  onChange?: (value: T) => void;
}

const renderIcon = (icon: JSX.Element, style: React.CSSProperties) => {
  return React.cloneElement(icon, { style: style });
};

export function EmbeddedChartSelector<T extends string>(
  props: IEmbeddedChartSelectorProps<T>
) {
  const { value, onChange, catalog } = props;
  const [open, setOpen] = useState<boolean>(false);

  const catalogReverseIndex = catalog.reduce<{
    [key: string]: {
      key: string;
      label: string;
      icon: JSX.Element;
      category: {
        key: string;
        order: number;
        label: string;
      };
    };
  }>((acc, cat, i) => {
    const { charts, ...rest } = cat;
    const values = cat.charts.reduce((bcc, chart, j) => {
      return {
        ...bcc,
        [chart.key]: {
          ...chart,
          category: rest,
        },
      };
    }, {});
    return {
      ...acc,
      ...values,
    };
  }, {});

  const selectedChart = value
    ? catalogReverseIndex[value]
    : catalogReverseIndex[Object.keys(catalogReverseIndex)[0]];

  const popover: PopoverProps = {
    title: null,
    open: open,
    onOpenChange: (v: boolean) => {
      setOpen(v);
    },
    trigger: ["click"],
    placement: "right",
    destroyTooltipOnHide: true,
    content: (
      <div className="popover-chart-selection">
        <Space direction="vertical" size={"large"} style={{ width: "100%" }}>
          {catalog.map((analysis, k) => {
            return (
              <Row gutter={[8, 8]} key={k}>
                <Col span={24}>
                  <Space>
                    <b>{analysis.label}</b>
                  </Space>
                </Col>
                {analysis.charts.map((chart, i) => {
                  const className = ["chart-selection-button"];
                  if (selectedChart.key === chart.key) {
                    className.push("chart-selection-button-selected");
                  }
                  return (
                    <Col span={6} key={i} className="chart-selection-box">
                      <Tooltip title={chart.label} mouseLeaveDelay={0}>
                        <Button
                          className={className.join(" ")}
                          onClick={() => {
                            if (onChange) {
                              onChange(chart.key);
                            }
                            setOpen(false);
                          }}
                        >
                          {renderIcon(chart.icon, {
                            fontSize: 36,
                            marginLeft: -10,
                            marginTop: 1,
                          })}
                        </Button>
                      </Tooltip>
                    </Col>
                  );
                })}
              </Row>
            );
          })}
        </Space>
      </div>
    ),
  };
  return (
    <Popover {...popover}>
      <div className="chart-selection">
        <div className="chart-selection-icon">
          <Button
            icon={renderIcon(selectedChart.icon, {
              fontSize: 26,
              marginTop: 2,
            })}
            style={{
              width: 42,
              height: 42,
            }}
            ghost
          />
        </div>
        <div className="chart-selection-text">
          <div className="chart-selection-text-title">
            <Typography.Text>{selectedChart.label}</Typography.Text>
          </div>
          <div className="chart-selection-text-subtitle">
            <Typography.Text
              type="secondary"
              style={{
                fontSize: 12,
              }}
            >
              {selectedChart.category.label}
            </Typography.Text>
          </div>
        </div>
        <div className="chart-selection-right">
          <CaretRightOutlined size={12} />
        </div>
      </div>
    </Popover>
  );
}
