import { EllipsisOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { Badge, Button, Dropdown, Tree, Typography } from "antd";
import type { DataNode } from "antd/es/tree";
import type { TreeProps } from "antd/lib/tree";
import _ from "lodash";
import { inject, observer } from "mobx-react";
import * as React from "react";
import type { InjectedAntUtilsProps } from "../../../../../../components/ant-utils/withAntUtils";
import { withAntUtils } from "../../../../../../components/ant-utils/withAntUtils";
import { compose } from "../../../../../../components/compose/WlyCompose";
import { SourceImageRenderer } from "../../../../../../components/sources/SourceImageRenderer";
import type { IDestination } from "../../../../../../interfaces/destinations";
import type { IModelFolder } from "../../../../../../interfaces/modelFolder";
import GraphQLService from "../../../../../../services/graphql/GraphQLService";
import type { WorkbenchUIStoreProps } from "../../../../../../store/workbenchUIStore";
import workbenchUIStore from "../../../../../../store/workbenchUIStore";
import { arrayToTree } from "../../../../../../utils/arrayToTree";
import type { TableTabItem } from "../../../../../spreadsheet/domain";
import { toDisplayErrors } from "../../../dataset/DatasetPopover";
import "./ModelTable.scss";

interface ModelTreeDataNode extends DataNode {
  id: string;
  label: string;
  parentKey: string;
  type: "folder" | "model";
}

interface IModelTableProps {
  tables: Array<TableTabItem>;
  modelFolders: IModelFolder[];
  activeKey?: string;
  onActiveModelChange: (prevKey: string, nextKey: string) => void;
  renderLine: (
    id: string,
    nameComponent: React.ReactNode,
    menuComponent?: React.ReactNode,
    rawId?: string,
    draggable?: boolean,
    dataset?: TableTabItem
  ) => React.ReactNode;
  onDeleteModelFolder: (id: string) => Promise<any>;
  onDeleteModel?: (datasetId: string) => Promise<any>;
  renderOrigin: (managedBy: "WHALY" | "DBT_CLOUD") => React.ReactNode | null;
  onRefreshModelFolderTree: () => Promise<any>;
  currentWarehouse: IDestination;
}

type Props = IModelTableProps & WorkbenchUIStoreProps & InjectedAntUtilsProps;

function ModelTable(props: Props) {
  const {
    tables,
    modelFolders,
    activeKey,
    antUtils,
    onActiveModelChange,
    onDeleteModel,
    onDeleteModelFolder,
    renderLine,
    onRefreshModelFolderTree,
  } = props;

  const folderUpdatesRef = React.useRef<{ id: string; data: any }[]>([]);
  const modelUpdatesRef = React.useRef<{ id: string; data: any }[]>([]);
  const [hoveredKey, setHoveredKey] = React.useState<string>(null);
  const [models, setModels] = React.useState<TableTabItem[]>(tables);
  const [folders, setFolders] = React.useState<IModelFolder[]>(modelFolders);
  const [selectedKeys, setSelectedKeys] = React.useState<string[]>(
    props.activeKey ? [props.activeKey] : []
  );
  const [expandedKeys, setExandedKeys] = React.useReducer<
    (
      state: string[],
      action:
        | { type: "remove" | "add"; key: string }
        | { type: "set" | "addMany"; keys: string[] }
    ) => string[]
  >((state, action) => {
    switch (action.type) {
      case "add":
        return _.uniq([...state, action.key]);
      case "addMany":
        return _.uniq([...state, ...action.keys]);
      case "remove":
        return _.uniq([...state].filter((key) => key !== action.key));
      case "set":
        return action.keys;
      default:
        return state;
    }
  }, []);

  // Update tree data when component gets monted
  React.useEffect(() => {
    onRefreshModelFolderTree();
  }, []);

  React.useEffect(() => {
    setModels(tables);
  }, [tables]);

  React.useEffect(() => {
    setFolders(modelFolders);
  }, [modelFolders]);

  React.useEffect(() => {
    const key = `model-${activeKey}`;
    setSelectedKeys([key]);
    const parentKeys = getAncestorsKeys(
      key,
      buildFolderTree(getModels(), getFolders())
    );
    setExandedKeys({
      type: "addMany",
      keys: parentKeys,
    });
    document.getElementById(key)?.scrollIntoView({ block: "center" });
  }, [activeKey]);

  const getFolders = () => {
    const remoteFolders = modelFolders;
    const localFolders = [...folders];
    return remoteFolders.map((remoteFolder) => {
      const matchingLocalFolder = localFolders.find(
        (lf) => remoteFolder.id === lf.id
      );
      if (
        matchingLocalFolder &&
        matchingLocalFolder.updatedAt > remoteFolder.updatedAt
      ) {
        return matchingLocalFolder;
      } else {
        return remoteFolder;
      }
    });
  };

  const getModels = () => {
    const remoteModels = tables;
    const localModels = [...models];
    return remoteModels.map((remoteModel) => {
      const matchingLocalModel = localModels.find(
        (lm) => remoteModel.dataset.id === lm.dataset.id
      );
      if (
        matchingLocalModel &&
        matchingLocalModel.dataset.updatedAt > remoteModel.dataset.updatedAt
      ) {
        return matchingLocalModel;
      } else {
        return remoteModel;
      }
    });
  };

  const debouncedModelFolderUpdate = React.useMemo(
    () =>
      _.debounce(async () => {
        const modelUpdates = [...modelUpdatesRef.current];
        const folderUpdates = [...folderUpdatesRef.current];
        modelUpdatesRef.current = [];
        folderUpdatesRef.current = [];
        try {
          await GraphQLService(
            `
              mutation updateModelFolderTree(
                $folderUpdates: [ModelFoldersUpdateInput]!
                $modelUpdates: [DatasetsUpdateInput]!
              ) {
                updateModelFolders(data: $folderUpdates) {
                  id
                }
                updateDatasets(data: $modelUpdates) {
                  id
                }
              }
            `,
            {
              folderUpdates,
              modelUpdates,
            }
          );
          await onRefreshModelFolderTree();
        } catch (error) {
          console.error(error);
        }
      }, 200),
    []
  );

  const flattenTree = (
    tree: any[],
    elements: any[],
    parentId: string = null
  ) => {
    const els = elements;
    tree.forEach((element) => {
      const { children, ...rest } = element;
      if (parentId) {
        rest.parentKey = `folder-${parentId}`;
      } else {
        rest.parentKey = null;
      }
      elements.push(rest);
      if (typeof children === "object") {
        flattenTree(children, els, rest.id);
      }
    });
    return els;
  };

  const getAncestorsKeys = (
    key: string,
    tree: any[],
    parentKeys?: string[]
  ): string[] => {
    const flattened = flattenTree(tree, []);
    const parents = parentKeys ?? [];
    const element = flattened.find((i) => i.key === key);
    if (element && element.parentKey) {
      parents.push(element.parentKey);
      return getAncestorsKeys(element.parentKey, tree, parents);
    } else {
      return parents;
    }
  };

  const buildFolderTree = (
    models: TableTabItem[],
    folders: IModelFolder[]
  ): ModelTreeDataNode[] => {
    const treeData = [
      ...folders.map((m) => ({
        id: m.id,
        key: `folder-${m.id}`,
        label: m.name,
        title: (
          <div
            style={{ opacity: 1 }}
            className="workbench-item"
            id={`folder-${m.id}`}
            onMouseEnter={() => setHoveredKey(`folder-${m.id}`)}
            onMouseLeave={() => setHoveredKey(null)}
          >
            <div className="workbench-item-name">
              <span
                onClick={() => toggleExpandedKey(`folder-${m.id}`)}
                className={"workbench-item-name-inner hoverable"}
              >
                <span
                  className="workbench-item-name-inner-image-wrapper"
                  style={{ display: "flex" }}
                >
                  <SourceImageRenderer
                    size={20}
                    className={"workbench-item-name-inner-image"}
                    alt={m.name}
                    img={m.image}
                  />
                </span>
                <span className="workbench-item-name-inner-text">
                  <b>{m.name}</b>
                </span>
                <span
                  className="workbench-item-name-inner-actions"
                  hidden={hoveredKey !== `folder-${m.id}`}
                >
                  <Dropdown
                    menu={{
                      items: [
                        {
                          key: "edit",
                          label: "Edit",
                          onClick: (e) => {
                            e.domEvent.stopPropagation();
                            workbenchUIStore.setModelFolderEditionOpen({
                              id: m.id,
                              name: m.name,
                              image: m.image,
                            });
                          },
                        },
                        {
                          key: "delete",
                          label: "Delete",
                          danger: true,
                          onClick: (e) => {
                            e.domEvent.stopPropagation();
                            const childrenFolders = getFolders().filter(
                              (mf) => mf.parent?.id === m.id
                            );
                            const childrenModel = getModels().filter(
                              (tb) => tb.folder?.id === m.id
                            );
                            if (
                              childrenFolders.length ||
                              childrenModel.length
                            ) {
                              antUtils.modal.confirm({
                                title: "Can't delete this folder",
                                content: `It is not possible to delete a folder with folders or models inside`,
                                icon: null,
                                okText: "Ok",
                                onOk: () => {},
                                onCancel: () => {},
                              });
                            } else {
                              antUtils.modal.confirm({
                                title: "Are you sure?",
                                content: `This operation can not be undone, do you want to proceed?`,
                                okText: "Delete",
                                okButtonProps: {
                                  danger: true,
                                },
                                onOk: () => {
                                  onDeleteModelFolder(m.id);
                                },
                                onCancel: () => {},
                              });
                            }
                          },
                        },
                      ],
                    }}
                    trigger={["click"]}
                    placement={"bottomRight"}
                    arrow={true}
                  >
                    <Button
                      type="text"
                      size="small"
                      shape="circle"
                      onClick={(e) => e.stopPropagation()}
                      icon={<EllipsisOutlined rotate={90} />}
                    />
                  </Dropdown>
                  <Dropdown
                    menu={{
                      items: [
                        {
                          key: "subfolder",
                          label: "Add subfolder",
                          onClick: (e) => {
                            e.domEvent.stopPropagation();
                            workbenchUIStore.setModelFolderEditionOpen({
                              parentId: m.id,
                            });
                          },
                        },
                        {
                          key: "model",
                          label: "Add model",
                          onClick: (e) => {
                            e.domEvent.stopPropagation();
                            workbenchUIStore.setDatasetEditionOpen({
                              folderId: m.id,
                            });
                          },
                        },
                      ],
                    }}
                    trigger={["click"]}
                    placement={"bottomRight"}
                    arrow={true}
                  >
                    <Button
                      shape="circle"
                      type="text"
                      size="small"
                      onClick={(e) => e.stopPropagation()}
                      icon={<PlusCircleOutlined />}
                    />
                  </Dropdown>
                </span>
              </span>
            </div>
          </div>
        ),
        isLeaf: false,
        selectable: false,
        type: "folder",
        parentKey: m.parent?.id ? `folder-${m.parent.id}` : null,
      })),
      ...models
        .sort((a, b) => a.label.localeCompare(b.label))
        .map((t) => {
          const hasError = () => {
            return toDisplayErrors(t).length > 0;
          };
          return {
            id: t.dataset.id,
            key: `model-${t.key}`,
            label: t.label,
            type: "model",
            title: renderLine(
              `model-${t.key}`,
              <span
                id={`model-${t.key}`}
                onMouseEnter={() => setHoveredKey(`model-${t.dataset.id}`)}
                onMouseLeave={() => setHoveredKey(null)}
                onClick={() => onActiveModelChange(activeKey!, t.key)}
                className={
                  activeKey === t.key
                    ? "workbench-item-name-inner hoverable selected"
                    : "workbench-item-name-inner hoverable"
                }
              >
                <Badge
                  className="workbench-item-name-inner-text"
                  dot={hasError()}
                  status="warning"
                  offset={[4, 6]}
                  size={"small"}
                >
                  <Typography.Text ellipsis>{t.label}</Typography.Text>
                </Badge>
                {t.managedBy !== "DBT_CLOUD" ? (
                  <span
                    className="workbench-item-name-inner-actions"
                    hidden={hoveredKey !== `model-${t.dataset.id}`}
                  >
                    <Dropdown
                      menu={{
                        items: [
                          {
                            key: "edit",
                            label: "Rename",
                            onClick: (e) => {
                              e.domEvent.stopPropagation();
                              workbenchUIStore.setDatasetEditionOpen({
                                id: t.baseDatasetId,
                                name: t.label,
                              });
                            },
                          },
                          onDeleteModel && {
                            key: "remove",
                            danger: true,
                            label: "Delete",
                            onClick: (e) => {
                              e.domEvent.stopPropagation();
                              onDeleteModel(t.baseDatasetId);
                            },
                          },
                        ],
                      }}
                      trigger={["click"]}
                      placement={"bottomRight"}
                      arrow={true}
                      getPopupContainer={(d) => {
                        const el = document.getElementById(`model-${t.key}`);
                        if (el) {
                          return el;
                        }
                        return document.body;
                      }}
                    >
                      <Button
                        type="text"
                        size="small"
                        shape="circle"
                        onClick={(e) => e.stopPropagation()}
                        icon={<EllipsisOutlined rotate={90} />}
                      />
                    </Dropdown>
                  </span>
                ) : null}
              </span>,
              undefined,
              t.key,
              true,
              t
            ),
            isLeaf: true,
            selectable: true,
            parentKey: t.folder?.id ? `folder-${t.folder.id}` : null,
          };
        }),
    ];
    return arrayToTree(treeData, {
      dataField: null,
      id: "key",
      parentId: "parentKey",
    }) as ModelTreeDataNode[];
  };

  const toggleExpandedKey = (expandedKey: string) => {
    if (expandedKeys.includes(expandedKey)) {
      setExandedKeys({
        key: expandedKey,
        type: "remove",
      });
    } else {
      setExandedKeys({
        key: expandedKey,
        type: "add",
      });
    }
  };

  const onExpand: TreeProps<ModelTreeDataNode>["onExpand"] = (keys) => {
    setExandedKeys({
      type: "set",
      keys: keys.map((k) => k.toString()),
    });
  };

  const onDragStart: TreeProps<ModelTreeDataNode>["onDragStart"] = (info) => {
    // we modify the onDrop event to allow dropping to CanvasWrapper.tsx
    if (info.node.type === "model" && info.node.id !== activeKey) {
      info.event.dataTransfer.setData("application/whaly.model", info.node.id);
    }
  };

  const allowDrop: TreeProps<ModelTreeDataNode>["allowDrop"] = ({
    dragNode,
    dropNode,
    dropPosition,
  }) => {
    // we prevent dropping on models
    if (dropNode.type === "model") {
      return false;
    }

    // we prevent in the same parent unless we drop at the top of the tree
    if (dropNode.key === dragNode.parentKey && dropPosition !== -1) {
      return false;
    }

    // we can drop in all folders at position 0
    if (dropPosition === 0) {
      return true;
    }

    // or on the topmost folder of the root if we have a parent
    const rootFolderKey = buildFolderTree(getModels(), getFolders()).map(
      (td) => td.key
    )?.[0];
    if (
      dropPosition === -1 &&
      dropNode.key === rootFolderKey &&
      dragNode.parentKey !== null
    ) {
      return true;
    }

    // otherwise, we prevent the drop
    return false;
  };

  const onDrop: TreeProps<ModelTreeDataNode>["onDrop"] = (info) => {
    // taken from antd example
    // https://ant.design/components/tree#components-tree-demo-draggable

    // we only drop one item at a time
    // so we should update this item
    // then connect it to the new parent or disconnect it

    const dropKey = info.node.key;
    const dragKey = info.dragNode.key;
    const dropPos = info.node.pos.split("-");
    const dropPosition =
      info.dropPosition - Number(dropPos[dropPos.length - 1]);

    const loop = (
      data: DataNode[],
      key: React.Key,
      callback: (node: DataNode, i: number, data: DataNode[]) => void
    ) => {
      for (let i = 0; i < data.length; i++) {
        if (data[i].key === key) {
          return callback(data[i], i, data);
        }
        if (data[i].children) {
          loop(data[i].children!, key, callback);
        }
      }
    };

    const newTreeData = buildFolderTree(getModels(), getFolders());

    // Find dragObject
    let dragObj: DataNode;
    loop(newTreeData, dragKey, (item, index, arr) => {
      arr.splice(index, 1);
      dragObj = item;
    });

    if (!info.dropToGap) {
      // Drop on the content
      loop(newTreeData, dropKey, (item) => {
        item.children = item.children || [];
        // where to insert 示例添加到头部，可以是随意位置
        item.children.unshift(dragObj);
      });
    } else if (
      ((info.node as any).children || []).length > 0 && // Has children
      (info.node as any).expanded && // Is expanded
      dropPosition === 1 // On the bottom gap
    ) {
      loop(newTreeData, dropKey, (item) => {
        item.children = item.children || [];
        // where to insert 示例添加到头部，可以是随意位置
        item.children.unshift(dragObj);
        // in previous version, we use item.children.push(dragObj) to insert the
        // item to the tail of the children
      });
    } else {
      let ar: DataNode[] = [];
      let i: number;
      loop(newTreeData, dropKey, (_item, index, arr) => {
        ar = arr;
        i = index;
      });
      if (dropPosition === -1) {
        ar.splice(i!, 0, dragObj!);
      } else {
        ar.splice(i! + 1, 0, dragObj!);
      }
    }

    const flattenedNewTree = flattenTree(newTreeData, []);
    const flattenedOldTree = flattenTree(
      buildFolderTree(getModels(), getFolders()),
      []
    );

    flattenedOldTree.forEach(async (oldItem) => {
      const newItem = flattenedNewTree.find((i) => i.key === oldItem.key);
      if (newItem) {
        const parentFolderId = newItem.parentKey?.split("folder-")[1];
        if (newItem.parentKey !== oldItem.parentKey) {
          if (newItem.type === "folder") {
            const id = newItem.id;
            const folderData = parentFolderId ? { id: parentFolderId } : null;
            setFolders((lm) =>
              [...lm].map((l) => {
                if (l.id === id) {
                  return {
                    ...l,
                    parent: folderData as IModelFolder,
                    updatedAt: new Date().toISOString(),
                  };
                } else {
                  return l;
                }
              })
            );
            const remoteData = parentFolderId
              ? {
                  id: id,
                  data: {
                    parent: {
                      connect: {
                        id: parentFolderId,
                      },
                    },
                  },
                }
              : {
                  id: id,
                  data: {
                    parent: {
                      disconnectAll: true,
                    },
                  },
                };
            folderUpdatesRef.current = [...folderUpdatesRef.current].filter(
              (u) => u.id !== id
            );
            folderUpdatesRef.current.push(remoteData);
            debouncedModelFolderUpdate();
          } else if (newItem.type === "model") {
            const id = newItem.id;
            const folderData = parentFolderId ? { id: parentFolderId } : null;
            setModels((lm) =>
              [...lm].map((l) => {
                if (l.dataset.id === id) {
                  return {
                    ...l,
                    dataset: {
                      ...l.dataset,
                      updatedAt: new Date().toISOString(),
                    },
                    folder: folderData as IModelFolder,
                  };
                } else {
                  return l;
                }
              })
            );
            const remoteData = parentFolderId
              ? {
                  id: id,
                  data: {
                    folder: {
                      connect: {
                        id: parentFolderId,
                      },
                    },
                  },
                }
              : {
                  id: id,
                  data: {
                    folder: {
                      disconnectAll: true,
                    },
                  },
                };

            modelUpdatesRef.current = [...modelUpdatesRef.current].filter(
              (u) => u.id !== id
            );
            modelUpdatesRef.current.push(remoteData);
            debouncedModelFolderUpdate();
          }
        }
      }
    });
  };

  return (
    <Tree<ModelTreeDataNode>
      activeKey={activeKey}
      onExpand={onExpand}
      expandedKeys={expandedKeys}
      blockNode={true}
      selectable={true}
      draggable={true}
      allowDrop={allowDrop}
      onDragStart={onDragStart}
      onDrop={onDrop}
      className="model-tree"
      selectedKeys={selectedKeys}
      defaultSelectedKeys={selectedKeys}
      treeData={buildFolderTree(getModels(), getFolders())}
    />
  );
}

export default compose<Props, IModelTableProps>(withAntUtils)(
  inject("workbenchUIStore")(observer(ModelTable))
);
