import chroma from "chroma-js";
import type { IBasePalette } from "./paletteData";

interface PaletteGeneratorProps extends IBasePalette {
  numberOfColors: number;
}

export default class PaletteGenerator {
  private type: "discrete" | "continue";
  private initialColors: string[];
  private numberOfColors: number;
  public colors: string[];

  constructor(props: PaletteGeneratorProps) {
    this.type = props.type;
    this.initialColors = props.colors;
    this.numberOfColors = props.numberOfColors;
    this.colors = this.generateFinalScale();
  }

  private generateFinalScale = (): string[] => {
    if (this.type === "continue") {
      const scale = chroma
        .scale(this.initialColors)
        .colors(this.numberOfColors);
      return scale;
    } else {
      const colors: string[] = [];
      const numberOfRound =
        Math.round(this.numberOfColors / this.initialColors.length) + 1;
      for (let i = 0; i < (numberOfRound || 1); i++) {
        this.initialColors.forEach((ic) => {
          colors.push(
            chroma(ic)
              .desaturate(i / 2)
              .hex()
          );
        });
      }
      return colors;
    }
  };

  public getColorAtIndex = (i: number) => {
    if (0 <= i && i <= this.colors.length - 1) {
      return this.colors[i];
    }
    throw new Error("Index out of bound");
  };
}
