import _ from "lodash";
import type {
  IObject,
  IObjectQueryBuilderSectionItemConfig_Parsed,
  IObjectQueryBuilderSectionType,
} from "../../../../../../../../interfaces/object";

export interface CreateEditQueryBuilderSectionFormData {
  id?: string;
  name: string;
  type: IObjectQueryBuilderSectionType;
  order: number;
}

export const findRelatedObjects = (
  currentObject: IObject,
  allObjects: IObject[],
  linkedObjectIds: string[]
) => {
  if (!linkedObjectIds.includes(currentObject.id)) {
    linkedObjectIds.push(currentObject.id);
  }
  const relatedIds = currentObject.foreignKeys.map((fk) => fk.object.id);
  const toAdd = _.difference(relatedIds, linkedObjectIds);
  if (toAdd.length === 0) {
    return;
  }
  toAdd.forEach((ta) => {
    linkedObjectIds.push(ta);
  });
  toAdd.forEach((ta) => {
    const o = allObjects.find((ao) => ta === ao.id);
    if (o) {
      findRelatedObjects(o, allObjects, linkedObjectIds);
    }
  });
};
