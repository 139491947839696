import type { ComponentClass, ComponentType as Component } from "react";

interface ComponentEnhancer<TInner, TOutter> {
  (component: Component<TInner>): ComponentClass<TOutter>;
}

export const compose = <TInner, TOutter>(
  ...funcs
): ComponentEnhancer<TInner, TOutter> =>
  funcs.reduce(
    (a, b) =>
      (...args) =>
        a(b(...args)),
    (arg) => arg
  );
