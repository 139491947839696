import { CheckCircleTwoTone } from "@ant-design/icons";
import type { Query } from "@cubejs-client/core";
import { Col, Progress, Row } from "antd";
import cuid from "cuid";
import _ from "lodash";
import * as React from "react";
import Confetti from "react-confetti";
import type { ChartOption } from "../../../containers/chart-options/ChartOptions";
import type { UserLocale } from "../../../interfaces/user";
import type { Formatter } from "../domain";
import { customFormatter } from "../utils/optionsHelper";
import "./GaugeChart.scss";

type CanDrill = (key: "current" | "previous") => Query | null;

interface IGaugeChartProps {
  config: Array<{
    name: string;
    color: string;
    data: {
      current: string;
      previous?: string;
    };
    canDrill: CanDrill;
    formatter: Formatter;
    invertedComparison?: boolean;
  }>;
  width: number | undefined;
  height: number | undefined;
  chartOptions?: ChartOption;
  onDrill?: (query: Query) => void;
  locale: UserLocale;
}

export default class GaugeChart extends React.Component<IGaugeChartProps> {
  id: string = cuid();

  shouldComponentUpdate(nextProps: Readonly<IGaugeChartProps>): boolean {
    if (!_.isEqual(this.props.width, nextProps.width)) return true;
    if (!_.isEqual(this.props.height, nextProps.height)) return true;
    if (!_.isEqual(this.props.chartOptions, nextProps.chartOptions))
      return true;
    return false;
  }

  public render() {
    const { config, width, height, chartOptions, onDrill, locale } = this.props;
    const currentProgress = config[0].data.current;
    const currentProgressFormatter = config[0].formatter;
    const objective = config[1].data.current;
    const objectiveFormatter = config[1].formatter;

    const progress = Math.round(
      (parseFloat(currentProgress) / parseFloat(objective)) * 100
    );
    const hideConfettis =
      chartOptions && chartOptions["hide-confettis"]
        ? chartOptions["hide-confettis"]
        : false;

    const fontSize =
      chartOptions && chartOptions["font-size"]
        ? +chartOptions["font-size"]
        : 40;

    const showInPercent =
      chartOptions && chartOptions["show-percent"]
        ? chartOptions["show-percent"]
        : false;

    const type =
      chartOptions && chartOptions["gauge-type"]
        ? chartOptions["gauge-type"]
        : "line";

    const showTotal =
      chartOptions && chartOptions["show-total"]
        ? chartOptions["show-total"]
        : false;

    const currentDrill = config[0].canDrill("current");

    const renderMeasure = () => {
      if (showInPercent) {
        if (isNaN(progress)) {
          return "0%";
        } else if (progress === Infinity) {
          return "∞";
        } else {
          return progress + "%";
        }
      }
      return (
        <>
          {customFormatter(currentProgress, locale, currentProgressFormatter)}
        </>
      );
    };

    const renderObjective = () => {
      if (!showTotal) return <></>;
      return (
        <>
          {showInPercent
            ? customFormatter(
                currentProgress,
                locale,
                currentProgressFormatter
              ) + " "
            : null}{" "}
          of {customFormatter(objective, locale, objectiveFormatter)}
        </>
      );
    };

    return (
      <div
        className="gauge-chart"
        style={{ width: width ? width : "100%", height: height }}
      >
        {progress >= 100 && !hideConfettis && (
          <Confetti
            width={width}
            height={height}
            opacity={0.3}
            numberOfPieces={40}
            gravity={0.03}
          />
        )}
        <Row className="align" id={this.id} style={{ textAlign: "center" }}>
          <Col span={24}>
            {type === "dashboard" ? (
              <>
                <Progress
                  type="dashboard"
                  percent={progress}
                  size={Math.min(width, height)}
                  strokeWidth={5}
                  format={(p) => {
                    return (
                      <div
                        className={currentDrill && onDrill ? "clickable" : null}
                        onClick={
                          currentDrill && onDrill
                            ? () => onDrill(currentDrill)
                            : undefined
                        }
                      >
                        <div
                          className="measure-value"
                          style={{ fontSize: fontSize }}
                          onClick={
                            currentDrill && onDrill
                              ? () => onDrill(currentDrill)
                              : undefined
                          }
                        >
                          {renderMeasure()}
                        </div>
                        <div className="measure-objective-dashboard">
                          {renderObjective()}
                        </div>
                      </div>
                    );
                  }}
                  strokeColor={{
                    "0%": config[0].color,
                    "100%": config[1].color,
                  }}
                />
              </>
            ) : (
              <>
                <div
                  style={{
                    display: "flex",
                    justifyItems: "baseline",
                    gap: 8,
                    paddingBottom: 4,
                  }}
                  className={currentDrill && onDrill ? "clickable" : null}
                  onClick={
                    currentDrill && onDrill
                      ? () => onDrill(currentDrill)
                      : undefined
                  }
                >
                  <div className="measure-value" style={{ fontSize: fontSize }}>
                    {renderMeasure()}
                  </div>
                  <div className="measure-objective">{renderObjective()}</div>
                  <div className="measure-icon">
                    {progress >= 100 ? (
                      <CheckCircleTwoTone
                        twoToneColor="#52c41a"
                        style={{ fontSize: 20 }}
                      />
                    ) : (
                      <CheckCircleTwoTone
                        twoToneColor="#DEE5ED"
                        style={{ fontSize: 20 }}
                      />
                    )}
                  </div>
                </div>
                <Progress
                  percent={progress}
                  size={[width, 12]}
                  showInfo={false}
                  strokeColor={{
                    "0%": config[0].color,
                    "100%": config[1].color,
                  }}
                />
              </>
            )}
          </Col>
        </Row>
      </div>
    );
  }
}
