import * as React from "react";
import { compose } from "../../../components/compose/WlyCompose";
import Error from "../../../components/error/Error";
import Feednack from "../../../components/layout/feedback/feedback";
import Loading from "../../../components/layout/feedback/loading";
import GraphQl from "../../graphql/graphql";
import type { InjectedOrgProps } from "../../orgs/WithOrg";
import WithOrg from "../../orgs/WithOrg";
import SettingsWrapper from "../SettingsWrapper";

interface SettingsSourceUsageProps {}

const gql = `
query getJwt($orgId: ID!) {
  createEmbedConfig(orgId: $orgId)
}
`;

type Props = InjectedOrgProps & SettingsSourceUsageProps;

class SettingsSourceUsage extends React.Component<Props> {
  render() {
    const { org } = this.props;

    const orgSlug = "whaly-internal";
    const embedToken = "2684f6de-9a82-4c4b-aaf2-4c1534bf315b";

    return (
      <SettingsWrapper>
        <GraphQl<{ createEmbedConfig: string }>
          query={gql}
          variables={{ orgId: org.id }}
        >
          {(gql) => {
            if (gql.status === "loading" || gql.status === "initial") {
              return (
                <Feednack>
                  <Loading />
                </Feednack>
              );
            }
            if (gql.status === "error") {
              return (
                <Feednack>
                  <Error />
                </Feednack>
              );
            }
            return (
              <iframe
                title="embedUsage"
                width={"100%"}
                height={"100%"}
                frameBorder={0}
                allowFullScreen
                src={`https://app.whaly.io/${orgSlug}/embed/report/${embedToken}?token=${gql.data.createEmbedConfig}`}
              />
            );
          }}
        </GraphQl>
      </SettingsWrapper>
    );
  }
}

export default compose<Props, SettingsSourceUsageProps>(WithOrg)(
  SettingsSourceUsage
);
