import Editor from "@monaco-editor/react";
import { Empty } from "antd";
import { useCallback, useEffect, useState } from "react";
import { compose } from "../../../../components/compose/WlyCompose";
import Loading from "../../../../components/layout/feedback/loading";
import type { InjectedOrgProps } from "../../../../containers/orgs/WithOrg";
import WithOrg from "../../../../containers/orgs/WithOrg";
import type { ISource } from "../../../../interfaces/sources";
import { getJSONFile } from "../../../../services/FileService";
import "./SourceSchema.scss";

type ISourceStateProps = {
  source: ISource;
};

type Props = ISourceStateProps & InjectedOrgProps;

function SourceState(props: Props) {
  const { source } = props;

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string>(null);
  const [sourceState, setsourceState] = useState<string>(null);

  const fetchSourceState = useCallback(async () => {
    try {
      setLoading(true);
      const resp = await getJSONFile<string>(source.stateFileURI);
      setsourceState(JSON.stringify(resp));
    } catch (error) {
      console.warn(error);
      setError("Error while loading the file, please try again");
    } finally {
      setLoading(false);
    }
  }, [source]);

  useEffect(() => {
    fetchSourceState();
  }, [fetchSourceState]);

  if (loading) {
    return <Loading />;
  }
  if (error || !sourceState) {
    return <Empty description={error ? error : "No data"} />;
  }

  return (
    <Editor
      defaultLanguage="json"
      theme="vs-dark"
      defaultValue={JSON.stringify(JSON.parse(sourceState), null, 2)}
      options={{
        readOnly: true,
        minimap: {
          enabled: false,
        },
        lineNumbers: "on",
      }}
    />
  );
}

export default compose<Props, ISourceStateProps>(WithOrg)(SourceState);
