import type {
  DefaultLinkModel,
  PortModelAlignment,
} from "@projectstorm/react-diagrams";
import { PortModel } from "@projectstorm/react-diagrams";
import { EditableLabelModel } from "../label/EditableLabelModel";
import { FlowLinkModel } from "../link/FlowLinkModel";

type SimplePortDirection = "in" | "out";

export class SimplePortModel extends PortModel {
  direction: SimplePortDirection;
  maxNumberOfConnection: number;

  constructor(alignment: PortModelAlignment) {
    super({
      type: "diamond",
      name: alignment,
      alignment: alignment,
      locked: true,
    });
  }

  setDirection = (direction: SimplePortDirection) =>
    (this.direction = direction);
  setMaxNumberOfConnection = (maxNumberOfConnection: number) =>
    (this.maxNumberOfConnection = maxNumberOfConnection);

  createLinkModel(): DefaultLinkModel {
    // return new DefaultLinkModel();
    return new FlowLinkModel();
  }

  // https://github.com/projectstorm/react-diagrams/blob/2a810ca2d3bc9e632ad52eeee6487006ac70ffd7/packages/react-diagrams-defaults/src/port/DefaultPortModel.ts#L21
  link(port: SimplePortModel) {
    let link = this.createLinkModel();
    link.setSourcePort(this);
    link.setTargetPort(port);
    link.addLabel(new EditableLabelModel());
    return link;
  }

  canLinkToPort(port: SimplePortModel): boolean {
    if (this.getMaximumLinks() >= Object.keys(this.getLinks()).length) {
      if (port.direction !== this.direction) {
        return true;
      }
    }
    return false;
  }
}
