import type {
  chartOptionRenderer,
  ConfigType,
} from "../../../containers/chart-options/ChartOptions";
import type { IAnalysisType } from "../../../containers/exploration/single/domain";
import { WlyStackedBarChartIcon } from "../../icons/custom-icons/WlyStackedBarChartIcon";
import type { SingleChartDefinition } from "../domain";

export const stackedBarChartTimeserieDefinition: SingleChartDefinition = {
  label: "Stacked bar chart",
  allowTimeComparison: false,
  minMetrics: 1,
  maxMetrics: 1,
  minDimensions: 1,
  icon: <WlyStackedBarChartIcon />,
  axis: {
    bottom: {
      editable: true,
      isTimeFormattable: (a) => true,
    },
    left: {
      editable: true,
      scaleEditable: true,
    },
  },
  pivot: (analysisType: IAnalysisType) => false,
  canDisplay: (rs) => undefined,
  config: (a) => {
    const ret: Array<ConfigType> = [
      "hide-legend",
      "labels",
      "labels-stacked",
      "percent",
      "palette",
    ];
    return ret;
  },
  extractCustomizationDimensions: (d, q) => {
    return {
      dimensions: [],
    };
  },
  declarativeChartOptionRenderer: (options) => {
    const isPercent = !!options.chartOptions?.percent;
    const template: chartOptionRenderer = [
      {
        label: "Chart options",
        lines: [
          {
            type: "standard",
            component: "hide-legend",
          },
          {
            type: "standard",
            component: "labels",
          },

          !isPercent && {
            type: "standard",
            component: "labels-stacked",
          },
          {
            type: "standard",
            component: "percent",
          },
          {
            type: "standard",
            component: "palette",
          },
        ],
      },
    ];
    return template;
  },
  canPredict: (analysisType, query) => false,
  extractCustomizationSeries: (series, d) => {
    return {
      series: series.map((serie) => {
        // we modify the key to keep only the dimension value
        // we replace null with ∅
        const dimensionValues = serie.dimensions.map((d) => d.value ?? "∅");
        const label = dimensionValues.join(", ");
        return { ...serie, label: label };
      }),
      customColor: true,
      customLabel: true,
      customType: [],
    };
  },
};
