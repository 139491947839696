import { Form, Select, Typography } from "antd";
import cuid from "cuid";
import * as React from "react";
import FormActions from "../../../../../../../components/form/actions/FormActions";
import { MultiValueInput } from "../../../../../../../components/form/multi-values/MultiValueInput";
import Loading from "../../../../../../../components/layout/feedback/loading";
import type { AsyncData } from "../../../../../../../helpers/typescriptHelpers";
import type { IParsedColumnTest } from "../../../../../../../interfaces/sources";
import type {
  DataType,
  SchemaResult,
} from "../../../../../../../interfaces/transformations";
import TypeRenderer from "../../../../../../spreadsheet/renderer/TypeRenderer";
import {
  catchErrors,
  getPopUpContainer,
} from "../../../../../../transformations/domain";
import type { IDatasetUpdate } from "../../../../domain";

interface IColumnTestFormProps {
  initialValues?: IParsedColumnTest;
  onDone: () => void;
  onUpdateDataset: IDatasetUpdate;
  schema: AsyncData<SchemaResult>;
  parsedColumnTests: IParsedColumnTest[];
}

export function ColumnTestForm(props: IColumnTestFormProps) {
  const { initialValues, onDone, onUpdateDataset, schema, parsedColumnTests } =
    props;

  const [form] = Form.useForm<IParsedColumnTest>();
  const [id] = React.useState<string>(cuid());

  const isEditing = !!initialValues;
  const [submitting, setSubmitting] = React.useState<boolean>(false);
  const [selectedColumnDomain, setSelectedColumnDomain] = React.useState<
    DataType | undefined
  >();

  const getInitialValues = (): Pick<IParsedColumnTest, "id" | "type"> => {
    if (!isEditing) {
      return {
        id: cuid(),
        type: "not_null",
      };
    }

    return initialValues;
  };

  const formattedInitialValues = getInitialValues();

  const onFinish = () => {
    form.resetFields();
    onDone();
  };

  const renderInner = () => {
    if (schema.status === "initial" || schema.status === "loading") {
      return (
        <div style={{ height: 250 }}>
          <Loading />
        </div>
      );
    }

    if (schema.status === "error") {
      return <div style={{ height: 250 }}>{schema.error?.message}</div>;
    }

    return (
      <>
        <Form.Item
          name={["column"]}
          label="Which column you want to create your test from"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select
            showSearch
            getPopupContainer={getPopUpContainer(id)}
            onChange={(e) =>
              e && setSelectedColumnDomain(schema.data[e.toString()].domain)
            }
          >
            {Object.keys(schema.data)
              .sort((a, b) => a.localeCompare(b))
              .map((r, i) => {
                return (
                  <Select.Option value={r} key={i}>
                    <TypeRenderer
                      formula={schema.data[r].operation === "Table.AddColumn"}
                      domain={schema.data[r].domain}
                    />{" "}
                    {r}
                  </Select.Option>
                );
              })}
          </Select>
        </Form.Item>
        <Form.Item
          rules={[
            {
              required: true,
            },
          ]}
          label="Test type"
          name="type"
        >
          <Select optionLabelProp="label">
            <Select.Option value="not_null" label="Not null">
              <div>
                <Typography.Text>Not null</Typography.Text>
              </div>
              <div>
                <Typography.Text type="secondary">
                  Validates that there are no null values present in a column.
                </Typography.Text>
              </div>
            </Select.Option>
            <Select.Option value="unique" label="Unique">
              <div>
                <Typography.Text>Unique</Typography.Text>
              </div>
              <div>
                <Typography.Text type="secondary">
                  Validates that there are no duplicate values present in a
                  field.
                </Typography.Text>
              </div>
            </Select.Option>
            <Select.Option value="accepted_values" label="Accepted values">
              <div>
                <Typography.Text>Accepted values</Typography.Text>
              </div>
              <div>
                <Typography.Text type="secondary">
                  Validates that all of the values in a column are present in a
                  supplied list.
                </Typography.Text>
              </div>
            </Select.Option>
          </Select>
        </Form.Item>
        <Form.Item noStyle shouldUpdate>
          {() => {
            if (form.getFieldValue("type") === "accepted_values") {
              return (
                <Form.Item
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                  name={["values"]}
                  label="Values"
                >
                  <MultiValueInput placeholder="Accepted value" />
                </Form.Item>
              );
            }
          }}
        </Form.Item>
        <Form.Item hidden name={["quote"]} />
        <Form.Item hidden name={["id"]} />
        <Form.Item hidden name={["config", "severity"]} />
        <Form.Item hidden name={["config", "limit"]} />
      </>
    );
  };

  return (
    <Form
      form={form}
      initialValues={formattedInitialValues}
      layout="vertical"
      className="form-dropdown-form"
      onFinish={async (values) => {
        try {
          setSubmitting(true);
          await form.validateFields();
          const v = {
            ...values,
            quote: selectedColumnDomain === "NUMERIC" ? false : true,
          };
          await onUpdateDataset({
            columnTests: isEditing
              ? JSON.stringify(
                  parsedColumnTests.map((ct) => {
                    if (ct.id === values.id) {
                      return v;
                    }
                    return ct;
                  })
                )
              : JSON.stringify([...parsedColumnTests, v]),
          });
          setSubmitting(false);
          await form.resetFields();
          onFinish();
        } catch (err) {
          console.error(err);
          catchErrors(err, id);
          setSubmitting(false);
        }
      }}
    >
      <div className="form-dropdown-form-content" id={id}>
        {renderInner()}
      </div>
      <div className="form-dropdown-form-buttons">
        <FormActions
          flex={true}
          size="small"
          onCancel={onFinish}
          isSubmitting={submitting}
        />
      </div>
    </Form>
  );
}
