import {
  CheckCircleOutlined,
  ClockCircleOutlined,
  CloseCircleOutlined,
  MinusCircleOutlined,
  MoreOutlined,
  QuestionCircleOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import { Button, Dropdown, List, Popover, Space, Tag, Typography } from "antd";
import { inject, observer } from "mobx-react";
import * as React from "react";
import type { InjectedAntUtilsProps } from "../../../../../components/ant-utils/withAntUtils";
import { withAntUtils } from "../../../../../components/ant-utils/withAntUtils";
import { compose } from "../../../../../components/compose/WlyCompose";
import { SourceImageRenderer } from "../../../../../components/sources/SourceImageRenderer";
import type { IDestination } from "../../../../../interfaces/destinations";
import type { ISource } from "../../../../../interfaces/sources";
import type { WorkbenchUIStoreProps } from "../../../../../store/workbenchUIStore";
import workbenchUIStore from "../../../../../store/workbenchUIStore";
import { getSourceStatus } from "../../../../workspace/domain";
import type { IActiveObject } from "../../domain";
import { ConnectorExecutionStatusRenderer } from "../../viewer/connector/ConnectorStatusRenderer";
import RenameConnectorModal from "../../viewer/connector/modals/RenameConnectorModal";
import "./ConnectorsTable.scss";

const { Text } = Typography;

const getSourceFrequency = (source: ISource): string => {
  if (source.isEnabled === false) return "manual";
  switch (source.syncPeriod) {
    case "five_minutes":
      return "5 minutes";
    case "fifteen_minutes":
      return "15 minutes";
    case "thirty_minutes":
      return "30 minutes";
    case "one_hour":
      return "1 hour";
    case "two_hours":
      return "2 hour";
    case "three_hours":
      return "3 hours";
    case "six_hours":
      return "6 hours";
    case "eight_hours":
      return "8 hours";
    case "twelve_hours":
      return "12 hours";
    case "twenty_four_hours":
      return "24 hours";
    default:
      return source.syncPeriod;
  }
};

const getSourceTag = (source: ISource): JSX.Element => {
  const status = getSourceStatus(source);
  let color = "default";
  let icon = <MinusCircleOutlined />;
  let state = "Unknown sync state";

  switch (status) {
    case "success":
      color = "green";
      state = "Last sync successful";
      icon = <CheckCircleOutlined />;
      break;
    case "syncing":
      color = "blue";
      state = "Syncing";
      icon = <SyncOutlined spin />;
      break;
    case "error":
      color = "red";
      state = "Last sync in error";
      icon = <CloseCircleOutlined />;
      break;
    case "late":
      color = "red";
      state = "Sync is late";
      icon = <ClockCircleOutlined />;
      break;
    case "misconfiguration":
      color = "default";
      state = "Missing source settings";
      icon = <QuestionCircleOutlined />;
      break;
  }

  return (
    <Tag color={color} icon={icon}>
      {state}
    </Tag>
  );
};

export type IOnDeleteConnector = (id: string) => Promise<void>;

export type IOnUpdateConnector = (
  id: string,
  data: Partial<ISource>
) => Promise<void>;

interface IConnectorsTableProps {
  connectors: ISource[];
  activeObject?: IActiveObject;
  onActiveObjectChange: (activeObject: IActiveObject) => void;
  onDeleteConnector: IOnDeleteConnector;
  onUpdateConnector: IOnUpdateConnector;
  currentWarehouse: IDestination;
}

type Props = IConnectorsTableProps &
  WorkbenchUIStoreProps &
  InjectedAntUtilsProps;

interface IState {
  renameOpen?: ISource;
}

class ConnectorsTable extends React.Component<Props, IState> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  public render() {
    const {
      activeObject,
      antUtils,
      connectors,
      onActiveObjectChange,
      onDeleteConnector,
      onUpdateConnector,
    } = this.props;

    return (
      <>
        <List<ISource>
          itemLayout="horizontal"
          dataSource={connectors}
          className="connector-content-list"
          renderItem={(source) => {
            return (
              <List.Item
                className={`connector-content-list-item ${
                  activeObject &&
                  activeObject.type === "connector" &&
                  activeObject.value === source.id
                    ? "active"
                    : ""
                }`}
                onClick={() =>
                  onActiveObjectChange({
                    type: "connector",
                    value: source.id,
                  })
                }
              >
                <Popover
                  placement="right"
                  trigger={"hover"}
                  overlayStyle={{
                    maxWidth: 250,
                  }}
                  title={
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: 6,
                      }}
                    >
                      <div>
                        <SourceImageRenderer
                          img={source.sourceMeta.publicInfo.logo}
                          size={20}
                          alt={source.sourceMeta.publicInfo.name}
                        />
                      </div>
                      <div
                        style={{
                          fontWeight: "bold",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {source.name}
                      </div>
                    </div>
                  }
                  content={
                    <Space direction="vertical" style={{ width: "100%" }}>
                      <div>
                        <Text strong>Frequency: </Text>
                        <Text>{getSourceFrequency(source)}</Text>
                      </div>
                      {getSourceTag(source)}
                    </Space>
                  }
                >
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      padding: "0px 8px",
                      gap: 8,
                      alignItems: "center",
                    }}
                  >
                    <div style={{ flex: 0 }}>
                      <SourceImageRenderer
                        img={source.sourceMeta.publicInfo.logo}
                        size={20}
                        alt={source.sourceMeta.publicInfo.name}
                      />
                    </div>
                    <div style={{ flex: 1, overflow: "hidden" }}>
                      <Typography.Text
                        ellipsis
                        type={
                          getSourceStatus(source) === "misconfiguration"
                            ? "secondary"
                            : null
                        }
                      >
                        {source.name}
                      </Typography.Text>
                      <br />
                    </div>
                    {getSourceStatus(source) !== "misconfiguration" && (
                      <div style={{ flex: 0 }}>
                        <ConnectorExecutionStatusRenderer connector={source} />
                      </div>
                    )}
                    <div style={{ flex: 0 }}>
                      <Dropdown
                        trigger={["click"]}
                        menu={{
                          items: [
                            {
                              label: "Rename",
                              key: "rename",
                              onClick: (e) => {
                                e.domEvent.stopPropagation();
                                this.setState({ renameOpen: source });
                              },
                            },
                            {
                              label: "Delete",
                              key: "delete",
                              onClick: (e) => {
                                e.domEvent.stopPropagation();
                                return antUtils.modal.confirm({
                                  title: "Do you want to proceed?",
                                  content:
                                    "You are about to delete this connector, this cannot be undone. Do you want to proceed ?",
                                  okButtonProps: {
                                    danger: true,
                                  },
                                  onCancel: () => undefined,
                                  onOk: async () => {
                                    try {
                                      await onDeleteConnector(source.id);

                                      workbenchUIStore.removeActiveObject({
                                        type: "connector",
                                        value: source.id,
                                      });
                                      antUtils.message.success(
                                        "Connector successfully deleted"
                                      );
                                    } catch (error) {
                                      console.error(error);
                                      antUtils.message.error(
                                        "Error while deleting connector, try again later"
                                      );
                                    }
                                  },
                                });
                              },
                              danger: true,
                            },
                          ],
                        }}
                        placement={"bottomRight"}
                      >
                        <Button
                          size="small"
                          type="text"
                          shape="circle"
                          style={{
                            marginTop: 4,
                          }}
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                        >
                          <MoreOutlined />
                        </Button>
                      </Dropdown>
                    </div>
                  </div>
                </Popover>
              </List.Item>
            );
          }}
        />
        <RenameConnectorModal
          open={!!this.state.renameOpen}
          onClose={() => this.setState({ renameOpen: null })}
          onRename={(v) =>
            this.state.renameOpen
              ? onUpdateConnector(this.state.renameOpen.id, {
                  name: v.name,
                })
              : Promise.resolve()
          }
          initialValue={
            this.state.renameOpen
              ? { name: this.state.renameOpen.name }
              : undefined
          }
        />
      </>
    );
  }
}

export default compose<Props, IConnectorsTableProps>(
  inject("workbenchUIStore"),
  observer,
  withAntUtils
)(ConnectorsTable);
