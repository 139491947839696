import { gql } from "@apollo/client";
import type { MutateOptions } from "../../../../../../../../components/hooks/query/useQGLMutate";
import { useGQLMutate } from "../../../../../../../../components/hooks/query/useQGLMutate";
import { OBJECT_PRESET_FILTER_FRAGMENT } from "../../../../../../../../fragments/object";
import type { IObjectPresetFilter } from "../../../../../../../../interfaces/object";

type MutationData = { updateObjectPresetFilter: IObjectPresetFilter };

type MutationVariables = {
  data: Array<{ id: string; data: Record<string, any> }>;
};

const UpdateObjectPresetFilterMutation = gql`
  ${OBJECT_PRESET_FILTER_FRAGMENT}

  mutation UpdateObjectPresetFilter($data: [ObjectPresetFiltersUpdateInput]) {
    updateObjectPresetFilters(data: $data) {
      ...ObjectPresetFilterFragment
    }
  }
`;

export const useUpdateObjectPresetFilters = (
  options?: MutateOptions<MutationData>
) => {
  return useGQLMutate<MutationData, MutationVariables>(
    UpdateObjectPresetFilterMutation,
    options
  );
};
