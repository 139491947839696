import { Space } from "antd";
import * as React from "react";
import type { DraggableMeasure } from "../../../../../../../components/measures/measure-item/DraggableMeasureItem";
import { DraggableMeasureItem } from "../../../../../../../components/measures/measure-item/DraggableMeasureItem";
import type { MeasureItemSortValue } from "../../../../../../../components/measures/measure-item/MeasureItem";
import MeasurePicker from "../../../../../../../components/measures/measure-picker/MeasurePicker";
import type { AvailableColumn } from "../../../domain";

interface IPropertySortProps {
  columns?: Array<AvailableColumn>;
  value?: Array<[string, MeasureItemSortValue]>;
  onChange?: (value: Array<[string, MeasureItemSortValue]>) => any;
}

const PropertySort: React.FunctionComponent<IPropertySortProps> = (props) => {
  const { onChange } = props;
  const columns = props.columns ? props.columns : [];
  const value = props.value ? props.value : [];

  const getColumnFilterKey = (c: AvailableColumn) => {
    if (c.type === "property") return c.data.sortAndFilterKey;
    return c.data.key;
  };

  const availableColumns = columns
    .filter(
      (column) =>
        !value
          .map((v) => v[0])
          .includes(
            column?.type === "property"
              ? column.data.sortAndFilterKey
              : column.data.key
          )
    )
    .filter((m) => m);

  const isMeasureAvailable = (key: string): boolean => {
    return !!columns.find((c) => getColumnFilterKey(c) === key);
  };

  const onMeasureDelete = (key: string) => {
    if (!onChange) return;
    const newValue = [...value.filter((v) => v[0] !== key)];
    onChange(newValue);
  };

  const onMeasureAdd = (key: string, position?: number) => {
    if (!isMeasureAvailable(key) || !onChange) return;
    const newValue = [...value];
    let dropIndex = newValue.length;
    if (position != null) dropIndex = position;
    newValue.splice(dropIndex, 0, [key, "asc"]);
    onChange(newValue);
  };

  const onMeasureDrop = (key: string, position: number) => {
    if (typeof position !== "number" || !onChange) return;
    const order = value.find((v) => v[0] === key)?.[1];
    if (!order) return;
    const newValue = [...value.filter((v) => v[0] !== key)];
    let dropIndex = newValue.length;
    if (position != null) dropIndex = position;
    newValue.splice(dropIndex, 0, [key, order]);
    onChange(newValue);
  };

  const onMeasureSort = (key: string, order: MeasureItemSortValue) => {
    if (!onChange) return;

    const newValue = value.map((v) => {
      if (v[0] === key) {
        const k = v[0];
        const o = order;

        const item: [string, MeasureItemSortValue] = [k, o];
        return item;
      } else {
        return v;
      }
    });
    onChange(newValue);
  };

  return (
    <Space direction="vertical" style={{ width: "100%" }} size={4}>
      {value.map((sm, i) => {
        const column = columns.find(
          (column) => getColumnFilterKey(column) === sm[0]
        );
        const onDelete = () => {
          onMeasureDelete(sm[0]);
        };
        const onDrop = (item: DraggableMeasure, position: number) => {
          onMeasureDrop(item.id, position);
        };
        const onSort = (s: MeasureItemSortValue) => {
          onMeasureSort(sm[0], s);
        };
        return (
          <DraggableMeasureItem
            key={sm[0]}
            id={sm[0]}
            zone={"sort"}
            index={i}
            onDrop={onDrop}
            onRemove={onDelete}
            type={column?.type ?? "property"}
            isDeleted={!!!column}
            description={column?.data.description}
            name={column?.data.label ?? "Unknown"}
            onDelete={onDelete}
            sort={sm[1]}
            onSort={onSort}
            accept={["property", "metric"]}
          />
        );
      })}
      <MeasurePicker
        availableMeasures={availableColumns.map((c) => ({
          key: getColumnFilterKey(c),
          label: c.data.label,
        }))}
        selectedMeasureKeys={value.map((m) => m[0])}
        onMeasureChange={(i) => onMeasureAdd(i)}
        disabled={false}
        block
      >
        Add
      </MeasurePicker>
    </Space>
  );
};

export default PropertySort;
