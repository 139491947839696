import { Dropdown } from "antd";
import cuid from "cuid";
import * as React from "react";
import { SketchPicker } from "react-color";
import type { InjectedOrgProps } from "../../../containers/orgs/WithOrg";
import WithOrg from "../../../containers/orgs/WithOrg";
import { compose } from "../../compose/WlyCompose";
import type { IPalette } from "../../palette/utils/paletteData";
import { getSelectedPalette } from "../../palette/utils/paletteData";
import "./ColorPicker.scss";
import ColorRenderer from "./ColorRenderer";

interface IColorPickerProps {
  value?: string;
  defaultValue?: string;
  onChange?: (v: string) => void;
  presets?: string[];
}

type Props = IColorPickerProps & InjectedOrgProps;

class ColorPicker extends React.Component<Props> {
  id: string = cuid();

  public render() {
    const { onChange, defaultValue, presets, org } = this.props;

    let value = this.props.value;
    if (!value || value.length === 0) {
      value = defaultValue;
    }
    if (!value || value.length === 0) {
      value = "rgba(0, 0, 0, 0)";
    }

    const currentPalette: IPalette = getSelectedPalette(org, {
      type: "PALETTE_SELECTION",
      collection_name: "default",
      index: 0,
      palette_type: "discrete",
    });

    return (
      <Dropdown
        getPopupContainer={() => {
          if (document.getElementById(this.id)) {
            return document.getElementById(this.id)!;
          }
          return document.body;
        }}
        dropdownRender={() => {
          return (
            <div
              className="ant-dropdown-menu"
              style={{
                padding: 0,
              }}
            >
              <SketchPicker
                color={value}
                onChange={(c) => {
                  if (onChange) {
                    onChange(
                      `rgba(${c.rgb.r}, ${c.rgb.g}, ${c.rgb.b}, ${
                        c.rgb.a ? c.rgb.a : 1
                      })`
                    );
                  }
                }}
                presetColors={presets ?? currentPalette.colors}
              />
            </div>
          );
        }}
        overlayStyle={{
          maxWidth: 214,
          minWidth: 214,
        }}
        trigger={["click"]}
        placement="bottomLeft"
        arrow={true}
      >
        <div id={this.id}>
          <ColorRenderer color={value} />
        </div>
      </Dropdown>
    );
  }
}

export default compose<Props, IColorPickerProps>(WithOrg)(ColorPicker);
