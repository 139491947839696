import { Button, Space } from "antd";
import * as React from "react";
import type { XYCoord } from "react-dnd";
import { useDrop } from "react-dnd";
import type { DraggedItemProps } from "../../../../../custom-layout-editor/main/sider/add/domain";
import { IObjectLayoutItemType } from "../../../../../custom-layout-editor/main/sider/add/domain";
import { COLOR_GOLD, COLOR_NEUTRAL, COLOR_UP } from "../../../layout/domain";

interface IHoverWrapperProps {
  edit?: boolean;
  hovered?: string;
  selected?: string;
  componentKey: string;
  componentLabel: string;
  setAction: (action: "hover" | "click", key: string | null) => void;
  drop?: {
    accept: IObjectLayoutItemType;
    onDrop: (item: DraggedItemProps, position: "top" | "bottom" | null) => void;
  };
  toolbox?: Array<{
    key: string;
    icon: React.ReactNode;
    name: string;
    onClick: () => void;
  }>;
  children?: React.ReactNode;
}

export function HoverWrapper(
  props: React.PropsWithChildren<IHoverWrapperProps>
) {
  const {
    children,
    edit,
    componentKey,
    componentLabel,
    hovered,
    selected,
    setAction,
    drop,
    toolbox,
  } = props;

  const ref = React.useRef<HTMLDivElement>(null);
  const [showPosition, setShowPosition] = React.useState<
    null | "top" | "bottom"
  >(null);

  const getPosition = (monitor): null | "top" | "bottom" => {
    if (!ref.current) {
      return null;
    }

    // Determine rectangle on screen
    const hoverBoundingRect = ref.current?.getBoundingClientRect();

    // Get vertical middle
    const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

    // Determine mouse position
    const clientOffset = monitor.getClientOffset();

    // Get pixels to the top
    const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

    // Only perform the move when the mouse has crossed half of the items height
    // When dragging downwards, only move when the cursor is below 50%
    // When dragging upwards, only move when the cursor is above 50%

    // Dragging downwards
    if (hoverClientY < hoverMiddleY) {
      return "top";
    }

    // Dragging upwards
    if (hoverClientY > hoverMiddleY) {
      return "bottom";
    }

    return null;
  };

  const [{ isOver, canDrop }, dropRef] = useDrop(
    () => ({
      accept: drop?.accept ? drop.accept : "none",
      drop: (item, monitor) => {
        const position = getPosition(monitor);
        drop?.onDrop(item as DraggedItemProps, position);
      },
      hover: (item, monitor) => {
        setShowPosition(getPosition(monitor));
      },
      canDrop: (item: DraggedItemProps, monitor) => {
        if (item) {
          if (item.operation === "add") {
            return true;
          } else if (
            item.operation === "move" &&
            `widget::${item.data.id}` !== componentKey
          ) {
            return true;
          }
        }
        return false;
      },
      collect: (monitor) => {
        return {
          canDrop: !!monitor.canDrop(),
          isOver: !!monitor.isOver(),
        };
      },
    }),
    [drop?.onDrop]
  );

  if (edit) {
    dropRef(ref);
  }

  if (!edit) {
    return <>{children}</>;
  }

  const getBarColor = () => {
    if (drop?.accept === IObjectLayoutItemType.LAYOUT) {
      return COLOR_UP;
    }
    if (drop?.accept === IObjectLayoutItemType.WIDGET) {
      return COLOR_GOLD;
    }
    return COLOR_NEUTRAL;
  };

  const layoutAddBar = (
    <div style={{ outline: `2px solid ${getBarColor()}` }} />
  );

  const buildTools = () => {
    return (
      <Space>
        {(toolbox || []).map((t) => {
          return (
            <Button
              key={t.key}
              size="small"
              type="text"
              style={{ color: "#fff" }}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                t.onClick();
              }}
            >
              {t.icon}
            </Button>
          );
        })}
      </Space>
    );
  };

  if (selected === componentKey) {
    return (
      <div
        ref={ref}
        onDrag={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
        className="selected"
      >
        {isOver && canDrop && showPosition === "top" ? layoutAddBar : null}
        {toolbox && toolbox.length ? (
          <div className="toolbox">{buildTools()}</div>
        ) : null}
        {children}
        {isOver && canDrop && showPosition === "bottom" ? layoutAddBar : null}
      </div>
    );
  }

  const hvrd = hovered === componentKey;

  return (
    <>
      {isOver && canDrop && showPosition === "top" ? layoutAddBar : null}
      <div
        ref={ref}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          setAction("click", componentKey);
        }}
        onMouseEnter={() => setAction("hover", componentKey)}
        onMouseLeave={() => setAction("hover", null)}
        className={hvrd ? "hovered" : "hoverable"}
      >
        {hvrd ? <div className="cell-name">{componentLabel}</div> : null}
        {children}
      </div>
      {isOver && canDrop && showPosition === "bottom" ? layoutAddBar : null}
    </>
  );
}
