import { Alert } from "antd";
import * as React from "react";
import ThirdParty from "../../../assets/sources/third-party.svg";
import Warehouse from "../../../assets/sources/warehouse.svg";
import { compose } from "../../../components/compose/WlyCompose";
import WlyModal from "../../../components/modal/WlyModal";
import { WlySelector } from "../../../components/selector/WlySelector";
import type { IDestination } from "../../../interfaces/destinations";
import { CatalogModal } from "../../catalog/CatalogModal";
import type { InjectedOrgProps } from "../../orgs/WithOrg";
import WithOrg from "../../orgs/WithOrg";
import type { TableTabItem } from "../../spreadsheet/domain";
import type { DatasetEditionSavedData } from "../../workbench/workbench/dataset/modal/DatasetEdition";
import DatasetEdition from "../../workbench/workbench/dataset/modal/DatasetEdition";

interface ISourceCreationProps {
  tables: Array<TableTabItem>;
  visible: boolean;
  onCancel: () => void;
  onSave: DatasetEditionSavedData;
  currentWarehouse: IDestination;
}

type Props = ISourceCreationProps & InjectedOrgProps;

type Step = "STEP_1" | "STEP_2";

const SourceCreation: React.FunctionComponent<Props> = (props) => {
  const { tables, visible, onCancel, onSave, currentWarehouse } = props;

  const isWarehouseActive = !!currentWarehouse && currentWarehouse.isBiEnabled;

  const [step, setStep] = React.useState<Step>(
    currentWarehouse?.isDataLoadingEnabled ? "STEP_1" : "STEP_2"
  );
  const [selected, setSelected] = React.useState<"CATALOG" | "FOLDER">(
    currentWarehouse?.isDataLoadingEnabled ? "CATALOG" : "FOLDER"
  );

  const renderInnerModal = () => {
    if (!isWarehouseActive) {
      return (
        <Alert
          message="Warehouse connection error"
          description="There is an issue with your warehouse connection. Please reconnect it and try again"
          type="error"
          showIcon
        />
      );
    } else if (step === "STEP_1") {
      return (
        <WlySelector
          left={{
            title: "From third party",
            description:
              "Use our source catalog to connect to any third party you own.",
            image: ThirdParty,
            id: "CATALOG",
            disabled: !currentWarehouse.destinationMeta.targetName,
          }}
          right={{
            title: "From warehouse",
            description: "Import tables from your warehouse.",
            image: Warehouse,
            id: "FOLDER",
            disabled: currentWarehouse.destinationMeta.isWhalyManaged,
          }}
          selected={selected as any}
          onSelect={(s) => {
            setSelected(s as any);
          }}
        />
      );
    } else if (step === "STEP_2" && selected === "FOLDER") {
      return (
        <DatasetEdition
          action="CREATE_SOURCE_AND_IMPORT_DATASETS"
          tables={tables}
          currentWarehouse={currentWarehouse}
          visible={true}
          onCancel={() => {
            setStep("STEP_1");
            onCancel();
          }}
          onSave={async (values) => {
            try {
              await onSave(values);
            } catch (error) {
              console.error(error);
            }
          }}
        />
      );
    } else if (step === "STEP_2" && selected === "CATALOG") {
      return (
        <CatalogModal
          type="source"
          selectedWarehouseSlug={currentWarehouse?.slug}
          open={visible}
          onClose={() => {
            setStep("STEP_1");
            onCancel();
          }}
        />
      );
    }
  };

  return (
    <WlyModal
      open={visible}
      title={"Create a source"}
      okText={!isWarehouseActive ? "Ok" : "Create"}
      cancelText="Cancel"
      onClose={() => {
        onCancel();
      }}
      onOk={() => {
        if (!isWarehouseActive) {
          onCancel();
        } else if (step === "STEP_1") {
          setStep("STEP_2");
        }
      }}
    >
      {renderInnerModal()}
    </WlyModal>
  );
};

export default compose<Props, ISourceCreationProps>(WithOrg)(SourceCreation);
