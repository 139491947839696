import type { FormInstance } from "antd";
import { message } from "antd";
import type {
  BundledTransformation,
  SchemaResult,
  Transformation,
} from "../../interfaces/transformations";

export interface ICreateComponentProps<T extends Transformation> {
  onSave?: (
    transformations: Transformation[],
    reloadView?: boolean,
    scrollTo?: string
  ) => Promise<void>;
  onCancel?: () => void;
  isStale?: (stale: boolean) => void;
  currentTransformation: T;
  transformations: Transformation[];
  submitting: boolean;
  reports: Array<IReport>;
  columns: string[];
  currentSchema: SchemaResult;
  setFormInstance?: (f: FormInstance) => void;
}

export interface IReport {
  key: string;
  label: string;
  query: Transformation[];
  isStale?: boolean;
  isPersistedAs?: string;
}

export interface IListComponentProps<T extends Transformation> {
  transformation: BundledTransformation;
}

export const getPopUpContainer = (id: string) => (): HTMLElement => {
  const el = document.getElementById(id);
  if (el) {
    return el;
  }
  return document.body;
};

export const validateColumnName =
  (columns: string[], prevColumnName?: string) =>
  (_: any, v: any): Promise<void> => {
    if (
      columns.map((column) => column.toLowerCase()).indexOf(v.toLowerCase()) >
        -1 &&
      prevColumnName !== v
    ) {
      return Promise.reject("This columnn name is already used");
    }
    if ((v as string).match(/^\d/)) {
      return Promise.reject("Column name cannot start with a number");
    }
    if ((v as string).length >= 128) {
      return Promise.reject(
        "Column name cannot be more than 128 characters length"
      );
    }
    if (!(v as string).match(/^[\da-zA-Z_]*$/)) {
      return Promise.reject(
        "Column name can only contain alphanumeric characters and _"
      );
    }
    return Promise.resolve();
  };

export const replaceRemoveColumns = (
  transformations: Transformation[]
): Transformation[] => {
  return transformations.map((t) => {
    if (t.operation.type === "Table.RemoveColumns") {
      return {
        ...t,
        operation: {
          ...t.operation,
          type: "Table.Ref",
          args: {
            table: t.operation.args.table,
          },
        },
      };
    }
    return t;
  });
};

export const catchErrors = (err: Error, id?: string) => {
  message.error(`An unexpected error happended... ${err.message}`);
};
