import Icon from "@ant-design/icons";
import type { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";

export const WlyHorizontalBarChartIcon = (
  props: Partial<CustomIconComponentProps>
) => (
  <Icon
    component={() => (
      <svg
        width="1em"
        height="1em"
        viewBox="0 0 213 213"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_1008_1284)">
          <rect
            x="199.739"
            width="28.1201"
            height="199.739"
            transform="rotate(90 199.739 0)"
            fill="#3A5C83"
          />
          <rect
            x="146.697"
            y="46.22"
            width="28.1201"
            height="146.696"
            transform="rotate(90 146.697 46.22)"
            fill="#3A5C83"
          />
          <rect
            x="213"
            y="92.4399"
            width="28.1201"
            height="213"
            transform="rotate(90 213 92.4399)"
            fill="#3A5C83"
          />
          <rect
            x="82.0505"
            y="138.66"
            width="28.1201"
            height="82.0505"
            transform="rotate(90 82.0505 138.66)"
            fill="#3A5C83"
          />
          <rect
            x="123.49"
            y="184.88"
            width="28.1201"
            height="123.49"
            transform="rotate(90 123.49 184.88)"
            fill="#3A5C83"
          />
        </g>
        <defs>
          <clipPath id="clip0_1008_1284">
            <rect width="213" height="213" fill="white" />
          </clipPath>
        </defs>
      </svg>
    )}
    {...props}
  />
);
