import { useForm } from "antd/es/form/Form";
import { SimpleFormModal } from "../../../../../../components/form/modal/SimpleFormModal";
import type { RenameObjectViewFormData } from "../forms/RenameObjectViewForm";
import { RenameObjectViewForm } from "../forms/RenameObjectViewForm";

type Props = {
  open: boolean;
  initialValues?: Partial<RenameObjectViewFormData>;
  isSaving: boolean;
  onCancel: () => void;
  onSave: (values: RenameObjectViewFormData) => any;
};

export default function RenameObjectViewModal({
  open,
  initialValues,
  isSaving,
  onCancel,
  onSave,
}: Props) {
  const [form] = useForm<RenameObjectViewFormData>();

  return (
    <SimpleFormModal<RenameObjectViewFormData>
      form={form}
      open={open}
      onCancel={onCancel}
      isSaving={isSaving}
      modalProps={{ title: "Rename view", okText: "Rename", width: 420 }}
    >
      <RenameObjectViewForm
        form={form}
        initialValues={initialValues}
        onSave={onSave}
      />
    </SimpleFormModal>
  );
}
