// import "ag-grid-community/styles/ag-grid.css";
// import "ag-grid-community/styles/ag-theme-alpine.css";
import type { ComponentType } from "react";
import { useCallback, useEffect, useRef, useState } from "react";
import type {
  GridChildComponentProps,
  ListChildComponentProps,
} from "react-window";
import { FixedSizeGrid, FixedSizeList } from "react-window";
import useResizeObserver from "use-resize-observer";
import { getScrollbarSize } from "../../helpers/domHelpers";
import type { SchemaResult } from "../../interfaces/transformations";
import Feednack from "../layout/feedback/feedback";
import Loading from "../layout/feedback/loading";
import "./SpreadsheetScrollSync.scss";

export type SpreadsheetHeaderCellRenderer = ComponentType<
  ListChildComponentProps<SchemaResult>
>;
export type SpreadsheetBodyCellRenderer = ComponentType<
  GridChildComponentProps<SchemaResult>
>;

interface IFuncSpreadsheetScrollSyncProps {
  renderHeaderCell: SpreadsheetHeaderCellRenderer;
  renderBodyCell: SpreadsheetBodyCellRenderer;
  columnCount: number;
  rowCount: number;
  scrollToColumnIndex?: number;
  isLoading?: boolean;
  initialScrollLeft?: number;
  initialScrollTop?: number;
  onScroll?: (scrollLeft: number, scrollTop: number) => void;
}

// some `const`s to quickly move the grid around
const cellHeight = 30;
const cellWidth = 200;

function SpreadsheetScrollSync(props: IFuncSpreadsheetScrollSyncProps) {
  const {
    columnCount,
    rowCount,
    isLoading,
    scrollToColumnIndex,
    renderHeaderCell,
    renderBodyCell,
    onScroll,
    initialScrollTop,
    initialScrollLeft,
  } = props;

  // we set default value to avoid undefined errors in CSS
  const { ref, width = 1, height = 1 } = useResizeObserver();

  const [isHeaderReady, setIsHeaderReady] = useState<boolean>(false);
  const [isGridReady, setIsGridReady] = useState<boolean>(false);

  const headerRef = useRef<FixedSizeList<SchemaResult>>(null);
  const gridRef = useRef<FixedSizeGrid<SchemaResult>>(null);
  const scrollTimeoutRef = useRef<ReturnType<typeof setTimeout>>(null);
  const isWlyScrolling = useRef<boolean>(false);
  const hasDoneInitialScroll = useRef<boolean>(false);

  // we need to substract the scrollbar size from the width
  const scrollbarSize = getScrollbarSize();

  // create event handlers for the `onScroll` events.
  // NOTE: they are wrapped in `useCallback` for performance reasons
  const handleGridScroll = useCallback((e) => {
    // we make sure the platform isn't being restoring a scroll
    if (isWlyScrolling.current) return;
    // we make sure both grids are ready before scrolling
    if (!headerRef?.current || !gridRef.current) return;
    // from the official docs:
    // > scrollUpdateWasRequested is a boolean.
    // > This value is true if the scroll was caused by scrollTo() or scrollToItem(),
    // > And false if it was the result of a user interaction in the browser.
    //
    // so we want to ignore events that were from `scrollTo`
    if (e.scrollUpdateWasRequested) return;
    headerRef.current?.scrollTo(e.scrollLeft);
    gridRef.current?.scrollTo({
      scrollLeft: e.scrollLeft,
      scrollTop: e.scrollTop,
    });
    clearTimeout(scrollTimeoutRef.current);
    scrollTimeoutRef.current = setTimeout(() => {
      onScroll?.(e.scrollLeft, e.scrollTop);
    }, 200);
  }, []);

  // when both grid are ready, we check if we have a initial scroll and we apply it
  // we use timeouts to make sure everything can be processed
  useEffect(() => {
    if (!isGridReady || !isHeaderReady || hasDoneInitialScroll.current) return;
    isWlyScrolling.current = true;
    hasDoneInitialScroll.current = true;
    setTimeout(() => {
      if (typeof scrollToColumnIndex === "number") {
        headerRef.current?.scrollToItem?.(scrollToColumnIndex);
        gridRef.current?.scrollToItem?.({
          columnIndex: scrollToColumnIndex,
        });
      } else {
        headerRef.current?.scrollTo?.(initialScrollLeft ?? 0);
        gridRef.current?.scrollTo?.({
          scrollTop: initialScrollTop ?? 0,
          scrollLeft: initialScrollLeft ?? 0,
        });
      }
      setTimeout(() => {
        isWlyScrolling.current = false;
      }, 30);
    }, 20);
  }, [
    isGridReady,
    isHeaderReady,
    initialScrollLeft,
    initialScrollTop,
    scrollToColumnIndex,
  ]);

  const onGridRefChange = useCallback((node) => {
    if (!node) {
      gridRef.current = null;
    } else {
      gridRef.current = node;
      setIsGridReady(true);
    }
  }, []);

  const onHeaderRefChange = useCallback((node) => {
    if (!node) {
      headerRef.current = null;
    } else {
      headerRef.current = node;
      setIsHeaderReady(true);
    }
  }, []);

  return (
    <div className="spreadsheet-grid-row">
      <div
        className="smooth-scroller"
        style={{
          position: "absolute",
          zIndex: 100,
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          pointerEvents: "none",
          willChange: "transform",
        }}
        ref={ref}
      />
      <div className={"spreadsheet-grid-column"}>
        <div
          style={{
            height: cellHeight,
            width: `calc( 100% - ${scrollbarSize.width}px )`,
          }}
        >
          <FixedSizeList
            height={cellHeight}
            itemSize={cellWidth}
            width={width - scrollbarSize.width}
            layout="horizontal"
            itemCount={columnCount}
            ref={onHeaderRefChange}
            style={{
              overflow: "hidden",
            }}
          >
            {renderHeaderCell}
          </FixedSizeList>
        </div>
        {rowCount === 0 ? (
          <div style={{ width: width, height: "100%" }}>
            <Feednack>{!isLoading ? "No Data" : <Loading />}</Feednack>
          </div>
        ) : (
          <div
            style={{
              height: cellHeight,
              width: `calc( 100% - ${scrollbarSize.width}px )`,
            }}
          >
            <FixedSizeGrid
              columnCount={columnCount}
              columnWidth={cellWidth}
              height={height - cellHeight}
              width={width}
              rowCount={rowCount}
              rowHeight={cellHeight}
              onScroll={handleGridScroll}
              ref={onGridRefChange}
            >
              {renderBodyCell}
            </FixedSizeGrid>
          </div>
        )}
      </div>
    </div>
  );
}

export default SpreadsheetScrollSync;
