import {
  DeleteOutlined,
  DownloadOutlined,
  FilterOutlined,
  LinkOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { type AgGridReact } from "ag-grid-react";
import { Button, Dropdown, Space, Tooltip } from "antd";
import copy from "copy-to-clipboard";
import _ from "lodash";
import { inject } from "mobx-react";
import { useState } from "react";
import type { RouteComponentProps } from "react-router";
import { withRouter } from "react-router";
import type {
  ISetTableQueryAction,
  TableQuery,
} from "../../../../../../../../components/ag-grid/object-table/domain";
import type { InjectedAntUtilsProps } from "../../../../../../../../components/ant-utils/withAntUtils";
import { withAntUtils } from "../../../../../../../../components/ant-utils/withAntUtils";
import { compose } from "../../../../../../../../components/compose/WlyCompose";
import {
  IObjectViewType,
  type IObject,
  type IObjectView,
} from "../../../../../../../../interfaces/object";
import { routeDescriptor } from "../../../../../../../../routes/routes";
import { type ObjectUIStoreProps } from "../../../../../../../../store/objectUIStore";
import { BORDER_COLOR } from "../../../../../layout/domain";
import { parseLabel, type IRecord } from "../../../../../record/domain";
import type { AvailableColumn } from "../../../../domain";
import type { AvailableProperty } from "../../../domain";
import {
  DUMMY_TOKEN,
  getAvailableDimensions,
  getDimensionPartFromTimeDimension,
  getTimePartFromTimeDimension,
  MANUAL_LIST_LIMIT,
  tableQueryManualFilterKey,
} from "../../../domain";
import SaveButton from "../../../header/SaveButton";
import { ObjectToolbarFields } from "../../../toolbar/ObjectToobarFields";
import ObjectToolbarFilter from "../../../toolbar/ObjectToobarFilter";
import { ObjectToolbarGroup } from "../../../toolbar/ObjectToobarGroup";
import { ObjectToolbarSort } from "../../../toolbar/ObjectToobarSort";
import { ObjectToolbarSelectedRows } from "../../../toolbar/ObjectToolbarSelectedRows";
import { getTotalNumberOfFilters } from "../query-builder/domain";

interface IObjectToolbarProps {
  selectedRecords: IRecord[];
  setSelectedRecords: () => void;
  availableColumns: AvailableColumn[];
  object: IObject;
  tableQuery: TableQuery;
  activeView?: IObjectView;
  views: IObjectView[];
  isStale: boolean;
  setTableQuery: React.Dispatch<ISetTableQueryAction>;
  gridRef: React.RefObject<AgGridReact>;
  hasQueryBuilderOpened: boolean;
  setQueryBuilderOpened: (open) => void;
}

type Props = IObjectToolbarProps &
  RouteComponentProps &
  InjectedAntUtilsProps &
  ObjectUIStoreProps;

const getRecordKey = (object: IObject, key: string) => {
  return `${object.table.cubeName}.${key}`;
};

const getRecordIds = (records: IRecord[], object: IObject): string[] => {
  const labelKey = getRecordKey(object, "label");
  return records.map((sr) => {
    const { id } = parseLabel(sr[labelKey] as string);
    return id;
  });
};

function ObjectTableToolbar(props: Props) {
  const {
    activeView,
    availableColumns,
    object,
    tableQuery,
    isStale,
    setTableQuery,
    gridRef,
    selectedRecords,
    setSelectedRecords,
    match: { params },
    antUtils,
    objectUIStore,
    views,
    hasQueryBuilderOpened,
    setQueryBuilderOpened,
  } = props;

  const availableProperties = availableColumns
    .filter((c) => c.type === "property")
    .map((m) => m.data as AvailableProperty);

  // used to rerender the components when grouping changes
  // as refs property changes don't trigger refresh
  const [toggle, setToggle] = useState<boolean>(false);

  const refreshComponent = () => {
    setTimeout(() => {
      setToggle(!toggle);
    }, 200);
  };

  const renderInner = () => {
    if (selectedRecords.length) {
      const manualViews = views
        ? views.filter((v) => {
            if (v.viewType === IObjectViewType.MANUAL) {
              if (activeView && activeView?.id === v.id) {
                return false;
              }
              return true;
            }
            return false;
          })
        : [];
      return (
        <>
          <div style={{ flex: 1 }}>
            <Space size="middle">
              <ObjectToolbarSelectedRows
                selectedRecords={selectedRecords}
                setSelectedRecords={setSelectedRecords}
              />
              <Tooltip placement="top" title="Download">
                <Button
                  type="text"
                  size="small"
                  shape="circle"
                  icon={<DownloadOutlined />}
                  onClick={() => {
                    if (gridRef?.current?.api) {
                      gridRef?.current?.api.exportDataAsExcel({
                        onlySelected: true,
                      });
                    }
                  }}
                />
              </Tooltip>
              <Tooltip placement="top" title="Add to view">
                <Dropdown
                  trigger={["click"]}
                  menu={{
                    items: [
                      {
                        key: "new",
                        label: "Create new view",
                        onClick: () => {
                          const allIds = getRecordIds(selectedRecords, object);
                          if (allIds.length > MANUAL_LIST_LIMIT) {
                            antUtils.message.error(
                              `Can't have more than ${
                                MANUAL_LIST_LIMIT - 1
                              } records to a view`
                            );
                            return;
                            return;
                          }
                          objectUIStore.setCreateViewOpen({
                            title: "Save as",
                            type: IObjectViewType.MANUAL,
                            values: {
                              name: "",
                              query: JSON.stringify({
                                ...tableQuery,
                                filters: [
                                  {
                                    and: [
                                      {
                                        member: getRecordKey(object, "id"),
                                        operator: "equals",
                                        values: [
                                          ...getRecordIds(
                                            selectedRecords,
                                            object
                                          ),
                                          DUMMY_TOKEN,
                                        ],
                                      },
                                    ],
                                  },
                                ],
                              } as TableQuery),
                            },
                          });
                        },
                      },
                      {
                        key: "existing",
                        label: "Add to existing view",
                        disabled: manualViews.length === 0,
                        onClick: () => {
                          const allIds = getRecordIds(selectedRecords, object);

                          objectUIStore.setAddRecordsToView({
                            records: allIds,
                            views: manualViews.map((v) => v.id),
                          });
                        },
                      },
                    ],
                  }}
                >
                  <Button type="text" size="small" icon={<PlusOutlined />} />
                </Dropdown>
              </Tooltip>
              <Tooltip placement="top" title="Copy links">
                <Button
                  type="text"
                  size="small"
                  icon={<LinkOutlined />}
                  onClick={() => {
                    const allLinks = getRecordIds(selectedRecords, object)
                      .map((id) => {
                        return `${
                          window.WHALY_CONSTANTS.APP_URL
                        }${routeDescriptor["object-record"].renderRoute({
                          ...params,
                          recordId: id,
                        })}`;
                      })
                      .join("\n");
                    copy(allLinks);
                    antUtils.message.success("Links successfully copied", 2);
                  }}
                />
              </Tooltip>
              {activeView && activeView.viewType === IObjectViewType.MANUAL && (
                <Tooltip placement="top" title="Delete">
                  <Button
                    type="text"
                    size="small"
                    danger
                    icon={<DeleteOutlined />}
                    onClick={() => {
                      const deletedRecordIds = getRecordIds(
                        selectedRecords,
                        object
                      );
                      const newTableQuery = { ...tableQuery };
                      const values = _.get(
                        newTableQuery,
                        tableQueryManualFilterKey
                      );
                      _.set(
                        newTableQuery,
                        tableQueryManualFilterKey,
                        values.filter((v) => !deletedRecordIds.includes(v))
                      );
                      objectUIStore.setUpdateViewOpen({
                        id: activeView.id,
                        query: JSON.stringify(newTableQuery as TableQuery),
                        title: "Remove records from view",
                        content: `You are about to remove ${selectedRecords.length} records from your view, this can't be undone. Do you want to proceed?`,
                      });
                    }}
                  />
                </Tooltip>
              )}
            </Space>
          </div>
        </>
      );
    }

    const hasQueryBuilder = (object.queryBuilderSections ?? []).length > 0;
    const queryBuilderButton = () => {
      if (!activeView || activeView.viewType === IObjectViewType.QUERY) {
        const numberOfAppliedFilters = getTotalNumberOfFilters(
          tableQuery,
          object
        );
        return (
          <Button
            style={
              hasQueryBuilderOpened
                ? { background: "#DBE0FD", color: "#3f6ac4" }
                : undefined
            }
            onClick={() => {
              hasQueryBuilderOpened
                ? setQueryBuilderOpened(false)
                : setQueryBuilderOpened(true);
            }}
            type="text"
            size="small"
            icon={<FilterOutlined />}
          >
            Query builder
            {getTotalNumberOfFilters(tableQuery, object) > 0
              ? `: ${numberOfAppliedFilters}`
              : ""}
          </Button>
        );
      } else {
        return (
          <Tooltip title="Can't use the query builder in manual views">
            <Button disabled type="text" size="small" icon={<FilterOutlined />}>
              Query builder
            </Button>
          </Tooltip>
        );
      }
    };

    const renderFilterButton = () => {
      if (activeView && activeView.viewType === IObjectViewType.MANUAL) {
        return (
          <Tooltip placement="top" title="Manual views can't be filtered">
            <Button size="small" type="text" icon={<FilterOutlined />} disabled>
              Filters
            </Button>
          </Tooltip>
        );
      }

      return (
        <ObjectToolbarFilter
          presetValue={tableQuery.presetFilters ?? []}
          onPresetChange={(v) => {
            setTableQuery({ action: "setPresetFilters", presetIds: v });
            refreshComponent();
          }}
          availableDimensions={getAvailableDimensions(availableProperties, {
            type: "sortAndFilter",
          })}
          object={object}
          value={tableQuery.filters}
          onChange={(v) => {
            setTableQuery({ action: "setFilters", filters: v });
            refreshComponent();
          }}
        />
      );
    };

    return (
      <>
        <div style={{ flex: 1 }}>
          <Space size="middle">
            {hasQueryBuilder ? queryBuilderButton() : null}
            {hasQueryBuilder ? (
              <div
                style={{
                  height: 24,
                  width: 1,
                  backgroundColor: BORDER_COLOR,
                }}
              />
            ) : null}
            <ObjectToolbarFields
              availableColumns={availableColumns.filter((p) => {
                if (p.type === "property" && p.data.type === "primaryKey") {
                  return false;
                }
                return true;
              })}
              value={availableColumns
                .filter((p) => {
                  if (p.type === "property" && p.data.type === "primaryKey") {
                    return false;
                  }
                  return true;
                })
                .map((c) => ({
                  key: c.data.key,
                  visible:
                    tableQuery.columnVisibility?.visibleColumnIds?.includes?.(
                      c.data.key
                    )
                      ? true
                      : false,
                }))
                .sort((a, b) => {
                  const indexA =
                    tableQuery.columnOrder?.orderedColIds.indexOf(a.key) || 0;
                  const indexB =
                    tableQuery.columnOrder?.orderedColIds.indexOf(b.key) || 0;

                  if (indexA < indexB) return -1;
                  if (indexA > indexB) return 1;
                  return 0;
                })}
              onChange={(value) => {
                setTableQuery({
                  action: "columnVisibilityAndOrder",
                  columnOrder: {
                    orderedColIds: value.map((v) => v.key),
                  },
                  columnVisibility: {
                    visibleColumnIds: value
                      .filter((v) => v.visible === true)
                      .map((v) => v.key),
                  },
                });
                setTableQuery({
                  action: "columnVisibility",
                  columnVisibility: {
                    visibleColumnIds: value
                      .filter((v) => v.visible === true)
                      .map((v) => v.key),
                  },
                });
                refreshComponent();
              }}
            />
            {!hasQueryBuilder ? renderFilterButton() : null}
            <ObjectToolbarSort
              columns={availableColumns}
              value={
                tableQuery.order && Array.isArray(tableQuery.order)
                  ? tableQuery.order
                  : []
              }
              onChange={(e) => {
                setTableQuery({ action: "setOrder", order: e });
                refreshComponent();
              }}
            />
            <ObjectToolbarGroup
              availableDimensions={getAvailableDimensions(
                availableProperties.filter((p) => p.type !== "primaryKey"),
                {
                  type: "display",
                }
              )}
              value={tableQuery.groups.map((g) => ({
                id: g.groupedDimension,
                agg: getTimePartFromTimeDimension(g.groupedField) || undefined,
              }))}
              onChange={(currentValues) => {
                setTableQuery({
                  action: "setGroups",
                  groups: currentValues.map((cv) => {
                    if (cv.agg) {
                      return {
                        groupedDimension: getDimensionPartFromTimeDimension(
                          cv.id
                        ),
                        groupedField: cv.id + "." + cv.agg,
                      };
                    } else {
                      return {
                        groupedDimension: cv.id,
                        groupedField: cv.id,
                      };
                    }
                  }),
                });
              }}
            />
          </Space>
        </div>
        <div style={{ flex: 0 }}>
          <SaveButton
            object={object}
            activeView={activeView}
            query={JSON.stringify(tableQuery)}
            isStale={isStale}
            onReset={() => setTableQuery({ action: "reset" })}
            gridRef={gridRef}
          />
        </div>
      </>
    );
  };

  return <div style={{ display: "flex" }}>{renderInner()}</div>;
}

export default compose<Props, IObjectToolbarProps>(
  inject("objectUIStore"),
  withRouter,
  withAntUtils
)(ObjectTableToolbar);
