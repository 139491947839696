import type { IObjectLayoutType } from "../../interfaces/object";
import type {
  IColumn,
  ILayout,
} from "../v2-demo/container/record/component/domain";
import { isFakeId } from "../workbench/workbench/exploration/domain";

export interface IBehaviorState {
  hover: {
    component: string | null;
    setComponent: (c: string) => void;
  };
  selected: {
    component: string | null;
    setComponent: (c: string) => void;
  };
}

interface IObjectLayoutUpdatePayload {
  id?: string;
  type: IObjectLayoutType;
  header: string;
  rows: Array<IObjectLayoutRowPayload>;
  widgets: Array<IObjectLayoutWidgetPayload>;
}

type IObjectLayoutRowPayload =
  | IObjectLayoutRowCreatePayload
  | IObjectLayoutRowUpdatePayload;

interface IObjectLayoutRowUpdatePayload extends IObjectLayoutBasePayload {
  id: string;
  name?: string;
  description?: string;
  displayFilters?: string;
  cols: Array<IObjectLayoutColPayload>;
}

interface IObjectLayoutRowCreatePayload extends IObjectLayoutBasePayload {
  tempId: string;
  name?: string;
  description?: string;
  displayFilters?: string;
  cols: Array<IObjectLayoutColPayload>;
}

type IObjectLayoutColPayload =
  | IObjectLayoutColCreatePayload
  | IObjectLayoutColUpdatePayload;

interface IObjectLayoutColCreatePayload extends IObjectLayoutBasePayload {
  tempId: string;
}

interface IObjectLayoutColUpdatePayload extends IObjectLayoutBasePayload {
  id: string;
}

type IObjectLayoutWidgetPayload =
  | IObjectLayoutWidgetCreatePayload
  | IObjectLayoutWidgetUpdatePayload;

interface IObjectLayoutWidgetUpdatePayload extends IObjectLayoutBasePayload {
  id: string;
  type: string;
  parentColumn: string;
  displayFilters?: string;
}

interface IObjectLayoutWidgetCreatePayload extends IObjectLayoutBasePayload {
  tempId: string;
  type: string;
  parentColumn: string;
  displayFilters?: string;
}

interface IObjectLayoutBasePayload {
  position: number;
  name?: string;
  conf?: string;
}

export const convertLayoutToPayload = (
  layout: ILayout
): IObjectLayoutUpdatePayload => {
  const remainingColIds = layout.rows
    .flatMap((r) => r.columns)
    .map((c) => c.id);
  const filteredWidgets = layout.widgets.filter(
    (w) =>
      w.parentColumn &&
      w.parentColumn.id &&
      !!remainingColIds.includes(w.parentColumn.id)
  );

  return {
    id: isFakeId(layout.id) ? undefined : layout.id,
    header: JSON.stringify(layout.header),
    type: layout.type,
    rows: layout.rows.map((r) => {
      const generateColumns = (a: IColumn[]): IObjectLayoutColPayload[] =>
        a.map((c) => {
          if (isFakeId(c.id)) {
            const createColPayload: IObjectLayoutColCreatePayload = {
              tempId: c.id,
              name: undefined,
              position: c.position,
              conf: JSON.stringify({ size: c.size }),
            };
            return createColPayload;
          }
          const updateColPayload: IObjectLayoutColUpdatePayload = {
            id: c.id,
            name: undefined,
            position: c.position,
            conf: JSON.stringify({ size: c.size }),
          };
          return updateColPayload;
        });

      if (isFakeId(r.id)) {
        const createRowPayload: IObjectLayoutRowCreatePayload = {
          tempId: r.id,
          name: r.name,
          description: r.description,
          displayFilters: r.displayFilters
            ? JSON.stringify(r.displayFilters)
            : undefined,
          position: r.position,
          conf: "",
          cols: generateColumns(r.columns),
        };
        return createRowPayload;
      }
      const updateRowPayload: IObjectLayoutRowUpdatePayload = {
        id: r.id,
        name: r.name,
        description: r.description,
        displayFilters: r.displayFilters
          ? JSON.stringify(r.displayFilters)
          : undefined,
        position: r.position,
        conf: "",
        cols: generateColumns(r.columns),
      };
      return updateRowPayload;
    }),
    widgets: filteredWidgets.map((w) => {
      if (isFakeId(w.id)) {
        const createWidget: IObjectLayoutWidgetCreatePayload = {
          tempId: w.id,
          name: w.name,
          displayFilters: w.displayFilters
            ? JSON.stringify(w.displayFilters)
            : undefined,
          type: w.type,
          position: w.position,
          parentColumn: w.parentColumn?.id!,
          conf: w.config,
        };
        return createWidget;
      }
      const updateWidget: IObjectLayoutWidgetUpdatePayload = {
        id: w.id,
        name: w.name,
        displayFilters: w.displayFilters
          ? JSON.stringify(w.displayFilters)
          : undefined,
        type: w.type,
        position: w.position,
        parentColumn: w.parentColumn?.id!,
        conf: w.config,
      };
      return updateWidget;
    }),
  };
};
