import * as React from "react";
import { Panel } from "./Panel";

interface SplitViewProps {
  left?: React.ReactChild;
  leftClassName?: string;
  right?: React.ReactChild;
  rightClassName?: string;
  minWidth: number;
  startWidth: number | string;
  maxWidth?: number | string;
  mainClassName?: string;
  alignement: "LTR" | "RTL";
  compact?: boolean;
  collapsedWidth?: number;
  collapsed?: boolean;
}

export const SplitView: React.FunctionComponent<SplitViewProps> = ({
  left,
  right,
  minWidth,
  leftClassName,
  rightClassName,
  mainClassName,
  alignement,
  maxWidth,
  startWidth,
  compact,
  collapsed,
  collapsedWidth,
}) => {
  const [panelWidth, setPanelWidth] = React.useState<undefined | number>();
  const [cachedMaxWidth, setCachedMaxWidth] = React.useState<number>();
  const [cachedFullWidth, setCachedFullWidth] = React.useState<number>();
  const [separatorXPosition, setSeparatorXPosition] = React.useState<
    undefined | number
  >(undefined);
  const [dragging, setDragging] = React.useState(false);
  const splitPaneRef = React.createRef<HTMLDivElement>();

  const generateMaxWidth = (w: any) => {
    if (typeof w === "string" && w.endsWith("%") && splitPaneRef.current) {
      const splitPaneWidth = splitPaneRef.current.clientWidth;
      return splitPaneWidth * (parseInt(w.replace("%", "")) / 100);
    } else if (typeof w === "number") {
      return w;
    }
  };

  const onMouseDown = (e: React.MouseEvent) => {
    setSeparatorXPosition(e.clientX);
    setDragging(true);
  };

  const onMouseMove = (e: React.MouseEvent) => {
    if (dragging) e.preventDefault();
    onMove(e.clientX);
  };

  React.useEffect(() => {
    document.addEventListener("mousemove", onMouseMove as any);
    document.addEventListener("touchmove", onTouchMove);
    document.addEventListener("mouseup", onMouseUp);
    return () => {
      document.removeEventListener("mousemove", onMouseMove as any);
      document.removeEventListener("touchmove", onTouchMove);
      document.removeEventListener("mouseup", onMouseUp);
    };
  });

  React.useEffect(() => {
    if (collapsed) {
      setPanelWidth(collapsedWidth);
    }
    if (!collapsed) {
      setPanelWidth(generateMaxWidth(startWidth));
    }
  }, [collapsed]);

  React.useEffect(() => {
    const computedMaxWidth = generateMaxWidth(maxWidth);
    if (computedMaxWidth !== cachedMaxWidth) {
      setCachedMaxWidth(computedMaxWidth);
    }

    const computedFullWidth = generateMaxWidth("100%");
    if (computedFullWidth !== cachedFullWidth) {
      setCachedFullWidth(computedFullWidth);
    }

    const computedStartWidth = generateMaxWidth(startWidth);

    if (collapsed && typeof collapsedWidth === "number" && !panelWidth) {
      setPanelWidth(collapsedWidth);
    } else if (startWidth && !panelWidth) {
      setPanelWidth(computedStartWidth);
    }
  }, [
    splitPaneRef,
    maxWidth,
    setCachedMaxWidth,
    startWidth,
    panelWidth,
    setPanelWidth,
  ]);

  const onMouseUp = () => {
    setDragging(false);
  };

  const onTouchStart = (e: React.TouchEvent) => {
    setSeparatorXPosition(e.touches[0].clientX);
    setDragging(true);
  };

  const onTouchMove = (e: TouchEvent) => {
    onMove(e.touches[0].clientX);
  };

  const onMove = (clientX: number) => {
    if (dragging && panelWidth && separatorXPosition) {
      const newPanelWidth =
        alignement === "LTR"
          ? panelWidth + clientX - separatorXPosition
          : panelWidth - clientX + separatorXPosition;
      setSeparatorXPosition(clientX);

      if (newPanelWidth < minWidth) {
        setPanelWidth(minWidth);
        return;
      }

      if (cachedMaxWidth && newPanelWidth > cachedMaxWidth) {
        setPanelWidth(cachedMaxWidth);
        return;
      }

      if (splitPaneRef.current) {
        const splitPaneWidth = splitPaneRef.current.clientWidth;

        if (newPanelWidth > splitPaneWidth - minWidth) {
          setPanelWidth(splitPaneWidth - minWidth);
          return;
        }
      }
      window.dispatchEvent(new Event("resize"));
      setPanelWidth(newPanelWidth);
    }
  };

  const getDivider = () => {
    if (collapsed) return <></>;
    if (compact) {
      return (
        <div className="divider-compact">
          <div
            className="divider-compact-handle"
            onMouseDown={onMouseDown}
            onTouchStart={onTouchStart}
            onTouchEnd={onMouseUp}
          />
        </div>
      );
    }
    return (
      <div
        className="divider"
        onMouseDown={onMouseDown}
        onTouchStart={onTouchStart}
        onTouchEnd={onMouseUp}
      />
    );
  };

  return alignement === "LTR" ? (
    <div ref={splitPaneRef} className={mainClassName}>
      {left && (
        <Panel
          className={leftClassName}
          width={right ? panelWidth : "100%"}
          setWidth={setPanelWidth}
        >
          {left}
        </Panel>
      )}
      {left && right && getDivider()}
      {right && <div className={rightClassName}>{right}</div>}
    </div>
  ) : (
    <div ref={splitPaneRef} className={mainClassName}>
      {left && <div className={leftClassName}>{left}</div>}
      {left && right && getDivider()}
      {right && (
        <Panel
          className={rightClassName}
          width={left ? panelWidth : "100%"}
          setWidth={setPanelWidth}
        >
          {right}
        </Panel>
      )}
    </div>
  );
};
