import * as React from "react";
import type { RouteComponentProps } from "react-router";
import { withRouter } from "react-router";
import { compose } from "../../../../../components/compose/WlyCompose";
import type { DeepPartial } from "../../../../../helpers/typescriptHelpers";
import type { IDestination } from "../../../../../interfaces/destinations";
import type { IDataset, ISource } from "../../../../../interfaces/sources";
import type { IView } from "../../../../../interfaces/view";
import type { InjectedOrgProps } from "../../../../orgs/WithOrg";
import WithOrg from "../../../../orgs/WithOrg";
import type { TableTabItem } from "../../../../spreadsheet/domain";
import FlowMigrationModal from "../../dataset/tabs/flow/FlowMigrationModal";
import type {
  FetchedDestination,
  IActiveObject,
  IActiveObjectDatasetUrlState,
  IDatasetLineageGraph,
  ModifyQuery,
  TabData,
  TableData,
} from "../../domain";
import Model from "../../model/Model";
import type { DatasetSavedData } from "../../selector/tables/models/AddModelModal";
import NoAccess from "../empty/NoAccess";

interface IDataViewerProps {
  updateActiveObject: (newActiveObject: IActiveObject) => void;
  activeObject: IActiveObject;

  store: {
    [tableKey: string]: TabData;
  };
  datasets: IDataset[];
  sources: ISource[];
  destination: FetchedDestination;
  onFullscreenChange: (isFullscreen: boolean) => void;
  onCreateView?: (datasetId: string, viewName: string) => Promise<any>;
  onRenameView?: (viewId: string, viewName: string) => Promise<any>;
  onDeleteView?: (viewId: string, nextViewSlug: string) => Promise<any>;
  onCreateRelationship?: (relationshipQuery: any) => Promise<any>;
  onUpdateRelationship?: (id: string, relationshipQuery: any) => Promise<any>;
  onDeleteRelationship?: (id: string) => Promise<any>;
  onCreateDataset?: (datasetData: DatasetSavedData) => Promise<any>;
  onDeleteDataset?: (datasetId: string) => Promise<any>;
  onUpdateDataset?: (
    datasetId: string,
    a: {
      name?: string;
      description?: string;
      sql?: string;
      primaryKey?: string;
    }
  ) => Promise<any>;
  onUpdateDrills?: (viewId: string, drills: string[]) => Promise<any>;
  onCreateSource?: (sourceName: string, sourceImage: string) => Promise<any>;
  onUpdateSource?: (
    sourceId: string,
    data: DeepPartial<ISource>
  ) => Promise<void>;
  routeRender: (params: object, query?: object) => string;
  mainTabs: TableTabItem[];
  fetchData: (
    warehouseId: string,
    activeDatasetId: string,
    activeViewId: string,
    increment?: boolean,
    fullReload?: boolean
  ) => Promise<void>;
  resetDatasetCache: (datasetId: string) => void;
  onModifyTransformation: (
    activeDataset: string,
    activeView: string
  ) => ModifyQuery;
  datasetLineageGraph: IDatasetLineageGraph;
  onRefreshDatasets: (id?: string[]) => Promise<void>;
  onRefreshExplorations: (id?: string[]) => Promise<void>;
  currentWarehouse: IDestination;
}

type DatasetView =
  | { datasetId: string; selectedView: IView | undefined }
  | undefined;

interface IState {
  scrollToColumn?: number;
  showFlowMigration: boolean;
}

type Props = IDataViewerProps &
  InjectedOrgProps &
  RouteComponentProps<{ dependencySlug?: string }>;

class DataViewer extends React.Component<Props, IState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      showFlowMigration: false,
    };
  }

  componentDidMount() {
    const { activeObject, store, currentWarehouse, fetchData } = this.props;
    const { urlState } = activeObject;
    const datasetExists = this.datasetViewExists(
      activeObject.value,
      (urlState as IActiveObjectDatasetUrlState)?.view
    );
    if (
      activeObject &&
      activeObject.type === "dataset" &&
      store[activeObject.value] &&
      !store[activeObject.value].data[datasetExists.selectedView.id].store.cache
    ) {
      fetchData(
        currentWarehouse.id,
        store[datasetExists.datasetId].id,
        datasetExists.selectedView.id
      );
    }
  }

  componentDidUpdate(prevProps: Props) {
    const { org, currentWarehouse, fetchData, activeObject, store } =
      this.props;
    const { activeObject: prevActiveObject } = prevProps;

    const { urlState } = activeObject;
    const { urlState: prevUrlState } = prevActiveObject;

    if (
      activeObject.type !== prevActiveObject.type ||
      activeObject.value !== prevActiveObject.value ||
      (urlState as IActiveObjectDatasetUrlState)?.view !==
        (prevUrlState as IActiveObjectDatasetUrlState)?.view
    ) {
      const datasetExists = this.datasetViewExists(
        activeObject.value,
        (urlState as IActiveObjectDatasetUrlState)?.view
      );
      if (
        datasetExists &&
        datasetExists.selectedView &&
        store[datasetExists.datasetId] &&
        !store[datasetExists.datasetId].data[datasetExists.selectedView.id]
          .store.cache
      ) {
        this.refreshCurrentView(
          currentWarehouse.id,
          store[datasetExists.datasetId].id,
          datasetExists.selectedView.id
        );
      }
    }
  }

  refreshCurrentView = (
    warehouseId: string,
    datasetId: string,
    viewId: string
  ) => {
    const { fetchData } = this.props;
    fetchData(warehouseId, datasetId, viewId);
  };

  datasetViewExists = (
    datasetId: string,
    currentViewId?: string
  ): DatasetView => {
    const { store } = this.props;
    let datasetExists = store[datasetId];
    if (datasetExists) {
      const viewId = currentViewId
        ? currentViewId
        : store[datasetId].views.filter((v) => v.default)[0].id;
      return {
        datasetId: store[datasetId].id,
        selectedView: store[datasetId].views.find((v) => v.id === viewId),
      };
    }
    return;
  };

  getActiveDependency = (id?: string) => {
    const { store } = this.props;
    return Object.keys(store).find((k) => store[k].slug === id);
  };

  getActiveView = (tableId: string, id?: string) => {
    const { store } = this.props;
    if (id) {
      return store[tableId]?.views?.find((v) => v.id === id);
    } else {
      return store[tableId]?.views?.find((v) => v.default === true);
    }
  };

  public render() {
    const {
      store,
      onCreateRelationship,
      onDeleteDataset,
      onUpdateRelationship,
      onDeleteRelationship,
      onUpdateDataset,
      onCreateDataset,
      onCreateView,
      datasets,
      mainTabs,
      routeRender,
      resetDatasetCache,
      fetchData,
      onModifyTransformation,
      onUpdateDrills,
      onRenameView,
      onDeleteView,
      activeObject,
      sources,
      updateActiveObject,
      onFullscreenChange,
      destination,
      datasetLineageGraph,
      onRefreshDatasets,
      onRefreshExplorations,
      currentWarehouse,
    } = this.props;

    const { urlState } = activeObject;

    const { showFlowMigration } = this.state;

    const activeTable = store[activeObject.value]
      ? activeObject.value
      : undefined;

    if (!activeTable) {
      return <NoAccess />;
    }

    const activeView = this.getActiveView(
      activeTable,
      (urlState as IActiveObjectDatasetUrlState)?.view
    )
      ? this.getActiveView(
          activeTable,
          (urlState as IActiveObjectDatasetUrlState)?.view
        )!.id
      : undefined;

    const activeTableData = store[activeTable];

    const activeModelViewKey = activeView
      ? activeView
      : store[activeTable].views.filter((v) => v.default)[0].id;

    const activeModel = store[activeTable];

    const mashupLeftData =
      activeModel &&
      activeModel.data[activeModelViewKey] &&
      activeModel.data[activeModelViewKey].store.cache
        ? activeModel.data[activeModelViewKey].store.cache!
        : ({
            rowCount: undefined,
            list: undefined,
            schema: undefined,
          } as TableData);

    const isRawQueryLoading =
      activeModel.data[activeModelViewKey].currentStack.status === "loading";

    const leftLoading = {
      tables: false,
      count:
        mashupLeftData.rowCount === undefined ||
        (mashupLeftData.rowCount === 0 &&
          activeModel.data[activeModelViewKey].store.raw.status === "loading"),
      records: mashupLeftData.list === undefined || isRawQueryLoading,
      schema: mashupLeftData.schema === undefined || isRawQueryLoading,
    };

    const leftMashupErrorData =
      activeModel &&
      activeModel.data[activeModelViewKey].store.raw.status === "error"
        ? (activeModel.data[activeModelViewKey].store.raw as any).error
        : undefined;

    const managedBy = store[activeTable].dataset.managedBy;

    return (
      <>
        <Model
          onFullscreenChange={onFullscreenChange}
          activeObject={activeObject}
          destination={destination}
          currentWarehouse={currentWarehouse}
          onRefreshDatasets={onRefreshDatasets}
          left={{
            tabData: store[activeTable],
            onCreateRelationship: onCreateRelationship,
            onUpdateRelationship: onUpdateRelationship,
            onDeleteRelationship: onDeleteRelationship,
            onDeleteDataset:
              (store[activeTable].type === "SQL" ||
                store[activeTable].type === "FLOW") &&
              managedBy !== "DBT_CLOUD" &&
              onDeleteDataset
                ? () =>
                    onDeleteDataset(activeTable).then(() =>
                      updateActiveObject({ ...activeObject, stale: false })
                    )
                : undefined,
            onUpdateDataset:
              (store[activeTable].type === "SQL" ||
                store[activeTable].type === "FLOW") &&
              managedBy !== "DBT_CLOUD" &&
              onUpdateDataset
                ? (a) =>
                    onUpdateDataset(activeTable, a).then((r) => {
                      resetDatasetCache(activeTable);
                      updateActiveObject({ ...activeObject, stale: false });
                    })
                : (a) => onUpdateDataset(activeTable, a),
            scollToColumnIndex: this.state.scrollToColumn,
            mashupData: mashupLeftData,
            onRefresh: (activeKey: string, avK: string) =>
              fetchData(currentWarehouse.id, activeKey, avK, false, true),
            onModifyTransformation:
              managedBy !== "DBT_CLOUD" &&
              onModifyTransformation(activeTable, activeModelViewKey),
            activeTableKey: activeTable,
            activeViewKey: activeModelViewKey,
            spreadsheetLoading: leftLoading,
            spreadsheetErrorMessage: leftMashupErrorData
              ? leftMashupErrorData!.message
              : undefined,
            datasetQueryCursor: store[activeTable].currentDatasetResolverCursor,
            viewQueryCursor:
              store[activeTable].data[activeModelViewKey]
                .currentViewResolverCursor,
            transformations:
              store[activeTable].data[activeModelViewKey].currentStack,
            onCreateView: onCreateView
              ? (n) => onCreateView(activeTable, n)
              : undefined,
            onRenameView: onRenameView,
            onDeleteView: onDeleteView
              ? (viewId) => onDeleteView(viewId, activeTableData.views[0].slug)
              : undefined,
            onDrillUpdate: onUpdateDrills,
            datasetLineageGraph: datasetLineageGraph,
          }}
          sources={sources}
          datasets={datasets}
          tables={mainTabs}
          activeModelKey={activeTable}
          routeRender={routeRender}
          onShowFlowMigration={() => this.setState({ showFlowMigration: true })}
          onCreateModel={(d) =>
            onCreateDataset
              ? onCreateDataset(d).then(() =>
                  updateActiveObject({ ...activeObject, stale: false })
                )
              : undefined
          }
          onRefreshExplorations={onRefreshExplorations}
        />
        {onCreateDataset &&
          onCreateRelationship &&
          this.getActiveView(
            activeTable,
            (urlState as IActiveObjectDatasetUrlState)?.view
          ) && (
            <FlowMigrationModal
              visible={showFlowMigration}
              fromViewId={
                this.getActiveView(
                  activeTable,
                  (urlState as IActiveObjectDatasetUrlState)?.view
                ).id
              }
              currentWarehouse={currentWarehouse}
              onClose={() => this.setState({ showFlowMigration: false })}
              onUpdateDataset={onUpdateDataset}
              routeRender={routeRender}
            />
          )}
      </>
    );
  }
}

export default compose<Props, IDataViewerProps>(
  WithOrg,
  withRouter
)(DataViewer);
