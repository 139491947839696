import { CheckCircleTwoTone, SyncOutlined } from "@ant-design/icons";
import { Button } from "antd";
import moment from "moment";
import * as React from "react";
import type { JobSyncStatus } from "../../../../interfaces/jobExecutions";
import type { ISource, SourceStatus } from "../../../../interfaces/sources";
import GraphQLService from "../../../../services/graphql/GraphQLService";
import "./SourceStatusRenderer.scss";

interface ISourceStatusRendererProps {
  source: ISource;
}

const QUERY = `query fetchSource($sourceId: ID!) {
  Source(where: {id: $sourceId}) {
    status
    syncStatus
    nextSyncDate
    lastSyncDate
  }
}`;

const SYNC_NOW_QUERY = `mutation SyncNowSource($sourceId:ID!) {
  syncNowSource(sourceId: $sourceId)
}`;

const LAST_JOB_EXEC_QUERY = `
query GetCurrentJobExecutionID($sourceId: ID!) {
  Source(where: { id: $sourceId }) {
    lastJobExecution {
      id
      status
    }
  }
}
`;

const CANCEL_SYNC = `
mutation StopCurrentSync($sourceId: ID!) {
  updateSource(id: $sourceId, data: {
    syncStatus: idle
  }) {
    id
  }
}
`;

const CANCEL_SYNC_WITH_JOB_EXECUTION = `
mutation StopCurrentSync($sourceId: ID!, $jobExecutionId: ID!) {
  updateSource(id: $sourceId, data: {
    syncStatus: idle
  }) {
    id
  }
  
  updateJobExecution(id: $jobExecutionId, data: {
    status: FAILED
  }) {
    id
  }
}
`;

interface IState {
  status: SourceStatus;
  syncStatus: JobSyncStatus;
  lastSyncDate?: moment.Moment;
  nextSyncDate?: moment.Moment;
}

export default class SourceStatusRenderer extends React.Component<
  ISourceStatusRendererProps,
  IState
> {
  interval: NodeJS.Timeout;

  constructor(props: ISourceStatusRendererProps) {
    super(props);
    this.state = {
      status: props.source.status,
      syncStatus: props.source.syncStatus,
      lastSyncDate: props.source.lastSyncDate
        ? moment(props.source.lastSyncDate)
        : undefined,
      nextSyncDate: props.source.nextSyncDate
        ? moment(props.source.nextSyncDate)
        : undefined,
    };
  }

  componentDidMount() {
    this.interval = setInterval(this.fetchStatus, 1000);
  }

  componentWillUnmount() {
    window.clearTimeout(this.interval);
  }

  public fetchStatus = async () => {
    const { source } = this.props;
    const statusRes = await GraphQLService<{
      Source: {
        status: SourceStatus;
        syncStatus: JobSyncStatus;
        nextSyncDate?: string;
        lastSyncDate?: string;
      };
    }>(QUERY, {
      sourceId: source.id,
    });

    const currStatus = statusRes.Source.status;
    const currSyncStatus = statusRes.Source.syncStatus;
    const lastSyncDate = statusRes.Source.lastSyncDate
      ? moment(statusRes.Source.lastSyncDate)
      : undefined;
    const nextSyncDate = statusRes.Source.nextSyncDate
      ? moment(statusRes.Source.nextSyncDate)
      : undefined;

    this.setState({
      ...this.state,
      status: currStatus,
      syncStatus: currSyncStatus,
      lastSyncDate,
      nextSyncDate,
    });
  };

  syncNow = async () => {
    const { source } = this.props;

    const syncNowRes = await GraphQLService<{ syncNowSource: boolean }>(
      SYNC_NOW_QUERY,
      {
        sourceId: source.id,
      }
    );

    if (syncNowRes.syncNowSource === true) {
      // This one is to make sure that the UI is responsive, so we're optimistic
      // The next interval refresh will make it synced with the backend
      this.setState({
        ...this.state,
        syncStatus: "scheduled",
      });
    }
  };

  cancelCurrentSync = async () => {
    const {
      source: { id: sourceId },
    } = this.props;

    const currentJobExecutionIdRes = await GraphQLService<{
      Source: {
        lastJobExecution: {
          id: string;
          status: string;
        };
      };
    }>(LAST_JOB_EXEC_QUERY, {
      sourceId,
    });

    const jobExecutionStatus =
      currentJobExecutionIdRes.Source.lastJobExecution.status;
    const jobExecutionId = currentJobExecutionIdRes.Source.lastJobExecution.id;

    if (jobExecutionStatus === "RUNNING") {
      await GraphQLService<{
        updateSource: {
          id: string;
        };
        updateJobExecution: {
          id: string;
        };
      }>(CANCEL_SYNC_WITH_JOB_EXECUTION, {
        sourceId,
        jobExecutionId,
      });
    } else {
      await GraphQLService<{
        updateSource: {
          id: string;
        };
      }>(CANCEL_SYNC, {
        sourceId,
      });
    }
  };

  getSyncButton = (): JSX.Element => {
    const { syncStatus } = this.state;
    if (syncStatus === "idle") {
      return (
        <Button type="primary" icon={<SyncOutlined />} onClick={this.syncNow}>
          Sync Now
        </Button>
      );
    } else if (syncStatus === "scheduled" || syncStatus === "syncing") {
      return (
        <>
          <Button type="primary" icon={<SyncOutlined />} loading>
            Syncing
          </Button>
          <Button danger type="text" onClick={this.cancelCurrentSync}>
            Cancel
          </Button>
        </>
      );
    }
  };

  public render() {
    const { status, syncStatus, lastSyncDate, nextSyncDate } = this.state;

    const now = moment();

    let timeSinceLastSyncText = (
      <p>
        📆 Last Sync was done <b>{moment(lastSyncDate).fromNow()}.</b>
      </p>
    );

    let timeToNextSyncText: JSX.Element;
    if (nextSyncDate) {
      const minutesToNextSync = nextSyncDate.diff(now, "minute");
      if (minutesToNextSync >= 60) {
        timeToNextSyncText = (
          <p>
            ⏭ Next Sync will run in{" "}
            <b>{nextSyncDate.diff(now, "hour")} hours.</b>
          </p>
        );
      } else if (minutesToNextSync <= 5) {
        timeToNextSyncText = (
          <p>
            ⏭ Next Sync will run in <b>less than 10 minutes!</b>
          </p>
        );
      } else {
        timeToNextSyncText = (
          <p>
            ⏭ Next Sync will run in <b>{minutesToNextSync} minutes.</b>
          </p>
        );
      }
    }

    return (
      <div className="source-status-renderer">
        <div className="main">
          <div className="status-items">
            {(status === "initial" ||
              status === "discovery" ||
              status === "running") && (
              <div>
                <CheckCircleTwoTone /> Authentication
              </div>
            )}
            {(status === "initial" || status === "running") && (
              <div>
                <CheckCircleTwoTone /> Initial Load
              </div>
            )}
            {status === "running" && (
              <div>
                <CheckCircleTwoTone /> Run
              </div>
            )}
            {status !== "running" && <div>...</div>}
          </div>
          {status === "running" && <div>{this.getSyncButton()}</div>}
        </div>
        {status === "running" && syncStatus === "idle" && (
          <div>
            <br />
            <br />
          </div>
        )}
        {status === "running" && syncStatus === "idle" && lastSyncDate && (
          <div>{timeSinceLastSyncText}</div>
        )}
        {status === "running" && syncStatus === "idle" && nextSyncDate && (
          <div>{timeToNextSyncText}</div>
        )}
      </div>
    );
  }
}
