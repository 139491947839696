import Icon from "@ant-design/icons";
import type { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";

export const Wly4ColumnIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon
    component={() => (
      <svg
        width="1em"
        height="1em"
        viewBox="0 0 213 213"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <mask id="path-1-inside-1_1648_347" fill="white">
          <rect x="6" y="43" width="46" height="127" rx="8" />
        </mask>
        <rect
          x="6"
          y="43"
          width="46"
          height="127"
          rx="8"
          stroke="#5D6671"
          strokeWidth="16"
          strokeLinejoin="round"
          mask="url(#path-1-inside-1_1648_347)"
        />
        <mask id="path-2-inside-2_1648_347" fill="white">
          <rect x="57" y="43" width="47" height="127" rx="8" />
        </mask>
        <rect
          x="57"
          y="43"
          width="47"
          height="127"
          rx="8"
          stroke="#5D6671"
          strokeWidth="16"
          strokeLinejoin="round"
          mask="url(#path-2-inside-2_1648_347)"
        />
        <mask id="path-3-inside-3_1648_347" fill="white">
          <rect x="109" y="43" width="46" height="127" rx="8" />
        </mask>
        <rect
          x="109"
          y="43"
          width="46"
          height="127"
          rx="8"
          stroke="#5D6671"
          strokeWidth="16"
          strokeLinejoin="round"
          mask="url(#path-3-inside-3_1648_347)"
        />
        <mask id="path-4-inside-4_1648_347" fill="white">
          <rect x="160" y="43" width="46" height="127" rx="8" />
        </mask>
        <rect
          x="160"
          y="43"
          width="46"
          height="127"
          rx="8"
          stroke="#5D6671"
          strokeWidth="16"
          strokeLinejoin="round"
          mask="url(#path-4-inside-4_1648_347)"
        />
      </svg>
    )}
    {...props}
  />
);
