import { CheckOutlined, DownCircleFilled } from "@ant-design/icons";
import { Dropdown } from "antd";
import cuid from "cuid";
import * as React from "react";

interface IViewSelectorItemProps {
  isSelected: boolean;
  name: string;
  complementaryIcon?: React.ReactNode;
  onClick?: () => void;
  onDeleteView?: () => void;
  onRenameView?: () => void;
  hoverable?: boolean;
  shouldShowDropdown?: boolean;
}

interface IState {
  isRenaming: boolean;
  isHovering: boolean;
}

export default class ViewSelectorItem extends React.Component<
  IViewSelectorItemProps,
  IState
> {
  id: string = cuid();

  constructor(props: IViewSelectorItemProps) {
    super(props);
    this.state = {
      isHovering: false,
      isRenaming: false,
    };
  }

  public render() {
    const {
      isSelected,
      name,
      complementaryIcon,
      onClick,
      hoverable,
      onDeleteView,
      onRenameView,
      shouldShowDropdown,
    } = this.props;
    const { isHovering } = this.state;

    const shouldHover = (onRenameView || onDeleteView) && hoverable;

    return (
      <div
        id={this.id}
        onMouseEnter={
          shouldHover ? () => this.setState({ isHovering: true }) : undefined
        }
        onMouseLeave={
          shouldHover ? () => this.setState({ isHovering: false }) : undefined
        }
        className={`view-selector-inner-list-item ${
          isSelected ? "selected" : ""
        } ${onClick ? "clickable" : ""}`}
        onClick={onClick}
      >
        <div className="view-selector-inner-list-item-title">{name}</div>
        {complementaryIcon ? (
          <div
            style={{ paddingRight: shouldShowDropdown ? 10 : 0 }}
            className={`view-selector-inner-list-item-icon`}
          >
            {complementaryIcon}
          </div>
        ) : undefined}
        {shouldShowDropdown && (
          <div
            className={`view-selector-inner-list-item-icon ${
              isHovering ? "gray" : ""
            }`}
          >
            {isHovering ? (
              <Dropdown
                getPopupContainer={() => {
                  const el = document.getElementById(this.id);
                  if (el) {
                    return el;
                  }
                  return document.body;
                }}
                trigger={["click"]}
                menu={{
                  items: [
                    {
                      key: 1,
                      onClick: onRenameView,
                      label: "Rename view",
                    },
                    {
                      key: 2,
                      onClick: onDeleteView,
                      label: "Delete view",
                    },
                  ],
                }}
              >
                <DownCircleFilled />
              </Dropdown>
            ) : isSelected ? (
              <CheckOutlined />
            ) : undefined}
          </div>
        )}
      </div>
    );
  }
}
