import Icon from "@ant-design/icons";
import type { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";

export const WlyRetentionChartIcon = (
  props: Partial<CustomIconComponentProps>
) => (
  <Icon
    component={() => (
      <svg
        width="1em"
        height="1em"
        viewBox="0 0 213 213"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_1671_290)">
          <rect width="53.25" height="53.25" fill="#38A76F" />
          <rect x="53.25" width="53.25" height="53.25" fill="#3DBA7B" />
          <rect x="106.5" width="53.25" height="53.25" fill="#41D58A" />
          <rect x="159.75" width="53.25" height="53.25" fill="#319D66" />
          <rect y="53.2498" width="53.25" height="53.25" fill="#337956" />
          <rect
            x="53.25"
            y="53.2498"
            width="53.25"
            height="53.25"
            fill="#35A76D"
          />
          <rect
            x="106.5"
            y="53.2498"
            width="53.25"
            height="53.25"
            fill="#319D66"
          />
          <rect y="106.5" width="53.25" height="53.25" fill="#1D4933" />
          <rect
            x="53.25"
            y="106.5"
            width="53.25"
            height="53.25"
            fill="#246846"
          />
          <rect y="159.75" width="53.25" height="53.25" fill="#123121" />
        </g>
        <defs>
          <clipPath id="clip0_1671_290">
            <rect width="213" height="213" fill="white" />
          </clipPath>
        </defs>
      </svg>
    )}
    {...props}
  />
);
