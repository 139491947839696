import type { Filter } from "@cubejs-client/core";
import { Button, Flex, Typography } from "antd";
import { useHover } from "../../../../../../../../../../components/hooks/useHover";
import type {
  IObject,
  IObjectQueryBuilderSectionItemConfigSingle_Parsed,
} from "../../../../../../../../../../interfaces/object";
import FieldFilter from "../../../../../../../../../reports/view/filters/field-filter/FieldFilter";
import type { AvailableColumn } from "../../../../../../domain";
import type { AvailableProperty } from "../../../../../domain";

export interface IOwnPropertyItemRendererProps {
  object: IObject;
  availableColumns: AvailableColumn[];
  itemPropertyConfig?: IObjectQueryBuilderSectionItemConfigSingle_Parsed;
  value?: string[];
  onChange?: (e: string[]) => void;
  injectedFilters?: Filter[];
  injectedMetrics?: string[];
}

export function OwnPropertyItemRenderer(props: IOwnPropertyItemRendererProps) {
  const {
    availableColumns,
    object,
    itemPropertyConfig: propertyConfig,
    value,
    onChange,
    injectedFilters,
  } = props;

  const [ref, isOver] = useHover<HTMLDivElement>();

  const renderInner = () => {
    if (!propertyConfig) {
      return (
        <Typography.Text ellipsis type="danger">
          No config found
        </Typography.Text>
      );
    }
    const foundProperty = availableColumns
      .filter((ac) => ac.type === "property")
      .find((ac) => ac.data.key === propertyConfig.key);
    if (!foundProperty) {
      return (
        <Typography.Text ellipsis type="danger">
          Property does not exist
        </Typography.Text>
      );
    }
    const fp = foundProperty.data as AvailableProperty;
    const config = propertyConfig.config;

    const injectedMetrics = [`${object.table.cubeName}.count`];

    return (
      <div ref={ref}>
        <Flex style={{ paddingBottom: 4 }}>
          <div style={{ flex: 1 }}>
            <Typography.Text strong ellipsis>
              {foundProperty.data.label}
            </Typography.Text>
          </div>
          <div
            style={{
              flex: `0 auto`,
              opacity: isOver && value && value.length > 0 ? 1 : 0,
              transition: "0.2s opacity",
            }}
          >
            <Button
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                onChange?.([]);
              }}
              danger
              type="text"
              size="small"
            >
              Clear
            </Button>
          </div>
        </Flex>
        <FieldFilter
          filterType={fp.domain}
          value={value}
          filterName={fp.label}
          filterNameDisplayType="placeholder"
          valueDimension={fp.sortAndFilterKey}
          autocompleteLimit={
            propertyConfig?.config?.limit ? propertyConfig.config.limit : 100
          }
          objectId={object.id}
          objectType={"OBJECT"}
          componentType={config.singleValueOnly ? "SELECT" : "MULTI_SELECT"}
          tilesToUpdate={[]}
          editing={false}
          controlledBy={[]}
          additionalQueryFilters={injectedFilters}
          block
          allowNullValueForDate
          hideLinkFilterMark
          injectedMetrics={injectedMetrics}
          onChange={(v) => {
            onChange?.(v as any);
          }}
        />
      </div>
    );
  };

  return <div>{renderInner()}</div>;
}
