import { LoadingOutlined } from "@ant-design/icons";
import { Button, List, Skeleton } from "antd";
import { inject, observer } from "mobx-react";
import moment from "moment";
import * as React from "react";
import { compose } from "../../../../../components/compose/WlyCompose";
import type { IDestination } from "../../../../../interfaces/destinations";
import type {
  IJobExecution,
  JobSyncStatus,
} from "../../../../../interfaces/jobExecutions";
import { JobType } from "../../../../../interfaces/jobExecutions";
import type { WorkbenchUIStoreProps } from "../../../../../store/workbenchUIStore";
import type { IActiveObject } from "../../domain";
import { generateJobExecutionName } from "../../domain";
import { JobExecutionStatusRenderer } from "../../viewer/job/JobStatusRenderer";
import "./JobTable.scss";

interface IJobTableProps {
  jobExecutions: IJobExecution[];
  activeObject?: IActiveObject;
  jobType: JobType;
  onActiveObjectChange: (activeObject: IActiveObject) => void;
  cancelJobExecutionRun: (exec: IJobExecution) => Promise<void>;
  onRefreshJobExecutions: () => Promise<void>;
  destinationDbtCloudSyncStatus: JobSyncStatus;
  destinationPersistenceEngineSyncStatus: JobSyncStatus;
  currentWarehouse: IDestination;
}

type Props = IJobTableProps & WorkbenchUIStoreProps;

class JobTable extends React.Component<Props> {
  public render() {
    const {
      jobExecutions,
      jobType,
      activeObject,
      onRefreshJobExecutions,
      onActiveObjectChange,
      cancelJobExecutionRun,
      destinationDbtCloudSyncStatus,
      destinationPersistenceEngineSyncStatus,
    } = this.props;

    const filteredJobExecutions = jobExecutions.filter((execution) => {
      if (execution.jobType === jobType) {
        return true;
      }
      return false;
    });

    const pendingExecution: { type: "PENDING" }[] =
      ((jobType === JobType.DBT_CLOUD_SYNC &&
        destinationDbtCloudSyncStatus === "scheduled") ||
        (jobType === JobType.PERSISTENCE_ENGINE_RUN &&
          destinationPersistenceEngineSyncStatus === "scheduled")) &&
      filteredJobExecutions[0]?.status !== "RUNNING"
        ? [
            {
              type: "PENDING",
            },
          ]
        : [];

    const JobExecutionList = [...pendingExecution, ...filteredJobExecutions];

    return (
      <List
        itemLayout="horizontal"
        dataSource={JobExecutionList}
        className="job-content-list"
        renderItem={(i) => {
          if ((i as IJobExecution).identifier) {
            const item = i as IJobExecution;
            return (
              <List.Item
                className={`job-content-list-item ${
                  activeObject && activeObject.value === item.identifier
                    ? "active"
                    : ""
                }`}
                onClick={() =>
                  onActiveObjectChange({
                    type: "job",
                    value: item.identifier,
                  })
                }
              >
                <List.Item.Meta
                  avatar={<JobExecutionStatusRenderer jobExecution={item} />}
                  title={
                    <div className="job-content-list-item-title">
                      {generateJobExecutionName(item)}
                    </div>
                  }
                  description={
                    <div className="job-content-list-item-description">
                      {item.status !== "RUNNING" ? (
                        moment
                          .duration(moment(item.updatedAt).diff(item.createdAt))
                          .humanize()
                      ) : (
                        <>
                          <Skeleton.Input active size="small" />
                          <Button
                            type="text"
                            danger
                            onClick={async () => {
                              await cancelJobExecutionRun(item);
                              await onRefreshJobExecutions();
                            }}
                          >
                            Cancel
                          </Button>
                        </>
                      )}
                    </div>
                  }
                />
              </List.Item>
            );
          }
          return (
            <List.Item className={`job-content-list-item disabled`}>
              <List.Item.Meta
                avatar={
                  <div>
                    <LoadingOutlined />
                  </div>
                }
                title="Pending Execution"
                description="Waiting for the next slot"
              />
            </List.Item>
          );
        }}
      />
    );
  }
}

export default compose<Props, IJobTableProps>()(
  inject("workbenchUIStore")(observer(JobTable))
);
