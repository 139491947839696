import type { MeasureItemSortValue } from "../../../../../../../components/measures/measure-item/MeasureItem";
import type { IObject } from "../../../../../../../interfaces/object";
import {
  convertPropertyToAvailableProperties,
  getObjectColumns,
  isAvailableMetric,
  isAvailableProperty,
} from "../../../../object/domain";
import { parseLabel, type IRecord } from "../../../domain";
import { type BaseConfig } from "../domain";
import type { IFilterEditorValue } from "../related-lists/domain";
import { buildQueryFromDimensionAndMetrics } from "../related-lists/domain";

const getForeignObject = (object: IObject, foreignObjectPropertyId: string) => {
  const foreignProperty = object.foreignKeys.find(
    (p) => p.id === foreignObjectPropertyId
  );
  if (!foreignProperty) return undefined;
  return foreignProperty.object;
};

export interface IWidgetSuggestedProductsConfig extends BaseConfig {
  foreignObjectPropertyId?: string;
  options?: {
    secondaryText?: string;
    primaryText?: string;
    productKey?: string;
    categoryKey?: string;
    badgeLabel?: string[];
    displayedAction?: string;
    sortBy?: Array<[string, MeasureItemSortValue]>;
    filters?: IFilterEditorValue;
    fallbackImageSrc?: string;
    limit?: number;
  };
}
export interface IWidgetParsedConfig {
  categoryKey?: string;
  labelKey: string;
  badgeKey?: string;
  primaryText?: string;
  secondaryText?: string;
  fallbackImageSrc?: string;
  displayedAction?: string;
}

export const getSuggestedProductsQuery = (options: {
  object: IObject;
  record: IRecord;
  conf: IWidgetSuggestedProductsConfig;
  recosToAvoid: string[];
  foreignObject: IObject;
}) => {
  const { object, record, conf, recosToAvoid } = options;

  if (!conf.foreignObjectPropertyId) {
    return undefined;
  }

  const foreignObject = getForeignObject(object, conf.foreignObjectPropertyId);

  if (!foreignObject) {
    return undefined;
  }

  const property = foreignObject.properties.find(
    (p) => p.id === conf.foreignObjectPropertyId
  );

  if (!property) {
    return undefined;
  }

  const foreignAvailable = convertPropertyToAvailableProperties(
    foreignObject.table.cubeName,
    foreignObject,
    property
  );

  const dimensions = [
    ...(conf.options?.productKey ? [conf.options?.productKey] : []),
    ...(conf.options?.categoryKey ? [conf.options?.categoryKey] : []),
  ];

  if (!dimensions) {
    return undefined;
  }

  const recordFilter = {
    recordForeignKey: `${foreignAvailable.key}_raw`,
    recordId: record[`${object.table.cubeName}.id`] as string,
  };

  const filters: IFilterEditorValue | undefined =
    conf.options?.filters || recosToAvoid.length
      ? {
          operator: "and",
          filters: [
            ...(conf.options?.filters
              ? [
                  {
                    [conf.options?.filters?.operator]:
                      conf.options.filters.filters,
                  } as any,
                ]
              : []),
            ...(recosToAvoid.length
              ? [
                  {
                    member: `${foreignObject.table.cubeName}.id`,
                    operator: "notEquals",
                    values: recosToAvoid,
                  },
                ]
              : []),
          ],
        }
      : undefined;

  return buildQueryFromDimensionAndMetrics(
    getObjectColumns(foreignObject)
      .filter(isAvailableProperty)
      .map((c) => c.data.key),

    getObjectColumns(foreignObject)
      .filter(isAvailableMetric)
      .map((c) => c.data.key),
    recordFilter,
    filters,
    conf.options?.sortBy ?? [],
    conf.options?.limit ?? 500
  );
};

export const renderScoreStyle = (value: string | number | boolean) => {
  const nb = +value;
  if (isNaN(nb)) return { display: "none " };
  if (nb < 0.6) return { fontSize: 12, color: "rgba(0, 0, 0, 0.45)" };
  if (nb >= 0.8) {
    return { fontSize: 12, color: "#389E0E" };
  }
  return { fontSize: 12, color: "#D36B08" };
};

export const extractImageUrl = (p: IRecord, conf: IWidgetParsedConfig) => {
  if (!p[conf.labelKey]) return undefined;
  const { image } = parseLabel(p[conf.labelKey] as string);
  if (image) {
    return image;
  }
  return undefined;
};
