import type { EditorState } from "@codemirror/state";
import { StateField } from "@codemirror/state";
import type { Tooltip } from "@codemirror/view";
import { showTooltip } from "@codemirror/view";
import type { FunctionDefinition } from "../../../parser/functions";

type IActiveFunction = {
  name: string;
  tooltipPosition: number;
  argIndex: number;
};

export function FormulaEditorTooltips(availableFormulas: FunctionDefinition) {
  function getActiveFunction(partialFormula: string): IActiveFunction {
    if (!partialFormula || partialFormula === "") return null;

    // on trim la partial formula de toutes les strings
    // pour cela on les remplace par des xxxxx
    const trimmed: string = partialFormula.replace(
      /"(?:[^\\]|\\.)*?(?:"|$)/g,
      (match) => {
        if (match?.length) {
          return "x".repeat(match.length);
        } else {
          return "";
        }
      }
    );

    if (!trimmed || trimmed === "") return null;

    // compter le nombre de parenthèses ouvertes
    // compter le nombre de parenthèses fermées
    // si le nombre nb ouvert = nb fermé, l'utilisateur n'est pas dans une fonction;
    const open = trimmed.match(/\(/g);
    const close = trimmed.match(/\)/g);

    if (open?.length === close?.length) {
      return null;
    }

    const savedPosition = trimmed.length;

    // en partant de la fin, remonter la string en comptant le nombre de parenthèses ouverertes et fermées
    // s'arrêter dès que nb parenthèses ouvertes > nb parenthèses fermées
    let position = savedPosition;
    let openCount = 0;
    let closeCount = 0;

    while (position > 0) {
      const char = trimmed.charAt(position - 1);
      if (char === "(") {
        openCount++;
      } else if (char === ")") {
        closeCount++;
      }
      if (openCount > closeCount) {
        break;
      }
      position--;
    }

    // on coupe la string à ce niveau - 1 pour supprimer la parenthèse ouverte
    const trimmedToOpenFunction = trimmed.substring(0, position - 1);

    // on match le resultat avec notre regex et retourne le dernier résultat
    const formulaRegex = new RegExp(
      "(?:" + Object.keys(availableFormulas).join("|") + ")\\b",
      "gi"
    );

    const finalMatch = trimmedToOpenFunction.match(formulaRegex);

    if (!finalMatch || !finalMatch.length) return null;

    // on detecte l'index de l'argument actif

    // on prend tout dabord la string entre la fonction matchée et le curseur de l'utilsateur
    const argumentString = trimmed.substring(position, savedPosition);

    // on exlu les fonctions nestées, pour cela
    // on regarde si il y a une parenthèse ouverte, une parenthèse fermée, et on supprime tout ce qui est entre
    const firstParenthesisIndex = argumentString.indexOf("(");
    const lastParenthesisIndex = argumentString.lastIndexOf(")");

    let trimmedArgumentString = argumentString;

    if (firstParenthesisIndex > -1 && lastParenthesisIndex > -1) {
      trimmedArgumentString =
        argumentString.substring(0, firstParenthesisIndex) +
        argumentString.substring(lastParenthesisIndex, argumentString.length);
    }

    // on compte le nombre de delimiters, ce qui donne l'index de l'argument actif
    const matchCount = (trimmedArgumentString.match(/;/g) || []).length;

    const activeFunction: IActiveFunction = {
      name: finalMatch[finalMatch.length - 1].toUpperCase(),
      tooltipPosition: position,
      argIndex: matchCount,
    };

    return activeFunction;
  }

  function getCursorTooltips(state: EditorState): readonly Tooltip[] {
    return state.selection.ranges
      .filter((range) => range.empty)
      .map((range) => {
        if (!availableFormulas) return null;
        // we get the text content of the line were the cursor is currently positionned
        const cursorPosition = state.selection.main.head;
        const line = state.doc.lineAt(range.head);
        const lineText = line.text;
        const truncatedText = lineText.substring(0, cursorPosition);

        const activeFunction = getActiveFunction(truncatedText);

        if (!activeFunction) return null;

        const formula = availableFormulas[activeFunction?.name]
          ? availableFormulas[activeFunction?.name]
          : null;

        if (!formula || !formula.args || !formula.args.length) return null;

        return {
          pos: activeFunction?.tooltipPosition,
          above: true,
          strictSide: true,
          arrow: true,
          create: () => {
            const outer = document.createElement("div");
            outer.className = "cm-tooltip-cursor";
            const inner = document.createElement("span");
            inner.innerHTML = `${activeFunction?.name} (${formula.args
              .map((arg, i) => {
                const className =
                  i === activeFunction?.argIndex
                    ? "cm-tooltip-argument cm-tooltip-argument-active"
                    : "cm-tooltip-argument";
                const name = `<span class="${className}">${arg.name}</span>`;
                if (i === formula.args.length - 1) {
                  return name;
                } else {
                  return `${name}<span>;</span>
`;
                }
              })
              .join?.("")})`;
            outer.appendChild(inner);
            return { dom: outer };
          },
        };
      });
  }

  const cursorTooltipField = StateField.define<readonly Tooltip[]>({
    create: getCursorTooltips,

    update(tooltips, tr) {
      if (!tr.docChanged && !tr.selection) return tooltips;
      return getCursorTooltips(tr.state);
    },

    provide: (f) => showTooltip.computeN([f], (state) => state.field(f)),
  });

  return cursorTooltipField;
}
