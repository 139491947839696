import StorageService from "../../../../../../../../services/StorageService";

export const saveTempRecosToAvoid = (key: string, recoId: string) => {
  try {
    const item = StorageService.getItem(key);
    let val: string[] = [];
    if (item) {
      let arr = JSON.parse(item);
      if (Array.isArray(arr)) {
        val = [...arr, recoId];
      } else {
        val = [recoId];
      }
    } else {
      val = [recoId];
    }
    StorageService.setItem({ [key]: JSON.stringify(val) });
  } catch (err) {
    console.error(err);
    StorageService.setItem({ [key]: JSON.stringify([recoId]) });
  }
};

export const getTempRecosToAvoid = (key: string): Array<string> => {
  try {
    const item = StorageService.getItem(key);
    if (item) {
      let arr = JSON.parse(item);
      if (Array.isArray(arr)) {
        return arr;
      } else {
        return [];
      }
    } else {
      return [];
    }
  } catch (err) {
    console.error(err);
    return [];
  }
};
