import type { RouteComponentProps } from "react-router";
import { withRouter } from "react-router";
import { compose } from "../../../../../components/compose/WlyCompose";
import Error from "../../../../../components/error/Error";
import Feednack from "../../../../../components/layout/feedback/feedback";
import Loading from "../../../../../components/layout/feedback/loading";
import type { IDestination } from "../../../../../interfaces/destinations";
import type { ISource } from "../../../../../interfaces/sources";
import GraphQl from "../../../../graphql/graphql";
import type { IActiveObject } from "../../domain";
import ConnectorViewer from "./ConnectorViewer";

interface IConnectorViewerWrapperProps {
  activeObject: IActiveObject;
  currentWarehouse: IDestination;
}

interface GQLResult {
  allSources: ISource[];
}

const GQL = `
  query getSource($id: ID!) {
    allSources(where: { id: $id }) {
      id
      name
      status
      syncStatus
      lastSyncDate
      nextSyncDate
      catalogFileURI
      stateFileURI
      targetDatabase
      targetSchema
      isEnabled
      shouldCheckSla
      slaPeriod
      lastJobExecution {
        id
        status
      }
      sourceMeta {
        id
        slug
        publicInfo {
          name
          label
          description
          logo
          website
        }
        name
        executor
        auth
        options(where: { hidden: false }) {
          id
          slug
          key
          label
          editable
          type
          encrypt
          hidden
          required
        }
      }
      warehouse {
        id
      }
      values(where: { option: { hidden: false } }) {
        id
        value
        option {
          id
          slug
          key
          label
          editable
          type
          encrypt
          description
          hidden
          required
        }
      }
      slug
      org {
        id
      }
    }
  }
`;

type Props = IConnectorViewerWrapperProps &
  RouteComponentProps<{ organizationSlug: string; sourceSlug: string }>;

function ConnectorViewerWrapper(props: Props) {
  return (
    <GraphQl<GQLResult>
      query={GQL}
      variables={{ id: props.activeObject.value }}
    >
      {(gql) => {
        if (gql.status === "initial" || gql.status === "loading") {
          return (
            <Feednack>
              <Loading />
            </Feednack>
          );
        }
        if (gql.status === "error") {
          return (
            <Feednack>
              <Error />
            </Feednack>
          );
        }
        if (gql.data.allSources.length !== 1) {
          return (
            <Feednack>
              <Error />
            </Feednack>
          );
        }
        return (
          <ConnectorViewer
            activeObject={props.activeObject}
            sources={gql.data.allSources}
            currentWarehouse={props.currentWarehouse}
          />
        );
      }}
    </GraphQl>
  );
}

export default compose<Props, IConnectorViewerWrapperProps>(withRouter)(
  ConnectorViewerWrapper
);
