import { DiffEditor } from "@monaco-editor/react";
import { Button, Modal } from "antd";
import * as React from "react";

export type HistoryValueRendererFieldType = "unknown" | "query" | "sql";
interface IHistoryValueRendererProps {
  children?: React.ReactNode;
  previousValue: string;
  newValue: string;
  fieldType: HistoryValueRendererFieldType;
}

export function HistoryValueRenderer(props: IHistoryValueRendererProps) {
  const { previousValue, newValue, fieldType } = props;
  const [visible, setVisible] = React.useState<boolean>(false);

  const renderSQLDiff = () => (
    <div
      style={{
        height: "calc(var(--doc-height) - 300px)",
      }}
    >
      <DiffEditor
        language="sql"
        original={previousValue}
        modified={newValue}
        options={{
          automaticLayout: true,
        }}
      />
    </div>
  );

  const renderQueryDiff = () => {
    let original = previousValue
      ? JSON.stringify(JSON.parse(previousValue), null, 2)
      : previousValue;
    let modified = newValue
      ? JSON.stringify(JSON.parse(newValue), null, 2)
      : newValue;
    return (
      <div
        style={{
          height: "calc(var(--doc-height) - 300px)",
        }}
      >
        <DiffEditor
          language="json"
          original={original}
          modified={modified}
          options={{
            automaticLayout: true,
          }}
        />
      </div>
    );
  };

  const renderDefaultDiff = () => {
    if (previousValue && newValue) {
      return (
        <div>
          <div>
            <b>From:</b>
          </div>
          <div>{previousValue}</div>
          <br />
          <br />
          <div>
            <b>To:</b>
          </div>
          <div>{newValue}</div>
        </div>
      );
    }
    if (!previousValue && newValue) {
      return (
        <div>
          <div>Added:</div>
          <div>{newValue}</div>
        </div>
      );
    }
    if (previousValue && !newValue) {
      return (
        <div>
          <div>Deleted:</div>
          <div>{newValue}</div>
        </div>
      );
    }
  };

  return (
    <>
      <Button
        onClick={() => setVisible(true)}
        ghost
        size="small"
        type="primary"
      >
        {props.children}
      </Button>
      <Modal
        title="Value"
        open={visible}
        width={"90%"}
        destroyOnClose={true}
        onCancel={() => setVisible(false)}
        onOk={() => setVisible(false)}
        className="history-value-renderer"
        cancelButtonProps={{
          hidden: true,
        }}
      >
        {fieldType === "sql" && renderSQLDiff()}
        {fieldType === "query" && renderQueryDiff()}
        {fieldType === "unknown" && renderDefaultDiff()}
      </Modal>
    </>
  );
}
