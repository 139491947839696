import { Button, Form, Radio, Space, Typography } from "antd";
import React from "react";
import type { InjectedAntUtilsProps } from "../../../../components/ant-utils/withAntUtils";
import { withAntUtils } from "../../../../components/ant-utils/withAntUtils";
import { compose } from "../../../../components/compose/WlyCompose";
import type { SchemaResult } from "../../../../interfaces/transformations";
import type {
  IDimensionFormInput,
  IMetricFormInput,
} from "../../../workbench/workbench/exploration/domain";
import { generateMeasuresFromSchema } from "./domain";

const { Text } = Typography;

interface ISchemaSelectionStepProps {
  fetchSchema: () => Promise<SchemaResult[]>;
  onSelect: (
    metrics: IMetricFormInput[],
    dimensions: IDimensionFormInput[]
  ) => Promise<void>;
}

type Props = ISchemaSelectionStepProps & InjectedAntUtilsProps;

function SchemaSelectionStep(props: Props) {
  const { antUtils, fetchSchema, onSelect } = props;
  const [submitting, setSubmitting] = React.useState<boolean>(false);
  return (
    <Form
      initialValues={{
        type: "AUTOMATICALLY_GENERATE",
      }}
      onFinish={async (v) => {
        try {
          setSubmitting(true);
          let measuresToCreate: IMetricFormInput[] | undefined = undefined;
          let dimensionsToCreate: IDimensionFormInput[] | undefined = undefined;

          if (v.type === "AUTOMATICALLY_GENERATE") {
            const schema = await fetchSchema();
            const { metrics, dimensions } = generateMeasuresFromSchema(
              schema[0]
            );

            const LIMIT = 200;
            if (metrics.length + dimensions.length > 200) {
              await new Promise((resove, reject) => {
                antUtils.modal.error({
                  title: "Too many dimension & metrics",
                  content: `This will create ${dimensions.length} dimension & ${metrics.length} metrics. The limit is ${LIMIT}, please remove some columns in your models and try again.`,
                  onOk: () => {
                    reject();
                    return false;
                  },
                });
              });
            }

            measuresToCreate = metrics;
            dimensionsToCreate = dimensions;
          }

          return onSelect(measuresToCreate, dimensionsToCreate);
        } catch (err) {
          console.error(err);
          return onSelect([], []);
        } finally {
          setSubmitting(false);
        }
      }}
    >
      <Form.Item name="type">
        <Radio.Group value={"START_FROM_SCRATCH"}>
          <Space
            style={{ width: "100%", paddingTop: 12 }}
            size={24}
            direction="vertical"
          >
            <Radio value={"AUTOMATICALLY_GENERATE"}>
              <div>
                <Text strong>Automatically generate dimensions & metrics</Text>
              </div>
              <div>
                <Text>
                  This will automatically create dimension & metrics based on
                  your dataset schema. Please check our dimension to learn more.
                </Text>
              </div>
            </Radio>
            <Radio value={"START_FROM_SCRATCH"}>
              <div>
                <Text strong>I will manually create dimensions & metrics</Text>
              </div>
              <div>
                <Text>
                  We will not create any metrics and dimension by default, you
                  can still add dimension & metrics afterwards.
                </Text>
              </div>
            </Radio>
          </Space>
        </Radio.Group>
      </Form.Item>
      <div style={{ width: "100%", textAlign: "right" }}>
        <Button loading={submitting} type="primary" htmlType="submit">
          Add
        </Button>
      </div>
    </Form>
  );
}

export default compose<Props, ISchemaSelectionStepProps>(withAntUtils)(
  SchemaSelectionStep
);
