import type { ResultSet } from "@cubejs-client/core";
import { Progress, Skeleton, Tag } from "antd";
import numeral from "numeral";
import * as React from "react";
import { compose } from "../../../components/compose/WlyCompose";
import type { AsyncData } from "../../../helpers/typescriptHelpers";
import type { DataType } from "../../../interfaces/transformations";
import {
  LagoonCallOrigin,
  lagoonServiceLoad,
} from "../../../services/LagoonService";
import type { InjectedOrgProps } from "../../orgs/WithOrg";
import WithOrg from "../../orgs/WithOrg";
import type { ColumnInfoTest } from "../domain";
import FieldRenderer from "../renderer/FieldRenderer";
import { TestRenderer } from "../tests/TestRenderer";

interface IColumnModalContentProps {
  columnName: string;
  description?: string;
  columnDomain: DataType;
  columnType: string;
  tests?: Array<ColumnInfoTest>;
  viewId: string;
  recordCount?: number;
}

interface IState {
  nulls: AsyncData<ResultSet>;
  top10: AsyncData<ResultSet>;
  distinct: AsyncData<ResultSet>;
}

type Props = IColumnModalContentProps & InjectedOrgProps;

class ColumnModalContent extends React.Component<Props, IState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      nulls: { status: "initial" },
      top10: { status: "initial" },
      distinct: { status: "initial" },
    };
  }

  componentDidMount() {
    const { org, columnName, viewId } = this.props;
    this.fetchData(org.id, viewId, columnName);
  }

  componentDidUpdate(prevProps: Props) {
    const { org, columnName, viewId } = this.props;
    const {
      org: prevOrg,
      columnName: prevColumnName,
      viewId: prevViewId,
    } = prevProps;
    if (
      org.id !== prevOrg.id ||
      columnName !== prevColumnName ||
      viewId !== prevViewId
    ) {
      this.setState(
        {
          nulls: { status: "initial" },
          top10: { status: "initial" },
          distinct: { status: "initial" },
        },
        () => {
          this.fetchData(org.id, viewId, columnName);
        }
      );
    }
  }

  fetchData = (orgId: string, viewId: string, fieldName: string) => {
    this.setState({
      nulls: { status: "loading" },
      top10: { status: "loading" },
      distinct: { status: "loading" },
    });
    return Promise.all([
      // get number of nulls
      lagoonServiceLoad(
        orgId,
        {
          measures: [`View_${viewId}._wly_count`],
          filters: [
            {
              and: [
                {
                  member: `View_${viewId}.${fieldName}`,
                  operator: "notSet",
                },
              ],
            },
          ],
        },
        "VIEW",
        viewId,
        undefined,
        LagoonCallOrigin.WHALY_APP
      )
        .then((r) => {
          this.setState({
            nulls: { status: "success", data: r },
          });
        })
        .catch((err) => {
          this.setState({
            nulls: { status: "error", error: err },
          });
        }),
      // get top 10 values with associated counts
      lagoonServiceLoad(
        orgId,
        {
          measures: [`View_${viewId}._wly_count`],
          dimensions: [`View_${viewId}.${fieldName}`],
          order: [[`View_${viewId}._wly_count`, "desc"]],
          limit: 10,
          filters: [
            {
              and: [
                {
                  member: `View_${viewId}.${fieldName}`,
                  operator: "set",
                },
              ],
            },
          ],
        },
        "VIEW",
        viewId,
        undefined,
        LagoonCallOrigin.WHALY_APP
      )
        .then((r) => {
          this.setState({
            top10: { status: "success", data: r },
          });
        })
        .catch((err) => {
          this.setState({
            top10: { status: "error", error: err },
          });
        }),
      // get number of distinct values with associated counts
      lagoonServiceLoad(
        orgId,
        {
          measures: [`View_${viewId}.${fieldName}_distinct`],
          filters: [
            {
              and: [
                {
                  member: `View_${viewId}.${fieldName}`,
                  operator: "set",
                },
              ],
            },
          ],
        },
        "VIEW",
        viewId,
        undefined,
        LagoonCallOrigin.WHALY_APP
      )
        .then((r) => {
          this.setState({
            distinct: { status: "success", data: r },
          });
        })
        .catch((err) => {
          this.setState({
            distinct: { status: "error", error: err },
          });
        }),
    ]);
  };

  renderNulls = () => {
    const { recordCount, viewId } = this.props;
    const { nulls } = this.state;
    if (nulls.status === "error") {
      return <div>{nulls.error.message}</div>;
    }
    if (
      nulls.status === "initial" ||
      nulls.status === "loading" ||
      recordCount === undefined ||
      recordCount === null
    ) {
      return (
        <div>
          <Skeleton.Input
            style={{ width: 100, borderRadius: 20, height: 20 }}
            active={true}
            size="small"
          />
        </div>
      );
    }
    const table = nulls.data.tablePivot();
    const numberOfNulls = table[0][`View_${viewId}._wly_count`] as number;
    const percent = (numberOfNulls / recordCount) * 100;
    const status =
      percent < 20 ? "success" : percent > 80 ? "exception" : "normal";
    return (
      <Progress
        percent={percent}
        format={(p) => `${numeral(p).format("0[.]00")}%`}
        status={status}
      />
    );
  };

  renderTop10 = () => {
    const { recordCount, viewId, columnName, columnType, columnDomain } =
      this.props;
    const { top10 } = this.state;
    if (top10.status === "error") {
      return <div>{top10.error.message}</div>;
    }
    if (
      top10.status === "initial" ||
      top10.status === "loading" ||
      recordCount === undefined ||
      recordCount === null
    ) {
      return (
        <div>
          <Skeleton.Input
            size="small"
            style={{ width: 50, borderRadius: 20, height: 20, marginRight: 5 }}
            active={true}
          />
          <Skeleton.Input
            size="small"
            style={{ width: 50, borderRadius: 20, height: 20, marginRight: 5 }}
            active={true}
          />
          <Skeleton.Input
            size="small"
            style={{ width: 50, borderRadius: 20, height: 20, marginRight: 5 }}
            active={true}
          />
          <Skeleton.Input
            size="small"
            style={{ width: 50, borderRadius: 20, height: 20, marginRight: 5 }}
            active={true}
          />
          <Skeleton.Input
            size="small"
            style={{ width: 50, borderRadius: 20, height: 20, marginRight: 5 }}
            active={true}
          />
          <Skeleton.Input
            size="small"
            style={{ width: 50, borderRadius: 20, height: 20, marginRight: 5 }}
            active={true}
          />
          <Skeleton.Input
            size="small"
            style={{ width: 50, borderRadius: 20, height: 20, marginRight: 5 }}
            active={true}
          />
          <Skeleton.Input
            size="small"
            style={{ width: 50, borderRadius: 20, height: 20, marginRight: 5 }}
            active={true}
          />
          <Skeleton.Input
            size="small"
            style={{ width: 50, borderRadius: 20, height: 20, marginRight: 5 }}
            active={true}
          />
          <Skeleton.Input
            size="small"
            style={{ width: 50, borderRadius: 20, height: 20, marginRight: 5 }}
            active={true}
          />
        </div>
      );
    }
    const dataSource = top10.data.tablePivot();
    return (
      <>
        {dataSource.map((d) => {
          return (
            <Tag
              style={{ marginTop: 10 }}
              key={d[`View_${viewId}.${columnName}`] as any}
            >
              <FieldRenderer
                content={d[`View_${viewId}.${columnName}`]}
                type={columnDomain}
              />
            </Tag>
          );
        })}
      </>
    );
  };

  renderDistincts = () => {
    const { recordCount, viewId, columnName } = this.props;
    const { distinct } = this.state;
    if (distinct.status === "error") {
      return <div>{distinct.error.message}</div>;
    }
    if (
      distinct.status === "initial" ||
      distinct.status === "loading" ||
      recordCount === undefined ||
      recordCount === null
    ) {
      return (
        <div>
          <Skeleton.Input
            style={{ width: 200, borderRadius: 20, height: 20 }}
            active={true}
            size="small"
          />
        </div>
      );
    }
    const table = distinct.data.tablePivot();
    const numberOfDistincts = table[0][
      `View_${viewId}.${columnName}_distinct`
    ] as number;
    return (
      <div>
        <div>{numberOfDistincts}</div>
        {numberOfDistincts === recordCount ? (
          <div>
            <i>Each row has a unique value</i>
          </div>
        ) : null}
      </div>
    );
  };

  public render() {
    const { tests, description } = this.props;
    return (
      <div>
        <div className="item">
          <div className="title">Name</div>
          <div>{this.props.columnName}</div>
        </div>
        {description ? (
          <div className="item">
            <div className="title">Description</div>
            <div>{description}</div>
          </div>
        ) : undefined}
        <div className="item">
          <div className="title">Type</div>
          <div>{this.props.columnType}</div>
        </div>
        {tests && tests.length ? (
          <div className="item">
            <div className="title">Tests</div>
            {tests.map((t, i) => {
              return <TestRenderer key={i} test={t} />;
            })}
          </div>
        ) : null}
        <div className="item">
          <div className="title">% of nulls</div>
          <div>{this.renderNulls()}</div>
        </div>
        <div className="item">
          <div className="title">Number of distinct values</div>
          {this.renderDistincts()}
        </div>
        <div className="item">
          <div className="title">Top 10 values</div>
          {this.renderTop10()}
        </div>
      </div>
    );
  }
}

export default compose<Props, IColumnModalContentProps>(WithOrg)(
  ColumnModalContent
);
