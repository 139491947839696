import { CaretDownFilled, CaretRightFilled } from "@ant-design/icons";
import { Button, Typography } from "antd";
import * as React from "react";
import "./ClosableContent.scss";
import { ClosableContentItem } from "./ClosableContentItem";

interface ClosableContentProps {
  name: string;
  items:
    | Array<{
        key: string;
        onClick: () => void;
        selected?: boolean;
        label: React.ReactNode;
        style?: React.CSSProperties;
      }>
    | React.ReactNode;
  avoidClosing?: boolean;
  noPadding?: boolean;
  button?: {
    name: string;
    icon?: React.ReactNode;
    onClick?: () => void;
  };
  actionButton?: React.ReactNode;
}

export function ClosableContent(props: ClosableContentProps) {
  const { name, items, button, noPadding, avoidClosing, actionButton } = props;
  const [open, setOpen] = React.useState(true);
  return (
    <div className="closable-content">
      <div
        style={{
          paddingTop: noPadding ? 0 : undefined,
        }}
        className="closable-content-title"
        onClick={() => !avoidClosing && setOpen(!open)}
      >
        <div
          className={`closable-content-title-arrow ${
            avoidClosing ? "" : "closable"
          }`}
        >
          {open ? <CaretDownFilled /> : <CaretRightFilled />}
        </div>
        <div className="closable-content-title-title">
          <Typography.Text strong>{name}</Typography.Text>
        </div>
        <div>{actionButton}</div>
      </div>
      <div className={`closable-content-inner${!open ? " open" : ""}`}>
        {Array.isArray(items) ? (
          <div className="closable-content-inner-wrapper">
            {items.map((i, index) => {
              return (
                <ClosableContentItem
                  key={index}
                  selected={i.selected ? i.selected : false}
                  onClick={i.onClick}
                  style={i.style}
                >
                  <div style={{ display: "flex" }}>
                    <div style={{ flex: 1, minWidth: 0 }}>{i.label}</div>
                  </div>
                </ClosableContentItem>
              );
            })}
          </div>
        ) : (
          items
        )}
        {button && (
          <Button
            className="closable-content-inner-button"
            type="text"
            size="small"
            icon={button.icon}
            onClick={button.onClick}
          >
            {button.name}
          </Button>
        )}
      </div>
    </div>
  );
}
