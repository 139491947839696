import { Layout } from "antd";
import { inject, observer } from "mobx-react";
import * as React from "react";
import { compose } from "../../components/compose/WlyCompose";
import Content from "../../components/layout/content/Content";
import MainHeader from "../../components/layout/header/MainHeader";
import UserAvatar from "../../components/user/avatar/UserAvatar";
import type { UserStoreProps } from "../../store/userStore";
import WhlyBreadcrumb from "../breadcrumb/Breadcrumb";
import ProfileDropDown from "../menu/ProfileDropDown";

interface IExternalLayoutProps {
  contentComponent: React.ComponentClass<any, any>;
  additionalProps?: { [key: string]: any };
}

type Props = IExternalLayoutProps & UserStoreProps;

class ExternalLayout extends React.Component<Props> {
  public render() {
    const { userStore, contentComponent, additionalProps } = this.props;
    const ContentComponent = contentComponent;

    return (
      <Layout style={{ minHeight: "100%" }}>
        <Layout className="wly-layout background">
          <MainHeader
            userInfo={
              <ProfileDropDown placement="bottomLeft">
                <UserAvatar user={userStore.user} />
              </ProfileDropDown>
            }
            breadcrumb={<WhlyBreadcrumb />}
            showLogo={true}
          />
          <Content>
            <ContentComponent {...additionalProps} />
          </Content>
        </Layout>
      </Layout>
    );
  }
}

export default compose<Props, IExternalLayoutProps>()(
  inject("userStore")(observer(ExternalLayout))
);
