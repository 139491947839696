import * as React from "react";
import { Layout } from "antd";

const { Content: AntContent } = Layout;

interface IContentProps {
  children?: React.ReactNode;
}

export default class Content extends React.Component<IContentProps> {
  public render() {
    return (
      <AntContent className="wly-layout-content">
        {this.props.children}
      </AntContent>
    );
  }
}
