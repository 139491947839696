import { inject, observer } from "mobx-react";
import * as React from "react";
import type { RouteChildrenProps } from "react-router";
import { withRouter } from "react-router";
import { login } from "../../auth/auth";
import { compose } from "../../components/compose/WlyCompose";

import ErrorFeedback from "../../components/layout/feedback/error";
import Feednack from "../../components/layout/feedback/feedback";
import { LoadingLogo } from "../../components/layout/feedback/loadinglogo";
import type { AsyncData } from "../../helpers/typescriptHelpers";
import { match } from "../../helpers/typescriptHelpers";
import type { IUser } from "../../interfaces/user";
import { decodePageLocation } from "../../services/authService";
import StorageService from "../../services/StorageService";
import type { AuthStoreProps } from "../../store/authStore";
import type { WorkspaceDatatoreProps } from "../../store/dataStores/workspace/workspaceDatastore";
import type { UserStoreProps } from "../../store/userStore";
import { orgStorageKey } from "../orgs/WithOrg";
import type { LoginSearchSettings } from "./Callback";

interface ILoginProps {}

type ILoginState = AsyncData<undefined>;

type Props = ILoginProps &
  RouteChildrenProps<{}, LoginSearchSettings | undefined> &
  UserStoreProps &
  AuthStoreProps &
  WorkspaceDatatoreProps;

const getV2DefaultOrgSlug = (user: IUser) => {
  const lastVisitedOrgFromStorage = StorageService.getItem(orgStorageKey(user));
  const lastVisitedOrg =
    lastVisitedOrgFromStorage && typeof lastVisitedOrgFromStorage === "string"
      ? user?.roles?.find((r) => r.org.id === lastVisitedOrgFromStorage)
      : undefined;
  const firstOrg = user?.roles?.[0];
  const org = lastVisitedOrg ? lastVisitedOrg : firstOrg;
  return org;
};

class Login extends React.Component<Props, ILoginState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      status: "initial",
    };
  }

  public componentDidMount() {
    const {
      location: { state },
      history,
      userStore,
    } = this.props;

    // when loading this component it is supposed to get a state coming from the Callback component
    // this state contains the response from hydra on the state of the auth

    const values = (state || {}) as LoginSearchSettings;

    if (values.code) {
      // if hydra gives us a code then it means that the auth was successfull
      // we then try to use this code to get a token; if the code has already been used it will throw an error
      // if we get a token (after login) we can fetch the current user
      this.setState({ status: "loading" });
      this.props.authStore
        .login(values.code)
        .then((r) => {
          return userStore
            .getUser()
            .then(async () => {
              const org = getV2DefaultOrgSlug(userStore.user);
              const key = orgStorageKey(userStore.user);
              this.setState({ status: "success" });
              // the auth has been successfull and we redirect the user to the asked page
              // we don't want the auth process to loop forever therefore if the redirect url
              // if /login or /callback? .... we redirect to the main page
              const state = decodePageLocation(values.state);
              if (state !== "/login" && !state.startsWith("/callback")) {
                if (org && state === "/") {
                  StorageService.setItem({ [key]: org.org.id });
                  history.replace(`/${org.org.slug}/`);
                } else {
                  history.replace(state);
                }
              } else {
                if (org) {
                  StorageService.setItem({ [key]: org.org.id });
                  history.replace(`/${org.org.slug}/`);
                } else {
                  history.replace("/");
                }
              }
            })
            .catch((err) => {
              // we couldn't get the user info from the auth process
              // we warn the user and put a blocking error message
              this.setState({
                status: "error",
                error: new Error(
                  "There was an error fetching your user information"
                ),
              });
            });
        })
        .catch((err) => {
          // the auth has not been successfull, usually the code has expired or has already been used.
          // We then try the process again by reseting the state and letting render redirect
          // we reset the state because when the user reloads the state is kept by the browser
          history.replace(this.props.location.pathname, null);
          this.setState({
            status: "error",
            error: new Error(
              err && err.error_description
                ? err.error_description
                : "Error requesting access and refresh token"
            ),
          });
        });
    } else {
      if (values.error) {
        // we go a error from the callback meaning that hydra hasn't validated our ask to be logged in
        // we then logout the user so he can log back in
        history.replace(this.props.location.pathname, null);
        this.setState({
          status: "error",
          error: new Error(
            `${values.error}\n ${values.error_description}\n\n ${values.error_hint} \n\n You will be logged out in 1 second.`
          ),
        });
        setTimeout(() => {
          window.location.href = `${window.WHALY_CONSTANTS.AUTH_SERVER_URL}/oauth2/sessions/logout`;
        }, 1000);
      } else {
        // the callback component has not passed any value on the state meaning
        // that the user has reloaded the page on /login or /callback without doing the full flow
        // we remove the remaining state and the render component will trigger a redirect
        history.replace(this.props.location.pathname, null);
        this.setState({
          status: "error",
          error: new Error("missing state value"),
        });
      }
    }
  }

  public renderFromState = (state: ILoginState) => {
    return match<undefined, React.ReactNode>(
      {
        initial: () => (
          <Feednack>
            <div>Trying to log you in</div>
          </Feednack>
        ),
        loading: () => <LoadingLogo />,
        success: (data) => (
          <Feednack>
            <div>
              You are successfully logged in. Please wait while we are
              redirecting you to the desired page
            </div>
          </Feednack>
        ),
        error: (error) => {
          // if there are no state in the location it means that we retry the auth process
          if (!this.props.location.state) {
            window.location.href = login(
              `${window.location.pathname}${window.location.search}${window.location.hash}`
            );

            return (
              <Feednack>
                <LoadingLogo />
              </Feednack>
            );
          }
          return <ErrorFeedback err={error} />;
        },
      },
      state
    );
  };

  public render() {
    return this.renderFromState(this.state);
  }
}

export default compose<Props, ILoginProps>(withRouter)(
  inject("userStore", "authStore", "workspaceDatastore")(observer(Login))
);
