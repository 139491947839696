import { Progress } from "antd";
import { WlyCheckIcon } from "../../../../../../../../../../components/icons/custom-icons/WlyCheckIcon";
import { WlyHearbeatIcon } from "../../../../../../../../../../components/icons/custom-icons/WlyHearbeatIcon";

interface IProgressSparklineProps {
  theme: { background: string; primary: string; secondary: string };
  size: number;
  rawValue: number;
}

export function ProgressSparkline(props: IProgressSparklineProps) {
  const { size, theme, rawValue } = props;
  return (
    <Progress
      strokeColor={theme.primary}
      success={{
        strokeColor: theme.primary,
      }}
      trailColor={theme.secondary}
      format={() => {
        if (rawValue >= 1) {
          return <WlyCheckIcon style={{ color: theme.primary }} />;
        }
        return <WlyHearbeatIcon style={{ color: theme.primary }} />;
      }}
      size={size}
      strokeWidth={6}
      type="circle"
      percent={Math.round(rawValue * 100)}
    />
  );
}
