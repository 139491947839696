import { Button, type ButtonProps } from "antd";
import { compose } from "../../../../../../../../components/compose/WlyCompose";
import { WlyDynamicIcon } from "../../../../../../../../components/icons/dynamic-renderer/WlyDynamicIconRenderer";
import {
  type IObject,
  type IObjectRegisteredAction,
} from "../../../../../../../../interfaces/object";
import WithOrg, {
  type InjectedOrgProps,
} from "../../../../../../../orgs/WithOrg";
import { getObjectColumns } from "../../../../../object/domain";
import { type IRecord } from "../../../../domain";
import { getParsedDoc } from "../../../email-widgets/text/domain";
import renderTreeNodeAsText from "../../generated-text/renderAsText";
import { convertKeyToMarkdocVariable } from "../../markdown/domain";
import { nodesConfig } from "../../markdown/widget/markdoc-react-component/config";

export interface ILinkActionButtonRendererProps {
  conf: {
    linkTemplate?: string;
  };
  contextKey: string;
  object: IObject;
  record: IRecord;
  buttonProps: ButtonProps;
  display: "ICON" | "TEXT";
  registeredAction: IObjectRegisteredAction;
}

type Props = ILinkActionButtonRendererProps & InjectedOrgProps;

function LinkActionButtonRenderer(props: Props) {
  const {
    conf,
    record,
    buttonProps,
    display,
    registeredAction,
    object,
    contextKey,
    user,
  } = props;

  const columns = getObjectColumns(object);
  const formattedRecord = Object.keys(record).reduce((acc, v) => {
    return {
      ...acc,
      [convertKeyToMarkdocVariable(v)]: record[v],
    };
  }, {});
  const source = conf.linkTemplate ?? "";
  const content = getParsedDoc(
    source,
    formattedRecord,
    user,
    columns,
    nodesConfig
  );
  const text = renderTreeNodeAsText(content);

  return (
    <Button
      {...buttonProps}
      target="_blank"
      href={text}
      icon={
        display === "ICON" ? (
          <WlyDynamicIcon
            name={
              registeredAction.icon ? registeredAction.icon : "DislikeFilled"
            }
          />
        ) : undefined
      }
    >
      {display === "TEXT" ? registeredAction.displayName : undefined}
    </Button>
  );
}

export default compose<Props, ILinkActionButtonRendererProps>(WithOrg)(
  LinkActionButtonRenderer
);
