import _ from "lodash";
import type { IObject } from "../interfaces/object";
import type { IDataset } from "../interfaces/sources";
import GraphQLService from "../services/graphql/GraphQLService";

export const userCanDeleteDataset = (dataset: IDataset) => {
  if (["SQL", "FLOW"].includes(dataset.type)) {
    return true;
  }
  if (dataset.source.sourceMeta.executor === "WAREHOUSE") {
    return true;
  }
  return false;
};

type DatasetUsageOptions = {
  datasetId: string;
  datasetName: string;
  orgId: string;
  sourceName?: string;
};

export const getDatasetUsage = async (options: DatasetUsageOptions) => {
  try {
    const data = await GraphQLService<{
      Dataset: IDataset;
      allDatasets: IDataset[];
      allObjects: IObject[];
    }>(
      `
        query getDatasetDependencies(
          $datasetId: ID!
          $orgId: ID!
          $tableNameReplacement: String!
          $datasetNameReplacement: String!
          $rawNameReplacement: String!
        ) {

          Dataset(where: { id: $datasetId }) {
            views(where: { deleted_not: true }) {
              id
              table(
                where: {
                  AND: [
                    { deleted_not: true }
                    { exploration: { deleted_not: true } }
                  ]
                }
              ) {
                exploration {
                  id
                  slug
                  name
                }
              }
            }
          }
          allDatasets(
            where: {
              deleted_not: true
              OR: [
                { sql_contains: $tableNameReplacement }
                { sql_contains: $datasetNameReplacement }
                { sql_contains: $rawNameReplacement }
              ]
              org: { id: $orgId }
            }
          ) {
            id
            slug
            name
          }
          allObjects(
            where: {
              AND: [
                { org: { id: $orgId } }
                { model: { id: $datasetId } }
                { isDeleted_not: true }
              ]
            }
          ) {
            id
          }
        }
      `,
      {
        datasetId: options.datasetId,
        orgId: options.orgId,
        tableNameReplacement: `$\{TABLES["${options.sourceName}"]["${options.datasetName}"]}`,
        datasetNameReplacement: `$\{DATASETS["${options.datasetId}"]}`,
        rawNameReplacement: `$\{RAW["${options.datasetId}"]}`,
      }
    );
    return {
      explorations: _.uniq(
        data.Dataset?.views?.flatMap((v) => {
          return v.table.map((t) => {
            return t.exploration;
          });
        })
      ),
      models: data.allDatasets,
      objects: data.allObjects,
    };
  } catch (error) {
    console.warn(error);
    throw Error(error);
  }
};
