import { gql } from "@apollo/client";
import type { MutateOptions } from "../../../../../components/hooks/query/useQGLMutate";
import { useGQLMutate } from "../../../../../components/hooks/query/useQGLMutate";

type MutationData = { createTopic: { id: string } };

type MutationVariables = {
  data: {
    name: string;
    apiName: string;
    topicGroup: { connect: { id: string } };
    org: { connect: { id: string } };
  };
};

const CreateTopicMutation = gql`
  mutation CreateTopic($data: TopicCreateInput!) {
    createTopic(data: $data) {
      id
    }
  }
`;

export const useCreateTopic = (options?: MutateOptions<MutationData>) => {
  return useGQLMutate<MutationData, MutationVariables>(
    CreateTopicMutation,
    options
  );
};
