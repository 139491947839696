import type { DocumentNode } from "graphql";
import { useState } from "react";
import type { AsyncData } from "../../../helpers/typescriptHelpers";
import GraphQLService from "../../../services/graphql/GraphQLService";

export type MutateOptions<TData = any> = {
  /** (optional) A callback function that's called when your query successfully completes with zero errors.
   * Useful for displaying toast messages on query success. */
  onSuccess?: (data: TData) => void;
  /** (optional) A callback function that's called when the query encounters one or more errors
   * Useful for displaying toast messages on query error. */
  onError?: (error: Error) => void;
};

type MutationOptions<TVariable> = { variables: TVariable };

export function useGQLMutate<TData, TVariable = Record<string, any>>(
  query: DocumentNode,
  options?: MutateOptions<TData>
) {
  const [data, setData] = useState<AsyncData<TData>>({
    status: "initial",
  });

  const mutateAsync = async (mutationOptions: MutationOptions<TVariable>) => {
    try {
      setData({ status: "loading" });
      const response = await GraphQLService<TData>(
        query,
        mutationOptions.variables
      );
      setData({ status: "success", data: response });
      options?.onSuccess?.(response);
      return response;
    } catch (err) {
      const error = err instanceof Error ? err : new Error(err);
      console.error(error);
      setData({
        status: "error",
        error: error,
      });
      options?.onError?.(error);
      throw error;
    }
  };

  const mutate = (mutationOptions: MutationOptions<TVariable>) => {
    try {
      mutateAsync(mutationOptions);
    } catch (error) {}
  };

  return {
    /** The mutation function you can call with variables to trigger the mutation and optionally hooks on additional callback options. */
    mutate,
    /** Similar to mutate but returns a promise which can be awaited. */
    mutateAsync,
    /** An object containing the result of your GraphQL query after it completes.
     * This value might be undefined if a query results in one or more errors */
    data: data.status === "success" ? data.data : undefined,
    /** If true, the query is still in flight and results have not yet been returned. */
    loading: data.status === "loading",
    /** If the query produces an error, this object contains the associated error. Otherwise, this value is undefined. */
    error: data.status === "error" ? data.error : undefined,
    /** A function that you can call to reset the mutation's result to its initial, uncalled state. */
    reset: () => setData({ status: "initial" }),
  };
}
