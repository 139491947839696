import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";
import { Form, Input, Space } from "antd";
import * as React from "react";
import ReactQuill from "react-quill";
import Delayed from "../../../../../components/delayed/delayed";
import { SwitchIcons } from "../../../../../components/form/elements/switch-icons/SwitchIcons";
import type { ITile } from "../../../../../interfaces/reports";
import ChartOptionLine from "../../../../chart-options/components/ChartOptionLine";
interface IEditTilePanelProps {
  tile: ITile;
  tileOptions?: ITileOptions;
  onChange: (v: ITileOptions) => void;
}

export interface ITileOptions {
  hide_name?: boolean;
  hide_card?: boolean;
  description: string;
  name: string;
}

export function EditTilePanel(props: IEditTilePanelProps) {
  const { tileOptions, onChange, tile } = props;
  const [form] = Form.useForm();

  React.useEffect(() => {
    form.resetFields();
  }, [tile.id]);

  const popover = {
    title: "Title and description",
    overlayStyle: {
      width: 400,
    },
    content: (
      <Delayed delay={100}>
        <Space direction="vertical" style={{ width: "100%" }}>
          Title:
          <Form.Item name={["name"]} noStyle>
            <Input />
          </Form.Item>
          Description:
          <Form.Item name={["description"]} noStyle>
            <ReactQuill
              formats={[
                "header",
                "bold",
                "italic",
                "underline",
                "strike",
                "blockquote",
                "list",
                "bullet",
                "indent",
                "link",
                "image",
                "align",
              ]}
              theme="snow"
              modules={{
                toolbar: [
                  ["bold", "italic", "underline"],
                  [{ list: "ordered" }, { list: "bullet" }],
                  ["link"],
                ],
              }}
            />
          </Form.Item>
        </Space>
      </Delayed>
    ),
  };

  return (
    <Form
      layout="vertical"
      form={form}
      initialValues={tileOptions ? tileOptions : { name: "" }}
      onFieldsChange={(e) => {
        if (onChange) {
          // react quill does not correctly clear the field value
          // https://github.com/zenoamaro/react-quill/issues/698
          if (form.getFieldValue("description") === "<p><br></p>") {
            form.setFieldsValue({
              description: "",
            });
          }
          onChange(form.getFieldsValue());
        }
      }}
    >
      <Space direction="vertical" style={{ width: "100% " }}>
        <ChartOptionLine
          items={[
            {
              flex: 1,
              content: "Title and description",
              popover: popover,
            },
            {
              flex: 0,
              type: "edit",
              popover: popover,
            },
          ]}
        />
        <ChartOptionLine
          items={[
            {
              flex: 1,
              content: "Background",
            },
            {
              flex: 0,
              content: (
                <Form.Item label={"Hide card"} name={["hide_card"]} noStyle>
                  <SwitchIcons
                    icons={{
                      true: <EyeInvisibleOutlined />,
                      false: <EyeOutlined />,
                    }}
                  />
                </Form.Item>
              ),
            },
          ]}
        />
      </Space>
    </Form>
  );
}
