import { EditOutlined } from "@ant-design/icons";
import type { Query } from "@cubejs-client/core";
import type { FormInstance } from "antd";
import {
  Button,
  Form,
  Input,
  InputNumber,
  Modal,
  Select,
  Space,
  Typography,
} from "antd";
import { useState } from "react";
import { compose } from "../../../../../../../../components/compose/WlyCompose";
import type { AvailableDimension } from "../../../../../../../../components/measures/filter-item/FilterItem";
import { ColumnsAddition } from "../../../../../../../../components/widget-options/ColumnsAddition";
import { SingleColumnAddition } from "../../../../../../../../components/widget-options/SingleColumnAddition";
import type {
  IObject,
  IObjectProperty,
} from "../../../../../../../../interfaces/object";
import {
  LagoonCallOrigin,
  lagoonServiceLoad,
} from "../../../../../../../../services/LagoonService";
import { ChartOptionCollapse } from "../../../../../../../chart-options/components/ChartOptionCollapse";
import ChartOptionLine from "../../../../../../../chart-options/components/ChartOptionLine";
import type { InjectedOrgProps } from "../../../../../../../orgs/WithOrg";
import WithOrg from "../../../../../../../orgs/WithOrg";
import {
  getObjectColumns,
  isAvailableProperty,
} from "../../../../../object/domain";
import PropertySort from "../../../../../object/viewer/toolbar/PropertiesSelector/PropertySortSelector";
import type { IRecord } from "../../../../domain";
import type { IWidget } from "../../../domain";
import { convertKeyToMarkdocVariable } from "../../markdown/domain";
import { MarkdocEditor } from "../../markdown/editor/MarkdocEditor";
import { FilterEditor } from "../../related-lists/editor/FilterEditor";
import type { IWidgetSuggestedProductsConfig } from "../domain";
import { open } from "fs/promises";

interface IWidgetRelatedListEditorProps {
  widget: IWidget;
  object: IObject;
  record: IRecord;
  conf: IWidgetSuggestedProductsConfig;
  form: FormInstance;
}

type Props = IWidgetRelatedListEditorProps & InjectedOrgProps;

function WidgetSuggestedProductsEditor(props: Props) {
  const { widget, object, record, conf, form, org } = props;

  const [openPrimary, setPrimaryOpen] = useState<boolean>(false);
  const [openSecondary, setSecondaryOpen] = useState<boolean>(false);

  const canDisplayAdditionalProperties = (): [
    string | undefined,
    IObjectProperty | undefined
  ] => {
    const currentPropertyId: string | undefined = form.getFieldValue([
      "config",
      "foreignObjectPropertyId",
    ]);
    const propertyExists: IObjectProperty | undefined = currentPropertyId
      ? object.foreignKeys.find((fk) => fk.id === currentPropertyId)
      : undefined;

    return [currentPropertyId, propertyExists];
  };

  return (
    <>
      <ChartOptionCollapse dark title="Product suggestions configuration">
        <Space style={{ width: "100%" }} size="middle" direction="vertical">
          <ChartOptionLine
            items={[
              {
                content: (
                  <Form.Item
                    label="Related Object"
                    required
                    style={{ marginBottom: 4 }}
                    name={["config", "foreignObjectPropertyId"]}
                  >
                    <Select
                      optionLabelProp="label"
                      style={{ width: "100%", maxWidth: "100%" }}
                      popupMatchSelectWidth={false}
                    >
                      {object.foreignKeys.map((ok) => {
                        return (
                          <Select.Option
                            label={ok.object.name}
                            key={ok.id}
                            value={ok.id}
                          >
                            <div
                              style={{
                                width: "100%",
                                maxWidth: "100%",
                                overflow: "hidden",
                              }}
                            >
                              {ok.object.name}{" "}
                              <Typography.Text type="secondary">
                                on {ok.label}
                              </Typography.Text>
                            </div>
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                ),
                flex: 1,
              },
            ]}
          />
          {/* Category */}
          <Form.Item noStyle shouldUpdate>
            {() => {
              const [currentPropertyId, propertyExists] =
                canDisplayAdditionalProperties();

              if (currentPropertyId && propertyExists) {
                const availableColumns = getObjectColumns(
                  propertyExists.object
                ).filter((c) => c.type === "property");
                return (
                  <ChartOptionLine
                    items={[
                      {
                        content: (
                          <Form.Item
                            label="Split by"
                            style={{ marginBottom: 4 }}
                            name={["config", "options", "categoryKey"]}
                          >
                            <SingleColumnAddition
                              availableColumns={availableColumns}
                            />
                          </Form.Item>
                        ),
                        flex: 1,
                      },
                    ]}
                  />
                );
              } else {
                return (
                  <Form.Item
                    noStyle
                    hidden
                    name={["config", "options", "categoryLabel"]}
                  />
                );
              }
            }}
          </Form.Item>

          {/* Sort by */}
          <Form.Item noStyle shouldUpdate>
            {() => {
              const [currentPropertyId, propertyExists] =
                canDisplayAdditionalProperties();

              if (currentPropertyId && propertyExists) {
                const availableColumns = getObjectColumns(
                  propertyExists.object
                );

                return (
                  <ChartOptionLine
                    items={[
                      {
                        content: (
                          <Form.Item
                            style={{ marginBottom: 4 }}
                            label={"Sort"}
                            name={["config", "options", "sortBy"]}
                          >
                            <PropertySort columns={availableColumns} />
                          </Form.Item>
                        ),
                        flex: 1,
                      },
                    ]}
                  />
                );
              }
              return (
                <Form.Item
                  noStyle
                  hidden
                  name={["config", "options", "sortBy"]}
                />
              );
            }}
          </Form.Item>
          {/* Filters */}
          <Form.Item noStyle shouldUpdate>
            {() => {
              const [currentPropertyId, propertyExists] =
                canDisplayAdditionalProperties();

              if (currentPropertyId && propertyExists) {
                const availableColumns = getObjectColumns(
                  propertyExists.object
                );
                return (
                  <ChartOptionLine
                    items={[
                      {
                        content: (
                          <Form.Item
                            required
                            noStyle
                            name={["config", "options", "filters"]}
                          >
                            <FilterEditor
                              autocomplete={async (
                                dimension,
                                operator,
                                value
                              ) => {
                                try {
                                  const dim = availableColumns
                                    .filter(isAvailableProperty)
                                    .find(
                                      (ac) =>
                                        ac.type === "property" &&
                                        dimension === ac.data.key
                                    );

                                  if (!dim) {
                                    throw new Error("Dim not found");
                                  }

                                  const dimKey = dim.data.sortAndFilterKey;
                                  if (!dimKey) {
                                    throw new Error("Dim not found");
                                  }

                                  let query: Query = {
                                    dimensions: [dimKey],
                                    limit: 50,
                                    filters: [
                                      {
                                        member: dimKey,
                                        operator: "set",
                                      },
                                    ],
                                  };
                                  if (
                                    typeof value === "string" &&
                                    value !== "" &&
                                    operator
                                  ) {
                                    query = {
                                      dimensions: [dimKey],
                                      limit: 50,
                                      filters: [
                                        {
                                          member: dimKey,
                                          operator: operator,
                                          values: [value],
                                        },
                                      ],
                                    };
                                  }

                                  const data = await lagoonServiceLoad(
                                    org.id,
                                    query,
                                    "OBJECT",
                                    object.id,
                                    undefined,
                                    LagoonCallOrigin.WHALY_APP
                                  );

                                  const results = data.tablePivot();

                                  return results.map((r) =>
                                    r[dimKey]?.toString?.()
                                  );
                                } catch (err) {
                                  console.error(err);
                                  throw new Error(
                                    "Can't fetch recommendations"
                                  );
                                }
                              }}
                              availableDimensions={availableColumns
                                .filter(isAvailableProperty)
                                .map<AvailableDimension>((ac) => {
                                  const prop = ac.data;
                                  return {
                                    key: prop.key,
                                    label: prop.label,
                                    description: prop.description,
                                    type: "standard",
                                    domain: prop.domain,
                                  };
                                })}
                            />
                          </Form.Item>
                        ),
                        flex: 1,
                      },
                    ]}
                  />
                );
              } else {
                return (
                  <Form.Item
                    noStyle
                    hidden
                    name={["config", "options", "filters"]}
                  />
                );
              }
            }}
          </Form.Item>
          {/* Limit */}
          <Form.Item noStyle shouldUpdate>
            {() => {
              const [currentPropertyId, propertyExists] =
                canDisplayAdditionalProperties();

              if (currentPropertyId && propertyExists) {
                return (
                  <ChartOptionLine
                    items={[
                      {
                        content: "Limit",
                        flex: 1,
                      },
                      {
                        content: (
                          <Form.Item
                            noStyle
                            name={["config", "options", "limit"]}
                          >
                            <InputNumber
                              placeholder={"500"}
                              style={{ width: "100%" }}
                            />
                          </Form.Item>
                        ),
                        flex: 1,
                      },
                    ]}
                  />
                );
              }
              return (
                <Form.Item
                  noStyle
                  hidden
                  name={["config", "options", "limit"]}
                />
              );
            }}
          </Form.Item>
        </Space>
      </ChartOptionCollapse>

      <ChartOptionCollapse dark title="Product card configuration">
        <Space style={{ width: "100%" }} direction="vertical" size={"middle"}>
          {/* Product */}
          <Form.Item noStyle shouldUpdate>
            {() => {
              const [currentPropertyId, propertyExists] =
                canDisplayAdditionalProperties();

              if (currentPropertyId && propertyExists) {
                const availableColumns = getObjectColumns(
                  propertyExists.object
                ).filter((c) => c.type === "property");
                return (
                  <ChartOptionLine
                    items={[
                      {
                        content: (
                          <Form.Item
                            label="Product Label"
                            required
                            style={{ marginBottom: 4 }}
                            name={["config", "options", "productKey"]}
                          >
                            <SingleColumnAddition
                              availableColumns={availableColumns}
                            />
                          </Form.Item>
                        ),
                        flex: 1,
                      },
                    ]}
                  />
                );
              } else {
                return (
                  <Form.Item
                    noStyle
                    hidden
                    name={["config", "options", "productKey"]}
                  />
                );
              }
            }}
          </Form.Item>
          {/* Fallback image */}
          <Form.Item noStyle shouldUpdate>
            {() => {
              const [currentPropertyId, propertyExists] =
                canDisplayAdditionalProperties();

              if (currentPropertyId && propertyExists) {
                return (
                  <ChartOptionLine
                    items={[
                      {
                        content: (
                          <Form.Item
                            label="Fallback image"
                            style={{ marginBottom: 4 }}
                            name={["config", "options", "fallbackImageSrc"]}
                          >
                            <Input />
                          </Form.Item>
                        ),
                        flex: 1,
                      },
                    ]}
                  />
                );
              }
              return (
                <Form.Item
                  noStyle
                  hidden
                  name={["config", "options", "fallbackImageSrc"]}
                />
              );
            }}
          </Form.Item>
          {/* Action */}
          <Form.Item noStyle shouldUpdate>
            {() => {
              const [currentPropertyId, propertyExists] =
                canDisplayAdditionalProperties();

              if (currentPropertyId && propertyExists) {
                const availableActions =
                  propertyExists.object.registeredActions;
                return (
                  <ChartOptionLine
                    items={[
                      {
                        content: (
                          <Form.Item
                            label="Displayed actions"
                            style={{ marginBottom: 4 }}
                            name={["config", "options", "displayedAction"]}
                          >
                            <Select allowClear>
                              {availableActions.map((a) => {
                                return (
                                  <Select.Option key={a.id} value={a.id}>
                                    {a.name}
                                  </Select.Option>
                                );
                              })}
                            </Select>
                          </Form.Item>
                        ),
                        flex: 1,
                      },
                    ]}
                  />
                );
              } else {
                return (
                  <Form.Item
                    noStyle
                    hidden
                    name={["config", "options", "displayedAction"]}
                  />
                );
              }
            }}
          </Form.Item>

          {/* Badge label */}
          <Form.Item noStyle shouldUpdate>
            {() => {
              const [currentPropertyId, propertyExists] =
                canDisplayAdditionalProperties();

              if (currentPropertyId && propertyExists) {
                const availableColumns = getObjectColumns(
                  propertyExists.object
                ).filter((c) => c.type === "property");
                return (
                  <ChartOptionLine
                    items={[
                      {
                        content: (
                          <Form.Item
                            label="Badges"
                            style={{ marginBottom: 4 }}
                            name={["config", "options", "badgeLabel"]}
                          >
                            <ColumnsAddition
                              availableColumns={availableColumns}
                              max={1}
                            />
                          </Form.Item>
                        ),
                        flex: 1,
                      },
                    ]}
                  />
                );
              } else {
                return (
                  <Form.Item
                    noStyle
                    hidden
                    name={["config", "options", "badgeLabel"]}
                  />
                );
              }
            }}
          </Form.Item>

          {/* Top text */}
          <Form.Item noStyle shouldUpdate>
            {() => {
              const [currentPropertyId, propertyExists] =
                canDisplayAdditionalProperties();

              if (currentPropertyId && propertyExists) {
                const availableColumns = getObjectColumns(
                  propertyExists.object
                );

                return (
                  <ChartOptionLine
                    items={[
                      {
                        content: "Top content",
                        flex: 1,
                      },
                      {
                        content: (
                          <>
                            <Form.Item
                              noStyle
                              hidden
                              name={["config", "options", "primaryText"]}
                            />
                            <Button
                              onClick={() => setPrimaryOpen(true)}
                              size="small"
                              shape="circle"
                              type="text"
                              icon={<EditOutlined />}
                            />
                            <Modal
                              open={openPrimary}
                              onCancel={() => setPrimaryOpen(false)}
                              destroyOnClose
                              maskClosable={false}
                              className="markdown-modal"
                              title={"Edit text"}
                              width={"50%"}
                              okText={"Save"}
                              onOk={() => {
                                setPrimaryOpen(false);
                              }}
                            >
                              <Form.Item
                                noStyle
                                name={["config", "options", "primaryText"]}
                              >
                                <MarkdocEditor
                                  columnsSuggestions={availableColumns.map(
                                    (c) => ({
                                      key: `$${convertKeyToMarkdocVariable(
                                        c.data.key
                                      )}`,
                                      label: c.data.label,
                                    })
                                  )}
                                />
                              </Form.Item>
                            </Modal>
                          </>
                        ),
                        flex: `0 auto`,
                      },
                    ]}
                  />
                );
              }
              return (
                <Form.Item
                  noStyle
                  hidden
                  name={["config", "options", "primaryText"]}
                />
              );
            }}
          </Form.Item>

          {/* Secondary text - to be removed */}
          <Form.Item noStyle shouldUpdate>
            {() => {
              const [currentPropertyId, propertyExists] =
                canDisplayAdditionalProperties();

              if (currentPropertyId && propertyExists) {
                const availableColumns = getObjectColumns(
                  propertyExists.object
                );

                return (
                  <ChartOptionLine
                    items={[
                      {
                        content: "Bottom content",
                        flex: 1,
                      },
                      {
                        content: (
                          <>
                            <Form.Item
                              noStyle
                              hidden
                              name={["config", "options", "secondaryText"]}
                            />
                            <Button
                              onClick={() => setSecondaryOpen(true)}
                              size="small"
                              shape="circle"
                              type="text"
                              icon={<EditOutlined />}
                            />
                            <Modal
                              open={openSecondary}
                              onCancel={() => setSecondaryOpen(false)}
                              destroyOnClose
                              maskClosable={false}
                              className="markdown-modal"
                              title={"Edit text"}
                              width={"50%"}
                              okText={"Save"}
                              onOk={() => {
                                setSecondaryOpen(false);
                              }}
                            >
                              <Form.Item
                                noStyle
                                name={["config", "options", "secondaryText"]}
                              >
                                <MarkdocEditor
                                  columnsSuggestions={availableColumns.map(
                                    (c) => ({
                                      key: `$${convertKeyToMarkdocVariable(
                                        c.data.key
                                      )}`,
                                      label: c.data.label,
                                    })
                                  )}
                                />
                              </Form.Item>
                            </Modal>
                          </>
                        ),
                        flex: `0 auto`,
                      },
                    ]}
                  />
                );
              }
              return (
                <Form.Item
                  noStyle
                  hidden
                  name={["config", "options", "secondaryText"]}
                />
              );
            }}
          </Form.Item>
        </Space>
      </ChartOptionCollapse>
    </>
  );
}

export default compose<Props, IWidgetRelatedListEditorProps>(WithOrg)(
  WidgetSuggestedProductsEditor
);
