import { FileAddOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Col, Empty } from "antd";
import { inject, observer } from "mobx-react";
import React from "react";
import type { RouteComponentProps } from "react-router";
import { withRouter } from "react-router";
import Logo from "../../../../../assets/logo_inverted.svg";
import { compose } from "../../../../../components/compose/WlyCompose";
import Aligner from "../../../../../components/layout/aligner/Aligner";
import Feednack from "../../../../../components/layout/feedback/feedback";
import type { WorkbenchUIStoreProps } from "../../../../../store/workbenchUIStore";
import { CatalogModal } from "../../../../catalog/CatalogModal";
import type { InjectedOrgProps } from "../../../../orgs/WithOrg";
import WithOrg from "../../../../orgs/WithOrg";

interface IEmptyViewerProps {}

type Props = IEmptyViewerProps &
  RouteComponentProps &
  InjectedOrgProps &
  WorkbenchUIStoreProps;

function EmptyViewer(props: Props) {
  const { warehouses } = props;

  const [showCatalog, setShowCatalog] = React.useState<boolean>(false);

  if (warehouses.length === 0) {
    return (
      <Aligner>
        <Feednack>
          <Empty
            description={
              "You don't have any data yet, please add a warehouse to get started."
            }
          >
            <Button type="primary" onClick={() => setShowCatalog(true)}>
              Connect a warehouse
            </Button>
          </Empty>
        </Feednack>
        <CatalogModal
          open={showCatalog}
          onClose={() => setShowCatalog(false)}
          type="warehouse"
        />
      </Aligner>
    );
  }

  return (
    <Aligner>
      <Feednack>
        <Col>
          <div>
            <img src={Logo} alt="Whaly Logo" width={250} />
          </div>
          <Button
            type="link"
            onClick={() => props.workbenchUIStore.setSourceEditionOpen(true)}
          >
            <PlusOutlined /> Import data
          </Button>
          <Button
            type="link"
            onClick={() => props.workbenchUIStore.setDatasetEditionOpen(true)}
          >
            <FileAddOutlined /> Create a model
          </Button>
        </Col>
      </Feednack>
    </Aligner>
  );
}

export default compose<Props, IEmptyViewerProps>(
  WithOrg,
  withRouter
)(inject("workbenchUIStore")(observer(EmptyViewer)));
