import DatasetNodeWidget from "./DatasetNodeWidget";
import { DatasetNodeModel } from "./DatasetNodeModel";
import * as React from "react";
import type {
  GenerateWidgetEvent,
  GenerateModelEvent,
} from "@projectstorm/react-canvas-core";
import { AbstractReactFactory } from "@projectstorm/react-canvas-core";
import type { DiagramEngine } from "@projectstorm/react-diagrams-core";

export class DatasetNodeFactory extends AbstractReactFactory<
  DatasetNodeModel,
  DiagramEngine
> {
  constructor() {
    super("data");
  }

  generateReactWidget(
    event: GenerateWidgetEvent<DatasetNodeModel>
  ): JSX.Element {
    return (
      <DatasetNodeWidget engine={this.engine} size={50} node={event.model} />
    );
  }

  generateModel(event: GenerateModelEvent) {
    return new DatasetNodeModel();
  }
}
