import { Avatar, Tooltip } from "antd";
import type { AvatarProps } from "antd/lib/avatar";
import * as React from "react";
import type { IUserGravatarInfo } from "../../../interfaces/user";
import "./UserAvatar.scss";

interface IUserAvatarProps extends AvatarProps {
  user?: IUserGravatarInfo;
  /**
   * Displays a tooltip containing the user name on hover
   */
  tooltip?: boolean;
  tooltipSuffixMessage?: string;
}

export default class UserAvatar extends React.Component<IUserAvatarProps> {
  public initialAvatar(avatarColor: string, letter: string) {
    const canvas = document.createElement("canvas");
    const width = 48;
    const height = 48;
    const devicePixelRatio = Math.max(window.devicePixelRatio, 1);
    canvas.width = width * devicePixelRatio;
    canvas.height = height * devicePixelRatio;
    canvas.style.width = `${width}px`;
    canvas.style.height = `${height}px`;

    const context = canvas.getContext("2d");
    if (context) {
      context.scale(devicePixelRatio, devicePixelRatio);
      context.rect(0, 0, canvas.width, canvas.height);
      context.fillStyle = avatarColor;
      context.fill();
      context.font = `${height / 2}px 'Lato'`;
      context.textAlign = "center";
      context.textBaseline = "middle";
      context.fillStyle = "#e7ecef";
      context.fillText(letter, width / 2, height / 2);
    }

    return canvas.toDataURL("image/png");
  }

  public render() {
    const { user, tooltip, tooltipSuffixMessage, ...rest } = this.props;

    if (!user) {
      return (
        <Avatar
          shape="circle"
          size="large"
          {...rest}
          src={this.initialAvatar("#69a2b0", "?")}
          alt={"unknown"}
        />
      );
    }

    const name = `${user.firstName} ${user.lastName}`;
    const src = user.gravatar;
    const avatarColor = user.avatarColor;

    const getFirstLetter = (n: string) => {
      return name.substr(0, 1).toLocaleUpperCase();
    };

    return (
      <Tooltip
        title={[name, tooltipSuffixMessage].join(" ")}
        overlayStyle={{
          visibility: tooltip ? "initial" : "hidden",
        }}
      >
        <Avatar
          shape="circle"
          size="large"
          {...rest}
          src={
            src ? src : this.initialAvatar(avatarColor, getFirstLetter(name))
          }
          alt={name}
        />
      </Tooltip>
    );
  }
}
