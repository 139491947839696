import { QuestionCircleOutlined } from "@ant-design/icons";
import { Form, Modal, Typography } from "antd";
import { useEffect, useState } from "react";
import type { InjectedAntUtilsProps } from "../../../../../../components/ant-utils/withAntUtils";
import { withAntUtils } from "../../../../../../components/ant-utils/withAntUtils";
import { compose } from "../../../../../../components/compose/WlyCompose";
import Loading from "../../../../../../components/layout/feedback/loading";
import type { SchemaResult } from "../../../../../../interfaces/transformations";
import { PKValidationRule, PrimaryKeyField } from "./PrimaryKeyField";

const { Text } = Typography;

interface Values {
  keys: string[];
}

interface IPrimaryKeysModalProps {
  visible: boolean;
  saving: boolean;
  showOutdatedKeys?: boolean;
  getInitialKeys?: () => string[];
  getSchema?: () => Promise<SchemaResult>;
  onSave: (values: Values) => Promise<void>;
  onCancel: () => void;
}

type Props = IPrimaryKeysModalProps & InjectedAntUtilsProps;

function PrimaryKeysModal(props: Props) {
  const {
    antUtils,
    visible,
    saving,
    showOutdatedKeys,
    getInitialKeys,
    getSchema,
    onCancel,
    onSave,
  } = props;
  const [loading, setLoading] = useState(true);
  const [schema, setSchema] = useState<SchemaResult>(undefined);
  const [form] = Form.useForm();

  useEffect(() => {
    if (!visible) {
      return setSchema(undefined);
    }
    const fetchSchema = async () => {
      try {
        setLoading(true);
        const schema = await getSchema();
        setSchema(schema);
      } catch (error) {
        console.warn(error);
        antUtils.message.error(
          "Error while loading the schema, please try again"
        );
      } finally {
        setLoading(false);
        form.resetFields();
      }
    };
    fetchSchema();
  }, [visible]);

  const renderInner = () => {
    if (!visible) return <></>;
    if (loading) return <Loading />;
    if (!schema) return <>At least 1 column required</>;

    const initialKeys = getInitialKeys();
    const outdatedKeys = initialKeys.filter(
      (k) => !Object.keys(schema).includes(k)
    );
    const availableKeys = initialKeys.filter((k) =>
      Object.keys(schema).includes(k)
    );

    return (
      <Form
        form={form}
        layout="vertical"
        initialValues={{
          keys: showOutdatedKeys ? initialKeys : availableKeys,
        }}
      >
        <Form.Item name="keys" label="Primary key(s)" rules={PKValidationRule}>
          <PrimaryKeyField schema={schema} outdatedKeys={outdatedKeys} />
        </Form.Item>
        <Text type="secondary">
          <QuestionCircleOutlined /> Select a key/combination of keys that
          returns a unique value for each row
        </Text>
      </Form>
    );
  };

  return (
    <Modal
      title="Set primary keys"
      open={visible}
      onCancel={onCancel}
      onOk={async () => {
        try {
          const values: Values = await form.validateFields();
          await onSave(values);
        } catch (error) {
          console.warn("Validate Failed:", error);
        }
      }}
      okButtonProps={{
        loading: saving,
      }}
    >
      {renderInner()}
    </Modal>
  );
}

export default compose<Props, IPrimaryKeysModalProps>(withAntUtils)(
  PrimaryKeysModal
);
