import { Segmented } from "antd";
import * as React from "react";
import type { SketchPickerProps } from "react-color";
import { SketchPicker } from "react-color";
import { WlyColorDropCrossedIcon } from "../../icons/custom-icons/WlyColorDropCrossedIcon";
import { WlyColorDropIcon } from "../../icons/custom-icons/WlyColorDropIcon";
import "./SketchPickerFormItem.scss";

interface ISketchPickerFormItemProps
  extends Omit<SketchPickerProps, "onChange"> {
  onChange?: (v: string) => void;
  allowClear?: boolean;
}

const SketchPickerFormItem: React.FunctionComponent<
  ISketchPickerFormItemProps
> = (props) => {
  const { onChange, ...rest } = props;

  const [fill, setFill] = React.useState<boolean>(props.color ? true : false);

  React.useEffect(() => {
    if (!fill && onChange) {
      onChange(null);
    }
  }, [fill]);

  return (
    <div className="sketch-picker-form-item">
      <div style={{ padding: "4px 12px 0px 12px" }} hidden={!props.allowClear}>
        <Segmented
          options={[
            {
              label: "Fill",
              icon: <WlyColorDropIcon />,
              value: "fill",
            },
            {
              label: "Don't fill",
              icon: <WlyColorDropCrossedIcon />,
              value: "dont",
            },
          ]}
          value={fill ? "fill" : "dont"}
          block
          onChange={(value) => {
            if (value === "fill") {
              setFill(true);
            } else {
              setFill(false);
            }
          }}
        />
      </div>
      <div style={{ position: "relative" }}>
        <SketchPicker
          {...rest}
          onChange={(c) => {
            if (!fill) {
              setFill(true);
            }
            if (onChange) {
              onChange(
                `rgba(${c.rgb.r}, ${c.rgb.g}, ${c.rgb.b}, ${
                  c.rgb.a ? c.rgb.a : 1
                })`
              );
            }
          }}
          color={props.color ?? "#fefefe"}
        />
      </div>
    </div>
  );
};

export default SketchPickerFormItem;
