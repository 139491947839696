import { Base64 } from "js-base64";
import queryString from "query-string";
import type { Index } from "./typescriptHelpers";

interface SearchSetting {
  paramName: string;
  defaultValue: any;
  deserialize: (query: Index<any>) => any;
  serialize: (value: any) => string;
  isValid: (query: Index<any>) => boolean;
}

export const encodeLagoonQueryForURL = (value: object) =>
  Base64.encode(JSON.stringify(value));

export const QUERY_SEARCH_SETTINGS: SearchSetting[] = [
  {
    paramName: "query",
    defaultValue: "",
    deserialize: (query) => {
      try {
        return query.query ? JSON.parse(Base64.decode(query.query)) : null;
      } catch (err) {
        console.error("error deserializing", query.query, err);
        return null;
      }
    },
    serialize: encodeLagoonQueryForURL,
    isValid: (query) => {
      try {
        if (query.query) {
          JSON.parse(Base64.decode(query.query));
        }
        return true;
      } catch {
        return false;
      }
    },
  },
];

export const FILTERS_SEARCH_SETTINGS: SearchSetting[] = [
  {
    paramName: "filters",
    defaultValue: "",
    deserialize: (query) => {
      try {
        return query.filters ? JSON.parse(Base64.decode(query.filters)) : null;
      } catch (err) {
        console.error("error deserializing", query.query, err);
        return null;
      }
    },
    serialize: encodeLagoonQueryForURL,
    isValid: (query) => {
      try {
        if (query.filters) {
          JSON.parse(Base64.decode(query.filters));
        }
        return true;
      } catch {
        return false;
      }
    },
  },
];

export const LOADQUERY_SEARCH_SETTINGS: SearchSetting[] = [
  {
    paramName: "loadQuery",
    defaultValue: true,
    deserialize: (query) => {
      try {
        return query.loadQuery ? JSON.parse(query.loadQuery) : false;
      } catch (err) {
        return false;
      }
    },
    serialize: (value: boolean) => JSON.stringify(value),
    isValid: (query) => {
      try {
        if (query.loadQuery) {
          JSON.parse(query.loadQuery);
        }
        return true;
      } catch {
        return false;
      }
    },
  },
];

export const TOKEN_SEARCH_SETTINGS: SearchSetting[] = [
  {
    paramName: "token",
    defaultValue: null,
    deserialize: (query: Index<string>) => query.token,
    serialize: (value: string) => value,
    isValid: (query: Index<string>) => !!query,
  },
];

export const ONBOARDING_SEARCH_SETTINGS: SearchSetting[] = [
  {
    paramName: "onboardingStatus",
    defaultValue: "",
    deserialize: (query) => {
      return query.onboardingStatus
        ? JSON.parse(Base64.decode(query.onboardingStatus))
        : null;
    },
    serialize: (value: object) => Base64.encode(JSON.stringify(value)),
    isValid: () => true,
  },
];

export const AUTHORIZATION_CODE_SEARCH_SETTINGS: SearchSetting[] = [
  {
    paramName: "code",
    defaultValue: "",
    deserialize: (query) => {
      return query.code;
    },
    serialize: (value: string) => value,
    isValid: () => true,
  },
];

export const PORTAL_JWT_TOKEN_SEARCH_SETTINGS: SearchSetting[] = [
  {
    paramName: "token",
    defaultValue: "",
    deserialize: (query) => {
      return query.token;
    },
    serialize: (value: string) => value,
    isValid: () => true,
  },
];

export const STATE_SEARCH_SETTINGS: SearchSetting[] = [
  {
    paramName: "state",
    defaultValue: "",
    deserialize: (query) => {
      return query.state;
    },
    serialize: (value: string) => value,
    isValid: () => true,
  },
];

export const OBJECT_VIEW_SLUG: SearchSetting[] = [
  {
    paramName: "view",
    defaultValue: "",
    deserialize: (query) => {
      return query.view;
    },
    serialize: (value: string) => value,
    isValid: () => true,
  },
];

export const AUTHORIZATION_ERROR_SEARCH_SETTINGS: SearchSetting[] = [
  {
    paramName: "error",
    defaultValue: "",
    deserialize: (query) => {
      return query.error;
    },
    serialize: (value: string) => value,
    isValid: () => true,
  },
  {
    paramName: "error_description",
    defaultValue: "",
    deserialize: (query) => {
      return query.error_description;
    },
    serialize: (value: string) => value,
    isValid: () => true,
  },
  {
    paramName: "error_hint",
    defaultValue: "",
    deserialize: (query) => {
      return query.error_hint;
    },
    serialize: (value: string) => value,
    isValid: () => true,
  },
];

// merge query with serialized params object
export const updateSearch = (
  search: string,
  params: Index<any>,
  settings?: SearchSetting[]
) => {
  const query = queryString.parse(search);

  if (!settings) {
    // No settings provided, basic search string constuction
    return queryString.stringify({
      ...query,
      ...params,
    });
  }

  const serializedParams = Object.keys(params).reduce((acc, paramName) => {
    const newAcc: any = acc;
    const setting = settings.find((s) => s.paramName === paramName);
    if (setting) {
      newAcc[paramName] = setting.serialize(params[paramName]);
    }
    return newAcc;
  }, {});
  return queryString.stringify({
    ...query,
    ...serializedParams,
  });
};

/**
 * @param {String} search location.search
 * @param {Array} settings (optional) type settings
 * @returns the parsed search string into object
 */
export function parseSearch<T = Index<any>>(
  search: string,
  settings?: SearchSetting[]
): T {
  const query = queryString.parse(search);
  if (!settings) return {} as T;
  return settings.reduce((acc, setting) => {
    return {
      ...acc,
      [setting.paramName]: setting.deserialize(query),
    };
  }, {}) as T;
}
