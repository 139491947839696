import type { Action } from "history";
import { useBeforeunload } from "react-beforeunload";
import { Prompt } from "react-router";

interface IRouterPromptProps {
  /**
   * Condition to activate the prompt
   */
  when: boolean;
  /**
   * List of actions to the prompt. Default to REPLACE
   */
  actions?: Action[];
}

/**
 * Warns the user when exiting, refreshing page or navigating in-app
 */
export const RouterPrompt = (props: IRouterPromptProps) => {
  const { when, actions } = props;
  useBeforeunload((event) => {
    if (when) {
      event.preventDefault();
    }
  });

  const targetActions: Action[] = actions ? actions : ["REPLACE"];

  return (
    <>
      <Prompt
        when={when}
        message={(location, action) => {
          if (targetActions.includes(action)) return false;
          return "You have unsaved changes. Are you sure you want to exit?";
        }}
      />
    </>
  );
};
