import { inject, observer } from "mobx-react";
import * as React from "react";
import type { RouteChildrenProps } from "react-router";
import { withRouter } from "react-router";
import { login } from "../../auth/auth";
import { compose } from "../../components/compose/WlyCompose";

import ErrorFeedback from "../../components/layout/feedback/error";
import Feednack from "../../components/layout/feedback/feedback";
import Loading from "../../components/layout/feedback/loading";
import type { AsyncData } from "../../helpers/typescriptHelpers";
import { match } from "../../helpers/typescriptHelpers";
import { decodePageLocation } from "../../services/authService";
import type { AuthStoreProps } from "../../store/authStore";
import type { UserStoreProps } from "../../store/userStore";
import type { LoginSearchSettings } from "./Callback";
import type { PortalLoginSearchSettings } from "./PortalCallback";

interface ILoginProps {}

type ILoginState = AsyncData<undefined>;

type Props = ILoginProps &
  RouteChildrenProps<{}, LoginSearchSettings | undefined> &
  UserStoreProps &
  AuthStoreProps;

class Login extends React.Component<Props, ILoginState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      status: "initial",
    };
  }

  public componentDidMount() {
    const {
      location: { state },
      history,
    } = this.props;

    // when loading this component it is supposed to get a state coming from the Callback component
    // this state contains the response from hydra on the state of the auth

    const values = (state || {}) as PortalLoginSearchSettings;

    if (values.token) {
      // if hydra gives us a code then it means that the auth was successfull
      // we then try to use this code to get a token; if the code has already been used it will throw an error
      // if we get a token (after login) we can fetch the current user
      this.setState({ status: "loading" });
      const expireIn = values.expire_in ? parseFloat(values.expire_in) : 2500;
      this.props.authStore.saveToken({
        token_type: "portal-jwt",
        expires_in: expireIn,
        expires_at: Date.now() + expireIn * 1000 - 10 * 1000,
        access_token: values.token,
      });
      this.props.userStore
        .getUser()
        .then((d) => {
          this.setState({ status: "success" });
          // the auth has been successfull and we redirect the user to the asked page
          // we don't want the auth process to loop forever therefore if the redirect url
          // if /login or /callback? .... we redirect to the main page
          const state = values?.state ? decodePageLocation(values.state) : null;
          const userRoleOrgSlug = this.props.userStore.user.roles[0]?.org?.slug;
          if (
            state &&
            !state.startsWith("/portal-login") &&
            !state.startsWith("/portal-callback") &&
            !state.startsWith("/callback") &&
            !state.startsWith("/login") &&
            !state.startsWith("/")
          ) {
            history.replace(state);
          } else if (userRoleOrgSlug) {
            history.replace(`/${userRoleOrgSlug}/`);
          } else {
            history.replace(`/`);
          }
        })
        .catch((err) => {
          console.error(err);
          // we couldn't get the user info from the auth process
          // we warn the user and put a blocking error message
          this.setState({
            status: "error",
            error: new Error(
              "There was an error fetching your user information"
            ),
          });
        });
    } else {
      // the callback component has not passed any value on the state meaning
      // that the user has reloaded the page on /login or /callback without doing the full flow
      // we remove the remaining state and the render component will trigger a redirect
      history.replace(this.props.location.pathname, null);
      this.setState({
        status: "error",
        error: new Error("missing state value"),
      });
    }
  }

  public fetchUserDetails = (code: string) => {
    return {};
  };

  public renderFromState = (state: ILoginState) => {
    return match<undefined, React.ReactNode>(
      {
        initial: () => (
          <Feednack>
            <div>Trying to log you in</div>
          </Feednack>
        ),
        loading: () => <Loading />,
        success: (data) => (
          <Feednack>
            <div>
              You are successfully logged in. Please wait while we are
              redirecting you to the desired page
            </div>
          </Feednack>
        ),
        error: (error) => {
          // if there are no state in the location it means that we retry the auth process
          if (!this.props.location.state) {
            window.location.href = login(
              `${window.location.pathname}${window.location.search}${window.location.hash}`
            );

            return (
              <Feednack>
                <Loading />
              </Feednack>
            );
          }
          return <ErrorFeedback err={error} />;
        },
      },
      state
    );
  };

  public render() {
    return this.renderFromState(this.state);
  }
}

export default compose<Props, ILoginProps>(withRouter)(
  inject("userStore", "authStore")(observer(Login))
);
