import type { IEmailRendererConfig } from "../domain";
import type { IEmailWidgetRelatedListConfig } from "./domain";
import { EmailRelatedListEditor } from "./editor/EmailRelatedListEditor";
import { EmailRelatedListMJML } from "./mjml/EmailRelatedListMJML";
import { EmailRelatedListRenderer } from "./web-widget/EmailRelatedListRenderer";

export const emailWidgetRelatedListDefinition: IEmailRendererConfig<IEmailWidgetRelatedListConfig> =
  {
    parseConfig: (widget) => {
      const defaultValue: IEmailWidgetRelatedListConfig = {
        day: "monday",
      };

      if (!widget.config) {
        return defaultValue;
      }

      try {
        const config: IEmailWidgetRelatedListConfig = JSON.parse(widget.config);
        return config;
      } catch (err) {
        console.error(err);
        return defaultValue;
      }
    },
    renderEditor: (widget, object, record, conf, org, user, form, datasets) => {
      return (
        <EmailRelatedListEditor
          widget={widget}
          object={object}
          record={record}
          conf={conf}
          form={form}
          org={org}
          user={user}
          datasets={datasets}
        />
      );
    },
    renderComponent: (widget, object, record, conf, org, user, edit) => {
      return (
        <EmailRelatedListRenderer
          widget={widget}
          object={object}
          record={record}
          conf={conf}
          org={org}
          user={user}
          edit={edit}
        />
      );
    },
    renderMjml: EmailRelatedListMJML,
  };
