import cuid from "cuid";
import * as React from "react";
import { compose } from "../../../../components/compose/WlyCompose";
import Error from "../../../../components/error/Error";
import Loading from "../../../../components/layout/feedback/loading";
import type { IPartnerPortal } from "../../../../interfaces/org";
import GraphQl from "../../../graphql/graphql";
import type { InjectedOrgProps } from "../../../orgs/WithOrg";
import WithOrg from "../../../orgs/WithOrg";
import SettingsWrapper from "../../SettingsWrapper";
import PartnerPortalTable from "./PartnerPortalTable";

interface IPartnerPortalPageProps {}

type Props = IPartnerPortalPageProps & InjectedOrgProps;

const gql = `
query fetchPartnerPortal($orgId: ID!) {
  allPartnerPortals(where: { isDeleted_not: true, org: { id: $orgId } }) {
    id
    slug
    name
    logo
    primaryColor
    logoutUrl
    defaultHomeCover
  }
}
`;

function PartnerPortalPage(props: Props) {
  const { org } = props;
  const [render, setRender] = React.useState<string>(cuid());
  return (
    <SettingsWrapper>
      <GraphQl<{
        allPartnerPortals: Array<IPartnerPortal>;
      }>
        query={gql}
        variables={{
          orgId: org.id,
          render: render,
        }}
      >
        {(gql) => {
          if (gql.status === "loading" || gql.status === "initial") {
            return <Loading />;
          }
          if (gql.status === "error") {
            return <Error />;
          }
          return (
            <PartnerPortalTable
              rerender={() => setRender(cuid())}
              portals={gql.data.allPartnerPortals}
            />
          );
        }}
      </GraphQl>
    </SettingsWrapper>
  );
}

export default compose<Props, IPartnerPortalPageProps>(WithOrg)(
  PartnerPortalPage
);
