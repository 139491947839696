import { Button, Form, Input, Progress, Select, Typography } from "antd";
import { useForm } from "antd/es/form/Form";
import moment from "moment";
import { useState } from "react";
import type { RouteComponentProps } from "react-router";
import { withRouter } from "react-router";
import WhalyLogo from "../../assets/logo/logo.svg";
import { WlyCard } from "../../components/cards/WlyCard";
import { compose } from "../../components/compose/WlyCompose";
import PhoneInput from "../../components/phone-input/PhoneInput";
import GraphQLService from "../../services/graphql/GraphQLService";
import type { InjectedOrgProps } from "../orgs/WithOrg";
import WithOrg from "../orgs/WithOrg";
import "./Questionnaire.scss";

interface IQuestionnaireProps {
  onDone: () => Promise<void>;
}

type Props = IQuestionnaireProps & InjectedOrgProps & RouteComponentProps;

function Questionnaire(props: Props) {
  const [form] = useForm();
  const [step, setStep] = useState<number>(1);
  const [loading, setLoading] = useState<boolean>(false);
  const maxStep = 4;

  const incrementStep = async () => {
    const { user, org, onDone } = props;
    try {
      form.setFieldValue("user_email", user.email);
      form.setFieldValue("company_id", org.id);
      form.setFieldValue("whaly_env", window.WHALY_CONSTANTS.WHALY_ENVIRONMENT);
      const formValues = await form.validateFields();
      const nextStep = step + 1;
      if (nextStep <= maxStep) return setStep(nextStep);
      try {
        setLoading(true);
        await fetch(
          "https://n8n.whaly.io/webhook/c4426edc-eff0-4269-8999-479808dfa778",
          {
            method: "POST",
            mode: "no-cors",
            cache: "no-cache",
            credentials: "same-origin",
            headers: {
              "Content-Type": "application/json",
            },
            redirect: "follow",
            referrerPolicy: "no-referrer",
            body: JSON.stringify({
              ...formValues,
              phone_number: `${formValues.phone_number?.dial_code} ${formValues.phone_number?.number}`,
            }),
          }
        );
      } catch (error) {
        console.error("can't reach n8n", error);
      } finally {
        const mutation = `
          mutation updateOrg($id: ID!, $data: OrgUpdateInput!) {
            updateOrg(id: $id, data: $data) {
              id
            }
          }
        `;
        await GraphQLService(mutation, {
          id: org.id,
          data: {
            name: formValues.company_name,
            status: "freetrial",
            freeTrialEndDate: moment().add(14, "days").format("YYYY-MM-DD"),
          },
        });
        await onDone();
        setLoading(false);
      }
    } catch (error) {
      console.warn(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="questionnaire">
      <div className="questionnaire-wrapper">
        <div className="questionnaire-logo-wrapper">
          <img
            src={WhalyLogo}
            alt="Whaly Logo"
            className="questionnaire-wly-logo"
          />
        </div>
        <WlyCard className="questionnaire-wrapper">
          <Form
            layout="vertical"
            form={form}
            preserve
            initialValues={{ company_name: props.org.name }}
          >
            <div style={{ width: 50, margin: "auto" }}>
              <Progress
                percent={(step / maxStep) * 100}
                steps={maxStep}
                showInfo={false}
              />
            </div>
            <div hidden>
              <Form.Item name={"company_id"}>
                <Input />
              </Form.Item>
              <Form.Item name={"user_email"}>
                <Input />
              </Form.Item>
              <Form.Item name={"whaly_env"}>
                <Input />
              </Form.Item>
            </div>
            {step >= 1 && (
              <div hidden={step !== 1}>
                <Typography.Title
                  level={3}
                  style={{ textAlign: "center", paddingBottom: 16 }}
                >
                  What company do you work for ?
                </Typography.Title>

                <Form.Item
                  rules={[
                    { required: true, message: "This field is required" },
                  ]}
                  name={"company_name"}
                  label={"Company name"}
                >
                  <Input placeholder="Type your company name" />
                </Form.Item>
                <Form.Item
                  rules={[
                    { required: true, message: "This field is required" },
                  ]}
                  name={"company_size"}
                  label={"Company size"}
                >
                  <Select placeholder="Select an option">
                    <Select.Option value="1">Just me</Select.Option>
                    <Select.Option value="2-5">2-5</Select.Option>
                    <Select.Option value="6-10">6-10</Select.Option>
                    <Select.Option value="11-50">11-50</Select.Option>
                    <Select.Option value="51-200">51-200</Select.Option>
                    <Select.Option value="201-1000">201-1000</Select.Option>
                    <Select.Option value="1000+">1000+</Select.Option>
                  </Select>
                </Form.Item>
                <Form.Item
                  rules={[
                    { required: true, message: "This field is required" },
                  ]}
                  name={"role"}
                  label={"What best describe your role?"}
                >
                  <Select placeholder="Select an option">
                    <Select.Option value="customer_success_or_support">
                      Customer Success or support
                    </Select.Option>
                    <Select.Option value="cto">CTO</Select.Option>
                    <Select.Option value="data_analyst">
                      Data analyst
                    </Select.Option>
                    <Select.Option value="data_engineer">
                      Data engineer
                    </Select.Option>
                    <Select.Option value="engineering">
                      Engineering
                    </Select.Option>
                    <Select.Option value="finance_or_accounting">
                      Finance or accounting
                    </Select.Option>
                    <Select.Option value="founder_or_ceo">
                      Founder / CEO
                    </Select.Option>
                    <Select.Option value="marketing">Marketing</Select.Option>
                    <Select.Option value="product">Product</Select.Option>
                    <Select.Option value="sales">Sales</Select.Option>
                    <Select.Option value="other">Other</Select.Option>
                  </Select>
                </Form.Item>
              </div>
            )}
            {step >= 2 && (
              <div hidden={step !== 2}>
                <Typography.Title
                  level={3}
                  style={{ textAlign: "center", paddingBottom: 16 }}
                >
                  About your data project
                </Typography.Title>
                <Form.Item
                  rules={[
                    { required: true, message: "This field is required" },
                  ]}
                  name={"data_usage"}
                  label={"What will your company be using Whaly for?"}
                >
                  <Select
                    placeholder="Select all options that apply"
                    mode="multiple"
                  >
                    <Select.Option value="internal_analytics">
                      Internal analytics
                    </Select.Option>
                    <Select.Option value="external_analytics">
                      External analytics
                    </Select.Option>
                    <Select.Option value="embedded_analytics">
                      Embedded analytics
                    </Select.Option>
                    <Select.Option value="reselling_whaly">
                      Reselling Whaly
                    </Select.Option>
                    <Select.Option value="curious">
                      I'm just curious
                    </Select.Option>
                  </Select>
                </Form.Item>
                <Form.Item
                  rules={[
                    { required: true, message: "This field is required" },
                  ]}
                  name={"project_timeframe"}
                  label={"What is your project time frame?"}
                >
                  <Select placeholder="Select an option">
                    <Select.Option value="immediate">Immediate</Select.Option>
                    <Select.Option value="next_3_months">
                      Next 3 months
                    </Select.Option>
                    <Select.Option value="next_6_months">
                      Next 6 months
                    </Select.Option>
                    <Select.Option value="more_12_months">
                      More than 12 months
                    </Select.Option>
                  </Select>
                </Form.Item>
              </div>
            )}
            {step >= 3 && (
              <div hidden={step !== 3}>
                <Typography.Title
                  level={3}
                  style={{ textAlign: "center", paddingBottom: 16 }}
                >
                  About your data knowledge
                </Typography.Title>
                <Form.Item
                  rules={[
                    { required: true, message: "This field is required" },
                  ]}
                  name={"familiar_with_data"}
                  label={"How familiar are you with data?"}
                >
                  <Select placeholder="Select an option">
                    <Select.Option value="not_familiar">
                      Not familiar at all
                    </Select.Option>
                    <Select.Option value="just_starting">
                      Just starting out
                    </Select.Option>
                    <Select.Option value="some_experience">
                      I have some experience
                    </Select.Option>
                    <Select.Option value="experienced">
                      I’ve already rolled out a data project
                    </Select.Option>
                  </Select>
                </Form.Item>
                <Form.Item
                  rules={[
                    { required: true, message: "This field is required" },
                  ]}
                  name={"familiar_with_tools"}
                  label={
                    "Which of the following tools are you already familiar with?"
                  }
                >
                  <Select
                    placeholder="Select all options that apply"
                    mode="multiple"
                  >
                    <Select.Option value="looker">Looker</Select.Option>
                    <Select.Option value="tableau">Tableau</Select.Option>
                    <Select.Option value="powerbi">PowerBI</Select.Option>
                    <Select.Option value="holistics">Holistics</Select.Option>
                    <Select.Option value="metabase">Metabase</Select.Option>
                    <Select.Option value="others">Others</Select.Option>
                  </Select>
                </Form.Item>
              </div>
            )}
            {step >= 4 && (
              <div hidden={step !== 4}>
                <Typography.Title
                  level={3}
                  style={{ textAlign: "center", paddingBottom: 16 }}
                >
                  How should we get in touch ?
                </Typography.Title>
                <Form.Item
                  rules={[
                    { required: false, message: "This field is required" },
                  ]}
                  name={"phone_number"}
                  label={"What's your phone number?"}
                >
                  <PhoneInput />
                </Form.Item>
              </div>
            )}
            <Button
              type="primary"
              block
              onClick={() => incrementStep()}
              loading={loading}
            >
              Continue
            </Button>
          </Form>
        </WlyCard>
      </div>
    </div>
  );
}

export default compose<Props, IQuestionnaireProps>(
  WithOrg,
  withRouter
)(Questionnaire);
