import React from "react";
import "./ExplorationPanel.scss";

interface IExplorationPanelProps {
  title?: React.ReactNode;
  actions?: React.ReactNode;
  children?: React.ReactNode;
  footer?: React.ReactNode;
  bodyStyle?: React.CSSProperties;
  footerStyle?: React.CSSProperties;
}

export function ExplorationPanel(props: IExplorationPanelProps) {
  const { title, actions, children, bodyStyle, footerStyle, footer } = props;
  return (
    <div className="exploration-panel">
      <div className="exploration-panel-header">
        <div className="exploration-panel-header-title">{title}</div>
        <div className="exploration-panel-header-actions">{actions}</div>
      </div>
      <div className="exploration-panel-content" style={bodyStyle}>
        {children}
      </div>
      {footer && (
        <div className="exploration-panel-footer" style={footerStyle}>
          {footer}
        </div>
      )}
    </div>
  );
}
