import { CaretDownOutlined } from "@ant-design/icons";
import { Typography } from "antd";
import _ from "lodash";
import {
  type AvailableDimension,
  type AvailableMetric,
} from "../../../../../components/measures/filter-item/FilterItem";
import { type ISummaryQueryOptions } from "../../../../chart-options/ChartOptions";
import { type ILagoonQuery } from "../../../../exploration/single/domain";
import { timeGrains } from "../panel/summary.domain";
import { ChartPickerDropdown } from "./ChartPickerDropdown";

export interface IChartCardSummaryProps {
  availableDimensions: AvailableDimension[];
  availableMetrics: AvailableMetric[];
  overrideQuery?: ILagoonQuery;
  initialQuery: ILagoonQuery;
  onRefresh?: (query: ILagoonQuery) => void;
  editing: boolean;
}

export const showSummary = (summary?: ISummaryQueryOptions) => {
  if (summary?.metrics?.allow) {
    return true;
  }
  if (summary?.time?.allow) {
    return true;
  }
  if (summary?.dimensions?.allow) {
    return true;
  }
  return false;
};

export function ChartCardSummary(props: IChartCardSummaryProps) {
  const {
    availableDimensions,
    availableMetrics,
    overrideQuery,
    onRefresh,
    initialQuery,
    editing,
  } = props;

  const query = overrideQuery ? overrideQuery : initialQuery;

  const shouldShow = showSummary(initialQuery.chartOptions?.summary);

  if (!shouldShow) {
    return null;
  }

  const canSelectMetric = initialQuery.chartOptions?.summary?.metrics?.allow;
  const whiteListMetric =
    initialQuery.chartOptions?.summary?.metrics?.whiteList;
  const canSelectDimensions =
    initialQuery.chartOptions?.summary?.dimensions?.allow;
  const whiteListDimensions =
    initialQuery.chartOptions?.summary?.dimensions?.whiteList;
  const canSelectTimegrain = initialQuery.chartOptions?.summary?.time?.allow;
  const whiteListTimegrain =
    initialQuery.chartOptions?.summary?.time?.whiteList;

  const findGranularity = () => {
    if (query.selectedGranularity) {
      return _.capitalize(query.selectedGranularity);
    }
  };

  const findDimension = (): AvailableDimension | "NOT_FOUND" | "NONE" => {
    if (query.selectedDimensions && query.selectedDimensions.length === 1) {
      const foundDimension = availableDimensions.find(
        (d) => d.key === query.selectedDimensions[0]
      );
      if (foundDimension) {
        return foundDimension;
      }
      return "NOT_FOUND";
    }
    return "NONE";
  };

  const findMeasure = (): AvailableMetric | "NOT_FOUND" | "NONE" => {
    if (query.selectedMeasures && query.selectedMeasures.length === 1) {
      const foundMeasure = availableMetrics.find(
        (d) => d.key === query.selectedMeasures[0]
      );
      if (foundMeasure) {
        return foundMeasure;
      }
      return "NOT_FOUND";
    }
    return "NONE";
  };

  const renderMeasure = (
    d: AvailableDimension | AvailableMetric | "NOT_FOUND" | "NONE"
  ) => {
    if (d === "NONE") {
      return (
        <Typography.Text
          underline
          italic
          style={{ cursor: "pointer" }}
          type="secondary"
        >
          None{" "}
          <span style={{ transform: "scale(0.4)" }}>
            <CaretDownOutlined />
          </span>
        </Typography.Text>
      );
    }
    if (d === "NOT_FOUND") {
      return (
        <Typography.Text underline style={{ cursor: "pointer" }} type="danger">
          Not found{" "}
          <span style={{ transform: "scale(0.4)" }}>
            <CaretDownOutlined />
          </span>
        </Typography.Text>
      );
    }
    return (
      <Typography.Text underline style={{ cursor: "pointer" }} type="secondary">
        {d.label}{" "}
        <span style={{ transform: "scale(0.4)" }}>
          <CaretDownOutlined />
        </span>
      </Typography.Text>
    );
  };

  return (
    <div className="tile-chart-sub-header">
      {canSelectMetric && (
        <>
          <ChartPickerDropdown
            disabled={editing}
            menu={{
              items: availableMetrics
                .filter((r) => {
                  if (!whiteListMetric || whiteListMetric.length === 0) {
                    return true;
                  }
                  return whiteListMetric.includes(r.key);
                })
                .map((r) => ({
                  label: r.label,
                  key: r.key,
                  onClick: onRefresh
                    ? () =>
                        onRefresh({
                          ...query,
                          selectedMeasures: [r.key],
                        })
                    : undefined,
                })),
            }}
          >
            {renderMeasure(findMeasure())}
          </ChartPickerDropdown>{" "}
        </>
      )}
      {canSelectDimensions && (
        <>
          <Typography.Text type="secondary">by</Typography.Text>{" "}
          <ChartPickerDropdown
            disabled={editing}
            menu={{
              items: [
                ...(initialQuery.selectedDimensions.length === 0
                  ? [
                      {
                        key: "none",
                        label: "None",
                        onClick: onRefresh
                          ? () =>
                              onRefresh({
                                ...query,
                                selectedDimensions: [],
                              })
                          : undefined,
                      },
                    ]
                  : []),
                ...availableDimensions
                  .filter((r) => {
                    if (
                      !whiteListDimensions ||
                      whiteListDimensions.length === 0
                    ) {
                      return true;
                    }
                    return whiteListDimensions.includes(r.key);
                  })
                  .map((r) => ({
                    label: r.label,
                    key: r.key,
                    onClick: onRefresh
                      ? () =>
                          onRefresh({
                            ...query,
                            selectedDimensions: [r.key],
                          })
                      : undefined,
                  })),
              ],
            }}
          >
            {renderMeasure(findDimension())}
          </ChartPickerDropdown>{" "}
        </>
      )}
      {canSelectTimegrain && (
        <>
          <Typography.Text type="secondary">by</Typography.Text>{" "}
          <ChartPickerDropdown
            disabled={editing}
            menu={{
              items: timeGrains
                .filter((r) => {
                  if (!whiteListTimegrain || whiteListTimegrain.length === 0) {
                    return true;
                  }
                  return whiteListTimegrain.includes(r);
                })
                .map((d) => ({
                  label: _.capitalize(d),
                  key: d,
                  onClick: onRefresh
                    ? () =>
                        onRefresh({
                          ...query,
                          selectedGranularity: d as any,
                        })
                    : undefined,
                })),
            }}
          >
            <Typography.Text
              underline
              style={{ cursor: "pointer" }}
              type="secondary"
            >
              {findGranularity()}{" "}
              <span style={{ transform: "scale(0.4)" }}>
                <CaretDownOutlined />
              </span>
            </Typography.Text>
          </ChartPickerDropdown>{" "}
        </>
      )}
    </div>
  );
}
