import { CloseOutlined, GroupOutlined } from "@ant-design/icons";
import { Button, Space } from "antd";
import Dropdown from "antd/es/dropdown/dropdown";
import useToken from "antd/es/theme/useToken";
import type { AvailableDimension } from "../../../../../../components/measures/filter-item/FilterItem";
import PropertyGroupsSelector from "./PropertiesSelector/PropertyGroupsSelector";

interface IObjectToolbarGroupProps {
  availableDimensions: Array<AvailableDimension>;
  value: Array<{ id: string; agg?: string }>;
  onChange: (value: Array<{ id: string; agg?: string }>) => any;
}

export function ObjectToolbarGroup(props: IObjectToolbarGroupProps) {
  const { availableDimensions, value, onChange } = props;
  const [, token] = useToken();

  const contentStyle = {
    backgroundColor: token.colorBgElevated,
    borderRadius: token.borderRadiusLG,
    boxShadow: token.boxShadowSecondary,
  };

  return (
    <Dropdown
      trigger={["click"]}
      dropdownRender={() => {
        return (
          <div
            className="wlydatepicker-custom-menu"
            style={{
              ...contentStyle,
            }}
          >
            <div
              style={{
                width: 320,
                margin: "4px 12px",
                paddingTop: 12,
                paddingBottom: 12,
              }}
            >
              <PropertyGroupsSelector
                measures={availableDimensions}
                zone="dimensions"
                accept={["property"]}
                value={value}
                onChange={onChange}
                showTimeAgg
              />
            </div>
          </div>
        );
      }}
    >
      <div>
        <Button
          size="small"
          type="text"
          icon={<GroupOutlined />}
          style={
            value?.length
              ? {
                  background: "#DBE0FD",
                  color: "#3f6ac4",
                }
              : undefined
          }
        >
          <Space>
            {value.length ? `Group by: ${value.length}` : "Group by"}
            {value.length > 0 && (
              <CloseOutlined
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  onChange([]);
                }}
              />
            )}
          </Space>
        </Button>
      </div>
    </Dropdown>
  );
}
