import { useForm } from "antd/es/form/Form";
import { SimpleFormModal } from "../../../../../../components/form/modal/SimpleFormModal";
import {
  AddRecordsObjectViewForm,
  type AddRecordsObjectViewFormData,
} from "../forms/AddRecordsObjectViewForm";
import { type IObjectView } from "../../../../../../interfaces/object";

type Props = {
  open: boolean;
  initialValues?: Partial<AddRecordsObjectViewFormData>;
  isSaving: boolean;
  onCancel: () => void;
  onSave: (values: AddRecordsObjectViewFormData) => any;
  views: IObjectView[];
};

export default function AddRecordsObjectViewModal({
  open,
  initialValues,
  isSaving,
  onCancel,
  onSave,
  views,
}: Props) {
  const [form] = useForm<AddRecordsObjectViewFormData>();

  return (
    <SimpleFormModal<AddRecordsObjectViewFormData>
      form={form}
      open={open}
      onCancel={onCancel}
      isSaving={isSaving}
      modalProps={{
        title: `Add ${initialValues?.recordIds?.length} records to view`,
      }}
    >
      <AddRecordsObjectViewForm
        form={form}
        initialValues={initialValues}
        onSave={onSave}
        views={views}
      />
    </SimpleFormModal>
  );
}
