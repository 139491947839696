/**
 * Calcultate the reverse distance in % between min and max bounds for a value
 *
 * Useful for selecting a color in a linear color scale
 *
 * @param min number
 * @param max number
 * @param value value
 * @returns
 */
export function calculateReverseDistance(
  min: number,
  max: number,
  value: number
): number {
  // Ensure min is less than or equal to max
  if (min > max) {
    [min, max] = [max, min];
  }

  // Ensure value is in bounds
  if (value > max) {
    value = max;
  }
  if (value < min) {
    value = min;
  }

  // Calculate the range
  const range = max - min;
  // Calculate the distance
  const distance = Math.abs(value - min);
  // Calculate the distance as a percentage
  const percentage = Math.round((distance / range) * 100);
  // Reverse
  return Math.abs(100 - percentage);
}
