import * as React from "react";
import type { FormikProps } from "formik";
import { Formik, Form } from "formik";
import type { ISourceValue, ISourceOption } from "../../../interfaces/sources";
import { Button } from "antd";
import { handleGQLErrors } from "../../../helpers/gqlHelpers";
import FormCard from "../../../components/form/card/FormCard";
import { CatalogFormElement } from "../form/CatalogFormElement";
import type { IDestinationValue } from "../../../interfaces/destinations";

interface ICatalogItemInfoValueFormProps {
  value: ISourceValue | IDestinationValue;
  onBackClick: () => void;
  onUpdate: (
    itemValueId: string,
    value: string | number | Date
  ) => Promise<void>;
}

interface InitialValues {
  [key: string]: string | number | Date;
}

const F = Form as any;

export default class CatalogItemInfoValueForm extends React.Component<ICatalogItemInfoValueFormProps> {
  public onSubmit = (v: InitialValues) => {
    const { value, onBackClick, onUpdate } = this.props;
    return onUpdate(value.id, v[value.option.key])
      .then(onBackClick)
      .catch(handleGQLErrors());
  };

  public render() {
    const { value, onBackClick } = this.props;

    const initialValue: InitialValues = {
      [value.option.key]: value.value,
    };

    return (
      <Formik initialValues={initialValue} onSubmit={this.onSubmit}>
        {(p: FormikProps<InitialValues>) => {
          return (
            <F className="form-container">
              <FormCard
                left={<Button onClick={onBackClick}>Back</Button>}
                right={
                  <Button
                    loading={p.isSubmitting}
                    htmlType="submit"
                    type="primary"
                  >
                    Save
                  </Button>
                }
              >
                <CatalogFormElement
                  catalogItemOption={value.option as ISourceOption}
                  catalogValue={value as any}
                  submitting={p.isSubmitting}
                />
              </FormCard>
            </F>
          );
        }}
      </Formik>
    );
  }
}
