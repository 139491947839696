import { gql } from "@apollo/client";
import {
  OBJECT_FRAGMENT,
  OBJECT_LAYOUT_FRAGMENT,
} from "../../../../fragments/object";
import type { IObject } from "../../../../interfaces/object";
import type { IOrg } from "../../../../interfaces/org";
import type { IUser } from "../../../../interfaces/user";
import GraphQLService from "../../../../services/graphql/GraphQLService";
import { fetchRecord, parseObjectLayout } from "./component/domain";
import { renderRecordEmailMJML } from "./component/layout/RecordEmailMjml";
import type { IRecord } from "./domain";

export const getRelatedMjml = async (
  org: IOrg,
  user: IUser,
  layoutId: string,
  objectId: string,
  recordId: string
): Promise<string[]> => {
  const objectData = await GraphQLService<{ allObjects: IObject[] }>(
    gql`
      ${OBJECT_FRAGMENT}

      ${OBJECT_LAYOUT_FRAGMENT}

      query getRecordMetaData($value: ID!) {
        allObjects(where: { id: $value, isDeleted_not: true }) {
          ...ObjectFragment
          layouts(where: { isDeleted_not: true, id: ${layoutId}}) {
            ...ObjectLayoutFragment
          }
          foreignKeys(where: { isDeleted_not: true, object: { isDeleted_not: true } }) {
            id
            label
            object {
              ...ObjectFragment
            }
          }
        }
      }
  `,
    {
      value: objectId,
    }
  );

  const object = objectData.allObjects[0];

  if (!object) {
    throw new Error("Could not find object");
  }

  const record = await new Promise<IRecord | null>((resolve, reject) => {
    fetchRecord(org.id, recordId, object, (d) => {
      if (d.status === "error") {
        reject(d.error);
      }
      if (d.status === "success") {
        resolve(d.data);
      }
    });
  });
  if (!record) {
    throw new Error("Record not found");
  }
  const layout = parseObjectLayout((object.layouts || [])[0]);
  const data = await renderRecordEmailMJML(layout, object, record, org, user);
  return data;
};
