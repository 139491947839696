import type {
  BinaryFilter,
  PivotConfig,
  Query,
  ResultSet,
  TableColumn,
  UnaryFilter,
} from "@cubejs-client/core";
import type {
  ColDef,
  ColGroupDef,
  GridOptions,
  ICellRendererParams,
} from "ag-grid-community";
import { AgGridReact } from "ag-grid-react/lib/agGridReact";
import { Progress } from "antd";
import _ from "lodash";
import * as React from "react";
import type { ChartOption } from "../../../containers/chart-options/ChartOptions";
import type { IAnalysisType } from "../../../containers/exploration/single/domain";
import type { InjectedOrgProps } from "../../../containers/orgs/WithOrg";
import WithOrg from "../../../containers/orgs/WithOrg";
import FieldRenderer from "../../../containers/spreadsheet/renderer/FieldRenderer";
import type { Index } from "../../../helpers/typescriptHelpers";
import type { IPaletteCollection } from "../../../interfaces/org";
import type { UserLocale } from "../../../interfaces/user";
import { getFontColorFromBackground } from "../../../utils/colorUtils";
import { compose } from "../../compose/WlyCompose";
import type { IConditionalFormatterOutputFormat } from "../../conditional-formatting/domain";
import { getFormatFromRules } from "../../conditional-formatting/domain";
import type { AvailableMetric } from "../../measures/filter-item/FilterItem";
import PaletteGenerator from "../../palette/utils/PaletteGenerator";
import { customFormatter } from "../utils/optionsHelper";
import "./TableChart.scss";

const generateKey = (value: string) => {
  return value.replaceAll(".", "_");
};

const getSeries = (tableRows) => {
  return Object.keys(tableRows?.[0] ?? {}).filter((key) => {
    const splitResult = key.split(",");
    const metricName = splitResult[splitResult.length - 1];
    return generateKey(metricName).match(/Tab_[0-9]*_met[0-9]*/gm);
  });
};

const getFormattedGrandTotal = (grandTotal) => {
  return grandTotal
    ? Object.keys(grandTotal).reduce<{ [key: string]: any }>((acc, v) => {
        return {
          ...acc,
          [generateKey(v)]: grandTotal[v],
        };
      }, {})
    : undefined;
};

interface ITableChartProps {
  height: number | string;
  analysisType: IAnalysisType;
  data: ResultSet<any>;
  pivotConfig: PivotConfig;
  columns: TableColumn[];
  tableRows: Array<Index<boolean | string | number>>;
  grandTotal?: Index<boolean | string | number>;
  metrics?: Array<string>;
  availableMetrics: Array<AvailableMetric>;
  defaultCollection: IPaletteCollection;
  chartOptions?: ChartOption;
  isPivoting?: boolean;
  onDrill?: (query: Query) => void;
  locale: UserLocale;
}

type Props = ITableChartProps & InjectedOrgProps;

class TableChart extends React.Component<Props> {
  mainTableRef = React.createRef<AgGridReact>();
  summaryTableRef = React.createRef<AgGridReact>();

  componentDidUpdate(prevProps: Props) {
    if (
      !_.isEqual(
        prevProps.chartOptions?.["show-row-number"],
        this.props.chartOptions?.["show-row-number"]
      )
    ) {
      this.mainTableRef?.current?.columnApi?.autoSizeColumn("_wly_row");
      this.summaryTableRef?.current?.columnApi?.autoSizeColumn("_wly_row");
    }
    this.mainTableRef?.current?.api?.refreshCells({
      force: true,
      suppressFlash: true,
    });
    this.summaryTableRef?.current?.api?.refreshCells({
      force: true,
      suppressFlash: true,
    });
  }

  shouldComponentUpdate(nextProps: Readonly<ITableChartProps>): boolean {
    if (
      !_.isEqual(this.props.height, nextProps.height) ||
      !_.isEqual(this.props.analysisType, nextProps.analysisType) ||
      !_.isEqual(this.props.data, nextProps.data) ||
      !_.isEqual(this.props.pivotConfig, nextProps.pivotConfig) ||
      !_.isEqual(this.props.columns, nextProps.columns) ||
      !_.isEqual(this.props.tableRows, nextProps.tableRows) ||
      !_.isEqual(this.props.grandTotal, nextProps.grandTotal) ||
      !_.isEqual(this.props.availableMetrics, nextProps.availableMetrics) ||
      !_.isEqual(this.props.isPivoting, nextProps.isPivoting) ||
      !_.isEqual(this.props.chartOptions, nextProps.chartOptions)
    ) {
      return true;
    }
    return false;
  }

  public render() {
    const {
      height,
      data,
      analysisType,
      pivotConfig,
      columns,
      tableRows,
      grandTotal,
      onDrill,
      chartOptions,
      isPivoting,
      defaultCollection,
      org,
      locale,
    } = this.props;

    const series = getSeries(tableRows);
    const formattedGrandTotal = getFormattedGrandTotal(grandTotal);

    const tMap = series.reduce((acc, v) => {
      const s = v.split(",");
      const serieKey = s[s.length - 1];
      return {
        ...acc,
        [generateKey(v)]: {
          max: Math.max(
            ...(tableRows
              .map((tr) =>
                typeof tr[v] === "string"
                  ? parseFloat(tr[v] as any)
                  : tr[v] || 0
              )
              .filter((tr) => typeof tr === "number") as any)
          ),
          min: Math.min(
            ...(tableRows
              .map((tr) =>
                typeof tr[v] === "string"
                  ? parseFloat(tr[v] as any)
                  : tr[v] || 0
              )
              .filter((tr) => typeof tr === "number") as any)
          ),
          count: tableRows.filter((tr) => tr[v] !== undefined).length,
          showVisualization:
            chartOptions &&
            chartOptions.series[serieKey] &&
            chartOptions.series[serieKey].showVisualization
              ? chartOptions.series[serieKey].showVisualization
              : false,
        },
      };
    }, {});

    const map = Object.keys(tMap).reduce((acc, v) => {
      const s = v.split(",");
      const m = s[s.length - 1];
      const prevVal = acc[m];
      return {
        ...acc,
        [m]: {
          max:
            prevVal && prevVal.max >= tMap[v].max ? prevVal.max : tMap[v].max,
          min:
            prevVal && prevVal.min >= tMap[v].min ? prevVal.min : tMap[v].min,
          count:
            prevVal && prevVal.count
              ? prevVal.count + tMap[v].count
              : tMap[v].count,
          showVisualization:
            prevVal && prevVal.showVisualization
              ? prevVal.showVisualization
              : tMap[v].showVisualization,
        },
      };
    }, {});

    const rowData = tableRows.map((row) => ({
      ...Object.keys(row).reduce((acc, current) => {
        return {
          ...acc,
          [generateKey(current)]: row[current],
        };
      }, {}),
    }));

    const queryType = (data as any).queryType;
    const isTimeComparingQuery = queryType === "compareDateRangeQuery";
    const keys: { current?: string; previous?: string } = {};

    if (isTimeComparingQuery) {
      let dimensionOffset = 0;
      if (analysisType === "CATEGORIES") {
        const firstQuery = data.decompose()[0].query();
        const dimensions = firstQuery.dimensions?.filter(
          (d) => d !== "compareDateRange"
        );
        dimensionOffset = dimensions ? dimensions.length : 0;
      }
      if (analysisType === "TIME") {
        dimensionOffset = 1;
      }
      keys.current = columns[0 + dimensionOffset]?.key;
      keys.previous = columns[1 + dimensionOffset]?.key;
    }

    const additionalColumns: (ColDef | ColGroupDef)[] = [];

    if (queryType === "compareDateRangeQuery") {
      const startKey = keys.current;
      const endKey = keys.previous;

      // delta percent
      additionalColumns.push(
        ...[
          {
            sortable: true,
            suppressMovable: true,
            resizable: true,
            headerName: "Δ %",
            children: (this.props.metrics ?? []).map((am) => {
              const metric = this.props.availableMetrics.find(
                (m) => m.key === am
              );
              return {
                sortable: true,
                hide: chartOptions?.["table-comparison-delta-percent"] !== true,
                suppressMovable: true,
                resizable: true,
                valueGetter: (p) => {
                  const Va =
                    p?.data?.[[startKey, am].join(",").replaceAll(".", "_")] ||
                    0;
                  const Vd =
                    p?.data?.[[endKey, am].join(",").replaceAll(".", "_")] || 0;
                  return (Va - Vd) / Vd;
                },
                valueFormatter: (p) => {
                  if (p.value === Infinity) return "∞";
                  if (p.value === -Infinity) return "-∞";
                  return customFormatter(p.value, locale, {
                    format: "PERCENT",
                  });
                },
                headerName: metric?.label ?? am,
                flex: 1,
                initialFlex: 1,
              };
            }),
          },
        ]
      );
      // delta value
      additionalColumns.push(
        ...[
          {
            sortable: true,
            suppressMovable: true,
            resizable: true,
            headerName: "Δ value",
            children: (this.props.metrics ?? []).map((am) => {
              const metric = this.props.availableMetrics.find(
                (m) => m.key === am
              );
              return {
                sortable: true,
                suppressMovable: true,
                resizable: true,
                hide: chartOptions?.["table-comparison-delta-value"] !== true,
                valueGetter: (p) => {
                  return (
                    (p?.data?.[[startKey, am].join(",").replaceAll(".", "_")] ||
                      0) -
                    (p?.data?.[[endKey, am].join(",").replaceAll(".", "_")] ||
                      0)
                  );
                },
                valueFormatter: (p) => {
                  return customFormatter(p.value, locale, metric?.formatter);
                },
                headerName: metric?.label ?? am,
                flex: 1,
                initialFlex: 1,
              };
            }),
          },
        ]
      );
    }

    const cellRenderer =
      // eslint-disable-next-line react/display-name
      (type: "main" | "grandtotal") => (v: ICellRendererParams) => {
        const cellRendererParams =
          v?.column?.getUserProvidedColDef?.()?.cellRendererParams;

        const fieldType = cellRendererParams?.customType;
        const showVisualization =
          cellRendererParams?.showVisualization ?? false;

        const seriePalette = cellRendererParams?.palette;
        const defaultPalette = seriePalette
          ? seriePalette
          : defaultCollection.sequential[0];
        const palette = new PaletteGenerator({
          ...defaultPalette,
          numberOfColors: 101,
        });

        const key = v?.colDef?.field;
        const maxWidth = v?.column?.getActualWidth?.()
          ? v.column.getActualWidth() - 68
          : 0;

        let canDrill: Query | undefined | null = undefined;

        if (onDrill) {
          if (v.colDef && v.colDef.field) {
            const colDefFieldMetric = v.colDef.field.split(",");
            colDefFieldMetric.reverse();
            const [metricName, ...dimValues] = colDefFieldMetric;
            const isPivoting =
              colDefFieldMetric.filter((q) => {
                if (keys.current && q === generateKey(keys.current))
                  return false;
                if (keys.previous && q === generateKey(keys.previous))
                  return false;
                return true;
              }).length > 1;
            const isComparing = keys.current || keys.previous;

            if (
              this.props.availableMetrics.find(
                (am) => generateKey(am.key) === metricName
              )
            ) {
              const foundMetric = this.props.availableMetrics.find(
                (am) => generateKey(am.key) === metricName
              );
              const dimensionValues = Object.keys(v.data).reduce<Array<string>>(
                (acc, k) => {
                  return k.match(/Tab_[0-9]*_dim[0-9]*/gm)
                    ? [...acc, v.data[k]?.toString()]
                    : [...acc];
                },
                []
              );
              // when pivoting the pivot config should be { xValues: ["the values of remaining dimensions"], yValues: [the value of the pivoted dimensions, the metric key] }
              // so we need to extract the value of the pivoted dimensions from the v.colDef.field as this as the following shape : dimValue,metricKey
              if (isComparing) {
                const datasets = data.decompose();
                // we assume that the first dataset is always the current and the last the previous
                if (dimValues.includes(generateKey(keys.current!))) {
                  canDrill = datasets[0].drillDown(
                    {
                      xValues: [
                        ...dimensionValues.map((dv) => (!dv ? null : dv)),
                      ],
                      yValues: [
                        ...(isPivoting ? [v.colDef.field.split(",")[0]] : []),
                        foundMetric!.key,
                      ],
                    },
                    pivotConfig
                  );
                  // there is a bug in cube js that foces us to remove the compareDateRange filter that is present by default
                  if (canDrill) {
                    canDrill = {
                      ...canDrill,
                      filters: (canDrill.filters || []).filter((a) => {
                        return (
                          (a as BinaryFilter | UnaryFilter).member !==
                          "compareDateRange"
                        );
                      }),
                    };
                  }
                } else {
                  // no bug for previous comparison
                  canDrill = datasets[1].drillDown(
                    {
                      xValues: [
                        ...dimensionValues.map((dv) => (!dv ? null : dv)),
                      ],
                      yValues: [
                        ...(isPivoting ? [v.colDef.field.split(",")[0]] : []),
                        foundMetric!.key,
                      ],
                    },
                    pivotConfig
                  );
                }
              } else {
                canDrill = data.drillDown(
                  {
                    xValues: [
                      ...dimensionValues.map((dv) => (!dv ? null : dv)),
                    ],
                    yValues: [
                      ...(isPivoting ? [v.colDef.field.split(",")[0]] : []),
                      foundMetric!.key,
                    ],
                  },
                  pivotConfig
                );
              }
            }
          }
        }

        canDrill = type === "grandtotal" ? undefined : canDrill;

        const isPrediction = v.data["__wly_prediction"];

        const baseStyle: React.CSSProperties = {
          fontStyle: isPrediction ? "italic" : undefined,
          opacity: isPrediction ? 0.8 : 1,
          display: "flex",
          gap: 4,
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          width: "100%",
        };

        let metricStyle: React.CSSProperties = {
          textAlign: "left",
          flex: 1,
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        };

        const newKey = key.split(",")[key.split(",").length - 1];

        const w = !map[newKey]
          ? maxWidth
          : map[newKey].max === map[newKey].min
          ? maxWidth
          : (maxWidth * (v.value - map[newKey].min)) /
            (map[newKey].max - map[newKey].min);

        const percent = !map[newKey]
          ? 100
          : map[newKey].max === map[newKey].min
          ? 100
          : (100 * (v.value - map[newKey].min)) /
            (map[newKey].max - map[newKey].min);

        let barStyle: React.CSSProperties = {};

        if (showVisualization) {
          let color;
          try {
            color = !Number.isNaN(percent)
              ? palette.getColorAtIndex(Math.round(100 - percent))
              : "black";
          } catch (err) {
            color = "transparent";
          }
          barStyle = {
            background: color,
            content: "",
            alignSelf: "auto",
            width: w && typeof w === "number" ? Math.max(w, 6) : 6,
            height: 16,
            borderRadius: 6,
            display: showVisualization ? "block" : "none",
          };

          metricStyle = {
            ...metricStyle,
            flexGrow: 1,
            flexBasis: 68,
            minWidth: 68,
          };
        }

        if (type === "grandtotal" && v.value == null) {
          return null;
        } else {
          // we need to apply color style to fieldrenderer has is overrides color by default
          const style: React.CSSProperties = {};
          if (cellRendererParams?.conditionalFormatting) {
            const conditionalFormatting = getFormatFromRules(
              org,
              cellRendererParams?.conditionalFormatting,
              v.value
            );
            if (conditionalFormatting?.fontColor) {
              style.color = conditionalFormatting.fontColor;
            }
          }

          let displayValue = (
            <FieldRenderer content={v.valueFormatted} style={style} />
          );

          const isTimeColumn = (column: string) => {
            if (fieldType === "TIME") {
              return true;
            } else if (column.endsWith("_hour")) {
              return true;
            } else if (column.endsWith("_day")) {
              return true;
            } else if (column.endsWith("_week")) {
              return true;
            } else if (column.endsWith("_month")) {
              return true;
            } else if (column.endsWith("_quarter")) {
              return true;
            } else if (column.endsWith("_year")) {
              return true;
            } else {
              return false;
            }
          };

          const getCustomTimeFormat = (
            column: string,
            chartOptions: ChartOption
          ) => {
            if (!column || !chartOptions?.dimensions) return null;
            const sanitizedColumn = column
              ?.replace("_hour", "")
              ?.replace("_day", "")
              ?.replace("_week", "")
              ?.replace("_month", "")
              ?.replace("_quarter", "")
              ?.replace("_year", "")
              ?.replaceAll(".", "_");

            let momentFormat = null;

            Object.keys(chartOptions.dimensions).forEach((k) => {
              if (
                k.replaceAll(".", "_") === sanitizedColumn &&
                chartOptions?.dimensions[k]
              ) {
                momentFormat = chartOptions?.dimensions[k].momentFormat;
              }
            });
            return momentFormat;
          };

          if (fieldType) {
            let momentFormat = null;
            if (
              isTimeColumn(v.colDef?.field) &&
              getCustomTimeFormat(v.colDef?.field, chartOptions)
            ) {
              momentFormat = getCustomTimeFormat(v.colDef?.field, chartOptions);
            } else if (v.colDef?.field.endsWith("_hour")) {
              momentFormat = "hour";
            } else if (v.colDef?.field.endsWith("_day")) {
              momentFormat = "day";
            } else if (v.colDef?.field.endsWith("_week")) {
              momentFormat = "week";
            } else if (v.colDef?.field.endsWith("_month")) {
              momentFormat = "month";
            } else if (v.colDef?.field.endsWith("_quarter")) {
              momentFormat = "quarter";
            } else if (v.colDef?.field.endsWith("_year")) {
              momentFormat = "year";
            }
            displayValue = (
              <FieldRenderer
                content={v.valueFormatted}
                type={fieldType as any}
                style={style}
                momentFormat={momentFormat}
              />
            );
          }

          return (
            <div
              style={{
                ...baseStyle,
              }}
              className={canDrill && onDrill ? "drillable" : undefined}
              onClick={
                canDrill && onDrill ? () => onDrill(canDrill!) : undefined
              }
            >
              {showVisualization && type === "main" && (
                <Progress
                  percent={
                    typeof w === "number" ? Math.round((w / maxWidth) * 100) : 0
                  }
                  strokeColor={barStyle.background as any}
                  showInfo={false}
                  style={{
                    minWidth: 10,
                    height: 24,
                    marginBottom: 0,
                  }}
                />
              )}
              <div style={metricStyle}>{displayValue}</div>
            </div>
          );
        }
      };

    const renderColumn = (
      columns: TableColumn[],
      options?: {
        hide?: boolean;
        parentKey?: string;
        disableConditionalFormatting?: boolean;
      }
    ): (ColDef | ColGroupDef)[] => {
      return columns
        .filter((c) => c.key !== "compareDateRange")
        .map((c) => {
          const key =
            options?.parentKey != null
              ? generateKey(`${options.parentKey},${c.key}`)
              : generateKey(`${c.key}`);
          if (c.children) {
            const isPrevious = c.key === keys.previous;
            const name =
              c.key === keys.previous
                ? "Previous"
                : c.key === keys.current
                ? "Current"
                : c.key;
            return {
              sortable: true,
              comparator(valueA, valueB, nodeA, nodeB, isInverted) {
                if (c.type === "string" || c.type === "time") {
                  if (valueA) {
                    return valueB ? valueA.localeCompare(valueB) : -1;
                  } else if (valueB) {
                    return valueA ? valueB.localeCompare(valueA) : 1;
                  } else {
                    return 0;
                  }
                } else if (c.type === "number") {
                  return +valueA - +valueB;
                }
              },
              suppressMovable: true,
              resizable: true,
              headerName: name,
              children: renderColumn(c.children, {
                hide: isPrevious
                  ? chartOptions?.["table-comparison-value"] === false
                  : false,
                parentKey: key,
                disableConditionalFormatting:
                  options?.disableConditionalFormatting,
              }),
            };
          }
          const formatter = this.props.availableMetrics.find(
            (am) => am.key === c.key
          )
            ? this.props.availableMetrics.find((am) => am.key === c.key)!
                .formatter
            : undefined;

          const getLabel = () => {
            if (
              chartOptions &&
              chartOptions.series &&
              chartOptions.series[c.key] &&
              chartOptions.series[c.key].label
            ) {
              return chartOptions.series[c.key].label;
            }
            const key = c.key
              ?.replace(".hour", "")
              ?.replace(".day", "")
              ?.replace(".week", "")
              ?.replace(".month", "")
              ?.replace(".quarter", "")
              ?.replace(".year", "");

            if (
              chartOptions &&
              chartOptions.dimensions &&
              chartOptions.dimensions[key] &&
              chartOptions.dimensions[key].label
            ) {
              return chartOptions.dimensions[key].label;
            }
            if (c.meta && c.meta.name) {
              return c.meta.name;
            }
            return c.title;
          };

          return {
            // Those props seems to not be supported by AG Grid anymore
            sortable: true,
            comparator(valueA, valueB, nodeA, nodeB, isInverted) {
              if (c.type === "string" || c.type === "time") {
                if (valueA) {
                  return valueB ? valueA.localeCompare(valueB) : -1;
                } else if (valueB) {
                  return valueA ? valueB.localeCompare(valueA) : 1;
                } else {
                  return 0;
                }
              } else if (c.type === "number") {
                return +valueA - +valueB;
              }
            },
            suppressMovable: true,
            resizable: true,
            field: key,
            hide: !!options?.hide,
            headerName: getLabel(),
            cellRenderer: cellRenderer(
              options?.disableConditionalFormatting ? "grandtotal" : "main"
            ),
            flex: 1,
            initialFlex: 1,
            valueFormatter: (params) => {
              // Formatting sequence :
              // 1. return conditionnally formatted value
              // 3. return metric formatted value
              // 4. return initial value

              const conditionalFormattingRules =
                params.context?.chartOptions?.series?.[c?.key]
                  ?.conditionalFormatting;

              const useConditionalFormatter =
                !options?.disableConditionalFormatting &&
                conditionalFormattingRules;

              const conditionalFormat = useConditionalFormatter
                ? getFormatFromRules(
                    org,
                    conditionalFormattingRules,
                    params.value
                  )
                : null;

              if (conditionalFormat?.overrideValue?.value) {
                return conditionalFormat.overrideValue.value;
              } else if (formatter && params.value != null) {
                return customFormatter(params.value, locale, formatter);
              } else {
                return params.value;
              }
            },
            cellStyle: (params) => {
              // Ag Grid expects a default style in order to be able to detect later changes
              const cellStyle: React.CSSProperties = {
                background: "none",
                color: "initial",
              };

              const conditionalFormattingRules =
                params.context?.chartOptions?.series?.[c?.key]
                  ?.conditionalFormatting;

              // We return the default style if we don't have conditional formatting
              if (
                options?.disableConditionalFormatting ||
                !conditionalFormattingRules
              ) {
                return cellStyle;
              }

              // We apply the custom style as we may have conditional formatting
              let conditionalFormatting: IConditionalFormatterOutputFormat =
                null;

              if (conditionalFormattingRules) {
                conditionalFormatting = getFormatFromRules(
                  org,
                  conditionalFormattingRules,
                  params.value
                );
              }

              // When we are in color scale we apply only background + font colors
              if (conditionalFormatting?.paletteColor) {
                cellStyle.background = conditionalFormatting.paletteColor;
                cellStyle.color = getFontColorFromBackground(
                  conditionalFormatting.paletteColor
                );
              } else {
                // When we are in single color we apply all of the possibilities
                if (conditionalFormatting?.backgroundColor) {
                  cellStyle.background = conditionalFormatting.backgroundColor;
                }
                if (conditionalFormatting?.fontColor) {
                  cellStyle.color = conditionalFormatting.fontColor;
                }
              }

              return cellStyle;
            },
            cellRendererParams: {
              customFormatter: formatter,
              customType: c.meta ? c.meta.domain : undefined,
              key: key,
              showVisualization:
                chartOptions?.series?.[c.key]?.showVisualization ?? false,
              palette: chartOptions?.series?.[c.key]?.palette ?? undefined,
              conditionalFormatting:
                chartOptions?.series?.[c.key]?.conditionalFormatting,
            },
          } as ColDef;
        });
    };

    // count number of columns and if > 16 remove flex
    let columnCount = 0;
    const countColumn = (c: TableColumn[]) => {
      c.forEach((a) => {
        if (a.children) {
          return countColumn(a.children);
        }
        columnCount += 1;
      });
    };

    countColumn(columns);

    const getRowColumn = (isSummaryColDef: boolean): ColDef[] => [
      {
        headerName: "",
        field: "_wly_row",
        valueGetter: isSummaryColDef ? null : "node.rowIndex + 1",
        sortable: false,
        resizable: false,
        suppressMovable: true,
        hide: !(chartOptions && chartOptions["show-row-number"]),
        minWidth: 20,
        width: 68,
        flex: 0,
      },
    ];

    const getColDef = (isSummaryColDef: boolean): ColDef[] => {
      return [
        ...getRowColumn(isSummaryColDef),
        ...renderColumn(columns, {
          disableConditionalFormatting: isSummaryColDef,
        }),
        ...additionalColumns,
      ];
    };

    const mainColDef = getColDef(false);
    const secondaryColDef = getColDef(true);

    const theme = chartOptions?.["table-theme"] ?? "standard";

    const mainGridOptions: GridOptions = {
      alignedGrids: [],
    };

    const summaryGridOptions: GridOptions = {
      alignedGrids: [],
      suppressHorizontalScroll: true,
    };

    if (chartOptions?.["show-total"] && grandTotal) {
      if (typeof mainGridOptions.alignedGrids === "object") {
        mainGridOptions.alignedGrids.push(summaryGridOptions);
      }
      if (typeof summaryGridOptions.alignedGrids === "object") {
        summaryGridOptions.alignedGrids.push(mainGridOptions);
      }
    }

    const style: React.CSSProperties = {
      height: height,
    };

    return (
      <div className={`ag-theme-${theme} wly-table-chart`} style={style}>
        <div
          className={`wly-main-table ${
            columnCount < 16 && "horizontal-scroll-hidden"
          }`}
        >
          <AgGridReact
            ref={this.mainTableRef}
            context={{
              chartOptions,
            }}
            rowData={rowData}
            enableCellTextSelection={true}
            defaultColDef={
              columnCount >= 16
                ? {
                    minWidth: 200,
                    suppressMenu: true,
                  }
                : {
                    minWidth: 0,
                    suppressMenu: true,
                  }
            }
            suppressContextMenu={true}
            columnDefs={mainColDef}
            gridOptions={mainGridOptions}
            onFirstDataRendered={() =>
              this.mainTableRef?.current?.api.refreshCells({ force: true })
            }
          />
        </div>
        {chartOptions?.["show-total"] && grandTotal && (
          <div className="wly-summary-table">
            <AgGridReact
              context={{
                chartOptions,
              }}
              ref={this.summaryTableRef}
              rowData={[formattedGrandTotal]}
              gridOptions={summaryGridOptions}
              enableCellTextSelection={true}
              defaultColDef={
                columnCount >= 16
                  ? {
                      minWidth: 200,
                      suppressMenu: true,
                    }
                  : {
                      minWidth: 0,
                      suppressMenu: true,
                    }
              }
              suppressContextMenu={true}
              columnDefs={secondaryColDef}
              headerHeight={0}
              onFirstDataRendered={() =>
                this.summaryTableRef?.current?.api?.refreshCells({
                  force: true,
                })
              }
            />
          </div>
        )}
      </div>
    );
  }
}

export default compose<Props, ITableChartProps>(WithOrg)(TableChart);
