import * as React from "react";
import type { RouteComponentProps } from "react-router";
import { withRouter } from "react-router";
import { compose } from "../../components/compose/WlyCompose";

import "./OnboardingWrapper.scss";

import Onboarding from "./Onboarding";

interface IOnboardingWrapperProps {
  children?: React.ReactNode;
}

type Props = IOnboardingWrapperProps &
  RouteComponentProps<{ organizationSlug?: string }>;

class OnboardingWrapper extends React.Component<Props> {
  public render() {
    const {
      children,
      match: {
        params: { organizationSlug },
      },
    } = this.props;
    if (organizationSlug) {
      return <Onboarding>{children}</Onboarding>;
    }
    return children;
  }
}

export default compose<Props, IOnboardingWrapperProps>(withRouter)(
  OnboardingWrapper
);
