import * as React from "react";
import type { RouteComponentProps } from "react-router";
import { withRouter } from "react-router";
import { compose } from "../../../../components/compose/WlyCompose";
import type { ISourceMeta, ISourceValue } from "../../../../interfaces/sources";
import withOrg from "../../../orgs/WithOrg";
import type {
  AdditionalFieldsGenerator,
  ItemValueFromInputGenerator,
  Step1CreationPromise,
} from "./step1/ItemConnectionStep1";
import ItemConnectionStep1 from "./step1/ItemConnectionStep1";

import Error from "../../../../components/layout/feedback/error";
import Loading from "../../../../components/layout/feedback/loading";
import type { ICatalogValue } from "../../../../interfaces/catalog";
import GraphQl from "../../../graphql/graphql";
import "./ItemConnection.scss";
import type {
  InputValidationPromise,
  Step2CreationPromise,
} from "./step2/ItemConnectionStep2";
import ItemConnectionStep2 from "./step2/ItemConnectionStep2";

interface IItemConnectionWrapperProps {
  itemMeta: ISourceMeta;
  remainingItemValuesQuery: string;
  step1CreationPromise: Step1CreationPromise;
  step2CreationPromise: Step2CreationPromise;
  inputValidationPromise?: InputValidationPromise;
  overrideGenerateItemValueFromInput?: ItemValueFromInputGenerator;
  additionalFormFieldsGenerator?: AdditionalFieldsGenerator;
  redirectUri: string;
  submitButtonLabel?: string;
  catalogValueTransformer?: (i: ICatalogValue[]) => Array<ICatalogValue>;
  getDocumentPagePath: (itemMeta: ISourceMeta) => string | undefined;
}

interface GQLQueryResult {
  allItemValue: ISourceValue[];
}

type Props = IItemConnectionWrapperProps &
  RouteComponentProps<{ itemSlug: string; itemId?: string }>;

class ItemConnectionWrapper extends React.Component<Props> {
  public renderStep = (itemMeta: ISourceMeta, itemId?: string) => {
    const {
      remainingItemValuesQuery,
      step1CreationPromise,
      step2CreationPromise,
      inputValidationPromise,
      overrideGenerateItemValueFromInput,
      additionalFormFieldsGenerator,
      redirectUri,
      submitButtonLabel,
      catalogValueTransformer,
      getDocumentPagePath,
    } = this.props;
    if (!itemId) {
      return (
        <ItemConnectionStep1
          initialValues={{ name: itemMeta.publicInfo.name }}
          itemMeta={itemMeta}
          step1CreationPromise={step1CreationPromise}
          overrideGenerateItemValueFromInput={
            overrideGenerateItemValueFromInput
          }
          additionalFormFieldsGenerator={additionalFormFieldsGenerator}
          redirectUri={redirectUri}
        />
      );
    }
    return (
      <GraphQl<GQLQueryResult>
        query={remainingItemValuesQuery}
        variables={{ itemId }}
      >
        {(gql) => {
          if (gql.status === "initial" || gql.status === "loading") {
            return <Loading />;
          }
          if (gql.status === "error") {
            return <Error />;
          }
          return (
            <ItemConnectionStep2
              itemValues={gql.data.allItemValue}
              documentationPagePath={getDocumentPagePath(itemMeta)}
              itemId={itemId}
              submitButtonLabel={submitButtonLabel}
              inputValidationPromise={inputValidationPromise}
              step2CreationPromise={step2CreationPromise}
              catalogValueTransformer={catalogValueTransformer}
            />
          );
        }}
      </GraphQl>
    );
  };

  public render() {
    const {
      itemMeta,
      match: {
        params: { itemId },
      },
    } = this.props;
    return (
      <div className="whly-content item-connection">
        {this.renderStep(itemMeta, itemId)}
      </div>
    );
  }
}

export default compose<Props, IItemConnectionWrapperProps>(
  withOrg,
  withRouter
)(ItemConnectionWrapper);
