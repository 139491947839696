import { Form, Input } from "antd";
import { useForm } from "antd/es/form/Form";
import React from "react";
import { compose } from "../../../../../../../../components/compose/WlyCompose";
import { SimpleFormDrawer } from "../../../../../../../../components/form/drawer/SimpleFormDrawer";
import Feednack from "../../../../../../../../components/layout/feedback/feedback";
import type {
  IObject,
  IObjectQueryBuilderSectionItem,
  IObjectQueryBuilderSectionItemConfig_Parsed,
  IObjectQueryBuilderSectionType,
} from "../../../../../../../../interfaces/object";
import WithOrg, {
  type InjectedOrgProps,
} from "../../../../../../../orgs/WithOrg";
import type { IFilterEditorValue } from "../../../../../../../v2-demo/container/record/component/widgets/related-lists/domain";
import { isFakeId } from "../../../../../exploration/domain";
import { parseQueryBuilderSectionItemConfidProperty } from "../domain";
import { ForeignPropertySectionItemForm } from "./forms/ForeignPropertySectionItemForm";
import { OwnPropertySectionItemForm } from "./forms/OwnPropertySectionItemForm";

export interface IObjectSectionItemUpdateDrawerProps {
  open: boolean;
  initialValues?: IObjectQueryBuilderSectionItem | null;
  sectionType: IObjectQueryBuilderSectionType;
  onCancel: () => void;
  onSave: (values: IObjectQueryBuilderSectionItem) => any;
  object: IObject;
}

type Props = IObjectSectionItemUpdateDrawerProps & InjectedOrgProps;

function ObjectSectionItemUpdateDrawer(props: Props) {
  const { open, initialValues, onCancel, onSave, object, sectionType, org } =
    props;

  const [isSaving, setIsSaving] = React.useState<boolean>(false);
  const [form] = useForm<IObjectQueryBuilderSectionItem>();

  const renderInner = () => {
    if (!initialValues) {
      return (
        <Feednack>
          <div>Please pass values</div>
        </Feednack>
      );
    }

    const renderFormItems = () => {
      if (sectionType === "OWN_PROPERTIES") {
        return <OwnPropertySectionItemForm form={form} object={object} />;
      } else if (sectionType === "FOREIGN_PROPERTIES") {
        return (
          <ForeignPropertySectionItemForm
            form={form}
            object={object}
            org={org}
          />
        );
      }

      return (
        <Feednack>
          <div>Section type {sectionType} is not supported</div>
        </Feednack>
      );
    };

    const parseFilters = (s: string): IFilterEditorValue => {
      if (!s) {
        return { filters: [], operator: "and" };
      }
      try {
        return JSON.parse(s);
      } catch (err) {
        console.error(err);
        return { filters: [], operator: "and" };
      }
    };

    const iv = {
      ...initialValues,
      property: parseQueryBuilderSectionItemConfidProperty(
        initialValues.property
      ),
      additionalFilters: parseFilters(initialValues.additionalFilters),
    };

    return (
      <Form
        form={form}
        preserve={false}
        layout="vertical"
        initialValues={iv}
        onFinish={async (values) => {
          try {
            setIsSaving(true);
            const d = await form.validateFields();
            await onSave({
              ...d,
              property:
                d && d.property
                  ? JSON.stringify(
                      (
                        d.property as any as IObjectQueryBuilderSectionItemConfig_Parsed
                      ).filter((p) => {
                        if (!p) {
                          return false;
                        }
                        if (!p.key) {
                          return false;
                        }
                        return true;
                      })
                    )
                  : "",
              additionalFilters:
                d && d.additionalFilters
                  ? JSON.stringify(d.additionalFilters)
                  : "",
            });
            setIsSaving(false);
          } catch (err) {
            console.error(err);
            setIsSaving(false);
          }
        }}
      >
        <Form.Item noStyle name="id" hidden>
          <Input />
        </Form.Item>
        {renderFormItems()}
      </Form>
    );
  };

  return (
    <SimpleFormDrawer
      form={form}
      open={open}
      onCancel={onCancel}
      isSaving={isSaving}
      drawerProps={{
        title: isFakeId(initialValues?.id || "")
          ? "Create section item"
          : "Update a section item",
        width: 640,
      }}
      disableEnterKey={true}
    >
      {renderInner()}
    </SimpleFormDrawer>
  );
}

export default compose<Props, IObjectSectionItemUpdateDrawerProps>(WithOrg)(
  ObjectSectionItemUpdateDrawer
);
