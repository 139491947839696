import type { Query } from "@cubejs-client/core";
import Highcharts from "highcharts";
import { HighchartsReact } from "highcharts-react-official";
import HC_more from "highcharts/highcharts-more";
import _ from "lodash";
import * as React from "react";
import type { ChartOption } from "../../../containers/chart-options/ChartOptions";
import { getChartOptionValue } from "../../../containers/chart-options/ChartOptions";
import type { UserLocale } from "../../../interfaces/user";
import type { Formatter } from "../domain";
import { localizeHighchart } from "../domain";
import {
  customFormatter,
  optionsHelper,
  renderSerieTooltip,
} from "../utils/optionsHelper";

HC_more(Highcharts);

interface IWaterfallChartProps {
  height: number;
  data: Array<{ [key: string]: string | number | boolean }>;
  config: WaterfallChartConfig;
  tinyLegend?: boolean;
  scale?: "time" | "ordinal";
  width?: number;
  onDrill: (q: Query) => void;
  chartOptions?: ChartOption;
  locale: UserLocale;
}

interface WaterfallChartConfig {
  x1: {
    key: string;
    label?: string;
  };
  x2: {
    key: string;
    label?: string;
  };
  y: {
    key: string;
    label?: string;
    color?: string;
    canDrill: (xValue: string, yValue?: string) => Query | null;
    dashed?: boolean;
    formatter: Formatter;
  };
  showLabels?: boolean;
  dataMapper?: (d: { [key: string]: string | number | boolean }) => {
    [key: string]: string | number | boolean;
  };
}

export default class WaterfallChart extends React.Component<IWaterfallChartProps> {
  ref?: Highcharts.Chart;

  componentDidUpdate(prevProps: IWaterfallChartProps) {
    if (
      (prevProps.width !== this.props.width ||
        prevProps.height !== this.props.height ||
        !_.isEqual(this.props.config, prevProps.config)) &&
      this.ref &&
      this.ref.reflow
    ) {
      this.ref.reflow();
    }
  }

  shouldComponentUpdate(nextProps: Readonly<IWaterfallChartProps>): boolean {
    if (!_.isEqual(this.props.width, nextProps.width)) return true;
    if (!_.isEqual(this.props.height, nextProps.height)) return true;
    if (!_.isEqual(this.props.data, nextProps.data)) return true;
    if (!_.isEqual(this.props.scale, nextProps.scale)) return true;
    if (!_.isEqual(this.props.tinyLegend, nextProps.tinyLegend)) return true;
    if (!_.isEqual(this.props.chartOptions, nextProps.chartOptions))
      return true;
    return false;
  }

  public render() {
    const {
      height,
      config,
      data,
      width,
      scale,
      onDrill,
      chartOptions,
      locale,
    } = this.props;
    const y = config.y;
    localizeHighchart(Highcharts, locale);
    const optionHelpers = optionsHelper(
      height,
      width,
      locale,
      scale,
      config.y.formatter,
      undefined,
      undefined,
      chartOptions
    );
    const colors = chartOptions?.palette?.colors;
    const formattedData = data.map((d) => {
      if (config.dataMapper) {
        return config.dataMapper(d);
      }
      return {
        name: d[config.x1.key],
        y: parseFloat(d[y.key] as string),
      };
    }) as any;

    if (getChartOptionValue(chartOptions, "show-total", "waterfall")) {
      formattedData.push({
        name: "Total",
        isSum: true,
        color:
          colors && colors[2] ? colors[2] : Highcharts.getOptions().colors[1],
      });
    }

    const options: Highcharts.Options = {
      ...optionHelpers,
      legend: {
        ...optionHelpers.legend,
        enabled: false,
      },
      chart: {
        ...optionHelpers.chart,
        type: "waterfall",
        height: height,
        width: width,
      },
      xAxis: {
        ...optionHelpers.xAxis,
        title: { text: config.x1.label },
      },
      yAxis: {
        ...optionHelpers?.yAxis?.[0],
        title: { text: config.x2.label },
      },
      series: [
        {
          type: "waterfall",
          data: formattedData as any,
          cursor: "pointer",
          borderColor: "transparent",
          events: {
            click: (e) => {
              const xValue = e.point.name.toString();
              const q = y.canDrill(
                xValue.toString(),
                e.point.y ? e.point.y.toString() : undefined
              );
              if (q) {
                return onDrill(q);
              }
            },
          },
          name: y.label,
          color: colors && colors[1] ? colors[1] : "#cde4ff",
          upColor: colors && colors[0] ? colors[0] : "#3a5c83",
          dataLabels: [
            {
              enabled: getChartOptionValue(chartOptions, "labels", "waterfall"),
              formatter: function () {
                return customFormatter((this as any).y, locale, y.formatter);
              },
              crop: false,
              inside: false,
              style: {
                fontSize: "0.8em",
              },
            },
          ],
          tooltip: renderSerieTooltip({
            data: data,
            locale: locale,
            seriesLength: 1,
            formatter: y.formatter,
            hideSerieColor: true,
          }),
        },
      ],
    };

    return (
      <div style={{ height, width: "100%" }}>
        <HighchartsReact
          ref={(r) => (this.ref = r?.chart)}
          highcharts={Highcharts}
          options={options}
        />
      </div>
    );
  }
}
