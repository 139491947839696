import { Form, Input, Select } from "antd";
import type { FormInstance } from "antd/es/form/Form";
import type { INotificationWorkflow } from "../../../../../interfaces/notification";

interface ICreateEditTopicGroupFormProps {
  form: FormInstance<CreateEditTopicGroupFormData>;
  notificationWorkflows: INotificationWorkflow[];
  initialValues?: Partial<CreateEditTopicGroupFormData>;
  onSave: (values: CreateEditTopicGroupFormData) => any;
}

export type CreateEditTopicGroupFormData = {
  id: string;
  name: string;
  allowedWorkflows?: string[];
};

export function CreateEditTopicGroupForm(
  props: ICreateEditTopicGroupFormProps
) {
  const { form, initialValues, notificationWorkflows, onSave } = props;
  const isEditing = !!initialValues?.id;
  return (
    <Form<CreateEditTopicGroupFormData>
      form={form}
      preserve={false}
      layout="vertical"
      initialValues={initialValues}
      onFinish={(values) => {
        onSave(values);
      }}
    >
      <Form.Item
        label="Topic group name"
        name={["name"]}
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Allowed workflows"
        name={["allowedWorkflows"]}
        // hidden={!isEditing}
        rules={[
          {
            required: false,
          },
        ]}
      >
        <Select
          mode="multiple"
          options={notificationWorkflows.map((nw) => ({
            label: nw.name,
            value: nw.id,
          }))}
        />
      </Form.Item>
      <Form.Item
        noStyle
        hidden
        name={["id"]}
        rules={[
          {
            required: isEditing,
          },
        ]}
      >
        <Input />
      </Form.Item>
    </Form>
  );
}
