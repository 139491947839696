import type { Query } from "@cubejs-client/core";
import * as turf from "@turf/turf";
import _, { uniqueId } from "lodash";
import mapboxgl from "mapbox-gl";
import MapboxGLWorker from "mapbox-gl/dist/mapbox-gl-csp-worker?worker";
import "mapbox-gl/dist/mapbox-gl.css";
import React, { useEffect, useMemo } from "react";
import type { ChartOption } from "../../../containers/chart-options/ChartOptions";
import type { IDimensionType } from "../../../interfaces/table";
import type { UserLocale } from "../../../interfaces/user";
import usePrevious from "../../hooks/usePrevious";
import type PaletteGenerator from "../../palette/utils/PaletteGenerator";
import type { Formatter } from "../domain";
import "./Map.scss";

(mapboxgl as any).workerClass = MapboxGLWorker;
mapboxgl.accessToken =
  "pk.eyJ1IjoiZXNhbmNoZXoxNDMiLCJhIjoiY2w0cXp0cHM3MGJ3bzNlazJhdm5lNGxjayJ9.FZi7Vst4XWV2HS18PPUFrQ";

interface IMapboxBubbleChartProps {
  height: number;
  data: Array<{ [key: string]: string | number | boolean }>;
  config: MapboxBubbleChartConfig;
  width?: number;
  onDrill?: (q: Query) => void;
  chartOptions?: ChartOption;
  palette: PaletteGenerator;
  locale: UserLocale;
}

interface MapboxBubbleChartConfig {
  x: {
    key: string;
    type: IDimensionType;
    label?: string;
  };
  y: {
    key: string;
    label?: string;
    canDrill: (xValue: string, yValue?: string) => Query | null;
    formatter: Formatter;
  };
}

const navigation = new mapboxgl.NavigationControl({
  showCompass: false,
  visualizePitch: false,
});

const MIN_RADIUS = 10; // km

interface IFormatedData {
  lat: number;
  long: number;
  value: number;
  radius: number;
}

const convertToLatLong = (s: string) => {
  if (typeof s === "string") {
    const [lat, long] = s.split(",");
    if (lat && long) {
      try {
        return {
          lat: parseFloat(lat),
          long: parseFloat(long),
        };
      } catch (err) {
        console.error(err);
        return;
      }
    }
  }
  return;
};

const convertToScale = (
  value: number,
  minValue: number,
  maxValue: number,
  minRadius: number,
  maxRadius?: number
) => {
  const interval = maxValue - minValue > 0 ? maxValue - minValue : 1; // when only one value in the map that would lead to NaN on the next line
  const ratio = (value - minValue) / interval;
  if (maxRadius) {
    return (maxRadius - minRadius) * ratio + minRadius;
  }
  return (1 + ratio) * minRadius;
};

const createCircle = (center: [number, number], radius: number) => {
  var circle = turf.circle(center, radius, {
    steps: 50,
    units: "kilometers",
  });
  return circle.geometry;
};

const MapboxBubbleChart: React.FunctionComponent<IMapboxBubbleChartProps> = (
  props
) => {
  const { width, height, data, config, chartOptions, palette } = props;

  const mapContainer = React.useRef(null);
  const map = React.useRef<mapboxgl.Map>(null);
  const prevChartOptions = usePrevious(chartOptions);
  const prevPalette = usePrevious(palette);
  const prevData = usePrevious(data);

  const getGeoJSON = (): mapboxgl.GeoJSONSourceRaw => {
    const formattedData: IFormatedData[] = data
      .map((d) => {
        const coordinates = convertToLatLong(d[config.x.key] as string);
        if (coordinates) {
          return {
            lat: coordinates.lat,
            long: coordinates.long,
            value: !d[config.y.key] ? 0 : parseFloat(d[config.y.key] as string),
          };
        } else {
          return null;
        }
      })
      .filter((d) => d)
      .sort((a, b) => b.value - a.value)
      .map((d) => {
        let minRadius = MIN_RADIUS;
        let maxRadius: number | undefined = undefined;
        if (
          chartOptions?.["interractive-map-marker-radius"]?.radius?.type ===
            "fixed" &&
          chartOptions?.["interractive-map-marker-radius"]?.radius?.radius
        ) {
          minRadius = parseFloat(
            chartOptions?.["interractive-map-marker-radius"]?.radius?.radius
          );
          maxRadius = parseFloat(
            chartOptions?.["interractive-map-marker-radius"]?.radius?.radius
          );
        } else if (
          chartOptions?.["interractive-map-marker-radius"]?.radius?.type ===
            "proportional" &&
          chartOptions?.["interractive-map-marker-radius"]?.radius?.minRadius
        ) {
          minRadius = parseFloat(
            chartOptions?.["interractive-map-marker-radius"]?.radius?.minRadius
          );
          maxRadius = parseFloat(
            chartOptions?.["interractive-map-marker-radius"]?.radius?.maxRadius
          );
        }

        const values = data.map((dt) => {
          return parseFloat(dt[config.y.key] as string);
        });

        const radius =
          chartOptions?.["interractive-map-marker-radius"]?.radius?.type ===
          "equal"
            ? d.value
            : convertToScale(
                d.value,
                Math.min(...values),
                Math.max(...values),
                minRadius,
                maxRadius
              );
        return {
          ...d,
          radius: radius,
        };
      });

    return {
      type: "geojson",
      data: {
        type: "FeatureCollection",
        features: formattedData.map((d, i) => {
          return {
            type: "feature",
            geometry: createCircle([d.long, d.lat], d.radius),
            id: uniqueId(),
            properties: {
              value: d.value,
              lat: d.lat,
              long: d.long,
              color: palette.getColorAtIndex(i),
            },
          };
        }),
      },
    } as any;
  };

  const getMapPosition = (geojsonData: mapboxgl.GeoJSONSourceRaw) => {
    const bounds = new mapboxgl.LngLatBounds();
    (
      geojsonData.data as GeoJSON.FeatureCollection<GeoJSON.Geometry>
    ).features.forEach((d) => {
      const bbox = turf.bbox(d.geometry);
      bounds.extend(bbox as [number, number, number, number]);
    });

    let mapInitialPosition: Partial<
      Pick<
        mapboxgl.MapboxOptions,
        "bounds" | "fitBoundsOptions" | "center" | "zoom"
      >
    > = {
      bounds: bounds,
      fitBoundsOptions: {
        padding: 20,
      },
    };

    if (chartOptions?.["interractive-map-position"]?.type === "custom") {
      const lat = chartOptions?.["interractive-map-position"]?.position?.lat
        ? parseFloat(chartOptions?.["interractive-map-position"]?.position?.lat)
        : 0;
      const lng = chartOptions?.["interractive-map-position"]?.position?.lng
        ? parseFloat(chartOptions?.["interractive-map-position"]?.position?.lng)
        : 0;
      mapInitialPosition = {
        center: [lng, lat],
        zoom: chartOptions?.["interractive-map-position"]?.position?.zoomLevel
          ? parseFloat(
              chartOptions?.["interractive-map-position"]?.position?.zoomLevel
            )
          : 1,
      };
    }
    return mapInitialPosition;
  };

  useEffect(() => {
    if (map.current) return; // initialize map only once
    const geojsonData = getGeoJSON();
    const mapInitialPosition = getMapPosition(geojsonData);

    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: "mapbox://styles/esanchez143/cl4r4nbwu000c14l8xj43b1b7",
      attributionControl: false,
      interactive: true,
      ...mapInitialPosition,
    });

    if (
      !chartOptions?.["interractive-map-disable-zoom"] &&
      !map.current.hasControl(navigation)
    ) {
      map.current.addControl(navigation, "top-left");
      map.current.scrollZoom.enable();
    }

    map.current.on("load", () => {
      map.current.addSource("data", geojsonData);
      map.current.addLayer({
        id: "fill",
        type: "fill",
        source: "data",
        layout: {},
        paint: {
          "fill-color": ["get", "color"],
          "fill-opacity": [
            "case",
            ["boolean", ["feature-state", "hover"], false],
            1,
            0.6,
          ],
        },
      });

      if (props.onDrill) {
        map.current.on("click", "fill", (e) => {
          const latlong = [
            e.features[0].properties.lat.toString(),
            e.features[0].properties.long.toString(),
          ].join(",");
          const q = props.config?.y?.canDrill?.(latlong);
          if (typeof q !== "object") return;
          const newQ = {
            ...q,
            filters: q.filters?.map((f: any) => {
              if (f.member === props.config?.x?.key) {
                return {
                  ...f,
                  values: [latlong],
                };
              } else {
                return f;
              }
            }),
          };
          if (newQ) {
            props.onDrill?.(newQ);
          }
        });
      }

      map.current.on("mouseenter", "fill", (e) => {
        map.current.getCanvas().style.cursor = "pointer";
      });

      let hoverID = null;

      map.current.on("mouseleave", "fill", (e) => {
        map.current.getCanvas().style.cursor = "";
        if (hoverID) {
          map.current.setFeatureState(
            {
              source: "data",
              id: hoverID,
            },
            {
              hover: false,
            }
          );
        }

        hoverID = null;
      });

      map.current.on("mousemove", "fill", (e) => {
        if (!e.features?.[0]?.id) return;
        if (hoverID) {
          map.current.removeFeatureState({
            source: "data",
            id: hoverID,
          });
        }
        hoverID = e.features[0].id;
        map.current.setFeatureState(
          { source: "data", id: hoverID },
          { hover: true }
        );
      });

      map.current.triggerRepaint();
    });
  });

  const debouncedResizeMap = useMemo(
    () =>
      _.debounce(() => {
        map.current?.resize?.();
      }, 200),
    []
  );

  useEffect(() => {
    debouncedResizeMap();
  }, [width, height]);

  useEffect(() => {
    let shouldUpdateData = false;

    if (
      !_.isEqual(
        chartOptions?.["interractive-map-marker-radius"],
        prevChartOptions?.["interractive-map-marker-radius"]
      )
    ) {
      shouldUpdateData = true;
    }

    if (!_.isEqual(palette, prevPalette)) {
      shouldUpdateData = true;
    }

    if (!_.isEqual(data, prevData)) {
      shouldUpdateData = true;
    }

    if (shouldUpdateData) {
      const source = map.current?.getSource("data");
      if (source && source.type === "geojson") {
        source.setData(getGeoJSON().data as any);
      }
    }

    if (
      _.isEqual(
        chartOptions?.["interractive-map-disable-zoom"],
        prevChartOptions?.["interractive-map-disable-zoom"]
      )
    ) {
      if (
        map.current?.hasControl?.(navigation) &&
        chartOptions?.["interractive-map-disable-zoom"]
      ) {
        map.current?.removeControl?.(navigation);
        map.current?.scrollZoom.disable();
      } else if (
        !map.current?.hasControl(navigation) &&
        !chartOptions?.["interractive-map-disable-zoom"]
      ) {
        map.current?.addControl?.(navigation, "top-left");
        map.current?.scrollZoom.enable();
      }
    }

    if (
      !_.isEqual(
        chartOptions?.["interractive-map-position"],
        prevChartOptions?.["interractive-map-position"]
      )
    ) {
      const mapPosition = getMapPosition(getGeoJSON());
      if (typeof mapPosition.zoom === "number") {
        map.current?.setZoom(mapPosition.zoom);
      }
      if (mapPosition.center) {
        map.current?.setCenter(mapPosition.center);
      }
      if (mapPosition.bounds) {
        map.current?.fitBounds(mapPosition.bounds, { padding: 20 });
      }
    }
  }, [chartOptions, palette]);

  return (
    <div
      ref={mapContainer}
      className="map-container"
      style={{
        height: height - 6,
        width: width ? width : "100%",
        marginTop: 6,
      }}
    />
  );
};

export default MapboxBubbleChart;
