import { Form, Input, Modal, Typography } from "antd";
import { useForm } from "antd/es/form/Form";
import React, { useEffect } from "react";
import type { InjectedAntUtilsProps } from "../../../../../../components/ant-utils/withAntUtils";
import { withAntUtils } from "../../../../../../components/ant-utils/withAntUtils";
import { compose } from "../../../../../../components/compose/WlyCompose";

interface IRenameRadarModalProps {
  open: boolean;
  initialValue?: RenameRadarFormValue;
  onClose: () => void;
  onRename: (values: RenameRadarFormValue) => Promise<any>;
}

interface RenameRadarFormValue {
  name: string;
}

type Props = IRenameRadarModalProps & InjectedAntUtilsProps;

function RenameRadarModal(props: Props) {
  const { open, onClose, onRename, initialValue, antUtils } = props;

  const [form] = useForm<RenameRadarFormValue>();

  const [loading, setLoading] = React.useState<boolean>(false);

  useEffect(() => {
    if (open) {
      form.setFieldValue("name", initialValue?.name);
    }
  }, [open]);

  return (
    <Modal
      open={open}
      title={"Rename radar"}
      onOk={async () => form.submit()}
      onCancel={onClose}
      confirmLoading={loading}
    >
      <Form
        form={form}
        initialValues={initialValue}
        layout="vertical"
        onFinish={async (v) => {
          try {
            setLoading(true);
            await form.validateFields();
            await onRename(v);
            onClose();
          } catch (err) {
            console.error(err);
            antUtils.message.error(
              "An unexpected error happened, please retry"
            );
          } finally {
            setLoading(false);
          }
        }}
      >
        <div>
          <Typography.Text>
            <Typography.Text type="danger">*</Typography.Text> Name
          </Typography.Text>
          <div style={{ display: "flex", paddingTop: 12 }}>
            <div style={{ flex: 1 }}>
              <Form.Item
                required
                rules={[{ required: true }]}
                label={null}
                name="name"
              >
                <Input />
              </Form.Item>
            </div>
          </div>
        </div>
      </Form>
    </Modal>
  );
}

export default compose<Props, IRenameRadarModalProps>(withAntUtils)(
  RenameRadarModal
);
