import { useForm } from "antd/es/form/Form";
import { SimpleFormModal } from "../../../../../../components/form/modal/SimpleFormModal";
import type { ShareObjectViewFormData } from "../forms/ShareObjectViewForm";
import { ShareObjectViewForm } from "../forms/ShareObjectViewForm";

type Props = {
  open: boolean;
  initialValues?: Partial<ShareObjectViewFormData>;
  isSaving: boolean;
  onCancel: () => void;
  onSave: (values: ShareObjectViewFormData) => any;
};

export default function ShareObjectViewModal({
  open,
  initialValues,
  isSaving,
  onCancel,
  onSave,
}: Props) {
  const [form] = useForm<ShareObjectViewFormData>();

  return (
    <SimpleFormModal<ShareObjectViewFormData>
      form={form}
      open={open}
      onCancel={onCancel}
      isSaving={isSaving}
      modalProps={{ title: "Set visibility", okText: "Save", width: 420 }}
    >
      <ShareObjectViewForm
        form={form}
        initialValues={initialValues}
        onSave={onSave}
      />
    </SimpleFormModal>
  );
}
