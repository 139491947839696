import { LockFilled, LockOutlined } from "@ant-design/icons";
import {
  Alert,
  Button,
  Col,
  Form,
  Input,
  Row,
  Slider,
  Space,
  Switch,
  Tag,
  Typography,
} from "antd";
import { useForm } from "antd/lib/form/Form";
import * as _ from "lodash";
import * as React from "react";
import type { RouteComponentProps } from "react-router";
import { withRouter } from "react-router";
import type { InjectedAntUtilsProps } from "../../../../../../components/ant-utils/withAntUtils";
import { withAntUtils } from "../../../../../../components/ant-utils/withAntUtils";
import { WlyCard } from "../../../../../../components/cards/WlyCard";
import { compose } from "../../../../../../components/compose/WlyCompose";
import Aligner from "../../../../../../components/layout/aligner/Aligner";
import Feednack from "../../../../../../components/layout/feedback/feedback";
import type { SyncPeriod } from "../../../../../../interfaces/destinations";
import { IOrgFeatureType } from "../../../../../../interfaces/org";
import type { InjectedOrgProps } from "../../../../../orgs/WithOrg";
import WithOrg, { getCurrentWarehouse } from "../../../../../orgs/WithOrg";
import type { FetchedDestination } from "../../../domain";
import type { UpdateDestination } from "./domain";

const { Title } = Typography;

interface IModelsSyncFormProps {
  destination?: FetchedDestination;
  onDestinationUpdate?: UpdateDestination;
}

const marks = {
  0: "5min",
  1: "15min",
  2: "30min",
  3: "1h",
  4: "2h",
  5: "3h",
  6: "6h",
  7: "8h",
  8: "12h",
  9: "24h",
};

const marksToPeriod: { [mark: number]: SyncPeriod } = {
  0: "five_minutes",
  1: "fifteen_minutes",
  2: "thirty_minutes",
  3: "one_hour",
  4: "two_hours",
  5: "three_hours",
  6: "six_hours",
  7: "eight_hours",
  8: "twelve_hours",
  9: "twenty_four_hours",
};

const periodToMarks: { [period: string]: any } = _.invert(marksToPeriod);

type Props = IModelsSyncFormProps &
  InjectedOrgProps &
  InjectedAntUtilsProps &
  RouteComponentProps<{ warehouseSlug?: string }>;

interface FormValues {
  destination: {
    activate: boolean;
    schedule?: number;
  };
  dbt?: {
    api_key: string;
    account_id: string;
    project_id: string;
  };
}

function ModelsSyncForm(props: Props) {
  const { antUtils, destination, onDestinationUpdate, orgFeatures } = props;

  const currentWarehouse = getCurrentWarehouse(
    props.org,
    props.match.params.warehouseSlug
  );

  const orgHasAccess =
    orgFeatures.includes(IOrgFeatureType.MODELS_SYNC_FEATURE_API_NAME) &&
    currentWarehouse &&
    !currentWarehouse?.destinationMeta?.isWhalyManaged;

  const [submitting, setSubmitting] = React.useState<boolean>(false);

  const [form] = useForm<FormValues>();

  const renderInner = () => {
    if (!currentWarehouse) {
      return <Feednack>Please connect a warehouse</Feednack>;
    }

    if (!destination) {
      return <Feednack>You should have a warehouse connected...</Feednack>;
    }

    const activeMarks = periodToMarks[destination.dbtCloudSyncPeriod];
    const initialData: FormValues = {
      destination: {
        activate: destination.isDbtCloudSyncEnabled,
        schedule: activeMarks,
      },
      dbt: {
        account_id: destination.dbtAccountId,
        api_key: destination.dbtApiKey,
        project_id: destination.dbtProjectId,
      },
    };

    const onSave = async (values: FormValues) => {
      setSubmitting(true);
      try {
        if (!onDestinationUpdate) {
          throw new Error("Can't perform this operation");
        }
        await form.validateFields();
        await onDestinationUpdate(currentWarehouse.id, {
          isDbtCloudSyncEnabled: values.destination.activate,
          dbtCloudSyncStatus: "idle",
          dbtCloudSyncNextSyncDate: values.destination.schedule
            ? new Date()
            : null,
          dbtCloudSyncPeriod: values.destination.schedule
            ? marksToPeriod[values.destination.schedule]
            : null,
          dbtAccountId: values.dbt ? values.dbt.account_id : null,
          dbtApiKey: values.dbt ? values.dbt.api_key : null,
          dbtProjectId: values.dbt ? values.dbt.project_id : null,
        });
        antUtils.message.success("Successfully updated modeling settings", 2);
      } catch (err) {
        console.error(err);
        antUtils.message.error("there was an error saving settings...", 2);
      } finally {
        setSubmitting(false);
      }
    };
    return (
      <Form
        layout="vertical"
        form={form}
        disabled={!orgHasAccess}
        initialValues={initialData}
        onFinish={onSave}
        onValuesChange={(v) => {
          if (v?.destination?.activate === true) {
            form.setFieldsValue({
              destination: {
                schedule: 3,
              },
            });
          }
        }}
      >
        <Space direction="vertical" size={32} style={{ width: "100%" }}>
          <div style={{ paddingTop: 16, display: "flex" }}>
            <div style={{ flex: 1 }}>
              <Title style={{ marginBottom: 0 }} level={3}>
                {orgHasAccess ? (
                  "Models Sync"
                ) : (
                  <>
                    <LockOutlined /> Models Sync
                  </>
                )}
              </Title>
            </div>
            <div>
              <Button
                type="primary"
                htmlType="submit"
                disabled={submitting || !onDestinationUpdate || !orgHasAccess}
                loading={submitting}
              >
                Save
              </Button>
            </div>
          </div>
          <div>
            <Typography.Text>
              Models sync is a set of integrations that enable Whaly to import
              Models managed by an external modelling solution (dbt Cloud, dbt
              Core, Keboola, Dataform). This help surface to Data Consumers all
              the metadata that were produced in the Modelling layer including:
              Model Documentation, Source freshness checks, Models tests and
              lineage. Being able to share those informations with Data
              consumers by makig it available in Whaly is important to build
              trust with your stakeholders and make sure that important facts
              such as pipelines failures are communicated through warnings in
              the produced Dashboards.
            </Typography.Text>
          </div>
          {!orgHasAccess ? (
            <Alert
              message={
                <div style={{ display: "flex" }}>
                  <div>Your plan doesn't have access to this feature yet.</div>
                </div>
              }
              type="warning"
            />
          ) : undefined}
          <WlyCard
            title={
              <>
                <img
                  src="https://cdn.whaly.io/modelling_engines/dbt.png"
                  style={{ width: 16, height: 16, marginRight: 8 }}
                />
                dbt Cloud{" "}
                <a href="https://docs.whaly.io/data-management/workbench/integrating-with-dbt-cloud/configuration">
                  (Documentation)
                </a>
              </>
            }
          >
            <Form.Item
              name={["destination", "activate"]}
              label={"Activate dbt Cloud Sync"}
              valuePropName={"checked"}
            >
              <Switch
                disabled={!orgHasAccess}
                checkedChildren={!orgHasAccess ? <LockFilled /> : undefined}
                unCheckedChildren={!orgHasAccess ? <LockFilled /> : undefined}
              />
            </Form.Item>
            <Form.Item noStyle shouldUpdate={true}>
              {() => {
                if (form.getFieldValue(["destination", "activate"])) {
                  return (
                    <>
                      <Form.Item
                        name={["destination", "schedule"]}
                        label={"Run every"}
                        help={
                          "This will trigger a periodic job that will download your manifest.json from dbt Cloud and sync it with Whaly."
                        }
                        style={{
                          paddingBottom: 24,
                        }}
                      >
                        <Slider
                          disabled={!orgHasAccess}
                          min={0}
                          max={9}
                          trackStyle={{ backgroundColor: "#3A5C83" }}
                          marks={marks}
                          tooltip={{ open: false }}
                          dots={true}
                        />
                      </Form.Item>
                      <Form.Item
                        name={["dbt", "api_key"]}
                        label={"dbt Cloud Service Token"}
                        rules={[{ required: true }]}
                      >
                        <Input type="password" disabled={!orgHasAccess} />
                      </Form.Item>
                      <Form.Item
                        name={["dbt", "account_id"]}
                        label={"dbt Cloud Account ID"}
                        rules={[{ required: true }]}
                      >
                        <Input disabled={!orgHasAccess} />
                      </Form.Item>
                      <Form.Item
                        name={["dbt", "project_id"]}
                        label={"dbt Cloud Project ID"}
                        rules={[{ required: true }]}
                      >
                        <Input disabled={!orgHasAccess} />
                      </Form.Item>
                    </>
                  );
                }
                return;
              }}
            </Form.Item>
          </WlyCard>
          <WlyCard>
            <Typography.Text strong style={{ marginRight: 8 }}>
              <img
                src="https://cdn.whaly.io/modelling_engines/dbt.png"
                style={{ width: 16, height: 16, marginRight: 8 }}
              />
              dbt Core
            </Typography.Text>
            <Tag color="yellow">Coming soon</Tag>
          </WlyCard>
          <WlyCard>
            <Typography.Text strong style={{ marginRight: 8 }}>
              <img
                src="https://cdn.whaly.io/modelling_engines/keboola.png"
                style={{ width: 16, height: 16, marginRight: 8 }}
              />
              Keboola
            </Typography.Text>
            <Tag color="yellow">Coming soon</Tag>
          </WlyCard>
          <WlyCard>
            <Typography.Text strong style={{ marginRight: 8 }}>
              <img
                src="https://cdn.whaly.io/modelling_engines/dataform.png"
                style={{ width: 16, height: 16, marginRight: 8 }}
              />
              Dataform
            </Typography.Text>
            <Tag color="yellow">Coming soon</Tag>
          </WlyCard>
        </Space>
      </Form>
    );
  };

  return (
    <Aligner>
      <Row justify="center" className="access-management">
        <Col xs={24} sm={24} md={20} lg={20} xl={20}>
          {renderInner()}
        </Col>
      </Row>
    </Aligner>
  );
}

export default compose<Props, IModelsSyncFormProps>(
  withRouter,
  WithOrg,
  withAntUtils
)(ModelsSyncForm);
