import * as React from "react";
import { useDrop } from "react-dnd";
import type { DraggableMeasure } from "../../../../../components/measures/measure-item/DraggableMeasureItem";
import type QueryBuilder from "../query-builder/QueryBuilder";
import "./ChartQueryBuilderDNDOverlay.scss";

interface IChartQueryBuilderDNDOverlayProps {
  formRef: React.RefObject<QueryBuilder>;
}

const getClassName = (
  isDragging: boolean = false,
  canDrop: boolean = false
) => {
  const lessened = "chart-dnd-overlay-cant-drop";
  const highlighted = "chart-dnd-overlay-can-drop";
  if (!isDragging) return undefined;
  if (canDrop) {
    return highlighted;
  } else {
    return lessened;
  }
};

const isAllowedToDrop = (availableMeasures, item) => {
  if (item?.zone !== "measure_store") return false;
  return availableMeasures.map((m) => m.key).includes(item?.id) ? true : false;
};

export const ChartQueryBuilderDNDOverlay: React.FunctionComponent<
  IChartQueryBuilderDNDOverlayProps
> = (props) => {
  const { formRef } = props;
  const [canDrop, setCanDrop] = React.useState<boolean>(false);
  React.useEffect(() => {}, [props]);
  const overlayRef = React.useRef<HTMLDivElement>();

  const availableMeasures = formRef.current
    ? formRef.current.listAvailableMeasureKeys()
    : [];

  const onMeasureDrop = (v: string) => {
    if (formRef.current) {
      formRef.current.onExternalMeasureAdd(v);
    }
  };

  const [{ isOver, isDragging, what }, drop] = useDrop<
    DraggableMeasure,
    void,
    {
      isOver: boolean;
      isDragging: boolean;
      what: DraggableMeasure;
    }
  >(
    () => ({
      accept: ["metric", "dimension"],
      collect: (monitor) => ({
        isOver: !!monitor.isOver(),
        isDragging: !!monitor.getItem(),
        what: monitor.getItem(),
      }),
      canDrop: (item) => {
        if (item.zone !== "measure_store") {
          // we should check if measure is available there
          setCanDrop(false);
          return false;
        }
        const allowed = isAllowedToDrop(availableMeasures, item);
        setCanDrop(allowed);
        return allowed;
      },
      drop: (item: DraggableMeasure, monitor) => {
        const didDrop = monitor.didDrop();
        // didDrop becomes true when a nested zone catched the drop
        if (didDrop) return;
        onMeasureDrop(item.id);
      },
    }),
    [availableMeasures]
  );

  drop(overlayRef);

  const classNames = ["chart-dnd-overlay"];

  if (isDragging) {
    classNames.push("chart-dnd-overlay-dragging");
  }
  const additionalClass = getClassName(
    isDragging,
    isAllowedToDrop(availableMeasures, what)
  );

  if (additionalClass && isOver) {
    classNames.push(additionalClass);
  }

  return <div className={classNames.join(" ")} ref={overlayRef} />;
};
