import { gql } from "@apollo/client";
import type { QueryOptions } from "../../../../../components/hooks/query/useGQLQuery";
import { useGQLQuery } from "../../../../../components/hooks/query/useGQLQuery";
import type { ITopic } from "../../../../../interfaces/topic";

type QueryData = {
  Topic: ITopic;
};

type QueryVariables = {
  topicId: string;
};

const NotificationTopicSubscribersQuery = gql`
  query getTopicSubscribers($topicId: ID!) {
    Topic(where: { id: $topicId }) {
      id
      subscribers(where: { isDeleted: false, user: { isDeleted: false } }) {
        id
        user {
          id
          firstName
          lastName
          email
          gravatar
          avatarColor
        }
      }
    }
  }
`;

export const useGetTopicSubscribers = (
  options: QueryOptions<QueryData, QueryVariables>
) => {
  return useGQLQuery<QueryData, QueryVariables>(
    NotificationTopicSubscribersQuery,
    options
  );
};
