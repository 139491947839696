import { LoadingOutlined } from "@ant-design/icons";
import _ from "lodash";
import { TypeAnimation } from "react-type-animation";

interface ILongLoadingProps {
  color?: string;
}

const messages = [
  "Aligning Covariance Matrices",
  "Building Data Trees",
  "Calculating Inverse Probability Matrices",
  "Coalescing Cloud Formations",
  "Compounding Inert Tessellations",
  "Computing Optimal Bin Packing",
  "Cohorting Exemplars",
  "Containing Existential Buffer",
  "Deciding What Message to Display Next",
  "Decomposing Singular Values",
  "Dicing Models",
  "Generating Jobs",
  "Flushing Pipe Network",
  "Initializing Robotic Click-Path AI",
  "Graphing Whale Migration",
  "Integrating Curves",
  "Obfuscating Quigley Matrix",
  "Realigning Alternate Time Frames",
  "Searching for Whales",
  "Normalizing Power",
  "Relaxing Splines",
  "Reticulating Splines",
  "Routing Neural Network Infanstructure",
];

export function LongLoading(props: ILongLoadingProps) {
  const { color: colorProps } = props;

  const color = colorProps ? colorProps : "#fff";

  const sequence = _.shuffle(messages).reduce(
    (acc, v) => {
      return [...acc, v, 5000];
    },
    ["Loading...", 500, "L", 0, "Loading...", 500]
  );

  return (
    <div>
      <LoadingOutlined style={{ fontSize: "32px", color: color }} />
      <TypeAnimation
        sequence={sequence}
        wrapper="div"
        cursor={true}
        repeat={Infinity}
        style={{ fontSize: 24, color: color, fontWeight: "bold" }}
      />
    </div>
  );
}
