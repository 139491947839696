import { Button } from "antd";
import type { FormInstance } from "antd/lib/form/Form";
import * as React from "react";
import Feednack from "../../../../../../../components/layout/feedback/feedback";
import Loading from "../../../../../../../components/layout/feedback/loading";
import type { AsyncData } from "../../../../../../../helpers/typescriptHelpers";
import type { IDataset } from "../../../../../../../interfaces/sources";
import type {
  SchemaResult,
  Transformation,
} from "../../../../../../../interfaces/transformations";
import type { BasicTableTabItem } from "../../../../../../spreadsheet/domain";
import * as Toolbar from "../../../../../../spreadsheet/toolbar/Toolbar";
import type { CanvasNodeGenerator } from "../helpers/CanvasNodeGenerator";
import { OperationHandler } from "./OperationHandler";
import { getDisplayName, type FlowOperationFormOperation } from "./domain";

import type { IDestination } from "../../../../../../../interfaces/destinations";
import "./OperationRenderer.scss";

interface IOperationRendererProps {
  operation: Transformation;
  onModifyOperation?: (o: FlowOperationFormOperation[]) => Promise<void>;
  schema: AsyncData<SchemaResult>;
  transformations: Transformation[];
  height: number;
  currentTabItem?: BasicTableTabItem;
  canvasNodeGenerator: CanvasNodeGenerator;
  datasets: IDataset[];
  onNodeHighlight?: (nodeId: string, hovering: boolean) => void;
  currentWarehouse: IDestination;
}

export function OperationRenderer(props: IOperationRendererProps) {
  const {
    operation,
    onModifyOperation,
    schema,
    transformations,
    datasets,
    canvasNodeGenerator,
    onNodeHighlight,
    currentWarehouse,
  } = props;
  const [formInstance, setFormInstance] = React.useState<FormInstance>();
  const [operationHandler, setOperationHandler] =
    React.useState<OperationHandler>(new OperationHandler(operation));
  const [isStale, setIsStale] = React.useState<boolean>(false);
  const [isSubmitting, setSubmitting] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (operation.var) {
      setOperationHandler(new OperationHandler(operation));
      setIsStale(false);
      setSubmitting(false);
    }
  }, [setOperationHandler, operation.var]);

  const renderForm = () => {
    if (!schema || schema.status === "loading" || schema.status === "initial") {
      return (
        <Feednack>
          <Loading />
        </Feednack>
      );
    }
    if (schema.status === "error") {
      return <Feednack>Error loading data</Feednack>;
    }
    return operationHandler.getForm({
      prevStepSchema: schema.data,
      currentTransformation: operation,
      onSave: onModifyOperation,
      datasets: datasets,
      setSubmitting: setSubmitting,
      transformations: transformations,
      setFormInstance: setFormInstance,
      canvasNodeGenerator: canvasNodeGenerator,
      isStale: setIsStale,
      onNodeHighlight: onNodeHighlight,
      currentWarehouse: currentWarehouse,
    });
  };

  return (
    <div style={{ flex: "0 0 380px", width: 380 }}>
      <div className="operation-renderer">
        <Toolbar.Toolbar style={{ borderTop: "none" }}>
          <Toolbar.ViewName>
            {getDisplayName(operation.operation.type)}
          </Toolbar.ViewName>
        </Toolbar.Toolbar>
        <div className="operation-renderer-content">
          <div className="operation-renderer-content-inner">{renderForm()}</div>
        </div>
        <div className="operation-renderer-footer">
          <Button
            onClick={() => {
              if (formInstance) {
                formInstance.validateFields().then(() => {
                  return formInstance.submit();
                });
              }
            }}
            size="small"
            type="primary"
            disabled={!isStale || !onModifyOperation}
            loading={isSubmitting}
          >
            Update
          </Button>
        </div>
      </div>
    </div>
  );
}
