import { SourceImageRenderer } from "../../../../../../../components/sources/SourceImageRenderer";
import "./RelationshipSideRenderer.scss";

export interface IRelationshipRendererProps {
  isModel?: boolean;
  datasetName?: string;
  datasetImg?: string;
  columnName?: string;
}

export function RelationshipSideRenderer(props: IRelationshipRendererProps) {
  const { isModel, datasetName, datasetImg, columnName } = props;

  let img = datasetImg;
  if (!img) {
    img = "warning";
  }
  return (
    <div className="relationship-side-container">
      <div className="relationship-side-image">
        <SourceImageRenderer
          alt={"sourceName"}
          className="source-table-selection-logo"
          img={img}
          size={24}
          isModel={isModel}
        />
      </div>
      <div className="relationship-side-info">
        <div className="relationship-side-text">
          {datasetName ? datasetName : "No dataset found"}
        </div>
        <div className="relationship-side-text relationship-side-column">
          {columnName ? columnName : "No column found"}
        </div>
      </div>
    </div>
  );
}
