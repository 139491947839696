import { Typography } from "antd";

import React from "react";
import type { IObjectLayoutType } from "../../../../../interfaces/object";
import {
  emailWidgetObjectData,
  layoutObjectData,
  widgetObjectData,
} from "../../../../v2-demo/container/record/component/domain";
import "./ObjectLayoutEditorAdd.scss";
import { ObjectLayoutEditorAddItem } from "./ObjectLayoutEditorAddItem";
import { IObjectLayoutItemType } from "./domain";

interface IObjectLayoutEditorAddProps {
  type: IObjectLayoutType;
}

export function ObjectLayoutEditorAdd(props: IObjectLayoutEditorAddProps) {
  const { type } = props;

  const layoutData: Array<{
    icon: React.ReactNode;
    title: string;
    id: string;
  }> = Object.keys(layoutObjectData).map((k) => {
    return {
      id: k,
      title: layoutObjectData[k].title,
      icon: layoutObjectData[k].icon,
    };
  });

  const widgetData: Array<{
    icon: React.ReactNode;
    title: string;
    id: string;
  }> = Object.keys(widgetObjectData).map((k) => {
    return {
      id: k,
      title: widgetObjectData[k].title,
      icon: widgetObjectData[k].icon,
    };
  });

  const emailData: Array<{
    icon: React.ReactNode;
    title: string;
    id: string;
  }> = Object.keys(emailWidgetObjectData).map((k) => {
    return {
      id: k,
      title: emailWidgetObjectData[k].title,
      icon: emailWidgetObjectData[k].icon,
    };
  });

  return (
    <div className="layout-editor-add">
      <div>
        <div>
          <Typography.Title level={5}>Layout</Typography.Title>
        </div>
        <div className="layout-editor-add-wrapper">
          {layoutData.map((d) => {
            return (
              <ObjectLayoutEditorAddItem
                title={d.title}
                icon={d.icon}
                type={IObjectLayoutItemType.LAYOUT}
                identifier={d.id}
                key={d.id}
              />
            );
          })}
        </div>
      </div>
      <div className="layout-editor-add-section">
        <div>
          <Typography.Title level={5}>Widgets</Typography.Title>
        </div>
        <div className="layout-editor-add-wrapper">
          {(type === "RECORD" ? widgetData : emailData).map((d) => {
            return (
              <ObjectLayoutEditorAddItem
                title={d.title}
                icon={d.icon}
                type={IObjectLayoutItemType.WIDGET}
                identifier={d.id}
                key={d.id}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
}
