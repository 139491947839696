import { Button } from "antd";
import * as React from "react";
import { WlyCard } from "../../components/cards/WlyCard";
import { compose } from "../../components/compose/WlyCompose";
import type { IRealm } from "../../interfaces/realm";
import {
  authenticatedRequest,
  getAccessToken,
} from "../../services/authService";

interface IRealmInfoFormProps {
  realm: IRealm;
}

type Props = IRealmInfoFormProps;

type State = {
  isLoading: boolean;
  workOsSSOAdminUrl?: string;
};

class RealmSSOInfoForm extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    const { realm } = this.props;

    if (realm.identityProviderType === "work_os") {
      this.state = {
        isLoading: true,
      };
    } else {
      this.state = {
        isLoading: false,
      };
    }

    this.fetchWorkOSSSOAdminURL(realm);
  }

  fetchWorkOSSSOAdminURL = async (realm: IRealm): Promise<void> => {
    const ssoPortalUrlProviderUrl = `${window.WHALY_CONSTANTS.LOGIN_APP_URL}/realms/${realm.id}/sso-admin-portal-url`;

    await authenticatedRequest();
    const accessToken = getAccessToken();

    const headers = new Headers();
    headers.set("Authorization", `Bearer ${accessToken}`);

    const options: RequestInit = {
      method: "GET",
      headers,
    };
    const res = await fetch(ssoPortalUrlProviderUrl, options);
    const resJson = await res.json();
    if (resJson.url) {
      this.setState({
        isLoading: false,
        workOsSSOAdminUrl: resJson.url,
      });
    }
  };

  renderInner = (): JSX.Element => {
    const { realm } = this.props;

    if (realm.identityProviderType === "whaly") {
      const cardHeader: JSX.Element = (
        <div style={{ display: "flex", alignItems: "baseline" }}>
          <div
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            ❌ Single Sign On (SSO) is disabled
          </div>
          <Button
            type="primary"
            style={{ marginLeft: "auto" }}
            href="https://meetings.hubspot.com/nicolas-scheerens/sales-calendar"
            target="_blank"
          >
            Contact our Sales team
          </Button>
        </div>
      );
      return (
        <WlyCard title={cardHeader}>
          <div>
            <p>
              Your team is currently authenticated with login/passwords managed
              by Whaly.
              <br />
              <br />
              Upgrade your subscription to connect to your company Identity
              Provider (Google Workspace, Microsoft Azure Active Directory,
              Okta, Ping Identity, ...)
            </p>
          </div>
        </WlyCard>
      );
    } else if (realm.identityProviderType === "work_os") {
      const { isLoading, workOsSSOAdminUrl } = this.state;

      const cardHeader: JSX.Element = (
        <div style={{ display: "flex", alignItems: "baseline" }}>
          <div
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            ✅ Single Sign On (SSO) is enabled
          </div>
          {isLoading ? (
            <Button type="primary" loading style={{ marginLeft: "auto" }}>
              Loading
            </Button>
          ) : (
            <Button
              type="primary"
              style={{ marginLeft: "auto" }}
              href={workOsSSOAdminUrl}
            >
              Configure my SSO
            </Button>
          )}
        </div>
      );
      return (
        <WlyCard title={cardHeader}>
          <div>
            <p>
              Your team logins are managed by your company Identity Provider.
            </p>
            <p>
              All new users with a <b>@{realm.emailDomain}</b> email will be
              redirected to your company Identity Provider for login and will be
              automatically added to your team.
            </p>
          </div>
        </WlyCard>
      );
    } else {
      return <p>Unsupported Identity provider...</p>;
    }
  };

  public render() {
    return this.renderInner();
  }
}

export default compose<Props, IRealmInfoFormProps>()(RealmSSOInfoForm);
