import type { FormInstance } from "antd";
import { Form, Input, message, Space, Typography } from "antd";
import cuid from "cuid";
import * as React from "react";
import { WlyCard } from "../../../../../components/cards/WlyCard";
import { compose } from "../../../../../components/compose/WlyCompose";
import EmojiPicker from "../../../../../components/form/emoji-picker/EmojiPicker";
import Loading from "../../../../../components/layout/feedback/loading";
import type { IDestination } from "../../../../../interfaces/destinations";
import {
  getDatabases,
  getSchemas,
  getTables,
  WarehouseUserType,
} from "../../../../../services/BrizoService";
import type { InjectedOrgProps } from "../../../../orgs/WithOrg";
import WithOrg from "../../../../orgs/WithOrg";
import type { TableTabItem } from "../../../../spreadsheet/domain";
import type { DatasetInitialData } from "./DatasetEdition";
import type { DatasetImportDataNode } from "./ImportDatasetTreeTransfer";
import ImportDatasetTreeTransfer from "./ImportDatasetTreeTransfer";
import PrimaryKeysSelect from "./PrimaryKeysSelect";

interface IImportDatasetFormProps {
  form: FormInstance<any>;
  disabledFields?: Array<"name">;
  initialData?: DatasetInitialData;
  alreadyImportedtables: Array<TableTabItem>;
  step: "STEP_1" | "STEP_2";
  action: ImportDatasetFormAction;
  currentWarehouse: IDestination;
}

export type ImportDatasetFormAction =
  | "CREATE_SOURCE_AND_IMPORT_DATASETS"
  | "IMPORT_DATASETS"
  | "EDIT_SOURCE";

type Props = IImportDatasetFormProps & InjectedOrgProps;

function ImportDatasetForm(props: Props) {
  const {
    action,
    form,
    step,
    alreadyImportedtables,
    currentWarehouse,
    disabledFields,
    initialData,
  } = props;

  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [treeData, setTreeData] = React.useState<DatasetImportDataNode[]>();

  // we only load the databased at first render
  React.useEffect(() => {
    fetchDatabases()
      .then((db) => {
        setTreeData(
          db.map((d) => ({
            key: d.databaseName,
            title: d.databaseName,
            label: d.databaseName,
            type: "database",
            database: d.databaseName,
            databaseQuoting: d.shouldQuote,
            isLeaf: false,
            checkable: false,
          }))
        );
        setIsLoading(false);
      })
      .catch((err) => {
        message.error("Error fetching databases");
      });
  }, [currentWarehouse?.id]);

  const fetchDatabases = async () => {
    try {
      const dbs = await getDatabases(currentWarehouse.id, WarehouseUserType.BI);
      return dbs.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const fetchDatabaseSchemas = async (database: string) => {
    try {
      const schemas = await getSchemas(
        currentWarehouse.id,
        database,
        WarehouseUserType.BI
      );
      return schemas;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const fetchSchemaTables = async (database: string, schema: string) => {
    try {
      const schemas = await getTables(
        currentWarehouse.id,
        database,
        schema,
        WarehouseUserType.BI
      );
      return schemas;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const updateTreeData = (
    list: DatasetImportDataNode[],
    key: React.Key,
    children: DatasetImportDataNode[]
  ): DatasetImportDataNode[] =>
    list.map((node) => {
      if (node.key === key) {
        return {
          ...node,
          children,
        };
      }
      if (node.children) {
        return {
          ...node,
          children: updateTreeData(node.children, key, children),
        };
      }
      return node;
    });

  const onLoadData = async (node: DatasetImportDataNode) => {
    if (node.children) {
      return;
    } else if (node.type === "database") {
      try {
        const schemas = await fetchDatabaseSchemas(node.database);
        setTreeData((origin) =>
          updateTreeData(
            origin,
            node.key,
            schemas.data?.length > 0
              ? schemas.data.map((d) => ({
                  key: node.database + "@||@" + d.schemaName,
                  title: d.schemaName,
                  label: d.schemaName,
                  isLeaf: false,
                  checkable: false,
                  type: "schema",
                  database: node.database,
                  databaseQuoting: node.databaseQuoting,
                  schema: d.schemaName,
                  schemaQuoting: d.shouldQuote,
                }))
              : [
                  {
                    key: cuid(),
                    title: (
                      <Typography.Text
                        type="secondary"
                        style={{ fontStyle: "italic" }}
                      >
                        Empty
                      </Typography.Text>
                    ),
                    label: "Empty",
                    isLeaf: true,
                    checkable: false,
                    type: "schema",
                  },
                ]
          )
        );
      } catch (error) {
        message.error("Error fetching schemas");
      }
    } else if (node.type === "schema") {
      try {
        const tables = await fetchSchemaTables(node.database, node.schema);
        setTreeData((origin) =>
          updateTreeData(
            origin,
            node.key,
            tables.data?.length > 0
              ? tables.data.map((d) => ({
                  key:
                    node.database + "@||@" + node.schema + "@||@" + d.tableName,
                  title: d.tableName,
                  label: d.tableName,
                  isLeaf: true,
                  checkable: true,
                  type: "table",
                  database: node.database,
                  databaseQuoting: node.databaseQuoting,
                  schema: node.schema,
                  schemaQuoting: node.schemaQuoting,
                  table: d.tableName,
                  tableQuoting: d.shouldQuote,
                  alreadyImported:
                    alreadyImportedtables
                      .flatMap((t) => t.dataset)
                      .filter((dataset) => {
                        if (
                          ["WAREHOUSE", "TABLE"].includes(dataset.type) &&
                          dataset.warehouseDatabaseId === node.database &&
                          dataset.warehouseSchemaId === node.schema &&
                          dataset.warehouseTableId === d.tableName
                        ) {
                          return true;
                        }
                        return false;
                      }).length > 0,
                }))
              : [
                  {
                    key: cuid(),
                    title: (
                      <Typography.Text
                        type="secondary"
                        style={{ fontStyle: "italic" }}
                      >
                        Empty
                      </Typography.Text>
                    ),
                    label: "Empty",
                    isLeaf: true,
                    checkable: false,
                    type: "table",
                  },
                ]
          )
        );
      } catch (error) {
        message.error("Error fetching tables");
      }
    }
    return;
  };

  return (
    <Form
      form={form}
      layout="vertical"
      initialValues={
        initialData
          ? initialData
          : {
              name: "",
              emoji: "random",
            }
      }
      onValuesChange={(changedValues, values) => {
        if (changedValues.importTables) {
          const transferDataSource: DatasetImportDataNode[] = [];
          const flatten = (list: DatasetImportDataNode[] = []) => {
            list.forEach((item) => {
              transferDataSource.push(item);
              flatten(item.children);
            });
          };
          flatten(treeData);

          const initialValues = form
            .getFieldValue(["importTables"])
            ?.map?.((key) => {
              const item = transferDataSource.find((i) => i.key === key);
              if (!item) return null;
              return {
                name: item.table,
                overrideName: item.table,
                tableQuoting: item.tableQuoting,
                database: item.database,
                databaseQuoting: item.databaseQuoting,
                schema: item.schema,
                schemaQuoting: item.schemaQuoting,
              };
            })
            .filter((f) => f);

          form.setFieldValue("tables", initialValues);
        }
      }}
    >
      <div hidden={step !== "STEP_1"}>
        {["EDIT_SOURCE", "CREATE_SOURCE_AND_IMPORT_DATASETS"].includes(
          action
        ) && (
          <Form.Item label="Source name">
            <Input.Group compact>
              <Form.Item
                name={["emoji"]}
                noStyle
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <EmojiPicker />
              </Form.Item>
              <Form.Item
                name={["name"]}
                noStyle
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input
                  style={{ width: "Calc(100% - 38px)", marginLeft: 6 }}
                  placeholder="Your source name"
                  disabled={disabledFields?.includes("name")}
                />
              </Form.Item>
              {disabledFields?.includes("name") && (
                <Typography.Text
                  type="secondary"
                  style={{
                    marginTop: 8,
                  }}
                >
                  You can't update this source name as it's managed by dbt
                </Typography.Text>
              )}
            </Input.Group>
          </Form.Item>
        )}
        {["CREATE_SOURCE_AND_IMPORT_DATASETS", "IMPORT_DATASETS"].includes(
          action
        ) && (
          <Form.Item name={"importTables"} valuePropName="targetKeys">
            {isLoading ? (
              <Loading />
            ) : (
              <ImportDatasetTreeTransfer
                dataSource={treeData}
                loadData={onLoadData}
                onRenameNode={(node, overrideName) => {
                  const values = form.getFieldValue("tables");
                  const index = values.findIndex(
                    (v) =>
                      v.database === node.database &&
                      v.schema === node.schema &&
                      v.name === node.table
                  );
                  form.setFieldValue(
                    ["tables", index, "overrideName"],
                    overrideName && overrideName !== ""
                      ? overrideName
                      : node.title
                  );
                }}
              />
            )}
          </Form.Item>
        )}
      </div>
      <div hidden={step !== "STEP_2"}>
        <Form.Item noStyle shouldUpdate={true}>
          {() => {
            return (
              <Form.List name="tables">
                {(fields) => {
                  return (
                    <Space direction="vertical" style={{ width: "100%" }}>
                      {fields.map((field, i) => {
                        return (
                          <WlyCard
                            key={i}
                            type="inner"
                            title={form.getFieldValue([
                              "tables",
                              field.key,
                              "overrideName",
                            ])}
                          >
                            <div hidden>
                              <Form.Item name={[field.key, "name"]}>
                                <Input />
                              </Form.Item>
                              <Form.Item name={[field.key, "database"]}>
                                <Input />
                              </Form.Item>
                              <Form.Item name={[field.key, "schema"]}>
                                <Input />
                              </Form.Item>
                              <Form.Item
                                name={[field.key, "overrideName"]}
                                rules={[
                                  {
                                    required: true,
                                  },
                                ]}
                              >
                                <Input />
                              </Form.Item>
                            </div>
                            <Form.Item
                              name={[field.key, "primaryKeys"]}
                              label="Select your dataset primary keys"
                              rules={[
                                {
                                  required: true,
                                },
                              ]}
                            >
                              <PrimaryKeysSelect
                                databaseId={form.getFieldValue([
                                  "tables",
                                  field.key,
                                  "database",
                                ])}
                                schemaId={form.getFieldValue([
                                  "tables",
                                  field.key,
                                  "schema",
                                ])}
                                tableId={form.getFieldValue([
                                  "tables",
                                  field.key,
                                  "name",
                                ])}
                                currentWarehouse={currentWarehouse}
                              />
                            </Form.Item>
                          </WlyCard>
                        );
                      })}
                    </Space>
                  );
                }}
              </Form.List>
            );
          }}
        </Form.Item>
      </div>
    </Form>
  );
}

export default compose<Props, IImportDatasetFormProps>(WithOrg)(
  ImportDatasetForm
);
