import Markdoc from "@markdoc/markdoc";
import { Space } from "antd";
import React from "react";
import {
  type IObject,
  type IObjectRegisteredAction,
} from "../../../../../../../../../interfaces/object";
import { type IUser } from "../../../../../../../../../interfaces/user";
import {
  isAvailableProperty,
  type AvailableColumn,
} from "../../../../../../object/domain";
import { type IRecord } from "../../../../../domain";
import { getParsedDoc } from "../../../../email-widgets/text/domain";
import { ActionButtonRenderer } from "../../../actions/ActionButtonRenderer";
import { convertKeyToMarkdocVariable } from "../../../markdown/domain";
import {
  additionalComponents,
  nodesConfig,
} from "../../../markdown/widget/markdoc-react-component/config";
import PropertyRenderer from "../../../properties/widget/PropertyRenderer";
import { extractImageUrl, type IWidgetParsedConfig } from "../../domain";
import SuggestedProductAvatar from "../SuggestedProductAvatar";

export interface IProductSuggestionCardProps {
  object: IObject;
  data: IRecord;
  selected?: boolean;
  onSelect: (r: IRecord | null) => void;
  conf: IWidgetParsedConfig;
  columns: AvailableColumn[];
  action?: IObjectRegisteredAction;
  parentContext: string;
  user: IUser;
}

export function ProductSuggestionCard(props: IProductSuggestionCardProps) {
  const {
    object,
    onSelect,
    data,
    conf,
    columns,
    action,
    selected,
    parentContext,
    user,
  } = props;

  const badgeColumn = columns
    .filter(isAvailableProperty)
    .find((c) => c.data.key === conf.badgeKey);

  const labelColumn = columns
    .filter(isAvailableProperty)
    .find((c) => c.data.key === conf.labelKey);

  const primSource = conf.primaryText ?? "";
  const secondSource = conf.secondaryText ?? "";
  const formattedRecord = Object.keys(data).reduce((acc, v) => {
    return {
      ...acc,
      [convertKeyToMarkdocVariable(v)]: data[v],
    };
  }, {});

  const primContent = getParsedDoc(
    primSource,
    formattedRecord,
    user,
    columns,
    nodesConfig
  );
  const secondContent = getParsedDoc(
    secondSource,
    formattedRecord,
    user,
    columns,
    nodesConfig
  );
  const primHtml = Markdoc.renderers.react(
    primContent,
    React,
    additionalComponents
  );
  const secondHtml = Markdoc.renderers.react(
    secondContent,
    React,
    additionalComponents
  );

  return (
    <div
      style={{
        width: 160,
        display: "flex",
        flexDirection: "column",
        gap: 8,
        border: selected ? `1px solid #3A5C83` : "1px solid #D8D8D8",
        borderRadius: 6,
        // minHeight: 305,
        height: "100%",
        cursor: "pointer",
        paddingBottom: secondSource && secondSource.length ? 0 : 8,
      }}
      onClick={() => (selected ? onSelect(null) : onSelect(data))}
      className="wly-suggested-products-item-card"
    >
      <div
        style={{
          width: 160,
          height: 160,
          position: "relative",
        }}
      >
        <SuggestedProductAvatar
          src={extractImageUrl(data, conf)}
          fallback={conf.fallbackImageSrc}
        />
        <div style={{ position: "absolute", right: 2, top: 6 }}>
          {badgeColumn && conf.badgeKey && data[conf.badgeKey] && (
            <PropertyRenderer
              column={badgeColumn}
              object={object}
              value={data[conf.badgeKey]}
            />
          )}
        </div>
        <div style={{ position: "absolute", right: 6, bottom: -12 }}>
          <Space size={6}>
            {action ? (
              <ActionButtonRenderer
                display="ICON"
                registeredAction={action}
                type="default"
                size="small"
                shape="circle"
                record={data}
                object={object}
                contextKey={parentContext}
              />
            ) : undefined}
          </Space>
        </div>
      </div>
      {primSource && primSource.length && (
        <div
          style={{
            paddingLeft: 8,
            paddingRight: 8,
          }}
        >
          {primHtml}
        </div>
      )}
      <div
        style={{
          overflow: "hidden",
          paddingLeft: 8,
          paddingRight: 8,
          textOverflow: "ellipsis",
          WebkitBoxOrient: "vertical",
          WebkitLineClamp: 2,
          lineClamp: 2,
          fontSize: 12,
          minHeight: 38,
          display: "-webkit-box",
        }}
      >
        {labelColumn && (
          <PropertyRenderer
            value={data[conf.labelKey]}
            object={object}
            column={labelColumn}
          />
        )}
      </div>
      {secondSource && secondSource.length && (
        <div
          style={{
            paddingLeft: 8,
            paddingRight: 8,
          }}
        >
          {secondHtml}
        </div>
      )}
    </div>
  );
}
