import * as Sentry from "@sentry/react";
import * as React from "react";

interface IChartErrorHandlerProps {
  children?: React.ReactNode;
}

interface IState {
  error: boolean;
  errorMessage: any;
}

export default class ChartErrorHandler extends React.Component<
  IChartErrorHandlerProps,
  IState
> {
  constructor(props: IChartErrorHandlerProps) {
    super(props);
    this.state = {
      error: false,
      errorMessage: null,
    };
  }

  componentDidCatch(error: any, errorInfo: any) {
    console.error("something wrong happened", error, errorInfo);
    Sentry.captureException(error);
    this.setState({
      error: true,
      errorMessage: error,
    });
  }
  public render() {
    return this.state.error ? (
      <div className="chart-content">
        <div className="inner">
          <div className="chart-drawing">
            An unexpected error happened, please contact your support...
            {this.state.errorMessage}
          </div>
        </div>
      </div>
    ) : (
      this.props.children
    );
  }
}
