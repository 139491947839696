import { hasRoleAccessBoolean } from "../containers/user-settings/HasRoleAccess";
import type { IObject, IObjectView } from "../interfaces/object";
import type { IReport } from "../interfaces/reports";
import type { IUser } from "../interfaces/user";
import { IUserRoleType } from "../interfaces/user";

export const POLICIES = {
  "objectview:rename": (params: {
    user: IUser;
    orgId: string;
    view: IObjectView | "system";
  }) => {
    if (params.view === "system") {
      return false;
    }

    if (
      hasRoleAccessBoolean(IUserRoleType.BUILDER, params.user, params.orgId)
    ) {
      return true;
    }

    if (
      params.view.isPersonal &&
      params.view.createdBy?.id === params.user.id
    ) {
      return true;
    }

    return false;
  },
  "objectview:delete": (params: {
    user: IUser;
    orgId: string;
    view: IObjectView | "system";
  }) => {
    if (params.view === "system") {
      return false;
    }

    if (
      hasRoleAccessBoolean(IUserRoleType.BUILDER, params.user, params.orgId)
    ) {
      return true;
    }

    if (
      params.view.isPersonal &&
      params.view.createdBy?.id === params.user.id
    ) {
      return true;
    }

    return false;
  },
  "objectview:share": (params: {
    user: IUser;
    orgId: string;
    view: IObjectView | "system";
    object: IObject;
  }) => {
    if (params.view === "system") {
      return false;
    }

    if (params.object.defaultView?.id === params.view.id) {
      return false;
    }

    if (
      hasRoleAccessBoolean(IUserRoleType.BUILDER, params.user, params.orgId)
    ) {
      return true;
    }

    return false;
  },
  "objectview:promote": (params: {
    user: IUser;
    orgId: string;
    view: IObjectView | "system";
  }) => {
    if (
      hasRoleAccessBoolean(IUserRoleType.BUILDER, params.user, params.orgId) &&
      (params.view === "system" || params.view.isPersonal === false)
    ) {
      return true;
    }

    return false;
  },
  "objectview:updateconfig": (params: {
    user: IUser;
    orgId: string;
    view: IObjectView;
  }) => {
    if (
      hasRoleAccessBoolean(IUserRoleType.BUILDER, params.user, params.orgId)
    ) {
      return true;
    }

    if (
      params.view.isPersonal &&
      params.view.createdBy?.id === params.user.id
    ) {
      return true;
    }

    return false;
  },
  "report:edit": (params: { user: IUser; orgId: string; report: IReport }) => {
    if (
      params.report.canBeEditedByCurrentUser &&
      params.user.type === "STANDARD" &&
      !params.user.isPublicGuestUser &&
      hasRoleAccessBoolean(IUserRoleType.EDITOR, params.user, params.orgId)
    ) {
      return true;
    }

    return false;
  },
};
