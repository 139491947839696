import * as Icons from "@ant-design/icons/lib/icons";

interface IWlyDynamicIconProps {
  name: string;
  style?: React.CSSProperties;
  fallback?: React.ReactNode;
}

export function WlyDynamicIcon(props: IWlyDynamicIconProps) {
  const Icon = Icons[props.name];
  if (Icon) {
    return <Icon style={props.style} />;
  }
  if (props.fallback) {
    return <>{props.fallback}</>;
  }
  return <>?</>;
}
