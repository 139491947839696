import { Select } from "antd";
import type { IMapType } from "./Map";

const { Option } = Select;

interface IMapPickerProps {
  value?: IMapType;
  onChange?: (v: IMapType) => void;
  defaultValue?: IMapType;
  children?: React.ReactNode;
}

export default function MapPicker(props: IMapPickerProps) {
  const { value, defaultValue, onChange } = props;
  const currentMap: IMapType = value
    ? value
    : defaultValue
    ? defaultValue
    : "world";
  const onMapSelect = (s: IMapType) => {
    if (onChange) {
      onChange(s);
    }
  };
  return (
    <>
      <Select
        showSearch
        placeholder="Select a map"
        optionFilterProp="children"
        filterOption={(input, option) =>
          option.children
            .toString()
            .toLowerCase()
            .indexOf(input.toLowerCase()) >= 0
        }
        defaultValue={currentMap}
        onChange={onMapSelect}
        style={{ width: "100%" }}
        popupMatchSelectWidth={false}
      >
        <Option value="africa">Africa</Option>
        <Option value="asia">Asia</Option>
        <Option value="europe">Europe</Option>
        <Option value="europeanUnion">European Union</Option>
        <Option value="middleEast">Middle East</Option>
        <Option value="northAmerica">North America</Option>
        <Option value="northAmericaWithoutCentral">
          North America (witout central)
        </Option>
        <Option value="nordicCountries">Nordic countries</Option>
        <Option value="oceania">Oceania</Option>
        <Option value="scandinavia">Scandinavia</Option>
        <Option value="southAmerica">South America</Option>
        <Option value="world">World</Option>
      </Select>
    </>
  );
}
