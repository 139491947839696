import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Divider, Flex, Form, Input, Select } from "antd";
import { useForm } from "antd/es/form/Form";
import { SimpleFormModal } from "../../../../../../../../components/form/modal/SimpleFormModal";
import ActionIconPicker from "../../../../../../../../components/icons/action-icon-picker/ActionIconPicker";
import { type IObject } from "../../../../../../../../interfaces/object";
import { getObjectColumns } from "../../../../../../../v2-demo/container/object/domain";
import { convertKeyToMarkdocVariable } from "../../../../../../../v2-demo/container/record/component/widgets/markdown/domain";
import { MarkdocEditor } from "../../../../../../../v2-demo/container/record/component/widgets/markdown/editor/MarkdocEditor";

export interface CreateEditRegisteredActionFormData {
  id?: string;
  name: string;
  displayName: string;
  icon: string;
  description?: string;
  type: "FEEDBACK" | "EXTERNAL_LINK";
  config: string;
}

export interface ICreateRegisteredActionProps {
  open: boolean;
  initialValues?: Partial<CreateEditRegisteredActionFormData>;
  isSaving: boolean;
  onCancel: () => void;
  onSave: (values: CreateEditRegisteredActionFormData) => any;
  object: IObject;
}

export function CreateRegisteredAction(props: ICreateRegisteredActionProps) {
  const { open, onCancel, onSave, isSaving, initialValues, object } = props;
  const [form] = useForm<CreateEditRegisteredActionFormData>();

  const iv = {
    ...initialValues,
    config:
      initialValues?.config && typeof initialValues?.config === "string"
        ? JSON.parse(initialValues?.config)
        : {},
  };

  const columns = getObjectColumns(object, true);

  return (
    <SimpleFormModal<CreateEditRegisteredActionFormData>
      form={form}
      open={open}
      onCancel={onCancel}
      isSaving={isSaving}
      modalProps={{
        title: iv?.id ? "Edit registered action" : "Register an action",
        okText: "Save",
        width: 640,
      }}
      disableEnterKey={true}
    >
      <Form
        form={form}
        preserve={false}
        layout="vertical"
        initialValues={iv}
        onFinish={async (values) => {
          try {
            const d = await form.validateFields();
            const { config, ...rest } = d;
            await onSave({
              ...rest,
              id: initialValues?.id,
              config: JSON.stringify(config),
            });
          } catch (err) {
            console.error(err);
          }
        }}
      >
        <Form.Item
          rules={[{ required: true }]}
          required
          name={["name"]}
          label="Internal Name"
        >
          <Input />
        </Form.Item>
        <Form.Item name={["description"]} label="Description">
          <Input.TextArea />
        </Form.Item>
        <Divider />
        <Flex gap={12}>
          <Flex flex={1}>
            <Form.Item
              rules={[{ required: true }]}
              required
              name={["displayName"]}
              label="Display Name"
              style={{ width: "100%" }}
            >
              <Input style={{ width: "100%" }} />
            </Form.Item>
          </Flex>
          <Form.Item
            rules={[{ required: true }]}
            required
            name={["icon"]}
            label="Display Icon"
          >
            <ActionIconPicker />
          </Form.Item>
        </Flex>
        <Form.Item
          rules={[{ required: true }]}
          required
          name={["type"]}
          label="Type"
        >
          <Select onChange={() => form.setFieldValue(["config"], {})}>
            <Select.Option value="FEEDBACK">Feedback</Select.Option>
            <Select.Option value="EXTERNAL_LINK">External Link</Select.Option>
          </Select>
        </Form.Item>

        <Divider />
        <Form.Item shouldUpdate>
          {() => {
            const val = form.getFieldValue("type");
            if (val === "EXTERNAL_LINK") {
              return (
                <Form.Item label="Link" name={["config", "linkTemplate"]}>
                  <MarkdocEditor
                    columnsSuggestions={columns.map((c) => ({
                      key: `$${convertKeyToMarkdocVariable(c.data.key)}`,
                      label: c.data.label,
                    }))}
                    height={100}
                    avoidLineBreak={true}
                  />
                </Form.Item>
              );
            }
            return (
              <Form.List
                name={["config", "reasons"]}
                rules={[
                  {
                    validator: async (_, names) => {
                      if (!names || names.length < 1) {
                        return Promise.reject(
                          new Error("Please add at least 1 reason")
                        );
                      }
                    },
                  },
                ]}
              >
                {(fields, { add, remove }, { errors }) => (
                  <>
                    {fields.map((field, index) => (
                      <Form.Item
                        label={index === 0 ? "Reasons" : ""}
                        required={false}
                        key={field.key}
                      >
                        <Flex gap={8}>
                          <Flex flex={1}>
                            <Form.Item
                              {...field}
                              validateTrigger={["onChange", "onBlur"]}
                              rules={[
                                {
                                  required: true,
                                  whitespace: true,
                                  message:
                                    "Please input a reason name or delete this field.",
                                },
                              ]}
                              noStyle
                            >
                              <Input placeholder="Reason" />
                            </Form.Item>
                          </Flex>
                          <Button
                            onClick={() => remove(field.name)}
                            type="text"
                            shape="circle"
                            icon={<MinusCircleOutlined />}
                          />
                        </Flex>
                      </Form.Item>
                    ))}
                    <Form.Item>
                      <Button
                        type="dashed"
                        onClick={() => add()}
                        icon={<PlusOutlined />}
                        block
                      >
                        Add reason
                      </Button>
                      <Form.ErrorList errors={errors} />
                    </Form.Item>
                  </>
                )}
              </Form.List>
            );
          }}
        </Form.Item>
      </Form>
    </SimpleFormModal>
  );
}
