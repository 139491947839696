import { Tag } from "antd";
import type { IObjectPropertyPillsFormatterConfig } from "../../../../interfaces/object";

interface ITagCellRendererProps {
  formatterConfig: IObjectPropertyPillsFormatterConfig;
  value: string | number | boolean;
}

const TagCellRenderer = (props: ITagCellRendererProps) => {
  const { formatterConfig, value } = props;
  const stringifiedValue = value?.toString?.();

  const matchingRule = formatterConfig.config.values.find(
    (v) => v.value === stringifiedValue
  );

  let color = formatterConfig.config.default;
  if (matchingRule) {
    color = matchingRule.color;
  }

  if (stringifiedValue && color) {
    return (
      <Tag
        bordered={false}
        style={{
          background: color,
          color: "#333333",
        }}
      >
        {stringifiedValue}
      </Tag>
    );
  } else {
    return null;
  }
};

export default TagCellRenderer;
