import { Input, Space, Tooltip, Transfer, Tree } from "antd";
import type { TransferDirection, TransferItem } from "antd/es/transfer";
import type { DataNode } from "antd/es/tree";
import * as React from "react";
import { Emoji } from "../../../../../components/form/emoji-picker/Emoji";

export interface DatasetImportDataNode extends DataNode {
  label: string;
  key: string;
  type: "database" | "schema" | "table";
  database?: string;
  databaseQuoting?: boolean;
  schema?: string;
  schemaQuoting?: boolean;
  table?: string;
  tableQuoting?: boolean;
  alreadyImported?: boolean;
  children?: DatasetImportDataNode[];
}

interface IImportDatasetTreeTransferProps {
  dataSource: DatasetImportDataNode[];
  defaultExpandedKeys?: string[];
  targetKeys?: string[];
  loadData: (node: DatasetImportDataNode) => Promise<any>;
  onChange?: (
    targetKeys: string[],
    direction: TransferDirection,
    moveKeys: string[]
  ) => void;
  onRenameNode?: (node: DatasetImportDataNode, overrideName: string) => void;
}

// Customize Table Transfer
const isChecked = (
  selectedKeys: (string | number)[],
  eventKey: string | number
) => selectedKeys.includes(eventKey);

const generateTree = (
  treeNodes: DatasetImportDataNode[] = [],
  checkedKeys: string[] = []
): DatasetImportDataNode[] =>
  treeNodes.map(({ children, ...props }) => {
    if (children) {
      return {
        ...props,
        disabled: checkedKeys.includes(props.key as string),
        children: generateTree(children, checkedKeys),
      };
    } else {
      return {
        ...props,
        disabled: checkedKeys.includes(props.key as string),
      };
    }
  });

const ImportDatasetTreeTransfer: React.FunctionComponent<
  IImportDatasetTreeTransferProps
> = (props) => {
  const {
    dataSource,
    defaultExpandedKeys,
    targetKeys,
    loadData,
    onChange,
    ...restProps
  } = props;

  const transferDataSource: TransferItem[] = [];
  function flatten(list: DataNode[] = []) {
    list.forEach((item) => {
      transferDataSource.push(item as TransferItem);
      flatten(item.children);
    });
  }
  flatten(dataSource);

  return (
    <Transfer
      {...restProps}
      onChange={onChange}
      targetKeys={targetKeys || []}
      dataSource={transferDataSource}
      className="tree-transfer"
      oneWay
      titles={["Available", "To import"]}
      render={(item) => {
        return (
          <Input
            placeholder={item.title}
            defaultValue={item.title}
            onChange={(event) =>
              props.onRenameNode(
                item as DatasetImportDataNode,
                event.target.value
              )
            }
          />
        );
      }}
      showSelectAll={false}
      listStyle={{
        height: 450,
      }}
    >
      {({ direction, onItemSelect, selectedKeys }) => {
        if (direction === "left") {
          const checkedKeys = [...selectedKeys, ...(targetKeys || [])];
          return (
            <div>
              <Tree
                blockNode
                checkable
                defaultExpandedKeys={defaultExpandedKeys ?? []}
                titleRender={(node: DatasetImportDataNode) => {
                  if (node.alreadyImported) {
                    return (
                      <Space>
                        <>{node.title}</>
                        <Tooltip title="This dataset was already imported">
                          <div style={{ height: 18 }}>
                            <Emoji emoji={":warning:"} size={16} />
                          </div>
                        </Tooltip>
                      </Space>
                    );
                  } else {
                    return <>{node.title}</>;
                  }
                }}
                height={405}
                selectable={false}
                checkedKeys={checkedKeys}
                loadData={loadData}
                treeData={generateTree(dataSource, targetKeys || [])}
                onCheck={(_, { node: { key, alreadyImported } }) => {
                  onItemSelect(key as string, !isChecked(checkedKeys, key));
                }}
              />
            </div>
          );
        }
      }}
    </Transfer>
  );
};

export default ImportDatasetTreeTransfer;
