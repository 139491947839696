import { CustomizeHomePage } from "./CustomizeHomePage";
import type { CustomizeHomeHideConfig } from "./domain";
import { parseV2HomeConfig } from "./domain";

interface ICustomizeHomePageFormItemProps {
  hide?: CustomizeHomeHideConfig;
  value?: string | undefined | null;
  onChange?: (value: string) => void;
}

export function CustomizeHomePageFormItem(
  props: ICustomizeHomePageFormItemProps
) {
  const value = props.value
    ? parseV2HomeConfig(props.value)
    : parseV2HomeConfig();

  return (
    <CustomizeHomePage
      hide={props.hide}
      color={value.color}
      layout={value.layout}
      onChange={(color, value) =>
        props.onChange?.(JSON.stringify({ color, layout: value }))
      }
    />
  );
}
