import { Form, Input, Modal } from "antd";
import { useForm } from "antd/es/form/Form";
import React from "react";
import type { InjectedAntUtilsProps } from "../../../../../../components/ant-utils/withAntUtils";
import { withAntUtils } from "../../../../../../components/ant-utils/withAntUtils";
import { compose } from "../../../../../../components/compose/WlyCompose";

interface IRenameWorksheetModalProps {
  open: boolean;
  initialValue?: RenameWorksheetFormValue;
  onClose: () => void;
  onRename: (values: RenameWorksheetFormValue) => Promise<any>;
}

interface RenameWorksheetFormValue {
  name: string;
}

type Props = IRenameWorksheetModalProps & InjectedAntUtilsProps;

function RenameWorksheetModal(props: Props) {
  const { open, onClose, onRename, initialValue, antUtils } = props;

  const [form] = useForm<RenameWorksheetFormValue>();

  const [loading, setLoading] = React.useState<boolean>(false);

  return (
    <Modal
      open={open}
      title={"Rename worksheet"}
      onOk={async () => form.submit()}
      onCancel={onClose}
      confirmLoading={loading}
    >
      <Form
        form={form}
        initialValues={initialValue}
        layout="vertical"
        onFinish={async (v) => {
          try {
            setLoading(true);
            await form.validateFields();
            await onRename(v);
            onClose();
          } catch (err) {
            console.error(err);
            antUtils.message.error(
              "An unexpected error happened, please retry"
            );
          } finally {
            setLoading(false);
          }
        }}
      >
        <Form.Item
          required
          rules={[{ required: true }]}
          label={"Name"}
          name="name"
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default compose<Props, IRenameWorksheetModalProps>(withAntUtils)(
  RenameWorksheetModal
);
