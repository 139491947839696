import type { NodeModelGenerics } from "@projectstorm/react-diagrams";
import { NodeModel, PortModelAlignment } from "@projectstorm/react-diagrams";
import type { IDataset } from "../../../../../../../../interfaces/sources";
import type { Transformation } from "../../../../../../../../interfaces/transformations";
import type { WhalyNodeModel } from "../../domain";
import type { CanvasNodeGenerator } from "../../helpers/CanvasNodeGenerator";
import { SimplePortModel } from "../port/SimplePortModel";
import { HEIGHT, WIDTH } from "./DataNodeWidget";

// prettier-ignore
export class DataNodeModel extends NodeModel<NodeModelGenerics> implements WhalyNodeModel {
  dataset: IDataset;
  transformation: Transformation;
  canvaNodeGenerator: CanvasNodeGenerator;

  constructor() {
    super({
      type: "data",
    });
    const port = new SimplePortModel(PortModelAlignment.RIGHT);
    port.setDirection("out");
    port.setMaximumLinks(1);
    this.addPort(port);

    this.width = WIDTH;
    this.height = HEIGHT;
  }

  bindDataset = (d: IDataset) => {
    this.dataset = d;
  };

  bindCanvasNodeGenerator = (c: CanvasNodeGenerator) => {
    this.canvaNodeGenerator = c;
  };

  bindTransformation = (t: Transformation) => {
    this.transformation = t;
  };

  getPort = (name: string): SimplePortModel => {
    return super.getPort(name) as SimplePortModel;
  };

  getPrimaryInPort = () => {
    return undefined;
  };

  getSecondaryInPort = () => {
    return undefined;
  };

  getOutPort = () => {
    return this.getPort(PortModelAlignment.RIGHT);
  };

  hasWarning = () => {
    return false;
  };

  isOutput = () => {
    if (
      this.canvaNodeGenerator &&
      this.transformation &&
      this.canvaNodeGenerator.outputVar === this.transformation.var
    ) {
      return true;
    }
    return false;
  };

  canEdit = () => {
    if (this.canvaNodeGenerator) {
      return this.canvaNodeGenerator.canEdit;
    }
    return false;
  };
}
