import { Tag } from "antd";
import * as React from "react";
import type { ISourceMetaDataPublicInfoLabel } from "../../../interfaces/sources";

interface ILabelRendererProps {
  label: ISourceMetaDataPublicInfoLabel;
}

export function LabelRenderer(props: ILabelRendererProps) {
  switch (props.label) {
    case "beta":
      return (
        <Tag color="#3A5C83" className="label">
          Beta
        </Tag>
      );
    case "deprecated":
      return (
        <Tag color="#AF2239" className="label">
          Deprecated
        </Tag>
      );
    case "technical":
      return (
        <Tag color="#EE5858" className="label">
          Technical
        </Tag>
      );
    case "thirdparty":
      return (
        <Tag color="#FBCE19" className="label">
          Third Party
        </Tag>
      );
    default:
      return <span />;
  }
}
