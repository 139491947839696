import { Popover, Space, Typography } from "antd";
import type { TooltipPlacement } from "antd/lib/tooltip";
import React from "react";

const { Text } = Typography;

interface IWorkbenchPopoverProps {
  description?: string;
  tags?: React.ReactNode[];
  placement: TooltipPlacement;
  children?: React.ReactNode;
  title: string;
  logo?: React.ReactNode;
}

export function WorkbenchPopover(props: IWorkbenchPopoverProps) {
  const { description, tags, placement, children, title, logo } = props;
  return (
    <Popover
      content={
        <div
          style={{
            maxWidth: 250,
          }}
        >
          <div>
            {description ? description : <Text italic>Empty description</Text>}
          </div>
          {tags && tags.length > 0 && (
            <Space style={{ paddingTop: 8 }} size={[0, 8]} wrap>
              {tags.map((tag, i) => {
                return <React.Fragment key={i}>{tag}</React.Fragment>;
              })}
            </Space>
          )}
        </div>
      }
      title={
        <div
          style={{
            display: "flex",
            alignItems: "center",
            maxWidth: 250,
          }}
        >
          {logo ? logo : null}{" "}
          <Text ellipsis strong>
            {title}
          </Text>
        </div>
      }
      trigger="hover"
      placement={placement}
      style={{ maxWidth: 250 }}
    >
      {children}
    </Popover>
  );
}
