import { Form, Input } from "antd";
import type { FormInstance } from "antd/es/form/Form";

interface ICreateObjectViewFormProps {
  form: FormInstance<CreateObjectViewFormData>;
  initialValues?: Partial<CreateObjectViewFormData>;
  onSave: (values: CreateObjectViewFormData) => any;
}

export type CreateObjectViewFormData = {
  name: string;
  query: string;
};

export function CreateObjectViewForm(props: ICreateObjectViewFormProps) {
  const { form, initialValues, onSave } = props;
  return (
    <Form<CreateObjectViewFormData>
      form={form}
      preserve={false}
      layout="vertical"
      initialValues={initialValues}
      onFinish={(values) => {
        onSave(values);
      }}
    >
      <Form.Item
        label="Name"
        name={["name"]}
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input placeholder="View name" />
      </Form.Item>
      <Form.Item
        noStyle
        hidden
        name={["query"]}
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input />
      </Form.Item>
    </Form>
  );
}
