import { Radio, Typography } from "antd";
import {
  parseAdditionalFilters,
  sanitizeFilterValues,
} from "../../../../../../../../../../components/ag-grid/object-table/domain";
import type {
  IObject,
  IObjectQueryBuilderSectionItem,
} from "../../../../../../../../../../interfaces/object";
import { parseQueryBuilderSectionItemConfidProperty } from "../../../../../../../../../workbench/workbench/viewer/object/tabs/query-builder/domain";
import type { AvailableColumn } from "../../../../../../domain";
import { OwnPropertyItemRenderer } from "../properties/OwnPropertyItemRenderer";

export interface IForeignValues {
  [key: string]: string[];
}

export interface IForeignItemRendererProps {
  object: IObject;
  item: IObjectQueryBuilderSectionItem;
  availableColumns: AvailableColumn[];
  checked?: boolean;
  onChange?: () => void;
  values?: IForeignValues;
  onValueChange?: (v: IForeignValues) => void;
}

export function ForeignItemRenderer(props: IForeignItemRendererProps) {
  const {
    item,
    onChange,
    checked,
    availableColumns,
    object,
    values,
    onValueChange,
  } = props;

  const parsedConfig = parseQueryBuilderSectionItemConfidProperty(
    item.property
  );

  const parsedFilters = parseAdditionalFilters(item.additionalFilters);
  const injectedFilters = sanitizeFilterValues(parsedFilters);

  return (
    <div
      onClick={onChange}
      style={{
        backgroundColor: "#F9F8F8",
        borderRadius: 8,
        padding: `8px 12px`,
        display: "flex",
        gap: 8,
        flexDirection: "column",
        cursor: "pointer",
      }}
    >
      <div style={{ display: "flex", gap: 8, flex: 1 }}>
        <div style={{ flex: 0 }}>
          <Radio checked={checked} />
        </div>
        <div style={{ flex: 1 }}>
          <Typography.Text ellipsis>{item.label}</Typography.Text>
        </div>
      </div>
      {parsedConfig && parsedConfig.length && checked
        ? parsedConfig.map((pc) => {
            return (
              <div style={{ flex: 1 }} key={pc.key}>
                <OwnPropertyItemRenderer
                  availableColumns={availableColumns}
                  object={object}
                  injectedFilters={injectedFilters}
                  itemPropertyConfig={pc}
                  value={values?.[pc.key]}
                  onChange={(e) => {
                    onValueChange?.({
                      ...(values || {}),
                      [pc.key]: e,
                    });
                  }}
                />
              </div>
            );
          })
        : undefined}
    </div>
  );
}
