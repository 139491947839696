export enum EmbedType {
  "home" = "home",
  "recordModal" = "recordModal",
}
export const parseLabel = (
  label: string
): { id: string; name?: string; image?: string } => {
  const [id, name, image] = (label || "").split("||");
  return {
    id,
    name,
    image,
  };
};

export interface IRecord {
  [key: string]: string | boolean | number;
}
