import type { IEmailRendererConfig } from "../domain";
import type { IEmailWidgetImageConfig } from "./domain";
import { EmailWidgetImageEditor } from "./editor/EmailWidgetImageEditor";
import { EmailWidgetImageMjml } from "./mjml/EmailWidgetImageMjml";
import { EmailWidgetImageRenderer } from "./web-widget/EmailWidgetImageRenderer";

export const emailWidgetImageDefinition: IEmailRendererConfig<IEmailWidgetImageConfig> =
  {
    parseConfig: (widget) => {
      const defaultValue: IEmailWidgetImageConfig = {
        align: "center",
        width: 50,
      };

      if (!widget.config) {
        return defaultValue;
      }

      try {
        const config: IEmailWidgetImageConfig = JSON.parse(widget.config);
        return config;
      } catch (err) {
        console.error(err);
        return defaultValue;
      }
    },
    renderEditor: (widget, object, record, conf, org, user, form, datasets) => {
      return (
        <EmailWidgetImageEditor
          widget={widget}
          object={object}
          record={record}
          conf={conf}
          org={org}
          user={user}
          form={form}
          datasets={datasets}
        />
      );
    },
    renderComponent: (widget, object, record, conf, org, user, edit) => {
      return (
        <EmailWidgetImageRenderer
          widget={widget}
          object={object}
          record={record}
          conf={conf}
          org={org}
          user={user}
          edit={edit}
        />
      );
    },
    renderMjml: EmailWidgetImageMjml,
  };
