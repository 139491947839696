import { Card, Col, Empty, List, Row, Skeleton, Typography } from "antd";
import { inject, observer } from "mobx-react";
import moment from "moment";
import type { RouteComponentProps } from "react-router";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import { compose } from "../../../../../components/compose/WlyCompose";
import { IconHolder } from "../../../../../components/icons/holder/IconHolder";
import Feednack from "../../../../../components/layout/feedback/feedback";
import { FromNow } from "../../../../../components/moment/FromNow";
import { WlyDashboardIcon } from "../../../../../components/report/icons/DashboardIcon";
import { WlyQuestionIcon } from "../../../../../components/report/icons/QuestionIcon";
import type { IReport } from "../../../../../interfaces/reports";
import { routeDescriptor } from "../../../../../routes/routes";
import type { WorkspaceDatatoreProps } from "../../../../../store/dataStores/workspace/workspaceDatastore";
import GraphQl from "../../../../graphql/graphql";
import type { InjectedOrgProps } from "../../../../orgs/WithOrg";
import WithOrg from "../../../../orgs/WithOrg";
import {
  POPULAR_REPORT_GQL,
  PREVIOUS_REPORT_GQL,
} from "../../../../workspace/reports/domain";
import "./HomeBody.scss";

interface IHomeBodyProps {}

type Props = IHomeBodyProps &
  InjectedOrgProps &
  RouteComponentProps &
  WorkspaceDatatoreProps;

function HomeBody(props: Props) {
  const {
    user,
    org,
    match: { params },
  } = props;

  const sharedReports =
    props.workspaceDatastore.data.status === "success"
      ? props.workspaceDatastore.data.data.allReportFolders.flatMap((f) => {
          return f.reports.map((r) => {
            return r.id;
          });
        })
      : [];
  const personalReports = (
    props.workspaceDatastore.data.status === "success" &&
    props.workspaceDatastore.data.data.myFolder?.reports
      ? props.workspaceDatastore.data.data.myFolder.reports
      : []
  ).map((r) => {
    return r.id;
  });

  const allReportsIds = [...sharedReports, ...personalReports];

  const DashboardIcon = (
    <IconHolder
      size={20}
      background="#BE98C6"
      color="#F9F8F8"
      icon={<WlyDashboardIcon />}
    />
  );

  const QuestionIcon = (
    <IconHolder
      size={20}
      background="#69A2B0"
      color="#F9F8F8"
      icon={<WlyQuestionIcon />}
    />
  );

  return (
    <Row gutter={32} style={{ paddingBottom: 16 }}>
      <Col span={24}>
        <div style={{ display: "flex" }}>
          <div style={{ flex: 1 }}>
            <Typography.Title level={4}>Most popular</Typography.Title>
          </div>
        </div>
      </Col>
      <Col span={24}>
        <GraphQl<{
          allReportUserViews: Array<{
            id: string;
            report?: Pick<IReport, "id" | "name" | "slug" | "type">;
          }>;
        }>
          query={POPULAR_REPORT_GQL}
          variables={{
            orgId: org.id,
            userId: user.id,
            createdAfter: moment()
              .subtract(30, "days")
              .startOf("day")
              .toISOString(true),
          }}
        >
          {(result) => {
            if (result.status === "initial" || result.status === "loading") {
              const elements = [0, 1, 2];

              return (
                <List
                  dataSource={elements}
                  split={false}
                  size={"small"}
                  grid={{ gutter: 16, column: 3 }}
                  renderItem={(item) => (
                    <List.Item style={{ padding: 0, marginBottom: 8 }}>
                      <div style={{ width: "100%", display: "flex" }}>
                        <div style={{ flex: 1 }}>
                          <Skeleton.Button
                            block={true}
                            shape={"round"}
                            active
                            key={item}
                            style={{ height: 26 }}
                          />
                        </div>
                      </div>
                    </List.Item>
                  )}
                />
              );
            }
            if (result.status === "error") {
              return <Feednack>{JSON.stringify(result.error)}</Feednack>;
            }
            if (result.data.allReportUserViews.length === 0) {
              return (
                <Feednack>
                  <div>
                    <div>
                      <Empty description={<b>No popular reports</b>} />
                    </div>
                  </div>
                </Feednack>
              );
            }

            const grouped = result.data.allReportUserViews
              .filter((rp) => rp.report && allReportsIds.includes(rp.report.id))
              .reduce(
                (
                  accumulator: {
                    [key: string]: {
                      name: string;
                      slug: string;
                      type: string;
                      viewCount: number;
                    };
                  },
                  currentValue
                ) => {
                  if (!currentValue.report) {
                    return accumulator;
                  }
                  if (typeof accumulator[currentValue.report.id] === "object") {
                    accumulator[currentValue.report.id] = {
                      ...accumulator[currentValue.report.id],
                      viewCount:
                        accumulator[currentValue.report.id].viewCount + 1,
                    };
                  } else {
                    accumulator[currentValue.report.id] = {
                      name: currentValue.report.name,
                      slug: currentValue.report.slug,
                      type: currentValue.report.type,
                      viewCount: 1,
                    };
                  }
                  return accumulator;
                },
                {}
              );

            const sorted = Object.entries(grouped)
              .sort(
                ([_k1, val1], [_k2, val2]) => val2.viewCount - val1.viewCount
              )
              .slice(0, 3);

            return (
              <List
                dataSource={sorted}
                split={false}
                size={"small"}
                grid={{ gutter: 16, column: 3 }}
                renderItem={(item) => (
                  <List.Item style={{ padding: 0, marginBottom: 8 }}>
                    <Link
                      to={routeDescriptor.report.renderRoute({
                        ...params,
                        reportSlug: item[1].slug,
                      })}
                      style={{ width: "100%" }}
                    >
                      <Card
                        size="small"
                        className="homebody-card"
                        style={{ width: "100%" }}
                        bodyStyle={{
                          padding: 8,
                          display: "flex",
                          gap: 8,
                          alignItems: "center",
                        }}
                      >
                        <div style={{ width: 20 }}>
                          {item[1].type === "DASHBOARD"
                            ? DashboardIcon
                            : QuestionIcon}
                        </div>
                        <Typography.Text strong ellipsis>
                          {item[1].name}
                        </Typography.Text>
                      </Card>
                    </Link>
                  </List.Item>
                )}
              />
            );
          }}
        </GraphQl>
      </Col>
      <Col span={24} style={{ height: 34 }}></Col>

      <Col span={24}>
        <div style={{ display: "flex" }}>
          <div style={{ flex: 1 }}>
            <Typography.Title level={4}>Recently viewed</Typography.Title>
          </div>
        </div>
      </Col>
      <Col span={24}>
        <GraphQl<{
          allReportUserLastViews: Array<{
            report: IReport;
            lastViewedAt: string;
          }>;
        }>
          query={PREVIOUS_REPORT_GQL}
          variables={{
            orgId: org.id,
            userId: user.id,
          }}
        >
          {(result) => {
            if (result.status === "initial" || result.status === "loading") {
              const elements = [0, 1, 2, 3, 4, 5, 6];

              return (
                <List
                  dataSource={elements}
                  split={false}
                  size={"small"}
                  renderItem={(item) => (
                    <List.Item style={{ padding: 0, marginBottom: 8 }}>
                      <div style={{ width: "100%", display: "flex", gap: 8 }}>
                        <div style={{ flex: 1 }}>
                          <Skeleton.Button
                            block={true}
                            shape={"round"}
                            active
                            key={item}
                            style={{ height: 26 }}
                          />
                        </div>
                      </div>
                    </List.Item>
                  )}
                />
              );
            }
            if (result.status === "error") {
              return <Feednack>{JSON.stringify(result.error)}</Feednack>;
            }
            if (result.data.allReportUserLastViews.length === 0) {
              return (
                <Feednack>
                  <div>
                    <div>
                      <Empty description={<b>No recently viewed reports</b>} />
                    </div>
                  </div>
                </Feednack>
              );
            }

            return (
              <List
                dataSource={result.data.allReportUserLastViews.filter(
                  (rp) => !!rp.report
                )}
                split={false}
                size={"small"}
                renderItem={(item) => (
                  <List.Item style={{ padding: 0, marginBottom: 8 }}>
                    <Link
                      to={routeDescriptor.report.renderRoute({
                        ...params,
                        reportSlug: item.report.slug,
                      })}
                      style={{ width: "100%" }}
                    >
                      <Card
                        size="small"
                        style={{ width: "100%" }}
                        className="homebody-card"
                        bodyStyle={{
                          padding: 8,
                          display: "flex",
                          gap: 8,
                          alignItems: "center",
                        }}
                      >
                        <div>
                          {item.report.type === "DASHBOARD"
                            ? DashboardIcon
                            : QuestionIcon}
                        </div>
                        <div style={{ flex: 1, fontWeight: "bold" }}>
                          {item.report.name}
                        </div>
                        <div style={{ flex: `0 0 auto` }}>
                          <Typography.Text
                            type="secondary"
                            style={{ fontSize: 12 }}
                          >
                            <FromNow date={moment(item.lastViewedAt)} />
                          </Typography.Text>
                        </div>
                      </Card>
                    </Link>
                  </List.Item>
                )}
              />
            );
          }}
        </GraphQl>
      </Col>
    </Row>
  );
}

export default compose<Props, IHomeBodyProps>(
  WithOrg,
  withRouter,
  inject("workspaceDatastore", "workspaceUIStore"),
  observer
)(HomeBody);
