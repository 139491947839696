import type {
  chartOptionRenderer,
  ConfigType,
} from "../../../containers/chart-options/ChartOptions";
import type { IAnalysisType } from "../../../containers/exploration/single/domain";
import { WlyBarChartIcon } from "../../icons/custom-icons/WlyBarChartIcon";
import { WlyHorizontalBarChartIcon } from "../../icons/custom-icons/WlyHorizontalBarChartIcon";
import type { SingleChartDefinition } from "../domain";

export const verticalBarChartDefinition: SingleChartDefinition = {
  label: "Bar chart",
  allowTimeComparison: true,
  minMetrics: 1,
  minDimensions: 0,
  icon: <WlyBarChartIcon />,
  pivot: (analysisType: IAnalysisType) => analysisType === "CATEGORIES",
  canDisplay: (rs) => undefined,
  config: (a) => {
    const ret: Array<ConfigType> = [
      "hide-legend",
      "labels",
      "labels-stacked",
      "percent",
      "palette",
    ];
    return ret;
  },
  declarativeChartOptionRenderer: (options) => {
    const isPivoted = !!(options.query?.pivotDimensions?.length > 0);
    const isPercent = !!options.chartOptions?.percent;
    const template: chartOptionRenderer = [
      {
        label: "Chart options",
        lines: [
          {
            type: "standard",
            component: "hide-legend",
          },
          {
            type: "standard",
            component: "labels",
          },
          isPivoted &&
            !isPercent && {
              type: "standard",
              component: "labels-stacked",
            },
          isPivoted && {
            type: "standard",
            component: "percent",
          },
          {
            type: "standard",
            component: "palette",
          },
        ],
      },
    ];
    return template;
  },
  canPredict: (analysisType, query) =>
    analysisType === "TIME" &&
    (!query.pivotDimensions || query.pivotDimensions.length === 0) &&
    query.selectedDimensions.length === 0 &&
    !query.comparison,
  axis: {
    bottom: {
      editable: true,
      isTimeFormattable: (a) => a === "TIME",
    },
    left: {
      editable: true,
      scaleEditable: true,
    },
    right: {
      editable: true,
      scaleEditable: true,
    },
  },
  extractCustomizationDimensions: (d, q) => {
    return {
      dimensions: [],
    };
  },
  extractCustomizationSeries: (s, d) => {
    return {
      series: s,
      customColor: true,
      customLabel: true,
      customType: ["area", "bar", "line", "scatter"],
      showVisualization: false,
    };
  },
};

export const horizontalBarChartDefinition: SingleChartDefinition = {
  label: "Horizontal bar chart",
  allowTimeComparison: true,
  icon: <WlyHorizontalBarChartIcon />,
  minMetrics: 1,
  minDimensions: 0,
  pivot: (analysisType: IAnalysisType) => analysisType === "CATEGORIES",
  canDisplay: (rs) => undefined,
  config: ["hide-legend", "labels", "labels-stacked", "percent", "palette"],
  declarativeChartOptionRenderer: (options) => {
    const isPivoted = !!(options.query?.pivotDimensions?.length > 0);
    const isPercent = !!options.chartOptions?.percent;
    const template: chartOptionRenderer = [
      {
        label: "Chart options",
        lines: [
          {
            type: "standard",
            component: "hide-legend",
          },
          {
            type: "standard",
            component: "labels",
          },
          isPivoted &&
            !isPercent && {
              type: "standard",
              component: "labels-stacked",
            },
          isPivoted && {
            type: "standard",
            component: "percent",
          },
          {
            type: "standard",
            component: "palette",
          },
        ],
      },
    ];
    return template;
  },
  axis: {
    bottom: {
      editable: true,
      isTimeFormattable: (a) => false,
    },
    left: {
      editable: true,
      scaleEditable: true,
    },
    right: {
      editable: true,
      scaleEditable: true,
    },
  },
  canPredict: (analysisType, query) =>
    analysisType === "TIME" &&
    (!query.pivotDimensions || query.pivotDimensions.length === 0) &&
    query.selectedDimensions.length === 0 &&
    !query.comparison,
  extractCustomizationDimensions: (d, q) => {
    return {
      dimensions: [],
    };
  },
  extractCustomizationSeries: (s, d) => {
    return {
      series: s,
      customColor: true,
      customLabel: true,
      customType: ["area", "bar", "line", "scatter"],
    };
  },
};
