import { PlusOutlined } from "@ant-design/icons";
import { gql } from "@apollo/client";
import { Button, Dropdown, Skeleton, Typography } from "antd";
import type { ItemType } from "antd/es/menu/hooks/useItems";
import _ from "lodash";
import { inject, observer } from "mobx-react";
import * as React from "react";
import type { RouteComponentProps } from "react-router";
import { withRouter } from "react-router";
import type { InjectedAntUtilsProps } from "../../../components/ant-utils/withAntUtils";
import { withAntUtils } from "../../../components/ant-utils/withAntUtils";
import { compose } from "../../../components/compose/WlyCompose";
import { WlyCaretDownIcon } from "../../../components/icons/custom-icons/WlyCaretDownIcon";
import { WlyPlusFilledIcon } from "../../../components/icons/custom-icons/WlyPlusFilledIcon";
import Feednack from "../../../components/layout/feedback/feedback";
import StudioLayout from "../../../components/studio/layout/StudioLayout";
import UserAvatar from "../../../components/user/avatar/UserAvatar";
import type { AsyncData } from "../../../helpers/typescriptHelpers";
import type { IJobExecution } from "../../../interfaces/jobExecutions";
import { JobType } from "../../../interfaces/jobExecutions";
import { IOrgFeatureType } from "../../../interfaces/org";
import type { IDataset, ISource } from "../../../interfaces/sources";
import { routeDescriptor } from "../../../routes/routes";
import StorageService from "../../../services/StorageService";
import GraphQLService from "../../../services/graphql/GraphQLService";
import {
  GQL_PAGINATION_QUERY_CLAUSE_PARAMS,
  GQL_PAGINATION_VARIABLES_CLAUSE_PARAMS,
  makeRecordIterator,
} from "../../../services/graphql/PaginatedGQLService";
import {
  CONNECTOR_FRAGMENT,
  DESTINATION_FRAGMENT,
  EXPLORATION_EXTRACT_INFO_FRAGMENT,
  EXPLORATION_SECTION_FRAGMENT,
  JOB_EXECUTION_FRAGMENT,
} from "../../../store/dataStores/workspace/WorkspaceDatastoreDomain";
import type {
  ISelectorType,
  WorkbenchUIStoreProps,
} from "../../../store/workbenchUIStore";
import type { WorkspaceUIStoreProps } from "../../../store/workspaceUIStore";
import {
  DATASET_FRAGMENT,
  EXPLORATION_ITEM_FRAGMENT,
} from "../../exploration/single/domain";
import type { InjectedOrgProps } from "../../orgs/WithOrg";
import WithOrg, { getCurrentWarehouse } from "../../orgs/WithOrg";
import AppSwitcherDropdown from "../../orgs/picker/AppSwitcherDropdown";
import UserMenuDropdown from "../../orgs/picker/UserMenuDropdown";
import { WarehouseRenderer } from "../../settings/warehouse/warehouse-renderer";
import { BORDER_COLOR } from "../../v2-demo/container/layout/domain";
import type {
  FetchedDestination,
  UpdateDestination,
} from "../workbench/domain";
import { WorkbenchLoader } from "./WorkbenchLoader";
import WorkbenchPage from "./WorkbenchPage";
import type { IWorkbenchData } from "./domain";
import {
  MODEL_FOLDER_FRAGMENT,
  OBJECT_FRAGMENT,
  OBJECT_LAYOUT_FRAGMENT,
  RADAR_LIST_FRAGMENT,
  WORKSHEET_FRAGMENT,
  getValueFromCache,
} from "./domain";

interface IWorkbenchPageWrapperProps {
  hideLayout?: boolean;
  height?: number;
  renderRoute?: (params: object, query?: object | undefined) => string;
}

type Props = IWorkbenchPageWrapperProps &
  InjectedOrgProps &
  WorkbenchUIStoreProps &
  InjectedAntUtilsProps &
  WorkspaceUIStoreProps &
  RouteComponentProps<
    { organizationSlug: string; warehouseSlug?: string },
    {},
    {
      showImportDataModal: boolean;
      showCreateExplorationModal: boolean;
      activeSelector: ISelectorType;
    }
  >;

interface IState {
  data: AsyncData<IWorkbenchData>;
  saving: boolean;
}

const CANCEL_DBT_CLOUD_SYNC_WITH_JOB_EXECUTION = `
mutation StopCurrentDbtCloudSync($destinationId: ID!, $jobExecutionId: ID!) {
  updateDestination(id: $destinationId, data: {
    dbtCloudSyncStatus: idle
  }) {
    id
  }

  updateJobExecution(id: $jobExecutionId, data: {
    status: FAILED
  }) {
    id
  }
}
`;

class WorkbenchPageWrapper extends React.Component<Props, IState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      saving: false,
      data: { status: "initial" },
    };
  }

  buildKey = () => `_wly_org_${this.props.org.id}_warehouse`;

  componentDidMount() {
    const warehouseId = getCurrentWarehouse(
      this.props.org,
      this.props.match.params.warehouseSlug
    )?.id;

    if (!warehouseId && this.props.warehouses.length > 0) {
      const key = this.buildKey();
      const localWarehouseId = StorageService.getItem(key);
      if (
        localWarehouseId &&
        this.props.warehouses.find((w) => w.id === localWarehouseId)
      ) {
        this.props.history.replace(
          routeDescriptor.workbench.renderRoute({
            ...this.props.match.params,
            warehouseSlug: this.props.warehouses.find(
              (w) => w.id === localWarehouseId
            )?.slug,
          })
        );
        StorageService.setItem({
          [key]:
            this.props.warehouses.find((w) => w.id === localWarehouseId)?.id ||
            "",
        });
      } else {
        this.props.history.replace(
          routeDescriptor.workbench.renderRoute({
            ...this.props.match.params,
            warehouseSlug: this.props.warehouses[0]?.slug,
          })
        );
        StorageService.setItem({ key: this.props.warehouses[0]?.id });
      }
    } else {
      this.fetchData(this.props.org.id, warehouseId);
    }
    this.props.workbenchUIStore.setSourceEditionOpen(
      !!this.props.location.state?.showImportDataModal
    );
    this.props.workbenchUIStore.setExplorationEditionOpen(
      !!this.props.location.state?.showCreateExplorationModal
    );
    if (this.props.location.state?.activeSelector) {
      this.props.workbenchUIStore.setSelector(
        this.props.location.state?.activeSelector
      );
    }
  }

  componentDidUpdate(prevProps: Props, prevState: IState) {
    const { data } = this.state;
    const { data: prevData } = prevState;

    const warehouseId = getCurrentWarehouse(
      this.props.org,
      this.props.match.params.warehouseSlug
    )?.id;

    if (
      (this.props.org.id !== prevProps.org.id ||
        this.props.match.params.warehouseSlug !==
          prevProps.match.params.warehouseSlug) &&
      warehouseId
    ) {
      this.fetchData(this.props.org.id, warehouseId);
    }

    if (
      data.status === "success" &&
      prevData.status === "success" &&
      prevData.data.Destination?.persistenceEngineSyncStatus === "syncing" &&
      data.data.Destination?.persistenceEngineSyncStatus !== "syncing"
    ) {
      this.reloadDatasets(this.props.org.id);
    }

    if (
      data.status === "success" &&
      prevData.status === "success" &&
      prevData.data.Destination?.dbtCloudSyncStatus === "syncing" &&
      data.data.Destination?.dbtCloudSyncStatus !== "syncing"
    ) {
      this.reloadDatasets(this.props.org.id);
    }
  }

  componentWillUnmount() {
    this.setState({});
  }

  reloadDatasets = async (orgId: string) => {
    const { data } = this.state;
    if (data.status === "success") {
      const resp = await GraphQLService<{
        allDatasets: IDataset[];
        allSources: ISource[];
      }>(
        `
        ${DATASET_FRAGMENT}

      query reloadWorkbenchDataset($orgId: ID!) {       
        allDatasets(
          sortBy: name_ASC,
          where: {
            AND: [
              {
                org: {id: $orgId},
                deleted_not: true,
                OR: [
                  { isModel: true },
                  { source: { isDeleted_not: true } }
                ]
              }
            ]}
            ) {
          ...DatasetQuery
        }

        allSources(
          sortBy: name_ASC,
          where: {
            AND: [
              {
                org: { id: $orgId },
                isDeleted_not: true,
                hideFromInterface: false
              }
            ]
          }) {
          id
          name
          status
          managedBy
          sourceMeta {
            executor
            publicInfo {
              logo
            }
          }
        }
      }
      `,
        {
          orgId,
        }
      );
      this.setState({
        data: {
          ...data,
          data: {
            ...data.data,
            allDatasets: resp.allDatasets,
            allSources: resp.allSources,
          },
        },
      });
    }
  };

  fetchData = async (orgId: string, warehouseId?: string) => {
    const { user, antUtils } = this.props;
    this.setState({ data: { status: "loading" } });
    const messageKey = "workbenchLoader";
    const message = antUtils.message;
    try {
      if (warehouseId) {
        const value = getValueFromCache(user.id, orgId, warehouseId);
        if (value) {
          const defaultValue: IWorkbenchData = {
            allDatasets: [],
            allExplorations: [],
            allExplorationSections: [],
            allModelFolders: [],
            allObjects: [],
            allObjectLayouts: [],
            allRadars: [],
            allSources: [],
            allWorksheets: [],
            connectors: [],
            destinationJobExecutions: [],
          };
          this.setState(
            {
              data: {
                status: "success",
                data: {
                  ...defaultValue,
                  ...value,
                },
              },
            },
            () => {
              setTimeout(() => {
                message.loading({
                  key: messageKey,
                  content: "Refreshing your workbench",
                  duration: 0,
                });
              }, 0);
            }
          );
        }
      }

      const datasetQuery = `
      ${DATASET_FRAGMENT}

      query getWorkbenchDetailsDatasets(
        $orgId: ID!,
        $destinationId: ID!,
        ${GQL_PAGINATION_VARIABLES_CLAUSE_PARAMS}
        ) {
        allDatasets(
          ${GQL_PAGINATION_QUERY_CLAUSE_PARAMS}
          where: {
            AND: [
              {
                org: { id: $orgId },
                deleted_not: true,
                warehouse: { id: $destinationId }
                OR: [
                    { isModel: true },
                    {
                      AND:[
                        { source: { isDeleted_not: true } }
                      ]
                    }
                  ]
                }
              ]
            }) {
          ...DatasetQuery
        }
      }
      `;

      let allDatasets: IDataset[] = [];
      const datasetIt = makeRecordIterator<IDataset>(
        datasetQuery,
        {
          orgId,
          destinationId: warehouseId ? warehouseId : "doesnotmatter",
        },
        `allDatasets`
      );
      for await (const dataset of datasetIt) {
        allDatasets.push(dataset);
      }

      const otherResult = await GraphQLService(
        gql`
          ${MODEL_FOLDER_FRAGMENT}

          ${EXPLORATION_ITEM_FRAGMENT}

          ${DESTINATION_FRAGMENT}

          ${EXPLORATION_SECTION_FRAGMENT}

          ${WORKSHEET_FRAGMENT}

          ${JOB_EXECUTION_FRAGMENT}

          ${EXPLORATION_EXTRACT_INFO_FRAGMENT}

          ${CONNECTOR_FRAGMENT}

          ${OBJECT_FRAGMENT}

          ${OBJECT_LAYOUT_FRAGMENT}

          ${RADAR_LIST_FRAGMENT}

          query getWorkbenchDetails(
            $orgId: ID!
            $destinationId: ID!
            $objectId: String!
            $shouldFetchDestintation: Boolean!
            $notInlabels: [SourceMetaDataPublicInfoLabelType]
          ) {
            allRadars(
              sortBy: name_ASC
              where: {
                org: { id: $orgId }
                isDeleted_not: true
                warehouse: { id: $destinationId }
              }
            ) {
              ...RadarListFragment
            }
            allWorksheets(
              sortBy: createdAt_DESC
              where: {
                org: { id: $orgId }
                deleted_not: true
                warehouse: { id: $destinationId }
              }
            ) {
              ...WorksheetQuery
            }
            allExplorations(
              sortBy: name_ASC
              where: {
                org: { id: $orgId }
                warehouse: { id: $destinationId }
                deleted_not: true
              }
            ) {
              ...Exploration
              ...ExplorationExtractInfo
            }
            allExplorationSections(
              sortBy: name_ASC
              where: { org: { id: $orgId }, deleted_not: true }
            ) {
              ...Section
            }
            allModelFolders(
              sortBy: name_ASC
              where: {
                org: { id: $orgId }
                warehouse: { id: $destinationId }
                isDeleted_not: true
              }
            ) {
              ...ModelFolderQuery
            }
            allObjects(
              sortBy: createdAt_DESC
              where: { org: { id: $orgId }, isDeleted_not: true }
            ) {
              ...ObjectQuery
            }
            allObjectLayouts(
              where: { org: { id: $orgId }, isDeleted_not: true }
            ) {
              ...ObjectLayoutFragment
            }
            allSources(
              sortBy: name_ASC
              where: {
                AND: [
                  {
                    org: { id: $orgId }
                    isDeleted_not: true
                    hideFromInterface: false
                    warehouse: { id: $destinationId }
                  }
                ]
              }
            ) {
              id
              name
              status
              managedBy
              lastJobExecution {
                status
              }
              sourceMeta {
                executor
                publicInfo {
                  logo
                }
              }
            }
            destinationJobExecutions: allJobExecutions(
              where: {
                org: { id: $orgId }
                objectType: Destination
                objectId: $objectId
              }
              sortBy: [createdAt_DESC]
              first: 30
            ) {
              ...JobExecution
            }
            connectors: allSources(
              sortBy: name_ASC
              where: {
                AND: [
                  {
                    org: { id: $orgId }
                    isDeleted_not: true
                    hideFromInterface: false
                    warehouse: { id: $destinationId }
                    sourceMeta: {
                      executor_not: WAREHOUSE
                      publicInfo: { label_not_in: $notInlabels }
                    }
                  }
                ]
              }
            ) {
              ...ConnectorFragment
            }
            Destination(where: { id: $destinationId })
              @include(if: $shouldFetchDestintation) {
              ...Destination
            }
          }
        `,
        {
          orgId,
          destinationId: warehouseId ? warehouseId : "doesnotmatter",
          objectId: warehouseId ? warehouseId : "doesnotmatter",
          shouldFetchDestintation: !!warehouseId,
          notInLabels: user.isAdmin ? [] : ["technical"],
        }
      );

      this.setState({
        data: {
          status: "success",
          data: {
            allDatasets,
            ...otherResult,
          },
        },
      });
    } catch (err) {
      this.setState({
        data: {
          status: "error",
          error: err,
        },
      });
    } finally {
      message.destroy(messageKey);
    }
  };

  public cancelJobExecutionRun = async (exec: IJobExecution) => {
    const jobType = exec.jobType;
    const jobExecutionStatus = exec.status;
    const destination =
      this.state.data.status === "success"
        ? this.state.data.data.Destination
        : undefined;

    if (
      destination &&
      jobExecutionStatus === "RUNNING" &&
      jobType === JobType.DBT_CLOUD_SYNC
    ) {
      await GraphQLService<{
        updateDestination: {
          id: string;
        };
        updateJobExecution: {
          id: string;
        };
      }>(CANCEL_DBT_CLOUD_SYNC_WITH_JOB_EXECUTION, {
        destinationId: destination.id,
        jobExecutionId: exec.id,
      });
    }
  };

  public onRefreshJobExecutions = async () => {
    const {
      user,
      org,
      match: {
        params: { warehouseSlug },
      },
    } = this.props;
    const { data } = this.state;
    const currentWarehouse = getCurrentWarehouse(org, warehouseSlug);
    const isDocumentHidden = document.hidden;
    if (data.status === "success" && !isDocumentHidden) {
      const warehouseId = currentWarehouse?.id;
      return GraphQLService(
        `
          ${JOB_EXECUTION_FRAGMENT}
          ${CONNECTOR_FRAGMENT}

          query getWorkbenchDetailsJob(
            $orgId: ID!
            $destinationId: ID!
            $notInlabels: [SourceMetaDataPublicInfoLabelType]
          ) {
            destinationJobExecutions: allJobExecutions(
              where: { org: { id: $orgId }, objectType: Destination }
              sortBy: [createdAt_DESC]
              first: 30
            ) {
              ...JobExecution
            }
            connectors: allSources(
              sortBy: name_ASC
              where: {
                AND: [
                  {
                    org: { id: $orgId }
                    isDeleted_not: true
                    hideFromInterface: false
                    warehouse: { id: $destinationId }
                    sourceMeta: {
                      executor_not: WAREHOUSE
                      publicInfo: { label_not_in: $notInlabels }
                    }
                  }
                ]
              }
            ) {
              ...ConnectorFragment
            }
          }
        `,
        {
          orgId: this.props.org.id,
          destinationId: warehouseId ? warehouseId : "doesnotmatter",
          notInLabels: user.isAdmin ? [] : ["technical"],
        }
      ).then((r) => {
        if (
          !_.isEqual(
            r.destinationJobExecutions,
            data.data.destinationJobExecutions
          ) ||
          !_.isEqual(r.connectors, data.data.connectors)
        ) {
          this.setState({
            data: {
              ...data,
              data: {
                ...data.data,
                destinationJobExecutions: r.destinationJobExecutions,
                connectors: r.connectors,
              },
            },
          });
        }
      });
    }
  };

  public onDestinationUpdate: UpdateDestination = async (
    destinationId: string,
    data: any
  ) => {
    const { antUtils } = this.props;
    try {
      const r = await GraphQLService<{ updateDestination: FetchedDestination }>(
        `
        ${DESTINATION_FRAGMENT}

        mutation updateDestintation($destinationId: ID!, $data: DestinationUpdateInput!) {
          updateDestination(id: $destinationId, data: $data) {
            ...Destination
          }
        }
        `,
        {
          destinationId,
          data,
        }
      );
      await this.onRefreshJobExecutions();
      this.setState((s) => {
        if (s.data.status === "success") {
          return {
            ...s,
            data: {
              ...s.data,
              data: {
                ...s.data.data,
                Destination: r.updateDestination,
              },
            },
          };
        }
        return s;
      });
    } catch (err) {
      console.error(err);
      antUtils.message.error("An unexpected error happened...");
    }
  };

  public renderContent = () => {
    const {
      org,
      match: {
        params: { warehouseSlug },
      },
    } = this.props;
    const { data } = this.state;

    const currentWarehouse = getCurrentWarehouse(org, warehouseSlug);

    const warehouseId = currentWarehouse?.id;

    if (!warehouseId) {
      return (
        <Feednack>You don't seem to have access to this warehouse</Feednack>
      );
    }

    switch (data.status) {
      case "initial":
      case "loading":
        return <WorkbenchLoader />;
      case "error":
        return <Feednack>{data.error.message}</Feednack>;
      case "success":
        return (
          <WorkbenchPage
            onRefreshJobExecutions={this.onRefreshJobExecutions}
            cancelJobExecutionRun={this.cancelJobExecutionRun}
            saving={(b) => this.setState({ saving: b })}
            datasets={data.data.allDatasets}
            worksheets={data.data.allWorksheets}
            explorations={data.data.allExplorations}
            explorationSections={data.data.allExplorationSections}
            modelFolders={data.data.allModelFolders}
            radars={data.data.allRadars}
            allObjectLayouts={data.data.allObjectLayouts}
            sources={data.data.allSources}
            connectors={data.data.connectors}
            destinationJobExecutions={data.data.destinationJobExecutions}
            destination={data.data.Destination}
            height={this.props.height}
            renderRoute={this.props.renderRoute}
            onDestinationUpdate={this.onDestinationUpdate}
            objects={data.data.allObjects}
          />
        );
    }
  };

  public render() {
    const {
      hideLayout,
      user,
      warehouses,
      history,
      workbenchUIStore,
      match: { params },
      org,
    } = this.props;
    const { data } = this.state;

    if (hideLayout) {
      return this.renderContent();
    }

    const currentWarehouse = getCurrentWarehouse(org, params.warehouseSlug);

    const hasLoaded = data.status === "success";

    let leftButtons: Array<React.ReactNode> = [];
    let midButtons: Array<React.ReactNode> = [];
    let rightButtons: Array<React.ReactNode> = [];

    rightButtons.push(
      <UserMenuDropdown>
        <div
          style={{
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            border: `1px solid ${BORDER_COLOR}`,
            borderRadius: "50%",
          }}
        >
          <UserAvatar user={user} size={32} style={{ cursor: "pointer" }} />
        </div>
      </UserMenuDropdown>
    );

    if (!hasLoaded) {
      midButtons = [
        <Skeleton.Button
          key={"loading-mid"}
          shape="round"
          size="small"
          style={{ width: 280 }}
          active
        />,
      ];
      leftButtons = [
        <div
          key={"loading-left"}
          style={{
            display: "flex",
            alignItems: "center",
            marginLeft: 4,
            marginTop: 4,
          }}
        >
          <Skeleton.Button
            size="small"
            shape="round"
            style={{ width: 40, height: 20, marginRight: 12 }}
            active
          />
        </div>,
      ];
    } else {
      const { Destination } = data.data;
      leftButtons = [
        <Button
          type="text"
          key="org"
          size="small"
          onClick={() =>
            history.push(
              routeDescriptor["org-picker"].renderRoute({
                ...params,
              })
            )
          }
        >
          <Typography.Text type="secondary" strong>
            {_.capitalize(org.name)}
          </Typography.Text>
          <span
            style={{
              // transform: "scale(0.8)",
              marginLeft: 4,
            }}
          >
            <WlyCaretDownIcon style={{ color: "#939393" }} />
          </span>
        </Button>,
        <Typography.Text
          key={"divider-1"}
          type="secondary"
          className="display-none display-inline-lg"
        >
          /
        </Typography.Text>,
        <AppSwitcherDropdown key={"app-switcher-dpdown"}>
          <Button
            type="text"
            key="app"
            size="small"
            className="display-none display-inline-lg"
          >
            <Typography.Text strong>Workbench</Typography.Text>
            <span style={{ /*transform: "scale(0.8)",*/ marginLeft: 4 }}>
              <WlyCaretDownIcon />
            </span>
          </Button>
        </AppSwitcherDropdown>,

        <Typography.Text
          key={"divider-2"}
          type="secondary"
          className="display-none display-inline-lg"
        >
          /
        </Typography.Text>,
        <Dropdown
          key={"menu"}
          trigger={["click"]}
          arrow
          placement="bottomLeft"
          menu={{
            items: [
              ...warehouses.map(
                (w, i) =>
                  ({
                    key: w.id,
                    label: (
                      <WarehouseRenderer
                        key={w.id}
                        destination={w}
                        showCaret={false}
                        selected={w.id === currentWarehouse?.id}
                      />
                    ),
                    onClick: () => {
                      StorageService.setItem({
                        [this.buildKey()]: w.id,
                      });
                      history.push(
                        routeDescriptor.workbench.renderRoute({
                          organizationSlug: params.organizationSlug,
                          warehouseSlug: w.slug,
                        })
                      );
                    },
                  } as ItemType)
              ),
              {
                type: "divider",
              },
              {
                key: "add",
                onClick: () => workbenchUIStore.setWarehouseAdditionOpen(true),
                label: (
                  <span key={"connect"}>
                    <PlusOutlined /> Connect a warehouse
                  </span>
                ),
              },
            ],
          }}
        >
          <Button size="small" type="text">
            {currentWarehouse ? (
              <WarehouseRenderer
                destination={currentWarehouse}
                showCaret={true}
                secondary
                strong
              />
            ) : (
              "unknown warehouse"
            )}
          </Button>
        </Dropdown>,
      ];
      midButtons = [];
      rightButtons = [
        <Dropdown
          key={"menu-left"}
          placement="bottomRight"
          arrow
          menu={{
            items: [
              {
                key: 10,
                onClick: () =>
                  this.props.workbenchUIStore.setExplorationEditionOpen(true),
                label: "New exploration",
              },
              {
                key: 0,
                onClick: () =>
                  this.props.workbenchUIStore.setDatasetEditionOpen(true),
                label: "New model",
              },
              {
                key: 1,
                onClick: () =>
                  this.props.workbenchUIStore.setSourceEditionOpen(true),
                label: "New source",
              },
              this.props.orgFeatures.includes(IOrgFeatureType.OBJECTS)
                ? {
                    key: 12,
                    onClick: () =>
                      this.props.workbenchUIStore.setObjectAdditionOpen(true),
                    label: "New object",
                  }
                : null,
              this.props.orgFeatures.includes(IOrgFeatureType.OBJECTS)
                ? {
                    key: 13,
                    onClick: () =>
                      this.props.workbenchUIStore.setRadarAdditionOpen(true),
                    label: "New radar",
                  }
                : null,
              Destination?.isDbtCloudSyncEnabled
                ? {
                    key: 2,
                    onClick: () =>
                      this.props.workbenchUIStore.setDbtCloudSyncExecutionEditionOpen(
                        true
                      ),
                    label: "New dbt Cloud sync",
                  }
                : null,
              Destination?.isPersistenceEngineEnabled
                ? {
                    key: 3,
                    onClick: () =>
                      this.props.workbenchUIStore.setPersistenceEngineExecutionEditionOpen(
                        true
                      ),
                    label: "New persistence engine run",
                  }
                : null,
            ].filter((v) => typeof v !== "undefined"),
          }}
          trigger={["click"]}
        >
          <Button
            className="display-none display-inline-lg"
            shape="round"
            icon={
              <WlyPlusFilledIcon
                style={{
                  color: "#FCAA76",
                  fontSize: 20,
                  position: "absolute",
                  left: 6,
                  top: 5,
                }}
              />
            }
          >
            <span style={{ marginLeft: 12, marginRight: -4 }}>Create</span>
          </Button>
        </Dropdown>,
        ...rightButtons,
      ];
    }

    return (
      <StudioLayout
        homePageLink={routeDescriptor.home.renderRoute({
          organizationSlug: this.props.org.slug,
        })}
        saving={false}
        titleRenderer={null}
        theme={"WORKBENCH"}
        leftButtons={leftButtons}
        midButtons={midButtons}
        rightButtons={rightButtons}
      >
        {this.renderContent()}
      </StudioLayout>
    );
  }
}

export default compose<Props, IWorkbenchPageWrapperProps>(
  WithOrg,
  withRouter,
  withAntUtils
)(inject("workbenchUIStore")(observer(WorkbenchPageWrapper)));
