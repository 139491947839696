import { Form, Input, Select } from "antd";
import { useForm } from "antd/es/form/Form";
import React from "react";
import { SimpleFormModal } from "../../../../../../../../components/form/modal/SimpleFormModal";
import { type IObject } from "../../../../../../../../interfaces/object";
import { type CreateEditQueryBuilderSectionFormData } from "./domain";

export interface IObjectQueryBuilderCreationProps {
  open: boolean;
  initialValues?: Partial<CreateEditQueryBuilderSectionFormData>;
  onCancel: () => void;
  onSave: (values: CreateEditQueryBuilderSectionFormData) => any;
  object: IObject;
}

export function ObjectQueryBuilderCreation(
  props: IObjectQueryBuilderCreationProps
) {
  const { open, onCancel, onSave, initialValues } = props;

  const [isSaving, setisSaving] = React.useState<boolean>(false);
  const [form] = useForm<CreateEditQueryBuilderSectionFormData>();

  const iv = initialValues;

  return (
    <SimpleFormModal<CreateEditQueryBuilderSectionFormData>
      form={form}
      open={open}
      onCancel={onCancel}
      isSaving={isSaving}
      modalProps={{
        title: iv?.id ? "Edit section" : "Create a section",
        okText: "Save",
        width: 640,
      }}
      disableEnterKey={true}
    >
      <Form
        form={form}
        preserve={false}
        layout="vertical"
        initialValues={iv}
        onFinish={async (values) => {
          try {
            setisSaving(true);
            const d = await form.validateFields();
            // const { config, ...rest } = d;
            await onSave({
              ...d,
            });
            setisSaving(false);
          } catch (err) {
            console.error(err);
            setisSaving(false);
          }
        }}
      >
        <Form.Item
          rules={[{ required: true }]}
          required
          name={["name"]}
          label="Name"
        >
          <Input />
        </Form.Item>
        <Form.Item
          rules={[{ required: true }]}
          required
          name={["type"]}
          label="Section type"
        >
          <Select>
            <Select.Option value="OWN_PROPERTIES">
              Filter object own properties
            </Select.Option>
            <Select.Option value="FOREIGN_PROPERTIES">
              Filter object foreign properties
            </Select.Option>
          </Select>
        </Form.Item>
      </Form>
    </SimpleFormModal>
  );
}
