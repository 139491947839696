import {
  DeleteOutlined,
  EditOutlined,
  HolderOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import { Button, Card, Flex, Popconfirm, Typography } from "antd";
import * as React from "react";
import { type DraggableProvidedDragHandleProps } from "react-beautiful-dnd";
import { useHover } from "../../../../../../../../components/hooks/useHover";
import type {
  IObject,
  IObjectQueryBuilderSectionItem,
  IObjectQueryBuilderSectionType,
} from "../../../../../../../../interfaces/object";
import { getObjectColumns } from "../../../../../../../v2-demo/container/object/domain";
import { parseQueryBuilderSectionItemConfidProperty } from "../domain";

export interface IObjectQueryBuilderSectionItemCardProps {
  sectionType: IObjectQueryBuilderSectionType;
  sectionItem: IObjectQueryBuilderSectionItem;
  object: IObject;
  dragHandleProps: DraggableProvidedDragHandleProps | undefined;
  onRemove: () => void;
  onEdit: () => void;
}

export function ObjectQueryBuilderSectionItemCard(
  props: IObjectQueryBuilderSectionItemCardProps
) {
  const {
    sectionType,
    sectionItem,
    dragHandleProps,
    onRemove,
    onEdit,
    object,
  } = props;
  const [hoverRef, isHover] = useHover<HTMLDivElement>();
  const [confirmOpen, setConfirmOpen] = React.useState<boolean>(false);
  const id = React.useId();

  const renderCardDescription = () => {
    if (sectionType === "OWN_PROPERTIES") {
      const parsedConfig = parseQueryBuilderSectionItemConfidProperty(
        sectionItem.property
      );
      const propertyKey = parsedConfig[0]?.key;
      const foundColumn = getObjectColumns(object).find(
        (c) => c.data.key === propertyKey
      );
      if (propertyKey && foundColumn) {
        return (
          <Typography.Text>
            Filtering on{" "}
            <Typography.Text code>{foundColumn?.data.label}</Typography.Text>
          </Typography.Text>
        );
      }
      return (
        <Typography.Text italic>
          Please configure this element {id}
        </Typography.Text>
      );
    } else {
      return <Typography.Text strong>{sectionItem.label}</Typography.Text>;
    }
  };

  return (
    <div ref={hoverRef}>
      <Card key={sectionItem.id} size="small">
        <Flex align="center" gap={8}>
          <div {...dragHandleProps}>
            <HolderOutlined style={{ cursor: "move" }} />
          </div>
          <div style={{ flex: 1 }}>{renderCardDescription()}</div>
          <div style={{ height: 32 }}>
            <div hidden={!isHover && !confirmOpen}>
              <Button
                shape="circle"
                type="text"
                icon={<EditOutlined />}
                onClick={() => onEdit()}
              />
              <Popconfirm
                title="Remove the section item"
                description="Are you sure to remove this section item?"
                icon={<QuestionCircleOutlined style={{ color: "red" }} />}
                open={confirmOpen}
                onOpenChange={(open) => setConfirmOpen(open)}
                okText="Delete"
                okButtonProps={{ danger: true }}
                onConfirm={() => onRemove()}
              >
                <Button
                  shape="circle"
                  type="text"
                  icon={<DeleteOutlined />}
                  onClick={() => setConfirmOpen(true)}
                  danger
                />
              </Popconfirm>
            </div>
          </div>
        </Flex>
      </Card>
    </div>
  );
}
