import type { BinaryFilter } from "@cubejs-client/core";
import { Space, Typography } from "antd";
import { compose } from "../../../../../components/compose/WlyCompose";
import type { AvailableDimension } from "../../../../../components/measures/filter-item/FilterItem";
import type { InjectedOrgProps } from "../../../../orgs/WithOrg";
import WithOrg from "../../../../orgs/WithOrg";
import type { AvailableProperty } from "../../../../v2-demo/container/object/viewer/domain";
import { parseRowLevelFilterValue } from "./domain";

interface IRowLevelFilterDisplayProps {
  rowLevelFilters: string;
  columns: Array<AvailableDimension | AvailableProperty>;
}

type Props = IRowLevelFilterDisplayProps & InjectedOrgProps;

function RowLevelFilterDisplay(props: Props) {
  const { rowLevelFilters, columns, org } = props;
  const data = parseRowLevelFilterValue(rowLevelFilters);

  const allDimensions = columns;

  const renderOperator = (e: "equals" | "notEquals") => {
    if (e === "equals") {
      return "equals";
    } else {
      return "does not equal";
    }
  };

  const renderDimension = (key?: string) => {
    if (!key) {
      return (
        <Typography.Text type="danger" strong>
          No dimension setup
        </Typography.Text>
      );
    }
    const foundDimension = allDimensions.find((ad) => ad.key === key);
    if (foundDimension) {
      return <Typography.Text strong>{foundDimension.label}</Typography.Text>;
    }
    return (
      <Typography.Text type="danger" strong>
        Deleted Dimension
      </Typography.Text>
    );
  };

  const renderAttribute = (id?: string) => {
    if (!id) {
      return (
        <Typography.Text type="danger" strong>
          No attribute setup
        </Typography.Text>
      );
    }
    const foundAttribute = org.userAttributeMetas.find((uam) => uam.id === id);
    if (foundAttribute) {
      return <Typography.Text strong>{foundAttribute.label}</Typography.Text>;
    }
    return (
      <Typography.Text type="danger" strong>
        Deleted Attribute
      </Typography.Text>
    );
  };

  const renderLine = (f: BinaryFilter) => {
    return (
      <div>
        <div>
          Dimension {renderDimension(f.member)}{" "}
          {renderOperator(f.operator as any)} user attribute{" "}
          {renderAttribute(f.values[0])}
        </div>
      </div>
    );
  };

  return (
    <Space style={{ width: "100%" }} direction="vertical">
      <div>
        Filter rows matching{" "}
        <Typography.Text strong>
          {data.operator === "and" ? "all" : "any"}
        </Typography.Text>{" "}
        of the following user attributes:
      </div>
      {data.condition.map((c, i) => {
        return <div key={i}>{renderLine(c)}</div>;
      })}
    </Space>
  );
}

export default compose<Props, IRowLevelFilterDisplayProps>(WithOrg)(
  RowLevelFilterDisplay
);
