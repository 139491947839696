import type { Filter } from "@cubejs-client/core";
import type { ColumnSizeState } from "ag-grid-community";
import type { AgGridReact } from "ag-grid-react";
import * as React from "react";
import ObjectTable from "../../../../../../../../components/ag-grid/object-table/ObjectTable";
import type {
  ColumnsSettings,
  TableQuery,
} from "../../../../../../../../components/ag-grid/object-table/domain";
import { buildQuery } from "../../../../../../../../components/ag-grid/object-table/domain";
import { useCubeJSDatasource } from "../../../../../../../../components/ag-grid/object-table/useCubeJSDatasource";
import { useTableQuery } from "../../../../../../../../components/ag-grid/object-table/useTableQuery";
import { compose } from "../../../../../../../../components/compose/WlyCompose";
import type { IObject } from "../../../../../../../../interfaces/object";
import type { InjectedOrgProps } from "../../../../../../../orgs/WithOrg";
import WithOrg from "../../../../../../../orgs/WithOrg";
import type { AvailableColumn } from "../../../../../object/domain";
import { getDimensionPartFromTimeDimension } from "../../../../../object/viewer/domain";

interface IRelatedListQueryProps {
  object: IObject;
  columns: string[];
  filters?: Filter[];
  sortBy?: [string, "asc" | "desc"][];
  groupBy?: Array<{ id: string; agg?: string | undefined }>;
  availableColumns: AvailableColumn[];
  pageSize: number;
  hidePagination?: boolean;
  showRowNumber?: boolean;
  columnsSettings?: ColumnsSettings;
}

type Props = IRelatedListQueryProps & InjectedOrgProps;

function RelatedListQuery(props: Props) {
  const {
    org,
    columns,
    object,
    filters,
    sortBy,
    groupBy,
    pageSize,
    availableColumns,
    hidePagination,
    showRowNumber,
    columnsSettings,
  } = props;

  const gridRef = React.useRef<AgGridReact>(null);

  const csettings = columnsSettings ?? {};

  const columnSizingModel: ColumnSizeState[] = Object.keys(csettings).map(
    (key) => {
      const useFixedWidth =
        csettings[key].initialSizeType === "fixedWidth" &&
        typeof csettings[key].initialSizeWidth === "number";
      return {
        colId: key,
        flex: useFixedWidth ? 0 : 1,
        width: useFixedWidth ? csettings[key].initialSizeWidth : undefined,
      };
    }
  );

  const defaultQuery: TableQuery = {
    columnVisibility: {
      visibleColumnIds: columns,
    },
    queryBuilderItems: {},
    filters: filters ?? [],
    groups: (groupBy ?? []).map((cv) => {
      if (cv.agg) {
        return {
          groupedDimension: getDimensionPartFromTimeDimension(cv.id),
          groupedField: cv.id + "." + cv.agg,
        };
      } else {
        return {
          groupedDimension: cv.id,
          groupedField: cv.id,
        };
      }
    }),
    order: sortBy?.length ? sortBy : [],
    columnOrder: {
      orderedColIds: columns,
    },
    columnSizing: {
      columnSizingModel: columnSizingModel,
    },
  };

  const [tableQuery, setTableQuery] = useTableQuery({
    defaultQuery: defaultQuery,
    initialQuery: defaultQuery,
    getInitialQuery: () => defaultQuery,
  });

  React.useEffect(() => {
    gridRef?.current?.api?.refreshServerSide?.({ purge: true });
  }, [
    tableQuery?.presetFilters,
    tableQuery?.filters,
    tableQuery.groups,
    tableQuery?.order,
    tableQuery.columnVisibility?.visibleColumnIds,
  ]);

  const cubeJSDataSource = useCubeJSDatasource({
    buildQuery: buildQuery,
    object: object,
    org: org,
    onError: (error) => console.error(error),
    availableColumns: availableColumns,
  });

  return (
    <div
      style={{
        margin: -12,
        borderBottomLeftRadius: 8,
        borderBottomRightRadius: 8,
        overflow: "hidden",
        paddingTop: 1,
      }}
    >
      <ObjectTable
        displayType="relatedList"
        availableColumns={availableColumns}
        cubeJSDataSource={cubeJSDataSource}
        gridRef={gridRef}
        object={object}
        columnsSettings={columnsSettings}
        tableQuery={tableQuery}
        setTableQuery={setTableQuery}
        showRowNumbers={showRowNumber}
        pagination={{
          enabled: !hidePagination,
          pageSize: pageSize,
        }}
      />
    </div>
  );
}

export default compose<Props, IRelatedListQueryProps>(WithOrg)(
  RelatedListQuery
);
