import type { Query } from "@cubejs-client/core";
import { Form, InputNumber, Select, Space, Typography } from "antd";
import type { FormInstance } from "antd/lib";
import { compose } from "../../../../../../../../components/compose/WlyCompose";
import type { AvailableDimension } from "../../../../../../../../components/measures/filter-item/FilterItem";
import type { IObject } from "../../../../../../../../interfaces/object";
import {
  LagoonCallOrigin,
  lagoonServiceLoad,
} from "../../../../../../../../services/LagoonService";
import { ChartOptionCollapse } from "../../../../../../../chart-options/components/ChartOptionCollapse";
import ChartOptionLine from "../../../../../../../chart-options/components/ChartOptionLine";
import type { InjectedOrgProps } from "../../../../../../../orgs/WithOrg";
import WithOrg from "../../../../../../../orgs/WithOrg";
import { getObjectColumns } from "../../../../../object/domain";
import type { AvailableProperty } from "../../../../../object/viewer/domain";
import PropertySort from "../../../../../object/viewer/toolbar/PropertiesSelector/PropertySortSelector";
import type { IRecord } from "../../../../domain";
import type { IWidget } from "../../../domain";
import { FilterEditor } from "../../related-lists/editor/FilterEditor";
import type { IWidgetRepeatableConfig } from "../domain";

interface RepeatableWidgetEditorProps {
  widget: IWidget;
  object: IObject;
  record: IRecord;
  conf: IWidgetRepeatableConfig;
  form: FormInstance;
}

type Props = RepeatableWidgetEditorProps & InjectedOrgProps;

function RepeatableWidgetEditor(props: Props) {
  const { widget, object, record, conf, form, org } = props;

  return (
    <>
      <ChartOptionCollapse dark title="Repeatable Configuration">
        <Space size={"middle"} direction="vertical" style={{ width: "100%" }}>
          <ChartOptionLine
            items={[
              {
                content: (
                  <Form.Item
                    label="Type"
                    style={{ marginBottom: 0 }}
                    name={["config", "type"]}
                  >
                    <Select style={{ width: "100%" }}>
                      <Select.Option value="TAB">Tabs</Select.Option>
                      <Select.Option value="SECTION">Sections</Select.Option>
                      <Select.Option value="COLLAPSE">
                        Collapsible
                      </Select.Option>
                    </Select>
                  </Form.Item>
                ),
                flex: 1,
              },
            ]}
          />
          <ChartOptionLine
            items={[
              {
                content: (
                  <Form.Item
                    label="Related Object"
                    required
                    style={{ marginBottom: 0 }}
                    name={["config", "foreignObjectPropertyId"]}
                  >
                    <Select style={{ width: "100%" }}>
                      {object.foreignKeys.map((ok) => {
                        return (
                          <Select.Option
                            label={ok.object.name}
                            key={ok.id}
                            value={ok.id}
                          >
                            <div
                              style={{
                                width: "100%",
                                maxWidth: "100%",
                                overflow: "hidden",
                              }}
                            >
                              {ok.object.name}{" "}
                              <Typography.Text type="secondary">
                                on {ok.label}
                              </Typography.Text>
                            </div>
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                ),
                flex: 1,
              },
            ]}
          />
          <Form.Item noStyle shouldUpdate>
            {() => {
              const currentPropertyId = form.getFieldValue([
                "config",
                "foreignObjectPropertyId",
              ]);
              const propertyExists = currentPropertyId
                ? object.foreignKeys.find((fk) => fk.id === currentPropertyId)
                : undefined;

              if (currentPropertyId && propertyExists) {
                const availableColumns = getObjectColumns(
                  propertyExists.object
                );
                return (
                  <ChartOptionLine
                    items={[
                      {
                        content: (
                          <Form.Item
                            style={{ marginBottom: 4 }}
                            label={"Sort"}
                            name={["config", "options", "sortBy"]}
                          >
                            <PropertySort columns={availableColumns} />
                          </Form.Item>
                        ),
                        flex: 1,
                      },
                    ]}
                  />
                );
              } else {
                return (
                  <Form.Item
                    noStyle
                    hidden
                    name={["config", "options", "sortBy"]}
                  />
                );
              }
            }}
          </Form.Item>
          <Form.Item noStyle shouldUpdate>
            {() => {
              const currentPropertyId = form.getFieldValue([
                "config",
                "foreignObjectPropertyId",
              ]);
              const propertyExists = currentPropertyId
                ? object.foreignKeys.find((fk) => fk.id === currentPropertyId)
                : undefined;

              if (currentPropertyId && propertyExists) {
                const availableColumns = getObjectColumns(
                  propertyExists.object
                );
                return (
                  <ChartOptionLine
                    items={[
                      {
                        content: (
                          <Form.Item
                            required
                            noStyle
                            name={["config", "options", "filters"]}
                          >
                            <FilterEditor
                              autocomplete={async (
                                dimension,
                                operator,
                                value
                              ) => {
                                try {
                                  const dim = availableColumns.find(
                                    (ac) =>
                                      ac.type === "property" &&
                                      dimension === ac.data.key
                                  );

                                  const dimKey = (dim.data as AvailableProperty)
                                    .sortAndFilterKey;
                                  if (!dimKey) {
                                    throw new Error("Dim not found");
                                  }

                                  let query: Query = {
                                    dimensions: [dimKey],
                                    limit: 50,
                                    filters: [
                                      {
                                        member: dimKey,
                                        operator: "set",
                                      },
                                    ],
                                  };
                                  if (
                                    typeof value === "string" &&
                                    value !== "" &&
                                    operator
                                  ) {
                                    query = {
                                      dimensions: [dimKey],
                                      limit: 50,
                                      filters: [
                                        {
                                          member: dimKey,
                                          operator: operator,
                                          values: [value],
                                        },
                                      ],
                                    };
                                  }

                                  const data = await lagoonServiceLoad(
                                    org.id,
                                    query,
                                    "OBJECT",
                                    object.id,
                                    undefined,
                                    LagoonCallOrigin.WHALY_APP
                                  );

                                  const results = data.tablePivot();

                                  return results.map((r) =>
                                    r[dimKey]?.toString?.()
                                  );
                                } catch (err) {
                                  console.error(err);
                                  throw new Error(
                                    "Can't fetch recommendations"
                                  );
                                }
                              }}
                              availableDimensions={availableColumns
                                .filter((ac) => ac.type === "property")
                                .map<AvailableDimension>((ac) => {
                                  const prop = ac.data as AvailableProperty;
                                  return {
                                    key: prop.key,
                                    label: prop.label,
                                    description: prop.description,
                                    type: "standard",
                                    domain: prop.domain,
                                  };
                                })}
                            />
                          </Form.Item>
                        ),
                        flex: 1,
                      },
                    ]}
                  />
                );
              } else {
                return (
                  <Form.Item
                    noStyle
                    hidden
                    name={["config", "options", "filters"]}
                  />
                );
              }
            }}
          </Form.Item>
          <Form.Item noStyle shouldUpdate>
            {() => {
              const currentPropertyId = form.getFieldValue([
                "config",
                "foreignObjectPropertyId",
              ]);
              const propertyExists = currentPropertyId
                ? object.foreignKeys.find((fk) => fk.id === currentPropertyId)
                : undefined;

              if (currentPropertyId && propertyExists) {
                const availableLayouts = propertyExists.object.layouts || [];
                return (
                  <ChartOptionLine
                    items={[
                      {
                        content: (
                          <Form.Item
                            label="Layout"
                            required
                            name={[
                              "config",
                              "options",
                              "foreignObjectSelectedlayout",
                            ]}
                            style={{ marginBottom: 0 }}
                          >
                            <Select
                              placeholder={"Default layout"}
                              style={{ width: "100%" }}
                            >
                              {availableLayouts.map((l) => {
                                return (
                                  <Select.Option key={l.id} value={l.id}>
                                    {l.name ? l.name : "Untitled"}
                                  </Select.Option>
                                );
                              })}
                            </Select>
                          </Form.Item>
                        ),
                        flex: 1,
                      },
                    ]}
                  />
                );
              }
            }}
          </Form.Item>
          <ChartOptionLine
            style={{ marginTop: 12 }}
            items={[
              {
                content: "Limit",
                flex: 1,
              },
              {
                content: (
                  <Form.Item noStyle name={["config", "options", "limit"]}>
                    <InputNumber style={{ width: "100%" }} />
                  </Form.Item>
                ),
                flex: 1,
              },
            ]}
          />
        </Space>
      </ChartOptionCollapse>
    </>
  );
}

export default compose<Props, RepeatableWidgetEditorProps>(WithOrg)(
  RepeatableWidgetEditor
);
