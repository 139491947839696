import { Form, Input, Select, Space, Typography } from "antd";
import { useForm } from "antd/es/form/Form";
import * as React from "react";
import { PhoneData } from "./PhoneData";

const { Option } = Select;

type IPhoneInput = {
  dial_code: string;
  phone_number: string;
};

interface IPhoneInputProps {
  value?: IPhoneInput;
  onChange?: (value: IPhoneInput) => void;
}

const PhoneInput: React.FunctionComponent<IPhoneInputProps> = (props) => {
  const [form] = useForm<IPhoneInput>();
  let value: IPhoneInput = props.value ?? {
    dial_code: "+33",
    phone_number: null,
  };

  const prefixSelector = (
    <Form.Item noStyle name={"dial_code"}>
      <Select
        style={{ width: 110 }}
        optionLabelProp="label"
        popupMatchSelectWidth={false}
        showSearch={true}
        optionFilterProp="search"
      >
        {PhoneData.map((data, i) => (
          <Option
            key={i}
            label={
              <Space size={4}>
                {data.emoji} {data.dial_code}
              </Space>
            }
            search={`${data.name} ${data.dial_code}`}
            value={data.dial_code}
          >
            {data.emoji} {data.name}{" "}
            <Typography.Text type="secondary">{data.dial_code}</Typography.Text>
          </Option>
        ))}
      </Select>
    </Form.Item>
  );

  return (
    <Form
      form={form}
      initialValues={value}
      onValuesChange={(changedValues, values) => props.onChange?.(values)}
    >
      <Form.Item noStyle name={"number"}>
        <Input
          addonBefore={prefixSelector}
          style={{ width: "100%" }}
          value={value.phone_number}
        />
      </Form.Item>
    </Form>
  );
};

export default PhoneInput;
