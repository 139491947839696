import type { Remote } from "comlink";
import _ from "lodash";
import React, { useEffect, useLayoutEffect, useMemo, useState } from "react";
import type { Layout } from "react-grid-layout";
import { Responsive, WidthProvider } from "react-grid-layout";
import type { RouteComponentProps } from "react-router";
import { withRouter } from "react-router";
import useResizeObserver from "use-resize-observer";
import { compose } from "../../../../../components/compose/WlyCompose";
import type { IReport, ITile } from "../../../../../interfaces/reports";
import type { CubeJSPivotWorker } from "../../../../../worker/main.worker";
import type { ILagoonQuery } from "../../../../exploration/single/domain";
import type { InjectedOrgProps } from "../../../../orgs/WithOrg";
import WithOrg from "../../../../orgs/WithOrg";
import type { Store } from "../../chart/card/ChartCard";
import type { DataMapStore, FilterMapStore } from "../../domain";
import { generateGridBackground, windowWidth } from "../../grid/utils";
import DashboardGridItem from "./DashboardGridItem";
import { MIN_HEIGHT, MIN_WIDTH } from "./domain";

const ResponsiveGridLayout = WidthProvider(Responsive);

interface IDashboardGridProps {
  disableNavigationItems?: boolean;
  isEmbedded: boolean;
  addingNewText: boolean | ITile;
  editing: boolean;
  dataStore: DataMapStore;
  report: IReport;
  selected?: string;
  filterStore: FilterMapStore;
  onGridUpdate: (tiles: ITile[]) => void;
  reloadReport: (reportSlug: string, orgId) => Promise<IReport>;
  saving: (saving: boolean) => void;
  setAddingNewText: (c: boolean | ITile) => void;
  setDataStoreValue: (id: string, data: Partial<Store>) => void;
  getFilterStoreValues: (id: string) => string[];
  setReport: (report: IReport) => void;
  setSelected: (s: string | undefined) => void;
  setShouldUpdateQuery: (v: boolean) => void;
  scrollToBottom: () => void;
  onOpenConsole?: (c?: string) => void;
  disableDrills?: boolean;
  blinkingTileId?: string;
  renderOutsideViewport?: boolean;
  externalWorker: Remote<CubeJSPivotWorker>;
  onRefresh?: (tileId: string, overrideQuery?: ILagoonQuery) => void;
}

// available grid breakpoints and columns size
const breakpoints = {
  lg: {
    breakpoint: 768,
    cols: 24,
  },
  sm: {
    breakpoint: 0,
    cols: 1,
  },
};

// formatted grid breakpoints for the grid component
const gridBreakpoints = () => {
  return Object.keys(breakpoints).reduce((acc, k) => {
    return {
      ...acc,
      [k]: breakpoints[k].breakpoint,
    };
  }, {} as { [p: string]: number });
};

// formatted grid columns for the grid component
const gridCols = () => {
  return Object.keys(breakpoints).reduce((acc, k) => {
    return {
      ...acc,
      [k]: breakpoints[k].cols,
    };
  }, {} as { [p: string]: number });
};

type Props = IDashboardGridProps &
  RouteComponentProps<
    { tileSlug?: string; explorationSlug?: string },
    {},
    { scrollToBottom?: boolean }
  > &
  InjectedOrgProps;

type currentLayout = {
  breakpoint: string;
  cols: number;
};

type Size = {
  width: number;
  height: number;
};

type ObservedSize = {
  width: number | undefined;
  height: number | undefined;
};

type grid = {
  backgroundUrl: string;
  cols: number;
  margin: [number, number];
  padding: [number, number];
  width: number;
};

function DashboardGrid(props: Props) {
  const {
    disableNavigationItems,
    dataStore,
    editing,
    report,
    selected,
    onGridUpdate,
    reloadReport,
    saving,
    scrollToBottom,
    setAddingNewText,
    setDataStoreValue,
    setReport,
    onOpenConsole,
    isEmbedded,
    blinkingTileId,
    getFilterStoreValues,
    renderOutsideViewport,
    externalWorker,
    filterStore,
    onRefresh,
  } = props;

  const [size, setSize] = useState<Size>({
    width: 0,
    height: 0,
  });

  const setSizeSafe = (s: ObservedSize) =>
    setSize({ width: s.width || 0, height: s.height || 0 });
  const onResize = useMemo(() => _.debounce(setSizeSafe, 300), []);
  const { ref } = useResizeObserver({ onResize });

  useLayoutEffect(() => {
    window.dispatchEvent(new Event("resize"));
  }, [size.width]);

  const [currentGrid, setCurrentGrid] = useState<grid>({
    backgroundUrl: "",
    cols: 24,
    margin: [12, 12],
    padding: [0, 0],
    width: 1200,
  });

  const [currentLayout, setCurrentLayout] = useState<currentLayout>({
    breakpoint: "lg",
    cols: 24,
  });

  const [staticId, setStaticId] = useState<string>();

  const getGridBackgroundImage = (grid: grid): string => {
    const gridWidth = grid.width;
    const cellHeight = 31;
    const cols = grid.cols;
    const margin = {
      horizontalMargin: grid.margin[0],
      verticalMargin: grid.margin[1] + 1,
    };
    const cellWidth = (gridWidth - (cols - 1) * margin.horizontalMargin) / cols;

    const backgroundUrl = generateGridBackground({
      cellSize: {
        width: cellWidth,
        height: cellHeight,
      },
      margin: [margin.horizontalMargin, margin.verticalMargin],
      cols: cols,
      gridWidth: gridWidth,
    });
    return backgroundUrl;
  };

  const onWidthChange = (
    containerWidth: number,
    margin: [number, number],
    cols: number,
    containerPadding: [number, number]
  ) => {
    setCurrentGrid({
      ...currentGrid,
      cols: cols,
      width: containerWidth,
      margin: margin,
      padding: containerPadding,
    });
  };

  const onBreakpointChange = (newBreakpoint: string, newCols: number) => {
    setCurrentLayout({
      ...currentLayout,
      cols: newCols,
      breakpoint: newBreakpoint,
    });
  };

  const onLayoutChange = (layout: ReactGridLayout.Layout[], allLayouts) => {
    const { report } = props;
    const rebuiltLayoutElement = report.tiles.map((ti) => {
      const foundLayoutItem = allLayouts.lg.find((b) => b.i === ti.id);
      if (foundLayoutItem) {
        return {
          ...ti,
          height: foundLayoutItem.h,
          width: foundLayoutItem.w,
          left: foundLayoutItem.x,
          top: foundLayoutItem.y,
        };
      }
      return ti;
    });
    onGridUpdate(rebuiltLayoutElement);
  };

  const computeCurrentBreakpoint = (
    width: number
  ): currentLayout | undefined => {
    const key = Object.keys(breakpoints).find(
      (k) => breakpoints[k].breakpoint <= width
    );
    if (!key) return undefined;
    return {
      breakpoint: key,
      cols: breakpoints[key].cols,
    };
  };

  const isStatic = !editing;
  const layout = {
    lg: [
      ...report.tiles.map((c) => {
        return {
          i: c.id,
          x: c.left,
          y: c.top,
          w: c.width,
          h: c.height,
          static: isStatic,
          minH: c.type === "chart" ? MIN_HEIGHT : 1,
          minW: c.type === "chart" ? MIN_WIDTH : 1,
          isDraggable: staticId !== c.id && editing,
          isResizable: editing,
        } as Layout;
      }),
    ],
  };

  const currentWindowWidth = windowWidth();

  const style: React.CSSProperties = editing
    ? {
        backgroundImage: currentGrid.backgroundUrl,
        backgroundOrigin: "padding-box",
      }
    : {};

  // we update the layout if the window dimension changes
  useEffect(() => {
    const newLayout = computeCurrentBreakpoint(currentWindowWidth);
    if (newLayout && !_.isEqual(newLayout, currentLayout)) {
      setCurrentLayout(newLayout);
    }
  }, [currentWindowWidth, currentLayout]);

  useEffect(() => {
    const backgroundUrl = getGridBackgroundImage(currentGrid);
    if (currentGrid.backgroundUrl !== backgroundUrl) {
      setCurrentGrid({
        ...currentGrid,
        backgroundUrl: backgroundUrl,
      });
    }
  }, [currentGrid]);

  const breakspoints = gridBreakpoints();
  const cols = gridCols();

  const setStatic = (i, s) => {
    if (s) {
      setStaticId(i);
    } else {
      setStaticId(undefined);
    }
  };
  return (
    <div ref={ref}>
      <ResponsiveGridLayout
        className="layout"
        layouts={layout}
        breakpoints={breakspoints}
        cols={cols}
        rowHeight={32}
        containerPadding={[0, 0]}
        margin={[12, 12]}
        onLayoutChange={onLayoutChange}
        onBreakpointChange={onBreakpointChange}
        onWidthChange={onWidthChange}
        style={style}
        measureBeforeMount={true}
        useCSSTransforms={false}
      >
        {report.tiles.map((tile) => {
          const store = dataStore[tile.id];
          return (
            <div
              className={blinkingTileId === tile.id ? `blinking-tile` : ""}
              id={`tile-${tile.id}`}
              key={tile.id}
              style={{ zIndex: selected === tile.id ? 100 : 1 }}
            >
              <DashboardGridItem
                disableNavigationItems={disableNavigationItems}
                key={tile.id}
                tile={tile}
                report={report}
                editing={editing}
                store={store}
                selected={selected}
                reloadReport={reloadReport}
                scrollToBottom={scrollToBottom}
                saving={saving}
                setAddingNewText={setAddingNewText}
                setDataStoreValue={setDataStoreValue}
                setReport={setReport}
                isEmbedded={isEmbedded}
                onOpenConsole={onOpenConsole}
                getFilterStoreValues={getFilterStoreValues}
                disableDrills={props.disableDrills}
                setStatic={setStatic}
                renderOutsideViewport={renderOutsideViewport}
                externalWorker={externalWorker}
                filterStore={filterStore}
                onRefresh={onRefresh}
              />
            </div>
          );
        })}
      </ResponsiveGridLayout>
    </div>
  );
}

export default compose<Props, IDashboardGridProps>(
  withRouter,
  WithOrg
)(DashboardGrid);
