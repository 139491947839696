import { Col, Row, Tag } from "antd";
import Fuse from "fuse.js";
import * as React from "react";
import type { IDataset, ISource } from "../../interfaces/sources";
import SourceTableSelectionCard from "../explorations/SourceTableSelectionCard";
import Aligner from "../layout/aligner/Aligner";
import Feednack from "../layout/feedback/feedback";
import "./SourceTableSelectionList.scss";

interface ISourceTableSelectionListProps {
  sources: ISource[];
  models: IDataset[];
  search?: string;
  onSelect: (dataset: IDataset) => void;
}

export default class SourceTableSelectionList extends React.Component<ISourceTableSelectionListProps> {
  public render() {
    const { onSelect, sources, search, models } = this.props;
    const datasets = [
      ...sources.flatMap((s) => s.datasets.map((d) => ({ ...d, source: s }))),
      ...models.map((_) => ({
        ..._,
        isModel: true,
      })),
    ];

    const isSearching = search && search.length > 2;
    const options = {
      // isCaseSensitive: false,
      includeScore: true,
      shouldSort: true,
      includeMatches: true,
      findAllMatches: true,
      minMatchCharLength: 3,
      // location: 0,
      threshold: 0.1,
      distance: 5,
      // useExtendedSearch: false,
      ignoreLocation: true,
      // ignoreFieldNorm: false,
      keys: ["name", "warehouseFields.name"],
    };

    const fuse = new Fuse(datasets, options);
    const searchedResults = search ? fuse.search(search) : [];
    const tableFinal =
      isSearching && search ? searchedResults.map((f) => f.item) : datasets;

    const formatColumns = (i: number): React.ReactNode => {
      const matchedColumns = searchedResults[i].matches!.filter(
        (m) => m.key! === "warehouseFields.name"
      );
      const hasMore = matchedColumns.length > 3;
      return (
        <span>
          {matchedColumns
            .map((m) => <Tag key={m.value}>{m.value}</Tag>)
            .slice(0, 3)}
          {hasMore ? (
            <span>and {matchedColumns.length - 3} other ...</span>
          ) : (
            <span />
          )}
          `
        </span>
      );
    };

    return (
      <div className="source-table-list">
        <Row className="table-wrapper" gutter={[16, 16]}>
          {tableFinal.length > 0 ? (
            tableFinal.map((_, i) => {
              return (
                <Col span={isSearching ? 24 : 8} key={i}>
                  <SourceTableSelectionCard
                    name={_.name}
                    sourceName={_.isModel ? "Model" : _.source?.name}
                    primaryKey={_.primaryKey}
                    isModel={_.isModel}
                    isManagedByDbt={_.managedBy === "DBT_CLOUD"}
                    onClick={() => onSelect(_)}
                    logo={
                      _.isModel
                        ? undefined
                        : _.source?.sourceMeta?.publicInfo?.logo
                    }
                  >
                    {isSearching &&
                    searchedResults[i].matches!.find(
                      (m) => m.key! === "warehouseFields.name"
                    ) ? (
                      <div className="column-matches">
                        Available Columns: {formatColumns(i)}
                      </div>
                    ) : undefined}
                  </SourceTableSelectionCard>
                </Col>
              );
            })
          ) : (
            <Aligner>
              <Feednack>
                {isSearching ? (
                  <span>No Results</span>
                ) : (
                  <span>No available tables. Please connect a Source.</span>
                )}
              </Feednack>
            </Aligner>
          )}
        </Row>
      </div>
    );
  }
}
