import * as React from "react";

interface IClosableContentItemProps {
  selected?: boolean;
  onClick?: () => void;
  children?: React.ReactNode;
  style?: React.CSSProperties;
}

export function ClosableContentItem(
  props: React.PropsWithChildren<IClosableContentItemProps>
) {
  const { selected, onClick, style } = props;
  return (
    <div
      onClick={onClick}
      className={`closable-content-item ${selected ? "selected" : ""}`}
    >
      <div className="closable-content-item-inner" style={style}>
        {props.children}
      </div>
    </div>
  );
}
