import type {
  NodeModel,
  NodeModelListener,
} from "@projectstorm/react-diagrams";
import { DiagramModel } from "@projectstorm/react-diagrams";
import * as _ from "lodash";
import type {
  IDataset,
  IDatasetRelationship,
} from "../../../../../../../interfaces/sources";
import type {
  TableFromDatasetTableOperation,
  TableFromWarehouseTableOperation,
  Transformation,
} from "../../../../../../../interfaces/transformations";
import { getUserCreatedColumn } from "../../../../../../../services/BrizoService";
import type {
  BasicTableTabItem,
  CoreTableTabItem,
} from "../../../../../../spreadsheet/domain";
import {
  generateForeignKeys,
  generatePrimaryKey,
  generateRelationships,
} from "../../../../domain";
import { GRID_SIZE } from "../domain";
import { DataNodeModel } from "../nodes/data/DataNodeModel";
import { OperationNodeModel } from "../nodes/operation/OperationNodeModel";
import type { SimplePortModel } from "../nodes/port/SimplePortModel";

interface NodeIndex {
  [key: string]: NodeIndexItem;
}

export interface NodeIndexItem {
  children: string[];
  node: NodeModel;
  step: Transformation;
  outPort?: SimplePortModel;
  primaryInPort?: SimplePortModel;
  secondaryInPort?: SimplePortModel;
}

interface BaseEventListenerPayload {
  type:
    | "POSITION_UPDATED"
    | "DELETE"
    | "SELECTION"
    | "REMOVE_SELECTION"
    | "CREATE_MODEL";
}

interface PositionEventListenerPayload extends BaseEventListenerPayload {
  type: "POSITION_UPDATED";
  x: number;
  y: number;
}

interface RemoveEventListenerPayload extends BaseEventListenerPayload {
  type: "DELETE";
}

interface SelectionEventListenerPayload extends BaseEventListenerPayload {
  type: "SELECTION";
}

interface DeSelectionEventListenerPayload extends BaseEventListenerPayload {
  type: "REMOVE_SELECTION";
}

interface CreateModelEventListenerPayload extends BaseEventListenerPayload {
  type: "CREATE_MODEL";
}

type EventListenerPayload =
  | RemoveEventListenerPayload
  | PositionEventListenerPayload
  | SelectionEventListenerPayload
  | DeSelectionEventListenerPayload
  | CreateModelEventListenerPayload;

export type EventListener = (
  operationVar: string,
  event: EventListenerPayload
) => void;

export class CanvasNodeGenerator {
  protected transformations: Transformation[];
  protected nodeIndex: NodeIndex;
  public parentKeys: string[];
  protected datasets: IDataset[];
  protected eventListener: EventListener | undefined;
  public outputVar: string | undefined;
  public canEdit: boolean;

  constructor(
    transformations: Transformation[],
    datasets: IDataset[],
    canEdit: boolean,
    eventListener?: EventListener,
    outputVar?: string,
    selectedStepVar?: string
  ) {
    this.eventListener = eventListener;
    this.transformations = transformations;
    this.datasets = datasets;
    this.outputVar = outputVar;
    this.canEdit = canEdit;
    // first we compute an index for all nodes and assign a random position
    this.nodeIndex = this.generateNodeIndex(selectedStepVar);
    // second we get all heads (there can be multiple as not every node might be linked to the main tree)
    this.parentKeys = this.getParentKeys();
    // third we recompute the position of all nodes
    this.computePosition();
  }

  private computePosition = () => {
    const SPACE_BETWEEN = 1;
    // we start from 0 and go in negatives
    // we need to offset the whole drawing to positive values
    let topOffet = 0;
    let leftOffset = 0;

    let top = 0;
    this.parentKeys.forEach((pk, i) => {
      let left = 0;
      top = i > 0 ? top - ((SPACE_BETWEEN * 4) / 3) * GRID_SIZE : top;
      const assignPosition = (k: string, left: number) => {
        const current = this.nodeIndex[k];
        if (current) {
          left = left - Math.round(current.node.width / GRID_SIZE) * GRID_SIZE;
          leftOffset = Math.min(left, leftOffset);
          current.node.setPosition(left, top);
          if (current.children.length) {
            current.children
              .filter((c) => !!c)
              .forEach((c, j) => {
                if (j > 0) {
                  top =
                    top -
                    (SPACE_BETWEEN / 3) * GRID_SIZE -
                    Math.round(current.node.height / GRID_SIZE) * GRID_SIZE;
                  topOffet = Math.min(top, topOffet);
                }
                if (j === 0) {
                  left = left - SPACE_BETWEEN * GRID_SIZE; // leave space to  2 time the grid size
                  leftOffset = Math.min(left, leftOffset);
                }

                assignPosition(c, left);
              });
          }
        }
      };
      assignPosition(pk, left);
    });

    Object.keys(this.nodeIndex).forEach((k) => {
      const x = this.nodeIndex[k].node.getX() - leftOffset;
      const y = this.nodeIndex[k].node.getY() - topOffet;
      this.nodeIndex[k].node.setPosition(x, y);
    });
  };

  private generateNodeIndex = (selectedStepVar: string): NodeIndex => {
    return this.transformations.reduce((acc, tansformation) => {
      return {
        ...acc,
        [tansformation.var]: {
          children: this.extractChildren(tansformation),
          step: tansformation,
          ...this.generateNode(
            tansformation,
            tansformation.var === selectedStepVar
          ),
        },
      };
    }, {} as NodeIndex);
  };

  private extractChildren = (currentStep: Transformation): string[] => {
    if (
      currentStep.operation.type === "SQL.Database" ||
      currentStep.operation.type === "Table.FromWarehouseTable" ||
      currentStep.operation.type === "Table.FromWhalyReport" ||
      currentStep.operation.type === "Table.FromWhalyTable" ||
      currentStep.operation.type === "Table.FromWhalyDataset" ||
      currentStep.operation.type === "Table.Schema" ||
      currentStep.operation.type === "Table.RowCount"
    ) {
      return [];
    }
    // has two previous transformation
    if (currentStep.operation.type === "WhalyExt.Table.AddLookupColumn") {
      return [
        currentStep.operation.args.table1,
        currentStep.operation.args.table2,
      ];
    } else if (
      currentStep.operation.type === "WhalyExt.Table.AddRollupColumn"
    ) {
      return [
        currentStep.operation.args.table1,
        currentStep.operation.args.table2,
      ];
    } else if (currentStep.operation.type === "Table.Join") {
      return [
        currentStep.operation.args.table1,
        currentStep.operation.args.table2,
      ];
    } else if (currentStep.operation.type === "Table.Combine") {
      return [
        currentStep.operation.args.table1,
        currentStep.operation.args.table2,
      ];
    }

    return [currentStep.operation.args.table];
  };

  private generateNode = (
    currentStep: Transformation,
    selected: boolean
  ): {
    node: NodeModel;
    outPort?: SimplePortModel;
    primaryInPort?: SimplePortModel;
    secondaryInPort?: SimplePortModel;
  } => {
    const op = currentStep.operation;

    const listeners: NodeModelListener = {
      positionChanged: (e) => {
        this.eventListener?.(currentStep.var, {
          type: "POSITION_UPDATED",
          x: e.entity.getX(),
          y: e.entity.getY(),
        });
      },
      entityRemoved: (e) => {
        this.eventListener?.(currentStep.var, {
          type: "DELETE",
        });
      },
      selectionChanged: (e) => {
        if (e.isSelected) {
          this.eventListener?.(currentStep.var, {
            type: "SELECTION",
          });
        } else {
          this.eventListener?.(currentStep.var, {
            type: "REMOVE_SELECTION",
          });
        }
      },
    };

    const getX = () => {
      return 0;
    };

    const getY = () => {
      return 0;
    };

    switch (op.type) {
      case "Table.FromWarehouseTable":
        const dataWarehouseNode = new DataNodeModel();
        dataWarehouseNode.registerListener(listeners);
        dataWarehouseNode.bindTransformation(currentStep);
        dataWarehouseNode.bindCanvasNodeGenerator(this);
        dataWarehouseNode.setPosition(getX(), getY());
        if (selected) {
          dataWarehouseNode.setSelected(true);
        }
        const foundWarehouseDataset = this.datasets.find((d) => {
          if (
            d.warehouseDatabaseId === op.args.databaseName &&
            d.warehouseSchemaId === op.args.schemaName &&
            d.warehouseTableId === op.args.tableName
          ) {
            return true;
          }
          return false;
        });
        if (foundWarehouseDataset) {
          dataWarehouseNode.bindDataset(foundWarehouseDataset);
        }
        const dataWarehouseOutPort = dataWarehouseNode.getOutPort();
        return {
          node: dataWarehouseNode,
          outPort: dataWarehouseOutPort,
        };
      case "Table.FromWhalyDataset":
        const datasetNode = new DataNodeModel();
        datasetNode.registerListener(listeners);
        datasetNode.bindTransformation(currentStep);
        datasetNode.bindCanvasNodeGenerator(this);
        datasetNode.setPosition(getX(), getY());
        if (selected) {
          datasetNode.setSelected(true);
        }
        const foundDataset = this.datasets.find((d) => {
          if (d.id === op.args.datasetId) {
            return true;
          }
          return false;
        });
        if (foundDataset) {
          datasetNode.bindDataset(foundDataset);
        }
        const datasetOutPort = datasetNode.getOutPort();
        return {
          node: datasetNode,
          outPort: datasetOutPort,
        };
      case "Table.AddColumn":
      case "Table.RemoveColumns":
      case "Table.RenameColumns":
      case "Table.SelectColumns":
      case "Table.SelectRows":
      case "Table.Ref":
      case "Table.Group":
        const simpleOperationNode = new OperationNodeModel();
        simpleOperationNode.registerListener(listeners);
        simpleOperationNode.bindCanvasNodeGenerator(this);
        simpleOperationNode.setPosition(getX(), getY());
        if (selected) {
          simpleOperationNode.setSelected(true);
        }
        simpleOperationNode.bindTransformation(currentStep);

        const simpleOperationOutPort = simpleOperationNode.getOutPort();
        const simpleOperationInPort = simpleOperationNode.getPrimaryInPort();
        return {
          node: simpleOperationNode,
          outPort: simpleOperationOutPort,
          primaryInPort: simpleOperationInPort,
        };
      case "Table.Combine":
      case "WhalyExt.Table.AddLookupColumn":
      case "WhalyExt.Table.AddRollupColumn":
        const complexOperationNode = new OperationNodeModel(true);
        complexOperationNode.registerListener(listeners);
        complexOperationNode.setPosition(getX(), getY());
        complexOperationNode.bindCanvasNodeGenerator(this);
        if (selected) {
          complexOperationNode.setSelected(true);
        }
        complexOperationNode.bindTransformation(currentStep);

        const complexOperationOutPort = complexOperationNode.getOutPort();
        const complexOperationInPort = complexOperationNode.getPrimaryInPort();
        const complexOperationSecondaryInPort =
          complexOperationNode.getSecondaryInPort();
        return {
          node: complexOperationNode,
          outPort: complexOperationOutPort,
          primaryInPort: complexOperationInPort,
          secondaryInPort: complexOperationSecondaryInPort,
        };
      default:
        throw new Error(
          `Operation ${currentStep.operation.type}, no implemented`
        );
    }
  };

  private getParentKeys = () => {
    const allKeys = this.transformations.map((t) => t.var);
    const allChildrenKeys = _.uniq(
      Object.keys(this.nodeIndex).flatMap((k) => {
        return this.nodeIndex[k].children;
      })
    );
    const allParentKeys = allKeys.filter((k) => !allChildrenKeys.includes(k));
    return allParentKeys;
  };

  public getKeysToRemove = (nodeKey: string) => {
    let keysToRemove = [nodeKey];
    const current = this.nodeIndex[nodeKey];

    const visitChild = (key: string) => {
      const node = this.nodeIndex[key];
      keysToRemove.push(key);
      if (node) {
        node.children.forEach((childKey) => {
          visitChild(childKey);
        });
      }
    };

    // if node is a junction to several node we keep the main line
    // and remove the secondary line
    // if (current && current.children.length > 1) {
    //   const child = this.nodeIndex[current.children[1]];
    //   if (child && child.children.length === 0) {
    //     visitChild(current.children[1]);
    //   }
    // }

    // if node is a data node we remove the whole line
    // if the node is on a secondary line then we stop
    // when encountering the primary line
    if (current && current.children.length === 0) {
      const visitParent = (key: string) => {
        const curr = this.nodeIndex[key];
        if (curr) {
          keysToRemove.push(key);
          const parentKey = this.getParentKey(key);
          const parent = this.nodeIndex[parentKey];
          // we visit the parent only if on the main line
          if (parentKey && parent.children[0] === key) {
            visitParent(parentKey);
          }
          // else {
          //   // we only remove the next node
          //   keysToRemove.push(parentKey)
          // }

          // we visit the child only if we are on tge main line
          if (
            parent &&
            parent.children.length > 1 &&
            parent.children[0] === key
          ) {
            visitChild(parent.children[1]);
          }
        }
      };

      visitParent(nodeKey);
    }

    return keysToRemove;
  };

  public getParentKey = (key: string) => {
    let parentKey: string | undefined;
    Object.keys(this.nodeIndex).forEach((k) => {
      if (this.nodeIndex[k].children.includes(key)) {
        parentKey = k;
      }
    });
    return parentKey;
  };

  public getQueryAtNode = (key: string): Transformation[] => {
    const transformations: Transformation[] = [];
    const visitChild = (k: string) => {
      const current = this.nodeIndex[k];
      if (current) {
        transformations.push(current.step);
        if (current.children.length > 0) {
          current.children.forEach((c) => visitChild(c));
        }
      }
    };
    visitChild(key);
    const newTransformations = [...transformations];
    newTransformations.reverse();
    return newTransformations;
  };

  private findDataAttributes = (key: string): CoreTableTabItem => {
    const current = this.nodeIndex[key];
    if (!current) {
      throw new Error("Unknown step");
    }
    const query = this.getQueryAtNode(key);

    const visitChild = (
      k: string
    ): {
      foreignKeys: string[];
      primaryKey: string[];
      relationships: IDatasetRelationship[];
      datasetId: string;
    } => {
      const current = this.nodeIndex[k];
      if (
        current &&
        current.step.operation.type !== "Table.Join" &&
        current.step.operation.type !== "Table.Combine"
      ) {
        if (current.children && current.children.length) {
          return visitChild(current.children[0]);
        } else if (current.step.operation.type === "Table.FromWarehouseTable") {
          const dataset = this.datasets.find((d) => {
            if (
              d.warehouseDatabaseId ===
                (current.step.operation as TableFromWarehouseTableOperation)
                  .args.databaseName &&
              d.warehouseSchemaId ===
                (current.step.operation as TableFromWarehouseTableOperation)
                  .args.schemaName &&
              d.warehouseTableId ===
                (current.step.operation as TableFromWarehouseTableOperation)
                  .args.tableName
            ) {
              return true;
            }
            return false;
          });
          if (dataset) {
            return {
              foreignKeys: generateForeignKeys(dataset),
              relationships: generateRelationships(dataset, this.datasets),
              primaryKey: generatePrimaryKey(dataset),
              datasetId: dataset.id,
            };
          }
        } else if (current.step.operation.type === "Table.FromWhalyDataset") {
          const dataset = this.datasets.find(
            (d) =>
              d.id ===
              (current.step.operation as TableFromDatasetTableOperation).args
                .datasetId
          );
          if (dataset) {
            return {
              foreignKeys: generateForeignKeys(dataset),
              relationships: generateRelationships(dataset, this.datasets),
              primaryKey: generatePrimaryKey(dataset),
              datasetId: dataset.id,
            };
          }
        }
      }
    };
    const data = visitChild(key);

    return {
      foreignKeys: data && data.foreignKeys ? data.foreignKeys : [],
      primaryKey: data && data.primaryKey ? data.primaryKey : [],
      relationships: data && data.relationships ? data.relationships : [],
      userDefinedColumns: getUserCreatedColumn(query),
      datasetId: data && data.datasetId ? data.datasetId : undefined,
      runResults: [],
    };
  };

  public getCurrentTabItem = (key: string): BasicTableTabItem => {
    const current = this.nodeIndex[key];
    if (!current) {
      throw new Error("Unknown step");
    }

    const query = this.getQueryAtNode(key);
    const coreInfo = this.findDataAttributes(key);
    return {
      key: key,
      query: query,
      ...coreInfo,
    };
  };

  hasWarnings = (key: string) => {
    const current = this.nodeIndex[key];
    if (current) {
      switch (current.step.operation.type) {
        case "Table.AddColumn":
          if (
            current.step.operation.args.columnGenerator.length === 0 ||
            current.step.operation.args.newColumnName.length === 0
          ) {
            return true;
          }
          return false;
        case "Table.Combine":
          if (
            !current.step.operation.args.table1 ||
            !current.step.operation.args.table2 ||
            current.step.operation.args.columns.length === 0
          ) {
            return true;
          }
          return false;
        case "Table.Group":
          if (current.step.operation.args.keys.length === 0) {
            return true;
          }
          return false;
        case "Table.FromWhalyDataset":
        case "Table.FromWarehouseTable":
        case "Table.RemoveColumns":
        case "Table.SelectRows":
          return false;
        case "WhalyExt.Table.AddLookupColumn":
        case "WhalyExt.Table.AddRollupColumn":
          if (
            !current.step.operation.args.key1 ||
            !current.step.operation.args.key2 ||
            !current.step.operation.args.table1 ||
            !current.step.operation.args.table2 ||
            !current.step.operation.args.aggregationType ||
            !current.step.operation.args.newColumnName ||
            !this.nodeIndex[current.step.operation.args.table2] ||
            !this.nodeIndex[current.step.operation.args.table1]
          ) {
            return true;
          }
          return false;
        default:
          return false;
      }
    }
    return false;
  };

  hasChildInWarning = (key: string) => {
    let hasChildWarning: boolean = false;
    const visitChild = (childkey: string, initial?: boolean) => {
      const hasWarning = this.hasWarnings(childkey);
      const node = this.nodeIndex[childkey];
      if (hasWarning && !initial) {
        hasChildWarning = true;
        return;
      } else if (node && node.children && node.children.length > 0) {
        return node.children.map((c) => {
          visitChild(c);
        });
      } else {
        return;
      }
    };
    visitChild(key, true);
    return hasChildWarning;
  };

  getNodeIndex = (key: string) => {
    return this.nodeIndex[key];
  };

  public buildModel = (): DiagramModel => {
    const model = new DiagramModel();
    model.setGridSize(GRID_SIZE);
    const modelItem: any = [];

    const generateModelStep = (ni: NodeIndexItem) => {
      modelItem.push(ni.node);
      if (!ni.children.length) {
        return;
      }
      // if the node has children, we create the link and add the link to the modelItem as well
      // if child index is 0 then it means its the primary connection
      ni.children.forEach((c, i) => {
        const outPort = this.nodeIndex[c]
          ? this.nodeIndex[c].outPort!
          : undefined;
        const inPort = i === 0 ? ni.primaryInPort! : ni.secondaryInPort!;
        if (inPort && outPort) {
          const link = inPort.link(outPort);
          modelItem.push(link);
        }
        if (this.nodeIndex[c]) {
          return generateModelStep(this.nodeIndex[c]);
        }
      });
    };

    this.parentKeys.forEach((k) => {
      generateModelStep(this.nodeIndex[k]);
    });

    model.addAll(...modelItem);
    model.setLocked(true);
    return model;
  };
}
