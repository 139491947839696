import * as React from "react";
import "./Aligner.scss";

interface IAlignerProps {
  className?: string;
  style?: React.CSSProperties;
  children?: React.ReactNode;
}

export default class Aligner extends React.Component<IAlignerProps> {
  public render() {
    const { className, style } = this.props;
    return (
      <div
        style={style}
        className={className ? `${className} aligner` : "aligner"}
      >
        {this.props.children}
      </div>
    );
  }
}
