import { EditOutlined } from "@ant-design/icons";
import type { PopoverProps } from "antd";
import { Button, Form, Input, Space, Switch } from "antd";
import type { FormInstance } from "antd/lib";
import { CalculatedMetricFormulaEditor } from "../../../../../../../../components/formula/calculated-metric/CalculatedMetricFormulaEditor";
import type { AvailableDimension } from "../../../../../../../../components/measures/filter-item/FilterItem";
import { ColumnsAddition } from "../../../../../../../../components/widget-options/ColumnsAddition";
import type { IObject } from "../../../../../../../../interfaces/object";
import type { IOrg } from "../../../../../../../../interfaces/org";
import {
  LagoonCallOrigin,
  lagoonServiceLoad,
} from "../../../../../../../../services/LagoonService";
import { ChartOptionCollapse } from "../../../../../../../chart-options/components/ChartOptionCollapse";
import ChartOptionLine from "../../../../../../../chart-options/components/ChartOptionLine";
import { getObjectColumns } from "../../../../../object/domain";
import type { AvailableProperty } from "../../../../../object/viewer/domain";
import type { IRecord } from "../../../../domain";
import { WidgetVisibilityFilters } from "../../../common/visibility-filters/WidgetVisibilityFilters";
import type { IWidget } from "../../../domain";
import { buildQueryFromDimensionAndMetrics } from "../../related-lists/domain";
import type { IWidgetPropertiesConfig } from "../domain";

interface IWidgetPropertiesEditorProps {
  widget: IWidget;
  object: IObject;
  record: IRecord;
  conf: IWidgetPropertiesConfig;
  form: FormInstance;
  org: IOrg;
}

export function WidgetPropertiesEditor(props: IWidgetPropertiesEditorProps) {
  const { widget, object, record, conf, form, org } = props;

  const objectColumns = getObjectColumns(object).filter(
    (o) => !o.data.key.endsWith(".label")
  );

  return (
    <>
      <ChartOptionCollapse dark title="Properties Configuration">
        <Space size="middle" style={{ width: "100%" }} direction="vertical">
          <ChartOptionLine
            items={[
              {
                content: <div>Display Inline</div>,
                flex: 1,
              },
              {
                content: (
                  <Form.Item
                    noStyle
                    valuePropName="checked"
                    name={["config", "inline"]}
                  >
                    <Switch size="small" />
                  </Form.Item>
                ),
                flex: `0 auto`,
              },
            ]}
          />
          <ChartOptionLine
            items={[
              {
                content: (
                  <Form.Item noStyle name={["config", "columns"]}>
                    <ColumnsAddition availableColumns={objectColumns} />
                  </Form.Item>
                ),
                flex: 1,
              },
            ]}
          />
        </Space>
      </ChartOptionCollapse>
      <ChartOptionCollapse dark title="Properties settings">
        <Form.Item shouldUpdate noStyle>
          {() => {
            const columns = form.getFieldValue(["config", "columns"]);
            return (
              <Space direction="vertical" style={{ display: "flex" }}>
                {(columns || []).map((c) => {
                  const foundColumn = objectColumns.find(
                    (oc) => oc.data.key === c
                  );

                  const formPopover: PopoverProps = {
                    title: foundColumn ? foundColumn.data.label : c,
                    content: (
                      <Space
                        direction="vertical"
                        size={"middle"}
                        style={{ display: "flex" }}
                      >
                        <div>
                          <div style={{ paddingBottom: 4 }}>Label</div>
                          <Form.Item name={["config", "options", c, "label"]}>
                            <Input />
                          </Form.Item>
                        </div>
                        <div>
                          <div style={{ paddingBottom: 4 }}>Badge</div>
                          <Form.Item name={["config", "options", c, "badge"]}>
                            <CalculatedMetricFormulaEditor
                              columnsSuggestions={objectColumns.map((oc) => ({
                                key: `{{ ${oc.data.key.replace(".", "-")} }}`,
                                label: oc.data.label,
                              }))}
                            />
                          </Form.Item>
                        </div>
                        <div>
                          <div style={{ paddingBottom: 4 }}>
                            Display filters
                          </div>

                          <Form.Item
                            noStyle
                            name={["config", "options", c, "displayFilters"]}
                          >
                            <WidgetVisibilityFilters
                              autocomplete={async (dimension) => {
                                try {
                                  const dim = objectColumns.find(
                                    (ac) =>
                                      ac.type === "property" &&
                                      dimension === ac.data.key
                                  );

                                  if (!dim) {
                                    return [];
                                  }

                                  const dimKey = (dim.data as AvailableProperty)
                                    .sortAndFilterKey;
                                  if (!dimKey) {
                                    throw new Error("Dim not found");
                                  }

                                  const query =
                                    buildQueryFromDimensionAndMetrics(
                                      [dimKey],
                                      []
                                    );

                                  const data = await lagoonServiceLoad(
                                    org.id,
                                    query,
                                    "OBJECT",
                                    object.id,
                                    undefined,
                                    LagoonCallOrigin.WHALY_APP
                                  );

                                  const results = data.tablePivot();

                                  return results.map((r) =>
                                    r[dimKey].toString()
                                  );
                                } catch (err) {
                                  console.error(err);
                                  throw new Error(
                                    "Can't fetch recommendations"
                                  );
                                }
                              }}
                              availableDimensions={objectColumns.map<AvailableDimension>(
                                (ac) => {
                                  const prop = ac.data;
                                  const domain =
                                    ac.type === "property"
                                      ? ac.data.domain
                                      : "NUMERIC";
                                  return {
                                    key: prop.key,
                                    label: prop.label,
                                    description: prop.description,
                                    type: "standard",
                                    domain: domain,
                                  };
                                }
                              )}
                            />
                          </Form.Item>
                        </div>
                      </Space>
                    ),
                  };

                  return (
                    <ChartOptionLine
                      key={c}
                      items={[
                        {
                          content: (
                            <div>
                              {foundColumn ? foundColumn.data.label : c}
                            </div>
                          ),
                          flex: 1,
                          popover: formPopover,
                        },
                        {
                          content: (
                            <Button
                              size="small"
                              type="text"
                              shape="circle"
                              icon={<EditOutlined />}
                            />
                          ),
                          popover: formPopover,
                          flex: "0 auto",
                        },
                      ]}
                    />
                  );
                })}
              </Space>
            );
          }}
        </Form.Item>
      </ChartOptionCollapse>
    </>
  );
}
