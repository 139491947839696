import _ from "lodash";
import { useMemo, useState } from "react";
import useResizeObserver from "use-resize-observer";

type Size = {
  width: number;
  height: number;
};

export function useDebouncedResizeObserver() {
  const [size, setSize] = useState<Size>({
    width: 0,
    height: 0,
  });

  const setSizeSafe = (s: {
    width: number | undefined;
    height: number | undefined;
  }) => setSize({ width: s.width || 0, height: s.height || 0 });

  const onResize = useMemo(() => _.debounce(setSizeSafe, 100), []);

  const { ref } = useResizeObserver({ onResize });

  return { ref, size };
}
