import { CheckCircleFilled } from "@ant-design/icons";
import { Button, Col, Modal, Row } from "antd";
import cuid from "cuid";
import * as React from "react";
import Scratch from "../../../assets/exploration/scratch.svg";
import Template from "../../../assets/exploration/template.svg";
import { CatalogModal } from "../../../containers/catalog/CatalogModal";
import type { InjectedOrgProps } from "../../../containers/orgs/WithOrg";
import WithOrg from "../../../containers/orgs/WithOrg";
import InitialDatasetSelection from "../../../containers/spreadsheet/steps/InitialDatasetSelection";
import { TableType } from "../../../containers/workbench/workbench/exploration/domain";
import type { AsyncData } from "../../../helpers/typescriptHelpers";
import type { IDestination } from "../../../interfaces/destinations";
import { track } from "../../../services/AnalyticsService";
import type { IExplorationUpdatePayload } from "../../../services/ExplorationMutationService";
import { mutateExplorationFromCode } from "../../../services/ExplorationMutationService";
import GraphQLService from "../../../services/graphql/GraphQLService";
import { compose } from "../../compose/WlyCompose";
import Error from "../../error/Error";
import Loading from "../../layout/feedback/loading";
import "./ExplorationCreation.scss";

type IExplorationCreationProps = {
  visible: boolean;
  onCancel: () => any;
  onCreate: (explorationIds: string[]) => Promise<void>;
  currentWarehouse: IDestination;
};

type INewExplorationType = "TEMPLATE" | "SCRATCH";
type INewExplorationStep = "SELECT_TYPE" | "SELECT_TABLE" | "SELECT_TEMPLATE";

type Props = IExplorationCreationProps & InjectedOrgProps;

function ExplorationCreation(props: Props) {
  const {
    currentWarehouse,
    visible,
    onCancel,
    onCreate: onExplorationCreate,
  } = props;
  const [selected, setSelected] =
    React.useState<INewExplorationType>("SCRATCH");
  const [step, setStep] = React.useState<INewExplorationStep>(
    currentWarehouse?.isDataLoadingEnabled ? "SELECT_TYPE" : "SELECT_TABLE"
  );
  const [savedExploration, setSavedExploration] = React.useState<AsyncData<{}>>(
    { status: "initial" }
  );

  const onExit = () => {
    setStep(
      currentWarehouse?.isDataLoadingEnabled ? "SELECT_TYPE" : "SELECT_TABLE"
    );
    setSelected("SCRATCH");
    return onCancel();
  };

  const onSelectType = () => {
    if (step !== "SELECT_TYPE") return false;
    if (selected === "TEMPLATE") {
      return setStep("SELECT_TEMPLATE");
    }
    if (selected === "SCRATCH") {
      return setStep("SELECT_TABLE");
    }
  };

  const onCreate = (payload: IExplorationUpdatePayload) => {
    return createExploration(payload);
  };

  const createExploration = async (payload: IExplorationUpdatePayload) => {
    const { org } = props;
    setSavedExploration({
      status: "loading",
    });
    try {
      const redirect = await mutateExplorationFromCode(
        org.id,
        currentWarehouse.id,
        payload,
        {
          resourceType: "table",
          resourceId: (payload.table as any).tempId,
        }
      );

      track("Exploration Created", {
        id: redirect.explorationId,
      });

      const exploration = await GraphQLService<{ Exploration: { id; slug } }>(
        `
      query getExploration($id: ID!) {
        Exploration(where: {id: $id}) {
          id
          slug
        }
      }
      `,
        {
          id: redirect.explorationId,
        }
      );

      await onExplorationCreate([exploration.Exploration.id]);
      setSavedExploration({
        status: "success",
        data: {},
      });
    } catch (err) {
      console.error(err);
      setSavedExploration({
        status: "error",
        error: err,
      });
    }
  };

  const renderStep2 = () => {
    if (savedExploration.status === "loading") {
      return <Loading />;
    }
    if (savedExploration.status === "error") {
      return <Error>{JSON.stringify(savedExploration.error)}</Error>;
    }

    return (
      <InitialDatasetSelection
        onSelect={(payload) => {
          const dataset = payload.dataset;
          const explorationCreationPayload: IExplorationUpdatePayload = {
            name: payload.explorationName,
            table: {
              tempId: cuid(),
              primaryKey: dataset.primaryKey,
              name: dataset.name,
              view: payload.view ? payload.view.id : dataset.views[0].id,
              tableType: payload.blendedDatasets
                ? TableType.BLENDED_WITH_DATASET
                : TableType.REGULAR,
              blendedDatasetIds: payload.blendedDatasets?.map((dat) => dat.id),
              metrics: payload.metrics?.map((metric) => {
                return {
                  tempId: cuid(),
                  expression: metric.expression,
                  columnName: metric.columnName,
                  description: metric.description,
                };
              }),
              dimensions: payload.dimensions?.map((dimension) => ({
                tempId: cuid(),
                type: dimension.type,
                columnName: dimension.columnName,
                description: dimension.description,
                columnDomain: dimension.columnDomain,
                semanticGroupIds: dimension.semanticGroupIds,
              })),
              drills: JSON.stringify({ type: "INHERIT" }),
              semanticGroups: [],
            },
          };
          onCreate(explorationCreationPayload);
        }}
      />
    );
  };

  if (step === "SELECT_TEMPLATE") {
    return (
      <CatalogModal
        type="template"
        onClose={onExit}
        open={visible}
        onSuccess={async (explorations) => {
          await onExplorationCreate(explorations.map((e) => e.id));
        }}
      />
    );
  }

  return (
    <Modal
      footer={null}
      closable={false}
      title={null}
      open={visible}
      centered={true}
      width={"50%"}
      className={`exploration-creation-modal whaly-modal-v2`}
      style={{ padding: 10 }}
      styles={{
        body: { padding: 0 },
      }}
      destroyOnClose={true}
      onCancel={onExit}
      maskClosable={true}
    >
      <div className="header">What do you want to explore?</div>
      <div className="content">
        <Row gutter={[16, 16]}>
          {step === "SELECT_TYPE" ? (
            <>
              <Col span={12}>
                <div
                  className={
                    selected === "SCRATCH"
                      ? "choice-selection selected"
                      : "choice-selection"
                  }
                >
                  <div
                    onClick={() => setSelected("SCRATCH")}
                    className="choice-selection-inner"
                  >
                    <div className="choice-selection-image">
                      <img
                        src={Scratch}
                        alt={"From scratch"}
                        style={{ width: "100%" }}
                      />
                    </div>
                    <div className="choice-selection-title">
                      Start from scratch
                    </div>
                    <div className="choice-selection-description">
                      Start from a blank canvas to create your own custom
                      exploration
                    </div>
                    <div className="choice-selection-icon">
                      <CheckCircleFilled />
                    </div>
                  </div>
                </div>
              </Col>
              <Col span={12}>
                <div
                  className={
                    selected === "TEMPLATE"
                      ? "choice-selection selected"
                      : "choice-selection"
                  }
                >
                  <div
                    onClick={() => setSelected("TEMPLATE")}
                    className="choice-selection-inner"
                  >
                    <div className="choice-selection-image">
                      <img
                        src={Template}
                        alt={"From template"}
                        style={{ width: "100%" }}
                      />
                    </div>
                    <div className="choice-selection-title">
                      Start from a template
                    </div>
                    <div className="choice-selection-description">
                      Leverage our templates collection to kickstart your
                      exploration. You can always modify it later.
                    </div>
                    <div className="choice-selection-icon">
                      <CheckCircleFilled />
                    </div>
                  </div>
                </div>
              </Col>
            </>
          ) : undefined}
          {step === "SELECT_TABLE" ? renderStep2() : undefined}
        </Row>
      </div>
      {step === "SELECT_TYPE" ? (
        <div className="footer">
          <div className="left">
            <Button onClick={onExit} type="link">
              Cancel
            </Button>
          </div>
          <div className="right">
            <Button onClick={onSelectType} type="primary">
              Continue
            </Button>
          </div>
        </div>
      ) : undefined}
    </Modal>
  );
}

export default compose<Props, IExplorationCreationProps>(WithOrg)(
  ExplorationCreation
);
