import { Tree } from "antd";
import type { DataNode } from "antd/lib/tree";
import * as React from "react";
import { compose } from "../../../components/compose/WlyCompose";
import type {
  IExploration,
  IExplorationSection,
} from "../../../interfaces/explorations";
import type { InjectedOrgProps } from "../../orgs/WithOrg";
import WithOrg from "../../orgs/WithOrg";

import type { RouteComponentProps } from "react-router";
import { withRouter } from "react-router";
import { MenuTitle } from "../../../components/menu/MenuTitle";
import { IUserRoleType } from "../../../interfaces/user";
import { routeDescriptor } from "../../../routes/routes";
import { hasRoleAccessBoolean } from "../../user-settings/HasRoleAccess";
import "./ExplorationMenu.scss";
import ExplorationMenuItem from "./ExplorationMenuItem";
import { ExplorationSectionMenuItem } from "./ExplorationSectionMenuItem";

interface IExplorationMenuProps {
  explorations: IExploration[];
  onBack?: () => void;
  isDragging?: boolean;
  sections: IExplorationSection[];
  hideTitle?: boolean;
  updateExplorations?: (e: IExploration[]) => void;
  updateExplorationSections?: (s: IExplorationSection[]) => void;
  onClick?: (explorationSlug: string) => void;
}

type Props = IExplorationMenuProps &
  InjectedOrgProps &
  RouteComponentProps<{ organizationSlug: string; explorationSlug: string }>;

const generateSectionKey = (id: string) => {
  return `section-${id}`;
};

const getSortedSection = (s: IExplorationSection[]): IExplorationSection[] => {
  return [...s].sort((a, b) => {
    const f = a.order ? a.order : 0;
    const g = b.order ? b.order : 0;
    if (f === 0 && g === 0) {
      return 1;
    }
    return f - g;
  });
};

function ExplorationMenu(props: Props) {
  const {
    explorations,
    onBack,
    sections,
    updateExplorations,
    user,
    org,
    match: { params },
    hideTitle,
    onClick,
  } = props;

  const [openKeys, setOpenKeys] = React.useState<string[]>([
    ...sections.map((s) => generateSectionKey(s.id)),
    "section-wip",
  ]);

  const isBuilder = hasRoleAccessBoolean(IUserRoleType.BUILDER, user, org.id);

  const buildTree = (): DataNode[] => {
    const renderLine = (ex: IExplorationSection) => {
      return <ExplorationSectionMenuItem explorationSection={ex} />;
    };

    const renderExplorationTitle = (e: IExploration) => {
      return (
        <ExplorationMenuItem
          exploration={e}
          onClick={() =>
            onClick
              ? onClick(e.slug)
              : props.history.push(
                  routeDescriptor.explore.renderRoute({
                    ...props.match.params,
                    exploreSlug: e.slug,
                  })
                )
          }
          onDelete={
            updateExplorations
              ? () =>
                  updateExplorations(
                    explorations.filter(
                      (exploration) => exploration.id !== e.id
                    )
                  )
              : undefined
          }
          onEditDescription={
            updateExplorations
              ? (v) => {
                  updateExplorations(
                    explorations.map((exploration) => {
                      if (exploration.id === v.id)
                        return {
                          ...exploration,
                          description: v.description,
                        };
                      return exploration;
                    })
                  );
                }
              : undefined
          }
          onRename={
            updateExplorations
              ? (v) => {
                  updateExplorations(
                    explorations.map((exploration) => {
                      if (v.id !== exploration.id) return exploration;
                      return {
                        ...exploration,
                        name: v.name,
                      };
                    })
                  );
                }
              : undefined
          }
          showAccelerationStatus={false}
        />
      );
    };

    const buildExploration = (
      parentKey: string,
      ex: IExploration[]
    ): DataNode[] => {
      if (ex.length > 0) {
        return ex.map((e) => ({
          key: `exploration-${e.id}`,
          title: renderExplorationTitle(e),
          isLeaf: true,
          selectable: false,
        }));
      }
      return [
        {
          key: `no-data-${parentKey}`,
          title: "No explorations...",
          selectable: false,
          isLeaf: true,
          disabled: true,
        },
      ];
    };

    return [
      ...getSortedSection(sections).map((s) => {
        return {
          key: generateSectionKey(s.id),
          title: renderLine(s),
          selectable: false,
          children: buildExploration(
            s.id,
            explorations.filter((e) => e.section && e.section.id === s.id)
          ),
        };
      }),
      ...(isBuilder
        ? [
            {
              key: "section-wip",
              title: renderLine({
                name: "Work in progress",
                image: ":hammer_and_wrench:",
              } as IExplorationSection),
              selectable: false,
              children: buildExploration(
                "wip",
                explorations.filter((e) => !e.section)
              ),
            },
          ]
        : []),
    ];
  };

  return (
    <div className="exploration-menu">
      {!hideTitle ? (
        <MenuTitle onBackClick={onBack} title={"Explore"} />
      ) : undefined}
      <div className="exploration-menu-content">
        <Tree
          onExpand={(expandedKeys) => {
            setOpenKeys(expandedKeys.map((k) => k.toString()));
          }}
          expandedKeys={openKeys}
          defaultExpandAll={true}
          blockNode={true}
          selectable={true}
          draggable={false}
          className="exploration-item"
          treeData={buildTree()}
          selectedKeys={
            explorations.find((e) => e.slug === params.explorationSlug)
              ? [
                  `exploration-${
                    explorations.find((e) => e.slug === params.explorationSlug)
                      ?.id
                  }`,
                ]
              : undefined
          }
        />
      </div>
    </div>
  );
}

export default compose<Props, IExplorationMenuProps>(
  WithOrg,
  withRouter
)(ExplorationMenu);
