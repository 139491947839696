import { Form, Space } from "antd";
import type { VisualizationSpec } from "react-vega";
import { ChartOptionCollapse } from "../../../../../../../../../chart-options/components/ChartOptionCollapse";
import ChartOptionLine from "../../../../../../../../../chart-options/components/ChartOptionLine";
import { AggregateField } from "../common/AggregateField";
import { SchemaField } from "../common/SchemaField";
import type { AggrerateValue, IDiscoveryChartDefinition } from "../domain";

const defaultAggregation: AggrerateValue = "count";
const nonNumberAggregation: AggrerateValue[] = [
  "count",
  "distinct",
  "valid",
  "missing",
];

const pie: IDiscoveryChartDefinition<{
  value: string;
  groupBy: string;
  aggregate: string;
}> = {
  renderForm: (schema, form, onChange) => {
    return (
      <Form
        form={form}
        initialValues={{
          aggregate: "count",
        }}
        onValuesChange={(changed, values) => {
          if (changed?.value) {
            form.setFieldValue(["aggregate"], defaultAggregation);
          }
          onChange(form.getFieldsValue());
        }}
      >
        <ChartOptionCollapse title="Value">
          <Space style={{ width: "100%" }} direction="vertical">
            <ChartOptionLine
              items={[
                {
                  flex: 1,
                  content: (
                    <Form.Item noStyle name="value">
                      <SchemaField schema={schema} />
                    </Form.Item>
                  ),
                },
              ]}
            />
            <ChartOptionLine
              items={[
                {
                  flex: 1,
                  content: <div>Aggregate</div>,
                },
                {
                  flex: 0,
                  content: (
                    <Form.Item noStyle shouldUpdate>
                      {() => {
                        const value = form.getFieldValue("value");
                        return (
                          <Form.Item name="aggregate" noStyle>
                            <AggregateField
                              availableAggregates={
                                value && schema[value]?.domain === "NUMERIC"
                                  ? ["count", "sum", "mean", "min", "max"]
                                  : nonNumberAggregation
                              }
                            />
                          </Form.Item>
                        );
                      }}
                    </Form.Item>
                  ),
                },
              ]}
            />
          </Space>
        </ChartOptionCollapse>
        <ChartOptionCollapse title="Group by">
          <ChartOptionLine
            items={[
              {
                flex: 1,
                content: (
                  <Form.Item noStyle name="groupBy">
                    <SchemaField
                      schema={schema}
                      filter={(s) => s.domain === "STRING"}
                    />
                  </Form.Item>
                ),
              },
            ]}
          />
        </ChartOptionCollapse>
      </Form>
    );
  },
  renderSpec: (data, schema, def) => {
    if (!def || !def.groupBy || !def.value) {
      return {};
    }

    const spec: VisualizationSpec = {
      $schema: "https://vega.github.io/schema/vega-lite/v5.json",
      width: "container",
      height: "container",
      data: { values: data },
      mark: "arc",
      background: "transparent",
      padding: {
        left: 12,
        right: 12,
      },
      encoding: {
        theta: {
          field: def.value,
          aggregate: def.aggregate as any,
          type: "quantitative",
        },
        color: { field: def.groupBy, type: "nominal" },
      },
    };

    return spec;
  },
};

export default pie;
