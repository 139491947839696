import { Tag } from "antd";
import type { IPartnerPortal } from "../../../../../interfaces/org";

interface IPartnerPortalDetailGroupProps {
  portal: IPartnerPortal;
}

export function PartnerPortalDetailGroup(
  props: IPartnerPortalDetailGroupProps
) {
  return (
    <div>
      <div>
        All user from the portal will be added to the following groups:{" "}
        <Tag>All {props.portal.name} users</Tag>
      </div>
    </div>
  );
}
