import _ from "lodash";
import * as React from "react";
import Feednack from "../../../../../../../components/layout/feedback/feedback";
import type { AsyncData } from "../../../../../../../helpers/typescriptHelpers";
import type { IDestination } from "../../../../../../../interfaces/destinations";
import type { IDataset } from "../../../../../../../interfaces/sources";
import type {
  SchemaResult,
  Transformation,
} from "../../../../../../../interfaces/transformations";
import SpreadsheetFlow from "../../../../../../spreadsheet/SpreadsheetFlow";
import type { Store, TableData } from "../../../../domain";
import type {
  CanvasNodeGenerator,
  NodeIndexItem,
} from "../helpers/CanvasNodeGenerator";
import { HAS_FORM } from "../operations/OperationHandler";
import { OperationRenderer } from "../operations/OperationRenderer";

interface IOperationPanelProps {
  nodeIndex: NodeIndexItem;
  currentQuery: Transformation[];
  validateQuery: (q: Transformation[]) => Promise<void>;
  updateQuery?: (q: Transformation[], head: string) => void;
  datasets: IDataset[];
  prevSchema: AsyncData<SchemaResult>;
  height: number;
  completeQuery: Transformation[];
  canvasNodeGenerator: CanvasNodeGenerator;
  head: string;
  dataStore: Store;
  operationSchema: AsyncData<SchemaResult>;
  output: string;
  onAddTransformation?: (transformation: Transformation) => Promise<any>;
  setOutput?: (head: string) => void;
  onNodeHighlight?: (nodeId: string, hovering: boolean) => void;
  currentWarehouse: IDestination;
}

interface IState {
  hasChildInError: boolean;
}

export default class OperationPanel extends React.Component<
  IOperationPanelProps,
  IState
> {
  constructor(props: IOperationPanelProps) {
    super(props);
    this.state = {
      hasChildInError: false,
    };
  }

  componentDidMount() {
    this.setHasChildInError(this.props.head);
  }

  componentDidUpdate(prevProps: IOperationPanelProps) {
    if (prevProps.head !== this.props.head) {
      this.setHasChildInError(this.props.head);
    }
  }

  shouldComponentUpdate(
    nextProps: Readonly<IOperationPanelProps>,
    nextState: Readonly<IState>,
    nextContext: any
  ): boolean {
    const {
      datasets,
      prevSchema,
      height,
      completeQuery,
      canvasNodeGenerator,
      head,
      dataStore,
      operationSchema,
      output,
      nodeIndex,
      currentQuery,
    } = this.props;
    const {
      datasets: nextDatasets,
      prevSchema: nextPrevSchema,
      height: nextHeight,
      completeQuery: nextCompleteQuery,
      canvasNodeGenerator: nextCanvasNodeGenerator,
      head: nextHead,
      dataStore: nextDataStore,
      operationSchema: nextOperationSchema,
      output: nextOutput,
      nodeIndex: nextNodeIndex,
      currentQuery: nextCurrentQuery,
    } = nextProps;
    if (
      !_.isEqual(datasets, nextDatasets) ||
      !_.isEqual(prevSchema, nextPrevSchema) ||
      !_.isEqual(height, nextHeight) ||
      !_.isEqual(completeQuery, nextCompleteQuery) ||
      !_.isEqual(canvasNodeGenerator, nextCanvasNodeGenerator) ||
      !_.isEqual(head, nextHead) ||
      !_.isEqual(dataStore, nextDataStore) ||
      !_.isEqual(operationSchema, nextOperationSchema) ||
      !_.isEqual(output, nextOutput) ||
      !_.isEqual(nodeIndex, nextNodeIndex) ||
      !_.isEqual(currentQuery, nextCurrentQuery) ||
      !_.isEqual(this.state, nextState)
    ) {
      return true;
    }
    return false;
  }

  setHasChildInError = (head: string) => {
    const hasChildInError =
      this.props.canvasNodeGenerator.hasChildInWarning(head);
    this.setState({
      hasChildInError,
    });
  };

  public render() {
    const {
      updateQuery,
      validateQuery,
      output,
      dataStore,
      datasets,
      prevSchema,
      height,
      completeQuery,
      canvasNodeGenerator,
      head,
      operationSchema,
      onAddTransformation,
      setOutput,
      nodeIndex,
      currentQuery,
      onNodeHighlight,
      currentWarehouse,
    } = this.props;

    const { hasChildInError } = this.state;

    const hasWarning = canvasNodeGenerator.hasWarnings(head);

    if (hasChildInError) {
      return (
        <div style={{ height: "100%" }}>
          <Feednack>A child node is in error, please fix it...</Feednack>
        </div>
      );
    }

    const mashupData =
      head &&
      dataStore &&
      dataStore?.raw &&
      dataStore?.raw?.status === "success"
        ? (dataStore?.raw as any).data
        : ({
            rowCount: undefined,
            list: undefined,
            schema: undefined,
          } as TableData);

    const hasError = () => {
      if (operationSchema && operationSchema.status === "success") {
        if (hasWarning) {
          return "This step has warning please fix them";
        }
      }

      if (dataStore && dataStore.raw.status === "error") {
        return `Error while querying data: \n ${dataStore.raw.error.message}`;
      }

      if (operationSchema && operationSchema.status === "error") {
        return "Error while fetching schema";
      }
    };

    return (
      <div style={{ height: "100%", display: "flex" }}>
        {HAS_FORM.includes(nodeIndex.step.operation.type) && prevSchema && (
          <OperationRenderer
            transformations={currentQuery}
            currentWarehouse={currentWarehouse}
            operation={nodeIndex.step}
            onModifyOperation={
              updateQuery
                ? async (operations) => {
                    const newQuery = operations.reduce((acc, operation) => {
                      if (operation.type === "UPDATE") {
                        return acc.map((v) => {
                          if (v.var === operation.transformation.var) {
                            return operation.transformation;
                          }
                          return v;
                        });
                      } else if (operation.type === "CREATE") {
                        return acc.flatMap((v) => {
                          if (v.var === operation.parentKey) {
                            return [operation.transformation, v];
                          }
                          return [v];
                        });
                      }
                    }, completeQuery);
                    await validateQuery(newQuery);
                    updateQuery(newQuery, head);
                  }
                : undefined
            }
            datasets={datasets}
            schema={prevSchema}
            height={height}
            canvasNodeGenerator={canvasNodeGenerator}
            currentTabItem={canvasNodeGenerator.getCurrentTabItem(head)}
            onNodeHighlight={onNodeHighlight}
          />
        )}
        <div style={{ flex: 1, width: "calc(100% - 380px)" }}>
          <SpreadsheetFlow
            count={mashupData.rowCount}
            schema={mashupData.schema}
            records={mashupData.list}
            transformations={currentQuery}
            currentTabItem={canvasNodeGenerator.getCurrentTabItem(head)}
            onAddTransformation={onAddTransformation}
            setOutput={setOutput}
            output={output}
            head={head}
            error={hasError()}
            loading={{
              count: dataStore?.raw.status !== "success",
              records: dataStore?.raw.status !== "success",
              schema: dataStore?.raw.status !== "success",
              tables: dataStore?.raw.status !== "success",
            }}
          />
        </div>
      </div>
    );
  }
}
