import { FileTextOutlined } from "@ant-design/icons";
import { Button } from "antd";
import type { ColumnProps } from "antd/lib/table";
import moment from "moment";
import * as React from "react";
import Aligner from "../../../../components/layout/aligner/Aligner";
import Loading from "../../../../components/layout/feedback/loading";
import CardTable from "../../../../components/table/CardTable";
import type { IJobExecution } from "../../../../interfaces/jobExecutions";
import type { ISchedule } from "../../../../interfaces/schedule";
import GraphQLService from "../../../../services/graphql/GraphQLService";
import ExecutionLogsDrawer from "../../../job-execution/ExecutionLogsDrawer";
import { ExecutionStatusTag } from "../../../job-execution/status/ExecutionStatusTag";

interface IPushExecutionProps {
  schedule: ISchedule;
}

interface IPushExecutionsState {
  loading: boolean;
  executions: IJobExecution[];
  selectedJobExecution?: IJobExecution;
}

const GQL = `
query getScheduleExecutions($objectId: String) {
  allJobExecutions(where:{AND: [{objectId: $objectId}, {objectType: Schedule}]}, sortBy: [createdAt_DESC], first: 24) {
    id
    identifier
    objectType
    objectId
    status
    errorText
    errorDebugText
    errorType
    createdAt
    updatedAt
  }
}
`;

export default class PushExecutions extends React.Component<
  IPushExecutionProps,
  IPushExecutionsState
> {
  interval: NodeJS.Timeout;

  constructor(props: IPushExecutionProps) {
    super(props);
    this.state = {
      loading: true,
      executions: [],
    };
  }

  componentDidMount() {
    this.interval = setInterval(this.getJobExecutions, 2000);
  }

  componentWillUnmount() {
    window.clearTimeout(this.interval);
  }

  public generateColumns = (): ColumnProps<IJobExecution>[] => {
    return [
      {
        title: "Status",
        key: "status",
        render: (v, s) => {
          return (
            <div>
              <ExecutionStatusTag execution={s} />
            </div>
          );
        },
      },
      {
        title: "Start Date",
        key: "status",
        render: (v, s) => {
          return <div>{moment(s.createdAt).fromNow()}</div>;
        },
      },
      {
        title: "Duration",
        key: "duration",
        render: (v, s) => {
          return (
            <div>
              {s.status !== "RUNNING"
                ? moment
                    .duration(moment(s.updatedAt).diff(s.createdAt))
                    .humanize()
                : "---"}
            </div>
          );
        },
      },
      {
        title: "Logs",
        key: "logs",
        render: (v, s) => {
          return (
            <div>
              <Button
                shape="circle"
                icon={<FileTextOutlined />}
                onClick={() => {
                  this.setState({
                    ...this.state,
                    selectedJobExecution: s,
                  });
                }}
              />
            </div>
          );
        },
      },
    ];
  };

  getJobExecutions = async () => {
    const { schedule } = this.props;
    const jobExecutions = await GraphQLService<{
      allJobExecutions: IJobExecution[];
    }>(GQL, {
      objectId: schedule.id,
    });
    this.setState({
      loading: false,
      executions: jobExecutions.allJobExecutions,
    });
  };

  public render() {
    const { loading, executions } = this.state;
    if (loading === true) {
      return (
        <Aligner className="whly-content">
          <Loading />
        </Aligner>
      );
    } else {
      return (
        <>
          <CardTable<IJobExecution>
            cardTitle={<div>Executions</div>}
            actionButtons={<div />}
            rowKey="id"
            dataSource={executions}
            columns={this.generateColumns()}
            pagination={{
              size: "small",
              style: { display: "none" },
              defaultPageSize: 1000,
            }}
          />

          <ExecutionLogsDrawer
            onClose={() => this.setState({ selectedJobExecution: undefined })}
            selectedJobExecution={this.state.selectedJobExecution}
            visible={!!this.state.selectedJobExecution}
          />
        </>
      );
    }
  }
}
