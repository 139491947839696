import { ResizableDrawer } from "../../../../../components/resizable/Drawer";
import type { IExploration } from "../../../../../interfaces/explorations";
import type { IReport, ITile } from "../../../../../interfaces/reports";
import type { ILagoonQuery } from "../../../../exploration/single/domain";
import type { ActionType, FilterMapStore } from "../../domain";
import ChartCreateTileDrawer from "./actions/ChartCreateTileDrawer";
import ChartEditTileDrawer from "./actions/ChartEditTileDrawer";
import ChartExploreTileDrawer from "./actions/ChartExploreDrawer";

interface IChartEditionDrawerProps {
  visible: boolean;
  setVisible: (b: boolean) => void;
  initialData?: ChartEditionInitialData;
  embededWorkbench?: boolean;
  report: IReport;
}

interface BaseChartEditionInitialData {
  actionType: ActionType;
}

interface CreationChartEditionInitialData extends BaseChartEditionInitialData {
  actionType: "CREATE_TILE";
  explorationSlug: string;
  onSave: (query: ILagoonQuery, exploration: IExploration) => Promise<void>;
  onCancel?: (exploration: IExploration) => Promise<void>;
}

interface EditChartEditionInitialData extends BaseChartEditionInitialData {
  actionType: "EDIT_TILE";
  tile: ITile;
  onSave: (
    tile: ITile,
    query: ILagoonQuery,
    exploration: IExploration
  ) => Promise<void>;
  onCancel?: (exploration: IExploration) => Promise<void>;
}

interface ExploreChartEditionInitialData extends BaseChartEditionInitialData {
  actionType: "EXPLORE";
  tile: ITile;
  filters?: FilterMapStore;
  onAddToReport?: () => Promise<void>;
  onCancel?: (exploration: IExploration) => Promise<void>;
}

export type ChartEditionInitialData =
  | ExploreChartEditionInitialData
  | EditChartEditionInitialData
  | CreationChartEditionInitialData;

export function ChartEditionDrawer(props: IChartEditionDrawerProps) {
  const { visible, setVisible, initialData, embededWorkbench } = props;

  const renderInner = () => {
    if (!initialData) {
      return <div />;
    }

    switch (initialData.actionType) {
      case "CREATE_TILE":
        return (
          <ChartCreateTileDrawer
            setVisible={setVisible}
            explorationSlug={initialData.explorationSlug}
            visible={visible}
            onCancel={initialData.onCancel}
            onSave={initialData.onSave}
            embededWorkbench={embededWorkbench}
          />
        );
      case "EDIT_TILE":
        return (
          <ChartEditTileDrawer
            setVisible={setVisible}
            tile={initialData.tile}
            onCancel={initialData.onCancel}
            onSave={initialData.onSave}
            visible={visible}
          />
        );
      case "EXPLORE":
        // not used anymore in the v2 of the UI
        // instead we redirect to the explore page
        return (
          <ChartExploreTileDrawer
            setVisible={setVisible}
            tile={initialData.tile}
            visible={visible}
            embededWorkbench={embededWorkbench}
            onCancel={initialData.onCancel}
            onAddToReport={initialData.onAddToReport}
            currentReportId={props.report.id}
            filterInfo={{
              reportFilters: props.report.filters,
              filters: initialData.filters,
            }}
          />
        );
    }
  };

  return (
    <ResizableDrawer
      visible={visible}
      setVisible={setVisible}
      defaultHeight={document.body.clientHeight - 96}
    >
      {renderInner}
    </ResizableDrawer>
  );
}
