import { Form, Select as AntSelect } from "antd";
import type { FormItemProps } from "antd/lib/form/FormItem";
import type { FieldProps } from "formik";
import type { SelectProps } from "rc-select";
import * as React from "react";

interface ISelectProps extends FieldProps {
  inputProps: Omit<SelectProps, "mode" | "renderMode" | "suffixIcon">;
  formItemProps: FormItemProps;
}

export default class Select extends React.Component<ISelectProps> {
  public render() {
    const { inputProps, formItemProps, field, form } = this.props;

    const isSubmitting = form.isSubmitting;
    const touched = form.touched[field.name];
    const submitted = form.submitCount > 0;
    const hasError = form.errors[field.name];
    const submittedError = hasError && submitted;
    const touchedError = hasError && touched;
    const onInputChange = (e: string | string[]) =>
      form.setFieldValue(field.name, e);
    const onSelect = (value: string) => {
      const existingValue = form.values[field.name];
      if (existingValue && existingValue instanceof Array) {
        form.setFieldValue(field.name, existingValue.concat(value));
      } else {
        form.setFieldValue(field.name, value);
      }
    };
    const onBlur = () => form.setFieldTouched(field.name, true);

    return (
      <Form.Item
        label={formItemProps.label}
        hasFeedback={
          (formItemProps.hasFeedback && submitted) ||
          (formItemProps.hasFeedback && touched)
            ? true
            : undefined
        }
        help={submittedError || touchedError ? <>{hasError}</> : undefined}
        validateStatus={
          submittedError || touchedError
            ? "error"
            : isSubmitting
            ? "validating"
            : "success"
        }
        {...formItemProps}
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
      >
        <AntSelect
          {...field}
          size={"large"}
          {...inputProps}
          defaultValue={
            inputProps.options && inputProps.options.length
              ? inputProps.options[0].value
              : undefined
          }
          disabled={isSubmitting || inputProps.disabled}
          onBlur={onBlur}
          onChange={onInputChange}
          onSelect={onSelect}
        />
      </Form.Item>
    );
  }
}
