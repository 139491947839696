import { Tag } from "antd";
import type { ColumnProps } from "antd/lib/table";
import * as React from "react";
import { compose } from "../../components/compose/WlyCompose";
import CardTable from "../../components/table/CardTable";
import UserAvatar from "../../components/user/avatar/UserAvatar";
import type { IRealm, IUserRealm } from "../../interfaces/realm";
import { IUserRealmType } from "../../interfaces/realm";
import { authenticatedRequest } from "../../services/authService";
import GraphQLService from "../../services/graphql/GraphQLService";

interface IUserRealmTableProps {
  realm: IRealm;
}

type Props = IUserRealmTableProps;

interface State {
  isLoading: boolean;
  userRealms: IUserRealm[];
}

class UserRealmTable extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isLoading: true,
      userRealms: [],
    };

    this.fetchUserRealms();
  }

  fetchUserRealms = async (): Promise<void> => {
    const { realm } = this.props;

    await authenticatedRequest();
    const GQL = `
    query GetuserRealmsOfRealm($realmId: ID!) {
      allUserRealms(where: { isDeleted_not: true, realm: { id: $realmId } }) {
        id
        type
        user {
          id
          email
          firstName
          lastName
          avatarColor
        }
      }
    }
    `;
    const userRealmsRes = await GraphQLService(GQL, { realmId: realm.id });

    this.setState({
      isLoading: false,
      userRealms: userRealmsRes.allUserRealms,
    });
  };

  public generateColumns = (): ColumnProps<IUserRealm>[] => {
    return [
      {
        title: "User Email",
        key: "name",
        render: (value, record) => {
          return (
            <div>
              <div style={{ float: "left", display: "inline-clock" }}>
                <UserAvatar user={record.user} style={{ marginRight: 14 }} />
                <div style={{ display: "inline-block" }}>
                  {record.user.email}
                  {record.type === IUserRealmType.ADMIN ? (
                    <Tag style={{ marginLeft: 8 }} color="magenta">
                      Owner
                    </Tag>
                  ) : undefined}
                </div>
              </div>
            </div>
          );
        },
      },
    ];
  };

  public render() {
    const { isLoading, userRealms } = this.state;

    return (
      <CardTable<IUserRealm>
        loading={isLoading}
        cardTitle={<div>Team Members ({userRealms.length})</div>}
        actionButtons={<div />}
        rowKey={(s) => s.id}
        dataSource={userRealms}
        columns={this.generateColumns()}
        pagination={{
          size: "small",
          style: { display: "none" },
          defaultPageSize: 1000,
        }}
      />
    );
  }
}

export default compose<Props, IUserRealmTableProps>()(UserRealmTable);
