import type { IObject } from "../../../../../../interfaces/object";
import type { IOrg } from "../../../../../../interfaces/org";
import type { IUser } from "../../../../../../interfaces/user";
import type { IRecord } from "../../domain";
import type { IWidget } from "../domain";
import type { IEmailRendererConfig } from "./domain";
import { rendererConfig } from "./rendererConfig";

export function EmailWidgetMjmlRenderer(
  object: IObject,
  widget: IWidget,
  record: IRecord,
  org: IOrg,
  user: IUser,
  edit?: boolean
): Promise<string> {
  const renderer: IEmailRendererConfig<any> = rendererConfig[widget.type];

  const conf = renderer.parseConfig(widget, object);

  return renderer.renderMjml(widget, object, record, conf, org, user, edit);
}
