import type { Query } from "@cubejs-client/core";
import africa from "@highcharts/map-collection/custom/africa.geo.json";
import asia from "@highcharts/map-collection/custom/asia.geo.json";
import centralAmerica from "@highcharts/map-collection/custom/central-america.geo.json";
import europe from "@highcharts/map-collection/custom/europe.geo.json";
import europeanUnion from "@highcharts/map-collection/custom/european-union.geo.json";
import middleEast from "@highcharts/map-collection/custom/middle-east.geo.json";
import nordicCountries from "@highcharts/map-collection/custom/nordic-countries.geo.json";
import northAmericaWithoutCentral from "@highcharts/map-collection/custom/north-america-no-central.geo.json";
import northAmerica from "@highcharts/map-collection/custom/north-america.geo.json";
import oceania from "@highcharts/map-collection/custom/oceania.geo.json";
import scandinavia from "@highcharts/map-collection/custom/scandinavia.geo.json";
import southAmerica from "@highcharts/map-collection/custom/south-america.geo.json";
import world from "@highcharts/map-collection/custom/world.geo.json";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts/highmaps";
import map from "highcharts/modules/map";
import _ from "lodash";
import React from "react";
import type { ChartOption } from "../../../containers/chart-options/ChartOptions";
import type { UserLocale } from "../../../interfaces/user";
import type { Formatter } from "../domain";
import {
  customFormatter,
  optionsHelper,
  renderSerieTooltip,
} from "../utils/optionsHelper";

map(Highcharts);
window.Highcharts = Highcharts;

export type IMapType =
  | "africa"
  | "asia"
  | "centralAmerica"
  | "europe"
  | "europeanUnion"
  | "middleEast"
  | "northAmerica"
  | "northAmericaWithoutCentral"
  | "nordicCountries"
  | "oceania"
  | "scandinavia"
  | "southAmerica"
  | "world";

interface IMapChartProps {
  height: number;
  data: Array<{ [key: string]: string | number | boolean }>;
  config: MapChartConfig;
  tinyLegend?: boolean;
  scale?: "time" | "ordinal";
  width?: number;
  onDrill?: (q: Query) => void;
  chartOptions?: ChartOption;
  locale: UserLocale;
}

interface MapChartConfig {
  x1: {
    key: string;
    label?: string;
  };
  x2: {
    key: string;
    label?: string;
  };
  y: {
    key: string;
    label?: string;
    color?: string;
    canDrill: (xValue: string, yValue?: string) => Query | null;
    dashed?: boolean;
    formatter: Formatter;
  };
  showLabels?: boolean;
}

const getTopology = (topopology: IMapType) => {
  switch (topopology) {
    case "world":
      return world;
    case "asia":
      return asia;
    case "centralAmerica":
      return centralAmerica;
    case "africa":
      return africa;
    case "europe":
      return europe;
    case "europeanUnion":
      return europeanUnion;
    case "middleEast":
      return middleEast;
    case "northAmerica":
      return northAmerica;
    case "northAmericaWithoutCentral":
      return northAmericaWithoutCentral;
    case "nordicCountries":
      return nordicCountries;
    case "oceania":
      return oceania;
    case "scandinavia":
      return scandinavia;
    case "southAmerica":
      return southAmerica;
    default:
      return world;
  }
};

const generateMapOptions = (
  data,
  config,
  onDrill,
  chartOptions,
  optionHelpers,
  locale
) => {
  const formattedData = data.map((d) => [
    d[config.x1.key]?.toLocaleLowerCase(),
    +d[config.y.key],
  ]);
  const colors = chartOptions?.palette?.colors;
  const topopology = chartOptions?.map
    ? getTopology(chartOptions.map)
    : getTopology("world");
  const options: Highcharts.Options = {
    ...optionHelpers,
    chart: {
      ...optionHelpers.chart,
      map: topopology,
    },
    legend: {
      ...optionHelpers.legend,
    },
    xAxis: {},
    yAxis: {},
    colorAxis: {
      min: 0,
      minColor: colors && colors[1] ? colors[1] : "#E9E9E9",
      maxColor: colors && colors[0] ? colors[0] : "#69A2B0",
      labels: {
        formatter: function () {
          return customFormatter(this.value, locale, config.y.formatter);
        },
      },
    },
    mapNavigation: {
      enabled: false,
    },
    series: [
      {
        dataLabels: {
          enabled: config.showLabels,
          formatter: function () {
            if (this.point.value) {
              return this.point.name;
            }
          },
        },
        tooltip: renderSerieTooltip({
          data: data,
          locale: locale,
          seriesLength: 1,
          formatter: config.y.formatter,
          valueGetter: "this.value",
          hideSerieColor: true,
        }),
        data: formattedData,
        cursor: "pointer",
        events: {
          click: onDrill
            ? (e) => {
                const xValue = e.point.options["hc-key"].toString();
                const q = config.y.canDrill(
                  xValue.toString(),
                  e.point.value ? e.point.value.toString() : undefined
                );
                if (q) {
                  return onDrill(q);
                }
              }
            : undefined,
        },
        name: config.y.label,
      },
    ],
  };
  return options;
};

type IState = Highcharts.Options;

export default class MapChart extends React.Component<IMapChartProps, IState> {
  constructor(props: IMapChartProps) {
    super(props);

    const opts = optionsHelper(
      props.height,
      props.width,
      props.locale,
      props.scale,
      undefined,
      undefined,
      undefined,
      props.chartOptions
    );

    this.state = {
      ...generateMapOptions(
        props.data,
        props.config,
        props.onDrill,
        props.chartOptions,
        opts,
        props.locale
      ),
    };
  }

  componentDidUpdate(prevProps: IMapChartProps) {
    const {
      data,
      config,
      chartOptions,
      height,
      width,
      scale,
      onDrill,
      locale,
    } = this.props;
    const {
      data: prevData,
      config: prevConfig,
      chartOptions: prevChartOptions,
      height: prevHeight,
      width: prevWidth,
    } = prevProps;
    if (
      !_.isEqual(data, prevData) ||
      !_.isEqual(config, prevConfig) ||
      !_.isEqual(chartOptions, prevChartOptions) ||
      height !== prevHeight ||
      width !== prevWidth
    ) {
      this.setState(
        {
          ...this.state,
          series: [],
        },
        () => {
          const opts = optionsHelper(
            height,
            width,
            locale,
            scale,
            undefined,
            undefined,
            undefined,
            chartOptions
          );
          this.setState({
            ...generateMapOptions(
              data,
              config,
              onDrill,
              chartOptions,
              opts,
              locale
            ),
          });
        }
      );
    }
  }

  render() {
    const { height } = this.props;
    const options = this.state;

    return (
      <div style={{ height, width: "100%" }}>
        <HighchartsReact
          highcharts={Highcharts}
          constructorType={"mapChart"}
          options={options}
          updateArgs={[true, true, false]}
        />
      </div>
    );
  }
}
