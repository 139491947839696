import type React from "react";
import { createContext } from "react";
import type { AsyncData } from "../../../../../helpers/typescriptHelpers";

export type IRecordModalContext = AsyncData<{
  id: string;
  name?: string;
  image?: string;
  additionalButtons?: React.ReactNode[];
  recommendations?: React.ReactNode[];
}>;

export const RecordModalContext = createContext<{
  value: IRecordModalContext | undefined;
  setValue: ((value: IRecordModalContext) => void) | undefined;
}>({ value: undefined, setValue: undefined });
