import { CloseOutlined } from "@ant-design/icons";
import { Button, Drawer } from "antd";
import * as React from "react";
import * as Toolbar from "../../../spreadsheet/toolbar/Toolbar";
import type { DataMapStore } from "../domain";
import ChartPerformance from "./charts/ChartPerformance";
import { OverallPerformance } from "./overall/OverallPerformance";

import "./ReportConsole.scss";

interface IReportConsoleProps {
  visible: boolean;
  onClose: () => void;
  data: DataMapStore;
  initialSelectedItem?: string;
}

export function ReportConsole(props: IReportConsoleProps) {
  const { visible, onClose, data, initialSelectedItem } = props;
  const [selectedTab, setSelectedTab] = React.useState<"OVERALL" | "CHARTS">(
    initialSelectedItem ? "CHARTS" : "OVERALL"
  );

  React.useEffect(() => {
    if (initialSelectedItem) {
      setSelectedTab("CHARTS");
    }
  }, [initialSelectedItem]);

  const renderInner = () => {
    switch (selectedTab) {
      case "OVERALL":
        return <OverallPerformance data={data} />;
      case "CHARTS":
        return (
          <ChartPerformance
            data={data}
            initialSelectedItem={initialSelectedItem}
          />
        );
    }
  };

  return (
    <Drawer
      title="Console"
      placement="bottom"
      open={visible}
      onClose={onClose}
      height={540}
      destroyOnClose
      styles={{
        body: { padding: 0 },
        header: { display: "none" },
      }}
    >
      {visible && (
        <div className="report-console">
          <Toolbar.Toolbar>
            <Toolbar.ViewName>Console</Toolbar.ViewName>
            <Toolbar.Separator />
            <Toolbar.Item
              onClick={() => setSelectedTab("OVERALL")}
              color="green"
              active={selectedTab === "OVERALL"}
            >
              Performance
            </Toolbar.Item>
            <Toolbar.Item
              onClick={() => setSelectedTab("CHARTS")}
              color="pink"
              active={selectedTab === "CHARTS"}
            >
              Charts
            </Toolbar.Item>
            <div style={{ flex: 1 }} />
            <Button
              type="text"
              shape="circle"
              onClick={onClose}
              icon={<CloseOutlined />}
            />
          </Toolbar.Toolbar>
          <div className="report-console-content">{renderInner()}</div>
        </div>
      )}
    </Drawer>
  );
}
