import moment from "moment";
import { type IObjectPropertyActivitiesFormatterConfig } from "../../../../interfaces/object";

export const computeMin = (
  config: IObjectPropertyActivitiesFormatterConfig
) => {
  if (config?.config?.dateRange?.window === "lookback") {
    return moment()
      .subtract(
        config?.config?.dateRange?.number + 1,
        config?.config?.dateRange?.unit
      )
      .valueOf();
  }
  return moment().valueOf();
};

export const computeMax = (
  config: IObjectPropertyActivitiesFormatterConfig
) => {
  if (config?.config?.dateRange?.window === "lookforward") {
    return moment()
      .add(
        config?.config?.dateRange?.number + 1,
        config?.config?.dateRange?.unit
      )
      .valueOf();
  }
  return moment().valueOf();
};
