import { compose } from "../../../components/compose/WlyCompose";
import type { InjectedOrgProps } from "../../orgs/WithOrg";
import WithOrg from "../../orgs/WithOrg";
import SettingsWrapper from "../SettingsWrapper";
import UserAttributesTable from "./UserAttributesTable";

interface IUserAttributesPageProps {}

type Props = IUserAttributesPageProps & InjectedOrgProps;

function UserAttributesPage(props: Props) {
  const { org } = props;
  return (
    <SettingsWrapper>
      <UserAttributesTable userAttributeMetas={org.userAttributeMetas} />;
    </SettingsWrapper>
  );
}

export default compose<Props, IUserAttributesPageProps>(WithOrg)(
  UserAttributesPage
);
