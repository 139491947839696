import { Button, Col, Row, Tooltip } from "antd";
import type { ColumnProps } from "antd/lib/table";
import moment from "moment";
import * as React from "react";
import type { RouteComponentProps } from "react-router";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import type { InjectedAntUtilsProps } from "../../../components/ant-utils/withAntUtils";
import { withAntUtils } from "../../../components/ant-utils/withAntUtils";
import { compose } from "../../../components/compose/WlyCompose";
import { Emoji } from "../../../components/form/emoji-picker/Emoji";
import Aligner from "../../../components/layout/aligner/Aligner";
import { FromNow } from "../../../components/moment/FromNow";
import CardTable from "../../../components/table/CardTable";
import UserAvatar from "../../../components/user/avatar/UserAvatar";
import { handleGQLErrors } from "../../../helpers/gqlHelpers";
import type { IExploration } from "../../../interfaces/explorations";
import { IUserRoleType } from "../../../interfaces/user";
import { routeDescriptor } from "../../../routes/routes";
import GraphQLService from "../../../services/graphql/GraphQLService";
import type { InjectedOrgProps } from "../../orgs/WithOrg";
import WithOrg from "../../orgs/WithOrg";
import { hasRoleAccessBoolean } from "../../user-settings/HasRoleAccess";

interface IUnsedExplorationTableProps {
  explorations: IExploration[];
}

type Props = IUnsedExplorationTableProps &
  InjectedOrgProps &
  InjectedAntUtilsProps &
  RouteComponentProps<{}>;

function UnsedExplorationTable(props: Props) {
  const {
    antUtils,
    explorations,
    user,
    org,
    history,
    match: { params },
    location,
  } = props;

  const [keys, setKeys] = React.useState<string[]>([]);

  const trash = () => {
    antUtils.modal.confirm({
      title: "This operation cannot be undone",
      content: `You are about to delete ${keys.length} explorations. This operation cannot be undone. Do you want to proceed?`,
      okButtonProps: {
        danger: true,
      },
      okText: "Delete",
      onOk: () => {
        return GraphQLService(
          `
          mutation DeletedUnusedExplorations($data: [ExplorationsUpdateInput]!) {
            updateExplorations(data: $data) {
              id
            }
          }
          `,
          {
            data: keys.map((i) => ({
              id: i,
              data: {
                deleted: true,
              },
            })),
          }
        )
          .then(() => {
            history.push(routeDescriptor["redirect"].renderRoute(params), {
              to: location.pathname,
            });
          })
          .catch(handleGQLErrors);
      },
      onCancel: () => {
        return Promise.resolve();
      },
    });
  };

  const renderSection = (name: string, image: string) => {
    return (
      <div style={{ display: "flex" }}>
        <div style={{ height: 24, width: 24, marginRight: 6 }}>
          <Emoji emoji={image} size={24} />
        </div>
        <div>{name}</div>
      </div>
    );
  };

  const generateColumns = (): ColumnProps<IExploration>[] => [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (v, s) => {
        return (
          <Link
            target="_blank"
            to={routeDescriptor.explore.renderRoute({
              ...props.match.params,
              exploreSlug: s.slug,
            })}
          >
            {v}
          </Link>
        );
      },
    },
    {
      title: "Section",
      dataIndex: "section",
      key: "section",
      render: (v, s) => {
        return s.section
          ? renderSection(s.section.name, s.section.image)
          : renderSection("Work in progress", ":hammer_and_wrench:");
      },
    },
    {
      title: "Last updated",
      dataIndex: "updatedBy",
      key: "updatedBy",
      render: (v, s) => {
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <Tooltip
              title={`${s.updatedBy.firstName} ${s.updatedBy.lastName}`}
              mouseEnterDelay={0.5}
              placement="top"
            >
              <UserAvatar user={v} size={24} />
            </Tooltip>
            <div style={{ paddingLeft: 6 }}>
              <FromNow date={moment(s.updatedAt)} />
            </div>
          </div>
        );
      },
    },
  ];

  return (
    <Aligner>
      <Row justify="center" className="access-management">
        <Col xs={24} sm={24} md={20} lg={20} xl={20}>
          <CardTable<IExploration>
            rowSelection={{
              type: "checkbox",
              selectedRowKeys: keys,
              onChange: (s) => {
                setKeys(s as string[]);
              },
            }}
            cardTitle={<div className="title">Unused Explorations</div>}
            actionButtons={
              hasRoleAccessBoolean(
                IUserRoleType.ADMIN_BUILDER,
                user,
                org.id
              ) ? (
                <Button
                  onClick={trash}
                  disabled={keys.length === 0}
                  color={"danger"}
                  danger={true}
                  type="primary"
                >
                  Trash
                </Button>
              ) : undefined
            }
            rowKey="id"
            dataSource={explorations}
            columns={generateColumns()}
            pagination={{
              size: "small",
              style: { display: "none" },
              defaultPageSize: 1000,
            }}
          />
        </Col>
      </Row>
    </Aligner>
  );
}

export default compose<Props, IUnsedExplorationTableProps>(
  WithOrg,
  withRouter,
  withAntUtils
)(UnsedExplorationTable);
