import { LinkOutlined, MinusCircleOutlined } from "@ant-design/icons";
import type {
  BinaryFilter,
  BinaryOperator,
  UnaryFilter,
  UnaryOperator,
} from "@cubejs-client/core";
import { Col, Input, Row, Select, Typography } from "antd";
import type { CancelablePromise } from "cancelable-promise";
import { cancelable } from "cancelable-promise";
import cuid from "cuid";
import _ from "lodash";
import moment from "moment";
import * as React from "react";
import type { AsyncData } from "../../../helpers/typescriptHelpers";
import type { IDimensionType, IMetricType } from "../../../interfaces/table";
import type { DataType } from "../../../interfaces/transformations";
import { convertDatatypes } from "../../../utils/cubejsUtils";
import {
  format,
  getDatesFromObservationPeriod,
} from "../../../utils/periodUtils";
import DatePicker from "../../datepicker/DatePicker";
import "./FilterItem.scss";

export interface AvailableDimension {
  key: string;
  label: string;
  description?: string;
  type: IDimensionType;
  domain: DataType;
  allowTimeAgg?: boolean;
  isControl?: boolean; // only used in the object parametric section filters
}

export interface AvailableMetric {
  // Unique identifier
  key: string;
  // Label of the metric
  label: string;
  // Description
  description?: string;
  hierarchyPath?: string;
  // Formatting parameters of the metric
  formatter: {
    prefix?: string;
    suffix?: string;
    customFormatting?: string;
    format: IMetricType;
  };
}

export interface IAvailableDimensionGroup {
  type: "group";
  key: string;
  label: string;
  availableDimensions: AvailableDimension[];
}

interface IFilterItemProps {
  filter: BinaryFilter | UnaryFilter;
  availableDimensions: Array<AvailableDimension | IAvailableDimensionGroup>;
  onDelete: () => void;
  onChange: (filter: BinaryFilter | UnaryFilter) => void;
  autocomplete?: (
    dimensionName: string,
    operator?: BinaryOperator,
    value?: string
  ) => Promise<string[]>;
  className?: string;
  keyName?: "column" | "member";
  disabled?: boolean;
  valueSubstitutionColumns?: Array<{
    key: string;
    domain: DataType;
    label: string;
  }>;
}

const { RangePicker } = DatePicker;

const availableOperators = [
  { operator: "set", label: "Is set", dimensionTypes: [] },
  { operator: "notSet", label: "Is not set", dimensionTypes: [] },
  {
    operator: "equals",
    label: "Equals",
    dimensionTypes: ["string", "number", "time", "boolean"],
  },
  {
    operator: "notEquals",
    label: "Does not equal",
    dimensionTypes: ["string", "number", "time"],
  },
  { operator: "contains", label: "Contains", dimensionTypes: ["string"] },
  {
    operator: "notContains",
    label: "Does not contain",
    dimensionTypes: ["string"],
  },
  { operator: "gt", label: "Is greater than", dimensionTypes: ["number"] },
  {
    operator: "gte",
    label: "Is greater or equal than",
    dimensionTypes: ["number"],
  },
  { operator: "lt", label: "Is lower than", dimensionTypes: ["number"] },
  {
    operator: "lte",
    label: "Is lower or equal than",
    dimensionTypes: ["number"],
  },
  {
    operator: "inDateRange",
    label: "Is in date range",
    dimensionTypes: ["time"],
  },
  {
    operator: "notInDateRange",
    label: "Is not in date range",
    dimensionTypes: ["time"],
  },
  { operator: "beforeDate", label: "Is before", dimensionTypes: ["time"] },
  { operator: "afterDate", label: "Is after", dimensionTypes: ["time"] },
];

type IState = {
  [dimension: string]: AsyncData<{
    dimension: string;
    recommendations: string[];
  }>;
};

const availableRecommendationDomain: DataType[] = ["STRING", "NUMERIC"];

export default class FilterItem extends React.Component<
  IFilterItemProps,
  IState
> {
  id: string;
  ref: React.RefObject<HTMLDivElement>;
  currentPromise?: CancelablePromise;

  constructor(props: IFilterItemProps) {
    super(props);
    this.ref = React.createRef();
    this.state = this.getAllAvailableDimensions(
      props.availableDimensions
    ).reduce((acc, ad) => {
      return {
        ...acc,
        [ad.key]: { status: "initial" },
      };
    }, {});
    this.id = cuid();
  }

  getAllAvailableDimensions = (
    availableDimensions: Array<AvailableDimension | IAvailableDimensionGroup>
  ): AvailableDimension[] => {
    return availableDimensions.flatMap((ad) =>
      ad.type === "group" ? ad.availableDimensions : [ad]
    );
  };

  componentDidMount() {
    const { availableDimensions, filter, keyName } = this.props;
    const selectedDimension = this.getAllAvailableDimensions(
      availableDimensions
    ).find((ad) => ad.key === (filter as any)[this.getKeyname(keyName)]);
    if (
      selectedDimension &&
      availableRecommendationDomain.indexOf(selectedDimension.domain) > -1
    ) {
      this.fetchRecommendation(selectedDimension);
    }
  }

  componentDidUpdate(prevProps: IFilterItemProps) {
    const { availableDimensions, filter, keyName } = this.props;
    const {
      availableDimensions: prevAvailableDimension,
      keyName: prevKeyName,
    } = prevProps;
    const selectedDimension = this.getAllAvailableDimensions(
      availableDimensions
    ).find((ad) => ad.key === (filter as any)[this.getKeyname(keyName)]);
    const prevSelectedDimension = this.getAllAvailableDimensions(
      prevAvailableDimension
    ).find((ad) => ad.key === (filter as any)[this.getKeyname(prevKeyName)]);
    if (
      selectedDimension &&
      availableRecommendationDomain.indexOf(selectedDimension.domain) > -1 &&
      prevSelectedDimension &&
      selectedDimension.key !== prevSelectedDimension.key
    ) {
      if (this.currentPromise) {
        this.currentPromise.cancel();
      }
      this.fetchRecommendation(selectedDimension);
    } else if (
      selectedDimension &&
      prevSelectedDimension &&
      selectedDimension.key !== prevSelectedDimension.key
    ) {
      if (this.currentPromise) {
        this.currentPromise.cancel();
      }
      this.setState({
        ...this.state,
        [selectedDimension.key]: { status: "initial" },
      });
    }
  }

  fetchRecommendation = (
    selectedDimension: AvailableDimension,
    operator?: BinaryOperator,
    value?: string
  ) => {
    const { autocomplete } = this.props;
    if (selectedDimension.isControl) {
      return;
    }
    if (autocomplete) {
      const dimKey =
        typeof selectedDimension === "string"
          ? selectedDimension
          : selectedDimension.key;
      this.setState({ ...this.state, [dimKey]: { status: "loading" } });
      const p = cancelable(
        autocomplete(dimKey, operator, value)
          .then((r) => {
            this.setState({
              ...this.state,
              [dimKey]: {
                status: "success",
                data: {
                  dimension: dimKey,
                  recommendations: r,
                },
              },
            });
          })
          .catch((err) => {
            this.setState({
              ...this.state,
              [dimKey]: {
                status: "error",
                error: err,
              },
            });
          })
      );
      this.currentPromise = p;
    }
  };

  getKeyname = (keyName?: string) => {
    return keyName ? keyName : "member";
  };

  formatValue = (v: string, selectedDimension: AvailableDimension) => {
    switch (selectedDimension.domain) {
      case "BOOLEAN":
      case "NUMERIC":
      case "STRING":
        return v.toString();
      case "TIME":
        return getDatesFromObservationPeriod(v);
    }
  };

  debouncedOnSearch = _.debounce(
    (dimension: AvailableDimension, value?: string) => {
      if (!value) {
        return this.fetchRecommendation(dimension);
      }
      if (dimension.domain === "STRING") {
        return this.fetchRecommendation(dimension, "contains", value);
      }
      if (dimension.domain === "NUMERIC") {
        return this.fetchRecommendation(dimension, "gte", value);
      }
    },
    200
  );

  renderValueSelector = (
    selectedDimension: AvailableDimension,
    filter: BinaryFilter | UnaryFilter,
    onChange: (filter: BinaryFilter | UnaryFilter) => void,
    isControl?: boolean
  ) => {
    const { disabled, valueSubstitutionColumns } = this.props;

    if (isControl) {
      return (
        <Select value="Controlled by the user" size="small" disabled></Select>
      );
    }

    if (selectedDimension.domain === "TIME") {
      switch (filter.operator) {
        case "inDateRange":
        case "notInDateRange":
          if (!filter.values || filter.values.length !== 2) {
            onChange({
              ...filter,
              values: [moment().startOf("day"), moment().endOf("day")].map(
                (val) => (val ? val.format(format) : "")
              ),
            });
          }
          return (
            <RangePicker
              size={"small"}
              style={{ width: "100%" }}
              disabled={disabled}
              getPopupContainer={() => this.ref?.current || document.body}
              defaultValue={
                filter.values && filter.values.length === 2
                  ? [
                      moment(filter.values[0]).startOf("day"),
                      moment(filter.values[1]).endOf("day"),
                    ]
                  : [moment().startOf("day"), moment().endOf("day")]
              }
              onChange={(v) => {
                return onChange({
                  ...filter,
                  values: v
                    ? [
                        moment(v[0]).startOf("day").format(format),
                        moment(v[1]).endOf("day").format(format),
                      ]
                    : [],
                });
              }}
            />
          );
        case "notSet":
        case "set":
          return (
            <Input disabled={true} size={"small"} style={{ width: "100%" }} />
          );
        case "afterDate":
        case "beforeDate":
        case "equals":
          if (!filter.values || filter.values.length !== 1) {
            onChange({
              ...filter,
              values: [moment()].map((val) => (val ? val.format(format) : "")),
            });
          }
          const filtervalue =
            filter.values && filter.values.length
              ? moment(filter.values[0]).startOf("day")
              : moment().startOf("day");

          return (
            <DatePicker
              size={"small"}
              style={{ width: "100%" }}
              disabled={disabled}
              defaultValue={filtervalue}
              getPopupContainer={() => this.ref?.current || document.body}
              showTime
              onChange={(v) => {
                return onChange({
                  ...filter,
                  values: v ? [v.format(format)] : ([] as any),
                });
              }}
            />
          );
      }
    }

    const renderDropdown = (
      menu: React.ReactElement<any, string | React.JSXElementConstructor<any>>
    ) => {
      if (!selectedDimension || !selectedDimension.key) return <span />;

      if (this.state[selectedDimension.key].status === "initial") {
        return <span />;
      }
      if (this.state[selectedDimension.key].status === "loading") {
        return <div>loading...</div>;
      }
      if (this.state[selectedDimension.key].status === "error") {
        return <div>Error fetching recommendations...</div>;
      }
      return menu;
    };

    let recommendations: string[] = [];

    if (selectedDimension.domain === "BOOLEAN") {
      recommendations = ["true", "false"];
    } else if (this.state[selectedDimension.key].status === "success") {
      recommendations = (this.state[selectedDimension.key] as any).data
        .recommendations;
    }

    return (
      <Select
        size="small"
        style={{ width: "100%" }}
        mode="tags"
        labelRender={({ label, value }) => {
          const f = valueSubstitutionColumns?.find((v) => v.key === value);
          if (f) {
            return f.label;
          } else {
            return label;
          }
        }}
        notFoundContent={
          <Typography.Text italic type="secondary">
            Type a value
          </Typography.Text>
        }
        optionRender={(e) => {
          const f = valueSubstitutionColumns?.find((v) => v.key === e.value);
          if (f) {
            return (
              <Typography.Text>
                {f.label}{" "}
                <Typography.Text type="secondary">
                  (from column)
                </Typography.Text>
              </Typography.Text>
            );
          }
          return e.label ? e.label : e.value;
        }}
        options={[
          ...recommendations.map((r: string) => ({
            key: _.toString(r),
            value: _.toString(r),
          })),
          ...(valueSubstitutionColumns || [])
            .filter((s) => s.domain === selectedDimension.domain)
            .map((v) => {
              return {
                key: v.key,
                value: v.key,
                label: v.label,
              };
            }),
        ]}
        disabled={
          disabled
            ? disabled
            : filter.operator === "set" || filter.operator === "notSet"
        }
        value={filter.values}
        popupMatchSelectWidth={false}
        getPopupContainer={() => this.ref?.current || document.body}
        onSearch={(v) => this.debouncedOnSearch(selectedDimension, v)}
        onChange={(v) => {
          onChange({
            ...filter,
            values: v.map((val) => this.formatValue(val, selectedDimension)),
          } as BinaryFilter);
        }}
        dropdownRender={
          this.state[selectedDimension.key].status === "initial"
            ? undefined
            : renderDropdown
        }
      >
        {recommendations.map((r: string) => {
          return (
            <Select.Option key={r} value={r}>
              {r}
            </Select.Option>
          );
        })}
      </Select>
    );
  };

  public render() {
    const {
      availableDimensions,
      onDelete,
      filter,
      onChange,
      className,
      keyName,
      disabled,
    } = this.props;

    const allAvailableDimensions =
      this.getAllAvailableDimensions(availableDimensions);
    const selectedDimension = allAvailableDimensions.find(
      (ad) => ad.key === (filter as any)[this.getKeyname(keyName)]
    );

    let content: React.ReactNode = null;

    if (!selectedDimension) {
      content = (
        <>
          <div style={{ flex: 1 }}>
            <Select
              size="small"
              disabled
              style={{
                width: "100%",
                border: "1px solid red",
                borderRadius: 6,
              }}
              bordered={false}
              value={"This dimension was deleted"}
            />
          </div>
          <div style={{ flex: "0 0 26px" }}>
            <div className="remove" onClick={disabled ? undefined : onDelete}>
              <MinusCircleOutlined />
            </div>
          </div>
        </>
      );
    } else {
      const operators = availableOperators.filter(
        (ao) =>
          ao.dimensionTypes.indexOf(
            convertDatatypes(selectedDimension.domain)
          ) > -1 || ao.dimensionTypes.length === 0
      );
      content = (
        <>
          <div
            ref={this.ref}
            style={{
              flex: 1,
            }}
          >
            <Row gutter={6}>
              <Col span={12}>
                <Select
                  size="small"
                  style={{ width: "100%" }}
                  defaultValue={availableDimensions[0].key}
                  value={(filter as any)[this.getKeyname(keyName)]}
                  showSearch={true}
                  optionLabelProp="label"
                  optionFilterProp="children"
                  popupMatchSelectWidth={false}
                  getPopupContainer={() => this.ref?.current || document.body}
                  disabled={disabled}
                  labelRender={(v) => {
                    if (
                      typeof v.value === "string" &&
                      v.value.startsWith("_wly_column::")
                    ) {
                      return "test";
                    }
                    return v.label;
                  }}
                  onChange={(v) => {
                    const found = allAvailableDimensions.find(
                      (ad) => ad.key === v
                    );
                    if (found) {
                      this.fetchRecommendation(found);
                    }
                    onChange({
                      ...filter,
                      [this.getKeyname(keyName)]: v,
                    });
                  }}
                >
                  {availableDimensions.map((ad) => {
                    if (ad.type === "group") {
                      return (
                        <Select.OptGroup
                          key={ad.key}
                          label={ad.label}
                          title={ad.label}
                        >
                          {ad.availableDimensions.map((a) => {
                            return (
                              <Select.Option
                                label={
                                  <>
                                    {a.isControl ? (
                                      <>
                                        <LinkOutlined />{" "}
                                      </>
                                    ) : (
                                      ""
                                    )}
                                    {a.label === "" ? (
                                      <i>Empty String</i>
                                    ) : (
                                      a.label
                                    )}{" "}
                                    ({ad.label})
                                  </>
                                }
                                key={a.key}
                                value={a.key}
                              >
                                {a.isControl ? (
                                  <>
                                    <LinkOutlined />{" "}
                                  </>
                                ) : (
                                  ""
                                )}
                                {a.label === "" ? <i>Empty String</i> : a.label}
                              </Select.Option>
                            );
                          })}
                        </Select.OptGroup>
                      );
                    }
                    return (
                      <Select.Option
                        label={
                          <>
                            {" "}
                            {ad.isControl ? (
                              <>
                                <LinkOutlined />{" "}
                              </>
                            ) : (
                              ""
                            )}
                            {ad.label === "" ? <i>Empty String</i> : ad.label}
                          </>
                        }
                        key={ad.key}
                        value={ad.key}
                      >
                        {ad.isControl ? (
                          <>
                            <LinkOutlined />{" "}
                          </>
                        ) : (
                          ""
                        )}
                        {ad.label === "" ? <i>Empty String</i> : ad.label}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Col>
              <Col span={12}>
                <Select
                  size="small"
                  style={{ width: "100%" }}
                  defaultValue={operators[0].operator}
                  value={filter.operator}
                  popupMatchSelectWidth={false}
                  getPopupContainer={() => this.ref?.current || document.body}
                  disabled={disabled}
                  onChange={(v) => {
                    onChange(
                      v === "notSet" || v === "set"
                        ? ({
                            ...filter,
                            values: undefined,
                            operator: v as UnaryOperator,
                          } as UnaryFilter)
                        : ({
                            ...filter,
                            values: filter.values,
                            operator: v as BinaryOperator,
                          } as BinaryFilter)
                    );
                  }}
                >
                  {operators.map((o) => {
                    return (
                      <Select.Option key={o.operator} value={o.operator}>
                        {o.label}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Col>
            </Row>
            <Row>
              <Col span={24} style={{ paddingTop: 6, display: "flex", gap: 6 }}>
                {this.renderValueSelector(
                  selectedDimension,
                  filter,
                  onChange,
                  selectedDimension.isControl
                )}
              </Col>
            </Row>
          </div>
          <div
            style={{
              display: "flex",
              flex: "0 0 26px",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div className="remove" onClick={disabled ? undefined : onDelete}>
              <MinusCircleOutlined />
            </div>
          </div>
        </>
      );
    }

    return (
      <div id={this.id} className={`filter-item ${className}`}>
        {content}
      </div>
    );
  }
}
