import type { Query, TimeDimensionGranularity } from "@cubejs-client/core";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import HighchartsMore from "highcharts/highcharts-more";
import _ from "lodash";
import * as React from "react";
import type { ChartOption } from "../../../containers/chart-options/ChartOptions";
import type { UserLocale } from "../../../interfaces/user";
import type { AxisChartOptionDefinition, Formatter } from "../domain";
import { localizeHighchart } from "../domain";
import type { HighchartLegendItem } from "../utils/HighchartsLegend";
import { HighchartsLegend } from "../utils/HighchartsLegend";
import {
  customFormatter,
  hasPredominentFormatter,
  optionsHelper,
  renderSerieTooltip,
} from "../utils/optionsHelper";

HighchartsMore(Highcharts);

interface ILineChart2Props {
  height: number;
  data: Array<{ [key: string]: string | number }>;
  config: LineChartConfig;
  tinyLegend?: boolean;
  scale?: "time" | "ordinal";
  unit?: "second" | "percent";
  width?: number;
  onDrill?: (q: Query) => void;
  chartOptions?: ChartOption;
  locale: UserLocale;
}

interface LineChartConfig {
  x: {
    key: string;
    label?: string;
  };
  y: Array<IYConfigLineChart>;
  stacking?: boolean;
  axis?: AxisChartOptionDefinition;
  showLabels?: boolean;
  timeGranularity?: TimeDimensionGranularity;
}

interface IYConfigLineChart {
  key: string;
  label?: string;
  type?: "bar" | "line" | "area" | "scatter";
  axis?: "left" | "right";
  canDrill: (xValue: string) => Query | null;
  color?: string;
  prediction?: {
    previousStartValue: string;
    startValue: string;
    getHighKey: () => string;
    getLowKey: () => string;
  };
  dashed?: boolean;
  formatter: Formatter;
}

interface IState {
  legendItems: HighchartLegendItem[];
}

export default class LineChart2 extends React.Component<
  ILineChart2Props,
  IState
> {
  chartRef: HighchartsReact.RefObject;

  constructor(props: ILineChart2Props) {
    super(props);
    this.state = {
      legendItems: [],
    };
  }

  componentDidUpdate(prevProps: ILineChart2Props) {
    if (
      (prevProps.width !== this.props.width ||
        prevProps.height !== this.props.height ||
        !_.isEqual(this.props.config, prevProps.config)) &&
      this.chartRef
    ) {
      this.chartRef?.chart?.reflow?.();
    }
  }

  shouldComponentUpdate(
    nextProps: Readonly<ILineChart2Props>,
    nextState: Readonly<IState>,
    nextContext: any
  ): boolean {
    if (!_.isEqual(this.state.legendItems, nextState.legendItems)) return true;
    if (!_.isEqual(this.props.width, nextProps.width)) return true;
    if (!_.isEqual(this.props.height, nextProps.height)) return true;
    if (!_.isEqual(this.props.data, nextProps.data)) return true;
    if (!_.isEqual(this.props.scale, nextProps.scale)) return true;
    if (!_.isEqual(this.props.tinyLegend, nextProps.tinyLegend)) return true;
    if (!_.isEqual(this.props.chartOptions, nextProps.chartOptions))
      return true;
    return false;
  }

  public render() {
    const {
      height,
      config,
      data,
      scale,
      width,
      onDrill,
      chartOptions,
      locale,
    } = this.props;

    localizeHighchart(Highcharts, locale);
    const xFormatter: Formatter | undefined = chartOptions?.axis?.bottom
      ?.timeFormat
      ? {
          format: {
            type: "moment",
            value: chartOptions?.axis?.bottom?.timeFormat,
          },
        }
      : null;
    const predominentY1Formatter = hasPredominentFormatter(
      config.y
        .filter((f) => !f.axis || f.axis === "left")
        .map((f) => f.formatter)
    );
    const predominentY2Formatter = hasPredominentFormatter(
      config.y.filter((f) => f.axis === "right").map((f) => f.formatter)
    );

    const adjustedHeight =
      chartOptions?.["hide-legend"] !== true &&
      this.state.legendItems.length > 1
        ? height - 40
        : height;

    const defaultOptions = optionsHelper(
      adjustedHeight,
      width,
      locale,
      scale,
      predominentY1Formatter,
      predominentY2Formatter,
      xFormatter,
      chartOptions
    );

    const options: Highcharts.Options = {
      ...defaultOptions,
      series: config.y.flatMap((y) => {
        let type: string = "spline";
        if (y.type) {
          if (y.type === "line") {
            type = "spline";
          } else if (y.type === "area") {
            type = "areaspline";
          } else if (y.type === "bar") {
            type = "column";
          } else {
            type = y.type;
          }
        }
        let fillColor: any = {
          fillColor: undefined,
          color: Highcharts.color(y.color!).setOpacity(1).get("rgba"),
        };
        if (type === "areaspline") {
          fillColor.fillColor = {
            linearGradient: {
              x1: 0,
              y1: 0,
              x2: 0,
              y2: 1,
            },
            stops: [
              [0, Highcharts.color(y.color!).setOpacity(0.6).get("rgba")],
              [1, Highcharts.color(y.color!).setOpacity(0).get("rgba")],
            ],
          };
        } else if (type === "column" && y.prediction) {
          fillColor.color = Highcharts.color(y.color!)
            .setOpacity(0.5)
            .get("rgba");
        }

        const startPrediction = () => {
          if (y.prediction) {
            if (type === "column" || type === "scatter") {
              return scale === "time"
                ? new Date(y.prediction.startValue).getTime()
                : y.prediction.startValue;
            }
            return scale === "time"
              ? new Date(y.prediction.previousStartValue).getTime()
              : y.prediction.previousStartValue;
          }
          return undefined;
        };
        const getXValue = (d: { [key: string]: string | number }) =>
          scale === "time"
            ? new Date(d[config.x.key]).getTime()
            : d[config.x.key];

        const getZoneDefinition = () => {
          if (y.prediction) {
            if (type === "column" || type === "scatter") {
              return [
                {
                  value: startPrediction(),
                  color: Highcharts.color(y.color!).setOpacity(1).get("rgba"),
                },
              ];
            }
            return [
              {
                value: startPrediction(),
                dashStyle: "solid",
              },
            ];
          }
          return [];
        };

        const ret: Array<Highcharts.SeriesOptionsType> = [
          {
            type: type as any,
            color: y.color,
            name: y.label,
            id: y.key,
            dashStyle:
              y.dashed || (y.prediction && type !== "column")
                ? "Dash"
                : "Solid",
            yAxis: y.axis && y.axis === "right" ? 1 : 0,
            marker: {
              symbol: "circle",
            },
            // dataLabels: y.showLabels ? {
            //   enabled: true
            // } : undefined,
            data: data.map((d) => {
              return [getXValue(d), d[y.key]];
            }),
            cursor: "pointer",
            events: {
              click: onDrill
                ? (e) => {
                    const xValue =
                      scale === "time"
                        ? data.find(
                            (q) =>
                              new Date(q[config.x.key]).getTime() === e.point.x
                          )![config.x.key]
                        : e.point.name.toString();
                    const q = y.canDrill(xValue.toString());
                    if (q) {
                      return onDrill(q);
                    }
                  }
                : undefined,
            },
            zIndex: 1,
            zoneAxis: "x",
            zones: getZoneDefinition(),
            dataLabels: [
              {
                enabled: config.showLabels,
                formatter: function () {
                  return customFormatter((this as any).y, locale, y.formatter);
                },
                style: {
                  fontSize: "0.8em",
                },
              },
              {
                enabled: config.showLabels,
                formatter: function () {
                  return customFormatter((this as any).y, locale, y.formatter);
                },
                filter: {
                  operator: "<",
                  property: "y",
                  value: 0,
                },
              },
            ],
            tooltip: renderSerieTooltip({
              data: data,
              locale: locale,
              seriesLength: config.y.length,
              formatter: y.formatter,
              comparison: {
                timeGranularity: config.timeGranularity,
              },
            }),
            ...fillColor,
          },
        ];

        if (y.prediction) {
          const getLowValue = (d: { [key: string]: string | number }) => {
            const lowValueKey = y.prediction.getLowKey();
            if (d[lowValueKey]) {
              return d[lowValueKey];
            }
            if (startPrediction() === getXValue(d)) {
              return d[y.key];
            }
            return undefined;
          };

          const getHighValue = (d: { [key: string]: string | number }) => {
            const highValueKey = y.prediction.getHighKey();
            if (d[highValueKey]) {
              return d[highValueKey];
            }
            if (startPrediction() === getXValue(d)) {
              return d[y.key];
            }
            return undefined;
          };

          if (type === "spline" || type === "areaspline") {
            ret.push({
              type: "areasplinerange",
              lineWidth: 0,
              color: y.color,
              name: y.label,
              linkedTo: ":previous",
              dashStyle: y.dashed ? "Dash" : "Solid",
              yAxis: y.axis && y.axis === "right" ? 1 : 0,
              fillOpacity: 0.3,
              enableMouseTracking: false,
              zIndex: 0,
              tooltip: {
                enabled: false,
              } as any,
              marker: {
                enabled: false,
              },
              data: data
                .map((d) => {
                  return [getXValue(d), getLowValue(d), getHighValue(d)];
                })
                .filter((d) => d[1] && d[2]),
              dataLabels: [
                {
                  enabled: config.showLabels,
                  style: {
                    fontSize: "0.8em",
                  },
                  formatter: function () {
                    return customFormatter(
                      (this as any).y,
                      locale,
                      y.formatter
                    );
                  },
                  filter: {
                    operator: ">",
                    property: "y",
                    value: 0,
                  },
                },
                {
                  enabled: config.showLabels,
                  formatter: function () {
                    return customFormatter(
                      (this as any).y,
                      locale,
                      y.formatter
                    );
                  },
                  filter: {
                    operator: "<",
                    property: "y",
                    value: 0,
                  },
                },
              ],
            });
          } else {
            ret.push({
              type: "errorbar",
              color: y.color,
              name: y.label,
              linkedTo: ":previous",
              zIndex: 0,
              enableMouseTracking: false,
              data: data
                .map((d) => {
                  return [getXValue(d), getLowValue(d), getHighValue(d)];
                })
                .filter((d) => d[1] && d[2]),
            });
          }
        }

        return ret;
      }),
      legend: {
        ...defaultOptions.legend,
        enabled: true,
        floating: true,
        x: -10000,
      },
      ...{
        plotOptions: {
          ...defaultOptions.plotOptions,
          series: {
            ...defaultOptions.plotOptions.series,
            lineWidth: 2,
            marker: {
              enabled: false,
            },
          },
          spline: {
            marker: {
              enabled: false,
            },
            lineWidth: 2,
            dataLabels: {
              enabled: config.showLabels,
              style: {
                fontSize: "0.8em",
              },
            },
          },
        },
      },
    };

    if (config.stacking) {
      options.plotOptions = {
        ...(options.plotOptions ? options.plotOptions : {}),
        column: {
          ...(options.plotOptions && options.plotOptions.column
            ? options.plotOptions.column
            : {}),
          stacking: "normal",
        },
      };
    }

    return (
      <div style={{ height, width: width ? width : "100%" }}>
        {this.props.chartOptions?.["hide-legend"] !== true &&
          this.state.legendItems.length > 1 && (
            <HighchartsLegend
              legendItems={this.state.legendItems}
              setVisibilityState={(index, s) => {
                this.chartRef?.chart?.series?.[index]?.setState?.(s);
              }}
              toggleVisibility={(index) => {
                this.setState((prevState) => ({
                  legendItems: prevState.legendItems?.map((legendItem, i) => {
                    if (index === i) {
                      return {
                        ...legendItem,
                        visible: !legendItem.visible,
                      };
                    } else {
                      return legendItem;
                    }
                  }),
                }));
                if (this.state.legendItems?.[index]?.visible) {
                  this.chartRef?.chart?.series?.[index]?.hide?.();
                } else {
                  this.chartRef?.chart?.series?.[index]?.show?.();
                }
              }}
            />
          )}
        <HighchartsReact
          ref={(ref) => {
            if (ref) {
              this.chartRef = ref;
              if (Array.isArray(ref?.chart?.legend?.allItems)) {
                const legendItems: HighchartLegendItem[] =
                  ref?.chart?.legend?.allItems.map((legendItem, index) => ({
                    key: index,
                    name: legendItem.name,
                    color: legendItem.color as string,
                    visible: legendItem.visible,
                  }));
                this.setState({ legendItems: legendItems });
              }
            }
          }}
          highcharts={Highcharts}
          options={options}
          callback={(chart) => {
            this.setState({ legendItems: chart.legend.allItems });
          }}
        />
      </div>
    );
  }
}
