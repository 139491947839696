import { Col } from "antd";
import React from "react";
import * as Toolbar from "../../../../spreadsheet/toolbar/Toolbar";

interface IMeasureEditorFormWrapperProps {
  name?: string;
  fullSize?: boolean;
  children?: React.ReactNode;
}

export const MeasureEditorFormWrapper = React.forwardRef(
  (props: IMeasureEditorFormWrapperProps, ref: React.Ref<HTMLDivElement>) => {
    const renderInner = () => {
      if (props.fullSize) {
        return props.children;
      }

      return (
        <Col span={16} offset={4}>
          {props.children}
        </Col>
      );
    };

    return (
      <>
        <Toolbar.Toolbar style={{ borderTop: "none" }}>
          <Toolbar.ViewName>{props.name ? props.name : ""}</Toolbar.ViewName>
        </Toolbar.Toolbar>
        <div className="exploration-viewer-logs-panel" ref={ref}>
          <div>{renderInner()}</div>
        </div>
      </>
    );
  }
);
