import { gql } from "@apollo/client";
import type { QueryOptions } from "../../../components/hooks/query/useGQLQuery";
import { useGQLQuery } from "../../../components/hooks/query/useGQLQuery";
import type { IExploration } from "../../../interfaces/explorations";

type QueryData = {
  Exploration: IExploration;
};

type QueryVariables = {
  explorationId: string;
};

const ExplorationUpdatedByQuery = gql`
  query GetExplorationPopoverDetails($explorationId: ID!) {
    Exploration(where: { id: $explorationId }) {
      id
      description
      updatedAt
      updatedBy {
        id
        email
        firstName
        lastName
        gravatar
        avatarColor
      }
    }
  }
`;

export const useGetExplorationUpdatedBy = (
  options: QueryOptions<QueryData, QueryVariables>
) => {
  return useGQLQuery<QueryData, QueryVariables>(
    ExplorationUpdatedByQuery,
    options
  );
};
