import { Col, Row, Space } from "antd";
import _ from "lodash";
import * as React from "react";
import { compose } from "../../../../../../components/compose/WlyCompose";
import type { IObject } from "../../../../../../interfaces/object";
import type { InjectedOrgProps } from "../../../../../orgs/WithOrg";
import WithOrg from "../../../../../orgs/WithOrg";
import type {
  IColumn,
  ILayout,
  IRecordAction,
  IRecordWidgetData,
  IRow,
  IWidget,
} from "../domain";
import { emailWidgetObjectData, layoutObjectData } from "../domain";

import {
  ArrowDownOutlined,
  ArrowUpOutlined,
  CopyFilled,
  DeleteFilled,
} from "@ant-design/icons";
import Loading from "../../../../../../components/layout/feedback/loading";
import { IObjectLayoutItemType } from "../../../../../custom-layout-editor/main/sider/add/domain";
import { generateFakeId } from "../../../../../workbench/workbench/exploration/domain";
import { EmailWidgetRenderer } from "../email-widgets/EmailWidgetRenderer";
import { EmptyColumnDropZone } from "./EmptyColumnDropZone";
import { HoverWrapper } from "./HoverWrapper";
import {
  getSortedCols,
  getSortedRows,
  getSortedWidgets,
} from "./RecordEmailDomain";
import { RecordEmailPreview } from "./RecordEmailPreview";
import "./RecordLayout.scss";
import { WidgetDrag } from "./WidgetDrag";

type IRecordEmailLayoutProps =
  | IRecordEmailLayoutEditionProps
  | IRecordEmailLayoutDisplayProps;

interface IRecordEmailLayoutEditionProps
  extends IRecordEmailLayoutDisplayProps {
  edit: true;
  hovered?: string;
  selected?: string;
  setAction: (action: "hover" | "click", key: string) => void;
  onLayoutChange: (actions: IRecordAction[]) => void;
}

interface IRecordEmailLayoutDisplayProps {
  layout: ILayout;
  object: IObject;
  data: IRecordWidgetData;
  edit: boolean;
  preview: boolean;
  emailTemplateMjml?: string;
}

interface IRecordEmailLayoutState {}

type Props = IRecordEmailLayoutProps & InjectedOrgProps;

interface IInner<T, S extends string> {
  id: string;
  position: number;
  type: S;
  data: T;
}

type IInnerColumn =
  | IInner<IRow, "row">
  | IInner<IWidget, "widget">
  | IInner<{}, "space">;

class RecordEmailLayout extends React.Component<
  Props,
  IRecordEmailLayoutState
> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  buildWidget = (widget: IWidget, colId: string, p: number) => {
    const { data, object, org, user, layout } = this.props;
    const { edit, hovered, selected, setAction, onLayoutChange } = this
      .props as IRecordEmailLayoutEditionProps;

    const renderInner = () => {
      return (
        <EmailWidgetRenderer
          object={object}
          widget={widget}
          record={data}
          edit={edit}
          org={org}
          user={user}
        />
      );
    };

    return (
      <HoverWrapper
        componentKey={`widget::${widget.id}`}
        componentLabel={"Widget"}
        edit={edit}
        hovered={hovered}
        selected={selected}
        setAction={setAction}
        drop={{
          accept: IObjectLayoutItemType.WIDGET,
          onDrop: (i, position) => {
            if (i.operation === "add") {
              const widgetItem = emailWidgetObjectData[i.identifier];
              if (!widgetItem) {
                return alert("item does not exists");
              }

              const gen = widgetItem.generate(
                widget.position,
                widget.parentColumn?.id
              );
              onLayoutChange([
                { type: "widget::add", data: gen },
                {
                  type: "widget::move",
                  data: gen,
                  position: position === "top" ? "top" : "bottom",
                },
              ]);
            } else {
              const parentId = colId;
              const prevParentId = i.data.parentColumn?.id;

              const data = {
                ...i.data,
                position: p,
                parentColumn: {
                  id: parentId,
                },
              };

              onLayoutChange([
                {
                  type: "widget::move",
                  data: Object.assign({}, data),
                  colIdToReorder:
                    parentId !== prevParentId ? prevParentId : undefined,
                  position: position === "top" ? "top" : "bottom",
                },
              ]);
            }
          },
        }}
        toolbox={[
          {
            key: "duplicate",
            name: "Duplicate",
            icon: <CopyFilled />,
            onClick: () => {
              const id = generateFakeId();
              const newWidget = {
                ...widget,
                id,
              };
              onLayoutChange([
                {
                  type: "widget::add",
                  data: newWidget,
                },
                {
                  type: "widget::move",
                  position: "bottom",
                  data: newWidget,
                },
              ]);
              setAction("click", `widget::${id}`);
            },
          },
          {
            key: "delete",
            name: "Delete",
            icon: <DeleteFilled />,
            onClick: () => {
              setAction("click", "body");
              onLayoutChange([{ type: "widget::remove", data: widget }]);
            },
          },
        ]}
      >
        <WidgetDrag edit={edit} widget={widget}>
          {renderInner()}
        </WidgetDrag>
      </HoverWrapper>
    );
  };

  buildColumns = (cols: IColumn[]) => {
    const { data, object, edit, hovered, selected, setAction, onLayoutChange } =
      this.props as IRecordEmailLayoutEditionProps;

    return getSortedCols(cols).map((c) => {
      const { layout } = this.props;

      const sortedWidgets = getSortedWidgets(
        layout.widgets,
        c,
        object,
        data,
        edit
      );
      const innerWidgets: Array<IInner<IWidget, "widget">> = sortedWidgets.map(
        (w) => ({
          data: w,
          type: "widget",
          position: w.position,
          id: w.id,
        })
      );

      const inner: IInnerColumn[] = _.sortBy([...innerWidgets], ["position"]);

      const renderInnerColumn = (i: IInnerColumn) => {
        if (i.type === "widget") {
          return this.buildWidget(i.data, c.id, i.data.position);
        } else if (i.type === "space") {
          return (
            <EmptyColumnDropZone
              colId={c.id}
              drop={{
                accept: IObjectLayoutItemType.WIDGET,
                onDrop: (d) => {
                  if (d.operation === "add") {
                    const widgetItem = emailWidgetObjectData[d.identifier];
                    if (!widgetItem) {
                      return alert("item does not exists");
                    }
                    const gen = widgetItem.generate(0, c.id);
                    onLayoutChange([{ type: "widget::add", data: gen }]);
                  } else if (d.operation === "move") {
                    const parentId = c.id;
                    const prevParentId = d.data.parentColumn?.id;

                    const ndata = {
                      ...d.data,
                      position: 0,
                      parentColumn: {
                        id: parentId,
                      },
                    };

                    // alert(
                    //   `moved from position ${
                    //     d.data.position
                    //   } to position 0. We have ${
                    //     parentId !== prevParentId ? "" : "not "
                    //   } changed columns`
                    // );
                    onLayoutChange([
                      {
                        type: "widget::move",
                        data: ndata,
                        position: "top",
                        colIdToReorder:
                          parentId !== prevParentId ? prevParentId : undefined,
                      },
                    ]);
                  }
                },
              }}
            >
              Drop something here
            </EmptyColumnDropZone>
          );
        }
      };

      if (edit && inner.length === 0) {
        inner.unshift({
          id: generateFakeId(),
          position: 0,
          type: "space",
          data: {},
        });
      }

      return (
        <Col key={c.id} xs={24} md={c.size}>
          <HoverWrapper
            componentKey={`col::${c.id}`}
            componentLabel={`Col`}
            edit={edit}
            hovered={hovered}
            selected={selected}
            setAction={setAction}
          >
            <Space
              style={{ width: "100%", display: "flex" }}
              size={"middle"}
              direction="vertical"
            >
              {inner.map((i) => {
                return <div key={i.id}>{renderInnerColumn(i)}</div>;
              })}
            </Space>
          </HoverWrapper>
        </Col>
      );
    });
  };

  buildRows = (rows: IRow[]) => {
    const { data, object, edit, hovered, selected, setAction, onLayoutChange } =
      this.props as IRecordEmailLayoutEditionProps;

    const sortedRows = getSortedRows(rows, object, data, edit);

    if (sortedRows.length === 0 && edit) {
      return (
        <EmptyColumnDropZone
          colId={"initial"}
          drop={{
            accept: IObjectLayoutItemType.LAYOUT,
            onDrop: (i) => {
              const layoutItem = layoutObjectData[i.identifier];
              if (!layoutItem) {
                return alert("item does not exists");
              }

              const newItemPosition = 0;

              const gen = layoutItem.generate(newItemPosition);
              onLayoutChange([{ type: "layout::add", data: gen }]);
            },
          }}
        >
          Drop something here
        </EmptyColumnDropZone>
      );
    }

    return sortedRows.map((r, i, s) => {
      return (
        <HoverWrapper
          componentKey={`row::${r.id}`}
          componentLabel={"Row"}
          edit={edit}
          hovered={hovered}
          selected={selected}
          setAction={setAction}
          key={r.id}
          toolbox={[
            // {
            //   key: "position",
            //   name: "Position",
            //   icon: r.position,
            //   onClick: () => console.log("ok"),
            // },
            ...(i !== 0
              ? [
                  {
                    key: "move-up",
                    name: "Move up",
                    icon: <ArrowUpOutlined />,
                    onClick: () => {
                      onLayoutChange([
                        {
                          type: "layout::move",
                          data: { ...r, position: r.position - 1 },
                          position: "top",
                        },
                      ]);
                    },
                  },
                ]
              : []),
            ...(i !== s.length - 1
              ? [
                  {
                    key: "move-down",
                    name: "Move down",
                    icon: <ArrowDownOutlined />,
                    onClick: () => {
                      onLayoutChange([
                        {
                          type: "layout::move",
                          data: { ...r, position: r.position + 1 },
                          position: "top",
                        },
                      ]);
                    },
                  },
                ]
              : []),
            {
              key: "delete",
              name: "Delete",
              icon: <DeleteFilled />,
              onClick: () => {
                setAction("click", "body");
                onLayoutChange([{ type: "layout::remove", data: r }]);
              },
            },
          ]}
          drop={
            edit
              ? {
                  accept: IObjectLayoutItemType.LAYOUT,
                  onDrop: (i, position) => {
                    const layoutItem = layoutObjectData[i.identifier];
                    if (!layoutItem) {
                      return alert("item does not exists");
                    }

                    const newPosition =
                      position === "top" ? r.position : r.position + 1;
                    const gen = layoutItem.generate(newPosition);

                    onLayoutChange([
                      {
                        type: "layout::add",
                        data: gen,
                      },
                      {
                        type: "layout::move",
                        data: gen,
                        position: "top",
                      },
                    ]);
                  },
                }
              : undefined
          }
        >
          <Row style={{ marginTop: 8, padding: `8px 0` }} gutter={[16, 16]}>
            {this.buildColumns(r.columns)}
          </Row>
        </HoverWrapper>
      );
    });
  };

  render() {
    const { layout, preview, org, user, data, object, emailTemplateMjml } =
      this.props;
    const { edit } = this.props as IRecordEmailLayoutEditionProps;

    if (preview) {
      if (data.status === "success" && emailTemplateMjml) {
        return (
          <RecordEmailPreview
            object={object}
            record={data.data}
            layout={layout}
            org={org}
            user={user}
            emailTemplateMjml={emailTemplateMjml}
          />
        );
      }
      return <Loading />;
    }

    return (
      <div
        className={`record-view record-layout boxed ${edit ? "edition" : ""}`}
      >
        <div>{this.buildRows(layout.rows)}</div>
      </div>
    );
  }
}

export default compose<Props, IRecordEmailLayoutProps>(WithOrg)(
  RecordEmailLayout
);
