import Feednack from "../../../../../components/layout/feedback/feedback";
import { type IDataset } from "../../../../../interfaces/sources";
import type { IActiveObject, ObjectStore } from "../../domain";
import type { UpdateObjectLayoutFunction } from "../../selector/object-layouts/domain";
import { ObjectLayoutViewerInner } from "./ObjectLayoutViewerInner";

interface IObjectLayoutViewerProps {
  objectStore: ObjectStore;
  activeObject: IActiveObject;
  fetchObject: (objectId: string) => Promise<void>;
  fetchObjectLayout: (objectId: string, layoutId: string) => Promise<void>;
  updateActiveObject: (newActiveObject: IActiveObject) => void;
  onUpdateObjectLayout: UpdateObjectLayoutFunction;
  allDatasets: IDataset[];
}

export function ObjectLayoutViewer(props: IObjectLayoutViewerProps) {
  const {
    objectStore,
    activeObject,
    fetchObject,
    fetchObjectLayout,
    updateActiveObject,
    onUpdateObjectLayout,
    allDatasets,
  } = props;

  const [oId, lId] = activeObject.value.split("::");
  const object = objectStore[oId];

  if (!object) {
    return <Feednack>Can't find object related to layout</Feednack>;
  }

  const layout = object.layouts[lId];

  if (!layout) {
    return <Feednack>Can't find object layout</Feednack>;
  }

  return (
    <ObjectLayoutViewerInner
      objectData={object}
      layoutData={layout}
      fetchObject={fetchObject}
      fetchObjectLayout={fetchObjectLayout}
      activeObject={activeObject}
      updateActiveObject={updateActiveObject}
      onUpdateObjectLayout={onUpdateObjectLayout}
      allDatasets={allDatasets}
    />
  );
}
