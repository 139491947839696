import _ from "lodash";
import type { IObject } from "../../../../../../interfaces/object";
import { shouldDisplayWidget } from "../common/visibility-filters/domain";
import type { IColumn, IRecordWidgetData, IRow, IWidget } from "../domain";

export const getSortedRows = (
  rows: IRow[],
  object: IObject,
  record: IRecordWidgetData,
  edit?: boolean
) =>
  _.sortBy(rows, ["position"]).filter((r) => {
    if (edit) {
      return true;
    }
    if (r.displayFilters) {
      const shouldDisplay = shouldDisplayWidget(
        r.displayFilters,
        record.status === "success" ? record.data : {},
        object
      );
      return shouldDisplay;
    }
    return true;
  });

export const getSortedCols = (cols: IColumn[]) => _.sortBy(cols, ["position"]);

export const getSortedWidgets = (
  widgets: IWidget[],
  column: IColumn,
  object: IObject,
  record: IRecordWidgetData,
  edit?: boolean
) =>
  _.sortBy(widgets, ["position"])
    .filter((f) => f.parentColumn?.id === column.id)
    .filter((i) => {
      if (edit) {
        return true;
      }
      if (i.displayFilters) {
        return shouldDisplayWidget(
          i.displayFilters,
          record.status === "success" ? record.data : {},
          object
        );
      }

      return true;
    });
