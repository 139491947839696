import type { IWidgetEmailRendererProps } from "../../domain";
import type { IEmailWidgetDividerConfig } from "../domain";
import { getDefaultConfig } from "../domain";

interface IEmailWidgetDividerRendererProps
  extends IWidgetEmailRendererProps<IEmailWidgetDividerConfig> {}

export function EmailWidgetDividerRenderer(
  props: IEmailWidgetDividerRendererProps
) {
  const { conf, record } = props;

  const defaults = getDefaultConfig(conf, record);

  return (
    <div
      style={{
        width: "100%",
        height: 5,
        borderBottom: `${defaults.borderWidth}px solid ${defaults.color}`,
      }}
    />
  );
}
