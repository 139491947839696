import fetch from "cross-fetch";
import "url-search-params-polyfill";

// WARNING -- COPIED FROM https://github.com/cube-js/cube.js/blob/master/packages/cubejs-client-core/src/HttpTransport.js
//
// We need a custom transport implementation to lift the request id to get access to the trace of the query
// so I have added a method liftRequestId to the class implementation

class HttpTransport {
  constructor({
    authorization,
    apiUrl,
    method,
    headers = {},
    credentials,
    baseRequestId,
  }) {
    this.authorization = authorization;
    this.apiUrl = apiUrl;
    this.method = method;
    this.headers = headers;
    this.credentials = credentials;
    this.baseRequestId = baseRequestId;
  }

  request(method, { baseRequestId, ...params }) {
    let spanCounter = 1;
    const newBaseRequestId = this.baseRequestId
      ? this.baseRequestId
      : baseRequestId;
    const searchParams = new URLSearchParams(
      params &&
        Object.keys(params)
          .map((k) => ({
            [k]:
              typeof params[k] === "object"
                ? JSON.stringify(params[k])
                : params[k],
          }))
          .reduce((a, b) => ({ ...a, ...b }), {})
    );

    let url = `${this.apiUrl}/${method}${
      searchParams.toString().length ? `?${searchParams}` : ""
    }`;

    const requestMethod = this.method || (url.length < 2000 ? "GET" : "POST");
    if (requestMethod === "POST") {
      url = `${this.apiUrl}/${method}`;
      this.headers["Content-Type"] = "application/json";
    }

    // Currently, all methods make GET requests. If a method makes a request with a body payload,
    // remember to add {'Content-Type': 'application/json'} to the header.
    const runRequest = () =>
      fetch(url, {
        method: requestMethod,
        headers: {
          Authorization: this.authorization,
          "x-request-id":
            newBaseRequestId && `${newBaseRequestId}-span-${spanCounter++}`,
          ...this.headers,
        },
        credentials: this.credentials,
        body: requestMethod === "POST" ? JSON.stringify(params) : null,
      });

    return {
      async subscribe(callback) {
        const result = await runRequest();
        return callback(result, () => this.subscribe(callback));
      },
    };
  }
}

export default HttpTransport;
