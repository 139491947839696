import type { INotificationJob } from "../../../../../interfaces/notification";

export const getJobColor = (job: INotificationJob): string => {
  switch (job.status) {
    case "completed":
      return "#52c41a";
    case "canceled":
    case "failed":
      return "#ff4d4f";
    case "delayed":
    case "pending":
    case "running":
      return "#69a2b0";
    case "merged":
      return "#d3adf7";
    default:
      return "#9e9e9e";
  }
};

export const getJobSimplifiedStatus = (job: INotificationJob): string => {
  switch (job.status) {
    case "completed":
      return "Success";
    case "canceled":
      return "Canceled";
    case "failed":
      return "Error";
    case "delayed":
      return "Delayed";
    case "pending":
      return "Pending";
    case "running":
      return "Running";
    case "merged":
      return "Merged";
    default:
      return "Unknown";
  }
};

export const getJobMessageTitle = (
  job: INotificationJob
): string | undefined => {
  if (job.notificationWorkflow.channel === "email") {
    return `To: ${job.user.email}`;
  }
  return undefined;
};

export const getJobMessageSubtitle = (
  job: INotificationJob
): string | undefined => {
  if (job.notificationWorkflow.channel === "email" && job.emailSubject) {
    return `${job.emailSubject}`;
  }
  return undefined;
};
