import * as React from "react";
import type { FieldProps } from "formik";
import type { InputProps } from "antd/lib/input";
import type { FormItemProps } from "antd/lib/form/FormItem";
import { Form, Input as AntInput } from "antd";

interface IInputProps extends FieldProps {
  inputProps: InputProps;
  formItemProps: FormItemProps;
}

export default class Input extends React.Component<IInputProps> {
  public render() {
    const { inputProps, formItemProps, field, form } = this.props;

    const isSubmitting = form.isSubmitting;
    const touched = form.touched[field.name];
    const submitted = form.submitCount > 0;
    const hasError = form.errors[field.name];
    const submittedError = hasError && submitted;
    const touchedError = hasError && touched;
    const onInputChange = ({
      target: { value },
    }: React.ChangeEvent<HTMLInputElement>) =>
      form.setFieldValue(field.name, value);
    const onBlur = () => form.setFieldTouched(field.name, true);

    return (
      <Form.Item
        label={formItemProps.label}
        hasFeedback={
          (formItemProps.hasFeedback && submitted) ||
          (formItemProps.hasFeedback && touched)
            ? true
            : undefined
        }
        help={submittedError || touchedError ? <>{hasError}</> : undefined}
        validateStatus={
          submittedError || touchedError
            ? "error"
            : isSubmitting
            ? "validating"
            : "success"
        }
        {...formItemProps}
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
      >
        <AntInput
          {...field}
          {...inputProps}
          size="large"
          disabled={isSubmitting || inputProps.disabled}
          onBlur={onBlur}
          onChange={onInputChange}
        />
      </Form.Item>
    );
  }
}
