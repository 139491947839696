import { Button, Form, Input, Modal, TreeSelect } from "antd";
import * as React from "react";
import type { RouteComponentProps } from "react-router";
import { withRouter } from "react-router";
import { compose } from "../../../components/compose/WlyCompose";
import { buildFolderTree } from "../../../components/folders/domain";
import { WlyFolderIcon } from "../../../components/icons/custom-icons/WlyFolderIcon";
import Aligner from "../../../components/layout/aligner/Aligner";
import Loading from "../../../components/layout/feedback/loading";
import type { InjectedOrgProps } from "../../../containers/orgs/WithOrg";
import WithOrg from "../../../containers/orgs/WithOrg";
import type { AsyncData } from "../../../helpers/typescriptHelpers";
import type { IReportFolder } from "../../../interfaces/folder";
import type { IReport } from "../../../interfaces/reports";
import { routeDescriptor } from "../../../routes/routes";
import { track } from "../../../services/AnalyticsService";
import GraphQLService from "../../../services/graphql/GraphQLService";
import { REPORT_FOLDER } from "../../../store/dataStores/workspace/WorkspaceDatastoreDomain";
import "./ReportCreation.scss";

interface IReportCreationModalProps {
  folder?: IReportFolder;
  overrideTitle?: string;
  children: (onClick: () => void) => React.ReactNode;
}

const GQL = `
mutation createReport($data: ReportCreateInput!) {
  createReport(data: $data) {
    id
    slug
  }
}
`;

type Props = IReportCreationModalProps &
  InjectedOrgProps &
  RouteComponentProps<{}>;

interface IFormValue {
  name: string;
  folderId?: string;
}

const ReportCreationModal = (props: Props) => {
  const { org, history, folder, overrideTitle, children } = props;

  const [reportCreationVisible, setReportCreationVisible] =
    React.useState<boolean>(false);
  const [savedReport, setSavedReport] = React.useState<AsyncData<IReport>>({
    status: "initial",
  });
  const [form] = Form.useForm<IFormValue>();

  const [folders, setFolders] = React.useState<
    AsyncData<{ allReportFolders: IReportFolder[]; myFolder?: IReportFolder }>
  >({ status: "initial" });

  React.useEffect(() => {
    if (!reportCreationVisible) {
      form.resetFields();
    }
  }, [reportCreationVisible]);

  React.useEffect(() => {
    async function fetchFolders() {
      setFolders({ status: "loading" });
      await GraphQLService(REPORT_FOLDER, {
        orgId: org.id,
        userId: props.user.id,
      })
        .then((r) => {
          setFolders({
            status: "success",
            data: {
              allReportFolders: r.allReportFolders,
              myFolder: r.myFolder[0],
            },
          });
        })
        .catch((r) => {
          setFolders({ status: "error", error: r });
        });
    }
    if (reportCreationVisible == true) {
      fetchFolders();
    }
  }, [org.id, reportCreationVisible]);

  const createReport = async (payload: any) => {
    setSavedReport({
      status: "loading",
    });

    try {
      const data = await GraphQLService<{ createReport: IReport }>(GQL, {
        data: payload,
      });
      track("Report Created", {
        type: payload.type,
      });
      history.push(
        routeDescriptor["reportEdit"].renderRoute({
          organizationSlug: org.slug,
          reportSlug: data.createReport.slug,
        })
      );
    } catch (err) {
      setSavedReport({
        status: "error",
        error: err,
      });
    }
  };

  const onClose = () => {
    setReportCreationVisible(!reportCreationVisible);
  };

  const onCreate = (values: IFormValue) => {
    return createReport({
      name: values.name,
      type: "DASHBOARD",
      folder: {
        connect: {
          id: folder ? folder.id : values.folderId,
        },
      },
      org: {
        connect: {
          id: org.id,
        },
      },
    });
  };

  const renderStep = () => {
    if (folders.status === "initial" || folders.status === "loading") {
      return (
        <Aligner>
          <Loading />
        </Aligner>
      );
    }

    if (folders.status === "error") {
      return <Aligner>An unexpected error happened</Aligner>;
    }

    const initialValues: IFormValue = {
      name: "",
      folderId: !!folder ? folder.id : folders[0]?.id,
    };

    return (
      <Form
        onFinish={onCreate}
        initialValues={initialValues}
        form={form}
        layout="vertical"
      >
        <Form.Item name="name" required={true} label="Report name">
          <Input placeholder="Report name" />
        </Form.Item>
        {!!folder ? undefined : (
          <Form.Item style={{ marginBottom: 0 }} shouldUpdate={true}>
            {() => {
              const style: React.CSSProperties = {
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              };
              const render = (f) => (
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div
                    style={{ width: 16, height: 24, marginRight: 6, ...style }}
                  >
                    <WlyFolderIcon
                      style={{ fontSize: 20, color: f.color ?? "#2599D4" }}
                    />
                  </div>
                  <span>{f.name}</span>
                </div>
              );

              return (
                <Form.Item
                  name={["folderId"]}
                  label="Choose your folder"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <TreeSelect
                    treeData={buildFolderTree(
                      folders.data.allReportFolders,
                      render,
                      folders.data.myFolder
                    )}
                  />
                </Form.Item>
              );
            }}
          </Form.Item>
        )}
      </Form>
    );
  };

  return (
    <>
      {children(onClose)}
      <Modal
        footer={null}
        closable={false}
        title={null}
        open={reportCreationVisible}
        centered={true}
        width={"50%"}
        className={`report-creation-modal whaly-modal-v2`}
        styles={{
          body: { padding: 0 },
        }}
        destroyOnClose={true}
        onCancel={onClose}
        maskClosable={true}
      >
        <div className="header">
          {overrideTitle ? overrideTitle : "Give your report a name"}
        </div>
        <div className="content">{renderStep()}</div>
        <div className="footer">
          <div className="left">
            <Button onClick={onClose} type="text">
              Cancel
            </Button>
          </div>
          <div className="right">
            <Button
              onClick={() => form.submit()}
              loading={savedReport.status === "loading"}
              type="primary"
            >
              Continue
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default compose<Props, IReportCreationModalProps>(
  WithOrg,
  withRouter
)(ReportCreationModal);
