import { CloseOutlined, EyeInvisibleOutlined } from "@ant-design/icons";
import { Button, Space } from "antd";
import { type IRecord } from "../../../record/domain";

interface IObjectToolbarSelectedRowsProps {
  selectedRecords: Array<IRecord>;
  setSelectedRecords: (selectedRecords: IRecord[]) => void;
}

export function ObjectToolbarSelectedRows(
  props: IObjectToolbarSelectedRowsProps
) {
  const { selectedRecords, setSelectedRecords } = props;

  return (
    <div>
      <Button
        size="small"
        type="text"
        icon={<EyeInvisibleOutlined />}
        style={
          selectedRecords?.length
            ? { background: "#DBE0FD", color: "#3f6ac4" }
            : undefined
        }
      >
        <Space>
          <span>
            Selected
            {selectedRecords?.length && <>: {selectedRecords?.length}</>}
          </span>
          {selectedRecords?.length > 0 && (
            <CloseOutlined
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setSelectedRecords([]);
              }}
            />
          )}
        </Space>
      </Button>
    </div>
  );
}
