import { type Filter } from "@cubejs-client/core";
import "ag-grid-enterprise";
import type { RouteComponentProps } from "react-router";
import { withRouter } from "react-router";
import { compose } from "../../../../../../components/compose/WlyCompose";
import { useDebouncedResizeObserver } from "../../../../../../components/hooks/useDebouncedResizeObserver";
import type { IObject, IObjectView } from "../../../../../../interfaces/object";
import { IObjectViewView } from "../../../../../../interfaces/object";
import type { InjectedOrgProps } from "../../../../../orgs/WithOrg";
import WithOrg from "../../../../../orgs/WithOrg";
import ObjectHeader from "../header/ObjectHeader";
import ObjectTableView from "./table-view/ObjectTableView";

interface IObjectPageContentProps {
  object: IObject;
  views: IObjectView[];
  activeView?: IObjectView;
  injectedFilters: Filter[];
  setActiveView: (slug: string | undefined) => void;
  referencedObjects: IObject[];
}

type Props = IObjectPageContentProps & InjectedOrgProps & RouteComponentProps;

function ObjectPageContent(props: Props) {
  const {
    object,
    views,
    activeView,
    setActiveView,
    injectedFilters,
    referencedObjects,
  } = props;
  const { ref, size } = useDebouncedResizeObserver();
  const renderContent = () => {
    if (!activeView || activeView?.type === IObjectViewView.table) {
      return (
        <ObjectTableView
          object={object}
          size={size}
          views={views}
          injectedFilters={injectedFilters}
          activeView={activeView}
          referencedObjects={referencedObjects}
        />
      );
    }

    return (
      <div
        style={{
          display: "flex",
          flex: 1,
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        Content type not supported
      </div>
    );
  };
  return (
    <div
      className="wly-object-page"
      ref={ref}
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        overflow: "hidden",
      }}
    >
      <ObjectHeader
        activeView={activeView}
        views={views}
        object={object}
        setActiveView={setActiveView}
      />
      {renderContent()}
    </div>
  );
}

export default compose<Props, IObjectPageContentProps>(
  WithOrg,
  withRouter
)(ObjectPageContent);
