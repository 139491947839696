import { Button, Dropdown } from "antd";
import type { RouteComponentProps } from "react-router";
import { withRouter } from "react-router";
import { compose } from "../../../../../components/compose/WlyCompose";
import { WlyPlusFilledIcon } from "../../../../../components/icons/custom-icons/WlyPlusFilledIcon";
import { routeDescriptor } from "../../../../../routes/routes";
import ReportCreationModal from "../../../../reports/creation/ReportCreationModal";
import UserInviteCreationModal from "../../../../users/invite/UserInviteCreationModal";

interface ICreateButtonProps {}

type Props = RouteComponentProps & ICreateButtonProps;

function CreateButton(props: Props) {
  const {
    history,
    match: { params },
  } = props;

  return (
    <ReportCreationModal>
      {(setCreateDashboardOpen) => {
        return (
          <UserInviteCreationModal>
            {(onClick) => {
              return (
                <Dropdown
                  trigger={["click"]}
                  menu={{
                    items: [
                      {
                        key: "chart",
                        label: "Create a Chart",
                        onClick: () =>
                          history.push(
                            routeDescriptor["explores"].renderRoute({
                              ...params,
                            })
                          ),
                      },
                      {
                        key: "dashboard",
                        label: "Create a Dashboard",
                        onClick: () => setCreateDashboardOpen(),
                      },
                      // {
                      //   key: "rootCause",
                      //   label: "Create a Root Cause Analysis",
                      //   onClick: () =>
                      //     history.push(
                      //       routeDescriptor["rootCause"].renderRoute({
                      //         ...params,
                      //       })
                      //     ),
                      // },
                      // {
                      //   key: "alert",
                      //   label: "Create an Alert",
                      // },
                      {
                        type: "divider",
                      },
                      {
                        key: "invite",
                        onClick: onClick,
                        label: "Invite Teammates",
                      },
                    ],
                  }}
                  arrow
                  placement="bottomLeft"
                >
                  <Button
                    className="display-none display-inline-lg"
                    shape="round"
                    icon={
                      <WlyPlusFilledIcon
                        style={{
                          color: "#FCAA76",
                          fontSize: 20,
                          position: "absolute",
                          left: 6,
                          top: 5,
                        }}
                      />
                    }
                  >
                    <span style={{ marginLeft: 12, marginRight: -4 }}>
                      Create
                    </span>
                  </Button>
                </Dropdown>
              );
            }}
          </UserInviteCreationModal>
        );
      }}
    </ReportCreationModal>
  );
}
export default compose<Props, ICreateButtonProps>(withRouter)(CreateButton);
