import { CloseOutlined, EditOutlined } from "@ant-design/icons";
import {
  Button,
  Form,
  Input,
  Modal,
  Select,
  Space,
  Typography,
  type FormInstance,
} from "antd";
import cuid from "cuid";
import React from "react";
import { type IObject } from "../../../../../../../../interfaces/object";
import { type IDataset } from "../../../../../../../../interfaces/sources";
import { ChartOptionCollapse } from "../../../../../../../chart-options/components/ChartOptionCollapse";
import ChartOptionLine from "../../../../../../../chart-options/components/ChartOptionLine";
import { getObjectColumns } from "../../../../../object/domain";
import { type IRecord } from "../../../../domain";
import { type IWidget } from "../../../domain";
import {
  convertDataSheetNameToMarkdocVariable,
  convertKeyToMarkdocVariable,
} from "../../markdown/domain";
import { MarkdocEditor } from "../../markdown/editor/MarkdocEditor";
import "../../markdown/editor/MarkdownModal.scss";
import type { IGeneratedTextConfig, IGeneratedTextDataSheet } from "../domain";
import CreateEditDatasheetModal from "./datasheet/CreateEditDatasheetModal";

export interface IGeneratedTextEditorProps {
  widget: IWidget;
  object: IObject;
  record: IRecord;
  conf: IGeneratedTextConfig;
  edit?: boolean;
  form: FormInstance;
  datasets: IDataset[];
}

export default function GeneratedTextEditor(props: IGeneratedTextEditorProps) {
  const { object, form, datasets } = props;
  const [openPromptModal, setPromptModalOpen] = React.useState<boolean>(false);
  const [dataSheetModalOpen, setDataSheetModalOpen] = React.useState<
    | {
        type: "edit";
        name: string | number;
      }
    | {
        type: "create";
      }
    | null
  >(null);
  const columns = getObjectColumns(object);

  return (
    <>
      <ChartOptionCollapse dark title="Data sheets">
        <Form.List name={["config", "dataSheets"]}>
          {(fields, { add, remove }) => {
            return (
              <Space
                size="middle"
                style={{ width: "100%" }}
                direction="vertical"
              >
                {fields.map(({ key, name, ...restField }) => {
                  const fullPath = ["config", "dataSheets", name];
                  const value = form.getFieldValue(fullPath);
                  const inputId = `config-dataSheets-${name}`;
                  return (
                    <div style={{ display: "flex" }} key={key}>
                      <Form.Item name={fullPath} hidden noStyle>
                        <Input id={inputId} />
                      </Form.Item>
                      <div style={{ flex: 1 }}>
                        {value?.name ? (
                          <Typography.Text ellipsis>
                            {value.name}
                          </Typography.Text>
                        ) : (
                          <Typography.Text italic ellipsis>
                            Untitled
                          </Typography.Text>
                        )}
                      </div>
                      <Space style={{ flex: `0 auto` }}>
                        <Button
                          size="small"
                          shape="circle"
                          type="text"
                          icon={<EditOutlined />}
                          onClick={() =>
                            setDataSheetModalOpen({
                              type: "edit",
                              name: name,
                            })
                          }
                        />
                        <Button
                          size="small"
                          shape="circle"
                          type="text"
                          danger
                          onClick={() => remove(name)}
                          icon={<CloseOutlined />}
                        />
                      </Space>
                      <Form.Item noStyle shouldUpdate>
                        {() => {
                          return (
                            <CreateEditDatasheetModal
                              open={
                                dataSheetModalOpen?.type === "edit" &&
                                dataSheetModalOpen.name === name
                              }
                              initialValue={value}
                              onCancel={() => {
                                setDataSheetModalOpen(null);
                              }}
                              object={object}
                              datasets={datasets}
                              onSave={(v) => {
                                form.setFieldValue([...fullPath], v);
                                // we need to do this on trigger onValuesChang on form
                                // https://github.com/ant-design/ant-design/issues/23782#issuecomment-1770840035
                                var input = document.getElementById(inputId);
                                if (
                                  input &&
                                  Object &&
                                  window.HTMLInputElement.prototype
                                ) {
                                  const nativeInputValueSetter =
                                    Object.getOwnPropertyDescriptor(
                                      window.HTMLInputElement.prototype,
                                      "value"
                                    )?.set;

                                  nativeInputValueSetter?.call(input, value);

                                  // dispatch change event
                                  var inputEvent = new Event("change", {
                                    bubbles: true,
                                  });
                                  input.dispatchEvent(inputEvent);
                                }

                                setDataSheetModalOpen(null);
                              }}
                            />
                          );
                        }}
                      </Form.Item>
                    </div>
                  );
                })}
                <Button
                  onClick={() => {
                    setDataSheetModalOpen({ type: "create" });
                  }}
                  size="small"
                  block
                >
                  Add a data sheet
                </Button>

                <CreateEditDatasheetModal
                  open={dataSheetModalOpen?.type === "create"}
                  initialValue={{
                    id: cuid(),
                    type: "MODEL",
                    name: "",
                    select: [],
                    from: "",
                    additionalFilters: { operator: "and", filters: [] },
                    limit: { type: "value", value: "" },
                  }}
                  onCancel={() => {
                    setDataSheetModalOpen(null);
                  }}
                  object={object}
                  datasets={datasets}
                  onSave={(v) => {
                    setDataSheetModalOpen(null);
                    add(v);
                  }}
                />
              </Space>
            );
          }}
        </Form.List>
      </ChartOptionCollapse>
      <ChartOptionCollapse dark title="AI Generated Text">
        <Space size="middle" style={{ width: "100%" }} direction="vertical">
          <ChartOptionLine
            items={[
              {
                content: <div>Model</div>,
                flex: 0,
              },
              {
                content: (
                  <Form.Item noStyle name={["config", "model"]}>
                    <Select style={{ width: "100%" }}>
                      <Select.Option key="gpt-4o-2024-05-13">
                        gpt-4o-2024-05-13
                      </Select.Option>
                      <Select.Option key="gpt-4">gpt-4</Select.Option>
                      <Select.Option key="gpt-3.5-turbo">
                        gpt-3.5-turbo
                      </Select.Option>
                    </Select>
                  </Form.Item>
                ),
                flex: 1,
              },
            ]}
          />
          <ChartOptionLine
            items={[
              {
                content: <div>Prompt</div>,
                flex: 1,
              },
              {
                content: (
                  <>
                    <Form.Item noStyle hidden name={["config", "prompt"]} />
                    <Button
                      onClick={() => setPromptModalOpen(true)}
                      size="small"
                      icon={<EditOutlined />}
                    >
                      Edit
                    </Button>
                    <Modal
                      open={openPromptModal}
                      onCancel={() => setPromptModalOpen(false)}
                      destroyOnClose
                      maskClosable={false}
                      className="markdown-modal"
                      title={"Edit prompt"}
                      width={"50%"}
                      okText={"Save"}
                      onOk={() => {
                        setPromptModalOpen(false);
                      }}
                    >
                      <Form.Item noStyle shouldUpdate>
                        {() => {
                          const datasheets = form.getFieldValue([
                            "config",
                            "dataSheets",
                          ]) as IGeneratedTextDataSheet[];
                          return (
                            <Form.Item noStyle name={["config", "prompt"]}>
                              <MarkdocEditor
                                columnsSuggestions={(columns || []).map(
                                  (c) => ({
                                    key: `$${convertKeyToMarkdocVariable(
                                      c.data.key
                                    )}`,
                                    label: c.data.label,
                                  })
                                )}
                                datasheetsSuggestions={(datasheets || []).map(
                                  (d) => ({
                                    key: `$${convertDataSheetNameToMarkdocVariable(
                                      d.id
                                    )}`,
                                    label: d.name,
                                  })
                                )}
                              />
                            </Form.Item>
                          );
                        }}
                      </Form.Item>
                    </Modal>
                  </>
                ),
                flex: `0 auto`,
              },
            ]}
          />
        </Space>
      </ChartOptionCollapse>
    </>
  );
}
