import { message, Modal, notification } from "antd";
import type { MessageInstance } from "antd/es/message/interface";
import type { ModalStaticFunctions } from "antd/es/modal/confirm";
import type { NotificationInstance } from "antd/es/notification/interface";
import type { Context } from "react";
import React from "react";
import { generateUniqueId } from "../../utils/uniqueId";

// Ant useMessage / useModal needs to be fed the context from a parent config provider in order to work and get the correct theme variables
// When using this context provider, message displayed from component just before unmounting them will on the user screen

export type IAntUtilsContext = {
  message: MessageInstance;
  modal: Omit<ModalStaticFunctions, "warn">;
  notifications: NotificationInstance;
  children?: React.ReactNode;
};

export const AntUtilsContext: Context<IAntUtilsContext> = React.createContext({
  notifications: null,
  message: null,
  modal: null,
});

export const AntUtilsProvider: React.FC<{ children?: React.ReactNode }> = ({
  children,
}: any) => {
  const [messageApi, messageContextHolder] = message.useMessage();
  const [modal, modalContextHolder] = Modal.useModal();
  const [notifications, notificationContextHolder] =
    notification.useNotification();

  return (
    <AntUtilsContext.Provider
      key={generateUniqueId()}
      value={{
        message: messageApi,
        modal: modal,
        notifications: notifications,
      }}
    >
      {children}
      {/* We clone the context providers in order to avoid key duplicates */}
      {React.cloneElement(messageContextHolder, {
        key: Math.random(),
      })}
      {React.cloneElement(modalContextHolder, {
        key: Math.random(),
      })}
      {React.cloneElement(notificationContextHolder, {
        key: Math.random(),
      })}
    </AntUtilsContext.Provider>
  );
};
