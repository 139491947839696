import { DeleteOutlined } from "@ant-design/icons";
import type { ButtonProps } from "antd";
import { Button } from "antd";
import cuid from "cuid";
import * as React from "react";
import type { RouteComponentProps } from "react-router";
import { withRouter } from "react-router";
import type { InjectedAntUtilsProps } from "../../../../../components/ant-utils/withAntUtils";
import { withAntUtils } from "../../../../../components/ant-utils/withAntUtils";
import { compose } from "../../../../../components/compose/WlyCompose";
import type { EditTooltipItem } from "../../../../../components/report/tooltip/EditTooltip";
import { EditTooltip } from "../../../../../components/report/tooltip/EditTooltip";
import type { ITile } from "../../../../../interfaces/reports";
import "./NavigationComponent.scss";

interface INavigationComponentProps {
  tile: ITile;
  editing: boolean;
  selected: boolean;
  disabled: boolean;
  onDelete: (id: string) => Promise<void>;
}

type Props = INavigationComponentProps &
  RouteComponentProps<{}> &
  InjectedAntUtilsProps;

function NavigationComponent(props: Props) {
  const { antUtils, tile, history, onDelete, editing, selected, disabled } =
    props;

  const [id] = React.useState(cuid());

  let content: ButtonProps = {};
  try {
    content = JSON.parse(tile.content);
  } catch (err) {
    content = {
      type: "default",
    };
  }
  const { href, target, ...rest } = content;

  const editionItems: Array<EditTooltipItem> = [];

  if (onDelete) {
    editionItems.push({
      key: "delete",
      name: "Delete",
      props: {
        danger: true,
        icon: <DeleteOutlined />,
        onClick: () =>
          antUtils.modal.confirm({
            title: "Do you want to continue?",
            content:
              "Removing a navigation item cannot be undone, are you sure you want to proceed?",
            onOk: () => onDelete(tile.id),
            okType: "danger",
            okText: "Proceed",
            cancelText: "Cancel",
            onCancel: () => undefined,
          }),
      },
    });
  }

  if (href) {
    return (
      <>
        {onDelete && editing && selected ? (
          <EditTooltip
            additionalStyle={{
              position: "absolute",
              top: -42,
              right: -2,
            }}
            items={editionItems}
          />
        ) : null}
        <Button
          onClick={() => {
            if (!href.startsWith("http") && !target) {
              history.push(href);
            } else {
              const win = window.open(href, target ? "_blank" : undefined);
              win.focus();
            }
          }}
          type="default"
          disabled={disabled}
          {...rest}
        >
          {tile.name}
        </Button>
      </>
    );
  }

  return (
    <>
      {onDelete && editing && selected ? (
        <EditTooltip
          additionalStyle={{
            position: "absolute",
            top: -42,
            right: -2,
          }}
          items={editionItems}
        />
      ) : null}

      <Button type="default" disabled={disabled} {...content}>
        {tile.name}
      </Button>
    </>
  );
}

export default compose<Props, INavigationComponentProps>(
  withRouter,
  withAntUtils
)(NavigationComponent);
