import * as React from "react";
import type { IPalette } from "../utils/paletteData";
import ContinueEditor from "./continue/ContinueEditor";
import DiscreteEditor from "./discrete/DiscreteEditor";

interface IPaletteEditorProps {
  value: IPalette;
  onPaletteChange?: (v: IPalette) => void;
}

export default class PaletteEditor extends React.Component<IPaletteEditorProps> {
  public renderDiscreteValue = () => {
    const { value, onPaletteChange } = this.props;

    return (
      <DiscreteEditor
        value={value}
        onChange={(p) => {
          if (onPaletteChange) {
            onPaletteChange(p);
          }
        }}
      />
    );
  };

  public renderContinueValue = () => {
    const { value, onPaletteChange } = this.props;
    return (
      <ContinueEditor
        value={value}
        onChange={(p) => {
          if (onPaletteChange) {
            onPaletteChange(p);
          }
        }}
      />
    );
  };

  public render() {
    const { value } = this.props;

    if (value.type === "discrete") {
      return this.renderDiscreteValue();
    } else if (value.type === "continue") {
      return this.renderContinueValue();
    }
  }
}
