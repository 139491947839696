import { Layout } from "antd";
import * as React from "react";
import type { RouteComponentProps } from "react-router-dom";
import { Link, withRouter } from "react-router-dom";
import Logo from "../../../assets/icon.svg";
import { routeDescriptor } from "../../../routes/routes";
import { compose } from "../../compose/WlyCompose";
import "./MainHeader.scss";

const { Header } = Layout;

interface IMainHeaderProps {
  breadcrumb: React.ReactNode;
  userInfo: React.ReactNode;
  showLogo?: boolean;
}

type Props = IMainHeaderProps &
  RouteComponentProps<{ organizationSlug?: string }>;

class MainHeader extends React.Component<Props> {
  public render() {
    const {
      match: {
        params: { organizationSlug },
      },
    } = this.props;
    return (
      <Header className="main-header">
        <div className="left">
          {this.props.showLogo ? (
            <div className="logo">
              <Link
                to={
                  organizationSlug
                    ? routeDescriptor.home.renderRoute({ organizationSlug })
                    : routeDescriptor.organisations.renderRoute({})
                }
              >
                <img src={Logo} alt="Whaly Logo" />
              </Link>
            </div>
          ) : undefined}
          <div className="breadcrumb">{this.props.breadcrumb}</div>
        </div>
        <div className="right">{this.props.userInfo}</div>
      </Header>
    );
  }
}

export default compose<Props, IMainHeaderProps>(withRouter)(MainHeader);
