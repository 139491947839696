import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, ColorPicker, Flex, Form, Input, Typography } from "antd";
import type { FormInstance } from "antd/lib";
import {
  colorPresets,
  defaultColor,
  getColorAtIndex,
  normalizeColorPickerValue,
  renderColorPanel,
} from "./domain";

interface IPillFormatterProps {
  form: FormInstance;
}

export function PillFormatter(props: IPillFormatterProps) {
  const { form } = props;
  return (
    <>
      <Form.Item
        name={["formatterConfig", "default"]}
        label="Default color"
        initialValue={defaultColor}
      >
        <ColorPicker
          format="hex"
          disabledAlpha
          showText={(color) => "Default color"}
          presets={colorPresets}
          panelRender={renderColorPanel}
        />
      </Form.Item>
      <Typography.Text style={{ marginBottom: 8 }}>
        Custom colors
      </Typography.Text>
      <div style={{ height: 8 }} />
      <Form.List name={["formatterConfig", "values"]}>
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, ...restField }) => (
              <Flex
                key={key}
                gap={8}
                style={{
                  height: 45,
                }}
              >
                <Form.Item
                  {...restField}
                  normalize={normalizeColorPickerValue}
                  name={[name, "color"]}
                >
                  <ColorPicker
                    format="hex"
                    disabledAlpha
                    presets={colorPresets}
                    panelRender={renderColorPanel}
                  />
                </Form.Item>
                <Flex flex={1}>
                  <Form.Item
                    {...restField}
                    style={{ width: "100%" }}
                    name={[name, "value"]}
                  >
                    <Input placeholder="Value" style={{ width: "100%" }} />
                  </Form.Item>
                </Flex>
                <Form.Item>
                  <Button
                    icon={<DeleteOutlined />}
                    onClick={() => remove(name)}
                  />
                </Form.Item>
              </Flex>
            ))}
            <Form.Item>
              <Button
                type="dashed"
                onClick={() =>
                  add({
                    value: "Change me",
                    color: getColorAtIndex(
                      form.getFieldValue("formatterConfig")?.values?.length ?? 0
                    ),
                  })
                }
                block
                icon={<PlusOutlined />}
              >
                Add field
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>
    </>
  );
}
