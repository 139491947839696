import { CloseOutlined } from "@ant-design/icons";
import { Button, Dropdown } from "antd";
import type { DropdownProps } from "antd/lib";
import * as React from "react";
import "./Toolbar.scss";

interface ToolbarSeparatorProps {
  children?: React.ReactNode;
}

export class Separator extends React.Component<ToolbarSeparatorProps> {
  public render() {
    return (
      <div className="tool-bar-separator">
        <div className="tool-bar-separator-inner" />
      </div>
    );
  }
}

type colors = "green" | "violet" | "pink" | "yellow" | "green";
type types = "success" | "warning";

interface ToolbarItemProps {
  disabled?: boolean;
  color?: colors;
  type?: types;
  active?: boolean;
  dropdownProps?: DropdownProps;
  onClick?: () => void;
  children?: React.ReactNode;
}

export class Item extends React.Component<ToolbarItemProps> {
  public render() {
    const { color, type, disabled, active, onClick, ...props } = this.props;
    return (
      <div
        {...props}
        onClick={onClick}
        className={`tool-bar-element ${color ? color : ""} ${
          type ? `type-${type}` : ""
        } ${active ? "active" : ""}`}
        {...(!disabled ? {} : { "aria-disabled": true })}
      >
        {this.props.dropdownProps ? (
          <Dropdown {...this.props.dropdownProps}>
            <div>{this.props.children}</div>
          </Dropdown>
        ) : (
          this.props.children
        )}
      </div>
    );
  }
}

interface IViewName {
  children?: React.ReactNode;
}

export class ViewName extends React.Component<IViewName> {
  public render() {
    return <div className="tool-bar-view-name">{this.props.children}</div>;
  }
}

interface IToolbarProps {
  rounded?: boolean;
  align?: "right" | "center" | "left";
  style?: React.CSSProperties;
  onClose?: () => void;
  children?: React.ReactNode;
}

export class Toolbar extends React.Component<IToolbarProps> {
  public render() {
    const { style, rounded, align, onClose } = this.props;
    return (
      <div
        className={`tool-bar ${rounded ? "rounded" : ""} ${
          align === "right" ? "right" : ""
        }`}
        style={style}
      >
        <div style={{ justifyContent: align }} className="tool-bar-inner">
          {this.props.children}
        </div>
        {onClose ? (
          <div>
            <Button
              onClick={onClose}
              type="text"
              shape="circle"
              icon={<CloseOutlined />}
            />
          </div>
        ) : null}
      </div>
    );
  }
}
