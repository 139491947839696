import {
  Button,
  Divider,
  Empty,
  Flex,
  Form,
  Input,
  Select,
  Typography,
} from "antd";
import { useForm } from "antd/es/form/Form";
import _ from "lodash";
import React from "react";
import { Draggable } from "react-beautiful-dnd";
import type { InjectedAntUtilsProps } from "../../../../../../../../components/ant-utils/withAntUtils";
import { withAntUtils } from "../../../../../../../../components/ant-utils/withAntUtils";
import { compose } from "../../../../../../../../components/compose/WlyCompose";
import { SimpleFormDrawer } from "../../../../../../../../components/form/drawer/SimpleFormDrawer";
import Feednack from "../../../../../../../../components/layout/feedback/feedback";
import type {
  IObject,
  IObjectQueryBuilderSectionItem,
} from "../../../../../../../../interfaces/object";
import GraphQLService from "../../../../../../../../services/graphql/GraphQLService";
import WithOrg, {
  type InjectedOrgProps,
} from "../../../../../../../orgs/WithOrg";
import { generateFakeId, isFakeId } from "../../../../../exploration/domain";
import { ObjectQueryBuilderSectionItemCard } from "../cards/ObjectQueryBuilderSectionItemCard";
import { DNDWrapper } from "../dnd/DNDWrapper";
import type { CreateEditQueryBuilderSectionFormData } from "./domain";
import ObjectSectionItemUpdateDrawer from "./ObjectSectionItemUpdateDrawer";

export interface IObjectQueryBuilderUpdateDrawerProps {
  open: boolean;
  initialValues?: Partial<CreateEditQueryBuilderSectionFormData>;
  onCancel: () => void;
  onSave: (id: string, name: string) => any;
  object: IObject;
}

interface ExtendedInitialValues
  extends Partial<CreateEditQueryBuilderSectionFormData> {
  id: string;
  items: IObjectQueryBuilderSectionItem[];
}

type Props = IObjectQueryBuilderUpdateDrawerProps &
  InjectedOrgProps &
  InjectedAntUtilsProps;

function ObjectQueryBuilderUpdateDrawer(props: Props) {
  const { open, onCancel, onSave, initialValues, object, org, antUtils } =
    props;

  const [isSaving, setIsSaving] = React.useState<boolean>(false);
  const [form] = useForm<ExtendedInitialValues>();

  const [editedSectionItem, setEditedSectionItem] = React.useState<
    | { type: "update"; data: IObjectQueryBuilderSectionItem }
    | { type: "create" }
    | { type: "hide" }
  >({ type: "hide" });

  const renderInner = () => {
    if (!initialValues || !initialValues.id) {
      return (
        <Feednack>
          <div>Please select a section to edit</div>
        </Feednack>
      );
    }

    return (
      <>
        <Form.Item
          rules={[{ required: true }]}
          required
          name={["name"]}
          label="Name"
        >
          <Input />
        </Form.Item>
        <Form.Item
          rules={[{ required: true }]}
          required
          name={["type"]}
          label="Section type"
        >
          <Select disabled={!!iv?.id}>
            <Select.Option value="OWN_PROPERTIES">
              Filter object own properties
            </Select.Option>
            <Select.Option value="FOREIGN_PROPERTIES">
              Filter object foreign properties
            </Select.Option>
          </Select>
        </Form.Item>
        {iv?.id && (
          <>
            <Divider />
            <Form.List name="items">
              {(d, s) => {
                const renderInner = () => {
                  if (d.length === 0) {
                    return (
                      <div style={{ paddingTop: 12 }}>
                        <Empty description={"No associated section items"} />
                      </div>
                    );
                  }

                  return (
                    <DNDWrapper<IObjectQueryBuilderSectionItem>
                      droppableId="OBJECT_QUERY_BUILDER_SECTION_ITEM"
                      droppableType="OBJECT_QUERY_BUILDER_SECTION_ITEM"
                      items={form.getFieldValue("items")}
                      onReorder={(results, from, to) => {
                        s.move(from, to);
                      }}
                    >
                      {d.map((a, i) => {
                        const d = form.getFieldValue(["items", a.name]);
                        return (
                          <Draggable draggableId={d.id} index={i} key={a.key}>
                            {(provided) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                              >
                                <div style={{ padding: `3px 0` }}>
                                  <ObjectQueryBuilderSectionItemCard
                                    sectionItem={d}
                                    object={object}
                                    sectionType={initialValues.type!}
                                    onEdit={() =>
                                      setEditedSectionItem({
                                        type: "update",
                                        data: d,
                                      })
                                    }
                                    onRemove={() => s.remove(i)}
                                    dragHandleProps={provided.dragHandleProps}
                                  />
                                </div>
                              </div>
                            )}
                          </Draggable>
                        );
                      })}
                    </DNDWrapper>
                  );
                };

                return (
                  <div>
                    <Flex>
                      <div style={{ flex: 1 }}>
                        <Typography.Text strong>Section Items</Typography.Text>
                      </div>
                      <div>
                        <Button
                          onClick={() =>
                            setEditedSectionItem({ type: "create" })
                          }
                          size="small"
                        >
                          Add
                        </Button>
                      </div>
                    </Flex>
                    {renderInner()}
                  </div>
                );
              }}
            </Form.List>
          </>
        )}
        <ObjectSectionItemUpdateDrawer
          open={
            editedSectionItem.type === "create" ||
            editedSectionItem.type === "update"
          }
          sectionType={iv.type!}
          initialValues={
            editedSectionItem.type === "update"
              ? editedSectionItem.data
              : ({ id: generateFakeId() } as any)
          }
          onCancel={() => setEditedSectionItem({ type: "hide" })}
          onSave={(v) => {
            const allItems = form.getFieldValue("items");
            if (editedSectionItem.type === "update") {
              const newItems = allItems.map((ai) => {
                if (ai.id === v.id) {
                  return v;
                }
                return ai;
              });
              form.setFieldValue("items", newItems);
            } else if (editedSectionItem.type === "create") {
              const newItems = [...allItems, { ...v, order: allItems.length }];
              form.setFieldValue("items", newItems);
            }
            setEditedSectionItem({ type: "hide" });
          }}
          object={object}
        />
      </>
    );
  };

  const iv: ExtendedInitialValues = {
    ...initialValues,
    id: initialValues?.id!,
    items: _.sortBy((initialValues as any)?.items || [], ["order"]),
  };

  return (
    <SimpleFormDrawer
      form={form}
      open={open}
      onCancel={onCancel}
      isSaving={isSaving}
      drawerProps={{
        title: initialValues?.id ? "Edit section" : "Create a section",
        width: 640,
      }}
      disableEnterKey={true}
    >
      <Form
        form={form}
        preserve={false}
        layout="vertical"
        initialValues={iv}
        onFinish={async (values) => {
          try {
            setIsSaving(true);
            const d = await form.validateFields();
            const items = values.items.map((item, i) => ({
              ...item,
              order: i,
            }));
            const initialIds = iv.items.map((i) => i.id);
            const finalIds = items.map((i) => i.id).filter((v) => !isFakeId(v));
            const toCreate = items.filter((i) => isFakeId(i.id));
            const toUpdate = items.filter((i) => !isFakeId(i.id));
            const toDelete = initialIds.filter((i) => !finalIds.includes(i));

            await GraphQLService(
              `mutation updateAllSectionItems(
  $sectionsToCreate: [ObjectQueryBuilderSectionItemsCreateInput]!
  $sectionsToUpdate: [ObjectQueryBuilderSectionItemsUpdateInput]!
) {
 	createObjectQueryBuilderSectionItems(data: $sectionsToCreate) {id}
  updateObjectQueryBuilderSectionItems(data: $sectionsToUpdate) {id}
}`,
              {
                sectionsToCreate: toCreate.map((tc) => {
                  const { id, ...rest } = tc;

                  return {
                    data: {
                      ...rest,
                      org: {
                        connect: {
                          id: org.id,
                        },
                      },
                      section: {
                        connect: {
                          id: iv.id,
                        },
                      },
                    },
                  };
                }),
                sectionsToUpdate: [
                  ...toUpdate.map((tu) => {
                    const { id, ...rest } = tu;
                    delete rest["__typename"];
                    return {
                      id: tu.id,
                      data: {
                        ...rest,
                      },
                    };
                  }),
                  ...toDelete.map((td) => {
                    return {
                      id: td,
                      data: {
                        isDeleted: true,
                      },
                    };
                  }),
                ],
              }
            );

            const { items: a, ...rest } = d;
            await onSave(iv.id, rest.name!);
            setIsSaving(false);
          } catch (err) {
            console.error(err);
            antUtils.message.error("There was an error saving your section");
            setIsSaving(false);
          }
        }}
      >
        {renderInner()}
      </Form>
    </SimpleFormDrawer>
  );
}
export default compose<Props, IObjectQueryBuilderUpdateDrawerProps>(
  WithOrg,
  withAntUtils
)(ObjectQueryBuilderUpdateDrawer);
