import authStore from "../store/authStore";
import StorageService from "./StorageService";

export const encodePageLocation = (page: string): string => {
  // todo generate random value
  return `whaly-request-${btoa(page)}`;
};

export const decodePageLocation = (hash: string): string => {
  return atob(hash.replace("whaly-request-", ""));
};

export const hasNeverBeenAuthenticated = () => {
  return !!(window as any).authentication;
};

const isAuthenticated = () => {
  return (
    !!(window as any).authentication &&
    (window as any).authentication.expires_at > Date.now()
  );
};

export const isPartnerPortal = () => {
  const domain = window.location.hostname;
  return (
    domain.includes(".portals") || window.WHALY_CONSTANTS.OVERRIDE_PORTAL_URL
  );
};

export const getAccessToken = (): string | undefined => {
  const overrideToken = StorageService.getItem("_wly_override_token");
  if (overrideToken) {
    return overrideToken;
  }
  return window.authentication ? window.authentication.access_token : undefined;
};

export const getAuthorizationType = () => {
  const overrideToken = StorageService.getItem("_wly_override_token");
  if (overrideToken) {
    return "Bearer";
  }
  if (window.authentication && window.authentication.token_type === "bearer") {
    return "Bearer";
  } else if (
    window.authentication &&
    window.authentication.token_type === "portal-jwt"
  ) {
    return "PortalJwt";
  }
};

export const authenticatedRequest = (): Promise<void> => {
  const auth = isAuthenticated();
  if (
    !auth &&
    window.authentication &&
    window.authentication.expires_at < Date.now()
  ) {
    return authStore.refreshSession() as Promise<any>;
  } else {
    return Promise.resolve();
  }
};
