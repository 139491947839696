import { MoreOutlined } from "@ant-design/icons";
import Editor from "@monaco-editor/react";
import type { MenuProps } from "antd";
import { Button, Dropdown, Modal } from "antd";
import * as React from "react";
import type { RouteComponentProps } from "react-router";
import { withRouter } from "react-router";
import { ExplorationPanel } from "../../containers/exploration/single/ExplorationPanel";
import type { InjectedOrgProps } from "../../containers/orgs/WithOrg";
import WithOrg from "../../containers/orgs/WithOrg";
import type {
  BasicExplorationTemplate,
  BasicTableTemplate,
} from "../../containers/templates/executor/domain";
import { hasRoleAccessBoolean } from "../../containers/user-settings/HasRoleAccess";
import type { IExploration } from "../../interfaces/explorations";
import type { IStandardDimension, ITable } from "../../interfaces/table";
import { IUserRoleType } from "../../interfaces/user";
import { routeDescriptor } from "../../routes/routes";
import { encodeUrlParam } from "../../store/workbenchUIStore";
import { compose } from "../compose/WlyCompose";
import MeasureTree from "./measure-tree/MeasureTree";

interface IMeasuresProps {
  exploration: IExploration;
  isEmbeded: boolean;
  renderRoute?: (params: object, query?: object | undefined) => string;
}

interface IState {
  templateDefinitionOpen: boolean;
}

type Props = InjectedOrgProps & IMeasuresProps & RouteComponentProps<{}>;

class Measures extends React.Component<Props, IState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      templateDefinitionOpen: false,
    };
  }

  computeTemplateValue = (): BasicExplorationTemplate => {
    const { exploration } = this.props;

    const renderComputedColumn = (columnName: string) => {
      let newColumnName = columnName;
      exploration.tables.flatMap((t) =>
        t.metrics
          .map((m) => ({
            id: `${t.cubeName}.${m.cubeName}`,
            name: m.overrideName ? m.overrideName : m.name,
          }))
          .forEach((m) => {
            newColumnName = newColumnName.replaceAll(m.id, `{{${m.name}}}`);
          })
      );
      return newColumnName;
    };

    const generateTableTemplate = (table: ITable): BasicTableTemplate => {
      return {
        table_name: table.view.dataset.warehouseTableId,
        relationships: table.outgoingRelationships.map((or) => {
          return {
            from: or.from,
            to: or.to,
            type: or.type,
            table: generateTableTemplate(
              exploration.tables.find((t) => t.id === or.right.id)!
            ),
          };
        }),
        dimensions: table.dimensions.map((d) => {
          return {
            type: d.type,
            columnName: (d as IStandardDimension).columnName,
            description: d.description,
            overrideName: d.name,
            columnDomain: d.type === "standard" ? d.columnDomain : null,
          };
        }),
        metrics: table.metrics.map((m) => {
          return {
            columnName:
              m.expression === "COMPUTED"
                ? renderComputedColumn(m.columnName)
                : m.columnName,
            description: m.description,
            expression: m.expression,
            filters: m.filters,
            format: m.format,
            prefix: m.prefix,
            suffix: m.suffix,
            overrideName: m.name,
          };
        }),
      };
    };

    return {
      name: exploration.name,
      description: exploration.description,
      table: generateTableTemplate(
        exploration.tables.find((t) => t.incomingRelationships.length === 0)!
      ),
    };
  };

  public replacer = (key, value) =>
    typeof value === "undefined" ? null : value;

  public render() {
    const {
      user,
      exploration,
      isEmbeded,
      org,
      match: { params },
    } = this.props;

    const menuItems: MenuProps["items"] = [];

    if (
      isEmbeded &&
      hasRoleAccessBoolean(IUserRoleType.BUILDER, user, org.id)
    ) {
      menuItems.push({
        key: "edit",
        onClick: () => {
          const computedUrlState = encodeUrlParam({
            type: "exploration",
            value: exploration.id,
          });
          window
            .open(
              routeDescriptor.workbench.renderRoute({
                ...params,
                tableSlug: computedUrlState,
                warehouseSlug: exploration.warehouse.slug,
              }),
              "_blank"
            )
            .focus();
        },
        label: "Edit exploration",
      });
    }

    if (user.isAdmin) {
      menuItems.push({
        key: "tp",
        onClick: () => this.setState({ templateDefinitionOpen: true }),
        label: "Get template definition",
      });
    }

    return (
      <ExplorationPanel
        title="Measures"
        actions={
          menuItems.length > 0 ? (
            <div className="actions">
              <Dropdown
                trigger={["click"]}
                menu={{
                  items: menuItems,
                }}
                placement="bottomRight"
              >
                <Button type="text" icon={<MoreOutlined />} />
              </Dropdown>
            </div>
          ) : null
        }
      >
        <MeasureTree tables={exploration.tables} />
        {this.state.templateDefinitionOpen && (
          <Modal
            title="Exploration configuration"
            open={this.state.templateDefinitionOpen}
            onOk={() => this.setState({ templateDefinitionOpen: false })}
            onCancel={() => this.setState({ templateDefinitionOpen: false })}
            width={"70%"}
            styles={{
              body: {
                height: 360,
              },
            }}
          >
            <Editor
              defaultLanguage="json"
              defaultValue={JSON.stringify(
                this.computeTemplateValue(),
                this.replacer,
                4
              )}
              options={{
                readOnly: true,
                minimap: {
                  enabled: false,
                },
                lineNumbers: "on",
              }}
            />
          </Modal>
        )}
      </ExplorationPanel>
    );
  }
}

export default compose<Props, IMeasuresProps>(WithOrg, withRouter)(Measures);
