import { Modal } from "antd";
import { inject, observer } from "mobx-react";
import { compose } from "../../../../../components/compose/WlyCompose";
import Feednack from "../../../../../components/layout/feedback/feedback";
import Loading from "../../../../../components/layout/feedback/loading";
import type { IObject } from "../../../../../interfaces/object";
import { IUserRoleType } from "../../../../../interfaces/user";
import type { WorkspaceDatatoreProps } from "../../../../../store/dataStores/workspace/workspaceDatastore";
import GraphQl from "../../../../graphql/graphql";
import type { InjectedOrgProps } from "../../../../orgs/WithOrg";
import WithOrg from "../../../../orgs/WithOrg";
import { hasRoleAccessBoolean } from "../../../../user-settings/HasRoleAccess";
import "../settingsModal.scss";
import ObjectPageModalInner from "./ObjectPageModalInner";

interface IObjectPageModalProps {
  open: boolean;
  onClose: () => void;
}

type Props = IObjectPageModalProps & InjectedOrgProps & WorkspaceDatatoreProps;

function ObjectPageModal(props: Props) {
  const { open, onClose, org, workspaceDatastore, user, role } = props;

  const canMoveObject = hasRoleAccessBoolean(
    IUserRoleType.BUILDER,
    user,
    org.id
  );

  return (
    <Modal
      maskClosable={false}
      closable={true}
      width={"50%"}
      centered
      className="settings-modal"
      destroyOnClose={true}
      styles={{
        body: {
          height: "calc(100% - 44px - 8px)",
          maxHeight: "calc(100% - 44px - 8px)",
          overflowX: "hidden",
          overflowY: "auto",
        },
      }}
      title={"Browse Lists"}
      onCancel={onClose}
      footer={null}
      open={open}
    >
      <GraphQl<{
        allObjects: Array<IObject>;
      }>
        query={`
        query allObjectListing($orgId: ID!) {
          allObjects(where: { org: { id: $orgId }, isDeleted_not: true }) {
            id
            slug
            color
            icon
            name
            position
            canBeListed
            canBeManagedByCurrentUser
            defaultView {
              id
              slug
              isDeleted
            }
          }
        }
        `}
        variables={{ orgId: org.id }}
      >
        {(data) => {
          if (workspaceDatastore.data.status !== "success") {
            return <Feednack>Please load the workspace first</Feednack>;
          }

          if (data.status === "initial" || data.status === "loading") {
            return (
              <Feednack>
                <Loading />
              </Feednack>
            );
          }

          if (data.status === "error") {
            return (
              <Feednack>
                <div>{data.error.message}</div>
              </Feednack>
            );
          }

          return (
            <ObjectPageModalInner
              canMoveObject={canMoveObject}
              org={org}
              data={data.data.allObjects}
            />
          );
        }}
      </GraphQl>
    </Modal>
  );
}

export default compose<Props, IObjectPageModalProps>(
  WithOrg,
  inject("workspaceDatastore"),
  observer
)(ObjectPageModal);
