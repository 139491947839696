import { Form, Input, Popover, Select, Typography } from "antd";
import cuid from "cuid";
import * as React from "react";
import { compose } from "../../../components/compose/WlyCompose";
import FormActions from "../../../components/form/actions/FormActions";
import Feednack from "../../../components/layout/feedback/feedback";
import Loading from "../../../components/layout/feedback/loading";
import { SourceImageRenderer } from "../../../components/sources/SourceImageRenderer";
import type { AsyncData } from "../../../helpers/typescriptHelpers";
import type { IDestination } from "../../../interfaces/destinations";
import type {
  IDataset,
  IDatasetRelationship,
} from "../../../interfaces/sources";
import type {
  SchemaResult,
  TableFromDatasetTableOperation,
  Transformation,
  WhalyExtTableRollupOperation,
} from "../../../interfaces/transformations";
import { computeTransformations } from "../../../services/BrizoService";
import GraphQLService from "../../../services/graphql/GraphQLService";
import { generateUniqueId } from "../../../utils/uniqueId";
import type { InjectedOrgProps } from "../../orgs/WithOrg";
import WithOrg from "../../orgs/WithOrg";
import type { ICreateComponentProps } from "../domain";
import {
  catchErrors,
  getPopUpContainer,
  replaceRemoveColumns,
  validateColumnName,
} from "../domain";

type CreateEditProps = ICreateComponentProps<{
  var: string;
  operation: WhalyExtTableRollupOperation;
  viewId?: string;
  domain: "datasetResolver";
}> & {
  currentDatasetId: string;
  currentWarehouse: IDestination;
};

type Props = CreateEditProps & InjectedOrgProps;

const id = cuid();

const { Text } = Typography;
const { Option } = Select;

const CreateEdit: React.FunctionComponent<Props> = (props) => {
  const {
    onSave,
    currentTransformation,
    transformations,
    onCancel,
    reports,
    org,
    isStale,
    currentDatasetId,
    columns,
    setFormInstance,
    currentWarehouse,
  } = props;

  const [form] = Form.useForm();
  const [submitting, setSubmitting] = React.useState<boolean>(false);
  const [schemas, setSchemas] = React.useState<
    AsyncData<{
      schema: { [key: string]: SchemaResult };
      relationships: IDatasetRelationship[];
    }>
  >({ status: "initial" });
  const [selectedRelationship, setSelectedRelationship] = React.useState<
    string | undefined
  >();
  const [selectedAggregation, setSelectedAggregation] = React.useState<
    string | undefined
  >(
    currentTransformation.operation.args.aggregationType
      ? currentTransformation.operation.args.aggregationType
      : undefined
  );

  if (setFormInstance) {
    setFormInstance(form);
  }
  // step 1 - list related dataset
  // step 2 - fetch dataset schema
  // step 3 - print form
  // step 4 - submit form

  React.useEffect(() => {
    async function fetchSchemas() {
      setSchemas({ status: "loading" });
      await GraphQLService(
        `
      query getDatasetRelationship($datasetId: ID!) {
        Dataset(where:{id: $datasetId}) {
          id
          rawQuery
          
          outgoingRelationships(where: { type: "1-N", deleted_not: true }) {
            id
            from
            to
            left {
              id
              name
              source {
                sourceMeta {
                  publicInfo {
                    logo
                  }
                }
              }
            }
            right {
              id
              name
              rawQuery
              source {
                sourceMeta {
                  publicInfo {
                    logo
                  }
                }
              }
              views(where: { deleted_not: true }) {
                id
                default
                name
              }
            }
          }
          incomingRelationships(where:{type: "N-1", deleted_not: true}) {
            id
            from
            to
            left {
              id
              name
              rawQuery
              source {
                sourceMeta {
                  publicInfo {
                    logo
                  }
                }
              }
              views(where: { deleted_not: true }) {
                id
                default
                name
              }
            }
            right {
              id
              name
              source {
                sourceMeta {
                  publicInfo {
                    logo
                  }
                }
              }
            }
          }
        }
      }
      `,
        {
          datasetId: currentDatasetId,
        }
      )
        .then((d) => {
          return d.Dataset;
        })
        .then((d: IDataset) => {
          const relationships = [
            ...d.outgoingRelationships,
            ...d.incomingRelationships.map((ir) => {
              return {
                id: ir.id,
                from: ir.to,
                to: ir.from,
                left: ir.right,
                right: ir.left,
              } as IDatasetRelationship;
            }),
          ];
          return relationships;
        })
        .then((r) => {
          return computeTransformations(
            currentWarehouse?.id,
            r.reduce(
              (acc, v, i) => {
                const query = JSON.parse(v.right.rawQuery);
                return {
                  ...acc,
                  [v.id]: replaceRemoveColumns([
                    ...query,
                    {
                      var: generateUniqueId(),
                      operation: {
                        type: "Table.Schema",
                        args: {
                          table: query[query.length - 1].var,
                        },
                      },
                    },
                  ]),
                };
              },
              {
                current: replaceRemoveColumns([
                  ...transformations,
                  {
                    var: generateUniqueId(),
                    operation: {
                      type: "Table.Schema",
                      args: {
                        table: transformations[transformations.length - 1].var,
                      },
                    },
                    domain: "viewResolver",
                  },
                ]),
              } as {}
            ),
            false,
            false
          ).then((resp) => ({
            relationships: r,
            schema: resp.data as any,
          }));
        })
        .then((r) => {
          setSchemas({ status: "success", data: r });
        })
        .catch((err) => {
          setSchemas({ status: "error", error: err });
        });
    }
    fetchSchemas();
  }, [currentDatasetId]);

  if (schemas.status === "loading" || schemas.status === "initial") {
    return <Loading />;
  }

  if (schemas.status === "error") {
    return <Feednack>{schemas.error?.message}</Feednack>;
  }

  const isEditing = transformations.find(
    (t) => t.var === currentTransformation.var
  );
  const foundAncestor = transformations.find(
    (t) => t.var === currentTransformation.operation.args.table2
  );
  const initialRelationship = schemas.data.relationships.find((r) => {
    if (foundAncestor) {
      return (
        r.right.id ===
          (foundAncestor.operation as TableFromDatasetTableOperation).args
            .datasetId &&
        r.from === currentTransformation.operation.args.key1 &&
        r.to === currentTransformation.operation.args.key2
      );
    }
    return false;
  });

  let baseTransformations = transformations;

  if (isEditing) {
    if (!initialRelationship) {
      return <div>Relationship not found</div>;
    }
    if (!selectedRelationship) {
      setSelectedRelationship(initialRelationship!.id);
    }
    if (foundAncestor) {
      baseTransformations = baseTransformations.filter(
        (t) =>
          t.var !== foundAncestor.var || t.var !== currentTransformation.var
      );
    }
  }

  const initialValues = initialRelationship
    ? {
        ...currentTransformation.operation.args,
        table2: initialRelationship.id,
        viewId: currentTransformation.viewId,
      }
    : currentTransformation.operation.args;

  return (
    <Form
      initialValues={initialValues}
      form={form}
      onFieldsChange={() => {
        isStale && isStale(true);
      }}
      className="form-dropdown-form"
      onFinish={async (v) => {
        try {
          setSubmitting(true);
          const relationship = schemas.data.relationships.find(
            (r) => r.id === selectedRelationship
          );

          if (relationship) {
            const id = generateUniqueId();
            const newTransformation: Transformation = {
              ...currentTransformation,
              operation: {
                ...currentTransformation.operation,
                args: {
                  ...currentTransformation.operation.args,
                  ...v,
                  key1: relationship.from,
                  key2: relationship.to,
                  table2: id,
                },
              },
            };

            if (isEditing) {
              const newTransformations = baseTransformations.flatMap((t) => {
                if (t.var === newTransformation.var) {
                  return [
                    {
                      var: id,
                      domain: "datasetResolver",
                      operation: {
                        type: "Table.FromWhalyDataset",
                        args: {
                          ...(v.viewId ? { viewId: v.viewId } : {}),
                          datasetId: relationship.right.id,
                        },
                      },
                    } as Transformation,
                    newTransformation,
                  ];
                }
                return t;
              });
              await onSave(newTransformations, undefined, v.newColumnName);
              if (onCancel) {
                onCancel();
              }

              setSubmitting(false);
            } else {
              await onSave(
                [
                  ...baseTransformations,
                  {
                    var: id,
                    domain: "datasetResolver",
                    operation: {
                      type: "Table.FromWhalyDataset",
                      args: {
                        ...(v.viewId ? { viewId: v.viewId } : {}),
                        datasetId: relationship.right.id,
                      },
                    },
                  },
                  newTransformation,
                ],
                undefined,
                v.newColumnName
              );
              if (onCancel) {
                onCancel();
              }
              setSubmitting(false);
            }
          }
        } catch (err) {
          console.error(err);
          catchErrors(err, id);
          setSubmitting(false);
        }
      }}
      layout="vertical"
    >
      <div className="form-dropdown-form-header">
        <Form.Item
          name={["newColumnName"]}
          label="Column Name"
          rules={[
            {
              required: true,
            },
            {
              validator: validateColumnName(
                Object.keys(schemas.data.schema.current || {}),
                currentTransformation.operation.args.newColumnName
              ),
            },
          ]}
        >
          <Input />
        </Form.Item>
      </div>
      <div className="form-dropdown-form-content" id={id}>
        <Form.Item
          name={["table2"]}
          label="Select the dataset your want to rollup"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select
            getPopupContainer={getPopUpContainer(id)}
            onChange={(v) => v && setSelectedRelationship(v.toString())}
            showSearch={true}
            optionFilterProp="label"
            popupMatchSelectWidth={false}
          >
            {schemas.data.relationships.map((r, i) => {
              const hasSeveralRelationshipsToSameDataset =
                schemas.data.relationships.filter(
                  (sr) => r.right.id === sr.right.id
                ).length > 1;
              const isInError =
                schemas.data.schema[r.id] === undefined ? true : false;
              let label = <Text disabled={isInError}>{r.right.name}</Text>;
              if (hasSeveralRelationshipsToSameDataset) {
                label = (
                  <Text disabled={isInError}>
                    {r.right.name}
                    <Text disabled={isInError} type="secondary">
                      {" "}
                      (where {r.to} = {r.from})
                    </Text>
                  </Text>
                );
              }
              return (
                <Option
                  value={r.id}
                  label={r.right.name}
                  disabled={isInError}
                  key={i}
                >
                  <div className="demo-option-label-item">
                    <span
                      role="img"
                      style={{ marginRight: 5, opacity: isInError ? 0.5 : 1 }}
                    >
                      <SourceImageRenderer
                        alt={"sourceName"}
                        className="source-table-selection-logo"
                        img={
                          !r.right.source
                            ? undefined
                            : r.right.source.sourceMeta.publicInfo.logo
                        }
                        size={16}
                        isModel={!r.right.source}
                      />
                    </span>
                    {label}
                    <div
                      style={{
                        display: isInError ? "inline-block" : "none",
                        marginLeft: 6,
                      }}
                    >
                      <Popover
                        title={<b>Dataset in error</b>}
                        content={
                          <div>
                            This dataset in currently in error and must be fixed
                            before you can use it in relationships.
                          </div>
                        }
                      >
                        <span>
                          <SourceImageRenderer
                            alt={"sourceName"}
                            className="source-table-selection-logo"
                            img={":warning:"}
                            size={16}
                          />
                        </span>
                      </Popover>
                    </div>
                  </div>
                </Option>
              );
            })}
          </Select>
        </Form.Item>

        <Form.Item style={{ marginBottom: 0 }}>
          <Form.Item
            name={["aggregationType"]}
            label="Aggregation Type"
            style={{ display: "inline-block", width: "calc(50% - 12px)" }}
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select
              getPopupContainer={getPopUpContainer(id)}
              onChange={(v) => v && setSelectedAggregation(v.toString())}
            >
              <Select.Option value={"COUNT"}>Count</Select.Option>
              <Select.Option value={"SUM"}>Sum</Select.Option>
              <Select.Option value={"MAX"}>Max</Select.Option>
              <Select.Option value={"MIN"}>Min</Select.Option>
              <Select.Option value={"AVG"}>Average</Select.Option>
              <Select.Option value={"FIRST_VALUE"}>First value</Select.Option>
              <Select.Option value={"FIRST_VALUE_IGNORE_NULLS"}>
                First non null value
              </Select.Option>
            </Select>
          </Form.Item>
          <span
            style={{
              display: "inline-block",
              width: "24px",
              lineHeight: "32px",
              textAlign: "center",
            }}
          />
          <Form.Item
            name={["aggregatedColumn"]}
            label="Aggregated Column"
            style={{
              display: "inline-block",
              width: "calc(50% - 12px)",
              paddingBottom: 0,
            }}
          >
            <Select
              getPopupContainer={getPopUpContainer(id)}
              disabled={!selectedAggregation || selectedAggregation === "COUNT"}
              showSearch={true}
              optionFilterProp="children"
              popupMatchSelectWidth={false}
            >
              {selectedAggregation &&
                selectedRelationship &&
                Object.keys(schemas.data.schema[selectedRelationship]).map(
                  (r) => {
                    return (
                      <Select.Option key={r} value={r}>
                        {schemas.data.schema[selectedRelationship][r].label
                          ? schemas.data.schema[selectedRelationship][r].label
                          : r}
                      </Select.Option>
                    );
                  }
                )}
            </Select>
          </Form.Item>
        </Form.Item>
        <Form.Item shouldUpdate={true}>
          {() => {
            const a = schemas.data.relationships.find(
              (r) => r.id === selectedRelationship
            );
            if (!a) {
              return undefined;
            }
            return (
              <span>
                <Form.Item name={["viewId"]} label={"View"}>
                  <Select
                    getPopupContainer={getPopUpContainer(id)}
                    showSearch={true}
                    optionFilterProp="children"
                    popupMatchSelectWidth={false}
                  >
                    {a.right.views.map((v) => {
                      return (
                        <Select.Option value={v.id} key={v.id}>
                          {v.name}
                        </Select.Option>
                      );
                    })}
                  </Select>
                </Form.Item>
                <Form.Item name={["orderedBy"]} label={"Ordered By"}>
                  <Select
                    getPopupContainer={getPopUpContainer(id)}
                    showSearch={true}
                    optionFilterProp="children"
                    popupMatchSelectWidth={false}
                  >
                    {selectedAggregation &&
                      selectedRelationship &&
                      Object.keys(
                        schemas.data.schema[selectedRelationship]
                      ).map((r) => {
                        return (
                          <Select.Option key={r} value={r}>
                            {schemas.data.schema[selectedRelationship][r].label
                              ? schemas.data.schema[selectedRelationship][r]
                                  .label
                              : r}
                          </Select.Option>
                        );
                      })}
                  </Select>
                </Form.Item>
                <Form.Item name={["order"]} label={"Order"}>
                  <Select
                    getPopupContainer={getPopUpContainer(id)}
                    showSearch={true}
                    optionFilterProp="children"
                    popupMatchSelectWidth={false}
                  >
                    <Select.Option value={"ASC"}>Ascendant</Select.Option>
                    <Select.Option value={"DESC"}>Descendant</Select.Option>
                  </Select>
                </Form.Item>
              </span>
            );
          }}
        </Form.Item>
      </div>
      {onCancel && (
        <div className="form-dropdown-form-buttons">
          <FormActions
            flex={true}
            size="small"
            onCancel={onCancel}
            isSubmitting={submitting}
          />
        </div>
      )}
    </Form>
  );
};

export default compose<Props, CreateEditProps>(WithOrg)(CreateEdit);
