import moment from "moment";

interface IDateCellRendererProps {
  value: string;
  timeAgg?: string;
}

const DateCellRenderer = (props: IDateCellRendererProps) => {
  const { value, timeAgg } = props;

  let formatted: string | React.ReactElement = value;

  try {
    if (timeAgg === "hour") {
      formatted = moment.utc(value).local().format("YYYY-MM-DD HH:mm");
    } else if (timeAgg === "day" || timeAgg === "week") {
      formatted = moment.utc(value).local().format("YYYY-MM-DD");
    } else if (timeAgg === "month") {
      formatted = moment.utc(value).local().format("YYYY-MM");
    } else if (timeAgg === "quarter") {
      formatted = moment.utc(value).local().format("\\QQ YYYY");
    } else if (timeAgg === "year") {
      formatted = moment.utc(value).local().format("YYYY");
    } else {
      const date = moment
        .utc(value)
        .local()
        .format("YYYY-MM-DD HH:mm:ss")
        .split(" ");
      formatted = (
        <>
          <span style={{ width: 94, display: "inline-block" }}>{date[0]}</span>
          <span>{date[1]}</span>
        </>
      );
    }
  } catch (error) {}

  if (formatted) {
    return <>{formatted}</>;
  } else {
    return null;
  }
};

export default DateCellRenderer;
