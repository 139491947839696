import type { IObject } from "../../../../../../interfaces/object";
import type { IOrg } from "../../../../../../interfaces/org";
import type { IUser } from "../../../../../../interfaces/user";
import type { IRecord } from "../../domain";
import type { ILayout } from "../domain";
import { EmailWidgetMjmlRenderer } from "../email-widgets/EmailWidgetMjmlRenderer";
import {
  getSortedCols,
  getSortedRows,
  getSortedWidgets,
} from "./RecordEmailDomain";

export const renderRecordEmailMJML = async (
  layout: ILayout,
  object: IObject,
  data: IRecord,
  org: IOrg,
  user: IUser
) => {
  const renderedWidgets = await Promise.all(
    layout.widgets.map(async (w) => {
      const rendered = await EmailWidgetMjmlRenderer(
        object,
        w,
        data,
        org,
        user
      );
      return {
        ...w,
        rendered,
      };
    })
  );

  const sortedRows = getSortedRows(layout.rows, object, {
    status: "success",
    data,
  });
  return Promise.all(
    sortedRows.flatMap((r) => {
      const sortedCols = getSortedCols(r.columns);
      return sortedCols.flatMap((c) => {
        const allWidgets = renderedWidgets.filter(
          (w) => w.parentColumn?.id === c.id
        );

        const sortedWidgets = getSortedWidgets(allWidgets, c, object, {
          status: "success",
          data,
        });

        return `
<mj-column width="${Math.round((c.size / 24) * 100)}%">
  ${sortedWidgets
    .map((w) => {
      return (w as any).rendered;
    })
    .join("\n")}
</mj-column>
      `;
      });
    })
  );
};
