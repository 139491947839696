import MapboxMarkerChart from "../../../../../../../components/chart/interractive-map/PinMap";
import { compose } from "../../../../../../../components/compose/WlyCompose";
import { getDefaultCollection } from "../../../../../../../components/palette/utils/paletteData";
import type { IObject } from "../../../../../../../interfaces/object";
import { UserLocale } from "../../../../../../../interfaces/user";
import type { InjectedOrgProps } from "../../../../../../orgs/WithOrg";
import WithOrg from "../../../../../../orgs/WithOrg";
import type { IRecord } from "../../../domain";
import type { IWidget } from "../../domain";
import type { IWidgetMapConfig } from "./domain";

interface IWidgetMapRendererProps {
  widget: IWidget;
  object: IObject;
  record: IRecord;
  conf: IWidgetMapConfig;
  edit?: boolean;
}

type Props = IWidgetMapRendererProps & InjectedOrgProps;

function WidgetMapRenderer(props: Props) {
  const { conf, record, org, edit } = props;

  const parseLatLong = (key: string, defaultValue: string) => {
    try {
      if (!key || !record[key]) {
        return defaultValue;
      }
      const floatValue = parseFloat(record[key] as any);
      if (Number.isNaN(floatValue)) {
        throw new Error(`${key} is not a number`);
      }
      return floatValue.toString();
    } catch (err) {
      console.log(err);
      return defaultValue;
    }
  };

  const lat = parseLatLong(conf.options.lat, "48.8566");
  const long = parseLatLong(conf.options.long, "2.3522");

  const height = conf.height;

  return (
    <div style={{ margin: -12, overflow: "hidden", position: "relative" }}>
      {edit && (
        <div
          style={{
            position: "absolute",
            zIndex: 1,
            inset: 0,
            background: "transparent",
          }}
        />
      )}
      <MapboxMarkerChart
        removeMargin={true}
        config={{
          x: { key: "x", type: "geo" },
          y: {
            key: "y",
            canDrill: () => ({}),
            formatter: { format: "NUMBER" },
          },
        }}
        chartOptions={{
          axis: { left: {}, right: {}, bottom: {} },
          "interractive-map-disable-zoom": true,
          "interractive-map-position": {
            type: "custom",
            position: {
              zoomLevel: "12",
              lat: lat,
              lng: long,
            },
          },
        }}
        height={height ? height : 250}
        locale={UserLocale.en_US}
        defaultCollection={getDefaultCollection(org)}
        data={[
          {
            x: `${lat}, ${long}`,
            y: 1,
          },
        ]}
      />
    </div>
  );
}

export default compose<Props, IWidgetMapRendererProps>(WithOrg)(
  WidgetMapRenderer
);
