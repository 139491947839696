import { MoreOutlined } from "@ant-design/icons";
import { Button, Dropdown, List, Space } from "antd";
import { inject, observer } from "mobx-react";
import * as React from "react";
import type { InjectedAntUtilsProps } from "../../../../../components/ant-utils/withAntUtils";
import { withAntUtils } from "../../../../../components/ant-utils/withAntUtils";
import { compose } from "../../../../../components/compose/WlyCompose";
import type { IObject } from "../../../../../interfaces/object";
import type { IRadar } from "../../../../../interfaces/radar";
import type { WorkbenchUIStoreProps } from "../../../../../store/workbenchUIStore";
import workbenchUIStore from "../../../../../store/workbenchUIStore";
import type { IActiveObject } from "../../domain";
import RenameRadarModal from "../../viewer/radar/modals/RenameRadarModal";
import "./RadarTable.scss";
import type { UpdateRadarFunction } from "./domain";

interface IRadarTableProps {
  radars: IRadar[];
  activeObject?: IActiveObject;
  onActiveObjectChange: (activeObject: IActiveObject) => void;
  onUpdateRadar: UpdateRadarFunction;
}

type Props = IRadarTableProps & WorkbenchUIStoreProps & InjectedAntUtilsProps;

function RadarTable(props: Props) {
  const {
    activeObject,
    onActiveObjectChange,
    radars,
    antUtils,
    onUpdateRadar,
  } = props;

  const [renameOpen, setRenameOpen] = React.useState<IObject | undefined>(
    undefined
  );

  return (
    <>
      <List<IRadar>
        itemLayout="horizontal"
        dataSource={radars}
        className="radar-table-list"
        renderItem={(radar) => {
          return (
            <List.Item
              className={`radar-table-list-item ${
                activeObject &&
                activeObject.type === "radar" &&
                activeObject.value === radar.id
                  ? "active"
                  : ""
              }`}
              actions={[
                <Space
                  className="ant-list-item-action"
                  size="small"
                  key="space"
                >
                  <Dropdown
                    trigger={["click"]}
                    menu={{
                      items: [
                        {
                          label: "Rename",
                          key: "rename",
                          onClick: (e) => {
                            e.domEvent.stopPropagation();
                            setRenameOpen(radar as any);
                          },
                        },
                        {
                          label: "Delete",
                          key: "delete",
                          onClick: (e) => {
                            e.domEvent.stopPropagation();
                            return antUtils.modal.confirm({
                              title: "Do you want to proceed?",
                              content:
                                "You are about to delete this radar, this cannot be undone. Do you want to proceed ?",
                              onCancel: () => undefined,
                              okButtonProps: {
                                danger: true,
                              },
                              onOk: async () => {
                                try {
                                  await onUpdateRadar(radar.id, {
                                    isDeleted: true,
                                  });
                                  workbenchUIStore.removeActiveObject({
                                    type: "radar",
                                    value: radar.id,
                                  });
                                  antUtils.message.success("Radar deleted");
                                } catch (error) {
                                  antUtils.message.error(
                                    "Error while deleting radar. Please try again"
                                  );
                                }
                              },
                            });
                          },
                          danger: true,
                        },
                      ],
                    }}
                    placement={"bottomRight"}
                  >
                    <Button
                      size="small"
                      type="text"
                      shape="circle"
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    >
                      <MoreOutlined />
                    </Button>
                  </Dropdown>
                </Space>,
              ]}
              onClick={() =>
                onActiveObjectChange({
                  type: "radar",
                  value: radar.id,
                })
              }
            >
              <List.Item.Meta
                title={
                  <div className="radar-table-list-item-title">
                    {radar.name}
                  </div>
                }
              />
            </List.Item>
          );
        }}
      />
      <RenameRadarModal
        open={!!renameOpen}
        onClose={() => setRenameOpen(undefined)}
        onRename={async (values) => {
          try {
            if (renameOpen) {
              await onUpdateRadar(renameOpen.id, {
                name: values.name,
              });
              antUtils.message.success("Radar renamed");
            }
          } catch (error) {
            antUtils.message.error(
              "Error while renaming radar. Please try again"
            );
          }
        }}
        initialValue={
          renameOpen
            ? {
                name: renameOpen.name,
              }
            : undefined
        }
      />
    </>
  );
}

export default compose<Props, IRadarTableProps>(
  inject("workbenchUIStore"),
  observer,
  withAntUtils
)(RadarTable);
