import {
  AlignCenterOutlined,
  AlignLeftOutlined,
  AlignRightOutlined,
  EditOutlined,
} from "@ant-design/icons";
import { Button, Form, Modal, Radio, Select, Space } from "antd";
import React from "react";
import { ChartOptionCollapse } from "../../../../../../../chart-options/components/ChartOptionCollapse";
import ChartOptionLine from "../../../../../../../chart-options/components/ChartOptionLine";
import { getObjectColumns } from "../../../../../object/domain";
import { convertKeyToMarkdocVariable } from "../../../widgets/markdown/domain";
import { MarkdocEditor } from "../../../widgets/markdown/editor/MarkdocEditor";
import "../../../widgets/markdown/editor/MarkdownModal.scss";
import type { IWidgetEmailEditorProps } from "../../domain";
import type { IEmailWidgetTextConfig } from "../domain";

interface IEmailWidgetTextEditorProps
  extends IWidgetEmailEditorProps<IEmailWidgetTextConfig> {}

export function EmailWidgetTextEditor(props: IEmailWidgetTextEditorProps) {
  const { object } = props;
  const [open, setOpen] = React.useState<boolean>(false);

  const cols = getObjectColumns(object);

  return (
    <>
      <ChartOptionCollapse dark title="Text Configuration">
        <Space size="middle" style={{ width: "100%" }} direction="vertical">
          <ChartOptionLine
            items={[
              {
                content: <div>Align</div>,
                flex: `1`,
              },
              {
                content: (
                  <Form.Item name={["config", "align"]} noStyle>
                    <Radio.Group
                      options={[
                        {
                          value: "left",
                          label: <AlignLeftOutlined />,
                        },
                        {
                          value: "center",
                          label: <AlignCenterOutlined />,
                        },
                        {
                          value: "right",
                          label: <AlignRightOutlined />,
                        },
                      ]}
                      optionType="button"
                      buttonStyle="solid"
                    />
                  </Form.Item>
                ),
                flex: `0 auto`,
              },
            ]}
          />
          <ChartOptionLine
            items={[
              {
                content: <div>Spacing</div>,
                flex: `1`,
              },
              {
                content: (
                  <Form.Item
                    initialValue={"normal"}
                    name={["config", "spacing"]}
                    noStyle
                  >
                    <Select style={{ width: "100%" }}>
                      <Select.Option value="normal">Normal</Select.Option>
                      <Select.Option value="compact">Compact</Select.Option>
                    </Select>
                  </Form.Item>
                ),
                flex: `1`,
              },
            ]}
          />
          <ChartOptionLine
            items={[
              {
                flex: 1,
                content: <div>Content</div>,
              },
              {
                flex: 0,
                content: (
                  <>
                    <Button
                      shape="circle"
                      onClick={() => setOpen(true)}
                      size="small"
                      type="text"
                      icon={<EditOutlined />}
                    />
                    <Form.Item noStyle hidden name={["config", "content"]} />
                    <Modal
                      open={open}
                      onCancel={() => setOpen(false)}
                      destroyOnClose
                      maskClosable={false}
                      title={"Edit markdown"}
                      className="markdown-modal"
                      width={"50%"}
                      okText={"Save"}
                      onOk={() => {
                        setOpen(false);
                      }}
                    >
                      <Form.Item noStyle name={["config", "content"]}>
                        <MarkdocEditor
                          columnsSuggestions={cols.map((c) => ({
                            key: `$${convertKeyToMarkdocVariable(c.data.key)}`,
                            label: c.data.label,
                          }))}
                        />
                      </Form.Item>
                    </Modal>
                  </>
                ),
              },
            ]}
          />
        </Space>
      </ChartOptionCollapse>
    </>
  );
}
