import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Drawer, Form, Input } from "antd";
import React from "react";
import type { InjectedAntUtilsProps } from "../../../components/ant-utils/withAntUtils";
import { withAntUtils } from "../../../components/ant-utils/withAntUtils";
import { compose } from "../../../components/compose/WlyCompose";
import FormActions from "../../../components/form/actions/FormActions";
import PalettePicker from "../../../components/palette/PalettePicker";
import type { IPalette } from "../../../components/palette/utils/paletteData";
import { palettes } from "../../../components/palette/utils/paletteData";
import GraphQLService from "../../../services/graphql/GraphQLService";
import type { InjectedOrgProps } from "../../orgs/WithOrg";
import WithOrg from "../../orgs/WithOrg";

interface IPaletteCollectionEditProps {
  visible: boolean;
  onClose: (reload?: boolean) => void;
  initialData?: ICollectionInitialData;
}

export interface ICollectionInitialData {
  id?: string;
  name: string;
  categorical: IPalette[];
  diverging: IPalette[];
  sequential: IPalette[];
}

type Props = InjectedOrgProps &
  IPaletteCollectionEditProps &
  InjectedAntUtilsProps;

function PaletteCollectionEdit(props: Props) {
  const { visible, onClose, initialData, org, antUtils } = props;

  const [submitting, setSubmitting] = React.useState<boolean>(false);
  const [form] = Form.useForm();

  React.useEffect(() => {
    if (!visible) {
      form.resetFields();
    }
  }, [visible]);

  const title = initialData
    ? "Edit your collection"
    : "Create a new collection";

  const initialValues: ICollectionInitialData = initialData
    ? initialData
    : {
        name: "",
        categorical: [palettes["whaly-categorical"]],
        diverging: [palettes["whaly-divergent-green"]],
        sequential: [palettes["whaly-divergent-blue"]],
      };

  return (
    <Drawer
      visible={visible}
      onClose={() => onClose()}
      title={title}
      maskClosable={true}
      destroyOnClose={true}
      width={"50%"}
    >
      <Form
        form={form}
        style={{ paddingBottom: 24 }}
        layout="vertical"
        initialValues={initialValues}
        onFinish={async (v) => {
          try {
            setSubmitting(true);
            await form.validateFields();
            if (initialData && initialData.id) {
              await GraphQLService(
                `
              mutation updatePalette($id: ID!, $data: PaletteCollectionUpdateInput!) {
                updatePaletteCollection(id: $id, data: $data) {
                  id
                }
              }
              `,
                {
                  id: initialData.id,
                  data: {
                    name: v.name,
                    divergingData: JSON.stringify(
                      v.diverging.filter((d) => !!d)
                    ),
                    categoricalData: JSON.stringify(
                      v.categorical.filter((d) => !!d)
                    ),
                    sequentialData: JSON.stringify(
                      v.sequential.filter((d) => !!d)
                    ),
                  },
                }
              );
            } else {
              // create mode
              await GraphQLService(
                `
              mutation createPalette($data: PaletteCollectionCreateInput!) {
                createPaletteCollection(data: $data) {
                  id
                }
              }
              `,
                {
                  data: {
                    name: v.name,
                    divergingData: JSON.stringify(
                      v.diverging.filter((d) => !!d)
                    ),
                    categoricalData: JSON.stringify(
                      v.categorical.filter((d) => !!d)
                    ),
                    sequentialData: JSON.stringify(
                      v.sequential.filter((d) => !!d)
                    ),
                    org: {
                      connect: {
                        id: org.id,
                      },
                    },
                  },
                }
              );
            }

            onClose(true);
          } catch (err) {
            console.error(err);
            antUtils.message.error(
              "Error while saving your palette collection"
            );
          } finally {
            setSubmitting(false);
          }
        }}
      >
        <Form.Item
          name="name"
          rules={[{ required: true }]}
          label="Your collection name"
        >
          <Input />
        </Form.Item>
        <Form.List
          name="categorical"
          rules={[
            {
              validator: async (_, names) => {
                if (!names || names.length < 1) {
                  return Promise.reject(
                    new Error(
                      "You should define at least one categorical palette"
                    )
                  );
                }
              },
            },
          ]}
        >
          {(fields, { add, remove }, { errors }) => {
            return (
              <>
                {fields.map((field, index) => (
                  <Form.Item
                    label={index === 0 ? "Categorical" : ""}
                    required={false}
                    key={field.key}
                  >
                    <div style={{ display: "flex" }}>
                      <Form.Item {...field} noStyle>
                        <PalettePicker
                          editModeOnly={true}
                          restrict="discrete"
                        />
                      </Form.Item>

                      {fields.length > 1 ? (
                        <div
                          style={{
                            justifyContent: "center",
                            alignItems: "center",
                            marginLeft: 24,
                          }}
                        >
                          <MinusCircleOutlined
                            className="dynamic-delete-button"
                            onClick={() => remove(field.name)}
                          />
                        </div>
                      ) : null}
                    </div>
                  </Form.Item>
                ))}
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    icon={<PlusOutlined />}
                  >
                    Add categorical palette
                  </Button>
                  <Form.ErrorList errors={errors} />
                </Form.Item>
              </>
            );
          }}
        </Form.List>
        <Form.List
          name="sequential"
          rules={[
            {
              validator: async (_, values) => {
                if (!values || values.length < 1) {
                  return Promise.reject(
                    new Error(
                      "You should define at least one sequential palette"
                    )
                  );
                }
              },
            },
          ]}
        >
          {(fields, { add, remove }, { errors }) => {
            return (
              <>
                {fields.map((field, index) => (
                  <Form.Item
                    label={index === 0 ? "Sequential" : ""}
                    required={false}
                    key={field.key}
                  >
                    <div style={{ display: "flex" }}>
                      <Form.Item {...field} noStyle>
                        <PalettePicker
                          editModeOnly={true}
                          restrict="continue"
                        />
                      </Form.Item>

                      {fields.length > 1 ? (
                        <div
                          style={{
                            justifyContent: "center",
                            alignItems: "center",
                            marginLeft: 24,
                          }}
                        >
                          <MinusCircleOutlined
                            className="dynamic-delete-button"
                            onClick={() => remove(field.name)}
                          />
                        </div>
                      ) : null}
                    </div>
                  </Form.Item>
                ))}
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    icon={<PlusOutlined />}
                  >
                    Add sequential palette
                  </Button>
                  <Form.ErrorList errors={errors} />
                </Form.Item>
              </>
            );
          }}
        </Form.List>
        <Form.List
          name="diverging"
          rules={[
            {
              validator: async (_, names) => {
                if (!names || names.length < 1) {
                  return Promise.reject(
                    new Error(
                      "You should define at least one diverging palette"
                    )
                  );
                }
              },
            },
          ]}
        >
          {(fields, { add, remove }, { errors }) => {
            return (
              <>
                {fields.map((field, index) => (
                  <Form.Item
                    label={index === 0 ? "Diverging" : ""}
                    required={false}
                    key={field.key}
                  >
                    <div style={{ display: "flex" }}>
                      <Form.Item {...field} noStyle>
                        <PalettePicker
                          editModeOnly={true}
                          restrict="continue"
                        />
                      </Form.Item>

                      {fields.length > 1 ? (
                        <div
                          style={{
                            justifyContent: "center",
                            alignItems: "center",
                            marginLeft: 24,
                          }}
                        >
                          <MinusCircleOutlined
                            className="dynamic-delete-button"
                            onClick={() => remove(field.name)}
                          />
                        </div>
                      ) : null}
                    </div>
                  </Form.Item>
                ))}
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    icon={<PlusOutlined />}
                  >
                    Add diverging palette
                  </Button>
                  <Form.ErrorList errors={errors} />
                </Form.Item>
              </>
            );
          }}
        </Form.List>
      </Form>
      <FormActions
        isSubmitting={submitting}
        onCancel={() => onClose()}
        onSubmit={form.submit}
      />
    </Drawer>
  );
}

export default compose<Props, IPaletteCollectionEditProps>(
  WithOrg,
  withAntUtils
)(PaletteCollectionEdit);
