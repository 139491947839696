import { Flex, Form, InputNumber, Select } from "antd";
import { useForm } from "antd/es/form/Form";
import { useState } from "react";
import {
  withAntUtils,
  type InjectedAntUtilsProps,
} from "../../../../../../../../components/ant-utils/withAntUtils";
import { compose } from "../../../../../../../../components/compose/WlyCompose";
import { SimpleFormModal } from "../../../../../../../../components/form/modal/SimpleFormModal";
import type { IRadar } from "../../../../../../../../interfaces/radar";
import type {
  ISchedule,
  ScheduleDailySendEvery,
  ScheduleDayOfMonthPreset,
  SchedulePeriod,
  ScheduleWeeklyAtDay,
} from "../../../../../../../../interfaces/schedule";
import { useUpdateSchedule } from "../../../api/useUpdateSchedule";

type IRadarScheduleModalProps = {
  radar: IRadar;
  open: boolean;
  onClose: () => void;
  afterScheduleSave: () => Promise<void>;
};

type Props = IRadarScheduleModalProps & InjectedAntUtilsProps;

const hourMapping = {
  0: "00:00",
  1: "01:00",
  2: "02:00",
  3: "03:00",
  4: "04:00",
  5: "05:00",
  6: "06:00",
  7: "07:00",
  8: "08:00",
  9: "09:00",
  10: "10:00",
  11: "11:00",
  12: "12:00",
  13: "13:00",
  14: "14:00",
  15: "15:00",
  16: "16:00",
  17: "17:00",
  18: "18:00",
  19: "19:00",
  20: "20:00",
  21: "21:00",
  22: "22:00",
  23: "23:00",
};

function RadarScheduleModal({
  antUtils,
  radar,
  open,
  onClose,
  afterScheduleSave,
}: Props) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { mutateAsync: updateSchedule } = useUpdateSchedule();
  const [form] = useForm();

  return (
    <SimpleFormModal
      open={open}
      isSaving={isLoading}
      form={form}
      modalProps={{ title: "Update radar" }}
      onCancel={() => {
        onClose();
      }}
    >
      <Form<ISchedule>
        form={form}
        layout="vertical"
        initialValues={radar?.schedule ?? {}}
        onFinish={async (values) => {
          try {
            if (!radar.schedule?.id) {
              throw new Error("Schedule ID must be defined");
            }
            await updateSchedule({
              variables: { id: radar.schedule?.id, data: values },
            });
            await afterScheduleSave();
            setIsLoading(true);
            antUtils.message.success("Schedule successfully updated");
          } catch (error) {
            console.error(error);
            antUtils.message.error("Error while updating schedule");
          } finally {
            setIsLoading(false);
            onClose();
          }
        }}
      >
        <Form.Item
          label="Recurrence"
          name={["period"]}
          rules={[{ required: true }]}
        >
          <Select<SchedulePeriod>
            options={[
              { label: "Never", value: "never" },
              { label: "Hourly", value: "hour" },
              { label: "Daily", value: "day" },
              { label: "Weekly", value: "week" },
              { label: "Monthly", value: "month" },
            ]}
          />
        </Form.Item>
        <Form.Item noStyle shouldUpdate>
          {() => {
            if (
              ["day", "week", "month"].includes(form.getFieldValue("period"))
            ) {
              return (
                <Flex gap={16}>
                  <Form.Item noStyle shouldUpdate>
                    {() => {
                      if (form.getFieldValue("period") === "day") {
                        return (
                          <Form.Item
                            label="Every"
                            name={["dailySendEvery"]}
                            style={{ flex: 1 }}
                            rules={[{ required: true }]}
                          >
                            <Select<ScheduleDailySendEvery>
                              options={[
                                { label: "Day", value: "every_day" },
                                {
                                  label: "Business day",
                                  value: "on_week_day",
                                },
                              ]}
                            />
                          </Form.Item>
                        );
                      }
                      if (form.getFieldValue("period") === "week") {
                        return (
                          <Form.Item
                            label="Every"
                            name={["weeklyOnDay"]}
                            style={{ flex: 1 }}
                            rules={[{ required: true }]}
                          >
                            <Select<ScheduleWeeklyAtDay>
                              options={[
                                { label: "Monday", value: "monday" },
                                { label: "Tuesday", value: "tuesday" },
                                { label: "Wednesday", value: "wednesday" },
                                { label: "Thursday", value: "thursday" },
                                { label: "Friday", value: "friday" },
                                { label: "Saturday", value: "saturday" },
                                { label: "Sunday", value: "sunday" },
                              ]}
                            />
                          </Form.Item>
                        );
                      }
                      if (form.getFieldValue("period") === "month") {
                        return (
                          <Form.Item
                            label="Every"
                            name={["monthlyDayOfMonthPreset"]}
                            style={{ flex: 1 }}
                            rules={[{ required: true }]}
                          >
                            <Select<ScheduleDayOfMonthPreset>
                              options={[
                                {
                                  label: "First day of the month",
                                  value: "first_day",
                                },
                                {
                                  label: "Last day of the month",
                                  value: "last_day",
                                },
                                {
                                  label: "Nth day of the month",
                                  value: "nth_day",
                                },
                              ]}
                            />
                          </Form.Item>
                        );
                      }
                      return <></>;
                    }}
                  </Form.Item>
                  <Form.Item
                    label="At"
                    name={["atHour"]}
                    style={{ flex: 1 }}
                    rules={[{ required: true }]}
                  >
                    <Select
                      options={Object.keys(hourMapping).map((value) => ({
                        value: +value,
                        label: hourMapping[value],
                      }))}
                    />
                  </Form.Item>
                </Flex>
              );
            } else {
              return <></>;
            }
          }}
        </Form.Item>
        <Form.Item noStyle shouldUpdate>
          {() => {
            if (
              form.getFieldValue("period") === "month" &&
              form.getFieldValue("monthlyDayOfMonthPreset") === "nth_day"
            ) {
              return (
                <Flex gap={16}>
                  <Form.Item
                    label="On nth day"
                    name={["monthlyDayOfMonthNumber"]}
                    style={{ flex: 1 }}
                    rules={[{ required: true }]}
                    normalize={(value) => +value}
                  >
                    <InputNumber
                      style={{ width: "100%" }}
                      min={1}
                      max={31}
                      precision={0}
                    />
                  </Form.Item>
                  <div style={{ flex: 1 }} />
                </Flex>
              );
            } else {
              return <></>;
            }
          }}
        </Form.Item>
      </Form>
    </SimpleFormModal>
  );
}

export default compose<Props, IRadarScheduleModalProps>(withAntUtils)(
  RadarScheduleModal
);
