import { Button, Tooltip } from "antd";
import type { ButtonProps } from "antd/lib/button";
import React from "react";

interface IEditTooltipProps {
  items: Array<EditTooltipItem>;
  additionalStyle: React.CSSProperties;
}

export interface EditTooltipItem {
  key: string;
  name: string;
  props: ButtonProps;
}

export function EditTooltip(props: IEditTooltipProps) {
  const { items, additionalStyle } = props;
  return (
    <div
      style={{
        ...additionalStyle,
        backgroundColor: "white",
        border: "1px solid rgb(58 92 131)",
        borderRadius: 6,
        boxShadow:
          "0 6px 16px 0 rgb(0 0 0 / 8%), 0 3px 6px -4px rgb(0 0 0 / 12%), 0 9px 28px 8px rgb(0 0 0 / 5%)",
      }}
    >
      <Button.Group>
        {items.map((i) => {
          return (
            <Tooltip key={i.key} title={i.name}>
              <Button {...i.props} type="text" />
            </Tooltip>
          );
        })}
      </Button.Group>
    </div>
  );
}
