import { Form, Input } from "antd";
import * as React from "react";
import EmojiPicker from "../../../form/emoji-picker/EmojiPicker";
import WlyModal from "../../../modal/WlyModal";

interface IExplorationSectionCreationProps {
  initialData?: ExplorationSectionInitialData;
  onEdit: (v: ExplorationSectionInitialData) => Promise<any>;
  onCreate: (v: ExplorationSectionInitialData) => Promise<any>;
  visible: boolean;
  onCancel: () => any;
}

export interface ExplorationSectionInitialData {
  id?: string;
  order?: number;
  name: string;
  image: string;
}

export function ExplorationSectionCreation(
  props: IExplorationSectionCreationProps
) {
  const { onCreate, visible, onCancel, initialData, onEdit } = props;

  const [confirmLoading, setConfirmLoading] = React.useState(false);

  const [form] = Form.useForm();

  React.useEffect(() => {
    if (visible === false) {
      form.resetFields();
      form.setFieldsValue({ name: "", image: "random" });
    }
  }, [visible, form]);

  React.useEffect(() => {
    form.setFieldsValue(initialData);
  }, [initialData]);

  const renderInnerModal = () => {
    return (
      <Form
        initialValues={
          !initialData
            ? {
                name: "",
                image: "random",
              }
            : initialData
        }
        layout="vertical"
        form={form}
      >
        <Form.Item label="Section Name">
          <Input.Group compact>
            <Form.Item
              name={["image"]}
              noStyle
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <EmojiPicker />
            </Form.Item>
            <Form.Item
              name={["name"]}
              noStyle
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input
                style={{ width: "Calc(100% - 38px)", marginLeft: 6 }}
                placeholder="Your section name"
              />
            </Form.Item>
          </Input.Group>
        </Form.Item>
      </Form>
    );
  };

  return (
    <WlyModal
      open={!!visible}
      title={initialData?.id ? "Update a section" : "Create a section"}
      okText={initialData?.id ? "Update" : "Create"}
      cancelText="Cancel"
      onClose={() => {
        form.resetFields();
        onCancel();
      }}
      confirmLoading={confirmLoading}
      onOk={() => {
        setConfirmLoading(true);
        return form
          .validateFields()
          .then((values) => {
            const v = values as ExplorationSectionInitialData;
            if (initialData && initialData.id) {
              return onEdit({ ...v, id: initialData.id }).then(() =>
                setConfirmLoading(false)
              );
            } else {
              return onCreate({ ...initialData, ...v }).then(() =>
                setConfirmLoading(false)
              );
            }
          })
          .then(() => {
            onCancel();
            return form.resetFields();
          })
          .catch((info) => {
            setConfirmLoading(false);
            console.error("Validate Failed:", info);
          });
      }}
    >
      {renderInnerModal()}
    </WlyModal>
  );
}
