import type { FormInstance } from "antd";
import { Form, Input } from "antd";
import _ from "lodash";
import { compose } from "../../../../../../components/compose/WlyCompose";
import Feednack from "../../../../../../components/layout/feedback/feedback";
import type { AvailableDimension } from "../../../../../../components/measures/filter-item/FilterItem";
import type { IObject } from "../../../../../../interfaces/object";
import { type IDataset } from "../../../../../../interfaces/sources";
import {
  LagoonCallOrigin,
  lagoonServiceLoad,
} from "../../../../../../services/LagoonService";
import { ChartOptionCollapse } from "../../../../../chart-options/components/ChartOptionCollapse";
import ChartOptionLine from "../../../../../chart-options/components/ChartOptionLine";
import type { InjectedOrgProps } from "../../../../../orgs/WithOrg";
import WithOrg from "../../../../../orgs/WithOrg";
import { getObjectColumns } from "../../../object/domain";
import type { AvailableProperty } from "../../../object/viewer/domain";
import type { IRecord } from "../../domain";
import { WidgetVisibilityFilters } from "../common/visibility-filters/WidgetVisibilityFilters";
import type { IWidget } from "../domain";
import { rendererConfig } from "./domain";
import { buildQueryFromDimensionAndMetrics } from "./related-lists/domain";

interface IWidgetEditionRendererProps {
  object: IObject;
  widget: IWidget;
  record: IRecord;
  form: FormInstance;
  datasets: IDataset[];
  onChange: (widget: Omit<IWidget, "config">, config: any) => void;
}

type Props = IWidgetEditionRendererProps & InjectedOrgProps;

function WidgetEditionRenderer(props: Props) {
  const { widget, object, record, form, org, onChange, datasets } = props;

  const renderer = rendererConfig[widget.type];

  if (!renderer) {
    return <Feednack>Can't find widget type</Feednack>;
  }

  const conf = renderer.parseConfig(widget, object);

  const objectColumns = getObjectColumns(object);
  const debouncedChange = _.debounce(onChange, 200);

  return (
    <Form
      form={form}
      initialValues={{
        ...widget,
        config: conf,
      }}
      onValuesChange={(e, v) => {
        const { config, ...rest } = v;
        const { config: initialConf, ...initialWidget } = widget;
        debouncedChange(
          {
            ...initialWidget,
            ...rest,
          },
          config
        );
      }}
      layout="vertical"
    >
      <div style={{ margin: `0 -8px` }}>
        {/* widget specific */}
        {renderer.renderEditor(
          widget,
          object,
          record,
          conf,
          form,
          org,
          datasets
        )}
        {/* common */}
        {!renderer.removeCard && (
          <ChartOptionCollapse dark title="Card Configuration">
            <ChartOptionLine
              items={[
                {
                  content: "Title",
                  flex: `0 auto`,
                },
                {
                  content: (
                    <Form.Item noStyle name="name">
                      <Input />
                    </Form.Item>
                  ),
                  flex: 1,
                },
              ]}
            />
          </ChartOptionCollapse>
        )}
        <ChartOptionCollapse dark title="Conditional Display">
          <ChartOptionLine
            items={[
              {
                content: (
                  <Form.Item noStyle name="displayFilters">
                    <WidgetVisibilityFilters
                      autocomplete={async (dimension) => {
                        try {
                          const dim = objectColumns.find(
                            (ac) =>
                              ac.type === "property" &&
                              dimension === ac.data.key
                          );

                          if (!dim) {
                            return [];
                          }

                          const dimKey = (dim.data as AvailableProperty)
                            .sortAndFilterKey;
                          if (!dimKey) {
                            throw new Error("Dim not found");
                          }

                          const query = buildQueryFromDimensionAndMetrics(
                            [dimKey],
                            []
                          );

                          const data = await lagoonServiceLoad(
                            org.id,
                            query,
                            "OBJECT",
                            object.id,
                            undefined,
                            LagoonCallOrigin.WHALY_APP
                          );

                          const results = data.tablePivot();

                          return results.map((r) => r[dimKey].toString());
                        } catch (err) {
                          console.error(err);
                          throw new Error("Can't fetch recommendations");
                        }
                      }}
                      availableDimensions={objectColumns.map<AvailableDimension>(
                        (ac) => {
                          const prop = ac.data;
                          const domain =
                            ac.type === "property" ? ac.data.domain : "NUMERIC";
                          return {
                            key: prop.key,
                            label: prop.label,
                            description: prop.description,
                            type: "standard",
                            domain: domain,
                          };
                        }
                      )}
                    />
                  </Form.Item>
                ),
                flex: 1,
              },
            ]}
          />
        </ChartOptionCollapse>
      </div>
    </Form>
  );
}

export default compose<Props, IWidgetEditionRendererProps>(WithOrg)(
  WidgetEditionRenderer
);
