import createEngine, { DiagramModel } from "@projectstorm/react-diagrams";
import _ from "lodash";
import * as React from "react";
import type { IExploration } from "../../../../../../interfaces/explorations";
import type { TableTabItem } from "../../../../../spreadsheet/domain";
import type {
  FetchedDestination,
  IDatasetLineageGraph,
  TabData,
} from "../../../domain";
import { CanvasWrapper } from "../flow/canvas/CanvasWrapper";
import type { SimplePortModel } from "../flow/nodes/port/SimplePortModel";
import { DatasetNodeModel } from "./dataset-node/DatasetNodeModel";
import { generateEngine, GRID_SIZE } from "./domain";
import { ExplorationNodeModel } from "./exploration-node/ExplorationNodeModel";

interface ILineageRendererProps {
  datasetLineageGraph: IDatasetLineageGraph;
  currentTab: TabData;
  tableTabItems: TableTabItem[];
  destination: FetchedDestination;
}

export default class LineageRenderer extends React.Component<ILineageRendererProps> {
  engine = generateEngine(createEngine());

  constructor(props: ILineageRendererProps) {
    super(props);
    this.computeModel();
  }

  componentDidMount() {
    this.computeModel();
  }

  componentDidUpdate(prevProps: Readonly<ILineageRendererProps>): void {
    if (
      !_.isEqual(prevProps.currentTab, this.props.currentTab) ||
      !_.isEqual(prevProps.tableTabItems, this.props.tableTabItems)
    ) {
      this.computeModel();
    }
  }

  computeModel = () => {
    const model = new DiagramModel();
    const tree = this.props.currentTab.dependencyGraph;

    let modelItems: any = [];

    const findDataset = (id: string) => {
      return this.props.tableTabItems.find((d) => d.dataset.id === id);
    };

    const computeDataset = (
      dataset: TableTabItem,
      x: number,
      y: number,
      selected?: boolean
    ) => {
      const { destination } = this.props;
      const datasetNode = new DatasetNodeModel(selected);
      datasetNode.bindDataset(dataset);
      datasetNode.bindDestination(destination);
      datasetNode.setPosition(x, y);
      return datasetNode;
    };

    const computeExploration = (
      exploration: IExploration,
      x: number,
      y: number,
      hasUpstreamError?: boolean
    ) => {
      const explorationNode = new ExplorationNodeModel(hasUpstreamError);
      explorationNode.bindExploration(exploration);
      explorationNode.setPosition(x, y);
      return explorationNode;
    };

    const decreaseXOffset = (offset: number) => offset - 4;

    const increaseXOffet = (offset: number) => offset + 4;

    const maxNumberOfKeys = (t: IDatasetLineageGraph): number => {
      const children = Object.keys(t);
      if (!children.length) {
        return 1; // always take up 1 grid space
      }
      return Math.max(
        children.length,
        children.reduce((acc, c) => acc + maxNumberOfKeys(t[c]), 0)
      );
    };

    const addChildrenToCanvas = (
      datasetLineageGraph: IDatasetLineageGraph,
      parentPort: SimplePortModel,
      xOffset: number,
      yOffset: number
    ) => {
      const childKey = Object.keys(datasetLineageGraph);
      const baricenter = childKey.map((datasetId) => {
        const foundNumber = maxNumberOfKeys(datasetLineageGraph[datasetId]);
        if (!foundNumber) {
          return 1;
        }
        return foundNumber;
      });
      childKey.map((datasetId, i) => {
        const foundDataset = findDataset(datasetId);
        const children = datasetLineageGraph[datasetId];
        const numberOfChildren = Object.keys(
          datasetLineageGraph[datasetId]
        ).length;
        if (foundDataset) {
          const x = xOffset * GRID_SIZE;
          const sumUntilIndex = (i) => {
            const recomputedIndex = i;
            if (i === 0) {
              // we are on the first element of the array it should be based on the offset
              return yOffset;
            }
            return (
              yOffset +
              [...baricenter]
                .slice(0, recomputedIndex)
                .reduce((acc, v) => acc + v, 0)
            );
          };
          // we first compute the offset with the amount of space the block is going to take
          // to to do so consider the offset being the sum of all offset from baricenter
          // then we translate the blocks to vertically center them with their children
          const newYOffset = sumUntilIndex(i);
          const childHeight = baricenter[i];
          const translation = childHeight === 1 ? 0 : childHeight / 2 - 0.5; // half a grid

          const node = computeDataset(
            foundDataset,
            x,
            (newYOffset + translation) * GRID_SIZE
          );
          const outPort = node.getOutPort();
          const link = parentPort.link(outPort);
          modelItems.push(node);
          modelItems.push(link);

          if (numberOfChildren) {
            const inPort = node.getInPort();
            addChildrenToCanvas(
              children,
              inPort,
              decreaseXOffset(xOffset),
              yOffset + newYOffset
            );
          }
        }
      });
    };
    const initialDataset = findDataset(this.props.currentTab.dataset.id);
    if (initialDataset) {
      const heightMax = maxNumberOfKeys(tree);
      const translation = heightMax === 1 ? 0 : heightMax / 2 - 0.5;
      const node = computeDataset(
        initialDataset,
        0,
        translation * GRID_SIZE,
        true
      );
      node.setSelected(true);
      modelItems.push(node);
      const port = node.getInPort();
      addChildrenToCanvas(tree, port, decreaseXOffset(0), 0);

      const allUsedExplorations: Array<{ name: string; slug: string }> = [];
      initialDataset.views.forEach((v) => {
        v.usedInExplorations.forEach((a) => {
          if (!allUsedExplorations.find((ae) => ae.slug === a.slug)) {
            allUsedExplorations.push(a);
          }
        });
      });

      const addParentsToCanvas = (
        parents: Array<{ name: string; slug: string }>,
        childPort: SimplePortModel,
        xOffset: number,
        yOffset: number,
        hasUpstreamError: boolean
      ) => {
        parents.forEach((p, i, s) => {
          const explorationNode = computeExploration(
            p as IExploration,
            xOffset * GRID_SIZE,
            (yOffset + i - (s.length - 1) / 2) * GRID_SIZE,
            hasUpstreamError
          );
          const link = childPort.link(explorationNode.getInPort());
          modelItems.push(explorationNode);
          modelItems.push(link);
        });
      };

      addParentsToCanvas(
        allUsedExplorations,
        node.getOutPort(),
        increaseXOffet(0),
        node.getY() / GRID_SIZE,
        initialDataset.hasUpstreamError
      );
    }

    model.addAll(...modelItems);
    model.setLocked(true);
    this.engine.setModel(model);
    if (this.engine.getCanvas()) {
      this.engine.zoomToFitNodes({
        nodes: modelItems,
        maxZoom: 1,
        margin: 5,
      });
    }

    return model;
  };

  public render() {
    return (
      <div className="flow-dataset-wrapper">
        <CanvasWrapper
          engine={this.engine}
          onZoomIn={() => {
            const zoomLevel = this.engine.getModel().getZoomLevel();
            this.engine.getModel().setZoomLevel(zoomLevel + 1);
          }}
          onZoomOut={() => {
            const zoomLevel = this.engine.getModel().getZoomLevel();
            if (zoomLevel > 0) {
              this.engine.getModel().setZoomLevel(zoomLevel - 1);
            }
          }}
        />
      </div>
    );
  }
}
