import Icon from "@ant-design/icons";
import type { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";

export const WlyStackedAreaChartIcon = (
  props: Partial<CustomIconComponentProps>
) => (
  <Icon
    component={() => (
      <svg
        width="1em"
        height="1em"
        viewBox="0 0 213 213"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_1103_1258)">
          <path
            d="M58 73.5L-2.5 56.5L-14.5 47.5V219.5H237.5V-0.5L151.5 18.5L58 73.5Z"
            fill="#ABEDCC"
          />
          <path
            d="M-1 60L53.5 71L151.5 21L227.5 1"
            stroke="#41D58A"
            strokeWidth="12"
          />
          <path
            d="M58 102.5L1 144.5V214H212V44.5L137 144.5L58 102.5Z"
            fill="#A2B2C5"
          />
          <path
            d="M-11 155L59 104.5L135 146L220.5 33"
            stroke="#3A5C83"
            strokeWidth="12"
          />
        </g>
        <defs>
          <clipPath id="clip0_1103_1258">
            <rect width="213" height="213" fill="white" />
          </clipPath>
        </defs>
      </svg>
    )}
    {...props}
  />
);
