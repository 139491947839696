import { CaretRightOutlined, InfoCircleOutlined } from "@ant-design/icons";
import type { PopoverProps } from "antd";
import { Button, Col, Popover, Row, Space, Tooltip, Typography } from "antd";
import type { TooltipPlacement } from "antd/lib/tooltip";
import React, { useState } from "react";
import type { ChartType } from "../../../../../components/chart/domain";
import { ChartDefinition } from "../../../../../components/chart/domain";
import type { IAnalysisType } from "../../domain";
import { AnalysisDefiniton } from "../../domain";
import "./ChartSelector.scss";

type ChartSelectorValue = {
  chartType: ChartType;
  analysisType: IAnalysisType;
};

interface IChartSelectorProps {
  disabledAnalysis?: Array<IAnalysisType>;
  placement?: TooltipPlacement;
  value?: ChartSelectorValue;
  onChange?: (value: ChartSelectorValue) => void;
}

const renderIcon = (icon: JSX.Element, style: React.CSSProperties) => {
  return React.cloneElement(icon, { style: style });
};

export function ChartSelector(props: IChartSelectorProps) {
  const { disabledAnalysis, value, onChange, placement } = props;
  const [open, setOpen] = useState<boolean>(false);
  const selectedChart = value
    ? ChartDefinition[value.chartType]
    : ChartDefinition[Object.keys(ChartDefinition)[0]];
  const selectedAnalysis = value
    ? AnalysisDefiniton.find((analysis) => analysis.key === value.analysisType)
    : AnalysisDefiniton[0];

  const popover: PopoverProps = {
    title: null,
    open: open,
    onOpenChange: (v: boolean) => {
      setOpen(v);
    },
    trigger: ["click"],
    placement: placement ? placement : "left",
    destroyTooltipOnHide: true,
    content: (
      <div className="popover-chart-selection">
        <Space direction="vertical" size={"large"} style={{ width: "100%" }}>
          {AnalysisDefiniton.map((analysis, k) => {
            let isAnalysisDisabled = false;
            if (disabledAnalysis && disabledAnalysis.includes(analysis.key)) {
              isAnalysisDisabled = true;
            }
            return (
              <Row gutter={[8, 8]} key={k}>
                <Col span={24}>
                  <Space>
                    <b>{analysis.label}</b>
                    <Tooltip
                      title={analysis.description}
                      mouseLeaveDelay={0}
                      destroyTooltipOnHide
                    >
                      <InfoCircleOutlined />
                    </Tooltip>
                  </Space>
                </Col>
                {Object.keys(ChartDefinition)
                  .map((chartType: ChartType) => {
                    return { key: chartType, ...ChartDefinition[chartType] };
                  })
                  .filter((chart) =>
                    analysis.availableCharts.includes(chart.key)
                  )
                  .map((chart, i) => {
                    const isChartDisabled = isAnalysisDisabled;
                    const className = ["chart-selection-button"];
                    if (
                      selectedChart === chart.key &&
                      selectedAnalysis.key === analysis.key
                    ) {
                      className.push("chart-selection-button-selected");
                    }
                    return (
                      <Col span={6} key={i} className="chart-selection-box">
                        <Tooltip title={chart.label} mouseLeaveDelay={0}>
                          <Button
                            disabled={isChartDisabled}
                            className={className.join(" ")}
                            onClick={() => {
                              if (onChange) {
                                onChange({
                                  analysisType: analysis.key,
                                  chartType: chart.key,
                                });
                              }
                              setOpen(false);
                            }}
                          >
                            {renderIcon(chart.icon, {
                              fontSize: 36,
                              marginLeft: -10,
                              marginTop: 1,
                            })}
                          </Button>
                        </Tooltip>
                      </Col>
                    );
                  })}
              </Row>
            );
          })}
        </Space>
      </div>
    ),
  };
  return (
    <Popover {...popover}>
      <div className="chart-selection">
        <div className="chart-selection-icon">
          <Button
            icon={renderIcon(selectedChart.icon, {
              fontSize: 26,
              marginTop: 2,
            })}
            style={{
              width: 42,
              height: 42,
            }}
            ghost
          />
        </div>
        <div className="chart-selection-text">
          <div className="chart-selection-text-title">
            <Typography.Text>{selectedChart.label}</Typography.Text>
          </div>
          <div className="chart-selection-text-subtitle">
            <Typography.Text
              type="secondary"
              style={{
                fontSize: 12,
              }}
            >
              {selectedAnalysis.label}
            </Typography.Text>
          </div>
        </div>
        <div className="chart-selection-right">
          <CaretRightOutlined size={12} />
        </div>
      </div>
    </Popover>
  );
}
