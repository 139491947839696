import type {
  BinaryFilter,
  BinaryOperator,
  UnaryFilter,
} from "@cubejs-client/core";
import { Button, Space } from "antd";
import * as React from "react";
import { AddFilterButton } from "../../../../../../components/measures/filter-item/AddFilterButton";
import { FilterGroupItem } from "../../../../../../components/measures/filter-item/FilterGroupItem";
import type {
  AvailableDimension,
  IAvailableDimensionGroup,
} from "../../../../../../components/measures/filter-item/FilterItem";
import FilterItem from "../../../../../../components/measures/filter-item/FilterItem";
import { hasFilterGroup } from "../../../../../../components/measures/filter-item/domain";
import { DroppableMeasureZone } from "../../../../../../components/measures/measure-picker/DroppableMeasureZone";
import type { DataType } from "../../../../../../interfaces/transformations";

interface IQueryBuilderFilterProps {
  availableDimensions: Array<AvailableDimension | IAvailableDimensionGroup>;
  autocomplete: (
    dimensionName: string,
    operator: BinaryOperator,
    value?: string
  ) => Promise<string[]>;
  filterOperator: "and" | "or";
  filters: (UnaryFilter | BinaryFilter)[];
  newFilterOperator?: "set" | "equals";
  setFilters: (filters: (UnaryFilter | BinaryFilter)[]) => void;
  setFilterOperator: (operator: "and" | "or") => void;
  valueSubstitutionColumns?: Array<{
    key: string;
    domain: DataType;
    label: string;
  }>;
}

export function QueryBuilderFilter(props: IQueryBuilderFilterProps) {
  const {
    availableDimensions,
    newFilterOperator,
    autocomplete,
    setFilters,
    filters,
    setFilterOperator,
    filterOperator,
    valueSubstitutionColumns,
  } = props;

  const [hasGroup, setHasGroup] = React.useState<boolean>(
    hasFilterGroup(filters)
  );

  React.useEffect(() => {
    setHasGroup(hasFilterGroup(filters));
  }, [filters]);

  // spec
  // on last group deleted up the underlying filters

  const wrapInDroppable = (r: React.ReactElement): React.ReactElement => {
    if (hasGroup) {
      return r;
    }
    return (
      <DroppableMeasureZone
        zone="dimensions_filters"
        accept={["dimension"]}
        availableMeasures={availableDimensions}
        onAddMeasure={(i) =>
          setFilters([
            ...filters,
            {
              member: i,
              operator: "set",
            },
          ])
        }
      >
        {r}
      </DroppableMeasureZone>
    );
  };

  return wrapInDroppable(
    <Space direction="vertical" style={{ width: "100%" }}>
      {hasGroup ? (
        <div className="line">Keep rows that match</div>
      ) : (
        <div className="line">
          Keep rows that match{" "}
          <Button
            onClick={() =>
              setFilterOperator(filterOperator === "or" ? "and" : "or")
            }
            size="small"
          >
            {filterOperator === "or" ? "any" : "all"}
          </Button>{" "}
          of the following:
        </div>
      )}
      {filters.flatMap((f, i, s) => {
        if ((f as any).or || (f as any).and) {
          const operator = Object.keys(f)[0] as "and" | "or";
          const subFilters = (f as any)[operator];
          return [
            <FilterGroupItem
              key={i}
              filterOperator={operator}
              filters={subFilters}
              availableDimensions={availableDimensions}
              onFilterOperatorChange={() => {
                setFilters(
                  filters.map((a, index) => {
                    const nextKey = operator === "and" ? "or" : "and";
                    if (index === i) {
                      return {
                        [nextKey]: subFilters,
                      } as any;
                    }
                    return a;
                  })
                );
              }}
              autocomplete={autocomplete}
              onDelete={(index) =>
                setFilters(
                  filters.map((a, n) => {
                    if (n === i) {
                      return {
                        [operator]: subFilters.filter((s, j) => j !== index),
                      } as any;
                    }
                    return a;
                  })
                )
              }
              onGroupDelete={() => {
                const newFilters = filters.filter((a, index) => i !== index);
                if (newFilters.length === 1) {
                  if ((newFilters[0] as any).or) {
                    setFilters((newFilters[0] as any).or);
                  } else if ((newFilters[0] as any).and) {
                    setFilters((newFilters[0] as any).and);
                  }
                } else {
                  setFilters(newFilters);
                }
              }}
              onFiltersChange={(filts) =>
                setFilters(
                  filters.map((a, j) => {
                    if (j === i) {
                      return {
                        [operator]: filts,
                      } as any;
                    }
                    return a;
                  })
                )
              }
            />,
            i !== s.length - 1 ? (
              <div style={{ textAlign: "center" }}>
                <Button
                  onClick={() =>
                    setFilterOperator(filterOperator === "and" ? "or" : "and")
                  }
                  size="small"
                >
                  {filterOperator === "and" ? "And" : "Or"}
                </Button>
              </div>
            ) : null,
          ];
        } else {
          return (
            <FilterItem
              key={i}
              onDelete={() => setFilters(filters.filter((f, ci) => ci !== i))}
              onChange={(f) =>
                setFilters(filters.map((cf, ci) => (i === ci ? f : cf)))
              }
              valueSubstitutionColumns={valueSubstitutionColumns}
              filter={f}
              availableDimensions={availableDimensions}
              autocomplete={autocomplete}
              className="line"
            />
          );
        }
      })}
      <AddFilterButton
        availableDimensions={availableDimensions}
        filters={filters}
        setFilters={setFilters}
        newFilterOperator={newFilterOperator}
      />
    </Space>
  );
}
