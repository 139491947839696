import { EllipsisOutlined, PlusOutlined } from "@ant-design/icons";
import { Badge, Button, Dropdown, Tree } from "antd";
import _ from "lodash";
import * as React from "react";
import type { InjectedAntUtilsProps } from "../../../../../../components/ant-utils/withAntUtils";
import { withAntUtils } from "../../../../../../components/ant-utils/withAntUtils";
import { compose } from "../../../../../../components/compose/WlyCompose";
import { SourceImageRenderer } from "../../../../../../components/sources/SourceImageRenderer";
import { handleGQLErrors } from "../../../../../../helpers/gqlHelpers";
import { userCanDeleteDataset } from "../../../../../../helpers/sourceHelpers";
import type { DeepPartial } from "../../../../../../helpers/typescriptHelpers";
import type { IDestination } from "../../../../../../interfaces/destinations";
import type { IDataset, ISource } from "../../../../../../interfaces/sources";
import type { InjectedOrgProps } from "../../../../../orgs/WithOrg";
import WithOrg from "../../../../../orgs/WithOrg";
import type { TableTabItem } from "../../../../../spreadsheet/domain";
import { toDisplayErrors } from "../../../dataset/DatasetPopover";
import type { DatasetInitialData } from "../../../dataset/modal/DatasetEdition";
import DatasetEdition from "../../../dataset/modal/DatasetEdition";
import type { DatasetSavedData } from "../models/AddModelModal";
import "./SourceTable.scss";

interface ISourceTableProps {
  tables: Array<TableTabItem>;
  sources: Array<ISource>;
  activeKey?: string;
  onActiveTableChange: (prevKey: string, nextKey: string) => void;
  onCreateDataset?: (
    dataset: DatasetSavedData | DatasetSavedData[]
  ) => Promise<any>;
  onUpdateSource?: (
    sourceId: string,
    data: DeepPartial<ISource>
  ) => Promise<void>;
  onDeleteSource?: (sourceId: string) => Promise<void>;
  onDeleteDataset?: (datasetId: string) => Promise<void>;
  renderLine: (
    id: string,
    nameComponent: React.ReactNode,
    menuComponent?: React.ReactNode,
    rawKey?: string,
    draggable?: any,
    dataset?: TableTabItem
  ) => React.ReactNode;
  renderOrigin: (
    managedBy: "WHALY" | "DBT_CLOUD",
    overrideSourceLogo?: string
  ) => React.ReactNode | null;
  currentWarehouse: IDestination;
}

interface IState {
  openKeys: string[];
  selectedKeys: string[];
  datasetEditionOpen: boolean;
  datasetInitialData?: DatasetInitialData;
  sourceEditionOpen: boolean;
  sourceInitialData?: DatasetInitialData;
}

type Props = ISourceTableProps & InjectedOrgProps & InjectedAntUtilsProps;

class SourceTable extends React.Component<Props, IState> {
  constructor(props: Props) {
    super(props);
    const openKeys: string[] = [];
    const selectedKeys: string[] = [];
    if (props.activeKey) {
      const foudTable = props.tables.find((t) => t.key === props.activeKey);
      if (foudTable) {
        openKeys.push(`source-${foudTable.baseSourceId}`);
      }
    }
    if (props.activeKey) {
      selectedKeys.push(`table-${props.activeKey}`);
    }
    this.state = {
      openKeys: openKeys,
      selectedKeys: selectedKeys,
      datasetEditionOpen: false,
      sourceEditionOpen: false,
    };
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.activeKey !== prevProps.activeKey) {
      const selectedKeys: string[] = [];
      let newOpenKey: string;
      if (this.props.activeKey) {
        selectedKeys.push(`table-${this.props.activeKey}`);
        document
          .getElementById(`table-${this.props.activeKey}`)
          ?.scrollIntoView({ block: "center" });
        const foudTable = this.props.tables.find(
          (t) => t.key === this.props.activeKey
        );
        if (foudTable) {
          newOpenKey = `source-${foudTable.baseSourceId}`;
        }
      }
      this.setState({
        selectedKeys: selectedKeys,
        openKeys: [...this.state.openKeys, newOpenKey].filter(_.uniq),
      });
    }
  }

  onSourceDelete = (source: ISource, tables: TableTabItem[]) => {
    const { antUtils, onDeleteSource } = this.props;
    if (tables.length) {
      return new Promise((resolve, reject) => {
        antUtils.modal.error({
          title: "Cannot delete a source with datasets",
          content: `In order to delete ${source.name}, you need to first delete each of its datasets.`,
          onOk: () => {
            return resolve(true);
          },
          onCancel: () => {
            return reject(false);
          },
        });
      });
    }
    return new Promise((resolve, reject) => {
      antUtils.modal.confirm({
        title: "Are you sure you want to delete",
        content: `You are about to delete ${source.name}, this operation cannot be undone. Do you want to proceed?`,
        okButtonProps: {
          danger: true,
        },
        okText: "Delete",
        onOk: () => {
          return onDeleteSource(source.id).then((r) => {
            return resolve(true);
          });
        },
        onCancel: () => {
          return reject(false);
        },
      });
    });
  };

  public render() {
    const {
      renderLine,
      sources,
      onCreateDataset,
      onUpdateSource,
      tables,
      activeKey,
      onActiveTableChange,
      currentWarehouse,
      onDeleteSource,
      renderOrigin,
    } = this.props;

    const { openKeys } = this.state;

    return (
      <>
        <Tree
          onExpand={(expandedKeys) => {
            this.setState({
              openKeys: expandedKeys.map((k) => k.toString()),
            });
          }}
          expandedKeys={openKeys}
          blockNode={true}
          selectable={true}
          draggable={false}
          className="source-tree"
          autoExpandParent={true}
          selectedKeys={this.state.selectedKeys}
          defaultSelectedKeys={this.state.selectedKeys}
          treeData={[
            ...sources
              .sort((a, b) => a.name.localeCompare(b.name))
              .map((s) => {
                const sourceKey = `source-${s.id}`;
                const sourceTables = tables
                  .sort((a, b) => a.label.localeCompare(b.label))
                  .filter((t) => t.baseSourceId === s.id);
                return {
                  title: renderLine(
                    sourceKey,
                    <span
                      className="workbench-item-name-inner bold"
                      onClick={() => {
                        if (this.state.openKeys.includes(sourceKey)) {
                          this.setState({
                            openKeys: this.state.openKeys.filter(
                              (ok) => ok !== sourceKey
                            ),
                          });
                        } else {
                          this.setState({
                            openKeys: [...this.state.openKeys, sourceKey],
                          });
                        }
                      }}
                    >
                      <span
                        className="workbench-item-name-inner-image-wrapper"
                        style={{ display: "flex" }}
                      >
                        <SourceImageRenderer
                          size={20}
                          className={"workbench-item-name-inner-image"}
                          alt={s.name}
                          img={s.sourceMeta.publicInfo.logo}
                        />
                        {renderOrigin(s.managedBy)}
                      </span>
                      <Badge
                        dot={
                          !!sourceTables.find(
                            (t) => toDisplayErrors(t).length > 0
                          )
                        }
                        className="workbench-item-name-inner-text"
                        status="warning"
                        offset={[4, 6]}
                        size={"small"}
                      >
                        {s.name}
                      </Badge>
                      {s.sourceMeta.executor === "WAREHOUSE" &&
                      (onDeleteSource || onUpdateSource) ? (
                        <span className="workbench-item-name-inner-actions">
                          <Dropdown
                            menu={{
                              items: [
                                onUpdateSource && {
                                  key: 0,
                                  onClick: (m) => {
                                    m.domEvent.stopPropagation();
                                    this.setState({
                                      sourceEditionOpen: true,
                                      sourceInitialData: {
                                        sourceId: s.id,
                                        name: s.name,
                                        emoji: s.sourceMeta?.publicInfo?.logo,
                                      },
                                    });
                                  },
                                  label: "Edit",
                                },
                                onDeleteSource &&
                                  s.sourceMeta.executor === "WAREHOUSE" &&
                                  s.managedBy !== "DBT_CLOUD" && {
                                    key: 1,
                                    onClick: (m) => {
                                      m.domEvent.stopPropagation();
                                      this.onSourceDelete(s, sourceTables);
                                    },
                                    label: "Delete",
                                    danger: true,
                                  },
                              ],
                            }}
                            getPopupContainer={() => {
                              const el = document.getElementById(sourceKey);
                              if (el) {
                                return el;
                              }
                              return document.body;
                            }}
                            placement={"bottomRight"}
                            arrow={true}
                            trigger={["click"]}
                          >
                            <Button
                              size="small"
                              type="text"
                              shape="circle"
                              icon={<EllipsisOutlined rotate={90} />}
                              onClick={(e) => {
                                e.stopPropagation();
                              }}
                            />
                          </Dropdown>
                        </span>
                      ) : null}
                    </span>,
                    undefined,
                    s.id,
                    false
                  ),
                  key: sourceKey,
                  selectable: false,
                  children: [
                    ...tables
                      .sort((a, b) => a.label.localeCompare(b.label))
                      .filter((t) => t.baseSourceId === s.id)
                      .map((t) => {
                        const key = `table-${t.key}`;
                        return {
                          title: renderLine(
                            key,
                            <span
                              id={key}
                              onClick={() => {
                                this.setState(
                                  {
                                    selectedKeys: [key],
                                  },
                                  () => {
                                    onActiveTableChange(activeKey!, t.key);
                                  }
                                );
                              }}
                              className={
                                activeKey === t.key
                                  ? "workbench-item-name-inner hoverable selected"
                                  : "workbench-item-name-inner hoverable"
                              }
                            >
                              <span className="workbench-item-name-inner-text">
                                <Badge
                                  dot={toDisplayErrors(t).length > 0}
                                  status="warning"
                                  offset={[4, 6]}
                                  size={"small"}
                                >
                                  {t.label}
                                </Badge>
                              </span>
                              {this.props.onDeleteDataset &&
                                userCanDeleteDataset({
                                  id: t.baseDatasetId,
                                  source: sources.find(
                                    (s) => s.id === t.baseSourceId
                                  ),
                                } as IDataset) &&
                                t.managedBy !== "DBT_CLOUD" && (
                                  <span className="workbench-item-name-inner-actions">
                                    <Dropdown
                                      menu={{
                                        items: [
                                          {
                                            key: "remove",
                                            danger: true,
                                            disabled: !userCanDeleteDataset({
                                              id: t.baseDatasetId,
                                              source: sources.find(
                                                (s) => s.id === t.baseSourceId
                                              ),
                                            } as IDataset),
                                            onClick: (e) => {
                                              e.domEvent.stopPropagation();
                                              this.props.onDeleteDataset(
                                                t.baseDatasetId
                                              );
                                            },
                                            label: "Delete dataset",
                                          },
                                        ],
                                      }}
                                      trigger={["click"]}
                                      placement={"bottomRight"}
                                      arrow={true}
                                      getPopupContainer={(d) => {
                                        const el = document.getElementById(
                                          `model-${t.key}`
                                        );
                                        if (el) {
                                          return el;
                                        }
                                        return document.body;
                                      }}
                                    >
                                      <Button
                                        type="text"
                                        size="small"
                                        shape="circle"
                                        onClick={(e) => e.stopPropagation()}
                                        icon={<EllipsisOutlined rotate={90} />}
                                      />
                                    </Dropdown>
                                  </span>
                                )}
                            </span>,
                            undefined,
                            t.key,
                            true,
                            t
                          ),
                          key: key,
                          selectable: true,
                        };
                      }),
                    ...(s.sourceMeta.executor === "WAREHOUSE" &&
                    currentWarehouse &&
                    !currentWarehouse.destinationMeta.isWhalyManaged &&
                    s.managedBy === "WHALY"
                      ? [
                          {
                            title: (
                              <Button
                                type="link"
                                style={{
                                  padding: 0,
                                  height: "initial",
                                }}
                                onClick={() =>
                                  this.setState({
                                    datasetEditionOpen: true,
                                    datasetInitialData: {
                                      sourceId: s.id,
                                      name: s.name,
                                      emoji: s.sourceMeta?.publicInfo.logo,
                                    },
                                  })
                                }
                              >
                                <PlusOutlined /> Import datasets
                              </Button>
                            ),
                            key: `select-table-source-${s.id}`,
                            selectable: false,
                            isLeaf: true,
                            children: [],
                          },
                        ]
                      : []),
                  ],
                };
              }),
          ]}
        />
        {onUpdateSource && this.state.sourceEditionOpen && (
          <>
            <DatasetEdition
              action="EDIT_SOURCE"
              currentWarehouse={currentWarehouse}
              disabledFields={
                sources.find(
                  (s) => s.id === this.state.sourceInitialData?.sourceId
                ).managedBy === "DBT_CLOUD"
                  ? ["name"]
                  : undefined
              }
              tables={this.props.tables}
              visible={this.state.sourceEditionOpen}
              initialData={this.state.sourceInitialData}
              onCancel={() =>
                this.setState({
                  sourceEditionOpen: false,
                  sourceInitialData: undefined,
                })
              }
              onSave={async (data) => {
                try {
                  await onUpdateSource(this.state.sourceInitialData.sourceId, {
                    name: data.source.name,
                    sourceMeta: {
                      publicInfo: {
                        logo: data.source.emoji,
                      },
                    },
                  });
                  this.setState({
                    sourceEditionOpen: false,
                    sourceInitialData: undefined,
                  });
                } catch (error) {
                  handleGQLErrors();
                }
              }}
            />
          </>
        )}
        {onCreateDataset && this.state.datasetEditionOpen && (
          <DatasetEdition
            action="IMPORT_DATASETS"
            currentWarehouse={currentWarehouse}
            tables={this.props.tables}
            visible={this.state.datasetEditionOpen}
            initialData={this.state.datasetInitialData}
            onCancel={() =>
              this.setState({
                datasetEditionOpen: false,
                datasetInitialData: undefined,
              })
            }
            onSave={(data) => {
              return onCreateDataset(data.importTables)
                .then(() => {
                  this.setState({
                    datasetEditionOpen: false,
                    datasetInitialData: undefined,
                  });
                })
                .catch(handleGQLErrors());
            }}
          />
        )}
      </>
    );
  }
}

export default compose<Props, ISourceTableProps>(
  WithOrg,
  withAntUtils
)(SourceTable);
