import { MoreOutlined, PlusCircleOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  Dropdown,
  Empty,
  Flex,
  Row,
  Space,
  Typography,
} from "antd";
import { useForm } from "antd/es/form/Form";
import { Fragment, useState } from "react";
import type { InjectedAntUtilsProps } from "../../../../components/ant-utils/withAntUtils";
import { withAntUtils } from "../../../../components/ant-utils/withAntUtils";
import { compose } from "../../../../components/compose/WlyCompose";
import { SimpleFormModal } from "../../../../components/form/modal/SimpleFormModal";
import Feednack from "../../../../components/layout/feedback/feedback";
import Loading from "../../../../components/layout/feedback/loading";
import type { ITopic } from "../../../../interfaces/topic";
import type { InjectedOrgProps } from "../../../orgs/WithOrg";
import WithOrg from "../../../orgs/WithOrg";
import SettingsWrapper from "../../SettingsWrapper";
import NotificationTopicCard from "./NotificationTopicCard";
import { useCreateTopic } from "./api/useCreateTopic";
import { useCreateTopicGroup } from "./api/useCreateTopicGroup";
import { useGetTopicGroups } from "./api/useGetTopicGroups";
import { useUpdateTopicGroup } from "./api/useUpdateTopicGroup";
import type { CreateEditTopicFormData } from "./forms/CreateEditTopicForm";
import { CreateEditTopicForm } from "./forms/CreateEditTopicForm";
import type { CreateEditTopicGroupFormData } from "./forms/CreateEditTopicGroupForm";
import { CreateEditTopicGroupForm } from "./forms/CreateEditTopicGroupForm";

interface INotificationTopicsPageProps {}

type Props = INotificationTopicsPageProps &
  InjectedOrgProps &
  InjectedAntUtilsProps;

function NotificationTopicsPage(props: Props) {
  const { org, antUtils } = props;

  const [createEditTopicGroupOpen, setCreateEditTopicGroupOpen] = useState<{
    open: boolean;
    initialValues?: Partial<CreateEditTopicGroupFormData>;
  }>({ open: false });

  const [createTopicOpen, setCreateTopicOpen] = useState<{
    open: boolean;
    initialValues?: { topicGroupId: string };
  }>({ open: false });

  const [topicForm] = useForm<CreateEditTopicFormData>();
  const [topicGroupForm] = useForm<CreateEditTopicGroupFormData>();
  const { data, loading, error, refetch } = useGetTopicGroups({
    variables: { orgId: org.id },
  });

  const { mutate: createTopicGroup, loading: isCreatingTopicGroup } =
    useCreateTopicGroup({
      onError: () => {
        antUtils.message.error("Error while creating topic group");
      },
      onSuccess: () => {
        setCreateEditTopicGroupOpen({ open: false });
        antUtils.message.success("Topic group created!");
        refetch();
      },
    });

  const {
    mutate: updateTopicGroup,
    mutateAsync: updateTopigGroupAsync,
    loading: isUpdatingTopicGroup,
  } = useUpdateTopicGroup({
    onError: () => {
      antUtils.message.error("Error while updating topic group");
    },
    onSuccess: (d) => {
      if (d.updateTopicGroup.isDeleted) {
        antUtils.message.success("Topic group deleted!");
        refetch();
      } else {
        setCreateEditTopicGroupOpen({ open: false });
        antUtils.message.success("Topic group updated!");
        refetch();
      }
    },
  });

  const { mutate: createTopic, loading: isCreatingTopic } = useCreateTopic({
    onError: () => {
      antUtils.message.error("Error while creating topic");
    },
    onSuccess: (d) => {
      setCreateTopicOpen({ open: false });
      antUtils.message.success("Topic created!");
      refetch();
    },
  });

  const onCreateTopic = (values: CreateEditTopicFormData) => {
    if (values.topicGroupId) {
      createTopic({
        variables: {
          data: {
            name: values.name,
            apiName: values.apiName,
            org: { connect: { id: org.id } },
            topicGroup: { connect: { id: values.topicGroupId } },
          },
        },
      });
    }
  };

  const onCreateEditTopicGroup = async (
    values: CreateEditTopicGroupFormData
  ) => {
    if (values.id) {
      updateTopicGroup({
        variables: {
          id: values.id,
          data: {
            name: values.name,
            workflowSelections: {
              disconnectAll: true,
              create: (values.allowedWorkflows ?? []).map((workflowId) => ({
                topicGroup: {
                  connect: {
                    id: values.id,
                  },
                },
                workflow: {
                  connect: {
                    id: workflowId,
                  },
                },
                org: {
                  connect: {
                    id: org.id,
                  },
                },
              })),
            },
          },
        },
      });
    } else {
      createTopicGroup({
        variables: {
          data: {
            name: values.name,
            org: { connect: { id: org.id } },
            workflowSelections: {
              create: (values.allowedWorkflows ?? []).map((workflowId) => ({
                org: { connect: { id: org.id } },
                workflow: { connect: { id: workflowId } },
              })),
            },
          },
        },
      });
    }
  };

  if (loading) {
    return (
      <Feednack>
        <Loading />
      </Feednack>
    );
  }

  if (error) return <Feednack>{error.message}</Feednack>;

  const topicGroups = data?.allTopicGroups ?? [];

  return (
    <SettingsWrapper>
      <div style={{ padding: 32 }}>
        <Row gutter={[16, 16]}>
          <Col xs={24}>
            <Flex justify="space-between">
              <Typography.Title level={3}>Notification topics</Typography.Title>
              <Button
                icon={<PlusCircleOutlined />}
                onClick={() => setCreateEditTopicGroupOpen({ open: true })}
              >
                New topic group
              </Button>
            </Flex>
          </Col>
        </Row>
        {topicGroups.length <= 0 && (
          <Feednack>
            <Empty
              description={
                <>
                  No topics groups
                  <br />
                  <Button
                    type="link"
                    onClick={() => setCreateEditTopicGroupOpen({ open: true })}
                  >
                    Create a topic group
                  </Button>
                </>
              }
            />
          </Feednack>
        )}
        {topicGroups.length > 0 &&
          topicGroups.map((tg) => (
            <Fragment key={tg.id}>
              <Row gutter={[16, 16]} style={{ marginTop: 24 }}>
                <Col span={24}>
                  <Typography.Title level={5}>
                    <Space style={{ display: "flex" }}>
                      {tg.name}
                      <Dropdown
                        trigger={["click"]}
                        menu={{
                          items: [
                            {
                              key: "add",
                              label: "Add topic",
                              onClick: () =>
                                setCreateTopicOpen({
                                  open: true,
                                  initialValues: { topicGroupId: tg.id },
                                }),
                            },
                            {
                              key: "edit",
                              label: "Edit",
                              onClick: () =>
                                setCreateEditTopicGroupOpen({
                                  open: true,
                                  initialValues: {
                                    id: tg.id,
                                    name: tg.name,
                                    allowedWorkflows: (
                                      tg.workflowSelections ?? []
                                    )?.flatMap((ws) => ws.workflow.id),
                                  },
                                }),
                            },
                            {
                              key: "delete",
                              label: "Delete",
                              danger: true,
                              onClick: () =>
                                antUtils.modal.confirm({
                                  title: "Are you sure?",
                                  content: `You are about to delete this topic group, this can't be undone. Continue?`,
                                  okButtonProps: {
                                    danger: true,
                                  },
                                  okText: "Delete",
                                  onOk: () =>
                                    updateTopigGroupAsync({
                                      variables: {
                                        id: tg.id,
                                        data: { isDeleted: true },
                                      },
                                    }).catch(),
                                  onCancel: () => {},
                                }),
                            },
                          ],
                        }}
                      >
                        <Button
                          icon={<MoreOutlined />}
                          ghost
                          type="text"
                          shape="circle"
                          size="small"
                        />
                      </Dropdown>
                    </Space>
                  </Typography.Title>
                </Col>
              </Row>
              <Row gutter={[16, 16]}>
                {(tg.topics ?? []).length <= 0 && (
                  <Feednack>
                    <Empty
                      description={
                        <>
                          This topic group is empty
                          <br />
                          <Button
                            type="link"
                            onClick={() =>
                              setCreateTopicOpen({
                                open: true,
                                initialValues: { topicGroupId: tg.id },
                              })
                            }
                          >
                            Add a topic
                          </Button>
                        </>
                      }
                    />
                  </Feednack>
                )}
                {(tg.topics ?? []).map(
                  (t: ITopic & { _subscribersMeta?: { count?: number } }) => (
                    <Col xs={12} md={6} key={t.id}>
                      <NotificationTopicCard
                        topic={t}
                        onSave={() => refetch()}
                      />
                    </Col>
                  )
                )}
              </Row>
            </Fragment>
          ))}
      </div>
      <SimpleFormModal<CreateEditTopicGroupFormData>
        form={topicGroupForm}
        open={createEditTopicGroupOpen.open}
        onCancel={() => setCreateEditTopicGroupOpen({ open: false })}
        isSaving={isCreatingTopicGroup || isUpdatingTopicGroup}
        modalProps={{
          title: createEditTopicGroupOpen.initialValues?.id
            ? "Update topic group"
            : "Create a new topic group",
        }}
      >
        <CreateEditTopicGroupForm
          form={topicGroupForm}
          initialValues={createEditTopicGroupOpen.initialValues}
          notificationWorkflows={data?.allNotificationWorkflows ?? []}
          onSave={onCreateEditTopicGroup}
        />
      </SimpleFormModal>
      <SimpleFormModal<CreateEditTopicFormData>
        form={topicForm}
        open={createTopicOpen.open}
        onCancel={() => setCreateTopicOpen({ open: false })}
        isSaving={isCreatingTopic}
        modalProps={{
          title: "New topic",
        }}
      >
        <CreateEditTopicForm
          form={topicForm}
          initialValues={createTopicOpen.initialValues}
          onSave={onCreateTopic}
        />
      </SimpleFormModal>
    </SettingsWrapper>
  );
}

export default compose<Props, INotificationTopicsPageProps>(
  WithOrg,
  withAntUtils
)(NotificationTopicsPage);
