import { inject, observer } from "mobx-react";
import { useEffect } from "react";
import { compose } from "../../../../../components/compose/WlyCompose";
import Feednack from "../../../../../components/layout/feedback/feedback";
import Loading from "../../../../../components/layout/feedback/loading";
import type { IDestination } from "../../../../../interfaces/destinations";
import type { IDataset } from "../../../../../interfaces/sources";
import type { WorkbenchUIStoreProps } from "../../../../../store/workbenchUIStore";
import * as Toolbar from "../../../../spreadsheet/toolbar/Toolbar";
import type {
  IActiveObject,
  IActiveObjectObjectUrlState,
  ObjectStore,
} from "../../domain";
import type { UpdateRadarFunction } from "../../selector/radar/domain";
import "./RadarViewer.scss";
import { useGetRadarDetails } from "./api/useGetRadarDetails";
import RadarSettings from "./tabs/settings/RadarSettings";

type IRadarViewerProps = {
  currentWarehouse: IDestination;
  activeObject: IActiveObject;
  objectStore: ObjectStore;
  datasets: IDataset[];
  updateKey: string | undefined;
  onUpdateRadar: UpdateRadarFunction;
};

type Props = IRadarViewerProps & WorkbenchUIStoreProps;

enum Tabs {
  SETTINGS,
}

function RadarViewer({
  currentWarehouse,
  activeObject,
  datasets,
  objectStore,
  updateKey,
  onUpdateRadar,
  workbenchUIStore: { getActiveObjectUrlParams, setActiveObjectUrlParams },
}: Props) {
  const { data, loading, error, refetch } = useGetRadarDetails({
    variables: { id: activeObject.value },
  });

  useEffect(() => {
    refetch();
  }, [updateKey]);

  const getSelectedTab = () => {
    switch (
      (getActiveObjectUrlParams(activeObject) as IActiveObjectObjectUrlState)
        ?.tab
    ) {
      case "settings":
        return Tabs.SETTINGS;
      default:
        return Tabs.SETTINGS;
    }
  };

  const selectedTab = getSelectedTab();

  const renderInner = () => {
    const radar = data?.Radar;
    if (loading) {
      return (
        <Feednack>
          <Loading />
        </Feednack>
      );
    }
    if (error) {
      return <Feednack>{error.message}</Feednack>;
    }
    if (!radar) {
      return <Feednack>Radar not found</Feednack>;
    }
    if (selectedTab === Tabs.SETTINGS) {
      return (
        <RadarSettings
          currentWarehouse={currentWarehouse}
          radar={radar}
          objectStore={objectStore}
          datasets={datasets}
          onUpdateRadar={onUpdateRadar}
          afterSave={refetch}
        />
      );
    }
    return (
      <div className="workbench-content">
        <div className="radar-viewer">
          <Feednack>Radar not found</Feednack>
        </div>
      </div>
    );
  };

  return (
    <div className="workbench-content">
      <div className="workbench-spreadsheet">
        <div className="workbench-content-inner">
          <Toolbar.Toolbar style={{ borderTop: "none" }}>
            <Toolbar.Item
              color="yellow"
              active={selectedTab === Tabs.SETTINGS}
              onClick={() => {
                setActiveObjectUrlParams(activeObject, {
                  ...activeObject.urlState,
                  tab: "settings",
                });
              }}
            >
              Settings
            </Toolbar.Item>
          </Toolbar.Toolbar>
          <div className="workbench-content">
            <div className="radar-viewer">{renderInner()}</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default compose<Props, IRadarViewerProps>(
  inject("workbenchUIStore"),
  observer
)(RadarViewer);
