import Icon from "@ant-design/icons";
import type { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";

export const WlyImageIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon
    component={() => (
      <svg
        width="1em"
        height="1em"
        viewBox="0 0 213 213"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M61.3571 178H152.643C166.624 178 178 166.624 178 152.643V61.3571C178 47.376 166.624 36 152.643 36H61.3571C47.376 36 36 47.376 36 61.3571V152.643C36 166.624 47.376 178 61.3571 178ZM61.3571 167.857C52.9675 167.857 46.1429 161.033 46.1429 152.643V117.863L79.4464 94.6582L158.525 166.67C156.714 167.432 154.728 167.857 152.643 167.857H61.3571ZM61.3571 46.1429H152.643C161.033 46.1429 167.857 52.9675 167.857 61.3571V152.643C167.857 155.229 167.148 157.63 166.005 159.765L83.2822 84.4361C81.529 82.8364 78.9115 82.6779 76.9676 84.025L46.1429 105.502V61.3571C46.1429 52.9675 52.9675 46.1429 61.3571 46.1429Z"
          fill="#5D6671"
        />
        <path
          d="M134.167 100.116C145.353 100.116 154.453 91.018 154.453 79.8301C154.453 68.6423 145.353 59.5444 134.167 59.5444C122.982 59.5444 113.882 68.6423 113.882 79.8301C113.882 91.018 122.982 100.116 134.167 100.116ZM134.167 69.6873C139.761 69.6873 144.31 74.2387 144.31 79.8301C144.31 85.4216 139.761 89.973 134.167 89.973C128.573 89.973 124.024 85.4216 124.024 79.8301C124.024 74.2387 128.573 69.6873 134.167 69.6873Z"
          fill="#5D6671"
        />
      </svg>
    )}
    {...props}
  />
);
