import type { Identifier } from "dnd-core";
import React from "react";
import { useDrag, useDrop } from "react-dnd";
import type { MeasureDropZone } from "../measure-picker/DroppableMeasureZone";
import type { MeasureType } from "../measure-table/MeasureTable";
import type { IBaseMeasureItemProps } from "./MeasureItem";
import MeasureItem from "./MeasureItem";

interface IDraggableMeasureItemProps extends IBaseMeasureItemProps {
  id: string;
  zone: MeasureDropZone;
  /* add index only if item should be orderable */
  index?: number;
  onDrop?: (item: DraggableMeasure, position?: number) => void;
  onRemove?: () => void;
  accept?: Array<MeasureType | "property">;
}

export interface DraggableMeasure {
  id: string;
  index: number;
  zone: MeasureDropZone;
  agg?: string;
  onRemove?: () => void;
}

export function DraggableMeasureItem(props: IDraggableMeasureItemProps) {
  const ref = React.useRef<HTMLDivElement>();

  const [{ isOver, handlerId }, drop] = useDrop<
    DraggableMeasure,
    void,
    { isOver: boolean; handlerId: Identifier | null }
  >(
    {
      accept: props.accept ? props.accept : props.type,
      collect(monitor) {
        return {
          isOver: !!monitor.isOver(),
          handlerId: monitor.getHandlerId(),
        };
      },
      canDrop: (item) => {
        // can't reoder item with itself
        if (item.id === props.id) return false;
        // can't add item into other zone unless item from the store zone
        if (item.zone !== props.zone && item.zone !== "measure_store")
          return false;
        return true;
      },
      drop: (item) => {
        props.onDrop(item, props.index);
      },
    },
    [props]
  );

  const [{ isDragging }, drag] = useDrag(
    () => ({
      type: props.type,
      item: {
        id: props.id,
        index: props.index,
        zone: props.zone,
        agg: props.timeAgg,
        onRemove: props.onRemove,
      },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    }),
    [props]
  );

  drag(drop(ref));

  const opacity = isDragging ? 0.7 : 1;
  // items that don't have an index are not orderable
  const border =
    props.index != null && isOver ? "-2px 0px 0px 0px purple" : "none";

  const style: React.CSSProperties = {
    opacity,
    display: props.small ? "inline-block" : "block",
    transform: "translate(0, 0)",
    boxShadow: border,
  };

  return (
    <div style={style} ref={ref} data-handler-id={handlerId}>
      <MeasureItem
        draggable={
          typeof props.draggable === "boolean" ? props.draggable : true
        }
        isDragging={isDragging}
        {...props}
      />
    </div>
  );
}
