import { Alert } from "antd";
import "./SpreadsheetErrorRenderer.scss";

interface ISpreadsheetErrorRendererProps {
  children?: React.ReactNode;
}

export const SpreadsheetErrorRenderer = (
  props: ISpreadsheetErrorRendererProps
) => {
  return (
    <div className="spreadsheet-error-wrapper">
      <div className="spreadsheet-error-container">
        <div className="spreadsheet-error-item">
          <Alert
            style={{ maxWidth: 600 }}
            message="Your query could not be executed"
            description={
              typeof props.children === "string"
                ? props.children.split("errorMsg='").pop()
                : props.children
            }
            type="error"
            showIcon
          />
        </div>
      </div>
    </div>
  );
};
