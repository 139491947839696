import {
  Button,
  Dropdown,
  Flex,
  Modal,
  Select,
  Space,
  Tag,
  Typography,
} from "antd";
import { useState } from "react";
import type { InjectedAntUtilsProps } from "../../../../../../../components/ant-utils/withAntUtils";
import { withAntUtils } from "../../../../../../../components/ant-utils/withAntUtils";
import { WlyCard } from "../../../../../../../components/cards/WlyCard";
import { compose } from "../../../../../../../components/compose/WlyCompose";
import { Emoji } from "../../../../../../../components/form/emoji-picker/Emoji";
import type { IDestination } from "../../../../../../../interfaces/destinations";
import type { IRadar } from "../../../../../../../interfaces/radar";
import type {
  ObjectData,
  ObjectLayoutData,
  ObjectStore,
} from "../../../../domain";
import type { UpdateRadarFunction } from "../../../../selector/radar/domain";
import RadarTopicKeyModal from "./modals/RadarTopicKeyModal";

interface IRadarSignalPublicCardProps {
  currentWarehouse: IDestination;
  radar: IRadar;
  objectStore: ObjectStore;
  onUpdateRadar: UpdateRadarFunction;
}

type Props = IRadarSignalPublicCardProps & InjectedAntUtilsProps;

function RadarSignalPublicCard(props: Props) {
  const { antUtils, currentWarehouse, radar, onUpdateRadar, objectStore } =
    props;
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [editOpen, setEditOpen] = useState<"topic" | "layout" | undefined>(
    undefined
  );
  const [newLayoutId, setNewLayoutId] = useState<string | undefined>(
    radar?.emailLayout?.id
  );

  const currentObject: ObjectData | undefined = radar.object?.id
    ? objectStore[radar.object.id]
    : undefined;

  const currentLayout: ObjectLayoutData | undefined =
    currentObject && radar.emailLayout?.id
      ? currentObject.layouts[radar.emailLayout.id]
      : undefined;

  const isValid = !!currentLayout && !!currentObject;

  const allObjects = Object.keys(objectStore)
    .map((key) => objectStore[key])
    .map((object) => ({
      ...object,
      emailLayouts: Object.keys(object.layouts)
        .map((key) => object.layouts[key])
        .filter((layout) => layout.type === "EMAIL")
        .sort(function (a, b) {
          if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }
          return 0;
        }),
    }))
    .sort((a, b) => {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    });

  return (
    <>
      <WlyCard
        title="Publish signal in"
        extra={
          <Dropdown
            placement="bottomRight"
            trigger={["click"]}
            menu={{
              items: [
                {
                  key: "topic",
                  label: "Edit topic",
                  onClick: () => setEditOpen("topic"),
                },
                {
                  key: "layout",
                  label: "Edit layout",
                  onClick: () => setEditOpen("layout"),
                },
              ],
            }}
          >
            <Button type="primary" size="small">
              Edit
            </Button>
          </Dropdown>
        }
      >
        <Space direction="vertical" style={{ display: "flex" }} size={20}>
          <Flex>
            <div style={{ flex: 1 }}>
              <Typography.Text strong>Topic</Typography.Text>
              <br />
              <Typography.Text type="secondary">
                Unique key that identifies your topic api key
              </Typography.Text>
            </div>
            <div>
              {radar.topicKey ? (
                <Tag>{radar.topicKey}</Tag>
              ) : (
                <Typography.Text type="secondary">Not set</Typography.Text>
              )}
            </div>
          </Flex>
          <Flex>
            <div style={{ flex: 1 }}>
              <Typography.Text strong>Layout</Typography.Text>
              <br />
              <Typography.Text type="secondary">
                Layout used to render the notification
              </Typography.Text>
            </div>
            <div>
              {isValid ? (
                currentLayout.name
              ) : (
                <Space>
                  <Emoji emoji={":warning:"} size={16} />
                  <Typography.Text type="secondary">
                    Layout not found
                  </Typography.Text>
                </Space>
              )}
            </div>
          </Flex>
        </Space>
      </WlyCard>
      <RadarTopicKeyModal
        currentWarehouse={currentWarehouse}
        onClose={() => setEditOpen(undefined)}
        onUpdateRadar={onUpdateRadar}
        open={editOpen === "topic"}
        radar={radar}
      />
      <Modal
        title={"Update layout"}
        open={editOpen === "layout"}
        onOk={async () => {
          try {
            setIsLoading(true);
            await onUpdateRadar(radar.id, {
              emailLayout: {
                id: newLayoutId,
              },
            });
            antUtils.message.success("Object successfully updated");
          } catch (error) {
            console.error(error);
            antUtils.message.error("Error while updating object");
          } finally {
            setIsLoading(false);
            setEditOpen(undefined);
          }
        }}
        okButtonProps={{
          loading: isLoading,
          disabled:
            isLoading || !newLayoutId || newLayoutId === radar?.emailLayout?.id,
        }}
        onCancel={() => {
          setNewLayoutId(radar?.emailLayout?.id);
          setEditOpen(undefined);
        }}
      >
        {allObjects.find((d) => d.id === radar.object?.id)?.emailLayouts && (
          <div style={{ marginTop: 12 }}>
            <Typography.Text type="secondary">
              Using email layout
            </Typography.Text>
            <Select
              style={{ width: "100%" }}
              showSearch
              optionFilterProp="label"
              value={newLayoutId}
              onChange={(v) => setNewLayoutId(v)}
              options={allObjects
                .find((d) => d.id === radar.object?.id)
                ?.emailLayouts?.map?.((v) => ({ label: v.name, value: v.id }))}
            />
          </div>
        )}
      </Modal>
    </>
  );
}

export default compose<Props, IRadarSignalPublicCardProps>(withAntUtils)(
  RadarSignalPublicCard
);
