import { HighlightStyle } from "@codemirror/language";
import { tags } from "@lezer/highlight";
import { markdocTags } from "./MarkdocExtension";

export const markdocHighlightStyle = HighlightStyle.define([
  { tag: tags.link, color: "#795D26" },
  { tag: tags.emphasis, fontStyle: "italic" },
  { tag: tags.strong, fontWeight: "bold" },
  { tag: markdocTags.markdocTag, color: "#1979FF" },
  { tag: markdocTags.markdocString, color: "#EE4803" },
]);
