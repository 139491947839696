import type { UploadProps } from "antd";
import {
  Button,
  Modal,
  Progress,
  Space,
  Timeline,
  Typography,
  Upload,
} from "antd";
import { ExportToCsv } from "export-to-csv";
import Papa from "papaparse";
import React from "react";
import type { InjectedAntUtilsProps } from "../../../../components/ant-utils/withAntUtils";
import { withAntUtils } from "../../../../components/ant-utils/withAntUtils";
import { compose } from "../../../../components/compose/WlyCompose";
import Feednack from "../../../../components/layout/feedback/feedback";
import Loading from "../../../../components/layout/feedback/loading";
import type { AsyncData } from "../../../../helpers/typescriptHelpers";
import type { IPartnerPortal } from "../../../../interfaces/org";
import type { IUserRole } from "../../../../interfaces/user";
import { UserLocale } from "../../../../interfaces/user";
import GraphQLService from "../../../../services/graphql/GraphQLService";
import type { InjectedOrgProps } from "../../../orgs/WithOrg";
import WithOrg from "../../../orgs/WithOrg";
import { parseAttributeValue } from "../../user-attributes/domain";
import { generateUserWhereInput, GET_USER_ROLE_USER } from "../domain";

import "./BulkUpload.scss";

interface IBulkUploadProps {
  open: boolean;
  portal?: IPartnerPortal;
  onClose: () => void;
  onDone: () => void;
}

interface IUserTask {
  email: string;
  role: string;
  firstName: string;
  lastName: string;
  password: string;
  locale: UserLocale;
  [key: string]: string;
}

interface IUpdateTask {
  type: "update" | "create";
  task: IUserTask;
}

interface IProcessedTask {
  status: "success" | "error";
  taskType: "update" | "create";
  error?: string[];
  task: IUserTask;
}

const attributeDelimiter = "||";
const passwordValue = "****";
const attributePrefix = "attribute_";

type Props = IBulkUploadProps & InjectedOrgProps & InjectedAntUtilsProps;

type Step = "STEP_1" | "STEP_2" | "STEP_3";

interface State {
  step: Step;
  geneteCsvTemplate: AsyncData<{}>;
  geneteCsvUsers: AsyncData<{}>;
  checkCsvImport: AsyncData<File>;
  plannedTasks: AsyncData<Array<IUpdateTask>>;
  doneTasks: {
    tasks: IProcessedTask[];
    status: "loading" | "done";
  };
}

class BulkUpload extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = this.computeInitialState();
  }

  computeInitialState = (): State => ({
    step: "STEP_1",
    geneteCsvTemplate: { status: "initial" },
    geneteCsvUsers: { status: "initial" },
    checkCsvImport: { status: "initial" },
    plannedTasks: { status: "initial" },
    doneTasks: {
      tasks: [],
      status: "loading",
    },
  });

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (prevProps.open && !this.props.open) {
      this.setState(this.computeInitialState());
    }
  }

  render() {
    const {
      open,
      onClose,
      org,
      portal,
      user,
      antUtils: { message },
      onDone,
    } = this.props;

    const {
      step,
      geneteCsvTemplate,
      geneteCsvUsers,
      checkCsvImport,
      plannedTasks,
      doneTasks,
    } = this.state;

    const fetchUsersTenByTen = async (
      data: IUserRole[],
      page?: number
    ): Promise<Array<IUserRole>> => {
      const newPage = page ? page : 0;
      const pageSize = 10;
      const where = generateUserWhereInput(
        portal ? "PORTAL" : "STANDARD",
        org,
        user,
        portal
      );
      return GraphQLService<{ allUserRoles: Array<IUserRole> }>(
        GET_USER_ROLE_USER,
        {
          orgId: org.id,
          userRoleWhereInput: where,
          first: pageSize,
          skip: pageSize * newPage,
        }
      ).then((r) => {
        if (r.allUserRoles.length === pageSize) {
          return fetchUsersTenByTen(r.allUserRoles, newPage + 1);
        } else {
          return [...r.allUserRoles, ...data];
        }
      });
    };

    const generateCsvLine = (role?: IUserRole): IUserTask => {
      return {
        email: role ? role.user.email : "",
        role: role ? role.role : "",
        firstName: role ? role.user.firstName : "",
        lastName: role ? role.user.lastName : "",
        password: role ? passwordValue : "",
        locale: role ? role.user.locale : UserLocale.en_US,
        ...org.userAttributeMetas.reduce<{ [key: string]: string }>(
          (acc, ua) => {
            const foundUserAttribute = role?.user?.attributes?.find(
              (a) => a.userAttributeMeta.id === ua.id
            );
            return {
              ...acc,
              [`${attributePrefix}${ua.technicalName}`]: foundUserAttribute
                ? parseAttributeValue(foundUserAttribute.value).join(
                    attributeDelimiter
                  )
                : "",
            };
          },
          {}
        ),
      };
    };

    const formatData = (data: IUserRole[]) => {
      return data.map((d) => {
        return generateCsvLine(d);
      });
    };

    const buildCsvTemplate = () => {
      this.setState({ geneteCsvTemplate: { status: "loading" } });
      var data = [generateCsvLine()];

      const options = {
        fieldSeparator: ";",
        quoteStrings: '"',
        decimalSeparator: ".",
        showLabels: true,
        showTitle: false,
        title: "Bulk user create",
        useTextFile: false,
        useBom: true,
        useKeysAsHeaders: true,
        filename: "template",
      };

      const csvExporter = new ExportToCsv(options);

      this.setState({ geneteCsvTemplate: { status: "initial" } });
      return csvExporter.generateCsv(data);
    };

    const buildFilledTemplate = async () => {
      try {
        this.setState({ geneteCsvUsers: { status: "loading" } });

        const rawData = await fetchUsersTenByTen([]);

        const data = formatData(rawData);

        const options = {
          fieldSeparator: ";",
          quoteStrings: '"',
          decimalSeparator: ".",
          showLabels: true,
          showTitle: false,
          title: "Bulk user update",
          useTextFile: false,
          useBom: true,
          useKeysAsHeaders: true,
          filename: "user_upload",
        };

        const csvExporter = new ExportToCsv(options);

        this.setState({ geneteCsvUsers: { status: "initial" } });
        return csvExporter.generateCsv(data);
      } catch (err) {
        console.error(err);
      }
    };

    const parseCsv = (file: File): Promise<Array<IUserTask>> => {
      return new Promise((resolve, reject) => {
        return Papa.parse(file, {
          header: true,
          skipEmptyLines: true,
          complete: (data) => {
            return resolve(data.data as Array<IUserTask>);
          },
          error: (err) => {
            return reject(err);
          },
        });
      });
    };

    const checkOperationDetails = async () => {
      const tasks: Array<IUpdateTask> = [];
      try {
        this.setState({ plannedTasks: { status: "loading" } });
        if (checkCsvImport.status === "success") {
          const rawData = await fetchUsersTenByTen([]);
          const data = formatData(rawData);
          const csvData = await parseCsv(checkCsvImport.data);

          csvData.forEach((d) => {
            if (data.find((a) => a.email === d.email)) {
              tasks.push({ task: d, type: "update" });
            } else {
              tasks.push({ task: d, type: "create" });
            }
          });
          this.setState({ plannedTasks: { status: "success", data: tasks } });
        } else {
          this.setState({ plannedTasks: { status: "success", data: tasks } });
        }
      } catch (err) {
        console.error(err);
        this.setState({ plannedTasks: { status: "error", error: err } });
      }
    };

    const processTasks = async () => {
      if (plannedTasks.status === "success") {
        const getStatus = (index: number) => {
          return plannedTasks.data.length - 1 === index ? "done" : "loading";
        };

        const registerProcessedTask = (t: IProcessedTask[], index: number) => {
          return new Promise((resolve, reject) => {
            this.setState(
              {
                doneTasks: {
                  status: getStatus(index),
                  tasks: t,
                },
              },
              () => {
                resolve({});
              }
            );
          });
        };

        const tasks: IProcessedTask[] = [];
        plannedTasks.data.reduce<Promise<any>>((acc, v, i) => {
          return acc.then(() => {
            return GraphQLService(
              `
            mutation createOrUpdateUser(
              $orgId: ID!,
              $role: String!,
              $email: String!,
              $firstName: String!,
              $lastName: String!,
              $password: String,
              $attributes: [UserAttributeValues]!
              $portalId: ID,
              $locale: String!
            ) {
              createOrUpdateUser(orgId: $orgId, role: $role, email: $email, firstName: $firstName, lastName: $lastName, password: $password, portalId: $portalId, attributes: $attributes, locale: $locale)
            }
            `,
              {
                orgId: org.id,
                role: v.task.role,
                email: v.task.email,
                firstName: v.task.firstName,
                lastName: v.task.lastName,
                portalId: portal?.id,
                locale: v.task.locale,
                password:
                  v.task.password === passwordValue
                    ? undefined
                    : v.task.password,
                attributes: Object.keys(v.task)
                  .filter((k) => k.startsWith(attributePrefix))
                  .flatMap((r) => {
                    const [prefix, technicalName] = r.split(attributePrefix);
                    const value = JSON.stringify(
                      v.task[r].split(attributeDelimiter)
                    );
                    const foundAttributeMeta = org.userAttributeMetas.find(
                      (a) => a.technicalName === technicalName
                    );
                    if (foundAttributeMeta) {
                      return [
                        {
                          attributeMetaId: foundAttributeMeta.id,
                          value,
                        },
                      ];
                    } else {
                      return [];
                    }
                  }),
              }
            )
              .then((r) => {
                if (r.createOrUpdateUser.length > 0) {
                  tasks.push({
                    status: "error",
                    task: v.task,
                    error: r.createOrUpdateUser as string[],
                    taskType: v.type,
                  });
                } else {
                  tasks.push({
                    status: "success",
                    task: v.task,
                    taskType: v.type,
                  });
                }

                return registerProcessedTask(tasks, i);
              })
              .catch((err) => {
                console.error(err);
                tasks.push({
                  status: "error",
                  task: v.task,
                  taskType: v.type,
                  error: ["An unexpected error happened, please retry"],
                });
                return registerProcessedTask(tasks, i);
              });
          });
        }, Promise.resolve());
      }
    };

    const uploadProps: UploadProps = {
      name: "file",
      disabled: checkCsvImport.status === "loading",
      accept: "text/csv",
      showUploadList: true,
      maxCount: 1,
      listType: "picture",
      customRequest: (options) => {
        options.onSuccess("ok");
      },
      onRemove: (file) => {
        this.setState({ checkCsvImport: { status: "initial" } });
      },
      beforeUpload: async (file) => {
        this.setState({ checkCsvImport: { status: "loading" } });
        try {
          this.setState({ checkCsvImport: { status: "success", data: file } });
          return file;
        } catch (err) {
          console.error(err);
          this.setState({
            checkCsvImport: { status: "error", error: new Error("Error") },
          });
          message.error(`Logo upload failed: ${err.message}`);
          throw new Error("`Logo upload failed");
        }
      },
    };

    const renderStep2 = () => {
      if (
        plannedTasks.status === "initial" ||
        plannedTasks.status === "loading"
      ) {
        return (
          <Feednack>
            <Loading />
          </Feednack>
        );
      }
      if (plannedTasks.status === "error") {
        return (
          <Feednack>
            An unexpected error happened, please contact your support
          </Feednack>
        );
      }
      return (
        <div>
          You are about to update{" "}
          {plannedTasks.data.filter((f) => f.type === "update").length} users
          and create{" "}
          {plannedTasks.data.filter((f) => f.type === "create").length} users.
        </div>
      );
    };

    const renderStep3 = () => {
      if (plannedTasks.status === "success") {
        const numberOfTask = plannedTasks.data.length;
        const progress = Math.round(
          (doneTasks.tasks.length / numberOfTask) * 100
        );
        const errors = doneTasks.tasks.filter((t) => t.status === "error");
        if (doneTasks.status === "done") {
          const numberOfErrors = errors.length;
          if (numberOfErrors > 0) {
            return (
              <Space
                direction="vertical"
                style={{ textAlign: "center", width: "100%" }}
              >
                <div>
                  <Progress
                    type="circle"
                    status="exception"
                    percent={progress}
                  />
                </div>
                <div>
                  <Typography.Text type="danger">
                    Your user bulk update action is done with {numberOfErrors}{" "}
                    errors. Download the log to check the errors.
                  </Typography.Text>
                </div>
                <Button
                  onClick={() => {
                    const options = {
                      fieldSeparator: ";",
                      quoteStrings: '"',
                      decimalSeparator: ".",
                      showLabels: true,
                      showTitle: false,
                      title: "Error log",
                      useTextFile: false,
                      useBom: true,
                      useKeysAsHeaders: true,
                      filename: "error_log",
                    };

                    const csvExporter = new ExportToCsv(options);
                    const data = errors.flatMap((e) => {
                      return (e.error || []).map((err) => {
                        return {
                          email: e.task.email,
                          error: err,
                        };
                      });
                    });
                    csvExporter.generateCsv(data);
                  }}
                >
                  Download error log
                </Button>
              </Space>
            );
          } else {
            return (
              <Space
                direction="vertical"
                style={{ textAlign: "center", width: "100%" }}
              >
                <div>
                  <Progress type="circle" percent={progress} />
                </div>
                <div>
                  <Typography.Text type="success">
                    Successfully updated your users!
                  </Typography.Text>
                </div>
              </Space>
            );
          }
        } else {
          return (
            <Space
              direction="vertical"
              style={{ textAlign: "center", width: "100%" }}
            >
              <Progress type="circle" percent={progress} />
            </Space>
          );
        }
      }
    };

    const renderInner = () => {
      if (step === "STEP_1") {
        return (
          <div className="bulk-upload">
            <Timeline mode="left">
              <Timeline.Item dot={<div className="bulk-upload-dot">1</div>}>
                <div>
                  <div>
                    <Typography.Title level={5}>
                      Download CSV file
                    </Typography.Title>
                  </div>
                  <Space>
                    <Button
                      loading={geneteCsvUsers.status === "loading"}
                      disabled={geneteCsvUsers.status === "loading"}
                      onClick={() => buildFilledTemplate()}
                    >
                      Download user info in CSV file
                    </Button>
                    <Button
                      loading={geneteCsvTemplate.status === "loading"}
                      disabled={geneteCsvTemplate.status === "loading"}
                      onClick={() => buildCsvTemplate()}
                    >
                      Download blank CSV template
                    </Button>
                  </Space>
                </div>
              </Timeline.Item>
              <Timeline.Item dot={<div className="bulk-upload-dot">2</div>}>
                <div>
                  <div>
                    <Typography.Title level={5}>
                      Add or edit user info in CSV template.
                    </Typography.Title>
                  </div>
                  <div>
                    Required fields are email address, and role. Email cannot be
                    changed for existing users
                  </div>
                </div>
              </Timeline.Item>
              <Timeline.Item dot={<div className="bulk-upload-dot">3</div>}>
                <div>
                  <div>
                    <Typography.Title level={5}>
                      Upload CSV file
                    </Typography.Title>
                  </div>
                  <div>
                    <Upload {...uploadProps}>
                      {checkCsvImport.status === "success" ? null : (
                        <Button loading={checkCsvImport.status === "loading"}>
                          Attach CSV file
                        </Button>
                      )}
                    </Upload>
                  </div>
                </div>
              </Timeline.Item>
            </Timeline>
          </div>
        );
      } else if (step === "STEP_2") {
        return <div className="bulk-upload">{renderStep2()}</div>;
      } else if (step === "STEP_3") {
        return <div className="bulk-upload">{renderStep3()}</div>;
      }
    };

    const onOkClick = () => {
      if (step === "STEP_1") {
        this.setState({ step: "STEP_2" });
        checkOperationDetails();
      }
      if (step === "STEP_2") {
        this.setState({ step: "STEP_3" });
        processTasks();
      }
      if (step === "STEP_3") {
        onDone();
        onClose();
      }
    };

    const okText = () => {
      if (step === "STEP_1") {
        return "Next";
      }
      if (step === "STEP_2") {
        return "Update";
      }
      if (step === "STEP_3") {
        return "Finish";
      }
    };

    const cancelText = () => {
      if (step === "STEP_1") {
        return "Cancel";
      }
      if (step === "STEP_2") {
        return "Previous";
      }
    };

    return (
      <Modal
        title="Bulk update users"
        okText={okText()}
        cancelText={cancelText()}
        cancelButtonProps={{
          style: { display: !cancelText() ? "none" : undefined },
        }}
        okButtonProps={{ disabled: checkCsvImport.status !== "success" }}
        onOk={onOkClick}
        onCancel={() => onClose()}
        maskClosable={false}
        open={open}
      >
        {renderInner()}
      </Modal>
    );
  }
}

export default compose<Props, IBulkUploadProps>(
  WithOrg,
  withAntUtils
)(BulkUpload);
