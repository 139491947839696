import { CatalogGenericModal } from "../../components/modal/CatalogModal/CatalogGenericModal";
import type { IExploration } from "../../interfaces/explorations";
import TemplatesPage from "../templates/list/TemplatesPageWrapper";
import ActionCatalogPage from "./actions/ActionCatalogPage";

import SourceCatalogPage from "./sources/SourceCatalogPage";
import WarehouseCatalogPage from "./warehouses/WarehouseCatalogPage";

type ICatalogModalProps =
  | IBaseCatalogModalProps
  | ITemplateCatalogModalProps
  | ISourceCatalogModalProps;

interface ITemplateCatalogModalProps extends IBaseCatalogModalProps {
  type: "template";
  onSuccess: (
    explorations: Array<Pick<IExploration, "id" | "slug">>
  ) => Promise<void>;
}

interface ISourceCatalogModalProps extends IBaseCatalogModalProps {
  type: "source";
  selectedWarehouseSlug: string;
}

interface IBaseCatalogModalProps {
  open: boolean;
  onClose: () => void;
  type: ICatalogType;
  onSuccess?: (
    explorations: Array<Pick<IExploration, "id" | "slug">>
  ) => Promise<void>;
}

type ICatalogType =
  | "warehouse"
  | "action"
  | "source"
  | "template"
  | "demo-integrations";

export function CatalogModal(props: ICatalogModalProps) {
  const { open, onClose, type, onSuccess } = props;

  const renderInner = () => {
    switch (type) {
      case "source":
        return (
          <SourceCatalogPage
            onClose={onClose}
            selectedWarehouseSlug={
              (props as ISourceCatalogModalProps).selectedWarehouseSlug
            }
          />
        );
      case "action":
        return <ActionCatalogPage onClose={onClose} />;
      case "template":
        return <TemplatesPage onSuccess={onSuccess} onClose={onClose} />;
      case "warehouse":
        return <WarehouseCatalogPage onClose={onClose} />;
    }
  };

  return (
    <CatalogGenericModal open={open} onClose={onClose}>
      {renderInner()}
    </CatalogGenericModal>
  );
}
