import type { SelectProps } from "antd";
import { Button, Col, Row } from "antd";
import type { FormItemProps } from "antd/lib/form";
import type { InputProps } from "antd/lib/input";
import type { FormikProps } from "formik";
import { Field, Form, Formik } from "formik";
import { inject, observer } from "mobx-react";
import * as React from "react";
import { compose } from "../../components/compose/WlyCompose";
import FormCard from "../../components/form/card/FormCard";
import Input from "../../components/form/elements/input/Input";
import Select from "../../components/form/elements/select/Select";
import { isRequired } from "../../components/form/validators/validators";
import UserAvatar from "../../components/user/avatar/UserAvatar";
import { handleGQLErrors } from "../../helpers/gqlHelpers";
import type { IUser } from "../../interfaces/user";
import { createUserAsset } from "../../services/AssetService";
import GraphQLService from "../../services/graphql/GraphQLService";
import type { UserStoreProps } from "../../store/userStore";
import LogoUploader from "../uploader/modal/LogoUploader";

interface IUserInfoFormProps {}

const F = Form as any;

interface InitialValues extends IUser {}

const gql = `
  mutation updateCurrentUser(
    $id: ID!
    $firstName: String!
    $lastName: String!
    $email: String!
    $locale: String!
  ) {
    updateUser(
      id: $id,
      data: {
        firstName: $firstName
        lastName: $lastName
        email: $email
        locale: $locale
      }
    ) {
      id
    }
  }
`;

type Props = IUserInfoFormProps & UserStoreProps;

class UserInfoForm extends React.Component<Props> {
  public onSubmit = (v: InitialValues) => {
    return GraphQLService(gql, v)
      .then(() => {
        return this.props.userStore.getUser();
      })
      .catch(handleGQLErrors());
  };

  public render() {
    const { userStore } = this.props;
    return (
      <Formik initialValues={userStore.user!} onSubmit={this.onSubmit}>
        {(p: FormikProps<InitialValues>) => {
          return (
            <F className="form-container">
              <FormCard
                left={<span>Profile</span>}
                right={
                  <Button
                    type="primary"
                    disabled={p.isSubmitting}
                    loading={p.isSubmitting}
                    htmlType="submit"
                  >
                    Save
                  </Button>
                }
              >
                <Row>
                  <Col style={{ paddingTop: 12 }} flex="100px">
                    <LogoUploader
                      size={88}
                      display={"circle"}
                      minWidth={256}
                      modalTitle={"Change your profile picture"}
                      onUpload={(fileName, formData) => {
                        return createUserAsset(
                          userStore.user.id,
                          fileName,
                          formData
                        ).then((r) => {
                          return r.data.filePath;
                        });
                      }}
                      onSave={(filePath) => {
                        return GraphQLService(
                          `
                    mutation updateUser($userId: ID!, $logo: String!) {
                      updateUser(id: $userId, data: { logo: $logo }) {
                        id
                      }
                    }
                  `,
                          {
                            userId: userStore.user.id,
                            logo: filePath,
                          }
                        ).then((r) => {
                          return userStore.getUser();
                        });
                      }}
                    >
                      <UserAvatar user={userStore.user} size={88} />
                    </LogoUploader>
                  </Col>
                  <Col flex="auto">
                    <Field
                      type="firstName"
                      name="firstName"
                      component={Input}
                      validate={isRequired}
                      inputProps={
                        {
                          placeholder: "John",
                          disabled: p.isSubmitting,
                        } as InputProps
                      }
                      formItemProps={
                        {
                          label: "First Name",
                          hasFeedback: true,
                          colon: false,
                          required: true,
                        } as FormItemProps
                      }
                    />
                    <Field
                      type="lastName"
                      name="lastName"
                      component={Input}
                      validate={isRequired}
                      inputProps={
                        {
                          placeholder: "Doe",
                          disabled: p.isSubmitting,
                        } as InputProps
                      }
                      formItemProps={
                        {
                          label: "Last Name",
                          hasFeedback: true,
                          colon: false,
                          required: true,
                        } as FormItemProps
                      }
                    />
                    <Field
                      type="email"
                      name="email"
                      component={Input}
                      validate={isRequired}
                      inputProps={
                        {
                          placeholder: "Doe",
                          disabled: true,
                        } as InputProps
                      }
                      formItemProps={
                        {
                          label: "Email Address",
                          hasFeedback: true,
                          colon: false,
                          required: true,
                        } as FormItemProps
                      }
                    />
                    <Field
                      type="locale"
                      name="locale"
                      component={Select}
                      validate={isRequired}
                      inputProps={
                        {
                          options: [
                            {
                              label: "English",
                              value: "en_US",
                            },
                            {
                              label: "French",
                              value: "fr_FR",
                            },
                          ],
                        } as SelectProps
                      }
                      formItemProps={
                        {
                          label: "Locale",
                          hasFeedback: true,
                          colon: false,
                          required: true,
                        } as FormItemProps
                      }
                    />
                  </Col>
                </Row>
              </FormCard>
            </F>
          );
        }}
      </Formik>
    );
  }
}

export default compose<Props, IUserInfoFormProps>()(
  inject("userStore")(observer(UserInfoForm))
);
