import type { IUserRealmType } from "../../interfaces/realm";
import type { IUser } from "../../interfaces/user";

const RealmStrengh = {
  citizen: 0,
  admin: 1,
} as { [key in IUserRealmType]: number };

export const hasRealmAccessBoolean = (
  accessLevel: IUserRealmType,
  user: IUser
) => {
  if (RealmStrengh[accessLevel] <= RealmStrengh[user.realm.type]) {
    return true;
  }
  return false;
};
