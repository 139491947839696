import type { FormInstance } from "antd";
import { Form, InputNumber, Select, Space } from "antd";
import type { IObject } from "../../../../../../../interfaces/object";
import { ChartOptionCollapse } from "../../../../../../chart-options/components/ChartOptionCollapse";
import ChartOptionLine from "../../../../../../chart-options/components/ChartOptionLine";
import { getObjectColumns } from "../../../../object/domain";
import type { IRecord } from "../../../domain";
import type { IWidget } from "../../domain";
import type { IWidgetMapConfig } from "./domain";

interface IWidgetMapEditorProps {
  widget: IWidget;
  object: IObject;
  record: IRecord;
  conf: IWidgetMapConfig;
  form: FormInstance;
}

export function WidgetMapEditor(props: IWidgetMapEditorProps) {
  const { object } = props;
  const availableColumns = getObjectColumns(object);

  return (
    <>
      <ChartOptionCollapse dark title="Map Configuration">
        <Space direction="vertical" style={{ width: "100%" }}>
          <ChartOptionLine
            items={[
              {
                content: "Latitude",
                flex: 1,
              },
              {
                content: (
                  <Form.Item noStyle name={["config", "options", "lat"]}>
                    <Select allowClear style={{ width: "100%" }}>
                      {availableColumns.map((ac) => {
                        return (
                          <Select.Option key={ac.data.key} value={ac.data.key}>
                            {ac.data.label}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                ),
                flex: 1,
              },
            ]}
          />
          <ChartOptionLine
            items={[
              {
                content: "Longitude",
                flex: 1,
              },
              {
                content: (
                  <Form.Item noStyle name={["config", "options", "long"]}>
                    <Select allowClear style={{ width: "100%" }}>
                      {availableColumns.map((ac) => {
                        return (
                          <Select.Option key={ac.data.key} value={ac.data.key}>
                            {ac.data.label}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                ),
                flex: 1,
              },
            ]}
          />
          <ChartOptionLine
            items={[
              {
                content: "Height",
                flex: 1,
              },
              {
                content: (
                  <Form.Item noStyle name={["config", "height"]}>
                    <InputNumber addonAfter={"px"} style={{ width: "100%" }} />
                  </Form.Item>
                ),
                flex: 1,
              },
            ]}
          />
        </Space>
      </ChartOptionCollapse>
    </>
  );
}
