import { Space, Tag, Tooltip } from "antd";
import type { AbstractTooltipProps } from "antd/lib/tooltip";
import { useEffect } from "react";
import EmojiRenderer from "../../../../../components/form/emoji-picker/EmojiRenderer";

interface IChartWarningProps extends AbstractTooltipProps {
  /**
   * List of warnings to display
   */
  explicit?: boolean;
  warnings: string[];
  size?: number;
}

export default function ChartWarnings(props: IChartWarningProps) {
  useEffect(() => {}, []);

  return (
    <Tooltip
      title={props.warnings.join(", ")}
      arrowPointAtCenter={true}
      placement={props.placement || "top"}
    >
      {props.explicit ? (
        <Tag color="gold">
          <Space>
            <EmojiRenderer
              size={props.size ? props.size : 14}
              emoji={":warning:"}
            />
            <span>
              {props.warnings.length} chart
              {props.warnings.length > 1 ? " warnings" : " warning"}
            </span>
          </Space>
        </Tag>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <EmojiRenderer
            size={props.size ? props.size : 18}
            emoji={":warning:"}
          />
        </div>
      )}
    </Tooltip>
  );
}
