import { Button, Col, Form, Input, Row, Tag, Typography } from "antd";
import type { Rule } from "antd/es/form";
import { useForm } from "antd/es/form/Form";
import { useState } from "react";
import type { InjectedAntUtilsProps } from "../../../../../../../components/ant-utils/withAntUtils";
import { withAntUtils } from "../../../../../../../components/ant-utils/withAntUtils";
import { WlyCard } from "../../../../../../../components/cards/WlyCard";
import { compose } from "../../../../../../../components/compose/WlyCompose";
import { SimpleFormModal } from "../../../../../../../components/form/modal/SimpleFormModal";
import type { IRadar } from "../../../../../../../interfaces/radar";
import type { UpdateRadarFunction } from "../../../../selector/radar/domain";

interface IRadarPersistenceCardProps {
  radar: IRadar;
  onUpdateRadar: UpdateRadarFunction;
}

type Props = IRadarPersistenceCardProps & InjectedAntUtilsProps;

const { Text } = Typography;

const validationRules: Rule[] = [
  {
    pattern: /^[a-z0-9A-Z-_]*$/,
    message: "Only alphanumeric and - _ are allowed",
  },
];

function RadarPersistenceCard(props: Props) {
  const { antUtils, radar, onUpdateRadar } = props;
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [editionOpen, setEditionOpen] = useState<boolean>(false);
  const [form] = useForm();

  return (
    <>
      <WlyCard
        title="Signals persistence"
        extra={
          <Button
            type="primary"
            size="small"
            onClick={() => setEditionOpen(true)}
          >
            Edit
          </Button>
        }
      >
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Text strong>Warehouse database</Text>
            <br />
            <Tag>{radar.warehouseDatabaseId}</Tag>
          </Col>
          <Col span={12}>
            <Text strong>Warehouse schema</Text>
            <br />
            <Tag>{radar.warehouseSchemaId}</Tag>
          </Col>
          <Col span={12}>
            <Text strong>Warehouse table</Text>
            <br />
            <Tag>{radar.warehouseTableId}</Tag>
          </Col>
        </Row>
      </WlyCard>
      <SimpleFormModal
        open={editionOpen}
        isSaving={isLoading}
        form={form}
        modalProps={{ title: "Update radar" }}
        onCancel={() => {
          setEditionOpen(false);
        }}
      >
        <Form
          layout="vertical"
          form={form}
          initialValues={{
            warehouseDatabaseId: radar.warehouseDatabaseId,
            warehouseSchemaId: radar.warehouseSchemaId,
            warehouseTableId: radar.warehouseTableId,
          }}
          onFinish={async (values) => {
            try {
              setIsLoading(true);
              await onUpdateRadar(radar.id, {
                warehouseDatabaseId: values.warehouseDatabaseId,
                warehouseSchemaId: values.warehouseSchemaId,
                warehouseTableId: values.warehouseTableId,
              });
              antUtils.message.success("Radar successfully updated");
            } catch (error) {
              console.error(error);
              antUtils.message.error("Error while updating radar");
            } finally {
              setIsLoading(false);
              setEditionOpen(false);
            }
          }}
        >
          <Form.Item
            label="Warehouse database"
            name={"warehouseDatabaseId"}
            rules={validationRules}
          >
            <Input placeholder="Warehouse database" allowClear />
          </Form.Item>
          <Form.Item
            label="Warehouse schema"
            name={"warehouseSchemaId"}
            rules={validationRules}
          >
            <Input placeholder="Warehouse schema" allowClear />
          </Form.Item>
          <Form.Item
            label="Warehouse table"
            name={"warehouseTableId"}
            rules={validationRules}
          >
            <Input placeholder="Warehouse table" allowClear />
          </Form.Item>
        </Form>
      </SimpleFormModal>
    </>
  );
}

export default compose<Props, IRadarPersistenceCardProps>(withAntUtils)(
  RadarPersistenceCard
);
