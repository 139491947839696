import { LockOutlined, StopOutlined } from "@ant-design/icons";
import { Button, Tooltip, Typography } from "antd";
import cuid from "cuid";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import React from "react";
import type { RouteComponentProps } from "react-router";
import { withRouter } from "react-router";
import { compose } from "../../../../../components/compose/WlyCompose";
import Aligner from "../../../../../components/layout/aligner/Aligner";
import Feednack from "../../../../../components/layout/feedback/feedback";
import type {
  IExploration,
  IExplorationSection,
} from "../../../../../interfaces/explorations";
import type { IReportFolder } from "../../../../../interfaces/folder";
import { IOrgFeatureType } from "../../../../../interfaces/org";
import type { IReport } from "../../../../../interfaces/reports";
import type { ISource } from "../../../../../interfaces/sources";
import { IUserRoleType } from "../../../../../interfaces/user";
import type { WorkspaceUIStoreProps } from "../../../../../store/workspaceUIStore";
import FolderShareForm from "../../../../folders/share/FolderShareForm";
import GraphQl from "../../../../graphql/graphql";
import type { InjectedOrgProps } from "../../../../orgs/WithOrg";
import WithOrg from "../../../../orgs/WithOrg";
import ReportCreation from "../../../../reports/creation/ReportCreation";
import HasRoleAccess, {
  hasRoleAccessBoolean,
} from "../../../../user-settings/HasRoleAccess";
import { ALL_REPORTS_GQL } from "../../../../workspace/reports/domain";
import RelatedFolders from "../../../../workspace/reports/folder/items/related-folders/RelatedFolders";
import Reports from "../../../../workspace/reports/folder/items/reports/Reports";
import { BORDER_COLOR } from "../../layout/domain";

interface IWorkspaceContext {
  folders: IReportFolder[];
  personalFolder?: IReportFolder;
  allExplorations: IExploration[];
  allExplorationSections: IExplorationSection[];
  allSources: ISource[];
  onFolderUpdate: (folder: IReportFolder) => void;
}

interface IFolderPageProps {
  context: IWorkspaceContext;
}

type Props = IFolderPageProps &
  RouteComponentProps<{ folderSlug?: string }> &
  WorkspaceUIStoreProps &
  InjectedOrgProps;

function FolderPage(props: Props) {
  const {
    match: {
      params: { folderSlug },
    },
    context,
    org,
    orgFeatures,
    user,
    workspaceUIStore: { setSelectedFolder },
  } = props;

  const [reportShareFormIsVisible, setReportShareFormIsVisible] =
    React.useState(false);
  const [rerender, setRerender] = React.useState(cuid());

  React.useEffect(() => {
    const f = () => {
      setRerender(cuid());
    };

    window.addEventListener("hasDroppedFolder", f);
    return () => window.removeEventListener("hasDroppedFolder", f);
  }, []);

  React.useEffect(() => {
    if (folderSlug) {
      const allFolders = [...context.folders];
      if (context.personalFolder) {
        allFolders.push(context.personalFolder);
      }
      const folder = allFolders.find((f) => f.slug === folderSlug);
      if (folder) {
        setSelectedFolder(folder.id);
      }
      return () => {
        setSelectedFolder();
      };
    }
  }, [folderSlug]);

  if (!folderSlug) {
    return (
      <Aligner>
        <Feednack>No folder selected</Feednack>
      </Aligner>
    );
  }

  const hasDNDaccess = hasRoleAccessBoolean(IUserRoleType.EDITOR, user, org.id);

  const allFolders = [...context.folders];
  if (context.personalFolder) {
    allFolders.push(context.personalFolder);
  }

  const folder = allFolders.find((f) => f.slug === folderSlug);
  if (!folder) {
    return (
      <Aligner>
        <Feednack>
          <div>You don't seem to have access to this folder.</div>
          <div> If you think this is a mistake, please contact your admin.</div>
        </Feednack>
      </Aligner>
    );
  }

  const relatedFolders = context.folders.filter(
    (f) => f.parent && f.parent.id === folder.id
  );

  const orgHasShareFeature = orgFeatures.includes(
    IOrgFeatureType.GRANULAR_REPORT_FOLDER_SHARING
  );
  const userIsFromOrgRealm = org.realm;

  const isPersonalFolder = !!folder.user?.id;

  return (
    <div
      id={rerender}
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        overflow: "hidden",
      }}
    >
      <div
        style={{
          borderBottom: `1px solid ${BORDER_COLOR}`,
          padding: `24px 24px 24px 24px`,
          flex: 0,
          display: "flex",
        }}
      >
        <div style={{ flex: 1 }}>
          <Typography.Title style={{ marginBottom: 0 }} level={4}>
            {folder.name}
          </Typography.Title>
        </div>
        <div style={{ flex: `0 0 auto` }}>
          <div>
            {!isPersonalFolder && user.type === "STANDARD" && (
              <>
                {!orgHasShareFeature ? (
                  <Tooltip title="Your plan doesn't have access to this feature yet. ">
                    <Button
                      onClick={() => {
                        setReportShareFormIsVisible(true);
                      }}
                      type="text"
                      style={{
                        marginRight: 8,
                        marginLeft: 8,
                      }}
                    >
                      <LockOutlined style={{ marginRight: 4 }} />
                      Share
                    </Button>
                  </Tooltip>
                ) : (
                  <>
                    {userIsFromOrgRealm ? (
                      <Button
                        onClick={() => {
                          setReportShareFormIsVisible(true);
                        }}
                        type="text"
                        style={{
                          marginRight: 8,
                          marginLeft: 8,
                        }}
                      >
                        Share
                      </Button>
                    ) : (
                      <Tooltip title="You don't belong to this organisation, hence you do not have access to its sharing configuration. Please contact your admin to update your access control if needed.">
                        <Button
                          disabled
                          onClick={() => {
                            setReportShareFormIsVisible(false);
                          }}
                          type="text"
                          style={{
                            marginRight: 8,
                            marginLeft: 8,
                          }}
                        >
                          <StopOutlined style={{ marginRight: 4 }} />
                          Share
                        </Button>
                      </Tooltip>
                    )}
                  </>
                )}
                <FolderShareForm
                  reportFolder={folder}
                  onClose={() => setReportShareFormIsVisible(false)}
                  visible={reportShareFormIsVisible}
                  canBeManagedByCurrentUser={folder.canBeManagedByCurrentUser}
                  orgHasFeature={orgHasShareFeature}
                />
              </>
            )}
            <HasRoleAccess accessLevel={IUserRoleType.EDITOR}>
              {folder.canBeEditedByCurrentUser && (
                <ReportCreation folder={folder} />
              )}
            </HasRoleAccess>
          </div>
        </div>
      </div>
      <div style={{ flex: 1, padding: 24, overflowY: "scroll" }}>
        {relatedFolders.length ? (
          <RelatedFolders
            shouldRerender={() => setRerender(cuid())}
            relatedFolders={relatedFolders}
          />
        ) : null}
        {folderSlug && (
          <GraphQl<{ allReports: Array<IReport> }>
            query={ALL_REPORTS_GQL}
            variables={{
              orgId: org.id,
              rerender: rerender,
              userId: user.id,
              folderSlug: folderSlug,
            }}
          >
            {(result) => {
              return (
                <Reports
                  reports={result}
                  onUpdate={() => setRerender(cuid())}
                  hasDNDaccess={hasDNDaccess}
                />
              );
            }}
          </GraphQl>
        )}
      </div>
    </div>
  );
}

export default compose<Props, IFolderPageProps>(
  withRouter,
  WithOrg,
  inject("workspaceUIStore"),
  observer
)(FolderPage);
