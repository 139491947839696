import {
  MoreOutlined,
  PushpinOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import type { InputRef } from "antd";
import { AutoComplete, Button, Dropdown, Flex, Input, theme } from "antd";
import { inject, observer } from "mobx-react";
import { useRef } from "react";
import { POLICIES } from "../../../../../../auth/authorization";
import { compose } from "../../../../../../components/compose/WlyCompose";
import { useFuseSearch } from "../../../../../../components/hooks/useFuseSearch";
import type { IObject, IObjectView } from "../../../../../../interfaces/object";
import { IUserRoleType } from "../../../../../../interfaces/user";
import type { ObjectUIStoreProps } from "../../../../../../store/objectUIStore";
import type { InjectedOrgProps } from "../../../../../orgs/WithOrg";
import WithOrg from "../../../../../orgs/WithOrg";
import { hasRoleAccessBoolean } from "../../../../../user-settings/HasRoleAccess";
import { renderObjectViewIcon } from "../domain";
import "./ObjectViews.scss";

interface IObjectViewsProps {
  activeView?: IObjectView;
  views: IObjectView[];
  object: IObject;
  setActiveView: (slug: string | undefined) => void;
  children?: React.ReactNode;
}

type Props = IObjectViewsProps & ObjectUIStoreProps & InjectedOrgProps;

type ListableObjectView = {
  title: string;
  slug: string;
  icon: React.ReactNode;
  active: boolean;
  isSystem: boolean;
  isDefault: boolean;
  isPersonal: boolean;
  canShare: boolean;
  canRename: boolean;
  canDelete: boolean;
  canPromote: boolean;
  setActive: () => void;
  onShare?: () => void;
  onRename?: () => void;
  onDelete?: () => void;
  onPromote?: () => void;
};

const { useToken } = theme;

function ObjectViews(props: Props) {
  const { activeView, object, objectUIStore, views, org, user, setActiveView } =
    props;
  const inputRef = useRef<InputRef>(null);
  const resultsRef = useRef<HTMLDivElement>(null);
  const { token } = useToken();

  const contentStyle: React.CSSProperties = {
    backgroundColor: token.colorBgElevated,
    borderRadius: token.borderRadiusLG,
    boxShadow: token.boxShadowSecondary,
  };

  const viewItems: ListableObjectView[] = [
    {
      slug: "default",
      title: `All ${object.name}`,
      icon: renderObjectViewIcon(undefined, { color: "gray" }),
      active: activeView === undefined,
      isSystem: true,
      isDefault: !object.defaultView || object.defaultView.isDeleted,
      isPersonal: false,
      setActive: () => setActiveView(undefined),
      canDelete: POLICIES["objectview:delete"]({
        user,
        orgId: org.id,
        view: "system",
      }),
      canRename: POLICIES["objectview:rename"]({
        user,
        orgId: org.id,
        view: "system",
      }),
      canShare: POLICIES["objectview:share"]({
        user,
        orgId: org.id,
        view: "system",
        object: object,
      }),
      canPromote: POLICIES["objectview:promote"]({
        user,
        orgId: org.id,
        view: "system",
      }),
      onPromote: () =>
        objectUIStore.setPromoteViewOpen({
          id: "system",
        }),
    },
    ...views.map((v) => ({
      slug: v.slug,
      title: v.name,
      icon: renderObjectViewIcon(v, { color: "gray" }),
      active: v.slug === activeView?.slug,
      isSystem: false,
      isDefault: object.defaultView?.id === v.id,
      isPersonal: v.isPersonal,
      setActive: () => setActiveView(v.slug),
      canDelete: POLICIES["objectview:delete"]({
        user,
        orgId: org.id,
        view: v,
      }),
      canRename: POLICIES["objectview:rename"]({
        user,
        orgId: org.id,
        view: v,
      }),
      canShare: POLICIES["objectview:share"]({
        user,
        orgId: org.id,
        view: v,
        object: object,
      }),
      canPromote: POLICIES["objectview:promote"]({
        user,
        orgId: org.id,
        view: v,
      }),
      onDelete: () => objectUIStore.setDeleteViewOpen({ id: v.id }),
      onRename: () =>
        objectUIStore.setRenameViewOpen({
          id: v.id,
          name: v.name,
        }),
      onShare: () =>
        objectUIStore.setShareViewOpen({
          id: v.id,
          isPersonal: v.isPersonal,
        }),
      onPromote: () =>
        objectUIStore.setPromoteViewOpen({
          id: v.id,
        }),
    })),
  ];

  const { search, setSearch, results } = useFuseSearch({
    items: viewItems,
    fuseOptions: {
      keys: ["title"],
      includeMatches: false,
      shouldSort: false,
    },
  });

  const renderItem = (item: ListableObjectView) => {
    const isBuilder = hasRoleAccessBoolean(IUserRoleType.BUILDER, user, org.id);

    return (
      <Flex
        align="center"
        id={`object-views-list-item-${item.slug}`}
        className={`object-views-list-item ${item.active ? "active" : ""}`}
      >
        <div style={{ paddingRight: 12 }}>
          {item.isDefault ? (
            <PushpinOutlined
              style={{
                color: "gray",
              }}
            />
          ) : (
            item.icon
          )}
        </div>
        <div style={{ flex: 1, overflow: "hidden", textOverflow: "ellipsis" }}>
          {item.title}
        </div>
        {(isBuilder || (!isBuilder && item.isPersonal)) && (
          <Dropdown
            trigger={["click"]}
            menu={{
              items: [
                {
                  key: "share",
                  label: "Set visibility",
                  style: item.canShare ? undefined : { display: "none" },
                  onClick: (e) => {
                    e.domEvent.preventDefault();
                    e.domEvent.stopPropagation();
                    item.onShare?.();
                  },
                },
                {
                  key: "promote",
                  label: "Set as default",
                  style: item.canPromote ? undefined : { display: "none" },
                  disabled: item.isDefault,
                  onClick: (e) => {
                    e.domEvent.preventDefault();
                    e.domEvent.stopPropagation();
                    item.onPromote?.();
                  },
                },
                {
                  type: "divider",
                  style:
                    item.canRename || item.canDelete
                      ? undefined
                      : { display: "none" },
                },
                {
                  key: "rename",
                  label: "Rename",
                  style: item.canRename ? undefined : { display: "none" },
                  onClick: (e) => {
                    e.domEvent.preventDefault();
                    e.domEvent.stopPropagation();
                    item.onRename?.();
                  },
                },
                {
                  key: "delete",
                  label: "Delete",
                  danger: true,
                  style: item.canDelete ? undefined : { display: "none" },
                  onClick: (e) => {
                    e.domEvent.preventDefault();
                    e.domEvent.stopPropagation();
                    item.onDelete?.();
                  },
                },
              ],
            }}
          >
            <Button
              type="text"
              shape="circle"
              size="small"
              icon={<MoreOutlined />}
              onClick={(e) => {
                e.stopPropagation();
              }}
            />
          </Dropdown>
        )}
      </Flex>
    );
  };

  const sections = [
    {
      label: "Public views",
      options: results
        .filter((r) => !r.isPersonal)
        .map((r) => ({
          label: renderItem(r),
          value: r.slug,
        })),
    },
    {
      label: "Personal views",
      options: results
        .filter((r) => r.isPersonal)
        .map((r) => ({
          label: renderItem(r),
          value: r.slug,
        })),
    },
  ].filter((section) => section.options.length);

  return (
    <Dropdown
      trigger={["click"]}
      overlayStyle={{ zIndex: 950 }}
      placement="bottomLeft"
      className="object-views"
      open={objectUIStore.selectViewOpen}
      onOpenChange={(open) => {
        if (open) {
          setTimeout(() => {
            const element = document.getElementById(
              `object-views-list-item-${activeView?.slug ?? "default"}`
            );
            element?.scrollIntoView({
              block: "center",
              inline: "center",
            });
            inputRef.current?.focus();
          }, 100);
        }
        if (!open) {
          setSearch(undefined);
        }
        objectUIStore.setSelectViewOpen(open);
      }}
      dropdownRender={() => {
        return (
          <div style={contentStyle}>
            <div className="object-views">
              <div
                style={{
                  width: 320,
                }}
              >
                <AutoComplete
                  getPopupContainer={() => resultsRef.current || document.body}
                  size="large"
                  style={{ width: "100%" }}
                  open
                  dropdownStyle={{
                    width: 308,
                    maxWidth: 308,
                    position: "unset",
                    boxShadow: "none",
                  }}
                  onChange={setSearch}
                  onKeyDown={(e) => {
                    if (e.key === "Escape") {
                      setSearch(undefined);
                      objectUIStore.setSelectViewOpen(false);
                    }
                  }}
                  value={search}
                  notFoundContent={"No matching views"}
                  options={sections}
                  onSelect={(value) => {
                    setSearch(undefined);
                    objectUIStore.setSelectViewOpen(false);
                    viewItems.find((v) => v.slug === value)?.setActive?.();
                  }}
                >
                  <div
                    style={{
                      padding: "3px 0",
                      borderBottom: "1px solid #D8D8D8",
                      borderTopLeftRadius: 6,
                      borderTopRightRadius: 6,
                    }}
                  >
                    <Input
                      value={search}
                      ref={inputRef}
                      placeholder="Search views"
                      onChange={(e) => setSearch(e.target.value)}
                      prefix={<SearchOutlined />}
                      variant="borderless"
                    />
                  </div>
                </AutoComplete>
                <div style={{ paddingLeft: 12, paddingBottom: 8 }}>
                  <div ref={resultsRef} />
                </div>
              </div>
            </div>
          </div>
        );
      }}
    >
      {props.children}
    </Dropdown>
  );
}

export default compose<Props, IObjectViewsProps>(
  WithOrg,
  inject("objectUIStore"),
  observer
)(ObjectViews);
