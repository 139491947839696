import { Space, Tag, Typography } from "antd";
import _ from "lodash";
import React from "react";
import type {
  ISetTableQueryAction,
  TableQuery,
} from "../../../../../../../../components/ag-grid/object-table/domain";
import type {
  IObject,
  IObjectQueryBuilderSection,
} from "../../../../../../../../interfaces/object";
import { ChartOptionCollapse } from "../../../../../../../chart-options/components/ChartOptionCollapse";
import { parseQueryBuilderSectionItemConfidProperty } from "../../../../../../../workbench/workbench/viewer/object/tabs/query-builder/domain";
import type { AvailableColumn } from "../../../../domain";
import type { IForeignValues } from "./items/foreign/ForeignItemRenderer";
import { ForeignItemRenderer } from "./items/foreign/ForeignItemRenderer";
import { OwnPropertyItemRenderer } from "./items/properties/OwnPropertyItemRenderer";

export interface IObjectQueryBuilderSectionProps {
  object: IObject;
  availableColumns: AvailableColumn[];
  referencedAvailableColumns: AvailableColumn[];
  section: IObjectQueryBuilderSection;
  tableQuery: TableQuery;
  setTableQuery: React.Dispatch<ISetTableQueryAction>;
}

export function ObjectQueryBuilderSection(
  props: IObjectQueryBuilderSectionProps
) {
  const {
    section,
    object,
    availableColumns,
    tableQuery,
    setTableQuery,
    referencedAvailableColumns,
  } = props;

  const allValues = tableQuery.queryBuilderItems || {};
  const allValuesKeys = Object.keys(tableQuery.queryBuilderItems || {});
  const allItemsKeys = section.items.map((i) => i.id);
  const itemIdsWithValues = _.intersection(allItemsKeys, allValuesKeys);
  const allAvailableColumns = [
    ...availableColumns,
    ...referencedAvailableColumns,
  ];

  const renderInner = () => {
    if (section.type === "OWN_PROPERTIES") {
      return (
        <Space direction="vertical" size="small" style={{ width: "100%" }}>
          {_.orderBy(section.items, ["order"]).map((si) => {
            return (
              <OwnPropertyItemRenderer
                availableColumns={allAvailableColumns}
                key={si.id}
                object={object}
                itemPropertyConfig={
                  parseQueryBuilderSectionItemConfidProperty(si.property)?.[0]
                }
                value={allValues[si.id]}
                onChange={(e) => {
                  const withRemovedValues = _.omit(allValues, [si.id]);
                  if (!e || e.length === 0 || e[0] === "WHALY_NO_FILTER") {
                    setTableQuery({
                      action: "setQueryBuilderItems",
                      queryBuilderItems: { ...withRemovedValues },
                    });
                  } else {
                    setTableQuery({
                      action: "setQueryBuilderItems",
                      queryBuilderItems: { ...withRemovedValues, [si.id]: e },
                    });
                  }
                }}
              />
            );
          })}
        </Space>
      );
    } else if (section.type === "FOREIGN_PROPERTIES") {
      const allItemIds = section.items.map((si) => si.id);
      const checkedValues = Object.keys(allValues).filter((k) =>
        allItemIds.includes(k)
      );
      return (
        <Space direction="vertical" size="small" style={{ width: "100%" }}>
          {_.orderBy(section.items, ["order"]).map((si) => {
            const isChecked = !!allValues[si.id];
            return (
              <div key={si.id}>
                <ForeignItemRenderer
                  checked={isChecked}
                  onChange={() => {
                    const withRemovedValues = _.omit(allValues, checkedValues);
                    if (isChecked) {
                      setTableQuery({
                        action: "setQueryBuilderItems",
                        queryBuilderItems: { ...withRemovedValues },
                      });
                    } else {
                      setTableQuery({
                        action: "setQueryBuilderItems",
                        queryBuilderItems: {
                          ...withRemovedValues,
                          [si.id]: [],
                        },
                      });
                    }
                  }}
                  values={
                    (allValues[si.id]?.[0] as any as IForeignValues) || {}
                  }
                  onValueChange={(v) => {
                    if (isChecked) {
                      setTableQuery({
                        action: "setQueryBuilderItems",
                        queryBuilderItems: {
                          ...allValues,
                          [si.id]: [v as any as string],
                        },
                      });
                    }
                  }}
                  availableColumns={allAvailableColumns}
                  object={object}
                  item={si}
                />
              </div>
            );
          })}
        </Space>
      );
    } else {
      return <div>Type {section.type} not supported</div>;
    }
  };

  const onClick = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.preventDefault();
    e.stopPropagation();
    const ret = _.omit(allValues, itemIdsWithValues);
    setTableQuery({
      action: "setQueryBuilderItems",
      queryBuilderItems: { ...ret },
    });
  };

  return (
    <ChartOptionCollapse
      title={
        <>
          <Typography.Text ellipsis>{section.name}</Typography.Text>

          {itemIdsWithValues.length > 0 && (
            <div style={{ position: "relative", display: "inline" }}>
              <Tag
                bordered={false}
                style={{
                  background: "#DBE0FD",
                  color: "#3f6ac4",
                  marginLeft: 8,
                  fontWeight: "initial",
                  position: "absolute",
                }}
                closable
                onClose={onClick}
                onClick={onClick}
              >
                {itemIdsWithValues.length}
              </Tag>
            </div>
          )}
        </>
      }
    >
      {renderInner()}
    </ChartOptionCollapse>
  );
}
