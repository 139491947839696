import * as React from "react";
import type { RouteComponentProps } from "react-router";
import { withRouter } from "react-router";
import { compose } from "../../../components/compose/WlyCompose";
import Aligner from "../../../components/layout/aligner/Aligner";
import Loading from "../../../components/layout/feedback/loading";
import type { AsyncCachedData } from "../../../helpers/typescriptHelpers";
import type { IExploration } from "../../../interfaces/explorations";
import type { IReport } from "../../../interfaces/reports";
import type { IWorkflow } from "../../../interfaces/schedule";
import GraphQLService from "../../../services/graphql/GraphQLService";
import type { InjectedOrgProps } from "../../orgs/WithOrg";
import WithOrg from "../../orgs/WithOrg";
import { getFilterDefaultValue, parseEmbedValue } from "../domain";
import ReportContent from "../view/ReportContent";
import {
  INITIAL_PRIVATE_LINK_GQL_QUERY,
  INITIAL_PUBLIC_LINK_GQL_QUERY,
  fetchUsedExplorationInReport,
} from "../view/domain";

import "../view/ReportView.scss";

interface IReportPublicViewProps {
  isEmbedded: boolean;
  isBeingPushed: boolean;
  fetchOnlyPublicReport?: boolean;
  hideLayout?: boolean;
  showTitle?: boolean;
  hideFilters?: boolean;
  defaultFilters?: { [key: string]: boolean | number | string };
  disableDrills?: boolean;
}

interface IState {
  report: AsyncCachedData<IReport>;
  explorations: AsyncCachedData<IExploration[]>;
}

type Props = IReportPublicViewProps &
  RouteComponentProps<{
    publicToken?: string;
    privateToken?: string;
    embedToken?: string;
    workflowId?: string;
  }> &
  InjectedOrgProps;

class ReportPublicView extends React.Component<Props, IState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      report: {
        status: "initial",
      },
      explorations: {
        status: "initial",
      },
    };
  }

  componentDidMount() {
    // this code sucks
    if (this.props.match.params.publicToken) {
      this.fetchData(this.props.match.params.publicToken, "public");
    } else if (this.props.match.params.privateToken) {
      this.fetchData(
        this.props.match.params.privateToken,
        "private",
        this.props.match.params.workflowId
      );
    } else if (this.props.match.params.embedToken) {
      this.fetchData(this.props.match.params.embedToken, "public");
    }
  }

  componentDidUpdate(prevProps: Props) {
    // this code sucks
    if (
      this.props.match.params.publicToken &&
      this.props.match.params.publicToken !== prevProps.match.params.publicToken
    ) {
      this.fetchData(this.props.match.params.publicToken, "public");
    } else if (
      this.props.match.params.privateToken &&
      this.props.match.params.privateToken !==
        prevProps.match.params.privateToken
    ) {
      this.fetchData(
        this.props.match.params.privateToken,
        "private",
        this.props.match.params.workflowId
      );
    } else if (
      this.props.match.params.embedToken !== prevProps.match.params.embedToken
    ) {
      this.fetchData(this.props.match.params.embedToken, "public");
    }
  }

  fetchData = (
    token: string,
    tokenType: "public" | "private",
    workflowId?: string
  ) => {
    const fetchOnlyPublicReport = this.props.fetchOnlyPublicReport;
    this.setState({
      report: { status: "initial" },
      explorations: { status: "initial" },
    });
    const query =
      tokenType === "public"
        ? INITIAL_PUBLIC_LINK_GQL_QUERY(fetchOnlyPublicReport)
        : INITIAL_PRIVATE_LINK_GQL_QUERY();

    const args =
      tokenType === "public" ? { publicToken: token } : { privateToken: token };

    return GraphQLService<{ allReports: IReport[]; Workflow: IWorkflow }>(
      query,
      {
        ...args,
        orgId: this.props.org.id,
        enableWorkflow: !!workflowId,
        workflowId: workflowId ? workflowId : "",
      }
    )
      .then((r) => {
        if ((r.allReports as IReport[]).length !== 1) {
          throw new Error("NOT_FOUND");
        }
        return r;
      })
      .then((resp) => {
        const r = resp.allReports[0] as IReport;
        const w = resp.Workflow;
        const additionalFilters: { [key: string]: boolean | number | string } =
          {};
        if (this.props.defaultFilters) {
          Object.keys(this.props.defaultFilters).forEach((k) => {
            additionalFilters[k] = this.props.defaultFilters[k];
          });
        }
        if (w && w.controlledValues) {
          w.controlledValues.forEach((cv) => {
            additionalFilters[cv?.filter?.apiName] = cv.value as any as string;
          });
        }
        const params = new Proxy(additionalFilters, {
          get: (searchParams, prop) => searchParams[prop as string],
        });

        return fetchUsedExplorationInReport(r, this.props.org.id).then((e) => {
          this.setState({
            report: {
              status: "success",
              data: {
                ...r,
                filters: r.filters.map((f) => {
                  let p = params[f.apiName];

                  if (p) {
                    return {
                      ...f,
                      defaultValue: parseEmbedValue(f, p),
                      rawDefaultValue: f.defaultValue as any,
                    };
                  }
                  return {
                    ...f,
                    defaultValue: getFilterDefaultValue(
                      f,
                      this.props.userAttributes
                    ),
                    rawDefaultValue: f.defaultValue as any,
                  };
                }),
              },
            },
            explorations: {
              status: "success",
              data: e,
            },
          });
        });
      })
      .catch((err) => {
        console.error(err);
        this.setState({
          report: {
            status: "error",
            error: err,
          },
          explorations: {
            status: "error",
            error: err,
          },
        });
      });
  };

  public renderContent = () => {
    const { report, explorations } = this.state;

    switch (report.status) {
      case "initial":
        return (
          <Aligner className="standard-bg">
            <Loading />
          </Aligner>
        );
      case "error":
        return (
          <Aligner className="standard-bg">
            This report either doesn't exists or is not public anymore...
          </Aligner>
        );
      case "loading":
      case "success":
        return (
          <ReportContent
            saving={() => ({})}
            isSaving={false}
            report={report.status === "success" ? report.data : report.cache!}
            editing={false}
            hideLayout={this.props.hideLayout}
            hideFilters={this.props.hideFilters}
            showTitle={this.props.showTitle}
            isEmbedded={this.props.isEmbedded}
            isSharingLink={false}
            isPublic={!this.props.isEmbedded && !this.props.isBeingPushed}
            isBeingPushed={this.props.isBeingPushed}
            disableDrills={this.props.disableDrills}
            isDisplayedInWorkspace={false}
            explorations={
              explorations.status === "success" ? explorations.data : []
            }
          />
        );
    }
  };

  public render() {
    return (
      <div className="standard-bg report-view">{this.renderContent()}</div>
    );
  }
}

export default compose<Props, IReportPublicViewProps>(
  withRouter,
  WithOrg
)(ReportPublicView);
