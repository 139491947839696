import { Tag, Typography } from "antd";
import type { ColumnsType } from "antd/lib/table";
import Table from "antd/lib/table";
import _ from "lodash";
import moment from "moment";
import React from "react";
import type { InjectedAntUtilsProps } from "../../components/ant-utils/withAntUtils";
import { withAntUtils } from "../../components/ant-utils/withAntUtils";
import { compose } from "../../components/compose/WlyCompose";
import type { AsyncData } from "../../helpers/typescriptHelpers";
import type { IJobExecution } from "../../interfaces/jobExecutions";
import type { LogEntry } from "../../services/logService";
import { getExecutionLogs } from "../../services/logService";
import type { InjectedOrgProps } from "../orgs/WithOrg";
import WithOrg from "../orgs/WithOrg";
import "./ExecutionLogsDrawer.scss";

interface IExecutionLogsRendererProps {
  selectedJobExecution?: IJobExecution;
  viewType?: "table" | "terminal";
}

const dedupLogs = (l: LogEntry) => {
  return `${l.timestamp}-${l.level}-${l.message}`;
};

const columns: ColumnsType<LogEntry> = [
  {
    title: "Date (UTC)",
    dataIndex: "timestamp",
    key: "timestamp",
    width: 210,
  },
  {
    title: "Level",
    key: "level",
    dataIndex: "level",
    width: 70,
    render: (_, log) => {
      let color = "blue";
      if (log.level === "info") {
        color = "blue";
      } else if (log.level === "warning") {
        color = "orange";
      } else if (log.level === "error") {
        color = "red";
      }
      return <Tag color={color}>{log.level}</Tag>;
    },
    filters: [
      {
        text: "info",
        value: "info",
      },
      {
        text: "warning",
        value: "warning",
      },
      {
        text: "error",
        value: "error",
      },
    ],
    onFilter: (value: string, record) => record.level.indexOf(value) === 0,
  },
  {
    title: "Message",
    dataIndex: "message",
    key: "message",
    ellipsis: true,
  },
];

type Props = IExecutionLogsRendererProps &
  InjectedOrgProps &
  InjectedAntUtilsProps;

const ExecutionLogsRenderer = (props: Props) => {
  const { antUtils, selectedJobExecution, org, viewType } = props;
  const [logs, setLogs] = React.useState<AsyncData<LogEntry[]>>({
    status: "initial",
  });
  const timeout = React.useRef(null);
  const intervalMs = 5000;

  const getCurrentLogs = () => {
    if (logs.status === "success") {
      return logs.data;
    }
    return [];
  };

  const fetchLog = async () => {
    try {
      const res = await getExecutionLogs(
        selectedJobExecution.identifier,
        org.id,
        {
          only: selectedJobExecution.status === "RUNNING" ? "live" : undefined,
        }
      );
      setLogs({
        status: "success",
        data: res.data.logs,
      });
    } catch (error) {
      antUtils.message.error("An error happened while fetching the logs");
      setLogs({ status: "error", error: error });
      console.error(error);
    } finally {
      if (selectedJobExecution.status === "RUNNING") {
        timeout.current = setTimeout(() => {
          fetchLog();
        }, intervalMs);
      }
    }
  };

  // initial load
  React.useEffect(() => {
    if (selectedJobExecution?.identifier) {
      setLogs({ status: "loading" });
      fetchLog();
    }
    return () => {
      if (timeout.current) {
        clearTimeout(timeout.current);
      }
    };
  }, [selectedJobExecution?.identifier]);

  const renderInner = (): JSX.Element => {
    const dedup = _.uniqBy(getCurrentLogs(), dedupLogs);
    const sorted = _.sortBy(dedup, "timestamp");
    const filtered = sorted.filter((entry) => !!entry.message);

    if (viewType === "terminal") {
      const bad_color = "#FF7B72";

      return (
        <div
          style={{
            backgroundColor: "#252a33",
            color: "#eee",
            height: "100%",
            overflowY: "auto",
            padding: 14,
          }}
        >
          {filtered.map((f) => {
            let color = "#A4D5FE";
            if (f.level === "info") {
              color = "#A4D5FE";
            } else if (f.level === "warn") {
              color = "#D2A8FF";
            } else if (f.level === "error") {
              color = "#FF7B72";
            }
            return (
              <div
                style={{ whiteSpace: "pre-wrap", overflowWrap: "anywhere" }}
                key={f.timestamp}
              >
                <Typography.Text style={{ color: color }}>
                  → {f.level} -
                </Typography.Text>{" "}
                <Typography.Text type="secondary" style={{ color: color }}>
                  {moment(f.timestamp).format()}
                </Typography.Text>
                {"\t"}
                <Typography.Text style={{ color: "#E6EDF3" }}>
                  {f.message}
                </Typography.Text>
              </div>
            );
          })}
        </div>
      );
    }

    return (
      <Table
        loading={logs.status !== "success"}
        pagination={false}
        columns={columns}
        dataSource={filtered.map((log, i) => {
          return {
            key: i,
            level: log.level,
            message: log.message,
            timestamp: log.timestamp,
          };
        })}
        size="small"
        scroll={{
          y: null,
        }}
        sticky={true}
        expandable={{
          expandedRowRender: (log) => (
            <p style={{ margin: 0 }}>{log.message}</p>
          ),
        }}
      />
    );
  };

  return renderInner();
};

export default compose<Props, IExecutionLogsRendererProps>(
  WithOrg,
  withAntUtils
)(ExecutionLogsRenderer);
