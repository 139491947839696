import * as React from "react";
import { compose } from "../../../../components/compose/WlyCompose";
import Error from "../../../../components/layout/feedback/error";
import Loading from "../../../../components/layout/feedback/loading";
import type { IActionMeta } from "../../../../interfaces/actions";
import type { ISchedule } from "../../../../interfaces/schedule";
import GraphQl from "../../../graphql/graphql";
import type { InjectedOrgProps } from "../../../orgs/WithOrg";
import WithOrg from "../../../orgs/WithOrg";
import SettingsWrapper from "../../SettingsWrapper";
import ActionHistoryTable from "./PushHistoryTable";

interface IPushPageProps {
  selectedKey: string;
}

interface IReturnValue {
  allSchedules: ISchedule[];
  allActionMetas: IActionMeta[];
}

const gql = `
query getSchedules(
    $orgId: ID!,
) {
  allActionMetas {
    slug
    publicInfo {
        logo
    }
  }
  allSchedules(sortBy: name_ASC, where:{org: { id: $orgId }, deleted_not: true, workflow: {deleted: false, report: {deleted: false}}}) {
    id
    name
    period
    atHour
    dailySendEvery
    weeklyOnDay
    monthlyDayOfMonthPreset
    monthlyDayOfMonthNumber
    timezone
    lastJobExecution {
      status
      errorText
    }
    workflow {
      id
      type
      emails
      contentFormat
      action {
        id
        name
        actionMeta
      }
      report {
        name
        slug
      }
      actionFormValues
    }
  }
}
`;

type Props = IPushPageProps & InjectedOrgProps;

class ActionHistoryPage extends React.Component<Props> {
  public render() {
    const { org } = this.props;
    return (
      <SettingsWrapper>
        <GraphQl<IReturnValue> query={gql} variables={{ orgId: org.id }}>
          {(gql) => {
            if (gql.status === "loading" || gql.status === "initial") {
              return <Loading />;
            }
            if (gql.status === "error") {
              return <Error />;
            }
            return (
              <ActionHistoryTable
                schedules={gql.data.allSchedules}
                actionMetas={gql.data.allActionMetas}
              />
            );
          }}
        </GraphQl>
      </SettingsWrapper>
    );
  }
}

export default compose<Props, IPushPageProps>(WithOrg)(ActionHistoryPage);
