/* eslint-disable no-nested-ternary */
import type { TimeDimensionGranularity } from "@cubejs-client/core";
import type Highcharts from "highcharts";
import type { SeriesTooltipOptionsObject } from "highcharts";
import moment from "moment";
import momentDurationFormatSetup from "moment-duration-format";
import type { ChartOption } from "../../../containers/chart-options/ChartOptions";
import type { IAnalysisType } from "../../../containers/exploration/single/domain";
import type { IMetricType } from "../../../interfaces/table";
import type { UserLocale } from "../../../interfaces/user";
import { LocaleService } from "../../../services/localeService";
import type { IComparisonPeriod } from "../../measures/comparison-selector/ComparisonSelector";
import type { Formatter } from "../domain";

momentDurationFormatSetup(moment as any);

export const customFormatter = (
  value: any,
  locale: UserLocale,
  formatter?: Formatter
): string => {
  if (formatter) {
    return `${formatter.prefix ? formatter.prefix : ""}${renderUnit(
      isNaN(+value) ? value : +value,
      locale,
      formatter.format,
      formatter.customFormatting
    )}${formatter.suffix ? formatter.suffix : ""}`;
  }
  return value.toString();
};

const formatDuration = (
  time: number,
  unit?: "milliseconds" | "seconds",
  customFormat?: string
): string => {
  if (!customFormat || customFormat === "") {
    const timeAPI = {
      DAYS:
        unit && unit === "milliseconds" ? 1000 * 60 * 60 * 24 : 60 * 60 * 24,
      HOURS: unit && unit === "milliseconds" ? 1000 * 60 * 60 : 60 * 60,
      MINUTES: unit && unit === "milliseconds" ? 1000 * 60 : 60,
      SECONDS: unit && unit === "milliseconds" ? 1000 : 1,
    };

    const getTimeRemaining = () => {
      const days = Math.floor(time / timeAPI.DAYS);
      time %= timeAPI.DAYS;
      var hours = Math.floor(time / timeAPI.HOURS);
      time %= timeAPI.HOURS;
      var minutes = Math.floor(time / timeAPI.MINUTES);
      time %= timeAPI.MINUTES;
      var seconds = Math.floor(time / timeAPI.SECONDS);

      return {
        days: days,
        hours: hours,
        minutes: minutes,
        seconds: seconds,
      };
    };

    const timeRemaining = getTimeRemaining();
    let string = `${timeRemaining.seconds}s`;
    if (
      timeRemaining.minutes > 0 ||
      timeRemaining.hours > 0 ||
      timeRemaining.days > 0
    ) {
      string = `${timeRemaining.minutes}m ${string}`;
      if (timeRemaining.hours > 0 || timeRemaining.days > 0) {
        string = `${timeRemaining.hours}h ${string}`;
        if (timeRemaining.days > 0) {
          string = `${timeRemaining.days}d ${string}`;
        }
      }
    }
    return string;
  } else {
    return moment.duration(time, unit).format(customFormat).toString();
  }
};

const renderUnit = (
  value: number,
  locale: UserLocale,
  unit?: IMetricType,
  customFormatting?: string
) => {
  const localeService = new LocaleService(locale);
  const numeral = localeService.getNumberDefaultFormatting();
  if (unit) {
    switch (unit) {
      case "NUMBER":
        return numeral(value).format(
          customFormatting ? customFormatting : "0,0[.]0(0)"
        );
      case "DURATION_MILLISECONDS":
        return formatDuration(value, "milliseconds", customFormatting);
      case "DURATION_SECONDS":
        return formatDuration(value, "seconds", customFormatting);
      case "PERCENT":
        return `${Math.round(value * 10000) / 100}%`;
      default:
        if (unit.type === "moment") {
          const v = value - new Date(value).getTimezoneOffset() * 60 * 1000;
          if (unit.value === "hour") {
            return moment.utc(v).local().format("MMMM DD YYYY, HH:mm");
          } else if (unit.value === "day" || unit.value === "week") {
            return moment.utc(v).local().format("MMMM DD, YYYY");
          } else if (unit.value === "month") {
            return moment.utc(v).local().format("MMMM YYYY");
          } else if (unit.value === "quarter") {
            return moment.utc(v).local().format("\\QQ YYYY");
          } else if (unit.value === "year") {
            return moment.utc(v).local().format("YYYY");
          } else {
            return moment.utc(v).local().format(unit.value);
          }
        }
    }
  }
  return numeral(value).format(
    customFormatting ? customFormatting : "0,0[.]0(0)"
  );
};

export const hasPredominentFormatter = (
  f: Formatter[]
): Formatter | undefined => {
  const uniqueValues = f
    .map((f) => JSON.stringify(f))
    .filter((v, i, s) => {
      return s.indexOf(v) === i;
    });
  if (uniqueValues.length === 1) {
    return JSON.parse(uniqueValues[0]);
  }
};

export interface IReferenceLine {
  value: number;
  color: string;
  dashed?: boolean;
  label?: string;
}

export const optionsHelper = (
  height: number,
  width: number | undefined,
  locale: UserLocale,
  scale?: "time" | "ordinal" | "numeric",
  y1Formatter?: Formatter,
  y2Formatter?: Formatter,
  xFormatter?: Formatter,
  config?: ChartOption,
  referenceLine?: IReferenceLine
): Highcharts.Options => {
  const renderType = () => {
    switch (scale) {
      case "time":
        return "datetime";
      case "ordinal":
        return "category";
      case "numeric":
        return "linear";
      default:
        return "datetime";
    }
  };
  const newScale = renderType();
  const labels = xFormatter
    ? {
        labels: {
          style: { fontSize: "12px" },
          formatter: (ctx: any) => {
            return customFormatter(ctx.value, locale, xFormatter);
          },
        },
        title: {
          text: config?.axis?.bottom?.label,
        },
      }
    : {
        title: {
          text: config?.axis?.bottom?.label,
        },
        labels: {
          style: { fontSize: "12px" },
          formatter: null,
        },
      };

  let stackLabelsEnabled: boolean = !!config?.["label-stacked"];

  if (!!config?.percent) {
    stackLabelsEnabled = false;
  }

  const yAxis: Highcharts.YAxisOptions[] = [
    {
      gridLineDashStyle: "Dash",
      gridLineColor: "#EEEDF2",
      title: {
        text: config?.axis?.left?.label,
      },
      min:
        typeof config?.axis?.left?.min === "number"
          ? config?.axis?.left?.min
          : null,
      max:
        typeof config?.axis?.left?.max === "number"
          ? config?.axis?.left?.max
          : null,
      tickInterval:
        typeof config?.axis?.left?.tickInterval === "number"
          ? config?.axis?.left?.tickInterval
          : undefined,
      labels: {
        formatter: (ctx) => {
          return customFormatter(ctx.value, locale, y1Formatter);
        },
        style: {
          fontSize: "12px",
        },
      },
      plotLines: referenceLine
        ? [
            {
              color: referenceLine.color,
              width: 2,
              dashStyle: "ShortDash",
              value: referenceLine.value,
              zIndex: 1000,
              label: referenceLine.label
                ? {
                    text: referenceLine.label,
                    style: {
                      color: referenceLine.color,
                      fontWeight: "bold",
                    },
                  }
                : undefined,
            },
          ]
        : [],
      stackLabels: {
        enabled: stackLabelsEnabled,
        style: {
          fontSize: "0.8em",
        },
        formatter: function () {
          if (stackLabelsEnabled === false) return null;
          return customFormatter(this.total, locale, y1Formatter);
        },
      },
    },
  ];

  if (config?.axis?.right?.enabled) {
    yAxis.push({
      gridLineDashStyle: "Dash",
      gridLineColor: "#EEEDF2",
      title: {
        text: config?.axis.right.label,
      },
      labels: {
        formatter: (ctx) => {
          return customFormatter(ctx.value, locale, y2Formatter);
        },
        style: {
          fontSize: "12px",
        },
      },
      opposite: true,
      min:
        typeof config?.axis?.right?.min === "number"
          ? config?.axis?.right?.min
          : null,
      max:
        typeof config?.axis?.right?.max === "number"
          ? config?.axis?.right?.max
          : null,
      tickInterval:
        typeof config?.axis?.right?.tickInterval === "number"
          ? config?.axis?.right?.tickInterval
          : undefined,
      alignTicks:
        typeof config?.axis?.right?.min === "number" ||
        typeof config?.axis?.right?.max === "number" ||
        typeof config?.axis?.right?.tickInterval === "number"
          ? false
          : true,
      gridLineWidth: 0,
    });
  }

  return {
    xAxis: {
      type: newScale,
      ...labels,
      gridLineWidth: 0,
      gridLineColor: "transparent",
      lineWidth: 0,
      tickWidth: 0,
    },
    legend: {
      enabled: !config?.["hide-legend"],
    },
    yAxis: yAxis,
    time: {
      getTimezoneOffset: (timestamp) => new Date(timestamp).getTimezoneOffset(),
    },
    tooltip: {
      shared: true,
      useHTML: true,
      outside: false,
      shadow: false,
      backgroundColor: "#2E363B",
      borderRadius: 5,
      style: {
        color: "white",
        fontSize: "0.85em",
      },
    },
    accessibility: {
      enabled: false,
    },
    chart: {
      reflow: false,
      animation: false,
      height: height,
      width: width,
      spacing: [20, 0, 10, 0],
      borderWidth: 0,
      backgroundColor: "transparent",
    },
    title: {
      text: undefined,
    },
    credits: {
      enabled: false,
    },
    plotOptions: {
      series: {
        animation: false,
      },
      column: {
        stacking: undefined,
        borderWidth: 0,
      },
      line: {
        lineWidth: 2,
        marker: {
          enabled: false,
        },
      },
      areaspline: {
        lineWidth: 2,
        marker: {
          enabled: false,
        },
      },
      spline: {
        lineWidth: 2,
        marker: {
          enabled: false,
        },
      },
      heatmap: {
        borderRadius: 3,
        pointPadding: 2,
        nullColor: "#F2F2F2",
      },
    },
  };
};

export const renderSerieTooltip = (options: {
  formatter?: Formatter;
  locale: UserLocale;
  data: { [key: string]: string | number | boolean }[];
  seriesLength: number;
  valueGetter?: "this.x" | "this.z" | "this.value" | "this.options.value";
  hideHeader?: boolean;
  hideSerieColor?: boolean;
  alternateSerieDisplayLabel?: string;
  displayValueInPercent?: boolean;
  hideZeros?: boolean;
  showTotal?: boolean;
  additionalLine?: {
    formatter: Formatter;
    hideSerieColor: boolean;
    alternateSerieDisplayLabel: string;
  };
  comparison?: {
    analysisType?: IAnalysisType;
    comparisonPeriod?: IComparisonPeriod;
    timeGranularity?: TimeDimensionGranularity;
  };
}): SeriesTooltipOptionsObject => {
  return {
    headerFormat: `<div style="overflow-y: auto; max-height: 300px;"><table>${
      options.hideHeader
        ? ""
        : '<tr><th colspan="3" style="padding-bottom: 8px;font-size:12px">{point.key}</th></tr>'
    }`,
    footerFormat: `</table></<div>`,
    pointFormatter: function () {
      let shouldHideLine: boolean = false;

      if (options.hideZeros) {
        if (options.valueGetter === "this.z" && (this as any).z === 0) {
          shouldHideLine = true;
        }
        if (options.valueGetter === "this.value" && this.value === 0) {
          shouldHideLine = true;
        }
        if (
          options.valueGetter === "this.options.value" &&
          this.options?.value === 0
        ) {
          shouldHideLine = true;
        }
        if (options.valueGetter === "this.x" && this.x === 0) {
          shouldHideLine = true;
        }
        if (this.y === 0) {
          shouldHideLine = true;
        }
      }
      let displayValue: string | null = customFormatter(
        options.valueGetter === "this.value"
          ? this.value
          : options.valueGetter === "this.options.value"
          ? this.options.value
          : options.valueGetter === "this.x"
          ? this.x
          : options.valueGetter === "this.z"
          ? (this as any).z
          : this.y,
        options.locale,
        options.formatter
      );

      let additionalDisplayValue = options.additionalLine
        ? customFormatter(
            this.y,
            options.locale,
            options.additionalLine.formatter
          )
        : null;

      if (options.displayValueInPercent) {
        displayValue =
          typeof this.percentage === "number"
            ? Number(this.percentage?.toFixed(1)) + "%"
            : null;
      }

      const formatX2 = (str: string) => {
        const utcOffset = moment(str).utcOffset();
        const date = moment(str).utc().add(utcOffset, "minutes");
        switch (options.comparison?.timeGranularity) {
          case "hour":
            return date.format("dddd, D MMM YYYY HH:mm");
          case "day":
            return date.format("dddd, D MMM YYYY");
          case "week":
            return date.format("[Week from ] dddd, D MMM YYYY");
          case "month":
            return date.format("MMMM YYYY");
          case "quarter":
            return date.format("MMMM YYYY");
          case "year":
            return date.format("YYYY");
          default:
            return date.format();
        }
      };

      const originalDate = moment(
        this.x - new Date(this.x).getTimezoneOffset() * 60 * 1000
      )
        .utc()
        .format("YYYY-MM-DDTHH:mm:SS.SSS");

      let comparedDate: string | null = null;

      // we have a time granularity, we are in a timeserie
      if (options.comparison?.timeGranularity) {
        comparedDate = formatX2(
          options.data.find((d) => d.x === originalDate)?.x2 as string
        );
      }
      // we have a comparison period without granularity, we are in category
      else if (options.comparison?.comparisonPeriod) {
        comparedDate = `previous ${
          options.comparison?.comparisonPeriod === "previous"
            ? "period"
            : options.comparison?.comparisonPeriod
        }`;
      }

      let totalValue: string | null = null;

      if (options.showTotal) {
        totalValue = customFormatter(
          (this as any).stackTotal,
          options.locale,
          options.formatter
        );
      }

      const valueLine = shouldHideLine
        ? ""
        : `<tr style="font-size:13px"><td style="color: ${this.series.color}">${
            options.hideSerieColor ? "" : "\u25CF"
          }</td>` +
          `<td>${
            options.alternateSerieDisplayLabel
              ? options.alternateSerieDisplayLabel
              : this.series.name
          }</td>
      <td style="text-align: right; padding-left: 10px"><b>${displayValue}</b></td></tr>`;

      const additionalLine =
        options.additionalLine && !shouldHideLine
          ? `<tr style="font-size:13px"><td style="color: ${
              this.series.color
            }">${options.additionalLine.hideSerieColor ? "" : "\u25CF"}</td>` +
            `<td>${
              options.additionalLine.alternateSerieDisplayLabel
                ? options.additionalLine.alternateSerieDisplayLabel
                : this.series.name
            }</td>
<td style="text-align: right; padding-left: 10px"><b>${additionalDisplayValue}</b></td></tr>`
          : "";

      const totalLine =
        options.showTotal &&
        totalValue &&
        this.series.index === options.seriesLength - 1
          ? `<tr><td style="padding-top: 8px;font-size:13px"></td>` +
            `<td style="padding-top: 8px;font-size:13px">Total</td>
  <td style="padding-top: 8px;font-size:13px;text-align: right; padding-left: 10px"><b>${totalValue}</b></td></tr>`
          : "";

      const comparisonLine = `${
        (options.comparison?.comparisonPeriod ||
          options.comparison?.timeGranularity) &&
        this.series.index === options.seriesLength - 1 &&
        this.series.userOptions.id?.endsWith(".previous")
          ? `<tr><td colspan="3" style="padding-top: 8px;font-size:12px">Comparing with ${comparedDate}</td></tr>`
          : ``
      }`;

      return [valueLine, additionalLine, totalLine, comparisonLine].join("");
    },
  };
};
