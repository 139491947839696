import {
  DeleteOutlined,
  EditOutlined,
  HolderOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import { Button, Card, Flex, Popconfirm, Space, Tag, Typography } from "antd";
import * as React from "react";
import { type DraggableProvidedDragHandleProps } from "react-beautiful-dnd";
import { useHover } from "../../../../../../../../components/hooks/useHover";
import { type IObjectQueryBuilderSection } from "../../../../../../../../interfaces/object";

export interface IObjectQueryBuilderSectionCardProps {
  section: IObjectQueryBuilderSection;
  dragHandleProps: DraggableProvidedDragHandleProps | undefined;
  onDelete: (id: string) => void;
  onEdit: () => void;
}

export function ObjectQueryBuilderSectionCard(
  props: IObjectQueryBuilderSectionCardProps
) {
  const { section, dragHandleProps, onDelete, onEdit } = props;
  const [hoverRef, isHover] = useHover<HTMLDivElement>();
  const [confirmOpen, setConfirmOpen] = React.useState<boolean>(false);

  return (
    <div ref={hoverRef}>
      <Card key={section.id} size="small">
        <Flex align="center" gap={8}>
          <div {...dragHandleProps}>
            <HolderOutlined style={{ cursor: "move" }} />
          </div>
          <div style={{ flex: 1 }}>
            <div>
              <Typography.Text strong>{section.name}</Typography.Text>
            </div>
            <div>
              <Space wrap>
                <Tag bordered={false} color="orange">
                  {section.type === "FOREIGN_PROPERTIES"
                    ? "Foreign properties"
                    : "Own properties"}
                </Tag>
                <Tag bordered={false} color="blue">
                  {section.type === "FOREIGN_PROPERTIES"
                    ? `${section.items.length} conditions`
                    : `${section.items.length} properties`}
                </Tag>
              </Space>
            </div>
          </div>
          <div style={{ height: 32 }}>
            <div hidden={!isHover && !confirmOpen}>
              <Button
                shape="circle"
                type="text"
                icon={<EditOutlined />}
                onClick={() => onEdit()}
              />
              <Popconfirm
                title="Delete the section"
                description="Are you sure to delete this section?"
                icon={<QuestionCircleOutlined style={{ color: "red" }} />}
                open={confirmOpen}
                onOpenChange={(open) => setConfirmOpen(open)}
                okText="Delete"
                okButtonProps={{ danger: true }}
                onConfirm={() => onDelete(section.id)}
              >
                <Button
                  shape="circle"
                  type="text"
                  icon={<DeleteOutlined />}
                  onClick={() => setConfirmOpen(true)}
                  danger
                />
              </Popconfirm>
            </div>
          </div>
        </Flex>
      </Card>
    </div>
  );
}
