import Markdoc from "@markdoc/markdoc";
import { Card, List, Space, Tag, Typography } from "antd";
import React from "react";
import { Link, withRouter, type RouteComponentProps } from "react-router-dom";
import { compose } from "../../../../../../../../../components/compose/WlyCompose";
import {
  type IObject,
  type IObjectRegisteredAction,
} from "../../../../../../../../../interfaces/object";
import { type IUser } from "../../../../../../../../../interfaces/user";
import { routeDescriptor } from "../../../../../../../../../routes/routes";
import { BORDER_COLOR } from "../../../../../../layout/domain";
import {
  isAvailableProperty,
  type AvailableColumn,
} from "../../../../../../object/domain";
import { type IRecord } from "../../../../../domain";
import { getParsedDoc } from "../../../../email-widgets/text/domain";
import { convertKeyToMarkdocVariable } from "../../../markdown/domain";
import { additionalComponents } from "../../../markdown/widget/markdoc-react-component/config";
import PropertyRenderer from "../../../properties/widget/PropertyRenderer";
import { productToRemove, recommendedStores } from "../../data";
import { extractImageUrl, type IWidgetParsedConfig } from "../../domain";
import SuggestedProductAvatar from "../SuggestedProductAvatar";

export interface IProductSuggestionDetailProps {
  object: IObject;
  data: IRecord;
  onSelect: () => void;
  conf: IWidgetParsedConfig;
  columns: AvailableColumn[];
  action?: IObjectRegisteredAction;
  user: IUser;
}

type Props = IProductSuggestionDetailProps & RouteComponentProps;

function ProductSuggestionDetail(props: Props) {
  const {
    conf,
    data,
    user,
    columns,
    object,
    match: { params },
  } = props;

  const source = conf.secondaryText ?? "";
  const formattedRecord = Object.keys(data).reduce((acc, v) => {
    return {
      ...acc,
      [convertKeyToMarkdocVariable(v)]: data[v],
    };
  }, {});

  const content = getParsedDoc(source, formattedRecord, user, columns);
  const html = Markdoc.renderers.react(content, React, additionalComponents);

  const badgeColumn = columns
    .filter(isAvailableProperty)
    .find((c) => c.data.key === conf.badgeKey);

  const labelColumn = columns
    .filter(isAvailableProperty)
    .find((c) => c.data.key === conf.labelKey);

  return (
    <div style={{ padding: `24px 40px` }}>
      <div
        style={{
          height: 1,
          width: "100%",
          backgroundColor: BORDER_COLOR,
          marginBottom: 24,
        }}
      />
      <div style={{ display: "flex", gap: 8 }}>
        <div style={{ width: 160, height: 160, flex: `0 160px` }}>
          <Space direction="vertical">
            <div>
              <SuggestedProductAvatar
                src={extractImageUrl(data, conf)}
                fallback={conf.fallbackImageSrc}
              />
            </div>
          </Space>
        </div>
        {labelColumn && (
          <div
            style={{
              flex: 1,
            }}
          >
            <div style={{ display: "flex" }}>
              <div style={{ flex: 1 }}>
                <Typography.Title style={{ marginBottom: 0 }} level={5}>
                  <PropertyRenderer
                    value={data[conf.labelKey]}
                    object={object}
                    column={labelColumn}
                  />
                </Typography.Title>

                <Typography.Text
                  type="secondary"
                  style={{ fontSize: 11 }}
                  ellipsis
                >
                  Ref: 11
                </Typography.Text>
              </div>
              <div style={{ flex: `0 auto`, textAlign: "right" }}>
                <div style={{ fontSize: 14 }}>
                  <strong>43,23€ vmh</strong>
                </div>

                <div>
                  <Typography.Text type="secondary" ellipsis>
                    4 facings
                  </Typography.Text>
                </div>
              </div>
            </div>

            <div style={{ paddingTop: 12 }}>
              Le jus tropical 90cl attire une clientèle à la recherche de
              saveurs exotiques et rafraîchissantes, augmentant ainsi la
              diversité de votre offre. Son format pratique convient
              parfaitement aux consommateurs soucieux de la commodité,
              favorisant des achats récurrents. En outre, sa présentation
              attrayante booste la visibilité en rayon, stimulant les ventes
              impulsives.
            </div>
            <div style={{ paddingTop: 12 }}>
              <div style={{ paddingBottom: 12 }}>
                <Typography.Text strong>Lien utiles</Typography.Text>
              </div>
              <div>
                <Typography.Paragraph>
                  <ul>
                    <li>
                      <a href={"#"}>Site web</a>
                    </li>
                    <li>
                      <a href={"#"}>Présentation de vente</a>
                    </li>
                  </ul>
                </Typography.Paragraph>
              </div>
            </div>
            <div style={{ paddingTop: 12 }}>
              <div style={{ paddingBottom: 12 }}>
                <Typography.Text strong>
                  Ce produit est aussi distribué
                </Typography.Text>
              </div>
              <div>
                <List
                  grid={{ gutter: 16, column: 2 }}
                  dataSource={recommendedStores}
                  renderItem={(item) => (
                    <List.Item>
                      <Link
                        to={routeDescriptor[
                          "object-record-centered"
                        ].renderRoute({
                          ...params,
                          objectSlug: "magasins-4jz00xv",
                          recordId: item.id,
                        })}
                      >
                        <Card styles={{ body: { padding: 4 } }} size="small">
                          <div
                            style={{
                              display: "flex",
                              gap: 8,
                              alignItems: "center",
                            }}
                          >
                            <div style={{ flex: `0 32px` }}>
                              <img
                                alt={item.name}
                                src={item.image}
                                style={{
                                  width: 32,
                                  height: 32,
                                  borderRadius: 32,
                                }}
                              />
                            </div>
                            <div style={{ flex: 1, overflow: "hidden" }}>
                              <Typography.Text ellipsis>
                                {item.name}
                              </Typography.Text>
                            </div>
                          </div>
                        </Card>
                      </Link>
                    </List.Item>
                  )}
                />
              </div>
            </div>
            <div style={{ paddingTop: 12 }}>
              <div style={{ paddingBottom: 12 }}>
                <Typography.Text strong>
                  Pour rentrer ce produit on peut aussi rogner sur
                </Typography.Text>
              </div>
              <List
                grid={{ gutter: 16, column: 2 }}
                dataSource={productToRemove}
                renderItem={(item) => (
                  <List.Item>
                    <Link
                      to={routeDescriptor["object-record-centered"].renderRoute(
                        {
                          ...params,
                          objectSlug: "magasins-4jz00xv",
                          recordId: item.id,
                        }
                      )}
                    >
                      <Card styles={{ body: { padding: 4 } }} size="small">
                        <div
                          style={{
                            display: "flex",
                            gap: 8,
                            alignItems: "center",
                          }}
                        >
                          <div style={{ flex: `0 32px` }}>
                            <img
                              alt={item.name}
                              src={item.image}
                              style={{
                                width: 54,
                                height: 54,
                                margin: -4,
                                paddingRight: 4,
                                borderRadius: 6,
                              }}
                            />
                          </div>
                          <div style={{ flex: 1, overflow: "hidden" }}>
                            <div>
                              <Typography.Text ellipsis>
                                {item.name}
                              </Typography.Text>
                            </div>
                            <div>
                              <Tag bordered={false} color="red">
                                {item.facing} facing
                              </Tag>
                            </div>
                          </div>
                        </div>
                      </Card>
                    </Link>
                  </List.Item>
                )}
              />
            </div>
            <div style={{ paddingTop: 12 }}>
              <div style={{ paddingBottom: 12 }}>
                <Typography.Text strong>Calendrier marketing</Typography.Text>
              </div>
              <div>
                <Typography.Paragraph>
                  <ul>
                    <li>Campagne TV nationale - 01 juillet au 31 aout</li>
                    <li>
                      Campagne Réseau Sociaux ciblés - Rhone Alpes - 01 juillet
                      au 31 aout
                    </li>
                  </ul>
                </Typography.Paragraph>
              </div>
            </div>
          </div>
        )}
      </div>
      <div
        style={{
          height: 1,
          width: "100%",
          backgroundColor: BORDER_COLOR,
          marginTop: 24,
        }}
      />
    </div>
  );
}

export default compose<Props, IProductSuggestionDetailProps>(withRouter)(
  ProductSuggestionDetail
);
