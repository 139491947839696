import type { RenderableTreeNode } from "@markdoc/markdoc";
import Markdoc from "@markdoc/markdoc";
import type { IUser } from "../../../../../../../interfaces/user";
import type { AvailableColumn } from "../../../../object/domain";
import type { IRecord } from "../../../domain";
import { parseLabel } from "../../../domain";
import { generateConfig } from "../../common/markdoc/domain";
import { convertKeyToMarkdocVariable } from "../../widgets/markdown/domain";

export interface IEmailWidgetTextConfig {
  content?: string;
  align?: "left" | "right" | "center";
  spacing?: "normal" | "compact";
}

export const getDefaultConfig = (
  conf: IEmailWidgetTextConfig,
  record: IRecord
): IEmailWidgetTextConfig => {
  return {
    content: conf.content ? conf.content : "",
    align: conf.align ? conf.align : "left",
    spacing: conf.spacing ? conf.spacing : "normal",
  };
};

export const getParsedDoc = (
  source: string,
  record: IRecord,
  user: IUser,
  availableColumns: AvailableColumn[],
  additionalTransform?: object
): RenderableTreeNode => {
  const formattedRecord = Object.keys(record).reduce((acc, v) => {
    return {
      ...acc,
      [convertKeyToMarkdocVariable(v)]: record[v],
    };
  }, {});

  const config = generateConfig(user, availableColumns);
  const ast = Markdoc.parse(source);
  const content = Markdoc.transform(ast, {
    ...config,
    ...additionalTransform,
    variables: formattedRecord,
  });
  return content;
};

export const getFormattedRecord = (
  columns: AvailableColumn[],
  record: IRecord
) => {
  return Object.keys(record).reduce<IRecord>((acc, v) => {
    const column = columns.find((c) => c.data.key === v);
    if (!column) {
      return acc;
    }

    const format = () => {
      if (column.type === "property" && column.data.type !== "standard") {
        const parsedValue = parseLabel(record[v] as string);
        return parsedValue.name ? parsedValue?.name : parsedValue.id;
      }
      return record[v];
    };

    return {
      ...acc,
      [v]: format(),
    };
  }, {});
};
