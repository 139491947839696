import { Form, Input, Modal } from "antd";
import { useForm } from "antd/es/form/Form";
import React, { useEffect } from "react";
import type { InjectedAntUtilsProps } from "../../../../../../components/ant-utils/withAntUtils";
import { withAntUtils } from "../../../../../../components/ant-utils/withAntUtils";
import { compose } from "../../../../../../components/compose/WlyCompose";

interface IRenameConnectorModalProps {
  open: boolean;
  initialValue?: RenameConnectorFormValue;
  onClose: () => void;
  onRename: (values: RenameConnectorFormValue) => Promise<any>;
}

interface RenameConnectorFormValue {
  name: string;
}

type Props = IRenameConnectorModalProps & InjectedAntUtilsProps;

function RenameConnectorModal(props: Props) {
  const { open, onClose, onRename, initialValue, antUtils } = props;
  const [form] = useForm<RenameConnectorFormValue>();
  const [loading, setLoading] = React.useState<boolean>(false);

  useEffect(() => {
    if (open) {
      form.setFieldValue("name", initialValue.name);
    }
  }, [open]);

  return (
    <Modal
      open={open}
      title={"Rename Function"}
      onOk={async () => form.submit()}
      onCancel={onClose}
      confirmLoading={loading}
    >
      <Form
        form={form}
        initialValues={initialValue}
        layout="vertical"
        onFinish={async (v) => {
          try {
            setLoading(true);
            await form.validateFields();
            await onRename(v);
            onClose();
          } catch (err) {
            console.error(err);
            antUtils.message.error(
              "An unexpected error happened, please retry"
            );
          } finally {
            setLoading(false);
          }
        }}
      >
        <Form.Item
          required
          rules={[{ required: true }]}
          label={"Name"}
          name="name"
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default compose<Props, IRenameConnectorModalProps>(withAntUtils)(
  RenameConnectorModal
);
