import Icon from "@ant-design/icons";
import type { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";

export const WlyHashtagIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon
    component={() => (
      <svg
        width="1em"
        height="1em"
        viewBox="0 0 213 213"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M56.2922 135.158C49.2359 135.158 49.2359 124.286 56.2922 124.286H74.7469L81.2604 89.4958H56.2922C49.2359 89.4958 49.2359 78.6239 56.2922 78.6239H82.8888L86.6883 56.3364C88.3166 49.2697 98.6296 51.444 97.544 58.5108L94.2873 78.6239H129.026L132.825 56.3364C133.911 49.2697 144.766 51.444 143.681 58.5108L139.881 78.6239H156.708C163.764 78.6239 163.764 89.4958 156.708 89.4958H138.253L131.74 124.286H156.708C163.764 124.286 163.764 135.158 156.708 135.158H130.111L125.769 157.445C124.683 164.512 114.37 162.881 115.456 155.815L118.713 135.158H83.9743L80.1748 157.445C79.0892 164.512 68.2335 162.881 69.3191 155.815L73.1186 135.158H56.2922ZM86.1455 124.286H120.884L126.855 89.4958H92.1161L86.1455 124.286Z"
          fill="#5D6671"
        />
      </svg>
    )}
    {...props}
  />
);
