import type { FormInstance } from "antd";
import { Form, Input, Select, Space, Typography } from "antd";
import type { IObject } from "../../../../../../../../interfaces/object";
import { ChartOptionCollapse } from "../../../../../../../chart-options/components/ChartOptionCollapse";
import ChartOptionLine from "../../../../../../../chart-options/components/ChartOptionLine";
import { getObjectColumns } from "../../../../../object/domain";
import type { IRecord } from "../../../../domain";
import type { IWidget } from "../../../domain";
import type { IWidgetTitleConfig } from "../domain";

interface TitleWidgetEditorProps {
  widget: IWidget;
  object: IObject;
  record: IRecord;
  conf: IWidgetTitleConfig;
  form: FormInstance;
}

export function TitleWidgetEditor(props: TitleWidgetEditorProps) {
  const { widget, object, record, conf, form } = props;

  const objectColumns = getObjectColumns(object).filter(
    (o) => !o.data.key.endsWith(".label")
  );

  return (
    <>
      <ChartOptionCollapse dark title="Alert Configuration">
        <Space size={"middle"} direction="vertical" style={{ width: "100%" }}>
          <ChartOptionLine
            items={[
              {
                content: <div>Size</div>,
                flex: `0`,
              },
              {
                content: (
                  <Form.Item name={["config", "level"]} noStyle>
                    <Select optionLabelProp="label" style={{ width: "100%" }}>
                      <Select.Option label={"XXL"} value={1}>
                        <Typography.Title style={{ marginBottom: 0 }} level={1}>
                          XXL
                        </Typography.Title>
                      </Select.Option>
                      <Select.Option label={"XL"} value={2}>
                        <Typography.Title style={{ marginBottom: 0 }} level={2}>
                          XL
                        </Typography.Title>
                      </Select.Option>
                      <Select.Option label={"L"} value={3}>
                        <Typography.Title style={{ marginBottom: 0 }} level={3}>
                          L
                        </Typography.Title>
                      </Select.Option>
                      <Select.Option label={"M"} value={4}>
                        <Typography.Title style={{ marginBottom: 0 }} level={4}>
                          M
                        </Typography.Title>
                      </Select.Option>
                      <Select.Option label={"S"} value={5}>
                        <Typography.Title style={{ marginBottom: 0 }} level={5}>
                          S
                        </Typography.Title>
                      </Select.Option>
                    </Select>
                  </Form.Item>
                ),
                flex: `1`,
              },
            ]}
          />
          <ChartOptionLine
            items={[
              {
                content: <div>Title</div>,
                flex: `1`,
              },
              {
                content: (
                  <Form.Item name={["config", "text"]} noStyle>
                    <Input />
                  </Form.Item>
                ),
                flex: `0 auto`,
              },
            ]}
          />
        </Space>
      </ChartOptionCollapse>
    </>
  );
}
