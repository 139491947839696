import { List, Typography } from "antd";
import Feednack from "../../../../components/layout/feedback/feedback";
import Loading from "../../../../components/layout/feedback/loading";
import UserAvatar from "../../../../components/user/avatar/UserAvatar";
import type { ITopic } from "../../../../interfaces/topic";
import { useGetTopicSubscribers } from "./api/useGetTopicSubscribers";

interface INotificationTopicUserListProps {
  open: boolean;
  topic: ITopic & { _subscribersMeta?: { count?: number } };
}

type Props = INotificationTopicUserListProps;

function NotificationTopicUserList(props: Props) {
  const { open, topic } = props;

  const { data, loading, error } = useGetTopicSubscribers({
    variables: { topicId: topic.id },
    enabled: open,
  });

  if (loading)
    return (
      <Feednack>
        <Loading />
      </Feednack>
    );

  if (error) return <Feednack>{error.message}</Feednack>;

  const subscribers = (data?.Topic.subscribers ?? []).flatMap((s) =>
    s.user ? [s.user] : []
  );

  if (subscribers.length === 0) {
    return <Feednack>No subscribers</Feednack>;
  }

  return (
    <div style={{ maxHeight: 260, overflow: "hidden auto" }}>
      <List
        dataSource={subscribers}
        renderItem={(item) => {
          return (
            <List.Item>
              <List.Item.Meta
                avatar={<UserAvatar user={item} size={"large"} />}
                title={
                  <Typography.Text strong>
                    {item.firstName} {item.lastName}
                  </Typography.Text>
                }
                description={item.email}
              />
            </List.Item>
          );
        }}
      />
    </div>
  );
}

export default NotificationTopicUserList;
