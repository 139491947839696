import { LayoutOutlined, MoreOutlined } from "@ant-design/icons";
import { Button, Dropdown, List, Space } from "antd";
import { inject, observer } from "mobx-react";
import * as React from "react";
import type { InjectedAntUtilsProps } from "../../../../../components/ant-utils/withAntUtils";
import { withAntUtils } from "../../../../../components/ant-utils/withAntUtils";
import { compose } from "../../../../../components/compose/WlyCompose";
import { WlyDynamicIcon } from "../../../../../components/icons/dynamic-renderer/WlyDynamicIconRenderer";
import { IconHolder } from "../../../../../components/icons/holder/IconHolder";
import type { IObject } from "../../../../../interfaces/object";
import type { WorkbenchUIStoreProps } from "../../../../../store/workbenchUIStore";
import workbenchUIStore from "../../../../../store/workbenchUIStore";
import type { IActiveObject } from "../../domain";
import RenameObjectModal from "../../viewer/object/modals/RenameObjectModal";
import "./ObjectTable.scss";
import type { UpdateObjectFunction } from "./domain";

interface IObjectTableProps {
  objects: IObject[];
  activeObject?: IActiveObject;
  onActiveObjectChange: (activeObject: IActiveObject) => void;
  onUpdateObject: UpdateObjectFunction;
}

type Props = IObjectTableProps & WorkbenchUIStoreProps & InjectedAntUtilsProps;

function ObjectTable(props: Props) {
  const {
    activeObject,
    onActiveObjectChange,
    objects,
    antUtils,
    onUpdateObject,
  } = props;

  const [renameOpen, setRenameOpen] = React.useState<IObject | undefined>(
    undefined
  );

  return (
    <>
      <List<IObject>
        itemLayout="horizontal"
        dataSource={objects.slice().sort(function (a, b) {
          if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }
          return 0;
        })}
        className="object-table-list"
        renderItem={(object) => {
          return (
            <List.Item
              className={`object-table-list-item ${
                activeObject &&
                activeObject.type === "object" &&
                activeObject.value === object.id
                  ? "active"
                  : ""
              }`}
              actions={[
                <Space
                  className="ant-list-item-action"
                  size="small"
                  key="space"
                >
                  <Dropdown
                    trigger={["click"]}
                    menu={{
                      items: [
                        {
                          label: "Rename",
                          key: "rename",
                          onClick: (e) => {
                            e.domEvent.stopPropagation();
                            setRenameOpen(object);
                          },
                        },
                        {
                          label: "Delete",
                          key: "delete",
                          onClick: (e) => {
                            e.domEvent.stopPropagation();
                            return antUtils.modal.confirm({
                              title: "Do you want to proceed?",
                              content:
                                "You are about to delete this object, this cannot be undone. Do you want to proceed ?",
                              onCancel: () => undefined,
                              okButtonProps: {
                                danger: true,
                              },
                              onOk: async () => {
                                try {
                                  await onUpdateObject(object.id, {
                                    isDeleted: true,
                                  });
                                  workbenchUIStore.removeActiveObject({
                                    type: "object",
                                    value: object.id,
                                  });
                                  antUtils.message.success("Object deleted");
                                } catch (error) {
                                  antUtils.message.error(
                                    "Error while deleting object. Please try again"
                                  );
                                }
                              },
                            });
                          },
                          danger: true,
                        },
                      ],
                    }}
                    placement={"bottomRight"}
                  >
                    <Button
                      size="small"
                      type="text"
                      shape="circle"
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    >
                      <MoreOutlined />
                    </Button>
                  </Dropdown>
                </Space>,
              ]}
              onClick={() =>
                onActiveObjectChange({
                  type: "object",
                  value: object.id,
                })
              }
            >
              <List.Item.Meta
                avatar={
                  <IconHolder
                    icon={
                      object.icon ? (
                        <WlyDynamicIcon
                          name={object.icon}
                          fallback={<LayoutOutlined />}
                        />
                      ) : (
                        <LayoutOutlined />
                      )
                    }
                    size={20}
                    background={object.color ?? "#F1BD6C"}
                    color={"#F9F8F8"}
                  />
                }
                title={
                  <div className="object-table-list-item-title">
                    {object.name}
                  </div>
                }
              />
            </List.Item>
          );
        }}
      />
      <RenameObjectModal
        open={!!renameOpen}
        onClose={() => setRenameOpen(undefined)}
        onRename={async (values) => {
          try {
            if (renameOpen) {
              await onUpdateObject(renameOpen.id, {
                name: values.name,
                icon: values.colorIcon?.icon,
                color: values.colorIcon?.color,
              });
              antUtils.message.success("Object renamed");
            }
          } catch (error) {
            antUtils.message.error(
              "Error while renaming object. Please try again"
            );
          }
        }}
        initialValue={
          renameOpen
            ? {
                name: renameOpen.name,
                colorIcon: {
                  color: renameOpen.color,
                  icon: renameOpen.icon,
                },
              }
            : undefined
        }
      />
    </>
  );
}

export default compose<Props, IObjectTableProps>(
  inject("workbenchUIStore"),
  observer,
  withAntUtils
)(ObjectTable);
