import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Form, Input, Select, Space } from "antd";
import cuid from "cuid";
import * as React from "react";
import { WlyCard } from "../../../../../../../../components/cards/WlyCard";
import { compose } from "../../../../../../../../components/compose/WlyCompose";
import type {
  TableGroupOperation,
  Transformation,
} from "../../../../../../../../interfaces/transformations";
import type { InjectedOrgProps } from "../../../../../../../orgs/WithOrg";
import WithOrg from "../../../../../../../orgs/WithOrg";
import TypeRenderer from "../../../../../../../spreadsheet/renderer/TypeRenderer";
import {
  catchErrors,
  validateColumnName,
} from "../../../../../../../transformations/domain";
import type { FlowOperationFormProps } from "../domain";

const { Option } = Select;

const id = cuid();

type GroupByFormProps = FlowOperationFormProps<{
  var: string;
  operation: TableGroupOperation;
  domain: "viewResolver";
}>;

type Props = GroupByFormProps & InjectedOrgProps;

interface FormValue {
  group_on: string[];
  aggregate_on: {
    column: string;
    name: string;
    operator:
      | "COUNT"
      | "SUM"
      | "MAX"
      | "MIN"
      | "AVG"
      | "FIRST_VALUE"
      | "FIRST_VALUE_IGNORE_NULLS";
  }[];
}

const GroupByForm: React.FunctionComponent<Props> = (props) => {
  const {
    currentTransformation,
    prevStepSchema,
    isStale,
    onCancel,
    onSave,
    setFormInstance,
    setSubmitting,
  } = props;

  const [form] = Form.useForm();

  React.useEffect(() => {
    if (setFormInstance) {
      setFormInstance(form);
    }
  }, [setFormInstance, form]);

  const isFormDisabled = !onSave;

  const initialValues: FormValue = {
    group_on: currentTransformation.operation.args.keys,
    aggregate_on: currentTransformation.operation.args.aggregatedColumns.map(
      (a) => {
        return {
          column: a.aggregatedColumn,
          name: a.destinationColumnName,
          operator: a.aggregationType,
        };
      }
    ),
  };

  const getUsedColumnNames = (columnToExclude?: string): string[] => {
    const columns = [];
    const fieldValues: FormValue = form.getFieldsValue();
    const columnsInGroupBy = fieldValues.group_on;
    const columnsInAggregations = fieldValues.aggregate_on?.filter(
      (c) => c && c.name
    );

    if (
      columnsInGroupBy &&
      Array.isArray(columnsInGroupBy) &&
      columnsInGroupBy.length > 0
    ) {
      columns.push(...columnsInGroupBy);
    }

    if (
      columnsInAggregations &&
      Array.isArray(columnsInAggregations) &&
      columnsInAggregations.length > 0
    ) {
      columns.push(...columnsInAggregations.map((c) => c.name));
    }

    const index = columns.findIndex((name) => name === columnToExclude);
    if (index > -1) {
      columns.splice(index, 1);
    }
    return columns;
  };

  return (
    <Form
      layout="vertical"
      form={form}
      initialValues={initialValues}
      onFieldsChange={() => {
        isStale && isStale(true);
      }}
      onFinish={async (v: FormValue) => {
        try {
          setSubmitting(true);
          const newTransformation: Transformation = {
            ...currentTransformation,
            operation: {
              ...currentTransformation.operation,
              args: {
                ...currentTransformation.operation.args,
                keys: v.group_on,
                aggregatedColumns: v.aggregate_on.map((a) => {
                  return {
                    aggregatedColumn: a.column,
                    aggregationType: a.operator,
                    destinationColumnName: a.name,
                  };
                }),
              },
            },
          };

          await onSave([
            {
              type: "UPDATE",
              transformation: newTransformation,
            },
          ]);
          setSubmitting(false);

          if (onCancel) {
            onCancel();
          }
        } catch (err) {
          setSubmitting(false);
          catchErrors(err, id);
          console.warn(err);
        }
      }}
    >
      <Form.Item
        label="Group on..."
        name="group_on"
        required={true}
        rules={[
          {
            validator: async (_, group) => {
              if (!group || group.length < 1) {
                return Promise.reject(new Error("At least 1 column required"));
              }
            },
          },
        ]}
      >
        <Select
          mode="multiple"
          disabled={isFormDisabled}
          allowClear
          style={{ width: "100%" }}
          placeholder="Select the columns to group on"
          showSearch={true}
          optionFilterProp="key"
          popupMatchSelectWidth={false}
        >
          {Object.keys(prevStepSchema).map((k) => {
            return (
              <Select.Option key={k} value={k}>
                <div className="demo-option-label-item">
                  <span role="img" style={{ marginRight: 5 }}>
                    <TypeRenderer
                      domain={prevStepSchema[k].domain}
                      formula={
                        prevStepSchema[k].operation === "Table.AddColumn"
                      }
                    />
                  </span>
                  {prevStepSchema[k].label ? prevStepSchema[k].label : k}
                </div>
              </Select.Option>
            );
          })}
        </Select>
      </Form.Item>
      <p>Then, aggregate on ...</p>
      <Form.List name="aggregate_on">
        {(fields, { add, remove }, { errors }) => (
          <Space direction="vertical" style={{ width: "100%" }}>
            <Form.ErrorList errors={errors} />
            {fields.map(({ key, name, ...restField }) => (
              <WlyCard
                key={key}
                title={"Aggregation"}
                size="small"
                extra={<DeleteOutlined onClick={() => remove(name)} />}
              >
                <Form.Item label="Aggregation">
                  <Input.Group compact>
                    <Form.Item
                      {...restField}
                      name={[name, "operator"]}
                      noStyle
                      rules={[
                        {
                          validator: async (_, operator) => {
                            if (!operator) {
                              return Promise.reject(
                                new Error("Operator is required")
                              );
                            }
                          },
                        },
                      ]}
                    >
                      <Select
                        disabled={isFormDisabled}
                        placeholder="Operator"
                        style={{ width: "30%" }}
                      >
                        <Option value="COUNT">Count</Option>
                        <Option value="SUM">Sum</Option>
                        <Option value="MIN">Min</Option>
                        <Option value="MAX">Max</Option>
                        <Option value="AVG">Average</Option>
                      </Select>
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      name={[name, "column"]}
                      noStyle
                      rules={[
                        {
                          validator: async (_, column) => {
                            if (!column) {
                              return Promise.reject(
                                new Error("Column is required")
                              );
                            }
                          },
                        },
                      ]}
                    >
                      <Select
                        showSearch={true}
                        optionFilterProp="key"
                        popupMatchSelectWidth={false}
                        placeholder="Aggregated column"
                        disabled={isFormDisabled}
                        style={{ width: "70%" }}
                      >
                        {Object.keys(prevStepSchema).map((k) => {
                          return (
                            <Select.Option key={k} value={k}>
                              <div className="demo-option-label-item">
                                <span role="img" style={{ marginRight: 5 }}>
                                  <TypeRenderer
                                    domain={prevStepSchema[k].domain}
                                    formula={
                                      prevStepSchema[k].operation ===
                                      "Table.AddColumn"
                                    }
                                  />
                                </span>
                                {prevStepSchema[k].label
                                  ? prevStepSchema[k].label
                                  : k}
                              </div>
                            </Select.Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </Input.Group>
                </Form.Item>
                <Form.Item
                  {...restField}
                  name={[name, "name"]}
                  label="New column name"
                  rules={[
                    {
                      required: true,
                      message: "Name is required",
                    },
                    {
                      validator: validateColumnName(
                        getUsedColumnNames(
                          form.getFieldValue(["aggregate_on", name, "name"])
                        )
                      ),
                    },
                  ]}
                >
                  <Input
                    placeholder="New column name"
                    disabled={isFormDisabled}
                  />
                </Form.Item>
              </WlyCard>
            ))}
            <Form.Item>
              <Button
                type="dashed"
                onClick={() => add()}
                block
                icon={<PlusOutlined />}
              >
                Add aggregation
              </Button>
            </Form.Item>
          </Space>
        )}
      </Form.List>
    </Form>
  );
};

export default compose<Props, GroupByFormProps>(WithOrg)(GroupByForm);
