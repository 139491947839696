import type { FormInstance } from "antd";
import React from "react";
import type { IObject } from "../../../../../../interfaces/object";
import type { IOrg } from "../../../../../../interfaces/org";
import { type IDataset } from "../../../../../../interfaces/sources";
import type { IUser } from "../../../../../../interfaces/user";
import type { IRecord } from "../../domain";
import type { IWidget } from "../domain";

export interface IWidgetEmailRendererProps<T> {
  widget: IWidget;
  object: IObject;
  record: IRecord;
  conf: T;
  org: IOrg;
  user: IUser;
  edit?: boolean;
}

export type IWidgetEmailMjmlRenderer<T> = (
  widget: IWidget,
  object: IObject,
  record: IRecord,
  conf: T,
  org: IOrg,
  user: IUser,
  edit?: boolean
) => Promise<string>;

export interface IWidgetEmailEditorProps<T> {
  widget: IWidget;
  object: IObject;
  record: IRecord;
  conf: T;
  org: IOrg;
  user: IUser;
  form: FormInstance;
  datasets: IDataset[];
}

export interface IEmailRendererConfig<T> {
  parseConfig: (widget: IWidget, object: IObject) => T;
  renderEditor: (
    widget: IWidget,
    object: IObject,
    record: IRecord,
    conf: T,
    org: IOrg,
    user: IUser,
    form: FormInstance,
    datasets: IDataset[]
  ) => React.ReactElement;
  renderComponent: (
    widget: IWidget,
    object: IObject,
    record: IRecord,
    conf: T,
    org: IOrg,
    user: IUser,
    edit?: boolean
  ) => React.ReactElement;
  renderMjml: IWidgetEmailMjmlRenderer<T>;
}

export enum IEmailWidgetType {
  "text" = "text",
  "image" = "image",
  "properties" = "properties",
  "button" = "button",
  "spacer" = "spacer",
  "property-list" = "property-list",
  "related-list" = "related-list",
  "divider" = "divider",
  "repeatable" = "repeatable",
}

export const notImplemented: IEmailRendererConfig<null> = {
  parseConfig: (widget: IWidget, object: IObject) => null,
  renderEditor: (
    widget: IWidget,
    object: IObject,
    record: IRecord,
    conf: null
  ) => {
    return <div>Not implemented</div>;
  },
  renderComponent: (
    widget: IWidget,
    object: IObject,
    record: IRecord,
    conf: null
  ) => {
    return <div>Not implemented</div>;
  },
  renderMjml: (
    widget: IWidget,
    object: IObject,
    record: IRecord,
    conf: null,
    org: IOrg,
    user: IUser,
    edit?: boolean
  ) =>
    Promise.resolve(`
<mj-text align="center">
  <i>Not Implemented</i>
</mj-text>
`),
};
