import moment from "moment";
import type { IJobExecution } from "../interfaces/jobExecutions";
import ApiService from "./ApiService";

export interface LogEntry {
  timestamp: string;
  level: string;
  message: string;
}

interface LogsPageResult {
  data: {
    logs?: LogEntry[];
    nextPageToken?: string;
    pageSize?: number;
  };
}

export const getLogs = (orgId: string, jobExecution: IJobExecution) => {
  const endpoint = `logs/orgs/${orgId}/executions/${jobExecution.identifier}/logs`;
  const options = {};
  return ApiService.getRequest<LogsPageResult>(endpoint, options);
};

export const getExecutionLogs = (
  jobIdentifier: string,
  orgId: string,
  opts?: {
    sinceTs?: string;
    only: "live";
  }
) => {
  const endpoint = `logs/orgs/${orgId}/executions/${jobIdentifier}/logs`;
  const options = {
    sinceTs: opts?.sinceTs ? opts?.sinceTs : moment().utc().format(),
    only: opts?.only,
  };
  return ApiService.getRequest<LogsPageResult>(endpoint, options);
};
