import { CopyOutlined, DeleteOutlined } from "@ant-design/icons";
import cuid from "cuid";
import Handlebars from "handlebars";
import * as React from "react";
import "react-quill/dist/quill.snow.css";
import type { InjectedAntUtilsProps } from "../../../../../components/ant-utils/withAntUtils";
import { withAntUtils } from "../../../../../components/ant-utils/withAntUtils";
import { compose } from "../../../../../components/compose/WlyCompose";
import type { EditTooltipItem } from "../../../../../components/report/tooltip/EditTooltip";
import { EditTooltip } from "../../../../../components/report/tooltip/EditTooltip";
import type { IReport, ITile } from "../../../../../interfaces/reports";
import {
  getInnerHeight,
  getInnerWidth,
  getOuterHeight,
} from "../../../../../utils/htmlUtils";
import "./TextCard.scss";

interface ITextCardProps {
  onDelete?: (id: string) => Promise<any>;
  onEdit?: (id: string) => void;
  position: string;
  tile: ITile;
  report: IReport;
  getFilterStoreValues: (id: string) => string[];
  onDuplicate?: (tile: ITile) => Promise<any>;
  editing: boolean;
  selected?: boolean;
  setStatic: (s: boolean) => void;
}

type Props = ITextCardProps & InjectedAntUtilsProps;

type IState = {
  containerHeight: number;
  headerHeight: number;
  containerWidth: number;
};

class TextCard extends React.Component<Props, IState> {
  id: string = cuid();
  headerId: string = cuid();

  constructor(props: Props) {
    super(props);
    this.state = {
      containerHeight: 0,
      headerHeight: 0,
      containerWidth: 0,
    };
  }

  public componentDidMount() {
    this.setHeight();
    this.setWidth();
  }

  public componentDidUpdate(prevProps: ITextCardProps) {
    this.setHeight();
    this.setWidth();

    if (prevProps.editing !== this.props.editing) {
      this.props.setStatic(false);
    }
  }

  setHeight = () => {
    const containerEl = document.getElementById(this.id);
    const headerEl = document.getElementById(this.headerId);
    if (containerEl) {
      const innerHeight = getInnerHeight(containerEl);
      if (innerHeight !== this.state.containerHeight) {
        this.setState({
          containerHeight: innerHeight,
        });
      }
    }
    if (headerEl) {
      const outterHeight = getOuterHeight(headerEl);
      if (outterHeight !== this.state.headerHeight) {
        this.setState({
          headerHeight: outterHeight,
        });
      }
    }
  };

  setWidth = () => {
    const containerEl = document.getElementById(this.id);
    if (containerEl) {
      const innerWidth = getInnerWidth(containerEl);
      if (innerWidth !== this.state.containerWidth) {
        this.setState({
          containerWidth: innerWidth,
        });
      }
    }
  };

  public render() {
    const {
      antUtils,
      onDelete,
      onDuplicate,
      tile,
      report,
      getFilterStoreValues,
      editing,
      selected,
    } = this.props;

    const renderContent = () => {
      try {
        const data = {
          filters: report.filters
            .filter((f) => f.apiName)
            .reduce((acc, f) => {
              return {
                ...acc,
                [f.apiName]: getFilterStoreValues(f.id),
              };
            }, {}),
        };
        const template = Handlebars.compile(tile.content);
        Handlebars.registerHelper(
          "helperMissing",
          function (/* dynamic arguments */) {
            var options = arguments[arguments.length - 1];
            var args = Array.prototype.slice.call(
              arguments,
              0,
              arguments.length - 1
            );
            return new Handlebars.SafeString(
              "Missing: " + options.name + "(" + args + ")"
            );
          }
        );

        Handlebars.registerHelper("capitalize", function (aString) {
          return aString.charAt(0).toUpperCase() + aString.slice(1);
        });

        Handlebars.registerHelper("ifEquals", function (arg1, arg2, options) {
          return arg1 === arg2 ? options.fn(this) : options.inverse(this);
        });

        return template(data);
      } catch (err) {
        return tile.content;
      }
    };

    const editionItems: Array<EditTooltipItem> = [];

    if (onDuplicate) {
      editionItems.push({
        key: "duplicate",
        name: "Duplicate",
        props: {
          icon: <CopyOutlined />,
          onClick: () => onDuplicate(tile),
        },
      });
    }

    if (onDelete) {
      editionItems.push({
        key: "delete",
        name: "Delete",
        props: {
          danger: true,
          icon: <DeleteOutlined />,
          onClick: () =>
            antUtils.modal.confirm({
              title: "Do you want to continue?",
              content:
                "Removing a tile cannot be undone, are you sure you want to proceed?",
              onOk: () => onDelete(tile.id),
              okType: "danger",
              okText: "Proceed",
              cancelText: "Cancel",
              onCancel: () => undefined,
            }),
        },
      });
    }

    return (
      <div className="tile-text-wrapper">
        {editionItems.length > 0 && editing && selected && (
          <EditTooltip
            additionalStyle={{
              position: "absolute",
              top: -42,
              right: -2,
            }}
            items={editionItems}
          />
        )}
        <div
          className={`tile-text ${tile.hide_card ? "hide-card" : ""}`}
          id={this.id}
        >
          <div
            className={`tile-text-content quill vertical-align vertical-align-${tile.vertical_align}`}
          >
            <div className="ql-snow">
              <div
                className="ql-editor"
                dangerouslySetInnerHTML={{ __html: renderContent() }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default compose<Props, ITextCardProps>(withAntUtils)(TextCard);
