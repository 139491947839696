import { Typography } from "antd";
import * as React from "react";

export interface IHeadingProps {
  level: number;
  id: string;
}

export function Heading(props: React.PropsWithChildren<IHeadingProps>) {
  const { level, children } = props;

  return <Typography.Title level={level as any}>{children}</Typography.Title>;
}
