import OperationNodeWidget from "./OperationNodeWidget";
import { OperationNodeModel } from "./OperationNodeModel";
import * as React from "react";
import type {
  GenerateWidgetEvent,
  GenerateModelEvent,
} from "@projectstorm/react-canvas-core";
import { AbstractReactFactory } from "@projectstorm/react-canvas-core";
import type { DiagramEngine } from "@projectstorm/react-diagrams-core";

export class OperationNodeFactory extends AbstractReactFactory<
  OperationNodeModel,
  DiagramEngine
> {
  constructor() {
    super("operation");
  }

  generateReactWidget(
    event: GenerateWidgetEvent<OperationNodeModel>
  ): JSX.Element {
    return (
      <OperationNodeWidget engine={this.engine} size={50} node={event.model} />
    );
  }

  generateModel(event: GenerateModelEvent) {
    return new OperationNodeModel();
  }
}
