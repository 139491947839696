import type { FormInstance } from "antd";
import type { ChartType } from "../../components/chart/domain";
import { ChartDefinition } from "../../components/chart/domain";
import type {
  AvailableDimension,
  AvailableMetric,
} from "../../components/measures/filter-item/FilterItem";
import type { ILagoonQuery } from "../exploration/single/domain";
import type { IAsyncMeta } from "../exploration/single/visualization/chart/Chart";
import "./ChartOption.scss";
import ChartOptions from "./ChartOptions";

interface IChartOptionsWrapperProps {
  currentChartType: ChartType;
  lagoonQuery: ILagoonQuery;
  availableDimensions: AvailableDimension[];
  availableMetrics: AvailableMetric[];
  meta: IAsyncMeta;
  form: FormInstance;
  onChange?: () => void;
  showSummary?: boolean;
}

export function ChartOptionsWrapper(props: IChartOptionsWrapperProps) {
  const {
    currentChartType,
    lagoonQuery,
    meta,
    form,
    onChange,
    availableDimensions,
    availableMetrics,
    showSummary,
  } = props;

  return (
    <ChartOptions
      chartType={currentChartType}
      query={lagoonQuery}
      chartOptions={lagoonQuery.chartOptions}
      analysisType={lagoonQuery.analysisType}
      series={ChartDefinition[currentChartType].extractCustomizationSeries(
        meta.status === "success" ? meta.data.series : [],
        meta.status === "success" ? meta.data.tableRows : [],
        lagoonQuery
      )}
      dimensions={ChartDefinition[
        currentChartType
      ].extractCustomizationDimensions(
        meta.status === "success" ? meta.data.tableRows : [],
        availableDimensions,
        lagoonQuery
      )}
      axis={{
        bottom: {
          editable: ChartDefinition[currentChartType].axis.bottom.editable,
          values: ChartDefinition[currentChartType].axis.bottom.reorderable
            ? ChartDefinition[currentChartType].axis.bottom.reorderable!(
                meta.status === "success" ? meta.data.series : [],
                meta.status === "success" ? meta.data.tableRows : [],
                lagoonQuery
              )
            : undefined,
        },
        left: {
          editable: !!ChartDefinition[currentChartType].axis.left?.editable,
          values: ChartDefinition[currentChartType].axis.left?.reorderable
            ? ChartDefinition[currentChartType].axis.left?.reorderable!(
                meta.status === "success" ? meta.data.series : [],
                meta.status === "success" ? meta.data.tableRows : [],
                lagoonQuery
              )
            : undefined,
        },
        right: {
          editable: !!ChartDefinition[currentChartType].axis.right?.editable,
          values: ChartDefinition[currentChartType].axis.right?.reorderable
            ? ChartDefinition[currentChartType].axis.right?.reorderable!(
                meta.status === "success" ? meta.data.series : [],
                meta.status === "success" ? meta.data.tableRows : [],
                lagoonQuery
              )
            : undefined,
        },
      }}
      form={form}
      onChange={onChange}
      showSummary={showSummary}
      availableDimensions={availableDimensions}
      availableMetrics={availableMetrics}
    />
  );
}
