import Lottie from "lottie-react";
import "./feedback.scss";
import logoAnimation from "./logo.json";

interface ILoadingLogoProps {}

export function LoadingLogo(props: ILoadingLogoProps) {
  return (
    <div className="feedback">
      <div
        style={{
          width: 64,
          height: 64,
          borderRadius: 8,
          overflow: "hidden",
        }}
      >
        <Lottie animationData={logoAnimation} loop={true} />
      </div>
    </div>
  );
}
