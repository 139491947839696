import {
  AreaChartOutlined,
  ExportOutlined,
  FilterOutlined,
} from "@ant-design/icons";
import { Alert, Button, Modal, Space, Tag, Typography } from "antd";
import _ from "lodash";
import React from "react";
import type { RouteComponentProps } from "react-router";
import { withRouter } from "react-router";
import { compose } from "../../../../../../components/compose/WlyCompose";
import Loading from "../../../../../../components/layout/feedback/loading";
import { WlyDashboardIcon } from "../../../../../../components/report/icons/DashboardIcon";
import { WlyQuestionIcon } from "../../../../../../components/report/icons/QuestionIcon";
import type { AsyncData } from "../../../../../../helpers/typescriptHelpers";
import { routeDescriptor } from "../../../../../../routes/routes";
import GraphQLService from "../../../../../../services/graphql/GraphQLService";
import type { ExplorationMeasureItemUsage } from "../../../domain";

interface IUsageAlertProps {
  usage: ExplorationMeasureItemUsage[];
  type: "dimension" | "metric";
}

interface IUsageData {
  allTiles: Array<{
    id: string;
    name: string;
    slug: string;
  }>;
  allFilters: Array<{
    id: string;
    name: string;
    slug: string;
  }>;
}

type Props = IUsageAlertProps & RouteComponentProps;

function UsageAlert(props: Props) {
  const {
    usage,
    type,
    match: { params },
  } = props;

  const [visible, setVisible] = React.useState<boolean>(false);
  const [data, setData] = React.useState<AsyncData<IUsageData>>({
    status: "initial",
  });

  const dashboardsInUse: Array<{ slug: string; name: string }> = _.uniqBy(
    usage
      .filter((u) => u.reportType === "DASHBOARD")
      .map((u) => ({ slug: u.reportSlug, name: u.reportName })),
    (i) => i.slug
  );
  const questionsInUse: Array<{ slug: string; name: string }> = _.uniqBy(
    usage
      .filter((u) => u.reportType === "QUESTION")
      .map((u) => ({ slug: u.reportSlug, name: u.reportName }))
      .filter((v, i, s) => s.indexOf(v) === i),
    (i) => i.slug
  );

  const numberOfTiles = usage.filter(
    (d) => d.usageType === "query" && d.reportType === "DASHBOARD"
  );
  const numberOfFilter = usage.filter(
    (d) => d.usageType === "filter" && d.reportType === "QUESTION"
  );

  const fetchData = async () => {
    try {
      setData({ status: "loading" });
      const d = await GraphQLService<IUsageData>(
        `
      query fetchUsageData($tileIds: [ID]!, $filterIds: [ID]!) {
        allTiles(where: {id_in: $tileIds}) {
          id
          slug
          name
        }
        allFilters(where: {id_in: $filterIds}) {
          id
          slug
          name
        }
      }
      `,
        {
          tileIds: usage
            .filter((u) => u.usageType === "query")
            .map((u) => u.foreignResourceId),
          filterIds: usage
            .filter((u) => u.usageType === "filter")
            .map((u) => u.foreignResourceId),
        }
      );
      setData({ status: "success", data: d });
    } catch (err) {
      console.error(err);
      setData({ status: "error", error: err });
    }
  };

  React.useEffect(() => {
    if (visible) {
      fetchData();
    }
  }, [visible, usage]);

  const renderInner = () => {
    if (data.status === "initial" || data.status === "loading") {
      return <Loading />;
    }
    if (data.status === "error") {
      return <div>Error: {data.error.message}</div>;
    }
    return (
      <Space style={{ width: "100%" }} direction="vertical">
        {dashboardsInUse.map((m, i) => {
          return (
            <div key={i}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <div style={{ flex: `0 auto`, marginRight: 6 }}>
                  <WlyDashboardIcon style={{ color: "#BE98C6" }} />
                </div>
                <div style={{ flex: 1 }}>
                  <Typography.Text strong>{m.name}</Typography.Text>
                </div>
                <div style={{ flex: `0 auto` }}>
                  <Button
                    size="small"
                    type="text"
                    icon={<ExportOutlined />}
                    onClick={() =>
                      window.open(
                        routeDescriptor.report.renderRoute({
                          ...params,
                          reportSlug: m.slug,
                        })
                      )
                    }
                  >
                    open
                  </Button>
                </div>
              </div>
              <Space wrap>
                {usage
                  .filter(
                    (u) =>
                      u.reportType === "DASHBOARD" && u.reportSlug === m.slug
                  )
                  .map((u, ui) => {
                    if (u.usageType === "query") {
                      const tile = data.data.allTiles.find(
                        (t) => t.id === u.foreignResourceId
                      );
                      if (!tile) {
                        return (
                          <Tag key={ui}>
                            <Typography.Text type="danger">
                              Tile not found
                            </Typography.Text>
                          </Tag>
                        );
                      }
                      return (
                        <Tag
                          key={ui}
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            window.open(
                              routeDescriptor["reportTileEdit"].renderRoute({
                                ...params,
                                reportSlug: m.slug,
                                tileSlug: tile.slug,
                              })
                            )
                          }
                        >
                          <AreaChartOutlined /> {tile.name} <ExportOutlined />
                        </Tag>
                      );
                    } else {
                      const filter = data.data.allFilters.find(
                        (af) => af.id === u.foreignResourceId
                      );
                      if (!filter) {
                        return (
                          <Tag key={ui}>
                            <Typography.Text type="danger">
                              Filter not found
                            </Typography.Text>
                          </Tag>
                        );
                      }
                      return (
                        <Tag
                          key={ui}
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            window.open(
                              routeDescriptor["reportFilterEdit"].renderRoute({
                                ...params,
                                reportSlug: m.slug,
                                filterSlug: filter.slug,
                              })
                            )
                          }
                        >
                          <FilterOutlined /> {filter.name} <ExportOutlined />
                        </Tag>
                      );
                    }
                  })}
              </Space>
            </div>
          );
        })}
        {questionsInUse.map((m, mi) => {
          return (
            <div key={mi}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <div style={{ flex: `0 auto`, marginRight: 6 }}>
                  <WlyQuestionIcon style={{ color: "#69A2B0" }} />
                </div>
                <div style={{ flex: 1 }}>
                  <Typography.Text strong>{m.name}</Typography.Text>
                </div>
                <div style={{ flex: `0 auto` }}>
                  <Button
                    size="small"
                    type="text"
                    icon={<ExportOutlined />}
                    onClick={() =>
                      window.open(
                        routeDescriptor.report.renderRoute({
                          ...params,
                          reportSlug: m.slug,
                        })
                      )
                    }
                  >
                    open
                  </Button>
                </div>
              </div>
            </div>
          );
        })}
      </Space>
    );
  };

  return (
    <>
      <Alert
        message={`This ${type} is in use.`}
        description={
          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ flex: 1 }}>
              This {type} is in use in {dashboardsInUse.length} dashboards (
              {numberOfTiles.length} tiles, {numberOfFilter.length} filters) and{" "}
              {questionsInUse.length} questions.
            </div>
            <div style={{ flex: `0 auto` }}>
              <Button onClick={() => setVisible(true)} type="primary" ghost>
                Details
              </Button>
            </div>
          </div>
        }
        type="info"
        showIcon
      />
      <Modal
        open={visible}
        onCancel={() => setVisible(false)}
        title={`${_.capitalize(type)} usage`}
        footer={null}
      >
        {renderInner()}
      </Modal>
    </>
  );
}

export default compose<Props, IUsageAlertProps>(withRouter)(UsageAlert);
