import { LockFilled, LockOutlined } from "@ant-design/icons";
import {
  Alert,
  Button,
  Col,
  Form,
  Row,
  Select,
  Slider,
  Space,
  Switch,
  Typography,
} from "antd";
import { useForm } from "antd/lib/form/Form";
import * as _ from "lodash";
import * as React from "react";
import type { InjectedAntUtilsProps } from "../../../../../../components/ant-utils/withAntUtils";
import { withAntUtils } from "../../../../../../components/ant-utils/withAntUtils";
import { WlyCard } from "../../../../../../components/cards/WlyCard";
import { compose } from "../../../../../../components/compose/WlyCompose";
import Aligner from "../../../../../../components/layout/aligner/Aligner";
import Feednack from "../../../../../../components/layout/feedback/feedback";
import type { AsyncData } from "../../../../../../helpers/typescriptHelpers";
import type {
  IDestination,
  SyncPeriod,
} from "../../../../../../interfaces/destinations";
import { IOrgFeatureType } from "../../../../../../interfaces/org";
import type {
  DatabaseInfo,
  SchemaInfo,
} from "../../../../../../services/BrizoService";
import {
  getDatabases,
  getSchemas,
  WarehouseUserType,
} from "../../../../../../services/BrizoService";
import type { InjectedOrgProps } from "../../../../../orgs/WithOrg";
import WithOrg from "../../../../../orgs/WithOrg";
import type { FetchedDestination } from "../../../domain";
import type { UpdateDestination } from "./domain";

const { Title } = Typography;

interface IModelingManagementPageProps {
  destination?: FetchedDestination;
  onDestinationUpdate?: UpdateDestination;
  currentWarehouse: IDestination;
}

const marks = {
  0: "5min",
  1: "15min",
  2: "30min",
  3: "1h",
  4: "2h",
  5: "3h",
  6: "6h",
  7: "8h",
  8: "12h",
  9: "24h",
};

const marksToPeriod: { [mark: number]: SyncPeriod } = {
  0: "five_minutes",
  1: "fifteen_minutes",
  2: "thirty_minutes",
  3: "one_hour",
  4: "two_hours",
  5: "three_hours",
  6: "six_hours",
  7: "eight_hours",
  8: "twelve_hours",
  9: "twenty_four_hours",
};

const periodToMarks: { [period: string]: any } = _.invert(marksToPeriod);

type Props = IModelingManagementPageProps &
  InjectedOrgProps &
  InjectedAntUtilsProps;

interface FormValues {
  destination: {
    activate: boolean;
    schedule?: number;
    persistenceEngineDefaultTargetDatabase?: string;
    persistenceEngineDefaultTargetSchema?: string;
  };
}

function ModelingManagementPage(props: Props) {
  const {
    antUtils,
    currentWarehouse,
    destination,
    onDestinationUpdate,
    orgFeatures,
    org,
  } = props;

  const orgHasAccess =
    orgFeatures.includes(IOrgFeatureType.PERSISTENCE_ENGINE_FEATURE_API_NAME) &&
    currentWarehouse;

  const hasPersistCapability =
    !currentWarehouse?.destinationMeta?.isWhalyManaged &&
    !!currentWarehouse.destinationMeta.targetName;
  const isPersistencePossible = currentWarehouse.isDataLoadingEnabled;

  const [submitting, setSubmitting] = React.useState<boolean>(false);
  const [availableDatabases, setAvailableDatabases] = React.useState<
    AsyncData<Array<DatabaseInfo>>
  >({ status: "initial" });
  const [availableSchemas, setAvailableSchemas] = React.useState<
    AsyncData<Array<SchemaInfo>>
  >({ status: "initial" });
  const [selectedDatabaseName, setSelectedDatabaseName] =
    React.useState<string>();

  React.useEffect(() => {
    setAvailableDatabases({ status: "loading" });
    getDatabases(currentWarehouse.id, WarehouseUserType.DATA_LOADING)
      .then((r) => {
        setAvailableDatabases({ status: "success", data: r.data });
      })
      .catch((err) => {
        setAvailableDatabases({ status: "error", error: err });
      });
  }, [currentWarehouse?.id]);

  React.useEffect(() => {
    if (
      selectedDatabaseName ||
      destination?.persistenceEngineDefaultTargetDatabase
    ) {
      setAvailableSchemas({ status: "loading" });
      getSchemas(
        currentWarehouse.id,
        selectedDatabaseName ||
          destination?.persistenceEngineDefaultTargetDatabase,
        WarehouseUserType.DATA_LOADING
      )
        .then((r) => {
          setAvailableSchemas({ status: "success", data: r.data });
        })
        .catch((err) => {
          setAvailableSchemas({ status: "error", error: err });
        });
    }
  }, [currentWarehouse?.id, selectedDatabaseName]);

  const [form] = useForm<FormValues>();

  const renderInner = () => {
    if (!currentWarehouse) {
      return <Feednack>Please connect a warehouse</Feednack>;
    }

    if (!destination) {
      return <Feednack>You should have a warehouse connected...</Feednack>;
    }

    const activeMarks = periodToMarks[destination?.persistenceEngineSyncPeriod];
    const initialData: FormValues = {
      destination: {
        activate: destination?.isPersistenceEngineEnabled,
        schedule: activeMarks,
        persistenceEngineDefaultTargetDatabase:
          destination?.persistenceEngineDefaultTargetDatabase,
        persistenceEngineDefaultTargetSchema:
          destination?.persistenceEngineDefaultTargetSchema,
      },
    };

    const onSave = async (values: FormValues) => {
      setSubmitting(true);
      try {
        if (!onDestinationUpdate) {
          throw new Error("Can't perform this operation");
        }
        await form.validateFields();
        await onDestinationUpdate(currentWarehouse.id, {
          isPersistenceEngineEnabled: values.destination.activate,
          persistenceEngineSyncStatus: "idle",
          persistenceEngineNextSyncDate: values.destination.schedule
            ? new Date()
            : null,
          persistenceEngineSyncPeriod: values.destination.schedule
            ? marksToPeriod[values.destination.schedule]
            : null,
          persistenceEngineDefaultTargetDatabase:
            values.destination.persistenceEngineDefaultTargetDatabase,
          persistenceEngineDefaultTargetSchema:
            values.destination.persistenceEngineDefaultTargetSchema,
        });
        antUtils.message.success("Successfully updated modeling settings", 2);
      } catch (err) {
        console.error(err);
        antUtils.message.error("there was an error saving settings...", 2);
      } finally {
        setSubmitting(false);
      }
    };

    return (
      <Form
        layout="vertical"
        form={form}
        disabled={!orgHasAccess}
        initialValues={initialData}
        onFinish={onSave}
        onValuesChange={(changedValues) => {
          if (changedValues?.destination?.activate === true) {
            form.setFieldsValue({
              destination: {
                schedule: 3,
              },
            });
          }

          if (
            changedValues["destination"]?.[
              "persistenceEngineDefaultTargetDatabase"
            ]
          ) {
            const newDatabase = form.getFieldValue([
              "destination",
              "persistenceEngineDefaultTargetDatabase",
            ]);
            setSelectedDatabaseName(newDatabase);
            form.setFieldValue(
              ["destination", "persistenceEngineDefaultTargetSchema"],
              null
            );
          }
        }}
      >
        <Space direction="vertical" size={32} style={{ width: "100%" }}>
          <div style={{ paddingTop: 16, display: "flex" }}>
            <div style={{ flex: 1 }}>
              <Title style={{ marginBottom: 0 }} level={3}>
                {orgHasAccess ? (
                  "Persistence Engine"
                ) : (
                  <>
                    <LockOutlined /> Persistence Engine
                  </>
                )}
              </Title>
            </div>
            <div>
              <Button
                type="primary"
                htmlType="submit"
                disabled={
                  submitting ||
                  !onDestinationUpdate ||
                  !orgHasAccess ||
                  !isPersistencePossible ||
                  !hasPersistCapability
                }
                loading={submitting}
              >
                Save
              </Button>
            </div>
          </div>
          <div>
            <Typography.Text>
              Persistence engine allows you create Views or Tables from your
              models in your warehouse instead of running the model query at run
              time. This gives you the tool to speed up the load time of
              dashboards in Whaly or use the model created in Whaly in an
              alternative tool such as another visualisation tool or in a
              reverse ETL solution.
            </Typography.Text>
          </div>
          {!orgHasAccess ? (
            <Alert
              message={
                <div style={{ display: "flex" }}>
                  <div>Your plan doesn't have access to this feature yet.</div>
                </div>
              }
              type="warning"
            />
          ) : undefined}
          {orgHasAccess && !hasPersistCapability ? (
            <Alert
              message={
                <div style={{ display: "flex" }}>
                  <div>
                    Persistence engine is not available for your warehouse.
                  </div>
                </div>
              }
              type="warning"
            />
          ) : undefined}
          {orgHasAccess && hasPersistCapability && !isPersistencePossible ? (
            <Alert
              message={
                <div style={{ display: "flex" }}>
                  <div>
                    Please add the data loader credentials in your warehouse to
                    activate persistence
                  </div>
                </div>
              }
              type="warning"
            />
          ) : undefined}
          <WlyCard title="Configuration">
            <Form.Item
              name={["destination", "activate"]}
              label={"Activate Persistence Engine"}
              valuePropName={"checked"}
            >
              <Switch
                disabled={
                  !orgHasAccess ||
                  !isPersistencePossible ||
                  !hasPersistCapability
                }
                checkedChildren={
                  !orgHasAccess ||
                  !isPersistencePossible ||
                  !hasPersistCapability ? (
                    <LockFilled />
                  ) : undefined
                }
                unCheckedChildren={
                  !orgHasAccess ||
                  !isPersistencePossible ||
                  !hasPersistCapability ? (
                    <LockFilled />
                  ) : undefined
                }
              />
            </Form.Item>
            <Form.Item noStyle shouldUpdate={true}>
              {() => {
                if (form.getFieldValue(["destination", "activate"])) {
                  return (
                    <>
                      <Form.Item
                        name={["destination", "schedule"]}
                        label={"Run every"}
                        help={
                          "This will trigger a periodic job that will persistst your Models based on their materialization configuration."
                        }
                        style={{
                          paddingBottom: 24,
                        }}
                      >
                        <Slider
                          disabled={
                            !orgHasAccess ||
                            !isPersistencePossible ||
                            !hasPersistCapability
                          }
                          min={0}
                          max={9}
                          trackStyle={{ backgroundColor: "#3A5C83" }}
                          marks={marks}
                          tooltip={{ open: false }}
                          dots={true}
                        />
                      </Form.Item>
                      <Form.Item
                        name={[
                          "destination",
                          "persistenceEngineDefaultTargetDatabase",
                        ]}
                        label={"Default database to persist to"}
                        help={
                          "This database will be the one used by default to persist the Models."
                        }
                        style={{
                          paddingBottom: 24,
                        }}
                      >
                        <Select
                          disabled={
                            availableDatabases.status !== "success" ||
                            !orgHasAccess ||
                            !isPersistencePossible ||
                            !hasPersistCapability
                          }
                          loading={availableDatabases.status === "loading"}
                        >
                          {availableDatabases.status === "success" &&
                            availableDatabases.data.map((db, i) => {
                              return (
                                <Select.Option value={db.databaseName} key={i}>
                                  {db.databaseName}
                                </Select.Option>
                              );
                            })}
                        </Select>
                      </Form.Item>
                      <Form.Item
                        name={[
                          "destination",
                          "persistenceEngineDefaultTargetSchema",
                        ]}
                        label={"Default schema to persist to"}
                        help={
                          "This schema will be the one used by default to persist the Models."
                        }
                        style={{
                          paddingBottom: 24,
                        }}
                      >
                        <Select
                          disabled={
                            !form.getFieldValue([
                              "destination",
                              "persistenceEngineDefaultTargetDatabase",
                            ]) ||
                            availableSchemas.status !== "success" ||
                            !orgHasAccess ||
                            !isPersistencePossible ||
                            !hasPersistCapability
                          }
                          loading={availableSchemas.status === "loading"}
                        >
                          {availableSchemas.status === "success" &&
                            availableSchemas.data.map((db, i) => {
                              return (
                                <Select.Option value={db.schemaName} key={i}>
                                  {db.schemaName}
                                </Select.Option>
                              );
                            })}
                        </Select>
                      </Form.Item>
                    </>
                  );
                }
                return;
              }}
            </Form.Item>
          </WlyCard>
        </Space>
      </Form>
    );
  };

  return (
    <Aligner>
      <Row justify="center" className="access-management">
        <Col xs={24} sm={24} md={20} lg={20} xl={20}>
          {renderInner()}
        </Col>
      </Row>
    </Aligner>
  );
}

export default compose<Props, IModelingManagementPageProps>(
  WithOrg,
  withAntUtils
)(ModelingManagementPage);
