import _ from "lodash";
import * as React from "react";
import type { RouteComponentProps } from "react-router";
import { withRouter } from "react-router";
import { compose } from "../../../components/compose/WlyCompose";
import Aligner from "../../../components/layout/aligner/Aligner";
import Loading from "../../../components/layout/feedback/loading";
import type { AsyncCachedData } from "../../../helpers/typescriptHelpers";
import type { IExploration } from "../../../interfaces/explorations";
import type { IReport } from "../../../interfaces/reports";
import GraphQLService from "../../../services/graphql/GraphQLService";
import type { InjectedOrgProps } from "../../orgs/WithOrg";
import WithOrg from "../../orgs/WithOrg";
import { FILTER_ARRAY_SEPARATOR, getFilterDefaultValue } from "../domain";
import {
  fetchUsedExplorationInReport,
  INITIAL_SHARED_GQL_QUERY,
  updateCSS,
} from "../view/domain";
import ReportContent from "../view/ReportContent";
import "../view/ReportView.scss";

interface IReportSharingLinkProps {}

interface Store {
  report: IReport;
  explorations: IExploration[];
}

interface IState {
  report: AsyncCachedData<Store>;
}

type Props = IReportSharingLinkProps &
  RouteComponentProps<{ publicToken: string; sharingToken: string }> &
  InjectedOrgProps;

class ReportSharingLink extends React.Component<Props, IState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      report: {
        status: "initial",
      },
    };
  }

  componentDidMount() {
    this.fetchData(
      this.props.match.params.publicToken,
      this.props.match.params.sharingToken
    );
  }

  componentDidUpdate(prevProps: Props, prevState: IState) {
    if (
      this.props.match.params.publicToken !==
        prevProps.match.params.publicToken ||
      this.props.match.params.sharingToken !==
        prevProps.match.params.sharingToken
    ) {
      this.fetchData(
        this.props.match.params.publicToken,
        this.props.match.params.sharingToken
      );
    }
    if (!_.isEqual(prevState.report, this.state.report)) {
      if (this.state.report.status === "success") {
        updateCSS(this.state.report.data.report.reportOptions);
      }
    }
  }

  fetchData = (publicToken: string, sharingToken: string) => {
    this.setState({ report: { status: "initial" } });
    // read if the password is stored in the local storage

    return GraphQLService<{ allReports: Array<IReport> }>(
      INITIAL_SHARED_GQL_QUERY(),
      {
        publicToken: publicToken,
        sharingToken: sharingToken,
        orgId: this.props.org.id,
      }
    )
      .then((r) => {
        if (r.allReports.length !== 1) {
          throw new Error("NOT_FOUND");
        }
        return r.allReports[0];
      })
      .then((r) => {
        // replace the default value of filters by controled values
        return fetchUsedExplorationInReport(r, this.props.org.id).then((e) => {
          return {
            explorations: e,
            report: {
              ...r,
              filters: r.filters.map((f) => {
                const findOne = r.controledValues.find(
                  (a) => a.filter_id === f.id
                );
                if (
                  findOne &&
                  findOne.controledValues &&
                  findOne.controledValues[0] &&
                  findOne.controledValues[0].value
                ) {
                  const def = findOne.controledValues[0].value
                    ? (findOne.controledValues[0].value as any)
                        ?.split(FILTER_ARRAY_SEPARATOR)
                        .filter((v) => v)
                    : [];
                  return {
                    ...f,
                    defaultValue: def,
                  };
                }
                return {
                  ...f,
                  defaultValue: getFilterDefaultValue(
                    f,
                    this.props.userAttributes
                  ),
                  rawDefaultValue: f.defaultValue as any,
                };
              }),
            },
          } as Store;
        });
      })
      .then((r) => {
        this.setState({ report: { status: "success", data: r } });
      })
      .catch((err) => {
        this.setState({
          report: {
            status: "error",
            error: err,
          },
        });
      });
  };

  public renderContent = () => {
    const { report } = this.state;

    switch (report.status) {
      case "initial":
        return (
          <Aligner className="standard-bg">
            <Loading />
          </Aligner>
        );
      case "error":
        return (
          <Aligner className="standard-bg">
            This report either doesn't exists or is not public anymore...
          </Aligner>
        );
      case "loading":
      case "success":
        return (
          <ReportContent
            saving={() => ({})}
            isEmbedded={false}
            isBeingPushed={false}
            isSharingLink={true}
            isSaving={false}
            isPublic={false}
            disableDrills={
              report.status === "success" &&
              report.data.report.sharingLinks?.length > 0
                ? report.data.report.sharingLinks[0].disableDrills
                : false
            }
            report={
              report.status === "success"
                ? report.data.report
                : report.cache!.report
            }
            editing={false}
            explorations={
              report.status === "success" ? report.data.explorations : []
            }
          />
        );
    }
  };

  public render() {
    const { report } = this.state;

    return (
      <div className="standard-bg report-view">{this.renderContent()}</div>
    );
  }
}

export default compose<Props, IReportSharingLinkProps>(
  withRouter,
  WithOrg
)(ReportSharingLink);
