import { SaveOutlined } from "@ant-design/icons";
import { gql } from "@apollo/client";
import { Button, ColorPicker, Form, Space, Typography } from "antd";
import { observer } from "mobx-react";
import { useState } from "react";
import type { InjectedAntUtilsProps } from "../../../components/ant-utils/withAntUtils";
import { withAntUtils } from "../../../components/ant-utils/withAntUtils";
import { compose } from "../../../components/compose/WlyCompose";
import GraphQLService from "../../../services/graphql/GraphQLService";
import type { InjectedOrgProps } from "../../orgs/WithOrg";
import WithOrg from "../../orgs/WithOrg";
import SettingsWrapper from "../SettingsWrapper";
import { EmailTemplateRenderer } from "./EmailTemplateRenderer";
import "./EmailTemplateSettings.scss";

interface IEmailTemplateProps {}

type Props = IEmailTemplateProps & InjectedOrgProps & InjectedAntUtilsProps;

export type IEmailTemplateSettings = {
  backgroundColor: string;
  bodyBackgroundColor: string;
  textColor: string;
  linkColor: string;
  buttonColor: string;
  buttonBackgroundColor: string;
};

const UPDATE_EMAIL_TEMPLATE_QUERY = gql`
  mutation updateOrgEmailTemplate($id: ID!, $emailTemplate: String!) {
    updateOrg(id: $id, data: { emailTemplate: $emailTemplate }) {
      id
      emailTemplate
    }
  }
`;

function EmailTemplateSettings(props: Props) {
  const { org, antUtils } = props;
  const [loading, setLoading] = useState<boolean>(false);
  const [form] = Form.useForm<IEmailTemplateSettings>();

  const defaultValues: IEmailTemplateSettings = {
    backgroundColor: "#F9F8F8",
    bodyBackgroundColor: "#FFFFFF",
    textColor: "#222222",
    linkColor: "#2199E8",
    buttonColor: "#FFFFFF",
    buttonBackgroundColor: "#2199E8",
  };

  let initialValues: IEmailTemplateSettings = {
    ...defaultValues,
  };

  try {
    const orgEmailTemplate = JSON.parse(org.emailTemplate);
    initialValues = {
      ...initialValues,
      ...orgEmailTemplate,
    };
  } catch (error) {}

  const normalizeColorPickerValue = (value: any) => {
    if (typeof value?.toHexString === "function") {
      return value.toHexString();
    }
    return value;
  };

  const onSubmit = async () => {
    try {
      setLoading(true);
      const values = await form.validateFields();
      await GraphQLService(UPDATE_EMAIL_TEMPLATE_QUERY, {
        id: org.id,
        emailTemplate: JSON.stringify(values),
      });
      antUtils.message.success("Template saved");
      setTimeout(() => {
        window.location.reload();
      }, 300);
    } catch (error) {
      console.error(error);
      antUtils.message.error("Error while saving template");
    } finally {
      setLoading(false);
    }
  };

  return (
    <SettingsWrapper>
      <Form<IEmailTemplateSettings>
        form={form}
        initialValues={initialValues}
        layout="vertical"
        className="email-template-settings"
      >
        <div className="email-template-settings-header">
          <div className="email-template-settings-header-title">
            <Typography.Title level={4} style={{ marginBottom: 0 }}>
              Email template
            </Typography.Title>
          </div>
          <Form.Item noStyle shouldUpdate={true}>
            {() => {
              const isDirty = form.isFieldsTouched();
              return (
                <Space style={{ display: "flex" }}>
                  <Button
                    type="text"
                    onClick={() => form.setFieldsValue(defaultValues)}
                    disabled={loading}
                  >
                    Reset
                  </Button>
                  <Button
                    ghost
                    type="primary"
                    onClick={onSubmit}
                    icon={<SaveOutlined />}
                    disabled={!isDirty}
                    loading={loading}
                  >
                    Save
                  </Button>
                </Space>
              );
            }}
          </Form.Item>
        </div>
        <div className="email-template-settings-body">
          <div className="email-template-settings-body-sider">
            <Typography.Title level={5} style={{ marginBottom: 20 }}>
              Template Settings
            </Typography.Title>
            <Form.Item
              label="Background color"
              name="backgroundColor"
              normalize={normalizeColorPickerValue}
            >
              <ColorPicker
                showText
                format="hex"
                style={{ width: "100%", justifyContent: "left" }}
                defaultFormat="hex"
              />
            </Form.Item>
            <Form.Item
              label="Body background"
              name="bodyBackgroundColor"
              normalize={normalizeColorPickerValue}
            >
              <ColorPicker
                showText
                format="hex"
                style={{ width: "100%", justifyContent: "left" }}
              />
            </Form.Item>
            <Form.Item
              label="Text color"
              name="textColor"
              normalize={normalizeColorPickerValue}
            >
              <ColorPicker
                showText
                format="hex"
                style={{ width: "100%", justifyContent: "left" }}
              />
            </Form.Item>
            <Form.Item
              label="Link color"
              name="linkColor"
              normalize={normalizeColorPickerValue}
            >
              <ColorPicker
                showText
                format="hex"
                style={{ width: "100%", justifyContent: "left" }}
              />
            </Form.Item>
            <Form.Item
              label="Button text color"
              name="buttonColor"
              normalize={normalizeColorPickerValue}
            >
              <ColorPicker
                showText
                format="hex"
                style={{ width: "100%", justifyContent: "left" }}
              />
            </Form.Item>
            <Form.Item
              label="Button background color"
              name="buttonBackgroundColor"
              normalize={normalizeColorPickerValue}
            >
              <ColorPicker
                showText
                format="hex"
                style={{ width: "100%", justifyContent: "left" }}
              />
            </Form.Item>
          </div>
          <div className="email-template-settings-body-content">
            <Typography.Title level={5} style={{ marginBottom: 20 }}>
              Preview
            </Typography.Title>
            <Form.Item noStyle shouldUpdate={true}>
              {() => {
                const values = form.getFieldsValue();
                return (
                  <EmailTemplateRenderer
                    variables={values}
                    logo={org.logo || ""}
                  />
                );
              }}
            </Form.Item>
          </div>
        </div>
      </Form>
    </SettingsWrapper>
  );
}

export default compose<Props, IEmailTemplateProps>(
  WithOrg,
  withAntUtils,
  observer
)(EmailTemplateSettings);
