import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { Button } from "antd";
import React from "react";
import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu";
import "react-horizontal-scrolling-menu/dist/styles.css";
import "./HighchartsLegend.scss";

export type HighchartLegendItem = {
  key: number | string;
  name: string;
  color: string;
  visible: boolean;
};

interface IHighchartsLegendProps {
  legendItems: HighchartLegendItem[];
  toggleVisibility: (index: number | string) => void;
  setVisibilityState: (
    index: number | string,
    visibilityState: "inactive" | "hover" | "normal"
  ) => void;
}

function LeftArrow() {
  const { isFirstItemVisible, scrollPrev } =
    React.useContext(VisibilityContext);

  return (
    <Button
      shape="circle"
      size="small"
      hidden={isFirstItemVisible}
      disabled={isFirstItemVisible}
      onClick={() => scrollPrev()}
      icon={<LeftOutlined style={{ fontSize: 11 }} />}
      style={{
        transform: "scale(0.7)",
        translate: -3,
        marginTop: -2,
      }}
    />
  );
}

function RightArrow() {
  const { isLastItemVisible, scrollNext } = React.useContext(VisibilityContext);

  return (
    <Button
      shape="circle"
      size="small"
      hidden={isLastItemVisible}
      disabled={isLastItemVisible}
      onClick={() => scrollNext()}
      icon={<RightOutlined style={{ fontSize: 11 }} />}
      style={{
        transform: "scale(0.7)",
        translate: 3,
        marginTop: -2,
      }}
    />
  );
}

export function HighchartsLegend(props: IHighchartsLegendProps) {
  const { legendItems, setVisibilityState, toggleVisibility } = props;

  return (
    <div className="wly-highchart-legend-container">
      <ScrollMenu LeftArrow={LeftArrow} RightArrow={RightArrow}>
        {legendItems?.map?.((legendItem, index) => (
          <div
            key={index}
            onClick={() => {
              toggleVisibility(index);
            }}
            className="wly-highchart-legend-item-container"
            onMouseEnter={() => {
              legendItems.forEach((s, i) => setVisibilityState(i, "inactive"));
              setVisibilityState(index, "hover");
            }}
            onMouseLeave={() => {
              legendItems.forEach((s, i) => setVisibilityState(i, "normal"));
            }}
            style={{
              color: legendItem.visible === false ? "#CCCCCC" : "initial",
              textDecoration:
                legendItem.visible === false ? "line-through" : "none",
            }}
          >
            <div
              style={{
                backgroundColor:
                  legendItem.visible === false ? "#CCCCCC" : legendItem.color,
                width: 10,
                height: 10,
                borderRadius: 5,
              }}
            />
            {legendItem.name}
          </div>
        ))}
      </ScrollMenu>
    </div>
  );
}
