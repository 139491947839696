import * as React from "react";

export const Panel: React.FunctionComponent<{
  width: number | string | undefined;
  setWidth: (value: number) => void;
  className?: string;
  children?: React.ReactNode;
}> = ({ children, width, setWidth, className }) => {
  const ref = React.createRef<HTMLDivElement>();

  React.useEffect(() => {
    if (ref.current) {
      if (typeof width !== "number") {
        setWidth(ref.current.clientWidth);
        return;
      }

      ref.current.style.width =
        typeof width === "string" ? width : `${width}px`;
    }
  }, [ref, width, setWidth]);

  return (
    <div className={className} ref={ref}>
      {children}
    </div>
  );
};
