import {
  ArrowRightOutlined,
  CloseOutlined,
  EyeInvisibleOutlined,
  FilterOutlined,
  ForwardOutlined,
  FunctionOutlined,
  GroupOutlined,
  MergeCellsOutlined,
  PlusCircleOutlined,
  SearchOutlined,
  UndoOutlined,
  WarningFilled,
} from "@ant-design/icons";
import type { DiagramEngine } from "@projectstorm/react-diagrams";
import { PortModelAlignment, PortWidget } from "@projectstorm/react-diagrams";
import { Dropdown } from "antd";
import cuid from "cuid";
import { inject, observer } from "mobx-react";
import * as React from "react";
import type { RouteComponentProps } from "react-router";
import { withRouter } from "react-router";
import { compose } from "../../../../../../../../components/compose/WlyCompose";
import type { WorkbenchUIStoreProps } from "../../../../../../../../store/workbenchUIStore";
import type { OperationNodeModel } from "./OperationNodeModel";

interface DataNodeWidgetProps {
  node: OperationNodeModel;
  engine: DiagramEngine;
  size?: number;
}

const HEIGHT = 52;
export const WIDTH = 188; // GRID IS 68 px so this should be a multiple of 68 - PORT SIZE
const PORT_SIZE = 16;

type Props = DataNodeWidgetProps &
  RouteComponentProps<{}> &
  WorkbenchUIStoreProps;

class OperationNodeWidget extends React.Component<Props> {
  id = cuid();

  render() {
    const { node, engine, workbenchUIStore } = this.props;

    const hovering =
      workbenchUIStore.showcasedNodeId === node.transformation.var;

    const renderIcon = () => {
      const fontSize = 16;
      switch (node.transformation.operation.type) {
        case "Table.AddColumn":
          return <FunctionOutlined style={{ margin: 6, fontSize: fontSize }} />;
        case "Table.SelectRows":
          return <FilterOutlined style={{ margin: 6, fontSize: fontSize }} />;
        case "Table.RemoveColumns":
          return (
            <EyeInvisibleOutlined style={{ margin: 6, fontSize: fontSize }} />
          );
        case "WhalyExt.Table.AddLookupColumn":
          return <SearchOutlined style={{ margin: 6, fontSize: fontSize }} />;
        case "Table.Ref":
          return <ForwardOutlined style={{ margin: 6, fontSize: fontSize }} />;
        case "WhalyExt.Table.AddRollupColumn":
          return <UndoOutlined style={{ margin: 6, fontSize: fontSize }} />;
        case "Table.Group":
          return <GroupOutlined style={{ margin: 6, fontSize: fontSize }} />;
        case "Table.Combine":
          return (
            <MergeCellsOutlined style={{ margin: 6, fontSize: fontSize }} />
          );
        default:
          const operationNameArray =
            node.transformation.operation.type.split(".");
          const name = operationNameArray[operationNameArray.length - 1];
          return name;
      }
    };

    const renderTitle = () => {
      switch (node.transformation.operation.type) {
        case "Table.AddColumn":
          return "Formula";
        case "WhalyExt.Table.AddLookupColumn":
          return "Lookup";
        case "WhalyExt.Table.AddRollupColumn":
          return "Rollup";
        case "Table.SelectRows":
          return "Filter";
        case "Table.RemoveColumns":
          return "Hide columns";
        case "Table.Group":
          return "Group by";
        case "Table.Combine":
          return "Union";
        default:
          const operationNameArray =
            node.transformation.operation.type.split(".");
          const name = operationNameArray[operationNameArray.length - 1];
          return name;
      }
    };

    const renderSubtitle = () => {
      switch (node.transformation.operation.type) {
        case "Table.AddColumn":
        case "WhalyExt.Table.AddLookupColumn":
        case "WhalyExt.Table.AddRollupColumn":
          if (node.transformation.operation.args.newColumnName) {
            return (
              <>
                <PlusCircleOutlined style={{ marginRight: 4 }} />
                {node.transformation.operation.args.newColumnName}
              </>
            );
          } else {
            return null;
          }
        case "Table.SelectRows":
          let selectRowsLength = 0;
          if (node.transformation.operation.args.condition?.[0]?.["and"]) {
            selectRowsLength =
              node.transformation.operation.args.condition?.[0]?.["and"]
                ?.length;
          } else if (node.transformation.operation.args.condition[0]?.["or"]) {
            selectRowsLength =
              node.transformation.operation.args.condition?.[0]?.["or"]?.length;
          }
          return `On ${selectRowsLength} condition${
            selectRowsLength > 1 ? "s" : ""
          }`;
        case "Table.RemoveColumns":
          return `Hiding ${
            node.transformation.operation.args.columns?.length
          } column${
            node.transformation.operation.args.columns?.length > 1 ? "s" : ""
          }`;
        case "Table.Group":
          return `Using ${
            node.transformation.operation.args.keys?.length
          } column${
            node.transformation.operation.args.keys?.length > 1 ? "s" : ""
          }`;
        case "Table.Combine":
          return `Combining ${
            node.transformation.operation.args.columns?.length
          } column${
            node.transformation.operation.args.columns?.length > 1 ? "s" : ""
          }`;
        default:
          return null;
      }
    };

    const withMenu = (child: React.ReactChild) => {
      if (node.canEdit()) {
        return (
          <Dropdown
            getPopupContainer={() => {
              const d = document.getElementById(this.id);
              if (d) {
                return d;
              }
              return document.body;
            }}
            menu={{
              items: [
                {
                  key: 2,
                  onClick: () => {
                    node.remove();
                  },
                  label: "Remove",
                },
              ],
            }}
            trigger={["contextMenu"]}
          >
            {child}
          </Dropdown>
        );
      }
      return child;
    };

    return withMenu(
      <div
        id={this.id}
        className={`data-node-widget-wrapper ${
          node.isSelected() ? "selected" : ""
        } ${hovering ? "showcasing" : ""}`}
        style={{ height: HEIGHT + PORT_SIZE, width: WIDTH + PORT_SIZE }}
      >
        <PortWidget
          style={{
            top: HEIGHT / 2,
            right: 0,
            position: "absolute",
          }}
          port={this.props.node.getPort(PortModelAlignment.RIGHT)}
          engine={this.props.engine}
        >
          <div className="data-port-widget" />
        </PortWidget>
        <PortWidget
          style={{
            top: HEIGHT / 2,
            left: 0,
            position: "absolute",
          }}
          port={this.props.node.getPort(PortModelAlignment.LEFT)}
          engine={this.props.engine}
        >
          <div className="data-port-widget" />
        </PortWidget>
        {node.hasSecondaryOutPort ? (
          <PortWidget
            style={{
              top: 0,
              left: WIDTH / 2,
              position: "absolute",
            }}
            port={this.props.node.getPort(PortModelAlignment.TOP)}
            engine={this.props.engine}
          >
            <div className="data-port-widget" />
          </PortWidget>
        ) : null}
        {node.isSelected() && node.canEdit() && (
          <div
            onClick={() => {
              node.remove();
            }}
            className="data-node-widget-remove"
          >
            <CloseOutlined />
          </div>
        )}
        {node.hasWarning() && (
          <div className="data-node-widget-warning">
            <WarningFilled />
          </div>
        )}
        {node.isOutput() && (
          <div className="data-node-widget-output">
            <ArrowRightOutlined />
          </div>
        )}
        <div
          className="data-node-widget-core"
          style={{ height: HEIGHT, width: WIDTH }}
        >
          <div className="data-node-widget-core-inner">
            <div className="data-node-widget-core-logo">{renderIcon()}</div>
            <div className="data-node-widget-core-content">
              <div className="data-node-widget-core-content-title">
                {renderTitle()}
              </div>
              <div className="data-node-widget-core-content-subtitle">
                {renderSubtitle()}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default compose<Props, DataNodeWidgetProps>(withRouter)(
  inject("workbenchUIStore")(observer(OperationNodeWidget))
);
