import { Form, Popover, Select, Typography } from "antd";
import cuid from "cuid";
import * as React from "react";
import type { RouteComponentProps } from "react-router";
import { withRouter } from "react-router";
import { compose } from "../../../components/compose/WlyCompose";
import FormActions from "../../../components/form/actions/FormActions";
import Feednack from "../../../components/layout/feedback/feedback";
import Loading from "../../../components/layout/feedback/loading";
import { SourceImageRenderer } from "../../../components/sources/SourceImageRenderer";
import type { AsyncData } from "../../../helpers/typescriptHelpers";
import type { ISource } from "../../../interfaces/sources";
import type { IRelationshipType } from "../../../interfaces/table";
import type { SchemaResult } from "../../../interfaces/transformations";
import { computeTransformations } from "../../../services/BrizoService";
import { generateUniqueId } from "../../../utils/uniqueId";
import type { InjectedOrgProps } from "../../orgs/WithOrg";
import WithOrg, { getCurrentWarehouse } from "../../orgs/WithOrg";
import {
  catchErrors,
  getPopUpContainer,
  replaceRemoveColumns,
} from "../../transformations/domain";
import type { TableTabItem } from "../domain";
import TypeRenderer from "../renderer/TypeRenderer";

const { Text } = Typography;

interface RelationshipModalFormProps {
  onCreate: (creationPayload: {
    left: {
      connect: {
        id: string;
      };
    };
    right: {
      connect: {
        id: string;
      };
    };
    editable: true;
    from: string;
    to: string;
    type: IRelationshipType;
    org: {
      connect: {
        id: string;
      };
    };
  }) => Promise<void>;
  onUpdate: (
    id: string,
    updatePayload: {
      left: {
        connect: {
          id: string;
        };
      };
      right: {
        connect: {
          id: string;
        };
      };
      from: string;
      to: string;
      type: IRelationshipType;
      org: {
        connect: {
          id: string;
        };
      };
    }
  ) => Promise<void>;
  onCancel: () => void;
  onFinish: () => void;
  isStale: (stale: boolean) => void;
  currentTabs: TableTabItem[];
  initialValues: IRelationshipInitialValue;
  hideTitle?: boolean;
  sources: Array<ISource>;
}

interface IRelationshipInitialValue {
  relationshipId?: string;
  fromDatasetId: string;
  fromColumnName: string;
  type: IRelationshipType;
  toDatasetId?: string;
  toColumnName?: string;
  id?: string;
}

type Props = RelationshipModalFormProps &
  InjectedOrgProps &
  RouteComponentProps<{ warehouseSlug?: string }>;

type schemaItem = {
  [key: string]: {
    schema: SchemaResult;
    status: "success" | "error";
  };
};

const id = cuid();

const RelationshipModalForm: React.FunctionComponent<Props> = (props) => {
  const {
    onCreate,
    onUpdate,
    onCancel,
    currentTabs,
    org,
    isStale,
    initialValues,
    onFinish,
    hideTitle,
    sources,
    match,
  } = props;

  const currentWarehouse = getCurrentWarehouse(org, match.params.warehouseSlug);
  const [form] = Form.useForm();
  const [submitting, setSubmitting] = React.useState<boolean>(false);
  const [schemas, setSchemas] = React.useState<AsyncData<schemaItem>>({
    status: "initial",
  });
  const [selectedToDataset, setSelectedToDataset] = React.useState<
    string | undefined
  >(initialValues.toDatasetId);
  const [selectedFromColumnDomain, setSelectedFromColumnDomain] =
    React.useState<string | undefined>();

  // step 1 - list related dataset
  // step 2 - fetch dataset schema
  // step 3 - print form
  // step 4 - submit form

  React.useEffect(() => {
    async function fetchSchemas() {
      setSchemas({ status: "loading" });
      await computeTransformations(
        currentWarehouse?.id,
        currentTabs
          .filter((tab) => tab.query.length)
          .reduce((acc, v, i) => {
            const query = v.query;
            return {
              ...acc,
              [v.baseDatasetId]: replaceRemoveColumns([
                ...query,
                {
                  var: generateUniqueId(),
                  operation: {
                    type: "Table.Schema",
                    args: {
                      table: query[query.length - 1].var,
                    },
                  },
                  domain: "viewResolver",
                },
              ]),
            };
          }, {}),
        false,
        false
      )
        .then((r) => {
          const objectMap = (obj, fn) =>
            Object.fromEntries(
              Object.entries(obj).map(([k, v], i) => [k, fn(v, k, i)])
            );

          const datasetsInSuccess: schemaItem = objectMap(r.data, (v) => {
            return { status: "succcess", schema: v };
          });

          const datasetsInError: schemaItem = r.errors
            ? objectMap(r.errors, (v) => {
                return { status: "error", schema: v };
              })
            : {};

          const mergedDatasets = { ...datasetsInSuccess, ...datasetsInError };

          setSchemas({
            status: "success",
            data: mergedDatasets,
          });
        })
        .catch((err) => {
          setSchemas({ status: "error", error: err });
        });
    }
    fetchSchemas();
  }, [org.id]);

  if (schemas.status === "loading" || schemas.status === "initial") {
    return <Loading />;
  }

  if (schemas.status === "error") {
    return <Feednack>{schemas.error?.message}</Feednack>;
  }

  if (
    !selectedFromColumnDomain &&
    initialValues.fromDatasetId &&
    initialValues.fromColumnName
  ) {
    setSelectedFromColumnDomain(
      schemas.data[initialValues.fromDatasetId].schema[
        initialValues.fromColumnName
      ].domain
    );
  }

  return (
    <Form
      initialValues={initialValues}
      form={form}
      onFieldsChange={() => {
        isStale && isStale(true);
      }}
      className="form-dropdown-form"
      onFinish={async (v: IRelationshipInitialValue) => {
        try {
          setSubmitting(true);
          if (v.id) {
            await onUpdate(id, {
              from: v.fromColumnName,
              to: v.toColumnName!,
              left: {
                connect: {
                  id: initialValues.fromDatasetId,
                },
              },
              right: {
                connect: {
                  id: v.toDatasetId!,
                },
              },
              type: v.type,
              org: {
                connect: {
                  id: org.id,
                },
              },
            });
          } else {
            await onCreate({
              from: v.fromColumnName,
              to: v.toColumnName!,
              left: {
                connect: {
                  id: initialValues.fromDatasetId,
                },
              },
              right: {
                connect: {
                  id: v.toDatasetId!,
                },
              },
              editable: true,
              type: v.type,
              org: {
                connect: {
                  id: org.id,
                },
              },
            });
          }
          setSubmitting(false);
          onFinish();
        } catch (err) {
          console.warn(err);
          catchErrors(err, id);
          setSubmitting(false);
        }
      }}
      layout="vertical"
    >
      {!hideTitle && (
        <div className="form-dropdown-form-header">
          {initialValues.id ? "Update" : "Create"} a relationship
        </div>
      )}
      <div className="form-dropdown-form-content" id={id}>
        <Form.Item
          name={["fromColumnName"]}
          label="Which column you want to create your relationship from"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select
            showSearch
            getPopupContainer={getPopUpContainer(id)}
            onChange={(e) =>
              e &&
              setSelectedFromColumnDomain(
                schemas.data[initialValues.fromDatasetId].schema[e.toString()]
                  .domain
              )
            }
          >
            {Object.keys(schemas.data[initialValues.fromDatasetId].schema)
              .sort((a, b) => a.localeCompare(b))
              .map((r, i) => {
                return (
                  <Select.Option value={r} key={i}>
                    <TypeRenderer
                      formula={
                        schemas.data[initialValues.fromDatasetId].schema[r]
                          .operation === "Table.AddColumn"
                      }
                      domain={
                        schemas.data[initialValues.fromDatasetId].schema[r]
                          .domain
                      }
                    />{" "}
                    {r}
                  </Select.Option>
                );
              })}
          </Select>
        </Form.Item>

        <Form.Item
          name={["type"]}
          label="What relationship you want to create"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select getPopupContainer={getPopUpContainer(id)}>
            <Select.Option value={"1-1"}>Has one</Select.Option>
            <Select.Option value={"1-N"}>Has Many</Select.Option>
            <Select.Option value={"N-1"}>Belongs to</Select.Option>
          </Select>
        </Form.Item>

        <Form.Item
          name={["toDatasetId"]}
          label="Which dataset you want to use for your relationship"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select
            showSearch
            optionFilterProp="label"
            getPopupContainer={getPopUpContainer(id)}
            onChange={(e) => e && setSelectedToDataset(e.toString())}
          >
            {currentTabs
              .sort((a, b) => {
                const aSource = sources.find((s) => s.id === a.baseSourceId);
                const bSource = sources.find((s) => s.id === b.baseSourceId);
                if (a.isModel !== b.isModel) {
                  return a.isModel ? 1 : -1;
                } else if (aSource?.name !== bSource?.name) {
                  return aSource?.name > bSource?.name ? 1 : -1;
                } else {
                  return a.label > b.label ? 1 : -1;
                }
              })
              .map((r, i) => {
                const isDatasetInError =
                  schemas.data[r.baseDatasetId]?.status === "error"
                    ? true
                    : false;
                const isEmptyFlowModel = !r.query.length;
                return (
                  <Select.Option
                    label={r.label}
                    value={r.baseDatasetId}
                    disabled={isDatasetInError || isEmptyFlowModel}
                    open={true}
                    key={i}
                  >
                    <div
                      className={`demo-option-label-item ${
                        isDatasetInError || isEmptyFlowModel
                          ? "demo-option-label-item-disabled"
                          : null
                      }`}
                    >
                      <span role="img" style={{ marginRight: 5 }}>
                        <SourceImageRenderer
                          alt={"sourceName"}
                          className="source-table-selection-logo"
                          isModel={r.isModel}
                          img={
                            r.isModel
                              ? undefined
                              : sources.find((s) => s.id === r.baseSourceId)
                                  ?.sourceMeta.publicInfo.logo
                          }
                          size={16}
                        />
                      </span>
                      <Text disabled={isDatasetInError || isEmptyFlowModel}>
                        {r.label}
                        <Text
                          type="secondary"
                          disabled={isDatasetInError || isEmptyFlowModel}
                        >
                          {" "}
                          {r.isModel ? (
                            <>Model</>
                          ) : (
                            <>
                              {
                                sources.find((s) => s.id === r.baseSourceId)
                                  ?.name
                              }
                            </>
                          )}
                        </Text>
                      </Text>
                      <div
                        style={{
                          display: isDatasetInError ? "inline-block" : "none",
                          marginLeft: 6,
                        }}
                      >
                        <Popover
                          title={<b>Dataset in error</b>}
                          content={
                            <div>
                              This dataset in currently in error and must be
                              fixed before you can use it in relationships.
                            </div>
                          }
                        >
                          <span>
                            <SourceImageRenderer
                              alt={"sourceName"}
                              className="source-table-selection-logo"
                              img={":warning:"}
                              size={16}
                            />
                          </span>
                        </Popover>
                      </div>
                    </div>
                  </Select.Option>
                );
              })}
          </Select>
        </Form.Item>
        <Form.Item
          name={["toColumnName"]}
          label="Which column you want to use for your relationship"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select
            showSearch
            disabled={!selectedToDataset}
            getPopupContainer={getPopUpContainer(id)}
          >
            {selectedToDataset &&
              Object.keys(schemas.data[selectedToDataset].schema)
                .filter(
                  (r) =>
                    schemas.data[selectedToDataset].schema[r].domain ===
                    selectedFromColumnDomain
                )
                .sort((a, b) => a.localeCompare(b))
                .map((r, i) => {
                  return (
                    <Select.Option value={r} key={i}>
                      <TypeRenderer
                        formula={
                          schemas.data[selectedToDataset].schema[r]
                            .operation === "Table.AddColumn"
                        }
                        domain={
                          schemas.data[selectedToDataset].schema[r].domain
                        }
                      />{" "}
                      {r}
                    </Select.Option>
                  );
                })}
          </Select>
        </Form.Item>
      </div>
      <div className="form-dropdown-form-buttons">
        <FormActions
          flex={true}
          size="small"
          onCancel={onCancel}
          isSubmitting={submitting}
        />
      </div>
    </Form>
  );
};

export default compose<Props, RelationshipModalFormProps>(
  WithOrg,
  withRouter
)(RelationshipModalForm);
