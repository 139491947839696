import { Col, Row, Tooltip } from "antd";
import type { ColumnProps } from "antd/lib/table";
import moment from "moment";
import type { RouteComponentProps } from "react-router";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import type { InjectedAntUtilsProps } from "../../../components/ant-utils/withAntUtils";
import { withAntUtils } from "../../../components/ant-utils/withAntUtils";
import { compose } from "../../../components/compose/WlyCompose";
import Aligner from "../../../components/layout/aligner/Aligner";
import { FromNow } from "../../../components/moment/FromNow";
import CardTable from "../../../components/table/CardTable";
import UserAvatar from "../../../components/user/avatar/UserAvatar";
import type { AsyncData } from "../../../helpers/typescriptHelpers";
import type { IAuditLog } from "../../../interfaces/auditLog";
import {
  AuditLogAction,
  AuditLogCategory,
  AuditLogSubCategory,
} from "../../../interfaces/auditLog";
import { routeDescriptor } from "../../../routes/routes";
import type { InjectedOrgProps } from "../../orgs/WithOrg";
import WithOrg from "../../orgs/WithOrg";

interface IAuditLogTableProps {
  total: number;
  current: number;
  pageSize: number;
  auditLogs: AsyncData<IAuditLogWithSlug[]>;
  setPageNumber: (pageNumber: number) => void;
}

interface IAuditLogWithSlug extends IAuditLog {
  recordSlug?: string;
}

type Props = IAuditLogTableProps &
  InjectedOrgProps &
  InjectedAntUtilsProps &
  RouteComponentProps<{}>;

function AuditLogTable(props: Props) {
  const { auditLogs, total, current, pageSize, setPageNumber } = props;

  const generateColumns = (): ColumnProps<IAuditLogWithSlug>[] => [
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
      render: (v, s) => {
        if (v === AuditLogCategory.SECURITY_ACTIVITY) {
          return <>Security Activity</>;
        }
        return <>{v}</>;
      },
    },
    {
      title: "Subcategory",
      dataIndex: "subCategory",
      key: "subCategory",
      render: (v, s) => {
        if (v === AuditLogSubCategory.DATA_EXPORT) {
          return <>Data Export</>;
        }
        return <>{v}</>;
      },
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (v, s) => {
        if (v === AuditLogAction.PERFORM) {
          return <>Perform</>;
        }
        return <>{v}</>;
      },
    },
    {
      title: "Modified by",
      dataIndex: "user",
      key: "user",
      render: (v, s) => {
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <Tooltip
              title={`${v.firstName} ${v.lastName}`}
              mouseEnterDelay={0.5}
              placement="top"
            >
              <UserAvatar user={v} size={24} />
            </Tooltip>
            <div style={{ paddingLeft: 6 }}>
              <FromNow tooltip date={moment(s.at)} />
            </div>
          </div>
        );
      },
    },
    {
      title: "Source",
      dataIndex: "source",
      key: "source",
      render: (v, s) => {
        if (!s.recordSlug) {
          return <>N/A</>;
        }
        return (
          <Link
            target="_blank"
            to={routeDescriptor.report.renderRoute({
              ...props.match.params,
              reportSlug: s.recordSlug,
            })}
          >
            Link
          </Link>
        );
      },
    },
  ];

  return (
    <Aligner>
      <Row justify="center" className="access-management">
        <Col xs={24} sm={24} md={20} lg={20} xl={20}>
          <CardTable<IAuditLog>
            cardTitle={<div className="title">Audit Logs</div>}
            rowKey="id"
            actionButtons={<></>}
            loading={
              auditLogs.status === "loading" || auditLogs.status === "initial"
            }
            dataSource={auditLogs.status === "success" ? auditLogs.data : []}
            columns={generateColumns()}
            pagination={{
              size: "default",
              showTotal: (total) => `Total ${total} items`,
              total,
              pageSize,
              current,
              onChange: (page, pageSize) => {
                setPageNumber(page);
              },
            }}
          />
        </Col>
      </Row>
    </Aligner>
  );
}

export default compose<Props, IAuditLogTableProps>(
  WithOrg,
  withRouter,
  withAntUtils
)(AuditLogTable);
