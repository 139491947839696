import type { FormInstance } from "antd";
import { Button, Form, Input, Space, Typography } from "antd";
import _ from "lodash";
import { inject } from "mobx-react";
import { WlyCard } from "../../../../../../components/cards/WlyCard";
import { WlyCardDangerZone } from "../../../../../../components/cards/WlyCardDangerZone";
import { compose } from "../../../../../../components/compose/WlyCompose";
import type { WorkbenchUIStoreProps } from "../../../../../../store/workbenchUIStore";
import type { InjectedOrgProps } from "../../../../../orgs/WithOrg";
import WithOrg from "../../../../../orgs/WithOrg";
import type { IMeasureTable, ISemanticGroupFormInput } from "../../domain";

interface ISemanticGroupEditorProps {
  initialData: ISemanticGroupFormInput & {
    id: string;
  };
  tables: IMeasureTable[];
  onSave: (data: ISemanticGroupFormInput) => Promise<void>;
  form: FormInstance<any>;
  onDelete?: () => Promise<void>;
}

const { Title } = Typography;

type Props = ISemanticGroupEditorProps &
  InjectedOrgProps &
  WorkbenchUIStoreProps;

function SemanticGroupEditor(props: Props) {
  const { initialData, onSave, form, onDelete } = props;

  const debouncedOnSave = _.debounce(onSave, 200);

  return (
    <Form
      name="groupInfoUpdateForm"
      initialValues={{
        name: initialData.name,
      }}
      layout="vertical"
      form={form}
      onValuesChange={(change) => {
        debouncedOnSave({
          name: change.name ? change.name : initialData.name,
        });
      }}
    >
      <Space direction="vertical" size={24} style={{ width: "100%" }}>
        <div style={{ paddingTop: 12 }}></div>
        <WlyCard title={<Title level={5}>Configuration</Title>}>
          {/* Name of the semantic group */}
          <Form.Item
            name={["name"]}
            label="Name"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input placeholder={`Group name`} />
          </Form.Item>
        </WlyCard>
        {onDelete && (
          <WlyCardDangerZone
            title="Danger Zone"
            button={
              <Button
                type="primary"
                danger={true}
                onClick={onDelete ? onDelete : undefined}
              >
                Delete
              </Button>
            }
          >
            Click here to permanently delete this group. This cannot be undone.
          </WlyCardDangerZone>
        )}
        <div style={{ paddingTop: 24 }}></div>
      </Space>
    </Form>
  );
}

export default compose<Props, ISemanticGroupEditorProps>(
  WithOrg,
  inject("workbenchUIStore")
)(SemanticGroupEditor);
