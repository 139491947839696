import { Button, Form, Modal } from "antd";
import * as React from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import type { InjectedOrgProps } from "../../../containers/orgs/WithOrg";
import WithOrg from "../../../containers/orgs/WithOrg";
import { hasRoleAccessBoolean } from "../../../containers/user-settings/HasRoleAccess";
import { IUserRoleType } from "../../../interfaces/user";
import { compose } from "../../compose/WlyCompose";
import Delayed from "../../delayed/delayed";

interface IDescriptionEditorProps {
  allowEdit?: boolean;
  initialData?: initialData;
  visible: boolean;
  onSave: (initialData: initialData) => Promise<any>;
  onCancel: () => void;
}

interface initialData {
  description: string;
}

type Props = IDescriptionEditorProps & InjectedOrgProps;

const DescriptionEditor: React.FunctionComponent<Props> = (props) => {
  const { allowEdit, visible, initialData, onSave, onCancel, user, org } =
    props;
  const [editing, setEditing] = React.useState<boolean>(
    !props.initialData ? true : false
  );
  const [confirmLoading, setConfirmLoading] = React.useState<boolean>(false);
  const [form] = Form.useForm();
  const formattedInitialData = initialData ? initialData : { description: "" };

  const renderContent = (initialData: initialData, editing: boolean) => {
    if (editing && hasRoleAccessBoolean(IUserRoleType.BUILDER, user, org.id)) {
      return (
        <Delayed delay={100}>
          <Form initialValues={initialData} layout="vertical" form={form}>
            <Form.Item name={["description"]}>
              <ReactQuill
                formats={[
                  "header",
                  "bold",
                  "italic",
                  "underline",
                  "strike",
                  "blockquote",
                  "list",
                  "bullet",
                  "indent",
                  "link",
                  "image",
                  "align",
                ]}
                theme="snow"
                modules={{
                  toolbar: [
                    [{ header: [1, 2, 3, false] }],
                    ["bold", "italic", "underline"],
                    [
                      { align: "" },
                      { align: "center" },
                      { align: "right" },
                      { align: "justify" },
                    ],
                    [
                      { list: "ordered" },
                      { list: "bullet" },
                      { indent: "-1" },
                      { indent: "+1" },
                    ],
                    ["link"],
                  ],
                }}
              />
            </Form.Item>
          </Form>
        </Delayed>
      );
    }
    return (
      <div className="tile-text-content quill">
        <div className="ql-snow">
          <div
            className="ql-editor"
            dangerouslySetInnerHTML={{
              __html: initialData.description
                ? initialData.description
                : "No description...",
            }}
          />
        </div>
      </div>
    );
  };

  const renderFooter = (editing: boolean) => {
    if (editing) {
      return (
        <div>
          <Button onClick={onCancel}>Cancel</Button>
          <Button
            disabled={allowEdit === false}
            type="primary"
            loading={confirmLoading}
            onClick={() => {
              setConfirmLoading(true);
              return form
                .validateFields()
                .then((values) => {
                  const v = values as initialData;
                  return onSave({ ...initialData, ...v })
                    .then(() => setConfirmLoading(false))
                    .then(() => setEditing(false));
                })
                .catch((info) => {
                  setConfirmLoading(false);
                });
            }}
          >
            Save
          </Button>
        </div>
      );
    }
    return (
      <div>
        <Button onClick={() => setEditing(true)}>Edit</Button>
      </div>
    );
  };

  return (
    <Modal
      title="Description"
      centered
      open={visible}
      footer={renderFooter(editing)}
      confirmLoading={confirmLoading}
      destroyOnClose={true}
      closable={false}
      maskClosable={editing ? false : true}
      onCancel={onCancel}
    >
      {renderContent(formattedInitialData, editing)}
    </Modal>
  );
};

export default compose<Props, IDescriptionEditorProps>(WithOrg)(
  DescriptionEditor
);
