import { useContext } from "react";
import type { IAntUtilsContext } from "./antUtilsContextProvider";
import { AntUtilsContext } from "./antUtilsContextProvider";

export interface InjectedAntUtilsProps {
  antUtils: IAntUtilsContext;
}

export const withAntUtils = (Component) => {
  return function WithAntUtils(props) {
    const antUtils = useContext(AntUtilsContext);
    return <Component {...props} antUtils={antUtils} />;
  };
};
