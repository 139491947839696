import type { Filter } from "@cubejs-client/core";
import type { AgGridReact } from "ag-grid-react";
import { Typography } from "antd";
import _ from "lodash";
import React, {
  useCallback,
  useEffect,
  useReducer,
  useRef,
  useState,
} from "react";
import ObjectTable from "../../../../../../../components/ag-grid/object-table/ObjectTable";
import {
  buildQuery,
  createQueryBuilderFilters,
  type ISetTableUIStateAction,
  type TableQuery,
  type TableUIState,
} from "../../../../../../../components/ag-grid/object-table/domain";
import { useCubeJSDatasource } from "../../../../../../../components/ag-grid/object-table/useCubeJSDatasource";
import { useTableQuery } from "../../../../../../../components/ag-grid/object-table/useTableQuery";
import type { InjectedAntUtilsProps } from "../../../../../../../components/ant-utils/withAntUtils";
import { withAntUtils } from "../../../../../../../components/ant-utils/withAntUtils";
import { BlurredWrapper } from "../../../../../../../components/cards/BlurredWrapper";
import { compose } from "../../../../../../../components/compose/WlyCompose";
import usePrevious from "../../../../../../../components/hooks/usePrevious";
import {
  IObjectViewType,
  type IObject,
  type IObjectView,
} from "../../../../../../../interfaces/object";
import {
  LagoonCallOrigin,
  lagoonServiceLoad,
} from "../../../../../../../services/LagoonService";
import type { InjectedOrgProps } from "../../../../../../orgs/WithOrg";
import WithOrg from "../../../../../../orgs/WithOrg";
import { BORDER_COLOR } from "../../../../layout/domain";
import { type IRecord } from "../../../../record/domain";
import { getObjectColumns, getObjectPresetFilters } from "../../../domain";
import { ObjectQueryBuilder } from "./query-builder/ObjectQueryBuilder";
import ObjectTableToolbar from "./toolbar/ObjectTableToolbar";

type IObjectTableViewProps = {
  object: IObject;
  views: IObjectView[];
  referencedObjects: IObject[];
  activeView?: IObjectView;
  injectedFilters: Filter[];
  size: { width: number; height: number };
};

type Props = IObjectTableViewProps & InjectedAntUtilsProps & InjectedOrgProps;

function ObjectTableView(props: Props) {
  const {
    object,
    views,
    activeView,
    size,
    org,
    antUtils,
    injectedFilters,
    referencedObjects,
  } = props;

  const cubeName = object.table.cubeName;
  const metrics = object.table.metrics ?? [];

  const prevInjectedFilters = usePrevious(injectedFilters);
  const availableColumns = getObjectColumns(object);
  const referencedAvailableColumns = referencedObjects.flatMap((o) =>
    getObjectColumns(o)
  );

  const getDefaultQueryBuilderState = () => {
    if (activeView && activeView.viewType === IObjectViewType.MANUAL) {
      return false;
    }
    if (object.queryBuilderSections && object.queryBuilderSections.length > 0) {
      return true;
    }
    return false;
  };

  const [selectedRecords, setSelectedRecords] = React.useState<IRecord[]>([]);
  const [hasQueryBuilderOpened, setQueryBuilderOpened] =
    React.useState<boolean>(getDefaultQueryBuilderState());
  const prevSelectedRecords = usePrevious(selectedRecords);

  const dimensions = availableColumns
    .filter((c) => c.type === "property")
    .map((p) => p.data.key);

  const defaultQuery: TableQuery = {
    order: [],
    filters: [{ and: injectedFilters }],
    queryBuilderItems: {},
    groups: [],
    columnOrder: {
      orderedColIds: [
        ...dimensions,
        ...metrics.map((m) => `${cubeName}.${m.cubeName}`),
      ],
    },
    columnPinning: {
      leftColIds: [],
      rightColIds: [],
    },
    columnSizing: {
      columnSizingModel: [],
    },
    columnVisibility: {
      visibleColumnIds: [
        ...dimensions,
        ...metrics.map((m) => `${cubeName}.${m.cubeName}`),
      ],
    },
  };

  const defaultUIState: TableUIState = {
    expandedNodes: [],
  };

  const getInitialQuery = (): TableQuery => {
    if (activeView?.config) {
      try {
        const activeViewConfig = JSON.parse(activeView.config) as TableQuery;
        return activeViewConfig;
      } catch (error) {
        console.error(error);
      }
    }
    return defaultQuery;
  };

  const initialQuery = getInitialQuery();

  const gridRef = useRef<AgGridReact>(null);

  const [rowCount, setRowCount] = useState<number | undefined>(undefined);

  const [tableQuery, setTableQuery] = useTableQuery({
    initialQuery,
    defaultQuery,
    getInitialQuery,
  });

  React.useEffect(() => {
    if (!_.isEqual(injectedFilters, prevInjectedFilters)) {
      setTableQuery({
        action: "setFilters",
        filters: [{ and: injectedFilters }],
      });
    }
  }, [injectedFilters]);

  React.useEffect(() => {
    if (
      prevSelectedRecords &&
      selectedRecords &&
      prevSelectedRecords.length > 0 &&
      selectedRecords.length === 0
    ) {
      gridRef?.current?.api.deselectAll();
    }
  }, [JSON.stringify(selectedRecords), JSON.stringify(prevSelectedRecords)]);

  React.useEffect(() => {
    if (
      gridRef &&
      gridRef.current &&
      gridRef.current.api &&
      gridRef.current.api.deselectAll
    ) {
      gridRef?.current?.api.deselectAll();
    }
    const defaultActiveViewQueryBuilderState = getDefaultQueryBuilderState();
    if (!defaultActiveViewQueryBuilderState) {
      setQueryBuilderOpened(false);
    }
  }, [JSON.stringify(activeView)]);

  const isStale = !_.isEqual(tableQuery, initialQuery);

  const [tableUIState, setTableUIState] = useReducer(
    (state: TableUIState, payload: ISetTableUIStateAction) => {
      switch (payload.action) {
        case "setExpandedNodes":
          return {
            ...state,
            expandedNodes: payload.expandedNodes,
          };
        case "setTableUIState":
          return {
            ...payload.tableUIState,
          };
        default:
          return state;
      }
    },
    defaultUIState
  );

  useEffect(() => {
    setTableUIState({
      action: "setTableUIState",
      tableUIState: defaultUIState,
    });
    setTableQuery({
      action: "setTableQuery",
      tableQuery: activeView?.config
        ? JSON.parse(activeView?.config)
        : getInitialQuery(),
    });
  }, [activeView?.config]);

  const fetchQuery = useCallback(() => {
    gridRef?.current?.api?.refreshServerSide?.({ purge: true });
  }, []);

  useEffect(() => {
    fetchQuery();
  }, [
    tableQuery?.presetFilters,
    tableQuery?.filters,
    tableQuery.groups,
    tableQuery?.order,
    tableQuery.columnVisibility?.visibleColumnIds,
    tableQuery?.queryBuilderItems,
  ]);

  useEffect(() => {
    const fetchRowCount = async () => {
      try {
        setRowCount(undefined);
        const advancedFilters = tableQuery.filters;
        const presetFilters = (tableQuery.presetFilters ?? []).flatMap((pf) => {
          const foundPreset = getObjectPresetFilters(object).find(
            (apf) => apf.id === pf
          )?.value;
          if (!foundPreset) return [];
          const filter = [
            { [foundPreset.operator]: foundPreset.filters },
          ] as Filter[];
          return filter;
        });
        // create queryBuilderOptions
        const queryBuilderFilters = createQueryBuilderFilters({
          availableColumns: [
            ...availableColumns,
            ...referencedAvailableColumns,
          ],
          tableQuery: tableQuery,
          object: object,
        });
        const filters: Filter[] = [
          {
            and: [...advancedFilters, ...presetFilters, ...queryBuilderFilters],
          },
        ];
        const resultSet = await lagoonServiceLoad(
          org.id,
          {
            measures: [`${object.table.cubeName}.count`],
            filters: filters,
          },
          "OBJECT",
          object.id,
          object.id,
          LagoonCallOrigin.WHALY_APP,
          undefined,
          undefined
        );
        const rowData = resultSet.tablePivot({ fillMissingDates: false });
        const rows = rowData?.[0]?.[`${object.table.cubeName}.count`];
        if (typeof rows === "string" || typeof rows === "number") {
          setRowCount(+rows);
        }
      } catch (error) {}
    };
    fetchRowCount();
  }, [
    tableQuery?.filters,
    tableQuery?.presetFilters,
    tableQuery?.queryBuilderItems,
  ]);

  useEffect(() => {
    gridRef?.current?.api?.setRowGroupColumns(
      tableQuery.groups.map((v) => v.groupedDimension)
    );
  }, [tableQuery.groups]);

  const cubeJSDataSource = useCubeJSDatasource({
    buildQuery: buildQuery,
    object: object,
    org: org,
    availableColumns: [...availableColumns, ...referencedAvailableColumns],
    onError: (error) => {
      antUtils.modal.error({
        title: "Error while loading query",
        content: (
          <>
            <Typography.Paragraph>
              <pre>{error?.toString?.()}</pre>
            </Typography.Paragraph>
            <Typography.Text>
              Make sure that no deleted columns are used in groups, filters or
              sort
            </Typography.Text>
          </>
        ),
      });
    },
  });

  return (
    <>
      <div
        style={{
          borderBottom: `1px solid ${BORDER_COLOR}`,
          padding: `6px 8px`,
        }}
      >
        <ObjectTableToolbar
          availableColumns={availableColumns}
          object={object}
          tableQuery={tableQuery}
          activeView={activeView}
          views={views}
          setTableQuery={setTableQuery}
          isStale={isStale}
          gridRef={gridRef}
          selectedRecords={selectedRecords}
          setSelectedRecords={() => setSelectedRecords([])}
          hasQueryBuilderOpened={hasQueryBuilderOpened}
          setQueryBuilderOpened={setQueryBuilderOpened}
        />
      </div>
      <div style={{ height: size.height, display: "flex" }}>
        {hasQueryBuilderOpened ? (
          <BlurredWrapper
            style={{
              flex: `0 250px`,
              borderRight: `1px solid ${BORDER_COLOR}`,
              maxWidth: 250,
            }}
            message={
              selectedRecords.length > 0
                ? "Query builder cannot be used with manual selection"
                : ""
            }
          >
            <ObjectQueryBuilder
              availableColumns={availableColumns}
              referencedAvailableColumns={referencedAvailableColumns}
              object={object}
              tableQuery={tableQuery}
              setTableQuery={setTableQuery}
              isStale={isStale}
              gridRef={gridRef}
            />
          </BlurredWrapper>
        ) : null}
        <div style={{ flex: 1 }}>
          <ObjectTable
            displayType="list"
            availableColumns={availableColumns}
            cubeJSDataSource={cubeJSDataSource}
            gridRef={gridRef}
            object={object}
            setTableQuery={setTableQuery}
            tableQuery={tableQuery}
            rowCount={rowCount}
            tableUIState={tableUIState}
            setTableUIState={setTableUIState}
            selectable
            selectedRecords={selectedRecords}
            setSelectedRecords={(r) => setSelectedRecords(r)}
          />
        </div>
      </div>
    </>
  );
}

export default compose<Props, IObjectTableViewProps>(
  WithOrg,
  withAntUtils
)(ObjectTableView);
