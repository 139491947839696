import { SearchOutlined } from "@ant-design/icons";
import { Button, Checkbox, Col, Form, Input, Row } from "antd";
import * as React from "react";
import { compose } from "../../../../../../../../components/compose/WlyCompose";
import type { TableRemoveColumnOperation } from "../../../../../../../../interfaces/transformations";
import type { InjectedOrgProps } from "../../../../../../../orgs/WithOrg";
import WithOrg from "../../../../../../../orgs/WithOrg";
import TypeRenderer from "../../../../../../../spreadsheet/renderer/TypeRenderer";

import type { FlowOperationFormProps } from "../domain";
import "./HideColumnsForm.scss";

type HideColumnFormProps = FlowOperationFormProps<{
  var: string;
  operation: TableRemoveColumnOperation;
  domain: "viewResolver";
}>;

type Props = HideColumnFormProps & InjectedOrgProps;

const HideColumnForm: React.FunctionComponent<Props> = (props) => {
  const {
    onSave,
    setSubmitting,
    onCancel,
    isStale,
    currentTransformation,
    setFormInstance,
    prevStepSchema,
    canvasNodeGenerator,
  } = props;

  const [search, setSearch] = React.useState<string>();
  const [form] = Form.useForm();

  React.useEffect(() => {
    if (setFormInstance) {
      setFormInstance(form);
    }
  }, [setFormInstance]);

  const isFormDisabled = !onSave;

  const node = canvasNodeGenerator.getCurrentTabItem(currentTransformation.var);
  if (!node) {
    return <div>Operation not found</div>;
  }

  return (
    <Form
      form={form}
      initialValues={{
        columns: Object.keys(prevStepSchema).filter((c) => {
          return !currentTransformation.operation.args.columns.includes(c);
        }),
      }}
      onFieldsChange={() => {
        isStale && isStale(true);
      }}
      onFinish={async (v) => {
        const currentlySelected = v.columns as string[];
        const toRemoveColumn = Object.keys(prevStepSchema).filter(
          (k) => currentlySelected.indexOf(k) < 0
        );

        try {
          setSubmitting(true);
          const newTransformation = {
            ...currentTransformation,
            operation: {
              ...currentTransformation.operation,
              args: {
                ...currentTransformation.operation.args,
                columns: toRemoveColumn,
              },
            },
          };
          await onSave([
            {
              type: "UPDATE",
              transformation: newTransformation,
            },
          ]);
          if (onCancel) {
            onCancel();
          }
          setSubmitting(false);
        } catch (err) {
          console.error(err);
          setSubmitting(false);
        }
      }}
      layout="vertical"
      className="form-dropdown-form"
    >
      <div className="form-dropdown-form-header hide-column-header">
        <div style={{ paddingBottom: 6 }}>
          <Button
            className="left"
            onClick={() => {
              form.setFieldsValue({ columns: Object.keys(prevStepSchema) });
              isStale(true);
            }}
            size="small"
            disabled={isFormDisabled}
          >
            Select All
          </Button>
          <Button
            className="right"
            onClick={() => {
              form.setFieldsValue({
                columns: [],
              });
              isStale(true);
            }}
            disabled={isFormDisabled}
            size="small"
          >
            Hide All
          </Button>
        </div>
        <div style={{ padding: "6px 0" }}>
          <Input
            value={search}
            onChange={(v) => setSearch(v.target.value)}
            addonAfter={<SearchOutlined />}
            disabled={isFormDisabled}
          />
        </div>
      </div>
      <div className="form-dropdown-form-content">
        <Form.Item style={{ marginBottom: 0 }} shouldUpdate={true}>
          {() => {
            return (
              <Form.Item style={{ marginBottom: 0 }} name={["columns"]}>
                <Checkbox.Group style={{ width: "100%" }}>
                  <Row>
                    {Object.keys(prevStepSchema)
                      .map((k) => ({
                        key: k,
                        domain: prevStepSchema[k].domain,
                        formula:
                          prevStepSchema[k].operation === "Table.AddColumn",
                      }))
                      .map((ck, i) => {
                        if (search) {
                          if (
                            !ck.key.toLowerCase().includes(search.toLowerCase())
                          ) {
                            return (
                              <Col
                                style={{ display: "none" }}
                                key={ck.key}
                                span={24}
                              >
                                <Checkbox
                                  disabled={isFormDisabled}
                                  value={ck.key}
                                >
                                  <TypeRenderer
                                    domain={ck.domain}
                                    formula={ck.formula}
                                  />{" "}
                                  {ck.key}
                                </Checkbox>
                              </Col>
                            );
                          }
                        }
                        return (
                          <Col key={ck.key} span={24}>
                            <Checkbox disabled={isFormDisabled} value={ck.key}>
                              <TypeRenderer
                                domain={ck.domain}
                                formula={ck.formula}
                              />{" "}
                              {ck.key}
                            </Checkbox>
                          </Col>
                        );
                      })}
                  </Row>
                </Checkbox.Group>
              </Form.Item>
            );
          }}
        </Form.Item>
      </div>
    </Form>
  );
};

export default compose<Props, HideColumnFormProps>(WithOrg)(HideColumnForm);
