import type { IWidgetEmailMjmlRenderer } from "../../domain";
import type { IEmailWidgetSpacerConfig } from "../domain";
import { getDefaultConfig } from "../domain";

export const EmailWidgetSpacerMjml: IWidgetEmailMjmlRenderer<
  IEmailWidgetSpacerConfig
> = async (widget, object, record, conf) => {
  const defaults = getDefaultConfig(conf, record);
  return `<mj-spacer height="${defaults.height}px" />`;
};
