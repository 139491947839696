import { Tree } from "antd";
import type { DataNode } from "antd/lib/tree";
import _ from "lodash";
import * as React from "react";
import type { InjectedOrgProps } from "../../../containers/orgs/WithOrg";
import WithOrg from "../../../containers/orgs/WithOrg";
import type {
  IDimension,
  IMetric,
  ISemanticGroup,
  ITable,
} from "../../../interfaces/table";
import { compose } from "../../compose/WlyCompose";
import usePrevious from "../../hooks/usePrevious";
import { DraggableMeasureItem } from "../measure-item/DraggableMeasureItem";
import "./MeasureTree.scss";

interface IMeasureTreeProps {
  tables: ITable[];
}

type MeasureNodeWithType =
  | {
      type: "dimension";
      item: IDimension;
    }
  | {
      type: "metric";
      item: IMetric;
    };

type SemanticGroupNodeWithType = {
  type: "semanticGroup";
  item: ISemanticGroup;
};

type NodeWithType = MeasureNodeWithType | SemanticGroupNodeWithType;

type Props = IMeasureTreeProps & InjectedOrgProps;

function MeasureTree(props: Props) {
  const { tables } = props;
  const [openKeys, setOpenKeys] = React.useState<{
    [tableKey: string]: string[];
  }>({});

  const previousTables = usePrevious(tables);

  const renderTableHeader = (
    datasetName: string,
    viewName: string,
    tableName?: string
  ) => {
    return (
      <div className="measures-table-header">
        <div className="measures-table-header-name">
          {tableName ? tableName : `${datasetName} (${viewName})`}
        </div>
      </div>
    );
  };

  const renderGroupHeader = (groupId: string, groupName: string) => {
    return (
      <div className="measures-table-header">
        <div className="measures-table-header-name">{groupName}</div>
      </div>
    );
  };

  const tableKeys = [];
  const semanticGroupKeys: { [tableKey: string]: string[] } = {};

  const generateTreeFromTable = (table: ITable): { tree: DataNode[] } => {
    const tableKey = `table-${table.id}`;
    tableKeys.push(tableKey);

    const getMeasureDataNode = (
      key: string,
      node: MeasureNodeWithType
    ): DataNode => {
      const parentTable = tables
        .map((table) => {
          return {
            cubeName: table.cubeName,
            metricsAndDimensionsCubeName: [
              ...table.metrics.map((m) => m.cubeName),
              ...table.dimensions.map((d) => d.cubeName),
            ],
          };
        })
        .find((t) =>
          t.metricsAndDimensionsCubeName.includes(node.item.cubeName)
        ).cubeName;

      return {
        key: key,
        className: `measure-switcher-hide`,
        selectable: false,
        isLeaf: true,
        title: (
          <div className="measures-table-content">
            <DraggableMeasureItem
              id={[parentTable, node.item.cubeName].join(".")}
              zone={"measure_store"}
              type={node.type}
              name={node.item.name}
              description={node.item.description}
            />
          </div>
        ),
      };
    };

    const getSemanticGroupDataNode = (
      key: string,
      node: SemanticGroupNodeWithType
    ): DataNode => {
      if (!semanticGroupKeys[tableKey]) {
        semanticGroupKeys[tableKey] = [];
      }
      semanticGroupKeys[tableKey].push(key);

      const dimensionsInSemanticGroup = table.dimensions.filter((dim) => {
        return !!(dim.semanticGroups.map((sg) => sg.id) || []).includes(
          node.item.id
        );
      });
      const metricsInSemanticGroup = table.metrics.filter((met) => {
        return !!(met.semanticGroups.map((sg) => sg.id) || []).includes(
          node.item.id
        );
      });

      return {
        key: key,
        // : Create adhoc class for groups
        className: `measure-table-selector`,
        selectable: true,
        isLeaf: false,
        checkable: false,
        title: renderGroupHeader(node.item.id, node.item.name),
        children: [
          ...dimensionsInSemanticGroup.map((dim) =>
            getDataNode({ type: "dimension", item: dim })
          ),
          ...metricsInSemanticGroup.map((met) =>
            getDataNode({ type: "metric", item: met })
          ),
        ],
      };
    };

    const getDataNode = (node: NodeWithType): DataNode => {
      const key = `${node.type}-${node.item.id}`;
      if (node.type === "semanticGroup") {
        return getSemanticGroupDataNode(key, node);
        // Dimensions | Metrics case
      } else {
        return getMeasureDataNode(key, node);
      }
    };

    const alphabeticalOrder = (a, b) => {
      const aName = a.overrideName
        ? a.overrideName.toLocaleLowerCase()
        : a.name?.toLocaleLowerCase();
      const bName = b.overrideName
        ? a.overrideName?.toLocaleLowerCase()
        : b.name?.toLocaleLowerCase();
      if (aName < bName) {
        return -1;
      }
      if (aName > bName) {
        return 1;
      }
      return 0;
    };

    return {
      tree: [
        {
          key: tableKey,
          title: renderTableHeader(
            table.view.dataset.name,
            table.view.name,
            table.name
          ),
          className: `measure-table-selector`,
          selectable: false,
          children: [
            ...[...table.dimensions]
              .filter((d) => !d.hidden)
              .filter((dimension) => {
                const tableSemanticGroupIds = table.semanticGroups.map(
                  (sg) => sg.id
                );
                const isDimensionInASemanticGroup =
                  tableSemanticGroupIds.reduce<boolean>((acc, smId) => {
                    const dimensionIsInGroup = dimension?.semanticGroups
                      ?.map((sg) => sg.id)
                      .includes(smId);
                    return acc || dimensionIsInGroup;
                  }, false);
                return !isDimensionInASemanticGroup;
              })
              .sort(alphabeticalOrder)
              .map((dim) => getDataNode({ type: "dimension", item: dim })),
            ...[...table.metrics]
              .filter((d) => !d.hidden)
              .filter((metric) => {
                const tableSemanticGroupIds = table.semanticGroups.map(
                  (sg) => sg.id
                );
                const isMetricInASemanticGroup =
                  tableSemanticGroupIds.reduce<boolean>((acc, smId) => {
                    const dimensionIsInGroup = metric.semanticGroups
                      .map((sg) => sg.id)
                      ?.includes(smId);
                    return acc || dimensionIsInGroup;
                  }, false);
                return !isMetricInASemanticGroup;
              })
              .sort(alphabeticalOrder)
              .map((met) => getDataNode({ type: "metric", item: met })),
            ...[...table.semanticGroups]
              .sort(alphabeticalOrder)
              .map((semanticGroup) =>
                getDataNode({
                  type: "semanticGroup",
                  item: semanticGroup,
                })
              ),
          ],
        },
      ],
    };
  };

  React.useEffect(() => {
    if (!_.isEqual(tables, previousTables)) {
      const openKeysDict = tableKeys.reduce((acc, key) => {
        acc[key] = [key].concat(semanticGroupKeys[key]);
        return acc;
      }, {});
      setOpenKeys(openKeysDict);
    }
  }, [tables]);

  const tableTrees = [...tables]
    .sort((a, b) => {
      if (a.incomingRelationships.length === 0) {
        return -1;
      } else if (b.incomingRelationships.length === 0) {
        return 1;
      } else {
        return 0;
      }
    })
    .filter((table) => {
      if (
        table.dimensions.length === 0 &&
        table.metrics.length === 0 &&
        table.semanticGroups.length === 0
      ) {
        return false;
      } else {
        return true;
      }
    })
    .map((table, index) => {
      const treeData = generateTreeFromTable(table);
      const tableKey = `table-${table.id}`;

      return (
        <Tree
          key={index}
          className="measure-table"
          onExpand={(expandedKeys) => {
            setOpenKeys({
              ...openKeys,
              [tableKey]: expandedKeys
                .filter((k) => !!k)
                .map((k) => k.toString()),
            });
          }}
          expandedKeys={openKeys[tableKey]}
          blockNode={true}
          selectable={false}
          treeData={treeData.tree}
          showIcon={true}
        />
      );
    });

  return (
    <div className="measures-tree">
      <div className="measures-content">{tableTrees}</div>
    </div>
  );
}

export default compose<Props, IMeasureTreeProps>(WithOrg)(MeasureTree);
