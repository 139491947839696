import { Tree } from "antd";
import type { DataNode } from "antd/es/tree";
import _ from "lodash";
import type { IObject } from "../../../../../interfaces/object";
import type {
  IColumn,
  ILayout,
  IRow,
  IWidget,
} from "../../../../v2-demo/container/record/component/domain";
import type { IBehaviorState } from "../../../domain";

interface IObjectLayoutEditorLayerProps {
  object: IObject;
  layout: ILayout;
  behavior: IBehaviorState;
}

type ItemPayload = ItemRow | ItemCol | ItemWidget;

interface ItemRow {
  type: "row";
  data: IRow;
}

interface ItemCol {
  type: "col";
  data: IColumn;
}

interface ItemWidget {
  type: "widget";
  data: IWidget;
}

export function ObjectLayoutEditorLayer(props: IObjectLayoutEditorLayerProps) {
  const { behavior, layout } = props;

  const renderTitle = (id: string, title: string) => {
    return (
      <div
        onMouseEnter={() => behavior.hover.setComponent(id)}
        onMouseLeave={() => behavior.hover.setComponent(null)}
      >
        {title}
      </div>
    );
  };

  const buildItem = (i: ItemPayload): DataNode => {
    if (i.type === "row") {
      const id = `row::${i.data.id}`;
      return {
        key: id,
        title: renderTitle(id, "Row"),
        selectable: true,
        isLeaf: false,
        children: i.data.columns.map((c) =>
          buildItem({ type: "col", data: c })
        ),
      };
    }
    if (i.type === "col") {
      const id = `col::${i.data.id}`;
      const widgetChildren = layout.widgets
        .filter((w) => w.parentColumn?.id === i.data.id)
        .map<ItemPayload>((w) => ({ type: "widget", data: w }));
      const layoutChildren = layout.rows
        .filter((w) => w.parentColumn?.id === i.data.id)
        .map<ItemPayload>((w) => ({ type: "row", data: w }));
      const children = [...widgetChildren, ...layoutChildren].sort(
        (a, b) => b.data.position - a.data.position
      );

      return {
        key: id,
        title: renderTitle(id, "Col"),
        selectable: true,
        isLeaf: false,
        children: children.map((c) => buildItem(c)),
      };
    }
    if (i.type === "widget") {
      const id = `widget::${i.data.id}`;
      return {
        key: id,
        title: renderTitle(id, "Widget"),
        selectable: true,
        isLeaf: false,
        children: [],
      };
    }
  };

  const tree: DataNode[] = [
    {
      key: "body",
      title: renderTitle("body", "Body"),
      selectable: true,
      isLeaf: false,
      children: [
        ...(layout.type === "RECORD"
          ? [
              {
                key: "header",
                title: renderTitle("header", "Header"),
                selectable: true,
                isLeaf: false,
              },
            ]
          : []),
        ..._.sortBy(
          layout.rows.filter((r) => !r.parentColumn),
          ["position"]
        ).map((r) => buildItem({ type: "row", data: r })),
      ],
    },
  ];

  return (
    <div style={{ margin: `0 -8px` }}>
      <Tree
        blockNode={true}
        onSelect={(e) => behavior.selected.setComponent(e[0] as string)}
        multiple={false}
        defaultSelectedKeys={
          behavior.selected ? [behavior.selected.component] : null
        }
        defaultExpandAll={true}
        defaultExpandParent={true}
        selectedKeys={behavior.selected ? [behavior.selected.component] : null}
        treeData={tree}
      />
    </div>
  );
}
