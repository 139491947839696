import type { ConfigType } from "../../../containers/chart-options/ChartOptions";
import type { IAnalysisType } from "../../../containers/exploration/single/domain";
import { WlyActivityChartIcon } from "../../icons/custom-icons/WlyActivityChartIcon";
import type { SingleChartDefinition } from "../domain";

export const activityChartTimeserieDefinition: SingleChartDefinition = {
  label: "Activity chart",
  allowTimeComparison: false,
  minMetrics: 1,
  maxMetrics: 1,
  minDimensions: 1,
  maxDimensions: 1,
  icon: <WlyActivityChartIcon />,
  axis: {
    bottom: {
      editable: true,
      isTimeFormattable: (a) => a === "TIME",
    },
    left: {
      editable: true,
    },
  },
  pivot: (analysisType: IAnalysisType) => false,
  canDisplay: (rs) => undefined,
  config: (a) => {
    const ret: Array<ConfigType> = ["labels", "palette"];
    return ret;
  },
  extractCustomizationDimensions: (d, q) => {
    return {
      dimensions: [],
    };
  },
  canPredict: (analysisType, query) => false,
  extractCustomizationSeries: (series, d) => {
    return {
      series: series.map((serie) => {
        // we modify the key to keep only the dimension value
        // we replace null with ∅
        const dimensionValues = serie.dimensions.map((d) => d.value ?? "∅");
        const label = dimensionValues.join(", ");
        return { ...serie, label: label };
      }),
      customColor: true,
      customLabel: true,
      customType: [],
    };
  },
};
