import { Col, Row } from "antd";
import Aligner from "../../../components/layout/aligner/Aligner";
import SettingsWrapper from "../SettingsWrapper";
import AccessManagementTableWrapper from "./AccessManagementTableWrapper";

interface IAccessManagementPageProps {}

export function AccessManagementPage(props: IAccessManagementPageProps) {
  return (
    <SettingsWrapper>
      <Aligner>
        <Row justify="center" className="access-management">
          <Col xs={24} sm={24} md={20} lg={20} xl={20}>
            <AccessManagementTableWrapper type="STANDARD" />
          </Col>
        </Row>
      </Aligner>
    </SettingsWrapper>
  );
}
