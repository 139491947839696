import {
  BorderBottomOutlined,
  EyeInvisibleOutlined,
  EyeOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import type { FormInstance, PopoverProps } from "antd";
import {
  Checkbox,
  Form,
  Input,
  InputNumber,
  Select,
  Slider,
  Space,
  Switch,
  Tooltip,
  Typography,
} from "antd";
import React, { useEffect, useState } from "react";
import { useDebouncedCallback } from "use-debounce";
import type {
  AxisChartOptionDefinition,
  ChartType,
} from "../../components/chart/domain";
import { ChartDefinition } from "../../components/chart/domain";
import type {
  FunnelChartDirection,
  FunnelCompareWith,
} from "../../components/chart/funnel/Funnel";
import MapPicker from "../../components/chart/geo-map/MapPicker";
import type { IMapMarkerRadiusPickerValue } from "../../components/chart/interractive-map/map/MapMarkerRadiusPicker";
import { MapMarkerRadiusPicker } from "../../components/chart/interractive-map/map/MapMarkerRadiusPicker";
import type { IMapPositionPickerValue } from "../../components/chart/interractive-map/map/MapPositionPicker";
import { MapPositionPicker } from "../../components/chart/interractive-map/map/MapPositionPicker";
import { compose } from "../../components/compose/WlyCompose";
import { WlyConditionalFormatterDrawer } from "../../components/conditional-formatting/WlyConditionalFormatterDrawer";
import type { IConditionalFormatterRule } from "../../components/conditional-formatting/domain";
import ColorPicker from "../../components/form/color-picker/ColorPicker";
import ColorRenderer from "../../components/form/color-picker/ColorRenderer";
import { SwitchIcons } from "../../components/form/elements/switch-icons/SwitchIcons";
import SketchPickerFormItem from "../../components/form/sketch-picker-form-item/SketchPickerFormItem";
import usePrevious from "../../components/hooks/usePrevious";
import type {
  AvailableDimension,
  AvailableMetric,
} from "../../components/measures/filter-item/FilterItem";
import PalettePicker from "../../components/palette/PalettePicker";
import PaletteRenderer from "../../components/palette/PaletteRenderer";
import PaletteSelector from "../../components/palette/selector/PaletteSelector";
import PaletteGenerator from "../../components/palette/utils/PaletteGenerator";
import type {
  IPalette,
  IPaletteSelection,
} from "../../components/palette/utils/paletteData";
import { getSelectedPalette } from "../../components/palette/utils/paletteData";
import type {
  ExtractCustomizationDimension,
  ExtractCustomizationSeries,
  IAnalysisType,
  ILagoonQuery,
} from "../exploration/single/domain";
import { CustomOrderedList } from "../exploration/single/visualization/actions/form-item/CustomOrderedList";
import type { InjectedOrgProps } from "../orgs/WithOrg";
import WithOrg from "../orgs/WithOrg";
import { ChartOptionSummary, canDisplaySummary } from "./ChartOptionSummary";
import { ChartOptionCollapse } from "./components/ChartOptionCollapse";
import type { IChartOptionLineItem } from "./components/ChartOptionLine";
import ChartOptionLine from "./components/ChartOptionLine";

const { Link } = Typography;

type chartOptionRendererPanelLine =
  | {
      type: "label";
      title: string;
    }
  | {
      type: "component";
      component: Exclude<ConfigType, "palette">;
    };

type chartOptionRendererLineItem =
  | {
      type: "label";
      title: string;
      description?: string;
      flex: number;
    }
  | {
      type: "option";
      option: Exclude<ConfigType, "palette">;
      flex: number;
    }
  | {
      type: "panel";
      title: string;
      lines: chartOptionRendererPanelLine[];
    };

export type chartOptionRendererLine =
  | {
      type: "composed";
      items: chartOptionRendererLineItem[];
      hidden?: boolean;
    }
  | {
      type: "standard";
      component: ConfigType;
      hidden?: boolean;
    };

interface chartOptionRendererGroup {
  label: string;
  lines: chartOptionRendererLine[];
}

export type chartOptionRenderer = chartOptionRendererGroup[];

interface ExtractCustomizationAxis {
  bottom: {
    editable: boolean;
    timeFormat?: boolean;
    values?: string[];
  };
  left: {
    editable: boolean;
    values?: string[];
  };
  right: {
    editable: boolean;
    values?: string[];
  };
}

export type ConfigType =
  | "font-size"
  | "hide-legend"
  | "show-total"
  | "labels"
  | "pie-labels-in-percent"
  | "labels-stacked"
  | "stack"
  | "inverted-arrow"
  | "palette"
  | "palette-continue"
  | "percent"
  | "map-picker"
  | "hide-confettis"
  | "show-percent"
  | "gauge-type"
  | "show-sparkline"
  | "show-row-number"
  | "table-theme"
  | "interractive-map-position"
  | "interractive-map-marker-radius"
  | "interractive-map-disable-zoom"
  | "interractive-map-pin-cluster-enabled"
  | "funnel-direction"
  | "funnel-compare-with"
  | "calendar-type"
  | "calendar-month-spacing"
  | "stacked-area-stacking"
  | "retention-percent"
  | "retention-colors"
  | "retention-total"
  | "table-comparison-value"
  | "table-comparison-delta-value"
  | "table-comparison-delta-percent"
  | "waterfall-timeserie-color-up"
  | "waterfall-timeserie-color-down"
  | "waterfall-timeserie-color-bounds";

type ComponentType =
  | "Checkbox"
  | "Switch"
  | "Input"
  | "PalettePicker"
  | "PalettePickerContinue"
  | "FontPicker"
  | "MapPicker"
  | "GaugeType"
  | "TableTheme"
  | "MapPosition"
  | "MapMarkerRadius"
  | "FunnelDirection"
  | "VisibilityToggle"
  | "VisibilityToggleResersed"
  | "FunnelCompareWith"
  | "GaugeChartDisplayTotal"
  | "ComparisonArrowColor"
  | "PieLabelValueDisplay"
  | "CalendarType"
  | "CalendarMonthSpacing"
  | "StackedAreaStacking"
  | "ColorPicker";

interface FontSize {
  "font-size"?: string;
}
interface HideLegendChartOption {
  "hide-legend"?: boolean;
}
interface LabelChartOption {
  label?: boolean;
}
interface UnstackChartOption {
  unstack?: boolean;
}
interface PercentChartOption {
  percent?: boolean;
}
interface PaletteChartOption {
  palette?: IPalette;
}
interface PaletteContinueChartOption {
  "palette-continue"?: IPalette;
}
interface MapPickerChartOption {
  map?: string;
} // to fix
interface InvertedArrowChartOption {
  "inverted-arrow"?: boolean;
}
interface SeriesChartOption {
  series?: {
    [key: string]: {
      label?: string;
      color?: string;
      axis?: "left" | "right";
      type?: "bar" | "line" | "area" | "scatter";
      showVisualization?: boolean;
      conditionalFormatting?: IConditionalFormatterRule[];
      palette?: any;
    };
  };
}

interface DimensionChartOption {
  dimensions?: {
    [key: string]: {
      label?: string;
      momentFormat?: string;
    };
  };
}

interface AxisChartOption {
  axis: AxisChartOptionDefinition;
}
interface HideConfettisChartOption {
  "hide-confettis"?: boolean;
}
interface ShowPercentChartOption {
  "show-percent"?: boolean;
}
interface ShowGrandTotalChartOption {
  "show-total"?: boolean;
}
interface GaugeTypeChartOption {
  "gauge-type"?: "line" | "circle" | "dashboard";
}
interface ShowSparklineChartOption {
  "show-sparkline"?: boolean;
}
interface ShowRowNumberOption {
  "show-row-number"?: boolean;
}
interface TableThemeOption {
  "table-theme"?: "standard" | "light";
}
interface MapMarkerRadiusPickerValue {
  "interractive-map-marker-radius"?: IMapMarkerRadiusPickerValue;
}
interface MapMarkerRadiusPickerValue {
  "interractive-map-position"?: IMapPositionPickerValue;
}
interface MapMarkerRadiusPickerValue {
  "interractive-map-disable-zoom"?: boolean;
}
interface MapMarkerClusterEnabled {
  "interractive-map-pin-cluster-enabled"?: boolean;
}
interface FunnelDirectionChartOption {
  "funnel-direction"?: FunnelChartDirection;
}
interface FunnelCompareWithChartOption {
  "funnel-compare-with"?: FunnelCompareWith;
}

interface CalendarTypeChartOption {
  "calendar-type"?: "yearly" | "timeframe";
}
interface CalendarMonthSpacingOption {
  "calendar-month-spacing"?: number;
}

interface StackedAreaStackingOption {
  "stacked-area-stacking"?: "normal" | "percent";
}

interface RetentionPercent {
  "retention-percent"?: boolean;
}

interface RetentionColors {
  "retention-colors"?: boolean;
}

interface RetentionTotal {
  "retention-total"?: boolean;
}

interface TableComparisonValue {
  "table-comparison-value"?: boolean;
}

interface TableComparisonDeltaValue {
  "table-comparison-delta-value"?: boolean;
}

interface TableComparisonDeltaPercent {
  "table-comparison-delta-percent"?: boolean;
}

interface WaterfallTimeserieColorUp {
  "waterfall-timeserie-color-up"?: string;
}

interface WaterfallTimeserieColorDown {
  "waterfall-timeserie-color-down"?: string;
}

interface WaterfallTimeserieColorBounds {
  "waterfall-timeserie-color-bounds"?: string;
}

interface ISummary {
  summary?: ISummaryQueryOptions;
}

export interface ISummaryQueryOptions {
  metrics?: {
    allow?: boolean;
    whiteList?: string[];
  };
  dimensions?: {
    allow?: boolean;
    whiteList?: string[];
  };
  time?: {
    allow?: boolean;
    whiteList?: string[];
  };
}

export type ChartOption = FontSize &
  HideLegendChartOption &
  LabelChartOption &
  UnstackChartOption &
  InvertedArrowChartOption &
  SeriesChartOption &
  AxisChartOption &
  PaletteChartOption &
  PaletteContinueChartOption &
  MapPickerChartOption &
  PercentChartOption &
  HideConfettisChartOption &
  ShowPercentChartOption &
  GaugeTypeChartOption &
  ShowSparklineChartOption &
  ShowRowNumberOption &
  TableThemeOption &
  ShowGrandTotalChartOption &
  MapMarkerRadiusPickerValue &
  MapMarkerRadiusPickerValue &
  MapMarkerClusterEnabled &
  FunnelDirectionChartOption &
  FunnelCompareWithChartOption &
  CalendarTypeChartOption &
  CalendarMonthSpacingOption &
  StackedAreaStackingOption &
  DimensionChartOption &
  RetentionPercent &
  RetentionColors &
  RetentionTotal &
  TableComparisonValue &
  TableComparisonDeltaValue &
  TableComparisonDeltaPercent &
  WaterfallTimeserieColorUp &
  WaterfallTimeserieColorDown &
  WaterfallTimeserieColorBounds &
  ISummary;

interface IChartOptionsProps {
  form: FormInstance;
  query: ILagoonQuery;
  chartOptions?: ChartOption;
  chartType: ChartType;
  analysisType: IAnalysisType;
  dimensions?: ExtractCustomizationDimension;
  series?: ExtractCustomizationSeries;
  axis?: ExtractCustomizationAxis;
  onChange?: () => void;
  showSummary?: boolean;
  availableDimensions: AvailableDimension[];
  availableMetrics: AvailableMetric[];
}

export const getChartOptionValue = (
  ChartOption: ChartOption,
  key: ConfigType,
  chartType: ChartType
) => {
  if (typeof ChartOption?.[chartOptionsDefinition[key].name] !== "undefined") {
    return ChartOption?.[chartOptionsDefinition[key].name];
  }
  if (
    typeof chartOptionsDefinition[key]?.chartSpecificDefaultValue?.[
      chartType
    ] !== "undefined"
  ) {
    return chartOptionsDefinition[key]?.chartSpecificDefaultValue?.[chartType];
  }
  return chartOptionsDefinition[key]?.defaultValue;
};

type ChartOptionsDefinition = {
  [key in ConfigType]: {
    name: string;
    label: string;
    description: React.ReactNode;
    component: ComponentType;
    placeholder?: string;
    defaultValue?: any;
    chartSpecificDefaultValue?: {
      [key in ChartType]?: any;
    };
    flex?: number;
    valuePropName?: string;
    displayInPanel?: boolean;
  };
};

const renderComponent = (componentType: ComponentType) => {
  switch (componentType) {
    case "CalendarMonthSpacing":
      return (
        <Slider
          defaultValue={0}
          min={0}
          max={40}
          tooltip={{
            formatter: (value: number) => `${value}px`,
          }}
        />
      );
    case "CalendarType":
      return (
        <Select<"yearly" | "timeframe"> style={{ width: "100%" }}>
          <Select.Option value={"yearly"}>Yearly</Select.Option>
          <Select.Option value={"timeframe"}>Timeframe</Select.Option>
        </Select>
      );
    case "PieLabelValueDisplay":
      return (
        <Select<boolean> style={{ width: "100%" }}>
          <Select.Option value={false}>Value</Select.Option>
          <Select.Option value={true}>Percent</Select.Option>
        </Select>
      );
    case "VisibilityToggleResersed":
      return (
        <SwitchIcons
          icons={{
            true: <EyeInvisibleOutlined />,
            false: <EyeOutlined />,
          }}
        />
      );
    case "VisibilityToggle":
      return (
        <SwitchIcons
          icons={{
            true: <EyeOutlined />,
            false: <EyeInvisibleOutlined />,
          }}
        />
      );
    case "Checkbox":
      return <Checkbox />;
    case "Input":
      return <Input />;
    case "Switch":
      return <Switch style={{ margin: "2px 0" }} />;
    case "PalettePicker":
      return <PalettePicker />;
    case "PalettePickerContinue":
      return <PalettePicker restrict="continue" />;
    case "MapPicker":
      return <MapPicker />;
    case "FontPicker":
      return (
        <InputNumber
          style={{ width: "100%" }}
          min={12}
          max={192}
          addonAfter="px"
        />
      );
    case "FunnelDirection":
      return (
        <Select<"horizontal" | "vertical"> style={{ width: "100%" }}>
          <Select.Option value="horizontal">Horizontal</Select.Option>
          <Select.Option value="vertical">Vertical</Select.Option>
        </Select>
      );
    case "FunnelCompareWith":
      return (
        <Select<"first-value" | "previous-value">
          style={{ width: "100%" }}
          popupMatchSelectWidth={false}
        >
          <Select.OptGroup label="Compare with">
            <Select.Option value="first-value">First value</Select.Option>
            <Select.Option value="previous-value">Previous value</Select.Option>
          </Select.OptGroup>
        </Select>
      );
    case "GaugeType":
      return (
        <Select<"line" | "dashboard"> style={{ width: "100%" }}>
          <Select.Option value="line">Line</Select.Option>
          <Select.Option value="dashboard">Circle</Select.Option>
        </Select>
      );
    case "MapPosition":
      return <MapPositionPicker />;
    case "MapMarkerRadius":
      return <MapMarkerRadiusPicker />;
    case "TableTheme":
      return (
        <Select<"standard" | "light"> style={{ width: "100%" }}>
          <Select.Option value="standard">Standard</Select.Option>
          <Select.Option value="light">Light</Select.Option>
        </Select>
      );
    case "GaugeChartDisplayTotal":
      return (
        <Select<boolean> style={{ width: "100%" }}>
          <Select.Option value={false}>Value</Select.Option>
          <Select.Option value={true}>Percent</Select.Option>
        </Select>
      );
    case "ComparisonArrowColor":
      return (
        <Select<boolean> style={{ width: "100%" }}>
          <Select.Option value={false}>Regular</Select.Option>
          <Select.Option value={true}>Inverted</Select.Option>
        </Select>
      );
    case "StackedAreaStacking":
      return (
        <Select<"normal" | "percent"> style={{ width: "100%" }}>
          <Select.Option value={"normal"}>Stack</Select.Option>
          <Select.Option value={"percent"}>Stack - 100%</Select.Option>
        </Select>
      );
    case "ColorPicker":
      return <ColorPicker />;
  }
};

const chartOptionsDefinition: ChartOptionsDefinition = {
  "waterfall-timeserie-color-up": {
    name: "waterfall-timeserie-color-up",
    label: "Color up",
    description: "",
    component: "ColorPicker",
    defaultValue: "rgba(150, 182, 87, 1)",
  },
  "waterfall-timeserie-color-down": {
    name: "waterfall-timeserie-color-down",
    label: "Color down",
    description: "",
    component: "ColorPicker",
    defaultValue: "rgba(255, 136, 133, 1)",
  },
  "waterfall-timeserie-color-bounds": {
    name: "waterfall-timeserie-color-bounds",
    label: "Color bounds",
    description: "",
    component: "ColorPicker",
    defaultValue: "rgba(190, 190, 190, 1)",
  },
  "retention-percent": {
    name: "retention-percent",
    label: "Percent",
    description: "",
    component: "VisibilityToggle",
    defaultValue: true,
  },
  "retention-colors": {
    name: "retention-colors",
    label: "Color",
    description: "",
    component: "VisibilityToggle",
    defaultValue: true,
  },
  "table-comparison-value": {
    name: "table-comparison-value",
    label: "Comparison value",
    description: "",
    component: "VisibilityToggle",
    defaultValue: true,
    chartSpecificDefaultValue: {
      table: true,
    },
  },
  "table-comparison-delta-value": {
    name: "table-comparison-delta-value",
    label: "Comparison Δ value",
    description: "",
    component: "VisibilityToggle",
    defaultValue: false,
    chartSpecificDefaultValue: {
      table: false,
    },
  },
  "table-comparison-delta-percent": {
    name: "table-comparison-delta-percent",
    label: "Comparison Δ %",
    description: "",
    component: "VisibilityToggle",
    defaultValue: false,
    chartSpecificDefaultValue: {
      table: false,
    },
  },
  "retention-total": {
    name: "retention-total",
    label: "Total",
    description: "",
    component: "VisibilityToggle",
    defaultValue: false,
  },
  "interractive-map-pin-cluster-enabled": {
    name: "interractive-map-pin-cluster-enabled",
    label: "Clusters",
    description: "",
    component: "VisibilityToggle",
    defaultValue: false,
  },
  "hide-legend": {
    name: "hide-legend",
    label: "Legend",
    description: "",
    component: "VisibilityToggleResersed",
    defaultValue: false,
    chartSpecificDefaultValue: {
      waterfall: true,
    },
  },
  labels: {
    name: "label",
    label: "Label",
    description: "",
    component: "VisibilityToggle",
    defaultValue: false,
    chartSpecificDefaultValue: {
      treemap: true,
      funnel: true,
      calendar: true,
      sunburst: true,
      pie: true,
      waterfall: true,
      "waterfall-timeserie": true,
    },
  },
  "pie-labels-in-percent": {
    name: "pie-labels-in-percent",
    label: "Label format",
    description:
      "If labels are shown, display labels in percentage instead of in value",
    component: "PieLabelValueDisplay",
    defaultValue: false,
    flex: 1,
  },
  "labels-stacked": {
    name: "label-stacked",
    label: "Stack label",
    description: "Display the total label for each stack",
    component: "VisibilityToggle",
    defaultValue: false,
  },
  "show-percent": {
    name: "show-percent",
    label: "Label format",
    description: "",
    component: "GaugeChartDisplayTotal",
    defaultValue: true,
    flex: 1,
  },
  "font-size": {
    name: "font-size",
    label: "Font",
    description: "",
    component: "FontPicker",
    flex: 1,
    defaultValue: null,
  },
  "show-total": {
    name: "show-total",
    label: "Total",
    description: "",
    component: "VisibilityToggle",
    defaultValue: false,
    chartSpecificDefaultValue: {
      waterfall: true,
      gauge: true,
    },
  },
  stack: {
    name: "unstack",
    label: "Unstack",
    description: "",
    component: "Switch",
    defaultValue: false,
    valuePropName: "checked",
  },
  percent: {
    name: "percent",
    label: "Percent",
    description: "",
    component: "Switch",
    defaultValue: false,
    valuePropName: "checked",
  },
  "inverted-arrow": {
    name: "inverted-arrow",
    label: "Comparison color",
    description: "Revert the comparison color",
    component: "ComparisonArrowColor",
    flex: 1,
    defaultValue: false,
  },
  palette: {
    name: "palette",
    label: "Palette",
    description: "",
    component: "PalettePicker",
  },
  "palette-continue": {
    name: "palette-continue",
    label: "Palette",
    description: "",
    component: "PalettePickerContinue",
  },
  "map-picker": {
    name: "map",
    label: "Map",
    description: (
      <>
        📚 Read our map{" "}
        <Link
          href="https://docs.whaly.io/data-management/explorations/create-a-chart/worldmap-chart"
          target="whalydoc"
        >
          documention
        </Link>
      </>
    ),
    component: "MapPicker",
    defaultValue: "world",
    flex: 1,
  },
  "hide-confettis": {
    name: "hide-confettis",
    label: "Confettis",
    description:
      "Display a confetti celebration when total is reached of exceeded",
    component: "VisibilityToggleResersed",
    defaultValue: false,
  },
  "gauge-type": {
    name: "gauge-type",
    label: "Gauge type",
    description: "",
    component: "GaugeType",
    defaultValue: "line",
    flex: 1,
  },
  "show-sparkline": {
    name: "show-sparkline",
    label: "Sparkline",
    description: "",
    component: "VisibilityToggle",
    defaultValue: true,
  },
  "show-row-number": {
    name: "show-row-number",
    label: "Row number",
    description: "",
    component: "VisibilityToggle",
    defaultValue: false,
  },
  "interractive-map-position": {
    name: "interractive-map-position",
    label: "Map position",
    description: "",
    component: "MapPosition",
    defaultValue: false,
    displayInPanel: true,
  },
  "interractive-map-marker-radius": {
    name: "interractive-map-marker-radius",
    label: "Marker type",
    description: "",
    component: "MapMarkerRadius",
    defaultValue: false,
    displayInPanel: true,
  },
  "interractive-map-disable-zoom": {
    name: "interractive-map-disable-zoom",
    label: "Zoom",
    description: "",
    component: "VisibilityToggleResersed",
    defaultValue: false,
  },
  "funnel-direction": {
    name: "funnel-direction",
    label: "Direction",
    description: "",
    component: "FunnelDirection",
    defaultValue: "horizontal",
    flex: 1,
  },
  "funnel-compare-with": {
    name: "funnel-compare-with",
    label: "Compare with",
    description: "",
    component: "FunnelCompareWith",
    defaultValue: "first-value",
    flex: 1,
  },
  "table-theme": {
    name: "table-theme",
    label: "Theme",
    description: "",
    component: "TableTheme",
    defaultValue: "standard",
    flex: 1,
  },
  "calendar-type": {
    name: "calendar-type",
    label: "Calendar type",
    description: "",
    component: "CalendarType",
    defaultValue: "yearly",
    flex: 1,
  },
  "calendar-month-spacing": {
    name: "calendar-month-spacing",
    label: "Month spacing",
    description: "",
    component: "CalendarMonthSpacing",
    defaultValue: 0,
    flex: 1,
  },
  "stacked-area-stacking": {
    name: "stacked-area-stacking",
    label: "Stacking",
    description: null,
    component: "StackedAreaStacking",
    defaultValue: "stacked",
    flex: 1,
  },
};

type Props = IChartOptionsProps & InjectedOrgProps;

function ChartOptions(props: Props) {
  const {
    form,
    chartOptions,
    chartType,
    series,
    analysisType,
    onChange,
    org,
    query,
    dimensions,
    showSummary,
    availableDimensions,
    availableMetrics,
  } = props;

  const debouncedChange = useDebouncedCallback(onChange, 500);
  const prevChartType = usePrevious(chartType);

  const [initialValues, setInitialValues] = useState<ChartOption>(() => {
    let iv: ChartOption = chartOptions;
    if (!iv) {
      iv = {
        axis: {
          bottom: {},
          left: {},
          right: {},
        },
      };

      Object.keys(chartOptionsDefinition).forEach((k: ConfigType) => {
        if (chartOptionsDefinition[k].defaultValue !== undefined) {
          let defaultValue = chartOptionsDefinition[k].defaultValue;
          if (
            chartOptionsDefinition[k].chartSpecificDefaultValue?.[chartType]
          ) {
            defaultValue =
              chartOptionsDefinition[k].chartSpecificDefaultValue?.[chartType];
          }
          iv[chartOptionsDefinition[k].name] = defaultValue;
        }
      });
    }
    return iv;
  });

  useEffect(() => {
    if (!prevChartType) return;
    let iv = {
      axis: {
        bottom: {},
        left: {},
        right: {},
      },
    };
    Object.keys(chartOptionsDefinition).forEach((k: ConfigType) => {
      if (chartOptionsDefinition[k].defaultValue !== undefined) {
        let defaultValue = chartOptionsDefinition[k].defaultValue;
        if (chartOptionsDefinition[k].chartSpecificDefaultValue?.[chartType]) {
          defaultValue =
            chartOptionsDefinition[k].chartSpecificDefaultValue?.[chartType];
        }
        iv[chartOptionsDefinition[k].name] = defaultValue;
      }
    });
    setInitialValues(iv);
    form.resetFields();
    form.setFieldsValue(iv);
  }, [chartType]);

  const renderSingleAxis = (axis: "left" | "right" | "bottom") => {
    const axisInfo = {
      left: {
        label: chartType === "bar-horizontal" ? "Bottom axis" : "Left axis",
        icon: (
          <BorderBottomOutlined
            style={{ fontSize: 20 }}
            rotate={chartType === "bar-horizontal" ? 0 : 90}
          />
        ),
      },
      right: {
        label: chartType === "bar-horizontal" ? "Top axis" : "Right axis",
        icon: (
          <BorderBottomOutlined
            style={{ fontSize: 20 }}
            rotate={chartType === "bar-horizontal" ? 180 : 270}
          />
        ),
      },
      bottom: {
        label: chartType === "bar-horizontal" ? "Left axis" : "Bottom axis",
        icon: (
          <BorderBottomOutlined
            style={{ fontSize: 20 }}
            rotate={chartType === "bar-horizontal" ? 90 : 0}
          />
        ),
      },
    };

    const label = axisInfo[axis].label;
    const icon = axisInfo[axis].icon;

    const popover: PopoverProps = {
      title: label,
      content: (
        <Space direction="vertical" style={{ width: "100%" }}>
          Label:
          <Form.Item noStyle name={["axis", axis, "label"]}>
            <Input />
          </Form.Item>
          {(axis === "left" || axis === "right") &&
          ChartDefinition[chartType].axis[axis].scaleEditable ? (
            <div style={{ paddingTop: 12 }}>
              <b>Scale</b>
              <div style={{ display: "flex", gap: 8 }}>
                <div style={{ flex: 1 }}>
                  Min:
                  <Form.Item noStyle name={["axis", axis, "min"]}>
                    <InputNumber placeholder="auto" style={{ width: "100%" }} />
                  </Form.Item>
                </div>
                <div style={{ flex: 1 }}>
                  Max:
                  <Form.Item noStyle name={["axis", axis, "max"]}>
                    <InputNumber placeholder="auto" style={{ width: "100%" }} />
                  </Form.Item>
                </div>
                <div style={{ flex: 1 }}>
                  Interval:
                  <Form.Item noStyle name={["axis", axis, "tickInterval"]}>
                    <InputNumber placeholder="auto" style={{ width: "100%" }} />
                  </Form.Item>
                </div>
              </div>
            </div>
          ) : undefined}
          {axis === "bottom" &&
            analysisType &&
            ChartDefinition[chartType].axis[axis].isTimeFormattable(
              analysisType
            ) && (
              <>
                <span>
                  Time format{" "}
                  <Tooltip title="Click to open the documentation">
                    <a
                      style={{ color: "gray" }}
                      href="https://docs.whaly.io/data-management/explorations/create-a-chart/custom-time-format-in-time-series"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <QuestionCircleOutlined size={20} />
                    </a>
                  </Tooltip>
                </span>
                <Form.Item noStyle name={["axis", axis, "timeFormat"]}>
                  <Input />
                </Form.Item>
              </>
            )}
          {ChartDefinition[chartType].axis[axis].reorderable && (
            <>
              Order
              <Form.Item name={["axis", axis, "customOrder"]} noStyle>
                <CustomOrderedList
                  fallbackValues={
                    props.axis?.[axis].values ? props.axis?.[axis].values : []
                  }
                />
              </Form.Item>
            </>
          )}
        </Space>
      ),
    };

    return (
      <ChartOptionLine
        items={[
          {
            flex: 0,
            content: icon,
            popover: popover,
          },
          {
            flex: 1,
            content: label,
            popover: popover,
          },
          axis === "right" && {
            flex: 0,
            content: (
              <Form.Item name={["axis", "right", "enabled"]} noStyle>
                <SwitchIcons
                  icons={{
                    true: <EyeOutlined />,
                    false: <EyeInvisibleOutlined />,
                  }}
                />
              </Form.Item>
            ),
          },
          {
            flex: 0,
            type: "settings",
            popover: popover,
          },
        ]}
      />
    );
  };

  const renderAxis = () => {
    return (
      ChartDefinition[chartType].axis.bottom.editable && (
        <ChartOptionCollapse title="Axis">
          <Space direction="vertical" style={{ width: "100%" }}>
            {ChartDefinition[chartType].axis.bottom.editable &&
              renderSingleAxis("bottom")}
            {ChartDefinition[chartType].axis.left?.editable &&
              renderSingleAxis("left")}
            {ChartDefinition[chartType].axis.right?.editable &&
              renderSingleAxis("right")}
          </Space>
        </ChartOptionCollapse>
      )
    );
  };

  const chartOptionPaletteRenderer = (): React.ReactNode => {
    return (
      <Form.Item shouldUpdate={true} noStyle={true}>
        {() => {
          const currentPalette: IPalette | IPaletteSelection =
            form.getFieldValue("palette")
              ? form.getFieldValue("palette")
              : {
                  type: "PALETTE_SELECTION",
                  collection_name: "default",
                  palette_type:
                    typeof series.customColor === "string" &&
                    series.customColor.split("::")[1] === "continue"
                      ? "continue"
                      : "discrete",
                  palette_subtype:
                    typeof series.customColor === "string" &&
                    series.customColor.split("::")[1] === "continue"
                      ? "diverging"
                      : undefined,
                  index: 0,
                };

          const currentChartPalette = getSelectedPalette(org, currentPalette);
          const popover = {
            content: (
              <Form.Item name="palette" noStyle>
                <PaletteSelector value={currentPalette} />
              </Form.Item>
            ),
            overlayInnerStyle: {
              padding: 0,
            },
            overlayStyle: {
              width: 220,
            },
          };
          return (
            <ChartOptionLine
              items={[
                {
                  content: "Palette",
                  flex: 1,
                  popover: popover,
                },
                {
                  content: <PaletteRenderer {...currentChartPalette} />,
                  flex: 0,
                  popover: popover,
                },
              ]}
            />
          );
        }}
      </Form.Item>
    );
  };

  const chartOptionComponentRenderer = (
    component: Exclude<ConfigType, "palette">
  ): React.ReactNode => {
    const option = chartOptionsDefinition[component];
    const valuePropName = option.valuePropName;
    const node = (
      <Form.Item noStyle name={option.name} valuePropName={valuePropName}>
        {renderComponent(option.component)}
      </Form.Item>
    );
    return node;
  };

  const chartOptionRenderer = (
    template: chartOptionRenderer
  ): React.ReactNode => {
    return template?.map?.((group, groupIndex) => (
      <ChartOptionCollapse title={group.label} key={groupIndex}>
        <Space direction="vertical" style={{ width: "100%" }}>
          {group.lines?.map?.((line, lineIndex) => {
            if (line.type === "standard") {
              if (line.component === "palette") {
                // we are rendering the palette component
                return (
                  <React.Fragment key={lineIndex}>
                    {chartOptionPaletteRenderer()}
                  </React.Fragment>
                );
              } else {
                // we are rendering a standard component line
                const option = chartOptionsDefinition[line.component];
                const displayInPanel = option.displayInPanel;
                let label: React.ReactNode = option.label;
                if (option.description) {
                  label = (
                    <Space>
                      {option.label}
                      <Tooltip
                        title={option.description}
                        color="white"
                        overlayInnerStyle={{
                          color: "black",
                        }}
                      >
                        <QuestionCircleOutlined />
                      </Tooltip>
                    </Space>
                  );
                }

                const flex = typeof option.flex === "number" ? option.flex : 0;
                const style: React.CSSProperties = line.hidden
                  ? { display: "none" }
                  : null;
                const component = chartOptionComponentRenderer(line.component);
                if (!displayInPanel) {
                  return (
                    <ChartOptionLine
                      key={lineIndex}
                      style={style}
                      items={[
                        { flex: 1, content: label },
                        {
                          flex: flex,
                          content: component,
                        },
                      ]}
                    />
                  );
                } else {
                  const popover: PopoverProps = {
                    title: label,
                    content: component,
                  };
                  return (
                    <ChartOptionLine
                      key={lineIndex}
                      style={style}
                      items={[
                        { flex: 1, content: label, popover },
                        { flex: 0, type: "settings", popover },
                      ]}
                    />
                  );
                }
              }
            } else if (line.type === "composed") {
              // composed lines may contain a settings panel
              // we need to create the popover from the settings panel now
              // and apply it to "label" and "panel" line items
              // we also prevent two settings in the same line
              let popover: PopoverProps = null;
              const panel = line.items.filter(
                (lineItem) => lineItem.type === "panel"
              );
              if (panel.length > 1) {
                return <>Can't display two panels in same line</>;
              } else if (panel.length === 1 && panel[0].type === "panel") {
                const currentPanel = panel[0];
                const popContent = currentPanel.lines?.map?.(
                  (popLine, popLineIndex) => {
                    if (popLine.type === "label") {
                      return (
                        <React.Fragment key={popLineIndex}>
                          {popLine.title}
                        </React.Fragment>
                      );
                    } else if (popLine.type === "component") {
                      return (
                        <React.Fragment key={popLineIndex}>
                          {chartOptionComponentRenderer(popLine.component)}
                        </React.Fragment>
                      );
                    } else {
                      return null;
                    }
                  }
                );
                popover = {
                  title: currentPanel.title,
                  content: (
                    <Space direction="vertical" style={{ width: "100%" }}>
                      {popContent}
                    </Space>
                  ),
                };
              }
              const items: IChartOptionLineItem[] = line.items?.map?.(
                (lineItem, lineIndex) => {
                  if (lineItem.type === "label") {
                    let content: React.ReactNode = lineItem.title;
                    if (lineItem.description) {
                      content = (
                        <Space>
                          {lineItem.title}
                          <Tooltip
                            title={lineItem.description}
                            color="white"
                            overlayInnerStyle={{
                              color: "black",
                            }}
                          >
                            <QuestionCircleOutlined />
                          </Tooltip>
                        </Space>
                      );
                    }
                    return {
                      flex: lineItem.flex,
                      content: content,
                      key: lineIndex,
                      popover: popover,
                    };
                  } else if (lineItem.type === "option") {
                    const definition = chartOptionsDefinition[lineItem.option];
                    const component = renderComponent(definition.component);
                    const valuePropName = definition.valuePropName;
                    const content = (
                      <Form.Item
                        name={definition.name}
                        noStyle
                        valuePropName={valuePropName}
                      >
                        {component}
                      </Form.Item>
                    );

                    return {
                      flex: lineItem.flex,
                      content: content,
                      key: lineIndex,
                    };
                  } else if (lineItem.type === "panel") {
                    return {
                      flex: 0,
                      key: lineIndex,
                      type: "settings",
                      popover: popover,
                    };
                  } else {
                    return null;
                  }
                }
              );
              return <ChartOptionLine items={items} key={lineIndex} />;
            } else {
              return null;
            }
          })}
        </Space>
      </ChartOptionCollapse>
    ));
  };

  const renderChartOptions = () => {
    if (ChartDefinition[chartType].declarativeChartOptionRenderer) {
      const template = ChartDefinition[
        chartType
      ].declarativeChartOptionRenderer({
        query: query,
        chartOptions: chartOptions,
      });

      return chartOptionRenderer(template);
    } else {
      const config = ChartDefinition[chartType].config;
      const options = Array.isArray(config) ? config : config(analysisType);
      const template: chartOptionRenderer = [
        {
          label: "Chart options",
          lines: options?.map?.((option) => {
            return {
              type: "standard",
              component: option,
            };
          }),
        },
      ];
      return chartOptionRenderer(template);
    }
  };

  const renderDimensions = () => {
    return (
      dimensions?.dimensions?.length > 0 && (
        <ChartOptionCollapse title="Dimensions">
          <Space direction="vertical" style={{ width: "100%" }}>
            {dimensions.dimensions.map((d, i) => {
              const popover = {
                title: d.label ? d.label : d.key,
                content: (
                  <Space direction="vertical" style={{ width: "100%" }}>
                    {d.labelEditable && (
                      <>
                        Label:
                        <Form.Item
                          name={["dimensions", d.key, "label"]}
                          noStyle
                          getValueFromEvent={(args) => {
                            if (
                              args.target.value &&
                              typeof args.target.value === "string" &&
                              args.target.value.length > 0
                            ) {
                              return args.target.value;
                            } else {
                              return null;
                            }
                          }}
                        >
                          <Input />
                        </Form.Item>
                      </>
                    )}
                    {d.momentFormatEditable && (
                      <>
                        Moment format:
                        <Form.Item
                          name={["dimensions", d.key, "momentFormat"]}
                          noStyle
                          getValueFromEvent={(args) => {
                            if (
                              args.target.value &&
                              typeof args.target.value === "string" &&
                              args.target.value.length > 0
                            ) {
                              return args.target.value;
                            } else {
                              return null;
                            }
                          }}
                        >
                          <Input />
                        </Form.Item>
                      </>
                    )}
                  </Space>
                ),
              };

              return (
                <Form.Item shouldUpdate={true} noStyle={true} key={i}>
                  {() => {
                    return (
                      <ChartOptionLine
                        items={[
                          {
                            flex: 1,
                            content:
                              form.getFieldValue(["dimensions", d.key, "label"])
                                ?.length > 0
                                ? form.getFieldValue([
                                    "dimensions",
                                    d.key,
                                    "label",
                                  ])
                                : d.label,
                            popover: popover,
                          },
                          {
                            type: "settings",
                            flex: 0,
                            popover: popover,
                          },
                        ]}
                      />
                    );
                  }}
                </Form.Item>
              );
            })}
          </Space>
        </ChartOptionCollapse>
      )
    );
  };

  const renderSeries = () => {
    return (
      series &&
      series.series.length > 0 && (
        <ChartOptionCollapse title="Series">
          <Space direction="vertical" style={{ width: "100%" }}>
            {series.series.map((s, i) => {
              return (
                <Form.Item shouldUpdate={true} noStyle={true} key={i}>
                  {() => {
                    const chartPalettePayload: IPalette | IPaletteSelection =
                      form.getFieldValue(["palette"])
                        ? form.getFieldValue(["palette"])
                        : {
                            type: "PALETTE_SELECTION",
                            collection_name: "default",
                            palette_type:
                              typeof series.customColor === "string" &&
                              series.customColor.split("::")[1] === "continue"
                                ? "continue"
                                : "discrete",
                            palette_subtype:
                              typeof series.customColor === "string" &&
                              series.customColor.split("::")[1] === "continue"
                                ? "diverging"
                                : undefined,
                            index: 0,
                          };

                    const currentChartPalette: IPalette = getSelectedPalette(
                      org,
                      chartPalettePayload
                    );

                    const numberOfSeries =
                      series && series.series.length ? series.series.length : 1;

                    const currentChartPaletteGenerator = new PaletteGenerator({
                      ...currentChartPalette,
                      numberOfColors: numberOfSeries,
                    });

                    const seriePalette: IPalette | IPaletteSelection =
                      form.getFieldValue(["series", s.key, "palette"])
                        ? form.getFieldValue(["series", s.key, "palette"])
                        : {
                            type: "PALETTE_SELECTION",
                            collection_name: "default",
                            palette_type:
                              typeof series.customColor === "string" &&
                              series.customColor.split("::")[1] === "continue"
                                ? "continue"
                                : "discrete",
                            palette_subtype:
                              typeof series.customColor === "string" &&
                              series.customColor.split("::")[1] === "continue"
                                ? "diverging"
                                : undefined,
                            index: 0,
                          };

                    const currentSeriePalette = getSelectedPalette(
                      org,
                      seriePalette
                    );

                    const hasCustomLabel = series.customLabel;
                    const hasCustomType = series.customType.length > 0;
                    const hasVisualization = series.showVisualization;
                    const hasRightAxis =
                      ChartDefinition[chartType].axis.right?.editable &&
                      form.getFieldValue(["axis", "right", "enabled"]) === true;

                    const hasAdditionalOptions =
                      !!hasCustomLabel ||
                      !!hasRightAxis ||
                      !!hasCustomType ||
                      !!hasVisualization;

                    let popover: PopoverProps = null;
                    if (hasAdditionalOptions) {
                      popover = {
                        title: s.label,
                        content: (
                          <Space direction="vertical" style={{ width: "100%" }}>
                            {hasCustomLabel && (
                              <>
                                Label:
                                <Form.Item
                                  name={["series", s.key, "label"]}
                                  noStyle
                                  getValueFromEvent={(args) => {
                                    if (
                                      args.target.value &&
                                      typeof args.target.value === "string" &&
                                      args.target.value.length > 0
                                    ) {
                                      return args.target.value;
                                    } else {
                                      return null;
                                    }
                                  }}
                                >
                                  <Input />
                                </Form.Item>
                              </>
                            )}
                            {hasRightAxis && (
                              <>
                                Axis
                                <Form.Item
                                  name={["series", s.key, "axis"]}
                                  noStyle
                                >
                                  <Select style={{ width: "100%" }}>
                                    <Select.Option value="left">
                                      Left
                                    </Select.Option>
                                    <Select.Option value="right">
                                      Right
                                    </Select.Option>
                                  </Select>
                                </Form.Item>
                              </>
                            )}
                            {hasCustomType && (
                              <>
                                Type:
                                <Form.Item
                                  name={["series", s.key, "type"]}
                                  noStyle
                                >
                                  <Select style={{ width: "100%" }}>
                                    <Select.Option value="line">
                                      Line
                                    </Select.Option>
                                    <Select.Option value="bar">
                                      Bar
                                    </Select.Option>
                                    <Select.Option value="area">
                                      Area
                                    </Select.Option>
                                    <Select.Option value="scatter">
                                      Scatter
                                    </Select.Option>
                                  </Select>
                                </Form.Item>
                              </>
                            )}
                            {hasVisualization && (
                              <ChartOptionLine
                                items={[
                                  {
                                    flex: 1,
                                    content: "Vizualisation",
                                  },
                                  {
                                    flex: 0,
                                    content: (
                                      <Form.Item
                                        name={[
                                          "series",
                                          s.key,
                                          "showVisualization",
                                        ]}
                                        noStyle
                                        valuePropName="checked"
                                      >
                                        <Switch />
                                      </Form.Item>
                                    ),
                                  },
                                ]}
                              />
                            )}
                          </Space>
                        ),
                      };
                    }

                    return (
                      <ChartOptionLine
                        items={[
                          {
                            flex: 1,
                            content:
                              form.getFieldValue(["series", s.key, "label"])
                                ?.length > 0
                                ? form.getFieldValue(["series", s.key, "label"])
                                : s.label,
                            popover: popover,
                          },
                          series.conditionalFormatting && {
                            flex: 0,
                            content: (
                              <Form.Item
                                noStyle
                                name={[
                                  "series",
                                  s.key,
                                  "conditionalFormatting",
                                ]}
                              >
                                <WlyConditionalFormatterDrawer
                                  options={series.conditionalFormatting}
                                />
                              </Form.Item>
                            ),
                          },
                          (series.customColor === true ||
                            series.customColor === "color") && {
                            flex: 0,
                            content: (
                              <ColorRenderer
                                color={
                                  form.getFieldValue(["series", s.key, "color"])
                                    ? form.getFieldValue([
                                        "series",
                                        s.key,
                                        "color",
                                      ])
                                    : currentChartPaletteGenerator.getColorAtIndex(
                                        i
                                      )
                                }
                              />
                            ),
                            popover: {
                              overlayInnerStyle: { padding: 0 },
                              content: (
                                <Form.Item
                                  name={["series", s.key, "color"]}
                                  noStyle={true}
                                  valuePropName="color"
                                >
                                  <SketchPickerFormItem
                                    presetColors={currentChartPalette.colors}
                                    styles={{
                                      default: {
                                        picker: {
                                          width: "initial",
                                        },
                                      },
                                    }}
                                  />
                                </Form.Item>
                              ),
                            },
                          },
                          (series.customColor === "palette" ||
                            series.customColor === "palette::continue" ||
                            series.customColor === "palette::discrete") && {
                            flex: 0,
                            popover: {
                              overlayInnerStyle: {
                                padding: 0,
                              },
                              overlayStyle: {
                                width: 220,
                              },
                              content: (
                                <Form.Item
                                  name={["series", s.key, "palette"]}
                                  noStyle
                                >
                                  <PaletteSelector
                                    restrict={
                                      series.customColor.split("::")[1] as any
                                    }
                                  />
                                </Form.Item>
                              ),
                            },
                            content: (
                              <PaletteRenderer
                                size="small"
                                {...currentSeriePalette}
                              />
                            ),
                          },
                          hasAdditionalOptions && {
                            type: "settings",
                            flex: 0,
                            popover: popover,
                          },
                        ]}
                      />
                    );
                  }}
                </Form.Item>
              );
            })}
          </Space>
        </ChartOptionCollapse>
      )
    );
  };

  return (
    <div>
      <Form<ChartOption>
        layout="vertical"
        form={form}
        initialValues={initialValues}
        onFieldsChange={(e) => {
          // we need to reset custom series color when palette changes
          if (
            e.find(
              (a) => JSON.stringify(a.name) === JSON.stringify(["palette"])
            )
          ) {
            const field = form.getFieldsValue(["series"]);
            if (field && field.series) {
              Object.keys(field.series).forEach((k) => {
                field.series[k].color = undefined;
              });
              form.setFieldsValue({ series: field.series });
            }
          }
          // when enabling right axis we give it a default name to display it
          const rightAxisEnabled = e.find(
            (a) =>
              JSON.stringify(a.name) ===
              JSON.stringify(["axis", "right", "enabled"])
          );
          if (rightAxisEnabled && rightAxisEnabled?.value === true) {
            const rightAxisLabel = form.getFieldValue([
              "axis",
              "right",
              "label",
            ]);
            if (!rightAxisLabel || rightAxisLabel === "") {
              form.setFieldValue(["axis", "right", "label"], "Axis");
            }
          }
          // when disabling right axis we need to remove series attached to it
          if (
            e.find(
              (a) =>
                JSON.stringify(a.name) ===
                JSON.stringify(["axis", "right", "enabled"])
            )
          ) {
            const a = e.find(
              (a) =>
                JSON.stringify(a.name) ===
                JSON.stringify(["axis", "right", "enabled"])
            );
            if (a?.value === false) {
              const field = form.getFieldsValue(["series"]);
              if (field && field.series) {
                Object.keys(field.series).forEach((k) => {
                  field.series[k].axis = undefined;
                });
                form.setFieldsValue({ series: field.series });
              }
            }
          }
          if (debouncedChange) {
            debouncedChange();
          }
        }}
      >
        {/* Summary */}
        {showSummary && canDisplaySummary(query) ? (
          <ChartOptionSummary
            availableDimensions={availableDimensions}
            availableMetrics={availableMetrics}
            query={query}
          />
        ) : null}

        {/* Chart options */}
        {renderChartOptions()}

        {/* Axis */}
        {renderAxis()}

        {/* Dimensions */}
        {renderDimensions()}

        {/* Series */}
        {renderSeries()}
      </Form>
    </div>
  );
}

export default compose<Props, IChartOptionsProps>(WithOrg)(ChartOptions);
