import { CloseOutlined } from "@ant-design/icons";
import type { SqlQuery } from "@cubejs-client/core";
import { Button, Drawer } from "antd";
import type {
  AsyncCachedData,
  AsyncData,
} from "../../../../helpers/typescriptHelpers";
import type {
  IQueryTraceResult,
  WlyResultSet,
} from "../../../../services/LagoonService";
import ItemPerformance from "../../../performance-console/item-performance/ItemPerformance";
import * as Toolbar from "../../../spreadsheet/toolbar/Toolbar";

import "./TraceDrawer.scss";

export interface ITraceModalProps {
  visible: boolean;
  onClose: () => void;
  trace?: AsyncData<IQueryTraceResult>;
  data?: AsyncCachedData<WlyResultSet<any>>;
  sql?: SqlQuery | string;
}

export function TraceDrawer(props: ITraceModalProps) {
  const { visible, trace, data, sql, onClose } = props;

  return (
    <Drawer
      title="Console"
      placement="bottom"
      open={visible}
      onClose={onClose}
      height={540}
      styles={{
        body: { padding: 0 },
        header: { display: "none" },
      }}
    >
      {visible && (
        <div className="trace-drawer">
          <Toolbar.Toolbar>
            <Toolbar.ViewName>Console</Toolbar.ViewName>
            <Toolbar.Separator />
            <Toolbar.Item color="green" active={true}>
              Performance
            </Toolbar.Item>

            <div style={{ flex: 1 }} />
            <Button
              type="text"
              shape="circle"
              onClick={onClose}
              icon={<CloseOutlined />}
            />
          </Toolbar.Toolbar>
          <div className="trace-drawer-content">
            <div className="trace-drawer-content-wrapper">
              <ItemPerformance
                traceData={trace}
                sql={sql}
                name={"Query"}
                data={data}
              />
            </div>
          </div>
        </div>
      )}
    </Drawer>
  );
}
