import type { IEmailRendererConfig } from "../domain";
import type { IEmailWidgetDividerConfig } from "./domain";
import { EmailWidgetDividerEditor } from "./editor/EmailWidgetDividerEditor";
import { EmailWidgetDividerMjml } from "./mjml/EmailWidgetDividerMjml";
import { EmailWidgetDividerRenderer } from "./web-widget/EmailWidgetDividerRenderer";

export const emailWidgetDividerDefinition: IEmailRendererConfig<IEmailWidgetDividerConfig> =
  {
    parseConfig: (widget) => {
      const defaultValue: IEmailWidgetDividerConfig = {
        borderWidth: 1,
        spacing: "compact",
        color: "#F3F3F3",
      };

      if (!widget.config) {
        return defaultValue;
      }

      try {
        const config: IEmailWidgetDividerConfig = JSON.parse(widget.config);
        return config;
      } catch (err) {
        console.error(err);
        return defaultValue;
      }
    },
    renderEditor: (widget, object, record, conf, org, user, form, datasets) => {
      return (
        <EmailWidgetDividerEditor
          widget={widget}
          object={object}
          record={record}
          conf={conf}
          org={org}
          user={user}
          form={form}
          datasets={datasets}
        />
      );
    },
    renderComponent: (widget, object, record, conf, org, user, edit) => {
      return (
        <EmailWidgetDividerRenderer
          widget={widget}
          object={object}
          record={record}
          conf={conf}
          org={org}
          user={user}
          edit={edit}
        />
      );
    },
    renderMjml: EmailWidgetDividerMjml,
  };
