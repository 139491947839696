import { InputNumber, Select, Space } from "antd";
import type { Moment } from "moment";
import moment from "moment";
import { useEffect, useState } from "react";
import DatePicker from "../../../../../components/datepicker/DatePicker";

const { Option } = Select;

interface IAdvancedDatePickerPanelProps {
  title: string;
  value?: AdvancedDatePickerPeriod;
  onChange?: (value: AdvancedDatePickerPeriod) => void;
}

type start =
  | "today"
  | "start_day"
  | "start_week"
  | "start_month"
  | "start_quarter"
  | "start_year"
  | "end_day"
  | "end_week"
  | "end_month"
  | "end_quarter"
  | "end_year"
  | "fixed";
type fixed = Moment;
type operator = "plus" | "minus";
type difference = number;
type period = "hour" | "day" | "week" | "month" | "quarter" | "year";
type roundTo =
  | "nothing"
  | "start_hour"
  | "end_hour"
  | "start_day"
  | "end_day"
  | "start_week"
  | "start_month"
  | "start_quarter"
  | "start_year"
  | "end_week"
  | "end_month"
  | "end_quarter"
  | "end_year";

export interface AdvancedDatePickerPeriod {
  startOn: start;
  fixedDate: fixed;
  operator: operator;
  difference: difference;
  period: period;
  roundTo: roundTo;
}

function AdvancedDatePickerPanel(props: IAdvancedDatePickerPanelProps) {
  const { value, title, onChange } = props;
  const [start, setStart] = useState<start>(value ? value.startOn : "today");
  const [fixedDate, setFixedDate] = useState<fixed>(
    value && typeof value.fixedDate === "string"
      ? moment(value.fixedDate)
      : moment()
  );
  const [operator, setOperator] = useState<operator>(value?.operator ?? "plus");
  const [difference, setDifference] = useState<difference>(
    value?.difference ?? 0
  );
  const [period, setPeriod] = useState<period>(value?.period ?? "day");
  const [roundTo, setRoundTo] = useState<roundTo>(value?.roundTo ?? "nothing");

  useEffect(() => {
    onChange(computeOutput());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [start, fixedDate, operator, difference, period, roundTo]);

  useEffect(() => {
    if (value?.startOn) {
      setStart(value.startOn);
    }
    if (value?.fixedDate) {
      setFixedDate(value.fixedDate);
    }
    if (value?.operator) {
      setOperator(value.operator);
    }
    if (value?.difference) {
      setDifference(value.difference);
    }
    if (value?.period) {
      setPeriod(value.period);
    }
    if (value?.roundTo) {
      setRoundTo(value.roundTo);
    }
  }, [value]);

  const renderRoundOptions = (): { value: roundTo; label: string }[] => {
    const options: { value: roundTo; label: string }[] = [];
    options.push({ value: "nothing", label: "Don't round" });
    if (start === "fixed") return options;
    if (period === "hour") {
      options.push({ value: "start_hour", label: "Start of the hour" });
      options.push({ value: "end_hour", label: "End of the hour" });
      return options;
    }
    if (period === "day") {
      options.push({ value: "start_day", label: "Start of the day" });
      options.push({ value: "end_day", label: "End of the day" });
      return options;
    }
    if (period === "week") {
      options.push({ value: "start_week", label: "Start of the week" });
      options.push({ value: "end_week", label: "End of the week" });
      return options;
    }
    if (period === "month") {
      options.push({ value: "start_month", label: "Start of the month" });
      options.push({ value: "end_month", label: "End of the month" });
      return options;
    }
    if (period === "quarter") {
      options.push({ value: "start_quarter", label: "Start of the quarter" });
      options.push({ value: "end_quarter", label: "End of the quarter" });
      return options;
    }
    if (period === "year") {
      options.push({ value: "start_year", label: "Start of the year" });
      options.push({ value: "end_year", label: "End of the year" });
      return options;
    }
  };

  const computeOutput = (): AdvancedDatePickerPeriod => {
    return {
      startOn: start,
      fixedDate: fixedDate,
      operator: operator,
      difference: difference,
      period: period,
      roundTo: roundTo,
    };
  };

  return (
    <div>
      <Space direction="vertical" style={{ width: "100%" }}>
        {title}
        <Select
          onChange={(value) => {
            setStart(value);
            if (value === "fixed") {
              setRoundTo("nothing");
            }
          }}
          value={start}
          style={{ width: "100%" }}
        >
          <Option key="today" value="today">
            Now
          </Option>
          <Option key="fixed" value="fixed">
            Fixed date
          </Option>
          <Option key="start_day" value="start_day">
            Beginning of the day
          </Option>
          <Option key="start_week" value="start_week">
            Beginning of the week
          </Option>
          <Option key="start_month" value="start_month">
            Beginning of the month
          </Option>
          <Option key="start_quarter" value="start_quarter">
            Beginning of the quarter
          </Option>
          <Option key="start_year" value="start_year">
            Beginning of the year
          </Option>
          <Option key="end_day" value="end_day">
            End of the day
          </Option>
          <Option key="end_week" value="end_week">
            End of the week
          </Option>
          <Option key="end_month" value="end_month">
            End of the month
          </Option>
          <Option key="end_quarter" value="end_quarter">
            End of the quarter
          </Option>
          <Option key="end_year" value="end_year">
            End of the year
          </Option>
        </Select>
        {start === "fixed" && (
          <DatePicker
            onChange={(value) => setFixedDate(value)}
            value={fixedDate}
            mode="date"
            style={{ width: "100%" }}
            showTime
          />
        )}
        {start !== "fixed" && (
          <>
            ... then:
            <InputNumber
              onChange={(value) => setDifference(value)}
              defaultValue={difference}
              min={0}
              style={{ width: "100%" }}
              addonBefore={
                <Select
                  onChange={(value) => setOperator(value)}
                  value={operator}
                  style={{ width: 95 }}
                >
                  <Option key="plus" value="plus">
                    add
                  </Option>
                  <Option key="minus" value="minus">
                    subtract
                  </Option>
                </Select>
              }
              addonAfter={
                <Select
                  onChange={(value) => {
                    setRoundTo("nothing");
                    setPeriod(value);
                  }}
                  value={period}
                  style={{ width: 90 }}
                >
                  <Option key="hour" value="hour">
                    hour{difference > 1 && "s"}
                  </Option>
                  <Option key="day" value="day">
                    day{difference > 1 && "s"}
                  </Option>
                  <Option key="week" value="week">
                    week{difference > 1 && "s"}
                  </Option>
                  <Option key="month" value="month">
                    month{difference > 1 && "s"}
                  </Option>
                  <Option key="quarter" value="quarter">
                    quarter{difference > 1 && "s"}
                  </Option>
                  <Option key="year" value="year">
                    year{difference > 1 && "s"}
                  </Option>
                </Select>
              }
            />
            <div
              style={{
                display:
                  renderRoundOptions().length && renderRoundOptions().length > 1
                    ? "inherit"
                    : "none",
              }}
            >
              <Space direction="vertical" style={{ width: "100%" }}>
                ... finally, round to:
                <Select
                  style={{ width: "100%" }}
                  onChange={(value) => setRoundTo(value)}
                  value={roundTo}
                >
                  {renderRoundOptions().map((option, i) => {
                    return (
                      <Option key={i} value={option.value}>
                        {option.label}
                      </Option>
                    );
                  })}
                </Select>
              </Space>
            </div>
          </>
        )}
      </Space>
    </div>
  );
}

export default AdvancedDatePickerPanel;
