import { getObjectColumns } from "../../../../../object/domain";
import type { IWidgetEmailRendererProps } from "../../domain";
import type { IEmailWidgetRelatedListConfig } from "../domain";

interface IEmailRelatedListRendererProps
  extends IWidgetEmailRendererProps<IEmailWidgetRelatedListConfig> {}

export function EmailRelatedListRenderer(
  props: IEmailRelatedListRendererProps
) {
  const { conf, record, object, user } = props;

  const objectColumns = getObjectColumns(object);

  return (
    <div
      style={{
        display: "flex",
        flexWrap: "wrap",
        gap: 8,
      }}
    >
      test
    </div>
  );
}
