import { Modal, Tag } from "antd";
import * as React from "react";
import { compose } from "../../components/compose/WlyCompose";
import Error from "../../components/error/Error";
import Feednack from "../../components/layout/feedback/feedback";
import Loading from "../../components/layout/feedback/loading";
import UserPicker from "../../components/user/picker/UserPicker";
import type { AsyncData } from "../../helpers/typescriptHelpers";
import type { IUserGravatarInfo } from "../../interfaces/user";
import GraphQLService from "../../services/graphql/GraphQLService";
import type { InjectedOrgProps } from "../orgs/WithOrg";
import WithOrg from "../orgs/WithOrg";
import "./OwnerSelectionModal.scss";

interface IOwnerSelectionModalProps {
  visible: boolean;
  onClose: () => void;
  currentOwnerId?: string;
  onOwnerSelection: (ownerId: string) => Promise<void>;
}

type Props = InjectedOrgProps & IOwnerSelectionModalProps;

function OwnerSelectionModal(props: Props) {
  const { visible, onClose, org, user, currentOwnerId, onOwnerSelection } =
    props;

  const [users, setUsers] = React.useState<AsyncData<IUserGravatarInfo[]>>({
    status: "initial",
  });
  const [saving, setSaving] = React.useState<boolean>(false);

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        setUsers({ status: "loading" });
        const usersRes = await GraphQLService<{
          allUsers: IUserGravatarInfo[];
        }>(
          `
        query getOrgUsers($orgId: ID!, $hideAdmin: Boolean) {
          allUsers(where: { 
            isDeleted_not: true, 
            isPublicGuestUser: false,
            isAdmin_not: $hideAdmin,
            roles_some: { 
              org: { 
                id: $orgId 
              },
              role_in: [member, owner],
              isDeleted_not: true 
            } 
          }) {
            id
            firstName
            lastName
            avatarColor
            gravatar
            email
          }
        }
        `,
          {
            orgId: org.id,
            hideAdmin: !user.isAdmin,
          }
        );
        setUsers({ status: "success", data: usersRes.allUsers });
      } catch (err) {
        console.error(err);
        setUsers({ status: "error", error: err });
      }
    };

    if (visible) {
      fetchData();
    }
  }, [visible, org.id]);

  const onUserSelection = async (ownerId: string) => {
    setSaving(true);
    try {
      await onOwnerSelection(ownerId);
      onClose();
      setSaving(false);
    } catch (err) {
      console.error(err);
      setSaving(false);
    }
  };

  const renderInner = () => {
    if (users.status === "initial" || users.status === "loading" || saving) {
      return (
        <Feednack>
          <Loading />
        </Feednack>
      );
    }

    if (users.status === "error") {
      return (
        <Feednack>
          <Error />
        </Feednack>
      );
    }

    return (
      <UserPicker
        ctaText="Grant ownership"
        onClose={onClose}
        selectedUserId={currentOwnerId}
        selectUserComponent={<Tag color="magenta">Owner</Tag>}
        availableUsers={users.data}
        onUserSelection={onUserSelection}
      />
    );
  };

  return (
    <Modal
      open={visible}
      title={"Grant ownership"}
      onCancel={onClose}
      maskClosable={false}
      footer={null}
    >
      {renderInner()}
    </Modal>
  );
}

export default compose<Props, IOwnerSelectionModalProps>(WithOrg)(
  OwnerSelectionModal
);
