import type { SelectProps } from "antd";
import { Select, Typography } from "antd";
import type { AggrerateValue } from "../domain";
import { aggregates } from "../domain";

interface IAggregateFieldProps extends SelectProps {
  availableAggregates: AggrerateValue[];
  allowNull?: boolean;
}

export function AggregateField(props: IAggregateFieldProps) {
  const { availableAggregates, allowNull, ...rest } = props;
  return (
    <Select
      {...rest}
      popupMatchSelectWidth={false}
      bordered={false}
      style={{ width: "100%" }}
      optionLabelProp="label"
    >
      {aggregates
        .filter(
          (a) =>
            availableAggregates.includes(a.value) ||
            (allowNull && a.value === null)
        )
        .map((a) => {
          return (
            <Select.Option key={a.value} value={a.value} label={a.label}>
              <div>
                <Typography.Text>{a.label}</Typography.Text>
              </div>
              <div>
                <Typography.Text type="secondary">
                  {a.description}
                </Typography.Text>
              </div>
            </Select.Option>
          );
        })}
    </Select>
  );
}
