import { Button } from "antd";
import type { RouteComponentProps } from "react-router";
import { withRouter } from "react-router";
import Logo from "../../../assets/icon.svg";
import { compose } from "../../../components/compose/WlyCompose";
import Feednack from "../../../components/layout/feedback/feedback";

interface INoUserProps {}

type Props = INoUserProps & RouteComponentProps;

function NoUser(props: Props) {
  return (
    <Feednack>
      <div>
        <div style={{ paddingBottom: 12 }}>
          <img src={Logo} style={{ width: 120, height: 120 }} alt={"logo"} />
        </div>
        <div>We couldn't properly log you in, please try again.</div>
        <div style={{ paddingTop: 12 }}>
          <Button onClick={() => window.location.reload()}>Try again</Button>
        </div>
      </div>
    </Feednack>
  );
}

export default compose<Props, INoUserProps>(withRouter)(NoUser);
