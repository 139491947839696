import { PlayCircleOutlined } from "@ant-design/icons";
import { Button, Tooltip } from "antd";
import * as React from "react";
import type { QueryBuilderFormStatus } from "./QueryBuilder";
import type QueryBuilder from "./QueryBuilder";
import "./QueryBuilderSubmitButton.scss";

interface IQueryBuilderSubmitButtonProps {
  formStatus: QueryBuilderFormStatus;
  formRef: React.RefObject<QueryBuilder>;
}

export const QueryBuilderSubmitButton: React.FunctionComponent<
  IQueryBuilderSubmitButtonProps
> = (props) => {
  const { formStatus, formRef } = props;
  const errors = formStatus.formValidation.errors;

  const isButtonHighlighted = (): boolean => {
    if (
      (formStatus.form.isStale ||
        formStatus.form.isExplorationVersionOutdated) &&
      formStatus.formValidation.isValid
    ) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <Tooltip
      title={
        errors
          ? errors.map((e, index) => (
              <React.Fragment key={index}>
                {e.message}
                <br />
              </React.Fragment>
            ))
          : null
      }
    >
      <Button
        loading={formStatus.form.isLoading}
        disabled={formStatus.formValidation.isValid === false}
        onClick={() => {
          if (formRef.current?.runQuery) {
            formRef.current?.runQuery();
          }
        }}
        icon={<PlayCircleOutlined />}
        type={isButtonHighlighted() ? "primary" : "text"}
        className={
          isButtonHighlighted()
            ? "query-builder-submit-button"
            : "query-builder-submit-button"
        }
      >
        Run Query
      </Button>
    </Tooltip>
  );
};
