import {
  MoreOutlined,
  PlusCircleOutlined,
  UserOutlined,
} from "@ant-design/icons";
import type { MenuProps } from "antd";
import { Dropdown, Tooltip, Typography } from "antd";
import * as React from "react";
import { useDrop } from "react-dnd";
import type { IReport } from "../../../interfaces/reports";
import { WlyFolderIcon } from "../../icons/custom-icons/WlyFolderIcon";
import "./FolderItem.scss";

interface IFolderItemProps {
  color?: string;
  emoji: string;
  name: string;
  id: string;
  onCreate?: () => any;
  onDelete?: () => any;
  onShare?: () => any;
  onEdit?: () => any;
  setOpen?: () => void;
  onDrop?: (item: IReport) => void;
  setKeyHovered: (key: string) => void;
  // Based on current user access
  userCanEditFolder: boolean;
  userCanShareFolder: boolean;
  isPersonal?: boolean;
}

export function FolderItem(props: IFolderItemProps) {
  const {
    emoji,
    name,
    id,
    onCreate,
    onDelete,
    onShare,
    onEdit,
    setOpen,
    onDrop,
    setKeyHovered,
    userCanEditFolder,
    userCanShareFolder,
    isPersonal,
  } = props;

  const menuItems: MenuProps["items"] = [
    {
      key: "share",
      onClick: onShare,
      label: "Share",
      disabled: !userCanShareFolder,
    },
    userCanEditFolder && onEdit
      ? {
          key: "edit",
          onClick: onEdit,
          label: "Edit",
        }
      : null,
    userCanEditFolder && onDelete
      ? {
          key: "delete",
          onClick: onDelete,
          label: "Delete",
          danger: true,
        }
      : null,
  ];

  const [{ isOver }, dropRef] = useDrop(() => ({
    accept: "REPORT",
    drop: (item: IReport) => onDrop?.(item),
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  }));

  function open() {
    if (isOver) {
      setOpen?.();
    }
  }

  const debouncedSetOpen = React.useCallback(open, [isOver]);

  React.useEffect(() => {
    if (isOver) {
      setKeyHovered(id);
      debouncedSetOpen();
    } else {
      setKeyHovered("");
    }
  }, [isOver]);

  const getToolTipText = () => {
    if (onDelete && onEdit && onShare) {
      return "Share, Edit, Delete";
    } else if (!onDelete && !onEdit && onShare) {
      return "Edit";
    } else if (!onDelete && !onShare && onEdit) {
      return "Edit";
    } else if (!onEdit && !onShare && onDelete) {
      return "Delete";
    } else if (!onDelete && onShare && onEdit) {
      return "Share, Edit";
    }
  };

  return (
    <div ref={dropRef} className={"folder-item v2"}>
      <div className="folder-item-name-container">
        <div
          style={{ fontSize: 16, color: "#5E6671" }}
          className="folder-item-name-image"
        >
          <WlyFolderIcon
            style={{
              fontSize: 20,
              color: props.color ?? "#2599D4",
            }}
          />
        </div>
        <div className="folder-item-name-title">
          <Typography.Text>{name}</Typography.Text>
        </div>
        <div className="folder-item-actions">
          {isPersonal ? (
            <UserOutlined style={{ color: "gray" }} />
          ) : (
            <Tooltip
              placement="top"
              title={getToolTipText()}
              destroyTooltipOnHide
            >
              <Dropdown
                getPopupContainer={() => {
                  const el = document.getElementById(id);
                  if (el) return el;
                  return document.body;
                }}
                menu={{
                  items: menuItems,
                  className: "folder-item-actions-dropdown",
                }}
                placement="bottom"
                trigger={["click"]}
                destroyPopupOnHide
              >
                <span id={id} style={{ cursor: "pointer", marginRight: 6 }}>
                  <MoreOutlined className="folder-item-actions-icon" />
                </span>
              </Dropdown>
            </Tooltip>
          )}
          {userCanEditFolder && onCreate && (
            <span style={{ cursor: "pointer" }} onClick={onCreate}>
              <Tooltip
                placement="top"
                mouseLeaveDelay={0}
                title="Add subfolder"
                style={{ pointerEvents: "none" }}
              >
                <PlusCircleOutlined className="folder-item-actions-icon" />
              </Tooltip>
            </span>
          )}
        </div>
      </div>
    </div>
  );
}
