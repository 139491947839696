import { Alert } from "antd";

interface IErrorRendererProps {
  error: string[];
}

export function ErrorRenderer(props: IErrorRendererProps) {
  const { error } = props;
  return <Alert message={error.join(". ")} type="error" showIcon />;
}
