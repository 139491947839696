import { ColorPicker, Form, InputNumber, Select, Space } from "antd";
import { ChartOptionCollapse } from "../../../../../../../chart-options/components/ChartOptionCollapse";
import ChartOptionLine from "../../../../../../../chart-options/components/ChartOptionLine";
import type { IWidgetEmailEditorProps } from "../../domain";
import type { IEmailWidgetDividerConfig } from "../domain";

interface IEmailWidgetDividerEditorProps
  extends IWidgetEmailEditorProps<IEmailWidgetDividerConfig> {}

export function EmailWidgetDividerEditor(
  props: IEmailWidgetDividerEditorProps
) {
  const { object } = props;

  return (
    <>
      <ChartOptionCollapse dark title="Spacer Configuration">
        <Space size="middle" style={{ width: "100%" }} direction="vertical">
          <ChartOptionLine
            items={[
              {
                content: <div>Spacing</div>,
                flex: `1`,
              },
              {
                content: (
                  <Form.Item
                    initialValue={"normal"}
                    name={["config", "spacing"]}
                    noStyle
                  >
                    <Select style={{ width: "100%" }}>
                      <Select.Option value="normal">Normal</Select.Option>
                      <Select.Option value="compact">Compact</Select.Option>
                    </Select>
                  </Form.Item>
                ),
                flex: `1`,
              },
            ]}
          />
          <ChartOptionLine
            items={[
              {
                content: <div>Border Width</div>,
                flex: `1`,
              },
              {
                content: (
                  <Form.Item name={["config", "borderWidth"]} noStyle>
                    <InputNumber suffix={"px"} style={{ width: "100%" }} />
                  </Form.Item>
                ),
                flex: `1`,
              },
            ]}
          />
          <ChartOptionLine
            items={[
              {
                content: <div>Border Color</div>,
                flex: `1`,
              },
              {
                content: (
                  <Form.Item
                    normalize={(value: any) => {
                      if (typeof value?.toHexString === "function") {
                        return value.toHexString();
                      }
                      return value;
                    }}
                    name={["config", "color"]}
                    noStyle
                  >
                    <ColorPicker format="hex" defaultFormat="hex" />
                  </Form.Item>
                ),
                flex: `0 auto`,
              },
            ]}
          />
        </Space>
      </ChartOptionCollapse>
    </>
  );
}
