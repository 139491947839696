import Icon from "@ant-design/icons";
import type { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";

export const WlySunburstChartIcon = (
  props: Partial<CustomIconComponentProps>
) => (
  <Icon
    component={() => (
      <svg
        width="1em"
        height="1em"
        viewBox="0 0 213 213"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_1202_1258)">
          <path
            d="M155.705 170.211C141.32 181.321 123.598 187.236 105.424 186.993C87.2492 186.75 69.692 180.363 55.609 168.873C41.526 157.382 31.7461 141.464 27.8607 123.708C23.9754 105.952 26.2133 87.404 34.2103 71.0818L55.8972 81.7073C50.2993 93.1328 48.7328 106.116 51.4525 118.546C54.1722 130.975 61.0182 142.117 70.8763 150.161C80.7344 158.204 93.0244 162.675 105.746 162.845C118.468 163.015 130.874 158.875 140.943 151.098L155.705 170.211Z"
            fill="#41D58A"
          />
          <path
            d="M171.597 190.789C152.503 205.536 128.967 213.362 104.844 212.987C80.721 212.612 57.4401 204.057 38.8135 188.724C20.1869 173.391 7.31845 152.187 2.31524 128.586C-2.68798 104.984 0.470572 80.3835 11.2737 58.8114L30.0794 68.2292C21.4098 85.5411 18.875 105.284 22.8901 124.224C26.9053 143.165 37.2324 160.181 52.1806 172.486C67.1287 184.791 85.812 191.656 105.171 191.958C124.53 192.259 143.418 185.978 158.741 174.143L171.597 190.789Z"
            fill="#41D58A"
          />
          <path
            d="M145.075 35.8443C156.409 42.0323 166.088 50.8561 173.296 61.5712C180.503 72.2862 185.027 84.5779 186.485 97.4087C187.943 110.239 186.294 123.233 181.675 135.292C177.056 147.351 169.604 158.122 159.948 166.696L143.913 148.637C150.673 142.636 155.889 135.096 159.122 126.654C162.356 118.213 163.51 109.118 162.489 100.136C161.469 91.1545 158.302 82.5503 153.257 75.0498C148.212 67.5493 141.436 61.3726 133.502 57.041L145.075 35.8443Z"
            fill="#FBCE19"
          />
          <path
            d="M156.572 12.5053C176.47 23.105 192.49 39.7455 202.327 60.0308L183.562 69.1307C175.651 52.8177 162.768 39.4359 146.767 30.9119L156.572 12.5053Z"
            fill="#FBCE19"
          />
          <path
            d="M204.306 64.3544C213.248 85.105 215.358 108.162 210.332 130.191C205.305 152.22 193.403 172.079 176.346 186.898L162.668 171.154C176.385 159.237 185.957 143.267 189.999 125.551C194.041 107.836 192.344 89.2947 185.153 72.6076L204.306 64.3544Z"
            fill="#FBCE19"
          />
          <path
            d="M36.0579 67.5364C45.8414 49.8489 61.9209 36.4892 81.1014 30.1118C100.282 23.7344 121.16 24.806 139.586 33.1138L129.661 55.1297C116.762 49.3142 102.147 48.5641 88.721 53.0282C75.2946 57.4924 64.039 66.8442 57.1906 79.2255L36.0579 67.5364Z"
            fill="#3A5C83"
          />
          <path
            d="M13.3064 54.9518C25.2922 33.283 44.4542 16.468 67.4966 7.39912L75.1441 26.8301C56.6197 34.1208 41.2148 47.6388 31.5791 65.059L13.3064 54.9518Z"
            fill="#3A5C83"
          />
          <path
            d="M72.3704 5.61681C99.1262 -3.43493 128.382 -1.4872 153.702 11.0315L144.447 29.7502C124.092 19.6861 100.572 18.1203 79.0622 25.3972L72.3704 5.61681Z"
            fill="#3A5C83"
          />
        </g>
        <defs>
          <clipPath id="clip0_1202_1258">
            <rect width="213" height="213" fill="white" />
          </clipPath>
        </defs>
      </svg>
    )}
    {...props}
  />
);
