import type { InjectedAntUtilsProps } from "../../../../../../components/ant-utils/withAntUtils";
import { withAntUtils } from "../../../../../../components/ant-utils/withAntUtils";
import { compose } from "../../../../../../components/compose/WlyCompose";
import type { IObject } from "../../../../../../interfaces/object";
import type { InjectedOrgProps } from "../../../../../orgs/WithOrg";
import WithOrg from "../../../../../orgs/WithOrg";
import QueryModeCard from "../../../servingLayer/queryModeCard";

interface IObjectQueryModeCardProps {
  object: IObject;
  onObjectExtractDataNow: (objectId: string) => Promise<void>;
  onObjectUpdate: (id: string, payload: any) => Promise<void>;
}

type Props = IObjectQueryModeCardProps &
  InjectedOrgProps &
  InjectedAntUtilsProps;

function ObjectQueryModeCard(props: Props) {
  const { object, onObjectExtractDataNow, onObjectUpdate, org } = props;

  return (
    <QueryModeCard
      isExtractEnabled={object.isExtractEnabled}
      extractSchedule={object.extractSchedule}
      objectType={"Object"}
      objectId={object.id}
      tables={[object.table]}
      onExtractDataNow={onObjectExtractDataNow}
      onSave={async (queryMode, dataExtractConfig) => {
        if (onObjectUpdate) {
          const isExtractEnabled = queryMode === "DIRECT_QUERY" ? false : true;

          const data = {
            isExtractEnabled,
          };

          if (queryMode === "SERVING_LAYER") {
            data["extractSchedule"] = {
              create: {
                name: `${object.name} - Serving Layer extract`,
                period: dataExtractConfig.period,
                atHour: dataExtractConfig.atHour,
                dailySendEvery: dataExtractConfig.dailySendEvery,
                timezone: dataExtractConfig.timezone,
                type: "extract",
                org: {
                  connect: {
                    id: org.id,
                  },
                },
              },
            };
          } else {
            data["extractSchedule"] = {
              disconnectAll: true,
            };
          }
          await onObjectUpdate(object.id, data);
        }
      }}
    />
  );
}

export default compose<Props, IObjectQueryModeCardProps>(
  WithOrg,
  withAntUtils
)(ObjectQueryModeCard);
