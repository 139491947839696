import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";
import { Empty, Form, Select, Space, Typography } from "antd";
import { useForm } from "antd/es/form/Form";
import React from "react";
import { SwitchIcons } from "../../../../../components/form/elements/switch-icons/SwitchIcons";
import Feednack from "../../../../../components/layout/feedback/feedback";
import type { IObject } from "../../../../../interfaces/object";
import { ChartOptionCollapse } from "../../../../chart-options/components/ChartOptionCollapse";
import ChartOptionLine from "../../../../chart-options/components/ChartOptionLine";
import type {
  IHeaderProperties,
  ILayout,
  IRecordAction,
} from "../../../../v2-demo/container/record/component/domain";
import EmailWidgetEditionRenderer from "../../../../v2-demo/container/record/component/email-widgets/EmailWidgetEditionRenderer";
import RowEditionRenderer from "../../../../v2-demo/container/record/component/rows/RowEditionRenderer";
import WidgetEditionRenderer from "../../../../v2-demo/container/record/component/widgets/WidgetEditionRenderer";
import type { IRecord } from "../../../../v2-demo/container/record/domain";
import { type IDataset } from "../../../../../interfaces/sources";

interface IObjectLayoutEditorEditProps {
  object: IObject;
  layout: ILayout;
  record: IRecord;
  selected: string | null;
  allDatasets: IDataset[];
  onLayoutChange: (actions: IRecordAction[]) => void;
}

export function ObjectLayoutEditorEdit(props: IObjectLayoutEditorEditProps) {
  const { selected, layout, onLayoutChange, object, record, allDatasets } =
    props;

  const [form] = useForm();

  React.useEffect(() => {
    form.resetFields();
  }, [selected]);

  const renderInner = () => {
    if (!selected || selected.startsWith("body")) {
      return (
        <Feednack>
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description={
              <Typography.Text>Please select an element</Typography.Text>
            }
          />
        </Feednack>
      );
    }

    if (selected.startsWith("header")) {
      const initialValues = layout.header;
      return (
        <Form<IHeaderProperties>
          form={form}
          initialValues={initialValues}
          onValuesChange={(e, v) => {
            onLayoutChange([{ type: "header::modify", data: v }]);
          }}
          layout="vertical"
        >
          <div style={{ margin: `0 -8px` }}>
            <ChartOptionCollapse dark title="Header Configuration">
              <Space direction="vertical" style={{ width: "100%" }}>
                <ChartOptionLine
                  items={[
                    {
                      content: "Visible",
                      flex: 1,
                    },
                    {
                      content: (
                        <Form.Item noStyle name="visible">
                          <SwitchIcons
                            icons={{
                              false: <EyeInvisibleOutlined />,
                              true: <EyeOutlined />,
                            }}
                          />
                        </Form.Item>
                      ),
                      flex: 0,
                    },
                  ]}
                />
                <ChartOptionLine
                  items={[
                    {
                      content: (
                        <Form.Item
                          label="Featured Actions"
                          required
                          style={{ marginBottom: 4 }}
                          name={["featuredActions"]}
                        >
                          <Select
                            optionLabelProp="label"
                            style={{ width: "100%", maxWidth: "100%" }}
                            popupMatchSelectWidth={false}
                            mode="multiple"
                          >
                            {(object.registeredActions || []).map((ok) => {
                              return (
                                <Select.Option
                                  label={ok.name}
                                  key={ok.id}
                                  value={ok.id}
                                >
                                  <div
                                    style={{
                                      width: "100%",
                                      maxWidth: "100%",
                                      overflow: "hidden",
                                    }}
                                  >
                                    {ok.name}
                                  </div>
                                </Select.Option>
                              );
                            })}
                          </Select>
                        </Form.Item>
                      ),
                      flex: 1,
                    },
                  ]}
                />
              </Space>
            </ChartOptionCollapse>
          </div>
        </Form>
      );
    }

    if (selected.startsWith("row::")) {
      const id = selected.replace("row::", "");
      const row = layout.rows.find((r) => r.id === id);
      if (!row) {
        return (
          <Feednack>
            <Empty description="Could not find row" />
          </Feednack>
        );
      }
      return (
        <RowEditionRenderer
          row={row}
          object={object}
          record={record}
          form={form}
          onChange={(row) => {
            onLayoutChange([
              {
                type: "layout::modify",
                data: {
                  ...row,
                },
              },
            ]);
          }}
        />
      );
    }

    if (selected.startsWith("col::")) {
      return (
        <Feednack>
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description={
              <Typography.Text>There is nothing to edit</Typography.Text>
            }
          />
        </Feednack>
      );
    }

    if (selected.startsWith("widget::")) {
      const id = selected.replace("widget::", "");
      const widget = layout.widgets.find((w) => w.id === id);
      if (!widget) {
        return <div>widget not found</div>;
      }

      if (layout.type === "EMAIL") {
        return (
          <EmailWidgetEditionRenderer
            widget={widget}
            object={object}
            record={record}
            form={form}
            datasets={allDatasets}
            onChange={(w, c) => {
              onLayoutChange([
                {
                  type: "widget::modify",
                  data: {
                    ...widget,
                    ...w,
                    config: JSON.stringify(c),
                  },
                },
              ]);
            }}
          />
        );
      }

      return (
        <WidgetEditionRenderer
          widget={widget}
          object={object}
          record={record}
          form={form}
          datasets={allDatasets}
          onChange={(w, c) => {
            onLayoutChange([
              {
                type: "widget::modify",
                data: {
                  ...widget,
                  ...w,
                  config: JSON.stringify(c),
                },
              },
            ]);
          }}
        />
      );
    }

    return <div>not supported</div>;
  };

  return <>{renderInner()}</>;
}
