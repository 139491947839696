import {
  CheckCircleTwoTone,
  CompassTwoTone,
  ExclamationCircleTwoTone,
} from "@ant-design/icons";
import type { ResultProps } from "antd";
import { Alert, Result, Typography } from "antd";
import * as React from "react";
import type { ChartType } from "../../../../../components/chart/domain";
import { ChartDefinition } from "../../../../../components/chart/domain";
import { isMacintosh } from "../../../../../utils/isMacinthosh";
import type { QueryBuilderFormStatus } from "../query-builder/QueryBuilder";
import type QueryBuilder from "../query-builder/QueryBuilder";
import { QueryBuilderSubmitButton } from "../query-builder/QueryBuilderSubmitButton";
import "./ChartQueryBuilderFormStatusOverlay.scss";

interface IChartQueryBuilderFormStatusOverlayProps {
  formStatus: QueryBuilderFormStatus;
  formRef: React.RefObject<QueryBuilder>;
}

type ChartOverlayActionState =
  | "INITIAL"
  | "HIDDEN"
  | "INVALID_FORM"
  | "SHOULD_RUN_QUERY"
  | "SHOULD_REFRESH_QUERTY"
  | "LOADING";

const renderIcon = (chartType: ChartType, status: ChartOverlayActionState) => {
  const chartDefinition = ChartDefinition[chartType];
  const chartIcon = React.cloneElement(chartDefinition.icon, {
    style: { fontSize: 72, padding: 12 },
  });

  let statusIcon: React.ReactNode = null;

  switch (status) {
    case "INVALID_FORM":
      statusIcon = (
        <ExclamationCircleTwoTone
          twoToneColor="#faad14"
          className="status-icon"
        />
      );
      break;
    case "SHOULD_RUN_QUERY":
      statusIcon = (
        <CheckCircleTwoTone twoToneColor="#52C41A" className="status-icon" />
      );
      break;
    case "LOADING":
      statusIcon = (
        <CheckCircleTwoTone twoToneColor="#52C41A" className="status-icon" />
      );
      break;
    case "SHOULD_REFRESH_QUERTY":
      statusIcon = <CompassTwoTone className="status-icon" />;
      break;
    case "INITIAL":
      statusIcon = <CompassTwoTone className="status-icon" />;
      break;
    default:
      statusIcon = null;
      break;
  }
  return (
    <div className="result-icon-container">
      {statusIcon}
      {chartIcon}
    </div>
  );
};
export const ChartQueryBuilderFormStatusOverlay: React.FunctionComponent<
  IChartQueryBuilderFormStatusOverlayProps
> = (props) => {
  const { formStatus, formRef } = props;

  const chartType: ChartType = formStatus.value?.chartType
    ? formStatus.value?.chartType
    : "table";
  const resultProps: Partial<ResultProps> = {};

  let overlayState: ChartOverlayActionState = null;

  const extra = (
    <div>
      <QueryBuilderSubmitButton formStatus={formStatus} formRef={formRef} />
      <div style={{ paddingTop: 4 }}>
        <Typography.Text type="secondary" style={{ fontSize: 12 }}>
          {isMacintosh() ? "(⌘ + enter)" : "(Ctrl + enter)"}
        </Typography.Text>
      </div>
    </div>
  );

  if (!chartType) {
    overlayState = "HIDDEN";
  } else if (
    !formStatus.value ||
    (!formStatus.value.timeDimension &&
      !formStatus.value.dimensions.length &&
      !formStatus.value.measures.length &&
      !formStatus.form.isStale)
  ) {
    overlayState = "INITIAL";
    resultProps.status = "info";
    resultProps.title = "Let's explore";
    resultProps.subTitle =
      "Start by drag and dropping measures into this area to build your query";
  } else if (formStatus.form.isLoading) {
    overlayState = "LOADING";
  } else if (
    (!formStatus.formValidation.isValid && formStatus.form.isStale) ||
    (!formStatus.formValidation.isValid &&
      formStatus.form.isExplorationVersionOutdated)
  ) {
    overlayState = "INVALID_FORM";
    resultProps.status = "warning";
    resultProps.title = "You're almost there";
    resultProps.subTitle =
      "Please check and modify the following information before resubmitting.";

    const errors = formStatus.formValidation?.errors
      ? formStatus.formValidation?.errors
      : [];

    if (errors.length > 0) {
      resultProps.children = (
        <Alert
          message={
            <>Your query has the following issue{errors.length > 1 && "s"}:</>
          }
          description={
            <ul>
              {errors.map((error, index) => {
                return <li key={index}>{error.message}</li>;
              })}
            </ul>
          }
          type="warning"
          showIcon
        />
      );
    }
  } else if (
    formStatus.formValidation.isValid &&
    !formStatus.form.isLoading &&
    !formStatus.form.isStale &&
    formStatus.form.isExplorationVersionOutdated
  ) {
    overlayState = "SHOULD_REFRESH_QUERTY";
    resultProps.status = "info";
    resultProps.title = "Refresh your query";
    resultProps.subTitle =
      "Exploration was updated, your query needs to be refreshed";
    resultProps.extra = extra;
  } else if (
    formStatus.formValidation.isValid &&
    !formStatus.form.isLoading &&
    (formStatus.form.isStale || formStatus.form.isExplorationVersionOutdated)
  ) {
    overlayState = "SHOULD_RUN_QUERY";
    resultProps.status = "success";
    resultProps.title = "Ready to query";
    resultProps.subTitle =
      "Your query is valid, you can run it to display your chart";
    resultProps.extra = extra;
  }

  if (["HIDDEN", "LOADING"].includes(overlayState) || !overlayState) {
    return null;
  } else {
    return (
      <div className="chart-overlay">
        <Result icon={renderIcon(chartType, overlayState)} {...resultProps} />
      </div>
    );
  }
};
