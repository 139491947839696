import type { InjectedAntUtilsProps } from "../../../../components/ant-utils/withAntUtils";
import { withAntUtils } from "../../../../components/ant-utils/withAntUtils";
import { compose } from "../../../../components/compose/WlyCompose";
import type { ExtendedMeasureType } from "../../../../components/measures/measure-table/MeasureTable";
import type { IDestination } from "../../../../interfaces/destinations";
import type { IExploration } from "../../../../interfaces/explorations";
import type {
  IDataset,
  IDatasetRelationship,
} from "../../../../interfaces/sources";
import type { SchemaResult } from "../../../../interfaces/transformations";
import { routeDescriptor } from "../../../../routes/routes";
import type { IExplorationUpdatePayload } from "../../../../services/ExplorationMutationService";
import { mutateExplorationFromCode } from "../../../../services/ExplorationMutationService";
import type { InjectedOrgProps } from "../../../orgs/WithOrg";
import WithOrg from "../../../orgs/WithOrg";
import type { IExplorationMeasureUsage, TabData } from "../domain";
import type {
  ExplorationOperation,
  SelectedItems,
  StaleElements,
} from "./domain";
import TableConfigurationEdit from "./TableConfigurationEdit";
interface IExplorationConfigurationLayoutProps {
  exploration: IExploration;
  usage: IExplorationMeasureUsage;
  selectedItems: SelectedItems;
  onClick: (selectedItems: SelectedItems) => void;
  fetchExploration: (explorationId: string) => Promise<void>;
  removeStaleElement: (operationId: string) => void;
  pushStaleElement: (e: ExplorationOperation | ExplorationOperation[]) => void;
  staleElements: StaleElements;
  dataStore: {
    [tableKey: string]: TabData;
  };
  fetchTableSchema: (
    tablesSchemas: Array<{ datasetId: string; viewId: string }>
  ) => Promise<SchemaResult[]>;
  allIncomingDatasetRelationships: IDatasetRelationship[];
  allDatasets: IDataset[];
  updateStaleElement: (
    type: ExtendedMeasureType,
    objectId: string,
    data: any
  ) => void;
  removeAllStaleElements: () => void;
  currentWarehouse: IDestination;
}

type Props = IExplorationConfigurationLayoutProps &
  InjectedOrgProps &
  InjectedAntUtilsProps;

function ExplorationConfigurationLayout(props: Props) {
  const {
    exploration,
    usage,
    onClick,
    selectedItems,
    staleElements,
    pushStaleElement,
    dataStore,
    fetchTableSchema,
    allIncomingDatasetRelationships,
    allDatasets,
    removeStaleElement,
    updateStaleElement,
    removeAllStaleElements,
    org,
    fetchExploration,
    currentWarehouse,
  } = props;

  const save = (explorationUpdatePayload: IExplorationUpdatePayload) =>
    mutateExplorationFromCode(
      org.id,
      currentWarehouse.id,
      explorationUpdatePayload,
      Array.isArray(selectedItems) && selectedItems.length > 0
        ? {
            resourceId: selectedItems[0].id,
            resourceType: selectedItems[0].type,
          }
        : undefined
    );

  return (
    <TableConfigurationEdit
      currentId={exploration.id}
      displayName={exploration.name}
      displayDescription={exploration.description}
      usage={usage}
      onClick={onClick}
      selectedItems={selectedItems}
      staleElements={staleElements}
      pushStaleElement={pushStaleElement}
      dataStore={dataStore}
      fetchTableSchema={fetchTableSchema}
      allIncomingDatasetRelationships={allIncomingDatasetRelationships}
      allDatasets={allDatasets}
      removeStaleElement={removeStaleElement}
      updateStaleElement={updateStaleElement}
      removeAllStaleElements={removeAllStaleElements}
      fetch={fetchExploration}
      worskspaceRoute={routeDescriptor["explore"].renderRoute({
        organizationSlug: org.slug,
        exploreSlug: exploration.slug,
      })}
      tables={exploration.tables}
      rowLevelFilters={exploration.rowLevelFilters}
      save={save}
      currentWarehouse={currentWarehouse}
    />
  );
}

export default compose<Props, IExplorationConfigurationLayoutProps>(
  WithOrg,
  withAntUtils
)(ExplorationConfigurationLayout);
