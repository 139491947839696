import type { Transformation } from "../interfaces/transformations";

type OptimizeStack = Array<{ from: string; to: string }>;

// change this code to rename the variables when removing steps
// removed steps are pass through and their pointer can easily be changed
// eg: when we have FromWHalyTable + SelectColumns + AddColumns and we remove SelectColumns we need to
// change the  AddColumns pointer to FromWHalyTable pointer
export const optimizeStack = (
  transformations: Transformation[],
  optimizedStack: OptimizeStack
): Transformation[] => {
  const varReplacementOptimizedTemp = optimizedStack.reduce<{
    [key: string]: string;
  }>((acc, v, i, a) => {
    const findAncestor = (curr: {
      from: string;
      to: string;
    }): { from: string; to: string } => {
      const ancestor = a.find((c) => c.from === curr.to);
      if (ancestor) {
        return { from: curr.from, to: ancestor.to };
      }
      return curr;
    };

    const optimizedValue = findAncestor(v);
    return {
      ...acc,
      [optimizedValue.from]: optimizedValue.to,
    };
  }, {});

  const varReplacementOptimized = Object.keys(
    varReplacementOptimizedTemp
  ).reduce<{ [key: string]: string }>((acc, v, i, a) => {
    const findAncestor = (k: string): string => {
      if (varReplacementOptimizedTemp[k]) {
        return findAncestor(varReplacementOptimizedTemp[k]);
      }
      return k;
    };
    return {
      ...acc,
      [v]: findAncestor(v),
    };
  }, {});

  const refinedTransformations: Transformation[] = transformations.map((ft) => {
    if (
      ft.operation.type !== "Table.FromWhalyReport" ||
      ft.operation.type !== "Table.FromWhalyReport"
    ) {
      if (
        ft.operation.type === "Table.Join" ||
        ft.operation.type === "WhalyExt.Table.AddRollupColumn"
      ) {
        const table1Replacement =
          varReplacementOptimized[(ft.operation.args as any).table1];
        const table2Replacement =
          varReplacementOptimized[(ft.operation.args as any).table2];
        if (table1Replacement || table2Replacement) {
          return {
            ...ft,
            operation: {
              ...ft.operation,
              args: {
                ...ft.operation.args,
                table1: table1Replacement
                  ? table1Replacement
                  : ft.operation.args.table1,
                table2: table2Replacement
                  ? table2Replacement
                  : ft.operation.args.table2,
              },
            },
          } as Transformation;
        }
        return ft;
      }

      const tableReplacement =
        varReplacementOptimized[(ft.operation.args as any).table];
      if (tableReplacement) {
        return {
          ...ft,
          operation: {
            ...ft.operation,
            args: {
              ...ft.operation.args,
              table: tableReplacement,
            },
          },
        } as Transformation;
      }

      return ft;
    }
    return ft;
  });

  return refinedTransformations;
};
