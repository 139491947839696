import {
  CaretDownOutlined,
  CheckCircleFilled,
  CloseCircleFilled,
  InfoCircleOutlined,
} from "@ant-design/icons";
import { Skeleton, Tooltip } from "antd";
import numeral from "numeral";
import * as React from "react";
import type {
  BooleanSchemaItem,
  Filter,
  SchemaResult,
  TableResult,
  Transformation,
} from "../../interfaces/transformations";
import { generateUniqueId } from "../../utils/uniqueId";
import * as Toolbar from "./toolbar/Toolbar";

import cuid from "cuid";
import type { GridChildComponentProps } from "react-window";
import type { InjectedAntUtilsProps } from "../../components/ant-utils/withAntUtils";
import { withAntUtils } from "../../components/ant-utils/withAntUtils";
import { compose } from "../../components/compose/WlyCompose";
import Feednack from "../../components/layout/feedback/feedback";
import type {
  SpreadsheetBodyCellRenderer,
  SpreadsheetHeaderCellRenderer,
} from "../../components/spreadsheet/SpreadsheetScrollSync";
import SpreadsheetScrollSync from "../../components/spreadsheet/SpreadsheetScrollSync";
import type { AsyncData } from "../../helpers/typescriptHelpers";
import type { IDatasetRelationship } from "../../interfaces/sources";
import type { InjectedOrgProps } from "../orgs/WithOrg";
import WithOrg from "../orgs/WithOrg";
import type { CreateEditTransformationComponent } from "../transformations";
import "./Spreadsheet.scss";
import ColumnDropdown from "./columnMenu/ColumnDropdown";
import type { ColumnMenuElement } from "./columnMenu/ColumnMenu";
import ColumnMenu from "./columnMenu/ColumnMenu";
import type { ColumnInformations } from "./domain";
import { SpreadsheetErrorRenderer } from "./error/SpreadsheetErrorRenderer";
import { renderContent } from "./helpers";
import TypeRenderer from "./renderer/TypeRenderer";
import { TestRenderer } from "./tests/TestRenderer";

interface ISpreadsheetCoreProps {
  records?: TableResult;
  count?: number;
  schema?: SchemaResult;
  columnsInfos?: ColumnInformations;
  transformations: AsyncData<Transformation[]>;
  style?: React.CSSProperties;
  tabStyle?: React.CSSProperties;
  primaryKeys?: string[];
  foreignKeys?: string[];
  userDefinedColumns?: string[];
  outgoingRelationships?: IDatasetRelationship[];
  headerMenuElements?: (
    dataKey: string,
    schema: SchemaResult
  ) => ColumnMenuElement[];
  toolbarMenuElements?: ToolbarMenuElement[];
  onRowClick?: (selection?: RowSelectedItem) => void;
  onHeaderClick?: (selection?: ColumnSelectedItem) => void;
  selectedItem?: SelectedItem;
  error?: string;
  renderModalContent?: (height: number) => React.ReactNode;
  footerMenuElements?: ToolbarMenuElement[];
  loading: ISpreadsheetLoading;
  scrollToColumnIndex?: number;
  rounded?: boolean;
}

interface BaseSelectedItem {
  type: "column" | "row";
}

export interface RowSelectedItem extends BaseSelectedItem {
  type: "row";
  index: string | number;
  data: any;
}

export interface ColumnSelectedItem extends BaseSelectedItem {
  type: "column";
  index: string | number;
  data: any;
}

export interface ToolbarMenuElement {
  key: string;
  renderer: () => React.ReactNode;
}

export type SelectedItem = RowSelectedItem | ColumnSelectedItem;
export interface ISpreadsheetLoading {
  tables: boolean;
  schema: boolean;
  count: boolean;
  records: boolean;
}

interface IState {
  editingTransformation?: {
    transformation: Transformation;
    component: CreateEditTransformationComponent<Transformation>;
  };
  submittingTransformation: boolean;
  height: number;
}

type Props = InjectedOrgProps & InjectedAntUtilsProps & ISpreadsheetCoreProps;

class SpreadsheetCore extends React.Component<Props, IState> {
  id = cuid();

  constructor(props: Props) {
    super(props);
    this.state = {
      submittingTransformation: false,
      height: 0,
    };
  }

  public spreadsheetBodyCellRenderer =
    (schemaDef: SchemaResult): SpreadsheetBodyCellRenderer =>
    (props: GridChildComponentProps<SchemaResult>) => {
      const {
        loading,
        schema,
        records,
        foreignKeys,
        outgoingRelationships,
        userDefinedColumns,
        onRowClick,
        selectedItem,
      } = this.props;
      const dataKey = Object.keys(schemaDef)[props.columnIndex];
      const cellData = records ? records[props.rowIndex][dataKey] : null;
      const isForeign = (foreignKeys || []).indexOf(dataKey) > -1;
      const isUsed = (outgoingRelationships || []).find((r) => {
        if (r.from === dataKey) {
          return true;
        }
        return false;
      });
      const userDefinedColumn =
        (userDefinedColumns || []).indexOf(dataKey) > -1;

      const key = props.rowIndex + "-" + props.columnIndex;

      return loading.records || (schema && schema[dataKey].loading) ? (
        <div
          key={`loading-${key}`}
          className={`cell-content`}
          style={{ ...props.style }}
        >
          <Skeleton.Input
            style={{ width: 174, borderRadius: 12, height: 16, marginTop: 7 }}
            active={true}
            size={"small"}
          />
        </div>
      ) : (
        <div
          key={key}
          onDoubleClick={(e) => {
            try {
              if (records?.[props.rowIndex]?.[dataKey]) {
                e.preventDefault();
                navigator.clipboard.writeText(
                  records[props.rowIndex]?.[dataKey]?.toString?.()
                );
                this.props.antUtils.message.success(
                  "Value copied to clipboard"
                );
              }
            } catch (error) {
              console.warn(error);
            }
          }}
          onClick={
            onRowClick
              ? (e) =>
                  records &&
                  records[props.rowIndex] &&
                  onRowClick({
                    data: records[props.rowIndex],
                    index: props.rowIndex,
                    type: "row",
                  })
              : undefined
          }
          style={{ ...props.style }}
          className={`cell-content ${userDefinedColumn ? "user-defined" : ""} ${
            selectedItem &&
            selectedItem.type === "column" &&
            dataKey === selectedItem.index
              ? "selected"
              : ""
          }`}
        >
          {renderContent(
            cellData,
            schema ? schema[dataKey].domain : undefined,
            isForeign,
            !!isUsed
          )}
        </div>
      );
    };

  public spreadsheetHeaderCellRenderer =
    (schemaDef: SchemaResult): SpreadsheetHeaderCellRenderer =>
    (listProps) => {
      const {
        loading,
        schema,
        primaryKeys,
        userDefinedColumns,
        headerMenuElements,
        selectedItem,
        onHeaderClick,
        columnsInfos,
      } = this.props;

      const dataKey = Object.keys(schemaDef)[listProps.index];
      const primary = (primaryKeys || []).indexOf(dataKey) > -1;
      const userDefinedColumn =
        (userDefinedColumns || []).indexOf(dataKey) > -1;
      const columnInfo = (columnsInfos || {})[dataKey];

      // const menuElements = [];

      const menuElements: ColumnMenuElement[] = headerMenuElements
        ? headerMenuElements(dataKey, schema)
        : [];

      const renderDataType = () => {
        if (schema) {
          const schemaItem = schema[dataKey];
          return (
            <TypeRenderer
              domain={schemaItem.domain}
              formula={schemaItem.operation === "Table.AddColumn"}
            />
          );
        }
        return <span />;
      };

      const isSelected =
        selectedItem &&
        selectedItem.type === "column" &&
        dataKey === selectedItem.index
          ? "selected"
          : "";

      const columnName =
        schema && schema[dataKey] && schema[dataKey].label
          ? schema[dataKey].label
          : dataKey;

      const renderTestStatus = () => {
        if (columnInfo && columnInfo.tests?.length) {
          const wrapInTooltip = (c: React.ReactElement) => {
            return (
              <Tooltip
                placement="topRight"
                title={
                  <div>
                    {columnInfo.tests.map((c, i) => {
                      return <TestRenderer key={i} test={c} />;
                    })}
                  </div>
                }
              >
                {c}
              </Tooltip>
            );
          };

          if (columnInfo.tests.find((t) => t.status !== "success")) {
            return wrapInTooltip(
              <div className="header-infos">
                <CloseCircleFilled style={{ color: "#ff4d4f" }} />
              </div>
            );
          } else {
            return wrapInTooltip(
              <div className="header-infos">
                <CheckCircleFilled style={{ color: "#52c41a" }} />
              </div>
            );
          }
        }
        return null;
      };
      return loading.schema ? (
        <div
          className="ReactVirtualized__Table__headerColumn"
          style={listProps.style}
          key={`loading-${dataKey}`}
        >
          <Skeleton.Input
            style={{ height: 16, borderRadius: 12, width: 174, marginTop: 7 }}
            active={true}
            size={"small"}
          />
        </div>
      ) : (
        <div
          className={`ReactVirtualized__Table__headerColumn ${
            userDefinedColumn ? "user-defined" : ""
          } ${primary ? "primary" : ""} ${isSelected}`}
          style={listProps.style}
          key={dataKey}
        >
          {
            <div
              className="header-inner"
              onClick={
                onHeaderClick
                  ? (e) =>
                      onHeaderClick({
                        data: schema![dataKey],
                        index: dataKey,
                        type: "column",
                      })
                  : undefined
              }
            >
              <div className="header-type">{renderDataType()}</div>
              <div className="header-name">
                <>
                  {schema[dataKey].description ? (
                    <Tooltip title={schema[dataKey].description}>
                      <div className="header-with-description">
                        {columnName}
                        <div className="info-icon">
                          <InfoCircleOutlined />
                        </div>
                      </div>
                    </Tooltip>
                  ) : (
                    columnName
                  )}
                </>
              </div>
              {renderTestStatus()}
              {menuElements.length !== 0 && (
                <div
                  className="header-action"
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  style={{ float: "right" }}
                >
                  <ColumnDropdown
                    position={"bottomRight"}
                    id={this.id}
                    overlay={(handleClose, handleClickOutside, confirmOpen) => (
                      <ColumnMenu
                        elements={menuElements}
                        handleClose={handleClose}
                        confirmOpen={confirmOpen}
                        handleClickOutside={handleClickOutside}
                      />
                    )}
                  >
                    <CaretDownOutlined />
                  </ColumnDropdown>
                </div>
              )}
            </div>
          }
        </div>
      );
    };

  public setElementHeight = (id: string) => () => {
    const el = document.getElementById(id);
    if (el) {
      const box = el.getBoundingClientRect();
      if (box.height !== this.state.height) {
        this.setState({
          height: box.height,
        });
      }
    }
  };

  public countNumberOfFilter = (condition: Filter) => {
    if ((condition[0] as any).and) {
      return (condition[0] as any).and.length;
    }
    if ((condition[0] as any).or) {
      return (condition[0] as any).or.length;
    }
    return condition.length;
  };

  public render() {
    const {
      schema,
      count,
      records,
      loading,
      style,
      transformations,
      error,
      scrollToColumnIndex,
      toolbarMenuElements,
      footerMenuElements,
      renderModalContent,
    } = this.props;

    let schemaDef = schema ? schema : {};
    if (loading.schema) {
      schemaDef = Array.from({ length: 10 }, (_, a) => ({
        key: generateUniqueId(),
      }))
        .map((_) => {
          return {
            [_.key]: {
              domain: "BOOLEAN",
              type: "BOOLEAN",
              distinct: 0,
              null: 0,
              count: 0,
            } as BooleanSchemaItem,
          };
        })
        .reduce((p, v, i) => {
          return {
            ...p,
            ...v,
          };
        }, {} as SchemaResult);
    }

    let recordsDef = records ? records : [];
    if (loading.records) {
      const record = Object.keys(schemaDef).reduce((p, v, i) => {
        return {
          ...p,
          [v]: null,
        };
      }, {});
      recordsDef = Array.from({ length: 100 }, (_, a) => record);
    }

    return (
      <div className="wly-spreadsheet" id={this.id} style={style}>
        {transformations.status === "success" &&
          transformations.data.length !== 0 && (
            <Toolbar.Toolbar
              rounded={this.props.rounded}
              style={{ borderTop: "none" }}
            >
              {(toolbarMenuElements || []).map((tr) => {
                return (
                  <React.Fragment key={tr.key}>{tr.renderer()}</React.Fragment>
                );
              })}
            </Toolbar.Toolbar>
          )}

        {transformations.status === "success" &&
        transformations.data.length === 0 ? (
          // to do change to related dataset selection
          <div className="main-view-wrapper">
            <div className="main-view">
              <div className="main-view-inner">
                <div className="table-view">
                  <div className="table-view-wrapper">
                    <Feednack>
                      <div>
                        <div>
                          There are no transformations, please add data...
                        </div>
                      </div>
                    </Feednack>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="main-view-wrapper">
            <div className="main-view">
              <div className="main-view-inner">
                <div className="table-view">
                  <div className="table-view-wrapper">
                    {error ? (
                      <SpreadsheetErrorRenderer>
                        {error}
                      </SpreadsheetErrorRenderer>
                    ) : (
                      <div className="table-view-inner">
                        <SpreadsheetScrollSync
                          columnCount={Object.keys(schemaDef).length}
                          rowCount={recordsDef.length}
                          renderHeaderCell={this.spreadsheetHeaderCellRenderer(
                            schemaDef
                          )}
                          renderBodyCell={this.spreadsheetBodyCellRenderer(
                            schemaDef
                          )}
                          scrollToColumnIndex={scrollToColumnIndex}
                          isLoading={loading.records || loading.count}
                        />
                      </div>
                    )}
                    {!error && (
                      <div className="counter-view">
                        <div className="counter-view-inner">
                          <div className="grow">
                            {(footerMenuElements || []).map((tr) => {
                              return (
                                <React.Fragment key={tr.key}>
                                  {tr.renderer()}
                                </React.Fragment>
                              );
                            })}
                          </div>
                          <div className="menu-right">
                            <div>
                              {loading.count ? (
                                <span>
                                  <Skeleton.Input
                                    style={{ width: 200, borderRadius: 12 }}
                                    active={true}
                                    size={"small"}
                                  />
                                </span>
                              ) : (
                                <span>
                                  {numeral(count).format("0,0[.]00")} records
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                {renderModalContent && !!renderModalContent(this.state.height) && (
                  <div
                    className="modal-view"
                    id="modal-view"
                    ref={this.setElementHeight("modal-view")}
                  >
                    {renderModalContent(this.state.height)}
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default compose<Props, ISpreadsheetCoreProps>(
  WithOrg,
  withAntUtils
)(SpreadsheetCore);
