import type { EmbeddedChartType, IDiscoveryChartDefinition } from "./domain";
import histogram from "./histogram/histogram";
import pie from "./pie/pie";
import scatter from "./scatter/scatter";

export const embeddedChartDefinition: {
  [key in EmbeddedChartType]: IDiscoveryChartDefinition<any>;
} = {
  histogram: histogram,
  pie: pie,
  scatter: scatter,
};
