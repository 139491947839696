import { Tooltip } from "antd";
import type { Moment } from "moment";
import moment from "moment";
import * as React from "react";

interface IFromNowProps {
  date: Moment;
  /**
   * Displays a tooltip containing the user name on hover
   */
  tooltip?: boolean;
  className?: string;
  style?: React.CSSProperties;
}

export function FromNow(props: IFromNowProps) {
  const [time, setTime] = React.useState<string>(props.date.fromNow());

  React.useEffect(() => {
    setTime(props.date.fromNow());
  }, [props.date]);

  React.useEffect(() => {
    const interval = setInterval(() => {
      setTime(props.date.fromNow());
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [props.date]);

  return (
    <Tooltip
      title={moment(props.date).format("MMMM Do YYYY, hh:mm:ss")}
      overlayStyle={{ visibility: props.tooltip ? "initial" : "hidden" }}
    >
      <span style={props.style} className={props.className}>
        {time}
      </span>
    </Tooltip>
  );
}
