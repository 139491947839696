import * as React from "react";

interface IErrorProps {
  children?: React.ReactNode;
}

export default class Error extends React.Component<IErrorProps> {
  public render() {
    return <div className="wly-centered-container">{this.props.children}</div>;
  }
}
