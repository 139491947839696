import { Button, Form, Modal } from "antd";
import * as React from "react";
import type { InjectedAntUtilsProps } from "../../../components/ant-utils/withAntUtils";
import { withAntUtils } from "../../../components/ant-utils/withAntUtils";
import { compose } from "../../../components/compose/WlyCompose";
import type { AsyncData } from "../../../helpers/typescriptHelpers";
import { IUserRoleType } from "../../../interfaces/user";
import type { InjectedOrgProps } from "../../orgs/WithOrg";
import WithOrg from "../../orgs/WithOrg";
import type { IUserInviteValues } from "./UserInviteForm";
import { UserInviteForm, inviteUsers } from "./UserInviteForm";

interface IUserInviteCreationModalProps {
  children: (onClick: () => void) => React.ReactNode;
}

type Props = IUserInviteCreationModalProps &
  InjectedAntUtilsProps &
  InjectedOrgProps;

function UserInviteCreationModal(props: Props) {
  const {
    antUtils: { message },
    org,
  } = props;
  const [visible, setVisible] = React.useState(false);
  const [data, setData] = React.useState<AsyncData<{}>>({ status: "initial" });
  const [form] = Form.useForm<IUserInviteValues>();

  const onClose = () => setVisible(false);

  React.useEffect(() => {
    if (!visible) {
      form.resetFields();
      setData({ status: "initial" });
    }
  }, [visible]);

  const onCreate = async (v: IUserInviteValues) => {
    try {
      setData({ status: "loading" });
      await form.validateFields();
      await inviteUsers(v, org.id, IUserRoleType.VIEWER);
      setVisible(false);
      await message.success("Successfully invited your teammates.");
    } catch (err) {
      console.error(err);
      setData({ status: "error", error: err });
      message.error("There was an error when inviting team members.");
    }
  };

  return (
    <>
      {props.children(() => setVisible(true))}
      <Modal
        footer={null}
        closable={false}
        title={null}
        open={visible}
        centered={true}
        width={"50%"}
        className={`report-creation-modal whaly-modal-v2`}
        styles={{ body: { padding: 0 } }}
        destroyOnClose={true}
        onCancel={onClose}
        maskClosable={true}
      >
        <div className="header">Invite teammates</div>
        <div className="content">
          <UserInviteForm form={form} onFinish={onCreate} />
        </div>
        <div className="footer">
          <div className="left">
            <Button onClick={onClose} type="link">
              Cancel
            </Button>
          </div>
          <div className="right">
            <Button
              onClick={form.submit}
              loading={data.status === "loading"}
              type="primary"
            >
              Continue
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default compose<Props, IUserInviteCreationModalProps>(
  withAntUtils,
  WithOrg
)(UserInviteCreationModal);
