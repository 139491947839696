import { matchPath } from "react-router";
import type { MenuIcon } from "../../../routes/domain";
import { routeDescriptor } from "../../../routes/routes";

export const currentTool = (pathName: string): MenuIcon => {
  const allPaths = Object.keys(routeDescriptor).map((k) => routeDescriptor[k]);
  const path = allPaths.find((p) => {
    const t = matchPath(pathName, p.path);
    if (t && t.isExact) {
      return true;
    }
    return false;
  });
  if (path && path.menuIcon) {
    return path.menuIcon;
  }
  return "workspace";
};
