import { Form, Modal, Select, Switch, Typography } from "antd";
import { useForm } from "antd/es/form/Form";
import * as React from "react";
import { compose } from "../../../../components/compose/WlyCompose";
import type {
  ISchedule,
  ScheduleDailySendEvery,
  SchedulePeriod,
} from "../../../../interfaces/schedule";
import type { InjectedOrgProps } from "../../../orgs/WithOrg";
import WithOrg from "../../../orgs/WithOrg";
import type { DataExtractConfig, QueryMode } from "./domain";

interface IQueryModeModalProps {
  visible: boolean;
  currentQueryMode: QueryMode;
  extractSchedule: ISchedule;
  onClose: () => void;
  onSave: (
    queryMode: QueryMode,
    dataExtractConfig?: DataExtractConfig
  ) => Promise<any>;
}

type Props = InjectedOrgProps & IQueryModeModalProps;

interface FormValues {
  activateServingLayer: boolean;
  period?: SchedulePeriod;
  atHour?: number;
  dailySendEvery?: ScheduleDailySendEvery;
  timezone: string;
}

function QueryModeEditModal(props: Props) {
  const { visible, onClose, onSave, extractSchedule, currentQueryMode } = props;

  const [form] = useForm<FormValues>();

  React.useEffect(() => {
    if (!visible) {
      form.resetFields();
    }
  }, [visible]);

  const [saving, setSaving] = React.useState<boolean>(false);

  const onFinish = async (values: any) => {
    setSaving(true);
    try {
      await form.validateFields();
      const formValues = form.getFieldsValue();
      const extractConfig: DataExtractConfig | undefined =
        formValues.activateServingLayer
          ? {
              period: formValues.period,
              atHour: formValues.atHour,
              dailySendEvery: formValues.dailySendEvery,
              timezone: formValues.timezone,
            }
          : undefined;
      await onSave(
        formValues.activateServingLayer ? "SERVING_LAYER" : "DIRECT_QUERY",
        extractConfig
      );
      onClose();
      setSaving(false);
    } catch (err) {
      console.error(err);
      setSaving(false);
    }
  };

  const initialValues: FormValues = {
    activateServingLayer: currentQueryMode === "SERVING_LAYER",
    period: extractSchedule?.period || "hour",
    atHour: extractSchedule?.atHour || 6,
    dailySendEvery: extractSchedule?.dailySendEvery || "on_week_day",
    timezone:
      extractSchedule?.timezone ||
      Intl.DateTimeFormat().resolvedOptions().timeZone,
  };

  const renderInner = () => {
    return (
      <Form
        layout="vertical"
        form={form}
        initialValues={initialValues}
        onFinish={onFinish}
        style={{ marginTop: 24, marginBottom: 24 }}
      >
        <Form.Item
          valuePropName="checked"
          name="activateServingLayer"
          label="Activate serving layer"
        >
          <Switch />
        </Form.Item>
        <Form.Item shouldUpdate={true} noStyle>
          {() => {
            const isActive = form.getFieldValue(["activateServingLayer"]);
            if (isActive) {
              return (
                <Typography.Text type="secondary">
                  Serving Layer is doing a extract of your data into an{" "}
                  <b>accelerated analytics engine</b> managed by Whaly. This is{" "}
                  <b>speeding up</b> your queries and{" "}
                  <b>decrease your Warehouse cost</b>, but you will query
                  extracts that can be outdated and it{" "}
                  <b>doesn't support large data</b> volume
                </Typography.Text>
              );
            }
            return (
              <Typography.Text type="secondary">
                Direct Query is running all <b>queries on your Warehouse</b>.
                This is giving you the <b>most up-to-date results</b> but is{" "}
                <b>slower</b> and generate <b>more Warehouse costs</b> than the
                Serving Layer.
              </Typography.Text>
            );
          }}
        </Form.Item>

        <Form.Item noStyle shouldUpdate={true}>
          {() => {
            const activated = form.getFieldValue(["activateServingLayer"]);
            if (activated === true) {
              return (
                <Form.Item
                  label="Extract every"
                  name="period"
                  style={{ marginTop: 24 }}
                  shouldUpdate={true}
                >
                  <Select>
                    <Select.Option value="hour">Hour</Select.Option>
                    <Select.Option value="day">Day</Select.Option>
                  </Select>
                </Form.Item>
              );
            }
            return undefined;
          }}
        </Form.Item>
        <Form.Item noStyle shouldUpdate={true}>
          {() => {
            const activated = form.getFieldValue(["activateServingLayer"]);
            const currentPeriod = form.getFieldValue(["period"]);
            if (activated && currentPeriod === "day") {
              return (
                <>
                  <Form.Item
                    label="Run every"
                    name="atHour"
                    style={{
                      display: "inline-block",
                      width: "calc(50% - 12px)",
                    }}
                    shouldUpdate={true}
                  >
                    <Select>
                      <Select.Option value={0}>00:00</Select.Option>
                      <Select.Option value={1}>01:00</Select.Option>
                      <Select.Option value={2}>02:00</Select.Option>
                      <Select.Option value={3}>03:00</Select.Option>
                      <Select.Option value={4}>04:00</Select.Option>
                      <Select.Option value={5}>05:00</Select.Option>
                      <Select.Option value={6}>06:00</Select.Option>
                      <Select.Option value={7}>07:00</Select.Option>
                      <Select.Option value={8}>08:00</Select.Option>
                      <Select.Option value={9}>09:00</Select.Option>
                      <Select.Option value={10}>10:00</Select.Option>
                      <Select.Option value={11}>11:00</Select.Option>
                      <Select.Option value={12}>12:00</Select.Option>
                      <Select.Option value={13}>13:00</Select.Option>
                      <Select.Option value={14}>14:00</Select.Option>
                      <Select.Option value={15}>15:00</Select.Option>
                      <Select.Option value={16}>16:00</Select.Option>
                      <Select.Option value={17}>17:00</Select.Option>
                      <Select.Option value={18}>18:00</Select.Option>
                      <Select.Option value={19}>19:00</Select.Option>
                      <Select.Option value={20}>20:00</Select.Option>
                      <Select.Option value={21}>21:00</Select.Option>
                      <Select.Option value={22}>22:00</Select.Option>
                      <Select.Option value={23}>23:00</Select.Option>
                    </Select>
                  </Form.Item>
                  <span
                    style={{ display: "inline-block", width: "24px" }}
                  ></span>
                  <Form.Item
                    label="On"
                    name="dailySendEvery"
                    shouldUpdate={true}
                    style={{
                      display: "inline-block",
                      width: "calc(50% - 12px)",
                    }}
                  >
                    <Select>
                      <Select.Option value="every_day">All days</Select.Option>
                      <Select.Option value="on_week_day">
                        Only Business days (Mon-Fri)
                      </Select.Option>
                    </Select>
                  </Form.Item>
                  <Form.Item
                    label="Timezone"
                    name="timezone"
                    shouldUpdate={true}
                  >
                    <Select
                      options={Intl.supportedValuesOf("timeZone").map((tz) => {
                        return {
                          label: tz,
                          value: tz,
                        };
                      })}
                    />
                  </Form.Item>
                </>
              );
            }
            return undefined;
          }}
        </Form.Item>
      </Form>
    );
  };

  return (
    <Modal
      open={visible}
      title={"Query Mode"}
      onCancel={onClose}
      maskClosable={false}
      destroyOnClose={true}
      closable={true}
      confirmLoading={saving}
      okText={"Save"}
      cancelText={"Cancel"}
      onOk={() => form.submit()}
      width={"50%"}
    >
      {renderInner()}
    </Modal>
  );
}

export default compose<Props, IQueryModeModalProps>(WithOrg)(
  QueryModeEditModal
);
