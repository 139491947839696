import * as React from "react";
import { useDrag } from "react-dnd";
import { IObjectLayoutItemType } from "../../../../../custom-layout-editor/main/sider/add/domain";
import type { IWidget } from "../domain";

interface IWidgetDragProps {
  widget: IWidget;
  edit: boolean;
  children?: React.ReactNode;
}

export function WidgetDrag(props: React.PropsWithChildren<IWidgetDragProps>) {
  const { widget, children, edit } = props;

  const [{ opacity }, dragRef] = useDrag(
    () => ({
      type: IObjectLayoutItemType.WIDGET,
      item: {
        operation: "move",
        identifier: widget.id,
        data: widget,
      },
      collect: (monitor) => {
        return {
          opacity: monitor.isDragging() ? 0.5 : 1,
        };
      },
    }),
    [widget]
  );

  return (
    <div ref={edit ? dragRef : undefined} style={{ opacity }}>
      {children}
    </div>
  );
}
