import { AbstractReactFactory } from "@projectstorm/react-canvas-core";
import type { DiagramEngine } from "@projectstorm/react-diagrams-core";
import { OutputNodeModel } from "./OutputNodeModel";
import OutputNodeWidget from "./OutputNodeWidget";

export class OutputNodeFactory extends AbstractReactFactory<
  OutputNodeModel,
  DiagramEngine
> {
  constructor() {
    super("output");
  }

  generateReactWidget(event): JSX.Element {
    return (
      <OutputNodeWidget engine={this.engine} size={50} node={event.model} />
    );
  }

  generateModel(event) {
    return new OutputNodeModel();
  }
}
