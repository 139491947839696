import type {
  AvailableDimension,
  AvailableMetric,
} from "../filter-item/FilterItem";
import MeasureItem from "../measure-item/MeasureItem";
import type { MeasureDropZone } from "../measure-picker/DroppableMeasureZone";
import { DroppableMeasureZone } from "../measure-picker/DroppableMeasureZone";
import MeasurePicker from "../measure-picker/MeasurePicker";
import type { MeasureType } from "../measure-table/MeasureTable";

interface IMeasureSingleSelectorProps {
  measures: Array<AvailableDimension | AvailableMetric>;
  zone: MeasureDropZone;
  type: MeasureType;
  value?: string;
  onChange?: (value: string) => void;
}

export const MeasureSingleSelector = (props: IMeasureSingleSelectorProps) => {
  const { value, onChange, measures, zone, type } = props;

  const onMeasureChange = (key: string) => {
    if (!onChange) return;
    onChange(key);
  };

  const measure = measures.find((measure) => measure.key === value);
  return (
    <DroppableMeasureZone
      zone={zone}
      accept={[type]}
      availableMeasures={measures}
      onAddMeasure={onMeasureChange}
    >
      {value ? (
        <MeasureItem
          type={type}
          isDeleted={!!!measures.find((measure) => measure.key === value)}
          description={measure?.description}
          name={measure?.label}
          onDelete={() => {
            onMeasureChange(undefined);
          }}
        />
      ) : (
        <MeasurePicker
          availableMeasures={measures}
          block
          selectedMeasureKeys={value ? [value] : []}
          onMeasureChange={(i) => onMeasureChange(i)}
        >
          {measure ? measure.label : "Select"}
        </MeasurePicker>
      )}
    </DroppableMeasureZone>
  );
};
