import * as React from "react";
import type { FieldProps } from "formik";
import type { FormItemProps } from "antd/lib/form/FormItem";
import { Form, Switch as AntSwitch } from "antd";
import type { SwitchProps } from "antd/lib/switch";

interface ISwitchProps extends FieldProps {
  switchProps: SwitchProps;
  formItemProps: FormItemProps;
}

export default class Switch extends React.Component<ISwitchProps> {
  public render() {
    const { switchProps, formItemProps, field, form } = this.props;

    const isSubmitting = form.isSubmitting;
    const touched = form.touched[field.name];
    const submitted = form.submitCount > 0;
    const hasError = form.errors[field.name];
    const submittedError = hasError && submitted;
    const touchedError = hasError && touched;
    const onInputChange = (checked: boolean) =>
      form.setFieldValue(field.name, JSON.stringify(checked));
    const value = field.value
      ? (JSON.parse(field.value) as boolean)
      : undefined;
    return (
      <Form.Item
        label={formItemProps.label}
        hasFeedback={
          (formItemProps.hasFeedback && submitted) ||
          (formItemProps.hasFeedback && touched)
            ? true
            : undefined
        }
        help={submittedError || touchedError ? <>{hasError}</> : undefined}
        validateStatus={
          submittedError || touchedError
            ? "error"
            : isSubmitting
            ? "validating"
            : "success"
        }
        {...formItemProps}
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
      >
        <AntSwitch
          {...field}
          {...switchProps}
          checked={value}
          disabled={isSubmitting || switchProps.disabled}
          onChange={onInputChange}
        />
      </Form.Item>
    );
  }
}
