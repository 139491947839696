import type { IAnalysisType } from "../../../containers/exploration/single/domain";
import { WlyScatterChartIcon } from "../../icons/custom-icons/WlyScatterChartIcon";
import type { SingleChartDefinition } from "../domain";

export const scatterChartDefinition: SingleChartDefinition = {
  label: "Scatter plot",
  allowTimeComparison: false,
  icon: <WlyScatterChartIcon />,
  canPredict: () => false,
  minDimensions: 1,
  maxDimensions: 1,
  minMetrics: 2,
  maxMetrics: 2,
  pivot: (analysisType: IAnalysisType) => false,
  canDisplay: (rs) => {
    if ((rs as any).queryType === "compareDateRangeQuery") {
      return "Cannot use a date comparison query on a scatter plot";
    }
    return;
  },
  config: ["labels", "palette"],
  axis: {
    bottom: {
      editable: true,
      isTimeFormattable: (a) => false,
    },
    left: {
      editable: true,
    },
    right: {
      editable: false,
    },
  },
  extractCustomizationDimensions: (d, q) => {
    return {
      dimensions: [],
    };
  },
  extractCustomizationSeries: (s, d, q) => {
    return {
      series: [{ key: q.selectedMeasures[1], label: "Scatter plot" }],
      customColor: true,
      customLabel: true,
      customType: [],
    };
  },
};
