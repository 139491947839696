import type {
  BaseModelOptions,
  DeserializeEvent,
} from "@projectstorm/react-canvas-core";
import { DefaultLabelModel } from "@projectstorm/react-diagrams";

interface EditableLabelOptions extends BaseModelOptions {
  value?: string;
}

export class EditableLabelModel extends DefaultLabelModel {
  value: string;

  constructor(options: EditableLabelOptions = {}) {
    super({
      ...options,
      type: "editable-label",
    });
    this.value = options.value || "";
  }

  serialize() {
    return {
      ...super.serialize(),
      value: this.value,
    };
  }

  deserialize(event: DeserializeEvent<this>): void {
    super.deserialize(event);
    this.value = event.data.value;
  }
}
