import * as React from "react";
import ColorRenderer from "../../../form/color-picker/ColorRenderer";
import { toRgba } from "../../utils/gradientUtils";
import type { IPalette } from "../../utils/paletteData";
import SimpleColorEditor from "../color-editor/SimpleColorEditor";

import "./DiscreteEditor.scss";

interface IDiscreteEditorProps {
  value: IPalette;
  onChange: (p: IPalette) => void;
}

interface IState {
  selected: number;
}

export default class DiscreteEditor extends React.Component<
  IDiscreteEditorProps,
  IState
> {
  constructor(props: IDiscreteEditorProps) {
    super(props);
    this.state = {
      selected: 0,
    };
  }

  public render() {
    const { value, onChange } = this.props;

    return (
      <div className="discrete-palette-editor">
        <div className="discrete-palette-editor-list">
          {value.colors.map((c, i) => {
            return (
              <ColorRenderer
                key={i}
                hasMargin={true}
                color={c}
                onClick={() => this.setState({ selected: i })}
                selected={i === this.state.selected}
              />
            );
          })}
        </div>
        <SimpleColorEditor
          color={
            this.state.selected
              ? value.colors[this.state.selected!]
              : value.colors[0]
          }
          onChange={(e) => {
            const i = this.state.selected;
            const colors = value.colors;
            colors[i] = toRgba(e.rgb);
            onChange({
              ...value,
              colors: colors,
            });
          }}
          disableAddition={value.colors.length === 1}
          disableDeletion={false}
          onAddition={() => {
            onChange({
              ...value,
              colors: [...value.colors, value.colors[this.state.selected]],
            });
          }}
          onDeletion={() => {
            const colors = value.colors;
            if (colors.length > 1) {
              colors.splice(this.state.selected, 1);
              this.setState({
                selected:
                  this.state.selected - 1 >= 0 ? this.state.selected - 1 : 0,
              });
              onChange({
                ...value,
                colors: colors,
              });
            }
          }}
        />
      </div>
    );
  }
}
