import React from "react";

interface IIconHolderProps {
  icon: React.ReactElement;
  size: number;
  color: string;
  background: string;
}

export function IconHolder(props: IIconHolderProps) {
  const { icon, size, color, background } = props;

  let offset = 6;
  let borderRadius = 5;

  if (size > 40) {
    offset = 16;
    borderRadius = 10;
  }

  let inset = offset / 2;
  let fontSize = size - offset;

  const renderIcon = React.cloneElement(icon, {
    style: {
      color: color,
      fontSize: fontSize,
      position: "absolute",
      inset,
    },
  });

  return (
    <div
      style={{
        width: size,
        height: size,
        borderRadius: borderRadius,
        position: "relative",
        background: background,
      }}
    >
      {renderIcon}
    </div>
  );
}
