import mjml2html from "mjml-browser";
import * as React from "react";
import Feednack from "../../../../../../components/layout/feedback/feedback";
import Loading from "../../../../../../components/layout/feedback/loading";
import type { AsyncData } from "../../../../../../helpers/typescriptHelpers";
import type { IObject } from "../../../../../../interfaces/object";
import type { IOrg } from "../../../../../../interfaces/org";
import type { IUser } from "../../../../../../interfaces/user";
import type { IRecord } from "../../domain";
import type { ILayout } from "../domain";
import { renderRecordEmailMJML } from "./RecordEmailMjml";

interface IRecordEmailPreviewProps {
  layout: ILayout;
  object: IObject;
  record: IRecord;
  org: IOrg;
  user: IUser;
  emailTemplateMjml: string;
}

export function RecordEmailPreview(props: IRecordEmailPreviewProps) {
  const { layout, object, record, org, user, emailTemplateMjml } = props;

  const [data, setData] = React.useState<AsyncData<string>>({
    status: "initial",
  });

  React.useEffect(() => {
    const fetch = async () => {
      setData({ status: "loading" });
      try {
        const s = await renderRecordEmailMJML(
          layout,
          object,
          record,
          org,
          user
        );
        const htmlString = mjml2html(
          emailTemplateMjml.replaceAll(
            "{{ BODY_CONTENT }}",
            s.map((a) => `<mj-section>${a}</mj-section>`).join("\n")
          )
        ).html;

        setData({
          status: "success",
          data: htmlString,
        });
      } catch (err) {
        console.error(err);
        setData({ status: "error", error: err });
      }
    };
    fetch();
  }, []);

  if (data.status === "initial" || data.status === "loading") {
    return <Loading />;
  }

  if (data.status === "error") {
    return <Feednack>Error while compiling email</Feednack>;
  }

  return (
    <iframe width="100%" height="100%" frameBorder="0" srcDoc={data.data} />
  );
}
