import type {
  BinaryFilter,
  BinaryOperator,
  UnaryFilter,
} from "@cubejs-client/core";
import { Button, Space } from "antd";
import { useEffect, useState } from "react";
import { FilterGroupItem } from "../../../../../../../components/measures/filter-item/FilterGroupItem";
import type { AvailableDimension } from "../../../../../../../components/measures/filter-item/FilterItem";
import FilterItem from "../../../../../../../components/measures/filter-item/FilterItem";

export type IWidgetVisibilityFiltersValue = {
  action: "show" | "hide";
  operator: "and" | "or";
  filters: (UnaryFilter | BinaryFilter)[];
};

interface IWidgetVisibilityFiltersProps {
  availableDimensions: AvailableDimension[];
  autocomplete: (
    dimensionName: string,
    operator: BinaryOperator,
    value?: string
  ) => Promise<string[]>;
  value?: IWidgetVisibilityFiltersValue;
  onChange?: (value: IWidgetVisibilityFiltersValue) => void;
}

export function WidgetVisibilityFilters(props: IWidgetVisibilityFiltersProps) {
  const { availableDimensions, autocomplete, value, onChange } = props;

  const [filters, setFilters] = useState<(UnaryFilter | BinaryFilter)[]>(
    value?.filters ? value?.filters : []
  );
  const [filterOperator, setFilterOperator] = useState<"and" | "or">(
    value?.operator ? value?.operator : "and"
  );
  const [action, setAction] = useState<"show" | "hide">(
    value?.action ? value?.action : "hide"
  );

  useEffect(() => {
    onChange?.({
      action: action,
      filters: filters,
      operator: filterOperator,
    });
  }, [filters, filterOperator, action]);

  return (
    <Space direction="vertical" style={{ width: "100%" }}>
      <div className="line">
        <Button
          onClick={() => setAction(action === "show" ? "hide" : "show")}
          size="small"
          style={{ width: 45 }}
        >
          {action === "show" ? "Show" : "Hide"}
        </Button>{" "}
        when filters match{" "}
        <Button
          onClick={() =>
            setFilterOperator(filterOperator === "or" ? "and" : "or")
          }
          size="small"
          style={{ width: 35 }}
        >
          {filterOperator === "or" ? "any" : "all"}
        </Button>{" "}
        of the following:
      </div>
      {filters.flatMap((f, i, s) => {
        if ((f as any).or || (f as any).and) {
          const operator = Object.keys(f)[0] as "and" | "or";
          const subFilters = (f as any)[operator];
          return [
            <FilterGroupItem
              key={i}
              filterOperator={operator}
              filters={subFilters}
              availableDimensions={availableDimensions}
              onFilterOperatorChange={() => {
                setFilters(
                  filters.map((a, index) => {
                    const nextKey = operator === "and" ? "or" : "and";
                    if (index === i) {
                      return {
                        [nextKey]: subFilters,
                      } as any;
                    }
                    return a;
                  })
                );
              }}
              autocomplete={autocomplete}
              onDelete={(index) =>
                setFilters(
                  filters.map((a, n) => {
                    if (n === i) {
                      return {
                        [operator]: subFilters.filter((s, j) => j !== index),
                      } as any;
                    }
                    return a;
                  })
                )
              }
              onGroupDelete={() => {
                const newFilters = filters.filter((a, index) => i !== index);
                if (newFilters.length === 1) {
                  if ((newFilters[0] as any).or) {
                    setFilters((newFilters[0] as any).or);
                  } else if ((newFilters[0] as any).and) {
                    setFilters((newFilters[0] as any).and);
                  }
                } else {
                  setFilters(newFilters);
                }
              }}
              onFiltersChange={(filts) =>
                setFilters(
                  filters.map((a, j) => {
                    if (j === i) {
                      return {
                        [operator]: filts,
                      } as any;
                    }
                    return a;
                  })
                )
              }
            />,
            i !== s.length - 1 ? (
              <div style={{ textAlign: "center" }}>
                <Button
                  onClick={() =>
                    setFilterOperator(filterOperator === "and" ? "or" : "and")
                  }
                  size="small"
                >
                  {filterOperator === "and" ? "And" : "Or"}
                </Button>
              </div>
            ) : null,
          ];
        } else {
          return (
            <FilterItem
              key={i}
              onDelete={() => setFilters(filters.filter((f, ci) => ci !== i))}
              onChange={(f) =>
                setFilters(filters.map((cf, ci) => (i === ci ? f : cf)))
              }
              filter={f}
              availableDimensions={availableDimensions}
              autocomplete={autocomplete}
              className="line"
            />
          );
        }
      })}
      <Button
        disabled={availableDimensions.length === 0}
        onClick={() =>
          setFilters([
            ...filters,
            {
              member: availableDimensions[0].key,
              operator: "set",
            },
          ])
        }
        size="small"
        block
      >
        <span style={{ width: "100%" }}>Add a filter</span>
      </Button>
    </Space>
  );
}
