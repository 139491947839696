import { Button, Modal } from "antd";
import * as React from "react";
import { WlyCard } from "../../../../../../../components/cards/WlyCard";
import CacheStrageyRenderer from "../../../../../../../components/workbench/cache/CacheStrategyRenderer";
import type { AsyncData } from "../../../../../../../helpers/typescriptHelpers";
import type { SchemaResult } from "../../../../../../../interfaces/transformations";
import type {
  CacheStrategy,
  IDatasetUpdate,
  TabData,
} from "../../../../domain";
import { CacheStrategyForm } from "./CacheStrategyForm";

interface ICacheStrategyProps {
  onUpdateDataset?: IDatasetUpdate;
  currentTab: TabData;
  schema: AsyncData<SchemaResult>;
}

export function CacheStrategyCard(props: ICacheStrategyProps) {
  const { onUpdateDataset, currentTab, schema } = props;
  const [isEditingCache, setEditingCache] = React.useState<boolean>(false);

  let cacheStrategy: CacheStrategy = {
    type: "every",
    value: "45 minute",
  };
  try {
    if (currentTab.dataset.cacheStrategy) {
      cacheStrategy = JSON.parse(currentTab.dataset.cacheStrategy);
    }
  } catch (err) {
    console.error("can't parse cache strategy");
  }

  const renderInner = () => {
    return <CacheStrageyRenderer cacheStrategy={cacheStrategy} />;
  };

  return (
    <WlyCard
      title="Cache strategy"
      extra={
        onUpdateDataset &&
        cacheStrategy.type !== "whaly_synced" && (
          <Button
            type="primary"
            size="small"
            onClick={() => setEditingCache(true)}
          >
            Edit
          </Button>
        )
      }
    >
      {renderInner()}
      {onUpdateDataset && (
        <Modal
          open={isEditingCache}
          footer={null}
          title={"Edit cache strategy"}
          onCancel={() => setEditingCache(false)}
          maskClosable={false}
        >
          <CacheStrategyForm
            initialValues={cacheStrategy}
            onUpdateDataset={onUpdateDataset}
            onDone={() => setEditingCache(false)}
            schema={schema}
          />
        </Modal>
      )}
    </WlyCard>
  );
}
