import { Empty, Input, List, theme } from "antd";
import cuid from "cuid";
import React from "react";
import { compose } from "../../../../../components/compose/WlyCompose";
import Feednack from "../../../../../components/layout/feedback/feedback";
import Loading from "../../../../../components/layout/feedback/loading";
import type { AsyncData } from "../../../../../helpers/typescriptHelpers";
import {
  LagoonCallOrigin,
  lagoonServiceLoad,
} from "../../../../../services/LagoonService";
import type { InjectedOrgProps } from "../../../../orgs/WithOrg";
import WithOrg from "../../../../orgs/WithOrg";
import { parseLabel } from "../../../../v2-demo/container/record/domain";
import "./RecordPicker.scss";

const { useToken } = theme;

interface IRecordPickerProps {
  objectId: string;
  objectCubeName: string;
  onRecordIdSelect: (recordId: string) => void;
}

type Props = InjectedOrgProps & IRecordPickerProps;

function RecordPicker(props: Props) {
  const { org, objectCubeName, objectId, onRecordIdSelect } = props;
  const [id] = React.useState(cuid());

  const { token } = useToken();

  const [data, setData] = React.useState<AsyncData<Array<string>>>({
    status: "initial",
  });
  const [search, setSearch] = React.useState<string>("");

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        setData({ status: "loading" });
        const key = `${objectCubeName}.label`;
        const data = await lagoonServiceLoad(
          org.id,
          {
            dimensions: [key],
            filters: [
              {
                and: [
                  {
                    member: `${objectCubeName}.name`,
                    operator: "contains",
                    values: [search],
                  },
                ],
              },
            ],
            limit: 1000,
          },
          "OBJECT",
          objectId,
          undefined,
          LagoonCallOrigin.WHALY_APP
        );
        const pivot = data.tablePivot();
        const formatted = pivot.map((p) => p[key] as string);
        setData({ status: "success", data: formatted });
      } catch (err) {
        console.error(err);
        setData({ status: "error", error: err as any });
      }
    };
    if (search && search.length) {
      fetchData();
    }
  }, [search]);

  const contentStyle: React.CSSProperties = {
    backgroundColor: token.colorBgElevated,
    borderRadius: token.borderRadiusLG,
    boxShadow: token.boxShadowSecondary,
    padding: 8,
    width: 400,
    height: 250,
    gap: 8,
    display: "flex",
    flexDirection: "column",
  };

  const renderInner = () => {
    if (data.status === "initial") {
      return (
        <Feednack>
          <Empty description="Please search for a record" />
        </Feednack>
      );
    }
    if (data.status === "loading") {
      return (
        <Feednack>
          <Loading />
        </Feednack>
      );
    }
    if (data.status === "error") {
      return (
        <Feednack>
          <div>Error while fetching data</div>
        </Feednack>
      );
    }
    if (data.data.length === 0) {
      return (
        <Feednack>
          <Empty description="No record found" />
        </Feednack>
      );
    }

    return (
      <List
        dataSource={data.data}
        className="record-picker-list"
        renderItem={(i) => {
          const label = parseLabel(i);
          return (
            <div
              key={i}
              className="record-picker-list-item"
              onClick={() => onRecordIdSelect(label.id)}
            >
              {label.name ? label.name : label.id}
            </div>
          );
        }}
      />
    );
  };

  return (
    <div style={contentStyle} id={id}>
      <div style={{ flex: `0 auto` }}>
        <Input.Search onSearch={(e) => setSearch(e)} />
      </div>
      <div
        style={{
          flex: 1,
          overflow: "hidden auto",
          padding: "8px 0",
          margin: "0 -8px -8px -8px",
        }}
      >
        {renderInner()}
      </div>
    </div>
  );
}

export default compose<Props, IRecordPickerProps>(WithOrg)(RecordPicker);
