import { Tag, Typography } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import "./RecommendationCard.scss";

export interface IRecommendationCardProps {
  title: string;
  items: Array<{
    title: string;
    link?: string;
    score?: number;
    subtitle?: string;
    logo?: string;
    badge?: React.ReactNode;
  }>;
}

export function RecommendationCard(props: IRecommendationCardProps) {
  const { title, items } = props;
  return (
    <div className="recommendation-card">
      <div className="recommendation-card-title">{title}</div>
      <div className="recommendation-card-items">
        {items.map((_, i) => {
          const inner = (
            <div
              className={`recommendation-card-items-item ${
                _.link ? "clickable" : ""
              }`}
            >
              {_.logo && (
                <div className="recommendation-card-items-item-logo">
                  <img alt={_.title} src={_.logo} />
                </div>
              )}
              <div className="recommendation-card-items-item-content">
                <div className="recommendation-card-items-item-content-title">
                  <Typography.Text ellipsis>{_.title}</Typography.Text>
                </div>
                {!!_.subtitle && (
                  <div className="recommendation-card-items-item-content-subtitle">
                    <div className="recommendation-card-items-item-content-subtitle-left">
                      <Typography.Text ellipsis type="secondary">
                        {_.subtitle}
                      </Typography.Text>
                    </div>
                    {typeof _.score !== "undefined" && (
                      <div className="recommendation-card-items-item-content-subtitle-right">
                        <Typography.Text ellipsis type="secondary">
                          <Tag
                            style={{ color: "#8E3C23" }}
                            color="#F8E8D0"
                            bordered={false}
                          >
                            {_.score}
                          </Tag>
                        </Typography.Text>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          );

          if (!_.link) {
            return <React.Fragment key={i}>{inner}</React.Fragment>;
          }

          return (
            <Link key={i} to={_.link}>
              {inner}
            </Link>
          );
        })}
      </div>
    </div>
  );
}
