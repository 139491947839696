import { CheckCircleOutlined } from "@ant-design/icons";
import { Button, Popover, Space } from "antd";
import { type ButtonProps } from "antd/lib";
import React from "react";
import { WlyDynamicIcon } from "../../../../../../../../components/icons/dynamic-renderer/WlyDynamicIconRenderer";
import Feednack from "../../../../../../../../components/layout/feedback/feedback";
import Loading from "../../../../../../../../components/layout/feedback/loading";
import { type AsyncData } from "../../../../../../../../helpers/typescriptHelpers";
import {
  type IObject,
  type IObjectRegisteredAction,
} from "../../../../../../../../interfaces/object";
import GraphQLService from "../../../../../../../../services/graphql/GraphQLService";
import { COLOR_UP } from "../../../../../layout/domain";
import { parseLabel, type IRecord } from "../../../../domain";
import { saveTempRecosToAvoid } from "./domain";

export interface IFeedbackActionButtonRendererProps {
  conf: {
    reasons?: Array<string>;
  };
  contextKey: string;
  object: IObject;
  record: IRecord;
  buttonProps: ButtonProps;
  display: "ICON" | "TEXT";
  registeredAction: IObjectRegisteredAction;
}

export function FeedbackActionButtonRenderer(
  props: IFeedbackActionButtonRendererProps
) {
  const {
    conf,
    record,
    buttonProps,
    display,
    registeredAction,
    object,
    contextKey,
  } = props;

  const [opened, setOpened] = React.useState<boolean>(false);
  const [registeredData, setRegisteredData] = React.useState<
    AsyncData<boolean>
  >({ status: "initial" });

  const registerFeedback = async (type: string) => {
    try {
      setRegisteredData({ status: "loading" });
      const { id } = parseLabel(
        record[`${object.table.cubeName}.label`] as string
      );
      const d = await GraphQLService<{ registerFeedback: string }>(
        `
      mutation registerFeedback($registeredActionId: ID!, $recordId: String!, $record: String!, $type: String!, $score: String!) {
        registerFeedback(registeredActionId: $registeredActionId, recordId: $recordId, record: $record, score: $score, type: $type)
      }
      `,
        {
          registeredActionId: registeredAction.id,
          recordId: id,
          record: JSON.stringify(record),
          type,
          score: "1",
        }
      );
      if (d.registerFeedback === "true") {
        saveTempRecosToAvoid(contextKey, id);
        setRegisteredData({ status: "success", data: true });
      } else {
        throw new Error(
          "Couln't register feedback, please reach out to your admin"
        );
      }

      setTimeout(() => {
        setRegisteredData({ status: "initial" });
        setOpened(false);
      }, 1000);
    } catch (err) {
      setRegisteredData({ status: "error", error: err });
    }
  };

  React.useEffect(() => {
    if (registeredData.status === "error" && !opened) {
      setRegisteredData({ status: "initial" });
    }
  }, [opened]);

  const renderInner = () => {
    if (registeredData.status === "initial") {
      return (
        <Space wrap>
          {(conf.reasons || []).map((r) => {
            return (
              <Button onClick={() => registerFeedback(r)} key={r}>
                {r}
              </Button>
            );
          })}
        </Space>
      );
    }
    if (registeredData.status === "loading") {
      return (
        <div style={{ height: 100, width: 500 }}>
          <Feednack>
            <Loading />
          </Feednack>
        </div>
      );
    }

    if (registeredData.status === "error") {
      return (
        <div style={{ height: 100, width: 500 }}>
          <Feednack>
            There was an error registering your feedback, please reach out to
            your admin
          </Feednack>
        </div>
      );
    }

    if (registeredData.status === "success") {
      return (
        <div style={{ height: 100, width: 500 }}>
          <Feednack>
            <CheckCircleOutlined style={{ color: COLOR_UP, fontSize: 48 }} />
          </Feednack>
        </div>
      );
    }
  };

  return (
    <Popover
      placement="bottom"
      open={opened}
      onOpenChange={(v) =>
        registeredData.status === "error" || registeredData.status === "initial"
          ? setOpened(v)
          : undefined
      }
      overlayInnerStyle={{ maxWidth: "500px" }}
      content={renderInner()}
      trigger={"click"}
    >
      <Button
        {...buttonProps}
        icon={
          display === "ICON" ? (
            <WlyDynamicIcon
              name={
                registeredAction.icon ? registeredAction.icon : "DislikeFilled"
              }
            />
          ) : undefined
        }
      >
        {display === "TEXT" ? registeredAction.displayName : undefined}
      </Button>
    </Popover>
  );
}
