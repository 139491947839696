import * as React from "react";

import "./PaletteRenderer.scss";
import { generateGradient } from "./utils/gradientUtils";
import type { IPaletteType } from "./utils/paletteData";

type IPaletteRendererProps = {
  type: IPaletteType;
  colors: string[];
  selected?: boolean;
  size?: "small" | "default";
};

export default class PaletteRenderer extends React.Component<IPaletteRendererProps> {
  public render() {
    const { type, colors, selected, size } = this.props;
    if (type === "discrete") {
      return (
        <div
          className={
            selected ? "palette-renderer selected" : "palette-renderer"
          }
        >
          <div
            className="palette-renderer-item"
            style={{ backgroundColor: colors[0] }}
          />
          <div
            className="palette-renderer-item"
            style={{ backgroundColor: colors[1] }}
          />
          <div
            className="palette-renderer-item"
            style={{ backgroundColor: colors[2] }}
          />
          <div
            className="palette-renderer-item"
            style={{ backgroundColor: colors[3] }}
          />
          <div
            className="palette-renderer-item"
            style={{ backgroundColor: colors[4] }}
          />
          <div
            className="palette-renderer-item"
            style={{ backgroundColor: colors[5] }}
          />
          <div
            className="palette-renderer-item"
            style={{ backgroundColor: colors[6] }}
          />
          <div
            className="palette-renderer-item"
            style={{ backgroundColor: colors[7] }}
          />
          <div
            className="palette-renderer-item"
            style={{ backgroundColor: colors[8] }}
          />
          <div
            className="palette-renderer-item"
            style={{ backgroundColor: colors[9] }}
          />
          <div
            className="palette-renderer-item"
            style={{ backgroundColor: colors[10] }}
          />
          <div
            className="palette-renderer-item"
            style={{ backgroundColor: colors[11] }}
          />
        </div>
      );
    } else {
      return (
        <div
          className={`palette-renderer ${selected ? "selected" : ""} ${
            size === "small" ? "small" : ""
          }`}
          style={{
            background: generateGradient(colors),
          }}
        ></div>
      );
    }
  }
}
