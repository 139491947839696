import type { FormInstance } from "antd";
import type { IDestination } from "../../../../../../../interfaces/destinations";
import type { IDataset } from "../../../../../../../interfaces/sources";
import type {
  OperationType,
  SchemaResult,
  Transformation,
} from "../../../../../../../interfaces/transformations";
import type { CanvasNodeGenerator } from "../helpers/CanvasNodeGenerator";

export type FlowOperationFormOperation =
  | FlowOperationFormUpdateOperation
  | FlowOperationFormCreateOperation;

interface FlowOperationFormUpdateOperation {
  type: "UPDATE";
  transformation: Transformation;
}

interface FlowOperationFormCreateOperation {
  type: "CREATE";
  parentKey: string;
  transformation: Transformation;
}

export interface FlowOperationFormProps<T extends Transformation> {
  onSave?: (operations: FlowOperationFormOperation[]) => Promise<void>;
  onCancel?: () => void;
  isStale?: (stale: boolean) => void;
  currentTransformation: T;
  transformations: Transformation[];
  setSubmitting: (submitting: boolean) => void;
  datasets: IDataset[];
  prevStepSchema: SchemaResult;
  setFormInstance?: (f: FormInstance) => void;
  canvasNodeGenerator: CanvasNodeGenerator;
  onNodeHighlight?: (nodeId: string, hovering: boolean) => void;
  currentWarehouse: IDestination;
}

export const getDisplayName = (type: OperationType) => {
  switch (type) {
    case "Table.AddColumn":
      return "Formula";
    case "WhalyExt.Table.AddLookupColumn":
      return "Lookup";
    case "WhalyExt.Table.AddRollupColumn":
      return "Rollup";
    case "Table.SelectRows":
      return "Filter";
    case "Table.RemoveColumns":
      return "Select columns";
    case "Table.FromWarehouseTable":
      return "From source";
    case "Table.FromWhalyDataset":
      return "From model";
    case "Table.Ref":
      return "Reference";
    case "Table.Group":
      return "Group by";
    case "Table.Combine":
      return "Union";
    default:
      return type;
  }
};
