import { CloseOutlined, FormatPainterOutlined } from "@ant-design/icons";
import { Button, Drawer, Space, Tooltip } from "antd";
import { useState } from "react";
import type { IConditionalFormatterRule } from "./domain";
import type { IWlyConditionalFormatterFormOptions } from "./WlyConditionalFormatterForm";
import WlyConditionalFormatterForm from "./WlyConditionalFormatterForm";

interface IWlyConditionalFormatterDrawerProps {
  options: IWlyConditionalFormatterFormOptions;
  value?: IConditionalFormatterRule[];
  onChange?: (values: IConditionalFormatterRule[]) => void;
}

export function WlyConditionalFormatterDrawer(
  props: IWlyConditionalFormatterDrawerProps
) {
  const { options, value, onChange } = props;
  const [open, setOpen] = useState(false);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };
  return (
    <>
      <Tooltip title="Conditional formatting" mouseLeaveDelay={0}>
        <Button
          type="text"
          shape="circle"
          onClick={showDrawer}
          icon={<FormatPainterOutlined />}
        />
      </Tooltip>
      <Drawer
        open={open}
        onClose={onClose}
        closable={false}
        title={"Conditional formatting"}
        destroyOnClose
        push={false}
        getContainer={() => {
          if (
            document.getElementsByClassName("chart-options-wrapper")?.length
          ) {
            // chart options wrapper in the exploration page
            return document.getElementsByClassName("chart-options-wrapper")[0];
          } else if (
            document.getElementsByClassName("chart-option-panel")?.length
          ) {
            // chart options wrapper in the dashboard page
            return document.getElementsByClassName("chart-option-panel")[0];
          }
        }}
        width={"100%"}
        styles={{
          body: { padding: 0 },
          mask: {
            background: "none",
          },
        }}
        rootStyle={{ position: "absolute" }}
        contentWrapperStyle={{
          boxShadow: "none",
        }}
        extra={
          <Space>
            <Button
              onClick={() => {
                onClose();
              }}
              icon={<CloseOutlined />}
            />
          </Space>
        }
      >
        <WlyConditionalFormatterForm
          options={options}
          value={value}
          onChange={(value) => {
            if (onChange) {
              onChange(value);
            }
          }}
        />
      </Drawer>
    </>
  );
}
