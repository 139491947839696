import type { MenuProps } from "antd";
import { Divider, Dropdown, Typography, theme } from "antd";
import { inject, observer } from "mobx-react";
import React from "react";
import type { RouteComponentProps } from "react-router";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import { compose } from "../../../components/compose/WlyCompose";
import UserAvatar from "../../../components/user/avatar/UserAvatar";
import { routeDescriptor } from "../../../routes/routes";
import type { UserStoreProps } from "../../../store/userStore";
import type { InjectedOrgProps } from "../WithOrg";
import WithOrg from "../WithOrg";

const { useToken } = theme;

interface IUserMenuDropdownProps {
  children?: JSX.Element;
}

type Props = IUserMenuDropdownProps &
  RouteComponentProps<{}> &
  InjectedOrgProps &
  UserStoreProps;

function UserMenuDropdown(props: Props) {
  const {
    history,
    org,
    user,
    children,
    match: { params },
  } = props;

  const { token } = useToken();

  const contentStyle = {
    backgroundColor: token.colorBgElevated,
    borderRadius: token.borderRadiusLG,
    boxShadow: token.boxShadowSecondary,
    width: 250,
  };

  const menuStyle = {
    boxShadow: "none",
  };

  const items: MenuProps["items"] = [
    {
      key: "user-group",
      type: "group",
      label: <b>Account</b>,
      children: [
        {
          key: "manage-user",
          onClick: () => {
            history.push(
              routeDescriptor["settingsUser"].renderRoute({
                organizationSlug: org.slug,
                ...params,
              })
            );
          },
          label: "Your settings",
        },
      ],
    },
  ];

  if (user.type === "STANDARD") {
    items.push({
      key: "resources-group",
      type: "group",
      label: <b>Resources</b>,
      children: [
        {
          key: "resource-docs",
          label: (
            <Link to={{ pathname: "https://docs.whaly.io" }} target="whaly_doc">
              Product documentation
            </Link>
          ),
        },
        {
          key: "resource-help",
          label: (
            <Link
              to={{ pathname: "https://help.whaly.io" }}
              target="whaly_help"
            >
              Implementation guides
            </Link>
          ),
        },
        {
          key: "resource-intercom",
          label: (
            <Link
              to="/"
              id="help-button"
              onClick={(e) => {
                e.preventDefault();
                (window as any).Intercom?.("show");
              }}
            >
              Send us a message
            </Link>
          ),
        },
        {
          key: "resource-hubspot",
          label: (
            <Link
              to={{
                pathname:
                  "https://meetings.hubspot.com/nicolas-scheerens/sales-calendar",
              }}
              target="whaly_sales"
            >
              Chat with sales
            </Link>
          ),
        },
        {
          key: "resource-blog",
          label: (
            <Link
              to={{
                pathname: "https://whaly.io/posts/tags/change-log",
              }}
              target="whaly_blog"
            >
              What's new
            </Link>
          ),
        },
      ],
    });
  }

  items.push({
    type: "divider",
  });

  items.push({
    key: "logout",
    onClick: () => {
      window.location.href =
        user.type === "PORTAL"
          ? `${
              window.WHALY_CONSTANTS.OVERRIDE_PORTAL_URL
                ? window.WHALY_CONSTANTS.OVERRIDE_PORTAL_URL
                : ""
            }/auth/api/logout`
          : `${window.WHALY_CONSTANTS.AUTH_SERVER_URL}/oauth2/sessions/logout`;
    },
    label: <Typography.Text type="danger">Log out</Typography.Text>,
  });

  return (
    <Dropdown
      menu={{
        items: items,
      }}
      dropdownRender={(menu) => {
        return (
          <div style={contentStyle}>
            <div
              style={{
                display: "flex",
                padding: 16,
                backgroundColor: "white",
                borderRadius: "6px 6px 0 0",
                alignItems: "center",
              }}
            >
              <div style={{ flex: 0, marginRight: 6 }}>
                <UserAvatar user={user} />
              </div>
              <div style={{ flex: 1 }}>
                <div>
                  <Typography.Text strong style={{ width: 180 }} ellipsis>
                    {user.firstName} {user.lastName}
                  </Typography.Text>
                </div>
                <div>
                  <Typography.Text
                    type="secondary"
                    style={{ width: 180 }}
                    ellipsis
                  >
                    {user.email}
                  </Typography.Text>
                </div>
              </div>
            </div>

            <Divider style={{ margin: 0 }} />
            {React.cloneElement(menu as React.ReactElement, {
              style: menuStyle,
            })}
          </div>
        );
      }}
      trigger={["click"]}
      placement="bottomLeft"
      arrow
    >
      {children}
    </Dropdown>
  );
}

export default compose<Props, IUserMenuDropdownProps>(
  withRouter,
  WithOrg,
  inject("userStore"),
  observer
)(UserMenuDropdown);
