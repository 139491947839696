import type { BinaryFilter } from "@cubejs-client/core";
import type { AvailableDimension } from "../../../../../components/measures/filter-item/FilterItem";
import type { IExploration } from "../../../../../interfaces/explorations";
import type { IStandardDimension } from "../../../../../interfaces/table";

export interface RowLevelFilter {
  operator: "or" | "and";
  condition: Array<BinaryFilter>;
}

export const parseRowLevelFilterValue = (value: string): RowLevelFilter => {
  try {
    const parsed = JSON.parse(value);
    return {
      operator: parsed.operator ? parsed.operator : "and",
      condition: parsed.condition ? parsed.condition : [],
    };
  } catch (err) {
    return {
      operator: "and",
      condition: [],
    };
  }
};

export const buildAvailableDimension = (exploration: IExploration) => {
  const allDimensions: AvailableDimension[] =
    exploration.tables.flatMap<AvailableDimension>((t) => {
      return t.dimensions
        .filter((d) => d.type === "standard")
        .map<AvailableDimension>((d) => {
          const sd = d as any as IStandardDimension;
          return {
            key: `${t.cubeName}.${d.cubeName}`,
            label: d.name,
            type: sd.type,
            domain: sd.columnDomain,
            description: sd.description,
          };
        });
    });
  return allDimensions;
};
