import { useEffect, useRef } from "react";
import type { InjectedAntUtilsProps } from "../../../components/ant-utils/withAntUtils";
import { withAntUtils } from "../../../components/ant-utils/withAntUtils";
import { compose } from "../../../components/compose/WlyCompose";
import { usePollingEffect } from "../../../components/hooks/usePolling";
import GraphQLService from "../../../services/graphql/GraphQLService";

interface IExplorationVersionWatcherProps {
  children: React.ReactNode;
  initialVersion: number;
  explorationId: string;
  loading: boolean;
  onReload: () => void;
}

type Props = IExplorationVersionWatcherProps & InjectedAntUtilsProps;

const getExplorationVersion = async ({ explorationId }) => {
  try {
    const data = await GraphQLService<{
      Exploration: {
        version: {
          value: number;
        };
      };
    }>(
      `
        query getExplorationVersion($explorationId: ID!) {
          Exploration(where: { id: $explorationId }) {
            version {
              value
            }
          }
        }
      `,
      {
        explorationId: explorationId,
      }
    );
    return data.Exploration.version.value;
  } catch (error) {
    return error;
  }
};

function ExplorationVersionWatcher(props: Props) {
  const {
    antUtils,
    explorationId,
    children,
    loading,
    initialVersion,
    onReload,
  } = props;
  const key = useRef<string>(null);

  usePollingEffect(async () => {
    const currentVersion = await getExplorationVersion({ explorationId });
    if (currentVersion instanceof Error) return;
    if (currentVersion !== initialVersion) {
      key.current = explorationId;
      antUtils.message.loading({
        content: "Loading latest version...",
        key: explorationId,
      });
      onReload();
    }
  }, [initialVersion, explorationId]);

  useEffect(() => {
    if (key.current && !loading) {
      antUtils.message.success({
        content: "Exploration updated!",
        key: explorationId,
        duration: 2,
      });
      key.current = null;
    }
  }, [loading, explorationId]);

  return <>{children}</>;
}

export default compose<Props, IExplorationVersionWatcherProps>(withAntUtils)(
  ExplorationVersionWatcher
);
