import * as React from "react";

interface IFeednackProps {
  children?: React.ReactNode;
}

export default class Feednack extends React.Component<IFeednackProps> {
  public render() {
    return <div className="feedback">{this.props.children}</div>;
  }
}
