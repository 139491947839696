import { Typography } from "antd";
import * as React from "react";
import "./BlurredWrapper.scss";

const { Text } = Typography;

interface IBlurredWrapperProps {
  message?: string;
  style?: React.CSSProperties;
}

export function BlurredWrapper(
  props: React.PropsWithChildren<IBlurredWrapperProps>
) {
  const { message, children, style } = props;
  if (!message && children) {
    return <div style={style}>{children}</div>;
  }
  return (
    <div style={style} className="blurred-wrapper">
      <div className="blurred-wrapper-item">{children}</div>
      <div className="blurred-wrapper-message">
        <Text strong>{message}</Text>
      </div>
    </div>
  );
}
