import { Form, Select } from "antd";
import type { FormInstance } from "antd/es/form/Form";
import { type IObjectView } from "../../../../../../interfaces/object";

interface AddRecordsObjectViewFormProps {
  form: FormInstance<AddRecordsObjectViewFormData>;
  initialValues?: Partial<AddRecordsObjectViewFormData>;
  onSave: (values: AddRecordsObjectViewFormData) => any;
  views: IObjectView[];
}

export type AddRecordsObjectViewFormData = {
  recordIds: string[];
  viewId: string;
};

export function AddRecordsObjectViewForm(props: AddRecordsObjectViewFormProps) {
  const { form, initialValues, onSave, views } = props;

  return (
    <Form<AddRecordsObjectViewFormData>
      form={form}
      preserve={false}
      layout="vertical"
      initialValues={initialValues}
      onFinish={(values) => {
        onSave(values);
      }}
    >
      <Form.Item
        name={["viewId"]}
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Select
          options={views.map((v) => ({
            key: v.id,
            value: v.id,
            label: v.name,
          }))}
          style={{ width: "100%" }}
        />
      </Form.Item>
      <Form.Item
        noStyle
        hidden
        name={["recordIds"]}
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Select mode="multiple" />
      </Form.Item>
    </Form>
  );
}
