import * as React from "react";
import Logo from "../../../assets/logo/logo_square_dark.svg";

interface IOrgLogoProps
  extends Omit<
    React.DetailedHTMLProps<
      React.ImgHTMLAttributes<HTMLImageElement>,
      HTMLImageElement
    >,
    "src" | "alt"
  > {
  logo?: string;
  imageSize: number;
}

export function OrgLogo(props: IOrgLogoProps) {
  const { logo, imageSize, style, ...rest } = props;

  const [error, setError] = React.useState<boolean>(false);

  return (
    <img
      {...rest}
      src={logo && !error ? logo : Logo}
      alt="Whaly Logo"
      height={imageSize}
      style={{ ...style, borderRadius: 6, position: "absolute" }}
      width={imageSize}
      onError={() => setError(true)}
    />
  );
}
