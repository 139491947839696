import cuid from "cuid";
import * as React from "react";
import { compose } from "../../../components/compose/WlyCompose";
import Error from "../../../components/layout/feedback/error";
import Loading from "../../../components/layout/feedback/loading";
import type { INotificationWorkflow } from "../../../interfaces/notification";
import type { IPartnerPortal } from "../../../interfaces/org";
import type { ITopic } from "../../../interfaces/topic";
import type {
  IUserRoleWithGroups,
  UserTypeType,
} from "../../../interfaces/user";
import GraphQl from "../../graphql/graphql";
import type { InjectedOrgProps } from "../../orgs/WithOrg";
import WithOrg from "../../orgs/WithOrg";
import AccessManagementTable from "./AccessManagementTable";
import {
  GET_USER_ROLE_USER_WITH_NOTIFICATION_INFO,
  generateUserWhereInput,
} from "./domain";

type IAccessManagementTableWrapperProps =
  | IAccessManagementTableWrapperStandardProps
  | IAccessManagementTableWrapperPartnerProps;

interface IAccessManagementTableWrapperBaseProps {
  type?: UserTypeType;
  portal?: IPartnerPortal;
}

interface IAccessManagementTableWrapperStandardProps
  extends IAccessManagementTableWrapperBaseProps {
  type: "STANDARD";
}
interface IAccessManagementTableWrapperPartnerProps
  extends IAccessManagementTableWrapperBaseProps {
  type: "PORTAL";
  portal: IPartnerPortal;
}

const gql = GET_USER_ROLE_USER_WITH_NOTIFICATION_INFO;

interface IReturnValue {
  allUserRoles: IUserRoleWithGroups[];
  allTopics: ITopic[];
  allNotificationWorkflows: INotificationWorkflow[];
}

type Props = IAccessManagementTableWrapperProps & InjectedOrgProps;

interface IState {
  render: string;
}

class AccessManagementTableWrapper extends React.Component<Props, IState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      render: cuid(),
    };
  }

  reRender = () => {
    this.setState({ render: cuid() });
  };

  public render() {
    const { org, user, type, portal } = this.props;

    const where = generateUserWhereInput(type, org, user, portal);

    return (
      <GraphQl<IReturnValue>
        query={gql}
        variables={{
          orgId: org.id,
          userRoleWhereInput: where,
          render: this.state.render,
        }}
      >
        {(gql) => {
          if (gql.status === "loading" || gql.status === "initial") {
            return <Loading />;
          }
          if (gql.status === "error") {
            return <Error />;
          }

          return (
            <AccessManagementTable
              users={gql.data.allUserRoles}
              currentOrg={org}
              reRender={this.reRender}
              portal={portal}
              topics={gql.data.allTopics}
              workflows={gql.data.allNotificationWorkflows}
            />
          );
        }}
      </GraphQl>
    );
  }
}

export default compose<Props, IAccessManagementTableWrapperProps>(WithOrg)(
  AccessManagementTableWrapper
);
