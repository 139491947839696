import * as React from "react";
import "./ColumnMenu.scss";

interface IColumnMenuProps {
  handleClickOutside: (stale: boolean) => void;
  handleClose: () => void;
  confirmOpen: boolean;
  elements: ColumnMenuElement[];
}

export interface ColumnMenuElement {
  key: string;
  renderListItem: (
    handleClose: () => void,
    setComponent: (t: React.ReactElement<any>) => void,
    setStale: (stale: boolean) => void
  ) => React.ReactNode;
}

interface IState {
  isStale: boolean;

  element?: React.ReactElement<any>;
}

let isDebouncing = false;

export default class ColumnMenu extends React.Component<
  IColumnMenuProps,
  IState
> {
  ref: any;
  isDebouncing: boolean = false;

  constructor(props: IColumnMenuProps) {
    super(props);
    this.state = {
      isStale: false,
    };
  }

  public componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside, false);
  }

  public componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside, false);
  }

  handleClickOutside = (e: MouseEvent) => {
    const d = isDebouncing;
    isDebouncing = true;

    if (!d) {
      isDebouncing = true;
      setTimeout(() => {
        isDebouncing = false;
      }, 200);
    }
    if (this.ref.contains(e.target) || this.props.confirmOpen) {
      return;
    }
    if (!d) {
      this.props.handleClickOutside(this.state.isStale);
    }
  };

  setElement = (t: React.ReactElement<any>) => {
    this.setState({
      element: t,
    });
  };

  setStale = (t: boolean) => {
    this.setState({
      isStale: t,
    });
  };

  public render() {
    const { elements } = this.props;
    const { element } = this.state;
    return (
      <div style={{ zIndex: 1000 }} ref={(ref) => (this.ref = ref)}>
        {element ? (
          element
        ) : (
          <div className="column-menu">
            {elements.map((el, i) => {
              return (
                <div className="column-menu-item" key={i}>
                  {el.renderListItem(
                    this.props.handleClose,
                    this.setElement,
                    this.setStale
                  )}
                </div>
              );
            })}
          </div>
        )}
      </div>
    );
  }
}
