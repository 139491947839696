import * as React from "react";
import type { AsyncData } from "../../helpers/typescriptHelpers";
import GraphQLService from "../../services/graphql/GraphQLService";

interface IGraphQlProps<T> {
  query: string;
  variables: object;
  extract?: string;
  children(props: GQLRenderProps<T>): JSX.Element;
  /**
   * Polling interval in ms
   */
  intervalMs?: number;
}

type Props<T> = IGraphQlProps<T>;

type State<T> = AsyncData<T>;

type GQLRenderProps<T> = AsyncData<T>;

export default class GraphQl<T> extends React.Component<Props<T>, State<T>> {
  timeout = null;

  constructor(props: Props<T>) {
    super(props);
    this.state = {
      status: "initial",
    };
  }

  componentDidMount() {
    this.runQuery();
  }

  async runQuery(isInTimeOut?: boolean) {
    const { query, variables, extract, intervalMs } = this.props;
    if (!isInTimeOut) {
      this.setState({ status: "loading" });
    }
    try {
      const response: T = await GraphQLService(query, variables, extract);
      this.setState({ status: "success", data: response });
    } catch (error) {
      console.error(error);
      if (!isInTimeOut) {
        this.setState({ status: "error", error: error });
      }
    } finally {
      if (intervalMs) {
        this.timeout = setTimeout(() => {
          this.runQuery(true);
        }, intervalMs);
      }
    }
  }

  componentWillUnmount(): void {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
  }

  componentDidUpdate(prevProps: Props<T>) {
    const { query, variables, extract } = this.props;
    const {
      query: prevQuery,
      variables: prevVariables,
      extract: prevExtract,
    } = prevProps;
    if (
      query !== prevQuery ||
      JSON.stringify(variables) !== JSON.stringify(prevVariables) ||
      JSON.stringify(extract) !== JSON.stringify(prevExtract)
    ) {
      this.runQuery();
    }
  }

  public render() {
    return this.props.children && this.props.children(this.state);
  }
}
