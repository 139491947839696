import React from "react";
import type { RouteComponentProps } from "react-router";
import { withRouter } from "react-router";
import type { InjectedAntUtilsProps } from "../../../../../components/ant-utils/withAntUtils";
import { withAntUtils } from "../../../../../components/ant-utils/withAntUtils";
import { compose } from "../../../../../components/compose/WlyCompose";
import { Folder } from "../../../../../components/folders/view/Folder";
import type { IReportFolder } from "../../../../../interfaces/folder";
import { routeDescriptor } from "../../../../../routes/routes";
import GraphQLService from "../../../../../services/graphql/GraphQLService";
import { ReportFolderFragment } from "../../../../../store/dataStores/workspace/WorkspaceDatastoreDomain";
import type { InjectedOrgProps } from "../../../../orgs/WithOrg";
import WithOrg from "../../../../orgs/WithOrg";

interface IFolderMenuInnerProps {
  personalFolder?: IReportFolder;
  folders: IReportFolder[];
  shouldRerender: () => void;
  isDragging: boolean;
  updateFolders: (r: IReportFolder[]) => void;
}

type Props = IFolderMenuInnerProps &
  RouteComponentProps<{}> &
  InjectedAntUtilsProps &
  InjectedOrgProps;

function FolderMenuInner(props: Props) {
  const {
    history,
    match: { params },
    folders,
    antUtils,
    shouldRerender,
    isDragging,
    updateFolders,
    org,
    personalFolder,
  } = props;

  const [isWorkspaceFolderDragging, setWorkspaceFolderDragging] =
    React.useState<boolean>(false);

  const reorderReportFolders = (data: Array<{ id: string; order: number }>) => {
    updateFolders(
      folders.map((f) => {
        const found = data.find((d) => d.id === f.id);
        if (found) {
          return {
            ...f,
            order: found.order,
          };
        }
        return f;
      })
    );
    return GraphQLService(
      `
              mutation reorderReportFolders($data: [ReportFolderOrderUpdateInput]!) {
                reorderReportFolders(data: $data)
              }
            `,
      {
        data,
      }
    );
  };

  const updateReportFolder = (data: Array<{ id: string; data: any }>) => {
    updateFolders(
      folders.map((f) => {
        const found = data.find((d) => d.id === f.id);
        if (found) {
          return {
            ...f,
            name: found.data.name ? found.data.name : f.name,
            description: found.data.description
              ? found.data.description
              : f.description,
            cover: found.data.cover ? found.data.cover : f.cover,
            image: found.data.image ? found.data.image : f.image,
            color: found.data.color ? found.data.color : f.color,
          };
        }
        return f;
      })
    );
    return GraphQLService(
      `
              ${ReportFolderFragment}
  
              mutation updateReportFolder($updateReportfolders: [ReportFoldersUpdateInput]!) {
                updateReportFolders(data: $updateReportfolders) {
                  ...Folder
                }
              }
            `,
      {
        updateReportfolders: data,
      }
    );
  };

  const onDropReport = (r, f) => {
    const m = antUtils.message.loading({ content: "Moving report..." });
    return GraphQLService(
      `
    mutation updateReport($id: ID!, $data: ReportUpdateInput) {
      updateReport(id: $id, data: $data) {
        id
      }
    }
    `,
      {
        id: r.id,
        data: {
          folder: {
            connect: {
              id: f.id,
            },
          },
        },
      }
    ).then(() => {
      m();
      antUtils.message.success(`Report ${r.name} moved to folder ${f.name}`);
      shouldRerender();
    });
  };

  return (
    <Folder
      setSelectedSlug={(s, e) => {
        const route = routeDescriptor["folders"].renderRoute({
          ...params,
          folderSlug: s,
        });
        if (e.ctrlKey || e.metaKey) {
          window.open(route, "_blank");
        } else {
          history.push(route);
        }
      }}
      folders={folders}
      onShare={async () => {}}
      hideTitle={true}
      isDragging={isDragging}
      onDropReport={onDropReport}
      personalFolder={personalFolder}
      onChange={(folderChanges) => {
        return reorderReportFolders(
          Object.keys(folderChanges).map((key) => {
            return {
              id: key,
              order: folderChanges[key].order,
              parent:
                folderChanges[key].parent?.operation === "CONNECT"
                  ? {
                      connect: {
                        id: folderChanges[key].parent?.id,
                      },
                    }
                  : folderChanges[key].parent?.operation === "DISCONNECT"
                  ? {
                      disconnect: {
                        id: folderChanges[key].parent?.id,
                      },
                    }
                  : undefined,
            };
          })
        );
      }}
      onDelete={(idsToDelete) => {
        return updateReportFolder(
          idsToDelete.map((v) => {
            return {
              id: v,
              data: {
                deleted: true,
              },
            };
          })
        ).then((r) => {
          updateFolders(
            folders.filter(
              (f) => !r.updateReportFolders.map((a) => a.id).includes(f.id)
            )
          );
          setTimeout(() => {
            props.history.push(
              routeDescriptor["folders"].renderRoute({
                ...props.match.params,
                folderSlug: folders[0].slug,
              })
            );
          }, 100);
          return r;
        });
      }}
      onEdit={(v) => {
        return updateReportFolder([
          {
            id: v.id,
            data: {
              name: v.name,
              image: v.image,
              color: v.color,
            },
          },
        ]).then((r) => {
          updateFolders(
            folders.map((f) => {
              if (r.updateReportFolders.map((i) => i.id).includes(f.id)) {
                return r.updateReportFolders.find((i) => i.id === f.id);
              }
              return f;
            })
          );
          return r;
        });
      }}
      onCreate={(v) => {
        return GraphQLService(
          `
        ${ReportFolderFragment}

        mutation createReportFolder($orgId: ID!, $name: String!, $image: String!, $order: Int!, $parent: ReportFolderRelateToOneInput) {
          createReportFolder(data: {
            name: $name,
            image: $image,
            order: $order,
            parent: $parent,
            org: {
              connect: {
                id: $orgId
              }
            }
          }) {
            ...Folder
          }
        }`,
          {
            orgId: org.id,
            name: v.name,
            image: v.image,
            parent: v.parentId
              ? {
                  connect: {
                    id: v.parentId,
                  },
                }
              : undefined,
            order: v.order,
          }
        ).then((r) => {
          updateFolders([...folders, r.createReportFolder]);
          setTimeout(() => {
            props.history.push(
              routeDescriptor["folders"].renderRoute({
                ...props.match.params,
                folderSlug: r.createReportFolder.slug,
              })
            );
          }, 100);

          return r.createReportFolder as IReportFolder;
        });
      }}
      setDragging={setWorkspaceFolderDragging}
    />
  );
}

export default compose<Props, IFolderMenuInnerProps>(
  withRouter,
  withAntUtils,
  WithOrg
)(FolderMenuInner);
