import * as React from "react";
import "./FormActions.scss";
import { Button } from "antd";

interface IFormActionsProps {
  onCancel?: () => void;
  onSubmit?: () => void;
  isSubmitting: boolean;
  size?: "small" | "middle" | "large";
  flex?: boolean;
  hideSubmit?: boolean;
  submitText?: string;
}

export default class FormActions extends React.Component<IFormActionsProps> {
  public render() {
    const {
      onCancel,
      onSubmit,
      isSubmitting,
      size,
      flex,
      hideSubmit,
      submitText,
    } = this.props;
    return (
      <div className={flex ? "flex form-actions" : "form-actions absolute"}>
        {onCancel && (
          <Button
            size={size}
            onClick={onCancel}
            className="cancel"
            disabled={isSubmitting}
          >
            Cancel
          </Button>
        )}
        {!hideSubmit && (
          <Button
            size={size}
            onClick={onSubmit}
            htmlType="submit"
            className="submit"
            type="primary"
            disabled={isSubmitting}
            loading={isSubmitting}
          >
            {submitText ? submitText : "Submit"}
          </Button>
        )}
      </div>
    );
  }
}
