import { compose } from "../../../../../../components/compose/WlyCompose";
import type { ExtendedMeasureType } from "../../../../../../components/measures/measure-table/MeasureTable";
import type { IDestination } from "../../../../../../interfaces/destinations";
import type { IObject } from "../../../../../../interfaces/object";
import type {
  IDataset,
  IDatasetRelationship,
} from "../../../../../../interfaces/sources";
import type { SchemaResult } from "../../../../../../interfaces/transformations";
import { routeDescriptor } from "../../../../../../routes/routes";
import type { IObjectUpdatePayload } from "../../../../../../services/ExplorationMutationService";
import { mutateObjectFromCode } from "../../../../../../services/ExplorationMutationService";
import type { InjectedOrgProps } from "../../../../../orgs/WithOrg";
import WithOrg from "../../../../../orgs/WithOrg";
import type { TabData } from "../../../domain";
import type {
  ExplorationOperation,
  SelectedItems,
  StaleElements,
} from "../../../exploration/domain";
import TableConfigurationEdit from "../../../exploration/TableConfigurationEdit";

interface IObjectTableEditorProps {
  object: IObject;
  selectedItems: SelectedItems;
  onClick: (selectedItems: SelectedItems) => void;
  fetchObject: (objectId: string) => Promise<void>;
  removeStaleElement: (operationId: string) => void;
  pushStaleElement: (e: ExplorationOperation | ExplorationOperation[]) => void;
  staleElements: StaleElements;
  dataStore: {
    [tableKey: string]: TabData;
  };
  fetchTableSchema: (
    tablesSchemas: Array<{ datasetId: string; viewId: string }>
  ) => Promise<SchemaResult[]>;
  allIncomingDatasetRelationships: IDatasetRelationship[];
  allDatasets: IDataset[];
  updateStaleElement: (
    type: ExtendedMeasureType,
    objectId: string,
    data: any
  ) => void;
  removeAllStaleElements: () => void;
  currentWarehouse: IDestination;
  allObjects: IObject[];
}

type Props = IObjectTableEditorProps & InjectedOrgProps;

function ObjectTableEditor(props: Props) {
  const {
    object,
    onClick,
    selectedItems,
    staleElements,
    pushStaleElement,
    dataStore,
    fetchTableSchema,
    allIncomingDatasetRelationships,
    allDatasets,
    removeStaleElement,
    updateStaleElement,
    removeAllStaleElements,
    org,
    fetchObject,
    allObjects,
    currentWarehouse,
  } = props;

  const save = (objectUpdatePayload: IObjectUpdatePayload) => {
    return mutateObjectFromCode(
      org.id,
      object.model.id,
      objectUpdatePayload,
      Array.isArray(selectedItems) && selectedItems.length > 0
        ? {
            resourceId: selectedItems[0].id,
            resourceType: selectedItems[0].type,
          }
        : undefined
    );
  };

  return (
    <TableConfigurationEdit
      overrideHeaderName="Configuration"
      currentWarehouse={currentWarehouse}
      currentId={object?.id}
      displayName={object?.name}
      onClick={onClick}
      selectedItems={selectedItems}
      staleElements={staleElements}
      pushStaleElement={pushStaleElement}
      dataStore={dataStore}
      fetchTableSchema={fetchTableSchema}
      allIncomingDatasetRelationships={allIncomingDatasetRelationships}
      allDatasets={allDatasets}
      removeStaleElement={removeStaleElement}
      updateStaleElement={updateStaleElement}
      removeAllStaleElements={removeAllStaleElements}
      fetch={fetchObject}
      worskspaceRoute={
        object?.canBeListed
          ? routeDescriptor["object"].renderRoute({
              organizationSlug: org.slug,
              objectSlug: object.slug,
            })
          : undefined
      }
      objectInfos={object}
      tables={[object.table]}
      save={save}
      allObjects={allObjects}
      disabledCapabilities={[
        "table::blend",
        "table::name",
        "table::model",
        "table::add_related_data",
        "table::add_dimension",
        "table::add_semantic_group",
      ]}
    />
  );
}

export default compose<Props, IObjectTableEditorProps>(WithOrg)(
  ObjectTableEditor
);
