import { gql } from "@apollo/client";
import type { MutateOptions } from "../../../../../components/hooks/query/useQGLMutate";
import { useGQLMutate } from "../../../../../components/hooks/query/useQGLMutate";

type MutationData = { createTopicGroup: { id: string } };

type MutationVariables = {
  data: {
    name: string;
    org: { connect: { id: string } };
    workflowSelections: {
      create: Array<{
        org: { connect: { id: string } };
        workflow: { connect: { id: string } };
      }>;
    };
  };
};

const CreateTopicGroupMutation = gql`
  mutation CreateTopicGroup($data: TopicGroupCreateInput!) {
    createTopicGroup(data: $data) {
      id
    }
  }
`;

export const useCreateTopicGroup = (options?: MutateOptions<MutationData>) => {
  return useGQLMutate<MutationData, MutationVariables>(
    CreateTopicGroupMutation,
    options
  );
};
