import { PlusOutlined } from "@ant-design/icons";
import { Avatar, Button, Col, Form, Input, Row, Space } from "antd";
import type { FormInstance } from "antd/lib/form";
import EmojiRenderer from "../../../components/form/emoji-picker/EmojiRenderer";
import type { IUserRoleType } from "../../../interfaces/user";
import GraphQLService from "../../../services/graphql/GraphQLService";
import { CREATE_USER_EMAIL_INVITE_GQL } from "../../settings/users-management/NewUserForm";

interface IUserInviteFormProps {
  form: FormInstance<IUserInviteValues>;
  onFinish: (v: IUserInviteValues) => Promise<void>;
  ref?: any;
}

export interface IUserInviteValues {
  emails: string[];
}

export const inviteUsers = (
  values: IUserInviteValues,
  orgId: string,
  role: IUserRoleType
): Promise<any> =>
  GraphQLService(
    CREATE_USER_EMAIL_INVITE_GQL,
    {
      orgId: orgId,
      emails: values.emails.filter((e) => e.length > 0),
      role: role,
    },
    "invite"
  );

export function UserInviteForm(props: IUserInviteFormProps) {
  const { form, onFinish } = props;
  return (
    <Row gutter={16}>
      <Col span={12}>
        <div style={{ padding: "0px 0px 20px 0px" }}>
          Data is most useful when shared ! Know a few colleagues who'd like to
          explore data with you ? Invite them to collaborate on Whaly
        </div>
        <Space size={"middle"}>
          <Avatar.Group>
            <Avatar size={50} style={{ backgroundColor: "#C6C6C6" }}>
              <EmojiRenderer
                emoji=":technologist:"
                size={40}
                style={{ paddingTop: 14 }}
              />
            </Avatar>
            <Avatar size={50} style={{ backgroundColor: "#C6C6C6" }}>
              <EmojiRenderer
                emoji=":astronaut:"
                size={40}
                style={{ paddingTop: 14 }}
              />
            </Avatar>
            <Avatar size={50} style={{ backgroundColor: "#C6C6C6" }}>
              <EmojiRenderer
                emoji=":mechanic:"
                size={40}
                style={{ paddingTop: 14 }}
              />
            </Avatar>
            <Avatar size={50} style={{ backgroundColor: "#C6C6C6" }}>
              <EmojiRenderer
                emoji=":mage:"
                size={40}
                style={{ paddingTop: 14 }}
              />
            </Avatar>
            <Avatar size={50} style={{ backgroundColor: "#C6C6C6" }}>
              <EmojiRenderer
                emoji=":office_worker:"
                size={40}
                style={{ paddingTop: 14 }}
              />
            </Avatar>
          </Avatar.Group>
        </Space>
      </Col>
      <Col span={12}>
        <Form
          name="dynamic_form_item"
          initialValues={{ emails: ["", "", ""] }}
          form={form}
          onFinish={onFinish}
        >
          <Form.List
            name="emails"
            rules={[
              {
                validator: async (_, emails) => {
                  if (!emails || emails.filter((v) => v).length < 1) {
                    return Promise.reject(
                      new Error("At least 1 user email is required")
                    );
                  }
                },
              },
            ]}
          >
            {(fields, { add }, { errors }) => (
              <>
                {fields.map((field, index) => (
                  <Form.Item required={false} key={field.key}>
                    <Form.Item
                      {...field}
                      validateTrigger={["onChange", "onBlur"]}
                      rules={[
                        {
                          required: false,
                          type: "email",
                          message: "Must be a valid email address",
                        },
                      ]}
                      noStyle
                    >
                      <Input placeholder="email@domain.com" />
                    </Form.Item>
                  </Form.Item>
                ))}
                <div style={{ width: "100%", textAlign: "right" }}>
                  <Button
                    type="dashed"
                    block
                    onClick={() => add()}
                    icon={<PlusOutlined />}
                  >
                    Add another invitation
                  </Button>
                </div>
                <Form.Item>
                  <Form.ErrorList errors={errors} />
                </Form.Item>
              </>
            )}
          </Form.List>
        </Form>
      </Col>
    </Row>
  );
}
