import {
  CheckCircleTwoTone,
  CloseCircleTwoTone,
  LoadingOutlined,
} from "@ant-design/icons";
import { Space, Typography } from "antd";
import React from "react";
import type { AsyncData } from "../../helpers/typescriptHelpers";

const { Text } = Typography;

interface IMigrationHelperProps {
  steps: IMigrationStep[];
  onDone: () => Promise<void>;
}

export interface IMigrationStep {
  name: JSX.Element;
  onStart: () => Promise<void>;
  store: AsyncData<{}>;
}

export function MigrationHelper(props: IMigrationHelperProps) {
  const { steps, onDone } = props;

  const onMount = "mount";

  React.useEffect(() => {
    steps
      .reduce((acc, v) => {
        return acc.then(v.onStart);
      }, Promise.resolve())
      .then(onDone);
  }, [onMount]);

  return (
    <Space direction="vertical">
      {steps.map((s, i) => {
        const isDisabled = s.store.status === "initial";

        const renderIcon = () => {
          if (s.store.status === "initial") {
            return <CheckCircleTwoTone twoToneColor={"#dddddd"} />;
          }
          if (s.store.status === "loading") {
            return <LoadingOutlined />;
          }
          if (s.store.status === "error") {
            return <CloseCircleTwoTone twoToneColor={"#eb2f96"} />;
          }

          return <CheckCircleTwoTone twoToneColor={"#52c41a"} />;
        };

        return (
          <Text key={i} disabled={isDisabled}>
            {renderIcon()}
            {"  "}
            {s.name}
          </Text>
        );
      })}
    </Space>
  );
}
