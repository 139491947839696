import { Button, Col, Row, Tag, Typography } from "antd";
import moment from "moment";
import React from "react";
import type { InjectedAntUtilsProps } from "../../../../components/ant-utils/withAntUtils";
import { withAntUtils } from "../../../../components/ant-utils/withAntUtils";
import { WlyCard } from "../../../../components/cards/WlyCard";
import { compose } from "../../../../components/compose/WlyCompose";
import type { ISchedule } from "../../../../interfaces/schedule";
import type { IParsedWarehouseSize } from "../../../../interfaces/sources";
import type { ITable } from "../../../../interfaces/table";
import { LocaleService } from "../../../../services/localeService";
import type { InjectedOrgProps } from "../../../orgs/WithOrg";
import WithOrg from "../../../orgs/WithOrg";
import CacheStrategyModal from "./cacheStrategyModal";
import type { DataExtractConfig, QueryMode } from "./domain";
import { getTotalServingLayerSpaceUsedBytes } from "./domain";
import QueryModeEditModal from "./queryModeEditModal";
import QueryModeStorageCard from "./queryModeStorageModal";
import ServingLayerQueryExecution from "./servingLayerExecutionHistory";
import WarehouseStorageModal from "./warehouseStorageModal";
const { Text } = Typography;

interface IQueryModeCardProps {
  isExtractEnabled: boolean;
  extractSchedule: ISchedule;
  objectType: string;
  objectId: string;
  tables: ITable[];
  onExtractDataNow: (objectId: string) => Promise<void>;
  onSave: (
    queryMode: QueryMode,
    dataExtractConfig: DataExtractConfig
  ) => Promise<void>;
}

type Props = IQueryModeCardProps & InjectedOrgProps & InjectedAntUtilsProps;

function QueryModeCard(props: Props) {
  const {
    isExtractEnabled,
    extractSchedule,
    objectType,
    objectId,
    tables,
    user: { locale },
    onExtractDataNow: onExplorationExtractDataNow,
    onSave,
  } = props;

  const [extractIsBooting, setExtractIsBooting] =
    React.useState<boolean>(false);
  const [queryModeModalOpen, setQueryModeModalOpened] =
    React.useState<boolean>(false);
  const [queryModeStorageModalOpened, setQueryModeStorageModalOpened] =
    React.useState<boolean>(false);
  const [warehouseStorageModalOpened, setWarehouseStorageModalOpened] =
    React.useState<boolean>(false);
  const [cacheStrategyModalOpened, setCacheStrategyModalOpened] =
    React.useState<boolean>(false);

  const storedBytesInSV = getTotalServingLayerSpaceUsedBytes(tables);
  const localeService = new LocaleService(locale);
  const numeral = localeService.getNumberDefaultFormatting();

  const renderServingLayerQueryMode = (): JSX.Element => {
    if (!extractSchedule) {
      return (
        <>
          No extracts are scheduled, this is not expected, please reach out to
          your support contact to fix this issue
        </>
      );
    }
    const renderExtractFrequency = (): JSX.Element => {
      if (extractSchedule.period === "day") {
        return (
          <p>
            Daily extract at: 0{extractSchedule.atHour}:00 (timezone:{" "}
            {extractSchedule.timezone})
          </p>
        );
      }
      if (extractSchedule.period === "hour") {
        return <p>Hourly extract</p>;
      }
      return (
        <p>
          Extract period ({extractSchedule.period}) is not supported, please
          reach out to your support contact to fix this issue
        </p>
      );
    };

    const renderExtractFreshness = (): JSX.Element => {
      if (extractSchedule.period === "day") {
        return (
          <p>
            Daily at: 0{extractSchedule.atHour}:00 (timezone:{" "}
            {extractSchedule.timezone})
          </p>
        );
      }
      if (extractSchedule.period === "hour") {
        return <p>Hourly</p>;
      }
      return <p>Not supported</p>;
    };

    const renderLastExtractInfo = (): JSX.Element => {
      const lastJobExecution = extractSchedule.lastJobExecution;
      if (!lastJobExecution) {
        return <p>No extract where run yet</p>;
      }
      const lastExtractMoment = moment(lastJobExecution.createdAt);
      const now = moment();
      const minSinceLastExtract = now.diff(lastExtractMoment, "minute");
      switch (lastJobExecution.status) {
        case "ERROR":
        case "FAILED":
          return (
            <p>
              Last extract ran with errors <b>{minSinceLastExtract} minutes</b>{" "}
              ago.
            </p>
          );
        case "SUCCESSED":
          return (
            <p>
              Last extract ran successfully <b>{minSinceLastExtract} minutes</b>{" "}
              ago.
            </p>
          );
        case "RUNNING":
          return <p>An extract is currently being run</p>;
        default:
          return <></>;
      }
    };
    const renderNextExtractInfo = (): JSX.Element => {
      if (!extractSchedule.nextScheduledAt) {
        return (
          <p>
            No extract is scheduled, please reach out to your support contact to
            fix this issue
          </p>
        );
      }
      const nextSyncDate = moment(extractSchedule.nextScheduledAt);
      const now = moment();
      const minutesToNextSync = nextSyncDate.diff(now, "minute");
      if (minutesToNextSync >= 60) {
        return (
          <p>
            Next Extract will run in{" "}
            <b>{nextSyncDate.diff(now, "hour")} hours</b>
          </p>
        );
      } else if (minutesToNextSync <= 5) {
        return (
          <p>
            Next Extract will run in <b>less than 10 minutes!</b>
          </p>
        );
      } else {
        return (
          <p>
            Next Extract will run in <b>{minutesToNextSync} minutes</b>
          </p>
        );
      }
    };
    return (
      <Row gutter={[16, 16]}>
        <Col span={12}>
          <Text strong>Query Mode</Text>
          <br />
          <Tag>
            <Text strong>Serving layer</Text>
          </Tag>
        </Col>
        <Col span={12}>
          <Text strong>Extract frequency</Text>
          <br />
          {renderExtractFrequency()}
        </Col>
        <Col span={12}>
          <Text strong>Last extract info</Text>
          <br />
          {renderLastExtractInfo()}
        </Col>
        <Col span={12}>
          <Text strong>Next extract info</Text>
          <br />
          {renderNextExtractInfo()}
        </Col>
        <Col span={12}>
          <Text strong>Storage infos</Text>
          <br />
          <p>
            <b>{numeral(storedBytesInSV).format("0.0 b")}</b> space used,{" "}
            <a
              onClick={() => {
                setQueryModeStorageModalOpened(true);
              }}
            >
              view details
            </a>
          </p>
        </Col>
        <Col span={12}>
          <Text strong>Data freshness</Text>
          <br />
          {renderExtractFreshness()}
        </Col>
        <Col span={24}>
          <ServingLayerQueryExecution
            objectType={objectType}
            objectId={objectId}
          />
        </Col>
      </Row>
    );
  };

  const renderDirectQueryMode = (): JSX.Element => {
    const localeService = new LocaleService(locale);
    const numeral = localeService.getNumberDefaultFormatting();
    const warehouseTotalSize: IParsedWarehouseSize | undefined = tables.reduce<
      IParsedWarehouseSize | undefined
    >(
      (acc, curr) => {
        if (acc === undefined) {
          return acc;
        }
        const dataset = curr.view.dataset;
        if (dataset.isModel && dataset.isPersistedAs === "ephemeral") {
          return undefined;
        }
        const incSizeBytes = (): number | undefined => {
          if (!dataset.warehouseSize?.sizeBytes) {
            return undefined;
          }
          return acc.sizeBytes + parseInt(dataset.warehouseSize.sizeBytes);
        };
        const incRowCount = (): number | undefined => {
          if (!dataset.warehouseSize?.rowCount) {
            return undefined;
          }
          return acc.rowCount + parseInt(dataset.warehouseSize.rowCount);
        };
        return {
          sizeBytes: incSizeBytes(),
          rowCount: incRowCount(),
        };
      },
      {
        sizeBytes: 0,
        rowCount: 0,
      }
    );

    return (
      <Row gutter={[16, 16]}>
        <Col span={12}>
          <Text strong>Query Mode</Text>
          <br />
          <Tag>
            <Text strong>Direct Query</Text>
          </Tag>
        </Col>
        <Col span={12}>
          <Text strong>Data freshness</Text>
          <br />
          <p>
            Based on your cache strategy{" "}
            <a onClick={() => setCacheStrategyModalOpened(true)}>
              view details
            </a>
          </p>
        </Col>
        <Col span={12}>
          <Text strong>Storage detail</Text>
          <br />
          <p>
            <b>
              {warehouseTotalSize?.sizeBytes
                ? numeral(warehouseTotalSize?.sizeBytes).format("0.00b")
                : "N/A"}
            </b>{" "}
            space used,{" "}
            <a
              onClick={() => {
                setWarehouseStorageModalOpened(true);
              }}
            >
              view details
            </a>
          </p>
        </Col>
      </Row>
    );
  };

  return (
    <>
      <WlyCard
        title="Query Mode"
        extra={
          <>
            {isExtractEnabled ? (
              <>
                <Button
                  type="link"
                  loading={
                    ["running", "scheduled"].includes(
                      extractSchedule?.status
                    ) || extractIsBooting
                  }
                  size="small"
                  style={{ marginRight: 8 }}
                  onClick={async () => {
                    setExtractIsBooting(true);
                    try {
                      await onExplorationExtractDataNow(objectId);
                    } finally {
                      setExtractIsBooting(false);
                    }
                  }}
                >
                  Extract Now
                </Button>
              </>
            ) : undefined}
            <Button
              type="primary"
              size="small"
              onClick={() => setQueryModeModalOpened(true)}
            >
              Edit
            </Button>
          </>
        }
      >
        {isExtractEnabled
          ? renderServingLayerQueryMode()
          : renderDirectQueryMode()}
      </WlyCard>
      <QueryModeEditModal
        currentQueryMode={isExtractEnabled ? "SERVING_LAYER" : "DIRECT_QUERY"}
        extractSchedule={extractSchedule}
        visible={queryModeModalOpen}
        onSave={onSave}
        onClose={() => setQueryModeModalOpened(false)}
      />
      <QueryModeStorageCard
        visible={queryModeStorageModalOpened}
        extractSchedule={extractSchedule}
        tables={tables}
        onClose={() => setQueryModeStorageModalOpened(false)}
      />
      <CacheStrategyModal
        visible={cacheStrategyModalOpened}
        tables={tables}
        onClose={() => setCacheStrategyModalOpened(false)}
      />
      <WarehouseStorageModal
        visible={warehouseStorageModalOpened}
        tables={tables}
        onClose={() => setWarehouseStorageModalOpened(false)}
      />
    </>
  );
}

export default compose<Props, IQueryModeCardProps>(
  WithOrg,
  withAntUtils
)(QueryModeCard);
