import {
  ArrowRightOutlined,
  ArrowsAltOutlined,
  CodeOutlined,
  DoubleRightOutlined,
  ShrinkOutlined,
} from "@ant-design/icons";
import createEngine from "@projectstorm/react-diagrams";
import { Badge, Button, Space, Tooltip } from "antd";
import _ from "lodash";
import { inject, observer } from "mobx-react";
import * as React from "react";
import { ResizableBox } from "react-resizable";
import { withRouter } from "react-router";
import type { InjectedAntUtilsProps } from "../../../../../../components/ant-utils/withAntUtils";
import { withAntUtils } from "../../../../../../components/ant-utils/withAntUtils";
import { compose } from "../../../../../../components/compose/WlyCompose";
import Feednack from "../../../../../../components/layout/feedback/feedback";
import type { AsyncData } from "../../../../../../helpers/typescriptHelpers";
import type { IDestination } from "../../../../../../interfaces/destinations";
import type { IDataset } from "../../../../../../interfaces/sources";
import type {
  SchemaResult,
  Transformation,
  TransformationDomain,
} from "../../../../../../interfaces/transformations";
import {
  validateResult as brizoValidateResult,
  computeTransformations,
} from "../../../../../../services/BrizoService";
import type { WorkbenchUIStoreProps } from "../../../../../../store/workbenchUIStore";
import workbenchUIStore from "../../../../../../store/workbenchUIStore";
import { generateUniqueId } from "../../../../../../utils/uniqueId";
import type { InjectedOrgProps } from "../../../../../orgs/WithOrg";
import WithOrg from "../../../../../orgs/WithOrg";
import FlowToSQL from "../../../../../spreadsheet/flowMigration/FlowToSQL";
import * as Toolbar from "../../../../../spreadsheet/toolbar/Toolbar";
import type {
  IActiveObjectDatasetUrlState,
  IActiveObjectFlowDatasetUIState,
  IDatasetUpdate,
  ModifyQuery,
  Store,
} from "../../../domain";
import { DEFAULT_WORKBENCH_RECORD_NUMBER } from "../../../domain";
import { ModelMigrationModal } from "../../../migration/MigrationModal";
import PreSaveModelModal from "../common/PreSaveModelModal";
import PrimaryKeysModal from "../common/PrimaryKeysModal";
import "./FlowConfiguration.scss";
import { CanvasWrapper } from "./canvas/CanvasWrapper";
import { generateEngine } from "./domain";
import type { EventListener } from "./helpers/CanvasNodeGenerator";
import { CanvasNodeGenerator } from "./helpers/CanvasNodeGenerator";
import { HAS_FORM, modifyChildKey } from "./operations/OperationHandler";
import OperationPanel from "./panel/OperationPanel";
import { WlyDefaultState } from "./stateMachine/WlyDefaultState";

interface IFlowConfigurationProps {
  datasets: IDataset[];
  query: Transformation[];
  staleQuery?: string;
  setStaleQuery?: (v: string) => void;
  setStaleHead?: (h: string) => void;
  staleHead?: string;
  isStale: boolean;
  onUpdateDataset?: IDatasetUpdate;
  onModifyTransformation?: ModifyQuery;
  dataset: IDataset;
  onRefreshDatasets?: (id?: string[]) => Promise<void>;
  onRefreshExplorations?: (id?: string[]) => Promise<void>;
  saveUIState?: (state: IActiveObjectFlowDatasetUIState) => void;
  initialState?: IActiveObjectFlowDatasetUIState;
  currentWarehouse: IDestination;
}

interface IState {
  height: number;
  maxHeight: number;
  output?: string;
  transformations: Transformation[];
  dataStore: {
    [key: string]: {
      store: Store;
    };
  };
  operationSchema: {
    [key: string]: AsyncData<SchemaResult>;
  };
  primaryKeysModalVisible: boolean;
  primaryKeysModalSaving: boolean;
  preSaveModalVisible: boolean;
  showSQLModal: boolean;
  showMigrationModal: boolean;
}

type Props = IFlowConfigurationProps &
  InjectedOrgProps &
  InjectedAntUtilsProps &
  WorkbenchUIStoreProps;

const MIN_FLOW_HEIGHT = 80;

class FlowConfiguration extends React.Component<Props, IState> {
  engine = generateEngine(createEngine());
  canvasNodeGenerator: CanvasNodeGenerator;
  isFirstPaint: boolean;

  constructor(props: Props) {
    super(props);
    this.isFirstPaint = true;
    const transformations = props.staleQuery
      ? this.parseStaleQuery(props.staleQuery)
      : [];
    this.state = {
      height: 300,
      maxHeight: 500,
      output: props.staleHead,
      transformations: transformations,
      operationSchema: {},
      dataStore: {},
      primaryKeysModalVisible: false,
      primaryKeysModalSaving: false,
      preSaveModalVisible: false,
      showSQLModal: false,
      showMigrationModal: false,
    };

    this.canvasNodeGenerator = this.computeModel(
      this.state.transformations,
      props.datasets,
      true
    );
  }

  debouncedSaveUIState = _.debounce(
    this.props.saveUIState ?? (() => null),
    200
  );

  parseStaleQuery = (q: string): Transformation[] => {
    try {
      const t = JSON.parse(q) as Transformation[];
      // we only keep what is being written as datasetResolver since our engine adds
      // operation on the view automatically for legacy tables
      return t.filter((a) => a.domain === "datasetResolver");
    } catch (err) {
      console.error(err);
      return [];
    }
  };

  componentDidMount() {
    this.computeModel(this.state.transformations, this.props.datasets, true);
    this.setState({ maxHeight: this.getEditorMaxHeight() });
  }

  UNSAFE_componentWillUpdate(nextProps: Props) {
    if (
      nextProps.staleQuery !== this.props.staleQuery &&
      nextProps.staleQuery
    ) {
      try {
        const q = JSON.parse(nextProps.staleQuery) as Transformation[];
        this.setState({
          transformations: q,
        });
      } catch (err) {
        console.error(err);
      }
    }
  }

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<IState>,
    snapshot?: any
  ): void {
    if (prevProps.dataset.id !== this.props.dataset.id) {
      this.setState(this.resetState(), () => {
        this.canvasNodeGenerator = this.computeModel(
          this.state.transformations,
          this.props.datasets,
          true
        );
      });
    }
  }

  resetState = (): IState => {
    return {
      height: this.state.height ?? 300,
      maxHeight: this.state.maxHeight ?? 500,
      output: this.props.staleHead,
      transformations: this.props.staleQuery
        ? this.parseStaleQuery(this.props.staleQuery)
        : [],
      operationSchema: {},
      dataStore: {},
      primaryKeysModalVisible: false,
      primaryKeysModalSaving: false,
      preSaveModalVisible: false,
      showSQLModal: false,
      showMigrationModal: false,
    };
  };

  // seems to cause issue when saving state
  // componentWillUnmount(): void {
  //   this.setSelection(null);
  // }

  validateQuery = async (transformations: Transformation[]) => {
    const validateResult = await brizoValidateResult(
      this.props.currentWarehouse?.id,
      transformations
    );

    if (validateResult.status === "error") {
      throw new Error(validateResult.error_message);
    }
  };

  updateQuery = (
    transformations: Transformation[],
    updateAtHead?: string,
    callback?: () => void
  ) => {
    const { setStaleQuery } = this.props;
    const p = JSON.stringify(
      transformations.map((t) => ({ ...t, domain: "datasetResolver" }))
    );
    if (setStaleQuery) {
      setStaleQuery(p);
    }
    this.setState(
      {
        transformations: transformations,
      },
      () => {
        this.computeModel(this.state.transformations, this.props.datasets);
        if (callback) {
          callback();
          this.computeModel(this.state.transformations, this.props.datasets);
        }
        if (
          updateAtHead &&
          this.canvasNodeGenerator.getQueryAtNode(updateAtHead)
        ) {
          this.fetchSpreadsheetData(
            this.props.currentWarehouse?.id,
            this.canvasNodeGenerator.getQueryAtNode(updateAtHead)
          );
        }
      }
    );
  };

  setSelection = (v: string | null) => {
    const {
      currentWarehouse,
      saveUIState,
      workbenchUIStore: { setActiveObjectUrlParams, getActiveObject },
    } = this.props;
    const activeObject = getActiveObject();
    if (!v) {
      setActiveObjectUrlParams(activeObject, {
        ...activeObject?.urlState,
        node_flow_selection: null,
      });
      saveUIState({ configCanvasNodeSelection: null });
    } else {
      if (this.canvasNodeGenerator) {
        const query = this.canvasNodeGenerator.getQueryAtNode(v);
        if (query && query.length) {
          setActiveObjectUrlParams(activeObject, {
            ...activeObject.urlState,
            node_flow_selection: v,
          });
          saveUIState({ configCanvasNodeSelection: v });
          if (query.length) {
            this.fetchSpreadsheetData(currentWarehouse?.id, query);
            if (
              HAS_FORM.includes(
                this.canvasNodeGenerator.getNodeIndex(v).step.operation.type
              )
            ) {
              this.fetchPreviousStepSchema(currentWarehouse?.id, query);
            }
          }
        }
      }
    }
  };

  getSelection = (): string | undefined => {
    const {
      workbenchUIStore: { getActiveObject },
    } = this.props;
    return (getActiveObject()?.urlState as IActiveObjectDatasetUrlState)
      ?.node_flow_selection;
  };

  fetchPreviousStepSchema = (warehouseId: string, query: Transformation[]) => {
    const head = query[query.length - 1].var;
    const prevKey = this.canvasNodeGenerator.getNodeIndex(head).children[0];
    const prevQuery = this.canvasNodeGenerator.getQueryAtNode(prevKey);

    this.setState({
      operationSchema: {
        ...this.state.operationSchema,
        [head]: {
          status: "loading",
        },
      },
    });

    // we don't have a prevKey if in the beginning of a branch
    if (!prevKey) {
      setTimeout(() => {
        this.setState({
          operationSchema: {
            ...this.state.operationSchema,
            [head]: {
              status: "success",
              data: null,
            },
          },
        });
      }, 200);
    }
    // we are not at the beginning of the branch
    else {
      return computeTransformations(warehouseId, {
        schema: [
          ...prevQuery,
          {
            var: generateUniqueId(),
            operation: {
              type: "Table.Schema",
              args: {
                table: prevKey,
              },
            },
            domain: "viewResolver",
          },
        ],
      })
        .then((r) => {
          this.setState({
            operationSchema: {
              ...this.state.operationSchema,
              [head]: {
                status: "success",
                data: r.data.schema as SchemaResult,
              },
            },
          });
        })
        .catch((err) => {
          this.setState({
            operationSchema: {
              ...this.state.operationSchema,
              [head]: {
                status: "error",
                error: err,
              },
            },
          });
        });
    }
  };

  fetchSpreadsheetData = (warehouseId: string, query: Transformation[]) => {
    const head = query[query.length - 1].var;
    this.setState({
      dataStore: {
        ...this.state.dataStore,
        [head]: {
          ...this.state.dataStore[head],
          store: {
            ...this.state.dataStore[head]?.store,
            raw: {
              status: "loading",
            },
          },
        },
      },
    });
    return computeTransformations(warehouseId, {
      records: [
        ...query,
        {
          var: generateUniqueId(),
          operation: {
            type: "Table.FirstN",
            args: {
              table: query[query.length - 1].var,
              countOrCondition: DEFAULT_WORKBENCH_RECORD_NUMBER,
            },
          },
          domain: "viewResolver",
        } as any,
      ],
      count: [
        ...query,
        {
          operation: {
            type: "Table.RowCount",
            args: {
              table: query[query.length - 1].var,
            },
          },
          var: generateUniqueId(),
          domain: "viewResolver",
        },
      ],
      schema: [
        ...query,
        {
          var: generateUniqueId(),
          operation: {
            type: "Table.Schema",
            args: {
              table: query[query.length - 1].var,
            },
          },
          domain: "viewResolver",
        },
      ],
    })
      .then((r) => {
        this.setState({
          dataStore: {
            ...this.state.dataStore,
            [head]: {
              ...this.state.dataStore[head],
              store: {
                ...this.state.dataStore[head].store,
                raw: {
                  status: "success",
                  data: {
                    list: r.data.records as any,
                    schema: r.data.schema as any,
                    rowCount: r.data.count as any,
                  },
                },
              },
            },
          },
        });
      })
      .catch((err) => {
        if (this.state.dataStore[head]) {
          this.setState({
            dataStore: {
              ...this.state.dataStore,
              [head]: {
                ...this.state.dataStore[head],
                store: {
                  ...this.state.dataStore[head].store,
                  raw: {
                    status: "error",
                    error: err,
                  },
                },
              },
            },
          });
        }
      });
  };

  computeModel = (
    transformations: Transformation[],
    datasets: IDataset[],
    initialLoad?: boolean
  ) => {
    const debouncedSaveUIState = this.debouncedSaveUIState;
    const eventListener: EventListener = (v: string, e) => {
      if (e.type === "DELETE") {
        // todo how do we rebase ???
        this.onTransformationRemove(v);
      } else if (e.type === "POSITION_UPDATED") {
        // none
      } else if (e.type === "SELECTION") {
        if (v !== this.getSelection()) {
          this.setSelection(v);
        }
      } else if (e.type === "REMOVE_SELECTION") {
        this.setSelection(null);
      } else if (e.type === "CREATE_MODEL") {
        //console.log("CREATE_MODEL");
      }
    };
    const transformNode = new CanvasNodeGenerator(
      transformations,
      datasets,
      this.canEdit(),
      eventListener,
      this.state.output,
      this.props.initialState?.configCanvasNodeSelection && this.isFirstPaint
        ? this.props.initialState?.configCanvasNodeSelection
        : this.getSelection()
    );

    // we keep the position and zoom of model between rebuilds
    const zoom = this.engine.getModel()?.getZoomLevel();
    const offsetX = this.engine.getModel()?.getOffsetX();
    const offsetY = this.engine.getModel()?.getOffsetY();
    const model = transformNode.buildModel();
    if (
      typeof zoom === "number" &&
      typeof offsetX === "number" &&
      typeof offsetY === "number"
    ) {
      model.setOffsetX(offsetX ?? 0);
      model.setOffsetY(offsetY ?? 0);
      model.setZoomLevel(zoom ?? 1);
    }

    this.engine.getStateMachine().pushState(new WlyDefaultState());

    this.engine.setModel(model);

    model.setLocked(true);

    // here we listen for the following events on the model
    // zoomUpdated
    // offsetUpdated
    model.registerListener({
      eventDidFire(event) {
        // we should get the
        debouncedSaveUIState({
          configCanvasOffsetX: model.getOffsetX(),
          configCanvasOffsetY: model.getOffsetY(),
          configCanvasZoom: model.getZoomLevel(),
        });
      },
    });

    //

    this.canvasNodeGenerator = transformNode;

    if (this.engine.getCanvas()) {
      // here it is called everytime we have a node change
      if (this.isFirstPaint && this.props.initialState) {
        // we apply the saved zoom and offset on first render
        if (typeof this.props.initialState.configCanvasZoom === "number") {
          this.engine
            .getModel()
            .setZoomLevel(this.props.initialState?.configCanvasZoom ?? 1);
        }
        if (
          typeof this.props.initialState?.configCanvasOffsetX === "number" ||
          typeof this.props.initialState?.configCanvasOffsetY === "number"
        ) {
          this.engine
            .getModel()
            .setOffset(
              this.props.initialState?.configCanvasOffsetX ?? 0,
              this.props.initialState?.configCanvasOffsetY ?? 0
            );
        }
      } else if (this.isFirstPaint) {
        // if nothing is saved, we just zoom to fit nodes
        this.engine.zoomToFitNodes({ margin: 5, maxZoom: 1 });
      }
      this.isFirstPaint = false;
    }

    const head = this.getSelection();
    if (head && initialLoad) {
      const query = this.canvasNodeGenerator.getQueryAtNode(head);
      const node = this.canvasNodeGenerator.getNodeIndex(head);

      if (query && query.length) {
        this.fetchSpreadsheetData(this.props.currentWarehouse?.id, query);
        if (node && HAS_FORM.includes(node.step.operation.type)) {
          this.fetchPreviousStepSchema(this.props.currentWarehouse?.id, query);
        }
      }
    }

    return transformNode;
  };

  onAddNode = (id: string) => {
    let newTransformation: Transformation;

    const currentDataset = this.props.datasets.find((d) => d.id === id);
    if (!currentDataset) {
      throw new Error("this dataset does not exist");
    }

    const stepId = generateUniqueId();

    if (currentDataset.type === "FLOW" || currentDataset.type === "SQL") {
      newTransformation = {
        var: stepId,
        operation: {
          type: "Table.FromWhalyDataset",
          args: {
            datasetId: id,
          },
        },
        domain: "datasetResolver",
      };
    } else if (
      currentDataset.type === "WAREHOUSE" ||
      currentDataset.type === "TABLE"
    ) {
      newTransformation = {
        var: stepId,
        operation: {
          type: "Table.FromWarehouseTable",
          args: {
            databaseName: currentDataset.warehouseDatabaseId,
            schemaName: currentDataset.warehouseSchemaId,
            tableName: currentDataset.warehouseTableId,
          },
        },
        domain: "datasetResolver",
      };
    } else {
      throw new Error("not supported");
    }

    this.updateQuery([...this.state.transformations, newTransformation]);
  };

  getEditorMaxHeight = () => {
    const maxHeight =
      document
        .getElementsByClassName("flow-dataset-wrapper")[0]
        .getBoundingClientRect().height - 121;
    return maxHeight;
  };

  onEditorResize = (event, { element, size, handle }) => {
    this.setState({ maxHeight: this.getEditorMaxHeight() });
    this.setState({ height: size.height });
  };

  onTransformationRemove = (removedKey: string) => {
    const toRemoveKey: string[] =
      this.canvasNodeGenerator.getKeysToRemove(removedKey);
    const parentKey = this.canvasNodeGenerator.getParentKey(removedKey);
    const parentTransformation = parentKey
      ? this.state.transformations.find((t) => t.var === parentKey)
      : undefined;
    const newChildKey =
      this.canvasNodeGenerator.getNodeIndex(removedKey).children[0];
    const modifiedParentTransformation =
      parentTransformation && newChildKey
        ? modifyChildKey(parentTransformation, removedKey, newChildKey)
        : parentTransformation;
    const updatedStack = this.state.transformations.flatMap((t) => {
      if (toRemoveKey.includes(t.var)) {
        return [];
      }
      if (t.var === parentKey) {
        return [modifiedParentTransformation];
      }
      return [t];
    });
    this.updateQuery(updatedStack);
  };

  onTransformationAdd = (
    afterKey: string,
    newKey: string,
    newTransformations: Transformation[]
  ) => {
    const parentKey = this.canvasNodeGenerator.getParentKey(afterKey);
    const parentTransformation = parentKey
      ? this.state.transformations.find((t) => t.var === parentKey)
      : undefined;
    const modifiedParentTransformation = parentTransformation
      ? modifyChildKey(parentTransformation, afterKey, newKey)
      : parentTransformation;
    const childIndex = this.state.transformations.findIndex(
      (t) => t.var === afterKey
    );

    const updatedTransformations = this.state.transformations.flatMap(
      (t, i) => {
        if (i === childIndex) {
          return [t, ...newTransformations];
        }
        if (parentKey && t.var === parentKey) {
          return [modifiedParentTransformation];
        }
        return t;
      }
    );
    this.updateQuery(updatedTransformations, newKey, () => {
      this.setSelection(newKey);
    });
  };

  public canEdit = () => {
    return !!(this.props.onModifyTransformation && this.props.onUpdateDataset);
  };

  public renderFlowSizer = () => {
    const MAX_FLOW_HEIGHT = this.state.maxHeight;
    const FLOW_HEIGHT = this.state.height;
    let action: "expand" | "shrink" = "expand";
    let icon = <ArrowsAltOutlined />;
    if (FLOW_HEIGHT !== MIN_FLOW_HEIGHT) {
      action = "shrink";
    }
    if (action === "expand") {
      icon = <ShrinkOutlined />;
    }
    const onClick = () => {
      if (action === "expand") {
        this.setState({ height: MAX_FLOW_HEIGHT });
      } else if (action === "shrink") {
        this.setState({ height: MIN_FLOW_HEIGHT });
      }
    };
    return <Button type="text" onClick={onClick} shape="circle" icon={icon} />;
  };

  public render() {
    const {
      onUpdateDataset,
      onModifyTransformation,
      setStaleQuery,
      isStale,
      setStaleHead,
      antUtils,
      datasets,
      dataset,
      query,
      onRefreshDatasets,
      onRefreshExplorations,
      currentWarehouse,
    } = this.props;

    const head = this.getSelection();
    const currentQuery = this.canvasNodeGenerator.getQueryAtNode(head);
    const nodeIndex = this.canvasNodeGenerator.getNodeIndex(head);

    const isNodeSelected = !!nodeIndex;
    const isNodeOutput = nodeIndex && this.state.output === head;

    const wrapOutputInTooltip = (n: React.ReactNode) => {
      if (!isNodeSelected) {
        return (
          <Tooltip title="Please select a step to mark it as output">
            {n}
          </Tooltip>
        );
      } else if (isNodeOutput) {
        return (
          <Tooltip title="This step is already marked as the output.">
            {n}
          </Tooltip>
        );
      }
      return n;
    };

    const wrapSqlInTooltip = (n: React.ReactNode) => {
      if (!isNodeSelected) {
        return (
          <Tooltip title="Please select a step to view its SQL">{n}</Tooltip>
        );
      }
      return n;
    };

    return (
      <div className="flow-dataset-wrapper">
        {this.canEdit() && (
          <Toolbar.Toolbar style={{ borderTop: "none" }}>
            <Toolbar.ViewName>Flow Query</Toolbar.ViewName>
            <Toolbar.Separator />
            <Toolbar.Item
              onClick={() => this.setState({ showMigrationModal: true })}
              color="pink"
            >
              <DoubleRightOutlined /> Migrate
            </Toolbar.Item>
            <ModelMigrationModal
              visible={this.state.showMigrationModal}
              currentWarehouse={currentWarehouse}
              onClose={() => this.setState({ showMigrationModal: false })}
              datasets={datasets}
              dataset={dataset}
              onMigrationDone={async (
                nextDatasetId: string,
                datasetIds: string[],
                explorationIds: string[]
              ) => {
                await Promise.all([
                  onRefreshDatasets
                    ? onRefreshDatasets(datasetIds)
                    : Promise.resolve(),
                  onRefreshExplorations
                    ? onRefreshExplorations(explorationIds)
                    : Promise.resolve(),
                ]);
                const activeObject = workbenchUIStore.getActiveObject();
                workbenchUIStore.removeActiveObject(activeObject);
                workbenchUIStore.pushActiveObject({
                  type: "dataset",
                  value: nextDatasetId,
                });
              }}
            />
            <div style={{ flex: 1 }} />
            <Toolbar.Item
              onClick={() => {
                if (
                  !this.state.output ||
                  !this.canvasNodeGenerator.getNodeIndex(this.state.output)
                ) {
                  antUtils.modal.error({
                    title: "Your model doesn't output anything",
                    content:
                      "Please select a step as being the output of your model in order to save it.",
                  });
                } else {
                  this.setState({
                    preSaveModalVisible: true,
                    primaryKeysModalVisible: false,
                    primaryKeysModalSaving: false,
                  });
                }
              }}
              color="violet"
            >
              <Badge offset={[5, -5]} dot={isStale}>
                Save
              </Badge>
            </Toolbar.Item>
            <div style={{ width: 12, display: "block" }} />
          </Toolbar.Toolbar>
        )}
        <ResizableBox
          axis="y"
          height={this.state.height}
          width={Infinity}
          minConstraints={[Infinity, MIN_FLOW_HEIGHT]}
          maxConstraints={[Infinity, this.state.maxHeight]}
          handle={<div className="divider-horizontal"></div>}
          onResize={this.onEditorResize}
        >
          <CanvasWrapper
            onAddDataset={this.canEdit() ? this.onAddNode : undefined}
            engine={this.engine}
            height={this.state.height}
            onZoomIn={() => {
              const zoomLevel = this.engine.getModel().getZoomLevel();
              this.engine.getModel().setZoomLevel(zoomLevel + 12);
            }}
            onZoomOut={() => {
              const zoomLevel = this.engine.getModel().getZoomLevel();
              if (zoomLevel > 0) {
                this.engine.getModel().setZoomLevel(zoomLevel - 12);
              }
            }}
            onFit={() => {
              this.engine.zoomToFitNodes({
                maxZoom: 1,
                margin: 5,
              });
            }}
            additionalButtons={
              this.canEdit() ? (
                <Space>
                  {wrapSqlInTooltip(
                    <Button
                      disabled={!nodeIndex}
                      onClick={() => this.setState({ showSQLModal: true })}
                      size="small"
                    >
                      <CodeOutlined /> View step SQL
                    </Button>
                  )}
                  {wrapOutputInTooltip(
                    <Button
                      onClick={
                        this.canEdit()
                          ? (v) => {
                              setStaleHead(head);
                              this.setState(
                                {
                                  output: head,
                                },
                                () => {
                                  this.computeModel(
                                    this.state.transformations,
                                    this.props.datasets
                                  );
                                }
                              );
                            }
                          : undefined
                      }
                      disabled={!nodeIndex || isNodeOutput}
                      size="small"
                    >
                      <ArrowRightOutlined /> Set as output
                    </Button>
                  )}
                  <>
                    <FlowToSQL
                      onCancel={() => this.setState({ showSQLModal: false })}
                      visible={this.state.showSQLModal}
                      transformations={currentQuery}
                    />
                  </>
                </Space>
              ) : undefined
            }
          />
        </ResizableBox>
        <div style={{ position: "relative", height: "100%" }}>
          <div style={{ position: "absolute", top: "4px", right: "8px" }}>
            {this.renderFlowSizer()}
          </div>
          {head &&
          currentQuery &&
          currentQuery.length &&
          nodeIndex &&
          this.state.dataStore &&
          this.state.dataStore[head] ? (
            <OperationPanel
              currentQuery={currentQuery}
              nodeIndex={nodeIndex}
              currentWarehouse={currentWarehouse}
              validateQuery={this.validateQuery}
              updateQuery={this.canEdit() ? this.updateQuery : undefined}
              datasets={this.props.datasets}
              prevSchema={this.state.operationSchema[head]}
              height={this.state.height}
              completeQuery={this.state.transformations}
              canvasNodeGenerator={this.canvasNodeGenerator}
              head={head}
              dataStore={this.state.dataStore[head]?.store}
              operationSchema={this.state.operationSchema[head]}
              output={this.state.output}
              onNodeHighlight={(nodeId, hovering) => {
                workbenchUIStore.setIsShowcased(hovering ? nodeId : null);
              }}
              onAddTransformation={
                this.canEdit()
                  ? (t) =>
                      new Promise((r, reject) => {
                        this.onTransformationAdd(head, t.var, [t]);
                      })
                  : undefined
              }
              setOutput={
                this.canEdit()
                  ? (v) => {
                      setStaleHead(v);
                      this.setState(
                        {
                          output: v,
                        },
                        () => {
                          this.computeModel(
                            this.state.transformations,
                            this.props.datasets
                          );
                        }
                      );
                    }
                  : undefined
              }
            />
          ) : (
            <div style={{ height: "100%" }}>
              {this.state.transformations?.length ? (
                <Feednack>Please select a step to edit it</Feednack>
              ) : (
                <Feednack>
                  Drag and drop a dataset or a model in the canvas ⤴️
                </Feednack>
              )}
            </div>
          )}
        </div>
        {this.canEdit() && (
          <>
            <PreSaveModelModal
              datasetId={this.props.workbenchUIStore.getActiveObject().value}
              visible={this.state.preSaveModalVisible}
              currentWarehouse={currentWarehouse}
              getNewSchema={async () => {
                const transformations = this.canvasNodeGenerator.getQueryAtNode(
                  this.state.output
                );
                const prevId = transformations[transformations.length - 1].var;
                transformations.push({
                  var: generateUniqueId(),
                  operation: {
                    type: "Table.Schema",
                    args: {
                      table: prevId,
                    },
                  },
                  domain: "viewResolver",
                });
                const schema = await computeTransformations(
                  this.props.currentWarehouse?.id,
                  {
                    schema: transformations,
                  }
                );
                return schema.data.schema as SchemaResult;
              }}
              onCancel={() => this.setState({ preSaveModalVisible: false })}
              onContinue={() =>
                this.setState({
                  preSaveModalVisible: false,
                  primaryKeysModalVisible: true,
                  primaryKeysModalSaving: false,
                })
              }
            />
            <PrimaryKeysModal
              visible={this.state.primaryKeysModalVisible}
              saving={this.state.primaryKeysModalSaving}
              getInitialKeys={() => {
                return this.canvasNodeGenerator.getCurrentTabItem(
                  this.state.output
                ).primaryKey;
              }}
              onCancel={() => {
                this.setState({
                  primaryKeysModalVisible: false,
                  primaryKeysModalSaving: false,
                });
              }}
              onSave={async (v) => {
                try {
                  this.setState({
                    primaryKeysModalSaving: true,
                  });
                  const tabItem = this.canvasNodeGenerator.getCurrentTabItem(
                    this.state.output
                  );

                  if (!tabItem) {
                    antUtils.message.error("Could not save model");
                    return;
                  }
                  const q = this.state.transformations.map((t) => {
                    return {
                      ...t,
                      domain: "datasetResolver" as TransformationDomain,
                    };
                  });
                  await onModifyTransformation(
                    query,
                    q,
                    undefined,
                    undefined,
                    true
                  );
                  await onUpdateDataset({
                    primaryKey: v.keys.join(","),
                    head: this.state.output,
                  });

                  if (setStaleQuery) {
                    setStaleQuery(JSON.stringify(q));
                  }
                } catch (error) {
                  console.warn(error);
                  antUtils.message.error("Error while saving");
                } finally {
                  this.setState({
                    primaryKeysModalVisible: false,
                    primaryKeysModalSaving: false,
                  });
                }
              }}
              getSchema={async () => {
                const transformations = this.canvasNodeGenerator.getQueryAtNode(
                  this.state.output
                );
                const prevId = transformations[transformations.length - 1].var;
                transformations.push({
                  var: generateUniqueId(),
                  operation: {
                    type: "Table.Schema",
                    args: {
                      table: prevId,
                    },
                  },
                  domain: "viewResolver",
                });
                const schema = await computeTransformations(
                  this.props.currentWarehouse?.id,
                  {
                    schema: transformations,
                  }
                );
                return schema.data.schema as SchemaResult;
              }}
            />
          </>
        )}
      </div>
    );
  }
}

export default compose<Props, IFlowConfigurationProps>(
  WithOrg,
  withRouter,
  withAntUtils
)(inject("workbenchUIStore")(observer(FlowConfiguration)));
