export const retryPromise = async <T>(
  fn: () => Promise<T> | T,
  { retries, retryIntervalMs }: { retries: number; retryIntervalMs: number }
): Promise<T> => {
  try {
    return await fn();
  } catch (error) {
    if (retries <= 0) {
      throw error;
    }
    if (
      (error instanceof Error || error instanceof TypeError) &&
      !error.message?.toLowerCase?.().includes?.("network request failed")
    ) {
      throw error;
    }
    await sleep(retryIntervalMs);
    return retryPromise(fn, { retries: retries - 1, retryIntervalMs });
  }
};

const sleep = (ms = 0) => new Promise((resolve) => setTimeout(resolve, ms));
