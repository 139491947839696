import Icon from "@ant-design/icons";
import type { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";

export const WlyCheckIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon
    component={() => (
      <svg
        width="1em"
        height="1em"
        viewBox="0 0 91 80"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M86 2.19989C81.8 -0.800109 76 0.299891 73 4.49989L37.1 55.7999L15.9 34.5999C12.3 30.9999 6.3 30.9999 2.7 34.5999C-0.9 38.1999 -0.9 44.1999 2.7 47.7999L31.7 76.7999C33.5 78.5999 35.9 79.4999 38.3 79.4999C38.3 79.4999 38.3 79.4999 38.4 79.4999C38.4 79.4999 39.1 79.4999 39.3 79.4999C41.9 79.2999 44.4 77.8999 46.1 75.5999L88.4 15.1999C91.3 10.8999 90.2 5.09989 86 2.19989Z"
          fill="currentColor"
        />
      </svg>
    )}
    {...props}
  />
);
