import type { AgGridReact } from "ag-grid-react";
import _ from "lodash";
import type {
  ISetTableQueryAction,
  TableQuery,
} from "../../../../../../../../components/ag-grid/object-table/domain";
import type {
  IObject,
  IObjectView,
} from "../../../../../../../../interfaces/object";
import { BORDER_COLOR } from "../../../../../layout/domain";
import type { AvailableColumn } from "../../../../domain";
import { ObjectQueryBuilderHeader } from "./ObjectQueryBuilderHeader";
import { ObjectQueryBuilderSection } from "./ObjectQueryBuilderSection";

export interface IObjectQueryBuilderProps {
  availableColumns: AvailableColumn[];
  referencedAvailableColumns: AvailableColumn[];
  object: IObject;
  tableQuery: TableQuery;
  activeView?: IObjectView;
  isStale: boolean;
  setTableQuery: React.Dispatch<ISetTableQueryAction>;
  gridRef: React.RefObject<AgGridReact>;
}

export function ObjectQueryBuilder(props: IObjectQueryBuilderProps) {
  const {
    object,
    availableColumns,
    referencedAvailableColumns,
    tableQuery,
    setTableQuery,
    activeView,
    isStale,
    gridRef,
  } = props;
  return (
    <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
      <div
        style={{ flex: `0 40px`, borderBottom: `1px solid ${BORDER_COLOR}` }}
      >
        <ObjectQueryBuilderHeader
          object={object}
          availableColumns={availableColumns}
          tableQuery={tableQuery}
          setTableQuery={setTableQuery}
          activeView={activeView}
          isStale={isStale}
          gridRef={gridRef}
        />
      </div>
      <div>
        {_.orderBy(object.queryBuilderSections, ["order"]).map((qbs) => {
          return (
            <ObjectQueryBuilderSection
              object={object}
              availableColumns={availableColumns}
              referencedAvailableColumns={referencedAvailableColumns}
              section={qbs}
              tableQuery={tableQuery}
              key={qbs.id}
              setTableQuery={setTableQuery}
            />
          );
        })}
      </div>
    </div>
  );
}
