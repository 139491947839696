import * as Icons from "@ant-design/icons/lib/icons";
import { Popover, Space, Typography } from "antd";
import { useState } from "react";
import { WlyDynamicIcon } from "../dynamic-renderer/WlyDynamicIconRenderer";
import { IconHolder } from "../holder/IconHolder";

const colorPresets = [
  {
    label: "wlyColors",
    colors: [
      "#F06A6A",
      "#EC8D71",
      "#F1BD6C",
      "#F8DF72",
      "#B3DF97",
      "#83C9A9",
      "#4ECBC4",
      "#9EE7E3",
      "#4473D2",
      "#A69FF3",
      "#CD95EA",
      "#F9AAEF",
      "#F26FB2",
      "#6D6E6F",
    ],
  },
];

const wlyIcons = [
  "AccountBookOutlined",
  "AimOutlined",
  "AlertOutlined",
  "ApiOutlined",
  "AudioOutlined",
  "BankOutlined",
  "BarcodeOutlined",
  "BellOutlined",
  "BookOutlined",
  "BugOutlined",
  "BulbOutlined",
  "CalculatorOutlined",
  "CalendarOutlined",
  "CameraOutlined",
  "CarOutlined",
  "CoffeeOutlined",
  "CompassOutlined",
  "ContainerOutlined",
  "ControlOutlined",
  "CreditCardOutlined",
  "CrownOutlined",
  "CustomerServiceOutlined",
  "DashboardOutlined",
  "DatabaseOutlined",
  "DollarOutlined",
  "ExperimentOutlined",
  "EyeOutlined",
  "FilterOutlined",
  "FireOutlined",
  "FlagOutlined",
  "GiftOutlined",
  "GlobalOutlined",
  "GoldOutlined",
  "HeartOutlined",
  "HighlightOutlined",
  "HomeOutlined",
  "HourglassOutlined",
  "LayoutOutlined",
  "LikeOutlined",
  "LockOutlined",
  "MailOutlined",
  "MedicineBoxOutlined",
  "MessageOutlined",
  "MoonOutlined",
  "NotificationOutlined",
  "PaperClipOutlined",
  "PhoneOutlined",
  "QrcodeOutlined",
  "RobotOutlined",
  "RocketOutlined",
  "SaveOutlined",
  "ScheduleOutlined",
  "ScissorOutlined",
  "SearchOutlined",
  "SendOutlined",
  "ShopOutlined",
  "ShoppingCartOutlined",
  "ShoppingOutlined",
  "SignatureOutlined",
  "SkinOutlined",
  "SoundOutlined",
  "StarOutlined",
  "SunOutlined",
  "TagOutlined",
  "TeamOutlined",
  "ToolOutlined",
  "TrophyOutlined",
  "TruckOutlined",
  "UnlockOutlined",
  "UserOutlined",
  "VideoCameraOutlined",
  "WalletOutlined",
];

type ColorIconPickerValue = {
  color: string;
  icon: string;
};

interface IColorIconPickerProps {
  value?: ColorIconPickerValue;
  onChange?: (v: ColorIconPickerValue) => void;
}

const ColorIconPicker = (props: IColorIconPickerProps) => {
  const { onChange } = props;
  const [popoverVisible, setPopoverVisible] = useState<boolean>(false);
  const value = {
    color: props.value?.color || "#F1BD6C",
    icon: props.value?.icon || "LayoutOutlined",
  };
  const size = 20;
  return (
    <div>
      <Popover
        trigger={["click"]}
        overlayStyle={{ width: 300, maxHeight: 400 }}
        open={popoverVisible}
        onOpenChange={setPopoverVisible}
        destroyTooltipOnHide
        content={
          <Space
            direction="vertical"
            style={{ display: "flex", width: "100%" }}
            size={20}
          >
            <Typography.Text strong>Edit icon appearance</Typography.Text>
            <Space wrap>
              {colorPresets
                .flatMap((c) => {
                  return c.colors;
                })
                .map((c, index) => {
                  return (
                    <div
                      key={index}
                      onClick={() => onChange?.({ color: c, icon: value.icon })}
                      style={{
                        width: size,
                        height: size,
                        backgroundColor: c,
                        borderRadius: 4,
                        outline:
                          value?.color === c ? "1px solid black" : undefined,
                        outlineOffset: 1,
                        border: `1px solid rgba(0, 0, 0, 0.06)`,
                        cursor: "pointer",
                      }}
                    />
                  );
                })}
            </Space>
            <Space wrap>
              {wlyIcons.map((name) => {
                const Icon = Icons[name];
                return (
                  <div
                    key={name}
                    onClick={() =>
                      onChange?.({ color: value.color, icon: name })
                    }
                    style={{
                      cursor: "pointer",
                      outline:
                        value.icon === name ? "1px solid blue" : undefined,
                      outlineOffset: 1,
                      width: size,
                      height: size,
                      borderRadius: 4,
                    }}
                  >
                    <Icon style={{ fontSize: size, color: "gray" }} />
                  </div>
                );
              })}
            </Space>
          </Space>
        }
      >
        <div
          style={{
            width: 32,
            height: 32,
            border: "1px solid #D9D9D9",
            borderRadius: 6,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            cursor: "pointer",
          }}
        >
          <IconHolder
            color="#F9F8F8"
            background={value?.color || "#F1BD6C"}
            icon={<WlyDynamicIcon name={value.icon} />}
            size={24}
          />
        </div>
      </Popover>
    </div>
  );
};

export default ColorIconPicker;
