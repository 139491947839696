import { Empty } from "antd";
import Aligner from "../../../../../components/layout/aligner/Aligner";
import Feednack from "../../../../../components/layout/feedback/feedback";

interface INoAccessProps {}

type Props = INoAccessProps;

export default function NoAccess(props: Props) {
  return (
    <Aligner>
      <Feednack>
        <Empty
          description={
            "You don't seem to have access to this dataset, or it has been deleted."
          }
        ></Empty>
      </Feednack>
    </Aligner>
  );
}
