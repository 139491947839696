import { FormOutlined } from "@ant-design/icons";
import { Col, Row, Tag } from "antd";
import type { ColumnProps } from "antd/lib/table";
import type { RouteComponentProps } from "react-router";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import { compose } from "../../../../components/compose/WlyCompose";
import Aligner from "../../../../components/layout/aligner/Aligner";
import CardTable from "../../../../components/table/CardTable";
import type { IActionMeta } from "../../../../interfaces/actions";
import type { ISchedule } from "../../../../interfaces/schedule";
import { routeDescriptor } from "../../../../routes/routes";
import { ExecutionStatusTag } from "../../../job-execution/status/ExecutionStatusTag";
import type { InjectedOrgProps } from "../../../orgs/WithOrg";
import WithOrg from "../../../orgs/WithOrg";
import {
  getActionDestination,
  getActionDestinationUrl,
  getActionImg,
  renderSchedule,
  shouldDisplayDestinationFormat,
} from "./helper";
import PushExecutions from "./PushExecution";

interface IActionHistoryTableProps {
  schedules: ISchedule[];
  actionMetas: IActionMeta[];
}

type Props = IActionHistoryTableProps &
  InjectedOrgProps &
  RouteComponentProps<{}>;

function ActionHistoryTable(props: Props) {
  const { actionMetas, org, schedules } = props;

  const expandedRowRender = (schedule) => {
    return <PushExecutions schedule={schedule} />;
  };

  const generateColumns = (): ColumnProps<ISchedule>[] => [
    {
      title: "Name",
      key: "name",
      render: (_, schedule) => (
        <div>
          <img
            src={getActionImg(schedule, actionMetas)}
            alt={schedule.name}
            style={{ width: 20 }}
          />{" "}
          {schedule.name}
        </div>
      ),
    },
    {
      title: "Recurrence",
      key: "recurrence",
      render: (_, schedule) => (
        <div>
          {renderSchedule(schedule, true, () => (
            <span>-</span>
          ))}
        </div>
      ),
    },
    {
      title: "Report",
      key: "report",
      render: (_, schedule) => (
        <Link
          to={routeDescriptor.report.renderRoute({
            organizationSlug: org.slug,
            reportSlug: schedule.workflow.report.slug,
          })}
          target="_blank"
        >
          {schedule.workflow.report.name} <FormOutlined />
        </Link>
      ),
    },
    {
      title: "Destination",
      key: "destination",
      render: (_, schedule) =>
        schedule.workflow.type === "email_content_delivery" ? (
          getActionDestination(schedule)
            .split(",")
            .map((email, i) => <Tag key={i}>{email}</Tag>)
        ) : getActionDestinationUrl(schedule) ? (
          <Link
            to={{ pathname: getActionDestinationUrl(schedule) }}
            target="_blank"
          >
            {getActionDestination(schedule)} <FormOutlined />
          </Link>
        ) : (
          getActionDestination(schedule)
        ),
    },
    {
      title: "Format",
      key: "format",
      render: (_, schedule) => (
        <div>
          {shouldDisplayDestinationFormat(schedule) ? (
            <div>{schedule.workflow.contentFormat}</div>
          ) : (
            <div>-</div>
          )}
        </div>
      ),
    },
    {
      title: "Last run",
      key: "lastRunStatus",
      render: (_, schedule) => (
        <ExecutionStatusTag execution={schedule.lastJobExecution} />
      ),
    },
  ];

  return (
    <Aligner>
      <Row justify="center" className="access-management">
        <Col xs={24} sm={24} md={20} lg={20} xl={20}>
          <CardTable<ISchedule>
            cardTitle={<div className="title">Push history</div>}
            actionButtons={undefined}
            rowKey="id"
            dataSource={schedules}
            columns={generateColumns()}
            pagination={{
              size: "small",
              style: { display: "none" },
              defaultPageSize: 1000,
            }}
            expandable={{
              expandedRowRender,
            }}
          />
        </Col>
      </Row>
    </Aligner>
  );
}

export default compose<Props, IActionHistoryTableProps>(
  WithOrg,
  withRouter
)(ActionHistoryTable);
