import { Flex, Space, Typography } from "antd";
import * as React from "react";
import { WlyCard } from "../../../../components/cards/WlyCard";
import { LabelRenderer } from "../../../../components/catalog/label/LabelRenderer";
import { compose } from "../../../../components/compose/WlyCompose";
import { SourceImageRenderer } from "../../../../components/sources/SourceImageRenderer";
import type { ISource } from "../../../../interfaces/sources";
import type { InjectedOrgProps } from "../../../orgs/WithOrg";
import WithOrg from "../../../orgs/WithOrg";
import SourceStatusRenderer from "../status/SourceStatusRenderer";

interface ISourceOverviewProps {
  source: ISource;
}

type Props = ISourceOverviewProps & InjectedOrgProps;

class SourceOverview extends React.Component<Props> {
  public render() {
    const { source } = this.props;
    return (
      <WlyCard>
        <Space direction="vertical" style={{ width: "100%" }} size={24}>
          <div>
            <Flex gap={12} align="center" justify="space-between">
              <Flex gap={24} align="center">
                <div>
                  <SourceImageRenderer
                    img={source.sourceMeta.publicInfo.logo}
                    alt={source.sourceMeta.publicInfo.name}
                    size={40}
                  />
                </div>
                <div>
                  <Space direction="vertical" size={0}>
                    <Typography.Text strong style={{ fontSize: 20 }}>
                      {source.name}
                    </Typography.Text>
                    <Typography.Text type="secondary">
                      {source.sourceMeta.publicInfo.name}
                    </Typography.Text>
                  </Space>
                </div>
              </Flex>
              <div>
                <LabelRenderer label={source?.sourceMeta?.publicInfo?.label} />
              </div>
            </Flex>
          </div>
          {source.sourceMeta.publicInfo.description}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={source.sourceMeta.publicInfo.website}
          >
            {source.sourceMeta.publicInfo.name} Website
          </a>
          <div>
            <div className="title">Your source status:</div>
            <SourceStatusRenderer source={source} />
          </div>
        </Space>
      </WlyCard>
    );
  }
}

export default compose<Props, ISourceOverviewProps>(WithOrg)(SourceOverview);
