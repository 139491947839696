import { Form, Input, Typography } from "antd";
import * as React from "react";
import EmojiPicker from "../../form/emoji-picker/EmojiPicker";
import FolderIconPicker from "../../icons/folder-icon-picker/FolderIconPicker";
import WlyModal from "../../modal/WlyModal";

interface IFolderEditFormProps {
  parentId?: string;
  initialData?: FolderEditInitialData;
  onEdit: (v: FolderEditInitialData) => Promise<any>;
  onCreate: (v: FolderEditInitialData) => Promise<any>;
  visible: boolean;
  onCancel: () => any;
}

export interface FolderEditInitialData {
  id?: string;
  name: string;
  image: string;
  color: string;
  parentId?: string;
}

export function FolderEditForm(props: IFolderEditFormProps) {
  const { onCreate, visible, onCancel, parentId, initialData, onEdit } = props;

  const [confirmLoading, setConfirmLoading] = React.useState(false);

  const [form] = Form.useForm();

  React.useEffect(() => {
    if (visible === false) {
      form.resetFields();
      form.setFieldsValue({ name: "", image: "random", color: "#2599D4" });
    }
  }, [visible, form]);

  React.useEffect(() => {
    form.setFieldsValue(initialData);
  }, [initialData]);

  const renderInnerModal = () => {
    return (
      <Form
        initialValues={
          !initialData
            ? {
                name: "",
                image: "random",
                color: "",
                parentId: props.parentId,
              }
            : initialData
        }
        layout="vertical"
        form={form}
      >
        <div>
          <Typography.Text>
            <Typography.Text type="danger">*</Typography.Text> Folder name
          </Typography.Text>
          <div style={{ display: "flex", paddingTop: 12 }}>
            <div style={{ width: 40 }}>
              <Form.Item name={["image"]} noStyle hidden>
                <EmojiPicker />
              </Form.Item>
              <Form.Item
                name={["color"]}
                noStyle
                rules={[
                  {
                    required: false,
                  },
                ]}
              >
                <FolderIconPicker />
              </Form.Item>
            </div>
            <div style={{ flex: 1 }}>
              <Form.Item
                name={["name"]}
                noStyle
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input
                  style={{
                    width: "100%",
                  }}
                  placeholder="Your folder name"
                />
              </Form.Item>
            </div>
          </div>
        </div>
      </Form>
    );
  };

  return (
    <WlyModal
      open={!!visible}
      title={initialData ? "Update a folder" : "Create a folder"}
      okText={initialData ? "Update" : "Create"}
      cancelText="Cancel"
      onClose={() => {
        form.resetFields();
        onCancel();
      }}
      confirmLoading={confirmLoading}
      onOk={() => {
        setConfirmLoading(true);
        return form
          .validateFields()
          .then((values) => {
            const v = values as FolderEditInitialData;
            const image = { image: v.image ?? ":file_folder:" };
            if (initialData && initialData.id) {
              return onEdit({ ...image, ...v, id: initialData.id }).then(() =>
                setConfirmLoading(false)
              );
            } else {
              return onCreate({
                ...image,
                ...v,
                parentId: parentId,
                ...image,
              }).then(() => setConfirmLoading(false));
            }
          })
          .then(() => {
            onCancel();
            return form.resetFields();
          })
          .catch((info) => {
            setConfirmLoading(false);
            console.error("Validate Failed:", info);
          });
      }}
    >
      {renderInnerModal()}
    </WlyModal>
  );
}
