import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";
import { Button, Flex, Input, Space, type InputRef } from "antd";
import * as React from "react";
import { SwitchIcons } from "../../../../../../../../components/form/elements/switch-icons/SwitchIcons";
import {
  DraggableMeasureItem,
  type DraggableMeasure,
} from "../../../../../../../../components/measures/measure-item/DraggableMeasureItem";
import { DroppableMeasureZone } from "../../../../../../../../components/measures/measure-picker/DroppableMeasureZone";
import { type AvailableColumn } from "../../../../domain";
import { type PropertySelectorValue } from "./domain";

export interface IUsedPropertyVisibilitySelectorProps {
  value?: Array<PropertySelectorValue>;
  onChange?: (value: Array<PropertySelectorValue>) => any;
  availableColumns?: Array<AvailableColumn>;
}

export function UsedPropertyVisibilitySelector(
  props: IUsedPropertyVisibilitySelectorProps
) {
  const { onChange, availableColumns } = props;

  const value = props.value ? props.value : [];
  const [search, setSearch] = React.useState<string>("");
  const inputRef = React.useRef<InputRef>(null);

  const onMeasureDrop = (key: string, position: number) => {
    // we prevent adding new measures if we've already reached max
    if (!value.find((v) => v.key === key)) {
      return;
    }
    if (typeof position !== "number" || !onChange) return;
    const oldValue = [...value].find((v) => v.key === key);
    const newValue = [...value.filter((v) => v.key !== key)];
    let dropIndex = newValue.length;
    if (position != null) dropIndex = position;
    newValue.splice(dropIndex, 0, oldValue!);
    onChange(newValue);
  };

  React.useLayoutEffect(() => {
    setTimeout(() => {
      inputRef.current?.focus();
    }, 200);
  }, []);

  return (
    <div style={{ marginRight: 12, marginLeft: 12 }}>
      <Flex style={{ marginBottom: 8 }}>
        <Input.Search
          placeholder="Find a field"
          allowClear
          onChange={(v) => setSearch(v.target.value)}
          ref={inputRef}
        />
      </Flex>
      <div style={{ maxHeight: 340, overflowY: "auto" }}>
        <Space direction="vertical" style={{ width: "100%", display: "flex" }}>
          <DroppableMeasureZone
            zone={"sort"}
            accept={["metric", "property"]}
            availableMeasures={(availableColumns ?? []).map((c) => ({
              key: c.data.key,
              label: c.data.label,
            }))}
            onAddMeasure={(i) => undefined}
          >
            {value
              .map((v) => ({
                ...v,
                column: (availableColumns ?? []).find(
                  (c) => c.data.key === v.key
                ),
              }))
              .filter((c) => c.visible === true)
              .filter((v) => {
                if (search) {
                  return v.column?.data?.label
                    .toLowerCase?.()
                    .includes?.(search.toLowerCase());
                }
                return true;
              })
              .map((valueColumn, index) => {
                const onDrop = (item: DraggableMeasure, position: number) => {
                  onMeasureDrop(item.id, position);
                };
                const isVisible = !![...value].find(
                  (v) => v.key === valueColumn?.column?.data?.key && v.visible
                );
                return (
                  <Flex
                    gap={8}
                    key={index}
                    align="center"
                    style={!isVisible ? { opacity: 0.5 } : {}}
                  >
                    <SwitchIcons
                      icons={{
                        true: <EyeOutlined />,
                        false: <EyeInvisibleOutlined />,
                      }}
                      shape="default"
                      size="small"
                      value={isVisible}
                      onChange={(checked) => {
                        if (checked) {
                          onChange?.(
                            [...value].map((v) => {
                              if (v.key === valueColumn?.column?.data?.key) {
                                return {
                                  key: v.key,
                                  visible: true,
                                };
                              }
                              return v;
                            })
                          );
                        } else {
                          onChange?.(
                            [...value].map((v) => {
                              if (v.key === valueColumn?.column?.data?.key) {
                                return {
                                  key: v.key,
                                  visible: false,
                                };
                              }
                              return v;
                            })
                          );
                        }
                      }}
                    />
                    <div style={{ flex: 1, marginRight: 6 }}>
                      <DraggableMeasureItem
                        id={valueColumn?.column?.data.key}
                        zone="sort"
                        index={index}
                        key={valueColumn?.column?.data.key}
                        type={valueColumn?.column?.type}
                        name={valueColumn?.column?.data?.label}
                        onDrop={onDrop}
                        accept={search ? [] : ["metric", "property"]}
                        draggable={search ? false : true}
                      />
                    </div>
                  </Flex>
                );
              })}
          </DroppableMeasureZone>
        </Space>
      </div>
      {!search && (
        <Flex gap={8} style={{ marginTop: 8 }}>
          <div style={{ flex: 1 }}>
            <Button
              block
              size="small"
              onClick={() =>
                onChange?.(
                  [...value].map((c) => ({ key: c.key, visible: false }))
                )
              }
            >
              Hide all
            </Button>
          </div>
          <div style={{ flex: 1 }}>
            <Button
              block
              size="small"
              onClick={() =>
                onChange?.(
                  [...value].map((c) => ({ key: c.key, visible: true }))
                )
              }
            >
              Show all
            </Button>
          </div>
        </Flex>
      )}
    </div>
  );
}
