import * as Sentry from "@sentry/react";
import React, { useEffect, useState } from "react";
import type { InjectedAntUtilsProps } from "../components/ant-utils/withAntUtils";
import { withAntUtils } from "../components/ant-utils/withAntUtils";
import { compose } from "../components/compose/WlyCompose";
import usePrevious from "../components/hooks/usePrevious";
import { activeTracking } from "../services/AnalyticsService";

const TIMEOUT_TIME_MS = 3000;
const onlinePollingInterval = 10000;
const PING_RESOURCE = "/version.txt";

const network = "network";
const version = "version";

interface IOnlineStatusProviderProps {
  children?: React.ReactNode;
}

type Props = IOnlineStatusProviderProps & InjectedAntUtilsProps;

const timeout = (time: number, promise: Promise<string>): Promise<string> => {
  return new Promise(function (resolve, reject) {
    setTimeout(() => {
      reject(new Error("Request timed out."));
    }, time);
    promise.then(resolve, reject);
  });
};

const checkOnlineStatus = async () => {
  // If the browser has no network connection return offline
  if (!navigator.onLine) return navigator.onLine;
  return true;
};

const bootSentry = (currentVersion: string) => {
  if (activeTracking) {
    Sentry.init({
      dsn: "https://7432521cfb89431da3fb5c4303c38c40@o1143842.ingest.sentry.io/6204897",
      integrations: [new Sentry.BrowserTracing(), Sentry.replayIntegration()],
      tracesSampleRate: 1.0,
      environment: "production",
      normalizeDepth: 0,
      release: currentVersion,
    });
  }
};

const OnlineStatusProvider: React.FC = (props: Props) => {
  const { antUtils, children } = props;
  const [onlineStatus, setOnlineStatus] = useState<boolean>(true);
  const [currentVersion, setCurrentVersion] = useState<string | undefined>();
  const previousVersion = usePrevious(currentVersion);

  const getDistantVersion = async () => {
    const controller = new AbortController();
    const { signal } = controller;

    try {
      const r = await timeout(
        TIMEOUT_TIME_MS,
        fetch(PING_RESOURCE, {
          method: "GET",
          signal,
        }).then((r) => {
          return r.text();
        })
      );
      if (r && r !== currentVersion) {
        setCurrentVersion(r);
      }
      return r;
    } catch (error) {
      // Error Log
      console.error(error);

      // This can be because of request timed out
      // so we abort the request for any case
      controller.abort();
    }
    return undefined;
  };

  const checkStatus = async () => {
    const online = await checkOnlineStatus();
    setOnlineStatus(online);
  };

  useEffect(() => {
    const fetchCurrentRelease = async () => {
      const currV = await fetch(PING_RESOURCE, {
        method: "GET",
      }).then((r) => {
        return r.text();
      });
      // this is the first version load we are booting sentry to map it with the current release
      bootSentry(currV);
    };
    fetchCurrentRelease();
  }, []);

  useEffect(() => {
    window.addEventListener("offline", () => {
      setOnlineStatus(false);
    });
    window.addEventListener("online", () => {
      setOnlineStatus(true);
    });

    // Add polling incase of slow connection
    const id = setInterval(() => {
      checkStatus();
      getDistantVersion();
    }, onlinePollingInterval);

    return () => {
      window.removeEventListener("offline", () => {
        setOnlineStatus(false);
      });
      window.removeEventListener("online", () => {
        setOnlineStatus(true);
      });
      clearInterval(id);
    };
  }, []);

  useEffect(() => {
    if (
      previousVersion &&
      currentVersion &&
      previousVersion !== currentVersion
    ) {
      antUtils.message.info({
        content: (
          <span>
            A new version of the app is available please{" "}
            <a onClick={() => window.location.reload()}>reload your page</a>
          </span>
        ),
        duration: 0,
        key: version,
      });
    }
  }, [currentVersion]);

  useEffect(() => {
    if (!onlineStatus) {
      antUtils.message.error({
        content: "You don't seem connected to the internet...",
        key: network,
        duration: 0,
      });
    } else {
      antUtils.message.destroy(network);
    }
  }, [onlineStatus]);

  return <>{children}</>;
};

export default compose<Props, IOnlineStatusProviderProps>(withAntUtils)(
  OnlineStatusProvider
);
