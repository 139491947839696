import ApiService from "./ApiService";

export const getJSONFile = <T>(fileUri: string): Promise<T> => {
  return ApiService.getRequest(
    `/v1/data?uri=${fileUri}`,
    {},
    {},
    { authenticated: true, fileServiceUrl: true }
  );
};

export const getBlobFile = (fileUri: string): Promise<Blob> => {
  return ApiService.getRequest(
    `/v1/data?uri=${fileUri}`,
    {},
    {},
    { authenticated: true, fileServiceUrl: true, asBlob: true }
  );
};

export const updateFile = (fileUri: string, body: FormData): Promise<any> => {
  return ApiService.putRequest(
    `/v1/data?uri=${fileUri}`,
    body,
    {},
    {},
    { authenticated: true, fileServiceUrl: true }
  );
};
