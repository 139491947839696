import { Alert, Button, Col, Row } from "antd";
import type { FormikProps } from "formik";
import { Form, Formik } from "formik";
import * as React from "react";
import type { RouteComponentProps } from "react-router";
import { withRouter } from "react-router";
import Card from "../../../../../components/cards/Card";
import { CatalogFormElement } from "../../../../../components/catalog/form/CatalogFormElement";
import { compose } from "../../../../../components/compose/WlyCompose";
import ErrorFeedback from "../../../../../components/error/Error";
import Aligner from "../../../../../components/layout/aligner/Aligner";
import Loading from "../../../../../components/layout/feedback/loading";
import GitbookMarkdownRenderer from "../../../../../components/markdown/gitbook";
import { handleGQLErrors } from "../../../../../helpers/gqlHelpers";
import type { AsyncData } from "../../../../../helpers/typescriptHelpers";
import type { ICatalogValue } from "../../../../../interfaces/catalog";
import { getReadme, githubEndpoint } from "../../../../../services/DocsService";
import type { BreadcrumbStoreProps } from "../../../../../store/breadCrumbStore";
import type { InjectedOrgProps } from "../../../../orgs/WithOrg";
import WithOrg from "../../../../orgs/WithOrg";
import "./ItemConnectionStep2.scss";

const F = Form as any;

export type InputValidationPromise = (
  itemId: string,
  v: { [key: string]: any }
) => Promise<any>;

interface IItemConnectionStep2Props {
  itemValues: ICatalogValue[];
  itemId: string;
  inputValidationPromise?: InputValidationPromise;
  step2CreationPromise: Step2CreationPromise;
  catalogValueTransformer?: (i: ICatalogValue[]) => Array<ICatalogValue>;
  submitButtonLabel?: string;
  documentationPagePath?: string;
}

interface IItemConnectionStep2State {
  errorMessage?: string;
  doc: AsyncData<string>;
}

type Props = IItemConnectionStep2Props &
  BreadcrumbStoreProps &
  InjectedOrgProps &
  RouteComponentProps<{ organizationSlug: string; sourceSlug: string }>;

interface InitialValues {
  [key: string]: string | number | Date;
}

export interface ItemValueUpdateInput {
  id: string;
  data: {
    value: string | Date | number;
  };
}

export type Step2CreationPromise = (
  itemId: string,
  itemValueUpdateInput: ItemValueUpdateInput[],
  rawValues: InitialValues
) => Promise<void>;

class ItemConnectionStep2 extends React.Component<
  Props,
  IItemConnectionStep2State
> {
  constructor(props: Props) {
    super(props);
    this.state = {
      errorMessage: undefined,
      doc: { status: "initial" },
    };
  }

  public componentDidMount() {
    const { itemValues, step2CreationPromise, itemId, documentationPagePath } =
      this.props;
    if (!itemValues.length) {
      // save the source with discovery status
      step2CreationPromise(itemId, [], {}).catch(handleGQLErrors());
    }

    if (!!documentationPagePath) {
      this.fetchDoc(documentationPagePath);
    }
  }

  public componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<IItemConnectionStep2State>,
    snapshot?: any
  ): void {
    const { documentationPagePath } = this.props;
    const { documentationPagePath: prevDocumentationpagePath } = prevProps;
    if (
      documentationPagePath &&
      documentationPagePath !== prevDocumentationpagePath
    ) {
      this.fetchDoc(documentationPagePath);
    }
  }

  fetchDoc = async (path: string) => {
    this.setState({ doc: { status: "loading" } });
    try {
      const mdRaw = await getReadme(path);
      const cleanMd = mdRaw
        .replaceAll(`{% hint style="info" %}`, `::: info`)
        .replaceAll(`{% endhint %}`, ":::");
      this.setState({ doc: { status: "success", data: cleanMd } });
    } catch (err) {
      console.error(err);
      this.setState({ doc: { status: "error", error: err } });
    }
  };

  public onSubmit = async (v: InitialValues) => {
    const { itemId, itemValues, step2CreationPromise, inputValidationPromise } =
      this.props;

    const itemValueUpdateInput: ItemValueUpdateInput[] = Object.keys(v).map(
      (key: keyof InitialValues) => {
        const value = v[key];
        const foundValue = itemValues.find((sv) => sv.option.key === key)!;
        return {
          id: foundValue.id,
          data: {
            value: value,
          },
        };
      }
    );

    let promise = (itemId: string, v: any): Promise<any> => Promise.resolve();

    if (inputValidationPromise) {
      promise = inputValidationPromise;
    }

    try {
      await promise(itemId, v);
    } catch (err) {
      if (err instanceof Error) {
        this.setState({
          errorMessage: err.message,
        });
        return;
      }
    }

    try {
      await step2CreationPromise(itemId, itemValueUpdateInput, v);
    } catch (err) {
      handleGQLErrors()(err);
    }
  };

  renderDoc = (): JSX.Element => {
    const { doc } = this.state;

    if (doc.status === "initial" || doc.status === "loading") {
      return <Loading />;
    }
    if (doc.status === "error") {
      return <ErrorFeedback>{doc.error.message}</ErrorFeedback>;
    }
    return (
      <GitbookMarkdownRenderer baseUrl={githubEndpoint}>
        {doc.data}
      </GitbookMarkdownRenderer>
    );
  };

  public render() {
    const {
      itemValues,
      submitButtonLabel,
      documentationPagePath,
      catalogValueTransformer,
    } = this.props;
    const { errorMessage } = this.state;
    const initialValues = itemValues.reduce((acc, v) => {
      return {
        ...acc,
        [v.option.key]: v.value,
      };
    }, {} as InitialValues);

    const it = catalogValueTransformer
      ? [...catalogValueTransformer([...itemValues])]
      : [...itemValues];
    const genForm = (p: FormikProps<InitialValues>) => (
      <F className="form-container">
        {errorMessage ? (
          <Alert
            type="error"
            message="Connection tests failed"
            description={errorMessage}
            style={{ marginBottom: 16, whiteSpace: "pre-line" }}
          />
        ) : undefined}
        {it
          .sort((a, b) => (a.option.order || 0) - (b.option.order || 0))
          .map((sv) => {
            return (
              <div key={sv.option.key}>
                <CatalogFormElement
                  catalogItemOption={sv.option}
                  submitting={p.isSubmitting}
                />
              </div>
            );
          })}
        {itemValues.length === 0 ? <Loading /> : undefined}
        {itemValues.length > 0 && !documentationPagePath ? (
          <Row>
            <Col span={24} style={{ textAlign: "right" }}>
              <Button type="primary" htmlType="submit">
                {submitButtonLabel ? submitButtonLabel : "Continue"}
              </Button>
            </Col>
          </Row>
        ) : undefined}
      </F>
    );

    const formik = (
      <Formik initialValues={initialValues} onSubmit={this.onSubmit}>
        {(p: FormikProps<InitialValues>) => {
          {
            return genForm(p);
          }
        }}
      </Formik>
    );

    const formikWithDiv = (
      <Formik initialValues={initialValues} onSubmit={this.onSubmit}>
        {(p: FormikProps<InitialValues>) => {
          {
            return (
              <>
                <div className="item-connection-doc-col-content padding form">
                  {genForm(p)}
                </div>
                <div className="item-connection-doc-col-footer right">
                  <Button
                    type="primary"
                    onClick={p.submitForm}
                    htmlType="submit"
                    loading={p.isSubmitting}
                  >
                    {submitButtonLabel ? submitButtonLabel : "Continue"}
                  </Button>
                </div>
              </>
            );
          }
        }}
      </Formik>
    );

    if (!documentationPagePath) {
      return (
        <Aligner className="whly-content item-connection">
          <Row justify="center">
            <Col xs={24} sm={20} md={18} lg={14} xl={12}>
              <Card className="card">{formik}</Card>
            </Col>
          </Row>
        </Aligner>
      );
    }

    return (
      <div className="item-connection-doc-wrapper">
        <div className="item-connection-doc-col white">{formikWithDiv}</div>
        <div className="item-connection-doc-col">
          <div className="item-connection-doc-col-header">Setup Guide</div>
          <div className="item-connection-doc-col-content padding">
            {this.renderDoc()}
          </div>
        </div>
      </div>
    );
  }
}

export default compose<Props, IItemConnectionStep2Props>(
  WithOrg,
  withRouter
)(ItemConnectionStep2);
