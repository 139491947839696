import { Collapse, ConfigProvider, Typography } from "antd";

export interface IToggleProps {
  title: string;
}

export function Toggle(props: React.PropsWithChildren<IToggleProps>) {
  const { children, title } = props;
  return (
    <Typography.Paragraph>
      <ConfigProvider
        theme={{
          components: {
            Collapse: {
              headerPadding: 0,
              contentPadding: `16px 0`,
            },
          },
        }}
      >
        <Collapse
          ghost
          items={[
            {
              key: "1",
              label: <Typography.Text strong>{title}</Typography.Text>,
              children: children,
            },
          ]}
        />
      </ConfigProvider>
    </Typography.Paragraph>
  );
}
