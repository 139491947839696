import * as React from "react";
import { compose } from "../../components/compose/WlyCompose";
import type { IUser, IUserRoleType } from "../../interfaces/user";
import { rolesImportance } from "../../interfaces/user";
import type { InjectedOrgProps } from "../orgs/WithOrg";
import WithOrg from "../orgs/WithOrg";

interface IHasRoleAccessProps {
  accessLevel: IUserRoleType;
  children?: React.ReactNode;
}

type Props = IHasRoleAccessProps & InjectedOrgProps;

export const hasRoleAccessBoolean = (
  accessLevel: IUserRoleType,
  user: IUser,
  orgId: string
) => {
  if (user.isAdmin) {
    return true;
  }
  const currentRole = user.roles.find((r) => r.org.id === orgId);
  if (
    currentRole &&
    rolesImportance[accessLevel] <= rolesImportance[currentRole.role]
  ) {
    return true;
  }
  return false;
};

class HasRoleAccess extends React.Component<Props> {
  public render() {
    const { org, user, accessLevel, children } = this.props;
    const currentRole = user.roles.find((r) => r.org.id === org.id);
    if (!currentRole) {
      return <div>No access</div>;
    }
    if (hasRoleAccessBoolean(accessLevel, user, org.id)) {
      return children ? children : null;
    } else {
      return null;
    }
  }
}

export default compose<Props, IHasRoleAccessProps>(WithOrg)(HasRoleAccess);
