import { Col, Row, Tag, Tooltip } from "antd";
import type { ColumnProps } from "antd/lib/table";
import moment from "moment";
import type { RouteComponentProps } from "react-router";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import { compose } from "../../../components/compose/WlyCompose";
import { WlyFolderIcon } from "../../../components/icons/custom-icons/WlyFolderIcon";
import Aligner from "../../../components/layout/aligner/Aligner";
import { FromNow } from "../../../components/moment/FromNow";
import CardTable from "../../../components/table/CardTable";
import UserAvatar from "../../../components/user/avatar/UserAvatar";
import type { IReport, ReportType } from "../../../interfaces/reports";
import { routeDescriptor } from "../../../routes/routes";
import type { InjectedOrgProps } from "../../orgs/WithOrg";
import WithOrg from "../../orgs/WithOrg";

interface ISharedReportsTableProps {
  reports: IReport[];
}

type Props = ISharedReportsTableProps &
  InjectedOrgProps &
  RouteComponentProps<{}>;

function SharedReportsTable(props: Props) {
  const { reports } = props;

  const renderReportType = (type: ReportType) => {
    switch (type) {
      case "DASHBOARD":
        return <Tag color="magenta">Dashboard</Tag>;
      case "QUESTION":
        return <Tag color="blue">Question</Tag>;
    }
  };

  const renderFolder = (name: string, image: string, color?: string) => {
    return (
      <div style={{ display: "flex" }}>
        <div style={{ height: 24, width: 24, marginRight: 6 }}>
          <WlyFolderIcon style={{ fontSize: 20, color: color ?? "#2599D4" }} />
        </div>
        <div>{name}</div>
      </div>
    );
  };

  const generateColumns = (): ColumnProps<IReport>[] => [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (v, s) => {
        return (
          <Link
            target="_blank"
            to={routeDescriptor.report.renderRoute({
              ...props.match.params,
              reportSlug: s.slug,
            })}
          >
            {v}
          </Link>
        );
      },
    },
    {
      title: "Type",
      key: "type",
      render: (_, report) => <div>{renderReportType(report.type)}</div>,
    },
    {
      title: "Folder",
      dataIndex: "folder",
      key: "folder",
      render: (v, s) => {
        return renderFolder(s.folder.name, s.folder.image, s.folder.color);
      },
    },
    {
      title: "Last updated",
      dataIndex: "updatedBy",
      key: "updatedBy",
      render: (v, s) => {
        const user = s.updatedBy ? s.updatedBy : s.createdBy;
        const date = s.updatedAt ? s.updatedAt : s.createdAt;
        const title = user ? `${user.firstName} ${user.lastName}` : null;
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <Tooltip title={title} mouseEnterDelay={0.5} placement="top">
              <UserAvatar user={user} size={24} />
            </Tooltip>
            <div style={{ paddingLeft: 6 }}>
              <FromNow date={moment(date)} />
            </div>
          </div>
        );
      },
    },
  ];

  return (
    <Aligner>
      <Row justify="center" className="access-management">
        <Col xs={24} sm={24} md={20} lg={20} xl={20}>
          <CardTable<IReport>
            actionButtons
            cardTitle={<div className="title">Shared reports</div>}
            rowKey="id"
            dataSource={reports}
            columns={generateColumns()}
            pagination={{
              size: "small",
              style: { display: "none" },
              defaultPageSize: 1000,
            }}
          />
        </Col>
      </Row>
    </Aligner>
  );
}

export default compose<Props, ISharedReportsTableProps>(
  WithOrg,
  withRouter
)(SharedReportsTable);
