import { Slider, Switch } from "antd";
import _ from "lodash";
import React from "react";
import { compose } from "../../../../components/compose/WlyCompose";
import Feednack from "../../../../components/layout/feedback/feedback";
import Loading from "../../../../components/layout/feedback/loading";
import type { AsyncData } from "../../../../helpers/typescriptHelpers";
import type { JobSyncPeriod } from "../../../../interfaces/jobExecutions";
import type { ISource } from "../../../../interfaces/sources";
import { SLAPeriodEnum } from "../../../../interfaces/sources";
import GraphQLService from "../../../../services/graphql/GraphQLService";
import type { InjectedOrgProps } from "../../../orgs/WithOrg";
import WithOrg from "../../../orgs/WithOrg";

interface SourceSyncSettingsProps {
  source: ISource;
}

type Props = SourceSyncSettingsProps & InjectedOrgProps;

const SourceSyncSettingsForm: React.FunctionComponent<Props> = (props) => {
  const { source } = props;
  const sourceId = source.id;
  const [sourceSyncInfo, setSourceSyncInfo] = React.useState<
    AsyncData<{
      syncPeriod: string;
      isEnabled: boolean;
      shouldCheckSla: boolean;
      slaPeriod: SLAPeriodEnum;
    }>
  >({ status: "initial" });
  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (isSubmitting === true) return;
    async function getSourceSyncInfo() {
      setSourceSyncInfo({ status: "loading" });
      try {
        const result = await GraphQLService<{
          Source: {
            syncPeriod: JobSyncPeriod;
            isEnabled: boolean;
            shouldCheckSla: boolean;
            slaPeriod: SLAPeriodEnum;
          };
        }>(
          `
            query GetSourceSyncSettings($sourceId: ID!) {
              Source(where: { id: $sourceId }) {
                syncPeriod
                isEnabled
                shouldCheckSla
                slaPeriod
              }
            }
          `,
          {
            sourceId,
          }
        );
        setSourceSyncInfo({
          status: "success",
          data: {
            syncPeriod: result.Source.syncPeriod,
            isEnabled: result.Source.isEnabled,
            shouldCheckSla: result.Source.shouldCheckSla,
            slaPeriod: result.Source.slaPeriod,
          },
        });
      } catch (err: any) {
        setSourceSyncInfo({ status: "error", error: err });
      }
    }
    getSourceSyncInfo();
  }, [sourceId, isSubmitting]);

  async function updateSourceSyncSettings(data: {
    syncPeriod?: JobSyncPeriod;
    isEnabled?: boolean;
    shouldCheckSla?: boolean;
    slaPeriod?: SLAPeriodEnum;
  }) {
    await GraphQLService(
      `
        mutation UpdateSourceSyncSettings($sourceId: ID!, $data: SourceUpdateInput!) {
            updateSource(id: $sourceId, data: $data) {
              id
            }
          }
      `,
      {
        sourceId,
        data: data,
      }
    );
    setIsSubmitting(false);
  }

  if (
    sourceSyncInfo.status === "loading" ||
    sourceSyncInfo.status === "initial"
  ) {
    return (
      <div style={{ padding: 24 }}>
        <Loading />
      </div>
    );
  }

  if (sourceSyncInfo.status === "error") {
    return <Feednack>{sourceSyncInfo.error?.message}</Feednack>;
  }

  if (sourceSyncInfo.status === "success") {
    const syncMarks = {
      0: "5min",
      1: "15min",
      2: "30min",
      3: "1h",
      4: "2h",
      5: "3h",
      6: "6h",
      7: "8h",
      8: "12h",
      9: "24h",
    };

    const syncMarksToPeriod: { [mark: number]: JobSyncPeriod } = {
      0: "five_minutes",
      1: "fifteen_minutes",
      2: "thirty_minutes",
      3: "one_hour",
      4: "two_hours",
      5: "three_hours",
      6: "six_hours",
      7: "eight_hours",
      8: "twelve_hours",
      9: "twenty_four_hours",
    };

    const syncPeriodToMarks: { [period: string]: string } =
      _.invert(syncMarksToPeriod);
    const syncPeriod = sourceSyncInfo.data.syncPeriod;
    const defaultSyncValueStr = syncPeriodToMarks[syncPeriod];
    const defaultSyncValue = parseInt(defaultSyncValueStr);

    const getCompatibleSLAs = (): {
      minSlaMark: number;
      slaMarks: { [mark: number]: string };
      slaMarksToPeriod: { [mark: number]: SLAPeriodEnum };
    } => {
      const getMinMark = (): number => {
        switch (syncPeriod) {
          case "twenty_four_hours":
            return 8;
          case "twelve_hours":
            return 7;
          case "eight_hours":
            return 6;
          case "six_hours":
            return 5;
          case "three_hours":
            return 4;
          case "two_hours":
            return 3;
          case "one_hour":
            return 2;
          default:
            return 1;
        }
      };
      const minSlaMark = getMinMark();
      const slaMarks = {
        1: "1h",
        2: "2h",
        3: "3h",
        4: "6h",
        5: "8h",
        6: "12h",
        7: "24h",
        8: "48h",
      };
      Object.keys(slaMarks).forEach((mark) => {
        if (parseInt(mark) < minSlaMark) {
          delete slaMarks[mark];
        }
      });
      return {
        minSlaMark,
        slaMarks,
        slaMarksToPeriod: {
          1: SLAPeriodEnum.one_hour,
          2: SLAPeriodEnum.two_hours,
          3: SLAPeriodEnum.three_hours,
          4: SLAPeriodEnum.six_hours,
          5: SLAPeriodEnum.eight_hours,
          6: SLAPeriodEnum.twelve_hours,
          7: SLAPeriodEnum.twenty_four_hours,
          8: SLAPeriodEnum.forty_eight_hours,
        },
      };
    };

    const { minSlaMark, slaMarks, slaMarksToPeriod } = getCompatibleSLAs();
    const slaPeriodToMarks: { [period: string]: string } =
      _.invert(slaMarksToPeriod);
    const slaPeriod = sourceSyncInfo.data.slaPeriod;
    const defaultSLAValueStr = slaPeriodToMarks[slaPeriod];
    const defaultSLAValue = parseInt(defaultSLAValueStr);

    const checkIfSlaCompatibleWithSyncPeriod = (
      slaPeriod: SLAPeriodEnum,
      syncPeriod: JobSyncPeriod
    ): { isCompatible: boolean; minCompatibleSla?: SLAPeriodEnum } => {
      if (syncPeriod === "twenty_four_hours") {
        if ([SLAPeriodEnum.forty_eight_hours].includes(slaPeriod)) {
          return {
            isCompatible: true,
          };
        } else {
          return {
            isCompatible: false,
            minCompatibleSla: SLAPeriodEnum.forty_eight_hours,
          };
        }
      } else if (syncPeriod === "twelve_hours") {
        if (
          [
            SLAPeriodEnum.forty_eight_hours,
            SLAPeriodEnum.twenty_four_hours,
          ].includes(slaPeriod)
        ) {
          return {
            isCompatible: true,
          };
        } else {
          return {
            isCompatible: false,
            minCompatibleSla: SLAPeriodEnum.twenty_four_hours,
          };
        }
      } else if (syncPeriod === "eight_hours") {
        if (
          [
            SLAPeriodEnum.forty_eight_hours,
            SLAPeriodEnum.twenty_four_hours,
            SLAPeriodEnum.twelve_hours,
          ].includes(slaPeriod)
        ) {
          return {
            isCompatible: true,
          };
        } else {
          return {
            isCompatible: false,
            minCompatibleSla: SLAPeriodEnum.twelve_hours,
          };
        }
      } else if (syncPeriod === "six_hours") {
        if (
          [
            SLAPeriodEnum.forty_eight_hours,
            SLAPeriodEnum.twenty_four_hours,
            SLAPeriodEnum.twelve_hours,
            SLAPeriodEnum.eight_hours,
          ].includes(slaPeriod)
        ) {
          return {
            isCompatible: true,
          };
        } else {
          return {
            isCompatible: false,
            minCompatibleSla: SLAPeriodEnum.eight_hours,
          };
        }
      } else if (syncPeriod === "three_hours") {
        if (
          [
            SLAPeriodEnum.forty_eight_hours,
            SLAPeriodEnum.twenty_four_hours,
            SLAPeriodEnum.twelve_hours,
            SLAPeriodEnum.eight_hours,
            SLAPeriodEnum.six_hours,
          ].includes(slaPeriod)
        ) {
          return {
            isCompatible: true,
          };
        } else {
          return {
            isCompatible: false,
            minCompatibleSla: SLAPeriodEnum.six_hours,
          };
        }
      } else if (syncPeriod === "two_hours") {
        if (
          [
            SLAPeriodEnum.forty_eight_hours,
            SLAPeriodEnum.twenty_four_hours,
            SLAPeriodEnum.twelve_hours,
            SLAPeriodEnum.eight_hours,
            SLAPeriodEnum.six_hours,
            SLAPeriodEnum.three_hours,
          ].includes(slaPeriod)
        ) {
          return {
            isCompatible: true,
          };
        } else {
          return {
            isCompatible: false,
            minCompatibleSla: SLAPeriodEnum.three_hours,
          };
        }
      } else if (syncPeriod === "one_hour") {
        if (
          [
            SLAPeriodEnum.forty_eight_hours,
            SLAPeriodEnum.twenty_four_hours,
            SLAPeriodEnum.twelve_hours,
            SLAPeriodEnum.eight_hours,
            SLAPeriodEnum.six_hours,
            SLAPeriodEnum.three_hours,
            SLAPeriodEnum.two_hours,
          ].includes(slaPeriod)
        ) {
          return {
            isCompatible: true,
          };
        } else {
          return {
            isCompatible: false,
            minCompatibleSla: SLAPeriodEnum.two_hours,
          };
        }
      }
      return { isCompatible: true };
    };
    return (
      <div>
        <p className="sync-settings-description">{`Automatically replicate data from this source to your warehouse:`}</p>
        <div style={{ marginBottom: 20 }}>
          <Switch
            checked={sourceSyncInfo.data.isEnabled}
            disabled={isSubmitting}
            onChange={(v) => {
              setIsSubmitting(true);
              updateSourceSyncSettings({
                isEnabled: v,
              });
            }}
          />
        </div>
        {sourceSyncInfo.data.isEnabled && (
          <>
            <p className="sync-settings-description">{`How often will Whaly attempt to replicate data:`}</p>
            <div style={{ padding: "6px 10px" }}>
              <Slider
                min={0}
                max={9}
                styles={{ track: { backgroundColor: "#3A5C83" } }}
                marks={syncMarks}
                step={null}
                disabled={isSubmitting}
                tooltip={{ open: false }}
                defaultValue={defaultSyncValue}
                dots={true}
                onAfterChange={(newValue) => {
                  setIsSubmitting(true);
                  const newSyncPeriod = syncMarksToPeriod[newValue];
                  const { isCompatible, minCompatibleSla } =
                    checkIfSlaCompatibleWithSyncPeriod(
                      slaPeriod,
                      newSyncPeriod
                    );
                  if (!isCompatible) {
                    updateSourceSyncSettings({
                      syncPeriod: newSyncPeriod,
                      slaPeriod: minCompatibleSla,
                    });
                    return;
                  }
                  updateSourceSyncSettings({
                    syncPeriod: newSyncPeriod,
                  });
                }}
              />
            </div>
          </>
        )}
        {sourceSyncInfo.data.isEnabled && (
          <>
            <p className="sync-settings-sla-description">{`Monitor freshness SLA on this source:`}</p>
            <div style={{ marginBottom: 20 }}>
              <Switch
                checked={sourceSyncInfo.data.shouldCheckSla}
                disabled={isSubmitting}
                onChange={(v) => {
                  setIsSubmitting(true);
                  updateSourceSyncSettings({
                    shouldCheckSla: v,
                  });
                }}
              />
            </div>
          </>
        )}
        {sourceSyncInfo.data.isEnabled && sourceSyncInfo.data.shouldCheckSla && (
          <>
            <p className="sync-settings-sla-description">{`What is the minimum freshness period (SLA) that data extracted using this connector should have?:`}</p>
            <div style={{ padding: "6px 10px" }}>
              <Slider
                min={minSlaMark}
                max={8}
                styles={{ track: { backgroundColor: "#3A5C83" } }}
                marks={slaMarks}
                step={null}
                disabled={isSubmitting}
                tooltip={{ open: false }}
                defaultValue={defaultSLAValue}
                dots={true}
                onAfterChange={(newValue) => {
                  setIsSubmitting(true);
                  const newSlaPeriod = slaMarksToPeriod[newValue];
                  updateSourceSyncSettings({
                    slaPeriod: newSlaPeriod,
                  });
                }}
              />
            </div>
          </>
        )}
      </div>
    );
  }
};

export default compose<Props, SourceSyncSettingsProps>(WithOrg)(
  SourceSyncSettingsForm
);
