export const RELATED_MODELS_QUERY = `
query getRelatedModels($orgId: ID!, $datasetId: ID!) {
  allModels: allDatasets(
    where: {
      org: { id: $orgId }
      isModel: true
      deleted_not: true
      dependsOn_some: { child: {id: $datasetId}, deleted_not: true}
    }
  ) {
    id
    name
  }
}
`;
