import { Dropdown } from "antd";
import cuid from "cuid";
import * as React from "react";
import type { InjectedOrgProps } from "../../containers/orgs/WithOrg";
import WithOrg from "../../containers/orgs/WithOrg";
import { compose } from "../compose/WlyCompose";
import PaletteRenderer from "./PaletteRenderer";
import PaletteSelector from "./selector/PaletteSelector";
import type { IPalette, IPaletteSelection } from "./utils/paletteData";
import { getSelectedPalette } from "./utils/paletteData";

interface IPalettePickerProps {
  value?: IPalette | IPaletteSelection;
  onChange?: (v: IPalette) => void;
  defaultValue?: IPalette;
  size?: "small" | "default";
  restrict?: "discrete" | "continue";
  editModeOnly?: boolean;
}

type Props = IPalettePickerProps & InjectedOrgProps;

class PalettePicker extends React.Component<Props> {
  id: string = cuid();

  public render() {
    const { value, defaultValue, onChange, size, restrict, editModeOnly, org } =
      this.props;

    // const value = _.cloneDeep(propsValue);
    // const defaultValue = _.cloneDeep(defaultValueProps);

    const getCurrentValue = (): IPalette | IPaletteSelection => {
      if (value) {
        return value;
      } else if (defaultValue) {
        return defaultValue;
      }
      return {
        type: "PALETTE_SELECTION",
        palette_type: restrict ? restrict : "discrete",
        collection_name: "default",
        index: 0,
      };
    };

    const currentValue = getCurrentValue();

    const currentPalette: IPalette = getSelectedPalette(org, currentValue);
    // const currentPalette = _.cloneDeep(currentPaletteT);

    const onPaletteSelect = (s: IPalette) => {
      if (onChange) {
        onChange(s);
      }
    };

    return (
      <Dropdown
        getPopupContainer={() => {
          if (document.getElementById(this.id)) {
            return document.getElementById(this.id)!;
          }
          return document.body;
        }}
        dropdownRender={() => {
          return (
            <div
              className="ant-dropdown-menu"
              style={{
                padding: 0,
              }}
            >
              <PaletteSelector
                restrict={restrict}
                onChange={onPaletteSelect}
                value={currentValue}
                editModeOnly={editModeOnly}
              />
            </div>
          );
        }}
        overlayStyle={{
          maxWidth: 214,
          minWidth: 214,
        }}
        trigger={["click"]}
        placement="bottomLeft"
        arrow={true}
      >
        <div id={this.id}>
          <PaletteRenderer size={size} {...currentPalette} />
        </div>
      </Dropdown>
    );
  }
}

export default compose<Props, IPalettePickerProps>(WithOrg)(PalettePicker);
