import { EditOutlined, SettingOutlined } from "@ant-design/icons";
import type { PopoverProps } from "antd";
import { Button, Popover } from "antd";
import React, { useState } from "react";
import "./ChartOptionLine.scss";

export interface IChartOptionLineItem {
  flex: number | string;
  additionalStyle?: React.CSSProperties;
  content?: React.ReactNode;
  type?: "settings" | "edit";
  popover?: PopoverProps;
}

interface IChartOptionLineProps {
  className?: string;
  style?: React.CSSProperties;
  items: IChartOptionLineItem[];
}

export default function ChartOptionLine(props: IChartOptionLineProps) {
  const [popover, setpopover] = useState<{
    popover: PopoverProps;
    isOpen: boolean;
  }>(null);

  return (
    <div className={props.className} style={props.style}>
      <Popover
        trigger={popover?.isOpen ? ["click"] : []}
        open={popover?.isOpen}
        destroyTooltipOnHide
        onOpenChange={(v) => {
          setpopover({
            ...popover,
            isOpen: v,
          });
        }}
        overlayStyle={
          popover?.popover?.overlayStyle ?? {
            width: 280,
            minWidth: 280,
            maxWidth: 280,
          }
        }
        placement={popover?.popover?.placement ?? "left"}
        {...popover?.popover}
      >
        <div className="chart-option-line">
          {props.items
            .filter((i) => i)
            .map((item, i) => {
              let content = item.content;
              if (item.type === "settings") {
                content = (
                  <Button
                    shape="circle"
                    type="text"
                    icon={<SettingOutlined />}
                  />
                );
              }
              if (item.type === "edit") {
                content = (
                  <Button shape="circle" type="text" icon={<EditOutlined />} />
                );
              }
              return (
                <div
                  className={item.popover && "chart-option-line-pointer"}
                  key={i}
                  style={{
                    ...(item.additionalStyle ? item.additionalStyle : {}),
                    flex: item.flex,
                  }}
                  onClick={() => {
                    if (!!item.popover && !popover?.isOpen) {
                      setpopover({
                        popover: item.popover,
                        isOpen: true,
                      });
                    }
                  }}
                >
                  {content}
                  {/* This is a workaround to make sure form items displayed in popover are not destroyed */}
                  <div style={{ display: "none" }}>
                    {item.popover?.content as React.ReactNode}
                  </div>
                </div>
              );
            })}
        </div>
      </Popover>
    </div>
  );
}
