import { syntaxHighlighting } from "@codemirror/language";
import { EditorView } from "@codemirror/view";
import ReactCodeMirror from "@uiw/react-codemirror";
import * as React from "react";
import type { FunctionDefinition } from "../../../parser/functions";
import "./FormulaEditor.scss";
import { FormulaEditorAutocomplete } from "./FormulaEditorAutocomplete";
import { formulaHighlightStyle } from "./FormulaEditorHighlight";
import { FormulaEditorLanguage } from "./FormulaEditorLanguage";
import { FormulaEditorPreventLineBreak } from "./FormulaEditorPreventLineBreak";
import { FormulaEditorTheme } from "./FormulaEditorTheme";
import { FormulaEditorTooltips } from "./FormulaEditorTooltips";
interface FormulaEditorProps {
  availableFormulas: FunctionDefinition;
  availableColumns: string[];
  disabled?: boolean;
  value?: string;
  onChange?: (v: string) => void;
  recordColumns?: (cols: string[]) => void;
}

const FormulaEditor: React.FunctionComponent<FormulaEditorProps> = (props) => {
  const {
    availableFormulas,
    availableColumns,
    disabled,
    onChange,
    recordColumns,
  } = props;

  const value =
    typeof props.value && typeof props.value === "string" ? props.value : "";

  const formulas = Object.keys(availableFormulas).length
    ? Object.keys(availableFormulas)
    : [];

  const getColumnsInUse = (view: EditorView) => {
    const columns = [];
    view.dom.querySelectorAll(".wly-column").forEach((column) => {
      columns.push(column.textContent);
    });
    if (recordColumns) {
      recordColumns(columns);
    }
  };

  return (
    <ReactCodeMirror
      value={value}
      basicSetup={{
        highlightActiveLine: false,
      }}
      className="wly-formula-editor"
      extensions={[
        syntaxHighlighting(formulaHighlightStyle),
        FormulaEditorLanguage(availableColumns, formulas),
        FormulaEditorAutocomplete(availableColumns, availableFormulas),
        FormulaEditorTooltips(availableFormulas),
        FormulaEditorPreventLineBreak,
        FormulaEditorTheme,
        EditorView.lineWrapping,
      ]}
      onChange={(value, viewUpdate) => {
        if (onChange) {
          onChange(value);
        }
        if (recordColumns) {
          getColumnsInUse(viewUpdate.view);
        }
      }}
      readOnly={disabled}
      style={{
        border: "1px solid #D9D9D9",
        borderRadius: 6,
      }}
    />
  );
};

export default FormulaEditor;
