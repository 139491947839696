import { Space, Typography } from "antd";
import { inject, observer } from "mobx-react";
import { useState } from "react";
import { WlyCard } from "../../../../../components/cards/WlyCard";
import { compose } from "../../../../../components/compose/WlyCompose";
import type { IDestination } from "../../../../../interfaces/destinations";
import type { ISource, ISourceValue } from "../../../../../interfaces/sources";
import GraphQLService from "../../../../../services/graphql/GraphQLService";
import type { WorkbenchUIStoreProps } from "../../../../../store/workbenchUIStore";
import type { InjectedOrgProps } from "../../../../orgs/WithOrg";
import WithOrg from "../../../../orgs/WithOrg";
import SourceExecutions from "../../../../sources/single/executions/SourceExecutions";
import SourceConnectionSettings from "../../../../sources/single/views/SourceConnectionSettings";
import SourceOverview from "../../../../sources/single/views/SourceOverview";
import SourceSchema from "../../../../sources/single/views/SourceSchema";
import SourceSyncSettingsForm from "../../../../sources/single/views/SourceSyncSettings";
import SourceUsage from "../../../../sources/single/views/SourceUsage";
import * as Toolbar from "../../../../spreadsheet/toolbar/Toolbar";
import type { IActiveObject, IActiveObjectSourceUrlState } from "../../domain";
import NoAccess from "../empty/NoAccess";
import "./ConnectorViewer.scss";

interface IConnectorViewerProps {
  activeObject: IActiveObject;
  sources: ISource[];
  currentWarehouse: IDestination;
}

type Props = IConnectorViewerProps & WorkbenchUIStoreProps & InjectedOrgProps;

const GQL = `mutation saveValue(
  $id: ID!
  $value: String!
) {
  updateSourceValue(
    id: $id
    data: {
      value: $value
    }
  ) {
    id
    value
  }
}`;

function ConnectorViewer(props: Props) {
  const {
    activeObject,
    sources,
    workbenchUIStore: {
      getActiveObject,
      setActiveObjectUrlParams,
      getActiveObjectUrlParams,
    },
    currentWarehouse,
  } = props;

  const [source, setSource] = useState<ISource>(
    sources.find((source) => source.id === getActiveObject().value)
  );

  const [sourceValue, setSourceValue] = useState<ISourceValue>(null);

  const onValueSelection = (optId: string) => {
    const value = source.values.find((v) => v.option.id === optId);
    setSourceValue(value);
  };

  const onSourceValueUpdate = (value: ISourceValue) => {
    const newSource: ISource = {
      ...source,
      values: source.values.map((v) => {
        return v.id === value.id ? { ...v, ...value } : v;
      }),
    };
    setSource(newSource);
  };

  const onSourceValueSave = (
    sourceValueId: string,
    value: string | number | Date
  ) =>
    GraphQLService(GQL, {
      id: sourceValueId,
      value: value,
    }).then((s) => {
      onSourceValueUpdate(s.updateSourceValue);
    });

  const getSelectedTab = () => {
    switch (
      (getActiveObjectUrlParams(activeObject) as IActiveObjectSourceUrlState)
        ?.tab
    ) {
      case "overview":
        return "overview";
      case "usage":
        return "usage";
      case "sync":
        return "sync";
      case "settings":
        return "settings";
      default:
        return "overview";
    }
  };

  const renderInner = () => {
    if (!activeObject) {
      return <NoAccess />;
    }

    if (getSelectedTab() === "overview") {
      return (
        <Space style={{ width: "100%" }} direction="vertical" size={24}>
          <SourceOverview source={source} />
          <SourceExecutions source={source} />
        </Space>
      );
    } else if (getSelectedTab() === "usage") {
      return (
        <div style={{ height: "600px" }}>
          <SourceUsage source={source} />
        </div>
      );
    } else if (getSelectedTab() === "sync") {
      return <SourceSchema source={source} />;
    } else if (getSelectedTab() === "settings") {
      return (
        <Space style={{ width: "100%" }} direction="vertical" size={24}>
          <WlyCard bodyStyle={{ padding: 0 }}>
            <SourceConnectionSettings
              selectedSourceValue={sourceValue}
              source={source}
              onValueSelection={onValueSelection}
              onBackClick={() => setSourceValue(null)}
              onSourceValueUpdate={onSourceValueSave}
            />
          </WlyCard>
          <WlyCard title="Sync settings">
            <SourceSyncSettingsForm source={source} />
          </WlyCard>
          {source.targetSchema && (
            <WlyCard title="Warehouse">
              Location in warehouse:{" "}
              <Typography.Text code>
                {source.targetDatabase || currentWarehouse?.targetDatabase}
              </Typography.Text>{" "}
              / <Typography.Text code>{source.targetSchema}</Typography.Text>
            </WlyCard>
          )}
        </Space>
      );
    }
  };

  return (
    <div className="workbench-content">
      <div className="workbench-spreadsheet">
        <div className="workbench-content-inner">
          <Toolbar.Toolbar style={{ borderTop: "none" }}>
            <Toolbar.Item
              color="green"
              active={getSelectedTab() === "overview"}
              onClick={() => {
                setActiveObjectUrlParams(activeObject, {
                  ...activeObject.urlState,
                  tab: "overview",
                });
              }}
            >
              Overview
            </Toolbar.Item>
            <Toolbar.Item
              color="yellow"
              active={getSelectedTab() === "usage"}
              onClick={() => {
                setActiveObjectUrlParams(activeObject, {
                  ...activeObject.urlState,
                  tab: "usage",
                });
              }}
            >
              Usage
            </Toolbar.Item>
            <Toolbar.Item
              color="pink"
              active={getSelectedTab() === "sync"}
              onClick={() => {
                setActiveObjectUrlParams(activeObject, {
                  ...activeObject.urlState,
                  tab: "sync",
                });
              }}
            >
              Selective sync
            </Toolbar.Item>
            <Toolbar.Item
              color="violet"
              active={getSelectedTab() === "settings"}
              onClick={() => {
                setActiveObjectUrlParams(activeObject, {
                  ...activeObject.urlState,
                  tab: "settings",
                });
              }}
            >
              Settings
            </Toolbar.Item>
          </Toolbar.Toolbar>
          <div className="workbench-content">
            <div className="source-viewer">
              <div
                style={{ maxWidth: 800, width: "100%", margin: "24px auto" }}
              >
                {renderInner()}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default compose<Props, IConnectorViewerProps>(
  inject("workbenchUIStore"),
  WithOrg,
  observer
)(ConnectorViewer);
