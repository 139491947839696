import { StreamLanguage } from "@codemirror/language";

export const FormulaEditorLanguage = (
  availableColumns: string[],
  availableForumulas: string[]
) => {
  function tokenBase(stream, state) {
    // string
    if (stream.match(/"(?:[^\\]|\\.)*?(?:"|$)/, true, false)) {
      return "string";
    }

    // atoms
    if (stream.match(/true|false|null/, true, false)) {
      return "atom";
    }

    // numbers
    if (
      stream.match(
        /0x[a-f\d]+|[-+]?(?:\.\d+|\d+\.?\d*)(?:e[-+]?\d+)?/,
        true,
        false
      )
    ) {
      return "number";
    }

    // formulas
    if (availableForumulas.length) {
      if (
        stream.match(
          new RegExp("(?:" + availableForumulas.join("|") + ")\\b", "i"),
          true,
          false
        )
      ) {
        return "variableName";
      }
    }

    // columns
    if (availableColumns.length) {
      if (
        stream.match(
          new RegExp("(?:" + availableColumns.join("|") + ")\\b", "i"),
          true,
          false
        )
      ) {
        return "keyword";
      }
    }

    // operators
    // eslint-disable-next-line no-useless-escape
    if (stream.match(/[-+\/*=<>!]+/, true, false)) {
      return null;
    }

    // everything else is an error
    stream.next(); // advance the stream.
    return "content";
  }

  return StreamLanguage.define({
    startState: function () {
      return { tokenize: tokenBase, commentLevel: 0 };
    },
    token: function (stream, state) {
      if (stream.eatSpace()) return null;
      return state.tokenize(stream, state);
    },
    languageData: {
      commentTokens: { block: { open: "(*", close: "*)" } },
    },
  });
};
