import { hoverTooltip } from "@codemirror/view";
import {
  functionToTooltip,
  getWarehouseLanguageDefinition,
} from "./sqlAutocomplete";

export const sqlFunctionsTooltip = (warehouseType: string) =>
  hoverTooltip((view, pos, side) => {
    let { from, to, text } = view.state.doc.lineAt(pos);
    let start = pos,
      end = pos;
    while (start > from && /\w/.test(text[start - from - 1])) start--;
    while (end < to && /\w/.test(text[end - from])) end++;
    if ((start === pos && side < 0) || (end === pos && side > 0)) return null;
    const trimmedText = text.slice(start - from, end - from);
    const isFunction = text
      .slice(end - from, text.length)
      .trim()
      .startsWith("(");
    if (!isFunction) return null;
    const source = getWarehouseLanguageDefinition(warehouseType);
    const func = source.functions.find(
      (func) => func.name.toUpperCase() === trimmedText.toUpperCase()
    );
    if (func) {
      return {
        pos: start,
        end,
        above: true,
        create(view) {
          let dom = functionToTooltip(func);
          return { dom };
        },
      };
    } else {
      return null;
    }
  });
