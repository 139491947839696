import { Col, Drawer, Row } from "antd";
import * as React from "react";
import type { RouteComponentProps } from "react-router";
import { withRouter } from "react-router";
import Card from "../../cards/Card";
import { compose } from "../../compose/WlyCompose";

import type { InjectedOrgProps } from "../../../containers/orgs/WithOrg";
import WithOrg from "../../../containers/orgs/WithOrg";
import type { ICatalogItemMeta } from "../../../interfaces/catalog";
import type { ISourceMeta } from "../../../interfaces/sources";
import CatalogItemDetail from "../detail/CatalogItemDetail";
import CatalogItem from "../item/CatalogItem";

interface IOnboardingCatalogProps {
  items: ISourceMeta[];
  onItemClick: (s: ISourceMeta) => Promise<any>;
}

type Props = IOnboardingCatalogProps &
  RouteComponentProps<{ itemSlug: string; organizationSlug: string }> &
  InjectedOrgProps;

interface IState {
  selected?: ICatalogItemMeta;
}

class OnboardingCatalog extends React.Component<Props, IState> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  public onItemClick = (s: ISourceMeta) => () => {
    this.setState({ selected: s });
  };

  public closeDrawer = () => {
    const {
      history,
      match: {
        params: { organizationSlug },
      },
    } = this.props;
    this.setState({ selected: undefined });
  };

  public render() {
    const { items, org, onItemClick } = this.props;
    const { selected } = this.state;

    return (
      <Row gutter={[24, 24]} className="source-catalog">
        {items.map((s) => {
          return (
            <Col
              key={s.slug}
              xs={24}
              sm={12}
              md={8}
              onClick={this.onItemClick(s)}
            >
              <Card height={300}>
                <CatalogItem
                  label={s.publicInfo.label}
                  logo={s.publicInfo.logo}
                  name={s.publicInfo.name}
                  connected={false}
                />
              </Card>
            </Col>
          );
        })}
        <Drawer
          closable={false}
          destroyOnClose={true}
          width={"50%"}
          styles={{
            body: { padding: "0 0 80px 0" },
          }}
          open={!!selected}
          maskClosable={true}
          onClose={this.closeDrawer}
        >
          {selected && (
            <CatalogItemDetail
              selected={selected}
              disabled={false}
              onClick={onItemClick as any}
            />
          )}
        </Drawer>
      </Row>
    );
  }
}

export default compose<Props, IOnboardingCatalogProps>(
  withRouter,
  WithOrg
)(OnboardingCatalog);
