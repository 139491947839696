import * as React from "react";
import type { ISourceValue, ISource } from "../../../../interfaces/sources";
import CatalogItemInfo from "../../../../components/catalog/info/CatalogItemInfo";
import CatalogItemInfoValueForm from "../../../../components/catalog/info/CatalogItemInfoValueForm";

interface ISourceSettingsProps {
  selectedSourceValue?: ISourceValue;
  source: ISource;
  onValueSelection: (value: string) => void;
  onBackClick: () => void;
  onSourceValueUpdate: (
    itemValueId: string,
    value: string | number | Date
  ) => Promise<void>;
}

export default class SourceConnectionSettings extends React.Component<ISourceSettingsProps> {
  public render() {
    const {
      selectedSourceValue,
      source,
      onValueSelection,
      onBackClick,
      onSourceValueUpdate,
    } = this.props;
    return (
      <div>
        {!selectedSourceValue && (
          <div style={{ padding: 24 }}>
            <CatalogItemInfo
              item={source}
              onValueSelection={onValueSelection}
            />
          </div>
        )}
        {selectedSourceValue && (
          <CatalogItemInfoValueForm
            value={selectedSourceValue}
            onBackClick={onBackClick}
            onUpdate={onSourceValueUpdate}
          />
        )}
      </div>
    );
  }
}
