import cuid from "cuid";
import { GoogleCharts } from "google-charts";
import _ from "lodash";
import * as React from "react";
import type { IQueryTraceData } from "../../../services/LagoonService";

interface ITraceChartProps {
  data: Array<IQueryTraceData>;
  width?: number;
}

export default class TraceChart extends React.Component<ITraceChartProps> {
  id = cuid();

  shouldComponentUpdate(
    nextProps: Readonly<ITraceChartProps>,
    nextState: Readonly<{}>,
    nextContext: any
  ): boolean {
    if (!_.isEqual(this.props.width, nextProps.width)) return true;
    if (!_.isEqual(this.props.data, nextProps.data)) return true;
    return false;
  }

  public render() {
    const { data, width } = this.props;

    const drawChart = () => {
      const dataTable = new GoogleCharts.api.visualization.DataTable();
      dataTable.addColumn({ type: "string", id: "Chart" });
      dataTable.addColumn({ type: "string", id: "Step" });
      dataTable.addColumn({ type: "date", id: "Start" });
      dataTable.addColumn({ type: "date", id: "End" });

      const timeline = new GoogleCharts.api.visualization.Timeline(
        document.getElementById(this.id)
      );

      const rows = [];

      _.uniq(data)
        .sort((a, b) => a.ts - b.ts)
        .forEach((d, i, s) => {
          if (i < s.length - 2) {
            rows.push([
              "load",
              d.evtName,
              new Date(d.ts),
              new Date(s[i + 1].ts),
            ]);
          } else {
            rows.push([
              "load",
              d.evtName,
              new Date(d.ts),
              new Date(d.ts + 100),
            ]);
          }
        });

      dataTable.addRows(rows);

      const options = {
        timeline: { groupByRowLabel: false },
      };

      timeline.draw(dataTable, options);
    };

    GoogleCharts.load(drawChart, { packages: ["timeline"] });

    return (
      <div
        id={this.id}
        style={{ height: 500, width: width ? width : "100%" }}
      />
    );
  }
}
