import { MoreOutlined, ReloadOutlined } from "@ant-design/icons";
import { Button, Flex, Form, Input, Radio, Space, Typography } from "antd";
import type { FormInstance } from "antd/lib";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { WlyCard } from "../../../../../../components/cards/WlyCard";
import Loading from "../../../../../../components/layout/feedback/loading";
import type { IDimensionFormInput } from "../../domain";

const { Text } = Typography;

type Props = {
  form: FormInstance<IDimensionFormInput>;
  loadingValues: boolean;
  getDistinctValues: () => Promise<void>;
  debouncedOnSave: (data: IDimensionFormInput) => any;
};

export default function DimensionOrdering({
  form,
  loadingValues,
  getDistinctValues,
  debouncedOnSave,
}: Props) {
  return (
    <>
      <Form.Item name={["customOrderingConfArray"]} hidden></Form.Item>
      <Form.Item noStyle shouldUpdate>
        {() => {
          const enabled =
            !!form.getFieldValue("customOrderingConfArray") &&
            typeof form.getFieldValue("customOrderingConfArray") === "object";

          if (form.getFieldValue("columnDomain") === "STRING") {
            return (
              <WlyCard title="Dimension value ordering">
                <Radio.Group
                  value={enabled}
                  onChange={(e) => {
                    if (e.target.value) {
                      form.setFieldValue("customOrderingConfArray", []);
                      getDistinctValues();
                    } else {
                      form.setFieldValue("customOrderingConfArray", null);
                      debouncedOnSave(form.getFieldsValue());
                    }
                  }}
                >
                  <Space direction="vertical">
                    <Radio value={false}>
                      <div>
                        <div>
                          <Text strong={true}>Alphabetical</Text>
                        </div>
                        <div>
                          <Text type="secondary" italic={true}>
                            Dimension value ordering will follow the
                            alphabetical rule
                          </Text>
                        </div>
                      </div>
                    </Radio>
                    <Radio value={true}>
                      <div>
                        <div>
                          <Text strong={true}>Manual</Text>
                        </div>
                        <div>
                          <Text type="secondary" italic={true}>
                            Dimension value ordering will follow your custom
                            rule. Limited to 20 values
                          </Text>
                        </div>
                      </div>
                    </Radio>
                  </Space>
                </Radio.Group>
                <Form.Item noStyle shouldUpdate>
                  {() => {
                    if (loadingValues) {
                      return <Loading />;
                    }
                    if (!enabled) {
                      return <></>;
                    }
                    return (
                      <Form.List name={["customOrderingConfArray"]}>
                        {(fields, { add, remove, move }) => (
                          <DragDropContext
                            onDragEnd={(r) => {
                              if (!r.destination) {
                                return;
                              }
                              move(r.source.index, r.destination.index);
                            }}
                          >
                            <Droppable droppableId="droppable">
                              {(provided, snapshot) => (
                                <div
                                  {...provided.droppableProps}
                                  ref={provided.innerRef}
                                >
                                  <>
                                    <Button
                                      block
                                      disabled={loadingValues}
                                      style={{ marginBottom: 12 }}
                                      onClick={() => {
                                        form.setFieldValue(
                                          "customOrderingConfArray",
                                          []
                                        );
                                        getDistinctValues();
                                      }}
                                      icon={<ReloadOutlined />}
                                    >
                                      Refresh values
                                    </Button>
                                    {fields.map(
                                      ({ key, ...restField }, index) => (
                                        <Draggable
                                          key={key}
                                          draggableId={String(key)}
                                          index={index}
                                        >
                                          {(provided, snapshot) => (
                                            <div
                                              ref={provided.innerRef}
                                              {...provided.draggableProps}
                                              {...provided.dragHandleProps}
                                            >
                                              <Flex
                                                key={key}
                                                gap={8}
                                                style={{
                                                  height: 45,
                                                }}
                                              >
                                                <Form.Item>
                                                  <Button
                                                    icon={<MoreOutlined />}
                                                  />
                                                </Form.Item>
                                                <Flex flex={1}>
                                                  <Form.Item
                                                    {...restField}
                                                    style={{ width: "100%" }}
                                                  >
                                                    <Input
                                                      placeholder="Value"
                                                      style={{
                                                        width: "100%",
                                                        backgroundColor:
                                                          "transparent",
                                                        color: "black",
                                                      }}
                                                      disabled
                                                    />
                                                  </Form.Item>
                                                </Flex>
                                              </Flex>
                                            </div>
                                          )}
                                        </Draggable>
                                      )
                                    )}
                                  </>
                                </div>
                              )}
                            </Droppable>
                          </DragDropContext>
                        )}
                      </Form.List>
                    );
                  }}
                </Form.Item>
              </WlyCard>
            );
          }
        }}
      </Form.Item>
    </>
  );
}
