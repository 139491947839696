import type { IAnalysisType } from "../../../containers/exploration/single/domain";
import { WlyWaterfallChartIcon } from "../../icons/custom-icons/WlyWaterfallChartIcon";
import type { SingleChartDefinition } from "../domain";

export const waterfallChartTimeserieDefinition: SingleChartDefinition = {
  label: "Waterfall chart",
  allowTimeComparison: false,
  icon: <WlyWaterfallChartIcon />,
  canPredict: () => false,
  pivot: (analysisType: IAnalysisType) => false,
  minDimensions: 1,
  maxDimensions: 1,
  minMetrics: 1,
  maxMetrics: 1,
  canDisplay: (rs) => {
    if ((rs as any).queryType === "compareDateRangeQuery") {
      return "Cannot use a date comparison query on a waterfall";
    }
    return;
  },
  config: [
    "labels",
    "waterfall-timeserie-color-up",
    "waterfall-timeserie-color-down",
    "waterfall-timeserie-color-bounds",
  ],
  axis: {
    bottom: {
      editable: true,
      isTimeFormattable: (a) => true,
    },
    left: {
      editable: true,
      scaleEditable: true,
    },
    right: {
      editable: false,
    },
  },
  extractCustomizationDimensions: (d, q) => {
    return {
      dimensions: [],
    };
  },
  extractCustomizationSeries: (s, d) => {
    return {
      series: s.map((serie) => {
        return { ...serie, label: serie.metric.label ?? serie.label };
      }),
      customColor: false,
      customLabel: true,
      customType: [],
    };
  },
};
