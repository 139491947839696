import { CopyOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { Button, Form, Input, List, message, Select, Typography } from "antd";
import moment from "moment";
import * as React from "react";
import type { InjectedAntUtilsProps } from "../../../components/ant-utils/withAntUtils";
import { withAntUtils } from "../../../components/ant-utils/withAntUtils";
import { compose } from "../../../components/compose/WlyCompose";
import FormActions from "../../../components/form/actions/FormActions";
import FormHeader from "../../../components/form/header/FormHeader";
import { handleGQLErrors } from "../../../helpers/gqlHelpers";
import type {
  IServiceAccount,
  IServiceAccountKey,
} from "../../../interfaces/serviceAccount";
import { IUserRoleType } from "../../../interfaces/user";
import { track } from "../../../services/AnalyticsService";
import type { InjectedOrgProps } from "../../orgs/WithOrg";
import WithOrg from "../../orgs/WithOrg";

interface IGroupManagementFormProps {
  onDrawerClose: () => void;
  onSubmit: () => Promise<void>;
  orgId: string;
  isEditMode: boolean;
  selectedServiceAccount?: IServiceAccount;
  createServiceAccount: (
    orgId: string,
    name: string,
    role: IUserRoleType
  ) => Promise<string>;
  createKey: (serviceAccountId: string) => Promise<string>;
  removeKey: (key: IServiceAccountKey) => Promise<void>;
}

type Props = InjectedOrgProps &
  InjectedAntUtilsProps &
  IGroupManagementFormProps;

interface IState {
  submitting: boolean;
  // State from the UI
  initialValues: InitialValues;
}

interface InitialValues {
  serviceAccountName: string;
  role: IUserRoleType;
}

class ServiceAccountManagementForm extends React.Component<Props, IState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      initialValues: {
        serviceAccountName: "My Service Account",
        role: IUserRoleType.ADMIN_BUILDER,
      },
      submitting: false,
    };
  }

  componentDidMount() {
    track("Service Account Edit Opened");
  }

  private showServiceAccountKeyCreationModal = (keyValue: string): void => {
    const { antUtils, onSubmit } = this.props;

    antUtils.modal.confirm({
      title: `Your Service Account key is created!`,
      icon: <ExclamationCircleOutlined />,
      content: (
        <>
          <p>
            Here is your Service Account Key that you can use to connect to the
            API:
          </p>
          <div style={{ display: "flex" }}>
            <Input
              disabled={true}
              value={keyValue}
              styles={{ input: { textOverflow: "ellipsis" } }}
              suffix={
                <Button
                  icon={<CopyOutlined />}
                  size={"middle"}
                  style={{ marginLeft: "auto" }}
                  onClick={() => {
                    navigator.clipboard.writeText(keyValue);
                    message.success(
                      `Service Account Key is copied to your clipbopard!`
                    );
                  }}
                >
                  Copy
                </Button>
              }
            />
          </div>
          <br></br>
          <p>
            Please make sure you copy it and place it in a safe place as you
            won't be able to see it again.
          </p>
        </>
      ),
      okText: "Got it",
      onOk: () => {
        return onSubmit();
      },
    });
  };

  public submitForm = async (v: InitialValues) => {
    const {
      org,
      onSubmit,
      onDrawerClose,
      isEditMode,
      createServiceAccount,
      createKey,
    } = this.props;

    this.setState({ submitting: true });

    try {
      // TODO: handle edit
      // Warning: Edit is not so executionAsyncId, depending on the input, either the ServiceAccount user is updated or it's its UserRole that should be updated
      if (!isEditMode) {
        const serviceAccountId = await createServiceAccount(
          org.id,
          v.serviceAccountName,
          v.role
        );
        const keyValue = await createKey(serviceAccountId);
        this.showServiceAccountKeyCreationModal(keyValue);
      } else {
        onSubmit();
      }
      onDrawerClose();
    } catch (err) {
      handleGQLErrors(() => {
        this.setState({ submitting: false });
      })(err);
    }
  };

  public render() {
    const {
      onDrawerClose,
      selectedServiceAccount,
      isEditMode,
      createKey,
      removeKey,
      antUtils,
      onSubmit,
    } = this.props;
    const { initialValues } = this.state;

    const getFormValues = (): InitialValues => {
      if (isEditMode && selectedServiceAccount) {
        return {
          serviceAccountName: selectedServiceAccount.name,
          role: selectedServiceAccount.roles?.[0].role || IUserRoleType.BUILDER,
        };
      } else {
        return initialValues;
      }
    };

    return (
      <>
        <Form
          initialValues={getFormValues()}
          onFinish={this.submitForm}
          className="form-container"
          layout="vertical"
        >
          <FormHeader
            title={
              isEditMode ? "Edit Service Account" : "Create Service Account"
            }
            onClose={onDrawerClose}
          />
          <div style={{ padding: "0 24px", marginTop: "16px" }}>
            <Form.Item
              name={["serviceAccountName"]}
              required={true}
              label="Name"
            >
              <Input disabled={isEditMode} />
            </Form.Item>
            <Form.Item label="Role" name={["role"]} required={true}>
              <Select disabled={isEditMode}>
                <Select.Option value={IUserRoleType.ADMIN_BUILDER}>
                  Admin builder
                </Select.Option>
                <Select.Option value={IUserRoleType.BUILDER}>
                  Builder
                </Select.Option>
                <Select.Option value={IUserRoleType.EDITOR}>
                  Editor
                </Select.Option>
                <Select.Option value={IUserRoleType.VIEWER}>
                  Viewer
                </Select.Option>
                <Select.Option value={IUserRoleType.REPORT_VIEWER}>
                  Report Viewer
                </Select.Option>
              </Select>
            </Form.Item>

            {isEditMode && selectedServiceAccount ? (
              <div style={{ paddingBottom: 24 }}>
                <div style={{ display: "flex", alignItems: "baseline" }}>
                  <div>
                    <Typography.Title level={5}>Keys</Typography.Title>
                  </div>
                  <Button
                    style={{ marginLeft: "auto" }}
                    type={"text"}
                    onClick={async () => {
                      const keyValue = await createKey(
                        selectedServiceAccount.id
                      );
                      this.showServiceAccountKeyCreationModal(keyValue);
                    }}
                  >
                    Add
                  </Button>
                </div>
                <List
                  itemLayout="horizontal"
                  bordered
                  dataSource={selectedServiceAccount.keys}
                  renderItem={(key) => (
                    <List.Item>
                      <div className="service-account-key-renderer-info">
                        <>
                          <Typography.Text type="secondary">
                            <code>{key.valueRedacted}</code> Creation date:{" "}
                            {moment(key.createdAt).format("MMM DD, YYYY")}
                          </Typography.Text>

                          <Button
                            className="service-account-key-renderer-remove-btn"
                            type="link"
                            onClick={() => {
                              antUtils.modal.confirm({
                                title: `Danger zone`,
                                icon: <ExclamationCircleOutlined />,
                                content: (
                                  <>
                                    <p>
                                      Deleting this Service Account Key will
                                      disable any integrations relying on it.
                                      Any API call made using this Service
                                      Account Key will fail.
                                    </p>
                                    <p>
                                      Please make sure that this Service Account
                                      Key is no longer in use before deleting
                                      it.
                                    </p>
                                  </>
                                ),
                                okText: "Delete anyway",
                                okType: "danger",
                                onOk: async () => {
                                  await removeKey(key);
                                  onSubmit();
                                },
                              });
                            }}
                          >
                            Remove
                          </Button>
                        </>
                      </div>
                    </List.Item>
                  )}
                />
              </div>
            ) : undefined}
          </div>

          <FormActions
            isSubmitting={this.state.submitting}
            onCancel={onDrawerClose}
          />
        </Form>
      </>
    );
  }
}

export default compose<Props, IGroupManagementFormProps>(
  WithOrg,
  withAntUtils
)(ServiceAccountManagementForm);
