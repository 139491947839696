import type { TabsProps } from "antd";
import { Form, Input, Space, Tabs } from "antd";
import { useForm } from "antd/lib/form/Form";
import { inject, observer } from "mobx-react";
import React from "react";
import Logo from "../../../../assets/icon.svg";
import type { InjectedAntUtilsProps } from "../../../../components/ant-utils/withAntUtils";
import { withAntUtils } from "../../../../components/ant-utils/withAntUtils";
import { compose } from "../../../../components/compose/WlyCompose";
import FormActions from "../../../../components/form/actions/FormActions";
import ColorPicker from "../../../../components/form/color-picker/ColorPicker";
import type { IOrg, IPartnerPortal } from "../../../../interfaces/org";
import GraphQLService from "../../../../services/graphql/GraphQLService";
import type { UserStoreProps } from "../../../../store/userStore";
import ImageUploader from "../../../uploader/form/ImageUploader";
import { defaultHomeImage } from "../../../uploader/popover/domain";

interface IPartnerPortalFormProps {
  selectedPortal?: IPartnerPortal;
  org: IOrg;
  onSubmit: () => void;
  onDrawerClose: () => void;
}

type Props = IPartnerPortalFormProps & InjectedAntUtilsProps & UserStoreProps;

function PartnerPortalForm(props: Props) {
  const {
    selectedPortal,
    onSubmit,
    onDrawerClose,
    org,
    userStore: { getUser },
    antUtils: { message },
  } = props;
  const [form] = useForm<IPartnerPortal>();
  const [submitting, setSubmitting] = React.useState<boolean>(false);

  return (
    <div>
      <Form<IPartnerPortal>
        form={form}
        layout="vertical"
        initialValues={
          selectedPortal
            ? selectedPortal
            : {
                primaryColor: "black",
              }
        }
        onFinish={async (v) => {
          try {
            setSubmitting(true);
            if (selectedPortal?.id) {
              await GraphQLService(
                `mutation updatePartnerPortal($id: ID!, $data: PartnerPortalUpdateInput!) {
                updatePartnerPortal(id: $id, data: $data) {
                  id
                }
              }`,
                {
                  id: selectedPortal.id,
                  data: {
                    name: v.name,
                    slug: v.slug,
                    primaryColor: v.primaryColor,
                    defaultHomeCover: v.defaultHomeCover,
                    logo: v.logo,
                    logoutUrl: v.logoutUrl,
                  },
                }
              );
            } else {
              await GraphQLService(
                `mutation createPartnerPortal($data: PartnerPortalCreateInput!) {
                createPartnerPortal(data: $data) {
                  id
                }
              }`,
                {
                  data: {
                    name: v.name,
                    slug: v.slug,
                    primaryColor: v.primaryColor,
                    logo: v.logo,
                    logoutUrl: v.logoutUrl,
                    defaultHomeCover: v.defaultHomeCover,
                    org: {
                      connect: {
                        id: org.id,
                      },
                    },
                  },
                }
              );
            }
            await getUser();
            onSubmit();
            message.success("Successfully created your portal");
          } catch (err) {
            message.error("An unexpected error happened...");
          } finally {
            setSubmitting(false);
          }
        }}
      >
        <Form.Item
          rules={[{ required: true }]}
          name={["name"]}
          label={"Name"}
          required
        >
          <Input />
        </Form.Item>
        <Form.Item
          rules={[
            { required: true, message: "Technical name is required" },
            {
              validator: (r, value) => {
                if (
                  !(value || ("" as string)).match(/^[A-Za-z][0-9A-Za-z_-]+$/)
                ) {
                  return Promise.reject(`A technical name must contain only letters (a-z, A-Z), numbers (0-9), dashes (-) or underscores (_), and it must start with a letter or underscore.
                Could you adapt the name?`);
                }
                return Promise.resolve();
              },
            },
          ]}
          normalize={(value) => ((value || "") as string).toLowerCase()}
          name={["slug"]}
          label={"Technical name"}
          required
        >
          <Input disabled={!!selectedPortal?.id} />
        </Form.Item>
        <Form.Item
          rules={[{ required: true }]}
          name={["primaryColor"]}
          label={"Color"}
          required
        >
          <ColorPicker />
        </Form.Item>
        <Form.Item
          rules={[{ required: true }]}
          name={["logo"]}
          label={"Logo"}
          required
        >
          <ImageUploader />
        </Form.Item>
        <Form.Item
          rules={[{ required: true }]}
          name={["logoutUrl"]}
          label={"Logout url"}
          required
        >
          <Input />
        </Form.Item>
        <Form.Item name={["defaultHomeCover"]} label={"Default Home Cover"}>
          <ImageUploader />
        </Form.Item>

        <Form.Item shouldUpdate noStyle>
          {() => {
            const getLogo = () => {
              if (form.getFieldValue(["logo"])) {
                return form.getFieldValue(["logo"]);
              }
              return Logo;
            };

            const getCover = () => {
              if (form.getFieldValue(["defaultHomeCover"])) {
                return form.getFieldValue(["defaultHomeCover"]);
              }
              return defaultHomeImage;
            };
            const getName = () => {
              if (form.getFieldValue(["name"])) {
                return form.getFieldValue(["name"]);
              }
              return org.name;
            };
            const items: TabsProps["items"] = [
              {
                key: "1",
                label: `Login form`,
                children: (
                  <div
                    style={{
                      width: "100%",
                      aspectRatio: "16/9",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      backgroundColor: form.getFieldValue(["primaryColor"]),
                    }}
                  >
                    <div
                      style={{
                        width: "30%",
                        backgroundColor: "white",
                        padding: 4,
                        borderRadius: 4,
                        textAlign: "center",
                      }}
                    >
                      <Space direction="vertical" style={{ width: "100%" }}>
                        <div style={{ height: 24, width: "100%" }}>
                          <img
                            style={{ maxHeight: 24, maxWidth: 40 }}
                            src={getLogo()}
                            alt={"Logo"}
                          />
                        </div>
                        <div
                          style={{
                            borderRadius: 4,
                            backgroundColor: "#bcbdbd",
                            height: 12,
                          }}
                        />
                        <div
                          style={{
                            borderRadius: 4,
                            backgroundColor: "#bcbdbd",
                            height: 12,
                          }}
                        />
                        <div
                          style={{
                            borderRadius: 4,
                            backgroundColor: form.getFieldValue([
                              "primaryColor",
                            ]),
                            textAlign: "center",
                            color: "#fff",
                            fontSize: "0.8em",
                            fontWeight: "bold",
                          }}
                        >
                          Login
                        </div>
                      </Space>
                    </div>
                  </div>
                ),
              },
              {
                key: "2",
                label: `Home page`,
                children: (
                  <div
                    style={{
                      width: "100%",
                      aspectRatio: "16/9",
                      display: "flex",
                      flexDirection: "row",
                      border: "1px solid #E7ECEF",
                      borderRadius: 4,
                    }}
                  >
                    <div
                      style={{
                        width: "30%",
                        backgroundColor: "white",
                        padding: 4,
                        height: "100%",
                      }}
                    >
                      <Space style={{ width: "100%" }} direction="vertical">
                        <div style={{ display: "flex" }}>
                          <div style={{ flex: 0 }}>
                            <img
                              style={{ maxHeight: 24, maxWidth: 40 }}
                              src={getLogo()}
                              alt={"Logo"}
                            />
                          </div>
                          <div
                            style={{
                              flex: 1,
                              marginLeft: 4,
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              fontWeight: "bold",
                            }}
                          >
                            {getName()}
                          </div>
                        </div>
                        <div
                          style={{
                            borderRadius: 4,
                            backgroundColor: "#bcbdbd82",
                            height: 12,
                          }}
                        />
                        <div
                          style={{
                            borderRadius: 4,
                            backgroundColor: "#bcbdbd82",
                            height: 12,
                          }}
                        />
                        <div
                          style={{
                            borderRadius: 4,
                            backgroundColor: "#bcbdbd82",
                            height: 12,
                          }}
                        />
                      </Space>
                      <div />
                    </div>
                    <div
                      style={{
                        width: "70%",
                        backgroundColor: "#F3F5F7",
                        height: "100%",
                      }}
                    >
                      <div style={{ height: "30%", width: "100%" }}>
                        <img
                          src={getCover()}
                          style={{
                            objectFit: "cover",
                            height: "100%",
                            width: "100%",
                          }}
                          alt="cover"
                        />
                      </div>
                      <div
                        style={{ width: "100%", display: "flex", padding: 4 }}
                      >
                        <div
                          style={{
                            width: "20%",
                            backgroundColor: "#fff",
                            border: "1px solid #E7ECEF",
                            height: 30,
                          }}
                        />
                        <div
                          style={{
                            width: "20%",
                            backgroundColor: "#fff",
                            border: "1px solid #E7ECEF",
                            height: 30,
                            marginLeft: 3,
                          }}
                        />
                        <div
                          style={{
                            width: "20%",
                            backgroundColor: "#fff",
                            border: "1px solid #E7ECEF",
                            height: 30,
                            marginLeft: 3,
                          }}
                        />
                      </div>
                    </div>
                  </div>
                ),
              },
            ];
            return <Tabs defaultActiveKey="1" items={items} />;
          }}
        </Form.Item>
      </Form>
      <FormActions
        isSubmitting={submitting}
        onCancel={onDrawerClose}
        onSubmit={() => form.submit()}
      />
    </div>
  );
}

export default compose<Props, IPartnerPortalFormProps>(
  inject("userStore"),
  observer,
  withAntUtils
)(PartnerPortalForm);
