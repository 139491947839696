import * as React from "react";
import { withRouter } from "react-router";
import { compose } from "../../../components/compose/WlyCompose";
import Feednack from "../../../components/layout/feedback/feedback";
import type { IExploration } from "../../../interfaces/explorations";
import type {
  ITemplate,
  ITemplateCategory,
} from "../../../interfaces/template";
import GraphQl from "../../graphql/graphql";
import { TemplateListLoader } from "../loader/TemplateListLoader";
import { TemplatePage } from "./TemplatePage";

interface ITemplatesPageWrapperProps {
  onClose: () => void;
  onSuccess: (
    explorations: Array<Pick<IExploration, "id" | "slug">>
  ) => Promise<any>;
}

const GQL = `
fragment TemplateSelection on Template {
  id
  slug
  name
  description
  googleDataStudioTemplateId
  categories {
    slug
  }
  sourceMeta {
    publicInfo {
      name
      logo
    }
  }
}

query getTemplatesAndCategories($search: String, $sourceMeta: ID) {
  allTemplates(sortBy: name_ASC, where: {AND: [{deleted_not: true}, {name_contains: $search}, {sourceMeta_some: { id: $sourceMeta }}]}) {
    ...TemplateSelection
  }
  allFeaturedTemplates: allTemplates(sortBy: name_ASC, where: {AND: [{deleted_not: true}, {name_contains: $search}, {sourceMeta_some: { id: $sourceMeta }}]}) {
    ...TemplateSelection
  }
  allTemplateCategories(sortBy: name_ASC, where: {deleted_not: true}) {
    id
    slug
    name
    templates(sortBy: name_ASC, where: {AND: [{deleted_not: true}, {name_contains: $search}, {sourceMeta_some: { id: $sourceMeta }}]}) {
    	...TemplateSelection
  	}
  }
}
`;

type Props = ITemplatesPageWrapperProps;

class TemplatesPageWrapper extends React.Component<Props> {
  public render() {
    const { onClose, onSuccess } = this.props;

    return (
      <GraphQl<{
        allTemplates: ITemplate[];
        allFeaturedTemplates: ITemplate[];
        allTemplateCategories: ITemplateCategory[];
      }>
        query={GQL}
        variables={{}}
      >
        {(r) => {
          if (r.status === "loading" || r.status === "initial") {
            return <TemplateListLoader onClose={onClose} />;
          }
          if (r.status === "error") {
            return <Feednack>{JSON.stringify(r.error)}</Feednack>;
          }

          return (
            <TemplatePage
              onClose={onClose}
              allTemplates={r.data.allTemplates}
              allFeaturedTemplates={r.data.allFeaturedTemplates}
              allTemplateCategories={r.data.allTemplateCategories}
              onSuccess={onSuccess}
            />
          );
        }}
      </GraphQl>
    );
  }
}

export default compose<Props, ITemplatesPageWrapperProps>(withRouter)(
  TemplatesPageWrapper
);
