import * as duckdb from "@duckdb/duckdb-wasm";
import eh_worker from "@duckdb/duckdb-wasm/dist/duckdb-browser-eh.worker.js?url";
import mvp_worker from "@duckdb/duckdb-wasm/dist/duckdb-browser-mvp.worker.js?url";
import duckdb_wasm_eh from "@duckdb/duckdb-wasm/dist/duckdb-eh.wasm?url";
import duckdb_wasm from "@duckdb/duckdb-wasm/dist/duckdb-mvp.wasm?url";
import { Button, Card, Dropdown, Modal, Space, Tabs, Typography } from "antd";
import React from "react";
import Feednack from "../../../../../components/layout/feedback/feedback";
import Loading from "../../../../../components/layout/feedback/loading";
import type { AsyncData } from "../../../../../helpers/typescriptHelpers";
import { RootCauseAnalysisFact } from "./RootCauseAnalysisFact";
import type { IAdditionalFilters } from "./RootCauseAnalysisTable";
import RootCauseAnalysisTable from "./RootCauseAnalysisTable";
import { RCA } from "./domain";

import "./RootCauseAnalysisPage.scss";

interface IRootCauseAnalysisPageProps {
  open: boolean;
  onClose: () => void;
}

const MANUAL_BUNDLES: duckdb.DuckDBBundles = {
  mvp: {
    mainModule: duckdb_wasm,
    mainWorker: mvp_worker,
  },
  eh: {
    mainModule: duckdb_wasm_eh,
    mainWorker: eh_worker,
  },
};

export function RootCauseAnalysisPage(props: IRootCauseAnalysisPageProps) {
  const { open, onClose } = props;

  const [init, setInit] = React.useState<AsyncData<{ db: duckdb.AsyncDuckDB }>>(
    { status: "initial" }
  );

  const [selectedAnalyses, setSelectedAnalyses] = React.useState<{
    start: string;
    end: string;
  }>();
  const [selectedProportionMetric, setSelectedProportion] = React.useState<
    string | undefined
  >();

  const [additionalFilters, setAdditionalFilters] =
    React.useState<IAdditionalFilters>([]);
  const [find, setFind] = React.useState<"low" | "high">("low");

  React.useEffect(() => {
    const init = async () => {
      try {
        setInit({ status: "loading" });
        // Select a bundle based on browser checks
        const bundle = await duckdb.selectBundle(MANUAL_BUNDLES);
        // Instantiate the asynchronus version of DuckDB-wasm
        const worker = new Worker(bundle.mainWorker!);
        const logger = new duckdb.ConsoleLogger();
        const db = new duckdb.AsyncDuckDB(logger, worker);
        await db.instantiate(bundle.mainModule, bundle.pthreadWorker);
        const csvurl = `${window.WHALY_CONSTANTS.APP_URL}/v3-demo-data/rca.csv`;
        const streamResponse = await fetch(csvurl);
        await db.registerFileBuffer(
          "data.csv",
          new Uint8Array(await streamResponse.arrayBuffer())
        );

        const c = await db.connect();

        await c.insertCSVFromPath("data.csv", {
          schema: "main",
          name: "rca",
          detect: true,
          header: true,
        });

        await c.close();
        setInit({ status: "success", data: { db } });
      } catch (err: any) {
        console.error(err);
        setInit({ status: "error", error: err });
      }
    };
    init();
  }, []);

  React.useEffect(() => {
    setSelectedProportion(RCA.proportionMetrics[0]?.key);
  }, [selectedAnalyses]);

  const renderInner = () => {
    if (!selectedAnalyses) {
      return (
        <Space style={{ width: "100%" }} direction="vertical">
          <div style={{ textAlign: "right" }}>
            <Button size="small">New</Button>
          </div>
          {RCA.analyses.map((a) => (
            <Card
              key={a.start + a.end}
              size="small"
              style={{ cursor: "pointer" }}
              onClick={() => setSelectedAnalyses(a)}
            >
              {RCA.name} du <Typography.Text strong>{a.start}</Typography.Text>{" "}
              au <Typography.Text strong>{a.end}</Typography.Text>
            </Card>
          ))}
        </Space>
      );
    }
    if (init.status === "initial" || init.status === "loading") {
      return (
        <Feednack>
          <Loading />
        </Feednack>
      );
    }
    if (init.status === "error") {
      return <Feednack>{init.error?.message}</Feednack>;
    }
    return (
      <Tabs
        style={{ height: "100%" }}
        items={[
          {
            key: "insights",
            label: "Insights",
            children: (
              <RootCauseAnalysisFact
                table={"main.rca"}
                db={init.data.db}
                rca={RCA}
                selectedProportionDimension="number_of_magasin"
                additionalFilters={additionalFilters}
              />
            ),
          },
          {
            key: "details",
            label: "Details",
            children: (
              <div
                style={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  gap: 8,
                }}
              >
                <div style={{ flex: 0, lineHeight: 2.4 }}>
                  <Typography.Text strong>Show</Typography.Text>{" "}
                  <Dropdown
                    menu={{
                      items: [
                        {
                          key: "low",
                          label: "low contributors",
                          onClick: () => setFind("low"),
                        },
                        {
                          key: "high",
                          label: "high contributors",
                          onClick: () => setFind("high"),
                        },
                      ],
                    }}
                    trigger={["click"]}
                  >
                    <Button size="small">{find} contributors</Button>
                  </Dropdown>{" "}
                  <Typography.Text strong>
                    and show proportions as
                  </Typography.Text>{" "}
                  <Dropdown
                    trigger={["click"]}
                    menu={{
                      items: RCA.proportionMetrics.map((m) => ({
                        ...m,
                        onClick: () => setSelectedProportion(m.key),
                      })),
                    }}
                  >
                    <Button size="small">Nombre de magasins</Button>
                  </Dropdown>{" "}
                  <Typography.Text strong>in %</Typography.Text>{" "}
                </div>
                {/* <div style={{ flex: 0 }}>
                  <RCAFilters />
                </div> */}
                <div style={{ flex: `1 auto`, height: 0, flexBasis: 0 }}>
                  <RootCauseAnalysisTable
                    table={"main.rca"}
                    db={init.data.db}
                    rca={RCA}
                    find={find}
                    selectedProportionDimension={
                      selectedProportionMetric || RCA.proportionMetrics[0].key
                    }
                    additionalFilters={additionalFilters}
                  />
                </div>
              </div>
            ),
          },
        ]}
      />
    );
  };

  return (
    <Modal
      maskClosable={false}
      closable={true}
      width={"70%"}
      centered
      className="settings-modal"
      destroyOnClose={true}
      styles={{
        body: {
          height: "Calc(100% - 52px)",
          maxHeight: "Calc(100% - 52px)",
          overflowX: "hidden",
          overflowY: "hidden",
          paddingTop: 0,
        },
      }}
      classNames={{
        body: "root-cause-analysis",
      }}
      title={
        selectedAnalyses
          ? `${RCA.name} du ${selectedAnalyses.start} au ${selectedAnalyses.end}`
          : RCA.name
      }
      onCancel={onClose}
      footer={null}
      open={open}
    >
      {renderInner()}
    </Modal>
  );
}
