import {
  MenuOutlined,
  QuestionCircleOutlined,
  ZoomInOutlined,
} from "@ant-design/icons";
import { Modal, Tooltip, Transfer, Typography } from "antd";
import _ from "lodash";
import * as React from "react";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { Emoji } from "../../../components/form/emoji-picker/Emoji";
import Loading from "../../../components/layout/feedback/loading";
import type { SchemaResult } from "../../../interfaces/transformations";
import TypeRenderer from "../renderer/TypeRenderer";
import "./DrillsModal.scss";

const { Text } = Typography;

interface IDrillsProps {
  disabled: boolean;
  drills: string[];
  primaryKeys: string[];
  schema: SchemaResult;
  onUpdate: (drills: string[]) => Promise<any>;
}

interface RecordType {
  key: string;
  disabled: boolean;
}

export default function DrillsModal(props: IDrillsProps) {
  const { disabled, drills, primaryKeys, schema, onUpdate } = props;

  const dataSource: RecordType[] = _.union(drills, Object.keys(schema)).map(
    (k) => {
      return {
        key: k,
        disabled: primaryKeys.includes(k) && schema[k] ? true : false,
      };
    }
  );

  const [targetKeys, setTargetKeys] = React.useState<string[]>(
    _.union(drills, primaryKeys)
  );
  const [selectedKeys, setSelectedKeys] = React.useState<string[]>([]);
  const [visible, setVisible] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);

  const resetState = React.useCallback(() => {
    setTargetKeys(_.union(drills, primaryKeys));
    setSelectedKeys([]);
  }, [drills, primaryKeys]);

  React.useEffect(() => {
    resetState();
  }, [drills, primaryKeys, resetState]);

  const onChange = (nextTargetKeys) => {
    setTargetKeys([...nextTargetKeys]);
  };

  const filterOption = (inputValue: string, option: RecordType) =>
    option.key.indexOf(inputValue) > -1;

  const onSelectChange = (
    sourceSelectedKeys: string[],
    targetSelectedKeys: string[]
  ) => {
    setSelectedKeys([...sourceSelectedKeys, ...targetSelectedKeys]);
  };

  const type = "DraggableItem";

  const DraggableItem = ({
    index,
    label,
    moveRow,
  }: {
    index: number;
    label: string;
    moveRow: (indexOne: number, indexTo: number) => void;
  }) => {
    const ref = React.useRef();
    const [{ isOver, dropClassName }, drop] = useDrop({
      accept: type,
      collect: (monitor: any) => {
        const { index: dragIndex } = monitor.getItem() || {};
        if (dragIndex === index) {
          return {};
        }
        return {
          isOver: monitor.isOver(),
          dropClassName:
            dragIndex < index ? ` drop-over-downward` : ` drop-over-upward`,
        };
      },
      drop: (item: { index: number }) => {
        moveRow(item.index, index);
      },
    });

    const [, drag, preview] = useDrag({
      type,
      item: { index },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    });

    preview(drop(ref));

    return (
      <div
        key={label}
        ref={ref}
        className={`item-wrapper ${isOver ? dropClassName : ""}`}
      >
        <span
          className={`label ${
            primaryKeys.includes(label) ? "primary-key" : ""
          }`}
        >
          {schema[label] ? (
            <TypeRenderer
              domain={schema[label].domain}
              formula={schema[label].type === "FORMULA"}
            />
          ) : (
            <div
              style={{ height: 14, display: "inline-block", marginRight: 3 }}
            >
              <Emoji emoji={":warning:"} size={14} />
            </div>
          )}{" "}
          {label}{" "}
          {primaryKeys.includes(label) ? (
            <span style={{ color: "gray", paddingLeft: 5 }}>
              <Tooltip title="Primary keys should always be included in drills">
                <QuestionCircleOutlined />
              </Tooltip>
            </span>
          ) : undefined}
          {!schema[label] ? (
            <span style={{ color: "gray", paddingLeft: 5 }}>
              <Tooltip title="This column does not exist anymore. You should probably remove it from the drill.">
                <QuestionCircleOutlined />
              </Tooltip>
            </span>
          ) : undefined}
        </span>
        {index !== -1 && (
          <span className="drag-icon" ref={drag}>
            <MenuOutlined />
          </span>
        )}
      </div>
    );
  };

  const moveRow = async (dragIndex, hoverIndex) => {
    const clonedList = targetKeys;
    const el = clonedList.splice(dragIndex, 1)[0];
    clonedList.splice(hoverIndex, 0, el);
    onChange(clonedList);
  };

  return (
    <>
      <div className="tool-bar-element" onClick={() => setVisible(true)}>
        {" "}
        <ZoomInOutlined /> Drills
      </div>
      <Modal
        title="Configure your drill down"
        className="drill-modal"
        open={visible}
        width={850}
        onCancel={() => {
          setLoading(false);
          setVisible(false);
          resetState();
        }}
        cancelButtonProps={{
          disabled: loading ? true : false,
        }}
        closable={loading ? false : true}
        maskClosable={loading ? false : true}
        confirmLoading={loading}
        onOk={() => {
          setLoading(true);
          return onUpdate(targetKeys).then(() => {
            setLoading(false);
            setVisible(false);
          });
        }}
        destroyOnClose
      >
        <div>
          <Text>
            Select the columns you want to display in your drill down. It is
            recommended to <b>not select more than 8 columns</b> to make sure
            the drill stays readable.
          </Text>
          {disabled ? (
            <div style={{ padding: "56px 0" }}>
              <Loading />
            </div>
          ) : (
            <DndProvider backend={HTML5Backend}>
              <Transfer
                dataSource={dataSource}
                titles={["Available columns", "Drill columns"]}
                showSearch
                filterOption={filterOption}
                targetKeys={targetKeys}
                selectedKeys={selectedKeys}
                onChange={onChange}
                onSelectChange={onSelectChange}
                render={(item) => (
                  <DraggableItem
                    index={targetKeys.findIndex((key) => key === item.key)}
                    label={item.key}
                    moveRow={moveRow}
                  />
                )}
                style={{ marginTop: 20 }}
                listStyle={{
                  width: 300,
                  height: 345,
                  flex: 1,
                }}
              />
            </DndProvider>
          )}
        </div>
      </Modal>
    </>
  );
}
