import type { Query } from "@cubejs-client/core";
import { Col, Row, Space, Typography } from "antd";
import cuid from "cuid";
import _ from "lodash";
import * as React from "react";
import type { ChartOption } from "../../../containers/chart-options/ChartOptions";
import type { InjectedOrgProps } from "../../../containers/orgs/WithOrg";
import WithOrg from "../../../containers/orgs/WithOrg";
import type { UserLocale } from "../../../interfaces/user";
import { compose } from "../../compose/WlyCompose";
import type { IConditionalFormatterRule } from "../../conditional-formatting/domain";
import { getFormatFromRules } from "../../conditional-formatting/domain";
import type { IComparisonPeriod } from "../../measures/comparison-selector/ComparisonSelector";
import type { Formatter } from "../domain";
import SparkLine from "../sparkline/Sparkline";
import { customFormatter } from "../utils/optionsHelper";
import "./MetricChart.scss";

type CanDrill = (key: "current" | "previous") => Query | null;

interface IMetricProps {
  config: Array<{
    name: string;
    canDrill: CanDrill;
    sparkline: number[];
    color: string;
    data: {
      current: string;
      previous?: string;
    };
    formatter: Formatter;
    conditionalFormatting?: IConditionalFormatterRule[];
    invertedComparison?: boolean;
    comparisonPeriod?: IComparisonPeriod;
  }>;
  onDrill?: (query: Query) => void;
  width: number | undefined;
  height: number | undefined;
  chartOptions?: ChartOption;
  locale: UserLocale;
}

type Props = IMetricProps & InjectedOrgProps;

class Metric extends React.Component<Props> {
  id: string = cuid();
  sparklineContainerRef: React.RefObject<HTMLDivElement>;

  constructor(props: Props) {
    super(props);
    this.sparklineContainerRef = React.createRef<HTMLDivElement>();
  }

  shouldComponentUpdate(nextProps: Readonly<Props>): boolean {
    if (!_.isEqual(this.props.width, nextProps.width)) return true;
    if (!_.isEqual(this.props.height, nextProps.height)) return true;
    if (!_.isEqual(this.props.chartOptions, nextProps.chartOptions))
      return true;
    return false;
  }

  public render() {
    const { config, width, height, chartOptions, locale, org } = this.props;

    const gutter = 18;

    const parentWidth =
      document.getElementById(this.id) &&
      document.getElementById(this.id)!.parentElement
        ? document.getElementById(this.id)!.parentElement!.clientWidth
        : document.body.clientWidth;
    const elWith = (width || parentWidth) / config.length - gutter / 2;

    return (
      <div className="metric-chart">
        <Row className="align" gutter={[gutter, gutter]} id={this.id}>
          {config.map((c, i) => {
            const metricFontSize = chartOptions?.["font-size"]
              ? +chartOptions["font-size"]
              : 40;

            const hasSparkline =
              c.sparkline.length > 0 && chartOptions?.["show-sparkline"];
            const hasTitle = config.length > 1;
            const hasComparison =
              c.data.previous !== undefined && c.data.previous !== null;

            let previousResult: number;
            if (hasComparison) {
              previousResult = parseFloat(c.data.previous);
            }

            const currentResult = parseFloat(c.data.current);
            const currValue = customFormatter(
              currentResult,
              locale,
              c.formatter
            );
            const formatting = getFormatFromRules(
              org,
              c.conditionalFormatting,
              currentResult
            );
            const fontColor =
              (formatting.fontColor || formatting.paletteColor) ?? "#5D6671";
            const displayValue = formatting.overrideValue?.value
              ? formatting.overrideValue.value
              : currValue;

            const currentDrill = c.canDrill("current");
            const previousDrill = c.canDrill("previous");

            const getComparisonString = () => {
              switch (c.comparisonPeriod) {
                case "day":
                  return "in prev. day";
                case "week":
                  return "in prev. day";
                case "month":
                  return "in prev. month";
                case "quarter":
                  return "in prev. quarter";
                case "year":
                  return "in prev. year";
                default:
                  return "in prev. period";
              }
            };

            const computeDifference = () => {
              const diff = +c.data.current - previousResult;
              const computeClass = () => {
                if (diff === 0) return "neutral";
                if (diff <= 0) {
                  if (c.invertedComparison) {
                    return "up";
                  }
                  return "down";
                }
                if (c.invertedComparison) {
                  return "down";
                }
                return "up";
              };
              return {
                color: computeClass(),
                arrow: diff <= 0 ? "down" : "up",
                percentage: Math.round((diff / previousResult) * 100) + "%",
              };
            };

            return (
              <Col span={24 / config.length} key={i}>
                <div
                  className={
                    "metric-container" + (!hasSparkline ? " centered" : "")
                  }
                >
                  <div className="metric-content-container">
                    <div className="measure-container">
                      <Space align="baseline">
                        <div
                          className={`measure-value ${
                            currentDrill && this.props.onDrill
                              ? "clickable"
                              : ""
                          }`}
                          onClick={
                            currentDrill && this.props.onDrill
                              ? () => this.props.onDrill(currentDrill)
                              : undefined
                          }
                          style={{
                            fontSize: metricFontSize,
                            color: fontColor,
                          }}
                        >
                          {displayValue}
                        </div>
                      </Space>
                    </div>
                    {hasTitle && <div className="measure-name">{c.name}</div>}
                    {c.data.previous ? (
                      <div
                        className={`${
                          previousDrill && this.props.onDrill ? "clickable" : ""
                        }`}
                        onClick={
                          previousDrill && this.props.onDrill
                            ? () => this.props.onDrill(previousDrill)
                            : undefined
                        }
                      >
                        <Space size={4}>
                          <div
                            style={{
                              color:
                                computeDifference().color === "up"
                                  ? "#52c41a"
                                  : computeDifference().color === "down"
                                  ? "#EB2F5C"
                                  : "#979797",
                            }}
                          >
                            {computeDifference().arrow === "up" && "+"}
                            {computeDifference().percentage}
                          </div>
                        </Space>
                        <Typography.Text
                          style={{
                            fontSize: 13,
                            color: "#979797",
                          }}
                        >
                          {" · "}was{" "}
                          {customFormatter(
                            c.data?.previous,
                            locale,
                            c.formatter
                          )}{" "}
                          {getComparisonString()}
                        </Typography.Text>
                      </div>
                    ) : null}
                  </div>
                  {hasSparkline && (
                    <div
                      ref={this.sparklineContainerRef}
                      style={{
                        flex: 1,
                        overflow: "clip",
                      }}
                    />
                  )}
                  {hasSparkline && (
                    <div className="sparkline-container">
                      <SparkLine
                        data={c.sparkline}
                        color={c.color}
                        width={elWith}
                        height={Math.min(
                          height -
                            metricFontSize * 1.1 -
                            6 -
                            (hasTitle ? 22 + 6 : 0) -
                            (hasComparison ? 22 + 6 : 0),
                          elWith * 0.75
                        )}
                      />
                    </div>
                  )}
                </div>
              </Col>
            );
          })}
        </Row>
      </div>
    );
  }
}

export default compose<Props, IMetricProps>(WithOrg)(Metric);
