import { Form, Select, Space } from "antd";
import type { FormInstance } from "antd/lib";
import { CalculatedMetricFormulaEditor } from "../../../../../../../components/formula/calculated-metric/CalculatedMetricFormulaEditor";
import type { IObject } from "../../../../../../../interfaces/object";
import { ChartOptionCollapse } from "../../../../../../chart-options/components/ChartOptionCollapse";
import ChartOptionLine from "../../../../../../chart-options/components/ChartOptionLine";
import { getObjectColumns } from "../../../../object/domain";
import type { IRecord } from "../../../domain";
import type { IWidget } from "../../domain";
import type { IWidgetAlertConfig } from "./domain";

interface AlertWidgetEditorProps {
  widget: IWidget;
  object: IObject;
  record: IRecord;
  conf: IWidgetAlertConfig;
  form: FormInstance;
}

export function AlertWidgetEditor(props: AlertWidgetEditorProps) {
  const { widget, object, record, conf, form } = props;

  const objectColumns = getObjectColumns(object).filter(
    (o) => !o.data.key.endsWith(".label")
  );

  return (
    <>
      <ChartOptionCollapse dark title="Alert Configuration">
        <Space size={"middle"} direction="vertical" style={{ width: "100%" }}>
          <Form.Item noStyle name={["config", "text"]} label="Text">
            <CalculatedMetricFormulaEditor
              columnsSuggestions={objectColumns.map((oc) => ({
                key: `{{ ${oc.data.key.replace(".", "-")} }}`,
                label: oc.data.label,
              }))}
            />
          </Form.Item>
          <ChartOptionLine
            items={[
              {
                content: <div>Type</div>,
                flex: `0 auto`,
              },
              {
                content: (
                  <Form.Item noStyle name={["config", "color"]}>
                    <Select style={{ width: "100%" }}>
                      <Select.Option value={"success"}>Success</Select.Option>
                      <Select.Option value={"warning"}>Warning</Select.Option>
                      <Select.Option value={"danger"}>Danger</Select.Option>
                      <Select.Option value={"neutral"}>Neutral</Select.Option>
                    </Select>
                  </Form.Item>
                ),
                flex: "1",
              },
            ]}
          />
        </Space>
      </ChartOptionCollapse>
    </>
  );
}
