import type { CollapseProps } from "antd";
import { Card, Collapse, List, Modal, Tag } from "antd";
import { inject, observer } from "mobx-react";
import { compose } from "../../../../../components/compose/WlyCompose";
import Feednack from "../../../../../components/layout/feedback/feedback";
import Loading from "../../../../../components/layout/feedback/loading";
import type { WorkspaceDatatoreProps } from "../../../../../store/dataStores/workspace/workspaceDatastore";
import GraphQl from "../../../../graphql/graphql";
import type { InjectedOrgProps } from "../../../../orgs/WithOrg";
import WithOrg from "../../../../orgs/WithOrg";
import { COLOR_DOWN, COLOR_UP } from "../../layout/domain";
import "../settingsModal.scss";
import { ERROR_GQL } from "./domain";

interface IStatusPageModalProps {
  open: boolean;
  onClose: () => void;
}

type Props = IStatusPageModalProps & InjectedOrgProps & WorkspaceDatatoreProps;

function StatusPageModal(props: Props) {
  const { open, onClose, org, warehouses, workspaceDatastore } = props;

  return (
    <Modal
      maskClosable={false}
      closable={true}
      width={"50%"}
      centered
      className="settings-modal"
      destroyOnClose={true}
      styles={{
        body: {
          height: "100%",
          maxHeight: "100%",
          overflowX: "hidden",
          overflowY: "auto",
        },
      }}
      title={"Status page"}
      onCancel={onClose}
      footer={null}
      open={open}
    >
      <GraphQl<{
        allSources: Array<{ id: string }>;
        allDestinations: Array<{ id: string }>;
        allExplorations: Array<{ id: string }>;
      }>
        query={ERROR_GQL}
        variables={{ orgId: org.id }}
      >
        {(data) => {
          if (workspaceDatastore.data.status !== "success") {
            return <Feednack>Please load the workspace first</Feednack>;
          }

          if (data.status === "initial" || data.status === "loading") {
            return (
              <Feednack>
                <Loading />
              </Feednack>
            );
          }

          if (data.status === "error") {
            return (
              <Feednack>
                <div>{data.error.message}</div>
              </Feednack>
            );
          }

          const renderErrors = (errors: number, items: number) => {
            if (!errors) {
              return (
                <Tag bordered={false} color="green">
                  Operational
                </Tag>
              );
            }
            return (
              <Tag bordered={false} color="red">
                {errors} / {items} in error
              </Tag>
            );
          };

          const items: CollapseProps["items"] = [
            {
              key: "1",
              label: (
                <div style={{ display: "flex" }}>
                  <div style={{ flex: 1 }}>Sources</div>
                  <div style={{ flex: `0 0 auto` }}>
                    {renderErrors(
                      data.data.allSources.length,
                      workspaceDatastore.data.data.allSources.length
                    )}
                  </div>
                </div>
              ),
              children: (
                <List
                  dataSource={workspaceDatastore.data.data.allSources}
                  split={false}
                  size={"small"}
                  renderItem={(item) => {
                    const hasError = data.data.allSources.find(
                      (ad) => ad.id === item.id
                    );
                    return (
                      <List.Item style={{ padding: 0, marginBottom: 8 }}>
                        <Card
                          size="small"
                          style={{ width: "100%" }}
                          bodyStyle={{ padding: 8, display: "flex" }}
                        >
                          <div
                            style={{
                              flex: `0 0 auto`,
                              color: hasError ? COLOR_DOWN : COLOR_UP,
                              fontSize: "2em",
                              lineHeight: "20px",
                              marginRight: 8,
                            }}
                          >
                            &#8226;
                          </div>
                          <div style={{ flex: 1 }}>{item.name}</div>
                          {/* <div style={{ flex: `0 0 auto` }}>View</div> */}
                        </Card>
                      </List.Item>
                    );
                  }}
                />
              ),
            },
            {
              key: "2",
              label: (
                <div style={{ display: "flex" }}>
                  <div style={{ flex: 1 }}>Persistence Jobs</div>
                  <div style={{ flex: `0 0 auto` }}>
                    {renderErrors(
                      data.data.allDestinations.length,
                      warehouses.length
                    )}
                  </div>
                </div>
              ),
              children: (
                <List
                  dataSource={warehouses}
                  split={false}
                  size={"small"}
                  renderItem={(item) => {
                    const hasError = data.data.allDestinations.find(
                      (ad) => ad.id === item.id
                    );
                    return (
                      <List.Item style={{ padding: 0, marginBottom: 8 }}>
                        <Card
                          size="small"
                          style={{ width: "100%" }}
                          bodyStyle={{ padding: 8, display: "flex" }}
                        >
                          <div
                            style={{
                              flex: `0 0 auto`,
                              color: hasError ? COLOR_DOWN : COLOR_UP,
                              fontSize: "2em",
                              lineHeight: "20px",
                              marginRight: 8,
                            }}
                          >
                            &#8226;
                          </div>
                          <div style={{ flex: 1 }}>{item.name}</div>
                          {/* <div style={{ flex: `0 0 auto` }}>View</div> */}
                        </Card>
                      </List.Item>
                    );
                  }}
                />
              ),
            },
            {
              key: "3",
              label: (
                <div style={{ display: "flex" }}>
                  <div style={{ flex: 1 }}>Explorations</div>
                  <div style={{ flex: `0 0 auto` }}>
                    {renderErrors(
                      data.data.allExplorations.length,
                      workspaceDatastore.data.data.allExplorations.length
                    )}
                  </div>
                </div>
              ),
              children: (
                <List
                  dataSource={workspaceDatastore.data.data.allExplorations}
                  split={false}
                  size={"small"}
                  renderItem={(item) => {
                    const hasError = data.data.allExplorations.find(
                      (ad) => ad.id === item.id
                    );
                    return (
                      <List.Item style={{ padding: 0, marginBottom: 8 }}>
                        <Card
                          size="small"
                          style={{ width: "100%" }}
                          bodyStyle={{ padding: 8, display: "flex" }}
                        >
                          <div
                            style={{
                              flex: `0 0 auto`,
                              color: hasError ? COLOR_DOWN : COLOR_UP,
                              fontSize: "2em",
                              lineHeight: "20px",
                              marginRight: 8,
                            }}
                          >
                            &#8226;
                          </div>
                          <div style={{ flex: 1 }}>{item.name}</div>
                          {/* <div style={{ flex: `0 0 auto` }}>View</div> */}
                        </Card>
                      </List.Item>
                    );
                  }}
                />
              ),
            },
          ];

          return (
            <div>
              <Collapse items={items} />
              <div style={{ height: 44 }} />
            </div>
          );
        }}
      </GraphQl>
    </Modal>
  );
}

export default compose<Props, IStatusPageModalProps>(
  WithOrg,
  inject("workspaceDatastore"),
  observer
)(StatusPageModal);
