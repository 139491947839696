import { gql } from "@apollo/client";
import type { QueryOptions } from "../../../../../../../../components/hooks/query/useGQLQuery";
import { useGQLQuery } from "../../../../../../../../components/hooks/query/useGQLQuery";
import { OBJECT_PRESET_FILTER_FRAGMENT } from "../../../../../../../../fragments/object";
import type { IObjectPresetFilter } from "../../../../../../../../interfaces/object";

type QueryData = {
  allObjectPresetFilters: IObjectPresetFilter[];
};

type QueryVariables = {
  objectId: string;
  orgId: string;
};

const ObjectPresetFiltersQuery = gql`
  ${OBJECT_PRESET_FILTER_FRAGMENT}

  query allObjectPresetFilters($orgId: ID!, $objectId: ID!) {
    allObjectPresetFilters(
      where: {
        isDeleted: false
        org: { id: $orgId }
        object: { id: $objectId }
      }
      sortBy: order_ASC
    ) {
      ...ObjectPresetFilterFragment
    }
  }
`;

export const useGetObjectPresetFilters = (
  options: QueryOptions<QueryData, QueryVariables>
) => {
  return useGQLQuery<QueryData, QueryVariables>(
    ObjectPresetFiltersQuery,
    options
  );
};
