import ReactDOM from "react-dom";

interface IFloatingLayoutBreadcrumbProps {
  children?: React.ReactNode;
}

export function FloatingLayoutBreadcrumb(
  props: IFloatingLayoutBreadcrumbProps
) {
  const getElement = () => {
    const els = document.getElementsByClassName(
      "floating-layout-header-right-left-breadcrumb"
    );
    if (els && els.length) {
      return els[0];
    }
  };

  const el = getElement();
  if (el) {
    return ReactDOM.createPortal(props.children, el);
  }
  return null;
}
