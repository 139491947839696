import { Input, InputNumber, Select, Switch } from "antd";
import type { IUserAttributeMetaType } from "../../../interfaces/org";
import {
  convertStringToWlyDatePickerValue,
  convertWlyDatePickerValueToString,
  WlyDatePicker,
} from "../../reports/view/filters/date-filter/WlyDatePicker";

interface IUserAttributeRendererProps {
  type: IUserAttributeMetaType;
  multi: boolean;
  options?: Array<{
    value: string;
    label: string;
  }>;
  value?: string[];
  defaultValue?: string[];
  onChange?: (value: string[]) => void;
}

const RenderMultiOptionsStringNumber = (props: {
  options: Array<{
    value: string;
    label: string;
  }>;
  value?: string[];
  defaultValue?: string[];
  onChange?: (value: string[]) => void;
}) => {
  const { options, value, defaultValue, onChange } = props;
  return (
    <Select
      mode="tags"
      style={{ width: "100%" }}
      value={value}
      defaultValue={defaultValue}
      onChange={onChange}
      tokenSeparators={[","]}
      options={options}
    />
  );
};

const RenderMultiNoOptionsStringNumber = (props: {
  value?: string[];
  defaultValue?: string[];
  onChange?: (value: string[]) => void;
}) => {
  const { value, defaultValue, onChange } = props;
  return (
    <Select
      mode="tags"
      style={{ width: "100%" }}
      value={value}
      defaultValue={defaultValue}
      onChange={onChange}
      tokenSeparators={[","]}
    />
  );
};

const RenderSingleOptionsStringNumber = (props: {
  options: Array<{
    value: string;
    label: string;
  }>;
  value?: string[];
  defaultValue?: string[];
  onChange?: (value: string[]) => void;
}) => {
  const { options, value, defaultValue, onChange } = props;
  const [v] = value ? value : [];
  const [d] = defaultValue ? defaultValue : [];
  return (
    <Select
      style={{ width: "100%" }}
      value={v}
      defaultValue={d}
      onChange={(e) => onChange([e])}
      options={options}
    />
  );
};

export function UserAttributeRenderer(props: IUserAttributeRendererProps) {
  const { type, multi, options, value, defaultValue, onChange } = props;

  if (multi) {
    // only for number and string;
    if (options) {
      if (type === "STRING" || type === "NUMERIC") {
        return (
          <RenderMultiOptionsStringNumber
            options={options}
            value={value}
            defaultValue={defaultValue}
            onChange={onChange}
          />
        );
      } else {
        return <div>Not supported multi type for {type}</div>;
      }
    } else {
      if (type === "STRING" || type === "NUMERIC") {
        return (
          <RenderMultiNoOptionsStringNumber
            value={value}
            defaultValue={defaultValue}
            onChange={onChange}
          />
        );
      } else {
        return <div>Not supported multi type with options for {type}</div>;
      }
    }
  }

  if (options) {
    if (type === "STRING" || type === "NUMERIC") {
      return (
        <RenderSingleOptionsStringNumber
          options={options}
          value={value}
          defaultValue={defaultValue}
          onChange={onChange}
        />
      );
    } else {
      return <div>Not supported multi type with no options for {type}</div>;
    }
  }

  if (type === "STRING") {
    const [v] = value ? value : [];
    const [d] = defaultValue ? defaultValue : [];
    return (
      <Input
        value={v}
        defaultValue={d}
        onChange={(e) => onChange([e.target.value])}
      />
    );
  } else if (type === "NUMERIC") {
    const [v] = value ? value : [];
    const [d] = defaultValue ? defaultValue : [];
    return (
      <InputNumber
        value={v}
        defaultValue={d}
        onChange={(e) => onChange([e.toString()])}
      />
    );
  } else if (type === "BOOLEAN") {
    const [v] = value ? value : [];
    const [d] = defaultValue ? defaultValue : [];
    return (
      <Switch
        checked={v === "true" ? true : false}
        defaultChecked={d === "true" ? true : false}
        onChange={(e) => onChange(e ? ["true"] : ["false"])}
      />
    );
  } else if (type === "TIME") {
    const [v] = value ? value : [];
    return (
      <WlyDatePicker
        value={convertStringToWlyDatePickerValue(v)}
        onChange={(a) => onChange([convertWlyDatePickerValueToString(a)])}
      />
    );
  }
  return <div>Not supported type for {type}</div>;
}
