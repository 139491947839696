import DataNodeWidget from "./DataNodeWidget";
import { DataNodeModel } from "./DataNodeModel";
import * as React from "react";
import type {
  GenerateWidgetEvent,
  GenerateModelEvent,
} from "@projectstorm/react-canvas-core";
import { AbstractReactFactory } from "@projectstorm/react-canvas-core";
import type { DiagramEngine } from "@projectstorm/react-diagrams-core";

export class DataNodeFactory extends AbstractReactFactory<
  DataNodeModel,
  DiagramEngine
> {
  constructor() {
    super("data");
  }

  generateReactWidget(event: GenerateWidgetEvent<DataNodeModel>): JSX.Element {
    return <DataNodeWidget engine={this.engine} size={50} node={event.model} />;
  }

  generateModel(event: GenerateModelEvent) {
    return new DataNodeModel();
  }
}
