import { ExclamationCircleOutlined, MoreOutlined } from "@ant-design/icons";
import type { MenuProps } from "antd";
import { Button, Col, Drawer, Dropdown, Row, Tag } from "antd";
import type { ColumnProps } from "antd/lib/table";
import { inject, observer } from "mobx-react";
import * as React from "react";
import type { RouteComponentProps } from "react-router";
import { withRouter } from "react-router";
import type { InjectedAntUtilsProps } from "../../../components/ant-utils/withAntUtils";
import { withAntUtils } from "../../../components/ant-utils/withAntUtils";
import { compose } from "../../../components/compose/WlyCompose";
import Aligner from "../../../components/layout/aligner/Aligner";
import CardTable from "../../../components/table/CardTable";
import type { IUserAttributeMeta } from "../../../interfaces/org";
import { IUserRoleType } from "../../../interfaces/user";
import GraphQLService from "../../../services/graphql/GraphQLService";
import type { UserStoreProps } from "../../../store/userStore";
import type { InjectedOrgProps } from "../../orgs/WithOrg";
import WithOrg from "../../orgs/WithOrg";
import HasRoleAccess, {
  hasRoleAccessBoolean,
} from "../../user-settings/HasRoleAccess";
import UserAttributesForm from "./UserAttributesForm";

interface IUserAttributeMetaTableProps {
  userAttributeMetas: IUserAttributeMeta[];
}

interface IState {
  visible: boolean;
  selectedMeta?: IUserAttributeMeta;
}

type Props = IUserAttributeMetaTableProps &
  RouteComponentProps<{ organizationSlug: string }> &
  InjectedOrgProps &
  UserStoreProps &
  InjectedAntUtilsProps;

class UserAttributeMetaTable extends React.Component<Props, IState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      visible: false,
    };
  }

  public showDrawer = () => {
    this.setState({ visible: true });
  };

  public hideDrawer = () => {
    this.setState({ visible: false, selectedMeta: undefined });
  };

  public shouldRefreshConnectedUser = (): Promise<void> => {
    return this.props.userStore.getUser();
  };

  public delete = (attribute: IUserAttributeMeta) => {
    const {
      history,
      match: {
        params: { organizationSlug },
      },
      antUtils,
    } = this.props;

    return antUtils.modal.confirm({
      title: `Are your sure you want to delete this attribute?`,
      icon: <ExclamationCircleOutlined />,
      content: `By deleting this attribute, any filter using this attribute will stop working. Do you wish to proceed?`,
      okText: "Delete",
      okButtonProps: {
        danger: true,
      },
      cancelText: "Cancel",
      onOk: async () => {
        try {
          await GraphQLService(
            `
          mutation deleteAttribute(
            $attributeId: ID!,
          ) {
            updateUserAttributeMeta(id: $attributeId, data: {deleted: true}) {
              id
            }
          }`,
            { attributeId: attribute.id }
          );
          await this.shouldRefreshConnectedUser();
          antUtils.message.success("Attribute successfully deleted");
        } catch (err) {
          antUtils.message.error("An unexpected error happened, please retry");
        }
      },
      onCancel() {
        return null;
      },
    });
  };

  renderType = (type: string) => {
    switch (type) {
      case "STRING":
        return "String";
      case "BOOLEAN":
        return "Boolean";
      case "NUMBER":
        return "Number";
      case "TIME":
        return "Date";
      default:
        return type;
    }
  };

  public generateColumns = (
    connectedUserRoleType: IUserRoleType
  ): ColumnProps<IUserAttributeMeta>[] => [
    {
      title: "Attribute",
      dataIndex: "label",
      key: "label",
      render: (v, s) => {
        return (
          <div>
            <div>{s.label}</div>
            <div>
              <Tag>{s.technicalName}</Tag> -{" "}
              <Tag color="green">{this.renderType(s.type)}</Tag>
            </div>
          </div>
        );
      },
    },
    ...(connectedUserRoleType === IUserRoleType.ADMIN_BUILDER
      ? [
          {
            title: "Actions",
            key: "Actions",
            align: "right" as const,
            render: (v, s: IUserAttributeMeta) => {
              const meta = s;

              const menu: MenuProps = {
                items: [
                  {
                    key: `edit-meta-${meta.id}`,
                    onClick: () =>
                      this.setState({
                        visible: true,
                        selectedMeta: meta,
                      }),
                    label: "Edit",
                  },
                  {
                    key: `delete-meta-${meta.id}`,
                    onClick: () => this.delete(meta),
                    label: "Delete",
                    danger: true,
                  },
                ],
              };

              return (
                <HasRoleAccess accessLevel={IUserRoleType.ADMIN_BUILDER}>
                  <Dropdown
                    trigger={["click"]}
                    placement="bottomRight"
                    menu={menu}
                  >
                    <Button
                      type="text"
                      shape="circle"
                      icon={<MoreOutlined />}
                    />
                  </Dropdown>
                </HasRoleAccess>
              );
            },
          },
        ]
      : []),
  ];

  public render() {
    const {
      userAttributeMetas,
      user: connectedUser,
      org,
      role: connectedUserRole,
    } = this.props;
    return (
      <Aligner>
        <Row justify="center" className="group-management">
          <Col xs={24} sm={24} md={20} lg={20} xl={20}>
            <CardTable<IUserAttributeMeta>
              cardTitle={<div className="title">User Attributes</div>}
              actionButtons={
                hasRoleAccessBoolean(
                  IUserRoleType.ADMIN_BUILDER,
                  connectedUser,
                  org.id
                ) ? (
                  <Button onClick={this.showDrawer} type="primary">
                    Create new Attribute
                  </Button>
                ) : undefined
              }
              rowKey="id"
              dataSource={userAttributeMetas}
              columns={this.generateColumns(connectedUserRole.role)}
              pagination={{
                size: "small",
                style: { display: "none" },
                defaultPageSize: 1000,
              }}
            />
          </Col>
          <Drawer
            width={"35%"}
            open={this.state.visible}
            closable={true}
            styles={{ body: { padding: "0" } }}
            keyboard={false}
            maskClosable={false}
            destroyOnClose={true}
            title={
              this.state.selectedMeta
                ? "Edit User Attribute"
                : "Create User Attribute"
            }
            onClose={() =>
              this.setState({ visible: false, selectedMeta: null })
            }
          >
            <UserAttributesForm
              onClose={() =>
                this.setState({ visible: false, selectedMeta: null })
              }
              onSubmit={this.shouldRefreshConnectedUser}
              userAttributeMeta={this.state.selectedMeta}
              visible={this.state.visible}
            />
          </Drawer>
        </Row>
      </Aligner>
    );
  }
}

export default compose<Props, IUserAttributeMetaTableProps>(
  inject("userStore"),
  observer,
  withRouter,
  WithOrg,
  withAntUtils
)(UserAttributeMetaTable);
