import { CloseOutlined } from "@ant-design/icons";
import { Button, Drawer, Typography } from "antd";
import type { IExploration } from "../../../../../interfaces/explorations";
import DashboardEditAddChartExploration from "./DashboardEditAddChartExploration";

interface IDashboarEditAddChartDrawerProps {
  open: boolean;
  onSelectExploration: (exploration: IExploration) => void;
  setOpen: (open: boolean) => void;
}

export function DashboarEditAddChartDrawer(
  props: IDashboarEditAddChartDrawerProps
) {
  const { open, onSelectExploration, setOpen } = props;

  return (
    <Drawer
      title={<>Add a chart</>}
      destroyOnClose
      open={open}
      onClose={() => setOpen(false)}
      placement="left"
      style={{
        borderRight: "1px solid #F3F3F3",
        boxShadow: "none",
      }}
      rootStyle={{
        left: 49,
        top: 64,
        boxShadow: "none",
        zIndex: 12,
      }}
      styles={{
        header: {
          display: "none",
        },
        body: {
          padding: 0,
          boxShadow: "none",
          background: "#fbfbfb",
        },
        mask: {
          background: "none",
        },
      }}
      contentWrapperStyle={{
        boxShadow: "none",
      }}
    >
      <div
        style={{
          padding: "10px",
          background: "#F9F8F8",
          borderBottom: "1px solid #E6E6E6",
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <div style={{ flex: 1 }}>
            <Typography.Text strong style={{ fontSize: 16 }}>
              Add a chart from an exploration
            </Typography.Text>
          </div>
          <div style={{ flex: 0 }}>
            <Button
              type="text"
              shape="circle"
              onClick={() => setOpen(false)}
              icon={<CloseOutlined />}
            />
          </div>
        </div>
        {/* <Segmented block options={["Exploration", "Question", "Report"]} /> */}
      </div>
      <div style={{ padding: "10px" }}>
        <DashboardEditAddChartExploration
          onSelectExploration={onSelectExploration}
        />
      </div>
    </Drawer>
  );
}
