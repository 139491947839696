import type {
  Completion,
  CompletionResult,
  CompletionSource,
} from "@codemirror/autocomplete";
import { autocompletion, CompletionContext } from "@codemirror/autocomplete";
import { keywordCompletionSource, StandardSQL } from "@codemirror/lang-sql";
import { EditorState } from "@codemirror/state";
import _ from "lodash";
import { snakeCase } from "snake-case";
import bigquery from "./languages/bigquery.json";
import postgres from "./languages/postgres.json";
import snowflake from "./languages/snowflake.json";

export type SQLAutocompleteItem = {
  label: string;
  apply: string;
  icon: string;
  type: string;
  detail?: string;
  info?: any;
  id?: string;
  whalyType?: "raw" | "source";
  datasetId?: string;
  isUsed?: boolean;
  columns?: Array<{ name: string; type: string }>;
  isModel?: boolean;
  description?: string;
};

export const getWarehouseLanguageDefinition = (warehouseType: string) => {
  let source = {
    keywords: [],
    functions: [],
  };

  if (warehouseType === "bigquery") {
    source = bigquery;
  } else if (warehouseType === "snowflake") {
    source = snowflake;
  } else if (warehouseType === "postgres") {
    source = postgres;
  }
  return source;
};

export const functionToTooltip = (func: any) => {
  if (!func.args && !func.desc) return null;
  const root = document.createElement("span");
  const wrapper = document.createElement("div");
  wrapper.style.padding = "6px 0px";

  const titleWrapper = document.createElement("div");
  titleWrapper.style.display = "flex";

  const titleContainer = document.createElement("div");
  if (func.args) {
    titleContainer.innerText =
      (func.name as string).toLowerCase() + "(" + func.args + ")";
  } else {
    titleContainer.innerText = (func.name as string).toLowerCase();
  }
  titleContainer.style.flex = "1";
  titleContainer.style.fontWeight = "bold";

  const descriptionWrapper = document.createElement("div");
  descriptionWrapper.style.marginTop = "12px";
  descriptionWrapper.innerText = func.desc;

  root.appendChild(wrapper);
  wrapper.appendChild(titleWrapper);
  wrapper.appendChild(descriptionWrapper);

  if (func.url) {
    const docWrapper = document.createElement("div");
    docWrapper.style.marginTop = "12px";
    const link = document.createElement("a");
    link.href = func.url;
    link.target = "_blank";
    link.innerText = "Docs";
    link.style.textDecoration = "underline";
    link.style.color = "#1777ff";
    docWrapper.appendChild(link);
    wrapper.appendChild(docWrapper);
  }

  titleWrapper.appendChild(titleContainer);
  return root;
};

export const datasetIconToHTMLElement = (
  icon: string,
  isModel: boolean,
  size: number
) => {
  if (isModel) {
    const iconSvg = document.createElementNS(
      "http://www.w3.org/2000/svg",
      "svg"
    );
    iconSvg.setAttribute("viewBox", "64 64 896 896");
    iconSvg.setAttribute("width", "1em");
    iconSvg.setAttribute("height", "1em");
    iconSvg.setAttribute("fill", "currentColor");
    const iconPath = document.createElementNS(
      "http://www.w3.org/2000/svg",
      "path"
    );
    iconPath.setAttribute(
      "d",
      "M854.6 288.6L639.4 73.4c-6-6-14.1-9.4-22.6-9.4H192c-17.7 0-32 14.3-32 32v832c0 17.7 14.3 32 32 32h640c17.7 0 32-14.3 32-32V311.3c0-8.5-3.4-16.7-9.4-22.7zM790.2 326H602V137.8L790.2 326zm1.8 562H232V136h302v216a42 42 0 0042 42h216v494zM429 481.2c-1.9-4.4-6.2-7.2-11-7.2h-35c-6.6 0-12 5.4-12 12v272c0 6.6 5.4 12 12 12h27.1c6.6 0 12-5.4 12-12V582.1l66.8 150.2a12 12 0 0011 7.1H524c4.7 0 9-2.8 11-7.1l66.8-150.6V758c0 6.6 5.4 12 12 12H641c6.6 0 12-5.4 12-12V486c0-6.6-5.4-12-12-12h-34.7c-4.8 0-9.1 2.8-11 7.2l-83.1 191-83.2-191z"
    );
    iconSvg.appendChild(iconPath);
    return iconSvg;
  } else if (icon?.startsWith("http")) {
    const img = document.createElement("img");
    img.style.height = `${size}px`;
    img.src = icon;
    return img;
  } else if (icon?.startsWith(":")) {
    const ememoji = document.createElement("em-emoji");
    ememoji.setAttribute("size", size?.toString?.());
    ememoji.setAttribute("shortcodes", icon);
    return ememoji;
  } else {
    return null;
  }
};

const getInfo = (v: SQLAutocompleteItem) => (completion: Completion) => {
  const root = document.createElement("span");
  const wrapper = document.createElement("div");
  wrapper.style.padding = "6px 0px";

  const titleWrapper = document.createElement("div");
  titleWrapper.style.display = "flex";

  const imageContainer = document.createElement("div");
  imageContainer.style.marginRight = "6px";

  const titleContainer = document.createElement("div");
  titleContainer.innerText = v.label;
  titleContainer.style.flex = "1";
  titleContainer.style.fontWeight = "bold";

  const descriptionWrapper = document.createElement("div");
  descriptionWrapper.style.marginTop = "12px";
  if (v.description) {
    descriptionWrapper.innerText = v.description;
  } else {
    descriptionWrapper.innerText = "Empty description";
    descriptionWrapper.style.fontStyle = "italic";
  }

  root.appendChild(wrapper);
  wrapper.appendChild(titleWrapper);
  wrapper.appendChild(descriptionWrapper);

  const imageElement = datasetIconToHTMLElement(v.icon, v.isModel, 16);
  if (imageElement) {
    imageContainer.appendChild(imageElement);
    titleWrapper.appendChild(imageContainer);
  }
  titleWrapper.appendChild(titleContainer);
  return root;
};

const autocompleteItemsToTableCompletions = (
  autocompleteItems: SQLAutocompleteItem[]
): Completion[] => {
  const options: Completion[] = autocompleteItems.map((v) => {
    let label = v.label;
    if (!v.isModel) {
      let substring = v.label;
      if (v.label.indexOf(" (from source)")) {
        substring = v.label.split(" (from source)")[0];
      }
      label =
        snakeCase(substring.split(" · ")[0]) +
        "." +
        snakeCase(substring.split(" · ")[1]);
    } else {
      label = snakeCase(v.label);
    }
    let detail = "TABLE";
    if (!v.isModel && v.label.endsWith(" (from source)")) {
      detail = "FROM SOURCE";
    } else if (v.isModel) {
      detail = "MODEL";
    }

    return {
      label: label,
      apply: v.apply,
      detail: detail,
      info: getInfo(v),
      boost: v.label.endsWith(" (from source)") ? -1 : 0,
      type: "table",
    };
  });

  return options;
};

const autocompleteItemsToColumnCompletions = (
  autocompleteItems: SQLAutocompleteItem[]
): Completion[] => {
  const options: Completion[] = [];
  autocompleteItems
    .filter(
      (value) =>
        value.isUsed === true && value.columns && value.columns.length > 0
    )
    .forEach((v) => {
      v.columns.forEach((column) => {
        options.push({
          label: column.name,
          apply: column.name,
          detail: column.type,
          info: getInfo(v),
          type: "column",
        });
      });
    });
  return options;
};

export function sqlAutocomplete(
  suggestions: SQLAutocompleteItem[],
  warehouseType: string
) {
  const defaultSuggestions = keywordCompletionSource(StandardSQL);

  const tablesSuggestions: CompletionSource = (context: CompletionContext) => {
    let word = context.matchBefore(/[\w.]*/);
    if (word.from === word.to && !context.explicit) return null;

    return {
      from: word.from,
      options: autocompleteItemsToTableCompletions(suggestions),
    };
  };

  const columnsSuggestions: CompletionSource = (context: CompletionContext) => {
    let word = context.matchBefore(/[\w]*\.{0,1}/);
    if (word.from === word.to && !context.explicit) return null;

    return {
      from: word.from,
      options: autocompleteItemsToColumnCompletions(suggestions),
    };
  };

  const localSuggestions: CompletionSource = (context: CompletionContext) => {
    let word = context.matchBefore(/[\w]*\.{0,1}/);
    if (word.from === word.to && !context.explicit) return null;

    // get the default list of keywords for the current language
    const defaultKeywords = (
      defaultSuggestions(
        new CompletionContext(EditorState.create(), 0, true)
      ) as CompletionResult
    ).options.map((o) => o.label);

    const tablesSuggestions = autocompleteItemsToTableCompletions(
      suggestions
    ).map((s) => (s.apply as string).toLocaleLowerCase());

    const columnsSuggestions = autocompleteItemsToColumnCompletions(
      suggestions
    ).map((s) => (s.apply as string).toLocaleLowerCase());

    const warehouseSuggestions = _.uniq(
      _.concat(
        bigquery.keywords.map((k) => k.name.toLocaleLowerCase()),
        bigquery.functions.map((k) => k.name.toLocaleLowerCase()),
        snowflake.keywords.map((k) => k.name.toLocaleLowerCase()),
        snowflake.functions.map((k) => k.name.toLocaleLowerCase()),
        postgres.keywords.map((k) => k.name.toLocaleLowerCase()),
        postgres.functions.map((k) => k.name.toLocaleLowerCase())
      )
        .join(" ")
        .split(" ")
    );

    const localKeywords = _.uniq(
      context.state.doc
        .toString()
        .trim()
        // split words by whitespace, dot or comma
        .split(/[.,\s]+/)
        // exclude the word being typed
        .filter((w) => w !== word.text)
        // exclude words that don't start with a letter and that contains
        // something else than a letter, a number and - _
        .filter((w: string) => w.match(/^[a-z|A-Z]+(\w|-)+$/))
        // exclude words existing in the SQL default suggestions
        .filter((w) => defaultKeywords.indexOf(w.toLocaleLowerCase()) === -1)
        // exlude words that already exist in whaly suggestions
        .filter((w) => !tablesSuggestions.includes(w.toLocaleLowerCase()))
        .filter((w) => !columnsSuggestions.includes(w.toLocaleLowerCase()))
        // exlude words that already exist in wharehouse suggestions
        .filter((w) => !warehouseSuggestions.includes(w.toLocaleLowerCase()))
    );

    return {
      from: word.from,
      options: localKeywords.map((w) => {
        return {
          label: w,
          apply: w,
          detail: "LOCAL",
          type: "localkeyword",
          icon: "localkeyword",
        };
      }),
    };
  };

  const warehouseSuggestions: CompletionSource = (
    context: CompletionContext
  ) => {
    let word = context.matchBefore(/[\w]*/);
    if (word.from === word.to && !context.explicit) return null;

    const source = getWarehouseLanguageDefinition(warehouseType);

    return {
      from: word.from,
      options: _.concat(
        source.keywords.map((key) => {
          return {
            label: key.name.toLowerCase(),
            apply: key.name.toUpperCase(),
            type: "keyword",
            detail: "KEYWORD",
          };
        }),
        source.functions.map((func) => {
          return {
            label: func.name.toLowerCase(),
            apply: func.name.toUpperCase(),
            type: "function",
            detail: "FUNCTION",
            info: (completion: Completion) => functionToTooltip(func),
          };
        })
      ),
    };
  };

  const override = [localSuggestions, tablesSuggestions, columnsSuggestions];
  if (["snowflake", "bigquery", "postgres"].includes(warehouseType)) {
    override.push(warehouseSuggestions);
  } else {
    override.push(defaultSuggestions);
  }

  return autocompletion({
    override: override,
    closeOnBlur: false,
    icons: false,
    addToOptions: [
      {
        render: (completion: Completion, state: EditorState) => {
          const wrap = document.createElement("div");

          const node = document.createElement("div");
          node.style.width = "24px";
          node.style.height = "24px";
          node.style.display = "flex";
          node.style.alignItems = "center";
          node.style.paddingLeft = "4px";
          node.style.color = "#77849B";

          const iconSvg = document.createElementNS(
            "http://www.w3.org/2000/svg",
            "svg"
          );
          iconSvg.setAttribute("viewBox", "64 64 896 896");
          iconSvg.setAttribute("width", "1em");
          iconSvg.setAttribute("height", "1em");
          iconSvg.setAttribute("fill", "currentColor");

          // COLUMN: string
          if (completion.type === "column" && completion.detail === "STRING") {
            const iconPath = document.createElementNS(
              "http://www.w3.org/2000/svg",
              "path"
            );
            iconPath.setAttribute(
              "d",
              "M875.6 515.9c2.1.8 4.4-.3 5.2-2.4.2-.4.2-.9.2-1.4v-58.3c0-1.8-1.1-3.3-2.8-3.8-6-1.8-17.2-3-27.2-3-32.9 0-61.7 16.7-73.5 41.2v-28.6c0-4.4-3.6-8-8-8H717c-4.4 0-8 3.6-8 8V729c0 4.4 3.6 8 8 8h54.8c4.4 0 8-3.6 8-8V572.7c0-36.2 26.1-60.2 65.1-60.2 10.4.1 26.6 1.8 30.7 3.4zm-537-40.5l-54.7-12.6c-61.2-14.2-87.7-34.8-87.7-70.7 0-44.6 39.1-73.5 96.9-73.5 52.8 0 91.4 26.5 99.9 68.9h70C455.9 311.6 387.6 259 293.4 259c-103.3 0-171 55.5-171 139 0 68.6 38.6 109.5 122.2 128.5l61.6 14.3c63.6 14.9 91.6 37.1 91.6 75.1 0 44.1-43.5 75.2-102.5 75.2-60.6 0-104.5-27.2-112.8-70.5H111c7.2 79.9 75.6 130.4 179.1 130.4C402.3 751 471 695.2 471 605.3c0-70.2-38.6-108.5-132.4-129.9zM841 729a36 36 0 1072 0 36 36 0 10-72 0zM653 457.8h-51.4V396c0-4.4-3.6-8-8-8h-54.7c-4.4 0-8 3.6-8 8v61.8H495c-4.4 0-8 3.6-8 8v42.3c0 4.4 3.6 8 8 8h35.9v147.5c0 56.2 27.4 79.4 93.1 79.4 11.7 0 23.6-1.2 33.8-3.1 1.9-.3 3.2-2 3.2-3.9v-49.3c0-2.2-1.8-4-4-4h-.4c-4.9.5-6.2.6-8.3.8-4.1.3-7.8.5-12.6.5-24.1 0-34.1-10.3-34.1-35.6V516.1H653c4.4 0 8-3.6 8-8v-42.3c0-4.4-3.6-8-8-8z"
            );
            iconSvg.appendChild(iconPath);
          }
          // COLUMN: time
          else if (
            completion.type === "column" &&
            completion.detail === "TIME"
          ) {
            const iconPath1 = document.createElementNS(
              "http://www.w3.org/2000/svg",
              "path"
            );
            iconPath1.setAttribute(
              "d",
              "M945 412H689c-4.4 0-8 3.6-8 8v48c0 4.4 3.6 8 8 8h256c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8zM811 548H689c-4.4 0-8 3.6-8 8v48c0 4.4 3.6 8 8 8h122c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8zM477.3 322.5H434c-6.2 0-11.2 5-11.2 11.2v248c0 3.6 1.7 6.9 4.6 9l148.9 108.6c5 3.6 12 2.6 15.6-2.4l25.7-35.1v-.1c3.6-5 2.5-12-2.5-15.6l-126.7-91.6V333.7c.1-6.2-5-11.2-11.1-11.2z"
            );
            iconSvg.appendChild(iconPath1);
            const iconPath2 = document.createElementNS(
              "http://www.w3.org/2000/svg",
              "path"
            );
            iconPath2.setAttribute(
              "d",
              "M804.8 673.9H747c-5.6 0-10.9 2.9-13.9 7.7a321 321 0 01-44.5 55.7 317.17 317.17 0 01-101.3 68.3c-39.3 16.6-81 25-124 25-43.1 0-84.8-8.4-124-25-37.9-16-72-39-101.3-68.3s-52.3-63.4-68.3-101.3c-16.6-39.2-25-80.9-25-124 0-43.1 8.4-84.7 25-124 16-37.9 39-72 68.3-101.3 29.3-29.3 63.4-52.3 101.3-68.3 39.2-16.6 81-25 124-25 43.1 0 84.8 8.4 124 25 37.9 16 72 39 101.3 68.3a321 321 0 0144.5 55.7c3 4.8 8.3 7.7 13.9 7.7h57.8c6.9 0 11.3-7.2 8.2-13.3-65.2-129.7-197.4-214-345-215.7-216.1-2.7-395.6 174.2-396 390.1C71.6 727.5 246.9 903 463.2 903c149.5 0 283.9-84.6 349.8-215.8a9.18 9.18 0 00-8.2-13.3z"
            );
            iconSvg.appendChild(iconPath2);
          }
          // COLUMN: boolean
          else if (
            completion.type === "column" &&
            completion.detail === "BOOLEAN"
          ) {
            const iconPath = document.createElementNS(
              "http://www.w3.org/2000/svg",
              "path"
            );
            iconPath.setAttribute(
              "d",
              "M600 395.4h91V649h79V267c0-4.4-3.6-8-8-8h-48.2c-3.7 0-7 2.6-7.7 6.3-2.6 12.1-6.9 22.3-12.9 30.9a86.14 86.14 0 01-26.3 24.4c-10.3 6.2-22 10.5-35 12.9-10.4 1.9-21 3-32 3.1a8 8 0 00-7.9 8v42.8c0 4.4 3.6 8 8 8zM871 702H567c-4.4 0-8 3.6-8 8v48c0 4.4 3.6 8 8 8h304c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8zM443.9 312.7c-16.1-19-34.4-32.4-55.2-40.4-21.3-8.2-44.1-12.3-68.4-12.3-23.9 0-46.4 4.1-67.7 12.3-20.8 8-39 21.4-54.8 40.3-15.9 19.1-28.7 44.7-38.3 77-9.6 32.5-14.5 73-14.5 121.5 0 49.9 4.9 91.4 14.5 124.4 9.6 32.8 22.4 58.7 38.3 77.7 15.8 18.9 34 32.3 54.8 40.3 21.3 8.2 43.8 12.3 67.7 12.3 24.4 0 47.2-4.1 68.4-12.3 20.8-8 39.2-21.4 55.2-40.4 16.1-19 29-44.9 38.6-77.7 9.6-33 14.5-74.5 14.5-124.4 0-48.4-4.9-88.9-14.5-121.5-9.5-32.1-22.4-57.7-38.6-76.8zm-29.5 251.7c-1 21.4-4.2 42-9.5 61.9-5.5 20.7-14.5 38.5-27 53.4-13.6 16.3-33.2 24.3-57.6 24.3-24 0-43.2-8.1-56.7-24.4-12.2-14.8-21.1-32.6-26.6-53.3-5.3-19.9-8.5-40.6-9.5-61.9-1-20.8-1.5-38.5-1.5-53.2 0-8.8.1-19.4.4-31.8.2-12.7 1.1-25.8 2.6-39.2 1.5-13.6 4-27.1 7.6-40.5 3.7-13.8 8.8-26.3 15.4-37.4 6.9-11.6 15.8-21.1 26.7-28.3 11.4-7.6 25.3-11.3 41.5-11.3 16.1 0 30.1 3.7 41.7 11.2a87.94 87.94 0 0127.4 28.2c6.9 11.2 12.1 23.8 15.6 37.7 3.3 13.2 5.8 26.6 7.5 40.1 1.8 13.5 2.8 26.6 3 39.4.2 12.4.4 23 .4 31.8.1 14.8-.4 32.5-1.4 53.3z"
            );
            iconSvg.appendChild(iconPath);
          }
          // COLUMN: numeric
          else if (
            completion.type === "column" &&
            completion.detail === "NUMERIC"
          ) {
            const iconPath = document.createElementNS(
              "http://www.w3.org/2000/svg",
              "path"
            );
            iconPath.setAttribute(
              "d",
              "M508 280h-63.3c-3.3 0-6 2.7-6 6v340.2H433L197.4 282.6c-1.1-1.6-3-2.6-4.9-2.6H126c-3.3 0-6 2.7-6 6v464c0 3.3 2.7 6 6 6h62.7c3.3 0 6-2.7 6-6V405.1h5.7l238.2 348.3c1.1 1.6 3 2.6 5 2.6H508c3.3 0 6-2.7 6-6V286c0-3.3-2.7-6-6-6zm378 413H582c-4.4 0-8 3.6-8 8v48c0 4.4 3.6 8 8 8h304c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8zm-152.2-63c52.9 0 95.2-17.2 126.2-51.7 29.4-32.9 44-75.8 44-128.8 0-53.1-14.6-96.5-44-129.3-30.9-34.8-73.2-52.2-126.2-52.2-53.7 0-95.9 17.5-126.3 52.8-29.2 33.1-43.4 75.9-43.4 128.7 0 52.4 14.3 95.2 43.5 128.3 30.6 34.7 73 52.2 126.2 52.2zm-71.5-263.7c16.9-20.6 40.3-30.9 71.4-30.9 31.5 0 54.8 9.6 71 29.1 16.4 20.3 24.9 48.6 24.9 84.9 0 36.3-8.4 64.1-24.8 83.9-16.5 19.4-40 29.2-71.1 29.2-31.2 0-55-10.3-71.4-30.4-16.3-20.1-24.5-47.3-24.5-82.6.1-35.8 8.2-63 24.5-83.2z"
            );
            iconSvg.appendChild(iconPath);
          }
          // TABLE
          else if (completion.type === "table") {
            const iconPath = document.createElementNS(
              "http://www.w3.org/2000/svg",
              "path"
            );
            iconPath.setAttribute(
              "d",
              "M928 160H96c-17.7 0-32 14.3-32 32v640c0 17.7 14.3 32 32 32h832c17.7 0 32-14.3 32-32V192c0-17.7-14.3-32-32-32zm-40 208H676V232h212v136zm0 224H676V432h212v160zM412 432h200v160H412V432zm200-64H412V232h200v136zm-476 64h212v160H136V432zm0-200h212v136H136V232zm0 424h212v136H136V656zm276 0h200v136H412V656zm476 136H676V656h212v136z"
            );
            iconSvg.appendChild(iconPath);
          }
          // TEXT
          else if (
            ["type", "variable", "keyword", "localkeyword"].includes(
              completion.type
            )
          ) {
            const iconPath = document.createElementNS(
              "http://www.w3.org/2000/svg",
              "path"
            );
            iconPath.setAttribute(
              "d",
              "M120 230h496c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8H120c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8zm0 424h496c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8H120c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8zm784 140H120c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8h784c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8zm0-424H120c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8h784c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8z"
            );
            iconSvg.appendChild(iconPath);
          }
          // FUNCTION
          else if (["function"].includes(completion.type)) {
            const iconPath = document.createElementNS(
              "http://www.w3.org/2000/svg",
              "path"
            );
            iconPath.setAttribute(
              "d",
              "M876.6 239.5c-.5-.9-1.2-1.8-2-2.5-5-5-13.1-5-18.1 0L684.2 409.3l-67.9-67.9L788.7 169c.8-.8 1.4-1.6 2-2.5 3.6-6.1 1.6-13.9-4.5-17.5-98.2-58-226.8-44.7-311.3 39.7-67 67-89.2 162-66.5 247.4l-293 293c-3 3-2.8 7.9.3 11l169.7 169.7c3.1 3.1 8.1 3.3 11 .3l292.9-292.9c85.5 22.8 180.5.7 247.6-66.4 84.4-84.5 97.7-213.1 39.7-311.3zM786 499.8c-58.1 58.1-145.3 69.3-214.6 33.6l-8.8 8.8-.1-.1-274 274.1-79.2-79.2 230.1-230.1s0 .1.1.1l52.8-52.8c-35.7-69.3-24.5-156.5 33.6-214.6a184.2 184.2 0 01144-53.5L537 318.9a32.05 32.05 0 000 45.3l124.5 124.5a32.05 32.05 0 0045.3 0l132.8-132.8c3.7 51.8-14.4 104.8-53.6 143.9z"
            );
            iconSvg.appendChild(iconPath);
          }

          node.appendChild(iconSvg);
          wrap.appendChild(node);
          return wrap;
        },
        position: 20,
      },
    ],
  });
}
