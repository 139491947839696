// @ts-strict-ignore
import {
  CaretDownFilled,
  EditOutlined,
  EyeFilled,
  MailOutlined,
  PlusOutlined,
  StarOutlined,
} from "@ant-design/icons";
import { Button, Dropdown, Skeleton, Space, Typography } from "antd";
import React from "react";
import type { RouteComponentProps } from "react-router-dom";
import { withRouter } from "react-router-dom";
import { compose } from "../../../../components/compose/WlyCompose";
import usePrevious from "../../../../components/hooks/usePrevious";
import type { AsyncData } from "../../../../helpers/typescriptHelpers";
import type { IObject } from "../../../../interfaces/object";
import {
  LagoonCallOrigin,
  lagoonServiceLoad,
} from "../../../../services/LagoonService";
import type { InjectedOrgProps } from "../../../orgs/WithOrg";
import WithOrg from "../../../orgs/WithOrg";
import type { ILayout } from "../../../v2-demo/container/record/component/domain";
import RecordPicker from "./record-picker/RecordPicker";

interface IObjectLayoutEditorHeaderProps {
  preview?: boolean;
  setPreview?: (preview: boolean) => void;
  size?: IScreenSize;
  setSize?: (size: IScreenSize) => void;
  onSave?: () => void;
  saving?: boolean;
  displayName?: AsyncData<{ id: string; label: string }>;
  canBeListed?: boolean;
  layout: ILayout;
  object: IObject;
  onRename?: () => void;
  onMarkAsDefault?: () => void;
  recordId?: string;
  setRecordId: (recordId: string) => void;
  sendEmail: () => Promise<void>;
}

export type IScreenSize = "desktop" | "tablet" | "mobile";

type Props = InjectedOrgProps &
  IObjectLayoutEditorHeaderProps &
  RouteComponentProps;

function ObjectLayoutEditorHeader(props: Props) {
  const {
    org,
    preview,
    setPreview,
    onSave,
    saving,
    object,
    layout,
    onMarkAsDefault,
    onRename,
    recordId,
    setRecordId,
    sendEmail,
  } = props;

  const prevRecordId = usePrevious(recordId);
  const [recordData, setRecordData] = React.useState<AsyncData<string>>({
    status: "initial",
  });
  const objectCubeName = object.table.cubeName;

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        setRecordData({ status: "loading" });
        const data = await lagoonServiceLoad(
          org.id,
          {
            dimensions: [`${objectCubeName}.name`],
            filters: recordId
              ? [
                  {
                    and: [
                      {
                        member: `${objectCubeName}.id`,
                        operator: "equals",
                        values: [recordId],
                      },
                    ],
                  },
                ]
              : undefined,
          },
          "OBJECT",
          object.id,
          undefined,
          LagoonCallOrigin.WHALY_APP
        );
        const name = data.tablePivot()?.[0]?.[
          `${objectCubeName}.name`
        ] as string;

        setRecordData({ status: "success", data: name });
      } catch (err) {
        console.error(err);
        setRecordData({ status: "error", error: err });
      }
    };

    if (recordId !== prevRecordId) {
      fetchData();
    }
  }, [recordId, prevRecordId]);

  const renderRecordName = () => {
    if (!recordId) {
      return (
        <Typography.Text type="secondary">
          Select a record{" "}
          <span style={{ transform: "scale(0.8)", marginLeft: 4 }}>
            <CaretDownFilled />
          </span>
        </Typography.Text>
      );
    }
    if (recordData.status === "initial" || recordData.status === "loading") {
      return (
        <Skeleton.Button
          size="small"
          active
          style={{ width: 100 }}
          shape="round"
        />
      );
    }
    if (recordData.status === "error") {
      return (
        <Typography.Text type="danger">
          Error fetching record{" "}
          <span style={{ transform: "scale(0.8)", marginLeft: 4 }}>
            <CaretDownFilled />
          </span>
        </Typography.Text>
      );
    }
    return (
      <Typography.Text strong>
        {recordData.data}{" "}
        <span style={{ transform: "scale(0.8)", marginLeft: 4 }}>
          <CaretDownFilled />
        </span>
      </Typography.Text>
    );
  };

  return (
    <>
      <div className="object-layout-editor-left">
        <div className="object-layout-editor-logo">
          <Space size={"small"}>
            <Dropdown
              destroyPopupOnHide
              trigger={["click"]}
              arrow
              menu={{
                items: [
                  {
                    key: "rename",
                    label: "Rename",
                    onClick: onRename,
                    disabled: !onRename,
                  },
                ],
              }}
            >
              <Button type="text" size="small">
                <Typography.Text strong>
                  {layout.name ? layout.name : "Untitled"}
                </Typography.Text>
              </Button>
            </Dropdown>
            <Typography.Text type="secondary">/</Typography.Text>
            <Dropdown
              trigger={["click"]}
              arrow
              destroyPopupOnHide
              dropdownRender={() => {
                return (
                  <RecordPicker
                    objectCubeName={objectCubeName}
                    objectId={object.id}
                    onRecordIdSelect={setRecordId}
                  />
                );
              }}
            >
              <Button type="text" size="small">
                {renderRecordName()}
              </Button>
            </Dropdown>
          </Space>
        </div>
      </div>
      <div className="object-layout-editor-mid">
        {/* <Segmented
          value={size}
          onChange={(s) => setSize(s as IScreenSize)}
          options={[
            {
              value: "desktop",
              label: <DesktopOutlined />,
            },
            {
              value: "tablet",
              label: <TabletOutlined rotate={90} />,
            },
            {
              value: "mobile",
              label: <MobileOutlined />,
            },
          ]}
        /> */}
      </div>
      <div className="object-layout-editor-right">
        <Space>
          {layout.type === "EMAIL" ? (
            <Button
              icon={<MailOutlined />}
              size="small"
              type="text"
              onClick={() => sendEmail()}
            >
              Send test email
            </Button>
          ) : undefined}
          {preview ? null : onMarkAsDefault ? (
            <Button
              onClick={onMarkAsDefault}
              size="small"
              type="text"
              icon={<StarOutlined />}
            >
              Mark as default
            </Button>
          ) : null}
          {setPreview && (
            <>
              {preview ? (
                <Button
                  onClick={() => setPreview(false)}
                  icon={<EditOutlined />}
                  size="small"
                  type="text"
                >
                  Continue editing
                </Button>
              ) : (
                <Button
                  size="small"
                  type="text"
                  onClick={() => setPreview(true)}
                  icon={<EyeFilled />}
                >
                  Preview
                </Button>
              )}
            </>
          )}
          {onSave && (
            <>
              {!preview && (
                <Button
                  size="small"
                  loading={saving}
                  onClick={() => onSave()}
                  icon={<PlusOutlined />}
                >
                  Save
                </Button>
              )}
            </>
          )}
        </Space>
      </div>
    </>
  );
}

export default compose<Props, IObjectLayoutEditorHeaderProps>(
  WithOrg,
  withRouter
)(ObjectLayoutEditorHeader);
