import type { IWidget } from "../../../../v2-demo/container/record/component/domain";

export enum IObjectLayoutItemType {
  LAYOUT = "layout",
  WIDGET = "widget",
}

export type DraggedItemProps = AddItemProps | MovedItemProps;

interface AddItemProps {
  operation: "add";
  identifier: string;
}

interface MovedItemProps {
  operation: "move";
  identifier: string;
  data: IWidget;
}
