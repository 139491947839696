import { Select } from "antd";
import type { SelectProps } from "rc-select";
import * as React from "react";
import { compose } from "../../../../../components/compose/WlyCompose";
import Feednack from "../../../../../components/layout/feedback/feedback";
import Loading from "../../../../../components/layout/feedback/loading";
import type { AsyncData } from "../../../../../helpers/typescriptHelpers";
import type { IDestination } from "../../../../../interfaces/destinations";
import type { DataType } from "../../../../../interfaces/transformations";
import type { TableFields } from "../../../../../services/BrizoService";
import { getWarehouseTableColumns } from "../../../../../services/BrizoService";
import type { InjectedOrgProps } from "../../../../orgs/WithOrg";
import WithOrg from "../../../../orgs/WithOrg";
import TypeRenderer from "../../../../spreadsheet/renderer/TypeRenderer";

interface IPrimaryKeysSelectProps extends SelectProps {
  databaseId?: string;
  schemaId?: string;
  tableId?: string;
  currentWarehouse: IDestination;
}

type Props = IPrimaryKeysSelectProps & InjectedOrgProps;

function PrimaryKeysSelect(props: Props) {
  const {
    databaseId,
    schemaId,
    tableId,
    org,
    user,
    currentWarehouse,
    ...rest
  } = props;
  const [tables, setTables] = React.useState<AsyncData<TableFields>>({
    status: "initial",
  });

  React.useEffect(() => {
    if (databaseId && schemaId && tableId) {
      setTables({ status: "loading" });
      getWarehouseTableColumns(
        currentWarehouse.id,
        databaseId,
        schemaId,
        tableId
      )
        .then((r) => {
          setTables({ status: "success", data: r.data });
        })
        .catch((err) => {
          setTables({ status: "error", error: err });
        });
    }
  }, [currentWarehouse?.id, databaseId, schemaId, tableId]);

  if (tables.status === "initial") {
    return <div>Please select a table first ...</div>;
  }

  if (tables.status === "loading") {
    return <Loading />;
  }

  if (tables.status === "error") {
    return <Feednack>{tables.error.message}</Feednack>;
  }

  return (
    <Select {...(rest as any)} mode="tags">
      {tables.data.fields?.map((f, i) => {
        return (
          <Select.Option value={f.name} key={i}>
            <TypeRenderer domain={f.domain as DataType} /> {f.name}
          </Select.Option>
        );
      })}
    </Select>
  );
}

export default compose<Props, IPrimaryKeysSelectProps>(WithOrg)(
  PrimaryKeysSelect
);
