import type { chartOptionRenderer } from "../../../containers/chart-options/ChartOptions";
import { WlyStackedAreaChartIcon } from "../../icons/custom-icons/WlyStackedAreaChartIcon";
import type { SingleChartDefinition } from "../domain";

export const stackedAreaChartDefinition: SingleChartDefinition = {
  label: "Stacked area chart",
  allowTimeComparison: false,
  minMetrics: 1,
  maxMetrics: 1,
  minDimensions: 1,
  maxDimensions: 1,
  icon: <WlyStackedAreaChartIcon />,
  canPredict: (analysisType, query) => false,
  pivot: () => false,
  canDisplay: (rs) => undefined,
  config: [
    "hide-legend",
    "labels",
    "labels-stacked",
    "stacked-area-stacking",
    "palette",
  ],
  declarativeChartOptionRenderer: (options) => {
    const showStackLabels =
      !options?.chartOptions?.["stacked-area-stacking"] ||
      options?.chartOptions?.["stacked-area-stacking"] === "normal";

    const template: chartOptionRenderer = [
      {
        label: "Chart options",
        lines: [
          {
            type: "standard",
            component: "hide-legend",
          },
          {
            type: "standard",
            component: "labels",
          },
          {
            type: "standard",
            component: "stacked-area-stacking",
          },
          {
            type: "standard",
            component: "labels-stacked",
            hidden: !showStackLabels,
          },
          {
            type: "standard",
            component: "palette",
          },
        ],
      },
    ];
    return template;
  },
  axis: {
    bottom: {
      editable: true,
      isTimeFormattable: (a) => a === "TIME",
    },
    left: {
      scaleEditable: true,
      editable: true,
    },
  },
  extractCustomizationDimensions: (d, q) => {
    return {
      dimensions: [],
    };
  },
  extractCustomizationSeries: (series, d) => {
    return {
      series: series.map((serie) => {
        // we modify the key to keep only the dimension value
        // we replace null with ∅
        const key =
          serie.key.indexOf(",") > -1 ? serie.key.split(",")?.[0] : "∅";
        return { ...serie, key: key, label: key };
      }),
      customColor: true,
      customLabel: true,
      customType: [],
    };
  },
};
