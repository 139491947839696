export const GROUP_INFO_FRAGMENT = `
fragment GroupInfo on Group {
  id
  name
  isAdminGroup
  isSystemGroup
  members: _usersMeta(where: { isDeleted: false, user: { isDeleted: false, isPublicGuestUser: false, isAdmin: false }}) {
    count
  }
}
`;
