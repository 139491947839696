import { Alert, Modal, Table, Typography } from "antd";
import type { InjectedAntUtilsProps } from "../../../../components/ant-utils/withAntUtils";
import { withAntUtils } from "../../../../components/ant-utils/withAntUtils";
import { compose } from "../../../../components/compose/WlyCompose";
import type { IParsedWarehouseSize } from "../../../../interfaces/sources";
import type { ITable } from "../../../../interfaces/table";
import { LocaleService } from "../../../../services/localeService";
import type { InjectedOrgProps } from "../../../orgs/WithOrg";
import WithOrg from "../../../orgs/WithOrg";

interface IWarehouseStorageModalProps {
  tables: ITable[];
  visible: boolean;
  onClose: () => void;
}

type Props = IWarehouseStorageModalProps &
  InjectedOrgProps &
  InjectedAntUtilsProps;

function WarehouseStorageModal(props: Props) {
  const {
    tables,
    user: { locale },
    visible,
    onClose,
  } = props;

  const localeService = new LocaleService(locale);
  const numeral = localeService.getNumberDefaultFormatting();
  const warehouseTotalSize: IParsedWarehouseSize | undefined = tables.reduce<
    IParsedWarehouseSize | undefined
  >(
    (acc, curr) => {
      if (acc === undefined) {
        return acc;
      }
      const dataset = curr.view.dataset;
      if (dataset.isModel && dataset.isPersistedAs === "ephemeral") {
        return undefined;
      }
      const incSizeBytes = (): number | undefined => {
        if (!dataset.warehouseSize?.sizeBytes) {
          return undefined;
        }
        return acc.sizeBytes + parseInt(dataset.warehouseSize.sizeBytes);
      };
      const incRowCount = (): number | undefined => {
        if (!dataset.warehouseSize?.rowCount) {
          return undefined;
        }
        return acc.rowCount + parseInt(dataset.warehouseSize.rowCount);
      };
      return {
        sizeBytes: incSizeBytes(),
        rowCount: incRowCount(),
      };
    },
    {
      sizeBytes: 0,
      rowCount: 0,
    }
  );

  const warehouseStorageTableBreakdownColumns = [
    {
      title: "Table",
      dataIndex: "table_name",
      key: "table_name",
    },
    {
      title: "Number of rows",
      dataIndex: "row_count",
      key: "row_count",
    },
    {
      title: "Storage",
      dataIndex: "storage",
      key: "storage",
    },
  ];

  const warehouseStorageTableBreakdownData = tables.map((table) => {
    const size = table?.view?.dataset?.warehouseSize;
    return {
      key: table.id,
      table_name: table.name,
      row_count: size?.rowCount
        ? numeral(size.rowCount).format("0.[0]a")
        : "N/A",
      storage: size?.sizeBytes
        ? numeral(size.sizeBytes).format("0.00b")
        : "N/A",
    };
  });

  const ephemeralTables = tables.filter((table) => {
    return (
      table?.view?.dataset?.isModel === true &&
      table?.view?.dataset?.isPersistedAs === "ephemeral"
    );
  });

  const renderInner = (): JSX.Element => {
    return (
      <>
        {" "}
        {ephemeralTables.length > 0 ? (
          <Alert
            style={{ marginBottom: 8 }}
            message={
              <>
                <Typography.Text>
                  The following tables are not persisted in the Warehouse and
                  will generate more processing at query time than persisted
                  tables:
                </Typography.Text>

                <ul>
                  {ephemeralTables.map((table, index) => {
                    return <li key={index}>{table.name}</li>;
                  })}
                </ul>
                <Typography.Text>
                  Use the Persistence Engine or another materialization engine
                  to persist those as Tables in your Warehouse for optimal
                  performance.{" "}
                </Typography.Text>
                <br />
                <a href={"https://docs.whaly.io/models/persistence-engine"}>
                  More info
                </a>
              </>
            }
            type="info"
          />
        ) : undefined}
        <Typography.Text strong>Total size in Warehouse</Typography.Text>:{" "}
        {warehouseTotalSize?.sizeBytes
          ? numeral(warehouseTotalSize?.sizeBytes).format("0.00b")
          : "N/A"}
        <br />
        <br />
        <Table
          size="small"
          pagination={false}
          rowKey={"table_name"}
          dataSource={warehouseStorageTableBreakdownData}
          columns={warehouseStorageTableBreakdownColumns}
        />
      </>
    );
  };

  return (
    <Modal
      open={visible}
      title={"Warehouse Storage details"}
      maskClosable={true}
      footer={null}
      okText={"Close"}
      onCancel={onClose}
      width={"50%"}
      closable={true}
    >
      {renderInner()}
    </Modal>
  );
}

export default compose<Props, IWarehouseStorageModalProps>(
  WithOrg,
  withAntUtils
)(WarehouseStorageModal);
