import { Avatar } from "antd";
import * as React from "react";
import "./OrgAvatar.scss";

interface IOrgAvatarProps {
  name: string;
  overrideUrl?: string;
  style?: React.CSSProperties;
  size?: number;
  className?: string;
}

export default class OrgAvatar extends React.Component<IOrgAvatarProps> {
  public render() {
    const { name, style, size, overrideUrl, className } = this.props;
    const getFirstLetter = (n: string) => {
      return name.substr(0, 1);
    };
    if (overrideUrl) {
      return (
        <Avatar
          size={size ? size : "large"}
          shape="square"
          style={style}
          src={overrideUrl}
          className={`org-avatar ${className ? className : ""}`}
        />
      );
    }
    return (
      <Avatar
        size={size ? size : "large"}
        shape="square"
        style={style}
        className={`org-avatar color ${className ? className : ""}`}
      >
        {getFirstLetter(name)}
      </Avatar>
    );
  }
}
