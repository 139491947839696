import Icon from "@ant-design/icons";
import type { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";

export const Wly3ColumnIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon
    component={() => (
      <svg
        width="1em"
        height="1em"
        viewBox="0 0 213 213"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <mask id="path-1-inside-1_1612_302" fill="white">
          <rect x="6" y="43" width="62" height="127" rx="8" />
        </mask>
        <rect
          x="6"
          y="43"
          width="62"
          height="127"
          rx="8"
          stroke="#5D6671"
          strokeWidth="16"
          strokeLinejoin="round"
          mask="url(#path-1-inside-1_1612_302)"
        />
        <mask id="path-2-inside-2_1612_302" fill="white">
          <rect x="75" y="43" width="63" height="127" rx="8" />
        </mask>
        <rect
          x="75"
          y="43"
          width="63"
          height="127"
          rx="8"
          stroke="#5D6671"
          strokeWidth="16"
          strokeLinejoin="round"
          mask="url(#path-2-inside-2_1612_302)"
        />
        <mask id="path-3-inside-3_1612_302" fill="white">
          <rect x="145" y="43" width="62" height="127" rx="8" />
        </mask>
        <rect
          x="145"
          y="43"
          width="62"
          height="127"
          rx="8"
          stroke="#5D6671"
          strokeWidth="16"
          strokeLinejoin="round"
          mask="url(#path-3-inside-3_1612_302)"
        />
      </svg>
    )}
    {...props}
  />
);
