import type { BinaryOperator } from "@cubejs-client/core";
import { useForm } from "antd/es/form/Form";
import { SimpleFormModal } from "../../../../../../../../components/form/modal/SimpleFormModal";
import type { AvailableDimension } from "../../../../../../../../components/measures/filter-item/FilterItem";
import type { CreateEditPresetFilterFormData } from "../domain";
import { CreateEditPresetFilterForm } from "../form/CreateEditPresetFilterForm";

type Props = {
  open: boolean;
  initialValues?: Partial<CreateEditPresetFilterFormData>;
  isSaving: boolean;
  onCancel: () => void;
  onSave: (values: CreateEditPresetFilterFormData) => any;
  availableDimensions: AvailableDimension[];
  autocomplete: (
    dimensionName: string,
    operator: BinaryOperator,
    value?: string
  ) => Promise<string[]>;
};

export default function CreateEditPresetFilterModal({
  autocomplete,
  availableDimensions,
  open,
  initialValues,
  isSaving,
  onCancel,
  onSave,
}: Props) {
  const [form] = useForm<CreateEditPresetFilterFormData>();

  return (
    <SimpleFormModal<CreateEditPresetFilterFormData>
      form={form}
      open={open}
      onCancel={onCancel}
      isSaving={isSaving}
      modalProps={{
        title: initialValues?.id
          ? "Edit preset filter"
          : "Create preset filter",
        okText: "Save",
        width: 640,
      }}
    >
      <CreateEditPresetFilterForm
        form={form}
        initialValues={initialValues}
        onSave={onSave}
        autocomplete={autocomplete}
        availableDimensions={availableDimensions}
      />
    </SimpleFormModal>
  );
}
