import type { IObject } from "../../../interfaces/object";
import { AbsctractDatastore } from "../abstract/AbstractDataStore";
import type { WorkspaceDatastoreData } from "./WorkspaceDatastoreDomain";
import { WORKSPACE_DETAILS } from "./WorkspaceDatastoreDomain";

interface WorkspaceDatatoreVariable {
  orgId: string;
  userId: string;
  notInLabels: string[];
  includeObjects: boolean;
}

class WorkspaceDatatore extends AbsctractDatastore<
  WorkspaceDatastoreData,
  WorkspaceDatatoreVariable
> {
  constructor() {
    super(WORKSPACE_DETAILS);
  }

  updateObjects = (data: IObject[]) => {
    if (this.data.status === "success" && this.data.data.allObjects) {
      this.data.data = {
        ...this.data.data,
        allObjects: data,
      };
    }
  };
}

export interface WorkspaceDatatoreProps {
  workspaceDatastore: WorkspaceDatatore;
}

export default new WorkspaceDatatore();
