import _ from "lodash";
import { inject, observer } from "mobx-react";
import * as React from "react";
import type { RouteComponentProps } from "react-router";
import { Prompt, withRouter } from "react-router";
import type { InjectedAntUtilsProps } from "../../../components/ant-utils/withAntUtils";
import { withAntUtils } from "../../../components/ant-utils/withAntUtils";
import { compose } from "../../../components/compose/WlyCompose";
import { handleGQLErrors } from "../../../helpers/gqlHelpers";
import { getDatasetUsage } from "../../../helpers/sourceHelpers";
import type { DeepPartial } from "../../../helpers/typescriptHelpers";
import type {
  IExploration,
  IExplorationSection,
} from "../../../interfaces/explorations";
import type { IJobExecution } from "../../../interfaces/jobExecutions";
import type { IModelFolder } from "../../../interfaces/modelFolder";
import type { IObject } from "../../../interfaces/object";
import type { IRadar } from "../../../interfaces/radar";
import type { IDataset, ISource } from "../../../interfaces/sources";
import type { IWorksheet } from "../../../interfaces/worksheet";
import { track } from "../../../services/AnalyticsService";
import GraphQLService from "../../../services/graphql/GraphQLService";
import type { WorkbenchUIStoreProps } from "../../../store/workbenchUIStore";
import workbenchUIStore from "../../../store/workbenchUIStore";
import { generateUniqueId } from "../../../utils/uniqueId";
import type { InjectedOrgProps } from "../../orgs/WithOrg";
import WithOrg, { getCurrentWarehouse } from "../../orgs/WithOrg";
import type { IObjectLayoutFragment } from "../single/domain";
import type { QueryItemUpdateInput } from "./Workbench";
import Workbench from "./Workbench";
import type {
  FetchedDestination,
  IActiveObject,
  UpdateDestination,
} from "./domain";
import type { ModelFolderEditInitialData } from "./model/folder/ModelFolderEdition";
import type {
  IOnDeleteConnector,
  IOnUpdateConnector,
} from "./selector/connectors/ConnectorsTable";
import type { UpdateObjectLayoutFunction } from "./selector/object-layouts/domain";
import type {
  CreateObjectFunction,
  UpdateObjectFunction,
} from "./selector/object/domain";
import type {
  CreateRadarFunction,
  UpdateRadarFunction,
} from "./selector/radar/domain";
import type { UpdateWorksheetData } from "./viewer/worksheet/WorksheetViewer";

interface IWorkbenchWrapperProps {
  explorationId?: string;
  datasets: IDataset[];
  sources: ISource[];
  connectors: ISource[];
  destinationJobExecutions: IJobExecution[];
  routeRender: (params: object, query?: object) => string;
  saving: (b: boolean) => void;
  onRefreshModelFolderTree: () => Promise<void>;
  onRefreshModelFolders: () => Promise<void>;
  onRefreshDatasets: (id?: string[]) => Promise<void>;
  onRefreshSections: (id?: string[]) => Promise<void>;
  onRefreshExplorations: (id?: string[]) => Promise<void>;
  cancelJobExecutionRun: (exec: IJobExecution) => Promise<void>;
  onRefreshJobExecutions: () => Promise<void>;
  onRefreshQueries: () => Promise<void>;
  destination?: FetchedDestination;
  height?: number;
  onDestinationUpdate: UpdateDestination;
  explorations: IExploration[];
  explorationSections: IExplorationSection[];
  modelFolders: IModelFolder[];
  onWorksheetRefresh: (swap?: {
    fakeId: string;
    newId: string;
  }) => Promise<any>;
  worksheets: IWorksheet[];
  createWorksheet: () => string;
  updateWorksheet: UpdateWorksheetData;
  onDeleteConnector: IOnDeleteConnector;
  onUpdateConnector: IOnUpdateConnector;
  objects: IObject[];
  updateObject: UpdateObjectFunction;
  createObject: CreateObjectFunction;
  allObjectLayouts: IObjectLayoutFragment[];
  createObjectLayout: (objectId: IObjectLayoutFragment) => string;
  onUpdateObjectLayout: UpdateObjectLayoutFunction;
  radars: IRadar[];
  onUpdateRadar: UpdateRadarFunction;
  onCreateRadar: CreateRadarFunction;
}

type Props = IWorkbenchWrapperProps &
  RouteComponentProps<{
    organizationSlug: string;
    tableSlug?: string;
    warehouseSlug?: string;
  }> &
  InjectedAntUtilsProps &
  InjectedOrgProps &
  WorkbenchUIStoreProps;

class WorkbenchWrapper extends React.Component<Props> {
  componentDidMount(): void {
    const {
      match: {
        params: { tableSlug },
      },
      routeRender,
      workbenchUIStore: { setOnRouteParamUpdate, initActiveObjects },
    } = this.props;
    setOnRouteParamUpdate(this.onRouteParamUpdate(routeRender));
    initActiveObjects(tableSlug);
    window.addEventListener("beforeunload", this.onBeforeUnload);
  }

  onBeforeUnload = (event: BeforeUnloadEvent) => {
    const {
      workbenchUIStore: { getActiveObjects },
    } = this.props;
    const activeObjects = getActiveObjects();
    if (activeObjects.find((a) => a.stale)) {
      event.preventDefault();
      event.returnValue = "";
    }
  };

  componentDidUpdate(prevProps: Readonly<Props>): void {
    const {
      routeRender,
      workbenchUIStore: { setOnRouteParamUpdate },
    } = this.props;
    setOnRouteParamUpdate(this.onRouteParamUpdate(routeRender));
  }

  componentWillUnmount(): void {
    const { workbenchUIStore } = this.props;
    workbenchUIStore.destroy();
    window.removeEventListener("beforeunload", this.onBeforeUnload);
  }

  onRouteParamUpdate =
    (routeRender: (params: object, query?: object) => string) =>
    (left?: string, right?: string) => {
      const {
        match: { params },
        history,
      } = this.props;

      const newRoute = routeRender({
        ...params,
        tableSlug: left ? left : null,
      });
      history.replace(newRoute);
    };

  public onRelationshipUpdate = (id: string, relationshipUpdateInput: any) => {
    this.props.saving(true);
    return GraphQLService(
      `
    mutation updateDatasetRelationship($id: ID!, $data: DatasetRelationshipUpdateInput!) {
      updateDatasetRelationship(id: $id, data: $data){
        id
      }
    }
    `,
      {
        id: id,
        data: relationshipUpdateInput,
      }
    )
      .then(() => this.props.onRefreshDatasets())
      .then(() => {
        this.props.saving(false);
      })
      .catch(
        handleGQLErrors(() => {
          this.props.saving(false);
        })
      );
  };

  public onModelFolderCreate = (values: ModelFolderEditInitialData) => {
    this.props.saving(true);
    return GraphQLService(
      `
        mutation createModelFolder(id: ID! $data: ModelFolderCreateInput!) {
          createModelFolder(data: $data) {
            id
          }
        }
      `,
      {
        data: values,
      }
    )
      .then(() => this.props.onRefreshModelFolders())
      .then(() => {
        this.props.saving(false);
      })
      .catch(
        handleGQLErrors(() => {
          this.props.saving(false);
        })
      );
  };

  public onRelationshipCreate = (relationshipCreateInput: any) => {
    this.props.saving(true);
    return GraphQLService(
      `
    mutation createDatasetRelationship($data: DatasetRelationshipCreateInput!) {
      createDatasetRelationship(data: $data){
        id
      }
    }
    `,
      {
        data: relationshipCreateInput,
      }
    )
      .then(() => this.props.onRefreshDatasets())
      .then(() => {
        this.props.saving(false);
      })
      .catch(
        handleGQLErrors(() => {
          this.props.saving(false);
        })
      );
  };

  public onViewCreate = (viewCreateInput: any) => {
    const {
      history,
      match: { params },
      routeRender,
    } = this.props;
    this.props.saving(true);
    let slug = "";
    return GraphQLService(
      `
    mutation createView($data: ViewCreateInput!) {
      createView(data: $data){
        id
        slug
      }
    }
    `,
      {
        data: viewCreateInput,
      }
    )
      .then((v) => {
        slug = v.createView.slug;
        return this.props.onRefreshDatasets().then((v) => {
          history.push(routeRender({ ...params, viewSlug: slug }));
          this.props.saving(false);
        });
      })
      .catch(
        handleGQLErrors(() => {
          this.props.saving(false);
        })
      );
  };

  public onDatasetCreateFromExisting = async (data: any, datasetId: string) => {
    const { antUtils, workbenchUIStore, org } = this.props;
    try {
      this.props.saving(true);

      const getDatasetQuery = `
        query getSyncedTables($id: ID!, $orgId: ID!) {
          allDatasets: allDatasets(
            where: {
              id: $id,
              org: { id: $orgId },
              deleted_not: true,
              hideFromInterface: false
            }
          ) {
            id
            isModel
            primaryKey
            warehouseDatabaseId
            warehouseSchemaId
            warehouseTableId
            incomingRelationships(where: { deleted_not: true }) {
              type
              from
              to
              left {
                id
              }
            }
            outgoingRelationships(where: { deleted_not: true }) {
              type
              from
              to
              right {
                id
              }
            }
          }
        }
      `;

      const dataset = (
        await GraphQLService<{ allDatasets: IDataset[] }>(getDatasetQuery, {
          id: datasetId,
          orgId: this.props.org.id,
        })
      ).allDatasets[0];

      const uniqueId = generateUniqueId();

      const createData = {
        data: {
          ...data,
          primaryKey: dataset.primaryKey,
          head: uniqueId,
          query: {
            create: {
              type: "MASHUP",
              queryText:
                dataset.isModel === true
                  ? JSON.stringify([
                      {
                        operation: {
                          type: "Table.FromWhalyDataset",
                          args: {
                            datasetId: dataset.id,
                          },
                        },
                        var: uniqueId,
                        domain: "datasetResolver",
                      },
                    ])
                  : JSON.stringify([
                      {
                        operation: {
                          type: "Table.FromWarehouseTable",
                          args: {
                            databaseName: dataset.warehouseDatabaseId,
                            schemaName: dataset.warehouseSchemaId,
                            tableName: dataset.warehouseTableId,
                          },
                        },
                        var: uniqueId,
                        domain: "datasetResolver",
                      },
                    ]),
              org: {
                connect: {
                  id: org.id,
                },
              },
            },
          },
        },
      };
      const result = await GraphQLService<{
        createDataset: IDataset;
      }>(
        `
      mutation createDataset($data: DatasetCreateInput!) {
        createDataset(data: $data){
          id
          slug
        }
      }
      `,
        createData
      );

      await GraphQLService<{
        updateDataset: { id: string };
      }>(
        `
      mutation updateDataset($id: ID!, $data: DatasetUpdateInput) {
        updateDataset(id: $id, data: $data) {
          id
          slug
        }
      }
      `,
        {
          id: result.createDataset.id,
          data: {
            incomingRelationships: {
              create: dataset.incomingRelationships.map((ir) => {
                return {
                  from: ir.from,
                  to: ir.to,
                  type: ir.type,
                  editable: true,
                  deleted: false,
                  left: {
                    connect: {
                      id: ir.left.id,
                    },
                  },
                  right: {
                    connect: {
                      id: result.createDataset.id,
                    },
                  },
                  org: {
                    connect: {
                      id: this.props.org.id,
                    },
                  },
                };
              }),
            },
            outgoingRelationships: {
              create: dataset.outgoingRelationships.map((ir) => {
                return {
                  from: ir.from,
                  to: ir.to,
                  type: ir.type,
                  editable: true,
                  deleted: false,
                  left: {
                    connect: {
                      id: result.createDataset.id,
                    },
                  },
                  right: {
                    connect: {
                      id: ir.right.id,
                    },
                  },
                  org: {
                    connect: {
                      id: this.props.org.id,
                    },
                  },
                };
              }),
            },
          },
        }
      );

      await this.props.onRefreshDatasets([result.createDataset.id]);

      const newActiveObject: IActiveObject = {
        type: "dataset",
        value: result.createDataset.id,
        focused: true,
      };
      workbenchUIStore.pushActiveObject(newActiveObject);
      workbenchUIStore.setActiveObjectFocused(newActiveObject);
      this.props.saving(false);
      antUtils.message.success("Dataset successfully created");
    } catch (error) {
      antUtils.message.error("Error saving dataset");
      console.warn(error);
      handleGQLErrors(() => {
        this.props.saving(false);
      });
    }
  };

  public onDatasetsCreate = async (
    data: { data: any }[],
    redirect?: boolean
  ) => {
    const { antUtils, workbenchUIStore } = this.props;
    try {
      this.props.saving(true);
      const result = await GraphQLService<{
        createDatasets: IDataset[];
      }>(
        `
          mutation createDatasets($data: [DatasetsCreateInput]) {
            createDatasets(data: $data) {
              id
              slug
            }
          }
        `,
        {
          data,
        }
      );
      const ids = result.createDatasets.flatMap((d) => d.id);
      await this.props.onRefreshDatasets(ids);
      if (redirect) {
        const newActiveObject: IActiveObject = {
          type: "dataset",
          value: result.createDatasets[0].id,
          focused: true,
        };
        workbenchUIStore.pushActiveObject(newActiveObject);
        workbenchUIStore.setActiveObjectFocused(newActiveObject);
      }

      this.props.saving(false);
      antUtils.message.success("Dataset successfully created");
    } catch (error) {
      antUtils.message.error("Error saving dataset");
      console.warn(error);
      handleGQLErrors(() => {
        this.props.saving(false);
      });
    }
  };

  public onWorksheetCreate = async (): Promise<any> => {
    this.props.saving(true);
    const fake = this.props.createWorksheet();
    const currentWarehouse = getCurrentWarehouse(
      this.props.org,
      this.props.match.params.warehouseSlug
    );
    GraphQLService(
      `
    mutation createWorksheet($data: WorksheetCreateInput!) {
      createWorksheet( data: $data){
        id
      }
    }
    `,
      {
        data: {
          query: "",
          warehouse: {
            connect: {
              id: currentWarehouse?.id,
            },
          },
          org: {
            connect: {
              id: this.props.org.id,
            },
          },
        },
      }
    ).then((d) => {
      track("Worksheet Created", {});
      this.props.workbenchUIStore.swapActiveObjectId(
        "worksheet",
        fake,
        d.createWorksheet.id
      );
      this.props.onWorksheetRefresh({
        fakeId: fake,
        newId: d.createWorksheet.id,
      });
    });
    return fake;
  };

  public onDatasetUpdate = (datasetId: string, data: any) => {
    const { antUtils } = this.props;
    this.props.saving(true);
    return GraphQLService(
      `
    mutation updateDataset($datasetId: ID!, $data: DatasetUpdateInput!) {
      updateDataset(id: $datasetId, data: $data){
        id
      }
    }
    `,
      {
        datasetId,
        data,
      }
    )
      .then((r) => this.props.onRefreshDatasets([datasetId]))
      .then(() => {
        if (
          workbenchUIStore.getActiveObject() &&
          workbenchUIStore.getActiveObject()?.type === "dataset" &&
          workbenchUIStore.getActiveObject()?.value === datasetId &&
          data.deleted === true
        ) {
          workbenchUIStore.removeActiveObject(
            workbenchUIStore.getActiveObject()!
          );
        }
        antUtils.message.success("Model successfully updated");
        this.props.saving(false);
      })
      .catch(
        handleGQLErrors(() => {
          this.props.saving(false);
        })
      );
  };

  public onSourceUpdate = (sourceId: string, data: DeepPartial<ISource>) => {
    const { antUtils } = this.props;
    this.props.saving(true);
    let shouldUpdatePublicInfo = false;
    if (data.sourceMeta?.publicInfo) shouldUpdatePublicInfo = true;
    return GraphQLService<{ updateSource: DeepPartial<ISource> }>(
      `
        mutation updateSource($sourceId: ID!, $data: SourceUpdateInput!) {
          updateSource(id: $sourceId, data: $data) {
            id
            sourceMeta {
              publicInfo {
                id
              }
            }
          }
        }
      `,
      {
        sourceId,
        data: _.omit(data, "sourceMeta"),
      }
    )
      .then((r) => {
        if (shouldUpdatePublicInfo) {
          return GraphQLService(
            `
              mutation updateSourceMetaDataPublicInfo($publicInfoId: ID!, $data: SourceMetaDataPublicInfoUpdateInput!) {
                updateSourceMetaDataPublicInfo(id: $publicInfoId, data: $data) {
                  id
                  sourceMeta {
                    publicInfo {
                      id
                    }
                  }
                }
              }
            `,
            {
              publicInfoId: r.updateSource.sourceMeta.publicInfo.id,
              data: data.sourceMeta.publicInfo,
            }
          );
        }
      })
      .then((r) => this.props.onRefreshDatasets())
      .then(() => {
        antUtils.message.success("Source successfully updated");
        this.props.saving(false);
      })
      .catch(
        handleGQLErrors(() => {
          this.props.saving(false);
        })
      );
  };

  public onViewUpdate = (viewId: string, viewUpdateInput: any) => {
    this.props.saving(true);
    return GraphQLService(
      `
    mutation updateView($id: ID!, $data: ViewUpdateInput!) {
      updateView(id: $id, data: $data){
        id
      }
    }
    `,
      {
        data: viewUpdateInput,
        id: viewId,
      }
    )
      .then(() => this.props.onRefreshDatasets())
      .then(() => {
        this.props.saving(false);
      })
      .catch(
        handleGQLErrors(() => {
          this.props.saving(false);
        })
      );
  };

  onQueryUpdate = (
    viewQuery: QueryItemUpdateInput,
    datasetQuery: QueryItemUpdateInput
  ) => {
    const { org } = this.props;

    return Promise.resolve()
      .then(() => {
        const promises = [];
        if (viewQuery.id || datasetQuery.id) {
          const queryItemInput = [];
          if (viewQuery.id) {
            queryItemInput.push({
              id: viewQuery.id,
              data: viewQuery.data,
            });
          }
          if (datasetQuery.id) {
            queryItemInput.push({
              id: datasetQuery.id,
              data: datasetQuery.data,
            });
          }
          promises.push(
            GraphQLService(
              `
          mutation updateQueries($queryItemInput: [QueryItemsUpdateInput]) {
            updateQueryItems(data: $queryItemInput){
              id
            }
          }
          `,
              {
                queryItemInput,
              }
            )
          );
        }
        if (!viewQuery.id && viewQuery.objectId) {
          promises.push(
            GraphQLService(
              `
          mutation createViewQuery($viewId: ID!, $data: ViewUpdateInput!) {
            updateView(id: $viewId, data: $data){
              id
            }
          }
          `,
              {
                viewId: viewQuery.objectId,
                data: {
                  query: {
                    create: {
                      type: "MASHUP",
                      ...viewQuery.data,
                      org: {
                        connect: {
                          id: org.id,
                        },
                      },
                    },
                  },
                },
              }
            )
          );
        }

        if (!datasetQuery.id && datasetQuery.objectId) {
          promises.push(
            GraphQLService(
              `
        mutation createDatasetQuery($datasetId: ID!, $data: DatasetUpdateInput!) {
          updateDataset(id: $datasetId, data: $data){
            id
          }
        }
        `,
              {
                datasetId: datasetQuery.objectId,
                data: {
                  query: {
                    create: {
                      type: "MASHUP",
                      ...datasetQuery.data,
                      org: {
                        connect: {
                          id: org.id,
                        },
                      },
                    },
                  },
                },
              }
            )
          );
        }
        return Promise.all(promises);
      })
      .then(this.props.onRefreshQueries)
      .then(() => {
        this.props.saving(false);
      })
      .catch(
        handleGQLErrors(() => {
          this.props.saving(false);
        })
      );
  };

  onCreateSource = async (
    sourceName: string,
    sourceImage: string
  ): Promise<{ id: string }> => {
    try {
      const currentWarehouse = getCurrentWarehouse(
        this.props.org,
        this.props.match.params.warehouseSlug
      );
      const data = await GraphQLService(
        `
          mutation createSource(
            $sourceName: String!
            $sourceImage: String!
            $orgId: ID!
            $warehouseId: ID!
          ) {
            createSourceMetaDataPublicInfo(
              data: {
                name: $sourceName
                logo: $sourceImage
                label: live
                sourceMeta: {
                  create: {
                    name: $sourceName
                    auth: false
                    executor: WAREHOUSE
                    sources: {
                      create: {
                        name: $sourceName
                        warehouse: { connect: { id: $warehouseId } }
                        org: { connect: { id: $orgId } }
                      }
                    }
                    org: { connect: { id: $orgId } }
                  }
                }
                org: { connect: { id: $orgId } }
              }
            ) {
              sourceMeta {
                sources {
                  id
                }
              }
            }
          }
        `,
        {
          sourceName,
          sourceImage,
          orgId: this.props.org.id,
          warehouseId: currentWarehouse?.id,
        }
      );
      await this.props.onRefreshDatasets();
      return data?.createSourceMetaDataPublicInfo?.sourceMeta?.sources?.[0];
    } catch (error) {
      console.error(error);
    } finally {
      this.props.saving(false);
    }
  };

  onDeleteModelFolder = (id: string) => {
    const { antUtils } = this.props;
    this.props.saving(true);
    return GraphQLService(
      `
    mutation updateModelFolder($id: ID!, $data: ModelFolderUpdateInput!) {
      updateModelFolder(id: $id, data: $data){
        id
      }
    }
    `,
      {
        id: id,
        data: {
          isDeleted: true,
        },
      }
    )
      .catch()
      .then((r) => this.props.onRefreshModelFolders())
      .then(() => {
        if (workbenchUIStore.modelFolderEditionOpen === true) {
          workbenchUIStore.setModelFolderEditionOpen(false);
        }
        antUtils.message.success("Folder successfully deleted");
        this.props.saving(false);
      })
      .catch(
        handleGQLErrors(() => {
          this.props.saving(false);
        })
      );
  };

  onUpdateModelFolder = (value: ModelFolderEditInitialData) => {
    const { antUtils } = this.props;
    const { id, ...data } = value;
    this.props.saving(true);
    return GraphQLService(
      `
    mutation updateModelFolder($id: ID!, $data: ModelFolderUpdateInput!) {
      updateModelFolder(id: $id, data: $data){
        id
      }
    }
    `,
      {
        id: id,
        data: data,
      }
    )
      .catch()
      .then((r) => this.props.onRefreshModelFolders())
      .then(() => {
        if (workbenchUIStore.modelFolderEditionOpen === true) {
          workbenchUIStore.setModelFolderEditionOpen(false);
        }
        antUtils.message.success("Folder successfully updated");
        this.props.saving(false);
      })
      .catch(
        handleGQLErrors(() => {
          this.props.saving(false);
        })
      );
  };

  onCreateModelFolder = (value: ModelFolderEditInitialData) => {
    const { antUtils } = this.props;
    const { id, parentId, ...data } = value;
    this.props.saving(true);
    const currentWarehouse = getCurrentWarehouse(
      this.props.org,
      this.props.match.params.warehouseSlug
    );
    return GraphQLService(
      `
    mutation createModelFolder($data: ModelFolderCreateInput!) {
      createModelFolder(data: $data){
        id
      }
    }
    `,
      {
        data: {
          ...data,
          parent: parentId
            ? {
                connect: {
                  id: parentId,
                },
              }
            : undefined,
          warehouse: {
            connect: {
              id: currentWarehouse?.id,
            },
          },
          org: {
            connect: {
              id: this.props.org.id,
            },
          },
        },
      }
    )
      .catch()
      .then((r) => this.props.onRefreshModelFolders())
      .then(() => {
        if (workbenchUIStore.modelFolderEditionOpen === true) {
          workbenchUIStore.setModelFolderEditionOpen(false);
        }
        antUtils.message.success("Folder successfully updated");
        this.props.saving(false);
      })
      .catch(
        handleGQLErrors(() => {
          this.props.saving(false);
        })
      );
  };

  public render() {
    const {
      explorationId,
      datasets,
      destinationJobExecutions,
      sources,
      connectors,
      saving,
      routeRender,
      history,
      match: { params },
      org,
      onRefreshJobExecutions,
      onRefreshExplorations,
      onRefreshSections,
      onRefreshModelFolderTree,
      destination,
      explorationSections,
      explorations,
      antUtils,
      onRefreshDatasets,
      modelFolders,
      worksheets,
      updateWorksheet,
      onDeleteConnector,
      onUpdateConnector,
      objects,
      updateObject,
      createObject,
      allObjectLayouts,
      createObjectLayout,
      onUpdateObjectLayout,
      radars,
      onUpdateRadar,
      onCreateRadar,
    } = this.props;
    const currentWarehouse = getCurrentWarehouse(
      this.props.org,
      this.props.match.params.warehouseSlug
    );
    return (
      <>
        <Prompt
          message={(location, action) => {
            const {
              workbenchUIStore: { getActiveObjects },
              org,
            } = this.props;
            // TODO: ADD EMBEDDED ROUTES HERE
            if (location.pathname.startsWith(`/${org.slug}/workbench`)) {
              return true;
            }
            const activeObjects = getActiveObjects();
            if (activeObjects.find((a) => a.stale)) {
              return "You have unsaved changes, by continuing your work will be lost. Do you want to proceed?";
            } else {
              return true;
            }
          }}
        />
        <Workbench
          worksheets={worksheets}
          onCreateWorksheet={this.onWorksheetCreate}
          explorationId={explorationId}
          sources={sources}
          connectors={connectors}
          onDeleteConnector={onDeleteConnector}
          onUpdateConnector={onUpdateConnector}
          saving={saving}
          datasets={datasets}
          destinationJobExecutions={destinationJobExecutions}
          explorations={explorations}
          explorationSections={explorationSections}
          modelFolders={modelFolders}
          height={this.props.height}
          updateQueries={this.onQueryUpdate}
          onCreateModelFolder={this.onCreateModelFolder}
          onUpdateModelFolder={this.onUpdateModelFolder}
          onDeleteModelFolder={this.onDeleteModelFolder}
          onRefreshModelFolderTree={onRefreshModelFolderTree}
          cancelJobExecutionRun={this.props.cancelJobExecutionRun}
          onRefreshJobExecutions={onRefreshJobExecutions}
          destination={destination}
          onRefreshExplorations={onRefreshExplorations}
          onRefreshSections={onRefreshSections}
          onUpdateObjectLayout={onUpdateObjectLayout}
          onUpdateDrills={(viewId: string, drills: string[]) => {
            return this.onViewUpdate(viewId, {
              drills: drills.join(","),
            });
          }}
          onCreateView={(datasetId, viewName) => {
            return this.onViewCreate({
              name: viewName,
              dataset: {
                connect: {
                  id: datasetId,
                },
              },
              query: {
                create: {
                  type: "MASHUP",
                  queryText: JSON.stringify([]),
                  org: {
                    connect: {
                      id: org.id,
                    },
                  },
                },
              },
              org: {
                connect: {
                  id: org.id,
                },
              },
            });
          }}
          onRenameView={(viewId, name) => {
            return this.onViewUpdate(viewId, {
              name,
            });
          }}
          onDeleteView={(viewId, nextViewSlug) => {
            return this.onViewUpdate(viewId, {
              deleted: true,
            }).then(() => {
              history.push(routeRender({ ...params, viewSlug: nextViewSlug }));
            });
          }}
          onCreateRelationship={this.onRelationshipCreate}
          onUpdateRelationship={this.onRelationshipUpdate}
          onDestinationUpdate={this.props.onDestinationUpdate}
          onDeleteRelationship={(id) =>
            this.onRelationshipUpdate(id, { deleted: true })
          }
          onUpdateSource={this.onSourceUpdate}
          onCreateDataset={(d) => {
            if (org.destinations.length === 0 || !org.destinations[0].id) {
              throw new Error("You must have a warehouse to continue");
            }

            if (!Array.isArray(d)) {
              if (d.type === "SQL") {
                return this.onDatasetsCreate(
                  [
                    {
                      data: {
                        name: d.name,
                        sql: d.sql ? d.sql : "select 42 as the_answer",
                        primaryKey: d.primaryKeys
                          ? d.primaryKeys.join(",")
                          : "the_answer",
                        type: "SQL",
                        isModel: d.isModel,
                        folder: d.folderId
                          ? {
                              connect: {
                                id: d.folderId,
                              },
                            }
                          : undefined,
                        source: d.sourceId
                          ? {
                              connect: {
                                id: d.sourceId,
                              },
                            }
                          : undefined,
                        warehouse: {
                          connect: {
                            id: currentWarehouse?.id,
                          },
                        },
                        org: {
                          connect: {
                            id: org.id,
                          },
                        },
                      },
                    },
                  ],
                  true
                );
              } else if (d.type === "FLOW") {
                if (d.startFromDatasetId) {
                  return this.onDatasetCreateFromExisting(
                    {
                      name: d.name,
                      type: "FLOW",
                      isModel: d.isModel,
                      folder: d.folderId
                        ? {
                            connect: {
                              id: d.folderId,
                            },
                          }
                        : undefined,
                      warehouse: {
                        connect: {
                          id: currentWarehouse?.id,
                        },
                      },
                      org: {
                        connect: {
                          id: org.id,
                        },
                      },
                    },
                    d.startFromDatasetId
                  );
                } else {
                  return this.onDatasetsCreate(
                    [
                      {
                        data: {
                          name: d.name,
                          primaryKey: "",
                          type: "FLOW",
                          isModel: d.isModel,
                          folder: d.folderId
                            ? {
                                connect: {
                                  id: d.folderId,
                                },
                              }
                            : undefined,
                          warehouse: {
                            connect: {
                              id: currentWarehouse?.id,
                            },
                          },
                          org: {
                            connect: {
                              id: org.id,
                            },
                          },
                        },
                      },
                    ],
                    true
                  );
                }
              }
            } else {
              return this.onDatasetsCreate(
                d
                  .filter((da) => da.type === "WAREHOUSE")
                  .map((da) => {
                    return {
                      data: {
                        name: da.name,
                        primaryKey: da.primaryKeys.join(","),
                        type: "WAREHOUSE",
                        warehouseDatabaseId: da.database,
                        warehouseDatabaseQuoting: da.databaseQuoting,
                        warehouseSchemaId: da.schema,
                        warehouseSchemaQuoting: da.schemaQuoting,
                        warehouseTableId: da.table,
                        warehouseTableQuoting: da.tableQuoting,
                        isModel: da.isModel,
                        source: {
                          connect: {
                            id: da.sourceId,
                          },
                        },
                        warehouse: {
                          connect: {
                            id: currentWarehouse?.id,
                          },
                        },
                        org: {
                          connect: {
                            id: org.id,
                          },
                        },
                      },
                    };
                  })
              );
            }
          }}
          onDeleteDataset={async (datasetId) => {
            try {
              const dataset = this.props.datasets.find(
                (d) => d.id === datasetId
              );
              const source = dataset.isModel
                ? undefined
                : this.props.sources.find((s) => s.id === dataset.source.id);
              const usage = await getDatasetUsage({
                datasetId: dataset.id,
                datasetName: dataset.name,
                orgId: this.props.org.id,
                sourceName: source?.name,
              });
              if (
                usage.explorations.length ||
                usage.models.length ||
                usage.objects.length
              ) {
                const list = [];
                if (usage.explorations.length) {
                  list.push(
                    `${usage.explorations.length} exploration${
                      usage.explorations.length > 1 ? "s" : ""
                    }`
                  );
                }
                if (usage.models.length) {
                  list.push(
                    `${usage.models.length} model${
                      usage.models.length > 1 ? "s" : ""
                    }`
                  );
                }
                if (usage.objects.length) {
                  list.push(
                    `${usage.objects.length} object${
                      usage.objects.length > 1 ? "s" : ""
                    }`
                  );
                }
                return antUtils.modal.error({
                  title: "This dataset is in use",
                  content: `This dataset is used in ${new Intl.ListFormat(
                    "en"
                  ).format(
                    list
                  )}. Please update those before deleting this dataset.`,
                });
              } else {
                return antUtils.modal.confirm({
                  title: "Do you want to proceed?",
                  content: `You are about to delete a dataset. This action cannot be undone. Do you want to proceed ?`,
                  okButtonProps: {
                    danger: true,
                  },
                  okText: "Delete",
                  onOk: async () => {
                    try {
                      await this.onDatasetUpdate(datasetId, {
                        deleted: true,
                      });
                    } catch (error) {
                      throw Error(error);
                    }
                  },
                });
              }
            } catch (error) {
              console.warn(error);
              antUtils.message.error(
                "Error deleting the dataset. Please try again."
              );
            }
          }}
          onUpdateDataset={(datasetId, data) => {
            return this.onDatasetUpdate(datasetId, data);
          }}
          onCreateSource={this.onCreateSource}
          routeRender={routeRender}
          onClose={() => Promise.resolve()}
          onRefreshDatasets={onRefreshDatasets}
          updateWorksheetStore={updateWorksheet}
          objects={objects}
          createObject={createObject}
          updateObject={updateObject}
          allObjectLayouts={allObjectLayouts}
          createObjectLayout={createObjectLayout}
          radars={radars}
          onUpdateRadar={onUpdateRadar}
          onCreateRadar={onCreateRadar}
        />
      </>
    );
  }
}

export default compose<Props, IWorkbenchWrapperProps>(
  WithOrg,
  withRouter,
  withAntUtils
)(inject("workbenchUIStore")(observer(WorkbenchWrapper)));
