import { Tabs, Typography } from "antd";
import type { AvailableColumn } from "../../../../domain";
import { AvailablePropertySelector } from "./AvailablePropertySelector";
import { UsedPropertyVisibilitySelector } from "./UsedPropertyVisibilitySelector";
import { type PropertySelectorValue } from "./domain";

interface IPropertyVisibilitySelectorProps {
  availableColumns?: Array<AvailableColumn>;
  value?: Array<PropertySelectorValue>;
  onChange?: (value: Array<PropertySelectorValue>) => any;
}

function PropertyVisibilitySelector(props: IPropertyVisibilitySelectorProps) {
  const { availableColumns, onChange } = props;

  const value = props.value ? props.value : [];
  const valueUsed = value.filter((v) => v.visible);

  return (
    <div>
      <div style={{ margin: `-12px -12px 0` }}>
        <Tabs
          style={{ width: "100%" }}
          centered
          items={[
            {
              key: "available",
              label: "Available",
              children: (
                <AvailablePropertySelector
                  onChange={onChange}
                  value={value}
                  availableColumns={availableColumns}
                />
              ),
            },
            {
              key: "used",
              label: (
                <div>
                  Used{" "}
                  <Typography.Text type="secondary">
                    ({valueUsed.length})
                  </Typography.Text>
                </div>
              ),
              children: (
                <UsedPropertyVisibilitySelector
                  value={value}
                  onChange={onChange}
                  availableColumns={availableColumns}
                />
              ),
            },
          ]}
        />
      </div>
    </div>
  );
}

export default PropertyVisibilitySelector;
