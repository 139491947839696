import { Col, Row } from "antd";
import type { RouteComponentProps } from "react-router";
import { withRouter } from "react-router";
import type { InjectedAntUtilsProps } from "../../../../../../components/ant-utils/withAntUtils";
import { withAntUtils } from "../../../../../../components/ant-utils/withAntUtils";
import { compose } from "../../../../../../components/compose/WlyCompose";
import type { IReportFolder } from "../../../../../../interfaces/folder";
import { routeDescriptor } from "../../../../../../routes/routes";
import GraphQLService from "../../../../../../services/graphql/GraphQLService";
import { FolderCard } from "../../../home/cards/FolderCard";

import "./RelatedFolder.scss";

interface IRelatedFolderProps {
  relatedFolders: IReportFolder[];
  shouldRerender: () => void;
}

type Props = IRelatedFolderProps &
  RouteComponentProps<{}> &
  InjectedAntUtilsProps;

function RelatedFolder(props: Props) {
  const {
    relatedFolders,
    history,
    match: { params },
    antUtils,
    shouldRerender,
  } = props;

  const onDropReport = (r, f) => {
    const m = antUtils.message.loading({ content: "Moving report..." });
    return GraphQLService(
      `
  mutation updateReport($id: ID!, $data: ReportUpdateInput) {
    updateReport(id: $id, data: $data) {
      id
    }
  }
  `,
      {
        id: r.id,
        data: {
          folder: {
            connect: {
              id: f.id,
            },
          },
        },
      }
    ).then(() => {
      m();
      shouldRerender();
      antUtils.message.success(`Report ${r.name} moved to folder ${f.name}`);
    });
  };

  return (
    <Row className="related-folders" gutter={[16, 16]}>
      <Col span={24} className="related-folders-title">
        Related Folders
      </Col>
      {relatedFolders.map((folder, i) => {
        return (
          <Col xs={24} sm={12} md={8} lg={6} key={i}>
            <FolderCard
              folder={folder}
              onDropReport={onDropReport}
              onClick={() =>
                history.push(
                  routeDescriptor["folders"].renderRoute({
                    ...params,
                    folderSlug: folder.slug,
                  })
                )
              }
            />
          </Col>
        );
      })}
    </Row>
  );
}

export default compose<Props, IRelatedFolderProps>(
  withRouter,
  withAntUtils
)(RelatedFolder);
