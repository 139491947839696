import { MoreOutlined, PlusOutlined, StarFilled } from "@ant-design/icons";
import { gql } from "@apollo/client";
import { Button, Dropdown, Typography } from "antd";
import { inject, observer } from "mobx-react";
import { useEffect, useState } from "react";
import type { InjectedAntUtilsProps } from "../../../../../components/ant-utils/withAntUtils";
import { withAntUtils } from "../../../../../components/ant-utils/withAntUtils";
import { compose } from "../../../../../components/compose/WlyCompose";
import Feednack from "../../../../../components/layout/feedback/feedback";
import Loading from "../../../../../components/layout/feedback/loading";
import type { ExtendedMeasureType } from "../../../../../components/measures/measure-table/MeasureTable";
import type { IDestination } from "../../../../../interfaces/destinations";
import type { IObject } from "../../../../../interfaces/object";
import type {
  IDataset,
  IDatasetRelationship,
} from "../../../../../interfaces/sources";
import type { SchemaResult } from "../../../../../interfaces/transformations";
import GraphQLService from "../../../../../services/graphql/GraphQLService";
import type { WorkbenchUIStoreProps } from "../../../../../store/workbenchUIStore";
import workbenchUIStore from "../../../../../store/workbenchUIStore";
import type { InjectedOrgProps } from "../../../../orgs/WithOrg";
import WithOrg from "../../../../orgs/WithOrg";
import * as Toolbar from "../../../../spreadsheet/toolbar/Toolbar";
import { COLOR_GOLD } from "../../../../v2-demo/container/layout/domain";
import type { IObjectLayoutFragment } from "../../../single/domain";
import type {
  IActiveObject,
  IActiveObjectExplorationUrlState,
  IActiveObjectObjectUrlState,
  ObjectLayoutData,
  ObjectStore,
  TabData,
} from "../../domain";
import type { SelectedItems, StaleElements } from "../../exploration/domain";
import type { UpdateObjectLayoutFunction } from "../../selector/object-layouts/domain";
import type { UpdateObjectFunction } from "../../selector/object/domain";
import CreateObjectLayoutModal from "../object-layout/modals/CreateObjectLayoutModal";
import RenameObjectLayoutModal from "../object-layout/modals/RenameObjectLayoutModal";
import "./ObjectViewer.scss";
import ObjectTable from "./tabs/ObjectTableEditor";
import ObjectRegisteredAction from "./tabs/actions/ObjectRegisteredAction";
import ObjectPresetFilters from "./tabs/preset-filters/ObjectPresetFilters";
import ObjectSettings from "./tabs/settings/ObjectSettings";
import ObjectQueryBuilder from "./tabs/query-builder/ObjectQueryBuilder";

interface IObjectViewerProps {
  activeObject: IActiveObject;
  dataStore: {
    [tableKey: string]: TabData;
  };
  objectStore: ObjectStore;
  fetchModelData: (
    warehouseId: string,
    activeDatasetId: string,
    activeViewId: string,
    increment?: boolean,
    fullReload?: boolean
  ) => Promise<void>;
  onUpdateObject: UpdateObjectFunction;
  onUpdateObjectLayout: UpdateObjectLayoutFunction;
  createObjectLayout: (object: IObjectLayoutFragment) => string;
  currentWarehouse: IDestination;
  fetchObject: (objectId: string) => Promise<void>;
  fetchTableSchema: (
    tablesSchemas: Array<{ datasetId: string; viewId: string }>
  ) => Promise<SchemaResult[]>;
  allIncomingDatasetRelationships: IDatasetRelationship[];
  allDatasets: IDataset[];
  allObjects: IObject[];
}

type Props = IObjectViewerProps &
  WorkbenchUIStoreProps &
  InjectedAntUtilsProps &
  InjectedOrgProps;

enum Tabs {
  SETTINGS,
  TABLE,
  PRESET_FILTERS,
  REGISTERED_ACTIONS,
  QUERY_BUILDER,
}

function ObjectViewer(props: Props) {
  const {
    antUtils,
    activeObject,
    dataStore,
    objectStore,
    onUpdateObject,
    onUpdateObjectLayout,
    fetchTableSchema,
    createObjectLayout,
    allIncomingDatasetRelationships,
    workbenchUIStore: {
      setActiveObjectUrlParams,
      getActiveObjectUrlParams,
      getActiveObjectStaleState,
      setActiveObjectStale,
      pushActiveObject,
    },
    currentWarehouse,
    allDatasets,
    allObjects,
    fetchObject,
    org,
  } = props;
  const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);
  const [renameLayoutOpen, setRenameLayoutOpen] =
    useState<ObjectLayoutData | null>(null);
  const [createModalOpen, setCreateModalOpen] = useState<
    { id: string; type: string } | false
  >(false);

  const activeObjectFromStore = objectStore[activeObject.value];

  useEffect(() => {
    if (
      activeObjectFromStore &&
      activeObjectFromStore.object.status === "initial"
    ) {
      fetchObject(activeObjectFromStore.id);
    }
  }, [activeObjectFromStore?.object.status]);

  const getSelectedTab = () => {
    switch (
      (getActiveObjectUrlParams(activeObject) as IActiveObjectObjectUrlState)
        ?.tab
    ) {
      case "settings":
        return Tabs.SETTINGS;
      case "table":
        return Tabs.TABLE;
      case "presetFilters":
        return Tabs.PRESET_FILTERS;
      case "registeredActions":
        return Tabs.REGISTERED_ACTIONS;
      case "queryBuilder":
        return Tabs.QUERY_BUILDER;
      default:
        return Tabs.TABLE;
    }
  };

  const selectedTab = getSelectedTab();

  const objectLayouts = Object.keys(activeObjectFromStore?.layouts ?? {}).map(
    (key) => activeObjectFromStore.layouts[key]
  );

  const renderInner = () => {
    if (!activeObjectFromStore) {
      return (
        <div className="workbench-content">
          <div className="exploration-viewer">
            <Feednack>Object not found</Feednack>
          </div>
        </div>
      );
    }

    if (
      activeObjectFromStore.object.status === "initial" ||
      (activeObjectFromStore.object.status === "loading" &&
        !activeObjectFromStore.object.cache)
    ) {
      return (
        <div className="workbench-content">
          <div className="exploration-viewer">
            <Loading />
          </div>
        </div>
      );
    }

    if (activeObjectFromStore.object.status === "error") {
      return (
        <div className="workbench-content">
          <div className="exploration-viewer">
            <Feednack>Error fetching the object content</Feednack>
          </div>
        </div>
      );
    }

    const currentObject =
      activeObjectFromStore.object.status === "success"
        ? activeObjectFromStore.object.data
        : activeObjectFromStore.object.cache;

    const state = getActiveObjectUrlParams(
      activeObject
    ) as IActiveObjectExplorationUrlState;

    const staleState: StaleElements = getActiveObjectStaleState(activeObject)
      ? (getActiveObjectStaleState(activeObject) as StaleElements)
      : [];

    const onSelectItems = (selectedItems: SelectedItems) => {
      setActiveObjectUrlParams(activeObject, {
        ...state,
        items: selectedItems,
      });
    };

    if (!currentObject) {
      return <Feednack>Object not found</Feednack>;
    }

    if (selectedTab === Tabs.PRESET_FILTERS) {
      return <ObjectPresetFilters object={currentObject} />;
    } else if (selectedTab === Tabs.SETTINGS) {
      return (
        <ObjectSettings
          object={currentObject}
          onUpdateObject={onUpdateObject}
          allDatasets={allDatasets}
        />
      );
    } else if (selectedTab === Tabs.QUERY_BUILDER) {
      return <ObjectQueryBuilder object={currentObject} />;
    } else if (selectedTab === Tabs.REGISTERED_ACTIONS) {
      return <ObjectRegisteredAction object={currentObject} />;
    } else if (selectedTab === Tabs.TABLE) {
      return (
        <ObjectTable
          object={currentObject}
          allObjects={allObjects}
          currentWarehouse={currentWarehouse}
          selectedItems={state?.items ? state.items : []}
          onClick={(selectedItems) => onSelectItems(selectedItems)}
          fetchObject={fetchObject}
          staleElements={staleState}
          pushStaleElement={(element) => {
            if (Array.isArray(element)) {
              setActiveObjectStale(activeObject, [...element, ...staleState]);
            } else {
              setActiveObjectStale(activeObject, [element, ...staleState]);
            }
          }}
          removeStaleElement={(operationId) => {
            setActiveObjectStale(
              activeObject,
              staleState.filter((dd) => dd.operationId !== operationId)
            );
          }}
          removeAllStaleElements={() => {
            setActiveObjectStale(activeObject, undefined, false);
          }}
          updateStaleElement={(
            type: ExtendedMeasureType,
            objectId: string,
            data: any
          ) => {
            if (
              staleState.find(
                (ss) =>
                  ss.objectType === type &&
                  ss.type === "update" &&
                  ss.objectId === objectId
              )
            ) {
              setActiveObjectStale(
                activeObject,
                staleState.filter((dd) => {
                  if (
                    dd.objectType === type &&
                    dd.type === "update" &&
                    dd.objectId === objectId
                  ) {
                    dd.data = data;
                  }
                  return dd;
                })
              );
            } else if (
              staleState.find(
                (ss) =>
                  ss.objectType === type &&
                  ss.type === "create" &&
                  ss.operationId === objectId
              )
            ) {
              setActiveObjectStale(
                activeObject,
                staleState.filter((dd) => {
                  if (
                    dd.objectType === type &&
                    dd.type === "create" &&
                    dd.operationId === objectId
                  ) {
                    dd.data = data;
                    return dd;
                  }
                  return dd;
                })
              );
            } else if (
              staleState.find(
                (ss) =>
                  ss.objectType === type &&
                  ss.type === "delete" &&
                  ss.objectId === objectId
              )
            ) {
              setActiveObjectStale(activeObject, staleState);
            } else {
              setActiveObjectStale(activeObject, [
                ...staleState,
                {
                  type: "update",
                  objectId: objectId,
                  objectType: type,
                  data,
                },
              ] as StaleElements);
            }
          }}
          dataStore={dataStore}
          fetchTableSchema={fetchTableSchema}
          allIncomingDatasetRelationships={allIncomingDatasetRelationships}
          allDatasets={allDatasets}
        />
      );
    } else {
      return <></>;
    }
  };

  return (
    <div className="workbench-content">
      <div className="workbench-spreadsheet">
        <div className="workbench-content-inner">
          <Toolbar.Toolbar style={{ borderTop: "none" }}>
            <Toolbar.Item
              color="violet"
              active={selectedTab === Tabs.TABLE}
              onClick={() => {
                setActiveObjectUrlParams(activeObject, {
                  ...activeObject.urlState,
                  tab: "table",
                });
              }}
            >
              Configuration
            </Toolbar.Item>
            <Toolbar.Item
              color="yellow"
              active={selectedTab === Tabs.QUERY_BUILDER}
              onClick={() => {
                setActiveObjectUrlParams(activeObject, {
                  ...activeObject.urlState,
                  tab: "queryBuilder",
                });
              }}
            >
              Query Builder
            </Toolbar.Item>
            <Toolbar.Item
              color="green"
              active={selectedTab === Tabs.PRESET_FILTERS}
              onClick={() => {
                setActiveObjectUrlParams(activeObject, {
                  ...activeObject.urlState,
                  tab: "presetFilters",
                });
              }}
            >
              Preset filters
            </Toolbar.Item>
            <Toolbar.Item
              color="violet"
              active={selectedTab === Tabs.REGISTERED_ACTIONS}
              onClick={() => {
                setActiveObjectUrlParams(activeObject, {
                  ...activeObject.urlState,
                  tab: "registeredActions",
                });
              }}
            >
              Registered Actions
            </Toolbar.Item>
            <Toolbar.Item
              color="pink"
              active={false}
              dropdownProps={{
                trigger: ["click"],
                open: dropdownOpen,
                onOpenChange: setDropdownOpen,
                menu: {
                  items: [
                    {
                      key: "RECORD",
                      label: "Record layouts",
                      children: objectLayouts.filter(
                        (ol) => ol.type === "RECORD"
                      ),
                    },
                    {
                      key: "EMAIL",
                      label: "Email layouts",
                      children: objectLayouts.filter(
                        (ol) => ol.type === "EMAIL"
                      ),
                    },
                  ].map((group) => {
                    return {
                      type: "group",
                      key: group.key,
                      label: (
                        <div style={{ display: "flex", width: 250 }}>
                          <div style={{ flex: 1 }}>{group.label}</div>
                          <Button
                            shape="circle"
                            size="small"
                            type="text"
                            icon={<PlusOutlined />}
                            onClick={() => {
                              setDropdownOpen(false);
                              setCreateModalOpen({
                                id: activeObjectFromStore.id,
                                type: group.key,
                              });
                            }}
                          />
                        </div>
                      ),
                      children:
                        group.children.length === 0
                          ? [
                              {
                                key: group.key + "-empty",
                                label: `No ${group.key.toLowerCase()} layout`,
                                disabled: true,
                              },
                            ]
                          : group.children.map((layout) => {
                              const isDefaultLayout =
                                allObjects.find(
                                  (o) => o.id === activeObjectFromStore.id
                                )?.defaultLayout?.id === layout.id;

                              return {
                                key: layout.id,
                                label: (
                                  <div
                                    style={{
                                      display: "flex",
                                      width: 242,
                                      maxWidth: 242,
                                    }}
                                  >
                                    <div
                                      onClick={() => {
                                        workbenchUIStore.pushActiveObject({
                                          type: "object-layout",
                                          value: `${activeObject.value}::${layout.id}`,
                                          stale: false,
                                        });
                                      }}
                                      style={{
                                        flex: 1,
                                        minWidth: 0,
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        whiteSpace: "nowrap",
                                      }}
                                    >
                                      {isDefaultLayout && (
                                        <StarFilled
                                          style={{
                                            marginRight: 6,
                                            color: COLOR_GOLD,
                                          }}
                                        />
                                      )}
                                      {layout.name}
                                    </div>
                                    <Dropdown
                                      trigger={["click"]}
                                      menu={{
                                        items: [
                                          {
                                            key: "rename",
                                            label: "Rename",
                                            onClick: () => {
                                              setDropdownOpen(false);
                                              setRenameLayoutOpen(layout);
                                            },
                                          },
                                          ...(group.key === "RECORD"
                                            ? [
                                                {
                                                  key: "default",
                                                  label: "Set as default",
                                                  disabled: isDefaultLayout,
                                                  onClick: () => {
                                                    onUpdateObject(
                                                      activeObjectFromStore.id,
                                                      {
                                                        defaultLayout: {
                                                          connect: {
                                                            id: layout.id,
                                                          },
                                                        } as any,
                                                      }
                                                    );
                                                  },
                                                },
                                              ]
                                            : []),
                                          {
                                            type: "divider",
                                          },
                                          {
                                            key: "delete",
                                            label: "Delete",
                                            danger: true,
                                            onClick: () => {
                                              return antUtils.modal.confirm({
                                                title:
                                                  "Do you want to proceed?",
                                                content:
                                                  "You are about to delete this layout, this cannot be undone. Do you want to proceed ?",
                                                onCancel: () => undefined,
                                                okButtonProps: {
                                                  danger: true,
                                                },
                                                onOk: async () => {
                                                  try {
                                                    await onUpdateObjectLayout(
                                                      layout.id,
                                                      {
                                                        isDeleted: true,
                                                      }
                                                    );
                                                    workbenchUIStore.removeActiveObject(
                                                      {
                                                        type: "object-layout",
                                                        value: `${activeObjectFromStore.id}::${layout.id}`,
                                                      }
                                                    );
                                                    antUtils.message.success(
                                                      "Layout deleted"
                                                    );
                                                  } catch (error) {
                                                    antUtils.message.error(
                                                      "Error while deleting layout. Please try again"
                                                    );
                                                  } finally {
                                                    setDropdownOpen(false);
                                                  }
                                                },
                                              });
                                            },
                                          },
                                        ],
                                      }}
                                    >
                                      <Button
                                        shape="circle"
                                        size="small"
                                        type="text"
                                        style={{ marginLeft: 6 }}
                                        icon={<MoreOutlined />}
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          setDropdownOpen(true);
                                        }}
                                      />
                                    </Dropdown>
                                  </div>
                                ),
                              };
                            }),
                    };
                  }),
                },
              }}
            >
              Layouts{" "}
              {objectLayouts.length > 0 && (
                <Typography.Text type="secondary">
                  ({objectLayouts.length})
                </Typography.Text>
              )}
            </Toolbar.Item>
            <Toolbar.Item
              color="yellow"
              active={selectedTab === Tabs.SETTINGS}
              onClick={() => {
                setActiveObjectUrlParams(activeObject, {
                  ...activeObject.urlState,
                  tab: "settings",
                });
              }}
            >
              Settings
            </Toolbar.Item>
          </Toolbar.Toolbar>
          <div className="workbench-content">
            <div className="object-viewer">{renderInner()}</div>
          </div>
        </div>
      </div>
      <RenameObjectLayoutModal
        open={!!renameLayoutOpen}
        onClose={() => setRenameLayoutOpen(null)}
        initialValue={{
          name: renameLayoutOpen?.name || "",
        }}
        onRename={async (v) => {
          try {
            if (!renameLayoutOpen) {
              throw new Error("Object layout data must be defined");
            }
            await onUpdateObjectLayout(renameLayoutOpen.id, v);
            antUtils.message.success("Successfully updated");
          } catch (err) {
            console.error(err);
            antUtils.message.error("There was an error renaming layout");
          }
        }}
      />
      <CreateObjectLayoutModal
        open={
          createModalOpen && createModalOpen.id === activeObjectFromStore.id
        }
        onClose={() => setCreateModalOpen(false)}
        onCreate={async (v) => {
          if (typeof createModalOpen === "boolean") return;
          const data = await GraphQLService<{
            createObjectLayout: IObjectLayoutFragment;
          }>(
            gql`
              mutation createObjectLayout($data: ObjectLayoutCreateInput!) {
                createObjectLayout(data: $data) {
                  id
                  name
                  type
                  object {
                    id
                  }
                  createdAt
                  updatedAt
                }
              }
            `,
            {
              data: {
                name: v.name,
                type: createModalOpen.type,
                header: JSON.stringify({
                  visible: createModalOpen.type === "EMAIL" ? false : true,
                }),
                object: {
                  connect: {
                    id: activeObjectFromStore.id,
                  },
                },
                org: {
                  connect: {
                    id: org.id,
                  },
                },
              },
            }
          );
          if (
            createModalOpen.type === "RECORD" &&
            objectLayouts.filter((ol) => ol.type === "RECORD").length === 0
          ) {
            await onUpdateObject(activeObjectFromStore.id, {
              defaultLayout: {
                connect: {
                  id: data?.createObjectLayout.id,
                },
              } as any,
            });
          }

          const id = createObjectLayout(data.createObjectLayout);
          pushActiveObject({
            type: "object-layout",
            value: `${activeObjectFromStore.id}::${id}`,
            stale: false,
          });
          setCreateModalOpen(false);
        }}
      />
    </div>
  );
}

export default compose<Props, IObjectViewerProps>(
  inject("workbenchUIStore"),
  observer,
  withAntUtils,
  WithOrg
)(ObjectViewer);
