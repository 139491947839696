import { CameraFilled } from "@ant-design/icons";
import type { UploadProps } from "antd";
import { Button, Upload } from "antd";
import * as React from "react";
import type { InjectedAntUtilsProps } from "../../../components/ant-utils/withAntUtils";
import { withAntUtils } from "../../../components/ant-utils/withAntUtils";
import { compose } from "../../../components/compose/WlyCompose";
import Loading from "../../../components/layout/feedback/loading";
import type { AsyncData } from "../../../helpers/typescriptHelpers";
import { createOrgAsset, getImageUrl } from "../../../services/AssetService";
import type { InjectedOrgProps } from "../../orgs/WithOrg";
import WithOrg from "../../orgs/WithOrg";

import "./ImageUploader.scss";

interface ILogoUploaderProps {
  onChange?: (filePath: string) => void;
  value?: string;
  disabled?: boolean;
}

type Props = ILogoUploaderProps & InjectedAntUtilsProps & InjectedOrgProps;

function LogoUploader(props: Props) {
  const {
    antUtils: { message },
    onChange,
    value,
    org,
    disabled,
  } = props;

  const [uploading, setUploading] = React.useState<
    AsyncData<{ filePath: string }>
  >({
    status: "initial",
  });

  const onUpload = (fileName: string, formData: FormData) => {
    return createOrgAsset(org.id, fileName, formData).then((r) => {
      return r.data.filePath;
    });
  };

  const uploadProps: UploadProps = {
    name: "file",
    disabled: uploading.status === "loading" || disabled,
    showUploadList: false,
    accept: "image/*",
    customRequest: (options) => {
      const formData = new FormData();
      formData.append("file", options.file);
      onUpload((options.file as File).name, formData)
        .then((r) => {
          options.onSuccess(r);
        })
        .catch((err) => {
          console.error(err);
          options.onError(err);
        });
    },
    onChange(info) {
      const { status } = info.file;
      if (status === "uploading") {
        setUploading({ status: "loading" });
      }
      if (status === "done" && info.file?.response) {
        setUploading({
          status: "success",
          data: { filePath: info.file?.response },
        });
        onChange(getImageUrl(info.file?.response));
      } else if (status === "error") {
        setUploading({ status: "initial" });
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    beforeUpload: async (file) => {
      setUploading({ status: "loading" });
      try {
        // const validatedFile = await verifyImageProperties(file);
        return file;
      } catch (err) {
        console.error(err);
        setUploading({ status: "initial" });
        message.error(`Logo upload failed: ${err.message}`);
        throw new Error("`Logo upload failed");
      }
    },
  };

  const renderInnerButton = () => {
    if (!value) {
      return (
        <Upload
          {...uploadProps}
          disabled={disabled || uploading.status === "loading"}
        >
          <Button loading={uploading.status === "loading"} type="default">
            Click to upload
          </Button>
        </Upload>
      );
    } else {
      return (
        <Upload
          {...uploadProps}
          disabled={disabled || uploading.status === "loading"}
        >
          <div className={`image-uploader `}>
            <img
              style={{
                maxHeight: 48,
                maxWidth: "100%",
                cursor: disabled ? "default" : "pointer",
              }}
              src={getImageUrl(value)}
              alt={"Logo"}
            />
            <div
              className={`image-uploader-inner ${
                uploading.status === "loading"
              }`}
            >
              {uploading.status === "loading" ? <Loading /> : <CameraFilled />}
            </div>
          </div>
        </Upload>
      );
    }
  };

  return <>{renderInnerButton()}</>;
}

export default compose<Props, ILogoUploaderProps>(
  withAntUtils,
  WithOrg
)(LogoUploader);
