import { Form, Select, Space } from "antd";
import type { VisualizationSpec } from "react-vega";
import { ChartOptionCollapse } from "../../../../../../../../../chart-options/components/ChartOptionCollapse";
import ChartOptionLine from "../../../../../../../../../chart-options/components/ChartOptionLine";
import { AggregateField } from "../common/AggregateField";
import { SchemaField } from "../common/SchemaField";
import { TimeAggregate } from "../common/TimeAggregate";
import type { AggrerateValue, IDiscoveryChartDefinition } from "../domain";

const defaultAggregation: AggrerateValue | null = null;
const nonNumberAggregation: AggrerateValue[] = [
  "count",
  "distinct",
  "valid",
  "missing",
];

const scatter: IDiscoveryChartDefinition<{
  x: {
    value: string;
    timeUnit: string | null;
    sort: "ascending" | "descending";
  };
  y: {
    value: string;
    aggregate: "count" | "distinct";
  };
}> = {
  renderForm: (schema, form, onChange) => {
    return (
      <Form
        form={form}
        initialValues={{
          x: {
            sort: "ascending",
            timeUnit: null,
          },
          y: {
            aggregate: defaultAggregation,
          },
        }}
        onValuesChange={(changed, values) => {
          if (changed?.y?.value) {
            form.setFieldValue(["y", "aggregate"], defaultAggregation);
          }
          if (changed?.x?.value && schema[changed.x.value]?.domain !== "TIME") {
            form.setFieldValue(["x", "timeUnit"], null);
          }
          onChange(form.getFieldsValue());
        }}
      >
        <ChartOptionCollapse title="X-Axis">
          <Space style={{ width: "100%" }} direction="vertical">
            <ChartOptionLine
              items={[
                {
                  flex: 1,
                  content: (
                    <Form.Item noStyle name={["x", "value"]}>
                      <SchemaField schema={schema} />
                    </Form.Item>
                  ),
                },
              ]}
            />
            <Form.Item noStyle shouldUpdate>
              {() => {
                const currentFieldDomain =
                  schema[form.getFieldValue(["x", "value"])]?.domain;
                if (currentFieldDomain === "TIME") {
                  return (
                    <ChartOptionLine
                      items={[
                        {
                          flex: 1,
                          content: <div>Time unit</div>,
                        },
                        {
                          flex: 0,
                          content: (
                            <Form.Item noStyle name={["x", "timeUnit"]}>
                              <TimeAggregate />
                            </Form.Item>
                          ),
                        },
                      ]}
                    />
                  );
                }
                return null;
              }}
            </Form.Item>
            <ChartOptionLine
              items={[
                {
                  flex: 1,
                  content: <div>Sort</div>,
                },
                {
                  flex: 0,
                  content: (
                    <Form.Item name={["x", "sort"]} noStyle>
                      <Select popupMatchSelectWidth={false} bordered={false}>
                        <Select.Option key="ascending" value="ascending">
                          Ascending
                        </Select.Option>
                        <Select.Option key="descending" value="descending">
                          Descending
                        </Select.Option>
                      </Select>
                    </Form.Item>
                  ),
                },
              ]}
            />
          </Space>
        </ChartOptionCollapse>
        <ChartOptionCollapse title="Y-Axis">
          <Space style={{ width: "100%" }} direction="vertical">
            <ChartOptionLine
              items={[
                {
                  flex: 1,
                  content: (
                    <Form.Item noStyle name={["y", "value"]}>
                      <SchemaField schema={schema} />
                    </Form.Item>
                  ),
                },
              ]}
            />
            <ChartOptionLine
              items={[
                {
                  flex: 1,
                  content: <div>Aggregate</div>,
                },
                {
                  flex: 0,
                  content: (
                    <Form.Item shouldUpdate noStyle>
                      {() => {
                        return (
                          <Form.Item name={["y", "aggregate"]} noStyle>
                            <AggregateField
                              allowNull={true}
                              availableAggregates={
                                form.getFieldValue(["y", "value"]) &&
                                schema[form.getFieldValue(["y", "value"])]
                                  ?.domain === "NUMERIC"
                                  ? ["count", "sum", "mean", "min", "max"]
                                  : nonNumberAggregation
                              }
                            />
                          </Form.Item>
                        );
                      }}
                    </Form.Item>
                  ),
                },
              ]}
            />
          </Space>
        </ChartOptionCollapse>
      </Form>
    );
  },
  renderSpec: (data, schema, def) => {
    if (!def || !def.x?.value || !def.y?.value) {
      return {};
    }

    const domain = schema[def?.x?.value]?.domain;

    const spec: VisualizationSpec = {
      $schema: "https://vega.github.io/schema/vega-lite/v5.json",
      width: "container",
      height: "container",
      data: { values: data },
      mark: "point",
      background: "transparent",
      padding: {
        left: 12,
        right: 12,
      },
      encoding: {
        x: {
          field: def.x.value,
          timeUnit: domain === "TIME" ? (def.x.timeUnit as any) : undefined,
          type: domain === "TIME" ? "temporal" : "quantitative",
        },
        y: {
          field: def.y.value,
          aggregate: def.y.aggregate,
        },
      },
    };

    return spec;
  },
};

export default scatter;
