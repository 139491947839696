import {
  CheckCircleTwoTone,
  CloseCircleTwoTone,
  ExclamationCircleTwoTone,
  LoadingOutlined,
} from "@ant-design/icons";
import { Space, Typography } from "antd";
import React from "react";

const { Text } = Typography;

interface IPreSaveModelStepsProps {
  steps: IPreSaveModelStep[];
  onDone: () => Promise<void>;
}

export interface IPreSaveModelStep {
  name: JSX.Element;
  onStart: () => Promise<void>;
  store: AsyncStepData<{}>;
}

export type AsyncStepData<T> =
  | { status: "initial" }
  | { status: "loading" }
  | { status: "success"; data?: T; component: JSX.Element }
  | { status: "warning"; data?: T; component: JSX.Element }
  | { status: "error"; error?: Error; component: JSX.Element };

export function PreSaveModelSteps(props: IPreSaveModelStepsProps) {
  const { steps, onDone } = props;

  React.useEffect(() => {
    steps
      .reduce(async (acc, v) => {
        await acc;
        return v.onStart();
      }, Promise.resolve())
      .then(onDone);
  }, []);

  return (
    <Space direction="vertical" size={16} style={{ width: "100%" }}>
      {steps.map((s, i) => {
        const isDisabled = s.store.status === "initial";

        const renderIcon = () => {
          if (s.store.status === "initial") {
            return <CheckCircleTwoTone twoToneColor={"#dddddd"} />;
          }
          if (s.store.status === "loading") {
            return <LoadingOutlined />;
          }
          if (s.store.status === "warning") {
            return <ExclamationCircleTwoTone twoToneColor={"#faad14"} />;
          }
          if (s.store.status === "error") {
            return <CloseCircleTwoTone twoToneColor={"#ff4d4f"} />;
          }

          return <CheckCircleTwoTone twoToneColor={"#52c41a"} />;
        };

        const renderText = () => {
          if (
            s.store.status === "success" ||
            s.store.status === "warning" ||
            s.store.status === "error"
          ) {
            return (
              <div style={{ marginLeft: 22, color: "#00000073" }}>
                {s.store.component}
              </div>
            );
          }
        };

        return (
          <div style={{ display: "flex", flexDirection: "column" }} key={i}>
            <Text disabled={isDisabled}>
              <span style={{ marginRight: 6 }}>{renderIcon()}</span>
              {"  "}
              {s.name}
            </Text>
            {renderText()}
          </div>
        );
      })}
    </Space>
  );
}
