import type { ConfigType } from "../../../containers/chart-options/ChartOptions";
import type { IAnalysisType } from "../../../containers/exploration/single/domain";
import { WlyLineChartIcon } from "../../icons/custom-icons/WlyLineChartIcon";
import type { SingleChartDefinition } from "../domain";

export const lineChartDefinition: SingleChartDefinition = {
  label: "Line chart",
  allowTimeComparison: true,
  minMetrics: 1,
  minDimensions: 0,
  icon: <WlyLineChartIcon />,
  canPredict: (analysisType, query) =>
    analysisType === "TIME" &&
    (!query.pivotDimensions || query.pivotDimensions.length === 0) &&
    query.selectedDimensions.length === 0 &&
    !query.comparison,
  pivot: (analysisType: IAnalysisType) => analysisType === "CATEGORIES",
  canDisplay: (rs) => undefined,
  config: (a) => {
    const ret: Array<ConfigType> = ["hide-legend", "labels", "palette"];
    return ret;
  },
  axis: {
    bottom: {
      editable: true,
      isTimeFormattable: (a) => a === "TIME",
    },
    left: {
      editable: true,
      scaleEditable: true,
    },
    right: {
      editable: true,
      scaleEditable: true,
    },
  },
  extractCustomizationDimensions: (d, q) => {
    return {
      dimensions: [],
    };
  },
  extractCustomizationSeries: (s, d) => {
    return {
      series: s,
      customColor: true,
      customLabel: true,
      customType: ["area", "bar", "line", "scatter"],
    };
  },
};
