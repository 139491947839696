import { LockOutlined } from "@ant-design/icons";
import { Typography } from "antd";
import type { RouteComponentProps } from "react-router";
import { matchPath, withRouter } from "react-router";
import { compose } from "../../../../../components/compose/WlyCompose";
import { IOrgFeatureType } from "../../../../../interfaces/org";
import { IUserRoleType } from "../../../../../interfaces/user";
import { routeDescriptor } from "../../../../../routes/routes";
import type { InjectedOrgProps } from "../../../../orgs/WithOrg";
import WithOrg from "../../../../orgs/WithOrg";
import { hasRoleAccessBoolean } from "../../../../user-settings/HasRoleAccess";
import { ClosableContent } from "../../../component/layout/ClosableContent";

interface ISettingsMenuProps {}

type Props = ISettingsMenuProps & RouteComponentProps & InjectedOrgProps;

type MenuDataItem = {
  label: React.ReactNode;
  isSelected: boolean;
  route: string;
};

function SettingsMenu(props: Props) {
  const {
    history,
    location: { pathname },
    org,
    orgFeatures,
    warehouses,
    user,
  } = props;

  const orgSettingsMeta: MenuDataItem[] = [
    {
      label: "General Settings",
      isSelected: !!matchPath(pathname, routeDescriptor["settingsBasic"].path),
      route: routeDescriptor["settingsBasic"].renderRoute({
        organizationSlug: org.slug,
      }),
    },
    {
      label: "Palette Collections",
      isSelected: !!matchPath(pathname, routeDescriptor["settingsTheme"].path),
      route: routeDescriptor["settingsTheme"].renderRoute({
        organizationSlug: org.slug,
      }),
    },
    {
      label: orgFeatures.includes(
        IOrgFeatureType.PARTNER_PORTAL_SYNC_FEATURE_API_NAME
      ) ? (
        "Partner Portal"
      ) : (
        <>
          <LockOutlined style={{ marginRight: 4 }} />
          Partner Portal
        </>
      ),
      isSelected:
        !!matchPath(pathname, routeDescriptor["settingsPartnerPortals"].path) ||
        !!matchPath(
          pathname,
          routeDescriptor["settingsPartnerPortalDetails"].path
        ),
      route: routeDescriptor["settingsPartnerPortals"].renderRoute({
        organizationSlug: org.slug,
      }),
    },
  ];

  if (user.isAdmin) {
    orgSettingsMeta.splice(2, 0, {
      label: "Email template",
      isSelected: !!matchPath(
        pathname,
        routeDescriptor["settingsEmailTemplate"].path
      ),
      route: routeDescriptor["settingsEmailTemplate"].renderRoute({
        organizationSlug: org.slug,
      }),
    });
  }

  const accessManagement: MenuDataItem[] = [
    {
      label: "Users",
      isSelected: !!matchPath(
        pathname,
        routeDescriptor["settingsAccessManagement"].path
      ),
      route: routeDescriptor["settingsAccessManagement"].renderRoute({
        organizationSlug: org.slug,
      }),
    },
    {
      label: "User Groups",
      isSelected: !!matchPath(
        pathname,
        routeDescriptor["settingsGroupManagement"].path
      ),
      route: routeDescriptor["settingsGroupManagement"].renderRoute({
        organizationSlug: org.slug,
      }),
    },
    {
      label: "User Attributes",
      isSelected: !!matchPath(
        pathname,
        routeDescriptor["settingsUserAttribute"].path
      ),
      route: routeDescriptor["settingsUserAttribute"].renderRoute({
        organizationSlug: org.slug,
      }),
    },
    {
      label: "Service Accounts",
      isSelected: !!matchPath(
        pathname,
        routeDescriptor["settingsServiceAccountManagement"].path
      ),
      route: routeDescriptor["settingsServiceAccountManagement"].renderRoute({
        organizationSlug: org.slug,
      }),
    },
  ];

  const notificationSettingsMeta: MenuDataItem[] = [
    {
      label: "Topics",
      isSelected: !!matchPath(
        pathname,
        routeDescriptor["settingsNotificationTopics"].path
      ),
      route: routeDescriptor["settingsNotificationTopics"].renderRoute({
        organizationSlug: org.slug,
      }),
    },
    {
      label: "Notification history",
      isSelected: !!matchPath(
        pathname,
        routeDescriptor["settingsNotificationJobs"].path
      ),
      route: routeDescriptor["settingsNotificationJobs"].renderRoute({
        organizationSlug: org.slug,
      }),
    },
  ];

  const storageSettingsMeta: MenuDataItem[] = warehouses
    ? [
        {
          label: "Warehouses",
          isSelected: !!matchPath(
            pathname,
            routeDescriptor["settingsWarehouse"].path
          ),
          route: routeDescriptor["settingsWarehouse"].renderRoute({
            organizationSlug: org.slug,
          }),
        },
      ]
    : [];

  const actionsSettingsMeta: MenuDataItem[] = [
    {
      label: "Installed actions",
      isSelected: !!matchPath(
        pathname,
        routeDescriptor["settingsActionManagement"].path
      ),
      route: routeDescriptor["settingsActionManagement"].renderRoute({
        organizationSlug: org.slug,
      }),
    },
    {
      label: "Push history",
      isSelected: !!matchPath(
        pathname,
        routeDescriptor["settingsActionHistory"].path
      ),
      route: routeDescriptor["settingsActionHistory"].renderRoute({
        organizationSlug: org.slug,
      }),
    },
  ];

  const usageSettingsMeta: MenuDataItem[] = [
    {
      label: "Source Usage",
      isSelected: !!matchPath(
        pathname,
        routeDescriptor["settingsSourceUsage"].path
      ),
      route: routeDescriptor["settingsSourceUsage"].renderRoute({
        organizationSlug: org.slug,
      }),
    },
  ];

  if (hasRoleAccessBoolean(IUserRoleType.ADMIN_BUILDER, user, org.id)) {
    usageSettingsMeta.push({
      label: "Platform Usage",
      isSelected: !!matchPath(
        pathname,
        routeDescriptor["settingsPlatformUsage"].path
      ),
      route: routeDescriptor["settingsPlatformUsage"].renderRoute({
        organizationSlug: org.slug,
      }),
    });
  }

  const contentSettingsMeta: MenuDataItem[] = [
    {
      label: "Shared reports",
      isSelected: !!matchPath(
        pathname,
        routeDescriptor["settingsContentSharedReports"].path
      ),
      route: routeDescriptor["settingsContentSharedReports"].renderRoute({
        organizationSlug: org.slug,
      }),
    },
    {
      label: "Unused explorations",
      isSelected: !!matchPath(
        pathname,
        routeDescriptor["settingsContentUnusedExplorations"].path
      ),
      route: routeDescriptor["settingsContentUnusedExplorations"].renderRoute({
        organizationSlug: org.slug,
      }),
    },
  ];

  const securitySettingsMeta: MenuDataItem[] = [
    {
      label: "Audit Logs",
      isSelected: !!matchPath(
        pathname,
        routeDescriptor["settingsAuditLog"].path
      ),
      route: routeDescriptor["settingsAuditLog"].renderRoute({
        organizationSlug: org.slug,
      }),
    },
  ];

  const menuData = [
    {
      key: "org-settings",
      node: "Org Admin",
      items: orgSettingsMeta,
    },
    {
      key: "access-settings",
      node: "Access Admin",
      items: accessManagement,
    },
    ...(orgFeatures.includes(IOrgFeatureType.OBJECTS)
      ? [
          {
            key: "notification-settings",
            node: "Notification Admin",
            items: notificationSettingsMeta,
          },
        ]
      : []),
    ...(storageSettingsMeta.length
      ? [
          {
            key: "storage-settings",
            node: "Storage Admin",
            items: storageSettingsMeta,
          },
        ]
      : []),
    {
      key: "actions-settings",
      node: "Actions Admin",
      items: actionsSettingsMeta,
    },
    {
      key: "usage-settings",
      node: "Usage",
      items: usageSettingsMeta,
    },
    {
      key: "content-settings",
      node: "Content Admin",
      items: contentSettingsMeta,
    },
    {
      key: "security-settings",
      node: "Security",
      items: securitySettingsMeta,
    },
  ];

  return (
    <div
      style={{
        overflowY: "auto",
        maxHeight: "100%",
        padding: "0 8px",
      }}
    >
      {menuData.map((md, i) => {
        return (
          <ClosableContent
            key={md.key}
            name={md.node}
            avoidClosing={true}
            items={md.items.map((i, key) => {
              return {
                key: key.toString(),
                label: (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        flex: 1,
                      }}
                    >
                      <Typography.Text>{i.label}</Typography.Text>
                    </div>
                  </div>
                ),
                selected: i.isSelected as boolean,
                onClick: () => history.push(i.route),
              };
            })}
          />
        );
      })}
    </div>
  );
}

export default compose<Props, ISettingsMenuProps>(
  withRouter,
  WithOrg
)(SettingsMenu);
